import * as React from 'react';
import moment from 'moment';
import {
 first, isArray, isFunction, isString, last, map,
} from 'lodash';
import { DatePicker } from 'antd';
import { localToUTC, utcToLocal } from '@frontend/app/utils/date';

import styles from './DateBetween.scss';

const { useCallback, useMemo } = React;

interface IProps {
  value: [Date, Date];
  onChange?(value: [Date, Date]);
}

export const DateBetween: React.FC<IProps> = (props) => {
  const {
    value,
    onChange,
  } = props;

  if (value instanceof Date) {
    onChange([localToUTC(value), localToUTC(value)]);
  } else if (isString(value)) {
    onChange([localToUTC(new Date(value)), localToUTC(new Date(value))]);
  }

  const handleChangeDate = useCallback((newDates: moment.Moment[]) => {
    if (isFunction(onChange)) {
      if (isArray(newDates) && newDates.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        onChange(map(newDates, (date) => (
          localToUTC(date.toDate())
        )));
      } else {
        onChange(null);
      }
    }
  }, [onChange]);

  const dates: [moment.Moment, moment.Moment] = useMemo(() => (
    value?.length === 2
      ? [
        moment(utcToLocal(new Date(first(value)))),
        moment(utcToLocal(new Date(last(value)))),
      ]
      : null
  ), [value]);

  return (
    <div className={styles.DateBetween}>
      <DatePicker.RangePicker
        className={styles.datePicker}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value={dates as any}
        onChange={handleChangeDate}
        ranges={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          'Today': [moment() as any, moment() as any],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          'This Month': [moment().startOf('month') as any, moment().endOf('month') as any],
        }}
      />
    </div>
  );
};
