export interface IOrderedColumn {
  memberFieldSchemaId?: number;
  dbColumn?: string;
  projectColumn?: string;
}

export interface ISelectedColumns {
  memberFieldSchemaIds: number[];
  dbColumns: string[];
  projectColumns?: string[];
  order?: IOrderedColumn[];
}
