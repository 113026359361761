import { filter } from 'lodash';
import { useMemo } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import {
  GET_RESOURCES_QUERY,
} from '@frontend/app/queries';
import {
  GetResourcesQuery,
  GetResourcesQueryVariables,
  GetResourcesQuery_resources,
} from '@frontend/app/queries/types/GetResourcesQuery';

type IOptions = QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>;
export type IResource = GetResourcesQuery_resources;

export function useGetResources(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetResourcesQuery>(
    GET_RESOURCES_QUERY,
    options,
  );

  const activeResources = useMemo(() => filter(
      data && data.resources,
      (r) => !r.authProvider.userRevokedAccess && !r.authProvider.systemRevokedAccess,
    ), [data]);

  return {
    loading,
    resources: data && data.resources,
    activeResources,
    error,
    refetch,
  };
}
