import { Button, ButtonProps } from '@frontend/shadcn/components/ui/button';
import React, { MouseEventHandler } from 'react';

interface IButtonProps {
  onClick?: MouseEventHandler<HTMLElement>;
  icon?: React.ReactNode;
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  label?: string;
  iconPosition?: 'before' | 'after';
  loading?: boolean;
}

const HeaderButton: React.FC<IButtonProps> = ({
  onClick,
  icon,
  disabled,
  variant = 'outlineHeader',
  size = 'headerIcon',
  label,
  iconPosition = 'after',
  loading,
}) => (
  <Button
    size={size}
    variant={variant}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
    className="font-inter flex gap-1"
  >
    {iconPosition === 'before' && icon}
    {label}
    {iconPosition === 'after' && icon}
  </Button>
);

export default HeaderButton;
