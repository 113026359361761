import { useCallback, useMemo, useState } from 'react';
import { values } from 'lodash';

interface IExcludeMemberReturn {
  excludeMemberIds: readonly number[];
  onExcludeMember: (memberId: number) => void;
  resetExcludeMemberIds: () => void;
}

interface IExcludeMemberState {
  [memberId: number]: number;
}

export const useExcludeMemberIds = (): IExcludeMemberReturn => {
  const [excludeMemberIds, setExcludeMemberIds] = useState<Readonly<IExcludeMemberState>>({});

  const onExcludeMember = useCallback((memberId: number) => {
    // check if member ID is already in the exclude list
    if (memberId in excludeMemberIds) {
      return;
    }
    setExcludeMemberIds((memberIds) => ({
      ...memberIds,
      [memberId]: memberId,
    }));
  }, [excludeMemberIds, setExcludeMemberIds]);

  const resetExcludeMemberIds = useCallback(() => {
    setExcludeMemberIds({});
  }, [setExcludeMemberIds]);

  return useMemo((): IExcludeMemberReturn => ({
    excludeMemberIds: values(excludeMemberIds),
    onExcludeMember,
    resetExcludeMemberIds,
  }), [excludeMemberIds, onExcludeMember, resetExcludeMemberIds]);
};
