import * as React from 'react';
import {
  chain,
  filter,
  has,
  map,
  toLower,
} from 'lodash';
import { logger } from '@common';
import {
  IResource,
  IRevokeAccessOptions,
  IUpdateResourceOptions,
  useClientFeatureEnabled,
  useFetchUsers,
  useGetAllThreadsCount,
  useGetApplicationsByIds,
  useGetInstalledApplicationIds,
  useGetResources,
  useInstalledApplicationInstances,
  useRevokeResourceAccess,
  useUpdateResource,
} from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';
import { GetUsersQuery_users as IUser } from '@frontend/app/queries/types/GetUsersQuery';
import { ResourceType, ThreadLabelType } from '@frontend/app/types/globalTypes';
import { messageThreadTypes } from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { useEffect, useState } from 'react';
import { useApolloClient as useStaApolloClient } from '@affiliates/hooks';
import { GET_ALL_CLIENT_CONNECTIONS } from '@frontend/applications/AffiliatesApp/queries/getAllClientConnectionsQuery';
import { SHOPIFY_APP_ID } from '@frontend/app/constants/applicationIds';
import { GetResourcesQuery_resources } from '../queries/types/GetResourcesQuery';
import { ClientFeature } from '../constants';

const {
  useContext, useMemo,
} = React;

interface IResourceContext {
  user: IUser;
  users: IUser[];
  allThreadsCount: number;
  refetchNotificationCount(): void;

  resources: IResource[];
  emailResources: IResource[];
  messageResources: IResource[];
  activeEmailResources: IResource[];
  sharedEmailResources: IResource[];
  individualEmailResources: IResource[];
  activeMessageResources: IResource[];
  sharedMessageResources: IResource[];
  individualMessageResources: IResource[];
  loading: boolean;
  refetch(): void;

  addAccount(type: ResourceType, isShared: boolean): void;

  // update resource
  /* eslint-disable @typescript-eslint/no-explicit-any */
  updateResource(options?: IUpdateResourceOptions): Promise<any>;
  updating: boolean;

  // revoke access
  /* eslint-disable @typescript-eslint/no-explicit-any */
  revokeAccess(options?: IRevokeAccessOptions): Promise<any>;
  revoking: boolean;
  isAllShopifyNotConnected: boolean;
  isPrimaryShopifyConnected: boolean;
}

const getActiveResources = (resources: GetResourcesQuery_resources[]) => (
  chain(resources)
    .filter((r) => !r.authProvider.userRevokedAccess && !r.authProvider.systemRevokedAccess)
    .sortBy((r) => (r.authProvider.isShared ? 0 : 1))
    .value()
);

const getSharedResource = (resources: GetResourcesQuery_resources[]) => (
  filter(resources, (resource) => resource.authProvider.isShared)
);

const getIndividualResource = (resources: GetResourcesQuery_resources[]) => (
  filter(resources, (resource) => !resource.authProvider.isShared)
);

export const ResourceContext = React.createContext<IResourceContext>(null);
export const useResourceContext = () => useContext(ResourceContext);
export const ResourceProvider = ({ children }) => {
  const { token, user: authUser } = useAuth();
  const { users } = useFetchUsers();
  const staApolloClient = useStaApolloClient();
  const [isAllShopifyNotConnected, setIsAllShopifyNotConnected] = useState(false);
  const [isPrimaryShopifyConnected, setIsPrimaryShopifyConnected] = useState(false);
  const isEnabledMultipleShopify = useClientFeatureEnabled(ClientFeature.ENABLE_MULTIPLE_SHOPIFY);
  const installedApps = useGetInstalledApplicationIds();
  const isShopifyAppInstalled = has(installedApps, SHOPIFY_APP_ID);

  const {
    data: {
      instances: applicationInstances = [],
    } = {},
  } = useInstalledApplicationInstances();

  const {
    data: {
      applications = [],
    } = {},
  } = useGetApplicationsByIds(applicationInstances ? map(applicationInstances, 'applicationId') : []);

  const { count: allThreadsCount, refetch: refetchNotificationCount } = useGetAllThreadsCount({
    assignees: [authUser.sub],
    userLabel: ThreadLabelType.TODO,
    isContact: true,
    applicationIds: applications.filter((application) => messageThreadTypes.includes(application.name)).map((application) => application.id),
  });

  const { resources, loading, refetch } = useGetResources({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,
  });
  const { updateResource, loading: updating } = useUpdateResource();
  const { revokeAccess, loading: revoking } = useRevokeResourceAccess();

  const emailResources = useMemo(() => filter(
    resources,
    (resource) => [ResourceType.GMAIL, ResourceType.OUTLOOK].includes(resource.type),
  ), [resources]);
  const messageResources = useMemo(() => filter(
    resources,
    (resource) => [ResourceType.GMAIL, ResourceType.OUTLOOK, ResourceType.IGDM].includes(resource.type),
  ), [resources]);

  const activeEmailResources = useMemo(() => getActiveResources(emailResources), [emailResources]);
  const activeMessageResources = useMemo(() => getActiveResources(messageResources), [messageResources]);

  const sharedEmailResources = useMemo(() => getSharedResource(activeEmailResources), [activeEmailResources]);
  const sharedMessageResources = useMemo(() => getSharedResource(activeMessageResources), [activeMessageResources]);

  const individualEmailResources = useMemo(() => getIndividualResource(activeEmailResources), [activeEmailResources]);
  const individualMessageResources = useMemo(() => getIndividualResource(activeMessageResources), [activeMessageResources]);

  const user = users.find((u) => u.id === authUser.sub);
  const addAccount = async (type: ResourceType, isShared: boolean) => {
    if (!type) {
      console.error('Resource type is needed.');
      logger.error('addAccount invoked without resource id', (new Error()).stack);

      return;
    }

    try {
      const encodedParams = encodeURIComponent(JSON.stringify({ isShared }));
      const res = await fetch(
        `/api/resource_authentication/auth_url?redirectURL=${encodeURIComponent(window.location.href)}&resourceType=${toLower(type)}&params=${encodedParams}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
      );
      const data = await res.json();
      if (data && data.url) {
        localStorage.setItem('addAccountFlag', 'true');
        window.location.assign(data.url);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    (async () => {
      if (isShopifyAppInstalled) {
        const { data } = await staApolloClient.query({ query: GET_ALL_CLIENT_CONNECTIONS });
        if (data?.advertiser?.getClientShopifyResources) {
          const allConnections = data.advertiser.getClientShopifyResources;
          const primaryConnection = allConnections.find((connection) => connection.isPrimary);
          let connectionsToCheck = allConnections;

          if (!isEnabledMultipleShopify) {
            connectionsToCheck = allConnections.filter((connection) => connection.isPrimary);
          }
          if (primaryConnection) {
            setIsPrimaryShopifyConnected(primaryConnection.isConnected);
            if (!primaryConnection.isConnected) {
              setIsAllShopifyNotConnected(true);
              return;
            }
          }

          const allConnected = connectionsToCheck.some((connection) => !connection.isConnected);
          setIsAllShopifyNotConnected(allConnected);
        }
      }
    })();
  }, [staApolloClient, isEnabledMultipleShopify, isShopifyAppInstalled]);

  return (
    <ResourceContext.Provider
      value={{
        user,
        users,
        allThreadsCount,
        refetchNotificationCount,

        resources,
        emailResources,
        messageResources,
        activeEmailResources,
        activeMessageResources,
        sharedEmailResources,
        sharedMessageResources,
        individualEmailResources,
        individualMessageResources,
        loading,
        refetch,

        // add account
        addAccount,

        // update resource
        updateResource,
        updating,

        // revoke access
        revokeAccess,
        revoking,
        isAllShopifyNotConnected,
        isPrimaryShopifyConnected,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};
