import * as React from 'react';
import * as moment from 'moment';
import cx from 'classnames';

import {
  times,
  } from 'lodash';

import {
  Typography,
  Space,
  Checkbox,
  Button,
  Input,
  Alert,
} from '@revfluence/fresh';
import {
  TrashIcon,
  PlusIcon,
} from '@revfluence/fresh-icons/regular/esm';

import styles from './DeliverableList.module.scss';

const { Text } = Typography;

interface IProps {
  areDueDatesSelected: boolean;
  deliverablesDueDates: number[];
  deliverablesWithNoDueDates: number;
  onToggleDueDates: (areDueDatesSelected: boolean) => void;
  onAddDeliverableDueDate: () => void;
  onRemoveDeliverableDueDate: (index: number) => void;
  onUpdateDeliverableDueDate: (index: number, value: number) => void;
  onAddDeliverableWithNoDueDate: () => void;
  onRemoveDeliverableWithNoDueDate: () => void;
}

const { useCallback } = React;

const DeliverableList: React.FC<IProps> = React.memo(
  (
    {
      areDueDatesSelected,
      deliverablesDueDates,
      deliverablesWithNoDueDates,
      onToggleDueDates,
      onAddDeliverableDueDate,
      onRemoveDeliverableDueDate,
      onUpdateDeliverableDueDate,
      onAddDeliverableWithNoDueDate,
      onRemoveDeliverableWithNoDueDate,
    },
  ) => {
    const onDeliverableDueDateChange = useCallback((e, index) => {
      const numericValue = parseInt(e.target.value, 10);
      onUpdateDeliverableDueDate(index, numericValue);
    }, [onUpdateDeliverableDueDate]);

    return (
      <Space
        direction="vertical"
        style={{ width: '100%' }}
      >
        <Text weight="semibold">
          Deliverables
        </Text>
        <Checkbox
          checked={!areDueDatesSelected}
          onChange={(event) => onToggleDueDates(event.target.checked)}
        >
          <Text>No due dates</Text>
        </Checkbox>
        { areDueDatesSelected && (
          <>
              {
                deliverablesDueDates.map((deliverableDueDate, index) => (
                  <Space
                    direction="horizontal"
                    key={index.toString()}
                    className={styles.DeliverableList}
                  >
                    <Text>
                      { moment.localeData().ordinal(index + 1) }
                      {' '}
                      deliverable due
                    </Text>
                    <Input
                      min={0}
                      onChange={(event) => onDeliverableDueDateChange(event, index)}
                      placeholder=""
                      type="number"
                      value={deliverableDueDate}
                      className={cx(styles.input, {
                        [styles.error]: !(deliverableDueDate >= 0),
                      })}
                    />
                    <Text>days after brief is sent</Text>
                    <Button
                      onClick={() => onRemoveDeliverableDueDate(index)}
                      danger
                      style={{ borderColor: 'transparent' }}
                      icon={<TrashIcon width={12} height={12} />}
                    />
                  </Space>
                ))
              }
            { deliverablesDueDates.length === 0 && (
              <Alert
                message="At least one deliverable is required to create a template."
                type="error"
              />
            )}
            <Button
              icon={<PlusIcon width={12} height={12} />}
              onClick={onAddDeliverableDueDate}
            >
              <Text weight="semibold" style={{ fontSize: '12px' }}>Add Deliverable</Text>
            </Button>
          </>
        )}

        { !areDueDatesSelected && (
          <>
              {
                times(deliverablesWithNoDueDates, (index) => (
                  <Space
                    direction="horizontal"
                    key={index.toString()}
                    style={{ width: '100%' }}
                  >
                    <Text>
                      { moment.localeData().ordinal(index + 1) }
                      {' '}
                      deliverable
                    </Text>
                    <Button
                      onClick={onRemoveDeliverableWithNoDueDate}
                      danger
                      style={{ borderColor: 'transparent' }}
                      icon={<TrashIcon width={12} height={12} />}
                    />
                  </Space>
                ))
              }
            { deliverablesWithNoDueDates === 0 && (
              <Alert
                message="At least one deliverable is required to create a template."
                type="error"
              />
            )}
            <Button
              icon={<PlusIcon width={12} height={12} />}
              onClick={onAddDeliverableWithNoDueDate}
            >
              <Text weight="semibold" style={{ fontSize: '12px' }}>Add Deliverable</Text>
            </Button>
          </>
        )}
      </Space>
    );
  },
);

DeliverableList.displayName = 'DeliverableList';

export default DeliverableList;
