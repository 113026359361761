import { useQuery, QueryHookOptions } from '@apollo/client';

import { MEMBER_PAGE_LOCAL_STATE } from '@frontend/app/queries';
import {
  MemberPageLocalStateQuery,
} from '@frontend/app/queries/types/MemberPageLocalStateQuery';

type IOptions = QueryHookOptions<MemberPageLocalStateQuery>;

export const useMemberPageLocalStateQuery = (options: IOptions = {}) => useQuery<MemberPageLocalStateQuery>(MEMBER_PAGE_LOCAL_STATE, options);
