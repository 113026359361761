/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xuw3_157 {
  display: flex;
}

._justify-content-space-between_1xuw3_161 {
  justify-content: space-between;
}

._tabular-nums_1xuw3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xuw3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Perks_1xuw3_178 {
  max-width: 93.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5rem auto 0;
  padding: 0 2rem;
  color: #fdfdfd;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
@media only screen and (min-width: 20rem) {
  ._Perks_1xuw3_178 ._title_1xuw3_189 {
    margin-bottom: 0;
    color: #fdfdfd;
    font-weight: 900;
    font-style: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    word-break: break-word;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 {
    display: flex;
    flex-direction: column;
    margin: 2.25rem 0 0;
    word-break: break-word;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemBullet_1xuw3_209 {
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemBullet_1xuw3_209 ._itemBulletSquare_1xuw3_216 {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.8;
    top: 0;
    left: 0;
    background-color: #FDFDFD;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemBullet_1xuw3_209 ._itemBulletValue_1xuw3_225 {
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1.25rem;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemBullet_1xuw3_209._hide_1xuw3_237 {
    display: none;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemBullet_1xuw3_209._numbered_1xuw3_240 {
    width: 2.25rem;
    height: 2.25rem;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemBullet_1xuw3_209._numbered_1xuw3_240 ._itemBulletValue_1xuw3_225 {
    display: flex;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemValue_1xuw3_247 {
    width: 100%;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 ._itemValue_1xuw3_247 *:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (orientation: landscape) {
  ._Perks_1xuw3_178 {
    align-items: flex-start;
    margin: 5rem auto;
    padding: 0 5rem;
  }
  ._Perks_1xuw3_178 ._title_1xuw3_189 {
    width: 20.75rem;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 {
    flex-direction: row;
    flex-wrap: wrap;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203 {
    width: 33.3333333333%;
    margin: 3rem 0 0;
  }
  ._Perks_1xuw3_178 ._list_1xuw3_198 ._item_1xuw3_203:not(:nth-child(3n+3)) {
    padding-right: 3.75rem;
  }
}