import { MutationHookOptions } from '@apollo/client';
import { useMutation } from '@frontend/app/hooks';

import {
  generateMemberSearchGraphQLQuery,
} from '@frontend/app/queries';
import {
  MemberSearchQuery,
  MemberSearchQueryVariables,
} from '@frontend/app/queries/types/MemberSearchQuery';

import { useReloadSegmentCounts } from './useReloadSegmentCounts';
import { useMemberSearchQueryVariables } from './useMemberSearchQueryVariables';
import { MEMBER_SEARCH_MEMBER_APPLICANT_OPERATION } from '../queries/MemberSearchMemberApplicantOperationMutation';
import {
  MemberSearchMemberApplicantOperationMutation,
  MemberSearchMemberApplicantOperationMutationVariables,
} from '../queries/types/MemberSearchMemberApplicantOperationMutation';

type IOptions = MutationHookOptions<MemberSearchMemberApplicantOperationMutation, MemberSearchMemberApplicantOperationMutationVariables>;

export const useMemberSearchMemberApplicantOperationMutation = (
  communityId: number,
  reloadCounts: boolean = true,
  options: IOptions = {},
) => {
  const memberSearchQueryVariables = useMemberSearchQueryVariables();
  const reloadSegmentCounts = useReloadSegmentCounts(communityId, null);

  return useMutation<MemberSearchMemberApplicantOperationMutation, MemberSearchMemberApplicantOperationMutationVariables>(
    MEMBER_SEARCH_MEMBER_APPLICANT_OPERATION,
    {
      ...options,
      update(store) {
        const newCache = {
          members: [],
        };

        store.writeQuery<MemberSearchQuery, MemberSearchQueryVariables>({
          query: generateMemberSearchGraphQLQuery(),
          variables: memberSearchQueryVariables,
          data: newCache,
        });

        // Refetch all segment counts
        if (reloadCounts) {
          reloadSegmentCounts();
        }
      },
    },
  );
};
