import * as React from 'react';
import * as qs from 'querystring';

import { useGetCurrentClient } from '@frontend/app/hooks';

const { useCallback } = React;

export const useGetProjectLandingPageUrl = () => {
  const {
    client = null,
  } = useGetCurrentClient();

  const getFullPageUrl = useCallback((
    customLandingPagePath: string,
    utmSource: string,
    isPreview?: boolean,
  ) => {
    const clientHostname = client?.hostname;
    let baseUrl = `${window?.location?.origin}/join/`;
    if (clientHostname) {
      baseUrl = baseUrl.replace('community', clientHostname);
    }

    const landingPagePath = encodeURIComponent(customLandingPagePath || '');
    const url = `${baseUrl}${landingPagePath}`;

    const params: Record<string, string> = utmSource ? { utmSource } : {};
    if (!clientHostname && client?.id) {
      params.clientId = client.id;
    }
    if (isPreview) {
      params.preview = 'true';
    }

    const search = qs.stringify(params);

    return `${url}?${search}`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, client]);

  return { getFullPageUrl };
};
