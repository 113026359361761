import { useQuery } from '@apollo/client';
import { GetWaitForAgreementTermsQuery, GetWaitForAgreementTermsQueryVariables } from '@frontend/app/queries/types/GetWaitForAgreementTermsQuery';
import { GET_WAIT_FOR_AGREEMENT_TERMS } from '@frontend/app/queries/GetWaitForAgreementTermsQuery';

export function useGetWaitForAgreementTerms(options = {}) {
  return useQuery<GetWaitForAgreementTermsQuery, GetWaitForAgreementTermsQueryVariables>(
    GET_WAIT_FOR_AGREEMENT_TERMS,
    options,
  );
}
