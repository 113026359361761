import * as React from 'react';
import cx from 'classnames';

import {
  Image,
  Button,
  Space,
  Menu,
  Dropdown,
  Tag,
  Spinner,
} from '@revfluence/fresh';
import {
  EllipsisIcon,
  VideoIcon,
  CircleCheckIcon,
  CircleExclamationIcon,
  ImageIcon,
} from '@revfluence/fresh-icons/regular/esm';
import {
  MessageLinesIcon,
  VideoIcon as VideoFilledIcon,
  ImageIcon as ImageFilledIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { Typography } from '@revfluence/fresh';
import { isEmpty } from 'lodash';
import {
  InstagramIcon,
  TiktokIcon,
  YoutubeIcon,
  TwitterIcon,
  PinterestIcon,
} from '@revfluence/fresh-icons/brands/esm';
import { clickToDownload } from '@frontend/app/utils';
import { ContentState, ContentType } from '@frontend/app/types/globalTypes';
import { ICollaborationDetails } from '../types';
import styles from './ContentCard.scss';

const { Text } = Typography;
const { useMemo, useCallback, useState } = React;

interface IProps {
  className?: string;
  content: ICollaborationDetails;
  onReviewClick(contentReviewId: number): void;
  onCopyTermsLinkAction(termsLink: string): void;
  onMarkCompleteAction(contentReviewId: number, shouldMarkComplete: boolean): void;
}

const getDueDateString = (content: ICollaborationDetails): string => {
  if (content.nextDeadlineTimeStamp) {
    return `due before ${new Date(content.nextDeadlineTimeStamp * 1000).toLocaleDateString('en-US')}`;
  }
  if (content.completedDateTimeStamp) {
    const completedBy = content.completedBy ? `by ${content.completedBy}` : '';
    return `completed on ${new Date(content.completedDateTimeStamp * 1000).toLocaleDateString('en-US')} ${completedBy}`;
  }
  return '';
};

const getDisplayNameForStatus = (status: ContentState): string => {
  switch (status) {
    case ContentState.PLACEHOLDER:
      return 'Waiting for creator to submit';
    case ContentState.NEW:
      return 'Needs Review';
    case ContentState.MARKED_COMPLETE:
      return 'Marked as Complete';
    case ContentState.PENDING_MARK_COMPLETE:
      return 'Marked as Complete Pending';
    case ContentState.REJECTED:
      return 'Waiting for revisions';
    case ContentState.AMENDED:
      return 'Review changes';
    case ContentState.ACCEPTED:
      return 'Waiting for creator to set live';
    case ContentState.UPLOADED:
      return 'Review live content';
    case ContentState.VALIDATING_UPLOAD:
      return 'Validating uploaded content';
    case ContentState.SOFT_APPROVED:
      return 'Approved with comment';
    case ContentState.SOFT_REJECTED:
      return 'Waiting for the brand to review content';
    case ContentState.GCR_PENDING:
      return 'Waiting for the brand to review content';
    case ContentState.COMPLETED_WITHOUT_PAYMENT:
    case ContentState.COMPLETED:
      return 'Complete';
  }
};

const getContentStatus = (content: ICollaborationDetails) => {
  switch (content.state) {
    case ContentState.ACCEPTED:
    case ContentState.PLACEHOLDER:
    case ContentState.REJECTED:
    case ContentState.VALIDATING_UPLOAD:
      return (
        <Tag className={styles.statusTag} icon={<CircleExclamationIcon />} color="default">
          {getDisplayNameForStatus(content.state)}
        </Tag>
      );
    case ContentState.NEW:
    case ContentState.GCR_PENDING:
    case ContentState.SOFT_REJECTED:
    case ContentState.AMENDED:
    case ContentState.UPLOADED:
    case ContentState.PENDING_MARK_COMPLETE:
    case ContentState.SOFT_APPROVED:
      return (
        <Tag className={styles.statusTag} icon={<CircleExclamationIcon />} color="warning">
          {getDisplayNameForStatus(content.state)}
        </Tag>
      );
    case ContentState.MARKED_COMPLETE:
      return (
        <Tag className={styles.statusTag} icon={<CircleCheckIcon />} color="success">
          {getDisplayNameForStatus(content.state)}
        </Tag>
      );
    case ContentState.COMPLETED_WITHOUT_PAYMENT:
    case ContentState.COMPLETED: {
      return (
        <Tag className={styles.statusTag} icon={<CircleCheckIcon />} color="success">
          {getDisplayNameForStatus(content.state)}
        </Tag>
      );
    }
    default:
      throw Error('bad value');
  }
};

const getIcon = (type: ContentType) => {
  switch (type) {
    case ContentType.ADDITIONAL_VIDEOS:
      return <VideoIcon className={styles.titleIcon} />;
    case ContentType.ADDITIONAL_IMAGES:
      return <ImageIcon className={styles.titleIcon} />;
    case ContentType.INSTAGRAM:
    case ContentType.INSTAGRAM_REEL:
    case ContentType.INSTAGRAM_STORY:
    case ContentType.INSTAGRAM_VIDEO:
      return <InstagramIcon className={styles.titleIcon} />;
    case ContentType.TIKTOK_VIDEO:
      return <TiktokIcon className={styles.titleIcon} />;
    case ContentType.YOUTUBE_DEDICATED:
    case ContentType.YOUTUBE_MENTION:
      return <YoutubeIcon className={styles.titleIcon} />;
    case ContentType.TWITTER_POST:
      return <TwitterIcon className={styles.titleIcon} />;
    case ContentType.PINTEREST:
      return <PinterestIcon className={styles.titleIcon} />;
  }
};

const getPlaceholderIcon = (type: ContentType) => {
  switch (type) {
    case ContentType.TIKTOK_VIDEO:
    case ContentType.INSTAGRAM_REEL:
    case ContentType.INSTAGRAM_VIDEO:
    case ContentType.YOUTUBE_DEDICATED:
    case ContentType.YOUTUBE_MENTION:
    case ContentType.ADDITIONAL_VIDEOS:
      return <VideoFilledIcon />;
    case ContentType.INSTAGRAM:
    case ContentType.INSTAGRAM_STORY:
    case ContentType.ADDITIONAL_IMAGES:
    case ContentType.PINTEREST:
      return <ImageFilledIcon />;
    case ContentType.TWITTER_POST:
      return <MessageLinesIcon />;
  }
};

export const ContentCard: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    onReviewClick,
    content,
    onCopyTermsLinkAction,
    onMarkCompleteAction,
  } = props;

  const [isImagePlaceholder, setIsImagePlaceholder] = useState(false);
  const [showSpinnerButton, setShowSpinnerButton] = useState(false);
  const showReviewButton = useMemo(() =>
    content.state === ContentState.NEW || content.state === ContentState.AMENDED,
    [content]);

  const shouldShowMarkComplete = useMemo(() =>
    content.state === ContentState.PLACEHOLDER || content.state === ContentState.ACCEPTED
    || content.state === ContentState.REJECTED,
    [content]);

  const shouldShowUndoMarkComplete = useMemo(() => {
    setShowSpinnerButton(false);
    return content.state === ContentState.PENDING_MARK_COMPLETE;
   }, [content]);
  const changeCompletionStatus = useCallback((contentReviewId: number, shouldMarkComplete: boolean) => {
    onMarkCompleteAction(contentReviewId, shouldMarkComplete);
    setShowSpinnerButton(true);
  }, [onMarkCompleteAction]);

  const handleActionClick = useCallback((target) => {
    const { key } = target;
    switch (key) {
      case 'copyTermsLink':
        onCopyTermsLinkAction(content.projectURL);
        break;
      case 'markComplete':
        changeCompletionStatus(content.id, true);
        break;
      case 'undoMarkComplete':
        changeCompletionStatus(content.id, false);
        break;
      case 'downloadContent':
        // a tag handles this for now
        break;
      default:
        return 'Unknown Type';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCopyTermsLinkAction, onMarkCompleteAction]);

  const onDownload = () => {
    const mediaUrl = content.contentUrls[0];
    clickToDownload(mediaUrl);
  };

  const menu = useMemo(() => (
    <Menu onClick={handleActionClick}>
      <Menu.Item
        key="copyTermsLink"
      >
        Copy Submission Link
      </Menu.Item>
      {shouldShowMarkComplete && (
        <Menu.Item
          key="markComplete"
        >
          Mark as Complete
        </Menu.Item>
      )}
      {shouldShowUndoMarkComplete && (
        <Menu.Item
          key="undoMarkComplete"
        >
          Undo Mark as Complete
        </Menu.Item>
      )}
      {!isEmpty(content.contentUrls) && (
        <Menu.Item
          key="downloadContent"
        >
          <a onClick={onDownload}>
            Download
          </a>
        </Menu.Item>
      )}
    </Menu>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [handleActionClick, content]);

  const isVideoContent = useCallback((type: ContentType) => {
    switch (type) {
      case ContentType.TIKTOK_VIDEO:
      case ContentType.INSTAGRAM_VIDEO:
      case ContentType.ADDITIONAL_VIDEOS:
        return true;
      default: return false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.postType]);

  return (
    <div className={cx(styles.ContentCard, props.className)}>
      {isImagePlaceholder ? (
        <div className={cx(styles.contentImage, styles.placeholder)}>
          {getPlaceholderIcon(content.postType)}
        </div>
      ) : (
        (
          content.contentUrls.length > 0
            && isVideoContent(content.postType)
        ) ? (
          /* eslint-disable-next-line jsx-a11y/media-has-caption */
          <video
            src={`${content.contentUrls[0]}#t=1`}
            preload="metadata"
            className={cx(styles.contentImage, styles.placeholder, styles.video)}
          />
        ) : (
          <Image
            className={styles.contentImage}
            src={content.previewImg || 'err'}
            placeholder={(
              <div className={cx(styles.contentImage, styles.placeholder)}>
                {getPlaceholderIcon(content.postType)}
              </div>
            )}
            onError={() => setIsImagePlaceholder(true)}
          />
        )
      )}
      <div className={cx(styles.details)}>
        <Text className={styles.title}>
          {getIcon(content.postType)}
          {content.displayName}
        </Text>
        <Text type="secondary">
          {getDueDateString(content)}
        </Text>
        {getContentStatus(content)}
      </div>
      <Space
        direction="horizontal"
        size="middle"
      >
        {showReviewButton
          && (
            <Button
              type="primary"
              onClick={() => onReviewClick(content.id)}
            >
              Review
            </Button>
          )}
        {showSpinnerButton
          ? <Spinner />
          : (
            <Dropdown
              overlay={menu}
            >
              <Button className={styles.additionalActions}><EllipsisIcon /></Button>
            </Dropdown>
        )}
      </Space>
    </div>
  );
});
ContentCard.displayName = 'ContentCard';
