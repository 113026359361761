import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const PartyPopperIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 42 42">
    <path d="M13.5637 8.73603C13.4331 8.86669 13.3339 9.02419 13.2511 9.19686L13.2417 9.18752L0.156396 38.6645L0.169229 38.6773C-0.0734375 39.1475 0.332563 40.1042 1.1644 40.9372C1.99623 41.769 2.9529 42.175 3.42306 41.9323L3.43473 41.944L32.9117 28.8575L32.9024 28.847C33.0739 28.7654 33.2314 28.6662 33.3632 28.5332C35.1856 26.7109 32.2304 20.8017 26.7646 15.3347C21.2964 9.86769 15.3872 6.91369 13.5637 8.73603Z" fill="#DD2E44" />
    <path d="M15.1667 14L0.485396 37.9237L0.156396 38.6645L0.169229 38.6774C-0.0734375 39.1475 0.332563 40.1042 1.1644 40.9372C1.43506 41.2079 1.71623 41.4132 1.99156 41.587L19.8334 19.8333L15.1667 14Z" fill="#EA596E" />
    <path d="M26.8474 15.2437C32.2957 20.6943 35.3209 26.5043 33.6012 28.2217C31.8827 29.9413 26.0727 26.9173 20.6209 21.469C15.1714 16.0183 12.1474 10.206 13.8659 8.48751C15.5855 6.76901 21.3955 9.79301 26.8474 15.2437Z" fill="#A0041E" />
    <path d="M21.6883 15.8772C21.4561 16.065 21.1528 16.163 20.8319 16.128C19.8193 16.0184 18.9676 15.666 18.3714 15.1095C17.7403 14.5204 17.4288 13.7294 17.5139 12.9372C17.6633 11.5465 19.0586 10.2702 21.4374 10.5269C22.3626 10.626 22.7756 10.3285 22.7896 10.1862C22.8059 10.045 22.4664 9.66585 21.5413 9.56552C20.5286 9.45585 19.6769 9.10352 19.0796 8.54702C18.4484 7.95785 18.1358 7.16685 18.2221 6.37468C18.3738 4.98402 19.7679 3.70768 22.1444 3.96552C22.8188 4.03785 23.1746 3.89902 23.3251 3.80918C23.4453 3.73568 23.4931 3.66568 23.4978 3.62485C23.5118 3.48368 23.1769 3.10452 22.2494 3.00418C21.6089 2.93418 21.1446 2.36018 21.2158 1.71852C21.2846 1.07802 21.8574 0.61485 22.5003 0.68485C24.8768 0.94035 25.9688 2.48385 25.8183 3.87568C25.6666 5.26868 24.2724 6.54268 21.8936 6.28718C21.2193 6.21368 20.8669 6.35368 20.7153 6.44352C20.5951 6.51585 20.5461 6.58702 20.5414 6.62668C20.5263 6.76902 20.8634 7.14702 21.7909 7.24735C24.1674 7.50402 25.2594 9.04635 25.1089 10.4382C24.9584 11.8289 23.5643 13.1052 21.1866 12.8474C20.5123 12.775 20.1576 12.915 20.0059 13.0037C19.8846 13.0784 19.8379 13.1484 19.8333 13.188C19.8181 13.3292 20.1553 13.7084 21.0816 13.8087C21.7209 13.8787 22.1864 14.4539 22.1153 15.0944C22.0826 15.414 21.9204 15.6905 21.6883 15.8772Z" fill="#AA8DD8" />
    <path d="M35.7712 26.6665C38.073 26.0167 39.6608 27.0433 40.0388 28.3908C40.4168 29.7372 39.5978 31.4417 37.2972 32.0892C36.3988 32.3412 36.1293 32.7705 36.1655 32.907C36.2052 33.0447 36.6614 33.271 37.5574 33.0178C39.858 32.3703 41.4458 33.397 41.8238 34.7433C42.2042 36.0908 41.3829 37.793 39.081 38.4417C38.1839 38.6937 37.9132 39.1242 37.9528 39.2607C37.9913 39.3972 38.4463 39.6235 39.3435 39.3715C39.9618 39.1977 40.6082 39.5582 40.782 40.1777C40.9547 40.7983 40.5942 41.4423 39.9735 41.6173C37.674 42.2648 36.085 41.2405 35.7047 39.8918C35.3267 38.5455 36.1469 36.8433 38.4499 36.1947C39.3482 35.9415 39.6177 35.5133 39.578 35.3757C39.5407 35.2392 39.0857 35.0117 38.1897 35.2637C35.8867 35.9123 34.3 34.888 33.9209 33.5382C33.5417 32.1918 34.3619 30.4897 36.6637 29.8398C37.5597 29.589 37.8292 29.1573 37.7919 29.022C37.7522 28.8843 37.2983 28.658 36.4012 28.91C35.7805 29.085 35.1377 28.7233 34.9627 28.1038C34.7889 27.4855 35.1505 26.8415 35.7712 26.6665Z" fill="#77B255" />
    <path d="M26.8345 23.52C26.4915 23.52 26.1532 23.3695 25.9222 23.0825C25.5197 22.5785 25.6025 21.8447 26.1042 21.4422C26.3585 21.238 32.4252 16.4733 40.999 17.6995C41.6372 17.7905 42.0805 18.3808 41.9895 19.019C41.8985 19.656 41.3128 20.104 40.6688 20.0083C33.0937 18.9327 27.6173 23.2213 27.5637 23.2645C27.3467 23.4372 27.09 23.52 26.8345 23.52Z" fill="#AA8DD8" />
    <path d="M6.71293 18.6667C6.60209 18.6667 6.48893 18.6503 6.37693 18.6177C5.75976 18.4322 5.40976 17.7823 5.59526 17.1652C6.91709 12.7633 8.11526 5.73882 6.64293 3.90715C6.47843 3.69949 6.22993 3.49532 5.66059 3.53849C4.56626 3.62249 4.67009 5.93132 4.67126 5.95465C4.72026 6.59749 4.23726 7.15749 3.59559 7.20532C2.94343 7.24499 2.39276 6.77132 2.34493 6.12849C2.22476 4.51965 2.72526 1.42099 5.48559 1.21215C6.71759 1.11882 7.74076 1.54699 8.46293 2.44532C11.2291 5.88815 8.42093 15.869 7.83059 17.836C7.67893 18.3412 7.21459 18.6667 6.71293 18.6667Z" fill="#77B255" />
    <path d="M29.75 12.8333C30.7165 12.8333 31.5 12.0498 31.5 11.0833C31.5 10.1168 30.7165 9.33332 29.75 9.33332C28.7835 9.33332 28 10.1168 28 11.0833C28 12.0498 28.7835 12.8333 29.75 12.8333Z" fill="#5C913B" />
    <path d="M2.33333 23.3333C3.622 23.3333 4.66667 22.2887 4.66667 21C4.66667 19.7113 3.622 18.6667 2.33333 18.6667C1.04467 18.6667 0 19.7113 0 21C0 22.2887 1.04467 23.3333 2.33333 23.3333Z" fill="#9266CC" />
    <path d="M37.9166 24.5C38.8831 24.5 39.6666 23.7165 39.6666 22.75C39.6666 21.7835 38.8831 21 37.9166 21C36.9501 21 36.1666 21.7835 36.1666 22.75C36.1666 23.7165 36.9501 24.5 37.9166 24.5Z" fill="#5C913B" />
    <path d="M27.4166 38.5C28.3831 38.5 29.1666 37.7165 29.1666 36.75C29.1666 35.7835 28.3831 35 27.4166 35C26.4501 35 25.6666 35.7835 25.6666 36.75C25.6666 37.7165 26.4501 38.5 27.4166 38.5Z" fill="#5C913B" />
    <path d="M32.6667 7C33.9554 7 35 5.95533 35 4.66667C35 3.378 33.9554 2.33334 32.6667 2.33334C31.378 2.33334 30.3334 3.378 30.3334 4.66667C30.3334 5.95533 31.378 7 32.6667 7Z" fill="#FFCC4D" />
    <path d="M37.9166 11.6667C38.8831 11.6667 39.6666 10.8832 39.6666 9.91666C39.6666 8.95017 38.8831 8.16666 37.9166 8.16666C36.9501 8.16666 36.1666 8.95017 36.1666 9.91666C36.1666 10.8832 36.9501 11.6667 37.9166 11.6667Z" fill="#FFCC4D" />
    <path d="M34.4166 16.3333C35.3831 16.3333 36.1666 15.5498 36.1666 14.5833C36.1666 13.6168 35.3831 12.8333 34.4166 12.8333C33.4501 12.8333 32.6666 13.6168 32.6666 14.5833C32.6666 15.5498 33.4501 16.3333 34.4166 16.3333Z" fill="#FFCC4D" />
    <path d="M8.75 29.1667C9.7165 29.1667 10.5 28.3832 10.5 27.4167C10.5 26.4502 9.7165 25.6667 8.75 25.6667C7.7835 25.6667 7 26.4502 7 27.4167C7 28.3832 7.7835 29.1667 8.75 29.1667Z" fill="#FFCC4D" />
  </SvgIcon>
));

PartyPopperIcon.displayName = 'PartyPopperIcon';
