/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';

import {
  SocialProfileCell,
} from '@frontend/app/components/MemberTable/CustomCells/SocialProfileCell';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';
import { TWorklet, TWorkItem, TTask } from '@frontend/app/containers/Projects/types';
import {
 Button, Tag, ITableColumn,
} from '@revfluence/fresh';
import { EllipsisLabel } from '@frontend/app/components';
import { NameCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/NameCell';
import { LastMessageCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/LastMessageCell';
import { GetAllCompletedMembersQuery_members } from '@frontend/app/queries/types/GetAllCompletedMembersQuery';
import {
  ColumnKey, ColumnConfig, Task,
} from '@frontend/app/containers/Projects/constants';
import { ClientFeature } from '@frontend/app/constants';
import { IMember, IThread, useClientFeatureEnabled } from '@frontend/app/hooks';
import { hasError, hasNotification } from '@frontend/app/utils/task';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import { OwnersCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/OwnersCell';

import { MemberSearchQuery_members_owners as IOwners } from '@frontend/app/queries/types/MemberSearchQuery';
import { GetAllTasksQuery_tasks as ITask } from '@frontend/app/queries/types/GetAllTasksQuery';
import {
 get, keyBy, isNil, orderBy, filter,
 size, isEmpty, isNumber,
} from 'lodash';
import {
  ICounts,
} from '@frontend/app/containers/Projects/hooks';
import styles from '../MembersTable.scss';
import { useMemberColumns } from '../../MemberTable/hooks';
import { sortStr, formatDate } from '../util';

const { useMemo } = React;

export type MemberTableRow = GetAllCompletedMembersQuery_members & {
  id: string;
  key: string;
  name: string;
  member: any;
  fields: any;
  email: string;
  owners: IOwners[];
  [ColumnKey.DaysInStage]: number;
  [ColumnKey.CTA]: any;
  [ColumnKey.DateAdded]: number;
  [ColumnKey.DateCompleted]: number;
  [ColumnKey.TaskName]: ITask;
  [ColumnKey.WorkletName]: ITask;
  [ColumnKey.WorkletTaskName]: ITask;
  [ColumnKey.PendingContracts]?: string;
  [ColumnKey.PaypalAddress]?: string;
  [ColumnKey.LastPaymentSent]?: Date;
  [ColumnKey.Message]?: string;
  [ColumnKey.Deliverables]?: string;
  [ColumnKey.Price]?: number;
  [ColumnKey.NextDeadline]?: Date;
  [ColumnKey.OpenDeliverables]?: number;
  [ColumnKey.CompletedDeliverables]?: number;
  [ColumnKey.Address1]?: string;
  [ColumnKey.Address2]?: string;
  [ColumnKey.City]?: string;
  [ColumnKey.State]?: string;
  [ColumnKey.PostalCode]?: string;
  [ColumnKey.Country]?: string;
  [ColumnKey.ShippingCountry]?: string;
  [ColumnKey.OrderStatus]?: string;
  [ColumnKey.Quantity]?: string;
  [ColumnKey.Products]?: string;
  [ColumnKey.ShipmentStatus]?: string;
  [ColumnKey.TrackingNumber]?: string;
  [ColumnKey.PromoCode]?: string;
  [ColumnKey.PromoCodeStatus]?: string;
  [ColumnKey.AffiliateLink]?: string;
  _raw: GetAllCompletedMembersQuery_members & {
    fields: any;
  };
};

export const useMembersTableColumns = ({
    cellActionsByColumn,
    columnsToDisplay,
    worklets,
    counts,
    task,
  }: {
    columnsToDisplay: string[];
    worklets: TWorklet[];
    counts: ICounts;
    task?: TTask;
    cellActionsByColumn?: {
      name: (memberId: IMember['id']) => void;
      lastMessage: (threadId: IThread['id']) => void;
      cta: (workItem: TWorkItem) => void;
      task: THandleTaskSelected;
    };
  }) => {
  const isPFAColumnsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_PFA_COLUMNS);
  const memberColumns = useMemberColumns();
  const isAutomatedApprovalCPSEnabled = useClientFeatureEnabled(ClientFeature.AUTOMATED_APPROVAL_CPS);
  const schemasByName = React.useMemo(
    () => ({
      ...keyBy(memberColumns, (column) => column.headerName),
    }),
    [memberColumns],
  );

  const instagramId = get(schemasByName, 'Instagram')?.schemaId;
  const tiktokId = get(schemasByName, 'TikTok')?.schemaId;

  const startDefaultColumns = [
    {
      ...ColumnConfig[ColumnKey.Member],
      ...{
        sorter: (a, b) => sortStr(a?.member?.name, b?.member?.name),
        render: (memberData) => (
          <NameCell
            imageUrl={memberData?.profilePicture}
            name={memberData?.name}
            onClick={() => { cellActionsByColumn.name?.(memberData.id); }}
          />
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.Email],
      ...{
        sorter: (a, b) => sortStr(a?.email, b?.email),
        render: (email) => (
          <EllipsisLabel
            tooltipPlacement="top"
            showTooltip
          >
            {email}
          </EllipsisLabel>
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.LastMessage],
      ...{
        sorter: (a, b) => (
          (a.member?.lastMessage?.internalDate || 0) - (b.member?.lastMessage?.internalDate || 0)
        ),
        render: (memberData) => (
          <div className={styles.fixedCell}>
            <LastMessageCell
              messageData={memberData.lastMessage}
              member={memberData}
              onClick={() => {
                cellActionsByColumn.lastMessage?.(memberData.lastMessage.threadId);
              }}
            />
          </div>
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.WorkletName],
      ...{
        sorter: (a, b) => sortStr(a[ColumnKey.WorkletName]?.taskName, b[ColumnKey.WorkletName]?.taskName),
        render: (data) => (
          <Button
            type="link"
            onClick={() => { cellActionsByColumn.task?.(data?.workletSpecUri, data.taskId); }}
            style={{ padding: 0 }}
          >
            {getWorkletNameBySpecURI(data?.workletSpecUri, worklets)}
          </Button>
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.CTA],
      ...{
        render: (data) => (
          <div className={styles.fixedCell}>
            <Button
              type="link"
              onClick={() => { cellActionsByColumn.cta?.(data?.workItem); }}
              style={{ padding: 0 }}
            >
              {data?.taskMetaData?.singleCTAText || 'Mark as Done'}
            </Button>
          </div>
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.WorkletTaskName],
      ...{
        sorter: (a, b) => sortStr(a[ColumnKey.WorkletTaskName]?.taskName, b[ColumnKey.WorkletTaskName]?.taskName),
        render: (task) => {
          if (isNil(task?.taskName)) return null;

          const error = hasError(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
          const notification = hasNotification(task, counts?.[task.workletSpecUri]?.[task.taskId]?.total);
          let color = 'processing'; // Blue
          if (error) {
            color = 'error'; // red
          } else if (notification) {
            color = 'warning'; // Yellow
          }

          return (
            <div className={styles.fixedCell}>
              <Tag
                onClick={() => cellActionsByColumn.task?.(task.workletSpecUri, task.taskId)}
                className={color === 'processing' ? cx(styles.tag, styles.defaultTag) : styles.tag}
                color={color}
              >
                {task.taskName}
              </Tag>
            </div>
          );
        },
      },
    },
  ];

  const endDefaultColumns = [
    {
      ...ColumnConfig[ColumnKey.Instagram],
      ...{
        key: instagramId,
      },
      ...{
        sorter: (a, b) => sortStr((a.fields?.[instagramId] || ''), (b.fields?.[instagramId] || '')),
        render: (memberData) => (
          <SocialProfileCell
            isEditable={false}
            isEditing={false}
            network="instagram"
            onChangeValue={() => undefined}
            onToggleIsEditing={() => undefined}
            username={memberData.fields[instagramId]}
            member={memberData}
          />
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.TikTok],
      ...{
        key: tiktokId,
      },
      ...{
        sorter: (a, b) => sortStr((a.fields?.[tiktokId] || ''), (b.fields?.[tiktokId] || '')),
        render: (memberData) => (
          <SocialProfileCell
            isEditable={false}
            isEditing={false}
            network="tiktok"
            onChangeValue={() => undefined}
            onToggleIsEditing={() => undefined}
            username={memberData.fields[tiktokId]}
            member={memberData}
          />
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.Owners],
      ...{
        sorter: (a, b) => {
          const aOwners = orderBy(a?.owners, (owner) => owner.name, 'asc');
          const bOwners = orderBy(b?.owners, (owner) => owner.name, 'asc');
          const compare = sortStr(aOwners[0]?.name, bOwners[0]?.name);
          if (size(aOwners) <= 1 || size(bOwners) <= 1) {
            if (compare === 0 && size(aOwners) < size(bOwners)) {
              return -1;
            }
            if (compare === 0 && size(bOwners) < size(aOwners)) {
              return 1;
            }
            return compare;
          }

          if (compare !== 0) return compare;
          return sortStr(aOwners[1]?.name, bOwners[1]?.name);
        },
        render: ({ owners }) => (
          <div className={styles.fixedCell}>
            <OwnersCell owners={owners} />
          </div>
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.DaysInStage],
      ...{
        sorter: (a, b) => a[ColumnKey.DaysInStage] - b[ColumnKey.DaysInStage],
        render: (days) => (
          <EllipsisLabel
            tooltipPlacement="top"
            showTooltip
          >
            {days}
          </EllipsisLabel>
        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.DateAdded],
      ...{
        sorter: (a, b) => new Date(a[ColumnKey.DateAdded]).getTime() - new Date(b[ColumnKey.DateAdded]).getTime(),
        render: (dateAdded) => (
          <EllipsisLabel
            tooltipPlacement="top"
            showTooltip
          >
            {formatDate(dateAdded)}
          </EllipsisLabel>

        ),
      },
    },
    {
      ...ColumnConfig[ColumnKey.DateCompleted],
      ...{
        sorter: (
          (a, b) => new Date(a[ColumnKey.DateCompleted]).getTime() - new Date(b[ColumnKey.DateCompleted]).getTime()
        ),
        render: (dateCompleted) => formatDate(dateCompleted),
      },
    },
  ];

  const specialColumns = useMemo(() => ({
      [ColumnKey.PendingContracts]: {
        ...ColumnConfig[ColumnKey.PendingContracts],
        ...{
          sorter: (a, b) => sortStr(a?.pendingContracts, b?.pendingContracts),
          render: (pendingContracts) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {pendingContracts}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.PaypalAddress]: {
        ...ColumnConfig[ColumnKey.PaypalAddress],
        ...{
          sorter: (a, b) => sortStr(a?.payPalAddress, b?.payPalAddress),
          render: (paypalAddress) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {paypalAddress}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.LastPaymentSent]: {
        ...ColumnConfig[ColumnKey.LastPaymentSent],
        ...{
          sorter: (a, b) => new Date(a[ColumnKey.LastPaymentSent]).getTime() - new Date(b[ColumnKey.LastPaymentSent]).getTime(),
          render: (lastPaymentSentDate) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {formatDate(lastPaymentSentDate)}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.Message]: ColumnConfig[ColumnKey.Message],
      [ColumnKey.Deliverables]: ColumnConfig[ColumnKey.Deliverables],
      [ColumnKey.Price]: {
        ...ColumnConfig[ColumnKey.Price],
        ...{
          sorter: (a, b) => a?.price - b?.price,
          render: (price) => (
            <span>
              {isNumber(price) ? numeral(price).format('0,0.[00]') : '-'}
            </span>
          ),
        },
      },
      [ColumnKey.NextDeadline]: {
        ...ColumnConfig[ColumnKey.NextDeadline],
        ...{
          sorter: (a, b) => new Date(a[ColumnKey.NextDeadline]).getTime() - new Date(b[ColumnKey.NextDeadline]).getTime(),
          render: (nextDeadLine) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {formatDate(nextDeadLine)}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.OpenDeliverables]: {
        ...ColumnConfig[ColumnKey.OpenDeliverables],
        ...{
          sorter: (a, b) => a[ColumnKey.OpenDeliverables] - b[ColumnKey.OpenDeliverables],
          render: (openDeliverables) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {openDeliverables}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.CompletedDeliverables]: {
        ...ColumnConfig[ColumnKey.CompletedDeliverables],
        ...{
          sorter: (a, b) => a[ColumnKey.CompletedDeliverables] - b[ColumnKey.CompletedDeliverables],
          render: (completedDeliverables) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {completedDeliverables}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.Address]: {
        ...ColumnConfig[ColumnKey.Address],
        ...{
          render: (member) => {
            const address1 = schemasByName && member && member.fields[schemasByName[ColumnKey.Address1]?.schemaId];
            const address2 = schemasByName && member && member.fields[schemasByName[ColumnKey.Address2]?.schemaId];
            const city = schemasByName && member && member.fields[schemasByName[ColumnKey.City]?.schemaId];
            const state = schemasByName && member && member.fields[schemasByName[ColumnKey.State]?.schemaId];
            const postalCode = schemasByName && member && member.fields[schemasByName[ColumnKey.PostalCode]?.schemaId];
            const country = schemasByName && member && member.fields[schemasByName[ColumnKey.Country]?.schemaId];

            const combinedAddress = `${address1 || ''} ${address2 || ''} ${city || ''} ${state || ''} ${postalCode || ''} ${country || ''}`;
            return (
              <EllipsisLabel
                tooltipPlacement="top"
                showTooltip
              >
                {combinedAddress}
              </EllipsisLabel>
            );
          },
          sorter: (a, b) => {
            const aAddress1 = schemasByName && a && a.fields[schemasByName[ColumnKey.Address1]?.schemaId];
            const aAddress2 = schemasByName && a && a.fields[schemasByName[ColumnKey.Address2]?.schemaId];
            const aCity = schemasByName && a && a.fields[schemasByName[ColumnKey.City]?.schemaId];
            const aState = schemasByName && a && a.fields[schemasByName[ColumnKey.State]?.schemaId];
            const aPostalCode = schemasByName && a && a.fields[schemasByName[ColumnKey.PostalCode]?.schemaId];
            const aCountry = schemasByName && a && a.fields[schemasByName[ColumnKey.Country]?.schemaId];

            const aCombinedAddress = `${aAddress1 || ''} ${aAddress2 || ''} ${aCity || ''} ${aState || ''} ${aPostalCode || ''} ${aCountry || ''}`;

            const bAddress1 = schemasByName && b && b.fields[schemasByName[ColumnKey.Address1]?.schemaId];
            const bAddress2 = schemasByName && b && b.fields[schemasByName[ColumnKey.Address2]?.schemaId];
            const bCity = schemasByName && b && b.fields[schemasByName[ColumnKey.City]?.schemaId];
            const bState = schemasByName && b && b.fields[schemasByName[ColumnKey.State]?.schemaId];
            const bPostalCode = schemasByName && b && b.fields[schemasByName[ColumnKey.PostalCode]?.schemaId];
            const bCountry = schemasByName && b && b.fields[schemasByName[ColumnKey.Country]?.schemaId];

            const bCombinedAddress = `${bAddress1 || ''} ${bAddress2 || ''} ${bCity || ''} ${bState || ''} ${bPostalCode || ''} ${bCountry || ''}`;
            return sortStr(aCombinedAddress, bCombinedAddress);
          },
        },
      },
      [ColumnKey.Address1]: {
        ...ColumnConfig[ColumnKey.Address1],
        ...{
          render: (member) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {schemasByName && member && member.fields[schemasByName[ColumnKey.Address1]?.schemaId]}
            </EllipsisLabel>
          ),
          sorter: (a, b) => sortStr(
            a?.fields[schemasByName[ColumnKey.Address1]?.schemaId],
            b?.fields[schemasByName[ColumnKey.Address1]?.schemaId],
          ),
        },
      },
      [ColumnKey.Address2]: {
        ...ColumnConfig[ColumnKey.Address2],
        ...{
          sorter: (a, b) => sortStr(
            a?.fields[schemasByName[ColumnKey.Address2]?.schemaId],
            b?.fields[schemasByName[ColumnKey.Address2]?.schemaId],
          ),
          render: (member) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {schemasByName && member && member.fields[schemasByName[ColumnKey.Address2]?.schemaId]}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.City]: {
        ...ColumnConfig[ColumnKey.City],
        ...{
          sorter: (a, b) => sortStr(
            a?.fields[schemasByName[ColumnKey.City]?.schemaId],
            b?.fields[schemasByName[ColumnKey.City]?.schemaId],
          ),
          render: (member) => (
            <span>
              {schemasByName && member && member.fields[schemasByName[ColumnKey.City]?.schemaId]}
            </span>
          ),
        },
      },
      [ColumnKey.State]: {
        ...ColumnConfig[ColumnKey.State],
        ...{
          sorter: (a, b) => sortStr(
            a?.fields[schemasByName[ColumnKey.State]?.schemaId],
            b?.fields[schemasByName[ColumnKey.State]?.schemaId],
          ),
          render: (member) => (
            <span>
              {schemasByName && member && member.fields[schemasByName[ColumnKey.State]?.schemaId]}
            </span>
          ),
        },
      },
      [ColumnKey.PostalCode]: {
        ...ColumnConfig[ColumnKey.PostalCode],
        ...{
          sorter: (a, b) => sortStr(
            a?.fields[schemasByName[ColumnKey.PostalCode]?.schemaId],
            b?.fields[schemasByName[ColumnKey.PostalCode]?.schemaId],
          ),
          render: (member) => (
            <span>
              {schemasByName && member && member.fields[schemasByName[ColumnKey.PostalCode]?.schemaId]}
            </span>
          ),
        },
      },
      [ColumnKey.Country]: {
        ...ColumnConfig[ColumnKey.Country],
        ...{
          sorter: (a, b) => sortStr(
            a?.fields[schemasByName[ColumnKey.Country]?.schemaId],
            b?.fields[schemasByName[ColumnKey.Country]?.schemaId],
          ),
          render: (member) => (
            <span>
              {schemasByName && member && member.fields[schemasByName[ColumnKey.Country]?.schemaId]}
            </span>
          ),
        },
      },
      [ColumnKey.OrderStatus]: {
        ...ColumnConfig[ColumnKey.OrderStatus],
        ...{
          sorter: (a, b) => sortStr(a.status, b.status),
          render: (status) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {status}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.Quantity]: {
        ...ColumnConfig[ColumnKey.Quantity],
        ...{
          sorter: (a, b) => a.quantity - b.quantity,
        },
      },
      [ColumnKey.Products]: {
        ...ColumnConfig[ColumnKey.Products],
        ...{
          sorter: (a, b) => sortStr(a?.products?.join(', '), b?.products?.join(', ')),
          render: (products) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {!isEmpty(products) && products.join(', ')}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.ShipmentStatus]: {
        ...ColumnConfig[ColumnKey.ShipmentStatus],
        ...{
          sorter: (a, b) => sortStr(a?.shimpentStatus, b?.shimpentStatus),
          render: (status) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {status}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.TrackingNumber]: {
        ...ColumnConfig[ColumnKey.TrackingNumber],
        ...{
          sorter: (a, b) => sortStr(a?.trackingNumber, b?.trackingNumber),
          render: (trackingNumber) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {trackingNumber}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.PromoCode]: {
        ...ColumnConfig[ColumnKey.PromoCode],
        ...{
          sorter: (a, b) => sortStr(a?.promoCode, b?.promoCode),
          render: (promoCode) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {promoCode}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.PromoCodeStatus]: {
        ...ColumnConfig[ColumnKey.PromoCodeStatus],
        ...{
          sorter: (a, b) => sortStr(a?.promoCodeStatus, b?.promoCodeStatus),
          render: (status) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {status}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.AffiliateLink]: {
        ...ColumnConfig[ColumnKey.AffiliateLink],
        ...{
          sorter: (a, b) => sortStr(a?.affiliateLink, b?.affiliateLink),
          render: (link) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              <a target="_blank" href={link}>{link}</a>
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.ShippingAddress]: {
        ...ColumnConfig[ColumnKey.ShippingAddress],
        ...{
          sorter: (a, b) => sortStr(a?.address, b?.address),
          render: (address) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {address}
            </EllipsisLabel>
          ),
        },
      },
      [ColumnKey.ShippingCountry]: {
        ...ColumnConfig[ColumnKey.ShippingCountry],
        ...{
          sorter: (a, b) => sortStr(a?.country, b?.country),
          render: (country) => (
            <EllipsisLabel
              tooltipPlacement="top"
              showTooltip
            >
              {country}
            </EllipsisLabel>
          ),
        },
      },
  }), [schemasByName]);

  const sendProductOrderTaskColumns = useMemo(() => {
    let columns = [];
    if (isPFAColumnsEnabled) {
      columns = [specialColumns[ColumnKey.Address]];
    } else {
      columns = [
        specialColumns[ColumnKey.Address1],
        specialColumns[ColumnKey.Address2],
        specialColumns[ColumnKey.City],
        specialColumns[ColumnKey.State],
        specialColumns[ColumnKey.PostalCode],
        specialColumns[ColumnKey.Country],
      ];
    }
    return columns;
  }, [
    specialColumns,
    isPFAColumnsEnabled,
  ]);

  const reviewOrderRequestTaskColumns = useMemo(() => {
    const columns = [
      specialColumns[ColumnKey.ShippingAddress],
      specialColumns[ColumnKey.ShippingCountry],
    ];

    if (isAutomatedApprovalCPSEnabled) {
      columns.push(specialColumns[ColumnKey.OrderStatus]);
    }

    return columns;
  }, [
    specialColumns,
    isAutomatedApprovalCPSEnabled,
  ]);

  const taskSpecialColumns = {
    [Task.MemberSignContractTask]: [
      specialColumns[ColumnKey.PendingContracts],
    ],
    [Task.BrandSignContractTask]: [
      specialColumns[ColumnKey.PendingContracts],
    ],
    [Task.SendPaymentTask]: [
      specialColumns[ColumnKey.PaypalAddress],
      specialColumns[ColumnKey.LastPaymentSent],
    ],
    [Task.WaitingForAgreementTask]: [
      specialColumns[ColumnKey.Message],
    ],
    [Task.FixErrorSendingTermsTask]: [
      specialColumns[ColumnKey.Message],
    ],
    [Task.ReviewTermsTask]: [
      specialColumns[ColumnKey.Deliverables],
      specialColumns[ColumnKey.Price],
    ],
    [Task.WaitingForContentTask]: [
      specialColumns[ColumnKey.NextDeadline],
      specialColumns[ColumnKey.OpenDeliverables],
      specialColumns[ColumnKey.CompletedDeliverables],
    ],
    [Task.ReviewContentTask]: [
      specialColumns[ColumnKey.NextDeadline],
      specialColumns[ColumnKey.OpenDeliverables],
      specialColumns[ColumnKey.CompletedDeliverables],
    ],
    [Task.WaitingForPostsTask]: [
      specialColumns[ColumnKey.NextDeadline],
      specialColumns[ColumnKey.OpenDeliverables],
      specialColumns[ColumnKey.CompletedDeliverables],
    ],
    [Task.ConfirmAddressesTask]: [
      specialColumns[ColumnKey.Address1],
      specialColumns[ColumnKey.Address2],
      specialColumns[ColumnKey.City],
      specialColumns[ColumnKey.State],
      specialColumns[ColumnKey.PostalCode],
      specialColumns[ColumnKey.Country],
    ],
    [Task.WaitingForAddressConfirmationTask]: [
      specialColumns[ColumnKey.Address1],
      specialColumns[ColumnKey.Address2],
      specialColumns[ColumnKey.City],
      specialColumns[ColumnKey.State],
      specialColumns[ColumnKey.PostalCode],
      specialColumns[ColumnKey.Country],
    ],
    [Task.SubmitOrderTask]: sendProductOrderTaskColumns,
    [Task.ReviewOrderRequestTask]: reviewOrderRequestTaskColumns,
    [Task.OfflineReviewOrderRequestTask]: [
      specialColumns[ColumnKey.ShippingAddress],
      specialColumns[ColumnKey.ShippingCountry],
      specialColumns[ColumnKey.Quantity],
      specialColumns[ColumnKey.Products],
    ],
    [Task.OfflineFulfillOrderTask]: [
      {
        ...specialColumns[ColumnKey.Quantity],
        ...{ title: 'Original Quantity' },
      },
      {
        ...specialColumns[ColumnKey.Products],
        ...{ title: 'Original Product Selected' },
      },
    ],
    [Task.OfflineOrderInTransitTask]: [
      specialColumns[ColumnKey.ShipmentStatus],
      specialColumns[ColumnKey.TrackingNumber],
    ],
    [Task.FixErrorPromoTask]: [
      specialColumns[ColumnKey.PromoCode],
      specialColumns[ColumnKey.PromoCodeStatus],
    ],
    [Task.SendLinkTask]: [
      specialColumns[ColumnKey.AffiliateLink],
    ],
    [Task.SendPromoTask]: [
      specialColumns[ColumnKey.PromoCode],
    ],
    [Task.SendLinkAndPromoTask]: [
      specialColumns[ColumnKey.AffiliateLink],
      specialColumns[ColumnKey.PromoCode],
    ],
  };

  let additionalColumns = [];
  if (task && !isEmpty(taskSpecialColumns[task.taskId])) {
    additionalColumns = taskSpecialColumns[task.taskId];
  }

  const filteredStartColumns = filter(
    startDefaultColumns,
    ((col: MemberTableRow) => columnsToDisplay.includes(col.key)),
  );
  const filteredEndColumns = filter(
    endDefaultColumns,
    ((col: MemberTableRow) => columnsToDisplay.includes(col.key)),
  );

  const columns: ITableColumn<MemberTableRow> = [
    ...filteredStartColumns,
    ...additionalColumns,
    ...filteredEndColumns,
  ];

  return columns;
};
