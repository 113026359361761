import * as React from 'react';
import {
  ITableCellRenderContext,
} from '@components';

import { TokenCellSelection } from './TokenCellSelection';

export const renderTokenSelectionCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: any[],
  options: string[],
  context: ITableCellRenderContext,
  isSingle: boolean = false,
) => (
  <TokenCellSelection
    values={values}
    options={options}
    isSingle={isSingle}
    onChange={context.onChangeCellValue}
  />
  );
