import { useMemberSearchQueryVariables as useDefault } from '@frontend/app/components/MemberTable/hooks';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';

export const useMemberSearchQueryVariables = () => {
  const {
    searchQuery,
    columnVisibility,
  } = useMemberListContext() || {};
  return useDefault(searchQuery, columnVisibility);
};
