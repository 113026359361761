import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import {
  isEmpty, isNull, size, first,
} from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { InfoNotice, SetupNotice } from '@affiliates/components';
import {
  useApolloClient,
  useCreateAdvertiserMutation,
  useGetAdvertiserSignupDate,
  useGetOffersBySearchQuery,
} from '@affiliates/hooks';
import { OFFER_TYPE, OFFER_PROMO_CODE_STATUS } from '@affiliates/types/globalTypes';
import {
  isLinkTask, isPromoTask, isSendTask, isSetupOfferTask, WorkflowTask,
} from '@affiliates/utils';
import { logger } from '@common';
import { useAuth } from '@frontend/context/authContext';
import { SetupOffers } from '../SetupOffers/SetupOffers';
import { FailedNotice } from '../../components/MemberTable/FailedNotice';
import { PromoCodeErrorMessage } from '../../components/MemberTable/PromoCodeErrorMessage';

const { useEffect, useState, useMemo } = React;
interface IProps {
  programId: number;
  workletSpecUri: string;
  taskId: string;
  memberIds: number[];
  clientName?: string;
}

const errorsStatus = [
  OFFER_PROMO_CODE_STATUS.FAILED_DUPLICATE,
  OFFER_PROMO_CODE_STATUS.FAILED_OTHER,
  OFFER_PROMO_CODE_STATUS.FAILED_TOO_LONG,
];

const _WorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
  const {
    programId, workletSpecUri, taskId, clientName, memberIds,
  } = props;

  const [showSelectOffers, setSelectOffers] = useState<boolean>(false);

  const isLink = isLinkTask(taskId);
  const isPromo = isPromoTask(taskId);
  const isSend = isSendTask(taskId);

  let offerType: OFFER_TYPE | undefined = undefined;
  if (isLink) {
    offerType = OFFER_TYPE.LINK;
  } else if (isPromo) {
    offerType = OFFER_TYPE.PROMO_CODE;
  }

  const advertiserSignupQuery = useGetAdvertiserSignupDate();
  const missingAdvertiser = (
    advertiserSignupQuery.loading
    || !!advertiserSignupQuery.error
    || !advertiserSignupQuery.data
  );
  const [createAdvertiser] = useCreateAdvertiserMutation({
    onCompleted: advertiserSignupQuery.refetch,
  });

  useEffect(() => {
    if (
      advertiserSignupQuery.loading
      || advertiserSignupQuery.error
      || !isNull(advertiserSignupQuery.data.advertiser)
    ) {
      return;
    }
    // create the advertiser for the first time
    createAdvertiser({
      variables: {
        data: {
          name: clientName,
        },
      },
    });
  }, [
    advertiserSignupQuery,
    clientName,
    createAdvertiser,
  ]);

  const offersQuery = useGetOffersBySearchQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        programId,
        type: offerType,
      },
    },
    skip: missingAdvertiser,
  });
  const offer = useMemo(() => (offersQuery.data ? first(offersQuery.data.offers) : null), [offersQuery.data]);
  const promoCodeErrors = useMemo(() => {
    switch (offerType) {
      case OFFER_TYPE.PROMO_CODE: {
        const promo = offer ? first(offer.promos) : null;
        const affiliates = promo ? promo.affiliates : [];
        const errors = affiliates
          .filter(({ affiliate: { memberId } }) => memberIds.includes(memberId))
          .filter(({ status }) => errorsStatus.includes(status))
          .map(({ status }) => status);
        return errors;
      }
      case OFFER_TYPE.LINK:
      default:
        return [];
    }
  }, [offerType, offer, memberIds]);

  if (!(isLink || isPromo || isSend)) {
    logger.error(`STA does not recognize taskId ${taskId}`);
    return null;
  }

  if (
    advertiserSignupQuery.loading
    || (!advertiserSignupQuery.error && !advertiserSignupQuery.data?.advertiser)
  ) {
    return null;
  }

  if (advertiserSignupQuery.error) {
    logger.error(advertiserSignupQuery.error);
    return <Notice type="error" message="Oops! Something went wrong. Please refresh or try again later." />;
  }

  if (offersQuery.loading) {
    return null;
  }

  if (offersQuery.error || !offersQuery.data) {
    return <Notice type="error" message="Oops! Something went wrong. Please refresh or try again later." />;
  }

  const isSetup = isSetupOfferTask(taskId);
  if (
    (!isSetup && isEmpty(offersQuery.data.offers))
    || (taskId === WorkflowTask.SEND_LINK_AND_PROMO_TASK && size(offersQuery.data.offers) !== 2)
  ) {
    return null;
  }

  if (isSetup && isEmpty(offersQuery.data.offers)) {
    return (
      <>
        <SetupNotice programId={programId} offerType={offerType} onClick={() => setSelectOffers(true)} />
        {showSelectOffers
          && (
            <SetupOffers
              offerType={offerType}
              visible={showSelectOffers}
              programId={programId}
              setVisible={setSelectOffers}
              refresh={offersQuery.refetch}
              workletSpecUri={workletSpecUri}
              taskId={taskId}
              isSetup
            />
          )}
      </>
    );
  }

  return (
    <>
      <InfoNotice
        offers={offersQuery.data.offers}
        programId={programId}
        workletSpecUri={workletSpecUri}
        taskId={taskId}
      />
      <FailedNotice
        message={<PromoCodeErrorMessage errors={promoCodeErrors} />}
        visible={!isEmpty(promoCodeErrors)}
        hideActions
      />
    </>
  );
};

export const WorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
  const { clientInfo } = useAuth();
  const apolloClient = useApolloClient();

  return (
    <ApolloProvider client={apolloClient}>
      <_WorkflowNotice {...props} clientName={clientInfo.name} />
    </ApolloProvider>
  );
};
