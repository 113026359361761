import * as React from 'react';
import cx from 'classnames';

import {
  Typography,
  Space,
  Tag,
  Alert,
} from '@revfluence/fresh';
import { ContentType } from '@frontend/app/types/globalTypes';
import { getContentTypeLabel } from '@frontend/applications/TermsApp/components/BulkTerms/utils';
import ContentGuidelineIcon from '@frontend/applications/TermsApp/components//shared/ContentGuidelineIcon';

import styles from './ContentTypeList.scss';

const { Text } = Typography;
const { useCallback } = React;

interface IProps {
  contentTypes: ContentType[];
  onAddContentType: (contentType: ContentType) => void;
  onRemoveContentType: (contentType: ContentType) => void;
}

export const ALLOWEDCONTENTTYPES: Array<ContentType> = [
  ContentType.INSTAGRAM,
  ContentType.INSTAGRAM_REEL,
  ContentType.INSTAGRAM_STORY,
  ContentType.TIKTOK_VIDEO,
  ContentType.PINTEREST,
  ContentType.YOUTUBE_MENTION,
  ContentType.YOUTUBE_DEDICATED,
  ContentType.ADDITIONAL_IMAGES,
  ContentType.ADDITIONAL_VIDEOS,
  ContentType.OTHER,
];

const ContentTypeList: React.FC<IProps> = React.memo((props) => {
  const {
    contentTypes,
    onAddContentType,
    onRemoveContentType,
  } = props;

  const isContentTypeSelected = useCallback(
    (contentType: ContentType) => contentTypes.includes(contentType),
    [
      contentTypes,
    ],
  );

  return (
    <div className={styles.ContentTypeList}>
      <Space
        direction="vertical"
        className={styles.space}
      >
        <Text weight="semibold">
          Content Types
        </Text>
        <Typography.Text className={styles.subtitle}>
          Creators can choose to submit any of the selected content types.
        </Typography.Text>
        <Space
          direction="horizontal"
          wrap
        >
          {
            ALLOWEDCONTENTTYPES
              .map((contentTypeKey) => (
                <Tag
                  className={cx(styles.tag, {
                    [styles.selected]: isContentTypeSelected(ContentType[contentTypeKey]),
                  })}
                  key={contentTypeKey}
                  icon={<ContentGuidelineIcon contentGuidelineType={ContentType[contentTypeKey]} />}
                  onClick={
                    () => (isContentTypeSelected(ContentType[contentTypeKey])
                    ? onRemoveContentType(ContentType[contentTypeKey])
                    : onAddContentType(ContentType[contentTypeKey]))
                  }
                >
                  {
                    getContentTypeLabel(1, ContentType[contentTypeKey])
                  }
                </Tag>
            ))
          }
          { contentTypes.length === 0 && (
            <Alert
              message="At least one accepted post type must be assigned to create a deliverable"
              type="error"
            />
          )}
        </Space>
      </Space>
    </div>
  );
});

ContentTypeList.displayName = 'ContentTypeList';

export default ContentTypeList;
