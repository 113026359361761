import * as React from 'react';

import { Skeleton, Space } from '@revfluence/fresh';

import { MemberActivities } from '../MemberActivities';
import { ActivityFilters, IMemberDetails } from '../types';

interface IProps {
  member: IMemberDetails;
  loading?: boolean;
  selectedFilter: ActivityFilters
}

export const Activities: React.FC<IProps> = (props: IProps) => {
  const { loading, member, selectedFilter } = props;

  if (loading || !member) {
    return renderInboxSkeleton();
  }

  return (
    <MemberActivities
      member={member}
      renderLoading={renderInboxSkeleton}
      selectedFilter={selectedFilter}
    />
  );
};

const renderInboxSkeleton = () => (
  <Space size="middle">
    {new Array(5).fill(<Skeleton active />)}
  </Space>
);
