import * as React from 'react';
import cx from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
 chain, countBy, filter, isEmpty, map, orderBy, uniq,
} from 'lodash';
import { Select } from '@revfluence/fresh';

import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { LazyImage, LoadSpinner } from '@components';
import { Card, Empty, Typography } from 'antd';

import {
 useClientFeatureEnabled, useCommunitiesQuery, useGetAllContentApprovalManagerProjectsQuery, useGetCurrentClient, useGetProfile, useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';

import { useEffect, useState } from 'react';
import { ClientFeature } from '@frontend/app/constants';
import { generateMemberSearchGraphQLQuery } from '@frontend/app/queries';
import { useApolloClient } from '@apollo/client';
import { findFieldByName, getFilteredGroups } from '../Groups/GcrUtils';
import styles from './ProgramList.scss';

interface IProps {
  className?: string;
}

const { useCallback, useMemo } = React;
const { Title } = Typography;
const { Meta } = Card;

/**
 * @type {React.FC}
 */
export const ProgramList: React.FC<IProps> = React.memo((props) => {
  const apolloClient = useApolloClient();
  const history = useHistory();
  const match = useRouteMatch();
  const { profile } = useGetProfile();
  const { client } = useGetCurrentClient();
  const isApplicantReview = useClientFeatureEnabled(ClientFeature.APPLICANT_REVIEW);
  const [projectStatus, setProjectStatus] = useState(ProjectStatus.Active);
  const [selectedYear, setSelectedYear] = useState('All Time');
  const [showCommunities, setShowCommunities] = useState(false);
  const [communities, setCommunities] = useState([]);
  const { data: { schemas } = {} } = useMemberFieldSchemasQuery();

  useEffect(() => {
    // 1. Feature enabled to show groups
    // 2. client -> user role should be manage content approver
    const clientId = client.id;
    const {
      auth0User: {
        appMetadata: {
          clients,
        },
      },
    } = profile;

    const role = clients[clientId].roles[0];
    setShowCommunities(role === 'manager:content_approver');
  }, [client, profile]);

  const goToProgramDetail = useCallback((programId: number) => {
    history.push(`${match.url}/${programId}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const { data: { projects } = {}, loading: projectsLoading, refetch: refetchProjects } = useGetAllContentApprovalManagerProjectsQuery({
    variables: {
      archived: false,
    },
  });

  const yearsOptions = useMemo(() => {
    const years = uniq(map(projects, (project) => new Date(project.createdDate).getFullYear()));
    years.sort((a, b) => b - a);
    const yearsOptions = map(years, (year) => ({
      label: year.toString(),
      value: year.toString(),
    }));
    yearsOptions.unshift({
      label: 'All Time',
      value: 'All Time',
    });
    return yearsOptions;
  }, [projects]);

  const processedProjects = chain(projects).filter((project) => {
    if (selectedYear === 'All Time') {
      return true;
    }
    return new Date(project.createdDate).getFullYear().toString() == selectedYear;
  })
  .orderBy(['title', 'id'], ['asc', 'asc'])
  .value();

  const { data: communityQueryData, loading: communityQueryLoading } = useCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const handleChangeProjectsStatus = useCallback((projectStatus) => {
    setProjectStatus(projectStatus);
    setSelectedYear('All Time');
    refetchProjects({
      archived: projectStatus === ProjectStatus.Archived,
    });
  }, [setProjectStatus, refetchProjects, setSelectedYear]);

  const handleSelectedYearChange = useCallback((year) => {
    setSelectedYear(year);
  }, [setSelectedYear]);

  const getGroupsWithPending = useCallback(async (groups, schemas) => {
    const communitiesWithCount = [];
    await Promise.all(map(groups, async (community) => {
      const { data } = await apolloClient.query({
        query: generateMemberSearchGraphQLQuery(),
        variables: {
          includeActivations: false,
          includeTags: false,
          includeCommunities: false,
          includeOwners: false,
          includePrograms: false,
          includeHighlights: false,
          includeLastMessage: false,
          includeProgramMemberships: false,
          includeProgramMembershipsLogs: false,
          query: {
              isContact: true,
              communityIds: [community.id],
          },
          take: 200,
          skip: 0,
        },
      });
      const brandApprovalField = findFieldByName('brand approval', schemas);
      const { members } = data;
      const pendingMemberCount = countBy(members, (member) => !member.fields[brandApprovalField.id]);
      communitiesWithCount.push({ pendingMemberCount: pendingMemberCount.true || 0, ...community });
    }));
    return communitiesWithCount;
}, [apolloClient]);

  useEffect(() => {
    if (communityQueryData && schemas) {
      const data = communityQueryData.communities;
      const orderedGroups = orderBy(
        filter(data, (_, idx) => idx !== null),
        ['memberCount', 'title', 'id'],
        ['desc', 'asc', 'asc'],
      );
      const groupWithTitles = getFilteredGroups(orderedGroups);
      getGroupsWithPending(groupWithTitles, schemas).then((response) => {
        setCommunities(response);
      }).catch(() => null);
    }
  }, [communityQueryData, schemas, getGroupsWithPending]);

  const goToGroupDetail = useCallback((groupId: number) => {
    history.push(`groups/${groupId}`);
  }, [history]);

  const FALLBACK_GROUP_URL = 'https://storage.googleapis.com/aspirex-static-files/home/groups.png';
  return (
    <div className={cx(styles.ProgramList, props.className)}>
      <div className={styles.projectsHeader}>
        <Title className={styles.title} level={3}>Projects</Title>
        <div className={styles.filters}>
          <Select
            defaultValue={ProjectStatus.Active}
            key="filter_project_select"
            onChange={handleChangeProjectsStatus}
            defaultActiveFirstOption
            className={styles.projectStatusSelect}
            options={[
              {
                label: 'Active Projects',
                value: ProjectStatus.Active,
              },
              {
                label: 'Archived Projects',
                value: ProjectStatus.Archived,
              },
            ]}
          />
          <Select
            key="project_year_select"
            onChange={handleSelectedYearChange}
            className={styles.yearSelect}
            options={yearsOptions}
            value={selectedYear}
          />
        </div>
      </div>
      {projectsLoading && <LoadSpinner />}
      {!projectsLoading && isEmpty(processedProjects) && (
        <Empty description="No projects found." />
      )}
      {!projectsLoading && !isEmpty(processedProjects) && (
      <div className={styles.content}>
        {map(processedProjects, (project) => (
          <div className={cx(
            styles.mainCard, {
              [styles.archived]: projectStatus === ProjectStatus.Archived,
            },
          )}
          >
            <Card
              key={project.id}
              cover={
                <LazyImage className={styles.image} src={project.splashImageUrl} />
                  }
              hoverable
              onClick={() => goToProgramDetail(project.id)}
            >
              <Meta
                title={project.title}
                description={`${project.activeMemberCount} Active Member${project.activeMemberCount > 1 ? 's' : ''}`}
              />
            </Card>
          </div>

              ))}
      </div>
      )}
      {/* Display all groups in case of manage-content approver */}
      { isApplicantReview && !isEmpty(communities) && showCommunities && (communityQueryLoading ? <LoadSpinner />
        : (
          <>
            <Title className={styles.title} level={3}>Applicants</Title>
            <div className={styles.content}>
              {
                communities.map((group) => (
                  <div key={`group-${group.id}`} className={styles.mainCard}>
                    <Card
                      key={`group-${group.id}`}
                      cover={
                        <LazyImage src={group.splashImageUrl || FALLBACK_GROUP_URL} />
                    }
                      onClick={() => goToGroupDetail(group.id)}
                    >
                      <Meta
                        key={`group-${group.id}`}
                        title={group.newTitle}
                        description={` ${group.pendingMemberCount} of ${group.memberCount} Members pending Approval`}
                      />
                    </Card>
                  </div>
                ))
              }
            </div>
          </>
        )
)}
    </div>
  );
});

ProgramList.displayName = 'ProgramList';
