/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fb3w_157 {
  display: flex;
}

._justify-content-space-between_1fb3w_161 {
  justify-content: space-between;
}

._tabular-nums_1fb3w_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fb3w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Folder_1fb3w_178 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
._Folder_1fb3w_178 ._parentItem_1fb3w_184 ._Title_1fb3w_184 {
  color: #1a1818;
}
._Folder_1fb3w_178 ._caret_1fb3w_187 {
  margin-left: 0.5rem;
  color: #1a1818;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._Folder_1fb3w_178 ._caret_1fb3w_187._expanded_1fb3w_192 {
  transform: rotate(-180deg);
}
._Folder_1fb3w_178 ._items_1fb3w_195 {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-duration: 0.25s;
}
._Folder_1fb3w_178 ._FolderItem_1fb3w_199 {
  height: 2rem;
  color: #8f8d91;
}
._Folder_1fb3w_178 ._FolderItem_1fb3w_199:last-of-type {
  margin-bottom: 0.5rem;
}

._FolderItem_1fb3w_199 {
  height: 2.5rem;
  margin: 0 0.5rem 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.8125rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._FolderItem_1fb3w_199:hover {
  background-color: #fafafa;
}
._FolderItem_1fb3w_199._active_1fb3w_219 {
  background-color: #eff5f9;
}
._FolderItem_1fb3w_199._active_1fb3w_219 ._Title_1fb3w_184 ._title_1fb3w_222,
._FolderItem_1fb3w_199._active_1fb3w_219 ._Title_1fb3w_184 ._accessory_1fb3w_223 {
  color: #0075ff;
}
._FolderItem_1fb3w_199._active_1fb3w_219:hover {
  background-color: #e6edf2;
}
._FolderItem_1fb3w_199._indent_1fb3w_229 {
  padding-left: 1.75rem;
}

._Title_1fb3w_184 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  padding: 0 0.25rem;
}
._Title_1fb3w_184 ._icon_1fb3w_242 {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  min-width: 1.25rem;
  max-width: 1.25rem;
  margin: 0 0.25rem;
}
._Title_1fb3w_184 ._title_1fb3w_222 {
  flex: 1;
}
._Title_1fb3w_184 ._accessory_1fb3w_223 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  color: #8f8d91;
}