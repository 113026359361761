import { useCallback, useMemo, useReducer } from 'react';
import {
  filter,
  groupBy,
  isEmpty,
  map,
} from 'lodash';
import moment from 'moment';

import { logger } from '@common';
import {
  OFFER_SOURCE,
  UPSERT_PROMO_STA_TASK_ID,
} from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { reducer, TActions, TState } from './state';
import { getDefaultState } from './state/getDefaultState';
import {
  ACTIVE_DATE_FORMAT,
  ACTIVE_TIME_FORMAT,
  ForceCheckInVariables,
  TAddMembersProps,
} from '../../types';

type TReturn = Readonly<{
  actions: TActions;
  state: TState;
}>;

export const useState = (props: TAddMembersProps): TReturn => {
  const {
    offerId,
    offerSource,
    isUngrouped,
    onSave,
    workItems = [],
  } = props;
  const defaultState = useMemo(() => getDefaultState(), []);
  const [state, dispatch] = useReducer(reducer, defaultState);
  const workItemByMemberIdDictionary = useMemo(() => groupBy(workItems, (workItem) => workItem.data.memberId), [workItems]);
  const actions: TActions = {
    dismissCloseConfirmation: useCallback(() => {
      dispatch({
        showCloseConfirmation: false,
        type: 'UPDATE SHOW CLOSE CONFIRMATION',
      });
    }, []),
    dismissNoMembersWarning: useCallback(() => {
      dispatch({
        type: 'DISMISS NO MEMBERS WARNING',
      });
    }, []),
    populateMembers: useCallback((members) => {
      switch (props.offerSource) {
        case OFFER_SOURCE.SHOPIFY:
          dispatch({
            codeGenerationStrategy: props.codeGenerationStrategy,
            members,
            promoCodeSuffix: props.promoCodeSuffix,
            promoCodePrefix: props.promoCodePrefix,
            offerSource: props.offerSource,
            type: 'POPULATE MEMBERS',
            defaultPayoutId: props.defaultPayoutId,
            isWorkflow: props.isWorkflow,
          });
          break;
        case OFFER_SOURCE.TUNE:
          dispatch({
            members,
            offerSource: props.offerSource,
            type: 'POPULATE MEMBERS',
            defaultPayoutId: props.defaultPayoutId,
            isWorkflow: props.isWorkflow,
          });
          break;
      }
    }, [props]),
    populatePrograms: useCallback((programs) => {
      dispatch({
        programs,
        type: 'POPULATE PROGRAMS',
      });
    }, []),
    reset: useCallback(() => {
      dispatch({
        type: 'RESET DEFAULT STATE',
      });
    }, []),
    save: useCallback((nextStep: number) => {
      dispatch({
        saving: true,
        type: 'UPDATE SAVING STATE',
      });
      const startDate = moment(
        `${state.activeDates.startDate} ${state.activeDates.startTime}`,
        `${ACTIVE_DATE_FORMAT} ${ACTIVE_TIME_FORMAT}`,
      ).toISOString(false);
      const endDate = moment(
        `${state.activeDates.endDate} ${state.activeDates.endTime}`,
        `${ACTIVE_DATE_FORMAT} ${ACTIVE_TIME_FORMAT}`,
      ).toISOString(false);
      const programIds = map(state.selectedPrograms, (p) => p.id.toString());
      const selectedMembers = filter(state.members, (m) => m.selected);
      let variables = null;
      let forceCheckInVariables: ForceCheckInVariables;
      switch (offerSource) {
        case OFFER_SOURCE.SHOPIFY: {
          if (!isEmpty(selectedMembers)) {
            variables = {
              affiliates: selectedMembers.map((m) => ({
                memberId: m.id,
                desiredPromoCode: m.code,
                payoutId: m.payoutId,
                startDate: isUngrouped ? m.startDate : null,
                endDate: isUngrouped ? m.endDate : null,
              })),
              id: offerId,
              endDate,
              startDate,
            };
            if (props.isWorkflow) {
              variables.affiliates = variables.affiliates.map((affiliate) => {
                const [workItem] = workItemByMemberIdDictionary[affiliate.memberId];
                return ({
                  ...affiliate,
                  workItemId: workItem?.id,
                  workflowTask: UPSERT_PROMO_STA_TASK_ID.generate_promo_task,
                });
              });
            }
          }

          const forceCheckInMembers = filter(state.members, (m) => m.forceCheckIn);
          if (!isEmpty(forceCheckInMembers)) {
            forceCheckInVariables = {
              offerId,
              forceCheckInAffiliates: forceCheckInMembers.map((member) => {
                const [workItem] = workItemByMemberIdDictionary[member.id];
                return {
                  memberId: member.id,
                  workItemId: workItem?.id,
                  workflowTask: UPSERT_PROMO_STA_TASK_ID.generate_promo_task,
                };
              }),
            };
          }
          break;
        }
        case OFFER_SOURCE.TUNE: {
          if (!isEmpty(selectedMembers)) {
            variables = {
              affiliates: selectedMembers.map((m) => ({
                deepLink: m.deepLink?.link,
                memberId: m.id,
                payoutId: m.payoutId,
              })),
              id: offerId,
            };
            if (props.isWorkflow) {
              variables.affiliates = variables.affiliates.map((affiliate) => {
                const [workItem] = workItemByMemberIdDictionary[affiliate.memberId];
                return ({
                  ...affiliate,
                  workItemId: workItem?.id,
                });
              });
            }
          }

          const forceCheckInMembers = filter(state.members, (m) => m.forceCheckIn);
          if (!isEmpty(forceCheckInMembers)) {
            forceCheckInVariables = {
              offerId,
              forceCheckInAffiliates: forceCheckInMembers.map((member) => {
                const [workItem] = workItemByMemberIdDictionary[member.id];
                return {
                  memberId: member.id,
                  workItemId: workItem?.id,
                };
              }),
            };
          }
          break;
        }
      }

      onSave(variables, programIds, forceCheckInVariables)
        .then(() => {
          dispatch({
            saving: false,
            type: 'UPDATE SAVING STATE',
          });
          dispatch({
            step: nextStep,
            type: 'SET CURRENT STEP',
          });
        })
        .catch((error) => {
          dispatch({
            saving: false,
            type: 'UPDATE SAVING STATE',
          });
          dispatch({
            error,
            type: 'SET ERROR',
          });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      offerId,
      offerSource,
      onSave,
      state.activeDates,
      state.members,
      state.selectedPrograms,
    ]),
    selectMembers: useCallback((members) => {
      dispatch({
        members,
        type: 'SELECT MEMBERS',
      });
    }, []),
    selectPrograms: useCallback((programs) => {
      dispatch({
        programs,
        type: 'SELECT PROGRAMS',
      });
    }, []),
    setCurrentStep: useCallback((step: number) => {
      dispatch({
        step,
        type: 'SET CURRENT STEP',
      });
    }, []),
    setError: useCallback((error) => {
      logger.error(error);
      dispatch({
        error,
        type: 'SET ERROR',
      });
    }, []),
    showCloseConfirmation: useCallback(() => {
      dispatch({
        showCloseConfirmation: true,
        type: 'UPDATE SHOW CLOSE CONFIRMATION',
      });
    }, []),
    updateActiveDateField: useCallback((field, value) => {
      dispatch({
        field,
        type: 'UPDATE DATE FIELD',
        value,
      });
    }, []),
    updateMemberCode: useCallback((memberId, field, code) => {
      switch (field) {
        case 'code':
          dispatch({
            code,
            memberId: Number(memberId),
            type: 'UPDATE MEMBER CODE',
          });
          break;
      }
    }, []),
    updateMemberPayoutId: useCallback((memberId, field, payoutId) => {
      switch (field) {
        case 'payoutId':
          dispatch({
            payoutId,
            memberId: Number(memberId),
            type: 'UPDATE MEMBER PAYOUT_ID',
          });
          break;
      }
    }, []),
    updateMemberDeepLink: useCallback((offerLink, domains, isCreatorDeepLinkAllowed) => (memberId, deepLink, focused) => {
      dispatch({
        deepLink,
        offerLink,
        memberId: Number(memberId),
        type: 'UPDATE MEMBER DEEP LINK',
        focused,
        domains,
        isCreatorDeepLinkAllowed,
      });
    }, []),
    updateBulkPayoutId: useCallback((payoutId) => {
      dispatch({
        type: 'BULK PAYOUT ID',
        payoutId: Number(payoutId),
      });
    }, []),
    updateBulkActiveDate: useCallback((field: string, value: Date) => {
      dispatch({
        type: 'BULK ACTIVE DATE',
        field,
        value,
      });
    }, []),
    updateMemberActiveDate: useCallback((memberId: number, field: string, value: Date) => {
      dispatch({
        type: 'UPDATE MEMBER ACTIVE DATE',
        memberId,
        field,
        value,
      });
    }, []),
    updateShowEndDate: useCallback((showEndDate: boolean) => {
      dispatch({
        type: 'SET SHOW ENDDATE',
        showEndDate,
      });
    }, []),
    updateSearchKey: useCallback((searchKey: string) => {
      dispatch({
        type: 'UPDATE SEARCH KEY',
        searchKey,
      });
    }, []),
    resetMember: useCallback(() => {
      dispatch({
        type: 'RESET MEMBER',
      });
    }, []),
    updateIsLoading: useCallback((isLoading: boolean) => {
      dispatch({
        type: 'UPDATE ISLOADING',
        isLoading,
      });
    }, []),
  };
  return {
    actions,
    state,
  };
};
