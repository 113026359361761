/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_845m7_157 {
  display: flex;
}

._justify-content-space-between_845m7_161 {
  justify-content: space-between;
}

._tabular-nums_845m7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_845m7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_845m7_178 ._header_845m7_178 ._title_845m7_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_845m7_178 ._header_845m7_178 ._title_845m7_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_845m7_157 {
  display: flex;
}

._justify-content-space-between_845m7_161 {
  justify-content: space-between;
}

._tabular-nums_845m7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_845m7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_845m7_178 ._header_845m7_178 ._title_845m7_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_845m7_178 ._header_845m7_178 ._title_845m7_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._content_845m7_178 {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
._content_845m7_178 ._header_845m7_178 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._content_845m7_178 ._header_845m7_178 ._title_845m7_178 {
  display: flex;
  flex-direction: column;
}
._content_845m7_178 ._header_845m7_178 ._title_845m7_178 h3 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  color: #2e426d;
}
._content_845m7_178 ._header_845m7_178 ._title_845m7_178 h4 {
  margin: 0 0 0.5rem 0.5rem;
  color: #8f8d91;
}
._content_845m7_178 ._header_845m7_178 ._select_845m7_451 {
  margin-left: auto;
}

._AudienceDemoSection_845m7_455 ._content_845m7_178 ._header_845m7_178 ._select_845m7_451 {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._AudienceDemoSection_845m7_455 ._content_845m7_178 ._header_845m7_178 ._select_845m7_451 ._item_845m7_461 {
  margin-left: 1.5rem;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.021875rem;
  color: #bdbdbd;
  will-change: color;
  transition: color 0.1s ease-out;
}
._AudienceDemoSection_845m7_455 ._content_845m7_178 ._header_845m7_178 ._select_845m7_451 ._item_845m7_461:hover, ._AudienceDemoSection_845m7_455 ._content_845m7_178 ._header_845m7_178 ._select_845m7_451 ._item_845m7_461._active_845m7_472 {
  color: #3996e0;
}
._AudienceDemoSection_845m7_455 ._content_845m7_178 ._main_845m7_475 {
  flex: 1;
}