/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ox54y_157 {
  display: flex;
}

._justify-content-space-between_ox54y_161 {
  justify-content: space-between;
}

._tabular-nums_ox54y_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ox54y_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._StyleControls_ox54y_209 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  padding: 0 0.75rem;
  color: #8f8d91;
  font-size: 0.875rem;
  background-color: #fafafa;
  border-radius: 0.375rem;
}
._StyleControls_ox54y_209 ._divider_ox54y_221 {
  width: 0.0625rem;
  height: 2rem;
  margin: 0 0.75rem;
  background: #dadcde;
}
._StyleControls_ox54y_209 ._blockTypes_ox54y_227 {
  margin-right: 0.625rem;
}
._StyleControls_ox54y_209 ._item_ox54y_230 {
  margin-right: 0.625rem;
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._StyleControls_ox54y_209 ._item_ox54y_230:hover {
  color: #e9e8ea;
}
._StyleControls_ox54y_209 ._item_ox54y_230._active_ox54y_239 {
  color: #3996e0;
}