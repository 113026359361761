import * as React from 'react';

import { Form, Select } from '@revfluence/fresh';
import { OFFER_TRACKING_TYPE } from '@affiliates/types/globalTypes';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { IAffiliateLinksFormElementProps } from '../../../types';

const { Option } = Select;

interface IProps extends IAffiliateLinksFormElementProps<'conversionTrackingType'> {
  events: {
    onBlur: () => void;
    onFocus: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}

export const OfferConversionTracking: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  events,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your offer conversion tracking!' },
  ];
  const disabledText = disabled ? "Can't be modified since at least one of your affiliate links uses a deep link." : undefined;
  return (
    <Form.Item label="Conversion Tracking" name={name} rules={rules} extra={disabledText}>
      <Select
        disabled={disabled}
        id={testIds.conversionTracking}
        placeholder="Conversion Tracking"
        size="large"
        {...events}
      >
        <Option value={OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK}>
          Javascript Postback
        </Option>
        <Option value={OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID}>
          Server Postback w/ Transaction ID
        </Option>
        <Option value={OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID} datatestid="postback">
          Server Postback w/ Partner ID
        </Option>
        <Option value={OFFER_TRACKING_TYPE.HTTPS_IMAGE_PIXEL}>
          HTTPS Image Pixel
        </Option>
        <Option value={OFFER_TRACKING_TYPE.HTTPS_IFRAME_PIXEL}>
          HTTPS iFrame Pixel
        </Option>
      </Select>
    </Form.Item>
  );
});
