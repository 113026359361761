import React from 'react';
import styles from './AutomationDate.scss';

type IProps = {
  title: string;
  date: string;
};

const AutomationDate = (props: IProps) => {
  const { title, date } = props;

  return (
    <div className={styles.AutomationDate}>
      <div className={styles.title}>{ title }</div>
      <div className={styles.date}>{ date }</div>
    </div>
  );
};

export default AutomationDate;
