import { Switch } from 'antd';
import {
  SwitchProps,
  SwitchChangeEventHandler,
  SwitchClickEventHandler,
  SwitchSize,
} from 'antd/lib/switch';

export type ISwitchProps = SwitchProps;
export type ISwitchChangeEventHandler = SwitchChangeEventHandler;
export type ISwitchClickEventHandler = SwitchClickEventHandler;
export type ISwitchSize = SwitchSize;

export { Switch };
