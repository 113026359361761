import { EditorState } from 'draft-js';

export function getSelectedEntityType(editorState: EditorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const entity = currentContentBlock.getEntityAt(start);

  if (entity) {
    return contentState.getEntity(entity).getType();
  }

  return null;
}
