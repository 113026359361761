import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const InvitedIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21.2,20.6H10c-0.4,0-0.8-0.3-0.8-0.8c0-2.7,1.2-5,3.4-6.1c0.4-0.2,0.8-0.1,1,0.3
      c0.2,0.4,0.1,0.8-0.3,1c-1.4,0.8-2.3,2.2-2.5,4h9.6c-0.3-1.9-1.2-3.3-2.6-4.1c-0.4-0.2-0.5-0.6-0.3-1c0.2-0.4,0.6-0.5,1-0.3
      c2.2,1.1,3.4,3.2,3.5,6.1c0,0.2-0.1,0.4-0.2,0.6C21.6,20.5,21.4,20.6,21.2,20.6z M15.6,5c-1.5,0-2.8,1.3-2.8,3s1.2,3,2.8,3
      c1.5,0,2.8-1.3,2.8-3S17.1,5,15.6,5z M15.6,12.5c-2.4,0-4.3-2-4.3-4.5c0-2.5,1.9-4.5,4.3-4.5s4.3,2,4.3,4.5
      C19.9,10.4,18,12.5,15.6,12.5z M4.6,14.9c-0.2,0-0.3-0.1-0.5-0.2l-1.9-1.5c-0.3-0.3-0.4-0.7-0.1-1.1c0.3-0.3,0.7-0.4,1.1-0.1l1.3,1
      l2.6-3.3c0.3-0.3,0.7-0.4,1.1-0.1c0.3,0.3,0.4,0.7,0.1,1.1l-3.1,3.9C5.1,14.7,4.9,14.8,4.6,14.9C4.7,14.9,4.6,14.9,4.6,14.9z"
    />
  </SvgIcon>
));

InvitedIcon.displayName = 'InvitedIcon';

export { InvitedIcon };
