/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';

import { logger } from '@common';

export const getData = async (url: string): Promise<any> => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.debug({ message: err });

    throw err;
  }
};

interface IUseGetData<T> {
  data: T;
  error: Error | null;
  loading: boolean;
}

export function useGetData<T>(url: string, skipFetch?: boolean): IUseGetData<T> {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!url || skipFetch) return;

    setLoading(true);
    getData(url)
      .then((urlData: any) => {
        setData(urlData);
        setError(null);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [url, skipFetch]);

  return {
    loading,
    data,
    error,
  };
}
