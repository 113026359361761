import * as React from 'react';
import {
  Space,
  Table,
  Typography,
} from '@revfluence/fresh';
import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  ReconnectButton,
  RemoveButton,
} from '@frontend/app/components/InstagramMeta/MetaItems/';

import { renderBCATag, renderInstagramDirectMessageTag, renderSocialListeningTag } from '@frontend/app/components/InstagramMeta/MetaItems/RenderTag';
import { size } from 'lodash';
import { ConnectMetaAccount } from './ConnectMetaAccount';

import styles from './Tables.scss';
import { BrandedContentAdsFeatures, DirectMessagingFeatures, SocialListeningFeatures } from './utils/composeFeatures';
import { LoadingState, TableLoadingRows } from './containers/InstagramAccountsTable';
import { CellStatus } from './utils/composeCells';
import { EmptyState } from './EmptyState';

const { Title } = Typography;

export interface IInstagramAccount {
  id: string;
  name: string;
  facebookPageId?: string;
  adAccountNames?: string[];
  disconnectAccount: boolean;
  loading: LoadingState;
  canRemove: boolean;
  socialListening: {
    cell: CellStatus,
    features: SocialListeningFeatures,
  },
  directMessaging: {
    cell: CellStatus,
    features: DirectMessagingFeatures,
    appNotInstalled: boolean,
    otherClientConnected: boolean,
  },
  brandedContentAds: {
    cell: CellStatus,
    features: BrandedContentAdsFeatures,
    otherClientConnected: boolean,
  },
}

interface IUpdateAccountInput {
  socialAccountId: string,
  facebookPageId?: string,
  socialListeningEnabled?: boolean,
  brandedContentAdsEnabled?: boolean,
  IGDMEnabled?: boolean,
}

export interface IInstagramAccountTableProps {
  data: IInstagramAccount[];
  pagination?: {
    total: number;
    current: number;
    pageSize: number;
  }
  selectedIGDMAccount: string;
  brandedContentAdsEnabled: boolean;
  igdmEnabled: boolean;
  igEstimatedImpressionsEnabled: boolean;
  onPaginationChange: (page: number) => void;
  removeAccount: (socialAccountId: string, facebookPageId: string) => void;
  reconnectAccount: () => void;
  updateAccount: (input: IUpdateAccountInput) => void;
  setLoadingRows: (loadingRow: TableLoadingRows) => void;
}

const BCAConfirmModal = (account: string, confirmAction: () => void) => Modal.confirm({
  title: `Are you sure you want to select @${account} for Ads Permissions?`,
  content: 'Only one account can be used for Ads Permissions, and you will not be able to change it at this time.',
  okText: 'Select',
  cancelText: 'Cancel',
  onOk: confirmAction,
});

const IGDMSelectConfirmModal = (selectedAccount: string, newAccount: string, confirmAction: () => void) => {
  if (selectedAccount) {
    return Modal.confirm({
      title: `Are you sure you want to select @${newAccount}?`,
      content: `Only one account can be used for direct messaging at this time. @${selectedAccount} will be disabled from direct messaging.`,
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: confirmAction,
    });
  }
  return confirmAction();
};

const IGDMRemoveConfirmModal = (adAccountNames: string[], confirmAction: () => void) => {
  const content = (
    <>
      <p>
        Please note that after removing your Instagram account, we will no longer capture any data
        {' '}
        for social listening purposes. This means that we will not be able to gather insights or
        {' '}
        monitor any awareness or engagement related to your account.
      </p>
      {size(adAccountNames) > 0 && (
        <>
          {' '}
          <p>
            By removing this account, the following Ad Account(s) will be unlinked and all reporting on Ads Insights will no longer be available:
            <ul style={{ paddingLeft: 20 }}>
              {adAccountNames.map((adAccountName) => (
                <li key={adAccountName}>{adAccountName}</li>
            ))}
            </ul>
          </p>
        </>
      )}
    </>
  );

  return Modal.confirm({
    title: 'Remove Instagram Account',
    content,
    okText: 'Remove',
    cancelText: 'Cancel',
    onOk: confirmAction,
  });
};

export const disableInstagramTableButton = (loadingState?: LoadingState, currentBtn?: 'bca' | 'igdm' | 'remove') => {
  if (currentBtn === 'bca' && loadingState?.bcaLoading) {
    return false;
  }
  if (currentBtn === 'igdm' && loadingState?.igdmLoading) {
    return false;
  }
  if (currentBtn === 'remove' && loadingState?.removing) {
    return false;
  }
  if (loadingState) {
    return loadingState.bcaLoading || loadingState.igdmLoading || loadingState.removing;
  }
  return false;
};

export const InstagramAccountTable: React.FC<IInstagramAccountTableProps> = (props: IInstagramAccountTableProps) => {
  const {
    data,
    pagination,
    brandedContentAdsEnabled,
    igdmEnabled,
    igEstimatedImpressionsEnabled,
    removeAccount,
    reconnectAccount,
    updateAccount,
    onPaginationChange,
    setLoadingRows,
    selectedIGDMAccount,
  } = props;
  const isLoadingIGDM = data.some((account) => account.loading?.igdmLoading);
  const isLoadingBCA = data.some((account) => account.loading?.bcaLoading);
  const isEmpty = data.length === 0;
  const columns: ColumnsType<IInstagramAccount> = [
    {
      title: 'Account',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Listening',
      dataIndex: 'insights',
      key: 'insights',
      width: '20%',
      render: (_, record) => renderSocialListeningTag(record, igEstimatedImpressionsEnabled),
    },
    {
      title: 'Direct Messaging',
      dataIndex: 'insights',
      key: 'insights',
      width: '20%',
      render: (_, record) => renderInstagramDirectMessageTag(
        isLoadingIGDM,
        record,
        () => IGDMSelectConfirmModal(
          selectedIGDMAccount,
          record.name,
          () => {
            updateAccount({
              socialAccountId: record.id,
              IGDMEnabled: true,
              facebookPageId: record.facebookPageId,
            });
            setLoadingRows({ [record.id]: { igdmLoading: true } });
          },
        ),
        () => {
          updateAccount({
            socialAccountId: record.id,
            IGDMEnabled: false,
            facebookPageId: record.facebookPageId,
          });
          setLoadingRows({ [record.id]: { igdmLoading: true } });
        },
      ),
    },
    {
      title: 'Ads Permissions',
      dataIndex: 'insights',
      key: 'insights',
      width: '20%',
      render: (_, record) => renderBCATag(
        isLoadingBCA,
        record,
        () => BCAConfirmModal(record.name, () => {
          updateAccount({
            socialAccountId: record.id,
            brandedContentAdsEnabled: true,
          });
          setLoadingRows({ [record.id]: { bcaLoading: true } });
        }),
      ),
    },
    {
      key: 'actions',
      width: '100%',
      render: (_, record) => (
        <Space>
          {record.disconnectAccount
            && (
              <ReconnectButton
                onClick={reconnectAccount}
                disconnectAccount
                disabled={disableInstagramTableButton(record.loading)}
              />
            )}
          {record.canRemove
            && (
              <RemoveButton
                removeAccount={() => {
                  IGDMRemoveConfirmModal(record.adAccountNames, () => {
                    removeAccount(record.id, record.facebookPageId);
                    setLoadingRows({ [record.id]: { removing: true } });
                  });
                }}
                loading={record.loading?.removing}
                disabled={disableInstagramTableButton(record.loading, 'remove')}
              />
            )}
        </Space>
      ),
    },
  ];
  const filteredColumns = columns.filter((column) => {
    if (column.title === 'Ads Permissions') {
      return brandedContentAdsEnabled;
    }
    if (column.title === 'Direct Messaging') {
      return igdmEnabled;
    }
    return true;
  });

  if (isEmpty) {
    return (
      <>
        <ConnectMetaAccount onClick={reconnectAccount} />
      </>
    );
  }

  if (isEmpty) {
    return (
      <>
        <Title level={5} className={styles.TableTitle}>Connected Ad Accounts</Title>
        <EmptyState />
        <ConnectMetaAccount onClick={reconnectAccount} />
      </>
    );
  }

  return (
    <>
      <ConnectMetaAccount className={styles.marginBottom} onClick={reconnectAccount} />
      <Title level={5} className={styles.TableTitle}>Connected Instagram Accounts</Title>
      <Table
        className={styles.InstagramTable}
        columns={filteredColumns}
        dataSource={data}
        rowClassName={() => styles.RowTable}
        pagination={{
          hideOnSinglePage: true,
          onChange(page) {
            onPaginationChange(page);
          },
          ...pagination,
        }}
      />
    </>
  );
};
