import * as React from 'react';
import { map, toNumber } from 'lodash';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { useAnalytics } from '@frontend/hooks';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import {
  AnalyzePage, LoadSpinner,
} from '@components';

import {
 useActivationsQuery, useProgramsQuery, useCommunitiesQuery, useGetCurrentClient,
} from '@frontend/app/hooks';
import { useLocation } from 'react-router-dom';
import { ClientFeature } from '@frontend/app/constants';

const SocialAppReports: React.FunctionComponent = () => {
  const analytics = useAnalytics();
  const { analyticsServerApiEndpoint, backendServerApiEndpoint, clientId } = useApplication();

  const { client, loading: clientLoading } = useGetCurrentClient();
  const clientStartDate = client?.createdDate ? new Date(client.createdDate) : new Date();

  const {
    loading: communityLoading,
    data: communityData,
  } = useCommunitiesQuery();

  const {
    loading: programLoading,
    data: programData,
  } = useProgramsQuery();

  const {
    loading: activationsLoading,
    activations,
  } = useActivationsQuery();

  const loading = activationsLoading || programLoading || communityLoading || clientLoading;
  const isProjects = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const showCostMetrics = useClientFeatureEnabled(ClientFeature.SOCIAL_ANALYTICS_COST_METRICS);
  const showAllTimeDefault = useClientFeatureEnabled(ClientFeature.SOCIAL_ANALYTICS_ALL_TIME_DEFAULT);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = toNumber(searchParams.get('projectId'));

  const projectFilters = [
    {
      title: 'Groups',
      objects: map(communityData ? communityData.communities : [], (community) => ({
          id: community.id,
          displayName: community.title,
        })),
      filtersKey: 'communityIds',
    },
    {
      title: 'Projects',
      objects: map(programData ? programData.programs : [], (program) => {
        if (program.id === projectId) {
          return {
            id: program.id,
            displayName: program.title,
            selected: true,
          };
        }
        return {
          id: program.id,
          displayName: program.title,
        };
      }),
      filtersKey: 'programIds',
    },
  ];

  const legacyFilters = [
    {
      title: 'Community',
      objects: map(communityData ? communityData.communities : [], (community) => ({
          id: community.id,
          displayName: community.title,
        })),
      filtersKey: 'communityIds',
    },
    {
      title: 'Program',
      objects: map(programData ? programData.programs : [], (program) => ({
          id: program.id,
          displayName: program.title,
        })),
      filtersKey: 'programIds',
    },
    {
      title: 'Activation',
      objects: map(activations, (activation) => ({
          id: activation.id,
          displayName: activation.name,
        })),
      filtersKey: 'activationIds',
    },
  ];
  const filters = isProjects ? projectFilters : legacyFilters;
  return (
    <div>
      {loading ? (
        <LoadSpinner />
      ) : (
        <AnalyzePage
          isQa={false}
          apiEndpoint={analyticsServerApiEndpoint}
          backendServerApiEndpoint={backendServerApiEndpoint}
          clientId={clientId}
          clientStartDate={clientStartDate}
          aspirexFilterOptions={filters}
          aspirexAnalytics={analytics}
          showCostMetrics={showCostMetrics}
          showAllTimeDefault={showAllTimeDefault}
          headerStickyStyles={{
            top: 0,
          }}
        />
      )}
    </div>
  );
};

export default SocialAppReports;
