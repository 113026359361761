import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useAuth } from '@frontend/context/authContext';
import { useApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { pfaV2Routes } from '../../routes';
import { CatalogsList } from '../CatalogsList/CatalogsList';
import { CatalogDetails } from '../CatalogDetails/CatalogDetails';
import { CatalogDetailsProvider } from '../CatalogDetails/CatalogDetailsContext';

export const CatalogRouter = () => {
  const { token } = useAuth();
  const apolloClient = useApolloClient(token);

  return (
    <CatalogDetailsProvider>
      <Switch>
        <ApolloProvider client={apolloClient}>
          <Route exact path={pfaV2Routes.settings.brandCatalogs} component={CatalogsList} />
          <Route path={pfaV2Routes.settings.brandCatalogsDetails} component={CatalogDetails} />
        </ApolloProvider>
      </Switch>
    </CatalogDetailsProvider>
  );
};
