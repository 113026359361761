import React, { useState } from 'react';
import { Input, Table, Typography } from '@revfluence/fresh';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { validateDeepLink } from '@frontend/applications/AffiliatesApp/components/MembersWizard/utils/validateDeepLinkUrls';
import { DeepLinkType } from '../createAdditionalDeepLinks';
import { TMemberTableRow } from '../../../types';

interface IndividualViewProps {
  selectedMembers?: readonly TMemberTableRow[];
  deepLinks: DeepLinkType[];
  handleInputChange: (id: number, field: string, value: string) => void;
  offerData?: GetOfferById_offer;
}

const { Text } = Typography;

const IndividualView: React.FC<IndividualViewProps> = ({
 selectedMembers, deepLinks, handleInputChange, offerData,
}) => {
  const offerLink = offerData?.links[0];
  const [labelErrors, setLabelErrors] = useState<{ [id: number]: string | null }>({});
  const [linkValidations, setLinkValidations] = useState<{ [id: number]: { error: string | null; warning: string | null } }>({});

  const handleValidateDeepLink = (id: number, value: string) => {
    const validation = validateDeepLink(value, offerLink.url, offerLink.creatorDeeplink, offerLink.domains);
    setLinkValidations((prev) => ({
      ...prev,
      [id]: {
        error: validation.error,
        warning: validation.warning,
      },
    }));
  };

  const handleLabelChange = (id: number, value: string) => {
    setLabelErrors((prev) => ({
      ...prev,
      [id]: value ? null : 'Label is required',
    }));
    handleInputChange(id, value, deepLinks.find((link) => link.affiliateOfferLinkId === id)?.deepLinkUrl || '');
  };

  const handleUrlChange = (id: number, url: string) => {
    handleValidateDeepLink(id, url);
    handleInputChange(id, deepLinks.find((link) => link.affiliateOfferLinkId === id)?.deepLinkLabel || '', url);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: '40%',
      render: (_: string, record: TMemberTableRow) => {
        const affiliateId = record.selectedAffliate.affiliateOfferId;
        const label = deepLinks.find((link) => link.affiliateOfferLinkId === affiliateId)?.deepLinkLabel || '';

        return (
          <div>
            <Input
              value={label}
              placeholder="Label"
              onChange={(e) => handleLabelChange(affiliateId, e.target.value)}
              status={labelErrors[affiliateId] ? 'error' : ''}
            />
            {labelErrors[affiliateId] && (
              <Text type="danger" className="text-red-500">
                {labelErrors[affiliateId]}
              </Text>
            )}
          </div>
        );
      },
    },
    {
      title: 'Deep Link',
      dataIndex: 'deepLink',
      key: 'deepLink',
      width: '40%',
      render: (_: string, record: TMemberTableRow) => {
        const affiliateId = record.selectedAffliate.affiliateOfferId;
        const deepLinkUrl = deepLinks.find((link) => link.affiliateOfferLinkId === affiliateId)?.deepLinkUrl || '';

        return (
          <div>
            <Input
              value={deepLinkUrl}
              placeholder="https://example.com"
              onChange={(e) => handleUrlChange(affiliateId, e.target.value)}
              status={linkValidations[affiliateId]?.error ? 'error' : linkValidations[affiliateId]?.warning ? 'warning' : ''}
            />
            {linkValidations[affiliateId]?.error && (
              <Text type="danger" className="text-red-500">
                {linkValidations[affiliateId].error}
              </Text>
            )}
            {!linkValidations[affiliateId]?.error && linkValidations[affiliateId]?.warning && (
              <Text type="warning" className="text-yellow-500">
                {linkValidations[affiliateId].warning}
              </Text>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={selectedMembers}
      columns={columns}
      rowKey={(record) => record.id.toString()}
      pagination={false}
    />
  );
};

export default IndividualView;
