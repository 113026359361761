import 'css-chunk:src/applications/ContractApp/components/MemberContractList.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1ov57_157",
  "justify-content-space-between": "_justify-content-space-between_1ov57_161",
  "tabular-nums": "_tabular-nums_1ov57_165",
  "MemberContractList": "_MemberContractList_1ov57_178",
  "toolbar": "_toolbar_1ov57_183",
  "displaying_disclaimer": "_displaying_disclaimer_1ov57_186",
  "new_button": "_new_button_1ov57_197",
  "none_placeholder": "_none_placeholder_1ov57_202",
  "contractList": "_contractList_1ov57_216",
  "contractItem": "_contractItem_1ov57_219",
  "contractItem_head": "_contractItem_head_1ov57_230",
  "text_wrap": "_text_wrap_1ov57_235",
  "contractName": "_contractName_1ov57_238",
  "dateCreated": "_dateCreated_1ov57_248",
  "button_wrap": "_button_wrap_1ov57_257",
  "download_button": "_download_button_1ov57_262",
  "requirements_wrap": "_requirements_wrap_1ov57_283",
  "requirement_label": "_requirement_label_1ov57_286",
  "requirement_list": "_requirement_list_1ov57_296",
  "requirement_item": "_requirement_item_1ov57_301",
  "signed_status": "_signed_status_1ov57_308",
  "signIcon": "_signIcon_1ov57_315",
  "status_txt": "_status_txt_1ov57_318",
  "show": "_show_1ov57_1"
};