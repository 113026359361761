import * as React from 'react';
import Helmet from 'react-helmet';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { useClientFeatureEnabled } from '@frontend/app/hooks/useClientFeatureEnabled';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import { AdvertiserAccessPage } from '../../containers/AdvertiserAccessPage';
import { AccessLimitHeader } from '../../components/AccessLimitHeader/AccessLimitHeader';

export const InfluencerWhitelistAppDashboard: React.FunctionComponent = () => {
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const displayName = 'Ads Permissions';
  const navSettings = [
    {
      route: '',
      displayName,
      component: AdvertiserAccessPage,
    },
  ];

  const application = {
    displayName,
    iconUrl: 'https://storage.googleapis.com/aspirex-static-files/influencer_whitelist_icon.svg',
  };

  return (
    <>
      {refreshUi ? (
        <>
          <AppHeader
            title={application.displayName}
            actions={[<AccessLimitHeader key="access-limit-header" refreshUi={refreshUi} />]}
          />
          <div className="px-6" style={{ padding: 24 }}>
            <AdvertiserAccessPage />
          </div>
        </>
      ) : (
        <>
          <Helmet title={displayName} />
          <AppDashboardNav
            application={application}
            navLinks={navSettings}
            hideTabs
            defaultRoute=""
          />
        </>
      )}
    </>
  );
};
