import * as React from 'react';
import { Button } from '@revfluence/fresh';
import { FolderIcon } from '@revfluence/fresh-icons/solid/esm';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { useResourceContext } from '@frontend/app/context';
import styles from './GdriveSettingsPage.scss';

interface IProps {
  type: ResourceType;
}

const GdriveSettingsPageEmpty: React.FC<IProps> = React.memo((props) => {
  const { type } = props;
  const { addAccount } = useResourceContext();
  return (
    <>
      <div className={styles.emptyContent}>
        <FolderIcon
          style={{ fontSize: '80px', marginBottom: '36px', color: '#f0f0f0' }}
        />
        <div className={styles.title}>
          Connect your Google Drive account
        </div>
        <div className={styles.text}>
          Easily sync content to your Google Drive to streamline content downloads.
        </div>
        <Button
          type="primary"
          onClick={() => addAccount(type, false)}
        >
          Connect
        </Button>
      </div>
    </>
  );
});

GdriveSettingsPageEmpty.displayName = 'GdriveSettingsPageEmpty';

export default GdriveSettingsPageEmpty;
