import gql from 'graphql-tag';

export const AUTOMATION_EXECUTION_FRAGMENT = gql`
  fragment AutomationExecutionFragment on AutomationExecution {
    id
    automationId
    automation {
      id
      name
      programId
      status
      deletedDate
    }
    variables {
      id
      type
      name
      value
    }
    createdDate
    updatedDate
    status
  }
`;
