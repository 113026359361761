import {
    QueryHookOptions,
    useQuery,
  } from '@apollo/client';

  import { GET_RUNNING_KLAVIYO_SYNC } from '@frontend/app/queries';
  import {
    GetRunningKlaviyoSync,
  } from '@frontend/app/queries/types/GetRunningKlaviyoSync';

  type IOptions = QueryHookOptions<GetRunningKlaviyoSync>;

  export const useGetRunningKlaviyoSync = (options: IOptions = {}) => (
    useQuery<GetRunningKlaviyoSync>(GET_RUNNING_KLAVIYO_SYNC, options)
  );
