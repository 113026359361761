import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { Tooltip } from 'antd';
import { UserAvatar } from '@frontend/app/components';

import styles from './NameCell.scss';

interface IProps {
  name: string;
  memberId: number;
  imageUrl: string;
  disabled?: boolean;
  disabledReason?: string;

}

export const NameCell: React.FC<IProps> = React.memo((props) => {
  const imageUrl = props.imageUrl;
  return (
    <div style={{
 display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '8px',
}}
    >
      <div className={styles.avatarWrapper}>
        <UserAvatar
          className={styles.avatar}
          profilePicture={imageUrl}
          name={props.name}
        />
      </div>
      <div className={styles.name}>
        <Tooltip
          title={props.disabledReason}
        >
          {
            props.disabled
              ? (
                <div>
                  {props.name}
                </div>
              )
              : (
                <NavLink
                  to={(location) => ({ ...location, pathname: `/members/${props.memberId}` })}
                >
                  {props.name}
                </NavLink>
              )
          }
        </Tooltip>
      </div>
    </div>
  );
});

NameCell.displayName = 'MemberTableNameCell';
