/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_fojnf_157 {
  display: flex;
}

._justify-content-space-between_fojnf_161 {
  justify-content: space-between;
}

._tabular-nums_fojnf_165 {
  font-variant-numeric: tabular-nums;
}

._BodyRow_fojnf_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_fojnf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._BodyRow_fojnf_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._BodyRow_fojnf_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._BodyRow_fojnf_169 ::-webkit-scrollbar-track, ._BodyRow_fojnf_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._BodyRow_fojnf_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._BodyRow_fojnf_169 {
  box-sizing: content-box;
  flex-shrink: 0;
  min-height: 3.25rem;
  display: flex;
  flex-direction: row;
  will-change: background-color;
  transition: background-color 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._BodyRow_fojnf_169._selected_fojnf_213 {
  background-color: #eff5f9;
}
._BodyRow_fojnf_169._selected_fojnf_213[class*=odd] {
  background-color: #e8f0f6 !important;
}
._BodyRow_fojnf_169 ._checkboxWrapper_fojnf_219 {
  z-index: 1;
  flex-shrink: 0;
  flex-basis: 3.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-right: 0.0625rem solid #e6edf2;
  background-color: inherit;
}
._BodyRow_fojnf_169 ._checkboxWrapper_fojnf_219 ._checkbox_fojnf_219:hover {
  background-color: transparent;
}
._BodyRow_fojnf_169 ._cellContainer_fojnf_233 {
  flex: 1;
  display: flex;
  flex-direction: row;
  opacity: 0;
  will-change: opacity, transform;
  transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._BodyRow_fojnf_169 ._cellContainer_fojnf_233._visible_fojnf_241 {
  opacity: 1;
}

._tooltip_fojnf_245 {
  font-size: 0.75rem;
  line-height: 1.2;
  font-weight: 600;
  color: #d9534f;
}