import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const FlagIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 12 12">
    <path
      d="
        M10.8447 1.11976C10.5367 0.897089 10.138 0.833756 9.77868 0.951089C9.39468 1.07642 8.89468
        1.14242 8.33268 1.14242C7.58335 1.14242 6.93268 0.887089 6.24335 0.617089C5.46868 0.313756
        4.66668 -0.000244141 3.66602 -0.000244141C1.74202 -0.000244141 0.996682 0.634423 0.866016
        0.762423C0.738682 0.887089 0.666016 1.05909 0.666016
        1.23842V5.99976V8.09509V11.3331C0.666016 11.7018 0.964682 11.9998 1.33268 11.9998C1.70135
        11.9998 1.99935 11.7018 1.99935 11.3331V8.46776C2.26268 8.35109 2.78135 8.19042 3.66602
        8.19042C4.41535 8.19042 5.06668 8.44576 5.75602 8.71642C6.53068 9.01976 7.33202 9.33309
        8.33268 9.33309C9.53868 9.33309 10.2813 9.08109 10.6933 8.86976C11.088 8.66509 11.3327
        8.26842 11.3327 7.83242V2.07042C11.3327 1.69709 11.1507 1.34176 10.8447 1.11976Z
      "
    />
  </SvgIcon>
));

FlagIcon.displayName = 'FlagIcon';
