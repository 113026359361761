/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._LoadSpinner_1rdks_32._centered_1rdks_32 {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._LoadSpinner_1rdks_32 ._img_1rdks_39 {
  margin-left: 0.75rem;
  width: 4.6875rem;
  height: 3.4375rem;
  display: inline-block;
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/loading_icon_next.gif);
  background-repeat: no-repeat;
  background-size: 4.6875rem 3.4375rem;
  background-position: center center;
  box-sizing: content-box;
}
._LoadSpinner_1rdks_32._boxed_1rdks_50 ._img_1rdks_39 {
  margin-left: 0;
  padding: 0.75rem 0 0.75rem 0.75rem;
  background-color: #fdfdfd;
  background-position: right center;
  border-radius: 0.5rem;
}
._LoadSpinner_1rdks_32 ._spinnerIcon_1rdks_57 {
  fill: #3996e0;
}