import gql from 'graphql-tag';

const GET_BUDGETS_FOR_CLIENT = gql`
  query GetBudgetsForClientQuery {
    budgets: getBudgetAccountsList {
      budgetId
      budgetName
      childBudgetsList
      parentBudgetId
      parentBudgetName
    }
  }
`;

export default GET_BUDGETS_FOR_CLIENT;
