/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1rcpl_157 {
  display: flex;
}

._justify-content-space-between_1rcpl_161 {
  justify-content: space-between;
}

._tabular-nums_1rcpl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1rcpl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PaymentOverview_1rcpl_178 ._paymentsOverviewTitle_1rcpl_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._PaymentOverview_1rcpl_178 ._subtitle1_1rcpl_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 ._paymentLogHeader_1rcpl_198 ._paymentLogTitle_1rcpl_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._PaymentOverview_1rcpl_178 .ant-drawer-content {
  background-color: #f7f8fa;
}
._PaymentOverview_1rcpl_178 .ant-drawer-header {
  background-color: #f7f8fa !important;
}
._PaymentOverview_1rcpl_178 ._caption_1rcpl_214 {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  /* identical to box height, or 171% */
  text-align: center;
  letter-spacing: -0.025rem;
  color: #8f8d91;
}
._PaymentOverview_1rcpl_178 ._smallText_1rcpl_224 {
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: -0.025rem;
  color: #3996e0;
  margin-top: 0.25rem;
}
._PaymentOverview_1rcpl_178 ._paymentBalance_1rcpl_233 {
  padding: 1.5625rem;
}
._PaymentOverview_1rcpl_178 ._paymentBalance_1rcpl_233 ._cost_1rcpl_236 {
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  /* identical to box height, or 121% */
  text-align: center;
  letter-spacing: -0.02rem;
  color: #1a1818;
}
._PaymentOverview_1rcpl_178 ._paymentBalance_1rcpl_233 ._cost_1rcpl_236 .ant-spin {
  color: #1a1818;
  margin-top: 0.25rem;
  margin-left: 1rem;
}
._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 {
  padding-top: 1.125rem;
}
._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 ._noPayment_1rcpl_254 {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: #8f8d91;
  padding-top: 0.25rem;
}
._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 ._title_1rcpl_261 {
  font-style: normal;
  font-weight: 800;
  font-size: 0.6875rem;
  /* identical to box height, or 145% */
  text-transform: uppercase;
  margin: auto;
  padding-left: 0.25rem;
  color: #000000;
}
._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 ._logIcon_1rcpl_271 {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}
._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 ._titleDivider_1rcpl_275 {
  margin: 0;
}
._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 ._paymentEntry_1rcpl_278 {
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
._PaymentOverview_1rcpl_178 ._paymentLog_1rcpl_198 ._paymentLogHeader_1rcpl_198 {
  padding-bottom: 0.75rem;
}
._PaymentOverview_1rcpl_178 ._spaceBottom_1rcpl_288 {
  margin-bottom: 1rem;
}
._PaymentOverview_1rcpl_178 ._dateFilterButton_1rcpl_291 {
  margin-bottom: 0;
  width: 100%;
}