import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_PROFILE_QUERY } from '@frontend/app/queries';
import {
  GetProfileQuery,
  GetProfileQuery_profile,
} from '@frontend/app/queries/types/GetProfileQuery';

export type IProfile = GetProfileQuery_profile;

type IOptions = QueryHookOptions<GetProfileQuery>;

export function useGetProfile(options: IOptions = {}) {
  const {
 loading, data: { profile } = {}, error, refetch,
} = useQuery<GetProfileQuery>(
    GET_PROFILE_QUERY,
    options,
  );

  return {
    loading,
    profile,
    error,
    refetch,
  };
}
