import { TBudgetPayment } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
    payments: TBudgetPayment[];
    type: ActionType.SET_OVERFLOW_BUDGET_PAYMENTS;
}
export type TSetOverflowBudgetPaymentsAction = Readonly<IAction>;

export const setOverflowBudgetPayments = (state: TState, action: TSetOverflowBudgetPaymentsAction): TState => ({
    ...state,
    overflowPayments: action.payments,
});
