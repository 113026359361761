import {
  useSegmentFoldersQuery,
  usePredefinedSegmentsQuery,
} from '@frontend/app/hooks';

import { useMemberPageContext } from './useMemberPageContext';

interface IProps {
  isContact?: boolean;
}

export const useRefetchSegments = (props?: IProps) => {
  const {
    communityId,
    sourcingGroupId,
  } = useMemberPageContext();

  const {
    refetch: refetchFolderSegments,
  } = useSegmentFoldersQuery(communityId, sourcingGroupId);

  const {
    refetch: refetchPredefinedSegments,
  } = usePredefinedSegmentsQuery({
    communityId,
    sourcingGroupId,
    isContact: props?.isContact,
  }, {
    skip: true, // the hook is used here for refetch only
  });

  const refetchSegments = async () => {
    await refetchPredefinedSegments();
    await refetchFolderSegments();
  };

  return { refetchSegments };
};
