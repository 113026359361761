import gql from 'graphql-tag';

export const GET_STORE_LOCATIONS_QUERY = gql`
  query GetStoreLocations {
    getStoreLocations {
      id
      name
    }
  }
`;
