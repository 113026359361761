import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import {
  GET_AD_ACCOUNT_SETTINGS,
  GET_SOCIAL_ACCOUNT_SETTINGS,
  GET_BUSINESS_SETTINGS,
} from '@frontend/app/queries';
import {
  GetSocialAccountSettings,
  GetSocialAccountSettingsVariables,
} from '@frontend/app/queries/types/GetSocialAccountSettings';
import {
  GetAdAccountsSettings,
  GetAdAccountsSettingsVariables,
} from '@frontend/app/queries/types/GetAdAccountsSettings';
import {
  GetBusinessSettings,
  GetBusinessSettingsVariables,
} from '@frontend/app/queries/types/GetBusinessSettings';

type IAdAccountOptions = QueryHookOptions<GetAdAccountsSettings, GetAdAccountsSettingsVariables>;

export const useGetAdAccountSettings = (options: IAdAccountOptions = {}) => (
  useQuery<GetAdAccountsSettings>(GET_AD_ACCOUNT_SETTINGS, options)
);

type ISocialAccountOptions = QueryHookOptions<GetSocialAccountSettings, GetSocialAccountSettingsVariables>;

export const useGetSocialAccountSettings = (options: ISocialAccountOptions = {}) => (
  useQuery<GetSocialAccountSettings>(GET_SOCIAL_ACCOUNT_SETTINGS, options)
);

export type TUseGetSocialAccountSettingsQuery = ReturnType<typeof useGetSocialAccountSettings>;

export type TUseGetAdAccountSettingsQuery = ReturnType<typeof useGetAdAccountSettings>;

type IBusinessOptions = QueryHookOptions<GetBusinessSettings, GetBusinessSettingsVariables>;

export const useGetBusinessSettings = (options: IBusinessOptions = {}) => (
  useQuery<GetBusinessSettings>(GET_BUSINESS_SETTINGS, options)
);

export type TUseGetBusinessSettingsQuery = ReturnType<typeof useGetBusinessSettings>;
