import * as React from 'react';
import { Tooltip, Typography, Tag } from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';

const { Text, Title } = Typography;
import {
  formatValue,
} from '@affiliates/utils';
import { getBudgetStatusColor } from '@frontend/app/utils/getBudgetStatusColor';
import styles from './BudgetStatisticsBarItem.module.scss';
import { IBudgetStat } from '../../types/IBudgetStat';

const { useMemo } = React;
interface IProps {
    stat: IBudgetStat;
}

const BudgetStatisticsBarItem: React.FC<IProps> = (props) => {
    const { stat } = props;
    const { primaryColor, secondaryColor } = getBudgetStatusColor(stat.status);
    const primaryText = useMemo(() => {
        if (stat.value < 0 && stat?.prefix) {
            return `-${stat.prefix}${formatValue(stat.dataFormat, stat.value * -1)}`;
        } else {
            return (stat.prefix ? stat.prefix : '') + formatValue(stat.dataFormat, stat.value);
        }
    }, [stat]);
    return (
      <div className={styles.budgetStatsItemContainer}>
        <Title level={5} className={styles.budgetStatsItemNumber} style={{ color: stat?.status ? primaryColor : '' }}>
          {primaryText}
        </Title>
        <Text type="secondary" className={styles.budgetStatsItemLabel}>
          {stat.label}
          {' '}
          {stat.tooltipText && <Tooltip title={stat.tooltipText}><CircleInfoIcon /></Tooltip>}
        </Text>
        {stat.status && (
          <div className={styles.budgetStatsItemTag}>
            <Tag size="small" style={{ color: primaryColor, backgroundColor: secondaryColor }}>{stat.status}</Tag>
          </div>
)}

      </div>
    );
};
export default BudgetStatisticsBarItem;
BudgetStatisticsBarItem.displayName = 'BudgetStatisticsBarItem';
