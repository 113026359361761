import * as React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ApplicationAvailabilityOptions, ApplicationCompensationOptions, ApplicationPageBuilderComponent } from '@frontend/app/containers/Projects/applicationPageUtils';

import { find, isEmpty } from 'lodash';
import { HashtagIcon } from '@revfluence/fresh-icons/solid/esm';
import { IQuickTemplateStyles, ITemplateProps } from '../../types';

import styles from './Perks.scss';

type IProps = ITemplateProps['perks'] & {
  isFieldVisible: (fieldName: ApplicationPageBuilderComponent) => boolean;
  componentStyles: IQuickTemplateStyles,
};

export const Perks: React.FC<IProps> = (props: IProps) => {
  const {
    isFieldVisible,
    compensation,
    compensationTitle,
    availability,
    availabilityTitle,
    componentStyles,
  } = props;

  const getLabelForCompensation = (perk: string) => {
    const element = find(ApplicationCompensationOptions, ['value', perk]);
    return element?.label || perk;
  };

  const getLabelForAvailability = (perk: string) => {
    const element = find(ApplicationAvailabilityOptions, ['value', perk]);
    return element?.label || perk;
  };

  return (
    <div className={styles.Perks}>
      {
        isFieldVisible(
          ApplicationPageBuilderComponent.PerksCompensation,
        ) && (
          <div className={styles.group}>
            <h2 style={componentStyles.heading}>{compensationTitle || 'Program Perks'}</h2>
            {
              !isEmpty(compensation)
                && compensation.map((perk) => (
                  <div key={perk} className={styles.perk}>
                    <CheckCircleOutlined />
                    <div className={styles.text} style={componentStyles.body}>
                      {getLabelForCompensation(perk)}
                    </div>
                  </div>
              ))
            }
          </div>
        )
      }
      {
        isFieldVisible(
          ApplicationPageBuilderComponent.PerksMediaAvailability,
        ) && (
          <div className={styles.group}>
            <h2 style={componentStyles.heading}>{availabilityTitle || 'Available For'}</h2>
            {
              !isEmpty(availability)
                && availability.map((perk) => (
                  <div key={perk} className={styles.perk}>
                    <HashtagIcon />
                    <div className={styles.text} style={componentStyles.body}>
                      {getLabelForAvailability(perk)}
                    </div>
                  </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};

Perks.displayName = 'QuickTemplatePerks';
