/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1y6qz_157 {
  display: flex;
}

._justify-content-space-between_1y6qz_161 {
  justify-content: space-between;
}

._tabular-nums_1y6qz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1y6qz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1y6qz_157 {
  display: flex;
}

._justify-content-space-between_1y6qz_161 {
  justify-content: space-between;
}

._tabular-nums_1y6qz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1y6qz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SelectOffers_1y6qz_355 ._nextButton_1y6qz_355 {
  border-color: #167cf4;
  background-color: #167cf4;
}
._SelectOffers_1y6qz_355 ._nextButton_1y6qz_355:focus, ._SelectOffers_1y6qz_355 ._nextButton_1y6qz_355:hover {
  background-color: #2e8af5;
  color: #fff;
}
._SelectOffers_1y6qz_355 ._nextButton_1y6qz_355:disabled {
  color: #fff;
  opacity: 0.8;
}

._SelectOffers_1y6qz_355 ._icon_1y6qz_368 {
  max-width: 1.875rem;
}