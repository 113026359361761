export function numTicksForWidth(width: number): number {
  if (width <= 300) {
    return 2;
  }
  if (width > 300 && width <= 400) {
    return 5;
  }

  return 6;
}
