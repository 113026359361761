import * as React from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import { AppModalNav } from '@frontend/applications/Shared/components/AppModalNav';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import MemberContentList from './MemberContentList';
import NewContent from './NewContent';
import ContentApprovalContainer from './ContentApprovalContainer';

import styles from './ContentAppModal.scss';

const { useEffect } = React;

interface IDeepLinkParams {
  context: string;
  id: string;
  in_approval_mode: boolean;
}

interface IProps {
  deepLinkParameters?: IDeepLinkParams;
}

const ContentAppModal: React.FunctionComponent<IProps> = ({ deepLinkParameters }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { workflowActionParameters } = useApplication();

  const isInApprovalMode = (!!workflowActionParameters || deepLinkParameters?.in_approval_mode);

  useEffect(() => {
    if (deepLinkParameters && deepLinkParameters.context === 'viewContent') {
      history.push({
        ...location,
        pathname: `${match.url}/content`,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navSettings = [
    {
      route: 'new_content',
      displayName: 'Upload New',
      component: NewContent,
    },
    {
      route: 'content',
      displayName: 'Member\'s Content',
      component: MemberContentList,
    },
  ];

  return (
    <div className={styles.ContentAppModal}>
      {isInApprovalMode
        ? (<ContentApprovalContainer deepLinkParameters={deepLinkParameters} />)
        : (<AppModalNav navLinks={navSettings} defaultRoute="new_content" />)}
    </div>
  );
};

export default ContentAppModal;
