import { isArray, values } from 'lodash';
import { IColumn } from '@frontend/app/types/Columns';
import {
  ITableColumnConfig,
  ITableCellRenderContext,
  TNetworkIdentifier,
} from '@components';

import { FieldType } from '@frontend/app/types/Fields';

import { renderMemberNameCell } from '../CustomCells/MemberNameCell';
import { renderActivationsCell } from '../CustomCells/ActivationsCell';
import { renderTagsCell } from '../CustomCells/TagsCell';
import { renderProgramsCell } from '../CustomCells/ProgramsCell';
import { renderHighlightsCell } from '../CustomCells/HighlightsCell';
import { renderTalentAgentCell } from '../CustomCells/TalentAgentCell';
import { renderCommunitiesCell } from '../CustomCells/CommunitiesCell';
import { renderOwnersCell } from '../CustomCells/OwnersCell';
import { renderSocialProfileCell } from '../CustomCells/SocialProfileCell';
import { renderImagesCell } from '../CustomCells/ImagesCell';
import { renderTokenSelectionCell } from '../CustomCells/TokenSelectionCell';
import { renderPercentageCell } from '../CustomCells/PercentageCell';
import { renderDefaultCell } from '../CustomCells/DefaultCell';
import { renderApplicantSourceCell, renderAppliedDateCell } from '../CustomCells/ApplicantSourceCell';

interface IRenderCellOptions {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  refetchProjectCounts?: () => Promise<any>;
  projectId?: number;
}

export const defaultRenderCell = (column: IColumn, options?: IRenderCellOptions): ITableColumnConfig => {
  switch (column.field) {
    case 'name':
      return {
        ...column,
        grow: true,
        width: 300,
        minWidth: 200,
        maxWidth: 400,
        render: renderMemberNameCell,
        lockPosition: true,
      };

    case 'email':
      return {
        ...column,
        grow: true,
        width: 250,
      };

    case 'activations':
      return {
        ...column,
        grow: true,
        width: 200,
        render: renderActivationsCell,
      };

    case 'tags':
      return {
        ...column,
        grow: true,
        width: 200,
        render: renderTagsCell,
      };

    case 'highlights':
      return {
        ...column,
        grow: true,
        width: 200,
        render: renderHighlightsCell,
      };

    case 'talentAgents':
      return {
        ...column,
        grow: true,
        width: 200,
        render: renderTalentAgentCell,
      };

    case 'programs':
    case 'invitedPrograms':
    case 'submittedPrograms':
    case 'rejectedPrograms':
      return {
        ...column,
        grow: true,
        width: 200,
        render: renderProgramsCell,
      };

    case 'communities':
      return {
        ...column,
        grow: true,
        width: 200,
        render: renderCommunitiesCell,
      };

    case 'owners':
      return {
        ...column,
        grow: true,
        width: 200,
        render: renderOwnersCell,
      };

    case 'appliedDate':
      return {
        ...column,
        grow: true,
        cellType: 'date',
        render: (data, context) => renderAppliedDateCell(data, context, options.projectId),
      };

      case 'rejectedDate':
        return {
          ...column,
          grow: true,
          cellType: 'date',
          render: (data, context) => renderAppliedDateCell(data, context, options.projectId, 'rejected'),
        };

    case 'applicantSource':
      return {
        ...column,
        grow: true,
        render: renderApplicantSourceCell,
      };
  }

  /**
   * NOTE: The following is a platform violation
   * https://aspireiq.atlassian.net/wiki/spaces/CEP/pages/475562033/Known+Platform+Violations#Member-App
   */
  if (['Instagram', 'Pinterest', 'TikTok', 'Twitter', 'YouTube'].includes(column.headerName)) {
    return {
      ...column,
      render: (rawValue, context) => renderSocialProfileCell(
        {
          username: rawValue,
          network: column.headerName.toLocaleLowerCase() as TNetworkIdentifier,
          refetchProjectCounts: options?.refetchProjectCounts,
        },
        context,
      ),
      editable: true,
    };
  }

  switch (column.type) {
    case FieldType.IMAGES:
      return {
        ...column,
        render: renderImagesCell,
      };

    case FieldType.ANNUAL:
      return {
        ...column,
        cellType: 'date',
      };

    case FieldType.DATE:
      return {
        ...column,
        cellType: 'date',
      };

    case FieldType.NUMBER:
      return {
        ...column,
        cellType: 'numeric',
      };
    case FieldType.PERCENTAGE:
      return {
        ...column,
        render: renderPercentageCell,
      };

    case FieldType.BOOLEAN:
      return {
        ...column,
        cellType: 'boolean',
        nullStr: 'N/A',
        trueStr: 'Yes',
        falseStr: 'No',
      };

    case FieldType.TEXT:
    case FieldType.EMAIL:
      if (isArray(column?.choices)) {
        return {
          ...column,
          editable: true,
          render: (value, context: ITableCellRenderContext) => {
            const data = [value];
            return renderTokenSelectionCell(
              data,
              column.choices as string[],
              context,
              true,
            );
          },
        };
      } else {
        return {
          ...column,
          render: !column.editable ? (value) => renderDefaultCell(value) : undefined,
        };
      }

    case FieldType.ARRAY:
      return {
        ...column,
        editable: true,
        render: (value, context: ITableCellRenderContext) => {
          const data = isArray(value) ? value : [];
          return renderTokenSelectionCell(
            data,
            column.choices as string[],
            context,
          );
        },
      };

    case FieldType.DYNAMIC_SELECT:
      return {
        ...column,
        editable: false,
        render: (value, context: ITableCellRenderContext) => {
          const data = isArray(value) ? value : [];
          return renderTokenSelectionCell(
            data,
            values(column.choices) as string[],
            context,
          );
        },
      };

    default:
      return {
        ...column,
        render: (value) => renderDefaultCell(value),
      };
  }
};
