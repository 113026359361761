import React, { useEffect } from 'react';
import {
 Button, message, Space, Spinner, Upload,
} from '@revfluence/fresh';
import { PlusIcon, TrashIcon } from '@revfluence/fresh-icons/regular/esm';
import { RcFile } from 'antd/lib/upload/interface';
import { useUploadContent } from '@frontend/app/hooks';
import { logger } from '@common';
import styles from './UploadCollectionItemImage.scss';

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLessThan10MB = file.size / 1024 / 1024 < 10;
  if (!isLessThan10MB) {
    message.error('Image must smaller than 10MB!');
  }
  return isJpgOrPng && isLessThan10MB;
};

export interface UploadCollectionItemImageProps {
  imageUrl: string;
  onChange: (imageUrl: string) => void;
  isLoading?: boolean;
}
export const UploadCollectionItemImage = ({ imageUrl, onChange, isLoading }: UploadCollectionItemImageProps) => {
  const { upload, isUploading, error } = useUploadContent({
    parentFolder: 'temp',
    serviceName: 'community',
  });

  useEffect(() => {
    if (error) {
      message.error(error.message || 'Failed to upload image');
      logger.error('Error while uploading image in UploadCollectionItemImage', error);
    }
  }, [error]);

  const uploadButton = (
    <div>
      {isUploading || isLoading ? <Spinner /> : <PlusIcon />}
      {!isLoading && <div style={{ marginTop: 8 }}>{isUploading ? 'Uploading...' : 'Upload Image'}</div>}
    </div>
  );

  return (
    <Space align="center">
      <Upload
        name="avatar"
        listType="picture-card"
        className={styles.UploadCollectionItemImage}
        showUploadList={false}
        beforeUpload={beforeUpload}
        action={(file) => {
        upload(file, 'image', { imageCompressionEnabled: true }).then((res) => {
          onChange(res);
          }).catch((err) => {
            logger.error('Error while uploading image in UploadCollectionItemImage', err);
            message.error('Failed to upload image');
            });
        return null;
      }}
        accept=".png,.jpg,.jpeg"
        maxCount={1}
      >
        {(imageUrl && !isUploading && !isLoading) ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
      {(imageUrl && !isUploading && !isLoading) && (
      <Button
        type="text"
        danger
        icon={<TrashIcon />}
        onClick={() => onChange('')}
      />
    )}
    </Space>
  );
};
