import * as React from 'react';
import { map, filter, trim } from 'lodash';

import {
  IContentGuideline,
  IContentGuidelineSection,
  IGuidelinePosition,
} from './contentGuidelineModel';
import { SortableGuidelineList } from './GuidelineList';

import styles from './ContentGuidelines.scss';

const { useState } = React;

interface IProps {
  onGuidelinesChanged(newGuidelines: IContentGuideline[][]);

  guidelines: IContentGuideline[][];
  sections: IContentGuidelineSection[];
}

const sanitizeGuidelines = (guidelines, requiredLength) => {
  const newGuidelines = [...guidelines];
  while (newGuidelines.length < requiredLength) {
    newGuidelines.push([]);
  }
  return newGuidelines;
};

export const ContentGuidelines: React.FunctionComponent<IProps> = (props) => {
  const [selectedGuidelinePosition, setSelectedGuidelinePosition] = useState<IGuidelinePosition>(
    null,
  );

  const handleClickGuideline = (_: IContentGuideline, position: IGuidelinePosition) => {
    setSelectedGuidelinePosition(position);
  };

  const handleAddGuidelines = (
    position: IGuidelinePosition,
    ...guidelines: IContentGuideline[]
  ) => {
    const newGuidelines = sanitizeGuidelines(props.guidelines, props.sections.length);
    const { sectionIndex, listIndex } = position;
    const guidelinesForSection = newGuidelines[sectionIndex];

    newGuidelines[sectionIndex] = [
      ...guidelinesForSection.slice(0, listIndex),
      ...guidelines,
      ...guidelinesForSection.slice(listIndex + 1),
    ];

    props.onGuidelinesChanged(newGuidelines);
  };

  const handleRemoveGuideline = (position: IGuidelinePosition) => {
    const newGuidelines = sanitizeGuidelines(props.guidelines, props.sections.length);
    const sectionGuidelines = [...newGuidelines[position.sectionIndex]];
    sectionGuidelines.splice(position.listIndex, 1);
    newGuidelines[position.sectionIndex] = sectionGuidelines;
    props.onGuidelinesChanged(newGuidelines);
  };

  const handleBlur = () => {
    setSelectedGuidelinePosition(null);
  };

  const handleChangeGuideline = (newGuideline: IContentGuideline, position: IGuidelinePosition) => {
    if (newGuideline.text) {
      handleAddGuidelines(position, newGuideline);
    } else {
      handleRemoveGuideline(position);
    }
  };

  const handleSortEnd = (data: { oldIndex: number; newIndex: number; collection: number }) => {
    const { collection: sectionIndex, oldIndex, newIndex } = data;
    if (oldIndex === newIndex) {
      return;
    }
    const newGuidelines = sanitizeGuidelines(props.guidelines, props.sections.length);
    const sectionGuidelines = [...newGuidelines[sectionIndex]];
    const guideline = sectionGuidelines[oldIndex];
    sectionGuidelines.splice(oldIndex, 1);
    sectionGuidelines.splice(newIndex, 0, guideline);
    newGuidelines[sectionIndex] = sectionGuidelines;
    props.onGuidelinesChanged(newGuidelines);
  };

  const handleEnterKeyDown = (position: IGuidelinePosition) => {
    const { listIndex, sectionIndex } = position;
    const sectionGuidelines = props.guidelines[sectionIndex] || [];
    // Move to the next guidelines text field.
    if (listIndex === sectionGuidelines.length) {
      setSelectedGuidelinePosition({ listIndex: listIndex + 1, sectionIndex });
    }
  };

  const handlePaste = (pastedText: string, position: IGuidelinePosition) => {
    const textArr = filter(map(pastedText.split(/\r?\n/), (text) => trim(text)), (text) => !!text);
    const guidelines = map(textArr, (text) => ({ text }));
    setSelectedGuidelinePosition(null);
    handleAddGuidelines(position, ...guidelines);
  };

  return (
    <div className={styles.ContentGuidelines}>
      {map(props.sections, (section, sectionIndex) => (
        <React.Fragment key={sectionIndex}>
          <div className={styles.header}>{section.header}</div>
          <SortableGuidelineList
            selectedPosition={selectedGuidelinePosition}
            onChangeGuideline={handleChangeGuideline}
            onClickGuideline={handleClickGuideline}
            sectionIndex={sectionIndex}
            section={section}
            guidelines={props.guidelines[sectionIndex]}
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            onSortEnd={handleSortEnd as any}
            onPaste={handlePaste}
            onBlur={handleBlur}
            onEnterKeyDown={handleEnterKeyDown}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

ContentGuidelines.displayName = 'ContentGuidelines';
