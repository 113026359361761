import gql from 'graphql-tag';

export const ORDER_SHIPMENT_TRACKING_FRAGMENT = gql`
  fragment OrderShipmentTrackingFragment on OrderShipmentTracking {
    id
    shipmentStatus
    slug
    trackingNumber
    raw
    createdDate
    updatedDate
  }
`;
