import * as React from 'react';
import cx from 'classnames';

import { Tooltip } from '@components';

import { ISocialProfileDetailConfig } from '../utils';

const { useRef } = React;
import styles from './Detail.scss';

interface IProps {
  className?: string;
  data: ISocialProfileDetailConfig;
  layout?: 'vertical' | 'horizontal';
}

export const Detail: React.FC<IProps> = (props) => {
  const {
    className,
    data,
    layout = 'vertical',
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const renderIcon = () => {
    const Icon = data.icon ? data.icon.component : null;
    if (!Icon) {
      return;
    }

    const {
      className: iconClassName,
      ...restIconProps
    } = data.icon.props;

    return (
      <Icon
        className={cx(styles[Icon.displayName], iconClassName)}
        {...restIconProps}
      />
    );
  };

  return (
    <div
      className={cx(
        className,
        styles.detail,
        styles[data.type],
        styles[layout],
        {
          [styles.tooltip]: data && !!data.tooltipText,
        },
      )}
      ref={containerRef}
    >
      <div className={styles.icon}>{renderIcon()}</div>
      <div className={styles.title}>{data.title}</div>
      <div className={styles.value}>{data.value}</div>
      {data && data.tooltipText && (
        <Tooltip
          placement="bottom"
          mountRef={containerRef}
          tooltipColor="black"
          {...data.tooltipProps}
        >
          {data.tooltipText}
        </Tooltip>
      )}
    </div>
  );
};

Detail.displayName = 'Detail';
