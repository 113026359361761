import * as React from 'react';
import {
  filter, pick, map, size, orderBy,
} from 'lodash';

import {
  TNetworkIdentifier,
  BlogIcon,
  InstagramIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
  IconSelect,
} from '@components';

import { FilterSection } from '../FilterSection';
import { INetworkFilters, INetworkOptions } from '../models';

const { useMemo } = React;
import styles from './NetworkSection.scss';

const NetworkTypes: { [key: string]: TNetworkIdentifier } = Object.freeze({
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  BLOG: 'blog',
  TWITTER: 'twitter',
  PINTEREST: 'pinterest',
  TIKTOK: 'tiktok',
});

const pickFilters = (obj: any) => pick(obj, ['channel']);

const iconForChannel = (channel: TNetworkIdentifier) => {
  switch (channel) {
    case NetworkTypes.INSTAGRAM:
      return <InstagramIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.YOUTUBE:
      return <YoutubeIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.BLOG:
      return <BlogIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.TWITTER:
      return <TwitterIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.PINTEREST:
      return <PinterestIcon size={16} className={styles.networkIcon} />;
    case NetworkTypes.TIKTOK:
      return <TiktokIcon size={16} className={styles.networkIcon} />;
    default:
      return null;
  }
};

const rankForChannel = (channel: TNetworkIdentifier) => {
  switch (channel) {
    case NetworkTypes.INSTAGRAM:
      return 0;
    case NetworkTypes.TIKTOK:
      return 1;
    case NetworkTypes.YOUTUBE:
      return 2;
    case NetworkTypes.PINTEREST:
      return 3;
    case NetworkTypes.BLOG:
      return 4;
    case NetworkTypes.TWITTER:
      return 5;
    default:
      return null;
  }
};

const DEMO_FEATURE_CHANNELS = ['instagram'];
const EXCLUDED_CHANNELS = ['twitter'];

interface IProps extends INetworkFilters, INetworkOptions {
  onChange(networkFilters: INetworkFilters);
  shouldShowDemoFeatures?: boolean;
}

export const NetworkSection: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    channel,
    channelOptions,
    hideChannels,
    onChange,
    shouldShowDemoFeatures = true,
  } = props;

  const getChannelOptions = useMemo(() => (
    orderBy(
      map(
        filter(channelOptions, (channel) => {
          if (shouldShowDemoFeatures) {
            return DEMO_FEATURE_CHANNELS.includes(channel);
          }

          return !EXCLUDED_CHANNELS.includes(channel);
        }),
        (channel: TNetworkIdentifier) => ({
          icon: iconForChannel(channel),
          value: channel,
          rank: rankForChannel(channel),
        }),
      ), 'rank', 'asc',
    )
  ), [channelOptions, shouldShowDemoFeatures]);

  const handleChangeChannel = (selectedOption: TNetworkIdentifier) => {
    onChange({
      ...pickFilters(props),
      channel: selectedOption,
    });
  };

  const hideChannelsFilterSection = hideChannels || size(getChannelOptions) < 2;

  return (
    <FilterSection
      header="Channels"
      isSubsection
      hide={hideChannelsFilterSection}
      classNames={[styles.NetworkSection]}
    >
      <IconSelect
        onChange={handleChangeChannel}
        selectedOption={channel}
        options={getChannelOptions}
        defaultSelectedOption={channelOptions[0]}
      />
    </FilterSection>
  );
});

NetworkSection.defaultProps = {
  channel: NetworkTypes.INSTAGRAM,
  channelOptions: [NetworkTypes.INSTAGRAM],
};
