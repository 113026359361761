import * as React from 'react';
import { Dropdown } from 'antd';
import { filter, keys } from 'lodash';

import { Button } from '@revfluence/fresh';
import { AngleDownIcon } from '@revfluence/fresh-icons/solid/esm';
import { CirclePlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { IEnabledOfferSources } from '@affiliates/types';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { AddOfferDropdown } from './AddOfferDropdown';
import { TOnClickAddOffer } from './types';

import styles from './OfferTable.scss';

interface IProps {
  disabled: boolean;
  missingShopifyCredentials: boolean;
  onClick: TOnClickAddOffer;
  sources: IEnabledOfferSources;
}

export const AddOfferButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
 disabled, missingShopifyCredentials, onClick, sources,
} = props;
  const enabledSources: OFFER_SOURCE[] = filter(keys(sources), (k: OFFER_SOURCE): k is OFFER_SOURCE => !!sources[k]);
  if (enabledSources.length === 1) {
    // only 1 enabled offer source so just skip the dropdown entirely
    return (
      <Button
        className={styles.addDropDownButton}
        disabled={disabled}
        icon={<AngleDownIcon />}
        onClick={onClick[enabledSources[0]]}
        title="Add Offer"
        type="primary"
      >
        Add Offer
      </Button>
    );
  }

  const menu = (
    <AddOfferDropdown missingShopifyCredentials={missingShopifyCredentials} onClickSource={onClick} sources={sources} />
  );
  return (
    <Dropdown trigger={['click']} overlay={menu}>
      <Button className={styles.addDropDownButton} disabled={disabled} icon={<CirclePlusIcon />} title="Add Offer" type="primary">
        Add Offer
        <AngleDownIcon />
      </Button>
    </Dropdown>
  );
});

AddOfferButton.displayName = 'AddOfferButton';
