import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
  isNumber,
  isString,
} from 'lodash';

import {
  AmbassadorIcon,
  CheckCircleIcon,
  StarIcon,
} from '@components';
import { Emoji } from '@components';
import { TooltipIcon } from '@components';
import { Image } from '@components';
import { tooltipText } from '@frontend/utils/tooltipsUtils';

import { Card } from './Card';
import { Posts } from './Posts';
import { Tags } from './Tags';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import {
  audienceCountLabel,
  getDisplayName,
} from '../utils';

import styles from './ProfileCard.scss';

export const ProfileCard: React.FC<{ className?: string }> = (props) => {
  const { className } = props;

  return (
    <Card className={cx(className, styles.ProfileCard)}>
      <AccountInfo />
      <div className={styles.separator} />
      <Posts />
      <Tags className={styles.hashtags} />
    </Card>
  );
};

ProfileCard.displayName = 'ProfileCard';

const AccountInfo: React.FC = () => {
  const { socialAccount } = useSocialProfileContext();

  const displayName = getDisplayName({ socialAccount });
  const followerCountText = audienceCountLabel(socialAccount);

  return (
    <div className={styles.AccountInfo}>
      <a
        href={socialAccount.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          className={styles.image}
          src={socialAccount.profile_image || socialAccount.profile_picture}
        />
      </a>
      <div className={styles.info}>
        <a
          className={styles.username}
          href={socialAccount.link}
          target="_blank"
          rel="noopener noreferrer"
          title={displayName}
        >
          <h4>
            {displayName}
            {socialAccount.is_ambassador && (
              <TooltipIcon
                className={cx(styles.ambIcon, styles.tooltip)}
                icon={<AmbassadorIcon size={14} />}
                tooltipText={tooltipText.ambassador}
              />
            )}
          </h4>
        </a>
        {isString(socialAccount.location_name) && (
          <span className={styles.location}>
            <Emoji
              name="pin"
              className={styles.icon}
            />
            {socialAccount.location_name}
          </span>
        )}
        <span className={styles.stats}>
          {followerCountText && (
            <>
              <Emoji
                name="heads"
                className={styles.icon}
              />
              {followerCountText}
            </>
          )}
          {isNumber(socialAccount.projects_completed) && (
            <>
              <span className={styles.verticalSeparator}>&#124;</span>
              <TooltipIcon
                className={styles.tooltip}
                icon={(
                  <>
                    <CheckCircleIcon
                      size={18}
                      className={cx(styles.icon, styles.checkCircleIcon)}
                    />
                    {socialAccount.projects_completed}
                  </>
                )}
                tooltipText={tooltipText.collabsCompleted}
              />
            </>
          )}
          {isNumber(socialAccount.rating) && (
            <>
              <span className={styles.verticalSeparator}>&#124;</span>
              <TooltipIcon
                className={styles.tooltip}
                icon={(
                  <>
                    <StarIcon
                      size={16}
                      className={cx(styles.icon, styles.starIcon)}
                    />
                    {numeral(socialAccount.rating).format('0.0')}
                  </>
                )}
                tooltipText={tooltipText.collabsRating}
              />
            </>
          )}
        </span>
      </div>
    </div>
  );
};
