import React, { useMemo } from 'react';
import { WidgetContainer, MainMetric, LegendGroup } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import {
 Cell, Pie, PieChart, ResponsiveContainer,
} from 'recharts';
import { useGetOrdersTypeBreakdown } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrdersTypeBreakdown';
import { formatDistanceStrict } from 'date-fns';
import { isNil } from 'lodash';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

interface PFAOrdersTypeProps {
  className?: string;
}

export default function PFAOrdersTypeBreakdown({ className }: PFAOrdersTypeProps) {
  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const distanceInWords = formatDistanceStrict(startDate, endDate);

  const { isOrdersTypeBreakdownLoading, ordersTypeBreakdown } = useGetOrdersTypeBreakdown({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const totalOrders = useMemo(() => {
    let total = 0;
    if (ordersTypeBreakdown?.creatorSelected) {
      total += ordersTypeBreakdown.creatorSelected;
    }
    if (ordersTypeBreakdown?.giftOrders) {
      total += ordersTypeBreakdown.giftOrders;
    }
    if (ordersTypeBreakdown?.offlineProcessed) {
      total += ordersTypeBreakdown.offlineProcessed;
    }
    return total;
  }, [ordersTypeBreakdown]);

  const data = [
    { label: 'Creator Selected', value: ordersTypeBreakdown?.creatorSelected, color: 'hsl(var(--refresh-blue-6))' },
    { label: 'Gift Orders', value: ordersTypeBreakdown?.giftOrders, color: 'hsl(var(--refresh-lime-6))' },
    { label: 'Offline Processed', value: ordersTypeBreakdown?.offlineProcessed, color: 'hsl(var(--refresh-yellow-6))' },
  ];

  const isDataEmpty = data.every((item) => !item.value);

  const emptyData = [
    { label: 'No Data', value: 100, color: 'hsl(var(--refresh-gray-2))' },
  ];

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col gap-6 h-full justify-center">
      <div className="flex flex-col flex-wrap items-start xl:flex-row xl:gap-6">
        <MainMetric
          heading="Total Orders"
          value={totalOrders}
          size="medium"
          icon={null}
          metricType="count"
          trend={ordersTypeBreakdown?.trendIncrease}
          subHeading={!isNil(ordersTypeBreakdown?.trendIncrease) ? `in last ${distanceInWords}` : undefined}
        />
        <ResponsiveContainer width={220} height={110}>
          <PieChart width={100} height={100}>
            <Pie
              data={isDataEmpty ? emptyData : data}
              dataKey="value"
              cx="50%"
              cy="100%"
              startAngle={180}
              endAngle={0}
              stroke="none"
              outerRadius={100}
              innerRadius={60}
            >
              {isDataEmpty && <Cell fill="hsl(var(--refresh-grey-1))" />}
              {!isDataEmpty && (
                <>
                  <Cell fill="hsl(var(--refresh-blue-6))" />
                  <Cell fill="hsl(var(--refresh-lime-6))" />
                  <Cell fill="hsl(var(--refresh-yellow-6))" />
                </>
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <LegendGroup
        options={data}
        optionShape="square"
        optionDirection="vertical"
        className="justify-between"
      />
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Order Type Breakdown"
      className={`${className}`}
      bgColor="bg-primary-foreground"
      textColor="text-primary"
    >
      {isOrdersTypeBreakdownLoading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
