._Video_17oqv_1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
._Video_17oqv_1 ._duration_17oqv_9 {
  padding: 0 5px;
  position: absolute;
  left: 8px;
  top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  user-select: none;
  border-radius: 3px;
  background-color: rgba(26, 24, 24, 0.72);
  color: white;
  font-size: 13px;
  line-height: 16px;
}
._Video_17oqv_1 ._duration_17oqv_9 ._playIcon_17oqv_25 {
  margin-right: 5px;
  transform: rotate(-90deg);
}
._Video_17oqv_1 ._video_17oqv_29 {
  max-width: 100%;
  max-height: 100%;
}