import * as React from 'react';
import {
  Card,
  Col,
  Row,
  Typography,
} from '@revfluence/fresh';
import { GearIcon } from '@revfluence/fresh-icons/regular/esm';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { isString } from 'lodash';

import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { useClientFeatureEnabled } from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import styles from './ContentCard.scss';
import { EllipsisLabel } from '..';

interface IProps {
  cardId: string;
  description: React.ReactNode;
  href?: string;
  image?: {
    fallbackUrl: string;
    title: string;
    url: string;
  };
  notificationCount: number;
  settingsHref?: string;
  size: 'small' | 'large';
  status?: string;
  title: React.ReactNode;
}

const {
  useState,
  useMemo,
  useCallback,
} = React;

export const ContentCard: React.FC<IProps> = React.memo((props) => {
  const {
    cardId,
    description,
    href,
    image,
    notificationCount,
    settingsHref,
    size,
    status,
    title,
  } = props;

  const history = useHistory();

  const [shouldFallbackImage, setShouldFallbackImage] = useState(false);
  const setImageFailed = useCallback(() => setShouldFallbackImage(true), []);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const isArchived = useMemo(() => status === ProjectStatus.Archived, [status]);

  const cover = useMemo(() => {
    if (!image) {
      return undefined;
    }
    return (
      <>
        <img
          alt={image.title}
          className={cx(
            styles.coverImage,
            { [styles.archivedImage]: isArchived && isArchiveProjectEnabled },
          )}
          loading="lazy"
          onError={setImageFailed}
          src={shouldFallbackImage ? image.fallbackUrl : image.url}
        />
        {notificationCount > 0 && (
          <div className={styles.notification}>
            {notificationCount}
          </div>
        )}
      </>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    image,
    setImageFailed,
    notificationCount,
    shouldFallbackImage,
  ]);

  const onClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isString(href)) {
      return;
    }
    history.push(href);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href]);

  const settingsLink = useMemo((): React.ReactNode => {
    if (!settingsHref) {
      return null;
    }

    return (
      <Typography.Link
        className={styles.settingsLink}
        href={settingsHref}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          history.push(settingsHref);
        }}
        type="secondary"
      >
        <GearIcon />
      </Typography.Link>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsHref]);

  const cardClassName = cx({
    [styles.ContentCard]: true,
    [styles.smallContentCard]: size === 'small',
    [styles.largeContentCard]: size === 'large',
  });

  return (
    <CSSTransition
      appear
      classNames="fade"
      exit={false}
      in
      timeout={250}
      unmountOnExit
    >
      <Card
        cover={cover}
        className={cardClassName}
        key={cardId}
        hoverable
        onClick={onClickHandler}
      >
        <div className={styles.cardBody}>
          <Row>
            <Col flex="auto">
              <Typography.Title
                className={
                  cx(
                    styles.title,
                    { [styles.archivedTitle]: isArchived && isArchiveProjectEnabled },
                  )
                }
                level={5}
              >
                <EllipsisLabel tooltipPlacement="top">
                  {title}
                </EllipsisLabel>
              </Typography.Title>
            </Col>
          </Row>
          <Row align="middle" wrap={false}>
            <Col flex="auto">
              <Typography.Text className={styles.description}>
                {description}
              </Typography.Text>
            </Col>
            {settingsLink && (
              <Col flex="16px">
                {settingsLink}
              </Col>
            )}
          </Row>
        </div>
      </Card>
    </CSSTransition>
  );
});
ContentCard.displayName = 'ContentCard';
