/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1vxlz_157 {
  display: flex;
}

._justify-content-space-between_1vxlz_161 {
  justify-content: space-between;
}

._tabular-nums_1vxlz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1vxlz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CreateNewField_1vxlz_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._CreateNewField_1vxlz_178 ._title_1vxlz_183 {
  margin: 0.25rem 0 0.75rem;
  font-weight: 600;
  text-align: center;
}
._CreateNewField_1vxlz_178 ._divider_1vxlz_188 {
  margin: 0.75rem 0;
}
._CreateNewField_1vxlz_178 ._divider_1vxlz_188:last-of-type {
  margin-top: auto;
}
._CreateNewField_1vxlz_178 ._errorMessage_1vxlz_194 {
  margin-top: 0.25rem;
  color: #d9534f;
  font-size: 0.75rem;
}

._overlay_1vxlz_200 .ant-popover-inner-content {
  width: 16.25rem;
  height: 18.75rem;
}

._clickable_1vxlz_205 {
  margin-bottom: 0.25rem;
  color: #3996e0;
  cursor: pointer;
}