import { useMemo } from 'react';
import { isNull, isNumber } from 'lodash';

import {
  OFFER_CONVERSION_TYPE,
  OFFER_PAYOUT_TYPE,
  OFFER_PAYOUT_TYPE_PROMO,
} from '@affiliates/types/globalTypes';
import { formatInteger, formatMoney } from '@affiliates/utils';

const getConversionText = (conversionType: OFFER_CONVERSION_TYPE | null): string => {
  if (isNull(conversionType)) {
    return 'Conversion';
  }
  switch (conversionType) {
    case OFFER_CONVERSION_TYPE.DOWNLOADS:
      return 'Download';
    case OFFER_CONVERSION_TYPE.CONVERSIONS:
      return 'Conversion';
    case OFFER_CONVERSION_TYPE.EMAIL_SUBMITS:
      return 'Email';
    case OFFER_CONVERSION_TYPE.LEAD_GENERATIONS:
      return 'Lead';
  }
};

// exported for testing
export const getPayoutLabel = (
  payoutType: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO,
  flatPayout: number | null,
  percentPayout: number | null,
  conversionType: OFFER_CONVERSION_TYPE | null,
): string | null => {
  const isValidNumber = (value: number) => isNumber(value) && !isNaN(value) && value >= 0;
  switch (payoutType) {
    case OFFER_PAYOUT_TYPE.SALE:
    case OFFER_PAYOUT_TYPE_PROMO.SALE: {
      if (isValidNumber(percentPayout)) {
        return `${formatInteger(percentPayout)}% Commission`;
      }
      break;
    }
    case OFFER_PAYOUT_TYPE.CONVERSION:
    case OFFER_PAYOUT_TYPE_PROMO.CONVERSION: {
      if (isValidNumber(flatPayout)) {
        const conversionText = getConversionText(conversionType);
        return `$${formatMoney(flatPayout)} per ${conversionText}`;
      }
      break;
    }
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
    case OFFER_PAYOUT_TYPE_PROMO.CONVERSION_AND_SALE: {
      if (isValidNumber(flatPayout) && isValidNumber(percentPayout)) {
        const conversionText = getConversionText(conversionType);
        return `$${formatMoney(flatPayout)} per ${conversionText} + ${Math.round(percentPayout)}% Commission`;
      }
      break;
    }
    case OFFER_PAYOUT_TYPE.CLICK: {
      if (isValidNumber(flatPayout)) {
        return `$${formatMoney(flatPayout)} per Click`;
      }
      break;
    }
  }
  return null;
};

export const usePayoutLabel = (
  payoutType: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO,
  flatPayout: number | null,
  percentPayout: number | null,
  conversionType: OFFER_CONVERSION_TYPE | null,
): string | null => useMemo(
  () => getPayoutLabel(payoutType, flatPayout, percentPayout, conversionType),
  [payoutType, flatPayout, percentPayout, conversionType],
);
