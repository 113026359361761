import * as React from 'react';
import cx from 'classnames';

import { Notice } from '@components';
import { Video, IVideoProps } from '@components';

import styles from './VideoContentDetail.scss';

const { useState } = React;

interface IProps extends IVideoProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const VideoContentDetail: React.FunctionComponent<IProps> = React.memo((props) => {
  const { className, ...rest } = props;
  const [failed, setFailed] = useState(false);

  return (
    <div className={cx(styles.VideoContentDetail, className)}>
      <Video
        {...rest}
        onError={() => setFailed(true)}
      />
      {failed && (
        <Notice
          type="error"
          showDivider
          className={styles.notice}
        >
          Failed to load the video, please try download it instead.
        </Notice>
      )}
    </div>
  );
});

VideoContentDetail.displayName = 'VideoContentDetail';
