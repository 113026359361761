/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1b5jq_157 {
  display: flex;
}

._justify-content-space-between_1b5jq_161 {
  justify-content: space-between;
}

._tabular-nums_1b5jq_165 {
  font-variant-numeric: tabular-nums;
}

._ContentFiltersHeader_1b5jq_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1b5jq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._ContentFiltersHeader_1b5jq_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._ContentFiltersHeader_1b5jq_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._ContentFiltersHeader_1b5jq_169 ::-webkit-scrollbar-track, ._ContentFiltersHeader_1b5jq_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._ContentFiltersHeader_1b5jq_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._ContentFiltersHeader_1b5jq_169 ._button_1b5jq_204 {
  margin-bottom: 16px;
}
._ContentFiltersHeader_1b5jq_169 ._separator_1b5jq_207 {
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e9e8ea;
}
._ContentFiltersHeader_1b5jq_169 ._syncedLabel_1b5jq_213 {
  vertical-align: middle;
}
._ContentFiltersHeader_1b5jq_169 ._syncedIcon_1b5jq_216 {
  color: #8f8d91;
}
._ContentFiltersHeader_1b5jq_169 ._syncedArrow_1b5jq_219 {
  vertical-align: middle;
  margin-left: 4px;
  transform: rotateZ(180deg);
}
._ContentFiltersHeader_1b5jq_169 ._googleDrive_1b5jq_224 {
  display: block;
  margin-bottom: 16px;
  text-decoration: none;
}