import { toLowerAndTrim } from '@frontend/utils';

export const isUSA = (country: string) => {
  const cleanCountry = toLowerAndTrim(country.replaceAll(/[^a-zA-Z ]/g, ''));

  if (['us', 'usa'].includes(cleanCountry)) {
    return true;
  }

  // !cleanCountry.includes('island') to avoid false positives as in the case of 'united states minor outlying islands'
  if (cleanCountry.includes('united states') && !cleanCountry.includes('island')) {
    return true;
  }

  return false;
};
