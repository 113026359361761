import gql from 'graphql-tag';

export const CREATE_DEEPLINK_MUTATION = gql`
  mutation CreateDeepLink($data: AffiliateDeepLinkCreateInput!) {
    createdDeeplink: createDeepLink(data: $data) {
        affiliateDeepLinkUrl,
        createdDate,
        label,
        url,
        deletedDate,
        id,
        shortLink,
        tuneShortLinkId,
    }
  }
`;
