import React from 'react';
import {
  MainMetric, SecondaryCardMetric,
} from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { useGetCatalogInsightsSummary } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCatalogInsightsSummary';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

export default function PFACatalogStats() {
  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const { catalogInsightsSummary, isCatalogInsightsSummaryLoading } = useGetCatalogInsightsSummary({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex items-center justify-between gap-2 text-primary">
      <SecondaryCardMetric color="hsl(var(--refresh-blue))" className="flex-1">
        <MainMetric
          heading="Total Catalogs"
          value={catalogInsightsSummary?.totalCatalogs}
          size="small"
          icon={null}
          metricType="count"
          className="flex-1"
        />
      </SecondaryCardMetric>
      <SecondaryCardMetric color="hsl(var(--refresh-teal))" className="flex-1">
        <MainMetric
          heading="Total Collections"
          value={catalogInsightsSummary?.totalCollections}
          size="small"
          icon={null}
          metricType="count"
          className="flex-1"
        />
      </SecondaryCardMetric>
      <SecondaryCardMetric color="hsl(var(--refresh-magenta))" className="flex-1">
        <MainMetric
          heading="Total Unique Products"
          value={catalogInsightsSummary?.totalProducts}
          size="small"
          icon={null}
          metricType="count"
          className="flex-1"
        />
      </SecondaryCardMetric>
      <SecondaryCardMetric color="hsl(var(--refresh-yellow))" className="flex-1">
        <MainMetric
          heading="Total Variants"
          value={catalogInsightsSummary?.totalVariants}
          size="small"
          icon={null}
          metricType="count"
          className="flex-1"
        />
      </SecondaryCardMetric>
    </div>
  );
  return (
    isCatalogInsightsSummaryLoading ? loadingContent : mainContent
  );
}
