import * as React from 'react';
import { uniqueId } from 'lodash';

import { SvgIcon, ISvgIconProps } from '@components';

export const EyeIcon = React.memo((props: ISvgIconProps) => {
  const maskId = uniqueId('EyeIconMask');
  return (
    <SvgIcon {...props}>
      <g>
        <path
          id={uniqueId('EyeIcon')}
          fillRule="evenodd"
          clipRule="evenodd"
          d="
            M11.729 5.00299C17.706 4.81599 21.229 10.39 21.868 11.502C22.044 11.811 22.044 12.189 21.868
            12.498C21.014 13.986 17.798 18.856 12.271 18.997C12.188 18.999 12.106 19 12.025 19C6.162 19 2.762
            13.595 2.132 12.498C1.956 12.189 1.956 11.811 2.132 11.502C2.986 10.014 6.202 5.14399 11.729 5.00299ZM12
            15.5C10.07 15.5 8.5 13.93 8.5 12C8.5 10.07 10.07 8.49999 12 8.49999C13.93 8.49999 15.5 10.07 15.5 12C15.5
            13.93 13.93 15.5 12 15.5ZM12 10.5C11.173 10.5 10.5 11.173 10.5 12C10.5 12.827 11.173 13.5 12 13.5C12.827
            13.5 13.5 12.827 13.5 12C13.5 11.173 12.827 10.5 12 10.5Z
          "
          fill={props.fill}
        />
        <mask id={maskId} mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="4" width="20" height="15">
          <path
            id={uniqueId('EyeIcon')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="
              M11.729 5.00299C17.706 4.81599 21.229 10.39 21.868 11.502C22.044 11.811 22.044 12.189 21.868
              12.498C21.014 13.986 17.798 18.856 12.271 18.997C12.188 18.999 12.106 19 12.025 19C6.162 19 2.762
              13.595 2.132 12.498C1.956 12.189 1.956 11.811 2.132 11.502C2.986 10.014 6.202 5.14399 11.729 5.00299ZM12
              15.5C10.07 15.5 8.5 13.93 8.5 12C8.5 10.07 10.07 8.49999 12 8.49999C13.93 8.49999 15.5 10.07 15.5 12C15.5
              13.93 13.93 15.5 12 15.5ZM12 10.5C11.173 10.5 10.5 11.173 10.5 12C10.5 12.827 11.173 13.5 12 13.5C12.827
              13.5 13.5 12.827 13.5 12C13.5 11.173 12.827 10.5 12 10.5Z
            "
            fill={props.fill || '#FFFFFF'}
          />
        </mask>
        <g mask={`url(#${maskId}`} />
      </g>
    </SvgIcon>
  );
});

EyeIcon.displayName = 'EyeIcon';
