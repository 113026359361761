import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const DownloadIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12.8,14.3l2.3-2.3c0.3-0.3,0.8-0.3,1.2,0c0.3,0.3,0.3,0.8,0,1.2l-3.7,3.7
      c-0.3,0.3-0.8,0.3-1.2,0l-3.7-3.7c-0.3-0.3-0.3-0.8,0-1.2c0.3-0.3,0.8-0.3,1.2,0l2.3,2.3V2.8c0-1.1,1.6-1.1,1.6,0V14.3z M20.3,18.4
      c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8c0,1.9-1.3,3.6-3.1,3.6H5.2c-1.7,0-3.1-1.6-3.1-3.6c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8
      c0,1.1,0.7,1.9,1.4,1.9h13.7C19.6,20.4,20.3,19.5,20.3,18.4z"
    />
  </SvgIcon>
));

DownloadIcon.displayName = 'DownloadIcon';

export { DownloadIcon };
