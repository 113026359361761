import * as React from 'react';
import { map } from 'lodash';
import {
 Divider, Row, Col, Space, Typography, Switch,
} from '@revfluence/fresh';
import { LoadSpinner } from '@components';
import { logger } from '@common';

import { useGetNotificationSettings, useSetNotificationSettings } from '@frontend/app/hooks';
import { NotificationSourceType, NotificationType, NotificationMediumType } from '@frontend/app/types/globalTypes';

const { Text, Title } = Typography;

interface IProps {
  className?: string;
}
type TSourceType = 'inbox' | 'social_post' | 'message_digest' | '__typename';
type TNotificationType = 'new_message' | 'assigned_to_you' | 'message_digest';

const NotificationSourceMap = {
  inbox: NotificationSourceType.INBOX,
  social_post: NotificationSourceType.SOCIAL,
  message_digest: NotificationSourceType.MESSAGE_DIGEST,
};
const NotificationTypeMap = {
  new_message: NotificationType.NEW_MESSAGE,
  assigned_to_you: NotificationType.ASSIGNED_TO_YOU,
  message_digest: NotificationType.MESSAGE_DIGEST,
};
const NotificationSourceLabel = {
  social_post: 'Social Post',
  message_digest: 'Daily Digest',
};

function generateText(source: TSourceType, type: TNotificationType) {
  switch (source) {
    case 'inbox': {
      if (type === 'new_message') {
        return 'Notifications for new messages';
      } else if (type === 'assigned_to_you') {
        return 'Notifications for messages assigned to you';
      }

      return '';
    }

    case 'social_post': {
      if (type === 'new_message') {
        return 'Notifications for new posts';
      }

      return '';
    }

    case 'message_digest': {
      if (type === 'message_digest') {
        return 'Notifications sent to you every day that contains updates from your communities';
      }

      return '';
    }
  }
}

/**
 * @type {React.FC}
 */
export const NotificationSettings: React.FC<IProps> = React.memo((props) => {
  const { loading, settings, refetch } = useGetNotificationSettings();
  const { loading: updating, setNotificationSettings } = useSetNotificationSettings();

  if (loading) {
    return <LoadSpinner />;
  }

  return (
    <Space direction="vertical" className={props.className}>
      <Title level={3}>Notification Settings</Title>

      {map(settings, (setting, source: TSourceType) => {
        if (source === '__typename') {
          return null;
        }

        return (
          <div key={source}>
            <Row key={source} gutter={24}>
              <Col span={24}>
                <Text strong>{NotificationSourceLabel[source]}</Text>
                {map(setting, (value, type: TNotificationType) => {
                  const emailEnabled = !!value.email;

                  return (
                    <Row key={type} gutter={24} justify="space-between" align="middle">
                      <Col>
                        <Text>{generateText(source, type)}</Text>
                      </Col>
                      <Col>
                        <Space>
                          <Switch
                            checked={emailEnabled}
                            loading={updating}
                            disabled={updating}
                            onClick={() => {
                              setNotificationSettings({
                                variables: {
                                  params: {
                                    source: NotificationSourceMap[source],
                                    type: NotificationTypeMap[type],
                                    medium: NotificationMediumType.EMAIL,
                                    enabled: !emailEnabled,
                                  },
                                },
                              })
                                .then(() => refetch())
                                .catch(logger.error);
                            }}
                          />
                          <Text>Email</Text>
                        </Space>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
            <Divider />
          </div>
        );
      })}
    </Space>
  );
});

NotificationSettings.displayName = 'NotificationSettings';
