import 'css-chunk:src/components/common/CreatorDetail/CreatorDetail.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1yz7m_157",
  "justify-content-space-between": "_justify-content-space-between_1yz7m_161",
  "tabular-nums": "_tabular-nums_1yz7m_165",
  "CreatorDetail": "_CreatorDetail_1yz7m_169",
  "detailHeader": "_detailHeader_1yz7m_174",
  "invite": "_invite_1yz7m_174",
  "header": "_header_1yz7m_213",
  "footer": "_footer_1yz7m_213",
  "creatorName": "_creatorName_1yz7m_223",
  "favoriteButton": "_favoriteButton_1yz7m_233",
  "actionButtonGroup": "_actionButtonGroup_1yz7m_238",
  "favorite": "_favorite_1yz7m_233",
  "info": "_info_1yz7m_256",
  "images": "_images_1yz7m_260",
  "mainImageContainer": "_mainImageContainer_1yz7m_264",
  "mainImage": "_mainImage_1yz7m_264",
  "imageList": "_imageList_1yz7m_278",
  "imageListItem": "_imageListItem_1yz7m_284",
  "active": "_active_1yz7m_298",
  "details": "_details_1yz7m_304",
  "contentItem": "_contentItem_1yz7m_313",
  "metrics": "_metrics_1yz7m_320",
  "metricsTitle": "_metricsTitle_1yz7m_324",
  "helpIcon": "_helpIcon_1yz7m_331",
  "metricsValue": "_metricsValue_1yz7m_335",
  "score": "_score_1yz7m_338",
  "positive": "_positive_1yz7m_341",
  "neutral": "_neutral_1yz7m_344",
  "negative": "_negative_1yz7m_347",
  "brandMention": "_brandMention_1yz7m_350",
  "brandLogo": "_brandLogo_1yz7m_356",
  "subValue": "_subValue_1yz7m_363",
  "icon": "_icon_1yz7m_368",
  "followerIcon": "_followerIcon_1yz7m_376",
  "followerAuthIcon": "_followerAuthIcon_1yz7m_379",
  "monthlyViewsIcon": "_monthlyViewsIcon_1yz7m_382",
  "expectedValueIcon": "_expectedValueIcon_1yz7m_385",
  "likeIcon": "_likeIcon_1yz7m_388",
  "sentimentIcon": "_sentimentIcon_1yz7m_391",
  "likeRatioIcon": "_likeRatioIcon_1yz7m_394",
  "bounceRateIcon": "_bounceRateIcon_1yz7m_397",
  "engagementIcon": "_engagementIcon_1yz7m_400",
  "engagementQualityIcon": "_engagementQualityIcon_1yz7m_403",
  "clickIcon": "_clickIcon_1yz7m_406",
  "commentIcon": "_commentIcon_1yz7m_409",
  "flagIcon": "_flagIcon_1yz7m_412",
  "hashtagIcon": "_hashtagIcon_1yz7m_415",
  "mentionIcon": "_mentionIcon_1yz7m_418",
  "saveIcon": "_saveIcon_1yz7m_421",
  "impressionIcon": "_impressionIcon_1yz7m_424",
  "pageViewIcon": "_pageViewIcon_1yz7m_427",
  "timeIcon": "_timeIcon_1yz7m_430",
  "cityIcon": "_cityIcon_1yz7m_433",
  "ageIcon": "_ageIcon_1yz7m_436",
  "genderIcon": "_genderIcon_1yz7m_439",
  "summary": "_summary_1yz7m_442",
  "userInfo": "_userInfo_1yz7m_452",
  "socialIcon": "_socialIcon_1yz7m_458",
  "name": "_name_1yz7m_462",
  "engagement": "_engagement_1yz7m_400",
  "followers": "_followers_1yz7m_471",
  "unit": "_unit_1yz7m_477",
  "content": "_content_1yz7m_313",
  "section": "_section_1yz7m_488",
  "sectionHeader": "_sectionHeader_1yz7m_491",
  "igInsightsIcon": "_igInsightsIcon_1yz7m_498",
  "verifiedIgInsights": "_verifiedIgInsights_1yz7m_501",
  "instagramIconWrapper": "_instagramIconWrapper_1yz7m_506",
  "divider": "_divider_1yz7m_520",
  "headerText": "_headerText_1yz7m_525",
  "sectionContent": "_sectionContent_1yz7m_530",
  "show": "_show_1yz7m_1"
};