import * as React from 'react';
import cx from 'classnames';
import { IModalProps } from '@affiliates/AspireUI';
import { Modal } from '@revfluence/fresh';
import { OFFER_TRACKING_TYPE } from '@affiliates/types/globalTypes';
import { ConversionTracking } from '../ConversionTracking/ConversionTracking';

import styles from './ConversionTrackingModal.scss';

const { useMemo } = React;

interface IProps extends IModalProps {
  pixelCode: string | null;
  postbackUrl: string | null;
  offerTrackingType: OFFER_TRACKING_TYPE;
}

export const ConversionTrackingModal: React.FC<IProps> = (props) => {
  const {
    pixelCode,
    postbackUrl,
    offerTrackingType,
    ...restProps
  } = props;
  const code = useMemo(() => {
    switch (offerTrackingType) {
      case OFFER_TRACKING_TYPE.HTTPS_IMAGE_PIXEL:
      case OFFER_TRACKING_TYPE.HTTPS_IFRAME_PIXEL:
        return pixelCode;
      case OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK:
      case OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID:
      case OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID:
        return postbackUrl;
      default:
        return '';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerTrackingType]);

  return (
    <Modal
      {...restProps}
      footer={null}
      maskClosable
      className={cx(restProps.className, styles.ConversionTrackingModal)}
    >
      <div className={styles.body}>
        <div className={styles.title}>Conversion Tracking</div>
        <ConversionTracking
          offerTrackingType={offerTrackingType}
          code={code}
        />
      </div>
    </Modal>
  );
};
