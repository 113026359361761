/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_7dv9t_157 {
  display: flex;
}

._justify-content-space-between_7dv9t_161 {
  justify-content: space-between;
}

._tabular-nums_7dv9t_165 {
  font-variant-numeric: tabular-nums;
}

._accountSection_7dv9t_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_7dv9t_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._accountSection_7dv9t_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._accountSection_7dv9t_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._accountSection_7dv9t_169 ::-webkit-scrollbar-track, ._accountSection_7dv9t_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._accountSection_7dv9t_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._accountSection_7dv9t_169 {
  padding: 0.75rem;
  width: 100%;
  display: flex;
  font-size: 0.75rem;
  border-top: 0.0625rem solid #ECECEC;
}
._accountSection_7dv9t_169 ._truncate_7dv9t_211 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  position: relative;
}
._accountSection_7dv9t_169 ._avatarImg_7dv9t_218 {
  max-width: 100%;
  height: 45px;
  overflow-x: hidden;
  width: 100%;
  border-radius: 100%;
}
._accountSection_7dv9t_169 ._avatarImg_7dv9t_218:hover {
  opacity: 0.9;
}
._accountSection_7dv9t_169 ._adminLink_7dv9t_228 {
  color: #218cb9;
}
._accountSection_7dv9t_169 ._accountNetworkIconContainer_7dv9t_231 {
  width: 1.5rem;
  height: 1.5rem;
  bottom: -0.75rem;
  right: -0.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 624.9375rem;
  z-index: 1;
}
._accountSection_7dv9t_169 ._demoAccountModeAccountNetworkIconContainer_7dv9t_244 {
  width: 1.75rem;
  height: 1.75rem;
  bottom: -0.75rem;
  right: -0.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 624.9375rem;
  z-index: 1;
}
._accountSection_7dv9t_169 ._dateContainer_7dv9t_257 {
  display: flex;
  margin-left: 10px;
}
._accountSection_7dv9t_169 ._dateContainer_7dv9t_257 ._dateTxt_7dv9t_261 {
  margin-right: 10px;
}
._accountSection_7dv9t_169 ._avatarContainer_7dv9t_264 {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  z-index: 0;
}
._accountSection_7dv9t_169 ._avatarContainer_7dv9t_264 > a {
  width: 100%;
  height: 100%;
  display: block;
}
._accountSection_7dv9t_169 ._txtContainer_7dv9t_275 {
  width: calc(100% - 61px);
}
._accountSection_7dv9t_169 ._accountNameContainer_7dv9t_278 {
  line-height: 1rem;
  margin-left: 10px;
  display: flex;
}
._accountSection_7dv9t_169 ._accountName_7dv9t_278 {
  font-weight: 600;
  margin-right: 0.15rem;
  color: #1A1818;
  text-decoration: none;
}
._accountSection_7dv9t_169 ._accountName_7dv9t_278:hover {
  color: #3996E0;
}
._accountSection_7dv9t_169 ._accountFollowersContainer_7dv9t_292 {
  line-height: 1rem;
  margin-left: 10px;
  display: flex;
}
._accountSection_7dv9t_169 ._accountFollowersContainer_7dv9t_292 ._accountFollowersTxt_7dv9t_297 {
  margin-right: 10px;
}
._accountSection_7dv9t_169 ._revLogo_7dv9t_300 {
  width: 16px;
  vertical-align: -3px;
  margin: 0 4px;
}