import React from 'react';
import {
  IResource,
  useRevokeResourceAccess,
} from '@frontend/app/hooks';
import { message } from '@revfluence/fresh';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular';
import KlaviyoResource from '../components/KlaviyoResource';

interface IProps {
  activeAccount: IResource;
  startSync: () => void;
  refetchResources: () => void;
  inProgress: boolean;
}

const KlaviyoSyncContainer: React.FC<IProps> = ({
 activeAccount, inProgress, startSync, refetchResources,
}) => {
  const { revokeAccess, loading: revoking } = useRevokeResourceAccess();
  const disconnectActiveAccount = async () => {
    const { data: { revoked } } = await revokeAccess({
      variables: {
        resourceId: activeAccount.id,
      },
    });

    if (revoked) {
      refetchResources();
    } else {
      message.error({
        icon: <TriangleExclamationIcon />,
        className: 'closable',
        content: 'An error occurred when removing this account. Please contact our support team.',
      });
    }
  };

  return (
    <KlaviyoResource
      userExternalDisplayId={activeAccount.authProvider.userExternalDisplayId}
      startSync={startSync}
      isRevoking={revoking}
      disconnectResource={disconnectActiveAccount}
      inProgress={inProgress}
    />
  );
};

KlaviyoSyncContainer.displayName = 'KlaviyoSyncContainer';

export default KlaviyoSyncContainer;
