import * as React from 'react';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { first } from 'lodash';
import { Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { IToastMessage, Toast, IToastRefHandles } from '@components';

import styles from './MessagingContext.scss';

const {
  useCallback, useRef, useState,
} = React;

export interface IMessagingContext {
  showSuccessMessage(message: string, duration?: number);
  showInfoMessage(message: string, duration?: number);
  showMessage(message: IToastMessage, duration?: number);
  showError(error: Error, duration?: number);
  showErrorMessage(message: string, duration?: number);
  showGenericErrorMessage();
  messageFromError(error: Error);
  isPaymentRequiredError(error: ApolloError);
}

export enum UsageEntity {
  USERS = 'users',
  PROGRAMS = 'programs',
  COLLABORATIONS = 'collaborations',
  BAM_CREATORS = 'BAM_creators',
  SALES_TRACKING = 'sales_tracking',
  SHOPIFY_CUSTOMER_SYNC = 'shopify_customer_sync',
}

const GENERIC_ERROR_MESSAGE = 'An unexpected error has occurred. Please contact help@aspireiq.com if this continues.';
const DElAY_ERROR_MESSAGE = 'It may take a few minutes to send your message. Please check back later to see if it was sent.';

const paymentErrorModalText = {
  [UsageEntity.PROGRAMS]: {
    title: 'Project Limit Reached',
    content: (
      <>
        You have reached the maximum number of Projects offered by your subscription. To learn more about your limits and how to upgrade, view Plan Details.
      </>
    ),
    upgradeReason: UsageEntity.PROGRAMS,
  },
  [UsageEntity.COLLABORATIONS]: {
    title: 'Active Collaboration Limit Reached',
    content: (
      <>
        You have reached the maximum number of collaborators on projects allowed by your subscription. To learn more about your limits and how to upgrade, view Plan Details.
      </>
    ),
    upgradeReason: UsageEntity.COLLABORATIONS,
  },
  [UsageEntity.USERS]: {
    title: 'Maximum Number of Team Members',
    content: (
      <>
        You have invited the maximum number of team members allowed by your Trial subscription. To learn more about your limits and how to upgrade, view Plan Details.
      </>
    ),
    upgradeReason: UsageEntity.USERS,
  },
};

export const MessagingContext = React.createContext<IMessagingContext>(null);

export const MessagingContextProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [showPaymentRequiredModal, setShowPaymentRequiredModal] = useState<boolean>(false);

  const handleViewPlanDetailsClick = useCallback((upgradeReason) => {
    setShowPaymentRequiredModal(false);
    history.push({
      ...location,
      pathname: '/settings/planDetails',
      search: '',
      state: {
        upgradeReason,
      },
    });
  }, [location, history]);

  const toastRef = useRef<IToastRefHandles>();

  const showMessage = useCallback((message: IToastMessage) => {
    if (toastRef.current) {
      toastRef.current.showMessage(message);
    }
  }, [toastRef]);

  const isPaymentRequiredError = (error: ApolloError): boolean => {
    if (error.graphQLErrors) {
      const graphQlError: GraphQLError = first(error.graphQLErrors);
      if (graphQlError
        && graphQlError.extensions
        && graphQlError.extensions.code === 'PAYMENT_REQUIRED'
      ) {
        return true;
      }
    }

    return false;
  };

  const showSuccessMessage = useCallback((message: string, duration?: number) => {
    showMessage({
      type: 'success',
      content: message,
      duration,
    });
  }, [showMessage]);

  const showInfoMessage = useCallback((message: string, duration?: number) => {
    showMessage({
      type: 'info',
      content: message,
      duration,
    });
  }, [showMessage]);

  const messageFromError = useCallback((error: ApolloError) => {
    const graphQLError: GraphQLError = first(error.graphQLErrors);
    if (graphQLError && graphQLError.extensions) {
      return graphQLError.message.includes('Message was not synced in the system: messageId:')
        ? DElAY_ERROR_MESSAGE
        : graphQLError.message;
    }
    return GENERIC_ERROR_MESSAGE;
  }, []);

  const showErrorMessage = useCallback((message: string, duration?: number) => {
    showMessage({
      type: 'error',
      content: message,
      duration,
    });
  }, [showMessage]);

  const showError = useCallback((error: ApolloError, duration?: number) => {
    if (isPaymentRequiredError(error)) {
      const graphQlError: GraphQLError = first(error.graphQLErrors);
      const data = graphQlError.extensions.data || {};
      if (!paymentErrorModalText[data.entity]) {
        // fallback in case entity is not recognized yet.
        showErrorMessage(messageFromError(error), duration);
        return;
      }

      const { title, content, upgradeReason } = paymentErrorModalText[data.entity];
      Modal.confirm({
        className: styles.paymentRequiredModal,
        title,
        icon: <ExclamationCircleOutlined />,
        autoFocusButton: null,
        content,
        okText: 'View Plan Details',
        cancelText: 'Close',
        onOk: () => handleViewPlanDetailsClick(upgradeReason),
        visible: showPaymentRequiredModal,
      });
      setShowPaymentRequiredModal(true);
    } else {
      showErrorMessage(messageFromError(error), duration);
    }
  },
  [
    showPaymentRequiredModal,
    showErrorMessage,
    messageFromError,
    handleViewPlanDetailsClick,
  ]);

  const showGenericErrorMessage = useCallback(() => {
    showMessage({
      type: 'error',
      content: GENERIC_ERROR_MESSAGE,
    });
  }, [showMessage]);
const value: IMessagingContext = {
    showMessage,
    showSuccessMessage,
    showInfoMessage,
    showError,
    showErrorMessage,
    showGenericErrorMessage,
    messageFromError,
    isPaymentRequiredError,
  };

  return (
    <MessagingContext.Provider value={value}>
      {children}
      {typeof window !== 'undefined' && <Toast useFresh ref={toastRef} />}
    </MessagingContext.Provider>
  );
};
