import * as React from 'react';
import { SyncOutlined } from '@ant-design/icons';
import cx from 'classnames';

import styles from './MembersWizardIcon.scss';

interface IRefreshMembersProps {
  noMargin?: boolean;
}

export const RefreshMembers: React.FC<IRefreshMembersProps> = React.memo((props) => {
  const { noMargin } = props;
  const className = cx(styles.MembersWizardIcon, styles.refreshMembers, {
    [styles.noMargin]: noMargin,
  });
  return (
    <div className={className}>
      <SyncOutlined size={24} />
    </div>
  );
});

RefreshMembers.displayName = 'RefreshMembersIcon';
