import * as React from 'react';
import {
  size,
  isNull,
  truncate,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './MemberIGDMActivityItem.scss';
import { IMemberActivity } from './types';
import { InstagramMessageItemDetails } from '../MessagingApp/MessageList/IGDM/InstagramMessageItem/InstagramMessageItemDetails';
import { cleanHtml } from '../MessagingApp/utils';

const { useState, useMemo, useCallback } = React;
const MAX_MESSAGE_SIZE = 300;

interface IIGDMActivityItemBodyProps {
  message: IMemberActivity;
  isStory: boolean;
}

export const IGDMActivityItemBody: React.FC<IIGDMActivityItemBodyProps> = ({ message, isStory }) => {
  const [showingMore, setShowingMore] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const toggleShowingMore = () => setShowingMore(!showingMore);

  const viewThreadForMessage = useCallback((message) => {
    history.push({
      ...location,
      pathname: `/messages/${message.threadId}`,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const messageText = useMemo(() => cleanHtml(message.payload.textHtml || message.payload.textPlain), [message.payload, cleanHtml]);
  const shouldHideLongerMessage = useMemo(() => ((!isNull(messageText)) ? size(messageText) > MAX_MESSAGE_SIZE : true), [messageText]);

  return (
    <div className={styles.body}>
      <InstagramMessageItemDetails
        messageItem={{
          ...message.payload,
          textMessage: shouldHideLongerMessage && !showingMore ? truncate(messageText, { length: MAX_MESSAGE_SIZE }) : messageText,
        }}
        sentByUser
        setIsExpired={undefined}
        hideMentionTitle
      />
      <div className={styles.actions}>
        {!isStory && shouldHideLongerMessage
          && (
            <div className={styles.action} onClick={toggleShowingMore}>
              {showingMore ? 'Show Less' : 'Show More'}
            </div>
          )}
        <div className={styles.action} onClick={() => viewThreadForMessage(message)}>
          Go to Thread
        </div>
      </div>
    </div>
  );
};
