/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_thec4_157 {
  display: flex;
}

._justify-content-space-between_thec4_161 {
  justify-content: space-between;
}

._tabular-nums_thec4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_thec4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ConversionTrackingModal_thec4_178 ._title_thec4_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ConversionTrackingModal_thec4_178 ._body_thec4_188 {
  min-height: 28.125rem;
}
._ConversionTrackingModal_thec4_178 ._title_thec4_178 {
  margin-bottom: 1.875rem;
  font-weight: normal;
  text-align: center;
}