import * as React from 'react';

import {
 FacebookIcon, InstagramIcon, PinterestIcon, SnapchatIcon, TiktokIcon, TwitterIcon, YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';
import { ImageIcon, VideoIcon } from '@revfluence/fresh-icons/regular/esm';
import { NetworkIcon, TNetworkIconType } from '@components';

export function getContentReviewIcon(type: TNetworkIconType): JSX.Element {
  if (type === 'instagram') {
    return <InstagramIcon />;
  }
  if (type === 'youtube') {
    return <YoutubeIcon />;
  }
  if (type === 'tiktok') {
    return <TiktokIcon />;
  }
  if (type === 'facebook') {
    return <FacebookIcon />;
  }
  if (type === 'pinterest') {
    return <PinterestIcon />;
  }
  if (type === 'twitter') {
    return <TwitterIcon />;
  }
  if (type === 'snapchat') {
    return <SnapchatIcon />;
  }
  if (type === 'additional_images') {
    return <ImageIcon />;
  }
  if (type === 'additional_videos') {
    return <VideoIcon />;
  }

  // fallback to network icon
  return <NetworkIcon identifier={type} />;
}
