import * as React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { Card } from './Card';
import { Detail } from './Detail';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import {
  DetailConfigType,
  getDetailConfigs,
} from '../utils';

const { useMemo } = React;
import styles from './PostPerformanceCard.scss';

export const PostPerformanceCard: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  const {
    hasData,
    network,
    socialAccount,
    fetchingDetailForId,
  } = useSocialProfileContext();

  const isLoading = !!fetchingDetailForId;

  // Set detail order and values
  const detailConfigs = useMemo(() => {
    if (!hasData) {
      return [];
    }
    switch (network) {
      case 'instagram':
        return getDetailConfigs({
          detailTypes: [
            DetailConfigType.AverageImpressionsPerPost,
            DetailConfigType.PostImpressionRatio,
            DetailConfigType.CommentSentiment,
            DetailConfigType.StoriesImpressionRatio,
            DetailConfigType.AverageSavesPerPost,
            DetailConfigType.AverageLikesPerPost,
            DetailConfigType.AverageCommentsPerPost,
            DetailConfigType.LikesToCommentsRatio,
          ],
          socialAccount,
          styles,
          isLoading,
        });
      case 'pinterest':
        return getDetailConfigs({
          detailTypes: [
            DetailConfigType.AverageCloseupsPerPin,
            DetailConfigType.AverageImpressionsPerPost,
            DetailConfigType.AverageSavesPerPost,
            DetailConfigType.AverageCommentsPerPost,
            DetailConfigType.AverageClicksPerPost,
          ],
          socialAccount,
          styles,
          isLoading,
        });
      case 'youtube':
        return getDetailConfigs({
          detailTypes: [
            DetailConfigType.TypicalViewsPerPost,
            DetailConfigType.CommentsPerPost,
            DetailConfigType.AverageLikesPerPost,
            DetailConfigType.SharesPerPost,
          ],
          socialAccount,
          styles,
          isLoading,
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, network, socialAccount]);

  const renderDetails = () => (
    <div className={styles.detailList}>
      {map(detailConfigs, (data, i) => (
        <Detail
          data={data}
          key={`post-performance-data-${i}`}
          layout="horizontal"
        />
      ))}
    </div>
  );

  return (
    <Card
      className={cx(
        className,
        styles.PostPerformanceCard,
        styles[network],
      )}
      header="Post Performance"
    >
      {renderDetails()}
    </Card>
  );
};

PostPerformanceCard.displayName = 'PostPerformanceCard';
