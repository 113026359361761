import * as React from 'react';
import { isEmpty, keyBy } from 'lodash';

import { NETWORK_NAME, TNetwork } from '@components';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import { ApplicationFormFieldsInput, ApplicationMemberFieldSchemaInput } from '@frontend/app/types/globalTypes';
import { CONTENT_TYPE } from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/context/model';
import { MemberFieldSchemasQuery_schemas } from '@frontend/app/queries/types/MemberFieldSchemasQuery';

import { IRequirementsInput } from '../types';

const { useMemo } = React;

const initialRequiredFormFields = new Set([
  'First Name',
  'Last Name',
]);

const initialOptionalFormFields = [
  'Address1',
  'Address2',
  'City',
  'State',
  'PostalCode',
  'Country',
  'Birthday',
  'Phone',
];

export const useGetApplicationFormFields = (requirementsInput: IRequirementsInput) => {
  const {
    data: {
      schemas: allSchemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const schemaByName = useMemo(() => {
    if (allSchemas === null) return null;

    return keyBy(allSchemas, 'name');
  }, [allSchemas]);

  const applicationFormFieldsInput: ApplicationFormFieldsInput = useMemo(() => {
    if (schemaByName === null) {
      return null;
    }

    const productTypes = requirementsInput.product_types;
    const networkFormFields: Set<string> = productTypes.reduce((result, networkType) => {
      const network: TNetwork = CONTENT_TYPE[networkType].network;
      if (!isEmpty(network)) {
        result.add(NETWORK_NAME[network]);
      }
      return result;
    }, new Set<string>());

    return {
      memberFieldSchemas: [
        ...Array.from(initialRequiredFormFields),
        ...initialOptionalFormFields,
        ...networkFormFields,
      ].map((fieldName, index) => {
        const schema: MemberFieldSchemasQuery_schemas = schemaByName[fieldName];
        return {
          schemaId: schema.id,
          order: index,
          required: initialRequiredFormFields.has(fieldName),
          label: fieldName,
        } as ApplicationMemberFieldSchemaInput;
      }),
      dbColumns: [{
        name: 'email',
        required: true,
        label: 'Email',
        order: null,
      }],
    };
  }, [schemaByName, requirementsInput.product_types]);

  return applicationFormFieldsInput;
};
