import { ISelectProjectAction, IState } from '../../../types/state';

export const selectProject = (state: IState, action: ISelectProjectAction): IState => {
  if (state.projectId === action.projectId) {
    return state;
  }
  return {
    ...state,
    projectId: action.projectId,
    isEditMode: false,
  };
};
