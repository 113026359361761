import * as React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { CustomFieldType } from '@services/communities/types/CustomFieldType';
import { formatMoney } from '@frontend/applications/AffiliatesApp/utils';
import { TruncatedText } from '@frontend/app/components/TruncatedText';

const RenderedValue = React.memo(
  (props: {
    value: string | number | Array<string | number> | moment.Moment;
    type: CustomFieldType;
    renderValue: (value: unknown) => JSX.Element;
  }) => {
    const { value, type, renderValue } = props;

    if (renderValue) {
      try {
        return renderValue(value);
      } catch {
        return <span>{value || '-'}</span>;
      }
    }

    let val = value;
    if (type === CustomFieldType.BOOLEAN) {
      val = value === 'true' ? 'Yes' : value === 'false' ? 'No' : '-';
    } else if (type === CustomFieldType.ARRAY && value) {
      val = (value as Array<string | number>).join(', ');
    } else if ((type === CustomFieldType.DATE || type === CustomFieldType.ANNUAL) && value) {
      val = moment(value).format('MM/DD/YYYY');
    } else if (type === CustomFieldType.CURRENCY && val) {
      val = `$${formatMoney(val as number)}`;
    } else if (type === CustomFieldType.PERCENTAGE && val) {
      val = numeral(val).format('0.[00]%');
    } else if (type === CustomFieldType.NUMBER && val) {
      val = +val;

      val = numeral(val).format('0.[00]');
    }

    return <TruncatedText>{val || '-'}</TruncatedText>;
  },
);

RenderedValue.displayName = 'RenderedValue';

export default RenderedValue;
