import * as React from 'react';
import { pick, findIndex, indexOf, map } from 'lodash';

import { RadioGroup, Select } from '@components';
import { FilterSection } from '../FilterSection';
import AudienceDropdown from './AudienceDropdown';

import { IAudienceAgeFilters, IAudienceAgeOptions } from '../models';

import styles from './AudienceAgeSection.scss';

const { useMemo } = React;

export const AUDIENCE_AGE_FILTERS = ['ageRange', 'ageLessThan', 'agePercentage', 'ageMin', 'ageMax'];

const DEFAULT_AUDIENCE_AGE_RANGES = ['13-17', '18-24', '25-34', '35-44', '45-64', '65+'];

const DEFAULT_AGE_MIN_VALUES = [
  { label: 'Select', value: '' },
  { label: '13', value: '13' },
  { label: '18', value: '18' },
  { label: '25', value: '25' },
  { label: '35', value: '35' },
  { label: '45', value: '45' },
];
const DEFAULT_AGE_MAX_VALUES = [
  { label: 'Select', value: '' },
  { label: '18', value: '18' },
  { label: '25', value: '25' },
  { label: '35', value: '35' },
  { label: '45', value: '45' },
  { label: '65', value: '65' },
  { label: '65+', value: '65+' },
];

const pickFilters = (obj: any) => pick(obj, AUDIENCE_AGE_FILTERS);

interface IProps extends IAudienceAgeFilters, IAudienceAgeOptions {
  onChange(ageFilters: IAudienceAgeFilters);
  hide?: boolean;
  hidePercentage?: boolean;
  showIQDataFilters?: boolean;
}

const AudienceAgeSection: React.FunctionComponent<IProps> = (props) => {
  const {
    ageLessThan,
    agePercentage,
    ageRange,
    ageRangeChoices: ageRangeChoicesProp,
    ageMin,
    ageMax,
    hide,
    hidePercentage,
    onChange,
    showIQDataFilters,
  } = props;

  const ageRangeChoices = useMemo(() => map(ageRangeChoicesProp, (range) => ({ value: range, label: range })), [ageRangeChoicesProp]);

  const ageRangeIndex = useMemo(() => indexOf(ageRangeChoicesProp, ageRange), [ageRangeChoicesProp, ageRange]);

  const ageMinIndex = useMemo(() => {
    return findIndex(DEFAULT_AGE_MIN_VALUES, { value: ageMin });
  }, [ageMin]);

  const ageMaxIndex = useMemo(() => {
    return findIndex(DEFAULT_AGE_MAX_VALUES, { value: ageMax });
  }, [ageMax]);
  
  const handleChangeAgeRange = (ageRange: string) => {
    const newFilters = {
      ...pickFilters(props),
      ageRange,
    };
    onChange(newFilters);
  };

  const handleChangeAgeMin = (ageMin: string) => {
    const newFilters = {
      ...pickFilters(props),
      ageMin,
    };
    onChange(newFilters);
  };

  const handleChangeAgeMax = (ageMax: string) => {
    const newFilters = {
      ...pickFilters(props),
      ageMax,
    };
    onChange(newFilters);
  };

  const handleChangeAudiencePercentage = (percentage: number) => {
    const newFilters = {
      ...pickFilters(props),
      agePercentage: percentage,
    };
    onChange(newFilters);
  };

  const handleChangeAudienceLessThan = (lessThan: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      ageLessThan: lessThan,
    };
    onChange(newFilters);
  };

  const handleClear = () => {
    handleChangeAgeRange('');
  };

  return (
    <FilterSection
      header="Age"
      hide={hide}
      isSubsection
      showClearButton={ageRangeIndex >= 0}
      onClear={handleClear}
      classNames={[styles.AudienceAgeSection]}
    >
      {
        showIQDataFilters ? 
        (
          <div className={styles.multiSelectContainer}>
            <Select
              options={DEFAULT_AGE_MIN_VALUES}
              onChange={handleChangeAgeMin}
              selectedIndex={ageMinIndex}
              className={styles.multiSelectSelect}
            />
            <span className={styles.multiSelectSeparator}>
            to 
            </span>
            <Select
              options={DEFAULT_AGE_MAX_VALUES}
              onChange={handleChangeAgeMax}
              selectedIndex={ageMaxIndex}
              className={styles.multiSelectSelect}
            />
          </div>
        ) :
        (
          <RadioGroup
            alignment="vertical"
            onChange={handleChangeAgeRange}
            options={ageRangeChoices}
            selectedIndex={ageRangeIndex}
            columns={2}
          />
        ) 
      }
      {hidePercentage ? null : (
        <AudienceDropdown
          onChangePercentage={handleChangeAudiencePercentage}
          onChangeLessThan={handleChangeAudienceLessThan}
          selectedPercentage={agePercentage}
          lessThan={ageLessThan}
          disabled={!ageRange && !ageMin && !ageMax}
        />
      )}
    </FilterSection>
  );
};

AudienceAgeSection.defaultProps = {
  ageRangeChoices: DEFAULT_AUDIENCE_AGE_RANGES,
  ageLessThan: false,
  ageMin: '',
  ageMax: '',
  agePercentage: 20,
  hidePercentage: false,
  hide: false,
};

export default AudienceAgeSection;
