import * as React from 'react';

import {
  Typography,
  Row,
  Space,
  Dropdown,
  Button,
  Menu,
} from '@revfluence/fresh';
import {
  ChevronLeftIcon,
  PlusIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { TemplateType } from '@frontend/applications/TermsApp/components/ContentGuidelines/types/state';
import { useScreenSize } from '@frontend/app/hooks/common';
import styles from './ContentGuidelinesListHeader.module.scss';

const { Text } = Typography;
const { useMemo } = React;

interface IProps {
  selectedProject: number;
  selectedProjectTitle: string;
  onSelectTemplateType: (templateType: TemplateType) => void;
  onSelectProject: (projectId: number) => void;
}

const ContentGuidelinesListHeader:React.FC<IProps> = React.memo((props) => {
  const {
    selectedProject,
    selectedProjectTitle,
    onSelectProject,
    onSelectTemplateType,
  } = props;
  const { isMobile } = useScreenSize();

  const menu = (
    <Menu>
      <Menu.Item onClick={() => onSelectTemplateType(TemplateType.PremadeTemplates)}>
        Select a premade template
      </Menu.Item>
      <Menu.Item onClick={() => onSelectTemplateType(TemplateType.OtherProjectTemplates)}>
        Select from another project
      </Menu.Item>
      <Menu.Item onClick={() => onSelectTemplateType(TemplateType.BlankTemplates)}>
        Select a blank template
      </Menu.Item>
    </Menu>
  );

  const renderCTADropdownButton = (
    <Space>
      <Dropdown
        overlay={menu}
        trigger={['click']}
      >
        <Button
          type="primary"
          icon={<PlusIcon />}
        >
          New Content Guideline
        </Button>
      </Dropdown>
    </Space>
  );

  const renderTitleHeader = useMemo(() => (
    isMobile ? (
      <Space size="middle">
        <Button
          icon={<ChevronLeftIcon />}
          onClick={() => onSelectProject(null)}
        >
          Back
        </Button>
        <Text strong>
          { selectedProjectTitle }
        </Text>
      </Space>
    ) : (
      <Text strong>
        Templates
      </Text>

    )
  ), [
    isMobile,
    onSelectProject,
    selectedProjectTitle,
  ]);

  return (
    <Row
      justify="space-between"
      align="middle"
      className={styles.contentGuidelinesListHeader}
    >
      { renderTitleHeader }
      { selectedProject && renderCTADropdownButton }
    </Row>
  );
});

ContentGuidelinesListHeader.displayName = 'ContentGuidelinesListHeader';

export default ContentGuidelinesListHeader;
