import gql from 'graphql-tag';

import { SEGMENT_FRAGMENT } from './SegmentFragment';

export const SEGMENT_FOLDER_FRAGMENT = gql`
  fragment MemberPageSegmentFolder on SegmentFolder {
    id
    title
    communityId
    sourceGroup
    createdDate
    segments {
      ...MemberPageSegment
    }
  }
  ${SEGMENT_FRAGMENT}
`;
