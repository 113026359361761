import * as React from 'react';
import cx from 'classnames';
import {
  isArray,
  isEmpty,
  map,
} from 'lodash';

import { Card } from './Card';

import styles from './ListCard.scss';

interface IProps {
  className?: string;
  /**
   * Whether the items flow inline or vertically
   * @default 'vertical'
   */
  flow?: 'inline' | 'vertical';
  /**
   * Class to be applied on all items
   */
  itemClass?: string;
  /**
   * Class to be applied on all items container
   */
  itemsClass?: string;
  /**
   * Items
   */
  items: React.ReactChild | React.ReactChild[];
  /**
   * Card title
   */
  title: React.ReactChild;
}

/**
 * Generic card for simple lists
 */
export const ListCard: React.FC<IProps> = (props) => {
  const {
    className,
    flow,
    itemClass,
    itemsClass,
    items,
    title,
  } = props;
  const hasItems = !isEmpty(items);

  return (
    <Card
      className={cx(
        className,
        styles.ListCard,
        { [styles.noData]: !hasItems },
      )}
      header={title}
    >
      <List
        className={itemsClass}
        flow={flow}
        itemClass={itemClass}
        items={items}
      />
    </Card>
  );
};
ListCard.defaultProps = {
  flow: 'vertical',
};
ListCard.displayName = 'ListCard';

/**
 * Generic list that can be used inside complex cards
 */
export const List: React.FC<Omit<IProps, 'itemsClass' | 'title'>> = (props) => {
  const {
    className,
    flow,
    itemClass,
    items,
  } = props;

  return (
    <div className={cx(className, styles.items, styles[flow])}>
      {!isEmpty(items) && isArray(items)
        ? map(items, (item, i) => (
          <span
            key={`item-${i}`}
            className={cx(styles.item, itemClass)}
          >
            {item}
          </span>
        ))
        : items}
    </div>
  );
};

List.displayName = 'List';
