import { times } from 'lodash';

export interface IDateTuple {
  year: number;
  month: number;
  day: number;
}
interface IYearAndMonth {
  year: number;
  month: number;
}

// Week days names
export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
// Calendar months names
export const CALENDAR_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Returns number of days from given year and month.
 *
 * @param {Number} year the year.
 * @param {Number} month the month, ranging from 1 to 12.
 *
 * @return {Number}
 */
export const getNumDaysInMonth = (year: number, month: number) => {
  const months30 = [4, 6, 9, 11];
  const isLeapYear = year % 4 === 0;

  return month === 2 ? (isLeapYear ? 29 : 28) : months30.includes(month) ? 30 : 31;
};

/**
 * Returns first day from given year and month.
 * Ranges from 1 to 7. (Sunday: 1, Saturday: 7)
 *
 * @param {Number} year the year.
 * @param {Number} month the month, ranging from 1 to 12.
 *
 * @return {Number}
 */
export const getFirstDayOfMonth = (year: number, month: number) => +new Date(year, month, 1).getDay() + 1;

/**
 * Returns previous year and month from given year and month.
 *
 * @param {Number} year the year.
 * @param {Number} month the month, ranging from 1 to 12.
 *
 * @return {IYearAndMonth}
 */
export const getPreviousMonth = (year: number, month: number): IYearAndMonth => {
  const prevMonth = month > 1 ? month - 1 : 12;
  const prevMonthYear = month > 1 ? year : year - 1;

  return { month: prevMonth, year: prevMonthYear };
};

/**
 * Returns next year and month from given year and month.
 *
 * @param {Number} year the year.
 * @param {Number} month the month, ranging from 1 to 12.
 *
 * @return {IYearAndMonth}
 */
export const getNextMonth = (year: number, month: number): IYearAndMonth => {
  const nextMonth = month < 12 ? month + 1 : 1;
  const nextMonthYear = month < 12 ? year : year + 1;

  return { month: nextMonth, year: nextMonthYear };
};

/**
 * Returns the calendar dates from given year and month.
 * Total number of dates is 42. (6 weeks)
 *
 * @param {Number} year the year.
 * @param {Number} month the month, ranging from 1 to 12.
 *
 * @return {IDateTuple[]}
 */
export const getCalendarDates = (year: number, month: number): IDateTuple[] => {
  // Get number of days in the month and the month's first day
  const monthDays = getNumDaysInMonth(year, month);
  const monthFirstDay = getFirstDayOfMonth(year, month - 1);

  // number of days from prev/next month
  const daysFromPrevMonth = monthFirstDay - 1;
  const daysFromNextMonth = 42 - (daysFromPrevMonth + monthDays);

  // Get the previous and next months and years
  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(year, month);
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(year, month);

  // Get number of days in previous month
  const prevMonthDays = getNumDaysInMonth(prevMonthYear, prevMonth);

  // Combines all dates from previous, current and next months
  return [
    ...times(daysFromPrevMonth, (index) => ({
      year: prevMonthYear,
      month: prevMonth,
      day: index + 1 + (prevMonthDays - daysFromPrevMonth),
    })),
    ...times(monthDays, (index) => ({
      year,
      month,
      day: index + 1,
    })),
    ...times(daysFromNextMonth, (index) => ({
      year: nextMonthYear,
      month: nextMonth,
      day: index + 1,
    })),
  ];
};
