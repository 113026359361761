import { useMemo, useCallback } from 'react';
import { mapValues, keyBy } from 'lodash';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import { FieldType } from '@frontend/app/types/Fields';
import { utcToLocal } from '@frontend/app/utils/date';

export const useMapMemberFields = () => {
  const {
    data: {
      schemas: memberFieldSchemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const memberFieldSchemasById = useMemo(() => (
    keyBy(memberFieldSchemas, 'id')
  ), [memberFieldSchemas]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useCallback((fields: { [id: string]: any }) => mapValues(fields, (value, field) => {
      const schema = memberFieldSchemasById[field];
      // Get the proper value for dates.
      if (value && schema && (schema.type === FieldType.DATE || schema.type === FieldType.ANNUAL)) {
        return utcToLocal(new Date(value));
      }
      return value;
    }), [memberFieldSchemasById]);
};
