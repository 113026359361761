import * as React from 'react';
import {
  Space,
} from '@revfluence/fresh';

import { TProject } from '@frontend/app/containers/Projects/types';
import { TermsType } from '@frontend/app/types/globalTypes';
import TermsTemplate from '@frontend/app/containers/Projects/TermsPage/components/TermsTemplate';

import styles from './TermsSettingsPage.module.scss';

interface IProps {
  project: TProject;
}

const TermsSettingsPage: React.FC<IProps> = React.memo(({ project }) => (
  <Space
    direction="vertical"
    className={styles.TermsSettingsPageContainer}
  >
    <Space
      direction="vertical"
      size={[8, 8]}
      className={styles.TermsSettingsLayout}
    >
      <TermsTemplate
        isEditing={false}
        memberIds={[]}
        projectId={project.id}
        termsType={TermsType.ADVANCED}
        onClose={() => {}}
        onSwitchTermsType={() => {}}
      />
    </Space>
  </Space>
  ));

TermsSettingsPage.displayName = 'TermsSettingsPage';

export default TermsSettingsPage;
