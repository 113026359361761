import { TState } from '../types';
import { resetDefaultState, TResetDefaultStateAction } from './resetDefaultState';
import { setCurrentStep, TSetCurrentStepAction } from './setCurrentStep';
import { selectMembers, TSelectMembersAction } from './selectMembers';
import { setError, TSetErrorAction } from './setError';
import { TUpdateSavingState, updateSavingState } from './updateSavingState';
import { TUpdateShowCloseConfirmationAction, updateShowCloseConfirmation } from './updateShowCloseConfirmation';

type TRawAction =
  | TResetDefaultStateAction
  | TSelectMembersAction
  | TSetCurrentStepAction
  | TSetErrorAction
  | TUpdateSavingState
  | TUpdateShowCloseConfirmationAction;

export const reducer = (state: TState, action: TRawAction): TState => {
  switch (action.type) {
    case 'RESET DEFAULT STATE':
      return resetDefaultState(state, action);
    case 'SELECT MEMBERS':
      return selectMembers(state, action);
    case 'SET CURRENT STEP':
      return setCurrentStep(state, action);
    case 'SET ERROR':
      return setError(state, action);
    case 'UPDATE SAVING STATE':
      return updateSavingState(state, action);
    case 'UPDATE SHOW CLOSE CONFIRMATION':
      return updateShowCloseConfirmation(state, action);
  }
  throw new Error('Should not reach here.');
};
