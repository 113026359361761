import gql from 'graphql-tag';

export const CONTENT_REVIEW_FRAGMENT = gql`
  fragment ContentReviewFragment on ContentReview {
    id
    memberId
    status
    info
    isGCR
  }
`;
