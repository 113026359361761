/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13z4k_157 {
  display: flex;
}

._justify-content-space-between_13z4k_161 {
  justify-content: space-between;
}

._tabular-nums_13z4k_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13z4k_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._customPagination_13z4k_178 {
  display: flex;
  align-items: center;
}
._customPagination_13z4k_178 ._paginationButton_13z4k_182 {
  border: none;
  background: transparent;
  color: #505256;
}
._customPagination_13z4k_178 ._paginationButton_13z4k_182 svg {
  font-size: 1.5rem;
}