import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const CheckListIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M6.79,3.26c-0.28-0.22-0.68-0.17-0.9,0.11L3.88,5.94l-0.78-0.8C2.85,4.9,2.44,4.89,2.19,5.14
      c-0.25,0.25-0.26,0.65-0.01,0.9l1.29,1.32c0.12,0.12,0.29,0.19,0.46,0.19c0.01,0,0.02,0,0.04,0c0.18-0.01,0.35-0.1,0.47-0.24
      L6.9,4.15C7.12,3.88,7.07,3.47,6.79,3.26z"
    />
    <path
      d="M6.79,9.92c-0.28-0.22-0.68-0.17-0.9,0.11l-2.02,2.58l-0.78-0.8c-0.25-0.25-0.65-0.26-0.9-0.01
      c-0.25,0.25-0.26,0.65-0.01,0.9l1.29,1.32c0.12,0.12,0.29,0.19,0.46,0.19c0.01,0,0.02,0,0.04,0c0.18-0.01,0.35-0.1,0.47-0.24
      l2.47-3.15C7.12,10.54,7.07,10.14,6.79,9.92z"
    />
    <path
      d="M6.79,16.59c-0.28-0.22-0.68-0.17-0.9,0.11l-2.02,2.58l-0.78-0.8c-0.25-0.25-0.65-0.26-0.9-0.01
      c-0.25,0.25-0.26,0.65-0.01,0.9l1.29,1.32c0.12,0.12,0.29,0.19,0.46,0.19c0.01,0,0.02,0,0.04,0c0.18-0.01,0.35-0.1,0.47-0.24
      l2.47-3.15C7.12,17.21,7.07,16.8,6.79,16.59z"
    />
    <path
      d="M8.78,6.78h12.59c0.35,0,0.64-0.29,0.64-0.64S21.71,5.5,21.36,5.5H8.78c-0.35,0-0.64,0.29-0.64,0.64
      S8.42,6.78,8.78,6.78z"
    />
    <path
      d="M21.36,11.81H8.78c-0.35,0-0.64,0.29-0.64,0.64c0,0.35,0.29,0.64,0.64,0.64h12.59c0.35,0,0.64-0.29,0.64-0.64
      C22,12.1,21.71,11.81,21.36,11.81z"
    />
    <path
      d="M21.36,18.05H8.78c-0.35,0-0.64,0.29-0.64,0.64s0.29,0.64,0.64,0.64h12.59c0.35,0,0.64-0.29,0.64-0.64
      S21.71,18.05,21.36,18.05z"
    />
  </SvgIcon>
));

CheckListIcon.displayName = 'CheckListIcon';
