import gql from 'graphql-tag';

export const GET_EMBEDDED_REPORT_BY_ID = gql`
query GetEmbeddedReportById($id: Int!) {
  embeddedReportingItemById(id: $id) {
    id
    name
    token
    url
  }
}
`;
