import { Radio } from 'antd';
import * as React from 'react';

interface IProps {
  value: 0 | 1;
  name: string;
  onFieldChange: (name: string, value: boolean) => void;
  header?: string;
}

const FormRadioSwitch = ({
  value,
  name,
  onFieldChange,
  header,
}: IProps) => {
  const handleChange = (e) => {
    onFieldChange(name, e.target.value);
  };

  return (
    <>
      {header && <p>{header}</p>}
      <Radio.Group
        value={value}
        onChange={handleChange}
        buttonStyle="solid"
      >
        <Radio.Button value={1}>Yes</Radio.Button>
        <Radio.Button value={0}>No</Radio.Button>
      </Radio.Group>
    </>
  );
};

export default React.memo(FormRadioSwitch);
