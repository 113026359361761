import { CustomFieldType } from './CustomFieldType';
import { IFieldMetaData } from './FieldMetaData';

export interface IMemberFieldSchemaInput {
  id?: number;
  name: string;
  type: CustomFieldType;
  applicationId?: string;
  defaultShownInMemberList?: boolean;
  metaData?: IFieldMetaData;
  choices?: string[];
}
