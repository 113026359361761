import * as React from 'react';
import cx from 'classnames';
import { isString } from 'lodash';

import { TSvgIcon } from '@components';
import { LazyImage } from '@components';

import { failedImage } from '@components';
import { Cell, ICellProps } from './Cell';

const { useState } = React;
import styles from './NameCell.scss';

const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

interface IProps extends ICellProps {
  // use value as image url
  value: {
    src: string;
    fallbackSrc: string;
  };
  avatarClassName?: string;
  nameClassName?: string;
  name?: string;
  badge?: TSvgIcon;
}

/**
 * @type {React.FunctionComponent}
 */
export const NameCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    badge,
    className,
    name,
    value,
    ...restProps
  } = props;
  const [failed, setFailed] = useState(failedImage.contains(value.src));

  const handleImageError = () => {
    failedImage.add(value.src);

    setFailed(true);
  };

  const content = (
    <>
      {!failed && (
        <LazyImage
          key={value.src}
          src={value.src}
          onError={handleImageError}
          className={styles.avatar}
        />
      )}
      {failed && (
        <LazyImage
          key={value.fallbackSrc}
          src={value.fallbackSrc}
          fallbackSrc={defaultAvatar}
          className={styles.avatar}
        />
      )}
      {badge && <div className={styles.badge}>{badge}</div>}
      {isString(name) && <div className={styles.name}>{name}</div>}
    </>
  );

  return (
    <Cell
      className={cx(styles.NameCell, className)}
      value={content}
      contentClassName={styles.content}
      {...restProps}
    />
  );
});

NameCell.defaultProps = {
  name: null,
};
NameCell.displayName = 'NameCell';
