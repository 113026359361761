/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wgpb_157 {
  display: flex;
}

._justify-content-space-between_1wgpb_161 {
  justify-content: space-between;
}

._tabular-nums_1wgpb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wgpb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wgpb_157 {
  display: flex;
}

._justify-content-space-between_1wgpb_161 {
  justify-content: space-between;
}

._tabular-nums_1wgpb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wgpb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._table_1wgpb_355 {
  width: 100%;
  margin-top: 0.5rem;
  border: 0.0625rem solid #ececec;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 0.5rem;
}
._table_1wgpb_355 thead tr th {
  text-transform: uppercase;
}
._table_1wgpb_355 thead tr th:first-of-type {
  border-top-left-radius: 0.5rem;
}
._table_1wgpb_355 thead tr th:last-of-type {
  border-top-right-radius: 0.5rem;
}
._table_1wgpb_355 tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 0.5rem;
}
._table_1wgpb_355 tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 0.5rem;
}
._table_1wgpb_355 thead tr th,
._table_1wgpb_355 tbody tr:not(:last-of-type) td {
  border-bottom: 0.0625rem solid #ececec;
}
._table_1wgpb_355 tr th,
._table_1wgpb_355 tr td {
  min-height: 2.5rem;
  padding: 1rem;
  text-align: left;
}
._table_1wgpb_355 tr th {
  color: #8f8d91;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

._footer_1wgpb_396 {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4.5rem;
  margin: 0 0 0 -2.25rem;
  padding: 1rem 2.25rem;
  border-top: 0.0625rem solid #dadcde;
  background-color: #fdfdfd;
}
._footer_1wgpb_396 > div:last-of-type {
  margin-left: auto;
}

._BulkAssign_1wgpb_412 {
  padding-bottom: 4.5rem;
  text-align: center;
}
._BulkAssign_1wgpb_412 h4 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
}
._BulkAssign_1wgpb_412 p {
  margin-bottom: 2rem;
}
._BulkAssign_1wgpb_412 ._selectListPicker_1wgpb_425 {
  width: 16.25rem;
}

._selectListPopoverOverlay_1wgpb_429 {
  width: 16.25rem;
}
._selectListPopoverOverlay_1wgpb_429 .ant-popover-inner-content {
  padding: 1rem;
}

._footer_1wgpb_396 {
  margin: 0 !important;
}