/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_187m6_157 {
  display: flex;
}

._justify-content-space-between_187m6_161 {
  justify-content: space-between;
}

._tabular-nums_187m6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_187m6_209 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._shareModalContent_187m6_178 ._body_187m6_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._shareModalContent_187m6_178 ._note_187m6_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._shareModalContent_187m6_178 ._body_187m6_178 {
  padding-bottom: 20px;
}
._shareModalContent_187m6_178 ._group_187m6_201 {
  margin: auto;
  padding-bottom: 10px;
}
._shareModalContent_187m6_178 ._copied_187m6_205 {
  display: none;
  color: #4eb468;
}
._shareModalContent_187m6_178 ._copied_187m6_205._show_187m6_209 {
  display: block;
}
._shareModalContent_187m6_178 ._note_187m6_188 {
  padding-top: 10px;
}