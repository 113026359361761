// Library imports
import * as React from 'react';
import {
 useState, useRef, useMemo, useReducer, useEffect,
} from 'react';
import { find } from 'lodash';
import { useHistory } from 'react-router-dom';

import { logger } from '@common';

// Widgets lib imports
import {
 Toast, IToastRefHandles, LoadSpinner, Notice,
} from '@components';

// Core imports
import {
  useGetMemberQuery,
  useMemberFieldSchemasQuery,
  useProgramsQuery,
} from '@frontend/app/hooks';

// Application imports
import { useGetData } from '@frontend/applications/TermsApp/hooks/useGetData';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { ArtifactAssignmentForm, IAssignmentAPIPayload } from '@frontend/applications/Shared/components/ArtifactAssignmentForm';
import { PAYPAL_APP_FIELD } from '@frontend/applications/PaymentsApp/appFields';

// Payments Imports
import { IPayment } from '../models';
import { useFetchPaymentSourceData } from '../useFetchPaymentSources';

import { ISendPaymentRequest, updatePaymentData } from '../savePayment';

import { PayeeSetup } from '../components/PayeeSetup';
import { PaymentSetup } from '../components/PaymentSetup';
import { ReviewPayment } from '../components/ReviewPayment';

import styles from './NewPayment.scss';

interface NewPaymentState {
  amount: number;
  payeeAddress?: string;
  payment: IPayment;
  cardId: string;
  editPayeeAddress: boolean;
}

enum NewPaymentPage {
  PayeeSetup = 'payeeSetup',
  PaymentSetup = 'paymentSetup',
  ReviewPayment = 'reviewPayment',
  ArtifactAssignment = 'artifactAssignment',
}

export enum PaymentReducerActionType {
  SetAmount = 'setAmount',
  SetCard = 'setCard',
  SetPayment = 'setPayment',
  SetPayeeAddress = 'setPayeeAddress',
  SetEditPayeeAddress = 'setEditPayeeAddress',
  CancelEditingPayeeAddress = 'cancelEditingPayeeAddress',
}

interface PaymentReducerAction {
  type: PaymentReducerActionType;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  value: any;
}

const NewPayment: React.FC = () => {
  /* Overall flow is:
    1. Load the member to determine if we need paypal address
    2. Load payment sources
    3. If paypal address needed, show PayeeSetup
    4. Once PayPal address set up, show PaymentSetup to collect payment amount
    5. Show PaymentReview to review the payment details, select card, etc. Block on #2 loading payment sources
    6. Payment is actually sent
    7. Artifact Assignment
  */

  const {
    backendServerApiEndpoint,
    memberId,
    clientId,
    workflowActionParameters,
  } = useApplication();
  const programId = workflowActionParameters?.programId;
  const programName = workflowActionParameters?.programName;
  const history = useHistory();

  const [page, setPage] = useState<NewPaymentPage[]>([]);

  const [loadingArtifactAssignment, setLoadingArtifactAssignment] = useState<boolean>(false);

  const toastRef = useRef<IToastRefHandles>(null);

  // Step 1 is get member information
  const {
    loading: memberLoading,
    data: memberData,
  } = useGetMemberQuery(Number(memberId));

  const {
    data: {
      schemas = null,
    } = {},
    loading: schemasLoading,
    // error: getMemberFieldError,
  } = useMemberFieldSchemasQuery({
    /**
     * Due to renaming of fields in API layer in a different query that returns the same GraphQL object
     * (memberFieldSchemasBySections) and how Apollo cache works, this query could result in data with non-DB names.
     * Since we use the DB name to find the corresponding PayPal field, always run this query with no-cache.
     */
    fetchPolicy: 'no-cache',
  });

  const { loading: programLoading, data: programsData } = useProgramsQuery();

  // Get agreements
  const { data: terms = [], loading: isTermsLoading } = useGetData<IAgreement[]>(
    `${backendServerApiEndpoint}/agreements?member_id=${memberId}`,
  );

  const paypalFieldId = useMemo(() => {
    if (!memberData || !schemas) {
      return null;
    }

    const paypalField = find(schemas, { name: PAYPAL_APP_FIELD });
    if (!paypalField) {
      return null;
    }

    return paypalField.id;
  }, [memberData, schemas]);

  // When member and schema data come back, update reducer
  useEffect(() => {
    if (paypalFieldId && memberData) {
      dispatch({
        type: PaymentReducerActionType.SetPayeeAddress,
        value: memberData.member.fields[paypalFieldId],
      });
    }
  }, [memberData, paypalFieldId]);

  // Step 2 Get payment sources
  const {
    loading: paymentSourcesLoading,
    data: paymentSources,
  } = useFetchPaymentSourceData();

  const initialDataLoading = useMemo(
    () => schemasLoading || memberLoading || paymentSourcesLoading || isTermsLoading || programLoading,
    [schemasLoading, memberLoading, paymentSourcesLoading, isTermsLoading, programLoading],
  );
  const paymentReducer = (state: NewPaymentState, action: PaymentReducerAction) => {
    const { value } = action;
    switch (action.type) {
      case PaymentReducerActionType.SetAmount:
        return { ...state, amount: value };
      case PaymentReducerActionType.SetCard:
        return { ...state, cardId: value };
      case PaymentReducerActionType.SetPayment:
        return { ...state, payment: value };
      case PaymentReducerActionType.SetPayeeAddress:
        return { ...state, payeeAddress: value, editPayeeAddress: false };
      case PaymentReducerActionType.SetEditPayeeAddress:
        return { ...state, editPayeeAddress: true };
      case PaymentReducerActionType.CancelEditingPayeeAddress:
        return { ...state, editPayeeAddress: false };
      default:
        throw new Error();
    }
  };

  const [paymentParams, dispatch] = useReducer(paymentReducer, {
    amount: 0,
    cardId: null,
    payment: null,
    payeeAddress: null,
    editPayeeAddress: false,
  });

  // Page state should reflect state of reducer
  useEffect(() => {
    const canPayMember = memberData?.member.email || paymentParams.payeeAddress;
    if (!canPayMember || paymentParams.editPayeeAddress) {
      setPage([...page, NewPaymentPage.PayeeSetup]);
    } else if (!paymentParams.amount) {
      setPage([...page, NewPaymentPage.PaymentSetup]);
    } else if (!paymentParams.payment) {
      setPage([...page, NewPaymentPage.ReviewPayment]);
    } else {
      setPage([...page, NewPaymentPage.ArtifactAssignment]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentParams, memberData]);

  // For payment review page, prep it with the actual server request
  const paymentRequestParams: ISendPaymentRequest = {
    amount: paymentParams.amount,
    client_id: clientId,
    member_id: Number(memberId),
    card_id: paymentParams.cardId,
    paypal_address: paymentParams.payeeAddress,
    member_name: memberData ? memberData.member.name : null,
    member_email: memberData ? memberData.member.email : null,
  };

  // Handle callbacks
  const onArtifactAssignmentSkip = () => {
    onCreatePaymentComplete();
  };

  const onArtifactAssignmentSave = (assignment: IAssignmentAPIPayload) => {
    const paymentUrl = `${backendServerApiEndpoint}/payment/${paymentParams.payment.id}`;
    setLoadingArtifactAssignment(true);
    updatePaymentData(paymentUrl, {
      ...assignment,
      client_id: clientId,
    }).then(() => {
      onCreatePaymentComplete();
    }).catch(logger.error)
    .finally(() => setLoadingArtifactAssignment(false));
  };

  const onCreatePaymentComplete = () => {
    history.push({
      ...location,
      pathname: './payments',
    });
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const formParams: any = {};
  return (
    <div className={styles.NewPayment}>
      {initialDataLoading ? <LoadSpinner /> : (
        <div>
          {page[page.length - 1] === NewPaymentPage.ArtifactAssignment
            && (
            <ArtifactAssignmentForm
              {...formParams}
              artifact={paymentParams.payment}
              artifactName="payment"
              onSave={onArtifactAssignmentSave}
              onSkip={onArtifactAssignmentSkip}
              loading={loadingArtifactAssignment}
            />
)}
          {page[page.length - 1] === NewPaymentPage.PayeeSetup
            && (
            <PayeeSetup
              member={memberData.member}
              paypalAddressAppField={paypalFieldId}
              dispatch={dispatch}
              canCancelEditing={paymentParams.editPayeeAddress}
            />
)}
          {page[page.length - 1] === NewPaymentPage.PaymentSetup && (
            <PaymentSetup
              isWorkflow={!!programId}
              programId={programId}
              programName={programName}
              programs={programsData?.programs}
              memberName={memberData?.member?.name}
              terms={terms}
              dispatch={dispatch}
            />
          )}
          {page[page.length - 1] === NewPaymentPage.ReviewPayment && (
            paymentSourcesLoading ? <LoadSpinner />
              : paymentSources
                ? (
                  <ReviewPayment
                    newPayment={paymentRequestParams}
                    payeeAddress={paymentParams.payeeAddress}
                    paymentSources={paymentSources}
                    memberEmail={memberData?.member?.email}
                    dispatch={dispatch}
                    requireHandlingFee={!paymentSources?.handling_fee_disabled || false}
                  />
)
                : <Notice type="error">There was an error loading your payment info. Please contact help@aspireiq.com if the problem continues.</Notice>
          )}
        </div>
)}
      <Toast ref={toastRef} />
    </div>
  );
};

export default NewPayment;
