import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider as ApolloHooksProvider,
} from '@apollo/client';

import { ErrorBoundary } from '@frontend/utils';

import { BrandAuth } from '@frontend/applications/SocialPostApp/components/BrandAuth';
import { EventProvider } from '@frontend/app/context/EventContext';
import { ClientSelectView } from '../ClientSelect/ClientSelectView';
import { ThankYouPage } from './Onboarding/ThankYouPage';
import { SignupForm } from './Onboarding/SignupForm';

import { LandingPageProvider } from '../Projects/LandingPages/Provider';

const { useMemo } = React;

export const PublicPages: React.FunctionComponent = () => {
  const apolloClient = useMemo(() => {
    const httpLink = new HttpLink({
      uri: '/api/public',
    });

    const client = new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
    });

    return client;
  }, []);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: Fix in Node upgrade typing bash!
    <ApolloHooksProvider client={apolloClient}>
      <EventProvider>
        <ErrorBoundary>
          <Switch>
            <Route path="/onboarding_form/:customLandingPagePath" component={SignupForm} exact />
            <Route path="/ig_auth_result/:clientId" component={BrandAuth} />

            {/* This route is only accesible with many conditions, see app/server.ts */}
            <Route path="/join/:customLandingPagePath" component={LandingPageProvider} exact />

            <Route path="/join/:customLandingPagePath/thankyou" component={ThankYouPage} />
            <Route path="/domain_select" component={ClientSelectView} />
            <Route path="/" component={ClientSelectView} />
          </Switch>
        </ErrorBoundary>
      </EventProvider>
    </ApolloHooksProvider>
  );
};
