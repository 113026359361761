import { MutationHookOptions, MutationTuple } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import {
  ADD_MEMBERS_TO_COMMUNITY_MUTATION,
  REMOVE_MEMBERS_FROM_COMMUNITY_MUTATION,
} from '@frontend/app/queries';
import {
  AddMembersToCommunityMutation,
  AddMembersToCommunityMutationVariables,
} from '@frontend/app/queries/types/AddMembersToCommunityMutation';
import {
  RemoveMembersFromCommunityMutation,
  RemoveMembersFromCommunityMutationVariables,
} from '@frontend/app/queries/types/RemoveMembersFromCommunityMutation';

type TAddType = 'add';
type TRemoveType = 'remove';
type TModifyType = TAddType | TRemoveType;
type IMutation<T extends TModifyType> = T extends TAddType ? AddMembersToCommunityMutation : RemoveMembersFromCommunityMutation;
type IMutationVariables<T extends TModifyType> = T extends TAddType ? AddMembersToCommunityMutationVariables : RemoveMembersFromCommunityMutationVariables;
type IOptions<T extends TModifyType> = MutationHookOptions<IMutation<T>, IMutationVariables<T>>;
type ITuple<T extends TModifyType> = MutationTuple<IMutation<T>, IMutationVariables<T>>;

export function useModifyCommunityMembersMutation(type: TAddType, options?: IOptions<TAddType>): ITuple<TAddType>;
export function useModifyCommunityMembersMutation(type: TRemoveType, options?: IOptions<TRemoveType>): ITuple<TRemoveType>;

export function useModifyCommunityMembersMutation(type: TModifyType, options: IOptions<TAddType> | IOptions<TRemoveType> = {}) {
  const mutationConfig = type === 'add'
    ? { mutation: ADD_MEMBERS_TO_COMMUNITY_MUTATION, options }
    : { mutation: REMOVE_MEMBERS_FROM_COMMUNITY_MUTATION, options };

  return useMemberMutation<
    IMutation<TAddType | TRemoveType>,
    IMutationVariables<TAddType | TRemoveType>
  >(mutationConfig.mutation, mutationConfig.options);
}
