import * as React from 'react';
import cn from 'classnames';
import { Typography } from '@revfluence/fresh';

import {
 BarChart, Bar, Tooltip, TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { MetricChartProps } from './MetricChart.types';
import styles from './MetricChart.module.scss';

const BarTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && (payload?.[0].payload.tooltipTitle || payload?.[0].payload.tooltipTitle)) {
    return (
      <div className="ant-tooltip ant-tooltip-placement-top" style={{ transform: 'translateX(-50%)', left: '8px' }}>
        <div className="ant-tooltip-content">
          <div className="ant-tooltip-arrow">
            <span className="ant-tooltip-arrow-content" />
          </div>
        </div>
        <div className="ant-tooltip-inner" style={{ transform: 'translateY(-100%)' }}>
          <Typography
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            className={styles.tooltipTitle}
          >
            {payload?.[0].payload.tooltipTitle}
          </Typography>
          <Typography
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            className={styles.tooltipDescription}
          >
            {payload?.[0].payload.tooltipDescription}
          </Typography>
        </div>
      </div>
    );
  }

  return null;
};

interface TooltipPosition {
  x: number;
  y: number;
  height: number;
  width: number;
}

const { useRef } = React;
const _defaultChart = [{ value: 1 }, { value: 1 }, { value: 1 }, { value: 1 }];

const MetricChart = ({ color = 'blue', chart = _defaultChart }: MetricChartProps) => {
  const [position, setPosition] = React.useState<TooltipPosition | null>();
  const ref = useRef(null);

  React.useEffect(() => {
    const tooltip = ref.current.wrapperNode as HTMLElement;

    if (!tooltip) return;
    const tooltipHeight = tooltip.getBoundingClientRect().height;
    const tooltipWidth = tooltip.getBoundingClientRect().width;
    const spaceForLittleTriangle = 10;

    // Rewrite tooltip styles
    tooltip.style.transform = `translate(${position?.x}px, ${position?.y}px)`;
    tooltip.style.position = 'absolute';
    tooltip.style.top = `-${tooltipHeight + spaceForLittleTriangle}px`;
    tooltip.style.left = `-${tooltipWidth / 2 - position?.width / 2}px`;
    tooltip.style.zIndex = '100';
    tooltip.style.transition = 'all 400ms ease 0s';
  }, [position]);

  return (
    <BarChart
      width={16 * chart.length}
      height={40}
      data={chart}
      barGap={1}
      barCategoryGap={1}
      margin={{
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      className={cn(styles[color])}
    >
      <Bar
        dataKey="value"
        barSize={100}
        minPointSize={1}
        onMouseMove={(elPosition) => setPosition(elPosition)}
        onMouseLeave={(elPosition) => setPosition(elPosition)}
      />
      <Tooltip
        ref={ref}
        cursor={{ fill: 'transparent' }}
        position={{
          x: 0,
          y: 0,
        }}
        content={BarTooltip}
      />
    </BarChart>
  );
};

export default MetricChart;
