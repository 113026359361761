import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_PAYMENTS_HISTORY_WITH_BUDGET_LOG from '@frontend/app/queries/GetPaymenstHistoryWithBudgetLog';
import { GetPaymenstHistoryWithBudgetLog, GetPaymenstHistoryWithBudgetLogVariables } from '@frontend/app/queries/types/GetPaymenstHistoryWithBudgetLog';

type IOptions = QueryHookOptions<GetPaymenstHistoryWithBudgetLog, GetPaymenstHistoryWithBudgetLogVariables>;

export function useGetPaymenstHistoryWithBudgetLog(options: IOptions) {
    const {
        loading, data: { paymentsLog } = {}, error, refetch,
    } = useQuery<GetPaymenstHistoryWithBudgetLog, GetPaymenstHistoryWithBudgetLogVariables>(GET_PAYMENTS_HISTORY_WITH_BUDGET_LOG, {
      ...options,
      fetchPolicy: 'no-cache',
    });
    return {
        loading,
        paymentsLog,
        error,
        refetch,
    };
}
