import { sortGuidelineInstructions } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { ISortContentGuidelineAction, IState } from '../../../types/state';

export const sortContentGuideline = (state: IState, action: ISortContentGuidelineAction): IState => ({
  ...state,
  contentGuidelines: sortGuidelineInstructions(
    state.contentGuidelines,
    action.id,
    Object.keys(action.sortableGuideline)[0],
    action.sortableGuideline,
  ),
  isEditMode: true,
});
