import * as React from 'react';

import cx from 'classnames';
import { map } from 'lodash';

import { Button } from '@revfluence/fresh';

import styles from './styles.scss';

const { useCallback, useMemo } = React;

interface IQuickFilter {
  label: string;
  filter: string;
}

interface IQuickFiltersProps {
  onSelect: (filter: string) => void;
  selectedFilter?: string;
  quickFiltersCounts?: Record<string, number>;
  hideFiltersCounts?: boolean;
  quickFiltersOptions: IQuickFilter[];
}

export const QuickFilters: React.FC<IQuickFiltersProps> = (props) => {
  const {
    onSelect,
    selectedFilter,
    quickFiltersCounts,
    quickFiltersOptions,
    hideFiltersCounts,
  } = props;

  const renderOption = useCallback((option: IQuickFilter) => (
    <div
      className={cx(
        styles.option,
        {
          [styles.selected]: option.filter === selectedFilter,
        },
      )}
      key={option.filter}
    >
      <Button
        onClick={() => onSelect(option.filter)}
      >
        {option.label}
        {!hideFiltersCounts && (
          <div className={styles.countBadge}>
            {quickFiltersCounts?.[option.filter] || 0}
          </div>
        )}

      </Button>
    </div>
  ), [onSelect, selectedFilter, quickFiltersCounts, hideFiltersCounts]);

  const options = useMemo(() => map(quickFiltersOptions, renderOption), [renderOption, quickFiltersOptions]);

  return (
    <div className={styles.wrapper}>
      {options}
    </div>
  );
};
