import * as React from 'react';
import { map } from 'lodash';

import { Typography } from '@revfluence/fresh';
import { Button, Table, ITableColumnConfig } from '@components';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { IShippingAddress } from '../context/ProductFulfillmentContext';

interface IProps {
  memberShippingAddresses: IShippingAddress[];
  onContinue(): void;
  canContinue: boolean;
}

const { Title, Paragraph } = Typography;
const { useCallback } = React;

import styles from './InvalidMembers.scss';

const COLS = [
  {
    field: 'first_name',
    headerName: 'First Name',
    type: 'TEXT',
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    type: 'TEXT',
  },
  {
    field: 'address1',
    headerName: 'Address 1',
    type: 'TEXT',
  },
  {
    field: 'city',
    headerName: 'City',
    type: 'TEXT',
  },
  {
    field: 'province',
    headerName: 'Province',
    type: 'TEXT',
  },
  {
    field: 'country',
    headerName: 'Country',
    type: 'TEXT',
  },
  {
    field: 'zip',
    headerName: 'Zip',
    type: 'TEXT',
  },

];

export const InvalidMembers: React.FunctionComponent<IProps> = (props) => {
  const { canContinue, memberShippingAddresses, onContinue } = props;
  const { closeModal } = useApplication();

  const renderBodyRow = useCallback((_, cellContent) => cellContent, []);

  const renderEmptyCell = useCallback(() => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div className={(styles as any).emptyCell} />
    ), []);

  const columnConfig = React.useMemo<ITableColumnConfig[]>(() => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map<any, ITableColumnConfig>(COLS, (column) => ({
        ...column,
        grow: true,
        width: 200,
        minWidth: 200,
        maxWidth: 400,
        render: (value) => value,
        lockPosition: true,
      }))
  ), []);

  return (
    <div className={styles.InvalidMembers}>
      <Title level={5}>
        {canContinue && (
          'Some members cannot be sent orders'
        )}
        {!canContinue && (
          'Cannot send order to selected members'
        )}
      </Title>
      <Paragraph>All members must have name, email and required address fields in order to be sent orders. Continue creating orders without the  members listed below or cancel to add the missing information.</Paragraph>
      <div className={styles.summary}>
        <Table
          columns={columnConfig}
          className={styles.table}
          config={{
            rowHeight: 56,
            allowSearch: false,
            configurableColumns: false,
            selectable: false,
            striped: false,
            rowBorder: true,
            renderBodyRow,
            renderEmptyCellContent: renderEmptyCell,
            reorderableColumns: true,
            resizableColumns: true,
          }}
          paddingBottom={20}
          data={map(memberShippingAddresses, (m) => ({
            ...m,
            _raw: m,
            id: `${m.first_name} ${m.last_name} ${m.address1}`,
          }))}
        />
        <div className={styles.footer}>
          <Button
            label="Cancel"
            theme="info"
            className={styles.button}
            onClick={closeModal}
          />
          {canContinue && (
            <Button
              label="Continue"
              theme="primary"
              className={styles.button}
              onClick={onContinue}
            />
          )}
        </div>
      </div>
    </div>
  );
};
