/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_2ia4x_157 {
  display: flex;
}

._justify-content-space-between_2ia4x_161 {
  justify-content: space-between;
}

._tabular-nums_2ia4x_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_2ia4x_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Intro_2ia4x_178 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (min-width: 20rem) {
  ._Intro_2ia4x_178 {
    margin-top: 0.75rem;
  }
  ._Intro_2ia4x_178 ._title_2ia4x_188,
  ._Intro_2ia4x_178 ._description_2ia4x_189,
  ._Intro_2ia4x_178 ._joinButton_2ia4x_190 {
    color: #fdfdfd;
  }
  ._Intro_2ia4x_178 ._title_2ia4x_188 {
    margin-bottom: 0;
    font-weight: 900;
    font-style: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    text-align: center;
    word-break: break-word;
    padding: 0 18px;
  }
  ._Intro_2ia4x_178 ._socialLinkCta_2ia4x_203 {
    display: flex;
    margin-top: 1.25rem;
  }
  ._Intro_2ia4x_178 ._socialLinkCta_2ia4x_203 a {
    margin-right: 0.9375rem;
  }
  ._Intro_2ia4x_178 ._heroImage_2ia4x_210 {
    width: 100%;
    margin-top: 1.5rem;
  }
  ._Intro_2ia4x_178 ._description_2ia4x_189 {
    width: 100%;
    margin: 2.25rem auto 1.5rem;
    font-weight: 500;
    font-style: normal;
    padding: 0 18px;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    white-space: break-spaces;
    word-break: break-word;
  }
  ._Intro_2ia4x_178 ._joinButton_2ia4x_190 {
    box-sizing: border-box;
    width: auto;
    border: 0.125rem solid #fdfdfd;
    background-color: #1a1818;
    color: #fdfdfd;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
    padding: 0.5rem 1.125rem;
  }
}
@media only screen and (orientation: landscape) {
  ._Intro_2ia4x_178 {
    margin-top: 3rem;
  }
  ._Intro_2ia4x_178 ._description_2ia4x_189 {
    margin: 1.5rem auto;
    max-width: 56.25rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}