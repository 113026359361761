import cx from 'classnames';
import { isFunction, isNil } from 'lodash';
import * as React from 'react';
import { SlideDown } from 'react-slidedown';

import { KeyboardArrowDownIcon } from '@components';

import { FolderItem } from './FolderItem';

import 'react-slidedown/lib/slidedown.css';

import styles from './Folder.scss';

const { useCallback, useEffect, useState } = React;

export interface IFolderProps {
  /**
   * Any element that would appear on the right side
   */
  accessory?: React.ReactChild;
  /**
   * Class name
   */
  className?: string;
  /**
   * Whether the folder is expanded by default
   */
  defaultExpanded?: boolean;
  /**
   * Controlled way of expanding the folder
   */
  expanded?: boolean;
  /**
   * Replaces `accessory` when hovering over the folder item
   */
  hoverAccessory?: React.ReactChild;
  /**
   * Icon to appear on the left side
   */
  icon?: React.ReactChild;
  /**
   * Is active?
   */
  isActive?: boolean;
  /**
   * Class name of the folder item
   */
  itemClassName?: string;
  /**
   * Callback when toggling the folder's expanded state
   */
  onChangeExpanded?: (expanded: boolean) => void;
  /**
   * Click handler
   */
  onClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /**
   * Folder title
   */
  title: React.ReactChild;
}

type TFolder = React.FC<IFolderProps> & {
  Item?: typeof FolderItem;
};

/**
 * Folder component -- can be used as a menu list
 */
export const Folder: TFolder = React.memo(({
  accessory,
  children,
  className,
  defaultExpanded = true,
  expanded: expandedProp,
  hoverAccessory,
  icon,
  isActive,
  itemClassName,
  onChangeExpanded,
  onClick,
  title,
}) => {
  const isControlled = !isNil(expandedProp);
  const [expandedState, setExpanded] = useState(isControlled ? expandedProp : defaultExpanded);
  useEffect(
    () => {
      if (isFunction(onChangeExpanded)) {
        onChangeExpanded(expandedState);
      }
    },
    [expandedState, onChangeExpanded],
  );
  const expanded = isControlled ? expandedProp : expandedState;

  const handleToggleExpand = useCallback(
    (expanded?: boolean) => {
      if (!isControlled) {
        setExpanded((e) => (isNil(expanded) ? !e : expanded));
      } else if (isFunction(onChangeExpanded)) {
        onChangeExpanded(!expanded);
      }
    },
    [isControlled, onChangeExpanded, setExpanded],
  );

  /**
   * The consumer has the chance to cancel the toggle when `onClick` returns `false`
   */
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      if (isFunction(onClick)) {
        const result = onClick(event);
        if (isNil(result) || result) {
          handleToggleExpand();
        }
      } else {
        handleToggleExpand();
      }
    },
    [handleToggleExpand, onClick],
  );

  const arrowDownIconElem = (
    <span
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleToggleExpand();
      }}
    >
      <KeyboardArrowDownIcon
        className={cx(styles.caret, {
          [styles.expanded]: expanded,
        })}
        size={10}
      />
    </span>
  );

  return (
    <div className={cx(styles.Folder, className)}>
      <FolderItem
        accessory={(
          <>
            {accessory}
            {arrowDownIconElem}
          </>
)}
        className={cx(styles.parentItem, itemClassName)}
        hoverAccessory={(
          <>
            {hoverAccessory || accessory}
            {arrowDownIconElem}
          </>
)}
        icon={icon}
        isActive={isActive}
        onClick={handleClick}
      >
        {title}
      </FolderItem>
      <SlideDown
        className={styles.items}
        closed={!expanded}
      >
        {expanded && children}
      </SlideDown>
    </div>
  );
});
Folder.Item = FolderItem;

Folder.displayName = 'Folder';
