/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_mfho5_157 {
  display: flex;
}

._justify-content-space-between_mfho5_161 {
  justify-content: space-between;
}

._tabular-nums_mfho5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_mfho5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PotentialCodeHeader_mfho5_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._PotentialCodeHeader_mfho5_178 p {
  margin-bottom: 0;
  margin-right: 0.1875rem;
}
._PotentialCodeHeader_mfho5_178 ._icon_mfho5_187 {
  display: flex;
  color: #3996e0 !important;
}