/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import cx from 'classnames';
import { useQuery } from '@apollo/client';
import {
 map, isNumber, filter, slice, size, debounce, isFunction, uniqBy,
} from 'lodash';

import {
  Table,
  ITableConfig,
  ITableProps,
  ITableColumnConfig,
  ITableRefHandles,
  IColumnSortDir,
  Notice,
  Button,
  OverlaySpinner,
} from '@components';

import { useMessagingContext, usePagination, useGraphQLFetchMore } from '@frontend/hooks';
import { useMemberSearchQuery, useMemberPageLocalStateQuery, useUpdateMemberPageLocalState } from '@frontend/app/hooks';

import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';
import { IColumn, IColumnVisibility } from '@frontend/app/types/Columns';

import { MEMBER_SEARCH_COUNT_QUERY } from '@frontend/app/queries';
import {
  MemberSearchCountQuery,
  MemberSearchCountQueryVariables,
} from '@frontend/app/queries/types/MemberSearchCountQuery';
import { MemberSearchQuery, MemberSearchQueryVariables } from '@frontend/app/queries/types/MemberSearchQuery';

import {
  useChangeCellValue,
  useMapMemberFields,
  useMemberSearchQueryVariables,
  useColumnReorder,
  useSearchTextQuery,
} from './hooks';
import { defaultRenderCell } from './utils/defaultRenderCell';

import { renderEmptyCell } from './CustomCells/EmptyCell';
import { useCheckboxCellConfig } from './CustomCells/CheckboxCell';

import styles from './MemberTable.scss';

const {
 useMemo, useState, useEffect, useCallback, useImperativeHandle, useRef,
} = React;

interface IMembersTableProps extends Partial<ITableProps> {
  columns: IColumn[];
  columnVisibility: IColumnVisibility;
  searchQuery: IMemberSearchQuery;
  initialSelectedMemberIds?: number[];

  className?: string;
  isLoading?: boolean;
  headerActions?: React.ReactElement;
  tableHeader?: React.ReactElement;

  showMembersChangedNotice?: boolean;
  showCheckboxColumn?: boolean;
  showSelectAllCheckbox?: boolean;

  enableColumnReordering?: boolean;
  segmentId?: number | string;
  segmentType?: 'custom' | 'predefined';
  initialColumnSort?: IColumnSortDir;

  sortableFieldsForMember?(member: IMember): any;

  pageSize: number;

  allowSearch?: boolean;
  selectedMembers?: IMember[];
  onSortDirChange?(columnField: string, sortDir: string);
  renderEmptyState?();
  onSelectedMembersChange?(selectedMembers: IMember[]);
  onMembersUpdated?: (members: IMember[]) => void;
  onLoadMembers?(members: IMember[]);
  onChangeMatchingMembers?(matchingMembers: number);
  onSearchTextChange?(searchText: string);
  renderCell?(column: IColumn): ITableColumnConfig;
  renderBodyRow?: ITableConfig['renderBodyRow'];
  mapMemberData?(member: IMember): { [field: string]: any };
  refetchProjectCounts?: () => Promise<any>;
}

export interface IMemberTableRefHandles {
  refetchData(): Promise<void>;
  selectAllRows: ITableRefHandles['selectAllRows'];
  selectRowsWithIds: ITableRefHandles['selectRowsWithIds'];
  unsetSelectedRows: ITableRefHandles['unsetSelectedRows'];
  removeProcessedMemberApplicants(memberIds: IMember['id'][]): void;
}

export const SEARCH_WORD_LENGTH = 2; // chars
const SEARCH_DEBOUNCE_TIME = 300; // ms
export const SEARCH_MEMBER_FIELDS = [
  // member fields that will be searched
  'Blog',
  'Facebook',
  'First Name',
  'Instagram',
  'Last Name',
  'Pinterest',
  'TikTok',
  'Twitter',
  'YouTube',
];

export const MemberTable = React.forwardRef<IMemberTableRefHandles, IMembersTableProps>((props, ref) => {
  const { onSelectedMembersChange, onMembersUpdated } = props;
  const tableRef = useRef<ITableRefHandles>();
  const [resetPageOnLoad, setResetPageOnLoad] = useState<boolean>(false);
  const [selectedMembers, setSelectedMembers] = useState<IMember[]>(props.selectedMembers || []);
  const [searchText, setSearchText] = useState<string>('');
  const [members, setMembers] = useState<IMember[]>([]);

  const { schemas } = useSearchTextQuery({
    memberFields: SEARCH_MEMBER_FIELDS,
  });

  const memberSearchQueryVariables = useMemberSearchQueryVariables(
    props.searchQuery,
    props.columnVisibility,
    props.pageSize,
  );

  const skipLoadingMembers = useMemo(() => !props.searchQuery || !props.columnVisibility, [
    props.searchQuery,
    props.columnVisibility,
  ]);

  const {
    loading: loadingMembers,
    error,
    data: { members: membersList = null } = {},
    fetchMore,
    refetch: refetchMembers,
    client: graphQLClient,
  } = useMemberSearchQuery({
    variables: memberSearchQueryVariables,
    skip: skipLoadingMembers,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // update member selection when it changes from controller
    setSelectedMembers(props.selectedMembers || []);
  }, [props.selectedMembers]);

  useEffect(() => {
    setMembers(membersList);
    if (onMembersUpdated) {
      onMembersUpdated(membersList);
    }
  }, [membersList, onMembersUpdated]);

  const { data: { count: memberCount = null } = {}, refetch: refetchMemberCount } = useQuery<
    MemberSearchCountQuery,
    MemberSearchCountQueryVariables
  >(MEMBER_SEARCH_COUNT_QUERY, {
    variables: {
      query: props.searchQuery,
    },
    skip: skipLoadingMembers,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (props.onChangeMatchingMembers && isNumber(memberCount)) {
      props.onChangeMatchingMembers(memberCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberCount]);

  const { showError } = useMessagingContext();

  useEffect(() => {
    if (error) {
      showError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChangeCellValue = useChangeCellValue();

  const mergeFetchResults = useCallback(
    (prev: MemberSearchQuery, result: MemberSearchQuery): MemberSearchQuery => ({
      ...prev,
      members: [...prev.members, ...result.members],
    }),
    [],
  );

  const fetchMoreMembers = useGraphQLFetchMore<MemberSearchQuery, MemberSearchQueryVariables>(
    fetchMore,
    mergeFetchResults,
  );

  const { updatePage, page } = usePagination(
    members,
    memberCount,
    props.pageSize,
    memberSearchQueryVariables.take,
    fetchMoreMembers,
  );

  useEffect(() => {
    // Reset selected members on page change
    setSelectedMembers([]);

    tableRef.current?.unsetSelectedRows();

    if (onSelectedMembersChange) {
      onSelectedMembersChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, onSelectedMembersChange, tableRef]);

  const { data: { membersHaveChanged = false } = {} } = useMemberPageLocalStateQuery();

  const updateMemberPageLocalState = useUpdateMemberPageLocalState();

  const handleDismissMembersHaveChangedNotice = () => {
    if (membersHaveChanged) {
      updateMemberPageLocalState(graphQLClient, { membersHaveChanged: false });
    }
  };

  const refetchData = async (queryVariables?: Partial<MemberSearchQueryVariables>) => {
    setResetPageOnLoad(true);
    handleDismissMembersHaveChangedNotice();

    await Promise.all([refetchMembers(queryVariables), refetchMemberCount(queryVariables)]);

    setSelectedMembers([]);

    if (onSelectedMembersChange) {
      onSelectedMembersChange([]);
    }

    tableRef?.current?.unsetSelectedRows();
  };

  const handleRefreshClick = () => {
    refetchData();
  };

  const removeProcessedMemberApplicants = useCallback(
    (membersIds: IMember['id'][]) => {
      const processedMemberApplicantIds = new Set(membersIds);

      const newMembersList = filter(members, (member) => !processedMemberApplicantIds.has(member.id));
      setMembers(newMembersList);
    },
    [members],
  );

  useImperativeHandle(ref, () => ({
    refetchData,
    selectAllRows: () => tableRef.current?.selectAllRows(),
    selectRowsWithIds: (ids: string[]) => tableRef.current?.selectRowsWithIds(ids),
    unsetSelectedRows: () => tableRef.current?.unsetSelectedRows(),
    removeProcessedMemberApplicants,
  }));

  useEffect(() => {
    if (membersHaveChanged && loadingMembers) {
      handleDismissMembersHaveChangedNotice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page > 0) {
      setResetPageOnLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchQuery]);

  useEffect(() => {
    if (resetPageOnLoad) {
      setResetPageOnLoad(false);
      updatePage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const selectedMemberIds = useMemo(() => map(selectedMembers, (member) => String(member.id)), [selectedMembers]);

  const checkboxCellConfig = useCheckboxCellConfig(members, selectedMemberIds, tableRef, props.showSelectAllCheckbox);

  const defaultColumnConfig = useMemo<ITableColumnConfig[]>(() => {
    if (props.renderCell) {
      return map<IColumn, ITableColumnConfig>(props.columns, props.renderCell);
    }

    return map<IColumn, ITableColumnConfig>(props.columns, (column) =>
      defaultRenderCell(column, { refetchProjectCounts: props.refetchProjectCounts }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns, props.renderCell]);

  const columnConfig = useMemo<ITableColumnConfig[]>(
    () => (props.showCheckboxColumn ? [checkboxCellConfig, ...defaultColumnConfig] : defaultColumnConfig),
    [defaultColumnConfig, props.showCheckboxColumn, checkboxCellConfig],
  );

  const mapMemberFields = useMapMemberFields();

  const defaultMemberMapping = useCallback(
    (member) => {
      const memberFields = mapMemberFields(member.fields);
      return {
        ...memberFields,
        ...member,
        name: {
          id: member.id,
          name: member.name,
          profile: member.profilePicture,
        },
        id: String(member.id),
      };
    },
    [mapMemberFields],
  );

  const tableData = useMemo(() => {
    const mapping = props.mapMemberData || defaultMemberMapping;
    return map(members, (member) => ({
      ...mapping(member),
      _raw: member,
      _sortableFields: props.sortableFieldsForMember ? props.sortableFieldsForMember(member) : null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, defaultMemberMapping, props.mapMemberData]);

  useEffect(() => {
    if (members && props.onLoadMembers) {
      props.onLoadMembers(members);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, props.onLoadMembers]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchMembers = useCallback(
    debounce((value: string) => {
      setSearchText(value);
      let filteredValue = value;
      if (value.startsWith('@')) {
        // remove @ from social handles
        filteredValue = value.split('@').pop();
      }

      if (size(filteredValue) > 0 && size(filteredValue) < SEARCH_WORD_LENGTH) {
        props.onSearchTextChange(filteredValue);
        return;
      }

      props.onSearchTextChange(filteredValue);
    }, SEARCH_DEBOUNCE_TIME),
    [schemas, props.searchQuery, setSearchText],
  );

  const handleColumnReorder = useColumnReorder(props.segmentId, props.segmentType);

  const currentPageMembers = useMemo(() => slice(membersList, page * props.pageSize, (page + 1) * props.pageSize), [
    membersList,
    page,
    props.pageSize,
  ]);

  const handleSelectMembers = useCallback(
    (newSelectedMembers: IMember[]) => {
      // This is made so we take only present on page members, as the table carry overs data (as it should)
      // Select should not carry over members, we are only interested in the visible ones.
      let updatedSelectedMembers;
      if (searchText) {
        const allPagesSelectedMemberIds = new Set<number>(map(selectedMembers, 'id'));
        const newSelectedMemberIds = new Set<number>(map(newSelectedMembers, 'id'));
        const currentPageSelectedMembers = filter(currentPageMembers, (member) =>
          allPagesSelectedMemberIds.has(member.id));
        const currentPageUnselectedMemberIds = new Set<number>(
          map(
            filter(currentPageSelectedMembers, (member) => !newSelectedMemberIds.has(member.id)),
            'id',
          ),
        );
        updatedSelectedMembers = filter(
          uniqBy([...selectedMembers, ...newSelectedMembers], 'id'),
          (member) => !currentPageUnselectedMemberIds.has(member.id),
        );
      } else {
        updatedSelectedMembers = newSelectedMembers;
      }

      if (onSelectedMembersChange) {
        onSelectedMembersChange(updatedSelectedMembers);
      }
      setSelectedMembers(updatedSelectedMembers);
    },
    [searchText, selectedMembers, currentPageMembers, onSelectedMembersChange, setSelectedMembers],
  );

  const renderEmptyState = useCallback(() => {
    if (!loadingMembers && isFunction(props?.renderEmptyState)) {
      return props.renderEmptyState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMembers, props?.renderEmptyState]);

  const renderTable = () => (
    <Table
      ref={tableRef}
      columns={columnConfig}
      className={styles.table}
      config={{
        rowHeight: 56,
        allowSearch: props.allowSearch,
        configurableColumns: false,
        selectable: false,
        pageSize: props.pageSize,
        striped: false,
        rowBorder: true,
        renderBodyRow: props.renderBodyRow,
        renderEmptyCellContent: renderEmptyCell,
        reorderableColumns: props.enableColumnReordering,
        resizableColumns: true,
      }}
      onSearchChange={handleSearchMembers}
      initialSelectedIds={props.initialSelectedMemberIds?.map((id) => `${id}`)}
      paddingBottom={20}
      totalRowCount={memberCount}
      resetSelectionOnPaging
      onSelectedDataChange={handleSelectMembers}
      page={page}
      pageSize={props.pageSize}
      onPageChangeCallback={updatePage}
      onSortDirChangeCallback={props.onSortDirChange}
      onChangeCellValue={handleChangeCellValue}
      onColumnReorder={handleColumnReorder}
      renderEmptyPlaceholder={renderEmptyState}
      data={tableData}
      disableInnerSearch
      headerActions={props.headerActions}
      initialColumnSort={props.initialColumnSort}
      tableHeader={(
        <>
          {props.showMembersChangedNotice && membersHaveChanged && (
            <Notice type="info" className={styles.notice} showDivider>
              <span>Member data are updated. Click Refresh to see the updated data</span>
              <Button
                label="Refresh"
                theme="primary"
                round={false}
                className={styles.btn}
                onClick={handleRefreshClick}
              />
              <Button
                label="Dismiss"
                theme="light"
                round={false}
                className={styles.btn}
                onClick={handleDismissMembersHaveChangedNotice}
              />
            </Notice>
          )}
          {props.tableHeader}
        </>
      )}
    />
  );

  const loading = props.isLoading || loadingMembers;

  if (skipLoadingMembers) {
    return null;
  }

  return (
    <div className={cx(styles.MemberTable, props.className)}>
      {loading && <OverlaySpinner />}
      {renderTable()}
    </div>
  );
});

MemberTable.defaultProps = {
  showCheckboxColumn: false,
  showSelectAllCheckbox: false,
  enableColumnReordering: false,
  renderCell: defaultRenderCell,
};
