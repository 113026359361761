/* eslint react/display-name: 0 */

import * as React from 'react';

import { OverlaySpinner } from '@components';

const { Suspense } = React;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lazyLoadComponent(Component: React.LazyExoticComponent<any>) {
  return (props) => (
    <Suspense fallback={<OverlaySpinner />}>
      <Component {...props} />
    </Suspense>
  );
}
