/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_43q4r_157 {
  display: flex;
}

._justify-content-space-between_43q4r_161 {
  justify-content: space-between;
}

._tabular-nums_43q4r_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_43q4r_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TextInput_43q4r_178 {
  height: 2.5rem !important;
  border-color: #dadcde;
  border-radius: 6.25rem;
  background-color: #eff5f9;
}
._TextInput_43q4r_178:focus {
  border-color: #3996E0;
  box-shadow: none;
}