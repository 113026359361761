import * as React from 'react';

import { Button, Tooltip } from '@revfluence/fresh';
import { UserPlusIcon } from '@revfluence/fresh-icons/regular/esm';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  tooltipText?: string;
}

export const AddMembersButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    className,
    disabled,
    onClick,
    tooltipText,
  } = props;
  return (
    <Tooltip placement="top" title={tooltipText || 'Add Members'}>
      <Button
        className={className}
        disabled={disabled}
        icon={<UserPlusIcon />}
        onClick={onClick}
      />
    </Tooltip>
  );
});

AddMembersButton.defaultProps = {
  disabled: false,
};

AddMembersButton.displayName = 'AddMembersButton';
