/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_jg7m1_157 {
  display: flex;
}

._justify-content-space-between_jg7m1_161 {
  justify-content: space-between;
}

._tabular-nums_jg7m1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_jg7m1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Notice_jg7m1_178 {
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  margin-bottom: 0.8rem;
  min-height: 3.75rem;
}
._Notice_jg7m1_178 ._iconWrapper_jg7m1_185 {
  align-items: center;
  display: flex;
  flex: 0 0 2.5rem;
  height: 100%;
  justify-content: center;
  font-size: 1.5rem;
}
._Notice_jg7m1_178 ._messageWrapper_jg7m1_193 {
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1 1 auto;
  padding: 1rem;
}
._Notice_jg7m1_178._info_jg7m1_200 {
  background-color: #EFF5F9;
}
._Notice_jg7m1_178._info_jg7m1_200 ._iconWrapper_jg7m1_185 {
  color: #167cf4;
}
._Notice_jg7m1_178._info_jg7m1_200 ._messageWrapper_jg7m1_193 {
  color: #1a1818;
}
._Notice_jg7m1_178._error_jg7m1_209 {
  background-color: #F8EDEE;
}
._Notice_jg7m1_178._error_jg7m1_209 ._iconWrapper_jg7m1_185 {
  color: #F1515F;
}
._Notice_jg7m1_178._error_jg7m1_209 ._messageWrapper_jg7m1_193 {
  color: #F1515F;
}
._Notice_jg7m1_178._alert_jg7m1_218 {
  background-color: #FFFBE6;
}
._Notice_jg7m1_178._alert_jg7m1_218 ._iconWrapper_jg7m1_185 {
  color: #FAAD14;
}
._Notice_jg7m1_178._alert_jg7m1_218 ._messageWrapper_jg7m1_193 {
  color: #1a1818;
}