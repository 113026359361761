import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_BRAND_CATALOG_DASHBOARD_STATS_QUERY } from '../queries';
import { GetBrandCatalogDashboardStats, GetBrandCatalogDashboardStatsVariables } from '../queries/types/GetBrandCatalogDashboardStats';

type IOptions = QueryHookOptions<GetBrandCatalogDashboardStats, GetBrandCatalogDashboardStatsVariables>;

export function useGetBrandCatalogDashboardStats(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetBrandCatalogDashboardStats, GetBrandCatalogDashboardStatsVariables>(
    GET_BRAND_CATALOG_DASHBOARD_STATS_QUERY,
    {
      ...options,
    },
  );

  return {
    isBrandCatalogDashboardStatsLoading: loading,
    brandCatalogDashboardStats: data?.catalogStats,
    brandCatalogDashboardStatsError: error,
    refetchBrandCatalogDashboardStats: refetch,
  };
}
