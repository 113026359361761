import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetImportedCollectionsStats } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportedCollectionsStats';
import { GET_IMPORTED_COLLECTIONS_STATS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/shopifyCollections';

type IOptions = QueryHookOptions<GetImportedCollectionsStats>;

export const useGetAllCollectionStats = (options: IOptions = {}) => {
    const {
   loading, data, error, refetch,
  } = useQuery(GET_IMPORTED_COLLECTIONS_STATS_QUERY, options);

  return {
    loading,
    stats: {
      shopifyCollectionCount: data?.getImportedCollectionsStats?.shopifyCollectionCount ?? 0,
      shopifyProductsCount: data?.getImportedProductsStats?.shopifyProductsCount ?? 0,
      shopifyVariantsCount: data?.getImportedProductsStats?.shopifyVariantsCount ?? 0,
    },
    error,
    refetch,
  };
};
