import { addEventLog } from './addEventLog';

/**
 * Function to allow adding event logs in widgets that are used in Create and Engage.
 *
 * @param {String} eventName name of event to track
 * @param {Object} parameters parameters for event
 * @param {SegmentAnalytics.AnalyticsJS} aspirexAnalytics optional analytics object from aspirex
 *
 */

export function genericAddEventLog(
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameters: any = {},
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS,
) {
  if (aspirexAnalytics) {
    parameters.aspirex = true;
    try {
      aspirexAnalytics.track(eventName, parameters);
    } catch (err) {
      console.log(`Failed to add event log for '${eventName}'`);
    }
  } else {
    parameters.aspirex = false;
    addEventLog(eventName, parameters);
  }
}
