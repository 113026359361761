import gql from 'graphql-tag';

export const UPSERT_BUDGET_ACCOUNT_DISTRIBUTION = gql`mutation UpsertBudgetAccountDistribution($budgetAccountInput: UpsertBudgetAccountDistributionInput!) {
    budgetAccountDistribution: upsertBudgetAccountDistribution(budgetAccountInput: $budgetAccountInput) {
        budgetId
        budgetName
        budgetDescription
        parentBudgetId
        fiscalYearsBudget
    }
}`;
