import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const BarcodeIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M19.5,4.81H4.5C3.12,4.81,2,5.93,2,7.31v9.38c0,1.38,1.12,2.5,2.5,2.5H19.5c1.38,0,2.5-1.12,2.5-2.5V7.31
      C22,5.93,20.88,4.81,19.5,4.81z M20.77,16.69c0,0.7-0.57,1.27-1.27,1.27H4.5c-0.7,0-1.27-0.57-1.27-1.27V7.31
      c0-0.7,0.57-1.27,1.27-1.27H19.5c0.7,0,1.27,0.57,1.27,1.27V16.69z"
    />
    <path
      d="M5.96,8C5.62,8,5.35,8.27,5.35,8.61v6.26c0,0.34,0.28,0.61,0.61,0.61c0.34,0,0.61-0.27,0.61-0.61V8.61
      C6.58,8.27,6.3,8,5.96,8z"
    />
    <path
      d="M18.54,8c-0.34,0-0.61,0.27-0.61,0.61v6.26c0,0.34,0.27,0.61,0.61,0.61c0.34,0,0.61-0.27,0.61-0.61V8.61
      C19.16,8.27,18.88,8,18.54,8z"
    />
    <path
      d="M12.43,8c-0.34,0-0.61,0.27-0.61,0.61v6.26c0,0.34,0.27,0.61,0.61,0.61c0.34,0,0.61-0.27,0.61-0.61V8.61
      C13.04,8.27,12.77,8,12.43,8z"
    />
    <path
      d="M9.21,8C8.87,8,8.6,8.27,8.6,8.61v4.92c0,0.34,0.27,0.61,0.61,0.61c0.34,0,0.61-0.27,0.61-0.61V8.61
      C9.83,8.27,9.55,8,9.21,8z"
    />
    <path
      d="M15.47,8c-0.34,0-0.61,0.27-0.61,0.61v4.92c0,0.34,0.27,0.61,0.61,0.61c0.34,0,0.61-0.27,0.61-0.61V8.61
      C16.08,8.27,15.81,8,15.47,8z"
    />
  </SvgIcon>
));

BarcodeIcon.displayName = 'BarcodeIcon';
