import { Button, Tag } from 'antd';
import { first, isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { IAdditionalDeepLinks, TMemberTableRow } from './types';
import { randomiseColorsForTags } from '../../utils';

interface IProps {
  affiliateOfferLinkRecord?: TMemberTableRow;
  setDeeplinkData: (value: TMemberTableRow) => void;
  setDeeplinkDrawerVisible: (value: boolean) => void;
}
export const AdditionalDeeplinkTags: React.FC<Readonly<IProps>> = (props: IProps) => {
  const { affiliateOfferLinkRecord, setDeeplinkData, setDeeplinkDrawerVisible } = props;
  const { additionalDeepLinks = [] } = affiliateOfferLinkRecord;
  const onButtonClick = () => {
    setDeeplinkDrawerVisible(true);
    setDeeplinkData(affiliateOfferLinkRecord);
  };
  const renderItem = useCallback((links: IAdditionalDeepLinks[]) => {
    if (isEmpty(links)) {
      return <Tag>+</Tag>;
    }
    const label = first(links).label;
    const color = randomiseColorsForTags(first(links).label);
    if (links.length > 1) {
      return [
        <Tag key={label} color={color}>{label}</Tag>,
        <Tag key={-1}>
          +
          {links.length - 1}
        </Tag>,
      ];
    }
    return <Tag color={color}>{label}</Tag>;
  }, []);
  return (
    <>
      <Button
        type="default"
        style={{ padding: 0, border: 'none', background: 'transparent' }}
        onClick={onButtonClick}
        size="small"
        disabled={affiliateOfferLinkRecord.linkStatus.toLowerCase() !== 'active'}
      >
        {renderItem(additionalDeepLinks)}
      </Button>
    </>
  );
};
