/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1mo6u_157 {
  display: flex;
}

._justify-content-space-between_1mo6u_161 {
  justify-content: space-between;
}

._tabular-nums_1mo6u_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1mo6u_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SimpleTextarea_1mo6u_178._error_1mo6u_178 ._message_1mo6u_178 {
  margin-top: 0.4375rem;
  color: #f1515f;
  font-size: 0.8125rem;
}
._SimpleTextarea_1mo6u_178 ._textarea_1mo6u_183 {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
._SimpleTextarea_1mo6u_178 ._textarea_1mo6u_183._error_1mo6u_178 {
  border: 0.0625rem solid #f1515f !important;
  background-color: #f6e4e5 !important;
}
._SimpleTextarea_1mo6u_178 ._textarea_1mo6u_183._error_1mo6u_178 textarea {
  color: #da3737 !important;
}
._SimpleTextarea_1mo6u_178 ._textarea_1mo6u_183._error_1mo6u_178 textarea::placeholder {
  color: #f1515f !important;
  opacity: 0.8;
}