import gql from 'graphql-tag';
import { FLEXIBLE_BRIEF_TEMPLATE } from './fragments/TermsTemplateFragment';

export const GET_WAIT_FOR_AGREEMENT_TERMS = gql`
  query GetWaitForAgreementTermsQuery($agreementId: String!) {
    termsTemplate: getTermsAgreementFromCreate(agreementId: $agreementId) {
      ...FlexibleBriefFragment
    }
  }
  ${FLEXIBLE_BRIEF_TEMPLATE}
`;
