/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_coml4_157 {
  display: flex;
}

._justify-content-space-between_coml4_161 {
  justify-content: space-between;
}

._tabular-nums_coml4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_coml4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Deliverable_coml4_178 ._header_coml4_178 ._text_coml4_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._Deliverable_coml4_178 ._header_coml4_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.9375rem;
  cursor: pointer;
}
._Deliverable_coml4_178 ._header_coml4_178 ._arrow_coml4_195 {
  display: flex;
  margin-right: 0.625rem;
  color: #8f8d91;
}
._Deliverable_coml4_178 ._header_coml4_178 ._icon_coml4_200 {
  display: flex;
  margin-right: 0.625rem;
}
._Deliverable_coml4_178 ._header_coml4_178 ._dueDate_coml4_204 {
  margin-left: auto;
  color: #8f8d91;
}
._Deliverable_coml4_178 ._content_coml4_208 {
  padding: 0.9375rem;
}
._Deliverable_coml4_178:not(._collapsed_coml4_211) ._arrow_coml4_195 {
  color: #3996e0;
}
._Deliverable_coml4_178._collapsed_coml4_211 ._arrow_coml4_195 {
  transform: rotateZ(-90deg);
}
._Deliverable_coml4_178._collapsed_coml4_211 ._content_coml4_208 {
  display: none;
}