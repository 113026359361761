import {
  isEmpty,
  map,
} from 'lodash';
import { IContentGuidelinesWithDueDates } from '../../types/ContentGuidelines';

import {
  INextStepAction,
  IState,
  Step,
} from '../../types/state';
import { applyContentGuidelinesToMembers } from '../../utils/applyContentGuidelinesToMembers';

const hasApprovedAndNotReviewedContent = (contentGuidelinesWithDueDates: IContentGuidelinesWithDueDates) => {
  const {
    requiresReviewBeforeSubmission,
    requiresReviewWithinThreeDaysOfCreatorSubmission,
  } = contentGuidelinesWithDueDates;
  return requiresReviewBeforeSubmission && !requiresReviewWithinThreeDaysOfCreatorSubmission;
};

export const nextStep = (state: IState, _action: INextStepAction): IState => {
  const {
    collaborationDetails: {
      reward: {
        payment: {
          showHideNewPrice,
        },
        freeProduct: {
          showHideDescription: showHideFreeProductDescription,
          description: freeProductDescription,
        },
        commission: {
          showHideDescription: showHideCommissionDescription,
          description: commissionDescription,
        },
        noCompensation: {
          showHideDescription: showHideNoCompensation,
        },
      },
    },
  } = state;
  switch (state.step) {
    case Step.AddContentGuidelines:
      return {
        ...state,
        step: Step.CollaborationDetails,
      };
    case Step.CollaborationDetails:
      if (isEmpty(state.contentGuidelines)
        || !(showHideNewPrice
          || showHideFreeProductDescription
          || showHideCommissionDescription
          || showHideNoCompensation)) {
        // they have no content guidelines or no reward section has been selected,
        // prevent them from moving to the next state
        // the UI should have disabled the button anyway, but this is a check, just in case
        return state;
      }
      if (state.contentGuidelines.some(hasApprovedAndNotReviewedContent)) {
        // they have selected "Content must be submitted for review before being posted"
        // and they have not selected "I agree to review..."
        return state;
      }
      if ((showHideFreeProductDescription && isEmpty(freeProductDescription))
        || (showHideCommissionDescription && isEmpty(commissionDescription))) {
          // they have selected reward section but they have not filled the value
          return {
            ...state,
            collaborationDetails: {
              ...state.collaborationDetails,
              reward: {
                ...state.collaborationDetails.reward,
                freeProduct: {
                  ...state.collaborationDetails.reward.freeProduct,
                },
                commission: {
                  ...state.collaborationDetails.reward.commission,
                },
                noCompensation: {
                  ...state.collaborationDetails.reward.noCompensation,
                },
              },
            },
          };
      }
      return {
        ...state,
        members: map(
          state.members,
          applyContentGuidelinesToMembers(state),
        ),
        step: Step.ReviewAndSetOffers,
      };
    case Step.ReviewAndSetOffers:
      return {
        ...state,
        step: Step.WriteMessage,
      };
    default:
      return state;
  }
};
