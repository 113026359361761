import gql from 'graphql-tag';

const GET_PROJECT_BUDGET_DASHBOARD_DATA = gql`
    query GetProjectBudgetDashboardData ($budgetId: Int, $fiscalYear: String, $programId: Int) {
        projectBudgetDashboardData: getProjectBudgetDashboardData (budgetId: $budgetId, fiscalYear: $fiscalYear, programId: $programId) {
            availableAmount
            budgetAccounts
            budgetId
            budgetName
            sourceCount
            spentAmount
            totalAmount
            totalCommittedAmount
            unpaidCommittedAmount
        }
    }
`;

export default GET_PROJECT_BUDGET_DASHBOARD_DATA;
