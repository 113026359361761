import * as React from 'react';

import { FileLinesIcon } from '@revfluence/fresh-icons/regular/esm';

import {
  IComplexStat,
  StatisticsCard,
} from '@frontend/app/components/StatisticsCard';

interface IProps {
  data: IComplexStat[];
  loading: boolean;
}

const EMPTY_LIST = [];

const WidgetAcceptedTerms: React.FC<IProps> = React.memo((props) => {
  const {
    data,
    loading,
  } = props;

  return (
    <StatisticsCard
      icon={<FileLinesIcon />}
      links={EMPTY_LIST}
      loading={loading}
      stats={data}
      subtitle=""
      smallStats={EMPTY_LIST}
      title="Accepted Terms"
    />
  );
});

export default WidgetAcceptedTerms;

WidgetAcceptedTerms.displayName = 'WidgetAcceptedTerms';
