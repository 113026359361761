._ProjectDropdown_vvdwd_1 {
  min-height: max-content;
  max-height: 350px;
  max-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
}

._Checkbox_vvdwd_9 {
  width: 100%;
}