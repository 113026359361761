import { MutationHookOptions, useMutation } from '@apollo/client';
import { UPDATE_CATALOG_COLLECTION_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { UpdateCatalogCollectionMutation, UpdateCatalogCollectionMutationVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/UpdateCatalogCollectionMutation';

type IOptions = MutationHookOptions<UpdateCatalogCollectionMutation, UpdateCatalogCollectionMutationVariables>;

export const useUpdateCatalogCollection = (options: IOptions = {}) => {
  const [updateCollectionItem, { loading, data, error }] = useMutation(UPDATE_CATALOG_COLLECTION_MUTATION, options);

  return {
    loading,
    collectionItem: data?.updateCatalogCollection,
    error,
    updateCollectionItem,
  };
};
