import * as React from 'react';
import { isEmpty } from 'lodash';
import {
  ITableCellRenderContext,
  TNetworkIdentifier,
} from '@components';
import { getSocialHandle } from '@frontend/app/utils/getSocialHandle';

import { SocialProfileCell } from './SocialProfileCell';

interface ISocialProfileCellData {
  username: string;
  network: TNetworkIdentifier;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetchProjectCounts?: () => Promise<any>;
}

export const renderSocialProfileCell = (data: ISocialProfileCellData, context: ITableCellRenderContext) => {
  const sanitizedUsername = getSocialHandle({
    network: data.network,
    username: data.username,
    shouldPrependAtSymbol: false,
  });

  return (
    sanitizedUsername && !isEmpty(data.network)
      ? (
        <SocialProfileCell
          username={sanitizedUsername}
          network={data.network}
          isEditing={context.isEditing}
          onToggleIsEditing={context.onToggleEditing}
          onChangeValue={context.onChangeCellValue}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          member={(context as any).rowData}
          refetchProjectCounts={data.refetchProjectCounts}
        />
      )
      : sanitizedUsername
  );
};
