import React, { useCallback, useMemo } from 'react';
import { IColumnsType, Select, Table } from '@revfluence/fresh';
import { useGetCatalogs } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useGetSelectionCriteria } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetSelectionCriteria';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { useRouteMatch } from 'react-router-dom';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useApolloClient } from '@apollo/client';
import { GetProjectConfigByProjectIdAndType } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProjectConfigByProjectIdAndType';
import { GET_PROJECT_CONFIG_BY_TYPE_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries';
import { isEmailAddressValid } from '@common';
import { MemberSelectionData, MemberSelectionRow } from './types';
import { useSendCatalogContext } from './SendCatalogContext';
import styles from './PerMemberSelection.scss';

const SelectionRuleRenderer = ({
  brandCatalogId,
  value,
  onChange,
}: {
  brandCatalogId: number;
  value: number;
  onChange: (value: number) => void;
}) => {
  const { criteria, loading: isCriteriaLoading } = useGetSelectionCriteria({
    variables: {
      brandCatalogId,
    },
    skip: !brandCatalogId,
  });

  return (
    <Select
      placeholder={isCriteriaLoading ? 'Loading...' : !brandCatalogId || criteria?.length ? 'Select a Rule' : 'No rules available'}
      value={value}
      onChange={onChange}
      disabled={!isCriteriaLoading && (!brandCatalogId || !criteria.length)}
      className={styles.fullWidthSelect}
      loading={isCriteriaLoading}
    >
      {criteria.map((rule) => (
        <Select.Option value={rule.id} key={rule.id}>
          {rule.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export const PerMemberSelection = () => {
  const {
    params: {
      projectId,
    },
  } = useRouteMatch<{ projectId: string }>();

  const { catalogs } = useGetCatalogs();

  const { projectConfig: projectConfigFromBE } = useGetProjectConfigByType({
    variables: {
      projectId: Number(projectId),
      type: ProjectConfigType.ProductCatalog,
    },
    skip: !projectId,
  });

  const apolloClient = useApolloClient();
  const projectConfigFromCache = apolloClient.readQuery<GetProjectConfigByProjectIdAndType>({
    query: GET_PROJECT_CONFIG_BY_TYPE_QUERY,
    variables: {
      projectId: Number(projectId),
      type: ProjectConfigType.ProductCatalog,
    },
  });
  const projectConfig = projectConfigFromCache?.projectConfig || projectConfigFromBE;

  const { allMembers } = useProductFulfillmentContext();

  const members = allMembers.filter((m) => isEmailAddressValid(m.email));

  const filteredCatalogs = useMemo(() => catalogs.filter((catalog) => projectConfig?.brandCatalogs?.includes(catalog.id)),
   [catalogs, projectConfig]);

  const {
 selectedCatalogs, setSelectedCatalogs, selectedRules, setSelectedRules,
} = useSendCatalogContext();

  const handleSelectCatalog = useCallback(
    (memberId: number, catalogId: number) => {
      setSelectedCatalogs({ memberId, catalogId });
      setSelectedRules({ memberId, ruleId: null });
    },
    [setSelectedCatalogs, setSelectedRules],
  );
  const handleSelectRule = useCallback(
    (memberId: number, ruleId: number) => {
      setSelectedRules({ memberId, ruleId });
    },
    [setSelectedRules],
  );

  const columns = useMemo<IColumnsType>(
    () => [
      {
        key: 'memberName',
        dataIndex: 'memberName',
        title: 'Member',
        width: '34%',
      },
      {
        key: 'catalog',
        dataIndex: '_raw',
        title: 'Catalog',
        width: '33%',
        render: ({ id }: MemberSelectionData) => (
          <Select
            placeholder="Select a Catalog"
            value={selectedCatalogs[id]}
            onChange={(value) => handleSelectCatalog(Number(id), value)}
            className={styles.fullWidthSelect}
          >
            {filteredCatalogs.map((catalog) => (
              <Select.Option value={catalog.id} key={catalog.id}>
                {catalog.name}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        key: 'ruleId',
        dataIndex: '_raw',
        title: 'Offer Ruleset',
        width: '33%',
        render: ({ id }: MemberSelectionData) => (
          <SelectionRuleRenderer
            brandCatalogId={selectedCatalogs[id]}
            value={selectedRules[id]}
            onChange={(value) => handleSelectRule(Number(id), value)}
          />
        ),
      },
    ],
    [filteredCatalogs, handleSelectCatalog, handleSelectRule, selectedCatalogs, selectedRules],
  );

  const tableData = useMemo<MemberSelectionRow[]>(
    () =>
      members?.map((member) => {
        const transformedData: MemberSelectionData = {
          key: String(member.id),
          id: String(member.id),
          memberName: member.name,
          catalogId: null,
          ruleId: null,
        };
        const rowData: MemberSelectionRow = { ...transformedData, _raw: transformedData };
        return rowData;
      }) ?? [],
    [members],
  );

  return <Table columns={columns} dataSource={tableData} className={styles.PerMemberSelection} />;
};
