/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Icon_r3ciy_79 {
  position: relative;
}

._badgeContainer_r3ciy_83 {
  position: relative;
  width: 100%;
  height: 100%;
}
._badgeContainer_r3ciy_83 ._badge_r3ciy_83 {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  min-width: 1rem;
  padding: 0 4px;
  height: 1rem;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 624.9375rem;
  background: #3996e0;
  color: #fdfdfd;
  font-size: 0.625rem;
  text-align: center;
  line-height: 1rem;
  transform: translate(50%, -50%);
}

._hasBadge_r3ciy_107 {
  overflow: visible;
}
._hasBadge_r3ciy_107 ._badgeContainer_r3ciy_83 {
  overflow: visible;
}

._SvgIcon_r3ciy_114 {
  position: relative;
  display: inline-block;
  user-select: none;
  color: currentColor;
  fill: currentColor;
}

._disabled_r3ciy_122 {
  fill: #bdbdbd !important;
}