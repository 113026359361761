import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const RefreshIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M7.97,10.43c-0.25-0.21-0.63-0.18-0.84,0.08l-1.84,2.2c-0.1-1.32,0.22-2.65,0.92-3.84
      c0.96-1.6,2.52-2.76,4.29-3.18c2.33-0.55,4.76,0.22,6.35,2.02c0.22,0.25,0.59,0.27,0.84,0.05c0.25-0.22,0.27-0.59,0.05-0.84
      c-1.88-2.12-4.76-3.04-7.52-2.39C8.15,5.02,6.32,6.38,5.2,8.26c-0.66,1.1-1.02,2.32-1.1,3.55L3.09,10.3
      c-0.18-0.27-0.55-0.35-0.83-0.16c-0.27,0.18-0.35,0.55-0.16,0.83l2.32,3.46c0.1,0.16,0.28,0.25,0.46,0.26c0.01,0,0.02,0,0.03,0
      c0.18,0,0.34-0.08,0.46-0.21l2.67-3.2C8.26,11.02,8.22,10.64,7.97,10.43z"
    />
    <path
      d="M21.9,13.88l-2.32-3.46c-0.1-0.16-0.28-0.25-0.46-0.26c-0.17-0.01-0.34,0.06-0.46,0.19
      c-0.04,0.03-0.07,0.07-0.1,0.11l-2.6,3.12c-0.21,0.25-0.18,0.63,0.08,0.84c0.25,0.21,0.63,0.18,0.84-0.08l1.68-2.01
      c-0.17,2.75-2.23,5.32-5.06,5.99c-2.19,0.52-4.45-0.12-6.05-1.7c-0.23-0.23-0.61-0.23-0.84,0.01c-0.23,0.23-0.23,0.61,0.01,0.84
      c1.46,1.44,3.39,2.22,5.38,2.22c0.59,0,1.19-0.07,1.78-0.21c3.26-0.77,5.62-3.56,5.95-6.7l1.19,1.78c0.11,0.17,0.3,0.26,0.5,0.26
      c0.11,0,0.23-0.03,0.33-0.1C22.01,14.52,22.08,14.15,21.9,13.88z"
    />
  </SvgIcon>
));

RefreshIcon.displayName = 'RefreshIcon';

export { RefreshIcon };
