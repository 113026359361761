import * as React from 'react';
import cx from 'classnames';
import {
  map, first, isEmpty, find, isNil, includes, join,
} from 'lodash';

import {
  Table, ITableColumnConfig, Notice,
  Select, Input, SubmitButton, LoadSpinner,
} from '@components';
import { Switch, Tooltip, message } from 'antd';
import { logger } from '@common';

import { useAuth } from '@frontend/context/authContext';
import {
  useSaveMemberRestriction,
  useGetMembersRestrictions,
  useDeleteMemberRestrictionById,
  useGetMembersCountForRestriction,
  useGetCurrentClient,
  useSaveCurrentClient,
  useFeatureFlagVerbiage,
} from '@frontend/app/hooks';
import {
  MemberRestrictionInput,
  MemberDataRestrictionFieldName,
  MemberDataRestrictionFieldType,
} from '@frontend/app/types/globalTypes';
import { filterOptions } from './constants';

import { ConfirmationModal, IRuleInfo } from './ConfirmationModal';

const { useState, useRef } = React;
const columns: ITableColumnConfig[] = [
  {
    field: 'type',
    headerName: 'Type',
    width: 250,
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 270,
  },
  {
    cellType: 'date',
    field: 'timeAdded',
    headerName: 'Time Added',
    isUnix: false,
  },
  {
    field: 'remove',
    headerName: null,
    width: 150,
  },
];

const { useMemo } = React;

import styles from './MemberRestrictionSettings.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const MemberRestrictionSettings: React.FC<IProps> = React.memo((props) => {
  const { user } = useAuth();
  const isBrandAdmin = includes(user['https://aspirex.api.com/roles'], 'manager:admin');
  const { restrictions, loading } = useGetMembersRestrictions({
    skip: !isBrandAdmin,
  });
  const { refetch: getCount } = useGetMembersCountForRestriction({
    skip: true,
  });
  const { saveMemberRestriction, loading: saving } = useSaveMemberRestriction();
  const { deleteMemberRestrictionById } = useDeleteMemberRestrictionById();
  const { client: currentClient } = useGetCurrentClient();
  const { saveClient, loading: savingCurrentClient } = useSaveCurrentClient();
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);
  const [filterValue, setFilterValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [ruleInfo, setRuleInfo] = useState<IRuleInfo>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const verbiage = useFeatureFlagVerbiage();

  const toggleDisabled = savingCurrentClient || !currentClient || isEmpty(currentClient?.domains);
  const tableData = useMemo(() => map(restrictions, (restriction) => {
    const option = find(
      filterOptions,
      (o) => o.filterFieldId === restriction.fieldName && o.filterType === restriction.filterType,
    );

    return {
      id: restriction.id,
      type: option?.displayName || '',
      value: restriction.filterValue,
      timeAdded: new Date(restriction.createdDate).getTime(),
      remove: (
        <div
          className={styles.removeCell}
          onClick={() => deleteMemberRestrictionById({
            variables: {
              id: restriction.id,
            },
          })}
        >
          Remove
        </div>
      ),
      _raw: restriction,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [restrictions]);
  const onRemoveEmails = () => {
    // If ruleInfo.restrictions is set, that means we are using the toggle. If we are using the toggle, we do not want to saveRestriction.
    saveMemberRestriction({
      variables: {
        restrictions: isNil(ruleInfo.restriction) ? ruleInfo.restrictions : [ruleInfo.restriction],
        saveRestriction: isNil(ruleInfo?.restrictions),
      },
    }).then(() => {
      if (ruleInfo.restrictions) {
        saveClient({
          variables: {
            client: {
              id: currentClient.id,
              memberDomainsRestricted: !currentClient.memberDomainsRestricted,
            },
          },
        });
      }
      setShowConfirmationModal(false);
    }).catch((err) => {
      console.error(err);
      message.error('There\'s an error when trying to add the restriction.');
    });
  };
  const toggleClicked = () => {
    const isChecked = !currentClient.memberDomainsRestricted;
    if (isChecked) {
      if (!isEmpty(currentClient?.domains)) {
        const restrictionsArray: MemberRestrictionInput[] = map(currentClient.domains, (domain) => ({
            filterFieldId: MemberDataRestrictionFieldName.EMAIL,
            filterType: MemberDataRestrictionFieldType.POSTFIX,
            filterValue: domain,
          }));
        console.log('Restrictions: ', restrictionsArray);
        getCount({
          restrictions: restrictionsArray,
        }).then(({ data }) => {
          if (data.count > 0) {
            setRuleInfo({
              count: data.count,
              restrictions: restrictionsArray,
              restriction: null,
            });

            setShowConfirmationModal(true);
          } else {
            saveClient({
              variables: {
                client: {
                  id: currentClient.id,
                  memberDomainsRestricted: isChecked,
                },
              },
            });
          }
        }).catch(logger.error);
      }
    } else {
      saveClient({
        variables: {
          client: {
            id: currentClient.id,
            memberDomainsRestricted: isChecked,
          },
        },
      });
    }
  };
  const onAddRestriction = () => {
    setErrorMessage('');
    const option = filterOptions[selectedOptionIndex];
    const restriction: MemberRestrictionInput = {
      filterFieldId: option.filterFieldId,
      filterType: option.filterType,
      filterValue,
    };

    console.log(option, filterValue);
    getCount({
      restrictions: [restriction],
    }).then(({ data }) => {
      console.log('count', data.count);
      setFilterValue('');
      if (data.count > 0) {
        setRuleInfo({
          count: data.count,
          restriction,
          restrictions: null,
        });

        setShowConfirmationModal(true);
      } else {
        console.log('saving');
        return saveMemberRestriction({
          variables: {
            restrictions: [restriction],
          },
        });
      }
    }).catch((err) => {
      console.log(err);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorMessage = (first(err.graphQLErrors) as any)?.message || 'There\'s an error when trying to add the restriction.';

      setErrorMessage(errorMessage);
    }).finally(() => inputRef.current.focus());
  };

  if (loading) {
    return <LoadSpinner />;
  }

  return (
    <div className={cx(styles.MemberRestrictionSettings, props.className)}>
      <div className={styles.title}>Conversation Privacy</div>
      <div className={styles.description}>
        Prevent unwanted conversations from appearing in your Inbox by restricting the email addresses that can be associated with
        {' '}
        {verbiage.community}
        {' '}
        members.
      </div>
      <div className={styles.toggleSection}>
        <div className={styles.left}>
          <div className={styles.header}>
            Restrict brand email domain(s)
          </div>
          <div className={styles.text}>
            Turning this on will prevent any
            {' '}
            {verbiage.community}
            {' '}
            members from being associated with your company's email domains:
            <div className={styles.domains}>
              {join(currentClient?.domains, ', ')}
            </div>
          </div>
        </div>
        <Tooltip
          title="No email domains have been added for your brand yet"
          overlayStyle={{
            zIndex: 9999,
            display: !toggleDisabled ? 'none' : undefined,
          }}
        >
          <Switch
            className={styles.toggle}
            checked={currentClient?.memberDomainsRestricted}
            onClick={() => toggleClicked()}
            disabled={toggleDisabled}
          />
        </Tooltip>
      </div>
      <div className={styles.emailSection}>
        <div className={styles.header}>
          Additional
          {' '}
          {verbiage.community}
          {' '}
          member email restrictions
        </div>
        <div className={styles.addRule}>
          <Select
            className={styles.select}
            round
            options={map(filterOptions, (option) => ({
              label: option.displayName,
              value: option,
            }))}
            selectedIndex={selectedOptionIndex}
            onChange={(_, index) => {
              setErrorMessage('');
              setSelectedOptionIndex(index);
            }}
          />
          <Input
            ref={inputRef}
            className={styles.input}
            onChange={(value) => {
              setErrorMessage('');
              setFilterValue(value);
            }}
            onPressEnter={onAddRestriction}
            value={filterValue}
          />
          <SubmitButton
            label="Restrict"
            disabled={isEmpty(filterValue)}
            onClick={onAddRestriction}
            submittingLabel="Restricting..."
            isSubmitting={saving}
            className={styles.button}
          />
        </div>
        {!isEmpty(errorMessage) && (
          <Notice type="error" showDivider className={styles.notice}>
            {errorMessage}
          </Notice>
        )}
        {isEmpty(tableData) && (
          <div className={styles.emptyNotice}>
            You have no restricted email accounts at this time
          </div>
        )}
        {!isEmpty(tableData) && (
          <Table
            data={tableData}
            columns={columns}
            config={{
              allowSearch: false,
              configurableColumns: false,
              selectable: false,
              scrollable: false,
            }}
            className={styles.table}
          />
        )}
      </div>
      <ConfirmationModal
        className={styles.ConfirmationModal}
        ruleInfo={ruleInfo}
        open={showConfirmationModal}
        onRemoveEmails={() => onRemoveEmails()}
        onRequestClose={() => setShowConfirmationModal(false)}
      />
    </div>
  );
});
