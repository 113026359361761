import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import {
 SpinnerIcon, SendOfferIcon, EditIcon, CloseIcon,
} from '@components';
import { Button } from '@components';
import { Input } from '@components';

import styles from './LeaveCommentSection.scss';

interface IProps {
  value: string;
  isUpdatingValue: boolean;
  onChange(value: string);

  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  editMode: boolean;
  value: string;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class LeaveCommentSection extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.state = {
      editMode: isEmpty(props.value),
      value: props.value || '',
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { isUpdatingValue, classNames } = this.props;
    const { editMode, value } = this.state;

    return (
      <div className={cx(classNames.concat(styles.LeaveCommentSection))}>
        {editMode && (
          <div className={styles.editMode}>
            <Input
              theme="info"
              value={value}
              placeholder="Write your feedback..."
              onChange={this.onValueChange}
            />
            <Button
              theme="info"
              label="Submit"
              round
              className={styles.submitButton}
              onClick={this.onSubmit}
            />
            <Button
              theme="light"
              label="Cancel"
              round
              className={styles.cancelButton}
              onClick={this.onCancel}
            />
          </div>
        )}
        {!editMode && (
          <div className={styles.displayMode}>
            <SendOfferIcon className={styles.messageIcon} />
            <div className={styles.feedback}>
              <div className={styles.feedbackTitle}>Your feedback:</div>
              {value}
            </div>
            <div className={styles.actions}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {isUpdatingValue && <SpinnerIcon size={20} className={(styles as any).spinnerIcon} />}
              {!isUpdatingValue && (
                <>
                  <EditIcon size={18} className={styles.icon} onClick={this.enterEditMode} />
                  <CloseIcon size={22} className={styles.icon} onClick={this.onClearClick} />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  private onValueChange = (value) => {
    this.setState({
      value,
    });
  };

  private enterEditMode = () => {
    this.setState({
      editMode: true,
    });
  };

  private onClearClick = () => {
    const { onChange } = this.props;

    onChange('');

    this.setState({
      value: '',
      editMode: true,
    });
  };

  private onSubmit = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    onChange(value);

    this.setState({
      editMode: false,
    });
  };

  private onCancel = () => {
    const { value } = this.props;

    this.setState({
      editMode: false,
      value,
    });
  };
}
