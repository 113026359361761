/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fgj8_157 {
  display: flex;
}

._justify-content-space-between_1fgj8_161 {
  justify-content: space-between;
}

._tabular-nums_1fgj8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fgj8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._disabledRow_1fgj8_178 {
  background-color: #f5f5f5;
  pointer-events: none;
  opacity: 0.6;
}

._ellipsis_1fgj8_184 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._newBulkPaymentsTableContainer_1fgj8_190 ._headerContainer_1fgj8_190 {
  display: flex;
  justify-content: space-between;
}