import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const DollarIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M11.6,19.9c-2.1-0.2-3.8-2-3.8-4.2c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6c0,1.5,1.2,2.8,2.7,3
      v-6.1c-2.1-0.2-3.8-2-3.8-4.2s1.7-4,3.8-4.2V2.6c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6v1.8c2,0.3,3.5,2.1,3.5,4.1
      c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6c0-1.4-1-2.7-2.4-3v6.1c2,0.3,3.5,2.1,3.5,4.1s-1.5,3.8-3.5,4.1v1.6
      c0,0.3-0.3,0.6-0.6,0.6c-0.3,0-0.6-0.3-0.6-0.6V19.9z M12.7,18.7c1.4-0.3,2.4-1.5,2.4-3c0-1.4-1-2.7-2.4-3V18.7z M11.6,5.5
      c-1.5,0.2-2.7,1.5-2.7,3c0,1.5,1.2,2.8,2.7,3V5.5z"
    />
  </SvgIcon>
));

DollarIcon.displayName = 'DollarIcon';

export { DollarIcon };
