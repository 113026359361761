import { TNetworkIdentifier } from '@components';
import { SearchTermType } from '@frontend/components/pages/SearchPage/models';

export interface ILocation {
  label: string;
  value: string;
}

export type TLatLng = [number, number];

export type TGender = '' | 'MALE' | 'FEMALE';

export type TLocationType = 'Country' | 'Region';

export type TInvitedType = 'all' | 'uninvited';

export interface ISavedSearch {
  campaign_id?: number;
  id?: number;
  modified_ts?: number;
  name?: string;
  page?: number;
  params: ISearchParams;
}

export interface ISearchParams {
  campaign_id?: number;
  image_url?: string;
  query?: string;
  creator_skillsets?: string[];
  network_type?: TNetworkIdentifier;
  has_sponsored_posts?: boolean;
  minimum_reach?: number;
  maximum_reach?: number;
  minimum_engagement?: number;
  maximum_engagement?: number;
  minimum_impression?: number;
  maximum_impression?: number;
  minimum_engagement_ratio?: number;
  maximum_engagement_ratio?: number;
  near_locations?: TLatLng[];
  locations?: ILocation[];
  country_codes?: string[];
  language_codes?: string[];
  gender?: TGender;
  age_ranges?: string[];
  age_min?: string;
  age_max?: string;
  interests?: string[];
  audience_gender?: TGender;
  audience_gender_less_than?: boolean;
  audience_gender_percentage?: number;
  audience_ethnicity?: string;
  audience_ethnicity_less_than?: boolean;
  audience_ethnicity_percentage?: number;
  audience_age_range?: string;
  audience_age_min?: string;
  audience_age_max?: string;
  audience_age_less_than?: boolean;
  audience_age_percentage?: number;
  audience_country_region?: string;
  audience_country_region_less_than?: boolean;
  audience_country_region_percentage?: number;
  audience_locations?: ILocation[];
  audience_location_type?: TLocationType;
  invited_type?: TInvitedType;
  tags?: string[];
  max_days_since_last_post?: string;
  can_contact?: boolean;
  has_email?: boolean;
  totalCount?: number;
  version?: number;
  search_term_type?: SearchTermType;
  project_ids?: number[];
  group_ids?: number[];
  is_in_instagram_marketplace?: boolean;
}
