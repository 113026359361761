/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1u2cl_157 {
  display: flex;
}

._justify-content-space-between_1u2cl_161 {
  justify-content: space-between;
}

._tabular-nums_1u2cl_165, ._barChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div, ._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._Card_1u2cl_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_1u2cl_170 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_1u2cl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Card_1u2cl_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Card_1u2cl_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Card_1u2cl_170 ::-webkit-scrollbar-track, ._Card_1u2cl_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Card_1u2cl_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_1u2cl_165,
._audienceAgeBarChart_1u2cl_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list],
._audienceAgeBarChart_1u2cl_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_1u2cl_306 {
  cursor: help;
}

._Card_1u2cl_170 {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  break-inside: avoid-column;
  box-sizing: border-box;
}
._Card_1u2cl_170 ._cardHeader_1u2cl_320 {
  margin-top: -0.5rem;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._Card_1u2cl_170 ._cardHeader_1u2cl_320:empty {
  display: none;
  margin: unset;
}
._Card_1u2cl_170 ._tabs_1u2cl_331 {
  margin-right: -0.5rem;
  align-self: flex-end;
}
._Card_1u2cl_170 ._tabs_1u2cl_331 ._tabButton_1u2cl_335 {
  min-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  letter-spacing: 0.0625rem;
}
._Card_1u2cl_170 ._tabs_1u2cl_331 ._tabButton_1u2cl_335, ._Card_1u2cl_170 ._tabs_1u2cl_331 ._tabButton_1u2cl_335:hover {
  background-color: transparent;
}
._Card_1u2cl_170 ._tabs_1u2cl_331 ._tabButton_1u2cl_335:hover > div {
  color: #3996e0;
}
._Card_1u2cl_170 ._tabs_1u2cl_331 ._tabButton_1u2cl_335 > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aeaeae;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Card_1u2cl_170 ._tabs_1u2cl_331 ._tabButton_1u2cl_335._activeTab_1u2cl_354 > div {
  color: #3996e0;
}

._separator_1u2cl_358 {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  height: 0.0625rem;
  border-bottom: 0.0625rem solid #eff5f9;
}

._cardTab_1u2cl_365 {
  display: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._cardTitle_1u2cl_373 {
  margin: 0 auto 0 0;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

._visible_1u2cl_381 {
  display: flex !important;
  animation: _show_1u2cl_1 0.4s;
}

._noData_1u2cl_386::after {
  position: absolute;
  top: calc(50% + 0.6666666667rem);
  left: 50%;
  display: block;
  color: #8f8d91;
  content: "No data yet";
  transform: translate(-50%, -50%);
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_1u2cl_165,
._audienceAgeBarChart_1u2cl_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list],
._audienceAgeBarChart_1u2cl_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_1u2cl_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_1u2cl_306 {
  cursor: help;
}

._CountryCard_1u2cl_497 {
  position: relative;
}

._barChart_1u2cl_165 {
  margin-top: 0.5rem;
  flex: 1;
}
._barChart_1u2cl_165 > [class*=list] {
  height: 100%;
}
._barChart_1u2cl_165 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
}
._barChart_1u2cl_165 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._barChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=label] {
  width: 14rem;
  margin-right: -14rem;
  padding-right: 0.75rem;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._barChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._barChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._barChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._barChart_1u2cl_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #3996e0 !important;
  font-size: 0.625rem;
  font-weight: 600;
  text-align: right;
}