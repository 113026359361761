import * as React from 'react';
import { nth } from 'lodash';
import xss from 'xss';
import { Input, Switch } from '@revfluence/fresh';

import {
  ConnectedImageVideoUpload, RichTextEditor,
} from '@frontend/app/components';
import {
  defaultAbout,
} from '@frontend/app/components/ApplicationPageTemplates/CustomizedTemplate/constants';

import { ShowSection } from '../FormComponents';
import { useUploadProps } from '../hooks/useUploadProps';
import { TTemplateProps } from '../../types';
import { IMAGES_HINTS } from './imagesHints';

import styles from './About.scss';
import { ApplicationPageBuilderComponent, isBuilderComponentUsedByTemplate, ProjectApplicationPageTemplateName } from '../../../applicationPageUtils';

const { useCallback } = React;

type TAboutProps = TTemplateProps['about'];

interface IProps {
  projectId: number;
  aboutProps?: TAboutProps;
  onChange?: (values: TAboutProps) => void;
  template?: ProjectApplicationPageTemplateName;
  isFieldVisible: (fieldName: string) => boolean;
  onChangeFieldVisibility: (fieldName: string, isVisible: boolean) => void;
}

export const About: React.FC<IProps> = (props) => {
  const {
    projectId,
    aboutProps,
    onChange,
    template,
    isFieldVisible,
    onChangeFieldVisibility,
  } = props;

  const uploadProps = useUploadProps(projectId);

  const handleChange = useCallback((values: Partial<TAboutProps>) => {
    if (onChange) {
      onChange({ ...aboutProps, ...values });
    }
  }, [aboutProps, onChange]);

  const handleChangeShow = useCallback((show: boolean) => {
    handleChange({ show });
  }, [handleChange]);

  const handleChangeBrandImages = useCallback((index: number, url: string) => {
    const brandImages = [...(aboutProps?.brand_images || defaultAbout.brand_images)];
    brandImages[index] = url || defaultAbout.brand_images[index];
    handleChange({ brand_images: brandImages });
  }, [handleChange, aboutProps]);

  const handleChangeDescription = useCallback((value: string) => {
    handleChange({ description: value });
  }, [handleChange]);

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ title: e.target.value });
  }, [handleChange]);

  return (
    <div className={styles.About}>
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.AboutSectionToggle,
          template,
        ) && (
          <ShowSection show={!(aboutProps?.show === false)} onChange={handleChangeShow} />
        )
      }
      <div className={styles.row}>
        {
          isBuilderComponentUsedByTemplate(
            ApplicationPageBuilderComponent.AboutTitle,
            template,
          ) && (
            <div className={styles.subrow}>
              <div className={styles.subtitle}>
                About Title
                {
                  isBuilderComponentUsedByTemplate(
                    ApplicationPageBuilderComponent.AboutTitleToggle,
                    template,
                  ) && (
                    <Switch
                      size="small"
                      checked={isFieldVisible(ApplicationPageBuilderComponent.AboutTitle)}
                      onChange={(isVisible: boolean) => (
                        onChangeFieldVisibility(ApplicationPageBuilderComponent.AboutTitle, isVisible)
                      )}
                    />
                  )
                }
              </div>
              <Input
                placeholder="About Title"
                value={aboutProps?.title}
                onChange={handleChangeTitle}
              />
            </div>
          )
        }
        <div className={styles.subrow}>
          <div className={styles.subtitle}>
            About Description
            {
              isBuilderComponentUsedByTemplate(
                ApplicationPageBuilderComponent.AboutDescriptionToggle,
                template,
              ) && (
                <Switch
                  size="small"
                  checked={isFieldVisible(ApplicationPageBuilderComponent.AboutDescription)}
                  onChange={(isVisible: boolean) => (
                    onChangeFieldVisibility(ApplicationPageBuilderComponent.AboutDescription, isVisible)
                  )}
                />
              )
            }
          </div>

          <RichTextEditor
            onChange={handleChangeDescription}
            value={xss(aboutProps?.description)}
            placeholder={xss(defaultAbout.description)}
            boundSelector="#steps"
          />
        </div>
      </div>
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.AboutBrandImages,
          template,
        ) && (
          [0, 1, 2].map((idx) => (
            <div key={idx} className={styles.row}>
              <div className={styles.subtitle}>
                Brand image
                {' '}
                {idx + 1}
              </div>
              <ConnectedImageVideoUpload
                defaultImageSrc={nth(aboutProps?.brand_images, idx)}
                isDefaultImage={nth(aboutProps?.brand_images, idx) === nth(defaultAbout.brand_images, idx)}
                uploadProps={uploadProps}
                showCropper
                cropperProps={{ aspectRatio: 800 / 1040 }}
                onUpload={handleChangeBrandImages.bind(this, idx)}
                onRemove={handleChangeBrandImages.bind(this, idx, undefined)}
                hintText={IMAGES_HINTS.ABOUT_IMAGES}
              />
            </div>
          ))
        )
      }
    </div>
  );
};
