import { createStore, applyMiddleware } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from './reducer';
import { IContentPageAction } from './actions';
import { IStore } from './models';
import { contentRefresher } from './middleware';

export type CPThunkDispatch = ThunkDispatch<IStore, unknown, IContentPageAction>;

export function configureStore(initialState?: IStore) {
  const middlewares = [thunk, contentRefresher];

  /** Add Only Dev. Middlewares */
  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger();
    middlewares.push(logger);
  }

  const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

  const store = createStoreWithMiddleware<IStore>(reducer, initialState as any);

  return store;
}
