/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_umnkd_157 {
  display: flex;
}

._justify-content-space-between_umnkd_161 {
  justify-content: space-between;
}

._tabular-nums_umnkd_165 {
  font-variant-numeric: tabular-nums;
}

._ContentUploaderFileSelector_umnkd_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_umnkd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._ContentUploaderFileSelector_umnkd_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._ContentUploaderFileSelector_umnkd_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._ContentUploaderFileSelector_umnkd_169 ::-webkit-scrollbar-track, ._ContentUploaderFileSelector_umnkd_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._ContentUploaderFileSelector_umnkd_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._ContentUploaderFileSelector_umnkd_169 {
  position: relative;
  width: 125px;
  height: 125px;
  border: 1.5px dashed rgb(238, 230, 219);
  border-radius: 6px;
  background-color: rgb(250, 251, 253);
  cursor: pointer;
  will-change: border-color;
  transition: border-color 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._ContentUploaderFileSelector_umnkd_169._disabled_umnkd_215 {
  cursor: not-allowed;
  opacity: 0.6;
}
._ContentUploaderFileSelector_umnkd_169:hover {
  border-color: #3996e0;
  color: #3996e0;
}
._ContentUploaderFileSelector_umnkd_169 ._label_umnkd_223 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
._ContentUploaderFileSelector_umnkd_169 ._text_umnkd_231 {
  font-size: 10px;
  font-weight: 600;
  user-select: none;
  will-change: color;
  transition: color 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._ContentUploaderFileSelector_umnkd_169 ._text_umnkd_231 ._supportedFile_umnkd_238 {
  font-size: 9px;
  color: grey;
}

._hideInput_umnkd_243 {
  display: none !important;
}

._invalid_umnkd_247 {
  color: #d9534f;
}