import gql from 'graphql-tag';
import { USER_FRAGMENT } from './user';

export const CONTENT_REVIEW_EVENT_FRAGMENT = gql`
  fragment ContentReviewEventFragment on ContentReviewEvent {
    id
    reviewId
    type
    userId
    user {
      ...UserFragment
    }
    info
    createdDate
  }
  ${USER_FRAGMENT}
`;
