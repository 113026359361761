import * as React from 'react';
import { Alert, Typography } from '@revfluence/fresh';
import ShadCnAlert from '@frontend/app/refresh-components/Alert';
import { HomeLayout } from '../../Home';
import Header from '../Header';
import Impact from '../Impact';
import MetricsPrimary from '../MetricsPrimary';
import MetricsSecondary from '../MetricsSecondary';
import { RefreshHomeLayout } from '../../RefreshHome';
import RefreshHeader from '../Header/RefreshHeader';
import RefreshImpact from '../Impact/RefreshImpact';
import RefreshMetricsPrimary from '../MetricsPrimary/RefreshMetricsPrimary';
import RefreshMetricsSecondary from '../MetricsSecondary/RefreshMetricsSecondary';

const emptyTrend = {
  percentDiff: null,
  value: null,
};

const emptyData = {
  historicalData: [],
  percentDiff: null,
  value: null,
};

const { Link } = Typography;

export const AlertError = ({ refreshUi }) => (
  refreshUi ? (
    <ShadCnAlert
      title="Data syncing issue"
      description={(
        <span>
          Sorry, there was an issue loading some or all of this data.Try refreshing page, come back in a bit or
          {' '}
          <Link href="mailto:help@aspireiq.com"> contact support</Link>
          {' '}
          if this keeps happening.
        </span>
    )}
      closeable
    />
)
    : (
      <Alert
        message="Data syncing issue"
        description={
        (
          <span>
            Sorry, there was an issue loading some or all of this data.Try refreshing page, come back in a bit or
            {' '}
            <Link href="mailto:help@aspireiq.com"> contact support</Link>
            {' '}
            if this keeps happening.
          </span>
        )
}
        type="error"
        showIcon
        style={{ width: '100%' }}
      />
)
);

interface IHomeErrorProps {
  startDate: Date;
  endDate: Date;
  advice?: React.ReactNode,
  refreshUi?: boolean;
  clientName?: string;
}

export const HomeError = ({
  startDate,
  endDate,
  advice,
  refreshUi,
  clientName,
}: IHomeErrorProps) => {
  const impactData = {
    totalValue: null,
    investment: null,
    impact: null,
    percentDiff: null,
  };
  const trends = {
    reach: emptyTrend,
    views: emptyTrend,
    conversions: emptyTrend,
    creators: emptyTrend,
    cpm: emptyTrend,
    cpe: emptyTrend,
    tmv: emptyTrend,
  };
  return (
    refreshUi ? (
      <RefreshHomeLayout
        Header={<RefreshHeader startDate={startDate} endDate={endDate} clientName={clientName} />}
        Alert={<AlertError refreshUi={refreshUi} />}
        ImpactComponent={<RefreshImpact impactData={impactData} isError />}
        MetricsPrimary={(
          <RefreshMetricsPrimary
            contentData={emptyData}
            impressionsData={emptyData}
            engagementsData={emptyData}
            salesData={emptyData}
          />
      )}
        MetricsSecondary={(
          <RefreshMetricsSecondary trends={trends} />
      )}
      />
) : (
  <HomeLayout
    Header={<Header startDate={startDate} endDate={endDate} />}
    Advice={advice}
    Alert={<AlertError refreshUi={refreshUi} />}
    ImpactComponent={<Impact impactData={impactData} />}
    MetricsPrimary={(
      <MetricsPrimary
        contentData={emptyData}
        impressionsData={emptyData}
        engagementsData={emptyData}
        salesData={emptyData}
      />
        )}
    MetricsSecondary={(
      <MetricsSecondary trends={trends} />
        )}
  />
    )
  );
};
