import { IBrand } from './brand';
import { IManager } from './manager';
import { IAdvertiserAccountAccessRequirement } from './advertiserAccountAccessRequirement';

export interface IClientAdvertiserAccess {
  id: number;
  date_created: string;
  brand: IBrand;
  user: IManager;
  requirements: IAdvertiserAccountAccessRequirement[];
}
