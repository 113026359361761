import * as React from 'react';
import { isUndefined } from 'lodash';

import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from './useClientFeatureEnabled';

const { useMemo } = React;

export interface DemoAccountFlagValue {
  shouldShowDemoFeatures: boolean
}

export const useDemoAccountFlag = (): DemoAccountFlagValue => {
  const isEnabled = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);

  return useMemo(() => ({
    /** Pessimistic approach, demo account should take precedence */
    shouldShowDemoFeatures: isUndefined(isEnabled) || isEnabled,
  }), [isEnabled]);
};
