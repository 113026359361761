import { useQuery, QueryHookOptions } from '@apollo/client';

import { MEMBER_FIELD_SCHEMAS_BY_IDS_QUERY } from '@frontend/app/queries';
import {
  MemberFieldSchemasByIdsQuery,
  MemberFieldSchemasByIdsQueryVariables,
} from '@frontend/app/queries/types/MemberFieldSchemasByIdsQuery';

type IOptions = QueryHookOptions<MemberFieldSchemasByIdsQuery, MemberFieldSchemasByIdsQueryVariables>;

export const useMemberFieldSchemasByIdsQuery = (schemaIds: number[], options: IOptions = {}) => useQuery<MemberFieldSchemasByIdsQuery, MemberFieldSchemasByIdsQueryVariables>(MEMBER_FIELD_SCHEMAS_BY_IDS_QUERY, {
    ...options,
    variables: {
      schemaIds,
    },
    skip: !schemaIds || options.skip,
  });
