import 'css-chunk:src/app/components/MembersTableOld/MembersTable.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1wel0_157",
  "justify-content-space-between": "_justify-content-space-between_1wel0_161",
  "tabular-nums": "_tabular-nums_1wel0_165",
  "MembersTable": "_MembersTable_1wel0_355",
  "tagsCell": "_tagsCell_1wel0_367",
  "addTagIcon": "_addTagIcon_1wel0_370",
  "clickableTag": "_clickableTag_1wel0_376",
  "membersTableContainer": "_membersTableContainer_1wel0_379",
  "loadSpinner": "_loadSpinner_1wel0_390",
  "table": "_table_1wel0_393",
  "tableHeader": "_tableHeader_1wel0_400",
  "checkboxHeaderCell": "_checkboxHeaderCell_1wel0_404",
  "checkboxCell": "_checkboxCell_1wel0_408",
  "tag": "_tag_1wel0_367",
  "defaultTag": "_defaultTag_1wel0_433",
  "fixedCell": "_fixedCell_1wel0_437",
  "leftAlignedButton": "_leftAlignedButton_1wel0_441",
  "loadingOverlaySpinner": "_loadingOverlaySpinner_1wel0_445",
  "show": "_show_1wel0_1"
};