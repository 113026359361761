/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gvveh_157 {
  display: flex;
}

._justify-content-space-between_gvveh_161 {
  justify-content: space-between;
}

._tabular-nums_gvveh_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gvveh_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._RequirementDetail_gvveh_178 ._header_gvveh_178 ._title_gvveh_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._RequirementDetail_gvveh_178 ._subtitle_gvveh_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._RequirementDetail_gvveh_178 ._badge_gvveh_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._RequirementDetail_gvveh_178 ._header_gvveh_178 {
  margin-bottom: 0.9375rem;
  text-align: center;
}
._RequirementDetail_gvveh_178 ._header_gvveh_178 ._title_gvveh_178 {
  margin-bottom: 0.9375rem;
}
._RequirementDetail_gvveh_178 ._badge_gvveh_198 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 18.75rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #e9e8ea;
  color: #8f8d91;
}
._RequirementDetail_gvveh_178 ._badge_gvveh_198 ._icon_gvveh_226 {
  margin-right: 0.625rem;
}
._RequirementDetail_gvveh_178 ._badge_gvveh_198._completed_gvveh_229 ._icon_gvveh_226 {
  color: #3996e0;
}
._RequirementDetail_gvveh_178 ._badge_gvveh_198._completed_gvveh_229 ._dueDate_gvveh_232 {
  text-decoration: line-through;
}
._RequirementDetail_gvveh_178 ._badge_gvveh_198._pastDue_gvveh_235 {
  background-color: #d9534f;
  color: #fdfdfd;
}
._RequirementDetail_gvveh_178 ._subtitle_gvveh_188 {
  margin-bottom: 0.9375rem;
}
._RequirementDetail_gvveh_178 ._deliverables_gvveh_242 {
  padding: 0.9375rem;
  border-radius: 0.5rem;
  background-color: #eff5f9;
}
._RequirementDetail_gvveh_178 ._actions_gvveh_247 {
  margin-top: 1.875rem;
  text-align: center;
}
._RequirementDetail_gvveh_178 ._actions_gvveh_247 > *:not(:last-child) {
  margin-right: 0.9375rem;
}