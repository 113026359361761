import * as React from 'react';
import cx from 'classnames';
import {
  map, keys, uniq, isEqual,
} from 'lodash';

import { Image, LazyImage } from '@components';
import { Tooltip } from '@components';
import { failedImage } from '@components';
import { Cell, ICellProps } from './Cell';

import styles from './MediaCell.scss';

interface IProps extends ICellProps {
  value: string[];
}

interface IState {
  showTooltip: boolean;
  selectedImageUrl: string;
  imageRefs: {
    [name: string]: React.RefObject<HTMLImageElement>;
  };
}

export class MediaCell extends React.PureComponent<IProps, IState> {
  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    // set image refs
    const imageRefs = {};
    map(uniq(props.value), (url) => {
      imageRefs[url] = React.createRef();
    });

    this.state = {
      showTooltip: false,
      selectedImageUrl: null,
      imageRefs,
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    const { value } = nextProps;
    const { imageRefs } = prevState;

    // update refs when content urls changed
    if (!isEqual(uniq(value), keys(imageRefs))) {
      // update refs
      const imageRefs = {};
      map(uniq(value), (url) => {
        imageRefs[url] = React.createRef();
      });

      return {
        imageRefs,
        selectedImageUrl: null,
      };
    }

    return null;
  }

  /**
   * @inheritDoc
   */
  public render() {
    const { value: contents, className, ...restProps } = this.props;
    const { showTooltip, selectedImageUrl, imageRefs } = this.state;

    const content = (
      <div className={styles.mediaContent}>
        {uniq(contents || [])
          .filter((url) => !failedImage.contains(url))
          .slice(0, 3)
          .map((url) => (
            <LazyImage
              key={url}
              src={url}
              ref={imageRefs[url]}
              onError={this.handleImageError.bind(this, url)}
              onMouseEnter={this.showTooltip.bind(this, url)}
              onMouseLeave={this.hideTooltip}
              className={styles.image}
            />
          ))}
        <Tooltip
          placement="bottom"
          mountRef={imageRefs[selectedImageUrl]}
          autoRegisterListener={false}
          show={showTooltip}
          className={styles.Tooltip}
        >
          <Image className={styles.image} src={selectedImageUrl} />
        </Tooltip>
      </div>
    );

    return <Cell className={cx(styles.MediaCell, className)} value={content} {...restProps} />;
  }

  /**
   * @private
   * Handler for when image load failed.
   *
   * @param {String} url the image url.
   */
  private handleImageError = (url: string) => {
    failedImage.add(url);

    this.forceUpdate();
  };

  /**
   * @private
   * Sets the selected image url, and shows the tooltip.
   *
   * @param {String} selectedImageUrl the selected image url.
   */
  private showTooltip = (selectedImageUrl) => {
    this.setState({
      selectedImageUrl,
      showTooltip: true,
    });
  };

  /**
   * @private
   * Hides the tooltip.
   */
  private hideTooltip = () => {
    this.setState({
      showTooltip: false,
    });
  };
}
