import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { InputNumber } from '@revfluence/fresh';
import { TimerIcon } from '@revfluence/fresh-icons/regular/esm';
import FormHeader from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormHeader';
import FormFooter from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormFooter';
import { TDelayNode, TSelectedNode } from '@frontend/app/containers/Projects/AutomationConfig/types';
import styles from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/ConfigDrawer.scss';
import { AutomationNodeType } from '@frontend/app/types/globalTypes';

type TProps = {
  onClose: () => void;
  onUpdateDelayNode: (targetNodeId: string, delayNode: TDelayNode) => void;
  selectedNode: TSelectedNode,
};

const DelayForm = (props: TProps) => {
  const { onUpdateDelayNode, onClose, selectedNode } = props;
  const [durationDays, setDurationDays] = useState<number>();
  useEffect(() => {
    if (selectedNode && selectedNode.nodeType === AutomationNodeType.DELAY) {
      setDurationDays(moment.duration(selectedNode.delayNode.durationMs).asDays());
    }
  }, [selectedNode, setDurationDays]);

  const onDurationChange = (value) => {
    setDurationDays(value);
  };

  const onSaveDuration = () => {
    if (selectedNode && selectedNode.nodeType === AutomationNodeType.DELAY && durationDays !== null) {
      onUpdateDelayNode(selectedNode.targetNodeId, {
        durationMs: moment.duration(durationDays, 'days').asMilliseconds(),
      });
    }
  };

  return (
    <div className={styles.ConfigDrawer}>
      <div>
        <FormHeader
          title="Edit Delay"
          description="Select how long to wait before completing the next step of this automation."
          onClose={onClose}
          icon={<TimerIcon fontSize={20} />}
        />
        <div className={styles.configBody}>
          <div className={styles.delayConfigContainer}>
            <InputNumber
              min={0}
              max={99}
              onChange={onDurationChange}
              value={durationDays}
            />
            days
          </div>
        </div>
      </div>
      <FormFooter
        onSave={onSaveDuration}
        onCancel={onClose}
      />
    </div>
  );
};

export default DelayForm;
