import 'css-chunk:src/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/MarketplaceTabs/NetworkRestriction/styles.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1cvg8_157",
  "justify-content-space-between": "_justify-content-space-between_1cvg8_161",
  "tabular-nums": "_tabular-nums_1cvg8_165",
  "networkRestriction": "_networkRestriction_1cvg8_178",
  "header": "_header_1cvg8_186",
  "networkIcon": "_networkIcon_1cvg8_193",
  "networkName": "_networkName_1cvg8_201",
  "content": "_content_1cvg8_204",
  "maxBudget": "_maxBudget_1cvg8_208",
  "maxBudgetLegend": "_maxBudgetLegend_1cvg8_212",
  "maxBudgetSelect": "_maxBudgetSelect_1cvg8_216",
  "sliders": "_sliders_1cvg8_223",
  "slider": "_slider_1cvg8_223",
  "sliderTitle": "_sliderTitle_1cvg8_231",
  "sliderInput": "_sliderInput_1cvg8_234",
  "sliderFooter": "_sliderFooter_1cvg8_246",
  "show": "_show_1cvg8_1"
};