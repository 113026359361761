import * as React from 'react';
import {
  Card,
  Col,
  Row,
  Statistic,
  Typography,
} from '@revfluence/fresh';

import { formatValue } from '@affiliates/utils';
import { includes } from 'lodash';
import { IStatisticsCardData } from './types';

import styles from './StatisticsCard.scss';

interface IProps {
  data?: IStatisticsCardData[];
  className?: string;
  timeText?: string;
  showCurrency?: boolean
}

const { Title, Text } = Typography;

export const OfferStatisticCardComponent: React.FC<Readonly<IProps>> = React.memo((props) => {
  const multicurrencyFields = ['Sales', 'Avg. Sale', 'Commissions Earned '];
  return (
    <Card>
      <Row
        justify="space-around"
        align="top"
        className={styles.statsRow}
      >
        {props.showCurrency ? props.data.map((item: IStatisticsCardData) => (
          <Col
            key={item.title}
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={4}
            className={styles.StatsColumn}
          >
            {!includes(multicurrencyFields, item.title) ? (
              <Statistic
                prefix={item.prefix}
                suffix={item.suffix}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                reverse
                value={formatValue(item.format, item.value as number)}
                title={item.title}
              />
            ) : (
              <Statistic
                valueStyle={{ color: '#cf1322' }}
                suffix={item.suffix}
                className={styles.statisticContainer}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                reverse
                valueRender={() => (
                  <>
                    <Row className={styles.currencyData} gutter={8}>
                      <Col span={24}>
                        {' '}
                        <Title level={4}>
                          $
                          {item.value.valueInUsd}
                        </Title>
                      </Col>
                      <Col span={24}>
                        {' '}
                        <Text>
                          {item.value.currency}
                          {' '}

                          {' '}
                          {item.value.valueInBase}
                        </Text>
                      </Col>
                    </Row>
                  </>
                  )}
                title={item.title}
              />
            )}
          </Col>
        )) : props.data.map((item) => (
          <Col
            key={item.title}
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={4}
            className={styles.StatsColumn}
          >
            <Statistic
              prefix={item.prefix}
              suffix={item.suffix}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              reverse
              value={formatValue(item.format, item.value as number)}
              title={item.title}
            />
          </Col>
        ))}
      </Row>
    </Card>
  );
});

OfferStatisticCardComponent.displayName = 'OfferStatisticCardComponent';
