// @ts-ignore: TS6133
import * as React from 'react';
import { isArray } from 'lodash';

import { ITableColumnConfig, 
  //EstImpressionsLabel 
  } from '@components';

export enum GroupBySetting {
  post = 'post',
  creator = 'creator',
  network = 'network',
  aspirexPost = 'aspirex_post',
  aspirexNetwork = 'aspirex_network',
  member = 'aspirex_member',
}

export const socialReportsAllColumns: Array<ITableColumnConfig & {
  valid_for_grouping_by?: GroupBySetting[];
  isCostMetric?: boolean;
}> = [
  {
    headerName: 'Creator',
    cellType: 'text',
    field: 'creator',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.creator],
    searchable: true,
  },
  {
    headerName: 'Social Account',
    cellType: 'link',
    field: 'social_account_name',
    hrefField: 'member_link',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.member,
      GroupBySetting.aspirexPost,
    ],
    searchable: true,
    searchValue: (value, data) => `${value} ${data._raw.member_id}`,
  },
  // TODO: Update this with all columns
  {
    headerName: 'Social Account Link',
    cellType: 'link',
    field: 'social_account_link',
    hrefField: 'social_account_link',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.member,
      GroupBySetting.aspirexPost,
    ],
  },
  {
    headerName: 'Creator Country',
    cellType: 'text',
    field: 'country_code',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
  },
  {
    headerName: 'Brand',
    cellType: 'text',
    field: 'brand_name',
    valid_for_grouping_by: [GroupBySetting.post],
    searchable: true,
  },
  {
    headerName: 'Campaign',
    cellType: 'text',
    field: 'campaign_name',
    valid_for_grouping_by: [GroupBySetting.post],
    searchable: true,
  },
  {
    headerName: 'Network',
    cellType: 'text',
    field: 'network',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
    searchable: true,
  },
  {
    headerName: 'Network',
    cellType: 'text',
    field: 'post_type',
    valid_for_grouping_by: [
      GroupBySetting.network,
      GroupBySetting.aspirexNetwork,
    ],
    searchable: true,
  },
  {
    headerName: 'Networks',
    cellType: 'text',
    field: 'networks',
    valid_for_grouping_by: [GroupBySetting.creator, GroupBySetting.member],
  },
  {
    headerName: 'Post Type',
    cellType: 'text',
    field: 'post_type',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
    searchable: true,
  },
  {
    headerName: 'Post Link',
    cellType: 'link',
    field: 'post_link',
    hrefField: 'post_link',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
  },
  {
    headerName: 'Post Image',
    cellType: 'media',
    field: 'media',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
  },
  {
    headerName: 'Creators',
    cellType: 'numeric',
    field: 'creator_count',
    valid_for_grouping_by: [
      GroupBySetting.network,
      GroupBySetting.aspirexNetwork,
    ],
  },
  {
    headerName: 'Estimated Closeups',
    cellType: 'numeric',
    field: 'estimated_closeups',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Comments',
    cellType: 'numeric',
    field: 'authed_comments',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Comments',
    cellType: 'numeric',
    field: 'estimated_comments',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Completion Rate',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'authed_completion_rate',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Dislikes',
    cellType: 'numeric',
    field: 'estimated_dislikes',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Engagements',
    cellType: 'numeric',
    field: 'authed_engagements',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Engagements',
    cellType: 'numeric',
    field: 'estimated_engagements',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Engagement Rate',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'authed_engagement_rate',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Engagement Rate',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'estimated_engagement_rate',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Impressions',
    cellType: 'numeric',
    field: 'authed_impressions',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Impressions',
    cellType: 'numeric',
    field: 'estimated_impressions',
    justify: 'flex-start',
  },
  {
    headerName: 'IG Story Estimated Impressions',
    cellType: 'numeric',
    field: 'ig_story_estimated_impressions',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Likes',
    cellType: 'numeric',
    field: 'authed_likes',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Likes',
    cellType: 'numeric',
    field: 'estimated_likes',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Reach',
    cellType: 'numeric',
    field: 'authed_reach',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Reach',
    cellType: 'numeric',
    field: 'estimated_reach',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Replays',
    cellType: 'numeric',
    field: 'authed_replays',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Replies',
    cellType: 'numeric',
    field: 'authed_replies',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Saves',
    cellType: 'numeric',
    field: 'authed_saves',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Saves',
    cellType: 'numeric',
    field: 'estimated_saves',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Shares',
    cellType: 'numeric',
    field: 'authed_shares',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Shares',
    cellType: 'numeric',
    field: 'estimated_shares',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Six Second Views',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'authed_six_second_views',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Started Following',
    cellType: 'numeric',
    field: 'authed_started_following',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Swipe Forwards',
    cellType: 'numeric',
    field: 'authed_swipe_forwards',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Tap Backs',
    cellType: 'numeric',
    field: 'authed_tap_backs',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Tap Exits',
    cellType: 'numeric',
    field: 'authed_tap_exits',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Exits',
    cellType: 'numeric',
    field: 'estimated_exits',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Tap Forwards',
    cellType: 'numeric',
    field: 'authed_tap_forwards',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Taps',
    cellType: 'numeric',
    field: 'authed_taps',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Taps',
    cellType: 'numeric',
    field: 'estimated_taps',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Two Second Views',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'authed_two_second_views',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Unique Views',
    cellType: 'numeric',
    field: 'authed_unique_views',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Views',
    cellType: 'numeric',
    field: 'authed_views',
    justify: 'flex-start',
  },
  {
    headerName: 'Estimated Views',
    cellType: 'numeric',
    field: 'estimated_views',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Organic Views',
    cellType: 'numeric',
    field: 'authed_organic_views',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Paid Views',
    cellType: 'numeric',
    field: 'authed_paid_views',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed View Time Avg Seconds',
    cellType: 'numeric',
    field: 'authed_view_time_avg_seconds',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed View Time Total Seconds',
    cellType: 'numeric',
    field: 'authed_view_time_total_seconds',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Mention Clicks',
    cellType: 'numeric',
    field: 'authed_mention_clicks',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Cost',
    cellType: 'numeric',
    field: 'authed_cost',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Product Cost',
    cellType: 'numeric',
    field: 'authed_product_cost',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Sales',
    cellType: 'numeric',
    field: 'authed_sales',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed Sales Amount',
    cellType: 'numeric',
    field: 'authed_sales_amount',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed TMV',
    cellType: 'numeric',
    field: 'authed_tmv',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed CPM Cost',
    cellType: 'numeric',
    field: 'authed_cpm_cost',
    justify: 'flex-start',
  },
  {
    headerName: 'Authed CPM Product Cost',
    cellType: 'numeric',
    field: 'authed_cpm_product_cost',
    justify: 'flex-start',
  },
  {
    headerName: 'TMV ROI %',
    cellType: 'numeric',
    formatStr: '0.0%',
    field: 'roi_pct',
    valid_for_grouping_by: [
      GroupBySetting.aspirexPost,
      GroupBySetting.member,
      GroupBySetting.aspirexNetwork,
    ],
    justify: 'flex-start',
    isCostMetric: true,
  },
  {
    headerName: 'CPE $',
    cellType: 'numeric',
    field: 'cost_per_engagement',
    isPrice: true,
    valid_for_grouping_by: [
      GroupBySetting.aspirexPost,
      GroupBySetting.member,
      GroupBySetting.aspirexNetwork,
    ],
    justify: 'flex-start',
    isCostMetric: true,
  },
  {
    headerName: 'Impression CPM $',
    cellType: 'numeric',
    field: 'impression_cpm',
    isPrice: true,
    valid_for_grouping_by: [
      GroupBySetting.aspirexPost,
      GroupBySetting.member,
      GroupBySetting.aspirexNetwork,
    ],
    justify: 'flex-start',
    isCostMetric: true,
  },
  {
    headerName: 'Post Count',
    cellType: 'numeric',
    field: 'post_count',
    valid_for_grouping_by: [
      GroupBySetting.creator,
      GroupBySetting.network,
      GroupBySetting.aspirexNetwork,
      GroupBySetting.member,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Coupon Codes',
    cellType: 'text',
    field: 'coupons',
    valid_for_grouping_by: [GroupBySetting.post],
    justify: 'flex-start',
  },
  {
    headerName: 'Sales Count',
    cellType: 'numeric',
    field: 'sales',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Sales $',
    cellType: 'numeric',
    formatStr: '0.00',
    field: 'sales_amount',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Payment',
    cellType: 'numeric',
    formatStr: '0.00',
    field: 'cost',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Product Cost',
    cellType: 'numeric',
    formatStr: '0.00',
    field: 'product_cost',
    valid_for_grouping_by: [
      GroupBySetting.post,
      GroupBySetting.creator,
      GroupBySetting.network,
    ],
    justify: 'flex-start',
  },
  {
    headerName: 'Date Posted',
    cellType: 'date',
    dateFormatStr: 'M/d/yyyy, h:mm a',
    field: 'datetime_posted',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
    justify: 'flex-start',
  },
  {
    headerName: 'Date Updated',
    cellType: 'date',
    dateFormatStr: 'M/d/yyyy, h:mm a',
    field: 'datetime_modified',
    valid_for_grouping_by: [GroupBySetting.post, GroupBySetting.aspirexPost],
    justify: 'flex-start',
  },
  {
    headerName: 'Last Posted',
    cellType: 'text',
    field: 'last_posted',
    valid_for_grouping_by: [GroupBySetting.creator, GroupBySetting.member],
    justify: 'flex-start',
  },
  {
    headerName: 'Owners',
    cellType: 'text',
    field: 'owners',
    valid_for_grouping_by: [GroupBySetting.creator, GroupBySetting.post],
    justify: 'flex-start',
    searchable: true,
    searchValue: (value) => {
      if (!value) {
        return '';
      }
      if (isArray(value)) {
        return value.join(',');
      }
      return value;
    },
  },
  {
    headerName: 'Caption',
    cellType: 'text',
    field: 'text',
    justify: 'flex-start',
    searchable: true,
  },
];
