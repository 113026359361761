import { TNetworkIdentifier } from './networkIdentifier';

export interface IAdvertiserAccountAccessRequirement {
  id: number;
  publisher_id: number;
  publisher: string;
  account_type: TNetworkIdentifier;
  account_name: string;
  access_start_ts: number;
  access_end_ts: number;
  selected_for_use: boolean;
  ready_for_use?: boolean;
}
