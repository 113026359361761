/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_h4520_157 {
  display: flex;
}

._justify-content-space-between_h4520_161 {
  justify-content: space-between;
}

._tabular-nums_h4520_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_h4520_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ImageSearch_h4520_178 ._divider_h4520_178 > * {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ImageSearch_h4520_178 ._divider_h4520_178 {
  margin-top: 20px;
  margin-bottom: 11px;
  border-top: 1px solid #ececec;
  text-align: center;
}
._ImageSearch_h4520_178 ._divider_h4520_178 > * {
  margin: -12px auto 0;
  display: block;
  background-color: #fdfdfd;
  width: 35px;
}

._popoverContent_h4520_201 {
  padding: 0 !important;
}