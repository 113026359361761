import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import { FIND_ALL_CONTENT_FIELDS_QUERY } from '@frontend/app/queries';
import {
  FindAllContentFields,
  FindAllContentFieldsVariables,
} from '@frontend/app/queries/types/FindAllContentFields';

const { useEffect } = React;
type IOptions = QueryHookOptions<FindAllContentFields, FindAllContentFieldsVariables>;

export function useFindAllContentFields(options: IOptions = {}) {
  const {
    loading, data: { findAllContentFields = [] } = {}, error, refetch,
  } = useQuery<FindAllContentFields, FindAllContentFieldsVariables>(
      FIND_ALL_CONTENT_FIELDS_QUERY,
      options,
    );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    contentFields: findAllContentFields,
    error,
    refetch,
  };
}
