import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

import { getStyleDimensionsFromSize, IIconProps } from './Icon';

import styles from './SvgIcon.scss';
import colors from '../../styles/_colors.scss';

export interface ISvgIconProps extends IIconProps {
  fill?: string;
  onClick?(event: React.MouseEvent<SVGElement>);
  onMouseDown?(event: React.MouseEvent<SVGElement>);
  onMouseEnter?(event: React.MouseEvent<SVGElement>);
  onMouseLeave?(event: React.MouseEvent<SVGElement>);
  viewBox?: string;
  disabled?: boolean;
}

export type TSvgIcon = React.FunctionComponent<ISvgIconProps>;

export const SvgIcon: TSvgIcon = React.memo((props) => {
  const {
    badgeColor,
    badgeText,
    children,
    className,
    fill,
    onMouseDown,
    size,
    viewBox,
    disabled,
  } = props;

  const hasBadge = badgeText !== null && badgeText !== undefined && badgeText !== '';

  /**
   * Handler for 'mouseenter' event.
   */
  const handleMouseEnter = (event: React.MouseEvent<SVGElement>) => {
    const { onMouseEnter } = props;
    if (isFunction(onMouseEnter)) {
      onMouseEnter(event);
    }
  };

  /**
   * Handler for 'mouseleave' event.
   */
  const handleMouseLeave = (event: React.MouseEvent<SVGElement>) => {
    const { onMouseLeave } = props;
    if (isFunction(onMouseLeave)) {
      onMouseLeave(event);
    }
  };

  return (
    <svg
      className={cx(className, styles.SvgIcon, {
        [styles.hasBadge]: badgeText && !!badgeText.length,
        [styles.disabled]: disabled,
      })}
      fill={fill}
      onClick={props.onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={isFunction(onMouseDown) ? onMouseDown : null}
      style={getStyleDimensionsFromSize(size)}
      viewBox={viewBox}
      width="36"
      height="36"
    >
      {children}
      {/* TODO: Antonn: Helper function for generic Icon component */}
      {hasBadge && (
        <foreignObject className={styles.badgeContainer}>
          <div
            className={styles.badge}
            style={{
              backgroundColor: badgeColor || colors.darkBlue,
            }}
          >
            {badgeText}
          </div>
        </foreignObject>
      )}
    </svg>
  );
});

SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  size: 24,
  onMouseDown: () => undefined,
  onMouseEnter: () => undefined,
  onMouseLeave: () => undefined,
};
SvgIcon.displayName = 'SvgIcon';
