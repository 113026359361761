import * as React from 'react';

import { AngleLeftIcon, AngleRightIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button, Col, Row } from '@revfluence/fresh';

interface IProps {
  nextButtonClassName: string;
  nextStepText: string;
  nextStepDisabled?: boolean;
  nextStepLoading?: boolean;
  showNextStepIcon?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  previousButtonClassName: string;
  previousStepDisabled?: boolean;
  previousText: string;
  showPreviousIcon?: boolean;
  oneStep: boolean;
  actionButtonClassName?: string;
  actionButtonText?: string;
  actionButtonDisabled?: boolean;
  actionButtonLoading?: boolean;
  onActionButton?: () => void;
}

export const StepperButtons: React.FC<Readonly<IProps>> = (props) => (
  <Row justify="center" align="middle" gutter={20}>
    {props.previousText
      && (
        <Col>
          <Button
            className={props.previousButtonClassName}
            disabled={props.previousStepDisabled}
            onClick={props.onPrevious}
            size="large"
          >
            {props.showPreviousIcon && !props.oneStep && <AngleLeftIcon />}
            {props.previousText}
          </Button>
        </Col>
      )}
    {
      props.actionButtonText && (
        <Col>
          <Button
            className={props.actionButtonClassName}
            disabled={props.actionButtonDisabled}
            loading={props.actionButtonLoading}
            onClick={props.onActionButton}
            size="large"
          >
            {props.actionButtonText}
          </Button>
        </Col>
      )
    }
    {props.nextStepText
      && (
        <Col>
          <Button
            className={props.nextButtonClassName}
            type="primary"
            disabled={props.nextStepDisabled}
            loading={props.nextStepLoading}
            onClick={props.onNext}
            size="large"
          >
            {props.nextStepText}
            {props.showNextStepIcon && !props.oneStep && <AngleRightIcon />}
          </Button>
        </Col>
      )}
  </Row>
);

StepperButtons.defaultProps = {
  nextStepDisabled: false,
  nextStepLoading: false,
  previousStepDisabled: false,
  showNextStepIcon: true,
  showPreviousIcon: true,
};
