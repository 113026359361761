import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const AddCircleIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 28">
    <path d="M9.17147 0.59375C4.57077 0.59375 0.838135 4.32639 0.838135 8.92708C0.838135 13.5278 4.57077 17.2604 9.17147 17.2604C13.7722 17.2604 17.5048 13.5278 17.5048 8.92708C17.5048 4.32639 13.7722 0.59375 9.17147 0.59375ZM13.3451 9.45833H9.69925V13.1042C9.69925 13.3924 9.46661 13.625 9.17841 13.625C8.89022 13.625 8.65758 13.3924 8.65758 13.1042V9.45833H5.01175C4.72355 9.45833 4.49091 9.22569 4.49091 8.9375C4.49091 8.64931 4.72355 8.41667 5.01175 8.41667H8.65758V4.77083C8.65758 4.48264 8.89022 4.25 9.17841 4.25C9.46661 4.25 9.69925 4.48264 9.69925 4.77083V8.41667H13.3451C13.6333 8.41667 13.8659 8.64931 13.8659 8.9375C13.8659 9.22569 13.6333 9.45833 13.3451 9.45833Z" />
  </SvgIcon>
));

AddCircleIcon.displayName = 'AddCircleIcon';
