/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_50nkq_157 {
  display: flex;
}

._justify-content-space-between_50nkq_161 {
  justify-content: space-between;
}

._tabular-nums_50nkq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_50nkq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._error_50nkq_178, ._empty_50nkq_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._spinner_50nkq_188 {
  margin-top: 20px;
}

._error_50nkq_178, ._empty_50nkq_178 {
  margin-top: 20px;
  display: block;
  text-align: center;
}

._ImageTile_50nkq_198 {
  position: relative;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
._ImageTile_50nkq_198 img {
  max-width: 100%;
  border-radius: inherit;
}
._ImageTile_50nkq_198 ._removeImage_50nkq_208 {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 5px;
  height: 34px;
  display: none;
  color: #fdfdfd;
  background: rgba(0, 0, 0, 0.2);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
._ImageTile_50nkq_198 ._removeImage_50nkq_208 ._close_50nkq_221 {
  position: absolute;
  right: 8px;
  top: 8px;
}
._ImageTile_50nkq_198:hover ._removeImage_50nkq_208 {
  display: block;
}