import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  BULK_REVIEW_ORDER_REQUEST_MUTATION,
} from '../queries';
import {
    BulkReviewOrderRequest,
    BulkReviewOrderRequestVariables,
} from '../queries/types/BulkReviewOrderRequest';

type IOptions = MutationHookOptions<BulkReviewOrderRequest, BulkReviewOrderRequestVariables>;

export function useBulkReviewOrderRequests(options: IOptions = {}) {
  const [bulkReviewOrderRequest, { loading, error }] = useMutation<
  BulkReviewOrderRequest,
  BulkReviewOrderRequestVariables
  >(BULK_REVIEW_ORDER_REQUEST_MUTATION, options);

  return {
    bulkReviewOrderRequest,
    loading,
    error,
  };
}
