import gql from 'graphql-tag';

import {
  ROLE_FRAGMENT,
} from './fragments';

export const GET_ROLES_QUERY = gql`
  query GetRolesQuery {
    roles: getManagerRoles {
      ...RoleFragment
    }
  }
  ${ROLE_FRAGMENT}
`;

export const UPDATE_MANAGER_ROLE_MUTATION = gql`
  mutation UpdateManagerRoleMutation($id: String!, $role: String!) {
    succeeded: updateManagerRole(id: $id, role: $role)
  }
`;
