import 'css-chunk:src/components/widgets/DatePicker/DatePicker.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_t8vt6_157",
  "justify-content-space-between": "_justify-content-space-between_t8vt6_161",
  "tabular-nums": "_tabular-nums_t8vt6_165",
  "DatePicker": "_DatePicker_t8vt6_169",
  "dateInput": "_dateInput_t8vt6_235",
  "dateSelected": "_dateSelected_t8vt6_253",
  "icon": "_icon_t8vt6_264",
  "formattedDate": "_formattedDate_t8vt6_267",
  "hasError": "_hasError_t8vt6_270",
  "close": "_close_t8vt6_274",
  "errorMessage": "_errorMessage_t8vt6_277",
  "Popover": "_Popover_t8vt6_283",
  "content": "_content_t8vt6_283",
  "yearAndMonth": "_yearAndMonth_t8vt6_287",
  "action": "_action_t8vt6_294",
  "prevYear": "_prevYear_t8vt6_309",
  "prevMonth": "_prevMonth_t8vt6_315",
  "label": "_label_t8vt6_318",
  "nextMonth": "_nextMonth_t8vt6_326",
  "nextYear": "_nextYear_t8vt6_329",
  "weekdaysLabel": "_weekdaysLabel_t8vt6_332",
  "labelColumn": "_labelColumn_t8vt6_340",
  "calendarDates": "_calendarDates_t8vt6_349",
  "calendarDate": "_calendarDate_t8vt6_349",
  "disabled": "_disabled_t8vt6_372",
  "active": "_active_t8vt6_380",
  "show": "_show_t8vt6_1"
};