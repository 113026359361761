import * as React from 'react';
import {
 filter, keys, map, mapValues,
} from 'lodash';

import {
 Checkbox, Popover,
} from '@components';

import cx from 'classnames';
import styles from './Filters.scss';

const { useRef, useState } = React;

export const sourceReducer = (state, action) => {
  const localState = state.sources;
  switch (action.type) {
    case 'CLEAR_ALL_SOURCES': {
      const deselected = mapValues(localState.sources, () => false);
      return { sources: deselected, selected: 0 };
    }
    case 'TOGGLE_SOURCE': {
      return {
        ...localState,
        sources: {
          ...localState.sources,
          [action.payload]: !localState.sources[action.payload],
        },
        selected: localState.sources[action.payload]
          ? localState.selected - 1
          : localState.selected + 1,
      };
    }
    default: {
      return localState;
    }
  }
};

type TSourceKey =
  | 'brand'
  | 'creator'
  | 'social_listening';

export const initialSourceState: {
  [key in TSourceKey]: boolean
} = {
  brand: false,
  creator: false,
  social_listening: false,
};

const SOURCE_CONFIG: {
  [key in TSourceKey]: {
    id: string;
    label: string;
  };
} = {
  brand: {
    id: 'brand',
    label: 'Brand',
  },
  creator: {
    id: 'creator',
    label: 'Creator',
  },
  social_listening: {
    id: 'social_listening',
    label: 'Listening',
  },
};

export const SourceFilter = ({ filters, setFilters, isDisabled }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const { sources } = filters.sources;

  const toggleValue = (value) => {
    setFilters({ type: 'TOGGLE_SOURCE', payload: value });
  };
  const justSelected = filter(sources, (s) => s === true);
  const clearAll = () => {
    setFilters({ type: 'CLEAR_ALL_SOURCES' });
  };

  const renderSources = (sourceId: string, label: string) => (
    <Checkbox
      className={styles.MenuItem}
      checked={sources[sourceId]}
      onClick={() => toggleValue(sourceId)}
      label={(
        <div className={styles.NetworkFilterLabelContainer}>
          <span className={styles.NetworkFilterIconLabels}>
            { label }
          </span>
        </div>
        )}
    />
    );

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {justSelected.length}
        </span> 
        <span className={styles.filterText}>
          {justSelected.length === keys(sources).length ? 'All' : ''}
          {' '}
          Sources
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <ul className={styles.MenuList}>
          <li className={styles.MenuItem} onClick={() => clearAll()}>
            Clear All
          </li>
          {
            map(SOURCE_CONFIG, (source) => (
              <li>
                {
                    renderSources(
                      source.id,
                      source.label,
                    )
                  }
              </li>
              ))
          }
        </ul>
      </Popover>
    </>
  );
};
