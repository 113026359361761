/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_holj6_157 {
  display: flex;
}

._justify-content-space-between_holj6_161 {
  justify-content: space-between;
}

._tabular-nums_holj6_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_holj6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._MediaTypes_holj6_209 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._MediaTypes_holj6_209 ._item_holj6_214 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: color, background-color;
}
._MediaTypes_holj6_209 ._item_holj6_214:hover {
  color: #499dfe;
}
._MediaTypes_holj6_209 ._item_holj6_214._active_holj6_231 {
  background-color: #f5f5f5;
  color: #2871c9;
}
._MediaTypes_holj6_209 ._file_holj6_235 {
  display: none;
}

._Tooltip_holj6_239 ._content_holj6_239 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1;
}
._Tooltip_holj6_239 ._content_holj6_239 ._hotkey_holj6_247 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.125rem;
  margin-left: 0.75rem;
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  background-color: #686268;
  color: #fdfdfd;
  pointer-events: none;
}