import { ITotalsData, TotalsSection } from './TotalsSection/TotalsSection';
import { PostTypeSummarySection, ISummaryData } from './PostTypeSummarySection/PostTypeSummarySection';
import { ITMVData, TMVSection } from './TMVSection/TMVSection';
import { IInsightData, InsightSection } from './InsightSection/InsightSection';
import { AudienceAuthSection } from './AudienceAuthSection/AudienceAuthSection';
import { AudienceLocationSection, IAudienceLocationData } from './AudienceLocationSection/AudienceLocationSection';
import { AudienceDemoSection, IAudienceDemoData } from './AudienceDemoSection/AudienceDemoSection';
import { ITopPostsData, TopPostsSection } from './TopPostsSection/TopPostsSection';
import { ITopCreatorsData, TopCreatorsSection } from './TopCreatorsSection/TopCreatorsSection';
import { ROISection } from './ROISection/ROISection';

export {
  TotalsSection,
  PostTypeSummarySection,
  TMVSection,
  InsightSection,
  AudienceAuthSection,
  AudienceLocationSection,
  AudienceDemoSection,
  TopPostsSection,
  TopCreatorsSection,
  ROISection,
};

export type AudienceDemoData = IAudienceDemoData;
export type AudienceLocationData = IAudienceLocationData;
export type SummaryData = ISummaryData;
export type TMVData = ITMVData;
export type InsightData = IInsightData;
export type TopCreatorsData = ITopCreatorsData;
export type TopPostsData = ITopPostsData;
export type TotalsData = ITotalsData;
