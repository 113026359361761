import 'css-chunk:src/components/widgets/SocialProfile/Card/GrowthCard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1loom_157",
  "justify-content-space-between": "_justify-content-space-between_1loom_161",
  "tabular-nums": "_tabular-nums_1loom_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_1loom_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_1loom_166",
  "Card": "_Card_1loom_170",
  "tooltip": "_tooltip_1loom_306",
  "cardHeader": "_cardHeader_1loom_320",
  "tabs": "_tabs_1loom_331",
  "tabButton": "_tabButton_1loom_335",
  "activeTab": "_activeTab_1loom_354",
  "separator": "_separator_1loom_358",
  "cardTab": "_cardTab_1loom_365",
  "cardTitle": "_cardTitle_1loom_373",
  "GrowthCard": "_GrowthCard_1loom_373",
  "title": "_title_1loom_373",
  "visible": "_visible_1loom_381",
  "show": "_show_1loom_1",
  "noData": "_noData_1loom_386",
  "growthValue": "_growthValue_1loom_396",
  "positive": "_positive_1loom_399",
  "negative": "_negative_1loom_402",
  "growthChart": "_growthChart_1loom_405",
  "areaChart": "_areaChart_1loom_410"
};