import * as React from 'react';
import {
  Col,
  Row,
  Typography,
} from '@revfluence/fresh';
import { IStatisticsCardData } from './types';
import styles from './StatisticsCard.scss';
import { formatValue } from '../../utils';

interface IProps {
  data?: IStatisticsCardData[];
  className?: string;
  timeText?: string;
}
const { Text } = Typography;
export const DashboardStatisticsCardComponent: React.FC<Readonly<IProps>> = React.memo((props) => (
  <Row style={{ height: '59px' }} justify="space-between" className={styles.statsItemContainer}>
    {
      props.data.map((data, index) => (
        <Col key={index} className={styles.payoutItemContainer}>
          <Text className={styles.payoutItemTitle}>{data.title}</Text>
          <Text className={styles.payoutItemDescription}>
            {data?.prefix}
            {' '}
            {formatValue(data.format, data.value)}
            {data.suffix}
          </Text>
        </Col>
      ))
    }
  </Row>
));

DashboardStatisticsCardComponent.displayName = 'DashboardStatisticsCardComponent';
