import * as React from 'react';

import { Typography } from '@revfluence/fresh';

const { Paragraph, Title } = Typography;

export const TuneInstructions = () => (
  <div>
    <Title level={4}>This is a links-based sales tracking offer.</Title>
    <Paragraph>
      An offer will organize any tracking links you send out to people.
      Once you have created the offer, you will need to paste the provided tracking snippet on your conversion website.
      Then it’s recommended to verify that the links are working by making a test sale before you send members their links.
    </Paragraph>
  </div>
);
