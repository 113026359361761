import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_BUDGET_ACCOUNTS from '@frontend/app/queries/GetBudgetAccounts';
import { GetBudgetAccountsQuery, GetBudgetAccountsQueryVariables } from '@frontend/app/queries/types/GetBudgetAccountsQuery';

type IOptions = QueryHookOptions<GetBudgetAccountsQuery, GetBudgetAccountsQueryVariables>;

export function useGetBudgetAccounts(options: IOptions) {
    const {
        loading, data: { budgetAccounts } = {}, error, refetch,
    } = useQuery<GetBudgetAccountsQuery, GetBudgetAccountsQueryVariables>(GET_BUDGET_ACCOUNTS, {
        ...options,
        fetchPolicy: 'cache-and-network',
    });
    return {
        loading,
        budgetAccounts,
        error,
        refetch,
    };
}
