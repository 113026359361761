import * as React from 'react';
import { size } from 'lodash';
import cx from 'classnames';
import { Button, Tooltip } from '@revfluence/fresh';

import {
  ModifyCommunityMembersModal,
  RemoveFromCollectionIcon,
} from '@frontend/app/components';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';

import styles from './RemoveFromGroupButton.scss';

const { useState } = React;

interface IProps {
  memberCount?: number;
  memberIds?: number[];
  disabled?: boolean;
}

export const RemoveFromGroupButton: React.FunctionComponent<IProps> = React.memo((props) => {
  const [open, setOpen] = useState<boolean>(false);

  const {
    searchQuery,
  } = useMemberListContext();

  const addEvent = useEventContext();

  const handleClick = () => {
    addEvent(
      EventName.AttemptBulkAction,
      {
        action: 'remove_from_group',
        member_count: size(props.memberIds) > 0 ? size(props.memberIds) : props.memberCount,
      },
    );
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Remove from Group">
        <span
          className={cx(
                styles.wrapper,
                { [styles.disabled]: props.disabled },
              )}
        >
          <Button
            icon={<RemoveFromCollectionIcon size={16} />}
            className={styles.button}
            disabled={props.disabled}
            onClick={handleClick}
          />
        </span>

      </Tooltip>
      <ModifyCommunityMembersModal
        type="remove"
        onRequestClose={handleModalClose}
        defaultSelectedCommunityIds={[]}
        query={searchQuery}
        memberIds={props.memberIds}
        memberCount={props.memberCount}
        show={open}
      />
    </>
  );
});

RemoveFromGroupButton.displayName = 'RemoveFromGroupButton';
