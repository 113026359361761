import { logger } from '@common';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveSocialData = async (url: string, params: any) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    if (json?.status?.code < 200 || json?.status?.code > 299 || !json?.data) {
      throw json?.status?.error_msg;
    }
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};
