/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

import { useAuth } from '@frontend/context/authContext';
import { ResourceType } from '@frontend/app/types/globalTypes';
import {
  SOCIAL_POST_APP_ID,
  SOCIAL_DISCOVERY_APP_ID,
  CONTRACT_APP_ID,
  PAYMENT_APP_ID,
  CONTENT_APP_ID,
  OUTLOOK_APP_ID,
  GMAIL_APP_ID,
  SHOPIFY_APP_ID,
  SALES_APP_ID,
  WOO_COMMERCE_APP_ID,
  TERMS_APP_ID,
  FULFILLMENT_APP_ID,
  MEMBER_DATA_UPDATE_APP_ID,
  CREATE_ARCHIVE_APP_ID,
  QA_APP_ID,
} from '@frontend/app/constants/applicationIds';

import SocialPostApp from '@frontend/applications/SocialPostApp/pages/SocialPostApp';
import ContractApp from '@frontend/applications/ContractApp/pages/ContractApp';
import ProductFulfillmentApp from '@frontend/applications/ProductFulfillmentApp/pages/ProductFulfillmentApp';
import PaymentApp from '@frontend/applications/PaymentsApp/pages/PaymentApp';
import ContentApp from '@frontend/applications/ContentApp/pages/ContentApp';
import SocialDiscoveryApp from '@frontend/applications/SocialDiscoveryApp/pages/SocialDiscoveryApp';
import ShopifyApp from '@frontend/applications/ShopifyApp/pages/ShopifyApp';
import WooCommerceApp from '@frontend/applications/WooCommerceApp/pages/WooApp';
import MemberDataUpdateApp from '@frontend/applications/MemberDataUpdateApp/pages/MemberDataUpdateApp';
import CreateArchiveApp from '@frontend/applications/CreateArchive/pages/CreateArchiveApp';
import { Main as AffiliatesApp } from '@frontend/applications/AffiliatesApp';
import {
  IMember,
  useAreApplicationsInstalled,
  useGetProgramById,
 } from '@frontend/app/hooks';
import TermsApp from '@frontend/applications/TermsApp/pages/TermsApp';
import QAApp from '@frontend/applications/QAApp/QAApp';
import EmailApp from '@frontend/app/containers/EmailSettings/EmailApp';
import { GroupContentApp } from '@frontend/applications/GroupContentApp';
import { IWorkItem } from '@services/workflow';
import termsWizardStyles from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard.scss';

import styles from './ApplicationContainer.scss';

interface IProps {
  applicationId: string;
  memberId?: string;
  deepLinkParameters?: any;
  modalView?: boolean;
  closeModal?(): void;
  className?: string;
  bulkActionParams?: IBulkActionParameters;
  workflowActionParameters?: IWorkflowActionParameters;
  onSetTitle?: (title: string) => void;
}

export interface IApplicationContainerHandle {
  showWarningOnClose?(): boolean;
  saving?: boolean;
}
export interface IBulkActionParameters {
  memberIds?: number[];
  members?: IMember[];
  memberQueryJson?: string;
  memberCount?: number;
}

export interface IWorkflowActionParameters {
  programId: number;
  programName: string;
  workletSpecUri: string;
  taskId: string;
  workItems: IWorkItem[];
  memberIds: number[];
  projectId?: number;
}

const { useRef, useImperativeHandle } = React;
const getApplicationTitle = (applicationId: string): string => {
  switch (applicationId) {
    case GMAIL_APP_ID: {
      return 'Gmail';
    }
    case OUTLOOK_APP_ID: {
      return 'Outlook';
    }
    case SOCIAL_POST_APP_ID: {
      return 'Social Analytics';
    }
    case SOCIAL_DISCOVERY_APP_ID: {
      return 'Creator Search';
    }
    case CONTRACT_APP_ID: {
      return 'Contracts';
    }
    case CONTENT_APP_ID: {
      return 'Content';
    }
    case PAYMENT_APP_ID: {
      return 'Payments';
    }
    case FULFILLMENT_APP_ID: {
      return 'Product Fulfillment';
    }
    case SHOPIFY_APP_ID: {
      return 'Shopify';
    }
    case SALES_APP_ID: {
      return 'Sales Tracking';
    }
    case WOO_COMMERCE_APP_ID: {
      return 'Woocommerce';
    }
    case MEMBER_DATA_UPDATE_APP_ID: {
      return 'Info Update';
    }
    case TERMS_APP_ID: {
      return 'Briefs';
    }
    case CREATE_ARCHIVE_APP_ID: {
      return 'Create Archive';
    }
  }

  return null;
};

/**
 * @type {React.FunctionComponent}
 */
const ApplicationContainerInternal: React.ForwardRefRenderFunction<IApplicationContainerHandle, IProps> = (
  props,
  ref,
) => {
  const {
    applicationId,
    modalView,
    memberId,
  } = props;
  const { clientInfo, user } = useAuth();
  const applicationRef = useRef<IApplicationContainerHandle>(null);

  const shopifyApp = useAreApplicationsInstalled([SHOPIFY_APP_ID]);

  const programId = props.deepLinkParameters?.program_id || props.workflowActionParameters?.programId;
  const {
    data: {
      program = null,
    } = {},
   } = useGetProgramById({
    variables: {
      id: programId,
    },
    skip: !programId,
  });

  useImperativeHandle(ref, () => ({
    showWarningOnClose: () => {
      if ([FULFILLMENT_APP_ID, TERMS_APP_ID].includes(applicationId) && applicationRef.current?.showWarningOnClose) {
        // check why pfa ref is null
        // could be related to the provider wrapper
        return applicationRef.current.showWarningOnClose();
      }

      return false;
    },
  }));

  return (
    <div
      className={cx(styles.ApplicationContainer, props.className, {
        [styles.modalView]: modalView,
        [termsWizardStyles.content]: true,
      })}
    >
      <Helmet title={getApplicationTitle(applicationId)} />
      {applicationId === GMAIL_APP_ID && <EmailApp type={ResourceType.GMAIL} />}
      {applicationId === OUTLOOK_APP_ID && <EmailApp type={ResourceType.OUTLOOK} />}
      {applicationId === SOCIAL_POST_APP_ID && (
        <SocialPostApp
          modalView={modalView}
          memberId={memberId}
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          userId={user.sub}
          deepLinkParameters={props.deepLinkParameters}
          workflowActionParameters={props.workflowActionParameters}
        />
      )}
      {applicationId === SOCIAL_DISCOVERY_APP_ID && (
        <SocialDiscoveryApp
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          userId={user.sub}
          memberId={memberId}
        />
      )}
      {applicationId === CONTRACT_APP_ID && (
        <ContractApp
          modalView={modalView}
          memberId={memberId}
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          bulkActionParameters={props.bulkActionParams}
          closeModal={props.closeModal}
          workflowActionParameters={props.workflowActionParameters}
        />
      )}
      {applicationId === FULFILLMENT_APP_ID && (
        <ProductFulfillmentApp
          ref={applicationRef}
          modalView={modalView}
          memberId={memberId}
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          deepLinkParameters={props.deepLinkParameters}
          bulkActionParameters={props.bulkActionParams}
          workflowActionParameters={props.workflowActionParameters}
          closeModal={props.closeModal}
        />
      )}
      {applicationId === PAYMENT_APP_ID && (
        <PaymentApp
          modalView={modalView}
          memberId={memberId}
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          workflowActionParameters={props.workflowActionParameters}
          closeModal={props.closeModal}
        />
      )}
      {applicationId === CONTENT_APP_ID && (
        <>
          {program?.gcrEnabled && (
            <GroupContentApp
              programId={props.deepLinkParameters?.program_id || props.workflowActionParameters?.programId}
              memberId={props.deepLinkParameters?.member_id || props.workflowActionParameters?.memberIds?.[0]}
            />
          )}
          {!program?.gcrEnabled && (
            <ContentApp
              modalView={modalView}
              memberId={memberId}
              clientId={clientInfo.id}
              clientName={clientInfo.name}
              deepLinkParameters={props.deepLinkParameters}
              workflowActionParameters={props.workflowActionParameters}
            />
          )}
        </>
      )}
      {applicationId === SHOPIFY_APP_ID && (
        <ShopifyApp clientId={clientInfo.id} clientName={clientInfo.name} />
      )}
      {applicationId === SALES_APP_ID && !shopifyApp.loading && (
        <AffiliatesApp
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          modalView={modalView}
          deepLinkParameters={props.deepLinkParameters}
          workflowActionParameters={props.workflowActionParameters}
          shopifyAppEnabled={shopifyApp.isInstalled[0]}
          shopifyAppId={SHOPIFY_APP_ID}
          closeModal={props.closeModal}
        />
      )}
      {applicationId === WOO_COMMERCE_APP_ID && (
        <WooCommerceApp clientId={clientInfo.id} clientName={clientInfo.name} />
      )}
      {applicationId === MEMBER_DATA_UPDATE_APP_ID && (
        <MemberDataUpdateApp
          memberId={memberId}
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          bulkActionParameters={props.bulkActionParams}
          workflowActionParameters={props.workflowActionParameters}
          closeModal={props.closeModal}
        />
      )}
      {applicationId === TERMS_APP_ID && (
        <TermsApp
          ref={applicationRef}
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          memberId={memberId}
          modalView={modalView}
          closeModal={props.closeModal}
          workflowActionParameters={props.workflowActionParameters}
          deepLinkParameters={props.deepLinkParameters}
          onSetTitle={props.onSetTitle}
        />
      )}
      {applicationId === CREATE_ARCHIVE_APP_ID && (
        <CreateArchiveApp
          clientId={clientInfo.id}
          clientName={clientInfo.name}
          memberId={memberId}
          closeModal={props.closeModal}
          workflowActionParameters={props.workflowActionParameters}
          deepLinkParameters={props.deepLinkParameters}
        />
      )}
      {applicationId === QA_APP_ID && (
        <QAApp
          {...{} as any}
          clientId={clientInfo.id}
          memberId={memberId}
          modalView={modalView}
          workflowActionParameters={props.workflowActionParameters}
          deepLinkParameters={props.deepLinkParameters}
        />
      )}
    </div>
  );
};

export const ApplicationContainer = React.forwardRef(ApplicationContainerInternal);
