/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xcgu_157 {
  display: flex;
}

._justify-content-space-between_1xcgu_161 {
  justify-content: space-between;
}

._tabular-nums_1xcgu_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xcgu_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ThumbnailUpload_1xcgu_178 ._fileSubtitle_1xcgu_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._ThumbnailUpload_1xcgu_178 {
  display: flex;
  align-items: flex-start;
  padding-right: 1.5rem;
}
._ThumbnailUpload_1xcgu_178 ._fileSelector_1xcgu_193 {
  width: 11.375rem;
  height: 11.375rem;
  border-color: var(--gray-5);
  background-color: var(--gray-3);
}
._ThumbnailUpload_1xcgu_178 ._fileSelector_1xcgu_193 [class*=FileSelector_text] > div {
  font-size: 0.75rem;
}
._ThumbnailUpload_1xcgu_178._error_1xcgu_202 ._fileSelector_1xcgu_193 {
  border-color: #f1515f;
  background-color: #f6e4e5 !important;
}
._ThumbnailUpload_1xcgu_178._error_1xcgu_202 ._fileSelector_1xcgu_193 [class*=FileSelector_text] > div {
  color: #f1515f;
  font-size: 0.75rem;
}
._ThumbnailUpload_1xcgu_178._error_1xcgu_202 ._fileSelector_1xcgu_193 svg {
  color: #f1515f;
}
._ThumbnailUpload_1xcgu_178 ._tmpLogoWrapper_1xcgu_213 {
  position: relative;
  width: 100%;
}
._ThumbnailUpload_1xcgu_178 ._tmpLogoWrapper_1xcgu_213 ._removeImg_1xcgu_217 {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.125rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 50%;
  background: #fdfdfd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
._ThumbnailUpload_1xcgu_178 ._tmpLogo_1xcgu_213 {
  width: 11.375rem;
  height: 11.375rem;
  margin-bottom: 0.625rem;
  border-radius: 0.5rem;
  object-fit: cover;
}
._ThumbnailUpload_1xcgu_178 ._tmpVideo_1xcgu_239 {
  width: 100%;
  height: 172px;
  object-fit: cover;
}
._ThumbnailUpload_1xcgu_178 ._fileSubtitle_1xcgu_178 {
  margin-top: 0.625rem;
  color: #8f8d91;
}
._ThumbnailUpload_1xcgu_178 ._fileSubtitle_1xcgu_178._error_1xcgu_202 {
  color: #f1515f;
}
._ThumbnailUpload_1xcgu_178 ._fileSubtitle_1xcgu_178._hintText_1xcgu_251 {
  display: flex;
  line-height: 1.5;
}
._ThumbnailUpload_1xcgu_178 ._fileSubtitle_1xcgu_178._hintText_1xcgu_251 ._hintTextIcon_1xcgu_255 {
  color: var(--gold-6);
  margin-right: 0.375rem;
  transform: translateY(3px);
}
._ThumbnailUpload_1xcgu_178 ._simpleUploadContent_1xcgu_260 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--gray-9);
}
._ThumbnailUpload_1xcgu_178 ._simpleUploadContent_1xcgu_260 ._simpleUploadContentIcon_1xcgu_269 {
  font-size: 22px;
}
._ThumbnailUpload_1xcgu_178 ._simpleUploadContent_1xcgu_260 span {
  margin-top: 6px;
  font-size: 14px;
}
._ThumbnailUpload_1xcgu_178._displayInline_1xcgu_276 ._selectorContainer_1xcgu_276 {
  display: flex;
}
._ThumbnailUpload_1xcgu_178._displayInline_1xcgu_276 ._tmpLogo_1xcgu_213 {
  width: 80px;
  height: 80px;
}
._ThumbnailUpload_1xcgu_178._displayInline_1xcgu_276 ._fileSubtitle_1xcgu_178 {
  margin-top: 0;
  display: block;
  margin-left: 16px;
}
._ThumbnailUpload_1xcgu_178._displayInline_1xcgu_276 ._fileSubtitle_1xcgu_178._hintText_1xcgu_251 ._hintTextIcon_1xcgu_255 {
  margin-right: 3px;
  transform: none;
}
._ThumbnailUpload_1xcgu_178._displayInline_1xcgu_276 ._fileSelector_1xcgu_193 {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
}
._ThumbnailUpload_1xcgu_178._displayExpanded_1xcgu_297 ._fileSelector_1xcgu_193 {
  width: 100%;
  height: 172px;
}