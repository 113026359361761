import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';

import { CloseIcon, CalendarIcon } from '@components';

import { DatePickerDropdown } from './DatePickerDropdown';

import styles from './DatePicker.scss';

interface IProps {
  defaultDate?: Date;
  onDateSelected?(date: Date);

  hasError?: boolean;
  errorMessage?: string;
  autoFocus?: boolean;

  classNames?: string[];
}
type TDefaultProp = 'defaultDate' | 'onDateSelected' | 'hasError' | 'errorMessage' | 'classNames' | 'autoFocus';
interface IState {
  selectedDate: Date;
  isDateSelected: boolean;

  showCalendar: boolean;
}

export { DatePickerDropdown };

/**
 * @class
 * @extends {React.PureComponent}
 */
export class DatePicker extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    defaultDate: null,
    onDateSelected: () => undefined,

    hasError: false,
    errorMessage: '',
    autoFocus: false,

    classNames: [],
  };

  private popoverAnchorRef: React.RefObject<HTMLDivElement>;

  private clearIconRef: React.RefObject<HTMLDivElement>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.popoverAnchorRef = React.createRef();
    this.clearIconRef = React.createRef();

    this.state = {
      selectedDate: props.defaultDate,
      isDateSelected: false,
      showCalendar: props.autoFocus,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      hasError,
      errorMessage,
      classNames,
    } = this.props;
    const { isDateSelected, selectedDate, showCalendar } = this.state;

    return (
      <div className={cx(classNames, styles.DatePicker)}>
        <div
          className={cx(styles.dateInput, {
            [styles.dateSelected]: isDateSelected,
            [styles.hasError]: hasError,
          })}
          ref={this.popoverAnchorRef}
          onClick={this.showCalendar}
        >
          <CalendarIcon size={16} className={styles.icon} />
          <div className={styles.formattedDate}>
            {selectedDate && format(selectedDate, 'MM/dd/yyyy')}
            {!selectedDate && 'Select Date...'}
          </div>
          <div
            ref={this.clearIconRef}
            onClick={this.clearSelectedDate}
          >
            <CloseIcon size={16} className={styles.close} />
          </div>
        </div>
        {hasError && !isEmpty(errorMessage) && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
        <DatePickerDropdown
          mountRef={this.popoverAnchorRef}
          show={showCalendar}
          onRequestClose={this.hideCalendar}
          onDateSelected={this.selectDate}
          selectedDate={this.state.selectedDate}
        />
      </div>
    );
  }

  /**
   * @private
   */
  private selectDate = (date: Date) => {
    this.setState({
      selectedDate: date,
      isDateSelected: true,
    });

    const { onDateSelected } = this.props;
    onDateSelected(date);

    this.hideCalendar();
  };

  /**
   * @private
   */
  private showCalendar = (e) => {
    // do not show calendar when user clicks clear icon
    if (!this.clearIconRef.current?.contains(e.target)) {
      this.setState({
        showCalendar: true,
      });
    }
  };

  /**
   * @private
   */
  private hideCalendar = () => {
    this.setState({
      showCalendar: false,
    });
  };

  /**
   * @private
   * Clears the selected date.
   */
  private clearSelectedDate = (event: React.MouseEvent) => {
    const { onDateSelected } = this.props;

    event.preventDefault();
    event.stopPropagation();

    onDateSelected(null);

    this.setState({
      selectedDate: null,
      isDateSelected: false,
    });
  };
}
