import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_IMPORTED_PRODUCTS } from '@frontend/applications/ProductFulfillmentApp/queries/importedProducts';
import { GetImportedProducts, GetImportedProductsVariables, GetImportedProducts_getProductsByCollectionId } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportedProducts';

type IOptions = QueryHookOptions<GetImportedProducts, GetImportedProductsVariables>;

export const useGetImportedProducts = (options: IOptions) => {
  const {
    loading, data, error, refetch, fetchMore,
   } = useQuery<GetImportedProducts, GetImportedProductsVariables>(GET_IMPORTED_PRODUCTS, options);

  return {
    loading,
    products: data?.getProductsByCollectionId ?? { products: [], totalProducts: 0 } as GetImportedProducts_getProductsByCollectionId,
    error,
    refetch,
    fetchMore,
  };
};
