import { IBrand } from './brand';
import { IManager } from './manager';

export interface IOrganization {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pending_plan_id?: any;
  features?: string[];
  in_opt_out_period: boolean;
  is_subscribed: boolean;
  set_live_ts: number;
  default_card: ICard;
  fee_percentage: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pending_subscription?: any;
  referral_code: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onboarding_flow?: any;
  representative_email: string;
  requires_processing_fee: boolean;
  max_users: number;
  max_campaigns_per_brand: number;
  is_self_serve: boolean;
  id: number;
  max_brands_reached: boolean;
  is_verified: boolean;
  website: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tmv_values?: any;
  on_trial_period: boolean;
  paid: number;
  brands: IBrand[];
  subscription: ISubscription;
  available_networks: string[];
  name: string;
  is_beta_tester: boolean;
  org_type: string;
  cards: ICard[];
  tutorial_onboarding_flow: boolean;
  managers: IManager[];
}

interface ICard {
  address_state?: string;
  last4: string;
  dynamic_last4?: string;
  address_zip_check?: boolean;
  address_country?: string;
  id: string;
  address_line2?: string;
  address_line1?: string;
  funding: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  cvc_check: string;
  exp_month: number;
  tokenization_method?: string;
  address_line1_check?: boolean;
  brand: string;
  object: string;
  fingerprint: string;
  exp_year: number;
  address_zip?: string;
  customer: string;
  address_city?: string;
  name?: string;
  country: string;
}

interface ISubscription {
  livemode: boolean;
  days_until_due?: number;
  id: string;
  billing: string;
  current_period_end: number;
  start: number;
  tax_percent?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  status: string;
  trial_start?: never;
  cancel_at_period_end: boolean;
  billing_cycle_anchor: number;
  object: string;
  discount?: never;
  trial_end?: never;
  plan: IPlan;
  application_fee_percent?: never;
  customer: string;
  ended_at?: never;
  billing_thresholds?: never;
  default_source?: never;
  canceled_at?: never;
  created: number;
  current_period_start: number;
  quantity: number;
}

interface IPlan {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  aggregate_usage?: any;
  livemode: boolean;
  interval_count: number;
  currency: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tiers_mode?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform_usage?: any;
  id: string;
  metadata: IPlanMetadata;
  product: string;
  statement_descriptor?: string;
  object: string;
  trial_period_days?: number;
  active: boolean;
  usage_type: string;
  nickname?: string;
  statement_description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tiers?: any;
  name: string;
  created: number;
  interval: string;
  amount: number;
  billing_scheme: string;
}

interface IPlanMetadata {
  fee_percentage: string;
  default_trial_days: string;
  default_quantity: string;
  price_per_post: string;
}
