import { useMemo } from 'react';

import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';
import { GetThreadQuery_thread_messages as IMessage } from '@frontend/app/queries/types/GetThreadQuery';

import { useResourceContext } from '@frontend/app/context';

export interface ILastMessageData {
  read: boolean;
  replierName: string;
  repliedByYou: boolean;
  snippet: string;
  internalDate: number;
}

export const useLastMessage = (messageData: IMessage, member: IMember): ILastMessageData => {
  const { emailResources } = useResourceContext();

  return useMemo(() => {
    if (!messageData) {
      return null;
    }

    const read = member.email !== messageData.payload.from;
    const userEmails = emailResources.map((resource) => resource.authProvider.userExternalDisplayId);
    const repliedByYou = userEmails.includes(messageData.payload.from);
    let replierName = repliedByYou ? 'You' : member.name;
    if (read && !repliedByYou) {
      replierName = messageData.payload.from;
    }

    const div = document.createElement('div');
    div.innerHTML = messageData.snippet || '';
    let snippet = div.innerText.replace(/\s/g, ' ');
    if (snippet.length > 200) {
      snippet = `${snippet.slice(0, 200)}...`;
    }

    return {
      read,
      replierName,
      repliedByYou,
      repliedBy: messageData.payload.from,
      snippet,
      internalDate: messageData.internalDate,
    };
  }, [messageData, emailResources, member]);
};
