import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const EditOutlinedIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <mask id="EditOutlinedIcon-mask" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
      <path id="icon" d="M20.9697 2.9697C20.2424 2.30303 19.5758 2 18.9091 2C18.1212 2 17.3939 2.42424 16.9091 2.90909L2.42424 17.3333C2.42424 17.3333 2.36364 17.3939 2.36364 17.4545L2 20.9697C2 21.1515 2 21.3939 2.30303 21.697C2.54545 21.9394 2.84848 22 2.9697 22C3.09091 22 6.48485 21.6364 6.48485 21.6364C6.54545 21.6364 6.54545 21.6364 6.60606 21.5758L20 8.18182C20.1212 8.12121 20.2424 8.06061 20.303 8C20.3636 7.93939 20.4848 7.81818 20.4848 7.69697L21.0909 7.09091C22 6.18182 22.6061 4.60606 20.9697 2.9697ZM18.9091 3.69697C19.2727 3.69697 19.5758 4 19.8182 4.18182C20.303 4.66667 20.6667 5.21212 19.9394 5.93939L19.697 6.18182L17.8788 4.36364L18.1212 4.12121C18.3636 3.81818 18.6667 3.69697 18.9091 3.69697ZM5.81818 20C5.09091 20.0606 4.30303 20.1818 3.75758 20.2424C3.81818 19.5758 3.93939 18.7273 4 18.1818L16.6667 5.51515L18.4848 7.33333L5.81818 20Z" />
    </mask>
    <g mask="url(#EditOutlinedIcon-mask)">
      <rect id="Rectangle" width="24" height="24" />
    </g>
  </SvgIcon>
));

EditOutlinedIcon.displayName = 'EditOutlinedIcon';
