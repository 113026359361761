/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ug5c_157 {
  display: flex;
}

._justify-content-space-between_1ug5c_161 {
  justify-content: space-between;
}

._tabular-nums_1ug5c_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ug5c_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._imgContain_1ug5c_178 ._ImageUpload_1ug5c_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._imgContain_1ug5c_178 {
  height: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
}
._imgContain_1ug5c_178 .ant-image-mask {
  border-radius: 0.25rem;
}
._imgContain_1ug5c_178 ._ImageUpload_1ug5c_178 {
  position: absolute;
  right: 0;
  bottom: -3px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25%;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  background-color: #8f8d91;
  color: #fdfdfd;
  text-align: center;
}
._imgContain_1ug5c_178 ._ImageUpload_1ug5c_178 ._wrapper_1ug5c_212 {
  position: relative;
  font-size: 12px;
  line-height: 1.33;
  color: #fdfdfd;
}
._imgContain_1ug5c_178 ._ImageUpload_1ug5c_178:not(._disabled_1ug5c_218) ._wrapper_1ug5c_212 {
  cursor: pointer;
}
._imgContain_1ug5c_178 ._ImageUpload_1ug5c_178 ._img_1ug5c_178 {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}
._imgContain_1ug5c_178 ._ImageUpload_1ug5c_178 ._img_1ug5c_178._largeImage_1ug5c_227 {
  width: 6rem;
  height: 6rem;
}
._imgContain_1ug5c_178 ._ImageUpload_1ug5c_178 ._img_1ug5c_178._largeImage_1ug5c_227 svg {
  margin-bottom: 1rem;
  font-size: 3rem;
}