import * as React from 'react';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { PaymentTable } from '../components/PaymentTable';
import { NewPaymentTable } from '../components/NewPaymentTable/NewPaymentTable';

const PaymentListPage: React.FunctionComponent = () => {
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  return <div>{isBudgetAllocationEnabled ? <NewPaymentTable /> : <PaymentTable />}</div>;
};

export default PaymentListPage;
