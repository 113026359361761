import gql from 'graphql-tag';

export const MEMBER_PROGRAMS_AND_COMMUNITIES = gql`
  query MemberProgramsAndCommunitiesQuery($memberId: Int!) {
    member: memberById(id: $memberId) {
      id
      programs {
        id
        title
        communityId
        status
      }
      communities {
        id
        title
      }
    }
  }
`;
