import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { flatMap, map, isNil } from 'lodash';

import { Radio } from '@revfluence/fresh';
import { ArrowsRotateIcon, CircleCheckIcon as CircleCheckIconSolid } from '@revfluence/fresh-icons/solid/esm';

import { Comments } from '@frontend/app/components/GroupContentReview/Comments';
import { Guidelines } from '@frontend/app/components/GroupContentReview/Guidelines';
import { Info } from '@frontend/app/components/GroupContentReview/Info';
import { ActionButtons } from '@frontend/app/components/GroupContentReview/ActionButtons';
import { ActionFeedbackForm } from '@frontend/app/components/GroupContentReview/ActionFeedbackForm';
import { TCheckoffGuidelines } from '@frontend/app/types/GroupContentReview';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import { TDetails } from '../../types';
import styles from './Details.module.scss';

export const Details = ({
  comments, guidelines, review, programId,
  takingActions, canTakeActions,
  onApprove, canApprove,
  onReject, canReject, isLatestVersion, refetchContentReview,
}: TDetails) => {
  const showInfoTab = useClientFeatureEnabled(ClientFeature.CONTENT_SUBMISSION_FORM);

  const [view, setView] = React.useState(showInfoTab ? 'info' : 'comments');
  const [isRejecting, setIsRejecting] = useState(false);
  const [isApproveWithComments, setIsApproveWithComments] = useState(false);
  const [comment, setComment] = useState('');
  const [guidelineCheckOffs, setGuidelineCheckOffs] = useState<TCheckoffGuidelines[]>([]);
  const approveWithComment = useClientFeatureEnabled(ClientFeature.APPROVE_WITH_COMMENTS);

  useEffect(() => {
    setView(showInfoTab ? 'info' : 'comments');
  }, [showInfoTab]);

  useEffect(() => {
    setGuidelineCheckOffs(guidelines.data);
  }, [guidelines]);

  const onRejectStateChange = (comment: string, checkOffs?: boolean[]) => {
    setIsRejecting(false);
    onReject(comment, checkOffs);
  };

  const onSubmissionClick = () => {
    const checkOffs = flatMap(guidelineCheckOffs, (g) => map(g.rules, (r) => r.required));
    onRejectStateChange(comment, checkOffs);
  };

  const approveWithComments = useCallback(() => {
    onApprove({ comment });
    setIsApproveWithComments(false);
  }, [onApprove, comment]);

  const buttonConfigs = [
    {
      label: 'Request Changes',
      onClick: () => setIsRejecting(true),
      icon: <ArrowsRotateIcon style={{ color: '#167CF4' }} />,
      disabled: !canReject || !isLatestVersion,
    },
    {
      label: 'Approve',
      onClick: () => (approveWithComment ? setIsApproveWithComments(true) : onApprove()),
      icon: <CircleCheckIconSolid style={{ color: '#389E0D' }} />,
      disabled: !canApprove || !isLatestVersion,
    },
  ];

  if (isNil(showInfoTab)) return null;

  return (
    <div className={styles.Details}>
      <Radio.Group
        value={view}
        onChange={(event) => setView(event.target.value)}
        buttonStyle="solid"
        className={styles.navigation}
        size="middle"
      >
        {showInfoTab && <Radio.Button value="info">Info</Radio.Button>}
        <Radio.Button value="comments">Comments</Radio.Button>
        <Radio.Button value="guidelines">Guidelines</Radio.Button>
      </Radio.Group>
      {isRejecting && (
        <ActionFeedbackForm
          onCancel={() => setIsRejecting(false)}
          comment={comment}
          onCommentChange={(comment) => setComment(comment)}
          instructionText="Which guidelines are not addressed?"
          submissionCTA="Submit Rejection"
          onSubmissionClick={onSubmissionClick}
          guidelineCheckOffs={guidelineCheckOffs}
          onGuidelineChange={(guidelines) => setGuidelineCheckOffs(guidelines)}
        />
      )}
      {isApproveWithComments && (
        <ActionFeedbackForm
          onCancel={() => setIsApproveWithComments(false)}
          comment={comment}
          onCommentChange={(comment) => setComment(comment)}
          instructionText="Approve Content"
          instructionDescription="Add supporting comments to this approval. The creator will receive an email notification of this approval with your attached comments."
          submissionCTA="Submit Approval"
          onSubmissionClick={approveWithComments}
          guidelineCheckOffs={guidelineCheckOffs}
          onGuidelineChange={(guidelines) => setGuidelineCheckOffs(guidelines)}
          isAdminRole
        />
      )}
      {(!isRejecting && !isApproveWithComments) && (
        <div className={styles.main}>
          <div className={styles.content}>
            {view === 'comments' && <Comments {...comments} isLatestVersion={isLatestVersion} />}
            {showInfoTab && view === 'info' && <Info review={review} programId={programId} refetchContentReview={refetchContentReview} allowEditingContentFields />}
            {view === 'guidelines' && <Guidelines {...guidelines} />}
          </div>
          {canTakeActions && (
            <div className={styles.actions}>
              <ActionButtons
                actionOptions={buttonConfigs}
                takingActions={takingActions}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
