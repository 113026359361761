import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_CATALOG_INSIGHTS_SUMMARY_QUERY } from '../queries';
import { GetCatalogInsightsSummary } from '../queries/types/GetCatalogInsightsSummary';

type IOptions = QueryHookOptions<GetCatalogInsightsSummary>;

export function useGetCatalogInsightsSummary(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetCatalogInsightsSummary>(
    GET_CATALOG_INSIGHTS_SUMMARY_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    isCatalogInsightsSummaryLoading: loading,
    catalogInsightsSummary: data?.summary,
    catalogInsightsSummaryError: error,
    refetchCatalogInsightsSummary: refetch,
  };
}
