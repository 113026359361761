import * as React from 'react';
import cx from 'classnames';

import {
  Space,
  Skeleton,
  Typography,
} from '@revfluence/fresh';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';

import { RouteComponentProps } from 'react-router-dom';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useAnalytics, useMessagingContext } from '@frontend/hooks';
import {
  OAuthNotice,
  Notice,
} from '@components';
import { includes, isUndefined, isArray } from 'lodash';
import { useFetchSocialAccountData } from '../useFetchSocialAccountData';

import { SocialAccountList } from '../components/SocialAccountList';

import styles from './SocialAppSettings.scss';

const { useEffect, useMemo } = React;

const OAUTH_TYPE = 'aspirex_add_brand_account_mentions';

export const SocialAppSettingsLoading = () => (
  <Space direction="vertical" style={{ width: '100%' }}>
    <Skeleton.Button size="large" block />
    <Skeleton.Button size="large" block />
    <Skeleton.Button size="large" block />
    <Skeleton.Button size="large" block />
  </Space>
);

interface IProps {
  title?: string;
  showNav?: boolean;
  classes?: string[];
}

const SocialAppSettings: React.FunctionComponent<RouteComponentProps<{}> & IProps> = (props) => {
  const { backendServerWebEndpoint, backendServerApiEndpoint, clientId } = useApplication();
  const oauthEndpoint = `${backendServerWebEndpoint}brands/${clientId}/facebook/aspirex_oauth`;
  const redirect = window.location.href;
  const directOauthEndpoint = `${oauthEndpoint}?oauth_type=${OAUTH_TYPE}&redirect=${redirect}`;
  const oauthStatus = null;
  const analytics = useAnalytics();
  const {
    showInfoMessage,
    showErrorMessage,
  } = useMessagingContext();

  const igSuccess = includes(window.location.search, 'ig_success=True');

  const { loading, data, error } = useFetchSocialAccountData({
    url: `${backendServerApiEndpoint}/social_account`,
    clientId,
    username: null,
    fetchAll: true,
  });

  const addEvent = useEventContext();

  useEffect(() => {
    if (analytics && isUndefined(window.localStorage.ig_connect_success_track) && igSuccess === true) {
      addEvent(EventName.OAuthGrantSuccess, { app: 'social_post' });
      // Setting to true so that we do not track duplicate events due to reload/refresh
      window.localStorage.ig_connect_success_track = true;
    }
  }, [igSuccess, addEvent, analytics]);

  const classNames = useMemo(() => {
    let pageClassName = styles.OauthNotice;
    let settingDetailClassName;
    if (isArray(props.classes)) {
      if (props.classes.length > 0) {
        pageClassName = cx(pageClassName, props.classes[0]);
      }
      if (props.classes.length > 1) {
        settingDetailClassName = props.classes[1];
      }
    }
    return { pageClassName, settingDetailClassName };
  }, [props.classes]);

  if (oauthStatus) {
    switch (oauthStatus) {
      case 'success':
        showInfoMessage('Your Instagram is now connected');
        break;
      case 'error.INCORRECT_ACCOUNT':
        showErrorMessage('The Instagram account you authorized does not match your brand\'s Instagram account.');
        break;
      case 'error.INSUFFICIENT_PERMISSIONS':
        showErrorMessage('You did not grant sufficient permissions for Aspire to retrieve mentions.');
        break;
      default:
        showErrorMessage('An unknown error occured. If this issue persists please contact help@aspireiq.com');
        break;
    }
  }

  if (error) {
    return (
      <Notice type="error">
        There is an error when trying to fetch the reports.
      </Notice>
    );
  }
  if (loading) {
    return <SocialAppSettingsLoading />;
  }

  return (
    <div className={styles.SocialAppSettings}>
      { props.title && <Typography.Title level={3}>{props.title}</Typography.Title>}
      {(data && data.length === 0) || oauthStatus
      ? (
        <div className={classNames.pageClassName}>
          <OAuthNotice
            oauthEndpoint={oauthEndpoint}
            oauthType="aspirex_add_brand_account_mentions"
            oauthStatus={oauthStatus || ''}
            redirect={redirect}
            aspirexAnalytics={analytics}
          />
        </div>
        )
      : <SocialAccountList accounts={data} oauthEndpoint={directOauthEndpoint} showNav={props.showNav} className={classNames.settingDetailClassName} />}
    </div>
  );
};

SocialAppSettings.defaultProps = {
  showNav: true,
};

export default SocialAppSettings;
