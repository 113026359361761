import gql from 'graphql-tag';

export const GET_COLLABORATION_DETAILS_FOR_PROJECT_QUERY = gql`
  query GetCollaborationDetailsForProjectQuery($projectId: Int!, $memberId: Int!) {
    details: getCollaborationDetailsForProject(projectId: $projectId, memberId: $memberId) {
      agreementDate
      agreementIterationId
      agreementId
      backendServerProjectId
      contentRequirementId
      displayName
      projectURL
      previewImg
      contentUrls
      submittedContentCount
      nextDeadlineTimeStamp
      completedContentCount
      totalContentCount
      completedDateTimeStamp
      completedBy
      id
      postType
      requiresApproval
      state
      termsType
    }
  }
`;
