const MAX_ACCEPTABLE_PAYMENT_AMOUNT: number = 100000000; // 100 MM

// Return an error message if something went wrong.
export const ValidatePaymentInput = (payment: string): string => {
  const amountNumericValue = parseFloat(payment);
  if (Number.isNaN(amountNumericValue)) {
    return 'Payment amount is required to continue.';
  }

  if (amountNumericValue < 0) {
    return 'Payment amount should be positive to continue.';
  }
  if (amountNumericValue > MAX_ACCEPTABLE_PAYMENT_AMOUNT) {
    return 'Payments over $100 million are not accepted.';
  }
  return '';
};

export const FormatPayment = (payment: number): number => {
  const fixedNumber = (Math.round(payment * 100) / 100).toFixed(2);
  return parseFloat(fixedNumber);
};

// It will format the number without the dollar signal.
const usMoneyFormatterWithoutSignal = new Intl.NumberFormat('en-US', {
  useGrouping: false,
});

export const FormatUSMoney = (payment: number): string => usMoneyFormatterWithoutSignal.format(payment);
