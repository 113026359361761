import * as React from 'react';
import moment from 'moment';
import { first, isArray } from 'lodash';
import { isFunction } from 'lodash';
import { DatePicker } from 'antd';
import { localToUTC, utcToLocal } from '@frontend/app/utils/date';

import styles from './DateEqual.scss';

const { useCallback, useMemo } = React;

interface IProps {
  value: Date;
  onChange?(value: Date);
}

export const DateEqual: React.FC<IProps> = (props) => {
  const {
    value,
    onChange,
  } = props;

  if (isArray(value)) {
    const date = new Date(first(value));
    onChange(localToUTC(date));
  }

  const handleChangeDate = useCallback((date: moment.Moment) => {
    if (isFunction(onChange)) {
      if (date) {
        onChange(localToUTC(date.toDate()));
      } else {
        onChange(null);
      }
    }
  }, [onChange]);

  const date = useMemo(() => {
    const val = isArray(value) ? first(value) : value;
    return val
      ? moment(utcToLocal(new Date(val)))
      : null;
  }, [value]);

  return (
    <div className={styles.DateEqual}>
      <DatePicker
        className={styles.datePicker}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value={date as any}
        onChange={handleChangeDate}
      />
    </div>
  );
};
