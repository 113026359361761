import * as React from 'react';

import { logger } from '@common';
import { Typography } from '@revfluence/fresh';

import styles from './ErrorBoundary.scss';
import { ErrorTrace } from './ErrorTrace';

interface IState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorInfo: any;
}

const { Link, Title } = Typography;

export class ErrorBoundary extends React.Component<{}, IState> {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  public componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  public render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;

    if (error) {
      logger.error('ErrorBoundary: Error caught:', error);
      return (
        <div className={styles.ErrorBoundary}>
          <div className={styles.content}>
            <div className={styles.text}>
              <Title
                className={styles.whoopsie}
                level={1}
              >
                Something went wrong.
              </Title>
              <Title
                className={styles.subtext}
                level={4}
                noMargin
              >
                This might take a little investigating.
                <br />
                If this issue continues, contact support&nbsp;
                <Link href="mailto:help@aspireiq.com">here</Link>
                .
              </Title>
            </div>
            <ErrorTrace
              className={styles.errorTrace}
              error={error}
              errorInfo={errorInfo}
            />
            <img
              className={styles.image}
              src="https://storage.googleapis.com/aspirex-static-files/error-art.svg"
            />
          </div>
        </div>
      );
    }

    return children;
  }
}
