import { useQuery } from '@apollo/client';
import {
  ThreadFilterInput,
} from '@frontend/app/types/globalTypes';
import { GET_ALL_THREADS_COUNT_QUERY } from '@frontend/app/queries';
import {
  GetAllThreadsCountQuery, GetAllThreadsCountQueryVariables,
} from '@frontend/app/queries/types/GetAllThreadsCountQuery';

export function useGetAllThreadsCount(filter?: ThreadFilterInput) {
  const {
    loading,
    data: { count } = {},
    error,
    refetch,
  } = useQuery<GetAllThreadsCountQuery, GetAllThreadsCountQueryVariables>(
    GET_ALL_THREADS_COUNT_QUERY,
    {
      variables: {
        filter,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    count,
    error,
    refetch,
  };
}
