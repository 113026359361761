import { isEmpty } from 'lodash';

export const willShowSPv2 = (socialAccount) => (
  !isEmpty(socialAccount) && (
    socialAccount.network_identifier === 'instagram'
    || socialAccount.network_identifier === 'pinterest'
    || socialAccount.network_identifier === 'youtube'
    || socialAccount.network_identifier === 'facebook'
    || socialAccount.network_identifier === 'twitter'
    || socialAccount.network_identifier === 'blog'
    || socialAccount.network_identifier === 'tiktok'
  )
);
