import gql from 'graphql-tag';
import { REQUIREMENT_FRAGMENT } from '@frontend/app/queries/fragments/RequirementFragment';

export const REQUIREMENT_DETAIL = gql`
  query RequirementDetailQuery($id: Int!) {
    requirement: requirementById(id: $id) {
      ...RequirementFragment
      deliverables {
        id
        type
        payload
      }
    }
  }
  ${REQUIREMENT_FRAGMENT}
`;
