import React, { useEffect, useRef, useState } from 'react';
import type { ReactNode, FunctionComponent } from 'react';

import { ChevronsLeftIcon, ChevronsRightIcon } from '@revfluence/fresh-icons/solid/esm';
import { debounce } from 'lodash';
import { Button } from '@/shadcn/components/ui/button';
import useBreakpoint from '@/shadcn/hooks/useBreakpoint';
import { ContentWithSideMenuContext } from './ContentWithSideMenuContext';

interface ContentWithSideMenuProps {
  menu: ReactNode;
  main: ReactNode;
}

const ContentWithSideMenu: FunctionComponent<ContentWithSideMenuProps> = ({ main, menu }) => {
  const ref = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [headerBlockEnd, setHeaderBlockEnd] = useState(0);
  const [roundedSideMenu, setRoundedSideMenu] = useState(false);
  const { lg: isLgOrAbove } = useBreakpoint();

  useEffect(() => {
    // Autocollapsing of sidebar menu
    if (isLgOrAbove) {
      setMenuCollapsed(false);
    } else {
      setMenuCollapsed(true);
    }
  }, [isLgOrAbove]);

  useEffect(() => {
    const header = document.getElementById('refresh-app-header');

    const observer = new ResizeObserver(
      debounce((entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          const headerBlockEndPixels = entry.target.getBoundingClientRect().bottom;
          setHeaderBlockEnd(headerBlockEndPixels);
        }
      }, 500),
    );
    if (header) observer.observe(header);

    return () => {
      if (header) observer.unobserve(header);
      observer.disconnect();
    };
  }, []);

  return (
    <ContentWithSideMenuContext.Provider value={{ setRoundedSideMenu, setSideMenuCollapsed: setMenuCollapsed }}>
      <div className="flex bg-background relative" style={{ height: `calc(100vh - ${headerBlockEnd}px)` }} ref={ref}>
        <div
          ref={sidebarRef}
          className={`${
            menuCollapsed ? 'w-0' : 'w-full sm:w-60'
          } transition-[width] ease-in-out absolute z-[190] lg:relative lg:z-auto overflow-x-visible grow-0 shrink-0 bg-primary h-full group`}
        >
          <Button
            className={`transition-[top] ease-in-out absolute ${roundedSideMenu ? 'top-12' : 'top-4'} size-6 p-0 z-10 ${
              menuCollapsed
                ? '-right-6 rounded-e-lg rounded-s-none'
                : 'right-0 rounded-s-lg rounded-e-none invisible group-hover:visible'
            }`}
            onClick={() => setMenuCollapsed((prev) => !prev)}
          >
            {menuCollapsed ? <ChevronsRightIcon className="size-3" /> : <ChevronsLeftIcon className="size-3" />}
          </Button>

          <aside
            className={`lg:overflow-hidden bg-background h-full ${
              roundedSideMenu ? 'rounded-tr-xl' : ''
            } transition-[border-radius] ease-in-out`}
          >
            {menu}
          </aside>
        </div>
        <div className="grow" style={{ width: `calc( 100% - ${sidebarRef.current?.getBoundingClientRect().width ?? 0}px)` }}>{main}</div>
      </div>
    </ContentWithSideMenuContext.Provider>
  );
};

export default ContentWithSideMenu;
