import 'css-chunk:src/components/widgets/CreatorTile/CreatorTile.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1s2z4_157",
  "justify-content-space-between": "_justify-content-space-between_1s2z4_161",
  "tabular-nums": "_tabular-nums_1s2z4_165",
  "CreatorTile": "_CreatorTile_1s2z4_169",
  "media": "_media_1s2z4_213",
  "invite": "_invite_1s2z4_216",
  "content": "_content_1s2z4_219",
  "ineligible": "_ineligible_1s2z4_224",
  "image": "_image_1s2z4_248",
  "moreImages": "_moreImages_1s2z4_255",
  "active": "_active_1s2z4_269",
  "imageItem": "_imageItem_1s2z4_273",
  "details": "_details_1s2z4_292",
  "userInfo": "_userInfo_1s2z4_300",
  "avatar": "_avatar_1s2z4_306",
  "avatarImage": "_avatarImage_1s2z4_313",
  "source": "_source_1s2z4_318",
  "sourceImage": "_sourceImage_1s2z4_330",
  "info": "_info_1s2z4_334",
  "name": "_name_1s2z4_344",
  "ambIcon": "_ambIcon_1s2z4_358",
  "date": "_date_1s2z4_363",
  "admin": "_admin_1s2z4_379",
  "favoriteButton": "_favoriteButton_1s2z4_393",
  "infoIcon": "_infoIcon_1s2z4_399",
  "programsList": "_programsList_1s2z4_403",
  "metrics": "_metrics_1s2z4_406",
  "reach": "_reach_1s2z4_413",
  "darkMode": "_darkMode_1s2z4_419",
  "amount": "_amount_1s2z4_422",
  "engagement": "_engagement_1s2z4_425",
  "unit": "_unit_1s2z4_428",
  "reviews": "_reviews_1s2z4_432",
  "approveSection": "_approveSection_1s2z4_435",
  "thumbsUpIcon": "_thumbsUpIcon_1s2z4_443",
  "moreReviewUsers": "_moreReviewUsers_1s2z4_449",
  "feedbackSection": "_feedbackSection_1s2z4_454",
  "feedbackItem": "_feedbackItem_1s2z4_461",
  "feedbackTitle": "_feedbackTitle_1s2z4_464",
  "showMore": "_showMore_1s2z4_468",
  "ineligibleReason": "_ineligibleReason_1s2z4_479",
  "reason": "_reason_1s2z4_491",
  "link": "_link_1s2z4_494",
  "inviteWithFavorite": "_inviteWithFavorite_1s2z4_512",
  "favoriteWidget": "_favoriteWidget_1s2z4_516",
  "show": "_show_1s2z4_468",
  "actions": "_actions_1s2z4_523",
  "Tooltip": "_Tooltip_1s2z4_529",
  "MoreUsersTooltip": "_MoreUsersTooltip_1s2z4_533",
  "infoTooltip": "_infoTooltip_1s2z4_539"
};