import {
 first, includes, isEmpty, map,
} from 'lodash';

import { TNetworkIdentifier, countries, languages } from '@components';
import { CreatorSearchVersion, ICreatorSearchFilters, IImageSearch, SearchTermType } from '../models';
import { ENGAGEMENT_LIMITS_IQ_VALUES, REACH_LIMITS_IQ_VALUES } from '../TextSearchFilters/EngagementSection';

const getDefaultCountryFilters = (acceptedCountryCodes: string[]) =>
  map(acceptedCountryCodes, (code) => ({
      label: countries[code],
      value: code,
    }));

const getDefaultLocationFilters = (placeIds: any[]) =>
  map(placeIds, (place_id) => ({
      label: place_id.label,
      value: place_id.value,
    }));

const getDefaultLanguageFilters = (acceptedLanguageCodes: string[]) =>
  map(acceptedLanguageCodes, (code) => ({
      label: languages[code],
      value: code,
    }));

export const getInitialTextSearchFilters = (
  options: {
    networkTypes: string[];
    placesIds: any[];
    acceptedCountryCodes: string[];
    acceptedLanguageCodes: string[];
    version: CreatorSearchVersion;
  },
): ICreatorSearchFilters => {
  const channel: TNetworkIdentifier = includes(options.networkTypes, 'instagram')
    ? 'instagram'
    : !isEmpty(options.networkTypes)
      ? (first(options.networkTypes) as TNetworkIdentifier)
      : null;

  return {
    query: '',
    termType: SearchTermType.Keywords,
    isIQSearch: options.version === CreatorSearchVersion.v2,
    creatorDemographics: {
      locations: getDefaultLocationFilters(options.placesIds),
      countries: getDefaultCountryFilters(options.acceptedCountryCodes),
      languages: getDefaultLanguageFilters(options.acceptedLanguageCodes),
    },
    engagement: {
      engagementRange: ENGAGEMENT_LIMITS_IQ_VALUES,
      reachRange: REACH_LIMITS_IQ_VALUES,
    },
    network: {
      channel,
    },
    other: {
      hideInvited: true,
      canContact: true,
    },
    contact: {
      canContactEmail: true,
      canContactInstagramDM: false,
    },
    relationships: {
      projectIds: [],
      groupIds: [],
    }
  };
};

export const getInitialImageSearchFilters = (
  countryCodes: string[],
): IImageSearch => ({
    imageURL: null,
    countries: getDefaultCountryFilters(countryCodes),
    hideInvited: true,
    hideInvitedToggle: true,
    isLoadingImage: false,
    contentLists: [],
    contentByListId: {},
    isLoadingContentLists: false,
    hasContentListError: false,
    isLoadingContent: false,
    hasContentError: false,
    clientId: null,
    selfServeExperiment: false,
  });
