import * as React from 'react';
import { Form } from 'antd';

import { IOption } from '@components';
import { Select } from '@revfluence/fresh';

import { FormItemStatus } from './types';
import styles from './FormSelect.scss';

const { Option } = Select;
const { useState } = React;

interface IProps {
  label: string;
  value: string,
  name: string;
  placeholder: string;
  options: IOption[];
  isRequired?: boolean;
  onFieldChange: (name: string, value: string) => void;
}

const FormSelect: React.FC<IProps> = ({
  label,
  value,
  name,
  placeholder,
  options,
  isRequired = false,
  onFieldChange,
}: IProps) => {
  const [inputStatus, setInputStatus] = useState<FormItemStatus>('');

  const handleChange = (value: string) => {
    if (isRequired) {
      setInputStatus(value.length === 0 ? 'error' : '');
    }
    onFieldChange(name, value);
  };

  return (
    <Form.Item label={label} validateStatus={inputStatus}>
      <Select
        className={styles.FormSelect}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      >
        {options.map((option) => (
          <Option value={option.value} key={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default React.memo(FormSelect);
