import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const MemberIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12.4839 12.5161C9.70972 12.5161 7.45166 10.129 7.45166 7.22581C7.45166 4.32258 9.70972 2 12.4839 2C15.2581 2 17.5162 4.3871 17.5162 7.29032C17.5162 10.1935 15.2581 12.5161 12.4839 12.5161ZM12.4839 3.80645C10.6775 3.80645 9.25811 5.35484 9.25811 7.29032C9.25811 9.22581 10.6775 10.7742 12.4839 10.7742C14.2904 10.7742 15.7097 9.22581 15.7097 7.29032C15.7097 5.35484 14.2259 3.80645 12.4839 3.80645Z" />
    <path d="M19.0645 22.0004H5.90323C5.3871 22.0004 5 21.6133 5 21.1616C5 17.9358 6.41935 15.3552 8.93548 14.0649C9.3871 13.8068 9.90323 14.0004 10.1613 14.452C10.4194 14.9036 10.2258 15.4197 9.77419 15.6778C8.09677 16.581 7.06452 18.2584 6.80645 20.3229H18.0968C17.7742 18.0649 16.7419 16.3874 15.0645 15.5487C14.6129 15.3552 14.4194 14.7745 14.6774 14.3874C14.871 13.9358 15.4516 13.7423 15.8387 14.0004C18.3548 15.2262 19.8387 17.8068 19.9677 21.1616C19.9677 21.4197 19.9032 21.6133 19.7097 21.8068C19.5161 21.9358 19.3226 22.0004 19.0645 22.0004Z" />
  </SvgIcon>
));

MemberIcon.displayName = 'MemberIcon';
