import { ApolloClient } from '@apollo/client';

import { MEMBER_PAGE_LOCAL_STATE } from '@frontend/app/queries';
import {
  MemberPageLocalStateQuery,
} from '@frontend/app/queries/types/MemberPageLocalStateQuery';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateCache = (client: ApolloClient<any>, newData: Partial<MemberPageLocalStateQuery>) => {
  const cache = client.readQuery<MemberPageLocalStateQuery>({
    query: MEMBER_PAGE_LOCAL_STATE,
  });

  // Do nothing. Cache doesn't exist.
  if (!cache) {
    return;
  }

  const newCache: MemberPageLocalStateQuery = {
    ...cache,
    ...newData,
  };

  client.writeQuery<MemberPageLocalStateQuery>({
    query: MEMBER_PAGE_LOCAL_STATE,
    data: newCache,
  });
};

export const useUpdateMemberPageLocalState = () => updateCache;
