export interface IApplicationFormFields {
  memberFieldSchemas: IMemberFieldSchemas[];
  dbColumns: IDbColumnsMeta[];
}

export interface IDbColumnsMeta {
  name: string;
  required?: boolean;
  label?: string;
  order?: number;
}

export interface IMemberFieldSchemas {
  schemaId: number;
  required?: boolean;
  label?: string;
  order?: number;
}
