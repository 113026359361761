import 'css-chunk:src/app/containers/EmailSettings/MessageTemplatesPage/MessageTemplatesPage.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_e7jyh_157",
  "justify-content-space-between": "_justify-content-space-between_e7jyh_161",
  "tabular-nums": "_tabular-nums_e7jyh_165",
  "MessageTemplatesPage": "_MessageTemplatesPage_e7jyh_178",
  "header": "_header_e7jyh_178",
  "container": "_container_e7jyh_188",
  "right": "_right_e7jyh_188",
  "EmailComposer": "_EmailComposer_e7jyh_188",
  "section": "_section_e7jyh_188",
  "titleSection": "_titleSection_e7jyh_188",
  "action": "_action_e7jyh_188",
  "label": "_label_e7jyh_198",
  "pageHeader": "_pageHeader_e7jyh_423",
  "backButton": "_backButton_e7jyh_430",
  "leftArrowIcon": "_leftArrowIcon_e7jyh_436",
  "image": "_image_e7jyh_440",
  "title": "_title_e7jyh_188",
  "searchInput": "_searchInput_e7jyh_459",
  "left": "_left_e7jyh_436",
  "templateRow": "_templateRow_e7jyh_478",
  "selected": "_selected_e7jyh_483",
  "program": "_program_e7jyh_504",
  "programIcon": "_programIcon_e7jyh_511",
  "subject": "_subject_e7jyh_520",
  "actions": "_actions_e7jyh_539",
  "edit": "_edit_e7jyh_554",
  "cancel": "_cancel_e7jyh_572",
  "primaryActions": "_primaryActions_e7jyh_581",
  "deleteButton": "_deleteButton_e7jyh_591",
  "signature": "_signature_e7jyh_605",
  "externalLinkIcon": "_externalLinkIcon_e7jyh_611",
  "loadSpinner": "_loadSpinner_e7jyh_615",
  "show": "_show_e7jyh_1"
};