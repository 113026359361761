import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';

import { GetOrdersByIds_orders } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetOrdersByIds';
import {
    Tag, Tooltip,
} from '@revfluence/fresh';

import { getDataSource } from '../../utils/getDataSource';
import { AvatarWithName, OrderTable } from '../OrderTable/OrderTable';
import { isValidHttpUrl } from '../../utils/isValidHttpsUrl';

interface IProps {
    onApprove: () => void;
    onReject: () => void;
    orders: GetOrdersByIds_orders[];
    loading: boolean;
}
interface ReviewRow {
    key: string;
    name?: string;
    avatar?: string;
    quantity?: number;
    productName?: string;
    sku?: string;
    preferences?: string;
    productUrl?: string;
    address?: string;
    country?: string;
}

const columns: ColumnProps<ReviewRow>[] = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (_text: string, record: ReviewRow) => (
            record.name ? <AvatarWithName name={record.name} avatar={record.avatar} /> : null
        ),

    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Product Name',
        dataIndex: 'productName',
        key: 'productName',
        render: (text: string, record: ReviewRow) => (
          <>
            <Tag>{text}</Tag>
            {(record?.key?.indexOf('parent') > -1 && record.quantity > 1)
                    && <Tag>{`+ ${record.quantity - 1}`}</Tag>}
          </>
        ),

    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        render: (_: string, record: ReviewRow) => (
          <>
            {(record?.key?.indexOf('parent') > -1 || record.quantity === 1) && (
            <Tooltip title={record?.address}>
              <span>{record?.address}</span>
            </Tooltip>
                )}
          </>
        ),

    },
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        render: (_: string, record: ReviewRow) => (
          <>
            {(record?.key?.indexOf('parent') > -1 || record.quantity === 1) && (
            <span>{record?.country}</span>
                )}
          </>
        ),

    },
    {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',

    },
    {
        title: 'Preferences',
        dataIndex: 'preferences',
        key: 'preferences',
    },

    {
        title: 'Product URL',
        dataIndex: 'productUrl',
        key: 'productUrl',
        render: (text: string) => {
            const hasHttpPrefix = /^https?:\/\//;

            const formattedUrl = hasHttpPrefix.test(text) ? text : `https://${text}`;

            return (
                isValidHttpUrl(formattedUrl)
                    ? <a href={formattedUrl} target="_blank" rel="noopener noreferrer">{text}</a>
                    : text
            );
        },
    },
];

export const OrderReview = ({
    onApprove, onReject, orders, loading,
}: IProps) => {
    const dataSource = getDataSource(orders);
    return (
      <OrderTable
        ordersSelected={orders.length}
        onPrimaryAction={onApprove}
        onSecondaryAction={onReject}
        primaryActionText="Accept & Download CSV"
        secondaryActionText="Reject Request"
        title="Review Order Request"
        description="Approve or reject order requests these selected members."
        columns={columns}
        dataSource={dataSource}
        rowKey="key"
        loading={loading}
      />
    );
};
