import * as React from 'react';
import dateStyle from '@frontend/app/components/DateFilter/DateMenu.scss';
import moment from 'moment';
import { format } from 'date-fns';

import {
  Button, DatePicker, Dropdown, Menu, Space,
} from '@revfluence/fresh';
import { AngleDownIcon } from '@revfluence/fresh-icons/solid/esm';
import { getCustomCompareRange } from '@frontend/app/containers/HomePageMetrics/components/DateSelect';
import { RangePickerProps } from 'antd/lib/date-picker';
import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import styles from './DateFilter.scss';

function getCustomDateRangePicker(dateRange: [Date?, Date?]): RangePickerProps['value'] {
  const startDate = dateRange[0];
  const endDate = dateRange[1];
  const startDateMoment = startDate ? moment(startDate) : undefined;
  const endDateMoment = endDate ? moment(endDate) : undefined;
  return [startDateMoment, endDateMoment];
}

export interface DateFilterOption {
  label: string;
  value: {
    dateRange: [Date?, Date?],
    compareRange: [Date?, Date?]
  };
}

export type DateFilterProps = {
  clientStartDate?: Date;
  defaultLabel?: string;
  options: DateFilterOption[];
  onFilterChange?: (options: DateFilterOption[]) => void;
  isCheckbox: boolean;
  dateRange?: [Date?, Date?];
  isCustomDate: boolean;
  refreshUi?: boolean;
};

interface DateFilterOptions {
  options: DateFilterOption[];
  onChange: (option: DateFilterOption, checked: boolean) => void;
  clientStartDate?: Date;
  dateRange?: [Date?, Date?];
  isCustomDate: boolean;
}

const { useState, useEffect } = React;
function DateFilterDropdown({
  options, onChange, clientStartDate,
  dateRange, isCustomDate,
}: DateFilterOptions) {
  const [open, setOpen] = useState(false);
  const onChangeCustomRange = React.useCallback((range) => {
    const customStartDate = range[0].toDate();
    const customEndDate = range[1].toDate();
    const customDateOption: DateFilterOption = {
      label: `${format(customStartDate, 'MMM dd, yyyy')} - ${format(customEndDate, 'MMM dd, yyyy')}`,
      value: {
        dateRange: [customStartDate, customEndDate],
        compareRange: getCustomCompareRange(clientStartDate, [customStartDate, customEndDate]),
      },
    };
    onChange(customDateOption, true);
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, open]);

  const customPickerValue: RangePickerProps['value'] = isCustomDate ? getCustomDateRangePicker(dateRange) : [undefined, undefined];
  const disabledDate: RangePickerProps['disabledDate'] = (currentDate) => currentDate > moment();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Menu className={styles.ProjectDropdown}>
        {options.map((option, index) => {
          const { label } = option;
          return (
            <Menu.Item key={index}>
              <div
                key={label}
                onClick={() => {
                  onChange(option, true);
                }}
              >
                {label}
              </div>
            </Menu.Item>
          );
        })}
        <Menu.Item className={dateStyle.customRangeDatePicker} key="Custom">
          <div className={dateStyle.customRangeWrapper} onClick={(e) => e.stopPropagation()}>
            <DatePicker.RangePicker
              disabledDate={disabledDate}
              allowClear
              open={open}
              onChange={onChangeCustomRange}
              onOpenChange={(open) => setOpen(open)}
              value={customPickerValue}
            />
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
}

function DateFilter({
  options,
  clientStartDate,
  defaultLabel = 'All Data',
  onFilterChange,
  dateRange,
  isCustomDate,
  refreshUi,
}: DateFilterProps) {
  const [label, setLabel] = useState(defaultLabel);
  const [selection, setSelection] = useState<DateFilterOption[]>([]);

  const onSelectionChange = (option: DateFilterOption, checked: boolean) => {
    if (checked) {
      setSelection([option]);
    } else {
      setSelection(selection.filter((opt) => option.label !== opt.label));
    }
  };

  useEffect(() => {
    if (typeof onFilterChange === 'function') {
      onFilterChange(selection);
    }

    if (selection.length === 0) {
      return setLabel(defaultLabel);
    }

    if (selection.length === 1) {
      return setLabel(selection[0].label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);
  return (
    <Dropdown
      overlay={(
        <DateFilterDropdown
          options={options}
          onChange={onSelectionChange}
          clientStartDate={clientStartDate}
          dateRange={dateRange}
          isCustomDate={isCustomDate}
        />
      )}
      trigger={['click']}
    >
      {
        refreshUi ? (
          <ShadCnBtn
            size="sm"
            variant="outlineHeader"
            className="font-inter"
          >
            {label}
            <AngleDownIcon className="ml-2" />
          </ShadCnBtn>

        ) : (
          <Button>
            <Space>
              {label}
              <AngleDownIcon />
            </Space>
          </Button>
        )
      }

    </Dropdown>
  );
}

export default DateFilter;
