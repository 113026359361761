import moment from 'moment';

import { DateRangeOptions, IDateRange } from './types';

export const getDateLabelFilterRange = (
  dateRange: IDateRange | undefined,
  earliestDate: Date | null,
): string => {
  if (!dateRange) {
    if (!earliestDate) {
      return 'up to Today';
    }
    return `${moment(earliestDate).format('MMM D, YYYY')} - Today`;
  }
  const startDate = moment(dateRange.startDate).format('MMM D, YYYY');
  let endDate = moment(dateRange.endDate).format('MMM D, YYYY');
  if (!dateRange.endDate) {
    endDate = 'Today';
  }
  const dateRangeLabel = `${startDate} - ${endDate}`;
  return dateRangeLabel;
};

export const getDateLabelFilter = (
  current: DateRangeOptions,
  dateRange: IDateRange | undefined,
  earliestDate: Date | null,
  refreshUi: boolean,
): string => {
  const range = getDateLabelFilterRange(dateRange, earliestDate);
  switch (current) {
    case DateRangeOptions.CUSTOM:
      return range;
    default:
      return `${current} ${refreshUi ? '' : `(${range})`}`;
  }
};
