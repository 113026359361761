import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';

import { CloseIcon } from '@components';
import { useHover } from '@frontend/app/hooks/common';
import { IContent } from '@frontend/app/hooks';
import { Typography } from 'antd';
import { FileOutlined, FileImageOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { ResourceType } from '@frontend/app/types/globalTypes';
import styles from './AttachmentItem.scss';

const { Text } = Typography;

import { isTooLarge } from './utils';

interface IProps {
  content: IContent;
  resourceType?: ResourceType,
  editable?: boolean;
  onDelete?(): void;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const AttachmentItem: React.FC<IProps> = React.memo((props) => {
  const { content, resourceType } = props;
  const [hovered, listeners] = useHover();

  const openContentLink = () => {
    window.open(content.fileUrl, '_blank');
  };
  const onDeleteClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    props.onDelete();
  };

  return (
    <div
      className={cx(styles.AttachmentItem, props.className, {
        [styles.hovered]: hovered,
        [styles.warning]: isTooLarge(content.size, resourceType),
      })}
      onClick={openContentLink}
      {...listeners}
    >
      {content.type === 'application' && (
        <FileOutlined className={styles.type} />
      )}
      {content.type === 'video' && (
        <VideoCameraOutlined className={styles.type} />
      )}
      {content.type === 'image' && (
        <FileImageOutlined className={styles.type} />
      )}
      <Text className={styles.name} ellipsis>{content.name}</Text>
      <div className={styles.size}>{numeral(content.size).format('0.0 b')}</div>
      {props.editable && (
        <>
          <div
            className={cx(styles.progress, {
              [styles.completed]: content?.progress?.percentage === 100,
            })}
            style={{
              transform: `scaleX(${content?.progress?.percentage / 100})`,
            }}
          />
          <div
            className={styles.deleteButton}
            onClick={onDeleteClick}
          >
            <CloseIcon
              className={styles.icon}
              size={10}
            />
          </div>
        </>
      )}
    </div>
  );
});

AttachmentItem.defaultProps = {
  className: null,
  editable: true,
  onDelete: () => undefined,
};

AttachmentItem.displayName = 'AttachmentItem';
