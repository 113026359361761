import 'css-chunk:src/components/widgets/SocialProfile/Card/EngagementRateCard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_zgg15_157",
  "justify-content-space-between": "_justify-content-space-between_zgg15_161",
  "tabular-nums": "_tabular-nums_zgg15_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_zgg15_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_zgg15_166",
  "Card": "_Card_zgg15_170",
  "tooltip": "_tooltip_zgg15_306",
  "cardHeader": "_cardHeader_zgg15_320",
  "tabs": "_tabs_zgg15_331",
  "tabButton": "_tabButton_zgg15_335",
  "activeTab": "_activeTab_zgg15_354",
  "separator": "_separator_zgg15_358",
  "cardTab": "_cardTab_zgg15_365",
  "cardTitle": "_cardTitle_zgg15_373",
  "visible": "_visible_zgg15_381",
  "show": "_show_zgg15_1",
  "noData": "_noData_zgg15_386",
  "EngagementRateCard": "_EngagementRateCard_zgg15_396",
  "betaBadge": "_betaBadge_zgg15_396",
  "footer": "_footer_zgg15_404",
  "organicTab": "_organicTab_zgg15_416",
  "sponsoredTab": "_sponsoredTab_zgg15_417",
  "chart": "_chart_zgg15_420",
  "barInfo": "_barInfo_zgg15_423",
  "image": "_image_zgg15_430",
  "aiqLogo": "_aiqLogo_zgg15_438",
  "username": "_username_zgg15_442",
  "value": "_value_zgg15_450",
  "percentage": "_percentage_zgg15_454",
  "description": "_description_zgg15_457",
  "higher": "_higher_zgg15_460",
  "lower": "_lower_zgg15_460"
};