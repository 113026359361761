import { logger } from '@common';
import { TState } from '../types';

interface IAction {
  error: Error;
  type: 'SET ERROR';
}
export type TSetErrorAction = Readonly<IAction>;

export const setError = (state: TState, action: TSetErrorAction): TState => {
  logger.error(action.error);
  return {
    ...state,
    error: new Error('Oops! Something went wrong. Please refresh or try again later.'),
  };
};
