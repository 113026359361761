export interface IAPIResponse {
  status: {
    code: number;
  };
}

export interface IAPIErrorResponse extends IAPIResponse {
  status: {
    code: number;
    error_msg: string;
  };
}

export const DEFAULT_GENERIC_ERROR_MSG = 'An unexpected error occurred. Please contact us at help@aspireiq.com if the issue persists!';

export function isErrorResponse(resp: IAPIResponse): resp is IAPIErrorResponse {
  return resp.status.code !== 200;
}

export function getErrorMessageFromResponse(
  resp: IAPIErrorResponse,
  defaultMessage: string = DEFAULT_GENERIC_ERROR_MSG,
) {
  if (resp && resp.status && resp.status.error_msg) {
    return resp.status.error_msg;
  }
  return defaultMessage;
}
