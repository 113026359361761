import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_ALL_RESOURCE_THREADS_COUNT_QUERY } from '@frontend/app/queries';
import {
  GetAllResourceThreadsCountQuery,
  GetAllResourceThreadsCountQueryVariables,
} from '@frontend/app/queries/types/GetAllResourceThreadsCountQuery';

type IOptions = QueryHookOptions<GetAllResourceThreadsCountQuery, GetAllResourceThreadsCountQueryVariables>;

export function useGetAllResourceThreadsCount(options: IOptions = {}) {
  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<GetAllResourceThreadsCountQuery, GetAllResourceThreadsCountQueryVariables>(
    GET_ALL_RESOURCE_THREADS_COUNT_QUERY,
    {
      ...options,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    result: data && data.result,
    error,
    refetch,
  };
}
