/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1lctr_157 {
  display: flex;
}

._justify-content-space-between_1lctr_161 {
  justify-content: space-between;
}

._tabular-nums_1lctr_165 {
  font-variant-numeric: tabular-nums;
}

._CreatorList_1lctr_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1lctr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._CreatorList_1lctr_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._CreatorList_1lctr_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._CreatorList_1lctr_169 ::-webkit-scrollbar-track, ._CreatorList_1lctr_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._CreatorList_1lctr_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._CreatorList_1lctr_169 {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  width: 100%;
  height: 100%;
}
._CreatorList_1lctr_169._tableMode_1lctr_243 {
  flex: 1;
  min-height: 0;
}
._CreatorList_1lctr_169 ._gridView_1lctr_247,
._CreatorList_1lctr_169 ._tableView_1lctr_248 {
  flex: 1;
}
._CreatorList_1lctr_169 ._tableView_1lctr_248 {
  overflow: hidden;
}
._CreatorList_1lctr_169 ._loadSpinner_1lctr_254 {
  position: absolute;
  top: 40%;
  left: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 999;
}
._CreatorList_1lctr_169 ._pagination_1lctr_262 {
  padding-top: 16px;
  padding-bottom: 16px;
  margin: auto auto 0;
  display: flex;
  flex-direction: row;
}
._CreatorList_1lctr_169 ._header_1lctr_269 {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorList_1lctr_169 ._header_1lctr_269 ._title_1lctr_276 {
  font-size: 40px;
  font-weight: bold;
}
._CreatorList_1lctr_169 ._header_1lctr_269 ._mode_1lctr_280 {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  border: 1px solid #f2f1f1;
  border-radius: 100px;
}
._CreatorList_1lctr_169 ._header_1lctr_269 ._mode_1lctr_280 ._modeItem_1lctr_289 {
  height: 37px;
  width: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #eff5f9;
  border-right: 1px solid #f2f1f1;
  will-change: color, background-color, border-color;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._CreatorList_1lctr_169 ._header_1lctr_269 ._mode_1lctr_280 ._modeItem_1lctr_289:last-child {
  border-right: none;
}
._CreatorList_1lctr_169 ._header_1lctr_269 ._mode_1lctr_280 ._modeItem_1lctr_289:hover {
  background-color: #f2f1f1;
}
._CreatorList_1lctr_169 ._header_1lctr_269 ._mode_1lctr_280 ._modeItem_1lctr_289._active_1lctr_308 {
  color: white;
  border-color: #3996e0;
  background-color: #3996e0;
}
._CreatorList_1lctr_169 ._progress_1lctr_313 {
  margin: 30px 0;
  width: 500px;
}
._CreatorList_1lctr_169 ._notice_1lctr_317 {
  margin: 40px auto;
  width: 650px;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}

._SelectFooter_1lctr_326 ._addList_1lctr_326 {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._SelectFooter_1lctr_326 ._addList_1lctr_326:hover {
  color: #3996e0;
  background-color: #f0f4f8;
}
._SelectFooter_1lctr_326 ._addList_1lctr_326 ._addIconWrapper_1lctr_340 {
  margin-right: 10px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 100px;
  background-color: white;
}
._SelectFooter_1lctr_326 ._addList_1lctr_326 ._addIcon_1lctr_340 {
  transform: rotate(45deg);
  color: #3996e0;
}
._SelectFooter_1lctr_326 ._editList_1lctr_356 {
  padding: 0 10px;
}
._SelectFooter_1lctr_326 ._editList_1lctr_356 ._back_1lctr_359 {
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: bold;
  font-size: 15px;
  color: #1a1818;
}
._SelectFooter_1lctr_326 ._editList_1lctr_356 ._back_1lctr_359 ._goBackButton_1lctr_372 {
  position: absolute;
  left: 0;
  top: 1px;
}
._SelectFooter_1lctr_326 ._editList_1lctr_356 ._editContent_1lctr_377 {
  max-width: 280px;
  line-height: 20px;
}
._SelectFooter_1lctr_326 ._editList_1lctr_356 ._editContent_1lctr_377 ._listName_1lctr_381 {
  font-weight: 600;
}
._SelectFooter_1lctr_326 ._editList_1lctr_356 ._button_1lctr_384 {
  margin-top: 10px;
}