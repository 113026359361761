import * as React from 'react';
import { map } from 'lodash';
import {
  Space, Typography, Button, Tooltip,
} from '@revfluence/fresh';

import { MemberInput } from '@frontend/app/types/globalTypes';
import { MemberFieldSchemasBySectionsQuery_sections_permissions } from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';

import styles from './Permissions.scss';

const { Text } = Typography;

type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_permissions;

interface Props {
  member: MemberInput;
  schemas?: MemberFieldSchema[];
  hasInstagramInsights?: boolean;
  onRequestInsights?: () => void;
}

const Permissions: React.FC<Props> = ({
  member, schemas = [], hasInstagramInsights, onRequestInsights,
}) => (member ? (
  <Space direction="vertical">
    {map(schemas, (schema) => {
      const value = schema.name === 'Instagram Insights'
        ? hasInstagramInsights
        : member.fields[schema.id];

      return (
        <Space key={schema.id} direction="vertical" size={0}>
          <Tooltip title={schema.metaData?.tooltip}>
            <Text className={styles.label} type="secondary">{schema.name}</Text>
          </Tooltip>
          <Space direction="horizontal">
            {schema.type === 'BOOLEAN' ? (
              <Text className={styles.value}>{value ? 'Yes' : 'No'}</Text>
            ) : (
              <Text className={styles.value}>{value || 0}</Text>
            )}
            {schema.name === 'Instagram Insights' && !value && (
              <Button type="primary" size="small" onClick={onRequestInsights}>
                Request Insights
              </Button>
            )}
          </Space>
        </Space>
      );
    })}
  </Space>
) : null);

export default Permissions;
