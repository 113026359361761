import React, { useMemo } from 'react';
import { CustomChartTooltipContent, MainMetric, WidgetContainer } from '@frontend/app/refresh-components';
import { Bar, BarChart, XAxis } from 'recharts';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { CoinIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardProjectTabSummaryQuery } from '../hooks';

const chartConfig = {
  briefs: {
    label: 'Spend in Briefs',
    color: 'hsl(var(--chart-1))',
  },
  commissions: {
    label: 'Spend on Commission',
    color: 'hsl(var(--chart-2))',
  },
  others: {
    label: 'Others',
    color: 'hsl(var(--chart-3))',
  },
} as ChartConfig;

interface TotalSpentProjectWiseWidgetProps {
  projectId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function TotalSpentProjectWiseWidget(props: TotalSpentProjectWiseWidgetProps) {
  const { projectId, fiscalYear, className } = props;
  const isParemetersAbsent = !projectId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardProjectTabSummaryQuery({
    variables: {
      programId: projectId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
    fetchPolicy: 'no-cache',
  });
  const chartData = useMemo(
    () => [
      {
        source: 'Spend in Briefs',
        value: budgetSummary?.summary?.spentBudget?.spentInBriefs || 0,
        fill: 'var(--color-briefs)',
      },
      {
        source: 'Spend on Commission',
        value: budgetSummary?.summary?.spentBudget?.spentOnCommission || 0,
        fill: 'var(--color-commissions)',
      },
      {
        source: 'Others',
        value: budgetSummary?.summary?.spentBudget?.spentOnOthers || 0,
        fill: 'var(--color-others)',
      },
    ],
    [budgetSummary],
  );
  const showEmptyState = useMemo(() => {
    if (
      !budgetSummary?.summary?.spentBudget?.spentInBriefs
      && !budgetSummary?.summary?.spentBudget?.spentOnCommission
      && !budgetSummary?.summary?.spentBudget?.spentOnOthers
    ) {
      return true;
    }
    return false;
  }, [budgetSummary]);
  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col">
      <MainMetric
        className="flex-1"
        value={budgetSummary?.summary?.spentBudget?.spentAmount || 0}
        size="medium"
        metricType="currency"
        subHeading="of Total Budget"
        icon={(
          <Badge variant="outline" className="text-green-700">
            {`${budgetSummary?.summary?.spentBudget?.spentPercent || 0}%`}
          </Badge>
        )}
      />
      {!showEmptyState && (
        <ChartContainer config={chartConfig} className="h-[150px] w-full">
          <BarChart data={chartData} margin={{ top: 0, left: 0 }}>
            <XAxis dataKey="source" tickLine={false} tickMargin={10} axisLine={false} />
            <ChartTooltip cursor={false} content={<CustomChartTooltipContent valueType="currency" />} />
            <Bar dataKey="value" strokeWidth={1} radius={4} barSize={35} />
            <ChartLegend content={<ChartLegendContent />} />
          </BarChart>
        </ChartContainer>
      )}
    </div>
  );

  return (
    <WidgetContainer
      widgetTitle="Total Spent in Budget"
      className={`${className}`}
      bgColor="bg-[#D1A34F]"
      widgetIcon={<CoinIcon className="text-white" fontSize={20} />}
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
