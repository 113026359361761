import * as React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as qs from 'qs';
import { Tabs } from 'antd';

import {
  OverlaySpinner, LazyImage,
} from '@components';

import { EventName } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import {
  useParsedRouterSearch,
  useFeatureFlagVerbiage,
  useClientFeatureEnabled,
  useDeleteCommunityById,
} from '@frontend/app/hooks';
import { useEventContext } from '@frontend/app/context/EventContext';

import { ClientFeature } from '@frontend/app/constants';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { isUndefined } from 'lodash';
import { CommunityBasics } from './CommunityBasics/CommunityBasics';
import { CommunityIdentity } from './CommunityIdentity/CommunityIdentity';
import { Programs } from './Programs/Programs';

import { CommunityIdentityContextProvider } from './context/CommunityIdentityContext';
import { useCommunityByIdQuery } from './hooks/useCommunityByIdQuery';
import { useSaveCommunityMutation } from './hooks/useSaveCommunityMutation';

import styles from './AddOrEditCommunity.scss';

const defaultCommunityLogo = 'https://storage.googleapis.com/aspirex-static-files/default_community.png';

const { TabPane } = Tabs;
const {
  useEffect, useState, useCallback, useMemo,
} = React;
interface IRouteParams {
  communityId?: string;
}

const AddOrEditCommunity: React.FC = React.memo(() => {
  const { tab = 'settings', ...searchQuery } = useParsedRouterSearch();
  const { communityId } = useParams<IRouteParams>();
  const history = useHistory();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>(tab);

  const verbiage = useFeatureFlagVerbiage();
  const workflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const isApplicantReview = useClientFeatureEnabled(ClientFeature.APPLICANT_REVIEW);
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const isNew = useMemo(() => location.pathname === '/communities/new', [location]);
  const isAllContacts = useMemo(() => location.pathname === '/communities/all_contacts/settings', [location]);

  const {
    loading,
    error,
    data: {
      community = null,
    } = {},
  } = useCommunityByIdQuery(parseInt(communityId, 10), {
    skip: !communityId,
  });

  const splashImageUrl = useMemo(() => {
    if (community) {
      return community.splashImageUrl;
    } else if (isNew) {
      return defaultCommunityLogo;
    }
  }, [community, isNew]);

  const {
    showError,
    showMessage,
  } = useMessagingContext();

  const addEvent = useEventContext();

  const [saveCommunity, {
    loading: saving,
    error: savedCommunityError,
  }] = useSaveCommunityMutation({
    onCompleted(data) {
      if (isNew) {
        showMessage({
          type: 'success',
          content: `Successfully created ${verbiage.community}`,
        });

        addEvent(EventName.CreateCommunity, {});

        history.push({
          pathname: '/member_table',
          search: qs.stringify({
            communityId: data.community.id,
          }),
        });
      } else {
        addEvent(EventName.EditCommunity, {});

        showMessage({
          type: 'success',
          content: 'Successfully saved changes',
        });
      }
    },
  });

  const [deleteCommunity, { loading: isDeletingCommunity, error: deletingCommunityError }] = useDeleteCommunityById({
    onCompleted() {
      goBack();
    },
  });

  const handleDeleteCommunity = (id: number) => {
    deleteCommunity({
      variables: {
        id,
      },
    });
  };

  useEffect(() => {
    if (deletingCommunityError) {
      showError(deletingCommunityError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingCommunityError]);

  useEffect(() => {
    if (savedCommunityError) {
      showError(savedCommunityError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedCommunityError]);

  const handleTabChange = (newTab: string) => {
    const newSearch = {
      ...searchQuery,
      tab: newTab,
    };

    history.replace({
      ...location,
      search: qs.stringify(newSearch),
    });
  };

  useEffect(() => {
    if (isAllContacts) {
      setSelectedTab('fields');
    } else {
      setSelectedTab(tab);
    }
  }, [tab, isAllContacts]);

  const handleSave = (communityInput) => {
    saveCommunity({
      variables: {
        community: communityInput,
      },
    });
  };

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  if (loading || isUndefined(refreshUi)) {
    return <OverlaySpinner />;
  }

  if (error) {
    return (
      <div className={styles.AddOrEditCommunity}>
        Error!
        {' '}
        {error.message}
      </div>
    );
  }

  return (
    <>
      {refreshUi && (
      <AppHeader
        imgIconUrl={splashImageUrl}
        title={`${isNew ? `New ${verbiage.Community}` : ''}${isAllContacts ? 'All Contacts' : ''}${community ? community.title : ''}`}
      />
)}

      <div className={styles.AddOrEditCommunity}>
        <div className={styles.content}>
          {!refreshUi && (
          <div className={styles.header}>
            {splashImageUrl && (
            <LazyImage
              key={splashImageUrl}
              src={splashImageUrl}
              fallbackSrc={defaultCommunityLogo}
              className={styles.splashImage}
            />
            )}
            {isNew && `New ${verbiage.Community}`}
            {isAllContacts && 'All Contacts'}
            {!!community && community.title}
          </div>
)}
          <Tabs activeKey={selectedTab} onChange={handleTabChange}>
            {!isAllContacts && (
              <TabPane tab="Settings" key="settings">
                <CommunityBasics
                  className={styles.basics}
                  community={community}
                  placeholderSplashUrl={defaultCommunityLogo}
                  isSaving={saving}
                  onClickSave={handleSave}
                  isNew={isNew}
                  handleDeleteCommunity={handleDeleteCommunity}
                  isDeletingCommunity={isDeletingCommunity}
                  isApplicantReview={isApplicantReview}
                />
              </TabPane>
            )}
            <TabPane tab="Fields" key="fields">
              <CommunityIdentityContextProvider>
                <CommunityIdentity />
              </CommunityIdentityContextProvider>
            </TabPane>
            {!isAllContacts && workflowEnabled === false && (
              <TabPane tab={verbiage.Programs} key="programs" disabled={isNew}>
                <Programs community={community} />
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </>
  );
});

export default AddOrEditCommunity;
