import * as React from 'react';
import { Typography } from '@revfluence/fresh';

const { Text } = Typography;

export const ShopifyInstructions: React.FC = () => (
  <div style={{ marginBottom: '24px' }}>
    <Text>
      Define your offer with an Offer Name and Description. These elements convey your promotion's essence and value.
    </Text>
  </div>
);
