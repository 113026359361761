import React, { useEffect, useState } from 'react';
import {
    Button, Modal, Space, Typography,
} from '@revfluence/fresh';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { useHistory, useParams } from 'react-router-dom';
import { SHOPIFY_APP_ID } from '@frontend/app/constants/applicationIds';
import { useGetResources } from '@frontend/app/hooks';
import styles from './ShopifyReauthenticateModal.scss';

const { Title, Text } = Typography;

const scopeMessageMap = {
    read_products: '"read products"',
    write_orders: '"write orders"',
    read_inventory: '"read inventory"',
};

const requiresPFAScopes = (workletSpecUri?: string) => (
        workletSpecUri === 'SendProductWorkletSpecification');

const getErrorMessage = (availableScopes: string) => {
    if (!availableScopes) {
        return 'Please connect your Shopify store to continue';
    }

    const missingScopes = ['read_products', 'read_inventory']
        .filter((scope) => !availableScopes.includes(scope))
        .map((scope) => scopeMessageMap[scope])
        .join(', ');

    if (!missingScopes) {
        return '';
    }

    return `The following scopes are missing: ${missingScopes}. Please reconnect your Shopify store and provide these scopes to continue.`;
};

export const ShopifyReauthenticateModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { workletSpecUri } = useParams<{ workletSpecUri: string }>();

    const isPFAScopesRequired = requiresPFAScopes(workletSpecUri);

    const { activeResources, loading } = useGetResources({
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            resourceType: 'shopify',
            ignoreRequestContextUserId: true,
        },
    });

    const errorMessage = getErrorMessage(activeResources?.[0]?.scopes);

    useEffect(() => {
        setIsOpen(!loading && isPFAScopesRequired && !!errorMessage);
    }, [errorMessage, isPFAScopesRequired, loading, workletSpecUri]);

    const history = useHistory();

    return (
      <Modal
        open={isOpen}
        className={styles.ShopifyReauthenticateModal}
        footer={null}
        onCancel={() => setIsOpen(false)}
        closable
      >
        <Space direction="vertical">
          <Space align="center">
            <TriangleExclamationIcon fontSize={24} className={styles.errorIcon} />
            <Title level={5}>Reconnect your Shopify Store</Title>
          </Space>
          <Text>{errorMessage}</Text>
        </Space>
        <Button type="primary" onClick={() => history.push(`/settings/${SHOPIFY_APP_ID}`)} className={styles.actionButton}>
          Reconnect
        </Button>
      </Modal>
    );
};
