import * as React from 'react';
import {
  isEmpty, pick, findIndex, map, pickBy,
} from 'lodash';

import {
 TLocationType, countries, regions, Select, RadioGroup,
} from '@components';

import { FilterSection } from '../FilterSection';
import AudienceDropdown from './AudienceDropdown';

import { IAudienceLocationFilters, IAudienceLocationOptions, ILocation } from '../models';

import styles from './AudienceLocationSection.scss';
import { LocationInputIQData } from '@frontend/components/widgets';
import { PlaceTypeOption, TPlacesOptions } from '@frontend/components/widgets/PlacesInput/types';
import { TNetworkIdentifier } from '@frontend/components/common';

const { useCallback, useMemo, useEffect } = React;

const LocationTypes: { [key: string]: TLocationType } = Object.freeze({
  COUNTRY: 'Country',
  REGION: 'Region',
});

const LocationTypeOptions = [
  { value: LocationTypes.COUNTRY, label: 'Country' },
  { value: LocationTypes.REGION, label: 'Region' },
];

const TopCountries = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
  { value: 'AU', label: 'Australia' },
  { value: 'GB', label: 'United Kingdom' },
];

const DEFAULT_AUDIENCE_DEMOGRAPHICS_COUNTRY_CHOICES = [
  { value: '', label: 'None' },
  ...TopCountries,
  { value: '', label: '---' },
  ...map(
    pickBy(countries, (_, code) => findIndex(TopCountries, { value: code }) < 0),
    (label, code) => ({ label, value: code }),
  ),
];

const DEFAULT_AUDIENCE_DEMOGRAPHICS_REGION_CHOICES = [
  { label: 'None', value: '' },
  ...map(regions, (label, code) => ({ label, value: code })),
];

export const AUDIENCE_LOCATION_FILTERS = [
  'locationType',
  'countryRegion',
  'countryRegionLessThan',
  'countryRegionPercentage',
  'audienceLocations',
];

const pickFilters = (obj: any) => pick(obj, AUDIENCE_LOCATION_FILTERS);

interface IProps extends IAudienceLocationFilters, IAudienceLocationOptions {
  onChange(locationFilters: IAudienceLocationFilters);
  hide?: boolean;
  hidePercentage?: boolean;
  showIQDataFilters?: boolean;
  network?: TNetworkIdentifier;
}

const AudienceLocationSection: React.FunctionComponent<IProps> = (props) => {
  const {
    audienceLocations,
    countryChoices,
    countryRegion,
    countryRegionLessThan,
    countryRegionPercentage,
    hide,
    hidePercentage,
    locationType,
    onChange,
    regionChoices,
    showIQDataFilters,
    network,
  } = props;

  const countryRegionIndex = useMemo(() => {
    const choices = locationType === LocationTypes.COUNTRY ? countryChoices : regionChoices;
    return findIndex(choices, { value: countryRegion });
  }, [locationType, countryChoices, regionChoices, countryRegion]);

  const locationTypeIndex = useMemo(
    () => findIndex(LocationTypeOptions, { value: locationType }),
    [locationType],
  );

  const handleChangeCountryRegion = useCallback(
    (countryRegion: string) => {
      const newFilters = {
        ...pickFilters(props),
        countryRegion,
      };
      onChange(newFilters);
    },
    [onChange, props],
  );

  useEffect(() => {
    if (countryRegionIndex < 0 && countryRegion) {
      handleChangeCountryRegion('');
    }
  }, [countryRegionIndex, countryRegion, handleChangeCountryRegion]);

  const handleChangeLocationType = (locationType: TLocationType) => {
    const newFilters = {
      ...pickFilters(props),
      locationType,
    };
    onChange(newFilters);
  };

  const handleChangeAudiencePercentage = (percentage: number) => {
    const newFilters = {
      ...pickFilters(props),
      countryRegionPercentage: percentage,
    };
    onChange(newFilters);
  };

  const handleChangeAudienceLessThan = (lessThan: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      countryRegionLessThan: lessThan,
    };
    onChange(newFilters);
  };

  const handleChangeLocations = (locations: ILocation[]) => {
    const newFilters = {
      ...pickFilters(props),
      audienceLocations: locations,
    };
    onChange(newFilters);
  }

  const iqLocationOptionsForNetwork = useMemo(() => {
    switch(network) {
      case 'instagram':
        return {
          types: [PlaceTypeOption.Country, PlaceTypeOption.State, PlaceTypeOption.City],
        } as TPlacesOptions;
      case 'tiktok':
        return {
          types: [PlaceTypeOption.Country],
        } as TPlacesOptions;
      case 'youtube':
        return {
          types: [PlaceTypeOption.Country],
        } as TPlacesOptions;
      default:
        return {
          types: [],
        } as TPlacesOptions;
    };
  }, [network]);

  const renderLocationContent = () => {
    if (showIQDataFilters) {
      return (
        <>
          <LocationInputIQData
            onChange={handleChangeLocations}
            locations={audienceLocations}
            options={iqLocationOptionsForNetwork}
          />
          {hidePercentage ? null : (
            <AudienceDropdown
              onChangePercentage={handleChangeAudiencePercentage}
              onChangeLessThan={handleChangeAudienceLessThan}
              selectedPercentage={countryRegionPercentage}
              lessThan={countryRegionLessThan}
              showIQDataFilters={showIQDataFilters}
              disabled={isEmpty(audienceLocations)}
            />
          )}
        </>
      )
    }

    return (
      <>
        <RadioGroup
          alignment="horizontal"
          onChange={handleChangeLocationType}
          options={LocationTypeOptions}
          selectedIndex={locationTypeIndex}
        />
        <div className={styles.selectWrapper}>
          {locationType === LocationTypes.COUNTRY ? (
            <Select
              options={countryChoices}
              onChange={handleChangeCountryRegion}
              selectedIndex={countryRegionIndex}
            />
          ) : (
            <Select
              options={regionChoices}
              onChange={handleChangeCountryRegion}
              selectedIndex={countryRegionIndex}
            />
          )}
        </div>
        {hidePercentage ? null : (
          <AudienceDropdown
            onChangePercentage={handleChangeAudiencePercentage}
            onChangeLessThan={handleChangeAudienceLessThan}
            selectedPercentage={countryRegionPercentage}
            lessThan={countryRegionLessThan}
            disabled={!countryRegion}
          />
        )}
      </>
    )
  }

  return (
    <FilterSection header="Location" hide={hide} isSubsection>
      {renderLocationContent()}
    </FilterSection>
  );
};

AudienceLocationSection.defaultProps = {
  countryChoices: DEFAULT_AUDIENCE_DEMOGRAPHICS_COUNTRY_CHOICES,
  regionChoices: DEFAULT_AUDIENCE_DEMOGRAPHICS_REGION_CHOICES,
  locationType: LocationTypes.COUNTRY,
  countryRegionLessThan: false,
  countryRegionPercentage: 20,
  countryRegion: '',
  hidePercentage: false,
};

export default AudienceLocationSection;
