import { useMutation, MutationHookOptions } from '@apollo/client';
import { DataProxy } from 'apollo-cache';

import { pullAllBy } from 'lodash';
import { GetCommunitiesQuery } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { GET_COMMUNITIES_QUERY } from '@frontend/app/queries';

import { DELETE_COMMUNITY_BY_ID } from '../queries';
import {
  DeleteCommunityByIdMutation,
  DeleteCommunityByIdMutationVariables,
} from '../queries/types/DeleteCommunityByIdMutation';

type IOptions = MutationHookOptions<DeleteCommunityByIdMutation, DeleteCommunityByIdMutationVariables>;

export const useDeleteCommunityById = (options: IOptions = {}) => {
  const mutation = useMutation<DeleteCommunityByIdMutation, DeleteCommunityByIdMutationVariables>(DELETE_COMMUNITY_BY_ID, options);

  const [deleteCommunityById] = mutation;

  const handleDeleteCommunity: typeof deleteCommunityById = (...args) => {
    const [thisOptions = {}] = args;

    args[0] = {
      ...thisOptions,
      update: (...updateArgs) => {
        if (thisOptions.update) {
          thisOptions.update(...updateArgs);
        }

        const [store, result] = updateArgs;

        const {
          data: {
            success,
          },
        } = result;

        if (!success) {
          return;
        }

        const {
          id,
        } = thisOptions.variables || options.variables;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateCache(store as any, id);
      },
    };

    return deleteCommunityById(...args);
  };

  mutation[0] = handleDeleteCommunity;

  return mutation;
};

const updateCache = (store: DataProxy, communityId: number) => {
  const cache = store.readQuery<GetCommunitiesQuery>({ query: GET_COMMUNITIES_QUERY });

  // Do nothing. Cache doesn't exist.
  if (!cache) {
    return;
  }

  cache.communities = [...cache.communities];

  pullAllBy(cache.communities, [{ id: communityId }], 'id');

  store.writeQuery({
    query: GET_COMMUNITIES_QUERY,
    data: { ...cache },
  });
};
