import gql from 'graphql-tag';

export const GET_PROJECTS_FOR_MEMBERS_QUERY = gql`
  query GetProjectsForMembers($memberIds: [Int!]!, $status: String!) {
    programs: programsForMembers(memberIds: $memberIds, status: $status) {
      id
      title
    }
  }
`;
