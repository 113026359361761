import * as React from 'react';
import cx from 'classnames';
import {
  size, map, isEmpty, chain, debounce, trim, isNil, omit,
} from 'lodash';

import { Popover, Input } from 'antd';
import { IconButton, AlertIcon } from '@components';
import { WandIcon } from '@frontend/app/components';

import { useFuzzySearchByKeys, useGetVariables } from '@frontend/app/hooks';

import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';

import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useRef } from 'react';
import { replaceVariableLabels } from './decorator/components/utils';

const {
  useState, useMemo, useCallback,
} = React;
import styles from './VariableReplacement.module.scss';

interface IProps {
  invalidMemberIdsForField: {
    [field: string]: number[];
  };
  additionalVariables?: { [key: string]: { label: string } };
  onInsertVariable(label: string, value: number): void;

  showProgramVariables?: boolean;

  className?: string;
  children?: React.ReactNode;
}

/**
 * @type {React.FC}
 */
export const VariableReplacement: React.FC<IProps> = React.memo((props) => {
  const {
    invalidMemberIdsForField,
    additionalVariables,
  } = props;

  // TODO - the PFA using proxy so need to pass via context
  const pfaContextVariables = useProductFulfillmentContext()?.variables;
  const pfaVariables = useProductFulfillmentContext()?.usePfaContextVariables && pfaContextVariables;
  const { variables: queryVariables } = useGetVariables({ skip: !!pfaVariables });

  const [visible, setVisible] = useState(false);
  const _variables = pfaVariables || queryVariables;

  const variables = useMemo(() => {
    const allVariables = {
      ..._variables?.global,
      ..._variables?.member,
      ...omit(_variables?.user, 'USER_ID'),
      ...(isNil(additionalVariables) ? {} : additionalVariables),
      ...(props.showProgramVariables ? _variables?.program : {}),
      ...(props.showProgramVariables ? _variables?.client : {}),
    };

    return chain(allVariables)
      .map((v, k) => ({ field: k, value: v }))
      .map((variable) => (
        {
          ...variable,
          label: replaceVariableLabels(variable.value.label),
          ...{ value: { label: replaceVariableLabels(variable.value.label) } },
        }))
      .sortBy(({ value }) => value.label)
      .value();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_variables, additionalVariables]);

  const [searchResult, setSearchResult] = useState(variables);
  const searchDataSource = useFuzzySearchByKeys(variables, ['label']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((text: string) => {
      if (trim(text)) {
        const result = searchDataSource(trim(text));
        setSearchResult(result);
      } else {
        setSearchResult(variables);
      }
    }, 300),
    [searchDataSource],
  );

  useEffect(() => {
    setSearchResult(variables);
  }, [variables]);

  const renderSearch = () => (
    <Input
      className={styles.searchInput}
      allowClear
      placeholder="Search for personalization"
      prefix={<SearchOutlined />}
      onChange={(e) => { debouncedSearch(e.target.value); }}
      // autoFocus attr works only once as the whole popover is always mounted even while its invisble
      autoFocus
    />
  );

  const [focusedIndex, setFocusedIndex] = useState(-1);

  const resultContainer = useRef<HTMLDivElement>(null);
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { key } = e;
    let nextIndexCount = 0;
    setIsKeyboardNavigation(true);
    if (key === 'ArrowDown') {
      nextIndexCount = (focusedIndex + 1) % searchResult.length;
    } else if (key === 'ArrowUp') {
      nextIndexCount = (focusedIndex + searchResult.length - 1) % searchResult.length;
    } else if (key === 'Escape') {
      setVisible(false);
    } else if (key === 'Enter') {
      if (focusedIndex !== -1 && focusedIndex < searchResult.length) {
        // Handle Enter key, select the item at the focused index
        const selectedVariable = searchResult[focusedIndex];
        setVisible(false);
        props.onInsertVariable(selectedVariable.value.label, selectedVariable.field);
      }
    }

    setFocusedIndex(nextIndexCount);
    // Scroll the focused item into view
    if (resultContainer.current && resultContainer.current.children[focusedIndex]) {
      resultContainer.current.children[focusedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };
  const handlePopoverClose = (visible: boolean) => {
    if (!visible) {
      // Reset the isKeyboardNavigation state when the popover is closed
      setIsKeyboardNavigation(false);
    }
  };

  useEffect(() => {
    if (!resultContainer.current) return;
    resultContainer.current.scrollIntoView({
      block: 'end',
    });
  }, [focusedIndex]);

  return (
    <div
      tabIndex={1}
      onKeyDown={handleKeyDown}
    >
      <Popover
        content={(
          <>
            <div className={styles.PopoverContent}>
              <div className={styles.StickytTop}>
                <div className={styles.title}>Select a personalization variable</div>
                {renderSearch()}
              </div>
              {searchResult.length ? (
                map(searchResult, (variable, index) => (
                  <div
                    key={variable.field}
                    className={cx(styles.item, {
                      [styles.focused]: index === focusedIndex, [styles['keyboard-navigation']]: isKeyboardNavigation,
                    })}
                    onClick={() => {
                      setVisible(false);
                      props.onInsertVariable(variable.value.label, variable.field);
                    }}
                    ref={index === focusedIndex ? resultContainer : null}
                  >
                    {variable.value.label}
                    {!isEmpty(invalidMemberIdsForField[variable.field]) && (
                      <div className={styles.error}>
                        <AlertIcon size={18} className={styles.icon} />
                        {`${size(invalidMemberIdsForField[variable.field])} missing`}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className={styles.empty}>
                  <div className={styles.flexColumn}>
                    <IconButton
                      className={cx(props.className, styles.NoEvent)}
                      icon={<WandIcon className={styles.icon} size={50} />}
                    />
                    <div className={styles.noVar}>No variables found</div>
                    <div className={styles.textEmptyStat}>Try modifying your search term.</div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        trigger="click"
        placement="topRight"
        visible={visible}
        onVisibleChange={setVisible}
        // @ts-ignore
        onClose={handlePopoverClose}
      >
        {props.children}
      </Popover>
    </div>
  );
});

VariableReplacement.defaultProps = {
  showProgramVariables: false,
  className: null,
};
