import * as React from 'react';
import { isFunction, map } from 'lodash';
import { Select } from 'antd';

import styles from './BooleanEqual.scss';

interface IProps {
  value: boolean;
  onChange?(value: boolean);
}

const BOOLEAN_OPTIONS = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const BooleanEqual: React.FC<IProps> = (props) => {
  const {
    value,
    onChange,
  } = props;

  /**
   * value will be converted to string for antd <Select>
   * and converted back to boolean for the onChange handler
   */
  return (
    <Select<string>
      className={styles.BooleanEqual}
      value={`${value}`}
      onChange={(val) => {
        if (isFunction(onChange)) {
          onChange(val === 'true');
        }
      }}
    >
      {map(BOOLEAN_OPTIONS, (option) => (
        <Select.Option
          key={`BooleanEqual-${option.value}`}
          value={option.value}
        >
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};
