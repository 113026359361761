import * as React from 'react';
import {
 isNil, reduce, find, unionBy,
} from 'lodash';

import {
  IToastRefHandles,
  TNetworkIdentifier,
  EditableHover,
  Toast,
  SocialProfileOverlay,
  TableCellEditInput,
  ISocialAccount,
} from '@components';
import { logger } from '@common';
import { EllipsisLabel } from '@frontend/app/components';

import { IMember, useFetchSocialAccount } from '@frontend/app/hooks';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { formatUsername } from '@frontend/app/utils/socialAccount';
import { useDemoAccountFlag } from '@frontend/app/hooks/useDemoAccountFlag';
import { useMemberFieldPartitions } from '@frontend/app/containers/MemberDetail/MemberInfo/MemberOverview/hooks';
import { getSocialAccountLink } from '@frontend/utils';

const {
 useCallback,
 useEffect,
 useMemo,
 useRef,
 useState,
} = React;

import styles from './SocialProfileCell.scss';

interface IProps {
  username: string;
  network: TNetworkIdentifier;
  member?: IMember;

  // Cell editing
  isEditable?: boolean;
  isEditing: boolean;
  onToggleIsEditing();
  onChangeValue(name: string);
  refetchProjectCounts?: () => Promise<unknown>;
}

const isEmptyValue = (value: unknown) => isNil(value) || value === '';

export const SocialProfileCell: React.FC<IProps> = React.memo(((props) => {
  const {
    isEditable = true,
    isEditing,
    network,
    onChangeValue,
    onToggleIsEditing,
    refetchProjectCounts,
    username,
    member,
  } = props;

  const { shouldShowDemoFeatures } = useDemoAccountFlag();

  const toastRef = useRef<IToastRefHandles>();

  const [show, setShow] = useState(false);

  const {
    sortedSocialSchemasByName,
  } = useMemberFieldPartitions({});

  const memberSocialAccounts = useMemo(() => reduce(sortedSocialSchemasByName, (accounts, schema) => {
      const username = member?.[schema.id];
      if (username) {
        accounts.push({
          id: schema.id,
          username,
          network_identifier: schema.name?.toLowerCase(),
        });
      }
      return accounts;
    }, []), [sortedSocialSchemasByName, member]);

  const {
    socialAccounts,
    loading,
  } = useFetchSocialAccount({ accounts: show ? memberSocialAccounts : undefined });

  const allSocialAccounts = useMemo(() =>
    // add accounts that Backend faild to load (just as a placeholder)
     unionBy(socialAccounts, memberSocialAccounts, (acc) => acc?.network_identifier + acc?.username),
   [socialAccounts, memberSocialAccounts]);

  // Providing spv2 with only `username` and `network_identifier`
  // causes SPv2 to show a message that "no data was pulled for user"
  const accountPlaceholder = { username, network_identifier: network };
  const account = loading
    ? undefined
    : find(socialAccounts, { network_identifier: network }) || accountPlaceholder;

  const defaultUsername = useMemo(() => {
    try {
      return formatUsername({ network, username });
    } catch (error) {
      return username;
    }
  }, [username, network]);

  const [usernameState, setUsernameState] = useState<string>(defaultUsername);

  useEffect(() => {
    setUsernameState(defaultUsername);
  }, [defaultUsername]);

  const handleBlur = (newUsername: string) => {
    try {
      newUsername = formatUsername({ network, username: newUsername, shouldValidateUsername: true });

      setUsernameState(newUsername);
      onToggleIsEditing();

      if (
        (newUsername === usernameState)
          || (isEmptyValue(newUsername) && isEmptyValue(usernameState))
      ) {
        return;
      }

      onChangeValue(newUsername);
    } catch (error) {
      const errorMessage = error.message;
      const toast = toastRef.current;

      setUsernameState('');

      logger.error(errorMessage);

      if (toast) {
        toast.showMessage({
          content: errorMessage,
          type: 'error',
        });
      }
    }
  };

  const onClickUsername = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (shouldShowDemoFeatures) {
      window.open(getSocialAccountLink(network, username));
      return;
    }

    setShow(true);
  }, [shouldShowDemoFeatures, network, username]);

  const renderUsername = () => (
    <div
      className={styles.username}
      onClick={onClickUsername}
    >
      <EllipsisLabel
        tooltipPlacement="top"
        showTooltip
      >
        {usernameState}
      </EllipsisLabel>
    </div>
  );

  const renderEditableHover = () => (
    isEditable
      ? (
        <EditableHover
          onClick={() => {
            onToggleIsEditing();
          }}
        >
          {renderUsername()}
        </EditableHover>
      )
      : renderUsername()
  );

  const renderEditableCell = () => (
    isEditing
      ? (
        <TableCellEditInput
          defaultValue={usernameState}
          onBlur={handleBlur}
          onCancel={onToggleIsEditing}
        />
      )
      : renderEditableHover()
  );

  return (
    <div
      className={styles.SocialProfileCell}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {renderEditableCell()}
      {show && (
        <SocialProfileOverlay
          apiEndpoint={backendServerApiEndpoint()}
          show={show}
          socialAccount={account as ISocialAccount}
          memberSocialAccounts={allSocialAccounts}
          hideActions
          showCreateFeatures={false}
          showSimilarCreators={false}
          onRequestClose={() => {
            setShow(false);
          }}
          refetchProjectCounts={refetchProjectCounts}
          // NOTE: Only the applicants section uses pagination currently. Please refactor for other needs.
          enablePagination={window.location.href.indexOf('/applicants') > -1}
        />
      )}
      <Toast ref={toastRef} />
    </div>
  );
}));
