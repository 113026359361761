import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import { unionBy } from 'lodash';

import { RESET_MESSAGE_TEMPLATE_MUTATION, MESSAGE_TEMPLATES_QUERY } from '@frontend/app/queries';
import {
  ResetMessageTemplateMutation,
  ResetMessageTemplateMutationVariables,
} from '@frontend/app/queries/types/ResetMessageTemplateMutation';
import { MessageTemplatesQuery } from '../queries/types/MessageTemplatesQuery';

type IOptions = MutationHookOptions<ResetMessageTemplateMutation, ResetMessageTemplateMutationVariables>;

export const useResetMessageTemplateMutation = (options: IOptions = {}) => (
  useMutation<ResetMessageTemplateMutation, ResetMessageTemplateMutationVariables>(RESET_MESSAGE_TEMPLATE_MUTATION, {
    update(cache, { data: { template } }) {
      const data = cache.readQuery<MessageTemplatesQuery>({ query: MESSAGE_TEMPLATES_QUERY });
      // Do nothing. Cache doesn't exist.
      if (!data) {
        return;
      }
      const existingTemplates: { templates } = data;
      cache.writeQuery({
        query: MESSAGE_TEMPLATES_QUERY,
        data: { templates: unionBy([template], existingTemplates.templates, 'id') },
      });
    },
    ...options,
  })
);
