import { IPaymentSources } from '@frontend/applications/PaymentsApp/models';
import { ActionType, TState } from '../types';

interface IAction {
  paymentSource: IPaymentSources;
  type: ActionType.SET_PAYMENT_SOURCE;
}
export type TSetPaymentSourceAction = Readonly<IAction>;

export const setPaymentSource = (state: TState, action: TSetPaymentSourceAction): TState => ({
  ...state,
  paymentSource: action.paymentSource,
});
