import { useMutation, MutationHookOptions } from '@apollo/client';

import { CREATE_CONTENT_REVIEW_MUTATION } from '@frontend/app/queries';

import {
  CreateContentReviewMutation,
  CreateContentReviewMutationVariables,
} from '@frontend/app/queries/types/CreateContentReviewMutation';

type IOptions = MutationHookOptions<CreateContentReviewMutation, CreateContentReviewMutationVariables>;

export function useCreateContentReview(options: IOptions = {}) {
  const [createReview, { loading, error }] = useMutation<
    CreateContentReviewMutation,
    CreateContentReviewMutationVariables
  >(CREATE_CONTENT_REVIEW_MUTATION, {
    ...options,
  });

  return {
    createReview,
    loading,
    error,
  };
}
