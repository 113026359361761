import * as React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import { EditIcon } from '@components';
import { EllipsisLabel } from '..';

import styles from './EditableField.scss';

const { useState, useEffect } = React;

interface IProps {
  className?: string;
  editIconSize?: number;
  defaultText?: string;
  allowEmptyString?: string;
  onFinishEditing(fieldValue: string);
}

export const EditableField: React.FunctionComponent<IProps> = (props) => {
  const {
 className, onFinishEditing, defaultText, editIconSize = 15, allowEmptyString = false,
} = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(defaultText);
  const [oldValue, setOldValue] = useState<string>(defaultText);
  const handleChangeTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(e.target.value);
  };
  const finishEditing = () => {
    setIsEditing(false);
    if (!allowEmptyString && editValue === '') {
      setEditValue(oldValue);
    } else if (editValue !== oldValue) {
      onFinishEditing(editValue);
    }
  };
  useEffect(() => {
    setEditValue(defaultText);
  }, [defaultText]);

  const startEditing = () => {
    setIsEditing(true);
    setOldValue(editValue);
  };
  return (!isEditing

    ? (
      <div className={cx(className, styles.readOnlyField)}>
        <div
          className={styles.field}
          onClick={startEditing}
        >
          <EllipsisLabel className={styles.ellipsisLabel}>
            {editValue}
          </EllipsisLabel>
          <div className={styles.editIcon}>
            <EditIcon size={editIconSize} />
          </div>
        </div>
      </div>
)
    : (
      <input
        value={isNil(editValue) ? '' : String(editValue)}
        className={cx(className, styles.input)}
        autoFocus
        spellCheck={false}
        onChange={handleChangeTextInput}
        onKeyPress={(event) => {
        if (event.key === 'Enter') {
          finishEditing();
        }
      }}
        onBlur={finishEditing}
      />
)
  );
};
