import React, { useEffect, useCallback } from 'react';
import {
 Alert, Button, Divider, Radio, Typography, message,
} from '@revfluence/fresh';
import { TActions, TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';

import { ChartPieIcon } from '@revfluence/fresh-icons/regular/esm';
import { AssignPaymentTo, PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import { v4 as uuidv4 } from 'uuid';
import { PlusIcon } from '@revfluence/fresh-icons/solid';
import styles from './BudgetTrackerReview.scss';
import { SectionHeader } from '../SectionHeader';
import { BudgetSpentItem } from '../BudgetSpentItem';

const { Text } = Typography;
interface IProps {
  actions: TActions;
  state: TState;
  programId?: number;
  projectBudgetExist: boolean;
}

export const BudgetTrackerReview: React.FC<IProps> = (props) => {
  const {
 actions, state, programId, projectBudgetExist,
} = props;
  const {
 payments, assignPaymentTo, budgetAccounts, overflowPayments,
} = state;
  const { setBudgetPayments, setAssignPaymentTo } = actions;
  const addSplitPayment = useCallback(() => {
    const assignedBudgetIds = payments.map((payment) => payment.budgetId);
    const availableBudgetIds = budgetAccounts.map((account) => account.id);

    const availableBudgetId = availableBudgetIds.find((id) => !assignedBudgetIds.includes(id));
    const amountAssigned = payments.reduce((sum, payment) => sum + payment.amount, 0);
    if (availableBudgetId) {
      if (state.assignPaymentTo === AssignPaymentTo.Project && !projectBudgetExist) {
        setBudgetPayments([]);
        return;
      }
      setBudgetPayments([
        ...payments,
        {
          id: uuidv4(),
          amount: state.amountPaying - amountAssigned,
          budgetId: availableBudgetId,
          periodId: null,
          budgetGranularityLabel: null,
          fiscalKeySelected: null,
          granularityLabelForPayload: null,
        },
      ]);
    } else if (state.assignPaymentTo === AssignPaymentTo.Other && availableBudgetIds.length) {
      message.error('All the budgets are already added');
    }
  }, [payments, budgetAccounts, setBudgetPayments, state.amountPaying, projectBudgetExist, state.assignPaymentTo]);

  const removeSplitPayment = (id: string) => {
    setBudgetPayments(payments.filter((payment) => payment.id !== id));
  };
  useEffect(() => {
    setBudgetPayments([]);
  }, [state.amountPaying, state.assignPaymentTo, setBudgetPayments]);
  useEffect(() => {
    if (!payments.length && !(state.assignPaymentTo === AssignPaymentTo.Project && !projectBudgetExist)) {
      addSplitPayment();
    }
  }, [payments, addSplitPayment, projectBudgetExist, state.assignPaymentTo]);
  useEffect(() => {
    if (
      [PaymentCreationSource.REASSIGN, PaymentCreationSource.SALES_TRACKING].includes(state.paymentCreationSource)
      && state.assignPaymentTo === AssignPaymentTo.Project
      && budgetAccounts.length
    ) {
      actions.setOverflowBudgetPayments([
        {
          id: uuidv4(),
          amount: 0,
          budgetId: budgetAccounts[0].id,
          periodId: null,
          budgetGranularityLabel: null,
          fiscalKeySelected: null,
          granularityLabelForPayload: null,
        },
      ]);
    } else {
      actions.setOverflowBudgetPayments([]);
    }
  }, [state.paymentCreationSource, actions, budgetAccounts, state.assignPaymentTo]);
  return (
    <div className={styles.budgetTrackerReviewContainer}>
      <SectionHeader
        title="Budget Tracker"
        sectionIcon={<ChartPieIcon />}
        tooltipMessage="Assign payments to a project’s budget or other budget accounts for easy tracking and management."
      />
      <Divider className={styles.divider} />
      <div className={styles.main}>
        {/* Will use later */}
        {/* <Text>You can always update the budget after the payment has been sent.</Text> */}
        <div className={styles.assignPaymentRadio}>
          <label htmlFor="assignPayment">Assign Payment to</label>
          <Radio.Group id="assignPayment" value={assignPaymentTo} onChange={(e) => setAssignPaymentTo(e.target.value)}>
            <Radio value="Project" disabled={state.paymentCreationSource === PaymentCreationSource.MEMBER}>
              Project’s Budget
            </Radio>
            <Radio value="Other">Others</Radio>
          </Radio.Group>
        </div>
        <div className={styles.budgetsSelect}>
          <Text>Select the Budget Account(s) to assign this payment with.</Text>
          {state.assignPaymentTo === AssignPaymentTo.Project && !projectBudgetExist && (
            <Alert message="No budget account connected with project." type="warning" />
          )}
          <div className={styles.budgetsItem}>
            {payments.map((item) => (
              <BudgetSpentItem
                actions={actions}
                state={state}
                key={item.id}
                paymentItem={item}
                removeSplitPayment={removeSplitPayment}
                programId={programId}
              />
            ))}
          </div>
          {state.assignPaymentTo === AssignPaymentTo.Other
            && ![PaymentCreationSource.SALES_TRACKING, PaymentCreationSource.REASSIGN].includes(state.paymentCreationSource) && (
              <Button size="small" icon={<PlusIcon />} className={styles.splitPayment} onClick={addSplitPayment}>
                Split Payment
              </Button>
            )}
        </div>
        {[PaymentCreationSource.REASSIGN, PaymentCreationSource.SALES_TRACKING].includes(state.paymentCreationSource)
          && state.assignPaymentTo === AssignPaymentTo.Project && (
            <div className={styles.overFlowPaymentsContainer}>
              <div className={styles.overFlowPayments}>
                <div>
                  <Text weight="semibold">Overflow Payments</Text>
                </div>
                <Text className={styles.description}>
                  If a member is not part of any project, their payments will be fully assigned to the selected budget
                  below.
                </Text>
              </div>
              <div>
                {overflowPayments.map((item) => (
                  <BudgetSpentItem
                    actions={actions}
                    state={state}
                    key={item.id}
                    paymentItem={item}
                    removeSplitPayment={removeSplitPayment}
                    programId={programId}
                    isOverflowPayment
                  />
                ))}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
