import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';
import { Modal } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ApolloError } from 'apollo-client';
import { Button, RoundAddCircleOutlineIcon } from '@components';

import { useMessagingContext } from '@frontend/hooks';
import { EmptyNotice } from '@frontend/app/components';
import { FieldType } from '@frontend/app/types/Fields';
import { useDeleteMemberField, useSaveMemberFieldSchema } from '@frontend/app/hooks';

import { FieldListItem } from './FieldListItem';

import { useCommunityIdentityContext } from '../hooks/useCommunityIdentityContext';

import styles from './CustomFieldsList.scss';

const { useState } = React;

interface ISchema {
  id?: number | null;
  type: string;
  name: string;
  choices?: string[];
}

interface IProps {
  className?: string;
}

export const CustomFieldsList: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();

  const [showNewField, setShowNewField] = useState<boolean>(false);
  const [savingId, setSavingId] = useState<number>();
  const [addingId, setAddingId] = useState<number>();

  const {
    customSchemas,
    refetchMemberFields,
  } = useCommunityIdentityContext();

  const [
    deleteField,
  ] = useDeleteMemberField({
    onCompleted: () => {
      showSuccessMessage('Field has been deleted');
      setSavingId(undefined);
      refetchMemberFields();
    },
    onError: (error: ApolloError) => {
      setSavingId(undefined);
      showError(error);
    },
  });

  const [saveSchema] = useSaveMemberFieldSchema({
    onCompleted: () => {
      if (savingId === null) {
        setShowNewField(false);
        showSuccessMessage('Your new field has been added!');
      } else {
        showSuccessMessage('Your changes have been saved!');
      }
      setSavingId(undefined);
    },
    onError: (error: ApolloError) => {
      setSavingId(undefined);
      showError(error);
    },
  });

  const handleSaveField = (newField: ISchema) => {
    setSavingId(newField.id);

    saveSchema({
      variables: {
        schema: {
          id: newField.id,
          name: newField.name,
          type: newField.type,
          choices: newField.choices,
        },
      },
    });
  };

  const confirmRemoveField = (field: ISchema) => {
    setSavingId(field.id);

    deleteField({
      variables: {
        id: field.id,
      },
    });
  };

  const handleRemoveField = (field: ISchema, requireConfirmation: boolean = true) => {
    if (!field.id) {
      setShowNewField(false);
      return;
    }

    if (requireConfirmation) {
      Modal.confirm({
        title: 'Are you sure you want to delete this field?',
        content: 'You won’t be able to restore this field later. This field will be removed across all product areas, including Member List, Projects, and Landing page',
        centered: true,
        icon: <QuestionCircleOutlined color="#FAAD14" />,
        okText: 'Delete',
        onOk: () => confirmRemoveField(field),
        okButtonProps: {
          danger: true,
        },
      });
    } else {
      confirmRemoveField(field);
    }
  };

  const handleAddNewField = () => {
    // Only allow one new field at a time.
    if (showNewField) {
      return;
    }

    setShowNewField(true);
  };

  const handleAddExistingSchema = async (originalId: number) => {
    setAddingId(originalId);
    setAddingId(undefined);
    showSuccessMessage('Your new field has been added!');

    if (!originalId) {
      setShowNewField(false);
    }
  };

  const preventDefaultSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const disabled = savingId !== undefined || addingId !== undefined;

  const innerClassNames = {
    name: styles.name,
  };

  return (
    <form className={cx(styles.CustomFieldsList, props.className)} onSubmit={preventDefaultSubmit}>
      <div className={styles.title}>
        Custom Fields
      </div>
      <div className={styles.description}>
        Add fields that will appear in your member list
      </div>
      <div className={styles.label}>
        <Button
          label="New Property"
          className={styles.newBtn}
          icon={<RoundAddCircleOutlineIcon size={18} />}
          onClick={handleAddNewField}
          theme="info"
          disabled={disabled}
        />
      </div>
      {showNewField && (
        <FieldListItem
          key="new-field"
          className={styles.customField}
          field={{ id: null, name: '', type: FieldType.TEXT }}
          disabled={disabled}
          editable
          saving={savingId === null}
          adding={addingId === null}
          onSaveField={handleSaveField}
          onRemoveField={handleRemoveField}
          onAddExistingSchema={handleAddExistingSchema.bind(this, null)}
          defaultShowForm
        />
      )}
      {isEmpty(customSchemas) && (
        <EmptyNotice>
          You have no custom fields
        </EmptyNotice>
      )}
      {map(customSchemas, (field) => (
        <FieldListItem
          key={field.id}
          className={styles.customField}
          field={field}
          innerClassNames={innerClassNames}
          disabled={disabled}
          editable
          saving={savingId === field.id}
          adding={addingId === field.id}
          onSaveField={handleSaveField}
          onRemoveField={handleRemoveField}
          onAddExistingSchema={handleAddExistingSchema.bind(this, field.id)}
        />
      ))}
    </form>
  );
});
