import { useCallback, useState } from 'react';

export const useOfferImage = (
  setIsTouched: (touched: boolean) => void,
) => {
  const [offerImage, setOfferImage] = useState<File | null>(null);

  // handle the image upload
  const handleChangeOfferImage = useCallback(
    (file: File) => {
      setOfferImage(file);
      setIsTouched(true);
    },
    [setIsTouched, setOfferImage],
  );

  return {
    handleChangeOfferImage,
    offerImage,
  };
};
