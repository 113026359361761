/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1nj7e_157 {
  display: flex;
}

._justify-content-space-between_1nj7e_161 {
  justify-content: space-between;
}

._tabular-nums_1nj7e_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1nj7e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Template_1nj7e_178 {
  position: relative;
  overflow-y: overlay;
  height: 100%;
}
@media all and (min--moz-device-pixel-ratio: 0) {
  ._Template_1nj7e_178 {
    overflow-y: auto;
  }
}
@media only screen and (min-width: 20rem) {
  ._Template_1nj7e_178 {
    overflow-x: hidden;
  }
  ._Template_1nj7e_178 ._header_1nj7e_192 {
    display: flex;
    justify-content: space-between;
  }
  ._Template_1nj7e_178 ._logo_1nj7e_196 {
    margin-top: 0.875rem;
  }
  ._Template_1nj7e_178 ._logo_1nj7e_196 {
    width: auto;
    height: 4.375rem;
    margin: 0.75rem auto 0;
    max-width: 60%;
    object-fit: contain;
  }
}
@media only screen and (orientation: landscape) {
  ._Template_1nj7e_178 {
    width: 100%;
  }
  ._Template_1nj7e_178 ._header_1nj7e_192 {
    display: flex;
    justify-content: center;
  }
  ._Template_1nj7e_178 ._logo_1nj7e_196 {
    margin-left: auto !important;
    margin-top: 4rem;
    height: 5rem;
    max-width: none;
  }
}