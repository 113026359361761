import * as React from 'react';
import cx from 'classnames';
import {
 find, first, isEmpty, map,
} from 'lodash';

import { Empty } from 'antd';
import {
 getThumbnailMedia, IContentReview, LoadSpinner, NetworkIcon,
} from '@components';
import { ContentReviewPanel } from '@frontend/app/containers/Projects/ProjectsPage/GroupContentReviewPage';
import { ContentReviewStatus, CreateContentReviewParams, InternalContentReviewStatus } from '@frontend/app/types/globalTypes';
import {
  useClientFeatureEnabled,
 useGetGroupContentReviews, useGetRawContentReviews, useProjectByIdQuery, useRejectRawContentReview,
} from '@frontend/app/hooks';
import { getContentReviewMedia } from '@frontend/app/utils';
import { usePromoteToGCR } from '@frontend/app/hooks/contentReviews/usePromoteContentReview';
import { ClientFeature } from '@frontend/app/constants';
import { getContentDeliverableLabel } from '@frontend/app/utils/getContentDeliverableLabel';
import { ContentSubmitted, TContentSubmittedCard } from './ContentSubmitted';
import { PreReviewPanel } from './PreReviewPanel';

import { getContentReviewStateColor, getContentReviewStateIcon, getContentReviewStateText } from './utils';
import { CONTENT_REVIEW_STATE } from './constants';

import styles from './GroupContentApp.scss';

interface IProps {
  programId: number;
  memberId: number;

  className?: string;
}

const { useMemo, useCallback, useState } = React;
const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

/**
 * @type {React.FC}
 */
export const GroupContentApp: React.FC<IProps> = React.memo(({
  programId, memberId, className,
}) => {
  const [selectedReview, setSelectedReview] = useState<IContentReview>(null);
  const [groupReviewId, setGroupReviewId] = useState<number>(null);
  const { promoteToGCR, loading: creating } = usePromoteToGCR();
  const { rejectRawReview, loading: rejecting } = useRejectRawContentReview();
  const gcrVersionSwitch = useClientFeatureEnabled(ClientFeature.GCR_VERSION_SWITCH);

  const {
    loading: loadingProject,
    data: {
      project = null,
    } = {},
  } = useProjectByIdQuery({
    variables: {
      id: programId,
    },
    skip: !programId,
  });
  const { loading: loadingReviews, reviews, refetch: refetchReviews } = useGetGroupContentReviews({
    variables: {
      memberId,
      programId,
    },
    skip: !programId || !memberId,
  });
  const { loading: loadingRawReviews, reviews: rawReviews, refetch: refetchRawReviews } = useGetRawContentReviews({
    variables: {
      programId,
      memberId,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loading = useMemo(() => loadingProject || loadingReviews || loadingRawReviews, [loadingReviews, loadingRawReviews]);
  const refetch = useCallback(() => {
    refetchReviews();
    refetchRawReviews();
  }, [refetchReviews, refetchRawReviews]);
  const onCloseGroupReviewPanel = useCallback(() => setGroupReviewId(null), []);

  const reviewItems: TContentSubmittedCard[] = useMemo((): TContentSubmittedCard[] => map(rawReviews, (rawReview) => {
    const { product, post } = rawReview;
    const dueDate = product.due_dates?.[0];
    const review = find(reviews, (r) => r.info?.backendServer?.contentReviewId === rawReview.id);
    const promotedToGCR = review?.isGCR || false;
    const canTakeAction = [
      CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_NEW,
      CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_UPLOADED,
      CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_VALIDATING_UPLOAD,
      CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_AMENDED,
    ].includes(rawReview.state);
    const reUploadedContent = review?.status === ContentReviewStatus.ChangesRequested
      && rawReview.state === CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_AMENDED;
    const media = getContentReviewMedia(rawReview);

    return {
      id: rawReview.id,
      title: getContentDeliverableLabel(rawReview),
      icon: <NetworkIcon size={14} identifier={post?.network_identifier || product?.network} />,
      description: dueDate?.submission?.description ? `Due ${dueDate?.submission?.description}` : null,
      media: first(media) || {
        type: 'image',
        url: defaultAvatar,
      },
      status: {
        text: getContentReviewStateText(rawReview.state),
        color: getContentReviewStateColor(rawReview.state),
        icon: getContentReviewStateIcon(rawReview.state),
      },
      canTakeAction,
      onReviewClick: () => {
        if (promotedToGCR) {
          if (reUploadedContent) {
            setSelectedReview(rawReview);
          } else {
            setGroupReviewId(review?.id);
          }
        } else {
          setSelectedReview(rawReview);
        }
      },
      promotedToGCR,
      canPromoteToGCR: !isEmpty(rawReview.content),
      reUploadedContent,
    };
  }), [reviews, rawReviews, setSelectedReview]);
  const onClosePanel = useCallback(() => setSelectedReview(null), []);
  const onSendToGCR = useCallback(async () => {
    if (!selectedReview) {
      return;
    }

    const params: CreateContentReviewParams = {
      memberId,
      programId,
      isGCR: true,
      info: {
        backendServer: {
          projectId: selectedReview.project_id,
          contentReviewId: selectedReview.id,
          postType: selectedReview.product.post_type,
          totalContentCount: selectedReview.product.count,
          displayName: selectedReview.product.product_description,
          nextDueTs: selectedReview.next_deadline,
          contentUrls: [getThumbnailMedia(selectedReview.content.media)],
        },
        raw: selectedReview,
      },
      status: ContentReviewStatus.Pending,
      internalStatus: InternalContentReviewStatus.UNDER_REVIEW,
    };

    await promoteToGCR({
      variables: {
        params,
      },
    }).then(({ data }) => {
      const reviewId = data?.review?.id;
      if (reviewId) {
        setGroupReviewId(reviewId);
      }
    });

    setSelectedReview(null);
    refetch();
  }, [selectedReview, promoteToGCR, programId, memberId, refetch]);

  const onRequestChanges = useCallback(async (comment: string, checkOffs?: boolean[]) => {
    if (!selectedReview) {
      return;
    }

    const { id: contentReviewId, project_id: projectId } = selectedReview;
    await rejectRawReview({
      variables: {
        projectId,
        contentReviewId,
        comment,
        checkOffs,
      },
    });

    setSelectedReview(null);
    refetch();
  }, [selectedReview, rejectRawReview, refetch]);

  return (
    <div className={cx(styles.GroupContentApp, className)}>
      {loading && <LoadSpinner />}
      {!loading && isEmpty(rawReviews) && (
        <Empty />
      )}
      {!loading && !isEmpty(rawReviews) && (
        <>
          {!selectedReview && (
            <ContentSubmitted items={reviewItems} />
          )}
          {selectedReview && (
            <PreReviewPanel
              review={selectedReview}
              onRequestClose={onClosePanel}
              onSendToGCR={onSendToGCR}
              onRequestChanges={onRequestChanges}
              loading={creating || rejecting}
            />
          )}
          <ContentReviewPanel
            project={project}
            reviewId={groupReviewId}
            open={!!groupReviewId}
            onRequestClose={onCloseGroupReviewPanel}
            gcrVersionSwitch={gcrVersionSwitch}
          />
        </>
      )}
    </div>
  );
});

GroupContentApp.defaultProps = {
  className: null,
};

GroupContentApp.displayName = 'GroupContentApp';
