/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w91g_157 {
  display: flex;
}

._justify-content-space-between_1w91g_161 {
  justify-content: space-between;
}

._tabular-nums_1w91g_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w91g_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._editPermissionsModalContent_1w91g_178 ._body_1w91g_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._editPermissionsModalContent_1w91g_178 ._fields_1w91g_188 ._label_1w91g_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._editPermissionsModalContent_1w91g_178 ._note_1w91g_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._editPermissionsModalContent_1w91g_178 {
  text-align: center;
}
._editPermissionsModalContent_1w91g_178 ._body_1w91g_178 {
  padding-bottom: 20px;
}
._editPermissionsModalContent_1w91g_178 ._fields_1w91g_188 {
  max-width: 440px;
  margin: auto;
}
._editPermissionsModalContent_1w91g_178 ._fields_1w91g_188 ._label_1w91g_188 {
  text-align: left;
  padding-bottom: 4px;
}
._editPermissionsModalContent_1w91g_178 ._fields_1w91g_188 ._field_1w91g_188 {
  margin-bottom: 20px;
}
._editPermissionsModalContent_1w91g_178 ._note_1w91g_198 {
  padding-top: 4px;
  text-align: left;
}