/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_12j57_157 {
  display: flex;
}

._justify-content-space-between_12j57_161 {
  justify-content: space-between;
}

._tabular-nums_12j57_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_12j57_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ConversionTracking_12j57_209 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._ConversionTracking_12j57_209 ._codeBlock_12j57_214 {
  width: 100%;
  border-width: thin;
  border-style: solid;
  border-color: #e9e8ea;
  border-radius: 0.25rem;
  background-color: #fdfdfd;
  border-radius: 0.375rem;
  border: 1px solid #FFE58F;
  background: #FFFBE6;
}
._ConversionTracking_12j57_209 ._code_12j57_214 {
  padding: 0.75rem;
  color: #4f4f4f;
  font-size: 0.875rem;
  line-height: 1.5rem;
  word-wrap: break-word;
  word-break: break-all;
}
._ConversionTracking_12j57_209 ._description_12j57_233 {
  color: #4f4f4f;
  font-size: 1rem;
}
._ConversionTracking_12j57_209 ._copySnippet_12j57_237 {
  margin-top: 16px;
  width: 10.125rem;
}