import { TState } from '../types';

interface IAction {
  type: 'RESET MEMBER';
}
export type TResetMemberAction = Readonly<IAction>;

export const resetMember = (state: TState, _: TResetMemberAction): TState => ({
    ...state,
    members: [],
    selectedMembers: [],
  });
