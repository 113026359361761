import * as React from 'react';
import {
 format, startOfToday, startOfYesterday, startOfTomorrow,
} from 'date-fns';
import {
 startsWith, isNil, isEmpty, get, trim, split, slice,
} from 'lodash';

import { IMember, IMessageVariable, IUser } from '@frontend/app/hooks';
import { MessageBackupPlan, BackupType } from '@frontend/app/types/globalTypes';

const dateFormatStr = 'MMMM dd, yyyy';

export function getPreviewValue({
  field, member, variables, backupPlan, user,
}: {
  field: string;
  member: IMember;
  variables: IMessageVariable;
  backupPlan: MessageBackupPlan;
  user?: Partial<IUser>;
}): string {
  if (startsWith(field, 'GLOBAL_')) {
    return getGlobalValue(field);
  } else if (startsWith(field, 'MEMBER_')) {
    return getMemberValue({
      field, member, variables, backupPlan,
    });
  } else if (startsWith(field, 'USER_')) {
    return getUserValue(field, user);
  }

  return getAdditionalValue(field);
}
function getAdditionalValue(field: string) {
  switch (field) {
    case 'LINK_TO_TERMS': {
      return 'View Brief';
    }
    default: {
      return null;
    }
  }
}

function getGlobalValue(field: string) {
  switch (field) {
    case 'GLOBAL_TODAY': {
      return format(startOfToday(), dateFormatStr);
    }

    case 'GLOBAL_YESTERDAY': {
      return format(startOfYesterday(), dateFormatStr);
    }

    case 'GLOBAL_TOMORROW': {
      return format(startOfTomorrow(), dateFormatStr);
    }

    default: {
      return null;
    }
  }
}

function getBackupValue(backupPlan: MessageBackupPlan) {
  if (backupPlan?.backupType === BackupType.CUSTOM_TEXT) {
    return backupPlan.value;
  }
  if (backupPlan?.backupType === BackupType.LEAVE_BLANK) {
    return '';
  }
}

function getUserValue(field: string, user: Partial<IUser>) {
  if (user && field === 'USER_NAME') {
    return user.name;
  }
  if (user && field === 'USER_ID') {
    return user.id;
  }
  if (user && field === 'USER_EMAIL') {
    return user.email;
  }
  return '';
}

function getMemberValue({
  field, member, variables, backupPlan,
}: {
  field: string;
  member: IMember;
  variables: IMessageVariable;
  backupPlan: MessageBackupPlan;
}) {
  const memberVariables = variables?.member;
  if (!memberVariables) {
    console.log('Member variables are undefined.');
    return null;
  }

  if (memberVariables[field]?.jsonParent === 'Sales Tracking Offers') {
    // field example: fields.22.${offerId}.link
    const splitKey = split(memberVariables[field].field, '.');
    const jsonParentField = slice(splitKey, 0, 2).join('.');
    const offerLinkKey = slice(splitKey, 2, 4).join('.');
    const salesTrackingOffersFieldValue = trim(get(member, jsonParentField));
    if (salesTrackingOffersFieldValue) {
      const salesTrackingOffersJson = JSON.parse(salesTrackingOffersFieldValue);
      const offerLink = get(salesTrackingOffersJson, offerLinkKey);
      if (offerLink) {
        switch (memberVariables[field].type) {
          case 'LINK':
            return (
              <a href={offerLink} rel="noreferrer" target="_blank">
                {memberVariables[field].metadata?.linkLabel || memberVariables[field].label}
              </a>
            );
          default:
            return offerLink;
        }
      }
    }

    return getBackupValue(backupPlan);
  }

  const fieldValue = trim(get(member, memberVariables[field]?.field));
  if (isNil(fieldValue) || isEmpty(fieldValue)) {
    return getBackupValue(backupPlan);
  }

  if (memberVariables[field].type === 'DATE') {
    return format(new Date(fieldValue), dateFormatStr);
  }
  if (memberVariables[field].type === 'LINK') {
    return (
      <a href={fieldValue} rel="noreferrer" target="_blank">
        {memberVariables[field]?.metadata?.linkLabel || memberVariables[field]?.label}
      </a>
    );
  }

  return fieldValue;
}
