/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bvnvr_157 {
  display: flex;
}

._justify-content-space-between_bvnvr_161 {
  justify-content: space-between;
}

._tabular-nums_bvnvr_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bvnvr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._VideoContentDetail_bvnvr_178 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._VideoContentDetail_bvnvr_178 ._notice_bvnvr_184 {
  margin-top: 1.25rem;
}