import * as React from 'react';
import cx from 'classnames';
import {
 isEmpty, isFunction, keyBy, map,
} from 'lodash';
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

import { ArrowDownFilledIcon } from '@components';

import { ISelectListProps, SelectList } from './SelectList';

const {
 useCallback, useEffect, useImperativeHandle, useMemo, useState,
} = React;

import styles from './SelectListPicker.scss';

export interface ISelectListPickerProps<T> extends ISelectListProps<T> {
  disabled?: boolean;
  footer?: React.ReactChild;
  placeholder?: string;
  popoverProps?: PopoverProps;
  selectListClassName?: string;
}

export interface ISelectListPickerHandles {
  hide: () => void;
}

type TProps<T> = React.PropsWithChildren<ISelectListPickerProps<T>>;
type TRef = { selectListPickerRef?: React.Ref<ISelectListPickerHandles> };

// https://github.com/microsoft/TypeScript/issues/15713
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const SelectListPicker = <T,>(props: TProps<T> & TRef) => {
  const {
    className,
    disabled = false,
    footer,
    placeholder,
    popoverProps,
    selectListClassName,
    selectListPickerRef,
    ...selectListProps
  } = props;
  const {
    options,
    mapOptionToId,
    mapOptionToLabel,
  } = selectListProps || {};

  const [isVisible, setVisible] = useState(false);
  const [displayOptions, setDisplayOptions] = useState(placeholder);

  useImperativeHandle(
    selectListPickerRef,
    () => ({
      hide: () => setVisible(false),
    }),
    [],
  );

  const optionForId = useMemo(
    () => keyBy(options, mapOptionToId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const updateDisplayOptions = (ids: any[]) => {
    if (!isEmpty(options) && !isEmpty(ids)) {
      setDisplayOptions(
        map(ids, (id) => (
          mapOptionToLabel
            ? mapOptionToLabel(optionForId[id])
            : JSON.stringify(optionForId[id])
        )).join(', '),
      );
    } else {
      setDisplayOptions(placeholder);
    }
  };

  useEffect(
    () => {
      updateDisplayOptions(selectListProps?.defaultSelectedIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(
    () => {
      updateDisplayOptions(selectListProps?.selectedIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectListProps?.selectedIds],
  );

  const onChange: ISelectListProps<T>['onChange'] = useCallback(
    (selectedIds) => {
      updateDisplayOptions(selectedIds);
      if (isFunction(selectListProps?.onChange)) {
        selectListProps.onChange(selectedIds);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectListProps?.onChange, updateDisplayOptions],
  );

  const renderContent = () => (
    <>
      <SelectList<T>
        className={selectListClassName}
        {...selectListProps}
        onChange={onChange}
      />
      {footer}
    </>
);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      overlayClassName={cx(styles.overlay, popoverProps?.overlayClassName)}
      {...popoverProps}
      content={renderContent()}
      visible={isVisible}
      onVisibleChange={(visible) => {
        if (!disabled && visible !== isVisible) {
          setVisible(visible);
        }
      }}
    >
      <div
        className={cx(styles.SelectListPicker, className, {
          [styles.disabled]: disabled,
        })}
      >
        <div className={cx(styles.label, {
          [styles.placeholder]: displayOptions === placeholder,
        })}
        >
          {displayOptions || placeholder}
        </div>
        <ArrowDownFilledIcon size={8} className={styles.downIcon} />
      </div>
    </Popover>
  );
};
