import gql from 'graphql-tag';

import { CLIENT_FRAGMENT, USER_FRAGMENT, USER_FRAGMENT_WITH_AUTH0 } from './fragments';

export const GET_PROFILE_QUERY = gql`
  query GetProfileQuery {
    profile: getProfile {
      ...UserFragmentWithAuth0
      clients {
        ...ClientFragment
      }
    }
  }
  ${USER_FRAGMENT_WITH_AUTH0}
  ${CLIENT_FRAGMENT}
`;

export const GET_PROFILE_QUERY_COMPACT = gql`
  query GetProfileQueryCompact {
    profile: getProfile {
      ...UserFragment
      clients {
        ...ClientFragment
      }
    }
  }
  ${USER_FRAGMENT}
  ${CLIENT_FRAGMENT}
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfileMutation($params: UpdateProfileInput!) {
    profile: updateProfile(params: $params) {
      ...UserFragmentWithAuth0
      clients {
        ...ClientFragment
      }
    }
  }
  ${USER_FRAGMENT_WITH_AUTH0}
  ${CLIENT_FRAGMENT}
`;
