import * as React from 'react';
import { Link } from 'react-router-dom';

import { ChartColumnIcon } from '@revfluence/fresh-icons/regular/esm';
import { InstagramIcon } from '@revfluence/fresh-icons/brands/esm';
import styles from '@frontend/applications/SocialPostApp/components/ZeroStateAnalyticsInsights.scss';
import {
  Button,
  Space,
  Typography,
} from '@revfluence/fresh';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

const { Paragraph, Text, Link: FreshLink } = Typography;

const analyticsArticle = 'https://intercom.help/aspireiq_elevate/en/articles/6029907-how-are-social-posts-pulled-into-social-analytics';

const OAUTH_TYPE = 'aspirex_add_brand_account_mentions';

export const ZeroStateAnalyticsInsights = () => {
  const { backendServerWebEndpoint, clientId } = useApplication();
  const oauthEndpoint = `${backendServerWebEndpoint}brands/${clientId}/facebook/aspirex_oauth`;
  const redirect = window.location.href;
  const directOauthEndpoint = `${oauthEndpoint}?oauth_type=${OAUTH_TYPE}&redirect=${redirect}`;
  const addEvent = useEventContext();

  const oauthRedirect = () => {
    addEvent(EventName.OAuthGrantStart, { app: 'social_post' });
    window?.localStorage?.removeItem('ig_connect_success_track');
    window.location.replace(directOauthEndpoint);
  };

  return (
    <Space
      direction="vertical"
      size="large"
      align="center"
    >
      <ChartColumnIcon
        width="4rem"
        height="4rem"
        color="#f0f0f0"
      />
      <Text
        strong
      >
        You have no posts within this date range
      </Text>
      <Paragraph className={styles.ZeroStateAnalyticsInsights}>
        View project data from posts as they get submitted and connect your Instagram to
        see post data from tags and mentions about your brand using Social Listening.
        For more information on how social posts are tracked, check out this
        <FreshLink rel="noopener noreferrer" href={analyticsArticle} target="_blank"> article</FreshLink>
        .
      </Paragraph>
      <div className={styles.Buttons}>
        <Button
          icon={<InstagramIcon />}
          className={styles.ConnectInstagramButton}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          label="Connect Instagram"
          size="large"
          onClick={oauthRedirect}
          type="primary"
        >
          Connect Instagram
        </Button>
        <Link
          to={{ ...location, pathname: '/projects/new/templates' }}
        >
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            type="secondary"
            size="large"
          >
            Create a Project
          </Button>
        </Link>
      </div>
    </Space>
  );
};

ZeroStateAnalyticsInsights.displayName = '<ZeroStateAnalyticsInsights />';
