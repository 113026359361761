/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from 'react';

interface PinterestImageProps {
  url: string;
  className?: string;
}

const PinterestEmbed: React.FC<PinterestImageProps> = ({ url, className = '' }) => {
  useEffect(() => {
    // Load the Pinterest script if it's not already loaded
    if (!document.querySelector('script[src="//assets.pinterest.com/js/pinit.js"]')) {
      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.src = '//assets.pinterest.com/js/pinit.js';
      document.body.appendChild(script);
    }
  }, []);

  return (
    <a data-pin-do="embedPin" href={url} className={className} />
  );
};

export default PinterestEmbed;
