/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import * as React from 'react';

import { EmailComposer } from '@frontend/app/components';
import { useGetMemberQuery } from '@frontend/app/hooks';
import { backendServerWebEndpoint, creatorPortalDomain } from '@frontend/applications/Shared/serviceHosts';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { subjectLineForClientAndProgram } from '@frontend/applications/Shared/utils';
import { IAxProjectInfo } from '../types/IAxProjectInfo';

import styles from './MessageComposer.scss';

const { useMemo } = React;

interface IMessageComposerProps {
  memberId: number;
  project?: IAxProjectInfo;
}

export enum TermsMessageType {
  NEW_TERMS = 'new',
  EDITED_TERMS = 'edit',
  ACCEPT_EDITED_TERMS = 'accept',
  TERMS_REMINDER = 'reminder',
}

export const MessageComposer: React.FC<IMessageComposerProps> = ({ memberId, project }) => {
  const { closeModal, clientName, workflowActionParameters } = useApplication();

  const { data } = useGetMemberQuery(+memberId);

  const subject = useMemo(() => {
    if (!project) {
      return '';
    }

    if (workflowActionParameters?.programName) {
      return subjectLineForClientAndProgram(clientName, workflowActionParameters.programName, 'Brief');
    }
    const subjectTemplates = {
      [TermsMessageType.NEW_TERMS]: `We want to work with you here at ${clientName}`,
      [TermsMessageType.EDITED_TERMS]: 'Need to modify our brief',
      [TermsMessageType.ACCEPT_EDITED_TERMS]: `Re: We want to work with you here at ${clientName}`,
      [TermsMessageType.TERMS_REMINDER]: `We want to work with you here at ${clientName}`,
    };
    return subjectTemplates[project.action];
  }, [project, clientName, workflowActionParameters?.programName]);

  const initialHTML = useMemo(() => {
    const directLink = `${backendServerWebEndpoint()}app/project/${project.id}/agreement?token=${project.token}`;
    const link = `${creatorPortalDomain()}/creators/programs/${project.programId}/direct?url=${directLink}`;

    const contentTemplates = {
      [TermsMessageType.NEW_TERMS]: `{"blocks":[{"key":"fe0b3","text":"Hi ${data?.member.name},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"64on2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"eg9na","text":"I am excited to work with you on this collaboration! Since I'm using Aspire","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"7akav","text":"to help manage this program, you'll need to click the link below to see the","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"a0n0o","text":"details and accept the brief. I look forward to working with you.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"9mvao","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5p9sj","text":"View Brief","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":24,"key":0}],"data":{}},{"key":"b7010","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"ho35","text":"Thanks,","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"link","mutability":"MUTABLE","data":{"src":"${link}"}}}}`,
      [TermsMessageType.ACCEPT_EDITED_TERMS]: `{"blocks":[{"key":"fe0b3","text":"Hi ${data?.member.name},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"64on2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"eg9na","text":"I just accepted the changes you proposed to our brief. I’m very excited to collaborate together!","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"b7016","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5p9sr","text":"View Brief","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":24,"key":0}],"data":{}},{"key":"b7019","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"ho35","text":"Thanks,","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"link","mutability":"MUTABLE","data":{"src":"${link}"}}}}`,
      [TermsMessageType.EDITED_TERMS]: `{"blocks":[{"key":"3t713","text":"Hi ${data?.member.name},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"b9ti5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"dhkk6","text":"We have updated the brief of this collaboration. You'll need to click the link below to see the details and accept the new brief.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"cs95t","text":"Let me know if you have any questions about it.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"cs95g","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"cs95a","text":"I look forward to working with you.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"cs95h","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"c5asc","text":"View Brief","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":24,"key":0}],"data":{}},{"key":"8a5dl","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"i14v","text":"Thanks,","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"link","mutability":"MUTABLE","data":{"src":"${link}"}}}}`,
      [TermsMessageType.TERMS_REMINDER]: `{"blocks":[{"key":"fe0b3","text":"Hi ${data?.member.name},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"f82vq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"2a57k","text":"I wanted to check in to see if you were able to review the brief I sent.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"dm8i5","text":"Let me know if you have any questions about it.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"7oino","text":"We're looking forward to working together!","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"4gtsb","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"agc9k","text":"View Brief","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":24,"key":0}],"data":{}},{"key":"7feiu","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5i5s4","text":"Thanks,","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"link","mutability":"MUTABLE","data":{"src":"${link}"}}}}`,
    };

    return project && data ? contentTemplates[project.action] : '';
  }, [project, data]);

  return (
    <div className={styles.TermsAppMessageComposer}>
      <div className={styles.MessageComposerWrapper}>
        <div className={styles.title}>Write your message</div>
        <div className={styles.description}>Add a personal touch by sending a message to the member.</div>
        {data?.member && (
          <EmailComposer
            className={styles.EmailComposer}
            subject={subject}
            subjectIsEditable
            initialMessage={initialHTML}
            members={[data?.member]}
            onMessageSent={() => closeModal()}
            hasTopBorder={false}
            enableExpandingEmailEditor
          />
        )}
      </div>
    </div>
  );
};
