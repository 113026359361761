import { Area as RechartArea } from 'recharts';
import styles from '../../Charts.module.scss';

// Work around to wrap Area component.
// Issue: https://github.com/recharts/recharts/issues/412#issuecomment-393407132
export class Area extends RechartArea {
  static defaultProps = {
    ...RechartArea.defaultProps,
    stroke: styles.green,
    strokeWidth: 3,
    dot: false,
    type: 'basis',
    fill: styles['primary-10'],
  };
}
