import 'css-chunk:src/applications/ContractApp/components/ContractCell.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_nd7dj_157",
  "justify-content-space-between": "_justify-content-space-between_nd7dj_161",
  "tabular-nums": "_tabular-nums_nd7dj_165",
  "ContractCell": "_ContractCell_nd7dj_178",
  "contractItemHead": "_contractItemHead_nd7dj_189",
  "textWrap": "_textWrap_nd7dj_194",
  "contractName": "_contractName_nd7dj_198",
  "dateCreated": "_dateCreated_nd7dj_208",
  "button_wrap": "_button_wrap_nd7dj_217",
  "download_button": "_download_button_nd7dj_222",
  "requirements_wrap": "_requirements_wrap_nd7dj_243",
  "requirement_label": "_requirement_label_nd7dj_246",
  "requirement_list": "_requirement_list_nd7dj_256",
  "requirement_item": "_requirement_item_nd7dj_261",
  "signed_status": "_signed_status_nd7dj_268",
  "signIcon": "_signIcon_nd7dj_275",
  "status_txt": "_status_txt_nd7dj_278",
  "actionsContainer": "_actionsContainer_nd7dj_286",
  "actionButton": "_actionButton_nd7dj_290",
  "show": "_show_nd7dj_1"
};