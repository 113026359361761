import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { map } from 'lodash';

import {
  TNetworkIdentifier, LazyImage, LoadSpinner, Notice,
} from '@components';

import styles from './TopPostsSection.scss';

export interface ITopPostsData {
  network: TNetworkIdentifier;
  image_url: string;
  link: string;
  likes: number;
  engagements: number;
  reach: number;
  impressions: number;
  profile_image_url: string;
}

export interface IProps {
  className?: string;
  data: { loading: boolean, data?: ITopPostsData[], error: Error|null };
}

/**
 * @type {React.FunctionComponent}
 */
export const TopPostsSection: React.FunctionComponent<IProps> = React.memo(
  (props) => {
    const { loading, data, error } = props.data;
    if (error) {
      return (
        <Notice className={(styles as any).notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    const openLink = (url: string) => {
      window.open(url, '_blank');
    };

    return (
      <div className={cx(styles.TopPostsSection, props.className)}>
        { loading ? <LoadSpinner centered /> : (
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>
                  Top Post
                  {data && data.length > 1 ? 's' : ''}
                </h3>
              </div>
            </div>
            <div className={styles.main}>
              {map(data, (record) => (
                <div key={record.link} className={styles.item}>
                  <div
                    className={styles.imageSection}
                    onClick={() => openLink(record.link)}
                  >
                    <LazyImage
                      className={styles.image}
                      src={record.image_url}
                    />
                    <LazyImage
                      className={styles.profileImage}
                      src={record.profile_image_url}
                    />
                  </div>
                  <div className={styles.info}>
                    <div className={styles.likes}>
                      {numeral(record.likes).format('0.[0]a').toUpperCase()}
                      &nbsp;Likes
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.type}>Engagements</div>
                      <div className={styles.count}>
                        {numeral(record.engagements).format('0.[0]a').toUpperCase()}
                      </div>
                    </div>
                    <div className={styles.infoItem}>
                      <div className={styles.type}>Impressions</div>
                      <div className={styles.count}>
                        {numeral(record.impressions).format('0.[0]a').toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  },
);

TopPostsSection.defaultProps = {
  className: null,
};
