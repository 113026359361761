import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_TOP_PRODUCTS_QUERY } from '../queries';
import { GetTopProducts, GetTopProductsVariables } from '../queries/types/GetTopProducts';

type IOptions = QueryHookOptions<GetTopProducts, GetTopProductsVariables>;

export function useGetTopProducts(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetTopProducts, GetTopProductsVariables>(
    GET_TOP_PRODUCTS_QUERY,
    {
      ...options,
    },
  );

  return {
    isTopProductsLoading: loading,
    topProducts: data?.products,
    topProductsError: error,
    refetchTopProducts: refetch,
  };
}
