import React, { useMemo } from 'react';
import {
 Button, IColumnsType, ITableProps, Tag,
} from '@revfluence/fresh';
import { pluralize } from '@frontend/app/utils/strings';
import { CaretDownIcon, CaretRightIcon } from '@revfluence/fresh-icons/solid/esm';
import { GetImportedProducts } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportedProducts';
import { useParams } from 'react-router-dom';
import { getPaginationProps } from '../../utils';
import { IProductsRow } from '../../Products/types';
import { NameWithImageRenderer } from '../../components/NameWithImageRenderer/NameWithImageRenderer';
import { useNumberOfVariantsSelected } from './BasicSelectionContext';
import styles from './ProductsTable.scss';
import paginationStyles from '../../styles.scss';
import { VariantTable } from './VariantTable';
import { useGetCatalogCollectionByIdQuery } from '../hooks/useGetCatalogCollectionById';

const PAGE_SIZE = 50;

export interface IUseProductTableProps {
  products: GetImportedProducts['getProductsByCollectionId']['products']
  onNextPage: () => void;
  onPreviousPage: () => void;
  totalProducts: number;
  pageNumber: number;
  disabled?: boolean;
  isReadonly?: boolean;
}

const NameWithVariantsRenderer = ({
 id, title, image, variantsCount, isReadonly, pageNumber,
}: IProductsRow) => {
  const variantsSelectedInState = useNumberOfVariantsSelected({
    productId: id,
  });

  const { collectionId } = useParams<{ collectionId: string }>();
  const { collectionItem } = useGetCatalogCollectionByIdQuery({
    variables: {
      id: Number(collectionId),
      includeProductDetails: true,
      page: pageNumber,
      pageSize: PAGE_SIZE,
    },
    fetchPolicy: 'cache-only',
    skip: !collectionId,
    notifyOnNetworkStatusChange: true,
  });

  const variantsSelected = useMemo(() => {
    if (isReadonly) {
      const selectedProductFromBE = collectionItem?.catalogProducts.find((product) => product.productId === Number(id));
      if (!selectedProductFromBE) {
        return 0;
      }
      return selectedProductFromBE.variantIds.length === 0 ? variantsCount : selectedProductFromBE.variantIds.length;
    } else {
      return variantsSelectedInState;
    }
  }, [collectionItem?.catalogProducts, id, isReadonly, variantsCount, variantsSelectedInState]);

  return (
    <NameWithImageRenderer
      title={title}
      image={image}
      subTitle={`${variantsCount} ${pluralize(variantsCount, 'Variant')}`}
      rightContent={(
        <Tag>
          {variantsSelected}
          {' '}
          Variants Selected
        </Tag>
)}
    />
  );
};

export const useProductTableProps = ({
 products, totalProducts, onNextPage, onPreviousPage, pageNumber, disabled = false, isReadonly = false,
}: IUseProductTableProps) => {
  const columnConfig = useMemo<IColumnsType<IProductsRow>>(
    () => [
      {
        key: '_raw',
        dataIndex: '_raw',
        render: (props) => <NameWithVariantsRenderer {...props} />,
      },
    ],
    [],
  );

  const tableData = useMemo<Partial<IProductsRow>[]>(
    () =>
      products.map((product) => {
        const transformedData: Partial<IProductsRow> = {
          key: String(product.id),
          id: String(product.id),
          title: product.name,
          image: product.images?.[0],
          variantsCount: product.totalVariants,
          isReadonly,
          pageNumber,
        };
        const productRow: Partial<IProductsRow> = { ...transformedData, _raw: transformedData };
        return productRow;
      }),
    [isReadonly, pageNumber, products],
  );

  const expandable = useMemo<ITableProps<IProductsRow>['expandable']>(
    () => ({
      expandedRowRender: (record, _, __, expanded) => expanded && <VariantTable productId={record.id} isReadonly={isReadonly} pageNumber={pageNumber} />,
      expandIcon: ({ expanded, onExpand, record }) => (
        <Button
          icon={
            expanded ? (
              <CaretDownIcon className={styles.expandIcon} />
            ) : (
              <CaretRightIcon className={styles.expandIcon} />
            )
          }
          onClick={(e) => onExpand(record, e)}
          type="text"
        />
      ),
    }),
    [isReadonly, pageNumber],
  );

  return {
    columnConfig,
    tableData,
    pagination: getPaginationProps({
      total: totalProducts,
      pageSize: PAGE_SIZE,
      className: paginationStyles.PfaV2PaginationWithoutHeader,
      onNextPage,
      onPreviousPage,
      current: pageNumber,
      disabled,
    }),
    expandable,
  };
};
