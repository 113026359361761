import * as React from 'react';
import cx from 'classnames';

import {
  Tabs, Tab, Button, InviteAddressPicker, ImageIcon, TextIcon, useResources,
} from '@components';

import { ISearchResultsProps } from './models';
import { ConnectedTextSearch } from './connected/ConnectedTextSearch';
import { ConnectedImageSearch } from './connected/ConnectedImageSearch';
import { ConnectedSearchResults } from './connected/ConnectedSearchResults';
import { ConnectedSavedSearches } from './connected/ConnectedSavedSearches';

import styles from './SearchPage.scss';
import { useEventContext } from '@frontend/app/context';
import { clearSearchedTerms, getSearchedTerms } from './utils/searchParams';
import { CreatorSearchSessionUserAction, EventName } from '../../../../../common/Event';
import { useDemoAccountFlag, useInstagramDMFlag, useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

const { useCallback, useEffect, useMemo, useRef } = React;

export interface ISearchPageProps extends ISearchResultsProps {
  isImageSearchSelected?: boolean;
  imageSearchEnabled?: boolean;
  showCreateFeatures?: boolean;

  onSelectTextSearch();
  onSelectImageSearch();
  onClickApplySearch: () => void;

  className?: string;
  resultsClass?: string;
}

const ImageTabTitle = React.memo(() => (
  <div className={styles.tabTitle}>
    <ImageIcon size={18} />
    <span>Image Search</span>
  </div>
));

const TextTabTitle = React.memo(() => (
  <div className={styles.tabTitle}>
    <TextIcon size={22} />
    <span>Text Search</span>
  </div>
));

export const SearchPage: React.FunctionComponent<ISearchPageProps> = React.memo((props) => {
  const {
    onSelectImageSearch,
    onSelectTextSearch,
  } = props;

  const {
    resources: emailResources,
    resourceId: selectedEmailId,
    selectResourceId: selectEmailResource,
  } = useResources();

  const { shouldShowDemoFeatures } = useDemoAccountFlag();

  const { isInstagramDMEnabled } = useInstagramDMFlag();
  const showInstagramMeta = useClientFeatureEnabled(ClientFeature.SHOW_INSTAGRAM_META);
  const disableImageSearch = useClientFeatureEnabled(ClientFeature.DISABLE_IMAGE_SEARCH);

  const addEvent = useEventContext();

  const searchPageSessionRef = useRef({
    startTime: null,
  });

  useEffect(() => {
    addEvent(EventName.CreatorSearchSession, {
      user_action: CreatorSearchSessionUserAction.ENTER_SEARCH_PAGE,
    });

    searchPageSessionRef.current.startTime = new Date().getTime();
    return () => {
      const session_time = (new Date().getTime() - searchPageSessionRef.current.startTime) / 1000;
      const searched_terms = getSearchedTerms();

      addEvent(EventName.CreatorSearchSession, {
        user_action: CreatorSearchSessionUserAction.LEAVE_SEARCH_PAGE,
        searched_terms,
        session_time: `${Math.round(session_time * 10) / 10} seconds`, //round to 1 decimal
      });

      clearSearchedTerms();
    };
  }, []);

  const handleChangeTab = useCallback((index: number) => {
    switch (index) {
      case 0:
        onSelectTextSearch();
        break;
      case 1:
        onSelectImageSearch();
        break;
      default:
        break;
    }
  }, [onSelectImageSearch, onSelectTextSearch]);

  const isImageSearchEnabled = useMemo(() => (
    props.imageSearchEnabled && !shouldShowDemoFeatures
  ), [shouldShowDemoFeatures, props.imageSearchEnabled]);

  return (
    <div className={cx(styles.SearchPage, props.className)}>
      <div className={styles.filters}>
        <div className={styles.filtersOptions}>
          {
            !props.showCreateFeatures
            && !isInstagramDMEnabled
            && !showInstagramMeta
            && (
              <InviteAddressPicker className={styles.inviteAddressPicker} />
            )
          }
          {isImageSearchEnabled ? (
            <>
              <ConnectedSavedSearches />
              <Tabs onChangeTab={handleChangeTab}>
                <Tab title={<TextTabTitle />} selected={!props.isImageSearchSelected}>
                  <ConnectedTextSearch
                    shouldShowDemoFeatures={shouldShowDemoFeatures}
                    isInstagramDMEnabled={isInstagramDMEnabled || showInstagramMeta}
                    emailResources={emailResources}
                    selectedEmailId={selectedEmailId}
                    onSelectEmailId={selectEmailResource}
                  />
                </Tab>
                <Tab
                  disabled={disableImageSearch}
                  title={<ImageTabTitle />}
                  selected={props.isImageSearchSelected}
                >
                  <ConnectedImageSearch
                    isInstagramDMEnabled={isInstagramDMEnabled || showInstagramMeta}
                    emailResources={emailResources}
                    selectedEmailId={selectedEmailId}
                    onSelectEmailId={selectEmailResource}
                  />
                </Tab>
              </Tabs>
            </>
          ) : (
            <ConnectedTextSearch
              isInstagramDMEnabled={isInstagramDMEnabled || showInstagramMeta}
              emailResources={emailResources}
              selectedEmailId={selectedEmailId}
              onSelectEmailId={selectEmailResource}
            />
          )}
        </div>
        <div className={styles.filtersButton}>
          <Button
            label="Apply Search & Filters"
            fullWidth
            onClick={() => props.onClickApplySearch()}
          />
        </div>
      </div>
      <div className={cx(styles.results, props.resultsClass)}>
        <ConnectedSearchResults
          $state={props.$state}
          sendBulkOffer={props.sendBulkOffer}
          exportCsv={props.exportCsv}
          reportAsIncorrect={props.reportAsIncorrect}
          onCreatorSelected={props.onCreatorSelected}
          selectedSocialAccountId={props.selectedSocialAccountId}
          showCreateFeatures={props.showCreateFeatures}
          isImageSearchSelected={props.isImageSearchSelected}
        />
      </div>
    </div>
  );
});
