import * as React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';

import { ITagColor } from '@frontend/app/hooks';

import styles from './ColoredTag.scss';

const { Text } = Typography;

interface IProps {
  name: string;
  tagColor?: ITagColor;
  boldFont?: boolean;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ColoredTag: React.FC<IProps> = React.memo((props) => {
  const { name, tagColor, boldFont } = props;

  return (
    <Text
      ellipsis
      className={cx(styles.ColoredTag, props.className)}
      style={{
        color: tagColor?.color || undefined,
        backgroundColor: tagColor?.backgroundColor || undefined,
        fontWeight: boldFont ? 600 : 400,
      }}
    >
      {name}
    </Text>
  );
});

ColoredTag.displayName = 'ColoredTag';
