import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const ColumnsIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M18.9478 4H5.0506C3.35582 4 2 5.35582 2 7.0506V16.0329C2 17.7277 3.35582 19.0835 5.0506 19.0835H18.9478C20.6425 19.0835 21.9984 17.7277 21.9984 16.0329V7.0506C22.0549 5.35582 20.6425 4 18.9478 4ZM14.4284 5.58179V17.5582H9.23105V5.58179H14.4284ZM3.58179 16.0329V7.0506C3.58179 6.2597 4.2597 5.58179 5.0506 5.58179H7.64925V17.5582H5.0506C4.2597 17.5582 3.58179 16.8803 3.58179 16.0329ZM20.4731 16.0329C20.4731 16.8803 19.7952 17.5582 18.9478 17.5582H15.9537V5.58179H18.9478C19.7952 5.58179 20.4731 6.2597 20.4731 7.10709V16.0329Z" />
  </SvgIcon>
));

ColumnsIcon.displayName = 'ColumnsIcon';
