import * as React from 'react';
import cx from 'classnames';
import { map, some, trim } from 'lodash';
import {
 Button, Divider, Input, Popover, Select,
} from 'antd';

import {
  useSaveMemberFieldSchema,
} from '@frontend/app/hooks';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { FieldType } from '@frontend/app/types/Fields';
import { CustomFieldType } from '@frontend/app/types/globalTypes';
import { useMessagingContext } from '@frontend/hooks';

const { useCallback, useMemo, useState } = React;

import styles from './CreateNewField.scss';

interface IProps {
  fields: IField[];
  onCreateNewField: (field: IField) => void;
  className?: string;
}

export const CreateNewField: React.FC<IProps> = (props) => {
  const {
    className,
    fields,
    onCreateNewField,
  } = props;

  const [visible, setVisible] = useState(false);
  const [fieldType, setFieldType] = useState<CustomFieldType>();
  const [name, setName] = useState<string>();
  const [isAdding, setAdding] = useState(false);

  const fieldNames = useMemo(
    () => map(fields, (f) => f.headerName),
    [fields],
  );
  const nameExists = useMemo(
    () => some(fieldNames, (n) => n.toLocaleLowerCase() === name?.toLocaleLowerCase()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, name],
  );

  const reset = useCallback(
    () => {
      setFieldType(undefined);
      setName(undefined);
    },
    [setName],
  );

  const { showError } = useMessagingContext();
  const [saveSchema] = useSaveMemberFieldSchema({
    onCompleted: (result) => {
      const newField = result?.schema;
      if (newField) {
        onCreateNewField({
          ...newField,
          headerName: newField.name,
          field: newField.id.toString(),
        });
      }
      setVisible(false);
    },
    onError: (error) => {
      showError(error);
      setAdding(false);
    },
  });
  const handleCreateField = useCallback(
    () => {
      setAdding(true);
      saveSchema({
        variables: {
          communityId: undefined,
          schema: {
            id: null,
            name,
            type: fieldType,
          },
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        } as any,
      });
    },
    [fieldType, name, saveSchema, setAdding],
  );

  const isAddButtonDisabled = (
    isAdding
    || nameExists
    || !name
    || !fieldType
  );
  const renderContent = () => (
    <div className={styles.CreateNewField}>
      <div className={styles.title}>Create New Field</div>
      <Select<CustomFieldType>
        onChange={(value) => setFieldType(value)}
        placeholder="Select field type"
        disabled={isAdding}
      >
        <Select.Option value={FieldType.TEXT}>Free Text</Select.Option>
        <Select.Option value={FieldType.NUMBER}>Number</Select.Option>
        <Select.Option value={FieldType.DATE}>Date</Select.Option>
        <Select.Option value={FieldType.BOOLEAN}>Yes/No</Select.Option>
      </Select>
      <Divider className={styles.divider} />
      <div className={cx({ 'ant-form-item-has-error': nameExists })}>
        <Input
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          className={(styles as any).input}
          onChange={(e) => setName(trim(e.currentTarget?.value))}
          size="large"
          placeholder="New field name"
          disabled={isAdding}
        />
      </div>
      {nameExists && !isAdding && (
        <span className={styles.errorMessage}>Name already exists</span>
      )}
      <Divider className={styles.divider} />
      <Button
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        className={(styles as any).button}
        onClick={handleCreateField}
        disabled={isAddButtonDisabled}
        type="primary"
      >
        Add
      </Button>
    </div>
  );

  return (
    <Popover
      trigger="click"
      placement="right"
      overlayClassName={styles.overlay}
      content={renderContent()}
      className={className}
      visible={visible}
      onVisibleChange={(visible) => {
        if (visible) {
          reset();
        }
        setVisible(visible);
      }}
    >
      <div className={styles.clickable}>
        Import as a new field
      </div>
    </Popover>
  );
};
