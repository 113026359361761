import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import PaymentListPage from './PaymentListPage';

interface IProps {
  refreshUi: boolean;
}
const application = {
  displayName: 'Payments',
  iconUrl: 'https://storage.googleapis.com/aspirex-static-files/payment.svg',
};

const navSettings = [
  {
    route: 'reports',
    displayName: 'My Payments',
    component: PaymentListPage,
  },
];

const PaymentAppDashboard: React.FunctionComponent<IProps> = ({ refreshUi }) => (
  <>
    {refreshUi ? (
      <>
        <AppHeader
          title={application.displayName}
        />
        <div className="px-6" style={{ padding: 24 }}>
          <PaymentListPage />
        </div>
      </>
    ) : (
      <AppDashboardNav
        application={application}
        navLinks={navSettings}
        defaultRoute="reports"
        hideTabs
      />
    )}
  </>
);

export default PaymentAppDashboard;
