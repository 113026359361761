import * as React from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { IPaymentSources } from './models';

const { useState, useCallback } = React;

export interface IAddPaymentSourceParams {
  card_token: string;
  client_id: string;
}

const saveCard = async (url: string, params: IAddPaymentSourceParams) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    if (json.status.code >= 400) {
      throw new Error(json.status.error_msg);
    }
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export const useAddPaymentSource = () => {
  const { backendServerApiEndpoint, clientId } = useApplication();

  const url = `${backendServerApiEndpoint}/payment_source`;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaymentSources>(null);
  const [error, setError] = useState<Error | null>(null);

  const save = useCallback(async (cardToken: string) => {
    setLoading(true);

    const params: IAddPaymentSourceParams = {
      card_token: cardToken,
      client_id: clientId,
    };

    let result;

    try {
      result = await saveCard(url, params);
      setData(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }

    return result;
  }, [url, clientId]);

  return {
    save,
    loading,
    data,
    error,
  };
};
