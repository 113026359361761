import 'css-chunk:src/applications/ShopifyApp/components/ShopifyAccountList.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_vy86r_157",
  "justify-content-space-between": "_justify-content-space-between_vy86r_161",
  "tabular-nums": "_tabular-nums_vy86r_165",
  "ShopifyAccountList": "_ShopifyAccountList_vy86r_178",
  "left": "_left_vy86r_178",
  "header": "_header_vy86r_178",
  "listItem": "_listItem_vy86r_188",
  "noticeContent": "_noticeContent_vy86r_188",
  "title": "_title_vy86r_188",
  "store": "_store_vy86r_188",
  "text": "_text_vy86r_198",
  "addIcon": "_addIcon_vy86r_226",
  "accounts": "_accounts_vy86r_229",
  "alert": "_alert_vy86r_233",
  "ctaButtonsContainer": "_ctaButtonsContainer_vy86r_236",
  "connectionContainer": "_connectionContainer_vy86r_240",
  "connectionContainerSecondary": "_connectionContainerSecondary_vy86r_245",
  "connectionInnerContainer": "_connectionInnerContainer_vy86r_248",
  "sectionHeaderContainer": "_sectionHeaderContainer_vy86r_253",
  "removeAccountMessage": "_removeAccountMessage_vy86r_256",
  "sectionGap": "_sectionGap_vy86r_262",
  "sectionHeader": "_sectionHeader_vy86r_253",
  "disabled": "_disabled_vy86r_277",
  "error": "_error_vy86r_286",
  "icon": "_icon_vy86r_289",
  "checkmark": "_checkmark_vy86r_296",
  "remove": "_remove_vy86r_256",
  "enablePromoCodesLink": "_enablePromoCodesLink_vy86r_311",
  "errorNotice": "_errorNotice_vy86r_315",
  "reconnect": "_reconnect_vy86r_331",
  "addBtn": "_addBtn_vy86r_337",
  "alertMessage": "_alertMessage_vy86r_340",
  "addNew": "_addNew_vy86r_347",
  "show": "_show_vy86r_1"
};