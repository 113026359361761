import * as React from 'react';

import { OFFER_TRACKING_TYPE } from '@affiliates/types/globalTypes';
import { Typography } from '@revfluence/fresh';
import { ConversionTracking } from '../ConversionTracking/ConversionTracking';

import styles from './ConversionTrackingPane.scss';

const { Title } = Typography;

export interface IProps {
  pixelCode: string | null;
  postbackUrl: string | null;
  offerTrackingType: OFFER_TRACKING_TYPE;
  title?: string;
  parentStyle?: string;
}

export const ConversionTrackingPane: React.FC<IProps> = (props) => {
  let code: string;
  switch (props.offerTrackingType) {
    case OFFER_TRACKING_TYPE.HTTPS_IFRAME_PIXEL:
    case OFFER_TRACKING_TYPE.HTTPS_IMAGE_PIXEL:
      code = props.pixelCode;
      break;
    case OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK:
    case OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID:
    case OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID:
      code = decodeURIComponent(props.postbackUrl);
      break;
  }
  return (
    <div className={props.parentStyle ? props.parentStyle : styles.ConversionTrackingPane}>
      {props.title
        && (
          <Title level={4}>
            {props.title}
          </Title>
        )}
      <ConversionTracking
        code={code}
        offerTrackingType={props.offerTrackingType}
      />
    </div>
  );
};
