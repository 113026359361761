import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_MEMBERS_FOR_IDS_QUERY } from '../queries';
import { GetMembersForIdsQuery, GetMembersForIdsQueryVariables } from '../queries/types/GetMembersForIdsQuery';

type IOptions = QueryHookOptions<GetMembersForIdsQuery, GetMembersForIdsQueryVariables>;

export const useGetMembersByIdsQuery = (memberIds: number[], options: IOptions = {}) => useQuery<GetMembersForIdsQuery, GetMembersForIdsQueryVariables>(GET_MEMBERS_FOR_IDS_QUERY, {
    ...options,
    variables: {
      ids: memberIds,
    },
    skip: !memberIds || options.skip,
  });
