/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8dj4g_157 {
  display: flex;
}

._justify-content-space-between_8dj4g_161 {
  justify-content: space-between;
}

._tabular-nums_8dj4g_165 {
  font-variant-numeric: tabular-nums;
}

._Overlay_8dj4g_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_8dj4g_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Overlay_8dj4g_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Overlay_8dj4g_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Overlay_8dj4g_169 ::-webkit-scrollbar-track, ._Overlay_8dj4g_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Overlay_8dj4g_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Overlay_8dj4g_169 {
  position: fixed !important;
  z-index: 9400;
  top: 0;
  left: 0;
  padding: 4rem 0 4rem 0;
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.85);
}
._Overlay_8dj4g_169 ._close_8dj4g_263 {
  position: fixed;
  top: 1rem;
  right: 2rem;
  color: white;
  background-color: rgba(253, 253, 253, 0.2);
  transition: background-color 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._Overlay_8dj4g_169 ._close_8dj4g_263:hover {
  background-color: rgba(253, 253, 253, 0.35);
}
._Overlay_8dj4g_169 ._contentWrapper_8dj4g_274 {
  margin: auto;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
._Overlay_8dj4g_169 ._contentWrapper_8dj4g_274 ._content_8dj4g_274 {
  position: relative;
  pointer-events: none;
}
._Overlay_8dj4g_169 ._contentWrapper_8dj4g_274 ._content_8dj4g_274 > * {
  pointer-events: initial;
}
._Overlay-enter_8dj4g_290 {
  opacity: 0;
}
._Overlay-enter_8dj4g_290 ._contentWrapper_8dj4g_274 {
  opacity: 0;
  transform: translateY(-4rem);
}
._Overlay-enter-active_8dj4g_297 {
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
._Overlay-enter-active_8dj4g_297 ._contentWrapper_8dj4g_274 {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.15s, transform 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
}
._Overlay-exit_8dj4g_306 {
  opacity: 1;
  overflow: hidden;
}
._Overlay-exit_8dj4g_306 ._contentWrapper_8dj4g_274 {
  opacity: 1;
  transform: translateY(0);
}
._Overlay-exit-active_8dj4g_314 {
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
._Overlay-exit-active_8dj4g_314 ._contentWrapper_8dj4g_274 {
  opacity: 0;
  transform: translateY(-4rem);
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1), transform 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}

._body-disable-scroll_8dj4g_324 {
  padding-right: 0.9375rem;
  overflow-y: hidden;
}