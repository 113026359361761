import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
 bind, debounce, defer, isFunction, map,
} from 'lodash';

import { CreatorTile, ICreatorTileProps } from '@components';
import { MasonryGrid } from '@components';
import { ICampaign } from '@components';
import { IFavoriteList } from '@components';
import { ISocialAccount } from '@components';
import { useForceUpdate } from '@frontend/utils';
import { browserHasScrollbar } from '@frontend/utils';
import {
  useCommunitiesQuery,
  useGetAllProjectsQuery,
} from '@frontend/app/hooks';

import { ICreatorList } from './redux/creatorListModel';

const {
 useImperativeHandle, useLayoutEffect, useRef, useState,
} = React;
import styles from './GridView.scss';

interface IOwnProps {
  className?: string;
  apiEndpoint: string;
  campaign: ICampaign;

  allowFavorite: boolean;
  showCreateFeatures: boolean;
  selfServeExperiment: boolean;
  showRelevantPostImage?: boolean;
  searchedMentions?: string;

  onCreatorSelected(socialAccount: ISocialAccount);
  inviteToCampaign(socialAccount: ISocialAccount);
  sendOffer(accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  goToManage(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  reportAsIncorrect(accountName: string);
  onReachBottom();

  forwardedRef?: React.RefObject<IGridViewRef>;
}
interface IStateProps {
  isQa: boolean;
  socialAccounts: ISocialAccount[];
  favoriteLists: IFavoriteList[];
}
type TProps = IOwnProps & IStateProps;

export interface IGridViewRef {
  container: HTMLDivElement;
  forceUpdate: () => void;
}

const mapStateToProps = (state: ICreatorList): IStateProps => ({
    isQa: state.isQa,
    socialAccounts: state.socialAccounts,
    favoriteLists: state.favoriteLists,
  });

const GridViewComponent: React.FC<TProps> = (props) => {
  const {
    allowFavorite,
    apiEndpoint,
    campaign,
    className,
    favoriteLists,
    forwardedRef,
    goToManage,
    inviteToCampaign,
    isQa,
    onCreatorSelected,
    onReachBottom,
    reportAsIncorrect,
    sendOffer,
    showCreateFeatures = true,
    showRelevantPostImage,
    socialAccounts,
    selfServeExperiment,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const forceUpdate = useForceUpdate();
  useImperativeHandle(forwardedRef, () => ({
    container: ref.current,
    forceUpdate: () => forceUpdate(),
  }));

  const {
    loading: isFetchingCommunities,
    data: {
      communities = null,
    } = {},
  } = useCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      projects = [],
    } = {},
    loading: isFetchingAllPrograms,
  } = useGetAllProjectsQuery({
    fetchPolicy: 'cache-and-network',
  });

  // Adjust column width on resize
  const [columnWidth, setColumnWidth] = useState<number>(null);
  useLayoutEffect(() => {
    if (typeof window !== 'object') {
      return;
    }

    const onWindowResize = () => {
      const node = ref.current;
      if (!node) {
        return;
      }

      // Consider browser's scrollbar width (usually 15px)
      const defaultColumnWidth = 330;
      const nodeWidth = node.offsetWidth - (browserHasScrollbar() ? 15 : 0);
      const minColumnWidth = Math.max(
        Math.floor(nodeWidth / Math.ceil(nodeWidth / defaultColumnWidth)),
        280,
      );
      const maxColumnWidth = Math.min(
        Math.floor(nodeWidth / Math.floor(nodeWidth / defaultColumnWidth)),
        380,
      );

      // choose column width closer to default column width
      if (
        Math.abs(minColumnWidth - defaultColumnWidth)
        <= Math.abs(maxColumnWidth - defaultColumnWidth)
      ) {
        setColumnWidth(minColumnWidth);
      } else {
        setColumnWidth(maxColumnWidth);
      }
    };

    window.addEventListener('resize', debounce(onWindowResize, 200), { passive: true });
    defer(onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  const itemProps: ICreatorTileProps[] = map(
    socialAccounts,
    (socialAccount): ICreatorTileProps => ({
      key: socialAccount.id,
      allowFavorite,
      apiEndpoint,
      isQa,
      campaign,
      socialAccount,
      favoriteLists,
      showReview: true,
      showRelevantPostImage,
      showCreateFeatures,
      showInviteToProgram: !showCreateFeatures,
      searchedMentions: props.searchedMentions,
      inviteToCampaign: isFunction(inviteToCampaign) && bind(inviteToCampaign, {}, socialAccount),
      sendOffer: isFunction(sendOffer) && bind(sendOffer, {}, socialAccount.id),
      goToManage: isFunction(goToManage) && bind(goToManage, {}, socialAccount.relation_id),
      reportAsIncorrect: isFunction(reportAsIncorrect) && bind(reportAsIncorrect, {}, socialAccount.name),
      onCreatorSelected: isFunction(onCreatorSelected) && bind(onCreatorSelected, {}, socialAccount),
      selfServeExperiment,
      communities,
      projects,
      isFetchingCommunities,
      isFetchingAllPrograms,
    }),
  );

  return (
    <div
      className={cx(className, styles.GridView)}
      ref={ref}
    >
      {/* only mount MasonryGrid after column width is calculated */}
      {columnWidth && (
        <MasonryGrid
          columnWidth={columnWidth}
          itemComponent={CreatorTile}
          itemProps={itemProps}
          defaultItemHeight={CreatorTile.defaultHeight}
          onReachBottom={onReachBottom}
          calculateHeight={false}
          checkLayoutCapacity={false}
        />
      )}
    </div>
  );
};

const ConnectedGridView = connect<IStateProps, unknown, IOwnProps>(mapStateToProps)(React.memo(GridViewComponent));

export const GridView = React.forwardRef((props: IOwnProps, ref: React.RefObject<IGridViewRef>) => (
  <ConnectedGridView {...props} forwardedRef={ref} />
));

GridView.displayName = 'GridView';
