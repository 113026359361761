import * as React from 'react';

import { Form, Select } from '@revfluence/fresh';
import { OFFER_CONVERSION_TYPE } from '@affiliates/types/globalTypes';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { IAffiliateLinksFormElementProps } from '../../../types';

const { Option } = Select;

interface IProps extends IAffiliateLinksFormElementProps<'conversionType'> { }

export const OfferConversionType: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => (
  <Form.Item label="Conversion Type" name={name}>
    <Select
      disabled={disabled}
      id={testIds.conversionType}
      placeholder="Select"
      size="large"
    >
      <Option value={OFFER_CONVERSION_TYPE.CONVERSIONS}>
        Conversions
      </Option>
      <Option value={OFFER_CONVERSION_TYPE.DOWNLOADS}>
        Downloads
      </Option>
      <Option value={OFFER_CONVERSION_TYPE.EMAIL_SUBMITS}>
        Email Submits
      </Option>
      <Option value={OFFER_CONVERSION_TYPE.LEAD_GENERATIONS}>
        Lead Generations
      </Option>
    </Select>
  </Form.Item>
));

OfferConversionType.displayName = 'OfferConversionType';
