import { QueryHookOptions, useQuery } from '@apollo/client';

import { LIST_TEMPLATES_QUERY } from '@frontend/app/queries';
import {
  ListTemplates,
  ListTemplates_listTemplates_results,
  ListTemplatesVariables,
} from '@frontend/app/queries/types/ListTemplates';

export type TTemplate = ListTemplates_listTemplates_results;
type IOptions = QueryHookOptions<ListTemplates, ListTemplatesVariables>;

export const useListTemplatesQuery = (options: IOptions = {}) =>
  useQuery<ListTemplates, ListTemplatesVariables>(LIST_TEMPLATES_QUERY, options);
