import { Line as RechartLine } from 'recharts';
import styles from '../../Charts.module.scss';

// Work around to wrap Line component.
// Issue: https://github.com/recharts/recharts/issues/412#issuecomment-393407132
export class Line extends RechartLine {
  static defaultProps = {
    ...RechartLine.defaultProps,
    stroke: styles.green,
    strokeWidth: 3,
    dot: false,
    type: 'basis',
  };
}
