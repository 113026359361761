import Accounts from './components/Accounts';

export enum Tabs {
  Accounts = 'Budget',
}

export const TAB_CONFIG = {
  [Tabs.Accounts]: {
    key: 'Accounts',
    label: 'Budget',
    component: Accounts,
  },
};
