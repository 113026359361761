import { useMutation, MutationHookOptions } from '@apollo/client';

import { DELETE_SEGMENT } from '@frontend/app/queries';
import {
  DeleteSegmentMutation,
  DeleteSegmentMutationVariables,
} from '@frontend/app/queries/types/DeleteSegmentMutation';

type IOptions = MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>;

export const useDeleteSegment = (options: IOptions = {}) => useMutation<DeleteSegmentMutation, DeleteSegmentMutationVariables>(DELETE_SEGMENT, options);
