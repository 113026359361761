import React, { createContext, useContext, ReactNode } from 'react';

interface IClientFeatureContext {
  allowMultipleConversions?: boolean;
  thirdPartyTrackingUrl?: boolean;
  archiveOffer?: boolean;
  isMultiCurrencyEnabled?: boolean;
  memberPortalFeatureFlagEnabled?: boolean;
  editUtmFieldsEnabled?: boolean;
  migrateToGraphQL?: boolean;
  isEnabledMultipleShopify?: boolean;
  rejectMemberConversions?: boolean;
  refreshUi?: boolean;
  deduplicateConversions?: boolean;
  manageDeepLinksAllowed?: boolean;
}

const defaultContext: IClientFeatureContext = {
  allowMultipleConversions: false,
  thirdPartyTrackingUrl: false,
  archiveOffer: false,
  isMultiCurrencyEnabled: false,
  memberPortalFeatureFlagEnabled: false,
  editUtmFieldsEnabled: false,
  migrateToGraphQL: false,
  isEnabledMultipleShopify: false,
  rejectMemberConversions: false,
  deduplicateConversions: false,
  manageDeepLinksAllowed: false,
  refreshUi: false,
};

const ClientFeatureContext = createContext<IClientFeatureContext | undefined>(undefined);

interface IProps {
  featureFlags: IClientFeatureContext;
  children: ReactNode;
}

export const ClientFeatureProvider: React.FC<IProps> = ({ featureFlags, children }) => (
  <ClientFeatureContext.Provider
    value={{
        ...featureFlags,
      }}
  >
    {children}
  </ClientFeatureContext.Provider>
  );

export const useClientFeature = () => {
  const context = useContext(ClientFeatureContext);
  if (!context) {
    return defaultContext;
  }
  return context;
};
