/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { CircleQuestionIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  head,
  isEmpty,
  isEqual,
  isNil,
  isString,
  keys,
  map,
  uniq,
  toLower,
} from 'lodash';

import {
  AmbassadorIcon,
  ThumbsUpIcon,
  Button,
  TooltipIcon,
  Image,
  LazyImage,
  IMasonryItemProps,
  ProgramsList,
  Tooltip,
  NetworkIcon,
  IProspect,
  TProgram,
  CreatorActionButtonGroup,
  campaignIneligibleReason,
  campaignWarningReason,
  networkConfig,
  getManagerName,
  failedImage,
  IToastRefHandles,
  Toast,
  ICampaign,
  ISocialAccount,
  IFavoriteList,
  IClientReview,
  FavoriteButton,
  Invite,
  Favorite,
} from '@components';
import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';
import {
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';

import styles from './CreatorTile.scss';

const ASSETS = process.env.ASSETS;
const defaultAvatar = `${ASSETS}/default_avatar.png`;

export interface ICreatorTileProps extends IMasonryItemProps {
  className?: string;
  isQa: boolean;
  showRelevantPostImage?: boolean;
  socialAccount: ISocialAccount;
  campaign?: ICampaign;
  allowFavorite?: boolean;
  favoriteLists?: IFavoriteList[];
  apiEndpoint: string;
  communities?: ICommunity[];
  projects?: IProject[];
  isFetchingCommunities?: boolean;
  isFetchingAllPrograms?: boolean;
  showHideButton?: boolean;
  showInviteButton?: boolean;
  showOfferButton?: boolean;
  selfServeExperiment?: boolean;
  showCreateFeatures: boolean;
  showInviteToProgram?: boolean;
  searchedMentions?: string;
  inviteToCampaign();
  sendOffer?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  goToManage?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  reportAsIncorrect();
  darkMode?: boolean; // set metric text to white

  showReview?: boolean;
  reviews?: IClientReview[];
  onCreatorSelected?();
}

type TDefaultProp =
  | 'allowFavorite'
  | 'darkMode'
  | 'onCreatorSelected'
  | 'onItemHeightChanged'
  | 'reviews'
  | 'showRelevantPostImage'
  | 'showReview'
  | 'showHideButton'
  | 'showInviteButton'
  | 'showOfferButton'
  | 'showInviteToProgram';

interface IState {
  hover: boolean;

  showTooltip: boolean;
  selectedImageUrl: string;
  imageRefs: {
    [name: string]: React.RefObject<any>;
  };

  isInviting: boolean;
  showMoreFeedbacks: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class CreatorTile extends React.PureComponent<ICreatorTileProps, IState> {
  public static defaultProps: Pick<ICreatorTileProps, TDefaultProp> = {
    allowFavorite: false,
    showRelevantPostImage: false,
    showReview: false,
    showHideButton: false,
    darkMode: false,
    showInviteButton: true,
    showOfferButton: true,
    showInviteToProgram: false,
    reviews: [],
    onItemHeightChanged: () => undefined,
    onCreatorSelected: () => undefined,
  };

  public static defaultHeight = 500;

  private ref: React.RefObject<HTMLDivElement>;

  private moreUsersRef: React.RefObject<HTMLSpanElement>;

  private infoIconRef: React.RefObject<HTMLDivElement>;

  private height: number;

  private toastRef: React.RefObject<IToastRefHandles>;

  constructor(props: ICreatorTileProps) {
    super(props);

    this.height = 300;
    this.ref = React.createRef();
    this.moreUsersRef = React.createRef();
    this.infoIconRef = React.createRef();
    this.toastRef = React.createRef();

    // set image refs
    const imageRefs = {};
    map(uniq(props.socialAccount.images), (url) => (imageRefs[url] = React.createRef()));

    this.state = {
      hover: false,

      showTooltip: false,
      selectedImageUrl: null,
      imageRefs,

      isInviting: false,

      showMoreFeedbacks: false,
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: ICreatorTileProps, prevState: IState) {
    const { socialAccount } = nextProps;
    const { imageRefs } = prevState;

    // update refs when socialAccount urls changed
    if (!isEqual(uniq(socialAccount.images), keys(imageRefs))) {
      // update refs
      const imageRefs = {};
      map(uniq(socialAccount.images), (url) => (imageRefs[url] = React.createRef()));

      return {
        imageRefs,
        selectedImageUrl: null,
      };
    }

    return null;
  }

  /**
   * @inheritdoc
   */
  public componentDidUpdate() {
    const { showReview } = this.props;

    if (showReview) {
      this.checkItemHeight();
    }
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      allowFavorite,
      apiEndpoint,
      campaign,
      className,
      isQa,
      onCreatorSelected,
      reportAsIncorrect,
      reviews,
      showCreateFeatures = true,
      showInviteToProgram,
      showRelevantPostImage,
      showReview,
      socialAccount,
      projects,
      communities,
      isFetchingAllPrograms,
      isFetchingCommunities,
      searchedMentions,
      darkMode,
    } = this.props;
    const {
      hover,
      imageRefs,
      showTooltip,
      selectedImageUrl,
      isInviting,
    } = this.state;

    const images = uniq(socialAccount.images || []).filter((url) => !failedImage.contains(url));

    const mainImage = (() => {
      if (showRelevantPostImage && socialAccount?.relevant_post?.image) {
        return socialAccount.relevant_post.image;
      } else if (images?.length) {
        return head(images);
      } else if (socialAccount?.posts?.length && socialAccount.posts[0]?.image) {
        return socialAccount.posts[0].image;
      }
    })();

    const ineligible = isString(socialAccount.ineligible_reason);
    const warning = isString(socialAccount.warning_reason);
    return (
      <div
        ref={this.ref}
        className={cx(className, styles.CreatorTile)}
        onMouseLeave={this.handleMouseLeave}
        onMouseEnter={this.handleMouseEnter}
      >
        <div
          className={cx(styles.content, {
            [styles.ineligible]: ineligible,
          })}
        >
          <div className={styles.media}>
            <Toast useFresh ref={this.toastRef} />
            <Image
              className={styles.image}
              src={mainImage}
              onSizeDetected={this.checkItemHeight}
              onError={this.handleImageError.bind(this, mainImage)}
              onClick={onCreatorSelected}
            />
            <div
              className={cx(styles.moreImages, {
                [styles.active]: hover,
              })}
            >
              {images.slice(1, 6).map((url) => (
                <LazyImage
                  key={url}
                  src={url}
                  ref={imageRefs[url]}
                  className={styles.imageItem}
                  onError={this.handleImageError.bind(this, url)}
                  onMouseEnter={this.showTooltip.bind(this, url)}
                  onMouseLeave={this.hideTooltip}
                />
              ))}
            </div>
            <Tooltip
              placement="bottom"
              mountRef={imageRefs[selectedImageUrl]}
              autoRegisterListener={false}
              show={showTooltip}
              className={styles.Tooltip}
            >
              <Image className={styles.image} src={selectedImageUrl} />
            </Tooltip>
          </div>
          <div className={styles.details}>
            <div
              onClick={onCreatorSelected}
              className={styles.userInfo}
            >
              <Avatar
                socialAccount={socialAccount}
                handleImageError={this.handleImageError}
                disableLink
              />
              <UserInfo
                socialAccount={socialAccount}
                disableLink
              />
              {isQa && showCreateFeatures && (
                <Button
                  className={styles.admin}
                  label="Admin"
                  theme="light"
                  onClick={this.openAdminPage}
                />
              )}
              {allowFavorite && (
                <FavoriteButton
                  className={styles.favoriteButton}
                  size={20}
                  apiEndpoint={apiEndpoint}
                  campaign={campaign}
                  socialAccount={socialAccount}
                />
              )}
              {socialAccount?.username === toLower(searchedMentions) && (
                <>
                  <div className={styles.infoIcon} ref={this.infoIconRef}>
                    <CircleQuestionIcon />
                  </div>
                  <Tooltip
                    placement="right"
                    mountRef={this.infoIconRef}
                    show
                  >
                    <div className={styles.infoTooltip}>
                      <h4>Why am I seeing this result?</h4>
                      Exact username matches will always be shown in search results,
                      regardless of any other selected criteria.
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
            {!showCreateFeatures && showInviteToProgram && (
              <ProgramsList
                className={styles.programsList}
                socialAccount={socialAccount}
                onProgramsLoaded={() => this.forceUpdate()}
              />
            )}
            <Metrics
              darkMode={darkMode}
              socialAccount={socialAccount}
            />
            {showReview && !isEmpty(reviews) && (
              <div className={styles.reviews}>
                {this.renderApprovedStatus()}
                {this.renderFeedbacks()}
              </div>
            )}
          </div>
          {!showCreateFeatures && showInviteToProgram && (hover || isInviting) && (
            <div className={cx(
              styles.invite,
              styles.inviteWithFavorite,
              {
                [styles.show]: hover,
              },
            )}
            >
              <Favorite
                className={styles.favoriteWidget}
                prospect={{ socialAccount }}
                communities={communities}
              />
              <Invite
                prospect={{ socialAccount }}
                onInviteStatusUpdate={this.onInviteStatusUpdate}
                communities={communities}
                projects={projects}
                isFetchingCommunities={isFetchingCommunities}
                isFetchingAllPrograms={isFetchingAllPrograms}
                onInvite={(prospect: IProspect, programs: TProgram[]) => {
                  this.forceUpdate();
                  if (this.toastRef && this.toastRef.current) {
                    this.toastRef.current.showMessage({
                      content: (
                        <>
                          Invited
                          {' '}
                          <span>
                            {prospect?.socialAccount?.username || prospect?.socialAccount?.display_name}
                          </span>
                          {' '}
                          to
                          {' '}
                          <span>{programs[0]?.title}</span>
                        </>
                      ),
                      type: 'success',
                    });
                  }
                }}
              />
            </div>
          )}
          {!ineligible && showCreateFeatures && this.renderActions()}
        </div>
        {ineligible && (
          <div className={styles.ineligibleReason}>
            <div className={(styles as any).text}>
              This creator cannot be invited to your campaign because:
              <span className={styles.reason}>
                {' '}
                {campaignIneligibleReason[socialAccount.ineligible_reason]}
              </span>
            </div>
            <div className={styles.link} onClick={reportAsIncorrect}>
              Report as Incorrect
            </div>
          </div>
        )}
        {warning && !ineligible && (
          <div className={styles.ineligibleReason}>
            <div className={(styles as any).text}>
              This creator may not meet your campaigns requirements as we cannot verify the creators
              <span className={styles.reason}>
                {' '}
                {campaignWarningReason[socialAccount.warning_reason]}
              </span>
            </div>
            <div className={styles.link} onClick={reportAsIncorrect}>
              Report as Incorrect
            </div>
          </div>
        )}
      </div>
    );
  }

  private renderApprovedStatus = () => {
    const { reviews } = this.props;
    const approvedReviews = reviews.filter((review) => review.approved);

    if (isEmpty(approvedReviews)) {
      return null;
    }

    return (
      <div className={styles.approveSection}>
        <ThumbsUpIcon size={18} className={styles.thumbsUpIcon} />
        {approvedReviews.length === 1 && `${getManagerName(approvedReviews[0].user)} approved`}
        {approvedReviews.length === 2
          && `${getManagerName(approvedReviews[0].user)} and ${getManagerName(
            approvedReviews[1].user,
          )} approved`}
        {approvedReviews.length === 3
          && `${getManagerName(approvedReviews[0].user)},
           ${getManagerName(approvedReviews[1].user)} and
           ${getManagerName(approvedReviews[2].user)} approved`}
        {approvedReviews.length > 3 && (
          <span>
            {getManagerName(approvedReviews[0].user)}
            ,
            {getManagerName(approvedReviews[1].user)}
            {' '}
            and
            <span className={styles.moreReviewUsers} ref={this.moreUsersRef}>
              {' '}
              {approvedReviews.length - 2}
              {' '}
              others
              {' '}
            </span>
            approved
            <Tooltip mountRef={this.moreUsersRef} className={styles.MoreUsersTooltip}>
              <div className={styles.content}>
                {approvedReviews
                  .slice(2)
                  .map((review) => getManagerName(review.user))
                  .join(', ')}
              </div>
            </Tooltip>
          </span>
        )}
      </div>
    );
  };

  /**
   * @private
   * Renders the feedbacks section.
   *
   * @return {JSX}
   */
  private renderFeedbacks = () => {
    const { reviews } = this.props;
    const { showMoreFeedbacks } = this.state;

    const nonEmptyReviews = reviews.filter((review) => !isEmpty(review.comment));
    if (isEmpty(nonEmptyReviews)) {
      return null;
    }

    const reviewsToShow = showMoreFeedbacks ? nonEmptyReviews : nonEmptyReviews.slice(0, 1);

    return (
      <div className={styles.feedbackSection}>
        {map(reviewsToShow, (review, index) => (
          <div key={index} className={styles.feedbackItem}>
            <div className={styles.feedbackTitle}>
              {getManagerName(review.user)}
              &apos;s feedback:
            </div>
            <div>{review.comment}</div>
          </div>
        ))}
        {nonEmptyReviews.length > 1 && (
          <div className={styles.showMore} onClick={this.toggleShowMore}>
            {showMoreFeedbacks ? 'Show less' : 'Show more'}
          </div>
        )}
      </div>
    );
  };

  /**
   * @private
   * Renders the action buttons.
   */
  private renderActions = () => {
    const {
      goToManage,
      sendOffer,
      showHideButton,
      showInviteButton,
      showOfferButton,
      socialAccount,
      selfServeExperiment,
    } = this.props;
    if (selfServeExperiment) {
      return null;
    }
    const { isInviting } = this.state;

    return (
      <CreatorActionButtonGroup
        className={styles.actions}
        inviteCallback={this.inviteToCampaign}
        isInviting={isInviting}
        manageCallback={goToManage}
        offerCallback={sendOffer}
        showHideButton={showHideButton}
        showInviteButton={showInviteButton}
        showOfferButton={showOfferButton}
        socialAccount={socialAccount}
      />
    );
  };

  private toggleShowMore = () => {
    const { showMoreFeedbacks } = this.state;

    this.setState({
      showMoreFeedbacks: !showMoreFeedbacks,
    });
  };

  private inviteToCampaign = () => {
    const { inviteToCampaign } = this.props;

    this.setState({
      isInviting: true,
    });
    return inviteToCampaign().finally(() => {
      this.setState({
        isInviting: false,
      });
    }).catch(console.error('Invite to campaign error'));
  };

  /**
   * @private
   * Checks if item height has changed, notify parent if so.
   */
  private checkItemHeight = () => {
    const { onItemHeightChanged } = this.props;
    const node = this.ref.current;
    if (!node) {
      return;
    }

    const newHeight = node.getBoundingClientRect().height;

    if (newHeight !== this.height) {
      this.height = newHeight;

      onItemHeightChanged(this.height);
    }
  };

  /**
   * @private
   * Handler for when image load failed.
   *
   * @param {String} url the image url.
   */
  private handleImageError = (url: string) => {
    failedImage.add(url);

    this.forceUpdate();
  };

  /**
   * @private
   * Handler for 'mouseenter' event.
   */
  private handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  private onInviteStatusUpdate = (isInviting) => {
    this.setState({
      isInviting,
    });
  };

  /**
   * @private
   * Handler for 'mouseleave' event.
   */
  private handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };

  /**
   * @private
   * Sets the selected image url, and shows the tooltip.
   *
   * @param {String} selectedImageUrl the selected image url.
   */
  private showTooltip = (selectedImageUrl) => {
    this.setState({
      selectedImageUrl,
      showTooltip: true,
    });
  };

  /**
   * @private
   * Hides the tooltip.
   */
  private hideTooltip = () => {
    this.setState({
      showTooltip: false,
    });
  };

  /**
   * @private
   * Opens the social account admin page.
   */
  private openAdminPage = () => {
    const { socialAccount } = this.props;

    window.open(`/admin/social_account/${socialAccount.id}`, '_blank');
  };
}

/**
 * @class
 * @extends {React.FunctionComponent}
 * The user avatar.
 */
const Avatar: React.FunctionComponent<{
  socialAccount: ISocialAccount;
  handleImageError(url: string);
  disableLink?: boolean;
}> = ({ socialAccount, handleImageError, disableLink }) => {
  const imageUrl = socialAccount.profile_image || socialAccount.profile_picture;

  if (disableLink) {
    return (
      <div className={styles.avatar}>
        <LazyImage
          className={styles.avatarImage}
          src={failedImage.contains(imageUrl) ? null : imageUrl}
          fallbackSrc={defaultAvatar}
          onError={() => handleImageError(imageUrl)}
        />
        {socialAccount.network_identifier && (
          <div className={styles.source}>
            <NetworkIcon identifier={socialAccount.network_identifier} />
          </div>
        )}
      </div>
    );
  }

  return (
    <a
      className={styles.avatar}
      href={socialAccount.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <LazyImage
        className={styles.avatarImage}
        src={failedImage.contains(imageUrl) ? null : imageUrl}
        fallbackSrc={defaultAvatar}
        onError={() => handleImageError(imageUrl)}
      />
      {socialAccount.network_identifier && (
        <div className={styles.source}>
          <NetworkIcon identifier={socialAccount.network_identifier} />
        </div>
      )}
    </a>
  );
};

/**
 * @class
 * @extends {React.FunctionComponent}
 * The user info section.
 */
const UserInfo: React.FunctionComponent<{
  socialAccount: ISocialAccount;
  disableLink?: boolean;
}> = ({ socialAccount, disableLink }) => (
  <div className={styles.info}>
    <div className={styles.name}>
      {disableLink && (
        <div>
          {socialAccount.username || socialAccount.name}
          {socialAccount.is_ambassador && (
            <TooltipIcon
              className={styles.ambIcon}
              icon={<AmbassadorIcon size={14} />}
              tooltipText="Aspire Ambassador verified by our community team"
            />
          )}
        </div>
      )}
      {!disableLink && (
        <>
          <a
            href={socialAccount.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {socialAccount.username || socialAccount.name}
          </a>
          {socialAccount.is_ambassador && (
            <TooltipIcon
              className={styles.ambIcon}
              icon={<AmbassadorIcon size={14} />}
              tooltipText="Aspire Ambassador verified by our community team"
            />
          )}
        </>
      )}
    </div>
  </div>
);

/**
 * @class
 * @extends {React.FunctionComponent}
 * Social post reach.
 */
const Metrics: React.FunctionComponent<{
  socialAccount: ISocialAccount;
  darkMode: boolean;
}> = ({ darkMode, socialAccount }) => {
  const contentName = networkConfig[socialAccount.network_identifier].contentName;

  const reach = (
    socialAccount.reach <= 0
    && socialAccount.network_identifier === 'pinterest'
    && socialAccount.has_pinterest_access_token === false
  )
    ? '-'
    : numeral(socialAccount.reach).format('0.[0]a').toUpperCase();

  return (
    <div className={styles.metrics}>
      <div
        className={cx(styles.reach, {
            [styles.darkMode]: darkMode,
          })}
      >
        <NetworkIcon identifier={socialAccount.network_identifier} />
        <div className={styles.amount}>
          {reach}
        </div>
        {(reach !== '-' && reach !== '0') && (
          <div className={styles.unit}>
            {networkConfig[socialAccount.network_identifier].audienceNamePlural}
          </div>
        )}
      </div>
      <div className={styles.engagement}>
        {!isNil(socialAccount.engagement)
          ? numeral(socialAccount.engagement)
            .format('0.[0]a')
            .toUpperCase()
          : '-'}
        <span className={styles.unit}>
          engs/
          {contentName}
        </span>
      </div>
    </div>
  );
};
