import { logger } from '@common';
import { isEmpty } from 'lodash';
import { ActionType, TState } from '../types';

interface IAction {
  error: Error;
  type: ActionType.SET_ERROR;
}
export type TSetErrorAction = Readonly<IAction>;

export const setError = (state: TState, action: TSetErrorAction): TState => {
  logger.error(action.error);
  if (action && action.error.message && !isEmpty(action.error.message)) {
    return {
      ...state,
      error: new Error(action.error.message),
    };
  }
  return {
    ...state,
    error: new Error(`Oops! Something went wrong. Please refresh or try again later.${JSON.stringify(action)}`),
  };
};
