/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17rjw_157 {
  display: flex;
}

._justify-content-space-between_17rjw_161 {
  justify-content: space-between;
}

._tabular-nums_17rjw_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17rjw_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._ThickBarChart_17rjw_177 {
  display: flex;
  flex: 1;
  flex-direction: column;
}
._ThickBarChart_17rjw_177 ._chart_17rjw_182 {
  height: auto;
  display: flex;
  flex: 1;
  animation: _show_17rjw_1 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.25s;
}
._ThickBarChart_17rjw_177 ._chart_17rjw_182 ._barGroup_17rjw_188 {
  position: relative;
}
._ThickBarChart_17rjw_177 ._chart_17rjw_182 ._barGroup_17rjw_188 ._customLabel_17rjw_191 {
  height: 1px;
  overflow: visible;
  position: relative;
  opacity: 0;
  -webkit-transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}
._ThickBarChart_17rjw_177 ._chart_17rjw_182 ._barGroup_17rjw_188 ._customLabel_17rjw_191 ._customLabelContainer_17rjw_199 {
  width: auto;
  height: auto;
  margin-top: 0.5rem;
  -webkit-transition: margin-top 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transition: margin-top 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}
._ThickBarChart_17rjw_177 ._chart_17rjw_182 ._barGroup_17rjw_188 ._customLabel_17rjw_191._visible_17rjw_206 {
  opacity: 1;
}
._ThickBarChart_17rjw_177 ._chart_17rjw_182 ._barGroup_17rjw_188 ._customLabel_17rjw_191._visible_17rjw_206 ._customLabelContainer_17rjw_199 {
  margin-top: 0rem;
}
._ThickBarChart_17rjw_177 ._chart_17rjw_182 ._barGroup_17rjw_188 ._bar_17rjw_188 {
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
}