import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';

export interface SelectedProduct {
  productId: string;
  variantIds: string[];
}

export interface BasicSelectionContextProps {
  selectedProducts: SelectedProduct[];
  selectedVariantsInProductCount: Record<string, number>;
  search: string;
  isOpen: boolean;
  collectionId: number;
  catalogCollectionId: number;
  isEditing: boolean;
  isAllProductsFetching: boolean;
  toggleProduct: (payload: { productId: string; variantIds: string[], checked: boolean }) => void;
  toggleVariant: (payload: { variantId: string, productId: string, checked: boolean, allVariantIds: string[] }) => void;
  setSearch: (search: string) => void;
  bulkSelect: (payload: { products: Array<{ id: string, totalVariants: number }>; }) => void;
  bulkSelectVariants: (payload: { productId: string, variantIds: string[] }) => void;
  resetBasicSelection: () => void;
  resetEditing: () => void;
  setIsOpen: (isOpen: boolean) => void;
  openEditingModal: (payload: { collectionId: number, catalogCollectionId: number }) => void;
  openFeaturedProductsEditingModal: () => void;
  setCollectionId: (collectionId: number) => void;
  setIsAllProductsFetching: (isAllProductsFetching: boolean) => void;
  setCatalogCollectionId: (catalogCollectionId: number) => void;
  setEditingDetails: (payload: { collectionId: number, catalogCollectionId: number }) => void;
}
export type BasicSelectionContextState = Omit<
  BasicSelectionContextProps,
  | 'toggleProduct'
  | 'toggleVariant'
  | 'setSearch'
  | 'bulkSelect'
  | 'bulkSelectVariants'
  | 'resetBasicSelection'
  | 'setIsOpen'
  | 'openEditingModal'
  | 'openFeaturedProductsEditingModal'
  | 'setCollectionId'
  | 'resetEditing'
  | 'setIsAllProductsFetching'
  | 'setCatalogCollectionId'
  | 'setEditingDetails'
>;

const initialState: BasicSelectionContextState = {
  selectedProducts: [],
  selectedVariantsInProductCount: {},
  search: '',
  isOpen: false,
  collectionId: null,
  catalogCollectionId: null,
  isEditing: false,
  isAllProductsFetching: false,
};

export const BasicSelectionContext = createContext<BasicSelectionContextProps>({
  ...initialState,
  toggleProduct: () => {},
  toggleVariant: () => {},
  setSearch: () => {},
  bulkSelect: () => {},
  bulkSelectVariants: () => {},
  resetBasicSelection: () => {},
  setIsOpen: () => {},
  openEditingModal: () => {},
  openFeaturedProductsEditingModal: () => {},
  setCollectionId: () => {},
  resetEditing: () => {},
  setIsAllProductsFetching: () => {},
  setCatalogCollectionId: () => {},
  setEditingDetails: () => {},
});

export enum ActionType {
  ToggleProduct = 'TOGGLE_PRODUCT',
  ToggleVariant = 'TOGGLE_VARIANT',
  SetSearch = 'SET_SEARCH',
  BulkSelect = 'BULK_SELECT',
  BulkSelectVariants = 'BULK_SELECT_VARIANTS',
  ResetBasicSelection = 'RESET_BASIC_SELECTION',
  SetIsOpen = 'SET_IS_OPEN',
  OpenEditingModal = 'OPEN_EDITING_MODAL',
  OpenFeaturedProductsEditingModal = 'OPEN_FEATURED_PRODUCTS_EDITING_MODAL',
  SetCollectionId = 'SET_COLLECTION_ID',
  ResetEditing = 'RESET_EDITING',
  SetIsAllProductsFetching = 'SET_IS_ALL_PRODUCTS_FETCHING',
  SetCatalogCollectionId = 'SET_CATALOG_COLLECTION_ID',
  SetEditingDetails = 'SET_EDITING_DETAILS',
}

export type Action = {
  type: ActionType.SetSearch;
  payload: string;
} | {
  type: ActionType.ToggleProduct;
  payload: { productId: string; variantIds: string[], checked: boolean };
} | {
  type: ActionType.ToggleVariant;
  payload: { variantId: string; productId: string, checked: boolean, allVariantIds: string[] };
} | {
  type: ActionType.BulkSelect;
  payload: { products: Array<{ id: string, totalVariants: number }>; };
} | {
  type: ActionType.BulkSelectVariants;
  payload: { productId: string, variantIds: string[] };
} | {
  type: ActionType.ResetBasicSelection;
} | {
  type: ActionType.SetIsOpen;
  payload: boolean;
} | {
  type: ActionType.OpenEditingModal;
  payload: { collectionId: number, catalogCollectionId: number };
} | {
  type: ActionType.SetCollectionId;
  payload: number;
} | {
  type: ActionType.ResetEditing;
} | {
  type: ActionType.SetIsAllProductsFetching;
  payload: boolean;
} | {
  type: ActionType.SetCatalogCollectionId;
  payload: number;
} | {
  type: ActionType.SetEditingDetails;
  payload: { collectionId: number, catalogCollectionId: number };
} | {
  type: ActionType.OpenFeaturedProductsEditingModal
};

export const BasicSelectionProvider: React.FC = ({ children }) => {
  const reducer = (state: BasicSelectionContextState, action: Action): BasicSelectionContextState => {
    switch (action.type) {
      case ActionType.ToggleProduct: {
        const { productId, variantIds, checked } = action.payload;
        const { selectedProducts } = state;
        let newSelectedProducts: SelectedProduct[] = [];
        let newSelectedVariantsInProductCount = 0;
        if (checked) {
          newSelectedProducts = [...selectedProducts, {
            productId,
            variantIds: [],
          }];
          newSelectedVariantsInProductCount = variantIds.length;
        } else {
          newSelectedProducts = selectedProducts.filter((product) => product.productId !== productId);
          newSelectedVariantsInProductCount = 0;
        }
        return {
          ...state,
          selectedProducts: newSelectedProducts,
          selectedVariantsInProductCount: {
            ...state.selectedVariantsInProductCount,
            [productId]: newSelectedVariantsInProductCount,
          },
        };
      }
      case ActionType.ToggleVariant: {
        const {
 variantId, productId, checked, allVariantIds,
} = action.payload;
        const { selectedProducts } = state;
        let newSelectedProducts: SelectedProduct[] = [...selectedProducts];
        let newSelectedVariantsInProductCount = 0;
        let selectedProductIndex = newSelectedProducts.findIndex((product) => product.productId === productId);
        if (selectedProductIndex === -1) {
          newSelectedProducts.push({
            productId,
            variantIds: [],
          });
          selectedProductIndex = newSelectedProducts.length - 1;
        }
        if (checked) {
          newSelectedProducts[selectedProductIndex] = {
            productId,
            variantIds: [...newSelectedProducts[selectedProductIndex].variantIds, variantId],
          };
          newSelectedVariantsInProductCount = 1;
        } else {
          if (newSelectedProducts[selectedProductIndex].variantIds.length === 0) {
            newSelectedProducts[selectedProductIndex] = {
              productId,
              variantIds: [...allVariantIds],
            };
          }
          newSelectedProducts[selectedProductIndex] = {
            productId,
            variantIds: newSelectedProducts[selectedProductIndex].variantIds.filter((id) => id !== variantId),
          };
          newSelectedVariantsInProductCount = -1;
          const atLeastOneVariantSelected = newSelectedProducts[selectedProductIndex].variantIds.length > 0;
          if (!atLeastOneVariantSelected) {
            newSelectedProducts = newSelectedProducts.filter((product) => product.productId !== productId);
          }
        }
        return {
          ...state,
          selectedProducts: newSelectedProducts,
          selectedVariantsInProductCount: {
            ...state.selectedVariantsInProductCount,
            [productId]: (state.selectedVariantsInProductCount[productId] ?? 0) + newSelectedVariantsInProductCount,
          },
        };
      }
      case ActionType.BulkSelectVariants: {
        const { productId, variantIds } = action.payload;
        const { selectedProducts } = state;
        const newSelectedProducts: SelectedProduct[] = [...selectedProducts];
        let newSelectedVariantsInProductCount = 0;
        const selectedProductIndex = newSelectedProducts.findIndex((product) => product.productId === productId);
        newSelectedProducts[selectedProductIndex].variantIds = [...variantIds];
        newSelectedVariantsInProductCount = variantIds.length;
        return {
          ...state,
          selectedProducts: newSelectedProducts,
          selectedVariantsInProductCount: {
            ...state.selectedVariantsInProductCount,
            [productId]: newSelectedVariantsInProductCount,
          },
        };
      }
      case ActionType.SetSearch:
        return {
          ...state,
          search: action.payload,
        };
      case ActionType.BulkSelect: {
        const { products } = action.payload;
        return {
          ...state,
          selectedProducts: products.map((product) => ({
            productId: product.id,
            variantIds: [],
          })),
          selectedVariantsInProductCount: products.reduce((acc, product) => {
            acc[String(product.id)] = product.totalVariants;
            return acc;
          }, {}),
        };
      }
      case ActionType.ResetBasicSelection:
        return initialState;
      case ActionType.SetIsOpen:
        return {
          ...state,
          isOpen: action.payload,
        };
      case ActionType.OpenEditingModal:
        return {
          ...state,
          isEditing: true,
          isOpen: true,
          collectionId: action.payload.collectionId,
          catalogCollectionId: action.payload.catalogCollectionId,
        };
      case ActionType.OpenFeaturedProductsEditingModal:
        return {
          ...state,
          isEditing: true,
          isOpen: true,
        };
      case ActionType.SetCollectionId:
        return {
          ...state,
          collectionId: action.payload,
        };
      case ActionType.ResetEditing:
        return {
          ...state,
          isEditing: false,
          isOpen: false,
          search: '',
          collectionId: null,
          catalogCollectionId: null,
        };
      case ActionType.SetIsAllProductsFetching:
        return {
          ...state,
          isAllProductsFetching: action.payload,
        };
      case ActionType.SetCatalogCollectionId:
        return {
          ...state,
          catalogCollectionId: action.payload,
        };
      case ActionType.SetEditingDetails:
        return {
          ...state,
          collectionId: action.payload.collectionId,
          catalogCollectionId: action.payload.catalogCollectionId,
          isEditing: true,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      toggleProduct: (payload: { productId: string; variantIds: string[], checked: boolean }) => {
        dispatch({ type: ActionType.ToggleProduct, payload });
      },
      toggleVariant: (payload: { variantId: string, productId: string, checked: boolean, allVariantIds: string[] }) => {
        dispatch({ type: ActionType.ToggleVariant, payload });
      },
      setSearch: (payload: string) => {
        dispatch({ type: ActionType.SetSearch, payload });
      },
      bulkSelect: (payload: { products: Array<{ id: string, totalVariants: number }>; }) => {
        dispatch({ type: ActionType.BulkSelect, payload });
      },
      bulkSelectVariants: (payload: { productId: string, variantIds: string[] }) => {
        dispatch({ type: ActionType.BulkSelectVariants, payload });
      },
      resetBasicSelection: () => {
        dispatch({ type: ActionType.ResetBasicSelection });
      },
      setIsOpen: (payload: boolean) => {
        dispatch({ type: ActionType.SetIsOpen, payload });
      },
      openEditingModal: (payload: { collectionId: number, catalogCollectionId: number }) => {
        dispatch({ type: ActionType.OpenEditingModal, payload });
      },
      openFeaturedProductsEditingModal: () => {
        dispatch({ type: ActionType.OpenFeaturedProductsEditingModal });
      },
      setCollectionId: (payload: number) => {
        dispatch({ type: ActionType.SetCollectionId, payload });
      },
      resetEditing: () => {
        dispatch({ type: ActionType.ResetEditing });
      },
      setIsAllProductsFetching: (payload: boolean) => {
        dispatch({ type: ActionType.SetIsAllProductsFetching, payload });
      },
      setCatalogCollectionId: (payload: number) => {
        dispatch({ type: ActionType.SetCatalogCollectionId, payload });
      },
      setEditingDetails: (payload: { collectionId: number, catalogCollectionId: number }) => {
        dispatch({ type: ActionType.SetEditingDetails, payload });
      },
    }),
    [],
  );

  const memoizedValue = useMemo<BasicSelectionContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <BasicSelectionContext.Provider value={memoizedValue}>{children}</BasicSelectionContext.Provider>;
};

export const useBasicSelectionContext = () => useContext(BasicSelectionContext);

export const useIsProductPartiallySelected = ({ productId, totalVariants }: { productId: string, totalVariants: number }) => {
  const { selectedVariantsInProductCount } = useBasicSelectionContext();
  const selectedCount = selectedVariantsInProductCount[productId] ?? 0;
  return selectedCount > 0 && selectedCount < totalVariants;
};

export const useNumberOfVariantsSelected = ({ productId }: { productId: string }) => {
  const { selectedVariantsInProductCount } = useBasicSelectionContext();
  return selectedVariantsInProductCount[productId] ?? 0;
};
