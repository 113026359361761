import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

interface IProps {
  value?: number;
  onChange?: (amount : number) => void;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  width?: string;
  className?: string;
  disabled?: boolean;
}

const CurrencyInput: React.FC<IProps> = (props: IProps) => {
  const {
 onChange, placeholder = '', prefix = '$', suffix = 'USD', value = 0, width = '', className = '', disabled = false,
} = props;

  const [amount, setAmount] = useState<number>(value);

  useEffect(() => {
    setAmount(value);
  }, [value]);

  const formatNumber = (num: number): string => num.toLocaleString();

  const unformatNumber = (formattedValue: string): number => parseFloat(formattedValue.replace(/,/g, ''));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const unformattedValue = unformatNumber(e.target.value);
    if (e.target.value == '') {
      setAmount(0);
      onChange && onChange(0);
    }
    if (!isNaN(unformattedValue)) {
      setAmount(unformattedValue);
      onChange && onChange(unformattedValue);
    }
  };

  return (
    <Input
      style={{ width: width ?? '256px' }}
      prefix={prefix}
      suffix={suffix}
      value={amount ? formatNumber(amount) : '0'}
      onChange={handleChange}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
    />
  );
};

export default CurrencyInput;
