/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';
import {
  analyticsServerApiEndpoint,
  backendServerApiEndpoint,
  backendServerWebEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import {
  IWorkflowActionParameters,
  IApplicationContainerHandle,
} from '@frontend/app/containers/Application/ApplicationContainer';

import TermsAppModal from './TermsAppModal';
import { TermsAppDashboard } from './TermsAppDashboard';

interface IProps {
  clientId: string;
  clientName: string;
  memberId: string;
  workflowActionParameters?: IWorkflowActionParameters;
  deepLinkParameters?: any;
  modalView: boolean;
  onSetTitle: (title: string) => void;
  closeModal?(): void;
}

const { useRef, useImperativeHandle } = React;

const TermsApp = React.forwardRef<IApplicationContainerHandle, IProps>((props, ref) => {
  const {
    clientId,
    clientName,
    memberId,
    modalView,
    closeModal,
    onSetTitle,
    workflowActionParameters,
    deepLinkParameters,
  } = props;

  const termsRef = useRef<IApplicationContainerHandle>(null);

  useImperativeHandle(ref, () => ({
    showWarningOnClose: termsRef.current?.showWarningOnClose,
  }));

  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={clientId}
      clientName={clientName}
      memberId={memberId}
      closeModal={closeModal}
      workflowActionParameters={workflowActionParameters}
    >
      {modalView ? (
        <TermsAppModal
          ref={termsRef}
          deepLinkParams={deepLinkParameters}
          onCloseModal={closeModal}
          onSetTitle={onSetTitle}
        />
      ) : (
        <TermsAppDashboard />
      )}
    </ApplicationProvider>
  );
});

TermsApp.displayName = 'TermsApp';

export default TermsApp;
