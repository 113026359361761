import * as React from 'react';
import {
 isEmpty, size, keyBy, map,
} from 'lodash';

import {
  Modal,
  IModalProps,
  RoundAddCircleOutlineIcon,
  ModalSize,
} from '@components';
import {
  SelectList, FormActions, NewActivationModal,
  ActivationTag,
} from '@frontend/app/components';

import { useMessagingContext } from '@frontend/hooks';
import {
  IActivation,
  useActivationsQuery,
  useFuzzySearchByKeys,
  useAddMembersToActivationsMutation,
  useRemoveMembersFromActivationsMutation,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import styles from './ModifyActivationMembersModal.scss';

const { useState, useEffect } = React;

type TType = 'add' | 'remove';

interface IProps extends IModalProps {
  query?: IMemberSearchQuery;
  memberIds?: number[];
  memberCount?: number;
  type: TType;
  showNewActivationButton?: boolean;
  onModifyComplete?(activations: IActivation[]);
}

const SEARCH_KEYS = ['name'];

export const ModifyActivationMembersModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const [selectedActivationIds, setSelectedActivationIds] = useState<number[]>([]);
  const [showNewActivationForm, setShowNewActivationForm] = useState<boolean>(false);
  const [type, setType] = useState<TType>(props.type);

  const {
    showMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  useEffect(() => {
    if (props.show) {
      setType(props.type);
    } else {
      setTimeout(() => {
        setType(props.type);
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const {
    activations,
    loading: isLoadingActivations,
  } = useActivationsQuery({
    skip: !props.show,
  });

  const [addMembersToActivations, {
    loading: isAddingMembers,
  }] = useAddMembersToActivationsMutation({
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount} members added to activations`,
      });
    },
    onError() {
      showGenericErrorMessage();
    },
  });

  const [removeMembersFromActivations, {
    loading: isRemovingMembers,
  }] = useRemoveMembersFromActivationsMutation({
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount} members removed from activations`,
      });
    },
    onError() {
      showGenericErrorMessage();
    },
  });

  const handleSearch = useFuzzySearchByKeys(activations, SEARCH_KEYS);

  type TOption = typeof activations[0];

  const mapOptionToLabel = (option: TOption) => <ActivationTag activation={option} boldFont />;

  const memberCount = size(props.memberIds) || props.memberCount;

  const addRemoveMembers = async (activationIds: number[]) => {
    const vars = isEmpty(props.memberIds) ? {
      query: props.query,
    } : {
      memberIds: props.memberIds,
    };

    switch (type) {
      case 'add':
        await addMembersToActivations({
          variables: {
            ...vars,
            activationIds,
          },
        });
        break;

      case 'remove':
        await removeMembersFromActivations({
          variables: {
            ...vars,
            activationIds,
          },
        });
        break;

      default:
        break;
    }
  };

  const addEvent = useEventContext();
  const handleSave = async (activationIds: number[]) => {
    if (!isEmpty(props.memberIds) || props.query) {
      await addRemoveMembers(activationIds);
    }

    if (props.onModifyComplete) {
      const activationsById = keyBy(activations, 'id');
      const selectedActivations = map(activationIds, (id) => activationsById[id]);
      props.onModifyComplete(selectedActivations);
    }

    if (props.onRequestClose) {
      props.onRequestClose();
    }

    const action = type === 'add' ? 'add_to_activation' : (type === 'remove' ? 'remove_from_activation' : null);
    if (action) {
      addEvent(
        EventName.CompleteBulkAction,
        {
          action,
          member_count: memberCount,
        },
      );
    }
  };

  let primaryType;
  let primaryText;
  let title;
  let subtitle;
  let emptyMessage;

  switch (type) {
    case 'add':
      title = 'Add to Activations';
      subtitle = `Add ${memberCount} members to Activations`;
      primaryText = `Add ${memberCount} Members`;
      primaryType = 'primary';
      emptyMessage = 'There are no available activations to add to';
      break;

    case 'remove':
      title = 'Remove from Activations';
      subtitle = `Remove ${memberCount} members from Activations`;
      primaryText = `Remove ${memberCount} Members`;
      primaryType = 'danger';
      emptyMessage = 'There are no available activations to remove from';
      break;

    default:
      break;
  }

  const handleClickNewActivation = () => {
    setShowNewActivationForm(true);
  };

  const isLoading = isAddingMembers || isRemovingMembers;

  const showNewActivationButton = props.showNewActivationButton && type === 'add';

  return (
    <>
      <Modal
        onRequestClose={props.onRequestClose}
        show={props.show}
        showCloseIcon={props.showCloseIcon}
        width={ModalSize.Small}
        className={styles.ModifyActivationMembersModal}
      >
        <FormActions
          title={title}
          subtitle={subtitle}
          primaryAction={{
            danger: primaryType === 'danger',
            disabled: isEmpty(selectedActivationIds) || isLoading,
            label: primaryText,
            loading: isLoading,
            onClick: handleSave.bind(this, selectedActivationIds),
          }}
          secondaryAction={showNewActivationButton
            ? {
              disabled: isLoading,
              icon: <RoundAddCircleOutlineIcon size={16} />,
              label: 'New Activation',
              onClick: handleClickNewActivation,
            }
            : undefined}
          tertiaryAction={{ label: 'Cancel', onClick: props.onRequestClose, disabled: isLoading }}
          disabled={isLoading}
        >
          <SelectList
            searchPlaceholder="Search activations..."
            showSearch
            onSearchRequest={handleSearch}
            options={activations}
            mapOptionToId={(option: TOption) => option.id}
            mapOptionToLabel={mapOptionToLabel}
            disabled={isLoading}
            onChange={(selectedIds) => setSelectedActivationIds(selectedIds as number[])}
            contentClassName={styles.content}
            isLoading={isLoadingActivations}
            emptyMessage={emptyMessage}
          />
        </FormActions>
      </Modal>
      <NewActivationModal
        show={showNewActivationForm}
        onRequestClose={setShowNewActivationForm.bind(this, false)}
      />
    </>
  );
});

ModifyActivationMembersModal.displayName = 'ModifyActivationMembersModal';
