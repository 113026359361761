import * as React from 'react';

import { map, size } from 'lodash';

import { useHistory, useLocation } from 'react-router-dom';

import { useMessagingContext } from '@frontend/hooks';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { Table, LoadSpinner, ITableColumnConfig } from '@components';
import { useValidateBulkSendMembers } from '../useFetchContracts';

import styles from './BulkContractInvalidMembers.scss';

const TABLE_COLUMNS: Array<ITableColumnConfig> = [
  {
    headerName: 'Name',
    cellType: 'text',
    field: 'name',
  },
  {
    headerName: 'Email',
    cellType: 'text',
    field: 'email',
    grow: true,
  },
];

interface IProps {
  onValidationSuccess(): void;
}

const { useMemo, useEffect } = React;

export const BulkContractInvalidMembers: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    bulkActionParameters,
    workflowActionParameters,
    backendServerApiEndpoint,
    clientId,
  } = useApplication();
  const { onValidationSuccess } = props;
  const { showErrorMessage } = useMessagingContext();

  const history = useHistory();
  const location = useLocation();

  const searchQuery = bulkActionParameters?.memberQueryJson;
  let memberIds = null;
  if (bulkActionParameters) {
    memberIds = JSON.stringify(bulkActionParameters.memberIds);
  } else if (workflowActionParameters) {
    memberIds = JSON.stringify(workflowActionParameters.memberIds);
  }

  const { data, loading, error } = useValidateBulkSendMembers(
    `${backendServerApiEndpoint}/contract/invalid_members`,
    {
      search_query: searchQuery,
      member_ids: memberIds,
      client_id: clientId,
    },
  );

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return map(data, (member) => ({
        id: member.id.toString(),
        _raw: member,
        email: member.email,
        name: member.name,
      }));
  }, [data]);

  const canProceed = useMemo(() => data && size(data) === 0, [data]);

  useEffect(() => {
    if (canProceed) {
      // Adding delay so that user can read the loading text so
      // it doesn't just seem bugged
      setTimeout(() => {
        onValidationSuccess();
      }, 2000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canProceed]);

  useEffect(() => {
    if (error) {
      showErrorMessage('Sorry we are not able to send mass contracts at this time. Please try again later.');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onRowClicked = (rowData) => {
    history.push({
      ...location,
      pathname: `/members/${rowData.id}`,
    });
  };

  return (
    <div className={styles.BulkContractInvalidMembers}>
      {
        loading || canProceed ? (
          <>
            <h1 className={styles.loadingHeader}>Checking that we can send contracts to selected members...</h1>
            <div className={styles.spinner}>
              <LoadSpinner />
            </div>
          </>
        ) : (
          <div>
            <h1>Some members cannot be sent contracts</h1>
            <div className={styles.subtext}>
              All members must have name and email in order to
              send them contracts. Please select different members or add both
              names and emails. The first 20 invalid members are shown in the table.
            </div>
            <Table
              ref={null}
              data={tableData}
              columns={TABLE_COLUMNS}
              className={styles.membersTable}
              disabled={false}
              emptyMessage=""
              paddingBottom={0}
              rowDisplayName="Members"
              onRowClicked={onRowClicked}
              config={{
                allowSearch: false,
                configurableColumns: false,
                rowHeight: 58,
                pageSize: 20,
                striped: true,
                rowBorder: true,
                selectable: false,
              }}
            />
          </div>
        )
      }
    </div>
  );
});
