import * as React from 'react';

import {
  Button, Checkbox, Dropdown, Menu, Space,
} from '@revfluence/fresh';
import { AngleDownIcon } from '@revfluence/fresh-icons/solid/esm';

import { groupBy } from 'lodash';
import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import styles from './Filter.scss';

export interface FilterOption<T> {
  label: string;
  value: T;
}

export type FilterProps<T> = {
  defaultLabel?: string;
  allLabel?: string;
  multipleLabel?: string;
  options: FilterOption<T>[];
  selection?: FilterOption<T>[];
  onFilterChange?: (options: FilterOption<T>[]) => void;
  isCheckbox: boolean;
  refreshUi: boolean;
};

interface FilterOptions<T> {
  options: FilterOption<T>[];
  onChange: (option: FilterOption<T>, checked: boolean) => void;
  isCheckbox: boolean;
  selectedOptions?: FilterOption<T>[];
}

const { useState, useEffect } = React;
function FilterDropdown<T>({
  options, onChange, isCheckbox, selectedOptions,
}: FilterOptions<T>) {
  const selections = groupBy(selectedOptions, ({ value, label }) => `${label}_${value}`);
  return (
    <div onClick={(e) => isCheckbox && e.stopPropagation()}>
      <Menu className={styles.ProjectDropdown}>
        {options.map((option, index) => {
          const { label, value } = option;
          return (
            <Menu.Item key={index}>
              {isCheckbox ? (
                <Checkbox
                  className={styles.Checkbox}
                  checked={!!selections[`${label}_${value}`]}
                  onChange={(e) => {
                    onChange(option, e.target.checked);
                  }}
                >
                  {label}
                </Checkbox>
              ) : (
                <div
                  key={label}
                  onClick={() => {
                    onChange(option, true);
                  }}
                >
                  {label}
                </div>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
}

function Filter<T>({
  options,
  defaultLabel = 'All Data',
  allLabel = 'All Projects',
  multipleLabel = 'Multiple Projects',
  onFilterChange,
  isCheckbox,
  selection: selectionProp = [],
  refreshUi,
}: FilterProps<T>) {
  const [label, setLabel] = useState(defaultLabel);
  const [selection, setSelection] = useState<FilterOption<T>[]>(selectionProp);

  const onSelectionChange = (option: FilterOption<T>, checked: boolean) => {
    if (checked) {
      if (isCheckbox) {
        setSelection([...selection, option]);
      } else {
        setSelection([option]);
      }
    } else {
      setSelection(selection.filter((opt) => option.label !== opt.label));
    }
  };

  useEffect(() => {
    if (typeof onFilterChange === 'function') {
      onFilterChange(selection);
    }

    if (selection.length === 0) {
      return setLabel(defaultLabel);
    }

    if (selection.length === 1) {
      return setLabel(selection[0].label);
    }

    if (selection.length > 1) {
      return setLabel(multipleLabel);
    }
    if (selection.length === options.length) {
      return setLabel(allLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  return (
    <Dropdown
      overlay={(
        <FilterDropdown
          isCheckbox={isCheckbox}
          options={options}
          selectedOptions={selectionProp}
          onChange={onSelectionChange}
        />
      )}
      trigger={['click']}
    >
      {
        refreshUi ? (
          <ShadCnBtn
            size="sm"
            variant="outlineHeader"
            className="font-inter"
          >
            {label}
            <AngleDownIcon className="ml-2" />
          </ShadCnBtn>
        ) : (
          <Button>
            <Space>
              {label}
              <AngleDownIcon />
            </Space>
          </Button>
        )
      }
    </Dropdown>
  );
}

export default Filter;
