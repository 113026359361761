import { TActiveDates } from '../types';

export const getCurrentActiveDatesLabel = (
  activeDates: TActiveDates,
): string => {
  if (!activeDates.startDate && !activeDates.endDate) {
    return 'Multiple';
  }

  return [
    activeDates.startDate || 'Multiple',
    activeDates.endDate || 'Multiple',
  ].join(' - ');
};
