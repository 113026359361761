/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8pqos_157 {
  display: flex;
}

._justify-content-space-between_8pqos_161 {
  justify-content: space-between;
}

._tabular-nums_8pqos_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8pqos_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._paymentSuccessContainer_8pqos_178 ._main_8pqos_178 {
  display: flex !important;
  justify-content: space-between;
  gap: 3rem;
  margin-top: 2rem;
}
._paymentSuccessContainer_8pqos_178 ._main_8pqos_178 ._left_8pqos_184 {
  flex: 1;
}
._paymentSuccessContainer_8pqos_178 ._main_8pqos_178 ._right_8pqos_187 {
  flex: 1;
}