import * as React from 'react';
import {
  Card as FreshCard,
  Row,
  Col,
  Skeleton,
} from '@revfluence/fresh';

import styles from './WidgetTopPerformingContent.module.scss';

const LoadingLine = () => (
  <Skeleton
    paragraph={false}
    title
    loading
    active
  />
);

const LoadingBox = () => (
  <Skeleton
    className={styles.loadingImageBox}
    paragraph={false}
    style={{
      borderRadius: '6px',
    }}
    loading
    active
  />
);

const LoadingSubtitle = () => (
  <Row
    style={{
      marginBottom: '16px',
    }}
  >
    <Col xs={8} xl={4}>
      <LoadingLine />
    </Col>
  </Row>
);

const BlockLoadingLine = () => (
  <Row>
    <Col span={24}>
      <LoadingLine />
    </Col>
  </Row>
);

const TopCreatorsSkeleton = () => (
  <Col span={8}>
    <LoadingBox />
    <Row>
      <Col span={16}>
        <LoadingLine />
      </Col>
    </Row>
    <BlockLoadingLine />
  </Col>
);

const TopPostsSkeleton = () => (
  <Col span={8}>
    <LoadingBox />
    <Row>
      <Col
        span={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '-24px',
        }}
      >
        <Skeleton.Avatar
          size="large"
          shape="square"
          style={{
            border: '2px solid white',
          }}
          active
        />
      </Col>
      <Col span={12}>
        <LoadingLine />
      </Col>
    </Row>
    <BlockLoadingLine />
    <BlockLoadingLine />
  </Col>
);

const LoadingButton = () => (
  <Row
    justify="center"
    align="middle"
    className={styles.actionButton}
  >
    <Col span={12}>
      <Skeleton.Button
        size="large"
        active
        block
      />
    </Col>
  </Row>
);

export const WidgetTopPerformingContentLoading: React.FC = React.memo(() => (
  <FreshCard>
    <Row>
      <Col xs={12} xl={6}>
        <LoadingLine />
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} xl={12}>
        <FreshCard
          className={styles.TopPerformingWidgetCard}
          bordered={false}
          bodyStyle={{
            paddingLeft: '0',
            paddingRight: '0',
          }}
        >
          <LoadingSubtitle />
          <Row gutter={16}>
            <TopPostsSkeleton />
            <TopPostsSkeleton />
            <TopPostsSkeleton />
          </Row>
          <LoadingButton />
        </FreshCard>
      </Col>

      <Col xs={24} xl={12}>
        <FreshCard
          className={styles.TopPerformingWidgetCard}
          bordered={false}
          bodyStyle={{
            paddingLeft: '0',
            paddingRight: '0',
          }}
        >
          <LoadingSubtitle />
          <Row gutter={16}>
            <TopCreatorsSkeleton />
            <TopCreatorsSkeleton />
            <TopCreatorsSkeleton />
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                visibility: 'hidden',
              }}
            >
              <LoadingLine />
            </Col>
          </Row>
          <LoadingButton />
        </FreshCard>
      </Col>
    </Row>
  </FreshCard>
));

WidgetTopPerformingContentLoading.displayName = 'WidgetTopPerformingContentLoading';
