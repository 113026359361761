/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_139id_157 {
  display: flex;
}

._justify-content-space-between_139id_161 {
  justify-content: space-between;
}

._tabular-nums_139id_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_139id_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FolderMenu_139id_178 ._header_139id_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._FolderMenu_139id_178 {
  margin-bottom: -0.125rem;
  user-select: none;
}
._FolderMenu_139id_178 ._header_139id_178 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.625rem 0.9375rem;
  cursor: pointer;
}
._FolderMenu_139id_178 ._title_139id_200 {
  flex: 1;
}
._FolderMenu_139id_178 ._arrow_139id_203 {
  margin-right: 0.625rem;
  color: #8f8d91;
  transform: rotateZ(-90deg);
}
._FolderMenu_139id_178 ._content_139id_208 {
  display: none;
}
._FolderMenu_139id_178._expanded_139id_211 ._arrow_139id_203 {
  transform: rotateZ(0);
}
._FolderMenu_139id_178._expanded_139id_211 ._content_139id_208 {
  display: block;
}