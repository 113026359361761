import * as React from 'react';
import { ColumnsType, Table, Typography } from '@revfluence/fresh';

import { RemoveButton } from '@frontend/app/components/InstagramMeta/MetaItems/';
import { renderBusinessTag } from './RenderTag';

import styles from './Tables.scss';

import { CellStatus } from './utils/composeCells';
import { AllowlistingTableZeroState } from './AllowlistingTableZeroState';

const { Title } = Typography;

export type BusinessFeatures = [
  { feature: 'Granting allowlisting permissions from creators' },
];

export interface IBusiness {
  id: string;
  name: string;
  networkId: string;
  cell: CellStatus,
  features: BusinessFeatures;
}

interface IAllowlistingTableProps {
  AddBusinessButton: React.ReactNode;
  data: IBusiness[];
  removeBusiness: (id: string) => void;
}

export const AllowlistingTable = (props: IAllowlistingTableProps) => {
  const {
    AddBusinessButton,
    data,
    removeBusiness,
  } = props;

  const isEmpty = data.length === 0;

  const columns: ColumnsType<IBusiness> = [
    {
      title: 'Business',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'ID',
      dataIndex: 'networkId',
      key: 'networkId',
      width: '20%',
    },
    {
      title: 'Allowlisting',
      dataIndex: 'insights',
      key: 'insights',
      width: '60%',
      render: (_, record) => renderBusinessTag(record),
    },
    {
      title: '',
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <RemoveButton removeAccount={() => removeBusiness(record.id)} />
      ),
    },
  ];

  return (
    <>
      <Title level={5} className={styles.TableTitle}>
        Connected Business
      </Title>
      {isEmpty ? (
        <>
          <AllowlistingTableZeroState />
          {AddBusinessButton}
        </>
      ) : (
        <>
          <Table
            className={styles.InstagramTable}
            rowClassName={() => styles.RowTable}
            columns={columns}
            dataSource={data}
            pagination={false}
            footer={() => (
              <div className={styles.InstagramTableFooter}>
                {AddBusinessButton}
              </div>
            )}
          />
        </>
      )}
    </>
  );
};
