import gql from 'graphql-tag';

import { MEMBER_FIELD_SCHEMA_FRAGMENT } from '@frontend/app/queries/fragments';

export const MEMBER_FIELD_SCHEMAS_BY_IDS_QUERY = gql`
  query MemberFieldSchemasByIdsQuery($schemaIds: [Int!]!) {
    schemas: memberFieldSchemas(schemaIds: $schemaIds) {
      ...MemberFieldSchemaFragment
    }
  }
  ${MEMBER_FIELD_SCHEMA_FRAGMENT}
`;
