import {
  useMemo,
  useReducer,
} from 'react';
import { useClientFeatureEnabled } from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import {
  IState,
  TDispatch,
} from '../../types/state';
import { getInitialState } from './getInitialState';
import { reducer } from './reducer';

interface IUseState {
  dispatch: TDispatch;
  state: IState;
}

export const useState = (): IUseState => {
  const InstagramPartnershipAds = useClientFeatureEnabled(ClientFeature.INSTAGRAM_PARTNERSHIP_ADS, {
    nextFetchPolicy: 'cache-first',
  });
  const MetaBrandedContent = useClientFeatureEnabled(ClientFeature.META_BRANDED_CONTENT, {
    nextFetchPolicy: 'cache-first',
  });
  const initialState = useMemo(() => getInitialState({
    InstagramPartnershipAds,
    MetaBrandedContent,
  }), [
    InstagramPartnershipAds,
    MetaBrandedContent,
  ]);
  const [state, dispatch] = useReducer(reducer, initialState);
  return {
    dispatch,
    state,
  };
};
