import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const UploadCloudIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M18.62,5.83a7.41,7.41,0,0,0-14,2.56A4.12,4.12,0,0,0,0,12.48c0,2.72,1.75,3.91,4,4.12a.34.34,0,0,0,.36-.3.33.33,0,0,0-.3-.35C2.1,15.76.65,14.78.65,12.48a3.48,3.48,0,0,1,4.2-3.4.32.32,0,0,0,.39-.3A6.77,6.77,0,0,1,18.12,6.29a.34.34,0,0,0,.31.19,4.74,4.74,0,0,1,4.92,4.74,4.35,4.35,0,0,1-2.13,3.94,5.92,5.92,0,0,1-1.49.65,5.76,5.76,0,0,1-.67.16.32.32,0,0,0-.28.36.34.34,0,0,0,.37.29,7.38,7.38,0,0,0,.76-.18,6.5,6.5,0,0,0,1.65-.73A5,5,0,0,0,24,11.22,5.38,5.38,0,0,0,18.62,5.83Z"
      transform="translate(0 -1.75)"
    />
    <path
      d="M11.47,11a5.64,5.64,0,1,0,5.63,5.63A5.63,5.63,0,0,0,11.47,11Zm0,10.61a5,5,0,1,1,5-5A5,5,0,0,1,11.47,21.59Z"
      transform="translate(0 -1.75)"
    />
    <path
      d="M11.7,13.52a.41.41,0,0,0-.11-.07l-.12,0h0l-.13,0-.1.07L9.12,15.64a.33.33,0,0,0,0,.47.34.34,0,0,0,.46,0l1.56-1.57v4.52a.33.33,0,0,0,.66,0V14.54l1.56,1.57a.34.34,0,0,0,.46,0,.33.33,0,0,0,0-.47Z"
      transform="translate(0 -1.75)"
    />
  </SvgIcon>
));

UploadCloudIcon.displayName = 'UploadCloudIcon';

export { UploadCloudIcon };
