/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1iosq_157 {
  display: flex;
}

._justify-content-space-between_1iosq_161 {
  justify-content: space-between;
}

._tabular-nums_1iosq_165, ._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._Card_1iosq_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_1iosq_170 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_1iosq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Card_1iosq_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Card_1iosq_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Card_1iosq_170 ::-webkit-scrollbar-track, ._Card_1iosq_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Card_1iosq_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_1iosq_165,
._audienceAgeBarChart_1iosq_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_1iosq_165 > [class*=list],
._audienceAgeBarChart_1iosq_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_1iosq_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_1iosq_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_1iosq_306 {
  cursor: help;
}

._Card_1iosq_170 {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  break-inside: avoid-column;
  box-sizing: border-box;
}
._Card_1iosq_170 ._cardHeader_1iosq_320 {
  margin-top: -0.5rem;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._Card_1iosq_170 ._cardHeader_1iosq_320:empty {
  display: none;
  margin: unset;
}
._Card_1iosq_170 ._tabs_1iosq_331 {
  margin-right: -0.5rem;
  align-self: flex-end;
}
._Card_1iosq_170 ._tabs_1iosq_331 ._tabButton_1iosq_335 {
  min-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  letter-spacing: 0.0625rem;
}
._Card_1iosq_170 ._tabs_1iosq_331 ._tabButton_1iosq_335, ._Card_1iosq_170 ._tabs_1iosq_331 ._tabButton_1iosq_335:hover {
  background-color: transparent;
}
._Card_1iosq_170 ._tabs_1iosq_331 ._tabButton_1iosq_335:hover > div {
  color: #3996e0;
}
._Card_1iosq_170 ._tabs_1iosq_331 ._tabButton_1iosq_335 > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aeaeae;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Card_1iosq_170 ._tabs_1iosq_331 ._tabButton_1iosq_335._activeTab_1iosq_354 > div {
  color: #3996e0;
}

._separator_1iosq_358 {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  height: 0.0625rem;
  border-bottom: 0.0625rem solid #eff5f9;
}

._cardTab_1iosq_365 {
  display: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._cardTitle_1iosq_373 {
  margin: 0 auto 0 0;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

._visible_1iosq_381 {
  display: flex !important;
  animation: _show_1iosq_1 0.4s;
}

._noData_1iosq_386::after {
  position: absolute;
  top: calc(50% + 0.6666666667rem);
  left: 50%;
  display: block;
  color: #8f8d91;
  content: "No data yet";
  transform: translate(-50%, -50%);
}

._ProfileCard_1iosq_396 ._hashtags_1iosq_396 {
  margin: auto 0;
  padding-top: 0.25rem;
}

._AccountInfo_1iosq_401 {
  min-height: 4rem;
  display: flex;
  flex-direction: row;
}
._AccountInfo_1iosq_401 ._image_1iosq_406 {
  margin-right: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 0 0.25rem 0.75rem rgba(46, 66, 109, 0.2);
  transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._AccountInfo_1iosq_401 ._image_1iosq_406:hover {
  transform: scale(1.05);
}
._AccountInfo_1iosq_401 ._info_1iosq_417 {
  width: calc(100% - 5rem);
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.3333333333rem;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._username_1iosq_427,
._AccountInfo_1iosq_401 ._info_1iosq_417 ._location_1iosq_428,
._AccountInfo_1iosq_401 ._info_1iosq_417 ._stats_1iosq_429 {
  margin: 0 auto 0 0;
  display: inline-flex;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._username_1iosq_427 {
  max-width: 100%;
  color: #1a1818;
  text-decoration: none;
  transition: color 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._username_1iosq_427:hover {
  color: #3996e0;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._username_1iosq_427 h4 {
  margin: 0;
  display: inline;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._username_1iosq_427 h4 ._ambIcon_1iosq_450 {
  margin-left: 0.5rem;
  display: inline-flex;
  align-self: center;
  vertical-align: middle;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._icon_1iosq_456 {
  width: 1.125rem;
  margin-right: 0.375rem;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._stats_1iosq_429 ._verticalSeparator_1iosq_460 {
  margin: 0 0.5rem;
  color: #dadcde;
  font-weight: bold;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._stats_1iosq_429 ._checkCircleIcon_1iosq_465 {
  transform: translateY(0.125rem);
  fill: #3996e0;
}
._AccountInfo_1iosq_401 ._info_1iosq_417 ._stats_1iosq_429 ._starIcon_1iosq_469 {
  transform: translateY(0.125rem);
  fill: #EACD60;
}