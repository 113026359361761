import * as React from 'react';
import { capitalize } from 'lodash';
import { Button } from '@revfluence/fresh';
import { EnvelopeIcon } from '@revfluence/fresh-icons/solid/esm';
import { ResourceType } from '@frontend/app/types/globalTypes';
import styles from './AccountsPageEmpty.scss';
import { ConnectModal } from './ConnectModal';

const { useState } = React;

interface IProps {
  type: ResourceType;
}

const AccountsPageEmpty: React.FC<IProps> = React.memo((props) => {
  const { type } = props;
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <div className={styles.emptyContent}>
        <EnvelopeIcon
          style={{ fontSize: '80px', marginBottom: '36px', color: '#f0f0f0' }}
        />
        <div className={styles.title}>
          Connect a
          {' '}
          {capitalize(type)}
          {' '}
          account
        </div>
        <div className={styles.text}>
          Connect an email account to start collaborating with members.
        </div>
        <Button
          type="primary"
          onClick={() => {
          setModalOpen(true);
        }}
        >
          Connect
        </Button>
      </div>
      <ConnectModal
        type={type}
        isOpen={modalOpen}
        onClose={() => { setModalOpen(false); }}
      />
    </>
  );
});

AccountsPageEmpty.displayName = 'AccountsPageEmpty';

export default AccountsPageEmpty;
