import { useQuery, QueryHookOptions } from '@apollo/client';

import { REQUIREMENTS_FOR_MEMBER } from '@frontend/app/queries';
import {
  RequirementsForMemberQuery,
  RequirementsForMemberQueryVariables,
} from '@frontend/app/queries/types/RequirementsForMemberQuery';

type IOptions = QueryHookOptions<RequirementsForMemberQuery, RequirementsForMemberQueryVariables>;

export const useRequirementsForMemberQuery = (memberId: number, options: IOptions = {}) => useQuery<RequirementsForMemberQuery, RequirementsForMemberQueryVariables>(REQUIREMENTS_FOR_MEMBER, {
    ...options,
    variables: {
      memberId,
    },
    skip: !memberId || options.skip,
  });
