import * as React from 'react';
import { isEmpty } from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { IDeactivateTrackingLinksMembers } from '../../types';
import { TActions, TState } from './state';
import { getSteps } from './getSteps';

import styles from '../../components/MembersWizard.scss';

export const getTrackingLinksSteps = (props: IDeactivateTrackingLinksMembers, state: TState, actions: TActions) => {
  const {
    onClose,
  } = props;

  const {
    selectMembers,
    reviewMembers,
    completed,
    selectedMembers,
  } = getSteps(props, state, actions);
  const nextButtonDisabled = isEmpty(selectedMembers);

  let steps = [
    selectMembers({
      instructions: {
        description: 'Confirm these are the tracking links you want to deactivate.',
        title: 'Deactivate these tracking links.',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Review Links',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        action: onClose,
      },
      stepNum: 1,
    }),
    reviewMembers({
      instructions: {
        description: 'Take one last look at the tracking links you will deactivate to confirm everything looks correct.',
        title: 'Review the tracking links to deactivate.',
      },
      nextButtonConfig: {
        action: () => actions.save(3),
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Deactivate Links',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Select Members',
      },
      stepNum: 2,
    }, { disableNextStep: true }),
    completed({
      instructions: {
        description: `
        You can email these members if you choose to from the “Email Members”
        button above the table for this offer.
       `.trim(),
        title: `
        Congrats! You are deactivating ${`${selectedMembers.length.toLocaleString()} `}
        member link${selectedMembers.length !== 1 ? 's' : ''}.
      `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Links',
      },
      stepNum: 3,
    }),
  ];
  if (state.error) {
    steps = steps.map((step) => ({
      ...step,
      actionComponents: (
        <Notice type="error" message={state.error.message} />
      ),
    }));
  }

  return steps;
};
