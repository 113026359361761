import * as React from 'react';

import { IRowData } from './tableContext';

interface ITableRowContext {
  rowIndex: number;
  rowData?: IRowData;
  rowId: string;
  isSelected: boolean;
  onToggleRowSelected();
  onToggleShift(
    isPressed: boolean,
    isRowselected: boolean,
  ): void;
}

export interface ITableCellRenderContext {
  isEditing: boolean;
  isRowSelected: boolean;
  isCellSelected: boolean;
  onToggleEditing();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeCellValue(value: any);
  onToggleRowSelected();
  onToggleShift(
    isPressed: boolean,
    isRowSelected: boolean,
  ): void;
}

export const TableRowContext = React.createContext<ITableRowContext>(null);
