import { MutationHookOptions, useMutation } from '@apollo/client';
import { SYNC_PRODUCT_INVENTORY_BY_PRODUCT_ID } from '@frontend/applications/ProductFulfillmentApp/queries/productDetails';
import { SyncProductInventoryByProductId, SyncProductInventoryByProductIdVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/SyncProductInventoryByProductId';

type IOptions = MutationHookOptions<SyncProductInventoryByProductId, SyncProductInventoryByProductIdVariables>;

export const useSyncProductInventoryByProductId = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<SyncProductInventoryByProductId, SyncProductInventoryByProductIdVariables>(
    SYNC_PRODUCT_INVENTORY_BY_PRODUCT_ID,
    options,
  );

  return {
    mutate,
    loading,
  };
};
