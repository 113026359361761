import * as React from 'react';
import cx from 'classnames';
import {
  Switch,
  ColorPicker,
} from '@frontend/app/components';

import styles from './TransparentColorPicker.scss';

interface IProps {
  hasTransparent?: boolean;
  transparentLabel?: string;
  onChange?(color: string, transparent?: boolean);
  className?: string;
  value?: { color: string, transparent?: boolean };
  defaultColor?: string;
}

export const TransparentColorPicker: React.FC<IProps> = (props) => {
  const handleChangeColor = (color: string) => {
    props.onChange?.(color, props.value?.transparent);
  };

  const handleChangeTransparent = (transparent: boolean) => {
    props.onChange?.(props.value?.color, transparent);
  };

  return (
    <div className={cx(styles.TransparentColorPicker, props.className)}>
      <ColorPicker
        color={props.value?.color}
        defaultColor={props.defaultColor}
        onChange={handleChangeColor}
        disabled={props.value?.transparent}
      />

      {props.hasTransparent && (
        <div className={styles.transparent}>
          <div>{props.transparentLabel}</div>
          <Switch
            checked={props.value?.transparent}
            onChange={handleChangeTransparent}
          />
        </div>
      )}
    </div>
  );
};

TransparentColorPicker.defaultProps = {
  hasTransparent: false,
  transparentLabel: 'Transparent',
};
