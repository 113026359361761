import * as React from 'react';
import cx from 'classnames';
import {
 map, filter, isEmpty,
} from 'lodash';

import {
  Switch,
 useRouteMatch,
 useHistory,
  Route,
} from 'react-router-dom';

import { Menu } from '@revfluence/fresh';

import {
  GetApplicationsByIds_applications as Application,
} from '@frontend/app/queries/types/GetApplicationsByIds';

import { AppIcon } from '@frontend/applications/Shared/components/AppIcon';

import {
  ApplicationDrawer,
} from '@frontend/app/containers/Application/ApplicationDrawer';

import { IMember } from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';

import styles from './BulkAppAction.scss';

const { useState, useMemo } = React;

interface IProps {
  // From members list page
  members?: IMember[];
  memberQuery?: IMemberSearchQuery;
  memberCount?: number;

  className?: string;
  installedApplications?: Application[];
  isWorkflowEnabled?: boolean;
}

const AppNameMapping = {
  'Info Update': 'Request Info Update',
  'Contracts': 'Send Contract',
};

/**
 * @type {React.FunctionComponent}
 */
export const BulkAppActionMenuItem: React.FunctionComponent<IProps> = React.memo((props) => {
  const { installedApplications } = props;
  const [selectedApplication, setSelectedApplication] = useState<Application>(null);
  const match = useRouteMatch();
  const history = useHistory();

  const onClickedApplication = (application) => {
    setSelectedApplication(application);
    history.push({ ...location, pathname: `${match.url}/app/${application.id}` });
  };

  const visibleApps = useMemo(() => {
    if (!installedApplications) {
      return [];
    }
    return filter(installedApplications, 'visibility.showOnMemberList');
  }, [installedApplications]);

  const hasMembers = useMemo(() => !isEmpty(props.members), [props.members]);

  return (
    <>
      {map(visibleApps, (application) => (
        <Menu.Item
          disabled={!hasMembers}
          onClick={() => onClickedApplication(application)}
          className={cx(
            styles.menuItem,
            {
              [styles.menuItemWorkflowEnabled]: props.isWorkflowEnabled,
            },
          )}
        >
          {
            !props.isWorkflowEnabled && (
              <AppIcon iconUrl={application.icon} />
            )
          }
          <span className={styles.menuText}>
            {AppNameMapping[application.name] || application.name}
          </span>
        </Menu.Item>
      ))}
      <section>
        <Switch>
          <Route path={`${match.path}/app/:appId`}>
            <ApplicationDrawer
              application={selectedApplication}
              bulkActionParameters={
                {
                  memberCount: props.memberCount,
                  memberQueryJson: JSON.stringify(props.memberQuery),
                  memberIds: map(props.members, 'id'),
                  members: props.members,
                }
              }
              memberId={null}
              deepLinkParameters={null}
            />
          </Route>
        </Switch>
      </section>
    </>
  );
});

BulkAppActionMenuItem.displayName = 'BulkAppActionMenuItem';
