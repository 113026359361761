import * as React from 'react';
import { findIndex } from 'lodash';

import { Select, LinkButton, Input } from '@components';
import { IContractSigner } from '../useFetchContracts';

import styles from './SignerCell.scss';

const { useState, useRef } = React;

interface ISignerError {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  required: Set<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invalid: Set<any>;
}

interface IContractSignerCell extends IContractSigner {
  firstName?: string;
  lastName?: string;
}

interface IProps {
  signer: IContractSignerCell;
  signerId: number;
  error?: ISignerError;
  reducerDispatch(action): void;
}

const selectRoleOptions = [
  {
    value: 'Creator',
    label: 'Member',
  },
  {
    value: 'Brand',
    label: 'Brand',
  },
];

const FieldNames = {
  firstName: 'first_name',
  lastName: 'last_name',
  email: 'email',
};

const SignerCell: React.FunctionComponent<IProps> = (props) => {
  const {
 reducerDispatch, signer, signerId, hideRoleSelect,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} = props as any;
  const { required, invalid } = props.error;
  const [initialFirstName, ...initialLastNames] = signer.name.split(' ');
  const [firstName, setFirstName] = useState(signer.firstName || initialFirstName || '');
  const [lastName, setLastName] = useState(signer.lastName || initialLastNames.join(' '));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const firstNameRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lastNameRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailAddressRef = useRef<any>();

  const handleFirstNameChange = (value) => {
    setFirstName(value);
    reducerDispatch({
      type: 'updateSigner',
      value: {
        ...signer,
        firstName: value,
        name: `${value} ${lastName}`,
      },
      signerId,
    });
  };

  const handleLastNameChange = (value) => {
    setLastName(value);
    reducerDispatch({
      type: 'updateSigner',
      value: {
        ...signer,
        lastName: value,
        name: `${firstName} ${value}`,
      },
      signerId,
    });
  };

  const handleEmailChange = (value) => {
    reducerDispatch({
      type: 'updateSigner',
      value: {
        ...signer, email: value,
      },
      signerId,
    });
  };

  const setRole = (role) => {
    reducerDispatch({
      type: 'updateSigner',
      value: { ...signer, role },
      signerId,
    });
  };

  const removeSigner = () => {
    reducerDispatch({
      type: 'removeSigner',
      signerId,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const focus = (field: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (field as any).current.focus();
  };

  const selectedRoleIndex = findIndex(selectRoleOptions, { value: signer.role });

  return (
    <div className={styles.SignerCell}>
      <fieldset>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <label onClick={() => focus(firstNameRef as any)}>
          Legal First Name:
        </label>
        <Input
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={firstNameRef as any}
          value={firstName}
          onChange={handleFirstNameChange}
          hasError={
            required.has(FieldNames.firstName)
            || invalid.has(FieldNames.firstName)
          }
        />
        {required.has(FieldNames.firstName) && (
          <div className={styles.error}>First Name is required</div>
        )}
        {invalid.has(FieldNames.firstName) && (
          <div className={styles.error}>First Name is invalid</div>
        )}
      </fieldset>
      <fieldset>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <label onClick={() => focus(lastNameRef as any)}>
          Legal Last Name:
        </label>
        <Input
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={lastNameRef as any}
          value={lastName}
          onChange={handleLastNameChange}
          hasError={
            required.has(FieldNames.lastName)
            || invalid.has(FieldNames.lastName)
          }
        />
        {required.has(FieldNames.lastName) && (
          <div className={styles.error}>Last Name is required</div>
        )}
        {invalid.has(FieldNames.lastName) && (
          <div className={styles.error}>Last Name is invalid</div>
        )}
      </fieldset>
      <fieldset>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <label onClick={() => focus(emailAddressRef as any)}>
          Email:
        </label>
        <Input
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={emailAddressRef as any}
          value={signer.email}
          onChange={handleEmailChange}
          hasError={
            required.has(FieldNames.email)
            || invalid.has(FieldNames.email)
          }
        />
        {required.has(FieldNames.email) && (
          <div className={styles.error}>Email Address is required</div>
        )}
        {invalid.has(FieldNames.email) && (
          <div className={styles.error}>Email Address is invalid</div>
        )}
      </fieldset>
      {!hideRoleSelect && (
        <Select
          options={selectRoleOptions}
          selectedIndex={selectedRoleIndex}
          onChange={setRole}
        />
      )}
      <LinkButton
        onClick={removeSigner}
        className={styles.removeSigner_button}
      >
        Remove Signer
      </LinkButton>
    </div>
  );
};

SignerCell.defaultProps = {
  error: {
    required: new Set(),
    invalid: new Set(),
  },
};

export { SignerCell, FieldNames };
