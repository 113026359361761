import * as React from 'react';
import { useState } from 'react';

import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { ArtifactAssignmentForm, IAssignmentAPIPayload } from '@frontend/applications/Shared/components/ArtifactAssignmentForm';

import { NewContractFileSelector } from './NewContractFileSelector';
import { ContractSetup } from './ContractSetup';
import { IContract } from '../useFetchContracts';
import { saveContractData } from '../useSendContract';

interface IProps {
  onCreateContractCompleted(): void;
}

const NewContract: React.FunctionComponent<IProps> = (props) => {
  /* Overall flow is:
    1. Upload file
    2. Set up signers and other fields and send the contract through HelloSign
    3. Assign CAP to contract artifact
  */

  const { onCreateContractCompleted } = props;

  const { backendServerApiEndpoint, memberId, clientId } = useApplication();
  const [contract, setContract] = useState<IContract>();
  const [loadingArtifactAssignment, setLoadingArtifactAssignment] = useState<boolean>(false);

  const [fileUrl, setFileUrl] = useState<string>(null);
  const [templateId, setTemplateId] = useState<string>();

  const {
    showMessage,
  } = useMessagingContext();

  const onContractSelected = (templateId?: string, fileUrl?: string) => {
    setFileUrl(fileUrl);
    setTemplateId(templateId);
  };

  const onArtifactAssignmentSkip = () => {
    onCreateContractCompleted();
  };

  const onArtifactAssignmentSave = (assignment: IAssignmentAPIPayload) => {
    setLoadingArtifactAssignment(true);
    const contractUrl = `${backendServerApiEndpoint}/contract/${contract.id}`;
    const params = { ...assignment, client_id: clientId };
    saveContractData(contractUrl, params).then(() => {
      setLoadingArtifactAssignment(false);
      onCreateContractCompleted();
    }).catch(logger.error);
  };

  const onSendContractSuccess = (contract: IContract) => {
    setContract(contract);
    showMessage({
      type: 'success',
      content: 'Your contract has been sent!',
    });
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const formParams: any = {};
  return (
    <div>
      {(templateId || fileUrl)
        ? contract
          ? (
            <ArtifactAssignmentForm
              {...formParams}
              artifact={contract}
              artifactName="contract"
              onSave={onArtifactAssignmentSave}
              onSkip={onArtifactAssignmentSkip}
              loading={loadingArtifactAssignment}
            />
) : (
  <ContractSetup
    fileUrl={fileUrl}
    onSendSuccess={onSendContractSuccess}
    templateId={templateId}
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    memberId={memberId as any}
    memberCount={1}
  />
        )
          : <NewContractFileSelector onSelect={onContractSelected} />}
    </div>
  );
};

export default NewContract;
