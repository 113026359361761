import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import {
  GET_SPECIFICATION_TEMPLATE_BY_NAME_QUERY,
} from '../queries/GetSpecificationTemplateByNameQuery';
import {
  GetSpecificationTemplateByNameQuery,
  GetSpecificationTemplateByNameQueryVariables,
} from '../queries/types/GetSpecificationTemplateByNameQuery';

type IOptions = QueryHookOptions<
  GetSpecificationTemplateByNameQuery,
  GetSpecificationTemplateByNameQueryVariables
>;

export const useGetSpecificationTemplateByNameQuery = (options: IOptions = {}) => (
  useQuery<GetSpecificationTemplateByNameQuery, GetSpecificationTemplateByNameQueryVariables>(
    GET_SPECIFICATION_TEMPLATE_BY_NAME_QUERY,
    options,
  )
);
