import { first } from 'lodash';
import { logger } from '../../../../../../common/Logger';
import { GetOffersBySearchQuery_offers } from '../../queries/types/GetOffersBySearchQuery';
import { OFFER_STATUS, OFFER_TYPE } from '../../types/globalTypes';

export const isValidOffer = (offerSource: OFFER_TYPE, offer: GetOffersBySearchQuery_offers) => {
  switch (offerSource) {
    case OFFER_TYPE.LINK: {
      const link = first(offer.links);
      if (offer.expired) {
        return false;
      }
      if (!link || link.status === OFFER_STATUS.DELETED || link.status === OFFER_STATUS.PAUSED) {
        return false;
      }
      return true;
    }
    case OFFER_TYPE.PROMO_CODE: {
      const promo = first(offer.promos);
      if (!promo || promo.status === OFFER_STATUS.DELETED || promo.status === OFFER_STATUS.PAUSED) {
        return false;
      }
      return true;
    }
    default:
      logger.warning('Invalid OFFER_TYPE on isValidOffer function');
      return false;
  }
};
