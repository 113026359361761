import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { CREATE_MESSAGE_TEMPLATE_MUTATION, MESSAGE_TEMPLATES_QUERY } from '@frontend/app/queries';
import {
  CreateMessageTemplateMutation,
  CreateMessageTemplateMutationVariables,
} from '@frontend/app/queries/types/CreateMessageTemplateMutation';
import { MessageTemplatesQuery } from '../queries/types/MessageTemplatesQuery';

type IOptions = MutationHookOptions<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>;

export const useCreateMessageTemplateMutation = (options: IOptions = {}) => (
  useMutation<CreateMessageTemplateMutation, CreateMessageTemplateMutationVariables>(CREATE_MESSAGE_TEMPLATE_MUTATION, {
    update(cache, { data: { template } }) {
      const data = cache.readQuery<MessageTemplatesQuery>({ query: MESSAGE_TEMPLATES_QUERY });

      // Do nothing. Cache doesn't exist.
      if (!data) {
        return;
      }

      const existingTemplates: { templates } = data;

      cache.writeQuery({
        query: MESSAGE_TEMPLATES_QUERY,
        data: { templates: [template, ...existingTemplates.templates] },
      });
    },
    ...options,
  })
);
