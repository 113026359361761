import * as React from 'react';

import {
  Modal, Button,
} from '@components';

import styles from './ConfirmationModal.scss';

interface IProps {
  open: boolean;
  onCancel(): void;
  onConfirm(): void;
}

/**
 * @type {React.FC}
 */
export const ConfirmationModal: React.FC<IProps> = React.memo((props) => (
  <Modal
    show={props.open}
    onRequestClose={props.onCancel}
    width={650}
    contentClassName={styles.ConfirmationModal}
  >
    <div className={styles.content}>
      <div className={styles.title}>
        Are you sure you want to close this request?
      </div>
      <div className={styles.text}>
        If you close this modal, you will lose your progress. Do you want to continue?
      </div>
      <div className={styles.actions}>
        <Button
          className={styles.button}
          theme="primary"
          label="Yes"
          onClick={props.onConfirm}
        />
        <Button
          label="Cancel"
          onClick={props.onCancel}
          theme="info"
        />
      </div>
    </div>
  </Modal>
));

ConfirmationModal.displayName = 'ConfirmationModal';
