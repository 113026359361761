import gql from 'graphql-tag';

import { MEMBER_FRAGMENT, MEMBER_RESTRICTION_FRAGMENT } from './fragments';

export const GET_MEMBER_RESTRICTIONS_QUERY = gql`
  query GetMemberRestrictionsQuery {
    restrictions: getMemberRestrictions {
      ...MemberRestrictionFragment
    }
  }
  ${MEMBER_RESTRICTION_FRAGMENT}
`;

export const CREATE_MEMBER_RESTRICTION_MUTATION = gql`
  mutation CreateMemberRestrictionMutation($restrictions: [MemberRestrictionInput!]!, $saveRestriction: Boolean) {
    restrictions: createMemberRestrictions(restrictions: $restrictions, saveRestriction: $saveRestriction) {
      ...MemberRestrictionFragment
    }
  }
  ${MEMBER_RESTRICTION_FRAGMENT}
`;

export const DELETE_MEMBER_RESTRICTION_MUTATION = gql`
  mutation DeleteMemberRestrictionMutation($id: String!) {
    deleted: deleteMemberRestriction(id: $id)
  }
`;

export const GET_MEMBERS_FOR_RESTRICTION_QUERY = gql`
  query GetMembersForRestrictionQuery($restrictions: [MemberRestrictionInput!]!) {
    members: getMembersForRestrictions(restrictions: $restrictions) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_MEMBERS_COUNT_FOR_RESTRICTION_QUERY = gql`
  query GetMembersCountForRestrictionQuery($restrictions: [MemberRestrictionInput!]!) {
    count: getMembersCountForRestrictions(restrictions: $restrictions)
  }
`;
