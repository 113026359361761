import * as React from 'react';
import { message } from '@revfluence/fresh';

import {
  TUseDeselectBusinessMutation,
  TUseGetBusinessSettingsQuery,
  TUseUpdateBusinessesMutation,
  TUseGetAvailableBusinessesQuery,
} from '@frontend/app/hooks';
import { IBusiness, AllowlistingTable as InstagramBusinessTable } from '../AllowlistingTable';
import { BusinessButton } from '../BusinessButton';
import { composeBusinessFeatures } from '../utils/composeFeatures';
import { CellStatus } from '../utils/composeCells';

export interface IAllowlistingTableProps {
  hidden: boolean;
  availableBusinessesQuery: TUseGetAvailableBusinessesQuery;
  deselectMutation: TUseDeselectBusinessMutation,
  updateBusinessesMutation: TUseUpdateBusinessesMutation;
  query: TUseGetBusinessSettingsQuery;
}

interface IBusinessBusinessInput {
  business_network_id: string;
}

export const AllowlistingTable: React.FC<IAllowlistingTableProps> = ({
  hidden,
  availableBusinessesQuery,
  deselectMutation,
  updateBusinessesMutation,
  query,
}) => {
  if (hidden) {
    return null;
  }
  const [deselectBusiness] = deselectMutation;
  const [updateBusiness] = updateBusinessesMutation;
  const data = query?.data?.getSocialAccountClientSettings?.metaBusinessData?.businesses || [];
  const availableBusinesses = availableBusinessesQuery?.data?.getAvailableBusinesses || [];

  const handleSelectBusiness = (input: IBusinessBusinessInput[]) => {
    const businessNetworkIds = input.map((business) => business.business_network_id);
    updateBusiness({
      variables: {
        businessNetworkIds,
      },
    });
  };

  const modalData = availableBusinesses.map((business) => ({
    networkId: business.network_id,
    name: business.name,
    selected: false,
  }));

  const tableData: IBusiness[] = data.map((business) => ({
    id: business.id,
    name: business.name,
    networkId: business.network_id,
    features: composeBusinessFeatures(),
    cell: CellStatus.ACTIVE,
  }));

  return (
    <InstagramBusinessTable
      data={tableData}
      removeBusiness={(id) => deselectBusiness({
        variables: {
          businessId: id,
        },
        update() {
          message.success({
            content: 'Successfully removed Business',
          });
        },
      })}
      AddBusinessButton={(
        <BusinessButton
          isButtonDisabled={availableBusinesses.length < 1}
          installedBusiness={data.length > 0}
          businesses={modalData}
          selectBusiness={handleSelectBusiness}
        />
      )}
    />
  );
};
