import gql from 'graphql-tag';

import { COMMUNITY_FRAGMENT } from './fragments/CommunityFragment';

export const GET_COMMUNITY_BY_ID = gql`
  query CommunityByIdQuery($id: Int!) {
    community: communityById(id: $id) {
      ...CommunityPageCommunity
    }
  }
  ${COMMUNITY_FRAGMENT}
`;
