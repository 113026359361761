/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SelectListPopover_rjj31_32 ._header_rjj31_32 {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border-bottom: solid 1px #e9e9e9;
  font-size: 15px;
  font-weight: 600;
}
._SelectListPopover_rjj31_32 ._header_rjj31_32 ._spinner_rjj31_42 {
  margin-left: 10px;
  color: #3996e0;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46 {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 37px;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46:hover {
  background-color: #f0f4f8;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46._disabled_rjj31_60 {
  pointer-events: none;
  color: gray;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46._active_rjj31_64 ._label_rjj31_64 {
  color: #3996e0;
  text-shadow: 0.5px 0 0 #3996e0;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46 ._label_rjj31_64 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46 ._actions_rjj31_73 {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46 ._actions_rjj31_73 ._item_rjj31_79 {
  margin-left: 5px;
  color: #999;
  will-change: color;
  transition: color 0.1s ease-out;
}
._SelectListPopover_rjj31_32 ._list_rjj31_46 ._option_rjj31_46 ._actions_rjj31_73 ._item_rjj31_79:hover {
  color: #3996e0;
}
._SelectListPopover_rjj31_32 ._addList_rjj31_88 {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._SelectListPopover_rjj31_32 ._addList_rjj31_88:hover {
  color: #3996e0;
  background-color: #f0f4f8;
}
._SelectListPopover_rjj31_32 ._addList_rjj31_88 ._addIconWrapper_rjj31_102 {
  margin-right: 10px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 100px;
  background-color: white;
}
._SelectListPopover_rjj31_32 ._addList_rjj31_88 ._addIcon_rjj31_102 {
  transform: rotate(45deg);
  color: #3996e0;
}
._SelectListPopover_rjj31_32 ._addList_rjj31_88._disabled_rjj31_60 {
  pointer-events: none;
  color: gray;
}
._SelectListPopover_rjj31_32 ._editList_rjj31_122 {
  padding: 0 10px;
}
._SelectListPopover_rjj31_32 ._editList_rjj31_122 ._back_rjj31_125 {
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  font-size: 15px;
  color: #1a1818;
  border-radius: 4px;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._SelectListPopover_rjj31_32 ._editList_rjj31_122 ._back_rjj31_125:hover {
  color: #3996e0;
  background-color: #f0f4f8;
}
._SelectListPopover_rjj31_32 ._editList_rjj31_122 ._back_rjj31_125 ._arrowIcon_rjj31_146 {
  position: absolute;
  left: 10px;
  top: 8px;
}
._SelectListPopover_rjj31_32 ._editList_rjj31_122 ._editContent_rjj31_151 {
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 280px;
  line-height: 20px;
}
._SelectListPopover_rjj31_32 ._editList_rjj31_122 ._button_rjj31_157 {
  margin-top: 10px;
}