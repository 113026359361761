import { backendServerApiEndpoint } from '../Shared/serviceHosts';
import { IPayment } from './models';

export const fetchPaymentById = async (paymentId: number, clientId: string): Promise<IPayment> => {
  try {
    const url = `${backendServerApiEndpoint()}/payment/${paymentId}?client_id=${clientId}`;
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};
