import * as React from 'react';
import {
  entries, find, isEmpty, last, map, orderBy, slice, size, unescape,
} from 'lodash';
import cx from 'classnames';
import { format } from 'date-fns';

import {
  EmailComposer, UserAvatar, AttachmentItem,
 } from '@frontend/app/components';
 import { getMessagePayload } from '@frontend/app/containers/Shared/utils';
 import { TContentType } from '@frontend/app/hooks';
 import { IPreviewConfig, IEmailComposer } from '@frontend/app/components/MessageComposer/EmailComposer';
 import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';

import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';
import { GetThreadsForMember_threads, GetThreadsForMember_threads_messages, GetThreadsForMember_threads_messages_payload } from '@frontend/app/queries/types/GetThreadsForMember';
import { GetUsersQuery_users } from '@frontend/app/queries/types/GetUsersQuery';

import { useAuth } from '@frontend/context/authContext';

import { Button } from '@revfluence/fresh';
import { ReplyIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './Thread.scss';

const {
 useCallback, useMemo, useState, useRef,
} = React;

const getMessageFormattedDate = (
  messageInternalDate: number,
) => format(
  new Date(messageInternalDate),
  "PPP' at 'p",
);

interface IMessageContentProps {
  message: GetThreadsForMember_threads_messages;
  isExpanded: boolean;
  className?: string;
}

const MessageContent: React.FC<IMessageContentProps> = ({ message, isExpanded, className }) => (
  <>
    {isExpanded ? (
      <>
        <div
          dangerouslySetInnerHTML={{ __html: getMessagePayload(message.payload) }}
        />
        {!isEmpty(message.attachments) && (
        <div>
          {map(message.attachments, (attachment, index) => (
            <AttachmentItem
              key={`attachment-${index}`}
              content={{
                    type: attachment.type as TContentType,
                    size: attachment.size,
                    fileUrl: attachment.href,
                    name: attachment.filename,
                }}
              editable={false}
            />
            ))}
        </div>
        )}
      </>
      ) : (
        <div className={className}>
          {unescape(message.snippet)}
        </div>
      )}
  </>
);

interface IThreadMessageProps {
  message: GetThreadsForMember_threads_messages;
  isExpanded: boolean;
  getAvatarForMessagePayload: (payload: GetThreadsForMember_threads_messages_payload) => JSX.Element
}

const ThreadMessage: React.FC<IThreadMessageProps> = ({
 message, isExpanded, getAvatarForMessagePayload,
}) => (
  <div className={styles.reply}>
    {getAvatarForMessagePayload(message.payload)}
    <div className={styles.replyContent}>
      <MessageContent message={message} isExpanded={isExpanded} className={styles.replySnippet} />
      <div className={styles.replyDate}>
        {getMessageFormattedDate(message.internalDate)}
      </div>
    </div>
  </div>
  );

export interface IMemberThread {
  member: GetMemberQuery_member;
  thread: GetThreadsForMember_threads;
  users: GetUsersQuery_users[];
  emailResources: Record<string, number>;
  onRefetch: () => void;
}

export const MemberThread: React.FC<IMemberThread> = (props) => {
  const {
    thread,
    users,
    member,
    emailResources,
    onRefetch,
  } = props;

  const { lastMessage } = thread;

  const { user } = useAuth();

  const [isThreadExpanded, setIsThreadExpanded] = useState(false);
  const [isEmailComposerShown, setIsEmailComposerShown] = useState(false);
  const messageFormRef = useRef<IEmailComposer>(null);
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);

  const resetThread = useCallback(() => {
    setIsThreadExpanded(false);
    setIsEmailComposerShown(false);
  }, [setIsThreadExpanded, setIsEmailComposerShown]);

  const handleCTAClick = useCallback(() => {
    if (isThreadExpanded) {
      resetThread();
    } else {
      setIsThreadExpanded(true);
    }
  }, [isThreadExpanded, resetThread]);

  const hasReplies = useMemo(() => (thread.messagesCount > 1), [thread.messagesCount]);

  const orderedMessages = useMemo(() => (
    orderBy(thread.messages, (m) => m.internalDate, 'asc')
  ), [thread.messages]);

  const firstMessage = useMemo(() => (
    orderedMessages[0]
  ), [orderedMessages]);

  const findResource = useCallback(() => (
    find(
      entries(emailResources),
      ([email]) =>
          !!find(
              thread.messages,
              (m: GetThreadsForMember_threads_messages) =>
                  m.payload?.from === email || (m.payload?.to && m.payload.to.includes(email)),
          ),
    )
  ), [emailResources, thread.messages]);

  const resourceId = useMemo(() => (
    // [0] -> email & [1] -> id
    findResource()?.[1]
  ), [findResource]);

  const getNormalizedThreadTitle = useCallback(() => {
    const title = lastMessage.title || lastMessage.subject;
    return title.split(/Re:\s?/i).filter(Boolean).pop();
  }, [lastMessage]);

  const getAvatarForMessagePayload = useCallback((
    payload: GetThreadsForMember_threads_messages_payload,
  ) => {
    if (payload.from === member.email) {
      return (
        <UserAvatar
          profilePicture={member.profilePicture}
          name={member.name}
        />
      );
    }

    const sender = find(users, ['email', payload.from]);

    return (
      <UserAvatar
        name={sender?.name || payload.from}
      />
    );
  }, [member.email, member.name, member.profilePicture, users]);

  const getCallToActionLabel = useCallback(() => {
    if (isThreadExpanded) return 'Show less';
    if (!hasReplies) return 'Show more';
    const hiddenReplies = thread.messagesCount - 1;
    return `${hiddenReplies} ${hiddenReplies === 1 ? 'Reply' : 'Replies'}`;
  }, [hasReplies, isThreadExpanded, thread.messagesCount]);

  const renderCallToAction = useCallback(() => (
    <div className={styles.callToAction}>
      <Button
        type="link"
        onClick={handleCTAClick}
      >
        { getCallToActionLabel() }
      </Button>
    </div>
), [getCallToActionLabel, handleCTAClick]);

const renderEmailComposer = useCallback(() => {
  if (isEmailComposerShown) {
    return (
      <div className={styles.composerContainer}>
        <UserAvatar name={user.name} />
        <div className={styles.composer}>
          <EmailComposer
            ref={messageFormRef}
            members={member ? [member] : undefined}
            threadId={thread.id}
            resourceId={resourceId}
            subject={lastMessage.subject}
            isHeaderForcedClose
            onMessageSent={onRefetch}
            className={cx(styles.emailComposer, {
              [styles.hide]: !!previewConfig,
            })}
            onPreview={setPreviewConfig}
            onClose={() => setIsEmailComposerShown(false)}
          />
          {previewConfig && (
            <EmailPreviewer
              previewConfig={previewConfig}
              onBack={() => setPreviewConfig(null)}
              onConfirm={() => {
                setPreviewConfig(null);

                messageFormRef.current.sendMessage(true);
              }}
              disableRemoveRecipient
            />
          )}
        </div>
      </div>
    );
    } else {
      return (
        <Button
          className={styles.replyButton}
          icon={<ReplyIcon />}
          onClick={() => setIsEmailComposerShown(true)}
        >
          Reply
        </Button>
  );
    }
  }, [
    isEmailComposerShown,
    lastMessage.subject,
    member,
    onRefetch,
    resourceId,
    thread.id,
    user.name,
    setPreviewConfig,
    previewConfig,
  ]);

  const renderMainMessage = useCallback(() => (
    <div className={styles.mainMessage}>
      <div className={styles.subject}>
        {getNormalizedThreadTitle()}
      </div>
      <MessageContent message={firstMessage} isExpanded={isThreadExpanded} className={styles.snippet} />
      {(isThreadExpanded || !hasReplies) && (
      <div>
        {getMessageFormattedDate(firstMessage.internalDate)}
      </div>
    )}
    </div>
  ), [firstMessage, getNormalizedThreadTitle, hasReplies, isThreadExpanded]);

  const renderPreviewMessage = useCallback(() => {
    if (size(orderedMessages) <= 1) return null;

    return (<ThreadMessage message={last(orderedMessages)} isExpanded={isThreadExpanded} getAvatarForMessagePayload={getAvatarForMessagePayload} />);
  }, [getAvatarForMessagePayload, isThreadExpanded, orderedMessages]);

  if (!lastMessage) {
    return null;
  }

  return (
    <div className={styles.Thread}>
      {getAvatarForMessagePayload(firstMessage.payload)}
      <div className={styles.content}>
        {renderMainMessage()}

        {isThreadExpanded ? (
          map(slice(orderedMessages, 1), (message) => (
            <ThreadMessage message={message} isExpanded={isThreadExpanded} getAvatarForMessagePayload={getAvatarForMessagePayload} key={message.id} />
          ))
        ) : (
          renderPreviewMessage()
        )}

        {isThreadExpanded && (
          renderEmailComposer()
        )}
        {renderCallToAction()}
      </div>
    </div>
  );
};
