import { IState } from '../../../../types/state';
import { IToggleSpecialPaymentTerms } from './index';

export const toggleSpecialPaymentTerms = (state: IState, action: IToggleSpecialPaymentTerms): IState => {
  if (state.collaborationDetails.reward.payment.toggleSpecialPaymentTerms === action.toggleSpecialPaymentTerms) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      reward: {
        ...state.collaborationDetails.reward,
        payment: {
          ...state.collaborationDetails.reward.payment,
          toggleSpecialPaymentTerms: action.toggleSpecialPaymentTerms,
        },
      },
    },
  };
};
