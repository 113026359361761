import * as React from 'react';
import { Input } from '@components';

export const SingleValue = ({ values, setFilters, filterName }) => {
  const onValueChange = (value) => {
    setFilters({
      type: `SET_${filterName}_FILTER_AMOUNT_ONE`,
      payload: value,
    });
  };
  return (
    <div style={{ margin: '10px' }}>
      <Input
        onChange={onValueChange}
        value={values.amountOne}
        type="integer"
        icon={<span>$</span>}
      />
    </div>
  );
};

export const ValueRange = ({ values, setFilters, filterName }) => {
  const onValueChange = (amountValue) => (value) => {
    setFilters({
      type: `SET_${filterName}_FILTER_AMOUNT_${amountValue}`,
      payload: value,
    });
  };
  return (
    <div style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
      <div style={{ maxWidth: '150px' }}>
        <Input
          value={values.amountOne}
          type="integer"
          icon={<span>$</span>}
          onChange={onValueChange('ONE')}
        />
      </div>
      &
      <div style={{ maxWidth: '150px' }}>
        <Input
          value={values.amountTwo}
          type="integer"
          icon={<span>$</span>}
          onChange={onValueChange('TWO')}
        />
      </div>
    </div>
  );
};
