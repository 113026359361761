import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/client';

import { UPDATE_RESOURCE_MUTATION } from '@frontend/app/queries';
import {
  UpdateResourceMutation, UpdateResourceMutationVariables,
} from '@frontend/app/queries/types/UpdateResourceMutation';
import { useMessagingContext } from '@frontend/hooks';

export type IUpdateResourceOptions = MutationHookOptions<UpdateResourceMutation, UpdateResourceMutationVariables>;

const { useEffect } = React;

export function useUpdateResource(options: IUpdateResourceOptions = {}) {
  const {
    showInfoMessage,
    showErrorMessage,
  } = useMessagingContext();
  const [updateResource, {
    loading,
    error,
  }] = useMutation<
  UpdateResourceMutation,
  UpdateResourceMutationVariables
  >(UPDATE_RESOURCE_MUTATION, {
    ...options,
    onCompleted() {
      showInfoMessage('Account settings updated.');
    },
  });

  useEffect(() => {
    if (error) {
      showErrorMessage(error.message);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    loading,
    updateResource,
    error,
  };
}
