import * as React from 'react';

import styles from './BaseMethod.scss';

interface IProps {
  loading?: boolean,
  icon: string,
  iconColor?: string,
  title: string,
  description: string,
  handleClick: Function,
}

export const BaseMethod: React.FC<IProps> = ({
    loading = false,
    icon,
    iconColor = '#000',
    title,
    description,
    handleClick,
  }) => (
    <div className={styles.BaseMethod} onClick={loading ? null : (e) => handleClick(e)}>
      <div className={styles.iconBox} style={{ backgroundColor: iconColor }}>
        <img className={styles.icon} src={icon} />
      </div>
      {loading ? (
        <>
          <p className={styles.description}>Loading...</p>
        </>
      ) : (
        <>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>{description}</p>
        </>
      )}
    </div>
  );
