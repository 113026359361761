import * as React from 'react';
import { Space, Typography } from '@revfluence/fresh';

const { Title, Text } = Typography;

type Props = {
  title: string;
  description: string;
};

export const Description = ({ title, description }: React.PropsWithChildren<Props>) => (
  <Space direction="vertical" size={0} style={{ width: '100%' }}>
    <Title level={5}>{title}</Title>
    <Text>{description}</Text>
  </Space>
);
