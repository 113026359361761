import { useQuery, QueryHookOptions } from '@apollo/client';

import {
  MEMBER_SEARCH_COUNT_QUERY,
} from '@frontend/app/queries';
import {
  MemberSearchCountQuery,
  MemberSearchCountQueryVariables,
} from '@frontend/app/queries/types/MemberSearchCountQuery';

type IOptions = QueryHookOptions<MemberSearchCountQuery, MemberSearchCountQueryVariables>;

export const useMemberSearchCountQuery = (options: IOptions = {}) => useQuery<MemberSearchCountQuery, MemberSearchCountQueryVariables>(MEMBER_SEARCH_COUNT_QUERY, options);
