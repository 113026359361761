import gql from 'graphql-tag';

export const TERMS_DASHBOARD_QUERY = gql`
  query TermsDashboardQuery($programId: Int!, $detailed: Boolean = false) {
    getTermsDashboardDataForProgram: getTermsDashboardDataForProgram(programId: $programId, detailed: $detailed) {
      accepted {
        count
        deliverableCount
        estimatedReach
        paymentSummary
      }
      details {
        programId
        memberId
        agreementId
        iterationId
        accepted
        deliverableCount
        estimatedReach
        offersPayment
        paymentValue
      }
    }
  }
`;
