import {
  subMonths,
  endOfMonth,
  isSameDay,
  startOfMonth,
  format,
} from 'date-fns';

import { IRecommendation } from '../pages/Home/components/AspireAdvice';

export const DEFAULT_RECOMMENDATIONS: IRecommendation[] = [
  {
    title: 'Want to improve your Impact Value?',
    text: 'Understand which goals are right for you, and in turn should influence your campaign strategy.',
    ctaText: 'Learn which goal is right for my brand',
    ctaLink: 'https://aspire.io/blog/how-to-measure-influencer-marketing-roi-the-right-way/',
    htmlTagId: 'improve-impact-value',
  },
  {
    title: 'Want to increase your ROAS?',
    text: 'Branded Content Ads perform up to 40% better than standard brand ads. Learn how to run high-performing influencer campaigns through Aspire.',
    ctaText: 'See how Vuori Performance Apparel does it',
    ctaLink: 'https://www.facebook.com/business/success/vuori',
    htmlTagId: 'use-branded-content-ads',
  },
  {
    title: 'Want to drive more sales through the platform?',
    text: 'From allowlisting to affiliate and referral programs, you can manage any campaign through Aspire.',
    ctaText: 'Here’s what you need to know about driving sales',
    ctaLink: 'https://aspire.io/drive-sales/',
    htmlTagId: 'drive-sales',
  },
  {
    title: 'Are you using TikTok to its full potential?',
    text: 'TikTok content is outperforming all other channels on the platform today.',
    ctaText: 'Here’s everything you need to know about TikTok',
    ctaLink: 'https://aspire.io/ty-tiktok-influencer-marketing-series/',
    htmlTagId: 'use-tiktok',
  },
];

export const getLastFullMonths = (n: number): [Date, Date] => {
  const today = new Date();
  const isFullMonth = isSameDay(today, endOfMonth(today));
  const startDate = startOfMonth(subMonths(today, isFullMonth ? n - 1 : n));
  const endDate = endOfMonth(isFullMonth ? today : endOfMonth(subMonths(today, 1)));
  return [startDate, endDate];
};

export const getTrendTooltip = ([startDate, endDate]: [Date, Date]) => {
  const formatMonth = (d: Date) => format(d, 'MMM');
  return `Month over Month change from ${formatMonth(startDate)} to ${formatMonth(endDate)}.`;
};
