import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const Youtube = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 26 18"
    >
      <path
        fill={props.fill}
        d="
        M25.2344 2.85938C24.9531 1.73438 24.0625 0.84375 22.9844 0.5625C20.9688 0 13 0 13 0C13 0 4.98438 0 2.96875 0.5625C1.89062 0.84375 1 1.73438 0.71875 2.85938C0.15625 4.82812 0.15625 9.04688 0.15625 9.04688C0.15625 9.04688 0.15625 13.2188 0.71875 15.2344C1 16.3594 1.89062 17.2031 2.96875 17.4844C4.98438 18 13 18 13 18C13 18 20.9688 18 22.9844 17.4844C24.0625 17.2031 24.9531 16.3594 25.2344 15.2344C25.7969 13.2188 25.7969 9.04688 25.7969 9.04688C25.7969 9.04688 25.7969 4.82812 25.2344 2.85938ZM10.375 12.8438V5.25L17.0312 9.04688L10.375 12.8438Z
        "
      />
      <mask
        id={id}
        maskUnits="userSpaceOnUse"
      >
        <path
          d="
          M25.2344 2.85938C24.9531 1.73438 24.0625 0.84375 22.9844 0.5625C20.9688 0 13 0 13 0C13 0 4.98438 0 2.96875 0.5625C1.89062 0.84375 1 1.73438 0.71875 2.85938C0.15625 4.82812 0.15625 9.04688 0.15625 9.04688C0.15625 9.04688 0.15625 13.2188 0.71875 15.2344C1 16.3594 1.89062 17.2031 2.96875 17.4844C4.98438 18 13 18 13 18C13 18 20.9688 18 22.9844 17.4844C24.0625 17.2031 24.9531 16.3594 25.2344 15.2344C25.7969 13.2188 25.7969 9.04688 25.7969 9.04688C25.7969 9.04688 25.7969 4.82812 25.2344 2.85938ZM10.375 12.8438V5.25L17.0312 9.04688L10.375 12.8438Z
          "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

Youtube.displayName = 'YoutubeUncolored';

export { Youtube };
