import gql from 'graphql-tag';

import { WORK_ITEM_FRAGMENT } from './fragments';

export const GET_WORK_ITEMS = gql`
  query GetWorkItemsQuery($specKey: String!, $specUri: String, $taskId: String) {
    workItems: getAllWorkItems(specKey: $specKey, specUri: $specUri, taskId: $taskId) {
      ...WorkItemFragment
    }
  }
  ${WORK_ITEM_FRAGMENT}
`;
