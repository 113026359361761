import cx from 'classnames';
import { isNil } from 'lodash';
import * as React from 'react';

import { EllipsisLabel } from '@frontend/app/components';

import { IFolderProps } from './Folder';

import styles from './Folder.scss';

type TSomeFolderProps = Pick<IFolderProps, 'accessory' | 'className' | 'hoverAccessory' | 'icon'>;
interface IFolderItemProps extends TSomeFolderProps {
  showHoverAccessory?: boolean;
}

export const FolderTitle: React.FC<IFolderItemProps> = React.memo((props) => {
  const {
    accessory,
    children,
    className,
    hoverAccessory,
    icon,
    showHoverAccessory,
  } = props;

  return (
    <div className={cx(styles.Title, className)}>
      {icon && (
        <div className={styles.icon}>{icon}</div>
      )}
      <EllipsisLabel
        className={styles.title}
        tooltipPlacement="bottomLeft"
      >
        {children}
      </EllipsisLabel>
      <div className={styles.accessory}>
        {showHoverAccessory && !isNil(hoverAccessory)
          ? hoverAccessory
          : accessory}
      </div>
    </div>
  );
});

FolderTitle.displayName = 'FolderTitle';
