import * as React from 'react';
import cx from 'classnames';

import styles from './Card.scss';

type TBaseProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export type TSocialProfileTabType = React.FC<ICardTabProps>;

export interface ICardTabProps extends TBaseProps {
  key: string;
}

export const CardTab: TSocialProfileTabType = React.memo<TSocialProfileTabType>(
  React.forwardRef((props: ICardTabProps, forwardRef: React.RefObject<HTMLDivElement>) => {
    const {
      children,
      className,
      ...restProps
    } = props;

    const ref = forwardRef || React.createRef<HTMLDivElement>();

    return (
      <div
        className={cx(styles.cardTab, className)}
        ref={ref}
        {...restProps}
      >
        {children}
      </div>
    );
  }),
);

CardTab.displayName = 'CardTab';
