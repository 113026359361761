import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_BRAND_CATALOG_BY_ID_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { GetBrandCatalogByIdQuery, GetBrandCatalogByIdQueryVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetBrandCatalogByIdQuery';

type IOptions = QueryHookOptions<GetBrandCatalogByIdQuery, GetBrandCatalogByIdQueryVariables>;

export const useGetCatalogDetails = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch,
} = useQuery(GET_BRAND_CATALOG_BY_ID_QUERY, options);

  return {
    loading,
    catalog: data?.getBrandCatalogById ?? ({} as GetBrandCatalogByIdQuery['getBrandCatalogById']),
    error,
    refetch,
  };
};
