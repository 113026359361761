/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1betj_157 {
  display: flex;
}

._justify-content-space-between_1betj_161 {
  justify-content: space-between;
}

._tabular-nums_1betj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1betj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CommunityDropdownLabel_1betj_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._CommunityDropdownLabel_1betj_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1a1818;
  font-weight: 600;
  border-radius: 0.25rem;
  padding: 0.625rem;
}
._CommunityDropdownLabel_1betj_178:hover {
  background-color: #e6edf2;
}
._CommunityDropdownLabel_1betj_178 ._logo_1betj_200 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  margin-right: 0.75rem;
}
._CommunityDropdownLabel_1betj_178 ._logo_1betj_200 ._img_1betj_209 {
  border-radius: 0.5rem;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
._CommunityDropdownLabel_1betj_178 ._title_1betj_215 {
  flex: 1;
  margin-right: 0.5rem;
}
._CommunityDropdownLabel_1betj_178 ._arrow_1betj_219 {
  margin-left: auto;
  font-size: 0.75rem;
  font-weight: bold;
}