import * as React from 'react';

import { Button, Tooltip } from '@revfluence/fresh';
import { CalendarPenIcon } from '@revfluence/fresh-icons/regular/esm';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  title: string;
  tooltipText?: string;
}

export const RefreshDatesButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
 disabled, onClick, title, tooltipText,
} = props;
  return (
    <Tooltip placement="top" title={tooltipText || title}>
      <Button disabled={disabled} icon={<CalendarPenIcon />} onClick={onClick} type="text">{title}</Button>
    </Tooltip>
  );
});

RefreshDatesButton.defaultProps = {
  disabled: false,
};

RefreshDatesButton.displayName = 'RefreshDatesButton';
