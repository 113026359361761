import * as React from 'react';
import cx from 'classnames';
import { Modal as AntdModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

import styles from './Modal.scss';

export interface IModalProps extends ModalProps { }

export const Modal: React.FC<IModalProps> = (props) => {
  const { className, ...restProps } = props;
  return (
    <AntdModal {...restProps} className={cx(className, styles.Modal)} />
  );
};
