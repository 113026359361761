import * as React from 'react';
import cx from 'classnames';
import { Form } from 'antd';

import { ExternalLinkIcon } from '@components';
import { CONTENT_TYPE_BY_NETWORK } from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/context/model';
import CreatorOffers from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/MarketplaceTabs/CreatorOffers/CreatorOffers';

import FormLocationField from '../../components/FormLocationField';
import FormMultiSelect from '../../components/FormMultiSelect';
import {
  OnboardingStep,
  OnboardingDispatchActionType,
  IRequirementsProps,
} from '../../types';
import styles from '../styles.scss';

import requirementsStyles from './styles.scss';

const requirementSvg = 'https://storage.googleapis.com/aspirex-static-files/onboarding/onboarding_requirements.svg';

const { useCallback } = React;

const Requirements = (props: IRequirementsProps) => {
  const { state, dispatch } = props;

  const onFieldChange = useCallback((name: string, value) => {
    dispatch({
      type: OnboardingDispatchActionType.UpdateFieldValue,
      payload: {
        step: OnboardingStep.Requirements,
        field: name,
        value,
      },
    });
  }, [dispatch]);

  return (
    <div className={styles.StepContent}>
      <div className={styles.stepForm}>
        <p>All fields are required unless marked optional.</p>
        <Form layout="vertical">
          <FormMultiSelect
            label="What do you want creators to deliver?"
            name="product_types"
            option={CONTENT_TYPE_BY_NETWORK}
            value={state.data?.product_types}
            onFieldChange={onFieldChange}
            isRequired
          />
          <FormLocationField
            label="Creator Location (Optional)"
            name="accepted_place_ids"
            placeholder="Enter location"
            value={state.data?.accepted_place_ids}
            onFieldChange={onFieldChange}
            extraMsg="Leave this field blank if you do not want to restrict creators by their city, state, or country of residence"
          />
          <CreatorOffers
            title="How will you compensate creators?"
            setFieldValue={onFieldChange}
            getFieldValue={(name) => state.data && state.data[name]}
          />
        </Form>
      </div>
      <div className={styles.stepInfo}>
        <h3 className={styles.title}>How To Incentivize Creators</h3>
        <p className={styles.section}>
          Not sure how to incentivize creators? Whatever method you choose, the goal is to create a relationship based on mutual respect and to set the foundation for a long term relationship. We've written a guide to help you understand the different ways you compensate your influential partners.
          {' '}
          <a href="https://aspire.io/blog/influencer-incentives/" target="_blank">
            Learn more
            {' '}
            <ExternalLinkIcon size={14} className={styles.externalLinkIcon} />
          </a>
        </p>
        <p className={cx(styles.section, requirementsStyles.requirementsImage)}>
          <img src={requirementSvg} />
        </p>
      </div>
    </div>
  );
};

const RequirementsComponent = React.memo(Requirements);

RequirementsComponent.displayName = 'Requirements';

export default RequirementsComponent;
