import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CURRENT_CLIENT_QUERY } from '@frontend/app/queries';

import {
  GetCurrentClientQuery,
  GetCurrentClientQuery_client,
  GetCurrentClientQuery_client_users,
} from '@frontend/app/queries/types/GetCurrentClientQuery';

type IOptions = QueryHookOptions<GetCurrentClientQuery>;
export type IClient = GetCurrentClientQuery_client;
export type IUser = GetCurrentClientQuery_client_users;

export function useGetCurrentClient(options: IOptions = {}) {
  const {
 loading, data: { client } = {}, error, refetch,
} = useQuery<GetCurrentClientQuery>(
    GET_CURRENT_CLIENT_QUERY,
    options,
  );

  return {
    loading,
    client,
    error,
    refetch,
  };
}
