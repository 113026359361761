import * as React from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { ContentPage } from '@components';
import { ContentAssignment } from '../components/ContentAssignment';

import styles from './MemberContentList.scss';

const { useState } = React;

const MemberContentList: React.FC = React.memo(() => {
  const [contentToAssign, setContentToAssign] = useState(null);

  const { backendServerApiEndpoint, memberId, clientId } = useApplication();

  const onAssignClick = (content, event) => {
    event.preventDefault();
    setContentToAssign(content);
  };

  const onAssignmentComplete = () => {
    setContentToAssign(null);
  };

  return (
    <div className={styles.MemberContentList}>
      {contentToAssign
        ? <ContentAssignment contents={[contentToAssign]} onFlowComplete={onAssignmentComplete} />
        : (
          <ContentPage
            apiEndpoint={backendServerApiEndpoint}
            contentUploadFolder="aspirex_content_library"
            showVisitManageLink={false}
            clientId={clientId}
            filters={{
              query: null,
              selectedCampaignId: null,
              selectedNetworkId: null,
              selectedTags: [],
              sku: null,
              selectedPermissionLevel: null,
              selectedMediaType: null,
              memberId,
              activationId: null,
              programId: null,
              communityId: null,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any}
            hideFilters
            onAssignClick={onAssignClick}
            isQA={false}
          />
)}
    </div>
  );
});

MemberContentList.displayName = 'MemberContentList';

export default MemberContentList;
