import * as React from 'react';
import { Tooltip } from '@revfluence/fresh';

import { InfoIcon } from '@components';

import { Info } from 'lucide-react';
import styles from './AccessLimitHeader.scss';

interface IProp {
  refreshUi?: boolean;
}
export const AccessLimitHeader: React.FC<IProp> = ({ refreshUi }) => (
  <>
    {refreshUi ? (
      <h2 className="text-white flex items-center">
        Current permissions:
        <span className="text-[#5DB884] ml-2">Unlimited</span>
        <Tooltip
          placement="right"
          title="You can request unlimited ad permissions from creators"
        >
          <Info className="ml-2 text-[#5DB884]" />
        </Tooltip>
      </h2>
    ) : (
      <h2 className={styles.AccessLimitHeader}>
        Current permissions this months: unlimited
        <Tooltip
          placement="right"
          title="You can request unlimited ad permissions from creators"
        >
          <InfoIcon className={styles.infoIcon} />
        </Tooltip>
      </h2>
    )}
  </>

);
