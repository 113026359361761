import * as React from 'react';
import { IField } from '@frontend/app/containers/Projects/LandingPages/types';
import { SignupForm } from '@frontend/app/containers/Public/Onboarding/SignupForm';
import { ApplicationPageBuilderComponent, isFieldVisibleInPage } from '@frontend/app/containers/Projects/applicationPageUtils';
import { isUndefined } from 'lodash';
import { getGoogleFontsURL } from '@frontend/app/containers/Projects/LandingPages/constants/fonts';
import Helmet from 'react-helmet';
import { HeroMedia } from '../common/HeroMedia/HeroMedia';
import { Intro } from './Intro/Intro';
import { About } from './About/About';
import { Perks } from './Perks/Perks';
import { defaultIntro } from '../CustomizedTemplate/constants';
import { IQuickTemplateStyles, ITemplateProps } from '../types';

import styles from './QuickTemplate.scss';

const { useCallback, useEffect } = React;

interface IProps {
  config?: ITemplateProps;
  applicationFormFields?: IField[];
  hasUnpaidOffer?: boolean;
  isUserView?: boolean;
}

export const QuickTemplate: React.FC<IProps> = (props) => {
  const isFieldVisible = (fieldName: ApplicationPageBuilderComponent) => isFieldVisibleInPage(fieldName, { onboardingTemplateConfig: props.config });

  const setFavicon = useCallback(async () => {
    const favicon = props.config?.settings?.favicon;

    if (!favicon) {
      return;
    }

    const { headers } = await fetch(favicon);
    const contentType = headers?.get('Content-Type')
      || 'image/png';

    const link = (
      document.querySelector('link[rel*=icon]')
        || document.createElement('link')
    ) as HTMLLinkElement;

    link.type = contentType;
    link.rel = 'shortcut apple-touch-icon icon';
    link.href = favicon;

    link.setAttribute('sizes', '180x180');

    document.getElementsByTagName('head')[0].appendChild(link);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, document, props.config?.settings?.favicon]);

  const componentStyles: IQuickTemplateStyles = {
    heading: {
      fontFamily: props.config.settings?.headline_styling?.font_family,
    },
    body: {
      fontFamily: props.config.settings?.body_styling?.font_family,
    },
  };

  useEffect(() => {
    if (
      isUndefined(window)
        || !props.isUserView
    ) {
      return;
    }

    setFavicon();
  }, [
    setFavicon,
    props.isUserView,
  ]);

  return (
    <div className={styles.QuickTemplate}>
      {
        props.isUserView && (
          <Helmet>
            <title>{props.config.intro?.title}</title>
          </Helmet>
        )
      }

      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href={getGoogleFontsURL()} rel="stylesheet" />
      </head>

      {
        isFieldVisible(ApplicationPageBuilderComponent.IntroHeroImageVideo)
          && (
            <HeroMedia
              heroSrc={props.config.intro?.hero_image ?? defaultIntro?.hero_image}
              isVideo={props.config.intro?.hero_is_video ?? false}
            />
          )
      }
      <div className={styles.templateContent}>
        <div className={styles.config}>
          <Intro
            logo={props.config.settings?.logo}
            title={props.config.intro?.title ?? defaultIntro?.title}
            brand_instagram={props.config.intro?.brand_instagram}
            brand_pinterest={props.config.intro?.brand_pinterest}
            brand_tiktok={props.config.intro?.brand_tiktok}
            brand_website={props.config.intro?.brand_website}
            brand_youtube={props.config.intro?.brand_youtube}
            subtitle={props.config.intro?.subtitle}
            isFieldVisible={isFieldVisible}
            componentStyles={componentStyles}
          />
          <About
            {...(props.config.about || {})}
            isFieldVisible={isFieldVisible}
            componentStyles={componentStyles}
          />
          <Perks
            {...(props.config.perks || {})}
            isFieldVisible={isFieldVisible}
            componentStyles={componentStyles}
          />
        </div>
        <div className={styles.form}>
          <SignupForm
            fields={props.applicationFormFields}
            isLandingPageV2
            isPreview
            className={styles.signUpForm}
            confirmationColor="black"
            hasUnpaidOffer={props.hasUnpaidOffer}
            backgroundColor="white"
            supportEmail={props.config?.application?.support_email}
            applyLabel={props.config?.application?.apply_label}
            unpaidLabel={props.config?.application?.unpaidLabel || 'This is an unpaid collaboration'}
            unpaidDescription={props.config?.application?.unpaidDescription || 'I understand that this collaboration does not offer payment.'}
          />
        </div>
      </div>
    </div>
  );
};

QuickTemplate.displayName = 'QuickTemplate';
