import * as React from 'react';
import { useState } from 'react';
import { PencilIcon, CheckIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button, Select, Space } from '@revfluence/fresh';

const { Option } = Select;

interface IProps {
    value?: number;
    onChange: (newValue: number) => void;
    options: { label: string, value: number }[];
    placeholder?: string;
}

export const EditableSelect: React.FC<IProps> = ({
 value, onChange, options, placeholder,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [tempValue, setTempValue] = useState(value || null);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCheckClick = () => {
        onChange(tempValue);
        setIsEditing(false);
    };

    const handleCloseClick = () => {
        setTempValue(value || null);
        setIsEditing(false);
    };

    const handleSelectChange = (newValue: number) => {
        setTempValue(newValue);
    };

    return (
      <div>
        <Space>
          {isEditing ? (
            <>
              <Select
                value={tempValue}
                onChange={handleSelectChange}
                style={{ width: 200 }}
                placeholder={placeholder}
              >
                {options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              <Button
                icon={<CheckIcon style={{ color: '#167CF4' }} />}
                onClick={handleCheckClick}
                type="text"
                style={{ padding: 0 }}
              />
              <Button
                icon={<XmarkIcon style={{ color: 'red' }} />}
                onClick={handleCloseClick}
                type="text"
                style={{ padding: 0 }}
              />
            </>
          ) : (
            <>
              <span>{value && options.find((option) => option.value === value)?.label ? options.find((option) => option.value === value).label : '-'}</span>
              <Button
                icon={<PencilIcon style={{ color: '#167CF4', fontSize: '14px' }} />}
                onClick={handleEditClick}
                type="text"
                style={{ padding: 0, width: '20px', height: '20px' }}
              />
            </>
          )}
        </Space>
      </div>
    );
};
