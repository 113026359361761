import * as React from 'react';
import cx from 'classnames';
import moment from 'moment';
import { isEmpty, size } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import {
 Input, Textarea,
} from '@components';

import { useMessagingContext } from '@frontend/hooks';
import {
 FormActions, SimpleForm, SimpleField, SimpleDateField,
} from '@frontend/app/components';
import {
  useCreateMembersRequirementMutation,
  useUpdateRequirementMutation,
  useDeleteRequirementByIdMutation,
} from '@frontend/app/hooks';
import { REQUIREMENTS_FOR_MEMBER } from '@frontend/app/queries';
import {
  RequirementsForMemberQuery,
  RequirementsForMemberQueryVariables,
} from '@frontend/app/queries/types/RequirementsForMemberQuery';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { RequirementInput } from '@frontend/app/types/globalTypes';
import { midnight } from '@frontend/app/utils/date';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import styles from './AddEditRequirementForm.scss';

const {
 useState, useCallback, useMemo, useRef,
} = React;

type IRequirement = RequirementsForMemberQuery['requirements'][0];

interface IProps {
  requirement?: IRequirement;
  query?: IMemberSearchQuery;
  memberIds?: number[];
  memberCount?: number;
  source?: string;
  onClickCancel?();
  onComplete?();
  onDelete?();
}

enum FieldNames {
  NAME = 'name',
  DESCRIPTION = 'description',
  DUE_DATE = 'dueDate',
}

export const AddEditRequirementForm: React.FunctionComponent<IProps> = React.memo((props) => {
  const convertDateToString = useCallback((date: Date): string => (date ? moment(midnight(date)).format('YYYY-MM-DD') : null), []);

  const defaultRequirementInput = useMemo(() => {
    if (props.requirement) {
      const {
        name,
        description,
        dueDate,
      } = props.requirement;

      return {
        name,
        description,
        dueDate,
      };
    }

    return {
      name: '',
      description: '',
      dueDate: convertDateToString(new Date()),
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formRef = useRef<SimpleForm>();

  const [requirementInput, setRequirementInput] = useState<RequirementInput>(defaultRequirementInput);

  const {
    showMessage,
    showError,
  } = useMessagingContext();

  const [fetchMemberRequirements] = useLazyQuery<RequirementsForMemberQuery, RequirementsForMemberQueryVariables>(REQUIREMENTS_FOR_MEMBER, {
    fetchPolicy: 'network-only',
  });

  const [createMembersRequirement, {
    loading: creating,
  }] = useCreateMembersRequirementMutation({
    update() {
      if (size(props.memberIds) === 1) {
        // If requirement was created for a single member, refetch that member's requirements.
        fetchMemberRequirements({
          variables: {
            memberId: props.memberIds[0],
          },
        });
      }
    },

    onCompleted() {
      showMessage({
        type: 'success',
        content: 'Requirement created for members',
      });

      if (props.onComplete) {
        props.onComplete();
      }
    },

    onError(error) {
      showError(error);
    },
  });

  const [updateRequirement, {
    loading: updating,
  }] = useUpdateRequirementMutation({
    onCompleted() {
      showMessage({
        type: 'success',
        content: 'Requirement updated',
      });

      if (props.onComplete) {
        props.onComplete();
      }
    },

    onError(error) {
      showError(error);
    },
  });

  const [deleteRequirement, {
    loading: deleting,
  }] = useDeleteRequirementByIdMutation({
    update() {
      fetchMemberRequirements({
        variables: {
          memberId: props.requirement.memberId,
        },
      });
    },

    onCompleted() {
      showMessage({
        type: 'success',
        content: 'Requirement deleted',
      });

      if (props.onDelete) {
        props.onDelete();
      }
    },

    onError(error) {
      showError(error);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeFields = useCallback((fieldName: string, value: any) => {
    if (fieldName === FieldNames.DUE_DATE) {
      value = convertDateToString(value);
    }
    setRequirementInput((input) => ({ ...input, [fieldName]: value }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSave = () => {
    formRef.current.submit();
  };

  const addEvent = useEventContext();

  const handleSubmit = () => {
    const vars = isEmpty(props.memberIds) ? {
      query: props.query,
    } : {
      memberIds: props.memberIds,
    };

    if (props.requirement) {
      updateRequirement({
        variables: {
          id: props.requirement.id,
          requirement: requirementInput,
        },
      });
    } else {
      createMembersRequirement({
        variables: {
          ...vars,
          requirement: requirementInput,
        },
      });
    }

    const dueInDays = () => {
      const input = new Date(requirementInput.dueDate);
      const today = new Date();
      return input.getDate() - today.getDate() + 1;
    };

    switch (props.source) {
      case 'member_list':
        addEvent(
          EventName.CompleteBulkAction,
          {
            action: 'add_requirement',
            member_count: size(props.memberIds) || props.memberCount,
            due_in_days: dueInDays(),
            details_char_count: requirementInput.description.length,
          },
        );
        break;
      case 'member_details':
        addEvent(
          EventName.CreateRequirement,
          {
            due_in_days: dueInDays(),
            details_char_count: requirementInput.description.length,
          },
        );
        break;
      default:
        break;
    }
  };

  const handleClickDelete = () => {
    deleteRequirement({
      variables: {
        id: props.requirement.id,
      },
    });
  };

  const saving = creating || updating;
  const disabled = creating || updating || deleting;

  const isEditing = !!props.requirement;

  const formValues = useMemo(() => ({
    ...requirementInput,
    dueDate: requirementInput.dueDate && moment(requirementInput.dueDate).toDate(),
  }), [requirementInput]);

  return (
    <div className={cx(styles.AddEditRequirementForm, { [styles.disabled]: disabled })}>
      <FormActions
        title={isEditing ? 'Edit Requirement' : 'New Requirement'}
        primaryAction={{
          disabled,
          label: 'Save',
          loading: saving,
          onClick: handleClickSave,
        }}
        secondaryAction={{
          disabled,
          label: 'Cancel',
          onClick: props.onClickCancel,
        }}
        tertiaryAction={isEditing
          ? {
            danger: true,
            disabled,
            label: 'Delete',
            loading: deleting,
            onClick: handleClickDelete,
          }
          : undefined}
      >
        <SimpleForm
          ref={formRef}
          values={formValues}
          onChange={handleChangeFields}
          onSubmit={handleSubmit}
        >
          <div className={styles.label}>
            Requirement name (required):
          </div>
          <SimpleField name={FieldNames.NAME} type="string" required>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <Input className={(styles as any).input} />
          </SimpleField>

          <div className={styles.label}>
            Requirement description:
          </div>
          <SimpleField name={FieldNames.DESCRIPTION} type="string" required={false}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <Textarea className={(styles as any).textarea} value="" onChange={() => {}} />
          </SimpleField>

          <div className={styles.label}>
            Deadline
          </div>
          <SimpleField name={FieldNames.DUE_DATE} type="date" required>
            <SimpleDateField className={styles.datePicker} />
          </SimpleField>
        </SimpleForm>
      </FormActions>
    </div>
  );
});
