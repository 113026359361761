/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1s0hp_157 {
  display: flex;
}

._justify-content-space-between_1s0hp_161 {
  justify-content: space-between;
}

._tabular-nums_1s0hp_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1s0hp_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._About_1s0hp_178 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem auto 0;
  max-width: 93.75rem;
}
@media only screen and (min-width: 20rem) {
  ._About_1s0hp_178 ._title_1s0hp_187,
  ._About_1s0hp_178 ._description_1s0hp_188 {
    color: #fdfdfd;
  }
  ._About_1s0hp_178 ._title_1s0hp_187 {
    margin-bottom: 0;
    font-weight: 900;
    font-style: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    text-align: center;
    word-break: break-word;
  }
  ._About_1s0hp_178 ._description_1s0hp_188 {
    width: 90%;
    padding: 0 1.25rem;
    margin: 1.5rem 0;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    white-space: break-spaces;
    word-break: break-word;
  }
  ._About_1s0hp_178 ._brandImageContainer_1s0hp_212 {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  ._About_1s0hp_178 ._brandImageContainer_1s0hp_212 ._brandImage_1s0hp_212 {
    flex: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  ._About_1s0hp_178 ._brandImageContainer_1s0hp_212 ._brandImage_1s0hp_212:nth-child(2) {
    margin: 0 0.5rem;
  }
  ._About_1s0hp_178 ._brandImageContainer_1s0hp_212 ._brandImage_1s0hp_212:after {
    content: "";
    display: block;
    padding-top: 130%;
  }
}
@media only screen and (orientation: landscape) {
  ._About_1s0hp_178 ._description_1s0hp_188 {
    width: 90%;
  }
  ._About_1s0hp_178 ._brandImageContainer_1s0hp_212 ._brandImage_1s0hp_212:nth-child(2) {
    margin: 0 0.875rem;
  }
}