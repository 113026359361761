import gql from 'graphql-tag';

export const GET_RUNNING_KLAVIYO_SYNC = gql`
  query GetRunningKlaviyoSync {
    getRunningKlaviyoSync {
      id
      name
      createdDate
      completeItems
      failedItems
      totalItems
      status
    }
  }
`;
