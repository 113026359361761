import * as React from 'react';

import { EventProperties } from '@common';
import { useEventContext } from '@frontend/app/context';
import { useProjectContext } from '@frontend/app/context';

const { useCallback } = React;

/**
 * This function adds project templateName (the template used to create the project) to addEvent.
 */
export const useAddProjectEvent = () => {
  const { project } = useProjectContext();
  const addEvent = useEventContext();

  const addProjectEvent = useCallback(<E extends EventProperties, K extends keyof E>(eventName: K, properties: E[K]) => {
    if (!project) {
      return addEvent(eventName, properties);
    }

    return addEvent(eventName, {
      ...properties,
      projectTemplateName: project?.templateName,
    } as E[K]);
  }, [addEvent, project]);

  return {
    addProjectEvent,
  };
};
