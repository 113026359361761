/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1c26y_157 {
  display: flex;
}

._justify-content-space-between_1c26y_161 {
  justify-content: space-between;
}

._tabular-nums_1c26y_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1c26y_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PopoverContent_1c26y_178 ._header_1c26y_178 ._title_1c26y_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._PopoverContent_1c26y_178 ._main_1c26y_188 ._list_1c26y_188 ._item_1c26y_188, ._PopoverContent_1c26y_178 ._search_1c26y_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._TemplateSelector_1c26y_198 ._icon_1c26y_198 {
  color: #8f8d91;
}

._Popover_1c26y_178 .ant-popover-inner {
  border-radius: 10px;
}

._PopoverContent_1c26y_178 {
  margin: -0.75rem -1rem;
  display: flex;
  flex-direction: column;
  width: 50rem;
  height: 30rem;
  color: #1a1818;
  font-size: 0.875rem;
}
._PopoverContent_1c26y_178 ._header_1c26y_178 {
  flex-shrink: 0;
  height: 3.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #EDEEEF;
}
._PopoverContent_1c26y_178 ._header_1c26y_178 ._settingsIcon_1c26y_224 {
  position: absolute;
  right: 0.75rem;
  color: #3996e0;
}
._PopoverContent_1c26y_178 ._search_1c26y_188 {
  flex-shrink: 0;
  height: 2.375rem;
  border: none;
  outline: none !important;
  box-shadow: none !important;
}
._PopoverContent_1c26y_178 ._search_1c26y_188:focus ._icon_1c26y_198 {
  color: #3996e0;
}
._PopoverContent_1c26y_178 ._search_1c26y_188 ._icon_1c26y_198 {
  color: #8f8d91;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #EDEEEF;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._list_1c26y_188 {
  overflow-y: auto;
  flex-shrink: 0;
  width: 240px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #EDEEEF;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._list_1c26y_188 ._item_1c26y_188 {
  cursor: pointer;
  user-select: none;
  padding: 0 0.75rem;
  flex-shrink: 0;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #EDEEEF;
  will-change: background-color;
  transition: background-color 0.15s ease-out;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._list_1c26y_188 ._item_1c26y_188:hover {
  background-color: #fafafa;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._list_1c26y_188 ._item_1c26y_188._selected_1c26y_273 {
  background-color: #EFF5F9;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._list_1c26y_188 ._item_1c26y_188._selected_1c26y_273 ._text_1c26y_276 {
  color: #3996e0;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._content_1c26y_279 {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._content_1c26y_279 ._subject_1c26y_285 {
  padding: 0 1.5rem;
  flex-shrink: 0;
  height: 2.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #EDEEEF;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._content_1c26y_279 ._subject_1c26y_285 ._subjectText_1c26y_294 {
  flex: 1;
  color: #8f8d91;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._content_1c26y_279 ._editor_1c26y_298 {
  flex: 1;
  overflow-y: auto;
  min-width: unset;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._content_1c26y_279 ._actions_1c26y_303 {
  padding: 0 0.75rem;
  flex-shrink: 0;
  height: 3.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #EDEEEF;
}
._PopoverContent_1c26y_178 ._main_1c26y_188 ._content_1c26y_279 ._actions_1c26y_303 ._button_1c26y_313 {
  height: 1.875rem;
  font-weight: normal;
}

.ant-popover {
  pointer-events: all !important;
}