._InputGroup_gf5hd_1 > *:not(:first-child) > * > *, ._InputGroup_gf5hd_1 > *:not(:first-child) > * {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

._InputGroup_gf5hd_1 > *:not(:last-child) > * > *, ._InputGroup_gf5hd_1 > *:not(:last-child) > * {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

._InputGroup_gf5hd_1 {
  display: table;
}
._InputGroup_gf5hd_1 ._input_gf5hd_14 {
  display: table-cell;
  width: 100%;
  height: inherit;
}
._InputGroup_gf5hd_1 ._prepend_gf5hd_19 {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
}
._InputGroup_gf5hd_1 ._append_gf5hd_24 {
  display: table-cell;
  height: inherit;
  vertical-align: middle;
}