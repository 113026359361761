import { useState } from 'react';
import * as React from 'react';
import { isNull, trim } from 'lodash';
import {
  Alert,
  Button,
  Col, Collapse, Divider, Row, Typography,
} from '@revfluence/fresh';

import { OFFER_PRICE_RULE_TYPE, OFFER_STATUS } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { FormInstance } from 'antd';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { LockIcon } from '@revfluence/fresh-icons/solid/esm';
import { OFFER_FORM_LOCKING_KEY } from '@frontend/applications/AffiliatesApp/types';
import {
  OfferCodeOption,
  AddPrefix,
  OfferCodePrefix,
  OfferPurchaseType,
  OfferEndDate,
  OfferRecurringPaymentForSubscriptions,
  MultipleDiscountOptions,
} from './FormElements';
import {
  OfferCodeCollectionOptions,
  OfferCodeSuffix,
  OfferCodeGroupName,
  OfferCodeLimitNewCustomersOnly,
  OfferCodeLimitOnePerCustomer,
  OfferCodeLimitCodeUses,
  OfferCodePurchaseRestrictions,
  OfferCodeDiscountOptions,
  OfferPromoCodeCombinations,
} from '../ShopifyPromoCodeSection/FormElements';
import {
  FormAsyncAction,
  IShopifyPromoCodeFormOptions,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  PrefixTypeStrategy,
  TDisabledMap,
} from '../../types';
import { AddSuffix } from './FormElements/AddSufix';

import styles from '../../OfferForm.scss';
import { OfferSync } from './FormElements/OfferSync';
import { MultipleShopifyStores } from './FormElements/MultipleShopifyStores';
import { NewOfferActiveDate, OldOfferActiveDate } from './FormGroup';

const { useMemo } = React;
const { Text, Title } = Typography;
const { Panel } = Collapse;

interface IProps {
  disabled: TDisabledMap<IShopifyPromoCodeFormValues>;
  onFieldFocused: (type: string, selected: boolean) => void;
  showStatus: boolean;
  values: IShopifyPromoCodeFormValues;
  formOptionData: IShopifyPromoCodeFormOptions;
  migrateToGraphQL: boolean;
  isMigrationEnabled?: boolean;
  isSubscriptionEnable?: boolean;
  mode: OfferFormModes,
  formRef: FormInstance,
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
  connectedClients: GetConnectedShopify_clientConnections[],
  isNewFlow?: boolean;
  isEnabledMultipleShopify?: boolean;
  handleFormAsyncActions?: (value: FormAsyncAction) => void,
  hasMembers?: boolean;
}

export const ShopifyPromoCodeSectionNew: React.FC<Readonly<IProps>> = ({
  disabled,
  values,
  formOptionData,
  isSubscriptionEnable,
  connectedAdvertiserForSync,
  isNewFlow,
  migrateToGraphQL,
  isEnabledMultipleShopify,
  handleFormAsyncActions,
  hasMembers,
  mode,
  formRef,
  connectedClients,
}) => {
  const [addPrefixChecked, setAddPrefixChecked] = useState(!(values.codePrefix === '' || values.codePrefix === undefined || values.codePrefix === null));
  const [addSuffixChecked, setAddSuffixChecked] = useState(values.codeSuffix !== '');
  const [offerEndDate, setOfferEndDate] = useState(values.endDate !== null);
  const dateErrorMsg = values.endDate === null
    ? ''
    : values.activeDate.isSame(values.endDate, 'day')
      ? values.activeDate.isAfter(values.endDate, 'minute') || values.activeDate.isSame(values.endDate, 'minute')
        ? 'Start time must be before end time'
        : ''
      : values.activeDate.isSameOrAfter(values.endDate)
        ? 'Start date must be before end date'
        : '';

  const codeSuffix = useMemo(() => {
    if (addSuffixChecked) {
      values.isSuffixSelected = true;
      return (
        <div className={styles.codeSuffixWrapper}>
          <OfferCodeSuffix
            disabled={disabled.codeSuffix}
            name="codeSuffix"
            required
          />
        </div>
      );
    } else {
      // values.codeSuffix = '';
      values.isSuffixSelected = false;
    }
    return null;
  }, [addSuffixChecked, disabled.codeSuffix, values]);

  const codePrefix = useMemo(() => {
    if (addPrefixChecked) {
      values.isPrefixSelected = true;
      return (
        <div className={styles.codeSuffixWrapper}>
          <OfferCodePrefix
            disabled={disabled.codePrefix}
            name="codePrefix"
          />
        </div>
      );
    } else {
      values.isPrefixSelected = false;
      // values.codePrefix = '';
    }
    return null;
  }, [addPrefixChecked, disabled.codePrefix, values]);

  const endDate = useMemo(() => {
    if (offerEndDate) {
      values.isEndDateEnable = true;
      return (
        <>
          <div className={styles.CustomTextColor}>
            <Text>End Date</Text>
          </div>
          <Row>
            <Col span={24}>
              <OfferEndDate disabled={false} name="endDate" startDate={values.activeDate} />
            </Col>
          </Row>
        </>
      );
    } else {
      values.isEndDateEnable = false;
    }
    return null;
  }, [offerEndDate, values]);

  const enablePrifxAndSuffix = useMemo(() => {
    if (values.prefixType) {
      return false;
    }
    return true;
  }, [values.prefixType]);

  const promoExample = useMemo((): React.ReactNode => {
    let examplePromoCode = '';
    switch (values.prefixType) {
      case PrefixTypeStrategy.INSTAGRAM_ONLY:
        examplePromoCode = 'Instagramusername';
        break;
      case PrefixTypeStrategy.FULL_NAME_ONLY:
        examplePromoCode = 'Fullname';
        break;
      case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY:
        examplePromoCode = 'Jsmith';
        break;
      case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY:
        examplePromoCode = 'Sarahm';
        break;
      default:
        return 'summer20';
    }
    let example = '';
    if (addSuffixChecked && addPrefixChecked) {
      example = `${values?.codePrefix ?? ''}${examplePromoCode}${values?.codeSuffix ?? ''}`;
    } else if (addSuffixChecked) {
      example = `${examplePromoCode}${values?.codeSuffix ?? ''}`;
    } else if (addPrefixChecked) {
      example = `${values?.codePrefix ?? ''}${examplePromoCode}`;
    } else {
      example = examplePromoCode;
    }

    return trim(example.toUpperCase());
  }, [values.prefixType, values.codePrefix, values.codeSuffix, addPrefixChecked, addSuffixChecked]);

  const connectedStore: Set<string> = new Set<string>();
  const connectedSubscriptionEnabled = useMemo(() => {
    if (values.isMultipleShopifySyncEnabled && connectedClients?.length) {
      const connectedAdvertiserIds = values.clientsForSync.map((obj) => obj.advertiserId);
      const allEligible = connectedAdvertiserIds.every((advertiserId) => {
        const connection = connectedClients.find((conn) => conn.connectedAdvertiserId === advertiserId);
        if (!connection.isSubscriptionEligible) {
          connectedStore.add(connection.label);
        }
        return connection ? connection.isSubscriptionEligible : false;
      });
      return allEligible && isSubscriptionEnable;
    } else {
      return isSubscriptionEnable;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedClients, values.isMultipleShopifySyncEnabled, values.clientsForSync.length, isSubscriptionEnable, connectedStore]);
  // eslint-enable-next-line react-hooks/exhaustive-deps
  const isAtLeastOneConnected = connectedClients?.some((item) => item.isPrimary === false && item.isConnected === true);
  return (
    <>
      <Collapse defaultActiveKey={['3', '4', '5', '6', '7', '8', '9']} ghost>
        <Panel className={`${styles.formSection} ${styles.promoCodeContainer}`} key={3} header="Promo Code Naming Strategy">
          <div className={styles.headerSpace}>

            <Text className={styles.descTextColor}>Create a naming strategy for promo codes. All the promo codes will be generated in compliance with these rules.</Text>
          </div>
          <OfferCodeGroupName disabled={disabled.groupName} name="groupName" />
          <OfferCodeOption
            disabled={disabled.prefixType}
            halfRow={!isNull(codeSuffix)}
            name="prefixType"
          />
          <AddPrefix name="codePrefix" disabled={enablePrifxAndSuffix || disabled.codePrefix} onAddPrefixChange={setAddPrefixChecked} checked={addPrefixChecked} />
          {codePrefix}
          <AddSuffix name="codeSuffix" disabled={enablePrifxAndSuffix || disabled.codeSuffix} onAddSuffixChange={setAddSuffixChecked} checked={addSuffixChecked} />
          {codeSuffix}

          {!enablePrifxAndSuffix && (
            <div className={styles.samplePromoContainer}>
              <Text strong type="secondary">Promo Code Example:</Text>
              <br />
              <Text strong>{promoExample}</Text>
            </div>
          )}

        </Panel>
        {(isEnabledMultipleShopify && isAtLeastOneConnected) && (
          <Panel className={styles.formSection} key={8} header="Sync offer to other Shopify stores">
            <OfferSync
              disabled={!values.isNewFlow || !!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY) || values.status === OFFER_STATUS.PAUSED}
              name="multiShopifyEnabled"
              handleFormAsyncActions={handleFormAsyncActions}
              isMultipleShopifySyncEnabled={values.isMultipleShopifySyncEnabled}
              hasMembers={hasMembers}
              isNewFlow={values.isNewFlow}
            />
            {values.isMultipleShopifySyncEnabled && (
              <>
                <Title level={5}>Select Shopify Stores</Title>
                <MultipleShopifyStores
                  disabled={!!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY) || values.status === OFFER_STATUS.PAUSED}
                  mode={mode}
                  values={values}
                  handleFormAsyncActions={handleFormAsyncActions}
                  formRef={formRef}
                  connectedAdvertiserForSync={connectedAdvertiserForSync}
                />
              </>
            )}
            {
              !!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY) && (
                <Alert
                  icon={<LockIcon scale={1.5} />}
                  description="Offer sync is currently locked for editing while date settings are being modified. To change offer sync options, please update or cancel changes to date settings."
                  type="warning"
                />
              )
            }
          </Panel>
        )}

        <Panel className={styles.formSection} key={4} header="Discount Type and Value">
          <div className={styles.headerSpace}>
            <Text className={styles.descTextColor}>Choose the discount type (Flat/Percentage) for the offer. </Text>
          </div>
          {(values.isMultipleShopifySyncEnabled && values.priceRuleType === OFFER_PRICE_RULE_TYPE.AMOUNT) && (
            <div className={styles.AlertWrapper}>
              <Alert message="Discount type have been changed to percentage only. Please update the discount. Changes will not be saved till you update the offer." type="warning" />
            </div>
          )}
          <OfferCodeDiscountOptions
            disabled={disabled.priceRuleType}
            name="priceRuleType"
            value={values.priceRuleType}
            isNewFlow={isNewFlow}
            isMultipleShopifyEnabled={values.isMultipleShopifySyncEnabled}
            mode={mode}
          />

          {(isEnabledMultipleShopify && values.isMultipleShopifySyncEnabled) && (
            <MultipleDiscountOptions
              disabled={!!values.lockEditing.includes(OFFER_FORM_LOCKING_KEY.MULTIPLE_SHOPIFY)}
              values={values}
              handleFormAsyncActions={handleFormAsyncActions}
              formRef={formRef}
              connectedAdvertiserForSync={connectedAdvertiserForSync}
            />
          )}

        </Panel>
        <Panel className={`${styles.formSection} ${styles.purchaseTypeContainer}`} key={5} header="Purchase Type">
          <div className={styles.headerSpace}>
            <Text className={styles.descTextColor}>Choose the purchase type for your offer, you can opt for one-time purchases, subscriptions, or a combination of both.</Text>
          </div>

          <OfferPurchaseType disabled={!connectedSubscriptionEnabled} name="purchaseType" />
          {!connectedSubscriptionEnabled && (
            <div className={styles.AlertWrapper}>
              <Alert
                message=""
                showIcon
                description={(
                  <Text>
                    To create subscription-based offer, your connected Shopify store
                    {' '}
                    <strong>{Array.from(connectedStore).join(', ')}</strong>
                    {' '}
                    must have the subscription feature enabled.
                  </Text>
                )}
                type="warning"
                subAction
                action={(
                  <a
                    href="https://help.shopify.com/en/manual/products/purchase-options/subscriptions/setup"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button style={{ background: '#fffbe6' }}>Enable subscription in Shopify</Button>
                  </a>
                )}
              />
            </div>
          )}
          {!values.isMultipleShopifySyncEnabled && (
            <>
              <Divider className={styles.dividerColor} />
              <OfferCodeCollectionOptions
                collections={formOptionData.productCollectionOptions}
                name="productCollections"
                value={values.productCollections}
              />
            </>
          )}
        </Panel>
        <Panel className={styles.formSection} key={6} header="Additional Settings">
          <div className={styles.headerSpace}>
            <Text className={styles.descTextColor}>Manage the usage and access of Promo Codes.</Text>
          </div>
          {(values?.purchaseType === 'Subscription' || values?.purchaseType === 'Both') && (<OfferRecurringPaymentForSubscriptions value={values.offerCodePurchaseRestrictionsRule} disabled={false} name="offerCodePurchaseRestrictionsRule" />)}
          <OfferCodePurchaseRestrictions
            disabled={false}
            name="usageLimitRule"
            value={values.usageLimitRule}

          />
          <OfferCodeLimitOnePerCustomer
            disabled={false}
            name="specialLimitOnePerSale"

          />
          <OfferCodeLimitNewCustomersOnly
            disabled={false}
            name="specialLimitNewCustomersOnly"
          />
          <OfferCodeLimitCodeUses
            disabled={false}
            name="specialLimitUsageCapEnabled"
            value={!!values.specialLimitUsageCapEnabled}

          />
        </Panel>
        <Panel className={styles.formSection} key={6} header="Combination">
          <div className={styles.headerSpace}>
            <Text className={styles.descTextColor}>Promo codes for this offer can be combined with</Text>
          </div>
          <OfferPromoCodeCombinations value={values} handleFormAsyncActions={handleFormAsyncActions} />
        </Panel>
        {
          !migrateToGraphQL && (
            <Panel className={`${styles.formSection} ${styles.offerStatusContainer}`} key={7} header="Offer Status">
              <OldOfferActiveDate
                values={values}
                dateErrorMsg={dateErrorMsg}
                endDate={endDate}
                offerEndDate={offerEndDate}
                setOfferEndDate={setOfferEndDate}
              />
            </Panel>
          )
        }
        <Panel className={`${styles.formSection} ${styles.offerStatusContainer}`} key={9} header="Promo Code Activate Date">
          <NewOfferActiveDate
            values={values}
            dateErrorMsg={dateErrorMsg}
            endDate={endDate}
            offerEndDate={offerEndDate}
            setOfferEndDate={setOfferEndDate}
            handleFormAsyncActions={handleFormAsyncActions}
          />
        </Panel>
      </Collapse>
    </>
  );
};
