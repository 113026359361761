import gql from 'graphql-tag';

export const COMPLETE_REQUIREMENT = gql`
  mutation MarkRequirementCompleteMutation($requirementId: Int!) {
    requirement: completeRequirement(requirementId: $requirementId) {
      id
      completed
    }
  }
`;
