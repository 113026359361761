import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_NOTIFICATION_SETTINGS_QUERY } from '@frontend/app/queries';
import {
  GetNotificationSettingsQuery,
  GetNotificationSettingsQuery_settings,
} from '@frontend/app/queries/types/GetNotificationSettingsQuery';

export type INotificationSetting = GetNotificationSettingsQuery_settings;

const { useEffect } = React;
type IOptions = QueryHookOptions<GetNotificationSettingsQuery>;

export function useGetNotificationSettings(options: IOptions = {}) {
  const {
 loading, data: { settings } = {}, error, refetch,
} = useQuery<GetNotificationSettingsQuery>(
    GET_NOTIFICATION_SETTINGS_QUERY,
    options,
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    settings,
    error,
    refetch,
  };
}
