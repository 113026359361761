import React from 'react';
import { TooltipProps } from 'recharts';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface CustomChartTooltipContentProps extends TooltipProps<any, any> {
  valueType?: 'currency' | 'percent' | 'number'; // valueType is now optional
}

const CustomChartTooltipContent = ({ active, payload, valueType = 'number' }: CustomChartTooltipContentProps) => {
  if (active && payload && payload.length) {
    const { source, value, tooltip } = payload?.[0]?.payload || {};
    const formattedValue = (() => {
      switch (valueType) {
        case 'currency':
          return value?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        case 'percent':
          return `${value}%`;
        case 'number':
        default:
          return value?.toLocaleString();
      }
    })();

    return (
      <div className="bg-white p-2 rounded shadow-md text-sm text-black">
        {(tooltip || source) && <p className="font-semibold">{tooltip || source}</p>}
        <p>{formattedValue}</p>
      </div>
    );
  }

  return null;
};

export default CustomChartTooltipContent;
