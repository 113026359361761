import * as React from 'react';
import cx from 'classnames';
import { isArray, times } from 'lodash';

import { endpoints, ResponsiveGrid } from '@components';
import {
  TotalsSection,
  PostTypeSummarySection,
  TMVSection,
  InsightSection,
  // AudienceAuthSection,
  AudienceLocationSection,
  AudienceDemoSection,
  TopPostsSection,
  TopCreatorsSection,
  ROISection,
  AudienceDemoData,
  AudienceLocationData,
  SummaryData,
  TMVData,
  InsightData,
  TopCreatorsData,
  TopPostsData,
  TotalsData,
} from '@frontend/components/pages/AnalyzePage/SummaryView/sections';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useFetchSocialAccountData } from '@frontend/applications/SocialPostApp/useFetchSocialAccountData';
import { ZeroStateAnalytics } from '@frontend/applications/SocialPostApp/components/ZeroStateAnalytics';
import { ZeroStateAnalyticsInsights } from '@frontend/applications/SocialPostApp/components/ZeroStateAnalyticsInsights';
import { useAnalyzeCompare } from '../useAnalyzeCompare';
import { useAnalyze } from '../useAnalyze';

import styles from './SummaryView.scss';
import { useFetchSummaryData, useFetchSummaryDataCompare } from '@frontend/components/pages/AnalyzePage/useFetchAnalyzeData';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const SummaryView: React.FunctionComponent<IProps> = (props) => {
  const { isComparing, filters: compareFilters } = useAnalyzeCompare();
  const { apiEndpoint, backendServerApiEndpoint, filters, showCostMetrics, insightFeatureFlag } = useAnalyze();
  const { clientId } = useApplication();
  const loadingFeatureFlag = insightFeatureFlag === undefined;
  const { loading: loadingSocialAccount, data: socialAccounts, error: socialAccountError } = useFetchSocialAccountData({
    url: `${backendServerApiEndpoint}/social_account`,
    clientId,
    username: null,
    fetchAll: true,
  });

  const clientNeedsAuth = !loadingSocialAccount && !socialAccountError && (!isArray(socialAccounts) || socialAccounts.length === 0 || !socialAccounts[0].has_social_listening_api_access);

  const TotalsSectionData = useFetchSummaryData<TotalsData>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/totals`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const TotalsSectionDataCompare = useFetchSummaryDataCompare<TotalsData>(
    isComparing,
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/totals`,
    showCostMetrics,
    compareFilters,
    loadingFeatureFlag,
  );

  const PostTypeSummarySectionData = useFetchSummaryData<SummaryData[]>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/post-type`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const PostTypeSummarySectionDataCompare = useFetchSummaryDataCompare<SummaryData[]>(
    isComparing,
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/post-type`,
    showCostMetrics,
    compareFilters,
    loadingFeatureFlag,
  );

  const TMVSectionData = useFetchSummaryData<TMVData[]>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/tmv`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const InsightSectionData = useFetchSummaryData<InsightData[]>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/insight`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const TopPostsSectionData = useFetchSummaryData<TopPostsData[]>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/top-posts`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const TopCreatorsSectionData = useFetchSummaryData<TopCreatorsData[]>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/top-creators`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const AudienceDemoSectionData = useFetchSummaryData<AudienceDemoData>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-demo`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const AudienceDemoSectionDataCompare = useFetchSummaryDataCompare<AudienceDemoData>(
    isComparing,
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-demo`,
    showCostMetrics,
    compareFilters,
    loadingFeatureFlag,
  );

  const AudienceLocationSectionData = useFetchSummaryData<AudienceLocationData>(
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-geo`,
    showCostMetrics,
    filters,
    loadingFeatureFlag,
  );

  const AudienceLocationSectionDataCompare = useFetchSummaryDataCompare<AudienceLocationData>(
    isComparing,
    `${apiEndpoint}/${endpoints.reportsEndpoint}/summary/audience-geo`,
    showCostMetrics,
    compareFilters,
    loadingFeatureFlag,
  );

  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);
  
  const zeroDataReturned = () => {
    if (TotalsSectionData.data?.post_total === 0 && !isComparing) {
      return true;
    } if (TotalsSectionData.data?.post_total === 0 && TotalsSectionDataCompare.data?.post_total === 0 && isComparing) {
      return true;
    };
    return false;
  }

  const sectionHeights = times(isComparing ? 2 : 7, () => 420);

  const loading = TotalsSectionData.loading ||
    InsightSectionData.loading ||
    AudienceLocationSectionData.loading ||
    AudienceDemoSectionData.loading ||
    TopCreatorsSectionData.loading ||
    TopPostsSectionData.loading ||
    TMVSectionData.loading ||
    PostTypeSummarySectionData.loading ||
    loadingFeatureFlag;

  const Grid = isComparing ? (
    <ResponsiveGrid className={styles.grid} heights={sectionHeights} itemMinWidth={600}>
      <AudienceDemoSection
        isComparing={isComparing}
        dataCompare={AudienceDemoSectionDataCompare}
        className={styles.section}
        data={AudienceDemoSectionData}
      />
      {/* <AudienceAuthSection className={styles.section} /> */}
      <AudienceLocationSection
        isComparing={isComparing}
        dataCompare={AudienceLocationSectionDataCompare}
        className={styles.section}
        data={AudienceLocationSectionData}
      />
    </ResponsiveGrid>
  ) : (
    <ResponsiveGrid className={styles.grid} heights={sectionHeights} itemMinWidth={600}>
      <TMVSection
        className={styles.section}
        data={TMVSectionData}
      />
      <InsightSection
        className={styles.section}
        data={InsightSectionData}
      />
      <TopPostsSection
        className={styles.section}
        data={TopPostsSectionData}
      />
      <TopCreatorsSection
        className={styles.section}
        data={TopCreatorsSectionData}
      />
      <AudienceDemoSection
        isComparing={isComparing}
        dataCompare={AudienceDemoSectionDataCompare}
        className={styles.section}
        data={AudienceDemoSectionData}
      />
      <AudienceLocationSection
        isComparing={isComparing}
        dataCompare={AudienceLocationSectionDataCompare}
        className={styles.section}
        data={AudienceLocationSectionData}
      />
      {/* <AudienceAuthSection className={styles.section} /> */}
      {showCostMetrics && <ROISection className={styles.section} />}
    </ResponsiveGrid>
  );

  const getSummaryContents = () => {
    const noData = zeroDataReturned();
    if (!loading && noData) {
      if (clientNeedsAuth) {
        return <ZeroStateAnalyticsInsights />;
      }
      return (
        <div>
          <ZeroStateAnalytics
            accounts={socialAccounts}
            isComparing={isComparing}
            AudienceLocationSectionDataCompare={AudienceLocationSectionDataCompare}
            AudienceDemoDataCompare={AudienceDemoSectionData}
            PostTypeSummarySectionDataCompare={PostTypeSummarySectionDataCompare}
            TotalsSectionDataCompare={TotalsSectionDataCompare}
            TotalsSectionData={TotalsSectionData}
            PostTypeSummarySectionData={PostTypeSummarySectionData}
          />
        </div>
      );
    }

    return (
      <div className={cx(styles.SummaryView, props.className)}>
        <TotalsSection
          isComparing={isComparing}
          dataCompare={TotalsSectionDataCompare}
          className={styles.totalsSection}
          data={TotalsSectionData}
          tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
        />
        <PostTypeSummarySection
          isComparing={isComparing}
          dataCompare={PostTypeSummarySectionDataCompare}
          className={styles.postTypeSummarySection}
          data={PostTypeSummarySectionData}
        />
        {Grid}
      </div>
    );
  };

  return (
    <div className={cx(styles.SummaryView, props.className)}>
      {getSummaryContents()}
    </div>
  );
};

export default SummaryView;
