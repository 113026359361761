import gql from 'graphql-tag';

export const WORK_ITEM_FRAGMENT = gql`
  fragment WorkItemFragment on WorkItem {
    id
    specURI
    taskId
    data
    dataUpdated
    enablementTime
    startTime
    firingTime
    viewed
  }
`;
