export enum WorkflowTask {
  GENERATE_LINK_TASK = 'generate_link_task',
  GENERATING_LINK_TASK = 'generating_link_task',
  SEND_LINK_TASK = 'send_link_task',
  GENERATE_PROMO_TASK = 'generate_promo_task',
  GENERATING_PROMO_TASK = 'generating_promo_task',
  FIX_ERROR_PROMO_TASK = 'fix_error_promo_task',
  SEND_PROMO_TASK = 'send_promo_task',
  SEND_LINK_AND_PROMO_TASK = 'send_link_and_promo_task',
  SEND_TERMS_TASK = 'send_terms_task',
}

export type LinkTask =
  | WorkflowTask.GENERATE_LINK_TASK
  | WorkflowTask.GENERATING_LINK_TASK
  | WorkflowTask.SEND_LINK_TASK;

export const isLinkTask = (input: string): input is LinkTask => {
  switch (input) {
    case WorkflowTask.GENERATE_LINK_TASK:
    case WorkflowTask.GENERATING_LINK_TASK:
    case WorkflowTask.SEND_LINK_TASK:
      return true;
    default:
      return false;
  }
};

export type PromoTask =
  | WorkflowTask.GENERATE_PROMO_TASK
  | WorkflowTask.GENERATING_PROMO_TASK
  | WorkflowTask.FIX_ERROR_PROMO_TASK
  | WorkflowTask.SEND_PROMO_TASK;

export const isPromoTask = (input: string): input is PromoTask => {
  switch (input) {
    case WorkflowTask.GENERATE_PROMO_TASK:
    case WorkflowTask.GENERATING_PROMO_TASK:
    case WorkflowTask.FIX_ERROR_PROMO_TASK:
    case WorkflowTask.SEND_PROMO_TASK:
      return true;
    default:
      return false;
  }
};

export type SendTask =
  | WorkflowTask.SEND_LINK_TASK
  | WorkflowTask.SEND_PROMO_TASK
  | WorkflowTask.SEND_LINK_AND_PROMO_TASK;

export const isSendTask = (input: string): input is SendTask => {
  switch (input) {
    case WorkflowTask.SEND_LINK_TASK:
    case WorkflowTask.SEND_PROMO_TASK:
    case WorkflowTask.SEND_LINK_AND_PROMO_TASK:
      return true;
    default:
      return false;
  }
};

export type SetupOfferTask =
  | WorkflowTask.GENERATE_LINK_TASK
  | WorkflowTask.GENERATE_PROMO_TASK;

export const isSetupOfferTask = (input: string): input is SetupOfferTask => {
  switch (input) {
    case WorkflowTask.GENERATE_LINK_TASK:
    case WorkflowTask.GENERATE_PROMO_TASK:
      return true;
    default:
      return false;
  }
};
