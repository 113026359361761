import gql from 'graphql-tag';

export const GET_ALL_COMPLETED_MEMBERS_QUERY = gql`
  query GetAllCompletedMembersQuery($specKey: String!) {
    members: getAllCompletedMembers(specKey: $specKey) {
      data
      dateCompleted
    }
  }
`;
