/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10g2g_157 {
  display: flex;
}

._justify-content-space-between_10g2g_161 {
  justify-content: space-between;
}

._tabular-nums_10g2g_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10g2g_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Pagination_10g2g_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._Pagination_10g2g_178 {
  min-height: 60px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
}
._Pagination_10g2g_178 ._title_10g2g_195 {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
}
._Pagination_10g2g_178 ._emptySpacer_10g2g_201 {
  flex: 1;
}
._Pagination_10g2g_178 ._item_10g2g_204 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #eff5f9;
  vertical-align: middle;
  cursor: pointer;
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 6px;
  color: black;
}
._Pagination_10g2g_178 ._item_10g2g_204._selected_10g2g_218 {
  background-color: #3996e0;
  color: white;
}
._Pagination_10g2g_178 ._item_10g2g_204:not(:first-child) {
  margin-left: 4px;
}
._Pagination_10g2g_178 ._item_10g2g_204:not(:last-child) {
  margin-right: 4px;
}
._Pagination_10g2g_178 ._rightArrow_10g2g_228 {
  transform: rotateZ(180deg);
}