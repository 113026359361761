import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import {
  Alert,
  Button,
  Col,
  Divider,
  Empty,
  Row,
  Typography,
} from '@revfluence/fresh';
import { CommentsIcon } from '@revfluence/fresh-icons/regular/esm';

import { TContentComment, TContentComments } from '@frontend/app/types/GroupContentReview';
import { toolbarModules, toolbarOptions } from '@frontend/app/utils/richTextEditormodules';
import { TrashIcon } from '@revfluence/fresh-icons/regular';
import styles from './Comments.module.scss';
import { Icon } from './Icon';
import { RichTextEditor } from '../RichTextEditor';

const {
  useRef, useState, useCallback, useEffect,
} = React;

export const Comments = ({
  title, comments, creatingComment, onCreateComment, isLatestVersion,
}: TContentComments) => {
  const ref = useRef<HTMLDivElement>(null);
  const [comment, setComment] = useState('');
  const [resetKey, setResetKey] = useState(0);

  const handleReset = () => {
    setResetKey((prevKey) => prevKey + 1);
  };

  const onCommentChange = useCallback((event) => setComment(event), []);
  const onReply = useCallback(async () => {
    if (isEmpty(comment)) {
      return;
    }

    await onCreateComment(comment);
    setComment('');
    handleReset();
  }, [comment, onCreateComment]);

  useEffect(
    () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
      }),
    [comments],
  );

  return (
    <div className={styles.Comments}>
      <Row justify="center" align="middle">
        <Divider className={styles.divider}>
          <Typography.Text type="secondary">{title}</Typography.Text>
        </Divider>
      </Row>
      <div className={styles.comments}>
        {comments.length > 0 ? (
          comments.map(
            ({
              id,
              user,
              icon,
              date,
              title,
              content,
              status,
              guidelines,
            }: TContentComment) => (
              <Alert
                key={id}
                className={cx(styles.comment, {
                  [styles.default]: status === 'default',
                })}
                message={(
                  <Typography.Text
                    type="secondary"
                    style={{ fontWeight: 'normal' }}
                  >
                    {user}
                  </Typography.Text>
                )}
                description={(
                  <>
                    {title && (
                      <Typography.Title
                        level={5}
                        style={{ fontSize: '14px' }}
                      >
                        {title}
                      </Typography.Title>
                    )}

                    {!isEmpty(guidelines) && (
                      <>
                        <Typography.Title
                          level={5}
                          style={{ marginBottom: 0, fontSize: '14px' }}
                        >
                          Content Guidelines
                        </Typography.Title>
                        <div className={styles.guidelines}>
                          {guidelines.map((text, index) => (
                            <Typography.Text key={index}>
                              •
                              {' '}
                              {text}
                            </Typography.Text>
                          ))}
                        </div>
                      </>
                    )}

                    {content && status !== 'default' && (
                      <Typography.Title
                        level={5}
                        style={{ marginBottom: 0, fontSize: '14px' }}
                      >
                        Comment
                      </Typography.Title>
                    )}
                    {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
                    <footer>
                      <Typography.Text type="secondary">
                        {date}
                      </Typography.Text>
                    </footer>
                  </>
                )}
                icon={<Icon user={user} status={status} icon={icon} />}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                type={status}
              />
            ),
          )
        ) : (
          <Empty
            image={<CommentsIcon style={{ color: '#F0F0F0' }} />}
            description={(
              <>
                <Typography.Title level={5}>
                  There are no comments at this time
                </Typography.Title>
                <Typography.Text type="secondary">
                  Start sharing feedback by commenting.
                </Typography.Text>
              </>
            )}
          />
        )}
        <div ref={ref} />
      </div>
      <Row className={styles.reply} gutter={8} style={{ marginTop: '24px' }}>
        <Col flex={1} span={23}>
          <RichTextEditor
            toolbarOptions={toolbarOptions}
            toolbarModules={toolbarModules}
            boundSelector="#commentEditor"
            placeholder="Enter your message..."
            value={comment}
            onChange={onCommentChange}
            className={styles.commentEditor}
            key={resetKey}

          />
        </Col>
        <Col style={{ gap: 8 }}>
          <Row gutter={8} className={styles.actionButton}>
            <Button
              onClick={() => {
                setComment('');
                handleReset();
              }}
              disabled={isEmpty(comment)}
              loading={creatingComment}
              icon={<TrashIcon />}
            />
            <Button
              onClick={onReply}
              disabled={isEmpty(comment) || !isLatestVersion}
              loading={creatingComment}
            >
              Reply
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
