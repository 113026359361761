/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TimePicker_1ckzq_32 {
  width: 185px;
}
._TimePicker_1ckzq_32 ._icon_1ckzq_35 {
  margin-right: 5px;
}
._TimePicker_1ckzq_32 ._timeInput_1ckzq_38 {
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  cursor: pointer;
  user-select: none;
  line-height: 17px;
  color: gray;
  border-radius: 100px;
  background-color: #eff5f9;
  will-change: background-color;
  transition: background-color 0.1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._TimePicker_1ckzq_32 ._timeInput_1ckzq_38._timeSelected_1ckzq_53 {
  color: #1a1818;
}
._TimePicker_1ckzq_32 ._timeInput_1ckzq_38:hover {
  color: #4fa7ee;
  background-color: #e6edf2;
}
._TimePicker_1ckzq_32 ._timeInput_1ckzq_38:active {
  color: #3996e0;
  background-color: #eff5f9;
}
._TimePicker_1ckzq_32 ._timeInput_1ckzq_38 ._icon_1ckzq_35 {
  margin-right: 5px;
}
._TimePicker_1ckzq_32 ._timeInput_1ckzq_38 ._close_1ckzq_67 {
  margin-left: auto;
}
._TimePicker_1ckzq_32 ._timeInput_1ckzq_38._hasError_1ckzq_70 {
  border: 1px solid #F96B77 !important;
  background-color: #f6e4e5 !important;
}
._TimePicker_1ckzq_32 ._errorMessage_1ckzq_74 {
  margin-top: 7px;
  color: #f1515f;
  font-size: 13px;
}