import { TUseGetSocialAccountSettingsQuery } from '@frontend/app/hooks';
import { BrandedContentStatus, IGDMStatus, SocialAccountSettingsStatus } from '@frontend/app/types/globalTypes';

type SocialAccount = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0];

export const canReconnect = (account: SocialAccount): boolean => {
  if (
    account.token_debug.status !== SocialAccountSettingsStatus.ACTIVE
    || account.branded_content_ads_debug.enabled && account.branded_content_ads_debug.status !== BrandedContentStatus.ACTIVE
    || account.igdm_debug.enabled && account.igdm_debug.status !== IGDMStatus.ACTIVE
    || account.social_listening_debug.enabled && account.social_listening_debug.status !== SocialAccountSettingsStatus.ACTIVE
  ) {
    return true;
  }
  return false;
};
