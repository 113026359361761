import * as React from 'react';
import {
  Checkbox,
  Typography,
  Form,
  Space,
  ValidateStatus,
} from '@revfluence/fresh';
import { Input } from 'antd';
import { PaymentTypes } from '../../types/CollaborationDetails';

const { TextArea } = Input;
const { Text } = Typography;
const {
  useCallback,
  useState,
  useRef,
} = React;

interface IProps {
  type: PaymentTypes;
  description?: string;
  showHideDescription: boolean;
  onShowHideDescription?: (paymentType: PaymentTypes, showHideDescription: boolean) => void;
  onUpdateDescription?: (paymentType: PaymentTypes, description: string) => void;
}

interface IState {
  validateStatus?: ValidateStatus;
  required?: boolean;
  help?: string;
}

const PaymentTypeLabels = {
  [PaymentTypes.Commission]: 'Commission',
  [PaymentTypes.FreeProduct]: 'Free product',
  [PaymentTypes.NoCompensation]: 'No Compensation',
};

const PaymentOption: React.FC<IProps> = React.memo((props) => {
  const {
    type,
    showHideDescription,
    description,
    onShowHideDescription,
    onUpdateDescription,
  } = props;

  const [formItemLayout, setFormItemLayout] = useState<IState>({});
  const descriptionRef = useRef<HTMLInputElement>(null);

  const handleShowHideDescriptionChange = useCallback((e) => {
    onShowHideDescription(type, e.target.checked);
    setTimeout(() => {
      if (e.target.checked) {
        descriptionRef?.current?.focus();
      }
    }, 500);
  }, [onShowHideDescription, type]);

  const handleDescriptionChange = useCallback((e) => {
    onUpdateDescription(type, e.target.value);
    setFormItemLayout(
      (prevFormItemLayout) => (description !== '' ? {} : prevFormItemLayout),
    );
  }, [onUpdateDescription, type, description]);

  const handleOnBlur = useCallback(() => {
    if (description === '') {
      setFormItemLayout({
        validateStatus: 'error',
        required: true,
        help: `${PaymentTypeLabels[type]} amount is required to continue.`,
      });
    }
  }, [description, type]);

  return (
    <Space direction="vertical">
      <Checkbox
        onChange={handleShowHideDescriptionChange}
        checked={showHideDescription}
      >
        <Text strong>
          { PaymentTypeLabels[type] }
        </Text>
      </Checkbox>
      {showHideDescription && type !== PaymentTypes.NoCompensation && (
        <Form layout="vertical">
          <Form.Item
            {...formItemLayout}
            label={type === PaymentTypes.FreeProduct
            ? 'Describe the product you will gift.'
            : 'Describe your commission program.'}
          >
            <TextArea
              ref={descriptionRef}
              rows={4}
              value={description}
              onChange={handleDescriptionChange}
              onBlur={handleOnBlur}
              maxLength={1000}
              showCount
            />
          </Form.Item>
        </Form>
      )}
    </Space>
  );
});

PaymentOption.displayName = 'PaymentOption';

export default PaymentOption;
