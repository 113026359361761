import { isEmpty, map } from 'lodash';

import { IContentReview, TMediaType } from '@components';

export enum CONTENT_SOURCE {
  GCS = 'GCS',
  INSTAGRAM_EMBED = 'INSTAGRAM_EMBED',
  YOUTUBE_EMBED = 'YOUTUBE_EMBED',
  TIKTOK_EMBED = 'TIKTOK_EMBED',
  PINTEREST_EMBED = 'PINTEREST_EMBED',
}

export interface IContent {
  type: TMediaType;
  url: string;
  source?: CONTENT_SOURCE
  videoUrl?: string;
  // For Instagram Reels, Tiktok Video, Youtube Videos and other Videos
  image?: string;
}

const YOUTUBE_REGEX = /^.*(youtu\.be\/|v\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;

export function getYouTubeVideoId(url: string): string | null {
  const match = url.match(YOUTUBE_REGEX);
  return match && match[2].length === 11 ? match[2] : null;
}

export function getContentReviewMedia(review: IContentReview): IContent[] {
  const { content, post } = review;

  let mediaList = [];

  if (review?.live_upload_link?.includes('instagram.com') || review?.content?.link?.includes('instagram.com')) {
    mediaList.push({
      url: review?.live_upload_link?.includes('instagram') ? review.live_upload_link : review?.content?.link,
      source: CONTENT_SOURCE.INSTAGRAM_EMBED,
    } as IContent);

    return mediaList;
  }

  if (review?.live_upload_link?.includes('pinterest.com') || review?.content?.link?.includes('pinterest.com')) {
    mediaList.push({
      url: review?.live_upload_link?.includes('pinterest') ? review.live_upload_link : review?.content?.link,
      source: CONTENT_SOURCE.PINTEREST_EMBED,
    } as IContent);

    return mediaList;
  }

  if (content?.content_class === 'YoutubeVideoContent' && (getYouTubeVideoId(content?.link) || getYouTubeVideoId(post?.link))) {
    mediaList.push({
      url: post?.link || content?.link,
      source: CONTENT_SOURCE.YOUTUBE_EMBED,
      image: review?.post?.image || review?.post?.images?.maxres || review?.post?.media?.filter((mediaItem) => mediaItem.media_type === 'image')?.[0],
    } as IContent);
    return mediaList;
  }

  if (content?.content_class === 'TiktokContent' && post?.link?.includes('tiktok.com')) {
    mediaList.push({
      url: post?.link,
      source: CONTENT_SOURCE.TIKTOK_EMBED,
      image: review?.post?.image || review?.post?.images?.maxres,
    } as IContent);
    return mediaList;
  }

  if (!isEmpty(post?.media)) {
    mediaList = post.media;
  } else {
    mediaList = !isEmpty(content?.saved_media_list) ? content?.saved_media_list : content?.media;
  }

  if (mediaList?.length > 1) {
    const videoMedia = mediaList?.find((media) => media.media_type === 'video');

    mediaList = mediaList?.filter((media) => {
      if (media.media_type === 'video') return true;

      else if ('category' in media && !videoMedia) {
        return media.category === 'downloadable_media';
      } else if ('category' in media && videoMedia) {
        return media.category === 'preview_media' || media.category === 'downloadable_media';
      }

      return true;
    });

    if (videoMedia) {
      const imageMedia = mediaList?.find(
        (media) =>
          media.resolution === 'maxres'
          || media.resolution === 'aiq_preview_resolution',
      );

      const mediaListToShow = mediaList.filter((media) => media.category === 'downloadable_media');

      return map(mediaListToShow, (media) => ({
        url: media.url,
        type: media.media_type,
        videoUrl: media.url,
        image: imageMedia?.url ?? null,
      }) as IContent);
    }
  }

  const contents = map(mediaList,
    (m) => ({
      type: m.media_type || m.type,
      url: m.url,
      source: CONTENT_SOURCE.GCS,
      videoUrl: m.videoUrl,
      image: m.image,
    } as IContent));

  if (isEmpty(contents) && !!content?.image_link) {
    contents.push({
      type: 'image',
      url: content.image_link,
      source: CONTENT_SOURCE.GCS,
    });
  }

  return contents;
}
