import React from 'react';
import { Drawer } from '@revfluence/fresh';
import ConditionForm from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/ConditionForm';
import DelayForm from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/DelayForm';
import ActionForm from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/ActionForm';
import {
  TAutomationActionOption,
  MessageTemplate,
  MessageTemplateInput,
} from '@frontend/app/hooks';
import {
  TActionNode,
  TDelayNode,
  TConditionNode,
  TSelectedNode,
} from '@frontend/app/containers/Projects/AutomationConfig/types';
import { AutomationNodeType } from '@frontend/app/types/globalTypes';
import { IAutomationVariable } from '@frontend/app/types/automations/condition/AutomationVariable';
import { GetNodeOptions_getNodeOptions_conditionOptions } from '@frontend/app/queries/types/GetNodeOptions';

type TProps = {
  visible: boolean;
  onClose: () => void;
  onAddConditionNode: (targetNodeId: string, conditionParentNodeUUID: string, newNode: TConditionNode) => void;
  onRmoveConditionNode: (targetNodeId: string, conditionParentNodeUUID: string, conditionNodeId: string) => void;
  onUpdateConditionNode: (targetNodeId: string, conditionNodeId: string, updatedConditionNode: TConditionNode) => void;
  onUpdateDelayNode: (targetNodeId: string, delayNode: TDelayNode) => void;
  onUpdateActionNode: (targetNodeId: string, actionNode: TActionNode) => void;
  onUpdateMessageTemplate: (updatedTemplate: MessageTemplate | MessageTemplateInput) => void;
  conditionOptions: GetNodeOptions_getNodeOptions_conditionOptions[];
  actionOptions: TAutomationActionOption[];
  automationVariables: IAutomationVariable[];
  selectedNode: TSelectedNode,
  resetMessageTemplate: boolean;
};

const ConfigDrawer = (props: TProps) => {
  const {
    visible,
    onClose,
    onAddConditionNode,
    onRmoveConditionNode,
    onUpdateConditionNode,
    onUpdateDelayNode,
    onUpdateActionNode,
    onUpdateMessageTemplate,
    conditionOptions,
    actionOptions,
    automationVariables,
    selectedNode,
    resetMessageTemplate,
  } = props;

  return (
    <Drawer
      placement="right"
      width="400px"
      visible={visible}
      onClose={onClose}
      closable={false}
      maskClosable={false}
      mask={false}
    >
      {selectedNode && selectedNode.nodeType === AutomationNodeType.DELAY && (
        <DelayForm
          onUpdateDelayNode={onUpdateDelayNode}
          onClose={onClose}
          selectedNode={selectedNode}
        />
      )}
      {selectedNode && selectedNode.nodeType === AutomationNodeType.ACTION && (
        <ActionForm
          onUpdateActionNode={onUpdateActionNode}
          onUpdateMessageTemplate={onUpdateMessageTemplate}
          onClose={onClose}
          actionOptions={actionOptions}
          selectedNode={selectedNode}
          resetMessageTemplate={resetMessageTemplate}
        />
      )}
      {selectedNode && selectedNode.nodeType === AutomationNodeType.CONDITION && (
        <ConditionForm
          onClose={onClose}
          onAddConditionNode={onAddConditionNode}
          onRmoveConditionNode={onRmoveConditionNode}
          onUpdateConditionNode={onUpdateConditionNode}
          conditionOptions={conditionOptions}
          automationVariables={automationVariables}
          selectedNode={selectedNode}
        />
      )}
    </Drawer>
  );
};

export default ConfigDrawer;
