import * as React from 'react';
import cx from 'classnames';
import { ArrowDownFilledIcon } from '@components';

import styles from './FolderMenu.scss';

const { useState } = React;

interface IProps {
  title: React.ReactNode;
  defaultExpanded?: boolean;
  expanded?: boolean;
  onChangeExpanded?(expanded: boolean);
  className?: string;
  toggleClassName?: string;
}

/**
 * Old "Folder" component -- renamed to "FolderMenu"
 */
export const FolderMenu: React.FunctionComponent<IProps> = (props) => {
  const [expandedState, setExpandedState] = useState<boolean>(props.defaultExpanded);

  const isControlled = props.expanded !== undefined;
  const expanded = isControlled ? props.expanded : expandedState;

  const handleToggleExpanded = () => {
    if (isControlled) {
      if (props.onChangeExpanded) {
        props.onChangeExpanded(!expanded);
      }
    } else {
      setExpandedState((s) => !s);
    }
  };

  return (
    <div className={cx(styles.FolderMenu, { [styles.expanded]: expanded }, props.className)}>
      <div className={cx(styles.header, props.toggleClassName)} onClick={handleToggleExpanded}>
        <ArrowDownFilledIcon size={10} className={styles.arrow} />
        <span className={styles.title}>{props.title}</span>
      </div>
      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
};

FolderMenu.defaultProps = {
  defaultExpanded: false,
};
