import * as React from 'react';
import { Form } from 'antd';

import { IPlaceId } from '@services/backend-server';
import LocationField from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/MarketplaceTabs/TabField/LocationField';

interface IProps {
  label: string;
  name: string;
  value: IPlaceId[];
  extraMsg?: string;
  placeholder?: string;
  onFieldChange: (name: string, value: IPlaceId[]) => void;
}

const FormLocationField: React.FC<IProps> = ({
  label,
  name,
  value,
  extraMsg = '',
  placeholder = '',
  onFieldChange,
}: IProps) => {
  const handleChange = (value) => {
    onFieldChange(name, value);
  };

  return (
    <Form.Item label={label} extra={extraMsg}>
      <LocationField
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

FormLocationField.displayName = 'FormLocationField';

export default React.memo(FormLocationField);
