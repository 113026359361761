import * as qs from 'qs';
import { first, isEmpty } from 'lodash';

import { endpoints } from '@components';
import { useGet } from '@frontend/utils';
import { ISocialAccount } from '@components';

export enum DetailedSocialAccountCaller {
  CREATOR_SEARCH = 'creator_search',
  SOCIAL_PROFILE = 'social_profile',
}

interface IProps {
  apiEndpoint: string;
  id: ISocialAccount['id'];
  brandInstagramUsername?: string;
  clientId?: string;
  caller?: DetailedSocialAccountCaller;
}

/**
 * Fetch detailed social account
 */
export const useDetailedSocialAccount = (props: IProps) => {
  const {
    apiEndpoint,
    id,
    brandInstagramUsername,
    clientId,
    caller,
  } = props;

  const url = apiEndpoint && id
    ? (
      `${apiEndpoint}/${endpoints.socialAccountEndpoint}${
      qs.stringify({
        social_account_id: id,
        brand_instagram_username: brandInstagramUsername || undefined,
        client_id: clientId,
        include_social_profile_data: true,
        caller,
      }, { addQueryPrefix: true })}`
    )
    : null;

  const {
    loading: isFetching,
    data,
    error,
  } = useGet<{ data: ISocialAccount[] }>({ url });
  const detailedSocialAccount = !isEmpty(data)
    ? Object.freeze(first(data.data))
    : null;

  return {
    detailedSocialAccount,
    isFetching,
    error,
  };
};
