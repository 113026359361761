import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/client';

import { MAKE_RESOURCE_SHARED_MUTATION } from '@frontend/app/queries';
import {
  MakeResourceSharedMutation,
  MakeResourceSharedMutationVariables,
} from '@frontend/app/queries/types/MakeResourceSharedMutation';
import { useMessagingContext } from '@frontend/hooks';

export type IMakeResourceSharedOptions = MutationHookOptions<
  MakeResourceSharedMutation,
  MakeResourceSharedMutationVariables
>;

const { useEffect } = React;

export function useMakeResourceShared(options: IMakeResourceSharedOptions = {}) {
  const { showInfoMessage, showErrorMessage } = useMessagingContext();
  const [makeResourceShared, { loading, error }] = useMutation<
    MakeResourceSharedMutation,
    MakeResourceSharedMutationVariables
  >(MAKE_RESOURCE_SHARED_MUTATION, {
    ...options,
    onCompleted() {
      showInfoMessage('The email is shared.');
    },
  });

  useEffect(() => {
    if (error) {
      showErrorMessage(error.message);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    makeResourceShared,
    loading,
    error,
  };
}
