import * as React from 'react';
import {
 Space, Typography, Input,
} from '@revfluence/fresh';
import styles from './CatalogueConfigs.scss';

const { Title, Text } = Typography;
const { TextArea } = Input;
interface IProps {
    instructionText: string;
    onInstructionTextChange: (string: string) => void;
}

export const CatalogueConfig = ({
    instructionText, onInstructionTextChange,
}: IProps) => (
  <div className={styles.CatalogueConfig}>
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Space direction="vertical" size={0} style={{ width: '100%' }}>
        <Title level={5}>Additional Settings</Title>
        <Text strong>
          Banner Information
          <Text type="secondary"> (optional)</Text>
        </Text>
        <TextArea
          showCount
          maxLength={200}
          onChange={(e) => onInstructionTextChange(e.target.value)}
          placeholder="Provide instructions for creators to follow when selecting their product."
          value={instructionText}
        />
      </Space>
    </Space>
  </div>
);
