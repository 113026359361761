import * as React from 'react';
import {
  Row, Col, Typography, Button, Space, Input,
} from '@revfluence/fresh';
import { XmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import { StarIcon } from '@revfluence/fresh-icons/regular/esm';
import { StarIcon as StarIconFilled } from '@revfluence/fresh-icons/solid/esm';
import { OwnerSelect } from '@frontend/app/components';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { useMemberNames } from './hooks/useMemberNames';
import styles from './NewAddMemberHeader.scss';

const { Title, Text } = Typography;

export interface NewAddMemberHeaderProps {
  member: MemberInput;
  ownerIds?: string[];
  isImportant?: boolean;
  onUpdateMember?(member: MemberInput);
  onChangeOwners?(ownerIds: string[]);
  onChangeIsImportant?(isImportant: boolean);
  onClose?: () => void;
}

export const NewAddMemberHeader = React.memo((props: NewAddMemberHeaderProps) => {
  const {
    values: { firstName, lastName },
    schemas: { firstNameSchema, lastNameSchema },
  } = useMemberNames(props.member);

  const handleUpdateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const fullName = name === 'firstName' ? `${value} ${lastName}` : `${firstName} ${value}`;
    const field = name === 'firstName' ? firstNameSchema.id : lastNameSchema.id;

    if (props.onUpdateMember) {
      if (!firstNameSchema?.id || !lastNameSchema?.id) return;

      props.onUpdateMember({
        ...props.member,
        name: fullName,
        fields: {
          ...props.member.fields,
          [field]: value,
        },
      });
    }
  };

  return (
    <div className={styles.header}>
      <Row className={styles.row} gutter={8}>
        <Col flex="auto">
          <Title level={5} style={{ margin: 0 }}>
            Add Member
          </Title>
        </Col>
        {props.onClose && (
          <Button shape="circle" className={styles.closeBtn} onClick={props.onClose}>
            <XmarkIcon />
          </Button>
        )}
      </Row>

      <Space className={styles.row}>
        <Text className={styles.label}>First Name:</Text>
        <Input
          type="text"
          value={firstName}
          name="firstName"
          placeholder="Enter name..."
          onChange={handleUpdateName}
        />
      </Space>

      <Space className={styles.row}>
        <Text className={styles.label}>Last Name:</Text>
        <Input
          type="text"
          value={lastName}
          name="lastName"
          placeholder="Enter name..."
          onChange={handleUpdateName}
        />
      </Space>

      <div className={styles.row}>
        <OwnerSelect ownerIds={props.ownerIds} onChangeOwners={props.onChangeOwners} />
      </div>

      <div className={styles.row}>
        <Button
          icon={props.isImportant ? <StarIconFilled /> : <StarIcon />}
          onClick={() => props.onChangeIsImportant?.(!props.isImportant)}
        >
          Important
        </Button>
      </div>
    </div>
  );
});

NewAddMemberHeader.displayName = 'NewAddMemberHeader';
