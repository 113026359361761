import React from 'react';
import { NODE_EDGE_COLOR } from '@frontend/app/containers/Projects/AutomationConfig/constants';
import { AutomationNodeData, TConditionNode } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { useGetNodeOptionsQuery } from '@frontend/app/hooks';
import {
  ArrayConditionOperators,
  BooleanConditionOperators,

  DateConditionOperators,
  NumberConditionOperators,
  TextConditionOperators,
} from '@frontend/app/types/automations/condition/ConditionOperator';

import {
  ComboOperator,
  ComparisonOperator,
} from '@frontend/app/types/automations/condition/Operator';

import {
  ConditionType,
} from '@frontend/app/types/automations/condition/Condition';
import { FieldType } from '@frontend/app/types/globalTypes';
import { Button, Tag } from '@revfluence/fresh';
import { CheckIcon, LockIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { upperFirst } from 'lodash';
import moment from 'moment';
import { Handle, Position } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import styles from './AutomationNode.scss';

const ConditionNode = (props: { data: AutomationNodeData }) => {
  const {
    data: {
      isEditing,
      metadata,
      nodeType,
      nodeId,
      automationId,
      templateId,
      onNodeItemClick,
    },
  } = props;

  const handleBackground = isEditing ? NODE_EDGE_COLOR.EDITING : NODE_EDGE_COLOR.PRIMARY;

  const {
    data: {
      getNodeOptions: {
        conditionOptions,
      },
    } = { getNodeOptions: { conditionOptions: [] } },
  } = useGetNodeOptionsQuery({
    variables: {
      context: {
        automationId,
        templateId,
      },
    },
    skip: !automationId && !templateId,
  });

  const onConditionClick = (condition: TConditionNode) => {
    if (!isEditing) return;
    onNodeItemClick({
      nodeType,
      targetNodeId: nodeId,
      conditionNode: condition,
    });
  };

  const onAddCondition = () => {
    if (!isEditing) return;
    onNodeItemClick({
      nodeType,
      targetNodeId: nodeId,
      conditionNode: {
        attribute: null,
        locked: false,
        mappedOperator: null,
        op: null,
        variableId: null,
        value: null,
        parentId: metadata.uuid,
        uuid: uuidv4(),
        type: ConditionType.COMPARISON,
      },
    });
  };

  // TODO: Verify that all these types are correct and supported by the backend
  const renderConditionTag = (condition: TConditionNode): string => {
    const operator = condition.mappedOperator;
    const field = conditionOptions.find((field) => field.id === condition.attribute);
    if (!field) return '';
    const fieldType = field.attributeType;
    const displayName = upperFirst(field.displayName);
    const value = condition.value;

    const getDisplayOption = () => {
      switch (fieldType) {
        case FieldType.ANNUAL:
        case FieldType.DATE:
          return DateConditionOperators.find((op) => op.value === operator);
        case FieldType.ARRAY:
          return ArrayConditionOperators.find((op) => op.value === operator);
        case FieldType.BOOLEAN:
          return BooleanConditionOperators.find((op) => op.value === operator);
        case FieldType.NUMBER:
        case FieldType.PERCENTAGE:
          return NumberConditionOperators.find((op) => op.value === operator);
        case FieldType.TEXT:
        case FieldType.EMAIL:
          return TextConditionOperators.find((op) => op.value === operator);
        default:
          return null;
      }
    };
    const displayOption = getDisplayOption();
    const inlineText = displayOption?.inlineLabel || '';
    const secondaryInlineText = displayOption?.secondaryInlineLabel || '';
    if (
      operator === ComboOperator.BETWEEN
      && (
        !Array.isArray(value)
        || value.length !== 2
      )
    ) {
      throw new Error('Invalid value for between operator');
    }

    switch (fieldType) {
      case FieldType.ANNUAL:
      case FieldType.DATE:
        const dateFormat = fieldType === FieldType.DATE ? 'MM/DD/YYYY' : 'MMM D';
        const valueAsDate = moment(value as Date).utc().format(dateFormat);
        switch (operator) {
          case ComparisonOperator.EQUALS:
          case ComboOperator.NOT_EQUALS:
          case ComparisonOperator.GREATER_THAN:
          case ComparisonOperator.GREATER_THAN_EQUALS:
          case ComparisonOperator.LESS_THAN:
          case ComparisonOperator.LESS_THAN_EQUALS:
            return `${displayName} ${inlineText} ${valueAsDate} ${secondaryInlineText}`;
          case ComboOperator.BETWEEN:
            return `${displayName} ${inlineText} ${moment(value[0]).utc().format(dateFormat)} ${secondaryInlineText} ${moment(value[1]).utc().format(dateFormat)}`;
          case ComparisonOperator.HAS_VALUE:
          case ComboOperator.IS_NULL:
            return `${displayName} ${inlineText}`;
          case ComparisonOperator.MORE_THAN_AGO:
          case ComboOperator.NOT_MORE_THAN_AGO:
          case ComparisonOperator.IN_THE_LAST:
          case ComboOperator.NOT_IN_THE_LAST:
          case ComboOperator.NOT_IN_THE_NEXT:
          case ComparisonOperator.IN_THE_NEXT:
            return `${displayName} ${inlineText} ${value} ${secondaryInlineText}`;
          default:
            throw new Error('Invalid comparison operator for date attribute');
        }
      case FieldType.ARRAY:
        switch (operator) {
          case ComparisonOperator.EQUALS:
          case ComboOperator.NOT_EQUALS:
          case ComparisonOperator.CONTAINS:
          case ComboOperator.NOT_CONTAINS:
            if (!Array.isArray(value)) {
              throw new Error('Invalid value for array attribute');
            }
            const formattedValues = value.map((val) => field.choices.find((c) => c.value === val)?.displayName || val).join(', ');
            return `${displayName} ${inlineText} ${formattedValues} ${secondaryInlineText}`;
          case ComboOperator.IS_NULL:
          case ComparisonOperator.HAS_VALUE:
            return `${displayName} ${inlineText}`;
          default:
            throw new Error('Invalid comparison operator for array attribute');
        }
      case FieldType.BOOLEAN:
        const boolValue = value ? 'True' : 'False';
        switch (operator) {
          case ComparisonOperator.EQUALS:
          case ComboOperator.NOT_EQUALS:
            return `${displayName} ${inlineText} ${boolValue} ${secondaryInlineText}`;
          case ComparisonOperator.HAS_VALUE:
          case ComboOperator.IS_NULL:
            return `${displayName} ${inlineText}`;
          default:
            throw new Error('Invalid comparison operator for boolean attribute');
        }
      case FieldType.NUMBER:
      case FieldType.PERCENTAGE:
        switch (operator) {
          case ComparisonOperator.EQUALS:
          case ComparisonOperator.GREATER_THAN:
          case ComparisonOperator.GREATER_THAN_EQUALS:
          case ComparisonOperator.LESS_THAN:
          case ComparisonOperator.LESS_THAN_EQUALS:
          case ComboOperator.NOT_EQUALS:
            return `${displayName} ${inlineText} ${value}`;
          case ComboOperator.BETWEEN:
            return `${displayName} ${inlineText} ${value[0]} ${secondaryInlineText} ${value[1]}`;
          case ComboOperator.IS_NULL:
          case ComparisonOperator.HAS_VALUE:
            return `${displayName} ${inlineText}`;
          default:
            throw new Error('Invalid comparison operator for number attribute');
        }
      case FieldType.TEXT:
      case FieldType.EMAIL:
        switch (operator) {
          case ComparisonOperator.EQUALS:
          case ComparisonOperator.CONTAINS:
          case ComboOperator.NOT_CONTAINS:
          case ComboOperator.NOT_EQUALS:
            return `${displayName} ${inlineText} ${value} ${secondaryInlineText}`;
          case ComboOperator.IS_NULL:
          case ComparisonOperator.HAS_VALUE:
            return `${displayName} ${inlineText}`;
          default:
            throw new Error('Invalid comparison operator for text attribute');
        }
      default:
        throw new Error('Invalid field type');
    }
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
        style={{ background: 'transparent' }}
      />
      <div className={styles.nodeDecorator}>
        <div className={styles.conditionNodeIcon}>
          <CheckIcon />
        </div>
      </div>
      <div className={styles.nodeContent}>
        <div>Check if all are true</div>
        {
          metadata.children.map((condition) => (
            <Tag
              className={styles.nodeTag}
              key={condition.uuid}
              loading={condition.locked}
              icon={condition.locked ? <LockIcon /> : null}
              onClick={() => onConditionClick(condition)}
            >
              {renderConditionTag(condition)}
            </Tag>
          ))
        }
        {isEditing && <Button type="dashed" size="small" icon={<PlusIcon />} onClick={onAddCondition} />}
      </div>
      <Handle
        type="source"
        isConnectable={false}
        position={Position.Bottom}
        style={{ background: handleBackground }}
        id="condition-resource"
      />
    </>
  );
};

export default ConditionNode;
