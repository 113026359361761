import { useMutation, MutationHookOptions } from '@apollo/client';
import { isFunction } from 'lodash';

import { message } from 'antd';

import { INVITE_USER_MUTATION } from '@frontend/app/queries';
import {
  InviteUserMutation,
  InviteUserMutationVariables,
} from '@frontend/app/queries/types/InviteUserMutation';

type IOptions = MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>;

export const useInviteUser = (options: IOptions = {}) => {
  const [inviteUser, { loading, error }] = useMutation<
    InviteUserMutation,
    InviteUserMutationVariables
  >(INVITE_USER_MUTATION, {
    ...options,
    onCompleted(data: InviteUserMutation) {
      if (isFunction(options.onCompleted)) {
        options.onCompleted(data);
      }

      message.info('An invitation has been sent to the user.');
    },
  });

  return {
    inviteUser,
    loading,
    error,
  };
};
