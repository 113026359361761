import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const CloseIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="
        M13.89,12l7.72-7.72c0.52-0.52,0.52-1.36,0-1.89c-0.52-0.52-1.36-0.52-1.89,0L12,10.11L4.28,2.39
        c-0.52-0.52-1.37-0.52-1.89,0c-0.52,0.52-0.52,1.36,0,1.89L10.11,12l-7.72,7.72c-0.52,0.52-0.52,1.36,0,1.89
        C2.65,21.87,2.99,22,3.33,22s0.68-0.13,0.94-0.39L12,13.89l7.72,7.72c0.26,0.26,0.6,0.39,0.94,0.39c0.34,0,0.68-0.13,0.94-0.39
        c0.52-0.52,0.52-1.36,0-1.89L13.89,12z
      "
      fill={props.fill}
    />
  </SvgIcon>
));

CloseIcon.displayName = 'CloseIcon';

export { CloseIcon };
