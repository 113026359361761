import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const TextIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M14.25,15.33c-0.88,0.41-1.62,0.38-1.94,0.18c-0.1-0.07-0.22-0.18-0.22-0.46V9.93h2.4
      c0.34,0,0.61-0.27,0.61-0.61s-0.27-0.61-0.61-0.61H8.54c-0.34,0-0.61,0.27-0.61,0.61S8.2,9.93,8.54,9.93h2.33v5.12
      c0,0.64,0.28,1.16,0.78,1.49c0.35,0.22,0.79,0.33,1.28,0.33c0.57,0,1.2-0.15,1.82-0.43c0.3-0.14,0.44-0.5,0.3-0.81
      C14.91,15.32,14.55,15.19,14.25,15.33z"
    />
    <path
      d="M19.51,1.9H4.46c-1.36,0-2.48,1.11-2.48,2.48v15.05c0,1.36,1.11,2.48,2.48,2.48h15.05
      c1.36,0,2.48-1.11,2.48-2.48V4.37C21.99,3.01,20.88,1.9,19.51,1.9z M20.77,19.42c0,0.69-0.57,1.26-1.26,1.26H4.46
      c-0.69,0-1.26-0.57-1.26-1.26V4.37c0-0.69,0.57-1.26,1.26-1.26h15.05c0.69,0,1.26,0.57,1.26,1.26V19.42z"
    />
  </SvgIcon>
));

TextIcon.displayName = 'TextIcon';
