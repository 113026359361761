import React, { useState, useEffect, useMemo } from 'react';
import {
 filter, first, find, isEmpty,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { Select, Button, Tooltip } from '@revfluence/fresh';
import {
  TAutomationActionOption,
  useGetMessageTemplateById,
  MessageTemplate,
  MessageTemplateInput,
} from '@frontend/app/hooks';
import { LoadSpinner } from '@components';
import { TemplateTransportType, TemplateBodyType, TemplateType } from '@frontend/app/types/globalTypes';
import FormHeader from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormHeader';
import { ArrowRightIcon } from '@revfluence/fresh-icons/regular/esm';
import FormFooter from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormFooter';
import { TActionNode, TSelectedNode } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { AutomationNodeType } from '@frontend/app/types/globalTypes';
import { useAuth } from '@frontend/context/authContext';
import { MessageTemplateEditor } from './MessageTemplateEditor';
import styles from './ConfigDrawer.scss';

const { Option } = Select;
type TProps = {
  onClose: () => void;
  onUpdateActionNode: (targetNodeId: string, actionNode: TActionNode) => void;
  onUpdateMessageTemplate: (updatedTemplate: MessageTemplate | MessageTemplateInput) => void;
  actionOptions: TAutomationActionOption[];
  selectedNode: TSelectedNode;
  resetMessageTemplate: boolean;
};

const ActionForm = (props: TProps) => {
  const {
    actionOptions,
    onUpdateActionNode,
    onClose,
    selectedNode,
    onUpdateMessageTemplate,
    resetMessageTemplate,
  } = props;

  const { user } = useAuth();
  const [templateId, setTemplateId] = useState<number>();
  const [resourceId, setResourceId] = useState<number>();
  const [fromPreviousSender, setFromPreviousSender] = useState<boolean>();
  const [templateValidationError, setTemplateValidationError] = useState<string>('');
  const [resourceValidationError, setResourceValidationError] = useState<string>('');
  const [newTemplate, setNewTemplate] = useState<MessageTemplateInput>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<MessageTemplate | MessageTemplateInput>(null);
  const [createNewTemplateDisabled, setCreateNewTemplateDisabled] = useState<boolean>(false);

  const resources = useMemo(() => (
    actionOptions && actionOptions[0]?.emailResources.filter(
      (resource) => (
        resource.userId === user.sub
        || resource.id === resourceId
        || resource.displayName === 'Previous Sender'
      ),
    )
  ), [actionOptions, user.sub, resourceId]);
  const selectedResourceExist = useMemo(() => !isEmpty(find(resources, { id: resourceId })), [resources, resourceId]);

  // TODO: refactor to move the state to the root component and managed by useReducer
  // to separate the state from the component. Then pass the state down to avoid using useEffect.
  // Same to DelayForm and ConditionForm
  useEffect(() => {
    if (selectedNode && selectedNode.nodeType === AutomationNodeType.ACTION) {
      setTemplateId(selectedNode.actionNode.templateId);
      setResourceId(selectedNode.actionNode.resourceId);
      setFromPreviousSender(selectedNode.actionNode.fromPreviousSender);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (resetMessageTemplate) {
      setCreateNewTemplateDisabled(false);
      setNewTemplate(null);
    }
  }, [resetMessageTemplate]);

  const handleCancel = () => {
    setCreateNewTemplateDisabled(false);
    setNewTemplate(null);
    onClose();
  };

  const actionNodeDefaultTemplateData = useMemo(() => {
    if (!actionOptions) return null;
    const filtered = filter(
      actionOptions[0]?.messageTemplates,
      (template) => template.messageTemplateType !== TemplateType.GENERIC,
    );
    return first(filtered);
  }, [actionOptions]);

  const {
    data: {
      template: defaultAutomationTemplate = null,
    } = {},
   } = useGetMessageTemplateById({
    variables: {
      templateId: actionNodeDefaultTemplateData?.id,
    },
    skip: !actionNodeDefaultTemplateData?.id,
  });

  const {
    data: {
      template = null,
    } = {},
    loading: isTemplateLoading,
   } = useGetMessageTemplateById({
    variables: {
      templateId,
    },
    skip: !templateId,
  });

  useEffect(() => {
    setSelectedTemplate(template || newTemplate);
  }, [template, newTemplate]);

  const handleCreateNewTemplate = () => {
    setCreateNewTemplateDisabled(true);
    setTemplateId(null);
    setNewTemplate({
      id: uuidv4(),
      title: 'New Template',
      subject: 'Enter the subject here',
      text: defaultAutomationTemplate?.text,
      transportType: TemplateTransportType.EMAIL,
      bodyType: TemplateBodyType.HTML,
      type: TemplateType.GENERIC,
    });
  };

  const handleTemplateChange = (templateId) => {
    setCreateNewTemplateDisabled(false);
    setTemplateId(templateId);
  };

  const validateAction = (actionNode: TActionNode) => {
    const noTemplateError = 'Please select an email template';
    const noResourceError = 'Please select an email';
    const noError = '';

    const templateError = (!actionNode.templateId && !newTemplate) ? noTemplateError : noError;
    const resourceError = (!actionNode.resourceId && !actionNode.fromPreviousSender) || !selectedResourceExist ? noResourceError : noError;

    setTemplateValidationError(templateError);
    setResourceValidationError(resourceError);
    return templateError === noError && resourceError === noError;
  };

  const onSaveAction = () => {
    const actionNode: TActionNode = {
      templateId,
      resourceId,
      fromPreviousSender,
    };
    if (!validateAction(actionNode)) return;
    onUpdateActionNode(selectedNode.targetNodeId, actionNode);
  };

  const onEmailResourceUpdate = (resourceId, resource) => {
    setResourceId(resourceId);
    setFromPreviousSender(resource.data.fromPreviousSender);
  };

  return (
    <div className={styles.ConfigDrawer}>
      <div>
        <FormHeader
          title="Edit Action"
          description="Select what action you would like to automate after previous steps are complete."
          onClose={onClose}
          icon={<ArrowRightIcon fontSize={20} />}
        />
        <div className={styles.configBody}>
          <div className={styles.actionConfigItem}>
            <div>Send email from</div>
            <Select
              value={selectedResourceExist ? resourceId : undefined}
              onChange={onEmailResourceUpdate}
            >
              {resources.map((resource) => (
                <Option
                  key={resource.id}
                  value={resource.id}
                  label={resource.displayName}
                  data={resource}
                >
                  {resource.displayName}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.validationError}>{templateValidationError}</div>
          <div className={styles.actionConfigItem}>
            <div>Email Template</div>
            <div className={styles.editTemplateActions}>
              <Select
                className={styles.selectTemplate}
                showSearch
                optionFilterProp="label"
                value={templateId || newTemplate?.title}
                onChange={handleTemplateChange}
              >
                {actionOptions[0].messageTemplates.map((template) => (
                  <Option
                    key={template.id}
                    value={template.id}
                    label={template.displayName}
                  >
                    {template.displayName}
                  </Option>
                ))}
              </Select>
              <Tooltip
                placement="topRight"
                title="Create new template"
              >
                <Button
                  onClick={handleCreateNewTemplate}
                  className={styles.createNewTemplate}
                  disabled={createNewTemplateDisabled}
                >
                  <PlusIcon />
                </Button>
              </Tooltip>
            </div>
          </div>
          {!selectedTemplate && isTemplateLoading && (
            <LoadSpinner className={styles.loadingSpinner} centered />
          )}
          {selectedTemplate && (
            <MessageTemplateEditor
              messageTemplate={selectedTemplate}
              onUpdateMessageTemplate={onUpdateMessageTemplate}
              resetMessageTemplate={resetMessageTemplate}
            />
          )}
          <div className={styles.validationError}>{resourceValidationError}</div>
        </div>
      </div>
      <FormFooter
        onSave={onSaveAction}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default ActionForm;
