import { TMember } from '@affiliates/components/MembersWizard/types';
import { getDefaultState } from '../getDefaultState';
import { TState } from '../types';

interface IAction {
  members: readonly TMember[];
  type: 'RESET DEFAULT STATE';
}
export type TResetDefaultStateAction = Readonly<IAction>;

export const resetDefaultState = (_state: TState, action: TResetDefaultStateAction): TState => getDefaultState(action.members);
