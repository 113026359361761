import 'css-chunk:src/components/widgets/Table/Table.scss';export default {
  "black": "#1a1818",
  "border": "_border_1uf7e_294",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1uf7e_157",
  "justify-content-space-between": "_justify-content-space-between_1uf7e_161",
  "tabular-nums": "_tabular-nums_1uf7e_165",
  "Table": "_Table_1uf7e_169",
  "scrollable": "_scrollable_1uf7e_240",
  "tableBody": "_tableBody_1uf7e_243",
  "tableHeader": "_tableHeader_1uf7e_247",
  "headerActions": "_headerActions_1uf7e_255",
  "right": "_right_1uf7e_262",
  "searchInput": "_searchInput_1uf7e_269",
  "exportButton": "_exportButton_1uf7e_273",
  "pagination": "_pagination_1uf7e_276",
  "columnConfig": "_columnConfig_1uf7e_279",
  "bodyRow": "_bodyRow_1uf7e_288",
  "odd": "_odd_1uf7e_291",
  "clickable": "_clickable_1uf7e_297",
  "highlightOnHover": "_highlightOnHover_1uf7e_300",
  "noResultNotice": "_noResultNotice_1uf7e_303",
  "mask": "_mask_1uf7e_307",
  "show": "_show_1uf7e_1"
};