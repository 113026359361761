import * as React from 'react';
import styles from './SplashPage.scss';

interface IProps {
  title: string;
  description: string;
  imageUrl: string;
  ctaButton: React.ReactNode;
}

export const SplashPage = React.memo((props: IProps) => (
  <div className={styles.SplashPage}>
    <div className={styles.textArea}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.description}>{props.description}</div>
      <div className={styles.ctaButton}>{props.ctaButton}</div>
    </div>
    <img src={props.imageUrl} />
  </div>
  ));

SplashPage.displayName = 'SplashPage';
