import { IState } from '../../../../types/state';
import { IUpdateDueDateAction } from './index';
import { autoPopulateDueDates, getDueDateValue } from '../../../../utils';

export const updateDueDate = (state: IState, action: IUpdateDueDateAction): IState => {
  const {
    dueDateType,
    contentGuidelineInstanceId,
    dueDate,
    dueDateIndex,
  } = action;
  const contentGuidelines = state.contentGuidelines.map(
    (contentGuideline) => {
      const updatedDueDateValue = getDueDateValue(dueDate);
      if (contentGuideline.contentGuidelineInstanceId === contentGuidelineInstanceId) {
        const dueDatesUpdated = autoPopulateDueDates(
          contentGuideline.dueDates,
          updatedDueDateValue,
          dueDateType,
        );

        return {
          ...contentGuideline,
          dueDates: {
            ...contentGuideline.dueDates,
            [dueDateType]: [
              ...dueDatesUpdated.slice(0, dueDateIndex),
              dueDate,
              ...dueDatesUpdated.slice(dueDateIndex + 1),
            ],
          },
        };
      }

      const dueDatesUpdated = autoPopulateDueDates(
        contentGuideline.dueDates,
        updatedDueDateValue,
        dueDateType,
      );

      return {
        ...contentGuideline,
        dueDates: {
          ...contentGuideline.dueDates,
          [dueDateType]: dueDatesUpdated,
        },
      };
    },
  );

  return {
    ...state,
    contentGuidelines,
  };
};
