._TextCell_itamp_1._hasTooltip_itamp_1 {
  user-select: none;
}
._TextCell_itamp_1 ._content_itamp_4 {
  height: 100%;
  width: 100%;
  overflow: hidden;
  white-space: normal;
  text-align: left;
  word-break: break-word;
  line-height: 21px;
}

._Tooltip_itamp_14 ._content_itamp_4 {
  white-space: pre-line;
}