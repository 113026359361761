._formGroup_zvu6v_1 {
  margin-bottom: 10px;
}

._styledInput_zvu6v_5 {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
}

._socialContainer_zvu6v_10 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}
._socialContainer_zvu6v_10 > * + * {
  margin-block-start: 16px;
}

._title_zvu6v_20 {
  color: var(--neutral-color-palette-gray-9, #1f1f21);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  margin: 0px 0 8px 0;
}

._editDeepLinkButtons_zvu6v_29 ._ant-input-disabled_zvu6v_29 {
  background: #F4FCFF;
  color: #1F1F21;
  font-weight: 600;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
}

._styledButtonGroup_zvu6v_38 {
  background: none;
  text-align: right;
  width: 100%;
  padding: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin-bottom: 12px;
}
._styledButtonGroup_zvu6v_38 ._ant-btn-primary_zvu6v_48[disabled] {
  opacity: 0.65;
  background: var(--action-blue-blue-5, #499DFF);
  color: #fff;
}
._styledButtonGroup_zvu6v_38:hover {
  background: none;
}

._deepLinkButtons_zvu6v_57 {
  display: flex;
  justify-content: end;
  column-gap: 8px;
  margin-top: 16px;
}
._deepLinkButtons_zvu6v_57 ._customButton_zvu6v_63:hover {
  border-color: #ff4d4f;
}
._deepLinkButtons_zvu6v_57 ._customButton_zvu6v_63:hover svg {
  color: #ff4d4f;
}

._editFormContainer_zvu6v_70 ._ant-form-item_zvu6v_70 {
  margin: 0 0 16px;
}
._editFormContainer_zvu6v_70 ._ant-form-item-label_zvu6v_73 {
  font-weight: 600;
}
._editFormContainer_zvu6v_70 ._ant-form_zvu6v_70 {
  border-radius: 6px;
  background: var(--neutral-color-palette-gray-2, #fafafa);
  padding: 16px 12px;
  margin-bottom: 16px;
}
._editFormContainer_zvu6v_70 ._ant-form-item-required_zvu6v_82::before {
  content: "" !important;
}
._editFormContainer_zvu6v_70 ._ant-form-item-explain_zvu6v_85 {
  color: #f5222d;
}
._editFormContainer_zvu6v_70 ._ant-form-item-row_zvu6v_88 {
  display: block;
}
._editFormContainer_zvu6v_70 ._ant-form-item-margin-offset_zvu6v_91 {
  margin-bottom: 0 !important;
}

._customEditText_zvu6v_95 {
  margin-bottom: 16px;
}
._customEditText_zvu6v_95 ._ant-typography_zvu6v_98 {
  color: var(--neutral-color-palette-gray-8, #505256);
  font-size: 12px;
}