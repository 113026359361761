import * as React from 'react';
import cx from 'classnames';

import {
 CircleCheckIcon, CirclePlusIcon, EnvelopeIcon, EnvelopeOpenIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { ClientFeature } from '@frontend/app/constants';
import { ThreadLabelType } from '@frontend/app/types/globalTypes';
import { THREAD_MARK_READ_MUTATION, THREAD_MARK_UNREAD_MUTATION } from '@frontend/app/queries';
import { UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables } from '@frontend/app/queries/types/UpdateThreadToReadStatus';
import { UpdateThreadToUnreadStatus, UpdateThreadToUnreadStatusVariables } from '@frontend/app/queries/types/UpdateThreadToUnreadStatus';
import { useMutation } from '@apollo/client';

import {
  isSelectAllThreadsVar,
  needRefetchMessageVar,
  selectedThreadsVar,
  TSelectedUserLabel,
  useMessagingContext,
} from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { useClientFeatureEnabled, useThreadLabelUpdateByIdsMutation } from '@frontend/app/hooks';
import { logger } from '@common';
import { useReactiveVar } from '@apollo/client';
import {
  Button,
  Checkbox,
  Select,
  Tooltip,
  Typography,
} from '@revfluence/fresh';
import { isEmpty, size } from 'lodash';
import styles from '../ThreadList.scss';
import { ThreadListHeaderGenericActions } from './ThreadListHeaderGenericActions';
import { YouThreadListHeaderProps } from './model';

const { Text } = Typography;
const { Option } = Select;

enum UpdateThreadType {
  LABEL = 'label',
  READ = 'read',
}

export const YouThreadListHeader = ({
  applications,
  assigneeType,
  count,
  status,
  excludeApplicationIdsFilter,
  filterApps,
  isAppFilterOpen,
  loadingThreads,
  title,
  syncing,
  refetchThreadsCount,
  refetchNotificationCount,
  onSelectAllThreadUpdate,
  onRefreshMessages,
  setExcludeApplicationIdsFilter,
  setIsAppFilterOpen,
  setStatus,
  goToNextThread,
}: YouThreadListHeaderProps) => {
  const [updateThreadLabelByIds] = useThreadLabelUpdateByIdsMutation();
  const selectedThreads = useReactiveVar(selectedThreadsVar);
  const isSelectAllThreads = useReactiveVar(isSelectAllThreadsVar);

  const isDisplayTodoAction = isEmpty(selectedThreads) || selectedThreads.some((threadId) => threadId.label === ThreadLabelType.TODO);
  const isAnyThreadUnread = selectedThreads.some((thread) => !thread?.isRead);

  const {
    threads,
    refetchAndUpdateThreads,
  } = useMessagingContext();
  const isInboxSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);

  const onCheckboxUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    isSelectAllThreadsVar(event.target.checked);
    onSelectAllThreadUpdate(event.target.checked);
  };

  const [markThreadAsRead] = useMutation<UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables>(THREAD_MARK_READ_MUTATION);
  const [markThreadAsUnread] = useMutation<UpdateThreadToUnreadStatus, UpdateThreadToUnreadStatusVariables>(THREAD_MARK_UNREAD_MUTATION);

  const onUpdateThreads = async (type: UpdateThreadType) => {
    try {
      switch (type) {
        case UpdateThreadType.LABEL:
          await updateThreadLabelByIds({
            variables: {
              label: isDisplayTodoAction ? ThreadLabelType.DONE : ThreadLabelType.TODO,
              threadIds: selectedThreads.map((item) => item.id),
            },
          });
          break;
        case UpdateThreadType.READ:
          const threadIds = selectedThreads.map((item) => item.id);
          if (isAnyThreadUnread) {
            await markThreadAsRead({ variables: { threadIds } });
          } else {
            await markThreadAsUnread({ variables: { threadIds } });
          }
          break;
      }

      selectedThreadsVar([]);
      isSelectAllThreadsVar(false);
      refetchAndUpdateThreads(0, size(threads));
      refetchThreadsCount();
      refetchNotificationCount();
      if (status !== 'ALL' && type !== UpdateThreadType.READ) {
        goToNextThread();
      }
      needRefetchMessageVar(true);
    } catch (error) {
      logger.error(error.message);
    }
  };

  const onThreadUserLabelUpdate = (value: TSelectedUserLabel) => {
    setStatus(value);
  };

  return (
    <div className={cx(
      styles.header,
      {
        [styles.legacy]: !isInboxSearchFilter,
      },
    )}
    >
      {!isInboxSearchFilter && (
        <div className={styles.titleAndSearch}>
          <div className={styles.titleAndCount}>
            <Text ellipsis className={styles.title}>{title}</Text>
            <div className={styles.count}>{count || 0}</div>
          </div>
          <Select value={status} onChange={onThreadUserLabelUpdate}>
            <Option value={ThreadLabelType.TODO}>To Do</Option>
            <Option value={ThreadLabelType.DONE}>Done</Option>
            <Option value="ALL">All</Option>
          </Select>
        </div>
      )}
      <div className={styles.actions}>
        <div className={styles.bulkActions}>
          { /* @ts-ignore */ }
          <Checkbox checked={isSelectAllThreads} onChange={onCheckboxUpdate} />
          {!isEmpty(selectedThreads) && (
            <>
              <Tooltip title={isDisplayTodoAction ? 'Mark all as Done' : 'Mark all as Todo'}>
                <Button
                  size="middle"
                  onClick={() => onUpdateThreads(UpdateThreadType.LABEL)}
                  icon={isDisplayTodoAction ? <CircleCheckIcon /> : <CirclePlusIcon />}
                />
              </Tooltip>
              <Tooltip title={isAnyThreadUnread ? 'Mark all as Read' : 'Mark all as Unread'}>
                <Button
                  size="middle"
                  onClick={() => onUpdateThreads(UpdateThreadType.READ)}
                  icon={isAnyThreadUnread ? <EnvelopeOpenIcon /> : <EnvelopeIcon />}
                />
              </Tooltip>
            </>
          )}
        </div>
        <ThreadListHeaderGenericActions
          excludeApplicationIdsFilter={excludeApplicationIdsFilter}
          filterApps={filterApps}
          setExcludeApplicationIdsFilter={setExcludeApplicationIdsFilter}
          assigneeType={assigneeType}
          status={status}
          loadingThreads={loadingThreads}
          syncing={syncing}
          isAppFilterOpen={isAppFilterOpen}
          applications={applications}
          onRefreshMessages={onRefreshMessages}
          setIsAppFilterOpen={setIsAppFilterOpen}
          onThreadUserLabelUpdate={onThreadUserLabelUpdate}
        />
      </div>
    </div>
  );
};
