import gql from 'graphql-tag';

export const GET_RESOURCES_QUERY = gql`
  query GetResourcesQuery($ignoreRequestContextUserId: Boolean, $includeExternalScopes: Boolean = false, $resourceType: String) {
    resources: getResources(ignoreRequestContextUserId: $ignoreRequestContextUserId, resourceType: $resourceType) {
      id
      type
      userId
      scopes
      createdDate
      updatedDate
      metadata
      isAdmin
      adminResourceId
      externalScopes @include(if: $includeExternalScopes)
      config {
        markSourceInboxRead
        signature {
          fromEditor
          fromHTML
          mode
        }
        displayName
      }
      authProvider {
        userExternalId
        type
        userExternalDisplayId
        userRevokedAccess
        systemRevokedAccess
        isShared
        access
      }
    }
  }
`;

export const UPDATE_RESOURCE_MUTATION = gql`
  mutation UpdateResourceMutation($resourceId: Int!, $data: UpdateResourceInput!) {
    updated: updateResource(resourceId: $resourceId, data: $data)
  }
`;

export const REVOKE_RESOURCE_ACCESS_MUTATION = gql`
  mutation RevokeResourceAccessMutation($resourceId: Int!, $ignoreRequestContextUserId: Boolean) {
    revoked: revokeResourceAccess(resourceId: $resourceId, ignoreRequestContextUserId: $ignoreRequestContextUserId)
  }
`;

export const MAKE_RESOURCE_SHARED_MUTATION = gql`
  mutation MakeResourceSharedMutation($resourceId: Int!) {
    updated: makeResourceShared(resourceId: $resourceId)
  }
`;

export const CREATE_IGDM_RESOURCE_MUTATION = gql`
  mutation CreateIgdmResourceMutation($socialAccountId: String!) {
    createdIgdm: createIGDMResource(socialAccountId: $socialAccountId) {
      id
      type
      userId
      scopes
      createdDate
      updatedDate
      metadata
      isAdmin
      adminResourceId
      config {
        markSourceInboxRead
        signature {
          fromEditor
          fromHTML
          mode
        }
      }
      authProvider {
        userExternalId
        type
        userExternalDisplayId
        userRevokedAccess
        systemRevokedAccess
        isShared
      }
    }
  }
`;
