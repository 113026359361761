import 'css-chunk:src/app/containers/Projects/ProjectsPage/TableHeader/ApplicantOperationButtons.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1wknn_157",
  "justify-content-space-between": "_justify-content-space-between_1wknn_161",
  "tabular-nums": "_tabular-nums_1wknn_165",
  "button": "_button_1wknn_355",
  "TableHeader": "_TableHeader_1wknn_377",
  "count": "_count_1wknn_384",
  "buttonRow": "_buttonRow_1wknn_387",
  "right": "_right_1wknn_394",
  "buttonTooltip": "_buttonTooltip_1wknn_401",
  "primaryCTA": "_primaryCTA_1wknn_402",
  "icon": "_icon_1wknn_406",
  "isPrimaryTheme": "_isPrimaryTheme_1wknn_411",
  "disabled": "_disabled_1wknn_419",
  "applicantOperationWrapper": "_applicantOperationWrapper_1wknn_425",
  "actionButton": "_actionButton_1wknn_429",
  "approveIcon": "_approveIcon_1wknn_439",
  "rejectIcon": "_rejectIcon_1wknn_445",
  "inline": "_inline_1wknn_451",
  "show": "_show_1wknn_1"
};