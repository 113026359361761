import * as React from 'react';
import { Tooltip } from '@revfluence/fresh';
import { TooltipProps } from 'antd';
import { map } from 'lodash';
import moment from 'moment';

import {
  DateOperator,
  TDateRequirement,
} from '../types/ContentDateRequirements';

interface IProps {
  dueDates: Array<TDateRequirement>;
  placement: TooltipProps['placement'];
}

const SHORT_DATE_FORMAT = 'MMM D';

export const DueDateTooltip: React.FC<IProps> = React.memo((props) => {
  const {
    children,
    dueDates,
    placement,
  } = props;

  const title = React.useMemo((): React.ReactNode => (
    <ol
      style={{
        margin: 0,
        padding: '0 4px 0 16px',
      }}
    >
      {map(dueDates, (date, index) => {
        switch (date.operator) {
          case DateOperator.BEFORE: {
            const formattedDate = moment(date.beforeDate).format(SHORT_DATE_FORMAT);
            return (
              <li key={`date-${index}`}>
                Before
                {' '}
                <strong>
                  {formattedDate}
                </strong>
              </li>
            );
          }
          case DateOperator.BETWEEN: {
            const formattedBeforeDate = moment(date.beforeDate).format(SHORT_DATE_FORMAT);
            const formattedOnDate = moment(date.onDate).format(SHORT_DATE_FORMAT);
            return (
              <li key={`date-${index}`}>
                B/T
                {' '}
                <strong>
                  {formattedOnDate}
                </strong>
                {' '}
                &amp;
                {' '}
                <strong>
                  {formattedBeforeDate}
                </strong>
              </li>
            );
          }
          case DateOperator.ON: {
            const formattedDate = moment(date.onDate).format(SHORT_DATE_FORMAT);
            return (
              <li key={`date-${index}`}>
                On
                {' '}
                <strong>
                  {formattedDate}
                </strong>
              </li>
            );
          }
          default:
            throw new Error('Unhandled date operator.');
        }
      })}
    </ol>
  ), [
    dueDates,
  ]);

  return (
    <Tooltip title={title} placement={placement}>
      {children}
    </Tooltip>
  );
});
DueDateTooltip.displayName = 'DueDateTooltip';
