import * as React from 'react';
import { useState } from 'react';

import { Pagination } from '@frontend/app/components';
import { DataTable } from '@frontend/app/refresh-components';
import { Button } from '@frontend/shadcn/components/ui/button';
import {
 DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import {
    Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/shadcn/components/ui/tabs';

export interface IKlaviyoTableRow {
    name: string,
    id: number,
    keepSynced: boolean,
}

interface IProps {
    syncSelectedItems: (projectOrGroup: string) => void,
    rowsLoading: boolean,
    selectedGroups: number[],
    selectedProjects: number[],
    totalGroupCount: number,
    totalProjectCount: number,
    currentGroupPage: number,
    currentProjectPage: number,
    setCurrentGroupPage: (page: number) => void,
    setCurrentProjectPage: (page: number) => void,
    pageSize: number,
    projectColumnDef: ColumnDef<{ name: string, id: number, keepSynced: boolean }>[],
    groupColumnDef: ColumnDef<{ name: string, id: number, keepSynced: boolean }>[],
    projectRows: IKlaviyoTableRow[],
    groupRows: IKlaviyoTableRow[],
}

const ProjectGroupTable: React.FC<IProps> = ({
    syncSelectedItems,
    rowsLoading,
    selectedGroups,
    selectedProjects,
    totalGroupCount,
    totalProjectCount,
    currentGroupPage,
    currentProjectPage,
    setCurrentGroupPage,
    setCurrentProjectPage,
    pageSize,
    projectColumnDef,
    groupColumnDef,
    projectRows,
    groupRows,
  }) => {
    const [openTab, setOpenTab] = useState('projects');
    return (
      <Tabs value={openTab} defaultValue="projects">
        <TabsList>
          <TabsTrigger asChild value="projects">
            <div
              className="cursor-pointer text-muted-foreground hover:text-foreground"
              onClick={() => setOpenTab('projects')}
            >
              Projects
            </div>
          </TabsTrigger>
          <TabsTrigger asChild value="groups">
            <div
              className="cursor-pointer text-muted-foreground hover:text-foreground"
              onClick={() => setOpenTab('groups')}
            >
              Groups
            </div>
          </TabsTrigger>
        </TabsList>
        <div className="h-6" />

        <div>
          {openTab === 'groups' && (
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              {selectedGroups?.length > 0 ? (
                <div>
                  {selectedGroups.length}
                  {' '}
                  groups selected
                </div>
              ) : (
                <div>
                  {totalGroupCount}
                  {' '}
                  projects
                </div>
              )}
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button
                    variant="outline"
                  >
                    Actions
                    <ChevronDownIcon className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => syncSelectedItems(openTab)}
                  >
                    Sync
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>

            <Pagination
              total={totalGroupCount}
              page={currentGroupPage}
              setPage={setCurrentGroupPage}
              pageSize={pageSize}
              showTotal
            />
          </div>
        )}
          {openTab === 'projects' && (
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              {selectedProjects?.length > 0 ? (
                <div>
                  {selectedProjects.length}
                  {' '}
                  projects selected
                </div>
              ) : (
                <div>
                  {totalProjectCount}
                  {' '}
                  projects
                </div>
              )}
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button
                    variant="outline"
                  >
                    Actions
                    <ChevronDownIcon className="ml-2 h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onClick={() => syncSelectedItems(openTab)}
                  >
                    Sync
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <Pagination
              total={totalProjectCount}
              page={currentProjectPage}
              setPage={setCurrentProjectPage}
              pageSize={pageSize}
              showTotal
            />
          </div>
        )}
        </div>
        <TabsContent value="projects">
          {!rowsLoading && <DataTable columns={projectColumnDef} data={projectRows} />}
        </TabsContent>
        <TabsContent value="groups">
          {!rowsLoading && <DataTable columns={groupColumnDef} data={groupRows} />}
        </TabsContent>
      </Tabs>
  );
};

export default ProjectGroupTable;
