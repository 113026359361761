import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import {
    OFFER_PAYOUT_ACTION, UpdateOfferInput, UpdatePromoInput, UserInfoInput,
} from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { first } from 'lodash';

export const createUpdateOfferPromoInput = (offer: GetOfferById_offer, profile: UserInfoInput, isUngrouped: boolean): UpdateOfferInput => {
    let returnObject: UpdateOfferInput = {
        description: offer.description,
        expirationDate: offer.expirationDate,
        imageUrl: offer.imageUrl,
        name: offer.name,
        isReadOnly: offer.isReadOnly,
        links: [],
        migrateToNewFlow: true,
        programId: offer.programId,
    };
    const offerPromo = first(offer.promos);
    const payoutInput = offer.payouts.map((payout) => ({
        flatPayout: payout.flatPayout,
        id: payout.id,
        isDefault: payout.id === offerPromo.defaultPayoutId,
        label: payout.label,
        payoutAction: OFFER_PAYOUT_ACTION.NOUPDATE,
        payoutType: payout.payoutType,
        percentPayout: payout.percentPayout,
    }));
    const promo: UpdatePromoInput[] = [
        {
            codePrefix: offerPromo.codePrefix,
            codeSuffix: offerPromo.codeSuffix,
            defaultPayoutId: offerPromo.defaultPayoutId,
            endDate: offerPromo.endDate,
            flatPayout: offerPromo.flatPayout,
            id: offerPromo.id,
            isOneTime: offerPromo.isOneTime,
            isSubscription: offerPromo.isSubscription,
            isUngrouped,
            multiShopifyEnabled: offerPromo.multiShopifyEnabled,
            name: offerPromo.name,
            payoutType: offerPromo.payoutType,
            payouts: payoutInput,
            percentPayout: offerPromo.percentPayout,
            prefixType: offerPromo.prefixType,
            priceRuleAmount: offerPromo.priceRuleAmount,
            priceRuleType: offerPromo.priceRuleType,
            productCollections: offerPromo?.providerMetadata?.productCollections?.map((collection) => ({
                id: collection.id,
                title: collection.title,
            })) || [],
            recurringCycleLimit: offerPromo.recurringCycleLimit,
            source: offerPromo.source,
            specialLimitNewCustomersOnly: offerPromo.specialLimitNewCustomersOnly,
            specialLimitOnePerSale: offerPromo.specialLimitOnePerSale,
            specialLimitUsageCapAmount: offerPromo.specialLimitUsageCapAmount,
            specialLimitUsageCapEnabled: offerPromo.specialLimitUsageCapEnabled,
            startDate: offerPromo.startDate,
            status: offerPromo.status,
            updatedClientsForSync: [],
            usageLimitAmount: offerPromo.usageLimitAmount,
            usageLimitRule: offerPromo.usageLimitRule,
        },
    ];
    returnObject = {
        ...returnObject,
        promos: promo,
        userInfo: {
            clientId: profile.clientId,
            email: profile.email,
            name: profile.name,
        },
    };
    return returnObject;
};
