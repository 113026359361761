import React from 'react';
import {
 Collapse, Select, Table, Typography, Row,
} from '@revfluence/fresh';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import styles from '../ManageOffer.scss';
import { TMember } from '../../MembersWizard/types';

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface EditPayoutData {
  key: number;
  name: string;
  code: string;
  payoutId?: number;
  status: string;
  affiliateLink: string;
}

interface IProps {
  defaultPayoutId: number;
  payoutOptions: { id: number; label: string }[];
  setDefaultPayoutId: (id: number) => void;
  editPayoutData: EditPayoutData[];
  selectedMembers: readonly TMember[];
  offer: GetOfferById_offer;
  offerSource?: OFFER_SOURCE;
  columns: CustomColumn[];
  data: readonly TMember[];
}

interface CustomColumn {
  title: string;
  dataIndex: string;
  key: string;
  width: number;
  ellipsis?: {
    showTitle: boolean;
  };
  render?: (text, record) => JSX.Element;
}

const ManageOfferPayout: React.FC<IProps> = ({
  defaultPayoutId,
  payoutOptions,
  setDefaultPayoutId,
  editPayoutData,
  selectedMembers,
  offer,
  offerSource,
  columns,
  data,
}) => (
  <div className={styles.manageOfferContainer}>
    <Title level={5}>Change offer payout for the selected members</Title>
    <Text>You can edit the payout associated with this project for selected members.</Text>
    <Title level={5}>Choose Payout</Title>
    <Select defaultValue={defaultPayoutId} onChange={(id) => setDefaultPayoutId(id)}>
      {payoutOptions.map((payout, key) => (
        <Select.Option value={payout.id} key={key}>
          {payout.label}
        </Select.Option>
      ))}
    </Select>

    <Text>
      Please make sure if you want to upgrade/downgrade any member payout as this action will change the value for all
      selected members.
    </Text>
    <Title level={5}>
      {data.length}
      {' '}
      {data.length === 1 ? 'Member' : 'Members'}
      {' '}
      Selected
    </Title>
    <Collapse ghost expandIconPosition="start">
      {offer.payouts.map((payout, index) => {
        const filteredData = editPayoutData.filter((member) => member.payoutId === payout.id);
        if (!filteredData.length) return null;
        return (
          <Panel
            key={index}
            header={
              (
                <Row justify="space-between" align="middle">
                  <div>
                    {offerSource === OFFER_SOURCE.SHOPIFY
                      && `${payout.label} (${payout.flatPayout ? `$${payout.flatPayout}` : ''}${
                        payout.percentPayout ? `+${payout.percentPayout}%` : ''
                      })`}
                    {offerSource === OFFER_SOURCE.TUNE
                      && `${payout.label} (${payout.flatPayout ? `$${payout.flatPayout}` : ''}${
                        payout.percentPayout ? ` + ${payout.percentPayout}%` : ''
                      })`}
                  </div>
                  <Text className={styles.memberCount}>{`${filteredData.length}/${selectedMembers.length} members`}</Text>
                </Row>
              )
            }
          >
            <Table columns={columns} dataSource={filteredData} pagination={false} />
          </Panel>
        );
      })}
    </Collapse>
  </div>
);

export default ManageOfferPayout;
