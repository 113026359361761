import { MutationHookOptions } from '@apollo/client';

import { useMutation } from '@frontend/app/hooks';
import { SAVE_SEGMENT } from '@frontend/app/queries/SaveSegmentMutation';
import {
  SaveSegmentMutation,
  SaveSegmentMutationVariables,
} from '@frontend/app/queries/types/SaveSegmentMutation';

type IOptions = MutationHookOptions<SaveSegmentMutation, SaveSegmentMutationVariables>;

export const useSaveSegmentMutation = (options: IOptions = {}) => useMutation<SaveSegmentMutation, SaveSegmentMutationVariables>(SAVE_SEGMENT, options);
