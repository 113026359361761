import * as React from 'react';
import {
  every,
  isEmpty,
  isNull,
} from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { IAddTrackingLinksMembers } from '../../types';
import { TActions, TState } from './state';
import { getSteps } from './getSteps';

import styles from '../../components/MembersWizard.scss';

export const getWorkflowTrackingLinksSteps = (props: IAddTrackingLinksMembers, state: TState, actions: TActions) => {
  const {
    onClose,
  } = props;

  const {
    selectMembers,
    reviewMembers,
    completed,
    selectedMembers,
    forceCheckInMembers,
    allMembers,
  } = getSteps(props, state, actions);

  const isOnlyForceCheckIn = every(allMembers, 'forceCheckIn');
  if (isOnlyForceCheckIn) {
    const forceCheckInSteps = [
      selectMembers({
        instructions: {
          description: 'By clicking "Move to next step", they will move forward in your workflow to the correct step.',
          title: 'These members already exist in your offer',
        },
        nextButtonConfig: {
          className: styles.wizardNextButton,
          text: 'Move to next step',
          disabled: isNull(state.members),
          action: () => {
            actions.save(1);
            onClose();
          },
        },
        previousButtonConfig: {
          action: onClose,
          className: styles.wizardButton,
          showIcon: false,
        },
        stepNum: 1,
      }),
      completed({
        instructions: {
          description: `
          Once you close this drawer, these members should be in the correct workflow step.
         `.trim(),
          title: `
          You have moved forward ${`${forceCheckInMembers.length.toLocaleString()} `}
          member${forceCheckInMembers.length !== 1 ? 's' : ''} to their next step.
        `.trim(),
        },
        nextButtonConfig: {
          action: onClose,
          className: styles.wizardNextButton,
          disabled: false,
          showIcon: false,
          text: 'Done',
        },
        previousButtonConfig: {
          className: `${styles.wizardButton} ${styles.hidden}`,
          disabled: true,
          text: 'Confirm Members',
        },
        stepNum: 2,
      }),
    ];
    if (state.error) {
      return forceCheckInSteps.map((step) => ({
        ...step,
        actionComponents: (
          <Notice type="error" message={state.error.message} />
        ),
      }));
    }
    return forceCheckInSteps;
  }

  const selectedMemberHasError = selectedMembers.some((member) => !isEmpty(member.deepLink?.error));
  const nextButtonDisabled = isEmpty(selectedMembers) || selectedMemberHasError;
  const steps = [
    selectMembers({
      instructions: {
        description: 'These are the members who will be receiving links for this offer.',
        title: 'Who will receive these sales tracking links?',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Review',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    reviewMembers({
      instructions: {
        description: state.saving ? '' : 'Make sure everything looks good before you create the unique tracking links.',
        title: state.saving ? '' : 'Review your link tracking details.',
      },
      nextButtonConfig: {
        action: () => actions.save(3),
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Links',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Confirm Members',
      },
      stepNum: 2,
    }, { disableNextStep: true }),
    completed({
      instructions: {
        description: `
          Their unique tracking links are starting to be generated, it should only take a
          few minutes. You can close this drawer. Once the tracking links have been generated,
          you can email these members their links in the next step of this project.
        `.trim(),
        title: `
          Congrats! You are generating unique tracking links for ${`${selectedMembers.length.toLocaleString()} `}
          member${selectedMembers.length !== 1 ? 's' : ''}.
        `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 3,
    }),
  ];
  if (state.error) {
    return steps.map((step) => ({
      ...step,
      actionComponents: (
        <Notice type="error" message={state.error.message} />
      ),
    }));
  }

  return steps;
};
