/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13yx2_157 {
  display: flex;
}

._justify-content-space-between_13yx2_161 {
  justify-content: space-between;
}

._tabular-nums_13yx2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13yx2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13yx2_157 {
  display: flex;
}

._justify-content-space-between_13yx2_161 {
  justify-content: space-between;
}

._tabular-nums_13yx2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13yx2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NameCell_13yx2_355 {
  display: flex;
  flex: 1;
  align-items: center;
  overflow: hidden;
}
._NameCell_13yx2_355 ._avatar_13yx2_361 {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  border-radius: 624.9375rem;
}
._NameCell_13yx2_355 ._avatar_13yx2_361 .ant-avatar-string {
  line-height: 2rem;
}
._NameCell_13yx2_355 ._name_13yx2_370 {
  display: flex;
  flex: 1;
  overflow: hidden;
}
._NameCell_13yx2_355 ._name_13yx2_370 ._ellipsisLabel_13yx2_375 {
  flex: 1;
}
._NameCell_13yx2_355 ._accessory_13yx2_378 {
  flex-shrink: 0;
  margin-left: auto;
}
._NameCell_13yx2_355 ._interactable_13yx2_382 {
  cursor: pointer;
}
._NameCell_13yx2_355 ._interactable_13yx2_382:hover {
  color: #4fa7ee;
}