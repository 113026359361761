import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const AddToGroupIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12.66,12.31c1.59,0,2.89-1.36,2.89-3.04s-1.3-3.04-2.89-3.04S9.78,7.59,9.78,9.27S11.07,12.31,12.66,12.31z
      M12.66,7.25c1.03,0,1.86,0.9,1.86,2.02s-0.84,2.02-1.86,2.02s-1.86-0.9-1.86-2.02S11.64,7.25,12.66,7.25z"
    />
    <path
      d="M14.6,13.1c-0.25-0.12-0.56-0.02-0.68,0.24c-0.12,0.25-0.02,0.56,0.24,0.68c0.97,0.47,1.58,1.44,1.76,2.74H9.4
      c0.13-1.22,0.74-2.18,1.71-2.69c0.25-0.13,0.35-0.44,0.22-0.69c-0.13-0.25-0.44-0.35-0.69-0.22c-1.45,0.76-2.29,2.26-2.29,4.11
      c0,0.28,0.23,0.51,0.51,0.51h7.6c0.14,0,0.27-0.06,0.37-0.16c0.1-0.1,0.15-0.24,0.14-0.38C16.89,15.28,16.05,13.8,14.6,13.1z"
    />
    <path
      d="M19.08,13.02c-0.83,0-1.33,0.19-1.51,0.57c-0.12,0.26-0.08,0.55,0.12,0.79c0.23,0.28,0.73,1.26,0.73,1.63
      c0,0.14,0.05,0.27,0.15,0.36s0.22,0.16,0.36,0.15c0.02,0,1.79-0.01,2.54,0c0,0,0.01,0,0.01,0c0.14,0,0.27-0.06,0.37-0.15
      c0.1-0.1,0.15-0.24,0.14-0.38C21.92,14.18,20.78,13.02,19.08,13.02z M19.36,15.5c-0.14-0.5-0.42-1.06-0.65-1.44
      c0.1-0.01,0.22-0.02,0.38-0.02c0.96,0,1.61,0.53,1.82,1.46C20.39,15.49,19.77,15.5,19.36,15.5z"
    />
    <path
      d="M19.08,12.46c1.22,0,2.2-0.99,2.2-2.22s-0.99-2.22-2.2-2.22c-1.22,0-2.21,0.99-2.21,2.22
      S17.87,12.46,19.08,12.46z M19.08,9.05c0.65,0,1.18,0.54,1.18,1.19s-0.53,1.19-1.18,1.19c-0.65,0-1.18-0.54-1.18-1.19
      S18.43,9.05,19.08,9.05z"
    />
    <path
      d="M7.25,11.61H5.39V9.75c0-0.28-0.23-0.51-0.51-0.51S4.37,9.47,4.37,9.75v1.86H2.51C2.23,11.61,2,11.84,2,12.12
      c0,0.28,0.23,0.51,0.51,0.51h1.86v1.86C4.37,14.77,4.6,15,4.88,15s0.51-0.23,0.51-0.51v-1.86h1.86c0.28,0,0.51-0.23,0.51-0.51
      C7.76,11.84,7.53,11.61,7.25,11.61z"
    />
  </SvgIcon>
));

AddToGroupIcon.displayName = 'AddToGroupIcon';

export { AddToGroupIcon };
