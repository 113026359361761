import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const InboxIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path d="M22 13.25C22 13.23 22 13.2 21.99 13.18C21.99 13.16 21.98 13.14 21.98 13.13C21.98 13.12 21.97 13.1 21.97 13.09L19.6 6.72C19.21 5.69 18.22 5 17.12 5H6.88C5.78 5 4.79 5.69 4.4 6.72L2.04 13.08C2.03 13.1 2.03 13.11 2.02 13.13C2.02 13.14 2.01 13.16 2.01 13.18C2.01 13.2 2 13.23 2 13.25C2 13.26 2 13.27 2 13.28V17.58C2 18.69 2.9 19.6 4.02 19.6H19.99C21.1 19.6 22.01 18.7 22.01 17.58V13.28C22 13.27 22 13.26 22 13.25ZM5.48 7.12C5.7 6.54 6.26 6.15 6.88 6.15H17.12C17.74 6.15 18.3 6.54 18.52 7.12L20.6 12.71H15.69C15.54 12.71 15.39 12.77 15.28 12.88C15.17 12.99 15.11 13.13 15.11 13.29V14.11C15.11 14.39 15 14.66 14.8 14.86C14.6 15.06 14.33 15.17 14.05 15.17H10.36C9.77 15.17 9.3 14.69 9.3 14.11V13.28C9.3 12.96 9.04 12.7 8.72 12.7H3.4L5.48 7.12ZM20.85 17.58C20.85 18.06 20.46 18.44 19.99 18.44H4.02C3.54 18.44 3.16 18.05 3.16 17.58V13.86H8.16V14.1C8.16 15.32 9.15 16.31 10.37 16.31H14.06C14.65 16.31 15.21 16.08 15.62 15.66C16.04 15.24 16.27 14.68 16.27 14.09V13.86H20.86V17.58H20.85Z" />
  </SvgIcon>
));

InboxIcon.displayName = 'InboxIcon';
