import { useState, useEffect } from 'react';

import { logger } from '@common';

export interface IDesignatedContractSigner {
  name: string;
  first_name?: string;
  last_name?: string;
  email_address: string;
  client_id: string;
  date_created: string;
  date_modified: string;
}

const fetchSignersData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.debug({ message: err });

    throw err;
  }
};

export function useFetchSignersData(url: string, clientId: string, newCount: number) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDesignatedContractSigner[]>(null);
  const [error, setError] = useState(null);

  const finalUrl = `${url}?client_id=${clientId}`;

  useEffect(() => {
    setLoading(true);
    fetchSignersData(finalUrl)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [finalUrl, newCount]);

  return {
    loading,
    data,
    error,
  };
}
