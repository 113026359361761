import * as React from 'react';
import { SelectList } from '@frontend/app/components';

import styles from './IncludeSelect.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IncludeSelect: React.FC<any> = (props) => (
  <SelectList
    {...props}
    className={styles.IncludeSelect}
    contentClassName={styles.content}
  />
  );
