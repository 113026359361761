/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_55xju_79 {
  display: flex;
}

._justify-content-space-between_55xju_83 {
  justify-content: space-between;
}

._tabular-nums_55xju_87 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_55xju_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._multiRangeSlider_55xju_99 {
  margin-right: 0.125rem;
}