import * as React from 'react';

import cx from 'classnames';
import { isEmpty, isFunction, size } from 'lodash';
import { Button, Modal, Tooltip } from 'antd';

import { DeleteFilled, ExclamationCircleFilled } from '@ant-design/icons';

import { useDeleteMembersById } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';

import styles from './DeleteMemberButton.scss';

const { useCallback, useMemo } = React;

interface IDeleteMemberProps {
  className?: string[] | string;
  memberIds?: number[];
  onDelete?(deletedMembers: number[]);
  isSync?: boolean
}

export const DeleteMemberButton: React.FC<IDeleteMemberProps> = React.memo((props) => {
  const {
    className = [],
    memberIds,
    onDelete,
    isSync,
  } = props;

  const {
    showSuccessMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  const {
    deleteMembers,
    isDeleting,
  } = useDeleteMembersById({
    memberIds,
    skip: isEmpty(memberIds),
    isSync,
  });

  const memberSize = size(memberIds);

  const isSingle = memberSize === 1;

  const confirmContent = useMemo(() => (
    <>
      <p>
        You are removing
        {' '}
        {isSingle ? 'a person' : 'people'}
        {' '}
        entirely from your database. Data from
        {' '}
        {isSingle ? 'this person' : 'these people'}
        {' '}
        will be removed from:
      </p>
      <ul>
        <li>Members</li>
        <li>Projects</li>
        <li>Inbox</li>
      </ul>
    </>
  ), [isSingle]);

  const handleDelete = useCallback(() => {
    deleteMembers({
      onSuccess: (deletedIds, message) => {
        showSuccessMessage(message);

        if (isFunction(onDelete)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          onDelete(deletedIds);
        }
      },
      onFailure: () => {
        showGenericErrorMessage();
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberIds]);

  const handleClick = useCallback(() => {
    Modal.confirm({
      title: `Remove ${isSingle ? 'person' : 'people'} from your database?`,
      content: confirmContent,
      okText: 'Remove',
      cancelText: 'Cancel',
      centered: true,
      zIndex: 10000000,
      icon: <ExclamationCircleFilled style={{ color: '#d9534f' }} />,
      okButtonProps: {
        danger: true,
      },
      onOk: handleDelete,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberIds, isSingle]);

  const deleteMemberButtonProperties = (() => {
    const tooltipText = `Delete ${isSingle ? 'member' : 'members'}`;

    return {
      disabled: memberSize === 0,
      tooltipText,
    };
  })();

  return (
    <Tooltip title={deleteMemberButtonProperties.tooltipText}>
      <div
        className={cx(
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          (styles as any).wrapper,
          {
            [styles.disabled]: deleteMemberButtonProperties.disabled,
          },
        )}
      >
        <Button
          onClick={handleClick}
          disabled={deleteMemberButtonProperties.disabled}
          loading={isDeleting}
          icon={<DeleteFilled size={16} />}
          className={cx(
            styles.button,
            className,
            {
              [styles.disabled]: deleteMemberButtonProperties.disabled,
            },
          )}
        />
      </div>
    </Tooltip>
  );
});
