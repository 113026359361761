import * as React from 'react';
import {
  groupBy, isEmpty, isNil, map, trim,
} from 'lodash';

import {
 Avatar, Button, Drawer, Form, Select, Typography, message,
} from '@revfluence/fresh';

import {
  useClientFeatureEnabled, useCommunitiesQuery, useGetAllProjectsQuery,
} from '@frontend/app/hooks';

import { TSortType, Table, LoadSpinner } from '@components';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useAuth } from '@frontend/context/authContext';

import { ClientFeature } from '@frontend/app/constants';
import { DollarSignIcon } from '@revfluence/fresh-icons/regular/esm';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { IPayment } from '../models';
import { IPaymentsFetchQueryParams, useFetchPaymentsData } from '../useFetchPayments';
import { useExportPayments } from '../useExportPayments';

import { allModalColumns, allDashboardColumns } from './PaymentTableColumns';

import { dollarsForCentsValue, statusForPayment } from '../constants';

const {
  useMemo, useState, useCallback,
} = React;
const { Title } = Typography;
const { Option } = Select;
import styles from './PaymentTable.scss';
import { updatePaymentData } from '../savePayment';
import { logger } from '../../../../../common/Logger';

interface IProps {
  memberId?: string;
}

interface OrderParams {
  orderBy?: string;
  orderDirection?: TSortType;
}

const COL_NAME_TO_PROPERTY = {
  created_date: 'date_created',
  payee_paypal: 'paypal',
  amount: 'amount_spent',
};

export const PaymentTable: React.FunctionComponent<IProps> = ({ memberId }) => {
  const { user } = useAuth();
  const [page, setPage] = useState<number>(0);
  const [selectedPayment, setSelectedPayment] = useState<IPayment[]>([]);
  const [selectedProgramId, setSelectedProgramId] = useState<number>(null);
  const [selectedCommunityId, setSelectedCommunityId] = useState<number>(null);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isReassigningPayment, setIsReassigningPayment] = useState<boolean>(false);

  const [orderParams, setOrderParams] = useState<OrderParams>({
    orderBy: 'created_date',
    orderDirection: 'DESC',
  });

  const { clientId } = useApplication();
  const workflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const isReassignPaymentToProject = useClientFeatureEnabled(ClientFeature.REASSIGN_PAYMENT_TO_PROJECT);

  const params: IPaymentsFetchQueryParams = {
    client_id: clientId,
    page: page + 1,
    orderBy: COL_NAME_TO_PROPERTY[orderParams.orderBy],
    orderDirection: orderParams.orderDirection,
  };
  if (memberId) {
    params.member_id = memberId;
  }
  const { loading: loadingProjects, data: projectsData } = useGetAllProjectsQuery({
    fetchPolicy: 'cache-only',
  });
  const { loading: loadingAllPayments, data: allPaymentsData, refetch } = useFetchPaymentsData(params);
  const { loading: loadingExportPayments, save: exportPayments } = useExportPayments();
  const {
    loading: communityLoading,
    data: communityData,
  } = useCommunitiesQuery({ skip: false });
  const projectsDataDir = useMemo(() => groupBy((projectsData?.projects || []), 'id'), [projectsData]);
  const isLoading = useMemo(() => loadingAllPayments && loadingProjects && communityLoading, [loadingProjects, loadingAllPayments, communityLoading]);
  const payments = useMemo(() => (allPaymentsData?.data || []).map((payment: IPayment) => {
    const names = [];
    (payment.program_ids || []).forEach((id) => {
      const [project] = projectsDataDir[id] || [];
      if (project) {
        names.push(project.title);
      }
    });
    payment.program_names = names;
    return payment;
  }), [allPaymentsData, projectsDataDir]);

  const columns = memberId ? allModalColumns(workflowEnabled) : allDashboardColumns(workflowEnabled);

  /**
   * Massage data into state expected by Table
   */
  const tableData = useMemo(() => {
    if (isEmpty(payments)) {
      return [];
    }

    return map(payments, (payment: IPayment, index) => {
      const programNames = payment.program_names ? payment.program_names.join(', ') : '';
      const activationNames = payment.activation_names ? payment.activation_names.join(', ') : '';
      const payeeName = payment.payee_name || '';
      return {
        id: index.toString(),
        _raw: payment,
        status: statusForPayment(payment),
        created_date: payment.created_ts,
        payee_paypal: payment.paypal || (payment.is_payable ? 'Not Shared' : 'Pending Info'),
        payee_name: payment.payee_name,
        activation_names: activationNames,
        program_names: programNames,
        amount: dollarsForCentsValue(payment.amount_intended_for_publisher).toFixed(2),
        _sortableFields: {
          payee_name: trim(payeeName).toLowerCase(),
          activation_names: trim(activationNames).toLocaleLowerCase(),
          program_names: trim(programNames).toLocaleLowerCase(),
          amount: payment.amount_intended_for_publisher,
        },
      };
    });
  }, [payments]);
  const programs = useMemo(() => projectsData?.projects?.map((project) => ({
        value: project.id,
        label: project.title,
      })) || [], [projectsData]);
  const communities = useMemo(() => communityData?.communities?.map((community) => ({
        value: community.id,
        label: community.title,
      })) || [], [communityData]);
  const onPageChange = useCallback((page: number) => {
    setPage(page);
  }, []);

  const onSortDirChange = useCallback((orderBy: string, orderDirection: TSortType) => {
    if (!(orderBy in COL_NAME_TO_PROPERTY)) {
      return;
    }
    setOrderParams({ orderBy, orderDirection });
    setPage(0);
  }, [setOrderParams, setPage]);
  const onCloseDrawer = () => {
    setIsReassigningPayment(false);
    setShowDrawer(false);
    setSelectedPayment([]);
    setSelectedCommunityId(null);
    setSelectedProgramId(null);
  };
  const onSubmitReassign = async () => {
    try {
      setIsReassigningPayment(true);
      const selectedProgram = programs.find((program) => program.value === selectedProgramId);
      for (const payment of selectedPayment) {
        const url = `${backendServerApiEndpoint()}/payment`;
        const updatePayload = {
          program_ids: selectedProgram ? [selectedProgram.value] : payment.program_ids,
          program_names: selectedProgram ? [selectedProgram.label] : payment.program_names,
          community_ids: selectedCommunityId ? [selectedCommunityId] : payment.community_ids,
          activation_ids: payment.activation_ids,
          activation_names: payment.activation_names,
          assigned: payment.assigned,
          client_id: payment.client_id,
        };
        await updatePaymentData(`${url}/${payment.id}`, updatePayload);
      }
      message.success('Sucessfully updated the payments');
    } catch (err) {
      message.error('Something went wrong');
      logger.error(err);
    } finally {
      refetch();
      onCloseDrawer();
    }
  };
  const renderHeader = () => {
    if (tableData && tableData.length > 0) {
      return (
        <div className={styles.mainTitle}>
          {`Showing ${tableData.length} payments`}
          {isNil(memberId) && (
            <Button
              className={styles.exportButton}
              loading={loadingExportPayments}
              disabled={loadingExportPayments}
              onClick={() => exportPayments(user.email)}
            >
              Export All Payments
            </Button>
          )}
          {
            isReassignPaymentToProject && (
              <Button
                disabled={!selectedPayment.length}
                onClick={() => setShowDrawer(true)}
              >
                Reassign
              </Button>
            )
          }
        </div>
      );
    }
    return null;
  };
  return (
    <div className={styles.PaymentTable}>
      {isLoading || loadingAllPayments ? (
        <LoadSpinner />
      ) : (
        <Table
          ref={null}
          data={tableData}
          columns={columns}
          disabled={false}
          emptyMessage="You have not sent any payments yet"
          paddingBottom={0}
          tableHeader={renderHeader()}
          rowDisplayName="Payments"
          page={page}
          totalRowCount={allPaymentsData?.count ?? 0}
          isDataForCurrentPage
          onSortDirChangeCallback={onSortDirChange}
          config={{
            allowSearch: false,
            configurableColumns: false,
            rowHeight: 58,
            pageSize: 100,
            striped: true,
            rowBorder: true,
            selectable: isReassignPaymentToProject,
          }}
          onSelectedDataChange={(payment:IPayment[]) => setSelectedPayment(payment)}
          onPageChangeCallback={onPageChange}
          initialColumnSort={{ [orderParams.orderBy]: orderParams.orderDirection }}
        />
      )}
      <Drawer
        size="large"
        title={(
          <div className={styles.drawerHeader}>
            <Avatar shape="square" className={styles.icon} icon={<DollarSignIcon style={{ margin: '0px' }} />} />
            Reassign
          </div>
        )}
        footer={(
          <div className={styles.drawerFooter}>
            <Button onClick={onCloseDrawer}>
              Cancel
            </Button>
            <Button type="primary" onClick={onSubmitReassign} loading={isReassigningPayment} disabled={!selectedCommunityId && !selectedProgramId}>
              Update
            </Button>
          </div>
        )}
        placement="right"
        onClose={onCloseDrawer}
        open={showDrawer}
      >
        <div className={styles.drawerBodyContainer}>
          <div className={styles.drawerBodyMain}>
            <div className={styles.desription}>
              <Title level={5}>Assign your payment</Title>
              <p>Set assignments for your payment.</p>
            </div>
            <div>
              <Form layout="vertical">
                <Form.Item label="Assign Project:">
                  <Select placeholder="Select Project" value={selectedProgramId} onChange={(e) => setSelectedProgramId(e)}>
                    {
                      programs.map((program) => <Option key={program.value} value={program.value}>{program.label}</Option>)
                    }
                  </Select>
                </Form.Item>
                <Form.Item label="Assign Group:">
                  <Select placeholder="Select Group" value={selectedCommunityId} onChange={(e) => setSelectedCommunityId(e)}>
                    {
                      communities.map((community) => <Option key={community.value} value={community.value}>{community.label}</Option>)
                    }
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
