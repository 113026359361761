import { findIndex } from 'lodash';

import { markDuplicates } from '../../../../utils';
import { TState } from '../types';

interface IAction {
    payoutId: number;
    memberId: number;
    type: 'UPDATE MEMBER PAYOUT_ID';
}
export type TUpdateMemberPayoutIdAction = Readonly<IAction>;

export const updateMemberPayoutId = (state: TState, action: TUpdateMemberPayoutIdAction): TState => {
    const payoutId = action.payoutId;
    const memberIndex = findIndex(state.members, (m) => m.id === action.memberId);
    if (memberIndex < 0) {
        // cannot find the member in the list..
        return state;
    }
    return {
        ...state,
        members: markDuplicates([
            ...state.members.slice(0, memberIndex),
            {
                ...state.members[memberIndex],
                payoutId,
            },
            ...state.members.slice(memberIndex + 1),
        ]),
    };
};
