import gql from 'graphql-tag';

import { AUTOMATION_BLUEPRINT_FRAGMENT } from './Automation';

export const AUTOMATION_TEMPLATE_FRAGMENT = gql`
  fragment AutomationTemplateFragment on AutomationTemplate {
    id
    blueprint {
      ...AutomationBlueprintFragment
    }
    isActive
    isSuggested
    isUsed
    createdDate
    updatedDate
  }
  ${AUTOMATION_BLUEPRINT_FRAGMENT}
`;
