import 'css-chunk:src/applications/ProductFulfillmentApp/pages/ConfirmationPage.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_gj4w7_157",
  "justify-content-space-between": "_justify-content-space-between_gj4w7_161",
  "tabular-nums": "_tabular-nums_gj4w7_165",
  "confirmationPage": "_confirmationPage_gj4w7_178",
  "embedded": "_embedded_gj4w7_182",
  "container": "_container_gj4w7_182",
  "scrollable": "_scrollable_gj4w7_188",
  "products": "_products_gj4w7_193",
  "title": "_title_gj4w7_216",
  "subTitle": "_subTitle_gj4w7_223",
  "section": "_section_gj4w7_231",
  "checkbox": "_checkbox_gj4w7_231",
  "checkboxLabel": "_checkboxLabel_gj4w7_237",
  "checkboxDetails": "_checkboxDetails_gj4w7_245",
  "boldText": "_boldText_gj4w7_251",
  "textarea": "_textarea_gj4w7_258",
  "memberRow": "_memberRow_gj4w7_261",
  "iconSquare": "_iconSquare_gj4w7_268",
  "productSection": "_productSection_gj4w7_280",
  "orders": "_orders_gj4w7_283",
  "order": "_order_gj4w7_283",
  "image": "_image_gj4w7_289",
  "details": "_details_gj4w7_294",
  "productTitle": "_productTitle_gj4w7_300",
  "sku": "_sku_gj4w7_306",
  "price": "_price_gj4w7_315",
  "priceDetails": "_priceDetails_gj4w7_327",
  "quantitySelect": "_quantitySelect_gj4w7_335",
  "customizeBtn": "_customizeBtn_gj4w7_338",
  "total": "_total_gj4w7_342",
  "removeButton": "_removeButton_gj4w7_351",
  "totals": "_totals_gj4w7_357",
  "totalSection": "_totalSection_gj4w7_357",
  "placeholder": "_placeholder_gj4w7_363",
  "totalDetails": "_totalDetails_gj4w7_366",
  "PopoverOverlay": "_PopoverOverlay_gj4w7_382",
  "popoverContent": "_popoverContent_gj4w7_386",
  "popoverTitle": "_popoverTitle_gj4w7_389",
  "popoverDetails": "_popoverDetails_gj4w7_397",
  "popoverRow": "_popoverRow_gj4w7_406",
  "inputWrapper": "_inputWrapper_gj4w7_409",
  "popoverInput": "_popoverInput_gj4w7_415",
  "prefix": "_prefix_gj4w7_439",
  "show": "_show_gj4w7_1"
};