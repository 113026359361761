import { TUseGetSocialAccountSettingsQuery, TUseGetAdAccountSettingsQuery } from '@frontend/app/hooks';
import {
  BrandedContentStatus,
  IGDMStatus,
  SocialAccountSettingsStatus,
} from '@frontend/app/types/globalTypes';

type SocialListeningDebug = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0]['social_listening_debug'];
type IGDMDebug = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0]['igdm_debug'];
type BrandedContentAdsDebug = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0]['branded_content_ads_debug'];
type TokenDebug = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0]['token_debug'];
type AdAccountDebug = TUseGetAdAccountSettingsQuery['data']['getSocialAccountClientSettings']['metaAdAccountData']['accounts'][0];

export enum CellStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  WARNING = 'WARNING',
  DISCONNECTED = 'DISCONNECTED',
  NA = 'NA',
}

export const composeSocialListeningDebug = (debug: SocialListeningDebug, token: TokenDebug): CellStatus => {
  if (debug.enabled === true) {
    if (token.status === SocialAccountSettingsStatus.ERROR) {
      return CellStatus.DISCONNECTED;
    }
    if (
      debug.status === SocialAccountSettingsStatus.WARNING
      || debug.status === SocialAccountSettingsStatus.ERROR
    ) {
      return CellStatus.WARNING;
    }
    if (debug.status === SocialAccountSettingsStatus.ACTIVE) {
      return CellStatus.ACTIVE;
    }
  }
  return CellStatus.NA;
};

export const composeDirectMessagingDebug = (debug: IGDMDebug, token: TokenDebug) => {
  if (
    debug.status === IGDMStatus.NOT_INSTALLED
    || debug.status === IGDMStatus.OTHER_CLIENT_CONNECTION
  ) {
    return CellStatus.NA;
  }
  if (!debug.enabled) {
    return CellStatus.INACTIVE;
  }
  if (token.status === SocialAccountSettingsStatus.ERROR) {
    return CellStatus.DISCONNECTED;
  }
  if (
    debug.status === IGDMStatus.WARNING
    || debug.status === IGDMStatus.ERROR
  ) {
    return CellStatus.WARNING;
  }
  if (debug.status === IGDMStatus.ACTIVE) {
    return CellStatus.ACTIVE;
  }
};

export const composeAdsPermissionsDebug = (debug: BrandedContentAdsDebug, token: TokenDebug) => {
  if (debug.status === BrandedContentStatus.OTHER_ACCOUNT_CONNECTED) {
    return CellStatus.NA;
  }
  if (!debug.enabled) {
    return CellStatus.INACTIVE;
  }
  if (token.status === SocialAccountSettingsStatus.ERROR) {
    return CellStatus.DISCONNECTED;
  }
  if (
    debug.status === BrandedContentStatus.WARNING
    || debug.status === BrandedContentStatus.ERROR
  ) {
    return CellStatus.WARNING;
  }
  return CellStatus.ACTIVE;
};

export const composeAdsInsightsDebug = (debug: AdAccountDebug) => {
  if (debug.status === SocialAccountSettingsStatus.ERROR) {
    return CellStatus.DISCONNECTED;
  }
  if (debug.status === SocialAccountSettingsStatus.WARNING) {
    return CellStatus.WARNING;
  }
  if (debug.status === SocialAccountSettingsStatus.ACTIVE) {
    return CellStatus.ACTIVE;
  }
};
