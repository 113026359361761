import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_REPORTING } from '../queries';
import {
  GetReporting,
  GetReportingVariables,
} from '../queries/types/GetReporting';

type IOptions = QueryHookOptions<GetReporting, GetReportingVariables>;

export const useGetReporting = (options: IOptions = {}) => (
  useQuery<GetReporting>(GET_REPORTING, options)
);
