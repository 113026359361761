import * as React from 'react';

const { useEffect, useRef } = React;

/**
 * Saves previous state/props value.
 */
export function usePrevious<T>(value: T): T {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
