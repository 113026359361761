import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_TOP_CREATORS_QUERY } from '../queries';
import { GetTopCreators, GetTopCreatorsVariables } from '../queries/types/GetTopCreators';

type IOptions = QueryHookOptions<GetTopCreators, GetTopCreatorsVariables>;

export function useGetTopCreators(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetTopCreators, GetTopCreatorsVariables>(
    GET_TOP_CREATORS_QUERY,
    {
      ...options,
    },
  );

  return {
    isTopCreatorsLoading: loading,
    topCreators: data?.creators,
    topCreatorsError: error,
    refetchTopCreators: refetch,
  };
}
