import {
  isEmpty,
  map,
} from 'lodash';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as qs from 'qs';
import { Notice } from '@affiliates/AspireUI';
import { GetOffersBySearchQuery_offers } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { SALES_APP_ID } from '@frontend/app/constants/applicationIds';

interface IProps {
  offers: GetOffersBySearchQuery_offers[];
  programId: number;
  workletSpecUri: string;
  taskId: string;
}

export const InfoNotice: React.FC<Readonly<IProps>> = (props) => {
  const { offers } = props;

  const location = useLocation();

  const renderMessage = () => {
    if (isEmpty(offers)) {
      return null;
    }

    return (
      <span>
        {map(offers, (offer) => {
          const pathname = `/app/${SALES_APP_ID}/offers/${offer.id}/details`;

          let offerVerbiage: string | undefined;
          if (!isEmpty(offer.links)) {
            offerVerbiage = 'links';
          } else if (!isEmpty(offer.promos)) {
            offerVerbiage = 'promo codes';
          }

          return (
            <React.Fragment key={offer.id}>
              <Link
                to={{ ...location, pathname }}
                target="_blank"
              >
                {offer.name}
              </Link>
              {' '}
              is the
              {' '}
              {offerVerbiage}
              {' '}
              offer associated for this project.
              {' '}
              You can change/edit the offer and view testing instructions
              {' '}
              <Link
                to={{
                  ...location,
                  pathname: `/app/${SALES_APP_ID}/offers/${offer.id}/confirmation`,
                  search: qs.stringify({
                    program_id: props.programId,
                    worklet_spec_uri: props.workletSpecUri,
                    task_id: props.taskId,
                  }),
                }}
              >
                here
              </Link>
              .
              {' '}
            </React.Fragment>
          );
        })}
        <>
          To monitor affiliate performance and make performance based payouts, visit the
          {' '}
          <Link
            to={{ ...location, pathname: `/app/${SALES_APP_ID}` }}
            target="_blank"
          >
            Sales Tracking App
          </Link>
          .
        </>
      </span>
    );
  };

  return (
    <Notice type="info" message={renderMessage()} />
  );
};
