._copyBtn_3u7ry_1 {
  margin-left: 8px;
  transform: translateY(-2px);
}

._offer_3u7ry_6 {
  font-size: 12px;
}

._noOffers_3u7ry_10 {
  padding: 8px;
  text-align: center;
}
._noOffers_3u7ry_10 span {
  font-size: 14px !important;
}