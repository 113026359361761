import 'css-chunk:src/components/pages/AnalyzePage/Filters/Filters.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1c1i9_157",
  "justify-content-space-between": "_justify-content-space-between_1c1i9_161",
  "tabular-nums": "_tabular-nums_1c1i9_165",
  "CheckBoxStyled": "_CheckBoxStyled_1c1i9_213",
  "LinkStyled": "_LinkStyled_1c1i9_219",
  "notice": "_notice_1c1i9_224",
  "isDisabled": "_isDisabled_1c1i9_229",
  "FilterBox": "_FilterBox_1c1i9_234",
  "FilterSet": "_FilterSet_1c1i9_242",
  "FilterRow": "_FilterRow_1c1i9_246",
  "filters": "_filters_1c1i9_250",
  "compareFilters": "_compareFilters_1c1i9_251",
  "filter": "_filter_1c1i9_250",
  "filterText": "_filterText_1c1i9_277",
  "filterCircle": "_filterCircle_1c1i9_281",
  "right": "_right_1c1i9_292",
  "disabled": "_disabled_1c1i9_300",
  "addFilterButton": "_addFilterButton_1c1i9_316",
  "compare": "_compare_1c1i9_251",
  "compareButton": "_compareButton_1c1i9_345",
  "compareIcon": "_compareIcon_1c1i9_352",
  "DateFilterPopover": "_DateFilterPopover_1c1i9_356",
  "NetworkFilterIcons": "_NetworkFilterIcons_1c1i9_360",
  "tiktok": "_tiktok_1c1i9_369",
  "blog": "_blog_1c1i9_372",
  "NetworkFilterLabelContainer": "_NetworkFilterLabelContainer_1c1i9_376",
  "NetworkFilterIconLabels": "_NetworkFilterIconLabels_1c1i9_382",
  "BrandFilterImages": "_BrandFilterImages_1c1i9_391",
  "MenuList": "_MenuList_1c1i9_398",
  "MenuItem": "_MenuItem_1c1i9_408",
  "selected": "_selected_1c1i9_421",
  "CheckboxMenuItem": "_CheckboxMenuItem_1c1i9_425",
  "customRange": "_customRange_1c1i9_442",
  "customRangeLabel": "_customRangeLabel_1c1i9_442",
  "datePicker": "_datePicker_1c1i9_452",
  "customRangeContent": "_customRangeContent_1c1i9_455",
  "dateRange": "_dateRange_1c1i9_462",
  "show": "_show_1c1i9_1"
};