import React, { useMemo } from 'react';
import {
 Button, Card, Col, Empty, Row, Skeleton, Space, Typography,
} from '@revfluence/fresh';
import { TabContainer } from '@frontend/app/containers/Settings/ProductFulfillment/Products/TabContainer/TabContainer';
import { BagShoppingIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetCatalogs } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useAuth } from '@frontend/context/authContext';
import { useApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { ApolloProvider } from '@apollo/client';
import { useGetAllCatalogStats } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetAllCatalogStats';
import { CatalogsList } from './CatalogsList';
import styles from './ProductCatalogs.scss';
import { ProductCatalogsProvider, useProductCatalogsContext } from './ProductCatalogsContext';
import { ConnectCatalogDrawer } from './ConnectCatalogDrawer';

const { Text, Title } = Typography;

const EmptyScreen = () => (
  <div className={styles.emptyScreen}>
    <Empty
      image={<BagShoppingIcon />}
      description={(
        <Space direction="vertical" size={0}>
          <Text>You have no catalogs added.</Text>
          <Text type="secondary">Select the catalogs for your project creators</Text>
        </Space>
      )}
    />
  </div>
);

export interface ProductCatalogsProps {
  programId: number;
}

export const ProductCatalogsInternal = ({ programId }: ProductCatalogsProps) => {
  const { catalogs, loading } = useGetCatalogs();
  const { allCatalogStats } = useGetAllCatalogStats();
  const { projectConfig } = useGetProjectConfigByType({
    variables: {
      projectId: programId,
      type: ProjectConfigType.ProductCatalog,
    },
    skip: !programId,
  });
  const { setIsDrawerOpen, setSelectedCatalogIds } = useProductCatalogsContext();

  const selectedCatalogs = useMemo(
    () => catalogs.filter((catalog) => projectConfig?.brandCatalogs?.includes(catalog.id)),
    [catalogs, projectConfig?.brandCatalogs],
  );

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
    setSelectedCatalogIds(projectConfig?.brandCatalogs?.map((id) => String(id)) || []);
  };

  return (
    <>
      <Space direction="vertical" className={styles.ProductCatalogs} size={0}>
        <Row align="middle" justify="space-between" className={styles.header}>
          <Col>
            <Space direction="vertical" size={0}>
              <Title level={4}>Product Fulfillment</Title>
              <Text className={styles.headerSubTitle}>
                Add catalogs to your project to send your creators a better and defined order submitting experience.
              </Text>
            </Space>
          </Col>
          <Col>
            <Button type="primary" onClick={handleOpenDrawer} icon={<PlusIcon />}>
              Connect Catalog
            </Button>
          </Col>
        </Row>
        <TabContainer>
          <Card>
            {loading && <Skeleton />}
            {!loading && (selectedCatalogs.length ? (
              <CatalogsList projectConfigId={projectConfig?.id} catalogs={selectedCatalogs} allCatalogStats={allCatalogStats} />
            ) : (
              <EmptyScreen />
            ))}
          </Card>
        </TabContainer>
      </Space>
      <ConnectCatalogDrawer programId={programId} />
    </>
  );
};

export const ProductCatalogs = ({ programId }: ProductCatalogsProps) => {
  const { token } = useAuth();
  const apolloClient = useApolloClient(token);

  return (
    <ApolloProvider client={apolloClient}>
      <ProductCatalogsProvider>
        <ProductCatalogsInternal programId={programId} />
      </ProductCatalogsProvider>
    </ApolloProvider>
  );
};
