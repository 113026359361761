import * as React from 'react';
import { cn } from '@/shadcn/lib/utils';

interface Tab {
  name: string;
  label: string;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  hasNotification?: boolean;
}

interface TabComponentProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (tabName: string) => void;
  className?: string;
  filter?: React.ReactNode;
  hideContent?: boolean;
}

const TabComponent = React.forwardRef<HTMLDivElement, TabComponentProps>(
  ({
 tabs, activeTab, onTabChange, className, filter, hideContent, ...props
}, ref) => (
  <div ref={ref} className={cn('w-full', className)} {...props}>
    <div className="flex flex-col md:flex-row md:justify-between px-8">
      <div className="flex space-x-2">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={cn(
                'relative flex items-center px-4 py-2 rounded-t-lg font-semibold text-sm focus:outline-none transition-colors overflow-hidden font-inter',
                activeTab === tab.name
                  ? 'text-primary bg-secondary'
                  : 'text-primary-foreground bg-transparent hover:primary-foreground',
                tab?.disabled ? 'cursor-not-allowed opacity-50' : '',
              )}
            onClick={() => onTabChange(tab.name)}
            disabled={tab?.disabled}
          >
            <span className="absolute inset-0 bg-secondary opacity-20 pointer-events-none" />
            <div className="relative">
              {tab.hasNotification && (
              <span className="bg-destructive w-1 h-1 -right-1 -top-0.5 absolute rounded-full" />
                )}
              {tab.icon && <span className="mr-2">{tab.icon}</span>}
              {tab.label}
            </div>
          </button>
          ))}
      </div>
      {filter && <div className="space-x-2 mt-2 md:mt-[-5px]">{filter}</div>}
    </div>
    {!hideContent && (
    <div className="mt-0">
      {tabs.map((tab) => activeTab === tab.name && <div key={tab.name}>{tab.content}</div>)}
    </div>
      )}
  </div>
  ),
);

TabComponent.displayName = 'TabComponent';

export default TabComponent;
