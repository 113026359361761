import gql from 'graphql-tag';

export const CONTENT_FIELD_WITH_PROGRAM_ID_FRAGMENT = gql`
  fragment ContentFieldWithProgramId on ContentFieldWithProgramId {
    canMarkNa
    clientId
    createdDate
    description
    fieldType
    id
    isMandatory
    name
    postTypes
    programIds
    selectOptions
    updatedDate
  }
`;
