/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fqee_157 {
  display: flex;
}

._justify-content-space-between_1fqee_161 {
  justify-content: space-between;
}

._tabular-nums_1fqee_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fqee_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._flexCol_1fqee_178 {
  display: flex;
  flex-direction: column;
}

._drawerHeader_1fqee_183 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  justify-content: center;
}

._drawerTitle_1fqee_192 {
  padding: 0.5rem 0rem;
}
._drawerTitle_1fqee_192 ._drawerTitleLeft_1fqee_195 {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
._drawerTitle_1fqee_192 ._drawerTitleLeft_1fqee_195 ._drawerTitleText_1fqee_201 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

._divider_1fqee_208 {
  margin: 0rem;
  padding: 0rem;
}

._mainContent_1fqee_213 {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
._mainContent_1fqee_213 ._description_1fqee_219 ._descriptionPrimary_1fqee_219 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  align-self: stretch;
}
._mainContent_1fqee_213 ._description_1fqee_219 ._descriptionSecondary_1fqee_226 {
  color: #505256;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  align-self: stretch;
}
._mainContent_1fqee_213 ._memberInfoCard_1fqee_234 {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  border: 1px solid var(--Neutral-Color-Palette-gray-4, #F0F0F0);
}
._mainContent_1fqee_213 ._memberInfoCard_1fqee_234 ._memberInfoItemContainer_1fqee_239 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0rem 0.5rem;
}
._mainContent_1fqee_213 ._memberInfoCard_1fqee_234 ._memberInfoItemContainer_1fqee_239 ._memberInfoItemTitle_1fqee_245 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
._mainContent_1fqee_213 ._memberInfoCard_1fqee_234 ._memberInfoItemContainer_1fqee_239 ._memberInfoItemValue_1fqee_252 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
._mainContent_1fqee_213 ._memberInfoCard_1fqee_234 ._memberInfoItemContainer_1fqee_239 ._memberInfoItemValueMargin_1fqee_259 {
  margin-left: 0.625rem;
}
._mainContent_1fqee_213 ._memberInfoCard_1fqee_234 ._memberInfoItemContainer_1fqee_239 ._memberInfoPromo_1fqee_262 {
  width: 90%;
  font-weight: 600;
}
._mainContent_1fqee_213 ._payoutSummaryContainer_1fqee_266 {
  font-size: 14px;
  padding: 1rem;
  border-radius: 6px;
  background: var(--Neutral-Color-Palette-gray-2, #FAFAFA);
  font-weight: 400;
}
._mainContent_1fqee_213 ._payoutSummaryContainer_1fqee_266 ._payoutSummaryDescriptionContainer_1fqee_273 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
._mainContent_1fqee_213 ._payoutSummaryContainer_1fqee_266 ._payoutSummaryDescriptionContainer_1fqee_273 ._payoutSummaryTitle_1fqee_278 {
  font-weight: 600;
}