import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const HamburgerIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 24">
    <circle cx="12.3184" cy="4" r="2" />
    <circle cx="12.3184" cy="12" r="2" />
    <circle cx="12.3184" cy="20" r="2" />
  </SvgIcon>
));

HamburgerIcon.displayName = 'HamburgerIcon';
