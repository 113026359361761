import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const HelpIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M12.2,16.9
      c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1S12.8,16.9,12.2,16.9z M13.7,11.6c-0.5,0.4-0.9,0.7-0.9,1.2
      c0,0.2,0.1,0.5,0.3,0.6l-1.5,0.4c-0.3-0.3-0.5-0.7-0.5-1.2c0-1,0.7-1.4,1.2-1.8c0.4-0.3,0.8-0.6,0.8-1C13.1,9.4,12.8,9,12,9
      c-0.7,0-1.3,0.4-1.7,0.9L9.2,8.6c0.7-0.9,1.9-1.4,3.1-1.4c1.8,0,2.9,0.9,2.9,2.2C15.2,10.6,14.4,11.2,13.7,11.6z"
    />
  </SvgIcon>
));

HelpIcon.displayName = 'HelpIcon';

export { HelpIcon };
