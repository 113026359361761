import 'css-chunk:src/components/widgets/SocialProfile/Card/Posts.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_9wsau_157",
  "justify-content-space-between": "_justify-content-space-between_9wsau_161",
  "tabular-nums": "_tabular-nums_9wsau_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_9wsau_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_9wsau_166",
  "Card": "_Card_9wsau_170",
  "tooltip": "_tooltip_9wsau_306",
  "cardHeader": "_cardHeader_9wsau_320",
  "tabs": "_tabs_9wsau_331",
  "tabButton": "_tabButton_9wsau_335",
  "activeTab": "_activeTab_9wsau_354",
  "separator": "_separator_9wsau_358",
  "cardTab": "_cardTab_9wsau_365",
  "cardTitle": "_cardTitle_9wsau_373",
  "visible": "_visible_9wsau_381",
  "show": "_show_9wsau_496",
  "noData": "_noData_9wsau_386",
  "ProfileCard": "_ProfileCard_9wsau_396",
  "hashtags": "_hashtags_9wsau_396",
  "AccountInfo": "_AccountInfo_9wsau_401",
  "image": "_image_9wsau_406",
  "info": "_info_9wsau_417",
  "username": "_username_9wsau_427",
  "location": "_location_9wsau_428",
  "stats": "_stats_9wsau_429",
  "ambIcon": "_ambIcon_9wsau_450",
  "icon": "_icon_9wsau_456",
  "verticalSeparator": "_verticalSeparator_9wsau_460",
  "checkCircleIcon": "_checkCircleIcon_9wsau_465",
  "starIcon": "_starIcon_9wsau_469",
  "Posts": "_Posts_9wsau_474",
  "carousel": "_carousel_9wsau_481",
  "content": "_content_9wsau_484",
  "recentPosts": "_recentPosts_9wsau_488",
  "sponsoredPosts": "_sponsoredPosts_9wsau_489",
  "popularPosts": "_popularPosts_9wsau_490",
  "showRecent": "_showRecent_9wsau_496",
  "showSponsored": "_showSponsored_9wsau_506",
  "showPopular": "_showPopular_9wsau_506",
  "carouselButtons": "_carouselButtons_9wsau_517",
  "recentButton": "_recentButton_9wsau_523",
  "sponsoredButton": "_sponsoredButton_9wsau_524",
  "popularButton": "_popularButton_9wsau_525",
  "active": "_active_9wsau_354",
  "youtube": "_youtube_9wsau_565",
  "grid": "_grid_9wsau_569",
  "PostsGrid": "_PostsGrid_9wsau_569",
  "Post": "_Post_9wsau_474",
  "blog": "_blog_9wsau_589",
  "facebook": "_facebook_9wsau_589",
  "instagram": "_instagram_9wsau_589",
  "twitter": "_twitter_9wsau_589",
  "masonry": "_masonry_9wsau_609",
  "masonryGrid": "_masonryGrid_9wsau_614",
  "postDetails": "_postDetails_9wsau_622",
  "noDataPulled": "_noDataPulled_9wsau_628",
  "thumbsDown": "_thumbsDown_9wsau_693"
};