import * as React from 'react';
import { find, has } from 'lodash';
import { Typography } from '@revfluence/fresh';
import { formatMessageDate } from '@frontend/app/utils';
import {
  GetMemberQuery_member as IMember,
} from '@frontend/app/queries/types/GetMemberQuery';
import {
  LazyImage,
} from '@components';
import { getMentionIGDMMessage } from '@frontend/app/utils/igdm';
import { IApplication, IMemberActivity } from './types';
import styles from './MemberIGDMActivityItem.scss';

const { useMemo } = React;
const { Text } = Typography;

interface IIGDMActivityItemHeaderProps {
  applications: IApplication[];
  instagramFieldId?: number;
  isStory: boolean;
  member: IMember;
  message: IMemberActivity;
}

const isSentByUser = (from: string, member: IMember, instagramFieldId?: number) => {
  const memberInstagramAccount = has(member.fields, instagramFieldId) ? `${member.fields[instagramFieldId]}` : '';
  return (from !== memberInstagramAccount);
};

export const IGDMActivityItemHeader: React.FC<IIGDMActivityItemHeaderProps> = ({
  applications, instagramFieldId, isStory, member, message,
}) => {
  const application = useMemo(() => find(applications, (app) => app.id === message.applicationId), [
    applications,
    message,
  ]);

  const title = useMemo(() => (isStory
    ? getMentionIGDMMessage(isSentByUser(message.payload?.from, member, instagramFieldId), message.payload.to[0], message.payload.from)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    : `@${message.payload.from}`), [message.payload, member, instagramFieldId, isStory, isSentByUser, getMentionIGDMMessage]);

  return (
    <div className={styles.header}>
      {message.applicationId
        ? application
          ? <LazyImage className={styles.appIcon} src={application.icon} />
          : <div className={styles.appIcon} />
        : <div className={styles.gmailIcon} />}
      <Text ellipsis className={styles.label}>
        {title}
      </Text>
      <div className={styles.date}>{formatMessageDate(message.internalDate)}</div>
    </div>
  );
};
