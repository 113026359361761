import * as React from 'react';
import styles from '@affiliates/components/OfferForm/CharCounter.scss';

interface IProps {
  charCount: number;
  maxCharCount: number;
}

export const CharCounter: React.FC<IProps> = (props) => {
  const { charCount, maxCharCount } = props;
  return (
    <span className={styles.charCounter}>
      {charCount}
      {' '}
      /
      {' '}
      {maxCharCount}
    </span>
  );
};
