import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_CATALOG_COLLECTION_BY_ID_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import {
  GetCatalogCollectionByIdQuery,
  GetCatalogCollectionByIdQueryVariables,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/GetCatalogCollectionByIdQuery';
import { useMemo } from 'react';

type IOptions = QueryHookOptions<GetCatalogCollectionByIdQuery, GetCatalogCollectionByIdQueryVariables>;

export const useGetCatalogCollectionByIdQuery = (options: IOptions) => {
  const {
 loading, data, error, refetch,
} = useQuery(GET_CATALOG_COLLECTION_BY_ID_QUERY, options);

  return {
    loading,
    collectionItem: useMemo(
      () =>
        data?.getCatalogCollectionsById
        ?? ({
          catalogProducts: [],
        } as GetCatalogCollectionByIdQuery['getCatalogCollectionsById']),
      [data?.getCatalogCollectionsById],
    ),
    error,
    refetch,
  };
};
