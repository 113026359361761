import gql from 'graphql-tag';
import { HIGHLIGHT_FRAGMENT } from './fragments/HighlightFragment';

export const HIGHLIGHTS_QUERY = gql`
  query HighlightsQuery {
    highlights {
      ...HighlightFragment
    }
  }
  ${HIGHLIGHT_FRAGMENT}
`;
