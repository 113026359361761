import * as React from 'react';
import { upperFirst } from 'lodash';
import { RawDraftContentState } from 'draft-js';

import { IMember } from '@frontend/app/hooks';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { IOrderRequestStatus } from '../../utils';

import styles from './OrderRequestReviewEmail.scss';
import { CPSEmailComposer } from '../../../EmailComposer/EmailComposer';

interface IProps {
  member: IMember;
  orderRequestStatus: IOrderRequestStatus;
  customSend(messageParams: SendMessageInput): void | Promise<void>;
  getInitialEmailState(): [string | null, RawDraftContentState | null];
}

export const OrderRequestReviewEmail: React.FC<IProps> = (props) => {
  const {
    member,
    orderRequestStatus,
    customSend,
    getInitialEmailState,
  } = props;

  const memberIds = [member.id];

  return (
    <div className={styles.MessageComposer}>
      <CPSEmailComposer
        className={styles.EmailComposer}
        memberIds={memberIds}
        getInitialState={getInitialEmailState}
        customSend={customSend}
        sendButtonText={`${upperFirst(orderRequestStatus)} request and send email`}
      />
    </div>
  );
};
