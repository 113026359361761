import gql from 'graphql-tag';
import { ONBOARDING_TEMPLATE_FRAGMENT, USER_FRAGMENT } from './fragments';

import { PROGRAM_FRAGMENT } from './fragments/ProgramFragment';

export const SAVE_PROGRAM = gql`
  mutation SaveProgramMutation($program: ProgramInput!) {
    program: saveProgram(program: $program) {
      ...ProgramFragment
      description
      splashImageUrl
      status
      archivedByUser {
        ...UserFragment
      }
      archivedDate
      deletedDate
      communityId
      published
      submittedForReview
      legacyLandingPage
      customLandingPagePath
      emailTemplateId
      templateName
      applicationPageTemplateName
      columns {
        memberFieldSchemaIds
        dbColumns
      }
      applicationFormFields {
        memberFieldSchemas {
          schemaId
          required
          label
          order
        }
        dbColumns {
          name
          required
          label
          order
        }
      }
      onboardingTemplateConfig {
        ...OnboardingTemplateFragment
      }
    }
  }
  ${PROGRAM_FRAGMENT}
  ${ONBOARDING_TEMPLATE_FRAGMENT}
  ${USER_FRAGMENT}
`;
