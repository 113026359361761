/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ggs27_157 {
  display: flex;
}

._justify-content-space-between_ggs27_161 {
  justify-content: space-between;
}

._tabular-nums_ggs27_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ggs27_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._list_ggs27_178 ._item_ggs27_178 {
  padding: 0.5rem 0.625rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}
._list_ggs27_178 ._item_ggs27_178:hover {
  background-color: #e6edf2;
}
._list_ggs27_178 ._item_ggs27_178 ._checkmark_ggs27_188 {
  color: #3996e0;
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
._list_ggs27_178 ._item_ggs27_178 ._noValueLabel_ggs27_195 {
  color: #8f8d91;
}

._dropDownArrow_ggs27_199 {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: #8f8d91;
}