import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_UNVIEWED_WORKITEM_COUNT_FOR_PROJECT_QUERY } from '@frontend/app/queries';
import { GetUnViewedWorkItemCountForProject, GetUnViewedWorkItemCountForProjectVariables } from '../queries/types/GetUnViewedWorkItemCountForProject';

type IOptions = QueryHookOptions<
  GetUnViewedWorkItemCountForProject,
  GetUnViewedWorkItemCountForProjectVariables
>;

export const useGetUnViewedWorkItemCountForProjectQuery = (options: IOptions = {}) => (
  useQuery<GetUnViewedWorkItemCountForProject, GetUnViewedWorkItemCountForProjectVariables>(
    GET_UNVIEWED_WORKITEM_COUNT_FOR_PROJECT_QUERY,
    options,
  )
);
