import * as React from 'react';

const { useEffect, useState } = React;

export const fetchFilterData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export function useFetchFilterData(
  url: string,
  setFilters: any,
  action: string,
) {
  // const [loading, setLoading] = useState(false);
  // const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // setLoading(true);
    fetchFilterData(url)
      .then((payload) => setFilters({ type: action, payload }))
      .catch(setError);
    // .finally(() => setLoading(false));

    // eslint-disable-next-line
  }, [setFilters]);

  return {
    error,
  };
}
