import * as React from 'react';
import { Space } from 'antd';
import { filter, keys } from 'lodash';

import { Button as WidgetsButton } from '@components';

import { Typography, Button } from '@affiliates/AspireUI';
import { IEnabledOfferSources } from '@affiliates/types';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { TOnClickAddOffer } from './types';

import styles from './GetStarted.scss';

interface IProps {
  onClickCreate: TOnClickAddOffer;
  sources: IEnabledOfferSources;
}

const AddOfferButtonOrder: OFFER_SOURCE[] = [
  OFFER_SOURCE.SHOPIFY,
  OFFER_SOURCE.TUNE,
];
const AddOfferButtonLabels = {
  [OFFER_SOURCE.TUNE]: 'Create Offer with Link Tracking',
  [OFFER_SOURCE.SHOPIFY]: 'Create Offer with Promo Codes',
};

export const GetStarted: React.FC<Readonly<IProps>> = ({
  onClickCreate,
  sources,
}) => {
  const enabledSources: OFFER_SOURCE[] = filter(
    keys(sources), (k: OFFER_SOURCE): k is OFFER_SOURCE => !!sources[k],
  );
  const renderButtons = (): React.ReactNode => {
    if (enabledSources.length === 1) {
      return (
        <Button
          className={styles.button}
          onClick={onClickCreate[enabledSources[0]]}
          shape="round"
          type="primary"
        >
          Create Offer
        </Button>
      );
    }
    return AddOfferButtonOrder.map((source) => {
      if (!sources[source]) {
        return null;
      }

      const label = AddOfferButtonLabels[source];
      if (!label) {
        return null;
      }

      return (
        <WidgetsButton
          className={styles.button}
          key={source}
          label={label}
          onClick={onClickCreate[source]}
          theme="info"
        />
      );
    });
  };

  return (
    <div className={styles.GetStarted}>
      <Space className={styles.space} direction="vertical" align="center">
        <img alt="Get Started" src="//storage.googleapis.com/aspirex-affiliates/GetStartedImage.png" />
        <Typography.Title className={styles.title}>
          Create an Offer
        </Typography.Title>
        <Typography.Paragraph className={styles.text}>
          Generate unique affiliate links to track clicks, conversions, and
          sales by creating an offer.
        </Typography.Paragraph>
      </Space>
      <div className={styles.buttonWrapper}>
        {renderButtons()}
      </div>
    </div>
  );
};
