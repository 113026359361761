/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_5pkwz_157 {
  display: flex;
}

._justify-content-space-between_5pkwz_161 {
  justify-content: space-between;
}

._tabular-nums_5pkwz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_5pkwz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_5pkwz_178 ._header_5pkwz_178 ._title_5pkwz_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_5pkwz_178 ._header_5pkwz_178 ._title_5pkwz_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_5pkwz_157 {
  display: flex;
}

._justify-content-space-between_5pkwz_161 {
  justify-content: space-between;
}

._tabular-nums_5pkwz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_5pkwz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_5pkwz_178 ._header_5pkwz_178 ._title_5pkwz_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_5pkwz_178 ._header_5pkwz_178 ._title_5pkwz_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._content_5pkwz_178 {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
._content_5pkwz_178 ._header_5pkwz_178 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._content_5pkwz_178 ._header_5pkwz_178 ._title_5pkwz_178 {
  display: flex;
  flex-direction: column;
}
._content_5pkwz_178 ._header_5pkwz_178 ._title_5pkwz_178 h3 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  color: #2e426d;
}
._content_5pkwz_178 ._header_5pkwz_178 ._title_5pkwz_178 h4 {
  margin: 0 0 0.5rem 0.5rem;
  color: #8f8d91;
}
._content_5pkwz_178 ._header_5pkwz_178 ._select_5pkwz_451 {
  margin-left: auto;
}

._tooltipBody_5pkwz_455 {
  max-width: 12.5rem;
}

._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 {
  display: flex;
  flex: 0 0 auto;
  padding: 2.5rem;
  min-width: 25%;
  flex-direction: column;
  align-items: center;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._icon_5pkwz_472 {
  margin-bottom: 1.25rem;
  flex-shrink: 0;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._name_5pkwz_476 {
  /*@extend %Headline-6-Style;*/
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center;
  line-height: 120%;
  margin-bottom: 1.25rem;
  flex-shrink: 0;
  color: #1a1818;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._info_5pkwz_486 {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.6875rem;
  line-height: 1;
  letter-spacing: 0.078125rem;
  text-transform: uppercase;
  color: #4f4f4f;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._info_5pkwz_486 ._infoItem_5pkwz_497 {
  padding: 0 0.75rem;
  margin: 0.125rem 0;
  flex-shrink: 0;
  height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._info_5pkwz_486 ._infoItem_5pkwz_497:nth-child(odd) {
  background-color: white;
  box-shadow: 0rem 0.25rem 3.75rem rgb(225, 227, 232);
  border-radius: 6.25rem;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._info_5pkwz_486 ._infoItem_5pkwz_497 ._key_5pkwz_512 {
  width: 50%;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._info_5pkwz_486 ._infoItem_5pkwz_497 ._key_5pkwz_512._withTooltip_5pkwz_515 {
  cursor: help;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._info_5pkwz_486 ._infoItem_5pkwz_497 ._value_5pkwz_518 {
  margin-left: auto;
  color: #3996e0;
  font-weight: 500;
  letter-spacing: 0;
}
._PostTypeSummarySection_5pkwz_459 ._postGrid_5pkwz_459 ._item_5pkwz_464 ._info_5pkwz_486 ._infoItem_5pkwz_497 ._value_5pkwz_518._compare_5pkwz_524 {
  color: #ef5682;
}

@media only screen and (max-width: 1190px) {
  ._item_5pkwz_464 {
    width: 33%;
  }
}
@media only screen and (max-width: 930px) {
  ._item_5pkwz_464 {
    width: 50%;
  }
}
@media only screen and (max-width: 595px) {
  ._item_5pkwz_464 {
    width: 100%;
  }
}