import * as React from 'react';
import { trim } from 'lodash';

import { Button, Typography, message } from '@revfluence/fresh';

import { OFFER_TRACKING_TYPE } from '@affiliates/types/globalTypes';
import { logger } from '@common';

import { CopyIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './ConversionTracking.scss';

const { Link, Paragraph, Title } = Typography;

const { Fragment, useMemo } = React;

interface IProps {
  offerTrackingType: OFFER_TRACKING_TYPE;
  code: string;
}

const IFRAME_INSTRUCTIONS = (
  <>
    <Paragraph>
      Copy and paste the code snippet below to place on your store&rsquo;s order confirmation page.
      See this
      {' '}
      <Link
        href="https://intercom.help/aspireiq_elevate/en/articles/4619656-how-can-i-add-a-tracking-pixel-to-my-e-commerce-store"
        rel="noopener noreferrer"
        target="_blank"
      >
        Help Article
      </Link>
      {' '}
      for directions.
    </Paragraph>
    <Paragraph>
      <Title level={4}>Important:</Title>
      {' '}
      In order to track a sales amount, you need to
      replace the
      {' '}
      <strong>AMOUNT</strong>
      {' '}
      text with your store&rsquo;s tag for order
      or sale amount. An example code (for Shopify) would look like:
      <br />
      <strong>
        {'{{'}
        {' '}
        total_price | money_without_currency
        {' '}
        {'}}'}
      </strong>
    </Paragraph>
    <Paragraph>
      To confirm if your sales pixel has been successfully implemented, please follow the steps in this
      {' '}
      <Link
        href="https://intercom.help/aspireiq_elevate/en/articles/5968850-how-do-i-confirm-if-my-sales-pixel-was-successfully-implemented"
        rel="noopener noreferrer"
        target="_blank"
      >
        Help Article
      </Link>
      .
    </Paragraph>
  </>
);

const POSTBACK_TRANSACTION_INSTRUCTIONS = (
  <>
    <Paragraph>
      Directions to your website&rsquo;s web developer: You must call the
      postback URL below after successful completion of the offer to track a
      conversion.
    </Paragraph>
    <Paragraph>
      <Title level={4}>Important:</Title>
      {' '}
      You must replace
      {' '}
      <strong>TRANSACTION_ID</strong>
      {' '}
      with the transaction_id
      {' '}
      that was passed to your landing page in the
      offer URL and replace
      {' '}
      <strong>AMOUNT</strong>
      {' '}
      with the amount of the sale.
    </Paragraph>
    <Paragraph>
      The conversion is recorded once your webpage hits the postback URL.
    </Paragraph>
  </>
);

const POSTBACK_AFFILIATE_INSTRUCTIONS = (
  <>
    <Paragraph>
      Directions to your website&rsquo;s web developer: You must call the
      postback URL below after successful completion of the offer to track a
      conversion.
    </Paragraph>
    <Paragraph>
      You must replace
      {' '}
      <strong>AFFILIATE_ID</strong>
      {' '}
      with the affiliate_id
      {' '}
      that was passed to your landing page in the
      offer URL and replace
      {' '}
      <strong>AMOUNT</strong>
      {' '}
      with the amount of the sale.
    </Paragraph>
    <Paragraph>
      The conversion is recorded once your webpage hits the postback URL.
    </Paragraph>
  </>
);
const JAVASCRIPT_POSTBACK_INSTRUCTIONS = (
  <>
    <Paragraph>
      To track conversions on your website, you need to set up conversion tracking. If you're using Javascript postback, follow the instruction to add your
      <Link href="https://intercom.help/aspireiq_elevate/en/collections/3990852-javascript-postback-tracking" target="_blank"> Javascript postback. </Link>
      Add this script once, and it will automatically work for all offer links created in Aspire using Javascript postback.
    </Paragraph>
    <Paragraph>
      <Title level={4}>Important:</Title>
      {' '}
      You must replace
      {' '}
      <strong>TRANSACTION_ID</strong>
      {' '}
      with the transaction_id
      {' '}
      that was passed to your landing page in the
      offer URL and replace
      {' '}
      <strong>AMOUNT</strong>
      {' '}
      with the amount of the sale.
    </Paragraph>
    <Paragraph>
      The conversion is recorded once your webpage hits the postback URL.
    </Paragraph>
  </>
);

export const ConversionTracking: React.FC<IProps> = (props) => {
  const instructions = useMemo((): React.ReactNode => {
    switch (props.offerTrackingType) {
      case OFFER_TRACKING_TYPE.HTTPS_IMAGE_PIXEL:
      case OFFER_TRACKING_TYPE.HTTPS_IFRAME_PIXEL:
        return IFRAME_INSTRUCTIONS;
      case OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID:
        return POSTBACK_AFFILIATE_INSTRUCTIONS;
      case OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID:
        return POSTBACK_TRANSACTION_INSTRUCTIONS;
      case OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK:
        return JAVASCRIPT_POSTBACK_INSTRUCTIONS;
      default:
        logger.warn('Unhandled offer tracking type.');
        return null;
    }
  }, [props.offerTrackingType]);
  const code = useMemo(() => props.code.split('\n').map((line, lineIndex) => (
    <Fragment key={`line-${lineIndex}`}>
      {trim(line)}
      <br />
    </Fragment>
  )), [props.code]);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.code)
      .then(() => {
        message.success('Copied to clipboard');
      }).catch((_) => {
        message.error('Failed to copy to clipboard');
      });
  };
  return (
    <div className={styles.ConversionTracking}>
      <Paragraph className={styles.description}>
        {instructions}
      </Paragraph>
      <div className={styles.codeBlock}>
        <div className={styles.code}>
          {code}
        </div>
      </div>
      <div className={styles.copySnippet}>
        <Button type="primary" icon={<CopyIcon />} onClick={handleCopy}>Copy Snippet</Button>
      </div>
    </div>
  );
};
