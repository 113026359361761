import { useMutation, MutationHookOptions } from '@apollo/client';

import { UPDATE_PROJECT_CONFIG_MUTATION } from '../queries';
import {
  UpdateProjectConfigMutation,
  UpdateProjectConfigMutationVariables,
} from '../queries/types/UpdateProjectConfigMutation';

type IOptions = MutationHookOptions<UpdateProjectConfigMutation, UpdateProjectConfigMutationVariables>;

export function useUpdateProjectConfig(options: IOptions = {}) {
  const [updateProjectConfig, { loading, error }] = useMutation<
  UpdateProjectConfigMutation,
  UpdateProjectConfigMutationVariables
  >(UPDATE_PROJECT_CONFIG_MUTATION, options);

  return {
    updateProjectConfig,
    loading,
    error,
  };
}
