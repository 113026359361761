import 'css-chunk:src/components/pages/SearchPage/TextSearchFilters/InvitesResourcesPicker.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "wrapper": "_wrapper_gjdrw_32",
  "title": "_title_gjdrw_36",
  "label": "_label_gjdrw_40",
  "icon": "_icon_gjdrw_45",
  "resourceRow": "_resourceRow_gjdrw_50",
  "resourceIcon": "_resourceIcon_gjdrw_55",
  "invalidResource": "_invalidResource_gjdrw_62",
  "invalidResourceLabel": "_invalidResourceLabel_gjdrw_71",
  "resourceContent": "_resourceContent_gjdrw_74",
  "select": "_select_gjdrw_77",
  "resourceLabel": "_resourceLabel_gjdrw_80"
};