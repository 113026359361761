/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_yepd9_157 {
  display: flex;
}

._justify-content-space-between_yepd9_161 {
  justify-content: space-between;
}

._tabular-nums_yepd9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_yepd9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_yepd9_157 {
  display: flex;
}

._justify-content-space-between_yepd9_161 {
  justify-content: space-between;
}

._tabular-nums_yepd9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_yepd9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._IncludeSelect_yepd9_355 ._content_yepd9_355 {
  overflow-y: auto;
  max-height: 15rem;
}