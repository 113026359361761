import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { lowerCase } from 'lodash';

import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import { BrandAuthFailure } from '@frontend/applications/SocialPostApp/components/BrandAuthFailure';
import { BrandAuthSuccess } from '@frontend/applications/SocialPostApp/components/BrandAuthSuccess';

interface IRouteParams {
  clientId: string;
}
export const BrandAuth = () => {
  const history = useHistory();
  const insightsArticle = 'https://intercom.help/aspireiq_elevate/en/articles/4499822-instagram-insights-faq';
  const { clientId } = useParams<IRouteParams>();
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const igSuccess = lowerCase(query.get('ig_success')) === 'true';
  const clientRedirect = () => history.push(`/client/${clientId}`);
  const clientFailRedirect = () => history.push(`/client/${clientId}/settings/${SOCIAL_POST_APP_ID}`);
  const heroImage = 'https://aspire.io/wp-content/uploads/2021/11/ShopifyBrands_BlogThumbnail-768x461.jpeg';
  const logoImage = 'https://storage.googleapis.com/aspirex-program-onboarding/AspireIQ%20Marketing%20Team/aiq_qhitebackground.png';

  return (
    <>
      {
        igSuccess
          ? <BrandAuthSuccess clientRedirect={clientRedirect} insightsArticle={insightsArticle} heroImage={heroImage} logoImage={logoImage} />
          : <BrandAuthFailure clientFailRedirect={clientFailRedirect} insightsArticle={insightsArticle} heroImage={heroImage} logoImage={logoImage} />
      }
    </>
  );
};
