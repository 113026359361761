import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import ContractsListPage from './ContractsListPage';
import ContractSignersPage from './ContractSignersPage';

interface IProps {
  refreshUi: boolean;
}

const ContractAppDashboard: React.FunctionComponent<IProps> = ({ refreshUi }) => {
  const navSettings = [
    {
      route: 'reports',
      displayName: 'My Contracts',
      component: ContractsListPage,
    },
    {
      route: 'settings',
      displayName: 'Settings',
      component: ContractSignersPage,
    },
  ];

  const application = {
    displayName: 'Contracts',
    iconUrl: 'https://storage.googleapis.com/aspirex-static-files/contracts.svg',
  };

  return (
    <>
      {refreshUi ? (
        <>
          <AppHeader
            title={application.displayName}
            // imgIconUrl={application.iconUrl}
          />
          <div className="px-6" style={{ padding: 24 }}>
            <ContractsListPage />
          </div>
        </>
      ) : (
        <AppDashboardNav
          application={application}
          navLinks={navSettings}
          hideTabs
          defaultRoute="reports"
        />
      )}
    </>
  );
};

export default ContractAppDashboard;
