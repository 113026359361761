import * as React from 'react';
import {
 Switch, useRouteMatch, Route,
} from 'react-router-dom';

import { NewMemberDrawer } from './NewMemberDrawer';

export const MemberDrawer = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/member/:memberId`}
        render={(routeProps) => (
          <NewMemberDrawer
            memberId={routeProps.match.params.memberId}
            backUrl={`${match.url}${routeProps.location.search}`}
            isDeliverablesHidden
          />
          )}
      />
    </Switch>
    );
};
