export const getTrendColor = (color) => {
  switch (color) {
    case 'red':
      return 'text-destructive';
    case 'gray':
      return 'text-neutral';
    default:
      return 'text-success';
  }
};

export const getBackgroundColor = (color) => {
  const colorClasses = {
    green: 'bg-success',
    red: 'bg-danger',
    gray: 'bg-neutral',
  };

  return colorClasses[color] || 'bg-neutral';
};
