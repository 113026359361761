._RelationshipSection_1vlcq_1 ._selectGroup_1vlcq_1 {
  width: 100%;
  margin-top: 20px;
}
._RelationshipSection_1vlcq_1 ._selectGroup_1vlcq_1 ._selectGroupTitle_1vlcq_5 {
  margin-bottom: 8px;
  color: var(--gray-9);
  display: flex;
  align-items: center;
  gap: 4px;
}
._RelationshipSection_1vlcq_1 ._selectGroup_1vlcq_1 ._selectGroupTitle_1vlcq_5 ._icon_1vlcq_12 {
  color: var(--gray-7);
  font-size: 12px;
}
._RelationshipSection_1vlcq_1 ._selectGroup_1vlcq_1 ._select_1vlcq_1 {
  width: 100%;
}

._groupTitle_1vlcq_20 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px;
  font-size: 14px;
}
._groupTitle_1vlcq_20 strong {
  color: var(--gray-9);
  font-weight: 600;
}

.relationships-popup {
  max-width: 320px;
}
.relationships-popup .ant-select-item-option-content {
  overflow: initial;
  white-space: initial;
}