import { useQuery, QueryHookOptions } from '@apollo/client';

import { REQUIREMENT_DETAIL } from '../queries/RequirementDetailQuery';
import {
  RequirementDetailQuery,
  RequirementDetailQueryVariables,
} from '../queries/types/RequirementDetailQuery';

type IOptions = QueryHookOptions<RequirementDetailQuery, RequirementDetailQueryVariables>;

export const useRequirementDetailQuery = (requirementId: number, options: IOptions = {}) => useQuery<RequirementDetailQuery, RequirementDetailQueryVariables>(REQUIREMENT_DETAIL, {
    ...options,
    variables: {
      id: requirementId,
    },
    skip: !requirementId || options.skip,
  });
