export const parseUrl = (url: string) => {
  try {
    if (!url.startsWith('http://') || !url.startsWith('https://')) {
      url = `https://${url}`;
    }

    return new URL(url);
  } catch {
    return null;
  }
};

/**
 * Validates a URL using a regular expression.
 *
 * @param url The URL to be validated.
 * @returns True if the URL is valid, false otherwise.
 */
export const validateUrl = (url: string): boolean => {
  const urlRegex = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(url);
};
