import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { isFunction, isNumber } from 'lodash';

import { Handle } from './Handle';
import { RangeInput } from './RangeInput';
import { Track } from './Track';
import { positionInRangeRoundedToNearestStep } from './util';

const { useCallback, useState, useEffect } = React;
import styles from './RangeSlider.scss';

export interface IRangeSliderProps {
  className?: string;
  defaultValue?: number;
  description?: string | JSX.Element;
  formatStr?: string;
  label?: string | JSX.Element;
  max: number;
  min: number;
  onChange?(newValue: number);
  onStoppedDragging?(currentValue: number);
  showInput?: boolean;
  showPlusOnMaxRange?: boolean;
  snapped?: boolean;
  step: number;
  subClassNames?: IRangeSliderClasses;
}

export interface IRangeSliderClasses {
  description?: string;
  input?: string;
  maxInput?: string;
  minInput?: string;
}

/**
 * Range Slider
 */
export const RangeSlider: React.FunctionComponent<IRangeSliderProps> = (props) => {
  const {
    className,
    defaultValue = props.min,
    description,
    formatStr,
    label,
    max,
    min,
    onChange,
    onStoppedDragging,
    showInput,
    showPlusOnMaxRange,
    snapped,
    step,
    subClassNames,
  } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(defaultValue);
  const [previousValue, setPreviousValue] = useState(defaultValue);

  useEffect(() => {
    setPosition(defaultValue);
    setPreviousValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback((newPosition) => {
    const newValue = positionInRangeRoundedToNearestStep(newPosition, step, min, max);

    if (snapped) {
      if (previousValue !== newValue) {
        setPosition(newValue);
      }
    } else {
      setPosition(newPosition);
    }

    if (previousValue !== newValue) {
      setPreviousValue(newValue);
      if (newValue) {
        onChange(newValue);
      }
    }
  }, [max, min, step, onChange, previousValue, snapped]);

  const handleStartDragging = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleStoppedDragging = useCallback(() => {
    if (onStoppedDragging) {
      setTimeout(() => onStoppedDragging(previousValue), 0);
    }
    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging, onStoppedDragging, previousValue, setIsDragging]);

  const handleInputChange = useCallback((value) => {
    const numberValue = numeral(value).value();
    const newValue = positionInRangeRoundedToNearestStep(
      isNumber(numberValue) ? numberValue : previousValue,
      step,
      min,
      max,
    );

    setPreviousValue(newValue);
    setPosition(newValue);
    if (isFunction(onChange)) {
      onChange(newValue);
    }
  }, [max, min, onChange, previousValue, step]);

  return (
    <div className={cx(className, styles.RangeSlider, {
      [styles.isDragging]: isDragging,
    })}
    >
      <div className={styles.label}>{label}</div>
      <Track
        className={cx({ [styles.isDragging]: isDragging })}
        min={min}
        max={max}
        range={[min, position]}
        onClickPosition={handleChange}
      >
        <Handle
          min={min}
          max={max}
          position={position}
          onChangePosition={handleChange}
          onStartDragging={handleStartDragging}
          onStoppedDragging={handleStoppedDragging}
        />
      </Track>
      <div className={cx(styles.description, {
        [styles.descriptionWithInput]: showInput,
        [styles.noDescription]: !description,
      })}
      >
        {showInput
          && (
          <RangeInput
            className={subClassNames.input || subClassNames.maxInput}
            max={max}
            onChange={handleInputChange}
            value={previousValue}
            showPlusOnMaxRange={showPlusOnMaxRange}
            formatStr={formatStr}
          />
)}
        {description}
      </div>
    </div>
  );
};

RangeSlider.defaultProps = {
  description: '',
  label: '',
  showInput: false,
  showPlusOnMaxRange: false,
  snapped: false,
  subClassNames: {},
};
RangeSlider.displayName = 'RangeSlider';
