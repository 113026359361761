import { TState } from '../types';

interface IAction {
  type: 'DISMISS NO MEMBERS WARNING';
}
export type TDismissNoMembersWarningAction = Readonly<IAction>;

export const dismissNoMembersWarning = (state: TState, _action: TDismissNoMembersWarningAction): TState => {
  if (state.showNoMembersWarning === false) {
    return state;
  }
  return {
    ...state,
    showNoMembersWarning: false,
  };
};
