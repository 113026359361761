import gql from 'graphql-tag';

const GET_BUDGET_PERIOD_DETAILS = gql`
    query GetBudgetPeriodDetails ($budgetAccountId: Int,$programId:Int) {
        periodDetails: getBudgetPeriodInfoForPayment (budgetAccountId: $budgetAccountId, programId: $programId) {
            fiscalYear
            fiscalYearLabel
            fiscalYearPeriodDefinitionId
            granularity
            quarterDistributions {
                quarterKey
                quarterLabel
                quarterPeriodDefinitionId
            }
        }
    }
`;

export default GET_BUDGET_PERIOD_DETAILS;
