/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
 useEffect, useMemo, useRef, useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { chain, isString } from 'lodash';

import { LoadSpinner } from '@components';
import { UserAvatar } from '@frontend/app/components';

import { GetOrdersQuery_orders } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetOrdersQuery';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@frontend/shadcn/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { useGetOrdersForDashboard } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrdersForDashboard';
import {
 BadgeGroup, DataTable, Empty, SelectWithInput, WidgetContainer,
} from '@frontend/app/refresh-components';
import { BoxOpenIcon } from '@revfluence/fresh-icons/regular/esm';
import { pluralize } from '../utils/strings';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';
import { useQueryParams } from '../hooks';

const styles = {} as any;

const PAGE_SIZE = 40;

export interface IColumn {
  schemaId?: number;
  headerName: string;
  field: string;
  type: string;
  editable?: boolean;
  showInFilters?: boolean;
  showInEditColumns?: boolean;
  choices?: string[];
}

export type IOrders = GetOrdersQuery_orders & {
  id: string;
  member: any;
  products: any;
  _sortableFields: {
    member: string;
    products: string;
    raw: string;
    shipmentTracking: string;
    fulfillmentStatus: string;
  };
};

const renderNameField = (member) => (
  <div className="flex items-center gap-2">
    <UserAvatar name={member.name} className={styles.avatar} />
    <NavLink to={(location) => ({ ...location, pathname: `/members/${member.id}` })}>
      <div className={(styles as any).name}>{member.name || ''}</div>
    </NavLink>
  </div>
);

const customSort = (aValue: string | number, bValue: string | number) => {
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};

const getOrderProducts = (order: GetOrdersQuery_orders): any[] => {
  switch (order.type) {
    case ProjectConfigType.NoCatalogue:
      return order.details?.no_catalogue_line_items || [];
    case ProjectConfigType.Shopify:
    case ProjectConfigType.ProductCatalog:
    default:
      return order.raw?.line_items || [];
  }
};

const getOrderProductsString = (order: GetOrdersQuery_orders): string => {
  const products = getOrderProducts(order);

  let productNames: string[] = [];
  switch (order.type) {
    case ProjectConfigType.NoCatalogue:
      productNames = chain(products)
        .map((lineItem) => lineItem?.productName)
        .filter((n): n is string => isString(n))
        .value();
      break;
    case ProjectConfigType.Shopify:
    case ProjectConfigType.ProductCatalog:
    default:
      productNames = chain(products)
        .map((lineItem) => lineItem?.name)
        .filter((n): n is string => isString(n))
        .value();
      break;
  }

  return productNames.join(', ');
};

const headerClassName = 'font-semibold text-[#1F1F21] flex items-center h-full -mx-2';

const Header = ({ children }) => (
  <div className={headerClassName}>{children}</div>
);

const PFAShipmentTable: React.FunctionComponent = () => {
  const [page, setPage] = useState(1);
  const { productCostEnabled } = useProductFulfillmentContext();

  const queryParams = useQueryParams();
  const programId = queryParams.get('programId');
  const catalogId = queryParams.get('catalogId');
  const userId = queryParams.get('userId');

  const [searchText, setSearchText] = useState('');
  const [tempSearchText, setTempSearchText] = useState('');
  const [searchType, setSearchType] = useState<'name' | 'orderId'>('name');

  const { dateRangeSettings } = usePFADashboardContext();

  const {
 isOrdersLoading, orders: currOrders, totalOrders: currTotalOrders, previousOrders, previousTotalOrders,
} = useGetOrdersForDashboard({
    variables: {
      offset: (page - 1) * PAGE_SIZE,
      limit: PAGE_SIZE,
      orderFilters: {
        projectId: parseInt(programId, 10),
        catalogId: parseInt(catalogId, 10),
        userId,
        startDate: dateRangeSettings.dateRange.startDate?.toISOString(),
        endDate: dateRangeSettings.dateRange.endDate?.toISOString(),
        memberName: searchType === 'name' ? searchText || undefined : undefined,
        orderNumber: searchType === 'orderId' ? searchText || undefined : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const orders = isOrdersLoading ? previousOrders : currOrders;
  const totalOrders = (isOrdersLoading ? previousTotalOrders : currTotalOrders) || 0;

  useEffect(() => {
    setPage(1);
  }, [programId, catalogId, userId, dateRangeSettings.dateRange.startDate, dateRangeSettings.dateRange.endDate, searchType, searchText]);

  const tableData: IOrders[] = useMemo(() => orders?.map((o, index) => {
      const row = {
        ...o,
        id: index.toString(),
        member: o.member,
        products: getOrderProducts(o),
        _sortableFields: {
          member: o.member?.name?.toLowerCase(),
          products: getOrderProductsString(o),
          raw: o.raw?.order_number || 'N/A',
          shipmentTracking: o.shipmentTracking?.shipmentStatus,
          fulfillmentStatus: o.fulfillmentStatus || 'N/A',
        },
      };
      return row;
    }) || [], [orders]);

  const debounceRef = useRef<NodeJS.Timer>(null);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    setTempSearchText(e.target.value);
    debounceRef.current = setTimeout(() => {
      setSearchText(e.target.value);
    }, 1000);
  };

  const columns: ColumnDef<IOrders>[] = [
    {
      id: 'member',
      header: () => <Header>Name</Header>,
      accessorKey: 'member',
      cell: ({ getValue }) => <div>{renderNameField(getValue())}</div>,
      sortingFn: (a, b) => customSort(a?.original?.member?.name.toLowerCase(), b?.original?.member?.name.toLowerCase()),
    },
    {
      header: () => <Header>Order Number</Header>,
      accessorKey: 'raw.order_number',
      cell: ({ getValue }) => <Badge variant="secondary">{getValue() || 'N/A'}</Badge>,
    },
    {
      header: () => <Header>Products Ordered</Header>,
      accessorKey: '_sortableFields.products',
      cell: ({ getValue }) => <BadgeGroup items={(getValue() as string)?.split(', ') || []} />,
    },
    {
      header: () => <Header>Created On</Header>,
      accessorKey: 'createdDate',
      cell: ({ getValue }) => (
        <Badge variant="secondary">
          {(getValue() && new Date(getValue() as string).toLocaleDateString()) || ''}
        </Badge>
      ),
    },
    {
      header: () => <Header>Fulfillment Status</Header>,
      accessorKey: 'fulfillmentStatus',
      cell: ({ getValue }) => <Badge variant="secondary">{getValue() || 'N/A'}</Badge>,
    },
    {
      header: () => <Header>Shipment Status</Header>,
      accessorKey: 'shipmentTracking.shipmentStatus',
      cell: ({ getValue }) => <Badge variant="secondary">{getValue() || 'N/A'}</Badge>,
    },
    {
      header: () => <Header>Tracking Number</Header>,
      accessorKey: 'shipmentTracking.trackingNumber',
      cell: ({ getValue }) => <Badge variant="secondary">{getValue() || 'N/A'}</Badge>,
    },
  ];

  if (productCostEnabled) {
    columns.splice(2, 0, {
      header: () => <Header>Product Cost</Header>,
      accessorKey: 'cost',
      cell: ({ getValue }) => <Badge variant="secondary">{`${getValue() ? `$${getValue()}` : 'N/A'}`}</Badge>,
    });
  }

  const emptyContent = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={BoxOpenIcon}
        title="Orders not found"
        description="It looks like there are no orders for the given filters. Try adjusting the filters and try again."
      />
    </div>
  );

  return (
    <div className="flex flex-col gap-6 relative">
      {isOrdersLoading && (
        <div className="absolute z-[9999] bg-primary-foreground opacity-50 rounded-2xl top-0 left-0 h-full w-full flex items-center justify-center">
          <LoadSpinner />
        </div>
      )}
      <WidgetContainer
        widgetTitle={`${totalOrders} ${pluralize(totalOrders, 'Order')}`}
        tooltip="This includes all the sent order requests as well."
        bgColor="bg-white"
        textColor="text-gray-700"
        widgetActions={(
          <div className="flex items-center gap-4">
            <SelectWithInput
              items={[
                    { value: 'name', label: 'Name' },
                    { value: 'orderId', label: 'Order ID' },
                  ]}
              selectProps={{
                    value: searchType,
                    onValueChange: (value) => setSearchType(value as 'name' | 'orderId'),
                  }}
              selectTriggerProps={{
                    className: 'w-[108px]',
                  }}
              inputProps={{
                    placeholder: 'Search',
                    value: tempSearchText,
                    onChange: handleSearchChange,
                    className: 'w-[250px]',
                  }}
            />
            <div className="flex gap-2 items-center">
              <span>{`${(page - 1) * PAGE_SIZE + 1}-${Math.min(page * PAGE_SIZE, totalOrders)} of ${totalOrders}`}</span>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  disabled={page === 1}
                  onClick={() => setPage((prev) => prev - 1)}
                >
                  <ChevronLeft className="text-gray-500" size={16} />
                </Button>
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  disabled={page * PAGE_SIZE >= totalOrders}
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  <ChevronRight className="text-gray-500" size={16} />
                </Button>
              </div>
            </div>
          </div>
            )}
      >
        {tableData?.length ? (
          <DataTable
            columns={columns}
            data={tableData}
            wrapperClassName="h-[471px]"
            columnPinning={{
              left: ['member'],
            }}
          />
        ) : emptyContent}
      </WidgetContainer>
    </div>
  );
};

export default PFAShipmentTable;
