import React, { useCallback } from 'react';
import {
    Input, Typography, DatePicker, Tag,
} from '@revfluence/fresh';
import moment from 'moment';
import styles from '../FiscalYears.scss';
import FiscalYearConfig from './FiscalYearConfig';

const { Text } = Typography;

export interface FiscalYear {
    id: string;
    yearName: string;
    year: number;
    isArchived: boolean;
    isCurrentYear: boolean;
    isNew?: boolean;
}

interface FiscalYearProps {
    value?: FiscalYear;
    fiscalYears?: FiscalYear[];
    handleFiscalYearChange: (fiscalYear: FiscalYear) => void;
    handleFiscalYearsChange: (fiscalYears: FiscalYear[]) => void;
}

const FiscalYearComponent: React.FC<FiscalYearProps> = ({
    value, handleFiscalYearChange, fiscalYears, handleFiscalYearsChange,
}: FiscalYearProps) => {
    const onFiscalYearNameChange = useCallback((e) => {
        const fiscalYearName = e.target.value;
        const updatedFiscalYear = { ...value, yearName: fiscalYearName };
        handleFiscalYearChange(updatedFiscalYear);
    }, [value, handleFiscalYearChange]);

    const onFiscalYearChange = useCallback((fiscalYear) => {
        const updatedFiscalYear = { ...value, year: fiscalYear.year() };
        const fiscalYearExists = fiscalYears.some((fy) => fy.year === fiscalYear.year());
        if (!fiscalYearExists) {
            const updatedFiscalYears = fiscalYears.map((fy) => (fy.id === value.id ? updatedFiscalYear : fy));
            handleFiscalYearsChange(updatedFiscalYears);
        }
    }, [value, handleFiscalYearsChange, fiscalYears]);

    return (
      <div className={styles.fiscalYearContainer}>
        <div className={styles.fiscalYearRow}>
          <div className={`${styles.field} ${styles.fiscalYearName}`}>
            <Text className={styles.fieldLabel}>Fiscal Year Name</Text>
            <Input value={value.yearName} onChange={onFiscalYearNameChange} placeholder="Enter Name" />
          </div>
          <div className={`${styles.field} ${styles.fiscalYearDate}`}>
            <Text className={styles.fieldLabel}>Fiscal Year</Text>
            <DatePicker onChange={onFiscalYearChange} value={moment(value.year, 'YYYY')} picker="year" placeholder="Fiscal Year" disabled={!value?.isNew} />
          </div>
          <FiscalYearConfig fiscalYear={value} fiscalYears={fiscalYears} handleFiscalYearChange={handleFiscalYearChange} handleFiscalYearsChange={handleFiscalYearsChange} />
        </div>
        {value.isCurrentYear ? (<Tag color="#E6F7FF" size="small">Current Year</Tag>) : null}
      </div>
    );
};

export default FiscalYearComponent;
