import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import {
  GET_AVAILABLE_AD_ACCOUNTS,
} from '@frontend/app/queries';

import {
  GetAvailableAdAccounts,
} from '@frontend/app/queries/types/GetAvailableAdAccounts';

type IOptions = QueryHookOptions<GetAvailableAdAccounts>;

export const useGetAvailableAdAccounts = (options: IOptions = {}) => (
  useQuery<GetAvailableAdAccounts>(GET_AVAILABLE_AD_ACCOUNTS, options)
);

export type TUseGetAvailableAdAccountsQuery = ReturnType<typeof useGetAvailableAdAccounts>;
