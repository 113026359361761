import * as React from 'react';
import {
  capitalize, isEmpty, join, map, split,
} from 'lodash';
import { FormInstance } from 'antd/lib/form';
import { Collapse, Typography } from '@revfluence/fresh';
import { OfferFormToolTips } from '@affiliates/components';
import { OFFER_CONVERSION_TYPE, OFFER_PAYOUT_TYPE, OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { OfferDescription, OfferName, OfferPayoutOptions } from './FormElements';
import { OfferConversionType } from '../AffiliateLinkSection/FormElements';
import {
  FormAsyncAction, OfferFormModes, TDisabledMap, TFormValues,
} from '../../types';

import styles from '../../OfferForm.scss';
import { OfferStatus } from '../ShopifyPromoCodeSectionNew/FormElements';

const { useMemo } = React;
const { Panel } = Collapse;
const { Text } = Typography;

interface IProps {
  disabled: TDisabledMap<TFormValues>;
  onFieldFocused: (type: string, selected: boolean) => void;
  showCostPerClick: boolean;
  values: TFormValues;
  instructions: string;
  isNewFlow: boolean;
  mode: OfferFormModes;
  migrateToGraphQL: boolean;
  formRef: FormInstance;
  hasMembers: boolean;
  initialValues: TFormValues;
  handleFormAsyncActions?: (value: FormAsyncAction) => void,
}

const singularize = (str: string): string => {
  if (!isEmpty(str) && str.charAt(str.length - 1).toLowerCase() === 's') {
    return str.slice(0, -1);
  }
  return str;
};

export const CommonSection: React.FC<Readonly<IProps>> = ({
  disabled,
  onFieldFocused,
  showCostPerClick,
  values,
  instructions,
  isNewFlow,
  mode,
  migrateToGraphQL,
  formRef,
  handleFormAsyncActions,
}) => {
  const formattedConversionType = useMemo(() => {
    if (values.source !== OFFER_SOURCE.TUNE) {
      return singularize(capitalize(OFFER_CONVERSION_TYPE.CONVERSIONS));
    }
    if (values.conversionType) {
      return singularize(join(map(split(values.conversionType, '_'), capitalize), ' '));
    }
    return singularize(capitalize(OFFER_CONVERSION_TYPE.CONVERSIONS));
  }, [values]);
  const offerPayoutOptionsEvents = useMemo(() => ({
    onBlur: () => onFieldFocused(null, true),
    options: {
      [OFFER_PAYOUT_TYPE.CONVERSION]: {
        onFocus: () => onFieldFocused(OfferFormToolTips.ppcInfo, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(OfferFormToolTips.ppcInfo, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
      [OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE]: {
        onFocus: () => onFieldFocused(OfferFormToolTips.cpccpsInfo, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(OfferFormToolTips.cpccpsInfo, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
      [OFFER_PAYOUT_TYPE.SALE]: {
        onFocus: () => onFieldFocused(OfferFormToolTips.cps, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(OfferFormToolTips.cps, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
      [OFFER_PAYOUT_TYPE.CLICK]: {
        onFocus: () => onFieldFocused(null, true),
        onBlur: () => onFieldFocused(null, true),
        onMouseEnter: () => onFieldFocused(null, false),
        onMouseLeave: () => onFieldFocused(null, false),
      },
    },
  }), [onFieldFocused]);
  let offerDetailsTitle = '';
  switch (values.source) {
    case OFFER_SOURCE.TUNE:
      offerDetailsTitle = 'Offer Details';
      break;
    case OFFER_SOURCE.SHOPIFY:
      offerDetailsTitle = 'Promo Code Offer Details';
      break;
    default:
      offerDetailsTitle = '';
      break;
  }

  return (
    <Collapse defaultActiveKey={['1', '2']} ghost>

      <Panel className={styles.formSection} key={1} header={offerDetailsTitle}>
        {instructions}
        <OfferName
          charCount={values.name?.length || 0}
          disabled={disabled.name}
          name="name"
          source={values.source}
        />
        <OfferDescription
          disabled={disabled.description}
          name="description"
        />
        {
          migrateToGraphQL && (
            <OfferStatus
              disabled={false}
              name="status"
            />
          )
        }
        {showCostPerClick && !migrateToGraphQL && (
          <OfferConversionType
            disabled={disabled.conversionType}
            name="conversionType"
          />
        )}
      </Panel>

      <Panel className={styles.formSection} key={2} header={migrateToGraphQL ? 'Manage Payouts' : 'Commission & Payout'}>
        <div className={styles.headerSpace}>
          <Text className={styles.descTextColor}>Manage payouts to members based on each conversion or sale generated using their promo code.</Text>
        </div>
        <OfferPayoutOptions
          disabled={disabled.payoutType}
          events={offerPayoutOptionsEvents}
          formattedConversionType={formattedConversionType}
          name="payoutType"
          showCostPerClick={showCostPerClick}
          value={migrateToGraphQL ? values.payoutOptions : values.payoutType}
          source={values.source}
          payoutType={values.payoutType}
          isNewFlow={isNewFlow}
          mode={mode}
          migrateToGraphQL={migrateToGraphQL}
          formRef={formRef}
          handleFormAsyncActions={handleFormAsyncActions}
          disabledConversionType={disabled.conversionType}
        />
      </Panel>
    </Collapse>
  );
};
