import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ORDER_BY_ID_QUERY } from '../queries';
import {
  GetOrderById,
  GetOrderByIdVariables,
  GetOrderById_order,
} from '../queries/types/GetOrderById';

type IOptions = QueryHookOptions<GetOrderById, GetOrderByIdVariables>;
export type IOrderById = GetOrderById_order;

export function useGetOrderById(options: IOptions = {}) {
  const {
    loading, data: { order } = {}, error, refetch,
  } = useQuery<GetOrderById, GetOrderByIdVariables>(
    GET_ORDER_BY_ID_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    order,
    error,
    refetch,
  };
}
