import * as React from 'react';
import { size } from 'lodash';

import { Menu } from '@revfluence/fresh';
import { ModifyCommunityMembersModal } from '@frontend/app/components';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';

import { BulkMenuAction } from './ListHeader';

const { useState } = React;

interface IProps {
  memberCount?: number;
  memberIds?: number[];
  disabled?: boolean;
}

export const AddToGroupMenuItem: React.FunctionComponent<IProps> = React.memo((props) => {
  const [open, setOpen] = useState<boolean>(false);

  const {
    searchQuery,
  } = useMemberListContext();

  const addEvent = useEventContext();

  const handleClick = () => {
    addEvent(
      EventName.AttemptBulkAction,
      {
        action: 'add_to_group',
        member_count: size(props.memberIds) > 0 ? size(props.memberIds) : props.memberCount,
      },
    );
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Menu.Item
        onClick={handleClick}
        key={BulkMenuAction.AddToGroup}
        disabled={props.disabled}
      >
        Add To Group
      </Menu.Item>
      <ModifyCommunityMembersModal
        type="add"
        onRequestClose={handleModalClose}
        defaultSelectedCommunityIds={[]}
        query={searchQuery}
        memberIds={props.memberIds}
        memberCount={props.memberCount}
        show={open}
      />
    </>
  );
});

AddToGroupMenuItem.displayName = 'AddToGroupMenuItem';
