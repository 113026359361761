/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1u570_157 {
  display: flex;
}

._justify-content-space-between_1u570_161 {
  justify-content: space-between;
}

._tabular-nums_1u570_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1u570_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SetupOffers_1u570_209 {
  height: 100%;
}