import { QueryHookOptions, useQuery } from '@apollo/client';

import { GetTikTokSparkAdsRecommendations, GetTikTokSparkAdsRecommendationsVariables } from '../queries/types/GetTikTokSparkAdsRecommendations';
// import { ITikTokSparkAdsRecommendation } from '../containers/Projects/OverviewPage/WidgetTikTokSparkAdsRecommendations/TikTokSparkAdsRecommendationMeta';
import { GET_TIKTOK_SPARK_ADS_RECOMENDATIONS } from '../queries/TikTokSparkAdsRecommendationsQuery';

type IOptions = QueryHookOptions<GetTikTokSparkAdsRecommendations, GetTikTokSparkAdsRecommendationsVariables>;

export const useGetTikTokSparkAdsRecommendations = (options: IOptions = {}) => useQuery<
  GetTikTokSparkAdsRecommendations,
  GetTikTokSparkAdsRecommendationsVariables
>(
  GET_TIKTOK_SPARK_ADS_RECOMENDATIONS,
  options,
);
