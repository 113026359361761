import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';

import { EmptyNotice } from '@frontend/app/components';
import { FieldListItem } from './FieldListItem';
import { useCommunityIdentityContext } from '../hooks/useCommunityIdentityContext';

import styles from './DefaultFieldsList.scss';

interface IProps {
  className?: string;
}

export const DefaultFieldsList: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    defaultSchemas,
  } = useCommunityIdentityContext();

  return (
    <form className={cx(styles.DefaultFieldsList, props.className)}>
      <div className={styles.title}>
        Default Fields
      </div>
      <div className={styles.description}>
        Define how you want to structure identity for your members.
      </div>
      {isEmpty(defaultSchemas) && (
        <EmptyNotice>
          You have no default fields
        </EmptyNotice>
      )}
      {map(defaultSchemas, (field) => (
        <FieldListItem
          key={field.id}
          className={styles.customField}
          field={field}
          disabled
          editable={false}
        />
      ))}
    </form>
  );
});

DefaultFieldsList.displayName = 'DefaultFieldsList';
