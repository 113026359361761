/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qfdn_157 {
  display: flex;
}

._justify-content-space-between_1qfdn_161 {
  justify-content: space-between;
}

._tabular-nums_1qfdn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qfdn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._addCreators_1qfdn_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._connectFacebook_1qfdn_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._OAuthNotice_1qfdn_178 ._permissions_1qfdn_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._OAuthNotice_1qfdn_178 {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  align-items: left;
}
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 {
  align-items: start;
  flex-direction: column;
  width: 100%;
}
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._contentsTitle_1qfdn_251 {
  margin: 4px;
  font-weight: bold;
}
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._contentsBody_1qfdn_255 {
  margin: 4px;
}
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._addCreators_1qfdn_178 {
  margin-bottom: 4px;
}
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._connectFacebook_1qfdn_188 {
  margin-bottom: 14px;
}
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._igButton_1qfdn_264 {
  margin-bottom: 16px;
  width: 240px;
  color: white;
  background-image: linear-gradient(-270deg, #FFC750 0%, #F8443E 27%, #D53592 53%, #A928A6 77%, #972FC0 100%);
}
._OAuthNotice_1qfdn_178 ._contents_1qfdn_178 ._igButton_1qfdn_264 ._igIcon_1qfdn_270 path {
  fill: white !important;
}
._OAuthNotice_1qfdn_178 ._permissions_1qfdn_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #4F4F4F;
}
._OAuthNotice_1qfdn_178 ._permissions_1qfdn_198 ._lockIcon_1qfdn_279 {
  margin-right: 2px;
}