import React, { useEffect } from 'react';
import {
 Button, Col, Drawer, Row, Skeleton, TableRowSelection, Typography,
} from '@revfluence/fresh';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { CatalogStatus, ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useGetCatalogs } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';
import { ChevronRightIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { useCreateProjectConfig, useUpdateProjectConfig } from '@frontend/applications/ProductFulfillmentApp/hooks';
import { GET_PROJECT_CONFIG_BY_TYPE_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries';
import { useHistory } from 'react-router-dom';
import { pfaV2Routes } from '@frontend/app/containers/Settings/ProductFulfillment/routes';
import { useGetAllCatalogStats } from '@frontend/app/containers/Settings/ProductFulfillment/Catalogs/hooks/useGetAllCatalogStats';
import { isEqual } from 'lodash';
import { useProductCatalogsContext } from './ProductCatalogsContext';
import { CatalogsList } from './CatalogsList';
import { CatalogRow } from './types';

const { Text } = Typography;

export interface ConnectCatalogDrawerProps {
  programId: number;
}

export const ConnectCatalogDrawer = ({ programId }: ConnectCatalogDrawerProps) => {
  const {
 isDrawerOpen, selectedCatalogIds, setSelectedCatalogIds, resetDrawer,
} = useProductCatalogsContext();

  const history = useHistory();

  const { catalogs, loading: isCatalogsLoading } = useGetCatalogs();
  const { allCatalogStats } = useGetAllCatalogStats();
  const { projectConfig } = useGetProjectConfigByType({
    variables: {
      projectId: programId,
      type: ProjectConfigType.ProductCatalog,
    },
    skip: !programId,
  });

  useEffect(() => {
    if (projectConfig?.brandCatalogs) {
      setSelectedCatalogIds(projectConfig.brandCatalogs.map((id) => String(id)));
    }
  }, [projectConfig?.brandCatalogs, setSelectedCatalogIds]);

  const { createProjectConfig, loading: isCreateLoading } = useCreateProjectConfig({
    onCompleted: () => {
      handleClose();
    },
    refetchQueries: [GET_PROJECT_CONFIG_BY_TYPE_QUERY],
  });
  const { updateProjectConfig, loading: isUpdateLoading } = useUpdateProjectConfig({
    onCompleted: () => {
      handleClose();
    },
    refetchQueries: [GET_PROJECT_CONFIG_BY_TYPE_QUERY],
  });

  const rowSelection: TableRowSelection<CatalogRow> = {
    selectedRowKeys: selectedCatalogIds,
    onChange: setSelectedCatalogIds,
  };

  let activeCatalogs = [];
  if (!isCatalogsLoading) {
    activeCatalogs = catalogs.filter((catalog) => catalog.status === CatalogStatus.ACTIVE);
  }

  const handleClose = () => {
    resetDrawer();
  };

  const handleAddCatalogs = () => {
    if (projectConfig) {
      updateProjectConfig({
        variables: {
          id: projectConfig.id,
          updates: {
            brandCatalogs: selectedCatalogIds.map((id) => Number(id)),
          },
        },
      });
    } else {
      createProjectConfig({
        variables: {
          projectConfig: {
            projectId: programId,
            type: ProjectConfigType.ProductCatalog,
            brandCatalogs: selectedCatalogIds.map((id) => Number(id)),
          },
        },
      });
    }
  };

  const handleCreateCatalog = () => {
    history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', 'new'));
  };

  const isLoading = isCreateLoading || isUpdateLoading;

  const isSelectionChanged = !isEqual(
    selectedCatalogIds.slice().sort(),
    projectConfig?.brandCatalogs?.map((id) => String(id)).slice().sort(),
  );

  return (
    <Drawer
      open={isDrawerOpen}
      title="Connect Catalog"
      width={1055}
      onClose={handleClose}
      footer={(
        <Row gutter={24} align="middle" justify="center">
          <Col>
            <Button size="large" onClick={handleClose} disabled={isLoading}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={handleAddCatalogs}
              disabled={!isSelectionChanged}
              loading={isLoading}
            >
              {projectConfig?.brandCatalogs?.length ? 'Update' : 'Add'}
              {' '}
              Catalog
              <ChevronRightIcon />
            </Button>
          </Col>
        </Row>
      )}
      footerStyle={{
        padding: '24px',
      }}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Text weight="semibold">Please select the catalogs you want to connect with this project.</Text>
        </Col>
        <Col>
          <Button icon={<PlusIcon />} onClick={handleCreateCatalog}>
            Create Catalog
          </Button>
        </Col>
      </Row>
      {isCatalogsLoading ? <Skeleton /> : (
        <CatalogsList
          projectConfigId={projectConfig?.id}
          catalogs={activeCatalogs}
          allCatalogStats={allCatalogStats}
          hideActionColumn
          rowSelection={rowSelection}
        />
      )}
    </Drawer>
  );
};
