import React, { useEffect, useState } from 'react';
import {
  Button, Card, Input, message, Modal, Row, Space, Spinner, Table, Typography,
} from '@revfluence/fresh';
import {
 ArrowLeftIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon, GearIcon, PencilIcon, PlusIcon,
 TriangleExclamationIcon,
 XmarkIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import { CatalogSelectionRuleConditionOperationType, SelectionType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { GET_CATALOG_COLLECTION_BY_ID_QUERY, GET_CATALOG_COLLECTIONS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { isNil } from 'lodash';
import styles from './EditCollectionItem.scss';
import { StatCard } from './StatCard';
import { useProductTableProps } from '../CatalogSelectionModal/useProductTableProps';
import { pfaV2Routes } from '../../routes';
import { useBasicSelectionContext } from '../CatalogSelectionModal/BasicSelectionContext';
import { CatalogSelectionModal } from '../CatalogSelectionModal/CatalogSelectionModal';
import { useGetCatalogCollectionByIdQuery } from '../hooks/useGetCatalogCollectionById';
import { useGetStatsByCatalogCollectionId } from '../hooks/useGetStatsByCatalogCollectionId';
import { AdvancedSelectionModal } from '../AdvancedSelectionModal/AdvancedSelectionModal';
import { useAdvancedSelectionContext } from '../AdvancedSelectionModal/AdvancedSelectionContext';
import { useUpdateCatalogCollection } from '../hooks/useUpdateCatalogCollection';
import { useDeleteCatalogCollection } from '../hooks/useDeleteCatalogCollection';
import { reloadPreviewIframe } from '../reloadPreviewIframe';

const { Title, Text } = Typography;

const PAGE_SIZE = 50;

const EditCollectionItemDetails = () => {
  const { collectionId, catalogId } = useParams<{ collectionId: string, catalogId: string }>();
  const { collectionItem, loading: isFetchItemLoading } = useGetCatalogCollectionByIdQuery({
    variables: {
      id: Number(collectionId),
      includeProductDetails: true,
      page: 1,
      pageSize: PAGE_SIZE,
    },
    fetchPolicy: 'network-only',
    skip: !collectionId,
    notifyOnNetworkStatusChange: true,
  });

  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);

  const [newLabel, setNewLabel] = useState(collectionItem.label);

  const { updateCollectionItem } = useUpdateCatalogCollection();

  const handleUpdateCollectionItem = (newImageUrl?: string) => {
    message.loading({ content: 'Updating Collection Item...', key: 'updateCollectionItem' });
    updateCollectionItem({
      refetchQueries: [
        GET_CATALOG_COLLECTION_BY_ID_QUERY,
      ],
      variables: {
        input: {
          brandCatalogId: collectionItem.brandCatalogId,
          selectionType: collectionItem.selectionType,
          label: !isNil(newImageUrl) ? collectionItem.label : newLabel,
          imageUrl: isNil(newImageUrl) ? collectionItem.imageUrl : newImageUrl,
        },
        catalogCollectionId: Number(collectionId),
      },
      onCompleted: () => {
        setIsEditing(false);
        message.success({ content: 'Collection Item Updated!', key: 'updateCollectionItem' });
        reloadPreviewIframe();
      },
    });
  };

  const { deleteCatalogCollection, loading: isDeleting } = useDeleteCatalogCollection({
    refetchQueries: [
      GET_CATALOG_COLLECTIONS_QUERY,
    ],
    update: (cache) => {
      cache.evict({ fieldName: 'getStatsForAllCatalogs' });
      cache.evict({ fieldName: 'getStatsByCatalogId' });
    },
    onCompleted: () => {
      message.success('Collection Item Deleted!');
      history.push(pfaV2Routes.settings.brandCatalogCollections.replace(':catalogId', catalogId));
      reloadPreviewIframe();
    },
  });

  const handleDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this collection item?',
      icon: <TriangleExclamationIcon />,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        deleteCatalogCollection({
          variables: {
            id: Number(collectionId),
          },
        });
      },
      width: 570,
    });
  };

  useEffect(() => {
    setNewLabel(collectionItem.label);
  }, [collectionItem.label]);

  return (
    <Space direction="vertical" size="middle">
      <Space direction="vertical">
        <Text>Label</Text>
        {isEditing ? (
          <Row wrap={false}>
            <Input value={newLabel} onChange={(e) => setNewLabel(e.target.value)} />
            <Button
              type="text"
              icon={<CheckIcon />}
              onClick={() => {
              handleUpdateCollectionItem();
              setIsEditing(false);
             }}
            />
            <Button
              type="text"
              icon={<XmarkIcon />}
              onClick={() => {
              setNewLabel(collectionItem.label);
              setIsEditing(false);
              }}
            />
          </Row>
          ) : (
            <Row wrap={false}>
              {isFetchItemLoading
                ? <Spinner />
                : (
                  <>
                    <Input value={newLabel} readOnly style={{ pointerEvents: 'none' }} />
                    <Button
                      type="link"
                      icon={<PencilIcon />}
                      onClick={() => setIsEditing(true)}
                    />
                  </>
                )}
            </Row>
          )}
      </Space>
      {/* <Space direction="vertical">
        <Text>Image</Text>
        <UploadCollectionItemImage
          isLoading={isFetchItemLoading || isUpdateItemLoading}
          imageUrl={collectionItem.imageUrl}
          onChange={handleUpdateCollectionItem}
        />
        <Text type="secondary">File Format: PNG, JPG, JPEG</Text>
      </Space> */}
      <Button
        danger
        type="text"
        className={styles.deleteCollectionItemBtn}
        onClick={handleDelete}
        loading={isDeleting}
      >
        Delete Collection Item
      </Button>
    </Space>
  );
};

export const EditCollectionItem = () => {
  const { collectionId, catalogId } = useParams<{ collectionId: string, catalogId: string }>();

  const [page, setPage] = useState(1);

  const [isExpanded, setIsExpanded] = useState(false);

  const { collectionItem, loading } = useGetCatalogCollectionByIdQuery({
    variables: {
      id: Number(collectionId),
      includeProductDetails: true,
      page,
      pageSize: PAGE_SIZE,
    },
    fetchPolicy: 'network-only',
    skip: !collectionId,
    notifyOnNetworkStatusChange: true,
  });

  const { collectionItem: collectionItemWithoutDetails } = useGetCatalogCollectionByIdQuery({
    variables: {
      id: Number(collectionId),
      includeProductDetails: true,
    },
    fetchPolicy: 'no-cache',
    skip: !collectionId || collectionItem.selectionType !== SelectionType.BASIC,
  });

  const { stats, loading: statsLoading } = useGetStatsByCatalogCollectionId({
    variables: {
      catalogCollectionId: Number(collectionId),
    },
  });

  const productsCount = stats?.productCount || 0;
  const variantsCount = stats?.variantCount || 0;

  const {
 bulkSelect, openEditingModal, setEditingDetails, resetEditing,
} = useBasicSelectionContext();

  const {
    setEditingDetails: setAdvancedEditingDetails,
    resetEditing: resetAdvancedEditing,
    setState: setAdvancedSelectionState,
  } = useAdvancedSelectionContext();

  const history = useHistory();

  useEffect(() => {
    bulkSelect({
      products: collectionItemWithoutDetails.catalogProducts.map((product) => ({
        id: String(product.productId),
        totalVariants: product.variantIds.length ? product.variantIds.length : product.product.totalVariants,
      })),
    });
  }, [bulkSelect, collectionItemWithoutDetails.catalogProducts]);

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setPage((prev) => prev - 1);
  };

  const {
 columnConfig, tableData, expandable, pagination,
} = useProductTableProps({
    // @ts-expect-error - we are not fetching productVariants
    products: collectionItem.catalogProducts.map((product) => product.product),
    totalProducts: collectionItem.totalProducts,
    onNextPage: handleNextPage,
    onPreviousPage: handlePrevPage,
    pageNumber: page,
    isReadonly: true,
  });

  const handleBack = () => {
    history.push(
      pfaV2Routes.settings.brandCatalogCollections.replace(':catalogId', catalogId),
    );
  };

  const openSelectionModal = () => {
    openEditingModal({ collectionId: collectionItem.collectionId, catalogCollectionId: Number(collectionItem.id) });
  };

  const openAdvancedSelectionModal = () => {
    setAdvancedSelectionState({
      label: collectionItem.label,
      isOpen: true,
      catalogCollectionId: Number(collectionItem.id),
      isEditing: true,
      operation: collectionItem.advanceSelectionConfig?.conditionOperation || CatalogSelectionRuleConditionOperationType.OR,
      collections: collectionItem.advanceSelectionConfig?.collections || [],
      vendors: collectionItem.advanceSelectionConfig?.vendors || [],
      productTypes: collectionItem.advanceSelectionConfig?.productTypes || [],
      priceMin: collectionItem.advanceSelectionConfig?.priceMin || null,
      priceMax: collectionItem.advanceSelectionConfig?.priceMax || null,
      tags: collectionItem.advanceSelectionConfig?.tags || [],
      categories: collectionItem.advanceSelectionConfig?.categories || [],
      options: collectionItem.advanceSelectionConfig?.options || [],
    });
  };

  useEffect(() => {
    setEditingDetails({
      collectionId: collectionItem.collectionId,
      catalogCollectionId: Number(collectionItem.id),
    });
  }, [collectionItem.collectionId, collectionItem.id, setEditingDetails]);

  useEffect(() => {
    setAdvancedEditingDetails(Number(collectionItem.id));
  }, [collectionItem.collectionId, collectionItem.id, setAdvancedEditingDetails]);

  useEffect(() => () => {
    resetEditing();
    resetAdvancedEditing();
  }, [resetAdvancedEditing, resetEditing]);

  return (
    <>
      <Space direction="vertical" className={styles.EditCollectionItem} size="large">
        <Space size="middle" className={styles.header} align="center">
          <Button icon={<ArrowLeftIcon />} type="text" onClick={handleBack} />
          <Title level={4} className={styles.title}>
            Edit Collection Item
          </Title>
        </Space>
        <>
          <Card className={styles.collectionNameCard}>
            <Space direction="vertical">
              <Row align="middle" justify="space-between" className="py-2">
                <Space>
                  {/* <Avatar size={46} shape="square" src={collectionItem.imageUrl || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'} /> */}
                  <Text weight="semibold">{collectionItem.label || collectionItem.collection?.name}</Text>
                </Space>
                <Button
                  icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  size="small"
                  onClick={() => setIsExpanded((prev) => !prev)}
                />
              </Row>
              {
              isExpanded && (
                <EditCollectionItemDetails />
              )
            }
            </Space>
          </Card>
          {statsLoading ? <Spinner /> : (
            <Space size={10}>
              <StatCard title="Products" count={productsCount} />
              <StatCard title="Variants" count={variantsCount} />
            </Space>
)}
          <Row align="middle" justify="space-between" className={styles.manageProductsRow}>
            <Text weight="semibold">Products</Text>
            {
              collectionItem.selectionType === SelectionType.BASIC && (
                <Button type="link" icon={<PlusIcon />} onClick={openSelectionModal}>
                  Manage Products
                </Button>
              )
            }
            {
              collectionItem.selectionType === SelectionType.ADVANCED && (
                <Button type="link" icon={<GearIcon />} onClick={openAdvancedSelectionModal}>
                  Manage Products Conditions
                </Button>
              )
            }
          </Row>
          <Table
            columns={columnConfig}
            dataSource={tableData}
            className="offerTable"
            size="small"
            expandable={expandable}
            showHeader={false}
            rowKey="id"
            pagination={pagination}
            loading={loading}
          />
        </>
      </Space>
      <CatalogSelectionModal />
      <AdvancedSelectionModal />
    </>
  );
};
