import { IState } from '../../../../types/state';
import { IToggleReviewWithinThreeDaysOfCreatorSubmissionAction } from './index';

export const toggleReviewWithinThreeDays = (
  state: IState,
  action: IToggleReviewWithinThreeDaysOfCreatorSubmissionAction,
): IState => {
  const {
    contentGuidelineInstanceId,
    requiresReviewWithinThreeDaysOfCreatorSubmission,
  } = action;
  const updatedContentGuidelines = state.contentGuidelines.map((contentGuideline) => {
    if (contentGuideline.contentGuidelineInstanceId === contentGuidelineInstanceId) {
      return {
        ...contentGuideline,
        requiresReviewWithinThreeDaysOfCreatorSubmission,
      };
    }
    return contentGuideline;
  });
  return {
    ...state,
    contentGuidelines: updatedContentGuidelines,
  };
};
