import * as React from 'react';
import { isFunction } from 'lodash';

interface ICopyParams {
  value: string;
  onSuccess?();
  onFailure?(err: Error);
}

interface ICopyToClipboard {
  copyToClipboard(params: ICopyParams)
}

const { useCallback } = React;

export const useCopyToClipboard = (): ICopyToClipboard => {
  const copyToClipboard = useCallback(async (params: ICopyParams) => {
    const {
      value,
      onSuccess,
      onFailure,
    } = params;

    try {
      await navigator.clipboard.writeText(value);

      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (err) {
      if (isFunction(onFailure)) {
        onFailure(err);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator]);

  return {
    copyToClipboard,
  };
};
