import * as React from 'react';
import {
 first, sortBy, isEmpty, find,
} from 'lodash';
import { useQuery } from '@apollo/client';

import { useProgramsForCommunityQuery } from '@frontend/app/hooks';
import { ProgramInput } from '@frontend/app/types/globalTypes';

import {
  CommunityByIdQuery_community as ICommunity,
} from '@frontend/app/queries/types/CommunityByIdQuery';

import { GET_PROGRAM_BY_ID } from '@frontend/app/queries/ProgramByIdQuery';
import {
  ProgramByIdQuery,
  ProgramByIdQueryVariables,
} from '@frontend/app/queries/types/ProgramByIdQuery';

import { ProgramList } from './ProgramList';
import { ProgramDetails } from './ProgramDetails';

import styles from './Programs.scss';

const { useState, useEffect, useMemo } = React;

interface IProps {
  community: ICommunity;
}

export const Programs: React.FunctionComponent<IProps> = (props) => {
  const [selectedProgramId, setSelectedProgramId] = useState<number>(null);
  const [newProgram, setNewProgram] = useState<ProgramInput>(null);

  const communityId = props.community && props.community.id;

  const {
    data: {
      programs = null,
    } = {},
    refetch,
    loading: isLoadingPrograms,
  } = useProgramsForCommunityQuery(communityId);

  const {
    data: {
      program: fetchedProgram = null,
    } = {},
  } = useQuery<ProgramByIdQuery, ProgramByIdQueryVariables>(GET_PROGRAM_BY_ID, {
    variables: {
      id: selectedProgramId,
    },
    skip: !selectedProgramId,
  });

  const sortedPrograms = useMemo(() => {
    if (programs) {
      return sortBy(programs, 'title');
    }
  }, [programs]);

  const allPrograms = useMemo(() => {
    if (sortedPrograms && newProgram) {
      return [newProgram, ...sortedPrograms];
    }
    return sortedPrograms;
  }, [sortedPrograms, newProgram]);

  useEffect(() => {
    if (selectedProgramId) {
      return;
    }

    const selectedProgram = first(sortedPrograms);
    if (selectedProgram) {
      setSelectedProgramId(selectedProgram.id);
    } else {
      setSelectedProgramId(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedPrograms]);

  const selectedProgram = useMemo(() => {
    if (fetchedProgram && fetchedProgram.id === selectedProgramId) {
      return fetchedProgram;
    } else if (newProgram) {
      return newProgram;
    } else {
      return null;
    }
  }, [selectedProgramId, fetchedProgram, newProgram]);

  const handleCreateProgram = () => {
    setSelectedProgramId(null);

    const {
      memberFieldSchemas,
    } = props.community;

    const firstNameField = find(memberFieldSchemas, { name: 'First Name' });
    const lastNameField = find(memberFieldSchemas, { name: 'Last Name' });

    const memberFieldSchemaIds: number[] = [];

    if (firstNameField) {
      memberFieldSchemaIds.push(firstNameField.id);
    }

    if (lastNameField) {
      memberFieldSchemaIds.push(lastNameField.id);
    }

    setNewProgram({
      id: null,
      title: 'New Program',
      columns: {
        memberFieldSchemaIds,
        dbColumns: ['email'],
      },
      applicationFormFields: {
        memberFieldSchemas: memberFieldSchemaIds.map((id) => {
          const schema = find(memberFieldSchemas, (schema) => schema.id === id);
          return {
            schemaId: schema.id,
            label: schema.name,
            required: false,
          };
        }),
        dbColumns: [{ name: 'email', label: 'Email', required: true }],
      },
      customLandingPagePath: 'New Program',
    });
  };

  type IProgram = typeof programs[0];

  const handleSaveProgram = (program: IProgram) => {
    refetch();
    setSelectedProgramId(program.id);
    if (newProgram) {
      setNewProgram(null);
    }
  };

  const hasPrograms = !isEmpty(allPrograms);

  return (
    <div className={styles.Programs}>
      {hasPrograms && (
        <>
          <ProgramList
            programs={allPrograms}
            selectedProgramId={selectedProgramId}
            onSelectProgram={setSelectedProgramId}
            onClickNewProgram={handleCreateProgram}
            className={styles.list}
          />
          <ProgramDetails
            community={props.community}
            program={selectedProgram}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSave={handleSaveProgram as any}
            className={styles.details}
          />
        </>
      )}

      {!isLoadingPrograms && !hasPrograms && (
        <div className={styles.details}>
          <div className={styles.emptyMsg}>
            You have no programs.&nbsp;
            <a className={styles.create} onClick={handleCreateProgram}>Create a program</a>
          </div>
        </div>
      )}
    </div>
  );
};
