/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1vhej_157 {
  display: flex;
}

._justify-content-space-between_1vhej_161 {
  justify-content: space-between;
}

._tabular-nums_1vhej_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1vhej_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DateFilter_1vhej_178 ._pickButton_1vhej_178 {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
._DateFilter_1vhej_178 ._pickButton_1vhej_178 svg {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._DateFilter_1vhej_178 ._pickButton_1vhej_178 ._calendarIcon_1vhej_186 {
  color: #1a1818;
}
._DateFilter_1vhej_178 ._pickButton_1vhej_178:active svg, ._DateFilter_1vhej_178 ._pickButton_1vhej_178:focus svg, ._DateFilter_1vhej_178 ._pickButton_1vhej_178:hover svg {
  color: #40a9ff;
}
._DateFilter_1vhej_178 ._pickButton_1vhej_178 ._label_1vhej_192 {
  flex: 1 1 auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}