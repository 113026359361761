/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_20pj8_157 {
  display: flex;
}

._justify-content-space-between_20pj8_161 {
  justify-content: space-between;
}

._tabular-nums_20pj8_165 {
  font-variant-numeric: tabular-nums;
}

._MultiSelect_20pj8_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_20pj8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._MultiSelect_20pj8_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._MultiSelect_20pj8_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._MultiSelect_20pj8_169 ::-webkit-scrollbar-track, ._MultiSelect_20pj8_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._MultiSelect_20pj8_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._MultiSelect_20pj8_169 ._button_20pj8_235 {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  color: #1a1818;
  will-change: color, filter;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._MultiSelect_20pj8_169 ._button_20pj8_235._light_20pj8_249 {
  background-color: white;
  border-radius: 3px;
  border: solid 1px #e1e4ea;
}
._MultiSelect_20pj8_169 ._button_20pj8_235._info_20pj8_254 {
  background-color: #eff5f9;
  border-radius: 100px;
  border: none;
}
._MultiSelect_20pj8_169 ._button_20pj8_235._active_20pj8_259, ._MultiSelect_20pj8_169 ._button_20pj8_235:hover {
  filter: brightness(0.92);
}
._MultiSelect_20pj8_169 ._button_20pj8_235._active_20pj8_259 ._arrow_20pj8_262, ._MultiSelect_20pj8_169 ._button_20pj8_235:hover ._arrow_20pj8_262 {
  color: #3996e0;
}
._MultiSelect_20pj8_169 ._button_20pj8_235 ._label_20pj8_265 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._MultiSelect_20pj8_169 ._button_20pj8_235 ._arrow_20pj8_262 {
  margin-left: auto;
  padding: 0 15px;
  display: flex;
}

._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276 {
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 37px;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276:hover {
  background-color: #f0f4f8;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276:hover ._label_20pj8_265 {
  color: #3996e0;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276._active_20pj8_259 ._label_20pj8_265 {
  color: #3996e0;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276._active_20pj8_259 ._label_20pj8_265 ._labelText_20pj8_296 {
  text-shadow: 0.5px 0 0 #3996e0;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276 ._label_20pj8_265 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276 ._label_20pj8_265 ._checkbox_20pj8_305 {
  pointer-events: none;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276 ._label_20pj8_265 ._labelText_20pj8_296 {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276 ._actions_20pj8_314 {
  position: absolute;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276 ._actions_20pj8_314 ._item_20pj8_321 {
  margin-left: 5px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 100px;
  background-color: white;
  color: #999;
  will-change: color, background-color;
  transition: all 0.1s ease-out;
}
._Popover_20pj8_276 ._list_20pj8_276 ._option_20pj8_276 ._actions_20pj8_314 ._item_20pj8_321:hover {
  color: #3996e0;
  background-color: #e2e2e2;
}