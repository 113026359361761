import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const ColumnsIconV2 = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="9 10 18 16"
  >
    <path d="M9.25 13.875C9.25 12.918 10.0156 12.125 11 12.125H25C25.957 12.125 26.75 12.918 26.75 13.875V22.625C26.75 23.6094 25.957 24.375 25 24.375H11C10.0156 24.375 9.25 23.6094 9.25 22.625V13.875ZM21.5 23.0625H25C25.2188 23.0625 25.4375 22.8711 25.4375 22.625V13.875C25.4375 13.6562 25.2188 13.4375 25 13.4375H21.5V23.0625ZM20.1875 13.4375H15.8125V23.0625H20.1875V13.4375ZM14.5 23.0625V13.4375H11C10.7539 13.4375 10.5625 13.6562 10.5625 13.875V22.625C10.5625 22.8711 10.7539 23.0625 11 23.0625H14.5Z" />
  </SvgIcon>
  ));

ColumnsIconV2.displayName = 'ColumnsIconV2';
