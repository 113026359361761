import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { SKIP_WORKLETS_WITH_TRACKER_MUTATION } from '@frontend/app/queries';
import {
  SkipWorkletsWithTrackerMutation,
  SkipWorkletsWithTrackerMutationVariables,
} from '@frontend/app/queries/types/SkipWorkletsWithTrackerMutation';

type IOptions = MutationHookOptions<SkipWorkletsWithTrackerMutation, SkipWorkletsWithTrackerMutationVariables>;

export const useSkipWorkletsWithTrackerMutation = (options: IOptions = {}) => (
  useMutation(SKIP_WORKLETS_WITH_TRACKER_MUTATION, options)
);
