/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1c1i9_157 {
  display: flex;
}

._justify-content-space-between_1c1i9_161 {
  justify-content: space-between;
}

._tabular-nums_1c1i9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1c1i9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

.ant-picker-dropdown {
  z-index: 9000;
}

._CheckBoxStyled_1c1i9_213 {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

._LinkStyled_1c1i9_219 {
  color: #ffffff !important;
  text-decoration: underline !important;
}

._notice_1c1i9_224 {
  margin-left: 1.25rem;
  max-width: 400px;
}

._isDisabled_1c1i9_229 {
  cursor: not-allowed;
  color: #aeaeae;
}

._FilterBox_1c1i9_234 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 1.25rem;
}
._FilterBox_1c1i9_234 ._FilterSet_1c1i9_242 {
  display: flex;
  flex-direction: column;
}
._FilterBox_1c1i9_234 ._FilterSet_1c1i9_242 ._FilterRow_1c1i9_246 {
  display: flex;
  margin: 5px 0;
}
._FilterBox_1c1i9_234 ._filters_1c1i9_250,
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 {
  background: #e0eef9;
  cursor: pointer;
  min-height: 44px;
  border-radius: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 6px;
}
._FilterBox_1c1i9_234 ._filters_1c1i9_250 ._filter_1c1i9_250,
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250 {
  height: 32px;
  background: #3996e0;
  color: white;
  margin: 2px;
  padding: 2px 4px 2px 4px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._FilterBox_1c1i9_234 ._filters_1c1i9_250 ._filter_1c1i9_250:hover,
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250:hover {
  background-color: #2182d0;
}
._FilterBox_1c1i9_234 ._filters_1c1i9_250 ._filter_1c1i9_250 ._filterText_1c1i9_277,
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250 ._filterText_1c1i9_277 {
  margin-right: 4px;
}
._FilterBox_1c1i9_234 ._filters_1c1i9_250 ._filter_1c1i9_250 ._filterCircle_1c1i9_281,
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250 ._filterCircle_1c1i9_281 {
  background: rgba(255, 255, 255, 0.2);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: 8px;
}
._FilterBox_1c1i9_234 ._filters_1c1i9_250 ._filter_1c1i9_250 ._filterCircle_1c1i9_281._right_1c1i9_292,
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250 ._filterCircle_1c1i9_281._right_1c1i9_292 {
  margin-right: 0;
  margin-left: 4px;
}
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 {
  background: rgba(234, 146, 175, 0.2);
}
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251._disabled_1c1i9_300 {
  background: #E9E8EA;
}
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250 {
  background: #ef5682;
}
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250:hover {
  background-color: #cc1348;
}
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250._disabled_1c1i9_300 {
  background: #aeaeae;
}
._FilterBox_1c1i9_234 ._compareFilters_1c1i9_251 ._filter_1c1i9_250._disabled_1c1i9_300:hover {
  background-color: #aeaeae;
  pointer-events: none;
}
._FilterBox_1c1i9_234 ._addFilterButton_1c1i9_316 {
  height: 36px;
  width: 36px;
  background: linear-gradient(180deg, #3996e0 0%, #6ab8f6 100%);
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 18px;
  margin: 4px;
  cursor: pointer;
}
._FilterBox_1c1i9_234 ._addFilterButton_1c1i9_316:hover {
  background: linear-gradient(180deg, #2182d0 0%, #44a6f4 100%);
}
._FilterBox_1c1i9_234 ._addFilterButton_1c1i9_316._compare_1c1i9_251 {
  background: linear-gradient(180deg, #ef5682 0%, #ffe9d9 100%);
}
._FilterBox_1c1i9_234 ._addFilterButton_1c1i9_316._compare_1c1i9_251:hover {
  background: linear-gradient(180deg, #eb3166 0%, #ffd1b0 100%);
}
._FilterBox_1c1i9_234 ._addFilterButton_1c1i9_316._disabled_1c1i9_300 {
  background: #E9E8EA;
}
._FilterBox_1c1i9_234 ._addFilterButton_1c1i9_316._disabled_1c1i9_300:hover {
  background: #E9E8EA;
  pointer-events: none;
}
._FilterBox_1c1i9_234 ._compareButton_1c1i9_345 {
  color: #3996e0;
}
._FilterBox_1c1i9_234 ._compareButton_1c1i9_345:active,
._FilterBox_1c1i9_234 ._compareButton_1c1i9_345 :hover {
  color: #3996e0;
}
._FilterBox_1c1i9_234 ._compareIcon_1c1i9_352 {
  color: #3996e0;
}

._DateFilterPopover_1c1i9_356 {
  z-index: 9000 !important;
}

._NetworkFilterIcons_1c1i9_360 {
  color: white;
  height: 36px;
  width: 36px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._NetworkFilterIcons_1c1i9_360._tiktok_1c1i9_369 {
  color: black;
}
._NetworkFilterIcons_1c1i9_360._blog_1c1i9_372 {
  color: black;
}

._NetworkFilterLabelContainer_1c1i9_376 {
  display: flex;
  color: #1c1c1c;
  align-items: center;
}

._NetworkFilterIconLabels_1c1i9_382 {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  margin-left: -0.8125rem;
  justify-content: center;
}

._BrandFilterImages_1c1i9_391 {
  height: 36px;
  width: 36px;
  border-radius: 2px;
  margin-right: 10px;
}

._MenuList_1c1i9_398 {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 342px;
}
._MenuList_1c1i9_398 .ant-divider {
  margin: 8px 0;
}

._MenuItem_1c1i9_408 {
  display: flex;
  align-items: center;
  min-height: 1.375rem;
  padding: 0.25rem 1rem 0.25rem 1rem;
  font-size: 0.875rem;
}
._MenuItem_1c1i9_408:hover:not(._isDisabled_1c1i9_229) {
  background-color: rgba(233, 232, 234, 0.5);
  color: #3996e0;
  cursor: pointer;
  border-radius: 0rem;
}
._MenuItem_1c1i9_408._selected_1c1i9_421 {
  color: #3996e0;
}

._CheckboxMenuItem_1c1i9_425 {
  display: flex;
  align-items: center;
  min-height: 1.375rem;
  padding: 0.25rem 0rem 0.25rem 1rem;
  font-size: 0.875rem;
}
._CheckboxMenuItem_1c1i9_425:hover:not(._isDisabled_1c1i9_229) {
  background-color: rgba(233, 232, 234, 0.5);
  color: #3996e0;
  cursor: pointer;
  border-radius: 0rem;
}
._CheckboxMenuItem_1c1i9_425._selected_1c1i9_421 {
  color: #3996e0;
}

._customRange_1c1i9_442 ._customRangeLabel_1c1i9_442 {
  margin: 0;
  height: 1.375rem;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: normal;
}
._customRange_1c1i9_442:hover ._customRangeLabel_1c1i9_442, ._customRange_1c1i9_442._selected_1c1i9_421 ._customRangeLabel_1c1i9_442 {
  color: #3996e0;
}
._customRange_1c1i9_442:hover ._datePicker_1c1i9_452 > div {
  background-color: #e6edf2 !important;
}
._customRange_1c1i9_442 ._customRangeContent_1c1i9_455 {
  padding-right: 0 !important;
}
._customRange_1c1i9_442 p {
  margin: 0.25rem 0;
  font-size: 0.75rem;
}
._customRange_1c1i9_442 ._dateRange_1c1i9_462 {
  display: flex;
  flex-direction: row;
}
._customRange_1c1i9_442 ._dateRange_1c1i9_462 > div:first-of-type {
  margin-right: 0.5rem;
}
._customRange_1c1i9_442 ._datePicker_1c1i9_452 {
  margin: 0.25rem 0;
}
._customRange_1c1i9_442 ._datePicker_1c1i9_452 > div {
  width: 7.5rem;
  padding: 0 0.625rem;
}