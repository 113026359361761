import * as React from 'react';
import { useEffect, useState } from 'react';

import { OverlaySpinner } from '@components';
import { logger } from '@common';
import { message } from '@revfluence/fresh';

import { isNil } from 'lodash';
import { OrderRequestSetup as _OrderRequestSetup } from '../components/OrderRequestSetup/OrderRequestSetup';

import { MODES } from '../components/OrderRequestSetup/components/SetupNotice';
import { useCreateProjectConfig } from '../../hooks/useCreateProjectConfig';
import { useUpdateProjectConfig } from '../../hooks/useUpdateProjectConfig';
import { GetShopifyProjectConfigByProjectIdAndType_projectConfig as ProjectConfig } from '../../queries/types/GetShopifyProjectConfigByProjectIdAndType';
import { ProjectConfigType, UpdateProjectConfigInput } from '../../types/globalTypes';

interface IProps {
    programId: number;
    projectConfig: ProjectConfig;
    mode: MODES;
    refresh: () => void;
}

export const OrderRequestSetup: React.FC<Readonly<IProps>> = (props) => {
    const {
        programId, projectConfig, mode, refresh,
    } = props;

    const { updateProjectConfig, loading: updatingProjectConfig } = useUpdateProjectConfig();

    const { createProjectConfig, loading: creatingProjectConfig } = useCreateProjectConfig();

    const [visible, setVisible] = useState(false);
    const [projectConfigInputs, setProjectConfigInputs] = useState<Partial<UpdateProjectConfigInput>>({
        displayPrice: !!projectConfig?.priceMax,
    });

    useEffect(() => {
        if (projectConfig) {
            const displayPrice = isNil(projectConfig.displayPrice) ? !!projectConfig?.priceMax : projectConfig.displayPrice;
            setProjectConfigInputs({ ...projectConfig, displayPrice });
        }
    }, [projectConfig]);

    if (creatingProjectConfig
        || updatingProjectConfig
        || updatingProjectConfig) {
        return <OverlaySpinner />;
    }

    const updateConfigs = async () => {
        try {
            if (projectConfig) {
                await updateProjectConfig({
                    variables: {
                        id: projectConfig.id,
                        updates: {
                            priceMax: projectConfigInputs.priceMax,
                            quantityMax: projectConfigInputs.quantityMax,
                            instructionText: projectConfigInputs.instructionText,
                            displayPrice: projectConfigInputs.displayPrice,
                            shopifyConfig: {
                                tags: projectConfigInputs?.shopifyConfig?.tags,
                            },

                        },
                    },
                });
            } else {
                await createProjectConfig({
                    variables: {
                        projectConfig: {
                            displayPrice: projectConfigInputs.displayPrice,
                            instructionText: projectConfigInputs.instructionText,
                            priceMax: projectConfigInputs.priceMax,
                            projectId: programId,
                            quantityMax: projectConfigInputs.quantityMax,
                            shopifyConfig: {
                                tags: projectConfigInputs?.shopifyConfig?.tags,
                            },
                            type: ProjectConfigType.Shopify,
                        },
                    },
                });
            }

            message.success('Product Catalog was successfully set up.');
            refresh();
            setVisible(false);
        } catch (e) {
            logger.error(e);
            message.error(`There was an error ${mode === MODES.CREATE ? 'creat' : 'edit'}ing the order, please try again.`);
        }
    };

    const onPriceMaxChange = (price: number) => {
        if (price) {
            setProjectConfigInputs({ ...projectConfigInputs, priceMax: price, displayPrice: true });
        } else {
            setProjectConfigInputs({ ...projectConfigInputs, priceMax: null, displayPrice: false });
        }
    };

    const onQuantityMaxChange = (quantity: number) => {
        if (quantity) {
            setProjectConfigInputs({ ...projectConfigInputs, quantityMax: quantity });
        } else {
            setProjectConfigInputs({ ...projectConfigInputs, quantityMax: null });
        }
    };

    const onTagChange = (tags: string[]) => {
        if (tags) {
            setProjectConfigInputs({
                ...projectConfigInputs,
                shopifyConfig: {
                    tags,
                },
            });
        } else {
            setProjectConfigInputs({
                ...projectConfigInputs,
                shopifyConfig: {
                    tags: [],
                },
            });
        }
    };

    return (
      <>
        <_OrderRequestSetup
          visible={visible}
          setVisible={setVisible}
          mode={mode}
          onTagChange={onTagChange}
          tags={projectConfigInputs?.shopifyConfig?.tags}
          quantityMax={projectConfigInputs?.quantityMax || null}
          priceMax={projectConfigInputs?.priceMax || null}
          onPriceMaxChange={onPriceMaxChange}
          onQuantityMaxChange={onQuantityMaxChange}
          onSubmit={updateConfigs}
          hidePrice={!projectConfigInputs?.displayPrice}
          instructionText={projectConfigInputs?.instructionText || ''}
          onInstructionTextChange={(instructionText) => setProjectConfigInputs({ ...projectConfigInputs, instructionText })}
          onHidePriceChange={(hidePrice) => setProjectConfigInputs({ ...projectConfigInputs, displayPrice: !hidePrice })}
        />
      </>
    );
};
