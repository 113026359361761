/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_12xng_157 {
  display: flex;
}

._justify-content-space-between_12xng_161 {
  justify-content: space-between;
}

._tabular-nums_12xng_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_12xng_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AddOfferDropdown_12xng_178 {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  width: 192px;
}
._AddOfferDropdown_12xng_178 ._dropdownItem_12xng_182 {
  align-items: center;
  display: flex;
}
._AddOfferDropdown_12xng_178 ._dropdownItem_12xng_182 ._icon_12xng_186 {
  align-items: center;
  display: flex;
}
._AddOfferDropdown_12xng_178 ._dropdownItem_12xng_182 ._linkIcon_12xng_190 {
  transform: rotate(-45deg);
}