/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lcrq7_157 {
  display: flex;
}

._justify-content-space-between_lcrq7_161 {
  justify-content: space-between;
}

._tabular-nums_lcrq7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lcrq7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._BackLink_lcrq7_225 {
  margin-right: 1rem;
}
._BackLink_lcrq7_225 ._icon_lcrq7_228 {
  margin-right: 1rem;
  font-size: 1.5rem;
  color: #1a1818;
}
._BackLink_lcrq7_225 span {
  margin-left: 8px;
}

._backLinkSection_lcrq7_237 {
  margin-bottom: 1rem;
}