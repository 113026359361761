import * as React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import {
  TNetworkIdentifier, NetworkIcon, LazyImage, Notice, LoadSpinner,
} from '@components';

import styles from './TopCreatorsSection.scss';

export interface ITopCreatorsData {
  username: string;
  network: TNetworkIdentifier;
  link: string;
  profile_image_url: string;
  rating: number;
  engagements: number;
  engagement_rate: number;
}
export interface IProps {
  className?: string;
  data: { loading: boolean, data?: ITopCreatorsData[], error: Error | null };
}

/**
 * @type {React.FunctionComponent}
 */
export const TopCreatorsSection: React.FunctionComponent<IProps> = React.memo(
  (props) => {
    const { loading, data, error } = props.data;
    if (error) {
      return (
        <Notice className={(styles as any).notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }
    const percentify = (value: number) => ((value * 100).toFixed(2));

    return (
      <div className={cx(styles.TopCreatorsSection, props.className)}>
        {loading ? <LoadSpinner centered /> : (
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>
                  Top Creator
                  {data && data.length > 1 ? 's' : ''}
                </h3>
              </div>
            </div>
            <div className={styles.main}>
              <div className={styles.container}>
                {map(data, (record) => (
                  <div key={record.link} className={styles.item}>
                    <a
                      className={styles.imageSection}
                      href={record.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LazyImage
                        className={styles.image}
                        src={record.profile_image_url}
                      />
                      <div className={styles.network}>
                        <NetworkIcon
                          className={styles.networkIcon}
                          identifier={record.network}
                          size={20}
                        />
                      </div>
                    </a>
                    <div className={styles.info}>
                      <div className={styles.infoItem}>
                        <a
                          className={styles.username}
                          href={record.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {record.username}
                        </a>
                      </div>
                      {/* TODO: PC-741: Uncomment when rating becomes ready on the back end}
                      {/* {record.rating && (
                        <div className={styles.infoItem}>
                          {record.rating}
                          <StarIcon className={styles.ratingIcon} size={18} />
                        </div>
                      )} */}
                      <div className={styles.infoItem}>
                        <div className={styles.type}>Engmt Rate</div>
                        <div className={styles.count}>
                          {percentify(record.engagement_rate)}
                          %
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);

TopCreatorsSection.defaultProps = {
  className: null,
};
