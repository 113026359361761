import React, {
 useEffect, useMemo, useState,
} from 'react';
import AffiliateTableSTA from '@frontend/applications/AffiliatesApp/components/AffiliateTableSTA/AffiliateTableSTA';
import {
 Button, Col, IColumnsType, IPaginationProps, Input, Row, Space, Tag,
 Tooltip,
} from '@revfluence/fresh';
import {
  isEmpty,
} from 'lodash';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  TagIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { TableHeader } from '@frontend/applications/AffiliatesApp/components';
import { useHistory, useParams } from 'react-router-dom';
import { IProductsRow } from '../../Products/types';
import { ProductStatusRenderer } from '../ProductStatusRenderer/ProductStatusRenderer';
import styles from './ProductsTable.scss';
import { NameWithImageRenderer } from '../NameWithImageRenderer/NameWithImageRenderer';
import { ProductDetailsDrawer } from '../../Products/ProductDetails/ProductDetailsDrawer';
import { useGetImportedProducts } from '../../Products/hooks/useGetProducts';
import { AssignTagsModal } from '../../Products/ProductDetails/AssignTagsModal';
import { useProductDetailsDrawerContext } from '../../Products/ProductDetails/ProductDetailsDrawerContext';
import { getCleanedTags } from '../../utils';

const PAGE_SIZE = 200;

const TagRenderer = (tags: string[]) => {
  if (isEmpty(tags)) {
    return '-';
  }
  const multipleTags = tags.length > 1 ? (
    <Tag>
      +
      {tags.length - 1}
    </Tag>
) : null;
  return (
    <Space>
      <Tag>{getCleanedTags(tags)[0]}</Tag>
      {multipleTags}
    </Space>
  );
};

export const ProductsTable = () => {
  const { collectionId } = useParams<{ collectionId: string }>();

  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [tempSearchText, setTempSearchText] = useState<string>('');

  const { selectedProductIds, setSelectedProductIds, setIsOpen: setTagsModalOpen } = useProductDetailsDrawerContext();

  const { products, loading } = useGetImportedProducts({
    variables: {
      collectionId: Number(collectionId),
      page,
      pageSize: PAGE_SIZE,
      search: searchText,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      setPage(1);
      setSearchText(tempSearchText);
    }, 1000);
    return () => clearTimeout(debouncedSearch);
  }, [tempSearchText]);

  const columnConfig = useMemo<IColumnsType<IProductsRow>>(
    () => [
      {
        title: 'Product',
        dataIndex: '_raw',
        key: 'product',
        align: 'left',
        width: 359,
        ellipsis: {
          showTitle: false,
        },
        render: NameWithImageRenderer,
      },
      {
        title: 'Variants',
        dataIndex: 'variantsCount',
        key: 'variantsCount',
        align: 'left',
        width: 116,
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'left',
        width: 200,
      },
      {
        title: 'Tags',
        dataIndex: 'tags',
        key: 'tags',
        align: 'left',
        width: 216,
        render: TagRenderer,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'left',
        width: 146,
        render: ProductStatusRenderer,
      },
    ],
    [],
  );

  const pagination: IPaginationProps = {
    total: products.totalProducts,
    pageSize: PAGE_SIZE,
    current: page,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    itemRender: (_current, type, originalElement) => {
      if (type === 'prev') {
        return <Button icon={<ChevronLeftIcon fontSize={24} />} />;
      }
      if (type === 'next') {
        return <Button icon={<ChevronRightIcon fontSize={24} />} />;
      }
      return originalElement;
    },
    showLessItems: true,
    className: 'customPagination',
    onChange: setPage,
  };

  const headerActions = useMemo(
    () => (
      <TableHeader
        renderCount={() => `${products.totalProducts} Product${products.totalProducts !== 1 ? 's' : ''}`}
      />
    ),
    [products.totalProducts],
  );

  const searchBox = () => (
    <Row gutter={9}>
      <Col>
        <Tooltip title="Assign Tags">
          <Button icon={<TagIcon />} disabled={!selectedProductIds.length} onClick={() => setTagsModalOpen(true)} />
        </Tooltip>
      </Col>
      <Col>
        <Input
          placeholder="Search"
          value={tempSearchText}
          onChange={(e) => setTempSearchText(e.target.value)}
          prefix={<MagnifyingGlassIcon />}
        />
      </Col>
    </Row>
  );

  const tableData = useMemo<IProductsRow[]>(
    () =>
      products.products.map((product) => {
        const transformedProduct = {
          key: product.id,
          id: String(product.id),
          title: product.name,
          image: product.images?.[0],
          inventory: product.inventory?.totalInventory,
          variantsCount: product.totalVariants,
          tags: product.tags,
          status: product.status,
          visibility: true,
          price: `$${product.metadata?.priceMin} - ${product.metadata?.priceMax}`,
          externalId: product.externalId?.split('Product/')[1],
        };

        if (product.metadata?.priceMin === product.metadata?.priceMax) {
          transformedProduct.price = `$ ${product.metadata?.priceMin}`;
        }

        return { ...transformedProduct, _raw: transformedProduct };
      }),
    [products],
  );

  const history = useHistory();

  return (
    <>
      <div className={styles.ProductsTable}>
        <AffiliateTableSTA<IProductsRow>
          dataSource={tableData}
          totalRows={products.totalProducts}
          columns={columnConfig}
          pagination={pagination}
          headerActions={headerActions}
          searchBox={searchBox()}
          searchText={searchText}
          sortField="name"
          className="offerTable"
          enableEditColumn
          onRow={(record) => ({
            onClick: () =>
              history.replace({
                search: `?productId=${record.id}`,
              }),
          })}
          loading={loading}
          rowSelection={{
            selectedRowKeys: selectedProductIds,
            onChange: (selectedRowKeys) => setSelectedProductIds(selectedRowKeys.map((key) => Number(key))),
          }}
        />
      </div>
      <ProductDetailsDrawer />
      <AssignTagsModal />
    </>
  );
};
