import * as React from 'react';

import { Space, Typography } from '@revfluence/fresh';
import styles from './StatisticsBlock.scss';
import { IStatisticsItem } from './types';
import { formatValue } from '../../utils';

interface IProps extends IStatisticsItem {
}

export const StatisticsItem: React.FC<Readonly<IProps>> = React.memo((props) => {
    const {
 title, value, prefix, suffix, format, baseValue, baseCurrency, baseFormat,
} = props;
    return (
      <Space className={styles.statsItemContainer}>
        <Typography.Text className={styles.statsItemTitle}>{title}</Typography.Text>
        <Typography.Text className={styles.statsItemDescription}>
          {prefix}
          {' '}
          {formatValue(format, value)}
          {suffix}
        </Typography.Text>
        {
                !!baseValue && !!baseCurrency && baseCurrency !== 'USD' && (
                <Typography.Link>
                  {`${formatValue(baseFormat, baseValue)} ${baseCurrency}`}
                </Typography.Link>
                )
            }

      </Space>
    );
});
StatisticsItem.displayName = 'StatisticsItem';
