export const composeAdTypeField = (adType: string) => {
  if (adType === 'meta_bam') {
    return 'Allowlisting';
  } else if (adType === 'ig_bc_promote') {
    return 'Branded Content Ads';
  } else if (adType === 'ig_partnership_ads') {
    return 'Paid Partnerships Ads';
  } else {
    return 'Unknown Ad Type';
  }
};
