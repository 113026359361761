import * as React from 'react';
import cx from 'classnames';

import styles from './EmptyNotice.scss';

interface IProps {
  className?: string;
}

export const EmptyNotice: React.FC<IProps> = (props) => (
  <div className={cx(styles.EmptyNotice, props.className)}>
    {props.children}
  </div>
  );
