import * as React from 'react';
import { Space } from '@revfluence/fresh';

import {
  EarIcon,
  HandshakeIcon,
  RectangleAdIcon,
  PaperPlaneIcon,
  BadgeCheckIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { InfoCard } from '@frontend/app/components';

interface IMetaSettingsInfoCardsProps {
  igdmEnabled: boolean;
  brandedContentAdsEnabled: boolean;
  adInsightsEnabled: boolean;
  allowlistingEnabled: boolean;
}

export const MetaSettingsInfoCards: React.FC<IMetaSettingsInfoCardsProps> = ({
  igdmEnabled,
  adInsightsEnabled,
  brandedContentAdsEnabled,
  allowlistingEnabled,
}) => (
  <Space direction="horizontal" align="start" size="middle" wrap>
    <InfoCard
      title="Listening"
      description="Capture brand mentions by creators across posts, stories, captions, and Partnership Content."
      icon={<EarIcon />}
    />
    <InfoCard
      title="Direct Messaging"
      description="Invite creators to projects and communicate through Instagram Direct Messages."
      icon={<PaperPlaneIcon />}
      display={igdmEnabled}
    />
    <InfoCard
      title="Instagram Ads Permissions"
      description="Request permission from creators to create Ads from UGC and boost their posts."
      icon={<HandshakeIcon />}
      display={brandedContentAdsEnabled}
    />
    <InfoCard
      title="Ads Insights"
      description="Access Meta Ads Performance"
      icon={<RectangleAdIcon />}
      display={adInsightsEnabled}
    />
    <InfoCard
      title="Allowlisting"
      description="Allow creators to grant brand partner advertising permissions."
      icon={<BadgeCheckIcon />}
      display={allowlistingEnabled}
    />
  </Space>
);
