import React from 'react';
import {
 Typography, Collapse, Table, Alert,
} from '@revfluence/fresh';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import styles from '../ManageOffer.scss';
import { TMember } from '../../MembersWizard/types';

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface IProps {
  offer: GetOfferById_offer;
  data: readonly TMember[];
  refreshColumn: CustomColumn[];
  isWorkFlow?: boolean;
}

interface CustomColumn {
  title: string;
  dataIndex: string;
  key: string;
  width?: number;
  ellipsis?: {
    showTitle: boolean;
  };
  render?: (text, record) => JSX.Element;
}

const RefreshPromoCodes: React.FC<IProps> = ({
 offer, data, refreshColumn, isWorkFlow,
}) => (
  <div className={styles.manageOfferContainer}>
    {offer.isNewFlow ? (
      <div>
        <Title level={5}>Refresh Promo Codes Names</Title>
        <Text>
          You can edit the promo code names for selected members without changing any data or impacting your
          reporting.
        </Text>
        <Collapse ghost expandIconPosition="start" defaultActiveKey={['1']}>
          <Panel header={`${data.length} ${data.length === 1 ? 'Member' : 'Members'} Selected`} key="1">
            {/* {isDuplicateError && <Notice type='error' message={'message'} />} */}
            <Table columns={refreshColumn} dataSource={data} pagination={false} />
          </Panel>
        </Collapse>
      </div>
      ) : (
        <div className={styles.noticeContainer}>
          <Alert
            message={
              isWorkFlow ? (
                'Please upgrade your offer via Sales Tracking to enable editing and access new features.'
              ) : (
                <div>
                  <Text>
                    This offer does not support refreshing promo code from this drawer. Please close this drawer and
                    click on "Refresh Promo Code" button to refresh the promo codes with active/inactive dates
                  </Text>
                  <br />
                  <Text>
                    You can also upgrade your offer to support new functionalities.
                    <a
                      href="https://help.aspireiq.com/en/articles/8535207-how-to-upgrade-existing-promo-code-offers"
                      target="_blank"
                    >
                      {' '}
                      Learn more
                    </a>
                  </Text>
                </div>
              )
            }
            type="error"
            icon={<ExclamationCircleFilled width={36} height={36} />}
          />
        </div>
      )}
  </div>
  );

export default RefreshPromoCodes;
