import * as React from 'react';
import Helmet from 'react-helmet';

import { logger } from '@common';

const {
 useRef, useCallback, useState, useEffect,
} = React;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TRecaptchaSuccessCallback = (...args: any[]) => void;

interface IRecaptchaContext {
  onRecaptchaSuccessRef: React.MutableRefObject<TRecaptchaSuccessCallback>;
  onSubmitRecaptcha();
  loading: boolean;
}

interface IProps {
  sitekey: string;
}

const waitForRecaptcha = (count = 0, maxAttempts = 30) => new Promise<void>((resolve) => {
    if (!window.grecaptcha?.ready && count < maxAttempts) {
      setTimeout(() => {
        resolve(waitForRecaptcha(count + 1, maxAttempts));
      }, 300);
    } else {
      resolve();
    }
  });

export const RecaptchaContext = React.createContext<IRecaptchaContext>(null);

export const RecaptchaContextProvider: React.FC<IProps> = (props) => {
  const onRecaptchaSuccessRef = useRef<TRecaptchaSuccessCallback>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const argsRef = useRef<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmitRecaptcha = useCallback((...args: any[]) => {
    onRecaptchaSuccessRef.current(...args);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argsRef]);

  const validateRecaptcha = useCallback(async (token: string) => {
    setLoading(true);

    const response = await fetch('/api/public/recaptcha', {
      method: 'post',
      body: JSON.stringify({ token }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const {
      success,
    } = await response.json();

    setLoading(false);

    if (success && onRecaptchaSuccessRef.current) {
      onRecaptchaSuccessRef.current(...argsRef.current);
    }
  }, [setLoading, onRecaptchaSuccessRef, argsRef]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).onSubmit = validateRecaptcha;
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (window as any).onSubmit;
    };
  }, [validateRecaptcha]);

  useEffect(() => {
    waitForRecaptcha().then(() => {
      window.grecaptcha?.ready(() => {
        window?.grecaptcha?.render('recaptcha', {
          sitekey: props.sitekey,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: (window as any)?.onSubmit,
          size: 'invisible',
        });
      });
    }).catch(logger.error);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: IRecaptchaContext = {
    onRecaptchaSuccessRef,
    onSubmitRecaptcha: handleSubmitRecaptcha,
    loading,
  };

  return (
    <RecaptchaContext.Provider value={value}>
      <Helmet>
        <script type="text/javascript" src="https://www.google.com/recaptcha/api.js?render=explicit" async defer />
      </Helmet>
      {props.children}
      <div id="recaptcha" />
    </RecaptchaContext.Provider>
  );
};
