export type MappedOperator = ComparisonOperator | LogicalOperator | ComboOperator;

export enum ComboOperator {
  NOT_EQUALS = 'NOT_EQUALS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  BETWEEN = 'BETWEEN',
  IS_NULL = 'IS_NULL',
  NOT_IN_THE_LAST = 'NOT_IN_THE_LAST',
  NOT_IN_THE_NEXT = 'NOT_IN_THE_NEXT',
  NOT_MORE_THAN_AGO = 'NOT_MORE_THAN_AGO',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  NOT_ENDS_WITH = 'NOT_ENDS_WITH',
}

export enum ComparisonOperator {
  CONTAINS = 'CONTAINS',
  HAS_VALUE = 'HAS_VALUE',
  EQUALS = 'EQUALS',
  IN_THE_LAST = 'IN_THE_LAST',
  IN_THE_NEXT = 'IN_THE_NEXT',
  MORE_THAN_AGO = 'MORE_THAN_AGO',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUALS = 'LESS_THAN_EQUALS',
}

export enum LogicalOperator {
  AND = 'AND',
  NOT = 'NOT',
  OR = 'OR',
}

export const isComparisonOperator = (input: ComparisonOperator | LogicalOperator): input is ComparisonOperator => input in ComparisonOperator;
export const isLogicalOperator = (input: ComparisonOperator | LogicalOperator): input is LogicalOperator => input in LogicalOperator;
