import 'css-chunk:src/components/widgets/Select/Select.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1eo00_157",
  "justify-content-space-between": "_justify-content-space-between_1eo00_161",
  "tabular-nums": "_tabular-nums_1eo00_165",
  "Select": "_Select_1eo00_169",
  "button": "_button_1eo00_169",
  "light": "_light_1eo00_217",
  "info": "_info_1eo00_222",
  "round": "_round_1eo00_228",
  "active": "_active_1eo00_232",
  "arrow": "_arrow_1eo00_235",
  "label": "_label_1eo00_238",
  "text": "_text_1eo00_244",
  "jsxLabel": "_jsxLabel_1eo00_249",
  "icon": "_icon_1eo00_254",
  "customLabel": "_customLabel_1eo00_265",
  "mask": "_mask_1eo00_268",
  "Popover": "_Popover_1eo00_277",
  "list": "_list_1eo00_281",
  "option": "_option_1eo00_281",
  "ellipsisLable": "_ellipsisLable_1eo00_306",
  "actions": "_actions_1eo00_312",
  "actionItem": "_actionItem_1eo00_320",
  "actions-enter": "_actions-enter_1eo00_327",
  "actions-enter-active": "_actions-enter-active_1eo00_330",
  "actions-exit": "_actions-exit_1eo00_334",
  "actions-exit-active": "_actions-exit-active_1eo00_337",
  "show": "_show_1eo00_1"
};