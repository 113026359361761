import Bluebird from 'bluebird';
import { ISocialAccount, IRouter } from '@components';
import { SearchTermType } from './textSearch';

export interface ISearchResults {
  socialAccounts?: ISocialAccount[];
  isLoading: boolean;
  isFeaturedSearch: boolean;
  isImageSearch?: boolean;
  isImageSearchSelected?: boolean;
  resultsCount?: number;
  currentPage: number;
  hasNextPage?: boolean;
  searchedMentions?: string;
  error?: {
    message: string;
  };
  _promise?: Bluebird<any>;
  fetchedSearchTermType?: SearchTermType;
}

export interface ISearchResultsProps {
  $state?: IRouter;

  sendBulkOffer(socialAccounts: ISocialAccount[]): void;
  exportCsv(socialAccounts: ISocialAccount[]): void;
  reportAsIncorrect(accountName: string): void;

  onCreatorSelected(socialAccount: ISocialAccount): void;
  selectedSocialAccountId: number | null;
  isImageSearchSelected?: boolean;
  showCreateFeatures?: boolean;
}
