import { useEffect, type RefObject } from 'react';

export const usePreventOverflowScroll = (scrollRef: RefObject<HTMLElement>) => {
  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      const element = scrollRef.current;
      if (!element) return;

      // Check if the scroll is at the start or end
      const atStart = element.scrollLeft === 0;
      const atEnd = element.scrollLeft + element.offsetWidth === element.scrollWidth;

      if (atStart && event.deltaX < 0) {
        // Prevent scrolling beyond the left edge
        event.preventDefault();
      } else if (atEnd && event.deltaX > 0) {
        // Prevent scrolling beyond the right edge
        event.preventDefault();
      }
    };

    // This prevents the user from having to click on the table to scroll
    const focus = () => {
      const element = scrollRef.current;
      if (element) element.focus();
    };

    const el = scrollRef.current;
    if (el) {
      el.addEventListener('wheel', handleScroll, { passive: false });
      el.addEventListener('mouseenter', focus);
      // Manually focus on mount to handle the reload case
      focus();
    }

    return () => {
      if (el) {
        el.removeEventListener('wheel', handleScroll);
        el.removeEventListener('mouseenter', focus);
      }
    };
  }, [scrollRef]);
};
