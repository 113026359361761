/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lz16h_157 {
  display: flex;
}

._justify-content-space-between_lz16h_161 {
  justify-content: space-between;
}

._tabular-nums_lz16h_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lz16h_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._appDashboardHeader_lz16h_209 {
  align-items: center;
  padding: var(--spacing-md) var(--spacing-lg);
  border-bottom: 1px solid var(--gray-5);
}
._appDashboardHeader_lz16h_209 ._logoWrap_lz16h_214 {
  display: flex;
  justify-content: space-between;
}
._appDashboardHeader_lz16h_209 ._logoWrap_lz16h_214 ._appLogoLink_lz16h_218 {
  align-items: center;
  display: flex;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
._appDashboardHeader_lz16h_209 ._logoWrap_lz16h_214 ._appLogoLink_lz16h_218:hover {
  opacity: 0.8;
}
._appDashboardHeader_lz16h_209 ._logoWrap_lz16h_214 ._appLogoLink_lz16h_218 ._appIcon_lz16h_227 {
  display: inline-block;
  width: 2rem;
  margin-right: 0.75rem;
}

._mainContent_lz16h_233 {
  overflow: auto;
  height: calc(100% - 4.5rem);
  padding: 2rem;
  padding-top: 0.5rem;
}