import * as React from 'react';
import {
  Typography, Button, Space, Col, Row,
} from '@revfluence/fresh';
import { LightbulbIcon, AngleLeftIcon, AngleRightIcon } from '@revfluence/fresh-icons/solid/esm';
import { ArrowRightIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './AspireAdvice.scss';

const { Text } = Typography;
const { useState } = React;

export interface IRecommendation {
  title: string;
  text: string;
  ctaText: string;
  ctaLink: string;
  htmlTagId: string;
}

export interface IAspireAdviceProps {
  recommendations: IRecommendation[];
  variant?: 'default' | 'stacked';
}

interface IAlertAction {
  total: number;
  index: number;
  onClick: (index: number) => void;
}

const Steps = ({
  index,
  total,
  onClick,
}: IAlertAction) => {
  const isFirst = index === 1;
  const isLast = index === total;
  return (
    <Space size={4} className={styles.AlertAction}>
      <Button
        disabled={isFirst}
        className={styles.button}
        type="text"
        onClick={() => onClick(index - 1)}
      >
        <AngleLeftIcon />
      </Button>
      <Typography.Text
        className={styles.counter}
        type="secondary"
        weight="semibold"
      >
        {index}
        /
        {total}
      </Typography.Text>
      <Button
        disabled={isLast}
        className={styles.button}
        type="text"
        onClick={() => onClick(index + 1)}
      >
        <AngleRightIcon />
      </Button>
    </Space>
  );
};

interface ILayoutProps {
  Icon: React.ReactNode;
  Actions?: React.ReactNode;
  Description: React.ReactNode;
  Title: React.ReactNode;
}

const DefaultLayout = ({
  Icon,
  Actions,
  Description,
  Title,
}: ILayoutProps) => (
  <Row className={styles.AspireAdvice} gutter={0}>
    <Col className={styles.icon}>
      {Icon}
    </Col>
    <Col flex="1">
      <Space direction="vertical" size={0} style={{ display: 'flex' }}>
        {Title}
        {Description}
      </Space>
    </Col>
    {Actions && (
      <Col className={styles.center}>
        {Actions}
      </Col>
    )}
  </Row>
);

const StackedLayout = ({
  Icon,
  Actions,
  Description,
  Title,
}: ILayoutProps) => (
  <Row className={styles.AspireAdvice} gutter={0}>
    <Col className={styles.icon}>
      {Icon}
    </Col>
    <Col flex="1">
      <Space direction="vertical" size={0} style={{ display: 'flex' }}>
        {Title}
        {Description}
        {Actions}
      </Space>
    </Col>
  </Row>
);

const Layouts: { [key in IAspireAdviceProps['variant']]: React.FC<ILayoutProps> } = {
  default: DefaultLayout,
  stacked: StackedLayout,
};

export const AspireAdvice = ({
  recommendations,
  variant = 'default',
}: IAspireAdviceProps) => {
  const [index, setIndex] = useState(1);
  const total = recommendations.length;
  const handleIndex = (newIndex: number) => {
    if (newIndex < 1) {
      setIndex(1);
    }
    if (newIndex > total) {
      setIndex(total);
    }
    setIndex(newIndex);
  };
  const isMoreThanOne = recommendations.length > 1;
  const recommendation = recommendations[index - 1];
  const Layout = Layouts[variant];
  if (!recommendation) {
    return null;
  }
  return (
    <Layout
      Icon={<LightbulbIcon fontSize={32} />}
      Title={(
        <Text weight="semibold" className={styles.title}>
          {recommendation.title}
        </Text>
      )}
      Description={(
        <>
          <Text className={styles.description}>
            {recommendation.text}
          </Text>
          <Text className={styles.link}>
            <a id={recommendation.htmlTagId} href={recommendation.ctaLink} target="_blank" rel="noopener noreferrer">
              {recommendation.ctaText}
              {' '}
              <ArrowRightIcon fontSize={10} />
            </a>
          </Text>
        </>
      )}
      Actions={isMoreThanOne && (
        <Steps index={index} total={recommendations.length} onClick={handleIndex} />
      )}
    />
  );
};
