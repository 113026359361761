/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_d4fpd_157 {
  display: flex;
}

._justify-content-space-between_d4fpd_161 {
  justify-content: space-between;
}

._tabular-nums_d4fpd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_d4fpd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NewFolderForm_d4fpd_178 {
  padding: 0 1rem 1rem;
}
._NewFolderForm_d4fpd_178._saving_d4fpd_181 {
  opacity: 0.7;
  pointer-events: none;
}
._NewFolderForm_d4fpd_178 ._icon_d4fpd_185 {
  position: absolute;
  padding: 0;
}
._NewFolderForm_d4fpd_178 ._header_d4fpd_189 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.875rem 0;
}
._NewFolderForm_d4fpd_178 ._header_d4fpd_189 > span {
  width: 100%;
  font-size: 0.875rem;
  text-align: center;
}
._NewFolderForm_d4fpd_178 ._button_d4fpd_200 {
  width: 100%;
  height: 2.25rem;
  border-radius: 0.25rem;
}
._NewFolderForm_d4fpd_178 ._form_d4fpd_205 {
  margin-bottom: 1rem;
}
._NewFolderForm_d4fpd_178 ._invalid_d4fpd_208 {
  margin: 0.25rem;
  color: #d9534f;
}
._NewFolderForm_d4fpd_178 ._inputError_d4fpd_212 {
  border-color: #d9534f;
  border-radius: 0.5rem;
}
._NewFolderForm_d4fpd_178 ._inputError_d4fpd_212:focus {
  border-color: #d9534f;
  box-shadow: none;
}
._NewFolderForm_d4fpd_178 ._input_d4fpd_212 {
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}