import * as React from 'react';

import { IOfferDetailsProps } from '@affiliates/components/OfferSummaryCard/types';
import { IProps as ConversionTrackingProps } from '@affiliates/components/ConversionTrackingPane';
import { OfferLinksConfirmation } from './OfferLinksConfirmation';
import { OfferPromoConfirmation } from './OfferPromoConfirmation';

import styles from './OfferConfirmation.scss';

interface IOfferConfirmationProps {
  conversionTrackingProps?: ConversionTrackingProps;
  offerDetails: IOfferDetailsProps;
  onRemoveOffer: () => void;
  onChangeOffer: () => void;
  canMigrateProgram?: boolean;
}
export const OfferConfirmation = (props: IOfferConfirmationProps) => {
  const {
    conversionTrackingProps,
    offerDetails,
    onRemoveOffer,
    onChangeOffer,
    canMigrateProgram,
  } = props;

  const getConfirmationPage = () => {
    if (conversionTrackingProps) {
      return (
        <OfferLinksConfirmation
          conversionTrackingProps={conversionTrackingProps}
          offerDetails={offerDetails}
          onRemoveOffer={onRemoveOffer}
          onChangeOffer={onChangeOffer}
          canMigrateProgram={canMigrateProgram}
        />
      );
    }

    return (
      <OfferPromoConfirmation
        offerDetails={offerDetails}
        onRemoveOffer={onRemoveOffer}
        canMigrateProgram={canMigrateProgram}
        onChangeOffer={onChangeOffer}
      />
    );
  };

  return (
    <div className={styles.OfferConfirmation}>
      <div className={styles.testingSection}>
        {getConfirmationPage()}
      </div>
    </div>
  );
};
