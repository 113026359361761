import {
  Button,
  IButtonProps,
  Typography,
} from '@revfluence/fresh';
import cx from 'classnames';
import {
  filter,
  omit,
} from 'lodash';
import * as React from 'react';

import styles from './FormActions.scss';

export interface IAction extends IButtonProps {
  button?: React.ReactNode;
  label?: React.ReactNode;
  buttonProps?: IButtonProps;
  disabled?: boolean;
  danger?: boolean;
  loading?: boolean;
  type?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';
  icon?: React.ReactNode;
  onClick?: () => void;
}

interface IProps {
  className?: string;
  contentClassName?: string;
  disabled?: boolean;
  primaryAction?: IAction;
  secondaryAction?: IAction;
  subtitle?: React.ReactNode;
  tertiaryAction?: IAction;
  title?: React.ReactNode;
}

const { Title, Text } = Typography;
export const FormActions: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    children,
    className,
    contentClassName,
    disabled = false,
    primaryAction,
    secondaryAction,
    subtitle,
    tertiaryAction,
    title,
  } = props;

  const actionCount = filter([
    primaryAction,
    secondaryAction,
    tertiaryAction,
  ]).length;

  return (
    <div className={cx(styles.FormActions, className, { [styles.disabled]: disabled })}>
      {title && (
        <div className={styles.titleWrapper}>
          <Title
            className={styles.title}
            level={3}
          >
            {title}
          </Title>
        </div>
      )}
      {subtitle && (
        <Text className={styles.subtitle}>
          {subtitle}
        </Text>
      )}
      <div className={cx(styles.content, contentClassName)}>
        {children}
      </div>
      {actionCount > 0 && (
        <div className={styles.actions}>
          {tertiaryAction && (
            tertiaryAction.button || (
              <Button
                type={tertiaryAction.type || 'default'}
                {...omit(tertiaryAction, 'button', 'label')}
              >
                {tertiaryAction.label}
              </Button>
            )
          )}
          {secondaryAction && (
            secondaryAction.button || (
              <Button
                type={secondaryAction.type || 'default'}
                {...omit(secondaryAction, 'button', 'label')}
              >
                {secondaryAction.label}
              </Button>
            )
          )}
          {primaryAction && (
            primaryAction.button || (
              <Button
                type={primaryAction.type || 'primary'}
                {...omit(primaryAction, 'button', 'label')}
              >
                {primaryAction.label}
              </Button>
            )
          )}
        </div>
      )}
    </div>
  );
});

FormActions.defaultProps = {
  disabled: false,
};

FormActions.displayName = 'FormActions';
