import * as React from 'react';
import xss from 'xss';
import { ApplicationPageBuilderComponent } from '@frontend/app/containers/Projects/applicationPageUtils';

import { RichTextVisualizer } from '@frontend/app/components/RichTextEditor/Visualizer';
import { defaultAbout } from '../../CustomizedTemplate/constants';
import { IQuickTemplateStyles, ITemplateProps } from '../../types';

import styles from './About.scss';

type IProps = ITemplateProps['about'] & {
  isFieldVisible: (fieldName: ApplicationPageBuilderComponent) => boolean;
  componentStyles: IQuickTemplateStyles,
};

export const About: React.FC<IProps> = React.memo((props) => (
  <div className={styles.About}>
    {
      props.isFieldVisible(ApplicationPageBuilderComponent.AboutTitle)
        && (<h2 style={props.componentStyles.heading}>{props.title || defaultAbout.title}</h2>)
    }
    {
      props.isFieldVisible(ApplicationPageBuilderComponent.AboutDescription)
        && (
        <RichTextVisualizer
          className={styles.description}
          style={props.componentStyles.body}
        >
          {xss(props.description) || xss(defaultAbout.description)}
        </RichTextVisualizer>
      )
    }
  </div>
));

About.displayName = 'QuickTemplateAbout';
