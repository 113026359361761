import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';
import * as React from 'react';

import {
  PostPerformanceCard,
  ProfileCard,
  SummaryCard,
} from '../Card';

import styles from './Content.scss';

export const PinterestContent: React.FC<{ version: CreatorSearchVersion }> = () => (
  <div className={styles.summary}>
    <ProfileCard className={styles.ProfileCard} />
    <SummaryCard className={styles.SummaryCard} />
    <PostPerformanceCard className={styles.PostPerformanceCard} />
  </div>
);

PinterestContent.displayName = 'PinterestContent';
