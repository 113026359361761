import { useMutation, MutationHookOptions } from '@apollo/client';

import { ACTIVATE_ALLOY_WORKFLOW_MUTATION } from '@frontend/app/queries';

import {
  ActivateAlloyWorkflowMutation,
  ActivateAlloyWorkflowMutationVariables,
} from '@frontend/app/queries/types/ActivateAlloyWorkflowMutation';

type IOptions = MutationHookOptions<ActivateAlloyWorkflowMutation, ActivateAlloyWorkflowMutationVariables>;

export function useActivateAlloyWorkflow(options: IOptions = {}) {
  const [activateAlloyWorkflow, { loading, error }] = useMutation<
    ActivateAlloyWorkflowMutation,
    ActivateAlloyWorkflowMutationVariables
  >(ACTIVATE_ALLOY_WORKFLOW_MUTATION, {
    ...options,
  });

  return {
    activateAlloyWorkflow,
    loading,
    error,
  };
}
