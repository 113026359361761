import * as React from 'react';

import { Form, Select } from '@revfluence/fresh';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { IAffiliateLinksFormElementProps } from '../../../types';

const { Option } = Select;

interface IProps extends IAffiliateLinksFormElementProps<'status'> { }

export const OfferStatus: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your status!' },
  ];
  return (
    <Form.Item label="Status" name={name} rules={rules}>
      <Select
        disabled={disabled}
        id={testIds.status}
        placeholder="Select"
        size="large"
      >
        <Option value="ACTIVE">
          Active
        </Option>
        <Option value="PAUSED" data-testid="postback">
          Paused
        </Option>
      </Select>
    </Form.Item>
  );
});
