import { isNull, values } from 'lodash';
import { RawDraftContentState } from 'draft-js';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { creatorPortalDomain } from '@frontend/applications/Shared/serviceHosts';
import { useGetPfaComposerMemberFields, MEMBER_FIRST_NAME, IVariable } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetPfaComposerMemberFields';
import { IOrderRequestStatus } from '../components/OrderRequestReview/utils';

export default (
  userName: string,
  clientName: string,
  orderRequestStatus: IOrderRequestStatus,
  orderHasUpdates: boolean,
  currentRequestItems: number,
): [string | null, RawDraftContentState | null] => {
  const memberFieldIds = useGetPfaComposerMemberFields();
  if (isNull(memberFieldIds)) {
    return [null, null];
  }

  const { workflowActionParameters } = useApplication();
  const { programName, programId } = workflowActionParameters;
  const creatorPortalDomainLink = `${creatorPortalDomain()}/programs/${programId}`;

  const variables: IVariable = {
    firstName: {
      field: `MEMBER_FIELDS.${memberFieldIds[MEMBER_FIRST_NAME]}`,
      key: 'FIRST NAME',
      label: 'First Name',
    },
  };

  let subject = '';
  let blocks: RawDraftContentState['blocks'] = [];

  if (orderRequestStatus === 'accept') {
    if (orderHasUpdates) {
      subject = 'Your product selection(s) have been approved with updates!';
      blocks = [
        {
            key: '8an9f',
            text: `Hello ${variables.firstName.label},`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [
              {
                offset: 6,
                length: variables.firstName.label.length,
                key: variables.firstName.key,
              },
            ],
            data: {},
        },
        {
            key: '571i2',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'dvilg',
            text: `This is ${userName} from ${clientName}. We know you’re excited to get your new item(s). I'm reaching out because the status of your order has been updated for the collaboration ${programName}.`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'ekhau',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'ag3ns',
            text: 'Order Status: Order Request Accepted.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: '518u3',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'bmv2r',
            text: `Your order is processing now. We did our best to fulfill all requested item(s), but please note that your order has been updated and will contain ${currentRequestItems} item(s).`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'a8dag',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'f36sh',
            text: 'Please let me know if you have any additional questions about this order!',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'a38ff',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: '4dvea',
            text: 'Best,',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: '9l83s',
            text: userName,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
      ];
    } else {
      subject = 'Your product selection(s) have been approved!';
      blocks = [
        {
            key: '8an9f',
            text: `Hello ${variables.firstName.label},`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [
              {
                offset: 6,
                length: variables.firstName.label.length,
                key: variables.firstName.key,
              },
            ],
            data: {},
        },
        {
            key: '571i2',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'dvilg',
            text: `This is ${userName} from ${clientName}. We know you’re excited to get your new item(s). I'm reaching out because the status of your order has been updated for the collaboration ${programName}.`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'ekhau',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'ag3ns',
            text: 'Order Status: Order Request Accepted.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: '518u3',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'bmv2r',
            text: 'Your order is processing now. You should expect to receive the exact item(s) you have requested.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'a8dag',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'f36sh',
            text: 'Please let me know if you have any additional questions about this order!',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'a38ff',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: '4dvea',
            text: 'Best,',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: '9l83s',
            text: userName,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
      ];
    }
  } else if (orderRequestStatus === 'reject') {
    subject = 'Unfortunately your product selections couldn’t be fulfilled as requested. Please submit new selections.';
    blocks = [
      {
          key: '8an9f',
          text: `Hello ${variables.firstName.label},`,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 6,
              length: variables.firstName.label.length,
              key: variables.firstName.key,
            },
          ],
          data: {},
      },
      {
          key: '571i2',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: 'dvilg',
          text: `This is ${userName} from ${clientName}. We know you’re excited to get your new item(s). I'm reaching out because the status of your order has been updated for the collaboration ${programName}.`,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: 'ekhau',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: 'ag3ns',
          text: 'Order Status: Order Request Rejected.',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: '518u3',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: 'bmv2r',
          text: 'Please click the link below and submit a new request. To ensure you select the best item(s) for this project, we review your request again and will be in touch after the review.',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
        key: 'a368h',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
          key: 'a8dag',
          text: `Make your product selections here: ${creatorPortalDomainLink}.`,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
        key: 'a36ff',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
          key: 'f36sh',
          text: 'If you have any additional questions about this request or the items it relates to, please let me know!',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: 'a38ff',
          text: '',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: '4dvea',
          text: 'Best,',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
      {
          key: '9l83s',
          text: userName,
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
      },
    ];
  }

  const entityMap: RawDraftContentState['entityMap'] = {};

  for (const variable of values(variables)) {
    entityMap[variable.key] = {
      type: 'variable',
      mutability: 'IMMUTABLE',
      data: {
        data: {
          field: variable.field,
        },
      },
    };
  }
  return [subject, { blocks, entityMap }];
};
