import { dismissNoMembersWarning, TDismissNoMembersWarningAction } from './dismissNoMembersWarning';
import { populateMembers, TPopulateMembersAction } from './populateMembers';
import { populatePrograms, TPopulateProgramsAction } from './populatePrograms';
import { TResetDefaultStateAction, resetDefaultState } from './resetDefaultState';
import { selectMembers, TSelectMembersAction } from './selectMembers';
import { TSelectProgramsAction, selectPrograms } from './selectPrograms';
import { TSetCurrentStepAction, setCurrentStep } from './setCurrentStep';
import { TSetErrorAction, setError } from './setError';
import { TUpdateDateFieldAction, updateDateField } from './updateDateField';
import { TUpdateMemberCodeAction, updateMemberCode } from './updateMemberCode';
import { TUpdateSavingState, updateSavingState } from './updateSavingState';
import { TUpdateShowCloseConfirmationAction, updateShowCloseConfirmation } from './updateShowCloseConfirmation';
import { TUpdateMemberDeepLinkAction, updateMemberDeepLink } from './updateMemberDeepLink';
import { TUpdateMemberPayoutIdAction, updateMemberPayoutId } from './updateMemberPayoutId';
import { TState } from '../types';
import { TUpdateBulkPayoutIdAction, updateBulkPayoutId } from './updateBulkPayoutId';
import { TUpdateBulkActiveDateAction, updateBulkActiveDate } from './updateBulkActiveDate';
import { TUpdateMemberActiveDateAction, updateMemberActiveDate } from './updateMemberActiveDate';
import { setShowEndDate, TSetShowEndDate } from './updateShowEndDate';
import { TSetSearchKey, updateSearchKey } from './updateSearchKey';
import { resetMember, TResetMemberAction } from './resetMember';
import { TUpdateIsLoadingAction, updateIsLoading } from './updateIsLoading';

type TRawAction =
  | TDismissNoMembersWarningAction
  | TPopulateMembersAction
  | TPopulateProgramsAction
  | TResetDefaultStateAction
  | TSelectMembersAction
  | TSelectProgramsAction
  | TSetCurrentStepAction
  | TSetErrorAction
  | TUpdateDateFieldAction
  | TUpdateMemberCodeAction
  | TUpdateSavingState
  | TUpdateMemberPayoutIdAction
  | TUpdateShowCloseConfirmationAction
  | TUpdateMemberDeepLinkAction
  | TUpdateBulkActiveDateAction
  | TUpdateBulkPayoutIdAction
  | TUpdateMemberActiveDateAction
  | TSetShowEndDate
  | TSetSearchKey
  | TResetMemberAction
  | TUpdateIsLoadingAction;

export const reducer = (state: TState, action: TRawAction): TState => {
  switch (action.type) {
    case 'DISMISS NO MEMBERS WARNING':
      return dismissNoMembersWarning(state, action);
    case 'POPULATE MEMBERS':
      return populateMembers(state, action);
    case 'POPULATE PROGRAMS':
      return populatePrograms(state, action);
    case 'RESET DEFAULT STATE':
      return resetDefaultState(state, action);
    case 'SELECT MEMBERS':
      return selectMembers(state, action);
    case 'SELECT PROGRAMS':
      return selectPrograms(state, action);
    case 'SET CURRENT STEP':
      return setCurrentStep(state, action);
    case 'SET ERROR':
      return setError(state, action);
    case 'UPDATE DATE FIELD':
      return updateDateField(state, action);
    case 'UPDATE MEMBER CODE':
      return updateMemberCode(state, action);
    case 'UPDATE MEMBER PAYOUT_ID':
      return updateMemberPayoutId(state, action);
    case 'UPDATE SAVING STATE':
      return updateSavingState(state, action);
    case 'UPDATE SHOW CLOSE CONFIRMATION':
      return updateShowCloseConfirmation(state, action);
    case 'UPDATE MEMBER DEEP LINK':
      return updateMemberDeepLink(state, action);
    case 'BULK PAYOUT ID':
      return updateBulkPayoutId(state, action);
    case 'BULK ACTIVE DATE':
      return updateBulkActiveDate(state, action);
    case 'UPDATE MEMBER ACTIVE DATE':
      return updateMemberActiveDate(state, action);
    case 'SET SHOW ENDDATE':
      return setShowEndDate(state, action);
    case 'UPDATE SEARCH KEY':
      return updateSearchKey(state, action);
    case 'RESET MEMBER':
      return resetMember(state, action);
    case 'UPDATE ISLOADING':
      return updateIsLoading(state, action);
  }
};
