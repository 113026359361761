import React, { useMemo } from 'react';
import {
  CustomChartTooltipContent,
  Empty,
  MainMetric,
  WidgetContainer,
} from '@frontend/app/refresh-components';
import { Pie, PieChart } from 'recharts';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { ChartPieSimpleIcon } from '@revfluence/fresh-icons/solid/esm';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartLegend,
  ChartLegendContent,
} from '@/shadcn/components/ui/chart';
import { useGetBudgetDashboardSpendSummaryQuery } from '../hooks';

interface AverageSpendOnCreatorWidgetProps {
  budgetId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function AverageSpendOnCreatorWidget(props: AverageSpendOnCreatorWidgetProps) {
  const { budgetId, fiscalYear, className } = props;
  const isParemetersAbsent = !budgetId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardSpendSummaryQuery({
    variables: {
      budgetId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
    fetchPolicy: 'no-cache',
  });
  const showEmptyState = useMemo(() => {
    if (
      !budgetSummary?.summary?.averageSpendData?.aboveAveragePercent
      && !budgetSummary?.summary?.averageSpendData?.belowAveragePercent
    ) {
      return true;
    }
    return false;
  }, [budgetSummary]);

  const chartData = [
    {
      source: 'aboveAvg',
      value: budgetSummary?.summary?.averageSpendData?.aboveAveragePercent || 0,
      fill: 'var(--color-aboveAvg)',
      tooltip: 'Above Average',
    },
    {
      source: 'belowAvg',
      value: budgetSummary?.summary?.averageSpendData?.belowAveragePercent || 0,
      fill: 'var(--color-belowAvg)',
      tooltip: 'Below Average',
    },
  ];

  const chartConfig = {
    percent: {
      label: 'Percent',
    },
    aboveAvg: {
      label: 'Above Average',
      color: 'hsl(var(--chart-1))',
    },
    belowAvg: {
      label: 'Below Average',
      color: 'hsl(var(--chart-2))',
    },
  } as ChartConfig;

  const emptyState = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={ChartPieSimpleIcon}
        title="No spending data available."
        description="Track your spending by creating and assigning budgets to campaigns."
      />
    </div>
  );

  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );

  const mainContent = (
    <div className="flex flex-col h-full">
      <MainMetric
        className="flex-1"
        value={budgetSummary?.summary?.averageSpendData?.averageSpentAmount || 0}
        size="medium"
        metricType="currency"
        subHeading={`Spent on ${budgetSummary?.summary?.averageSpendData?.totalMembers?.toFixed(0) || 0} creators`}
        icon={null}
      />
      {showEmptyState ? (
        emptyState
      ) : (
        <div>
          <ChartContainer config={chartConfig} className="mx-auto aspect-square max-h-[300px]">
            <PieChart>
              <ChartTooltip cursor={false} content={<CustomChartTooltipContent valueType="percent" />} />
              <Pie data={chartData} dataKey="value" nameKey="source" innerRadius={40} />
              <ChartLegend content={<ChartLegendContent nameKey="source" />} className="flex-wrap gap-2" />
            </PieChart>
          </ChartContainer>
        </div>
      )}
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Average Spend on Creators"
      className={`${className}`}
      bgColor="bg-white"
      textColor="text-gray-700"
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
