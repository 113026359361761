import * as React from 'react';

import { endpoints } from '@components';
import { IMember } from '@components';
import { useGet } from '@frontend/utils';

import { useInviteContext } from './useInviteContext';

const { useMemo } = React;

/**
 * Fetch member details
 */
export const useMember = (memberId: IMember['id']) => {
  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();

  const fetchMemberURL = useMemo(() => {
    if (memberId) {
      return `${apiEndpoint}/${endpoints.members}/${memberId}/?client_id=${clientId}`;
    }
  }, [apiEndpoint, clientId, memberId]);

  const {
    loading: isFetching,
    data: member,
    error,
  } = useGet<IMember>({ url: fetchMemberURL });

  return {
    member,
    isFetching,
    error,
  };
};
