import { IHelmetMetaTags } from '../types/MetaTags';

/** General App Config */
const appConfig = {
  // This part goes to React-Helmet for Head of our HTML
  app: {
    head: {
      title: 'Aspire',
      titleTemplate: '%s',
      meta: [
        { charset: 'utf-8' },
        { 'http-equiv': 'x-ua-compatible', 'content': 'ie=edge' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Aspire Community Platform' },
      ] as IHelmetMetaTags,
      script: [],
    },
  },
  serviceKeys: {
    googlePlacesAPIKey: 'AIzaSyDDanQRDZA0wVZMu4fkT2x3-byjD1Mm4ME',
  },
};

export {
  appConfig,
};
