import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { DELETE_SPECIFICATION_MUTATION } from '@frontend/app/queries';
import {
  DeleteSpecificationMutation,
  DeleteSpecificationMutationVariables,
} from '@frontend/app/queries/types/DeleteSpecificationMutation';

type IOptions = MutationHookOptions<DeleteSpecificationMutation, DeleteSpecificationMutationVariables>;

export const useDeleteSpecificationMutation = (options: IOptions = {}) => (
  useMutation<DeleteSpecificationMutation, DeleteSpecificationMutationVariables>(DELETE_SPECIFICATION_MUTATION, options)
);
