import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ORDERS_TYPE_BREAKDOWN_QUERY } from '../queries';
import { GetOrdersTypeBreakdown, GetOrdersTypeBreakdownVariables } from '../queries/types/GetOrdersTypeBreakdown';

type IOptions = QueryHookOptions<GetOrdersTypeBreakdown, GetOrdersTypeBreakdownVariables>;

export function useGetOrdersTypeBreakdown(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetOrdersTypeBreakdown, GetOrdersTypeBreakdownVariables>(
    GET_ORDERS_TYPE_BREAKDOWN_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    isOrdersTypeBreakdownLoading: loading,
    ordersTypeBreakdown: data?.breakdown,
    ordersTypeBreakdownError: error,
    refetchOrdersTypeBreakdown: refetch,
  };
}
