import * as React from 'react';
import cx from 'classnames';
import { Layout } from '@revfluence/fresh';
import { find } from 'lodash';
import {
  IState,
  TemplateType,
} from '@frontend/applications/TermsApp/components/ContentGuidelines/types/state';
import
  ContentGuidelinesList
from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesList/ContentGuidelinesList';
import
  ContentGuidelinesProjects
from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesProjects/ContentGuidelinesProjects';
import
  ContentGuidelinesLoading
from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesLoading/ContentGuidelinesLoading';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import {
  ISortableGuideline,
  IContentGuidelineInstruction,
  IContentGuidelineTitle,
} from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';
import { useScreenSize } from '@frontend/app/hooks/common';
import styles from './ContentGuidelinesContent.module.scss';

interface IProject {
  id: number;
  title: string;
  splashImageUrl: string;
}

interface IProps {
  projects: IProject[];
  isContentGuidelinesLoading: boolean;
  isEditMode: boolean;
  state: IState;
  onSelectProject: (projectId: number) => void;
  onSelectOtherProject: (otherProjectId: number) => void;
  onDuplicateContentGuideline: (contentGuideline: TContentGuideline) => void;
  onDeleteContentGuideline: (contentGuideline: TContentGuideline) => void;
  onSaveContentGuideline: (contentGuideline: TContentGuideline) => void;
  onUpdateContentGuidelineInstruction: (contentGuidelineInstruction: IContentGuidelineInstruction) => void;
  onSortContentGuideline: (
    id: number,
    sortableGuideline: ISortableGuideline,
  ) => void;
  onAddContentGuidelineAttachment: (
    attachment: Attachment,
    id: number,
  ) => void;
  onDeleteContentGuidelineAttachment: (
    attachments: Array<Attachment>,
    id: number,
  ) => void;
  onSelectTemplateType: (templateType: TemplateType) => void;
  onShowHideTemplatesModal: (isTemplatesModalVisible: boolean) => void;
  onNextStep: () => void;
  onPrevStep: () => void;
  onUpdateContentGuidelineLabel: (id: number, label: string) => void;
  onUpdateContentGuidelineActiveKey: (defaultActiveKey: number) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
}

const { Sider, Content } = Layout;
const { useMemo } = React;

const ContentGuidelinesContent: React.FC<IProps> = React.memo((props) => {
  const {
    projects,
    isContentGuidelinesLoading,
    isEditMode,
    state,
    onSelectProject,
    onSelectOtherProject,
    onDuplicateContentGuideline,
    onDeleteContentGuideline,
    onSaveContentGuideline,
    onUpdateContentGuidelineInstruction,
    onSortContentGuideline,
    onAddContentGuidelineAttachment,
    onDeleteContentGuidelineAttachment,
    onSelectTemplateType,
    onShowHideTemplatesModal,
    onNextStep,
    onPrevStep,
    onUpdateContentGuidelineLabel,
    onUpdateContentGuidelineActiveKey,
    onUpdateContentGuidelineTitle,
  } = props;
  const selectedProjectTitle = useMemo(() => (
    find(
      projects,
      (project) => project.id === state.projectId,
    )?.title
  ), [
    projects,
    state.projectId,
  ]);

  const { isMobile } = useScreenSize();
  const shouldFullWidthProjectList = isMobile && !state.projectId;
  const shouldHideProjectList = isMobile && state.projectId;

  return (
    <>
      <Sider
        theme="light"
        className={cx(
          styles.projectsSider,
          { [styles.fullScreenSidebar]: shouldFullWidthProjectList },
          { [styles.hideSidebar]: shouldHideProjectList },
        )}
      >
        <ContentGuidelinesProjects
          projects={projects}
          selectedProject={state.projectId}
          title="Projects"
          showActions
          onSelectProject={onSelectProject}
        />
      </Sider>
      <Content
        className={cx(
          styles.contentContainer,
          { [styles.hideContent]: shouldFullWidthProjectList },
          { [styles.fullScreenContent]: shouldHideProjectList },
        )}
      >
        { isContentGuidelinesLoading ? (
          <ContentGuidelinesLoading isMobile={isMobile} />
        ) : (
          <ContentGuidelinesList
            contentGuidelines={state.contentGuidelines}
            blankTemplates={state.blankTemplates}
            premadeTemplates={state.premadeTemplates}
            isTemplatesModalVisible={state.isTemplatesModalVisible}
            isEditMode={isEditMode}
            selectedProject={state.projectId}
            selectedOtherProject={state.otherProjectId}
            templateType={state.templateType}
            selectedProjectTitle={selectedProjectTitle}
            step={state.step}
            projects={projects}
            defaultActiveKey={state.defaultActiveKey}
            onSelectProject={onSelectProject}
            onSelectOtherProject={onSelectOtherProject}
            onDuplicateContentGuideline={onDuplicateContentGuideline}
            onSaveContentGuideline={onSaveContentGuideline}
            onDeleteContentGuideline={onDeleteContentGuideline}
            onUpdateContentGuidelineInstruction={onUpdateContentGuidelineInstruction}
            onSortContentGuideline={onSortContentGuideline}
            onAddContentGuidelineAttachment={onAddContentGuidelineAttachment}
            onDeleteContentGuidelineAttachment={onDeleteContentGuidelineAttachment}
            onSelectTemplateType={onSelectTemplateType}
            onShowHideTemplatesModal={onShowHideTemplatesModal}
            onNextStep={onNextStep}
            onPrevStep={onPrevStep}
            onUpdateContentGuidelineLabel={onUpdateContentGuidelineLabel}
            onUpdateContentGuidelineActiveKey={onUpdateContentGuidelineActiveKey}
            onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
          />
        )}
      </Content>
    </>
  );
});

ContentGuidelinesContent.displayName = 'ContentGuidelinesContent';

export default ContentGuidelinesContent;
