/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1szai_157 {
  display: flex;
}

._justify-content-space-between_1szai_161 {
  justify-content: space-between;
}

._tabular-nums_1szai_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1szai_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Media_1szai_178 ._engagementList_1szai_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._Media_1szai_178 {
  width: 100%;
  max-height: 100%;
  max-width: 450px;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}
._Media_1szai_178._instagram_1szai_196 {
  max-width: 500px;
}
._Media_1szai_178._youtube_1szai_199 {
  max-width: 600px;
}
._Media_1szai_178 ._mediaImage_1szai_202, ._Media_1szai_178 ._mediaVideo_1szai_202 {
  max-width: 100%;
}
._Media_1szai_178 ._engagementList_1szai_178 {
  list-style: none;
  padding: 0;
  margin: auto;
  max-width: 560px;
  color: #fdfdfd;
  text-align: left;
}
._Media_1szai_178 ._engagementList_1szai_178 ._engagementItem_1szai_213 > * {
  vertical-align: middle;
}
._Media_1szai_178 ._engagementList_1szai_178 ._engagementItem_1szai_213 > span {
  margin-left: 8px;
}