import { useQuery, QueryHookOptions } from '@apollo/client';

import GET_BUDGET_FISCAL_SETTINGS_QUERY from '@frontend/app/queries/GetBudgetFiscalSettings';
import { GetBudgetFiscalSettingsQuery, GetBudgetFiscalSettingsQueryVariables } from '@frontend/app/queries/types/GetBudgetFiscalSettingsQuery';

type IOptions = QueryHookOptions<GetBudgetFiscalSettingsQuery, GetBudgetFiscalSettingsQueryVariables>;

export function useGetBudgetFiscalSettings(options: IOptions) {
    const {
        loading, data: { budgetSettingsResponse } = {}, error, refetch,
} = useQuery<GetBudgetFiscalSettingsQuery, GetBudgetFiscalSettingsQueryVariables>(GET_BUDGET_FISCAL_SETTINGS_QUERY, {
  ...options,
  fetchPolicy: 'no-cache',
});

    return {
      loading,
      budgetSettingsResponse,
      error,
      refetch,
    };
  }
