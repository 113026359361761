import { throttle, isFinite } from 'lodash';

export interface IMousePosition {
  clientX: number;
  clientY: number;
}

interface IOptions {
  throttleWait?: number;
}

type TOnMoveCallback = (el: Element, position: IMousePosition, isDragging: boolean) => void;
type TCleanupCallback = () => void;

export const addDragSupportToElement = (
  el: Element,
  onMove: TOnMoveCallback,
  { throttleWait }: IOptions = {},
): TCleanupCallback => {
  let isDragging = false;

  const mouseMoveCallback = (e: MouseEvent) => {
    if (!isDragging) {
      return;
    }

    e.preventDefault();

    const { clientX, clientY } = e;
    onMove(el, { clientX, clientY }, isDragging);
  };

  const handleMouseMove = isFinite(throttleWait)
    ? throttle(mouseMoveCallback, throttleWait)
    : mouseMoveCallback;

  const handleMouseUp = (e: MouseEvent) => {
    if (!isDragging) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    isDragging = false;
    onMove(el, { clientX: null, clientY: null }, isDragging);
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { clientX, clientY } = e;
    onMove(el, { clientX, clientY }, isDragging);
    isDragging = true;
  };

  window.addEventListener('mousemove', handleMouseMove);
  window.addEventListener('mouseup', handleMouseUp);

  if (el) {
    el.addEventListener('mousedown', handleMouseDown);
  }

  return () => {
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
    el && el.removeEventListener('mousedown', handleMouseDown);
  };
};
