import * as React from 'react';
import { Empty, Typography } from '@revfluence/fresh';
import { InstagramIcon } from '@revfluence/fresh-icons/brands/esm';

import styles from '@frontend/app/components/InstagramMeta/MetaItems/MetaItems.module.scss';

const { Title } = Typography;

export const EmptyState: React.FC = () => (
  <Empty
    size="large"
    image={<InstagramIcon className={styles.InstagramIcon} />}
    description={(<Title level={5}>No connected Instagram accounts.</Title>)}
  />
);
