/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_v2li7_157 {
  display: flex;
}

._justify-content-space-between_v2li7_161 {
  justify-content: space-between;
}

._tabular-nums_v2li7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_v2li7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PopoverOverlay_v2li7_178 ._content_v2li7_178 ._header_v2li7_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ActivationPopover_v2li7_219 ._icon_v2li7_219 {
  font-size: 1.25rem;
}

._PopoverOverlay_v2li7_178 {
  width: 18.75rem;
}
._PopoverOverlay_v2li7_178 ._content_v2li7_178 {
  position: relative;
  margin: -0.75rem -1rem;
  padding: 0.9375rem 0;
}
._PopoverOverlay_v2li7_178 ._content_v2li7_178 ._header_v2li7_178 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75rem;
}
._PopoverOverlay_v2li7_178 ._content_v2li7_178 ._header_v2li7_178 ._addIcon_v2li7_239 {
  position: absolute;
  top: -0.5rem;
  right: 0.5rem;
}
._PopoverOverlay_v2li7_178 ._content_v2li7_178 ._emptyNotice_v2li7_244 {
  width: unset;
  margin: 0 1rem;
}
._PopoverOverlay_v2li7_178 ._content_v2li7_178 ._list_v2li7_248 {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 37.5rem;
}