/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  isEmpty,
  size,
  map,
  keyBy,
  filter,
  isUndefined,
} from 'lodash';
import {
  Modal,
  IModalProps,
  ModalSize,
} from '@components';
import { useAuth } from '@frontend/context/authContext';
import { useMessagingContext } from '@frontend/hooks';
import {
  useFuzzySearchByKeys,
  useModifyProgramMembersMutation,
  useFeatureFlagVerbiage,
  useProgramsQuery,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';

import {
  SelectList,
  FormActions,
  EllipsisLabel,
} from '@frontend/app/components';
import {
  GetAllPrograms_programs as IProgram,
} from '@frontend/app/queries/types/GetAllPrograms';
import { MemberProgramsAndCommunitiesQuery_member_programs } from '@frontend/app/queries/types/MemberProgramsAndCommunitiesQuery';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';

import { ClientFeature } from '@frontend/app/constants';
import styles from './ModifyProgramMembersModal.scss';

const {
  useState,
  useEffect,
  useMemo,
} = React;

type TType = 'add' | 'remove';

interface IProps extends IModalProps {
  query?: IMemberSearchQuery;
  memberIds?: number[];
  memberCount?: number;
  type: TType;
  onModifyComplete?(programs: IProgram[] | MemberProgramsAndCommunitiesQuery_member_programs[], memberIds?: number[]);
}

const SEARCH_KEYS = ['title'];

export const ModifyProgramMembersModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const [selectedProgramIds, setSelectedProgramIds] = useState<number[]>([]);
  const [type, setType] = useState<TType>(props.type);
  const verbiage = useFeatureFlagVerbiage();
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const { clientInfo } = useAuth();

  const {
    showMessage,
    showError,
  } = useMessagingContext();

  useEffect(() => {
    if (props.show) {
      setType(props.type);
    } else {
      setTimeout(() => {
        setType(props.type);
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const {
    data: {
      programs = [],
    } = {},
    loading: isLoadingPrograms,
  } = useProgramsQuery({
    skip: !props.show,
  });

  const filteredActivePrograms = useMemo(() => (
    filter(
      programs,
      (program) => program.status === ProjectStatus.Active,
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [programs, ProjectStatus.Active]);

  const [addMembersToPrograms, {
    loading: addingMembers,
  }] = useModifyProgramMembersMutation('add', {
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount > 1 ? `${memberCount} Members are` : `${memberCount} Member is`} being added to your ${verbiage.program}, feel free to continue working`,
      });
    },
    onError(error) {
      showError(error);
    },
  });

  const [removeMembersFromPrograms, {
    loading: removingMembers,
  }] = useModifyProgramMembersMutation('remove', {
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount} member${memberCount > 1 ? 's' : ''} removed from ${verbiage.programs}`,
      });
    },
    onError(error) {
      showError(error);
    },
  });

  const handleSearch = useFuzzySearchByKeys(filteredActivePrograms, SEARCH_KEYS);

  type TOption = typeof programs[0];

  const mapOptionToLabel = (program: TOption) => (
    <div className={styles.label}>
      <EllipsisLabel tooltipPlacement="right">
        {program.title}
      </EllipsisLabel>
    </div>
    );

  const memberCount = size(props.memberIds) || props.memberCount;

  const addRemoveMembers = async (programIds: number[]) => {
    const vars = isEmpty(props.memberIds) ? {
      query: props.query,
    } : {
      memberIds: props.memberIds,
    };

    if (type === 'add') {
      await addMembersToPrograms({
        variables: {
          ...vars,
          programIds,
          status: 'approved',
          clientId: clientInfo?.id,
        },
      });
    } else {
      await removeMembersFromPrograms({
        variables: {
          ...vars,
          programIds,
        },
      });
    }
  };

  const addEvent = useEventContext();
  const handleSave = async (programIds: number[]) => {
    if (!isEmpty(props.memberIds) || props.query) {
      await addRemoveMembers(programIds);
    }

    if (props.onModifyComplete) {
      const programsById = keyBy(programs, 'id');
      const selectedPrograms = map(programIds, (id) => programsById[id]);
      props.onModifyComplete(selectedPrograms, props.memberIds);
    }

    if (props.onRequestClose) {
      props.onRequestClose();
    }

    const action = type === 'add' ? 'add_to_project' : (type === 'remove' ? 'remove_from_project' : null);
    if (action) {
      addEvent(
        EventName.CompleteBulkAction,
        {
          action,
          member_count: memberCount,
        },
      );
    }
  };

  const primaryActionText = useMemo(() => {
    if (isUndefined(type) || isUndefined(memberCount)) return;

    let action;
    if (type === 'add') {
      action = 'Add';
    } else if (type === 'remove') {
      action = 'Remove';
    }

    return action;
  }, [type, memberCount]);

  const title = useMemo(() => {
    if (isUndefined(type)) return;

    if (type === 'add') {
      return `Add to ${verbiage.Programs}`;
    }

    if (type === 'remove') {
      return `Remove from ${verbiage.Programs}`;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const subtitle = useMemo(() => {
    if (isUndefined(type) || isUndefined(memberCount)) return;

    let action;
    if (type === 'add') {
      action = 'Add';
    } else if (type === 'remove') {
      action = 'Remove';
    }

    let text;
    if (memberCount === 0) {
      text = `${action} Members`;
    } else if (memberCount === 1) {
      text = `${action} 1 Member`;
    } else {
      text = `${action} ${memberCount} Members`;
    }

    if (type === 'add') {
      return `${text} to ${verbiage.Programs}`;
    } else if (type === 'remove') {
      return `${text} from ${verbiage.Programs}`;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, memberCount]);

  const primaryType = useMemo(() => {
    if (isUndefined(type)) return;
    if (type === 'add') return 'primary';
    if (type === 'remove') return 'danger';
  }, [type]);

  const emptyMessage = useMemo(() => {
    if (isUndefined(type)) return;
    if (type === 'add') return `There are no available ${verbiage.programs} to add to`;
    if (type === 'remove') return `There are no available ${verbiage.programs} to remove from`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const disabled = useMemo(() => {
    if (isUndefined(addingMembers) || isUndefined(removingMembers)) return;

    return addingMembers || removingMembers;
  }, [addingMembers, removingMembers]);

  return (
    <Modal
      width={ModalSize.Small}
      className={styles.ModifyProgramMembersModal}
      onRequestClose={props.onRequestClose}
      show={props.show}
      showCloseIcon={props.showCloseIcon}
    >
      <FormActions
        title={title}
        subtitle={subtitle}
        primaryAction={{
          danger: primaryType === 'danger',
          disabled: isEmpty(selectedProgramIds),
          label: primaryActionText,
          onClick: handleSave.bind(this, selectedProgramIds),
        }}
        tertiaryAction={{
          label: 'Cancel',
          onClick: props.onRequestClose,
        }}
        disabled={disabled}
      >
        <SelectList
          searchPlaceholder={`Search ${verbiage.programs}...`}
          showSearch
          onSearchRequest={handleSearch}
          options={isArchiveProjectEnabled
            ? filteredActivePrograms
            : programs}
          mapOptionToId={(program: TOption) => program.id}
          mapOptionToLabel={mapOptionToLabel}
          disabled={disabled}
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          onChange={setSelectedProgramIds as any}
          contentClassName={styles.content}
          isLoading={isLoadingPrograms}
          emptyMessage={emptyMessage}
        />
      </FormActions>
    </Modal>
  );
});
