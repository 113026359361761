import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Button } from '@revfluence/fresh';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { SettingsIcon } from '@components';
import { useGetBudgetAccountDistribution } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccountDistribution';
import { useUpsertBudgetAccountDistribution } from '@frontend/app/hooks/budgetAllocation/useUpsertBudgetAccountDistribution';
import { GetBudgetAccountDistribution_budgetAccountDistribution } from '@frontend/app/queries/types/GetBudgetAccountDistribution';
import { BuildingColumnsIcon } from '@revfluence/fresh-icons/regular/esm';
import BudgetAccountForm, { FormValues, mapToFormValues } from '../budgetAccountForm';
import PageSkeleton from '../PageSkeleton';
import styles from './masterBudgetSetup.scss';

const renderMasterBudgetIcon = () => (
  <div className={styles.iconContainer}>
    <BuildingColumnsIcon className={styles.icon} />
  </div>
);

const INITIAL_VALUES: FormValues = {
  budgetName: 'Master Budget',
  budgetDescription: 'Setting up a master account for all my campaigns on Aspire',
  fiscalYearsBudget: [],
  parentBudgetId: undefined,
};

const MasterBudgetSetup: React.FunctionComponent = () => {
  const location = useLocation();

  const { budgetAccountDistribution, loading: budgetDataLoading }: { budgetAccountDistribution: GetBudgetAccountDistribution_budgetAccountDistribution[], loading: boolean } = useGetBudgetAccountDistribution({
    variables: {
      fetchMasterBudget: true,
    },
  });

  const { upsertBudgetAccountDistribution, loading: saving } = useUpsertBudgetAccountDistribution();

  const [values, setValues] = useState<FormValues>(INITIAL_VALUES);

  useEffect(() => {
    if (budgetAccountDistribution) {
      setValues(mapToFormValues(budgetAccountDistribution?.[0], INITIAL_VALUES));
    }
  }, [budgetAccountDistribution]);

  const onSave = useCallback(async () => {
    try {
      await upsertBudgetAccountDistribution({
        variables: {
          budgetAccountInput: {
            ...values,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [values, upsertBudgetAccountDistribution]);
  const isSettingsPage = useMemo(() => location.pathname.includes('settings'), [location.pathname]);

  const getPageContent = useCallback(() => (
    <BudgetAccountForm
      values={values}
      setValues={setValues}
      budgetNameDisabled
      isMasterBudget
      showBudgetToLink={false}
      loading={budgetDataLoading}
      saving={saving}
      onSave={onSave}
      title="Setting Up Your Master Budget"
      saveButtonText="Save Master Budget"
      description={"The master budget is your financial blueprint for all campaigns. It's the foundation upon which all your budget allocations and spending decisions will be built."}
      isSettingsPage={isSettingsPage}
    />
    ), [values, setValues, budgetDataLoading, saving, onSave, isSettingsPage]);

  const getHeaderRightSection = useCallback(() => (
    <Link
      to={{ ...location, pathname: isSettingsPage ? '/settings/budget/fiscalYearSettings' : '/budget/fiscalYearSettings' }}
    >
      <Button className={styles.fiscalYearSettings}>
        <SettingsIcon size={12} />
        <span>Manage Fiscal Year</span>
      </Button>
    </Link>
    ), [location, isSettingsPage]);
  return (
    <PageSkeleton
      title="Master Budget Setup"
      backRedirectLink={isSettingsPage ? '/settings/budget/dashboard' : '/budget/dashboard'}
      renderIcon={renderMasterBudgetIcon}
      getHeaderRightSection={getHeaderRightSection}
    >
      {getPageContent()}
    </PageSkeleton>
  );
};

export default MasterBudgetSetup;
