import gql from 'graphql-tag';
import { TERMS_TEMPLATE_FRAGMENT } from './fragments/TermsTemplateFragment';

export const GET_DEFAULT_PROGRAM_TEMPLATE = gql`
  query GetDefaultProgramTemplateQuery($programId: Int!, $termsType: TermsType) {
    defaultTemplate: getDefaultProgramTemplate(programId: $programId, termsType: $termsType) {
      ...TermsTemplateFragment
    }
  }
  ${TERMS_TEMPLATE_FRAGMENT}
`;
