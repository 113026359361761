import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const UniqueImpressions = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g id="icon/outline/group">
      <path
        id="Vector"
        d="M12.02 12.507C13.7365 12.507 15.1337 11.0299 15.1337 9.23353C15.1337 7.43713 13.7365 6 12.02 6C10.3034 6 8.90619 7.47705 8.90619 9.27345C8.90619 11.0699 10.3034 12.507 12.02 12.507ZM12.02 7.11776C13.1377 7.11776 14.016 8.07585 14.016 9.27345C14.016 10.4711 13.1377 11.4291 12.02 11.4291C10.9022 11.4291 10.024 10.4711 10.024 9.27345C10.024 8.07585 10.9022 7.11776 12.02 7.11776Z"
        fill={props.fill}
      />
      <path
        id="Vector_2"
        d="M14.0958 13.3852C13.8164 13.2655 13.497 13.3453 13.3772 13.6247C13.2575 13.9042 13.3373 14.2235 13.6168 14.3433C14.6547 14.8623 15.3333 15.9002 15.493 17.2974H8.50699C8.66667 15.98 9.30539 14.9421 10.3433 14.4231C10.6228 14.2635 10.7026 13.9441 10.5828 13.6647C10.4232 13.3852 10.1038 13.3054 9.82435 13.4251C8.26746 14.2235 7.38922 15.8603 7.38922 17.8164C7.38922 18.1357 7.62874 18.3752 7.9481 18.3752H16.0918C16.2515 18.3752 16.3713 18.2954 16.491 18.2156C16.6108 18.0958 16.6507 17.976 16.6507 17.8164C16.5309 15.7006 15.6527 14.1437 14.0958 13.3852Z"
        fill={props.fill}
      />
      <path
        id="Vector_3"
        d="M18.8862 13.2655C18.008 13.2655 17.4491 13.4651 17.2495 13.8643C17.1297 14.1437 17.1697 14.4631 17.3693 14.7026C17.6088 15.022 18.1677 16.0599 18.1677 16.4591C18.1677 16.6188 18.2076 16.7385 18.3273 16.8583C18.4471 16.978 18.5669 17.018 18.7265 17.018C18.7265 17.018 20.6427 17.018 21.4411 17.018C21.6008 17.018 21.7206 16.978 21.8403 16.8583C21.9601 16.7385 22 16.6188 22 16.4591C21.9601 14.5429 20.7225 13.2655 18.8862 13.2655ZM19.2056 15.9401C19.0459 15.4212 18.7665 14.7824 18.487 14.3832C18.6068 14.3832 18.7265 14.3433 18.8862 14.3433C19.9241 14.3433 20.6028 14.9022 20.8423 15.9002C20.2834 15.9401 19.6447 15.9401 19.2056 15.9401Z"
        fill={props.fill}
      />
      <path
        id="Vector_4"
        d="M18.8862 12.7066C20.2036 12.7066 21.2415 11.6287 21.2415 10.3114C21.2415 8.99401 20.1637 7.91617 18.8862 7.91617C17.6088 7.91617 16.5309 8.99401 16.5309 10.3114C16.5309 11.6287 17.6088 12.7066 18.8862 12.7066ZM18.8862 9.03393C19.6048 9.03393 20.1637 9.59282 20.1637 10.3114C20.1637 11.0299 19.6048 11.5888 18.8862 11.5888C18.1677 11.5888 17.6088 11.0299 17.6088 10.3114C17.6088 9.59282 18.2076 9.03393 18.8862 9.03393Z"
        fill={props.fill}
      />
      <path
        id="Vector_5"
        d="M5.11377 13.2655C3.31737 13.2655 2.07984 14.503 2 16.4591C2 16.6188 2.03992 16.7385 2.15968 16.8583C2.27944 16.978 2.43912 17.018 2.55888 17.018C3.35729 17.018 5.27345 17.018 5.27345 17.018C5.43313 17.018 5.55289 16.978 5.67265 16.8583C5.79242 16.7385 5.83234 16.6188 5.83234 16.4591C5.83234 16.0599 6.3513 15.022 6.63074 14.7026C6.83034 14.4232 6.87026 14.1437 6.7505 13.8643C6.5509 13.505 6.03194 13.2655 5.11377 13.2655ZM4.83433 15.9401C4.51497 15.9401 4.07585 15.9401 3.63673 15.9401C3.47705 15.9401 3.31737 15.9401 3.1976 15.9401C3.39721 14.9421 4.11577 14.3832 5.11377 14.3832C5.27345 14.3832 5.39321 14.3832 5.51297 14.4232C5.27345 14.8224 4.99401 15.4212 4.83433 15.9401Z"
        fill={props.fill}
      />
      <path
        id="Vector_6"
        d="M5.11377 12.7066C6.43114 12.7066 7.46906 11.6287 7.46906 10.3114C7.46906 8.99401 6.39122 7.91617 5.11377 7.91617C3.83633 7.91617 2.75848 8.99401 2.75848 10.3114C2.75848 11.6287 3.83633 12.7066 5.11377 12.7066ZM5.11377 9.03393C5.83234 9.03393 6.39122 9.59282 6.39122 10.3114C6.39122 11.0299 5.83234 11.5888 5.11377 11.5888C4.39521 11.5888 3.83633 11.0299 3.83633 10.3114C3.83633 9.59282 4.43513 9.03393 5.11377 9.03393Z"
        fill={props.fill}
      />
    </g>
  </SvgIcon>
));

UniqueImpressions.displayName = 'UniqueImpressions';

export { UniqueImpressions };
