/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xn47_157 {
  display: flex;
}

._justify-content-space-between_1xn47_161 {
  justify-content: space-between;
}

._tabular-nums_1xn47_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xn47_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferConfirmation_1xn47_178 {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}
._OfferConfirmation_1xn47_178 ._secondaryButton_1xn47_183.ant-btn {
  width: 100%;
}
._OfferConfirmation_1xn47_178 ._ConversionTrackingPane_1xn47_186 {
  max-width: none;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 {
  display: flex;
  flex-direction: row;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 h3 {
  color: #1a1818;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 p {
  color: #aeaeae;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 ._pixelCodeSection_1xn47_199 {
  display: flex;
  flex-direction: column;
  width: 50%;
  display: flex;
  align-content: space-between;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 ._pixelCodeSection_1xn47_199 ._buttons_1xn47_206 {
  display: block;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 ._pixelCodeSection_1xn47_199 ._buttons_1xn47_206 button {
  margin-right: 0.625rem;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 ._summaryCard_1xn47_214 {
  margin-left: 1.6875rem;
  min-width: 30rem;
}
._OfferConfirmation_1xn47_178 ._testingSection_1xn47_189 ._buttons_1xn47_206 {
  padding-top: 1.125rem;
  display: flex;
  gap: 0.625rem;
  justify-content: space-between;
}
._OfferConfirmation_1xn47_178 ._instructionSection_1xn47_224 {
  display: flex;
  flex-direction: row;
}
._OfferConfirmation_1xn47_178 ._instructionSection_1xn47_224 ._instructions_1xn47_228 {
  width: 50%;
}
._OfferConfirmation_1xn47_178 ._instructionSection_1xn47_224 ._instructions_1xn47_228 h4 {
  color: #1a1818;
}
._OfferConfirmation_1xn47_178 ._instructionSection_1xn47_224 ._instructions_1xn47_228 li {
  color: #aeaeae;
}
._OfferConfirmation_1xn47_178 ._instructionSection_1xn47_224 ._video_1xn47_237 {
  margin-left: 1.6875rem;
  width: 36.25rem;
  max-height: 26.375rem;
}
._OfferConfirmation_1xn47_178 ._instructionSection_1xn47_224 ._video_1xn47_237 ._iframe_1xn47_242 {
  width: 100%;
  max-height: 100%;
}