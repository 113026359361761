import * as React from 'react';
import cx from 'classnames';
import { Input, Button, SpinnerIcon } from '@components';

import { useAddWooAccount } from '../hooks/useAddWooAccount';

import styles from './WooAddNew.scss';

const { useState, useCallback } = React;

interface IProps {
  className?: string;
}

export const WooAddNew: React.FC<IProps> = React.memo((props) => {
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const addAccount = useAddWooAccount();

  const handleAddAccount = useCallback(() => {
    setLoading(true);
    addAccount({ storeURL: name });
  }, [addAccount, name]);

  return (
    <div className={cx(styles.WooAddNew, props.className)}>
      <img src="https://storage.googleapis.com/aspirex-static-files/woo-app/woo.svg" className={styles.logo} />
      <div className={styles.title}>
        Connect Your WooCommerce Store
      </div>
      <div className={styles.description}>
        Sync and analyze WooCommerce data in Elevate to identify new and loyal customers for your communities.
      </div>
      <div className={styles.label}>
        WooCommerce Store URL
      </div>
      <Input placeholder="yourshopurl" onChange={setName} value={name} className={styles.input} />
      <Button
        label={loading ? '' : 'Connect my shop'}
        theme="primary"
        fullWidth
        disabled={!name || loading}
        onClick={handleAddAccount}
        icon={loading && <SpinnerIcon size={18} />}
      />
    </div>
  );
});

WooAddNew.displayName = 'WooAddNew';
