import * as React from 'react';
import {
  map, chain, keyBy, reject,
} from 'lodash';

import {
  ISource, IFilter,
  FilterList,
} from '@frontend/app/components';
import { SpecialFilters } from '@frontend/app/constants/specialFilters';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { useMemberFieldsWithSources } from '@frontend/app/hooks/memberList';

import { Operator, ProgramMembershipStatusType } from '@frontend/app/types/MemberSearch';

import { MemberPageSegment as ISegment } from '@frontend/app/queries/fragments/types/MemberPageSegment';
import { useMemberPageContext } from '../hooks';

import { Segment } from '../Segment/Segment';

const { useMemo } = React;

import styles from './MemberFilterList.scss';

interface IProps {
  communityId: number;
  sourcingGroupId: string;
  filters: IFilter[];
  isPredefinedSegment: boolean;
  onChangeFilters?(filters: IFilter[]): void;
  segment: ISegment | IPredefinedSegment;
  segmentFilters: IFilter[];
}

/**
 * @type {React.FC}
 */
export const MemberFilterList: React.FC<IProps> = React.memo((props) => {
  const {
    filters,
    segment,
    segmentFilters,
    isPredefinedSegment,
    ...rest
  } = props;

  const {
    communityId,
  } = useMemberPageContext();

  const { fields: fieldsWithSource, appsWithFields } = useMemberFieldsWithSources(communityId);

  const segmentFiltersById = useMemo(() => keyBy(segmentFilters, 'id'), [segmentFilters]);

  const fields = useMemo(() => (
    chain(fieldsWithSource)
      .reject({ showInFilters: false })
      .sortBy((field) => field.headerName.toLocaleLowerCase())
      .value()
  ), [fieldsWithSource]);

  const additionalSources: ISource[] = useMemo(() => map(appsWithFields, (app) => ({
    label: app.name,
    value: app.id,
  })), [appsWithFields]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isFilterDisabled = (filter: IFilter) => {
    if (filter.column === SpecialFilters.PROGRAMS && Operator.EQUAL in filter) {
      const {
        programStatus,
      } = filter[Operator.EQUAL];

      if (programStatus === ProgramMembershipStatusType.NEW) {
        return true;
      }
    }
    // disable filters that are part of the predefined segment.
    return isPredefinedSegment && !!segmentFiltersById[filter.id];
  };

  const visibleFilters = useMemo(() => reject(filters, (filter) => {
      // don't hide non-disabled filters.
      if (!isFilterDisabled(filter)) {
        return false;
      }
      // Hide highlights segment filter from filters.
      if (filter.column === SpecialFilters.HIGHLIGHTS) {
        return true;
      }
      return false;
    }), [filters, isFilterDisabled]);

  const segmentDescription = (segment as IPredefinedSegment)?.description;

  return (
    <div className={styles.MemberFilterList}>
      <div className={styles.filters}>
        <FilterList
          {...rest}
          filters={visibleFilters}
          fields={fields}
          additionalSources={additionalSources}
          isFilterDisabled={isFilterDisabled}
        />
        <Segment
          {...rest}
          className={styles.segment}
          segment={segment}
        />
      </div>
      {segmentDescription && (
        <div className={styles.description}>
          {segmentDescription}
        </div>
      )}
    </div>
  );
});

MemberFilterList.displayName = 'MemberFilterList';
