import * as React from 'react';

import {
  map, toUpper, isFunction,
} from 'lodash';

import { EventName } from '@common';
import { useSaveProjectCampaign } from '@frontend/app/hooks';
import { useEventContext } from '@frontend/app/context/EventContext';
import { UtmSource } from '@frontend/app/containers/Projects/hooks';

import {
  IRequirementsInput,
  IMarketplaceListingInput,
  ISetupInput,
  CampaignStatus,
  IObjectiveInput,
} from '../types';

import { useGetProjectLandingPageUrl } from './useGetProjectLandingPageUrl';

const { useMemo } = React;

export const useCreateMarketplaceCampaign = (customLandingPagePath: string) => {
  const addEvent = useEventContext();
  const [saveProjectCampaign] = useSaveProjectCampaign();
  const { getFullPageUrl } = useGetProjectLandingPageUrl();

  const marketplaceListingUrl = useMemo(() => (
    isFunction(getFullPageUrl) && getFullPageUrl(customLandingPagePath, UtmSource.Marketplace)
  ), [getFullPageUrl, customLandingPagePath]);

  const createMarketplaceCampaign = async (
    project,
    setupInput: ISetupInput,
    objectiveInput: IObjectiveInput,
    requirementsInput: IRequirementsInput,
    marketplaceListingInput: IMarketplaceListingInput,
    status: CampaignStatus,
  ) => {
    await saveProjectCampaign({
      variables: {
        projectId: project.id,
        campaign: {
          name: project.title,
          summary: setupInput.summary,
          product_types: requirementsInput.product_types,
          accepted_place_ids: requirementsInput.accepted_place_ids,
          splash_image_url: marketplaceListingInput.marketplace_image_url,
          offers_payment: requirementsInput.offers_payment,
          offers_product: requirementsInput.offers_product,
          offers_commission: requirementsInput.offers_commission,
          offers_other: requirementsInput.offers_other,
          external_listing_url: marketplaceListingUrl,
          status: toUpper(status),
          categories: [objectiveInput.brandCategory],
        },
      },
    });

    if (status === CampaignStatus.Publish) {
      addEvent(EventName.MarketplacePublishComplete, {
        marketplace_campaign_name: project.title,
        required_content: requirementsInput.product_types,
        location: map(requirementsInput.accepted_place_ids, 'value'),
        project_name: project.title,
        project_id: project.id,
      });
    }
  };

  return {
    createMarketplaceCampaign,
  };
};
