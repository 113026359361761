/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qiv0_157 {
  display: flex;
}

._justify-content-space-between_1qiv0_161 {
  justify-content: space-between;
}

._tabular-nums_1qiv0_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1qiv0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BulkPaymentsApp_1qiv0_178 ._title_1qiv0_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._BulkPaymentsApp_1qiv0_178 ._paymentAlert_1qiv0_188 ._warning_1qiv0_188 h6, ._BulkPaymentsApp_1qiv0_178 ._instructionTitle_1qiv0_188, ._BulkPaymentsApp_1qiv0_178 ._progress_1qiv0_188 h5 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._BulkPaymentsApp_1qiv0_178 ._paymentAlert_1qiv0_188 ._alertDescription_1qiv0_198 span, ._BulkPaymentsApp_1qiv0_178 ._progress_1qiv0_188 p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._BulkPaymentsApp_1qiv0_178 {
  height: 100%;
}
._BulkPaymentsApp_1qiv0_178 ._disabledRow_1qiv0_211 {
  background-color: #f8edee !important;
}
._BulkPaymentsApp_1qiv0_178 ._errorRow_1qiv0_214 {
  background-color: #f8edee !important;
}
._BulkPaymentsApp_1qiv0_178 ._errorRow_1qiv0_214 .Cell {
  color: #c24040 !important;
}
._BulkPaymentsApp_1qiv0_178 ._progress_1qiv0_188 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
._BulkPaymentsApp_1qiv0_178 ._progress_1qiv0_188 ._progressWidget_1qiv0_227 {
  width: 500px;
}
._BulkPaymentsApp_1qiv0_178 ._progress_1qiv0_188 ._progressWidgetLabel_1qiv0_230 {
  margin-right: 1.875rem;
}
._BulkPaymentsApp_1qiv0_178 ._instructionDescription_1qiv0_233 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: -0.4px;
}
._BulkPaymentsApp_1qiv0_178 .ant-radio-group {
  display: block;
}
._BulkPaymentsApp_1qiv0_178 ._radioGroup_1qiv0_244 {
  max-width: 25rem;
}
._BulkPaymentsApp_1qiv0_178 ._radioGroup_1qiv0_244 .ant-divider {
  margin: 1.125rem;
}
._BulkPaymentsApp_1qiv0_178 ._radioGroup_1qiv0_244 ._radio_1qiv0_244 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
._BulkPaymentsApp_1qiv0_178 ._radioGroup_1qiv0_244 ._radio_1qiv0_244 .ant-radio-wrapper {
  width: 15.8125rem;
}
._BulkPaymentsApp_1qiv0_178 ._paymentAlert_1qiv0_188 .ant-row {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}
._BulkPaymentsApp_1qiv0_178 ._paymentAlert_1qiv0_188 ._warning_1qiv0_188 h6 {
  width: 32.8125rem;
  text-align: center;
}
._BulkPaymentsApp_1qiv0_178 ._paymentAlert_1qiv0_188 ._alertDescription_1qiv0_198 {
  width: 31.25rem;
  text-align: center;
}
._BulkPaymentsApp_1qiv0_178 ._paymentAlert_1qiv0_188 ._alertDescription_1qiv0_198 ._emailLink_1qiv0_271 {
  color: #3996e0;
}
._BulkPaymentsApp_1qiv0_178 ._disabledRow_1qiv0_211 {
  background-color: #f8edee !important;
}

._bottomCaption_1qiv0_278 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.4px;
  padding-top: 1.25rem;
}
._bottomCaption_1qiv0_278 ._paymentSettingsLink_1qiv0_287 {
  color: #3996e0;
}

._statsCard_1qiv0_291 {
  height: 4.6875rem;
}
._statsCard_1qiv0_291 .ant-statistic-content-value {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height, or 161% */
  text-align: center;
  letter-spacing: -0.32px;
}
._statsCard_1qiv0_291 .ant-card {
  height: 4.6875rem;
}
._statsCard_1qiv0_291 .ant-card-body {
  height: 4.6875rem;
  padding: 14px 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}
._statsCard_1qiv0_291 .ant-card-body p {
  color: #8f8d91;
}