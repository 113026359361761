import * as React from 'react';
import { Button, Notice } from '@affiliates/AspireUI';

import { noop } from 'lodash';
import { XmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import styles from './FailedNotice.scss';

const { useMemo, useEffect, useState } = React;

interface IProps {
  message: React.ReactNode;
  onClickFix?: () => void;
  visible?: boolean;
  hideActions?: boolean;
  disabled?: boolean;
}

export const FailedNotice: React.FC<Readonly<IProps>> = (props) => {
  const {
    message,
    visible = true,
    hideActions = false,
    onClickFix = noop,
    disabled,
  } = props;

  const [dismissed, setDismissed] = useState(false);
  const actions = useMemo(() => {
    if (hideActions) {
      return null;
    }
    return (
      <div className={styles.actionsWrapper}>
        <Button
          className={styles.button}
          onClick={onClickFix}
          title="Fix Issue"
          type="default"
          disabled={disabled}
        >
          Fix Issue
        </Button>
        <Button
          className={styles.button}
          onClick={() => {
            setDismissed(true);
          }}
          title="Dismiss"
          type="text"
          icon={<XmarkIcon />}
        />
      </div>
    );
  }, [hideActions, onClickFix, disabled]);
  useEffect(() => {
    setDismissed(false);
  }, [message]);

  if (dismissed || !visible) {
    return null;
  }
  return (
    <Notice
      type="error"
      actions={actions}
      className={styles.FailedMembers}
      message={message}
    />
  );
};
