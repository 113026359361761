import * as React from 'react';
import { ApolloProvider } from '@apollo/client';

import { useAuth } from '@frontend/context/authContext';
import { OverlaySpinner } from '@components';
import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { MODES, SetupNotice } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/OrderRequestSetup/components';
import { Button } from '@revfluence/fresh';
import { useApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { useHistory } from 'react-router-dom';
import { pfaV2Routes } from '../../routes';

interface IProps {
  programId: number;
}

const _WorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
  const {
    programId,
  } = props;

  const {
    loading,
    projectConfig,
    error,
  } = useGetProjectConfigByType({
    variables: {
      projectId: programId,
      type: ProjectConfigType.ProductCatalog,
    },
    skip: !programId,
    fetchPolicy: 'network-only',
  });
  const { showErrorMessage } = useMessagingContext();

  const history = useHistory();

  const title = projectConfig?.brandCatalogs?.length ? ' You can edit the catalogs and rules for these Order Requests.' : 'Please connect at least one catalog to this project from project settings before sending an order request.';
  const description = 'Select products from your catalogs and set limits on what creators can pick for this collaboration.';
  const mode = projectConfig?.brandCatalogs?.length ? MODES.EDIT : MODES.CREATE;

  const orderRequestSetup = (
    <Button type="primary" onClick={() => history.push(pfaV2Routes.projectSettings.productCatalogs.replace(':projectId', String(programId)))}>
      {projectConfig?.brandCatalogs?.length ? 'Manage Catalogs' : 'Connect Catalogs'}
    </Button>
  );

  if (error) {
    logger.error('Error while fetching project config in BrandCatalogWorkflowNotice', error);
    showErrorMessage('Could not load project config for this project, please try again later.');
  }

  return (
    <>
      {loading ? <OverlaySpinner />
        : <SetupNotice title={title} description={description} actionComponent={orderRequestSetup} mode={mode} />}
    </>
  );
};

export const BrandCatalogWorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
  const { token } = useAuth();
  const apolloClient = useApolloClient(token);

  return (
    <ApolloProvider client={apolloClient}>
      <_WorkflowNotice {...props} />
    </ApolloProvider>
  );
};
