import { useMemo } from 'react';
import {
  IBasicStat,
  IComplexStat,
} from '@frontend/app/components/StatisticsCard';

import {
  IComplexMetric,
  ISimpleMetric,
  StatsAPIState,
} from '../types';

export interface ILabelMap<T extends IBasicStat | IComplexStat> {
  [label: string]: {
    format: IBasicStat['format'];
    stat: StatsAPIState<T extends IComplexStat ? IComplexMetric : ISimpleMetric>;
  } | undefined;
}

export const useGetBasicStatsFromLabelMap = (
  labelMap: ILabelMap<IBasicStat>,
): readonly IBasicStat[] => useMemo(() => (
  Object.keys(labelMap).reduce((statList: IBasicStat[], label: string): IBasicStat[] => {
    const entry = labelMap[label];
    if (!entry) {
      return statList;
    }

    const {
      format,
      stat,
    } = entry;

    switch (stat.state) {
      case 'loading':
        statList.push({
          format,
          hasError: false,
          label,
          value: 0,
        });
        break;
      case 'failed':
        statList.push({
          format,
          hasError: true,
          label,
          value: 0,
        });
        break;
      case 'ready':
        statList.push({
          format,
          hasError: false,
          label,
          value: stat.data.diff,
        });
        break;
      default:
        break;
    }
    return statList;
  }, [])
), [labelMap]);

// @TODO in the future there will be a difference in structure between basic and complex stats
export const useGetComplexStatsFromLabelMap = (labelMap: ILabelMap<IComplexStat>): readonly IComplexStat[] => (
  useGetBasicStatsFromLabelMap(labelMap)
);
