._VideoContent_ewbq3_1 {
  position: relative;
}
._VideoContent_ewbq3_1 ._video_ewbq3_4 {
  width: 100%;
  height: 100%;
}
._VideoContent_ewbq3_1._failed_ewbq3_8 {
  background-color: #d3d3d3;
}
._VideoContent_ewbq3_1 ._fileIconWrapper_ewbq3_11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}