import gql from 'graphql-tag';

export const MEMBER_JOIN_PROGRAM = gql`
  mutation MemberJoinProgramMutation($customLandingPagePath: String!, $fields: JSONObject!, $memberId: String, $token: String, $utmSource: String, $clientId: String, $talentAgents: [MemberAgentUpsertInput!]) {
    member: memberJoinProgram(customLandingPagePath: $customLandingPagePath, fields: $fields, memberId: $memberId, token: $token, utmSource: $utmSource, clientId: $clientId, talentAgents: $talentAgents) {
      id
      name
      email
    }
  }
`;
