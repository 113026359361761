import * as React from 'react';
import cn from 'classnames';

import {
  Row, Col, TTooltipProps, Tooltip, useBreakpoint, Space,
} from '@revfluence/fresh';

import Statistics from '../Statistics';
import Trend from '../Trend';

import styles from './Tab.module.scss';
import { TabProps } from './Tab.types';

const Tab = ({
  statisticsProps,
  trendProps,
  color,
  isActive,
  tooltip = '',
  onClick,
  showTrend,
}: TabProps) => {
  const classes = cn(styles.tab, {
    [styles[color]]: color,
    [styles.isActive]: isActive,
  });
  const tooltipProps: TTooltipProps = typeof tooltip === 'string'
    ? ({ title: tooltip } as TTooltipProps)
    : tooltip;

  const screens = useBreakpoint();
  return (
    <Tooltip {...tooltipProps}>
      <div className={classes} onClick={onClick}>
        <div className={cn(styles.content)}>
          <Row align="bottom">
            {screens.lg ? (
              <>
                <Col flex={1}>
                  <Statistics
                    {...statisticsProps}
                    className={cn(styles.statistic)}
                  />
                </Col>
                {showTrend && (
                <Col>
                  <Statistics
                    {...trendProps}
                    prefix={<Trend {...trendProps} />}
                    className={cn(styles.trend)}
                  />
                </Col>
)}
              </>
            ) : (
              <Space direction="vertical">
                <Col span={24}>
                  <Statistics
                    {...statisticsProps}
                    className={cn(styles.statistic)}
                  />
                </Col>
                {showTrend && (
                <Col span={24}>
                  <Statistics
                    {...trendProps}
                    prefix={<Trend {...trendProps} />}
                    className={cn(styles.trend)}
                  />
                </Col>
)}
              </Space>
            )}
          </Row>
        </div>
      </div>
    </Tooltip>
  );
};

export default Tab;
