import gql from 'graphql-tag';

export const CREATE_PROJECT_CONFIG_MUTATION = gql`
  mutation CreateProjectConfigMutation($projectConfig: CreateProjectConfigInput!) {
    createProjectConfig(projectConfig: $projectConfig) {
      id
    }
  }
`;
export const UPDATE_PROJECT_CONFIG_MUTATION = gql`
  mutation UpdateProjectConfigMutation($id: String!, $updates: UpdateProjectConfigInput!) {
    updateProjectConfig(id: $id, updates: $updates) {
      id
    }
  }
`;

export const GET_SHOPIFY_PROJECT_CONFIG_QUERY = gql`
  query GetShopifyProjectConfigByProjectIdAndType($projectId: Float!, $type: ProjectConfigType = Shopify) {
    projectConfig: getProjectConfigByProjectIdAndType(projectId: $projectId, type: $type) {
        id
        projectId
        instructionText
        shopifyConfig {
            productSubtitle
            productSubtitleMetafields
            tags
        }
        priceMax
        quantityMax
        displayPrice
    }
  }
`;

export const GET_PROJECT_CONFIG_BY_TYPE_QUERY = gql`
  query GetProjectConfigByProjectIdAndType($projectId: Float!, $type: ProjectConfigType!) {
    projectConfig: getProjectConfigByProjectIdAndType(projectId: $projectId, type: $type) {
        id
        projectId
        instructionText
        shopifyConfig {
            productSubtitle
            productSubtitleMetafields
            tags
        }
        noCatalogueConfig {
          additionalDetails
          formFields {
              enabled
              key
              label
              required
          }
       }
        brandCatalogs
        priceMax
        quantityMax
        displayPrice
    }
  }
`;

export const GET_NO_CATALOGUE_PROJECT_CONFIG_QUERY = gql`
  query GetNoCatalogueProjectConfigByProjectIdAndType($projectId: Float!) {
    projectConfig: getProjectConfigByProjectIdAndType(projectId: $projectId, type: NoCatalogue) {
        id
        projectId
        instructionText
        noCatalogueConfig {
            additionalDetails
            formFields {
                enabled
                key
                label
                required
            }
        }
        priceMax
        quantityMax
        displayPrice
    }
  }
`;
