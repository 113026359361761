import gql from 'graphql-tag';

export const GET_CONNECTED_SHOPIFY = gql`
  query GetConnectedShopify {
    clientConnections:getAllClientConnections {
        id
        isConnected
        isPrimary
        label
        masterClientId
        connectedClientId
        connectedAdvertiserId
        masterAdvertiserId
        isSubscriptionEligible
    }
  }
`;
