import * as React from 'react';
import { isNumber } from 'lodash';
import { useHistory } from 'react-router-dom';
import * as qs from 'qs';
import { useParsedRouterSearch } from '@frontend/app/hooks';

const {
 useState, useContext, useRef, useEffect, useMemo,
} = React;

interface IProps {
  useQueryParams?: boolean;
}

interface ICommunitySwitcherContext {
  selectedCommunityId: number;
  selectedSourcingId: string;
  setSelectedCommunityId(id: number);
  setSelectedSourcingId(id: string);
}

const CommunitySwitcherContext = React.createContext<ICommunitySwitcherContext>(null);

export const useCommunitySwitcherContext = () => useContext(CommunitySwitcherContext);

export const CommunitySwitcherProvider: React.FC<IProps> = (props) => {
  const {
    useQueryParams,
  } = props;

  const history = useHistory();

  const searchParams = useParsedRouterSearch();
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const {
    communityId: communityIdParam,
    sourcingId: sourcingIdParam,
  } = searchParams;

  let communityId: number = communityIdParam || null;
  const sourcingId: string = sourcingIdParam || null;

  if (useQueryParams && communityIdParam) {
    communityId = Number(communityIdParam);

    if (!isNumber(communityId)) {
      communityId = null;
    }
  }

  const [communityIdState, setSelectedCommunityIdState] = useState<number>(communityId);
  const [sourcingIdState, setSelectedSourcingIdState] = useState<string>(sourcingId);

  useEffect(() => {
    if (useQueryParams) {
      const newSearch = {
        ...searchParamsRef.current,
        communityId: communityIdState,
        sourcingId: sourcingIdState,
      };
      // update ref immediately in case sourceId changes simultaneously.
      searchParamsRef.current = newSearch;
      history.replace({
        pathname: '/member_table',
        search: qs.stringify(newSearch),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityIdState, sourcingIdState, useQueryParams]);

  useEffect(() => {
    if (searchParams.switchSourcing) {
      const newSearch = { sourcingId: searchParams.sourcingId || '' };
      history.replace({
        pathname: '/member_table',
        search: qs.stringify(newSearch),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // If using query params, then query params are source of truth, so changing state
  // should just change the route
  const setSelectedCommunityId = (communityId: number) => {
    if (useQueryParams) {
      const newSearch = { ...searchParamsRef.current, communityId };
      history.replace({
        pathname: '/member_table',
        search: qs.stringify(newSearch),
      });
    }
    setSelectedCommunityIdState(communityId);
  };

  const setSelectedSourcingId = (sourcingId: string) => {
    if (useQueryParams) {
      const newSearch = { ...searchParamsRef.current, sourcingId };
      history.replace({
        pathname: '/member_table',
        search: qs.stringify(newSearch),
      });
    }
    setSelectedSourcingIdState(sourcingId);
    if (sourcingId) {
      setSelectedCommunityIdState(null);
    }
  };

  const selectedCommunityId = useMemo(() => (
    useQueryParams ? communityId : communityIdState
  ), [useQueryParams, communityId, communityIdState]);

  const selectedSourcingId = useMemo(() => (
    useQueryParams ? sourcingId : sourcingIdState
  ), [useQueryParams, sourcingId, sourcingIdState]);

  return (
    <CommunitySwitcherContext.Provider
      value={{
        selectedCommunityId,
        setSelectedCommunityId,
        selectedSourcingId,
        setSelectedSourcingId,
      }}
    >
      {props.children}
    </CommunitySwitcherContext.Provider>
  );
};

CommunitySwitcherProvider.defaultProps = {
  useQueryParams: true,
};
