._HeroImage_xmgw1_1 ._image_xmgw1_1 {
  object-fit: cover;
}
._HeroImage_xmgw1_1 ._video_xmgw1_4 {
  object-fit: contain;
}
._HeroImage_xmgw1_1 ._image_xmgw1_1, ._HeroImage_xmgw1_1 ._video_xmgw1_4 {
  width: 100%;
  object-position: top center;
  aspect-ratio: 2.38;
}