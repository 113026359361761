import * as React from 'react';
import {
  Form,
  TimePicker,
} from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'activeTime'> {
}

export const OfferActiveTime: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please Select Time' },
  ];
  return (
    <>
      <Form.Item name={name} rules={rules}>
        <TimePicker
          size="large"
          disabled={disabled}
          format="hh:mm a"
        />
      </Form.Item>

    </>
  );
});
