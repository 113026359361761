import { MutationHookOptions, useMutation } from '@apollo/client';
import { UPDATE_BRAND_CATALOG_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { UpdateBrandCatalogMutation, UpdateBrandCatalogMutationVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/UpdateBrandCatalogMutation';

type IOptions = MutationHookOptions<UpdateBrandCatalogMutation, UpdateBrandCatalogMutationVariables>;

export const useUpdateCatalog = (options: IOptions = {}) => {
  const [updateCatalog, { data, loading, error }] = useMutation(UPDATE_BRAND_CATALOG_MUTATION, options);

  return {
    loading,
    catalogs: data?.updateBrandCatalog,
    updateCatalog,
    error,
  };
};
