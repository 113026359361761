import * as React from 'react';

import { SplitIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button, Tooltip } from '@revfluence/fresh';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  title: string;
}

export const ToggleCurrencyButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const { disabled, onClick, title } = props;
  return (
    <Tooltip placement="top" title={title}>
      <Button disabled={disabled} icon={<SplitIcon />} onClick={onClick} />
    </Tooltip>
  );
});

ToggleCurrencyButton.defaultProps = {
  disabled: false,
};

ToggleCurrencyButton.displayName = 'ToggleCurrencyButton';
