import * as React from 'react';

import {
  Button,
  Form,
  Tooltip,
} from '@revfluence/fresh';
import { OfferFormTestIds as testIds } from '../OfferFormTestIds';

interface IProps {
  disabled: boolean;
  loading: boolean;
  requiredFields: React.ReactNode;
}

export const UpdateButton: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  loading,
  requiredFields,
}) => (
  <Tooltip title={requiredFields}>
    <Form.Item>
      <Button
        block
        data-testid={testIds.update}
        disabled={disabled}
        htmlType="submit"
        loading={loading}
        type="primary"
        size="large"
      >
        Update Offer
      </Button>
    </Form.Item>
  </Tooltip>
));

UpdateButton.displayName = 'UpdateButton';
