import gql from 'graphql-tag';

// write mutation for creating order limitation
export const CREATE_ORDER_LIMITATION_MUTATION = gql`
  mutation CreateOrderLimitationMutation($params: ISaveOrderLimitationParams!) {
    result: createOrderLimitation(params: $params) {
      id
    }
  }`;

export const UPDATE_ORDER_LIMITATION_MUTATION = gql`
  mutation UpdateOrderLimitationMutation($params: IUpdateOrderLimitationParams!) {
    result: updateOrderLimitation(params: $params) {
      id
    }
  }`;

export const GET_ORDER_LIMITATION_QUERY = gql`
  query GetOrderLimitationQuery($projectId: Float!) {
    orderLimitation: orderLimitation(projectId: $projectId) {
      id
      tags
      rules {
        priceMax
        quantityMax
      }
    }
  }
`;
