import * as React from 'react';
import { Drawer } from 'antd';

import {
  IStepInfo as Step,
  WizardContainer as Wizard,
} from '@frontend/applications/Shared/Wizard/container';
import { TRefreshMembersProps } from '../types';
import { useRefreshMembers } from '../hooks';
import { ConfirmClose } from './ConfirmClose';
import {
  EditActiveDates as EditActiveDatesIcon,
  RefreshMembers as RefreshMembersIcon,
} from './Icons';

import styles from './MembersWizard.scss';

const { useCallback } = React;

export const RefreshMembers: React.FC<Readonly<TRefreshMembersProps>> = (props) => {
  const {
    onClose,
    refreshDatesOnly,
    visible,
    isWorkflow,
  } = props;
  const { actions, state, steps } = useRefreshMembers(props);

  const resetStateOnHide = useCallback((visible: boolean) => {
    if (!visible) {
      actions.reset();
    }
  }, [actions]);

  const handleCloseClick = useCallback(() => {
    if (state.saving) {
      return;
    }
    if (!state.error && state.currentStep > 1 && state.currentStep < 4) {
      actions.showCloseConfirmation();
      return;
    }
    onClose();
  }, [actions, onClose, state.currentStep, state.error, state.saving]);

  let contents;
  if (state.showCloseConfirmation) {
    contents = (
      <ConfirmClose
        onConfirm={onClose}
        onDismissConfirmation={actions.dismissCloseConfirmation}
      />
    );
  } else {
    contents = (
      <Wizard
        hideNavigation={!!state.error}
        icon={refreshDatesOnly ? <EditActiveDatesIcon /> : <RefreshMembersIcon />}
        onCancel={onClose}
        onStepChange={actions.setCurrentStep}
        step={state.currentStep}
        stepsInfo={steps as Step[]}
        noHeader={isWorkflow}
        noBodyPadding={isWorkflow}
      />
    );
  }
  if (isWorkflow) {
    return <div className={styles.MembersWizard}>{contents}</div>;
  }

  return (
    <Drawer
      afterVisibleChange={resetStateOnHide}
      className={styles.MembersWizard}
      closable
      destroyOnClose
      onClose={handleCloseClick}
      placement="right"
      visible={visible}
      width={1024}
    >
      {contents}
    </Drawer>
  );
};

RefreshMembers.displayName = 'RefreshMembers';
