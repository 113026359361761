/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_4b4o4_157 {
  display: flex;
}

._justify-content-space-between_4b4o4_161 {
  justify-content: space-between;
}

._tabular-nums_4b4o4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_4b4o4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._modalDialog_4b4o4_178 {
  width: 40rem;
}

._spinnerIcon_4b4o4_182 {
  margin-right: 0.25rem;
}

._primaryAction_4b4o4_186 {
  height: 2rem;
}