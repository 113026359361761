import gql from 'graphql-tag';

export const GET_CLIENT_ACTIVE_MARKETPLACE_PROJECTS_QUERY = gql`
  query GetClientActiveMarketplaceProjectsQuery {
    projects: getClientActiveMarketplaceProjects {
      id
      title
    }
  }
`;
