import * as React from 'react';
import cx from 'classnames';
import {
 map, debounce, groupBy, isEmpty, uniq,
} from 'lodash';
import {
 Checkbox, Input, List, Alert,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import styles from './SelectList.scss';

const { useState, useCallback, useMemo } = React;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TOption = any;

interface IProps {
  multi?: boolean;
  showSearch?: boolean;
  searchPlaceholder?: string;
  onSearchRequest?(searchText: string): TOption[] | Promise<TOption[]>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?(selectedIds: any[]);
  options: TOption[];
  mapOptionToLabel(option: TOption): React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapOptionToId(option: TOption): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapOptionToGroupId?(option: TOption): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapGroupIdToLabel?(groupId: any): JSX.Element | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSelectedIds?: any[];
  className?: string;
  contentClassName?: string;
  disabled?: boolean;
  isLoading?: boolean;
  emptyMessage?: React.ReactNode;
}

export interface ISelectListProps extends IProps { }

export const SelectList: React.FunctionComponent<IProps> = React.memo((props) => {
  const valueSet = useMemo(() => new Set(props.defaultSelectedIds || []), [props.defaultSelectedIds]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedValues, setSelectedValues] = useState<Set<any>>(valueSet);
  const [searchText, setSearchText] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<TOption[]>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchRequest = useCallback(
    debounce(async (text: string) => {
      if (props.onSearchRequest) {
        const result = await props.onSearchRequest(text);
        setSearchOptions(result);
      }
    }, 300),
    [props.options],
  );

  const handleChangeSearchText = (text: string) => {
    handleSearchRequest(text);
    setSearchText(text);
  };

  const handleSelectOption = (option: TOption) => {
    const newValues = new Set(selectedValues);
    const id = props.mapOptionToId(option);

    const hasValue = newValues.has(id);

    if (!props.multi) {
      newValues.clear();
    } else if (hasValue) {
      newValues.delete(id);
    }

    if (!hasValue) {
      newValues.add(id);
    }

    setSelectedValues(newValues);

    if (props.onChange) {
      props.onChange(
        Array.from(newValues),
      );
    }
  };

  const options = searchOptions || props.options;

  const groups = useMemo(() => {
    if (options && props.mapOptionToGroupId) {
      return groupBy(options, props.mapOptionToGroupId);
    }
  }, [options, props.mapOptionToGroupId]);

  const groupIds = useMemo(() => {
    if (options && props.mapOptionToGroupId) {
      return uniq(map(options, props.mapOptionToGroupId));
    }
  }, [options, props.mapOptionToGroupId]);

  const renderList = (options: TOption[]) => (
    <List
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      className={(styles as any).list}
      size="small"
      bordered={false}
      dataSource={options}
      renderItem={(option) => {
          const id = props.mapOptionToId(option);
          const handleSelect = handleSelectOption.bind(this, option);
          return (
            <Checkbox
              key={id}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              className={(styles as any).checkbox}
              checked={selectedValues.has(id)}
              onChange={() => handleSelect()}
            >
              {props.mapOptionToLabel(option)}
            </Checkbox>
          );
        }}
    />
    );

  const empty = isEmpty(groups || options);

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div className={cx(styles.SelectList, props.className, { [(styles as any).disabled]: props.disabled })}>
      {props.showSearch && (
        <Input
          className={styles.searchInput}
          size="large"
          allowClear
          placeholder={props.searchPlaceholder}
          value={searchText}
          onChange={(e) => handleChangeSearchText(e.target.value)}
          prefix={<SearchOutlined />}
        />
      )}

      <div className={props.contentClassName}>
        {!props.isLoading && empty && props.emptyMessage && (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          <Alert message={props.emptyMessage} type="info" className={(styles as any).empty} />
        )}

        {!props.isLoading && groups && !empty && map(groupIds, (groupId) => (
          <React.Fragment key={groupId}>
            {props.mapGroupIdToLabel && (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <div className={(styles as any).groupLabel}>
                {props.mapGroupIdToLabel(groupId)}
              </div>
              )}
            {renderList(groups[groupId])}
          </React.Fragment>
          ))}

        {!props.isLoading && !groups && !empty && renderList(options)}
      </div>
    </div>
  );
});

SelectList.defaultProps = {
  multi: true,
  showSearch: true,
  defaultSelectedIds: [],
  disabled: false,
  isLoading: false,
};

SelectList.displayName = 'AffiliateAppSelectList';
