import * as React from 'react';
import { Radio } from 'antd';
import cx from 'classnames';
import { isNull } from 'lodash';
import {
  Col,
  Form,
  InputNumber as Input,
  Row,
  Typography,
} from '@revfluence/fresh';

import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { OFFER_PROMO_USAGE_LIMIT_RULE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { IShopifyFormElementProps, IShopifyPromoCodeFormValues } from '../../../types';
import { validatePositiveInteger } from '../../../utils';

import styles from '../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'usageLimitRule'> {
  value: IShopifyPromoCodeFormValues['usageLimitRule'];
}
const { Text } = Typography;
export const OfferCodePurchaseRestrictions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
}) => {
  const className = cx({
    [styles.halfRow]: isNull(value),
  });
  let minAmountPrefix;
  if (value === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT) {
    minAmountPrefix = '$';
  }
  const rules = {
    [OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT]: [
      {
        message: 'Please enter a value!',
        required: value !== OFFER_PROMO_USAGE_LIMIT_RULE.NONE,
      },
      {
        validator: validatePositiveInteger,
      },
    ],
    [OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT]: [
      {
        message: 'Please enter a value!',
        required: value !== OFFER_PROMO_USAGE_LIMIT_RULE.NONE,
      },
      {
        validator: validatePositiveInteger,
      },
    ],
  };
  return (
    <>
      <div className={styles.customLabelText}>
        <Text strong>Purchase Restrictions</Text>
      </div>
      <Form.Item className={className} name={name}>
        <Radio.Group className={styles.radioGroup} disabled={disabled} name={name}>
          <Radio className={styles.radio} value={OFFER_PROMO_USAGE_LIMIT_RULE.NONE}>
            None
          </Radio>
          <Radio className={styles.radio} value={OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT}>
            Minimum Purchase Amount
          </Radio>
          {value === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT && (
            <Row>
              <Col className={styles.indented} span={24}>
                <Form.Item
                  label="Minimum Amount"
                  name="usageLimitAmount"
                  rules={rules[OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT]}
                >
                  <Input
                    className={styles.fullWidth}
                    defaultValue={0}
                    disabled={disabled}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    onKeyDown={UseNaturalNumbers}
                    prefix="$"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Radio className={styles.radio} value={OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT}>
            Minimum Quantity of Items
          </Radio>
          {value === OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_ITEM_AMOUNT && (
            <Row>
              <Col className={styles.indented} span={24}>
                <Form.Item
                  label="Minimum #"
                  name="usageLimitAmount"
                  rules={rules[OFFER_PROMO_USAGE_LIMIT_RULE.MINIMUM_PURCHASE_AMOUNT]}
                >
                  <Input
                    className={styles.fullWidth}
                    disabled={disabled}
                    min={0}
                    placeholder="0"
                    prefix={minAmountPrefix}
                    precision={0}
                    size="large"
                    type="number"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Radio.Group>
      </Form.Item>

    </>
  );
});
