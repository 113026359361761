import * as React from 'react';
import cx from 'classnames';
import {
  isArray,
  isEmpty,
  map,
} from 'lodash';

import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './Tags.scss';

export const Tags: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  const {
    network,
    socialAccount,
  } = useSocialProfileContext();

  const tags = (() => {
    if (network === 'instagram' || network === 'tiktok') {
      return isArray(socialAccount.top_hashtags)
        ? socialAccount.top_hashtags
        : [];
    }
  })();

  return !isEmpty(tags) && (
    <div className={cx(styles.Tags, className)}>
      {map(tags, (tag, index) => (
        <React.Fragment key={`tag-${index}`}>
          <span className={styles.tag}>
            {(network === 'instagram' || network === 'youtube') && !tag.startsWith('#')
              ? `#${tag}`
              : tag}
          </span>
          &nbsp;
        </React.Fragment>
      ))}
    </div>
  );
};

Tags.displayName = 'Tags';
