import React from 'react';

interface IProps {
}

export const PaymentsError: React.FC<IProps> = (props) => {
    const { } = props;
    return (
      <div>
        Error
      </div>
    );
};
