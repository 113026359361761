import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import { filter } from 'lodash';
import { IDeleteContentGuidelineAction, IState } from '../../../types/state';

export const deleteContentGuideline = (state: IState, action: IDeleteContentGuidelineAction): IState => ({
    ...state,
    contentGuidelines: filter(
      state.contentGuidelines,
      (
        contentGuideline: TContentGuideline,
      ) => contentGuideline.id !== action.contentGuideline.id,
    ),
    isEditMode: false,
    defaultActiveKey: 0,
});
