import gql from 'graphql-tag';
import { OFFER_FRAGMENT } from './fragments/OfferFragment';

export const GET_OFFERS_BY_SEARCH_QUERY = gql`
query GetOffersBySearchQuery($query: OfferSearchQuery) {
  offers(query: $query) {
    ...OfferFragment
    links {
      canEditConversionType
      affiliateStats(startDate: null, endDate: null) {
        affiliateEmail
        affiliateId
        affiliateImageUrl
        affiliateLink
        affiliateShortLink
        affiliateName
        affiliateOfferId
        aspirexMemberId
        avgSale
        clicks
        conversionRatio
        conversions
        linkCreationDate
        linkStatus
        payoutEarned
        payoutMade
        sales
      }
    }
    promos {
      affiliateStats(startDate: null, endDate: null) {
        affiliateCode
        affiliateEmail
        affiliateId
        affiliateImageUrl
        affiliateName
        affiliateOfferId
        aspirexMemberId
        avgSale
        conversions
        payoutEarned
        payoutMade
        sales
        avgSaleBase
        salesBase
        payoutEarnedBase
      }
  }
}
}
${OFFER_FRAGMENT}
`;
