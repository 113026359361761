import gql from 'graphql-tag';

export const ADVERTISER_SHOPIFY_SCOPE_CHECK_QUERY = gql`
  query AdvertiserShopifyScopeCheck {
    advertiser {
      hasShopifyScopes
      shopifyResources {
        identifier
      }
    }
  }
`;
