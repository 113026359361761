import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ORDERS_BY_IDS_QUERY } from '../queries';

import { GetOrdersByIds, GetOrdersByIdsVariables, GetOrdersByIds_orders } from '../queries/types/GetOrdersByIds';

type IOptions = QueryHookOptions<GetOrdersByIds, GetOrdersByIdsVariables>;
export type IOrdersByIds = GetOrdersByIds_orders;

export function useGetOrdersByIds(options: IOptions = {}) {
  const {
    loading, data: { orders } = {}, error, refetch,
  } = useQuery<GetOrdersByIds, GetOrdersByIdsVariables>(
    GET_ORDERS_BY_IDS_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    orders,
    error,
    refetch,
  };
}
