import * as React from 'react';
import { Modal, IModalProps } from '@components';

import { RequirementDetail } from './RequirementDetail';

interface IProps extends IModalProps {
  requirementId: number;
}

export const RequirementDetailModal: React.FC<IProps> = React.memo((props) => {
  const { requirementId, ...modalProps } = props;
  return (
    <Modal {...modalProps}>
      <RequirementDetail
        requirementId={requirementId}
        onDelete={props.onRequestClose}
      />
    </Modal>
  );
});

RequirementDetailModal.displayName = 'RequirementDetailModal';
