import * as React from 'react';

import { IState } from '@frontend/applications/TermsApp/components/BulkTerms/types/state';
import {
  TermsWizardFooter,
  TProps as IFooterSettings,
} from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizardFooter';

import styles from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard.scss';

const { useMemo, useEffect, useRef } = React;

export interface IProps {
  footerSettings?: IFooterSettings;
  state?: IState;
}

export const TermsWizard: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    children,
    footerSettings,
    state,
  } = props;
  const divRef = useRef<HTMLDivElement>();
  const footer = useMemo(() => {
    if (footerSettings) {
      return <TermsWizardFooter {...footerSettings} />;
    }
    return null;
  }, [footerSettings]);

  useEffect(() => {
    if (divRef && divRef.current) {
      divRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [state?.step]);

  return (
    <>
      <div ref={divRef} className={styles.mainContentWrapper} key={state ? state.step : ''}>
        {children}
      </div>
      {footer}
    </>
  );
});
TermsWizard.displayName = 'TermsWizard';
