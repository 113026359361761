import 'css-chunk:src/app/containers/Settings/SettingsContent/MemberRestrictionSettings/ConfirmationModal.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_ia6vh_157",
  "justify-content-space-between": "_justify-content-space-between_ia6vh_161",
  "tabular-nums": "_tabular-nums_ia6vh_165",
  "ConfirmationModal": "_ConfirmationModal_ia6vh_178",
  "title": "_title_ia6vh_178",
  "description": "_description_ia6vh_188",
  "memberList": "_memberList_ia6vh_198",
  "memberItem": "_memberItem_ia6vh_198",
  "name": "_name_ia6vh_198",
  "email": "_email_ia6vh_208",
  "ruleInfo": "_ruleInfo_ia6vh_265",
  "listContainer": "_listContainer_ia6vh_269",
  "mask": "_mask_ia6vh_272",
  "topFade": "_topFade_ia6vh_280",
  "bottomFade": "_bottomFade_ia6vh_281",
  "active": "_active_ia6vh_287",
  "avatar": "_avatar_ia6vh_313",
  "actions": "_actions_ia6vh_327",
  "button": "_button_ia6vh_333",
  "show": "_show_ia6vh_1"
};