import { AffliateLevelStats, OfferLevelStats } from '@frontend/applications/AffiliatesApp/components/MemberTable/types';
import { IStatisticsItem } from '@frontend/applications/AffiliatesApp/components/StatisticsBlock/types';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { DataFormat } from '@frontend/applications/AffiliatesApp/utils';
import { includes } from 'lodash';

const getConversionDetailsAffliateBlocks = (stats: AffliateLevelStats, source: OFFER_SOURCE, isMulticurrency: boolean, isMultipleShopifyOffer: boolean, baseCurrencyWithoutMulipleShopify?: string[]): IStatisticsItem[] => {
    let baseCurrency: string = null;
    if (source === OFFER_SOURCE.SHOPIFY && isMulticurrency && baseCurrencyWithoutMulipleShopify.length === 1 && !includes(baseCurrencyWithoutMulipleShopify, 'USD') || !isMultipleShopifyOffer) {
        baseCurrency = baseCurrencyWithoutMulipleShopify[0];
    }

    const headerBlocks: IStatisticsItem[] = [];
    if (stats) {
        for (const key of Object.keys(stats)) {
            switch (key) {
                case 'conversions':
                    headerBlocks.push({
                        title: 'Conversions',
                        value: stats[key],
                        format: DataFormat.Integer,
                    });
                    break;
                case 'sales':
                    headerBlocks.push({
                        title: 'Sales',
                        value: stats[key],
                        prefix: '$',
                        format: DataFormat.Money,
                        baseValue: isMulticurrency ? stats.salesBase : null,
                        baseCurrency: isMulticurrency ? baseCurrency : null,
                        baseFormat: isMulticurrency ? DataFormat.Money : null,
                    });
                    break;
                case 'clicks':
                    if (source === OFFER_SOURCE.TUNE) {
                        headerBlocks.push({
                            title: 'Clicks',
                            value: stats[key],
                            format: DataFormat.Integer,
                        });
                    }
                    break;
                case 'avgSale':
                    headerBlocks.push({
                        title: 'Avg. Sales',
                        value: stats[key],
                        prefix: '$',
                        format: DataFormat.Money,
                        baseValue: isMulticurrency ? stats.avgSaleBase : null,
                        baseCurrency: isMulticurrency ? baseCurrency : null,
                        baseFormat: isMulticurrency ? DataFormat.Money : null,
                    });
                    break;
                case 'payoutEarned':
                    headerBlocks.push({
                        title: 'Commission Earned',
                        value: stats[key],
                        prefix: '$',
                        format: DataFormat.Money,
                        baseValue: isMulticurrency ? stats.payoutEarnedBase : null,
                        baseCurrency: isMulticurrency ? baseCurrency : null,
                        baseFormat: isMulticurrency ? DataFormat.Money : null,
                    });
                    break;
                case 'payoutMade':
                    headerBlocks.push({
                        title: 'Amount Paid',
                        value: stats[key],
                        prefix: '$',
                        format: DataFormat.Money,
                    });
                    break;
            }
        }
    }
    return headerBlocks;
};

const getConversionDetailsOfferBlocks = (members: number, stats: OfferLevelStats, source: OFFER_SOURCE, isMulticurrency: boolean, isMultipleShopifyOffer: boolean): IStatisticsItem[] => {
    let baseCurrency: string = null;
    if (source === OFFER_SOURCE.SHOPIFY
        && (isMulticurrency && stats.currencies.length === 1 && !includes(stats.currencies, 'USD') || !isMultipleShopifyOffer)) {
        baseCurrency = stats.currencies[0];
    }

    const headerBlocks: IStatisticsItem[] = [];
    headerBlocks.push({
        title: 'Members',
        value: members,
        format: DataFormat.Integer,
    });
    for (const key of Object.keys(stats)) {
        switch (key) {
            case 'conversions':
                headerBlocks.push({
                    title: 'Conversions',
                    value: stats[key],
                    format: DataFormat.Integer,
                });
                break;
            case 'sales':
                headerBlocks.push({
                    title: 'Sales',
                    value: stats[key],
                    prefix: '$',
                    format: DataFormat.Money,
                    baseValue: isMulticurrency ? stats.salesBase : null,
                    baseCurrency: isMulticurrency ? baseCurrency : null,
                    baseFormat: isMulticurrency ? DataFormat.Money : null,
                });
                break;
            case 'clicks':
                if (source === OFFER_SOURCE.TUNE) {
                    headerBlocks.push({
                        title: 'Clicks',
                        value: stats[key],
                        format: DataFormat.Integer,
                    });
                }
                break;
            case 'avgSale':
                headerBlocks.push({
                    title: 'Avg. Sales',
                    value: stats[key],
                    prefix: '$',
                    format: DataFormat.Money,
                    baseValue: isMulticurrency ? stats.avgSaleBase : null,
                    baseCurrency: isMulticurrency ? baseCurrency : null,
                    baseFormat: isMulticurrency ? DataFormat.Money : null,
                });
                break;
            case 'payoutEarned':
                headerBlocks.push({
                    title: 'Commission Earned',
                    value: stats[key],
                    prefix: '$',
                    format: DataFormat.Money,
                    baseValue: isMulticurrency ? stats.payoutEarnedBase : null,
                    baseCurrency: isMulticurrency ? baseCurrency : null,
                    baseFormat: isMulticurrency ? DataFormat.Money : null,
                });
                break;
            case 'payoutMade':
                headerBlocks.push({
                    title: 'Amount Paid',
                    value: stats[key],
                    format: DataFormat.Money,
                    prefix: '$',
                });
                break;
        }
    }
    headerBlocks.push({
        title: 'Payout Due',
        value: stats.payoutEarned - stats.payoutMade,
        prefix: '$',
        format: DataFormat.Money,
    });
    return headerBlocks;
};

export { getConversionDetailsAffliateBlocks, getConversionDetailsOfferBlocks };
