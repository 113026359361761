import * as React from 'react';
import cn from 'classnames';

import {
  Col, Row, Card as FreshCard, Typography,
} from '@revfluence/fresh';

import styles from './Card.module.scss';

const { Title, Text } = Typography;

export interface ICardProps {
  title: string;
  subtitle: string;
  icon: React.ReactElement;
  dark?: boolean;
}

const Card: React.FC<ICardProps> = ({
  title, subtitle, icon, children, dark = false,
}) => {
  const renderTitle = (
    <Row align="middle" gutter={8}>
      <Col>
        <span className={styles.icon}>{icon}</span>
      </Col>
      <Col flex={1}>
        {title && (
          <Title level={4} style={{ margin: 0 }}>
            {title}
            {' '}
            <Text type="secondary" style={{ marginLeft: 4 }}>{subtitle}</Text>
          </Title>
        )}
      </Col>
    </Row>
  );

  return (
    <FreshCard
      className={cn(styles.card, { [styles.dark]: dark })}
      title={renderTitle}
      bordered={false}
    >
      {children}
    </FreshCard>
  );
};

export default Card;
