._MediaCell_1jukv_1 ._mediaContent_1jukv_1 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._MediaCell_1jukv_1 ._mediaContent_1jukv_1 ._image_1jukv_7 {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px;
  object-fit: cover;
  object-position: center center;
}

._Tooltip_1jukv_17 ._image_1jukv_7 {
  width: 100%;
}