import * as React from 'react';
import { Button } from '@revfluence/fresh';
import { IToastRefHandles, Toast } from '@components';
import { useUpgradeClientMutation } from '@frontend/app/hooks';
import { UsageEntity } from '@frontend/context/MessagingContext';

interface IProps {
  reason: UsageEntity;
}

const { useRef } = React;

export const UpgradeButton = (props: IProps) => {
  const {
    reason,
  } = props;

  const toastrRef = useRef<IToastRefHandles>();

  const [upgradeClient, { loading }] = useUpgradeClientMutation();

  const onButtonClick = async () => {
    const toastr = toastrRef.current;

    try {
      await upgradeClient({
        variables: {
          reason,
        },
      });

      if (toastr) {
        toastr.showMessage({
          content: 'Thank you for contacting us. We will reach out to you shortly.',
          type: 'info',
        });
      }
    } catch (error) {
      const errMsg = error.graphQLErrors && error.graphQLErrors[0]
        ? error.graphQLErrors[0].message
        : error.message;

      toastr.showMessage({
        content: errMsg,
        type: 'error',
      });
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={onButtonClick}
        loading={loading}
      >
        Contact Us to Upgrade
      </Button>
      <Toast ref={toastrRef} useFresh />
    </>
  );
};
