import * as React from 'react';
import {
  ComposedChart,
  ResponsiveContainer,
  YAxis,
  XAxis,
  AreaProps,
} from 'recharts';
import { uniqueId } from 'lodash';
import { IChartProps } from '../../types';

import { ReferenceLine } from '../ReferenceLine';
import styles from '../../Charts.module.scss';
import { Area } from '../Area/Area';
import { useAxisProps } from '../../hooks/useAxisProps';

const spacing = Number(styles['spacing-lg'].replace('px', ''));

const defaultProps = {
  margin: {
    top: spacing,
    right: spacing,
  },
};

/**
 * Area Chart Props
 */
export interface IAreaChartProps extends IChartProps {
  /** Area Props */
  area: Omit<AreaProps, 'ref'>;
}

/**
 * Area chart component
 */
export const AreaChart: React.FC<Readonly<IAreaChartProps>> = React.memo((props) => {
  const {
    xAxis,
    yAxis,
    area,
    referenceLines,
    data,
    margin,
  } = props;
  const xAxisProps = useAxisProps(xAxis);
  const yAxisProps = useAxisProps(yAxis);

  return (
    <ResponsiveContainer>
      <ComposedChart data={data} margin={margin}>
        <Area {...area} />
        {xAxis && <XAxis {...xAxisProps} />}
        {yAxis && <YAxis {...yAxisProps} />}
        {referenceLines
          && referenceLines.map((referenceLine) => (
            <ReferenceLine key={uniqueId()} {...referenceLine} />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
});

AreaChart.displayName = 'AreaChart';
AreaChart.defaultProps = defaultProps;
