import * as React from 'react';
import { find, lowerCase } from 'lodash';
import { Typography, message } from '@revfluence/fresh';
import { Link } from 'react-router-dom';
import { UserAvatar } from '@frontend/app/components';
import { CircleCheckIcon, CircleXIcon } from '@revfluence/fresh-icons/solid/esm';
import { logger } from '@common';
import { useUpdateMemberMutation } from '../../../hooks/useUpdateMemberMutation';
import styles from './GroupCreators.scss';

const { Text } = Typography;

const CreatorNameCell = ({
  memberRecord, groupId, fields, setBrandApprovalError,
}) => {
  const brandApprovalField = find(fields, (field) => lowerCase(field.name) === 'brand approval');

  const [memberMutation] = useUpdateMemberMutation({
    onError(error) {
      message.error('Status not updated');
      logger.error(`Error logging ${error}, error`);
    },
  });
  const { name, profilePicture, id } = memberRecord;

  const disableButton = memberRecord.brand_approval === 'Approved' || memberRecord.brand_approval === 'Rejected';

  const onAcceptClick = async () => {
    try {
      if (!brandApprovalField) {
        setBrandApprovalError(true);
        throw new Error('Brand Approval field not configured');
      }
      await memberMutation({
        variables: {
          id,
          member: {
            fields: {
              [brandApprovalField.id]: 'Approved',
            },
          },
        },
        onCompleted() {
          message.success('Applicant approved');
        },
      });
    } catch (error) {
      logger.error(`error in creator name cell ${error}`);
    }
  };

  const onRejectClick = async () => {
    try {
      if (!brandApprovalField) {
        setBrandApprovalError('Brand Approval field not configured');
        throw new Error('Brand Approval field not configured');
      }
      await memberMutation({
        variables: {
          id,
          member: {
            fields: {
              [brandApprovalField.id]: 'Rejected',
            },
          },
        },
        onCompleted() {
          message.error('Applicant rejected');
        },
      });
    } catch (error) {
      logger.error(`error in creator name cell ${error}`);
    }
  };

  return (
    <div className={styles.nameProfile}>
      <div className={styles.profile}>
        <Link
          to={{
            pathname: '/creator',
            search: `?groupId=${groupId}&creatorId=${id}`,
          }}
        >
          <UserAvatar name={name} profilePicture={profilePicture} />
          <Text className={styles.name}>
            {name}
            {' '}
          </Text>
        </Link>
      </div>
      <div className={styles.action}>
        <button
          className={styles.accept}
          onClick={onAcceptClick}
          disabled={disableButton}
        >
          <CircleCheckIcon />
          {' '}
          {disableButton}
          {' '}
        </button>
        <button
          className={styles.reject}
          onClick={onRejectClick}
          disabled={disableButton}
        >
          <CircleXIcon />
        </button>
      </div>
    </div>
  );
};

export default CreatorNameCell;
