import gql from 'graphql-tag';

import { USER_METADATA_FRAGMENT } from './fragments';

export const GET_CURRENT_USER_METADATA_QUERY = gql`
  query GetCurrentUserMetadataQuery {
    userMetadata: getCurrentUserMetadata {
      ...UserMetadataFragment
    }
  }
  ${USER_METADATA_FRAGMENT}
`;
