import { TContentSubmittedCardColor } from '../ContentSubmitted';
import { CONTENT_REVIEW_STATE } from '../constants';

export function getContentReviewStateColor(state: CONTENT_REVIEW_STATE): TContentSubmittedCardColor {
  switch (state) {
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_PLACEHOLDER:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_WAITING_FOR_SUBMISSION: {
      return 'processing';
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_APPROVED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_COMPLETED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_COMPLETED_WITHOUT_PAYMENT: {
      return 'success';
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_NEW:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_UPLOADED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_VALIDATING_UPLOAD:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_AMENDED: {
      return 'default';
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_REJECTED: {
      return 'error';
    }

    default: {
      break;
    }
  }

  return 'default';
}
