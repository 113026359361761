import * as React from 'react';

import { uniqueId } from 'lodash';

import { SvgIcon, ISvgIconProps } from '@components';

export const PersonCheckIcon = React.memo((props: ISvgIconProps) => {
  const maskId = uniqueId('PersonCheckIconMask');
  return (
    <SvgIcon {...props}>
      <g>
        <path
          id={uniqueId('PersonCheckIcon')}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 11C12.206 11 14 9.206 14 7C14 4.794 12.206 3 10 3C7.794 3 6 4.794 6 7C6 9.206 7.794 11 10
          11ZM21.6563 4.2456C21.2393 3.8836 20.6083 3.9266 20.2453 4.3436L18.3793 6.4886L17.7453 5.7796C17.3783
          5.3676 16.7463 5.3326 16.3333 5.7016C15.9223 6.0696 15.8863 6.7026 16.2553 7.1136L17.6443 8.6666C17.8343
          8.8786 18.1053 8.9996 18.3893 8.9996H18.3963C18.6833 8.9976 18.9563 8.8726 19.1443 8.6566L21.7553
          5.6566C22.1173 5.2396 22.0733 4.6076 21.6563 4.2456ZM17 20C17 20.552 16.553 21 16 21H4C3.447 21 3 20.552
          3 20C3 16.14 6.141 13 10 13C13.859 13 17 16.14 17 20Z"
          fill={props.fill}
        />
        <mask id={maskId} mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="20" height="18">
          <path
            id={uniqueId('PersonCheckIcon')}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 11C12.206 11 14 9.206 14 7C14 4.794 12.206 3 10 3C7.794 3 6 4.794 6 7C6 9.206 7.794 11 10 11ZM21.6563
            4.2456C21.2393 3.8836 20.6083 3.9266 20.2453 4.3436L18.3793 6.4886L17.7453 5.7796C17.3783 5.3676 16.7463 5.3326
            16.3333 5.7016C15.9223 6.0696 15.8863 6.7026 16.2553 7.1136L17.6443 8.6666C17.8343 8.8786 18.1053 8.9996 18.3893
            8.9996H18.3963C18.6833 8.9976 18.9563 8.8726 19.1443 8.6566L21.7553 5.6566C22.1173 5.2396 22.0733 4.6076 21.6563
            4.2456ZM17 20C17 20.552 16.553 21 16 21H4C3.447 21 3 20.552 3 20C3 16.14 6.141 13 10 13C13.859 13 17 16.14 17 20Z"
            fill={props.fill || '#FFFFFF'}
          />
        </mask>
        <g mask={`url(#${maskId}`} />
      </g>
    </SvgIcon>
  );
});

PersonCheckIcon.displayName = 'PersonCheckIcon';
