import 'css-chunk:src/components/widgets/ContentPreviewer/Content/Content.scss';export default {
  "Content": "_Content_1tmvx_1",
  "content": "_content_1tmvx_7",
  "hover": "_hover_1tmvx_14",
  "clickable": "_clickable_1tmvx_19",
  "info": "_info_1tmvx_22",
  "small": "_small_1tmvx_32",
  "fileType": "_fileType_1tmvx_36",
  "medium": "_medium_1tmvx_40",
  "actions": "_actions_1tmvx_48",
  "large": "_large_1tmvx_51",
  "image": "_image_1tmvx_66",
  "pdf": "_pdf_1tmvx_69",
  "video": "_video_1tmvx_72",
  "name": "_name_1tmvx_75",
  "zoomIcon": "_zoomIcon_1tmvx_92",
  "link": "_link_1tmvx_95"
};