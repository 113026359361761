import * as React from 'react';
import {
  pick, toNumber, map, toString, orderBy, filter, find, first, size,
} from 'lodash';

import {
  Input, Button, IRowData,
} from '@components';
import { SimpleForm, SimpleField } from '@frontend/app/components';
import {
  GetAllPrograms_programs as IProgram,
} from '@frontend/app/queries/types/GetAllPrograms';

import { IAgreement, AGREEMENT_STATUS_MAP } from '@frontend/applications/TermsApp/types/IAgreement';
import { TermsTable } from './TermsTable';
import styles from './PaymentSetup.scss';

const {
 useState, useRef, useMemo, useEffect,
} = React;

const MIN = 0.5;
interface IProps {
  memberName?: string;
  terms: IAgreement[];
  dispatch(action): void;

  isWorkflow: boolean;

  // Programs used to show program title in terms table
  programs?: IProgram[];

  // Program params exist if isWorkflow is true (i.e. we'll be showing terms for a specific program)
  programId?: number;
  programName?: string;
}

export const PaymentSetup: React.FunctionComponent<IProps> = (props) => {
  const {
    memberName,
    terms,
    dispatch,
    isWorkflow,
    programs,
    programId,
    programName,
  } = props;

  const filteredTerms = useMemo(() => {
    if (isWorkflow) {
      return filter(terms, (t) => t.program_id === programId);
    }
    return terms;
  }, [terms, isWorkflow, programId]);

  const termsData: IRowData[] = useMemo(() => {
    const tableData = map(filteredTerms, (t) => ({
      id: toString(t.id),
      _raw: t,
      agreed_date: t.agreed_ts,
      created_date: t.created_at,
      price: t.price,
      status: AGREEMENT_STATUS_MAP[t.status],
      program_name: find(programs, (p) => p.id === t.program_id)?.title,
    }));
    return orderBy(tableData, ['status', 'created_date'], ['asc', 'desc']);
  }, [filteredTerms, programs]);

  const EMPTY_INPUT = {
    amount: '',
  };

  const [input, setInput] = useState(EMPTY_INPUT);

  useEffect(() => {
    if (!isWorkflow) {
      // Don't pre-populate the amount if we're not in workflow
      return;
    }

    const agreedTerms = filter(filteredTerms, (t) => t.status === 'agreed');
    if (size(agreedTerms) === 1) {
      const agreedTerm = first(agreedTerms);
      if (agreedTerm?.price) {
        setInput({
          amount: toString(agreedTerm.price),
        });
      }
    }
  }, [filteredTerms, isWorkflow]);

  const hasError = useMemo(() => {
    if (input.amount) {
      return toNumber(input.amount) < MIN;
    }
    return false;
  }, [input.amount]);
  const errorMessage = useMemo(() =>
    (hasError ? 'Minimum amount is $0.50' : null), [hasError]);

  const formRef = useRef<SimpleForm>();

  const handleClickSave = () => {
    formRef.current.submit();
  };

  const handleChangeFields = (fieldName: string, value: string) => {
    setInput((i) => ({ ...i, [fieldName]: value }));
  };

  const formValues = pick(input, ['amount']);

  const handleSubmit = () => {
    dispatch({
      type: 'setAmount',
      value: formValues.amount,
    });
  };

  return (
    <div className={styles.PaymentSetup}>
      <div className={styles.title}>Send a payment</div>
      <div className={styles.tableTitle}>
        {isWorkflow
          ? (
            <>
              Terms for
              {' '}
              {memberName || 'this member'}
              {' '}
              in
              {' '}
              {programName || 'this project'}
              :
            </>
          ) : (
            <>
              All terms for
              {' '}
              {memberName || 'this member'}
              :
            </>
          )}
      </div>
      <div className={styles.TermsTable}>
        <TermsTable data={termsData} />
      </div>
      <div className={styles.subtitle}>Enter the amount in USD:</div>
      <SimpleForm
        ref={formRef}
        values={formValues}
        onChange={handleChangeFields}
        onSubmit={handleSubmit}
      >
        <div className={styles.form}>
          <SimpleField name="amount" type="number" errorMessage={errorMessage}>
            <Input />
          </SimpleField>
        </div>
      </SimpleForm>

      <div className={styles.buttons}>
        <Button disabled={hasError} label="Next" onClick={handleClickSave} />
      </div>
    </div>
  );
};
