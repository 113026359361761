/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._RadioGroup_1ef8j_32::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
._RadioGroup_1ef8j_32._horizontal_1ef8j_38 {
  display: flex;
  flex-direction: row;
}
._RadioGroup_1ef8j_32._horizontal_1ef8j_38 ._item_1ef8j_42 {
  margin-right: 30px;
  float: left;
}
._RadioGroup_1ef8j_32._horizontal_1ef8j_38 ._item_1ef8j_42 ._dot_1ef8j_46 {
  margin-right: 0;
}
._RadioGroup_1ef8j_32 ._column_1ef8j_49 {
  float: left;
  margin-right: 40px;
}
._RadioGroup_1ef8j_32 ._item_1ef8j_42 {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
._RadioGroup_1ef8j_32 ._item_1ef8j_42 ._dot_1ef8j_46 {
  margin-right: 16px;
  width: 19px;
  height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #eff5f9;
  will-change: background-color;
  transition: background-color 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._RadioGroup_1ef8j_32 ._item_1ef8j_42 ._dot_1ef8j_46._selected_1ef8j_74 {
  background-color: #3996e0;
}
._RadioGroup_1ef8j_32 ._item_1ef8j_42 ._dot_1ef8j_46 ._selectedDot_1ef8j_77 {
  width: 7px;
  height: 7px;
  background-color: #ffffff;
  border-radius: 100px;
}
._RadioGroup_1ef8j_32 ._item_1ef8j_42 ._label_1ef8j_83 {
  position: relative;
  margin-left: 8px;
  color: #1a1818;
  font-size: 15px;
  font-weight: 300;
  user-select: none;
  will-change: color;
}
._RadioGroup_1ef8j_32 ._selectedElement_1ef8j_92 {
  margin-left: 35px;
}