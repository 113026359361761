import 'css-chunk:src/applications/AffiliatesApp/components/PaymentsOverview/PaymentOverview.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1rcpl_157",
  "justify-content-space-between": "_justify-content-space-between_1rcpl_161",
  "tabular-nums": "_tabular-nums_1rcpl_165",
  "PaymentOverview": "_PaymentOverview_1rcpl_178",
  "paymentsOverviewTitle": "_paymentsOverviewTitle_1rcpl_178",
  "subtitle1": "_subtitle1_1rcpl_188",
  "paymentLog": "_paymentLog_1rcpl_198",
  "paymentLogHeader": "_paymentLogHeader_1rcpl_198",
  "paymentLogTitle": "_paymentLogTitle_1rcpl_198",
  "caption": "_caption_1rcpl_214",
  "smallText": "_smallText_1rcpl_224",
  "paymentBalance": "_paymentBalance_1rcpl_233",
  "cost": "_cost_1rcpl_236",
  "noPayment": "_noPayment_1rcpl_254",
  "title": "_title_1rcpl_261",
  "logIcon": "_logIcon_1rcpl_271",
  "titleDivider": "_titleDivider_1rcpl_275",
  "paymentEntry": "_paymentEntry_1rcpl_278",
  "spaceBottom": "_spaceBottom_1rcpl_288",
  "dateFilterButton": "_dateFilterButton_1rcpl_291",
  "show": "_show_1rcpl_1"
};