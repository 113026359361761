import gql from 'graphql-tag';

export const GET_ALL_PRESET_CONDITIONS_QUERY = gql`
  query GetAllPresetConditionsQuery($specKey: String!) {
    presetConditions: getAllPresetConditions(specKey: $specKey) {
      conditionId
      workletSpecKey
    }
  }
`;
