import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
 map, slice, sortBy, sumBy,
} from 'lodash';

import { Tooltip } from '@components';
import { scaleLinear } from '@vx/scale';
import { AxisLeft } from '@vx/axis';

import { numTicksForHeight } from '../utils/numTicksForHeight';
import { IBarChartProps } from './interfaces';

const {
 useMemo, useState, useRef, useEffect,
} = React;
import styles from './BarChartVertical.scss';

const MARGIN = {
  top: 30,
  right: 30,
  bottom: 30,
  left: 30,
};
const MAX_RECORDS = 6;

/**
 * @type {React.FunctionComponent}
 */
export const BarChartVertical: React.FunctionComponent<IBarChartProps> = React.memo(
  (props) => {
    const {
      className,
      data,
      height,
      maxRecords = MAX_RECORDS,
    } = props;
    const yMax = height - MARGIN.top - MARGIN.bottom;
    const yScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax, 0],
          domain: [0, Math.max(...data.map((d) => d.value))],
          nice: true,
        }),
      [data, yMax],
    );
    const [records, totalValue] = useMemo(() => {
      const sortedRawData = sortBy(data, (d) => -d.value);
      const sum = sumBy(data, 'value');
      if (sortedRawData.length > maxRecords) {
        return [
          [
            ...slice(sortedRawData, 0, maxRecords - 1),
            {
              label: 'Other',
              value: sumBy(slice(sortedRawData, maxRecords - 1), (d) => d.value),
            },
          ],
          sum,
        ];
      } else {
        return [sortedRawData, sum];
      }
    }, [data, maxRecords]);
    const refs = useRef<Array<React.RefObject<HTMLDivElement>>>([]);
    useEffect(() => {
      refs.current = Array(records.length)
        .fill(null)
        .map(() => React.createRef<HTMLDivElement>());
    }, [records.length]);
    const [selectedBar, setSelectedBar] = useState<{
      record: {
        label: string;
        value: number;
        percentage: number;
      };
      index: number;
    }>(null);

    return (
      <div className={cx(className, styles.BarChartVertical)}>
        <svg style={{ width: '50px' }} width={50} height={height}>
          <AxisLeft
            top={MARGIN.top}
            left={50}
            scale={yScale}
            numTicks={numTicksForHeight(height)}
            stroke="transparent"
            tickStroke="transparent"
            tickLabelProps={() => ({
              fill: '#4F4F4F',
              textAnchor: 'end',
              fontSize: 11,
              letterSpacing: 1.25,
              dx: '-0.25em',
              dy: '0.4em',
            })}
            tickFormat={(value: number) =>
              numeral(value)
                .format('0.[0]a')
                .toUpperCase()}
          />
        </svg>
        <div className={styles.list}>
          {map(records, (record, index) => {
            const percentage = (record.value / totalValue) * 100;
            const adjustedPercentage = Math.max(
              0,
              Math.min(100, percentage || 0),
            );

            return (
              <div className={styles.listItem} key={record.label}>
                <div className={styles.bar}>
                  <div
                    className={styles.progress}
                    ref={refs[index]}
                    onMouseEnter={() =>
                      setSelectedBar({
                        index,
                        record: {
                          ...record,
                          percentage: adjustedPercentage / 100,
                        },
                      })}
                    onMouseLeave={() => setSelectedBar(null)}
                    style={{
                      height: `${adjustedPercentage}%`,
                      background: 'linear-gradient(0deg, rgba(57, 150, 224, 0) 0%, #3996E0 108.69%)',
                    }}
                  />
                </div>
                <div className={styles.label}>{record.label}</div>
              </div>
            );
          })}
        </div>
        <Tooltip
          placement="top"
          mountRef={refs[selectedBar && selectedBar.index]}
          autoRegisterListener={false}
          show={!!selectedBar}
          tooltipColor="black"
          className={styles.Tooltip}
        >
          <div className={styles.content}>
            {selectedBar && (
              <>
                <div
                  className={styles.dot}
                  style={{
                    background: '#3996E0',
                  }}
                />
                <span>
                  {selectedBar.record.value}
                  {' '}
                  (
                  {numeral(selectedBar.record.percentage).format('0.0%')}
                  )

                </span>
              </>
            )}
          </div>
        </Tooltip>
      </div>
    );
  },
);

BarChartVertical.displayName = 'BarChartVertical';
