import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  SAVE_CLIENT_FEATURE_MUTATION,
} from '@frontend/app/queries';
import {
  SaveClientFeatureMutation,
  SaveClientFeatureMutationVariables,
} from '@frontend/app/queries/types/SaveClientFeatureMutation';

type IOptions = MutationHookOptions<SaveClientFeatureMutation, SaveClientFeatureMutationVariables>;

export const useSaveClientFeature = (options: IOptions = {}) => useMutation<SaveClientFeatureMutation, SaveClientFeatureMutationVariables>(SAVE_CLIENT_FEATURE_MUTATION, options);
