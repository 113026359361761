import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const StarIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 30 30">
    <path
      d="
        M16.5,1.2l2.7,7.7c0.2,0.7,0.8,1.1,1.5,1.1h7.3c1.5,0,2.2,2,1,2.9l-6.2,4.9c-0.5,0.4-0.8,1.1-0.5,1.8l2.7,8
        c0.5,1.5-1.2,2.8-2.5,1.8l-6.5-4.8c-0.6-0.4-1.4-0.4-1.9,0l-6.5,4.8c-1.3,0.9-3-0.3-2.5-1.8l2.7-8c0.2-0.7,
        0-1.4-0.5-1.8l-6.2-4.9 c-1.2-1-0.5-2.9,1-2.9h7.3c0.7,0,1.3-0.4,1.5-1.1l2.7-7.7C13.9-0.2,16-0.2,16.5,1.2z
      "
      fill={props.fill}
    />
  </SvgIcon>
));

StarIcon.displayName = 'StarIcon';
