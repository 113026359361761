import { QueryHookOptions, useLazyQuery } from '@apollo/client';
import { GET_PRODUCTS_FROM_SHOPIFY } from '@frontend/applications/ProductFulfillmentApp/queries/shopifyCollections';
import { GetProductsFromShopify, GetProductsFromShopifyVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductsFromShopify';

type IOptions = QueryHookOptions<GetProductsFromShopify, GetProductsFromShopifyVariables>;

export const useGetProductsFromShopify = (options: IOptions = {}) => {
  const [
    fetchShopifyProducts,
    {
    loading, data, error, refetch, fetchMore,
   }] = useLazyQuery<GetProductsFromShopify, GetProductsFromShopifyVariables>(GET_PRODUCTS_FROM_SHOPIFY, options);

  return {
    fetchShopifyProducts,
    isShopifyProductsLoading: loading,
    shopifyProductsResp: data?.getProductsFromShopify,
    shopifyProductsError: error,
    refetchShopifyProducts: refetch,
    fetchMoreShopifyProducts: fetchMore,
  };
};
