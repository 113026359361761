import * as React from 'react';
import * as querystring from 'querystring';

import { logger } from '@common';

const { useCallback, useEffect, useState } = React;

export interface IMetaBusiness {
  id: number;
  networkId: string;
  name: string;
}

const fetchSocialData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

interface IUseFetchMetaBusinessesOptions {
  url: string;
  clientId: string;
}

export function useFetchMetaBusinesses(options: IUseFetchMetaBusinessesOptions) {
  const {
    url: baseUrl,
    clientId,
  } = options;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IMetaBusiness[]>(null);
  const [error, setError] = useState(null);

  const filterString = querystring.stringify({
    client_id: clientId,
  });
  const url = `${baseUrl}?${filterString}`;

  const fetch = useCallback(() => {
    setLoading(true);
    fetchSocialData(url)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [url]);

  useEffect(fetch, [fetch]);

  return {
    loading,
    data,
    error,
    refetch: fetch,
  };
}
