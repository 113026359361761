import { useQuery, QueryHookOptions } from '@apollo/client';

import {
  GetMetaFieldsForProductFromShopify, GetMetaFieldsForProductFromShopifyVariables,
 } from '../queries/types/GetMetaFieldsForProductFromShopify';
import { GET_META_FIELDS_FOR_PRODUCT_FROM_SHOPIFY } from '../queries/productDetails';

type IOptions = QueryHookOptions<GetMetaFieldsForProductFromShopify, GetMetaFieldsForProductFromShopifyVariables>;

export function useGetMetaFieldsForProductFromShopify(options: IOptions) {
  const {
    loading, data, error,
  } = useQuery<GetMetaFieldsForProductFromShopify, GetMetaFieldsForProductFromShopifyVariables>(
    GET_META_FIELDS_FOR_PRODUCT_FROM_SHOPIFY,
    options,
  );

  return {
    isProductMetaFieldsLoading: loading,
    productMetaFields: data?.getMetaFieldsValuesFromShopify?.productMetaFields,
    productMetaFieldsError: error,
  };
}
