import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';
import { GET_APPLICATIONS_BY_IDS } from '@frontend/app/queries';
import {
  GetApplicationsByIds, GetApplicationsByIdsVariables,
} from '@frontend/app/queries/types/GetApplicationsByIds';

type IOptions = QueryHookOptions<GetApplicationsByIds, GetApplicationsByIdsVariables>;

export function useGetApplicationsByIds(applicationIds: string[], options: IOptions = {}) {
  return useQuery<GetApplicationsByIds>(
    GET_APPLICATIONS_BY_IDS,
    {
      ...options,
      variables: {
        applicationIds,
      },
      skip: isEmpty(applicationIds) || options.skip,
    },
  );
}
