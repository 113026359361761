import { Card, Space, Typography } from '@revfluence/fresh';
import { ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import React from 'react';
import styles from './SettingsCard.scss';

const { Text } = Typography;

export interface ISettingsCardProps {
  Icon: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  onClick: () => void;
}

export const SettingsCard = ({
 Icon, title, subtitle, onClick,
}: ISettingsCardProps) => (
  <Card className={styles.SettingsCard} onClick={onClick}>
    <Space size={12} align="start">
      <div className={styles.iconContainer}>
        <Icon fontSize={24} fill="var(--gray-9)" />
      </div>
      <Space direction="vertical" size={0}>
        <Text weight="semibold">{title}</Text>
        <Text className={styles.subTitle}>{subtitle}</Text>
      </Space>
      <div className={styles.chevronContainer}>
        <ChevronRightIcon />
      </div>
    </Space>
  </Card>
);
