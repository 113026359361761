import gql from 'graphql-tag';

import {
  MESSAGE_FRAGMENT_WITH_PAYLOAD,
  MESSAGE_VARIABLE_FRAGMENT,
} from './fragments';

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessageMutation($message: SendMessageInput!) {
    message: sendMessage(message: $message) {
      ...MessageFragmentWithPayload
    }
  }
  ${MESSAGE_FRAGMENT_WITH_PAYLOAD}
`;

export const SEND_BULK_MESSAGE_MUTATION = gql`
  mutation SendBulkMessageMutation($message: SendMessageInput!) {
    sent: sendBulkMessage(message: $message)
  }
`;

export const SYNC_MESSAGES_MUTATION = gql`
  mutation SyncMessagesMutation($resourceId: Int, $count: Int) {
    succeeded: syncMessages(resourceId: $resourceId, count: $count)
  }
`;

export const GET_VARIABLES_QUERY = gql`
  query GetVariablesQuery {
    variables: getVariables {
      ...MessageVariableFragment
    }
  }
  ${MESSAGE_VARIABLE_FRAGMENT}
`;
