import * as React from 'react';
import {
 Redirect, NavLink, useRouteMatch, useLocation, Switch, Route,
} from 'react-router-dom';

import { map } from 'lodash';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

import styles from './AppModalNav.scss';

interface INavLink {
  displayName: string;
  route: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
}

interface IProps {
  navLinks: INavLink[];
  defaultRoute: string;
  sidebarVisible?: boolean;
}

export const AppModalNav: React.FunctionComponent<IProps> = (props) => {
  const match = useRouteMatch();
  const location = useLocation();

  const { navLinks, defaultRoute, sidebarVisible = true } = props;

  return (
    <div className={styles.AppModalNav}>
      {sidebarVisible && (
        <section className={styles.sideNav}>
          <ul className={styles.coreItemList}>
            {map(navLinks, (navLink) => (
              <li key={navLink.route}>
                <NavLink
                  to={{ ...location, pathname: `${match.url}/${navLink.route}` }}
                  className={styles.coreItem}
                  activeClassName={styles.active}
                >
                  {navLink.displayName}
                </NavLink>
              </li>
            ))}
          </ul>
        </section>
      )}
      <section className={styles.mainContent}>
        <Switch>
          {map(navLinks, (navLink) => (
            <Route
              path={`${match.url}/${navLink.route}`}
              key={navLink.route}
              render={lazyLoadComponent(navLink.component)}
            />
          ))}
          <Redirect from={`${match.path}`} to={{ ...location, pathname: `${match.path}/${defaultRoute}` }} />
        </Switch>
      </section>
    </div>
  );
};
