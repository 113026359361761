import { IContent, IUploadProgress } from '@frontend/app/hooks';
import { MessageTemplateQuery_template_attachments as TemplateAttachment } from '@frontend/app/queries/types/MessageTemplateQuery';

export type IAttachment = IContent | TemplateAttachment;

export enum EmailAttachmentActionTypes {
  ADD_CONTENT = '@attachmentUploader/ADD_CONTENT',
  UPDATE_PROGRESS = '@attachmentUploader/UPDATE_PROGRESS',
  UPDATE_FILE_URL = '@attachmentUploader/UPDATE_FILE_URL',
  REMOVE_CONTENTS = '@attachmentUploader/REMOVE_CONTENTS',
  REMOVE_CONTENT = '@attachmentUploader/REMOVE_CONTENT',
  SET_ERROR_MESSAGE = '@attachmentUploader/SET_ERROR_MESSAGE',
}

export interface IAttachmentUploaderAction {
  type: EmailAttachmentActionTypes;
  payload?: {
    contents?: IAttachment[];
    content?: IAttachment;
    id?: string;
    progress?: IUploadProgress;
    fileUrl?: string;
    errorMessage?: string;
  };
}

/* States */
export interface IAttachmentUploader {
  contents: IContent[];

  // disable file selector when maximum number of contents reached
  maxContents: number;
  limitReached: boolean;
  errorMessage?: string;
}

export const initialState: IAttachmentUploader = {
  contents: [],

  maxContents: null,
  limitReached: false,
  errorMessage: null,
};
