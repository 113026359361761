import * as React from 'react';
import { Drawer } from 'antd';

import {
  IStepInfo as Step,
  WizardContainer as Wizard,
} from '@frontend/applications/Shared/Wizard/container';
import { TDeactivateMembersProps } from '../types';
import { useDeactivateMembers } from '../hooks';
import { ConfirmClose } from './ConfirmClose';
import { DeactivateMembers as DeactivateMembersIcon } from './Icons';

import styles from './MembersWizard.scss';

const { useCallback } = React;

export const DeactivateMembers: React.FC<Readonly<TDeactivateMembersProps>> = (props) => {
  const {
    onClose,
    visible,
  } = props;
  const { actions, state, steps } = useDeactivateMembers(props);

  const resetStateOnHide = useCallback((visible: boolean) => {
    if (!visible) {
      actions.reset();
    }
  }, [actions]);

  const handleCloseClick = useCallback(() => {
    if (state.saving) {
      return;
    }
    if (!state.error && state.currentStep > 1 && state.currentStep < 3) {
      actions.showCloseConfirmation();
      return;
    }
    onClose();
  }, [actions, onClose, state.currentStep, state.error, state.saving]);

  let contents;
  if (state.showCloseConfirmation) {
    contents = (
      <ConfirmClose
        onConfirm={onClose}
        onDismissConfirmation={actions.dismissCloseConfirmation}
      />
    );
  } else {
    contents = (
      <Wizard
        hideNavigation={!!state.error}
        icon={<DeactivateMembersIcon />}
        onCancel={onClose}
        onStepChange={actions.setCurrentStep}
        step={state.currentStep}
        stepsInfo={steps as Step[]}
      />
    );
  }

  return (
    <Drawer
      afterVisibleChange={resetStateOnHide}
      className={styles.MembersWizard}
      closable
      destroyOnClose
      onClose={handleCloseClick}
      placement="right"
      visible={visible}
      width={1024}
    >
      {contents}
    </Drawer>
  );
};

DeactivateMembers.displayName = 'DeactivateMembers';
