/**
 * This is a duplicate of @frontend/app/hooks/useFetchSocialAccount.ts.
 *
 * There is a bug with updating the loading status if urls is empty.
 * But the fix will cause P0 in other part of application.
 *
 * This is a temporary fix. As soon as the fix is in place, this will be removed.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import * as qs from 'querystring';
import {
  first, isArray, map, reduce,
} from 'lodash';

import { logger } from '@common';
import { TNetworkIdentifier } from '@components';
import { ISocialAccount } from '@components';
import { useGetCurrentClient } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';

const { useEffect, useMemo, useState } = React;

interface IProps {
  accounts?: {
    username?: string;
    network_identifier?: TNetworkIdentifier;
  }[]
}

interface IState {
  socialAccounts: ISocialAccount[];
  loading: boolean;
  error: any;
}

const fetchSocialAccount = async (url): Promise<ISocialAccount> => {
  try {
    const resp = await fetch(url);
    const json = await resp.json();
    if (json?.status?.code === 200 && json?.data) {
      return json.data;
    }
    throw json?.status?.error_msg;
  } catch (err) {
    logger.error({ message: err });
    throw err;
  }
};

/**
 * Fetch social profile data from backend_server
 *
 * IMPORTANT: This is a platform violation, see why here:
 * https://aspireiq.atlassian.net/wiki/spaces/CEP/pages/475562033/Known+Platform+Violations
 */
export const useFetchSocialAccount = (props: IProps): IState => {
  const {
    accounts,
  } = props;

  const [state, setState] = useState<IState>({
    socialAccounts: undefined,
    loading: true,
    error: undefined,
  });

  const { showError } = useMessagingContext();

  const { client } = useGetCurrentClient();
  const urls = useMemo(() => {
    if (!client?.id || !accounts) {
      return;
    }
    return map(accounts, (account) => {
      const query = qs.stringify({
        client_id: client.id,
        member_username: account.username,
        network: account.network_identifier,
        include_social_profile_data: true,
      });
      return `${backendServerApiEndpoint()}/social_account?${query}`;
    });
  }, [client, accounts]);

  useEffect(() => {
    // Fix for the loading state update
    if (!urls) {
      setState({
        socialAccounts: undefined,
        loading: false,
        error: undefined,
      });
      return;
    }

    setState({
      socialAccounts: undefined,
      loading: true,
      error: undefined,
    });
    // ------------------

    Promise.all(
      map(urls, (url) => fetchSocialAccount(url)
        .catch(() => { /* ingnore accounts with errors */ })),
    )
      .then((socialAccounts) => {
        setState({
          socialAccounts: reduce(socialAccounts, (accounts, account) => {
            const accountDetails = isArray(account) ? first(account) : account;
            if (accountDetails) {
              accounts.push(accountDetails);
            }
            return accounts;
          }, []),
          loading: false,
          error: undefined,
        });
      })
      .catch((err) => {
        showError(err);
        setState({
          socialAccounts: undefined,
          loading: false,
          error: err,
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urls]);

  return state;
};
