import { map, nth, slice } from 'lodash';
import { countries, languages, ISearchParams } from '@components';

import { ICreatorSearchFilters, ICreatorSearchOptions, SearchTermType, TRange } from '../models';

export const engagementRatioLimits = [0.0, 1.0];

export const mapSearchFiltersToAPIParams = (
  filters: ICreatorSearchFilters,
  options: ICreatorSearchOptions,
): ISearchParams => {
  const {
    query = '',
    termType = SearchTermType.Keywords,
    engagement = {},
    network = {},
    creatorDemographics = {},
    creatorSkillsets = {},
    audienceDemographics = {},
    other = {
      canContact: true,
    },
    contact,
    relationships: {
      projectIds = [],
      groupIds = [],
    } = {}
  } = filters;

  const {
    engagement: {
      reachLimitsByNetwork = {},
      engagementLimitsByNetwork = {},
      impressionLimitsByNetwork = {}
    } = {},
  } = options;

  const networkType = network.channel || 'instagram';
  const reachRange = engagement.reachRange || reachLimitsByNetwork[networkType];
  const engagementRange = engagement.engagementRange || engagementLimitsByNetwork[networkType];
  const impressionRange = engagement.impressionRange || impressionLimitsByNetwork[networkType];
  const engagementRatioRange = engagement.engagementRatioRange || engagementRatioLimits as TRange;
  const maximumReach = nth(reachRange, 1);
  const maximumEngagement = nth(engagementRange, 1);
  const maximumImpression = nth(impressionRange, 1);
  const maximumEngagementRatio = nth(engagementRatioRange, 1);
  const reachMax = nth(reachLimitsByNetwork[networkType], 1);
  const engagementMax = nth(engagementLimitsByNetwork[networkType], 1);
  const impressionMax = nth(impressionLimitsByNetwork[networkType], 1);
  const engagementRatioMax = nth(engagementRatioLimits, 1);

  return {
    // Search Field
    query,
    search_term_type: termType,
    // Creator Skillsets
    creator_skillsets: creatorSkillsets.selectedIdentifiers,
    // Network
    network_type: networkType,
    // Engagement
    minimum_reach: nth(reachRange, 0),
    maximum_reach: maximumReach === reachMax ? undefined : maximumReach,
    minimum_engagement: nth(engagementRange, 0),
    maximum_engagement: maximumEngagement === engagementMax ? undefined : maximumEngagement,
    minimum_impression: nth(impressionRange, 0),
    maximum_impression: maximumImpression === impressionMax ? undefined : maximumImpression,
    minimum_engagement_ratio: nth(engagementRatioRange, 0),
    maximum_engagement_ratio: maximumEngagementRatio === engagementRatioMax ? undefined : maximumEngagementRatio,
    // Creator Demographics
    locations: creatorDemographics.locations,
    country_codes: map(creatorDemographics.countries, 'value'),
    language_codes: map(creatorDemographics.languages, 'value'),
    gender: creatorDemographics.gender,
    age_ranges: creatorDemographics.ageRanges,
    age_min: creatorDemographics.ageMin,
    age_max: creatorDemographics.ageMax,
    interests: creatorDemographics.interests,
    // Audience Demographics
    audience_gender: audienceDemographics.gender,
    audience_gender_less_than: audienceDemographics.genderLessThan,
    audience_gender_percentage: audienceDemographics.genderPercentage,
    audience_age_range: audienceDemographics.ageRange,
    audience_age_min: audienceDemographics.ageMin,
    audience_age_max: audienceDemographics.ageMax,
    audience_ethnicity: audienceDemographics.ethnicity,
    audience_ethnicity_less_than: audienceDemographics.ethnicityLessThan,
    audience_ethnicity_percentage: audienceDemographics.ethnicityPercentage,
    audience_age_less_than: audienceDemographics.ageLessThan,
    audience_age_percentage: audienceDemographics.agePercentage,
    audience_country_region: audienceDemographics.countryRegion,
    audience_country_region_less_than: audienceDemographics.countryRegionLessThan,
    audience_country_region_percentage: audienceDemographics.countryRegionPercentage,
    audience_location_type: audienceDemographics.locationType,
    audience_locations: audienceDemographics.audienceLocations,
    // Other
    invited_type: other?.hideInvited ? 'uninvited' : 'all',
    tags: other?.tags,
    max_days_since_last_post: other?.lastPostActivity ? String(other?.lastPostActivity) : undefined,
    is_in_instagram_marketplace: networkType === 'instagram' ? contact?.canContactInstagramDM : undefined,
    has_email: networkType === 'instagram' ? contact?.canContactEmail : other?.canContact,
    has_sponsored_posts: other?.hasSponsoredPosts,
    // Relationships
    project_ids: projectIds,
    group_ids: groupIds,
  };
};

const DEFAULT_PERCENTAGE = 20;

export const mapAPIParamsToSearchFilters = (
  apiParams: ISearchParams,
  options: ICreatorSearchOptions,
): ICreatorSearchFilters => {
  const {
    query = '',
    search_term_type = SearchTermType.Keywords,
    creator_skillsets = [],
    network_type = 'instagram',
    has_sponsored_posts = false,
    minimum_reach,
    maximum_reach,
    minimum_engagement,
    maximum_engagement,
    minimum_impression,
    maximum_impression,
    minimum_engagement_ratio,
    maximum_engagement_ratio,
    locations = [],
    country_codes = [],
    language_codes = [],
    gender = '',
    age_ranges = [],
    age_min = '',
    age_max = '',
    interests = [],
    audience_gender = '',
    audience_gender_less_than = false,
    audience_gender_percentage = DEFAULT_PERCENTAGE,
    audience_ethnicity = '',
    audience_ethnicity_less_than = false,
    audience_ethnicity_percentage = DEFAULT_PERCENTAGE,
    audience_age_range = '',
    audience_age_min = '',
    audience_age_max = '',
    audience_age_less_than = false,
    audience_age_percentage = DEFAULT_PERCENTAGE,
    audience_country_region = '',
    audience_country_region_less_than = false,
    audience_country_region_percentage = DEFAULT_PERCENTAGE,
    audience_location_type = 'Country',
    audience_locations = [],
    invited_type = 'uninvited',
    tags = [],
    max_days_since_last_post,
    can_contact = true,
    is_in_instagram_marketplace,
    project_ids = [],
    group_ids = [],
    has_email,
  } = apiParams;

  const {
    engagement: { reachLimitsByNetwork = {}, engagementLimitsByNetwork = {}, impressionLimitsByNetwork = {} } = {},
  } = options;

  const reachRange = slice(reachLimitsByNetwork[network_type], 0) as [number, number];
  const engagementRange = slice(engagementLimitsByNetwork[network_type], 0) as [number, number];
  const impressionRange = slice(impressionLimitsByNetwork[network_type], 0) as [number, number];
  const engagementRatioRange = [...engagementRatioLimits];

  if (minimum_reach > reachRange[0]) {
    reachRange[0] = minimum_reach;
  }

  if (maximum_reach < reachRange[1]) {
    reachRange[1] = maximum_reach;
  }

  if (minimum_engagement > engagementRange[0]) {
    engagementRange[0] = minimum_engagement;
  }

  if (maximum_engagement < engagementRange[1]) {
    engagementRange[1] = maximum_engagement;
  }

  if (minimum_impression > impressionRange[0]) {
    impressionRange[0] = minimum_impression;
  }

  if (maximum_impression < impressionRange[1]) {
    impressionRange[1] = maximum_impression;
  }

  if (minimum_engagement_ratio > engagementRatioRange[0]) {
    engagementRatioRange[0] = minimum_engagement_ratio;
  }

  if (maximum_engagement_ratio < engagementRatioRange[1]) {
    engagementRatioRange[1] = maximum_engagement_ratio;
  }

  return {
    query,
    termType: search_term_type,
    creatorSkillsets: {
      selectedIdentifiers: creator_skillsets,
    },
    network: {
      channel: network_type,
    },
    contact: {
      canContactEmail: has_email,
      canContactInstagramDM: is_in_instagram_marketplace,
    },
    engagement: {
      reachRange,
      engagementRange,
      impressionRange,
      engagementRatioRange: engagementRatioRange as TRange,
    },
    creatorDemographics: {
      countries: map(country_codes, (code) => ({ value: code, label: countries[code] })),
      locations,
      languages: map(language_codes, (code) => ({ value: code, label: languages[code] })),
      gender,
      ageRanges: age_ranges,
      ageMin: age_min,
      ageMax: age_max,
      interests,
    },
    audienceDemographics: {
      gender: audience_gender,
      genderLessThan: audience_gender_less_than,
      genderPercentage: audience_gender_percentage,
      ethnicity: audience_ethnicity,
      ethnicityLessThan: audience_ethnicity_less_than,
      ethnicityPercentage: audience_ethnicity_percentage,
      ageRange: audience_age_range,
      ageMin: audience_age_min,
      ageMax: audience_age_max,
      ageLessThan: audience_age_less_than,
      agePercentage: audience_age_percentage,
      audienceLocations: audience_locations,
      countryRegion: audience_country_region,
      countryRegionLessThan: audience_country_region_less_than,
      countryRegionPercentage: audience_country_region_percentage,
      locationType: audience_location_type,
    },
    other: {
      hideInvited: invited_type === 'uninvited',
      tags,
      lastPostActivity: parseInt(max_days_since_last_post, 10) || undefined,
      canContact: can_contact,
      hasSponsoredPosts: has_sponsored_posts,
    },
    relationships: {
      projectIds: project_ids,
      groupIds: group_ids,
    }
  };
};

let searchedTerms: string[] = [];

export const getSearchedTerms = (): string[] => {
  return searchedTerms;
};

export const setSearchedTerms = (term: string): void => {
  if (term) {
    searchedTerms.push(term);
  }
};

export const clearSearchedTerms = (): void => {
  searchedTerms = [];
};
