import { uniqueId, trim } from 'lodash';

import { addEventLog } from '@components';
import {
  getErrorMessageFromResponse,
  isErrorResponse,
} from '@frontend/utils';

import {
  IContentUploaderAction,
  IContentUploader,
  IUploadProgress,
  IUploaderContent,
  TContentType,
  ActionTypes,
  ContentUploaderError,
} from './contentUploaderModel';
import {
  limitReachedSelector,
} from './contentUploaderSelectors';

/** *************************
 ***** Private actions *****
 ************************* */
const setContents = (contents: IUploaderContent[]): IContentUploaderAction => ({
    type: ActionTypes.SET_CONTENTS,
    payload: {
      contents,
    },
  });

const addContent = (content: IUploaderContent): IContentUploaderAction => ({
    type: ActionTypes.ADD_CONTENT,
    payload: {
      content,
    },
  });

const updateProgress = (id: string, progress: IUploadProgress): IContentUploaderAction => ({
    type: ActionTypes.UPDATE_PROGRESS,
    payload: {
      id,
      progress,
    },
  });

const updateFileUrl = (id: string, fileUrl: string, previewUrl: string): IContentUploaderAction => ({
    type: ActionTypes.UPDATE_FILE_URL,
    payload: {
      id,
      fileUrl,
      previewUrl,
    },
  });

const removeContent = (id: string): IContentUploaderAction => ({
    type: ActionTypes.REMOVE_CONTENT,
    payload: {
      id,
    },
  });

const removeContents = (): IContentUploaderAction => ({
    type: ActionTypes.REMOVE_CONTENTS,
  });

const setErrorMessage = (
  errorMessage: string = ContentUploaderError.DefaultMessage,
): IContentUploaderAction => ({
    type: ActionTypes.SET_ERROR_MESSAGE,
    payload: {
      errorMessage,
    },
  });

/**
 * The actual XHR that handles content uploading.
 *
 * @param {IUploaderContent} content the content to upload.
 */
const xhrUploadContent = (xhr: XMLHttpRequest, uploadUrl: string, content: IUploaderContent) => async (dispatch, getState) => {
    const state: IContentUploader = getState();
    const { postEndpoint } = state;
    const uploadEndpoint = uploadUrl === 'fake_url' ? postEndpoint : uploadUrl;

    addEventLog('upload_file', {
      file_name: content.name,
      file_type: content.file.type,
      file_size: content.file.size,
      file_size_in_mb: (content.file.size / (1024 * 1024)).toFixed(1),
    });

    xhr.open('POST', uploadEndpoint);

    xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
      const { lengthComputable, total, loaded } = event;
      if (lengthComputable) {
        const percentage = Math.floor((loaded / total) * 100);

        dispatch(
          updateProgress(content.id, {
            uploaded: loaded,
            percentage,
          }),
        );
      }
    });

    const promise = new Promise<{ fileUrl: string; previewUrl: string }>((resolve, reject) => {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          // if status is 0, operation could be aborted
          if (xhr.status === 0) {
            reject(
              new ContentUploaderError({
                isUploadAborted: true,
                message: ContentUploaderError.DefaultMessage,
              }),
            );
            return;
          }

          // response could be empty
          // or check for xhr.status != 200
          if (!xhr.response || xhr.status !== 200) {
            reject(new ContentUploaderError());
            return;
          }

          const response = JSON.parse(xhr.response);

          // response from gcloud
          // be really defensive here
          if (response.data && response.data.file_infos && response.data.file_infos[0]) {
            const fileUrl = response.data.file_infos[0].url;
            const previewUrl = response.data.file_infos[0].preview_url;

            resolve({ fileUrl, previewUrl });
          } else if (isErrorResponse(response)) {
            const errorMessage = getErrorMessageFromResponse(response);
            reject(new ContentUploaderError({ message: errorMessage }));
          } else {
            reject(new ContentUploaderError());
          }
        }
      };
    });

    const formData = new FormData();
    formData.append('file', content.file);
    xhr.send(formData);

    const { fileUrl, previewUrl } = await promise;

    addEventLog('upload_file_success', {
      file_name: content.name,
      file_type: content.file.type,
      file_size: content.file.size,
      file_size_in_mb: (content.file.size / (1024 * 1024)).toFixed(1),
      uploaded_url: fileUrl,
      preview_url: previewUrl,
    });

    dispatch(updateFileUrl(content.id, fileUrl, previewUrl));
  };

/**
 * @private
 * Gets the url for uploading the file.
 * This is needed for uploading large file to gcloud.
 *
 * @param {String} postEndpoint the post endpoint.
 * @param {String} filename the file name.
 * @param {String} type the file type.
 * @param {String} folder the folder to upload to.
 *
 * @return {String}
 */
const getFileUploadURL = async (
  postEndpoint: string,
  filename: string,
  type: string,
  folder: string,
) => {
  const resp = await fetch(
    `${postEndpoint}?filename=${trim(
      filename.replace(/[^\w\s.-]+/g, ''),
    )}&content_type=${type}&folder=${folder}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      credentials: 'include',
    },
  );

  if (resp.status !== 200) {
    throw new ContentUploaderError();
  }

  const json = await resp.json();

  if (isErrorResponse(json)) {
    const errorMessage = getErrorMessageFromResponse(json);
    throw new ContentUploaderError({ message: errorMessage });
  }

  const uploadUrl = json.upload_url ? json.upload_url : json.data ? json.data.upload_url : '';

  return uploadUrl;
};

/** *************************
 ***** public actions *****
 ************************* */
const uploadContent = (file: File, type: TContentType = 'image') => async (dispatch, getState) => {
    dispatch(setErrorMessage(null));

    const state: IContentUploader = getState();
    const { postEndpoint, uploadFolder } = state;

    // skip uploading if reach max limits
    const limitReached = limitReachedSelector(state);
    if (limitReached) {
      return;
    }

    // remove invalid chars from file name
    const validFilename = trim(file.name.replace(/[^\w\s.-]+/g, ''));
    let newFile;
    // try to create a new file using new filename
    // not supported in Edge and IE
    try {
      newFile = new File([file], validFilename, {
        type: file.type,
      });
    } catch (err) {
      console.log('File constructor is not supported.');

      newFile = file;
    }

    // set a unique id for later use
    const id = uniqueId(type);

    try {
      // add a preview image
      // const result = await readFileBase64(file);
      const xhr = new XMLHttpRequest();
      const content: IUploaderContent = {
        id,
        file: newFile,
        // dataUrl: result.dataUrl,
        localSrc: URL.createObjectURL(newFile),
        name: newFile.name,
        size: newFile.size,
        type,
        xhr,
      };
      dispatch(addContent(content));

      const uploadUrl = await getFileUploadURL(
        postEndpoint,
        content.name,
        content.type,
        uploadFolder,
      );

      await dispatch(xhrUploadContent(xhr, uploadUrl, content));
    } catch (err) {
      dispatch(removeContent(id));

      if (!err || !(err instanceof ContentUploaderError)) {
        // For any other error, show generic message.
        err = new ContentUploaderError();
      }

      // Don't show any error if upload is aborted
      if (!err.isUploadAborted) {
        console.error(err.message);
        dispatch(setErrorMessage(err.message));
      }
    }
  };

const deleteContent = (id) => (dispatch, getState) => {
    const state: IContentUploader = getState();
    const { contents } = state;
    const content = contents.find((content) => content.id === id);

    if (content && content.xhr) {
      content.xhr.abort();
    }

    dispatch(removeContent(id));
  };

const deleteContents = () => (dispatch, getState) => {
    const state: IContentUploader = getState();
    const { contents } = state;
    contents.forEach((content) => {
      if (content.xhr) {
        content.xhr.abort();
      }
    });

    // also remove content from server-side
    dispatch(removeContents());
  };

export default {
  setErrorMessage,
  setContents,
  uploadContent,
  deleteContent,
  deleteContents,
};
