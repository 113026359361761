._ClientContext_g6u09_1 {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
._ClientContext_g6u09_1 ._loading_g6u09_7 {
  height: 100%;
}
._ClientContext_g6u09_1 ._notice_g6u09_10 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._ClientContext_g6u09_1 ._notice_g6u09_10 .ant-result-image {
  margin: 0 0 40px 0;
}