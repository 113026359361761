import { useMutation, MutationHookOptions } from '@apollo/client';

import { GUEST_APPROVE_CONTENT_REVIEW_MUTATION } from '@frontend/app/queries';

import {
  GuestApproveContentReviewMutation,
  GuestApproveContentReviewMutationVariables,
} from '@frontend/app/queries/types/GuestApproveContentReviewMutation';

type IOptions = MutationHookOptions<GuestApproveContentReviewMutation, GuestApproveContentReviewMutationVariables>;

export function useGuestApproveContentReview(options: IOptions = {}) {
  const [approveReview, { loading, error }] = useMutation<
    GuestApproveContentReviewMutation,
    GuestApproveContentReviewMutationVariables
  >(GUEST_APPROVE_CONTENT_REVIEW_MUTATION, {
    ...options,
  });

  return {
    approveReview,
    loading,
    error,
  };
}
