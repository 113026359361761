import * as React from 'react';

import { useCharCounter } from '@affiliates/hooks';
import { CharCounter } from '@affiliates/components/OfferForm/CharCounter';
import { Form, Input } from '@revfluence/fresh';
import styles from '@affiliates/components/OfferForm/OfferForm.scss';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { ICommonFormElementProps } from '../../../types';

interface IProps extends ICommonFormElementProps<'name'> {
  charCount: number;
  source: OFFER_SOURCE;
}

export const OfferName: React.FC<Readonly<IProps>> = React.memo(({
  charCount: initialCharCount,
  disabled,
  name,
  source,
}) => {
  const rules = [
    { required: true, message: 'Please input your offer name!' },
    { max: 60, message: 'Your offer name is longer than 60 characters' },
    {
      validator: (_, value) => {
        if (source === OFFER_SOURCE.TUNE && value && (value.includes('%') || value.includes('&'))) {
          return Promise.reject(new Error('Offer name cannot contain "%" or "&"'));
        }
        return Promise.resolve();
      },
    },
  ];

  const { charCount, handleChange } = useCharCounter(initialCharCount);

  return (
    <>
      <Form.Item label="Offer Name" name={name} rules={rules} className={styles.hasCharacterCount}>
        <Input
          data-testid={testIds.offerName}
          disabled={disabled}
          onChange={handleChange}
          placeholder="Name your offer"
          size="large"
        />
      </Form.Item>
      <CharCounter
        charCount={charCount}
        maxCharCount={60}
      />
    </>
  );
});
