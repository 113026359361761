import * as React from 'react';
import { isNumber, isDate, isString } from 'lodash';
import { format } from 'date-fns';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import { Cell, ICellProps } from './Cell';

const { useCallback, useMemo } = React;

const DEFAULT_DATE_FORMAT = 'MMM dd, yyyy';

interface IProps extends ICellProps {
  isUnix?: boolean;
  showDistance?: boolean;
  dateFormatStr?: string;
  nullStr? : string;
}

/**
 * @type {React.FunctionComponent}
 */
export const DateCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    isUnix,
    showDistance,
    dateFormatStr,
    config,
    value,
    nullStr,
    ...restProps
  } = props;

  const dateValue = useMemo<Date>(() => {
    if (isDate(value)) {
      return value;
    }

    if (isNumber(value)) {
      const timestamp = isUnix ? value * 1000 : value;
      const date = new Date(timestamp);
      if (date.getTime() > 0) {
        return date;
      } else {
        console.warn(`Invalid date ${value}`);
        return null;
      }
    }

    if (isString(value)) {
      const date = new Date(value);
      if (date.getTime() > 0) {
        return date;
      }
    }

    return null;
  }, [value, isUnix]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatValue = useCallback((data: any) => {
    if (!data) {
      return nullStr;
    }

    return showDistance
      ? formatDistanceToNow(data)
      : format(data, dateFormatStr);
  }, [dateFormatStr, showDistance, nullStr]);

  const cellConfig = useMemo(() => {
    const copy = { ...config };
    copy.formatValue = copy.formatValue || formatValue;
    return copy;
  }, [formatValue, config]);

  return <Cell {...restProps} config={cellConfig} value={dateValue} />;
});

DateCell.defaultProps = {
  isUnix: true,
  showDistance: false,
  dateFormatStr: DEFAULT_DATE_FORMAT,
  nullStr: '-',
};
DateCell.displayName = 'DateCell';
