import { getEnvironment, Environment } from '@frontend/utils/Envionment/Environment';

const LOCAL_BACKEND_SERVER_WEB_ENDPOINT = 'http://localhost:9090/';
const LOCAL_ANALYTICS_SERVER_API_ENDPOINT = 'http://localhost:7001/api/v2';
const LOCAL_BACKEND_SERVER_API_ENDPOINT = 'http://localhost:9090/aspirex/api/v1';
const LOCAL_CREATOR_PORTAL_DOMAIN = 'http://localhost:3000';

const DEV_BACKEND_SERVER_WEB_ENDPOINT = 'https://app.aspireiq-dev.com/';
const DEV_ANALYTICS_SERVER_API_ENDPOINT = 'https://app.analytics.aspireiq-dev.com/api/v2';
const DEV_BACKEND_SERVER_API_ENDPOINT = 'https://app.aspireiq-dev.com/aspirex/api/v1';
const DEV_CREATOR_PORTAL_DOMAIN = 'https://creators.aspireiq-dev.com';

const STAGING_BACKEND_SERVER_WEB_ENDPOINT = 'https://app.aspireiq-staging.com/';
const STAGING_ANALYTICS_SERVER_API_ENDPOINT = 'https://app.analytics.aspireiq-staging.com/api/v2';
const STAGING_BACKEND_SERVER_API_ENDPOINT = 'https://app.aspireiq-staging.com/aspirex/api/v1';
const STAGING_CREATOR_PORTAL_DOMAIN = 'https://creators.aspireiq-staging.com';

const PROD_BACKEND_SERVER_WEB_ENDPOINT = 'https://app.aspireiq.com/';
const PROD_ANALYTICS_SERVER_API_ENDPOINT = 'https://app.analytics.aspireiq.com/api/v2';
const PROD_BACKEND_SERVER_API_ENDPOINT = 'https://app.aspireiq.com/aspirex/api/v1';
const PROD_CREATOR_PORTAL_DOMAIN = 'https://creators.aspireiq.com';

const currentEnvironment = getEnvironment();

export const backendServerWebEndpoint = () => {
  switch (currentEnvironment) {
    case Environment.PRODUCTION:
      return PROD_BACKEND_SERVER_WEB_ENDPOINT;
    case Environment.STAGING:
      return STAGING_BACKEND_SERVER_WEB_ENDPOINT;
    case Environment.DEVELOPMENT:
      return DEV_BACKEND_SERVER_WEB_ENDPOINT;
    case Environment.LOCALHOST:
      return LOCAL_BACKEND_SERVER_WEB_ENDPOINT;
    default:
      console.error('Invalid environemnt type received');
      return DEV_BACKEND_SERVER_WEB_ENDPOINT;
  }
};

export const analyticsServerApiEndpoint = () => {
  switch (currentEnvironment) {
    case Environment.PRODUCTION:
      return PROD_ANALYTICS_SERVER_API_ENDPOINT;
    case Environment.STAGING:
      return STAGING_ANALYTICS_SERVER_API_ENDPOINT;
    case Environment.DEVELOPMENT:
      return DEV_ANALYTICS_SERVER_API_ENDPOINT;
    case Environment.LOCALHOST:
      return LOCAL_ANALYTICS_SERVER_API_ENDPOINT;
    default:
      console.error('Invalid environemnt type received');
      return DEV_ANALYTICS_SERVER_API_ENDPOINT;
  }
};

export const backendServerApiEndpoint = () => {
  switch (currentEnvironment) {
    case Environment.PRODUCTION:
      return PROD_BACKEND_SERVER_API_ENDPOINT;
    case Environment.STAGING:
      return STAGING_BACKEND_SERVER_API_ENDPOINT;
    case Environment.DEVELOPMENT:
      return DEV_BACKEND_SERVER_API_ENDPOINT;
    case Environment.LOCALHOST:
      return LOCAL_BACKEND_SERVER_API_ENDPOINT;
    default:
      console.error('Invalid environemnt type received');
      return DEV_BACKEND_SERVER_API_ENDPOINT;
  }
};

export const creatorPortalDomain = () => {
  switch (currentEnvironment) {
    case Environment.PRODUCTION:
      return PROD_CREATOR_PORTAL_DOMAIN;
    case Environment.STAGING:
      return STAGING_CREATOR_PORTAL_DOMAIN;
    case Environment.DEVELOPMENT:
      return DEV_CREATOR_PORTAL_DOMAIN;
    case Environment.LOCALHOST:
      return LOCAL_CREATOR_PORTAL_DOMAIN;
    default:
      console.error('Invalid environemnt type received');
      return DEV_CREATOR_PORTAL_DOMAIN;
  }
};
