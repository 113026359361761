import { useQuery, QueryHookOptions } from '@apollo/react-hooks';

import { GET_CONTENT_FIELD_BY_ID_QUERY } from '@frontend/app/queries';
import {
  GetContentFieldById,
  GetContentFieldByIdVariables,
} from '@frontend/app/queries/types/GetContentFieldById';

type IOptions = QueryHookOptions<GetContentFieldById, GetContentFieldByIdVariables>;

export function useGetContentFieldById(id: number, options: IOptions = {}) {
  const {
    loading, data: { getContentFieldById = null } = {}, error, refetch,
  } = useQuery<
  GetContentFieldById,
  GetContentFieldByIdVariables
  >(GET_CONTENT_FIELD_BY_ID_QUERY, {
    ...options,
    variables: { id },
  });

  return {
    loading,
    contentField: getContentFieldById,
    error,
    refetch,
  };
}
