import gql from 'graphql-tag';

export const GET_COMMUNITIES_QUERY = gql`
  query GetCommunitiesQuery {
    communities {
      id
      title
      description
      splashImageUrl
      clientIds
      createdDate
      updatedDate
      memberCount
      showApplicantReview
    }
  }
`;
