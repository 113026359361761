import * as React from 'react';
import { uniqueId } from 'lodash';
import {
  ComposedChart,
  ResponsiveContainer,
  YAxis,
  XAxis,
  LineProps,
} from 'recharts';
import { IChartProps } from '../../types';

import { ReferenceLine } from '../ReferenceLine';
import styles from '../../Charts.module.scss';
import { Line } from '../Line/Line';
import { useAxisProps } from '../../hooks/useAxisProps';

const spacing = Number(styles['spacing-lg'].replace('px', ''));

/**
 * Line Chart Props
 */
export interface ILineChartProps extends IChartProps {
  /** Line Props */
  line: Omit<LineProps, 'ref'>;
}

/**
 * Line chart component
 */
export const LineChart: React.FC<Readonly<ILineChartProps>> = React.memo((props) => {
  const {
    xAxis,
    yAxis,
    line,
    referenceLines,
    data,
    margin,
  } = props;
  const xAxisProps = useAxisProps(xAxis);
  const yAxisProps = useAxisProps(yAxis);

  return (
    <ResponsiveContainer>
      <ComposedChart data={data} margin={margin}>
        <Line {...line} />
        {xAxis && <XAxis {...xAxisProps} />}
        {yAxis && <YAxis {...yAxisProps} />}
        {referenceLines
          && referenceLines.map((referenceLine) => (
            <ReferenceLine key={uniqueId()} {...referenceLine} />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
});

LineChart.defaultProps = {
  margin: {
    top: spacing,
    right: spacing,
  },
};
LineChart.displayName = 'LineChart';
