/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ezbng_157 {
  display: flex;
}

._justify-content-space-between_ezbng_161 {
  justify-content: space-between;
}

._tabular-nums_ezbng_165 {
  font-variant-numeric: tabular-nums;
}

._BarChartVertical_ezbng_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_ezbng_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._BarChartVertical_ezbng_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._BarChartVertical_ezbng_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._BarChartVertical_ezbng_169 ::-webkit-scrollbar-track, ._BarChartVertical_ezbng_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._BarChartVertical_ezbng_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Tooltip_ezbng_204 ._content_ezbng_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._BarChartVertical_ezbng_169 {
  display: flex;
  flex-direction: row;
  max-height: 250px;
}
._BarChartVertical_ezbng_169 ._list_ezbng_250 {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._BarChartVertical_ezbng_169 ._list_ezbng_250 ._listItem_ezbng_256 {
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
._BarChartVertical_ezbng_169 ._list_ezbng_250 ._listItem_ezbng_256 ._bar_ezbng_263 {
  margin-bottom: 10px;
  flex: 1 1;
  width: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
._BarChartVertical_ezbng_169 ._list_ezbng_250 ._listItem_ezbng_256 ._bar_ezbng_263 ._progress_ezbng_271 {
  width: 100%;
  border-radius: 100px 100px 0 0;
}
._BarChartVertical_ezbng_169 ._list_ezbng_250 ._listItem_ezbng_256 ._label_ezbng_275 {
  height: 24px;
  flex-shrink: 0;
  font-size: 11px;
  color: #4f4f4f;
  text-transform: uppercase;
}

._Tooltip_ezbng_204 ._content_ezbng_204 {
  min-width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #f7f7f7;
}
._Tooltip_ezbng_204 ._content_ezbng_204 ._dot_ezbng_291 {
  margin-right: 10px;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border-radius: 100px;
}
._Tooltip_ezbng_204 ._content_ezbng_204 ._label_ezbng_275 {
  margin-right: 10px;
}