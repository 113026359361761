import { ShopifyOwnerTypeEnum } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';

export const DEFAULT_META_FIELDS = {
  [ShopifyOwnerTypeEnum.COLLECTION]: [
    {
      id: 'collectionId',
      key: 'collectionId',
      name: 'Collection ID',
    },
    {
      id: 'collectionName',
      key: 'collectionName',
      name: 'Collection Name',
    },
    {
      id: 'collectionImage',
      key: 'collectionImage',
      name: 'Collection Images',
    },
    {
      id: 'productsCount',
      key: 'productsCount',
      name: 'Products count',
    },
    {
      id: 'collectionDescription',
      key: 'collectionDescription',
      name: 'Collection Description',
    },
    {
      id: 'collectionHandle',
      key: 'collectionHandle',
      name: 'Collection Handle',
    },
  ],
  [ShopifyOwnerTypeEnum.PRODUCT]: [
    {
      id: 'productId',
      key: 'productId',
      name: 'Product ID',
    },
    {
      id: 'productName',
      key: 'productName',
      name: 'Product Name',
    },
    {
      id: 'productImage',
      key: 'productImage',
      name: 'Product Images',
    },
    {
      id: 'productPrice',
      key: 'productPrice',
      name: 'Product Price',
    },
    {
      id: 'totalVariants',
      key: 'totalVariants',
      name: 'Total Variants',
    },
    {
      id: 'inventoryQuantity',
      key: 'inventoryQuantity',
      name: 'Inventory Quantity',
    },
    {
      id: 'productDescription',
      key: 'productDescription',
      name: 'Product Description',
    },
    {
      id: 'productCategory',
      key: 'productCategory',
      name: 'Product Category',
    },
    {
      id: 'productType',
      key: 'productType',
      name: 'Product Type',
    },
    {
      id: 'productVendor',
      key: 'productVendor',
      name: 'Product Vendor',
    },
    {
      id: 'productTags',
      key: 'productTags',
      name: 'Product Tags',
    },
    {
      id: 'status',
      key: 'status',
      name: 'Status',
    },
  ],
  [ShopifyOwnerTypeEnum.PRODUCTVARIANT]: [
    {
      id: 'variantId',
      key: 'variantId',
      name: 'Variant ID',
    },
    {
      id: 'variantName',
      key: 'variantName',
      name: 'Variant Name',
    },
    {
      id: 'variantSku',
      key: 'variantSku',
      name: 'Variant SKU',
    },
    {
      id: 'variantImage',
      key: 'variantImage',
      name: 'Variant Images',
    },
    {
      id: 'variantInventory',
      key: 'variantInventory',
      name: 'Variant Inventory',
    },
    {
      id: 'variantPrice',
      key: 'variantPrice',
      name: 'Variant Price',
    },
    {
      id: 'displayName',
      key: 'displayName',
      name: 'Display Name',
    },
    {
      id: 'compareAtPrice',
      key: 'compareAtPrice',
      name: 'Compare At Price',
    },
  ],
};

export const OWNER_TYPE_TO_META_FIELD_KEY = {
  [ShopifyOwnerTypeEnum.COLLECTION]: 'collectionMetaFields' as const,
  [ShopifyOwnerTypeEnum.PRODUCT]: 'productMetaFields' as const,
  [ShopifyOwnerTypeEnum.PRODUCTVARIANT]: 'variantMetaFields' as const,
};
