import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const NetworkSvgIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M8.38292 11.2562C8.43802 11.4766 8.43802 11.7521 8.43802 12.0275C8.43802 12.303 8.43802 12.5234 8.38292 12.7989C8.43802 12.7989 8.54821 12.854 8.60331 12.9091L14.2231 15.9394C14.8843 15.0579 15.9862 14.5069 17.1432 14.5069C19.1818 14.5069 20.8898 16.1598 20.8898 18.2534C20.8898 20.3471 19.2369 22 17.1432 22C15.0496 22 13.3967 20.3471 13.3967 18.2534C13.3967 17.9229 13.4518 17.6474 13.5069 17.3719H13.4518L7.88705 14.1763C7.83196 14.1763 7.83196 14.1212 7.77686 14.1212C7.1157 15.0579 6.01377 15.719 4.74656 15.719C2.65289 15.719 1 14.0661 1 11.9725C1 9.87879 2.65289 8.2259 4.74656 8.2259C6.01377 8.2259 7.1157 8.83196 7.77686 9.7686L13.562 6.6832C13.5069 6.35262 13.4518 6.07713 13.4518 5.74656C13.4518 3.70799 15.1047 2 17.1983 2C19.292 2 20.9449 3.65289 20.9449 5.74656C20.9449 7.84022 19.292 9.49311 17.1983 9.49311C16.0413 9.49311 14.9945 8.94215 14.2782 8.1157L8.38292 11.2562ZM4.74656 14.1763C5.95868 14.1763 6.89532 13.1846 6.89532 12.0275C6.89532 10.8705 5.90358 9.87879 4.74656 9.87879C3.58953 9.87879 2.5427 10.8154 2.5427 11.9725C2.5427 13.1295 3.53444 14.1763 4.74656 14.1763ZM17.1432 7.95041C18.3554 7.95041 19.292 6.95868 19.292 5.80165C19.292 4.64463 18.3003 3.65289 17.1432 3.65289C15.9862 3.65289 14.9945 4.64463 14.9945 5.80165C14.9945 6.95868 15.9311 7.95041 17.1432 7.95041ZM17.1432 20.4022C18.3554 20.4022 19.292 19.4105 19.292 18.2534C19.292 17.0964 18.3003 16.1047 17.1432 16.1047C15.9862 16.1047 14.9945 17.0964 14.9945 18.2534C14.9945 19.4105 15.9311 20.4022 17.1432 20.4022Z" />
  </SvgIcon>
));

NetworkSvgIcon.displayName = 'NetworkSvgIcon';
