/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';
import {
 map, flatMap, isNil, isEmpty, filter as lodashFilter, filter,
} from 'lodash';

import { Typography } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { OUTLOOK_APP_ID, GMAIL_APP_ID, SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import {
 UserGroupIcon, EnvelopeIcon, PencilIcon, PaperPlaneTopIcon,
} from '@revfluence/fresh-icons/regular/esm';

import { UserAvatar, CommunityDropdown } from '@frontend/app/components';
import { EmailComposerModal } from '@frontend/app/components';

import { useResourceContext } from '@frontend/app/context';
import { useAuth } from '@frontend/context/authContext';
import { useApplicationNavigation, useInstagramDMFlag, useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { useMessagingContext, TAssigneeType } from './context/MessagingAppContext';

import styles from './ThreadFolderSection.scss';
import { getThreadDisplayName, getThreadIcon } from './utils/thread';

const { Text } = Typography;
const { useMemo } = React;

interface IProps {
  visible: boolean;
  onClick?: () => void;
  className?: string;
}

/**
 * @todo - DT-4844 Delete once FF `inboxFoldersV2` is removed and GA
 * @type {React.FunctionComponent}
 */
export const LegacyThreadFolderSection: React.FunctionComponent<IProps> = React.memo((props) => {
  const { user: authUser } = useAuth();
  const {
    threadCountsByResourceId,
    assigneeType,
    setAssigneeType,
    setCurrentFolderLabel,
    resourceId,
    setResourceId,
    sharedThreadsCount,
    userThreadsCount,
    setIsAllFolderOpen,
  } = useMessagingContext();

  const {
    messageResources,
    activeMessageResources,
    addAccount,
    sharedMessageResources,
    individualMessageResources,
  } = useResourceContext();
  const { goTo: navigateTo } = useApplicationNavigation();
  const { isInstagramDMEnabled } = useInstagramDMFlag();
  const showInstagramMeta = useClientFeatureEnabled(ClientFeature.SHOW_INSTAGRAM_META);

  const threadFilterConfig: {
    [key in TAssigneeType]: {
      icon: any;
      assignee: TAssigneeType;
      label: string;
      count?: number;
    };
  } = {
    shared: {
      icon: <UserGroupIcon />,
      assignee: 'shared',
      count: sharedThreadsCount,
      label: 'Shared',
    },
    you: {
      icon: <UserAvatar size={18} name={authUser.name} profilePicture={authUser.picture} />,
      assignee: 'you',
      count: userThreadsCount,
      label: 'You',
    },
    appNotification: {
      icon: <EnvelopeIcon />,
      assignee: 'appNotification',
      label: 'App Notifications',
    },
    all: {
      icon: <TeamOutlined />,
      assignee: 'all',
      count: null,
      label: 'All',
    },
    sent: null,
  };

  threadFilterConfig.sent = {
    icon: <PaperPlaneTopIcon />,
    assignee: 'sent',
    count: null,
    label: 'Sent',
  };

  const disconnectedResources = useMemo(
    () =>
      lodashFilter(
        messageResources,
        (resource) => !resource.authProvider.userRevokedAccess && resource.authProvider.systemRevokedAccess,
      ),
    [messageResources],
  );

  const goToSettings = (resourceType: ResourceType) => {
    if (resourceType === ResourceType.GMAIL) {
      navigateTo(GMAIL_APP_ID, '/settings');
    } else if (resourceType === ResourceType.OUTLOOK) {
      navigateTo(OUTLOOK_APP_ID, '/settings');
    } else if (resourceType === ResourceType.IGDM) {
      navigateTo(SOCIAL_POST_APP_ID, '/settings');
    }
  };

  return (
    <div
      onClick={props.onClick}
      className={cx(styles.ThreadFolderSection, props.className, {
        [styles.visible]: props.visible,
      })}
    >
      <div className={styles.dropdownWrapper}>
        <CommunityDropdown optionAll useQueryParam={false} />
      </div>
      <div className={styles.composerWrapper}>
        <EmailComposerModal
          allowSendingAsSeparate
          className={styles.messageComposer}
          label="Compose"
          icon={<PencilIcon />}
        />
      </div>
      <div className={styles.threadFilter}>
        {flatMap(threadFilterConfig, (config, key) => {
          const components = [
            <div
              key={key}
              className={cx(styles.filterItem, {
                [styles.marginTop]: !isEmpty(activeMessageResources) && config?.assignee === 'appNotification',
                [styles.active]: isNil(resourceId) && config?.assignee === assigneeType,
              })}
              onClick={() => {
                setAssigneeType(config?.assignee);
                setCurrentFolderLabel(config?.label);
                setResourceId(null);
                setIsAllFolderOpen(true);
              }}
            >
              <div className={styles.iconWrapper}>{config?.icon}</div>
              <div className={styles.label}>{config?.label}</div>
              {
                !isNil(config?.count) && (
                  <div className={styles.count}>{config.count || 0}</div>
                )
              }
            </div>,
          ];

          if ((config?.assignee === 'you' || config?.assignee === 'shared') && !isEmpty(activeMessageResources)) {
            const isShared = config?.assignee === 'shared';
            const accounts = isShared ? sharedMessageResources : individualMessageResources;
            const nonInstagramAccounts = filter(accounts, (account) => account.type !== ResourceType.IGDM);
            const filteredAccounts = isInstagramDMEnabled || showInstagramMeta ? accounts : nonInstagramAccounts;
            components.push(
              ...map(filteredAccounts, (resource) => (
                <div
                  key={resource.id}
                  className={cx(styles.filterItem, styles.secondary, {
                    [styles.active]:
                      resourceId && (resourceId === resource.id || resourceId === resource.adminResourceId),
                  })}
                  onClick={() => {
                    if (isShared) {
                      setAssigneeType('shared');
                      setCurrentFolderLabel('Shared');
                      if (resource.isAdmin) {
                        setResourceId(resource.id);
                      } else {
                        setResourceId(resource.adminResourceId);
                      }
                    } else {
                      setAssigneeType('you');
                      setCurrentFolderLabel('You');
                      setResourceId(resource.id);
                    }
                    setIsAllFolderOpen(true);
                  }}
                >
                  <div className={styles.iconWrapper}>{getThreadIcon(resource.type)}</div>
                  <Text ellipsis className={styles.label}>
                    {getThreadDisplayName(resource.authProvider.userExternalDisplayId, resource.type)}
                  </Text>
                  <div className={styles.count}>{threadCountsByResourceId[resource.id]}</div>
                </div>
              )),
            );
          }

          return components;
        })}
        {map(disconnectedResources, (resource) => (
          <div key={resource.id} className={styles.errorNotice}>
            <div className={styles.content}>
              <div className={styles.errorTitle}>
                {resource.authProvider.userExternalDisplayId}
                {' '}
                is not connected
              </div>
              <div className={styles.errorText}>There is a problem with your account</div>
            </div>
            <div className={styles.actions}>
              {resource.type !== ResourceType.IGDM && (
                <div
                  className={cx(styles.actionButton, styles.reconnect)}
                  onMouseUp={() => addAccount(resource.type, resource.authProvider.isShared)}
                >
                  Reconnect
                </div>
              )}
              <div className={styles.actionButton} onMouseUp={() => goToSettings(resource.type)}>
                Settings
              </div>
            </div>
          </div>
        ))}
      </div>
      {/*
      <div className={styles.userList}>
        <div className={styles.title}>Assigned to</div>
        <div className={styles.list}>
          {
            map(sortBy(users, 'name'), (user) => (
              <div key={user.id} className={styles.item}>
                <UserAvatar
                  name={user.name}
                  size={20}
                  className={styles.avatar}
                />
                <Text ellipsis>{user.name}</Text>
              </div>
            ))
          }
        </div>
      </div>
      */}
    </div>
  );
});

LegacyThreadFolderSection.displayName = 'LegacyThreadFolderSection';
