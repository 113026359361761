/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_iyx8n_157 {
  display: flex;
}

._justify-content-space-between_iyx8n_161 {
  justify-content: space-between;
}

._tabular-nums_iyx8n_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_iyx8n_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DefaultCell_iyx8n_178 {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._tooltip_iyx8n_185 {
  pointer-events: none;
}

._hideTooltip_iyx8n_189 {
  display: none !important;
}