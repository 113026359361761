import * as React from 'react';
import moment from 'moment';
import {
  Select,
  DatePicker,
  Typography,
  Space,
  Alert,
} from '@revfluence/fresh';
import {
  DateOperator,
  TDateRequirement,
  IDateBetween,
} from '../../types/ContentDateRequirements';
import styles from './DueDateItem.scss';
import { DueDateType } from '../../types/ContentGuidelines';

const { Option } = Select;
const { Text } = Typography;
const {
  useCallback,
  useMemo,
  useState,
} = React;

interface IProps {
  dueDateType: DueDateType;
  dueDateDescription: string;
  contentGuidelineInstanceId: number;
  dueDateIndex: number;
  dueDate: TDateRequirement;
  disabled?: boolean;
  disableDateFrom?: Date;
  onUpdateDueDate: (
    dueDateType: DueDateType,
    contentGuidelineInstanceId: number,
    dueDateIndex: number,
    dueDate: TDateRequirement,
  ) => void;
}

const getDateValue = (date: Date) => (date ? moment(date) : '');
const convertToDate = (date: moment.Moment | string) => (date === '' ? null : (date as moment.Moment).toDate());

const DueDateItem: React.FC<IProps> = React.memo((props) => {
  const {
    dueDateType,
    dueDateDescription,
    contentGuidelineInstanceId,
    dueDateIndex,
    dueDate,
    disabled,
    disableDateFrom,
    onUpdateDueDate,
  } = props;

  const [error, setError] = useState('');

  const getDueDateValue = useMemo(
    () => {
      switch (dueDate.operator) {
        case DateOperator.BEFORE: return getDateValue(dueDate.beforeDate);
        case DateOperator.ON: return getDateValue(dueDate.onDate);
        case DateOperator.BETWEEN: return getDateValue(dueDate.beforeDate);
        default: throw new Error('Unhandled operator');
      }
    }, [dueDate],
  );

  const handleChange = useCallback((
    operator: DateOperator,
  ) => {
      switch (operator) {
        case DateOperator.BEFORE:
          return onUpdateDueDate(
            dueDateType,
            contentGuidelineInstanceId,
            dueDateIndex,
            {
              operator,
              beforeDate: convertToDate(getDueDateValue),
            },
          );
        case DateOperator.ON:
          return onUpdateDueDate(
            dueDateType,
            contentGuidelineInstanceId,
            dueDateIndex,
            {
              operator,
              onDate: convertToDate(getDueDateValue),
            },
          );
        case DateOperator.BETWEEN:
          return onUpdateDueDate(
            dueDateType,
            contentGuidelineInstanceId,
            dueDateIndex,
            {
              operator,
              beforeDate: convertToDate(getDueDateValue),
              onDate: null,
            },
          );
        default: throw new Error('Unhandled operator');
      }
    },
    [
      dueDateType,
      dueDateIndex,
      contentGuidelineInstanceId,
      onUpdateDueDate,
      getDueDateValue,
    ]);

  const onChangeDate = useCallback((date: moment.Moment | null) => {
    setError('');
    const selectedDate = date ? date.toDate() : null;
    if (!selectedDate) {
      setError('Deadline is required to continue.');
    }
    if (dueDate.operator === DateOperator.BETWEEN) {
      const { onDate } = (dueDate as IDateBetween);
      if (
        onDate !== null
        && (date.isSame(moment(onDate, 'MM/DD/YYYY')) || date.isAfter(moment(onDate, 'MM/DD/YYYY')))) {
        setError('End date must be later than start date');
      }
    }
    switch (dueDate.operator) {
      case DateOperator.BEFORE:
        return onUpdateDueDate(
          dueDateType,
          contentGuidelineInstanceId,
          dueDateIndex,
          {
            operator: dueDate.operator,
            beforeDate: selectedDate,
          },
        );
      case DateOperator.ON:
        return onUpdateDueDate(
          dueDateType,
          contentGuidelineInstanceId,
          dueDateIndex,
          {
            operator: dueDate.operator,
            onDate: selectedDate,
          },
        );
      case DateOperator.BETWEEN:
        return onUpdateDueDate(
          dueDateType,
          contentGuidelineInstanceId,
          dueDateIndex,
          {
            operator: dueDate.operator,
            beforeDate: selectedDate,
            onDate: (dueDate as IDateBetween).onDate,
          },
        );
      default: throw new Error('Unhandled operator');
    }
  }, [onUpdateDueDate, dueDate, dueDateIndex, contentGuidelineInstanceId, dueDateType]);

  const onChangeOnDate = useCallback((date: moment.Moment | null) => {
    if (error !== '') {
      setError('');
    }
    onUpdateDueDate(
      dueDateType,
      contentGuidelineInstanceId,
      dueDateIndex,
      {
        operator: dueDate.operator,
        beforeDate: (dueDate as IDateBetween).beforeDate,
        onDate: date.toDate(),
      },
    );
  }, [
    onUpdateDueDate,
    dueDate,
    dueDateIndex,
    contentGuidelineInstanceId,
    dueDateType,
    error,
  ]);

  const getOperator = useCallback(
    (operator: DateOperator) => {
      if (operator !== dueDate.operator) {
        return (
          <Option
            key={`${contentGuidelineInstanceId}-${dueDateIndex}-${operator}`}
            value={operator}
          >
            {operator}
          </Option>
          );
      }
      return null;
    }, [dueDateIndex, contentGuidelineInstanceId, dueDate.operator],
  );

  const disabledDate = useCallback((selectedDate: moment.Moment) => {
    let current = moment();
    if (disableDateFrom) {
      current = moment(disableDateFrom);
    }
    return current > selectedDate.endOf('day');
  }, [disableDateFrom]);

  const disabledBetweenDate = useCallback(
    (selectedDate: moment.Moment) => {
      const { beforeDate } = dueDate as IDateBetween;
      return moment(beforeDate).add(1, 'days') > selectedDate.endOf('day');
    },
     [dueDate],
  );

  return (
    <Space direction="vertical">
      <Text>{dueDateDescription}</Text>
      <div className={styles.DueDateItem}>
        <Select
          defaultValue={dueDate.operator}
          style={{ width: 120 }}
          onChange={handleChange}
        >
          {
            [DateOperator.ON, DateOperator.BEFORE, DateOperator.BETWEEN].map((operator) => getOperator(operator))
          }
        </Select>
        <DatePicker
          onChange={onChangeDate}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          value={getDueDateValue}
          format="MM/DD/YYYY"
          disabledDate={disabledDate}
          disabled={disabled}
        />
        {
          dueDate.operator === DateOperator.BETWEEN && (
            <>
              <div className={styles.text}>
                <Text>and</Text>
              </div>
              <DatePicker
                onChange={onChangeOnDate}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                value={getDateValue((dueDate as IDateBetween).onDate)}
                format="MM/DD/YYYY"
                disabledDate={disabledBetweenDate}
                disabled={disabled}
              />
            </>
          )
        }
      </div>
      { error && (
        <Alert
          message={error}
          type="error"
        />
      )}
    </Space>
  );
});

DueDateItem.displayName = 'DueDateItem';

export default DueDateItem;
