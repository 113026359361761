import * as React from 'react';
import cx from 'classnames';

import { Form } from '@revfluence/fresh';
import FormRadioGroup from '../../components/FormRadioGroup';
import FormRadioSwitch from '../../components/FormRadioSwitch';
import FormSelect from '../../components/FormSelect';
import { onboardingObjectiveOptions } from '../../config';
import { IObjectiveProps, OnboardingDispatchActionType, OnboardingStep } from '../../types';
import styles from '../styles.scss';
import objectiveStyles from './styles.scss';

const { useCallback } = React;

const brandCategoryOptions = [
  { value: 'selfhelp', label: 'Advice/Education' },
  { value: 'beauty', label: 'Beauty' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'food', label: 'Food/Drink' },
  { value: 'wellness', label: 'Health/Wellness' },
  { value: 'decor', label: 'Home/Design' },
  { value: 'kidsfamily', label: 'Kids/Family' },
  { value: 'pets', label: 'Pets' },
  { value: 'tech', label: 'Tech' },
  { value: 'travel', label: 'Travel' },
  { value: 'other', label: 'Other' },
];

const Objective = (props: IObjectiveProps) => {
  const { state, dispatch } = props;

  const onFieldChange = useCallback((name: string, value) => {
    dispatch({
      type: OnboardingDispatchActionType.UpdateFieldValue,
      payload: {
        step: OnboardingStep.Objective,
        field: name,
        value,
      },
    });

    if (['hasBudget', 'hasHighValueProduct', 'hasRecognition'].includes(name)) {
      dispatch({
        type: OnboardingDispatchActionType.PreselectProjectType,
        payload: null,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.StepContent}>
      <Form layout="vertical" className={styles.stepForm}>
        <div className={styles.formItem}>
          <FormRadioGroup
            header="What is your brand's primary business objective?"
            value={state.data?.objective}
            name="objective"
            options={onboardingObjectiveOptions}
            onFieldChange={onFieldChange}
          />
        </div>
        <div className={styles.formItem}>
          <FormRadioSwitch
            header="Do you have a budget to pay creators?"
            value={state.data?.hasBudget}
            onFieldChange={onFieldChange}
            name="hasBudget"
          />
        </div>
        <div className={styles.formItem}>
          <FormRadioSwitch
            header="Do you have a high value product ($150+)?"
            value={state.data?.hasHighValueProduct}
            onFieldChange={onFieldChange}
            name="hasHighValueProduct"
          />
        </div>
        <div className={styles.formItem}>
          <FormRadioSwitch
            header="Do you have brand recognition/mature in market?"
            value={state.data?.hasRecognition}
            onFieldChange={onFieldChange}
            name="hasRecognition"
          />
        </div>
        <div className={cx(styles.formItem, objectiveStyles.brandCategory)}>
          <FormSelect
            label="How would you categorize your brand?"
            name="brandCategory"
            placeholder="Select a Category"
            value={state.data?.brandCategory}
            onFieldChange={onFieldChange}
            isRequired
            options={brandCategoryOptions}
          />
        </div>
      </Form>
      <div className={styles.stepInfo}>
        <h3 className={styles.title}>Why Is The Right Strategy Important?</h3>
        <div className={styles.section}>
          To set up your first project in Aspire, we first want to understand your brand and influencer marketing objectives to recommend the best strategy. You can go with our recommendation, or choose your own. Most creators prefer a paid collaboration, so having a budget will help you achieve results. If budget is limited, having a high-value product may incentivize creators in spreading awareness of your brand - in lieu of compensation.
        </div>
        <div className={styles.section}>
          We recommend paying creators or gifting products without expecting content in return.
        </div>
      </div>
    </div>
  );
};

const ObjectiveComponent = React.memo(Objective);

ObjectiveComponent.displayName = 'Objective';

export default ObjectiveComponent;
