/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ichst_157 {
  display: flex;
}

._justify-content-space-between_ichst_161 {
  justify-content: space-between;
}

._tabular-nums_ichst_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ichst_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ImagesCell_ichst_178 {
  display: flex;
  flex-direction: row;
}
._ImagesCell_ichst_178 ._image_ichst_182 {
  margin-right: 0.5rem;
}
._ImagesCell_ichst_178 ._image_ichst_182,
._ImagesCell_ichst_178 ._image_ichst_182 ._thumb_ichst_186 {
  width: 2.25rem;
  height: 2.25rem;
}
._ImagesCell_ichst_178 ._image_ichst_182:last-of-type {
  margin-right: 0;
}
._ImagesCell_ichst_178 ._thumb_ichst_186 {
  object-fit: cover;
  border-radius: 0.25rem;
}

._popoverOverlay_ichst_198 .ant-popover-inner-content {
  padding: 0.75rem;
}

._popoverContent_ichst_202 {
  max-width: 12.5rem;
}
._popoverContent_ichst_202 ._image_ichst_182 {
  object-fit: cover;
  width: 12.5rem;
  min-height: 12.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
._popoverContent_ichst_202 ._date_ichst_212 {
  margin-bottom: 0.25rem;
  color: #8f8d91;
  font-size: 0.75rem;
}
._popoverContent_ichst_202 p {
  margin: 0 0 0.25rem;
  font-size: 0.75rem;
}
._popoverContent_ichst_202 p:last-of-type {
  margin: 0;
}