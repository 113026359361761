import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const ColumnsIconV3 = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={props.viewBox || '9 10 14 14'}
  >
    <path d="M21.25 10.625C22.207 10.625 23 11.418 23 12.375V21.125C23 22.1094 22.207 22.875 21.25 22.875H10.75C9.76562 22.875 9 22.1094 9 21.125V12.375C9 11.418 9.76562 10.625 10.75 10.625H21.25ZM10.75 21.5625H15.3438V14.125H10.3125V21.125C10.3125 21.3711 10.5039 21.5625 10.75 21.5625ZM16.6562 21.5625H21.25C21.4688 21.5625 21.6875 21.3711 21.6875 21.125V14.125H16.6562V21.5625Z" />
  </SvgIcon>
  ));

ColumnsIconV3.displayName = 'ColumnsIconV3';
