import * as React from 'react';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';

import { IPaymentGroup } from './models';

const { useState, useCallback } = React;
export interface IPaymentGroupPayload {
  name: string;
  client_id: string;
  aspirex_application?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata?: any;
  cardId?: string;
}

const createPaymentGroup = async (url: string, payload: IPaymentGroupPayload): Promise<IPaymentGroup> => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.error(err);

    throw err;
  }
};

export const useCreatePaymentGroup = () => {
  const url = `${backendServerApiEndpoint()}/payment_group`;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaymentGroup>(null);
  const [error, setError] = useState(null);

  const save = useCallback(async (payload: IPaymentGroupPayload): Promise<IPaymentGroup> => {
    setLoading(true);

    let result;
    const clientUrl = `${url}?client_id=${payload.client_id}`;
    try {
      result = await createPaymentGroup(clientUrl, payload);
      setData(result);
    } catch (err) {
      setError(err);
    }

    setLoading(false);

    return result;
  }, [url]);

  return {
    save,
    loading,
    data,
    error,
  };
};
