import { useMutation } from '@apollo/client';
import { ThreadMarkDone, ThreadMarkDoneVariables } from '@frontend/app/queries/types/ThreadMarkDone';
import { THREAD_MARK_DONE_MUTATION, THREAD_MARK_TODO_MUTATION } from '@frontend/app/queries';
import { ThreadMarkTodo, ThreadMarkTodoVariables } from '@frontend/app/queries/types/ThreadMarkTodo';
import { ThreadLabelType } from '@frontend/app/types/globalTypes';

type TArgs = {
  threadId: string;
  onThreadStatusChange: () => void;
  userLabel: ThreadLabelType | '';
};

export const useUpdateThreadStatusById = (args: TArgs) => {
  const {
    threadId,
    userLabel,
    onThreadStatusChange,
  } = args;

  const [threadMarkDone] = useMutation<ThreadMarkDone, ThreadMarkDoneVariables>(THREAD_MARK_DONE_MUTATION, {
    onCompleted: () => {
      onThreadStatusChange();
    },
  });
  const [threadMarkTodo] = useMutation<ThreadMarkTodo, ThreadMarkTodoVariables>(THREAD_MARK_TODO_MUTATION, {
    onCompleted: () => {
      onThreadStatusChange();
    },
  });
  const updateThreadStatusById = async () => {
    if (userLabel === ThreadLabelType.DONE) {
      await threadMarkTodo({
        variables: { id: threadId },
      });
    } else {
      await threadMarkDone({
        variables: { id: threadId },
      });
    }
  };

  return {
    updateThreadStatusById,
  };
};
