/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8ergo_157 {
  display: flex;
}

._justify-content-space-between_8ergo_161 {
  justify-content: space-between;
}

._tabular-nums_8ergo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8ergo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SubmitModal_8ergo_178 {
  position: relative;
}
._SubmitModal_8ergo_178 ._button_8ergo_181 {
  min-width: 6.25rem;
}
._SubmitModal_8ergo_178 ._button_8ergo_181._submit_8ergo_184 {
  position: absolute;
  bottom: 1.25rem;
  right: 1.875rem;
}