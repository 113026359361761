import * as React from 'react';

import { Checkbox } from '@components';

import { FilterSection } from '../FilterSection';
import { IContactFilters, IContactOptions } from '../models';

interface ContactSectionProps extends IContactFilters, IContactOptions {
  onChange: (contactFilters: IContactFilters) => void;
  channel: string;
}

type ContactFilters = 'canContactEmail' | 'canContactInstagramDM';

export const ContactSection: React.FC<ContactSectionProps> = (props) => {
  const {
    hide,
    isExpanded,
    canContactEmail,
    canContactInstagramDM,
    channel,
    onChange
  } = props;

  const isInstagram = channel === "instagram";

  const handleChange = (filter: ContactFilters, value: boolean) => {
    onChange({
      [filter]: value,
    })
  };

  return (
    <FilterSection
      header="Contact"
      hide={hide}
      collapsible
      defaultIsOpen={isExpanded}
    >
      <Checkbox
        checked={canContactEmail}
        label="Can contact via email"
        onChange={(isChecked: boolean) => handleChange('canContactEmail', isChecked)}
      />
      {
        isInstagram
        && (
          <Checkbox
            checked={canContactInstagramDM}
            label="Can contact via direct message"
            onChange={(isChecked: boolean) => handleChange('canContactInstagramDM', isChecked)}
          />
        )
      }
    </FilterSection>
  );
}
