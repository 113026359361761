import { each, findIndex } from 'lodash';
import { ISearchResults } from '../models';
import { ISearchResultsAction, SearchResultsActionTypes } from './searchResultsActions';

const initialState: ISearchResults = {
  socialAccounts: null,
  isLoading: false,
  isFeaturedSearch: true,
  isImageSearch: false,
  isImageSearchSelected: false,
  hasNextPage: false,
  searchedMentions: '',
  currentPage: null,
  resultsCount: undefined,
  _promise: null,
};

export const searchResultsReducer = (
  state = initialState,
  action: ISearchResultsAction,
) => {
  switch (action.type) {
    case SearchResultsActionTypes.RESET_SEARCH_RESULTS:
      return {
        ...state,
        socialAccounts: [],
        resultsCount: undefined,
        currentPage: 0,
      };

    case SearchResultsActionTypes.FETCH_PAGE:
      return {
        ...state,
        currentPage: action.payload.page,
        socialAccounts: [],
      };

    case SearchResultsActionTypes.REFETCH_CREATORS_SUCCESS:
      const refetchedSocialAccounts = state.socialAccounts;

      each(action.payload.socialAccounts, (refetchedAccount) => {
        const currentAccountIndex = findIndex(refetchedSocialAccounts, (acc) => acc.id === refetchedAccount.id);

        if (currentAccountIndex > -1) {
          refetchedSocialAccounts[currentAccountIndex] = refetchedAccount;
        }
      });

      return {
        ...state,
        socialAccounts: refetchedSocialAccounts,
      };

    case SearchResultsActionTypes.FETCH_CREATORS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isImageSearch: action.payload.isImageSearch,
        isFeaturedSearch: action.payload.isFeaturedSearch,
        error: null,
        _promise: action.payload.promise,
      };

    case SearchResultsActionTypes.FETCH_CREATORS_SUCCESS:
      return {
        ...state,
        socialAccounts: action.payload.appendResults
          ? [...state.socialAccounts, ...action.payload.socialAccounts]
          : [...action.payload.socialAccounts],
        isLoading: false,
        searchedMentions: action.payload.query,
        resultsCount: Math.abs(action.payload.count),
        hasNextPage: action.payload.hasNext,
        currentPage: action.payload.page,
        promise: null,
        fetchedSearchTermType: action.payload.fetchedSearchTermType,
      };

    case SearchResultsActionTypes.FETCH_CREATORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.meta.errorMessage,
        },
        promise: null,
      };

    case SearchResultsActionTypes.SELECT_TEXT_SEARCH:
      return {
        ...state,
        isImageSearchSelected: false,
      };

    case SearchResultsActionTypes.SELECT_IMAGE_SEARCH:
      return {
        ...state,
        isImageSearchSelected: true,
      };

    default:
      return state;
  }
};
