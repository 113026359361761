import { MutationHookOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { useUpdateMemberPageLocalState, useMutation } from '@frontend/app/hooks';

export const useMemberMutation = <T, U>(query: DocumentNode, options: MutationHookOptions<T, U> = {}, showMembersHaveChanged: boolean = true) => {
  const updateCache = useUpdateMemberPageLocalState();

  return useMutation<T, U>(query, {
    ...options,
    update: (...updateArgs) => {
      if (options.update) {
        options.update(...updateArgs);
      }

      const [store] = updateArgs;

      if (showMembersHaveChanged) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateCache(store as any, { membersHaveChanged: true });
      }
    },
  });
};
