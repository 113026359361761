import 'css-chunk:src/applications/TermsApp/components/shared/Wizard/TermsWizard.scss';export default {
  "TermsWizard": "_TermsWizard_18f7h_1",
  "content": "_content_18f7h_1",
  "ApplicationDrawer": "_ApplicationDrawer_18f7h_1",
  "header": "_header_18f7h_6",
  "headerContentWrapper": "_headerContentWrapper_18f7h_18",
  "titleIcon": "_titleIcon_18f7h_23",
  "mainContentWrapper": "_mainContentWrapper_18f7h_28",
  "footer": "_footer_18f7h_34",
  "footerButton": "_footerButton_18f7h_44",
  "progressWrapper": "_progressWrapper_18f7h_47",
  "progress": "_progress_18f7h_47",
  "progressStatus": "_progressStatus_18f7h_58"
};