import { ITableCellType, ITableColumnConfig } from '@components';
import { isNumber } from 'lodash';

// If value is not number, change it to -
// With more need, we might be able to make the change in app/src/components/widgets/Table/Cell/NumericCell.tsx
// instead of a override
export const getFieldTypeConfig = (cellType: ITableCellType): Partial<ITableColumnConfig> => {
  switch (cellType) {
    case 'numeric':
      return {
        formatValue: (value) => (
          isNumber(value)
            ? numeral(value).format('0,0.[00]')
            : '-'
        ),
      };
  }
  return {};
};
