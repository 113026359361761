import React from 'react';

const LogIcon = ({ icon, background }: { icon: JSX.Element, background: string }) => (
  <div style={{
        width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', backgroundColor: background,
    }}
  >
    {icon}
  </div>
);
export default LogIcon;
