import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_MEMBER_IDS_BY_INTERNAL_STATUS } from '@frontend/app/queries';
import { GetMemberIdsByInternalStatus, GetMemberIdsByInternalStatusVariables } from '@frontend/app/queries/types/GetMemberIdsByInternalStatus';

type IOptions = QueryHookOptions<GetMemberIdsByInternalStatus, GetMemberIdsByInternalStatusVariables>;

export function useGetMemberIdsByInternalStatus(options: IOptions = {}) {
  const {
    loading, data: { memberIds } = {}, error, refetch,
  } = useQuery<GetMemberIdsByInternalStatus, GetMemberIdsByInternalStatusVariables>(
    GET_MEMBER_IDS_BY_INTERNAL_STATUS,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    memberIds,
    error,
    refetch,
  };
}
