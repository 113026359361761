import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
  isEmpty,
  isNumber,
  isString,
} from 'lodash';

import { EllipsisLabel } from '@frontend/app/components';
import { ThickBarChart } from '@components';
import { LazyImage } from '@components';
import { Tooltip } from '@components';
import { networkConfig } from '@components';
import { ISocialAccount } from '@components';
import { tooltipText } from '@frontend/utils/tooltipsUtils';

import {
  ICardTabConfig,
  Card,
} from './Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import {
  calculateHigherLowerText,
  getSegmentName,
} from '../utils';

import styles from './EngagementRateCard.scss';

const { useMemo, useRef } = React;

const aiqLogo = 'https://storage.googleapis.com/aspirex-static-files/aspire_green_mark.svg';

export const EngagementRateCard: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  const {
    socialAccount,
    hasData,
  } = useSocialProfileContext();
  const orgTabRef = useRef<HTMLDivElement>(null);
  const sponTabRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLHeadingElement>(null);

  const orgEngRatioChartElem = useMemo(() => {
    const orgEngRatio = hasData && isNumber(socialAccount.engagement_ratio)
      ? socialAccount.engagement_ratio
      : null;
    const orgEngRatioBenchmark = hasData && isNumber(socialAccount.engagement_ratio_benchmark)
      ? socialAccount.engagement_ratio_benchmark
      : null;
    const orgHigherLowerText = calculateHigherLowerText(orgEngRatio, orgEngRatioBenchmark);
    const hasOrgEngRatioData = (
      isNumber(orgEngRatio)
      && isNumber(orgEngRatioBenchmark)
      && isString(orgHigherLowerText)
    );

    return hasData && hasOrgEngRatioData && (
      <Chart
        category="organic"
        engRatio={orgEngRatio}
        engRatioBenchmark={orgEngRatioBenchmark}
        higherLowerText={orgHigherLowerText}
        socialAccount={socialAccount}
      />
    );
  }, [hasData, socialAccount]);

  const sponEngRatioChartElem = useMemo(() => {
    const sponEngRatio = hasData && isNumber(socialAccount.sponsored_engagement_ratio)
      ? socialAccount.sponsored_engagement_ratio
      : null;
    const sponEngRatioBenchmark = hasData && isNumber(socialAccount.sponsored_engagement_ratio_benchmark)
      ? socialAccount.sponsored_engagement_ratio_benchmark
      : null;
    const sponHigherLowerText = calculateHigherLowerText(sponEngRatio, sponEngRatioBenchmark);
    const hasSponEngRatioData = (
      isNumber(sponEngRatio)
      && isNumber(sponEngRatioBenchmark)
      && isString(sponHigherLowerText)
    );

    return hasData && hasSponEngRatioData && (
      <Chart
        category="sponsored"
        engRatio={sponEngRatio}
        engRatioBenchmark={sponEngRatioBenchmark}
        higherLowerText={sponHigherLowerText}
        socialAccount={socialAccount}
      />
    );
  }, [hasData, socialAccount]);

  const tabs: readonly ICardTabConfig[] = [
    {
      key: 'ALL',
      tabClassName: cx(styles.organicTab, {
        [styles.noData]: !orgEngRatioChartElem,
      }),
      contentRef: orgTabRef,
      content: orgEngRatioChartElem,
    },
    {
      key: 'SPONSORED',
      tabClassName: cx(styles.sponsoredTab, {
        [styles.noData]: !sponEngRatioChartElem,
      }),
      contentRef: sponTabRef,
      content: sponEngRatioChartElem,
    },
  ];

  return (
    <Card
      className={cx(className, styles.EngagementRateCard)}
      header={hasData
        ? (
          <>
            <h4
              className={cx(styles.cardTitle, styles.tooltip)}
              ref={headerRef}
            >
              Engagement Rate Comparison
            </h4>
            <Tooltip
              placement="bottom"
              mountRef={headerRef}
              tooltipColor="black"
              maxWidth={280}
            >
              {tooltipText.engagementRateComparison}
            </Tooltip>
          </>
)
        : ''}
      tabConfigs={hasData ? tabs : []}
    />
  );
};

EngagementRateCard.displayName = 'EngagementRateCard';

const Chart: React.FC<{
  category: 'organic' | 'sponsored';
  engRatio: number;
  engRatioBenchmark: number;
  higherLowerText: string;
  socialAccount: ISocialAccount;
}> = (props) => {
  const {
    category,
    engRatio,
    engRatioBenchmark,
    higherLowerText,
    socialAccount,
  } = props;
  const segmentDisplayName = getSegmentName(socialAccount);

  return (
    <>
      <ThickBarChart
        className={styles.chart}
        margins={{
        top: 80,
        bottom: 20,
        left: 0,
        right: 0,
      }}
        data={[
        {
          label: socialAccount.username,
          customLabel: (
            <div className={styles.barInfo}>
              <LazyImage
                className={styles.image}
                src={socialAccount.profile_image}
              />
              <h5 className={styles.username}>
                <EllipsisLabel
                  tooltipPlacement="top"
                  showTooltip
                >
                  @
                  {socialAccount.username}
                </EllipsisLabel>
              </h5>
              <div className={styles.value}>
                <span className={styles.percentage}>
                  {(Math.round(engRatio * 1000) / 10)}
                  %
                </span>
                {higherLowerText && (
                  <span className={cx(styles.description, {
                    [styles.higher]: engRatio > engRatioBenchmark,
                    [styles.lower]: engRatio < engRatioBenchmark,
                  })}
                  >
                    (
                    {higherLowerText}
                    )
                  </span>
                )}
              </div>
            </div>
          ),
          value: engRatio,
        },
        {
          label: segmentDisplayName ? `${segmentDisplayName} Segment` : '',
          customLabel: (
            <div className={styles.barInfo}>
              <LazyImage
                className={cx(styles.image, styles.aiqLogo)}
                src={aiqLogo}
              />
              <h5 className={styles.username}>
                {segmentDisplayName ? `${segmentDisplayName} Segment` : ''}
              </h5>
              <div className={styles.value}>
                <span className={styles.percentage}>
                  {Math.round(engRatioBenchmark * 1000) / 10}
                  %
                </span>
              </div>
            </div>
          ),
          value: engRatioBenchmark,
        },
      ]}
      />
      <Footer
        category={category}
        higherLowerText={higherLowerText}
      />
    </>
);
};

const Footer: React.FC<{
  category: string;
  higherLowerText: string;
}> = (props) => {
  const {
    category,
    higherLowerText,
  } = props;
  const {
    hasData,
    network,
    socialAccount,
  } = useSocialProfileContext();
  const netConfig = network ? networkConfig[network] : null;

  if (
    !category
    || !hasData
    || !netConfig
    || !higherLowerText
    || isEmpty(socialAccount.engagement_ratio_segment_range)
  ) {
    return;
  }

  const [minRange, maxRange] = socialAccount.engagement_ratio_segment_range;
  const segmentDisplayName = getSegmentName(socialAccount);

  return (
    <footer className={styles.footer}>
      Creator
      {' '}
      {category}
      {' '}
      engagement rate is
      {' '}
      <span>{higherLowerText}</span>
      {' '}
      than the
      <br />
      average of the
      {' '}
      <span>
        {segmentDisplayName}
        {' '}
        segment
        (
        {isNumber(maxRange)
        ? `${numeral(minRange).format('0,0')} to ${numeral(maxRange).format('0,0')}`
        : `> ${numeral(minRange).format('0,0')}`}
        {' '}
        {netConfig.audienceNamePlural}
        )
      </span>
    </footer>
  );
};
