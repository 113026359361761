import { IArtifact } from '@frontend/applications/Shared/context/applicationContext';
import { IAssignmentAPIPayload } from '../Shared/components/ArtifactAssignmentForm';

// Pulled from backend_server:licensed_content.py directly, which includes backend compatibility
// concerns
export enum ContentRightsType {
  PERMISSION_LEVEL_BROAD = 'full',
  PERMISSION_LEVEL_BROAD_LIMITED_TIME = 'full_limited_time',
  PERMISSION_LEVEL_CUSTOM = 'limited',
  PERMISSION_LEVEL_NONE = 'none',
}

export interface ISendLicensedContentRequest {
  content: ISaveLicensedContentFormat[];
  member: IMember;
  user: IUser;
  client_id: string;
}

interface ISaveLicensedContentFormat {
  media: IMedia[];
  member_id: number;
  permission_level?: ContentRightsType;
  permission_notes?: string;
  tags?: string[];
  sku?: string;
  content_type: string;
  source: string;
}

interface IUpdateContentRequest extends IAssignmentAPIPayload {
  id: number;
}

interface IUpdateMultipleContentRequest {
  content_ids: number[];
  content: IUpdateContentRequest[];
}

interface IMember {
  name: string;
  email: string;
  id: number;
  profile_picture?: string;
}

interface IUser {
  name: string;
  email: string;
  profile_picture?: string;
}

export type TMediaType = 'image' | 'video';

export interface IMedia {
  media_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  md5_hash?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content_type?: any;
  resolution?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  width?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  height?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preview_url?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform?: any;
  url: string;
}

export interface ILicensedContent extends IArtifact {
  id: number;
  media: string[];
  member_id: number;
  client_id: string;
  license_rights: string;
  license_rights_type: string;
  sync_to: string[];
  tags: string[];
  sku: string;
  permission_level: ContentRightsType;
  permission_notes: string;
  drive_sync_status: {
    [driveId: string]: {
      status: string;
      error: string;
    }
  };
}

export const saveContentData = async (url: string, params: ISendLicensedContentRequest) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateContentData = async (url: string, params: IUpdateMultipleContentRequest) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};
