import * as React from 'react';

interface IProps {
  className?: string;
  size?: number;
}

export const Deactivate: React.FC<Readonly<IProps>> = ({ className, size }) => {
  const dimensions = {
    height: size,
    width: size,
  };
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...dimensions}
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        {...dimensions}
      >
        <rect fill="currentColor" {...dimensions} />
      </mask>
      <g mask="url(#mask0)">
        <path
          clipRule="evenodd"
          d="M7.37697 1.6665C6.84654 1.6665 6.33783 1.87722 5.96276 2.25229L2.25278 5.96227C1.87771 6.33734 1.66699 6.84605 1.66699 7.37648V12.6232C1.66699 13.1536 1.87771 13.6623 2.25278 14.0374L5.96276 17.7474C6.33783 18.1225 6.84654 18.3332 7.37697 18.3332L12.6237 18.3332C13.1541 18.3332 13.6628 18.1225 14.0379 17.7474L17.7479 14.0374C18.1229 13.6623 18.3337 13.1536 18.3337 12.6232L18.3337 7.37648C18.3337 6.84605 18.1229 6.33734 17.7479 5.96227L14.0379 2.25229C13.6628 1.87722 13.1541 1.6665 12.6237 1.6665H7.37697ZM8.12351 3.4688C7.59307 3.4688 7.08437 3.67951 6.70929 4.05459L4.05507 6.70881C3.68 7.08388 3.46929 7.59259 3.46929 8.12302L3.46929 11.8767C3.46929 12.4071 3.68 12.9158 4.05507 13.2909L6.70929 15.9451C7.08437 16.3202 7.59307 16.5309 8.12351 16.5309H11.8771C12.4076 16.5309 12.9163 16.3202 13.2914 15.9451L15.9456 13.2909C16.3206 12.9158 16.5314 12.4071 16.5314 11.8767L16.5314 8.12302C16.5314 7.59259 16.3206 7.08388 15.9456 6.70881L13.2914 4.05459C12.9163 3.67951 12.4076 3.4688 11.8771 3.4688L8.12351 3.4688Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
      <path
        d="M12.4477 7.56431C12.095 7.21163 11.525 7.21163 11.1723 7.56431L10.006 8.73059L8.83973 7.56431C8.48705 7.21163 7.91699 7.21163 7.56431 7.56431C7.21163 7.91699 7.21163 8.48705 7.56431 8.83973L8.73059 10.006L7.56431 11.1723C7.21163 11.525 7.21163 12.095 7.56431 12.4477C7.7402 12.6236 7.97111 12.712 8.20202 12.712C8.43293 12.712 8.66384 12.6236 8.83973 12.4477L10.006 11.2814L11.1723 12.4477C11.3482 12.6236 11.5791 12.712 11.81 12.712C12.0409 12.712 12.2718 12.6236 12.4477 12.4477C12.8004 12.095 12.8004 11.525 12.4477 11.1723L11.2814 10.006L12.4477 8.83973C12.8004 8.48705 12.8004 7.91699 12.4477 7.56431Z"
        fill="currentColor"
      />
    </svg>
  );
};
Deactivate.defaultProps = {
  className: '',
  size: 20,
};
