import * as React from 'react';
import { UserPlusIcon } from '@revfluence/fresh-icons/regular/esm';

import {
  Empty,
  Space,
  Row,
  Typography,
  Button,
} from '@revfluence/fresh';

import { SOCIAL_DISCOVERY_APP_ID } from '@frontend/app/constants/applicationIds';

import { useClientFeatureEnabled, useGetCurrentClient } from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import { Empty as RefreshEmpty } from '@frontend/app/refresh-components';
import { useHistory } from 'react-router-dom';
import { ModalType } from '../../ProjectsPage/AddMembersToProgramModal/AddMembersToCollectionModal';

const { Title, Paragraph, Link } = Typography;
interface IProps {
  openAddToCollectionModal?(modalType: ModalType): void;
  projectId?: number;
}

const EmptyNoCreators: React.FC<IProps> = React.memo((props) => {
  const {
    openAddToCollectionModal,
    projectId,
  } = props;
  const { client: currentClient } = useGetCurrentClient();
  const findCreatorsURL = `/client/${currentClient.id}/app/${SOCIAL_DISCOVERY_APP_ID}?projectId=${projectId}`;

  const history = useHistory();

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  if (isRefreshUIEnabled) {
    return (
      <RefreshEmpty
        icon={UserPlusIcon}
        title="Looks like you could use some creators in here."
        description={(
          <>
            Go to Find Creators to start working with creators or add people
            {' '}
            <Link
              onClick={() => openAddToCollectionModal(ModalType.AddFromGroup)}
            >
              from your groups
            </Link>
            {' '}
            or
            {' '}
            <Link
              onClick={() => openAddToCollectionModal(ModalType.AddFromProject)}
            >
              from other projects
            </Link>
            .
          </>
        )}
        primaryCtaProps={{
          text: 'Find Creators',
          onClick: () => history.push(`/app/${SOCIAL_DISCOVERY_APP_ID}?projectId=${projectId}`),
        }}
      />
    );
  }

  return (
    <Empty
      image={<UserPlusIcon color="#F0F0F0" />}
      size="small"
      description={(
        <>
          <Title level={5}>
            Looks like you could use some creators in here.
          </Title>
          <Paragraph type="secondary">
            Go to Find Creators to start working with creators or add people
            {' '}
            <Link
              onClick={() => openAddToCollectionModal(ModalType.AddFromGroup)}
            >
              from your groups
            </Link>
            {' '}
            or
            {' '}
            <Link
              onClick={() => openAddToCollectionModal(ModalType.AddFromProject)}
            >
              from other projects
            </Link>
            .
          </Paragraph>

          <Row justify="center">
            <Space size={12}>
              <Button type="primary">
                <Link href={findCreatorsURL}>
                  Find Creators
                </Link>
              </Button>
            </Space>
          </Row>
        </>
      )}
    />
  );
});

export default EmptyNoCreators;
EmptyNoCreators.displayName = 'EmptyNoCreators';
