/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gucbi_157 {
  display: flex;
}

._justify-content-space-between_gucbi_161 {
  justify-content: space-between;
}

._tabular-nums_gucbi_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gucbi_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._modal_gucbi_178 {
  z-index: 1000 !important;
}

._modalDialog_gucbi_182 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-width: 60rem !important;
  height: 90% !important;
  padding: 2.5rem 0 0 0 !important;
}
._modalDialog_gucbi_182 > [class*=Modal_closeButton] {
  top: 0.5rem !important;
  right: 0.75rem !important;
}
._modalDialog_gucbi_182 > [class*=Modal_content] {
  padding: 2.25rem !important;
}

._modalContent_gucbi_198 {
  display: flex;
  flex: 1;
  flex-direction: column;
}

._wizard_gucbi_204 {
  flex: 1;
}