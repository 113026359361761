import * as React from 'react';
import { filter, isEmpty } from 'lodash';
import { LoadSpinner } from '@components';
import { ResourceType } from '@frontend/app/types/globalTypes';

import {
  useGetResources,
} from '@frontend/app/hooks';

import Title from '@frontend/app/components/Title/Title';

import { WooAddNew } from '../components/WooAddNew';
import { WooAccountList } from '../components/WooAccountList';

import styles from './WooSettingsPage.scss';

const { useMemo } = React;

interface IProps {
  title?: string;
}

const WooCommerceSettingsPage: React.FC<IProps> = (props) => {
  const {
    resources,
    loading,
  } = useGetResources({
    variables: {
      ignoreRequestContextUserId: true,
    },
  });

  const activeAccounts = useMemo(() => filter(
      resources, (resource) =>
        !resource.authProvider.userRevokedAccess
        && resource.type === ResourceType.WOOCOMMERCE,
    ), [resources]);

  if (loading) {
    return (
      <div className={styles.spinner}>
        <LoadSpinner />
      </div>
    );
  }

  if (!isEmpty(activeAccounts)) {
    return (
      <>
        { props.title && <Title title={props.title} /> }
        <WooAccountList activeAccounts={activeAccounts} />
      </>
    );
  }

  return (
    <>
      { props.title && <Title title={props.title} /> }
      <WooAddNew className={styles.addNew} />
    </>
  );
};

export default WooCommerceSettingsPage;
