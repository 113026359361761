import gql from 'graphql-tag';

import {
  ONBOARDING_TEMPLATE_FRAGMENT,
  PROGRAM_FRAGMENT,
  USER_FRAGMENT,
} from './fragments';

export const PROJECT_BY_ID_QUERY = gql`
  query ProjectByIdQuery($id: Int!) {
    project: programById(id: $id) {
      ...ProgramFragment
      description
      splashImageUrl
      status
      archivedByUser {
        ...UserFragment
      }
      archivedDate
      deletedDate
      communityId
      published
      submittedForReview
      legacyLandingPage
      customLandingPagePath
      emailTemplateId
      templateName
      applicationPageTemplateName
      columns {
        memberFieldSchemaIds
        dbColumns
      }
      applicationFormFields {
        memberFieldSchemas {
          schemaId
          required
          label
          order
        }
        dbColumns {
          name
          required
          label
          order
        }
      }
      onboardingTemplateConfig {
        ...OnboardingTemplateFragment
      }
    }
  }
  ${PROGRAM_FRAGMENT}
  ${ONBOARDING_TEMPLATE_FRAGMENT}
  ${USER_FRAGMENT}
`;
