import { MutationHookOptions, useMutation } from '@apollo/client';
import { ADD_TAGS_TO_PRODUCTS } from '@frontend/applications/ProductFulfillmentApp/queries';
import { AddTagsToProducts, AddTagsToProductsVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/AddTagsToProducts';

type IOptions = MutationHookOptions<AddTagsToProducts, AddTagsToProductsVariables>;

export const useAddTagsToProducts = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<AddTagsToProducts, AddTagsToProductsVariables>(
    ADD_TAGS_TO_PRODUCTS, options,
);

  return {
    addTags: mutate,
    isAddingTags: loading,
  };
};
