import React from 'react';
import { Card } from '@frontend/shadcn/components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@/shadcn/lib/utils';

interface WidgetContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  widgetTitle: string;
  widgetIcon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  textColor?: string;
  bgColor?: string;
  widgetActions?: React.ReactNode;
  tooltip?: string;
}

const WidgetContainer: React.FC<WidgetContainerProps> = ({
  widgetTitle,
  widgetIcon,
  className,
  children,
  textColor = 'text-white',
  bgColor = 'bg-primary',
  widgetActions,
  tooltip,
  ...props
}) => (
  <Card {...props} className={cn('rounded-2xl h-full flex flex-col', className)}>
    <div className={cn('p-3 flex justify-between rounded-t-2xl', bgColor)}>
      <div className="flex gap-2 items-center">
        {widgetIcon}
        <span className={cn('text-base font-semibold', textColor)}>{widgetTitle}</span>
        {tooltip && (
          <Tooltip>
            <TooltipTrigger asChild>
              <CircleInfoIcon className="text-grey cursor-pointer" />
            </TooltipTrigger>
            <TooltipContent>{tooltip}</TooltipContent>
          </Tooltip>
        )}
      </div>
      <div className="flex gap-1 items-center">
        {widgetActions && widgetActions}
      </div>
    </div>
    <div className="border-solid border-[1px]" />
    <div className="p-4 bg-white flex-grow rounded-b-2xl">{children}</div>
  </Card>
);

export default WidgetContainer;
