import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';
import { CreateAppContainer } from '@frontend/app/components/CreateAppContainer/CreateAppContainer';
import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';

import styles from './SocialPostAppArchivedReports.scss';

const SocialPostAppArchivedReports: React.FunctionComponent = () => {
  const { token } = useAuth();
  return (
    <div className={styles.SocialPostAppArchivedReports}>
      <CreateAppContainer
        applicationId={SOCIAL_POST_APP_ID}
        token={token}
        className={styles.CreateContainer}
      />
    </div>
  );
};

export default SocialPostAppArchivedReports;
