import 'css-chunk:src/components/widgets/ContentUploader/Content/Content.scss';export default {
  "Content": "_Content_1hnvq_1",
  "content": "_content_1hnvq_5",
  "loading": "_loading_1hnvq_12",
  "hover": "_hover_1hnvq_15",
  "progressWrapper": "_progressWrapper_1hnvq_20",
  "progressLabel": "_progressLabel_1hnvq_33",
  "info": "_info_1hnvq_36",
  "fileType": "_fileType_1hnvq_43",
  "image": "_image_1hnvq_49",
  "pdf": "_pdf_1hnvq_52",
  "video": "_video_1hnvq_55",
  "detail": "_detail_1hnvq_58",
  "name": "_name_1hnvq_67",
  "size": "_size_1hnvq_71"
};