import * as React from 'react';
import cx from 'classnames';
import { size, map, sortBy } from 'lodash';

import { EmailComposerModal } from '@frontend/app/components';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { GetThreadQuery_thread as IThread } from '@frontend/app/queries/types/GetThreadQuery';

import { ClientFeature } from '@frontend/app/constants';
import { SystemMessageItem } from './SystemMessageItem';
import { ThreadHeader } from './ThreadHeader';
import { ApplicationDropDown } from '../../Application/ApplicationsDropDown';

interface IProps {
  thread: IThread;
  refetch(): void;
  className?: string;
  onToggleMessageCollapsed?: (isCollapsed: boolean, messageId: string) => void;
  onOpenOverview: () => void;
  setSelectedMemberId: (memberId: number) => void;
}

import styles from './SystemMessageList.scss';

const { useRef, useEffect } = React;

/**
 * @type {React.FunctionComponent}
 */
export const SystemMessageList: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    thread,
    refetch,
    onOpenOverview,
    setSelectedMemberId,
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  const isWorkflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  useEffect(() => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [ref]);

  return (
    <div className={cx(styles.SystemMessageList, props.className)}>
      <ThreadHeader
        onThreadStatusChange={refetch}
        thread={thread}
        className={styles.threadHeader}
        onOpenOverview={onOpenOverview}
        setSelectedMemberId={setSelectedMemberId}
      />
      <div className={styles.list}>
        {thread && map(sortBy(thread.messages, 'internalDate'), (message) => (
          <SystemMessageItem
            key={message.id}
            className={styles.item}
            message={message}
            onToggleMessageCollapsed={props.onToggleMessageCollapsed}
          />
        ))}
        <div ref={ref} />
        {size(thread?.members) === 1 && (
          <div className={styles.followUp}>
            <div className={styles.title}>
              Need to take additional action?
            </div>
            <div className={styles.replyOptions}>
              <EmailComposerModal
                isWorkflowEnabled={isWorkflowEnabled}
                label="New Thread"
                members={thread && thread.members ? [thread.members[0]] : []}
                source="systemMessageList"
              />
              <ApplicationDropDown
                className={styles.otherApps}
                isWorkflowEnabled={isWorkflowEnabled}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

SystemMessageList.displayName = 'SystemMessageList';
