import * as React from 'react';
import {
  Checkbox,
  Form,
  Space,
} from '@revfluence/fresh';
import { CLIENT_CONNECTION_STATUS, OFFER_PRICE_RULE_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { FormInstance } from 'antd';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import {
 FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions, OfferFormModes,
} from '../../../types';

interface IProps {
  disabled: boolean,
  mode: OfferFormModes,
  values: IShopifyPromoCodeFormValues;
  handleFormAsyncActions: (value: FormAsyncAction) => void,
  formRef: FormInstance,
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
}
export const MultipleShopifyStores: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  mode,
  values,
  handleFormAsyncActions,
  formRef,
  connectedAdvertiserForSync,
}) => {
  const clientsForSync = values.clientsForSync;
  const maxClientToSelect: number = 5;
  const rules = [
    {
      validator: (_) => {
        if (clientsForSync.length === 0) {
          return Promise.reject(new Error('Select at least one store to sync the promo codes to. If you want to disable the sync, please toggle off the “Sync Promo Codes” option.'));
        }
        if (clientsForSync.length > maxClientToSelect) {
          return Promise.reject(new Error(`Please select less than ${maxClientToSelect} stores.`));
        }
        return Promise.resolve();
      },
    },
  ];

  const handleCheckboxChnage = (connectedAdvertiserId: number, isChecked: boolean) => {
    const currentValues = formRef.getFieldValue('clientsForSync') || [];
    const oldClientIndex = currentValues.findIndex((client) => client.advertiserId === connectedAdvertiserId);
    if (oldClientIndex !== -1) {
      currentValues.splice(oldClientIndex, 1);
    } else {
      currentValues.push({
        advertiserId: connectedAdvertiserId,
        priceRuleAmount: null,
        priceRuleType: OFFER_PRICE_RULE_TYPE.PERCENTAGE,
        status: CLIENT_CONNECTION_STATUS.ACTIVE,
        isSelected: isChecked,
      });
    }
    formRef.setFieldsValue({
      clientsForSync: currentValues,
    });
    handleFormAsyncActions({ action: OfferFormAsyncActions.MULTIPLE_STORE_SELECT, payload: { advertiserId: connectedAdvertiserId, isSelected: isChecked } });
  };
  return (
    <>
      <Form.Item rules={rules} name="selectedClients">
        <Space direction="vertical" style={{ marginBottom: '8px' }}>
          {
            connectedAdvertiserForSync.map(((client) => {
              const isSelected = clientsForSync.find((c) => client.connectedAdvertiserId === c.advertiserId);
              if ((mode === OfferFormModes.Create && client.isConnected) || (mode === OfferFormModes.Edit && (isSelected?.status === CLIENT_CONNECTION_STATUS.ACTIVE || client.isConnected))) {
                return (
                  <Checkbox
                    checked={!!isSelected?.isSelected}
                    disabled={disabled}
                    key={client.id}
                    onChange={(e) => handleCheckboxChnage(client.connectedAdvertiserId, e.target.checked)}
                  >
                    {client.label}
                  </Checkbox>
                );
              }
              return null;
            }))
          }
        </Space>
      </Form.Item>
    </>
  );
});
MultipleShopifyStores.displayName = 'MultipleShopifyStores';
