import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Drawer,
  Row,
  Typography,
  Col,
  RadioChangeEvent,
  Input,
  Tooltip,
  Popconfirm,
  message,
  Alert,
  notification,
} from '@revfluence/fresh';
import {
  CalendarIcon, EnvelopeOpenDollarIcon, PlayPauseIcon, TagIcon, LinkIcon, ChevronRightIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { ArrowLeftIcon } from '@revfluence/fresh-icons/solid/esm';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
 filter, flatten, get, groupBy, has, isEmpty, isNull, keys, map,
 size,
} from 'lodash';
import { useMutation } from '@apollo/client';
import {
 OFFER_SOURCE, UpdateAdditionalDeeplinkBulk, UpdateAffiliateOfferDeeplinkBulkInput, UpdateAffiliatePayoutInput,
} from '../../types/globalTypes';
import { useUpdateOfferMembers } from '../../hooks';
import {
 IAdditionalDeeplinkGroupedMember, IAffiliateDeeplinkInfo, IManageDeeplinkForm, TMember, TMode,
} from '../MembersWizard/types';
import styles from './ManageOffer.scss';
import {
  GetOfferById_offer, GetOfferById_offer_links_affiliates, GetOfferById_offer_promos_affiliates,
} from '../../queries/types/GetOfferById';
import { OfferDetailCard } from '../AffiliatesApp/components/OfferDetailCard';
import { ManageOfferItem } from './ManageOfferItem';
import { cleanPromoCode } from '../MembersWizard/utils/cleanPromoCode';
import ManageOfferPayout from './Sections/ManageOfferPayout';
import RefreshPromoCodes from './Sections/RefreshPromoCodes';
import ManageOfferActivation from './Sections/ManageOfferActivation';
import OfferBulkDateAction from './Sections/OfferBulkDateAction';
import ManageDeepLink from './Sections/ManagePimaryDeepLink/ManageDeepLink';
import { REMOVE_BULK_DEEPLINKS } from '../../queries/removeBulkDeeplinksMutation';

const { Text, Title } = Typography;
interface IProps {
  offer: GetOfferById_offer;
  visible: boolean;
  onComplete: () => void;
  selectedMembers?: readonly TMember[];
  offerSource?: OFFER_SOURCE;
  onClose?: () => void;
  migrateToGraphQL?: boolean;
  selectedMemberIds?: number[];
  isWorkFlow?: boolean;
  setIsVisibleSelectOffer?: (value: boolean) => void
  manageDeepLinksAllowed?: boolean
}
enum AFFILIATE_STATUS {
  ACTIVE = 'active',
  DELETED = 'deleted',
  SUCCESS = 'success',
}
interface CustomColumn {
  title: string;
  dataIndex: string;
  key: string;
  width: number;
  ellipsis?: {
    showTitle: boolean;
  };
  render?: (text, record) => JSX.Element;
}

enum OFFER_ACTION_TYPE {
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  REFRESH = 'refresh',
  EDITPAYOUT = 'editPayout',
  UPDATE_BULK_ACTIVE_DATES = 'updateBulkActiveDates',
  UPDATE_BULK_REFRESH_DATES = 'refreshDates',
  DEEPLINK = 'deepLink',
}
type ActiveDate = {
  startDate: Date,
  endDate: Date,
  showEndDate: boolean
};
const TABLE_WIDTH = 166;

export const ManageOffer: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    offer,
    visible,
    offerSource,
    onComplete,
    selectedMembers,
    onClose,
    migrateToGraphQL,
    selectedMemberIds,
    isWorkFlow,
    setIsVisibleSelectOffer,
    manageDeepLinksAllowed = false,
  } = props;
  const defaultPayout = offerSource === OFFER_SOURCE.SHOPIFY ? offer.promos[0].defaultPayoutId : offer.links[0].defaultPayoutId;
  const isUngrouped = offerSource === OFFER_SOURCE.SHOPIFY ? offer.promos[0].isUngrouped : false;
  const [activeDates, setActiveDates] = useState<ActiveDate>({
    startDate: new Date(),
    endDate: null,
    showEndDate: false,
  });
  const [removeDeeplinks] = useMutation(REMOVE_BULK_DEEPLINKS);
  const [removeDisabled, setRemoveDisabled] = useState(true);
  const [mode, setMode] = useState<string | null>(null);
  const [data, setData] = useState<readonly TMember[]>(selectedMembers);
  const [defaultPayoutId, setDefaultPayoutId] = useState<number>(defaultPayout);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDuplicateError, setIsDuplicateError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedOfferMember, setSelectedOfferMember] = useState<(TMember & { status: string })[]>([]);
  const [toActivateMembers, setToActivateMember] = useState<(TMember & { status: string })[]>([]);
  const [toDeactivateMembers, setToDeactivateMember] = useState<(TMember & { status: string })[]>([]);
  const memberToOperate = mode === OFFER_ACTION_TYPE.ACTIVATE ? toActivateMembers : toDeactivateMembers;
  const netMembers = selectedMemberIds?.length - selectedMembers?.length;
  const [activeOrDeactive, setActiveOrDeactive] = useState('');
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [showDeeplinkRemoveTooltip, setShowDeeplinkRemoveTooltip] = useState(false);

  const [selectedActiveMembers, setSelectedActivemMembers] = useState([]);
  const [_, setDefaultDeeplinkUrl] = useState('');
  const [additionalDeeplinkGroupedMembers, setAdditionalDeeplinkGroupedMembers] = useState<IAdditionalDeeplinkGroupedMember>(null);
  const [manageDeeplinkForm, setManageDeeplinkForm] = useState<IManageDeeplinkForm>(null);
  const [linkValidation, setLinkValidation] = useState(false);
  // ---end Manage Deeplink states
  const [isFormViewVisible, setFormViewVisible] = useState(false);

  useEffect(() => {
    setSelectedActivemMembers(
      filter(selectedOfferMember, (member) => member.status.toLowerCase() === AFFILIATE_STATUS.ACTIVE),
    );
  }, [selectedOfferMember]);

  useEffect(() => {
    if (selectedActiveMembers.length) {
      const flattenedAdditionalLinks = flatten(map(selectedActiveMembers, (member): IAffiliateDeeplinkInfo[] => {
        const additionalDeeplinks = member.additionalDeeplinks || [];
        return map(
          additionalDeeplinks,
          (deeplink): IAffiliateDeeplinkInfo => ({
            affiliateDeeplinkId: deeplink.id,
            memberId: member.id,
            memberName: member.name,
            label: deeplink.label,
          }),
        );
      }));
      const groupedByLabel: IAdditionalDeeplinkGroupedMember = groupBy(flattenedAdditionalLinks, 'label');
      setAdditionalDeeplinkGroupedMembers(groupedByLabel);
    }
  }, [selectedActiveMembers]);

  useEffect(() => {
    const result = selectedMembers.map((selectedMember) => {
      let offerAffiliate = null;
      if (offerSource === OFFER_SOURCE.SHOPIFY) {
        const offerAffiliates = offer.promos[0].affiliates;
        offerAffiliate = offerAffiliates.find(
          (offerAffiliate) => offerAffiliate.id === selectedMember.affiliateOfferId
            && offerAffiliate.affiliate.status.toLowerCase() !== AFFILIATE_STATUS.DELETED,
        ) as GetOfferById_offer_promos_affiliates | undefined;
        return {
          ...selectedMember,
          status: offerAffiliate?.status ?? null,
        };
      } else {
        const offerAffiliates = offer.links[0].affiliates;
        const offerAffiliateStats = offer.links[0].affiliateStats;
        offerAffiliate = offerAffiliates.find(
          (offerAffiliate) => offerAffiliate.affiliateId === selectedMember.affiliateId
            && offerAffiliate.affiliate.status.toLowerCase() !== AFFILIATE_STATUS.DELETED,
        ) as GetOfferById_offer_links_affiliates | undefined;
        return {
          ...selectedMember,
          status: (offerAffiliateStats.find((affStat) => affStat.affiliateOfferId === offerAffiliate?.id)?.linkStatus || undefined) ?? null,
        };
      }
    });
    const newResult = result.filter((res) => res.status !== null);
    setData(newResult);
    setSelectedOfferMember(newResult);
  }, [selectedMembers, offer, offerSource]);

  const handleSteps = (mode: TMode) => {
    setIsLoading(false);
    if (mode === OFFER_ACTION_TYPE.DEACTIVATE || mode === OFFER_ACTION_TYPE.REFRESH) {
      setIsError(true);
    } else if (mode === OFFER_ACTION_TYPE.EDITPAYOUT) {
      setIsError(false);
    }
    setMode(mode);
  };
  const makeEmptyStates = useCallback(() => {
    setToActivateMember([]);
    setToDeactivateMember([]);
    setActiveOrDeactive('');
    setActiveDates({
      startDate: new Date(),
      endDate: null,
      showEndDate: false,
    });
  }, [setToActivateMember, setToDeactivateMember, setActiveOrDeactive, setActiveDates]);
  const goBack = () => {
    makeEmptyStates();
    if (isWorkFlow && mode === null) {
      onClose();
      setIsVisibleSelectOffer(true);
    } else if (mode === null) {
      onClose();
    } else {
      if (mode === OFFER_ACTION_TYPE.UPDATE_BULK_ACTIVE_DATES) {
        setMode(OFFER_ACTION_TYPE.REFRESH);
      } else {
        setMode(null);
        handleClearInput();
      }
    }
  };
  let hookMode: TMode = OFFER_ACTION_TYPE.DEACTIVATE;
  if (mode === OFFER_ACTION_TYPE.ACTIVATE && offerSource === OFFER_SOURCE.TUNE) {
    hookMode = OFFER_ACTION_TYPE.REFRESH;
  } else if (mode === OFFER_ACTION_TYPE.DEACTIVATE) {
    hookMode = OFFER_ACTION_TYPE.DEACTIVATE;
  } else if (mode === OFFER_ACTION_TYPE.REFRESH || mode === OFFER_ACTION_TYPE.UPDATE_BULK_ACTIVE_DATES || mode === OFFER_ACTION_TYPE.UPDATE_BULK_REFRESH_DATES) {
    hookMode = OFFER_ACTION_TYPE.REFRESH;
  } else if (mode === OFFER_ACTION_TYPE.EDITPAYOUT) {
    hookMode = OFFER_ACTION_TYPE.EDITPAYOUT;
  } else if (mode === OFFER_ACTION_TYPE.ACTIVATE && offerSource === OFFER_SOURCE.SHOPIFY) {
    hookMode = OFFER_ACTION_TYPE.REFRESH;
  } else if (mode === OFFER_ACTION_TYPE.DEEPLINK) {
    hookMode = OFFER_ACTION_TYPE.DEEPLINK;
  }

  // const isDeepLinkUrlEmptyorInvalid = useCallback((deepLinkUrl) => {
  //   const error = validateDeepLink(deepLinkUrl).error;
  //   return !deepLinkUrl || (error && error.length > 0);
  // }, []);

  const { onSave } = useUpdateOfferMembers(
    offer.id,
    hookMode,
    offerSource,
    onComplete,
    offerSource === OFFER_SOURCE.SHOPIFY ? offer.promos[0].defaultPayoutId : offer.links[0].defaultPayoutId,
  );
  let manageOfferVariable = null;
  switch (offerSource) {
    case OFFER_SOURCE.SHOPIFY:
      if (mode === OFFER_ACTION_TYPE.DEACTIVATE) {
        const extractedIdsArray = toDeactivateMembers.map((item) => ({ id: item.affiliateOfferId }));
        manageOfferVariable = {
          affiliates: extractedIdsArray,
          id: offer.id,
        };
      } else if (mode === OFFER_ACTION_TYPE.ACTIVATE) {
        const affiliates = toActivateMembers.map((affiliate) => ({
          memberId: affiliate.id,
          desiredPromoCode: affiliate.previousCode.code,
        }));
        manageOfferVariable = {
          affiliates,
          endDate: null,
          startDate: null,
          id: offer.id,
        };
      } else if (mode === OFFER_ACTION_TYPE.REFRESH || mode === OFFER_ACTION_TYPE.UPDATE_BULK_ACTIVE_DATES || mode === OFFER_ACTION_TYPE.UPDATE_BULK_REFRESH_DATES) {
        const affiliates = data.map((affiliate) => ({
          memberId: affiliate.id,
          desiredPromoCode: mode === OFFER_ACTION_TYPE.UPDATE_BULK_REFRESH_DATES ? affiliate.previousCode.code : affiliate.newCode,
          ...(isUngrouped ? { startDate: activeDates.startDate, endDate: activeDates.endDate } : {}),
        }));
        manageOfferVariable = {
          affiliates,
          endDate: null,
          startDate: null,
          id: offer.id,
        };
      } else if (mode === OFFER_ACTION_TYPE.EDITPAYOUT) {
        manageOfferVariable = data.map((member) => ({
          id: member.affiliateOfferId,
          oldPayoutId: member.payoutId,
          newPayoutId: defaultPayoutId,
        }));
        manageOfferVariable = manageOfferVariable as UpdateAffiliatePayoutInput[];
      }
      break;
    case OFFER_SOURCE.TUNE:
      if (mode === OFFER_ACTION_TYPE.EDITPAYOUT) {
        manageOfferVariable = data.map((member) => ({
          id: member.affiliateOfferId,
          oldPayoutId: member.payoutId,
          newPayoutId: defaultPayoutId,
        }));
        manageOfferVariable = manageOfferVariable as UpdateAffiliatePayoutInput[];
      } else if (mode === OFFER_ACTION_TYPE.ACTIVATE || mode === OFFER_ACTION_TYPE.DEACTIVATE) {
        // Activate and deactivate link offer
        const ids = data.map((item) => item.affiliateOfferId);
        if (ids.length) {
          manageOfferVariable = {
            ids,
          };
        }
      } else if (mode === OFFER_ACTION_TYPE.ACTIVATE || mode === OFFER_ACTION_TYPE.DEACTIVATE) {
        const ids = data.map((item) => item.affiliateOfferId);
        if (ids.length) {
          manageOfferVariable = {
            ids,
          };
        }
      }
      break;
    default:
  }
  const [deeplinkData, setDeeplinkData] = useState(null);
  useEffect(() => {
    if (mode === OFFER_ACTION_TYPE.DEEPLINK) {
      if (isFormViewVisible) {
        const ids = selectedActiveMembers.map((item) => item.affiliateOfferId);

        let defaultDeeplink: UpdateAffiliateOfferDeeplinkBulkInput;
        const additionalDeeplinks: UpdateAdditionalDeeplinkBulk[] = [];
        if (
          has(manageDeeplinkForm, 'Primary Link')
          && !isEmpty(get(manageDeeplinkForm, 'Primary Link').url)
        ) {
          defaultDeeplink = {
            affiliateOfferLinkIds: ids,
            deepLinkUrl: get(manageDeeplinkForm, 'Primary Link').url,
          };
        }
        map(manageDeeplinkForm, (val, label) => {
          if (label !== 'Primary Link') {
            const affiliateDeeplinkIds = map(additionalDeeplinkGroupedMembers[label] || [], 'affiliateDeeplinkId');
            const newLabel = !isEmpty(val.label) ? val.label : null;
            const url = !isEmpty(val.url) ? val.url : null;
            additionalDeeplinks.push({
              affiliateDeeplinkIds,
              newLabel,
              label,
              url,
            });
          }
        });
        setDeeplinkData({
          data: {
            ...(defaultDeeplink ? { defaultDeeplink } : {}),
            ...(additionalDeeplinks.length ? { additionalDeeplinks } : {}),
          },
        });
      }
    }
  }, [mode, isFormViewVisible, manageDeeplinkForm, additionalDeeplinkGroupedMembers, selectedActiveMembers]);

  useEffect(() => {
    if (manageDeeplinkForm) {
      if (!manageDeeplinkForm['Primary Link'] && !isEmpty(keys(manageDeeplinkForm))) {
        setShowDeeplinkRemoveTooltip(false);
        setRemoveDisabled(false);
      } else {
        setRemoveDisabled(true);
        if (size(keys(manageDeeplinkForm)) > 1) {
          setShowDeeplinkRemoveTooltip(true);
        } else {
          setShowDeeplinkRemoveTooltip(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keys(manageDeeplinkForm || {}).length]);

  const handleRemoveDeeplinks = async () => {
    const resultToRemove = [];
    map(manageDeeplinkForm, (_, label) => {
      const affiliateDeeplinkIdsForLable = map(additionalDeeplinkGroupedMembers[label], 'affiliateDeeplinkId');
      resultToRemove.push(...affiliateDeeplinkIdsForLable);
    });
    await removeDeeplinks({
      variables: {
        affiliateDeeplinkIds: resultToRemove,
      },
      onCompleted() {
        onComplete();
      },
    });
  };
  const showSuccessNotification = () => {
    notification.success({
      message: `Offer updated successfully for ${selectedMembers.length} member${selectedMembers.length > 1 ? 's' : ''}.`,
    });
  };
  const showErrorNotification = (msg = 'An error occurred') => {
    notification.error({
      message: msg,
    });
  };
  const save = () => {
    let hasEmptyField = false;
    setIsLoading(true);
    if (isWorkFlow) {
      message.info('Offer update in progress. We will notify you within the platform once completed.');
    }
    // Check for empty fields in the data
    data.forEach((item) => {
      if (!item.newCode || item.newCode.trim() === '') {
        hasEmptyField = true;
      }
    });

    if (mode === OFFER_ACTION_TYPE.REFRESH && hasEmptyField) {
      setIsError(true); // Set isError state to true if there's an empty field
    } else {
      setIsError(false);
      onSave(deeplinkData || manageOfferVariable, [])
        .then(() => {
          setMode(null);
          setIsLoading(false);
          if (isWorkFlow) {
            showSuccessNotification();
          }
          makeEmptyStates();
          onClose();
        })
        .catch((err) => {
          // Handle error
          message.error(err.message);
          if (isWorkFlow) {
            showErrorNotification(err.message);
          }
          setMode(null);
          makeEmptyStates();
          onClose();
        });
    }
  };
  const onChange = (e: RadioChangeEvent) => {
    setIsError(false);
    setMode(e.target.value);
    setActiveOrDeactive(e.target.value);
    if (e.target.value === OFFER_ACTION_TYPE.ACTIVATE) {
      if (offerSource === OFFER_SOURCE.SHOPIFY) {
        const toActivateMembers = selectedOfferMember.filter((selectedMember) => selectedMember.status.toLowerCase() !== AFFILIATE_STATUS.SUCCESS);
        setToActivateMember(toActivateMembers);
        if (toActivateMembers.length <= 0) {
          setIsError(true);
        }
      } else {
        const toActivateMembers = selectedOfferMember.filter((selectedMember) => selectedMember.status.toLowerCase() !== AFFILIATE_STATUS.ACTIVE);
        setToActivateMember(toActivateMembers);
        if (toActivateMembers.length <= 0) {
          setIsError(true);
        }
      }
    }
    if (e.target.value === OFFER_ACTION_TYPE.DEACTIVATE) {
      if (offerSource === OFFER_SOURCE.SHOPIFY) {
        const toDeactivateMembers = selectedOfferMember.filter((selectedMember) => selectedMember.status.toLowerCase() === AFFILIATE_STATUS.SUCCESS);
        setToDeactivateMember(toDeactivateMembers);
        if (toDeactivateMembers.length <= 0) {
          setIsError(true);
        }
      } else {
        const toDeactivateMembers = selectedOfferMember.filter((selectedMember) => selectedMember.status.toLowerCase() === AFFILIATE_STATUS.ACTIVE);
        setToDeactivateMember(toDeactivateMembers);
        if (toDeactivateMembers.length <= 0) {
          setIsError(true);
        }
      }
    }
  };
  const columns: CustomColumn[] = [
    {
      title: 'Member',
      dataIndex: 'name',
      key: 'name',
      width: TABLE_WIDTH,
    },
    {
      title: 'Current status',
      dataIndex: 'status',
      key: 'status',
      width: TABLE_WIDTH,
    },
  ];
  if (offerSource === OFFER_SOURCE.SHOPIFY) {
    columns.push({
      title: 'Promo Code',
      dataIndex: 'code',
      key: 'code',
      width: TABLE_WIDTH,
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.code}>
          {record.code}
        </Tooltip>
      ),

    });
  } else if (offerSource === OFFER_SOURCE.TUNE) {
    columns.push({
      title: 'Affiliate Link',
      dataIndex: 'affiliateLink',
      key: 'affiliateLink',
      width: TABLE_WIDTH,
    });
  }
  const refreshColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Previous Code',
      dataIndex: 'previousCode',
      key: 'previousCode',
      ellipsis: {
        showTitle: false,
      },
      render: (_, record) => (
        <Tooltip placement="topLeft" title={record.previousCode.code}>
          {record.previousCode.code}
        </Tooltip>
      ),
    },
    {
      title: 'New Promo Code',
      dataIndex: 'newCode',
      key: 'newCode',
      render: (_, record) => (
        <>
          <div className={styles.customInputBox}>
            <Input
              style={{ width: TABLE_WIDTH }}
              value={record.newCode || ''}
              onChange={(e) => handleNewCodeChange(record.id, e)}
              placeholder="TYPE CODE HERE"
            />

            <div className={styles.tooltipError}>
              {record.isDuplicateError && (
                <Tooltip
                  arrowPointAtCenter
                  overlayStyle={{
                    maxWidth: 270,
                  }}
                  placement="topRight"
                  title="Code must be unique."
                >
                  <ExclamationCircleFilled />
                </Tooltip>
              )}
            </div>
          </div>
          {record.error && <span className={styles.customInputBoxError}>Please enter code</span>}
          {record.isDuplicateError && <span className={styles.customInputBoxError}>Promo code must be unique</span>}

        </>
      ),
      width: 200,
    },
  ];
  const handleClearInput = useCallback(() => {
    const updatedData = data.map((record) => ({ ...record, newCode: '' }));
    setData(updatedData); // Assuming setData is a function to update the state
  }, [data]);
  const handleNewCodeChange = (id, e) => {
    const { value } = e.target;
    const updatedData = data.map((item) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          newCode: cleanPromoCode(value),
          error: value.trim() === '', // Checking if the input is empty
        };
        return updatedItem;
      }
      return item;
    });

    const hasEmptyField = updatedData.some((item) => !item.newCode || item.newCode.trim() === '');
    const hasDuplicateNewCode = updatedData.some(
      (item) => item.newCode && updatedData.findIndex((elem) => elem.newCode === item.newCode && elem.id !== item.id) !== -1,
    );
    const newData = updatedData.map((item) => ({
      ...item,
      isDuplicateError: item.newCode && updatedData.filter((elem) => elem.newCode === item.newCode).length > 1,
    }));

    setData(newData);
    setIsDuplicateError(hasDuplicateNewCode); // Set isDuplicateError state based on duplicate newCode
    setIsError(hasEmptyField); // Set isError state based on empty fields or duplicate newCode
  };
  const editPayoutData = data.map((member, memberIndex) => {
    const matchingAffiliate = offerSource === OFFER_SOURCE.SHOPIFY
      ? offer.promos[0].affiliates.find((affiliate) => affiliate.id === member.affiliateOfferId)
      : offerSource === OFFER_SOURCE.TUNE
        ? offer.links[0].affiliates.find((affiliate) => affiliate.affiliateId === member.affiliateId)
        : null;
    const affiliateLink = offerSource === OFFER_SOURCE.TUNE ? offer.links[0].affiliateStats.find((affiliate) => affiliate.affiliateId === member.affiliateId) : {};

    let affiliateStatus: string = '';
    if (offerSource === OFFER_SOURCE.SHOPIFY) {
      const matchingAffiliateStats = offer.promos[0].affiliates.find(
        (affiliate) => affiliate.id === member.affiliateOfferId,
      ) as GetOfferById_offer_promos_affiliates | undefined;
      if (matchingAffiliateStats) {
        affiliateStatus = matchingAffiliateStats.status.toLowerCase() === 'success' ? 'Active' : 'Inactive';
      }
    } else {
      const matchingAffiliateStats = offer.links[0].affiliateStats.find((affiliate) => affiliate.affiliateId === member.affiliateId);
      affiliateStatus = matchingAffiliateStats.linkStatus === 'Active' ? 'Active' : 'Inactive';
    }
    return {
      key: memberIndex,
      name: member.name,
      code: offerSource === OFFER_SOURCE.SHOPIFY && 'externalDiscountCode' in matchingAffiliate
        ? (matchingAffiliate as GetOfferById_offer_promos_affiliates).externalDiscountCode
        : '',
      payoutId: matchingAffiliate?.offerPayoutId,
      status: affiliateStatus,
      affiliateLink: offerSource === OFFER_SOURCE.TUNE && 'affiliateShortLink' in affiliateLink
        ? (affiliateLink as { affiliateShortLink?: string }).affiliateShortLink || ''
        : '',

    };
  });
  const activateDeactivatePayoutData = memberToOperate.map((member, memberIndex) => {
    const matchingAffiliate = offerSource === OFFER_SOURCE.SHOPIFY
      ? offer.promos[0].affiliates.find((affiliate) => affiliate.id === member.affiliateOfferId)
      : offerSource === OFFER_SOURCE.TUNE
        ? offer.links[0].affiliates.find((affiliate) => affiliate.affiliateId === member.affiliateOfferId)
        : null;
    const affiliateLink = offerSource === OFFER_SOURCE.TUNE ? offer.links[0].affiliateStats.find((affiliate) => affiliate.affiliateId === member.affiliateId) : {};

    let affiliateStatus: string = '';
    if (offerSource === OFFER_SOURCE.SHOPIFY) {
      const matchingAffiliateStats = offer.promos[0].affiliates.find(
        (affiliate) => affiliate.id === member.affiliateOfferId,
      ) as GetOfferById_offer_promos_affiliates | undefined;
      if (matchingAffiliateStats) {
        affiliateStatus = matchingAffiliateStats.status.toLowerCase() === 'success' ? 'Active' : 'Inactive';
      }
    } else {
      const matchingAffiliateStats = offer.links[0].affiliateStats.find((affiliate) => affiliate.affiliateId === member.affiliateId);
      affiliateStatus = matchingAffiliateStats.linkStatus === 'Active' ? 'Active' : 'Inactive';
    }
    return {
      key: memberIndex,
      name: member.name,
      code: offerSource === OFFER_SOURCE.SHOPIFY && 'externalDiscountCode' in matchingAffiliate
        ? (matchingAffiliate as GetOfferById_offer_promos_affiliates).externalDiscountCode
        : '',
      payoutId: matchingAffiliate?.offerPayoutId,
      status: affiliateStatus,
      affiliateLink: offerSource === OFFER_SOURCE.TUNE && 'affiliateShortLink' in affiliateLink
        ? (affiliateLink as { affiliateShortLink?: string }).affiliateShortLink || ''
        : '',

    };
  });
  const handleConfirm = () => {
    save();
  };
  const handleActiveDatesChange = (fieldName: keyof ActiveDate, value: Date | boolean) => {
    setActiveDates((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const payoutOptions: { id: number; label: string }[] = useMemo(() => offer.payouts.map((payout) => {
    if (!isNull(payout.flatPayout) && !isNull(payout.percentPayout)) {
      return { id: payout.id, label: `${payout.label} (${payout.percentPayout}% + $${payout.flatPayout})` };
    } else if (!isNull(payout.flatPayout)) {
      return { id: payout.id, label: `${payout.label} ($${payout.flatPayout})` };
    } else if (!isNull(payout.percentPayout)) {
      return { id: payout.id, label: `${payout.label} (${payout.percentPayout}%)` };
    }
    // Add a default return value in case none of the conditions are met
    return { id: payout.id, label: 'Default Label' }; // Adjust the default label as needed
  }), [offer.payouts]);

  const handleClose = () => {
    makeEmptyStates();
    setMode(null);
    onClose();
  };

  const isMigrationRequired = useMemo(() => {
    if (migrateToGraphQL && offerSource === OFFER_SOURCE.SHOPIFY && (!offer.isNewFlow || !offer.promos[0].defaultPayoutId)) {
      return true;
    }
    if (migrateToGraphQL && offerSource === OFFER_SOURCE.TUNE && !offer.links[0].defaultPayoutId) {
      return true;
    }
    return false;
  }, [migrateToGraphQL, offerSource, offer]);

  const disableUpdateButtonRefreshDates = useMemo(() => {
    if (mode === OFFER_ACTION_TYPE.UPDATE_BULK_REFRESH_DATES || mode === OFFER_ACTION_TYPE.UPDATE_BULK_ACTIVE_DATES) {
      return !activeDates.startDate || (activeDates.showEndDate && !activeDates.endDate);
    }
    return false;
  }, [mode, activeDates]);

  return (
    <Drawer
      title={(
        <Row justify="center" align="middle">
          <div className={styles.header}>
            <Button onClick={goBack} icon={<ArrowLeftIcon width={14} height={14} style={{ marginRight: 0 }} />} />
          </div>
          <div>
            <Title level={4}>Edit Offer</Title>
          </div>
        </Row>
      )}
      placement="right"
      onClose={onClose}
      open={visible}
      width={600}
      headerStyle={{ textAlign: 'center' }}
      closable={false}
      className={styles.ManageOfferDrawer}
      maskClosable={false}
      footer={
        mode !== null && (
          <div>
            <Row gutter={24}>
              <Col span={mode === OFFER_ACTION_TYPE.DEEPLINK && !isFormViewVisible ? 8 : 12}>
                <Button block onClick={goBack}>
                  Cancel
                </Button>
              </Col>
              {mode === OFFER_ACTION_TYPE.ACTIVATE || mode === OFFER_ACTION_TYPE.DEACTIVATE ? (
                <Col span={12}>
                  <Popconfirm
                    title={`Are you sure you want to ${
                      mode === OFFER_ACTION_TYPE.ACTIVATE ? OFFER_ACTION_TYPE.ACTIVATE : OFFER_ACTION_TYPE.DEACTIVATE
                    } ${offerSource === OFFER_SOURCE.SHOPIFY ? 'promo code' : 'link'} for ${memberToOperate.length} ${
                      memberToOperate.length === 1 ? 'member' : 'members'
                    }`}
                    cancelText="Cancel"
                    okText="Confirm"
                    onConfirm={handleConfirm}
                    disabled={isError}
                  >
                    <Tooltip title={isError ? 'Please select Activate/Deactivate' : ''}>
                      <Button block type="primary" disabled={isError} loading={isLoading}>
                        Update Offer
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Col>
              ) : mode === OFFER_ACTION_TYPE.REFRESH && isUngrouped ? (
                <Col span={12}>
                  <Button
                    block
                    onClick={() => setMode(OFFER_ACTION_TYPE.UPDATE_BULK_ACTIVE_DATES)}
                    type="primary"
                    disabled={isError || isDuplicateError || selectedMembers.length == 0}
                  >
                    Set Active Dates
                  </Button>
                </Col>
              ) : mode === OFFER_ACTION_TYPE.DEEPLINK ? (
                isFormViewVisible ? (
                  <Col span={12}>
                    <Popconfirm
                      title={`Are you sure you want to update Primary Link for ${
                        selectedOfferMember.filter(
                          (selectedMember) => selectedMember.status.toLowerCase() === AFFILIATE_STATUS.ACTIVE,
                        ).length
                      } ${
                        selectedOfferMember.filter(
                          (selectedMember) => selectedMember.status.toLowerCase() === AFFILIATE_STATUS.ACTIVE,
                        ).length === 1
                          ? 'member'
                          : 'members'
                      }`}
                      cancelText="Cancel"
                      okText="Confirm"
                      onConfirm={handleConfirm}
                      disabled={linkValidation}
                    >
                      <Tooltip title={linkValidation && 'Either URL or Label is empty.'}>
                        <Button
                          block
                          type="primary"
                          disabled={linkValidation || isError || isDuplicateError || selectedActiveMembers.length === 0}
                          loading={isLoading}
                        >
                          Update Link
                          {' '}
                          <ChevronRightIcon />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                ) : (
                  <>
                    <Col span={8}>
                      <Popconfirm
                        title="Are you sure you want to remove selected deeplinks?"
                        cancelText="No"
                        okText="Confirm"
                        onConfirm={handleRemoveDeeplinks}
                        disabled={removeDisabled}
                      >
                        {!showDeeplinkRemoveTooltip ? (
                          <Button disabled={removeDisabled} block>
                            Remove
                          </Button>
                        ) : (
                          <Tooltip title="You cannot remove Primary Link. Please uncheck Primary Link to continue.">
                            <Button disabled={removeDisabled} block>
                              Remove
                            </Button>
                          </Tooltip>
                        )}
                      </Popconfirm>
                    </Col>
                    <Col span={8}>
                      <Button
                        block
                        type="primary"
                        onClick={() => setFormViewVisible(true)}
                        disabled={
                          isError
                          || isDuplicateError
                          || selectedActiveMembers.length === 0
                          || selectedLabels.length === 0
                        }
                      >
                        Update Link
                        {' '}
                        <ChevronRightIcon />
                      </Button>
                    </Col>
                  </>
                )
              ) : (
                <Col span={12}>
                  <Tooltip
                    title={
                      isError
                        ? 'Please enter the code for all the members'
                        : isDuplicateError
                        ? 'Please enter unique Promo code for all the members'
                        : ''
                    }
                  >
                    <Button
                      block
                      onClick={save}
                      type="primary"
                      disabled={
                        isError || isDuplicateError || selectedMembers.length == 0 || disableUpdateButtonRefreshDates
                      }
                      loading={isLoading}
                    >
                      Update Offer
                    </Button>
                  </Tooltip>
                </Col>
              )}
            </Row>
          </div>
        )
      }
    >
      <button className="ant-modal-close" onClick={handleClose}>
        <span className="ant-modal-close-x">x</span>
      </button>
      <Row align="middle" justify="space-between" className={styles.offerDetails}>
        <OfferDetailCard
          showCta={false}
          migrateToGraphQL={migrateToGraphQL}
          isWorkFlow={isWorkFlow}
          offerData={offer}
          infoButtonClassName
        />
      </Row>

      {mode === null && (
        <div className={styles.manageOfferwrapper}>
          <Title level={5} className={styles.mb0}>
            Select the action to want to perform
          </Title>
          <Text>Choose what you want to edit for the selected set of members in this offer. </Text>
          {isWorkFlow && selectedMembers.length < selectedMemberIds.length && (
            <Alert
              type="warning"
              message={
                selectedMembers.length
                  ? `From ${selectedMemberIds.length} member${selectedMemberIds.length > 1 ? 's' : ''}, only ${
                      selectedMembers.length
                    } member${
                      selectedMembers.length > 1 ? 's are' : ' is'
                    } associated with the connected offer. The remaining ${netMembers} member${
                      netMembers > 1 ? 's are' : ' is'
                    } not linked to this offer, and will therefore be excluded from the editing process.`
                  : `No  members are part of this connected offer, Please generate ${
                      offer && offer.promos.length ? 'promo code' : 'sales link'
                    } to continue.`
              }
            />
          )}
          <div className={styles.manageOfferButtons}>
            <ManageOfferItem
              title="Edit Payout Value"
              description="Modify payout value for selected members in bulk"
              icon={<EnvelopeOpenDollarIcon />}
              onClick={() => handleSteps(OFFER_ACTION_TYPE.EDITPAYOUT)}
              disabled={isNull(defaultPayout)}
              errorMessage={
                isNull(defaultPayout)
                && 'This offer currently does not support multiple payouts. To enable editing, please upgrade the offer to support the multiple payouts feature.'
              }
            />
            {offerSource === OFFER_SOURCE.SHOPIFY && (
              <ManageOfferItem
                title="Refresh Promo Codes"
                description="Edit promo code names for all selected members"
                icon={<TagIcon />}
                onClick={() => handleSteps(OFFER_ACTION_TYPE.REFRESH)}
              />
            )}
            <ManageOfferItem
              title={offerSource === OFFER_SOURCE.SHOPIFY ? 'Activate/Deactivate Codes' : 'Activate/Deactivate Links'}
              description={
                offerSource === OFFER_SOURCE.SHOPIFY
                  ? 'Activate or deactivate promo codes for all selected members'
                  : 'Activate or deactivate Links for all selected members'
              }
              icon={<PlayPauseIcon />}
              onClick={() => handleSteps(OFFER_ACTION_TYPE.DEACTIVATE)}
            />
            {offerSource === OFFER_SOURCE.SHOPIFY && isUngrouped && (
              <ManageOfferItem
                title="Change Active Dates"
                description="Change active dates for offer with customized dates for each code"
                icon={<CalendarIcon />}
                onClick={() => handleSteps(OFFER_ACTION_TYPE.UPDATE_BULK_REFRESH_DATES)}
              />
            )}
            {manageDeepLinksAllowed && offerSource === OFFER_SOURCE.TUNE && (
              <ManageOfferItem
                title="Manage Deep Links"
                description="Manage deep links for selected members"
                icon={<LinkIcon />}
                onClick={() => handleSteps(OFFER_ACTION_TYPE.DEEPLINK)}
              />
            )}
          </div>
        </div>
      )}

      {/* Edit multiple payout */}

      {mode === OFFER_ACTION_TYPE.EDITPAYOUT && (
        <ManageOfferPayout
          defaultPayoutId={defaultPayoutId}
          payoutOptions={payoutOptions}
          setDefaultPayoutId={setDefaultPayoutId}
          editPayoutData={editPayoutData}
          selectedMembers={selectedMembers}
          offer={offer}
          offerSource={offerSource}
          columns={columns}
          data={data}
        />
      )}
      {mode === OFFER_ACTION_TYPE.REFRESH && (
        <RefreshPromoCodes offer={offer} data={data} refreshColumn={refreshColumn} isWorkFlow={isWorkFlow} />
      )}
      {(mode === OFFER_ACTION_TYPE.DEACTIVATE || mode === OFFER_ACTION_TYPE.ACTIVATE) && (
        <ManageOfferActivation
          offerSource={offerSource}
          isMigrationRequired={isMigrationRequired}
          isWorkFlow={isWorkFlow}
          offer={offer}
          onChange={onChange}
          activeOrDeactive={activeOrDeactive}
          memberToOperate={memberToOperate}
          selectedMembers={selectedMembers}
          activateDeactivatePayoutData={activateDeactivatePayoutData}
          columns={columns}
        />
      )}
      {(mode === OFFER_ACTION_TYPE.UPDATE_BULK_ACTIVE_DATES
        || mode === OFFER_ACTION_TYPE.UPDATE_BULK_REFRESH_DATES) && (
        <OfferBulkDateAction data={data} activeDates={activeDates} handleActiveDatesChange={handleActiveDatesChange} />
      )}
      {mode === OFFER_ACTION_TYPE.DEEPLINK && (
        <ManageDeepLink
          selectedActiveMembers={selectedActiveMembers}
          setDefaultDeeplinkUrl={setDefaultDeeplinkUrl}
          isFormViewVisible={isFormViewVisible}
          setFormViewVisible={setFormViewVisible}
          additionalDeeplinkGroupedMembers={additionalDeeplinkGroupedMembers}
          formData={manageDeeplinkForm}
          setFormData={setManageDeeplinkForm}
          setLinkValidation={setLinkValidation}
          selectedLabels={selectedLabels}
          setSelectedLabels={setSelectedLabels}
        />
      )}
    </Drawer>
  );
});

//
