import * as React from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { Button } from '@components';
import { StepIndicator } from '@components';
import { useElementResize } from '@frontend/utils';

import { IStepConfig } from './introModalModel';

import styles from './IntroModalStep.scss';

const { useState, useEffect, useRef } = React;

interface IProps {
  config: IStepConfig;
  step?: number;
  numberOfSteps?: number;
  minHeight?: number;
  minBannerHeight?: number;
  animated?: boolean;
  classNames?: string[];
  onResize?(height: number, bannerHeight: number);
  onClickNext();
  onClickBack?();
}

export const IntroModalStep: React.FunctionComponent<IProps> = (props) => {
  const {
    animated,
    classNames,
    config,
    minBannerHeight,
    minHeight,
    numberOfSteps,
    onClickBack,
    onClickNext,
    onResize,
    step,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const bannerRef = useRef<HTMLDivElement>();

  const [nextStep, setNextStep] = useState(null);
  const [currentStep, setCurrentStep] = useState(step);
  const [currentConfig, setCurrentConfig] = useState(config);

  const domRect = useElementResize(ref);

  useEffect(() => {
    if (onResize) {
      console.log(domRect); // Hack dependency for domRect
      onResize(ref.current.offsetHeight, bannerRef.current.offsetHeight);
    }
  }, [onResize, domRect]);

  const handleClickNext = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClickNext();
  };

  const handleClickBack = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClickBack();
  };

  useEffect(() => {
    if (animated && step !== currentStep) {
      setNextStep(step);
    }
  }, [animated, step, currentStep, numberOfSteps]);

  const handleExited = () => {
    if (nextStep !== null) {
      setCurrentConfig(config);
      setCurrentStep(nextStep);
      setNextStep(null);
    }
  };

  const getBanner = (config: IStepConfig) => (
    <div
      className={cx(styles.bannerWrapper, {
        forward: nextStep > currentStep,
        backward: nextStep < currentStep,
      })}
      style={{ minHeight: minBannerHeight || 0 }}
    >
      <div ref={bannerRef} className={styles.banner}>
        {config.banner || <img src={config.bannerSrc} className={styles.bannerImg} />}
      </div>
    </div>
  );

  const getTextContent = (config: IStepConfig) => (
    <div className={styles.textContent}>
      <div className={styles.title}>{config.title}</div>
      <div className={styles.subtitle}>{config.subtitle}</div>
    </div>
  );

  const getAnimatedComponent = (current: JSX.Element, next: JSX.Element) => (
    <>
      <CSSTransition
        in={step === nextStep}
        enter
        exit={false}
        timeout={500}
        classNames="step"
        unmountOnExit
      >
        {next}
      </CSSTransition>

      <CSSTransition
        in={step === currentStep}
        enter={false}
        exit
        timeout={500}
        classNames="step"
        onExited={handleExited}
        unmountOnExit
      >
        {current}
      </CSSTransition>
    </>
  );

  const getAnimatedBanner = () => getAnimatedComponent(getBanner(currentConfig), getBanner(config));

  return (
    <div
      ref={onResize ? ref : null}
      className={cx(styles.IntroModalStep, classNames)}
      style={{ minHeight: minHeight || 0 }}
    >
      {animated ? getAnimatedBanner() : getBanner(config)}

      <StepIndicator numberOfSteps={numberOfSteps} step={step} />

      {getTextContent(config)}

      <div className={styles.buttonWrapper}>
        {onClickBack && step > 0 ? (
          <Button
            label={config.backButtonLabel || 'Back'}
            onClick={handleClickBack}
            theme="info"
          />
        ) : null}
        <Button
          label={
            config.nextButtonLabel
            || (step === numberOfSteps - 1 ? 'Finish' : 'Next')
          }
          onClick={handleClickNext}
        />
      </div>
    </div>
  );
};

IntroModalStep.defaultProps = {
  step: 0,
  numberOfSteps: 3,
  animated: false,
};
IntroModalStep.displayName = 'IntroModalStep';
