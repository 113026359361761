import { InternalContentReviewStatus } from '@frontend/app/types/globalTypes';

export const ContentReviewFilters: ({
    label: string;
    filter: string;
}[]) = [
        {
            label: 'First review',
            filter: InternalContentReviewStatus.SUBMITTED,
        },
        {
            label: 'Final review',
            filter: InternalContentReviewStatus.SOFT_APPROVED,
        },
    ];
