import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import {
  UPDATE_IGDM_AND_SOCIAL_ACCOUNT_CLIENT_SETTINGS_MUTATION,
} from '@frontend/app/queries';

import {
  UpdateIGDMAndSocialAccountClientSettings,
  UpdateIGDMAndSocialAccountClientSettingsVariables,
} from '@frontend/app/queries/types/UpdateIGDMAndSocialAccountClientSettings';

type IOptions = MutationHookOptions<UpdateIGDMAndSocialAccountClientSettings, UpdateIGDMAndSocialAccountClientSettingsVariables>;

export const useUpdateSocialAccountClientSettings = (options: IOptions = {}) => (
  useMutation<UpdateIGDMAndSocialAccountClientSettings>(UPDATE_IGDM_AND_SOCIAL_ACCOUNT_CLIENT_SETTINGS_MUTATION, options)
);

export type TUseUpdateSocialAccountClientSettingsMutation = ReturnType<typeof useUpdateSocialAccountClientSettings>;
