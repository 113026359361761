import * as React from 'react';
import { isEmpty, keyBy, map } from 'lodash';
import { Tag } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useScrollableStatus } from '@frontend/app/hooks/common';
import { IconButton, CloseIcon } from '@components';

interface IProps {
  selectedTagIds: string[];
  closeTag(id: string): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tags: any;
  invertSelection: boolean; // if this flag is true selectedTagIds repesents unselected tags
}

import { AppIcon } from '@frontend/applications/Shared/components/AppIcon';

import styles from './TagCarousel.scss';

const {
 useState, useEffect, createRef, useMemo,
} = React;
/**
 * @type {React.FC}
 */
export const TagCarousel: React.FC<IProps> = React.memo((props) => {
  const {
 selectedTagIds, tags, closeTag, invertSelection = false,
} = props;
  const [offset, setOffset] = useState(0);
  const tagAreaRef = createRef<HTMLDivElement>();

  const { canScrollRight } = useScrollableStatus(tagAreaRef);

  const scroll = (distance) => {
    if (distance < 0) {
      setOffset(Math.max(offset - 1, 0));
    } else if (canScrollRight) {
      setOffset(Math.min(offset + 1, selectedTagIds.length));
    }
  };
  const tagsById = keyBy(tags, 'id');

  const invertedSelection = () => {
    const copyOfTagsById = Object.assign({}, tagsById);
    for (const id of selectedTagIds) {
      delete copyOfTagsById[id];
    }
    return Object.keys(copyOfTagsById);
  };

  const sortedTagIds = useMemo(() => {
    const tags = invertSelection ? selectedTagIds : invertedSelection();

    return [...tags].sort((a, b) => tagsById[a].name.localeCompare(tagsById[b].name));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTagIds]);

  useEffect(() => {
    const node = tagAreaRef && tagAreaRef.current;
    if (!node) {
      return;
    }
    if (isEmpty(node.children) && offset) {
      scroll(-1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTagIds]);
  return (
    <div className={styles.tagContainer}>
      {offset > 0 && (
        <IconButton
          className={styles.leftButton}
          onClick={() => scroll(-1)}
          icon={<LeftOutlined size={2} />}
        />
      )}
      <div className={styles.tagArea} ref={tagAreaRef}>
        {sortedTagIds && map(sortedTagIds.slice(offset), (id) => (
          <Tag
            className={styles.tag}
            key={id}
            closeIcon={<CloseIcon className={styles.closeIcon} size={16} />}
            closable
            onClose={() => {
              if (!canScrollRight) {
                scroll(-1);
              }
              closeTag(id);
            }}
          >
            <AppIcon iconUrl={tagsById[id].icon} />
            <span className={styles.appName}>{tagsById[id].name}</span>
          </Tag>
        ))}
      </div>

      {canScrollRight
      && (
      <IconButton
        className={styles.rightButton}
        onClick={() => scroll(1)}
        icon={<RightOutlined size={2} />}
      />
)}
    </div>
  );
});
