import { IItem } from '@frontend/app/components/CustomMenu/types';
import { pfaV2Routes } from '../Settings/ProductFulfillment/routes';

export const pfaV2SettingsConfig = (enabled: boolean): IItem[] =>
  (enabled
    ? [
        {
          id: 'product_fulfillment',
          text: 'Product Fulfillment',
          subItems: [
            {
              id: 'products',
              text: 'Products',
              route: pfaV2Routes.settings.imports,
            },
            {
              id: 'brandCatalogs',
              text: 'Brand Catalog',
              route: pfaV2Routes.settings.brandCatalogs,
            },
          ],
        },
      ]
    : []);
