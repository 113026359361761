import * as React from 'react';
import { filter, keys } from 'lodash';

import { Button } from '@frontend/shadcn/components/ui/button';
import { IEnabledOfferSources } from '@affiliates/types';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';

import { H4 } from '@frontend/shadcn/components/typography/h4';
import { P } from '@frontend/shadcn/components/typography/p';
import { ArrowUpRightFromSquareIcon, ChevronDownIcon } from '@revfluence/fresh-icons/regular';
import { LinkSimpleIcon } from '@revfluence/fresh-icons/regular';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { TOnClickAddOffer } from './types';
import { OFFER_LEARN_MORE_URL } from '../../utils/constant';

interface IProps {
  onClickCreate: TOnClickAddOffer;
  sources: IEnabledOfferSources;
}

const AddOfferButtonOrder: OFFER_SOURCE[] = [OFFER_SOURCE.SHOPIFY, OFFER_SOURCE.TUNE];
const AddOfferButtonLabels = {
  [OFFER_SOURCE.TUNE]: 'Create Offer with Link Tracking',
  [OFFER_SOURCE.SHOPIFY]: 'Create Offer with Promo Codes',
};

export const RefreshGetStarted: React.FC<Readonly<IProps>> = ({ onClickCreate, sources }) => {
  const enabledSources: OFFER_SOURCE[] = filter(keys(sources), (k: OFFER_SOURCE): k is OFFER_SOURCE => !!sources[k]);

  const renderButtons = (): React.ReactNode => (
    <>
      <Button onClick={() => window.open(OFFER_LEARN_MORE_URL, '_blank')} variant="outline">
        <ArrowUpRightFromSquareIcon className="w-4 h-4 mr-2 font-semibold" />
        Learn More
      </Button>
      {enabledSources.length === 1 && (
        <Button onClick={onClickCreate[enabledSources[0]]} variant="primary">
          Create Offer
        </Button>
      )}
      {enabledSources.length > 1 && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="primary">
              Create Offer
              <ChevronDownIcon className="w-4 h-4 ml-2" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {AddOfferButtonOrder.map((source) => {
              const label = AddOfferButtonLabels[source];

              if (!sources[source]) {
                return null;
              }

              if (!label) {
                return null;
              }
              return (
                <DropdownMenuItem key={source} onClick={onClickCreate[source]} className="cursor-pointer">
                  {label}
                </DropdownMenuItem>
              );
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );

  return (
    <div className="flex flex-col items-center justify-center pt-20 gap-3">
      <div className="max-w-lg text-center">
        <LinkSimpleIcon className="w-20 h-20 mr-2 font-semibold text-muted" />
        <H4>Create an Offer</H4>
        <P className="pb-3 text-grey-5 whitespace-nowrap">
          Generate unique affiliate links and codes to track clicks, conversions, and sales.
        </P>
      </div>
      <div className="flex gap-6">{renderButtons()}</div>
    </div>
  );
};
