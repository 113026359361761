import { useMutation as useOldMutation, MutationHookOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';

export const useMutation = <T, U>(query: DocumentNode, options: MutationHookOptions<T, U> = {}) => {
  const mutation = useOldMutation<T, U>(query, options);

  const method = mutation[0];

  // Wrap original method to include updating cache.
  const mutationWrapper: typeof method = (...args) => {
    const [thisOptions = {}] = args;

    args[0] = {
      ...thisOptions,
      update: (...updateArgs) => {
        if (thisOptions.update) {
          thisOptions.update(...updateArgs);
        }

        if (options.update) {
          options.update(...updateArgs);
        }
      },
    };

    return method(...args);
  };

  mutation[0] = mutationWrapper;

  return mutation;
};
