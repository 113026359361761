import gql from 'graphql-tag';
import { TERMS_TEMPLATE_FRAGMENT } from './fragments/TermsTemplateFragment';

export const UPDATE_BASIC_TERMS_TEMPLATE_MUTATION = gql`
  mutation UpdateBasicTermsTemplateMutation($id: Int!, $data: UpdateBasicTermsTemplateInput!) {
    terms: updateBasicTermsTemplateSettings(id: $id, terms: $data) {
      ...TermsTemplateFragment
    }
  }
  ${TERMS_TEMPLATE_FRAGMENT}
`;
