/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Badge_wxctd_32 {
  display: inline;
  border-radius: 100px;
  padding: 2px 10px;
  text-align: center;
  font-weight: 700;
}
._Badge_wxctd_32._primary_wxctd_39 {
  color: #fdfdfd;
  background-color: #3996e0;
  border: none;
}
._Badge_wxctd_32._light_wxctd_44 {
  color: #1a1818;
  background-color: #fdfdfd;
  border: solid 1px #ececec;
}
._Badge_wxctd_32._info_wxctd_49 {
  color: #3996e0;
  background-color: #eff5f9;
  border: none;
}
._Badge_wxctd_32._danger_wxctd_54 {
  color: #fdfdfd;
  background-color: #d9534f;
  border: none;
}