import * as React from 'react';
import {
  Empty,
  Typography,
} from '@revfluence/fresh';
import { PhotoFilmIcon } from '@revfluence/fresh-icons/regular/esm';

const GuidelinesListEmpty = React.memo(() => (
  <Empty
    style={{
      alignSelf: 'center',
    }}
    image={(
      <PhotoFilmIcon
        color="#F0F0F0"
        width={80}
        height={80}
      />
    )}
    imageStyle={{
      height: '48px',
      marginBottom: '52px',
    }}
    description={(
      <>
        <Typography.Title level={5}>
          No content guidelines for this project
        </Typography.Title>
        <Typography.Paragraph
          type="secondary"
          style={{
            fontSize: '14px',
          }}
        >
          Select from one of our suggested templates, use templates from another project, or start from scratch.
        </Typography.Paragraph>
      </>
    )}
  />

));

GuidelinesListEmpty.displayName = 'GuidelinesListEmpty';

export default GuidelinesListEmpty;
