const snakeToCamel = (str) =>
  str
    .replace(/([-_][A-Z])/g, (group) =>
      group
        .toLowerCase()
        .replace('-', '')
        .replace('_', ''))
    .split('')
    .map((x) => (/[A-z]/.test(x) ? String.fromCharCode(x.charCodeAt(0) ^ 32) : x))
    .join('');

export const monetaryReducer = (filterName, stateValue) => (state, action) => {
  const localState = state[stateValue];
  switch (action.type) {
    case `CHOOSE_${filterName}_FILTER_COMPARISON`: {
      return { ...localState, option: action.payload };
    }
    case 'REMOVE_ADDITIONAL_FILTER': {
      if (action.payload === snakeToCamel(filterName)) {
        return {
          option: { label: '', index: null },
          amountOne: 0,
          amountTwo: 0,
        };
      }
      return localState;
    }
    case `SET_${filterName}_FILTER_AMOUNT_ONE`: {
      return { ...localState, amountOne: action.payload };
    }
    case `SET_${filterName}_FILTER_AMOUNT_TWO`: {
      return { ...localState, amountTwo: action.payload };
    }
    default: {
      return localState;
    }
  }
};
