import { TState } from '../types';

interface IAction {
    field: string;
    type: 'BULK ACTIVE DATE';
    value: Date;
}
export type TUpdateBulkActiveDateAction = Readonly<IAction>;

export const updateBulkActiveDate = (state: TState, action: TUpdateBulkActiveDateAction): TState => {
    const members = state.members.map((m) => {
        if (m?.selected) {
            if (action.field === 'bulkStartDate') {
                return {
                    ...m,
                    startDate: action.value,
                };
            }
            if (action.field === 'bulkEndDate') {
                return {
                    ...m,
                    endDate: action.value,
                };
            }
        }
        return {
            ...m,
        };
    });
    return {
        ...state,
        members,
        [action.field]: action.value,
    };
};
