import * as React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';

import { IActivation, useActivationsQuery } from '@frontend/app/hooks';

import styles from './ActivationTag.scss';

const { Text } = Typography;

interface IProps {
  activation: IActivation;
  boldFont?: boolean;
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ActivationTag: React.FC<IProps> = React.memo((props) => {
  const { activation, boldFont } = props;
  const { activationColorMap } = useActivationsQuery();

  return (
    <Text
      ellipsis
      className={cx(styles.ActivationTag, props.className)}
      style={{
        color: activationColorMap.get(activation.name)?.color || undefined,
        backgroundColor: activationColorMap.get(activation.name)?.backgroundColor || undefined,
        fontWeight: boldFont ? 600 : 400,
      }}
    >
      {activation.name}
    </Text>
  );
});

ActivationTag.displayName = 'ActivationTag';
