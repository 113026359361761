import { DateOperator } from '../../../../types/ContentDateRequirements';
import { IState } from '../../../../types/state';
import { IToggleReviewBeforeSubmissionAction } from './index';

export const toggleReviewBeforeSubmission = (state: IState, action: IToggleReviewBeforeSubmissionAction): IState => {
  const {
    contentGuidelineInstanceId,
    requiresReviewBeforeSubmission,
  } = action;
  const updatedContentGuidelines = state.contentGuidelines.map((contentGuideline) => {
    if (contentGuideline.contentGuidelineInstanceId === contentGuidelineInstanceId) {
      return {
        ...contentGuideline,
        requiresReviewBeforeSubmission,
        dueDates: {
          ...contentGuideline.dueDates,
          submissionDate: requiresReviewBeforeSubmission
          ? new Array(contentGuideline.dueDates.completeDate.length).fill({
            beforeDate: null,
            operator: DateOperator.BEFORE,
          })
          : [],
        },
      };
    }
    return contentGuideline;
  });
  return {
    ...state,
    contentGuidelines: updatedContentGuidelines,
  };
};
