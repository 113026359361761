/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1lx4h_157 {
  display: flex;
}

._justify-content-space-between_1lx4h_161 {
  justify-content: space-between;
}

._tabular-nums_1lx4h_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1lx4h_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Table_1lx4h_209 .ant-table-thead .ant-table-cell {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: var(--font-family);
  text-transform: capitalize;
  color: #8f8d91;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
._Table_1lx4h_209 .ant-table-cell {
  background: #fdfdfd;
}
._Table_1lx4h_209 .ant-table-tbody > tr > td {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
}
._Table_1lx4h_209 .ant-table-tbody .ant-radio-inner {
  width: 1.125rem;
  height: 1.125rem;
}