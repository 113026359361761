import * as React from 'react';
import { IButtonProps, Tooltip } from '@revfluence/fresh';
import { ChartColumnIcon, CircleDollarIcon, CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';

import {
  IBasicStat,
  IComplexStat,
  ILink,
  StatisticsCard,
} from '@frontend/app/components/StatisticsCard';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import { Empty } from '@frontend/app/refresh-components';
import statisticsCardStyles from '@frontend/app/components/StatisticsCard/StatisticsCard.scss';
import { cx } from 'class-variance-authority';
import { isEmpty } from 'lodash';
import { IConversionStats } from '../types';
import { SetupSalesTracking } from './SetupSalesTracking';
import { ILabelMap, useGetBasicStatsFromLabelMap, useGetComplexStatsFromLabelMap } from './useGetStatsFromLabelMap';

interface IProps {
  affiliates?: IConversionStats['affiliates'];
  conversions?: IConversionStats['conversions'];
  isSalesTrackingSetup: boolean;
  links: ILink[];
  onClickDismissSetup: IButtonProps['onClick'];
  sales?: IConversionStats['sales'];
  subtitle: string;
}

// @TODO for p1 this will need to be calculated instead of hardcoded
// eslint-disable-next-line max-len
const CONVERSIONS_TOOLTIP = 'This section consists of data from conversions attributed to Sales Tracking Links and Shopify Promo Codes generated via Aspire.';

const EMPTY_LIST = [];

const CARD_TITLE = 'Conversions';

export const ConversionsCard: React.FC<IProps> = React.memo((props) => {
  const {
    affiliates,
    conversions,
    isSalesTrackingSetup,
    links,
    onClickDismissSetup,
    sales,
    subtitle,
  } = props;

  const loading = (affiliates && affiliates.state === 'loading')
    || (conversions && conversions.state === 'loading')
    || (sales && sales.state === 'loading');

  const smallStatsLabelMap = React.useMemo((): ILabelMap<IBasicStat> => ({
    Conversions: conversions ? {
      format: 'default',
      stat: conversions,
    } : undefined,
    Affiliates: affiliates ? {
      format: 'default',
      stat: affiliates,
    } : undefined,
  }), [
    affiliates,
    conversions,
  ]);
  const smallStats = useGetBasicStatsFromLabelMap(smallStatsLabelMap);

  const statsLabelMap = React.useMemo((): ILabelMap<IComplexStat> => ({
    Sales: sales ? {
      format: 'dollarsWithCents',
      stat: sales,
    } : undefined,
  }), [sales]);
  const stats = useGetComplexStatsFromLabelMap(statsLabelMap);
  const emptyStats = isEmpty(stats) || stats.every((stat) => !stat.value);

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  if (isRefreshUIEnabled && (!isSalesTrackingSetup || emptyStats)) {
    return (
      <ShadCnCard className="min-h-full flex flex-col">
        <CardHeader className="pb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="text-icon-grey">
                <CircleDollarIcon />
              </span>
              <span className="font-medium text-base">
                {CARD_TITLE}
                <span
                  className="text-xs whitespace-nowrap overflow-hidden text-ellipsis ml-2 text-subtitle"
                  title={subtitle}
                >
                  {subtitle}
                </span>
              </span>
            </div>
            <div className={cx(statisticsCardStyles.infoWrapper, { [statisticsCardStyles.loading]: loading })}>
              <Tooltip arrowPointAtCenter placement="bottomRight" title={CONVERSIONS_TOOLTIP}>
                <CircleInfoIcon />
              </Tooltip>
            </div>
          </div>
        </CardHeader>
        <CardContent className="h-full flex justify-center items-center flex-1">
          <Empty
            icon={ChartColumnIcon}
            title="See conversions as they are generated."
            // eslint-disable-next-line max-len
            description="You’ll start to see conversions as they are generated via your creators links/codes."
          />
        </CardContent>
      </ShadCnCard>
    );
  }

  if (!isSalesTrackingSetup) {
    const customContent = (
      <SetupSalesTracking onClickDismissSetup={onClickDismissSetup} />
    );
    return (
      <StatisticsCard
        customContent={customContent}
        icon={<CircleDollarIcon />}
        links={EMPTY_LIST}
        loading={false}
        smallStats={EMPTY_LIST}
        stats={EMPTY_LIST}
        subtitle={subtitle}
        title={CARD_TITLE}
        tooltip={CONVERSIONS_TOOLTIP}
      />
    );
  }

  return (
    <StatisticsCard
      icon={<CircleDollarIcon />}
      links={links}
      loading={loading}
      smallStats={smallStats}
      stats={stats}
      subtitle={subtitle}
      title={CARD_TITLE}
      tooltip={CONVERSIONS_TOOLTIP}
    />
  );
});
ConversionsCard.displayName = 'ConversionsCard';
