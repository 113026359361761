import * as React from 'react';
import cx from 'classnames';
import * as qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation } from 'react-router-dom';

import { Typography, Popconfirm } from 'antd';
import { TrashcanIcon } from '@components';
import { EditOutlinedIcon } from '@frontend/app/components';

import { IActivation } from '@frontend/app/hooks';
import { useHover } from '@frontend/app/hooks/common';
import { ActivationTag } from './ActivationTag';

const { Paragraph } = Typography;

import styles from './ActivationItem.scss';

interface IProps {
  activation: IActivation;
  onClick(): void;
  onEditClick(): void;
  onDeleteClick(): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const ActivationItem: React.FC<IProps> = React.memo((props) => {
  const { activation } = props;
  const history = useHistory();
  const location = useLocation();
  const [hovered, listeners] = useHover();

  return (
    <div
      className={cx(styles.ActivationItem, props.className)}
      onClick={() => {
        history.push({
          ...location,
          pathname: '/member_table',
          search: qs.stringify({
            filters: JSON.stringify({
              segmentId: 'all_members',
              value: [{
                id: uuidv4(),
                equal: [activation.id],
                column: 'activations',
              }],
            }),
          }),
        });

        props.onClick();
      }}
      {...listeners}
    >
      <ActivationTag className={styles.tag} activation={activation} />
      <Paragraph
        className={styles.description}
        ellipsis={{ rows: 2 }}
      >
        {activation.description}
      </Paragraph>
      <div
        className={cx(styles.actions, {
          [styles.active]: hovered,
        })}
      >
        <EditOutlinedIcon
          className={cx(styles.button, styles.editButton)}
          size={18}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            props.onEditClick();
          }}
        />
        <Popconfirm
          overlayClassName={styles.Popconfirm}
          title={(
            <div className={styles.title}>
              <div className={styles.header}>Delete this activation?</div>
              <div className={styles.text}>this action cannot be undone</div>
            </div>
          )}
          icon={null}
          onConfirm={(event) => {
            event.preventDefault();
            event.stopPropagation();

            props.onDeleteClick();
          }}
          onCancel={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          okText="Delete"
          cancelText="Cancel"
          okButtonProps={{
            danger: true,
            className: styles.okButton,
          }}
          cancelButtonProps={{
            className: styles.cancelButton,
          }}
        >
          <TrashcanIcon
            className={styles.button}
            size={18}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          />
        </Popconfirm>
      </div>
    </div>
  );
});

ActivationItem.defaultProps = {
  className: null,
};

ActivationItem.displayName = 'ActivationItem';
