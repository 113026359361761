import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const ExclamationIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 2 9">
    <path d="M0.282472 5.56908L0.0315952 0.4375H1.78774L1.55967 5.56908H0.282472ZM0.00878906 7.2454C0.00878906 6.74364 0.419315 6.32171 0.92107 6.32171C1.42282 6.32171 1.83335 6.74364 1.83335 7.2454C1.83335 7.73575 1.42282 8.15768 0.92107 8.15768C0.419315 8.15768 0.00878906 7.73575 0.00878906 7.2454Z" />
  </SvgIcon>
));

ExclamationIcon.displayName = 'ExclamationIcon';
