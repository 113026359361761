import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_COUNTS_BY_TASK_IDS_QUERY } from '@frontend/app/queries';
import {
  GetCountsByTaskIdsQuery,
  GetCountsByTaskIdsQueryVariables,
} from '@frontend/app/queries/types/GetCountsByTaskIdsQuery';

type IOptions = QueryHookOptions<GetCountsByTaskIdsQuery, GetCountsByTaskIdsQueryVariables>;

export const useGetCountsByTaskIdsQuery = (options: IOptions = {}) => (
  useQuery<GetCountsByTaskIdsQuery, GetCountsByTaskIdsQueryVariables>(GET_COUNTS_BY_TASK_IDS_QUERY, options)
);
