import gql from 'graphql-tag';

export const CREATE_CLIENT_CONNECTION = gql`
  mutation CreateClientConnection($data: [ClientShopifyConnectionInput!]!) {
    connectedClients: createClientConnection(data: $data) {
      id
      isConnected
      isPrimary
      label
      masterClientId
      connectedClientId
    }
  }
`;
