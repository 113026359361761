import { useQuery, QueryHookOptions } from '@apollo/client';

import { PROGRAMS_FOR_COMMUNITY } from '@frontend/app/queries/ProgramsForCommunityQuery';
import {
  ProgramsForCommunityQuery,
  ProgramsForCommunityQueryVariables,
} from '@frontend/app/queries/types/ProgramsForCommunityQuery';

type IOptions = QueryHookOptions<ProgramsForCommunityQuery, ProgramsForCommunityQueryVariables>;

export const useProgramsForCommunityQuery = (communityId: number, options: IOptions = {}) => useQuery<ProgramsForCommunityQuery, ProgramsForCommunityQueryVariables>(PROGRAMS_FOR_COMMUNITY, {
    variables: {
      communityId,
    },
    skip: !communityId || options.skip,
  });
