/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10nir_157 {
  display: flex;
}

._justify-content-space-between_10nir_161 {
  justify-content: space-between;
}

._tabular-nums_10nir_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10nir_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PostsView_10nir_178 {
  padding-bottom: 0.75rem;
  background-color: #fdfdfd;
  max-width: 118.75rem;
}
._PostsView_10nir_178 ._postFilters_10nir_183 {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
._PostsView_10nir_178 ._postFilters_10nir_183 ._searchInput_10nir_190 {
  width: 300px;
}
._PostsView_10nir_178 ._postFilters_10nir_183 ._selectSort_10nir_193 {
  width: 150px;
  margin-left: 25px;
}
._PostsView_10nir_178 ._postsGrid_10nir_197 {
  margin: auto;
  display: grid;
  width: auto;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
}