import * as React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import AddOrEditCommunity from './AddOrEditCommunity/AddOrEditCommunity';

const CommunitiesLayout: React.FunctionComponent<RouteComponentProps> = React.memo(({ match }) => (
  <Switch>
    <Route path={`${match.url}/new`} component={AddOrEditCommunity} />
    <Route path={`${match.url}/:communityId/edit`} component={AddOrEditCommunity} />
    <Route path={`${match.url}/all_contacts/settings`} component={AddOrEditCommunity} />
  </Switch>
));

CommunitiesLayout.displayName = 'CommunitiesLayout';

export default CommunitiesLayout;
