import { addGuidelineAttachment } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IAddContentGuidelineAttachmentAction, IState } from '../../../types/state';

export const addContentGuidelineAttachment = (
    state: IState,
    action: IAddContentGuidelineAttachmentAction,
  ): IState => ({
      ...state,
      contentGuidelines: addGuidelineAttachment(
        state.contentGuidelines,
        action.id,
        action.attachment,
      ),
      isEditMode: true,
    });
