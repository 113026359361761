import * as React from 'react';
import cx from 'classnames';
import { EditIcon } from '@components';

import { EllipsisLabel } from '@frontend/app/components';

import { EditSegment } from '../EditSegment/EditSegment';

import styles from './SegmentItem.scss';
import { IFolder } from './SegmentFolder';

const { useState, useRef, useCallback } = React;

interface ISegment {
  id: number | string;
  title: string;
  memberCount: number;
}

interface IProps {
  communityId: number;
  sourcingGroupId: string;
  segment: ISegment;
  folder?: IFolder;
  editable?: boolean;
  selected?: boolean;
  onClick?();
  className?: string;
}

export const SegmentItem: React.FC<IProps> = (props) => {
  const editSegmentMountRef = useRef<HTMLDivElement>();
  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  const handleEditSegment = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowEditForm(true);
  }, [setShowEditForm]);

  const handleCloseEditForm = useCallback(() => {
    setShowEditForm(false);
  }, [setShowEditForm]);

  const handleClick = useCallback(() => {
    if (!showEditForm && props.onClick) {
      props.onClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditForm, props.onClick]);

  return (
    <div
      onClick={handleClick}
      className={cx(styles.SegmentItem, {
        [styles.selected]: props.selected,
        [styles.editable]: props.editable,
      }, props.className)}
      ref={editSegmentMountRef}
    >
      <EllipsisLabel className={styles.title}>
        {props.segment.title}
      </EllipsisLabel>

      <span className={styles.count}>
        {props.segment.memberCount}
      </span>

      {props.editable && (
        <>
          <span className={styles.editIcon} onClick={handleEditSegment}>
            <EditIcon size={18} />
          </span>
          <EditSegment
            communityId={props.communityId}
            sourcingGroupId={props.sourcingGroupId}
            mountRef={editSegmentMountRef}
            show={showEditForm}
            segment={props.segment as ISegment & { id: number }}
            folder={props.folder}
            onRequestClose={handleCloseEditForm}
            arrowPosition="end"
            anchorOrigin="end"
          />
        </>
      )}
    </div>
  );
};

SegmentItem.defaultProps = {
  editable: true,
  selected: false,
};
