import React from 'react';
import { Button, Typography } from '@revfluence/fresh';
import { ArrowLeftIcon } from '@revfluence/fresh-icons/regular/esm';
import { UserAvatar } from '@frontend/app/components';
import { replace, trim } from 'lodash';
import styles from './GroupCreators.scss';

const { Title } = Typography;
const FALLBACK_GROUP_URL = 'https://storage.googleapis.com/aspirex-static-files/home/groups.png';

const GroupInfo = ({ communityById, history }) => (
  <div className={styles.head}>
    <Button
      onClick={() => {
                    history.replace('/programs');
                }}
      icon={<ArrowLeftIcon />}
    >
      Back
    </Button>
    <div>
      <UserAvatar
        className={styles.groupImage}
        name={communityById?.title}
        profilePicture={communityById?.splashImageUrl ? communityById?.splashImageUrl : FALLBACK_GROUP_URL}
      />
      <Title level={4}>
        {trim(replace(communityById?.title, '#AR', ''))}
      </Title>
    </div>
  </div>
    );

export default GroupInfo;
