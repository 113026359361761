import * as React from 'react';

import { map, filter } from 'lodash';

import {
  SubmitButton, LoadSpinner, Button,
} from '@components';

import {
  useRequirementsForMemberQuery,
} from '@frontend/app/hooks';

import { RequirementCell, IRequirementWithState } from './RequirementCell';

import styles from './RequirementAssignmentForm.scss';

const { useState, useEffect } = React;

interface IProps {
  artifactPreview?: React.ReactNode;
  memberId: number;
  artifactName: string;
  onAddToRequirement(requirementId: number, markComplete: boolean): void;
  saving: boolean;
}

export const RequirementAssignmentForm: React.FunctionComponent<IProps> = (props) => {
  const {
 artifactName, artifactPreview, saving, onAddToRequirement,
} = props;
  const [selectedRequirementId, setSelectedRequirementId] = useState<number>(null);
  const [requirementsWithState, setRequirementsWithState] = useState<IRequirementWithState[]>(null);

  const {
    data: {
      requirements = null,
    } = {},
    loading: isLoading,
  } = useRequirementsForMemberQuery(props.memberId, {
  });

  // Set up initial state
  useEffect(() => {
    if (!requirements) {
      return;
    }

    const incompleteRequirements = filter(requirements, { completed: false });
    if (incompleteRequirements.length === 0) {
      // No requirements to assign to
      skipRequirements();
      return;
    }
    if (incompleteRequirements && !requirementsWithState) {
      setRequirementsWithState(map(incompleteRequirements, (requirement) => ({
          requirement,
          markComplete: false,
        })));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requirements]);

  const onRequirementSelected = (requirementWithState: IRequirementWithState) => {
    const requirement = requirementWithState.requirement;
    if (selectedRequirementId && requirement.id === selectedRequirementId) {
      setSelectedRequirementId(null);
    } else {
      setSelectedRequirementId(requirementWithState.requirement.id);
    }
  };

  const onMarkCompleteSelected = (markedRequirementWithState: IRequirementWithState, markComplete: boolean) => {
    const newState = [];
    for (const i in requirementsWithState) {
      if (requirementsWithState[i].requirement.id === markedRequirementWithState.requirement.id) {
        newState[i] = {
          requirement: markedRequirementWithState.requirement,
          markComplete,
        };
      } else {
        newState[i] = requirementsWithState[i];
      }
    }
    setRequirementsWithState(newState);
  };

  const saveRequirements = () => {
    if (selectedRequirementId) {
      for (const requirementWithState of requirementsWithState) {
        if (requirementWithState.requirement.id === selectedRequirementId) {
          onAddToRequirement(selectedRequirementId, requirementWithState.markComplete);
        }
      }
    } else {
      onAddToRequirement(null, false);
    }
  };

  const skipRequirements = () => {
    onAddToRequirement(null, false);
  };

  return (
    <div>
      {isLoading ? <LoadSpinner />
        : (
          <div className={styles.RequirementAssignmentForm}>
            <h1 className={styles.Title}>Associate this content with requirements</h1>
            <p className={styles.Description}>
              If this
              {' '}
              {artifactName}
              {' '}
              helps fulfill any of the requirements below, select them to add to that requirement
            </p>
            <div className={styles.RequirementWrap}>
              <label className={styles.StandardLabel}>
                Add
                {artifactName}
                {' '}
                to requirement:
              </label>
              {map(requirementsWithState, (requirementWithState) => (
                <RequirementCell
                  requirementState={requirementWithState}
                  key={requirementWithState.requirement.id}
                  onMarkCompleteSelected={onMarkCompleteSelected}
                  onRequirementSelected={onRequirementSelected}
                  selected={!!selectedRequirementId
                  && requirementWithState.requirement.id === selectedRequirementId}
                />
            ))}
              <div>
                {artifactPreview || ''}
              </div>
              <div className={styles.ButtonWrap}>
                <SubmitButton
                  label="Add to Requirement"
                  onClick={saveRequirements}
                  disabled={selectedRequirementId === null || saving}
                  submittingLabel="Saving..."
                  className={styles.Submit}
                  isSubmitting={saving}
                />
                <Button
                  label="Skip"
                  onClick={skipRequirements}
                  theme="light"
                />
              </div>
            </div>
          </div>
)}
    </div>
  );
};
