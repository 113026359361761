import * as React from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import {
  isUndefined, map, find,
} from 'lodash';

import { Modal } from '@revfluence/fresh';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';

import {
  useFeatureFlagVerbiage,
  useGetAllProjectsQuery,
  useGetApplicationsByIds,
  useGetMemberQuery,
  useInstalledApplicationInstances,
  useMarkMembersAsImportantMutation,
  useModifyProgramMembersMutation,
  useGetCollaborationDetailsForProject,
  useCopyToClipboard,
  useMarkContentReviewComplete,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { EmailComposerModal } from '@frontend/app/components';
import { useMessagingContext } from '@frontend/hooks';
import { CONTENT_APP_ID, TERMS_APP_ID } from '@frontend/app/constants/applicationIds';

import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import {
  AddMembersToCollectionModal,
  ModalType,
} from '../Projects/ProjectsPage/AddMembersToProgramModal/AddMembersToCollectionModal';

import { MemberSection } from './components/MemberSection';

import { IApplication } from './types';

const {
  useState, useEffect, useCallback, useMemo,
} = React;

interface IProps {
  memberId: number;
  projectId?: number;
  disabledApplicationIds?: string[];
  isInSentTermsStage?: boolean;
  isDeliverablesHidden?: boolean;
  sendTermsAction?: () => void;
  onBackButtonClicked?: () => void;
  refetchMembers?: () => void;
}

export const NewMemberDetailContainer: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    memberId,
    disabledApplicationIds,
    projectId,
    isInSentTermsStage,
    isDeliverablesHidden,
    sendTermsAction,
    onBackButtonClicked,
    refetchMembers,
  } = props;

  const [isImportant, setIsImportant] = useState(false);
  const [isComposerOpen, setIsComposerOpen] = useState(false);
  const [modalType, setModalType] = useState<ModalType | undefined>();
  const location = useLocation();

  const { data: { member } = {}, loading } = useGetMemberQuery(memberId);
  const [markMemberAsImportant] = useMarkMembersAsImportantMutation();

  const { showMessage, showGenericErrorMessage } = useMessagingContext();
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const {
    data: { details = [] } = {},
    loading: loadingCollabDetails,
    refetch,
    error: fetchingContentError,
  } = useGetCollaborationDetailsForProject({
    fetchPolicy: 'no-cache',
    variables: { projectId, memberId },
    skip: !projectId,
  });
  useEffect(() => {
    if (fetchingContentError) {
      showMessage({
        type: 'warning',
        content: 'There was an issue fetching content for this member. Please contact support.',
      });
    }
  }, [fetchingContentError, showMessage]);

  const [markContentReviewComplete] = useMarkContentReviewComplete({
    onCompleted: async (data) => {
      showMessage({
        type: 'success',
        content: data.markedForCompletion ? 'Pending marked as complete. You have 5 minutes to undo this action.'
        : 'Mark as complete cancelled',
      });
      refetch();
    },
    onError: () => {
      showGenericErrorMessage();
    },
  });

  const verbiage = useFeatureFlagVerbiage();
  const [removeMemberFromPrograms] = useModifyProgramMembersMutation('remove', {
    onCompleted: async () => {
      showMessage({
        type: 'success',
        content: `1 member is being removed from ${verbiage.programs}`,
      });
      handleBack();
    },
    onError: () => {
      showGenericErrorMessage();
    },
  });

  const { copyToClipboard } = useCopyToClipboard();

  const history = useHistory();
  const match = useRouteMatch<{ projectId: string; memberId: string; workletSpecUri: string }>();

  const { data: { projects } = {}, loading: isProjectsLoading } = useGetAllProjectsQuery();

  const {
    data: {
      instances: applicationInstances = [],
    } = {},
  } = useInstalledApplicationInstances({
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      applications = null,
    } = {},
  } = useGetApplicationsByIds(applicationInstances ? map(applicationInstances, 'applicationId') : [], {
    fetchPolicy: 'cache-and-network',
  });

  const project = useMemo(() => projects?.find((p) => p.id === +match.params.projectId), [projects, match]);

  const handleBack = useCallback(() => {
    if (onBackButtonClicked) {
      onBackButtonClicked();
      return;
    }

    history.goBack();
  }, [onBackButtonClicked, history]);

  const showAppModal = useCallback(
    (application: IApplication | null, deepLinkParameters?: unknown) => {
      if (!application) {
        return;
      }

      const params: { pathname: string, search?: string } = {
        pathname: `${match.url}/app/${application.id}`,
      };
      if (deepLinkParameters) {
        params.search = `?link_params=${JSON.stringify(deepLinkParameters)}`;
      } else {
        params.search = '';
      }

      history.push({
        ...location,
        ...params,
        search: location.search,
      });
    },
    [location, history, match],
  );

  const onReviewClick = useCallback((contentReviewId) => {
    const contentApp = find(applications, { id: CONTENT_APP_ID });
    if (contentApp) {
      history.push({
        ...location,
        pathname: `${match.url}/app/${contentApp.id}/content`,
        search: `link_params=${encodeURIComponent(JSON.stringify({
          in_approval_mode: true,
          member_id: memberId,
          program_id: projectId,
          context: 'viewContext',
          id: contentReviewId,
        }))}`,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, match, applications]);

  const onTermsClick = useCallback((agreementIterationId: string, termsType: string, agreementId: string) => {
    const termsApp = find(applications, { id: TERMS_APP_ID });
    if (termsApp) {
      history.push({
        ...location,
        pathname: `${match.url}/app/${termsApp.id}/agreement/view`,
        search: `link_params=${encodeURIComponent(JSON.stringify({
          iteration_id: agreementIterationId,
          terms_type: termsType,
          action: 'view_terms',
          agreement_id: agreementId,
          member_id: memberId,
          program_id: projectId,
        }))}`,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, match, applications]);

  const toggleFavorite = useCallback(() => {
    markMemberAsImportant({
      variables: {
        memberIds: [member.id],
        isImportant: !isImportant,
      },
    });
    setIsImportant(!isImportant);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, isImportant]);

  useEffect(() => {
    setIsImportant(member?.isImportant);
  }, [member]);

  const addToProject = useCallback(() => {
    setModalType(ModalType.AddToAnotherProject);
  }, []);

  const composeMessage = useCallback(() => {
    setIsComposerOpen(true);
  }, []);

  const closeComposer = useCallback(() => {
    setIsComposerOpen(false);
  }, []);

  const addToGroup = useCallback(() => {
    setModalType(ModalType.AddToGroup);
  }, []);

  const removeFromProject = useCallback(() => {
    Modal.confirm({
      title: 'Confirm',
      icon: <CircleExclamationIcon />,
      content: 'Are you sure you want to remove selected members from project?',
      okText: 'Remove',
      cancelText: 'Cancel',
      onOk: () => {
        removeMemberFromPrograms({
          variables: {
            memberIds: [member.id],
            programIds: [+match.params.projectId],
          },
        });
      },
    });
  }, [removeMemberFromPrograms, member, match]);

  const markComplete = useCallback((contentReviewId: number, shouldMarkComplete: boolean) => {
    markContentReviewComplete({
      variables: {
        projectId,
        contentReviewId,
        shouldMarkComplete,
      },
    });
  }, [markContentReviewComplete, projectId]);

  const copyTermsLink = useCallback((termsUrl: string) => {
    copyToClipboard({
      value: termsUrl,
      onSuccess: () => {
        showMessage({
          type: 'success',
          content: 'Link copied to clipboard',
        });
      },
      onFailure: () => {
        showGenericErrorMessage();
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    copyToClipboard,
  ]);
  return (
    <>

      <MemberSection
        loading={loading || loadingCollabDetails}
        member={member}
        apps={applications}
        projectId={projectId}
        projects={projects}
        disabledApplicationIds={disabledApplicationIds}
        content={details}
        isImportant={isImportant}
        onBackButtonClicked={handleBack}
        onAddToProjectAction={addToProject}
        onComposeAction={composeMessage}
        onAddToGroupAction={addToGroup}
        onRemoveFromProjectAction={removeFromProject}
        onFavoriteAction={toggleFavorite}
        onMarkCompleteAction={markComplete}
        onCopyTermsLinkAction={copyTermsLink}
        onAppClickAction={showAppModal}
        onReviewClick={onReviewClick}
        onTermsClick={onTermsClick}
        isInSentTermsStage={isInSentTermsStage}
        sendTermsAction={sendTermsAction}
        refetchMembers={refetchMembers}
        isDeliverablesHidden={isDeliverablesHidden}
        refreshUi={refreshUi}
      />

      <EmailComposerModal
        isOpen={isComposerOpen}
        customButton={() => null}
        members={member ? [member] : []}
        source="memberdetail"
        label="Compose"
        onClose={closeComposer}
      />
      <AddMembersToCollectionModal
        loading={isProjectsLoading}
        onCloseModal={() => setModalType(undefined)}
        modalType={modalType}
        project={project}
        projects={projects}
        showModal={!isUndefined(modalType)}
        selectedMemberIds={member ? [member.id] : []}
      />
    </>
  );
});

NewMemberDetailContainer.displayName = 'NewMemberDetailContainer';
