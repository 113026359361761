import { useQuery, QueryHookOptions } from '@apollo/client';

import {
  GetPublicCampaignByProjectIdQuery,
  GetPublicCampaignByProjectIdQueryVariables,
} from '@frontend/app/queries/types/GetPublicCampaignByProjectIdQuery';
import { GET_PUBLIC_CAMPAIGN_BY_PROJECT_ID_QUERY } from '../queries/GetPublicCampaignByProjectIdQuery';

type IOptions = QueryHookOptions<GetPublicCampaignByProjectIdQuery, GetPublicCampaignByProjectIdQueryVariables>;

export function useGetPublicCampaignByProjectId(projectId: number, clientId: string, options: IOptions = {}) {
  return useQuery<GetPublicCampaignByProjectIdQuery>(
    GET_PUBLIC_CAMPAIGN_BY_PROJECT_ID_QUERY,
    {
      ...options,
      variables: {
        projectId,
        clientId,
      },
      skip: !projectId || options.skip,
    },
  );
}
