import 'css-chunk:src/components/widgets/PostCard/AccountSection.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_7dv9t_157",
  "justify-content-space-between": "_justify-content-space-between_7dv9t_161",
  "tabular-nums": "_tabular-nums_7dv9t_165",
  "accountSection": "_accountSection_7dv9t_169",
  "truncate": "_truncate_7dv9t_211",
  "avatarImg": "_avatarImg_7dv9t_218",
  "adminLink": "_adminLink_7dv9t_228",
  "accountNetworkIconContainer": "_accountNetworkIconContainer_7dv9t_231",
  "demoAccountModeAccountNetworkIconContainer": "_demoAccountModeAccountNetworkIconContainer_7dv9t_244",
  "dateContainer": "_dateContainer_7dv9t_257",
  "dateTxt": "_dateTxt_7dv9t_261",
  "avatarContainer": "_avatarContainer_7dv9t_264",
  "txtContainer": "_txtContainer_7dv9t_275",
  "accountNameContainer": "_accountNameContainer_7dv9t_278",
  "accountName": "_accountName_7dv9t_278",
  "accountFollowersContainer": "_accountFollowersContainer_7dv9t_292",
  "accountFollowersTxt": "_accountFollowersTxt_7dv9t_297",
  "revLogo": "_revLogo_7dv9t_300",
  "show": "_show_7dv9t_1"
};