import * as React from 'react';
import cx from 'classnames';
import {
 forEach, isEmpty, isFunction, map,
} from 'lodash';
import { Steps } from 'antd';

import { logger } from '@common';

const {
 useCallback, useEffect, useImperativeHandle, useState,
} = React;

import styles from './Wizard.scss';

interface IProps {
  steps: IWizardStep[];
  currentStep?: number;
  className?: string;
}

export interface IWizardStep {
  title: React.ReactChild;
  subtitle?: React.ReactChild;
  description?: React.ReactChild;
  render: () => React.ReactChild;
  reset?: () => void;
  nextStep?: () => void;
}

export interface IWizardHandles {
  goToStep: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  reset: () => void;
}

export interface IWizardStepHandles {
  reset: () => void;
}

/**
 * Generic Wizard component
 */
export const Wizard = React.forwardRef<IWizardHandles, IProps>((props, ref) => {
  const {
    className,
    steps,
    currentStep: currentStepProp,
  } = props;

  const [currentStep, setStep] = useState(currentStepProp || 1);
  useEffect(
    () => {
      if (currentStepProp) {
        setStep(currentStepProp);
      }
    },
    [currentStepProp],
  );
  const nextStep = useCallback(
    () => setStep((s) => Math.min(s + 1, steps.length)),
    [setStep, steps],
  );
  const prevStep = useCallback(
    () => setStep((s) => Math.max(1, s - 1)),
    [setStep],
  );
  const goToStep = useCallback(
    (step: number) => setStep(Math.max(1, Math.min(step, steps.length))),
    [setStep, steps],
  );

  const reset = useCallback(
    () => {
      forEach(steps, (step) => {
        if (isFunction(step.reset)) {
          step.reset();
        }
      });
      setStep(1);
    },
    [setStep, steps],
  );

  useImperativeHandle(
    ref,
    () => ({
      goToStep,
      nextStep,
      prevStep,
      reset,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nextStep, prevStep],
  );

  const renderSteps = useCallback(
    () => (
      <Steps
        current={currentStep - 1}
        size="small"
        className={styles.steps}
      >
        {map(steps, (step, i) => (
          <Steps.Step
            key={`step-${i}`}
            title={step.title}
            subTitle={step.subtitle}
            description={step.description}
          />
        ))}
      </Steps>
    ),
    [currentStep, steps],
  );

  const renderContent = useCallback(
    () => {
      if (isEmpty(steps)) {
        logger.warn('Wizard: No steps to render');
        return;
      }
      const renderFn = steps[currentStep - 1]?.render;
      if (isFunction(renderFn)) {
        return renderFn();
      }
      logger.warn(`Wizard: Unable to render contents for step ${currentStep}`);
    },
    [currentStep, steps],
  );

  return (
    <div className={cx(styles.Wizard, className)}>
      {renderSteps()}
      {renderContent()}
    </div>
  );
});

Wizard.displayName = 'Wizard';
