import * as React from 'react';
import cx from 'classnames';

import styles from './TableHeader.scss';

interface IProps {
  className?: string;
  renderCount: () => React.ReactNode;
}

export const TableHeader: React.FC<Readonly<IProps>> = ({
  className,
  children,
  renderCount,
}) => (
  <div className={cx(styles.TableHeader, className)}>
    <div className={styles.count}>
      {renderCount()}
    </div>
    <div className={styles.buttonToolbar}>
      {children}
    </div>
  </div>
  );
