import * as React from 'react';
import { IButtonProps, message } from '@revfluence/fresh';
import {
  filter,
  isEmpty,
  isFunction,
} from 'lodash';

import { IApplicationContainerHandle } from '@frontend/app/containers/Application/ApplicationContainer';
import { IInputTermsTemplate } from '@frontend/applications/TermsApp/components/BulkTerms/types/CollaborationDetails';
import { IProps as TermsWizardProps } from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard';
import { useGetTermsName } from '@frontend/app/hooks';
import { useState } from './hooks/useState/useState';
import { LoadedBulkTerms } from './LoadedBulkTerms';
import { LoadingBulkTerms } from './LoadingBulkTerms';
import { TContentUsageRights, WhitelistingLabel } from './types/CollaborationDetails';
import { TContentGuideline } from './types/ContentGuidelines';
import { IInputMember } from './types/Member';
import {
  Step,
  IState,
  TDispatch,
} from './types/state';
import { ActionTypes } from './types/actionTypes';

import { validateCollaborationDetails } from './utils/validateCollaborationDetails';

const {
  useCallback,
  useEffect,
  useMemo,
  useImperativeHandle,
} = React;

interface ILoadingProps {
  loading: true;
  onClose: () => void;
  onSave?: (state: IState) => Promise<boolean>;
  onSetTitle?: (title: string) => void;
  onScrollIntoView?: () => void;
  members?: Array<IInputMember>;
  contentGuidelinesTemplates?: Array<TContentGuideline>;
  clientNeedsAuthForBrandedContent?: boolean;
  clientNeedsAuthForPartnershipAds?: boolean;
  clientNeedsBCAAccount?: boolean;
  businessAccountMissing?: boolean;
  tiktokSparkAdsAccountMissing?: boolean;
  onClickConnectInstagram?: IButtonProps['onClick'];
  clientName?: string;
  clientId?: string;
  termsTemplate?: IInputTermsTemplate;
  onSwitchTermsType?: () => void;
  programId?:number;
}

interface ILoadedProps {
  advancedTerms: boolean;
  daysToApproveContent: number;
  paymentPeriod: number;
  isBrandedContentEnabled: boolean;
  whitelistingLabel: WhitelistingLabel;
  clientId: string;
  businessAccountMissing: boolean;
  clientNeedsAuthForBrandedContent: boolean;
  clientNeedsAuthForPartnershipAds?: boolean;
  clientNeedsBCAAccount: boolean;
  tiktokSparkAdsAccountMissing: boolean;
  isCreatorEditEnabled: boolean;
  contentRightsTemplates: Array<TContentUsageRights>;
  contentGuidelinesTemplates: Array<TContentGuideline>;
  loading: false;
  members: Array<IInputMember>;
  onSave: (state: IState) => Promise<boolean>;
  onClose: () => void;
  onClickConnectInstagram: IButtonProps['onClick'];
  onSetTitle: (title: string) => void;
  renderEmailComposer: (
    templateId: number,
    initialMessage: string,
    state: IState,
    dispatch: TDispatch,
  ) => React.ReactElement;
  clientName: string;
  termsTemplate: IInputTermsTemplate;
  onSwitchTermsType: () => void;
  programId?:number;
}

type TProps = ILoadingProps | ILoadedProps;
export const BulkTerms = React.memo(React.forwardRef<IApplicationContainerHandle, TProps>((props: TProps, ref) => {
  const {
    loading,
    onClose,
    members: propMembers,
    contentGuidelinesTemplates,
    onSave,
    onSetTitle,
    clientName,
    clientNeedsAuthForBrandedContent,
    clientNeedsAuthForPartnershipAds,
    clientNeedsBCAAccount,
    tiktokSparkAdsAccountMissing,
    termsTemplate,
    onSwitchTermsType,
    programId,
  } = props;

  const { dispatch, state } = useState();

  useImperativeHandle(ref, () => ({
    showWarningOnClose: () => ([
      Step.AddContentGuidelines,
      Step.CollaborationDetails,
      Step.ReviewAndSetOffers,
      Step.SocialProfile,
      Step.WriteMessage,
    ].includes(state?.step)),
    saving: state.saving,
  }), [state]);

  useEffect(() => {
    if (!loading) {
      dispatch({
        contentGuidelineTemplates: contentGuidelinesTemplates,
        members: propMembers,
        emailSubject: termsTemplate.subject,
        contentUsageRightType: termsTemplate.contentUsageRightType,
        contentUsageRightValue: termsTemplate.contentUsageRightValue,
        compensationPaymentAvailable: termsTemplate.compensationPaymentAvailable,
        compensationPaymentValue: termsTemplate.compensationPaymentValue,
        compensationFreeProductAvailable: termsTemplate.compensationFreeProductAvailable,
        compensationFreeProductValue: termsTemplate.compensationFreeProductValue,
        compensationCommissionAvailable: termsTemplate.compensationCommissionAvailable,
        compensationCommissionValue: termsTemplate.compensationCommissionValue,
        noCompensationAvailable: termsTemplate.noCompensationAvailable,
        type: ActionTypes.LoadInitialState,
      });
    }
  }, [
    dispatch,
    loading,
    propMembers,
    contentGuidelinesTemplates,
    clientName,
    termsTemplate,
  ]);

  const {
    contentGuidelines,
    members,
    saving,
    step,
    collaborationDetails: {
      bam,
      adsPermissions,
    },
    emailContent,
    emailSubject,
    featureFlags,
  } = state;
  const adsPermissionsFFs = (featureFlags.MetaBrandedContent || featureFlags.InstagramPartnershipAds);
  const linkBCAAccountAlert = clientNeedsBCAAccount && (bam.toggleInfluencerBrandedContent || adsPermissionsFFs);
  const linkInsightsAlertForBrandedContent = (
    clientNeedsAuthForBrandedContent
    && !clientNeedsBCAAccount
    && !adsPermissions.requireIgPartnershipAdsAccess
  );
  const linkInsightsAlertForPartnershipAds = clientNeedsAuthForPartnershipAds && !clientNeedsBCAAccount;
  const { singularTermsName } = useGetTermsName();
  useEffect(() => {
    if (onSetTitle) {
      switch (step) {
        case Step.AddContentGuidelines:
          onSetTitle(`Send ${singularTermsName} - Content Requirements`);
          break;
        case Step.CollaborationDetails:
          onSetTitle(`Send ${singularTermsName} - Collaboration Details`);
          break;
        case Step.ReviewAndSetOffers:
          onSetTitle(`Send ${singularTermsName} - Review & Set Offers`);
          break;
        case Step.WriteMessage:
          onSetTitle(`Send ${singularTermsName} - Write Message`);
          break;
        case Step.SocialProfile:
          onSetTitle(`Send ${singularTermsName} - Social Profile`);
          break;
        default:
          onSetTitle(`Send ${singularTermsName}`);
          break;
      }
    }
  }, [step, onSetTitle, singularTermsName]);

  const goToNextStep = useCallback(() => {
    dispatch({ type: ActionTypes.NextStep });
  }, [dispatch]);
  const goToPrevStep = useCallback(() => {
    dispatch({ type: ActionTypes.PrevStep });
  }, [dispatch]);

  const handleAddContentGuidelines = useCallback(
    () => {
      dispatch({ type: ActionTypes.AddContentGuidelines });
    }, [dispatch],
  );

  const footerSettings = useMemo((): TermsWizardProps['footerSettings'] => {
    if (loading || step === Step.SocialProfile || step === Step.ErrorStep) {
      return {
        hideFooter: true,
      };
    }
    const selectedMembers = filter(members, 'selected');
    let prevButtonLabel;
    let nextButtonLabel;
    let progressCurrent;
    let prevButtonOnClick = goToPrevStep;
    let nextButtonOnClick = goToNextStep;
    const prevButtonDisabled = false;
    let nextButtonDisabled = false;
    let showTooltipMessage = false;
    let tooltipMessage: React.ReactNode = '';
    switch (step) {
      case Step.AddContentGuidelines:
        progressCurrent = 1;
        prevButtonLabel = <>Back to project</>;
        nextButtonLabel = <>Collaboration Details</>;
        prevButtonOnClick = () => {
          if (isFunction(onClose)) {
            onClose();
          }
        };
        nextButtonOnClick = handleAddContentGuidelines;
        nextButtonDisabled = contentGuidelines.every(
          (contentGuideline) => contentGuideline.dueDates.completeDate.length < 1,
        );
        break;
      case Step.CollaborationDetails: {
        const errors = validateCollaborationDetails(state, {
          businessAccountMissing: props.businessAccountMissing,
          clientNeedsAuthForBrandedContent: props.clientNeedsAuthForBrandedContent,
          clientNeedsAuthForPartnershipAds: props.clientNeedsAuthForPartnershipAds,
          clientNeedsBCAAccount: props.clientNeedsBCAAccount,
          tiktokSparkAdsAccountMissing,
        });
        const hasErrors = !isEmpty(errors);
        prevButtonLabel = <>Requirements</>;
        nextButtonLabel = <>Review &amp; Set Offers</>;
        nextButtonDisabled = hasErrors;
        showTooltipMessage = hasErrors;
        tooltipMessage = (
          <ul>
            {errors.map((error) => <li key={error}>{error}</li>)}
          </ul>
        );
        progressCurrent = 2;
        break;
      }
      case Step.ReviewAndSetOffers:
        prevButtonLabel = <>Collaboration Details</>;
        nextButtonLabel = <>Write Message</>;
        progressCurrent = 3;
        nextButtonDisabled = selectedMembers.length < 1;
        break;
      case Step.WriteMessage: {
        prevButtonLabel = <>Review &amp; Set Offers</>;
        nextButtonLabel = (
          <>
            Send
            {' '}
            {selectedMembers.length}
            {' '}
            brief
            {selectedMembers.length === 1 ? '' : 's'}
          </>
        );
        progressCurrent = 4;
        nextButtonOnClick = async () => {
          if (isFunction(onClose)) {
            dispatch({ type: ActionTypes.ToggleSaving });
            let status = false;
            if (isFunction(onSave)) {
              status = await onSave(state);
            }
            if (status === false) {
              message.error('Error sending brief');
            } else {
              message.success(`Sent ${selectedMembers.length} brief${selectedMembers.length === 1 ? '' : 's'}.`);
            }
            onClose();
          }
        };
        const isLinkMissing = !emailContent?.html.includes('<span data="LINK_TO_TERMS">');
        const emptyBody = emailContent?.html === '<br />';
        nextButtonDisabled = emptyBody || isEmpty(emailSubject) || isLinkMissing;
        showTooltipMessage = nextButtonDisabled;
        if (isEmpty(emailSubject)) {
          tooltipMessage = 'Subject cannot be empty.';
        } else if (emptyBody) {
          tooltipMessage = 'Please enter a message body.';
        } else if (isLinkMissing) {
          tooltipMessage = 'You must include personalization for project link';
        }
        break;
      }
      default:
        throw new Error(`Unhandled step in footer: "${step}".`);
    }
    return {
      hideFooter: false,
      prevButtonDisabled,
      nextButtonDisabled,
      prevButtonLabel,
      nextButtonLabel,
      nextButtonLoading: saving,
      prevButtonOnClick,
      nextButtonOnClick,
      progress: {
        current: progressCurrent,
        total: 5,
      },
      showNextButtonIcon: step !== Step.WriteMessage,
      showPrevButtonIcon: step !== Step.AddContentGuidelines,
      showTooltipMessage,
      tooltipMessage,
      showProgress: true,
      showPrevButton: true,
    };
  }, [
    contentGuidelines,
    dispatch,
    emailContent?.html,
    emailSubject,
    goToNextStep,
    goToPrevStep,
    handleAddContentGuidelines,
    loading,
    members,
    onClose,
    onSave,
    props.businessAccountMissing,
    props.clientNeedsAuthForBrandedContent,
    props.clientNeedsAuthForPartnershipAds,
    props.clientNeedsBCAAccount,
    tiktokSparkAdsAccountMissing,
    saving,
    state,
    step,
  ]);

  // hold the loading state until the graphql query is done and state.members is populated
  if (loading === true || isEmpty(state.members)) {
    return (
      <LoadingBulkTerms footerSettings={footerSettings} />
    );
  }

  const { renderEmailComposer } = props;

  return (
    <LoadedBulkTerms
      paymentPeriod={props.paymentPeriod}
      linkInsightsAlertForBrandedContent={linkInsightsAlertForBrandedContent}
      linkInsightsAlertForPartnershipAds={linkInsightsAlertForPartnershipAds}
      linkBCAAccountAlert={linkBCAAccountAlert}
      tiktokSparkAdsAccountMissing={tiktokSparkAdsAccountMissing}
      businessAccountMissing={props.businessAccountMissing}
      advancedTerms={props.advancedTerms}
      daysToApproveContent={props.daysToApproveContent}
      isBrandedContentEnabled={props.isBrandedContentEnabled}
      whitelistingLabel={props.whitelistingLabel}
      onClickConnectInstagram={props.onClickConnectInstagram}
      isCreatorEditEnabled={props.isCreatorEditEnabled}
      contentRightsTemplates={props.contentRightsTemplates}
      contentGuidelinesTemplates={props.contentGuidelinesTemplates}
      dispatch={dispatch}
      footerSettings={footerSettings}
      renderEmailComposer={renderEmailComposer}
      state={state}
      initialMessage={termsTemplate.text}
      templateId={termsTemplate.templateId}
      onSwitchTermsType={onSwitchTermsType}
      programId={programId}
    />
  );
}));

BulkTerms.displayName = 'BulkTerms';
