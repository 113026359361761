import * as React from 'react';
import { format } from 'date-fns';

import { DatePickerDropdown } from '@components';

import { Input } from './Input';

const DEFAULT_DATE_FORMAT = 'MMM dd, yyyy';

const {
 useCallback, useRef, useState, useEffect, useMemo,
} = React;

export interface IProps {
  defaultValue: Date;
  formattedValue?: string;
  onBlur?(value: Date);
}

export const DateEditInput: React.FC<IProps> = (props) => {
  const {
 onBlur, formattedValue, defaultValue, ...restProps
} = props;

  const [show, setShow] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>();

  const [value, setValue] = useState(defaultValue);

  const strValue = useMemo(() => {
    if (formattedValue) {
      return formattedValue;
    }
    try {
      return format(defaultValue, DEFAULT_DATE_FORMAT);
    } catch (error) {
      setValue(null);
      return 'N/A';
    }
  }, [formattedValue, defaultValue]);

  const handleChange = useCallback((date: Date) => {
    if (onBlur) {
      onBlur(date);
    }
  }, [onBlur]);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <>
      <Input {...restProps} ref={inputRef} value={strValue} readOnly />
      <DatePickerDropdown
        selectedDate={value}
        onDateSelected={handleChange}
        mountRef={inputRef}
        show={show}
        onRequestClose={() => handleChange(value)}
      />
    </>
  );
};

DateEditInput.displayName = 'DateEditInput';
