import { TProgram } from '@affiliates/components/MembersWizard/types';
import { TState } from '../types';

interface IAction {
  programs: readonly TProgram[];
  type: 'POPULATE PROGRAMS';
}
export type TPopulateProgramsAction = Readonly<IAction>;

export const populatePrograms = (state: TState, action: TPopulateProgramsAction): TState => ({
    ...state,
    programs: action.programs,
  });
