/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import * as Joi from '@hapi/joi';
import cx from 'classnames';
import {
 isNil, isFunction, startCase,
} from 'lodash';

import styles from './SimpleField.scss';

const { useEffect } = React;

export type SimpleFieldType = 'string' | 'number' | 'email' | 'boolean' | 'date' | 'name' | 'array';

interface IProps {
  errorMessage?: string;
  hasError?: boolean;
  label?: string;
  name: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onChange?(name: string, value: any);
  onClick?(name: string);
  onDateSelected?(name: string, value: Date);
  required?: boolean;
  schema?: Joi.Schema;
  showControlledErrors?: boolean;
  type: SimpleFieldType;
  value?: string;
  _onChangeFieldSchema?(name: string, schema: Joi.Schema);
  useFreshComponents?: boolean;
}

export const SimpleField: React.FunctionComponent<IProps> = React.memo((props) => {
  useEffect(() => {
    if (props.schema) {
      props._onChangeFieldSchema(props.name, props.schema);
      return;
    }

    let def: Joi.Schema;
    let type: string = props.type;

    switch (props.type) {
      case 'string':
        def = Joi.string();
        break;

      case 'number':
        def = Joi.number();
        break;

      case 'email':
        def = Joi.string().email({ tlds: false }).trim();
        type = 'string';
        break;

      case 'boolean':
        def = Joi.boolean().truthy('yes').falsy('no');
        break;

      case 'array':
        def = Joi.array();
        break;

      case 'date':
        def = Joi.date();
        break;

      case 'name':
        const NAME_REGEX = /^[^#_\(\)\d\%\@\!\$]+$/;
        const label = props.label || startCase(props.name);
        def = Joi.string()
          .regex(NAME_REGEX)
          .message(`${label} must be a valid name`);
        type = 'string';
        break;

      default:
        return;
    }

    if (props.required) {
      def = def.required();
    } else {
      def = def.allow('').optional();
    }

    const label = props.label || startCase(props.name);

    def = def.messages({
      'string.base': `${label} must be a type of 'text'`,
      'string.email': `${label} must be a valid email`,
      'number.base': `${label} must be a number`,
      'boolean.base': `${label} must be a yes or no value`,
      'array.base': `${label} must be a selection`,
      'date.base': `${label} must be a valid date`,
      [`${type}.empty`]: `${label} is required`,
      [`${type}.required`]: `${label} is required`,
      'any.required': `${label} is required`,
    });

    props._onChangeFieldSchema(props.name, def);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props._onChangeFieldSchema,
    props.schema,
    props.type,
    props.required,
    props.label,
    props.name,
  ]);

  const handleChange = (value: string | Record<string, any>) => {
    const changeValue = !isNil((value as any)?.target?.value)
      ? (value as any).target.value
      : value;

    if (isFunction(props.onChange)) {
      props.onChange(props.name, changeValue);
    }
  };

  const input = React.Children.only(props.children) as React.ReactElement;

  const clonedInput = React.cloneElement(input, {
    errorMessage: props.errorMessage,
    hasError: props.hasError,
    onChange: handleChange,
    onClick: props.onClick,
    onDateSelected: handleChange,
    value: props.value,
  });

  const showErrorMessage = props.hasError && props.showControlledErrors;

  return (
    <div className={cx(
      styles.SimpleField,
      {
        [styles.showControlledErrors]: props.showControlledErrors,
        [styles.hasError]: props.hasError,
        [styles.legacyField]: !props.useFreshComponents,
      },
    )}
    >
      {clonedInput}
      {showErrorMessage && (
        <div className={styles.error}>
          {props.errorMessage}
        </div>
      )}
    </div>
  );
});

SimpleField.defaultProps = {
  required: true,
};
