import * as React from 'react';
import { Link } from 'react-router-dom';
import { Space } from 'antd';

import { ArrowIosRightOutlined } from '@affiliates/AspireUI';
import { SEND_STA_TASK_ID } from '@affiliates/types/globalTypes';
import { SALES_APP_ID } from '@frontend/app/constants/applicationIds';
import { Button } from '@components';

import styles from './STASendItems.scss';

interface IProps {
  workletSpecUri: string;
  taskId: SEND_STA_TASK_ID;
  programId: number;
  onCancel?(): void;
  onNext?(): void;
}

export const STASendConfirmation: React.FC<Readonly<IProps>> = ({
 workletSpecUri,
 taskId,
 programId,
 onCancel,
 onNext,
}) => {
  const text = React.useMemo(() => {
    switch (taskId) {
      case SEND_STA_TASK_ID.send_link_task:
        return 'links';
      case SEND_STA_TASK_ID.send_promo_task:
        return 'promo codes';
      default:
        return 'items';
    }
  }, [taskId]);

  const content = React.useMemo(() => {
    const Links = {
      members_list: '/member_table',
      generate_link: `/projects/${programId}/${workletSpecUri}/generate_link_task`,
      generate_promo: `/projects/${programId}/${workletSpecUri}/generate_promo_task`,
      send_link_and_promo: `/projects/${programId}/${workletSpecUri}/send_link_and_promo_task`,
      send_link: `/projects/${programId}/${workletSpecUri}/send_link_task`,
      send_promo: `/projects/${programId}/${workletSpecUri}/send_promo_task`,
      app: `/app/${SALES_APP_ID}`,
    };

    switch (taskId) {
      case SEND_STA_TASK_ID.send_link_and_promo_task:
        return [
          'Place the snippet above on the conversion page of your website.',
          [
            'If you don’t already have a “test member” created in Aspire, add yourself as a “test member” by going to the “',
            <Link key="membersList" to={Links.members_list} target="_blank">Members List</Link>,
            '” and clicking the “person icon” in the top right corner of that page.',
          ],
          'Once created, select yourself from the Member List and click the “Add to Project” button above the table to add yourself to this project.',
          [
            'Next go to the first stage in the project to find yourself and use the “actions icon” above the table to move yourself to the “',
            <Link key="generateLink" to={Links.generate_link} target="_blank">Generate Tracking Links</Link>,
            '” stage of this project.',
          ],
          'Generate a link for yourself by selecting yourself from the table in that stage and then click the “Generate Tracking Links” button above the table. Note: Make sure the active date for this offer includes today’s date with a start time before your test will occur so this test will succeed.',
          [
            'Once you have generated a link for yourself, you will automatically be moved to the “',
            <Link key="generatePromoCode" to={Links.generate_promo} target="_blank">Generate Promo Codes</Link>,
            '” Stage. Select yourself from the table and click the Generate Promo Codes button above the table. Follow the steps to create your test code. Note: Make sure the code’s Start Date includes today’s date with a start time before your test will occur so this test will succeed.',
          ],
          [
            'Once you have generated a Promo Code for yourself, you will automatically be moved to the “',
            <Link key="generateLink" to={Links.send_link_and_promo} target="_blank">Send Sales Tracking Items</Link>,
            '” Stage. From this point you can just try to make a test purchase on your store’s website by first copying and pasting your tracking link into a web browser. selecting a test item to purchase and then using your test code at checkout to see if it works.',
          ],
          [
            'If you decide to make a test purchase versus just checking if your promo code works and the link takes you to the correct page, you will see the results of that test purchase that reflected in the Aspire Sales Tracking App ',
            <Link key="generateLink" to={Links.app} target="_blank">here</Link>,
            '.',
          ],
        ];
      case SEND_STA_TASK_ID.send_link_task:
        return [
          'Place the snippet above on the conversion page of your website.',
          [
            'If you don’t already have a “test member” created in Aspire, add yourself as a “test member” by going to the “',
            <Link key="membersList" to={Links.members_list} target="_blank">Members List</Link>,
            '” and clicking the “person icon” in the top right corner of that page.',
          ],
          'Once created, select yourself from the Member List and click the “Add to Project” button above the table to add yourself to this project.',
          [
            'Next go to the first stage in the project to find yourself and use the “actions icon” above the table to move yourself to the “',
            <Link key="generateLink" to={Links.generate_link} target="_blank">Generate Tracking Links</Link>,
            '” stage of this project.',
          ],
          'Generate a link for yourself by selecting yourself from the table in that stage and then click the “Generate Tracking Links” button above the table. Note: Make sure the active date for this offer includes today’s date with a start time before your test will occur so this test will succeed.',
          [
            'Once you have generated a link for yourself, you will appear in the “',
            <Link key="sendLink" to={Links.send_link} target="_blank">Send Tracking Links</Link>,
            '” Stage. From this point you can just try to make a test purchase on your store’s website to confirm the link works. You can simply copy and paste the link into your browser and if your snippet is already placed on your checkout page, you will be able to make a test purchase that reflects the purchase in the Aspire Sales Tracking App ',
            <Link key="linksApp" to={Links.app} target="_blank">here</Link>,
            '.',
          ],
        ];
      case SEND_STA_TASK_ID.send_promo_task:
        return [
          [
            'If you don’t already have a “test member” created in Aspire, add yourself as a “test member” by going to the “',
            <Link key="linksApp" to={Links.members_list} target="_blank">Members List</Link>,
            '” and clicking the “person icon” in the top right corner of that page. You will probably want to fill out all the information needed to create the promo code using the logic you set to create the unique codes for this project to avoid having to update any information during the code creation stage.',
          ],
          'Once created, select yourself from the Member List and click the “Add to Project” button above the table to add yourself to the correct project.',
          [
            'Next go to the first stage in the project to find yourself and use the “actions icon” above the table to move yourself to the “',
            <Link key="linksApp" to={Links.generate_promo} target="_blank">Generate Promo Codes</Link>,
            '” stage of this project.',
          ],
          'Generate a code for yourself by selecting yourself from the table in that stage and then click the “Generate Promo Codes” button above the table. Make sure the code’s Start Date includes today’s date with a start time before your test will occur so this test will succeed.',
          [
            'Once you have generated a Promo Code for yourself, you will appear in the “',
            <Link key="linksApp" to={Links.send_promo} target="_blank">Send Promo Codes</Link>,
            '” Stage. From this point you can just try to make a test purchase on your store’s website using the code to confirm the code works. You can simply see if adding the code shows a discount was applied if you don’t want to make a test sale.',
          ],
          [
            'If you choose to actually make a sale to see what happens in Aspire, make the test sale then go to the Offer page of this project’s Sales Tracking Promo Code Offer in the ',
            <Link key="linksApp" to={Links.app} target="_blank">Sales Tracking App</Link>,
            ' to see if sales have been attributed to the offer you created for this project as well as the test member you made.',
          ],
        ];
      default:
        return [];
    }
  }, [programId, taskId, workletSpecUri]);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.contentTop}>
          <div className={styles.headline6}>
            Before you send out sales tracking
            {' '}
            {text}
            , have you made any test sales?
          </div>
          <div>
            Confirm that you have done the instructions below before you send members any sales tracking items.
          </div>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.subtitle1}>
            How to test if your
            {' '}
            {taskId === SEND_STA_TASK_ID.send_link_and_promo_task ? 'sales tracking links and promo codes' : text}
            {' '}
            are
            working.
          </div>
          <br />
          <ol className={styles.list}>
            {content.map((text, index) => (
              <li key={index.toString()}>{text}</li>
            ))}
          </ol>
        </div>
      </div>

      <div className={styles.footer}>
        <Space align="center" size="middle">
          <Button label="Cancel" onClick={onCancel} theme="light" className={styles.button} />
          <Button
            label="Yes, Compose Message"
            icon={<ArrowIosRightOutlined />}
            iconSide="right"
            onClick={onNext}
            theme="primary"
            className={styles.button}
            round={false}
          />
        </Space>
      </div>
    </>
  );
};
