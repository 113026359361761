import * as React from 'react';
import cn from 'classnames';

import {
 Typography, Divider, Space, Badge,
} from '@revfluence/fresh';
import { ScaleBalancedIcon } from '@revfluence/fresh-icons/regular/esm';

import { composeImpactValue } from '@frontend/applications/ReportsApp/utils/reportingFunctions';
import Statistics from '../../../../../components/Statistics';

import styles from '../TabContent.module.scss';
import { IInvestmentValues } from '../../../Reporting';

const { Title, Text } = Typography;

interface IProps {
  content: number;
  engagements: number;
  impactValue: number;
  impressions: number;
  investmentValues: IInvestmentValues;
  sales: number;
  total: number;
}

const getValue = (n?: number) => (n ? n.toFixed(2) : '-');

const Impact = ({
  content,
  engagements,
  impactValue,
  impressions,
  investmentValues: {
    payments,
    productCost,
    total: investments,
  },
  sales,
  total,
}: IProps) => (
  <Space direction="vertical" className={cn(styles.TabContent)} size="small">
    <Title level={4}>Impact Value</Title>
    <Title level={5}>
      <ScaleBalancedIcon style={{ marginRight: '8px' }} />
      How it's calculated
    </Title>
    <Divider />
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.blue)} />
        Content
      </Text>
      <Statistics value={getValue(content)} prefix={content ? '$' : ''} size="default" />
    </Space>
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.plum)} />
        Awareness
      </Text>
      <Statistics value={getValue(impressions)} prefix={impressions ? '$' : ''} size="default" />
    </Space>
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.orange)} />
        Engagements
      </Text>
      <Statistics value={getValue(engagements)} prefix={engagements ? '$' : ''} size="default" />
    </Space>
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.green)} />
        Sales
      </Text>
      <Statistics value={getValue(sales)} prefix={sales ? '$' : ''} size="default" />
    </Space>
    <Space>
      <Text type="secondary" weight="semibold">
        Total Value
      </Text>
      <Statistics value={getValue(total)} prefix={total ? '$' : ''} size="default" />
    </Space>
    <Divider />
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.yellow)} />
        Investment
      </Text>
    </Space>
    <Space>
      <Text type="secondary" weight="regular">
        Terms Agreements
      </Text>
      <span>
        <Statistics value={getValue(payments)} prefix={payments ? '$' : ''} size="default" />
      </span>
    </Space>
    <Space>
      <Text type="secondary" weight="regular">
        Product Cost
      </Text>
      <span>
        <Statistics value={getValue(productCost)} prefix={productCost ? '$' : ''} size="default" />
      </span>
    </Space>
    <Space>
      <Text type="secondary" weight="semibold">
        Total Investment
      </Text>
      <span>
        (
        <Statistics value={getValue(investments)} prefix={investments ? '$' : ''} size="default" />
        )
      </span>
    </Space>
    <Divider />
    <Space>
      <Title level={5}>Impact Value</Title>
      <Title level={5}>
        <Statistics
          value={composeImpactValue(impactValue, 2)}
          suffix="x"
          size="large"
          strong
        />
      </Title>
    </Space>
  </Space>
);

export default Impact;
