import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_WORK_ITEMS } from '@frontend/app/queries';
import {
  GetWorkItemsQuery,
  GetWorkItemsQueryVariables,
} from '../queries/types/GetWorkItemsQuery';

type IOptions = QueryHookOptions<GetWorkItemsQuery, GetWorkItemsQueryVariables>;

export const useGetWorkItemsQuery = (options: IOptions = {}) => (
  useQuery<GetWorkItemsQuery, GetWorkItemsQueryVariables>(GET_WORK_ITEMS, options)
);
