import gql from 'graphql-tag';

export const VALIDATE_ADDRESSES_MUTATION = gql`
  mutation ValidateAddresses($addresses: [AddressInput!]!) {
    bulkValidateAddress(addresses: $addresses) {
      memberName
      memberId
      messages {
        message
        type
        detailCode
      }
      status
      originalAddress {
        address1
        address2
        province
        city
        zip
        country
      }
      matchedAddress {
        address1
        address2
        city
        province
        zip
        country
      }
    }
  }
`;
