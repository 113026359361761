import * as React from 'react';
import { isEmpty, first } from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { LoadingOutlined } from '@ant-design/icons';
import { TOfferMember, OfferMemberStatus } from './types';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { isOfferShopify } from '../MembersWizard/utils';

import styles from './PendingMembers.scss';

export enum PendingAction {
  Deactivating = 'Deactivating',
  Generating = 'Generating',
  Mixed = 'Working on',
  Refreshing = 'Refreshing',
}

interface IProps {
  pendingMembers: ReadonlyArray<TOfferMember>;
  offerSource: OFFER_SOURCE;
}

const getPendingAction = (members: ReadonlyArray<TOfferMember>): PendingAction => {
  if (isEmpty(members)) {
    return PendingAction.Mixed;
  }

  const memberStatus = first(members).status;
  for (let i = 1; i < members.length; i++) {
    if (members[i].status !== memberStatus) {
      return PendingAction.Mixed;
    }
  }

  switch (memberStatus) {
    case OfferMemberStatus.PENDING:
      return PendingAction.Generating;
    case OfferMemberStatus.PENDING_DELETION:
      return PendingAction.Deactivating;
    case OfferMemberStatus.PENDING_UPDATE:
      return PendingAction.Refreshing;
    default:
      return PendingAction.Mixed;
  }
};

export const PendingMembers: React.FC<Readonly<IProps>> = (props) => {
  const {
    pendingMembers,
    offerSource,
  } = props;

  if (isEmpty(pendingMembers)) {
    return null;
  }
  const action = getPendingAction(pendingMembers);
  const count = pendingMembers.length;
  const type = isOfferShopify(offerSource) ? 'codes' : 'links';
  return (
    <Notice
      type="info"
      className={styles.PendingMembers}
      message={(
        <>
          <LoadingOutlined className={styles.icon} />
          {action}
          {' '}
          {type}
          {' '}
          for
          {' '}
          {count.toLocaleString()}
          {' '}
          member
          {count === 1 ? '' : 's'}
          &hellip;
        </>
      )}
    />
  );
};
