import { ChartLineUpIcon, CommentDollarIcon } from '@revfluence/fresh-icons/regular/esm';
import * as React from 'react';
import Card from '../components/Card';
import MetricsSecondary, { IMetricsProps } from '../pages/Reporting/components/MetricsSecondary/MetricsSecondary';
import MetricsTabs, { MetricsTabsProps } from '../pages/Reporting/components/MetricsTabs';
import { HowItsCalculatedLoading, MetricsSecondaryLoading, MetricsTabsLoading } from '../pages/Reporting/components/PageLoading/PageLoading';
import { HowItsCalculated, THowItsCalculatedProps } from '../pages/Reporting/Reporting';

interface IUseHowItsCalculated extends THowItsCalculatedProps {
  loading: boolean;
}

export const useHowItsCalculated = ({ loading, ...props }: IUseHowItsCalculated) => {
  if (loading) {
    return <HowItsCalculatedLoading />;
  }

  return <HowItsCalculated {...props} />;
};

interface IUseMetricsSecondary extends IMetricsProps {
  loading: boolean,
  cardSubtitle: string;
}

export const useMetricsSecondary = ({ loading, cardSubtitle, ...props }: IUseMetricsSecondary) => {
  if (loading) {
    return <MetricsSecondaryLoading />;
  }

  return (
    <Card title="Trends" subtitle={cardSubtitle} icon={<ChartLineUpIcon />} dark>
      <MetricsSecondary {...props} />
    </Card>
  );
};

interface IUseMetricsTabs extends MetricsTabsProps {
  loading: boolean;
  cardSubtitle: string;
}

export const useMetricsTabs = ({ loading, cardSubtitle, ...props }: IUseMetricsTabs) => {
  if (loading) {
    return <MetricsTabsLoading />;
  }

  return (
    <Card title="Your Impact" subtitle={cardSubtitle} icon={<CommentDollarIcon />}>
      <MetricsTabs {...props} />
    </Card>
  );
};
