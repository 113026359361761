/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_7a55u_157 {
  display: flex;
}

._justify-content-space-between_7a55u_161 {
  justify-content: space-between;
}

._tabular-nums_7a55u_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_7a55u_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TableHeader_7a55u_178 ._count_7a55u_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._TableHeader_7a55u_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._TableHeader_7a55u_178 ._count_7a55u_178 {
  margin-right: 1rem;
}
._TableHeader_7a55u_178 ._buttonToolbar_7a55u_196 {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  /* fix tooltips on disabled buttons */
}
._TableHeader_7a55u_178 ._buttonToolbar_7a55u_196 .ant-btn {
  align-items: center;
  display: flex;
  height: 2.25rem;
  justify-content: center;
  min-width: 2.25rem;
}
._TableHeader_7a55u_178 ._buttonToolbar_7a55u_196 ._buttonWrapper_7a55u_210._disabled_7a55u_210 {
  cursor: not-allowed;
}
._TableHeader_7a55u_178 ._buttonToolbar_7a55u_196 ._buttonWrapper_7a55u_210 .ant-btn:disabled {
  pointer-events: none;
}