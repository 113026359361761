import * as React from 'react';
import { LeftOutlined } from '@ant-design/icons';

import { WarningIcon } from '@components';

import { Button } from '@affiliates/AspireUI';

import styles from './MembersWizard.scss';

interface IProps {
  onConfirm: () => void;
  onDismissConfirmation: () => void;
}

export const ConfirmClose: React.FC<Readonly<IProps>> = (props) => {
  const {
    onConfirm,
    onDismissConfirmation,
  } = props;
  return (
    <div className={styles.confirmClose}>
      <WarningIcon className={styles.icon} size={80} />
      <h2 className={styles.title}>
        Are you sure you want to cancel?
      </h2>
      <p>
        If you close now before finishing you will lose your progress.
      </p>
      <div className={styles.buttons}>
        <Button className={styles.wizardButton} onClick={onDismissConfirmation} type="primary">
          <LeftOutlined />
          Back
        </Button>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <Button className={styles.wizardButton} onClick={onConfirm} type={'danger' as any}>
          Discard Changes
        </Button>
      </div>
    </div>
  );
};

ConfirmClose.displayName = 'ConfirmClose';
