/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_r5whs_157 {
  display: flex;
}

._justify-content-space-between_r5whs_161 {
  justify-content: space-between;
}

._tabular-nums_r5whs_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_r5whs_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Drawer_r5whs_178 {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 1.875rem;
  background-color: #fff;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  will-change: margin-left;
}
._Drawer_r5whs_178 ._collapseBtn_r5whs_187 {
  position: absolute;
  top: 1.5rem;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  border: solid 0.0625rem #ececec;
  background-color: #fdfdfd;
  transform: translateX(-50%);
}
._Drawer_r5whs_178 ._collapseBtn_r5whs_187:active {
  background-color: #fdfdfd;
}