import * as React from 'react';
import { map } from 'lodash';

import { Input, SearchIcon } from '@components';
import { Select } from './Select';

import { IResource } from '../hooks';

interface IProps {
  resources: IResource[];
  setSelectedResouce;
  selectedResource: IResource;
  initialSearchValue: string;
  setSearchValue(value: string): void;
}

import styles from './SearchBar.scss';

export const SearchBarV2: React.FunctionComponent<IProps> = (props) => {
  const {
    resources, setSelectedResouce, selectedResource,
    initialSearchValue, setSearchValue,
  } = props;
  const options = map(resources, (resource) => ({ value: resource.id, label: resource.authProviderId }));
  return (
    <div className={styles.searchBar}>
      <Select
        options={options}
        className={styles.shopSelect}
        onChange={setSelectedResouce}
        selectedValue={selectedResource?.id}
      />
      <Input
        className={styles.searchField}
        buffer={600}
        defaultValue={initialSearchValue}
        onChange={(value) => setSearchValue(value)}
        placeholder="Search for products by title and SKU"
        icon={<SearchIcon className={styles.prefix} />}
      />
    </div>
  );
};
