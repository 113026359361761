import * as React from 'react';
import { useState } from 'react';

import { size } from 'lodash';

import { useMessagingContext } from '@frontend/hooks';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { NewContractFileSelector } from './NewContractFileSelector';
import { ContractSetup } from './ContractSetup';
import { BulkContractInvalidMembers } from './BulkContractInvalidMembers';

const NewBulkContract: React.FunctionComponent = () => {
  const { bulkActionParameters, workflowActionParameters, closeModal } = useApplication();

  const [fileUrl, setFileUrl] = useState<string>(null);
  const [templateId, setTemplateId] = useState<string>();
  const [membersValidated, setMembersValidated] = useState<boolean>(false);

  const {
    showMessage,
  } = useMessagingContext();

  const messageCount = size(bulkActionParameters?.memberIds)
  || bulkActionParameters?.memberCount
  || size(workflowActionParameters?.memberIds);

  const onContractSelected = (templateId?: string, fileUrl?: string) => {
    setFileUrl(fileUrl);
    setTemplateId(templateId);
  };

  const onSendContractSuccess = (count: number) => {
    showMessage({
      type: 'info',
      content: `Sending ${count} contracts. This may take a few minutes.`,
    });
    closeModal();
  };

  const onSendSingleContractSuccess = () => {
    showMessage({
      type: 'info',
      content: 'Your contract has been successfully sent.',
    });
    closeModal();
  };

  const onValidationSuccess = () => {
    setMembersValidated(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contractParams: any = {};
  return (
    <div>
      {!membersValidated
        ? (<BulkContractInvalidMembers onValidationSuccess={onValidationSuccess} />)
        : (
          <>
            {(templateId || fileUrl)
              ? (
                <ContractSetup
                  {...contractParams}
                  fileUrl={fileUrl}
                  onBulkSendSuccess={onSendContractSuccess}
                  onSendSuccess={onSendSingleContractSuccess}
                  memberCount={messageCount}
                  memberIds={bulkActionParameters?.memberIds || workflowActionParameters?.memberIds}
                  memberQueryJson={bulkActionParameters?.memberQueryJson}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  workItemIds={(workflowActionParameters as any)?.workItemIds}
                  programId={workflowActionParameters?.programId}
                  templateId={templateId}
                />
              )
              : (<NewContractFileSelector onSelect={onContractSelected} />)}
          </>
        )}
    </div>
  );
};

export default NewBulkContract;
