import * as React from 'react';
import { Button } from '@revfluence/fresh';
import { ArrowsRotateIcon } from '@revfluence/fresh-icons/solid/esm';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { useResourceContext } from '@frontend/app/context';
import styles from './KlaviyoSettingsPage.scss';

interface IProps {
  type: ResourceType;
}

const KlaviyoSettingsPageEmpty: React.FC<IProps> = React.memo((props) => {
  const { type } = props;
  const { addAccount } = useResourceContext();
  return (
    <>
      <div className={styles.emptyContent}>
        <ArrowsRotateIcon style={{ fontSize: '80px', marginBottom: '36px', color: '#f0f0f0' }} />
        <div className={styles.title}>Connect a Klaviyo account</div>
        <div className={styles.text}>Easily sync member data to you Klaviyo account to keep information up-to-date.</div>
        <Button type="primary" onClick={() => addAccount(type, true)}>
          Connect
        </Button>
      </div>
    </>
  );
});

KlaviyoSettingsPageEmpty.displayName = 'KlaviyoSettingsPageEmpty';

export default KlaviyoSettingsPageEmpty;
