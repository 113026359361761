import React, {
    createContext, useContext, useReducer, useMemo, useCallback,
} from 'react';
import { TBudgetAccount, TBudgetAccountDistribution } from '@frontend/applications/PaymentsApp/types';
import { BudgetGranularity, FiscalYearBudgetBreakdown } from '../types/Budget';
import { BudgetActionMode } from '../containers/Projects/BudgetPage/types/IBudget';

export interface ProjectBudgetContextProps {
    clientId: string,
    projectId: string,
    showModal: boolean,
    showDrawer: boolean,
    fiscalYearBudgetBreakDowns: FiscalYearBudgetBreakdown[],
    budgetAccounts: TBudgetAccount[],
    budgetAccountDistribution: TBudgetAccountDistribution[],
    budgetAccountDistributionConnectedAccount: TBudgetAccountDistribution[],
    granularity: BudgetGranularity,
    selectedBudgetId?: number,
    loading: boolean,
    mode: BudgetActionMode,
    setShowModal: (isOpen: boolean) => void;
    setShowDrawer: (isOpen: boolean) => void;
    setBudgetGranularity: (granularity: BudgetGranularity) => void;
    setBudgetAccounts: (budgetAccounts: TBudgetAccount[]) => void;
    selectBudgetId: (id: number) => void;
    setLoading: (isLoading: boolean) => void;
    setBudgetAccountDistribution: (budgetAccountDistribution: TBudgetAccountDistribution[]) => void;
    setBudgetAccountDistributionConnectedAccount: (budgetAccountDistribution: TBudgetAccountDistribution[]) => void;
    setFiscalYearBudgetBreakDowns: (fiscalYearBudgetBreakDowns: FiscalYearBudgetBreakdown[]) => void;
    setMode: (mode: BudgetActionMode) => void;
    resetBudgetAccount: () => void;
}
export type ProjectBudgetContextState = Omit<ProjectBudgetContextProps,
    'setShowModal' | 'setShowDrawer' | 'setBudgetGranularity' | 'setBudgetAccounts' | 'selectBudgetId' | 'setLoading' | 'setBudgetAccountDistribution' | 'setFiscalYearBudgetBreakDowns' | 'setMode' | 'resetBudgetAccount' | 'setBudgetAccountDistributionConnectedAccount'>;

const defaultState: ProjectBudgetContextState = {
    clientId: '',
    projectId: '',
    showModal: false,
    showDrawer: false,
    fiscalYearBudgetBreakDowns: [],
    budgetAccounts: [],
    budgetAccountDistribution: [],
    selectedBudgetId: null,
    loading: false,
    granularity: BudgetGranularity.YEARLY,
    mode: BudgetActionMode.CREATE,
    budgetAccountDistributionConnectedAccount: [],
};

export const ProjectBudgetContext = createContext<ProjectBudgetContextProps>({
    ...defaultState,
    setShowModal: () => { },
    setShowDrawer: () => { },
    setBudgetGranularity: () => { },
    setBudgetAccounts: () => { },
    selectBudgetId: () => { },
    setLoading: () => { },
    setBudgetAccountDistribution: () => { },
    setFiscalYearBudgetBreakDowns: () => { },
    setMode: () => { },
    resetBudgetAccount: () => { },
    setBudgetAccountDistributionConnectedAccount: () => { },
});

export enum ActionType {
    ToggleBudgetModal = 'TOGGLE_BUDGET_MODAL',
    ToggleBudgetDrawer = 'TOGGLE_BUDGET_DRAWER',
    SelectBudgetGranularity = 'SELECT_BUDGET_GRANUARITY',
    SetBudgetAccounts = 'SET_BUDGET_ACCOUNTS',
    SelectBudgetId = 'SET_BUDGET_ID',
    SetLoading = 'SET_LOADING',
    SetBudgetAccountDistribution = 'SET_BUDGET_ACCOUNT_DISTRIBUTION',
    SetFiscalYearBudgetBreakDowns = 'SET_FISCAL_YEAR_BUDGET_BREAKDOWN',
    SetMode = 'SET_MODE',
    ResetBudgetAccount = 'RESET_BUDGET_ACCOUNT',
    SetBudgetAccountDistributionConnectedAccount = 'SET_BUDGET_ACCOUNT_DISTRIBUTION_CONNECTED_ACCOUNT',
}

export interface Action {
    type: ActionType;
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    payload: any;
}

const reducer = (state: ProjectBudgetContextState, action: Action): ProjectBudgetContextState => {
    switch (action.type) {
        case ActionType.ToggleBudgetModal:
            return {
                ...state,
                showModal: action.payload,
            };
        case ActionType.ToggleBudgetDrawer:
            return {
                ...state,
                showDrawer: action.payload,
            };
        case ActionType.SelectBudgetGranularity:
            return {
                ...state,
                granularity: action.payload,
            };
        case ActionType.SetBudgetAccounts:
            return {
                ...state,
                budgetAccounts: action.payload,
            };
        case ActionType.SelectBudgetId:
            return {
                ...state,
                selectedBudgetId: action.payload,
            };
        case ActionType.SetLoading:
            return {
                ...state,
                loading: action.payload,
            };
        case ActionType.SetBudgetAccountDistribution:
            return {
                ...state,
                budgetAccountDistribution: action.payload,
            };
        case ActionType.SetFiscalYearBudgetBreakDowns:
            return {
                ...state,
                fiscalYearBudgetBreakDowns: action.payload,
            };
        case ActionType.SetMode:
            return {
                ...state,
                mode: action.payload,
            };
        case ActionType.ResetBudgetAccount:
            return {
                ...state,
                mode: BudgetActionMode.CREATE,
                granularity: BudgetGranularity.YEARLY,
                fiscalYearBudgetBreakDowns: [],
                selectedBudgetId: null,
                budgetAccountDistribution: [],
                budgetAccountDistributionConnectedAccount: [],
            };
        case ActionType.SetBudgetAccountDistributionConnectedAccount:
            return {
                ...state,
                budgetAccountDistributionConnectedAccount: action.payload,
            };
        default:
            return state;
    }
};

export const ProjectBudgetProvider = ({ children, initialState = defaultState }: { children: React.ReactNode, initialState?: Partial<ProjectBudgetContextState> }) => {
    const [state, dispatch] = useReducer(reducer, { ...defaultState, ...initialState });

    const setShowModal = useCallback((isOpen: boolean) => {
        dispatch({ type: ActionType.ToggleBudgetModal, payload: isOpen });
    }, []);

    const setShowDrawer = useCallback((isOpen: boolean) => {
        dispatch({ type: ActionType.ToggleBudgetDrawer, payload: isOpen });
    }, []);

    const setBudgetGranularity = useCallback((granularity: BudgetGranularity) => {
        dispatch({ type: ActionType.SelectBudgetGranularity, payload: granularity });
    }, []);

    const setBudgetAccounts = useCallback((budgetAccounts: TBudgetAccount[]) => {
        dispatch({ type: ActionType.SetBudgetAccounts, payload: budgetAccounts });
    }, []);

    const selectBudgetId = useCallback((id: number) => {
        dispatch({ type: ActionType.SelectBudgetId, payload: id });
    }, []);

    const setLoading = useCallback((isLoading: boolean) => {
        dispatch({ type: ActionType.SetLoading, payload: isLoading });
    }, []);

    const setBudgetAccountDistribution = useCallback((budgetAccountDistribution: TBudgetAccountDistribution[]) => {
        dispatch({ type: ActionType.SetBudgetAccountDistribution, payload: budgetAccountDistribution });
    }, []);

    const setFiscalYearBudgetBreakDowns = useCallback((fiscalYearBudgetBreakDowns: FiscalYearBudgetBreakdown[]) => {
        dispatch({ type: ActionType.SetFiscalYearBudgetBreakDowns, payload: fiscalYearBudgetBreakDowns });
    }, []);

    const setMode = useCallback((mode: BudgetActionMode) => {
        dispatch({ type: ActionType.SetMode, payload: mode });
    }, []);

    const resetBudgetAccount = useCallback(() => {
        dispatch({ type: ActionType.ResetBudgetAccount, payload: null });
    }, []);

    const setBudgetAccountDistributionConnectedAccount = useCallback((budgetAccountDistribution: TBudgetAccountDistribution[]) => {
        dispatch({ type: ActionType.SetBudgetAccountDistributionConnectedAccount, payload: budgetAccountDistribution });
    }, []);

    const memoizedValue = useMemo(() => ({
        ...state,
        setShowModal,
        setShowDrawer,
        setBudgetGranularity,
        setBudgetAccounts,
        selectBudgetId,
        setLoading,
        setBudgetAccountDistribution,
        setFiscalYearBudgetBreakDowns,
        setMode,
        resetBudgetAccount,
        setBudgetAccountDistributionConnectedAccount,
    }), [
        state,
        setShowModal,
        setShowDrawer,
        setBudgetGranularity,
        setBudgetAccounts,
        selectBudgetId,
        setLoading,
        setBudgetAccountDistribution,
        setFiscalYearBudgetBreakDowns,
        setMode,
        resetBudgetAccount,
        setBudgetAccountDistributionConnectedAccount,
    ]);

    return (
      <ProjectBudgetContext.Provider value={memoizedValue}>
        {children}
      </ProjectBudgetContext.Provider>
    );
};

export const useProjectBudgetContext = () => useContext(ProjectBudgetContext);
