import * as React from 'react';
import { isEmpty, toString } from 'lodash';
import moment from 'moment';
import { LoadSpinner, IRowData } from '@components';

import { dollarsForCentsValue, statusForPayment } from '@frontend/applications/PaymentsApp/constants';
import { useFetchPaymentsData } from '@frontend/applications/PaymentsApp/useFetchPayments';
import { useFetchPaymentGroupData } from '@frontend/applications/PaymentsApp/useFetchPaymentGroup';
import { PaymentGroupInvoice as PaymentsGroupInvoiceComponent } from '@frontend/applications/PaymentsApp/BulkPaymentsApp/components';

import { useState, useEffect } from 'react';

export interface IPaymentGroupInvoiceProps {
  clientId: string;
  paymentGroupId: number;
  onClose: () => void;
}

export const PaymentGroupInvoice: React.FC<Readonly<IPaymentGroupInvoiceProps>> = (props) => {
  const [tableData, setTableData] = useState<IRowData[]>();
  const [amountPaid, setAmountPaid] = useState<number>();
  const [amountDue, setAmountDue] = useState<number>();

  const {
    data: paymentGroup,
    loading: loadingPaymentGroup,
  } = useFetchPaymentGroupData(props.paymentGroupId);

  const params = {
    client_id: props.clientId,
    payment_group_id: props.paymentGroupId.toString(),
  };
  const {
    data: paymentsData,
    loading: loadingPaymentsData,
  } = useFetchPaymentsData(params);

  useEffect(() => {
    if (!loadingPaymentsData && !isEmpty(paymentsData)) {
      const generatedTableData: IRowData[] = [];
      let accAmountPaid = 0;
      let accAmountDue = 0;

      for (let i = 0; i < paymentsData.data.length; i++) {
        const payment = paymentsData.data[i];
        const dollarAmountDue = payment.amount_due ? dollarsForCentsValue(payment.amount_due) : 0;
        const dollarAmountPaid = payment.amount_intended_for_publisher ? dollarsForCentsValue(payment.amount_intended_for_publisher) : 0;

        if (!payment.canceled) {
          accAmountDue += dollarAmountDue;
          accAmountPaid += dollarAmountPaid;
        }

        const generatedData = {
          ...payment.metadata?.columns,
          memberName: payment.payee_name,
          amountDue: dollarAmountDue.toFixed(2),
          amountPaid: dollarAmountPaid.toFixed(2),
          status: statusForPayment(payment),
          paypalEmail: payment.paypal,
          createdDate: moment(payment.created_ts * 1000).format('YYYY-MM-DD'),
        };

        generatedTableData.push({
          ...generatedData,
          id: toString(i),
          _raw: payment,
        });
      }

      setTableData(generatedTableData);
      setAmountDue(accAmountDue);
      setAmountPaid(accAmountPaid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPaymentsData, paymentsData, statusForPayment, setTableData, setAmountDue, setAmountPaid]);

  const isLoading = loadingPaymentsData || loadingPaymentGroup || isEmpty(tableData) || isEmpty(paymentGroup);

  return (
    <>
      {isLoading
        ? <LoadSpinner />
        : (
          <PaymentsGroupInvoiceComponent
            paymentGroup={paymentGroup}
            amountPaid={amountPaid}
            amountDue={amountDue}
            tableData={tableData}
            onCancel={props.onClose}
          />
)}
    </>
  );
};
