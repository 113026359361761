import * as React from 'react';
import cx from 'classnames';

import { map } from 'lodash';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { Button } from '@components';
import { SocialAccountCell } from './SocialAccountCell';
import { ISocialAccount } from '../useFetchSocialAccountData';

interface IProps {
  accounts: ISocialAccount[];
  oauthEndpoint: string;
  showNav: boolean;
  className: string;
}

import styles from './SocialAccountList.scss';

export const SocialAccountList: React.FunctionComponent<IProps> = (props) => {
  const { accounts, oauthEndpoint } = props;
  const addEvent = useEventContext();
  const oauthRedirect = () => {
    addEvent(EventName.OAuthGrantStart, { app: 'social_post' });
    window?.localStorage?.removeItem('ig_connect_success_track');
    window.location.replace(oauthEndpoint);
  };

  return (
    <div className={styles.SocialAccountList}>
      <div className={styles.SettingsLayout}>
        {props.showNav && (
          <div className={styles.setting_nav}>
            <ul>
              <li>
                <a href="#" className={styles.settings_nav_active}>
                  My Instagram Accounts
                </a>
              </li>
            </ul>
          </div>
        )}
        <section className={cx(styles.setting_detail, props.className)}>
          <h1>Your Instagram Accounts</h1>
          <p>Listening for posts mentioning the following Instagram accounts:</p>
          {map(accounts, (value, key) => (
            <SocialAccountCell key={key} oauthEndpoint={oauthEndpoint} account={value} />
          ))}
          <Button label="Add Social Account" className={styles.AddButton} theme="light" onClick={oauthRedirect} />
        </section>
      </div>
    </div>
  );
};
