import * as React from 'react';

import cx from 'classnames';
import Bluebird, { Promise } from 'bluebird';
import {
  isUndefined,
  map,
  identity,
  filter,
  debounce,
  isObject,
  toLower,
  includes,
  chain,
  trim,
  Dictionary,
} from 'lodash';

import { Popover } from '@revfluence/fresh';

import { SpinnerIcon } from '@components';
import { LoadSpinner } from '@components';

const {
 useState, useRef, useEffect, useMemo, useCallback, useReducer,
} = React;

import styles from './TokenInput.scss';

Promise.config({
  cancellation: true,
  warnings: false,
});

interface ITokenObject {
  label: string;
  value: string | number | boolean;
}
export type TToken = ITokenObject | string;
type TOptionsForValueCallback = (value: string) => string | number | boolean | Promise<Dictionary<TToken>>;

export interface ITokenInputProps {
  className?: string;
  onChange(newTokens: TToken[]);
  onChangeValue?(newValue: string);

  value?: string;
  tokens?: TToken[];
  defaultTokens?: TToken[];
  placeholder?: string;
  maxOptionsToShow?: number;
  options?: TToken[];
  optionsForValue?: TOptionsForValueCallback;
  debounceWait?: number;
  inputRef?: React.Ref<HTMLInputElement>;
  allowTokenCreation?: boolean;
  hidePopover?: boolean;
  emptyOptionsMessage?: JSX.Element | string;
  hideOptionsOnEmptyInput?: boolean;
  transformInputIntoToken?(inputValue: string): TToken;
  icon?: JSX.Element;
  maxTokensAllowed?: number;
  isSingle?: boolean;
}

const ENTER_KEY = 'Enter';

enum TokenInputActionType {
  StartLoadingOptions = 'startLoadingOptions',
  FinishedLoadingOptions = 'finishedLoadingOptions',
  SetOptions = 'setOptions',
}

interface IState {
  isLoading: boolean;
  options: TToken[];
}

interface IAction {
  type: TokenInputActionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

const getLabelFromToken = (token: TToken): string => (isObject(token) ? token.label : token);

const getLowerCaseToken = (token: TToken) => toLower(getLabelFromToken(token));

const mapTokensToLowerCase = (tokens: TToken[]) => map(tokens, getLowerCaseToken);

function reducer(state: IState, action: IAction) {
  switch (action.type) {
    case TokenInputActionType.StartLoadingOptions:
      return { ...state, isLoading: true };
    case TokenInputActionType.FinishedLoadingOptions:
      return { ...state, isLoading: false, options: action.payload };
    case TokenInputActionType.SetOptions:
      return { ...state, options: action.payload };
    default:
      throw new Error();
  }
}

const TokenInput: React.FunctionComponent<ITokenInputProps> = (props) => {
  const runningPromise: React.MutableRefObject<Bluebird<TToken[]>> = useRef(null);
  const [localValue, setLocalValue] = useState('');
  const [localTokens, setLocalTokens] = useState(props.defaultTokens);

  const inputWrapperRef = useRef<HTMLDivElement>();

  // Required to force re-render when tokens change
  const [, updateState] = useState();

  const isInputControlled = !isUndefined(props.value);
  const isControlled = !isUndefined(props.tokens);

  const tokens = isControlled ? props.tokens : localTokens;
  const value = isInputControlled ? props.value : localValue;
  const lowerCaseTokens = useMemo(() => mapTokensToLowerCase(tokens), [tokens]);

  const initialState = {
    isLoading: false,
    options: props.options,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const defaultOptionsForInputValue = (val: string) => {
    if (val) {
      const lowerVal = toLower(val);
      return filter(props.options, (option) => includes(getLowerCaseToken(option), lowerVal));
    } else {
      return props.options;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setOptionsForInputValue = useCallback(
    debounce(async (val: string) => {
      if (props.optionsForValue) {
        const timeout = setTimeout(
          () => dispatch({ type: TokenInputActionType.StartLoadingOptions }),
          0,
        );
        const promise = Promise.resolve(props.optionsForValue(val));
        runningPromise.current = promise;
        const result = await promise;
        runningPromise.current = null;

        // Don't show loading spinner unless this is a longer operation.
        clearTimeout(timeout);
        dispatch({ type: TokenInputActionType.FinishedLoadingOptions, payload: result });
      } else {
        const result = defaultOptionsForInputValue(val);
        dispatch({ type: TokenInputActionType.SetOptions, payload: result });
      }
    }, props.debounceWait),
    [props.optionsForValue, props.debounceWait, props.options],
  );

  useEffect(() => {
    setOptionsForInputValue(value);

    if (!value) {
      setOptionsForInputValue.flush();
    }
  }, [setOptionsForInputValue, value]);

  useEffect(() => {
    dispatch({ type: TokenInputActionType.SetOptions, payload: props.options });
  }, [props.options]);

  const handleChangeValue = (newValue: string) => {
    if (!isInputControlled) {
      setLocalValue(newValue);
    }
    if (props.onChangeValue) {
      props.onChangeValue(newValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeValue(e.target.value);
  };

  useEffect(() => {
    // Force a re-render to move Popover.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateState({} as any);
  }, [tokens]);

  const handleDeleteToken = (tokenIndex: number) => {
    const newTokens = [...tokens];
    newTokens.splice(tokenIndex, 1);
    props.onChange(newTokens);

    if (!isControlled) {
      setLocalTokens(newTokens);
    }
  };

  const handleAddToken = (newToken: TToken) => {
    if (!newToken) {
      return;
    }

    if (props.maxTokensAllowed && props.tokens.length >= props.maxTokensAllowed) {
      return;
    }

    const lowerCaseToken = getLowerCaseToken(newToken);

    if (!includes(lowerCaseTokens, lowerCaseToken)) {
      const newTokens = props.isSingle
        ? [newToken]
        : [...tokens, newToken];

      props.onChange(newTokens);

      if (!isControlled) {
        setLocalTokens(newTokens);
      }
    }

    handleChangeValue('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ENTER_KEY && props.allowTokenCreation) {
      const trimmedValue = trim(value);
      if (trimmedValue) {
        handleAddToken(props.transformInputIntoToken(trimmedValue));
      }
    }
  };

  const handleClickOption = (option: TToken, e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleAddToken(option);

    /** Force popover to hide on single select */
    if (props.isSingle) {
      inputWrapperRef.current.getElementsByTagName('input')[0].blur();
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const filterOptionsByCurrentTokens = useCallback(
    (options: TToken[]) => chain(options)
      .keyBy(getLowerCaseToken)
      .omit(lowerCaseTokens)
      .values()
      .value(),
    [lowerCaseTokens],
  );

  // `tokens` intentionally added as dependency
  const filteredOptionsByCurrentTokens = useMemo(
    () => filterOptionsByCurrentTokens(state.options),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.options, tokens, filterOptionsByCurrentTokens],
  );

  const renderPopoverContent = () => {
    if (state.isLoading) {
      return <LoadSpinner />;
    } else {
      const displayedOptions = filteredOptionsByCurrentTokens.slice(0, props.maxOptionsToShow);

      if (displayedOptions.length > 0 && (!props.hideOptionsOnEmptyInput || value)) {
        return map(displayedOptions, (option, index) => (
          <div
            key={index}
            className={styles.option}
            onClick={(e) => handleClickOption(option, e)}
            onMouseDown={handleMouseDown}
          >
            {getLabelFromToken(option)}
          </div>
        ));
      } else {
        return <div className={styles.emptyOptions}>{props.emptyOptionsMessage}</div>;
      }
    }
  };

  return (
    <div className={cx(styles.TokenInput, props.className)}>
      <ul className={styles.tokens}>
        {map(tokens, (token, index) => (
          <li
            key={index}
            className={cx(
              styles.token,
              {
                [styles.single]: props.isSingle,
              },
            )}
          >
            <span>{getLabelFromToken(token)}</span>
            <span className={styles.close} onClick={() => handleDeleteToken(index)}>
              &times;
            </span>
          </li>
        ))}
      </ul>
      <Popover
        trigger="focus"
        className={styles.Popover}
        autoAdjustOverflow
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        content={(
          <div className={styles.options}>
            {renderPopoverContent()}
          </div>
        )}
      >
        {
          (!props.isSingle
            || !props.tokens?.length)
            && (
              <div
                className={styles.inputWrapper}
                ref={inputWrapperRef}
              >
                {props.icon ? <div className={styles.icon}>{props.icon}</div> : null}
                <input
                  ref={props.inputRef}
                  value={value}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  autoCapitalize="off"
                  autoCorrect="off"
                  autoComplete="off"
                  spellCheck={false}
                  className={styles.input}
                  placeholder={props.placeholder}
                />
                {state.isLoading ? <SpinnerIcon size={20} className={styles.spinner} /> : null}
              </div>
            )
        }
      </Popover>
    </div>
  );
};

TokenInput.defaultProps = {
  defaultTokens: [],
  options: [],
  placeholder: 'Enter a token',
  maxOptionsToShow: 5,
  debounceWait: 300,
  allowTokenCreation: true,
  hidePopover: false,
  emptyOptionsMessage: 'Press enter to create a new token',
  hideOptionsOnEmptyInput: false,
  transformInputIntoToken: identity,
};
TokenInput.displayName = 'TokenInput';

export { TokenInput };
