import 'css-chunk:src/applications/ReportsApp/pages/Reporting/components/TabContent/TabContent.module.scss';export default {
  "TabContent": "_TabContent_kn52w_1",
  "chartTitle": "_chartTitle_kn52w_23",
  "chartTimeLabel": "_chartTimeLabel_kn52w_28",
  "Badge": "_Badge_kn52w_33",
  "marginRight": "_marginRight_kn52w_41",
  "blue": "_blue_kn52w_45",
  "green": "_green_kn52w_49",
  "plum": "_plum_kn52w_53",
  "orange": "_orange_kn52w_57",
  "teal": "_teal_kn52w_61",
  "yellow": "_yellow_kn52w_65"
};