import * as React from 'react';

import {
  endOfMonth, endOfQuarter, endOfWeek, startOfMonth, startOfQuarter, startOfWeek, startOfYear, subMonths, subQuarters,
  subWeeks, subYears,
} from 'date-fns';
import {
  Row, Col, Space, Typography, Skeleton, useBreakpoint,
} from '@revfluence/fresh';
import { ChartBarIcon } from '@revfluence/fresh-icons/regular/esm';
import { getLastFullMonths } from '@frontend/applications/ReportsApp/containers/utils';
import { DateRangesLabel, DATE_RANGES, getDateRange } from '@frontend/app/containers/HomePageMetrics/components/DateSelect';

import styles from './Header.module.scss';

const { Title } = Typography;

export const getCompareDateRange = (value: string): [Date, Date] => {
  const today = new Date();
  switch (value) {
    case DATE_RANGES.ALL_TIME:
      return getLastFullMonths(2);
    case DATE_RANGES.THIS_WEEK:
      const endDay = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 });
      const startDay = startOfWeek(endDay, { weekStartsOn: 1 });
      return [startDay, endDay];
    case DATE_RANGES.LAST_WEEK: {
      const endDay = endOfWeek(subWeeks(today, 2), { weekStartsOn: 1 });
      const startDay = startOfWeek(endDay, { weekStartsOn: 1 });
      return [startDay, endDay];
    }
    case DATE_RANGES.LAST_MONTH: {
      const lastMonth = subMonths(today, 2);
      const endDay = endOfMonth(lastMonth);
      const startDay = startOfMonth(lastMonth);
      return [startDay, endDay];
    }
    case DATE_RANGES.MONTH_TO_DATE: {
      const lastMonth = subMonths(today, 1);
      const endDay = endOfMonth(lastMonth);
      const startDay = startOfMonth(lastMonth);
      return [startDay, endDay];
    }
    case DATE_RANGES.LAST_QUARTER: {
      const startDay = subQuarters(startOfQuarter(today), 2);
      const endDay = endOfQuarter(startDay);
      return [startDay, endDay];
    }
    case DATE_RANGES.QUARTER_TO_DATE: {
      const startDay = subQuarters(startOfQuarter(today), 1);
      const endDay = endOfQuarter(startDay);
      return [startDay, endDay];
    }
    case DATE_RANGES.LAST_6_MONTHS: {
      const endDay = subMonths(today, 6);
      const startDay = subMonths(endDay, 6);
      return [startDay, endDay];
    }
    case DATE_RANGES.YEAR_TO_DATE: {
      const endDay = startOfYear(today);
      const startDay = subYears(endDay, 1);
      return [startDay, endDay];
    }
    default:
      return getLastFullMonths(2);
  }
};

export const dateValues = [
  {
    label: DateRangesLabel.THIS_WEEK,
    value: {
      dateRange: getDateRange(DATE_RANGES.THIS_WEEK),
      compareRange: getCompareDateRange(DATE_RANGES.THIS_WEEK),
    },
  },
  {
    label: DateRangesLabel.LAST_WEEK,
    value: {
      dateRange: getDateRange(DATE_RANGES.LAST_WEEK),
      compareRange: getCompareDateRange(DATE_RANGES.LAST_WEEK),
    },
  },
  {
    label: DateRangesLabel.LAST_MONTH,
    value: {
      dateRange: getDateRange(DATE_RANGES.LAST_MONTH),
      compareRange: getCompareDateRange(DATE_RANGES.LAST_MONTH),
    },
  },
  {
    label: DateRangesLabel.MONTH_TO_DATE,
    value: {
      dateRange: getDateRange(DATE_RANGES.MONTH_TO_DATE),
      compareRange: getCompareDateRange(DATE_RANGES.MONTH_TO_DATE),
    },
  },
  {
    label: DateRangesLabel.LAST_QUARTER,
    value: {
      dateRange: getDateRange(DATE_RANGES.LAST_QUARTER),
      compareRange: getCompareDateRange(DATE_RANGES.LAST_QUARTER),
    },
  },
  {
    label: DateRangesLabel.QUARTER_TO_DATE,
    value: {
      dateRange: getDateRange(DATE_RANGES.QUARTER_TO_DATE),
      compareRange: getCompareDateRange(DATE_RANGES.QUARTER_TO_DATE),
    },
  },
  {
    label: DateRangesLabel.LAST_6_MONTHS,
    value: {
      dateRange: getDateRange(DATE_RANGES.LAST_6_MONTHS),
      compareRange: getCompareDateRange(DATE_RANGES.LAST_6_MONTHS),
    },
  },
  {
    label: DateRangesLabel.YEAR_TO_DATE,
    value: {
      dateRange: getDateRange(DATE_RANGES.YEAR_TO_DATE),
      compareRange: getCompareDateRange(DATE_RANGES.YEAR_TO_DATE),
    },
  },
  {
    label: DateRangesLabel.ALL_TIME,
    value: {
      dateRange: getDateRange(DATE_RANGES.ALL_TIME),
      compareRange: getCompareDateRange(DATE_RANGES.ALL_TIME),
    },
  },
];

export const Header = ({ Actions }: { Actions: React.ReactNode[] }) => {
  const screens = useBreakpoint();
  return (
    <Row className={styles.Header} gutter={0}>
      <Col>
        <span className={styles.icon}>
          <ChartBarIcon />
        </span>
      </Col>
      <Col flex={1}>
        <Title level={4} style={{ margin: 0 }}>
          Impact Dashboard
        </Title>
      </Col>
      <Col style={{ marginTop: screens.xs && 8 }}>
        <Space size={16}>
          {Actions}
        </Space>
      </Col>
    </Row>
  );
};

export const HeaderLoading = () => (
  <Header Actions={[
    <Skeleton.Button key="date-filter" active style={{ width: 100 }} />,
    <Skeleton.Button key="project-filter" active style={{ width: 100 }} />,
  ]}
  />
);

export default Header;
