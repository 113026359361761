import React from 'react';
import { map } from 'lodash';
import {
 Typography, Card,
} from '@revfluence/fresh';
import { SackDollarIcon, BuildingColumnsIcon } from '@revfluence/fresh-icons/regular/esm';
import { FormValues } from '../../BudgetAccountForm';

const { Title, Text } = Typography;

import styles from './budgetInfo.scss';

const renderChildBudgetIcon = () => (
  <div className={styles.iconContainer}>
    <SackDollarIcon className={styles.icon} />
  </div>
);

const renderMasterBudgetIcon = () => (
  <div className={styles.iconContainer}>
    <BuildingColumnsIcon className={styles.icon} />
  </div>
);

const renderFiscalYear = (fiscalYearValue) => {
  const { fiscalYear, totalAmount, availableAmount } = fiscalYearValue;
  const availableAmountAmountToDisplay = availableAmount ?? totalAmount;
  return (
    <div className={styles.details}>
      <div className={styles.detail}>
        <Text className={styles.detailLabel}>Fiscal Year</Text>
        <Title level={5} className={styles.detailValue}>{fiscalYear}</Title>
      </div>
      <div className={styles.detail}>
        <Text className={styles.detailLabel}>Total Budget</Text>
        <Title level={5} className={styles.detailValue}>
          {`$${totalAmount?.toLocaleString()}`}
        </Title>
      </div>
      <div className={styles.detail}>
        <Text className={styles.detailLabel}>Available</Text>
        <Title level={5} className={styles.detailValue}>{`$${availableAmountAmountToDisplay?.toLocaleString()}`}</Title>
      </div>
    </div>
  );
};

interface IProps {
  values: FormValues;
  isMasterBudget: boolean;
}

const BudgetInfo: React.FunctionComponent<IProps> = (props) => {
  const { values = {} as FormValues, isMasterBudget } = props;
  const { fiscalYearsBudget } = values;
  return (
    <Card bordered={false} className={styles.card}>
      <div className={styles.content}>
        {isMasterBudget ? renderMasterBudgetIcon() : renderChildBudgetIcon()}
        <div className={styles.titleSection}>
          <Title level={4} className={styles.title}>{values?.budgetName || 'No Budget Name'}</Title>
          <Text type="secondary">{values?.budgetDescription || 'No Description'}</Text>
        </div>
        <div className={styles.fiscalYearContainer}>
          {map(fiscalYearsBudget, renderFiscalYear)}
        </div>
      </div>
    </Card>
  );
};

export default BudgetInfo;
