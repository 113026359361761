import * as React from 'react';
import {
  Card,
  Col,
  Row,
  Statistic,
} from '@revfluence/fresh';

import { formatValue } from '@affiliates/utils';
import { IStatisticsCardData } from './types';

import styles from './StatisticsCard.scss';

interface IProps {
  data?: IStatisticsCardData[];
  className?: string;
  timeText?: string;
}

export const StatsCardPromosComponent: React.FC<Readonly<IProps>> = React.memo((props) => (
  <Card className={styles.StatsCardPromosCard} bodyStyle={{ padding: '16px' }}>
    <Row
      justify="space-between"
    >
      {props.data.map((item) => (
        <Col
          key={item.title}
          xs={6}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          className={styles.StatsColumn}
        >
          <Statistic
            prefix={item.prefix}
            suffix={item.suffix}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            reverse
            value={formatValue(item.format, item.value)}
            title={item.title}
            size="small"
          />
        </Col>
      ))}
    </Row>
  </Card>
));

StatsCardPromosComponent.displayName = 'StatisticsCard';
