import * as React from 'react';
import cx from 'classnames';
import { get } from 'lodash';

import { TNetworkIdentifier, useInviteContext } from '@components';

import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';
import { BlogContent } from './BlogContent';
import { FacebookContent } from './FacebookContent';
import { InstagramContent } from './InstagramContent';
import { PinterestContent } from './PinterestContent';
import { PlaceholderContent } from './PlaceholderContent';
import { TiktokContent } from './TiktokContent';
import { TwitterContent } from './TwitterContent';
import { YoutubeContent } from './YoutubeContent';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './Content.scss';

interface IProps {
  showPlaceholder?: boolean;
}

export const Content: React.FC<IProps> = (props) => {
  const {
    showPlaceholder = false,
  } = props;

  const {
    hasData,
    network,
  } = useSocialProfileContext();

  const {
    versionByNetwork,
  } = useInviteContext();

  const supportedNetworks: TNetworkIdentifier[] = [
    'blog',
    'facebook',
    'instagram',
    'pinterest',
    'tiktok',
    'twitter',
    'youtube',
  ];

  const renderContent = () => {
    if (!hasData || !supportedNetworks.includes(network) || showPlaceholder) {
      return <PlaceholderContent />;
    }

    const version = get(versionByNetwork, network) || CreatorSearchVersion.v1;

    switch (network) {
      case 'blog': return <BlogContent version={version} />;
      case 'facebook': return <FacebookContent version={version} />;
      case 'instagram': return <InstagramContent version={version} />;
      case 'pinterest': return <PinterestContent version={version} />;
      case 'tiktok': return <TiktokContent version={version} />;
      case 'twitter': return <TwitterContent version={version} />;
      case 'youtube': return <YoutubeContent version={version} />;
    }
  };

  return (
    <div
      className={cx(
        styles.Content,
        styles[supportedNetworks.includes(network) ? network : 'placeholder'],
      )}
    >
      {renderContent()}
    </div>
  );
};

Content.displayName = 'Content';
