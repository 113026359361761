import * as React from 'react';

import { Video } from '@components';
import { Content, IContentProps } from './Content';

import styles from './VideoContent.scss';

/**
 * @class
 * @extends {React.Component}
 */
export class VideoContent extends React.PureComponent<IContentProps> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { content, classNames } = this.props;

    return (
      <Content content={content} classNames={classNames.concat(styles.VideoContent)}>
        <Video classNames={[styles.video]} src={content.localSrc} showDuration />
      </Content>
    );
  }
}
