import * as React from 'react';
import cx from 'classnames';

import {
  Space,
  Typography,
  Button,
} from '@revfluence/fresh';

import { CheckIcon } from '@components';

import styles from './TermsCard.module.scss';

interface ITermsCardProps {
  termsTemplateId: number;
  title: string;
  description: string;
  selected: boolean;
  onSelectTemplate: (termsTemplateId: number) => void;
  onEditTemplate: (event: React.MouseEvent<HTMLElement>) => void;
}

const TermsCard: React.FC<ITermsCardProps> = React.memo(({
  termsTemplateId, title, description, selected, onSelectTemplate, onEditTemplate,
}) => (
  <Space
    direction="vertical"
    className={cx(
        styles.TermsCardContainer,
        { [styles.selected]: selected },
      )}
    onClick={() => onSelectTemplate(termsTemplateId)}
    data-testid={termsTemplateId}
  >
    <Space
      direction="horizontal"
      align="start"
      className={styles.TermsCardHeader}
    >
      <Space
        direction="horizontal"
        align="start"
      >
        {selected && <CheckIcon size={24} /> }
        <Typography.Title
          level={4}
          style={{ marginBottom: '0' }}
        >
          {title}
        </Typography.Title>
      </Space>
      <Button
        type="link"
        size="middle"
        disabled={!selected}
        onClick={onEditTemplate}
      >
        Edit template
      </Button>
    </Space>
    <Typography.Text>{description}</Typography.Text>
  </Space>
  ));

TermsCard.displayName = 'TermsCard';

export default TermsCard;
