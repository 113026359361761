import { useCallback } from 'react';
import {
  ITableColumnConfig,
} from '@components';
import { columnsInputFromColumnConfig } from '@frontend/app/utils/columns';
import {
  useSaveSegmentColumns,
} from '@frontend/app/hooks';

export const useColumnReorder = (segmentId: string | number, segmentType: 'custom' | 'predefined') => {
  const [saveSegmentColumns] = useSaveSegmentColumns();

  return useCallback(async (orderedColumns: ITableColumnConfig[]) => {
    if (!segmentId) {
      return;
    }

    setTimeout(() => {
      const customSegmentId = segmentType === 'custom' ? segmentId as number : null;
      const predefinedSegmentId = segmentType === 'predefined' ? segmentId as string : null;
      const columnsInput = columnsInputFromColumnConfig(orderedColumns);
      saveSegmentColumns({
        variables: {
          metadata: {
            segmentId: customSegmentId,
            predefinedSegmentId,
            columns: columnsInput,
          },
        },
      });
    }, 100);
  }, [saveSegmentColumns, segmentId, segmentType]);
};
