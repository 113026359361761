import {
  useCallback, useMemo,
} from 'react';

import {
  IState,
  TDispatch,
} from '../../types/state';
import { ActionTypes } from '../../types/actionTypes';
import { IProps as ReviewTableProps } from './ReviewTable';
import { OnUpdateColumnValue } from './types';

export const useGetReviewTableProps = (
  dispatch: TDispatch,
  collaborationDetails: IState['collaborationDetails'],
  contentGuidelines: IState['contentGuidelines'],
  members: IState['members'],
): ReviewTableProps => {
  const onMarkMembersDeselected = useCallback((memberIds: number[]) => {
    dispatch({
      memberIds,
      type: ActionTypes.DeselectMembers,
    });
  }, [dispatch]);
  const onMarkMembersSelected = useCallback((memberIds: number[]) => {
    dispatch({
      memberIds,
      type: ActionTypes.SelectMembers,
    });
  }, [dispatch]);
  const onUpdateColumnValue: OnUpdateColumnValue = useCallback(
    (memberId, field, value) => {
      dispatch({
        field,
        memberId,
        type: ActionTypes.UpdateMemberValue,
        value,
      });
    },
    [dispatch],
  );

  return useMemo((): ReviewTableProps => ({
    collaborationDetails,
    contentGuidelines,
    members,
    onMarkMembersDeselected,
    onMarkMembersSelected,
    onUpdateColumnValue,
  }), [
    collaborationDetails,
    contentGuidelines,
    members,
    onMarkMembersDeselected,
    onMarkMembersSelected,
    onUpdateColumnValue,
  ]);
};
