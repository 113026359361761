import * as React from 'react';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '@frontend/context/authContext';

import { Button } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';

import { ChevronRightIcon } from '@components';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context';
import { ProjectsRouteRoot } from '../../Projects/constants';
import { useRefetch } from '../OnboardingWizard/hooks/useRefetch';
import styles from './Welcome.scss';

const { useLayoutEffect } = React;

const logoSvg = require('@frontend/app/assets/svgs/logo.svg');
const welcomeIllustrationSvg = require('@frontend/app/assets/svgs/onboarding_welcome_illustration.svg');
const successIllustrationSvg = require('@frontend/app/assets/svgs/onboarding_success_illustration.svg');

const welcomePicture1Png = 'https://storage.googleapis.com/aspirex-static-files/onboarding/onboarding_welcome_picture1.png';
const welcomePicture2Png = 'https://storage.googleapis.com/aspirex-static-files/onboarding/onboarding_welcome_picture2.png';
const successCampaignsPng = 'https://storage.googleapis.com/aspirex-static-files/onboarding/onboarding_success_campaigns.png';

const Welcome: React.FC = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const { refetch } = useRefetch();

  const sharedButtonProps: BaseButtonProps = { type: 'primary', size: 'large', className: styles.ctaButton };
  const firstName = auth.user.name.split(' ')[0];
  const showSuccessPage = location.state === 'project_created_and_listed';
  const addEvent = useEventContext();

  useLayoutEffect(() => {
    addEvent(EventName.OnboardingFlowWelcome, {});
  }, [addEvent]);

  const onStart = () => {
    addEvent(EventName.OnboardingFlowObjective, {});
    history.push('/onboarding/steps');
  };

  const onContinue = async () => {
    await refetch();
    const params = new URLSearchParams(location.search);
    const projectId = params.get('projectId');
    addEvent(EventName.OnboardingFlowFinish, {});
    history.replace({
      pathname: `${ProjectsRouteRoot}/${projectId}/applicants`,
      search: `?${new URLSearchParams({
        segmentId: `new_applicants_${projectId}`,
        disableRedirect: true.toString(),
      })}`,
    });
  };

  return (
    <div className={showSuccessPage ? cx(styles.Welcome, styles.success) : styles.Welcome}>
      <div className={styles.content}>
        <div className={styles.contentInner}>
          <img src={logoSvg} alt="Aspire" className={styles.logo} />
          {
            showSuccessPage ? (
              <>
                <h1>You have successfully created and listed your first project on our Creator Marketplace.</h1>
                <p>Creators on our Marketplace will start discovering and applying to your project. Applications will appear over time.</p>
                <Button {...sharedButtonProps} onClick={onContinue}>Continue to project</Button>
              </>
            ) : (
              <>
                <h1>
                  <strong>
                    Welcome,
                    {' '}
                    {firstName}
                    !
                  </strong>
                  Let’s create your project
                  <br />
                  and make it discoverable.
                </h1>
                <p>
                  Projects are where you will define campaign objectives
                  and outline key requirements from influencers.
                </p>
                <Button {...sharedButtonProps} onClick={onStart}>
                  Create Project &nbsp;
                  <ChevronRightIcon fill="white" size={10} />
                </Button>
              </>
            )
          }
        </div>
      </div>
      <div className={styles.visual}>
        <div className={styles.visualInner}>
          {showSuccessPage ? (
            <>
              <img src={successCampaignsPng} className={styles.visualPic3} />
              <img src={successIllustrationSvg} className={styles.illustration} />
            </>
          ) : (
            <>
              <img src={welcomePicture1Png} className={styles.visualPic1} />
              <img src={welcomePicture2Png} className={styles.visualPic2} />
              <img src={welcomeIllustrationSvg} className={styles.illustration} />
            </>
          )}
        </div>
      </div>
    </div>
  );
});

Welcome.displayName = 'Welcome';

export default Welcome;
