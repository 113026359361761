import { findIndex } from 'lodash';

import { markDuplicates } from '../../../../utils';
import { TState } from '../types';

interface IAction {
    memberId: number;
    field: string;
    value: Date;
    type: 'UPDATE MEMBER ACTIVE DATE';
}
export type TUpdateMemberActiveDateAction = Readonly<IAction>;

export const updateMemberActiveDate = (state: TState, action: TUpdateMemberActiveDateAction): TState => {
    const memberIndex = findIndex(state.members, (m) => m.id === action.memberId);
    if (memberIndex < 0) {
        // cannot find the member in the list..
        return state;
    }
    return {
        ...state,
        members: markDuplicates([
            ...state.members.slice(0, memberIndex),
            {
                ...state.members[memberIndex],
                [action.field]: action.value,
            },
            ...state.members.slice(memberIndex + 1),
        ]),
    };
};
