import { useMutation, MutationHookOptions } from '@apollo/client';

import {
    UPDATE_CONTENT_REVIEW_MUTATION,
} from '@frontend/app/queries';
import {
    UpdateContentReviewMutation,
    UpdateContentReviewMutationVariables,
} from '@frontend/app/queries/types/UpdateContentReviewMutation';

type IOptions = MutationHookOptions<UpdateContentReviewMutation, UpdateContentReviewMutationVariables>;

export const useUpdateContentReviewMutation = (options: IOptions = {}) => useMutation<UpdateContentReviewMutation, UpdateContentReviewMutationVariables>(UPDATE_CONTENT_REVIEW_MUTATION, options);
