import * as React from 'react';
import {
  useActivationsQuery,
} from '@frontend/app/hooks';
import { renderTokenCell } from '../TokenCell';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const ActivationsCell: React.FC<IProps> = (props) => {
  const { activationColorMap } = useActivationsQuery();
  return renderTokenCell(props.data, 'name', null, activationColorMap);
};
