/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';
import { map, reject } from 'lodash';
import {
  Switch,
} from 'antd';
import { AppIcon } from '@frontend/applications/Shared/components/AppIcon';
import styles from './ThreadTypeFilter.scss';

interface IProps {
  excludeApplicationIdsFilter: string[];
  setExcludeApplicationIdsFilter(excludeApplicationIdsFilter): void;
  applications: any;
}

/**
 * @type {React.FC}
 */
export const ThreadTypeFilter: React.FC<IProps> = React.memo((props) => {
  const {
    excludeApplicationIdsFilter,
    setExcludeApplicationIdsFilter,
    applications,
  } = props;

  const toggleSwitch = (appId, checked) => {
    if (checked) {
      const filteredApps = reject(excludeApplicationIdsFilter, (id) => appId === id);
      setExcludeApplicationIdsFilter(filteredApps);
    } else {
      setExcludeApplicationIdsFilter(excludeApplicationIdsFilter ? [...excludeApplicationIdsFilter, appId] : [appId]);
    }
  };

  return (
    <div className={styles.mainBody}>
      <div className={styles.header}>
        Showing the following thread types:
      </div>
      {map(applications, (app) => {
        const checked = excludeApplicationIdsFilter && !excludeApplicationIdsFilter.includes(app.id);
        return (
          <div key={app.id} className={styles.row}>
            <AppIcon iconUrl={app.icon} />
            <span className={styles.label}>{app.name}</span>
            <Switch className={cx(styles.switch, { [styles.checked]: checked })} checked={checked} onChange={(v) => toggleSwitch(app.id, v)} />
          </div>

        );
})}
    </div>
  );
});

ThreadTypeFilter.displayName = 'ThreadTypeFilter.tsx';
