import { RuleObject } from 'antd/lib/form';
import { isNull } from 'lodash';

enum ParserType {
  Integer = 'integer',
  Float = 'float',
}

const getParsedValue = (parser: ParserType, value: string): number => {
  switch (parser) {
    case ParserType.Integer:
      return Number.parseInt(value, 10);
    case ParserType.Float:
      return Number.parseFloat(value);
  }
};

const numberValidator = (
  parser: ParserType,
  canBeNegative: boolean,
  canBePositive: boolean,
  canBeZero: boolean,
): RuleObject['validator'] =>
  // return a validator that conforms to antd's validator type
   (_rule, value): void | Promise<void> => {
    if (isNull(value)) {
      return Promise.resolve();
    }
    const parsed = getParsedValue(parser, value);
    if (Number.isNaN(parsed)) {
      return Promise.reject(new Error('Not a valid number!'));
    }
    if (!canBeNegative && parsed < 0) {
      return Promise.reject(new Error('Amount cannot be negative!'));
    }
    if (!canBePositive && parsed > 0) {
      return Promise.reject(new Error('Amount cannot be positive!'));
    }
    if (!canBeZero && parsed === 0) {
      return Promise.reject(new Error('Amount cannot be zero!'));
    }
    return Promise.resolve();
  };
export const validateDecimal = numberValidator(ParserType.Float, true, true, true);
export const validatePositiveDecimal = numberValidator(ParserType.Float, false, true, false);
export const validateNonNegativeDecimal = numberValidator(ParserType.Float, false, true, true);
export const validateNegativeDecimal = numberValidator(ParserType.Float, true, false, false);
export const validateNonPositiveDecimal = numberValidator(ParserType.Float, true, false, true);
export const validateInteger = numberValidator(ParserType.Integer, true, true, true);
export const validatePositiveInteger = numberValidator(ParserType.Integer, false, true, false);
export const validateNonNegativeInteger = numberValidator(ParserType.Integer, false, true, true);
export const validateNegativeInteger = numberValidator(ParserType.Integer, true, false, false);
export const validateNonPositiveInteger = numberValidator(ParserType.Integer, true, false, true);
