import { ISelectOtherProjectAction, IState } from '../../../types/state';

export const selectOtherProject = (state: IState, action: ISelectOtherProjectAction): IState => {
  if (state.otherProjectId === action.otherProjectId) {
    return state;
  }
  return {
    ...state,
    otherProjectId: action.otherProjectId,
  };
};
