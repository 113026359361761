import { useMutation, MutationHookOptions } from '@apollo/client';

import { COMPLETE_REQUIREMENT } from '../queries/RequirementMarkCompleteMutation';
import {
  MarkRequirementCompleteMutation,
  MarkRequirementCompleteMutationVariables,
} from '../queries/types/MarkRequirementCompleteMutation';

type IOptions = MutationHookOptions<MarkRequirementCompleteMutation, MarkRequirementCompleteMutationVariables>;

export const useMarkRequirementCompleteMutation = (options: IOptions = {}) => useMutation<MarkRequirementCompleteMutation, MarkRequirementCompleteMutationVariables>(COMPLETE_REQUIREMENT, options);
