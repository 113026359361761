/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { isFunction } from 'lodash';

import { ApplicantOperationButtons } from '@frontend/app/containers/Projects/ProjectsPage/TableHeader/ApplicantOperationButtons';
import { useMessagingContext } from '@frontend/hooks';
import { useProjectContext } from '@frontend/app/context';
import { useEventContext } from '@frontend/app/context';
import { EventName } from '@common';
import { useGetMemberQuery } from '@frontend/app/hooks';

import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';
import { IToastRefHandles } from '@components';

const { useState, useEffect } = React;

interface IProps {
  isLoading?: boolean;
  id: string;
  applicantName: string;
  toastRef: React.RefObject<IToastRefHandles>;
  onRequestClose?: () => void;
  removeMemberFromState(id: string): void;
  refetchProjectCounts?: () => Promise<void>;
}

const TOAST_DELAY_MS = 2000;

export const ProfileApplicationOperationButtons = (props: IProps) => {
  const {
    isLoading,
    id,
    applicantName,
    toastRef,
    removeMemberFromState,
    refetchProjectCounts,
    onRequestClose,
  } = props;
  const { isPaymentRequiredError } = useMessagingContext();
  const { project } = useProjectContext();
  const [buttonsDisabled, setButtonsDisabled] = useState(isLoading);
  const addEvent = useEventContext();

  const { data: memberData, loading: memberLoading } = useGetMemberQuery(
    id ? Number(id) : null,
    { skip: !id },
  );

  const dataLoading = memberLoading || isLoading;

  useEffect(() => {
    setButtonsDisabled(dataLoading);
  }, [dataLoading]);

  const trackingEvents = (operation: MemberApplicantOperation) => {
    const socialProfileEventName = operation === MemberApplicantOperation.Approve
      ? EventName.SocialProfileApprove
      : EventName.SocialProfileReject;
    addEvent(
      socialProfileEventName,
      {
        project_id: project.id,
        project_title: project.title,
        applicant_id: id,
      },
    );
    addEvent(
      EventName.MemberApplicantOperation,
      {
        invite_funnel_id: `${id}_${project.id}`,
        program_id: project.id,
        member_id: parseInt(id, 10),
        type: operation.toLowerCase(),
        utm_source: 'social_profile',
        member_email: memberData.member.email,
      } as any,
    );
  };

  const handleMemberApplicantOperationSuccess = (_ids, operation: MemberApplicantOperation) => {
    trackingEvents(operation);
    refetchProjectCounts();
    if (operation === MemberApplicantOperation.Approve) {
      toastRef.current.showMessage({
        type: 'success',
        content: `${applicantName} has moved to In Progress.`,
        duration: TOAST_DELAY_MS,
      });
    } else if (operation === MemberApplicantOperation.Reject) {
      toastRef.current.showMessage({
        type: 'info',
        content: `${applicantName} has been rejected.`,
        duration: TOAST_DELAY_MS,
      });
    }
    removeMemberFromState(id);
  };

  const handleApplicantOperation = () => {
    setButtonsDisabled(true);
  };

  const handleMemberApplicantOperationError = (error) => {
    if (isPaymentRequiredError(error) && isFunction(onRequestClose)) {
      onRequestClose();
      return;
    }

    toastRef.current.showMessage({
      type: 'error',
      content: error.message,
    });
    setButtonsDisabled(false);
  };

  return (
    <>
      <ApplicantOperationButtons
        hideApplicantCount
        disabled={buttonsDisabled}
        memberIds={id ? [parseInt(id, 10)] : undefined}
        hideApproveButton={false}
        hideRejectButton={false}
        onMemberApplicantOperationSuccess={handleMemberApplicantOperationSuccess}
        onButtonClicked={handleApplicantOperation}
        onError={handleMemberApplicantOperationError}
        project={project}
      />
    </>
  );
};
