import gql from 'graphql-tag';

export const ROLE_FRAGMENT = gql`
  fragment RoleFragment on Role {
    id
    name
    description
    permissions
  }
`;
