import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { SvgIcon, ISvgIconProps } from '@components';

const { useMemo } = React;

export const TagFilledIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <path
        d="
          M10.0337 3.44266L14.3117 7.72066C14.5404 7.94933 14.6664 8.25333 14.6664 8.57733C14.6664 8.90133 14.5404 9.206 14.3117
          9.434L9.43437 14.3113C9.19771 14.5473 8.88771 14.6653 8.57771 14.6653C8.26771 14.6653 7.95771 14.5473 7.72104
          14.312L3.44304 10.0333C3.33304 9.924 3.26571 9.77866 3.25037 9.624L2.66904 3.39466C2.65104 3.19733 2.72104 3.002
          2.86171 2.86133C3.00237 2.72066 3.19704 2.64933 3.39504 2.66933L9.62437 3.25066C9.77904 3.26466 9.92371 3.33266
          10.0337 3.44266ZM6.29294 7.70682C6.6836 8.09749 7.31627 8.09749 7.70694 7.70682C8.0976 7.31615 8.0976 6.68282 7.70694
          6.29282C7.31627 5.90215 6.6836 5.90215 6.29294 6.29282C5.90227 6.68282 5.90227 7.31615 6.29294 7.70682Z
          "
      />
      <mask
        id={id}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="13"
        height="13"
      >
        <path
          d="
            M10.0337 3.44266L14.3117 7.72066C14.5404 7.94933 14.6664 8.25333 14.6664 8.57733C14.6664 8.90133 14.5404 9.206 14.3117
            9.434L9.43437 14.3113C9.19771 14.5473 8.88771 14.6653 8.57771 14.6653C8.26771 14.6653 7.95771 14.5473 7.72104 14.312L3.44304
            10.0333C3.33304 9.924 3.26571 9.77866 3.25037 9.624L2.66904 3.39466C2.65104 3.19733 2.72104 3.002 2.86171 2.86133C3.00237
            2.72066 3.19704 2.64933 3.39504 2.66933L9.62437 3.25066C9.77904 3.26466 9.92371 3.33266 10.0337 3.44266ZM6.29294 7.70682C6.6836
            8.09749 7.31627 8.09749 7.70694 7.70682C8.0976 7.31615 8.0976 6.68282 7.70694 6.29282C7.31627 5.90215 6.6836 5.90215 6.29294
            6.29282C5.90227 6.68282 5.90227 7.31615 6.29294 7.70682Z
            "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

TagFilledIcon.displayName = 'TagFilledIcon';
