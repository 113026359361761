/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1yzda_157 {
  display: flex;
}

._justify-content-space-between_1yzda_161 {
  justify-content: space-between;
}

._tabular-nums_1yzda_165 {
  font-variant-numeric: tabular-nums;
}

._BarChartHorizontal_1yzda_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1yzda_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._BarChartHorizontal_1yzda_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._BarChartHorizontal_1yzda_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._BarChartHorizontal_1yzda_169 ::-webkit-scrollbar-track, ._BarChartHorizontal_1yzda_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._BarChartHorizontal_1yzda_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._tooltipBody_1yzda_235 {
  display: grid;
  max-height: 32.1875rem;
  gap: 0.125rem 0.625rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, 0.9375rem);
  overflow: hidden;
}
._tooltipBody_1yzda_235 ._tooltipRow_1yzda_243 {
  display: flex;
  justify-content: space-between;
}
._tooltipBody_1yzda_235 ._tooltipNumber_1yzda_247 {
  color: #7fd4f4;
}

._BarChartHorizontal_1yzda_169 ._list_1yzda_251 {
  display: flex;
  flex-direction: column;
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 ._label_1yzda_261 {
  margin-right: 20px;
  width: 120px;
  flex-shrink: 0;
  text-align: right;
  font-size: 11px;
  color: #4f4f4f;
  text-transform: uppercase;
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 ._bar_1yzda_270 {
  margin-right: 20px;
  flex: 1 1;
  height: 12px;
  background: #eff5f9;
  border-radius: 0px 100px 100px 0px;
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 ._bar_1yzda_270 ._progress_1yzda_277 {
  position: relative;
  height: 100%;
  border-radius: 0px 100px 100px 0px;
  background: linear-gradient(90deg, rgba(255, 233, 217, 0.6) 0%, rgba(57, 150, 224, 0.6) 68%);
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 ._valueSection_1yzda_283 {
  width: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  font-weight: 500;
  font-size: 11px;
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 ._valueSection_1yzda_283 ._value_1yzda_283 {
  color: #3996e0;
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 ._valueSection_1yzda_283 ._value_1yzda_283:not(:last-child) {
  margin-right: 0.25rem;
}
._BarChartHorizontal_1yzda_169 ._list_1yzda_251 ._listItem_1yzda_255 ._valueSection_1yzda_283 ._percentage_1yzda_299 {
  color: #4f4f4f;
}