import * as React from 'react';
import { Modal, Radio } from '@revfluence/fresh';
import { capitalize } from 'lodash';
import { useResourceContext } from '@frontend/app/context';
import { ResourceType } from '@frontend/app/types/globalTypes';
import styles from './ConnectModal.scss';

declare var Intercom;

const { useState, useEffect, useMemo } = React;

interface IProps {
  type: ResourceType;
  isOpen: boolean;
  onClose: () => void;
}

const INTERCOM_HELP_LINK = 'https://intercom.help/aspireiq_elevate/en/articles/6486513-shared-inbox-overview';

export const ConnectModal = ({ type, isOpen, onClose }: IProps) => {
  const { addAccount } = useResourceContext();
  const [accountType, setAccountType] = useState('shared');

  const handleContactUs = () => {
    if (Intercom) {
      Intercom('show');
    }
  };

  useEffect(() => {
    setAccountType('shared');
  }, [isOpen]);

  const title = useMemo(() => {
    if (type === ResourceType.OUTLOOK) {
      return 'Connect an Outlook Account';
    }

    return `Connect a ${capitalize(type)} Account`;
  }, [type]);

  return (
    <Modal
      visible={isOpen}
      title={title}
      okText="Continue"
      onCancel={onClose}
      onOk={() => {
        addAccount(type, accountType === 'shared');
      }}
    >
      <Radio.Group
        name="connectgroup"
        value={accountType}
        onChange={(ev) => {
          setAccountType(ev.target.value);
        }}
      >
        <Radio className={styles.radio} value="shared">
          <span className={styles.radioText}>Set up an existing email to be a Shared email (Recommended)</span>
          <p className={styles.radioDescription}>
            All users on your Aspire account will be able to read and respond from this email.
          </p>
        </Radio>
        <Radio className={styles.radio} value="individual">
          <span className={styles.radioText}>Set up an existing email to be an individual email</span>
          <p className={styles.radioDescription}>
            All users on your Aspire account will be able to read but only you will be able to respond from this email.
          </p>
        </Radio>
      </Radio.Group>
      <div>
        If you don't have an existing email to set up as shared,
        {' '}
        <a onClick={handleContactUs}>contact us</a>
        {' '}
        to request one that ends in @brands.aspireiq.com.
        {' '}
        <a
          href={INTERCOM_HELP_LINK}
          target="_blank"
        >
          Learn more
        </a>
        {' '}
        about setting up your email client for collaborating with creators.
      </div>
    </Modal>
  );
};
