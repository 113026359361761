import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { isString } from 'lodash';

import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { useClientFeatureEnabled } from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import { H4 } from '@frontend/shadcn/components/typography/h4';
import { P } from '@frontend/shadcn/components/typography/p';

interface IProps {
  cardId: string;
  description: React.ReactNode;
  href?: string;
  image?: {
    fallbackUrl: string;
    title: string;
    url: string;
  };
  notificationCount: number;
  settingsHref?: string;
  size: 'small' | 'large';
  status?: string;
  title: React.ReactNode;
}

const { useState, useMemo, useCallback } = React;

export const RefreshContentCard: React.FC<IProps> = React.memo((props) => {
  const {
 cardId, description, href, image, notificationCount, status, title, size,
} = props;

  const history = useHistory();

  const [shouldFallbackImage, setShouldFallbackImage] = useState(false);
  const setImageFailed = useCallback(() => setShouldFallbackImage(true), []);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const isArchived = useMemo(() => status === ProjectStatus.Archived, [status]);

  const cover = useMemo(() => {
    if (!image) {
      return undefined;
    }
    return (
      <>
        <img
          alt={image.title}
          className={`object-cover h-full w-full rounded-xl ${
            isArchiveProjectEnabled && isArchived ? 'opacity-50' : ''
          }`}
          loading="lazy"
          onError={setImageFailed}
          src={shouldFallbackImage ? image.fallbackUrl : image.url}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, setImageFailed, notificationCount, shouldFallbackImage]);

  const onClickHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isString(href)) {
        return;
      }
      history.push(href);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [href, history],
  );

  const cardClasses = React.useMemo(
    () =>
      (size === 'small'
        ? 'card h-[17rem] md:h-[17rem] sm:h-[15rem] w-full hover:shadow-lg hover:bg-background rounded-2xl cursor-pointer border border-none group pb-3'
        : 'card h-[27.5rem] lg:h-[27.5rem] md:h-[25.5rem] sm:h-[15rem] w-full hover:shadow-lg hover:bg-background rounded-2xl cursor-pointer border border-none group pb-3'),
    [size],
  );

  return (
    <div className={cardClasses} onClick={onClickHandler} key={cardId}>
      <div className="flex flex-col h-full">
        <div className="flex-grow h-4/5">{cover}</div>
        <div className="pt-3 px-3 hover:bg-background rounded-b-2xl">
          <H4 className="mb-0 truncate ...">{title}</H4>
          <div className="flex items-center justify-between text-grey-5 truncate ...">
            <P className="m-0">{description}</P>
          </div>
        </div>
      </div>
    </div>
  );
});
RefreshContentCard.displayName = 'RefreshContentCard';
