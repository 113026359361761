import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_FEATURED_PRODUCTS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/featuredProducts';
import { GetFeaturedProductsQuery, GetFeaturedProductsQueryVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetFeaturedProductsQuery';
import { useMemo } from 'react';

type IOptions = QueryHookOptions<GetFeaturedProductsQuery, GetFeaturedProductsQueryVariables>;

export const useGetFeaturedProducts = (options: IOptions) => {
  const {
 loading, data, error, refetch,
} = useQuery(GET_FEATURED_PRODUCTS_QUERY, options);

  return {
    loading,
    error,
    featuredProducts: useMemo(() => data?.getFeatureProductsInCatalog ?? [], [data?.getFeatureProductsInCatalog]),
    refetch,
  };
};
