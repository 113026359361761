import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const InviteIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M6.5,12.8v2.5c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-2.5H2.7c-0.4,0-0.7-0.3-0.7-0.7
      s0.3-0.7,0.7-0.7h2.5V9c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7v2.5H9c0.4,0,0.7,0.3,0.7,0.7S9.4,12.8,9,12.8H6.5z M16.2,12.4
      c-2.1,0-3.9-1.8-3.9-4.1c0-2.2,1.7-4.1,3.9-4.1c2.1,0,3.9,1.8,3.9,4.1C20.1,10.6,18.4,12.4,16.2,12.4z M16.2,5.7
      c-1.4,0-2.5,1.2-2.5,2.7c0,1.5,1.1,2.7,2.5,2.7s2.5-1.2,2.5-2.7C18.7,6.9,17.6,5.7,16.2,5.7z M21.3,19.7H11.2
      c-0.4,0-0.7-0.3-0.7-0.7c0-2.5,1.1-4.5,3.1-5.5c0.3-0.2,0.7,0,0.9,0.3c0.2,0.3,0,0.7-0.3,0.9c-1.3,0.7-2.1,2-2.3,3.6h8.7
      c-0.2-1.7-1.1-3-2.4-3.7c-0.3-0.2-0.5-0.6-0.3-0.9c0.2-0.3,0.6-0.5,0.9-0.3c1.9,0.9,3.1,2.9,3.2,5.5c0,0.2-0.1,0.4-0.2,0.5
      C21.7,19.6,21.5,19.7,21.3,19.7z"
    />
  </SvgIcon>
));

InviteIcon.displayName = 'InviteIcon';

export { InviteIcon };
