import { useState, useEffect } from 'react';

import { logger } from '@common';
import { IDesignatedContractSigner } from './useFetchSigners';

export const saveSignerData = async (url: string, params: IDesignatedContractSigner) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.debug({ message: err });

    throw err;
  }
};

export function useSaveSigner(url: string, params: IDesignatedContractSigner) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDesignatedContractSigner>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    saveSignerData(url, params)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [url, params]);

  return {
    loading,
    data,
    error,
  };
}
