import * as React from 'react';

import styles from './AlreadySubmittedAlert.scss';

interface IProps {
  supportEmail: string
}

export const AlreadySubmittedAlert: React.FC<IProps> = (props) => {
  const {
    supportEmail,
  } = props;

  const linkElement = (
    <a href={`mailto:${supportEmail}`} className={styles.Link}>
      {supportEmail}
    </a>
  );

  return (
    <div className={styles.AlreadySubmitted}>
      This application has been submitted already.
      {' '}
      If you haven't done so, please contact
      {' '}
      {linkElement}
      {' '}
      to ask for a new invitation link to apply
    </div>
  );
};
