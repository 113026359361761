import * as React from 'react';
import { isFunction, pick, startsWith } from 'lodash';

import { Button } from '@revfluence/fresh';
import {
  Modal,
  IModalProps,
  Input,
  ModalSize,
} from '@components';

import { logger } from '@common';
import { SimpleForm, SimpleField } from '@frontend/app/components';
import { TagInput } from '@frontend/app/types/globalTypes';
import { useSaveTag } from '@frontend/app/hooks';

import styles from './NewTagModal.scss';

const { useState, useRef, useEffect } = React;

interface IProps extends IModalProps {
  onTagSaved?: (tagId: number) => void;
  tag?: TagInput;
  className?: string;
}

const EMPTY_INPUT = {
  name: '',
};

export const NewTagModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const defaultTagInput = props.tag || EMPTY_INPUT;
  const [input, setInput] = useState<TagInput>(defaultTagInput);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const [saveTag] = useSaveTag({
    onCompleted() {
      props.onRequestClose();

      setTimeout(() => {
        setInput(EMPTY_INPUT);
      }, 300);
    },
    onError(err) {
      setErrorMessage(err.message);
    },
  });

  useEffect(() => {
    if (props.tag) {
      setInput(props.tag);
    }
  }, [props.tag]);

  const formRef = useRef<SimpleForm>();

  const handleChangeFields = (fieldName: string, value: string) => {
    if (errorMessage) {
      setErrorMessage(null);
    }

    setInput((i) => ({ ...i, [fieldName]: value }));
  };

  const handleClickSave = () => {
    formRef.current.submit();
  };

  const handleSubmit = async () => {
    saveTag({
      variables: {
        tag: {
          id: input.id,
          name: input.name,
        },
      },
    })
    .then((tagData) => {
      if (isFunction(props.onTagSaved)) {
        props.onTagSaved(tagData.data.tag.id);
      }
    })
    .catch(logger.error);
  };

  const formValues = pick(input, 'name');
  const isEditing = !!props.tag;

  return (
    <Modal
      width={ModalSize.Small}
      className={styles.NewTagModal}
      show={props.show}
      showCloseIcon={props.showCloseIcon}
      onRequestClose={props.onRequestClose}
    >
      <div>
        <div className={styles.title}>
          {isEditing && 'Edit Existing Tag'}
          {!isEditing && 'Create a New Tag'}
        </div>
        <div className={styles.subtitle}>Tags help you organize members</div>
        {errorMessage && (
          <div className={styles.error}>
            {startsWith(errorMessage, 'GraphQL error: duplicate key value')
              ? 'Tag name exists already, please create a new tag name'
              : errorMessage}
          </div>
        )}
        <SimpleForm
          ref={formRef}
          values={formValues}
          onChange={handleChangeFields}
          onSubmit={handleSubmit}
        >
          <div className={styles.form}>
            <div className={styles.label}>Tag Name:</div>
            <SimpleField name="name" type="string">
              <Input onPressEnter={handleClickSave} />
            </SimpleField>
          </div>
        </SimpleForm>

        <div className={styles.buttons}>
          <Button type="default" size="middle" onClick={props.onRequestClose}>
            Cancel
          </Button>
          <Button type="primary" size="middle" onClick={handleClickSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
});

NewTagModal.defaultProps = {
  onTagSaved: () => undefined,
};
