import * as React from 'react';
import {
 NavLink, Redirect, Route, Switch, useHistory, useLocation, useRouteMatch,
} from 'react-router-dom';

import { map } from 'lodash';

import {
 Row, Col, Space, useBreakpoint, Typography,
} from '@revfluence/fresh';

import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

import styles from './AppDashboardNav.scss';

const { useEffect } = React;
const { Title } = Typography;

interface IApplication {
  displayName: string;
  iconUrl: string;
}

interface INavLink {
  displayName: string;
  route: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  component: any;
}

interface IProps {
  application: IApplication;
  navLinks: INavLink[];
  defaultRoute: string;
  fixToScreenHeight?: boolean;
  hideTabs?: boolean;
  sidebarComponents?: React.ReactElement[];
}

export const AppDashboardNav: React.FunctionComponent<IProps> = (props) => {
  const screens = useBreakpoint();
  const { fixToScreenHeight, hideTabs } = props;

  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const {
 application, navLinks, defaultRoute, sidebarComponents,
} = props;

  useEffect(() => {
    if (defaultRoute) {
      history.replace({
        ...location,
        pathname: `${match.url}/${defaultRoute}`,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRoute]);

  return (
    <Space
      direction="vertical"
      size={0}
      style={{
        width: '100%',
        ...(fixToScreenHeight ? { display: 'flex', flexDirection: 'column', height: '100%' } : {}),
      }}
    >
      <Row gutter={0} style={{ backgroundColor: 'white' }}>
        <Col xs={24}>
          <Row className={styles.appDashboardHeader} gutter={0}>
            <Col>
              <NavLink to={{ ...location, pathname: `${match.url}/${defaultRoute}` }} className={styles.appLogoLink}>
                <img src={application.iconUrl} className={styles.appIcon} />
                <Title level={4} style={{ margin: 0 }}>
                  {application.displayName}
                </Title>
              </NavLink>
            </Col>
            <Col style={{ marginTop: screens.xs && 8 }}>
              <Space size={16}>
                {!hideTabs && (
                  <ul className={styles.navList}>
                    {map(navLinks, (navLink) => (
                      <li key={navLink.route}>
                        <NavLink
                          to={{ ...location, pathname: `${match.url}/${navLink.route}` }}
                          activeClassName={styles.active}
                        >
                          {navLink.displayName}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </Space>
            </Col>
            {sidebarComponents && (
              <Col className={styles.sidebarComponents}>
                {sidebarComponents.map((component, index) => (
                  <React.Fragment key={index}>{component}</React.Fragment>
                ))}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row gutter={0}>
        <Col xs={24} style={{ padding: 24 }}>
          <Switch>
            {map(navLinks, (navLink) => (
              <Route
                key={navLink.route}
                path={`${match.url}/${navLink.route}`}
                render={lazyLoadComponent(navLink.component)}
              />
            ))}
            <Redirect from={match.url} to={{ ...location, pathname: `${match.url}/${defaultRoute}` }} />
          </Switch>
        </Col>
      </Row>
    </Space>
  );
};
