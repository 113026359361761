import { useMutation, QueryHookOptions } from '@apollo/client';

import { SEND_MESSAGE_MUTATION } from '@frontend/app/queries';
import {
  SendMessageMutation, SendMessageMutationVariables,
} from '@frontend/app/queries/types/SendMessageMutation';

type IOptions = QueryHookOptions<SendMessageMutation, SendMessageMutationVariables>;

export function useSendMessage(options: IOptions = {}) {
  const [
    sendMessage,
    { loading, error },
  ] = useMutation<SendMessageMutation, SendMessageMutationVariables>(SEND_MESSAGE_MUTATION, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...options as any,
  });

  return {
    loading,
    sendMessage,
    error,
  };
}
