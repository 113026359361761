import * as React from 'react';
import moment from 'moment';

import { useMemo } from 'react';
import {
 map, filter, reverse, sortBy,
} from 'lodash';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { Table, LoadSpinner } from '@components';
import { useFetchContractsData, IContract } from '../useFetchContracts';
import { ContractDownload } from '../components/ContractDownload';

import { allColumns } from './ContractTableColumns';

import styles from './ContractsListPage.scss';

const STATUS_MAP = {
  pending: 'Error',
  needs_signature: 'Awaiting Signatures',
  executed: 'Complete',
  declined: 'Declined',
  signed: 'Awaiting Member Signature',
};

const ContractsListPage: React.FunctionComponent = () => {
  const { backendServerApiEndpoint, clientId } = useApplication();

  const { data, loading } = useFetchContractsData(
    `${backendServerApiEndpoint}/contract`,
    {
      client_id: clientId,
    },
  );

  const renderDownloadField = (row: IContract) => ({
      value: row.id,
      render: () => (
        <ContractDownload contractId={row.id} />
      ),
    });

  /**
   * Massage data into state expected by Table
   */
  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    const sortedData = reverse(sortBy(data, ['created_ts']));

    return map(sortedData, (contractRow: IContract, index) => {
      const brandSigners = filter(contractRow.signers, ['role', 'Brand']);
      const memberSigners = filter(contractRow.signers, ['role', 'Creator']);
      return {
        id: index.toString(),
        _raw: contractRow,
        title: contractRow.title,
        status: STATUS_MAP[contractRow.status],
        member_signers: map(memberSigners, (signer) => signer.name).join(', '),
        brand_signers: map(brandSigners, (signer) => signer.name).join(', '),
        created_date: moment(contractRow.created_ts * 1000).format('ll'),
        activation_names: contractRow.activation_names.join(', '),
        download: renderDownloadField(contractRow),
      };
    });
  }, [data]);

  const renderHeader = () => {
    if (tableData && tableData.length > 0) {
      return (
        <h1 className={styles.mainTitle}>
          Showing
          {' '}
          {tableData.length}
          {' '}
          contracts
        </h1>
      );
    }
    return null;
  };

  return (
    <div className={styles.ContractsListPage}>
      {!loading && (
        <>
          <Table
            ref={null}
            data={tableData}
            columns={allColumns}
            disabled={false}
            tableHeader={renderHeader()}
            emptyMessage="You have not sent any contracts yet"
            paddingBottom={0}
            rowDisplayName="Contracts"
            config={{
              allowSearch: false,
              configurableColumns: false,
              rowHeight: 58,
              pageSize: 20,
              striped: true,
              rowBorder: true,
              selectable: false,
            }}
          />
        </>
      )}
      {loading && <LoadSpinner centered />}
    </div>
  );
};

export default ContractsListPage;
