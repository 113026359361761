import {
  IPrevStepAction,
  IState,
  Step,
} from '../../types/state';

export const prevStep = (state: IState, _action: IPrevStepAction): IState => {
  switch (state.step) {
    case Step.AddContentGuidelines:
      return {
        ...state,
        step: Step.CollaborationDetails,
      };
    case Step.CollaborationDetails:
      return {
        ...state,
        step: Step.AddContentGuidelines,
      };
    case Step.ReviewAndSetOffers:
      return {
        ...state,
        step: Step.CollaborationDetails,
      };
    case Step.SocialProfile:
      return {
        ...state,
        step: Step.ReviewAndSetOffers,
      };
    case Step.WriteMessage:
      return {
        ...state,
        step: Step.ReviewAndSetOffers,
      };
    default:
      return state;
  }
};
