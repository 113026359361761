import * as React from 'react';

import styles from './BaseStep.scss';

interface IProps {
  label: string;
  textContent?: React.ReactNode;
  actionContent?: React.ReactNode;
  iconContent?: React.ReactNode;
}

export const BaseStep: React.FC<IProps> = (props: IProps) => (
  <div className={styles.BaseStep}>
    <div className={styles.label}>
      <h2>{props.label}</h2>
    </div>
    <div className={styles.box}>
      <div className={styles.left}>
        <div className={styles.icon}>
          {props.iconContent}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.textContent}>
          {props.textContent}
        </div>
        <div className={styles.actionContent}>
          {props.actionContent}
        </div>
      </div>
    </div>
  </div>
);

BaseStep.displayName = 'BaseStep';
