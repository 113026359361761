import { isNaN } from 'lodash';

import { endpoints } from '@components';
import { useGet } from '@frontend/utils';
import { IPublisher } from '@components';

interface IProps {
  apiEndpoint: string;
  publisherId?: number;
}

/**
 * Fetch other social accounts for the given publisher id
 */
export const useFetchPublisherSocialAccounts = (props: IProps) => {
  const {
    apiEndpoint,
    publisherId,
  } = props;

  const url = apiEndpoint && !isNaN(Number(publisherId))
    ? `${apiEndpoint}/${endpoints.publisherEndpoint}/${publisherId}`
    : null;

  const {
    loading: isFetching,
    data,
    error,
  } = useGet<IPublisher>({ url, skip: !publisherId });

  return {
    socialAccounts: data?.social_accounts,
    isFetching,
    error,
  };
};
