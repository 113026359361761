import { useMemo } from 'react';
import {
  unionBy,
  keyBy,
  map,
  size,
  isEmpty,
  includes,
  filter,
} from 'lodash';
import { ProgramInput } from '@frontend/app/types/globalTypes';
import { useSchemas } from '../LeftPanel/hooks';
import { ProjectApplicationPageTemplateName } from '../../applicationPageUtils';

const defaultFieldNames = Object.freeze([
  'First Name',
  'Last Name',
  'Address1',
  'Address2',
  'City',
  'State',
  'PostalCode',
  'Country',
  'Birthday',
  'Phone',
  'Instagram',
  'UnpaidLabel',
  'UnpaidDescription',
]);

export const requiredFieldNames = Object.freeze([
  'First Name',
  'Last Name',
]);

export const useDefaultProjectInput = (project: ProgramInput) => {
  const {
    defaultSchemas,
  } = useSchemas();

  const fieldNames = useMemo(() => {
    if (project.applicationPageTemplateName === ProjectApplicationPageTemplateName.Quick) {
      return [
        'First Name',
        'Last Name',
        'Instagram',
      ];
    }

    return defaultFieldNames;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.templateName]);

  const schemasByName = useMemo(() => keyBy(defaultSchemas, (schema) => schema.name), [defaultSchemas]);

  return useMemo(() => {
    if (isEmpty(schemasByName) || !project) {
      return null;
    }

    let memberFieldSchemas = project.applicationFormFields?.memberFieldSchemas || [];

    if (!project.published) {
      memberFieldSchemas = unionBy(
        memberFieldSchemas,
        filter(map(fieldNames, (name, idx) => {
          // add default member field schemas to unpublished project.
          const schema = schemasByName[name];
          if (!schema) {
            // silently ignore if for some reason field doesn't exist.
            return null;
          }
          return {
            schemaId: schema.id,
            name: schema.name,
            required: includes(requiredFieldNames, schema.name),
            label: schema.name,
            order: size(memberFieldSchemas) + idx + 2,
          } as typeof memberFieldSchemas[0];
        })),
        (schema) => schema.schemaId,
      );
    }

    return {
      ...project,
      applicationFormFields: {
        memberFieldSchemas,
        dbColumns: unionBy(project.applicationFormFields?.dbColumns, [{
          // Add email field always.
          name: 'email',
          required: true,
          label: null,
          order: 1,
        }], 'name'),
      },
    } as ProgramInput;
  }, [project, schemasByName, fieldNames]);
};
