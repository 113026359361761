import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_PRESET_CONDITIONS_QUERY } from '@frontend/app/queries';
import {
  GetAllPresetConditionsQuery,
  GetAllPresetConditionsQueryVariables,
} from '@frontend/app/queries/types/GetAllPresetConditionsQuery';

type IOptions = QueryHookOptions<GetAllPresetConditionsQuery, GetAllPresetConditionsQueryVariables>;

export const useGetAllPresetConditionsQuery = (options: IOptions = {}) => (
  useQuery<GetAllPresetConditionsQuery, GetAllPresetConditionsQueryVariables>(GET_ALL_PRESET_CONDITIONS_QUERY, options)
);
