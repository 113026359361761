import * as React from 'react';
import { Spinner } from '@revfluence/fresh';
import { Select } from 'antd';
import { ISocialSearchTag } from '../models';
import { Cancelable, map } from 'lodash';

interface IProps {
  availableTags: ISocialSearchTag[];
  className?: string;
  handleTagsChange: (tags) => void;
  isFetching: boolean;
  onBlur: () => void;
  onSearch: ((value: string) => Promise<void>) & Cancelable;
  placeholder?: string;
  selectedTags: ISocialSearchTag[];
}

export const SelectQueryInput = React.memo((props: IProps) => {
  const {
    availableTags,
    className,
    handleTagsChange,
    isFetching,
    onBlur,
    onSearch,
    placeholder,
    selectedTags,
  } = props;

  return (
    <Select
      className={className}
      onSearch={onSearch}
      filterOption={false}
      mode="multiple"
      showSearch
      getPopupContainer={() => document.getElementById('filterInputContainer')}
      placeholder={placeholder}
      onChange={handleTagsChange}
      onBlur={onBlur}
      loading={isFetching}
      value={selectedTags ? map(selectedTags, 'value') : []}
      notFoundContent={null}
      suffixIcon={isFetching ? <Spinner size="small" /> : null}
    >
      {
        availableTags.map(tagData => {
          return (
            <Select.Option
              key={tagData.value}
              value={tagData.value}
            >
              {tagData.tag}
            </Select.Option>
          );
        })
      }
    </Select>
  );
});
