import * as React from 'react';
import cx from 'classnames';
import { assignWith, isNil, isUndefined } from 'lodash';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context';
import { OnboardingTemplateComponentStylesInput } from '@frontend/app/types/globalTypes';
import { getGoogleFontsURL } from '@frontend/app/containers/Projects/LandingPages/constants/fonts';
import { LazyImage } from '@components';

import { ApplicationPageBuilderComponent, isFieldVisibleInPage } from '@frontend/app/containers/Projects/applicationPageUtils';
import { IApplicationPageTemplateProps } from '../types';
import {
  defaultAbout,
  defaultApplication,
  defaultIntro,
  defaultPerks,
  defaultPersona,
  defaultSettings,
} from './constants';

import { Application } from './Application';
import { About } from './About';
import { Intro } from './Intro';
import { Perks } from './Perks';
import { Persona } from './Persona';

import styles from './CustomizedTemplate.scss';

const {
 useCallback, useEffect, useRef, useMemo, useState,
} = React;

interface IProps extends IApplicationPageTemplateProps {}

function customAssign(objValue, srcValue) {
  return (isNil(srcValue) || (!(srcValue === false) && !srcValue))
    ? objValue
    : srcValue;
}

export const CustomizedTemplate: React.FC<IProps> = ({ scrollTo, ...props }) => {
  const { isUserView } = props;

  const isFieldVisible = (fieldName: ApplicationPageBuilderComponent) => isFieldVisibleInPage(fieldName, { onboardingTemplateConfig: props.config });

  const containerRef = useRef<HTMLDivElement>(null);
  const designRef = useRef<HTMLDivElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const perksRef = useRef<HTMLDivElement>(null);
  const personaRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const addEvent = useEventContext();
  const location = useLocation();
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState<boolean>(false);

  const utmSource = useMemo(() => {
    const searchQueries = new URLSearchParams(location.search);
    return searchQueries.get('utmSource');
  }, [location.search]);

  const intro = useMemo(() => (
    assignWith(
      { ...defaultIntro },
      props.config?.intro,
      customAssign,
    )
  ), [props]);

  const settings = useMemo(() => (
    assignWith(
      { ...defaultSettings },
      props.config?.settings,
      customAssign,
    )
  ), [props]);

  const about = useMemo(() => (
    assignWith(
      { ...defaultAbout },
      props.config?.about,
      customAssign,
    )
  ), [props]);

  const perks = useMemo(() => (
    assignWith(
      { ...defaultPerks },
      props.config?.perks,
      customAssign,
    )
  ), [props]);

  const persona = useMemo(() => (
    assignWith(
      { ...defaultPersona },
      props.config?.persona,
      customAssign,
    )
  ), [props]);

  const application = useMemo(() => (
    assignWith(
      { ...defaultApplication },
      props.config?.application,
      customAssign,
    )
  ), [props]);

  const inlineStyles = {
    backgroundColor: settings?.page_color?.background_color,
  };

  const componentStyles: OnboardingTemplateComponentStylesInput = {
    button: {
      backgroundColor: settings?.cta_styling?.button_color,
      color: settings?.cta_styling?.font_color,
      borderColor: settings?.cta_styling?.font_color,
      fontFamily: settings?.cta_styling?.font_family,
      cursor: 'pointer',
    },
    heading: {
      fontSize: settings?.headline_styling?.font_size,
      color: settings?.headline_styling?.fill_color,
      fontFamily: settings?.headline_styling?.font_family,
    },
    body: {
      fontSize: settings?.body_styling?.font_size || 18,
      color: settings?.body_styling?.fill_color,
      fontFamily: settings?.body_styling?.font_family,
      lineHeight: '1.35em',
    },
    page: {
      backgroundColor: settings?.page_color.background_color,
    },
    form: {
      backgroundColor: settings?.form_color?.background_color,
      isTransparent: settings?.form_color?.is_transparent,
    },
  };

  const {
    favicon = '',
  } = settings;

  const refs = {
    design: designRef,
    intro: introRef,
    about: aboutRef,
    perks: perksRef,
    persona: personaRef,
    form: formRef,
  };

  useEffect(() => {
    const selectedRef = refs[scrollTo] || null;

    const scrollY = selectedRef
      ? selectedRef.current.offsetTop
      : 0;

    if (containerRef) {
      containerRef.current?.scroll({
        top: scrollY,
        behavior: 'smooth',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo, containerRef]);

  const handleJoinClick = useCallback(() => {
    const y = formRef?.current?.offsetTop;

    const element = containerRef?.current
      || window;

    element.scrollTo({
      top: y,
      behavior: 'smooth',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, window]);

  // We need to set favicon based on Content-Type of image
  // Note: this could be done during SSR but it will hit performance time, that's why it's an async client-side function
  const setFavicon = useCallback(async () => {
    const { headers } = await fetch(favicon);
    const contentType = headers?.get('Content-Type')
      || 'image/png';

    const link = (
      document.querySelector('link[rel*=icon]')
        || document.createElement('link')
    ) as HTMLLinkElement;

    link.type = contentType;
    link.rel = 'shortcut apple-touch-icon icon';
    link.href = favicon;

    link.setAttribute('sizes', '180x180');

    document.getElementsByTagName('head')[0].appendChild(link);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, document, favicon]);

  useEffect(() => {
    if (
      isUndefined(window)
        || !isUserView
        || !favicon
    ) {
      return;
    }

    setFavicon();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    window,
    document,
    favicon,
    setFavicon,
    isUserView,
  ]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const { scrollHeight, scrollTop } = e.currentTarget;
    const scrollDistanceToBottom = scrollHeight - (window.innerHeight + scrollTop);
    // capture user scrolling to the bottom of the page only first time
    if (!hasScrolledToBottom && scrollDistanceToBottom < 10) { // small enough to consider user at the bottom
      setHasScrolledToBottom(true);
      addEvent(EventName.ApplicationPageFormScroll, {
        project_id: props.projectId,
        project_name: props.projectTitle,
        utm_source: utmSource,
      });
    }
  };

  return (
    <>
      {
        isUserView && (
          <Helmet>
            <title>{intro.title}</title>
          </Helmet>
        )
      }

      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href={getGoogleFontsURL()} rel="stylesheet" />
      </head>

      <div
        style={inlineStyles}
        className={cx(
          styles.Template,
          props.className,
        )}
        ref={containerRef}
        onScroll={handleScroll}
      >
        <div className={styles.header} ref={designRef}>
          <LazyImage className={styles.logo} src={settings?.logo} />
        </div>

        <div ref={introRef}>
          <Intro
            {...intro}
            componentStyles={componentStyles}
            onJoinClick={handleJoinClick}
            isFieldVisible={isFieldVisible}
          />
        </div>

        <div ref={aboutRef}>
          <About {...about} componentStyles={componentStyles} />
        </div>

        <div ref={perksRef}>
          <Perks {...perks} componentStyles={componentStyles} />
        </div>

        <div ref={personaRef}>
          <Persona {...persona} componentStyles={componentStyles} />
        </div>

        <div ref={formRef}>
          <Application
            {...application}
            fields={props.applicationFormFields}
            componentStyles={componentStyles}
            isPreview={props.isPreview}
            hasUnpaidOffer={props.hasUnpaidOffer}
            projectId={props.projectId}
            projectTitle={props.projectTitle}
            disableSubmit={props.disableSubmit}
          />
        </div>
      </div>
    </>
  );
};
