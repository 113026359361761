import gql from 'graphql-tag';

export const MEMBER_ACTIVATIONS = gql`
  query MemberActivationsQuery($memberId: Int!) {
    member: memberById(id: $memberId) {
      id
      activations {
        id
        name
        description
      }
      tags {
        id
        name
      }
    }
  }
`;
