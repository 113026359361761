import { useQuery, QueryHookOptions } from '@apollo/client';

import { MEMBER_OWNERS } from '@frontend/app/queries';
import {
  MemberOwnersQuery,
  MemberOwnersQueryVariables,
} from '@frontend/app/queries/types/MemberOwnersQuery';

type IOptions = QueryHookOptions<MemberOwnersQuery, MemberOwnersQueryVariables>;

export const useMemberOwnersQuery = (memberId: number, options: IOptions = {}) => useQuery(MEMBER_OWNERS, {
    ...options,
    variables: {
      memberId,
    },
    skip: !memberId || options.skip,
  });
