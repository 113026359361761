import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { BRAND_EDIT_TERMS_MUTATION } from '@frontend/app/queries';
import { BrandEditTermsMutation, BrandEditTermsMutationVariables } from '../queries/types/BrandEditTermsMutation';

type IOptions = MutationHookOptions<BrandEditTermsMutation, BrandEditTermsMutationVariables>;

export const useBrandEditTermsMutation = (options: IOptions = {}) => (
  useMutation(BRAND_EDIT_TERMS_MUTATION, options)
);
