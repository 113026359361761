import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const ColumnsFilledIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
  >
    <path d="M1 5C1 3.89543 1.89543 3 3 3H5V13H3C1.89543 13 1 12.1046 1 11V5Z" />
    <path d="M11 3H13C14.1046 3 15 3.89543 15 5V11C15 12.1046 14.1046 13 13 13H11V3Z" />
    <rect x="6" y="3" width="4" height="10" />
  </SvgIcon>
));

ColumnsFilledIcon.displayName = 'ColumnsFilledIcon';
