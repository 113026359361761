._NameCell_1qxez_1 ._content_1qxez_1 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
}
._NameCell_1qxez_1 ._content_1qxez_1 ._avatar_1qxez_8 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center center;
  border-radius: 100px;
}
._NameCell_1qxez_1 ._content_1qxez_1 ._badge_1qxez_16 {
  position: absolute;
  left: 22px;
  top: 22px;
}
._NameCell_1qxez_1 ._content_1qxez_1 ._name_1qxez_21 {
  margin-left: 7px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}