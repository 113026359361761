import { ArrowLeftOutlined } from '@ant-design/icons';
import * as React from 'react';

interface IAUIArrowLeftOutlinedProps {
  color?: string;
}
export const AUIArrowLeftOutlined = (props: IAUIArrowLeftOutlinedProps) => {
  const color = props.color ? props.color : '#BDBDBD';
  return (
    <ArrowLeftOutlined style={{ color }} />
  );
};
