import {
  chain, find, lowerCase, map, replace, startsWith, trim,
} from 'lodash';

export const formatNumberToSuffixString = (value: string): string => {
  const num = Number(value);
  if (isNaN(num)) {
    return value;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(num >= 10000 ? 0 : 1)}K`;
  }
  return Number.isInteger(num) ? num.toString() : num.toFixed(2);
};

export const formatEngagementRateToPerc = (value: string) => (value ? `${(Number(value) * 100).toFixed(2)}%` : null);
export const formatNumberToPerc = (value: number) => (value * 100).toFixed(2);

export const formatMember = (member, fields) => {
  const updatedFields = {};
  if (fields) {
    map(fields, (field) => {
      const fieldId = field.id.toString();
      const fieldName = field.name.replace(/\s/g, '_').toLowerCase();
      if (member.fields[fieldId]) {
        updatedFields[fieldName] = member.fields[fieldId];
      }
    });
  }
  return {
    ...member,
    ...updatedFields,
    metaFields: updatedFields,
  };
};

const BASE_URLS = {
  pinterest: 'https://www.pinterest.com',
  youtube: 'https://www.youtube.com/channel',
  instagram: 'https://www.instagram.com',
  facebook: 'https://www.facebook.com',
  twitter: 'https://twitter.com',
  tiktok: 'https://www.tiktok.com',
};
export const generateLinkFromUsername = (network: string, username: string | null): string =>
  (username ? `${BASE_URLS[network]}/${username}/` : `${BASE_URLS[network]}/`);

// table sorting
export const handleTableNumericSorting = (dataIndex) => (memberRecordOne, memberRecordTwo) => {
  const recordOne = memberRecordOne.metaFields[dataIndex] || 0;
  const recordTwo = memberRecordTwo.metaFields[dataIndex] || 0;
  return recordOne - recordTwo;
};

export const handleTableSorting = (dataIndex) => (memberRecordOne, memberRecordTwo) => {
  const recordOne = memberRecordOne[dataIndex] || '';
  const recordTwo = memberRecordTwo[dataIndex] || '';
  return recordOne.localeCompare(recordTwo);
};

export const handleBrandApprovalSorting = (dataIndex) => (memberRecordOne, memberRecordTwo) => {
  const sortOrder = {
    'Approved': 1,
    'Pending Review': 2,
    'Maybe': 3,
    'Rejected': 4,
  };

  const recordOne = memberRecordOne.metaFields[dataIndex] || 'Pending Review';
  const recordTwo = memberRecordTwo.metaFields[dataIndex] || 'Pending Review';

  const orderOne = sortOrder[recordOne];
  const orderTwo = sortOrder[recordTwo];

  return (orderOne) - (orderTwo);
};

export const getFilteredGroups = (groups) => chain(groups)
  .filter((group) => startsWith(group.title, '#AR') || group.showApplicantReview)
  .map((group) => ({
    ...group,
    newTitle: trim(replace(group.title, '#AR', '')),
  }))
  .value();

export const findFieldByName = (fieldName, fields) => find(fields, (field) => lowerCase(field.name) === fieldName);

export const sortDataArray = (dataArray) => dataArray.sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10));
export const formatField = (fieldName: string) => fieldName.replace(/\s/g, '_').toLowerCase();
