import React, { useEffect, useState } from 'react';
import { TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import { useHistory } from 'react-router-dom';
import { DashboardLayout, SectionHeader, TabComponent } from '@frontend/app/refresh-components';
import { DashboardConfigNames } from '@frontend/app/constants/dashboards';
import { LoadSpinner } from '@components';
import widgetMapper from '@frontend/app/utils/widgetMapper';
import { useClientFeatureEnabled, useGetDashboardConfig, useQueryParams } from '@frontend/app/hooks';
import { useSyncOrders } from '@frontend/applications/ProductFulfillmentApp/hooks';
import { logger } from '@common';
import { DateFilter } from '@frontend/app/components';
import HeaderButton from '@frontend/app/refresh-components/HeaderButton';
import { ArrowsRotateIcon } from '@revfluence/fresh-icons/regular/esm';
import { ClientFeature } from '@frontend/app/constants';
import { useGetBrandCatalogDashboardStats } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetBrandCatalogDashboardStats';
import { useApolloClient } from '@apollo/client';
import { usePFADashboardContext } from './ProductFulfillmentDashboardContext';
import { ProductFulfillmentDashboardTabEnum } from './constants';
import { useGetCatalogs } from '../Settings/ProductFulfillment/Catalogs/hooks/useGetCatalogs';

export const AnalyticsTab = () => {
  const { data: dashboardConfigData, loading: isDashboardConfigLoading } = useGetDashboardConfig();

  const config = dashboardConfigData?.dashboardConfig?.dashboardConfig?.find(
    (config) => config.name === DashboardConfigNames.ProductFulfillmentAnalytics,
  ) || null;

  const isPfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);

  const { catalogs } = useGetCatalogs();

  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const { brandCatalogDashboardStats } = useGetBrandCatalogDashboardStats({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const showCatalogsSection = isPfaV2Enabled || catalogs?.length > 1 || brandCatalogDashboardStats?.length > 1;

  const filteredSections = config?.sections?.filter((section) => section.sectionName !== 'Catalog Insights' || showCatalogsSection);

  if (isDashboardConfigLoading) {
    return (
      <div className="p-8 bg-secondary h-full flex">
        <LoadSpinner />
      </div>
    );
  }

  return (
    <div className="p-8 bg-secondary flex flex-col gap-6">
      {filteredSections?.map((section) => (
        <section key={section.sectionId}>
          <SectionHeader title={section.sectionName} description={section.description} className="pb-4" />
          <DashboardLayout
            components={section?.widgets.map((widget) => ({
              content: widgetMapper({ type: widget.type }),
              columns: widget.width,
            }))}
            gap="4"
          />
        </section>
      ))}
    </div>
  );
};

const OrdersTab = () => {
  const { data: dashboardConfigData, loading: isDashboardConfigLoading } = useGetDashboardConfig();

  const config = dashboardConfigData?.dashboardConfig?.dashboardConfig?.find(
    (config) => config.name === DashboardConfigNames.ProductFulfillmentShipping,
  ) || null;

  if (isDashboardConfigLoading) {
    return (
      <div className="p-8 bg-secondary h-full flex">
        <LoadSpinner />
      </div>
    );
  }

  return (
    <div className="p-8 bg-secondary">
      {config?.sections?.map((section) => (
        <section key={section.sectionId}>
          <SectionHeader title={section.sectionName} description={section.description} className="pb-4" />
          <DashboardLayout
            components={section?.widgets.map((widget) => ({
                content: widgetMapper({ type: widget.type }),
                columns: widget.width,
              }))}
            gap="4"
          />
        </section>
      ))}
    </div>
  );
};

export const ProductFulfillmentDashboard = () => {
  const currentTab = (useQueryParams().get('tab') as ProductFulfillmentDashboardTabEnum)
    || ProductFulfillmentDashboardTabEnum.Analytics;
  const [activeTab, setActiveTab] = useState<ProductFulfillmentDashboardTabEnum>(currentTab);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  const history = useHistory();

  const { dateRangeSettings } = usePFADashboardContext();
  const apolloClient = useApolloClient();

  const { syncOrders, loading: syncing } = useSyncOrders({
    onCompleted: () => {
      apolloClient.refetchQueries({ include: 'all' });
    },
    onError: (error) => {
      logger.error(error);
    },
  });

  const tabs = [
    {
      name: ProductFulfillmentDashboardTabEnum.Analytics,
      label: 'Analytics',
      content: <AnalyticsTab />,
    },
    {
      name: ProductFulfillmentDashboardTabEnum.Orders,
      label: 'Orders',
      content: <OrdersTab />,
    },
  ];

  const handleTabChange = (tabName: ProductFulfillmentDashboardTabEnum) => {
    // Create a new URLSearchParams object based on the current location
    const params = new URLSearchParams();

    // Set the active tab in the query parameters
    params.set('tab', tabName);

    // Update the browser URL without a full reload
    history.push({ search: params.toString() });

    // Update the active tab state
    setActiveTab(tabName);
  };

  const renderFilter = () => (
    <div className="flex gap-2">
      <HeaderButton
        onClick={() => {
            syncOrders();
          }}
        icon={syncing ? null : <ArrowsRotateIcon className="h-4 w-4" />}
        loading={syncing}
      />
      <DateFilter
        earliestDate={dateRangeSettings.earliestDate}
        settings={dateRangeSettings}
        refreshUi
      />
    </div>
    );

  return (
    <TooltipProvider delayDuration={0}>
      <div className="h-full relative bg-secondary shadcn">
        <div className="w-full h-[140px] bg-primary px-8">
          <div className="flex justify-between items-center pt-9">
            <div className="flex justify-between items-center gap-3">
              <p className="text-secondary text-3xl font-bold m-0">
                Product Fulfillment
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-2 absolute top-[95px] w-full">
          <TabComponent
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            filter={renderFilter()}
          />
        </div>
      </div>
    </TooltipProvider>
  );
};
