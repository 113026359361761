import { ProgramInput } from '@api/src/graphql/inputs';
import { find, includes, isNil } from 'lodash';

export enum ProjectApplicationPageTemplateName {
  Customizable = 'Customizable', // This is the first non-legacy template.
  Quick = 'Quick',
}
interface IApplicationPageTemplatesData {
  templateName: ProjectApplicationPageTemplateName;
  placeholderSrc: string;
  name: string;
}

export enum ApplicationPageSection {
  Settings = 'Settings',
  Intro = 'Intro',
  About = 'About',
  Perks = 'Perks',
  Persona = 'Persona',
  Form = 'Form',
}

export enum ApplicationPageBuilderComponent {
  SettingsFavicon = 'SettingsFavicon',
  SettingsAccordions = 'SettingsAccordions',
  SettingsSimplifiedFonts = 'SettingsSimplifiedFonts',
  IntroSectionToggle = 'IntroSectionToggle',
  IntroSubtitle = 'IntroSubtitle',
  IntroSubtitleToggle = 'IntroSubtitleToggle',
  IntroHeroJustImage = 'IntroHeroJustImage',
  IntroHeroImageVideo = 'IntroHeroImageVideo',
  IntroHeroImageVideoToggle = 'IntroHeroImageVideoToggle',
  IntroDescription = 'IntroDescription',
  IntroJoinButtonLabel = 'IntroJoinButtonLabel',
  AboutSectionToggle = 'AboutSectionToggle',
  AboutTitle = 'AboutTitle',
  AboutTitleToggle = 'AboutTitleToggle',
  AboutDescription = 'AboutDescription',
  AboutDescriptionToggle = 'AboutDescriptionToggle',
  AboutBrandImages = 'AboutBrandImages',
  PerksTitle = 'PerksTitle',
  PerksSectionToggle = 'PerksSectionToggle',
  PerksBulletStyle = 'PerksBulletStyle',
  PerksPerkList = 'PerksPerkList',
  PerksCompensation = 'PerksCompensation',
  PerksCompensationToggle = 'PerksCompensationToggle',
  PerksMediaAvailability = 'PerksMediaAvailability',
  PerksMediaAvailabilityToggle = 'PerksMediaAvailabilityToggle',
  PersonaTitle = 'PersonaTitle',
  FormJoinTitle = 'FormJoinTitle',
  FormJoinDescription = 'FormJoinDescription',
  FormJoinButtonLabel = 'FormJoinButtonLabel',
}

export const ApplicationPageTemplatesData: IApplicationPageTemplatesData[] = [
  {
    templateName: ProjectApplicationPageTemplateName.Quick,
    placeholderSrc: 'https://storage.googleapis.com/aspirex-dev-program/onboarding-template/8b6dc402-22e4-4995-91b7-6ea2db7826b3.png',
    name: 'Quick Template',
  },
  {
    templateName: ProjectApplicationPageTemplateName.Customizable,
    placeholderSrc: 'https://storage.googleapis.com/aspirex-dev-program/onboarding-template/5ba50690-3b4b-4589-93b1-299f904129e6.png',
    name: 'Customizable Template',
  },
];

export const ComponentsForTemplate: Record<ProjectApplicationPageTemplateName, ApplicationPageBuilderComponent[]> = {
  [ProjectApplicationPageTemplateName.Quick]: [
    ApplicationPageBuilderComponent.SettingsSimplifiedFonts,
    ApplicationPageBuilderComponent.IntroSubtitle,
    ApplicationPageBuilderComponent.IntroHeroImageVideo,
    ApplicationPageBuilderComponent.IntroHeroImageVideoToggle,
    ApplicationPageBuilderComponent.AboutTitle,
    ApplicationPageBuilderComponent.AboutDescription,
    ApplicationPageBuilderComponent.PerksCompensation,
    ApplicationPageBuilderComponent.PerksMediaAvailability,
    ApplicationPageBuilderComponent.FormJoinButtonLabel,
  ],
  [ProjectApplicationPageTemplateName.Customizable]: [
    ApplicationPageBuilderComponent.SettingsFavicon,
    ApplicationPageBuilderComponent.SettingsAccordions,
    ApplicationPageBuilderComponent.IntroSectionToggle,
    ApplicationPageBuilderComponent.IntroHeroImageVideo,
    ApplicationPageBuilderComponent.IntroHeroImageVideoToggle,
    ApplicationPageBuilderComponent.IntroDescription,
    ApplicationPageBuilderComponent.IntroJoinButtonLabel,
    ApplicationPageBuilderComponent.AboutTitle,
    ApplicationPageBuilderComponent.AboutBrandImages,
    ApplicationPageBuilderComponent.AboutSectionToggle,
    ApplicationPageBuilderComponent.PerksSectionToggle,
    ApplicationPageBuilderComponent.PerksBulletStyle,
    ApplicationPageBuilderComponent.PerksPerkList,
    ApplicationPageBuilderComponent.FormJoinButtonLabel,
    ApplicationPageBuilderComponent.FormJoinDescription,
    ApplicationPageBuilderComponent.PerksTitle,
    ApplicationPageBuilderComponent.PersonaTitle,
    ApplicationPageBuilderComponent.FormJoinTitle,
  ],
};

export const isBuilderComponentUsedByTemplate = (componentName: ApplicationPageBuilderComponent, template: ProjectApplicationPageTemplateName) => (
  includes(ComponentsForTemplate[template] || ComponentsForTemplate[ProjectApplicationPageTemplateName.Customizable], componentName)
);

export const SectionsForTemplate: Record<ProjectApplicationPageTemplateName, ApplicationPageSection[]> = {
  [ProjectApplicationPageTemplateName.Quick]: [
        ApplicationPageSection.Settings,
        ApplicationPageSection.Intro,
        ApplicationPageSection.About,
        ApplicationPageSection.Perks,
        ApplicationPageSection.Form,
  ],
  [ProjectApplicationPageTemplateName.Customizable]: [
      ApplicationPageSection.Settings,
      ApplicationPageSection.Intro,
      ApplicationPageSection.About,
      ApplicationPageSection.Perks,
      ApplicationPageSection.Form,
      ApplicationPageSection.Persona,
  ],
};

export const isSectionUsedByPageTemplate = (section: ApplicationPageSection, template: ProjectApplicationPageTemplateName) => (
  includes(SectionsForTemplate[template] || SectionsForTemplate[ProjectApplicationPageTemplateName.Customizable], section)
);

/**
 * For backwards compatibility this function assumes all fields are visible.
 * This functions is supossed to control wheter or not to show them in the template.
 * If you want to determine if a field belongs to a template use isBuilderComponentUsedByTemplate()
 */
export const isFieldVisibleInPage = (fieldName: ApplicationPageBuilderComponent, projectInput: Partial<ProgramInput>) => {
  const fieldsSettings = projectInput.onboardingTemplateConfig?.fieldsSettings || [];

  const fieldSettings = find(fieldsSettings, (f) => f.fieldName === fieldName.toString());

  if (!fieldSettings || isNil(fieldSettings.isHidden)) {
    return true;
  }

  return !fieldSettings.isHidden;
};

export const BrandLinksDescriptionForTemplate = {
  [ProjectApplicationPageTemplateName.Quick]: "Insert your brand's social account handles to showcase what you are all about.",
};

export const ApplicationCompensationOptions = [
  {
    label: 'Payment upon publishing or delivery of content',
    value: 'payment-upon-publishing',
  },
  {
    label: 'Free products / Store Credit',
    value: 'free-products-credit',
  },
  {
    label: 'Commissions via Affiliate Program',
    value: 'comissions-affiliate',
  },
  {
    label: 'Other Compensation',
    value: 'other',
  },
];

export const ApplicationAvailabilityOptions = [
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'TikTok',
    value: 'tiktok',
  },
  {
    label: 'YouTube',
    value: 'youtube',
  },
  {
    label: 'Pinterest',
    value: 'pinterest',
  },
  // {
  //   label: 'Twitter',
  //   value: 'twitter',
  // },
  // {
  //   label: 'Facebook',
  //   value: 'facebook',
  // },
  {
    label: 'User Generated Content',
    value: 'user-content',
  },
];
