export const getInstagramEmbedData = async (postLink: string) => {
  try {
    const resp = await fetch(
      `https://graph.facebook.com/v20.0/instagram_oembed?url=${postLink}&media_url&access_token=491285117675871|W1lKVML_Y7cB-bylpoaEUADW4VY`,
    );

    if (!resp.ok) {
      throw new Error(`Instagram oembed failed with status: ${resp.status || 0}`);
    }

    const json = await resp.json();

    if (json) {
      return json;
    }

    return null;
  } catch (e) {
    return null;
  }
};
