import React, { useEffect, useState } from 'react';
import {
  Drawer, Empty, Space, Typography,
} from '@revfluence/fresh';

import { Timeline } from 'antd';
import {
  flatMap, groupBy, isNull, map, sortBy,
} from 'lodash';
import {
  CalendarDayIcon, ClockRotateLeftIcon, DollarSignIcon, FileLinesIcon, ImageIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { LinkSimpleIcon, CertificateIcon } from '@revfluence/fresh-icons/regular/esm';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { OFFER_CONVERSION_TYPE as OFFER_CONVERSION_TYPE_GLOBAL } from '@affiliates/types/globalTypes';
import { useGetOfferLogByIdQuery } from '../../hooks/useGetOfferLogByIdQuery';
import styles from './OfferLogDrawer.scss';
import { convertToHHMM } from '../../utils/dateTimeUtils';
import { OFFER_LOG_NAME, OFFER_PAYOUT_TYPE, OFFER_SOURCE } from '../../types/globalTypes';
import LogIcon from './LogIcon';

const { Text, Title } = Typography;

type LogEntry = {
  createdDate: Date;
  name: string;
  fieldName: string;
  prevValue: string;
  newValue: string;
  isNewFileld: boolean;
};

type PayoutLog = {
  label: string;
  payoutType: OFFER_PAYOUT_TYPE;
  percentPayout: number | null | undefined;
  flatPayout: number | null | undefined;
};
const getPayoutText = (payout: PayoutLog): string => {
  switch (payout.payoutType) {
    case OFFER_PAYOUT_TYPE.CLICK:
      return `${payout.label} ($${payout.flatPayout})`;
    case OFFER_PAYOUT_TYPE.CONVERSION:
      return `${payout.label} ($${payout.flatPayout})`;
    case OFFER_PAYOUT_TYPE.SALE:
      return `${payout.label} (${payout.percentPayout}%)`;
    case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      return `${payout.label} ($${payout.flatPayout}+${payout.percentPayout}%)`;
    default:
      return '_';
  }
};

enum OFFER_LOG_NAME_VALUE {
  OFFER_NAME = 'Offer Name',
  OFFER_DESCRIPTION = 'Offer Description',
  OFFER_IMAGE = 'Offer Image',
  OFFER_EXPIRATION_DATE = 'Expiration Date',
  OFFER_CONVERSION_TRACKING = 'Conversion Tracking',
  OFFER_ALLOW_MULTIPLE_CONVERSIONS = 'Multiple Conversions',
  OFFER_CONVERSION_TYPE = 'Conversion Type',
  OFFER_PAYOUT_VALUE = 'Payout Value',
  OFFER_PERCENT_PAYOUT = 'Percent Payout',
  OFFER_URL_TRACKING = 'Offer URL Tracking',
  OFFER_STATUS = 'Status',
  OFFER_PROMO_GROUP_CODE = 'Promo Code Group',
  OFFER_PROMO_NEW_FLOW = 'Promo offer migrated to new flow',
  ADD_PAYOUT = 'Add Payout',
  REMOVE_PAYOUT = 'Remove Payout',
  DEFAULT_PAYOUT = 'Default Payout',
  LABEL_UPDATE_PAYOUT = 'Label Update Payout',
  VALUE_UPDATE_PAYOUT = 'Value Update Payout',
  CODE_PREFIX = 'Code Prefix',
  CODE_SUFFIX = 'Code Suffix',
  PURCHASE_RESTRICTION_AMOUNT = 'Purchase restriction',
  PURCHASE_RESTRICTION_QUANTITY = 'Purchase restriction',
  DISABLED_PURCHASE_RESTRICTIONS = 'Disabled purchase restrictions',
  PURCHASE_TYPE = 'Purchase Type',
}

const getIcon = (fieldName, source?: OFFER_SOURCE) => {
  switch (fieldName) {
    case OFFER_LOG_NAME.OFFER_EXPIRATION_DATE:
      return <LogIcon icon={<CalendarDayIcon fontSize={12} style={{ color: 'white' }} />} background="#0A375A" />;
    case OFFER_LOG_NAME.OFFER_NAME:
      return <LogIcon icon={<FileLinesIcon fontSize={12} style={{ color: 'white' }} />} background="#923662" />;
    case OFFER_LOG_NAME.OFFER_DESCRIPTION:
      return <LogIcon icon={<FileLinesIcon fontSize={12} style={{ color: 'white' }} />} background="#923662" />;
    case OFFER_LOG_NAME.OFFER_IMAGE:
      return <LogIcon icon={<ImageIcon fontSize={12} style={{ color: 'white' }} />} background="#510E2E" />;
    case OFFER_LOG_NAME.OFFER_STATUS:
      return <LogIcon icon={<CalendarDayIcon fontSize={12} style={{ color: 'white' }} />} background="#0A375A" />;
    case OFFER_LOG_NAME.OFFER_URL_TRACKING:
      return <LogIcon icon={<LinkSimpleIcon fontSize={12} style={{ color: 'white' }} />} background="#FCBBAE" />;
    case OFFER_LOG_NAME.OFFER_CONVERSION_TRACKING:
      return <LogIcon icon={<LinkSimpleIcon fontSize={12} style={{ color: 'white' }} />} background="#FCBBAE" />;
    case OFFER_LOG_NAME.OFFER_CONVERSION_TYPE:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.OFFER_PAYOUT_VALUE:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.OFFER_PERCENT_PAYOUT:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.ADD_PAYOUT:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.REMOVE_PAYOUT:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.DEFAULT_PAYOUT:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.LABEL_UPDATE_PAYOUT:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.VALUE_UPDATE_PAYOUT:
      return <LogIcon icon={<DollarSignIcon fontSize={12} style={{ color: 'white' }} />} background="#113C0B" />;
    case OFFER_LOG_NAME.OFFER_PROMO_GROUP_CODE:
      return <LogIcon icon={<CertificateIcon fontSize={12} style={{ color: 'white' }} />} background="#FFC53D" />;
    case OFFER_LOG_NAME.OFFER_PROMO_NEW_FLOW:
      return <LogIcon icon={<CertificateIcon fontSize={12} style={{ color: 'white' }} />} background="#FFC53D" />;
    default:
      return <LogIcon icon={source === OFFER_SOURCE.SHOPIFY ? <CertificateIcon fontSize={12} style={{ color: 'white' }} /> : <CertificateIcon fontSize={12} style={{ color: 'white' }} />} background={source === OFFER_SOURCE.SHOPIFY ? '#FFC53D' : '#FCBBAE'} />;
  }
};

interface IProps {
  offerId: number,
  onClose: () => void,
  open: boolean,
  source: OFFER_SOURCE,
}
export const OfferLogDrawer: React.FC<IProps> = (props) => {
  const {
    offerId, onClose, open, source,
  } = props;
  const [log, setLog] = useState<LogEntry[]>([]);
  const offerLogQuery = useGetOfferLogByIdQuery({
    variables: {
      offerId: Number(offerId),
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (offerLogQuery.data) {
      const newArray: LogEntry[] = flatMap(offerLogQuery.data.offerLogs, (obj) => {
        const createdDate = obj.createdDate;
        const userName = obj.userInfo.name;

        return map(obj?.updatePayload?.fields, (field) => ({
          createdDate,
          name: userName,
          fieldName: field.name,
          prevValue: field.prevValue,
          newValue: field.newValue,
          isNewFileld: false,
        }));
      });
      const sortedArray = sortBy(newArray, 'createdDate').reverse();
      setLog(sortedArray);
    }
  }, [offerLogQuery]);
  const renderLog = () => {
    const grouppedLog = groupBy(log, (l) => {
      const date = new Date(l.createdDate);
      const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'short', day: '2-digit', year: 'numeric' }).format(date);
      return formattedDate;
    });
    if (!Object.keys(grouppedLog).length) {
      return (
        <Empty
          size="small"
          description="No update available"
        />
      );
    }
    try {
      return Object.keys(grouppedLog).map((date, index) => (
        <div key={index}>
          <Title className={styles.logDate} level={5}>{date}</Title>
          <Timeline>
            {
              grouppedLog[date].sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()).reverse().map((l) => {
                switch (l.fieldName) {
                  case OFFER_LOG_NAME.OFFER_IMAGE:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the offer image.
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.OFFER_PROMO_NEW_FLOW:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          upgraded the offer
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.OFFER_CONVERSION_TYPE: {
                    type ConversionTypeMapper = {
                      [key in keyof typeof OFFER_CONVERSION_TYPE_GLOBAL]: string;
                    };
                    const conversionTypeMappe: ConversionTypeMapper = {
                      CONVERSIONS: 'Conversions',
                      DOWNLOADS: 'Downloads',
                      EMAIL_SUBMITS: 'Email Submits',
                      LEAD_GENERATIONS: 'Lead Generations',
                    };
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the offer
                          {' '}
                          {OFFER_LOG_NAME_VALUE[l.fieldName]}
                          {' '}
                          from
                          {' '}
                          <span className={styles.bold}>{conversionTypeMappe[l.prevValue]}</span>
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{conversionTypeMappe[l.newValue]}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  }
                  case OFFER_LOG_NAME.ADD_PAYOUT: {
                    const newPayout: PayoutLog = JSON.parse(l.newValue);
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          added a new payout
                          {' '}
                          <span className={styles.bold}>{getPayoutText(newPayout)}</span>
                          {' '}
                          in the offer.
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  }
                  case OFFER_LOG_NAME.REMOVE_PAYOUT: {
                    const prevPayout: PayoutLog = JSON.parse(l.prevValue);
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          removed a payout
                          {' '}
                          <span className={styles.bold}>{getPayoutText(prevPayout)}</span>
                          {' '}
                          from the offer.
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  }
                  case OFFER_LOG_NAME.DEFAULT_PAYOUT: {
                    const prevPayout: PayoutLog = JSON.parse(l.prevValue);
                    const newPayout: PayoutLog = JSON.parse(l.newValue);
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          changed the default payout
                          {' '}
                          from
                          {' '}
                          <span className={styles.bold}>{getPayoutText(prevPayout)}</span>
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{getPayoutText(newPayout)}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  }
                  case OFFER_LOG_NAME.LABEL_UPDATE_PAYOUT: {
                    const prevPayout: PayoutLog = JSON.parse(l.prevValue);
                    const newPayout: PayoutLog = JSON.parse(l.newValue);
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the payout label from
                          {' '}
                          <span className={styles.bold}>{prevPayout.label}</span>
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{newPayout.label}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  }
                  case OFFER_LOG_NAME.VALUE_UPDATE_PAYOUT: {
                    const prevPayout: PayoutLog = JSON.parse(l.prevValue);
                    const newPayout: PayoutLog = JSON.parse(l.newValue);
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the payout from
                          {' '}
                          <span className={styles.bold}>{prevPayout.payoutType === OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE ? ((isNull(prevPayout.flatPayout) || isNull(prevPayout.percentPayout)) ? '' : getPayoutText(prevPayout)) : ((isNull(prevPayout.flatPayout) && isNull(prevPayout.percentPayout)) ? '' : getPayoutText(prevPayout))}</span>
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{getPayoutText(newPayout)}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  }
                  case OFFER_LOG_NAME.OFFER_EXPIRATION_DATE:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the
                          {' '}
                          {source === OFFER_SOURCE.TUNE ? OFFER_LOG_NAME_VALUE[l.fieldName] : 'End Date'}
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{format(zonedTimeToUtc(parseISO(l.newValue), 'UTC'), 'MM/dd/yyyy')}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.OFFER_PROMO_ISUNGROUPED:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          changed the offer
                          {' from '}
                          <span className={styles.bold}>{JSON.parse(l.prevValue) ? 'Customize dates for each code' : 'Unified dates for all codes'}</span>
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{JSON.parse(l.newValue) ? 'Customize dates for each code' : 'Unified dates for all codes'}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.LIMIT_ONE_PER_CUSTOMER:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          {(l.prevValue === 'true' && l.newValue === 'false') ? 'disabled' : 'enabled'}
                          {' '}
                          Limit to one use per customer.
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.LIMIT_NEW_CUSTOMER:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          {(l.prevValue === 'true' && l.newValue === 'false') ? 'disabled' : 'enabled'}
                          {' '}
                          Limit usage to new customers.
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.ENABLED_CODE_USAGE_COUNT:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          enabled the limit for the number of times code can be used
                          {(JSON.parse(l.prevValue) !== null) ? (
                            <span className={styles.bold}>
                              {' '}
                              from
                              {' '}
                              {
                                (
                                  JSON.parse(l.prevValue)
                                )
                              }
                            </span>
                          )
                            : ''}
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{JSON.parse(l.newValue)}</span>
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.DISABLED_CODE_USAGE_COUNT:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          disabled the limit for the number of times the code can be used.
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.PROMO_DISCOUNT_VALUE:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the discount value from
                          {' from '}
                          <span className={styles.bold}>{l.prevValue}</span>
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{l.newValue}</span>
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.DISABLED_PURCHASE_RESTRICTIONS:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          disabled offer's Purchase Restrictions
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.OFFER_MIGRATED_TO_MULTIPLE_STORE:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          enabled the sync of this offer to multiple Shopify stores
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.OFFER_MIGRATED_TO_SINGLE_STORE:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          disabled the sync of this offer to multiple Shopify store
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.STORE_UPDATED:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          {l.prevValue}
                          {' '}
                          sync for the Shopify store
                          {' '}
                          {l.newValue}
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.PROMO_START_DATE:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the offer start date from
                          {' '}
                          <span className={styles.bold}>{format(zonedTimeToUtc(parseISO(l.prevValue), 'UTC'), 'MM/dd/yyyy')}</span>
                          {' '}
                          to
                          {' '}
                          <span className={styles.bold}>{format(zonedTimeToUtc(parseISO(l.newValue), 'UTC'), 'MM/dd/yyyy')}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  case OFFER_LOG_NAME.PROMO_END_DATE:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          {(l.newValue === 'null') ? (
                            <>
                              disabled expiration date from
                              {' '}
                              <span className={styles.bold}>
                                {format(zonedTimeToUtc(parseISO(l.prevValue), 'UTC'), 'MM/dd/yyyy')}
                              </span>
                              {' '}
                              in the offer.
                            </>
                            ) : (l.prevValue !== 'null') ? (
                              <>
                                updated the offer expiration date
                                {' '}
                                from
                                {' '}
                                <span className={styles.bold}>
                                  {format(zonedTimeToUtc(parseISO(l.prevValue), 'UTC'), 'MM/dd/yyyy')}
                                </span>
                                {' '}
                                to
                                {' '}
                                <span className={styles.bold}>
                                  {format(zonedTimeToUtc(parseISO(l.newValue), 'UTC'), 'MM/dd/yyyy')}
                                </span>
                              </>
  ) : (
    <>
      updated the offer expiration date to
      {' '}
      <span className={styles.bold}>
        {format(zonedTimeToUtc(parseISO(l.newValue), 'UTC'), 'MM/dd/yyyy')}
      </span>
    </>
                          )}
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                  default:
                    return (
                      <Timeline.Item dot={getIcon(l.fieldName, source)}>
                        <Text className={styles.logItem}>
                          <span className={styles.bold}>{l.name}</span>
                          {' '}
                          updated the
                          {' '}
                          {OFFER_LOG_NAME_VALUE[l.fieldName]}
                          {' '}
                          {l.prevValue && (
                          <>
                            from
                            {' '}
                            <span className={styles.bold}>{l.prevValue}</span>
                            {' '}
                          </>
)}

                          to
                          {' '}
                          <span className={styles.bold}>{l.newValue}</span>
                          .
                        </Text>
                        <Text className={styles.logTime}>{convertToHHMM(l.createdDate)}</Text>
                      </Timeline.Item>
                    );
                }
              })
            }
          </Timeline>
        </div>
      ));
    } catch (err) {
      return <></>;
    }
  };
  return (
    <Drawer
      bodyStyle={{ paddingLeft: '30px' }}
      open={open}
      title={(
        <Space align="center" direction="horizontal" className={styles.drawerTitle}>
          <ClockRotateLeftIcon />
          <Text>
            Offer Update History
          </Text>
        </Space>
      )}
      onClose={onClose}
    >
      {renderLog()}
    </Drawer>
  );
};
