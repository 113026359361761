/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
 defer, isArray, isNumber, first,
} from 'lodash';

import { useEffect } from 'react';
import { Handle } from './Handle';
import { RangeInput } from './RangeInput';
import { Track } from './Track';
import { positionInRangeRoundedToNearestStep, valueInRange } from './util';
import { IRangeSliderClasses } from './RangeSlider';

import styles from './RangeSlider.scss';

const { useCallback, useMemo, useState } = React;

type IRange = [number, number];

interface IMultiRangeProps {
  className?: string;
  defaultValue?: IRange;
  description?: string | JSX.Element;
  formatStr?: string;
  label?: string | JSX.Element;
  max: number;
  min: number;
  onChange?(newRange: IRange);
  onStoppedDragging?(currentRange: IRange);
  showInput?: boolean;
  showPlusOnMaxRange?: boolean;
  snapped?: boolean;
  step: number;
  subClassNames?: IRangeSliderClasses;
  disableMaxHandler?: boolean;
  /** Used to reset defaultValues even if the ranges haven't changes */
  idempotencyKey?: string;
}

function isRange(arr): arr is IRange {
  return isArray(arr) && arr.length === 2;
}

/**
 * @type {React.FunctionComponent}
 */
export const MultiRangeSlider: React.FunctionComponent<IMultiRangeProps> = (props) => {
  const {
    className,
    defaultValue,
    description,
    label,
    max,
    min,
    onChange,
    onStoppedDragging,
    showInput,
    showPlusOnMaxRange,
    snapped,
    step,
    subClassNames,
    formatStr,
    disableMaxHandler,
    idempotencyKey,
  } = props;
  const defaultRange: IRange = useMemo(() => {
    if (isRange(defaultValue)) {
      // Make sure defaultRange is between min and max.
      return defaultValue.map((value: number) =>
        valueInRange(value, min, max)) as IRange;
    } else {
      // Default to [min, max].
      return [min, max];
    }
  }, [defaultValue, max, min]);

  const [isDragging, setIsDragging] = useState(false);
  const [positions, setPositions] = useState(defaultRange);
  const [currentRange, setCurrentRange] = useState(defaultRange);

  useEffect(() => {
    // we must update positions and currentRange when defaultRange changes
    setPositions(defaultRange);
    setCurrentRange(defaultRange);
  }, [
    defaultRange,
    setCurrentRange,
    setPositions,
    idempotencyKey,
  ]);

  // Determines which handle should have a higher z-index.
  // We don't want to get stuck in the case where both handles overlap at the start of the slider for example.
  const [focused, setFocused] = useState(0);

  const handleChange = useCallback((index: number, newPosition: number) => {
    setFocused(index);

    const newPositions = [...positions] as IRange;
    const newValue = positionInRangeRoundedToNearestStep(newPosition, step, min, max);

    if (snapped) {
      if (currentRange[index] !== newValue) {
        newPositions[index] = newValue;
        setPositions(newPositions);
      }
    } else {
      newPositions[index] = newPosition;
      setPositions(newPositions);
    }

    // Don't trigger an onChange event unless value changes.
    if (currentRange[index] !== newValue) {
      const newRange = [...currentRange] as IRange;
      newRange[index] = newValue;
      setCurrentRange(newRange);
      onChange && onChange(newRange);
    }
  }, [max, min, onChange, positions, currentRange, snapped, step]);

  const handleClickPosition = useCallback((position: number) => {
    const distanceFromLeftHandle = Math.abs(position - positions[0]);
    const distanceFromRightHandle = Math.abs(position - positions[1]);
    const leftPosition = positionInRangeRoundedToNearestStep(position - positions[0], step, min, max);
    const rightPosition = positionInRangeRoundedToNearestStep(position - positions[1], step, min, max);

    /** If max handler is disabled, only the min handler is able to move */
    if (disableMaxHandler) {
      handleChange(1, 1);
      handleChange(0, position);
      return;
    }

    // Handles are in the same position
    if (leftPosition === rightPosition) {
      if (position - positions[0] < 0) {
        handleChange(0, position);
      } else {
        handleChange(1, position);
      }
    // Use distance of track click from handle to determine which handle will move.
    } else if (distanceFromLeftHandle < distanceFromRightHandle) {
      handleChange(0, position);
    } else {
      handleChange(1, position);
    }
  }, [disableMaxHandler, handleChange, max, min, positions, step]);

  const handleStartDragging = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleStoppedDragging = useCallback(() => {
    if (onStoppedDragging) {
      defer(() => onStoppedDragging(currentRange));
    }
    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging, onStoppedDragging, currentRange, setIsDragging]);

  const handleInputChange = useCallback((index: number, inputValue: string) => {
    const numberPart = first(inputValue.split('%'));
    let numberValue = numeral(numberPart).value();
    if (isNumber(numberValue)) {
      if (step === 0.01) {
        numberValue /= 100;
      }
    } else {
      numberValue = currentRange[index];
    }

    const newValue = positionInRangeRoundedToNearestStep(
      numberValue,
      step,
      index === 0 ? min : currentRange[0],
      index === 1 ? max : currentRange[1],
    );

    const newRange: IRange = [
      index === 0 ? newValue : currentRange[0],
      index === 1 ? newValue : currentRange[1],
    ];

    setCurrentRange(newRange);
    setPositions(newRange);
    onChange(newRange);
  }, [max, min, onChange, currentRange, step]);

  return (
    <div
      className={cx(className, (styles as any).Slider, {
        [styles.isDragging]: isDragging,
      })}
    >
      <div className={styles.label}>{label}</div>
      <Track
        className={cx({ [styles.isDragging]: isDragging })}
        min={min}
        max={max}
        range={positions}
        onClickPosition={handleClickPosition}
      >
        <Handle
          min={min}
          max={positions[1]}
          position={positions[0]}
          onChangePosition={(newPosition) => handleChange(0, newPosition)}
          isFocused={focused === 0}
          onStartDragging={handleStartDragging}
          onStoppedDragging={handleStoppedDragging}
        />
        <Handle
          min={disableMaxHandler ? max : positions[0]}
          max={max}
          position={positions[1]}
          onChangePosition={(newPosition) => handleChange(1, newPosition)}
          isFocused={focused === 1}
          onStartDragging={handleStartDragging}
          onStoppedDragging={handleStoppedDragging}
          disabled={disableMaxHandler}
        />
      </Track>
      <div className={cx(styles.description, subClassNames.description, {
        [styles.descriptionWithInput]: showInput,
        [styles.noDescription]: !description,
      })}
      >
        {showInput
          && (
          <>
            <RangeInput
              className={subClassNames.minInput || subClassNames.input}
              max={max}
              onChange={(inputValue) => handleInputChange(0, inputValue)}
              value={currentRange[0]}
              formatStr={formatStr}
            />
            to
            <RangeInput
              className={subClassNames.maxInput || subClassNames.input}
              max={max}
              onChange={(inputValue) => handleInputChange(1, inputValue)}
              value={currentRange[1]}
              showPlusOnMaxRange={showPlusOnMaxRange}
              formatStr={formatStr}
              disabled={disableMaxHandler}
            />
          </>
        )}
        {description}
      </div>
    </div>
  );
};

MultiRangeSlider.defaultProps = {
  description: '',
  label: '',
  showInput: false,
  showPlusOnMaxRange: false,
  snapped: false,
  subClassNames: {},
};
MultiRangeSlider.displayName = 'MultiRangeSlider';
