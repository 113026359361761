/// <reference types="@types/segment-analytics" />

declare global {
  interface Window { analytics: SegmentAnalytics.AnalyticsJS & { initialized: boolean }}
}

export const useAnalytics = (): SegmentAnalytics.AnalyticsJS => {
  if (typeof window === 'undefined') {
    return null;
  }

  return window.analytics;
};
