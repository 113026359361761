import * as React from 'react';
import {
  Button,
  IButtonProps,
  Space,
  Typography,
} from '@revfluence/fresh';
import { InstagramIcon } from '@revfluence/fresh-icons/brands/esm';
import { HashtagIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './ConnectInstagram.scss';

interface IProps {
  onClickConnect: IButtonProps['onClick'];
}

export const ConnectInstagram: React.FC<IProps> = React.memo((props) => {
  const { onClickConnect } = props;

  return (
    <div className={styles.ConnectInstagram}>
      <Space direction="vertical" size="large">
        <HashtagIcon className={styles.icon} />
        <Space direction="vertical">
          <Typography.Text strong>
            See social stats from submitted posts or social listening.
          </Typography.Text>
          <Typography.Text type="secondary">
            View project data from posts as they get submitted and connect your
            Instagram to see post data from tags and mentions about your brand
            using Social Listening.
          </Typography.Text>
        </Space>
        <Button
          className={styles.button}
          icon={<InstagramIcon />}
          onClick={onClickConnect}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          label="Connect Instagram"
          type="primary"
        >
          Connect Instagram
        </Button>
      </Space>
    </div>
  );
});
ConnectInstagram.displayName = 'ConnectInstagram';
