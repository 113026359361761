import { useMemo } from 'react';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from './useClientFeatureEnabled';

const pickWord = (toggle: boolean | undefined, version1: string, version2: string) => {
  if (toggle === true) {
    return version1;
  } else if (toggle === false) {
    return version2;
  }
  return '';
};

export const useFeatureFlagVerbiage = (options?) => {
  const isWorkflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW, options);

  return useMemo(() => ({
      Community: pickWord(isWorkflowEnabled, 'Group', 'Community'),
      community: pickWord(isWorkflowEnabled, 'group', 'community'),
      Communities: pickWord(isWorkflowEnabled, 'Groups', 'Communities'),
      communities: pickWord(isWorkflowEnabled, 'groups', 'communities'),
      Program: pickWord(isWorkflowEnabled, 'Project', 'Program'),
      program: pickWord(isWorkflowEnabled, 'project', 'program'),
      Programs: pickWord(isWorkflowEnabled, 'Projects', 'Programs'),
      programs: pickWord(isWorkflowEnabled, 'projects', 'programs'),
    }), [isWorkflowEnabled]);
};
