import { gql } from '@apollo/client';

export const ASSIGN_BUDGET_TO_BULK_PAYMENTS = gql`
  mutation AssignBudgetToBulkPayments($bulkAssignBudgetData: BulkAssignBudgetDataInput!) {
    updated: bulkAssignBudget(bulkAssignBudgetData: $bulkAssignBudgetData) {
      budgetData
      clientId
      createdDate
      failedPaymentIds
      id
      paymentGroupId
      paymentIds
      processStatus
      processType
    }
  }
`;
