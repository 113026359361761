import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const Website = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
    >
      <path
        fill={props.fill}
        d="
        M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM12 21.75C12.3281 21.75 13.2188 21.4219 14.2031 19.5C14.625 18.6562 14.9531 17.6719 15.2344 16.5H8.71875C9 17.6719 9.32812 18.6562 9.75 19.5C10.7344 21.4219 11.625 21.75 12 21.75ZM8.34375 14.25H15.6094C15.7031 13.5469 15.75 12.7969 15.75 12C15.75 11.25 15.7031 10.5 15.6094 9.75H8.34375C8.25 10.5 8.25 11.25 8.25 12C8.25 12.7969 8.25 13.5469 8.34375 14.25ZM15.2344 7.5C14.9531 6.375 14.625 5.39062 14.2031 4.54688C13.2188 2.625 12.3281 2.25 12 2.25C11.625 2.25 10.7344 2.625 9.75 4.54688C9.32812 5.39062 9 6.375 8.71875 7.5H15.2344ZM17.8594 9.75C17.9531 10.5 18 11.25 18 12C18 12.7969 17.9531 13.5469 17.8594 14.25H21.4688C21.6562 13.5469 21.75 12.7969 21.75 12C21.75 11.25 21.6562 10.5 21.4688 9.75H17.8594ZM16.0312 3.14062C16.6875 4.35938 17.2031 5.85938 17.5312 7.5H20.625C19.6406 5.57812 18 4.03125 16.0312 3.14062ZM7.92188 3.14062C5.95312 4.03125 4.3125 5.57812 3.32812 7.5H6.42188C6.75 5.85938 7.26562 4.35938 7.92188 3.14062ZM2.25 12C2.25 12.7969 2.29688 13.5469 2.48438 14.25H6.09375C6 13.5469 6 12.7969 6 12C6 11.25 6 10.5 6.09375 9.75H2.48438C2.29688 10.5 2.25 11.25 2.25 12ZM20.625 16.5H17.5312C17.2031 18.1875 16.6875 19.6875 16.0312 20.9062C18 20.0156 19.6406 18.4688 20.625 16.5ZM6.42188 16.5H3.32812C4.3125 18.4688 5.95312 20.0156 7.92188 20.9062C7.26562 19.6875 6.75 18.1875 6.42188 16.5Z
        "
      />
      <mask
        id={id}
        maskUnits="userSpaceOnUse"
      >
        <path
          d="
          M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM12 21.75C12.3281 21.75 13.2188 21.4219 14.2031 19.5C14.625 18.6562 14.9531 17.6719 15.2344 16.5H8.71875C9 17.6719 9.32812 18.6562 9.75 19.5C10.7344 21.4219 11.625 21.75 12 21.75ZM8.34375 14.25H15.6094C15.7031 13.5469 15.75 12.7969 15.75 12C15.75 11.25 15.7031 10.5 15.6094 9.75H8.34375C8.25 10.5 8.25 11.25 8.25 12C8.25 12.7969 8.25 13.5469 8.34375 14.25ZM15.2344 7.5C14.9531 6.375 14.625 5.39062 14.2031 4.54688C13.2188 2.625 12.3281 2.25 12 2.25C11.625 2.25 10.7344 2.625 9.75 4.54688C9.32812 5.39062 9 6.375 8.71875 7.5H15.2344ZM17.8594 9.75C17.9531 10.5 18 11.25 18 12C18 12.7969 17.9531 13.5469 17.8594 14.25H21.4688C21.6562 13.5469 21.75 12.7969 21.75 12C21.75 11.25 21.6562 10.5 21.4688 9.75H17.8594ZM16.0312 3.14062C16.6875 4.35938 17.2031 5.85938 17.5312 7.5H20.625C19.6406 5.57812 18 4.03125 16.0312 3.14062ZM7.92188 3.14062C5.95312 4.03125 4.3125 5.57812 3.32812 7.5H6.42188C6.75 5.85938 7.26562 4.35938 7.92188 3.14062ZM2.25 12C2.25 12.7969 2.29688 13.5469 2.48438 14.25H6.09375C6 13.5469 6 12.7969 6 12C6 11.25 6 10.5 6.09375 9.75H2.48438C2.29688 10.5 2.25 11.25 2.25 12ZM20.625 16.5H17.5312C17.2031 18.1875 16.6875 19.6875 16.0312 20.9062C18 20.0156 19.6406 18.4688 20.625 16.5ZM6.42188 16.5H3.32812C4.3125 18.4688 5.95312 20.0156 7.92188 20.9062C7.26562 19.6875 6.75 18.1875 6.42188 16.5Z
          "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

Website.displayName = 'WebsiteIcon';

export { Website };
