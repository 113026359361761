import * as React from 'react';
import {
  Col,
  Form,
  Input,
  Row,
} from '@revfluence/fresh';

import cx from 'classnames';

import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { ICommonFormElementProps } from '../../../types';
import { validateNonNegativeDecimal, validateNonNegativeInteger } from '../../../utils';

import styles from '../../../OfferForm.scss';

interface IProps extends ICommonFormElementProps<'flatPayout'> { }

export const OfferPayoutConversionAndSaleAmount: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const decimalRules = [
    { required: true, message: 'Please input your offer payout!' },
    { validator: validateNonNegativeDecimal },
  ];
  const integerRules = [
    { required: true, message: 'Please input your offer payout!' },
    { validator: validateNonNegativeInteger },
  ];

  return (
    <Row>
      <Col span={24}>
        <Row align="middle">
          <Col span={10}>
            <Form.Item name={name} rules={decimalRules}>
              <Input
                data-testid={testIds.costAndCommission}
                disabled={disabled}
                prefix="$"
                onKeyDown={UseNaturalNumbers}
                size="large"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col className={cx(styles.centered, styles.hThreeRem)} span={2}>
            +
          </Col>
          <Col span={10}>
            <Form.Item name="percentPayout" rules={integerRules}>
              <Input
                disabled={disabled}
                onKeyDown={UseNaturalNumbers}
                size="large"
                suffix="%"
                type="number"
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
});
