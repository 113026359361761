/**
 * Utility function to extract the Shopify store name from a given URL.
 *
 * @param {string} url - The URL from which to extract the store name.
 * @returns {string | null} - The extracted store name or null if not found.
 */
export const extractStoreName = (url) => {
    if (url) {
      const match = url.match(/^([^.]+)\.myshopify\.com$/);
      return match ? match[1] : null;
    }
    return null;
  };

  export default {
    extractStoreName,
  };
