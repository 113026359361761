import * as React from 'react';
import styles from './Title.scss';

interface TitleProps {
  title: React.ReactNode;
}

const Title: React.FC<TitleProps> = (props) => (
  <div className={styles.title}>
    { props.title }
  </div>
);

export default React.memo(Title);
