import gql from 'graphql-tag';

import { SEGMENT_FOLDER_FRAGMENT } from './fragments';

export const SAVE_SEGMENT_FOLDER = gql`
  mutation SaveSegmentFolderMutation($segmentFolder: SegmentFolderInput!) {
    folder: saveSegmentFolder(folder: $segmentFolder) {
      ...MemberPageSegmentFolder
    }
  }
  ${SEGMENT_FOLDER_FRAGMENT}
`;
