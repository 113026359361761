import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_CATALOG_COLLECTION_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import {
  CreateCatalogCollectionMutation,
  CreateCatalogCollectionMutationVariables,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/CreateCatalogCollectionMutation';

type IOptions = MutationHookOptions<CreateCatalogCollectionMutation, CreateCatalogCollectionMutationVariables>;

export const useCreateCatalogCollection = (options: IOptions = {}) => {
  const [createCollectionItem, { loading, data, error }] = useMutation(CREATE_CATALOG_COLLECTION_MUTATION, options);

  return {
    loading,
    collectionItem: data?.createCatalogCollection,
    error,
    createCollectionItem,
  };
};
