import * as React from 'react';
import {
 isEmpty, isNil,
} from 'lodash';
import { Link } from 'react-router-dom';

import { GetResourcesQuery_resources } from '../queries/types/GetResourcesQuery';
import { SOCIAL_POST_APP_ID } from '../constants/applicationIds';

export const getMentionIGDMMessage = (sentByUser: boolean, to: string, from: string) => (sentByUser
  ? `You mentioned @${to} in your story`
  : `@${from} mentioned you in their story`);

const SCOPES_SEPARATOR = ',';
const OUTREACH_SCOPES = ['creator_marketplace', 'pages_messaging', 'instagram_manage_messages', 'pages_manage_metadata'];

export const invalidIGDMMessage = (igResource: GetResourcesQuery_resources) => {
  if (isNil(igResource)) {
    return (
      <div>
        All invites will be sent through email. To use direct messaging for sending invitations to eligible creators, please go to
        {' '}
        <Link to={`/settings/${SOCIAL_POST_APP_ID}`}>
          Settings
        </Link>
        {' '}
        to select an Instagram account
      </div>
    );
  }

  const scopes = new Set<string>(igResource.scopes.split(SCOPES_SEPARATOR));
  let message = '';

  OUTREACH_SCOPES.forEach((importantScope) => {
    if (!scopes.has(importantScope)) {
      message = `All invites will be sent through email. The account @${igResource.authProvider.userExternalDisplayId} has not shared permission to access creator marketplace with Aspire.`;
      return message;
    }
  });

  if (isEmpty(message) && (isEmpty(igResource.authProvider.access) || !igResource.authProvider.access.includes('creator_marketplace'))) {
    return `All invites will be sent through email. The account @${igResource.authProvider.userExternalDisplayId} is not eligible for Instagram Creator Marketplace.`;
  }

  return message;
};
