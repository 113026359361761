._SocialLinks_wc9o5_1 {
  display: flex;
  column-gap: 26px;
  border-top: 1px solid var(--gray-5);
  border-bottom: 1px solid var(--gray-5);
  padding: 16px 6px;
  margin-top: 16px;
  align-items: center;
}
._SocialLinks_wc9o5_1 a {
  display: flex;
}