import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useParsedRouterSearch } from '@frontend/app/hooks';
import { useProgramForLandingPagePath } from '@frontend/app/hooks/useProgramForLandingPagePath';
import { ProjectApplicationPage } from '@frontend/app/components';
import { ProjectApplicationPageTemplateName } from '../../applicationPageUtils';

interface IRouteParams {
  customLandingPagePath: string;
}

export const LandingPageProvider: React.FC = React.memo(() => {
  const { customLandingPagePath } = useParams<IRouteParams>();

  const {
    clientId,
  } = useParsedRouterSearch();

  const {
    loading,
    error,
    data: {
      programForLandingPage: {
        applicationPageTemplateName,
        onboardingTemplateConfig = { version: null },
        hasUnpaidOffer = false,
        id: projectId,
        title: projectTitle,
      } = {},
    } = {},
  } = useProgramForLandingPagePath(customLandingPagePath, clientId);

  if (loading) {
    return null;
  }

  if (error) {
    return <span>{error.message}</span>;
  }

  return (
    <ProjectApplicationPage
      template={applicationPageTemplateName as ProjectApplicationPageTemplateName}
      isUserView
      config={onboardingTemplateConfig}
      hasUnpaidOffer={hasUnpaidOffer}
      projectId={projectId}
      projectTitle={projectTitle}
    />
  );
});

LandingPageProvider.displayName = 'LandingPageProvider';
