import gql from 'graphql-tag';
import { OFFER_FRAGMENT } from './fragments/OfferFragment';

export const CREATE_OFFER = gql`
  mutation CreateOffer($data: CreateOfferInput!) {
    offer: createOffer(data: $data) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`;
