import {
  filter, isEmpty, map, size, trim,
} from 'lodash';

import { EventName } from '@common';
import { ApplicationFormFieldsInput } from '@frontend/app/types/globalTypes';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useAuth } from '@frontend/context/authContext';
import {
  useAddSpecificationMutation,
  useGetAllWorkletsQuery,
  useGetSpecificationTemplateByNameQuery,
  useGetInstalledApplicationIds,
} from '@frontend/app/hooks';
import { SaveProgramMutation_program } from '@frontend/app/queries/types/SaveProgramMutation';
import {
  useSaveProgramMutation,
} from '@frontend/app/containers/Communities/AddOrEditCommunity/hooks/useSaveProgramMutation';
import { filterWorkletsForUninstalledApps } from '@frontend/app/containers/Projects/utils';
import { useAddWorklets } from '@frontend/app/containers/Projects/hooks';
import { CustomProjectTemplateName } from '@frontend/app/containers/Projects/constants';
import { CREATOR_OFFERS } from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/context/model';

import { ProjectApplicationPageTemplateName } from '@frontend/app/containers/Projects/applicationPageUtils';
import {
  ISetupInput,
  IMarketplaceListingInput,
  IRequirementsInput,
  ProjectType,
} from '../types';

const TEMPLATE_NAME = {
  [ProjectType.InfluencerCampaign]: 'Influencer Campaign',
  [ProjectType.ProductSeeding]: 'Product Seeding',
  [ProjectType.CustomProject]: 'Influencer Campaign',
  [ProjectType.AmbassadorProgram]: 'Ambassador Program',
};

export const useCreateProject = (
  projectType: ProjectType,
  applicationFormFields: ApplicationFormFieldsInput,
) => {
  const templateName = TEMPLATE_NAME[projectType] || null;
  const addEvent = useEventContext();
  const [saveProgram] = useSaveProgramMutation();
  const { addWorklets } = useAddWorklets();
  const [addSpecification] = useAddSpecificationMutation();
  const installedApps = useGetInstalledApplicationIds();
  const { user } = useAuth();

  const {
    data: {
      template = undefined,
    } = {},
  } = useGetSpecificationTemplateByNameQuery({
    variables: { templateName },
    skip: !templateName,
  });

  const {
    data: {
      worklets: defaultWorklets = [],
    } = {},
  } = useGetAllWorkletsQuery({
    variables: {
      specKeys: template?.workletSpecKeys,
    },
    skip: isEmpty(template?.workletSpecKeys),
  });

  const createSpecification = async (requirementsInput: IRequirementsInput): Promise<{ specKey: string, workletSpecKeys: string[] }> => {
    let worklets = filterWorkletsForUninstalledApps(defaultWorklets, installedApps);
    if (!requirementsInput.offers_commission) {
      worklets = filter(
        worklets,
        (worklet) => worklet.specURI !== 'UID_89815092-abc1-4512-b490-c4b95e4590ee',
      );
    }

    const workletSpecKeys = await addWorklets(worklets);
    const {
      data: {
        specKey = null,
      } = {},
    } = await addSpecification({
      variables: {
        workletSpecKeys,
      },
    });

    return { specKey, workletSpecKeys };
  };

  const createProject = async (
    setupInput: ISetupInput,
    requirementsInput: IRequirementsInput,
    marketplaceListingInput: IMarketplaceListingInput,
    publishApplicationPage?: boolean,
  ): Promise<SaveProgramMutation_program> => {
    const { specKey, workletSpecKeys } = await createSpecification(requirementsInput);
    const creatorOffers = filter(CREATOR_OFFERS, (offer) => !!requirementsInput[offer.key]);
    const perkItems = map(creatorOffers, (offer) => offer.label);
    const hasUnpaidOffer = (
      requirementsInput.offers_product
      && !requirementsInput.offers_payment
      && !requirementsInput.offers_commission
      && !requirementsInput.offers_other
    );

    const projectInput = {
      title: trim(setupInput.title),
      description: trim(setupInput.description),
      specKey,
      owner: user.sub,
      customLandingPagePath: trim(setupInput.title),
      splashImageUrl: marketplaceListingInput.marketplace_image_url,
      applicationFormFields,
      columns: {
        memberFieldSchemaIds: map(applicationFormFields?.memberFieldSchemas, (schema) => schema.schemaId),
        dbColumns: map(applicationFormFields?.dbColumns, (column) => column.name),
      },
      applicationPageTemplateName: ProjectApplicationPageTemplateName.Customizable,
      onboardingTemplateConfig: {
        skipSelectPageTemplate: true,
        settings: {
          logo: marketplaceListingInput.logo_url,
          page_online: true,
          page_color: {
            background_color: '#ffffff',
          },
          form_color: {
            background_color: '#fdfdfd',
          },
          cta_styling: {
            font_color: '#000000',
            button_color: '#ffffff',
          },
          headline_styling: {
            fill_color: '#000000',
          },
          body_styling: {
            fill_color: '#000000',
          },
        },
        intro: {
          title: trim(setupInput.title),
          description: trim(setupInput.summary),
          hero_image: marketplaceListingInput.hero_image_url,
          show: true,
        },
        about: {
          description: trim(setupInput.description),
          brand_images: [
            marketplaceListingInput.brand_image_1_url,
            marketplaceListingInput.brand_image_2_url,
            marketplaceListingInput.brand_image_3_url,
          ],
          show: true,
        },
        perks: {
          items: perkItems,
          show: true,
        },
        persona: {
          show: false,
        },
        application: {
          description: 'We would love for you to consider joining our family. Our goal is to create a better way to communicate and learn from our customers and fans so that we can ultimately better serve your needs. Apply Now!',
        },
      },
      hasUnpaidOffer,
      published: publishApplicationPage || false,
      templateName,
    };
    const {
      data: {
        program: project = undefined,
      } = {},
    } = await saveProgram({
      variables: {
        program: projectInput,
      },
    });

    addEvent(
      EventName.CreatedProject,
      {
        spec_size: size(workletSpecKeys),
        spec: workletSpecKeys,
        projectId: project.id,
        template: templateName || CustomProjectTemplateName,
      },
    );

    // do a second update to create an email template for the new project
    // as ProgramResolver will call updateProgram endpoint when there is an id
    // and that endpoint is the one responsible for creating an email template
    await saveProgram({
      variables: {
        program: {
          id: project.id,
          ...projectInput,
        },
      },
    });

    return project;
  };

  return {
    createProject,
  };
};
