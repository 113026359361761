import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_BRAND_CATALOG_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import {
  CreateBrandCatalogMutation,
  CreateBrandCatalogMutationVariables,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/CreateBrandCatalogMutation';

type IOptions = MutationHookOptions<CreateBrandCatalogMutation, CreateBrandCatalogMutationVariables>;

export const useCreateCatalog = (options: IOptions = {}) => {
  const [createCatalog, { data, loading, error }] = useMutation(CREATE_BRAND_CATALOG_MUTATION, options);

  return {
    loading,
    catalog: data?.createBrandCatalog,
    createCatalog,
    error,
  };
};
