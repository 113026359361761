import * as React from 'react';

const { useState, useEffect } = React;

export function useIsServer() {
  const [isServer, setIsServer] = useState(true);

  useEffect(() => {
    setIsServer(false);
  }, []);

  return isServer;
}
