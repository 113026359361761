import * as React from 'react';
import { Tooltip } from '@revfluence/fresh';

import styles from './SummaryItem.scss';

interface IProps {
  icon: React.ReactNode;
  label: React.ReactNode;
  tooltip: React.ReactNode;
}

export const SummaryRewardItem: React.FC<IProps> = React.memo((props) => {
  const {
    icon,
    label,
    tooltip,
  } = props;

  const contents = (
    <div className={styles.SummaryItem}>
      {icon}
      <div>
        {label}
      </div>
    </div>
  );

  if (!tooltip) {
    return contents;
  }

  return (
    <Tooltip placement="bottom" title={tooltip}>
      {contents}
    </Tooltip>
  );
});
SummaryRewardItem.displayName = 'SummaryRewardItem';
