import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { cn } from '@frontend/shadcn/lib/utils';
import React from 'react';

interface SwitcherItem {
  key: string;
  label: string;
}

interface SwitcherProps {
  items: SwitcherItem[];
  type: 'single' | 'multiple';
  activeKeys: string[];
  onChange: (keys: string[]) => void;
}

const Switcher: React.FC<SwitcherProps> = ({
 items, type, activeKeys, onChange,
}) => (
    // @ts-ignore
  <ToggleGroup
    type={type}
    value={type === 'single' ? activeKeys[0] : activeKeys}
    onValueChange={(value) => {
        if (!value || !value[0]) {
          return;
        }
        if (type === 'single') {
          onChange([value as string]);
        } else {
          onChange(value as string[]);
        }
      }}
    className="flex gap-0 rounded overflow-hidden"
  >
    {items.map((item) => (
      <ToggleGroupItem
        key={item.key}
        value={item.key}
        className={cn(
            'px-2 py-[1px] text-sm rounded-none h-auto',
            'data-[state=on]:bg-border border data-[state=on]:border-primary',
            'data-[state=off]:bg-primary-foreground border data-[state=off]:border-border',
            'first:rounded-l last:rounded-r',
          )}
      >
        {item.label}
      </ToggleGroupItem>
      ))}
  </ToggleGroup>
  );

export default Switcher;
