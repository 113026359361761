/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10lcb_157 {
  display: flex;
}

._justify-content-space-between_10lcb_161 {
  justify-content: space-between;
}

._tabular-nums_10lcb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10lcb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AddCardForm_10lcb_178 ._title_10lcb_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._AddCardForm_10lcb_178 ._subtitle_10lcb_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._AddCardForm_10lcb_178 ._title_10lcb_178 {
  margin-bottom: 0.9375rem;
  text-align: center;
}
._AddCardForm_10lcb_178 ._subtitle_10lcb_188 {
  text-align: center;
}
._AddCardForm_10lcb_178 ._element_10lcb_205 {
  margin-top: 1.875rem;
}
._AddCardForm_10lcb_178 ._error_10lcb_208 {
  margin-bottom: 1.875rem;
}
._AddCardForm_10lcb_178 ._btnWrapper_10lcb_211 {
  margin-top: 3.75rem;
  text-align: center;
}