// eslint-disable-next-line
export function deepEqual<T extends Record<string, any>>(
    obj1: T,
    obj2: T,
    ignoreFields: string[] = [],
): boolean {
    const filteredObj1 = removeFields(obj1, ignoreFields);
    const filteredObj2 = removeFields(obj2, ignoreFields);

    return compare(filteredObj1, filteredObj2);
}
// eslint-disable-next-line
function removeFields<T extends Record<string, any>>(obj: T, fieldsToRemove: string[]): T {
    // eslint-disable-next-line
    const newObj: Record<string, any> = { ...obj };
    for (const field of fieldsToRemove) {
        delete newObj[field];
    }
    return newObj as T;
}
function compare(a, b) {
    return JSON.stringify(Object.fromEntries(Object.entries(a).sort())) == JSON.stringify(Object.fromEntries(Object.entries(b).sort()));
}
