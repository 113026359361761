import gql from 'graphql-tag';

import { AUTOMATION_EXECUTION_LIFECYCLE_STATS_FRAGMENT } from './AutomationExecutionLifecycleStats';

export const AUTOMATION_EXECUTION_PERIODIC_STATS_FRAGMENT = gql`
  fragment AutomationExecutionPeriodicStatsFragment on AutomationExecutionPeriodicStats {
    allTime {
      ...AutomationExecutionLifecycleStatsFragment
    }
    today {
      ...AutomationExecutionLifecycleStatsFragment
    }
    yesterday {
      ...AutomationExecutionLifecycleStatsFragment
    }
    pastWeek {
      ...AutomationExecutionLifecycleStatsFragment
    }
  }
  ${AUTOMATION_EXECUTION_LIFECYCLE_STATS_FRAGMENT}
`;
