/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._CreatorDetailOverlay_10si6_32 ._content_10si6_32 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  max-width: 75rem;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: initial;
  justify-content: initial;
}
._CreatorDetailOverlay_10si6_32 ._creatorDetail_10si6_43 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
}
._CreatorDetailOverlay_10si6_32 ._container_10si6_46 {
  padding: 16px;
  background-color: #fdfdfd;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
}
._CreatorDetailOverlay_10si6_32 ._container_10si6_46:not(:first-child) {
  margin-top: 32px;
}
._CreatorDetailOverlay_10si6_32 ._container_10si6_46 ._header_10si6_55 {
  margin: 16px;
  font-size: 24px;
  font-weight: bold;
}