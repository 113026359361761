import * as React from 'react';

import { LazyImage } from '@components';
import { Content, IContentProps } from './Content';

import { getThumbnailMedia } from '../utils/getContentImage';

import styles from './ImageContent.scss';

/**
 * @class
 * @extends {React.Component}
 */
// eslint-disable-next-line react/prefer-stateless-function
export class ImageContent extends React.Component<IContentProps> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritdoc
   */
  public render() {
    const { content, classNames } = this.props;

    return (
      <Content content={content} classNames={classNames.concat(styles.ImageContent)}>
        <LazyImage className={styles.image} src={getThumbnailMedia(content)} />
      </Content>
    );
  }
}
