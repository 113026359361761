import { ContentReviewStatus } from '@frontend/app/types/globalTypes';

// Add this new type definition
export type ExtendedContentReviewStatus = ContentReviewStatus | 'Live';

export enum ContentReviewGroup {
  All = 'All',
  Pending = 'Pending',
  ChangesRequested = 'ChangesRequested',
  Rejected = 'Rejected',
  ApprovedWithComments = 'ApprovedWithComments',
  Approved = 'Approved',
  Live = 'Live',
}
export const ContentReviewGroupLabel: Record<ContentReviewGroup, string> = {
  [ContentReviewGroup.All]: 'All Statuses',
  [ContentReviewGroup.Pending]: 'Pending Review',
  [ContentReviewGroup.ChangesRequested]: 'Changes Requested',
  [ContentReviewGroup.Rejected]: 'Rejected',
  [ContentReviewGroup.ApprovedWithComments]: 'Approved with Comments',
  [ContentReviewGroup.Approved]: 'Approved',
  [ContentReviewGroup.Live]: 'Live',
};
export const StatusesForGroup: Record<ContentReviewGroup, ExtendedContentReviewStatus[]> = {
  [ContentReviewGroup.All]: [
    ContentReviewStatus.Pending,
    ContentReviewStatus.ChangesRequested,
    ContentReviewStatus.Rejected,
    ContentReviewStatus.ApprovedWithComments,
    ContentReviewStatus.Approved,
    'Live' as ExtendedContentReviewStatus,
  ],
  [ContentReviewGroup.Pending]: [ContentReviewStatus.Pending],
  [ContentReviewGroup.ChangesRequested]: [ContentReviewStatus.ChangesRequested],
  [ContentReviewGroup.Rejected]: [ContentReviewStatus.Rejected],
  [ContentReviewGroup.ApprovedWithComments]: [ContentReviewStatus.ApprovedWithComments],
  [ContentReviewGroup.Approved]: [ContentReviewStatus.Approved],
  [ContentReviewGroup.Live]: ['Live' as ExtendedContentReviewStatus],
};
