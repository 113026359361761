/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import { isEmpty, isNil } from 'lodash';
import {
  Image,
  Space,
} from '@revfluence/fresh';

const { useState } = React;
interface IGDMAttachmentMessageItemProps {
  attachmentsUrl: string;
}

export const IGDMAttachmentMessageItem: React.FC<IGDMAttachmentMessageItemProps> = ({
  attachmentsUrl,
}) => {
  const [imageError, setImageError] = useState(false);
  const [videoError, setVideoError] = useState(false);
  if (isNil(attachmentsUrl) || isEmpty(attachmentsUrl)) return null;

  return (
    <Space
      direction="vertical"
      size={8}
      wrap
    >
      {
        !isNil(attachmentsUrl && !isEmpty(attachmentsUrl))
        && !imageError && (
          <a href={attachmentsUrl} target="_blank">
            <Image
              preview={false}
              onError={() => setImageError(true)}
              src={attachmentsUrl}
              width={201}
              height={300}
              style={{ borderRadius: 6 }}
            />
          </a>
        )
      }
      {
        !isNil(attachmentsUrl) && !isEmpty(attachmentsUrl)
        && imageError && !videoError && (
          <a href={attachmentsUrl} target="_blank">
            <video
              src={attachmentsUrl}
              style={{ width: '201px', height: '300px', borderRadius: 6 }}
              onError={() => setVideoError(true)}
            />
          </a>
        )
      }
    </Space>
  );
};
