import * as React from 'react';
import {
  first,
  isEmpty,
  map,
  isString,
  trim,
} from 'lodash';

import { TMember } from '@affiliates/components/MembersWizard/types';
import { useApolloClient, useGetOffersBySearchQuery } from '@affiliates/hooks';
import { OFFER_PROMO_CODE_STATUS } from '@affiliates/types/globalTypes';
import { GetOffersBySearchQuery_offers, GetOffersBySearchQuery_offers_promos_affiliates } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { GetMemberInfoForOffer, GetMemberInfoForOfferVariables } from '@affiliates/queries/types/GetMemberInfoForOffer';
import { AppendLinksToMembers, AppendLinksToMembersVariables } from '@affiliates/queries/types/AppendLinksToMembers';
import { AppendMembers, AppendMembersVariables } from '@affiliates/queries/types/AppendMembers';
import {
  ForceCheckInLinks,
  ForceCheckInLinksVariables,
} from '@affiliates/queries/types/ForceCheckInLinks';
import {
  ForceCheckInPromos,
  ForceCheckInPromosVariables,
} from '@affiliates/queries/types/ForceCheckInPromos';
import {
  FORCE_CHECK_IN_AFFILIATE_OFFER_LINKS_MUTATION,
  FORCE_CHECK_IN_AFFILIATE_OFFER_PROMOS_MUTATION,
  GET_MEMBER_INFO_FOR_OFFER,
  UPDATE_LINKS_MEMBERS_MUTATION,
  UPDATE_OFFER_MEMBERS_MUTATION,
} from '@affiliates/queries';
import { OFFER_TYPE } from '@affiliates/types/globalTypes';
import { EventName, logger } from '@common';
import { useAddProjectEvent } from '@frontend/app/containers/Projects/hooks';
import { message } from '@revfluence/fresh';
import { IWorkflowWizardProps } from '..';

const { useCallback, useMemo, useState } = React;
const failedStatus = [
  OFFER_PROMO_CODE_STATUS.FAILED_DUPLICATE,
  OFFER_PROMO_CODE_STATUS.FAILED_OTHER,
  OFFER_PROMO_CODE_STATUS.FAILED_TOO_LONG,
];

export const useWorkflowWizard = (props: IWorkflowWizardProps) => {
  const { offerSource, programId, membersIds } = props;
  const { addProjectEvent } = useAddProjectEvent();
  const client = useApolloClient();
  const [isCompleted, setCompleted] = useState(false);
  const { data, loading } = useGetOffersBySearchQuery({
    variables: {
      query: {
        type: offerSource,
        programId,
      },
    },
  });
  const offer = useMemo(() => (data ? first(data.offers) : undefined), [data]);
  const findAffiliateData = useCallback((memberId: number): GetOffersBySearchQuery_offers_promos_affiliates | null => {
    const promo = first(offer?.promos || []);
    if (promo) {
      return promo.affiliates.find((affiliate) => affiliate.affiliate.memberId === memberId);
    }
    return null;
  }, [offer]);
  const factoryFetchMembers = useCallback((offer: GetOffersBySearchQuery_offers) => async (): Promise<readonly TMember[]> => {
    const memberInfo = await client.query<GetMemberInfoForOffer, GetMemberInfoForOfferVariables>({
      query: GET_MEMBER_INFO_FOR_OFFER,
      variables: {
        // memberIds can sometimes be strings
        membersIds: map(membersIds, (id) => (isString(id) ? parseInt(id, 10) : id)),
        offerId: offer.id,
      },
    });
    const members: TMember[] = map(memberInfo.data.memberInfoForOffer, (m): TMember => {
      const affiliateData = findAffiliateData(m.memberId);
      const isFailed = affiliateData && failedStatus.includes(affiliateData.status);
      return ({
        affiliateOfferId: 0,
        firstName: trim(m.memberFirstName || ''),
        id: m.memberId || 0,
        inOffer: m.inOffer,
        instagramUsername: m.instagramUsername,
        lastName: trim(m.memberLastName || ''),
        name: trim(m.memberName || ''),
        previousCode: {
          code: isFailed ? affiliateData.externalDiscountCode : null,
          end: isFailed ? affiliateData.endDate : null,
          start: isFailed ? affiliateData.startDate : null,
        },
        // payoutId: isFailed ? affiliateData.offer_payout_id : null,
        payoutId: null,
        forceCheckIn: m.inOffer && !m.previouslyUnsuccessful,
        programIds: m.programIds,
      });
    });
    return members;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersIds, offer, client]);
  const factoryHandleSave = useCallback((offerSource: OFFER_TYPE) =>
    async (variables, _programIds, forceCheckInVariables): Promise<void> => {
      switch (offerSource) {
        case (OFFER_TYPE.LINK): {
          if (!isEmpty(variables)) {
            await client.mutate<AppendLinksToMembers, AppendLinksToMembersVariables>({
              mutation: UPDATE_LINKS_MEMBERS_MUTATION,
              variables,
            });
            setCompleted(true);
            addProjectEvent(EventName.SalesTrackingGenerateLink, {
              offerId: variables.id,
              memberCount: variables.affiliates.length,
              source: 'workflow',
              createdFrom: 'workflow',
            });
          }
          if (!isEmpty(forceCheckInVariables)) {
            await client.mutate<ForceCheckInLinks, ForceCheckInLinksVariables>({
              mutation: FORCE_CHECK_IN_AFFILIATE_OFFER_LINKS_MUTATION,
              variables: forceCheckInVariables,
            });
          }
          break;
        }
        case (OFFER_TYPE.PROMO_CODE): {
          if (!isEmpty(variables) && !offer?.isReadOnly) {
            await client.mutate<AppendMembers, AppendMembersVariables>({
              mutation: UPDATE_OFFER_MEMBERS_MUTATION,
              variables,
            });
            setCompleted(true);
            addProjectEvent(EventName.SalesTrackingGeneratePromoCodes, {
              offerId: variables.id,
              memberCount: variables.affiliates.length,
              source: 'workflow',
              createdFrom: 'workflow',
            });
          }
          if (offer?.isReadOnly) {
            message.warning('The connected offer is synced with multiple Shopify stores. Manual addition of members to this offer is not supported currently. If you need to add members, kindly connect with our support team for assistance.');
          }
          if (!isEmpty(forceCheckInVariables)) {
            await client.mutate<ForceCheckInPromos, ForceCheckInPromosVariables>({
              mutation: FORCE_CHECK_IN_AFFILIATE_OFFER_PROMOS_MUTATION,
              variables: forceCheckInVariables,
            });
          }
          break;
        }
        default:
          logger.warn('Invalid offer type on save function');
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, offerSource, offer]);
  const fetchMembers = useMemo(() => factoryFetchMembers(offer), [offer, factoryFetchMembers]);
  const onSave = useMemo(() => factoryHandleSave(offerSource), [offerSource, factoryHandleSave]);
  return {
    onSave,
    offer,
    loadingOffer: loading,
    fetchMembers,
    isCompleted,
  };
};
