import { useMutation, MutationHookOptions } from '@apollo/client';
import { SAVE_CONTENT_GUIDELINES_MUTATION } from '@frontend/app/queries';

import {
  SaveContentGuidelines,
  SaveContentGuidelinesVariables,
} from '@frontend/app/queries/types/SaveContentGuidelines';

type IOptions = MutationHookOptions<SaveContentGuidelines, SaveContentGuidelinesVariables>;

export const useSaveContentGuidelinesForProjectMutation = (options: IOptions = {}) =>
  useMutation<SaveContentGuidelines, SaveContentGuidelinesVariables>(
    SAVE_CONTENT_GUIDELINES_MUTATION,
    options,
  );
