import * as React from 'react';
import cx from 'classnames';
import { isBoolean, isEmpty, unescape } from 'lodash';
import { AUTOMATION_IMAGE_URL } from '@frontend/app/constants/imageUrls';

import { LazyImage } from '@components';
import { CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { Typography } from 'antd';

import { IThread } from '@frontend/app/hooks';
import { useReactiveVar } from '@apollo/client';
import { formatMessageDate } from '@frontend/app/utils';

import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import { Checkbox } from '@revfluence/fresh';
import { MessageType } from '@frontend/app/types/globalTypes';
import { useResourceContext } from '@frontend/app/context';
import { getThreadTitle } from './utils';
import { selectedThreadsVar, useMessagingContext } from './context/MessagingAppContext';
import { ThreadSingleActionButtons } from './ThreadSingleActionButtons';

import styles from './ThreadListItem.scss';
import { useMemberFieldPartitions } from '../MemberDetail/MemberInfo/MemberOverview/hooks';

const { Text } = Typography;

interface IProps {
  thread: IThread;
  selectedThread: IThread;
  onSelectThread(): void;
  userExternalDisplayId: string;
  shouldShowActions: boolean;
  defaultHovered?: boolean;
  className?: string;
  users?: GetAllUsersQuery_users[];
}

/**
 * @type {React.FC}
 */
export const ThreadListItem: React.FC<IProps> = React.memo((props) => {
  const {
    thread,
    className,
    users,
  } = props;

  const { selectedThread } = useMessagingContext();
  const selectedThreads = useReactiveVar(selectedThreadsVar);

  const onThreadItemSelectUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const checked = event.target.checked;
    if (checked) {
      const selectedThreadObjects: { id: string, label?: string, isRead?: boolean } = {
        id: thread.id,
      };
      if (!isEmpty(thread?.userLabels)) {
        selectedThreadObjects.label = thread.userLabels[0].label;
      }
      if (isBoolean(thread?.readStatus?.isRead)) {
        selectedThreadObjects.isRead = thread?.readStatus?.isRead;
      }
      selectedThreadsVar([...selectedThreadsVar(), selectedThreadObjects]);
    } else {
      selectedThreadsVar(selectedThreadsVar().filter((a) => a.id !== thread.id));
    }
  };
  const {
    sortedSocialSchemasByName,
  } = useMemberFieldPartitions({});

  const { messageResources } = useResourceContext();
  return (
    <div
      key={thread.id}
      className={cx(styles.ThreadListItem, className, {
        [styles.selected]: thread.id === selectedThread?.id,
        [styles.hasActions]: props.shouldShowActions,
        [styles.unreadDiv]: thread?.readStatus?.isRead === true,
      })}
      onClick={props.onSelectThread}
    >
      <div className={styles.info}>
        <div className={styles.titleAndCheckbox}>
          {props.shouldShowActions && (
            <Checkbox
              checked={selectedThreads.map((a) => a.id).includes(thread.id)}
              // @ts-ignore
              onClick={onThreadItemSelectUpdate}
            />
          )}
          <Text
            ellipsis
            className={cx(styles.title, className, {
              [styles.unreadTitle]: thread?.readStatus?.isRead,
            })}
          >
            {getThreadTitle(thread, sortedSocialSchemasByName, messageResources, users)}
          </Text>
        </div>
        {props.shouldShowActions && (
          <div className={styles.actionIcon}>
            <ThreadSingleActionButtons thread={thread} />
          </div>
        )}
        <div className={styles.appIconAndTime}>
          {thread.application && ![MessageType.OUTLOOK, MessageType.GMAIL].includes(thread.type) && (
            <>
              <LazyImage
                src={thread.application.icon}
                className={styles.appIcon}
              />
              {thread.lastMessage.isAutomated
                && (
                  <LazyImage
                    src={AUTOMATION_IMAGE_URL}
                    className={styles.automationIcon}
                  />
                )}
            </>
          )}
          <div className={styles.time}>{formatMessageDate(thread?.lastMessage.internalDate)}</div>
        </div>
      </div>
      <div className={styles.subjectAndCount}>
        <div className={styles.subject}>
          {unescape(thread.lastMessage?.title || thread?.lastMessage?.subject)}
        </div>
        <div className={styles.status}>
          {props.shouldShowActions && (
            <>
              {!isEmpty(thread?.userLabels) && thread.userLabels[0].label === 'DONE' && <CheckIcon className={styles.statusIcon} />}
            </>
          )}
          <div>{thread.messagesCount > 1 && thread.messagesCount}</div>
        </div>
      </div>
    </div>
  );
});

ThreadListItem.displayName = 'MessagingAppThreadListItem';
