import { useQuery, QueryHookOptions } from '@apollo/client';

import { MEMBER_PROGRAMS_AND_COMMUNITIES } from '@frontend/app/queries';
import {
  MemberProgramsAndCommunitiesQuery,
  MemberProgramsAndCommunitiesQueryVariables,
} from '@frontend/app/queries/types/MemberProgramsAndCommunitiesQuery';

type IOptions = QueryHookOptions<MemberProgramsAndCommunitiesQuery, MemberProgramsAndCommunitiesQueryVariables>;

export const useMemberProgramsAndCommunitiesQuery = (memberId: number, options: IOptions = {}) => useQuery<MemberProgramsAndCommunitiesQuery, MemberProgramsAndCommunitiesQueryVariables>(
    MEMBER_PROGRAMS_AND_COMMUNITIES,
    {
      ...options,
      variables: {
        memberId,
      },
      skip: !memberId || options.skip,
    },
  );
