import cx from 'classnames';
import * as React from 'react';

import { ICounts } from '@frontend/app/containers/Projects/hooks';
import {
  TProject,
  TTask,
  TWorklet,
} from '@frontend/app/containers/Projects/types';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import { FolderList } from '../FolderList/FolderList';
import {
  ProjectsPageState,
} from '../../constants';

import styles from './Sidebar.scss';

const SimpleBar = require('simplebar-react').default;

interface IProps {
  className?: string;
  counts?: ICounts;
  isLoading?: boolean;
  workletSpecUri?: string;
  onTaskSelected: THandleTaskSelected;
  pageState: ProjectsPageState;
  project: TProject;
  task: TTask;
  tasks: TTask[];
  worklets?: TWorklet[];
}

export const Sidebar: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    className,
    counts,
    onTaskSelected,
    pageState,
    project,
    task,
    tasks,
    workletSpecUri,
    worklets,
  } = props;

  return (
    <SimpleBar className={cx(styles.Sidebar, className)}>
      <div className={styles.content}>
        <FolderList
          counts={counts}
          onTaskSelected={onTaskSelected}
          pageState={pageState}
          project={project}
          task={task}
          tasks={tasks}
          workletSpecUri={workletSpecUri}
          worklets={worklets}
        />
      </div>
    </SimpleBar>
  );
});

Sidebar.displayName = 'Sidebar';
