import { ActionType, TState } from '../types';

interface IAction {
    amountPaying: number;
    type: ActionType.SET_AMOUNT_PAYING;
}
export type TSetAmountPayingAction = Readonly<IAction>;

export const setAmountPaying = (state: TState, action: TSetAmountPayingAction): TState => ({
    ...state,
    amountPaying: action.amountPaying,
});
