import * as React from 'react';
import {
  ArrowsRotateIcon,
  CameraIcon,
  CircleCheckIcon,
  CircleExclamationIcon,
} from '@revfluence/fresh-icons/regular/esm';

import { CONTENT_REVIEW_STATE } from '../constants';

export function getContentReviewStateIcon(state: CONTENT_REVIEW_STATE): React.ReactElement {
  switch (state) {
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_PLACEHOLDER:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_WAITING_FOR_SUBMISSION: {
      return <ArrowsRotateIcon />;
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_APPROVED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_COMPLETED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_COMPLETED_WITHOUT_PAYMENT: {
      return <CircleCheckIcon />;
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_NEW:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_UPLOADED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_VALIDATING_UPLOAD:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_AMENDED: {
      return <CircleExclamationIcon />;
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_REJECTED: {
      return <CameraIcon />;
    }

    default: {
      break;
    }
  }

  return <ArrowsRotateIcon />;
}
