import * as React from 'react';
import {
  get,
} from 'lodash';
import { ClipboardUserIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Row,
  Col,
  Skeleton,
} from '@revfluence/fresh';
import { TTask } from '@frontend/app/containers/Projects/types';
import { ICounts } from '@frontend/app/containers/Projects/hooks';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import Card from '../Card';
import Statistics from './components/Statistics';
import Stages from './components/Stages';
import { EmptyNoCreators } from '../Empty';
import { ModalType } from '../../ProjectsPage/AddMembersToProgramModal/AddMembersToCollectionModal';

import {
  TWorklet,
  TWidgetCount,
} from '../../types';

const {
  useState,
  useMemo,
  useEffect,
} = React;
interface IProps {
  onTaskSelected: THandleTaskSelected;
  counts: ICounts;
  countsNeedAttention: ICounts;
  projectId: number;
  tasks: TTask[];
  worklets: TWorklet[];
  openAddToCollectionModal(modalType: ModalType): void;
}

const WidgetProgress: React.FC<IProps> = React.memo((props) => {
  const {
    tasks,
    counts,
    onTaskSelected,
    countsNeedAttention,
    projectId,
    worklets,
    openAddToCollectionModal,
  } = props;

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const [actualNeedsAttentionCount, setActualNeedsAttentionCount] = useState<number>(0);

  const allInProgressCount: TWidgetCount = useMemo(() => (
    get(counts, 'all_in_progress', 0)
  ), [counts]);

  const completedCount: TWidgetCount = useMemo(() => (
    get(counts, 'completed', 0)
  ), [counts]);

  const applicantsCount: TWidgetCount = useMemo(() => (
    get(counts, 'new', 0)
  ), [counts]);

  const [isLoading, setIsLoading] = useState(true);

  const hasNoCreators = applicantsCount === 0
    && allInProgressCount === 0
    && completedCount === 0;

  useEffect(() => {
    setIsLoading(!countsNeedAttention && !counts);
  }, [
    countsNeedAttention,
    counts,
  ]);

  const renderOldUI = () => (
    <Card
      title="Creators that need attention"
      icon={<ClipboardUserIcon />}
    >
      {
        (isLoading || isRefreshUIEnabled === undefined) && (
          <Row gutter={[24, 24]} align="middle">
            <Skeleton
              active
              loading
              paragraph
            />
          </Row>
        )
      }
      {
          !isLoading
          && hasNoCreators
            && (
            <EmptyNoCreators
              openAddToCollectionModal={openAddToCollectionModal}
              projectId={projectId}
            />
          )
        }
      {
          !isLoading
          && !hasNoCreators
            && (
              <Row
                gutter={[24, 24]}
                align="middle"
              >
                <Col
                  xs={24}
                  lg={12}
                >
                  <Statistics
                    actualNeedsAttentionCount={actualNeedsAttentionCount}
                    applicantsCount={applicantsCount}
                    completedCount={completedCount}
                    allInProgressCount={allInProgressCount}
                  />
                </Col>
                <Col
                  xs={24}
                  lg={12}
                >
                  {worklets && (
                    <Stages
                      onTaskSelected={onTaskSelected}
                      countsNeedAttention={countsNeedAttention}
                      tasks={tasks}
                      projectId={projectId}
                      applicantsCount={applicantsCount as number}
                      worklets={worklets}
                      setActualNeedsAttentionCount={setActualNeedsAttentionCount}
                      openAddToCollectionModal={openAddToCollectionModal}
                    />
                  )}
                </Col>
              </Row>
          )
        }
    </Card>
    );

  const renderNewUI = () => (
    <ShadCnCard className="h-full">
      <CardHeader>
        <div className="flex items-center gap-2">
          <ClipboardUserIcon fontSize={16} className="text-icon-grey" />
          <span className="font-medium text-base">Creators that need attention</span>
        </div>
      </CardHeader>
      <CardContent className="flex items-center h-full">
        {
          (isLoading || isRefreshUIEnabled === undefined) && (
            <Row gutter={[24, 24]} align="middle">
              <Skeleton
                active
                loading
                paragraph
              />
            </Row>
          )
        }
        {
          !isLoading
          && hasNoCreators
            && (
            <EmptyNoCreators
              openAddToCollectionModal={openAddToCollectionModal}
              projectId={projectId}
            />
          )
        }
        {
          !isLoading
          && !hasNoCreators
            && (
              <div className="flex w-full flex-wrap justify-center items-center gap-6">
                <div className="flex flex-1 lg:flex-[0_1_45%]">
                  <Statistics
                    actualNeedsAttentionCount={actualNeedsAttentionCount}
                    applicantsCount={applicantsCount}
                    completedCount={completedCount}
                    allInProgressCount={allInProgressCount}
                  />
                </div>
                <div className="flex flex-1 lg:flex-[0_1_45%]">
                  {worklets && (
                    <Stages
                      onTaskSelected={onTaskSelected}
                      countsNeedAttention={countsNeedAttention}
                      tasks={tasks}
                      projectId={projectId}
                      applicantsCount={applicantsCount as number}
                      worklets={worklets}
                      setActualNeedsAttentionCount={setActualNeedsAttentionCount}
                      openAddToCollectionModal={openAddToCollectionModal}
                    />
                  )}
                </div>
              </div>
          )
        }
      </CardContent>
    </ShadCnCard>
    );

  return (
    isRefreshUIEnabled ? renderNewUI() : renderOldUI()
  );
});

export default WidgetProgress;
WidgetProgress.displayName = 'WidgetProgress';
