import { filter, reduce } from 'lodash';
import { useMemo } from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { ResourceType } from '@frontend/app/types/globalTypes';
import {
  GET_RESOURCES_QUERY,
} from '@frontend/app/queries';
import {
  GetResourcesQuery,
  GetResourcesQueryVariables,
  GetResourcesQuery_resources,
} from '@frontend/app/queries/types/GetResourcesQuery';

type IOptions = QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>;
export type IResource = GetResourcesQuery_resources;

export function useGetUserResources(options: IOptions = {}) {
  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<GetResourcesQuery>(
    GET_RESOURCES_QUERY,
    options,
  );

  const activeResources = useMemo(() => {
    if (!data || !data.resources) return [];

    return filter(
      data && data.resources,
      (r) => !r.authProvider.userRevokedAccess && !r.authProvider.systemRevokedAccess,
    );
  }, [data]);

  const userEmails = useMemo(() => {
    if (!activeResources) return [];

    return activeResources
      .filter((r) => [ResourceType.GMAIL, ResourceType.OUTLOOK].includes(r.type))
      .map((r) => r.authProvider.userExternalDisplayId);
  }, [activeResources]);

  const emailResourceId = useMemo(() => {
    if (!activeResources) return {};

    return reduce(
      activeResources,
      (acc, r: IResource) => ({
        ...acc,
        ...{ [r.authProvider.userExternalDisplayId]: r.id },
      }),
      {},
    );
  }, [activeResources]);

  return {
    loading,
    emailResourceId,
    emails: userEmails,
    error,
    refetch,
  };
}
