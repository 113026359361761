import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { DELETE_MESSAGE_TEMPLATE_MUTATION, MESSAGE_TEMPLATES_QUERY } from '@frontend/app/queries';
import {
  DeleteMessageTemplateMutation,
  DeleteMessageTemplateMutationVariables,
} from '@frontend/app/queries/types/DeleteMessageTemplateMutation';

type IOptions = MutationHookOptions<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>;

export const useDeleteMessageTemplateMutation = (options: IOptions = {}) => (
  useMutation<DeleteMessageTemplateMutation, DeleteMessageTemplateMutationVariables>(DELETE_MESSAGE_TEMPLATE_MUTATION, {
    refetchQueries: [{ query: MESSAGE_TEMPLATES_QUERY }],
    ...options,
  })
);
