import * as React from 'react';
import { IStepInfo as Step } from '@frontend/applications/Shared/Wizard/container';
import { IPaymentProps, PaymentCreationSource } from '../../types';
import { TActions, TState } from './state';
import { PaymentsOverview } from '../../components/PaymentsWizard/PaymentsOverview';
import { PaymentsProcessing } from '../../components/PaymentsWizard/PaymentsProcessing';
import { PaymentsConfirm } from '../../components/PaymentsWizard/PaymentsConfirm';
import { PaymentReview } from '../../components/PaymentsWizard/PaymentReview';
import { PaymentSuccess } from '../../components/PaymentsWizard/PaymentSuccess';
import { PaymentsError } from '../../components/PaymentsWizard/PaymentsError';
import { NewBulkPaymentsTable } from '../../BulkPaymentsApp/components/NewBulkPaymentsTable';
import styles from './getPaymentsSteps.scss';

interface IStepConfig {
  instructions: Step['instructions'];
  nextButtonConfig: Step['nextButtonConfig'];
  previousButtonConfig: Step['previousButtonConfig'];
  actionButtonConfig?: Step['actionButtonConfig'];
  stepNum: Step['stepNum'];
}

type TStepFactory = (config: IStepConfig, overrides?: Partial<Step>) => Step;

export const getSteps = (_props: IPaymentProps, state: TState, actions: TActions) => {
  const containerClass = `${styles.container} ${state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING ? styles.staContainer : ''}`;
  const paymentOverview: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <div className={containerClass}>
          <PaymentsOverview
            actions={actions}
            state={state}
          />
        </div>
      </>
    ),
    title: 'Payment',
    ...config,
    ...overrides,
  } as Step);

  const paymentConfirm: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <div className={containerClass}>
          <PaymentsConfirm
            actions={actions}
            state={state}
          />
        </div>
      </>
    ),
    title: 'Payment',
    ...config,
    ...overrides,
  } as Step);

  const paymentReview: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <div className={containerClass}>
          <PaymentReview
            actions={actions}
            state={state}
          />
        </div>
      </>
    ),
    title: 'Payment',
    ...config,
    ...overrides,
  } as Step);

  const paymentProcessing: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <div className={containerClass}>
          <PaymentsProcessing
            state={state}
          />
        </div>
      </>
    ),
    title: 'Payment',
    ...config,
    ...overrides,
  } as Step);

  const paymentSuccess: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <div className={containerClass}>
          <PaymentSuccess
            state={state}
          />
        </div>
      </>
    ),
    title: 'Payment',
    ...config,
    ...overrides,
  } as Step);

  const paymentsError: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <div className={containerClass}>
          <PaymentsError />
        </div>
      </>
    ),
    title: 'Payment',
    ...config,
    ...overrides,
  } as Step);

  const staPayments: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <div className={containerClass}>
          <NewBulkPaymentsTable
            actions={actions}
            state={state}
          />
        </div>
      </>
    ),
    title: 'Payment',
    ...config,
    ...overrides,
  } as Step);

  return {
    paymentOverview,
    paymentConfirm,
    paymentReview,
    paymentProcessing,
    paymentSuccess,
    paymentsError,
    staPayments,
  };
};
