import * as React from 'react';
import { uniqueId } from 'lodash';

import {
  ISvgIconProps,
  SvgIcon,
} from '@components';

export const UndoIcon = React.memo((props: ISvgIconProps) => {
  const {
    fill,
    ...restProps
  } = props;

  const undoId = uniqueId('UndoIcon_undo');
  const pathId = uniqueId('UndoIcon_path');
  const maskId = uniqueId('UndoIcon_mask');
  const maskPathId = uniqueId('UndoIcon_maskPath');

  return (
    <SvgIcon
      viewBox="0 0 16 16"
      {...restProps}
    >
      <g id={undoId}>
        <path
          id={pathId}
          d="
            M2.51998 14.0002C2.82732 14.0002 3.09465 13.7902 3.16798 13.4915C3.76065 11.0588 5.84798 9.30148 8.36465
            9.03548V9.78282C8.36465 10.2535 8.64398 10.6728 9.09265 10.8762C9.58065 11.0982 10.1487 11.0182 10.544
            10.6735L13.922 7.72415C14.1833 7.49482 14.334 7.17015 14.334 6.83348C14.334 6.49682 14.1833 6.17215 13.922
            5.94282L10.544 2.99348C10.1487 2.64882 9.58065 2.56882 9.09265 2.79082C8.64398 2.99415 8.36465 3.41348
            8.36465 3.88415V4.69615C4.61532 5.02482 1.66732 8.10015 1.66732 11.8335C1.66732 12.3735 1.73598 12.9308
            1.87198 13.4908C1.94465 13.7902 2.21198 14.0002 2.51998 14.0002Z
          "
          fill={fill}
        />
        <mask
          id={maskId}
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="1"
          y="2"
          width="14"
          height="13"
        >
          <path
            id={maskPathId}
            d="
              M2.51998 14.0002C2.82732 14.0002 3.09465 13.7902 3.16798 13.4915C3.76065 11.0588 5.84798 9.30148 8.36465
              9.03548V9.78282C8.36465 10.2535 8.64398 10.6728 9.09265 10.8762C9.58065 11.0982 10.1487 11.0182 10.544
              10.6735L13.922 7.72415C14.1833 7.49482 14.334 7.17015 14.334 6.83348C14.334 6.49682 14.1833 6.17215 13.922
              5.94282L10.544 2.99348C10.1487 2.64882 9.58065 2.56882 9.09265 2.79082C8.64398 2.99415 8.36465 3.41348
              8.36465 3.88415V4.69615C4.61532 5.02482 1.66732 8.10015 1.66732 11.8335C1.66732 12.3735 1.73598 12.9308
              1.87198 13.4908C1.94465 13.7902 2.21198 14.0002 2.51998 14.0002Z
            "
            fill="white"
          />
        </mask>
        <g mask={`url(#${maskId})`} />
      </g>
    </SvgIcon>
  );
});

UndoIcon.displayName = 'UndoIcon';
