import { useMutation, MutationHookOptions } from '@apollo/client';

import { CREATE_CONTENT_REVIEW_COMMENT_MUTATION } from '@frontend/app/queries';

import {
  CreateContentReviewCommentMutation,
  CreateContentReviewCommentMutationVariables,
} from '@frontend/app/queries/types/CreateContentReviewCommentMutation';

type IOptions = MutationHookOptions<CreateContentReviewCommentMutation, CreateContentReviewCommentMutationVariables>;

export function useCreateContentReviewComment(options: IOptions = {}) {
  const [createComment, { loading, error }] = useMutation<
    CreateContentReviewCommentMutation,
    CreateContentReviewCommentMutationVariables
  >(CREATE_CONTENT_REVIEW_COMMENT_MUTATION, {
    ...options,
  });

  return {
    createComment,
    loading,
    error,
  };
}
