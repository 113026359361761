import { useQuery, QueryHookOptions } from '@apollo/client';
import { isEmpty } from 'lodash';

import { GET_ALLOY_USER_TOKEN_QUERY } from '@frontend/app/queries';

import {
  GetAlloyUserTokenQuery,
  GetAlloyUserTokenQueryVariables,
} from '@frontend/app/queries/types/GetAlloyUserTokenQuery';

type IOptions = QueryHookOptions<GetAlloyUserTokenQuery, GetAlloyUserTokenQueryVariables>;

export function useGetAlloyUserToken(options: IOptions = {}) {
  const {
    loading, data: { token } = {}, error, refetch,
  } = useQuery<GetAlloyUserTokenQuery, GetAlloyUserTokenQueryVariables>(
    GET_ALLOY_USER_TOKEN_QUERY,
    {
      ...options,
      skip: isEmpty(options.variables?.userId),
    },
  );

  return {
    loading,
    token,
    error,
    refetch,
  };
}
