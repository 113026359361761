import { TNetworkIdentifier } from './networkIdentifier';
import { IMedia, TMediaType } from './media';

export interface ISocialPost {
  post_id: string;
  account_id?: number;
  mention_id: number;
  creator: string;
  profile_image_url?: string;
  social_account_link?: string;
  social_account_name?: string;
  datetime_posted?: string;
  datetime_modified?: string;
  image_url?: string;
  post_text?: string;
  tmv?: string;
  roi_pct?: string;
  cost?: string;
  product_cost?: string;
  network: string;
  ad_score?: number;
  caption?: string;
  impressions?: number;
  estimated_impressions?: number;
  comment_count?: number;
  comments?: number;
  created_ts?: number;

  dislike_count?: number;
  embed_link?: string;
  engageable_reach?: number;
  engagement_ratio?: number;
  engagement?: number;
  image?: string;
  images?: string[];
  like_count?: number;
  likes?: number;
  link?: string;
  member_ids?: number[];
  media_id?: string;
  media_type?: TMediaType;
  media?: IMedia[];
  network_identifier?: TNetworkIdentifier;
  post_type: string;
  posted_ts?: number;
  reach?: number;
  sponsored?: boolean;
  stats?: IStats;
  tags?: string[];
  text?: string;
  thumbnail_url?: string;
  title?: string;
  ts?: number;
  username?: string;
  view_count?: number;
  viewed?: boolean;
  /* eslint-disable */
  deleted?: any;
  id?: any;
  links?: any;
  video?: any;
  /* eslint-enable */
}

interface IStats {
  likes?: number;
  comments?: number;
  engageable_reach?: number;
  reach?: number;
  shares?: number;
  impressions?: number;
  /* eslint-disable */
  saves?: any;
  /* eslint-enable */
}
