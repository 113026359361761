import * as React from 'react';

import axios from 'axios';
import { debounce, map, uniqBy } from 'lodash';
import { Select } from 'antd';
import { useInviteContext } from '@frontend/components/widgets';
import { endpoints, TNetworkIdentifier } from '@frontend/components/common';

import { ISocialSearchTag, SearchTermType } from '../models';

import styles from './IQUsernameKeywordsFilter.scss';
import { SelectQueryInput } from './SelectQueryInput';
import { TextQueryInput } from './TextQueryInput';

const { useCallback, useEffect, useMemo, useState } = React;

const TAGS_DEBOUNCE_TIMEOUT = 250;

const termPlaceholderMap = {
  [SearchTermType.Keywords]: 'One or many keywords',
  [SearchTermType.Topics]: 'One or many topics',
  [SearchTermType.Username]: '@username',
  [SearchTermType.Lookalike]: '@username',
  [SearchTermType.Mentions]: '@usernames or #hashtags',
};

export const termTypeLabel = {
  [SearchTermType.Keywords]: 'Keywords',
  [SearchTermType.Lookalike]: 'Lookalike',
  [SearchTermType.Mentions]: 'Mentions',
  [SearchTermType.Topics]: 'Topics',
  [SearchTermType.Username]: 'Username',
}

const defaultSearchTermTypes = [{
  label: termTypeLabel[SearchTermType.Keywords],
  value: SearchTermType.Keywords,
}, {
  label: termTypeLabel[SearchTermType.Topics],
  value: SearchTermType.Topics,
}, {
  label: termTypeLabel[SearchTermType.Username],
  value: SearchTermType.Username,
}];

export interface IIQUsernameKeywordsFilterProps {
  query: string;
  termType: SearchTermType;
  onTermTypeChange: (type: SearchTermType) => void;
  onQueryChange: (query: string) => void;
  onPerformSearch: () => void;
  network: TNetworkIdentifier
}

export const IQUsernameKeywordsFilter: React.FC<IIQUsernameKeywordsFilterProps> = (props) => {
  const {
    query,
    termType,
    onTermTypeChange,
    onQueryChange,
    onPerformSearch,
    network,
  } = props;

  const {
    apiEndpoint
  } = useInviteContext();

  const [isFetchingTags, setIsFetchingTags] = useState(false);
  const [availableTags, setAvailableTags] = useState<ISocialSearchTag[]>([]);
  const [selectedTags, setSelectedTags] = useState<ISocialSearchTag[]>();
  const [selectedTermType, setSelectedTermType] = useState<string>('');
  const [placeholder, setPlaceholder] = useState<string>('');

  const termTypeOptions = useMemo(() => {
    if (['instagram', 'tiktok'].includes(network)) {
      return [...defaultSearchTermTypes, {
        label: termTypeLabel[SearchTermType.Lookalike],
        value: SearchTermType.Lookalike,
      }, {
        label: termTypeLabel[SearchTermType.Mentions],
        value: SearchTermType.Mentions,
      }];
    } else {
      return defaultSearchTermTypes;
    }
  }, [network]);

  const handleTermTypeChange = useCallback((type) => {
    setSelectedTags([]);
    setAvailableTags([]);
    onTermTypeChange(type);
  }, [onTermTypeChange]);

  useEffect(() => {
    if (termType) {
      setPlaceholder(termPlaceholderMap[termType]);
      setSelectedTermType(termType);
    } else {
      const selectedTerm = termTypeOptions[0]?.value;
      setPlaceholder(termPlaceholderMap[selectedTerm]);
      setSelectedTermType(selectedTerm);
      handleTermTypeChange(selectedTerm);
    }
  }, [termType, termTypeOptions, handleTermTypeChange]);

  useEffect(() => {
    if (termType !== SearchTermType.Topics || !query) return;

    const tags = query.split(',');
    setSelectedTags(map(tags, (tag) => ({ tag, value: tag })));
  }, [termType, query, setSelectedTags]);

  const getTags = useCallback(async (value: string) => {
    setAvailableTags(selectedTags);

    if (!value) {
      return;
    }

    setIsFetchingTags(true);

    const fetchURL = `${apiEndpoint}/${endpoints.socialAccountSearchTagsEndpoint}`;

    try {
      const response = await axios.post(fetchURL, {
        query: value,
        platform: network
      });

      const tags = response.data.data;

      const newAvailableTags = selectedTags
        ? uniqBy([...selectedTags, ...tags], 'value')
        : tags;

      setAvailableTags(newAvailableTags);
    } finally {
      setIsFetchingTags(false);
    }
  }, [apiEndpoint, network, selectedTags]);

  const tagFetcher = useMemo(() => {
    return debounce(getTags, TAGS_DEBOUNCE_TIMEOUT);
  }, [getTags]);

  const handleTagsChange = (tags) => {
    const tagValues: ISocialSearchTag[] = map(tags, (tag) => {
      return {
        tag,
        value: tag,
      }
    });

    setSelectedTags(tagValues);

    onQueryChange(tags.join(','));
  };

  const onSelectInputBlur = useCallback(() => {
    setAvailableTags(selectedTags);
  }, [selectedTags]);

  return (
    <div
      id="filterInputContainer"
      className={styles.inputContainer}
    >
      <Select
        className={styles.termPicker}
        value={selectedTermType}
        options={termTypeOptions}
        onChange={handleTermTypeChange}
      />
      {
        termType === SearchTermType.Topics
          ? (
            <SelectQueryInput
              availableTags={availableTags}
              className={styles.input}
              handleTagsChange={handleTagsChange}
              isFetching={isFetchingTags}
              onBlur={onSelectInputBlur}
              onSearch={tagFetcher}
              placeholder={placeholder}
              selectedTags={selectedTags}
            />
          )
          : (
            <TextQueryInput
              className={styles.input}
              onPerformSearch={onPerformSearch}
              onQueryChange={onQueryChange}
              placeholder={placeholder}
              query={query}
            />
          )
      }
    </div>
  );
}
