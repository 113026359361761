import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_FEATURED_PRODUCTS_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/featuredProducts';
import { CreateFeaturedProductsMutation, CreateFeaturedProductsMutationVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/CreateFeaturedProductsMutation';

type IOptions = MutationHookOptions<CreateFeaturedProductsMutation, CreateFeaturedProductsMutationVariables>;

export const useCreateFeaturedProducts = (options: IOptions = {}) => {
  const [createFeaturedProducts, { loading, data, error }] = useMutation(CREATE_FEATURED_PRODUCTS_MUTATION, options);

  return {
    loading,
    collectionItem: data?.addFeatureProductsInCatalog,
    error,
    createFeaturedProducts,
  };
};
