import gql from 'graphql-tag';
import { ACTIVATION_FRAGMENT } from './fragments/ActivationFragment';

export const ACTIVATIONS_QUERY = gql`
  query ActivationsQuery {
    activations {
      ...ActivationFragment
    }
  }
  ${ACTIVATION_FRAGMENT}
`;
