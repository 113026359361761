/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_xdcl1_188 {
  display: flex;
}

._justify-content-space-between_xdcl1_192 {
  justify-content: space-between;
}

._tabular-nums_xdcl1_196 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_xdcl1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SideBarButton_xdcl1_209 {
  height: 2rem;
  float: right;
}
._SideBarButton_xdcl1_209 ._buttonText_xdcl1_213 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
._SideBarButton_xdcl1_209 ._text_xdcl1_217 {
  float: right;
}
._SideBarButton_xdcl1_209 ._sideIcon_xdcl1_220 {
  width: 1.125rem;
}

._icon_xdcl1_224 {
  color: #876247;
}