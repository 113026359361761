import * as React from 'react';
import {
 Route, Switch, Redirect, useRouteMatch,
} from 'react-router-dom';

import NewPayment from './NewPayment';
import MemberPaymentList from '../components/MemberPaymentList';

const PaymentAppModal: React.FunctionComponent = () => {
  const match = useRouteMatch();

  return (
    <div>
      <section>
        <Switch>
          <Route
            path={`${match.path}/payments`}
            component={MemberPaymentList}
          />
          <Route
            path={`${match.path}/new`}
            component={NewPayment}
          />
          <Redirect from={`${match.path}`} to={{ ...location, pathname: `${match.path}/payments` }} />
        </Switch>
      </section>
    </div>
  );
};

export default PaymentAppModal;
