import gql from 'graphql-tag';

export const CREATE_ADVERTISER = gql`
  mutation CreateAdvertiser($data: AdvertiserInput!) {
    advertiser: createAdvertiser(data: $data) {
      id
      name
    }
  }
`;
