import * as React from 'react';
import { isNil } from 'lodash';
import {
  Space,
  Checkbox,
  Typography,
  Alert,
} from '@revfluence/fresh';
import { Input } from 'antd';

import { ValidatePaymentInput } from '@frontend/app/utils/payment';
import { ICompensation } from '@frontend/app/containers/Projects/TermsPage/components/types/state';
import { PaymentTypes } from '@frontend/applications/TermsApp/components/BulkTerms/types/CollaborationDetails';
import { DollarInput } from '@frontend/applications/TermsApp/components/BulkTerms/utils/DollarInput';

const {
  useRef,
  useCallback,
} = React;

const { Text } = Typography;

export interface IProps {
  compensation: ICompensation;
  onToggleNewPrice: (showHideNewPrice: boolean) => void;
  onUpdateNewPrice: (updatedNewPrice: number) => void;
  onToggleDescription: (paymentType: PaymentTypes, showHideDescription: boolean) => void;
  onUpdateDescription: (paymentType: PaymentTypes, description: string) => void;
  onToggleNoCompensation: (toggleNoCompensation: boolean) => void;
}

const FOCUS_TIMEOUT = 500;

const Compensation: React.FC<IProps> = React.memo(
  ({
    compensation,
    onToggleNewPrice,
    onUpdateNewPrice,
    onToggleDescription,
    onUpdateDescription,
    onToggleNoCompensation,
  }) => {
  const {
    payment,
    freeProduct,
    commission,
    noCompensation,
  } = compensation;

  const dollarInputRef = useRef<React.ElementRef<typeof Input>>(null);
  const freeProductDescriptionRef = useRef<HTMLInputElement>(null);
  const commissionDescriptionRef = useRef<HTMLInputElement>(null);
  let paymentValidationMessage = '';
  if (compensation.payment.toggleNewPrice) {
    const amountText = !isNil(compensation.payment.newPrice) ? compensation.payment.newPrice.toString() : '';
    paymentValidationMessage = amountText === '0' ? 'Payment amount is required to continue.' : ValidatePaymentInput(amountText);
  }

  const handleToggleNewPrice = useCallback((e) => {
    onToggleNewPrice(e.target.checked);
    setTimeout(() => {
      if (e.target.checked) {
        dollarInputRef?.current?.input?.focus();
      }
    }, FOCUS_TIMEOUT);
  }, [onToggleNewPrice]);

  const handleToggleFreeProduct = useCallback((e) => {
    onToggleDescription(PaymentTypes.FreeProduct, e.target.checked);
    setTimeout(() => {
      if (e.target.checked) {
        freeProductDescriptionRef?.current?.focus();
      }
    }, FOCUS_TIMEOUT);
  }, [onToggleDescription]);

  const handleToggleCommission = useCallback((e) => {
    onToggleDescription(PaymentTypes.Commission, e.target.checked);
    setTimeout(() => {
      if (e.target.checked) {
        commissionDescriptionRef?.current?.focus();
      }
    }, FOCUS_TIMEOUT);
  }, [onToggleDescription]);

  const handleToggleNoCompensation = useCallback((e) => {
    const checked = e.target.checked;
    onToggleNoCompensation(checked);
    setTimeout(() => {
      if (checked) {
        commissionDescriptionRef?.current?.focus();
      }
    }, FOCUS_TIMEOUT);
  }, [onToggleNoCompensation]);

  const handleOnBlur = useCallback(() => {
    const amountText = dollarInputRef?.current?.input?.value;
    onUpdateNewPrice(+amountText);
  }, [onUpdateNewPrice, dollarInputRef]);

  return (
    <Space
      direction="vertical"
      size={[16, 16]}
      style={{ width: '100%' }}
    >
      <Checkbox
        onChange={handleToggleNewPrice}
        checked={payment.toggleNewPrice}
      >
        <Text strong>Payment</Text>
      </Checkbox>
      { payment.toggleNewPrice && (
        <>
          <DollarInput
            style={{
              width: '100px',
            }}
            onChange={onUpdateNewPrice}
            onBlur={handleOnBlur}
            value={payment.newPrice}
            ref={dollarInputRef}
          />
          { paymentValidationMessage !== '' && (
            <Alert
              message={paymentValidationMessage}
              type="error"
            />
          )}
        </>
      )}
      <Checkbox
        onChange={handleToggleFreeProduct}
        checked={freeProduct.toggleDescription}
      >
        <Text strong>Free product</Text>
      </Checkbox>
      { freeProduct.toggleDescription && (
        <>
          <Input.TextArea
            ref={freeProductDescriptionRef}
            rows={4}
            value={freeProduct.description}
            onChange={(e) => onUpdateDescription(PaymentTypes.FreeProduct, e.target.value)}
            maxLength={1000}
          />
          { freeProduct.description === '' && (
            <Alert
              message="Please provide a free product."
              type="error"
            />
          )}
        </>
      )}
      <Checkbox
        onChange={handleToggleCommission}
        checked={commission.toggleDescription}
      >
        <Text strong>Commission</Text>
      </Checkbox>
      { commission.toggleDescription && (
        <>
          <Input.TextArea
            ref={commissionDescriptionRef}
            rows={4}
            value={commission.description}
            onChange={(e) => onUpdateDescription(PaymentTypes.Commission, e.target.value)}
            maxLength={1000}
          />
          { commission.description === '' && (
            <Alert
              message="Please provide a commission."
              type="error"
            />
          )}
        </>
      )}
      <Checkbox
        onChange={handleToggleNoCompensation}
        checked={noCompensation.toggleNoCompensation}
      >
        <Text strong>No Compensation</Text>
      </Checkbox>
    </Space>
  );
},
);

Compensation.displayName = 'Compensation';

export default Compensation;
