import * as React from 'react';

import { Button } from '@components';

import styles from './MemberMissingDetails.scss';

interface IProps {
  membersCount: number;
  missingCount: number;
  closeModal(): void;
  onContinue(): void;
}

const MemberMissingDetails: React.FC<IProps> = React.memo(({
 membersCount, missingCount, onContinue, closeModal,
}) => {
  const canContinue = membersCount !== missingCount;
  let header = ''; let
message = '';

  if (membersCount === 1) {
    header = 'This member does not have an email address';
    message = 'Please add an email address to make a data request.';
  } else if (membersCount !== missingCount) {
    header = 'Some members don’t have an email';
    message = 'Some of the members you selected do not have email addresses. These members will not receive this request.';
  } else {
    header = 'These members do not have an email address';
    message = 'Please add email addresses for these members to make a data request.';
  }

  return (
    <div className={styles.MemberMissingDetails}>
      <div className={styles.header}>{header}</div>
      <div className={styles.message}>{message}</div>
      <div>
        {
          canContinue ? (
            <>
              <Button className={styles.button} label="Continue" onClick={onContinue} />
              <Button className={styles.button} label="Cancel" onClick={closeModal} theme="info" />
            </>
          ) : (
            <Button className={styles.button} label="Okay" onClick={closeModal} />
            )
        }
      </div>
    </div>
  );
});

MemberMissingDetails.displayName = 'MemberMissingDetails';

export default MemberMissingDetails;
