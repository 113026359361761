import React, { useEffect, useMemo, useState } from 'react';
import { tagColors, useQueryParams } from '@frontend/app/hooks';
import { pluralize } from '@frontend/app/utils/strings';
import {
  Alert,
 Button, Checkbox, Col, Empty, Input, message, Modal, Row, Skeleton, Space, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import {
 BoxesStackedIcon, MagnifyingGlassIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { isEqual, sortBy } from 'lodash';
import { useGetProductDetails } from '../hooks/useGetProductDetails';
import { useGetProductAttributesByClientId } from '../../Catalogs/hooks/useGetProductAttributesByClientId';
import { useUpdateProduct } from '../hooks/useUpdateProduct';
import { useProductDetailsDrawerContext } from './ProductDetailsDrawerContext';
import styles from './AssignTagsModal.scss';
import { getAspireTags, simpleHash, toAspireTags } from '../../utils';
import { useAddTagsToProducts } from '../hooks/useAddTagsToProducts';

const { Text } = Typography;

export const AssignTagsModal = () => {
  const queryParams = useQueryParams();
  const productId = queryParams.get('productId');

  const {
 isOpen, newTags, setNewTags, selectedTags, setSelectedTags, reset, selectedProductIds,
} = useProductDetailsDrawerContext();

  const [search, setSearch] = useState('');

  const { product, loading: isProductLoading, refetch: refetchProduct } = useGetProductDetails({
    variables: {
      productId: Number(productId),
    },
    skip: !open || !productId,
    fetchPolicy: 'network-only',
  });

  const { productAttributes, loading: isAttributesLoading, refetch: refetchAttributes } = useGetProductAttributesByClientId({
    skip: !open,
    fetchPolicy: 'network-only',
  });

  const { updateProduct, isUpdatingProduct } = useUpdateProduct({
    onCompleted: () => {
      message.success('Tags updated successfully');
      refetchAttributes();
      refetchProduct();
      handleClose();
    },
    onError: (error) => {
      message.error(error.message || 'Unable to update tags');
    },
  });

  const { addTags, isAddingTags } = useAddTagsToProducts({
    onCompleted: () => {
      message.success('Tags updated successfully');
      refetchAttributes();
      handleClose();
    },
    onError: (error) => {
      message.error(error.message || 'Unable to update tags');
    },
  });

  const aspireTags = useMemo(() => [...newTags, ...(
    getAspireTags(productAttributes?.tags || [])
  )], [newTags, productAttributes?.tags]);

  const filteredTags = useMemo(() => {
    if (search) {
      return aspireTags?.filter((tag) => tag.toLowerCase().includes(search.toLowerCase()));
    }
    return aspireTags;
  }, [aspireTags, search]);

  const addNewTag = () => {
    setNewTags([search, ...newTags]);
    setSelectedTags([...selectedTags, search]);
    setSearch('');
  };

  const toggleTag = (tag: string) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleSave = () => {
    if (productId) {
      updateProduct({
        variables: {
          id: Number(productId),
          input: {
            tags: toAspireTags(selectedTags),
          },
        },
      });
    } else {
      addTags({
        variables: {
          ids: selectedProductIds,
          tags: toAspireTags(selectedTags),
        },
      });
    }
  };

  const handleClose = () => {
    reset();
    setSearch('');
  };

  useEffect(() => {
    if (isOpen && product?.tags?.length) {
      setSelectedTags(getAspireTags(product.tags));
    }
  }, [isOpen, product.tags, setSelectedTags]);

  const hasSelectionChanged = useMemo(() => {
    if (productId) {
      return !isEqual(
        sortBy(selectedTags),
        sortBy(getAspireTags(product?.tags || [])),
      );
    }
    return selectedTags.length > 0;
  }, [productId, product?.tags, selectedTags]);

  if (isProductLoading || isAttributesLoading) {
    return <Skeleton active />;
  }

  return (
    <Modal
      width={600}
      title="Assign Tags"
      footer={(
        <Space>
          <Button disabled={isUpdatingProduct || isAddingTags} onClick={handleClose}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleSave}
            loading={isUpdatingProduct || isAddingTags}
            disabled={!hasSelectionChanged}
          >
            Assign Tags
          </Button>
        </Space>
      )}
      open={isOpen}
      className={styles.AssignTagsModal}
      onCancel={handleClose}
    >
      <Space direction="vertical" className={styles.fullSpace}>
        <Text type="secondary">
          Assign selected tags to
          {
            productId ? ' this product' : ` ${selectedProductIds.length} selected ${pluralize(selectedProductIds.length, 'product')}`
          }
        </Text>
        <Input
          prefix={<MagnifyingGlassIcon />}
          placeholder="Search tags..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {
          search && !filteredTags?.length && (
            <Alert
              message={(
                <>
                  No tags with this name, search again or
                  <Button type="link" onClick={addNewTag} className={styles.createTagBtn}>create this new tag</Button>
                </>
              )}
            />
          )
        }
        {
          aspireTags?.length === 0 && (
            <Empty
              image={<BoxesStackedIcon />}
              description={
                <Text>No tags found. Create a new tag to assign to the product</Text>
              }
              size="small"
              style={{ margin: '32px 0' }}
            />
          )
        }
        <Space className={styles.tagRowsContainer} direction="vertical">
          {
            filteredTags?.map((tag) => (
              <Row key={tag} align="middle" justify="space-between">
                <Row align="middle" gutter={16}>
                  <Tooltip title={!selectedTags.includes(tag) && selectedTags.length >= 15 ? 'At most 15 tags can be assigned to a product' : ''}>
                    <Checkbox disabled={!selectedTags.includes(tag) && selectedTags.length >= 15} checked={selectedTags.includes(tag)} onChange={() => toggleTag(tag)} />
                  </Tooltip>
                  <Col>
                    <Tag className={styles.darkTextTag} color={tagColors[simpleHash(tag) % tagColors.length]?.backgroundColor}>{tag}</Tag>
                  </Col>
                </Row>
              </Row>
            ))
          }
        </Space>
      </Space>
    </Modal>
  );
};
