/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1docd_157 {
  display: flex;
}

._justify-content-space-between_1docd_161 {
  justify-content: space-between;
}

._tabular-nums_1docd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1docd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SendEmailForm_1docd_178 ._info_1docd_178 ._memberCount_1docd_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SendEmailForm_1docd_178 {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 80vh;
}
._SendEmailForm_1docd_178 > * {
  overflow: scroll;
}
._SendEmailForm_1docd_178._fullHeight_1docd_227 {
  height: 100%;
}
._SendEmailForm_1docd_178 ._info_1docd_178 {
  padding: 1rem 0.5rem 1rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  overflow: hidden;
}
._SendEmailForm_1docd_178 ._info_1docd_178._noTopBorder_1docd_239 {
  border-top: none;
}
._SendEmailForm_1docd_178 ._info_1docd_178._cursor_1docd_242 {
  cursor: pointer;
}
._SendEmailForm_1docd_178 ._info_1docd_178 ._validMember_1docd_245,
._SendEmailForm_1docd_178 ._info_1docd_178 ._additionalMembers_1docd_246 {
  background-color: #f4f5f5;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
}
._SendEmailForm_1docd_178 ._info_1docd_178 ._memberCount_1docd_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  background: #e2e2e2;
}
._SendEmailForm_1docd_178 ._info_1docd_178 ._memberCount_1docd_178 ._memberCcForMemberCount_1docd_260 {
  cursor: pointer;
  margin-left: auto;
}
._SendEmailForm_1docd_178 ._info_1docd_178 ._memberCc_1docd_260 {
  position: absolute;
  top: 0.375rem;
  right: 1rem;
  cursor: pointer;
}
._SendEmailForm_1docd_178 ._info_1docd_178 ._resourceSettingsButton_1docd_270 {
  display: inline-flex;
}
._SendEmailForm_1docd_178 ._info_1docd_178 ._messageTypeSelect_1docd_273 {
  flex-shrink: 0;
  width: 7.8125rem;
  margin-left: auto;
}
._SendEmailForm_1docd_178 ._editor_1docd_278 {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
._SendEmailForm_1docd_178 ._headerButtons_1docd_284 {
  display: flex;
}
._SendEmailForm_1docd_178 ._formSpace_1docd_287 {
  width: 100%;
  flex: 1;
  justify-content: space-between;
}
._SendEmailForm_1docd_178._headerForcedClose_1docd_292 ._memberInput_1docd_292 {
  pointer-events: none;
}
._SendEmailForm_1docd_178._headerForcedClose_1docd_292 ._memberInput_1docd_292 svg {
  display: none;
}

._newThread_1docd_299 {
  color: gray;
}

._sharedIcon_1docd_303 {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: decimal !important;
  position: inherit !important;
}