import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { merge } from 'lodash';

/**
 * This function merges two MemberSearchQueries.
 * The is the field name "fields", it can be an IMemberANDQuery or IMemberORQuery.
 * So if Query A has 'field.and' and QueryB has 'field.or', QueryB 'fields.or' needs
 * to be pushed to Query A 'fields.and', and vice versa.
 */
export const mergeMemberSearchQueries = (queryA: IMemberSearchQuery, queryB: IMemberSearchQuery): IMemberSearchQuery => {
  let query = { ...queryA };
  if (query.fields && ('and' in query.fields) && ('or' in queryB.fields)) {
    /**
     * If QueryA has 'fields.and' and QueryB has 'fields.or'.
     * Push QueryB 'fields.or' to QueryA 'fields.and'
     */
    query = {
      ...query,
      fields: {
        and: [
          ...query.fields.and,
          queryB.fields,
        ],
      },
    };
  } else if (query.fields && ('or' in query.fields) && ('and' in queryB.fields)) {
    /**
     * If QueryA has 'fields.or' and QueryB has 'fields.and'.
     * Push QueryB 'fields.and' to QueryA 'fields.or'
     */
    query = {
      ...query,
      fields: {
        or: [
          ...query.fields.or,
          queryB.fields,
        ],
      },
    };
  } else {
    query = merge(query, queryB);
  }
  return query;
};
