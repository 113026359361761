import { useMutation, MutationHookOptions } from '@apollo/client';

import { DELETE_SEGMENT_FOLDER } from '@frontend/app/queries';
import {
  DeleteSegmentFolderByIdMutation, DeleteSegmentFolderByIdMutationVariables,
} from '@frontend/app/queries/types/DeleteSegmentFolderByIdMutation';

type IOptions = MutationHookOptions<DeleteSegmentFolderByIdMutation, DeleteSegmentFolderByIdMutationVariables>;

export const useDeleteSegmentFolder = (options: IOptions = {}) => useMutation<DeleteSegmentFolderByIdMutation, DeleteSegmentFolderByIdMutationVariables>(DELETE_SEGMENT_FOLDER, options);
