import * as React from 'react';
import cx from 'classnames';
import {
  filter,
  find,
  findIndex,
  isBoolean,
  map,
  pick,
  some,
  upperFirst,
} from 'lodash';

import {
  Button,
  Checkbox,
  ExternalLinkIcon,
  Input,
  IOption,
  RoundAddCircleIcon,
  Select,
  TrashcanIcon,
} from '@components';
import {
  ApplicationMemberFieldSchemaInput,
  ProgramInput,
} from '@api/src/graphql/inputs';
import { useMessagingContext } from '@frontend/hooks';
import {
  useFeatureFlagVerbiage,
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';
import { SimpleField, SimpleForm } from '@frontend/app/components';
import { useAuth } from '@frontend/context/authContext';

import styles from './ProgramOnboarding.scss';

const { useMemo, useRef, useState } = React;

interface IProps {
  programInput: ProgramInput;
  onChange(programInput: ProgramInput);
  onSubmit();
  onCustomLandingPagePathSave(customLandingPagePath: string);
  className?: string;
}

/**
 * View model for the form fields
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IFormField extends ApplicationMemberFieldSchemaInput, Partial<Omit<any, 'id'>> {
  uuid?: string;
}

const DEFAULT_DB_COLUMNS: readonly IFormField[] = Object.freeze([
  {
 schemaId: null, name: 'email', label: 'Email', isDefault: true,
},
]);

export const ProgramOnboarding = React.forwardRef<SimpleForm, IProps>((props, ref) => {
  const linkInputRef = useRef<HTMLInputElement>();
  const [isEditingLink, setIsEditingLink] = useState(false);
  const [previousLink, setPreviousLink] = useState<string>(null);

  const verbiage = useFeatureFlagVerbiage();

  const {
    clientInfo,
  } = useAuth();

  const {
    showMessage,
  } = useMessagingContext();

  const {
    data: {
      schemas = undefined,
    } = {},
  } = useMemberFieldSchemasQuery();
  const nonAppFieldSchemas = useMemo(
    () => filter(schemas, (schema) => !schema.applicationId),
    [schemas],
  );

  const fields: IFormField[] = useMemo(() => {
    const memberFieldSchemas = map(nonAppFieldSchemas, (schema) => ({
      schemaId: schema.id,
      name: schema.name,
      type: schema.type,
      isDefault: schema.isDefault,
    } as IFormField));
    return [
      ...DEFAULT_DB_COLUMNS,
      ...memberFieldSchemas,
    ];
  }, [nonAppFieldSchemas]);

  const fieldsOptions = useMemo<IOption[]>(() => (
    map(fields, (field) => ({
      label: field.name === 'email' ? upperFirst(field.name) : field.name,
      value: field.schemaId,
    }))
      .sort((a, b) => a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()))
  ), [fields]);

  const landingPageBaseUrl = `${window.location.origin}/join/`;

  const handleChangeForm = (fieldName: string, value: string) => {
    const { programInput } = props;
    props.onChange({
      ...programInput,
      [fieldName]: value,
    });
  };

  const handleCopyLink = () => {
    const input = linkInputRef.current;

    const hiddenInput = document.createElement('input');
    hiddenInput.value = `${landingPageBaseUrl}${encodeURIComponent(input.value)}?clientId=${clientInfo.id}`;
    hiddenInput.className = styles.hidden;
    document.body.appendChild(hiddenInput);

    hiddenInput.select();
    hiddenInput.setSelectionRange(0, 99999);

    document.execCommand('copy');
    document.body.removeChild(hiddenInput);

    showMessage({
      type: 'success',
      content: 'Copied to clipboard',
    });
  };

  const handleEditLink = () => {
    setPreviousLink(linkInputRef.current?.value);
    setIsEditingLink(true);
  };

  const handleCancelEditLink = () => {
    handleChangeForm('customLandingPagePath', previousLink);
    setIsEditingLink(false);
  };

  const handleSaveLink = () => {
    setPreviousLink(linkInputRef.current?.value);
    setIsEditingLink(false);

    const input = linkInputRef.current;
    props.onCustomLandingPagePathSave(input.value);
  };

  const handleChangeEmailLabel = (label: string) => {
    const { programInput } = props;
    props.onChange({
      ...programInput,
      applicationFormFields: {
        memberFieldSchemas: programInput.applicationFormFields.memberFieldSchemas,
        dbColumns: map(programInput.applicationFormFields.dbColumns, (col) => {
          if (col.name === 'email') {
            return {
              ...col,
              label,
            };
          }
          return col;
        }),
      },
    });
  };

  const handleAddFormField = () => {
    const { programInput } = props;
    props.onChange({
      ...programInput,
      applicationFormFields: {
        dbColumns: programInput.applicationFormFields.dbColumns,
        memberFieldSchemas: programInput.applicationFormFields.memberFieldSchemas.concat({ schemaId: -1 }),
      },
    });
  };

  const handleDeleteFormField = (schemaId: number) => {
    const { programInput } = props;
    props.onChange({
      ...programInput,
      applicationFormFields: {
        dbColumns: programInput.applicationFormFields.dbColumns,
        memberFieldSchemas: filter(programInput.applicationFormFields.memberFieldSchemas, (schema) => schema.schemaId !== schemaId),
      },
    });
  };

  const handleChangeFormFieldType = (index: number, schemaId: number) => {
    const { programInput } = props;
    props.onChange({
      ...programInput,
      applicationFormFields: {
        dbColumns: programInput.applicationFormFields.dbColumns,
        memberFieldSchemas: map(programInput.applicationFormFields.memberFieldSchemas, (schema, i) => {
          if (index === Number(i)) {
            const fieldDef = find(fields, (f) => f.schemaId === schemaId);
            return {
              schemaId,
              label: fieldDef.label,
              required: fieldDef.required,
            };
          }
          return schema;
        }),
      },
    });
  };

  const handleChangeFormFieldLabel = (schemaId: number, label: string) => {
    const { programInput } = props;
    props.onChange({
      ...programInput,
      applicationFormFields: {
        dbColumns: programInput.applicationFormFields.dbColumns,
        memberFieldSchemas: map(programInput.applicationFormFields.memberFieldSchemas, (schema) => {
          if (schema.schemaId === schemaId) {
            return {
              ...schema,
              label,
            };
          }
          return schema;
        }),
      },
    });
  };

  const handleChangeFormFieldRequired = (schemaId: number, required: boolean) => {
    const { programInput } = props;
    props.onChange({
      ...programInput,
      applicationFormFields: {
        dbColumns: programInput.applicationFormFields.dbColumns,
        memberFieldSchemas: map(programInput.applicationFormFields.memberFieldSchemas, (schema) => {
          if (schema.schemaId === schemaId) {
            return {
              ...schema,
              required,
            };
          }
          return schema;
        }),
      },
    });
  };

  if (!props.programInput) {
    return null;
  }

  const {
    programInput: {
      applicationFormFields: {
        memberFieldSchemas,
        dbColumns,
      },
    },
  } = props;

  const formValues = pick(props.programInput, 'customLandingPagePath');

  const emailLabel = dbColumns?.find((col) => col.name === 'email')?.label || '';

  return (
    <div className={cx(styles.ProgramOnboarding, props.className)}>
      <SimpleForm
        ref={ref}
        key={props.programInput.id}
        onSubmit={props.onSubmit}
        onChange={handleChangeForm}
        values={formValues}
      >
        <div className={styles.label}>
          {verbiage.Program}
          {' '}
          page URL
        </div>
        <div className={styles.landing}>
          <span>
            {isEditingLink
              ? landingPageBaseUrl
              : (
                <a
                  href={`${landingPageBaseUrl}${encodeURIComponent(formValues.customLandingPagePath)}?clientId=${clientInfo.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {landingPageBaseUrl + formValues.customLandingPagePath}
                  <ExternalLinkIcon size={18} className={styles.externalLinkIcon} />
                </a>
              )}
          </span>
          <SimpleField name="customLandingPagePath" type="string">
            <Input
              className={cx(styles.input, {
                [styles.hidden]: !isEditingLink,
              })}
              theme="info"
              ref={linkInputRef}
            />
          </SimpleField>
          {!isEditingLink
            ? (
              <>
                <Button
                  className={styles.copyLinkButton}
                  theme="info"
                  label="Copy Link"
                  onClick={handleCopyLink}
                />
                <Button
                  theme="info"
                  label="Edit Link"
                  onClick={handleEditLink}
                />
              </>
)
            : (
              <>
                <Button
                  className={styles.cancelButton}
                  theme="info"
                  label="Cancel"
                  onClick={handleCancelEditLink}
                />
                <Button
                  theme="primary"
                  label="Save"
                  onClick={handleSaveLink}
                />
              </>
)}
        </div>
      </SimpleForm>

      <div className={styles.label}>Build your form</div>
      <p>Add fields you would like to gather from applicants</p>
      <div className={styles.applicationFormFields}>
        <div className={styles.row}>
          <Select
            className={cx(styles.fieldDropdown, styles.disabled)}
            options={fieldsOptions}
            selectedIndex={findIndex(fieldsOptions, (o) => o.label === 'Email')}
            disabled
            round
            onChange={() => undefined}
          />
          <Input
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            className={(styles as any).fieldInput}
            placeholder="What's your email?"
            value={emailLabel}
            onChange={(label) => handleChangeEmailLabel(label)}
          />
          <Checkbox
            label="Required"
            checked
            className={styles.fieldCheckbox}
            disabled
          />
        </div>
        {map(memberFieldSchemas, (schema, i) => {
          // Filter out 'email' and fields that are already selected
          const filteredFieldsOptions = fieldsOptions.filter((option) => (
            option.label !== 'Email' && (
              option.value === schema.schemaId
              || !some(memberFieldSchemas, (s) => s.schemaId === option.value)
            )
          ));
          const selectedIndex = findIndex(filteredFieldsOptions, (o) => o.value === schema.schemaId) || 0;
          const schemaDef = find(fields, (f) => f.schemaId === schema.schemaId);

          return (
            <div className={styles.row} key={`field-${i}-id-${schema.schemaId}`}>
              <Select
                className={styles.fieldDropdown}
                options={filteredFieldsOptions}
                selectedIndex={selectedIndex}
                onChange={(schemaId) => handleChangeFormFieldType(Number(i), schemaId)}
                popoverProps={{
                  height: 240,
                  minWidth: 240,
                }}
                round
              />
              <Input
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                className={(styles as any).fieldInput}
                placeholder={schemaDef?.name || 'Field title'}
                value={schema.label || schemaDef?.name || ''}
                onChange={(label) => handleChangeFormFieldLabel(schema.schemaId, label)}
                disabled={selectedIndex === -1}
              />
              <Checkbox
                label="Required"
                checked={isBoolean(schema.required) ? schema.required : null}
                className={styles.fieldCheckbox}
                onChange={(checked) => handleChangeFormFieldRequired(schema.schemaId, checked)}
                disabled={selectedIndex === -1}
              />
              <Button
                theme="light"
                label=""
                className={styles.fieldDelete}
                icon={<TrashcanIcon size={18} />}
                onClick={() => handleDeleteFormField(schema.schemaId)}
              />
            </div>
          );
        })}
      </div>
      <Button
        theme="info"
        label="Add Field"
        icon={<RoundAddCircleIcon size={16} />}
        onClick={handleAddFormField}
      />
    </div>
  );
});

ProgramOnboarding.displayName = 'ProgramOnboarding';
