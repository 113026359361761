._Perks_yegfc_1 ._group_yegfc_1 {
  margin-bottom: 24px;
}
._Perks_yegfc_1 ._group_yegfc_1 ._perk_yegfc_4 {
  display: flex;
  align-items: center;
  color: var(--gray-9);
  margin-top: 12px;
}
._Perks_yegfc_1 ._group_yegfc_1 ._perk_yegfc_4 ._text_yegfc_10 {
  margin-left: 6px;
  font-size: 16px;
  line-height: 24px;
}