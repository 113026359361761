/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1qnpz_157 {
  display: flex;
}

._justify-content-space-between_1qnpz_161 {
  justify-content: space-between;
}

._tabular-nums_1qnpz_165 {
  font-variant-numeric: tabular-nums;
}

._InstagramInsights_1qnpz_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1qnpz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._InstagramInsights_1qnpz_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._InstagramInsights_1qnpz_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._InstagramInsights_1qnpz_169 ::-webkit-scrollbar-track, ._InstagramInsights_1qnpz_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._InstagramInsights_1qnpz_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._InstagramInsights_1qnpz_169 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 8rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  background: #eff5f9;
  text-align: center;
  padding: 0.75rem;
}
._InstagramInsights_1qnpz_169 ._label_1qnpz_218 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.5rem;
}
._InstagramInsights_1qnpz_169 ._label_1qnpz_218 a {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.125rem;
  margin-left: 0.5rem;
}
._InstagramInsights_1qnpz_169 ._buttonLabel_1qnpz_230 {
  display: flex;
}
._InstagramInsights_1qnpz_169 ._igIcon_1qnpz_233 {
  vertical-align: middle;
  margin-right: 0.5rem;
}
._InstagramInsights_1qnpz_169 ._igIcon_1qnpz_233 path,
._InstagramInsights_1qnpz_169 ._igIcon_1qnpz_233 circle {
  fill: #fdfdfd !important;
}
._InstagramInsights_1qnpz_169 ._tooltipIcon_1qnpz_241 {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
._InstagramInsights_1qnpz_169 ._checkIcon_1qnpz_246 {
  margin: 0 auto 0.5rem;
  fill: #3996e0;
}
._InstagramInsights_1qnpz_169._markAsRequired_1qnpz_250 {
  border: 0.0625rem solid #da3737;
}
._InstagramInsights_1qnpz_169._markAsRequired_1qnpz_250 ._label_1qnpz_218 {
  color: #da3737;
}

._tooltip_1qnpz_241 {
  margin-left: -6.25rem;
}
._tooltip_1qnpz_241 > div:first-of-type > div:first-of-type {
  right: 0 !important;
  left: unset !important;
}