import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_ALL_IMPORTED_PRODUCTS } from '@frontend/applications/ProductFulfillmentApp/queries/importedProducts';
import { GetAllImportedProducts, GetAllImportedProducts_getAllImportedProducts, GetAllImportedProductsVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetAllImportedProducts';

type IOptions = QueryHookOptions<GetAllImportedProducts, GetAllImportedProductsVariables>;

export const useGetAllProducts = (options: IOptions) => {
  const {
    loading, data, error, refetch, fetchMore,
   } = useQuery<GetAllImportedProducts, GetAllImportedProductsVariables>(GET_ALL_IMPORTED_PRODUCTS, options);

  return {
    loading,
    products: data?.getAllImportedProducts ?? { products: [], totalProducts: 0 } as GetAllImportedProducts_getAllImportedProducts,
    error,
    refetch,
    fetchMore,
  };
};
