import { useQuery } from '@apollo/client';

import { GET_PROGRAM_BY_LANDING_PAGE_PATH } from '@frontend/app/queries';
import { GetProgramByLandingPagePathQuery, GetProgramByLandingPagePathQueryVariables } from '../queries/types/GetProgramByLandingPagePathQuery';

export function useProgramForLandingPagePath(customLandingPagePath: string, clientId: string) {
  return useQuery<GetProgramByLandingPagePathQuery, GetProgramByLandingPagePathQueryVariables>(
    GET_PROGRAM_BY_LANDING_PAGE_PATH,
    {
      variables: {
        customLandingPagePath,
        clientId,
      },
      skip: !customLandingPagePath
        || !clientId,
    },
  );
}
