/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1kabb_157 {
  display: flex;
}

._justify-content-space-between_1kabb_161 {
  justify-content: space-between;
}

._tabular-nums_1kabb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1kabb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._inputArea_1kabb_178 ._input_1kabb_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188 ._info_1kabb_188 ._sub_1kabb_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188 ._info_1kabb_188 ._name_1kabb_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._memberCount_1kabb_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._MemberSearch_1kabb_178 ._overlay_1kabb_178 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
  transition: opacity 400ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: opacity;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178._active_1kabb_234 {
  opacity: 1;
  pointer-events: auto;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._mask_1kabb_238 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 {
  z-index: 2;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 43.75rem;
  max-height: calc(100% - 25rem);
  margin-top: 12.5rem;
  border-radius: 0.5rem;
  background: #f7f7f7;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._inputArea_1kabb_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 5rem;
  padding: 0 1.5rem;
  border-radius: 0.5rem;
  background: #fdfdfd;
  box-shadow: rgba(0, 0, 0, 0.16) 0 0.1875rem 0.625rem, rgba(0, 0, 0, 0.23) 0 0.1875rem 0.625rem;
  transition: color 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._inputArea_1kabb_178._active_1kabb_234 ._icon_1kabb_270 {
  color: #3996e0;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._inputArea_1kabb_178 ._icon_1kabb_270 {
  flex-shrink: 0;
  margin-right: 1rem;
  font-size: 1.25rem;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._inputArea_1kabb_178 ._input_1kabb_178 {
  flex: 1;
  border: 0;
  color: #272727;
  outline: none;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._inputArea_1kabb_178 ._input_1kabb_178::placeholder {
  color: #cbcbcb;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._memberCount_1kabb_208 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  margin-top: 0.75rem;
  padding: 0 1.5rem;
  color: #8f8d91;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 1.25rem;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._noticeWrapper_1kabb_302 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5rem;
  padding: 0 0.9375rem;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.875rem 1.75rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: background-color, color;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188:hover, ._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188._selected_1kabb_319 {
  background-color: #fff;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188 ._avatar_1kabb_322 {
  margin-right: 1rem;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188 ._info_1kabb_188 ._icon_1kabb_270 {
  margin-right: 0.5rem;
  color: #8f8d91;
  font-size: 1rem;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188 ._info_1kabb_188 ._name_1kabb_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.125rem;
}
._MemberSearch_1kabb_178 ._overlay_1kabb_178 ._content_1kabb_178 ._suggestions_1kabb_188 ._item_1kabb_188 ._info_1kabb_188 ._sub_1kabb_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

._MemberSearchTooltip_1kabb_342 kbd {
  background-color: #fafafa;
}