import gql from 'graphql-tag';

export const MEMBER_HIGHLIGHTS = gql`
  query MemberHighlightsQuery($memberId: Int!) {
    member: memberById(id: $memberId) {
      id
      highlights {
        id
        name
      }
    }
  }
`;
