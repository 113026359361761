import * as React from 'react';

import { difference, filter, find, map, size, uniq } from 'lodash';
import { Button, Select, Tooltip } from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';

import { useClientProjects, useCommunitiesQuery } from '@frontend/app/hooks';

import { FilterSection } from '../FilterSection';
import { IRelationshipFilters } from '../models';

import styles from './RelationshipSection.scss';

const { useCallback, useMemo } = React;

interface RelationshipFiltersProps extends IRelationshipFilters {
  onChange: (relationshipFilters: IRelationshipFilters) => void;
}

export const RelationshipFilters: React.FC<RelationshipFiltersProps> = (props) => {
  const {
    onChange,
    projectIds = [],
    groupIds = []
  } = props;

  const {
    data: {
      projects = []
    } = {},
    loading: isFetchingProjects
  } = useClientProjects();

  const {
    loading: isFetchingGroups,
    data: {
      communities: groups = [],
    } = {},
  } = useCommunitiesQuery();

  const activeProjects = useMemo(() => (
    filter(projects, ['archivedDate', null])
  ), [projects]);

  const archivedProjects = useMemo(() => (
    difference(projects, activeProjects)
  ), [projects, activeProjects]);

  const handleChange = useCallback((filterKey: keyof IRelationshipFilters, value: number[]) => {
    onChange({
      [filterKey]: value,
    })
  }, [onChange]);

  const selectedActiveProjectIds = useMemo(() => (
    filter(projectIds, (id) => !!find(activeProjects, ['id', id]))
  ), [activeProjects, projectIds]);

  const shouldDeselectActive = useMemo(() => (
    size(selectedActiveProjectIds) > size(activeProjects) / 2
  ), [selectedActiveProjectIds, activeProjects]);

  const selectedArchivedProjectIds = useMemo(() => (
    filter(projectIds, (id) => !!find(archivedProjects, ['id', id]))
  ), [projectIds, archivedProjects]);

  const shouldDeselectArchived = useMemo(() => (
    size(selectedArchivedProjectIds) > size(archivedProjects) / 2
  ), [selectedArchivedProjectIds, archivedProjects]);

  const handleToogleActiveProjects = useCallback(() => {
    const value = shouldDeselectActive
      ? difference(projectIds, selectedActiveProjectIds)
      : uniq([...projectIds, ...map(activeProjects, 'id')])

    handleChange(
      'projectIds',
      value,
    )
  }, [
    handleChange,
    shouldDeselectActive,
    projectIds,
    selectedActiveProjectIds,
    activeProjects
  ]);

  const handleToggleArchivedProjects = useCallback(() => {
    const value = shouldDeselectArchived
      ? difference(projectIds, selectedArchivedProjectIds)
      : uniq([...projectIds, ...map(archivedProjects, 'id')])

    handleChange(
      'projectIds',
      value,
    )
  }, [
    shouldDeselectArchived,
    projectIds,
    selectedActiveProjectIds,
    archivedProjects,
    handleChange
  ])

  const projectOptions = useMemo(() => ([
      {
        label: (
          <div className={styles.groupTitle}>
            <strong>Active Projects</strong>
            <Button
              type="link"
              onClick={handleToogleActiveProjects}
            >
              {shouldDeselectActive ? 'Deselect' : 'Select'} all
            </Button>
          </div>
        ),
        options: map(activeProjects, (project) => ({
          label: project.title,
          value: project.id,
        }))
      },
      {
        label: (
          <div className={styles.groupTitle}>
            <strong>Archived Projects</strong>
            <Button
              type="link"
              onClick={handleToggleArchivedProjects}
            >
              {shouldDeselectArchived ? 'Deselect' : 'Select'} all
            </Button>
          </div>
        ),
        options: map(archivedProjects, (project) => ({
          label: project.title,
          value: project.id,
        }))
      }
  ]), [
    shouldDeselectActive,
    activeProjects,
    handleToogleActiveProjects,
    shouldDeselectArchived,
    archivedProjects,
    handleToggleArchivedProjects
  ]);

  const groupsOptions = useMemo(() => {
    const shouldDeselectGroups = size(groupIds) > size(groups) / 2;

    return [
      {
        label: (
          <div className={styles.groupTitle}>
            <strong>All Groups</strong>
            <Button
              type="link"
              onClick={() => {
                const value = shouldDeselectGroups
                  ? []
                  : uniq([...groupIds, ...map(groups, 'id')])

                handleChange(
                  'groupIds',
                  value,
                )
              }}
            >
              {shouldDeselectGroups ? 'Deselect' : 'Select'} all
            </Button>
          </div>
        ),
        options: map(groups, (group) => ({
          label: group.title,
          value: group.id,
        }))
      },
    ]
  }, [
    handleChange,
    groupIds,
    groups,
  ]);

  const filterOption = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    return optionOrGroup.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  return (
    <FilterSection
      header="Projects & Groups"
      collapsible
      classNames={[styles.RelationshipSection]}
    >
      <div className={styles.selectGroup}>
        <div className={styles.selectGroupTitle}>
          Exclude creators in projects:
          <Tooltip
            color="white"
            title="Invited, applied, active, and completed creators in projects will be excluded from this search result."
            overlayInnerStyle={{
              color: '#505256'
            }}
            overlayStyle={{
              maxWidth: '200px'
            }}
          >
            <CircleInfoIcon className={styles.icon} />
          </Tooltip>
        </div>
        <Select
          mode="multiple"
          loading={isFetchingProjects}
          maxTagCount={1}
          options={projectOptions}
          value={projectIds}
          onChange={(v) => {
            handleChange('projectIds', v)
          }}
          placeholder="Select projects"
          className={styles.select}
          filterOption={filterOption}
          showSearch
          showArrow
          popupClassName="relationships-popup"
        />
      </div>
      <div className={styles.selectGroup}>
        <div className={styles.selectGroupTitle}>
          Exclude creators in groups:
        </div>
        <Select
          mode="multiple"
          loading={isFetchingGroups}
          maxTagCount={1}
          options={groupsOptions}
          value={groupIds}
          onChange={(v) => {
            handleChange('groupIds', v)
          }}
          placeholder="Select groups"
          className={styles.select}
          filterOption={filterOption}
          showSearch
          showArrow
          popupClassName="relationships-popup"
        />
      </div>
    </FilterSection>
  );
}
