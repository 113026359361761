import {
 useEffect, useReducer, useCallback,
} from 'react';
import { forEach, isFunction, map } from 'lodash';

import {
  useUploadContent,
  TContentType,
  IContent,
} from '@frontend/app/hooks';
import {
  initialState as initialAttachmentState,
  reducer as attachmentReducer,
  actions as attachmentActions,
} from '@frontend/app/components/MessageComposer/attachments';
import { MessageTemplateQuery_template_attachments as TemplateAttachment } from '@frontend/app/queries/types/MessageTemplateQuery';

type Props = {
  onAttachmentsChange?: () => void;
};

export const useManageAttachments = (props: Props) => {
  const { onAttachmentsChange } = props;
  const [uploadState, dispatch] = useReducer(attachmentReducer, initialAttachmentState);

  const {
    content: uploadedContent,
    upload: uploadContent,
    error: uploadError,
  } = useUploadContent({
    serviceName: 'message',
    isTemp: true,
  });

  const content = uploadedContent as IContent;

  useEffect(() => {
    if (content?.progress) {
      dispatch(attachmentActions.updateProgress(content.id, content.progress));
      if (content.progress.percentage === 100) {
        if (isFunction(onAttachmentsChange)) {
          onAttachmentsChange();
        }
      }
    }
  }, [
    content?.id,
    content?.progress,
    onAttachmentsChange,
  ]);

  useEffect(() => {
    if (content?.fileUrl) {
      dispatch(attachmentActions.updateFileUrl(content.id, content.fileUrl));
    }
  }, [content?.id, content?.fileUrl]);

  useEffect(() => {
    if (content?.id) {
      dispatch(attachmentActions.addContent(content));
    }
  }, [content]);

  useEffect(() => {
    if (uploadError && content) {
      dispatch(attachmentActions.removeContent(content.id));

      if (!uploadError.isUploadAborted) {
        dispatch(attachmentActions.setErrorMessage(uploadError.message));
      }
    }
  }, [uploadError, content]);

  const handleSelectedFilesChange = useCallback(async (files: FileList) => {
    const configs = Array.from(files).map((file) => ({
      file,
      type: file.type.split('/')[0] as TContentType,
    }));
    for (const config of configs) {
      await uploadContent(config.file, config.type);
    }
  }, [uploadContent]);

  const isUploadingAttachments = useCallback(() => {
    for (const content of uploadState.contents) {
      const percentage = content.progress.percentage;
      if (percentage > 0 && percentage < 100) {
        return true;
      }
    }
    return false;
  }, [uploadState.contents]);

  const handleDeleteAttachment = useCallback((id: string) => {
    dispatch(attachmentActions.removeContent(id));
    if (isFunction(onAttachmentsChange)) {
      onAttachmentsChange();
    }
  }, [dispatch, onAttachmentsChange]);

  const setInitialAttachments = useCallback((attachments) => {
    dispatch(attachmentActions.removeContents());
    forEach(attachments, (attachment) => {
      dispatch(attachmentActions.addContent(attachment));
    });
  }, [dispatch]);

  return {
    attachments: map(uploadState.contents, (content) => content as TemplateAttachment),
    isUploadingAttachments,
    handleSelectedFilesChange,
    handleDeleteAttachment,
    setInitialAttachments,
  };
};
