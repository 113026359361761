import gql from 'graphql-tag';
import { TERMS_TEMPLATE_FRAGMENT } from './fragments/TermsTemplateFragment';

export const UPDATE_ADVANCE_TERMS_TEMPLATE_MUTATION = gql`
  mutation UpdateAdvanceTermsTemplateMutation($id: Int!, $data: UpdateAdvanceTermsTemplateInput!) {
    terms: updateAdvanceTermsTemplateSettings(id: $id, terms: $data) {
      ...TermsTemplateFragment
    }
  }
  ${TERMS_TEMPLATE_FRAGMENT}
`;
