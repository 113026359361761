import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const HeartFilledIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <g transform="translate(0, -2)">
        <path
          d="M8.00003 14C7.8227 14 7.65336 13.93 7.52803 13.804L2.35003 8.61734C0.992698
            7.25734 0.992698 5.04467 2.35003 3.68467C3.00536 3.02867 3.8807 2.66667 4.81336
            2.66667C5.74603 2.66667 6.62136 3.02867 7.2767 3.68467L8.00003 4.40934L8.7227
            3.68534C9.3787 3.02867 10.254 2.66667 11.1867 2.66667C12.1194 2.66667 12.9947 3.02867
            13.65 3.68467C15.0074 5.04467 15.0074 7.25734 13.6507 8.61734L8.47203 13.8047C8.3467
            13.93 8.17736 14 8.00003 14Z
          "
          style={{ fill: '#F1515F' }}
        />
        <mask
          id={id}
          maskUnits="userSpaceOnUse"
          x="1"
          y="2"
          width="14"
          height="12"
        >
          <path
            d="M8.00003 14C7.8227 14 7.65336 13.93 7.52803 13.804L2.35003 8.61734C0.992698 7.25734 0.992698
              5.04467 2.35003 3.68467C3.00536 3.02867 3.8807 2.66667 4.81336 2.66667C5.74603 2.66667 6.62136
              3.02867 7.2767 3.68467L8.00003 4.40934L8.7227 3.68534C9.3787 3.02867 10.254 2.66667 11.1867
              2.66667C12.1194 2.66667 12.9947 3.02867 13.65 3.68467C15.0074 5.04467 15.0074 7.25734 13.6507
              8.61734L8.47203 13.8047C8.3467 13.93 8.17736 14 8.00003 14Z
            "
            style={{ fill: 'white' }}
          />
        </mask>
        <g mask={`url(#${id})`} />
      </g>
    </SvgIcon>
  );
});

HeartFilledIcon.displayName = 'HeartFilledIcon';

export { HeartFilledIcon };
