import { first } from 'lodash';

import { IFormatters } from './types';

const moneyFormatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
});

export const defaultPrecision = 2;

export const formatters: IFormatters = {
  default: (value: number, options = { precision: defaultPrecision }) => {
    const absoluteValue = Math.abs(value);
    const sign = absoluteValue === value ? '' : '- ';

    if (absoluteValue < 1000) {
      return `${sign}${absoluteValue.toLocaleString(undefined, { maximumFractionDigits: options.precision })}`;
    }

    if (absoluteValue < 1000000) {
      return `${sign}${(Math.round(absoluteValue / 100) / 10).toFixed(1)}K`;
    }

    return `${sign}${(Math.round(absoluteValue / 100000) / 10).toFixed(1)}M`;
  },
  dollarsWithCents: (value: number) => moneyFormatter.format(value),
  wholeDollars: (value: number) => first(moneyFormatter.format(Math.round(value)).split('.')),
};
