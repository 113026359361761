/* eslint-disable no-tabs */
import { IAudienceLocationSectionProps as IAudienceLocationData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/AudienceLocationSection/AudienceLocationSection';
import { IProps as IAudienceDemoData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/AudienceDemoSection/AudienceDemoSection';
import { IProps as ITMVData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/TMVSection/TMVSection';
import { IProps as ITopCreatorsData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/TopCreatorsSection/TopCreatorsSection';
import { IProps as ITopPostsData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/TopPostsSection/TopPostsSection';

export const TopPostsSectionData: ITopPostsData['data'] = {
  loading: false,
  data: [
    {
      network: 'instagram',
      image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      profile_image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      reach: 0,
      likes: 0,
      engagements: 0,
      impressions: 0,
      link: '',
    },
    {
      network: 'tiktok',
      image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      profile_image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      reach: 0,
      likes: 0,
      engagements: 0,
      impressions: 0,
      link: '',
    },
    {
      network: 'youtube',
      image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      profile_image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      reach: 0,
      likes: 0,
      engagements: 0,
      impressions: 0,
      link: '',
    },
  ],
  error: null,
};

export const TMVSectionData: ITMVData['data'] = {
  loading: false,
  data: [
    {
      post_type: 'instagram',
      insights: {
        reach: {
          count: 1,
          multiplier: 0,
        },
        comments: {
          count: 1,
          multiplier: 0.001,
        },
        likes: {
          count: 1,
          multiplier: 0,
        },
        clicks: {
          count: 1,
          multiplier: 0,
        },
        views: {
          count: 1,
          multiplier: 0,
        },
      },
    },
    {
      post_type: 'instagram_reel',
      insights: {
        reach: {
          count: 1,
          multiplier: 0,
        },
        comments: {
          count: 1,
          multiplier: 0.001,
        },
        likes: {
          count: 1,
          multiplier: 0,
        },
        clicks: {
          count: 1,
          multiplier: 0,
        },
        views: {
          count: 1,
          multiplier: 0,
        },
      },
    },
  ],
  error: null,
};

export const TopCreatorSectionData: ITopCreatorsData['data'] = {
  loading: false,
  data: [
    {
      username: '@',
      profile_image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      network: 'instagram',
      engagements: 0,
      engagement_rate: 0.0,
      link: '',
      rating: 0,
    },
    {
      username: '@',
      profile_image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      network: 'instagram',
      engagements: 0,
      engagement_rate: 0.0,
      link: '',
      rating: 0,
    },
    {
      username: '@',
      profile_image_url: 'https://storage.googleapis.com/aspirex-program-onboarding/ZeroStateAnalytics/blank-image.png',
      network: 'instagram',
      engagements: 0,
      engagement_rate: 0,
      link: '',
      rating: 0,
    },
  ],
  error: null,
};

export const AudienceDemoSectionData: IAudienceDemoData['data'] = {
  loading: false,
  data: {
    gender: {
      male: 0,
      female: 0,
    },
    age: {
      '13-17': 1,
      '18-24': 1,
      '25-34': 1,
      '35-44': 1,
      '45-54': 1,
      '55-64': 1,
    },
  },
  error: null,
};

export const AudienceLocationSectionData: IAudienceLocationData['data'] = {
  loading: false,
  data: {
    city: [
      {
        grouping: 'Los Angeles',
        count: 0,
      },
      {
        grouping: 'Atlanta',
        count: 0,
      },
      {
        grouping: 'London',
        count: 0,
      },
      {
        grouping: 'New York',
        count: 0,
      },
      {
        grouping: 'Buenos Aires',
        count: 0,
      },
      {
        grouping: 'Stockholm',
        count: 0,
      },
    ],
    country: [
      {
        grouping: 'US',
        count: 0,
      },
      {
        grouping: 'ID',
        count: 0,
      },
      {
        grouping: 'IN',
        count: 0,
      },
      {
        grouping: 'IT',
        count: 0,
      },
      {
        grouping: 'MX',
        count: 0,
      },
      {
        grouping: 'MY',
        count: 0,
      },
    ],
  },
  error: null,
};
