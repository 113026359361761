import * as React from 'react';
import { format } from 'date-fns';
import { Card, Typography } from '@revfluence/fresh';

import { IDesignatedContractSigner } from '../useFetchSigners';

interface IProps {
  signer: IDesignatedContractSigner;
}

const { Text } = Typography;

export const AuthorizedSignerCell: React.FunctionComponent<IProps> = (props) => {
  const { signer } = props;

  return (
    <Card style={{ marginBottom: 'var(--spacing-sm)' }} bodyStyle={{ display: 'flex', flexFlow: 'column' }}>
      <Text strong>{signer.name}</Text>
      <Text>{signer.email_address}</Text>
      <Text>
        Added:
        {format(Date.parse(signer.date_created), 'MMM dd, yyyy')}
      </Text>
    </Card>
  );
};
