import * as React from 'react';
import {
  capitalize, isEmpty, size, map, keyBy, isUndefined,
} from 'lodash';
import { useQuery } from '@apollo/client';
import { Modal, IModalProps, ModalSize } from '@components';

import {
  SelectList,
  FormActions,
  EllipsisLabel,
} from '@frontend/app/components';
import { useMessagingContext } from '@frontend/hooks';
import {
  useFuzzySearchByKeys,
  useModifyCommunityMembersMutation,
  useFeatureFlagVerbiage,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { GET_COMMUNITIES_QUERY } from '@frontend/app/queries';
import {
  GetCommunitiesQuery,
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import styles from './ModifyCommunityMembersModal.scss';

const { useState, useEffect, useMemo } = React;

const SEARCH_KEYS = ['title'];

type TType = 'add' | 'remove';

interface IProps extends IModalProps {
  query?: IMemberSearchQuery;
  memberIds?: number[];
  memberCount?: number;
  type: TType;
  defaultSelectedCommunityIds?: number[];
  onModifyComplete?(communities: ICommunity[], memberIds?: number[]);
}

export const ModifyCommunityMembersModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const { defaultSelectedCommunityIds } = props;
  const verbiage = useFeatureFlagVerbiage();
  const [selectedCommunityIds, setSelectedCommunityIds] = useState<number[]>(props.defaultSelectedCommunityIds);
  const [type, setType] = useState<TType>(props.type);

  const {
    showMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  useEffect(() => {
    if (props.show) {
      setType(props.type);
    } else {
      setTimeout(() => {
        setType(props.type);
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  useEffect(() => {
    setSelectedCommunityIds(defaultSelectedCommunityIds);
  }, [defaultSelectedCommunityIds]);

  const {
    data: {
      communities = [],
    } = {},
    loading: isLoadingCommunities,
  } = useQuery<GetCommunitiesQuery>(GET_COMMUNITIES_QUERY, {
    skip: !props.show,
  });

  const [addMembersToCommunity, {
    loading: addingMembers,
  }] = useModifyCommunityMembersMutation('add', {
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount} member${memberCount > 1 ? 's' : ''} added to ${verbiage.communities}`,
      });
    },
    onError() {
      showGenericErrorMessage();
    },
  });

  const [removeMembersFromCommunity, {
    loading: removingMembers,
  }] = useModifyCommunityMembersMutation('remove', {
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount} member${memberCount > 1 ? 's' : ''} removed from ${verbiage.communities}`,
      });
    },
    onError() {
      showGenericErrorMessage();
    },
  });

  const handleSearch = useFuzzySearchByKeys(communities, SEARCH_KEYS);

  type TOption = typeof communities[0];

  const mapOptionToLabel = (community: TOption) => (
    <div className={styles.label}>
      <img className={styles.img} src={community.splashImageUrl} />
      <EllipsisLabel tooltipPlacement="right">
        {community.title}
      </EllipsisLabel>
    </div>
    );

  const memberCount = size(props.memberIds) || props.memberCount;

  const addRemoveMembers = async (communityIds: number[]) => {
    const vars = isEmpty(props.memberIds) ? {
      query: props.query,
    } : {
      memberIds: props.memberIds,
    };

    if (type === 'add') {
      await addMembersToCommunity({
        variables: {
          ...vars,
          communityIds,
        },
      });
    } else {
      await removeMembersFromCommunity({
        variables: {
          ...vars,
          communityIds,
        },
      });
    }
  };

  const addEvent = useEventContext();
  const handleSave = async (communityIds: number[]) => {
    if (!isEmpty(props.memberIds) || props.query) {
      await addRemoveMembers(communityIds);
    }

    if (props.onModifyComplete) {
      const communitiesById = keyBy(communities, 'id');
      const selectedCommunities = map(communityIds, (id) => communitiesById[id]);
      props.onModifyComplete(selectedCommunities, props.memberIds);
    }

    setSelectedCommunityIds([]);

    if (props.onRequestClose) {
      props.onRequestClose();
    }
    const action = type === 'add' ? 'add_to_group' : (type === 'remove' ? 'remove_from_group' : null);
    if (action) {
      addEvent(
        EventName.CompleteBulkAction,
        {
          action,
          member_count: memberCount,
        },
      );
    }
  };

  const primaryActionText = useMemo(() => {
    if (isUndefined(type) || isUndefined(memberCount)) return;

    let action;
    if (type === 'add') {
      action = 'Add';
    } else if (type === 'remove') {
      action = 'Remove';
    }

    return action;
  }, [type, memberCount]);

  const title = useMemo(() => {
    if (isUndefined(type)) return;

    if (type === 'add') {
      return `Add to ${capitalize(verbiage.communities)}`;
    }

    if (type === 'remove') {
      return `Remove from ${capitalize(verbiage.communities)}`;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const subtitle = useMemo(() => {
    if (isUndefined(type) || isUndefined(memberCount)) return;

    let action;
    if (type === 'add') {
      action = 'Add';
    } else if (type === 'remove') {
      action = 'Remove';
    }

    let text;
    if (memberCount === 0) {
      text = `${action} Members`;
    } else if (memberCount === 1) {
      text = `${action} 1 Member`;
    } else {
      text = `${action} ${memberCount} Members`;
    }

    if (type === 'add') {
      return `${text} to ${capitalize(verbiage.communities)}`;
    } else if (type === 'remove') {
      return `${text} from ${capitalize(verbiage.communities)}`;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, memberCount]);

  const primaryType = useMemo(() => {
    if (isUndefined(type)) return;
    if (type === 'add') return 'primary';
    if (type === 'remove') return 'danger';
  }, [type]);

  const emptyMessage = useMemo(() => {
    if (isUndefined(type)) return;
    if (type === 'add') return `There are no available ${verbiage.communities} to add to`;
    if (type === 'remove') return `There are no available ${verbiage.communities} to remove from`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const disabled = useMemo(() => {
    if (isUndefined(addingMembers) || isUndefined(removingMembers)) return;

    return addingMembers || removingMembers;
  }, [addingMembers, removingMembers]);

  return (
    <Modal
      width={ModalSize.Small}
      className={styles.ModifyCommunityMembersModal}
      onRequestClose={props.onRequestClose}
      show={props.show}
      showCloseIcon={props.showCloseIcon}
    >
      <FormActions
        title={title}
        subtitle={subtitle}
        primaryAction={{
          danger: primaryType === 'danger',
          disabled: isEmpty(selectedCommunityIds),
          label: primaryActionText,
          onClick: handleSave.bind(this, selectedCommunityIds),
        }}
        tertiaryAction={{
          label: 'Cancel',
          onClick: props.onRequestClose,
        }}
        disabled={disabled}
      >
        <SelectList
          searchPlaceholder={`Search ${verbiage.communities}...`}
          showSearch
          selectedIds={selectedCommunityIds}
          onSearchRequest={handleSearch}
          options={communities}
          mapOptionToId={(community: TOption) => community.id}
          mapOptionToLabel={mapOptionToLabel}
          disabled={disabled}
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          onChange={setSelectedCommunityIds as any}
          isLoading={isLoadingCommunities}
          emptyMessage={emptyMessage}
        />
      </FormActions>
    </Modal>
  );
});
