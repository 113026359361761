import gql from 'graphql-tag';

export const GET_REPORTING_RECOMMENDATION = gql`
  query ReportingRecommendation {
    reportingRecommendation {
      axis
      title
      text
      ctaText
      ctaLink
      htmlTagId
    }
  }
`;
