import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const FileTypeMp4Colored = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 56 56">
    <path
      style={{ fill: '#E9E9E0' }}
      d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
      c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
    />
    <path
      style={{ fill: '#FF5364' }}
      d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M22.4,42.924h1.668V53H22.4v-6.932l-2.256,5.605h-1.449l-2.27-5.605V53h-1.668V42.924h1.668
      l2.994,6.891L22.4,42.924z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M28.211,53H26.57V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
      c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
      c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
      s-0.921,0.232-1.449,0.232h-1.217V53z M28.211,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
      c0.196-0.068,0.376-0.18,0.54-0.335s0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032c0-0.164-0.023-0.354-0.068-0.567
      c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492c-0.255-0.132-0.593-0.198-1.012-0.198H28.211z"
    />
    <path
      style={{ fill: '#FFFFFF' }}
      d="M38.479,50.648h-4.361V49.35l4.361-6.426h1.668v6.426h1.053v1.299h-1.053V53h-1.668V50.648z
      M38.479,49.35v-4.512L35.58,49.35H38.479z"
    />
    <path
      style={{ fill: '#C8BDB8' }}
      d="M24.5,28c-0.166,0-0.331-0.041-0.481-0.123C23.699,27.701,23.5,27.365,23.5,27V13
      c0-0.365,0.199-0.701,0.519-0.877c0.321-0.175,0.71-0.162,1.019,0.033l11,7C36.325,19.34,36.5,19.658,36.5,20
      s-0.175,0.66-0.463,0.844l-11,7C24.874,27.947,24.687,28,24.5,28z M25.5,14.821v10.357L33.637,20L25.5,14.821z"
    />
    <path
      style={{ fill: '#C8BDB8' }}
      d="M28.5,35c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S36.771,35,28.5,35z M28.5,7
      c-7.168,0-13,5.832-13,13s5.832,13,13,13s13-5.832,13-13S35.668,7,28.5,7z"
    />
    <polygon style={{ fill: '#D9D7CA' }} points="37.5,0.151 37.5,12 49.349,12" />
  </SvgIcon>
));

FileTypeMp4Colored.displayName = 'FileTypeMp4Colored';

export { FileTypeMp4Colored };
