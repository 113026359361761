import 'css-chunk:src/app/containers/MessagingApp/ThreadFolderSection.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_18p4w_157",
  "justify-content-space-between": "_justify-content-space-between_18p4w_161",
  "tabular-nums": "_tabular-nums_18p4w_165",
  "ThreadFolderSection": "_ThreadFolderSection_18p4w_178",
  "userList": "_userList_18p4w_178",
  "title": "_title_18p4w_178",
  "threadFilter": "_threadFilter_18p4w_178",
  "filterItem": "_filterItem_18p4w_178",
  "label": "_label_18p4w_178",
  "list": "_list_18p4w_188",
  "item": "_item_18p4w_188",
  "count": "_count_18p4w_188",
  "errorNotice": "_errorNotice_18p4w_198",
  "actions": "_actions_18p4w_198",
  "actionButton": "_actionButton_18p4w_198",
  "content": "_content_18p4w_198",
  "errorText": "_errorText_18p4w_198",
  "errorTitle": "_errorTitle_18p4w_198",
  "visible": "_visible_18p4w_249",
  "dropdownWrapper": "_dropdownWrapper_18p4w_252",
  "composerWrapper": "_composerWrapper_18p4w_256",
  "messageComposer": "_messageComposer_18p4w_268",
  "active": "_active_18p4w_290",
  "marginTop": "_marginTop_18p4w_298",
  "secondary": "_secondary_18p4w_301",
  "iconWrapper": "_iconWrapper_18p4w_311",
  "reconnect": "_reconnect_18p4w_372",
  "avatar": "_avatar_18p4w_396",
  "show": "_show_18p4w_1"
};