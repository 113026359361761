import { QueryHookOptions, useQuery } from '@apollo/client';
import { GetThreadsForMember, GetThreadsForMemberVariables } from '../queries/types/GetThreadsForMember';
import { GET_THREADS_FOR_MEMBER_QUERY } from '../queries';

type IOptions = QueryHookOptions<GetThreadsForMember, GetThreadsForMemberVariables>;

export const useGetMemberThreads = (options: IOptions = {}) => (
  useQuery<GetThreadsForMember, GetThreadsForMemberVariables>(GET_THREADS_FOR_MEMBER_QUERY, {
    ...options,
    fetchPolicy: 'cache-and-network',
  })
);
