import * as React from 'react';
import cx from 'classnames';
import {
  Menu, Dropdown, Popover, Typography,
} from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  map, size, toLower, sortBy,
} from 'lodash';

import { useGetCurrentClient, useGetProfile, useTopNavigation } from '@frontend/app/hooks';
import { BadgeHasUserError } from '@frontend/app/components';
import { MemberSearch } from '../MemberSearch';
import { UserAvatar } from '../UserAvatar';
import { NavItem } from './NavItem';

import styles from './TopNavbar.scss';
import dropdownStyles from './TopbarDropdown.scss';

const { useMemo, useCallback } = React;
const { Title, Text } = Typography;

export const TopNavbar: React.FC = () => {
  const location = useLocation();
  const {
    mainNavItems,
    userMenuItems,
    handleNavItemClick,
    user,
  } = useTopNavigation();
  const { profile, loading: loadingProfile } = useGetProfile();
  const { client: currentClient, loading: loadingClient } = useGetCurrentClient();

  const onSwitchClient = useCallback((client) => {
    const { location } = window;
    const rootDomain = location.hostname.split('.').slice(-2).join('.');

    if (rootDomain === 'localhost') {
      return location.assign(`/client/${client.id}`);
    }

    const hostname = client.hostname || 'community';

    return location.assign(`${location.protocol}//${hostname}.${rootDomain}/client/${client.id}`);
  }, []);

  const loading = useMemo(() => loadingProfile || loadingClient, [loadingProfile, loadingClient]);
  const isSettingsPage = useMemo(() => !!location.pathname.startsWith('/settings/'), [location.pathname]);
  const UserClients = useMemo(
    () => (
      <div className={dropdownStyles.UserClientMenu}>
        {map(sortBy(profile?.clients, (c) => toLower(c.name)), (client) => (
          <div
            key={client.id}
            className={cx(dropdownStyles.client, {
              [dropdownStyles.active]: currentClient?.id === client.id,
            })}
            onClick={() => onSwitchClient(client)}
          >
            {client.name}
          </div>
        ))}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, currentClient],
  );

  const userSettingsMenu = useMemo(
    () => (
      <Menu className={dropdownStyles.TopNavbarSubmenu}>
        {!loading && (
          <>
            <Menu.Item key="userMenu">
              <div className={dropdownStyles.userMenuContent}>
                <Title level={4}>{currentClient?.name}</Title>
                <Text type="secondary" className={dropdownStyles.userEmail}>{profile.email}</Text>
                {size(profile.clients) > 1 && (
                  <Popover
                    content={UserClients}
                    placement="left"
                    trigger={['hover']}
                  >
                    <div
                      onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                      className={dropdownStyles.switchAccounts}
                    >
                      Switch Accounts
                    </div>
                  </Popover>
                )}
              </div>
            </Menu.Item>
            <Menu.Divider />
          </>
        )}

        {userMenuItems.map((item) => (
          <Menu.Item className={dropdownStyles.TopNavbarSubmenuItem} key={item.id} onClick={() => handleNavItemClick(item.id)}>
            {item.node || item.text}
          </Menu.Item>
        ))}
      </Menu>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, currentClient, profile, userMenuItems, handleNavItemClick],
  );

  return (
    <div className={styles.TopNavbar}>
      <div className={styles.Left}>
        <div className={styles.logo}>
          <Link to="/home">
            <img src="https://storage.googleapis.com/aspirex-static-files/new_logo.png" />
          </Link>
        </div>
        {mainNavItems.map((item) => (
          <NavItem key={item.id} item={item} onClick={handleNavItemClick} />
        ))}
      </div>
      <div className={styles.Right}>
        <MemberSearch className={styles.TopNavMemberSearch} />
        <Dropdown
          overlay={userSettingsMenu}
          overlayClassName={dropdownStyles.TopNavbarDropdown}
          placement="bottomRight"
          mouseEnterDelay={0}
          mouseLeaveDelay={0.05}
        >
          <div className={cx(styles.userMenu, { [styles.selected]: isSettingsPage })}>
            <BadgeHasUserError>
              <UserAvatar profilePicture={user.picture} name={user.name} size={32} />
            </BadgeHasUserError>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
