/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1q3pt_157 {
  display: flex;
}

._justify-content-space-between_1q3pt_161 {
  justify-content: space-between;
}

._tabular-nums_1q3pt_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1q3pt_178 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SimpleField_1q3pt_178._showControlledErrors_1q3pt_178 {
  margin-bottom: 2rem;
}
._SimpleField_1q3pt_178._showControlledErrors_1q3pt_178._hasError_1q3pt_181 .ant-input,
._SimpleField_1q3pt_178._showControlledErrors_1q3pt_178._hasError_1q3pt_181 .ant-select-selector,
._SimpleField_1q3pt_178._showControlledErrors_1q3pt_178._hasError_1q3pt_181 .ant-picker {
  border-color: #f1515f !important;
  box-shadow: none !important;
}
._SimpleField_1q3pt_178._showControlledErrors_1q3pt_178._legacyField_1q3pt_187 .ant-select-selector,
._SimpleField_1q3pt_178._showControlledErrors_1q3pt_178._legacyField_1q3pt_187 .ant-input,
._SimpleField_1q3pt_178._showControlledErrors_1q3pt_178._legacyField_1q3pt_187 .ant-picker {
  border: 0.03125rem solid #dadcde;
  border-radius: 1.25rem;
  background-color: #EFF5F9;
}
._SimpleField_1q3pt_178 ._error_1q3pt_194 {
  margin-top: 0.5rem;
  color: #f1515f;
  font-size: 0.75rem;
}