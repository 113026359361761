import * as React from 'react';

import { OfferDetailsSummaryCard } from './OfferDetailsSummaryCard';
import { OfferFormSummaryCard } from './OfferFormSummaryCard';
import { IFormProps, TProps } from './types';

export const isFormVariant = (props: TProps): props is IFormProps => props.offerFormVariant;

export const OfferSummaryCard: React.FC<TProps> = (props) => {
  if (isFormVariant(props)) {
    return <OfferFormSummaryCard {...props} />;
  }
  return <OfferDetailsSummaryCard {...props} />;
};
