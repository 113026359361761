import * as React from 'react';
import { isEmpty } from 'lodash';

import {
  ICampaign, endpoints, genericAddEventLog,
  LockIcon, InstagramIcon, Button, Notice,
} from '@components';

import styles from './OAuthNotice.scss';

const OauthPrompt: React.FunctionComponent<{
  campaign?: ICampaign;
  oauthEndpoint?: string;
  oauthType?: string;
  redirect?: string;
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS;
}> = React.memo((props) => {
  const { campaign, redirect, aspirexAnalytics } = props;
  const oauthEndpoint = props.oauthEndpoint || `${endpoints.mentionedListOauthEndpoint}`;
  const oauthType = props.oauthType || 'aspirex_add_brand_account_mentions';
  const oauthRedirect = () => {
    const campaignQueryString = campaign ? `&campaign_id=${campaign.id}` : '';
    const redirectQueryString = redirect ? `&redirect=${redirect}` : '';
    const parameters = `?oauth_type=${oauthType}${campaignQueryString}${redirectQueryString}`;

    genericAddEventLog(
      'oauth_grant_start',
      {
        app: 'social_post',
      },
      aspirexAnalytics,
    );

    window.location.replace(
      oauthEndpoint + parameters,
    );
  };

  return (
    <div className={styles.contents}>
      <div className={styles.addCreators}>
        Add creators who tag you in their photos and mention you in comments.
      </div>
      <div className={styles.connectFacebook}>
        Connecting your Instagram will allow you to see more creators that have mentioned you.
      </div>
      <Button
        label="Connect your Instagram"
        icon={<InstagramIcon className={styles.igIcon} size={14} />}
        onClick={oauthRedirect}
        className={styles.igButton}
      />
      <div className={styles.permissions}>
        <LockIcon className={styles.lockIcon} size={16} />
        You need to be the account owner or have admin permissions for your Instagram account&apos;s
        Facebook page to do this.
      </div>
    </div>
  );
});

OauthPrompt.displayName = 'OauthPrompt';

const GenericPrompt: React.FunctionComponent<{
  oauthStatus: string;
}> = React.memo((props) => {
  const { oauthStatus } = props;

  let contentsTitle = '';
  let contentsBody = '';
  if (oauthStatus === 'success') {
    contentsTitle = 'Your Instagram is now connected';
    contentsBody = 'The list below will now have improved coverage for creators who tag and mention you.';
  } else if (oauthStatus === 'error.INCORRECT_ACCOUNT') {
    contentsTitle = 'Authorization Failed';
    contentsBody = 'The Instagram account you authorized does not match your brand\'s Instagram account.';
  } else if (oauthStatus === 'error.INSUFFICIENT_PERMISSIONS') {
    contentsTitle = 'Authorization Failed';
    contentsBody = 'You did not grant sufficient permissions for Aspire to retrieve mentions. '
      + 'Please ensure you authorize with an admin account and grant all requested permissions.';
  } else if (oauthStatus === 'error.UNKNOWN') {
    contentsTitle = 'Authorization Failed';
    contentsBody = 'An unknown error occured. If this issue persists please contact help@aspireiq.com';
  }
  return (
    <div className={styles.contents}>
      <div className={styles.contentsTitle}>{contentsTitle}</div>
      <div className={styles.contentsBody}>{contentsBody}</div>
    </div>
  );
});

GenericPrompt.displayName = 'GenericPrompt';

export const OAuthNotice: React.FunctionComponent<{
  campaign?: ICampaign;
  oauthStatus: string;
  oauthEndpoint?: string;
  oauthType?: string;
  redirect?: string;
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS;
}> = React.memo((props) => (
  <Notice
    showDivider
    className={styles.OAuthNotice}
    type={props.oauthStatus.includes('error') ? 'error' : 'info'}
  >
    {isEmpty(props.oauthStatus) ? (
      <OauthPrompt
        campaign={props.campaign}
        oauthType={props.oauthType}
        redirect={props.redirect}
        oauthEndpoint={props.oauthEndpoint}
        aspirexAnalytics={props.aspirexAnalytics}
      />
      ) : (
        <GenericPrompt oauthStatus={props.oauthStatus} />
      )}
  </Notice>
));

OAuthNotice.displayName = 'OAuthNotice';
