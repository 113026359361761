import gql from 'graphql-tag';
import { ACTIVATION_FRAGMENT } from './fragments/ActivationFragment';

export const SAVE_ACTIVATION = gql`
  mutation SaveActivationMutation($activation: ActivationInput!) {
    activation: saveActivation(activation: $activation) {
      ...ActivationFragment
    }
  }
  ${ACTIVATION_FRAGMENT}
`;
