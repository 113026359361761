import * as React from 'react';
import { Notice } from '@components';

import styles from './SectionDataReturnedEmpty.scss';

const SectionDataReturnedEmpty = ({ dataReturnedEmpty }) => {
  if (dataReturnedEmpty) {
    return (
      <Notice type="disabled" className={styles.notice}>
        You have no data that matches those filters.
      </Notice>
    );
  }
  return null;
};

export default SectionDataReturnedEmpty;
