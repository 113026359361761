/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect, useRef } from 'react';

interface YouTubeEmbedProps {
  videoUrl: string;
  className?: string;
}

declare global {
  interface Window {
    YT?: typeof YT;
    onYouTubeIframeAPIReady?: () => void;
  }
}

declare namespace YT {
  export class Player {
    constructor(elementId: string | HTMLElement, options: PlayerOptions);
    destroy(): void;
  }

  export interface PlayerOptions {
    videoId: string;
    events?: {
      onReady?: (event: OnReadyEvent) => void;
      onError?: (event: OnErrorEvent) => void;
    };
  }

  export interface OnReadyEvent {
    target: Player;
  }

  export interface OnErrorEvent {
    target: Player;
    data: number;
  }
}

export const YoutubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoUrl, className }) => {
  const playerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getYouTubeVideoId = (url: string): string | null => {
      const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S*\/|(?:v|embed|e|shorts)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
      const match = url.match(regex);
      return match && match[1] ? match[1] : null;
    };

    const videoId = getYouTubeVideoId(videoUrl);

    if (!videoId) {
      console.error('Invalid YouTube URL provided.');
      return;
    }

    let player: YT.Player | null = null;

    const onPlayerReady = (_event: YT.OnReadyEvent) => {
      console.log('YouTube Player is ready.');
    };

    const onPlayerError = (event: YT.OnErrorEvent) => {
      console.error('YouTube Player Error:', event.data);
    };

    const createPlayer = () => {
      if (playerRef.current) {
        player = new window.YT!.Player(playerRef.current, {
          videoId,
          events: {
            onReady: onPlayerReady,
            onError: onPlayerError,
          },
        });
      }
    };

    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = createPlayer;
    } else {
      createPlayer();
    }

    return () => {
      if (player && typeof player.destroy === 'function') {
        player.destroy();
      }
    };
  }, [videoUrl]);

  return <div ref={playerRef} className={className} />;
};
