import { findIndex } from 'lodash';
import { IState } from '../../../../types/state';
import { IRemovePostTypeAction } from '../CollaborationDetails/index';

export const removePostType = (state: IState, action: IRemovePostTypeAction): IState => {
  const {
    id,
  } = action.contentGuideline;
  const { contentGuidelineInstanceId } = action;
  const findIndexBy = contentGuidelineInstanceId
    ? { contentGuidelineInstanceId }
    : {
        id, contentGuidelineInstanceId: null,
      };
  const contentGuidelineIndex = findIndex(state.contentGuidelines, findIndexBy);
  if (contentGuidelineIndex !== -1) {
    const updatedGuideline = state.contentGuidelines[contentGuidelineIndex];
    const { dueDates } = updatedGuideline;
    return {
      ...state,
      contentGuidelines: [
        ...state.contentGuidelines.slice(0, contentGuidelineIndex),
        {
          ...updatedGuideline,
          dueDates: {
            ...dueDates,
            completeDate: dueDates.completeDate.slice(0, dueDates.completeDate.length - 1),
            submissionDate: dueDates.submissionDate.slice(0, dueDates.submissionDate.length - 1),
          },
        },
        ...state.contentGuidelines.slice(contentGuidelineIndex + 1),
      ],
    };
  }
  return state;
};
