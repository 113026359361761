import { useMutation, MutationHookOptions } from '@apollo/client';

import { APPROVE_CONTENT_REVIEW_MUTATION } from '@frontend/app/queries';

import {
  ApproveContentReviewMutation,
  ApproveContentReviewMutationVariables,
} from '@frontend/app/queries/types/ApproveContentReviewMutation';

type IOptions = MutationHookOptions<ApproveContentReviewMutation, ApproveContentReviewMutationVariables>;

export function useApproveContentReview(options: IOptions = {}) {
  const [approveReview, { loading, error }] = useMutation<
    ApproveContentReviewMutation,
    ApproveContentReviewMutationVariables
  >(APPROVE_CONTENT_REVIEW_MUTATION, {
    ...options,
  });

  return {
    approveReview,
    loading,
    error,
  };
}
