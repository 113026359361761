import * as React from 'react';
import {
  Typography,
  Space,
} from '@revfluence/fresh';
import
  SelectContentUsageRights
from '@frontend/applications/TermsApp/components/shared/SelectContentUsageRights/SelectContentUsageRights';
import
  TextAreaContentUsageRights
  from '@frontend/applications/TermsApp/components/shared/TextAreaContentUsageRights/TextAreaContentUsageRights';
  import { ContentUsageRightsType } from '@frontend/app/types/globalTypes';
import { TContentUsageRights } from '../types/CollaborationDetails';

const { Title, Text } = Typography;
const { useCallback, useEffect } = React;

interface IProps {
  contentRightsTemplates: Array<TContentUsageRights>;
  contentRight: TContentUsageRights;
  onUpdateContentRight: (contentRight: TContentUsageRights) => void;
}

const ContentUsageRights: React.FC<IProps> = React.memo((props) => {
  const {
    contentRight,
    contentRightsTemplates,
    onUpdateContentRight,
  } = props;

  const handleChange = useCallback((type: ContentUsageRightsType | '') => {
    const contentRightTemplateSelected = contentRightsTemplates.find(
      (contentRightTemplate) => contentRightTemplate.type === type,
    );
    onUpdateContentRight({
      type,
      text: contentRightTemplateSelected.text,
    });
  }, [onUpdateContentRight, contentRightsTemplates]);

  useEffect(() => {
    if (contentRight.type === '') {
      handleChange(ContentUsageRightsType.LIMITED);
    }
  },
  [
    contentRight.type,
    handleChange,
  ]);

  return (
    <div>
      <Title level={4}>Content usage rights</Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Space direction="horizontal">
          <Text>Select the license terms you require for this collab: </Text>
          <SelectContentUsageRights
            contentRight={contentRight}
            contentRightsTemplates={contentRightsTemplates}
            onUpdateContentRight={onUpdateContentRight}
          />
        </Space>
        <TextAreaContentUsageRights
          contentRight={contentRight}
          onUpdateContentRight={onUpdateContentRight}
        />
      </Space>
    </div>
  );
});

ContentUsageRights.displayName = 'ContentUsageRights';

export default ContentUsageRights;
