import * as React from 'react';
import cx from 'classnames';
import { map, isString } from 'lodash';

import { Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import styles from './MenuList.scss';

const { Text } = Typography;

const {
  useMemo,
  useEffect,
  useRef,
  createRef,
} = React;

interface IItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  id: any;
  title: React.ReactNode;
  icon?: React.ReactNode;
}

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedId?: any;
  items: IItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectItem?(id: any);
  className?: string;
}

export const MenuList: React.FC<IProps> = React.memo((props) => {
  const {
    selectedId,
    items,
    onSelectItem,
    className,
  } = props;

  const itemsRef = useMemo(
    () => items.reduce(
      (acc, value) => {
        acc[value.id] = createRef<HTMLDivElement>();
        return acc;
      },
      {},
    ),
    [items],
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (itemsRef[selectedId] && containerRef) {
      const refTop = itemsRef[selectedId]?.current.offsetTop;
      const containerTop = containerRef?.current?.offsetTop;
      containerRef.current.scrollTop = refTop - containerTop;
    }
  }, [
    selectedId,
    itemsRef,
    containerRef,
  ]);

  return (
    <div ref={containerRef} className={cx(styles.MenuList, className)}>
      {map(items, (item) => (
        <div
          ref={itemsRef[item.id]}
          key={item.id}
          className={cx(styles.item, {
              [styles.active]: selectedId === item.id,
            })}
          onClick={() => onSelectItem?.(item.id)}
        >
          {isString(item.title) ? (
            <Text ellipsis className={styles.text}>
              {item.title}
            </Text>
            ) : item.title}
          <div className={styles.icons}>
            {item.icon}
            <RightOutlined />
          </div>
        </div>
        ))}
    </div>
  );
});

MenuList.displayName = 'MenuList';
