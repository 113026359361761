/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_m876f_157 {
  display: flex;
}

._justify-content-space-between_m876f_161 {
  justify-content: space-between;
}

._tabular-nums_m876f_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_m876f_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._LoadSpinner_m876f_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
}