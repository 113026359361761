import { useCallback, useMemo, useReducer } from 'react';
import { filter, first } from 'lodash';

import { TDeactivateMembersProps } from '@affiliates/components/MembersWizard/types';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
  getDefaultState,
  reducer,
  TActions,
  TState,
} from './state';

type TToReturn = Readonly<{
  actions: TActions;
  state: TState;
}>;

export const useState = (props: TDeactivateMembersProps): TToReturn => {
  const {
    members,
  } = props;
  const defaultState = useMemo(
    () => getDefaultState(members),
    [members],
  );
  const [state, dispatch] = useReducer(reducer, defaultState);
  const actions = {
    dismissCloseConfirmation: useCallback(() => {
      dispatch({
        showCloseConfirmation: false,
        type: 'UPDATE SHOW CLOSE CONFIRMATION',
      });
    }, []),
    reset: useCallback(() => {
      dispatch({
        members,
        type: 'RESET DEFAULT STATE',
      });
    }, [members]),
    save: useCallback((step: number) => {
      dispatch({
        saving: true,
        type: 'UPDATE SAVING STATE',
      });
      let variables = null;
      switch (props.offerSource) {
        case OFFER_SOURCE.SHOPIFY:
          variables = {
            affiliates: filter(state.members, (m) => m.selected).map((m) => ({
              id: m.affiliateOfferId,
            })),
            id: props.offerId,
          };
          break;
        case OFFER_SOURCE.TUNE:
          const { offer } = props;
          const { affiliateStats } = first(offer.links);
          const selectedMembers = filter(state.members, (m) => m.selected);
          variables = {
            ids: filter(affiliateStats, (affiliateStat) => {
              const affiliate = selectedMembers.find(({ id }) => id === affiliateStat.aspirexMemberId);
              return !!affiliate;
            }).map((m) => m.affiliateOfferId),
          };
          break;
      }
      props.onSave(variables, [], undefined)
        .then(() => {
          dispatch({
            saving: false,
            type: 'UPDATE SAVING STATE',
          });
          dispatch({
            step,
            type: 'SET CURRENT STEP',
          });
        })
        .catch((error) => {
          dispatch({
            saving: false,
            type: 'UPDATE SAVING STATE',
          });
          dispatch({
            error,
            type: 'SET ERROR',
          });
        });
    }, [
      props,
      state.members,
    ]),
    selectMembers: useCallback((members) => {
      dispatch({
        members,
        type: 'SELECT MEMBERS',
      });
    }, []),
    setCurrentStep: useCallback((step) => {
      dispatch({
        step,
        type: 'SET CURRENT STEP',
      });
    }, []),
    setError: useCallback((error) => {
      dispatch({
        error,
        type: 'SET ERROR',
      });
    }, []),
    showCloseConfirmation: useCallback(() => {
      dispatch({
        showCloseConfirmation: true,
        type: 'UPDATE SHOW CLOSE CONFIRMATION',
      });
    }, []),
  };

  return { actions, state };
};
