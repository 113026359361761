import * as React from 'react';
import { first } from 'lodash';

import { IWorkItem } from '@services/workflow';
import { AddMembers } from '@affiliates/components';
import { TAddMembersProps } from '@affiliates/components/MembersWizard/types';
import { OFFER_TYPE, OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { GetOffersBySearchQuery_offers } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { logger } from '@common';

const { useCallback } = React;

export interface IWorkflowAddMembers {
  offer: GetOffersBySearchQuery_offers,
  offerSource: OFFER_TYPE;
  programLabel: string;
  workItems: IWorkItem[];
  onClose: TAddMembersProps['onClose'];
  fetchMembers: TAddMembersProps['fetchMembers'];
  onSave: TAddMembersProps['onSave'];
  migrateToGraphQL?: boolean;
}

export const WorkflowAddMembers: React.FC<IWorkflowAddMembers> = (props) => {
  const fakeFetchPrograms = useCallback(() => Promise.resolve([]), []);
  const {
    offerSource,
    offer,
    programLabel,
    onClose,
    workItems,
    onSave,
    fetchMembers,
    migrateToGraphQL,
  } = props;
  switch (offerSource) {
    case OFFER_TYPE.PROMO_CODE: {
      const promo = first(offer.promos);
      if (!promo) {
        logger.warn('Received promo offer with no code!');
        return null;
      }
      return (
        <AddMembers
          offerSource={OFFER_SOURCE.SHOPIFY}
          codeGenerationStrategy={promo.prefixType}
          discountAmount={promo.priceRuleAmount || 0}
          discountType={promo.priceRuleType}
          fetchMembers={fetchMembers}
          fetchPrograms={fakeFetchPrograms}
          offerId={offer.id}
          offerName={offer.name}
          onClose={onClose}
          onSave={onSave}
          programLabel={programLabel}
          promoCodeSuffix={promo.codeSuffix || ''}
          promoCodePrefix={promo.codePrefix || ''}
          visible
          isWorkflow
          workItems={workItems}
          isNewFlow={offer.isNewFlow}
          payoutType={promo.payoutType}
          flatPayout={promo.flatPayout}
          percentPayout={promo.percentPayout}
          startDate={promo.startDate}
          endDate={promo.endDate}
          payouts={offer.payouts}
          defaultPayoutId={promo.defaultPayoutId}
          migrateToGraphQL={migrateToGraphQL}
          isUngrouped={promo.isUngrouped}
        />
      );
    }
    case OFFER_TYPE.LINK: {
      const link = first(offer.links);
      const isOldOffer = link?.defaultPayoutId === null;
      if (!link) {
        logger.warn('Received link offer with no links!');
        return null;
      }
      return (
        <AddMembers
          offerSource={OFFER_SOURCE.TUNE}
          payoutType={link.payoutType}
          flatPayout={link.flatPayout}
          expirationDate={offer.expirationDate}
          percentPayout={link.percentPayout}
          offerLink={link.url}
          fetchMembers={fetchMembers}
          fetchPrograms={fakeFetchPrograms}
          offerId={offer.id}
          offerName={offer.name}
          onClose={onClose}
          onSave={onSave}
          programLabel={programLabel}
          visible
          isWorkflow
          workItems={workItems}
          payouts={offer.payouts}
          defaultPayoutId={link.defaultPayoutId}
          migrateToGraphQL={migrateToGraphQL}
          isNewFlow={!isOldOffer}
          domains={link.domains}
          isCreatorDeepLinkAllowed={link.creatorDeeplink}
        />
      );
    }
    default: {
      logger.warn('Invalid offer type');
      return null;
    }
  }
};
