import { useMemo } from 'react';
import {
 keyBy, split, replace, trim, size,
} from 'lodash';

import { MemberInput } from '@frontend/app/types/globalTypes';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';

interface IOptions {
  skip?: boolean;
}

export const useMemberNames = (member: MemberInput, options: IOptions = {}) => {
  const {
    data: {
      schemas = null,
    } = {},
  } = useMemberFieldSchemasQuery({
    skip: !!options.skip,
  });

  const schemasByName = useMemo(() => keyBy(schemas, 'name'), [schemas]);

  const firstNameSchema = schemasByName['First Name'];
  const lastNameSchema = schemasByName['Last Name'];

  const schemaFirstName = member.fields && firstNameSchema && member.fields[firstNameSchema.id];
  const schemaLastName = member.fields && lastNameSchema && member.fields[lastNameSchema.id];

  const [firstName, lastName] = useMemo<[string, string]>(() => {
    if (options.skip) {
      return ['', ''];
    }

    let firstName: string = '';
    let lastName: string = '';

    if (schemaFirstName) {
      firstName = schemaFirstName;
    }

    if (schemaLastName) {
      lastName = schemaLastName;
    }

    if (firstName && !lastName && firstName !== member.name) {
      const potentialLastName = replace(member.name, firstName, '');

      if (size(potentialLastName) < size(member.name)) {
        lastName = trim(potentialLastName);
      }
    }

    if (lastName && !firstName && lastName !== member.name) {
      const potentialFirstName = replace(member.name, lastName, '');

      if (size(potentialFirstName) < size(member.name)) {
        firstName = trim(potentialFirstName);
      }
    }

    if (!firstName && !lastName) {
      const result = split(member.name, /\s+/);
      return [result[0] || '', result[1] || ''];
    } else {
      return [firstName, lastName];
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member.name, schemaFirstName, schemaLastName]);

  return {
    values: {
      firstName,
      lastName,
    },
    schemas: {
      firstNameSchema,
      lastNameSchema,
    },
  };
};
