import * as React from 'react';
import { OFFER_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { logger } from '@common';

const { useMemo } = React;

export const useText = (offerSource: OFFER_TYPE) => useMemo(() => {
    switch (offerSource) {
      case OFFER_TYPE.PROMO_CODE: {
        return {
          title: 'You need to set up a Promo Code Offer.',
          paragraph: 'An offer includes the discount criteria and settings you would like to use for all the creators you give these promo codes to.',
        };
      }
      case OFFER_TYPE.LINK: {
        return {
          title: 'You need to set up a Link Tracking Offer.',
          paragraph: 'An offer includes the discount criteria and settings you would like to use for all the creators you give these links to.',
        };
      }
      default: {
        logger.warn('Invalid offer type used on useText hook!');
        return {
          title: '',
          paragraph: '',
        };
      }
    }
  }, [offerSource]);
