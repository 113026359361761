import { MutationHookOptions, useMutation } from '@apollo/client';
import { KILL_INPROGRESS_IMPORT_JOB_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { KillInProgressJobByCollectionId, KillInProgressJobByCollectionIdVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/KillInProgressJobByCollectionId';

type IOptions = MutationHookOptions<KillInProgressJobByCollectionId, KillInProgressJobByCollectionIdVariables>;

export const useKillInProgressJobMutation = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<KillInProgressJobByCollectionId, KillInProgressJobByCollectionIdVariables>(
    KILL_INPROGRESS_IMPORT_JOB_MUTATION,
    options,
  );

  return {
    mutate,
    loading,
  };
};
