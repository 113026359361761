/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1h8wd_157 {
  display: flex;
}

._justify-content-space-between_1h8wd_161 {
  justify-content: space-between;
}

._tabular-nums_1h8wd_165, ._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._info_1h8wd_165 ._value_1h8wd_165, ._PieChart_1h8wd_165 ._labelHorizontal_1h8wd_165 ._labelItem_1h8wd_165 ._value_1h8wd_165 {
  font-variant-numeric: tabular-nums;
}

._PieChart_1h8wd_165 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1h8wd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._PieChart_1h8wd_165 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._PieChart_1h8wd_165 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._PieChart_1h8wd_165 ::-webkit-scrollbar-track, ._PieChart_1h8wd_165 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._PieChart_1h8wd_165 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._info_1h8wd_165 ._value_1h8wd_165, ._PieChart_1h8wd_165 ._graph_1h8wd_204 ._text_1h8wd_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._info_1h8wd_165 ._label_1h8wd_165 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1h8wd_157 {
  display: flex;
}

._justify-content-space-between_1h8wd_161 {
  justify-content: space-between;
}

._tabular-nums_1h8wd_165, ._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._info_1h8wd_165 ._value_1h8wd_165, ._PieChart_1h8wd_165 ._labelHorizontal_1h8wd_165 ._labelItem_1h8wd_165 ._value_1h8wd_165 {
  font-variant-numeric: tabular-nums;
}

._PieChart_1h8wd_165 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1h8wd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._PieChart_1h8wd_165 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fdfdfd;
}
._PieChart_1h8wd_165._vertical_1h8wd_364 {
  align-items: center;
  flex-direction: column;
}
._PieChart_1h8wd_165 ._graph_1h8wd_204 ._text_1h8wd_204 {
  color: #2e426d;
}
._PieChart_1h8wd_165 ._labelHorizontal_1h8wd_165 {
  height: 100%;
  margin-top: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
._PieChart_1h8wd_165 ._labelHorizontal_1h8wd_165 ._labelItem_1h8wd_165 {
  margin-bottom: 24px;
  width: 225px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  line-height: 11px;
}
._PieChart_1h8wd_165 ._labelHorizontal_1h8wd_165 ._labelItem_1h8wd_165 ._dot_1h8wd_389 {
  margin-right: 8px;
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border-radius: 100px;
}
._PieChart_1h8wd_165 ._labelHorizontal_1h8wd_165 ._labelItem_1h8wd_165 ._label_1h8wd_165 {
  text-transform: uppercase;
  letter-spacing: 1px;
  min-width: 120px;
  color: #4f4f4f;
}
._PieChart_1h8wd_165 ._labelHorizontal_1h8wd_165 ._labelItem_1h8wd_165 ._value_1h8wd_165 {
  margin-left: auto;
  font-weight: 500;
}
._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 {
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._dot_1h8wd_389 {
  margin-right: 8px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 100px;
}
._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._info_1h8wd_165 {
  display: flex;
  flex-direction: column;
}
._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._info_1h8wd_165 ._value_1h8wd_165 {
  color: #2e426d;
}
._PieChart_1h8wd_165 ._labelVertical_1h8wd_165 ._labelItem_1h8wd_165 ._info_1h8wd_165 ._label_1h8wd_165 {
  text-transform: uppercase;
  color: #4f4f4f;
}