import * as React from 'react';
import { Button } from '@revfluence/fresh';

interface IConnectMetaAccountProps {
  onClick: () => void;
  className?: string;
}

export const ConnectMetaAccount: React.FC<IConnectMetaAccountProps> = ({ onClick, className }) => (
  <Button onClick={onClick} type="primary" className={className}>Add Meta Account</Button>
);
