import * as React from 'react';
import {
  Alert,
  Col, Popover, Radio, Row, Space, Typography,
} from '@revfluence/fresh';
import { LockIcon } from '@revfluence/fresh-icons/solid/esm';
import cx from 'classnames';
import { OFFER_FORM_LOCKING_KEY } from '@frontend/applications/AffiliatesApp/types';
import { OFFER_STATUS } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import styles from '../../../OfferForm.scss';
import { OfferActiveDate, OfferEndDateCheckbox } from '../FormElements';
import { FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions } from '../../../types';

const groupedPromoIcon = require('@frontend/app/assets/images/groupedPromo.png');
const ungroupedPromoIcon = require('@frontend/app/assets/images/ungroupedPromo.png');

const { Text } = Typography;
interface IProps {
  values: IShopifyPromoCodeFormValues,
  offerEndDate: boolean,
  dateErrorMsg: string,
  endDate: JSX.Element,
  setOfferEndDate: React.Dispatch<React.SetStateAction<boolean>>,
  handleFormAsyncActions?: (value: FormAsyncAction) => void,
}

export const NewOfferActiveDate: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    values, offerEndDate, dateErrorMsg, endDate, setOfferEndDate, handleFormAsyncActions,
  } = props;
  const lockEditing = [OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_CLIENTS_FOR_SYNC, OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_MULTIPLE_SHOPIFY_ENABLED, OFFER_FORM_LOCKING_KEY.IS_UNGROUPED_IS_SAME_DISCOUNT_MS];
  return (
    <>
      <Row>
        <Text>Manage the active and end dates for this offer. You can either set the same dates for all the codes in this offer or customize the dates for individual codes.</Text>
      </Row>
      {
        lockEditing.some((item) => values.lockEditing.includes(item)) && (
          <Row className={styles.marginOnAlert}>
            <Alert
              icon={<LockIcon scale={1.5} />}
              description={values.isUngrouped ? "Date settings are currently locked to 'Customize Dates' while offer sync preferences for Shopify stores are being edited. To modify the date settings to 'Unified Date', please update or cancel the changes to the offer sync option." : "Date settings are currently locked to 'Unified Date' while offer sync preferences for Shopify stores are being edited. To modify date settings to ‘Individual Dates’ for each code, please update or cancel changes to the offer sync options."}
              type="warning"
            />
          </Row>
        )
      }
      <Row
        className={cx(styles.radioCustomContainer, { [styles.disableRadio]: lockEditing.some((item) => values.lockEditing.includes(item)) || values.status === OFFER_STATUS.PAUSED })}
      >

        <Radio.Group disabled={lockEditing.some((item) => values.lockEditing.includes(item)) || values.status === OFFER_STATUS.PAUSED} className="radioCustom" defaultValue={values.isUngrouped ? 'UnGrouped' : 'Grouped'} onChange={(e) => handleFormAsyncActions({ action: OfferFormAsyncActions.UPDATE_FIELD, payload: { key: 'isUngrouped', value: e.target.value === 'UnGrouped' } })}>
          <Popover content="You can have a common active and end date for all the promo codes in this offer." overlayStyle={{ maxWidth: '200px' }}>
            <Radio value="Grouped">
              <Space className={styles.radioItem} direction="vertical">
                <img src={groupedPromoIcon} className={styles.image} />
                <Text className={styles.description}>Set unified dates for all codes</Text>
              </Space>
            </Radio>
          </Popover>
          <Popover content="You can have different active and end date for all the promo codes in this offer." overlayStyle={{ maxWidth: '200px' }}>
            <Radio value="UnGrouped">
              <Space className={styles.radioItem} direction="vertical">
                <img src={ungroupedPromoIcon} className={styles.image} />
                <Text className={styles.description}>Customize dates for each code</Text>
              </Space>
            </Radio>
          </Popover>
        </Radio.Group>
      </Row>
      {
        values.isUngrouped && (
          <Alert
            className={styles.alertContainer}
            description="Each promo code within this offer will be assigned to individual discount groups in Shopify. You will be able to add default active and end dates."
            type="info"
          />
        )
      }
      <div className={styles.CustomTextColor}>
        <Text>Active Date</Text>
      </div>
      <Row>
        <Col span={24}>
          <OfferActiveDate disabled={false} name="activeDate" endDate={values.endDate} />
        </Col>
      </Row>
      <OfferEndDateCheckbox setOfferEndDate={setOfferEndDate} checked={offerEndDate} name="isEndDateEnable" />
      {endDate}
      <span className={styles.errorMessage}>
        {dateErrorMsg}
      </span>

    </>
  );
});
NewOfferActiveDate.displayName = 'NewOfferActiveDate';
