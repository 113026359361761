import gql from 'graphql-tag';

export const GET_OFFER_AFFILIATES_STATS_MULTIPLE_SHOPIFY = gql`
  query GetOfferAffliatesStatsMultipleShopify($endDate: DateTime,$offerId: Int!, $startDate: DateTime) {
    affiliatesStats: offerAffliatesStatsMultipleShopify(endDate: $endDate,offerId: $offerId,startDate: $startDate) {
        affiliateId
        storeStats{
            avgSaleBase
            avgSale
            conversions
            payoutEarnedBase
            payoutEarned
            salesBase
            sales
            currency
            storeName
            clientShopifyConnectionId
        }
    }
  }
`;
