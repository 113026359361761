._FilterMenuPopover_xmdy3_1 {
  width: 280px;
}
._FilterMenuPopover_xmdy3_1 ._select_xmdy3_4 {
  width: 100%;
  margin-bottom: var(--spacing-sm);
}
._FilterMenuPopover_xmdy3_1 ._select_xmdy3_4:hover, ._FilterMenuPopover_xmdy3_1 ._select_xmdy3_4:focus {
  border-color: var(--primary-10);
}
._FilterMenuPopover_xmdy3_1 ._select_xmdy3_4:hover .ant-select-selection-item, ._FilterMenuPopover_xmdy3_1 ._select_xmdy3_4:focus .ant-select-selection-item {
  color: var(--primary-40) !important;
}
._FilterMenuPopover_xmdy3_1 ._select_xmdy3_4:hover .ant-select-arrow, ._FilterMenuPopover_xmdy3_1 ._select_xmdy3_4:focus .ant-select-arrow {
  color: var(--primary-40) !important;
}

._searchInput_xmdy3_18 {
  margin-bottom: var(--spacing-sm);
}

._list_xmdy3_22 .ant-list-header {
  padding-top: 8px;
}
._list_xmdy3_22 .ant-list-items {
  overflow: auto;
  max-height: 320px;
}
._list_xmdy3_22 .ant-list-item {
  display: block;
  width: 100%;
  padding: 0;
  border-bottom: 0;
  cursor: pointer;
  padding: var(--spacing-xxs) var(--spacing-xs);
}
._list_xmdy3_22 .ant-list-item:hover {
  background-color: var(--primary-10);
}
._list_xmdy3_22 .ant-list-item:hover span {
  color: var(--primary-40) !important;
}

._item_xmdy3_44 {
  word-break: break-word;
}

._source_xmdy3_48 {
  color: var(--grey-50);
}

._highlight_xmdy3_52 {
  border-bottom: 1px solid var(--grey-50);
}

._selectItem_xmdy3_56:hover {
  background-color: var(--primary-10);
  color: var(--primary-40);
}