import React from 'react';
import { BackLink } from '@frontend/app/components';
import { useLocation } from 'react-router-dom';
import { Typography, Row } from '@revfluence/fresh';

const { Title } = Typography;
import styles from './PageSkeleton.scss';

interface PageHeaderProps {
  title: string;
  renderIcon?: () => React.ReactNode;
  backRedirectLink?: string;
  children?: React.ReactNode;
  getHeaderRightSection?: () => React.ReactNode;
}

const PageSkeleton: React.FC<PageHeaderProps> = ({
 title, renderIcon, backRedirectLink, children, getHeaderRightSection,
}) => {
  const location = useLocation();

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row gutter={0} style={{ height: '100%', width: '100%' }}>
        <div className={styles.header}>
          <div className={styles.headerLeftSection}>
            {backRedirectLink ? <BackLink location={location} path={backRedirectLink} iconWrapperClass={styles.backLink} /> : null}
            <div className={styles.logoTitle}>
              {renderIcon?.()}
              <Title level={4} style={{ margin: 0 }}>
                {title}
              </Title>
            </div>
          </div>
          {getHeaderRightSection && getHeaderRightSection()}
        </div>

        {children}
      </Row>
    </div>
  );
};

export default PageSkeleton;
