import * as React from 'react';
import cx from 'classnames';

import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import styles from './TextInput.scss';

export interface IInputProps extends InputProps { }

export const TextInput: React.FC<IInputProps> = (props) => (
  <Input {...props} className={cx(styles.TextInput, props.className)} />
  );
