import React from 'react';
import { CircleInfoIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { isNull } from 'lodash';
import { useGetAutomationStatsQuery } from '@frontend/app/hooks';
import styles from './AutomationState.scss';
import AutomationStatCard from '../../AutomationSummary/AutomationStatCard';
import { formatAutomationDate } from '../utils';
import AutomationDate from './AutomationDate';

type TProps = {
  automationId: string;
  createdDate: Date;
  setState: (state: boolean) => void;
  updatedDate: Date;
};

const AutomationState = (props: TProps) => {
  const {
    automationId,
    createdDate,
    setState,
    updatedDate,
  } = props;

  const onCloseState = () => {
    setState(false);
  };

  const {
    data: {
      getAutomationStats: {
        lastExecutionDate,
        executions: {
          allTime: {
            total: totalExecutions,
          },
          pastWeek: {
            total: pastWeekExecutions,
          },
        },
      },
    } = {
      getAutomationStats: {
        lastExecutionDate: new Date(),
        executions: {
          allTime: {
            total: 0,
          },
          pastWeek: {
            total: 0,
          },
        },
      },
    },
  } = useGetAutomationStatsQuery({
    variables: {
      automationId,
    },
  });

  return (
    <div className={styles.AutomationState}>
      <div className={styles.stateHeaderContainer}>
        <div className={styles.stateHeader}>
          <CircleInfoIcon />
          <div className={styles.title}>
            Details
          </div>
        </div>
        <XmarkIcon onClick={onCloseState} />
      </div>
      <div className={styles.statCards}>
        <AutomationStatCard
          stat={totalExecutions}
          title="Total executed"
        />
        <AutomationStatCard
          stat={pastWeekExecutions}
          title="Triggered this week"
        />
      </div>
      <AutomationDate
        title="Created:"
        date={formatAutomationDate(createdDate)}
      />
      <AutomationDate
        title="Last updated:"
        date={formatAutomationDate(updatedDate)}
      />
      {!isNull(lastExecutionDate) && (
        <AutomationDate
          title="Last run:"
          date={formatAutomationDate(lastExecutionDate)}
        />
      )}
    </div>
  );
};

export default AutomationState;
