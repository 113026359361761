import { useCallback } from 'react';
import {
 isEmpty, keyBy, map, filter, size,
} from 'lodash';

import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';

interface Props {
  selectedMembers: IMember[];
  members: IMember[];
  updateSelectedMembers: (members: IMember[]) => void;
}

export const useRefreshSelectedMembers = (props: Props): { refreshSelectedMembers: () => void } => {
  const { selectedMembers, members, updateSelectedMembers } = props;
  const refreshSelectedMembers = useCallback(() => {
    if (isEmpty(selectedMembers) || isEmpty(members)) return;

    const existingSelectedIds = new Set(map(selectedMembers, 'id'));
    const updatedMembers = filter(members, (member) => existingSelectedIds.has(member.id));
    const updatedMembersById = keyBy(updatedMembers, 'id');
    for (const selectedMember of selectedMembers) {
      const updatedMember = updatedMembersById[selectedMember.id];
      if (updatedMember) {
        if (size(updatedMember.talentAgents) !== size(selectedMember.talentAgents)) {
          // talent agents have changed (either added or removed)
          updateSelectedMembers(updatedMembers);
          return;
        }

        const updatedTalentAgents = keyBy(updatedMember.talentAgents, 'agentEmail');
        for (const talentAgent of selectedMember.talentAgents) {
          const updatedAgent = updatedTalentAgents[talentAgent.agentEmail];
          if (updatedAgent && updatedAgent.alwaysCC !== talentAgent.alwaysCC) {
            // alwaysCC value has changed
            updateSelectedMembers(updatedMembers);
            return;
          }
        }
      }
    }
  }, [selectedMembers, members, updateSelectedMembers]);

  return { refreshSelectedMembers };
};
