import * as React from 'react';

import { IBulkActionParameters, IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';

const { useContext, createContext } = React;
interface IAppProviderProps {
  backendServerApiEndpoint: string;
  analyticsServerApiEndpoint: string;
  backendServerWebEndpoint: string;
  clientId: string;
  clientName: string;
  userId?: string;
  memberId?: string;
  bulkActionParameters?: IBulkActionParameters;
  workflowActionParameters?: IWorkflowActionParameters;
  closeModal?(): void;
}

export interface IArtifact {
  client_id: string;
  community_ids: number[];
  activation_ids: number[];
  program_ids: number[];
  activation_names: string[];
  community_names: string[];
  program_names: string[];
  assigned: boolean;
  requirements_assigned: boolean;
}

export const ApplicationContext = createContext<IAppProviderProps>(null);
export const useApplication = () => useContext(ApplicationContext);

export const ApplicationProvider: React.FunctionComponent<IAppProviderProps> = (props) => (
  <ApplicationContext.Provider
    value={{
        backendServerApiEndpoint: props.backendServerApiEndpoint,
        analyticsServerApiEndpoint: props.analyticsServerApiEndpoint,
        backendServerWebEndpoint: props.backendServerWebEndpoint,
        clientId: props.clientId,
        clientName: props.clientName,
        userId: props.userId,
        memberId: props.memberId,
        bulkActionParameters: props.bulkActionParameters,
        workflowActionParameters: props.workflowActionParameters,
        closeModal: props.closeModal,
      }}
  >
    {props.children}
  </ApplicationContext.Provider>
  );
