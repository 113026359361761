import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { map, mapValues, omitBy } from 'lodash';

import {
  LoadSpinner,
  Notice,
  Tooltip,
} from '@components';

import { useAnalyze } from '../../../useAnalyze';

const { createRef, useMemo } = React;
import styles from './TotalsSection.scss';

type TDataKey =
  | 'creator_total'
  | 'member_total'
  | 'post_total'
  | 'impression_total'
  | 'reach_total'
  | 'engagement_total'
  | 'tmv_total'
  | 'favorite_total'
  | 'roi_pct'
  | 'cost_per_engagement'
  | 'impression_cpm';
export type ITotalsData = {
  [key in TDataKey]: number;
};

type TDataFieldConfig = {
  [key in TDataKey]: {
    name: string;
    isDollar?: boolean;
    isPercentage?: boolean;
    showDecimals?: boolean;
    tooltip?: string;
    isCostMetric?: boolean;
  }
};
const DATA_FIELD_CONFIG: TDataFieldConfig = {
  creator_total: {
    name: 'Creators',
  },
  member_total: {
    name: 'Members',
  },
  post_total: {
    name: 'Posts',
  },
  impression_total: {
    name: 'Impressions',
  },
  favorite_total: {
    name: 'Saves',
  },
  reach_total: {
    name: 'Pot. Reach',
    tooltip: 'Total combined followers for creators of posts at the time of posting.',
  },
  engagement_total: {
    name: 'Engagements',
  },
  tmv_total: {
    name: 'TMV',
    isDollar: true,
    tooltip:
      'Total Media Value (TMV) estimates what an equivalent advertising campaign would cost for the engagement output',
  },
  roi_pct: {
    name: 'TMV ROI',
    isPercentage: true,
    tooltip: 'Return is calculated by total media value divided by cost ',
    isCostMetric: true,
  },
  cost_per_engagement: {
    name: 'CPE',
    isDollar: true,
    showDecimals: true,
    tooltip: 'Cost Per Engagement (CPE) is calculated as total cost divided by number of engagements.',
    isCostMetric: true,
  },
  impression_cpm: {
    name: 'Impression CPM',
    isDollar: true,
    showDecimals: true,
    tooltip: 'Impression Cost-Per-Mille (CPM) is calculated as total cost per 1000 impressions.',
    isCostMetric: true,
  },
};
interface IProps {
  className?: string;
  data: { loading: boolean, data?: ITotalsData, error: Error | null };
  dataCompare: { loading: boolean, data?: ITotalsData, error: Error | null };
  isComparing: boolean;
  tempYoutubeDemoAccountMode?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const TotalsSection: React.FunctionComponent<IProps> = React.memo(
  (props) => {
    const { setFilters, showCostMetrics, } = useAnalyze();
    let filteredConfig: TDataFieldConfig = DATA_FIELD_CONFIG;
    if (props.tempYoutubeDemoAccountMode) {
      filteredConfig['reach_total']['tooltip'] = 'Total combined subscribers for creators of posts at the time of posting.';
    }
    if (!showCostMetrics) {
      filteredConfig = omitBy(filteredConfig, (o) => o.isCostMetric) as TDataFieldConfig;
    }

    const { loading, data, error } = props.data;
    const { loading: loadingCompare, data: dataCompare, } = props.dataCompare;

    const refs = useMemo(
      () => mapValues(filteredConfig, () => createRef<HTMLDivElement>()),
      [filteredConfig],
    );

    React.useEffect(() => {
      if (!data) {
        return;
      }
      if (data.creator_total === 0 && data.post_total === 0) {
        return setFilters({ type: 'NO_DATA_RETURNED' });
      }
      setFilters({ type: 'DATA_RETURNED' });
    }, [data, setFilters]);

    if (error) {
      return (
        <Notice className={(styles as any).notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    return (
      <div className={cx(styles.TotalsSection, props.className)}>
         {loading ? <LoadSpinner centered /> : (
          <div className={styles.totalsRow}>
            {map(data, (value, key) => {
              const config = filteredConfig[key];
              const numberFormat = `${config?.isDollar ? '$' : ''}0,0${config?.showDecimals ? '.00' : ''}`;

              return config && (
                <div
                  ref={refs[key]}
                  key={key}
                  className={cx(styles.item, {
                    [styles.withTooltip]: config.tooltip,
                  })}
                >
                  {value && numeral(value).value() > 0 ? (
                    <div className={styles.count}>
                      {numeral(value).format(numberFormat)}
                      {config.isPercentage && '%'}
                    </div>
                  ) : (
                    <div className={styles.count}>-</div>
                  )}
                  {props.isComparing
                    && (loadingCompare ? (
                      <LoadSpinner centered />
                    ) : dataCompare && numeral(dataCompare[key]).value() > 0 ? (
                      <div className={cx(styles.count, styles.compare)}>
                        {numeral(dataCompare[key]).format(numberFormat)}
                        {config.isPercentage && '%'}
                      </div>
                    ) : (
                      <div className={cx(styles.count, styles.compare)}>-</div>
                    ))
                  }
                  <div className={styles.type}>{config.name}</div>
                  {config.tooltip && (
                    <Tooltip
                      mountRef={refs[key]}
                      placement="bottom"
                      tooltipColor="black"
                    >
                      <div className="tooltip_bottomLeft tooltip">
                        <span className="tooltip_txt">{config.tooltip}</span>
                      </div>
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  },
);

TotalsSection.defaultProps = {
  className: null,
};
