import { useCallback, useState } from 'react';

import { OfferFormToolTips } from '@affiliates/components';

export const useOfferFormTooltip = () => {
  const [hoveredTooltip, setHoveredTooltip] = useState<OfferFormToolTips>(null);
  const [selectedTooltip, setSelectedTooltip] = useState<OfferFormToolTips>(null);

  const changeToolTip = useCallback((tooltipInfo: OfferFormToolTips, selected?: boolean) => {
    if (tooltipInfo && selected) {
      // new tooltip was selected
      setSelectedTooltip(tooltipInfo);
    } else if (!selected) {
      // new tooltip is hovered
      setHoveredTooltip(tooltipInfo);
    } else if (selected) {
      // tooltip is unselected (tooltipInfo is null)
      setSelectedTooltip(tooltipInfo);
    }
  }, [setHoveredTooltip, setSelectedTooltip]);

  return {
    changeToolTip,
    tooltipType: hoveredTooltip || selectedTooltip,
  };
};
