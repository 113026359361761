import gql from 'graphql-tag';

export const SEARCH_MEMBERS_QUERY = gql`
  query SearchMembersQuery($query: JSONObject!, $schemaIds: [Int!], $take: Int!, $skip: Int!) {
    members: membersForSearchQuery(query: $query, take: $take, skip: $skip) {
      id
      email
      name
      profilePicture
      fields(forSchemas: $schemaIds)
      talentAgents {
        memberId
        agentId
        alwaysCC
        agentName
        agentEmail
      }
    }
  }
`;
