import * as React from 'react';
import { Tooltip } from '@revfluence/fresh';

import { AccessStatus } from '../../hooks/useAccessData';
import { composeAdTypeField } from '../composeAdTypeField';

import styles from './StatusCell.scss';

const { useMemo } = React;

interface IStatusCellProps {
  status: AccessStatus;
  usageLimit: number
  adType: string;
}

export const StatusCell: React.FC<IStatusCellProps> = (props) => {
  const { adType, status, usageLimit } = props;
  const configPerStatus = useMemo(() => {
    switch (status) {
      case 'active':
        return {
          text: 'Active',
          tooltip: `Your ${composeAdTypeField(adType)} permissions are currently active for this creator.`,
          className: styles.active,
        };
      case 'disabled':
        return {
          text: 'Terms Expired',
          tooltip: `Your ${composeAdTypeField(adType)} permissions have expired for this creator.`,
          className: styles.disabled,
        };
      case 'auth_error':
        return {
          text: 'Creator Needs Reauthorization',
          tooltip: 'The creator\'s Instagram account is no longer authenticated with Aspire. Please ask the creator to reauthorize their ad access by going to their brief and selecting "Authorize My Instagram Insights".',
          className: styles.error,
        };
      case 'unknown_error':
        return {
          text: 'Connection Error',
          tooltip: 'There was an error determining the status of these permissions. This may require some investigation, so please contact support for further guidance.',
          className: styles.error,
        };
      case 'pending':
        return {
          text: 'Pending',
          tooltip: `Your ${composeAdTypeField(adType)} permissions have not begun yet for this creator.`,
          className: styles.pending,
        };
      case 'over_limit':
        return {
          text: 'Over Limit',
          tooltip: `You can only have ${usageLimit} active ${composeAdTypeField(adType)} permissions in a single month.`,
          className: styles.overLimit,
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, usageLimit]);
  return (
    <Tooltip title={configPerStatus.tooltip}>
      <span className={configPerStatus.className}>{configPerStatus.text}</span>
    </Tooltip>
  );
};
