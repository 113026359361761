/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_148pw_157 {
  display: flex;
}

._justify-content-space-between_148pw_161 {
  justify-content: space-between;
}

._tabular-nums_148pw_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_148pw_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._paymentsOverviewContainer_148pw_178 ._space48_148pw_178 {
  height: 3rem;
}

._spinnerContainer_148pw_182 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6.25rem;
}