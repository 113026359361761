/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ppy5v_157 {
  display: flex;
}

._justify-content-space-between_ppy5v_161 {
  justify-content: space-between;
}

._tabular-nums_ppy5v_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ppy5v_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CropModal_ppy5v_178 ._btns_ppy5v_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}
._CropModal_ppy5v_178 ._btns_ppy5v_178 > *:first-child {
  margin-right: 0.75rem;
}