/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1y650_157 {
  display: flex;
}

._justify-content-space-between_1y650_161 {
  justify-content: space-between;
}

._tabular-nums_1y650_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1y650_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ApplicationHeader_1y650_178 ._headerContentWrapper_1y650_178 ._title_1y650_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ApplicationHeader_1y650_178 {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
._ApplicationHeader_1y650_178 ._headerContentWrapper_1y650_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ApplicationHeader_1y650_178 ._headerContentWrapper_1y650_178 ._icon_1y650_232 {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.375rem;
  font-size: 1.5rem;
}
._ApplicationHeader_1y650_178 ._headerContentWrapper_1y650_178 ._title_1y650_178 {
  margin-left: 0.375rem;
}