import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const CommunityIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path d="M12.0199 12.507C13.7365 12.507 15.1337 11.0299 15.1337 9.23353C15.1337 7.43713 13.7365 6 12.0199 6C10.3033 6 8.90613 7.47705 8.90613 9.27345C8.90613 11.0699 10.3033 12.507 12.0199 12.507ZM12.0199 7.11776C13.1377 7.11776 14.0159 8.07585 14.0159 9.27345C14.0159 10.4711 13.1377 11.4291 12.0199 11.4291C10.9021 11.4291 10.0239 10.4711 10.0239 9.27345C10.0239 8.07585 10.9021 7.11776 12.0199 7.11776Z" />
    <path d="M14.0959 13.3859C13.8164 13.2662 13.4971 13.346 13.3773 13.6254C13.2575 13.9049 13.3374 14.2242 13.6168 14.344C14.6548 14.863 15.3334 15.9009 15.4931 17.2981H8.50705C8.66673 15.9807 9.30545 14.9428 10.3434 14.4238C10.6228 14.2642 10.7027 13.9448 10.5829 13.6654C10.4232 13.3859 10.1039 13.3061 9.82441 13.4258C8.26753 14.2242 7.38928 15.861 7.38928 17.8171C7.38928 18.1364 7.6288 18.3759 7.94816 18.3759H16.0919C16.2516 18.3759 16.3713 18.2961 16.4911 18.2163C16.6108 18.0965 16.6508 17.9767 16.6508 17.8171C16.531 15.7013 15.6528 14.1444 14.0959 13.3859Z" />
    <path d="M18.8862 13.2656C18.008 13.2656 17.4491 13.4652 17.2495 13.8644C17.1297 14.1439 17.1697 14.4632 17.3693 14.7028C17.6088 15.0221 18.1677 16.06 18.1677 16.4592C18.1677 16.6189 18.2076 16.7387 18.3273 16.8584C18.4471 16.9782 18.5669 17.0181 18.7265 17.0181C18.7265 17.0181 20.6427 17.0181 21.4411 17.0181C21.6008 17.0181 21.7206 16.9782 21.8403 16.8584C21.9601 16.7387 22 16.6189 22 16.4592C21.9601 14.5431 20.7225 13.2656 18.8862 13.2656ZM19.2056 15.9403C19.0459 15.4213 18.7665 14.7826 18.487 14.3834C18.6068 14.3834 18.7265 14.3435 18.8862 14.3435C19.9241 14.3435 20.6028 14.9024 20.8423 15.9004C20.2834 15.9403 19.6447 15.9403 19.2056 15.9403Z" />
    <path d="M18.8863 12.7064C20.2037 12.7064 21.2416 11.6286 21.2416 10.3112C21.2416 8.99386 20.1637 7.91602 18.8863 7.91602C17.6088 7.91602 16.531 8.99386 16.531 10.3112C16.531 11.6286 17.6088 12.7064 18.8863 12.7064ZM18.8863 9.03378C19.6049 9.03378 20.1637 9.59266 20.1637 10.3112C20.1637 11.0298 19.6049 11.5887 18.8863 11.5887C18.1677 11.5887 17.6088 11.0298 17.6088 10.3112C17.6088 9.59266 18.2077 9.03378 18.8863 9.03378Z" />
    <path d="M5.11377 13.2656C3.31737 13.2656 2.07984 14.5032 2 16.4592C2 16.6189 2.03992 16.7387 2.15968 16.8584C2.27944 16.9782 2.43912 17.0181 2.55888 17.0181C3.35729 17.0181 5.27345 17.0181 5.27345 17.0181C5.43313 17.0181 5.55289 16.9782 5.67265 16.8584C5.79242 16.7387 5.83234 16.6189 5.83234 16.4592C5.83234 16.06 6.3513 15.0221 6.63074 14.7028C6.83034 14.4233 6.87026 14.1439 6.7505 13.8644C6.5509 13.5051 6.03194 13.2656 5.11377 13.2656ZM4.83433 15.9403C4.51497 15.9403 4.07585 15.9403 3.63673 15.9403C3.47705 15.9403 3.31737 15.9403 3.1976 15.9403C3.39721 14.9423 4.11577 14.3834 5.11377 14.3834C5.27345 14.3834 5.39321 14.3834 5.51297 14.4233C5.27345 14.8225 4.99401 15.4213 4.83433 15.9403Z" />
    <path d="M5.11383 12.7064C6.4312 12.7064 7.46912 11.6286 7.46912 10.3112C7.46912 8.99386 6.39128 7.91602 5.11383 7.91602C3.83639 7.91602 2.75854 8.99386 2.75854 10.3112C2.75854 11.6286 3.83639 12.7064 5.11383 12.7064ZM5.11383 9.03378C5.8324 9.03378 6.39128 9.59266 6.39128 10.3112C6.39128 11.0298 5.8324 11.5887 5.11383 11.5887C4.39527 11.5887 3.83639 11.0298 3.83639 10.3112C3.83639 9.59266 4.43519 9.03378 5.11383 9.03378Z" />
  </SvgIcon>
));

CommunityIcon.displayName = 'CommunityIcon';
