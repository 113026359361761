import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const WandIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <mask id="WandIcon-mask" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="21">
      <path d="M13.2086 9.65603C12.9046 9.35207 12.5247 9.20009 12.1067 9.20009C11.6888 9.20009 11.3088 9.35207 11.0049 9.65603L2.45594 18.2429C2.15198 18.5469 2 18.9269 2 19.3448C2 19.7628 2.15198 20.1427 2.45594 20.4467L3.78577 21.7765C4.05174 22.0045 4.43169 22.1565 4.84964 22.1565C5.26759 22.1565 5.64754 22.0045 5.9515 21.7005L14.5384 13.1516C15.1463 12.5437 15.1463 11.5938 14.5384 10.9859L13.2086 9.65603ZM5.11561 20.9026C4.96363 21.0546 4.69766 21.0546 4.54568 20.9026L3.21585 19.5728C3.17785 19.5348 3.13986 19.4208 3.13986 19.3448C3.13986 19.2308 3.17785 19.1548 3.25384 19.0788L9.78902 12.5437L11.6508 14.4054L5.11561 20.9026ZM13.7025 12.3157L12.4487 13.5695L10.5869 11.7078L11.8408 10.4539C11.9167 10.3779 12.0307 10.3399 12.1067 10.3399C12.2207 10.3399 12.2967 10.3779 12.3727 10.4539L13.7025 11.7838C13.8545 11.9357 13.8545 12.1637 13.7025 12.3157Z" />
      <path d="M17.73 8.82014L18.4139 10.4159C18.5279 10.6439 18.8318 10.6439 18.9078 10.4159L19.5917 8.82014C19.6297 8.74415 19.6677 8.70615 19.7437 8.66816L21.3395 7.98424C21.5675 7.87026 21.5675 7.5663 21.3395 7.49031L19.7437 6.80639C19.6677 6.7684 19.6297 6.7304 19.5917 6.65441L18.9078 5.05861C18.7938 4.83064 18.4899 4.83064 18.4139 5.05861L17.73 6.65441C17.692 6.7304 17.654 6.7684 17.578 6.80639L15.9822 7.49031C15.7542 7.60429 15.7542 7.90825 15.9822 7.98424L17.578 8.66816C17.654 8.70615 17.692 8.74415 17.73 8.82014Z" />
      <path d="M6.02748 5.1346L7.62328 5.81852C7.69927 5.85651 7.73727 5.89451 7.77526 5.9705L8.45918 7.5663C8.57316 7.79427 8.87712 7.79427 8.95311 7.5663L9.63703 5.9705C9.67502 5.89451 9.71302 5.85651 9.78901 5.81852L11.3848 5.1346C11.6128 5.02062 11.6128 4.71666 11.3848 4.64067L9.78901 3.91876C9.71302 3.88076 9.67502 3.84277 9.63703 3.76678L8.95311 2.17098C8.83913 1.94301 8.53517 1.94301 8.45918 2.17098L7.77526 3.76678C7.73727 3.84277 7.69927 3.91876 7.62328 3.91876L6.02748 4.60267C5.79951 4.71666 5.79951 5.02062 6.02748 5.1346Z" />
      <path d="M21.8335 16.8751L20.2377 16.2292C20.1617 16.1912 20.1237 16.1532 20.0857 16.0772L19.4018 14.4814C19.2878 14.2535 18.9839 14.2535 18.9079 14.4814L18.186 16.0772C18.148 16.1532 18.11 16.1912 18.034 16.2292L16.4382 16.8751C16.2102 16.9891 16.2102 17.2931 16.4382 17.3691L18.034 18.053C18.11 18.091 18.148 18.129 18.186 18.205L18.8699 19.8008C18.9839 20.0287 19.2878 20.0287 19.3638 19.8008L20.0477 18.205C20.0857 18.129 20.1237 18.091 20.1997 18.053L21.7955 17.3691C22.0615 17.2931 22.0615 16.9891 21.8335 16.8751Z" />
    </mask>
    <g mask="url(#WandIcon-mask)">
      <rect width="24" height="24" />
    </g>
  </SvgIcon>
));

WandIcon.displayName = 'WandIcon';
