import * as React from 'react';
import cx from 'classnames';
import { size, isEmpty } from 'lodash';
import { useTransition, animated } from 'react-spring';

import { useElementResize, useMedia } from '@frontend/utils';

const { useMemo, useRef } = React;
import styles from './ResponsiveGrid.scss';

const MAX_WIDTH = 3360;

interface IProps {
  children: React.ReactElement[];
  heights: number[];
  itemMinWidth: number;

  padding?: number | string;
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const ResponsiveGrid: React.FunctionComponent<IProps> = React.memo((props) => {
  const { children, itemMinWidth } = props;
  if (isEmpty(props.heights) || size(props.heights) !== size(children)) {
    throw new Error('heights.length needs to match children.length.');
  }

  const [queries, counts] = useMemo(() => {
    const queries = [`(min-width: ${itemMinWidth}px)`];
    const counts = [1];

    let count = 2;
    while (count * itemMinWidth < MAX_WIDTH) {
      queries.unshift(`(min-width: ${itemMinWidth * count}px)`);
      counts.unshift(count);

      count++;
    }

    return [queries, counts];
  }, [itemMinWidth]);
  const columns = useMedia(queries, counts, 1);
  const ref = useRef<HTMLDivElement>(null);
  const domRect = useElementResize(ref);
  const width = domRect && domRect.width || 0;
  const heights = new Array(columns).fill(0);
  const gridItems = props.heights.map((height, index) => {
    const column = heights.indexOf(Math.min(...heights));
    const xy = [(width / columns) * column, (heights[column] += height) - height];

    return {
      index,
      xy,
      width: width / columns,
      height,
    };
  });
  const transitions = useTransition(gridItems, (item) => item.index, {
    from: ({ xy, width, height }) => ({
 xy, width, height, opacity: 0,
}),
    enter: ({ xy, width, height }) => ({
 xy, width, height, opacity: 1,
}),
    update: ({ xy, width, height }) => ({ xy, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);

  return (
    <div
      ref={ref}
      className={cx(styles.ResponsiveGrid, props.className)}
      style={{
        height: Math.max(...heights),
      }}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {transitions.map(({ item, props: { xy, ...rest }, key }: any) => {
        const Component = children[item.index];

        return (
          <animated.div
            key={key}
            style={{
              transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`),
              padding: props.padding,
              ...rest,
            }}
            className={styles.itemWrapper}
          >
            {Component}
          </animated.div>
        );
      })}
    </div>
  );
});

ResponsiveGrid.displayName = 'ResponsiveGrid';
ResponsiveGrid.defaultProps = {
  padding: 0,
};
