import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { FormInstance } from 'antd/lib/form';
import {
  Button, Col, Dropdown, Form, Input, Menu, Modal, Row, Space,
} from '@revfluence/fresh';
import { EllipsisIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { OFFER_PAYOUT_TYPE, OFFER_PAYOUT_TYPE_PROMO, OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { sortBy } from 'lodash';
import {
 FormAsyncAction, IPayoutVariant, OfferFormAsyncActions, OfferFormModes,
} from '../../../types';
import {
  validateNonNegativeDecimal, validateNonNegativeInteger, validateSpecialCharacters, validateUniquePayout,
} from '../../../utils';
import styles from '../../../OfferForm.scss';

const { confirm } = Modal;
interface IProps {
  key: number;
  remove: (index: number | number[]) => void;
  name: number;
  source: OFFER_SOURCE;
  payoutTypeTune: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO;
  disabled: boolean;
  mode: OfferFormModes;
  payouts: IPayoutVariant[];
  formRef: FormInstance;
  payoutIndex: number;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  showCostPerClick: boolean;
  formattedConversionType: string;
}

export const MultiplePayoutOption: React.FC<Readonly<IProps>> = React.memo(({
  key, disabled, name, source, payoutTypeTune, mode, remove, payouts, formRef, payoutIndex, handleFormAsyncActions, showCostPerClick, formattedConversionType,
}) => {
  const rules = {
    decimalRules: [
      { required: true, message: 'Required' },
      { validator: validateNonNegativeDecimal },
    ],
    integerRules: [
      { required: true, message: 'Required' },
      { validator: validateNonNegativeInteger },
    ],
    payoutRule: [
      { required: true, message: 'Please enter the payout' },
      { validator: validateUniquePayout, fields: formRef.getFieldsValue(), message: 'Label must be unique' },
      { validator: validateSpecialCharacters },
    ],
  };
  const { isDefault, payoutType, isMemberAdded } = payouts[payoutIndex];

  const handleClickPayoutOption = useCallback(({ key }) => {
    switch (key) {
      case 'delete': {
        const payouts = formRef.getFieldValue('payoutOptions') || [];
        if (isMemberAdded) {
          confirm({
            title: 'Can not remove this payout value',
            icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
            content: source === OFFER_SOURCE.SHOPIFY ? 'This payout option cannot be removed because it has members with active promo codes. Please re-assign all existing members to a different payout option, then re-attempt to delete this payout option.' : 'This Payout option cannot be removed because it has members with active offers. Please re-assign all existing members to a different payout option and then re-attempt deleting this payout option.',
            onOk() {
            },
            cancelButtonProps: {
              style: {
                display: 'none',
              },
            },
            okText: 'Close',
          });
        } else if (payouts.length === 1 && isDefault) {
          confirm({
            title: 'Offer must have at least one payout option.',
            icon: <ExclamationCircleFilled />,
            content: 'You must create another payout option and make it the default for this offer before removing this payout option.',
            onOk() {
            },
            cancelButtonProps: {
              style: {
                display: 'none',
              },
            },
          });
        } else if (isDefault && mode === OfferFormModes.Create) {
          let selectedIndex = 0;
          if (payoutIndex === 0) selectedIndex = 1;
          const currentValues = formRef.getFieldValue('payoutOptions') || [];
          currentValues.forEach((element) => {
            element.isDefault = false;
          });
          currentValues[selectedIndex].isDefault = true;
          formRef.setFieldsValue({
            payoutOptions: currentValues,
          });
          handleFormAsyncActions({ action: OfferFormAsyncActions.DEFAULT_PAYOUT, payload: { index: selectedIndex } });
          remove(payoutIndex);
        } else if (isDefault && mode === OfferFormModes.Edit) {
          confirm({
            title: source === OFFER_SOURCE.SHOPIFY ? 'Are you sure you want to remove the default payout option for this offer?' : 'Are you sure you want to remove this default payout?',
            icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
            content: source === OFFER_SOURCE.SHOPIFY ? 'If you remove the default payout option, we will automatically select the most recently created payout option as the new default for this offer. You can always change the default option.' : 'The Payout option you are deleting is currently the default option. A new one will be selected automatically. You can change this by going to offer settings. Do you want to proceed to delete “Standard” payout option?”',
            okText: 'Confirm Remove',
            okButtonProps: {
              style: {
                background: 'white',
                borderColor: 'red',
                color: 'red',
              },
            },
            onOk() {
              const sortedArray: IPayoutVariant[] = sortBy(payouts, (obj) => new Date(obj.createdDate));
              let newIndex = payouts.findIndex((obj) => obj.id === sortedArray[0].id);
              if (newIndex === payoutIndex) {
                newIndex = payouts.findIndex((obj) => obj.id === sortedArray[1].id);
              }
              if (newIndex >= 0) {
                const currentValues = formRef.getFieldValue('payoutOptions') || [];
                currentValues.forEach((element) => {
                  element.isDefault = false;
                });
                currentValues[newIndex].isDefault = true;
                formRef.setFieldsValue({
                  payoutOptions: currentValues,
                });
                handleFormAsyncActions({ action: OfferFormAsyncActions.DEFAULT_PAYOUT, payload: { index: newIndex } });
              }
              remove(payoutIndex);
            },
          });
        } else if (!isMemberAdded && mode === OfferFormModes.Edit) {
          confirm({
            title: 'Are you sure you want to remove this payout option?',
            icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
            content: source === OFFER_SOURCE.SHOPIFY ? 'You will no longer be able to assign members to this payout option.' : 'This payout option will no longer be available to be assigned to members. ',
            okText: 'Confirm Remove',
            okButtonProps: {
              style: {
                background: 'white',
                borderColor: 'red',
                color: 'red',
              },
            },
            onOk() {
              remove(payoutIndex);
            },
            onCancel() {
            },
            cancelText: 'Cancel',
          });
        } else {
          remove(payoutIndex);
        }

        break;
      }
      case 'default': {
        const currentValues = formRef.getFieldValue('payoutOptions') || [];
        currentValues.forEach((element) => {
          element.isDefault = false;
        });
        currentValues[payoutIndex].isDefault = true;
        formRef.setFieldsValue({
          payoutOptions: currentValues,
        });
        handleFormAsyncActions({ action: OfferFormAsyncActions.DEFAULT_PAYOUT, payload: { index: payoutIndex } });
        break;
      }
      default: {
        break;
      }
    }
  }, [formRef, handleFormAsyncActions, isDefault, isMemberAdded, mode, payoutIndex, remove, source]);
  const payoutOptionMenu = useMemo(() => (
    <Menu onClick={handleClickPayoutOption}>
      <Menu.Item key="delete" style={{ color: 'red' }}>
        Remove
      </Menu.Item>
      {
        !isDefault && (
          <Menu.Item key="default">
            Make Default Payment
          </Menu.Item>
        )
      }
    </Menu>
  ), [isDefault, handleClickPayoutOption]);

  const customPayoutValue = (payoutType) => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return (
          <Col span={12} offset={1}>
            <Row>
              <Col span={11}>
                <Form.Item label="Value" name={[name, 'flatPayout']} rules={rules.decimalRules}>
                  <Input
                    disabled={disabled}
                    size="large"
                    type="number"
                    onKeyDown={UseNaturalNumbers}
                    prefix="$"
                    placeholder="0"
                  />
                </Form.Item>
              </Col>
              <Col span={2} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                +
              </Col>
              <Col span={11}>
                <Form.Item label="Value" name={[name, 'percentPayout']} rules={rules.integerRules}>
                  <Input
                    disabled={disabled}
                    size="large"
                    type="number"
                    onKeyDown={UseNaturalNumbers}
                    suffix="%"
                    placeholder="0"
                    max={100}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        );
      case OFFER_PAYOUT_TYPE.CLICK:
        return (
          <Col span={12} offset={1}>
            <Form.Item label="Value" name={[name, 'flatPayout']} rules={rules.decimalRules}>
              <Input
                disabled={disabled}
                size="large"
                type="number"
                prefix="$"
                onKeyDown={UseNaturalNumbers}
                placeholder="Enter Payout Value"
              />
            </Form.Item>
          </Col>
        );
      case OFFER_PAYOUT_TYPE.CONVERSION:
        return (
          <Col span={12} offset={1}>
            <Form.Item label="Value" name={[name, 'flatPayout']} rules={rules.decimalRules}>
              <Input
                disabled={disabled}
                size="large"
                type="number"
                prefix="$"
                onKeyDown={UseNaturalNumbers}
                placeholder="Enter Payout Value"
              />
            </Form.Item>
          </Col>
        );
      case OFFER_PAYOUT_TYPE.SALE:
        return (
          <Col span={12} offset={1}>
            <Form.Item label="Value" name={[name, 'percentPayout']} rules={rules.integerRules}>
              <Input
                disabled={disabled}
                size="large"
                type="number"
                suffix="%"
                onKeyDown={UseNaturalNumbers}
                placeholder="Enter Payout Value"
                max={100}
              />
            </Form.Item>
          </Col>
        );
      default:
        return null;
    }
  };

  const defaultLabel = () => {
    if (isDefault) {
      return (
        <>
          Label
          {' '}
          <span className={cx(styles.defaultPayout)}>
            Default
          </span>
        </>
      );
    } else return <>Label</>;
  };
  const handleClickPayoutType = ({ key }) => {
    const currentValues = formRef.getFieldValue('payoutOptions') || [];
    currentValues[payoutIndex].payoutType = key;
    formRef.setFieldsValue({
      payoutOptions: currentValues,
    });
    handleFormAsyncActions({ action: OfferFormAsyncActions.PAYOUT_TYPE, payload: { index: payoutIndex, payoutType: key } });
  };
  const payoutLabelConversion = (payoutType) => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.CLICK:
        return 'Cost per Click (CPC)';
      case OFFER_PAYOUT_TYPE.CONVERSION:
        return `Cost per ${formattedConversionType} (CPA)`;
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return `Cost per ${formattedConversionType} + Commission per Sale (CPA+CPS)`;
      case OFFER_PAYOUT_TYPE.SALE:
        return 'Commission per Sale (CPS)';
      case OFFER_PAYOUT_TYPE.THOUSAND_IMPRESSIONS:
        return 'Cost per Thousand Impression';
      default:
        '';
    }
  };
  const ActionMenu = (
    <Menu onClick={handleClickPayoutType}>
      {showCostPerClick && <Menu.Item key={OFFER_PAYOUT_TYPE.CLICK}>{payoutLabelConversion(OFFER_PAYOUT_TYPE.CLICK)}</Menu.Item>}
      <Menu.Item key={OFFER_PAYOUT_TYPE.CONVERSION}>{payoutLabelConversion(OFFER_PAYOUT_TYPE.CONVERSION)}</Menu.Item>
      <Menu.Item key={OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE}>{payoutLabelConversion(OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE)}</Menu.Item>
      <Menu.Item key={OFFER_PAYOUT_TYPE.SALE}>{payoutLabelConversion(OFFER_PAYOUT_TYPE.SALE)}</Menu.Item>
    </Menu>
  );
  return (
    <Row key={key} style={{ marginBottom: source === OFFER_SOURCE.TUNE ? '8px' : '28px' }}>
      <Col span={24}>
        <Row align="middle">
          <Col span={21}>
            <Row>
              <Col span={11}>
                <Form.Item label={defaultLabel()} name={[name, 'label']} rules={rules.payoutRule}>
                  <Input
                    disabled={disabled}
                    size="large"
                    type="text"
                    maxLength={60}
                    placeholder="Enter Payout Label"
                  />
                </Form.Item>
              </Col>
              {customPayoutValue(source === OFFER_SOURCE.TUNE ? payoutTypeTune : payoutType)}
            </Row>
          </Col>
          <Col span={2} offset={1}>
            <Row justify="start">
              <Dropdown
                overlay={payoutOptionMenu}
                trigger={['click']}
              >
                <Button icon={<EllipsisIcon />} style={{ marginTop: '8px' }} size="large" />
              </Dropdown>
            </Row>
          </Col>
        </Row>
        {
          source === OFFER_SOURCE.SHOPIFY && (
            <Row style={{ width: '100%', marginTop: '-8px' }}>
              <Col span={22}>
                <Row>
                  Type
                  <Dropdown overlay={ActionMenu} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()}>
                      <Space style={{ marginLeft: '10px' }}>
                        {payoutLabelConversion(payoutType)}
                      </Space>
                    </a>
                  </Dropdown>
                </Row>
              </Col>
            </Row>
          )
        }
      </Col>
    </Row>
  );
});
MultiplePayoutOption.displayName = 'MultiplePayoutOption';
