import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const LinkIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip-link)">
      <path id="Vector" d="M13.901 16.9412L11.7305 19.1117C10.8143 20.0279 9.59922 20.5277 8.30574 20.5277C7.01226 20.5277 5.79717 20.023 4.88095 19.1117C2.99462 17.2254 2.99462 14.1533 4.88095 12.267L6.87997 10.268C7.16904 9.97892 7.16904 9.51346 6.87997 9.22928C6.59089 8.94511 6.12544 8.94021 5.84126 9.22928L3.84224 11.2283C1.38266 13.6879 1.38266 17.6908 3.84224 20.1504C5.03284 21.341 6.62029 21.9975 8.30574 21.9975C9.99119 21.9975 11.5737 21.341 12.7692 20.1504L14.9397 17.9799C15.2288 17.6908 15.2288 17.2254 14.9397 16.9412C14.6507 16.657 14.1852 16.657 13.901 16.9412Z" />
      <path id="Vector_2" d="M20.1529 3.84468C18.9623 2.65408 17.3748 1.99754 15.6894 1.99754C14.004 1.99754 12.4214 2.65408 11.2259 3.84468L9.31507 5.75551C9.026 6.04458 9.026 6.51004 9.31507 6.79421C9.60415 7.07839 10.0696 7.08329 10.3538 6.79421L12.2646 4.88338C14.1509 2.99705 17.223 2.99705 19.1093 4.88338C20.9956 6.76971 20.9956 9.84174 19.1093 11.7281L17.3357 13.5017C17.0466 13.7908 17.0466 14.2562 17.3357 14.5404C17.4777 14.6825 17.6688 14.756 17.855 14.756C18.0412 14.756 18.2323 14.6825 18.3744 14.5404L20.148 12.7668C21.3386 11.5762 21.9951 9.98872 21.9951 8.30328C21.9951 6.61783 21.3435 5.03527 20.1529 3.84468Z" />
      <path id="Vector_3" d="M14.0333 8.92552L8.92799 14.0309C8.63891 14.3199 8.63891 14.7854 8.92799 15.0696C9.07007 15.2117 9.26116 15.2851 9.44734 15.2851C9.63352 15.2851 9.82461 15.2117 9.96669 15.0696L15.072 9.96422C15.3611 9.67515 15.3611 9.20969 15.072 8.92552C14.783 8.64134 14.3175 8.64134 14.0333 8.92552Z" />
    </g>
    <defs>
      <clipPath id="clip-link">
        <rect width="20" height="20.0049" fill="white" transform="translate(2 1.99754)" />
      </clipPath>
    </defs>
  </SvgIcon>
));

LinkIcon.displayName = 'LinkIcon';
