import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_BUDGET_DISTRIBUTION_FOR_FISCAL_YEAR } from '@frontend/app/queries/GetBudgetDistributionForFiscalYear';
import { GetBudgetDistributionForFiscalYearVariables, GetBudgetDistributionForFiscalYear } from '@frontend/app/queries/types/GetBudgetDistributionForFiscalYear';

type IOptions = QueryHookOptions<GetBudgetDistributionForFiscalYear, GetBudgetDistributionForFiscalYearVariables>;

export function useGetBudgetDistributionsForFiscalYear(options: IOptions) {
    const {
        loading, data, error, refetch,
} = useQuery<GetBudgetDistributionForFiscalYear, GetBudgetDistributionForFiscalYearVariables>(GET_BUDGET_DISTRIBUTION_FOR_FISCAL_YEAR, {
  ...options,
  fetchPolicy: 'no-cache',
});

    return {
      loading,
      budgetDistributionsForFiscalYear: data?.getBudgetDistributionForFiscalYear?.budgetDistributions || [],
      error,
      refetch,
    };
  }
