import gql from 'graphql-tag';

export const ONBOARDING_TEMPLATE_FRAGMENT = gql`
  fragment OnboardingTemplateFragment on OnboardingTemplateConfig {
    time
    version
    skipSelectPageTemplate
    settings {
      page_online
      url
      favicon
      logo
      page_color {
        background_color
      }
      form_color {
        background_color
        is_transparent
      }
      headline_styling {
        font_family
        font_size
        fill_color
      }
      body_styling {
        font_family
        font_size
        fill_color
      }
      cta_styling {
        font_family
        font_color
        button_color
      }
    }
    fieldsSettings {
      fieldName
      isHidden
    }
    intro {
      show
      title
      subtitle
      hero_image
      hero_is_video
      description
      button_text
      brand_instagram
      brand_pinterest
      brand_tiktok
      brand_website
      brand_youtube
    }
    about {
      show
      title
      description
      brand_images
    }
    perks {
      show
      title
      compensation
      availability
      compensationTitle
      availabilityTitle
      bullet_style
      items
    }
    persona {
      show
      title
      cover_image
      bullet_style
      features
    }
    application {
      description
      support_email
      unpaidLabel
      unpaidDescription
      apply_label
      title
    }
  }
`;
