import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_BRAND_CATALOGS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { GetBrandCatalogsQuery } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetBrandCatalogsQuery';

type IOptions = QueryHookOptions<GetBrandCatalogsQuery>;

export const useGetCatalogs = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch,
} = useQuery(GET_BRAND_CATALOGS_QUERY, options);

  return {
    loading,
    catalogs: data?.getBrandCatalogs ?? [],
    error,
    refetch,
  };
};
