/**
 * Gets the filename from given url.
 *
 * @param {String} url the url.
 *
 * @return {String}
 */
export function getFilenameFromUrl(url: string) {
  return (url || '')
    .split('/')
    .pop()
    .split('#')[0]
    .split('?')[0];
}
