import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const ClockIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 25">
    <path d="M12.5862 22.9922C7.0513 22.9922 2.58618 18.4806 2.58618 12.9922C2.58618 7.50382 7.0513 2.99219 12.5862 2.99219C18.0746 2.99219 22.5862 7.4573 22.5862 12.9922C22.5862 18.5271 18.0746 22.9922 12.5862 22.9922ZM12.5862 4.29451C7.79548 4.29451 3.88851 8.20149 3.88851 12.9922C3.88851 17.7829 7.79548 21.6899 12.5862 21.6899C17.3769 21.6899 21.2839 17.7829 21.2839 12.9922C21.2839 8.20149 17.3769 4.29451 12.5862 4.29451Z" />
    <path d="M17.0514 18.1076C16.9118 18.1076 16.7258 18.061 16.6328 17.9215L11.749 13.4564C11.6095 13.3169 11.563 13.1773 11.563 12.9913V8.52616C11.563 8.15407 11.8421 7.875 12.2142 7.875C12.5862 7.875 12.8653 8.15407 12.8653 8.52616V12.7122L17.5165 16.9913C17.7955 17.2238 17.7955 17.6424 17.563 17.875C17.4235 18.0145 17.2374 18.1076 17.0514 18.1076Z" />
  </SvgIcon>
));

ClockIcon.displayName = 'ClockIcon';
