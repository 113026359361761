import * as React from 'react';

import {
 Table, Button, Alert,
 Select,
 Tooltip,
 message,
 IColumnsType,
} from '@revfluence/fresh';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import {
  ValidateAddresses_bulkValidateAddress_originalAddress,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/ValidateAddresses';
import { COUNTRY_DATA } from '@frontend/app/constants/countryData';
import { useInitializeApolloClient } from '@frontend/context/utils';
import { useAuth } from '@frontend/context/authContext';
import { UPDATE_MEMBER } from '@frontend/app/queries';
import { logger } from '@common';
import { toLowerAndTrim } from '@frontend/utils';
import styles from './InvalidMemberAddress.styles.scss';
import { useProductFulfillmentContext } from '../context';
import { isUSA } from '../utils';

const { useMemo, useState } = React;

export interface MemberWithInvalidAddress {
  id: number;
  name: string;
  address: ValidateAddresses_bulkValidateAddress_originalAddress;
  errorMessages: string;
}
interface IProps {
  memberShippingAddresses: MemberWithInvalidAddress[];
  onContinue(): void;
  canContinue: boolean;
}

type UpdatedAddress = Record<number, { country?: string, state?: string }>;

export const InvalidMemberAddress: React.FunctionComponent<IProps> = (props) => {
  const { canContinue, memberShippingAddresses, onContinue } = props;
  const { closeModal } = useApplication();
  const { addressSchemas, refetchMembersByIds } = useProductFulfillmentContext();

  const [updatedAddresses, setUpdatedAddresses] = useState<UpdatedAddress>({});
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);

  const COLS = useMemo<IColumnsType<MemberWithInvalidAddress>>(() => [
    {
      title: 'Error',
      dataIndex: 'errorMessages', // if errorMessages is an array,
      key: 'errorMessages',
      width: '250px',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
    },
    {
      title: 'Country',
      dataIndex: ['address', 'country'],
      key: 'country',
      render: (country: string, record: MemberWithInvalidAddress) => {
        const updatedCountry = updatedAddresses[record.id]?.country || country;
        const isValid = isUSA(updatedCountry) || COUNTRY_DATA.some((countryData) => [
          toLowerAndTrim(countryData.countryName),
          toLowerAndTrim(countryData.countryShortCode)].includes(toLowerAndTrim(updatedCountry)));
        return (
          <Select
            value={updatedCountry}
            onChange={(e) => setUpdatedAddresses((prev) => ({
            ...prev,
            [record.id]: { ...prev[record.id], country: e },
          }))}
            status={isValid ? '' : 'error'}
          >
            {COUNTRY_DATA.map((country) => (
              <Select.Option key={country.countryName} value={country.countryName}>
                {country.countryName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'State',
      dataIndex: ['address', 'province'],
      key: 'province',
      render: (province: string, record: MemberWithInvalidAddress) => {
        const updatedCountry = updatedAddresses[record.id]?.country || record.address.country;
        const updatedState = updatedAddresses[record.id]?.state || province;
        const countryFound = isUSA(updatedCountry)
        ? COUNTRY_DATA.find((country) => country.countryShortCode === 'US')
        : COUNTRY_DATA.find((country) => [
          toLowerAndTrim(country.countryName),
          toLowerAndTrim(country.countryShortCode)].includes(toLowerAndTrim(updatedCountry)));
        const isValid = countryFound?.regions.some((state) => [
          toLowerAndTrim(state.name),
          toLowerAndTrim(state.shortCode)].includes(toLowerAndTrim(updatedState)));
        return (
          <Select
            value={updatedState}
            onChange={(e) => setUpdatedAddresses((prev) => ({
              ...prev,
              [record.id]: { ...prev[record.id], state: e },
            }))}
            status={isValid ? '' : 'error'}
          >
            {countryFound?.regions.map((state) => (
              <Select.Option key={state.name} value={state.name}>
                {state.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
  ], [updatedAddresses]);

  const isUpdatedAddressValid = useMemo(() => memberShippingAddresses.every((member) => {
    const newCountry = updatedAddresses[member.id]?.country || member.address.country;
    const newState = updatedAddresses[member.id]?.state || member.address.province;
    const countryFound = isUSA(newCountry)
    ? COUNTRY_DATA.find((country) => country.countryShortCode === 'US')
    : COUNTRY_DATA.find((country) => [
      toLowerAndTrim(country.countryName),
      toLowerAndTrim(country.countryShortCode)].includes(toLowerAndTrim(newCountry)));
    return countryFound?.regions.some((state) => [
      toLowerAndTrim(state.name),
      toLowerAndTrim(state.shortCode)].includes(toLowerAndTrim(newState)));
  }), [updatedAddresses, memberShippingAddresses]);

  const { token } = useAuth();
  const apolloClient = useInitializeApolloClient(token);

  const handleContinue = async () => {
    setIsUpdatingAddress(true);
    try {
      await Promise.all(memberShippingAddresses.map(async (member) => {
        const updatedAddress = updatedAddresses[member.id];
        if (updatedAddress) {
          return await apolloClient.mutate({
            mutation: UPDATE_MEMBER,
            variables: {
              id: member.id,
              member: {
                fields: {
                  [addressSchemas.Country.id]: updatedAddress?.country?.trim(),
                  [addressSchemas.State.id]: updatedAddress?.state?.trim(),
                },
              },
            },
          });
        }
      }));
      await refetchMembersByIds();
      onContinue();
    } catch (e) {
      logger.error('Error in address validation, failed to update addresses', e);
      message.error('Failed to update addresses, please try again.');
    } finally {
      setIsUpdatingAddress(false);
    }
  };

  return (
    <div className={styles.InvalidMembers}>
      {!isUpdatedAddressValid && (
        <Alert
          type="error"
          message="Please review the following addresses."
          description="The following members have addresses that may be invalid. This may cause issues while generating orders. Please update the addresses on their profiles."
        />
      )}
      <div className={styles.summary}>
        <Table
          columns={COLS}
          className={styles.table}
          rowKey={(record) => `${record.name} ${record.address.address1}`} // Define how to find unique row key
          dataSource={memberShippingAddresses}
        />
        <div className={styles.footer}>
          <Button type="primary" className={styles.button} onClick={closeModal}>
            Cancel
          </Button>
          {canContinue && (
            <Tooltip title={isUpdatedAddressValid ? '' : 'Please fix the above addresses before continuing'}>
              <Button
                type="primary"
                className={styles.button}
                disabled={!isUpdatedAddressValid}
                onClick={handleContinue}
                loading={isUpdatingAddress}
              >
                Continue
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
    // Rest of your component
  );
};
