import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const NextIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M18.6,2c-0.39,0-0.71,0.32-0.71,0.71v8.45c-0.13-0.3-0.33-0.56-0.58-0.78L8.53,2.62
      C7.84,2.01,6.89,1.87,6.05,2.25C5.22,2.62,4.7,3.43,4.7,4.35v15.34c0,0.91,0.52,1.72,1.35,2.1C6.36,21.93,6.68,22,7.01,22
      c0.54,0,1.07-0.19,1.5-0.57l8.78-7.59c0.26-0.22,0.46-0.5,0.6-0.81v8.21c0,0.39,0.32,0.71,0.71,0.71s0.71-0.32,0.71-0.71V2.71
      C19.3,2.32,18.99,2,18.6,2z M16.37,12.78l-8.78,7.59c-0.27,0.23-0.63,0.28-0.95,0.14c-0.33-0.15-0.52-0.45-0.52-0.81V4.35
      c0-0.36,0.2-0.66,0.52-0.81c0.12-0.05,0.25-0.08,0.37-0.08c0.21,0,0.41,0.08,0.58,0.23l8.78,7.75c0.2,0.17,0.3,0.41,0.3,0.67
      C16.67,12.37,16.56,12.61,16.37,12.78z"
    />
  </SvgIcon>
));

NextIcon.displayName = 'NextIcon';

export { NextIcon };
