import { IProspect } from '@components';
import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';

export interface IProgram {
  id: number;
  title: string;
  communityId: number;
  published: boolean;
  archivedDate: string;
  deletedDate: string;
}

/**
 * aspirex/services/members/types/ProgramMembershipStatus.ts
 */
export type TProspectProgramStatus = 'new' | 'invited' | 'approved' | 'rejected';

export interface IMemberProgram extends Omit<IProject, 'status'> {
  status: TProspectProgramStatus;
  memberId: IProspect['id'];
  memberEmail: IProspect['email'];
  username?: string; // used as identifier only thru backend_server api
  social_account_id?: number;
}

/**
 * List of programs per member (identified by the username)
 */
export interface IMemberProgramMap {
  [socialAccountId: number]: IMemberProgram[];
}

export type TProgram = IProject | IMemberProgram;

/**
 * Duck typing to check if program is `IMemberProgram`
 * @param {TProgram} program
 */
export const isMemberProgram = (program: TProgram): program is IMemberProgram => (
  (program as IMemberProgram).status !== undefined
);
