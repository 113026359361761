/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1uoj2_157 {
  display: flex;
}

._justify-content-space-between_1uoj2_161 {
  justify-content: space-between;
}

._tabular-nums_1uoj2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1uoj2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MenuList_1uoj2_178 {
  display: flex;
  flex-direction: column;
}
._MenuList_1uoj2_178 ._item_1uoj2_182 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.125rem;
  margin-bottom: 0.5rem;
  padding: 0 0.75rem 0 1rem;
  border-radius: 0.5rem;
  color: #8f8d91;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color, background;
}
._MenuList_1uoj2_178 ._item_1uoj2_182:hover, ._MenuList_1uoj2_178 ._item_1uoj2_182._active_1uoj2_197 {
  background: #eff5f9;
  color: #3996e0;
}
._MenuList_1uoj2_178 ._item_1uoj2_182 ._text_1uoj2_201 {
  flex: 1;
  color: inherit;
}
._MenuList_1uoj2_178 ._item_1uoj2_182 ._icons_1uoj2_205 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}