import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_OR_UPDATE_META_FIELDS_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { CreateOrUpdateMetaFields, CreateOrUpdateMetaFieldsVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/CreateOrUpdateMetaFields';

type IOptions = MutationHookOptions<CreateOrUpdateMetaFields, CreateOrUpdateMetaFieldsVariables>;

export const useCreateMetaFields = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<CreateOrUpdateMetaFields, CreateOrUpdateMetaFieldsVariables>(
    CREATE_OR_UPDATE_META_FIELDS_MUTATION, options,
);

  return {
    createMetaFields: mutate,
    isCreatingMetaFields: loading,
  };
};
