import * as React from 'react';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import { IWorkflowActionParameters, IBulkActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';

import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import ContractAppDashboard from './ContractAppDashboard';
import ContractAppModal from './ContractAppModal';

const { useEffect } = React;

interface IProps {
  modalView: boolean;
  clientId: string;
  clientName: string;
  memberId?: string;
  bulkActionParameters?: IBulkActionParameters;
  workflowActionParameters?: IWorkflowActionParameters;
  closeModal?(): void;
}

const ContractApp: React.FunctionComponent<IProps> = (props) => {
  const { modalView } = props;
  const addEvent = useEventContext();
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'contract' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={props.clientId}
      clientName={props.clientName}
      memberId={props.memberId}
      bulkActionParameters={props.bulkActionParameters}
      closeModal={props.closeModal}
      workflowActionParameters={props.workflowActionParameters}
    >
      <div>
        {modalView ? (<ContractAppModal />) : (<ContractAppDashboard refreshUi={refreshUi} />)}
      </div>
    </ApplicationProvider>
  );
};

export default ContractApp;
