import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';

export interface ProductCatalogsState {
  isDrawerOpen: boolean;
  selectedCatalogIds: string[];
}

export interface ProductCatalogsContextProps extends ProductCatalogsState {
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
  setSelectedCatalogIds: (selectedCatalogIds: string[]) => void;
  toggleSelectedCatalogId: (catalogId: string) => void;
  resetDrawer: () => void;
}

export enum Actions {
  SetIsDrawerOpen = 'SET_IS_DRAWER_OPEN',
  SetSelectedCatalogIds = 'SET_SELECTED_CATALOG_IDS',
  ToggleSelectedCatalogId = 'TOGGLE_SELECTED_CATALOG_ID',
  ResetDrawer = 'RESET_DRAWER',
}

type ReducerAction =
  | { type: Actions.SetIsDrawerOpen; payload: boolean }
  | { type: Actions.SetSelectedCatalogIds; payload: string[] }
  | { type: Actions.ToggleSelectedCatalogId; payload: string }
  | { type: Actions.ResetDrawer };

const initialState: ProductCatalogsState = {
  isDrawerOpen: false,
  selectedCatalogIds: [],
};

export const reducer = (state: ProductCatalogsState, action: ReducerAction): ProductCatalogsState => {
  switch (action.type) {
    case Actions.SetIsDrawerOpen:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    case Actions.SetSelectedCatalogIds:
      return {
        ...state,
        selectedCatalogIds: action.payload,
      };
    case Actions.ToggleSelectedCatalogId:
      return {
        ...state,
        selectedCatalogIds: state.selectedCatalogIds.includes(action.payload)
          ? state.selectedCatalogIds.filter((id) => id !== action.payload)
          : [...state.selectedCatalogIds, action.payload],
      };
    case Actions.ResetDrawer:
      return initialState;
    default:
      return state;
  }
};

export const useProductCatalogReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setIsDrawerOpen: (payload: boolean) => {
        dispatch({ type: Actions.SetIsDrawerOpen, payload });
      },
      setSelectedCatalogIds: (payload: string[]) => {
        dispatch({ type: Actions.SetSelectedCatalogIds, payload });
      },
      toggleSelectedCatalogId: (payload: string) => {
        dispatch({ type: Actions.ToggleSelectedCatalogId, payload });
      },
      resetDrawer: () => {
        dispatch({ type: Actions.ResetDrawer });
      },
    }),
    [],
  );

  return useMemo(() => ({ ...state, ...actions }), [state, actions]);
};

export const ProductCatalogsContext = createContext<ProductCatalogsContextProps>(null);

export const ProductCatalogsProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useProductCatalogReducer();

  return <ProductCatalogsContext.Provider value={value}>{children}</ProductCatalogsContext.Provider>;
};

export const useProductCatalogsContext = () => useContext(ProductCatalogsContext);
