import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_IMPORT_LOGS } from '@frontend/applications/ProductFulfillmentApp/queries/importLogs';
import { GetImportLogs } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportLogs';

type IOptions = QueryHookOptions<GetImportLogs>;

export const useGetImportLogs = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch, stopPolling, startPolling,
} = useQuery<GetImportLogs>(GET_IMPORT_LOGS, options);

  return {
    importLogs: data?.getAllImportLogs || [],
    loading,
    error,
    refetch,
    startPolling,
    stopPolling,
  };
};
