import * as React from 'react';
import { useUploadContent, IUploadConfig, IContent } from '@frontend/app/hooks';

import { isMIMETypeVideo } from '@frontend/utils';
import { CropperProps, ThumbnailUpload, ThumbnailUploadProps } from './ThumbnailUpload';

const { useEffect, useState, useCallback } = React;

interface IProps extends Pick<ThumbnailUploadProps, 'className' | 'showCropper' | 'cropperProps' | 'accept' | 'acceptedLabel' | 'acceptedTypes' | 'display' | 'hideMaxSizeNote' | 'uploadLabel'> {
  defaultImageSrc?: string;
  isDefaultImage?: boolean;
  uploadProps: IUploadConfig;
  hintText?: string;
  onUpload?: (url: string, isVideo: boolean) => void;
  onRemove?();
  hasError?: boolean;
  isDefaultVideo?: boolean;
  cropperProps?: CropperProps,
  fileBytesLimit?: number,
}

export const ConnectedImageVideoUpload: React.FC<IProps> = React.memo((props) => {
  const {
    uploadProps,
    onUpload,
    onRemove,
    defaultImageSrc,
    isDefaultImage,
    hintText,
    hasError,
    isDefaultVideo,
    cropperProps,
    fileBytesLimit,
    ...thumbnailUploadProps
  } = props;

  const [tmpSource, setTmpSource] = useState<string>('');
  const [isVideo, setIsVideo] = useState(false);

  const {
    content: uploadContent,
    upload: uploadLogo,
    error: uploadError,
    isUploading,
  } = useUploadContent(uploadProps);

  const handleFileSelected = useCallback(async (file: File) => {
    if (!file) {
      if (onRemove) {
        onRemove();
      }
      return;
    }
    const fileUrl = await uploadLogo(file, 'image');

    if (onUpload) {
      onUpload(fileUrl, isMIMETypeVideo(file.type));
    }
  }, [uploadLogo, onUpload, onRemove]);

  useEffect(() => {
    setTmpSource(isDefaultImage ? '' : defaultImageSrc);
    setIsVideo(isDefaultVideo);
  }, [defaultImageSrc, isDefaultImage, isDefaultVideo]);

  const onThumbnailSelected = useCallback((fileData: string) => {
    setTmpSource(fileData);

    /** File data will look like: data:{mimeType};... */
    const mimeType = fileData.split(';')?.[0]?.split(':')?.[1] || '';
    setIsVideo(isMIMETypeVideo(mimeType));
  }, []);

  return (
    <ThumbnailUpload
      {...thumbnailUploadProps}
      thumbnail={tmpSource}
      onThumbnailSelected={onThumbnailSelected}
      onFileSelected={handleFileSelected}
      disabled={isUploading}
      showCropper
      progressPercentage={isUploading ? (uploadContent as IContent)?.progress?.percentage : undefined}
      hasError={!!uploadError || hasError}
      errorMessage={uploadError?.message}
      hintText={hintText}
      isVideo={isVideo}
      cropperProps={cropperProps}
      fileBytesLimit={fileBytesLimit}
    />
  );
});

ConnectedImageVideoUpload.displayName = 'ConnectedImageVideoUpload';
