import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_DASHBOARD_BUDGET_TAB_BUDGET_SPLIT from '../queries/GetBudgetDashboardBudgetTabBudgetSplitQuery';
import { GetBudgetDashboardBudgetTabBudgetSplitQuery, GetBudgetDashboardBudgetTabBudgetSplitQueryVariables } from '../queries/types/GetBudgetDashboardBudgetTabBudgetSplitQuery';

type IOptions = QueryHookOptions<GetBudgetDashboardBudgetTabBudgetSplitQuery, GetBudgetDashboardBudgetTabBudgetSplitQueryVariables>;

export function useGetBudgetDashboardBudgetTabBudgetSplitQuery(options: IOptions = {}) {
  return useQuery<GetBudgetDashboardBudgetTabBudgetSplitQuery, GetBudgetDashboardBudgetTabBudgetSplitQueryVariables>(
    GET_DASHBOARD_BUDGET_TAB_BUDGET_SPLIT,
    options,
  );
}
