import { MutationHookOptions } from '@apollo/client';

import { useMutation } from '@frontend/app/hooks';
import { UPDATE_SOCIAL_ACCOUNT_EMAILS_MUTATION } from '@frontend/app/queries';
import {
  UpdateSocialAccountEmailsMutation,
  UpdateSocialAccountEmailsMutationVariables,
} from '@frontend/app/queries/types/UpdateSocialAccountEmailsMutation';

type IOptions = MutationHookOptions<UpdateSocialAccountEmailsMutation, UpdateSocialAccountEmailsMutationVariables>;

export const useUpdateSocialAccountEmails = (options: IOptions = {}) => useMutation<UpdateSocialAccountEmailsMutation, UpdateSocialAccountEmailsMutationVariables>(UPDATE_SOCIAL_ACCOUNT_EMAILS_MUTATION, options);
