/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_57l8s_157 {
  display: flex;
}

._justify-content-space-between_57l8s_161 {
  justify-content: space-between;
}

._tabular-nums_57l8s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_57l8s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Submitted_57l8s_178 ._moreInfo_57l8s_178, ._Submitted_57l8s_178 ._submitted_57l8s_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._SignupForm_57l8s_188 ._label_57l8s_188._bold_57l8s_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._SignupForm_57l8s_188 ._checkbox_57l8s_198 ._checkboxLabel_57l8s_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._SignupForm_57l8s_188 {
  margin: auto;
  padding-bottom: 5.625rem;
}
@media only screen and (min-width: 20rem) {
  ._SignupForm_57l8s_188 {
    box-sizing: border-box;
    margin: 0.75rem 0.625rem auto;
    padding: 1.5rem 1rem;
    border-radius: 0.75rem;
  }
  ._SignupForm_57l8s_188 [class*=Button_primary] {
    border: 0.125rem solid #1c1c1c;
    border-radius: unset;
    background-color: #fdfdfd !important;
    color: #1A1818;
  }
}
@media only screen and (orientation: landscape) {
  ._SignupForm_57l8s_188 {
    width: 50%;
    padding: 2.8% 6.2%;
  }
}
._SignupForm_57l8s_188 ._label_57l8s_188 {
  display: block;
  margin-bottom: 0.3125rem;
  color: var(--grey-100);
}
._SignupForm_57l8s_188 ._label_57l8s_188 ._required_57l8s_237 {
  color: var(--red-6);
}
._SignupForm_57l8s_188 ._input_57l8s_240,
._SignupForm_57l8s_188 ._dateField_57l8s_241 {
  width: 100%;
  position: relative;
}
._SignupForm_57l8s_188 ._social_57l8s_245 {
  padding-left: 1.5rem !important;
}
._SignupForm_57l8s_188 ._socialPrefix_57l8s_248 {
  display: flex;
  align-items: center;
  position: relative;
}
._SignupForm_57l8s_188 ._socialPrefix_57l8s_248::before {
  content: "@";
  position: absolute;
  left: 0.625rem;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 2.5rem;
  color: #8f8d91;
}
._SignupForm_57l8s_188 ._checkbox_57l8s_198 {
  margin-bottom: 1.25rem;
}
._SignupForm_57l8s_188 ._unpaidCollaborationCheckbox_57l8s_266 {
  align-items: flex-start;
  padding-left: 0px;
}
._SignupForm_57l8s_188 ._unpaidCollaborationCheckbox_57l8s_266:hover {
  background-color: #e6f7ff;
}
._SignupForm_57l8s_188 ._unpaidDescriptionLabel_57l8s_273 {
  color: var(--gray-9);
}
._SignupForm_57l8s_188 ._unpaidDescriptionLabel_57l8s_273 p:last-child {
  margin-bottom: 0;
}
._SignupForm_57l8s_188 .ant-alert-icon {
  margin-right: 6px;
}
._SignupForm_57l8s_188 .ant-alert-icon svg {
  height: 1.25rem;
  width: 1.25rem;
}
._SignupForm_57l8s_188 .ant-alert-info {
  background-color: #e6f7ff;
  border: none;
}
._SignupForm_57l8s_188 .ant-alert-description {
  font-size: 0.875rem;
  color: #1F1F21;
}
._SignupForm_57l8s_188 .ant-alert-message {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1F1F21;
}
._SignupForm_57l8s_188 ._error_57l8s_299 {
  margin-top: 0.5rem;
  color: #f1515f;
  font-size: 0.75rem;
}

._Submitted_57l8s_178 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: auto;
  text-align: center;
}
._Submitted_57l8s_178 ._submitted_57l8s_178 {
  margin-top: 0.625rem;
  font-size: 1.25rem;
}
._Submitted_57l8s_178 ._moreInfo_57l8s_178 {
  margin-top: 0.625rem;
  font-size: 1.125rem;
}

._copyright_57l8s_322 {
  width: 100%;
  margin: 16px auto 0;
  font-size: 14px;
  color: var(--grey-100);
}

._supportEmail_57l8s_329 {
  color: var(--primary);
  text-decoration: none;
}

._talentAgentSection_57l8s_334 {
  padding-left: var(--spacing-lg);
  padding-top: var(--spacing-sm);
  width: 100%;
}