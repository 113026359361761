/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_3aj29_157 {
  display: flex;
}

._justify-content-space-between_3aj29_161 {
  justify-content: space-between;
}

._tabular-nums_3aj29_165 {
  font-variant-numeric: tabular-nums;
}

._Content_3aj29_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_3aj29_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Content_3aj29_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Content_3aj29_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Content_3aj29_169 ::-webkit-scrollbar-track, ._Content_3aj29_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Content_3aj29_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Content_3aj29_169 {
  height: 42.5rem;
  position: relative;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

._Content_3aj29_169 ._summary_3aj29_211,
._Content_3aj29_169 ._analyze_3aj29_212 {
  height: 100%;
  display: grid;
  grid-gap: 1rem;
  animation: _show_3aj29_1 0.4s;
}

._Content_3aj29_169._blog_3aj29_219 ._summary_3aj29_211, ._Content_3aj29_169._facebook_3aj29_219 ._summary_3aj29_211, ._Content_3aj29_169._twitter_3aj29_219 ._summary_3aj29_211 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
._Content_3aj29_169._blog_3aj29_219 ._summary_3aj29_211 ._ProfileCard_3aj29_223, ._Content_3aj29_169._facebook_3aj29_219 ._summary_3aj29_211 ._ProfileCard_3aj29_223, ._Content_3aj29_169._twitter_3aj29_219 ._summary_3aj29_211 ._ProfileCard_3aj29_223 {
  grid-column: 1;
  grid-row: 1/6 span;
}
._Content_3aj29_169._blog_3aj29_219 ._summary_3aj29_211 ._SummaryCard_3aj29_227, ._Content_3aj29_169._facebook_3aj29_219 ._summary_3aj29_211 ._SummaryCard_3aj29_227, ._Content_3aj29_169._twitter_3aj29_219 ._summary_3aj29_211 ._SummaryCard_3aj29_227 {
  grid-column: 2;
}
._Content_3aj29_169._blog_3aj29_219 ._summary_3aj29_211 ._SummaryCard_3aj29_227, ._Content_3aj29_169._facebook_3aj29_219 ._summary_3aj29_211 ._SummaryCard_3aj29_227 {
  grid-row: 1/4;
}
._Content_3aj29_169._twitter_3aj29_219 ._summary_3aj29_211 ._SummaryCard_3aj29_227 {
  grid-row: 1/3;
}
._Content_3aj29_169._instagram_3aj29_236 ._summary_3aj29_211 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
._Content_3aj29_169._instagram_3aj29_236 ._summary_3aj29_211 ._ProfileCard_3aj29_223 {
  grid-column: 1;
  grid-row: 1/2 span;
}
._Content_3aj29_169._instagram_3aj29_236 ._summary_3aj29_211 ._EngagementRateCard_3aj29_244 {
  grid-column: 2;
  grid-row: 1;
}
._Content_3aj29_169._instagram_3aj29_236 ._summary_3aj29_211 ._SummaryCard_3aj29_227 {
  grid-column: 2;
  grid-row: 2;
}
._Content_3aj29_169._instagram_3aj29_236 ._analyze_3aj29_212 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(9, minmax(0, 1fr));
}
._Content_3aj29_169._instagram_3aj29_236 ._analyze_3aj29_212 ._GenderCard_3aj29_256 {
  grid-column: 1;
  grid-row: 1/4 span;
}
._Content_3aj29_169._instagram_3aj29_236 ._analyze_3aj29_212 ._AgeCard_3aj29_260 {
  grid-column: 1;
  grid-row: 5/5 span;
}
._Content_3aj29_169._instagram_3aj29_236 ._analyze_3aj29_212 ._CountryCard_3aj29_264 {
  grid-column: 2;
  grid-row: 1/3 span;
}
._Content_3aj29_169._instagram_3aj29_236 ._analyze_3aj29_212 ._CityCard_3aj29_268 {
  grid-column: 2;
  grid-row: 4/3 span;
}
._Content_3aj29_169._instagram_3aj29_236 ._analyze_3aj29_212 ._PostPerformanceCard_3aj29_272 {
  grid-column: 3;
  grid-row: 1/6 span;
}
._Content_3aj29_169._instagram_3aj29_236 ._analyze_3aj29_212 ._GrowthCard_3aj29_276 {
  grid-column: 2/2 span;
  grid-row: 7/3 span;
}
._Content_3aj29_169._pinterest_3aj29_280 ._summary_3aj29_211 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(8, 1fr);
}
._Content_3aj29_169._pinterest_3aj29_280 ._summary_3aj29_211 ._ProfileCard_3aj29_223 {
  grid-column: 1;
  grid-row: 1/8 span;
}
._Content_3aj29_169._pinterest_3aj29_280 ._summary_3aj29_211 ._SummaryCard_3aj29_227 {
  grid-column: 2;
  grid-row: 1/3 span;
}
._Content_3aj29_169._pinterest_3aj29_280 ._summary_3aj29_211 ._PostPerformanceCard_3aj29_272 {
  grid-column: 2;
  grid-row: 4/5 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(9, 1fr);
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._ProfileCard_3aj29_223 {
  grid-column: 1/2 span;
  grid-row: 1/9 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._PostPerformanceCard_3aj29_272 {
  grid-column: 3/2 span;
  grid-row: 1/5 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._PostPerformanceCard_3aj29_272 ._detailList_3aj29_308 {
  margin: 0;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._HashtagsCard_3aj29_311 {
  grid-column: 3/2 span;
  grid-row: 6/1 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._TopCategoriesCard_3aj29_315 {
  grid-column: 3;
  grid-row: 7/3 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._TopPlaylistsCard_3aj29_319 {
  grid-column: 4;
  grid-row: 7/3 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._TopPlaylistsCard_3aj29_319 a {
  color: #3996e0;
  text-decoration: none;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._TopCategoriesCard_3aj29_315 ._items_3aj29_327,
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._TopPlaylistsCard_3aj29_319 ._items_3aj29_327 {
  overflow: hidden;
}
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._TopCategoriesCard_3aj29_315 ._items_3aj29_327 ._item_3aj29_327,
._Content_3aj29_169._youtube_3aj29_296 ._summary_3aj29_211 ._TopPlaylistsCard_3aj29_319 ._items_3aj29_327 ._item_3aj29_327 {
  margin-bottom: 0.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._Content_3aj29_169._youtube_3aj29_296 ._analyze_3aj29_212 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, 1fr);
}
._Content_3aj29_169._youtube_3aj29_296 ._analyze_3aj29_212 ._GenderCard_3aj29_256 {
  grid-column: 1;
  grid-row: 1/1 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._analyze_3aj29_212 ._AgeCard_3aj29_260 {
  grid-column: 1;
  grid-row: 2/1 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._analyze_3aj29_212 ._CountryCard_3aj29_264 {
  grid-column: 2;
  grid-row: 1/1 span;
}
._Content_3aj29_169._youtube_3aj29_296 ._analyze_3aj29_212 ._StateCard_3aj29_354 {
  grid-column: 2;
  grid-row: 2/1 span;
}
._Content_3aj29_169._tiktok_3aj29_358 ._summary_3aj29_211 {
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
}
._Content_3aj29_169._tiktok_3aj29_358 ._summary_3aj29_211 ._ProfileCard_3aj29_223 {
  grid-column: 1;
  grid-row: 1/3 span;
}
._Content_3aj29_169._tiktok_3aj29_358 ._summary_3aj29_211 ._SummaryCard_3aj29_227 {
  grid-column: 2/4;
  grid-row: 1;
}
._Content_3aj29_169._tiktok_3aj29_358 ._summary_3aj29_211 ._GenderCard_3aj29_256 {
  grid-column: 2;
  grid-row: 2;
}
._Content_3aj29_169._tiktok_3aj29_358 ._summary_3aj29_211 ._AgeCard_3aj29_260 {
  grid-column: 3;
  grid-row: 2;
}
._Content_3aj29_169._tiktok_3aj29_358 ._summary_3aj29_211 ._CountryCard_3aj29_264 {
  grid-column: 2;
  grid-row: 3;
}
._Content_3aj29_169._tiktok_3aj29_358 ._summary_3aj29_211 ._StateCard_3aj29_354 {
  grid-column: 3;
  grid-row: 3;
}