import React from 'react';
import { UserAvatar } from '@frontend/app/components';
import styles from './CreatorProfile.scss';

const CreatorAvatar = ({ memberRecord }) => (
  <div className={styles.profileImage}>
    <figure>
      <UserAvatar profilePicture={memberRecord?.profilePicture} name={memberRecord.name} />
    </figure>
  </div>
  );

export default CreatorAvatar;
