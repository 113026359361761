import * as React from 'react';
import cx from 'classnames';
import xss from 'xss';

import { Button, Input, Radio } from '@revfluence/fresh';

import { Editor, IEditor } from '@frontend/app/components';

import { IResource } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { useResourceContext } from '@frontend/app/context';

const {
 useState, useEffect, useRef, useMemo,
} = React;

import styles from './EmailSignature.scss';

type TSignatureMode = 'editor' | 'html';

interface IProps {
  account: IResource;

  className?: string;
}

const ignoreAttributes = ['style', 'onmouseover', 'onmouseout'];
const filterXss = (html: string) => xss(html, {
  onIgnoreTagAttr: (_tag, name, value) => {
    if (ignoreAttributes.includes(name)) {
      return `${name}="${value}"`;
    }
    return '';
  },
});

/**
 * @type {React.FC}
 */
export const EmailSignature: React.FC<IProps> = React.memo((props) => {
  const { account } = props;
  const { refetch, updateResource, updating } = useResourceContext();
  const { showErrorMessage } = useMessagingContext();
  const [mode, setMode] = useState<TSignatureMode>((account.config.signature?.mode as TSignatureMode) || 'html');
  const [editorValue, setEditorValue] = useState(account.config.signature?.fromEditor || '');
  const [htmlValue, setHtmlValue] = useState(account.config.signature?.fromHTML || '');
  const editorRef = useRef<IEditor>(null);

  const isUpdated = useMemo(() => {
    const orgMode = account.config.signature?.mode || 'html';
    const orgEditorValue = account.config.signature?.fromEditor || '';
    const orgHtmlValue = account.config.signature?.fromHTML || '';

    return orgMode !== mode || orgEditorValue !== editorValue || orgHtmlValue !== htmlValue;
  }, [account.config.signature, mode, editorValue, htmlValue]);

  const saveSignature = async () => {
    const config = mode === 'editor'
        ? {
            signature: {
              fromEditor: editorValue,
              mode: 'editor',
            },
          }
        : {
            signature: {
              fromHTML: htmlValue,
              mode: 'html',
            },
          };

    const {
      data: { updated },
    } = await updateResource({
      variables: {
        resourceId: account.id,
        data: {
          config,
        },
      },
    });

    if (updated) {
      refetch();
    } else {
      showErrorMessage("There's an error when trying to update the account.");
    }
  };

  useEffect(() => {
    setEditorValue(account.config.signature?.fromEditor || '');
    setHtmlValue(account.config.signature?.fromHTML || '');
    editorRef?.current?.setValue(account.config.signature?.fromEditor);
  }, [account, editorRef]);

  return (
    <div className={cx(styles.EmailSignature, props.className)}>
      <div className={styles.title}>Signature</div>
      <div className={styles.text}>Append at the end of all outgoing messages</div>
      <Radio.Group className={styles.mode} value={mode} onChange={(ev) => setMode(ev.target.value)}>
        <Radio value="html">Insert HTML</Radio>
        <Radio value="editor">Text editor</Radio>
      </Radio.Group>

      {mode === 'editor' && (
        <Editor
          ref={editorRef}
          className={styles.editor}
          initialValue={editorValue}
          convertFromHTML
          onChange={({ html }) => {
            setEditorValue(filterXss(html));
          }}
        />
      )}
      {mode === 'html' && (
        <>
          <Input.TextArea
            placeholder="Enter HTML..."
            value={htmlValue}
            onChange={(ev) => setHtmlValue(ev.target.value)}
            autoSize={{ minRows: 4 }}
          />
          {!!htmlValue && (
            <div className={styles.preview}>
              <div className={styles.title}>Signature preview</div>
              <div
                style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: filterXss(htmlValue),
                }}
              />
            </div>
          )}
        </>
      )}
      <Button
        type="primary"
        className={styles.saveButton}
        loading={updating}
        disabled={updating || !isUpdated}
        onClick={saveSignature}
      >
        {updating ? 'Saving' : 'Save Signature Changes'}
      </Button>
    </div>
  );
});

EmailSignature.displayName = 'EmailSignature';
