import gql from 'graphql-tag';

export const DELETE_DEEPLINK_MUTATION = gql`
  mutation DeleteDeeplink($affiliateDeepLinkId: Int!) {
    deletedDeeplink: deleteDeepLink(affiliateDeepLinkId: $affiliateDeepLinkId) {
      affiliateDeepLinkUrl,
      createdDate,
      label,
      url,
      deletedDate,
      id,
      shortLink,
      tuneShortLinkId,
    }
  }`;
