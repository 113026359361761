import {
  Col, Divider, Drawer, Row, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import React, {
  memo, useCallback, useMemo,
} from 'react';
import cx from 'classnames';
import { first } from 'lodash';
import { getConversionDetailsAffliateBlocks } from '@frontend/app/utils/getConversionDetailsBlocks';
import { CoinsIcon } from '@revfluence/fresh-icons/regular/esm';
import { InfoCircleOutlined } from '@ant-design/icons';
import { UserAvatar } from '@frontend/app/components';
import styles from './MemberConversionDrawer.scss';

import { PayoutSummaryCard } from '../PayoutSummaryCard';
import { ConversionTable } from './ConversionTable';
import { useGetMultipleShopifyAffliateStatsQuery } from '../../hooks';
import { OFFER_SOURCE } from '../../types/globalTypes';
import { GetOfferById_offer, GetOfferById_offer_promos } from '../../queries/types/GetOfferById';
import { AffliateLevelStats, OfferMemberStatus, TSelectedAffliate } from '../MemberTable/types';
import { getMultiplePayoutLabel, getPayoutLabel } from '../OfferSummaryCard/hooks';
import { OfferDetailCard } from '../AffiliatesApp/components/OfferDetailCard';
import { useGetAffiliateConversionHistory } from '../../hooks/useGetAffiliateConversionHistory';
import { OFFER_STATUS_TYPE, getTagType } from '../../utils/getTagType';

const { Text, Link } = Typography;
interface IProps {
  offer: GetOfferById_offer,
  source: OFFER_SOURCE,
  isEnabledMultipleShopify: boolean,
  isOpen: boolean,
  selectedMemberConversion: TSelectedAffliate,
  onClose: () => void,
  migrateToGraphQL?: boolean,
  rejectMemberConversions?: boolean,
  isMulticurrency?: boolean,
  refresh: () => void;
}

export const MemberConversionDrawer: React.FC<Readonly<IProps>> = memo((props) => {
  const {
    offer, source, isEnabledMultipleShopify, isOpen, selectedMemberConversion, onClose, migrateToGraphQL, rejectMemberConversions, isMulticurrency, refresh,
  } = props;
  const affiliateStatsData = useMemo(() => {
    if (source === OFFER_SOURCE.TUNE) {
      return offer.links[0].affiliateStats.find((link) => link.affiliateOfferId === selectedMemberConversion?.affiliateOfferId) as unknown as AffliateLevelStats;
    } else {
      return offer.promos[0].affiliateStats.find((promo) => promo.affiliateOfferId === selectedMemberConversion?.affiliateOfferId) as unknown as AffliateLevelStats;
    }
  }, [source, selectedMemberConversion, offer.promos, offer.links]);

  const baseCurrencyWithoutMulipleShopify = useMemo(() => {
    if (offer.promos.length) {
      return first(offer.promos).stats.baseCurrencies;
    }
    return [];
  }, [offer.promos]);
  const isMultipleShopifyOffer = useMemo(() => {
    if (offer.promos.length) {
      return first(offer.promos).multiShopifyEnabled;
    }
    return false;
  }, [offer.promos]);
  const multipleShopifyOfferStats = useGetMultipleShopifyAffliateStatsQuery({
    fetchPolicy: 'network-only',
    variables: {
      affiliateOfferPromoId: selectedMemberConversion?.affiliateOfferId,
    },
    skip: !selectedMemberConversion?.affiliateOfferId,
  });
  const affliateConversionHistory = useGetAffiliateConversionHistory({
    fetchPolicy: 'network-only',
    variables: {
      affiliateOfferId: selectedMemberConversion?.memberAffiliateOfferId,
      offerSource: source,
    },
    skip: !selectedMemberConversion?.memberAffiliateOfferId,
  });
  const refetchData = useCallback(() => {
    if (selectedMemberConversion?.memberAffiliateOfferId) {
      affliateConversionHistory.refetch({
        affiliateOfferId: selectedMemberConversion?.memberAffiliateOfferId,
        offerSource: source,
      });
    }
  }, [selectedMemberConversion?.memberAffiliateOfferId, source, affliateConversionHistory]);

  const statsSummaryBlocks = useMemo(() => getConversionDetailsAffliateBlocks(affiliateStatsData, source, isMulticurrency, isMultipleShopifyOffer, baseCurrencyWithoutMulipleShopify), [source, baseCurrencyWithoutMulipleShopify, isMultipleShopifyOffer, isMulticurrency, affiliateStatsData]);
  const promoOffer: GetOfferById_offer_promos = offer?.promos?.length ? first(offer.promos) : null;
  return (
    <Drawer
      open={isOpen}
      width={992}
      title={(
        <Text className={styles.drawerHeader}>Member Conversions Summary</Text>
      )}
      bodyStyle={{ paddingTop: 0 }}
      onClose={() => onClose()}
    >
      <Row className={styles.drawerTitle} justify="space-between" align="middle">
        <OfferDetailCard showCta={false} migrateToGraphQL={migrateToGraphQL} infoButtonClassName />

      </Row>
      <Divider className={styles.divider} />
      <Row className={styles.mainContent}>
        <Row className={styles.memberInfoCard}>
          <Col span={24}>
            {
              selectedMemberConversion && (
                <Row>
                  <Col span={6} className={styles.memberInfoItemContainer}>
                    <Text className={styles.memberInfoItemTitle}>Name</Text>
                    <Row style={{ flexFlow: 'nowrap' }}>
                      <Col>
                        <UserAvatar
                          profilePicture={selectedMemberConversion?.imageUrl}
                          name={selectedMemberConversion.name}
                        />
                      </Col>
                      <Col>
                        <Text className={cx(styles.memberInfoItemValue, styles.memberInfoItemValueMargin)}>
                          {selectedMemberConversion.name}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  {
                    source === OFFER_SOURCE.SHOPIFY ? (
                      <Col span={6} className={styles.memberInfoItemContainer}>
                        <Text className={styles.memberInfoItemTitle}>Promo Code</Text>
                        <Row align="middle">
                          <Text ellipsis className={cx(styles.memberInfoItemValue, styles.memberInfoPromo, styles.memberInfoItemValue)} copyable>
                            {`${selectedMemberConversion.code} `}
                          </Text>
                        </Row>
                      </Col>
                    ) : (
                      <Col span={6} className={styles.memberInfoItemContainer}>
                        <Text className={styles.memberInfoItemTitle}>Unique Link</Text>
                        <Row align="middle">
                          <Link ellipsis copyable>
                            {`${selectedMemberConversion.uniqueLink} `}
                          </Link>
                        </Row>
                      </Col>
                    )
                  }
                  <Col span={6} className={styles.memberInfoItemContainer}>
                    <Text className={styles.memberInfoItemTitle}>Status</Text>
                    <Row align="middle">
                      <Tag color={getTagType(offer.expired ? OFFER_STATUS_TYPE.EXPIRED : selectedMemberConversion.status as unknown as OFFER_STATUS_TYPE)}>{offer.expired ? OfferMemberStatus.EXPIRED : selectedMemberConversion.status}</Tag>
                    </Row>
                  </Col>
                  <Col span={6} className={styles.memberInfoItemContainer}>
                    <Text className={styles.memberInfoItemTitle}>Current Commission</Text>
                    <Row align="middle">
                      <Tag>
                        {
                          selectedMemberConversion?.payout
                            ? getMultiplePayoutLabel(selectedMemberConversion.payout.label, selectedMemberConversion.payout.payoutType, selectedMemberConversion.payout.flatPayout, selectedMemberConversion.payout.percentPayout)
                            : promoOffer ? getPayoutLabel(promoOffer.payoutType, promoOffer.flatPayout, promoOffer.percentPayout, null) : '-'
                        }
                      </Tag>
                    </Row>
                  </Col>
                </Row>
              )
            }
          </Col>
        </Row>
        <Row className={styles.payoutSummaryContainer}>
          <Col span={24} className={styles.payoutSummaryDescriptionContainer}>
            <div className={styles.payoutSummaryTitle}>
              <CoinsIcon />
              <Text style={{ marginLeft: '8px' }}>Sales Stats</Text>
              {source === OFFER_SOURCE.SHOPIFY && (isMultipleShopifyOffer || isMulticurrency) && (
              <Tooltip title="Sales are calculated based on the currency exchange rates during the time of conversion.">
                <InfoCircleOutlined style={{ marginLeft: '8px' }} />
              </Tooltip>
)}
            </div>
          </Col>
          <PayoutSummaryCard
            source={source}
            blocks={statsSummaryBlocks}
            storeStats={multipleShopifyOfferStats?.data?.affliateStats}
            isEnabledMultipleShopify={isEnabledMultipleShopify}
            isMemberSummary
          />
        </Row>
        <Row>
          <Col span={24}>
            {
              affliateConversionHistory?.data?.affliateConversionHistory && <ConversionTable affiliateConversionHistory={affliateConversionHistory?.data?.affliateConversionHistory} isEnabledMultipleShopify={isEnabledMultipleShopify} source={source} rejectMemberConversions={rejectMemberConversions} refetchData={refetchData} refresh={refresh} />
            }
          </Col>
        </Row>
      </Row>
    </Drawer>
  );
});
