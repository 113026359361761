import * as React from 'react';
import {
 isArray, isEmpty, map, uniqBy,
} from 'lodash';

import { TProgram } from '@components';
import { useProspectsInviteToProgram } from '@frontend/app/hooks';
import {
  getErrorMessageFromGraphQL,
} from '@frontend/utils';

import { useInviteContext } from './useInviteContext';
import { useResources } from './useResources';
import { IInviteProps } from '../Invite';
import {
  getSocialAccountsFromProspects,
  getProspectFromSocialAccount,
} from '../utils';

const { useEffect, useState } = React;

interface IInviteState extends Partial<Pick<IInviteProps, 'prospect'>> {
  isInviting?: boolean;
  isInvited?: boolean;
  programId?: TProgram['id'];
  error?: string | Error;
}

export const useInviteMember = (props: Pick<IInviteProps, 'prospect'>) => {
  const { prospect } = props;

  const {
    selectedProgramId,
  } = useInviteContext();

  const [inviteProspectsToProgram] = useProspectsInviteToProgram();

  const { resourceId } = useResources();
  const [inviteState, setInviteState] = useState<IInviteState>(null);

  /**
   *
   */
  const sendInvite = (id?: TProgram['id']) => {
    if (isEmpty(inviteState) || !inviteState.isInviting) {
      setInviteState(() => ({
        programId: id || selectedProgramId,
        prospect,
      }));
    }
  };

  const fetchInvite = async () => {
    try {
      const accounts = isArray(prospect)
      ? getSocialAccountsFromProspects(...prospect)
      : getSocialAccountsFromProspects(prospect);

      const socialAccounts = uniqBy(
        accounts,
        (account) => account.username || account.link,
      );

      if (isEmpty(socialAccounts)) {
        return null;
      }

      setInviteState((state) => ({
        ...state,
        isInviting: true,
      }));

      await inviteProspectsToProgram({
        variables: {
          prospects: map(socialAccounts, (account) => getProspectFromSocialAccount(account)),
          programIds: [inviteState.programId],
          resourceId,
          source: 'creator_search',
        },
      });

      setInviteState((state) => ({
        ...state,
        isInviting: false,
        isInvited: true,
      }));
    } catch (error) {
      setInviteState({ error: getErrorMessageFromGraphQL(error) });
    }
  };

  useEffect(() => {
    if (!isEmpty(inviteState)
      && !inviteState.isInviting
      && !inviteState.isInvited
      && inviteState.programId
      && inviteState.prospect
    ) {
      fetchInvite();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteState]);

  return {
    ...inviteState,
    sendInvite,
  };
};
