import * as React from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';
import { useLocation } from 'react-router-dom';

import { genericAddEventLog, Notice, LoadSpinner } from '@components';
import { useIsServer, ErrorBoundary } from '@frontend/utils';

import { FilterBox, ISelectFilterOptions } from './Filters/FilterBox';
import SummaryView from './SummaryView/SummaryView';
import BreakdownView from './BreakdownView/BreakdownView';
import PostsView from './PostsView/PostsView';

import { AnalyzeProvider, AnalyzeContext, TAnalyzePage } from './AnalyzeContext';

import styles from './AnalyzePage.scss';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

const {
  useCallback,
  useState,
  useEffect,
} = React;
export interface IAnalyzePageProps {
  apiEndpoint: string;
  backendServerApiEndpoint: string;
  isQa: boolean;
  className?: string;
  organizationId?: string;
  clientId?: string;
  clientStartDate: Date;
  aspirexFilterOptions: ISelectFilterOptions[];
  aspirexAnalytics: SegmentAnalytics.AnalyticsJS;
  showCostMetrics: boolean;
  showAllTimeDefault: boolean;
  headerStickyStyles?: {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
  };
  selectedBrandId?: number;
}

const Page = ({ page, useReportsV2 }: { page: TAnalyzePage, useReportsV2?: boolean | undefined }) => {
  switch (page) {
    case 'summary':
      return <SummaryView />;
    case 'breakdown':
      return <BreakdownView useReportsV2={useReportsV2}/>;
    case 'posts':
      return <PostsView />;
    case 'no data':
      return (
        <Notice type="disabled" className={styles.noDataNotice}>
          There are no campaigns to report on.
          Once creators start completing collaborations, you will see the posts here.
        </Notice>
      );
  }
};

/**
 * @type {React.FunctionComponent}
 */
export const AnalyzePage: React.FunctionComponent<IAnalyzePageProps> = (props) => {
  const {
    aspirexFilterOptions,
    apiEndpoint,
    backendServerApiEndpoint,
    organizationId,
    clientId,
    clientStartDate,
    isQa,
    aspirexAnalytics,
    showCostMetrics,
    showAllTimeDefault,
    headerStickyStyles,
    selectedBrandId,
  } = props;
  const isServer = useIsServer();
  const featureFlagInsightsDateFilter = useClientFeatureEnabled(ClientFeature.SPA_INSIGHTS_DATE_FILTER);
  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);

  // TODO(jb): Resolve issues with user level feature flags
  // const socialReportsV2FeatureFlag = useClientFeatureEnabled(ClientFeature.SOCIAL_REPORTS_V2);

  // TODO(jb): this is a temporary solution to allow for the use of reports v2 without enabling it for all client users
  const [useReportsV2, setUseReportsV2] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const useReportsV2Param = params.get('useReportsV2') === 'true';
    setUseReportsV2(useReportsV2Param);
  }, [window.location.search]);

  const [page, setPage] = useState<TAnalyzePage>('summary');
  const changePage = useCallback((currentPage: TAnalyzePage) => {
    const previousPage = page;
    setPage(currentPage);
    genericAddEventLog(
      'analyze_change_page',
      {
        previous_page: previousPage,
        current_page: currentPage,
      },
      aspirexAnalytics,
    );
  }, [aspirexAnalytics, page]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const socialsTabPage = (searchParams.get('socialsTabPage')) as TAnalyzePage;

  useEffect(() => {
    if (location.search && !isNull(socialsTabPage) ) {
      setPage(socialsTabPage);
    } else {
      setPage('summary');
    }
  }, [
    location,
    setPage,
    socialsTabPage,
  ]);

  if (isServer) {
    return <LoadSpinner centered />;
  }
  return (
    <div className={cx(styles.AnalyzePage, props.className)}>
      <ErrorBoundary>
        <AnalyzeProvider
          apiEndpoint={apiEndpoint}
          backendServerApiEndpoint={backendServerApiEndpoint}
          organizationId={organizationId}
          clientId={clientId}
          clientStartDate={clientStartDate}
          currentPage={page}
          isQa={isQa}
          showCostMetrics={showCostMetrics}
          showAllTimeDefault={showAllTimeDefault}
          aspirexAnalytics={aspirexAnalytics}
          selectedBrandId={selectedBrandId}
          insightFeatureFlag={featureFlagInsightsDateFilter}
          tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
        >
          <AnalyzeContext.Consumer>
            {() => (
              <>
                <div
                  className={cx(styles.headerWrapper, { [styles.sticky]: !!headerStickyStyles })}
                  style={headerStickyStyles}
                >
                  <FilterBox
                    aspirexFilterOptions={aspirexFilterOptions}
                    onNoData={setPage}
                    tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
                  />
                  <div className={styles.header}>
                    <div className={styles.navbar}>
                      <div
                        className={cx(styles.navItem, {
                            [styles.active]: page === 'summary',
                          })}
                        onClick={() => changePage('summary')}
                      >
                        Summary
                      </div>
                      <div
                        className={cx(styles.navItem, {
                            [styles.active]: page === 'breakdown',
                          })}
                        onClick={() => changePage('breakdown')}
                      >
                        Breakdown
                      </div>
                      <div
                        className={cx(styles.navItem, {
                            [styles.active]: page === 'posts',
                          })}
                        onClick={() => changePage('posts')}
                      >
                        Posts
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.children}>
                  <Page page={page} useReportsV2={useReportsV2} />
                </div>
              </>
              )}
          </AnalyzeContext.Consumer>
        </AnalyzeProvider>
      </ErrorBoundary>
    </div>
  );
};
