import * as React from 'react';
import cx from 'classnames';
import { RawDraftContentState } from 'draft-js';
import {
  filter, first, includes, isArray,
  isEmpty, isNull, isString, map,
  toLower, trim, isNil,
} from 'lodash';

import {
 Popover, Typography, Input, Button, Tooltip,
} from 'antd';
import { SettingFilled } from '@ant-design/icons';
import { logger } from '@common';
import {
  IconButton, SearchIcon, LoadSpinner,
  Button as WidgetsButton,
} from '@components';
import { EmailEditor, IEditor, DocumentIcon } from '@frontend/app/components';

import { IMessageTemplate, useGetMessageTemplates } from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';

import { TemplateType } from '@frontend/app/types/globalTypes';
import styles from './TemplateSelector.module.scss';

const {
 useState, useRef, useMemo, useEffect,
} = React;
const { Text } = Typography;

interface IProps {
  onTemplateSelected(template: IMessageTemplate): void;
  className?: string;
  children?: React.ReactNode;
}
const isRawDraftContentState = (input: unknown): input is RawDraftContentState => {
  if (typeof input !== 'object' || input === null) {
    return false;
  }
  if (('blocks' in input && 'entityMap' in input)) {
    const i = input as RawDraftContentState;
    if (!isArray(i.blocks)) {
        return false;
    }
    return typeof i.entityMap === 'object';
  }
    return false;
};

/**
 * @type {React.FC}
 */
export const TemplateSelector: React.FC<IProps> = React.memo((props) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const editorRef = useRef<IEditor>(null);
  const { clientInfo } = useAuth();
  const {
    loading,
    templates,
  } = useGetMessageTemplates();
  const [selectedTemplate, setSelectedTemplate] = useState<IMessageTemplate>(null);

  const editorInitialValue = useMemo((): string | RawDraftContentState => {
    if (isNull(selectedTemplate) || !isString(selectedTemplate.text)) {
      return '';
    }
    try {
      const template = JSON.parse(selectedTemplate.text);
      if (isRawDraftContentState(template)) {
        return template;
      }
      throw new Error('Template does not match raw draft content state.');
    } catch (err) {
      logger.error(err);
      return '';
    }
  }, [selectedTemplate]);

  const filteredTemplates = useMemo(() => {
    const availableTemplates = filter(templates, (t) => {
      if (
        [
          TemplateType.APPLICANT_REJECTED_FOLLOW_UP,
          TemplateType.BRIEF_ACCEPTED_FOLLOW_UP,
          TemplateType.BRIEF_REMINDER,
          TemplateType.CONTENT_POSTED_FOLLOW_UP,
          TemplateType.FIRST_SALES_CONVERSION_FOLLOW_UP,
          TemplateType.INVITE_REMINDER,
          TemplateType.ORDER_DELIVERED_FOLLOW_UP,
          TemplateType.ORDER_SELECTION_REMINDER,
        ].includes(t.type)) { // filter out automations templates
        return false;
      }
      if (isNil(t.programId)) { // filter out program templates
        return true;
      }
      return false;
    });

    if (!isEmpty(value)) {
      const loweredValue = toLower(trim(value));
      return filter(
        availableTemplates,
        (t) => includes(toLower(trim(t.title)), loweredValue)
          || includes(toLower(trim(t.subject)), loweredValue),
      );
    }

    return availableTemplates;
  }, [templates, value]);
  useEffect(() => {
    if (!isEmpty(filteredTemplates) && !selectedTemplate) {
      setSelectedTemplate(first(filteredTemplates));
    }
  }, [filteredTemplates, selectedTemplate, setSelectedTemplate]);

  const closePopover = () => {
    setVisible(false);
    setValue('');
  };
  const goToTemplatesPage = () => {
    window.open(`${window.location.origin}/client/${clientInfo.id}/settings/messageTemplates`, '_blank');
  };

  return (
    <Popover
      overlayClassName={styles.Popover}
      content={(
        <div className={styles.PopoverContent}>
          {loading && <LoadSpinner />}
          {!loading && (
            <>
              <div className={styles.header}>
                <div className={styles.title}>Templates</div>
                <Button
                  className={styles.settingsIcon}
                  icon={<SettingFilled />}
                  onClick={goToTemplatesPage}
                  type="link"
                />
              </div>
              <Input
                className={styles.search}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                size="middle"
                placeholder="Search..."
                spellCheck={false}
                prefix={<SearchIcon size={20} className={styles.icon} />}
              />
              <div className={styles.main}>
                <div className={styles.list}>
                  {map(filteredTemplates, (template) => (
                    <div
                      key={template.id}
                      className={cx(styles.item, {
                          [styles.selected]: selectedTemplate?.id === template.id,
                        })}
                      onClick={() => setSelectedTemplate(template)}
                    >
                      <Text className={styles.text} ellipsis>{template.title}</Text>
                    </div>
                    ))}
                </div>
                {!!selectedTemplate && (
                  <div className={styles.content}>
                    <div className={styles.subject}>
                      <Text ellipsis className={styles.subjectText}>{selectedTemplate.subject}</Text>
                    </div>
                    <EmailEditor
                      editorRef={editorRef}
                      className={styles.editor}
                      initialValue={editorInitialValue}
                      readOnly
                      disabled
                    />
                    <div className={styles.actions}>
                      <WidgetsButton
                        className={styles.button}
                        label="Use this template"
                        theme="primary"
                        disabled={!selectedTemplate}
                        onClick={() => {
                          props.onTemplateSelected(selectedTemplate);

                          closePopover();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
      trigger="click"
      placement="topRight"
      visible={visible}
      onVisibleChange={(visible) => {
        if (visible) {
          setVisible(true);
        } else {
          closePopover();
        }
      }}
    >
      {props.children ? props.children : (
        <Tooltip title="Select Email Template">
          <IconButton
            className={cx(styles.TemplateSelector, props.className)}
            icon={<DocumentIcon className={styles.icon} size={24} />}
          />
        </Tooltip>
)}
    </Popover>
  );
});

TemplateSelector.defaultProps = {
  className: null,
};

TemplateSelector.displayName = 'TemplateSelector';
