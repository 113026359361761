import React from 'react';
import { Card, Space, Typography } from '@revfluence/fresh';
import { ArrowRightIcon, BookIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './EmptyScreen.scss';

const { Title, Text, Link } = Typography;

export const EmptyScreen = () => (
  <Card className={styles.EmptyScreen}>
    <Space direction="vertical" size={12} align="center">
      <BookIcon fontSize={80} className={styles.bookIcon} />
      <Space direction="vertical" size={0}>
        <Title level={5}>Ready to Build Your Catalog? Start with Product Import.</Title>
        <Text className={styles.subTitle}>
          Simply start by importing your initial product and we'll automatically generate a default catalog for all your
          products. Want to organize your products differently? You're free to create and customize additional catalogs
          anytime.
        </Text>
      </Space>
      <Link>
        Go to import products
        {' '}
        <ArrowRightIcon />
      </Link>
    </Space>
  </Card>
);
