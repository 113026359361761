import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const CheckboxIndeterminateIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <defs>
      <path id="a" d="M0 0h24v24H0z" />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z" />
  </SvgIcon>
));

CheckboxIndeterminateIcon.displayName = 'CheckboxIndeterminateIcon';

export { CheckboxIndeterminateIcon };
