import * as React from 'react';
import { Checkbox } from '@components';

import styles from '../Filters.scss';

export const CampaignItem = ({ campaign, dispatch, brandId }) => (
  <li
    style={{ paddingLeft: 0 }}
    className={styles.MenuItem}
    onClick={() =>
        dispatch({ type: 'TOGGLE_CAMPAIGN', payload: { ...campaign, brandId } })}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={campaign.selected}
        onClick={() =>
            dispatch({
              type: 'TOGGLE_CAMPAIGN',
              payload: { ...campaign, brandId },
            })}
      />
      <img className={styles.BrandFilterImages} src={campaign.image_url} />
      {campaign.name}
    </div>
  </li>
  );
