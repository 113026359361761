import * as React from 'react';
import { isFunction, map, size } from 'lodash';

import { CreatorTile } from '@components';
import { LoadSpinner } from '@components';

import { useSimilarCreators } from '../hooks/useSimilarCreators';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './SimilarCreatorList.scss';

export const SimilarCreatorList: React.FC = () => {
  const {
    allowFavorite,
    apiEndpoint,
    campaign,
    isQa,
    onCreatorSelected,
    showCreateFeatures,
    socialAccount,
    pushHistory,
    setSocialAccount,
  } = useSocialProfileContext();

  const loadSimilarCreators = socialAccount.has_lookalikes === true;

  const {
    similarCreators,
    isFetching,
    error,
  } = useSimilarCreators({
    apiEndpoint,
    id: loadSimilarCreators ? socialAccount.id : null,
  });

  const renderSimilarCreators = () => (
    <>
      <header>
        <h4>
          {!isFetching && !error && size(similarCreators)
          ? 'Creators based on similar post content'
          : 'Similar creators are not available for this account'}
        </h4>
      </header>
      <div className={styles.creatorList}>
        {map(similarCreators, (creator, index) => (
          <CreatorTile
            key={`similar-creator-${index}`}
            className={styles.creatorTile}
            socialAccount={creator}
            darkMode
            campaign={campaign}
            apiEndpoint={apiEndpoint}
            favoriteLists={[]}
            inviteToCampaign={null}
            onCreatorSelected={() => {
            if (creator.id !== socialAccount.id) {
              pushHistory(socialAccount);
              setSocialAccount(creator);
              if (isFunction(onCreatorSelected)) {
                onCreatorSelected(creator);
              }
            }
          }}
            reportAsIncorrect={null}
            isQa={isQa}
            allowFavorite={allowFavorite}
            showCreateFeatures={showCreateFeatures}
            showInviteButton={false}
            showOfferButton={false}
            showHideButton={false}
            showRelevantPostImage={false}
          />
      ))}
      </div>
    </>
);

  return loadSimilarCreators && (
    <div className={styles.SimilarCreatorList}>
      {isFetching
        ? <LoadSpinner className={styles.loadSpinner} type="spinner" />
        : renderSimilarCreators()}
    </div>
  );
};

SimilarCreatorList.displayName = 'SimilarCreatorList';
