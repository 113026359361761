import * as React from 'react';
import { Radio } from 'antd';
import {
  Form,
  Typography,
} from '@revfluence/fresh';

import { IShopifyFormElementProps, PurchaseType } from '../../../types';
import styles from '../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'purchaseType'> {
}

const { Text } = Typography;
export const OfferPurchaseType: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please select Purchase Type' },
  ];
  return (
    <>
      <div className={styles.customLabelText}>
        <Text strong>Choose a purchase type for this offer</Text>
      </div>
      <Form.Item name={name} rules={disabled ? [] : rules}>
        <Radio.Group name="radiogroup">
          <Radio value={PurchaseType.ONE_TIME} disabled={disabled}>{PurchaseType.ONE_TIME}</Radio>
          <Radio value={PurchaseType.SUBSCRIPTION} disabled={disabled}>{PurchaseType.SUBSCRIPTION}</Radio>
          <Radio value={PurchaseType.BOTH} disabled={disabled}>{PurchaseType.BOTH}</Radio>
        </Radio.Group>
      </Form.Item>

    </>
  );
});

OfferPurchaseType.displayName = 'OfferPurchaseType';
