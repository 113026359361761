import { assign } from 'lodash';
import {
  IAttachmentUploaderAction,
  IAttachmentUploader,
  EmailAttachmentActionTypes,
} from './model';

export const reducer = (state: IAttachmentUploader, action: IAttachmentUploaderAction): IAttachmentUploader => {
  switch (action.type) {
    case EmailAttachmentActionTypes.REMOVE_CONTENTS: {
      return assign({}, state, {
        contents: [],
      });
    }

    case EmailAttachmentActionTypes.ADD_CONTENT: {
      const { content } = action.payload;
      const { contents, maxContents } = state;
      const contentIndex = contents.findIndex((c) => c.id === content.id);
      if (contentIndex >= 0) {
        return state;
      }

      return assign({}, state, {
        contents: [
          ...state.contents,
          {
            ...content,
            progress: {
              percentage: 0,
              uploaded: 0,
            },
          },
        ],
        limitReached: maxContents && contents.length + 1 >= maxContents,
      });
    }

    case EmailAttachmentActionTypes.REMOVE_CONTENT: {
      const { id } = action.payload;
      const { contents, maxContents } = state;
      const contentIndex = contents.findIndex((content) => content.id === id);

      // NOTE: Do nothing when `id` doesn't exist -- it may have already been removed before
      // This could happen when REMOVE_CONTENT is called twice with the same `id`
      if (contentIndex < 0) {
        return state;
      }

      // note that we cannot simply mutating the 'contents' from state (using splice)
      // and clone is slow
      return assign({}, state, {
        contents: [...contents.slice(0, contentIndex), ...contents.slice(contentIndex + 1)],
        limitReached: maxContents && contents.length - 1 >= maxContents,
      });
    }

    case EmailAttachmentActionTypes.UPDATE_PROGRESS: {
      const { id, progress } = action.payload;
      const { contents } = state;
      const content = contents.find((content) => content.id === id);
      const contentIndex = contents.indexOf(content);

      if (!content) {
        // throw new Error(`Couldn't find content with id: ${id}`);

        // do nothing
        return assign({}, state);
      }

      return assign({}, state, {
        contents: [
          ...contents.slice(0, contentIndex),
          {
            ...content,
            progress,
          },
          ...contents.slice(contentIndex + 1),
        ],
      });
    }

    case EmailAttachmentActionTypes.UPDATE_FILE_URL: {
      const { id, fileUrl } = action.payload;
      const { contents } = state;
      const content = contents.find((content) => content.id === id);
      const contentIndex = contents.indexOf(content);

      if (!content) {
        // throw new Error(`Couldn't find content with id: ${id}`);

        // do nothing
        return assign({}, state);
      }

      return assign({}, state, {
        contents: [
          ...contents.slice(0, contentIndex),
          {
            ...content,
            fileUrl,
          },
          ...contents.slice(contentIndex + 1),
        ],
      });
    }

    case EmailAttachmentActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };

    default:
      return state;
  }
};
