import 'css-chunk:src/app/containers/MessagingApp/MessageList/ThreadAssignees/styles.scss';export default {
  "ThreadAssignees": "_ThreadAssignees_tluz4_1",
  "popoverTrigger": "_popoverTrigger_tluz4_1",
  "editIcon": "_editIcon_tluz4_1",
  "header": "_header_tluz4_11",
  "sharedEmail": "_sharedEmail_tluz4_24",
  "popoverContent": "_popoverContent_tluz4_28",
  "popoverList": "_popoverList_tluz4_32",
  "popoverElementWrapper": "_popoverElementWrapper_tluz4_41",
  "popoverElement": "_popoverElement_tluz4_41"
};