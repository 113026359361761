import * as React from 'react';
import { get, debounce, isEmpty, isNil } from 'lodash';

import { SearchIcon, Input, InviteContext, IResource } from '@components';
import { FilterSection } from '../FilterSection';
import EngagementSection from './EngagementSection';
import OtherSection from './OtherSection';
import { NetworkSection } from './NetworkSection';
import CreatorDemographicsSection from './CreatorDemographicsSection';
import AudienceDemographicsSection from './AudienceDemographicsSection';
import { InvitesResourcesPicker } from './InvitesResourcesPicker';
import { IQUsernameKeywordsFilter } from './IQUsernameKeywordsFilter';
import {
  ICreatorSearchFilters,
  ICreatorSearchOptions,
  INetworkFilters,
  IEngagementFilters,
  ICreatorDemographicFilters,
  IAudienceDemographicFilters,
  IOtherFilters,
  SearchTermType,
  CreatorSearchVersion,
  TRange,
  IContactFilters,
  IRelationshipFilters,
} from '../models';
import { ContactSection } from './ContactSection';
import { RelationshipFilters } from './RelationshipSection';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

const { useCallback, useContext, useMemo, useRef } = React;

export interface ICreatorSearchFiltersProps {
  filters: ICreatorSearchFilters;
  options: ICreatorSearchOptions;
  onChange(newFilters: ICreatorSearchFilters);
  selfServeExperiment: boolean;
  shouldShowDemoFeatures?: boolean;
  performSearch?: (version: CreatorSearchVersion) => void;
  className?: string;
  isInstagramDMEnabled: boolean;
  emailResources?: IResource[];
  selectedEmailId?: number;
  onSelectEmailId?: (resourceId: number) => void;
}

export const MAX_LOCATIONS_LIMIT = 40;

export const CreatorSearchFilters: React.FunctionComponent<ICreatorSearchFiltersProps> = React.memo((props) => {
  const {
    className,
    filters,
    onChange,
    performSearch,
    options,
    selfServeExperiment,
    shouldShowDemoFeatures = true,
    isInstagramDMEnabled,
    emailResources = [],
    selectedEmailId,
    onSelectEmailId,
  } = props;

  const channel = useMemo(() => get(filters, 'network.channel'), [filters]);

  const isRelationshipFiltersEnabled = useClientFeatureEnabled(ClientFeature.CREATOR_SEARCH_RELATIONSHIP_FILTERS)
    && !['tiktok', 'youtube'].includes(channel);

  const {
    versionByNetwork,
  } = useContext(InviteContext);

  const hideEngagementRangeFilter = useMemo(() => (
    ['tiktok', 'pinterest', 'blog'].includes(filters.network?.channel)
  ), [filters.network?.channel]);

  const isNetworkSearchV2 = useMemo(() => (
    versionByNetwork[filters.network?.channel] === CreatorSearchVersion.v2
  ), [
    filters.network?.channel,
    versionByNetwork
  ]);

  const hideImpressionRangeFilter = useMemo(() => (
    filters.network?.channel !== 'tiktok'
  ), [filters.network?.channel]);

  const queryDebounce = useRef(debounce((value: string) => {
    onChange({
      query: value,
    });
    /** Set debounce 300ms below if real-time search gets re-enabled  */
  }, 0));

  const handleChangeSearchInput = useCallback((value: string) => {
    if (!isNetworkSearchV2) {
      queryDebounce.current(value);
      return;
    }

    onChange({
      query: value,
    });
  }, [isNetworkSearchV2, queryDebounce]);

  const handleChangeQueryTermType = useCallback((termType: SearchTermType) => {
    let query = filters.query;
    if (termType === SearchTermType.Topics) {
      query = '';
    }

    onChange({
      ...filters,
      termType,
      query,
    });
  }, [onChange, filters]);

  const handleChangeNetwork = useCallback((network: INetworkFilters) => {
    const newFilters = {
      ...filters,
      termType: null,
      network,
    }

    if (!isNil(newFilters.creatorDemographics) && !isEmpty(newFilters.creatorDemographics?.locations)) {
      newFilters.creatorDemographics.locations = [];
    }

    if (['pinterest', 'blog'].includes(network?.channel)) {
      const {
        engagement: {
          engagementLimitsByNetwork = {},
          impressionLimitsByNetwork = {},
        } = {},
      } = options;
      newFilters.engagement.engagementRange = engagementLimitsByNetwork[network?.channel] || ([] as unknown as TRange);
      newFilters.engagement.impressionRange = impressionLimitsByNetwork[network?.channel] || ([] as unknown as TRange);
    }
    onChange(newFilters);
  }, [onChange, filters]);

  const handleChangeEngagement = useCallback((engagement: IEngagementFilters) => {
    onChange({
      ...filters,
      engagement: {
        ...filters.engagement,
        ...engagement,
      },
    });
  }, [onChange, filters]);

  const handleChangeCreatorDemographics = useCallback((creatorDemographics: ICreatorDemographicFilters) => {
    onChange({
      ...filters,
      creatorDemographics: {
        ...filters.creatorDemographics,
        ...creatorDemographics,
      },
    });
  }, [onChange, filters]);

  const handleChangeAudienceDemographics = useCallback((audienceDemographics: IAudienceDemographicFilters) => {
    onChange({
      ...filters,
      audienceDemographics: {
        ...filters.audienceDemographics,
        ...audienceDemographics,
      },
    });
  }, [onChange, filters]);

  const handleChangeOtherFilters = useCallback((otherFilters: IOtherFilters) => {
    onChange({
      ...filters,
      other: {
        ...filters.other,
        ...otherFilters,
      },
    });
  }, [onChange, filters]);

  const handleChangeContactFilters = useCallback((contactFilters: IContactFilters) => {
    onChange({
      ...filters,
      contact: {
        ...filters.contact,
        ...contactFilters,
      },
    });
  }, [onChange, filters]);

  const handleChangeRelationship = useCallback((relationshipFilters: IRelationshipFilters) => {
    onChange({
      ...filters,
      relationships: {
        ...filters.relationships,
        ...relationshipFilters,
      },
    });
  }, [onChange, filters]);

  const handlePerformSearch = useCallback(() => {
    const version = isNetworkSearchV2
      ? CreatorSearchVersion.v2
      : CreatorSearchVersion.v1;

    performSearch?.(version);
  }, [isNetworkSearchV2, performSearch]);



  return (
    <div className={className}>
      <NetworkSection
        {...filters.network}
        {...options.network}
        onChange={handleChangeNetwork}
        shouldShowDemoFeatures={shouldShowDemoFeatures}
      />

      {
        isInstagramDMEnabled
        && (
          <InvitesResourcesPicker
            channel={filters.network?.channel}
            emailResources={emailResources}
            selectedEmailId={selectedEmailId}
            onSelectEmailId={onSelectEmailId}
          />
        )
      }

      <FilterSection>
        {
          isNetworkSearchV2
            ? (
              <IQUsernameKeywordsFilter
                network={filters.network.channel}
                query={filters.query}
                termType={filters.termType}
                onPerformSearch={handlePerformSearch}
                onQueryChange={handleChangeSearchInput}
                onTermTypeChange={handleChangeQueryTermType}
              />
            )
            : (
              <Input
                placeholder="Search username, keywords & more…"
                {...options.query}
                buffer={undefined}
                value={filters.query}
                onChange={handleChangeSearchInput}
                icon={<SearchIcon />}
                onPressEnter={handlePerformSearch}
              />
            )
        }
      </FilterSection>

      <ContactSection
        {...options.contact}
        {...filters.contact}
        channel={channel}
        onChange={handleChangeContactFilters}
        hide={!isInstagramDMEnabled || channel !== 'instagram'}
      />

      <EngagementSection
        {...options.engagement}
        {...filters.engagement}
        network={channel}
        showIQDataFilters={isNetworkSearchV2}
        onChange={handleChangeEngagement}
        hideEngagement={hideEngagementRangeFilter}
        hideImpression={hideImpressionRangeFilter}
      />

      <CreatorDemographicsSection
        {...options.creatorDemographics}
        {...filters.creatorDemographics}
        isIQSearch={isNetworkSearchV2}
        showIQDataFilters={isNetworkSearchV2}
        network={channel}
        onChange={handleChangeCreatorDemographics}
        shouldShowDemoFeatures={shouldShowDemoFeatures}
      />

      <AudienceDemographicsSection
        {...options.audienceDemographics}
        {...filters.audienceDemographics}
        showIQDataFilters={isNetworkSearchV2}
        network={channel}
        onChange={handleChangeAudienceDemographics}
        shouldShowDemoFeatures={shouldShowDemoFeatures}
      />

      {
        isRelationshipFiltersEnabled
          && (
            <RelationshipFilters
              {...filters.relationships}
              onChange={handleChangeRelationship}
            />
          )
      }

      <OtherSection
        {...options.other}
        {...filters.other}
        selfServeExperiment={selfServeExperiment}
        showIQDataFilters={isNetworkSearchV2}
        onChange={handleChangeOtherFilters}
        shouldShowDemoFeatures={shouldShowDemoFeatures}
        isInstagramDMEnabled={isInstagramDMEnabled}
        channel={get(filters, 'network.channel')}
      />
    </div>
  );
});
