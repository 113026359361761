/**
 * Checks if a string is a valid HTTP/HTTPS URL.
 *
 * Tries to create a URL object and validates its protocol.
 * Returns true for 'http:' or 'https:' protocols, false otherwise.
 *
 * @param {string} value - The string to validate.
 * @returns {boolean} - Whether the string is a valid HTTP/HTTPS URL.
 */
export function isValidHttpUrl(value: string): boolean {
  try {
    const url = new URL(value);
    return ['http:', 'https:'].includes(url.protocol);
  } catch (error) {
    return false;
  }
}
