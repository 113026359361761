/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_18ft6_157 {
  display: flex;
}

._justify-content-space-between_18ft6_161 {
  justify-content: space-between;
}

._tabular-nums_18ft6_165 {
  font-variant-numeric: tabular-nums;
}

._AvatarIcon_18ft6_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_18ft6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._AvatarIcon_18ft6_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._AvatarIcon_18ft6_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._AvatarIcon_18ft6_169 ::-webkit-scrollbar-track, ._AvatarIcon_18ft6_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._AvatarIcon_18ft6_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AvatarIcon_18ft6_169 {
  border-radius: 50%;
  overflow: hidden;
}
._AvatarIcon_18ft6_169._extraSmall_18ft6_286 {
  width: 1rem;
  height: 1rem;
  font-size: 0.5rem;
}
._AvatarIcon_18ft6_169._small_18ft6_291 {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.75rem;
}
._AvatarIcon_18ft6_169._medium_18ft6_296 {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.25rem;
}
._AvatarIcon_18ft6_169._large_18ft6_301 {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
._AvatarIcon_18ft6_169._extraLarge_18ft6_306 {
  width: 8rem;
  height: 8rem;
  font-size: 4rem;
}
._AvatarIcon_18ft6_169 > ._image_18ft6_311,
._AvatarIcon_18ft6_169 > ._initial_18ft6_312 {
  width: 100%;
  height: 100%;
}
._AvatarIcon_18ft6_169 > ._image_18ft6_311 {
  object-fit: cover;
  background-color: #e9e8ea;
}
._AvatarIcon_18ft6_169 > ._initial_18ft6_312 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9e8ea;
  color: #3996e0;
  font-size: inherit;
}