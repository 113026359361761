._Content_1tmvx_1 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
._Content_1tmvx_1 ._content_1tmvx_7 {
  width: 100%;
  height: 100%;
  will-change: filter, transform;
  transform-origin: center center;
  transition: all 0.2s ease;
}
._Content_1tmvx_1 ._content_1tmvx_7._hover_1tmvx_14 {
  background-color: #999;
  filter: brightness(0.5);
  transform: scale(1.05, 1.05);
}
._Content_1tmvx_1 ._content_1tmvx_7._clickable_1tmvx_19 {
  cursor: pointer;
}
._Content_1tmvx_1 ._info_1tmvx_22 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}
._Content_1tmvx_1 ._info_1tmvx_22._small_1tmvx_32 {
  padding: 8px 6px;
  font-size: 13px;
}
._Content_1tmvx_1 ._info_1tmvx_22._small_1tmvx_32 ._fileType_1tmvx_36 {
  margin-right: 4px;
  width: 20px;
}
._Content_1tmvx_1 ._info_1tmvx_22._medium_1tmvx_40 {
  padding: 12px 10px;
  font-size: 15px;
}
._Content_1tmvx_1 ._info_1tmvx_22._medium_1tmvx_40 ._fileType_1tmvx_36 {
  margin-right: 6px;
  width: 24px;
}
._Content_1tmvx_1 ._info_1tmvx_22._medium_1tmvx_40 ._actions_1tmvx_48 {
  bottom: 16px;
}
._Content_1tmvx_1 ._info_1tmvx_22._large_1tmvx_51 {
  padding: 16px 14px;
  font-size: 20px;
  line-height: 24px;
}
._Content_1tmvx_1 ._info_1tmvx_22._large_1tmvx_51 ._fileType_1tmvx_36 {
  margin-right: 8px;
  width: 36px;
}
._Content_1tmvx_1 ._info_1tmvx_22._large_1tmvx_51 ._actions_1tmvx_48 {
  bottom: 30px;
}
._Content_1tmvx_1 ._info_1tmvx_22 ._fileType_1tmvx_36 {
  color: rgb(57, 122, 242);
}
._Content_1tmvx_1 ._info_1tmvx_22 ._fileType_1tmvx_36._image_1tmvx_66 {
  color: rgb(251, 179, 9);
}
._Content_1tmvx_1 ._info_1tmvx_22 ._fileType_1tmvx_36._pdf_1tmvx_69 {
  color: rgb(231, 58, 47);
}
._Content_1tmvx_1 ._info_1tmvx_22 ._fileType_1tmvx_36._video_1tmvx_72 {
  color: rgb(231, 58, 47);
}
._Content_1tmvx_1 ._info_1tmvx_22 ._name_1tmvx_75 {
  flex: 1;
  word-break: break-all;
  color: white;
  font-weight: bold;
}
._Content_1tmvx_1 ._info_1tmvx_22 ._actions_1tmvx_48 {
  position: absolute;
  bottom: 10px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: auto;
}
._Content_1tmvx_1 ._info_1tmvx_22 ._actions_1tmvx_48 ._zoomIcon_1tmvx_92 {
  margin-right: 10px;
}
._Content_1tmvx_1 ._info_1tmvx_22 ._link_1tmvx_95 {
  display: none;
}