/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_18mnl_157 {
  display: flex;
}

._justify-content-space-between_18mnl_161 {
  justify-content: space-between;
}

._tabular-nums_18mnl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_18mnl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ListView_18mnl_178 ._header_18mnl_178 ._item_18mnl_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ListView_18mnl_178 ._header_18mnl_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.125rem;
  padding: 0 1.5rem;
}
._ListView_18mnl_178 ._header_18mnl_178 ._item_18mnl_178 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.75rem;
  color: #8f8d91;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._ListView_18mnl_178 ._header_18mnl_178 ._item_18mnl_178::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  padding-top: 2.9375rem;
  border-bottom: 0.125rem solid #3996e0;
  transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1);
  transform: scaleX(0);
  transform-origin: center;
}
._ListView_18mnl_178 ._header_18mnl_178 ._item_18mnl_178:hover {
  color: #4fa7ee;
}
._ListView_18mnl_178 ._header_18mnl_178 ._item_18mnl_178._active_18mnl_253 {
  color: #3996e0;
}
._ListView_18mnl_178 ._header_18mnl_178 ._item_18mnl_178._active_18mnl_253::after {
  transform: scaleX(1);
}
._ListView_18mnl_178 ._header_18mnl_178 ._moreFields_18mnl_259 {
  margin: 0 0.75rem;
}
._ListView_18mnl_178 ._header_18mnl_178 ._moreFields_18mnl_259 ._button_18mnl_262 {
  border: 0;
  color: #8f8d91;
}
._ListView_18mnl_178 ._header_18mnl_178 ._moreFields_18mnl_259._active_18mnl_253 ._button_18mnl_262 {
  color: #3996e0;
}
._ListView_18mnl_178 ._list_18mnl_269 {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 20rem;
}