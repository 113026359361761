import 'css-chunk:src/applications/SocialPostApp/components/AssignPostTile.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_frl6a_157",
  "justify-content-space-between": "_justify-content-space-between_frl6a_161",
  "tabular-nums": "_tabular-nums_frl6a_165",
  "AssignPostTile": "_AssignPostTile_frl6a_178",
  "image": "_image_frl6a_186",
  "video": "_video_frl6a_187",
  "media": "_media_frl6a_190",
  "assignButton": "_assignButton_frl6a_196",
  "imageContainer": "_imageContainer_frl6a_207",
  "checkbox": "_checkbox_frl6a_225",
  "search": "_search_frl6a_230",
  "details": "_details_frl6a_237",
  "avatar": "_avatar_frl6a_244",
  "avatarImage": "_avatarImage_frl6a_251",
  "info": "_info_frl6a_255",
  "name": "_name_frl6a_266",
  "date": "_date_frl6a_283",
  "actions": "_actions_frl6a_286",
  "visit": "_visit_frl6a_292",
  "TagList": "_TagList_frl6a_301",
  "tag": "_tag_frl6a_307",
  "networkIcon": "_networkIcon_frl6a_323",
  "avatarContainer": "_avatarContainer_frl6a_338",
  "show": "_show_frl6a_1"
};