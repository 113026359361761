import 'css-chunk:src/app/components/MemberTable/CustomCells/TokenCell/TokenCell.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_12d07_157",
  "justify-content-space-between": "_justify-content-space-between_12d07_161",
  "tabular-nums": "_tabular-nums_12d07_165",
  "TokenCell": "_TokenCell_12d07_178",
  "right": "_right_12d07_185",
  "btn": "_btn_12d07_188",
  "disabled": "_disabled_12d07_194",
  "value": "_value_12d07_198",
  "listValue": "_listValue_12d07_219",
  "singleValue": "_singleValue_12d07_229",
  "tokenTitle": "_tokenTitle_12d07_235",
  "valuesList": "_valuesList_12d07_241",
  "divider": "_divider_12d07_258",
  "all": "_all_12d07_262",
  "save": "_save_12d07_279",
  "emptyButton": "_emptyButton_12d07_286",
  "muted": "_muted_12d07_294",
  "show": "_show_12d07_1"
};