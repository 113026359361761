import { TBudgetAccount } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
    budgetAccounts: TBudgetAccount[];
    type: ActionType.SET_BUDGET_ACCOUNTS;
}
export type TSetBudgetAccountAction = Readonly<IAction>;

export const setBudgetAccounts = (state: TState, action: TSetBudgetAccountAction): TState => ({
    ...state,
    budgetAccounts: action.budgetAccounts,
});
