import * as React from 'react';
import {
  Col,
  Form,
  Input,
  Row,
} from '@revfluence/fresh';

import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { ICommonFormElementProps } from '../../../types';
import { validateNonNegativeDecimal } from '../../../utils';

interface IProps extends ICommonFormElementProps<'flatPayout'> { }

export const OfferPayoutClickAmount: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your offer payout!' },
    { validator: validateNonNegativeDecimal },
  ];
  return (
    <Row>
      <Col span={24}>
        <Form.Item label="Payout per Click" name={name} rules={rules}>
          <Input
            data-testid={testIds.clickCost}
            disabled={disabled}
            suffix="$"
            size="large"
          />
        </Form.Item>
      </Col>
    </Row>
  );
});
