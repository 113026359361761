import gql from 'graphql-tag';

export const GET_MEMBER_INFO_FOR_OFFER = gql`
  query GetMemberInfoForOffer(
    $membersIds: [Int!]
    $offerId: Int!
    $programIds: [Int!]
  ) {
    memberInfoForOffer(
    memberIds: $membersIds
    offerId: $offerId
    programIds: $programIds
    ) {
      inOffer
      previouslyUnsuccessful
      instagramUsername
      memberFirstName
      memberId
      memberLastName
      memberName
      programIds
    }
  }
`;
