import { useQuery, QueryHookOptions } from '@apollo/client';

import { MEMBER_COMMENTS } from '@frontend/app/queries';
import {
  MemberCommentsQuery,
  MemberCommentsQueryVariables,
} from '@frontend/app/queries/types/MemberCommentsQuery';

type IOptions = QueryHookOptions<MemberCommentsQuery, MemberCommentsQueryVariables>;

export const useMemberCommentsQuery = (memberId: number, options: IOptions = {}) => useQuery(MEMBER_COMMENTS, {
    ...options,
    variables: {
      memberId,
    },
    skip: !memberId || options.skip,
  });
