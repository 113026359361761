import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import {
  REMOVE_MEMBERS_FROM_TAGS_MUTATION,
} from '@frontend/app/queries';
import {
  RemoveMembersFromTagsMutation,
  RemoveMembersFromTagsMutationVariables,
} from '@frontend/app/queries/types/RemoveMembersFromTagsMutation';

type IOptions = MutationHookOptions<RemoveMembersFromTagsMutation, RemoveMembersFromTagsMutationVariables>;

export const useRemoveMembersFromTagsMutation = (options: IOptions = {}) => useMemberMutation<RemoveMembersFromTagsMutation, RemoveMembersFromTagsMutationVariables>(
    REMOVE_MEMBERS_FROM_TAGS_MUTATION,
    options,
  );
