import { isArray } from 'lodash';

import { endpoints } from '@components';
import { IFavoriteList, addEventLog } from '@components';
import { fetchAPI } from '@frontend/utils';

interface IFavoriteFetchOptions {
  apiEndpoint: string;
  brandId?: number;
  campaignId?: number;
  listId?: number;
  name?: string;
}

/**
 * Fetch favorite list
 */
export const addSocialAccountsToFavoriteList = (
  options: {
    accountIds: number | number[];
  } & Pick<IFavoriteFetchOptions, 'apiEndpoint' | 'campaignId' | 'listId'>,
) => new Promise<IFavoriteList[]>((resolve, reject) => {
  const {
    accountIds,
    apiEndpoint,
    listId,
    campaignId,
  } = options;

  if (!apiEndpoint || !accountIds || !listId || !campaignId) {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('Missing at least one option: `apiEndpoint` | `accountIds` | `listId` | `campaignId`');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payload: any = {
    list_id: listId,
    campaign_id: campaignId,
  };
  if (isArray(accountIds)) {
    payload.account_ids = accountIds;
  } else {
    payload.account_id = accountIds;
  }
  const count = isArray(accountIds) ? accountIds.length : 1;

  return fetchAPI({
    url: `${apiEndpoint}/${endpoints.favoritesListElementEndpoint}`,
    method: 'POST',
    body: JSON.stringify(payload),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }).then((response: any) => {
    // TODO: DT-787: SPv2 specific metrics
    if (count === 1) {
      addEventLog('add_to_favorites_list', {
        state: 'connect.browse_creators_v2',
      });
    } else if (count > 1) {
      addEventLog('bulk_add_to_favorites_list', {
        count,
        state: 'connect.browse_creators_v2',
      });
    }
    resolve(response.data);
  });
});

/**
 * Fetch favorite list
 */
export const fetchFavoriteList = (
  options: Pick<IFavoriteFetchOptions, 'apiEndpoint' | 'brandId'>,
) => new Promise<IFavoriteList[]>((resolve, reject) => {
  const {
    apiEndpoint,
    brandId,
  } = options;

  if (!apiEndpoint || !brandId) {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('Missing at least one option: `apiEndpoint` | `brandId`');
  }

  return fetchAPI({
    url: `${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}?brand_id=${brandId}`,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }).then((response: any) => {
    resolve(response.data || []);
  });
});

/**
 * Create favorite list
 */
export const createFavoriteList = (
  options: Pick<IFavoriteFetchOptions, 'apiEndpoint' | 'brandId' | 'name'>,
) => new Promise<IFavoriteList>((resolve, reject) => {
  const {
    apiEndpoint,
    brandId,
    name,
  } = options;

  if (!apiEndpoint || !brandId || !name) {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('Missing at least one option: `apiEndpoint` | `brandId` | `name`');
  }

  return fetchAPI({
    url: `${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}`,
    method: 'POST',
    body: JSON.stringify({
      brand_id: brandId,
      name,
    }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }).then((response: any) => resolve(response || {}));
});

/**
 * Create favorite list
 */
export const renameFavoriteList = (
  options: Pick<IFavoriteFetchOptions, 'apiEndpoint' | 'brandId' | 'listId' | 'name'>,
) => new Promise<IFavoriteList[]>((resolve, reject) => {
  const {
    apiEndpoint,
    brandId,
    listId,
    name,
  } = options;

  if (!apiEndpoint || !brandId || !name) {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('Missing at least one option: `apiEndpoint` | `brandId` | `listId` | `name`');
  }

  return fetchAPI({
    url: `${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}/${listId}`,
    method: 'POST',
    body: JSON.stringify({
      brand_id: brandId,
      name,
    }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }).then((response: any) => resolve(response.data || []));
});

/**
 * Delete favorite list
 */
export const deleteFavoriteList = (
  options: Pick<IFavoriteFetchOptions, 'apiEndpoint' | 'brandId' | 'listId'>,
) => new Promise<IFavoriteList[]>((resolve, reject) => {
  const {
    apiEndpoint,
    brandId,
    listId,
  } = options;

  if (!apiEndpoint || !brandId || !listId) {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('Missing at least one option: `apiEndpoint` | `brandId` | `listId`');
  }

  return fetchAPI({
    url: `${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}/${listId}`,
    method: 'DELETE',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }).then((response: any) => resolve(response.data || []));
});
