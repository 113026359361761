import { useMemo } from 'react';
import { keyBy, map, isEmpty } from 'lodash';
import { useInstalledApplicationInstances } from '@frontend/app/hooks';

interface IOptions {
  skip?: boolean;
}

export const useAreApplicationsInstalled = (appIds: string[], options: IOptions = {}) => {
  const {
    skip,
  } = options;

  const {
    data: {
      instances = null,
    } = {},
    loading,
  } = useInstalledApplicationInstances({
    skip: skip || isEmpty(appIds),
  });

  const isInstalled = useMemo(() => {
    if (!instances) {
      return;
    }

    const instancesByAppId = keyBy(instances, (inst) => inst.applicationId);
    return map(appIds, (appId) => !!instancesByAppId[appId]);
  }, [instances, appIds]);

  return {
    loading,
    isInstalled,
  };
};
