import { TState } from '../types';

interface IAction {
  field: keyof TState['activeDates'];
  type: 'UPDATE DATE FIELD';
  value?: string;
}
export type TUpdateDateFieldAction = Readonly<IAction>;

export const updateDateField = (state: TState, action: TUpdateDateFieldAction): TState => {
  if (state.activeDates[action.field] === action.value) {
    return state;
  }
  const newActiveDates = { ...state.activeDates };
  if (action.value) {
    newActiveDates[action.field] = action.value;
  } else if (action.field in newActiveDates) {
    delete newActiveDates[action.field];
  }
  return {
    ...state,
    activeDates: newActiveDates,
  };
};
