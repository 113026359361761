import * as React from 'react';
import * as H from 'history';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import Helmet from 'react-helmet';

import { ProjectsPageState } from './constants';
import AutomationConfigContainer from './AutomationConfig/AutomationConfig';
import { AddPage } from './ProjectDetailsForm/AddPage';
import { ProjectsPage } from './ProjectsPage/ProjectsPage';
import { SettingsPage } from './SettingsPage/SettingsPage';
import { TemplatesPage } from './TemplatesPage/TemplatesPage';
import { ProjectsWithPublishedApplicationPage } from './ProjectsWithPublishedApplicationPage/ProjectsWithPublishedApplicationPage';

const {
  useMemo,
  useEffect,
  useRef,
} = React;

const TabProjectState = {
  overview: ProjectsPageState.Overview,
  invited: ProjectsPageState.Invited,
  applicants: ProjectsPageState.Applicants,
  all_in_progress: ProjectsPageState.AllInProgress,
  find_creators: ProjectsPageState.FindCreators,
  gcr: ProjectsPageState.GroupContentReview,
  rejected: ProjectsPageState.Rejected,
};

export const ProjectsRoutes: React.FC<RouteComponentProps> = React.memo(({ match }) => {
  const location = useLocation();
  const prevLocation = useRef<H.Location<unknown>>();

  useEffect(() => {
    if (!prevLocation.current
      || (
        prevLocation.current.pathname !== location.pathname
        && !location.pathname.includes('/marketplace/')
      )
    ) {
      prevLocation.current = location;
    }
  }, [location]);

  const prevPageState = useMemo(() => {
    if (!prevLocation.current) return ProjectsPageState.FindCreators;
    const prevTab = prevLocation.current.pathname.split('/').pop();
    return TabProjectState[prevTab] || ProjectsPageState.Task || ProjectsPageState.Worklet;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevLocation.current]);

  return (
    <>
      <Helmet title="Projects" />
      <Switch>
        <Route
          component={TemplatesPage}
          exact
          path={`${match.path}/new/templates`}
        />
        <Route
          component={AddPage}
          exact
          path={`${match.path}/new`}
        />
        <Route
          component={SettingsPage}
          path={`${match.path}/:projectId/settings`}
        />
        <Route
          exact
          path={`${match.path}/:projectId`}
          render={(routeProps) => (
            <ProjectsPage
              routeProps={routeProps}
              shouldRedirect
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/all_in_progress`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.AllInProgress}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/applicants`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.Applicants}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/invited`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.Invited}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/rejected`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.Rejected}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/:projectId/find_creators`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.FindCreators}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/overview`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.Overview}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/gcr`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.GroupContentReview}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/:projectId/duplicate_applications`}
          component={ProjectsWithPublishedApplicationPage}
        />
        <Route
          exact
          path={`${match.path}/:projectId/automation_detail/automation/:automationId`}
          component={AutomationConfigContainer}
        />
        <Route
          exact
          path={`${match.path}/:projectId/automation_detail/template/:templateId`}
          component={AutomationConfigContainer}
        />
        <Route
          exact
          path={`${match.path}/:projectId/marketplace/:step`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={prevPageState}
              routeProps={{ ...routeProps, ...{ location: prevLocation.current || location } }}
            />
          )}
        />
        {/* NOTE: Disabled for now <Route
        exact={true}
        path={`${match.path}/:projectId/rejected`}
        render={(routeProps) => (
          <ProjectsPage
            pageState={ProjectsPageState.Task}
            routeProps={routeProps}
          />
        )}
      /> */}
        <Route
          path={`${match.path}/:projectId/completed`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.Completed}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/:workletSpecUri/worklet`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.Worklet}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:projectId/:workletSpecUri/:taskId`}
          render={(routeProps) => (
            <ProjectsPage
              pageState={ProjectsPageState.Task}
              routeProps={routeProps}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/`}
          render={(routeProps) => (
            <ProjectsPage routeProps={routeProps} shouldRedirect />
          )}
        />
        <Redirect to={match.url} />
      </Switch>
    </>
  );
});

ProjectsRoutes.displayName = 'ProjectsRoutes';
