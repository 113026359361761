import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { trim } from 'lodash';

const options = {
  defaultBlockTag: 'div',
  entityStyleFn: (entity) => {
    const entityType = entity.get('type');
    if (entityType === 'link') {
      const data = entity.getData();
      return {
        element: 'a',
        attributes: {
          href: data.src,
        },
      };
    }

    if (entityType === 'variable') {
      const data = entity.getData();

      return {
        element: 'span',
        attributes: {
          data: data.data.field,
        },
      };
    }
  },
  blockRenderers: {
    unstyled: (block) => {
      if (!trim(block.getText()).length) {
        return '<br />';
      }
    },
  },
};

export function exportHTML(editorState: EditorState) {
  const contentState = editorState.getCurrentContent();
  return stateToHTML(contentState, options);
}
