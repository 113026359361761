export type TBlockType =
  'unstyled' |
  'ordered-list-item' |
  'unordered-list-item' |
  'header-one' |
  'header-two' |
  'header-three' |
  'header-four' |
  'header-five' |
  'header-six';

export type TInlineStyle = 'BOLD' | 'ITALIC' | 'UNDERLINE';

export type TMediaType = 'link' | 'attachment';

export type TAdditionalType = 'personalize' | 'template';
