export const browserHasScrollbar = (): boolean => (
  window && document && document.documentElement
    ? window.innerWidth - document.documentElement.clientWidth > 0
    : false
);

export const copyToClipboard = (str: string) => {
  // Source: https://www.30secondsofcode.org/js/s/copy-to-clipboard
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0
    ? document.getSelection().getRangeAt(0)
    : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};
