import * as React from 'react';

import { EmailComposer } from '@frontend/app/components';
import { useGetMemberQuery } from '@frontend/app/hooks';
import { backendServerWebEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { subjectLineForClientAndProgram } from '@frontend/applications/Shared/utils';
import { IAxProjectInfo } from '../types/IAxProjectInfo';

import styles from './MessageComposer.scss';

const { useMemo } = React;

interface IMessageComposerProps {
  memberId: number;
  project?: IAxProjectInfo;
}

export enum ContentMessageType {
  REMINDER = 'reminder',
}

const CONTENT_OBJECT_TYPE = 'Upcoming deliverables';

export const MessageComposer: React.FC<IMessageComposerProps> = ({ memberId, project }) => {
  const { closeModal, clientName, workflowActionParameters } = useApplication();

  const { data } = useGetMemberQuery(+memberId);

  const subject = useMemo(() => {
    const programName = workflowActionParameters?.programName;
    const defaultReminderSubject = `${CONTENT_OBJECT_TYPE} for ${clientName}`;
    const programReminderSubject = subjectLineForClientAndProgram(clientName, programName, CONTENT_OBJECT_TYPE);
    const reminderSubject = programName ? programReminderSubject : defaultReminderSubject;

    const subjectTemplates = {
      [ContentMessageType.REMINDER]: reminderSubject,
    };

    return project ? subjectTemplates[project.action] : '';
  }, [project, clientName, workflowActionParameters?.programName]);

  const initialHTML = useMemo(() => {
    const link = `${backendServerWebEndpoint()}app/project/${project.id}/agreement?token=${project.token}`;

    const contentTemplates = {
      [ContentMessageType.REMINDER]: `{"blocks":[{"key":"fe0b3","text":"Hi ${data?.member.name},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"7cf33","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"77t1o","text":"I just wanted to check in on your upcoming content for us. As a reminder, please use the link below to submit your content.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3l5em","text":"Let us know if you have any questions!","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"47eu4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3b1ak","text":"Submit Content","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":14,"key":0}],"data":{}},{"key":"13bq9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"fkchr","text":"Thanks,","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"link","mutability":"MUTABLE","data":{"src":"${link}"}}}}`,
    };

    return project && data ? contentTemplates[project.action] : '';
  }, [project, data]);

  return (
    <div className={styles.TermsAppMessageComposer}>
      <div className={styles.MessageComposerWrapper}>
        <div className={styles.title}>Write your message</div>
        {data?.member
          && (
          <EmailComposer
            className={styles.EmailComposer}
            subject={subject}
            subjectIsEditable
            initialMessage={initialHTML}
            members={[data?.member]}
            onMessageSent={() => closeModal()}
            hasTopBorder={false}
            enableExpandingEmailEditor
          />
          )}
      </div>
    </div>
  );
};
