/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1s2z4_157 {
  display: flex;
}

._justify-content-space-between_1s2z4_161 {
  justify-content: space-between;
}

._tabular-nums_1s2z4_165 {
  font-variant-numeric: tabular-nums;
}

._CreatorTile_1s2z4_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1s2z4_468 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._CreatorTile_1s2z4_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._CreatorTile_1s2z4_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._CreatorTile_1s2z4_169 ::-webkit-scrollbar-track, ._CreatorTile_1s2z4_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._CreatorTile_1s2z4_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._CreatorTile_1s2z4_169 {
  padding: 16px;
  border-radius: 1rem;
  will-change: background-color;
  transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._CreatorTile_1s2z4_169:hover {
  background-color: rgba(239, 245, 249, 0.5);
}
._CreatorTile_1s2z4_169:hover ._media_1s2z4_213:after {
  opacity: 0.5;
}
._CreatorTile_1s2z4_169:hover ._invite_1s2z4_216 {
  opacity: 1;
}
._CreatorTile_1s2z4_169 ._content_1s2z4_219 {
  position: relative;
  display: flex;
  flex-direction: column;
}
._CreatorTile_1s2z4_169 ._content_1s2z4_219._ineligible_1s2z4_224 {
  filter: grayscale(1);
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213 {
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213:after {
  bottom: 0;
  width: 100%;
  height: 50%;
  display: block;
  position: absolute;
  background-image: linear-gradient(0, rgba(26, 24, 24, 0.5), transparent);
  content: "";
  border-radius: 0.5rem;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213 ._image_1s2z4_248 {
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  object-fit: contain;
  object-position: center;
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213 ._moreImages_1s2z4_255 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  will-change: opacity, transform;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  z-index: 2;
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213 ._moreImages_1s2z4_255._active_1s2z4_269 {
  opacity: 1;
  transform: translateY(-10px);
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213 ._moreImages_1s2z4_255 ._imageItem_1s2z4_273 {
  width: 48px;
  height: 48px;
  max-width: calc(20% - 10px);
  max-height: calc(20% - 10px);
  margin-right: 4px;
  object-fit: cover;
  object-position: center center;
  box-sizing: content-box;
  border-radius: 4px;
  border: 3px solid #ececec;
  opacity: 0.9;
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213 ._moreImages_1s2z4_255 ._imageItem_1s2z4_273:hover {
  opacity: 1;
}
._CreatorTile_1s2z4_169 ._media_1s2z4_213 ._moreImages_1s2z4_255 ._imageItem_1s2z4_273:last-child {
  margin-right: 0;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 {
  position: relative;
  display: flex;
  flex-direction: column;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 > div:last-child {
  margin-bottom: 0;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._userInfo_1s2z4_300 {
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._avatar_1s2z4_306 {
  margin-left: -0.125rem;
  margin-right: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._avatar_1s2z4_306 ._avatarImage_1s2z4_313 {
  width: 100%;
  height: 100%;
  border-radius: 624.9375rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._avatar_1s2z4_306 ._source_1s2z4_318 {
  display: none;
  position: absolute;
  bottom: -8px;
  right: -4px;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  background-color: #fdfdfd;
  border-radius: 624.9375rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._avatar_1s2z4_306 ._source_1s2z4_318 ._sourceImage_1s2z4_330 {
  width: 14px;
  height: 14px;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 0.875rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._name_1s2z4_344 {
  width: 100%;
  height: 1rem;
  display: flex;
  font-weight: 600;
  line-height: 1rem;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._name_1s2z4_344 a {
  color: #1a1818;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._name_1s2z4_344 ._ambIcon_1s2z4_358 {
  margin-left: 0.375rem;
  display: inline-block;
  align-self: center;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._date_1s2z4_363 a {
  color: #8f8d91;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._date_1s2z4_363:not(:empty) {
  margin-top: 0.375rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._name_1s2z4_344 a,
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._date_1s2z4_363 a {
  text-decoration: none;
  will-change: color;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._name_1s2z4_344 a:hover,
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._info_1s2z4_334 ._date_1s2z4_363 a:hover {
  color: #4fa7ee;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._admin_1s2z4_379 {
  margin: 0;
  padding: 0.125rem 0.5rem;
  width: auto;
  min-width: unset;
  height: auto;
  font-size: 0.6875rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._admin_1s2z4_379:not(:last-child) {
  margin-right: 0.375rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._admin_1s2z4_379 > div {
  font-weight: normal;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._favoriteButton_1s2z4_393 {
  display: flex;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._favoriteButton_1s2z4_393 svg {
  display: block;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._infoIcon_1s2z4_399 {
  cursor: pointer;
  margin-right: 0.5rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._programsList_1s2z4_403:not(:empty) {
  margin-bottom: 1rem;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._metrics_1s2z4_406 {
  margin: 0 0 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._metrics_1s2z4_406 ._reach_1s2z4_413 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #1a1818;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._metrics_1s2z4_406 ._reach_1s2z4_413._darkMode_1s2z4_419 {
  color: #fdfdfd;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._metrics_1s2z4_406 ._reach_1s2z4_413 ._amount_1s2z4_422 {
  margin-left: 8px;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._metrics_1s2z4_406 ._engagement_1s2z4_425 {
  margin: 0 0 0 auto;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._metrics_1s2z4_406 ._unit_1s2z4_428 {
  margin-left: 0.5rem;
  color: #8f8d91;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 {
  margin: 0 0 16px;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._approveSection_1s2z4_435 {
  margin-bottom: 10px;
  padding-left: 2px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._approveSection_1s2z4_435 ._thumbsUpIcon_1s2z4_443 {
  margin-right: 7px;
  position: relative;
  top: 3px;
  color: #3996e0;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._approveSection_1s2z4_435 ._moreReviewUsers_1s2z4_449 {
  user-select: none;
  cursor: pointer;
  color: #3996e0;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._feedbackSection_1s2z4_454 {
  padding: 10px 14px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  background-color: #fff1e7;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._feedbackSection_1s2z4_454 ._feedbackItem_1s2z4_461 {
  margin-bottom: 5px;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._feedbackSection_1s2z4_454 ._feedbackItem_1s2z4_461 ._feedbackTitle_1s2z4_464 {
  margin-bottom: 2px;
  font-weight: bold;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._feedbackSection_1s2z4_454 ._showMore_1s2z4_468 {
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  color: #3996e0;
  will-change: color;
  transition: color 0.1s ease-out;
}
._CreatorTile_1s2z4_169 ._details_1s2z4_292 ._reviews_1s2z4_432 ._feedbackSection_1s2z4_454 ._showMore_1s2z4_468:hover {
  color: #4fa7ee;
}
._CreatorTile_1s2z4_169 ._ineligibleReason_1s2z4_479 {
  margin-top: 20px;
  padding: 8px 16px;
  text-align: center;
  color: #656565;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  background-color: #EFEFEF;
  border-radius: 5px;
  filter: none;
}
._CreatorTile_1s2z4_169 ._ineligibleReason_1s2z4_479 ._reason_1s2z4_491 {
  font-weight: 600;
}
._CreatorTile_1s2z4_169 ._ineligibleReason_1s2z4_479 ._link_1s2z4_494 {
  margin: 7px 0;
  cursor: pointer;
  user-select: none;
  color: #3996e0;
}
._CreatorTile_1s2z4_169 ._ineligibleReason_1s2z4_479 ._link_1s2z4_494:hover {
  text-decoration: underline;
}
._CreatorTile_1s2z4_169 ._invite_1s2z4_216 {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  right: 0.75rem;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._CreatorTile_1s2z4_169 ._invite_1s2z4_216._inviteWithFavorite_1s2z4_512 {
  display: flex;
  align-items: center;
}
._CreatorTile_1s2z4_169 ._invite_1s2z4_216._inviteWithFavorite_1s2z4_512 ._favoriteWidget_1s2z4_516 {
  min-width: 3rem;
  margin-right: 1rem;
}
._CreatorTile_1s2z4_169 ._invite_1s2z4_216:hover, ._CreatorTile_1s2z4_169 ._invite_1s2z4_216._show_1s2z4_468 {
  opacity: 1;
}
._CreatorTile_1s2z4_169 ._actions_1s2z4_523 {
  margin-top: 1rem;
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

._Tooltip_1s2z4_529 ._image_1s2z4_248 {
  width: 100%;
}

._MoreUsersTooltip_1s2z4_533 ._content_1s2z4_219 {
  font-size: 13px;
  line-height: 18px;
  font-weight: bold;
}

._infoTooltip_1s2z4_539 {
  width: 12.5rem;
}