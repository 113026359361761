import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { CHECK_IN_WORK_ITEMS_MUTATION } from '@frontend/app/queries';
import {
  CheckInWorkItemsMutation,
  CheckInWorkItemsMutationVariables,
} from '@frontend/app/queries/types/CheckInWorkItemsMutation';

type IOptions = MutationHookOptions<CheckInWorkItemsMutation, CheckInWorkItemsMutationVariables>;

export const useCheckInWorkItemsMutation = (options: IOptions = {}) => (
  useMutation(CHECK_IN_WORK_ITEMS_MUTATION, options)
);
