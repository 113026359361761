/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_aj4af_157 {
  display: flex;
}

._justify-content-space-between_aj4af_161 {
  justify-content: space-between;
}

._tabular-nums_aj4af_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_aj4af_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberNameCell_aj4af_178 {
  display: flex;
  align-items: center;
  width: 100%;
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 {
  position: relative;
  width: 2.75rem;
  min-width: 2.75rem;
  height: 2.75rem;
  cursor: pointer;
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._checkbox_aj4af_190,
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._avatar_aj4af_183 {
  position: absolute;
  transition: opacity 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._checkbox_aj4af_190 {
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  transition: background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._checkbox_aj4af_190:hover {
  background-color: #d5e1e9;
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._checkbox_aj4af_190 .ant-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._checkbox_aj4af_190 .ant-checkbox .ant-checkbox-inner {
  transform: scale(1.25);
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._checkbox_aj4af_190 .ant-checkbox::after {
  display: none;
}
._MemberNameCell_aj4af_178 ._avatarWrapper_aj4af_183 ._avatar_aj4af_183 {
  top: 0.375rem;
  left: 0.375rem;
  z-index: 1;
  display: block;
  width: 2rem;
  height: 2rem;
  opacity: 1;
}
._MemberNameCell_aj4af_178 ._name_aj4af_234 {
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  margin-left: 0.75rem;
  text-overflow: ellipsis;
}
._MemberNameCell_aj4af_178 ._name_aj4af_234:hover {
  color: #4fa7ee;
}
._MemberNameCell_aj4af_178 ._name_aj4af_234._editable_aj4af_244 {
  width: 100%;
  min-height: 2.625rem;
  padding: 0.625rem;
  border-width: 0.0625rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.5rem;
  cursor: text;
}
._MemberNameCell_aj4af_178 ._name_aj4af_234._editable_aj4af_244:hover {
  border-color: #dadcde;
  background-color: #fdfdfd;
}
._MemberNameCell_aj4af_178 ._applicant_aj4af_258 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding-left: 0.75rem;
}
._MemberNameCell_aj4af_178 ._checkBtn_aj4af_265 {
  padding: 0.1875rem;
}
._MemberNameCell_aj4af_178 ._closeBtn_aj4af_268 {
  margin-left: 0.75rem;
  padding: 0.375rem;
  background-color: #e9e8ea;
  color: #da3737;
}