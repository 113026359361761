import * as React from 'react';

import { BaseEmptyState } from './BaseEmptyState';

export const ECommerceEmptyState: React.FC = () => (
  <BaseEmptyState
    title="Hey, nobody here yet"
    subtitle="You will see people once they are synced or meet the criteria for recommendations. This may take up to 24 hours."
  />
);

ECommerceEmptyState.displayName = 'ECommerceEmptyState';
