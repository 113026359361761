import * as React from 'react';

import { Input } from 'antd';
import { isFunction } from 'lodash';

import styles from './AnnualRelative.scss';

const { useCallback } = React;

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  onChange?(value: string);
}

export const AnnualRelative: React.FC<IProps> = (props) => {
  const { value, onChange } = props;

  if (isNaN(value)) {
    onChange('');
  }

  const handleChangeValue = useCallback((event) => {
    if (isFunction(onChange)) {
      const { value } = event.target;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        onChange(event.target.value);
      }
    }
  }, [onChange]);

  return (
    <Input
      value={value}
      addonAfter="days"
      className={styles.AnnualRelative}
      onChange={handleChangeValue}
    />
  );
};
