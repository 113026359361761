/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';

import {
 map, isNumber, isEmpty, toArray, size, sortBy, last,
} from 'lodash';

import { useGetMemberQuery } from '@frontend/app/hooks';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import {
 Button, SubmitButton, LoadSpinner, Notice, Checkbox,
} from '@components';
import { isEmailAddressValid } from '@common';
import { Alert } from '@revfluence/fresh';
import { ISendContractRequest, sendContract, sendBulkContract } from '../useSendContract';
import { SignerRole, IContract } from '../useFetchContracts';

import { useFetchSignersData } from '../useFetchSigners';

import { SignerCell, FieldNames } from './SignerCell';

import styles from './ContractSetup.scss';

const { useReducer } = React;

interface IProps {
  memberCount: number;

  fileUrl?: string;
  templateId?: string;
  memberId?: number;
  memberQueryJson?: string;
  memberIds?: number[];
  onSendSuccess(contract: IContract): void;
  onBulkSendSuccess?(count: number): void;
}

const NAME_REGEX = /^[^#_\(\)\d\%\@\!\$]+$/;

export const ContractSetup: React.FunctionComponent<IProps> = (props) => {
  const {
    fileUrl,
    memberId,
    onSendSuccess,
    onBulkSendSuccess,
    memberCount,
    memberQueryJson,
    memberIds,
  } = props;
  const [saving, setSaving] = useState<boolean>();
  const [signerErrors, setSignerErrors] = useState<Map<any, any>>(new Map());
  const [signerErrorsMsg, setSignerErrorsMsg] = useState<string>(null);
  const [error, setError] = useState<string>(null);
  const {
    backendServerApiEndpoint, clientId, workflowActionParameters, clientName,
  } = useApplication();
  const { data: signersData, loading } = useFetchSignersData(
    `${backendServerApiEndpoint}/contract_signer`,
    clientId,
    0,
  );

  const programId = workflowActionParameters?.programId;
  const workItemIds = workflowActionParameters ? map(workflowActionParameters?.workItems, 'id') : null;

  const isInBulkSendMode = memberCount > 1;
  const isInWorkflowView = workItemIds?.length > 0;
  const { data: memberData, loading: memberLoading } = useGetMemberQuery(
    memberId ? Number(memberId) : null,
    { skip: !memberId },
  );

  const contractFormReducer = (state: ISendContractRequest, action) => {
    const { value, signerId } = action;
    let signersCopy; let
signerErrorsCopy;

    switch (action.type) {
      case 'addSigner':
        signersCopy = new Map(state.signers as any);
        const newSignerId = isNumber(signerId) ? signerId : signersCopy.size;
        const newSigner = value || {
          firstName: '',
          lastName: '',
          name: '',
          email: '',
          role: SignerRole.CreatorRole,
        };
        signersCopy.set(newSignerId, newSigner);

        signerErrorsCopy = new Map(signerErrors);
        signerErrorsCopy.set(newSignerId, {
          required: new Set(),
          invalid: new Set(),
        });
        setSignerErrors(signerErrorsCopy);

        return {
          ...state,
          signers: signersCopy,
        };

      case 'updateSigner':
        if (isNumber(signerId) && (state.signers as any).has(signerId)) {
          signersCopy = new Map(state.signers as any);
          signersCopy.set(signerId, value);

          signerErrorsCopy = new Map(signerErrors);
          signerErrorsCopy.set(signerId, {
            required: new Set(),
            invalid: new Set(),
          });
          setSignerErrors(signerErrorsCopy);

          return {
            ...state,
            signers: signersCopy,
          };
        } else {
          return state;
        }

      case 'removeSigner':
        signersCopy = new Map(state.signers as any);
        signersCopy.delete(signerId);

        signerErrorsCopy = new Map(signerErrors);
        signerErrorsCopy.delete(signerId);
        setSignerErrors(signerErrorsCopy);
        if (signersCopy.size === 0) {
          setSignerErrorsMsg('');
        }

        return {
          ...state,
          signers: signersCopy,
        };

      case 'setTitle':
        return { ...state, title: value };
      case 'setSubject':
        return { ...state, subject: value };
      case 'setMessage':
        return { ...state, message: value };
      case 'setCompleted':
        return { ...state, completed: value };
      default:
        throw new Error();
    }
  };

  const [contractParams, dispatch] = useReducer(contractFormReducer, {
    signers: new Map(),
    subject: `Signature requested for ${clientName} Contract`,
    message: `Please review and sign this contract to move forward with our collaboration.
Thanks!
The ${clientName} Team`,
    uploaded_pdf_url: fileUrl,
    client_id: clientId,
    member_id: memberId,
    title: `${clientName} Contract`,
    template_id: props.templateId,
    completed: false,
  } as any);

  const handleMessageChange = (event) => {
    const { value } = event.target;
    dispatch({
      type: 'setMessage',
      value,
    });
  };

  const handleTitleChange = (event) => {
    const { value } = event.target;
    dispatch({
      type: 'setTitle',
      value,
    });
  };

  const addSigner = () => {
    dispatch({
      type: 'addSigner',
    });
    dispatch({
      type: 'setCompleted',
      value: false,
    });
  };

  const onChangedCompletedCheckbox = (checked: boolean) => {
    dispatch({
      type: 'setCompleted',
      value: checked,
    });
  };

  const areValidSigners = () => {
    let erroredSignersCount = 0;
    const { signers } = contractParams;
    const signerErrorsCopy = new Map();

    signers.forEach((signer, signerId) => {
      const signerError = {
        required: new Set(),
        invalid: new Set(),
      };

      const { required, invalid } = signerError;
      if (!signer.firstName) {
        required.add(FieldNames.firstName);
      } else if (!NAME_REGEX.test(signer.firstName)) {
        invalid.add(FieldNames.firstName);
      }

      if (!signer.lastName) {
        required.add(FieldNames.lastName);
      } else if (!NAME_REGEX.test(signer.lastName)) {
        invalid.add(FieldNames.lastName);
      }

      if (!signer.email) {
        required.add(FieldNames.email);
      } else if (!isEmailAddressValid(signer.email)) {
        invalid.add(FieldNames.email);
      }

      if (required.size > 0 || invalid.size > 0) {
        signerErrorsCopy.set(signerId, signerError);
        erroredSignersCount += 1;
      }
    });

    if (erroredSignersCount > 0) {
      setSignerErrors(signerErrorsCopy);
    }

    return erroredSignersCount === 0;
  };

  const validateForm = () => {
    if (!areValidSigners()) {
      setSignerErrorsMsg('Please make sure all signers have valid names and emails before sending');
      return false;
    }

    if (!contractParams.title) {
      setError('Please enter a title for your contract');
      return false;
    }
    if (!contractParams.message) {
      setError('Please enter a message for your contract');
      return false;
    }

    setSignerErrorsMsg('');
    setError('');
    return true;
  };

  const setServerError = (error) => {
    if (typeof error === 'string') {
      setError(error);
    } else {
      setError('An unexpected error has occurred. Please reach out to help@aspireiq.com for help');
    }
  };

  const sendSingleContract = () => {
    const contractUrl = `${backendServerApiEndpoint}/contract`;
    const params = {
      ...contractParams,
      signers: [...contractParams.signers.values()],
      member_id: memberId,
      member_ids: memberIds,
      program_id: programId,
      work_item_ids: workItemIds,
    };

    setSaving(true);
    sendContract(contractUrl, params as any).then((contract) => {
      onSendSuccess(contract);
    }).catch(setServerError).finally(() => setSaving(false));
  };

  const sendBulkContracts = () => {
    const contractUrl = `${backendServerApiEndpoint}/contract`;
    const params = {
      ...contractParams,
      signers: [...contractParams.signers.values()],
      search_query: memberQueryJson,
      member_ids: memberIds,
      program_id: programId,
      work_item_ids: workItemIds,
    };

    setSaving(true);
    sendBulkContract(contractUrl, params as any).then(() => {
      onBulkSendSuccess(memberCount);
    }).catch(setServerError).finally(() => setSaving(false));
  };

  const sendContractClicked = () => {
    if (!validateForm()) {
      return;
    }

    if (isInBulkSendMode) {
      sendBulkContracts();
    } else {
      sendSingleContract();
    }
  };

  interface IPerson {
    name?: string;
    first_name?: string;
    last_name?: string;
  }

  const getPersonFirstName = (person: IPerson) => {
    if (person.first_name) { return person.first_name; }

    if (!person.name) { return ''; }

    return person.name.split(' ')[0];
  };

  const getPersonLastName = (person: IPerson) => {
    if (person.last_name) { return person.last_name; }

    if (!person.name) { return ''; }

    return person.name.split(' ').slice(1).join('');
  };

  // Set up initial state for signers
  useEffect(() => {
    const defaultSigners = [];

    if (!isEmpty(signersData)) {
      const sortedSignersData = sortBy(signersData, (signer) => new Date(signer.date_created));
      const defaultSigner = last(sortedSignersData);

      defaultSigners.push({
        name: defaultSigner.name,
        firstName: getPersonFirstName(defaultSigner),
        lastName: getPersonLastName(defaultSigner),
        email: defaultSigner.email_address,
        role: SignerRole.BrandRole,
      });
    }

    if (memberData) {
      defaultSigners.push({
        firstName: getPersonFirstName(memberData.member),
        lastName: getPersonLastName(memberData.member),
        name: memberData.member.name,
        email: memberData.member.email,
        role: SignerRole.CreatorRole,
      });
    }

    defaultSigners.forEach((defaultSigner, index) => {
      dispatch({
        type: 'addSigner',
        value: defaultSigner,
        signerId: index,
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signersData, memberData]);

  const addSignersLabel = useMemo(() => {
    if (isInBulkSendMode) {
      return `If each contract needs to be countersigned
        by ${clientName} or anyone other than the member,
        please add them below:`;
    }
    if (isInWorkflowView) {
      return 'All selected members have already been added as signers.';
    }
    return 'Add signers:';
  }, [isInBulkSendMode, isInWorkflowView, clientName]);

  const submitButtonText = isInBulkSendMode ? `Send ${memberCount} contract${memberCount === 1 ? '' : 's'}`
    : 'Send Contract';

  return (
    <div className={styles.contract_setup}>
      {loading || memberLoading ? <LoadSpinner /> : (
        <div className={styles.form_wrap}>
          <h1>Set up your contract</h1>

          <div>
            <section className={styles.message_wrap}>
              <label>Enter a title for your contract:</label>
              <input
                className={styles.standard_input}
                value={contractParams.title}
                onChange={handleTitleChange}
              />
            </section>

            <section className={styles.message_wrap}>
              <label>Enter a message to be sent with the contract:</label>
              <textarea
                value={contractParams.message}
                onChange={handleMessageChange}
              />
            </section>

            <div className={styles.signers_wrap}>
              {isInBulkSendMode || isInWorkflowView
                ? (
                  <Alert
                    message={addSignersLabel}
                    type="info"
                  />
)
                : <label>{addSignersLabel}</label>}
              {map(toArray(contractParams.signers), ([signerId, signer]) => (
                <SignerCell
                  signer={signer}
                  signerId={signerId}
                  key={signerId}
                  error={signerErrors.get(signerId)}
                  reducerDispatch={dispatch}
                />
              ))}
              <Button
                label="Add New Signer"
                onClick={addSigner}
                theme="light"
                className={styles.add_signer_button}
              />
            </div>

            {size(contractParams.signers) === 0 && !isInBulkSendMode && (
              <div className={styles.signers_wrap}>
                <Checkbox
                  checked={contractParams.completed}
                  onChange={onChangedCompletedCheckbox}
                  className={styles.completeCheckbox}
                />
                <label className={styles.completeLabel}>
                  This contract has already been signed by all parties
                </label>
              </div>
            )}
          </div>
          {error && (
            <Notice type="error" className={styles.error}>{error}</Notice>
          )}
          {signerErrorsMsg && (
            <Notice type="error" className={styles.error}>{signerErrorsMsg}</Notice>
          )}
          <div className={styles.actions_wrap}>
            <SubmitButton
              label={submitButtonText}
              isSubmitting={saving}
              disabled={saving}
              submittingLabel="Sending..."
              onClick={sendContractClicked}
            />
          </div>
        </div>
      )}
    </div>
  );
};
