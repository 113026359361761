import {
  map, split, join, toUpper,
} from 'lodash';

export const capitalizeFirstLetter = (str: string): string => join(
  map(
    split(str, ' '),
    (word) => `${toUpper(word[0] || '')}${word.slice(1) || ''}`,
  ),
  ' ',
);
