import { replace, startCase, toLower } from 'lodash';

export const pluralize = (count: number, singular: string, plural?: string) => {
  plural = plural || `${singular}s`;
  return count === 1 ? singular : plural;
};

export const safeJSONParse = (JSONString: string) => {
  try {
    return JSON.parse(JSONString);
  } catch {
    return JSONString;
  }
};

export const convertToTitleCase = (str: string) => {
    // Replace underscores with spaces
    str = replace(str, /_/g, ' ');
    // Use regular expression to split camelCase
    str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    // Capitalize the first letter of each word
    return startCase(toLower(str));
};
