import * as React from 'react';
import {
  Card,
  Col,
  Row,
  Statistic,
} from '@revfluence/fresh';

import { formatValue } from '@affiliates/utils';
import { IStatisticsCardData } from './types';

import styles from './StatisticsCard.scss';

interface IProps {
  data?: IStatisticsCardData[];
  className?: string;
  timeText?: string;
}

export const BulkPaymentAppStatisticsCard: React.FC<Readonly<IProps>> = React.memo((props) => (
  <Card bodyStyle={{ padding: '16px' }}>
    <Row
      justify="space-between"
      align="top"
    >
      {props.data.map((item) => (
        <Col
          key={item.title}
          span={4}
          className={styles.BulkPayStatsCard}
        >
          <Statistic
            prefix={item.prefix}
            suffix={item.suffix}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            reverse
            value={formatValue(item.format, item.value)}
            title={item.title}
            size="small"
          />
        </Col>
      ))}
    </Row>
  </Card>
));

BulkPaymentAppStatisticsCard.displayName = 'StatisticsCard';
