import { TState } from '../types';

interface IAction {
  saving: boolean;
  type: 'UPDATE SAVING STATE';
}
export type TUpdateSavingState = Readonly<IAction>;

export const updateSavingState = (state: TState, action: TUpdateSavingState): TState => {
  if (state.saving === action.saving) {
    return state;
  }
  return {
    ...state,
    saving: action.saving,
  };
};
