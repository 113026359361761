/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1diex_157 {
  display: flex;
}

._justify-content-space-between_1diex_161 {
  justify-content: space-between;
}

._tabular-nums_1diex_165 {
  font-variant-numeric: tabular-nums;
}

._IconSelect_1diex_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1diex_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._IconSelect_1diex_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._IconSelect_1diex_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._IconSelect_1diex_169 ::-webkit-scrollbar-track, ._IconSelect_1diex_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._IconSelect_1diex_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._IconSelect_1diex_169 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  border-radius: 624.9375rem;
}
._IconSelect_1diex_169 ._modeItem_1diex_289 {
  height: 36px;
  width: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #eff5f9;
  border-right: 1px solid #dadcde;
  cursor: pointer;
  will-change: color, background-color, border-color;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._IconSelect_1diex_169 ._modeItem_1diex_289:first-child {
  padding-left: 2px;
  border-left: none;
}
._IconSelect_1diex_169 ._modeItem_1diex_289:last-child {
  padding-right: 2px;
  border-right: none;
}
._IconSelect_1diex_169 ._modeItem_1diex_289:hover {
  color: #3996e0;
}
._IconSelect_1diex_169 ._modeItem_1diex_289._active_1diex_314 {
  color: #fdfdfd;
  border-color: #3996e0;
  background-color: #3996e0;
}
._IconSelect_1diex_169 ._modeItem_1diex_289._disabled_1diex_319 {
  opacity: 0.5;
  cursor: not-allowed;
}