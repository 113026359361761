import { isNil } from 'lodash';

export const cleanHtml = (html: string) => {
  if (isNil(html)) return '';

  const fontFamilyRegex = /(font-family):\s*([^;]*)[;'][;"]/g;
  const fontSizeRegex = /(font-size):\s*([^;]*)[;'][;"]/g;
  const colorRegex = /(color):\s*([^;]*)[;'][;"]/g;
  const backgroundColorRegex = /(background-color):\s*([^;]*)[;'][;"]/g;
  const linkTargetRegex = /_self/gm;
  const cleanedHTML = html
    .replace(fontFamilyRegex, '"')
    .replace(fontSizeRegex, '"')
    .replace(backgroundColorRegex, '"')
    .replace(colorRegex, '"')
    .replace(linkTargetRegex, '_parent');

  return cleanedHTML;
};
