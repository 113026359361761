import {
  useMemo, useCallback, useEffect, useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  filter, find, map, orderBy,
} from 'lodash';
import {
  useClientFeatureEnabled, useCommunitiesQuery, useGetCurrentClient, useGetProfile,
} from '@frontend/app/hooks';
import { useProjectsApp } from '@frontend/app/containers/Projects/hooks';
import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { ClientFeature } from '@frontend/app/constants';
import { TopNavItem } from '@frontend/app/types/TopNavItem';
import { useGetEmbeddedReports } from '@frontend/app/hooks/useGetEmbeddedReports';
import { getFilteredGroups } from '../Groups/GcrUtils';

const FALLBACK_GROUP_URL = 'https://storage.googleapis.com/aspirex-static-files/home/groups.png';

const allNavItems: TopNavItem[] = [
  {
    id: 'home',
    text: 'Home',
    route: '/programs',
  },
  {
    id: 'projects',
    text: 'Projects',
  },
];

const applicantMenu: TopNavItem = {
  id: 'applicants',
  text: 'Applicants',
};

const reportingMenu: TopNavItem = {
  id: 'reporting',
  text: 'Reporting',
};

const getNavItemsWithSelectedStatus = (items: TopNavItem[], route: string) =>
  items.map((item) => {
    const children = getNavItemsWithSelectedStatus(item.children || [], route);
    return {
      ...item,
      children: children.length > 0 ? children : undefined,
    };
  });

const findItemPathById = (items: TopNavItem[], id: string) => {
  for (const item of items) {
    if (item.id === id) {
      return [item];
    } else if (item.children) {
      const subpath = findItemPathById(item.children, id);
      if (subpath.length > 0) {
        return [...subpath, item];
      }
    }
  }
  return [];
};

export const useTopNavigation = () => {
  const location = useLocation();
  const history = useHistory();
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const [navItems, setNavItems] = useState<TopNavItem[]>(allNavItems);
  const { projects } = useProjectsApp();
  const { profile } = useGetProfile();
  const { client } = useGetCurrentClient();
  const [showCommunities, setShowCommunities] = useState(false);
  const isApplicantReview = useClientFeatureEnabled(ClientFeature.APPLICANT_REVIEW);
  const isEmbeddedReportingEnabled = useClientFeatureEnabled(ClientFeature.EMBEDDED_REPORTING);

  const {
    data: { embeddedReportingItems } = {},
    loading: embeddedReportsLoading,
  } = useGetEmbeddedReports({ skip: !isEmbeddedReportingEnabled });

  useEffect(() => {
    // 1. Feature enabled to show groups
    // 2. client -> user role should be manage
    const clientId = client.id;
    const {
      auth0User: {
        appMetadata: {
          clients,
        },
      },
    } = profile;
    const role = clients[clientId].roles[0];
    setShowCommunities(role === 'manager:content_approver');
  }, [client, profile]);

  const { data: communityQueryData } = useCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [communities, setCommunities] = useState([]);
  const orderedCommunities = orderBy(
    filter(communities, (_, idx) => idx !== null),
    ['memberCount', 'title', 'id'],
    ['desc', 'asc', 'asc'],
  );

  /* Include Projects into Main Navigation */
  const navItemProjects = navItems.find((item) => item.id === 'projects');
  const projectsChildren = projects?.map((item) => ({
    id: String(item.id),
    text: item.title,
    route: `/programs/${item.id}`,
    imageUrl: item.splashImageUrl,
    status: item.status,
  }));

  navItemProjects.children = useMemo(() => projectsChildren?.filter((item) => (
    !isArchiveProjectEnabled || (isArchiveProjectEnabled && item.status === ProjectStatus.Active)
  )), [
    projectsChildren,
    isArchiveProjectEnabled,
  ]);

  useEffect(() => {
    if (communityQueryData) {
      setCommunities(getFilteredGroups(communityQueryData.communities));
    }
  }, [communityQueryData, showCommunities, isApplicantReview]);

  useEffect(() => {
    if (showCommunities && isApplicantReview) {
      // Only add "Applicants" menu item once
      const applicantsNavItem = find(navItems, (item) => item.id === 'applicants');
      if (!applicantsNavItem && communities.length) {
        setNavItems((previousNavItem) => {
          previousNavItem.push(applicantMenu);
          return previousNavItem;
        });
      }
    }

    // If Embedded Reporting is enabled, load all the Embedded Reporting routes and add them to the navigation
    if (isEmbeddedReportingEnabled && embeddedReportingItems && !embeddedReportsLoading && embeddedReportingItems.length > 0) {
      const reportingOptions = navItems.find((item) => item.id === 'reporting');
      if (!reportingOptions) {
        setNavItems([
          ...navItems,
          {
            ...reportingMenu,
            children: embeddedReportingItems.map((item) => ({
              id: item.id.toString(),
              text: item.name,
              route: `/embedded_reporting/${item.id}`,
            })),
          },
        ]);
      }
    }
  }, [communities, isApplicantReview, showCommunities, navItems, isEmbeddedReportingEnabled, embeddedReportingItems, embeddedReportsLoading]);

  // Include Applicants into Main Navigation
  const navItemApplicants = find(navItems, (item) => item.id === 'applicants');

  if (navItemApplicants) {
    const applicantsChildren = map(orderedCommunities, (item) => ({
      id: String(item.id),
      text: item.title,
      route: `/groups/${item.id}`,
      imageUrl: item.splashImageUrl ? item.splashImageUrl : FALLBACK_GROUP_URL,
    }));

    navItemApplicants.children = applicantsChildren;
  }

  const path = decodeURIComponent(location.pathname + location.search);
  const mainNavItems = getNavItemsWithSelectedStatus(navItems, path);

  const handleNavItemClick = useCallback(
    (id: string) => {
      const [item] = findItemPathById([...mainNavItems], id);
      if (item && item.route) {
        history.push(item.route);
      }
    },
    [history, mainNavItems],
  );

  return useMemo(
    () => ({
      mainNavItems,
      handleNavItemClick,
    }),
    [mainNavItems, handleNavItemClick],
  );
};
