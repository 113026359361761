/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._RatingCell_17uux_32 ._star_17uux_32 {
  margin-right: 4px;
  color: #3996e0;
}
._RatingCell_17uux_32 ._noStar_17uux_36 {
  margin-right: 4px;
  color: #8f8d91;
}