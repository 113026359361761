import * as React from 'react';

import { Button, Tooltip } from '@revfluence/fresh';
import { EditOutlined } from '@ant-design/icons';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  title: string;
}

export const ManageOfferButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const { disabled, onClick, title } = props;
  return (
    <Tooltip placement="top" title={title}>
      <Button disabled={disabled} icon={<EditOutlined />} onClick={onClick} />
    </Tooltip>
  );
});

ManageOfferButton.defaultProps = {
  disabled: false,
};

ManageOfferButton.displayName = 'ManageOfferButton';
