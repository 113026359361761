export function composeImpactValue(n: number, decimals = 1) {
  const zero = Number(0).toFixed(decimals);
  if (!n) return zero;
  const stringN = n.toFixed(decimals);
  const formattedN = Number(stringN);
  if (Math.abs(formattedN) === 0) {
    return zero;
  }
  return stringN;
}
