import moment from 'moment';
import { ContentType, NetworkType } from '@frontend/app/types/globalTypes';
import { startCase, toLower } from 'lodash';
import {
  DateOperator,
  IDateBefore,
  TDateRequirement,
  IDateOn,
  IDateBetween,
} from '../types/ContentDateRequirements';
import { TContentGuideline, IContentGuidelinesWithDueDates, DueDateType } from '../types/ContentGuidelines';
import { WhitelistingLabel } from '../types/CollaborationDetails';

export interface ISortAndGroupContentGuidelines {
  [property: ContentType | string]: Array<TContentGuideline | IContentGuidelinesWithDueDates>
}

export const getContentTypeLabel = (contentCount: number, contentType: ContentType): string => {
  switch (contentType) {
    case ContentType.INSTAGRAM_POST:
    case ContentType.INSTAGRAM:
      return contentCount === 1 ? 'Instagram Post' : 'Instagram Posts';
    case ContentType.INSTAGRAM_REEL:
      return contentCount === 1 ? 'Instagram Reel' : 'Instagram Reels';
    case ContentType.INSTAGRAM_STORY:
      return contentCount === 1 ? 'Instagram Story' : 'Instagram Stories';
    case ContentType.INSTAGRAM_VIDEO:
      return contentCount === 1 ? 'Instagram Video' : 'Instagram Videos';
    case ContentType.ADDITIONAL_IMAGES:
      return contentCount === 1 ? 'Additional Image' : 'Additional Images';
    case ContentType.ADDITIONAL_VIDEOS:
      return contentCount === 1 ? 'Additional Video' : 'Additional Videos';
    case ContentType.BLOG_DEDICATED:
      return contentCount === 1 ? 'Blog Dedicated' : 'Blogs Dedicated';
    case ContentType.BLOG_MENTION:
      return contentCount === 1 ? 'Blog Mention' : 'Blog Mentions';
    case ContentType.FACEBOOK_POST:
      return contentCount === 1 ? 'Facebook Post' : 'Facebook Posts';
    case ContentType.OTHER:
      return contentCount === 1 ? 'Custom Work' : 'Custom Works';
    case ContentType.PINTEREST_PIN:
    case ContentType.PINTEREST:
      return contentCount === 1 ? 'Pinterest Pin' : 'Pinterest Pins';
    case ContentType.SNAPCHAT_STORY:
      return contentCount === 1 ? 'Snapchat Story' : 'Snapchat Stories';
    case ContentType.TIKTOK_VIDEO:
      return contentCount === 1 ? 'TikTok Video' : 'TikTok Videos';
    case ContentType.TWITTER_POST:
      return contentCount === 1 ? 'Twitter Tweet' : 'Twitter Tweets';
    case ContentType.YOUTUBE_DEDICATED:
      return contentCount === 1 ? 'YouTube Dedicated Video' : 'YouTube Dedicated Videos';
    case ContentType.YOUTUBE_MENTION:
      return contentCount === 1 ? 'YouTube Product Mention' : 'YouTube Product Mentions';
    default:
      throw new Error(`Unhandled content type: "${contentType}"`);
  }
};

export const getNetworkTypeLabel = (networkType: string): string => {
  switch (networkType) {
    case NetworkType.INSTAGRAM:
      return 'Instagram';
      case NetworkType.FACEBOOK:
        return 'Facebook';
      case NetworkType.PINTEREST:
        return 'Pinterest';
      case NetworkType.TIKTOK:
        return 'Tiktok';
      case NetworkType.TWITTER:
        return 'Twitter';
      case NetworkType.YOUTUBE:
        return 'Youtube';
      case NetworkType.BLOG:
        return 'Blob';
      case NetworkType.SNAPCHAT:
        return 'Snapchat';
      case NetworkType.OTHER:
        return 'Additional Content';
      default:
        throw new Error(`Unhandled content type: "${networkType}"`);
  }
};

export const getBase64 = (file: File): Promise<string | ArrayBuffer> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const hasApprovedAndNotReviewedContent = (
  contentGuidelinesWithDueDates: IContentGuidelinesWithDueDates,
  ): boolean => {
  const {
    requiresReviewBeforeSubmission,
    requiresReviewWithinThreeDaysOfCreatorSubmission,
  } = contentGuidelinesWithDueDates;
  return requiresReviewBeforeSubmission && !requiresReviewWithinThreeDaysOfCreatorSubmission;
};

const hasAnEmptyDate = (date: TDateRequirement) => {
  switch (date.operator) {
    case DateOperator.BEFORE:
      return !date.beforeDate;
    case DateOperator.ON:
      return !date.onDate;
    case DateOperator.BETWEEN:
      return !date.beforeDate || !date.onDate;
    default:
      return false;
  }
};

const hasEndDateBeforeStartDate = (date: TDateRequirement) => {
  if (date.operator !== DateOperator.BETWEEN) {
    return false;
  }
  const beforeDate = moment((date as IDateBetween).beforeDate, 'MM/DD/YYYY');
  const onDate = moment((date as IDateBetween).onDate, 'MM/DD/YYYY');
  return beforeDate.isSame(onDate) || beforeDate.isAfter(onDate);
};

const getDate = (date: TDateRequirement) => {
  switch (date.operator) {
    case DateOperator.BEFORE:
      return date.beforeDate;
    case DateOperator.ON:
      return date.onDate;
    case DateOperator.BETWEEN:
      return date.onDate;
    default:
      return null;
  }
};

export const hasAnyEmptyDeadline = (
  completeDate: Array<TDateRequirement>,
  submissionDate: Array<TDateRequirement>,
): boolean => {
  const hasAnyEmptyCompleteDate = completeDate.some(hasAnEmptyDate);
  const hasAnyEmptySubmissionDate = submissionDate.some(hasAnEmptyDate);
  return hasAnyEmptyCompleteDate || hasAnyEmptySubmissionDate;
};

export const hasCompleteDateBeforeSubmissionDate = (
  requiresReviewBeforeSubmission: boolean,
  completeDates: Array<TDateRequirement>,
  submissionDates: Array<TDateRequirement>,
): boolean => {
  if (requiresReviewBeforeSubmission) {
    return completeDates.every(
      (completeDate, index) => {
        const completeDateValue = moment(getDate(completeDate)).format('MM/DD/YYYY');
        const submissionDateValue = moment(getDate(submissionDates[index])).format('MM/DD/YYYY');
        return moment(submissionDateValue).isSameOrBefore(completeDateValue);
      },
    );
  }
  return true;
};

export const getDueDateValue = (
  dateRequirement: TDateRequirement,
): Date => {
  if (!dateRequirement) return null;
  switch (dateRequirement.operator) {
    case DateOperator.BEFORE: return (dateRequirement as IDateBefore).beforeDate;
    case DateOperator.ON: return (dateRequirement as IDateOn).onDate;
    case DateOperator.BETWEEN: return (dateRequirement as IDateBetween).beforeDate;
    default: return null;
  }
};

export const setDueDateValue = (
  dateRequirement: TDateRequirement,
  date: Date,
): TDateRequirement => {
  switch (dateRequirement.operator) {
    case DateOperator.BEFORE: return { ...dateRequirement, beforeDate: date };
    case DateOperator.ON: return { ...dateRequirement, onDate: date };
    case DateOperator.BETWEEN: return { ...dateRequirement, beforeDate: date };
    default: return null;
  }
};

export const autoPopulateDueDates = (
  dueDates: { submissionDate: TDateRequirement[]; completeDate: TDateRequirement[]; },
  updatedDueDate: Date,
  dueDateType: DueDateType,
): TDateRequirement[] => dueDates[dueDateType].map((dueDate: TDateRequirement, index: number) => {
    const date = getDueDateValue(dueDate);
    if (!date) {
      if (dueDateType === DueDateType.COMPLETE_DATE) {
        const submissionElement = dueDates.submissionDate[index];
        if (!submissionElement) {
          return setDueDateValue(dueDate, updatedDueDate);
        }

        const submissionDate = getDueDateValue(submissionElement);

        if (updatedDueDate > submissionDate) {
          return setDueDateValue(dueDate, updatedDueDate);
        }
          return dueDate;
      }
        return setDueDateValue(dueDate, updatedDueDate);
    }
    return dueDate;
  });

export const hasAnyEndDateBeforeStartDate = (
  completeDate: Array<TDateRequirement>,
  submissionDate: Array<TDateRequirement>,
): boolean => {
  const hasAnyEndDateBeforeStartDateCompleteDate = completeDate.some(hasEndDateBeforeStartDate);
  const hasAnyEndDateBeforeStartDateSubmissionDate = submissionDate.some(hasEndDateBeforeStartDate);
  return hasAnyEndDateBeforeStartDateCompleteDate || hasAnyEndDateBeforeStartDateSubmissionDate;
};

export const hasSelectedInstagramStories = (
  contentGuidelinesWithDueDates: Array<IContentGuidelinesWithDueDates>,
): boolean => contentGuidelinesWithDueDates.some(
  (contentGuidelinesWithDueDate) => contentGuidelinesWithDueDate.type === ContentType.INSTAGRAM_STORY,
);

export const mappedWhitelistingLabel = (
  whitelistingLabel: WhitelistingLabel,
): string => startCase(toLower(whitelistingLabel));
