import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_META_FIELDS_FOR_VARIANT_FROM_SHOPIFY } from '../queries/productDetails';
import { GetMetaFieldsForVariantFromShopify, GetMetaFieldsForVariantFromShopifyVariables } from '../queries/types/GetMetaFieldsForVariantFromShopify';

type IOptions = QueryHookOptions<GetMetaFieldsForVariantFromShopify, GetMetaFieldsForVariantFromShopifyVariables>;

export function useGetMetaFieldsForVariantFromShopify(options: IOptions) {
  const {
    loading, data, error,
  } = useQuery<GetMetaFieldsForVariantFromShopify, GetMetaFieldsForVariantFromShopifyVariables>(
    GET_META_FIELDS_FOR_VARIANT_FROM_SHOPIFY,
    options,
  );

  return {
    isVariantMetaFieldsLoading: loading,
    variantMetaFields: data?.getMetaFieldsValuesFromShopify?.variantMetaFields,
    variantMetaFieldsError: error,
  };
}
