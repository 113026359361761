import {
 useMutation, MutationHookOptions,
} from '@apollo/client';

import { CREATE_INVENTORY_SETTINGS_MUTATION } from '../queries';
import { CreateInventorySettings, CreateInventorySettingsVariables } from '../queries/types/CreateInventorySettings';

type IOptions = MutationHookOptions<CreateInventorySettings, CreateInventorySettingsVariables>;

export function useCreateInventorySettings(options: IOptions = {}) {
  const [
    createInventorySetting, {
    loading,
    data,
    error,
  }] = useMutation<CreateInventorySettings, CreateInventorySettingsVariables>(
    CREATE_INVENTORY_SETTINGS_MUTATION,
    options,
  );

  return {
    createInventorySetting,
    loading,
    clientConfig: data?.createInventorySetting,
    error,
  };
}
