import * as React from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import {
  toUpper, isEmpty, toLower, startCase, isArray, first, sortBy,
} from 'lodash';

import {
 Empty, List, Button, Tooltip, PageHeader, message,
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { LoadSpinner } from '@components';

import { AlloyIntegrationType } from '@frontend/app/types/globalTypes';
import {
  useGetAlloyMetadata,
  useGetAlloyUserToken,
  useGetAlloyWorkflows,
  useActivateAlloyWorkflow,
  useDeactivateAlloyWorkflow,
} from '@frontend/app/hooks';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';

import styles from './AlloyIntegrations.scss';

interface IProps {
  className?: string;
}
interface IMatchParams {
  integration: string;
}

const { useMemo, useCallback } = React;

/**
 * @type {React.FC}
 */
export const AlloyIntegrations: React.FC<IProps> = React.memo(({
  className,
}) => {
  const { integration } = useParams<IMatchParams>();
  const addEvent = useEventContext();
  const { loading: activating, activateAlloyWorkflow } = useActivateAlloyWorkflow();
  const { loading: deactivating, deactivateAlloyWorkflow } = useDeactivateAlloyWorkflow();

  const { loading: loadingMetadata, metadata, refetch } = useGetAlloyMetadata();
  const { loading: loadingWorkflows, workflows } = useGetAlloyWorkflows({
    variables: {
      integration: toUpper(integration) as AlloyIntegrationType,
    },
  });
  const { token } = useGetAlloyUserToken({
    variables: {
      userId: metadata?.alloyUserId,
    },
  });

  const loading = useMemo(() => loadingMetadata || loadingWorkflows, [loadingMetadata, loadingWorkflows]);
  const takingActions = useMemo(() => activating || deactivating, [activating, deactivating]);
  const canInstallWorkflow = useMemo(() => {
    if (!token) {
      return false;
    }
    // eslint-disable-next-line
    if (typeof window['Alloy'] === 'undefined') {
      return false;
    }

    return true;
  }, [token]);
  const onDisableWorkflow = useCallback(async (workflowId: string) => {
    const childWorkflowId = metadata?.workflows[workflowId]?.childWorkflowId;

    await deactivateAlloyWorkflow({
      variables: {
        workflowId,
        childWorkflowId: childWorkflowId || '',
      },
    });

    refetch();

    addEvent(EventName.DeactivateIntegrationWorkflow, {
      integration,
      workflowId,
      childWorkflowId,
    });
  }, [integration, metadata, deactivateAlloyWorkflow, refetch, addEvent]);
  const onInstallWorkflow = useCallback((workflowId: string, isOneTimeWorkflow = false) => {
    try {
      // eslint-disable-next-line
      window['Alloy'].setToken(token);
      // if workflow is already installed
      // eslint-disable-next-line
      window['Alloy'].install({
        token,
        workflowIds: [workflowId],
        alwaysShowAuthentication: true,
        hide: false,
        callback: async (data: { success: boolean, workflowIds?: string | string[] }) => {
          if (!data.success) {
            return;
          }

          let childWorkflowId = data.workflowIds;
          if (isArray(childWorkflowId)) {
            childWorkflowId = first(childWorkflowId);
          }

          await activateAlloyWorkflow({
            variables: {
              workflowId,
              childWorkflowId: childWorkflowId || '',
            },
          });

          refetch();

          if (isOneTimeWorkflow) {
            message.info('Workflow is now running, this might take a few minutes to complete.');

            addEvent(EventName.RunIntegrationSyncWorkflow, {
              integration,
              workflowId,
              childWorkflowId,
            });
          } else {
            addEvent(EventName.InstallIntegrationWorkflow, {
              integration,
              workflowId,
              childWorkflowId,
            });
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, integration, workflows, activateAlloyWorkflow, refetch, addEvent]);

  console.log({
    loading,
    integration,
    token,
    metadata,
    workflows,
    canInstallWorkflow,
  });

  if (loading) {
    return <LoadSpinner className={styles.loading} />;
  }

  return (
    <div className={cx(styles.AlloyIntegrations, className)}>
      <PageHeader title={`${startCase(toLower(integration))} Settings`} />
      {isEmpty(workflows) && <Empty description="No workflow available for the selected integration." />}
      {!isEmpty(workflows) && (
        <List
          className={styles.list}
          bordered
          loading={takingActions}
          dataSource={sortBy(workflows, (w) => -w.sync)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderItem={(workflow: any) => {
            const workflowInstalled = metadata.workflows[workflow.workflowId]?.enabled;
            const isOneTimeWorkflow = workflow.sync === true;
            const errorMessage = metadata.workflows[workflow.workflowId]?.error;

            const actions = [];
            if (isOneTimeWorkflow) {
              // allow running it multiple times
              actions.push(
                <Button
                  type="primary"
                  onClick={() => onInstallWorkflow(workflow.workflowId, true)}
                >
                  Run
                </Button>,
              );
            } else if (workflowInstalled) {
              actions.push(
                <Button
                  danger
                  onClick={() => onDisableWorkflow(workflow.workflowId)}
                >
                  Disable
                </Button>,
              );
            } else {
              actions.push(
                <Button
                  type="primary"
                  onClick={() => onInstallWorkflow(workflow.workflowId)}
                >
                  Install
                </Button>,
              );
            }

            return (
              <List.Item
                actions={actions}
              >
                <div className={styles.content}>
                  {!isEmpty(errorMessage) && (
                    <Tooltip title={errorMessage}>
                      <WarningOutlined className={styles.warningIcon} />
                    </Tooltip>
                  )}
                  <span>{workflow.name}</span>
                </div>
              </List.Item>
            );
          }}
        />
      )}
    </div>
  );
});

AlloyIntegrations.defaultProps = {
  className: null,
};

AlloyIntegrations.displayName = 'AlloyIntegrations';
