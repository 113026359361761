import * as React from 'react';

import { Button } from '@affiliates/AspireUI';

import styles from './NeedsShopifyPermissions.scss';

interface IProps {
  onClick: () => void;
}

export const NeedsShopifyPermissions: React.FC<Readonly<IProps>> = ({ onClick }) => (
  <div className={styles.NeedsShopifyPermissions}>
    <img className={styles.icon} src="///storage.googleapis.com/aspirex-static-files/shopify.svg" />
    <h2 className={styles.title}>
      Looks like you need to authenticate with Shopify to create a Promo Code
      Offer.
    </h2>
    <p className={styles.description}>
      Once you do this, you will be able to create unique Shopify Promo Codes
      in Aspire to give to your members.
    </p>
    <Button className={styles.button} onClick={onClick} type="primary">
      Authenticate with Shopify
    </Button>
  </div>
  );
