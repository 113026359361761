import * as React from 'react';
import cx from 'classnames';
import {
  DeactivateButton,
  ActivateButton,
  PopoverType,
  SuccessTag,
  InactiveText,
  renderFeatures,
} from '@frontend/app/components/InstagramMeta/MetaItems';
import styles from '@frontend/app/components/InstagramMeta/MetaItems/MetaItems.module.scss';
import { ITiktokAdAccountRow } from './TikTokAdAccountsTable';
import { composeSparkAdsFeatures } from '../utils/composeFeatures';

const renderTagComponent = (enabled: boolean) => {
  if (enabled) {
    return SuccessTag;
  }
  return InactiveText;
};

export const renderTikTokSparkAdsTag = (
  isLoading: boolean,
  record: ITiktokAdAccountRow,
  activateAccount: () => void,
  deactivateAccount: () => void,
) => {
  const CellComponent = renderTagComponent(record.sparkAds.enabled);
  const shouldDisable = isLoading;

  let popoverType = PopoverType.SPARK_ADS;
  if (!record.sparkAds.enabled) {
    popoverType = PopoverType.HIDDEN;
  }
  let TagComponent = <CellComponent />;
  if (record.sparkAds.enabled) {
    TagComponent = (
      <span className={styles.Main}>
        {!isLoading && <CellComponent className={styles.Hide} />}
        <DeactivateButton
          className={cx({ [styles.Show]: !isLoading })}
          deactivateAccount={deactivateAccount}
          loading={isLoading}
          disabled={shouldDisable}
        />
      </span>
    );
  } else {
    TagComponent = (
      <span className={styles.Main}>
        {!false && <CellComponent className={styles.Hide} />}
        <ActivateButton
          className={cx({ [styles.Show]: !isLoading })}
          activateAccount={activateAccount}
          loading={isLoading}
          disabled={shouldDisable}
        />
      </span>
    );
  }

  return renderFeatures({
    features: composeSparkAdsFeatures(),
    popoverType,
  }, TagComponent);
};
