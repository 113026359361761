import 'css-chunk:src/app/containers/MessagingApp/MessageList/EmailMessageItem.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_bf4c9_157",
  "justify-content-space-between": "_justify-content-space-between_bf4c9_161",
  "tabular-nums": "_tabular-nums_bf4c9_165",
  "EmailMessageItem": "_EmailMessageItem_bf4c9_178",
  "header": "_header_bf4c9_178",
  "info": "_info_bf4c9_178",
  "name": "_name_bf4c9_178",
  "text": "_text_bf4c9_188",
  "snippet": "_snippet_bf4c9_188",
  "nameAndDate": "_nameAndDate_bf4c9_198",
  "date": "_date_bf4c9_198",
  "collapsed": "_collapsed_bf4c9_282",
  "avatar": "_avatar_bf4c9_296",
  "automationIcon": "_automationIcon_bf4c9_300",
  "email": "_email_bf4c9_316",
  "memberLink": "_memberLink_bf4c9_333",
  "messageDetail": "_messageDetail_bf4c9_343",
  "content": "_content_bf4c9_356",
  "hideQuote": "_hideQuote_bf4c9_359",
  "attachments": "_attachments_bf4c9_362",
  "actions": "_actions_bf4c9_365",
  "showQuoteButton": "_showQuoteButton_bf4c9_368",
  "showLess": "_showLess_bf4c9_371",
  "show": "_show_bf4c9_368"
};