._deeplinksList_1d7gn_1 {
  transition: opacity 0.3s ease, max-height 0.3s ease;
  margin-top: 16px;
}
._deeplinksList_1d7gn_1 .ant-space {
  width: 100%;
}

._collapseBg_1d7gn_9 {
  background-color: white;
}
._collapseBg_1d7gn_9 .ant-collapse-content-box {
  background-color: white;
  padding: 8px;
}
._collapseBg_1d7gn_9 .ant-collapse-item {
  border: 1px solid #e8e8e8;
  border-radius: 8px !important;
  margin: 8px 0px;
}