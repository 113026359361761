/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_9q7nv_157 {
  display: flex;
}

._justify-content-space-between_9q7nv_161 {
  justify-content: space-between;
}

._tabular-nums_9q7nv_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_9q7nv_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._drawerHeader_9q7nv_178 {
  display: flex;
  justify-content: center;
}

._OfferConversionHistoryDrawer_9q7nv_183 {
  padding-top: 1.5rem;
  border-top: 1px solid #f0f0f0;
  margin-top: 0.5rem;
}
._OfferConversionHistoryDrawer_9q7nv_183 ._OfferConversionHistoryTableHeader_9q7nv_188 {
  margin-bottom: 1rem;
}

.offerConversionTable .ant-pagination-item, .offerConversionTable .ant-pagination-jump-next, .offerConversionTable .ant-pagination-jump-prev {
  display: none;
}
.offerConversionTable .customPagination {
  position: absolute;
  top: -60px;
  z-index: 99;
  right: 0;
}
.offerConversionTable .customPagination .ant-btn {
  border: none;
  background: transparent;
}
.offerConversionTable .customPagination .ant-btn svg {
  font-size: 24px;
  margin-top: -8px;
}