import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_SELECTION_CRITERIA } from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import { GetCatalogSelectionRuleByCatalogId, GetCatalogSelectionRuleByCatalogIdVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetCatalogSelectionRuleByCatalogId';

type IOptions = QueryHookOptions<GetCatalogSelectionRuleByCatalogId, GetCatalogSelectionRuleByCatalogIdVariables>;

export const useGetSelectionCriteria = (options: IOptions) => {
  const {
    loading, data, error, refetch,
   } = useQuery(GET_SELECTION_CRITERIA, options);

  return {
    loading,
    criteria: data?.getCatalogSelectionRuleByCatalogId ?? [],
    error,
    refetch,
  };
};
