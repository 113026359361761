import gql from 'graphql-tag';

export const UPDATE_DEEPLINK_MUTATION = gql`
  mutation UpdateDeeplink($data: AffiliateDeepLinkUpdateInput!) {
    updatedDeeplink: updateDeepLink(data: $data) {
      affiliateDeepLinkUrl,
      createdDate,
      label,
      url,
      deletedDate,
      id,
      shortLink,
      tuneShortLinkId,
    }
  }
`;
