._Wizard_h1wkx_1 ._content_h1wkx_1 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._Wizard_h1wkx_1 ._content_h1wkx_1 ._header_h1wkx_6 {
  flex: 0 0 64px;
  height: 64px;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;
}
._Wizard_h1wkx_1 ._content_h1wkx_1 ._header_h1wkx_6 ._headerContentWrapper_h1wkx_18 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Wizard_h1wkx_1 ._content_h1wkx_1 ._header_h1wkx_6 ._headerIcon_h1wkx_23 {
  margin-right: 8px;
}
._Wizard_h1wkx_1 ._content_h1wkx_1 ._mainContentWrapper_h1wkx_26 {
  flex: 1 1 auto;
  min-height: 1px;
  overflow: hidden scroll;
  padding: 32px;
}
._Wizard_h1wkx_1 ._content_h1wkx_1 ._footer_h1wkx_32 {
  flex: 0 0 88px;
  height: 88px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._Wizard_h1wkx_1 ._content_h1wkx_1 ._footer_h1wkx_32 ._footerButton_h1wkx_42 {
  width: 270px;
}