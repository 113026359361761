import * as React from 'react';
import { Row, Typography } from '@revfluence/fresh';
import {
  endOfWeek, format, isSameMonth, lastDayOfMonth,
} from 'date-fns';

import { endOfMonth } from 'date-fns/esm';
import {
  contentColor,
  engagementsColor,
  impressionsColor,
  investmentColor,
  salesColor,
  ILineChartROIData,
  TChartTypes,
  TChartFilterTypes,
  stringToDate,
  isPartialWeek,
  isPartialMonth,
  getStartDate,
  getEndDate,
} from './LineChart';
import { tickFormatters } from '../../utils/tickFormatters';
import styles from './Tooltip.module.scss';

const { money, usNumber } = tickFormatters;

const { Text } = Typography;

interface ITooltipRowProps {
  boldTextValue?: boolean;
  circleColor?: string;
  label: string;
  value: string;
  hidden?: boolean;
}

const TooltipRow = (props: ITooltipRowProps) => {
  const valueWeightText: 'semibold' | 'regular' = props.boldTextValue ? 'semibold' : 'regular';
  if (props.hidden) {
    return null;
  }
  return (
    <Row justify="space-between">
      <Row align="middle" className={styles.rowText}>
        {props.circleColor && (
          <div
            className={styles.rowCircle}
            style={{
              background: props.circleColor,
            }}
          />
        )}
        <Text weight="semibold">{props.label}</Text>
      </Row>
      <Text weight={valueWeightText}>{props.value}</Text>
    </Row>
  );
};

const TooltipDivider = () => <div className={styles.rowDivider} />;

interface ICustomTooltipProps {
  active: boolean;
  payload: { payload: ILineChartROIData }[];
  label: string;
}

const getTooltipTitle = (type: TChartTypes, value: string, [startDate, endDate]: [Date, Date]): [string, string?] => {
  const date = stringToDate(value);
  const tooltipFormat = (date: Date) => format(date, 'MMM d');
  switch (type) {
    case 'daily': {
      const day = tooltipFormat(date);
      return [day, undefined];
    }
    case 'weekly': {
      const weekBegin = tooltipFormat(getStartDate(date, startDate));
      const endOfWeekDay = endOfWeek(date, { weekStartsOn: 1 });
      const lastDay = isSameMonth(date, endOfWeekDay) ? endOfWeekDay : lastDayOfMonth(date);
      const isPartial = isPartialWeek(date, endDate);
      const weekEnd = tooltipFormat(getEndDate(lastDay, endDate));
      const text = `${weekBegin} - ${weekEnd}`;
      const subtext = isPartial ? '(Partial Week)' : undefined;
      return [text, subtext];
    }
    case 'monthly': {
      const monthBegin = tooltipFormat(getStartDate(date, startDate));
      const monthEndDay = endOfMonth(date);
      const lastDay = getEndDate(monthEndDay, endDate);
      const isPartial = isPartialMonth(date, endDate);
      const monthEnd = tooltipFormat(lastDay);
      const text = `${monthBegin} - ${monthEnd}`;
      const subtext = isPartial ? '(Partial Month)' : undefined;
      return [text, subtext];
    }
  }
};

export const factoryCustomTooltip = (type: TChartTypes, dateRange: [Date, Date], filter?: TChartFilterTypes) => {
  const CustomTooltip = ({ active, payload, label }: ICustomTooltipProps) => {
    if (active && payload && payload.length) {
      const currentPayload = payload.find((p) => p.payload.date === label);
      const [text, subtext] = getTooltipTitle(type, label, dateRange);
      const content = currentPayload.payload.content;
      const engagements = currentPayload.payload.engagements;
      const impressions = currentPayload.payload.impressions;
      const investment = currentPayload.payload.investment;
      const sales = currentPayload.payload.sales;
      const sumValue = (value: number, rowType: TChartFilterTypes) => (filter ? filter !== rowType ? 0 : value : value);
      const total = sumValue(content, 'content') + sumValue(impressions, 'impressions')
        + sumValue(engagements, 'engagements') + sumValue(sales, 'sales') - investment;
      const impact = total / investment;
      const impactValue = (n: number) => (Number.isFinite(n) ? `${usNumber(n)}x` : '-');
      const PartialText = subtext ? <Text>{subtext}</Text> : null;
      const hideRow = (rowType: TChartFilterTypes) => (filter ? filter !== rowType : false);
      return (
        <div className={styles.CustomTooltip}>
          <Row align="middle">
            <Text weight="semibold" style={{ marginRight: 4 }}>{text}</Text>
            {PartialText}
          </Row>
          <TooltipDivider />
          <TooltipRow hidden={hideRow('content')} circleColor={contentColor} label="Content" value={money(content)} />
          <TooltipRow hidden={hideRow('impressions')} circleColor={impressionsColor} label="Awareness" value={money(impressions)} />
          <TooltipRow hidden={hideRow('engagements')} circleColor={engagementsColor} label="Engagements" value={money(engagements)} />
          <TooltipRow hidden={hideRow('sales')} circleColor={salesColor} label="Sales" value={money(sales)} />
          <TooltipRow circleColor={investmentColor} label="Investment" value={money(investment)} />
          <TooltipDivider />
          <TooltipRow label="Total Value" value={money(total)} />
          <TooltipRow label="Divide Investment" value={`(${money(investment)})`} />
          <TooltipDivider />
          <TooltipRow label="Impact Value" boldTextValue value={`${impactValue(impact)}`} />
        </div>
      );
    }

    return null;
  };
  return CustomTooltip;
};
