import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const IosArrowRight = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="M6.66633 12.6667C6.51566 12.6667 6.36433 12.616 6.23966 12.512C5.957 12.2767 5.919 11.856 6.15433 11.5734L9.13833 7.99272L6.26166 4.41805C6.031 4.13138 6.07633 3.71138 6.363 3.48072C6.65033 3.25005 7.06966 3.29538 7.301 3.58205L10.5197 7.58205C10.7183 7.82938 10.7157 8.18272 10.5123 8.42672L7.179 12.4267C7.047 12.5847 6.85766 12.6667 6.66633 12.6667Z"
      fill="#231F20"
    />
  </SvgIcon>
));

IosArrowRight.displayName = 'IosArrowRight';
