import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

import { logger } from '@common';
import { ProgramInput } from '@api/src/graphql/inputs';
import { Button, Modal, Radio } from '@revfluence/fresh';
import { useSaveProjectCampaign } from '@frontend/app/hooks';
import { TextInput, ProjectApplicationPage } from '@frontend/app/components';

import { TriangleExclamationIcon, TrashCanIcon } from '@revfluence/fresh-icons/regular/esm';
import { Space } from 'antd';
import { useDeleteProgramApplicationPage, useGetCampaignByProjectId } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { IApplicationFormFields, TTemplateProps } from '../types';
import { ProjectApplicationPageTemplateName } from '../../applicationPageUtils';
import { useFields } from '../LeftPanel/hooks';
import { IFrame } from './IFrame';

import styles from './RightPanel.scss';

const {
 useCallback, useRef, useMemo, useState,
} = React;
interface IProps {
  templateProps?: TTemplateProps;
  className?: string;
  activeKey: string;
  applicationFormFields?: IApplicationFormFields;
  onSubmit: () => Promise<unknown>;
  onDelete: () => void;
  clientId?: string;
  clientHostname?: string;
  project?: ProgramInput;
  hasError?: boolean;
}

const LAYOUTS = {
  desktop: 'desktop',
  mobile: 'mobile',
};

const layoutPickerOptions = [
  { value: 'desktop', label: 'Web' },
  { value: 'mobile', label: 'Mobile' },
];

export const RightPanel: React.FC<IProps> = React.memo(({
  templateProps,
  className,
  activeKey,
  applicationFormFields,
  onSubmit,
  clientId,
  clientHostname,
  project,
  onDelete,
  hasError,
}) => {
  const {
    showMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  const {
    data: {
      campaign = null,
    } = {},
    refetch: refetchCampaign,
  } = useGetCampaignByProjectId(project?.id, {
    skip: !project?.id,
  });

  const [deleteApplicationPage, { loading: isDeleting }] = useDeleteProgramApplicationPage();

  const layoutContainer = useRef<HTMLDivElement>(null);
  const [activeLayout, setActiveLayout] = useState<string>(LAYOUTS.desktop);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveProjectCampaign] = useSaveProjectCampaign();

  const onChangeLayout = (event) => {
    setActiveLayout(event.target.value);
  };

  const onSavePreview = async () => {
    if (isFunction(onSubmit)) {
      setIsSubmitting(true);

      if (!templateProps.settings) {
        templateProps = {
          ...templateProps,
          ...{ settings: { page_online: false } },
        };
      }
      templateProps.settings.page_online = true;

      return onSubmit()
        .then(() => {
          setIsSubmitting(false);
        })
        .catch(logger.error);
    }
  };

  const baseUrl = useMemo(() => {
    const url = `${window?.location?.origin}/join/`;
    return clientHostname ? url.replace('community', clientHostname) : url;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, clientHostname]);

  const fullUrl = useMemo(() => {
    const landingPagePath = encodeURIComponent(project?.customLandingPagePath || '');
    const url = `${baseUrl}${landingPagePath}`;
    return clientHostname ? url : `${url}?clientId=${clientId}`;
  }, [baseUrl, clientId, clientHostname, project]);

  const favicon = templateProps.settings?.favicon ?? '';
  const fields = useFields(applicationFormFields);

  const onConfirmDelete = useCallback(async () => {
    if (!project?.id) {
      return;
    }

    try {
      await deleteApplicationPage({
        variables: {
          programId: project.id,
        },
      });

      if (campaign?.enabled_ts && !campaign?.disabled_ts) {
        await saveProjectCampaign({
          variables: {
            projectId: project.id,
            campaign: {
              status: 'UNPUBLISH',
            },
          },
        });

        await refetchCampaign();
      }

      showMessage({
        type: 'success',
        content: 'Application page deleted successfuly.',
      });

      onDelete();
    } catch (err) {
      // TODO: This should be improved by the new error messaging system.
      showGenericErrorMessage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.id, deleteApplicationPage]);

  const onIntentDelete = useCallback(() => {
    const isListed = campaign?.enabled_ts && !campaign.disabled_ts;

    Modal.confirm({
      title: isListed
        ? 'This page is live in the Marketplace.'
        : 'Delete this application page?',
      content: isListed
        ? 'Deleting this application page will de-list it from the Marketplace and prevent creators from applying to this project until a new page is created and saved.'
        : 'All content will be deleted permanently. Creators will not be able to apply until a new page is created and saved.',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      centered: true,
      maskClosable: true,
      cancelButtonProps: {
      },
      okButtonProps: {
        danger: true,
        type: 'default',
      },
      icon: <TriangleExclamationIcon fill="#ff4d4f" />,
      onOk: onConfirmDelete,
    });
  }, [onConfirmDelete, campaign?.enabled_ts, campaign?.disabled_ts]);

  return (
    <div className={cx(className, styles.RightPanel)}>
      <div className={styles.header}>
        <Radio.Group
          options={layoutPickerOptions}
          value={activeLayout}
          onChange={onChangeLayout}
          optionType="button"
          buttonStyle="solid"
        />

        <div className={styles.buttons}>
          <Space size="middle">
            {
                project?.published
                  && (
                    <Button
                      danger
                      icon={<TrashCanIcon />}
                      loading={isDeleting}
                      onClick={onIntentDelete}
                    >
                      Delete
                    </Button>
                  )
              }
            <Button
              type="primary"
              onClick={onSavePreview}
              disabled={isSubmitting || hasError}
            >
              Save and View
            </Button>
          </Space>
        </div>
      </div>
      <div
        ref={layoutContainer}
        className={cx(styles.layout, {
            [styles.mobileLayout]: activeLayout === LAYOUTS.mobile,
            [styles.desktopLayout]: activeLayout === LAYOUTS.desktop,
          })}
      >
        <div className={styles.urlPanel}>
          {favicon && <img className={styles.favicon} src={favicon} />}
          <TextInput
            className={styles.url}
            value={fullUrl}
          />
        </div>

        <IFrame className={styles.frameLayout}>
          <ProjectApplicationPage
            template={project?.applicationPageTemplateName as ProjectApplicationPageTemplateName}
            isPreview
            config={templateProps || {}}
            applicationFormFields={fields}
            scrollTo={activeKey}
            hasUnpaidOffer={project?.hasUnpaidOffer}
          />
        </IFrame>
      </div>
    </div>
  );
});

RightPanel.displayName = 'RightPanel';
