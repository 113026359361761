import * as React from 'react';
import { Tag } from '@revfluence/fresh';
import { CircleCheckIcon, CircleXIcon, CommentQuestionIcon } from '@revfluence/fresh-icons/solid/esm';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './CreatorStatus.scss';
import { Statuses } from './GroupTypes';

const CreatorStatusCell = ({ memberRecord }) => {
  let status = null;
  switch (memberRecord.brand_approval) {
    case Statuses.APPROVED:
      status = (
        <Tag className={styles.approved}>
          <CircleCheckIcon />
          Approved
        </Tag>
      );
      break;
    case Statuses.REJECTED:
      status = (
        <Tag className={styles.rejected}>
          <CircleXIcon />
          Rejected
        </Tag>
      );
      break;
    case Statuses.MAYBE:
      status = (
        <Tag className={styles.maybe}>
          <CommentQuestionIcon />
          Maybe
        </Tag>
      );
      break;
    default:
      status = (
        <Tag className={styles.pending}>
          <CircleInfoIcon />
          Pending Review
        </Tag>
      );
  }
  return <div className={styles.status}>{status}</div>;
};

export default CreatorStatusCell;
