import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import {
  DESELECT_BUSINESS,
} from '@frontend/app/queries';

import {
  deselectBusiness,
  deselectBusinessVariables,
} from '@frontend/app/queries/types/deselectBusiness';

type IOptions = MutationHookOptions<deselectBusiness, deselectBusinessVariables>;

export const useDeselectBusinessMutation = (options: IOptions = {}) => (
  useMutation<deselectBusiness>(DESELECT_BUSINESS, options)
);

export type TUseDeselectBusinessMutation = ReturnType<typeof useDeselectBusinessMutation>;
