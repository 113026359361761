import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_COMPLETED_MEMBERS_QUERY } from '@frontend/app/queries';
import {
  GetAllCompletedMembersQuery,
  GetAllCompletedMembersQueryVariables,
} from '@frontend/app/queries/types/GetAllCompletedMembersQuery';

type IOptions = QueryHookOptions<GetAllCompletedMembersQuery, GetAllCompletedMembersQueryVariables>;

export const useGetAllCompletedMembersQuery = (options: IOptions = {}) => (
  useQuery<GetAllCompletedMembersQuery, GetAllCompletedMembersQueryVariables>(GET_ALL_COMPLETED_MEMBERS_QUERY, options)
);
