import * as React from 'react';

interface IProps {
  showComponent: boolean;
}

const AbTestContainer: React.FC<IProps> = React.memo((props) => {
  const {
    showComponent,
  } = props;
  if (showComponent) {
    return (
      <>
        {props.children}
      </>
    );
  }
  return null;
});

AbTestContainer.defaultProps = {
  showComponent: false,
};

AbTestContainer.displayName = 'AbTestContainer';

export default AbTestContainer;
