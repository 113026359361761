import * as React from 'react';
import { size } from 'lodash';

import { Table, ITableColumnConfig, IRowData } from '@components';

interface IProps {
  data: IRowData[];
}

const columns: ITableColumnConfig[] = [
  {
    headerName: 'Payment Due',
    cellType: 'numeric',
    field: 'price',
    isPrice: true,
    width: 140,
    sortable: true,
  },
  {
    headerName: 'Project',
    cellType: 'text',
    field: 'program_name',
    width: 180,
    sortable: true,
  },
  {
    headerName: 'Terms Status',
    cellType: 'text',
    field: 'status',
    width: 140,
    sortable: true,
  },
  {
    headerName: 'Agreement Date',
    cellType: 'date',
    field: 'agreed_date',
    isUnix: true,
    dateFormatStr: 'MMM d, yyyy',
    width: 160,
    sortable: false,
  },
  {
    headerName: 'Created Date',
    cellType: 'date',
    field: 'created_date',
    isUnix: true,
    dateFormatStr: 'MMM d, yyyy',
    width: 140,
    sortable: false,
  },
];

export const TermsTable: React.FunctionComponent<IProps> = ({ data }) => (
  <Table
    ref={null}
    data={data}
    emptyMessage="No briefs found."
    columns={columns}
    config={{
      pageSize: size(data) > 5 ? 5 : undefined,
      allowSearch: false,
      configurableColumns: false,
      rowHeight: 40,
      striped: true,
      rowBorder: true,
      selectable: false,
      highlightOnHover: false,
    }}
  />
  );
