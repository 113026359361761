import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const PersonAddIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 7.33333C8.13733 7.33333 9.33333 6.13733 9.33333 4.66667C9.33333 3.196 8.13733 2 6.66667 2C5.196 2 4 3.196 4 4.66667C4 6.13733 5.196 7.33333 6.66667 7.33333ZM14 4H13.3333V3.33333C13.3333 2.96667 13.0333 2.66667 12.6667 2.66667C12.3 2.66667 12 2.96667 12 3.33333V4H11.3333C10.9667 4 10.6667 4.3 10.6667 4.66667C10.6667 5.03333 10.9667 5.33333 11.3333 5.33333H12V6C12 6.36667 12.3 6.66667 12.6667 6.66667C13.0333 6.66667 13.3333 6.36667 13.3333 6V5.33333H14C14.3667 5.33333 14.6667 5.03333 14.6667 4.66667C14.6667 4.3 14.3667 4 14 4ZM11.3333 13.3333C11.3333 13.7013 11.0353 14 10.6667 14H2.66667C2.298 14 2 13.7013 2 13.3333C2 10.76 4.094 8.66667 6.66667 8.66667C9.23933 8.66667 11.3333 10.76 11.3333 13.3333Z" />
  </SvgIcon>
));

PersonAddIcon.displayName = 'PersonAddIcon';
