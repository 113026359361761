import 'css-chunk:src/app/containers/GuestHome/Groups/GroupCreators.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1qf9x_157",
  "justify-content-space-between": "_justify-content-space-between_1qf9x_161",
  "tabular-nums": "_tabular-nums_1qf9x_165",
  "creatorSec": "_creatorSec_1qf9x_178",
  "row": "_row_1qf9x_178",
  "creatorCard": "_creatorCard_1qf9x_183",
  "creatorList": "_creatorList_1qf9x_188",
  "head": "_head_1qf9x_191",
  "groupImage": "_groupImage_1qf9x_206",
  "tableWrapper": "_tableWrapper_1qf9x_217",
  "brandNote": "_brandNote_1qf9x_243",
  "nameProfile": "_nameProfile_1qf9x_251",
  "profile": "_profile_1qf9x_256",
  "name": "_name_1qf9x_251",
  "action": "_action_1qf9x_271",
  "accept": "_accept_1qf9x_289",
  "reject": "_reject_1qf9x_292",
  "nameHandle": "_nameHandle_1qf9x_295",
  "show": "_show_1qf9x_1"
};