import { TMemberInfoForPayment } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
    memberInfo: TMemberInfoForPayment;
    type: ActionType.SET_MEMBER_INFO;
}
export type TSetMemberInfoAction = Readonly<IAction>;

export const setMemberInfo = (state: TState, action: TSetMemberInfoAction): TState => ({
    ...state,
    memberInfo: action.memberInfo,
});
