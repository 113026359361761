import {
 isEmpty, toLower, trim, replace,
} from 'lodash';

import { SocialAccountFields } from '@frontend/app/constants/socialAccountFields';
import { SocialAccountType } from '@frontend/app/types/SocialAccountType';

const NETWORKS_WITH_AT_SYMBOL = [
  SocialAccountType.INSTAGRAM,
  SocialAccountType.TWITTER,
  SocialAccountType.PINTEREST,
  SocialAccountType.TIKTOK,
];

const validateSocialHandleAscii = (value: string, network: string): string => {
  const testRegex = /^[\x20-\x7F]*$/.test(value); // check for non-ascii chars

  if (!testRegex) {
    throw new Error(`The social handle ${value} for ${network} contains non-ascii characters`);
  }

  return value;
};

const socialUsernameValidators = {
  instagram: (value: string): string => {
    const handleRegex = /^(?:(?:https?:\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/?|@+)?((?!.*\.\.)(?!.*\.$)[^\W][\._a-zA-Z0-9]{0,29})(?:[\?\/].*)?$/gm;

    const socialHandle = handleRegex.exec(value);

    if (!socialHandle || !socialHandle[1]) {
      throw new Error(`The social handle @${value} for Instagram is incorrect. Please try again.`);
    }

    return socialHandle[1];
  },
  tiktok: (value: string): string => {
    const testRegex = /^[a-z0-9._]+$/.test(value);

    if (!testRegex) {
      throw new Error(`The social handle @${value} for TikTok is incorrect. Please try again.`);
    }

    return value;
  },
  pinterest: (value: string): string => {
    const testRegex = /^[a-z0-9_]+$/.test(value);

    if (!testRegex) {
      throw new Error(`The social handle @${value} for Pinterest is incorrect. Please try again.`);
    }

    return value;
  },
  twitter: (value: string): string => {
    const testRegex = /^[a-z0-9._]+$/.test(value);

    if (!testRegex) {
      throw new Error(`The social handle @${value} for Twitter is incorrect. Please try again.`);
    }

    return value;
  },
  youtube: (value: string) => validateSocialHandleAscii(value, 'YouTube'),
  facebook: (value: string) => validateSocialHandleAscii(value, 'Facebook'),
  blog: (value: string) => validateSocialHandleAscii(value, 'Blog'),
};

export interface IGetSocialHandle {
  network: SocialAccountType | string;
  username: string;
  shouldValidateUsername?: boolean;
  shouldPrependAtSymbol?: boolean;
}

export const getSocialHandle = (props: IGetSocialHandle): string => {
  const {
    network,
    username,
    shouldValidateUsername,
    shouldPrependAtSymbol = true,
  } = props;

  const networkPrependsAtSymbol = NETWORKS_WITH_AT_SYMBOL.includes(toLower(network) as SocialAccountType);

  const filteredUsername = trim(
    networkPrependsAtSymbol
      ? replace(username, /@/g, '')
      : username,
  );

  let formattedUsername = filteredUsername;

  if (networkPrependsAtSymbol) {
    formattedUsername = toLower(formattedUsername);
  }

  const validator = socialUsernameValidators[toLower(network)];

  if (
    validator
      && shouldValidateUsername
      && !isEmpty(formattedUsername)
  ) {
    formattedUsername = validator(formattedUsername);
  }

  if (
    !isEmpty(formattedUsername)
      && shouldPrependAtSymbol
      && networkPrependsAtSymbol
  ) {
    formattedUsername = `@${formattedUsername}`;
  }

  return formattedUsername;
};

export const isSocialField = (fieldName: string): boolean => SocialAccountFields.some((field) => field.name === fieldName);
