import gql from 'graphql-tag';

export const GET_KLAVIYO_SYNC_SETTINGS = gql`
  query GetKlaviyoSyncSettings {
    getSyncSettings {
      config {
        automatedSync
        syncAllMembers
        groups
        projects
      }
    }
  }
`;
