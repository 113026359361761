import 'css-chunk:src/components/widgets/SocialProfile/Card/SummaryCard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "audienceLocationBarChart": "_audienceLocationBarChart_18uns_48",
  "audienceAgeBarChart": "_audienceAgeBarChart_18uns_49",
  "tooltip": "_tooltip_18uns_98",
  "flex": "_flex_18uns_258",
  "justify-content-space-between": "_justify-content-space-between_18uns_262",
  "tabular-nums": "_tabular-nums_18uns_266",
  "detail": "_detail_18uns_266",
  "average_engagement": "_average_engagement_18uns_266",
  "value": "_value_18uns_266",
  "impression": "_impression_18uns_266",
  "age_range": "_age_range_18uns_266",
  "audience_authenticity": "_audience_authenticity_18uns_266",
  "impression_per_story": "_impression_per_story_18uns_266",
  "title": "_title_18uns_271",
  "Card": "_Card_18uns_271",
  "cardHeader": "_cardHeader_18uns_421",
  "tabs": "_tabs_18uns_432",
  "tabButton": "_tabButton_18uns_436",
  "activeTab": "_activeTab_18uns_455",
  "separator": "_separator_18uns_459",
  "cardTab": "_cardTab_18uns_466",
  "cardTitle": "_cardTitle_18uns_474",
  "visible": "_visible_18uns_482",
  "show": "_show_18uns_1",
  "noData": "_noData_18uns_487",
  "icon": "_icon_18uns_813",
  "horizontal": "_horizontal_18uns_835",
  "gender": "_gender_18uns_852",
  "dislikes_per_video": "_dislikes_per_video_18uns_855",
  "high": "_high_18uns_859",
  "good": "_good_18uns_863",
  "suspicious": "_suspicious_18uns_867",
  "low": "_low_18uns_868",
  "positive": "_positive_18uns_872",
  "neutral": "_neutral_18uns_876",
  "negative": "_negative_18uns_880",
  "reviewedIcon": "_reviewedIcon_18uns_884",
  "SummaryCard": "_SummaryCard_18uns_890",
  "audienceTab": "_audienceTab_18uns_890",
  "creatorTab": "_creatorTab_18uns_891",
  "detailGrid": "_detailGrid_18uns_894",
  "blog": "_blog_18uns_901",
  "facebook": "_facebook_18uns_901",
  "twitter": "_twitter_18uns_901",
  "tiktok": "_tiktok_18uns_904",
  "creatorSummary": "_creatorSummary_18uns_907",
  "interests": "_interests_18uns_913",
  "brands": "_brands_18uns_914",
  "interestsWrapper": "_interestsWrapper_18uns_928",
  "brandGrid": "_brandGrid_18uns_935",
  "brand": "_brand_18uns_914",
  "moreBrands": "_moreBrands_18uns_942"
};