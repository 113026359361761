import * as React from 'react';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import {
  Button,
  Image,
  Row,
  Space,
  Typography,
} from '@revfluence/fresh';

import styles from '@frontend/applications/SocialPostApp/components/BrandAuth.scss';

const { Title, Link } = Typography;

interface IBrandAuthFailurePage {
  clientFailRedirect: () => void,
  insightsArticle: string,
  heroImage: string,
  logoImage: string,
}

export const BrandAuthFailure: React.FunctionComponent<IBrandAuthFailurePage> = (props) => {
  const {
    clientFailRedirect,
    insightsArticle,
    heroImage,
    logoImage,
  } = props;

  return (
    <Row justify="center">
      <Space
        direction="vertical"
        size="large"
        align="center"
      >
        <Image
          preview={false}
          width={546}
          src={heroImage}
        />
      </Space>
      <Space
        direction="vertical"
        size="large"
        align="center"
      >
        <Image
          className={styles.imgLogo}
          preview={false}
          width={309}
          src={logoImage}
        />
        <TriangleExclamationIcon
          width="4rem"
          height="4rem"
          color="#f0f0f0"
        />
        <Title level={4} className={styles.titleStyle}>
          Please re-attempt to connect your Insights by going to your Settings Page
          in the Aspire app.
          If you have questions about linking your brand’s
          Instagram Insights check out these
          {' '}
          <Link rel="noopener noreferrer" href={insightsArticle} target="_blank">
            Help Articles
          </Link>
          {' '}
          or contact our
          {' '}
          <Link rel="noopener noreferrer" href="mailto:help@aspireiq.com" target="_blank">
            Support Team
          </Link>
          .
        </Title>
        <Button
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          label="Settings Page"
          onClick={clientFailRedirect}
          size="large"
          type="primary"
        >
          Settings Page
        </Button>
      </Space>
    </Row>
  );
};
