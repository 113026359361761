import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { SKIP_WORK_ITEMS_MUTATION } from '@frontend/app/queries';
import {
  SkipWorkItemsMutation,
  SkipWorkItemsMutationVariables,
} from '@frontend/app/queries/types/SkipWorkItemsMutation';

type IOptions = MutationHookOptions<SkipWorkItemsMutation, SkipWorkItemsMutationVariables>;

export const useSkipWorkItemsMutation = (options: IOptions = {}) => (
  useMutation(SKIP_WORK_ITEMS_MUTATION, options)
);
