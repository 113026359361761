import * as React from 'react';

import { pick, trim } from 'lodash';

import { Input, SubmitButton, Button } from '@components';

import { MemberInput } from '@frontend/app/types/globalTypes';

import { SimpleForm, SimpleField } from '@frontend/app/components';
import { useUpdateMemberMutation } from '@frontend/app/hooks';
import { UpdateMemberMutation_member as IMember } from '@frontend/app/queries/types/UpdateMemberMutation';
import { PaymentReducerActionType } from '../pages/NewPayment';

import styles from './PayeeSetup.scss';

const { useState, useRef } = React;
interface IProps {
  member: IMember;
  paypalAddressAppField: number;
  dispatch(action): void;
  canCancelEditing: boolean;
}

interface IPaypalAddressUpdateFormData {
  paypal_address: string;
}

export const PayeeSetup: React.FunctionComponent<IProps> = (props) => {
  const {
    dispatch, member, paypalAddressAppField, canCancelEditing,
  } = props;

  const [input, setInput] = useState<IPaypalAddressUpdateFormData>({
    paypal_address: member.fields[paypalAddressAppField],
  });

  const formRef = useRef<SimpleForm>();

  const handleClickSave = () => {
    formRef.current.submit();
  };

  const handleChangeFields = (fieldName: string, value: string) => {
    setInput((i) => ({ ...i, [fieldName]: trim(value) }));
  };

  const formValues = pick(input, ['paypal_address']);

  const onMemberUpdated = () => {
    dispatch({
      type: PaymentReducerActionType.SetPayeeAddress,
      value: formValues.paypal_address,
    });
  };

  const [updateMember, { loading }] = useUpdateMemberMutation({
    onCompleted: onMemberUpdated,
  });

  const handleSubmit = () => {
    const memberInput: MemberInput = {};
    memberInput.fields = {
      [paypalAddressAppField]: formValues.paypal_address,
    };

    updateMember({
      variables: {
        id: member.id,
        member: memberInput,
      },
    });
  };

  const cancelEditingPayeeAddress = () => {
    dispatch({
      type: PaymentReducerActionType.CancelEditingPayeeAddress,
    });
  };

  return (
    <div className={styles.PayeeSetup}>
      <div className={styles.title}>Send a payment</div>
      <div className={styles.subtitle}>
        Please enter the member's PayPal email:
      </div>
      <SimpleForm ref={formRef} values={formValues} onChange={handleChangeFields} onSubmit={handleSubmit}>
        <div className={styles.form}>
          <div className={styles.label}>PayPal Address:</div>
          <SimpleField name="paypal_address" type="email">
            <Input />
          </SimpleField>
        </div>
      </SimpleForm>

      <div className={styles.buttons}>
        <SubmitButton isSubmitting={loading} submittingLabel="Saving..." label="Save" onClick={handleClickSave} />
        {canCancelEditing && (
          <Button label="Cancel" onClick={cancelEditingPayeeAddress} theme="light" />
        )}
      </div>
    </div>
  );
};
