import * as React from 'react';
import cx from 'classnames';

import { Popover, Select } from '@components';

import { SingleValue, ValueRange } from './SingleValuePicker';
import options, { optionsToSymbols } from './options';
import { monetaryReducer } from './monetaryReducer';

import styles from '../Filters.scss';

const { useRef, useState } = React;

export const paymentAndProductCostReducer = monetaryReducer(
  'PAYMENT_AND_PRODUCT_COST',
  'paymentAndProductCost',
);

const OptionsToComponent = (option, setFilters, values) => {
  if (
    option === 'greaterThan'
    || option === 'lessThan'
    || option === 'equalTo'
  ) {
    return (
      <SingleValue
        setFilters={setFilters}
        values={values}
        filterName="PAYMENT_AND_PRODUCT_COST"
      />
    );
  }
  if (option === 'isBetween') {
    return (
      <ValueRange
        setFilters={setFilters}
        values={values}
        filterName="PAYMENT_AND_PRODUCT_COST"
      />
    );
  }
};

export const PaymentAndProductCostFilter = ({
  filters,
  setFilters,
  removeFilter,
  isDisabled,
}) => {
  const [open, setOpen] = useState(true);
  const paymentAndProductCostState = filters.paymentAndProductCost;
  const option = paymentAndProductCostState.option;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {optionsToSymbols[option.label]}
        </span>
        <span className={styles.filterText}>Payment + Product Cost</span>
        <span
          className={cx(styles.filterCircle, styles.right)}
          onClick={() => !isDisabled && removeFilter('paymentAndProductCost')}
        >
          ×
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <Select
          hintText="Select Comparison..."
          options={options}
          selectedIndex={option.index}
          onChange={(option, index) =>
            setFilters({
              type: 'CHOOSE_PAYMENT_AND_PRODUCT_COST_FILTER_COMPARISON',
              payload: { label: option, index },
            })}
        />
        {OptionsToComponent(
          option.label,
          setFilters,
          paymentAndProductCostState,
        )}
      </Popover>
    </>
  );
};
