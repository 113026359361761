import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const FavoriteBorderIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12,21.73c-0.11,0-0.23-0.03-0.33-0.09C11.28,21.39,2,15.6,2,8.44c0-3.94,2.45-5.9,4.87-6.1
      C8.74,2.19,10.89,3.1,12,5.37c1.13-2.31,3.34-3.23,5.26-3.08C20.52,2.56,22,5.41,22,7.95c0,6.64-9.24,13.38-9.63,13.66
      C12.26,21.69,12.13,21.73,12,21.73z M7.29,3.57c-0.11,0-0.21,0-0.32,0.01C5.12,3.74,3.25,5.3,3.25,8.44
      c0,5.72,7.05,10.78,8.72,11.9c1.65-1.27,8.78-7.06,8.78-12.39c0-2.84-1.81-4.27-3.6-4.41c-1.84-0.15-4.04,1.04-4.54,4.02
      c-0.05,0.3-0.31,0.52-0.62,0.52s-0.57-0.22-0.62-0.52C10.92,4.77,9.05,3.57,7.29,3.57z"
    />
  </SvgIcon>
));

FavoriteBorderIcon.displayName = 'FavoriteBorderIcon';

export { FavoriteBorderIcon };
