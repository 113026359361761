import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CAMPAIGN_BY_PROJECT_ID_QUERY } from '@frontend/app/queries';
import {
  GetCampaignByProjectIdQuery,
  GetCampaignByProjectIdQueryVariables,
} from '@frontend/app/queries/types/GetCampaignByProjectIdQuery';

type IOptions = QueryHookOptions<GetCampaignByProjectIdQuery, GetCampaignByProjectIdQueryVariables>;

export function useGetCampaignByProjectId(projectId: number, options: IOptions = {}) {
  return useQuery<GetCampaignByProjectIdQuery>(
    GET_CAMPAIGN_BY_PROJECT_ID_QUERY,
    {
      ...options,
      variables: {
        projectId,
      },
      skip: !projectId || options.skip,
    },
  );
}
