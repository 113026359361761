import React, { useState, useEffect } from 'react';
import { TActions, TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import {
 ColumnsType, Input, Table, Typography,
} from '@revfluence/fresh';
import { TSTAPayment } from '@frontend/applications/PaymentsApp/types';
import { formatValue, DataFormat } from '@affiliates/utils';
import { StepDescription } from '@frontend/applications/PaymentsApp/components/PaymentsWizard/common/StepDescription';
import { SearchOutlined } from '@ant-design/icons';
import NumericInput from '@frontend/applications/PaymentsApp/components/PaymentsWizard/common/NumericInput/NumericInput';
import styles from './NewBulkPaymentsTable.scss';

const { Title } = Typography;

interface IProps {
  actions: TActions;
  state: TState;
}

export const NewBulkPaymentsTable: React.FC<IProps> = (props) => {
  const { actions, state } = props;
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    actions.setAmountPaying(
      state.staPayments.filter((payment) => payment.isSelected).reduce((sum, payment) => parseFloat(payment.amountToPay) + sum, 0),
    );
  }, [state.staPayments, actions]);

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    const updatedPayments = state.staPayments.map((payment) => ({
      ...payment,
      isSelected: selectedRowKeys.includes(payment.key),
    }));
    actions.setSTAPayments(updatedPayments);
  };

  const rowSelection = {
    onChange: onSelectChange,
    getCheckboxProps: (record: TSTAPayment) => ({
      disabled: record.isMemberDeleted || !record.paypalEmail,
    }),
  };

  const onPaymentChange = (newValue:string, record: TSTAPayment) => {
    const updatedPayments = state.staPayments.map((payment) => {
      if (payment.key === record.key) {
        return {
          ...payment,
          amountToPay: newValue,
        };
      } else {
        return payment;
      }
    });
    actions.setSTAPayments(updatedPayments);
  };
  const columns: ColumnsType<TSTAPayment> = [
    {
      title: 'Member',
      dataIndex: 'member',
      width: 100,
      className: styles.ellipsis,
    },
    {
      title: 'Offer',
      dataIndex: 'offerName',
      width: 100,
    },
    {
      title: 'Last Payout',
      dataIndex: 'lastPayout',
      width: 100,
      render: (lastPayout: string) => `$${formatValue(DataFormat.Money, lastPayout)}`,
    },
    {
      title: 'Amount Due',
      dataIndex: 'amountDue',
      width: 100,
      render: (amountDue: string) => `$${formatValue(DataFormat.Money, amountDue)}`,
    },
    {
      title: 'Amount to Pay',
      dataIndex: 'amountToPay',
      width: 100,
      render: (amountToPay: string, record: TSTAPayment) => (
        <NumericInput
          prefix="$"
          value={amountToPay}
          disabled={!record.paypalEmail || !state.featureFlags.migrateToGraphQL}
          onChange={(newValue) => onPaymentChange(newValue, record)}
          max={9999.99}
        />
      ),
    },
    {
      title: 'PayPal Email',
      dataIndex: 'paypalEmail',
      width: 100,
      render: (paypalEmail: string, record: TSTAPayment) => {
        if (record.isMemberDeleted) {
          return <span style={{ color: 'red' }}>Member is deleted</span>;
        }
        return paypalEmail || <span style={{ color: 'red' }}>PayPal email required</span>;
      },
    },
  ];

  const filteredPayments = state.staPayments.filter((payment) =>
    payment.member.toLowerCase().includes(searchQuery.toLowerCase()));

  const rowClassName = (record: TSTAPayment) =>
    (record.isMemberDeleted || !record.paypalEmail ? styles.disabledRow : '');

  return (
    <div className={styles.newBulkPaymentsTableContainer}>
      <StepDescription
        title="Confirm Payment"
        description="Confirm all of the member information below before continuing to payment details. If necessary, additional funds can be added to your account in Payment Settings."
      />
      <div className={styles.headerContainer}>
        <div>
          <Title level={5}>
            {`${state.staPayments.length} Members (${state.staPayments.filter((payment) => payment.isSelected).length} Selected)`}
          </Title>
        </div>
        <div>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search Member"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: 200 }}
          />
        </div>
      </div>
      <Table
        size="middle"
        dataSource={filteredPayments}
        columns={columns}
        pagination={false}
        rowSelection={rowSelection}
        rowClassName={rowClassName}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};
