import {
 AutomationNodeData, FormattedOption, TConditionNode, TConditionNodeInput,
} from '@frontend/app/containers/Projects/AutomationConfig/types';
import { v4 as uuidv4 } from 'uuid';
import {
  ComboOperator,
  ComparisonOperator,
  LogicalOperator,
  isComparisonOperator,
  isLogicalOperator,
} from '@frontend/app/types/automations/condition/Operator';
import { ConditionType, ICondition } from '@frontend/app/types/automations/condition/Condition';
import { Node } from 'reactflow';
import { size, chain } from 'lodash';
import { AutomationNodeType, AutomationVariableType } from '@frontend/app/types/globalTypes';
import { IAutomationVariable } from '@frontend/app/types/automations/condition/AutomationVariable';
import { NODE_HEIGHT_OFFSET } from '@frontend/app/containers/Projects/AutomationConfig/constants';

export const parseConditionNode = (node: TConditionNode, parentNode: TConditionNode): TConditionNode => {
  switch (node.type) {
    case ConditionType.COMPARISON:
      return {
        ...node,
        uuid: uuidv4(),
        parentId: parentNode.uuid,
        mappedOperator: node.op,
        children: node.children ? node.children.map((condition) => parseConditionNode(condition, node)) : undefined,
      };
    case ConditionType.LOGICAL:
      switch (node.op) {
        case LogicalOperator.AND:
          if (node.children && node.children.length === 2) {
            const child1 = node.children[0];
            const child2 = node.children[1];
            if (
              child1.type === ConditionType.COMPARISON
              && child1.op === ComparisonOperator.GREATER_THAN_EQUALS
              && child2.type === ConditionType.COMPARISON
              && child2.op === ComparisonOperator.LESS_THAN_EQUALS
              && child1.attribute === child2.attribute
            ) {
              // TODO (an) figure out how to pass the value in a cleaner way without failing linter
              if (typeof child1.value === 'number' && typeof child2.value === 'number') {
                const value: [number, number] = [child1.value, child2.value];
                return {
                  ...child1,
                  uuid: uuidv4(),
                  parentId: parentNode.uuid,
                  mappedOperator: ComboOperator.BETWEEN,
                  value,
                };
              }
              if (child1.value instanceof Date && child2.value instanceof Date) {
                const value: [Date, Date] = [child1.value, child2.value];
                return {
                  ...child1,
                  uuid: uuidv4(),
                  parentId: parentNode.uuid,
                  mappedOperator: ComboOperator.BETWEEN,
                  value,
                };
              }
              if (typeof child1.value === 'string' && typeof child2.value === 'string') {
                const value: [string, string] = [child1.value, child2.value];
                return {
                  ...child1,
                  uuid: uuidv4(),
                  parentId: parentNode.uuid,
                  mappedOperator: ComboOperator.BETWEEN,
                  value,
                };
              }
              throw new Error('Between condition should have single value');
            }
          }
          break;

        case LogicalOperator.NOT:
          if (!node.children || node.children.length !== 1) {
            throw new Error('Not condition should have a single child');
          }
          const child = node.children[0];
          if (child.type !== ConditionType.COMPARISON) {
            throw new Error('Not condition should not have a comparison as a child');
          }
          let mappedOperator;
          switch (child.op) {
            case ComparisonOperator.CONTAINS:
              mappedOperator = ComboOperator.NOT_CONTAINS;
              break;
            case ComparisonOperator.HAS_VALUE:
              mappedOperator = ComboOperator.IS_NULL;
              break;
            case ComparisonOperator.EQUALS:
              mappedOperator = ComboOperator.NOT_EQUALS;
              break;
            case ComparisonOperator.IN_THE_LAST:
              mappedOperator = ComboOperator.NOT_IN_THE_LAST;
              break;
            case ComparisonOperator.IN_THE_NEXT:
              mappedOperator = ComboOperator.NOT_IN_THE_NEXT;
              break;
            case ComparisonOperator.MORE_THAN_AGO:
              mappedOperator = ComboOperator.NOT_MORE_THAN_AGO;
              break;
            case ComparisonOperator.STARTS_WITH:
              mappedOperator = ComboOperator.NOT_STARTS_WITH;
              break;
            case ComparisonOperator.ENDS_WITH:
              mappedOperator = ComboOperator.NOT_ENDS_WITH;
              break;
            case ComparisonOperator.GREATER_THAN:
              mappedOperator = ComparisonOperator.LESS_THAN_EQUALS;
              break;
            case ComparisonOperator.GREATER_THAN_EQUALS:
              mappedOperator = ComparisonOperator.LESS_THAN;
              break;
            case ComparisonOperator.LESS_THAN:
              mappedOperator = ComparisonOperator.GREATER_THAN_EQUALS;
              break;
            case ComparisonOperator.LESS_THAN_EQUALS:
              mappedOperator = ComparisonOperator.GREATER_THAN;
              break;
            default:
              throw new Error('Not condition should not have a comparison as a child1');
          }
          return {
            ...node.children[0], // flat not condition structure
            uuid: uuidv4(),
            parentId: parentNode.uuid,
            mappedOperator,
          };
        default:
          return {
            ...node,
            mappedOperator: node.op,
          };
      }
      throw new Error('Unknown condition error. should not reach here');

    default:
      throw new Error('Unknown condition type');
  }
};

export const parseConditionPayload = (node: TConditionNode): TConditionNodeInput => ({
  condition: parseConditionPayloadRecursive(node),
});

const parseConditionPayloadRecursive = (node: TConditionNode): ICondition => {
  const notWrapper = (node: TConditionNode, op: ComparisonOperator): ICondition => ({
    type: ConditionType.LOGICAL,
    op: LogicalOperator.NOT,
    locked: node.locked,
    children: [
      {
        type: ConditionType.COMPARISON,
        op,
        value: node.value,
        variableId: node.variableId,
        attribute: node.attribute,
        locked: node.locked,
      },
    ],
  });

  switch (node.type) {
    case ConditionType.COMPARISON:
      switch (node.mappedOperator) {
        case ComboOperator.NOT_CONTAINS:
          return notWrapper(node, ComparisonOperator.CONTAINS);
        case ComboOperator.IS_NULL:
          return notWrapper(node, ComparisonOperator.HAS_VALUE);
        case ComboOperator.NOT_EQUALS:
          return notWrapper(node, ComparisonOperator.EQUALS);
        case ComboOperator.NOT_IN_THE_LAST:
          return notWrapper(node, ComparisonOperator.IN_THE_LAST);
        case ComboOperator.NOT_IN_THE_NEXT:
          return notWrapper(node, ComparisonOperator.IN_THE_NEXT);
        case ComboOperator.NOT_MORE_THAN_AGO:
          return notWrapper(node, ComparisonOperator.MORE_THAN_AGO);
        case ComboOperator.NOT_STARTS_WITH:
          return notWrapper(node, ComparisonOperator.STARTS_WITH);
        case ComboOperator.NOT_ENDS_WITH:
          return notWrapper(node, ComparisonOperator.ENDS_WITH);
        case ComboOperator.BETWEEN:
          if (!(node.value instanceof Array) || node.value.length !== 2) {
            throw new Error('Between condition should have two values');
          }
          return {
            type: ConditionType.LOGICAL,
            op: LogicalOperator.AND,
            locked: node.locked,
            children: [
              {
                type: ConditionType.COMPARISON,
                op: ComparisonOperator.GREATER_THAN_EQUALS,
                value: node.value[0],
                variableId: node.variableId,
                attribute: node.attribute,
                locked: node.locked,
              },
              {
                type: ConditionType.COMPARISON,
                op: ComparisonOperator.LESS_THAN_EQUALS,
                value: node.value[1],
                variableId: node.variableId,
                attribute: node.attribute,
                locked: node.locked,
              },
            ],
          };
        default:
          if (!isComparisonOperator(node.op)) {
            throw new Error('Unknown logical operator');
          }
          return {
            type: ConditionType.COMPARISON,
            op: node.op,
            value: node.value,
            variableId: node.variableId,
            attribute: node.attribute,
            locked: node.locked,
          };
      }
    case ConditionType.LOGICAL:
      if (!isLogicalOperator(node.op)) {
        throw new Error('Unknown logical operator');
      }
      return {
        type: node.type,
        op: node.op as LogicalOperator,
        children: node.children ? node.children.map((conditionNode) => parseConditionPayloadRecursive(conditionNode)) : [],
        locked: node.locked,
      };

    default:
      throw new Error('Unknown condition type');
  }
};

export const findNodeById = (tree: TConditionNode, uuid: string): TConditionNode | null => {
  if (tree.uuid === uuid) {
    return tree;
  }
  if (tree.children) {
    for (const childNode of tree.children) {
      const foundNode = findNodeById(childNode, uuid);
      if (foundNode) return foundNode;
    }
  }
  return null;
};

export const addConditionNode = (tree: TConditionNode, nodeId: string, newNode: TConditionNode): TConditionNode => {
  if (tree.uuid === nodeId) {
    if (tree.children && tree.children.length == 0) {
      return {
        ...tree,
        children: [newNode],
      };
    }
    if (tree.children && tree.children.length > 0) {
      return {
        ...tree,
        children: [...tree.children, newNode],
      };
    }
  }
  if (tree.children) {
    return {
      ...tree,
      children: tree.children.map((childNode) => addConditionNode(childNode, nodeId, newNode)),
    };
  }

  return tree;
};

export const removeConditionNode = (tree: TConditionNode, nodeId: string, targetNodeId: string): TConditionNode => {
  if (tree.uuid === nodeId) {
    return {
      ...tree,
      children: tree.children?.filter((conditionNode) => conditionNode.uuid !== targetNodeId),
    };
  }
  if (tree.children) {
    return {
      ...tree,
      children: tree.children.map((childNode) => removeConditionNode(childNode, nodeId, targetNodeId)),
    };
  }
  return tree;
};

export const updateConditionNode = (tree: TConditionNode, nodeId: string, updatedProperties: Partial<TConditionNode>): TConditionNode => {
  // If the current node is the one to update, return a new node with the updated properties
  if (tree.uuid === nodeId) {
    return { ...tree, ...updatedProperties };
  }

  // If the current node has conditions, recursively update its children
  if (tree.children) {
    return {
      ...tree,
      children: tree.children.map((childNode) => updateConditionNode(childNode, nodeId, updatedProperties)),
    };
  }

  // If the current node is not the one to update and has no children, return it as is
  return tree;
};

export const getLeafNodeYPostionOffset = (nodes: Node<AutomationNodeData>[]) => {
  let conditionNodeHeightOffset = 0;

  nodes.forEach((node) => {
    if (node.type === AutomationNodeType.CONDITION) {
      conditionNodeHeightOffset = size(node.data.metadata.children) * NODE_HEIGHT_OFFSET;
    }
  });

  return conditionNodeHeightOffset;
};

export const getAllowedAutomationVariables = (automationVariables: IAutomationVariable[]) : IAutomationVariable[] => {
  const allowedTypes = [AutomationVariableType.MEMBER];
  return automationVariables.filter((variable) => allowedTypes.includes(variable.type));
};

export const getGroupsForVariable = (
  variableType: AutomationVariableType,
  formattedOptions: FormattedOption[],
) => chain(formattedOptions)
  .filter((option) => option.variableType === variableType)
  .uniqBy('group')
  .map((option) => option.group)
  .sort()
  .value();

export const getFieldsForGroup = (
  group: string,
  formattedOptions: FormattedOption[],
  type: AutomationVariableType,
) => chain(formattedOptions)
  .filter((option) => option.group === group && option.variableType === type)
  .sortBy('displayName')
  .value();
