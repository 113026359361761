import * as React from 'react';

import { useFeatureFlags } from '@frontend/applications/InfluencerWhitelist/components/featureFlags';

import styles from './EmptyAccessTable.scss';

export const EmptyAccessTable = () => {
  const {
    allowlistingFF,
    InstagramPartnershipAdsEnabled,
    isEmptyAllowlisting,
    isEmptyBrandedContentAds,
    metaBrandedContentEnabled,
    isEmptyInstagramPartnershipAds,
    isEmptyMultipleAgreements,
  } = useFeatureFlags();
  const emptyStateTitle = () => {
    if (isEmptyMultipleAgreements) {
      return 'You have no Branded Content Ads, Allowlisting, or Paid Partnership Ads agreements at this time';
    } else if (isEmptyBrandedContentAds) {
      return 'You have no Branded Content Ads agreements at this time';
    } else if (isEmptyAllowlisting) {
      return 'You have no Allowlisting agreements at this time';
    } else if (isEmptyInstagramPartnershipAds) {
      return 'You have no Paid Partnership Ads agreements at this time';
    } else if (metaBrandedContentEnabled && allowlistingFF) {
      return 'You have no Branded Content Ads or Allowlisting agreements at this time';
    } else if (metaBrandedContentEnabled && InstagramPartnershipAdsEnabled) {
      return 'You have no Branded Content Ads or Paid Partnership Ads agreements at this time';
    } else if (allowlistingFF && InstagramPartnershipAdsEnabled) {
      return 'You have no Allowlisting or Paid Partnership Ads agreements at this time';
    }
  };

  const emptyStateSubtitle = () => {
    if (isEmptyMultipleAgreements) {
      return "You can add Branded Content Ads, Allowlisting or Paid Partnership Ads to a project to run ads via an influencer's account.";
    } else if (isEmptyBrandedContentAds) {
      return "You can add Branded Content Ads to a project to run ads via an influencer's account.";
    } else if (isEmptyAllowlisting) {
      return "You can add Allowlisting to a project to run ads via an influencer's account.";
    } else if (isEmptyInstagramPartnershipAds) {
      return "You can add Paid Partnership Ads to a project to run ads via an influencer's account.";
    } else if (metaBrandedContentEnabled && allowlistingFF) {
      return "You can add Branded Content Ads or Allowlisting to a project to run ads via an influencer's account.";
    } else if (metaBrandedContentEnabled && InstagramPartnershipAdsEnabled) {
      return "You can add Branded Content Ads or Paid Partnership Ads to a project to run ads via an influencer's account.";
    } else if (allowlistingFF && InstagramPartnershipAdsEnabled) {
      return "You can add Allowlisting or Paid Partnership Ads to a project to run ads via an influencer's account.";
    }
  };

  return (
    <>
      <div className={styles.EmptyAccessTable}>
        <b>{emptyStateTitle()}</b>
        <p>{emptyStateSubtitle()}</p>
      </div>
    </>
  );
};
