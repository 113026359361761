import * as React from 'react';
import {
 Button, Col, Divider, Row,
} from '@revfluence/fresh';
import { LoadSpinner } from '@frontend/app/components';

interface IActionConfig {
  label: string;
  onClick: () => void;
  icon: JSX.Element;
  disabled: boolean;
}

interface IActionProps {
  actionOptions: IActionConfig[];
  takingActions?: boolean;
}

export const ActionButtons = ({ takingActions, actionOptions }: IActionProps) => (
  <>
    <Divider
      style={{
        width: 'calc(100% + 48px)',
        marginRight: '-24px',
        marginLeft: '-24px',
      }}
    />
    <Row gutter={8} justify="space-around">
      {takingActions && <LoadSpinner />}
      {!takingActions
        && actionOptions.map((config, index) => (
          <Col key={index} flex={1}>
            <Button onClick={config.onClick} icon={config.icon} block disabled={config.disabled}>
              {config.label}
            </Button>
          </Col>
        ))}
    </Row>
  </>
);
