import * as React from 'react';
import { CoinsIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Alert,
  Card, Divider, Select, Typography, InputNumber,
} from '@revfluence/fresh';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { DataFormat, formatValue } from '@frontend/applications/AffiliatesApp/utils';
import { IPaymentSources } from '@frontend/applications/PaymentsApp/models';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { TActions, TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { getCardIcon } from '@frontend/applications/PaymentsApp/utils/getCardIcon';
import { PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import styles from './AddPayment.scss';
import { SectionHeader } from '../SectionHeader';
import { SubItemSmall } from '../SubItemSmall';
import { SubItemLarge } from '../SubItemLarge';

const { useEffect } = React;
const { Text, Title } = Typography;
const { Option } = Select;

interface IProps {
  amountPaying: number;
  setAmountPaying: (amountPaying: number) => void;
  state: TState;
  actions: TActions;
  paymentSource?: IPaymentSources,
  brief?: IAgreement,
}

export const AddPayment: React.FC<IProps> = (props) => {
  const {
    brief,
    amountPaying,
    setAmountPaying,
    paymentSource,
    state,
    actions,
  } = props;

  const { workflowActionParameters } = useApplication();
  const currentProgram = state.programs.find((program) => program.id === workflowActionParameters?.programId);
  const availableBalance = paymentSource?.balance_in_cents ? paymentSource.balance_in_cents / 100 : 0;
  const paymentRequiredThroughCard = availableBalance - state.amountPaying >= 0 ? 0 : Math.abs(availableBalance - state.amountPaying);
  useEffect(() => {
    if (paymentRequiredThroughCard && state.paymentSource?.cards.length) {
      actions.setFieldValue('selectedCardId', state.paymentSource.cards[0].id);
    } else {
      actions.setFieldValue('selectedCardId', null);
    }
  }, [paymentRequiredThroughCard, actions, state.paymentSource]);
  return (
    <div className={styles.addPaymentContainer}>
      <SectionHeader
        title="Payment Details"
        sectionIcon={<CoinsIcon />}
      />
      <Card className={styles.paymentCard}>
        <Text weight="semibold">{currentProgram?.title || ''}</Text>
        {
          brief && (
            <>
              <div className={styles.briefContainer}>
                <div className={styles.briefAmountContainer}>
                  <Title level={2}>{`$${brief.price}`}</Title>
                  <Title level={4}>USD</Title>
                </div>
                <Text className={styles.briefText} weight="semibold">Briefs Due</Text>
              </div>
            </>
          )
        }
        {
          [PaymentCreationSource.PROJECT].includes(state.paymentCreationSource) && <Divider dashed className={styles.divider} />
        }
        <div className={styles.paymentAmountContainer}>
          <div className={styles.paymentInput}>
            <label htmlFor="payment-amount">Enter Amount to Pay</label>
            <InputNumber
              style={{ width: '100%' }}
              id="payment-amount"
              addonBefore="$"
              value={amountPaying}
              min={0}
              precision={2}
              disabled={state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING}
              onChange={(value) => setAmountPaying(value as number || 0)}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => parseFloat(value.replace(/\$\s?|(,*)/g, ''))}
            />
            <div className={styles.balanceAmount}>
              <Text className={styles.colorGray} type="secondary">Available Balance:</Text>
              <Text className={styles.colorBlack} type="secondary">{`$${formatValue(DataFormat.Money, availableBalance)} USD`}</Text>
            </div>
          </div>
          <div>
            <SubItemLarge
              name="Amount to Pay"
              value={`$${formatValue(DataFormat.Money, amountPaying)} USD`}
            />
            {
              brief && (
                <SubItemSmall
                  name="Remaining Due Amount"
                  value={`$${brief.price - amountPaying}`}
                />
              )
            }
          </div>
        </div>
      </Card>

      {
        paymentRequiredThroughCard > 0 && (
          <Card className={styles.cardPayment}>
            <div className={styles.cardPaymentContainer}>
              <Alert
                description="Your available balance can’t cover the full amount. Charge the remaining payment to a credit card."
                type="warning"
              />
              <SubItemSmall
                name="Charge Remaining Payment to"
                value={(
                  <>
                    <Select size="small" value={state.selectedCardId} onChange={(e) => actions.setFieldValue('selectedCardId', e)} style={{ width: 200 }}>
                      {
                        state.paymentSource?.cards?.map((card) => (
                          <Option key={card.id} value={card.id}>
                            <img src={getCardIcon(card.brand)} className={styles.cardIcon} />
                            {' '}
                            {`${card.brand} (${card.last4})`}
                          </Option>
                        ))
                      }
                    </Select>
                  </>
                )}
              />
              <SubItemLarge
                name="Charging to card"
                value={`$${formatValue(DataFormat.Money, paymentRequiredThroughCard)} USD`}
              />
            </div>

          </Card>
        )
      }
    </div>
  );
};
