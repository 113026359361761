import React, { useMemo } from 'react';
import {
  Col, Row, Typography, Button, Space, Tabs,
  Tooltip,
  IMenuProps,
  Dropdown,
} from '@revfluence/fresh';
import { AngleDownIcon, ClipboardListCheckIcon, GearIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory } from 'react-router-dom';
import { BagShoppingIcon } from '@revfluence/fresh-icons/regular/esm';
import { Collections } from '../Collections/Collections';
import styles from './Products.scss';
import { useProductsContext } from '../ProductsContext';
import { ShopifyImportModal } from '../ShopifyImportModal/ShopifyImportModal';
// import { AllProductsWrapper } from '../AllProducts/AllProducts';
import { ImportSettingsDrawer } from '../ImportSettings/ImportSettingsDrawer';
import { pfaV2Routes } from '../../routes';
import { ShopifyImportModalProvider } from '../ShopifyImportModal/ShopifyImportModalContext';
import { FindAndImportProvider } from '../FindAndImport/FindAndImportContext';
import { FindAndImportModal } from '../FindAndImport/FindAndImport';

const { Title, Text } = Typography;

export const Products = () => {
  const {
 setIsOpen, setIsImportSettingsDrawerOpen, setIsFindAndImportOpen, isShopifyConnected,
} = useProductsContext();
  const history = useHistory();

  const items = useMemo<IMenuProps['items']>(() => [
    {
      key: 'import',
      label: 'Import Collections',
      onClick: () => setIsOpen(true),
    },
    {
      key: 'findAndImport',
      label: 'Find and Import',
      onClick: () => setIsFindAndImportOpen(true),
    },
  ], [setIsFindAndImportOpen, setIsOpen]);

  return (
    <div className={styles.ProductFulfillmentProducts}>
      <Row className={styles.header}>
        <Col>
          <Title level={3}>Products</Title>
          <Text className={styles.headerSubTitle}>
            View, add, update, and organize your collection, products and variants imported into Aspire.
          </Text>
        </Col>
        <Space className={styles.headerCTA}>
          <Tooltip title="Import Logs">
            <Button icon={<ClipboardListCheckIcon />} onClick={() => history.push(pfaV2Routes.settings.importLogs)} />
          </Tooltip>
          <Tooltip title="Settings">
            <Button icon={<GearIcon />} onClick={() => setIsImportSettingsDrawerOpen(true)} />
          </Tooltip>
          <Tooltip title={isShopifyConnected ? '' : 'Please connect a Shopify store to start importing products'}>
            <Dropdown menu={{ items }} disabled={!isShopifyConnected}>
              <Button type="primary" icon={<BagShoppingIcon />}>
                Import Products
                <AngleDownIcon />
              </Button>
            </Dropdown>
          </Tooltip>
        </Space>
      </Row>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Collections',
            key: '1',
            children: <Collections />,
          },
          // {
          //   label: 'All Products',
          //   key: '2',
          //   children: <AllProductsWrapper />,
          // },
        ]}
      />
      <ShopifyImportModalProvider>
        <ShopifyImportModal />
      </ShopifyImportModalProvider>
      <FindAndImportProvider>
        <FindAndImportModal />
      </FindAndImportProvider>
      <ImportSettingsDrawer />
    </div>
  );
};
