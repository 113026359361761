/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_guqdu_79 {
  display: flex;
}

._justify-content-space-between_guqdu_83 {
  justify-content: space-between;
}

._tabular-nums_guqdu_87, ._NumericCell_guqdu_87 ._content_guqdu_87 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_guqdu_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}