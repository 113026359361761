import * as React from 'react';
import { isEmpty, map, each } from 'lodash';
import { ITagColor } from '@frontend/app/hooks';
import { renderEmptyCell } from '../EmptyCell';
import { TokenCell } from './TokenCell';

export const renderTokenCell = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objects: any[],
  key: string,
  backgroundColor: string = null,
  colorMap: Map<string, ITagColor> = new Map(),
) => {
  if (isEmpty(objects)) {
    return renderEmptyCell();
  }
  const values = map(objects, key);

  if (backgroundColor) {
    each(values, (value) => colorMap.set(value, { ...colorMap.get(value), ...{ backgroundColor } }));
  }

  return (
    <TokenCell values={values} colorMap={colorMap} />
  );
};
