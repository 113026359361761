/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1toqy_157 {
  display: flex;
}

._justify-content-space-between_1toqy_161 {
  justify-content: space-between;
}

._tabular-nums_1toqy_165 {
  font-variant-numeric: tabular-nums;
}

._Button_1toqy_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1toqy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Button_1toqy_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Button_1toqy_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Button_1toqy_169 ::-webkit-scrollbar-track, ._Button_1toqy_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Button_1toqy_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Button_1toqy_169 {
  padding: 0.5rem 1rem;
  min-width: 5rem;
  height: 2.5rem;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._Button_1toqy_169:hover._primary_1toqy_221 {
  background-color: #3d73d7;
}
._Button_1toqy_169:hover._light_1toqy_224 {
  color: #4fa7ee;
  background-color: #fafafa;
}
._Button_1toqy_169:hover._lightblue_1toqy_228 {
  color: #2a8fdc;
  background-color: #fafafa;
}
._Button_1toqy_169:hover._info_1toqy_232 {
  color: #167cf4;
  background-color: #dce9f2;
}
._Button_1toqy_169:hover._danger_1toqy_236 {
  filter: brightness(0.92);
}
._Button_1toqy_169:active._primary_1toqy_221 {
  background-color: #167cf4;
}
._Button_1toqy_169:active._light_1toqy_224 {
  color: #1a1818;
  background-color: #fdfdfd;
}
._Button_1toqy_169:active._lightblue_1toqy_228 {
  color: #167cf4;
  background-color: #fdfdfd;
}
._Button_1toqy_169:active._info_1toqy_232 {
  color: #3996e0;
  background-color: #cadeeb;
}
._Button_1toqy_169:active._danger_1toqy_236 {
  filter: brightness(1);
}
._Button_1toqy_169._primary_1toqy_221 {
  color: #fdfdfd;
  background-color: #167cf4;
  border: none;
}
._Button_1toqy_169._light_1toqy_224 {
  color: #1a1818;
  background-color: #fdfdfd;
  border: solid 0.0625rem #ececec;
}
._Button_1toqy_169._lightblue_1toqy_228 {
  color: #fdfdfd;
  background-color: #3d73d7;
  border: solid 0.0625rem #dadcde;
}
._Button_1toqy_169._info_1toqy_232 {
  color: #3996e0;
  background-color: #eff5f9;
  border: none;
}
._Button_1toqy_169._danger_1toqy_236 {
  color: #fdfdfd;
  background-color: #d9534f;
  border: none;
}
._Button_1toqy_169._noBorder_1toqy_282 {
  border: none !important;
}
._Button_1toqy_169._fullWidth_1toqy_285 {
  width: 100%;
}
._Button_1toqy_169._round_1toqy_288 {
  border-radius: 0.5rem;
}
._Button_1toqy_169._disabled_1toqy_291 {
  cursor: not-allowed;
}
._Button_1toqy_169 ._mask_1toqy_294 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
._Button_1toqy_169 ._icon_1toqy_302 {
  display: flex;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Button_1toqy_169 ._icon_1toqy_302._left_1toqy_306 {
  margin-right: 0.25rem;
}
._Button_1toqy_169 ._icon_1toqy_302._right_1toqy_309 {
  margin-left: 0.25rem;
}
._Button_1toqy_169 ._label_1toqy_312 {
  user-select: none;
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}