/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Pagination_s899t_32 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Pagination_s899t_32 ._label_s899t_37 {
  margin-right: 6px;
  color: #8f8d91;
  font-size: 12px;
  letter-spacing: 0.4px;
}
._Pagination_s899t_32 ._button_s899t_43 {
  margin-right: 4px;
}
._Pagination_s899t_32 ._button_s899t_43 ._nextPageIcon_s899t_46 {
  transform: rotate(180deg);
}