import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const ComposeIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M18.55 13.36C18.1 13.36 17.73 13.73 17.73 14.18V18.46C17.73 19.48 16.9 20.32 15.87 20.32H5.5C4.47 20.32 3.64 19.49 3.64 18.46V6.43C3.64 5.41 4.47 4.57 5.5 4.57H11.44C11.89 4.57 12.26 4.2 12.26 3.75C12.26 3.3 11.89 2.93 11.44 2.93H5.5C3.57 2.93 2 4.5 2 6.43V18.47C2 20.4 3.57 21.97 5.5 21.97H15.88C17.81 21.97 19.38 20.4 19.38 18.47V14.19C19.37 13.73 19.01 13.36 18.55 13.36Z" />
    <path d="M21.27 2.72C20.31 1.76 18.74 1.76 17.77 2.72L10.1 10.39C10.03 10.47 9.96998 10.55 9.92998 10.65L8.16998 14.75C8.03998 15.06 8.10998 15.42 8.33998 15.65C8.49998 15.81 8.70998 15.89 8.91998 15.89C9.02998 15.89 9.13998 15.87 9.23998 15.82L13.34 14.06C13.44 14.02 13.52 13.96 13.6 13.89L21.27 6.22C21.74 5.76 22 5.13 22 4.47C22 3.81 21.74 3.19 21.27 2.72ZM20.12 5.07L12.56 12.63L10.49 13.52L11.38 11.45L18.94 3.89C19.27 3.56 19.8 3.56 20.12 3.89C20.28 4.05 20.37 4.26 20.37 4.48C20.36 4.7 20.28 4.91 20.12 5.07Z" />
  </SvgIcon>
));

ComposeIcon.displayName = 'ComposeIcon';

export { ComposeIcon };
