import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_CUSTOM_COLLECTION_IMPORT_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { CustomCollectionImport, CustomCollectionImportVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/CustomCollectionImport';

type IOptions = MutationHookOptions<CustomCollectionImport, CustomCollectionImportVariables>;

export const useCreateCustomCollectionImport = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<CustomCollectionImport, CustomCollectionImportVariables>(
    CREATE_CUSTOM_COLLECTION_IMPORT_MUTATION,
    options,
  );

  return {
    createCustomCollectionImport: mutate,
    isCreatingCustomCollectionImport: loading,
  };
};
