// format.errors is not exposed to the browser so we reimplement it here
// https://github.com/winstonjs/logform/issues/97
// source: https://github.com/winstonjs/logform/blob/master/errors.js

const { LEVEL, MESSAGE, SPLAT } = require('triple-beam');
const { format } = require('winston');

/*
 * function errors (info)
 * If the `message` property of the `info` object is an instance of `Error`,
 * replace the `Error` object its own `message` property.
 * Also allows the developer to pass in an `Error` instance for the 'error' property,
 * which will convert 'error' to an object with `message`, `stack`, and `kind` properties.
 * In this usage, the `message` property of the `info` object will not be modified.
 *
 * Optionally, the Error's `stack` property can also be appended to the `info` object.
 */
export const formatErrors = format((einfo, { stack }) => {
  if (einfo instanceof Error) {
    // Dev called logger like logger.error(new Error('foo'))
    const info = Object.assign({}, einfo, {
      level: einfo.level,
      [LEVEL]: einfo[LEVEL] || einfo.level,
      message: einfo.message,
      [MESSAGE]: einfo[MESSAGE] || einfo.message
    });
    info.error = {
      kind: einfo.constructor.name,
      message: einfo.message,
    }
    if (stack) info.error.stack = einfo.stack;
    if (einfo.exc_context) info.error.exc_context = einfo.exc_context;
    if (info.exc_context) delete info.exc_context;
    return info;
  }

  if (einfo.error instanceof Error) {
    // Dev called logger like logger.error('Testing', { error: new Error('foo') })
    const origError = einfo.error;
    einfo.error = Object.assign({}, origError, {
      kind: origError.constructor.name,
      message: origError.message,
    });
    if (stack) einfo.error.stack = origError.stack;
    return einfo;
  }

  if (einfo.message instanceof Error) {
    // Dev called logger like logger.error({ message: new Error('foo')})
    const err = einfo.message;
    einfo.message = err.message;
    einfo[MESSAGE] = err.message;
    einfo.error = Object.assign({}, err, {
      kind: err.constructor.name,
      message: err.message,
    });
    if (stack) einfo.error.stack = err.stack;
    return einfo;
  }

  if (einfo[SPLAT] && einfo[SPLAT].length && einfo[SPLAT][0] instanceof Error) {
    // Dev called logger like logger.error('Testing', new Error('foo'))
    // We want to keep the original message merge functionality,
    // but we also want to move `stack` to `error.stack` if it exists.
    const err = einfo[SPLAT][0];
    einfo.error = Object.assign({}, err, {
      kind: err.constructor.name,
      message: err.message,
    });
    if (stack) einfo.error.stack = err.stack;
    delete einfo.stack;
    return einfo;
  }

  return einfo;
});
