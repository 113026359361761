import * as React from 'react';
import { Button } from '@frontend/applications/AffiliatesApp/AspireUI';
import { OFFER_TYPE } from '@affiliates/types/globalTypes';
import { GetOffersBySearchQuery_offers, GetOffersBySearchQuery_offers_links, GetOffersBySearchQuery_offers_promos } from '@affiliates/queries/types/GetOffersBySearchQuery';

import styles from './WorkflowOfferError.scss';
import { useText } from './hooks/useText';
import { OfferImage } from './OfferImage';
import { useButton } from './hooks/useButton';

type Promo = Pick<GetOffersBySearchQuery_offers_promos, 'status'>;
type Link = Pick<GetOffersBySearchQuery_offers_links, 'status'>;
export type IWorkflowOfferErrorOffer = {
  promos?: Promo[];
  links?: Link[];
  isNewFlow?: boolean;
} & Pick<GetOffersBySearchQuery_offers, 'id' | 'expired'>;

export interface IWorkflowOfferErrorProps {
  offerSource: OFFER_TYPE;
  offer: IWorkflowOfferErrorOffer;
  programId: number;
  taskId: string;
}

export const WorkflowOfferError: React.FC<IWorkflowOfferErrorProps> = (props) => {
  const {
    offerSource, offer, taskId, programId,
  } = props;
  const { title, paragraph } = useText(offerSource, offer);
  const {
    onEditOfferClick,
  } = useButton(offerSource, offer, programId, taskId);
  return (
    <div className={styles.WorkflowOfferError}>
      <div className={styles.textContainer}>
        <h1>{title}</h1>
        <p>{paragraph}</p>
        <div className={styles.buttonContainer}>
          <Button onClick={onEditOfferClick} type="primary" className={styles.Button}>
            Edit this offer
          </Button>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <OfferImage offerSource={offerSource} />
      </div>
    </div>
  );
};
