import { isEmpty } from "lodash";

export function toBoolean(value: any): boolean {
  const truthyValues = ['true', 'yes', '1', 'on'];
  return truthyValues.includes(String(value).toLowerCase());
}

export function sanitizeXML(value?: string): string {
  if (value === null || value === undefined) {
    return '';
  }
  return value.replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&apos;');
};

export function listToEnglish(list: string[]) {
  if (list.length == 0) {
    return '';
  } else if (list.length === 1) {
    return list[0];
  } else if (list.length === 2) {
    return `${list[0]} and ${list[1]}`;
  } else {
    return `${list.slice(0, list.length - 1).join(', ')}, and ${list[list.length - 1]}`;
  }
}

export function isEmailAddressValid(email: string): boolean {
  const EMAIL_REGEX = /(^[a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+(\.[a-zA-Z0-9_-]+))/gi;
  return (!!email && !isEmpty(email.match(EMAIL_REGEX)));
}

export function extractEmails(email: string = ''): string[] {
  const EMAIL_REGEX = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+(\.[a-zA-Z0-9_-]+)?)/gi;
  // TODO: Not working all the time
  return email.match(EMAIL_REGEX);
}

/**
 * This will parse a delimited string into an array of
 * arrays. The default delimiter is the comma, but this
 * can be overriden in the second argument.
 *
 * ref: http://stackoverflow.com/a/1293163/2343
 **/
export function CSVToArray(strData: string, strDelimiter?: string): any[][] {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = (strDelimiter || ",");

  // Create a regular expression to parse the CSV values.
  let objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  let arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec(strData)) {
    // Get the delimiter that was found.
    let strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter
    ) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);

    }

    let strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      );
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];

    }
    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return (arrData);
}
