import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const { useRef, useState, useEffect } = React;

interface IBounds {
  left: number;
  top: number;
  width: number;
  height: number;
}

export function useMeasure<T extends Element>(): [{ ref: React.MutableRefObject<T> }, IBounds] {
  const ref = useRef<T>();
  const [bounds, setBounds] = useState<IBounds>({
    left: 0, top: 0, width: 0, height: 0,
  });

  const [observer] = useState(
    () => new ResizeObserver(([entry]) => window.requestAnimationFrame(() => {
      if (ref && ref.current) {
        setBounds(entry.contentRect);
      }
    })),
  );
  // eslint-disable-next-line no-sequences
  useEffect(() => (observer.observe(ref.current), observer.disconnect), [observer]);

  return [{ ref }, bounds];
}
