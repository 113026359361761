import {
 ISelectTemplateTypeAction,
 IState,
 Step,
 TemplateType,
} from '../../../types/state';

export const selectTemplateType = (state: IState, action: ISelectTemplateTypeAction): IState => ({
    ...state,
    templateType: action.templateType,
    isTemplatesModalVisible: true,
    isEditMode: false,
    step: action.templateType === TemplateType.OtherProjectTemplates
      ? Step.SelectAnotherProject
      : Step.SelectContentGuidelines,
  });
