import * as React from 'react';
import { useLazyQuery } from '@apollo/client';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { SelectedColumnsInput } from '@frontend/app/types/globalTypes';

import { size } from 'lodash';
import { DOWNLOAD_MEMBER_CSV } from '../queries/DownloadMemberCSVQuery';
import {
  DownloadMemberCSVQuery,
  DownloadMemberCSVQueryVariables,
} from '../queries/types/DownloadMemberCSVQuery';

const {
  useState, useEffect, useCallback, useMemo,
} = React;

export const useExportToCSV = (
  searchQuery: IMemberSearchQuery,
  columnsInput: SelectedColumnsInput,
  selectedMemberIds?: number[],
) => {
  const [isExporting, setIsExporting] = useState<boolean>(false);

  const query = useMemo(() => {
    if (size(selectedMemberIds) > 0) {
      return { ...searchQuery, ...{ includeMemberIds: selectedMemberIds } };
    }
    return searchQuery;
  }, [searchQuery, selectedMemberIds]);

  const [downloadCsv, {
    data: {
      csv = null,
    } = {},
    loading,
    error,
  }] = useLazyQuery<DownloadMemberCSVQuery, DownloadMemberCSVQueryVariables>(DOWNLOAD_MEMBER_CSV, {
    variables: {
      query,
      columns: columnsInput,
    },
    onError: () => {
      setIsExporting(false);
    },
  });

  const handleExportToCSV = useCallback(() => {
    downloadCsv();
    setIsExporting(true);
  }, [downloadCsv, setIsExporting]);

  useEffect(() => {
    if (!isExporting || loading || !csv) {
      return;
    }

    const csvData = new Blob([csv], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);

    const link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', `members-${Date.now()}.csv`);
    document.body.appendChild(link);

    setIsExporting(false);

    link.click();
    document.body.removeChild(link);
  }, [isExporting, csv, loading]);

  return {
    exportToCSV: handleExportToCSV,
    loading: isExporting,
    error,
  };
};
