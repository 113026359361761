/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_15fjn_157 {
  display: flex;
}

._justify-content-space-between_15fjn_161 {
  justify-content: space-between;
}

._tabular-nums_15fjn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_15fjn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ImportData_15fjn_178 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
}
._ImportData_15fjn_178 ._art_15fjn_186 {
  margin: 1.5rem auto 2.25rem;
}
._ImportData_15fjn_178 h4 {
  display: inline-block;
  margin: 0 auto 0.5rem;
  font-weight: 600;
  font-size: 1rem;
}
._ImportData_15fjn_178 ._loading_15fjn_195 {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 4.75rem;
}
._ImportData_15fjn_178 ._loading_15fjn_195 ._spinnerIcon_15fjn_200 {
  color: #3996e0;
}
._ImportData_15fjn_178 ._result_15fjn_203 {
  margin: 0.75rem auto;
  padding: 0;
  list-style-type: none;
}
._ImportData_15fjn_178 ._result_15fjn_203 li {
  display: flex;
  width: 26.25rem;
  margin: 0 auto 0.75rem;
  padding: 0.75rem 1.5rem;
  border: 0.0625rem solid #e9e8ea;
  border-radius: 0.25rem;
  color: #4f4949;
  align-items: center;
}
._ImportData_15fjn_178 ._result_15fjn_203 li:last-of-type {
  margin-bottom: 0;
}
._ImportData_15fjn_178 ._result_15fjn_203 li ._xcircleIcon_15fjn_221,
._ImportData_15fjn_178 ._result_15fjn_203 li ._checkIcon_15fjn_222 {
  min-width: 1.25rem;
  min-height: 1.25rem;
  margin: 0 -0.75rem 0 auto;
}
._ImportData_15fjn_178 ._result_15fjn_203 li ._checkIcon_15fjn_222 {
  fill: #3996e0;
}
._ImportData_15fjn_178 ._result_15fjn_203 li._errorMessage_15fjn_230 {
  border-color: #d9534f;
  color: #d9534f;
}
._ImportData_15fjn_178 ._button_15fjn_234 {
  min-width: 11.25rem;
  margin: 2rem auto;
  padding-right: 2rem;
  padding-left: 2rem;
  font-size: 0.875rem;
}