/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dkbo_157 {
  display: flex;
}

._justify-content-space-between_1dkbo_161 {
  justify-content: space-between;
}

._tabular-nums_1dkbo_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dkbo_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FailedMembers_1dkbo_178 ._actionsWrapper_1dkbo_178 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  height: 100%;
  padding-right: 0.75rem;
}
._FailedMembers_1dkbo_178 ._actionsWrapper_1dkbo_178 ._button_1dkbo_185 {
  margin-right: 1rem;
}
._FailedMembers_1dkbo_178 ._actionsWrapper_1dkbo_178 ._button_1dkbo_185:last-child {
  margin-right: 0;
}