import * as React from 'react';
import {
  Alert,
  Checkbox,
  Typography,
  Form,
  Space,
  ValidateStatus,
} from '@revfluence/fresh';
import { Input } from 'antd';

import { FormatPayment, ValidatePaymentInput } from '@frontend/app/utils/payment';
import { isEmpty } from 'lodash';
import { DollarInput } from '../../utils/DollarInput';

const { Text, Link } = Typography;
const { TextArea } = Input;

const {
  useRef,
  useState,
  useCallback,
} = React;

interface IProps {
  paymentPeriod: number;
  advancedTerms: boolean;
  toggleSpecialPaymentTerms: boolean;
  specialPaymentTerms: string;
  showHideNewPrice: boolean;
  newPrice: number;
  onShowHideNewPrice: (showHideNewPrice: boolean) => void;
  onUpdateNewPrice: (updatedNewPrice: number) => void;
  onToggleSpecialPaymentTerms: (toggleSpecialPaymentTerms: boolean) => void;
  onUpdateSpecialPaymentTerms: (specialPaymentTerms: string) => void;
}

interface IState {
  validateStatus?: ValidateStatus;
  required?: boolean;
  help?: string;
}

const Payment: React.FC<IProps> = React.memo((props) => {
  const {
    advancedTerms,
    showHideNewPrice,
    newPrice,
    paymentPeriod,
    toggleSpecialPaymentTerms,
    specialPaymentTerms,
    onShowHideNewPrice,
    onUpdateNewPrice,
    onToggleSpecialPaymentTerms,
    onUpdateSpecialPaymentTerms,
  } = props;

  const dollarInputRef = useRef<React.ElementRef<typeof Input>>(null);
  const [formItemLayout, setFormItemLayout] = useState<IState>({});

  const handleShowHideNewPriceChange = useCallback((e) => {
    onShowHideNewPrice(e.target.checked);
    setTimeout(() => {
      if (e.target.checked) {
        dollarInputRef?.current?.input?.focus();
      }
    }, 500);
  }, [onShowHideNewPrice]);

  const handleNewPricePriceChange = useCallback((value: number) => {
    onUpdateNewPrice(value);
    setFormItemLayout(
      (prevFormItemLayout) => (value !== 0 ? {} : prevFormItemLayout),
    );
  }, [onUpdateNewPrice]);

  const handleToggleSpecialPaymentTermsChange = useCallback((e) => {
    onToggleSpecialPaymentTerms(e.target.checked);
  }, [onToggleSpecialPaymentTerms]);

  const handleUpdateSpecialPaymentTermsChange = useCallback((e) => {
    onUpdateSpecialPaymentTerms(e.target.value);
  }, [onUpdateSpecialPaymentTerms]);

  const handleOnBlur = useCallback(() => {
    const amountText = dollarInputRef?.current?.input?.value;
    const paymentValidationMessage = ValidatePaymentInput(amountText);
    if (!isEmpty(paymentValidationMessage)) {
      setFormItemLayout({
        validateStatus: 'error',
        required: true,
        help: paymentValidationMessage,
      });
      return;
    }

    const amountNumericValue = parseFloat(amountText);
    const fixedAmount = FormatPayment(amountNumericValue);
    onUpdateNewPrice(fixedAmount);
    setFormItemLayout(
      (prevFormItemLayout) => (fixedAmount !== 0 ? {} : prevFormItemLayout),
    );
  }, [onUpdateNewPrice, dollarInputRef]);

  return (
    <Space direction="vertical">
      {showHideNewPrice && (
        <Alert
          message="Payment Terms"
          description={(
            <Text>
              {`Default payment terms require an influencer to be paid ${paymentPeriod} days after posting.`}
              {' '}
              If you wish to change these terms please email
              {' '}
              <Link
                href="mailto:help@aspireiq.com"
                target="_blank"
              >
                help@aspireiq.com
              </Link>
              {' '}
              prior to sending a brief.
            </Text>
          )}
          type="info"
        />
      )}
      <Checkbox
        onChange={handleShowHideNewPriceChange}
        checked={showHideNewPrice}
      >
        <Text strong>Payment</Text>
      </Checkbox>
      {showHideNewPrice
        && (
          <Space direction="vertical">
            <Form layout="vertical">
              <Form.Item
                {...formItemLayout}
                label="Describe how much payment will be provided to creators."
              >
                <DollarInput
                  style={{
                    width: '100px',
                  }}
                  onChange={handleNewPricePriceChange}
                  onBlur={handleOnBlur}
                  value={newPrice}
                  ref={dollarInputRef}
                />
              </Form.Item>
              <Alert
                message="You can enter custom payment amounts per member in the next step."
                type="info"
              />
            </Form>
          </Space>
        )}
      {showHideNewPrice && advancedTerms
        && (
          <>
            <Checkbox
              onChange={handleToggleSpecialPaymentTermsChange}
              checked={toggleSpecialPaymentTerms}
            >
              <Text strong>This collab has special payment terms</Text>
            </Checkbox>
            <TextArea
              placeholder="E.g. payment will be made in the form of an Amazon gift card"
              rows={4}
              value={specialPaymentTerms}
              onChange={handleUpdateSpecialPaymentTermsChange}
              maxLength={1000}
              showCount
            />
          </>
        )}
    </Space>
  );
});

Payment.displayName = 'Payment';

export default Payment;
