import 'css-chunk:src/components/widgets/Invite/Favorite.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "buttonWidth": "80",
  "popoverWidth": "320",
  "flex": "_flex_4dfi7_157",
  "justify-content-space-between": "_justify-content-space-between_4dfi7_161",
  "tabular-nums": "_tabular-nums_4dfi7_165",
  "Favorite": "_Favorite_4dfi7_169",
  "Invite": "_Invite_4dfi7_169",
  "isFetching": "_isFetching_4dfi7_416",
  "loading": "_loading_4dfi7_423",
  "hideButton": "_hideButton_4dfi7_427",
  "select": "_select_4dfi7_427",
  "inviteButton": "_inviteButton_4dfi7_431",
  "groupOption": "_groupOption_4dfi7_431",
  "groupOptionAction": "_groupOptionAction_4dfi7_431",
  "toggleButton": "_toggleButton_4dfi7_431",
  "withButton": "_withButton_4dfi7_437",
  "selectButton": "_selectButton_4dfi7_444",
  "withBorder": "_withBorder_4dfi7_448",
  "isFocused": "_isFocused_4dfi7_473",
  "inviting": "_inviting_4dfi7_496",
  "wait": "_wait_4dfi7_499",
  "disabled": "_disabled_4dfi7_503",
  "popover": "_popover_4dfi7_507",
  "popoverContentWrapper": "_popoverContentWrapper_4dfi7_511",
  "shown": "_shown_4dfi7_514",
  "message": "_message_4dfi7_514",
  "popoverContent": "_popoverContent_4dfi7_511",
  "option": "_option_4dfi7_524",
  "noSelect": "_noSelect_4dfi7_544",
  "communityOption": "_communityOption_4dfi7_547",
  "optionTitle": "_optionTitle_4dfi7_547",
  "programOption": "_programOption_4dfi7_557",
  "noPrograms": "_noPrograms_4dfi7_560",
  "accountLink": "_accountLink_4dfi7_584",
  "ineligible": "_ineligible_4dfi7_589",
  "error": "_error_4dfi7_592",
  "noCommunities": "_noCommunities_4dfi7_596",
  "new": "_new_4dfi7_618",
  "invited": "_invited_4dfi7_619",
  "approved": "_approved_4dfi7_620",
  "tooltip": "_tooltip_4dfi7_632",
  "ammendAction": "_ammendAction_4dfi7_639",
  "FavoriteButton": "_FavoriteButton_4dfi7_663",
  "HeartButton": "_HeartButton_4dfi7_671",
  "FavoriteButtonDisabled": "_FavoriteButtonDisabled_4dfi7_674",
  "active": "_active_4dfi7_679",
  "isLoading": "_isLoading_4dfi7_682",
  "isDisabled": "_isDisabled_4dfi7_689",
  "spinner": "_spinner_4dfi7_696",
  "groupOptionActionLabel": "_groupOptionActionLabel_4dfi7_709",
  "addButton": "_addButton_4dfi7_719",
  "show": "_show_4dfi7_514"
};