import { ComparisonOperator, ComboOperator, MappedOperator } from './Operator';

interface IConditionOperator {
  value: MappedOperator;
  operatorLabel: string;
  inlineLabel: string;
  secondaryInlineLabel?: string;
  weight: number;
}

export const DateConditionOperators: IConditionOperator[] = [
  {
    value: ComparisonOperator.EQUALS,
    operatorLabel: 'is',
    inlineLabel: 'is',
    weight: 1,
  },
  {
    value: ComboOperator.NOT_EQUALS,
    operatorLabel: 'is not',
    inlineLabel: 'is not',
    weight: 2,
  },
  {
    value: ComboOperator.BETWEEN,
    operatorLabel: 'between',
    inlineLabel: 'between',
    secondaryInlineLabel: '-',
    weight: 3,
  },
  {
    value: ComparisonOperator.GREATER_THAN,
    operatorLabel: 'after',
    inlineLabel: 'after',
    weight: 4,
  },
  {
    value: ComparisonOperator.LESS_THAN,
    operatorLabel: 'before',
    inlineLabel: 'before',
    weight: 5,
  },
  {
    value: ComparisonOperator.IN_THE_LAST,
    operatorLabel: 'in the last...',
    inlineLabel: 'in the last',
    secondaryInlineLabel: 'days',
    weight: 6,
  },
  {
    value: ComboOperator.NOT_IN_THE_LAST,
    operatorLabel: 'not in the last...',
    inlineLabel: 'not in the last',
    secondaryInlineLabel: 'days',
    weight: 7,
  },
  {
    value: ComparisonOperator.IN_THE_NEXT,
    operatorLabel: 'in the next...',
    inlineLabel: 'in the next',
    secondaryInlineLabel: 'days',
    weight: 7,
  },
  {
    value: ComboOperator.NOT_IN_THE_NEXT,
    operatorLabel: 'not in the next...',
    inlineLabel: 'not in the next',
    secondaryInlineLabel: 'days',
    weight: 8,
  },
  {
    value: ComparisonOperator.MORE_THAN_AGO,
    operatorLabel: 'more than ... days ago',
    inlineLabel: 'more than',
    secondaryInlineLabel: 'days ago',
    weight: 9,
  },
  {
    value: ComboOperator.NOT_MORE_THAN_AGO,
    operatorLabel: 'not more than ... days ago',
    inlineLabel: 'not more than',
    secondaryInlineLabel: 'days ago',
    weight: 10,
  },
  {
    value: ComparisonOperator.HAS_VALUE,
    operatorLabel: 'has value',
    inlineLabel: 'has value',
    weight: 11,
  },
  {
    value: ComboOperator.IS_NULL,
    operatorLabel: 'has no value',
    inlineLabel: 'has no value',
    weight: 12,
  },
];

export const NumberConditionOperators: IConditionOperator[] = [
  {
    value: ComparisonOperator.EQUALS,
    operatorLabel: 'is',
    inlineLabel: 'is',
    weight: 1,
  },
  {
    value: ComboOperator.NOT_EQUALS,
    operatorLabel: 'is not',
    inlineLabel: 'is not',
    weight: 2,
  },
  {
    value: ComboOperator.BETWEEN,
    operatorLabel: 'between',
    inlineLabel: 'is between',
    secondaryInlineLabel: '-',
    weight: 3,
  },
  {
    value: ComparisonOperator.GREATER_THAN,
    operatorLabel: 'greater than',
    inlineLabel: 'greater than',
    weight: 4,
  },
  {
    value: ComparisonOperator.GREATER_THAN_EQUALS,
    operatorLabel: 'greater than or equal to',
    inlineLabel: 'greater than or equal to',
    weight: 5,
  },
  {
    value: ComparisonOperator.LESS_THAN,
    operatorLabel: 'less than',
    inlineLabel: 'less than',
    weight: 6,
  },
  {
    value: ComparisonOperator.LESS_THAN_EQUALS,
    operatorLabel: 'less than or equal to',
    inlineLabel: 'less than or equal to',
    weight: 7,
  },
  {
    value: ComparisonOperator.HAS_VALUE,
    operatorLabel: 'has a value',
    inlineLabel: 'has a value',
    weight: 8,
  },
  {
    value: ComboOperator.IS_NULL,
    operatorLabel: 'has no value',
    inlineLabel: 'has no value',
    weight: 9,
  },
];

export const BooleanConditionOperators: IConditionOperator[] = [
  {
    value: ComparisonOperator.EQUALS,
    operatorLabel: 'is',
    inlineLabel: 'is',
    weight: 1,
  },
  {
    value: ComboOperator.NOT_EQUALS,
    operatorLabel: 'is not',
    inlineLabel: 'is not',
    weight: 2,
  },
  {
    value: ComparisonOperator.HAS_VALUE,
    operatorLabel: 'has a value',
    inlineLabel: 'has a value',
    weight: 3,
  },
  {
    value: ComboOperator.IS_NULL,
    operatorLabel: 'has no value',
    inlineLabel: 'has no value',
    weight: 4,
  },
];

export const ArrayConditionOperators: IConditionOperator[] = [
  {
    value: ComparisonOperator.CONTAINS,
    operatorLabel: 'includes',
    inlineLabel: 'includes',
    weight: 1,
  },
  {
    value: ComboOperator.NOT_CONTAINS,
    operatorLabel: 'does not include',
    inlineLabel: 'does not include',
    weight: 2,
  },
  {
    value: ComparisonOperator.EQUALS,
    operatorLabel: 'is exactly',
    inlineLabel: 'is exactly',
    weight: 3,
  },
  {
    value: ComboOperator.NOT_EQUALS,
    operatorLabel: 'is not exactly',
    inlineLabel: 'is not exactly',
    weight: 4,
  },
  {
    value: ComparisonOperator.HAS_VALUE,
    operatorLabel: 'has a value',
    inlineLabel: 'has a value',
    weight: 5,
  },
  {
    value: ComboOperator.IS_NULL,
    operatorLabel: 'has no value',
    inlineLabel: 'has no value',
    weight: 6,
  },
];

export const SingleSelectConditionOperators: IConditionOperator[] = [
  {
    value: ComparisonOperator.EQUALS,
    operatorLabel: 'is',
    inlineLabel: 'is',
    weight: 1,
  },
  {
    value: ComboOperator.NOT_EQUALS,
    operatorLabel: 'is not',
    inlineLabel: 'is not',
    weight: 2,
  },
  {
    value: ComparisonOperator.HAS_VALUE,
    operatorLabel: 'has a value',
    inlineLabel: 'has a value',
    weight: 3,
  },
  {
    value: ComboOperator.IS_NULL,
    operatorLabel: 'has no value',
    inlineLabel: 'has no value',
    weight: 4,
  },
];

export const TextConditionOperators: IConditionOperator[] = [
  {
    value: ComparisonOperator.EQUALS,
    operatorLabel: 'is',
    inlineLabel: 'is',
    weight: 1,
  },
  {
    value: ComboOperator.NOT_EQUALS,
    operatorLabel: 'is not',
    inlineLabel: 'is not',
    weight: 2,
  },
  {
    value: ComparisonOperator.CONTAINS,
    operatorLabel: 'includes the substring',
    inlineLabel: 'includes the substring',
    weight: 3,
  },
  {
    value: ComboOperator.NOT_CONTAINS,
    operatorLabel: 'does not include the substring',
    inlineLabel: 'does not include the substring',
    weight: 4,
  },
  {
    value: ComparisonOperator.HAS_VALUE,
    operatorLabel: 'has a value',
    inlineLabel: 'has a value',
    weight: 5,
  },
  {
    value: ComboOperator.IS_NULL,
    operatorLabel: 'has no value',
    inlineLabel: 'has no value',
    weight: 6,
  },
];
