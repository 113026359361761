import * as React from 'react';
import { isFinite } from 'lodash';

import { Col, Row, useBreakpoint } from '@revfluence/fresh';
import { formatters } from '@frontend/app/components/StatisticsCard/formatters';

import { Link } from 'react-router-dom';
import Tab from '../../../../components/Tab';

import { IImpact } from '../../Reporting';
import { composeImpactValue } from '../../../../utils/reportingFunctions';

export type MetricsTabsProps = {
  impact: IImpact;
  filter: string;
  igStoryEstimationEnabled?: boolean;
  showTrend?: boolean;
};

const MetricsTabs: React.FC<MetricsTabsProps> = ({
  impact,
  filter,
  igStoryEstimationEnabled,
  showTrend,
}: MetricsTabsProps) => {
  const {
    roi, content, impressions, engagements, sales,
  } = impact;
  const trendDirection = (n: number | null) => (n > 0 || !n ? 'up' : 'down');
  const trendColor = (n: number | null) => (isFinite(n) ? n > 0 ? 'green' : 'red' : 'gray');
  const trendValue = (n: number | null) => (isFinite(n) ? Math.abs(n).toFixed(0) : '0');
  const screens = useBreakpoint();
  const flex = screens.md ? 1 : undefined;
  return (
    <Row gutter={[12, 12]} wrap={false} style={{ overflowX: 'scroll' }}>
      <Col flex={flex}>
        <Link to="?filter=">
          <Tab
            id="impact"
            isActive={!filter}
            color="green"
            tooltip="We calculate Impact Value by adding the values for Awareness, Engagements, Content, and Sales and divide the total by the amount you have paid creators."
            statisticsProps={{
              title: 'Impact Value',
              value: composeImpactValue(roi.value),
              suffix: 'x',
              size: 'large',
            }}
            trendProps={{
              value: trendValue(roi.percentDiff),
              direction: trendDirection(roi.percentDiff),
              suffix: '%',
              color: trendColor(roi.percentDiff),
            }}
            showTrend={showTrend}
          />
        </Link>
      </Col>
      <Col flex={flex}>
        <Link to="?filter=content">
          <Tab
            id="content"
            isActive={filter === 'content'}
            color="blue"
            tooltip="Total estimated production cost of all your content pieces."
            statisticsProps={{
              title: 'Content',
              valueRender: () => formatters.default(content.value),
              size: 'large',
              prefix: '$',
            }}
            trendProps={{
              value: trendValue(content.percentDiff),
              direction: trendDirection(content.percentDiff),
              suffix: '%',
              color: trendColor(content.percentDiff),
            }}
            showTrend={showTrend}
          />
        </Link>
      </Col>
      <Col flex={flex}>
        <Link to="?filter=impressions">
          <Tab
            id="impressions"
            isActive={filter === 'impressions'}
            color="plum"
            tooltip={igStoryEstimationEnabled
              ? 'Total estimated value of the views (excluding YouTube), impressions, estimated impressions, and potential reach you generated.'
              : 'Total estimated value of the views (excluding YouTube), impressions, and potential reach you generated.'}
            statisticsProps={{
              title: 'Awareness',
              valueRender: () => formatters.default(impressions.value),
              size: 'large',
              prefix: '$',
            }}
            trendProps={{
              value: trendValue(impressions.percentDiff),
              direction: trendDirection(impressions.percentDiff),
              suffix: '%',
              color: trendColor(impressions.percentDiff),
            }}
            showTrend={showTrend}
          />
        </Link>
      </Col>

      <Col flex={flex}>
        <Link to="?filter=engagements">
          <Tab
            id="engagements"
            isActive={filter === 'engagements'}
            color="orange"
            tooltip="Total estimated value of the engagements you generated."
            statisticsProps={{
              title: 'Engagements',
              valueRender: () => formatters.default(engagements.value),
              size: 'large',
              prefix: '$',
            }}
            trendProps={{
              value: trendValue(engagements.percentDiff),
              direction: trendDirection(engagements.percentDiff),
              suffix: '%',
              color: trendColor(engagements.percentDiff),
            }}
            showTrend={showTrend}
          />
        </Link>
      </Col>
      <Col flex={flex}>
        <Link to="?filter=sales">
          <Tab
            id="sales"
            isActive={filter === 'sales'}
            color="teal"
            tooltip="Total sales generated via promo codes or sales tracking links."
            statisticsProps={{
              title: 'Sales',
              valueRender: () => formatters.default(sales.value),
              size: 'large',
              prefix: '$',
            }}
            trendProps={{
              value: trendValue(sales.percentDiff),
              direction: trendDirection(sales.percentDiff),
              suffix: '%',
              color: trendColor(sales.percentDiff),
            }}
          />
        </Link>
      </Col>

    </Row>
  );
};

export default MetricsTabs;
