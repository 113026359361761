import { useMemo } from 'react';
import {
 map, keyBy, isUndefined, sortBy,
} from 'lodash';

import { talentAgentLabel } from '@frontend/app/containers/Projects/types';
import { FieldType } from '@frontend/app/types/Fields';
import { useSchemas } from './useSchemas';
import { IField, IApplicationFormFields } from '../../types';

export const useFields = (applicationFormFields: IApplicationFormFields) => {
  const {
    allSchemas,
  } = useSchemas();

  const schemasById = useMemo(() => keyBy(allSchemas, (schema) => schema.id), [allSchemas]);

  const mappedSchemas = useMemo<IField[]>(() => map(applicationFormFields?.memberFieldSchemas, (schemaField) => {
      const schema = schemasById[schemaField.schemaId];
      const active = isUndefined(schemaField.active) ? true : schemaField.active;

      if (isUndefined(schema)) {
        // This happens when a user remove a field from Project
        // @TODO Remove if BE solves this.
        return schema;
      }

      return {
        id: schema.id,
        name: schema.name,
        type: schema.type,
        label: schemaField.label,
        required: schemaField.required,
        choices: schema.choices,
        source: 'schema',
        order: schemaField.order,
        active,
      } as IField;
    }), [applicationFormFields, schemasById]);

  const mappedColumns = useMemo<IField[]>(() => map(applicationFormFields?.dbColumns, (columnField, index) => {
      const id = -(index + 1);
      const active = isUndefined(columnField.active) ? true : columnField.active;
      switch (columnField.name) {
        case 'email':
          return {
            id,
            type: FieldType.EMAIL,
            label: columnField.label,
            name: columnField.name,
            required: columnField.required,
            choices: null,
            active,
            source: 'dbColumn',
            order: columnField.order,
          } as IField;

        case talentAgentLabel:
          return {
            id,
            type: FieldType.TALENT_AGENTS,
            label: columnField.label,
            name: columnField.name,
            required: columnField.required,
            choices: null,
            active,
            source: 'dbColumn',
            order: columnField.order,
          } as IField;

        default:
          return {
            id,
            type: 'TEXT',
            label: columnField.label,
            name: columnField.name,
            required: columnField.required,
            choices: null,
            active,
            source: 'dbColumn',
            order: columnField.order,
          } as IField;
      }
    }), [applicationFormFields]);

  return useMemo(() => {
    const mergedArray = [
      ...mappedColumns,
      ...mappedSchemas,
    ];

    const orderedArray = sortBy(mergedArray, ['order', 'id'], ['DESC', 'ASC']);

    return orderedArray;
  }, [mappedSchemas, mappedColumns]);
};
