._AnnualEqual_f8yd3_1 ._datePicker_f8yd3_1,
._AnnualEqual_f8yd3_1 ._datePicker_f8yd3_1 > div {
  width: 100%;
}

._datePickerDropdown_f8yd3_6 .ant-picker-year-btn,
._datePickerDropdown_f8yd3_6 .ant-picker-header-super-prev-btn,
._datePickerDropdown_f8yd3_6 .ant-picker-header-super-next-btn {
  display: none !important;
}
._datePickerDropdown_f8yd3_6 .ant-picker-month-btn {
  pointer-events: none !important;
}