import * as React from 'react';
import cx from 'classnames';
import { map, capitalize } from 'lodash';

import { Tooltip } from 'antd';
import { EditorState } from 'draft-js';
import { BoldIcon, ItalicIcon, UnderlineIcon } from '@frontend/app/components';

import { TInlineStyle } from './types';

import styles from './InlineStyles.module.scss';

const INLINE_STYLE_ICON: {
  [key in TInlineStyle]: React.ReactNode;
} = {
  BOLD: <BoldIcon size={14} />,
  ITALIC: <ItalicIcon size={14} />,
  UNDERLINE: <UnderlineIcon size={34} />,
};
const INLINE_STYLES: TInlineStyle[] = [
  'BOLD', 'ITALIC', 'UNDERLINE',
];
const INLINE_STYLE_HOTKEY: {
  [key in TInlineStyle]: string;
} = {
  BOLD: '⌘B',
  ITALIC: '⌘I',
  UNDERLINE: '⌘U',
};

interface IProps {
  editorState: EditorState;
  toggleInlineStyle(style: TInlineStyle): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const InlineStyles: React.FC<IProps> = React.memo((props) => {
  const { editorState } = props;
  const inlineStyles = editorState.getCurrentInlineStyle();

  return (
    <div className={cx(styles.InlineStyles, props.className)}>
      {map(INLINE_STYLES, (style) => (
        <Tooltip
          key={style}
          overlayClassName={styles.Tooltip}
          title={(
            <div className={styles.content}>
              {capitalize(style)}
              <span className={styles.hotkey}>
                {INLINE_STYLE_HOTKEY[style]}
              </span>
            </div>
          )}
        >
          <div
            className={cx(styles.item, {
              [styles.active]: inlineStyles.has(style),
            })}
            onMouseDown={(event) => {
              event.preventDefault();

              props.toggleInlineStyle(style);
            }}
          >
            {INLINE_STYLE_ICON[style]}
          </div>
        </Tooltip>
      ))}
    </div>
  );
});

InlineStyles.displayName = 'InlineStyles';
