import React, { useEffect } from 'react';
import {
  Button,
  Card, Col, message, Row, Space, Switch, Typography,
} from '@revfluence/fresh';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { useUpdateImportSyncSettings } from '@frontend/applications/ProductFulfillmentApp/hooks/useUpdateImportSyncSettings';
import styles from './SyncPreferencesSettings.scss';
import drawerStyles from './ImportSettingsDrawer.scss';
import { useImportSettingsContext } from './ImportSettingsContext';
import { useProductsContext } from '../ProductsContext';

const { Text } = Typography;

export const SyncPreferenceFooter = () => {
  const { clientConfig } = useGetClientConfig();
  const {
    setIsImportSettingsDrawerOpen,
    setImportSettingScreen,
  } = useProductsContext();
  const {
    autoSyncFromShopify,
  } = useImportSettingsContext();

  const { updateImportSyncSettings } = useUpdateImportSyncSettings({
    onCompleted: () => {
      message.success('Sync settings saved successfully');
    },
    onError: (error) => {
      message.error(error.message || 'Failed to save Sync settings');
    },
  });

  const handleSave = () => {
    updateImportSyncSettings({
      variables: {
        importSyncSettings: {
          autoSyncFromShopify,
        },
      },
    });
  };

  const handleCancel = () => {
    setIsImportSettingsDrawerOpen(false);
    setImportSettingScreen('root');
  };

  const isNoChange = autoSyncFromShopify === clientConfig?.importSyncSettings?.autoSyncFromShopify;

  return (
    <Row align="middle" justify="center" gutter={24}>
      <Col flex="215px">
        <Button className={drawerStyles.footerBtn} size="large" onClick={handleCancel}>
          Cancel
        </Button>
      </Col>
      <Col flex="215px">
        <Button
          type="primary"
          className={drawerStyles.footerBtn}
          size="large"
          onClick={handleSave}
          disabled={isNoChange}
        >
          Save
        </Button>
      </Col>
    </Row>
  );
};

export const SyncPreferencesSettings = () => {
  const { clientConfig } = useGetClientConfig();
  const { importSettingScreen, isImportSettingsDrawerOpen } = useProductsContext();
  const {
    autoSyncFromShopify, setAutoSyncFromShopify,
  } = useImportSettingsContext();

  useEffect(() => {
    setAutoSyncFromShopify(clientConfig?.importSyncSettings?.autoSyncFromShopify || false);
  }, [setAutoSyncFromShopify, clientConfig?.importSyncSettings?.autoSyncFromShopify, importSettingScreen, isImportSettingsDrawerOpen]);

  const handleChange = () => {
    setAutoSyncFromShopify(!autoSyncFromShopify);
  };
  return (
    <>
      <Space direction="vertical" className={styles.SyncPreferencesSettings}>
        <Text weight="semibold">Automated Sync Settings</Text>
        <Card>
          <Space size="middle" align="start">
            <Space direction="vertical" size={0}>
              <Text weight="semibold">Auto-Sync from Shopify</Text>
              <Text className={styles.cardSubtitle}>Enable automatic daily sync to keep your products and collections updated with Shopify.</Text>
            </Space>
            <Switch checked={autoSyncFromShopify} onChange={handleChange} />
          </Space>
        </Card>
      </Space>
    </>
);
};
