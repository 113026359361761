import * as React from 'react';
import cx from 'classnames';
import { map, isNumber, isFunction } from 'lodash';

import { Checkbox } from '@components';

import {
  Cell,
  LinkCell,
  DateCell,
  MediaCell,
  NameCell,
  NetworkCell,
  NumericCell,
  RatingCell,
  TextCell,
  SelectableCell,
  BooleanCell,
} from './Cell';
import { Tooltip } from '../Tooltip';

import { TableContext, IRowData } from './tableContext';
import { TableRowContext } from './rowContext';

const { useContext, useCallback, useRef } = React;

import styles from './BodyRow.scss';

interface IProps {
  rowIndex: number;
  rowData: IRowData;
  onRowClicked?(rowData: IRowData, metaKey: boolean): void;
  scrollLeft: number;

  // only works when selectable is true
  selected?: boolean;
  toggleRowSelected?(id: string): void;
  toggleShiftPressed?(
    shiftPressed: boolean,
    isRowSelected: boolean,
  ): void;

  visible?: boolean;
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const BodyRow: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    rowIndex,
    rowData,
    toggleRowSelected,
    toggleShiftPressed,
    selected,
    scrollLeft,
  } = props;
  const {
    config,
    orderedColumns,
    showColumns,
  } = useContext(TableContext);

  const checkboxRef = useRef<HTMLDivElement>();

  // disable optimization if page size is small
  const visible = config.pageSize && config.pageSize <= 50 ? true : props.visible;

  const onRowClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isFunction(props.onRowClicked)) {
      return;
    }

    const { metaKey } = event;

    event.stopPropagation();
    event.preventDefault();

    props.onRowClicked(props.rowData, metaKey);
  };

  const onRowSelected = useCallback(() => {
    toggleRowSelected(rowData.id);
  }, [rowData, toggleRowSelected]);

  const onShiftPressed = (
    isPressed: boolean,
    isRowSelected: boolean,
  ) => {
    toggleShiftPressed(isPressed, isRowSelected);
  };

  const row = (
    <div
      className={cx(styles.BodyRow, props.className, {
        [styles.selected]: selected,
      })}
      style={{
        height: isNumber(config.rowHeight) ? `${config.rowHeight}px` : undefined,
      }}
    >

      {config.selectable && (
        <div className={styles.checkboxWrapper} ref={checkboxRef}>
          <Checkbox
            className={styles.checkbox}
            checked={
              rowData.disableSelection // Force unchecked when it's explicitly set to true
                ? false
                : selected
            }
            onChange={onRowSelected}
            disabled={rowData.disableSelection}
          />
        </div>
      )}
      {
        rowData.disabledReason && (
          <Tooltip mountRef={checkboxRef}>
            <div className={styles.tooltip}>
              {rowData.disabledReason}
            </div>
          </Tooltip>
        )
      }
      <div
        className={cx(styles.cellContainer, {
          [styles.visible]: visible,
        })}
        onClick={onRowClicked}
        style={{
          transform: `translateX(-${scrollLeft}px)`,
        }}
      >
        {visible
          && map(orderedColumns, (column) => {
            if (!showColumns[column.field]) {
              return null;
            }

            switch (column.cellType) {
              case 'link': {
                return (
                  <LinkCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field] ? column.linkText || rowData[column.field] : null}
                    href={rowData[column.hrefField]}
                    handler={rowData[column.handlerField]}
                    config={column}
                    useAnchor={column.useAnchor}
                    openInNewTab={column.openInNewTab}
                    title={column.anchorTitleField ? rowData[column.anchorTitleField] : undefined}
                    nullStr={column.nullStr}
                  />
                );
              }

              case 'date': {
                return (
                  <DateCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    dateFormatStr={column.dateFormatStr}
                    isUnix={column.isUnix}
                    showDistance={column.showDistance}
                    config={column}
                  />
                );
              }

              case 'media': {
                return (
                  <MediaCell key={column.field} value={rowData[column.field]} config={column} />
                );
              }

              case 'name': {
                return (
                  <NameCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    name={rowData[column.nameField]}
                    badge={rowData[column.badgeField]}
                    config={column}
                  />
                );
              }

              case 'network': {
                return (
                  <NetworkCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    unique={column.unique}
                    showAccountName={column.showAccountName}
                    config={column}
                  />
                );
              }

              case 'numeric': {
                return (
                  <NumericCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    formatStr={column.formatStr}
                    isPrice={column.isPrice}
                    currencyCode={rowData[column.currencyCodeField]}
                    currencyXRate={rowData[column.currencyXRateField]}
                    config={column}
                  />
                );
              }

              case 'rating': {
                return (
                  <RatingCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    config={column}
                  />
                );
              }

              case 'text': {
                return (
                  <TextCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    config={column}
                  />
                );
              }

              case 'selectable': {
                return (
                  <SelectableCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    selected={selected}
                    onSelect={onRowSelected}
                    config={column}
                  />
                );
              }

              case 'boolean':
                return (
                  <BooleanCell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    config={column}
                    nullStr={column.nullStr}
                    trueStr={column.trueStr}
                    falseStr={column.falseStr}
                  />
                );

              default: {
                return (
                  <Cell
                    className={column.cellClassName}
                    key={column.field}
                    value={rowData[column.field]}
                    config={column}
                  />
                );
              }
            }
          })}
      </div>
    </div>
  );

  return (
    <TableRowContext.Provider value={{
      rowIndex,
      rowData,
      rowId: rowData.id,
      isSelected: selected,
      onToggleRowSelected: onRowSelected,
      onToggleShift: onShiftPressed,
    }}
    >
      {config.renderBodyRow && config.renderBodyRow(props.rowData, row)}
      {!config.renderBodyRow && row}
    </TableRowContext.Provider>
  );
});

BodyRow.defaultProps = {
  selected: false,
  toggleRowSelected: () => undefined,
  className: null,
};
BodyRow.displayName = 'BodyRow';
