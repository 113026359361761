/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_u3gf5_157 {
  display: flex;
}

._justify-content-space-between_u3gf5_161 {
  justify-content: space-between;
}

._tabular-nums_u3gf5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_u3gf5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Editor_u3gf5_209 {
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  color: #1a1818;
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: all;
  height: 100%;
  border-top: 0.0625rem solid #dadcde;
}
._Editor_u3gf5_209 li::before {
  display: none !important;
}
._Editor_u3gf5_209._disabled_u3gf5_222 {
  color: #8f8d91;
}
._Editor_u3gf5_209 ._editorContainer_u3gf5_225 {
  height: 100%;
}
._Editor_u3gf5_209 ._editor_u3gf5_225 {
  display: grid;
  width: 100%;
  padding: 1rem 1.5rem 2rem 1.5rem;
  height: 100%;
  cursor: text;
  flex: 1;
  gap: 0.5rem;
  overflow: auto;
  grid-template-rows: 1fr auto;
}
._Editor_u3gf5_209 ._signature_u3gf5_239 {
  margin-top: 1rem;
}
._Editor_u3gf5_209 ._attachments_u3gf5_242 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 1rem 1.5rem 0.75rem;
}
._Editor_u3gf5_209 ._attachments_u3gf5_242 ._item_u3gf5_249 {
  flex-shrink: 0;
}
._Editor_u3gf5_209 ._attachmentSelector_u3gf5_252 {
  margin-right: 0.5rem;
}
._Editor_u3gf5_209 ._editorSpace_u3gf5_255 {
  border-top: 0.0625rem solid #dadcde;
  justify-content: space-between;
  padding: 0.5rem;
  max-height: 3.125rem;
}
._Editor_u3gf5_209 ._trashButton_u3gf5_261 {
  padding: 0.25rem 0.5rem;
}