import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { PartyPopperIcon } from '@frontend/app/components';
import { ThreadLabelType } from '@frontend/app/types/globalTypes';

import { useMessagingContext } from '../context/MessagingAppContext';

import styles from './EmptyState.scss';

interface IProps {
  className: string;
}

export const EmptyState: React.FC<IProps> = (props) => {
  const {
    loadingThreads,
    loadThreadsError,
    threads,
    assigneeType,
    status,
  } = useMessagingContext();

  if (loadingThreads || loadThreadsError || !isEmpty(threads)) {
    return null;
  }

  return (
    <div className={cx(styles.EmptyState, props.className)}>
      {assigneeType === 'you' && status === ThreadLabelType.TODO && (
        <div className={styles.messageWrapper}>
          <PartyPopperIcon size={42} />
          <div className={styles.title}>You did it!</div>
          <div className={styles.subtitle}>Reward yourself with a well-deserved break</div>
        </div>
      )}
    </div>
  );
};
