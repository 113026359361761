import gql from 'graphql-tag';

import { SEGMENT_FOLDER_FRAGMENT } from './fragments/SegmentFolderFragment';

export const GET_SEGMENT_FOLDERS = gql`
  query SegmentFoldersQuery($communityId: Int, $sourceGroup: String) {
    folders: segmentFolders(communityId: $communityId, sourceGroup: $sourceGroup) {
      ...MemberPageSegmentFolder
    }
  }
  ${SEGMENT_FOLDER_FRAGMENT}
`;
