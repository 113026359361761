import * as React from 'react';
import {
  Alert,
  Button,
  IButtonProps,
  Space,
  Tooltip,
} from '@revfluence/fresh';
import { CircleInfoIcon, HashtagIcon, MessagesIcon } from '@revfluence/fresh-icons/regular/esm';
import { isEmpty } from 'lodash';

import {
  IBasicStat,
  IComplexStat,
  ILink,
  StatisticsCard,
} from '@frontend/app/components/StatisticsCard';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import { Empty } from '@frontend/app/refresh-components';
import { InstagramIcon } from '@revfluence/fresh-icons/brands/esm';
import statisticsCardStyles from '@frontend/app/components/StatisticsCard/StatisticsCard.scss';
import { cx } from 'class-variance-authority';
import { Button as ShadCnButton } from '@frontend/shadcn/components/ui/button';
import { ISocialStats } from '../types';
import { ConnectInstagram } from './ConnectInstagram';
import {
  ILabelMap,
  useGetBasicStatsFromLabelMap,
  useGetComplexStatsFromLabelMap,
} from './useGetStatsFromLabelMap';

function reconnectIgAlert(show: boolean, onReconnect: IButtonProps['onClick']): React.ReactNode | undefined {
  if (!show) {
    return undefined;
  }
  return (
    <Alert
      message="You need to re-connect your IG account to make sure your data is up-to-date."
      type="error"
      showIcon
      action={(
        <Space>
          <Button onClick={onReconnect} size="small" type="ghost">
            Re-connect
          </Button>
        </Space>
      )}
      closable
    />
  );
}

interface IProps {
  creators?: ISocialStats['creatorCount'];
  engagements?: ISocialStats['engagements'];
  impressions?: ISocialStats['impressions'];
  isInstagramConnected: boolean;
  links: ILink[];
  onClickConnectInstagram: IButtonProps['onClick'];
  posts?: ISocialStats['postCount'];
  reach?: ISocialStats['reach'];
  tmv?: ISocialStats['tmv'];
  ugc?: ISocialStats['ugc'];
  videoViews?: ISocialStats['views'];
  subtitle: string;
}

// @TODO for p1 this will need to be calculated instead of hardcoded
// eslint-disable-next-line max-len
const SOCIAL_TOOLTIP = 'This section consists of data from all your projects and data from social listening tags and mentions for Instagram.';

const CARD_TITLE = 'Social';

const EMPTY_LIST = [];

export const SocialCard: React.FC<IProps> = React.memo((props) => {
  const {
    creators,
    engagements,
    impressions,
    isInstagramConnected,
    links,
    onClickConnectInstagram,
    posts,
    reach,
    tmv,
    ugc,
    videoViews,
    subtitle,
  } = props;

  const loading = (creators && creators.state === 'loading')
    || (engagements && engagements.state === 'loading')
    || (impressions && impressions.state === 'loading')
    || (posts && posts.state === 'loading')
    || (reach && reach.state === 'loading')
    || (tmv && tmv.state === 'loading')
    || (ugc && ugc.state === 'loading')
    || (videoViews && videoViews.state === 'loading');

  const smallStatsLabelMap = React.useMemo((): ILabelMap<IBasicStat> => ({
    'Posts': posts ? {
      format: 'default',
      stat: posts,
    } : undefined,
    'Creators': creators ? {
      format: 'default',
      stat: creators,
    } : undefined,
    'UGC': ugc ? {
      format: 'default',
      stat: ugc,
    } : undefined,
    'Video Views': videoViews ? {
      format: 'default',
      stat: videoViews,
    } : undefined,
    'TMV': tmv ? {
      format: 'wholeDollars',
      stat: tmv,
    } : undefined,
  }), [
    creators,
    posts,
    tmv,
    ugc,
    videoViews,
  ]);
  const smallStats = useGetBasicStatsFromLabelMap(smallStatsLabelMap);

  const statsLabelMap = React.useMemo((): ILabelMap<IComplexStat> => ({
    Reach: reach ? {
      format: 'default',
      stat: reach,
    } : undefined,
    Impressions: impressions ? {
      format: 'default',
      stat: impressions,
    } : undefined,
    Engagements: engagements ? {
      format: 'default',
      stat: engagements,
    } : undefined,
  }), [
    engagements,
    impressions,
    reach,
  ]);
  const stats = useGetComplexStatsFromLabelMap(statsLabelMap);
  const emptyStats = isEmpty(stats);

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  if (isRefreshUIEnabled && (emptyStats || stats.every((stat) => !stat.value))) {
    return (
      <ShadCnCard className="min-h-full flex flex-col">
        <CardHeader className="pb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="text-icon-grey">
                <MessagesIcon />
              </span>
              <span className="font-medium text-base">
                {CARD_TITLE}
                <span
                  className="text-xs whitespace-nowrap overflow-hidden text-ellipsis ml-2 text-subtitle"
                  title={subtitle}
                >
                  {subtitle}
                </span>
              </span>
            </div>
            <div className={cx(statisticsCardStyles.infoWrapper, { [statisticsCardStyles.loading]: loading })}>
              <Tooltip arrowPointAtCenter placement="bottomRight" title={SOCIAL_TOOLTIP}>
                <CircleInfoIcon />
              </Tooltip>
            </div>
          </div>
        </CardHeader>
        <CardContent className="h-full flex justify-center items-center flex-1">
          <Empty
            icon={HashtagIcon}
            title="See social stats from submitted posts or social listening."
            // eslint-disable-next-line max-len
            description="View project data from posts as they get submitted and connect your Instagram to see post data from tags and mentions about your brand using Social Listening."
            primaryCtaProps={isInstagramConnected ? undefined : {
              icon: InstagramIcon,
              text: 'Connect Instagram',
              onClick: onClickConnectInstagram,
              className: 'gap-2 text-background bg-gradient-to-r from-[#FDBE4B] via-[#E33659] to-[#8D27B4]',
            }}
          />
        </CardContent>
      </ShadCnCard>
    );
  }

  if (!isInstagramConnected && emptyStats) {
    const customContent = (
      <ConnectInstagram onClickConnect={onClickConnectInstagram} />
    );
    return (
      <StatisticsCard
        customContent={customContent}
        icon={<MessagesIcon />}
        links={EMPTY_LIST}
        loading={false}
        smallStats={EMPTY_LIST}
        stats={EMPTY_LIST}
        subtitle={subtitle}
        title={CARD_TITLE}
        tooltip={SOCIAL_TOOLTIP}
      />
    );
  }

  return (
    <div className="flex flex-col">
      <StatisticsCard
        icon={<MessagesIcon />}
        links={links}
        loading={loading}
        smallStats={smallStats}
        stats={stats}
        subtitle={subtitle}
        title={CARD_TITLE}
        tooltip={SOCIAL_TOOLTIP}
        alertComponent={reconnectIgAlert(!isRefreshUIEnabled && !isInstagramConnected, onClickConnectInstagram)}
      />
      {isRefreshUIEnabled && !isInstagramConnected && (
      <ShadCnButton
        onClick={onClickConnectInstagram}
      >
        <InstagramIcon fontSize={16} />
        Connect Instagram
      </ShadCnButton>
    ) }
    </div>
  );
});
SocialCard.displayName = 'SocialCard';
