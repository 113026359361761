import * as React from 'react';
import cx from 'classnames';

import { ChevronRightIcon, ChevronLeftIcon, SpinnerIcon } from '@components';
import { Button } from '@revfluence/fresh';

import { IStepConfig } from '../types';
import { Progressbar } from './Progressbar/Progressbar';
import styles from './styles.scss';

interface IProps {
  currentStepIndex: number;
  stepCount: number;
  currentStep: IStepConfig;
  prevStep?: IStepConfig;
  nextStep?: IStepConfig;
  isNextButtonDisabled: boolean;
  setCurrentStepIndex: (stepIndex: number) => void;
  submit: () => void;
  skip?: () => void;
  isSkipLoading?: boolean;
  isSubmitLoading?: boolean;
  onNext?: (currentStepIndex: number) => void;
}

export const Footer: React.FC<IProps> = React.memo((props: IProps) => {
  const {
    currentStepIndex,
    currentStep,
    prevStep,
    nextStep,
    stepCount,
    isNextButtonDisabled,
    setCurrentStepIndex,
    submit,
    skip,
    isSkipLoading,
    isSubmitLoading,
    onNext,
  } = props;

  const goPrevStep = () => {
    if (prevStep) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const goNextStep = () => {
    if (nextStep) {
      setCurrentStepIndex(currentStepIndex + 1);
      if (typeof onNext === 'function') {
        onNext(currentStepIndex + 1);
      }
    }
  };

  return (
    <div className={styles.Footer}>
      <Progressbar order={currentStepIndex + 1} count={stepCount} />
      <div className={styles.actions}>
        {prevStep && (
          <Button
            disabled={isSubmitLoading || isSkipLoading}
            type="primary"
            className={cx(styles.stepButton, styles.prev)}
            onClick={goPrevStep}
          >
            <ChevronLeftIcon className={styles.arrow} fill="#1F1F21" size={10} />
            {prevStep.buttonLabel}
          </Button>
        )}
        {nextStep && (
          <Button
            disabled={isNextButtonDisabled}
            type="primary"
            className={cx(styles.stepButton, styles.next)}
            onClick={goNextStep}
          >
            {prevStep ? nextStep.buttonLabel : nextStep.title}
            <ChevronRightIcon className={styles.arrow} fill="white" size={10} />
          </Button>
        )}
        {!nextStep && (
          <>
            <Button
              disabled={isNextButtonDisabled || isSubmitLoading || isSkipLoading}
              type="primary"
              className={cx(styles.stepButton, styles.next)}
              onClick={submit}
              icon={isSubmitLoading && <SpinnerIcon className={styles.spinner} size={16} />}
            >
              {currentStep.submitLabel}
            </Button>
            {skip && (
              <Button
                type="text"
                className={styles.skipBtn}
                onClick={skip}
                disabled={isSubmitLoading || isSkipLoading}
                icon={isSkipLoading && <SpinnerIcon className={styles.spinner} size={16} />}
              >
                {currentStep.skipLabel}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
});

Footer.displayName = 'WizardFooter';
