import 'css-chunk:src/components/widgets/SocialProfile/Card/Card.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_q9556_157",
  "justify-content-space-between": "_justify-content-space-between_q9556_161",
  "tabular-nums": "_tabular-nums_q9556_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_q9556_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_q9556_166",
  "Card": "_Card_q9556_170",
  "tooltip": "_tooltip_q9556_306",
  "cardHeader": "_cardHeader_q9556_320",
  "tabs": "_tabs_q9556_331",
  "tabButton": "_tabButton_q9556_335",
  "activeTab": "_activeTab_q9556_354",
  "separator": "_separator_q9556_358",
  "cardTab": "_cardTab_q9556_365",
  "cardTitle": "_cardTitle_q9556_373",
  "visible": "_visible_q9556_381",
  "show": "_show_q9556_1",
  "noData": "_noData_q9556_386"
};