import papa from 'papaparse';
import { startCase } from 'lodash';
import { GetOrdersByIds_orders as IOrder } from '../../queries/types/GetOrdersByIds';

export interface NoCatalogueLineItem {
    [key: string]: string;
}
interface IRow {
    'Order Date': string;
    'Ship to Name': string;
    'Ship to Email': string;
    'Ship to Phone': string;
    'Ship to Address 1': string;
    'Ship to Address 2': string;
    'City': string;
    'State': string;
    'Postal Code': string;
    'Country': string;
    'Additional Details': string;
    [key: string]: string;
}

export async function downloadCSV(data: string, defaultFileName: string) {
    if (window.showSaveFilePicker) {
        // Open a file picker dialog, set the types option to a file extension that is accepted.
        const fileHandle = await window.showSaveFilePicker({
            suggestedName: defaultFileName,
            types: [
                {
                    description: 'CSV Files',
                    accept: {
                        'text/csv': ['.csv'],
                    },
                },
            ],
        });

        // Create a FileSystemWritableFileStream to write to.
        const writable = await fileHandle.createWritable();
        // Write the contents of the file to the stream.
        await writable.write(data);
        // Close the file and write the contents to disk.
        await writable.close();
    } else {
        // Create a downloadable link and click it programmatically
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(csvData);
        link.setAttribute('download', `${defaultFileName}.csv`);
        link.click();
    }
}

export async function orderToCsv(defaultFileName: string, orders: IOrder[], productAttributes: string[]) {
    const headers = [
        'Order Date',
        'Ship to Name',
        'Ship to Email',
        'Ship to Phone',
        'Ship to Address 1',
        'Ship to Address 2',
        'City',
        'State',
        'Postal Code',
        'Country',
        'Additional Details',
    ];

    const table: IRow[] = [];

    orders.forEach((order: IOrder) => {
        const row: IRow = {
            'Order Date': order.createdDate,
            'Ship to Name': `${order.creatorOrderRequest.shipping_address.first_name} ${order.creatorOrderRequest.shipping_address.last_name}`,
            'Ship to Email': order.creatorOrderRequest.email,
            'Ship to Phone': order.creatorOrderRequest.shipping_address.phone,
            'Ship to Address 1': order.creatorOrderRequest.shipping_address.address1,
            'Ship to Address 2': order.creatorOrderRequest.shipping_address.address2,
            'City': order.creatorOrderRequest.shipping_address.city,
            'State': order.creatorOrderRequest.shipping_address.province,
            'Postal Code': order.creatorOrderRequest.shipping_address.zip,
            'Country': order.creatorOrderRequest.shipping_address.country,
            'Additional Details': order.creatorOrderRequest.note,
        };

        order.creatorOrderRequest.no_catalogue_line_items.forEach((item: NoCatalogueLineItem, i: number) => {
            productAttributes.forEach((attribute: string) => {
                // Check if the attribute exists in the item.
                if (typeof item[attribute] !== 'undefined') {
                    const n = i + 1;
                    const columnName = `Product ${n} - ${startCase(attribute)}`;
                    row[columnName] = item[attribute];
                    headers.push(columnName);
                }
            });
        });

        table.push(row);
    });

    const csv = papa.unparse({
        fields: headers,
        data: table,
    });

    await downloadCSV(csv, defaultFileName);
}
