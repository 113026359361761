import gql from 'graphql-tag';

export const GUIDELINE_FRAGMENT = gql`
  fragment GuidelineFragment on Guideline {
    title
    allowTitleEdition
    type
    placeholder
    instructions
  }
`;
