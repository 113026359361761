import { useQuery } from '@apollo/client';

import { GET_USERS_QUERY, GET_ALL_USERS_QUERY } from '@frontend/app/queries';
import { GetUsersQuery } from '@frontend/app/queries/types/GetUsersQuery';
import { GetAllUsersQuery } from '@frontend/app/queries/types/GetAllUsersQuery';

export function useFetchUsers() {
  const {
    loading,
    data: { users = [] } = {},
    error,
    refetch,
  } = useQuery<GetUsersQuery>(GET_USERS_QUERY);

  return {
    loading,
    users,
    error,
    refetch,
  };
}

export function useGetAllUsers() {
  const {
    loading,
    data: { users = [] } = {},
  } = useQuery<GetAllUsersQuery>(
    GET_ALL_USERS_QUERY,
  );

  return {
    users,
    loading,
  };
}
