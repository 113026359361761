/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vmfd4_157 {
  display: flex;
}

._justify-content-space-between_vmfd4_161 {
  justify-content: space-between;
}

._tabular-nums_vmfd4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_vmfd4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ContentOverlay_vmfd4_178 ._fileName_vmfd4_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ContentOverlay_vmfd4_178 {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}
._ContentOverlay_vmfd4_178 ._navigation_vmfd4_194 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 720px;
  height: 52px;
  padding-bottom: 1.25rem;
}
._ContentOverlay_vmfd4_178 ._navigation_vmfd4_194._hidden_vmfd4_202 {
  display: none;
}
._ContentOverlay_vmfd4_178 ._leftArrow_vmfd4_205, ._ContentOverlay_vmfd4_178 ._rightArrow_vmfd4_205 {
  color: #fdfdfd;
  background-color: rgba(253, 253, 253, 0.2);
}
._ContentOverlay_vmfd4_178 ._leftArrow_vmfd4_205:hover, ._ContentOverlay_vmfd4_178 ._rightArrow_vmfd4_205:hover {
  background-color: rgba(253, 253, 253, 0.35);
}
._ContentOverlay_vmfd4_178 ._leftArrow_vmfd4_205 {
  left: 0;
}
._ContentOverlay_vmfd4_178 ._rightArrow_vmfd4_205 {
  right: 0;
  transform: rotateZ(180deg);
}
._ContentOverlay_vmfd4_178 ._fileName_vmfd4_178 {
  color: white;
  margin-top: 20px;
}
._ContentOverlay_vmfd4_178 ._download_vmfd4_223 {
  padding: 10px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  background-color: rgba(253, 253, 253, 0.2);
}
._ContentOverlay_vmfd4_178 ._download_vmfd4_223:hover {
  background-color: rgba(253, 253, 253, 0.35);
}
._ContentOverlay_vmfd4_178 ._download_vmfd4_223 > :not(:last-child) {
  margin-right: 6px;
}
._ContentOverlay_vmfd4_178 ._content_vmfd4_243 {
  padding-top: 20px;
  z-index: 1;
}