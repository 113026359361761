import {
  ICreatorSearchOptions,
  INetworkInfo,
  TRange,
  ConnectSearchPage,
} from '@components';
import {
  TNetworkIdentifier, IToastRefHandles, LoadSpinner, Toast,
} from '@components';
import { chain, isEmpty, isUndefined } from 'lodash';
import * as React from 'react';
import Helmet from 'react-helmet';
import { useHistory } from 'react-router-dom';
import * as qs from 'qs';

import { logger } from '@common';
import {
  useApplicationNavigation,
  useClientFeatureEnabled,
  useProgramsQuery,
  useUploadContent,
} from '@frontend/app/hooks';
import {
  GMAIL_APP_ID,
  OUTLOOK_APP_ID,
} from '@frontend/app/constants/applicationIds';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';

import { ClientFeature } from '@frontend/app/constants';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import styles from './InfluencerSearchPage.scss';

const creatorSearchIcon = require('@frontend/app/assets/svgs/creator-search.svg');

const {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} = React;

interface IProps {
}

export interface INetworkSearchVersion {
  [network: string]: CreatorSearchVersion;
}

// TODO: Hardcoding these here for now, but should be derived from static config files in the future.
const CREATOR_SKILLSETS = [
  { identifier: 'photographer', displayName: 'Photographer', order: 1 },
  { identifier: 'videographer', displayName: 'Videographer', order: 2 },
];

const networkInfos: INetworkInfo[] = [
  {
    identifier: 'instagram' as TNetworkIdentifier,
    audienceNamePlural: 'followers',
    engagementNamePlural: 'likes',
    contentName: 'post',
  },
  {
    identifier: 'youtube' as TNetworkIdentifier,
    audienceNamePlural: 'subscribers',
    engagementNamePlural: 'views',
    contentName: 'video',
  },
  {
    identifier: 'blog' as TNetworkIdentifier,
    audienceNamePlural: 'monthly visitors',
    engagementNamePlural: '',
    contentName: '',
  },
  {
    identifier: 'twitter',
    audienceNamePlural: 'followers',
    engagementNamePlural: 'retweets',
    contentName: 'tweet',
  },
  {
    identifier: 'pinterest',
    audienceNamePlural: 'monthly viewers',
    engagementNamePlural: 'views',
    contentName: 'pin',
  },
  {
    identifier: 'tiktok',
    audienceNamePlural: 'followers',
    engagementNamePlural: 'views',
    contentName: 'video',
  },
];

const ENGAGEMENT_LIMITS_BY_NETWORK: { [network: string]: TRange } = {
  instagram: [0, 50000],
  tiktok: [0, 50000],
  youtube: [0, 50000],
};

const REACH_LIMITS_BY_NETWORK: { [network: string]: TRange } = {
  instagram: [1000, 1000000],
  tiktok: [1000, 1000000],
  youtube: [1000, 1000000],
  blog: [1000, 1000000],
  twitter: [1000, 1000000],
  pinterest: [1000, 1000000],
};

const IQ_IMPRESSION_LIMITS_BY_NETWORK: { [network: string]: TRange } = {
  tiktok: [0, 1000000],
};

const IMPRESSION_LIMITS_BY_NETWORK: { [network: string]: TRange } = {
  tiktok: [100, 1000000],
};

const UPLOAD_FOLDER = 'influencer-image-search';

const options: ICreatorSearchOptions = {
  creatorSkillsets: {
    choices: CREATOR_SKILLSETS,
  },
  network: {
    channelOptions: ['instagram', 'youtube', 'twitter', 'pinterest', 'tiktok'],
  },
  engagement: {
    networkInfos,
  },
  other: {
    tagOptions: [],
  },
};

const helmetHead = {
  script: [{
    type: 'text/javascript',
    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDDanQRDZA0wVZMu4fkT2x3-byjD1Mm4ME&v=3.exp&libraries=places',
  }],
};

const useImageSearchUpload = (clientId, setUploadedImageUrl, setUploadedImageLoading, setUploadedImageError) => {
  const {
    content,
    upload,
    error,
    isUploading,
  } = useUploadContent({
    serviceName: 'social_discovery_app',
    parentFolder: `${clientId}/${UPLOAD_FOLDER}`,
    isTemp: true,
  });

  React.useEffect(() => {
    if (error) {
      setUploadedImageError(error);
    } else if (content && content.fileUrl) {
      setUploadedImageUrl(content.fileUrl);
    } else if (isUploading) {
      setUploadedImageLoading();
    }
  }, [content, error, isUploading, setUploadedImageError, setUploadedImageLoading, setUploadedImageUrl]);

  const onUploadImage = (file: File) => {
    upload(file, 'image');
  };

  return onUploadImage;
};

const InfluencerSearchPage: React.FunctionComponent<IProps> = () => {
  const history = useHistory();
  const [showSearch, setShowSearch] = useState(false);
  const toastRef = useRef<IToastRefHandles>();
  const {
    backendServerApiEndpoint,
    clientId,
    userId,
  } = useApplication();

  const initialProjectId = useMemo(() => {
    const parsedSearch = qs.parse(history.location?.search?.substr(1));
    return parsedSearch.projectId;
  }, [history.location?.search]);

  // TODO: Default search is based on grabbing the most recent project splash url and image searching on that.
  const {
    data: {
      programs: projects = [],
    } = {},
    error,
    loading,
  } = useProgramsQuery({
    variables: {
      clientId,
    },
  });

  const isWorkflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  const isInstagramCreatorsSearchV2 = useClientFeatureEnabled(ClientFeature.INSTAGRAM_CREATOR_SEACH_V2);
  const isTiktokCreatorsSearchV2 = useClientFeatureEnabled(ClientFeature.TIKTOK_CREATOR_SEARCH_V2);
  const isYoutubeCreatorsSearchV2 = useClientFeatureEnabled(ClientFeature.YOUTUBE_CREATOR_SEARCH_V2);
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const {
    goTo: navigateTo,
  } = useApplicationNavigation();

  const handleRequestAddEmail = useCallback((type: 'gmail' | 'outlook') => {
    switch (type) {
      case 'gmail':
        navigateTo(GMAIL_APP_ID, '/settings');
        break;

      case 'outlook':
        navigateTo(OUTLOOK_APP_ID, '/settings');
        break;
    }
  }, [navigateTo]);

  const versionByNetwork: INetworkSearchVersion = useMemo(() => {
    if (
      isUndefined(isInstagramCreatorsSearchV2)
      || isUndefined(isTiktokCreatorsSearchV2)
      || isUndefined(isYoutubeCreatorsSearchV2)
    ) {
      // wait for the feature flags to be loaded
      return null;
    }
    return {
      instagram: isInstagramCreatorsSearchV2 ? CreatorSearchVersion.v2 : CreatorSearchVersion.v1,
      tiktok: isTiktokCreatorsSearchV2 ? CreatorSearchVersion.v2 : CreatorSearchVersion.v1,
      youtube: isYoutubeCreatorsSearchV2 ? CreatorSearchVersion.v2 : CreatorSearchVersion.v1,
    };
  }, [isInstagramCreatorsSearchV2, isTiktokCreatorsSearchV2, isYoutubeCreatorsSearchV2]);

  useEffect(
    () => {
      if (loading || !versionByNetwork) {
        setShowSearch(false);
      } else if (error) {
        logger.error(error);
        toastRef.current?.showMessage?.({
          content: 'There was an error loading the search page.',
          type: 'error',
        });
        setShowSearch(false);
        history.push('/home');
      } else {
        setShowSearch(true);
      }
    },
    [loading, versionByNetwork, error, history],
  );

  const defaultImageSearchUrl = useMemo(
    () => {
      if (isEmpty(projects)) {
        return '';
      }

      const mostRecentProject = chain(projects)
        .orderBy('id', 'desc')
        .first()
        .value();

      return mostRecentProject.splashImageUrl;
    },
    [projects],
  );

  return (
    <>

      {refreshUi && <AppHeader title="Creator Search" />}
      <div className={styles.InfluencerSearchPage}>
        <Helmet {...helmetHead} title="Creator Search" />
        {!showSearch && <LoadSpinner className={styles.spinner} />}
        {showSearch && (
          <>
            {!refreshUi && (
            <header className={styles.header}>
              <div className={styles.square}><img src={creatorSearchIcon} className={styles.sectionIcon} /></div>
              <h2 className={styles.title}>Creator Search</h2>
            </header>
)}
            <ConnectSearchPage
              apiEndpoint={backendServerApiEndpoint}
              apiEndpointV1={backendServerApiEndpoint}
              networkTypes={['instagram']}
              acceptedPlaceIds={[]}
              acceptedCountryCodes={[]}
              acceptedLanguageCodes={[]}
              clientId={clientId}
              userId={userId}
              searchOptions={options}
              uploadFolder={UPLOAD_FOLDER}
              defaultEngagementLimitsByNetwork={ENGAGEMENT_LIMITS_BY_NETWORK}
              defaultReachLimitsByNetwork={REACH_LIMITS_BY_NETWORK}
              defaultImpressionLimitsByNetwork={IMPRESSION_LIMITS_BY_NETWORK}
              defaultIQImpressionLimitsByNetwork={IQ_IMPRESSION_LIMITS_BY_NETWORK}
              useImageSearchUpload={useImageSearchUpload}
              defaultImageSearchUrl={defaultImageSearchUrl}
              showCreateFeatures={false}
              // TODO: Wire these up.
              sendBulkOffer={null}
              exportCsv={null}
              reportAsIncorrect={null}
              onCreatorSelected={null}
              selectedSocialAccountId={null}
              isQa={false}
              onRequestAddEmail={handleRequestAddEmail}
              isWorkflowEnabled={isWorkflowEnabled}
              initialSelectedProjectId={initialProjectId}
              className={styles.searchPageComponent}
              resultsClass={styles.searchPageResults}
              version={versionByNetwork?.instagram}
              versionByNetwork={versionByNetwork}
            />
          </>
        )}
        <Toast useFresh ref={toastRef} />
      </div>
    </>
  );
};

export default InfluencerSearchPage;
