import * as React from 'react';
import cx from 'classnames';

import { valueInRange } from './util';

const { useCallback, useRef } = React;
import styles from './RangeSlider.scss';

interface ITrackProps {
  className?: string;
  max: number;
  min: number;
  range: [number, number];
  onClickPosition(position: number);
}

/**
 * @type {React.FunctionComponent}
 */
export const Track: React.FunctionComponent<ITrackProps> = (props) => {
  const {
    className,
    max,
    min,
    onClickPosition,
    range,
  } = props;

  const trackRef = useRef();

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const { clientX } = e;

    const parentNode: HTMLDivElement = trackRef.current;
    const rect = parentNode.getBoundingClientRect();

    const position = valueInRange(
      min + ((max - min) * (clientX - rect.left)) / rect.width,
      min,
      max,
    );

    onClickPosition(position);
  }, [max, min, onClickPosition]);

  const left = valueInRange((range[0] - min) / (max - min), 0, 1);
  const right = valueInRange((range[1] - min) / (max - min), 0, 1);

  return (
    <div
      className={cx(className, styles.trackWrapper)}
      ref={trackRef}
      onMouseDown={handleMouseDown}
    >
      <div className={styles.Track}>
        <div
          className={styles.selected}
          style={{ left: `${left * 100}%`, right: `${(1 - right) * 100}%` }}
        />
      </div>
      {React.Children.map(props.children, (child: React.ReactElement) =>
        React.cloneElement(child, { trackRef, trackMin: min, trackMax: max }))}
    </div>
  );
};

Track.displayName = 'Track';
