import gql from 'graphql-tag';

export const MEMBER_COMMENTS = gql`
  query MemberCommentsQuery($memberId: Int!) {
    member: memberById(id: $memberId) {
      id
      comments {
        id
        text
        createdDate
        user {
          id
          email
          name
        }
      }
    }
  }
`;
