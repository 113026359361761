import * as React from 'react';
import { first } from 'lodash';

import { OFFER_STATUS, OFFER_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { logger } from '@common';

import { IWorkflowOfferErrorOffer } from '..';

const { useMemo } = React;

export const useText = (offerSource: OFFER_TYPE, offer: IWorkflowOfferErrorOffer) => useMemo(() => {
    switch (offerSource) {
      case OFFER_TYPE.PROMO_CODE: {
        const promo = first(offer.promos);
        if (promo.status === OFFER_STATUS.PAUSED) {
          return {
            title: 'The offer for this project has been paused.',
            paragraph: 'You need to update this offer\'s status. An offer includes the discount criteria and settings you would like to use for all the creators you give these promo codes to.',
          };
        }
        if (promo.status === OFFER_STATUS.DELETED) {
          return {
            title: 'The offer for this project has been deleted.',
            paragraph: 'You need to un-delete this offer. An offer includes the discount criteria and settings you would like to use for all the creators you give these promo codes to.',
          };
        }
        break;
      }
      case OFFER_TYPE.LINK: {
        const link = first(offer.links);
        if (link.status === OFFER_STATUS.DELETED) {
          return {
            title: 'The offer for this project has been deleted.',
            paragraph: 'You need to un-delete this offer. An offer includes the discount criteria and settings you would like to use for all the creators you give these links to.',
          };
        }
        if (link.status === OFFER_STATUS.PAUSED) {
          return {
            title: 'The offer for this project has been paused.',
            paragraph: 'You need to update this offer\'s status. An offer includes the discount criteria and settings you would like to use for all the creators you give these links to.',
          };
        }
        if (offer.expired) {
          return {
            title: 'The offer for this project has expired.',
            paragraph: 'You need to update this offer\'s expiration date. An offer includes the discount criteria and settings you would like to use for all the creators you give these links to.',
          };
        }
        break;
      }
      default: {
        logger.warn('Invalid offer type used on useText hook!');
        return {
          title: '',
          paragraph: '',
        };
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerSource]);
