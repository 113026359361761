import * as React from 'react';
import { Menu } from 'antd';

import { IEnabledOfferSources } from '@affiliates/types';
import { LinkSimpleIcon } from '@revfluence/fresh-icons/regular/esm';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { PromoCodeDetails } from '@affiliates/AspireUI';
import { TOnClickAddOffer } from './types';

import styles from './AddOfferDropdown.scss';

const AddOfferButtonOrder = [
  OFFER_SOURCE.SHOPIFY,
  OFFER_SOURCE.TUNE,
];
const AddOfferButtonLabels = {
  [OFFER_SOURCE.TUNE]: 'Link Tracking Offer',
  [OFFER_SOURCE.SHOPIFY]: 'Promo Code Offer',
};
const AddOfferButtonIcons = {
  [OFFER_SOURCE.TUNE]: <LinkSimpleIcon className={styles.linkIcon} />,
  [OFFER_SOURCE.SHOPIFY]: (
    <span className={styles.icon}>
      <PromoCodeDetails />
    </span>
  ),
};

interface IProps {
  missingShopifyCredentials: boolean;
  onClickSource: TOnClickAddOffer;
  sources: IEnabledOfferSources;
}

export const AddOfferDropdown: React.FC<Readonly<IProps>> = (props) => {
  const {
    missingShopifyCredentials,
    onClickSource,
    sources,
  } = props;
  return (
    <Menu className={styles.AddOfferDropdown} selectedKeys={[]} theme="light">
      {AddOfferButtonOrder.map((source) => {
        if (!sources[source]) {
          return null;
        }

        const label = AddOfferButtonLabels[source];
        if (!label) {
          return null;
        }

        const icon = AddOfferButtonIcons[source];

        return (
          <Menu.Item
            className={styles.dropdownItem}
            disabled={source === OFFER_SOURCE.SHOPIFY && missingShopifyCredentials}
            key={source}
            icon={icon}
            onClick={onClickSource[source]}
            title={`Add ${label}`}
          >
            {label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
