import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { format } from 'date-fns';

import { Image, LazyImage } from '@components';
import { IMasonryItemProps } from '@components';

import { NetworkIcon } from '@components';
import { networkConfig } from '@components';

import { ISocialPost } from '@components';

import styles from './SocialPostTile.scss';

export interface ISocialPostTileProps extends IMasonryItemProps {
  socialPost: ISocialPost;
  avatarImage: string;
  name: string;

  classNames?: string[];
}
type TDefaultProp = 'onItemHeightChanged' | 'classNames';

/**
 * @class
 * @extends {React.Component}
 */
export class SocialPostTile extends React.PureComponent<ISocialPostTileProps> {
  public static defaultProps: Pick<ISocialPostTileProps, TDefaultProp> = {
    onItemHeightChanged: () => undefined,
    classNames: [],
  };

  public static defaultHeight = 500;

  private ref: React.RefObject<HTMLDivElement>;

  public height: number;

  constructor(props: ISocialPostTileProps) {
    super(props);

    this.ref = React.createRef();
    this.height = 300;
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      socialPost,
      name,
      avatarImage,
      classNames,
    } = this.props;

    const text = socialPost.title || socialPost.text || '';

    return (
      <div ref={this.ref} className={cx(classNames.concat(styles.SocialPostTile))}>
        <div>
          <div className={styles.media} onClick={this.openPostLink}>
            <Image
              className={styles.image}
              src={socialPost.image}
              onSizeDetected={this.onMediaDimensionDetected}
            />
            <div className={styles.text}>
              {text.length > 200 ? `${text.substr(0, 197)}...` : text}
            </div>
          </div>
          <div className={styles.details}>
            <Avatar image={avatarImage} socialPost={socialPost} />
            <UserInfo name={name} socialPost={socialPost} />
          </div>
          <Metrics socialPost={socialPost} />
        </div>
      </div>
    );
  }

  /**
   * @private
   * Callback for when image's natural size is detected.
   */
  private onMediaDimensionDetected = () => {
    const { onItemHeightChanged } = this.props;
    const node = this.ref.current;
    if (!node) {
      return;
    }

    this.height = node.getBoundingClientRect().height;

    onItemHeightChanged(this.height);
  };

  private openPostLink = () => {
    const { socialPost } = this.props;

    window.open(socialPost.link, '_blank');
  };
}

/**
 * @class
 * @extends {React.FunctionComponent}
 * The user avatar.
 */
const Avatar: React.FunctionComponent<{
  image: string;
  socialPost: ISocialPost;
}> = ({ image, socialPost }) => (
  <a
    className={styles.avatar}
    href={socialPost.link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <LazyImage className={styles.avatarImage} src={image} />
    {socialPost.network_identifier && (
      <div className={styles.source}>
        <NetworkIcon identifier={socialPost.network_identifier} />
      </div>
    )}
  </a>
);

/**
 * @class
 * @extends {React.FunctionComponent}
 * The user info section.
 */
const UserInfo: React.FunctionComponent<{
  name: string;
  socialPost: ISocialPost;
}> = ({ name, socialPost }) => (
  <div className={styles.info}>
    <div className={styles.name}>
      <a
        href={socialPost.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </a>
    </div>
    <div className={styles.date}>{format(socialPost.created_ts * 1000, 'MMM d, yyyy')}</div>
  </div>
);

/**
 * @class
 * @extends {React.FunctionComponent}
 * Social post reach.
 */
const Metrics: React.FunctionComponent<{
  socialPost: ISocialPost;
}> = ({ socialPost }) => {
  const contentName = networkConfig[socialPost.network_identifier].contentName;

  return (
    <div className={styles.metrics}>
      <a
        className={styles.reach}
        href={socialPost.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <NetworkIcon identifier={socialPost.network_identifier} />
        <div className={styles.amount}>
          {numeral(socialPost.reach)
            .format('0.[0]a')
            .toUpperCase()}
        </div>
      </a>
      {socialPost.engagement && (
        <div className={styles.engagement}>
          {numeral(socialPost.engagement)
            .format('0.[0]a')
            .toUpperCase()}
          <span className={styles.unit}>
            engs/
            {contentName}
          </span>
        </div>
      )}
    </div>
  );
};
