/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1d67m_157 {
  display: flex;
}

._justify-content-space-between_1d67m_161 {
  justify-content: space-between;
}

._tabular-nums_1d67m_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1d67m_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NameCell_1d67m_178 ._name_1d67m_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._NameCell_1d67m_178 {
  display: flex;
  flex-direction: row;
}
._NameCell_1d67m_178:hover ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192, ._NameCell_1d67m_178._selected_1d67m_192 ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192 {
  opacity: 1;
}
._NameCell_1d67m_178:hover ._avatarWrapper_1d67m_192 ._avatar_1d67m_192, ._NameCell_1d67m_178._selected_1d67m_192 ._avatarWrapper_1d67m_192 ._avatar_1d67m_192 {
  opacity: 0;
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 {
  position: relative;
  width: 2.75rem;
  min-width: 2.75rem;
  height: 2.75rem;
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192,
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._avatar_1d67m_192 {
  position: absolute;
  transition: opacity 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192 {
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  transition: background-color 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192:hover {
  background-color: #d5e1e9;
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192 .ant-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192 .ant-checkbox .ant-checkbox-inner {
  transform: scale(1.25);
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._checkbox_1d67m_192 .ant-checkbox::after {
  display: none;
}
._NameCell_1d67m_178 ._avatarWrapper_1d67m_192 ._avatar_1d67m_192 {
  top: 0.375rem;
  left: 0.375rem;
  z-index: 1;
  display: block;
  object-fit: cover;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  opacity: 1;
}
._NameCell_1d67m_178 ._name_1d67m_178 {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}