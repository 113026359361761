import * as React from 'react';
import {
  Space,
  Table,
  Typography,
  Button,
  Empty,
} from '@revfluence/fresh';
import cx from 'classnames';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TUseListTikTokSettingsAccountsQuery } from '@frontend/app/hooks/useListTikTokSettingsAccountsQuery';
import {
  TiktokIcon,
} from '@revfluence/fresh-icons/brands/esm';
import styles from './TikTokAccountsTable.scss';
import { ActiveTag } from './ActiveTag';

const { Title } = Typography;

type TikTokAccountTableData = TUseListTikTokSettingsAccountsQuery['data']['tiktokSettingsListAccounts']['results']['0'];

export interface ILoadingRows {
  [key: string]: boolean;
}

interface ITikTokAccountsTableProps {
  data: TikTokAccountTableData[],
  pagination: TablePaginationConfig,
  loadingRows: ILoadingRows,
  removeAccount: (accountId: string) => void,
}

export const TikTokAccountsTable: React.FC<ITikTokAccountsTableProps> = (props) => {
  const {
    data, removeAccount, loadingRows, pagination,
  } = props;

  const isEmpty = data.length === 0;

  const columns: ColumnsType<TikTokAccountTableData> = [
    {
      title: 'Account',
      dataIndex: 'username',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Listening',
      dataIndex: 'insights',
      key: 'insights',
      width: '60%',
      render: () => <ActiveTag />,
    },
    {
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            className={cx(styles.RemoveButton, {
              [styles.loading]: loadingRows[record.accountId],
            })}
            onClick={() => removeAccount(record.accountId)}
            loading={loadingRows[record.accountId]}
            disabled={loadingRows[record.accountId]}
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  if (isEmpty) {
    return (
      <>
        <Title level={5} className={styles.TableTitle}>Connected TikTok Accounts</Title>
        <Empty
          size="small"
          // TODO: Create a tiktok icon
          image={<TiktokIcon />}
          description={(
            <>
              <Typography.Title level={5}>
                No connected TikTok accounts.
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Connect TikTok accounts to leverage Listening.
              </Typography.Paragraph>
            </>
          )}
        />
      </>
    );
  }

  return (
    <>
      <Title level={5} className={styles.TableTitle}>Connected TikTok Accounts</Title>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          hideOnSinglePage: true,
          ...pagination,
        }}
        rowClassName={() => styles.RowTable}
      />
    </>
  );
};
