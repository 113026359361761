import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_IMPORTED_COLLECTIONS_STATUSES_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { GetImportedCollectionsStatusesQuery } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportedCollectionsStatusesQuery';
import { useMemo } from 'react';

type IOptions = QueryHookOptions<GetImportedCollectionsStatusesQuery>;

export const useGetCollectionStatuses = (options: IOptions = {}) => {
    const {
   loading, data, error, refetch, startPolling, stopPolling,
  } = useQuery(GET_IMPORTED_COLLECTIONS_STATUSES_QUERY, options);

  return {
    loading,
    statuses: useMemo(() => data?.getImportedCollections ?? [], [data?.getImportedCollections]),
    error,
    refetch,
    startPolling,
    stopPolling,
  };
};
