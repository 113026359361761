import gql from 'graphql-tag';

export const GET_ALL_CLIENT_CONNECTIONS = gql`
  query GetAllClientConnections {
    advertiser {
      getClientShopifyResources{
        clientId
        isConnected
        isPrimary
      }
    }
  }
`;
