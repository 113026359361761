import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_NO_CATALOGUE_PROJECT_CONFIG_QUERY } from '../queries';
import {
  GetNoCatalogueProjectConfigByProjectIdAndType,
  GetNoCatalogueProjectConfigByProjectIdAndTypeVariables,
  GetNoCatalogueProjectConfigByProjectIdAndType_projectConfig,
} from '../queries/types/GetNoCatalogueProjectConfigByProjectIdAndType';

type IOptions = QueryHookOptions<GetNoCatalogueProjectConfigByProjectIdAndType, GetNoCatalogueProjectConfigByProjectIdAndTypeVariables>;
export type IProjectConfig = GetNoCatalogueProjectConfigByProjectIdAndType_projectConfig;

export function useGetNoCatalogueProjectConfig(options: IOptions = {}) {
  const {
    loading,
    data: {
      projectConfig,
    } = {},
    error,
    refetch,
  } = useQuery<GetNoCatalogueProjectConfigByProjectIdAndType, GetNoCatalogueProjectConfigByProjectIdAndTypeVariables>(
    GET_NO_CATALOGUE_PROJECT_CONFIG_QUERY,
    {
      ...options,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    loading,
    projectConfig,
    error,
    refetch,
  };
}
