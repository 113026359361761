import * as React from 'react';
import { DatePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { DatePicker } from '@revfluence/fresh';

const { useCallback, useMemo } = React;

type IProps = Pick<DatePickerProps, 'format' | 'popupStyle' | 'className'> & {
  value?: Date;
  onChange?(value: Date);
  getPopupContainer?();
};

export const SimpleAntdDateField: React.FC<IProps> = (props) => {
  const {
    value,
    format,
    popupStyle,
    className,
    onChange,
    getPopupContainer,
  } = props;

  const momentValue = useMemo(() => {
    if (value) {
      return moment(value);
    }
    return null;
  }, [value]);

  const handleChange = useCallback((momentObject: moment.Moment) => {
    if (onChange) {
      const formattedDate = momentObject.toDate();
      onChange(formattedDate);
    }
  }, [onChange]);

  return (
    <DatePicker
      value={momentValue}
      format={format}
      popupStyle={popupStyle}
      getPopupContainer={getPopupContainer}
      className={className}
      onChange={handleChange}
    />
  );
};
