import * as React from 'react';
import cx from 'classnames';

import { useAuth } from '@frontend/context/authContext';

import {
  OnboardingStep,
  OnboardingDispatchActionType,
  IMarketplaceListingProps,
} from '../../types';
import ImageUpload from '../../components/ImageUpload';
import styles from '../styles.scss';

import marketplaceListingStyles from './styles.scss';

const { useCallback, useMemo } = React;

const MarketplaceListing = (props: IMarketplaceListingProps) => {
  const { state: { data }, dispatch } = props;
  const { clientInfo } = useAuth();

  const uploadProps = useMemo(() => ({
    serviceName: 'program',
    parentFolder: `onboarding/projects/${clientInfo.id}`,
    isTemp: false,
  }), [clientInfo]);

  const onFileSelected = useCallback((name: string, imageUrl: string) => {
    dispatch({
      type: OnboardingDispatchActionType.UpdateFieldValue,
      payload: {
        step: OnboardingStep.MarketplaceListing,
        field: name,
        value: imageUrl,
      },
    });
  }, [dispatch]);

  const onThumbnailSelected = useCallback((name: string, thumbnail: string) => {
    dispatch({
      type: OnboardingDispatchActionType.UpdateFieldValue,
      payload: {
        step: OnboardingStep.MarketplaceListing,
        field: name,
        value: thumbnail,
      },
    });
  }, [dispatch]);

  return (
    <div className={styles.StepContent}>
      <div className={styles.stepForm}>
        <p>All fields are required unless marked optional.</p>
        <ImageUpload
          key="logo"
          label="Logo"
          urlFieldNames={['logo_url']}
          thumbnailFieldNames={['logo_thumbnail']}
          thumbnails={[data?.logo_thumbnail]}
          showCropper
          onFileSelected={onFileSelected}
          onThumbnailSelected={onThumbnailSelected}
          uploadProps={uploadProps}
          hintText="The logo should be square or horizontal proportioned with 5:3"
        />
        <ImageUpload
          key="marketplace_image"
          label="Marketplace Image"
          dimensions={[1000, 1000]}
          urlFieldNames={['marketplace_image_url']}
          thumbnailFieldNames={['marketplace_image_thumbnail']}
          thumbnails={[data?.marketplace_image_thumbnail]}
          showCropper
          onFileSelected={onFileSelected}
          onThumbnailSelected={onThumbnailSelected}
          uploadProps={uploadProps}
          helpText="Use this image as your brand's thumbnail in our Creator Marketplace. This is your opportunity to stand out!"
          hintText="The recommended image size is 1000 x 1000 pixels"
        />
        <ImageUpload
          key="hero_image"
          label="Hero Image"
          dimensions={[1920, 806]}
          urlFieldNames={['hero_image_url']}
          thumbnailFieldNames={['hero_image_thumbnail']}
          thumbnails={[data?.hero_image_thumbnail]}
          showCropper
          onFileSelected={onFileSelected}
          onThumbnailSelected={onThumbnailSelected}
          uploadProps={uploadProps}
          helpText="This image will be the hero image of your application page and will span the full width of your application page. Use an eye-catching, high quality image to showcase your brand!"
          hintText="The recommended image size is 1920 x 806 pixels"
        />
        <ImageUpload
          key="brand_images"
          label="Brand Images"
          dimensions={[800, 1040]}
          urlFieldNames={[
            'brand_image_1_url',
            'brand_image_2_url',
            'brand_image_3_url',
          ]}
          thumbnailFieldNames={[
            'brand_image_1_thumbnail',
            'brand_image_2_thumbnail',
            'brand_image_3_thumbnail',
          ]}
          thumbnails={[
            data?.brand_image_1_thumbnail,
            data?.brand_image_2_thumbnail,
            data?.brand_image_3_thumbnail,
          ]}
          showCropper
          onFileSelected={onFileSelected}
          onThumbnailSelected={onThumbnailSelected}
          uploadProps={uploadProps}
          helpText="These images give creators more insight into the content you are looking for in this collaboration, what your project is about, and your brand as a whole! Just make sure you have the rights to use these images :)"
          hintText="The recommended image size for each image is 800 x 1040 pixels."
        />
      </div>
      <div className={cx(styles.stepInfo, marketplaceListingStyles.stepInfo)}>
        <h3 className={styles.title}>Aspire Creator Marketplace</h3>
        <div className={styles.section}>
          <h4>What is the Creator Marketplace?</h4>
          <br />
          It is a network of creators who have organically signed up to the Aspire
          platform with the intention of partening with brands like you.
        </div>
        <div className={styles.section}>
          <h4>Why share your campaign on the Marketplace?</h4>
          <br />
          Think of the marketplace as a way for creators to find you. Once you list your
          application page, you can leave it for a few days and expect to come back with lots of applications. So while you’re searching for creators outbound via our Search Engine, your application page is also being viewed by hundreds of thousands of creators looking to work with brands on the Aspire platform.
        </div>
        <div className={styles.section}>
          <h4>Examples of Live Marketplace Application Pages:</h4>
          <ul>
            <li><a target="_blank" href="https://mybeautybrand.aspireiq.com/join/Fall%20Collaboration">My Beauty Brand</a></li>
            <li><a target="_blank" href="https://outer.aspireiq.com/join/OuterInfluencerPartnerships">Outer</a></li>
            <li><a target="_blank" href="https://tula.aspireiq.com/join/TULA%20Brand%20Ambassador%20Program!">Tula</a></li>
          </ul>
        </div>
        <div className={styles.section}>
          <h4>Best practices to increase inbound interest from creators:</h4>
          <ul>
            <li><a target="_blank" href="https://intercom.help/aspireiq_elevate/en/articles/6020836-application-page-best-practices">How to get quality applicants</a></li>
            <li><a target="_blank" href="https://intercom.help/aspireiq_elevate/en/articles/5828756-tips-for-creating-an-eye-catching-application-page">How to create an eye-catching Application Page</a></li>
          </ul>
        </div>
        <div className={styles.section}>
          <h4>
            Image Requirements and Guidelines
            <br />
            .jpg and .png files are accepted
            <br />
            24MB file size maximum
          </h4>
        </div>
      </div>
    </div>
  );
};

const MarketplaceListingComponent = React.memo(MarketplaceListing);

MarketplaceListingComponent.displayName = 'MarketplaceListing';

export default MarketplaceListingComponent;
