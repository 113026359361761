import * as React from 'react';
import cx from 'classnames';

import { Select as AntdSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';

import styles from './Select.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISelectProps extends SelectProps<any> {}

export const Select: React.FC<ISelectProps> = (props) => (
  <AntdSelect
    {...props}
    className={cx(styles.Select, props.className)}
    dropdownClassName={cx(styles.SelectPopover, props.dropdownClassName)}
  />
  );
