import { useMemo } from 'react';
import {
 keyBy, map, camelCase, mapValues, filter,
} from 'lodash';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import { SocialAccountFields } from '@frontend/app/constants/socialAccountFields';
import {
  MemberFieldSchemasQuery_schemas as ISchema,
} from '@frontend/app/queries/types/MemberFieldSchemasQuery';

import { useMemberNames } from './useMemberNames';

interface IOptions {
  skip?: boolean;
}

interface IContactInfo {
  email?: string;
  phoneNumber?: string;
  address1?: string;
  instagram?: string;
  youTube?: string;
  twitter?: string;
  pinterest?: string;
  facebook?: string;
  blog?: string;
}

interface IContactInfoSchemas {
  phoneNumberSchema?: ISchema;
  address1Schema?: ISchema;
  instagramSchema?: ISchema;
  youTubeSchema?: ISchema;
  twitterSchema?: ISchema;
  pinterestSchema?: ISchema;
  facebookSchema?: ISchema;
  blogSchema?: ISchema;
}

interface IReturnType {
  values: {
    firstName?: string;
    lastName?: string;
  } & IContactInfo;
  schemas: {
    firstNameSchema?: ISchema;
    lastNameSchema?: ISchema;
  } & IContactInfoSchemas;
}

export const useMemberContactInfo = (member: MemberInput, options: IOptions = {}): IReturnType => {
  const {
    data: {
      schemas = null,
    } = {},
  } = useMemberFieldSchemasQuery({
    skip: !!options.skip,
  });

  const nameInfo = useMemberNames(member, options);

  const schemasByName = useMemo(() => keyBy(schemas, 'name'), [schemas]);

  const phoneNumberSchema = schemasByName.Phone;
  const address1Schema = schemasByName.Address1;
  const socialSchemas = useMemo(() => map(SocialAccountFields, ({ name }) => schemasByName[name]), [schemasByName]);

  const contactInfoSchemas = useMemo(() => filter([
      phoneNumberSchema,
      address1Schema,
      ...socialSchemas,
    ]), [phoneNumberSchema, address1Schema, socialSchemas]);

  const contactInfoSchemasById = useMemo(() => keyBy(contactInfoSchemas, (schema) => schema.id), [contactInfoSchemas]);

  const contactInfoSchemasByName = useMemo(() => keyBy(contactInfoSchemas, (schema) => `${camelCase(schema.name)}Schema`), [contactInfoSchemas]);

  const contactInfoByName = useMemo<IContactInfo>(() => mapValues(contactInfoSchemasById, (schema) => member.fields && schema && member.fields[schema.id]), [contactInfoSchemasById, member.fields]);

  return {
    values: {
      ...nameInfo.values,
      ...contactInfoByName,
      email: member.email,
    },
    schemas: {
      ...nameInfo.schemas,
      ...contactInfoSchemasByName,
    },
  };
};
