import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import {
  GET_AVAILABLE_BUSINESSES,
} from '@frontend/app/queries';

import {
  GetAvailableBusinesses,
} from '@frontend/app/queries/types/GetAvailableBusinesses';

type IOptions = QueryHookOptions<GetAvailableBusinesses>;

export const useGetAvailableBusinesses = (options: IOptions = {}) => (
  useQuery<GetAvailableBusinesses>(GET_AVAILABLE_BUSINESSES, options)
);

export type TUseGetAvailableBusinessesQuery = ReturnType<typeof useGetAvailableBusinesses>;
