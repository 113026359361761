/* eslint-disable max-len */
import {
  map,
  unset,
} from 'lodash';
import { addDays, format } from 'date-fns';

import {
  ContentGuidelinesWithDueDates,
  AgreementIteration,
  ContentUsageRightsType,
  DueDateType,
} from '@frontend/app/types/globalTypes';
import { IState as BasicTermsState } from '@frontend/app/containers/Projects/TermsPage/components/types/state';

import { IState } from '../components/BulkTerms/types/state';
import { IContentGuidelinesWithDueDates } from '../components/BulkTerms/types/ContentGuidelines';
import { TDateRequirement, DateOperator } from '../components/BulkTerms/types/ContentDateRequirements';

const mapDueDate = (date: TDateRequirement) => {
  if (!date) {
    return {
      type: DueDateType.BEFORE,
      startDate: '',
      endDate: '',
    };
  }
  switch (date.operator) {
    case DateOperator.ON:
      return {
        endDate: '',
        startDate: date.onDate.toLocaleDateString('en-US'),
        type: DueDateType.ON,
        dueDateInDays: date.dueDateInDays,
      };
    case DateOperator.BEFORE:
      return {
        endDate: '',
        startDate: date.beforeDate.toLocaleDateString('en-US'),
        type: DueDateType.BEFORE,
        dueDateInDays: date.dueDateInDays,
      };
    case DateOperator.BETWEEN:
      return {
        endDate: date.onDate.toLocaleDateString('en-US'),
        startDate: date.beforeDate.toLocaleDateString('en-US'),
        type: DueDateType.BETWEEN,
        dueDateInDays: date.dueDateInDays,
      };
    default:
      throw Error('bad value');
  }
};

const mapDueDates = (dates: { completeDate: TDateRequirement[], submissionDate: TDateRequirement[] }) => map(dates.completeDate, (date: TDateRequirement, index) => ({
  completion: mapDueDate(date),
  submission: mapDueDate(dates.submissionDate[index]),
}));

export const mapAgreementIteration = (stateVariables: IState): AgreementIteration => {
  const contentGuidelines: ContentGuidelinesWithDueDates[] = map(stateVariables.contentGuidelines, (guideline: IContentGuidelinesWithDueDates) => ({
    accountHandle: '',
    count: guideline.dueDates.completeDate.length,
    dueDates: mapDueDates(guideline.dueDates),
    requiresBrandedContentTag: !!guideline.requiresBrandedContentTag,
    guidelines: map(guideline.guidelines, (g) => {
      unset(g, '__typename');
      return g;
    }),
    label: guideline.label,
    id: guideline.id,
    postType: guideline.type,
    networkType: guideline.networkType,
    requiresContentReview: guideline.requiresReviewBeforeSubmission,
    attachments: map(guideline.attachments, (attachment) => ({
      filename: attachment.filename,
      url: attachment.url,
      type: attachment.type,
    })),
    acceptedPostTypes: [guideline.type],
  }));

  const mapAdsPermissions = ({ metaBamAccess, bcPromoteAccess }: IState['collaborationDetails']['adsPermissions']):
    Pick<AgreementIteration, 'metaBamAccessEndDate' | 'metaBamAccessStartDate' |
      'igBcPromoteAccessEndDate' | 'igBcPromoteAccessStartDate'> => ({
      metaBamAccessEndDate: metaBamAccess.endDate ? metaBamAccess.endDate.toLocaleDateString('en-US') : '',
      metaBamAccessStartDate: metaBamAccess.startDate ? metaBamAccess.startDate.toLocaleDateString('en-US') : '',
      igBcPromoteAccessStartDate: bcPromoteAccess.startDate ? bcPromoteAccess.startDate.toLocaleDateString('en-US') : '',
      igBcPromoteAccessEndDate: bcPromoteAccess.endDate ? bcPromoteAccess.endDate.toLocaleDateString('en-US') : '',
    });
  const adsPermissions = mapAdsPermissions(stateVariables.collaborationDetails.adsPermissions);
  const specialPaymentTerms = stateVariables.collaborationDetails.reward.payment.toggleSpecialPaymentTerms
    ? stateVariables.collaborationDetails.reward.payment.specialPaymentTerms : '';
  return {
    requireIgBcPromoteAccess: stateVariables.collaborationDetails.adsPermissions.bcPromoteAccess.toggle,
    igBcPromoteAccessEndDate: adsPermissions.igBcPromoteAccessEndDate,
    igBcPromoteAccessStartDate: adsPermissions.igBcPromoteAccessStartDate,
    requireMetaBamAccess: stateVariables.collaborationDetails.adsPermissions.metaBamAccess.toggle,
    metaBamAccessEndDate: adsPermissions.metaBamAccessEndDate,
    metaBamAccessStartDate: adsPermissions.metaBamAccessStartDate,
    requireIgPartnershipAdsAccess: stateVariables.collaborationDetails.adsPermissions.requireIgPartnershipAdsAccess,
    requireTiktokSparkAdsAccess: stateVariables.collaborationDetails.adsPermissions.tiktokSparkAdsAccess.toggle,
    tiktokSparkAdsDuration: stateVariables.collaborationDetails.adsPermissions.tiktokSparkAdsAccess.duration,
    paymentTerms: specialPaymentTerms,
    briefUrl: stateVariables.collaborationDetails.brief.briefUrl,
    commissionDescription: stateVariables.collaborationDetails.reward.commission.description,
    productDescription: stateVariables.collaborationDetails.reward.freeProduct.description,
    contentUsageRights: stateVariables.collaborationDetails.contentRight.text,
    disableCreatorEdits: stateVariables.collaborationDetails.disableCreatorEdits || false,
    offersPayment: stateVariables.collaborationDetails.reward.payment.showHideNewPrice && stateVariables.collaborationDetails.reward.payment.newPrice > 0,
    offersProduct: stateVariables.collaborationDetails.reward.freeProduct.showHideDescription,
    offersCommission: stateVariables.collaborationDetails.reward.commission.showHideDescription,
    price: stateVariables.collaborationDetails.reward.payment.newPrice || 0,
    requireContentRights: stateVariables.collaborationDetails.contentRight.type !== ContentUsageRightsType.NONE,
    requireInstagramInsights: stateVariables.collaborationDetails.bam.toggleInstagramInsights,
    contentGuidelines,
  };
};

export const mapBasicTermsToAgreementIteration = (state: BasicTermsState): AgreementIteration => {
  const currentDate = format(new Date(), 'MM/dd/yyyy');
  const contentGuideline = {
    accountHandle: '',
    guidelines: map(state.contentGuideline.guidelines, (g) => {
      unset(g, '__typename');
      return g;
    }),
    label: state.contentGuideline.label,
    id: state.contentGuideline.id,
    postType: state.contentGuideline.type,
    networkType: state.contentGuideline.networkType,
    requiresContentReview: false, // Todo: Product confirmation
    attachments: map(state.contentGuideline.attachments, (attachment) => ({
        filename: attachment.filename,
        url: attachment.url,
        type: attachment.type,
      })),
  };
  const contentGuidelines = state.contentRequirements.areDueDatesSelected
  ? [{
    ...contentGuideline,
    count: state.contentRequirements.deliverablesDueDates.length,
    dueDates: mapDueDates({
      completeDate: map(state.contentRequirements.deliverablesDueDates, (deliverableDueDate) => ({
        operator: DateOperator.ON,
        onDate: addDays(new Date(currentDate), deliverableDueDate),
        dueDateInDays: deliverableDueDate,
      })),
      submissionDate: [],
    }),
    acceptedPostTypes: state.contentRequirements.contentTypes,
    requiresBrandedContentTag: false,
    requiresContentReview: false,
  }]
  : [{
    ...contentGuideline,
    count: state.contentRequirements.deliverablesWithNoDueDates,
    dueDates: null,
    acceptedPostTypes: state.contentRequirements.contentTypes,
    requiresBrandedContentTag: false,
    requiresContentReview: false,
  }];
  return {
    paymentTerms: '',
    briefUrl: '',
    commissionDescription: state.compensation.commission.description,
    productDescription: state.compensation.freeProduct.description,
    contentUsageRights: state.contentRight.text,
    disableCreatorEdits: true,
    offersPayment: state.compensation.payment.toggleNewPrice && state.compensation.payment.newPrice > 0,
    offersProduct: state.compensation.freeProduct.toggleDescription,
    offersCommission: state.compensation.commission.toggleDescription,
    price: state.compensation.payment.newPrice || 0,
    requireContentRights: state.contentRight.type !== ContentUsageRightsType.NONE,
    requireInstagramInsights: false,
    contentGuidelines,
    requireIgBcPromoteAccess: false,
    requireIgPartnershipAdsAccess: false,
    requireMetaBamAccess: false,
    igBcPromoteAccessEndDate: '',
    igBcPromoteAccessStartDate: '',
    metaBamAccessEndDate: '',
    metaBamAccessStartDate: '',
    requireTiktokSparkAdsAccess: false,
    tiktokSparkAdsDuration: null,
  };
};
