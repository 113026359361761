import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { LIST_TIKTOK_SETTINGS_AD_ACCOUNTS } from '@frontend/app/queries';
import {
  ListTikTokSettingsAdAccountsQuery,
  ListTikTokSettingsAdAccountsQueryVariables,
} from '@frontend/app/queries/types/ListTikTokSettingsAdAccountsQuery';

type IOptions = QueryHookOptions<
  ListTikTokSettingsAdAccountsQuery,
  ListTikTokSettingsAdAccountsQueryVariables
>;

export const useListTikTokSettingsAdAccountsQuery = (options: IOptions = {}) => (
  useQuery<
    ListTikTokSettingsAdAccountsQuery,
    ListTikTokSettingsAdAccountsQueryVariables
  >(LIST_TIKTOK_SETTINGS_AD_ACCOUNTS, options)
);

export type TUseListTikTokSettingsAdAccountsQuery = ReturnType<typeof useListTikTokSettingsAdAccountsQuery>;
