import * as React from 'react';
import { TProject } from '@frontend/app/containers/Projects/types';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { CreateProjectApplicationStep } from './CreateProjectApplicationStep';
import { SubmitProjectToMarketplaceStep } from './SubmitProjectToMarketplaceStep';
import { DiscoverCreatorsStep } from './DiscoverCreatorsStep';

import styles from './Steps.scss';

interface IProps {
  project: TProject;
}

const StepsComponent: React.FC<IProps> = ({ project }) => {
  const isCreatorSearchHidden = useClientFeatureEnabled(ClientFeature.HIDE_CREATOR_SEARCH);

  return (
    <div className={styles.Steps}>
      <div className={styles.container}>
        <CreateProjectApplicationStep project={project} />
        <SubmitProjectToMarketplaceStep project={project} />
        {isCreatorSearchHidden === false && (
          <DiscoverCreatorsStep project={project} />
        )}
      </div>
    </div>
  );
};

const Steps = React.memo(StepsComponent);

Steps.displayName = 'FindCreatorsSteps';

export { Steps };
