import * as React from 'react';
import {
 Button, Col, Drawer, Row, Tooltip, Typography, notification,
} from '@revfluence/fresh';
import { ArrowLeftIcon, ChevronRightIcon, SquarePlusIcon } from '@revfluence/fresh-icons/regular';
import { useState } from 'react';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { CreateCustomDeepLink, CreateCustomDeepLinkVariables } from '@frontend/applications/AffiliatesApp/queries/types/CreateCustomDeepLink';
import { CREATE_CUSTOM_DEEP_LINK_MUTATION_BULK } from '@frontend/applications/AffiliatesApp/queries/createCustomDeepLink';
import { useMutation } from '@apollo/client';
import styles from './createAdditionalDeepLinks.scss';
import { OfferDetailCard } from '../../../AffiliatesApp/components/OfferDetailCard';
import ManageDeepLinkViews from './Sections/ManageDeepLinkViews';
import { TMemberTableRow } from '../../types';
import { validateDeepLink } from '../../../MembersWizard/utils/validateDeepLinkUrls';

interface IProps {
  className?: string;
  offer: GetOfferById_offer;
  isWorkFlow?: boolean;
  migrateToGraphQL?: boolean;
  selectedMembers?: readonly TMemberTableRow[];
  selectedAffiliateIds: number[];
  onComplete?: () => void;
}

export interface DeepLinkType {
  affiliateOfferLinkId: number;
  deepLinkLabel: string;
  deepLinkUrl: string;
  isBrandCreated: boolean;
}

const { Title } = Typography;

export const CreateAdditionalDeepLinks: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
 migrateToGraphQL, isWorkFlow, offer, selectedMembers, selectedAffiliateIds, onComplete,
} = props;
  const [open, setOpen] = useState(false);
  const [isBulkCreate, setBulkCreate] = useState(true);
  const [bulkDeepLink, setBulkDeepLink] = useState<DeepLinkType[]>([]);
  const [individualDeepLinks, setIndividualDeepLinks] = useState<DeepLinkType[]>([]);
  const selectedActiveMembers = selectedMembers?.filter((member) => member.linkStatus.toLowerCase() === 'active') ?? [];

  const [createCustomDeepLink, { loading }] = useMutation<
        CreateCustomDeepLink,
        CreateCustomDeepLinkVariables
    >(CREATE_CUSTOM_DEEP_LINK_MUTATION_BULK, {
        onCompleted: () => {
          onClose(); // Close the drawer
          notification.success({
            message: 'Success',
            description: 'It may take a few minutes for the links to be created. Please refresh the page to see the updated links.',
          });
          setBulkDeepLink([]);
          setIndividualDeepLinks([]);
          onComplete();
        },
        onError: (error) => {
          notification.error({
            message: 'Error',
            description: `Failed to create DeepLink: ${error.message}`,
          });
        },
    });

  // Validation function for both bulk and individual views
  const areDeepLinksValid = () => {
    if (isBulkCreate) {
      const bulkLink = bulkDeepLink[0];
      const bulkLabel = bulkLink?.deepLinkLabel.trim();
      const bulkUrl = bulkLink?.deepLinkUrl.trim();
      const validation = validateDeepLink(bulkUrl || '');
      return bulkLabel && bulkUrl && !validation.error;
    } else {
      if (individualDeepLinks.length === 0) {
        return false;
      }
      return individualDeepLinks.every((link) => {
        const labelValid = link.deepLinkLabel.trim();
        const urlValid = link.deepLinkUrl.trim() && !validateDeepLink(link.deepLinkUrl).error;
        return labelValid && urlValid;
      });
    }
  };

  // Tooltip and error message logic
  const isError = selectedActiveMembers.length === 0
    ? 'You have not selected any active member. Please select an active member to proceed.'
    : !areDeepLinksValid()
    ? isBulkCreate
      ? 'Please enter a valid label and deep link URL for bulk create.'
      : 'Please fill in all individual labels and valid deep link URLs.'
    : '';

  const handleCreateLink = () => {
    if (!areDeepLinksValid()) {
      return;
    }

    createCustomDeepLink({
      variables: {
        data: isBulkCreate ? bulkDeepLink : individualDeepLinks,
      },
    });
  };

  const handleAdditionalDeepLinkCreate = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        placement="top"
        title={!selectedAffiliateIds.length ? 'Select Members to Add Deep Links' : offer.archivedDate ? 'Cannot add links for Archived Offer' : 'Create Additional DeepLinks'}
      >
        <Button
          icon={<SquarePlusIcon />}
          type="text"
          onClick={handleAdditionalDeepLinkCreate}
          disabled={!selectedAffiliateIds.length || offer.archivedDate}
        >
          Create Additional Links
        </Button>
      </Tooltip>

      <Drawer
        title={(
          <Row justify="center" align="middle">
            <div className={styles.header}>
              <Button onClick={onClose} icon={<ArrowLeftIcon width={14} height={14} style={{ marginRight: 0 }} />} />
            </div>
            <div>
              <Title level={4}>Create Additional Links</Title>
            </div>
          </Row>
        )}
        placement="right"
        onClose={onClose}
        open={open}
        width={600}
        headerStyle={{ textAlign: 'center' }}
        closable={false}
        className={styles.CreateLinksDrawer}
        maskClosable={false}
        destroyOnClose
        footer={(
          <div>
            <Row gutter={24}>
              <Col span={12}>
                <Button block onClick={onClose}>
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Tooltip title={isError}>
                  <Button block type="primary" disabled={!!isError} loading={loading} onClick={handleCreateLink}>
                    Create Link
                    {' '}
                    <ChevronRightIcon />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </div>
        )}
      >
        <button className="ant-modal-close" onClick={onClose}>
          <span className="ant-modal-close-x">x</span>
        </button>
        <Row align="middle" justify="space-between" className={styles.linkDetails}>
          <OfferDetailCard
            showCta={false}
            migrateToGraphQL={migrateToGraphQL}
            isWorkFlow={isWorkFlow}
            offerData={offer}
            infoButtonClassName
          />
        </Row>
        <ManageDeepLinkViews
          isBulkCreate={isBulkCreate}
          setBulkCreate={setBulkCreate}
          selectedMembers={selectedActiveMembers}
          setBulkDeepLink={setBulkDeepLink}
          individualDeepLinks={individualDeepLinks}
          setIndividualDeepLinks={setIndividualDeepLinks}
          offerData={offer}
        />
      </Drawer>
    </>
  );
});

CreateAdditionalDeepLinks.defaultProps = {};
CreateAdditionalDeepLinks.displayName = 'CreateAdditionalDeepLinks';
