import * as React from 'react';
import cx from 'classnames';

import {
  InputGroup, Checkbox, Input, Button, SpinnerIcon,
} from '@components';

import { Typography } from '@revfluence/fresh';

const { Text } = Typography;

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useAddShopifyAccount } from '../hooks/useAddShopifyAccount';

import styles from './ShopifyAddNew.scss';
import { ShopifyConnectionError } from '../pages/types';

const { useState, useCallback } = React;

const errorSVG = require('@frontend/app/assets/svgs/error.svg');

interface IProps {
  className?: string;
  isEnabledMultipleShopify: boolean;
  onAddShopifyClient?: () => Promise<void>
  shoplessShopifyConnectionEnabled: boolean;
  shopifyConnectionError: ShopifyConnectionError | null;
  setShopifyConnectionError: (error: ShopifyConnectionError | null) => void;
}

export const ShopifyAddNew: React.FC<IProps> = React.memo((props) => {
  const addEvent = useEventContext();
  const [name, setName] = useState<string>(undefined);
  const [supportCreateOrder, setSupportCreateOrder] = useState(true);
  const [supportPromoCodes, setSupportPromoCodes] = useState(true);
  const [supportPromoCodeCustomerSegmentation, setSupportPromoCodeCustomerSegmentation] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const {
 isEnabledMultipleShopify, onAddShopifyClient, shopifyConnectionError, setShopifyConnectionError, shoplessShopifyConnectionEnabled,
    } = props;

  const addAccount = useAddShopifyAccount();

  const handleAddAccount = useCallback(async () => {
    addEvent(EventName.ResourceAuthStart, {
      type: 'shopify',
      shop: name,
      supportCreateOrder,
      supportPromoCodes,
      supportPromoCodeCustomerSegmentation,
    });
    setLoading(true);
    await addAccount({
      shop: name,
      supportCreateOrder,
      supportPromoCodes,
      supportPromoCodeCustomerSegmentation: supportPromoCodes && supportPromoCodeCustomerSegmentation,
      redirectionURL: window.location.href,
      shoplessEnabled: shoplessShopifyConnectionEnabled,
    });
    if (isEnabledMultipleShopify) {
      await onAddShopifyClient();
    }
  }, [addAccount, supportCreateOrder, supportPromoCodes, supportPromoCodeCustomerSegmentation, addEvent, isEnabledMultipleShopify, onAddShopifyClient, name, shoplessShopifyConnectionEnabled]);

  const handleTryAgain = useCallback(() => {
    setShopifyConnectionError(null);
  }, [setShopifyConnectionError]);

  if (shopifyConnectionError) {
    return (
      <div className={styles.error}>
        <img src={errorSVG} className={styles.errorIcon} alt="Error" />
        <Text className={styles.errorTitle}>
          {' '}
          Sorry, there is an issue in connecting
          {' '}
          {' '}
          {shopifyConnectionError?.userExternalDisplayId}
          .
        </Text>
        <Text className={styles.errorDescription}>
          Shopify store
          {' '}
          {shopifyConnectionError?.userExternalDisplayId}
          {' '}
          is already connected to another account in Aspire.
          If you think this is an error, please contact Aspire Support.
        </Text>
        <Text className={styles.errorDescription}>
          If you have access to multiple Shopify stores,
          please connect to the right account in Shopify
          and then try again to connect the correct Shopify store to Aspire.
        </Text>
        <Button
          label="Try again"
          theme="primary"
          onClick={handleTryAgain}
        />
      </div>
    );
  }

  return (
    <div className={cx(styles.ShopifyAddNew, props.className)}>
      <img src="https://storage.googleapis.com/aspirex-static-files/shopify.svg" className={styles.logo} />
      <div className={styles.title}>
        Connect Your Shopify Store
      </div>
      <div className={styles.description}>
        Sync and analyze Shopify data in Elevate to identify new and loyal customers for your communities.
      </div>
      {!shoplessShopifyConnectionEnabled && (
        <>
          <div className={styles.label}>
            Shopify Store URL
          </div>
          <InputGroup append={<span>.myshopify.com</span>} classNames={[styles.inputGroup]}>
            <Input placeholder="yourshopname" onChange={setName} value={name} />
          </InputGroup>
        </>
      )}
      <div className={styles.permissionsLabel}>Permissions</div>
      <Checkbox
        className={styles.checkbox}
        checked={supportCreateOrder}
        onChange={setSupportCreateOrder}
        label={(
          <span className={styles.checkboxContent}>
            <div className={styles.header}>
              Product Fulfillment
            </div>
            <div className={styles.body}>
              Allow Aspire to see products and create orders to automate and streamline product fulfillment.
            </div>
          </span>
        )}
      />
      <Checkbox
        className={styles.checkbox}
        checked={supportPromoCodes}
        onChange={setSupportPromoCodes}
        label={(
          <span className={styles.checkboxContent}>
            <div className={styles.header}>
              Shopify Promo Codes
            </div>
            <div className={styles.body}>
              Allow Aspire to create and manage Shopify Promo Codes, also
              known as Discount Codes.
            </div>
          </span>
        )}
      />
      <Checkbox
        className={styles.checkbox}
        disabled={!supportPromoCodes}
        checked={supportPromoCodes && supportPromoCodeCustomerSegmentation}
        onChange={setSupportPromoCodeCustomerSegmentation}
        label={(
          <span className={styles.checkboxContent}>
            <div className={styles.header}>
              Shopify Promo Code User Segmentation
            </div>
            <div className={styles.body}>
              Allow Aspire to create and manage Customer segments on Shopify, allowing you
              to limit Promo Codes (also known as Discount Codes) to specific customers.
              Requires "Shopify Promo Codes" permission.
            </div>
          </span>
        )}
      />
      <Button
        label={loading ? '' : 'Connect my store'}
        theme="primary"
        fullWidth
        onClick={handleAddAccount}
        disabled={!shoplessShopifyConnectionEnabled && (!name || loading)}
        icon={loading && <SpinnerIcon size={18} />}
      />
    </div>
  );
});

ShopifyAddNew.displayName = 'ShopifyAddNew';
