/**
 * Helper class for storing 404 images.
 * Avoids trying to load failed images more than once.
 */
class FailedImage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private failedImage: Record<string, any>;

  /**
   * @inheritdoc
   */
  constructor() {
    this.failedImage = {};
  }

  /**
   * @public
   * Returns if failed image map contains given url.
   *
   * @return {Boolean}
   */
  public contains = (url: string) => !!this.failedImage[url];

  /**
   * @public
   * Adds a url to failed image map.
   *
   * @param {String} url the url to add.
   */
  public add = (url: string) => {
    this.failedImage[url] = true;
  };
}

// singleton
export const failedImage = new FailedImage();
