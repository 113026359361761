import gql from 'graphql-tag';

import { WORKLET_FRAGMENT } from './fragments';

export const GET_ALL_WORKLETS_QUERY = gql`
  query GetAllWorkletsQuery($specKeys: [String!]) {
    worklets: getAllWorklets(specKeys: $specKeys) {
      ...WorkletFragment
    }
  }
  ${WORKLET_FRAGMENT}
`;
