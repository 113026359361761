import React, { useEffect, useState } from 'react';
import {
 Avatar, Button, Col, Input, Row, Space, Tooltip, Typography,
} from '@revfluence/fresh';
import {
 ArrowLeftIcon, CheckIcon, CircleInfoIcon, PencilIcon, XmarkIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useHistory } from 'react-router-dom';
import { CreateBrandCatalogInput } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { FetchResult } from '@apollo/client';
import { CreateBrandCatalogMutation } from '@frontend/applications/ProductFulfillmentApp/queries/types/CreateBrandCatalogMutation';
import { UpdateBrandCatalogMutation } from '@frontend/applications/ProductFulfillmentApp/queries/types/UpdateBrandCatalogMutation';
import { useCatalogDetailsContext } from './CatalogDetailsContext';
import styles from './Header.scss';

const { Title, Text } = Typography;

export interface IPageHeaderProps {
  handleSaveCatalog: (newValues: Partial<CreateBrandCatalogInput>) =>
    Promise<FetchResult<CreateBrandCatalogMutation>> | Promise<FetchResult<UpdateBrandCatalogMutation>>;
  image?: string;
  rightContent?: React.ReactNode;
  showBackButton?: boolean;
  backButtonUrl?: string;
  isDefaultCatalog?: boolean;
}

export const Header = ({
  handleSaveCatalog,
  image = null,
  rightContent = null,
  showBackButton = true,
  backButtonUrl = null,
  isDefaultCatalog = false,
}: IPageHeaderProps) => {
  const history = useHistory();

  const [isEditing, setIsEditing] = useState(false);

  const [localTitle, setLocalTitle] = useState('');
  const [localDescription, setLocalDescription] = useState('');

  const {
 title, description, setCatalog,
} = useCatalogDetailsContext();

  useEffect(() => {
    setLocalTitle(title);
    setLocalDescription(description);
  }, [title, description]);

  const handleLocalTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalTitle(e.target.value);
  };

  const handleLocalDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalDescription(e.target.value);
  };

  const handleSave = async () => {
    const response = await handleSaveCatalog({ name: localTitle.trim(), description: localDescription.trim() });
    if (response.data) {
      setCatalog({ title: localTitle, description: localDescription });
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setLocalTitle(title);
    setLocalDescription(description);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleBack = () => {
    if (backButtonUrl) {
      history.push(backButtonUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <Row align="middle" justify="space-between" className={styles.Header}>
      <Col>
        <Space size="middle">
          {showBackButton && <Button icon={<ArrowLeftIcon />} onClick={handleBack} />}
          {image && <Avatar src={image} shape="square" />}
          {isEditing ? (
            <Space direction="vertical" className={styles.inputContainer}>
              <Input placeholder="Name" value={localTitle} onChange={handleLocalTitleChange} />
              <Input.TextArea placeholder="Description" value={localDescription} onChange={handleLocalDescriptionChange} autoSize={{ maxRows: 3 }} />
            </Space>
          ) : (
            <Space direction="vertical" size={0}>
              <Title level={3}>{localTitle}</Title>
              <Space>
                <Text className={styles.subTitle}>{localDescription}</Text>
                <Tooltip title={'"Description" is internal and will not be displayed to creators'}>
                  <span className="text-grey">
                    <CircleInfoIcon fontSize={12} />
                  </span>
                </Tooltip>
              </Space>
            </Space>
          )}
          {isEditing ? (
            <Space>
              <Button
                type="text"
                icon={<CheckIcon />}
                onClick={handleSave}
                data-testid="saveBtn"
              />
              <Button
                type="text"
                icon={<XmarkIcon />}
                onClick={handleCancel}
                data-testid="cancelBtn"
              />
            </Space>
          ) : (!isDefaultCatalog
            && <Button type="text" data-testid="editBtn" icon={<PencilIcon className={styles.editIcon} />} onClick={handleEdit} className={styles.editBtn} />
          )}
        </Space>
      </Col>
      <Col>{rightContent}</Col>
    </Row>
  );
};
