import * as React from 'react';
import { Alert, Button } from '@revfluence/fresh';
import { EventName } from '@common';
import { backendServerWebEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useHomePageClientStatusQuery } from '@frontend/app/hooks';
import ShadCnAlert from '@frontend/app/refresh-components/Alert';

export const useInstagramStatus = (clientId: string, refreshUi?: boolean): React.ReactNode => {
  // configure the "Connect Instagram" onClick flow
  const addEvent = useEventContext();
  const oauthUrl = React.useMemo((): string => {
    const backend = backendServerWebEndpoint();
    const oauthEndpoint = `${backend}brands/${encodeURIComponent(clientId)}/facebook/aspirex_oauth`;
    const redirect = encodeURIComponent(window.location.href);
    const oauthScope = encodeURIComponent('aspirex_add_brand_account_mentions');
    return `${oauthEndpoint}?oauth_type=${oauthScope}&redirect=${redirect}`;
  }, [clientId]);
  const onClickConnectInstagram = React.useCallback(
    (e) => {
      e.preventDefault();
      addEvent(EventName.OAuthGrantStart, { app: 'home_page' });
      window.localStorage.removeItem('ig_connect_success_track');
      window.location.href = oauthUrl;
    },
    [addEvent, oauthUrl],
  );
  const statusQuery = useHomePageClientStatusQuery(false);
  const hasError = statusQuery.error;
  const isLoading = statusQuery.loading;
  const isInstagramConnected = !!statusQuery.data?.clientHomePageStatsStatus?.isInstagramConnected;
  return React.useMemo(() => {
    if (isLoading) {
      return null;
    }
    if (hasError) {
      return refreshUi ? (
        <ShadCnAlert
          title="Connection Issue"
          description="You need to re-connect your Instagram account to make sure your Impact Value data is up-to-date."
          onClick={onClickConnectInstagram}
          buttonText="Reconnect"
          closeable
        />
      ) : (
        <Alert
          message="Connection Issue"
          description="You need to re-connect your Instagram account to make sure your Impact Value data is up-to-date."
          type="error"
          showIcon
          style={{ width: '100%' }}
          action={(
            <Button type="primary" onClick={onClickConnectInstagram} danger>
              Re-connect
            </Button>
          )}
        />
      );
    }
    if (!isInstagramConnected) {
      return refreshUi ? (
        <ShadCnAlert
          title="Connect Instagram account(s)"
          description="See the impact of posts created for your projects and impact from organic posts that include tags or mentions of your brand."
          onClick={onClickConnectInstagram}
          buttonText="Connect"
          closeable
        />
      ) : (
        <Alert
          message="Connect Instagram account(s)"
          type="warning"
          description="See the impact of posts created for your projects and impact from organic posts that include tags or mentions of your brand."
          showIcon
          closable
          style={{ width: '100%' }}
          action={(
            <Button onClick={onClickConnectInstagram} type="primary">
              Connect
            </Button>
          )}
        />
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError, isInstagramConnected, onClickConnectInstagram]);
};
