import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_EMBEDDED_REPORT_BY_ID } from '@frontend/app/queries';
import {
  GetEmbeddedReportById,
  GetEmbeddedReportByIdVariables,
} from '@frontend/app/queries/types/GetEmbeddedReportById';

type IOptions = QueryHookOptions<GetEmbeddedReportById, GetEmbeddedReportByIdVariables>;

export function useGetEmbeddedReportById(id: number, options: IOptions = {}) {
  return useQuery<GetEmbeddedReportById>(
    GET_EMBEDDED_REPORT_BY_ID,
    {
      ...options,
      variables: {
        id,
      },
      skip: !id || options.skip,
      fetchPolicy: 'no-cache',
    },
  );
}
