import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { find, map, each } from 'lodash';
// import { schemeCategory10 } from 'd3-scale-chromatic';
import { TPostType } from '@components';
import colors from './TableColor';
import { ITMVData } from './TMVSection';

const { useMemo } = React;
import styles from './TableView.scss';

const displayNamesByNetwork = {
  pinterest: {
    favorites: 'saves',
  },
};

const displayNamesByKey = {
  reach: 'pot. reach',
};

const convertKey = (key: any, network: any) => {
  if (network in displayNamesByNetwork) {
    if (key in displayNamesByNetwork[network]) {
      return displayNamesByNetwork[network][key];
    }
  }

  if (key in displayNamesByKey) {
    return displayNamesByKey[key];
  }

  return key.split('_').join(' ');
};
interface IProps {
  data: ITMVData[];
  postType: TPostType;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const TableView: React.FunctionComponent<IProps> = React.memo((props) => {
  const { data, postType } = props;
  const post = useMemo(
    () => find(data, (record) => record.post_type === postType),
    [data, postType],
  );
  const typeToColor = useMemo(() => {
    const colorMap = {};

    each(Object.keys(post), (type, index) => {
      colorMap[type] = colors[index];
    });

    return colorMap;
  }, [post]);
  const attributes = post.insights;
  const totalValue = useMemo(() => {
    let total = 0;
    const attributes = post.insights;
    Object.keys(attributes).forEach((key) => {
      total += attributes[key].count * attributes[key].multiplier;
    });

    return total;
  }, [post]);

  return (
    <div className={cx(styles.TableView, props.className)}>
      <div className={styles.header}>
        <div className={styles.type} />
        <div className={styles.count}>Count</div>
        <div className={styles.mulSymbol} />
        <div className={styles.multiplier}>Multiplier</div>
        <div className={styles.equalSymbol} />
        <div className={styles.tmv}>TMV</div>
      </div>
      <div className={styles.body}>
        {[
          ...map(Object.keys(attributes), (key) => (
            <div key={key} className={styles.row}>
              <div className={styles.type}>
                <div
                  className={styles.dot}
                  style={{
                      backgroundColor: typeToColor[attributes[key].count],
                    }}
                />
                {convertKey(key, post.post_type)}
              </div>
              <div className={styles.count}>
                {numeral(attributes[key].count).format('0.[0]a')}
              </div>
              <div className={styles.mulSymbol}>x</div>
              <div className={styles.multiplier}>{attributes[key].multiplier}</div>
              <div className={styles.equalSymbol}>=</div>
              <div className={styles.tmv}>
                {numeral(attributes[key].count * attributes[key].multiplier).format('$0.[0]a')}
              </div>
            </div>
            )),
          <div
            key="tmv"
            className={styles.row}
            style={{
              fontWeight: 'bold',
            }}
          >
            <div className={styles.type}>
              <div
                className={styles.dot}
                style={{
                  backgroundColor: '#1A1818',
                }}
              />
              Total
            </div>
            <div className={styles.count} />
            <div className={styles.mulSymbol} />
            <div className={styles.multiplier} />
            <div className={styles.equalSymbol} />
            <div className={styles.tmv}>
              {numeral(totalValue).format('$0.[0]a')}
            </div>
          </div>,
        ]}
      </div>
    </div>
  );
});

TableView.defaultProps = {
  className: null,
};
