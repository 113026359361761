import * as React from 'react';

import { IIntroModal } from '@components';
import AspireIntroModal from './AspireIntroModal';
import BrandedIntroModal from './BrandedIntroModal';

interface IProps extends IIntroModal {
  brandName?: string;
  brandLogoSrc?: string;
  publisherName?: string;
}

export const CreatorImportIntroModal: React.FunctionComponent<IProps> = (props) => {
  if (props.brandName) {
    return (
      <BrandedIntroModal
        {...props}
        brandName={props.brandName}
        brandLogoSrc={props.brandLogoSrc}
        publisherName={props.publisherName}
      />
    );
  } else {
    return <AspireIntroModal {...props} />;
  }
};
