import { endpoints } from '@components';
import { ICommunity } from '@components';
import { useGet } from '@frontend/utils';

import { useInviteContext } from './useInviteContext';

/**
 * Fetch communities
 */
export const useCommunities = () => {
  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();

  const fetchCommunitiesURL = apiEndpoint && clientId
    ? `${apiEndpoint}/${endpoints.communitiesEndpoint}?client_id=${clientId}`
    : null;

  const {
    loading: isFetching,
    data: communities,
    error,
  } = useGet<ICommunity[]>({ url: fetchCommunitiesURL, cache: true });

  return {
    communities: Object.freeze(communities),
    isFetching,
    error,
  };
};
