import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/client';

import { message } from 'antd';

import { UPDATE_MANAGER_ROLE_MUTATION } from '@frontend/app/queries';
import {
  UpdateManagerRoleMutation,
  UpdateManagerRoleMutationVariables,
} from '@frontend/app/queries/types/UpdateManagerRoleMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<UpdateManagerRoleMutation, UpdateManagerRoleMutationVariables>;

export function useUpdateManagerRole(options: IOptions = {}) {
  const [updateManagerRole, { loading, error }] = useMutation<
    UpdateManagerRoleMutation,
    UpdateManagerRoleMutationVariables
  >(UPDATE_MANAGER_ROLE_MUTATION, {
    ...options,
    onCompleted(data) {
      if (options.onCompleted) {
        options.onCompleted(data);
      }

      message.info('Successfully updated user role');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    updateManagerRole,
    loading,
    error,
  };
}
