export * from './attachments';
export * from './attachments/AttachmentItem';

// if you only need the email message input
export * from './EmailEditor';

// if you need the whole email form
export * from './SendEmailForm';

// email editor in a form
// subject, to, attachments, etc
export * from './EmailComposer';

// wraps composer for a drawer
export * from './EmailComposerPage';

// email composer in a modal
export * from './EmailComposerModal';
