import React from 'react';
import { Statistic } from '@revfluence/fresh';
import { isNil } from 'lodash';

export interface IStatsRendererProps {
  title: string;
  total: number;
  outOff?: number;
}

export const StatsRenderer = ({
 title, total, outOff,
}: IStatsRendererProps) => (
  <Statistic value={total} title={title} {...(!isNil(outOff) && { suffix: `/ ${outOff}` })} />
);
