import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';
import { Dropdown } from 'antd';
import { IconButton } from '@components';
import { EllipsisIcon } from '@frontend/app/components';
import { ITagColor } from '@frontend/app/hooks';
import styles from './TokenCell.scss';

interface IProps {
  values: string[];
  colorMap: Map<string, ITagColor>;
  className?: string;
}

export const TokenCell: React.FC<IProps> = React.memo((props) => {
  const { values, colorMap } = props;

  const [firstValue, ...remainingValues] = values;
  const remainingValueList = (
    <div className={styles.all}>
      {map(remainingValues, (value, idx) => (
        <div
          key={idx}
          className={styles.value}
          style={{
            color: colorMap.get(value)?.color || undefined,
            backgroundColor: colorMap.get(value)?.backgroundColor || undefined,
          }}
        >
          {value}
        </div>
      ))}
    </div>
  );

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (isEmpty(values)) {
    return null;
  }

  return (
    <>
      <div className={cx(styles.TokenCell, props.className)}>
        <div
          className={styles.value}
          style={{
            color: colorMap.get(firstValue)?.color || undefined,
            backgroundColor: colorMap.get(firstValue)?.backgroundColor || undefined,
          }}
        >
          {firstValue}
        </div>
        {!isEmpty(remainingValues) && (
          <div className={styles.right} onMouseDown={handleMouseDown}>
            <Dropdown overlay={remainingValueList} placement="bottomRight">
              <IconButton
                icon={<EllipsisIcon size={12} />}
                className={styles.btn}
              />
            </Dropdown>
          </div>
        )}
      </div>
    </>
  );
});

TokenCell.displayName = 'TokenCell';
