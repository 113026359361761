import * as React from 'react';
import cx from 'classnames';

import { emojiDictionary } from './emojiDictionary';

import styles from './Emoji.scss';

interface IEmojiProps {
  className?: string;
  /**
   * Emoji name: refer to ./emojiDictionary.ts
   */
  name: string;
  /**
   * Emoji font size
   */
  size?: number;
}

export const Emoji: React.FunctionComponent<IEmojiProps> = (props) => {
  const {
    className,
    name,
    size,
  } = props;

  return name in emojiDictionary
    ? (
      <span
        className={cx(styles.Emoji, className)}
        style={{
          height: size ? `${size}px` : undefined,
        }}
      >
        <i
          className={cx(styles.character, styles[name])}
          style={{
            fontSize: size ? `${size}px` : undefined,
          }}
        >
          {emojiDictionary[name]}
        </i>
      </span>
    )
    : null;
};

Emoji.defaultProps = {
  size: 16,
};
Emoji.displayName = 'Emoji';
