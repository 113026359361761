import gql from 'graphql-tag';

export const TIKTOK_SETTINGS_ACCOUNT_FRAGMENT = gql`
  fragment TikTokSettingsAccountFragment on TikTokSettingsAccount {
    accountId
    networkId
    username
    socialListening {
      enabled
    }
  }
`;

export const TIKTOK_SETTINGS_AD_ACCOUNT_FRAGMENT = gql`
  fragment TikTokSettingsAdAccountFragment on TikTokSettingsAdAccount {
    accountId
    networkId
    username
    sparkAds {
      enabled
    }
  }
`;
