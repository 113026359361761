import { GetBudgetDistributionsForBudgetAccountQuery_budgetDistributionsForBudgetAccount } from '@frontend/app/queries/types/GetBudgetDistributionsForBudgetAccountQuery';
import { ActionType, TState } from '../types';

interface IAction {
    budgetDistributions: GetBudgetDistributionsForBudgetAccountQuery_budgetDistributionsForBudgetAccount[];
    type: ActionType.SET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT;
}
export type TSetBudgetDistributions = Readonly<IAction>;

export const setBudgetDistributionsForBudgetAccount = (state: TState, action: TSetBudgetDistributions): TState => ({
        ...state,
        budgetDistributionsForBudgetAccount: action.budgetDistributions,
    });
