import * as React from 'react';
import { CollapsePanelProps } from 'antd';
import {
  Space, Typography, Collapse, Spinner,
} from '@revfluence/fresh';

import styles from './Section.scss';

const { useState, useCallback } = React;
const { Link } = Typography;
const { Panel } = Collapse;

type RenderProps = {
  viewMore: boolean;
  setViewMoreVisibility: (visible: boolean) => void;
  setIsSectionEmpty: (empty: boolean) => void;
};

type Props = CollapsePanelProps & {
  expandable?: boolean;
  loading: boolean;
  render: (props: RenderProps) => React.ReactNode;
};

const Section: React.FC<Props> = ({
  render,
  loading,
  expandable = false,
  ...otherProps
}) => {
  const [viewMore, setViewMore] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isSectionEmpty, setIsSectionEmpty] = useState(false);

  const setSectionEmpty = useCallback((empty: boolean) => {
    setIsSectionEmpty(empty);
    if (isSectionEmpty && !empty) {
      setViewMore(true);
    }
  }, [isSectionEmpty, setIsSectionEmpty, setViewMore]);

  return (
    <Panel className={styles.section} {...otherProps}>
      <Space direction="vertical">
        {loading ? <Spinner /> : render({
          viewMore: viewMore || isSectionEmpty,
          setViewMoreVisibility: setVisible,
          setIsSectionEmpty: setSectionEmpty,
        })}
        {expandable && visible && (
          viewMore ? (
            <Link onClick={() => setViewMore(false)}>View Less</Link>
          ) : (
            <Link onClick={() => setViewMore(true)}>View More</Link>
          )
        )}
      </Space>
    </Panel>
  );
};

export default Section;
