import * as React from 'react';
import {
  isFunction,
  map,
  pick,
  isUndefined,
  trim,
} from 'lodash';
import omitDeep from 'omit-deep-lodash';

import { Button } from '@components';
import { EventName, logger } from '@common';
import { LoadSpinner } from '@frontend/app/components';
import { ProgramInput } from '@frontend/app/types/globalTypes';
import { TProject } from '@frontend/app/containers/Projects/types';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import {
  useSaveProgramMutation,
} from '@frontend/app/containers/Communities/AddOrEditCommunity/hooks/useSaveProgramMutation';
import {
  ProgramOnboarding,
} from '@frontend/app/containers/Communities/AddOrEditCommunity/Programs/ProgramOnboarding';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useMessagingContext } from '@frontend/hooks';
import {
  useConfirmOnExit,
  useSaveProjectCampaign,
} from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';

import { useLocation } from 'react-router-dom';
import { LandingPages as LandingPagesV2 } from '../LandingPages/LandingPages';
import { useDefaultProjectInput } from '../LandingPages/hooks';

import styles from './LandingPages.scss';
import { onOpenTemplate } from '../utils';
import { useGetProjectLandingPageUrl } from '../../Onboarding/OnboardingWizard/hooks/useGetProjectLandingPageUrl';
import { UtmSource } from '../hooks';

const {
  useCallback,
  useEffect,
  useState,
  useMemo,
} = React;

interface IProps {
  onSave: () => void;
  onDeleteApplicationPage: () => void;
  project: TProject;
}

export const LandingPages: React.FC<IProps> = ({
  onSave,
  project,
  onDeleteApplicationPage,
}) => {
  const {
    showErrorMessage,
    showSuccessMessage,
  } = useMessagingContext();
  const [saveProjectCampaign] = useSaveProjectCampaign();

  const location = useLocation<Pick<ProgramInput, 'applicationFormFields' | 'onboardingTemplateConfig' | 'applicationPageTemplateName'>>();

  const showLandingPageV2 = project?.legacyLandingPage === false;

  const {
    clientInfo,
  } = useAuth();

  /**
   * Save project
   */
  const [
    saveProgram,
    { loading: isSavingProject },
  ] = useSaveProgramMutation(undefined);

  const [contentHasChanged, setContentHasChanged] = useState(false);
  const [projectInput, setProjectInput] = useState<ProgramInput>();

  const defaultProjectInput = useDefaultProjectInput(project);

  const { getFullPageUrl } = useGetProjectLandingPageUrl();

  const templateUrl = useMemo(() => (
    isFunction(getFullPageUrl) && getFullPageUrl(projectInput?.customLandingPagePath, '')
  ), [getFullPageUrl, projectInput?.customLandingPagePath]);

  useEffect(
    () => {
      setContentHasChanged(false);
      if (!projectInput) {
        setProjectInput({
          ...defaultProjectInput,
          applicationPageTemplateName: location.state?.applicationPageTemplateName || defaultProjectInput.applicationPageTemplateName,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultProjectInput, location.state],
  );

  useEffect(() => {
    if (
      location.state?.onboardingTemplateConfig
        && location.state?.applicationFormFields
    ) {
      setProjectInput((currentState) => ({
          ...currentState,
          applicationFormFields: location.state.applicationFormFields,
          applicationPageTemplateName: location.state.applicationPageTemplateName || null,
          onboardingTemplateConfig: location.state.onboardingTemplateConfig,
        }));
    }
  }, [location.state]);

  /**
   * Show confirmation dialog on exit
   */

  const showConfirm = useMemo(() => (
    showLandingPageV2 && contentHasChanged
  ), [showLandingPageV2, contentHasChanged]);

  useConfirmOnExit({
    show: showConfirm,
  });

  /**
   * Handlers
   */
  const handleChangeProjectInput = useCallback(
    (input: ProgramInput) => {
      // Restrict the fields we can modify
      setProjectInput({
        ...input,
        ...pick<ProgramInput>(input, 'customLandingPagePath', 'columns', 'applicationFormFields', 'applicationPageTemplateName'),
      });
      setContentHasChanged(true);
    },
    [setContentHasChanged, setProjectInput],
  );

  const openTemplate = () => {
    onOpenTemplate(templateUrl);
  };

  const addEvent = useEventContext();

  const handleSave = () => {
    if (!project) {
      logger.warn('No project to save');
      return;
    }

    const previouslyPublished = project.published;

    const columns: ProgramInput['columns'] = {
      dbColumns: map(projectInput.applicationFormFields.dbColumns, (col) => col.name),
      memberFieldSchemaIds: map(projectInput.applicationFormFields.memberFieldSchemas, (field) => field.schemaId),
    };
    const applicationFormFields: ProgramInput['applicationFormFields'] = {
      memberFieldSchemas: map(projectInput.applicationFormFields?.memberFieldSchemas, (schema) => (
        pick(schema, 'schemaId', 'label', 'required', 'order')
      )),
      dbColumns: map(projectInput.applicationFormFields?.dbColumns, (col) => (
        pick(col, 'name', 'label', 'required', 'order')
      )),
    };

    const onboardingTemplateConfig = {
      ...(projectInput?.onboardingTemplateConfig || {}),
      perks: {
        ...(projectInput?.onboardingTemplateConfig?.perks || {}),
        items: projectInput?.onboardingTemplateConfig?.perks?.items
          ? projectInput.onboardingTemplateConfig.perks.items.map((p) => (isUndefined(p) ? 'What are awesome things your community can expect from joining?' : p))
          : undefined,
      },
      persona: {
        ...(projectInput?.onboardingTemplateConfig?.persona || {}),
        features: (projectInput?.onboardingTemplateConfig?.persona?.features)
          ? projectInput.onboardingTemplateConfig.persona.features.map((p) => (isUndefined(p) ? 'List out characteristics you are looking for' : p))
          : undefined,
      },
    };

    return saveProgram({
      variables: {
        // Only update 'columns' and 'applicationFormFields'; 'id','title' and 'hasUnpaidOffer' are required
        program: {
          ...pick(project, 'id', 'title', 'customLandingPagePath', 'hasUnpaidOffer'),
          onboardingTemplateConfig: omitDeep(onboardingTemplateConfig, '__typename'),
          columns,
          applicationPageTemplateName: projectInput.applicationPageTemplateName || project.applicationPageTemplateName,
          applicationFormFields,
          published: true,
        },
      },
    })
    .then((project) => {
      showSuccessMessage('Updated application page settings');
      if (isFunction(onSave)) {
        onSave();
      }

      if (showLandingPageV2) {
        openTemplate();
      }
      if (!previouslyPublished) {
        addEvent(
          EventName.LandingPagePublish, {
            project_name: project.data?.program?.title,
            project_id: project.data?.program?.id,
            template_resource: location.state ? 'duplicated' : 'new',
          },
        );
      }

      setProjectInput((previousInput) => ({
        ...previousInput,
        published: true,
      }));

      setContentHasChanged(false);

      return project;
    })
    .catch((error) => {
      logger.error(error);
      const msg = getErrorMessageFromGraphQL(error);
      showErrorMessage(msg);
    });
  };

  const handleSaveCustomLandingPagePath = async (customLandingPagePath: string) => {
    if (!project) {
      logger.warn('No project to save');
      return;
    }

    try {
      customLandingPagePath = trim(customLandingPagePath);
      const updatedProject = await saveProgram({
        variables: {
          program: {
            ...pick(project, 'id', 'title', 'hasUnpaidOffer'),
            customLandingPagePath,
          },
        },
      });

      const marketplaceListingUrl = getFullPageUrl(customLandingPagePath, UtmSource.Marketplace);
      await saveProjectCampaign({
        variables: {
          projectId: project.id,
          campaign: {
            external_listing_url: marketplaceListingUrl,
          },
        },
      });

      showSuccessMessage('Updated application page URL');
      if (isFunction(onSave)) {
        onSave();
      }

      // Prevent legacy issues
      // Preserve state, change path
      if (showLandingPageV2) {
        setProjectInput({
          ...projectInput,
          customLandingPagePath: updatedProject?.data?.program?.customLandingPagePath,
        });
      }

      return updatedProject;
    } catch (error) {
      console.error(error);
      const msg = getErrorMessageFromGraphQL(error);
      showErrorMessage(msg);
    }
  };

  const handleChangeTemplateData = (templateData) => {
    setContentHasChanged(true);
    setProjectInput({
      ...projectInput,
      onboardingTemplateConfig: templateData,
    });
  };

  /**
   * Legacy landing page
   */
  const legacyLandingPageElem = (
    <div className={styles.legacyLandingPage}>
      <ProgramOnboarding
        onChange={handleChangeProjectInput}
        onCustomLandingPagePathSave={handleSaveCustomLandingPagePath}
        onSubmit={handleSave}
        programInput={projectInput}
      />
      <Button
        className={styles.saveButton}
        disabled={isSavingProject || !contentHasChanged}
        label="Save"
        onClick={handleSave}
      />
    </div>
  );

  const renderLandingPage = () => (
    showLandingPageV2
      ? (
        <LandingPagesV2
          projectId={projectInput?.id}
          templateData={projectInput?.onboardingTemplateConfig}
          applicationFormFields={projectInput?.applicationFormFields}
          programInput={projectInput}
          onChange={handleChangeProjectInput}
          onChangeCustomPath={handleSaveCustomLandingPagePath}
          onChangeTemplateData={handleChangeTemplateData}
          onSubmit={handleSave}
          onDeleteApplicationPage={onDeleteApplicationPage}
          clientId={clientInfo?.id}
        />
      )
      : legacyLandingPageElem
  );

  const isLoading = (
    !project
    || showLandingPageV2 === undefined
  );

  return (
    <div className={styles.LandingPages}>
      {isLoading
        ? <LoadSpinner />
        : renderLandingPage()}
    </div>
  );
};
