import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { each, isNumber } from 'lodash';

import { TAGS_QUERY } from '@frontend/app/queries';
import {
  TagsQuery,
  TagsQuery_tags,
} from '@frontend/app/queries/types/TagsQuery';

export type ITag = TagsQuery_tags;
type IOptions = QueryHookOptions<TagsQuery>;

const { useMemo } = React;

const PRESET_TEXT_COLOR = '#1f1f21'; // black
const CUSTOM_TEXT_COLOR = '#ffffff'; // white

export const tagColors = [
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#fdf', // magenta
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#f5b3b4', // red
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#f1e5ac', // gold
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#c1e1c1', // sage
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#c9f2f2', // cyan
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#fabf95', // orange
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#70d1f1', // blue
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#a2aff1', // geekblue
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#c6b0ca', // mauve
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#ebffd0', // lime
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#ff9c93', // volcano
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#ff8a8a', // coral
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#f5a287', // apricot
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#fdaf1c', // marigold
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#f9eda1', // butter
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#b7d275', // apple
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#5fcdca', // tiffany
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#c5b3e3', // purple
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#a3ddf9', // baby
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#7eb3d8', // carolina
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#ffb4c7', // rose
  },
  {
    color: PRESET_TEXT_COLOR,
    backgroundColor: '#ffa1b6', // watermelon
  },
  {
    color: CUSTOM_TEXT_COLOR,
    backgroundColor: '#ff5501', // custom red
  },
  {
    color: CUSTOM_TEXT_COLOR,
    backgroundColor: '#2db7f5', // custom blue
  },
  {
    color: CUSTOM_TEXT_COLOR,
    backgroundColor: '#87d068', // custom green
  },
  {
    color: CUSTOM_TEXT_COLOR,
    backgroundColor: '#108ee9', // custom dark blue
  },
];

export interface ITagColor {
  color?: string;
  backgroundColor?: string;
}

export const useGetTags = (options: IOptions = {}) => {
  const {
    loading, refetch, error,
    data: { tags = null } = {},
  } = useQuery<TagsQuery>(TAGS_QUERY, options);

  const tagColorMap: Map<string, ITagColor> = useMemo(() => {
    const map = new Map();

    each(tags, (a, index) => map.set(
      a.name, tagColors[(isNumber(a.id) ? a.id : index) % tagColors.length],
    ));

    return map;
  }, [tags]);

  return {
    loading,
    tags,
    tagColorMap,
    error,
    refetch,
  };
};
