import { Modal } from '@revfluence/fresh';
import {
  filter,
  first,
  isEmpty,
  map,
  size,
} from 'lodash';
import * as React from 'react';

import { EventName } from '@common';
import {
  EllipsisLabel,
  FormActions,
  SelectList,
} from '@frontend/app/components';
import {
  useEventContext,
  useResourceContext,
} from '@frontend/app/context';
import { useAuth } from '@frontend/context/authContext';
import {
  useCommunitiesQuery,
  useFeatureFlagVerbiage,
  useFuzzySearchByKeys,
  useInviteMembersToProgramsMutation,
  useModifyCommunityMembersMutation,
  useModifyProgramMembersMutation,
} from '@frontend/app/hooks';
import {
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';
import { useMessagingContext } from '@frontend/hooks';
import {
  CloseIcon,
  IconButton,
  LazyImage,
} from '@components';
import { TProject } from '../../types';

import styles from './AddMembersToCollectionModal.scss';

const {
  useCallback,
  useEffect,
  useMemo,
  useState,
} = React;

export enum ModalType {
  AddFromProject = 'addFromProject',
  AddFromGroup = 'addFromGroup',
  InviteFromProject = 'inviteFromProject',
  InviteFromGroup = 'inviteFromGroup',
  AddToGroup = 'addToGroup',
  AddToAnotherProject = 'addToAnotherProject',
  RemoveFromProject = 'removeFromProject',
}

const SEARCH_KEYS = ['title'];

interface IProps {
  loading: boolean;
  modalType: ModalType;
  onCloseModal: () => void;
  project: Pick<TProject, 'id'>;
  projects: Pick<TProject, 'id'>[];
  selectedMemberIds: number[];
  showModal: boolean;
}

interface IModalState {
  emptyMessage: string;
  handleSave(selectedIds: number[]): void;
  sources: Pick<TProject, 'id'>[] | ICommunity[];
  subtitle: string;
  title: string;
  searchPlaceholder: string;
}

export const AddMembersToCollectionModal: React.FC<IProps> = (props) => {
  const {
    loading,
    modalType,
    onCloseModal,
    project,
    projects,
    selectedMemberIds,
    showModal,
  } = props;
  const verbiage = useFeatureFlagVerbiage();
  const {
    showMessage,
    showError,
  } = useMessagingContext();
  const addEvent = useEventContext();
  const [clearSelection, setClearSelection] = useState<boolean>(false);
  const [modalState, setModalState] = useState<IModalState>();
  const [selectedIds, setSelectedIds] = useState<number[]>();
  const handleSelectListChange = useCallback(
    (ids: string[]) => {
      setSelectedIds(map(ids, (id) => parseInt(id, 10)));
    },
    [setSelectedIds],
  );

  const closeModal = useCallback(() => {
    setClearSelection(true);
    onCloseModal();
  }, [setClearSelection, onCloseModal]);

  const resetClearSelection = useCallback(() => {
    setClearSelection(false);
  }, [setClearSelection]);

  useEffect(() => {
    if (!showModal) {
      setSelectedIds([]);
    }
  }, [showModal]);

  const { user, clientInfo } = useAuth();
  const { activeEmailResources: resources } = useResourceContext();
  const firstResource = first(resources);

  const mapOptionToLabel = useCallback(
    (projectOption) => (
      <div className={styles.label}>
        <LazyImage
          className={styles.labelImage}
          src={projectOption.splashImageUrl}
        />
        <div className={styles.labelTitle}>
          <EllipsisLabel tooltipPlacement="right">
            {projectOption.title}
          </EllipsisLabel>
        </div>
      </div>
    ),
    [],
  );

  const {
    loading: loadingCommunities,
    data: {
      communities = undefined,
    } = {},
  } = useCommunitiesQuery();

  // Add to program
  const [
    addMembersToPrograms,
    { loading: addingMembers },
  ] = useModifyProgramMembersMutation('add', {
    onCompleted: () => {
      showMessage({
        type: 'success',
        content: `${size(selectedMemberIds) > 1 ? `${size(selectedMemberIds)} Members are` : `${size(selectedMemberIds)} Member is`} being added to your ${verbiage.program}, feel free to continue working`,
      });
      closeModal();
    },
    onError: (error) => {
      showError(error);
    },
  });

  // Invite to program
  const {
    inviteMembersToPrograms,
    loading: isInviting,
  } = useInviteMembersToProgramsMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        content: `Members invited to ${verbiage.program}`,
      });
    },
    onError: (error) => {
      showError(error);
    },
  });

  // Add to community
  const [
    addMembersToCommunity,
    { loading: addingMembersToCommunity },
  ] = useModifyCommunityMembersMutation('add', {
    onCompleted: () => {
      showMessage({
        type: 'success',
        content: `Members added to ${verbiage.communities}`,
      });
    },
    onError: (error) => {
      showError(error);
    },
  });

  useEffect(() => {
    switch (modalType) {
      case ModalType.AddFromProject:
        setModalState({
          title: `Add People from ${verbiage.Program}`,
          subtitle: `Add people to this ${verbiage.Program} from other ${verbiage.Programs}`,
          emptyMessage: `There are no available ${verbiage.programs} to add from`,
          sources: filter(projects, (p) => p.id !== project?.id),
          searchPlaceholder: `Search ${verbiage.programs}...`,
          handleSave: async (saveSelectedIds) => {
            await addMembersToPrograms({
              variables: {
                query: { programIds: saveSelectedIds },
                memberIds: selectedMemberIds,
                programIds: [project.id],
                status: 'approved',
                clientId: clientInfo.id,
              },
            });
            addEvent(
              EventName.AddMemberToProject,
              { count: selectedMemberIds.length },
            );
            closeModal();
          },
        });
        break;
      case ModalType.AddFromGroup:
        setModalState({
          title: `Add People from ${verbiage.Community}`,
          subtitle: `Add people to this ${verbiage.Program} from ${verbiage.Communities}`,
          emptyMessage: `There are no available ${verbiage.Communities} to add from`,
          sources: communities,
          searchPlaceholder: `Search ${verbiage.communities}...`,
          handleSave: async (saveSelectedIds) => {
            await addMembersToPrograms({
              variables: {
                query: { communityIds: saveSelectedIds },
                memberIds: selectedMemberIds,
                programIds: [project.id],
                status: 'approved',
              },
            });
            addEvent(
              EventName.AddMemberToProject,
              { count: selectedMemberIds.length },
            );
            closeModal();
          },
        });
        break;
      case ModalType.InviteFromGroup:
        setModalState({
          title: `Invite People from ${verbiage.Community}`,
          subtitle: `Invite people to this ${verbiage.Program} from ${verbiage.Communities}`,
          emptyMessage: `There are no available ${verbiage.Communities} to invite from`,
          sources: communities,
          searchPlaceholder: `Search ${verbiage.communities}...`,
          handleSave: async (saveSelectedIds) => {
            await inviteMembersToPrograms({
              variables: {
                memberIds: selectedMemberIds,
                programIds: [project.id],
                userId: user.sub,
                resourceId: firstResource?.id,
                query: { communityIds: saveSelectedIds },
                source: 'programs_list',
              },
            });
            closeModal();
          },
        });
        break;
      case ModalType.InviteFromProject:
        // setModalState({
        //   title: `Invite People from ${verbiage.Program}`,
        //   subtitle: `Invite people to this ${verbiage.Program} from ${verbiage.Programs}`,
        //   emptyMessage: `There are no available ${verbiage.Programs} to invite from`,
        //   sources: filter(projects, p => p.id !== project?.id),
        //   handleSave: async (selectedIds) => {
        //     await inviteMembersToPrograms({
        //       variables: {
        //         memberIds: selectedMemberIds,
        //         programIds: [project.id],
        //         userId: user.sub,
        //         resourceId: firstResource?.id,
        //         query: {programIds: selectedIds},
        //         source: 'programs_list',
        //       },
        //     });
        //     onCloseModal();
        //   }
        // });
        break;
      case ModalType.AddToGroup:
        setModalState({
          title: `Add People to ${verbiage.Communities}`,
          subtitle: `Add selected people to one of your ${verbiage.Communities}`,
          emptyMessage: `There are no available ${verbiage.Communities} to add to`,
          sources: communities,
          searchPlaceholder: `Search ${verbiage.communities}...`,
          handleSave: async (saveSelectedIds) => {
            await addMembersToCommunity({
              variables: {
                query: { programIds: project?.id ? [project.id] : [] },
                memberIds: isEmpty(selectedMemberIds) ? null : selectedMemberIds,
                communityIds: saveSelectedIds,
              },
            });
            closeModal();
          },
        });
        break;
      case ModalType.AddToAnotherProject:
        setModalState({
          title: `Add People to Another ${verbiage.Program}`,
          subtitle: `Add selected people to one of your ${verbiage.Programs}`,
          emptyMessage: `There are no available ${verbiage.Programs} to add to`,
          sources: filter(projects, (p) => p.id !== project?.id),
          searchPlaceholder: `Search ${verbiage.programs}...`,
          handleSave: async (saveSelectedIds) => {
            await addMembersToPrograms({
              variables: {
                query: { programIds: project?.id ? [project.id] : [] },
                memberIds: selectedMemberIds,
                programIds: saveSelectedIds,
                status: 'approved',
              },
            });
            addEvent(
              EventName.AddMemberToProject,
              { count: selectedMemberIds.length },
            );
            closeModal();
          },
        });
        break;
        default:
          break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalType,
    project,
    projects,
    communities,
    selectedMemberIds,
    selectedIds,
    firstResource?.id,
    user?.sub,
    verbiage.Communities,
    verbiage.Community,
    verbiage.Program,
    verbiage.Programs,
    verbiage.programs,
  ]);

  const isSaving = addingMembersToCommunity || addingMembers || isInviting;
  const handleSearch = useFuzzySearchByKeys((modalState?.sources as TProject[]), SEARCH_KEYS);

  const closeButton = (
    <IconButton
      icon={<CloseIcon size={18} />}
      onClick={closeModal}
    />
  );

  const isSubmitDisabled = useMemo(() => (
    loading
      || !size(selectedIds)
  ), [loading, selectedIds]);

  return (
    <Modal
      afterClose={closeModal}
      centered
      width={600}
      closeIcon={closeButton}
      footer={null}
      onCancel={closeModal}
      visible={showModal}
    >
      <FormActions
        title={modalState?.title}
        subtitle={modalState?.subtitle}
        primaryAction={{
          disabled: isSubmitDisabled,
          loading: isSaving,
          label: !isSaving ? 'Add Members' : 'Adding...',
          onClick: modalState?.handleSave.bind(this, selectedIds),
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: closeModal,
        }}
      >
        <SelectList
          className={styles.selectList}
          contentClassName={styles.content}
          searchPlaceholder={modalState?.searchPlaceholder}
          showSearch
          onSearchRequest={handleSearch}
          options={modalState?.sources}
          mapOptionToId={(program) => program.id}
          mapOptionToLabel={mapOptionToLabel}
          disabled={false}
          onChange={handleSelectListChange}
          selectedIds={selectedIds}
          isLoading={loading || loadingCommunities}
          emptyMessage={modalState?.emptyMessage}
          clearSelection={clearSelection}
          onClearSelection={resetClearSelection}
        />
      </FormActions>
    </Modal>
  );
};
