import React from 'react';
import {
 Avatar, Button, Col, Row, Space, Typography,
} from '@revfluence/fresh';
import { ArrowLeftIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory } from 'react-router-dom';
import styles from './PageHeader.scss';

const { Title, Text } = Typography;

export interface IPageHeaderProps {
  title: string;
  subtitle?: string;
  image?: string;
  rightContent?: React.ReactNode;
  showBackButton?: boolean;
  backButtonUrl?: string;
}

export const PageHeader = ({
 title, subtitle, image = null, rightContent = null, showBackButton = true, backButtonUrl = null,
}: IPageHeaderProps) => {
  const history = useHistory();

  const handleBack = () => {
    if (backButtonUrl) {
      history.push(backButtonUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <Row align="middle" justify="space-between" className={styles.PageHeader}>
      <Col>
        <Space size="middle">
          {showBackButton && <Button icon={<ArrowLeftIcon />} onClick={handleBack} />}
          {image && <Avatar src={image} shape="square" />}
          <Space direction="vertical" size={0}>
            <Title level={3}>{title}</Title>
            <Text className={styles.subTitle}>{subtitle}</Text>
          </Space>
        </Space>
      </Col>
      <Col>{rightContent}</Col>
    </Row>
  );
};
