import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { SvgIcon, ISvgIconProps } from '@components';

const { useMemo } = React;

export const LightningFilledIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <path
        d="
          M7.4066 15.3333C7.3306 15.3333 7.25393 15.32 7.1786 15.2926C6.8906 15.188 6.7106 14.9
          6.74393 14.5953L7.25593 9.86662H3.3326C3.0866 9.86662 2.8606 9.73129 2.7446 9.51396C2.6286
          9.29663 2.64193 9.03396 2.7786 8.82929L8.03726 0.962625C8.20793 0.707292 8.5306 0.601292
          8.81926 0.707292C9.10793 0.811958 9.28726 1.09996 9.25393 1.40463L8.74193
          6.13329H12.6659C12.9119 6.13329 13.1379 6.26862 13.2539 6.48596C13.3693 6.70329 13.3566
          6.96596 13.2199 7.17062L7.9606 15.0373C7.8346 15.2266 7.62393 15.3333 7.4066 15.3333Z
        "
      />
      <mask
        height="16"
        id={id}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        width="12"
        x="2"
        y="0"
      >
        <path
          d="
            M7.4066 15.3333C7.3306 15.3333 7.25393 15.32 7.1786 15.2926C6.8906 15.188 6.7106 14.9
            6.74393 14.5953L7.25593 9.86662H3.3326C3.0866 9.86662 2.8606 9.73129 2.7446
            9.51396C2.6286 9.29663 2.64193 9.03396 2.7786 8.82929L8.03726 0.962625C8.20793 0.707292
            8.5306 0.601292 8.81926 0.707292C9.10793 0.811958 9.28726 1.09996 9.25393
            1.40463L8.74193 6.13329H12.6659C12.9119 6.13329 13.1379 6.26862 13.2539 6.48596C13.3693
            6.70329 13.3566 6.96596 13.2199 7.17062L7.9606 15.0373C7.8346 15.2266 7.62393 15.3333
            7.4066 15.3333Z
          "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

LightningFilledIcon.displayName = 'LightningFilledIcon';
