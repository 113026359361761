import * as React from 'react';
import { map, reject, find } from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  Button, Dropdown, Menu, Space, Spinner, Tooltip, Modal,
} from '@revfluence/fresh';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';

import {
  useMemberProgramsAndCommunitiesQuery,
  useModifyProgramMembersMutation,
  useFeatureFlagVerbiage,
} from '@frontend/app/hooks';
import { GetAllProjectsQuery_projects } from '@frontend/app/queries/types/GetAllProjectsQuery';
import {
  ModifyProgramMembersModal,
  InviteToProgramModal,
} from '@frontend/app/components';
import {
  MemberProgramsAndCommunitiesQuery_member_programs as IProgram,
} from '@frontend/app/queries/types/MemberProgramsAndCommunitiesQuery';
import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { TagItem } from '../TagItem';

const { useState, useMemo } = React;

interface IProps {
  memberId?: number;
  selectedPrograms?: IProgram[];
  onChangePrograms?(programs: IProgram[]);
  projects?: GetAllProjectsQuery_projects[];
  isAddingMember?: boolean;
}

export enum ShownModal {
  AddToPrograms = 'addToPrograms',
  InviteToPrograms = 'inviteToPrograms',
}

const Projects: React.FunctionComponent<IProps> = ({
  memberId,
  selectedPrograms,
  projects,
  isAddingMember,
  onChangePrograms,
}) => {
  const [removingProgramId, setRemovingProgramId] = useState<number>();
  const [shownModal, setShownModal] = useState<ShownModal>(null);
  const history = useHistory();

  const {
    data: {
      member: {
        programs: memberPrograms = null,
      } = {},
    } = {},
    loading: isLoadingMemberData,
    refetch: refetchMember,
  } = useMemberProgramsAndCommunitiesQuery(memberId, {
    fetchPolicy: 'no-cache',
  });

  const verbiage = useFeatureFlagVerbiage();

  const closeModal = () => {
    setShownModal(null);
  };

  const handleAddPrograms = (programs: IProgram[]) => {
    if (onChangePrograms) {
      onChangePrograms(programs);
    }

    if (memberId) {
      refetchMember();
    }
  };

  const [removeMemberFromProgram] = useModifyProgramMembersMutation('remove', {
    async onCompleted() {
      await refetchMember();
      setRemovingProgramId(null);
    },
    onError() {
      setRemovingProgramId(null);
    },
  });

  const handleRemoveProgram = async (programId: number) => {
    if (!memberId) {
      if (onChangePrograms) {
        const nextPrograms = reject(selectedPrograms, { id: programId });
        onChangePrograms(nextPrograms);
      }
      return;
    }

    setRemovingProgramId(programId);

    await removeMemberFromProgram({
      variables: {
        memberIds: [memberId],
        programIds: [programId],
      },
    });
    refetchMember();
  };

  const memberIds = useMemo(() => {
    if (memberId) {
      return [memberId];
    } else {
      return [];
    }
  }, [memberId]);

  const programs = useMemo(
    () => memberPrograms || selectedPrograms,
    [memberPrograms, selectedPrograms],
  );

  return (
    <Space direction="vertical" size="small">
      {isLoadingMemberData && (
        <Spinner />
      )}

      {map(programs, (program) => {
        const project = find(projects, { id: program.id });

        return (
          <TagItem
            key={program.id}
            color={project?.status === ProjectStatus.Archived ? 'default' : 'processing'}
            closable
            loading={removingProgramId === program.id}
            onClick={() => {
              history.push(`/projects/${program.id}`);
            }}
            onClose={(e) => {
              e.preventDefault();
              Modal.confirm({
                open: false,
                title: 'Remove Member from Project?',
                onOk: () => handleRemoveProgram(program.id),
                okText: 'Remove',
                okButtonProps: { danger: true },
                cancelText: 'Cancel',
              });
            }}
          >
            {program.title}
          </TagItem>
        );
      })}

      {isAddingMember ? (
        <Tooltip
          title={`Add to ${verbiage.Program}`}
          overlayStyle={{ zIndex: 9502 }}
        >
          <Button
            icon={<PlusIcon />}
            size="small"
            onClick={() => {
              setShownModal(ShownModal.AddToPrograms);
            }}
          />
        </Tooltip>
      ) : (
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item
                key="invite"
                onClick={() => setShownModal(ShownModal.InviteToPrograms)}
              >
                Invite to apply
              </Menu.Item>
              <Menu.Item
                key="add"
                onClick={() => setShownModal(ShownModal.AddToPrograms)}
              >
                Add to
                {' '}
                {verbiage.Program}
              </Menu.Item>
            </Menu>
          )}
          overlayStyle={{ zIndex: 9501 }}
          trigger={['click']}
        >
          <Tooltip
            title={`Add to ${verbiage.Program}`}
            overlayStyle={{ zIndex: 9502 }}
          >
            <Button icon={<PlusIcon />} size="small" />
          </Tooltip>
        </Dropdown>
      )}

      {shownModal === ShownModal.AddToPrograms && (
        <ModifyProgramMembersModal
          show
          onRequestClose={closeModal}
          type="add"
          memberIds={memberIds}
          memberCount={1}
          onModifyComplete={handleAddPrograms}
        />
      )}

      {shownModal === ShownModal.InviteToPrograms && (
        <InviteToProgramModal
          show
          memberIds={memberIds}
          memberCount={1}
          onRequestClose={closeModal}
        />
      )}
    </Space>
  );
};

export default Projects;
