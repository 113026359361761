import { Typography } from '@revfluence/fresh';
import { capitalize, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import Tags from './Tags';

const { Title } = Typography;
const { Text } = Typography;

const CreatorInfo = ({ memberRecord, socialProfileData, applicantReviewFields }) => {
  const [bio, setBio] = useState<string>(null);
  const [memberPronoun, setMemberPronoun] = useState<string>(null);
  useEffect(() => {
    if (memberRecord && applicantReviewFields) {
      const fieldToShow = applicantReviewFields.Pronouns;
      if (fieldToShow && memberRecord.fields?.[fieldToShow]) {
        setMemberPronoun(memberRecord.fields?.[fieldToShow]);
      }
    }
  }, [memberRecord, applicantReviewFields]);
  useEffect(() => {
    setBio(socialProfileData?.instagram && socialProfileData?.instagram[0].description || socialProfileData?.tiktok && socialProfileData?.tiktok[0].description);
  }, [socialProfileData]);
  return (
    <>
      <Title level={3}>
        {memberRecord?.name}
        {memberPronoun ? ( // add
          <i>
            (
            {memberPronoun}
            )
          </i>
) : null}
      </Title>
      <Tags tags={map(memberRecord?.tags, (memberTag) => memberTag.name)} eligibleTags={applicantReviewFields?.tags} />

      {bio && <Text style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>{capitalize(bio)}</Text>}
    </>
  );
};

export default CreatorInfo;
