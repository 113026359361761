import * as React from 'react';
import { Typography } from '@revfluence/fresh';
import { find } from 'lodash';
import { Button } from 'antd';

import { TContentGuideline, IContentGuidelinesWithDueDates } from '../types/ContentGuidelines';

import styles from './ContentDeliverableTemplate.scss';

const { useMemo, useCallback } = React;
const { Text } = Typography;

interface IProps {
  contentGuideline: TContentGuideline | IContentGuidelinesWithDueDates;
  contentGuidelinesWithDueDates: Array<IContentGuidelinesWithDueDates>;
  contentGuidelineInstanceId?: number | null;
  onAddPostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
  onRemovePostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
}

const ContentDeliverableTemplate: React.FC<IProps> = React.memo((props) => {
  const {
    contentGuideline,
    contentGuidelinesWithDueDates,
    onAddPostType,
    onRemovePostType,
    contentGuidelineInstanceId,
  } = props;

  const findContentGuideline = useCallback(
    (
      contentGuidelineWithDueDates: IContentGuidelinesWithDueDates,
    ) => contentGuidelineWithDueDates.id === contentGuideline?.id
      && contentGuidelineWithDueDates.contentGuidelineInstanceId === (contentGuidelineInstanceId || null),
    [contentGuideline, contentGuidelineInstanceId],
  );

  const postsCount = useMemo(
    () => {
      const post = find(
        contentGuidelinesWithDueDates,
        findContentGuideline,
      );
      if (post) {
        return post.dueDates.completeDate.length;
      }
      return 0;
    }, [contentGuidelinesWithDueDates, findContentGuideline],
  );

  return (
    <div className={styles.ContentDeliverableTemplate}>
      <Text>{contentGuideline?.label}</Text>
      <div className={styles.controls}>
        <Button
          type="link"
          onClick={() => onRemovePostType(contentGuideline, contentGuidelineInstanceId)}
          disabled={!postsCount}
        >
          -
        </Button>
        <Text>{ postsCount }</Text>
        <Button
          type="link"
          onClick={() => onAddPostType(contentGuideline, contentGuidelineInstanceId)}
        >
          +
        </Button>
      </div>
    </div>
  );
});

ContentDeliverableTemplate.displayName = 'ContentDeliverableTemplate';

export default ContentDeliverableTemplate;
