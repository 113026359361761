import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { GetAdvertiserProductCollections } from '@affiliates/queries/types/GetAdvertiserProductCollections';
import { GET_PRODUCT_COLLECTIONS } from '@affiliates/queries';

const { useMemo } = React;

type IOptions = QueryHookOptions;

export const getProductCollectionData = (data: GetAdvertiserProductCollections) =>
    data.advertiser.shopifyResources[0].collections;
export const useGetProductCollections = (options: IOptions = {}) => {
    const { data, loading, error } = useQuery<GetAdvertiserProductCollections>(
        GET_PRODUCT_COLLECTIONS,
        options,
    );
    const memoizedData = useMemo(() => (
        data ? getProductCollectionData(data) : undefined
    ), [data]);
    return { data: memoizedData, loading, error } as const;
};
