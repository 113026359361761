import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';
import { Upload } from 'antd';

import {
  Button,
  CheckCircleIcon,
  IToastRefHandles,
  SpinnerIcon,
  Toast,
  XCircleIcon,
} from '@components';

import {
  CSVToArray,
  EventName,
  logger,
} from '@common';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { IWizardStepHandles } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context/EventContext';

import { ClientFeature } from '@frontend/app/constants';
import { useUploadCSVContext } from '../hooks/useUploadCSVModalContext';

const {
 useCallback, useImperativeHandle, useRef, useState,
} = React;

import styles from './UploadFile.scss';

const womanWorkingSvg = 'https://storage.googleapis.com/aspirex-static.aspireiq.com/app/public/images/a5d18880e0cc8f6d4c1e0aa1b45ddd78.svg';

interface IProps {
  nextStep: () => void;
  uploadIdentifier: string;
}

const MAX_FILE_SIZE_MB = 2;
const MAX_FILE_ROW_COUNT = 5000;

export const UploadFile = React.forwardRef<IWizardStepHandles, IProps>((props, ref) => {
  const {
    nextStep,
    uploadIdentifier,
   } = props;

   const workflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  const { setFile } = useUploadCSVContext();
  const addEvent = useEventContext();
  const toastRef = useRef<IToastRefHandles>();
  const [isUploading, setIsUploading] = useState(false);
  const [isFileTypeError, setFileTypeError] = useState<boolean>();
  const [isFileSizeError, setFileSizeError] = useState<boolean>();
  const [isFileRowCountError, setFileRowCountError] = useState<boolean>();

  const resetErrors = useCallback(
    () => {
      setFileSizeError(undefined);
      setFileTypeError(undefined);
      setFileRowCountError(undefined);
    },
    [setFileTypeError, setFileSizeError, setFileRowCountError],
  );

  useImperativeHandle(
    ref,
    () => ({
      reset: () => {
        setIsUploading(false);
        resetErrors();
      },
    }),
    [resetErrors, setIsUploading],
  );

  const success = useCallback(
    (file: File) => {
      setFile(file);
      if (isFunction(nextStep)) {
        // Pause for a while, then proceed to the next step
        setTimeout(() => {
          setIsUploading(false);
          nextStep();
        }, 1000);
      }
    },
    [nextStep, setFile, setIsUploading],
  );

  const verifyFile = useCallback(
    async (file: File) => {
      let isCorrectSize = true;

      // Check file type
      const regex = /^.+(\.csv)$/g;
      const isCSV = file.type === 'text/csv' || regex.test(file.name);

      if (!isCSV) {
        const errorMessage = 'File format must be .csv';
        logger.error(errorMessage);
        setFileTypeError(true);
        addEvent(EventName.CSVUploadLoadFileCompleted, {
          uploadIdentifier,
          success: false,
          isCSV,
          isCorrectSize,
        });
        return false;
      } else {
        setFileTypeError(false);
      }

      // Check file size
      const fileSizeLimit = file.size < MAX_FILE_SIZE_MB * 1024 * 1024;
      if (!fileSizeLimit) {
        const errorMessage = `File cannot exceed ${MAX_FILE_SIZE_MB} MB`;
        logger.error(errorMessage);
        isCorrectSize = false;
        setFileSizeError(true);
        return false;
      } else {
        setFileSizeError(false);
      }

      // Check file row count
      const rowCount = await new Promise<number>((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (event) => {
          const csv = CSVToArray(event.target.result.toString());
          logger.debug(`CSV has ${csv.length} lines, including the header.`);
          resolve(csv.length - 1);
        };
      });

      if (rowCount > MAX_FILE_ROW_COUNT) {
        const formatter = new Intl.NumberFormat();
        const formattedMax = formatter.format(MAX_FILE_ROW_COUNT);
        const errorMessage = `File row count cannot exceed ${formattedMax}`;
        logger.error(errorMessage);
        setFileRowCountError(true);
        return false;
      } else {
        setFileRowCountError(false);
      }

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addEvent, setFileSizeError, setFileTypeError],
  );

  const handleBeforeUpload = useCallback(
    async (file: File) => {
      setIsUploading(true);
      resetErrors();

      if (!(await verifyFile(file))) {
        setIsUploading(false);
        return false;
      }

      addEvent(EventName.CSVUploadLoadFileCompleted, {
        uploadIdentifier,
        success: true,
        isCSV: true,
        isCorrectSize: true,
      });

      success(file);
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetErrors, setIsUploading, success, verifyFile],
  );

  return (
    <div className={styles.UploadFile}>
      <img
        src={womanWorkingSvg}
        className={styles.art}
      />
      <h4>Upload your .csv file</h4>
      <p>Before you upload, make sure that your file meets these requirements</p>
      <ul className={styles.requirements}>
        <li
          className={cx({
              [styles.hasError]: isFileTypeError,
            })}
        >
          Uploaded file should be a .csv file
          {isFileTypeError && <XCircleIcon size={20} className={styles.xcircleIcon} />}
          {isFileTypeError === false && <CheckCircleIcon size={20} className={styles.checkIcon} />}
        </li>
        <li
          className={cx({
            [styles.hasError]: isFileSizeError,
          })}
        >
          Uploaded file size should be under
          {' '}
          {MAX_FILE_SIZE_MB}
          {' '}
          MB
          {isFileSizeError && <XCircleIcon size={20} className={styles.xcircleIcon} />}
          {isFileSizeError === false && <CheckCircleIcon size={20} className={styles.checkIcon} />}
        </li>
        <li
          className={cx({
            [styles.hasError]: isFileRowCountError,
          })}
        >
          Number of rows should be less than
          {' '}
          {new Intl.NumberFormat().format(MAX_FILE_ROW_COUNT)}
          {isFileRowCountError && <XCircleIcon size={20} className={styles.xcircleIcon} />}
          {isFileRowCountError === false && <CheckCircleIcon size={20} className={styles.checkIcon} />}
        </li>
      </ul>
      {isUploading
        ? (
          <div className={styles.loading}>
            <SpinnerIcon className={styles.spinnerIcon} />
          </div>
        )
        : (
          <>
            <Upload
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
              className={styles.upload}
            >
              <Button
                label="Begin csv upload"
                disabled={isUploading}
                theme="primary"
                fullWidth={false}
                className={styles.button}
              />
            </Upload>
            <p className={styles.downloadTemplateLink}>
              <a
                href={workflowEnabled ? (
                'https://storage.googleapis.com/aspirex-static-files/AspireIQ%20Member%20CSV%20Template.csv'
              ) : (
                'https://storage.googleapis.com/aspirex-static-files/Member%20CSV%20Template.csv'
              )}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download our template
              </a>
            &nbsp;to jumpstart your file
            </p>
          </>
)}
      <Toast ref={toastRef} />
    </div>
  );
});

UploadFile.displayName = 'UploadFile';
