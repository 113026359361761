import * as React from 'react';
import { Button } from '@revfluence/fresh';

import styles from './Tables.scss';

interface IRemoveButtonProps {
  removeAccount: () => void;
  loading?: boolean;
  disabled?: boolean;
}

export const RemoveButton = (props: IRemoveButtonProps) => {
  const { removeAccount, loading, disabled } = props;
  return (
    <Button className={styles.RemoveButton} onClick={removeAccount} loading={loading} disabled={disabled} size="small">Remove</Button>
  );
};
