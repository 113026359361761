import * as React from 'react'; // eslint-disable-line
import { useHistory } from 'react-router-dom';

import { ProjectsRouteRoot } from '@frontend/app/containers/Projects/constants';
import {
 TTemplate, useClientFeatureEnabled, useGetAutomationSummaryQuery, useListTemplatesQuery,
} from '@frontend/app/hooks';
import {
 Card, Typography, Row, Button,
} from '@revfluence/fresh';
import { isEmpty } from 'lodash';
import { LightningIcon } from '@frontend/app/components';
import { ClientFeature } from '@frontend/app/constants';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import { BoltIcon } from '@revfluence/fresh-icons/regular/esm';
import TemplateItem from '../AutomationPage/templateModal/TemplateItem';
import styles from './AutomationSummary.scss';
import AutomationStatCard from './AutomationStatCard';

const { Title } = Typography;

type TProps = {
  projectId: number;
};

// TODO(jb) when reoganizing feature, consider moving this to its own file
const SuggestedAutomations = (props: { projectId: number }) => {
  const history = useHistory();
  const { projectId } = props;

  const {
    data: { listTemplates: { results: templates } } = {
      listTemplates: {
        results: [],
      },
    },
    loading: isLoadingTemplates,
  } = useListTemplatesQuery({
    // TODO(jb) filter `isUsed` after the fact, consider how this works with paging
    //          currently `isUsed` is not computed in the db, therefore can't be filtered up front
    variables: {
      // TOOD(jb) refactor GQL paging to be optional (defaults to `offset: 0, limit: 10`)
      paging: {
        offset: 0,
        limit: 10, // TODO(jb) temporary limit, revert to `limit: 2` when `isUsed` can be filtered in the db
      },
      filters: {
        isSuggested: true,
        // isUsed: false, // this is not currently supported in the db
      },
      context: {
        programId: projectId,
      },
    },
  });

  // TODO(jb) when reoganizing feature, rename this appropriately
  const goToConfig = (templateId: string) => {
    // TODO(jb) rethink our routing structure, consider template as a query param in this case
    history.push({
      pathname: `${ProjectsRouteRoot}/${projectId}/automation_detail/template/${templateId}`,
    });
  };

  if (isLoadingTemplates) return null;

  const availableTemplates = templates.filter((template: TTemplate) => !template.isUsed);
  if (isEmpty(availableTemplates)) return null;

  return (
    <div>
      <div className={styles.AutomationSuggestedSubtitle}>Suggested Automations</div>
      <div className={styles.TemplateList}>
        {availableTemplates
          // TODO(jb) remove filter/slice when `isUsed` can be filtered in the db
          .slice(0, 2)
          .map((template: TTemplate) => (
            <TemplateItem
              key={template.id}
              templateId={template.id}
              name={template.blueprint.name}
              icon={template.blueprint.metadata.icon}
              gotoConfig={goToConfig}
              navText="Configure"
              isNavTextTag={false}
              isNavTextPersistent
            />
        ))}
      </div>
    </div>
  );
};

const AutomationSummary = (props: TProps) => {
  const history = useHistory();
  const { projectId } = props;

  const {
    data: { getAutomationSummary: summary } = {
      getAutomationSummary: {
        automations: {
          total: 0,
          active: 0,
        },
        executions: {
          pastWeek: {
            total: 0,
          },
        },
      },
    },
  } = useGetAutomationSummaryQuery({
    variables: {
      context: { programId: projectId },
    },
  });

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const goToAutomationSettings = (projectId: number) => {
    // TODO(jb) rethink our routing structure, consider template as a query param in this case
    history.push({
      pathname: `${ProjectsRouteRoot}/${projectId}/settings/automation`,
    });
  };

  const renderNewUI = () => (
    <ShadCnCard>
      <CardHeader>
        <div className="flex items-center gap-2">
          <BoltIcon fontSize={16} className="text-icon-grey" />
          <span className="font-medium text-base">Automations</span>
        </div>
      </CardHeader>
      <CardContent className="h-full">
        <Row className={styles.AutomationSummaryBody}>
          <div className={styles.AutomationSummary}>
            <AutomationStatCard
              stat={summary.automations.active}
              title={summary.automations.active === 1 ? 'Automation on' : 'Automations on'}
            />
            <AutomationStatCard
              stat={summary.executions.pastWeek.total}
              title="Triggered this week"
            />
          </div>
          <SuggestedAutomations projectId={projectId} />
        </Row>
        <Row className={styles.AutomationSummaryFooter}>
          <Button
            onClick={() => {
              goToAutomationSettings(projectId);
            }}
            type="text"
          >
            View All Automations
          </Button>
        </Row>
      </CardContent>
    </ShadCnCard>
    );

  const renderOldUI = () => (
    <Card>
      <Row className={styles.AutomationSummaryBody}>
        <Title level={5} className={styles.AutomationSummaryTitle}>
          {' '}
          <LightningIcon size={17} />
          {' '}
          <div>Automations</div>
        </Title>
        <div className={styles.AutomationSummary}>
          <AutomationStatCard
            stat={summary.automations.active}
            title={summary.automations.active === 1 ? 'Automation on' : 'Automations on'}
          />
          <AutomationStatCard
            stat={summary.executions.pastWeek.total}
            title="Triggered this week"
          />
        </div>
        <SuggestedAutomations projectId={projectId} />
      </Row>
      <Row className={styles.AutomationSummaryFooter}>
        <Button
          onClick={() => {
              goToAutomationSettings(projectId);
            }}
          type="text"
        >
          View All Automations
        </Button>
      </Row>
    </Card>
    );

  return (
    isRefreshUIEnabled ? renderNewUI() : renderOldUI()
  );
};

export default AutomationSummary;
