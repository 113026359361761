import { TState } from '../types';

interface IAction {
    payoutId: number;
    type: 'BULK PAYOUT ID';
}
export type TUpdateBulkPayoutIdAction = Readonly<IAction>;

export const updateBulkPayoutId = (state: TState, action: TUpdateBulkPayoutIdAction): TState => {
    const members = state.members.map((m) => {
        if (m?.selected) {
            return {
                ...m,
                payoutId: action.payoutId,
            };
        }
        return {
            ...m,
        };
    });
    return {
        ...state,
        members,
        bulkPayoutId: action.payoutId,
    };
};
