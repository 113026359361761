import * as React from 'react';
import { map, isEmpty } from 'lodash';
import { Space, Typography, Tooltip } from '@revfluence/fresh';

import { MemberInput } from '@frontend/app/types/globalTypes';
import { MemberFieldSchemasBySectionsQuery_sections_permissions } from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';
import { ImagesCell } from '../FieldRow/ImagesCell';
import { FieldRow } from '../FieldRow';

const { Text } = Typography;

const labelStyle = {
  fontSize: '12px',
  cursor: 'default',
};

type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_permissions;

interface Props {
  member: MemberInput;
  schemas?: MemberFieldSchema[];
}

const SocialPerformance: React.FC<Props> = React.memo(({ member, schemas = [] }) => (member ? (
  <Space direction="vertical">
    {map(schemas, (schema) => (
      <Space key={schema.id} direction="vertical" size={0}>
        {schema.type === 'IMAGES' ? (
          <>
            <Tooltip title={schema.metaData?.tooltip}>
              <Text type="secondary" style={labelStyle}>{schema.name}</Text>
            </Tooltip>
            {renderImageCell(member.fields[schema.id])}
          </>
        ) : (
          <FieldRow
            type={schema.type}
            value={member.fields[schema.id]}
            label=""
            renderLabel={() => (
              <Tooltip title={schema.metaData?.tooltip}>
                <Text type="secondary" style={labelStyle}>{schema.name}</Text>
              </Tooltip>
            )}
          />
        )}
      </Space>
    ))}
  </Space>
) : null));

SocialPerformance.displayName = 'SocialPerformance';

export default SocialPerformance;

function renderImageCell(val: string | undefined) {
  try {
    const images = JSON.parse(val);

    return isEmpty(images)
      ? <Text>-</Text>
      : <ImagesCell images={images} />;
  } catch {
    return <Text>-</Text>;
  }
}
