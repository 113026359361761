/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_3lzf9_157 {
  display: flex;
}

._justify-content-space-between_3lzf9_161 {
  justify-content: space-between;
}

._tabular-nums_3lzf9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_3lzf9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TableView_3lzf9_178 {
  height: 100%;
  letter-spacing: 0.078125rem;
  text-transform: uppercase;
  color: #4F4F4F;
}
._TableView_3lzf9_178 ._header_3lzf9_184 {
  margin-bottom: 0.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-size: 0.5rem;
}
._TableView_3lzf9_178 ._body_3lzf9_192 {
  font-size: 0.6875rem;
  line-height: 0.6875rem;
}
._TableView_3lzf9_178 ._body_3lzf9_192 ._row_3lzf9_196 {
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
._TableView_3lzf9_178 ._body_3lzf9_192 ._row_3lzf9_196:nth-child(odd) {
  background-color: #eff5f9;
  border-radius: 6.25rem;
}
._TableView_3lzf9_178 ._type_3lzf9_207 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._TableView_3lzf9_178 ._type_3lzf9_207 ._dot_3lzf9_213 {
  margin-left: 0.375rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 6.25rem;
}
._TableView_3lzf9_178 ._count_3lzf9_221 {
  width: 3.75rem;
}
._TableView_3lzf9_178 ._mulSymbol_3lzf9_224 {
  width: 2.5rem;
  color: #8F8D91;
  font-size: 0.5rem;
}
._TableView_3lzf9_178 ._multiplier_3lzf9_229 {
  width: 3.75rem;
}
._TableView_3lzf9_178 ._equalSymbol_3lzf9_232 {
  width: 2.5rem;
  color: #8F8D91;
  font-size: 0.5rem;
}
._TableView_3lzf9_178 ._tmv_3lzf9_237 {
  width: 3.75rem;
}