import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_PROJECT_BUDGET_DASHBOARD_DATA from '@frontend/app/queries/GetProjectBudgetDashboardData';
import { GetProjectBudgetDashboardData, GetProjectBudgetDashboardDataVariables } from '@frontend/app/queries/types/GetProjectBudgetDashboardData';

type IOptions = QueryHookOptions<GetProjectBudgetDashboardData, GetProjectBudgetDashboardDataVariables>;

export function useGetProjectBudgetDashboardData(options: IOptions) {
    const {
        loading, data: { projectBudgetDashboardData } = {}, error, refetch,
    } = useQuery<GetProjectBudgetDashboardData, GetProjectBudgetDashboardDataVariables>(GET_PROJECT_BUDGET_DASHBOARD_DATA, {
      ...options,
      fetchPolicy: 'cache-and-network',
    });
    return {
        loading,
        projectBudgetDashboardData,
        error,
        refetch,
    };
}
