import 'css-chunk:src/app/components/MembersTableOld/EditProjectColumns.scss';export default {
  "primary": "#167cf4",
  "primary-40": "#3d73d7",
  "primary-10": "#e6f7ff",
  "secondary": "#f58984",
  "secondary-40": "#fcbbae",
  "secondary-10": "#fef0ed",
  "baygrey": "#989ea8",
  "grey-10": "#fafafa",
  "grey-25": "#e9e8ea",
  "grey-50": "#aeaeae",
  "grey-75": "#8f8f8f",
  "grey-100": "#1a1b1c",
  "white": "#fdfdfd",
  "black": "#1a1818",
  "green": "#006462",
  "sand": "#ffe9d9",
  "yellow": "#ffcd5b",
  "magenta-base": "#ffddff",
  "red-base": "#f5b3b4",
  "volcano-base": "#ff9c93",
  "orange-base": "#fabf95",
  "gold-base": "#f1e5ac",
  "lime-base": "#ebffd0",
  "sage-base": "#c1e1c1",
  "cyan-base": "#c9f2f2",
  "blue-base": "#70d1f1",
  "geekblue-base": "#a2aff1",
  "purple-base": "#c5b3e3",
  "mauve-base": "#c6b0ca",
  "coral-base": "#ff8a8a",
  "apricot-base": "#f5a287",
  "marigold-base": "#fdaf1c",
  "butter-base": "#f9eda1",
  "apple-base": "#b7d275",
  "asparagus-base": "#9db17c",
  "tiffany-base": "#5fcdca",
  "baby-base": "#a3ddf9",
  "carolina-base": "#7eb3d8",
  "rose-base": "#ffb4c7",
  "watermelon-base": "#ffa1b6",
  "blue-0": "#f4fcff",
  "blue-1": "#e6f7ff",
  "blue-3": "#91d5ff",
  "blue-5": "#499dff",
  "blue-6": "#167cf4",
  "blue-7": "#2771c9",
  "gray-1": "#fff",
  "gray-2": "#fafafa",
  "gray-3": "#f5f5f5",
  "gray-4": "#f0f0f0",
  "gray-5": "#d9d9d9",
  "gray-6": "#bfbfbf",
  "gray-7": "#8c8c8c",
  "gray-8": "#505256",
  "gray-9": "#1f1f21",
  "red-1": "#fff1f0",
  "red-3": "#ffa39e",
  "red-4": "#ff7875",
  "red-5": "#ff4d4f",
  "red-6": "#f5222d",
  "gold-1": "#fffbe6",
  "gold-3": "#ffe58f",
  "gold-5": "#ffc53d",
  "gold-6": "#faad14",
  "gold-7": "#d48806",
  "green-1": "#f6ffed",
  "green-3": "#b7eb8f",
  "green-5": "#73d13d",
  "green-6": "#52c41a",
  "green-7": "#389e0d",
  "text-color": "#505256",
  "text-color-secondary": "#8c8c8c",
  "text-color-inverse": "#fff",
  "icon-color": "inherit",
  "icon-color-hover": "rgba(26, 27, 28, 0.75)",
  "heading-color": "#1f1f21",
  "text-color-dark": "rgba(255, 255, 255, 0.85)",
  "text-color-secondary-dark": "rgba(255, 255, 255, 0.65)",
  "text-selection-bg": "#167cf4",
  "link-color": "#167cf4",
  "modal-mask-bg": "rgba(79, 90, 114, 0.45)",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_sgc8u_325",
  "justify-content-space-between": "_justify-content-space-between_sgc8u_329",
  "tabular-nums": "_tabular-nums_sgc8u_333",
  "modalFooter": "_modalFooter_sgc8u_523",
  "modalHeader": "_modalHeader_sgc8u_529",
  "openButton": "_openButton_sgc8u_536",
  "subTitle": "_subTitle_sgc8u_552",
  "columnSearch": "_columnSearch_sgc8u_557",
  "selectedColumns": "_selectedColumns_sgc8u_561",
  "columnsList": "_columnsList_sgc8u_567",
  "columnLabel": "_columnLabel_sgc8u_577",
  "selectedColumnItem": "_selectedColumnItem_sgc8u_582",
  "columns": "_columns_sgc8u_567",
  "column": "_column_sgc8u_557",
  "columnTitle": "_columnTitle_sgc8u_596",
  "columnSourceSelect": "_columnSourceSelect_sgc8u_601",
  "show": "_show_sgc8u_1"
};