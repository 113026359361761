import * as React from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { message } from '@revfluence/fresh';
import { useResourceContext } from '@frontend/app/context';
import {
  TUseGetSocialAccountSettingsQuery as TGetSocialAccountSettingsQuery,
  TUseUpdateSocialAccountClientSettingsMutation as TUpdateSocialAccountClientSettingsMutation,
} from '@frontend/app/hooks';
import { BrandedContentStatus, IGDMStatus } from '@frontend/app/types/globalTypes';
import {
  IInstagramAccount,
  InstagramAccountTable,
} from '../InstagramAccountTable';
import {
  composeAdsPermissionsDebug as composeBrandedContentAdsDebug,
  composeDirectMessagingDebug,
  composeSocialListeningDebug,
  CellStatus,
} from '../utils/composeCells';
import {
  composeAdsPermissionsFeatures,
  composeDirectMessagingFeatures,
  composeSocialListeningFeatures,
} from '../utils/composeFeatures';
import { canReconnect } from '../utils/canReconnect';
import { ResultsPerPage } from '../pages/InstagramMeta';

const { useEffect, useState, useCallback } = React;

interface IInstagramAccountsTableProps {
  brandedContentAdsEnabled: boolean;
  igdmEnabled: boolean;
  igEstimatedImpressionsEnabled: boolean;
  query: TGetSocialAccountSettingsQuery;
  mutation: TUpdateSocialAccountClientSettingsMutation;
  reconnectAccount: () => void;
}

export interface LoadingState {
  removing?: true;
  igdmLoading?: true;
  bcaLoading?: true;
}
export interface TableLoadingRows {
  [key: string]: LoadingState;
}

export const InstagramAccountsTable: React.FC<IInstagramAccountsTableProps> = (
  {
    query,
    mutation,
    brandedContentAdsEnabled,
    igdmEnabled,
    igEstimatedImpressionsEnabled,
    reconnectAccount,
  },
) => {
  const [loadingRows, setLoadingState] = useState<TableLoadingRows>({});
  const [
    updateSettings, mutationResult,
  ] = mutation;
  const {
    refetch,
  } = query;
  const setLoadingRows = (loadingRow: TableLoadingRows) => {
    setLoadingState((state) => ({
      ...state,
      ...loadingRow,
    }));
  };
  const clearLoadingRows = useCallback(() => {
    setLoadingState({});
  }, []);

  const {
    refetch: refetchResources,
  } = useResourceContext();

  useEffect(() => {
    const hasError = !!query.error || !!mutationResult.error;
    const notLoading = !(query.loading || mutationResult.loading);
    const hasLoadingRows = !isEmpty(loadingRows);
    if ((hasError || notLoading) && hasLoadingRows) {
      return clearLoadingRows();
    }
  }, [query, mutationResult, clearLoadingRows, loadingRows]);

  const data = query?.data?.getSocialAccountClientSettings?.instagramAccountData?.accounts || [];
  const pagination = query?.data?.getSocialAccountClientSettings?.instagramAccountData?.paging;
  let selectedIGDMAccount: string;
  const tableData: IInstagramAccount[] = data.map((account) => {
    const BCATag = composeBrandedContentAdsDebug(account.branded_content_ads_debug, account.token_debug);
    const IGDMTag = composeDirectMessagingDebug(account.igdm_debug, account.token_debug);
    if (!(IGDMTag === CellStatus.INACTIVE || IGDMTag === CellStatus.NA)) {
      selectedIGDMAccount = account.username;
    }
    return {
      id: account.id,
      loading: loadingRows[account.id],
      disconnectAccount: canReconnect(account),
      name: account.username,
      facebookPageId: account.facebook_page_id,
      adAccountNames: account.ad_account_names,
      canRemove: BCATag !== CellStatus.ACTIVE,
      socialListening: {
        cell: composeSocialListeningDebug(account.social_listening_debug, account.token_debug),
        features: composeSocialListeningFeatures(account.social_listening_debug),
      },
      brandedContentAds: {
        cell: BCATag,
        features: composeAdsPermissionsFeatures(account.branded_content_ads_debug),
        otherClientConnected: account.branded_content_ads_debug.status === BrandedContentStatus.OTHER_ACCOUNT_CONNECTED,
      },
      directMessaging: {
        cell: composeDirectMessagingDebug(account.igdm_debug, account.token_debug),
        features: composeDirectMessagingFeatures(account.igdm_debug),
        appNotInstalled: account.igdm_debug.status === IGDMStatus.NOT_INSTALLED,
        otherClientConnected: account.igdm_debug.status === IGDMStatus.OTHER_CLIENT_CONNECTION,
      },
    };
  });
  return (
    <InstagramAccountTable
      selectedIGDMAccount={selectedIGDMAccount}
      data={tableData}
      removeAccount={(socialAccountId, facebookPageId) => {
        updateSettings({
          variables: {
            socialAccountId,
            facebookPageId,
            disconnectAccount: true,
          },
          update: () => {
            refetchResources();
            message.success({
              content: 'Successfully removed Instagram Account',
            });
          },
        });
        setLoadingRows({ [socialAccountId]: { igdmLoading: true } });
      }}
      reconnectAccount={reconnectAccount}
      updateAccount={(variables) => {
        updateSettings({
          variables,
          update() {
            if (!isUndefined(variables.IGDMEnabled)) {
              refetchResources();
            }
          },
        });
        setLoadingRows({ [variables.socialAccountId]: { igdmLoading: true } });
      }}
      brandedContentAdsEnabled={brandedContentAdsEnabled}
      igdmEnabled={igdmEnabled}
      igEstimatedImpressionsEnabled={igEstimatedImpressionsEnabled}
      pagination={pagination ? {
        total: pagination.totalResults,
        current: pagination.currentPage,
        pageSize: pagination.resultsPerPage,
      } : undefined}
      onPaginationChange={(page) => {
        refetch({
          socialAccountPage: page,
          socialAccountResultsPerPage: ResultsPerPage,
        });
      }}
      setLoadingRows={setLoadingRows}
    />
  );
};
