import React, { useCallback, useEffect, useMemo } from 'react';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useGetData } from '@frontend/applications/TermsApp/hooks/useGetData';
import { IAgreementIteration } from '@frontend/applications/TermsApp/types/IAgreementIteration';
import { orderBy } from 'lodash';
import { format } from 'date-fns';
import {
  Avatar, Button, Col, message, Popover, Row, Space, Tag, Typography, Tooltip,
} from '@revfluence/fresh';

import { ClockRotateLeftIcon, LinkIcon } from '@revfluence/fresh-icons/regular/esm';
import { GetAllPrograms_programs } from '@frontend/app/queries/types/GetAllPrograms';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { useCopyToClipboard } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import styles from './BriefsAgreement.scss';

const PLACEHOLDER_IMG_URL = 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png';

interface IBriefsAgreementProps {
  agreement: IAgreement;
  onViewIteration: (iterationId: number) => void;
  onViewBasicTerms: (backendServerPostProjectId: string, programId: number, agreementId: string) => void;
  program: GetAllPrograms_programs;
}

export const BriefsAgreement: React.FC<IBriefsAgreementProps> = ({
 agreement, onViewIteration, onViewBasicTerms, program,
}) => {
  const { copyToClipboard } = useCopyToClipboard();

  const { showMessage, showGenericErrorMessage } = useMessagingContext();
  const { backendServerApiEndpoint, memberId } = useApplication();
  // TODO: Update to use GRAPHQL
  const { data: iterations, error: iterationError } = useGetData<IAgreementIteration[]>(
    `${backendServerApiEndpoint}/agreements/${agreement?.id}/iterations?member_id=${memberId}`,
  );
  useEffect(() => {
    if (iterationError) {
      message.error({
        content: "We're very sorry, there was a problem fetching this member's agreement history. "
          + 'If this issue persists, please reach out to us at help@aspireiq.com',
        icon: <TriangleExclamationIcon />,
      });
    }
  }, [iterationError]);
  const previousVersions = useMemo(() => {
    if (!iterations) {
      return [];
    }

    const sortedIterations = orderBy(iterations, ['created_at'], ['desc']);

    return sortedIterations.map((iteration, index) => ({
      id: iteration.id,
      text: `Version ${sortedIterations.length - index}: `
        + `Created at ${format(new Date(iteration.created_at * 1000), 'MMM dd, yyyy')}`,
      raw: iteration,
    }));
  }, [iterations]);

  const viewBriefs = useCallback(
    (iterationId?: number) => {
      if (agreement.type !== 'basic') {
        onViewIteration(iterationId || agreement.latest_iteration_id);
      } else {
        onViewBasicTerms(
          agreement.project_id.toString(),
          agreement.program_id,
          agreement.id.toString(),
        );
      }
    },
    [agreement, onViewIteration, onViewBasicTerms],
  );

  const copyTermsLink = useCallback(() => {
    copyToClipboard({
      value: agreement.project_link,
      onSuccess: () => {
        showMessage({
          type: 'success',
          content: 'Brief link copied!',
        });
      },
      onFailure: () => {
        showGenericErrorMessage();
      },
    });
  }, [
    copyToClipboard, agreement, showMessage, showGenericErrorMessage,
  ]);

  const titleText = program ? program.title : agreement.campaign_name;
  const canceledDate = format(new Date(agreement.canceled_ts * 1000), 'MMM dd, yyyy');
  const statusTags = {
    agreed: <Tag color="success">{`Agreed on ${format(new Date(agreement.agreed_ts * 1000), 'MMM dd, yyyy')}`}</Tag>,
    invalid: <Tag color="error">Invalid</Tag>,
    publisher_modified: <Tag color="warning">Review Edits</Tag>,
    manager_modified: <Tag color="processing">Waiting for Agreement</Tag>,
    canceled: <Tag color="error">{`Canceled on ${canceledDate}`}</Tag>,
  };
  return (
    <div className={styles.BriefsAgreement}>
      <Row align="middle" justify="space-between" className={styles.BriefsContainer}>
        <Col span={1}>
          <Avatar shape="square" size={40} src={program ? program.splashImageUrl : PLACEHOLDER_IMG_URL} />
        </Col>
        <Col span={6}>
          <span className={styles.TitleText}>
            {titleText.length > 50 ? `${titleText.substring(0, 50)}...` : titleText}
          </span>
        </Col>

        <Col span={4}>
          <Row className={styles.FieldHeading}>Sent on</Row>
          <Row>{format(new Date(agreement.created_at * 1000), 'MMM dd, yyyy')}</Row>
        </Col>
        <Col span={4}>
          <Row className={styles.FieldHeading}>Status</Row>
          <Row>{statusTags[agreement.status]}</Row>
        </Col>
        <Col span={6} className={styles.ButtonsColumn}>
          <Space size="middle" align="center">
            {previousVersions.length > 1
              && (
              <Popover
                style={{ borderBottom: 0 }}
                title="Previous Versions"
                content={(
                  <Space direction="vertical" size={0}>
                    {previousVersions.map((version) => (
                      <Typography.Link key={version.id} onClick={() => viewBriefs(version.id)}>
                        {version.text}
                      </Typography.Link>
                    ))}
                  </Space>
                )}
                placement="left"
                trigger="click"
              >
                <Button type="link" className={styles.HistoryButton} icon={<ClockRotateLeftIcon />} />
              </Popover>
)}
            {agreement.project_link && (

            <Tooltip
              title="Copy link for creator"
            >
              <Button
                type="link"
                onClick={() => copyTermsLink()}
                className={styles.HistoryButton}
                icon={<LinkIcon />}
              />
            </Tooltip>
)}
            <Button onClick={() => viewBriefs()}>View Brief</Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};
