import * as React from 'react';
import { isEmpty, isFunction, isNumber, lowerCase, trim } from 'lodash';

import {
  Toast, IToastRefHandles, CreatorList, useInviteContext,
} from '@components';
import { useDemoAccountFlag } from '@frontend/app/hooks';

import {
  IExternalVariables,
  ISearchResultsProps,
  ISearchResults,
  CreatorSearchVersion,
  SearchTermType,
} from './models';

import { termTypeLabel } from './TextSearchFilters/IQUsernameKeywordsFilter';

import { IgdmInviteBanner } from '@frontend/utils';

import styles from './SearchResults.scss';

const { useEffect, useRef } = React;

const PAGE_SIZE = 20;

interface ICreatorListTitleProps {
  searchedMentions?: string;
  isLoadingFirstPage?: boolean;
  isImageSearch?: boolean;
  isFeaturedSearch?: boolean;
  resultsCount?: number;
  version: CreatorSearchVersion;
  termType: SearchTermType;
}

const CreatorListTitle: React.FunctionComponent<ICreatorListTitleProps> = React.memo((props) => {
  if (props.isLoadingFirstPage) {
    return null;
  }

  const getResultsContent = () => {
    const parseResultCount = () => {
      const count = props.resultsCount;

      if (isNumber(count) && count > 0) {
        if (count > 1000) return '1000+';
        return Number(count);
      }
      return 'No';
    };

    const resultsCount = parseResultCount();

    const subject = `creator${resultsCount === 1 ? '' : 's'}`;
    let predicate = props.searchedMentions ? `that mentioned ${props.searchedMentions} found` : ' found';

    if (
      props.version == CreatorSearchVersion.v2
      && trim(props.searchedMentions)
    ) {
      const wereVerb = resultsCount === 1 ? 'was' : 'were';
      predicate = `${wereVerb} found for "${props.searchedMentions}" ${lowerCase(termTypeLabel[props.termType])}`;
    }

    return `${resultsCount === 1 ? 'One' : resultsCount.toLocaleString()} ${subject} ${predicate} `;
  }

  return (
    <div className={styles.CreatorListTitle}>
      {(() => {
        if (props.isImageSearch) {
          return 'Showing creators that match your image';
        } else if (props.isFeaturedSearch) {
          return 'Sample creators for your project';
        }

        return getResultsContent();
      })()}
    </div>
  );
});

const EmptyMessage = React.memo(() => (
  <div className={styles.EmptyMessage}>
    <div className={styles.title}>No Creators Found</div>
    <div>Try a different search or relax your filters to see more creators!</div>
  </div>
));

export interface ISearchResultsComponentProps extends IExternalVariables, ISearchResultsProps, ISearchResults {
  searchedMentions?: string;
  onSelectPage?(pageNumber: number, version?: CreatorSearchVersion);
  fetchNextPage?(version?: CreatorSearchVersion);
}

export const SearchResults: React.FunctionComponent<ISearchResultsComponentProps> = React.memo((props) => {
  const {
    selectedSocialAccounts,
    updatedSelectedSocialAccounts,
  } = useInviteContext();

  const { shouldShowDemoFeatures } = useDemoAccountFlag();

  const toastRef = useRef<IToastRefHandles>();

  useEffect(() => {
    if (props.error && toastRef.current) {
      toastRef.current.showMessage({
        content: props.error.message,
        type: 'error',
      });
    }
  }, [props, props.error]);

  const handleSelectPage = (pageNumber: number) => {
    updatedSelectedSocialAccounts([]);
    if (isFunction(props.onSelectPage)) {
      props.onSelectPage(pageNumber - 1);
    }
  };

  return (
    <div className={styles.CreatorListWrapper}>
      <IgdmInviteBanner
        paddings={[24, -4]}
        ctaSource="creatorSearch"
      />
      <CreatorList
        classNames={[styles.SearchResults]}
        apiEndpoint={props.apiEndpoint}
        clientId={props.clientId}
        brandId={props.brand && props.brand.id}
        campaign={props.campaign}
        org={props.organization}
        searchedMentions={props.searchedMentions}
        title={(
          <CreatorListTitle
            isLoadingFirstPage={props.isLoading && isEmpty(props.socialAccounts)}
            isFeaturedSearch={props.isFeaturedSearch}
            isImageSearch={props.isImageSearch}
            resultsCount={props.resultsCount}
            searchedMentions={props.searchedMentions}
            version={props.version}
            termType={props.fetchedSearchTermType}
          />
        )}
        emptyMessage={<EmptyMessage />}
        loadingStatus={{
          isLoading: props.isLoading,
        }}
        initialSocialAccounts={props.socialAccounts}
        isQa={props.isQa}
        $state={props.$state}
        sendBulkOffer={props.sendBulkOffer}
        exportCsv={props.exportCsv}
        reportAsIncorrect={props.reportAsIncorrect}
        showPagination={!!props.resultsCount}
        onSelectPage={handleSelectPage}
        pageCount={props.resultsCount > 10000 ? 500 : Math.ceil(props.resultsCount / PAGE_SIZE)}
        selectedPage={props.currentPage + 1}
        onCreatorSelected={props.onCreatorSelected}
        selectedSocialAccountId={props.selectedSocialAccountId}
        showCreateFeatures={props.showCreateFeatures}
        selectedSocialAccounts={selectedSocialAccounts}
        onSelectedAccountsChange={updatedSelectedSocialAccounts}
        reloadAccountDetails
        shouldShowDemoFeatures={shouldShowDemoFeatures}
        showRelevantPostImage={props.isImageSearchSelected}
      />
      <Toast useFresh ref={toastRef} />
    </div>
  );
});
