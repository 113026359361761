import gql from 'graphql-tag';

export const UPDATE_KLAVIYO_SYNC_SETTINGS = gql`
  mutation UpdateKlaviyoSyncSettings($config: KlaviyoSyncSettings!) {
    updateKlaviyoSyncSettings(config: $config) {
      config {
        automatedSync
        syncAllMembers
        groups
        projects
      }
    }
  }
`;
