import * as React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import styles from './IFrame.scss';

const { useState, useEffect } = React;

export interface IFrameProps {
  children?: React.ReactNode;
  className?: string;
  links?: string;
  scripts?: string;
  styles?: string;
}

const defaultProps: IFrameProps = {
  links: 'true',
  scripts: 'true',
  styles: 'true',
};

const copyElements = (styles: NodeListOf<HTMLElement>, target: Window) => {
  if (styles.length) {
    styles.forEach((element: HTMLElement) => {
      target.document.head.appendChild(element.cloneNode(true));
    });
  }
};

export const IFrame: React.FC<IFrameProps> = React.memo(({ children, ...props }) => {
  const [contentWindow, setContentWindow] = useState(null);
  const [iframeBody, setIframeBody] = useState(null);
  const options = { ...defaultProps, ...props };

  const handleLoad = (e) => {
    if (e.target.contentDocument) {
      setIframeBody(e.target.contentDocument.body);
    }
    setContentWindow(e.target.contentWindow);
  };

  useEffect(() => {
    if (!contentWindow) {
      return;
    }

    const win: Window = contentWindow;

    if (options.styles === 'true') {
      copyElements(win.parent.document.querySelectorAll('style'), win);
    }

    if (options.links === 'true') {
      copyElements(win.parent.document.querySelectorAll('link'), win);
    }

    if (options.scripts === 'true') {
      copyElements(win.parent.document.querySelectorAll('script'), win);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentWindow]);

  return (
    <iframe
      srcDoc="<!DOCTYPE html>"
      {...options}
      className={cx(styles.IFrame, options.className)}
      onLoad={handleLoad}
    >
      {iframeBody && createPortal(children, iframeBody)}
    </iframe>
  );
});

IFrame.displayName = 'IFrame';
