import { IOnboardingTemplateConfigProperties } from './OnboardingTemplateConfigProperties';

export interface IPublicProgramInfo {
  id: number;
  title: string;
  clientId: string;
  splashImageUrl: string;
  onboardingTemplateConfig?: IOnboardingTemplateConfigProperties;
  ownerEmail?: string;
  specKey: string;
}
