import * as React from 'react';
import cx from 'classnames';
import {
  Image,
  List,
  Typography,
} from '@revfluence/fresh';
import { ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import { useQueryParams } from '@frontend/app/hooks';

import styles from './ContentGuidelinesProjects.module.scss';

export interface IProject {
  id: number;
  title: string;
  splashImageUrl: string;
}

interface IProps {
  projects: IProject[];
  selectedProject: number;
  showActions?: boolean;
  title?: string;
  onSelectProject : (projectId: number) => void;
}

const { Text } = Typography;
const {
  useCallback,
  createRef,
  useMemo,
  useEffect,
} = React;

const {
  Item,
  Item: { Meta },
} = List;

const { ASSETS } = process.env;
const defaultImageSrc = `${ASSETS}/content_image_placeholder.png`;

const ContentGuidelinesProjects: React.FC<IProps> = React.memo((props) => {
  const {
    projects,
    selectedProject,
    title,
    showActions,
    onSelectProject,
  } = props;
  const query = useQueryParams();

  const projectsRef = useMemo(
    () => projects.reduce(
      (acc, value) => {
        acc[value.id] = createRef();
        return acc;
      },
      {},
    ),
    [projects],
  );

  useEffect(() => {
    const projectId = +query.get('projectId');
    if (
      projectId
      && projectId === selectedProject
      && projects.length > 0
      && projectsRef[projectId]?.current
    ) {
      setTimeout(() => projectsRef[projectId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      }));
    }
  }, [
    selectedProject,
    query,
    projects,
    projectsRef,
  ]);

  const renderProjectItem = useCallback((project) => (
    <div
      key={project.id}
      ref={projectsRef[project.id]}
    >
      <Item
        className={cx(
          styles.projectListItem,
          { [styles.selectedItem]: project.id === selectedProject },
        )}
        actions={showActions
          ? [<ChevronRightIcon key={`chevronRightIcon-${project.id}`} />]
          : []}
        onClick={() => onSelectProject(project.id)}
      >
        <Meta
          title={project.title}
          avatar={(
            <Image
              className={styles.projectListImage}
              src={project.splashImageUrl}
              width={46}
              height={46}
              fallback={defaultImageSrc}
              preview={false}
            />
          )}
        />
      </Item>
    </div>
  ), [
    selectedProject,
    showActions,
    onSelectProject,
    projectsRef,
  ]);

  return (
    <div className={styles.projectListContainer}>
      { title && (
        <div className={styles.projectListHeader}>
          <Text strong>
            {title}
          </Text>
        </div>
      )}
      <List
        split={false}
        dataSource={projects}
        renderItem={renderProjectItem}
        className={styles.ContentGuidelinesProjects}
      />
    </div>
  );
});

export default ContentGuidelinesProjects;
ContentGuidelinesProjects.displayName = 'ContentGuidelinesProjects';
