/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1iq64_157 {
  display: flex;
}

._justify-content-space-between_1iq64_161 {
  justify-content: space-between;
}

._tabular-nums_1iq64_165 {
  font-variant-numeric: tabular-nums;
}

._engageList_1iq64_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1iq64_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._engageList_1iq64_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._engageList_1iq64_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._engageList_1iq64_169 ::-webkit-scrollbar-track, ._engageList_1iq64_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._engageList_1iq64_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._engageList_1iq64_169 {
  margin: 0.75rem 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
}
._engageList_1iq64_169 ._accoutLink_1iq64_213 {
  color: #1c1c1c;
  text-decoration: none;
}
._engageList_1iq64_169 ._engageItem_1iq64_217 {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
}
._engageList_1iq64_169 ._engageAmount_1iq64_223 {
  color: #1c1c1c;
  text-decoration: none;
}
._engageList_1iq64_169 ._avatarImg_1iq64_227 {
  max-width: 100%;
  height: 45px;
  overflow-x: hidden;
  width: 100%;
}
._engageList_1iq64_169 ._avatarImg_1iq64_227:hover {
  opacity: 0.9;
}