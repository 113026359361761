import * as React from 'react';
import * as qs from 'qs';
import cx from 'classnames';

import { backendServerWebEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { LoadSpinner } from '@components';

import styles from './CreateAppContainer.scss';

const { useState, useEffect } = React;

interface ICreateAppContainerProps {
  applicationId: string;
  token: string;
  extraParams?: Record<string, string | number>;
  className?: string;
}

export const CreateAppContainer: React.FC<ICreateAppContainerProps> = ({
  applicationId,
  token,
  extraParams,
  className,
}) => {
  const [loading, setLoading] = useState(true);

  // This variable allows us to show a link to pop the test environment in a new tab. We cannot
  // show test environment directly in iframe for now due to issues with loading cross-domain
  // iframe (and test environs for create are on appspot.com)
  const isTestEnvironment = backendServerWebEndpoint().includes('appspot.com');
  let url = `${backendServerWebEndpoint()}app/client/app_login/${applicationId}?token=${token}`;
  if (extraParams) {
    url += `&${qs.stringify(extraParams)}`;
  }

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.data && event.data.msgType === 'ready') {
        setLoading(false);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [setLoading]);

  return (
    <>
      {isTestEnvironment ? (
        <a href={url} target="_blank">Go to Create Test Environment</a>
      ) : (
        <>
          {loading && <LoadSpinner className={styles.LoadingSpinner} centered={false} />}
          <iframe
            className={cx(styles.CreateAppContainer, className)}
            src={url}
            allow={`clipboard-write ${backendServerWebEndpoint()}`}
          />
        </>
      )}
    </>
  );
};
