import * as React from 'react';
import cx from 'classnames';
import {
  SubmitButton, Button,
} from '@components';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useFetchContractDownloadData } from '../useFetchContracts';

import styles from './ContractDownload.scss';

const downloadIcon = require('@frontend/app/assets/svgs/download_blue.svg');

const { useState } = React;
interface IProps {
  contractId: string;
  className?: string;
}

export const ContractDownload: React.FunctionComponent<IProps> = (props) => {
  const { backendServerApiEndpoint, clientId } = useApplication();
  const [contractIdToDownload, setContractIdToDownload] = useState<string>('');

  const skip = !contractIdToDownload || contractIdToDownload !== props.contractId;

  const { loading, data } = useFetchContractDownloadData(
    `${backendServerApiEndpoint}/contract/${contractIdToDownload}/download`,
    clientId,
    skip,
);

  const getDownloadLink = () => {
    setContractIdToDownload(props.contractId);
  };

  return (
    <div className={cx(styles.ContractDownload, styles[props.className])}>
      {(data && !skip)
        ? (
          <a href={data.file_url} target="_blank">
            <Button label="Download" />
          </a>
)
        : (
          <SubmitButton
            label=""
            theme="light"
            border={false}
            submittingLabel=""
            isSubmitting={loading && !skip}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            icon={(<img src={downloadIcon} className={(styles as any).signIcon} />)}
            onClick={getDownloadLink}
          />
)}
    </div>
  );
};
