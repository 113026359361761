import { isUndefined } from 'lodash';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { GET_PREDEFINED_SEGMENTS_FOR_COMMUNITY } from '../queries';
import {
  PredefinedSegmentsQuery,
  PredefinedSegmentsQueryVariables,
} from '../queries/types/PredefinedSegmentsQuery';

type IOptions = QueryHookOptions<PredefinedSegmentsQuery, PredefinedSegmentsQueryVariables>;

export const usePredefinedSegmentsQuery = (vars: PredefinedSegmentsQueryVariables = {}, options: IOptions = {}) => useQuery<PredefinedSegmentsQuery, PredefinedSegmentsQueryVariables>(GET_PREDEFINED_SEGMENTS_FOR_COMMUNITY, {
    ...options,
    variables: vars,
    skip: options.skip || (isUndefined(vars.communityId) && isUndefined(vars.sourcingGroupId)),
  });
