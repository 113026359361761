import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_TASKS_QUERY } from '@frontend/app/queries';
import {
  GetAllTasksQuery,
  GetAllTasksQueryVariables,
} from '@frontend/app/queries/types/GetAllTasksQuery';

type IOptions = QueryHookOptions<GetAllTasksQuery, GetAllTasksQueryVariables>;

export const useGetAllTasksQuery = (options: IOptions = {}) => (
  useQuery<GetAllTasksQuery, GetAllTasksQueryVariables>(GET_ALL_TASKS_QUERY, options)
);
