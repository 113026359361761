import * as React from 'react';

import {
  Space,
  Checkbox,
  Typography,
  Alert,
} from '@revfluence/fresh';
import { WhitelistingLabel } from '../../types/CollaborationDetails';
import { mappedWhitelistingLabel } from '../../utils';

const { Title, Text, Link } = Typography;

interface IProps {
  showNewAlert?: boolean;
  whitelistingLabel: WhitelistingLabel;
  hasSelectedInstagramStories: boolean;
  toggleInstagramInsights: boolean;
  toggleInfluencerWhitelist: boolean;
  toggleInfluencerBrandedContent: boolean;
  onToggleInstagramInsights: (toggleInstagramInsights: boolean) => void;
}

const InstagramInsights: React.FC<IProps> = React.memo((props) => {
  const {
    showNewAlert,
    whitelistingLabel,
    hasSelectedInstagramStories,
    toggleInstagramInsights,
    toggleInfluencerWhitelist,
    toggleInfluencerBrandedContent,
    onToggleInstagramInsights,
  } = props;

  const isCheckBoxDisabled = toggleInfluencerWhitelist || hasSelectedInstagramStories || toggleInfluencerBrandedContent;
  const showWhiteListAlert = toggleInstagramInsights && toggleInfluencerWhitelist;
  const showBrandedContentAlert = toggleInfluencerBrandedContent;

  const mappedLabel = React.useMemo(() => mappedWhitelistingLabel(whitelistingLabel), [whitelistingLabel]);

  return (
    <Space direction="vertical">
      <Title level={4}>Influencer Insights</Title>
      <Text>
        In order to receive the most data about posts, instagram insights can be required for this collab.
        <Link
          href="https://intercom.help/aspireiq_elevate/en/articles/5163902-instagram-insights-faq-for-brands"
          target="_blank"
        >
          {' Learn more about Insights'}
        </Link>
      </Text>
      <Checkbox
        disabled={isCheckBoxDisabled}
        onChange={(event) => onToggleInstagramInsights(event.target.checked)}
        checked={toggleInstagramInsights}
      >
        <Text>IG Insights required</Text>
      </Checkbox>
      {
        !showNewAlert && showWhiteListAlert && (
          <Alert
            message={`This section is disabled because you are already asking the creator
            ${' '}
            for ${mappedLabel} permissions through your business account.
            When doing so, the creator will also be required to authorize their Insights.
            If you only wish to ask for Insights authorization, uncheck the "${mappedLabel}" box above.`}
            type="info"
            showIcon={false}
          />
        )
      }
      {
        !showNewAlert && showBrandedContentAlert && (
          <Alert
            message={`This section is disabled because the Branded Content permission is selected for this
            ${' '}
            collaboration. When doing so, the creator will also be required to authorize their Insights.
            ${' '}
            If you only wish to ask for Insights authorization, uncheck the “Branded Content” box above.`}
            type="info"
            showIcon={false}
          />
        )
      }
      {
        showNewAlert && (showWhiteListAlert || showBrandedContentAlert) && (
          <Alert
            message={(
              <>
                Instagram Insights access is required when requesting either Instagram Branded Content
                {' '}
                or Allowlisting permissions. In order to edit your request for Instagram Insights,
                {' '}
                you must deselect your request for them.
              </>
            )}
            type="info"
            showIcon={false}
          />
        )
      }
    </Space>
  );
});

InstagramInsights.displayName = 'InstagramInsights';

InstagramInsights.defaultProps = {
  showNewAlert: false,
};

export default InstagramInsights;
