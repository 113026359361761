import * as React from 'react';
import { Button, Tooltip } from '@revfluence/fresh';
import { MessageType } from '@frontend/app/types/globalTypes';
import { useReactiveVar } from '@apollo/client';
import { expandedMessagesVar } from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { RectangleHistoryIcon } from '@revfluence/fresh-icons/regular/esm';
import { GetThreadQuery_thread_messages } from '@frontend/app/queries/types/GetThreadQuery';
import {
  GetThreadQuery_thread as IThread,
} from '@frontend/app/queries/types/GetThreadQuery';
import { map } from 'lodash';
import { ThreadSingleActionButtons } from '../../ThreadSingleActionButtons';

const { memo } = React;

type IProps = {
  thread: IThread;
  messages: GetThreadQuery_thread_messages[];
};

const MessageActions = memo((props: IProps) => {
  const {
    messages,
    thread,
  } = props;

  const expandedMessages = useReactiveVar(expandedMessagesVar);
  const isNotAllMessageExpanded = expandedMessages.length !== messages.length;

  const expandActionTitle = isNotAllMessageExpanded ? 'Expand All Messages' : 'Collapse All Messages';

  const onExpandAction = () => {
    if (isNotAllMessageExpanded) {
      expandedMessagesVar(map(messages, 'id'));
    } else {
      expandedMessagesVar([]);
    }
  };

  if (thread?.type === MessageType.IGDM) return null;

  const collapseThreadButton = (
    <Tooltip title={expandActionTitle}>
      <Button
        size="small"
        type="text"
        onClick={() => onExpandAction()}
        icon={<RectangleHistoryIcon />}
      />
    </Tooltip>
  );

  return (
    <ThreadSingleActionButtons thread={thread} frameless>
      {collapseThreadButton}
    </ThreadSingleActionButtons>
  );
});

MessageActions.displayName = 'MessageActions';

export default MessageActions;
