import 'css-chunk:src/app/containers/BudgetReporting/BudgetReportingContainer/components/fiscalYearSettings/components/FiscalYears/FiscalYears.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_l2ddf_157",
  "justify-content-space-between": "_justify-content-space-between_l2ddf_161",
  "tabular-nums": "_tabular-nums_l2ddf_165",
  "fiscalYearFieldInfo": "_fiscalYearFieldInfo_l2ddf_178",
  "fiscalYearTitle": "_fiscalYearTitle_l2ddf_184",
  "fiscalYearsContainer": "_fiscalYearsContainer_l2ddf_190",
  "fiscalYearsAndAddButton": "_fiscalYearsAndAddButton_l2ddf_199",
  "addFiscalYearButton": "_addFiscalYearButton_l2ddf_206",
  "fiscalYearRow": "_fiscalYearRow_l2ddf_210",
  "field": "_field_l2ddf_220",
  "fiscalYearName": "_fiscalYearName_l2ddf_226",
  "referenceText": "_referenceText_l2ddf_233",
  "fieldLabel": "_fieldLabel_l2ddf_239",
  "fiscalYearDate": "_fiscalYearDate_l2ddf_245",
  "removeFiscalYear": "_removeFiscalYear_l2ddf_251",
  "archiveFiscalYear": "_archiveFiscalYear_l2ddf_257",
  "fiscalYearContainer": "_fiscalYearContainer_l2ddf_263",
  "show": "_show_l2ddf_1"
};