/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ColumnConfig_15k0n_32 {
  position: relative;
}
._ColumnConfig_15k0n_32 ._button_15k0n_35 {
  min-width: none;
  width: 36px;
  height: 36px;
}
._ColumnConfig_15k0n_32 ._button_15k0n_35:hover {
  border-color: #499dff;
}
._ColumnConfig_15k0n_32 ._button_15k0n_35._active_15k0n_43 {
  color: #499dff;
}

._ColumnsFilledIcon_15k0n_47 {
  margin-left: 4px;
}