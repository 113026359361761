._row_1ldci_1 {
  width: 100%;
}
._row_1ldci_1 ._actions_1ldci_4 {
  display: flex;
  align-items: center;
}
._row_1ldci_1 ._edit_1ldci_8 {
  transform: translateY(-2px);
  opacity: 0;
  z-index: -1;
}
._row_1ldci_1 ._value_1ldci_13 {
  font-size: 12px;
  line-height: 24px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
._row_1ldci_1:hover ._edit_1ldci_8 {
  height: 18px;
  opacity: 1;
  z-index: 0;
}
._row_1ldci_1 ._copyBtn_1ldci_25 {
  display: block;
}