import * as React from 'react';
import cx from 'classnames';
import {
  isNil,
  size,
} from 'lodash';
import {
  Space,
  Modal,
  Button,
  Row,
} from '@revfluence/fresh';
import {
  useGetContentGuidelineTemplatesForProject,
  useGetAllProjectsWithContentGuidelinesQuery,
} from '@frontend/app/hooks';
import
  ContentGuidelinesListHeader
  // eslint-disable-next-line max-len
  from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesList/ContentGuidelinesListHeader/ContentGuidelinesListHeader';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
} from '@frontend/app/queries/types/TermsConfigsQuery';

import GuidelinesListEmpty
  from '@frontend/applications/TermsApp/components/ContentGuidelines/GuidelinesListEmpty/GuidelinesListEmpty';
import GuidelinesListEmptyNoProject
  from '@frontend/applications/TermsApp/components/ContentGuidelines/GuidelinesListEmpty/GuidelinesListEmptyNoProject';
import GuidelinesListItems
  // eslint-disable-next-line max-len
  from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesList/GuidelinesListItems/GuidelinesListItems';
import { LoadingList }
  from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesLoading/ContentGuidelinesLoading';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import {
  ISortableGuideline,
  IContentGuidelineInstruction,
  IContentGuidelineTitle,
} from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';
import {
  TemplateType,
  Step,
} from '@frontend/applications/TermsApp/components/ContentGuidelines/types/state';
import
  ContentGuidelinesProjects,
  { IProject }
from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesProjects/ContentGuidelinesProjects';
import styles from './ContentGuidelinesList.module.scss';

const { useMemo } = React;

interface IProps {
  contentGuidelines: TContentGuideline[];
  premadeTemplates: TContentGuideline[];
  blankTemplates: TContentGuideline[];
  isTemplatesModalVisible: boolean;
  isEditMode: boolean;
  templateType: TemplateType;
  selectedProject: number;
  selectedOtherProject: number;
  selectedProjectTitle: string;
  projects: IProject[];
  step: Step,
  defaultActiveKey: number;
  onSelectProject: (projectId: number) => void;
  onSelectOtherProject: (otherProjectId: number) => void;
  onDuplicateContentGuideline: (contentGuideline: TContentGuideline) => void;
  onDeleteContentGuideline: (contentGuideline: TContentGuideline) => void;
  onSaveContentGuideline: (contentGuideline: TContentGuideline) => void;
  onUpdateContentGuidelineInstruction: (contentGuidelineInstruction: IContentGuidelineInstruction) => void;
  onSortContentGuideline: (
    id: number,
    sortableGuideline: ISortableGuideline,
  ) => void;
  onAddContentGuidelineAttachment: (
    attachment: Attachment,
    id: number,
  ) => void;
  onDeleteContentGuidelineAttachment: (
    attachments: Array<Attachment>,
    id: number,
  ) => void;
  onSelectTemplateType: (templateType: TemplateType) => void;
  onShowHideTemplatesModal: (isTemplatesModalVisible: boolean) => void;
  onNextStep: () => void;
  onPrevStep: () => void;
  onUpdateContentGuidelineLabel: (id: number, label: string) => void;
  onUpdateContentGuidelineActiveKey: (defaultActiveKey: number) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
}

const ContentGuidelinesList: React.FC<IProps> = React.memo((props) => {
  const {
    contentGuidelines,
    premadeTemplates,
    blankTemplates,
    isTemplatesModalVisible,
    isEditMode,
    selectedProject,
    selectedOtherProject,
    templateType,
    selectedProjectTitle,
    step,
    projects,
    defaultActiveKey,
    onSelectProject,
    onSelectOtherProject,
    onDuplicateContentGuideline,
    onSaveContentGuideline,
    onDeleteContentGuideline,
    onUpdateContentGuidelineInstruction,
    onSortContentGuideline,
    onAddContentGuidelineAttachment,
    onDeleteContentGuidelineAttachment,
    onSelectTemplateType,
    onShowHideTemplatesModal,
    onNextStep,
    onPrevStep,
    onUpdateContentGuidelineLabel,
    onUpdateContentGuidelineActiveKey,
    onUpdateContentGuidelineTitle,
  } = props;

  const {
    data,
    loading: loadingProjectsWithContentGuidelines,
  } = useGetAllProjectsWithContentGuidelinesQuery({
    skip: !(isTemplatesModalVisible && step === Step.SelectAnotherProject),
  });

  const {
    data: otherProjectGuidelinesData,
    loading: otherProjectGuidelinesLoading,
  } = useGetContentGuidelineTemplatesForProject({
    skip: !(isTemplatesModalVisible && !isNil(selectedOtherProject) && step === Step.SelectContentGuidelines),
    variables: {
      programId: selectedOtherProject,
    },
    fetchPolicy: 'no-cache',
  });

  const hasNoProjectSelected = isNil(selectedProject);
  const hasNoGuidelines = !hasNoProjectSelected && size(contentGuidelines) === 0;
  const shouldCenterContent = hasNoProjectSelected || hasNoGuidelines;
  const modalTitle = useMemo(() => {
    if (!isTemplatesModalVisible) {
      return '';
    }
    if ([TemplateType.BlankTemplates, TemplateType.PremadeTemplates].includes(templateType)) {
      return templateType === TemplateType.BlankTemplates ? 'Select Content Type' : 'Premade Content Guidelines';
    }
    return step === Step.SelectAnotherProject
    ? 'Select from another project'
    : projects.find((project: IProject) => project.id === selectedOtherProject)?.title;
  }, [
    isTemplatesModalVisible,
    templateType,
    projects,
    selectedOtherProject,
    step,
  ]);

  const modalFooter = useMemo(() => {
    if (
      !isTemplatesModalVisible
      || [TemplateType.BlankTemplates, TemplateType.PremadeTemplates].includes(templateType)
    ) {
      return null;
    }
    return step === Step.SelectAnotherProject
    ? [
      <Button key="back" onClick={() => onShowHideTemplatesModal(false)}>
        Cancel
      </Button>,
      <Button key="next" type="primary" onClick={onNextStep} disabled={isNil(selectedOtherProject)}>
        Next
      </Button>,
      ]
    : [
      <Button key="back" onClick={onPrevStep}>
        Back
      </Button>,
    ];
  }, [
    isTemplatesModalVisible,
    templateType,
    step,
    selectedOtherProject,
    onShowHideTemplatesModal,
    onNextStep,
    onPrevStep,
  ]);

  const shouldListSectionsOnly = useMemo(() => (
    templateType === TemplateType.BlankTemplates
  ), [templateType]);

  return (
    <>
      <ContentGuidelinesListHeader
        selectedProject={selectedProject}
        onSelectTemplateType={onSelectTemplateType}
        selectedProjectTitle={selectedProjectTitle}
        onSelectProject={onSelectProject}
      />
      <div
        className={cx(
          styles.ContentGuidelinesList,
          {
            [styles.center]: shouldCenterContent,
            [styles.start]: !shouldCenterContent,
          },
        )}
      >
        <Space
          direction="vertical"
          style={{
            flex: '1',
            padding: '24px',
          }}
        >
          { hasNoProjectSelected && <GuidelinesListEmptyNoProject />}
          { hasNoGuidelines && <GuidelinesListEmpty /> }
          {
            (!hasNoProjectSelected && !hasNoGuidelines) && (
              <GuidelinesListItems
                isReadOnly={false}
                templateType={templateType}
                contentGuidelines={contentGuidelines}
                premadeTemplates={premadeTemplates}
                otherProjectContentGuidelines={[]}
                blankTemplates={blankTemplates}
                defaultActiveKey={defaultActiveKey}
                isEditMode={isEditMode}
                onDuplicateContentGuideline={onDuplicateContentGuideline}
                onDeleteContentGuideline={onDeleteContentGuideline}
                onSaveContentGuideline={onSaveContentGuideline}
                onUpdateContentGuidelineInstruction={onUpdateContentGuidelineInstruction}
                onSortContentGuideline={onSortContentGuideline}
                onAddContentGuidelineAttachment={onAddContentGuidelineAttachment}
                onDeleteContentGuidelineAttachment={onDeleteContentGuidelineAttachment}
                onUpdateContentGuidelineLabel={onUpdateContentGuidelineLabel}
                onUpdateContentGuidelineActiveKey={onUpdateContentGuidelineActiveKey}
                onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
              />
            )
          }
        </Space>
      </div>
      <Modal
        destroyOnClose
        title={modalTitle}
        visible={isTemplatesModalVisible}
        onCancel={() => onShowHideTemplatesModal(false)}
        footer={(
          <Row
            justify={
              step === Step.SelectContentGuidelines
                ? 'start'
                : 'end'
            }
          >
            {modalFooter}
          </Row>
        )}
        width={650}
        bodyStyle={{
          height: '490px',
          overflowY: 'scroll',
        }}
      >
        {
          step === Step.SelectAnotherProject
          && !loadingProjectsWithContentGuidelines
          && (
            <ContentGuidelinesProjects
              projects={data?.projects.filter((project: IProject) => project.id !== selectedProject)}
              selectedProject={selectedOtherProject}
              onSelectProject={onSelectOtherProject}
            />
          )
        }
        {
          (loadingProjectsWithContentGuidelines
          || otherProjectGuidelinesLoading)
          && <LoadingList />
        }
        {
          step === Step.SelectContentGuidelines
            && !otherProjectGuidelinesLoading
            && (
              <GuidelinesListItems
                isReadOnly
                shouldListSectionsOnly={shouldListSectionsOnly}
                templateType={templateType}
                contentGuidelines={contentGuidelines}
                premadeTemplates={premadeTemplates}
                blankTemplates={blankTemplates}
                defaultActiveKey={defaultActiveKey}
                isEditMode={isEditMode}
                otherProjectContentGuidelines={otherProjectGuidelinesData?.guidelines?.contentGuidelines}
                onDuplicateContentGuideline={onDuplicateContentGuideline}
                onDeleteContentGuideline={onDeleteContentGuideline}
                onSaveContentGuideline={onSaveContentGuideline}
                onUpdateContentGuidelineInstruction={onUpdateContentGuidelineInstruction}
                onSortContentGuideline={onSortContentGuideline}
                onAddContentGuidelineAttachment={onAddContentGuidelineAttachment}
                onDeleteContentGuidelineAttachment={onDeleteContentGuidelineAttachment}
                onUpdateContentGuidelineLabel={onUpdateContentGuidelineLabel}
                onUpdateContentGuidelineActiveKey={onUpdateContentGuidelineActiveKey}
                onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
              />
            )
        }
      </Modal>
    </>
  );
});

ContentGuidelinesList.displayName = 'ContentGuidelinesList';

export default ContentGuidelinesList;
