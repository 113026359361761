import * as React from 'react';
import { Link } from 'react-router-dom';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { TProject } from '@frontend/app/containers/Projects/types';
import { Button } from '@revfluence/fresh';

import { BaseStep } from './BaseStep';
import styles from './CreateProjectApplicationStep.scss';
import { isApplicationPageEditable } from '../../../utils';

const { useMemo } = React;

interface IProps {
  project: TProject;
}

const CreateProjectApplicationStepComponent: React.FC<IProps> = ({ project }) => {
  const addEvent = useEventContext();

  const linkLabel = useMemo(
    () => (isApplicationPageEditable(project) ? 'Edit' : 'Create'),
    [project],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLinkClick = () => {
    addEvent(
      EventName.FindCreatorsApplicationClick,
      {
        source: linkLabel,
      },
    );
  };

  const textContent = useMemo(
    () => (
      <p>
        Build a custom, branded application page for creators to easily apply to
        {' '}
        {project.title}
        .
        {' '}
        <a
          href="https://intercom.help/aspireiq_elevate/en/articles/5828756-tips-for-creating-an-eye-catching-application-page"
          target="_blank"
        >
          See best practices
        </a>
      </p>
    ),
    [project.title],
  );

  const actionContent = useMemo(() => (
    <Button
      type="primary"
      onClick={handleLinkClick}
    >
      <Link to={
      linkLabel === 'Create'
      ? 'settings/select_template'
      : 'settings/landing_page'
    }
      >
        {linkLabel}
      </Link>
    </Button>
    ), [linkLabel, handleLinkClick]);

  const iconContent = useMemo(() => (
    <img src="https://storage.googleapis.com/aspirex-static-files/find-creators/create_application.png" />
  ), []);

  return (
    <div className={styles.CreateProjectApplicationStep}>
      <BaseStep
        label="Step 1: Create Your Application Page"
        iconContent={iconContent}
        textContent={textContent}
        actionContent={actionContent}
      />
    </div>
  );
};

const CreateProjectApplicationStep = React.memo(CreateProjectApplicationStepComponent);

CreateProjectApplicationStep.displayName = 'CreateProjectApplicationStep';

export { CreateProjectApplicationStep };
