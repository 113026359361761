export interface IProgramLandingPageFieldSettings {
  fieldName: string;
  isHidden?: boolean;
}

export interface IProgramLandingPageProperties {
  accentColor?: string;
  primaryColor?: string;
  logoUrl?: string;
  brandImage1?: string;
  brandImage2?: string;
  brandImage3?: string;
  brandImage4?: string;
  brandImage5?: string;
  tagLine?: string;
  description?: string;
  perks?: ITextContent[];
  whoYouAre?: ITextContent[];
  joinText?: string;
  copyright?: string;
  email?: string;
  favicon?: string;
  fieldsSettings?: IProgramLandingPageFieldSettings[];
}

interface ITextContent {
  content: string;
}
