import * as React from 'react';
import Trend from '@frontend/applications/ReportsApp/components/Trend';
import { TTooltipProps } from '@revfluence/fresh';
import StatsComponent from '@frontend/applications/Shared/components/RefreshStatsComponent';
import Text from '@frontend/app/refresh-components/Text';

type SecondaryMetricsCardProps = {
  title: React.ReactNode;
  value: React.ReactNode;
  trendDirection: 'up' | 'down';
  trendColor: 'green' | 'red' | 'gray' | 'white';
  tooltip?: string | TTooltipProps;
  className?: string;
  prefix?: React.ReactNode;
};

const SecondaryMetricsCard: React.FC<SecondaryMetricsCardProps> = ({
  title,
  value,
  trendDirection,
  trendColor,
  tooltip = '',
  className,
  prefix,
}) => (
  <div className={`rounded-lg shadow-sm text-left ${className}`}>
    <StatsComponent
      title={(
        <div className="text-xs leading-tight font-semibold text-grey-2 py-2">{title}</div>
      )}
      valueRender={(
        <Text visualVariant="h4" className="text-primary-foreground flex">
          {prefix}
          {value}
          <StatsComponent
            valueRender={(
              <div className="px-2 py-1 rounded">
                <Trend direction={trendDirection} color={trendColor} />
              </div>
            )}
            tooltip={tooltip}
          />
        </Text>
      )}
      tooltip={tooltip}
    />
  </div>
);

export default SecondaryMetricsCard;
