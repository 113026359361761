import gql from 'graphql-tag';

export const GET_SHOPIFY_RESOURCE = gql`
  query ShopifyResourceForClient($clientId: String!) {
    shopifyResource:shopifyResourceForClient(clientId: $clientId) {
        id
        type
        userId
        scopes
        createdDate
        updatedDate
        metadata
        isAdmin
        adminResourceId
        clientId
        authProvider {
            userExternalId
            type
            userExternalDisplayId
            userRevokedAccess
            systemRevokedAccess
            isShared
            access
        }
    }
  }
`;
