import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';

interface IPendoInitializeParams {
  visitor?: {
    id: string;
    email?: string;
    full_name?: string;
  };
  account?: {
    id: string;
    name: string;
  };
}
declare global {
  interface Window {
    pendo: {
      initialize(params: IPendoInitializeParams): void;
    }
  }
}

const { useEffect } = React;

export function useInitPendo(): void {
  const { user, clientInfo } = useAuth();

  useEffect(() => {
    if (!window.pendo || window?.isUnderTest) {
      return;
    }
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    window.pendo.initialize({
      visitor: {
        id: user.sub,
        email: user.email,
        full_name: user.name,
        // role:         // Optional
      },

      account: {
        id: clientInfo.id,
        name: clientInfo.name,
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
      },
    });
  }, [clientInfo, user]);
}
