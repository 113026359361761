import {
 useMutation, MutationHookOptions,
} from '@apollo/client';

import { UPDATE_IMPORT_SYNC_SETTING } from '../queries';
import { CreateOrUpdateImportSyncSettings, CreateOrUpdateImportSyncSettingsVariables } from '../queries/types/CreateOrUpdateImportSyncSettings';

type IOptions = MutationHookOptions<CreateOrUpdateImportSyncSettings, CreateOrUpdateImportSyncSettingsVariables>;

export function useUpdateImportSyncSettings(options: IOptions = {}) {
  const [
    updateImportSyncSettings, {
    loading,
    data,
    error,
  }] = useMutation<CreateOrUpdateImportSyncSettings, CreateOrUpdateImportSyncSettingsVariables>(
    UPDATE_IMPORT_SYNC_SETTING,
    options,
  );

  return {
    updateImportSyncSettings,
    loading,
    clientConfig: data?.createOrUpdateImportSyncSettings,
    error,
  };
}
