import gql from 'graphql-tag';

import {
 CLIENT_ALLOY_METADATA_FRAGMENT, CLIENT_FRAGMENT, USER_FRAGMENT, USER_FRAGMENT_WITH_AUTH0,
} from './fragments';

export const GET_CURRENT_CLIENT_QUERY = gql`
  query GetCurrentClientQuery {
    client: getCurrentClient {
      ...ClientFragment
      users {
        ...UserFragmentWithAuth0
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${USER_FRAGMENT_WITH_AUTH0}
`;

export const SAVE_CURRENT_CLIENT_MUTATION = gql`
  mutation SaveCurrentClientMutation($client: ClientInput!) {
    client: saveCurrentClient(client: $client) {
      ...ClientFragment
      users {
        ...UserFragment
      }
    }
  }
  ${CLIENT_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const GET_ALLOY_METADATA_QUERY = gql`
  query GetAlloyMetadataQuery {
    metadata: getAlloyMetadata {
      ...ClientAlloyMetadataFragment
    }
  }
  ${CLIENT_ALLOY_METADATA_FRAGMENT}
`;

export const GET_ALLOY_WORKFLOWS_QUERY = gql`
  query GetAlloyWorkflowsQuery($integration: AlloyIntegrationType) {
    workflows: getAlloyWorkflows(integration: $integration)
  }
`;

export const GET_ALLOY_USER_TOKEN_QUERY = gql`
  query GetAlloyUserTokenQuery($userId: String!) {
    token: getAlloyUserToken(userId: $userId)
  }
`;

export const ACTIVATE_ALLOY_WORKFLOW_MUTATION = gql`
  mutation ActivateAlloyWorkflowMutation($workflowId: String!, $childWorkflowId: String!) {
    succeeded: activateAlloyWorkflow(workflowId: $workflowId, childWorkflowId: $childWorkflowId)
  }
`;

export const DEACTIVATE_ALLOY_WORKFLOW_MUTATION = gql`
  mutation DeactivateAlloyWorkflowMutation($workflowId: String!, $childWorkflowId: String!) {
    succeeded: deactivateAlloyWorkflow(workflowId: $workflowId, childWorkflowId: $childWorkflowId)
  }
`;

export const UPGRADE_CLIENT_MUTATION = gql`
  mutation UpgradeClientMutation($reason: String!) {
    succeeded: upgradeClient(reason: $reason)
  }
`;
