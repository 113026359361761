import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_BUDGETS_WITH_FISCAL_YEAR_QUERY } from '../queries/GetBudgetsWithFiscalYearQuery';
import {
  GetBudgetsWithFiscalYearQuery,
  GetBudgetsWithFiscalYearQueryVariables,
} from '../queries/types/GetBudgetsWithFiscalYearQuery';

type IOptions = QueryHookOptions<GetBudgetsWithFiscalYearQuery, GetBudgetsWithFiscalYearQueryVariables>;

export function useGetBudgetsWithFiscalYearQuery(options: IOptions = {}) {
  return useQuery<GetBudgetsWithFiscalYearQuery, GetBudgetsWithFiscalYearQueryVariables>(
    GET_BUDGETS_WITH_FISCAL_YEAR_QUERY,
    options,
  );
}
