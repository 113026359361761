import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import {
  UPDATE_BUSINESSES,
} from '@frontend/app/queries';

import {
  UpdateBusinesses,
  UpdateBusinessesVariables,
} from '@frontend/app/queries/types/UpdateBusinesses';

type IOptions = MutationHookOptions<UpdateBusinesses, UpdateBusinessesVariables>;

export const useUpdateBusinessesMutation = (options: IOptions = {}) => (
  useMutation<UpdateBusinesses>(UPDATE_BUSINESSES, options)
);

export type TUseUpdateBusinessesMutation = ReturnType<typeof useUpdateBusinessesMutation>;
