export const campaignIneligibleReason: {
  [identifer: string]: string;
} = {
  suspended: 'The creator has been suspended from Revfluence',
  network: `The creator's social networks do not match this campaign requirements`,
  min_followers: `The creator's reach is below your requirement`,
  min_engagement: `The creator's engagement is below your requirement`,
  max_engagement: `The creator's engagement is higher than your campaign budget/limits allow`,
  country: `The creator does not live within the countries you specified for this campaign`,
  state: `The creator does not live within the states you specified for this campaign`,
  city: `The creator does not live within the cities you specified for this campaign`,
  language: `The creator's primary language does not match your campaign`,
  will_not_advertise: `Our system has flagged this account's engagement as suspicious`,
  audience_gender: `The creator's audience gender demo does not match your requirements`,
  audience_country: `The creator's audience country demo does not match your requirements`,
  gender: `The creator's gender does not match your campaign requirements`,
  location: `The creator's location is too far from your target location`,
  qa: 'Cannot show QA account to non-QA campaign',
};
