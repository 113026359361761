import * as React from 'react';
import * as H from 'history';

import {
 cloneDeep,
 filter,
 get,
 includes,
 isArray,
 isEmpty,
 isEqual,
 isNil,
 map,
 set,
 some,
 omit,
 pick,
 uniq,
 values,
 reduce,
 isObject,
 each,
} from 'lodash';
import {
 useHistory,
 useLocation,
 useParams,
 useRouteMatch,
} from 'react-router-dom';
import * as qs from 'qs';

import {
 IBackendServerCampaignInput, ICampaignCategory, INetworkDetails, INetworkType, IProductType,
} from '@services/backend-server';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { GetCampaignByProjectIdQuery_campaign as ICampaign } from '@frontend/app/queries/types/GetCampaignByProjectIdQuery';

import {
  useGetCampaignByProjectId,
  useGetProgramById,
  useSaveProjectCampaign,
  useGetCurrentClient,
  useGetClientActiveMarketplaceProjects,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { networkSliders } from '../MarketplaceTabs/NetworkRestriction/model';
import { ProjectsRouteRoot } from '../../../constants';
import { CONTENT_TYPE, NETWORK_TYPE, TFieldValue } from './model';
import { isCampaignNeverPublished } from '../../../utils';

const {
 createContext,
 useContext,
 useEffect,
 useMemo,
 useState,
} = React;

interface IListMarketplaceContext {
  baseRoutePath: string;
  campaign: ICampaign;
  campaignInputData: IBackendServerCampaignInput;
  projectId: number;
  isDataEmpty: boolean;
  hasDataChanged: boolean;
  getFieldValue: (fieldKey: string, defaultValue?: TFieldValue) => TFieldValue;
  setFieldValue: (fieldKey: string, fieldValue: TFieldValue) => void;
  setHasBeenTouched: (isTouched: boolean) => void;
  submitData: () => Promise<void>;
  isSubmitting: boolean;
  isDrawerVisible: boolean;
  close: () => void;
  goBack: () => void;
  hasBeenSubmitted: boolean;
  hasBeenTouched: boolean;
  selectedNetworks: NETWORK_TYPE[];
  isEditingCampaign: boolean;
  isCampaignLoading: boolean;
  hasEmptyRequiredFields: (fields: string[]) => boolean;
}

const ListOnMarketplaceContext = createContext<IListMarketplaceContext>(null);

interface ListOnMarketplaceProviderProps {
  children: React.ReactElement;
  openFrom: H.Location<unknown>;
}

export interface IParams {
  projectId: string;
}

export const ListOnMarketplaceProvider: React.FC<ListOnMarketplaceProviderProps> = (props) => {
  const location = useLocation();
  const history = useHistory();

  const { projectId: projectIdString } = useParams<IParams>();
  const projectId = +projectIdString;
  const baseRoutePath = `${ProjectsRouteRoot}/${projectId}/marketplace`;

  const {
    children,
    openFrom,
  } = props;
  const addEvent = useEventContext();

  const match = useRouteMatch(baseRoutePath);
  const isDrawerVisible = useMemo(() => !!match, [match]);

  const [campaignInputData, setCampaignInputData] = useState<IBackendServerCampaignInput>({} as IBackendServerCampaignInput);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  const {
    showSuccessMessage,
    showError,
  } = useMessagingContext();

  const {
    data: {
      program: project = null,
    } = {},
  } = useGetProgramById({
    variables: {
      id: projectId,
    },
    skip: !projectId,
  });

  const {
    data: {
      campaign = null,
    } = {},
    loading: isCampaignLoading,
    refetch: refetchCampaign,
  } = useGetCampaignByProjectId(projectId, {
    skip: !projectId,
  });

  const {
    refetch: refetchActiveProjects,
  } = useGetClientActiveMarketplaceProjects();

  const {
    client = null,
  } = useGetCurrentClient();

  const clientHostname = useMemo(() => client?.hostname, [client]);

  const externalListingBaseUrl = useMemo(() => {
    const url = `${window?.location?.origin}/join/`;

    return clientHostname ? url.replace('community', clientHostname) : url;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, clientHostname]);

  const externalListingFullUrl = useMemo(() => {
    const landingPagePath = encodeURIComponent(project?.customLandingPagePath || '');
    const url = `${externalListingBaseUrl}${landingPagePath}`;

    const params: Record<string, string> = {
      utmSource: 'marketplace',
    };

    if (!clientHostname && client?.id) {
      params.clientId = client.id;
    }

    const search = qs.stringify(params);

    return `${url}?${search}`;
  }, [externalListingBaseUrl, client, clientHostname, project]);

  const isEditingCampaign = useMemo(() => !isNil(campaign) && (!!campaign.enabled_ts || !!campaign.disabled_ts), [campaign]);

  const defaultInputData = useMemo(() => {
    if (!isCampaignLoading && campaign) {
      const campaignData: IBackendServerCampaignInput = {
        ...pick(campaign, [
          'name',
          'splash_image_url',
          'summary',
          'offers_payment',
          'offers_product',
          'offers_commission',
          'offers_other',
        ]),
        external_listing_url: externalListingFullUrl,
        product_types: campaign?.product_types as IProductType[],
        network_types: campaign?.network_types as INetworkType[],
        accepted_place_ids: reduce(campaign?.accepted_place_ids, (acc, acceptedPlaceId) => {
          acc.push(omit(acceptedPlaceId, '__typename'));
          return acc;
        }, []),
        network_details: reduce(campaign?.network_details, (acc, networkDetails) => {
          const AdjustedNetworkDetails = omit(networkDetails, '__typename');
          AdjustedNetworkDetails.maximum_budget = AdjustedNetworkDetails.maximum_budget || 0;
          acc[networkDetails.network_type] = AdjustedNetworkDetails;
          return acc;
        }, {}) as INetworkDetails[],
      };

      return campaignData;
    }

    return {
      name: project?.title,
    };
  }, [
    project?.title,
    externalListingFullUrl,
    campaign,
    isCampaignLoading,
  ]);

  const [saveProjectCampaign, {
    loading: isSubmitting,
  }] = useSaveProjectCampaign({
    onCompleted: () => {
      const eventName = isEditingCampaign && !isCampaignNeverPublished(campaign)
      ? EventName.MarketplaceEditComplete
      : EventName.MarketplacePublishComplete;

      addEvent(
        eventName,
        {
          marketplace_campaign_name: campaignInputData.name,
          required_content: campaignInputData.network_types,
          location: map(campaignInputData.accepted_place_ids, 'value'),
          project_name: project.title,
          project_id: project.id,
        },
      );

      const successLabel = isEditingCampaign
        ? 'Your Project\'s Marketplace settings have been updated'
        : 'Your project has been added to Aspire Creator Marketplace';

      showSuccessMessage(successLabel);
      setHasBeenSubmitted(true);
      refetchCampaign();
      refetchActiveProjects();
    },
    onError: (error) => {
      showError(error);
    },
  });

  const sanitizeValue = (value: unknown) => {
    if (isArray(value)) {
      return filter(value);
    }

    return value;
  };

  const getFieldValue = (fieldPath: string, defaultValue?: unknown) => (
    get(campaignInputData, fieldPath, defaultValue || undefined)
  );

  const setFieldValue = (fieldPath: string, fieldValue: unknown) => {
    setCampaignInputData((currentData) => {
      const newData = cloneDeep(currentData);
      if (isObject(fieldValue) && !isArray(fieldValue)) {
        set(newData, fieldPath, {
          ...get(newData, fieldPath),
          ...(sanitizeValue(fieldValue) as object),
        });
      } else {
        set(newData, fieldPath, sanitizeValue(fieldValue));
      }
      return newData;
    });
  };

  const setInitialData = () => {
    setCampaignInputData(defaultInputData);
  };

  const reset = () => {
    setHasBeenSubmitted(false);
    setInitialData();
  };

  const close = () => {
    setHasBeenTouched(false);
    history.replace({ ...openFrom });
  };

  useEffect(() => {
    reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (includes(location?.pathname, 'find_creators')) {
      reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  useEffect(() => {
    setInitialData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultInputData,
    isCampaignLoading,
    campaign,
    isDrawerVisible,
  ]);

  const hasDataChanged = useMemo(() => {
    if (isCampaignNeverPublished(campaign)) {
      // a campaign in draft mode is considered with changed data
      return true;
    }

    return (
      !isEmpty(defaultInputData)
      && !isEmpty(campaignInputData)
      && hasBeenTouched
      && !isEqual(defaultInputData, campaignInputData)
    );
  }, [campaignInputData, defaultInputData, hasBeenTouched, campaign]);

  const isDataEmpty = useMemo(() => {
    if (isEmpty(campaignInputData)) {
      return true;
    }

    const hasNonEmptyElements = some(campaignInputData, (value) => !isEmpty(value));

    return !hasNonEmptyElements;
  }, [campaignInputData]);

  const hasEmptyRequiredFields = (fields: string[]): boolean =>
    some(fields, (fieldKey) => isEmpty(getFieldValue(fieldKey)));

  const selectedNetworks: NETWORK_TYPE[] = useMemo(() => {
    if (!campaignInputData?.product_types) {
      return [];
    }

    const dirtySelectedNetworks = map(campaignInputData.product_types, (contentTypeKey) => (
      CONTENT_TYPE[contentTypeKey]?.network
    ));

    return uniq(filter(dirtySelectedNetworks));
  }, [campaignInputData?.product_types]);

  const submitData = async () => {
    if (isSubmitting) {
      return;
    }

    const networkTypes = new Set(map(campaignInputData.product_types, (type) => CONTENT_TYPE[type]?.network));

    const networkDetails = map(
      values(campaignInputData?.network_details),
      (detail) => {
        if (!networkTypes.has(detail.network_type)) {
          /** We need to send null values to BE so the network gets reseted */
          return {
            ...detail,
            maximum_engagements: null,
            maximum_followers: null,
            minimum_engagements: null,
            minimum_followers: null,
            maximum_budget: null,
          };
        }

        const networkLimits = networkSliders[detail.network_type] || [];
        each(networkLimits, (limit) => {
          const maxKey = `maximum_${limit.key}`;
          if (detail[maxKey] === limit.max) {
            // remove max values if they match the default max limit
            detail[maxKey] = null;
          }
        });

        return {
          ...detail,
          maximum_budget: detail.maximum_budget || null,
        };
      },
    );

    const cleanCampaignData: IBackendServerCampaignInput = {
      ...pick(
        campaignInputData,
        [
          'name',
          'splash_image_url',
          'summary',
          'accepted_place_ids',
          'offers_payment',
          'offers_product',
          'offers_commission',
          'offers_other',
        ],
      ),
      external_listing_url: externalListingFullUrl,
      product_types: campaignInputData?.product_types as IProductType[],
      network_details: networkDetails,
    };

    if (!campaign || isCampaignNeverPublished(campaign)) {
      cleanCampaignData.status = 'PUBLISH';
    }

    if (client?.brandCategory) {
      cleanCampaignData.categories = [client?.brandCategory as ICampaignCategory];
    }

    saveProjectCampaign({
      variables: {
        projectId,
        campaign: cleanCampaignData,
      },
    });
  };

  useEffect(() => {
    if (hasBeenSubmitted) {
      close();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBeenSubmitted]);

  return (
    <ListOnMarketplaceContext.Provider
      value={{
        baseRoutePath,
        campaignInputData,
        projectId,
        getFieldValue,
        setFieldValue,
        submitData,
        setHasBeenTouched,
        close,
        goBack: history.goBack,
        isDrawerVisible,
        isDataEmpty,
        hasDataChanged,
        isSubmitting,
        hasBeenSubmitted,
        hasBeenTouched,
        selectedNetworks,
        isEditingCampaign,
        isCampaignLoading,
        hasEmptyRequiredFields,
        campaign,
      }}
    >
      {children}
    </ListOnMarketplaceContext.Provider>
  );
};

export const useListOnMarketplace = () => {
  if (!ListOnMarketplaceContext) {
    throw new Error('useListOnMarketplace should be used in a context.');
  }

  return useContext(ListOnMarketplaceContext);
};
