import { ITemplateProps } from '../types';
import { IAboutProps } from './About';
import { IIntroProps } from './Intro';
import { IPerksProps } from './Perks';
import { IPersonaProps } from './Persona';
import { IApplicationProps } from './Application';

type ISettingsProps = ITemplateProps['settings'];

export const SUPPORT_EMAIL = 'help@aspireiq.com';
export const ACCOUNTS_RECEIVABLE_EMAIL = 'AR@aspireiq.com';

export const defaultSettings: ISettingsProps = {
  page_online: true,
  page_color: {
    background_color: '#131313',
  },
  form_color: {
    background_color: '#fdfdfd',
  },
  logo: 'https://storage.googleapis.com/aspirex-static-files/aspire_light_mark.svg',
  body_styling: {
    font_size: 18,
  },
  url: 'www.community.aspireiq.com/',
};

export const defaultIntro: IIntroProps = {
  show: true,
  title: 'Your page title',
  hero_image: 'https://storage.googleapis.com/aspirex-static-files/onboarding-template/lp_intro.png',
  description: `Welcome to your page, put something amazing here about your brand.
  It can be your tag line or something your community cares about joining`,
  button_text: 'Join',
};

export const defaultAbout: IAboutProps = {
  show: true,
  title: 'About',
  description: 'Welcome to your page! Write something amazing  about your brand and give creators more information about this collaboration.',
  brand_images: [
    'https://storage.googleapis.com/aspirex-static-files/onboarding-template/lp_about.png',
    'https://storage.googleapis.com/aspirex-static-files/onboarding-template/lp_about.png',
    'https://storage.googleapis.com/aspirex-static-files/onboarding-template/lp_about.png',
  ],
};

export const defaultPerks: IPerksProps = {
  show: true,
  title: 'Program Perks',
  bullet_style: 'numbers',
  items: [
    'What are awesome things your community can expect from joining?',
    'What are awesome things your community can expect from joining?',
    'What are awesome things your community can expect from joining?',
    'What are awesome things your community can expect from joining?',
    'What are awesome things your community can expect from joining?',
  ],
};

export const defaultPersona: IPersonaProps = {
  show: true,
  title: 'Who you are',
  bullet_style: 'bullets',
  cover_image: 'https://storage.googleapis.com/aspirex-static-files/onboarding-template/lp_persona.png',
  features: [
    'List out characteristics you are looking for',
    'List out characteristics you are looking for',
    'List out characteristics you are looking for',
    'List out characteristics you are looking for',
  ],
};

export const defaultApplication: IApplicationProps = {
  title: 'Join Us',
  description: 'We would love for you to consider joining our family. Our goal is to create a better way to communicate and learn from our customers and fans so that we can ultimately better serve your needs. Apply Now!',
  unpaidLabel: 'This is an unpaid collaboration',
  unpaidDescription: 'I understand that this collaboration does not offer payment.',
  apply_label: 'Apply',
};
