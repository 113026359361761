._FeatureNotice_1y9ej_1 {
  font-weight: normal;
}
._FeatureNotice_1y9ej_1 span {
  font-weight: bold;
}
._FeatureNotice_1y9ej_1 a {
  cursor: pointer;
  user-select: none;
  color: #3996e0;
  text-decoration: none;
}