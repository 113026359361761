import { findIndex } from 'lodash';
import { DateOperator } from '../../../../types/ContentDateRequirements';
import { IState } from '../../../../types/state';
import { IAddPostTypeAction } from '../CollaborationDetails/index';

export const addPostType = (state: IState, action: IAddPostTypeAction): IState => {
  const {
    id,
  } = action.contentGuideline;
  const { contentGuidelineInstanceId } = action;
  const findIndexBy = contentGuidelineInstanceId ? {
      contentGuidelineInstanceId,
    } : {
      id,
      contentGuidelineInstanceId: null,
    };
  const contentGuidelineIndex = findIndex(state.contentGuidelines, findIndexBy);
  if (contentGuidelineIndex !== -1) {
    const { contentGuidelines } = state;
    let contentGuideline = contentGuidelines[contentGuidelineIndex];
    contentGuideline = {
      ...contentGuidelines[contentGuidelineIndex],
      dueDates: {
        ...contentGuidelines[contentGuidelineIndex].dueDates,
        completeDate: [
          ...contentGuidelines[contentGuidelineIndex].dueDates.completeDate,
          {
            beforeDate: null,
            operator: DateOperator.BEFORE,
          },
        ],
        submissionDate: contentGuidelines[contentGuidelineIndex].requiresReviewBeforeSubmission
          ? [
              ...contentGuidelines[contentGuidelineIndex].dueDates.submissionDate,
              {
                beforeDate: null,
                operator: DateOperator.BEFORE,
              },
            ]
          : [],
      },
    };
    contentGuidelines[contentGuidelineIndex] = contentGuideline;
    return {
      ...state,
      contentGuidelines: [...contentGuidelines],
    };
  }
  return {
    ...state,
    contentGuidelines: [
      ...state.contentGuidelines,
      {
        ...action.contentGuideline,
        dueDates: {
          completeDate: [
            {
              beforeDate: null,
              operator: DateOperator.BEFORE,
            },
          ],
          submissionDate: [],
        },
        requiresReviewBeforeSubmission: false,
        contentGuidelineInstanceId: null,
        requiresReviewWithinThreeDaysOfCreatorSubmission: false,
      },
    ],
  };
};
