import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_USERS_QUERY } from '@frontend/app/queries';
import {
  GetUsersQuery,
} from '@frontend/app/queries/types/GetUsersQuery';

type IOptions = QueryHookOptions<GetUsersQuery>;

export const useGetUsersQuery = (options: IOptions = {}) => useQuery<GetUsersQuery>(GET_USERS_QUERY, options);
