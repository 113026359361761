import * as React from 'react';
import { useEffect, useState } from 'react';
import { message } from 'antd';

import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useGetOrdersByIds } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrdersByIds';
import { IWorkItem } from '@services/workflow';
import { Spinner, Typography } from '@revfluence/fresh';
import { IBulkFulfillOrderInput } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { logger } from '@common';

import { useBulkFulfillOrder } from '@frontend/applications/ProductFulfillmentApp/hooks/useBulkFulfillOrder';
import { getOrderIdFromWorkItem } from '@frontend/applications/ProductFulfillmentApp/utils';
import { ShipmentProcessing } from '../../components/ShipmentProcessing/ShipmentProcessing';
import { ShipmentEmail } from '../../components/ShipmentEmail/ShipmentEmail';

interface IOrderFulfillmentMap {
  [key: string]: IBulkFulfillOrderInput;
}

export const ShipmentProcessingContainer = () => {
  const { workItems } = useProductFulfillmentContext();
  const orderIds = workItems.map((workItem) => getOrderIdFromWorkItem(workItem));
  const orderIdWorkItemMap = workItems.reduce((acc, workItem) => {
    const orderId = getOrderIdFromWorkItem(workItem);
    if (orderId) {
      acc[orderId] = workItem.id;
    }
    return acc;
  }, {} as Record<string, IWorkItem>);

  const { closeModal } = useApplication();
  const [showOrderShipEmail, setShowOrderShipEmail] = useState(false);

  const { orders, loading: isLoadingOrders, error: getOrdersError } = useGetOrdersByIds({
    variables: {
      ids: orderIds,
    },
  });
  const { bulkFulfillOrder, loading: isBulkFulfillOrderLoading } = useBulkFulfillOrder();

  const [orderInputs, setOrderInputs] = useState<IOrderFulfillmentMap>({});
  useEffect(() => {
    if (orders) {
      setOrderInputs((prev) => {
        if (Object.keys(prev).length === 0) {
          return orders.reduce((acc, order) => {
            acc[order.id] = {
              cost: 0,
              orderId: order.id,
              workItemId: orderIdWorkItemMap[order.id],
              trackingNumber: '',
            };
            return acc;
          }, {} as IOrderFulfillmentMap);
        }
        return prev; // return prev when it's truthy
      });
    }
  }, [orders, orderIdWorkItemMap, setOrderInputs]);

  const onOrderChange = (value: string | number, orderId: string, type: 'cost' | 'trackingNumber') => {
    setOrderInputs((prev) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        [type]: value,
      },
    }));
  };

  const onMarkAsShipped = () => {
    setShowOrderShipEmail(true);
  };

  const onSendTrackingInfo = async (messageParams: SendMessageInput): Promise<void> => {
    await bulkFulfillOrder({
      variables: {
        params: {
          messageParams: {
            attachments: messageParams.attachments,
            members: messageParams.members,
            membersSearchQuery: messageParams.membersSearchQuery,
            message: messageParams.message,
            resourceId: messageParams.resourceId,
            subject: messageParams.subject,
            type: messageParams.type.toLowerCase(),
            additionalCc: messageParams.additionalCc,
          },
          orderInputs: Object.keys(orderInputs).reduce((acc, key) => {
            const orderInput = orderInputs[key];
            acc.push({
              cost: orderInput.cost,
              orderId: orderInput.orderId,
              trackingNumber: orderInput.trackingNumber,
              workItemId: orderInput.workItemId,
            });
            return acc;
          }, []),
        },
      },
      onCompleted: () => {
        message.success('Orders successfully marked as shipped');
        closeModal();
      },
      onError: (err) => {
        logger.error(err);
        message.error('There was an error processing your shipping updates');
      },
    });
  };

  const onCancel = () => {
    closeModal();
  };
  return (
    <>
      {isLoadingOrders && <Spinner />}
      {getOrdersError && <Typography.Text type="danger">{getOrdersError.message}</Typography.Text>}
      {!isLoadingOrders && orders && !showOrderShipEmail && (
        <ShipmentProcessing
          loading={isBulkFulfillOrderLoading}
          orders={orders}
          onOrderChange={onOrderChange}
          onMarkAsShipped={onMarkAsShipped}
          onCancel={onCancel}
        />
      )}

      {showOrderShipEmail && <ShipmentEmail sendShippingEmail={onSendTrackingInfo} />}
    </>
  );
};
