import * as React from 'react';
import {
  Label,
  LabelProps,
  ReferenceLine as RechartReferenceLine,
  ReferenceLineProps,
} from 'recharts';
import { CartesianViewBox } from 'recharts/types/util/types';
import styles from '../../Charts.module.scss';

const fontSize = Number(styles['font-size-sm'].replace('px', ''));
const green7 = styles['green-7'];
export interface IReferenceLineProps extends ReferenceLineProps {
  text?: string;
  withIcon?: boolean;
}

const LabelWithIcon = (props: LabelProps) => (
  <Label
    {...props}
    content={({ viewBox }) => {
        if (!viewBox) {
          return undefined;
        }
        const { x = 0, y = 0, width = 0 } = viewBox as CartesianViewBox;
        const dimension = 16;
        const radius = dimension / 2;
        const transform = `translate(${width + x - dimension} ${y - radius})`;

        return (
          <g transform={transform}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width={dimension}
              height={dimension}
              fill={styles['green-7']}
            >
              <circle cx="256" cy="256" fill="#fff" r="256" />
              <path d="M176 256C176 211.8 211.8 176 256 176C300.2 176 336 211.8 336 256C336 300.2 300.2 336 256 336C211.8 336 176 300.2 176 256zM256 0C273.7 0 288 14.33 288 32V66.65C368.4 80.14 431.9 143.6 445.3 224H480C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H445.3C431.9 368.4 368.4 431.9 288 445.3V480C288 497.7 273.7 512 256 512C238.3 512 224 497.7 224 480V445.3C143.6 431.9 80.14 368.4 66.65 288H32C14.33 288 0 273.7 0 256C0 238.3 14.33 224 32 224H66.65C80.14 143.6 143.6 80.14 224 66.65V32C224 14.33 238.3 0 256 0zM128 256C128 326.7 185.3 384 256 384C326.7 384 384 326.7 384 256C384 185.3 326.7 128 256 128C185.3 128 128 185.3 128 256z" />
            </svg>
          </g>
        );
      }}
  />
  );

export const ReferenceLine = (props: IReferenceLineProps) => {
  const {
 text, label, withIcon, ...rest
} = props;
  const labelProps = label as LabelProps;
  if (text) {
    labelProps.value = text;
  }
  return (
    <>
      <RechartReferenceLine {...rest} label={labelProps} />
      (
      {withIcon && (
      <RechartReferenceLine
        {...rest}
        label={(labelIconProps) => <LabelWithIcon {...labelIconProps} />}
      />
      )}
      )
    </>
  );
};

ReferenceLine.defaultProps = {
  ...RechartReferenceLine.defaultProps,
  ifOverflow: 'extendDomain',
  withIcon: false,
  label: {
    position: 'insideBottomLeft',
    fill: green7,
    fontSize,
  },
  strokeDasharray: '3 3',
  stroke: green7,
  strokeWidth: 2,
} as ReferenceLineProps;
