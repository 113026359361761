import * as React from 'react';
import cx from 'classnames';
import {
 map, slice, sortBy, uniqBy,
} from 'lodash';

import { NetworkIcon } from '@components';
import { Cell, ICellProps } from './Cell';

import { INetworkCellConfig } from '../tableContext';

type TTableNetworkCellValue = INetworkCellConfig[];

import styles from './NetworkCell.scss';

interface IProps extends ICellProps {
  value: TTableNetworkCellValue;

  unique?: boolean;
  showAccountName?: boolean;
}
/**
 * @type {React.FunctionComponent}
 */
export const NetworkCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
 className, value, unique, showAccountName, ...restProps
} = props;

  const handleNetworkClick = (
    network: INetworkCellConfig,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    window.open(network.link, '_blank');
  };
  // get at most 6 networks and sort by network type
  const networks = sortBy(slice(unique ? uniqBy(value, 'type') : value, 0, 6), 'type');
  const isMoreThenOne = networks.length > 1;
  const content = (
    <div
      className={cx(styles.accountList, {
        [styles.iconOnly]: !showAccountName,
      })}
    >
      {map(networks, (network, index) => (
        <div
          key={index}
          className={cx(styles.accountEntry, {
            [styles.hasLink]: !!network.link,
            [styles.marginBottom]: isMoreThenOne,
          })}
          onClick={(e) => (network.link
            ? handleNetworkClick(network, e)
            : undefined)}
        >
          <div className={styles.icon}>
            <NetworkIcon identifier={network.type} size={20} />
          </div>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {showAccountName && <div className={(styles as any).accountName}>{network.accountName}</div>}
        </div>
      ))}
    </div>
  );

  return <Cell className={cx(styles.NetworkCell, className)} value={content} {...restProps} />;
});

NetworkCell.defaultProps = {
  unique: true,
  showAccountName: false,
};
NetworkCell.displayName = 'NetworkCell';
