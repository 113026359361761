import * as React from 'react';
import cx from 'classnames';
import { noop } from 'lodash';

import {
  Form, Modal, Button, Input,
} from 'antd';

import { useMessagingContext } from '@frontend/hooks';
import { useInviteUser } from '@frontend/app/hooks';

import styles from './InviteUserModal.scss';

interface IProps {
  open: boolean;
  onRequestClose(): void;

  onComplete?(): void;
  className?: string;
}
interface IInviteUserFormProps {
  onRequestClose?(): void;
  onComplete?(): void;
}

const InviteUserForm: React.FunctionComponent<IInviteUserFormProps> = React.memo(({
  onRequestClose = noop, onComplete = noop,
}) => {
  const [form] = Form.useForm();
  const { showError } = useMessagingContext();
  const { inviteUser, loading } = useInviteUser();

  const onFinish = async ({ email, name }: { email: string; name: string; }) => {
    try {
      await inviteUser({
        variables: {
          email,
          name,
        },
      });
      onComplete();
      onRequestClose();
    } catch (error) {
      showError(error);
      onRequestClose();
    }
  };
  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <Form
      className={styles.form}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not a valid email.',
          },
          {
            required: true,
            message: 'Please enter a valid email address.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please enter the user\'s name.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className={styles.actions}>
        <Button onClick={onRequestClose}>
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Invite
        </Button>
      </div>
    </Form>
  );
});
InviteUserForm.displayName = 'InviteUserForm';

/**
 * @type {React.FunctionComponent}
 */
export const InviteUserModal: React.FunctionComponent<IProps> = React.memo(({
  open, onRequestClose, onComplete = noop, className,
}) => (
  <Modal
    className={cx(styles.InviteUserModal, className)}
    title="Invite Team Member"
    visible={open}
    width={600}
    onCancel={onRequestClose}
    footer={null}
    destroyOnClose
  >
    <InviteUserForm onRequestClose={onRequestClose} onComplete={onComplete} />
  </Modal>
  ));

InviteUserModal.defaultProps = {
  className: null,
};
InviteUserModal.displayName = 'InviteUserModal';
