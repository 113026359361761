import { TMember } from '../../../types';
import { TState } from './types';

export const getDefaultState = (members: readonly TMember[]): TState => ({
    currentStep: 1,
    error: null,
    members: members.map((m) => ({
      ...m,
      code: '',
      inOffer: true,
      selected: true,
      selectedPrograms: '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })) as any,
    saving: false,
    showCloseConfirmation: false,
  });
