import * as React from 'react';
import { SplashPage, UpgradeButton } from '@frontend/app/components';
import { UsageEntity } from '@frontend/context/MessagingContext';

const salesTrackingSplashSvg = 'https://storage.googleapis.com/aspirex-static-files/upsell-gates/sales_tracking_splash.svg';

const Button = () => (
  <UpgradeButton
    reason={UsageEntity.SALES_TRACKING}
  />
);

const SalesTrackingSplashPage = () => (
  <SplashPage
    title="Tie Relationships to Revenue"
    description="Take the guesswork out of ROI. Create unique Shopify promo codes for every influencer in your campaign and see real-time results via sales tracking."
    imageUrl={salesTrackingSplashSvg}
    ctaButton={<Button />}
  />
);

export default SalesTrackingSplashPage;
