import { useMutation, MutationHookOptions } from '@apollo/client';
import { DataProxy } from 'apollo-cache';
import { unionBy } from 'lodash';

import { SAVE_ACTIVATION } from '@frontend/app/queries/SaveActivationMutation';
import {
  SaveActivationMutation,
  SaveActivationMutationVariables,
} from '@frontend/app/queries/types/SaveActivationMutation';

import { ACTIVATIONS_QUERY } from '@frontend/app/queries/ActivationsQuery';
import { IActivation } from '.';
import {
  ActivationsQuery,
} from '../queries/types/ActivationsQuery';

type IOptions = MutationHookOptions<SaveActivationMutation, SaveActivationMutationVariables>;

const updateCache = (store: DataProxy, activation: IActivation) => {
  // Read the data from our cache for this query.
  const data = store.readQuery<ActivationsQuery>({
    query: ACTIVATIONS_QUERY,
  });

  // Do nothing. Cache doesn't exist.
  if (!data) {
    return;
  }

  const newCache: ActivationsQuery = {
    ...data,
    activations: unionBy(data.activations, [activation], 'id'),
  };

  store.writeQuery({
    query: ACTIVATIONS_QUERY,
    data: newCache,
  });
};

export const useSaveActivationMutation = (options: IOptions = {}) => useMutation<SaveActivationMutation, SaveActivationMutationVariables>(SAVE_ACTIVATION, {
    ...options,
    update: (...args) => {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateCache(store as any, result.data.activation);
    },
  });
