import 'css-chunk:src/app/containers/Projects/ProjectApplicationPagePicker/ProjectApplicationPagePicker.scss';export default {
  "ProjectApplicationPagePicker": "_ProjectApplicationPagePicker_oq2r8_1",
  "navigate": "_navigate_oq2r8_1",
  "backButton": "_backButton_oq2r8_10",
  "title": "_title_oq2r8_13",
  "splashImage": "_splashImage_oq2r8_19",
  "content": "_content_oq2r8_24",
  "container": "_container_oq2r8_32",
  "section": "_section_oq2r8_42",
  "header": "_header_oq2r8_48",
  "projectStatusSelect": "_projectStatusSelect_oq2r8_53",
  "listGridView": "_listGridView_oq2r8_65",
  "listItem": "_listItem_oq2r8_72",
  "image": "_image_oq2r8_79",
  "archivedImage": "_archivedImage_oq2r8_85",
  "ctaBtns": "_ctaBtns_oq2r8_88",
  "listItemTitle": "_listItemTitle_oq2r8_112",
  "alignedLeftContent": "_alignedLeftContent_oq2r8_129"
};