import { getEnvironment, Environment } from '@frontend/utils/Envionment/Environment';

const SOCIAL_POST_APP_IDS = {
  [Environment.LOCALHOST]: 'AflfzT0dxcKinP7ycrmvcZ1Du5crpE0o',
  [Environment.DEVELOPMENT]: 'AflfzT0dxcKinP7ycrmvcZ1Du5crpE0o',
  [Environment.STAGING]: 'ahGR2RR1XXK4FLMs2zyGqo31H1fVMGKD',
  [Environment.PRODUCTION]: 'IDb1Q11xTFeipMvAJXKY0mcTGZ1SmmHT',
};
const SOCIAL_DISCOVERY_APP_IDS = {
  [Environment.LOCALHOST]: 'zxbQi0fSAAU3DRJ4cmlP9IqwRqF2IDXf',
  [Environment.DEVELOPMENT]: 'zxbQi0fSAAU3DRJ4cmlP9IqwRqF2IDXf',
  [Environment.STAGING]: 'spfrddnwQpzLdzSNvPpnSIpf9B7PCsm4',
  [Environment.PRODUCTION]: 'KyXOg4RiEupXxAFkWh3qnud6nX3tIWb4',
};

const CONTRACT_APP_IDS = {
  [Environment.LOCALHOST]: '44HQrli1pWBBSfWJ675Y7Ximjhwsbm7g',
  [Environment.DEVELOPMENT]: '44HQrli1pWBBSfWJ675Y7Ximjhwsbm7g',
  [Environment.STAGING]: 'mb3keVkaWmUYvHlFBN2ya72xDBgbZwyK',
  [Environment.PRODUCTION]: 'aCc1XefWUQSpmQFlM9YTetkfsFpLNKWX',
};

const CONTENT_APP_IDS = {
  [Environment.LOCALHOST]: 'S7qLzSWQs9cUB2LswQU3zuR2Ndm20KpH',
  [Environment.DEVELOPMENT]: 'S7qLzSWQs9cUB2LswQU3zuR2Ndm20KpH',
  [Environment.STAGING]: '9Qt32js3xt1B4AhwITSZwswbMGXr9s3M',
  [Environment.PRODUCTION]: 'qpHb5RrgBXSqqoFW4jMCB7qz415a33zb',
};

const PAYMENT_APP_IDS = {
  [Environment.LOCALHOST]: 'Tv4mxLeb8zLGIpR4stmnkGzMrE7ONwzB',
  [Environment.DEVELOPMENT]: 'Tv4mxLeb8zLGIpR4stmnkGzMrE7ONwzB',
  [Environment.STAGING]: 'KjQtEWkso49BxI74P2tACa2QXFCawuji',
  [Environment.PRODUCTION]: 'LlzwYg9aHNUT6WXr7EUa6JDTuJIbZMU3',
};

const OUTLOOK_APP_IDS = {
  [Environment.LOCALHOST]: 'oh2nLulYAllsbFKZQulb0iaDR6fv2N3C',
  [Environment.DEVELOPMENT]: 'oh2nLulYAllsbFKZQulb0iaDR6fv2N3C',
  [Environment.STAGING]: 'hbgosyZFErKhujxb5wjAzUSMt0HUnhkd',
  [Environment.PRODUCTION]: 'onDETPH4CH7eJjn7ufhjb5ajNWYWNYoK',
};

const GMAIL_APP_IDS = {
  [Environment.LOCALHOST]: 'mr91sAs5jHBkWEp52jbO9lZHUQJXnXQA',
  [Environment.DEVELOPMENT]: 'mr91sAs5jHBkWEp52jbO9lZHUQJXnXQA',
  [Environment.STAGING]: 'rylQ2PSSjVpLUfBHbwsnECPsPs2JPeke',
  [Environment.PRODUCTION]: '0Sw41W5lC6GbYuOwueETWqVfALfM7FFX',
};

const GDRIVE_APP_IDS = {
  [Environment.LOCALHOST]: 'lAN1pcZZ0fln7hMShm54cE41TENCxegA',
  [Environment.DEVELOPMENT]: 'lAN1pcZZ0fln7hMShm54cE41TENCxegA',
  [Environment.STAGING]: 'NX5L8cD5gNOP5Pau5eN38OI93wkI3FZu',
  [Environment.PRODUCTION]: 'pmZgQFHnIWHkfiGHqazU1RJuwAhrctmV',
};

const SHOPIFY_APP_IDS = {
  [Environment.LOCALHOST]: 'rpnNs7yFpOBxidDJdYZCO3se5bcJ7qaF',
  [Environment.DEVELOPMENT]: 'rpnNs7yFpOBxidDJdYZCO3se5bcJ7qaF',
  [Environment.STAGING]: 'RCvhbYnkmuS3c6t42X8g5vwFFVVaeU6d',
  [Environment.PRODUCTION]: 'aGujNNiqwG8ApvxVth8cdD2b1dTkHwXO',
};

const WOO_COMMERCE_APP_IDS = {
  [Environment.LOCALHOST]: 'zmyBYWGxbd0C3cLYLGdCkHmURRlyORfg',
  [Environment.DEVELOPMENT]: 'zmyBYWGxbd0C3cLYLGdCkHmURRlyORfg',
  [Environment.STAGING]: 'vs25cUOeaDfWLFYWPJZhEJRRehWanmSG',
  [Environment.PRODUCTION]: 'F9r9MN0se2IbMRLGBWFsafXjF8VG2TEq',
};

const SALES_APP_IDS = {
  [Environment.LOCALHOST]: 'AbVx5w7T6kgf07cmiZnfWpSXAR3bY4IP',
  [Environment.DEVELOPMENT]: 'AbVx5w7T6kgf07cmiZnfWpSXAR3bY4IP',
  [Environment.STAGING]: '2aWgD4iQaAYMPZF5SkntWqj2nS6Iva4D',
  [Environment.PRODUCTION]: 'JQwfMaNyvyF7rjjWyprN86AZYAZxeb63',
};

const REMINDER_APP_IDS = {
  [Environment.LOCALHOST]: 'BGCfsVYof192nZk8Fom7Z34VyLFMGCl2',
  [Environment.DEVELOPMENT]: 'BGCfsVYof192nZk8Fom7Z34VyLFMGCl2',
  [Environment.STAGING]: 'tumTw1j3Xc0joYwCxgmHytfdneqnWMEU',
  [Environment.PRODUCTION]: '4FTa0LwPRbMw79UN5aT0Sf4r2AXjibPT',
};

const MEMBER_DATA_UPDATE_APP_IDS = {
  [Environment.LOCALHOST]: 'FLjsCWxxXc51CNy2nEZ0rH0X1hoFyqcr',
  [Environment.DEVELOPMENT]: 'FLjsCWxxXc51CNy2nEZ0rH0X1hoFyqcr',
  [Environment.STAGING]: '7sSBJ0MFozpMvvr97tJnWu3oLxylYFVm',
  [Environment.PRODUCTION]: 'LLd8ydSr1dPdeAWdv733ICIqSBvlgAZa',
};

const TERMS_APP_IDS = {
  [Environment.LOCALHOST]: '1jov72liI2vgkex4tmTcTosjkfS9zOSU',
  [Environment.DEVELOPMENT]: '1jov72liI2vgkex4tmTcTosjkfS9zOSU',
  [Environment.STAGING]: 'mj5JigrcVzR2iLJ8vEWHr1sksSmFzLJq',
  [Environment.PRODUCTION]: 'ZNzUeytyeQ7bb3FlvTI38sxHiAuNYUp9',
};
const FULFILLMENT_APP_IDS = {
  [Environment.LOCALHOST]: 'PcQnomgI9zhlJlOwW8nm5PLhSRhDK7dN',
  [Environment.DEVELOPMENT]: 'PcQnomgI9zhlJlOwW8nm5PLhSRhDK7dN',
  [Environment.STAGING]: 'vTvupk6prUjCvXVpZ76xrun1QDF18mZN',
  [Environment.PRODUCTION]: 'vUfHbHqskA2CEQ3wIJosBO5hZdZHg2Jf',
};

const CREATE_ARCHIVE_APP_IDS = {
  [Environment.LOCALHOST]: 'SkV62QFLqbvh9Of2ZoLPl7dg05M1W77y',
  [Environment.DEVELOPMENT]: 'SkV62QFLqbvh9Of2ZoLPl7dg05M1W77y',
  [Environment.STAGING]: 'TFjwaV5k2MJutswBNSprbRlFZKmuz5DB',
  [Environment.PRODUCTION]: '6Kg2u3hAeTDKW1iqkMSud0HSKRwl3zU0',
};

const QA_APP_IDS = {
  [Environment.LOCALHOST]: 'XbuhNfmt75297LmVSuUyc1i95aaW2vBU',
  [Environment.DEVELOPMENT]: 'XbuhNfmt75297LmVSuUyc1i95aaW2vBU',
  [Environment.STAGING]: 'mjrCMxNVfEk5sFaL0VsIVbCGPjpiJnq5',
  [Environment.PRODUCTION]: 'VgntpgzMvLReGvYtF0g6uhPCj7kDDCjF',
};

const ALLOY_APP_IDS = {
  [Environment.LOCALHOST]: '5SbxXQSVMYyafcYHaU8gB4QmFxQ6MVHG',
  [Environment.DEVELOPMENT]: '5SbxXQSVMYyafcYHaU8gB4QmFxQ6MVHG',
  [Environment.STAGING]: 'nIvdsLWXEnWKcCI0S9pNsCHX52Q2ivvC',
  [Environment.PRODUCTION]: 'tS5AgQxb0JiPYl9SCVniXA6ZHbYUzb1a',
};

const IGDM_APP_IDS = {
  [Environment.LOCALHOST]: 'sWOfMA4BnbwMrzJssHD5vMuHBXGACGql',
  [Environment.DEVELOPMENT]: 'sWOfMA4BnbwMrzJssHD5vMuHBXGACGql',
  [Environment.STAGING]: 'TvUCMR8sTijRFYdQ6zb6iQRcd8xONDtA',
  [Environment.PRODUCTION]: 'VefWYhASFf3EgNm1RC62CbsZH5vjMwN5',
};

const KLAVIYO_APP_IDS = {
  [Environment.LOCALHOST]: 'v8G5Bl6pyBCsfpOJyMHu6hTprzZhUWhq',
  [Environment.DEVELOPMENT]: 'v8G5Bl6pyBCsfpOJyMHu6hTprzZhUWhq',
  [Environment.STAGING]: 'v8G5Bl6pyBCsfpOJyMHu6hTprzZhUWhq',
  [Environment.PRODUCTION]: 'O6ByzYwIi9PVQLTdUYAdIOCX0yjvnmYc',
};

export const SOCIAL_POST_APP_ID = SOCIAL_POST_APP_IDS[getEnvironment()];
export const SOCIAL_DISCOVERY_APP_ID = SOCIAL_DISCOVERY_APP_IDS[getEnvironment()];
export const CONTRACT_APP_ID = CONTRACT_APP_IDS[getEnvironment()];
export const FULFILLMENT_APP_ID = FULFILLMENT_APP_IDS[getEnvironment()];
export const PAYMENT_APP_ID = PAYMENT_APP_IDS[getEnvironment()];
export const CONTENT_APP_ID = CONTENT_APP_IDS[getEnvironment()];
export const OUTLOOK_APP_ID = OUTLOOK_APP_IDS[getEnvironment()];
export const GMAIL_APP_ID = GMAIL_APP_IDS[getEnvironment()];
export const GDRIVE_APP_ID = GDRIVE_APP_IDS[getEnvironment()];
export const KLAVIYO_APP_ID = KLAVIYO_APP_IDS[getEnvironment()];
export const SHOPIFY_APP_ID = SHOPIFY_APP_IDS[getEnvironment()];
export const SALES_APP_ID = SALES_APP_IDS[getEnvironment()];
export const REMINDER_APP_ID = REMINDER_APP_IDS[getEnvironment()];
export const WOO_COMMERCE_APP_ID = WOO_COMMERCE_APP_IDS[getEnvironment()];
export const MEMBER_DATA_UPDATE_APP_ID = MEMBER_DATA_UPDATE_APP_IDS[getEnvironment()];
export const TERMS_APP_ID = TERMS_APP_IDS[getEnvironment()];
export const CREATE_ARCHIVE_APP_ID = CREATE_ARCHIVE_APP_IDS[getEnvironment()];
export const QA_APP_ID = QA_APP_IDS[getEnvironment()];
export const ALLOY_APP_ID = ALLOY_APP_IDS[getEnvironment()];
export const IGDM_APP_ID = IGDM_APP_IDS[getEnvironment()];
