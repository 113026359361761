import { useMutation, MutationHookOptions } from '@apollo/client';

import { UPDATE_ADVANCE_TERMS_TEMPLATE_MUTATION } from '@frontend/app/queries';
import {
  UpdateAdvanceTermsTemplateMutation,
  UpdateAdvanceTermsTemplateMutationVariables,
} from '@frontend/app/queries/types/UpdateAdvanceTermsTemplateMutation';

type IOptions = MutationHookOptions<UpdateAdvanceTermsTemplateMutation, UpdateAdvanceTermsTemplateMutationVariables>;

export const useUpdateAdvanceTermsTemplateMutation = (
  options: IOptions = {},
  ) => useMutation<UpdateAdvanceTermsTemplateMutation, UpdateAdvanceTermsTemplateMutationVariables>(UPDATE_ADVANCE_TERMS_TEMPLATE_MUTATION, options);
