import * as React from 'react';
import { Row, Col } from 'antd';

import { format } from 'date-fns';
import { isEmpty, map } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { IRowData, Notice } from '@components';

// TODO: Refactor StatisticsCard to either be in AspireUI or a shared comcponent
import {
  Progress,
  Typography,
} from '@revfluence/fresh';
import { BulkPaymentAppStatisticsCard, IStatisticsCardData } from '@frontend/applications/AffiliatesApp/components';
import { DataFormat } from '@frontend/applications/AffiliatesApp/utils';
import { WizardContainer, IStepInfo } from '@frontend/applications/Shared/Wizard/container';
import { PAYMENT_APP_ID } from '@frontend/app/constants/applicationIds';
import { ICard } from '@frontend/applications/PaymentsApp/models';
import { BulkPaymentsTable } from '../BulkPaymentsTable/BulkPaymentsTable';
import { IPaymentGroupMetadata, CREATING_PAYMENTS_STATUS } from '../../containers';
import { PaymentDetails } from '../../containers/PaymentDetails/PaymentDetails';
import { useBulkPaymentComputation } from '../../hooks/useBulkPaymentComputation';

import styles from './BulkPaymentsApp.scss';

const { Paragraph, Title } = Typography;
const { useMemo, useEffect, useState } = React;
const paymentsIcon = require('@frontend/app/assets/svgs/payment_circle.svg');

const PAYMENT_GROUP_DATE_FORMAT = 'MMMM dd, yyyy';

interface IBulkPaymentsAppProps {
  paymentGroupName: string;
  paymentGroupMetadata?: IPaymentGroupMetadata;
  amountToPay: number;
  amountDue?: number;
  showAmountDue: boolean;
  availableBalance: number;
  memberCount: number;
  tableData: IRowData[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onDataSelection: (selectedData: any[]) => void;
  onCancel: () => void;
  onDone: () => void;
  onConfirmPayments: Function;
  onFinishPayment: Function;
  onCardSelected: Function;
  paymentProgress: number;
  creatingPaymentsStatus: CREATING_PAYMENTS_STATUS;
  errorRows: string[];
  selectedData: IRowData[];
  paymentPeriod?: string;
  cards: ICard[];
  onCardAdded: (cards: ICard) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdatedData?: (selectedData: any[]) => void;
  updatedData?: IRowData[];
  migrateToGraphQL: boolean;
  requireHandlingFee?: boolean;
}

export const renderFooterInfoComponent = () => (
  <div className={styles.bottomCaption}>
    <span>If you need to add funds to your account - </span>
    <Link
      className={styles.paymentSettingsLink}
      to={(location) => ({ ...location, pathname: `/settings/${PAYMENT_APP_ID}` })}
    >
      Go To Payment Settings.
    </Link>
  </div>
);

interface IStatsCardParams {
  forInvoice: boolean;
  memberCount: number;
  amountPay: number;
  showAmountDue: boolean;
  amountDue?: number;
  availableBalance?: number;
  paymentPeriod?: string;
}

export const renderStatsCard = ({
  forInvoice,
  memberCount,
  amountPay,
  showAmountDue,
  amountDue,
  availableBalance,
  paymentPeriod,
}: IStatsCardParams) => {
  const statsInfo: IStatisticsCardData[] = [
    {
      title: 'Members',
      value: memberCount,
      format: DataFormat.Integer,
    },
  ];
  if (!forInvoice) {
    statsInfo.push({
      title: 'Available Balance',
      value: availableBalance,
      prefix: '$',
      format: DataFormat.Money,
    });
  }
  if (paymentPeriod) {
    statsInfo.push({
      format: DataFormat.None,
      title: 'Payment Period',
      value: paymentPeriod,
    });
  }
  if (showAmountDue) {
    statsInfo.push({
      title: forInvoice ? 'Amount Due at Time' : 'Amount Due',
      value: amountDue,
      prefix: '$',
      format: DataFormat.Money,
    });
  }
  statsInfo.push({
    title: forInvoice ? 'Amount Paid' : 'Amount to Pay',
    value: amountPay,
    prefix: '$',
    format: DataFormat.Money,
  });

  return <BulkPaymentAppStatisticsCard className={styles.statsCard} data={statsInfo} />;
};

export const BulkPaymentsApp: React.FC<Readonly<IBulkPaymentsAppProps>> = (props) => {
  const [cardSelected, setCardSelected] = useState<boolean>(false);
  const [isConfirmMembers, setIsConfirmMembers] = useState<boolean>(true);
  const [disablePaymentButton, setDisablePaymentButton] = useState<boolean>(true);
  const { totalAmountToPay } = useBulkPaymentComputation(props.amountToPay, props.availableBalance, props.requireHandlingFee);
  // If balance is less than or equal to 0 and credit card is not selected set disablePaymentButton to true
  useEffect(() => {
    if (props.availableBalance > 0 || cardSelected) {
      setDisablePaymentButton(false);
    } else {
      setDisablePaymentButton(true);
    }
  }, [cardSelected, props.availableBalance]);

  const history = useHistory();
  const tableDataWithFormatting = map(props.tableData, (row: IRowData) => {
    if (row.disableSelection) {
      return {
        ...row,
        _bodyRowClassName: styles.disabledRow,
      };
    } else {
      return row;
    }
  });
  const selectedTableDataWithFormatting = (tableData) =>
    map(tableData, (row: IRowData) => {
      if (row.row_id in props.errorRows) {
        return {
          ...row,
          _bodyRowClassName: styles.errorRow,
        };
      } else {
        return row;
      }
    });

  const customTableColumns = props.paymentGroupMetadata?.columns;

  const statsCard = useMemo(
    () =>
      renderStatsCard({
        forInvoice: false,
        memberCount: props.memberCount,
        amountPay: isConfirmMembers ? props.amountToPay : totalAmountToPay,
        amountDue: props.amountDue,
        showAmountDue: props.showAmountDue,
        availableBalance: props.availableBalance,
        paymentPeriod: props.paymentPeriod,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.memberCount, props.amountToPay, props.amountDue, props.showAmountDue, props.availableBalance, isConfirmMembers],
  );
  // used in step 1
  const selectPayments = (
    <Row gutter={[10, 30]}>
      <Col span={24}>{statsCard}</Col>
      {!isEmpty(props.tableData) && (
        <Col span={24}>
          <BulkPaymentsTable
            selectable
            extraColumns={customTableColumns}
            data={tableDataWithFormatting}
            onSelectedData={props.onDataSelection}
            showAmountDue={props.showAmountDue}
            onUpdatedData={props.onUpdatedData}
            migrateToGraphQL={props.migrateToGraphQL}
          />
        </Col>
      )}
    </Row>
  );

  // used in the review and payment.
  const selectedPayments = useMemo(
    () => (
      <Row gutter={[10, 30]}>
        {props.errorRows.length > 0 && (
          <Notice type="alert">
            {props.errorRows.length}
            {' '}
            payment
            {props.errorRows.length > 1 ? 's' : ''}
            {' '}
            failed to send. This could be due to insufficient funds,
            an invalid PayPal email address, payout amount below 50 cents, or another user on your account making bulk
            payments at the same time. Please reach out to support if you need any assistance.
          </Notice>
        )}
        <Col span={24}>{statsCard}</Col>
        <Col span={24}>
          <BulkPaymentsTable
            selectable={false}
            extraColumns={customTableColumns}
            data={selectedTableDataWithFormatting(props.updatedData)}
            showAmountDue={props.showAmountDue}
            onUpdatedData={props.onUpdatedData}
          />
        </Col>
      </Row>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.errorRows, statsCard, customTableColumns, props.updatedData, props.showAmountDue],
  );

  const reviewPaymentComponent = (
    <Row>
      <Col span={24}>
        {statsCard}
      </Col>
      <Col span={24}>
        <PaymentDetails
          onCardAdded={props.onCardAdded}
          creditCards={props.cards}
          amountToPay={props.amountToPay}
          availableBalance={props.availableBalance}
          onCardSelected={props.onCardSelected}
          cardSelected={cardSelected}
          setCardSelected={setCardSelected}
          requireHandlingFee={props.requireHandlingFee}
        />
      </Col>
    </Row>
  );

  const reviewPaymentInstructions = {
    title: 'Confirm payment details',
    description: 'Confirm the payment methods you want to use for this payment.',
  };

  const reviewPaymentNextButton = {
      text: 'Make Payment',
      action: props.onConfirmPayments,
      disabled: disablePaymentButton,
  };

  let paymentCompleteInstruction = `Congrats! You sent ${props.updatedData.length} payment${
    props.updatedData.length > 1 ? 's' : ''
  }.`;

  if (props.errorRows.length > 0) {
    paymentCompleteInstruction = [
      `Well there is good news and bad news. ${props.updatedData.length - props.errorRows.length}`,
      `payments were sent successfully, but ${props.errorRows.length} failed.`,
    ].join(' ');
  }

  const stepsInfo: IStepInfo[] = [
    {
      title: 'Make a Payment',
      stepNum: 1,
      instructions: {
        title: 'Confirm members',
        description: 'Confirm members with payments due and how much to pay them.',
      },
      actionComponents: selectPayments,
      nextButtonConfig: {
        text: 'Payment Details',
        action: () => setIsConfirmMembers(false),
        disabled: props.selectedData.length === 0,
      },
    },
    {
      title: 'Make a Payment',
      stepNum: 2,
      instructions: reviewPaymentInstructions,
      actionComponents: reviewPaymentComponent,
      nextButtonConfig: reviewPaymentNextButton,
      previousButtonConfig:
        {
          text: 'Confirm Members',
          action: () => {
            setIsConfirmMembers(true);
            setCardSelected(false);
            props.onDataSelection([]);
            props.onUpdatedData([]);
          },
        },
    },
    {
      title: 'Payment Invoice',
      stepNum: 3,
      instructions: {
        title: paymentCompleteInstruction,
        description: `Date payment was initiated: ${format(
          new Date(),
          PAYMENT_GROUP_DATE_FORMAT,
        )}. Payments are typically processed within 1-3 business days.`,
      },
      actionComponents: selectedPayments,
      nextButtonConfig: {
        text: 'Done',
        action: props.onDone,
        showIcon: false,
      },
      disableDecrementStep: true,
      previousButtonConfig: {
        text: 'View Payment History',
        action: () => history.push({ ...location, pathname: `/app/${PAYMENT_APP_ID}` }),
        showIcon: false,
      },
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } as any,
  ];

  const footerInfoComponent = useMemo(renderFooterInfoComponent, []);

  return (
    <div className={styles.BulkPaymentsApp}>
      {props.creatingPaymentsStatus === CREATING_PAYMENTS_STATUS.IN_PROGRESS ? (
        <div className={styles.progress}>
          <Title level={4}>Processing payment requests...</Title>
          <Paragraph>Be sure not to close this browser tab while payments are processing.</Paragraph>
          <Progress
            className={styles.progressWidget}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            labelClassName={styles.progressWidgetLabel}
            percentage={Math.round(props.paymentProgress)}
          />
        </div>
      )
        : (
          <WizardContainer
            icon={paymentsIcon}
            initialStep={props.creatingPaymentsStatus === CREATING_PAYMENTS_STATUS.FINISHED ? 3 : null}
            stepsInfo={stepsInfo}
            footerInfoComponent={footerInfoComponent}
            onCancel={props.onCancel}
          />
)}
    </div>
  );
};
