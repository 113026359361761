import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const DocumentFilledIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 12 14"
  >
    <path
      clipRule="evenodd"
      d="
        M8.19668 0.551919L11.1593 3.88525C11.2707 4.00792 11.3327 4.16725 11.3327
        4.33325V11.9999C11.3327 12.9186 10.5687 13.6666 9.62868 13.6666H2.37002C1.43068 13.6666
        0.666016 12.9186 0.666016 11.9999V1.99992C0.666016 1.08125 1.43068 0.333252 2.37002
        0.333252H7.70335C7.89135 0.333252 8.07068 0.412585 8.19668 0.551919ZM7.33268 3.76641C7.33268
        4.07908 7.54601 4.33308 7.80868 4.33308H9.82735L7.33268 1.65175V3.76641ZM7.99935
        10.9999C8.36802 10.9999 8.66602 10.7013 8.66602 10.3333C8.66602 9.96525 8.36802 9.66659
        7.99935 9.66659H3.99935C3.63135 9.66659 3.33268 9.96525 3.33268 10.3333C3.33268 10.7013
        3.63135 10.9999 3.99935 10.9999H7.99935ZM3.99935 6.99992H5.99935C6.36735 6.99992 6.66602
        7.29858 6.66602 7.66658C6.66602 8.03458 6.36735 8.33325 5.99935 8.33325H3.99935C3.63135
        8.33325 3.33268 8.03458 3.33268 7.66658C3.33268 7.29858 3.63135 6.99992 3.99935 6.99992Z
      "
      fillRule="evenodd"
    />
  </SvgIcon>
));

DocumentFilledIcon.displayName = 'DocumentFilledIcon';
