import 'css-chunk:src/components/widgets/ContentGuidelines/ContentGuidelines.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_nbwdh_157",
  "justify-content-space-between": "_justify-content-space-between_nbwdh_161",
  "tabular-nums": "_tabular-nums_nbwdh_165",
  "ContentGuidelines": "_ContentGuidelines_nbwdh_169",
  "header": "_header_nbwdh_204",
  "suggestion": "_suggestion_nbwdh_248",
  "list": "_list_nbwdh_251",
  "sorting": "_sorting_nbwdh_254",
  "listItemWrapper": "_listItemWrapper_nbwdh_254",
  "showingInput": "_showingInput_nbwdh_254",
  "emptyGuideline": "_emptyGuideline_nbwdh_254",
  "hoverBox": "_hoverBox_nbwdh_254",
  "dragHandle": "_dragHandle_nbwdh_257",
  "dragging": "_dragging_nbwdh_284",
  "listItem": "_listItem_nbwdh_254",
  "guidelineWrapper": "_guidelineWrapper_nbwdh_293",
  "guideline": "_guideline_nbwdh_293",
  "inputWrapper": "_inputWrapper_nbwdh_304",
  "input": "_input_nbwdh_304",
  "show": "_show_nbwdh_254"
};