import * as React from 'react';

import { TFilter } from './AnalyzeContext';

const { createContext, useContext } = React;

export interface IAnalyzeCompareContext {
  apiEndpoint: string;
  filters: TFilter;
  organizationId?: string;
  setFilters(filters: TFilter): void;
  isComparing: boolean;
}

export const AnalyzeCompareContext = createContext<IAnalyzeCompareContext>(
  null,
);

export const useAnalyzeCompare = () => useContext(AnalyzeCompareContext);
