import React from 'react';
import {
  IResource,
} from '@frontend/app/hooks';
import { useStartGDriveSyncMutation } from '@frontend/app/hooks/useStartGDriveSyncMutation';
import GDriveSyncComponent from '../components/GDriveSyncComponent';

interface IProps {
  activeAccount: IResource;
  onSyncCompleted: (taskTrackerId: string) => void;
  onSyncStart: () => void;
  inProgress: boolean;
}

const GDriveSyncContainer: React.FC<IProps> = ({
 activeAccount, onSyncCompleted, inProgress, onSyncStart,
}) => {
  const [startSyncMutation] = useStartGDriveSyncMutation({
    onCompleted: (data) => {
      onSyncCompleted(data.taskId);
    },
  });

  const startSync = () => {
    startSyncMutation();
    onSyncStart();
  };

  return (
    <GDriveSyncComponent
      userExternalDisplayId={activeAccount.authProvider.userExternalDisplayId}
      startSync={startSync}
      inProgress={inProgress}
    />
  );
};

GDriveSyncContainer.displayName = 'GDriveSyncContainer';

export default GDriveSyncContainer;
