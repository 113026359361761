import * as React from 'react';
import {
  ITableCellRenderContext,
} from '@components';

import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';

import { MemberNameCell } from './MemberNameCell';

interface IMemberNameCellData {
  id: number;
  name: string;
  profile: string;
  isApplicant: boolean;
}

type TMemberApplicantOperationCallback = (memberId: number, operation: MemberApplicantOperation) => void;

export const renderMemberNameCell = (
  data: IMemberNameCellData,
  context: ITableCellRenderContext,
  onMemberApplicantOperation?: TMemberApplicantOperationCallback,
  onClickName?: (id: number) => void,
) => (
  <MemberNameCell
    selected={context.isRowSelected}
    onSelect={context.onToggleRowSelected}
    editable={false}
    isEditing={context.isEditing}
    onToggleIsEditing={context.onToggleEditing}
    onChangeName={context.onChangeCellValue}
    id={data.id}
    onClickName={onClickName}
    name={data.name}
    photo={data.profile}
    isApplicant={!!onMemberApplicantOperation}
    onMemberApplicantOperation={onMemberApplicantOperation?.bind(this, data.id)}
  />
  );
