/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1tpb8_157 {
  display: flex;
}

._justify-content-space-between_1tpb8_161 {
  justify-content: space-between;
}

._tabular-nums_1tpb8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1tpb8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DragAndDropImageInput_1tpb8_178 ._title_1tpb8_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._DragAndDropImageInput_1tpb8_178 ._subtitle_1tpb8_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._DragAndDropImageInput_1tpb8_178 ._wrapper_1tpb8_198 {
  display: table;
  padding: 16px;
  box-sizing: border-box;
  background-color: #eff5f9;
  border: 1px dashed #3996e0;
  border-radius: 6px;
  cursor: pointer;
  text-align: left;
}
._DragAndDropImageInput_1tpb8_178._hasImage_1tpb8_208 {
  text-align: center;
}
._DragAndDropImageInput_1tpb8_178._hasImage_1tpb8_208 ._wrapper_1tpb8_198 {
  display: inline-block;
}
._DragAndDropImageInput_1tpb8_178:not(._hasImage_1tpb8_208) ._wrapper_1tpb8_198 {
  width: 100%;
}
._DragAndDropImageInput_1tpb8_178 ._iconWrapper_1tpb8_217 {
  display: table-cell;
  vertical-align: middle;
  width: 28px;
}
._DragAndDropImageInput_1tpb8_178 ._right_1tpb8_222 {
  display: table-cell;
  vertical-align: middle;
  padding-left: 16px;
}
._DragAndDropImageInput_1tpb8_178 ._title_1tpb8_178 {
  margin-bottom: 2px;
}
._DragAndDropImageInput_1tpb8_178 ._spinner_1tpb8_230 {
  transform: scale(0.8);
}
._DragAndDropImageInput_1tpb8_178 ._fileSelector_1tpb8_233 {
  display: none;
}

._SelectedImage_1tpb8_237 {
  position: relative;
  width: 165px;
  height: 165px;
}
._SelectedImage_1tpb8_237 ._img_1tpb8_242 {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
._SelectedImage_1tpb8_237 ._closeButton_1tpb8_250 {
  position: absolute;
  right: -30px;
  top: -30px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: 1px solid #e9e8ea;
  background-color: #fdfdfd;
  padding: 4px;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
._SelectedImage_1tpb8_237 ._closeIcon_1tpb8_263 {
  color: #8f8d91;
}