import 'css-chunk:src/app/containers/BudgetReporting/BudgetReportingContainer/components/fiscalYearSettings/fiscalYearSettings.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_kcma8_157",
  "justify-content-space-between": "_justify-content-space-between_kcma8_161",
  "tabular-nums": "_tabular-nums_kcma8_165",
  "referenceText": "_referenceText_kcma8_178",
  "calendarIcon": "_calendarIcon_kcma8_182",
  "enableQuarterlyBudgetTracking": "_enableQuarterlyBudgetTracking_kcma8_188",
  "startMonthFieldContainer": "_startMonthFieldContainer_kcma8_194",
  "fiscalYearStartMonth": "_fiscalYearStartMonth_kcma8_206",
  "quartersNamingStyleTitle": "_quartersNamingStyleTitle_kcma8_216",
  "quarterNamingStyleContainer": "_quarterNamingStyleContainer_kcma8_222",
  "quartersNamingStyleTitleContainer": "_quartersNamingStyleTitleContainer_kcma8_229",
  "quarterNameCell": "_quarterNameCell_kcma8_235",
  "quarterNamingStyleInfo": "_quarterNamingStyleInfo_kcma8_241",
  "iconContainer": "_iconContainer_kcma8_247",
  "icon": "_icon_kcma8_247",
  "currentYearAlert": "_currentYearAlert_kcma8_263",
  "show": "_show_kcma8_1"
};