// social icons
export * from './BlogIcon';
export * from './FacebookIcon';
export * from './GmailIcon';
export * from './HeartFilledIcon';
export * from './InstagramIcon';
export * from './OutlookIcon';
export * from './PinterestIcon';
export * from './SnapchatIcon';
export * from './TiktokIcon';
export * from './TwitterIcon';
export * from './YoutubeIcon';

// file type icons
export * from './FileTypeAviColored';
export * from './FileTypeDocColored';
export * from './FileTypeMp4Colored';
export * from './FileTypePdfColored';
export * from './FileTypeZipColored';

// etc
export * from './CheckCircleYellowIcon';
export * from './ColorAddCircleOutline';
export * from './GoogleDriveVerifiedIcon';
export * from './LikedCircleBlueIcon';
export * from './MessageIcon';
export * from './OfferBlueIcon';
export * from './RecentlyLikedIcon';
