import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  SYNC_ORDERS_MUTATION,
} from '../queries';
import {
  SyncOrdersMutation,
} from '../queries/types/SyncOrdersMutation';

type IOptions = MutationHookOptions<SyncOrdersMutation>;

export function useSyncOrders(options: IOptions = {}) {
  const [syncOrders, { loading, error }] = useMutation<SyncOrdersMutation>(SYNC_ORDERS_MUTATION, options);

  return {
    syncOrders,
    loading,
    error,
  };
}
