import { findIndex, isEmpty, isString } from 'lodash';

import { checkMultipleDomainHasDeepLink, checkUrlsHasSameDomain, createUrlObj } from '@frontend/applications/AffiliatesApp/utils';

import { TState } from '../types';

interface IAction {
  deepLink: string;
  memberId: number;
  offerLink: string;
  focused: boolean;
  type: 'UPDATE MEMBER DEEP LINK';
  domains: string[];
  isCreatorDeepLinkAllowed: boolean;
}
export type TUpdateMemberDeepLinkAction = Readonly<IAction>;

export const updateMemberDeepLink = (state: TState, action: TUpdateMemberDeepLinkAction): TState => {
  const offerLink = createUrlObj(action.offerLink);

  if (!state.members || isString(offerLink)) {
    return state;
  }
  const memberIndex = findIndex(state.members, (m) => m.id === action.memberId);
  if (memberIndex < 0) {
    return state;
  }
  const notUndefined = !isEmpty(action.deepLink);
  const member = state.members[memberIndex];
  const hasValidLink = notUndefined ? member.selected && !isString(createUrlObj(action.deepLink)) : true;
  const hasValidLength = notUndefined && hasValidLink ? action.deepLink.length < 500 : null;
  const hasSameDomain = notUndefined && member.selected ? ((action.isCreatorDeepLinkAllowed && !isEmpty(action.domains)) ? checkMultipleDomainHasDeepLink(action.deepLink, [...action.domains, action.offerLink]) : checkUrlsHasSameDomain(action.offerLink, action.deepLink)) : true;
  let error = null;
  if (!hasValidLink) {
    error = 'Deep Link must be a valid URL';
  }
  if (hasValidLength !== null && !hasValidLength) {
    error = 'Deep link must be less than 500 characters';
  }

  const warning = hasSameDomain ? null : `Ensure proper tracking is setup for your Deep Link URL, as it differs from ${offerLink.origin} ${(action.isCreatorDeepLinkAllowed && !isEmpty(action.domains)) ? `,${action.domains.join(', ')}` : ''}.`;

  return {
    ...state,
    members: [
      ...state.members.slice(0, memberIndex),
      {
        ...state.members[memberIndex],
        deepLink: {
          link: action.deepLink,
          error,
          warning,
          focused: action.focused,
        },
      },
      ...state.members.slice(memberIndex + 1),
    ],
  };
};
