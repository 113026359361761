import * as React from 'react';
import {
  DatePicker,
  Form,
} from '@revfluence/fresh';
import moment, { Moment } from 'moment';
import { IShopifyFormElementProps } from '../../../types';
import styles from '../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'activeDate'> {
  endDate: Moment;
}

export const OfferActiveDate: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  endDate,
}) => {
  const rules = [
    { required: true, message: 'Please Select Date' },
    {
      validator: (_, value) => {
        if (value && value.isSameOrAfter(endDate, 'minute')) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
    },
  ];
  const disabledDate = (current: moment.Moment) =>
    // Disable only days before today
    current && current < moment().startOf('day');
  return (
    <>
      <Form.Item name={name} rules={rules} className={styles.startDateBorder}>
        <DatePicker
          showTime={{ format: 'hh:mm a' }}
          format="MMM D, YYYY hh:mm a"
          disabledDate={disabledDate}
          size="large"
          disabled={disabled}
          style={{ borderColor: 'none' }}
        />
      </Form.Item>

    </>
  );
});
