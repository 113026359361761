import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import {
  UPDATE_AD_ACCOUNTS,
} from '@frontend/app/queries';

import {
  UpdateAdAccounts,
  UpdateAdAccountsVariables,
} from '@frontend/app/queries/types/UpdateAdAccounts';

type IOptions = MutationHookOptions<UpdateAdAccounts, UpdateAdAccountsVariables>;

export const useUpdateAdAccountsMutation = (options: IOptions = {}) => (
  useMutation<UpdateAdAccounts>(UPDATE_AD_ACCOUNTS, options)
);

export type TUseUpdateAdAccountsMutation = ReturnType<typeof useUpdateAdAccountsMutation>;
