import * as React from 'react';
import cx from 'classnames';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { Button } from '@components';
import Cropper, { ReactCropperProps } from 'react-cropper';

import 'cropperjs/dist/cropper.css';

import styles from './CropModal.scss';

const { useCallback, useState } = React;

interface IProps extends ModalProps {
  src?: string;
  cropperProps?: Omit<ReactCropperProps, 'src' | 'onInitialized'>;
  onClickSave?(blob: Blob);
}

export const CropModal: React.FC<IProps> = React.memo((props) => {
  const {
    cropperProps,
    src,
    className,
    onClickSave,
    ...modalProps
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [cropper, setCropper] = useState<any>();

  const handleClickSave = useCallback(() => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob((blob: Blob) => {
        onClickSave(blob);
      });
    }
  }, [onClickSave, cropper]);

  return (
    <Modal
      closable={false}
      footer={null}
      {...modalProps}
      bodyStyle={{ padding: 0, ...modalProps.bodyStyle }}
      className={cx(styles.CropModal, className)}
      destroyOnClose
      maskClosable={false}
    >
      <Cropper
        viewMode={2}
        background={false}
        {...cropperProps}
        src={src}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <div className={styles.btns}>
        <Button label="Cancel" theme="info" onClick={modalProps.onCancel} />
        <Button label="Save" theme="primary" onClick={handleClickSave} />
      </div>
    </Modal>
  );
});

CropModal.displayName = 'CropModal';
