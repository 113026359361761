/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ae3j_157 {
  display: flex;
}

._justify-content-space-between_1ae3j_161 {
  justify-content: space-between;
}

._tabular-nums_1ae3j_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ae3j_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._viewSelect_1ae3j_178 ._button_1ae3j_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailPreviewer_1ae3j_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding-top: 1rem;
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._prevButton_1ae3j_233 {
  margin-right: 1.5rem;
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._nextButton_1ae3j_236 ._icon_1ae3j_236 {
  transform: rotate(180deg);
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._viewSelect_1ae3j_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._viewSelect_1ae3j_178 ._button_1ae3j_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 6.875rem;
  height: 2.25rem;
  background-color: #eff5f9;
  color: #8f8d91;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color, background-color;
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._viewSelect_1ae3j_178 ._button_1ae3j_178._active_1ae3j_260 {
  background-color: #3996e0;
  color: #fdfdfd;
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._viewSelect_1ae3j_178 ._singleView_1ae3j_264 {
  border-radius: 6.25rem 0 0 6.25rem;
}
._EmailPreviewer_1ae3j_178 ._header_1ae3j_178 ._viewSelect_1ae3j_178 ._listView_1ae3j_267 {
  border-radius: 0 6.25rem 6.25rem 0;
}
._EmailPreviewer_1ae3j_178 ._previewerSpace_1ae3j_270 {
  border-top: 0.0625rem solid #dadcde;
  justify-content: flex-end;
  padding: 0.5rem;
}

._LoadingSpinner_1ae3j_276 {
  min-height: 18.75rem;
}