import * as React from 'react';

import { Button, Result } from 'antd';
import { LoadSpinner } from '@components';

import { useAuth } from '@frontend/context/authContext';
import {
 IClient, IProfile, useGetCurrentClient, useGetProfile,
} from '@frontend/app/hooks';

import styles from './ClientContext.module.scss';

interface IClientContext {
  loading: boolean;
  client: IClient;
  profile: IProfile;
  refetch(): void;
  hasError: boolean;
}

const { useContext, useMemo, useCallback } = React;

export const ClientContext = React.createContext<IClientContext>(null);
export const useClientContext = () => useContext(ClientContext);
export const ClientContextProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  ({ children }) => {
    const { logout } = useAuth();

    const {
      loading: loadingClient,
      client,
      error: clientError,
      refetch: refetchClient,
    } = useGetCurrentClient();
    const {
      loading: loadingProfile,
      profile,
      error: profileError,
      refetch: refetchProfile,
    } = useGetProfile();

    const loading = useMemo(() => loadingClient || loadingProfile, [loadingClient, loadingProfile]);
    const hasError = useMemo(() => !!clientError || !!profileError, [clientError, profileError]);
    const isClientActive = useMemo(() => {
      if (!client) {
        return false;
      }

      return client.forceActive || client.active;
    }, [client]);

    const refetch = useCallback(() => {
      refetchClient();
      refetchProfile();
    }, [refetchClient, refetchProfile]);
    return (
      <ClientContext.Provider
        value={{
          loading,
          client,
          profile,
          refetch,
          hasError,
        }}
      >
        <div className={styles.ClientContext}>
          {loading && <LoadSpinner className={styles.loading} />}
          {!loading && hasError && (
            <Result
              className={styles.notice}
              status="500"
              title="Oops"
              subTitle="Looks like there's an error when trying to load the page. If the error persists, please contact support at support@aspire.io."
              extra={<Button onClick={logout}>Log Out</Button>}
            />
          )}
          {!loading && !hasError && !isClientActive && (
            <Result
              className={styles.notice}
              status="403"
              title="This client is no longer active"
              subTitle="If this is unexpected and the error persists, please contact support at support@aspire.io."
              extra={<Button onClick={logout}>Log Out</Button>}
            />
          )}
          {!loading && !hasError && isClientActive && children}
        </div>
      </ClientContext.Provider>
    );
  },
);

ClientContextProvider.displayName = 'ClientContextProvider';
