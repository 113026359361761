import * as React from 'react';
import { isEmpty, reduce } from 'lodash';

import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';

const { useEffect, useState } = React;

export interface IUploadCSVContext {
  dataSize?: number;
  fields?: IField[];
  file?: File;
  headers?: string[];
  initFields?: (fieldCount: number) => void;
  reset: () => void;
  setActivationIdsParam?: (activationIds: number[]) => void;
  setCommunityIdsParam?: (communityIds: number[]) => void;
  setDataSize?: (dataSize: number) => void;
  setFieldAtIndex?: (field: IField, index: number) => void;
  setFields?: (fields: IField[]) => void;
  setFile?: (file: File) => void;
  setHeaders?: (headers: string[]) => void;
  setOwnerIdsParam?: (ownerIds: string[]) => void;
  setProgramIdsParam?: (programIds: number[]) => void;
  uploadCsvParams?: Required<IUploadCsvParams>;
}

export interface IUploadCsvParams {
  file: File;
  params: {
    activationIds?: number[];
    communityIds?: number[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    memberFieldsMapping?: { [index: string]: any; };
    ownerIds?: string[];
    programIds?: number[];
  };
}

export const UploadCSVContext = React.createContext<IUploadCSVContext>(null);

export const UploadCSVContextProvider = ({ children }) => {
  const [file, setFile] = useState<IUploadCSVContext['file']>();
  const [fields, setFields] = useState<IUploadCSVContext['fields']>([]);
  const [dataSize, setDataSize] = useState<IUploadCSVContext['dataSize']>();
  const [headers, setHeaders] = useState<IUploadCSVContext['headers']>();
  const [uploadCsvParams, setUploadCsvParams] = useState<IUploadCsvParams>();

  const initFields = (fieldCount: number) => {
    if (fieldCount) {
      setFields(new Array(fieldCount));
    }
  };

  const setFieldAtIndex = (field: IField, index: number) => {
    if (index < fields.length) {
      setFields((f) => {
        const newFields = [...f];
        newFields[index] = field;
        return newFields;
      });
    }
  };

  // uploadCSV params
  useEffect(
    () => {
      setUploadCsvParams((p) => ({
        ...p,
        file,
      }));
    },
    [file],
  );
  useEffect(
    () => {
      if (isEmpty(fields) || isEmpty(headers)) {
        return;
      }
      setUploadCsvParams((p) => ({
        ...p,
        params: {
          ...p?.params,
          memberFieldsMapping: reduce(
            fields,
            (result, field, index) => {
              const header = headers[index];
              if (['id', 'uid', '__uid'].includes(header.toLocaleLowerCase())) {
                return result;
              }
              result[header] = field?.headerName || null;
              return result;
            },
            {},
          ),
        },
      }));
    },
    [fields, headers],
  );
  const setActivationIdsParam = (activationIds: number[]) => (
    setUploadCsvParams((p) => ({
      ...p,
      params: {
        ...p?.params,
        activationIds,
      },
    }))
  );
  const setCommunityIdsParam = (communityIds: number[]) => (
    setUploadCsvParams((p) => ({
      ...p,
      params: {
        ...p?.params,
        communityIds,
      },
    }))
  );
  const setOwnerIdsParam = (ownerIds: string[]) => (
    setUploadCsvParams((p) => ({
      ...p,
      params: {
        ...p?.params,
        ownerIds,
      },
    }))
  );
  const setProgramIdsParam = (programIds: number[]) => (
    setUploadCsvParams((p) => ({
      ...p,
      params: {
        ...p?.params,
        programIds,
      },
    }))
  );

  const reset = () => {
    setFile(undefined);
    setFields([]);
    setDataSize(undefined);
    setHeaders(undefined);
    setUploadCsvParams(undefined);
  };

  const value: IUploadCSVContext = {
    dataSize,
    fields,
    file,
    headers,
    initFields,
    reset,
    setActivationIdsParam,
    setCommunityIdsParam,
    setDataSize,
    setFieldAtIndex,
    setFields,
    setFile,
    setHeaders,
    setOwnerIdsParam,
    setProgramIdsParam,
    uploadCsvParams: uploadCsvParams as Required<IUploadCsvParams>,
  };

  return (
    <UploadCSVContext.Provider value={value}>
      {children}
    </UploadCSVContext.Provider>
  );
};
