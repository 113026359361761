import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ORDER_SUMMARY_QUERY } from '../queries';
import { GetOrderSummaryQuery } from '../queries/types/GetOrderSummaryQuery';

type IOptions = QueryHookOptions<GetOrderSummaryQuery>;

export function useGetOrderSummary(options: IOptions = {}) {
  const {
 loading, data: { summary } = {}, error, refetch,
} = useQuery<GetOrderSummaryQuery>(
    GET_ORDER_SUMMARY_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    summary,
    error,
    refetch,
  };
}
