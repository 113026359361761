import * as React from 'react';
import cx from 'classnames';
import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import { ProgramList } from './ProgramList';
import { ProgramDetail } from './ProgramDetail';

import styles from './ProgramsPage.scss';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const ProgramsPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();

  return (
    <div className={cx(styles.ProgramsPage, props.className)}>
      <Switch>
        <Route path={`${match.path}`} exact component={ProgramList} />
        <Route path={`${match.path}/:programId`} component={ProgramDetail} />
      </Switch>
    </div>
  );
});

ProgramsPage.displayName = 'ProgramsPage';
