import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const CloseModalIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <circle cx="9.90583147" cy="9.90583147" r="9.90583147" />
    <g transform="translate(5.943499, 5.943499)" fill="#FFFFFF">
      <path d="M6.82280006,0.138437678 L0.138437678,6.82280006 C-0.0461458926,7.00738363 -0.0461458926,7.30665273 0.138437678,7.4912363 C0.323021248,7.67581987 0.622290346,7.67581987 0.806873916,7.4912363 L7.4912363,0.806873916 C7.67581987,0.622290346 7.67581987,0.323021248 7.4912363,0.138437678 C7.30665273,-0.0461458926 7.00738363,-0.0461458926 6.82280006,0.138437678 L6.82280006,0.138437678 Z" />
      <path d="M7.4912363,6.82280006 L0.806873916,0.138437678 C0.622290346,-0.0461458926 0.323021248,-0.0461458926 0.138437678,0.138437678 C-0.0461458926,0.323021248 -0.0461458926,0.622290346 0.138437678,0.806873916 L6.82280006,7.4912363 C7.00738363,7.67581987 7.30665273,7.67581987 7.4912363,7.4912363 C7.67581987,7.30665273 7.67581987,7.00738363 7.4912363,6.82280006 L7.4912363,6.82280006 Z" />
    </g>
  </SvgIcon>
));

CloseModalIcon.displayName = 'CloseModalIcon';
