import * as React from 'react';
import {
 Redirect, Route, Switch, withRouter,
} from 'react-router-dom';

import { ErrorBoundary } from '@frontend/utils';
import { EmbeddedReporting } from '@frontend/app/containers/EmbeddedReporting/EmbeddedReporting';
import { TopNavbar } from './TopNavbar';
import { ProgramsPage } from './Programs';

import styles from './GuestHome.scss';
import { GroupsPagePage } from './Groups/GroupsPage';
import { GroupCreatorDetail } from './Groups/GroupCreatorDetail/GroupCreatorDetail';

const GuestHomePage: React.FC = React.memo(() => (
  <div className={styles.GuestHome}>
    <TopNavbar className={styles.nav} />
    <div className={styles.content}>
      <ErrorBoundary>
        <Switch>
          <Route path="/programs" component={ProgramsPage} />
          <Route path="/groups" component={GroupsPagePage} />
          <Route path="/creator" component={GroupCreatorDetail} />
          <Route path="/embedded_reporting/:id" component={EmbeddedReporting} />
          <Redirect to="/programs" />
        </Switch>
      </ErrorBoundary>
    </div>
  </div>
  ));

GuestHomePage.displayName = 'GuestHomePage';

const GuestHome = withRouter(GuestHomePage);

GuestHome.displayName = 'GuestHomeWithRouter';

export { GuestHome };
