import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { PROJECT_BY_ID_QUERY } from '@frontend/app/queries';
import {
  ProjectByIdQuery,
  ProjectByIdQueryVariables,
} from '@frontend/app/queries/types/ProjectByIdQuery';

type IOptions = QueryHookOptions<ProjectByIdQuery, ProjectByIdQueryVariables>;

export const useProjectByIdQuery = (options: IOptions = {}) => (
  useQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(PROJECT_BY_ID_QUERY, options)
);
