import * as React from 'react';
import cx from 'classnames';
import { map, findIndex } from 'lodash';

import {
  LoadSpinner, Notice, Select, AreaChart,
} from '@components';

const { useMemo } = React;
import styles from './InsightSection.scss';

export interface IInsightData {
  date: string;
  value: number;
  engagements: number;
  impressions: number;
  reach: number;
}
interface IProps {
  className?: string;
  data: { loading: boolean, data: IInsightData[], error: Error | null };
}

const dropDownValues = [
  {
    value: 'impressions',
    label: 'Impressions',
  },
  {
    value: 'engagements',
    label: 'Engagements',
  },
  {
    value: 'reach',
    label: 'Potential Reach',
  },
];

export const InsightSection: React.FunctionComponent<IProps> = React.memo(
  (props) => {
    const { loading, data, error } = props.data;
    const [selection, setSelection] = React.useState('engagements');

    const records = useMemo(
      () =>
        map(data, (record) => ({
          x: new Date(record.date),
          y: record[selection],
        })),
      [data, selection],
    );

    if (error) {
      return (
        <Notice className={(styles as any).notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }
    const selectedIndex = useMemo(() => findIndex(dropDownValues, (p) => p.value === selection), [selection]);

    const onChange = (value) => setSelection(value);
    const getTitle = (value) => {
      if (value === 'engagements') {
        return 'Engagement';
      }
      if (value === 'impressions') {
        return 'Impression';
      }
      if (value === 'reach') {
        return 'Potential Reach';
      }
    };
    return (
      <div className={cx(styles.InsightSection, props.className)}>
        {loading ? <LoadSpinner centered /> : (
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>
                  {getTitle(selection)}
                  {' '}
                  Over Time
                </h3>
              </div>
              <Select
                theme="info"
                className={styles.select}
                options={dropDownValues}
                onChange={onChange}
                selectedIndex={selectedIndex}
              />
            </div>
            <div className={styles.main}>
              <AreaChart data={records} />
            </div>
          </div>
        )}
      </div>
    );
  },
);

InsightSection.defaultProps = {
  className: null,
};
