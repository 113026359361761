import * as React from 'react';
import { useQuery } from '@apollo/client';
import {
  ThreadFilterInput,
} from '@frontend/app/types/globalTypes';
import { GET_THREADS_QUERY } from '@frontend/app/queries';
import {
  GetThreadsQuery, GetThreadsQueryVariables,
  GetThreadsQuery_threads,
} from '@frontend/app/queries/types/GetThreadsQuery';
import { ApolloError } from 'apollo-client';
import { logger } from '@common';

const { useState, useEffect } = React;

export type IThread = GetThreadsQuery_threads;

export function useFetchThreads(filter?: ThreadFilterInput) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ApolloError>(null);

  const {
    loading: loadingThreads,
    data,
    refetch,
  } = useQuery<GetThreadsQuery, GetThreadsQueryVariables>(
    GET_THREADS_QUERY,
    {
      variables: {
        skip: 0,
        limit: 20,
        filter,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: false,
    },
  );

  useEffect(() => {
    setLoading(loadingThreads);
  }, [loadingThreads]);

  const handleRefetch = async (filter) => {
    try {
      setLoading(true);
      setError(null);
      const data = await refetch(filter);
      setLoading(false);
      return data?.data?.threads || [];
    } catch (error) {
      logger.error(error);
      setError(error);
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    data: data?.threads || [],
    refetch: handleRefetch,
  };
}
