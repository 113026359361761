import * as React from 'react';
import { map } from 'lodash';

import {
 IContentList, LoadSpinner, Tabs, Tab,
} from '@components';

import { ConnectedImageSelectList } from '../connected/ConnectedImageSelectList';

import styles from './ImageSelect.scss';

const { useState } = React;

interface IProps {
  contentLists: IContentList[];
  isLoading?: boolean;
  hasError?: boolean;
  onSelectImage?(imageURL: string);
}

export const ImageSelect: React.FunctionComponent<IProps> = React.memo((props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTab = (index: number) => {
    setTabIndex(index);
  };

  if (props.isLoading) {
    return (
      <div className={styles.ImageSelect}>
        <LoadSpinner className={styles.spinner} />
      </div>
    );
  } else if (props.hasError) {
    return (
      <div className={styles.ImageSelect}>
        <span className={styles.error}>Error loading content lists</span>
      </div>
    );
  }

  return (
    <Tabs className={styles.ImageSelect} onChangeTab={handleChangeTab}>
      {map(props.contentLists, (list, idx) => (
        <Tab key={list.id} title={list.name} className={styles.tabContent}>
          {tabIndex === idx ? (
            <ConnectedImageSelectList contentList={list} onSelectImage={props.onSelectImage} />
          ) : null}
        </Tab>
      ))}
    </Tabs>
  );
});

ImageSelect.defaultProps = {
  isLoading: false,
  hasError: false,
};
