import * as React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLError } from 'graphql/error';

const { useMemo } = React;
const customFetch = (uri, options) => fetch(uri, options)
  .then((response) => {
    if (response.status >= 500) { // or handle 400 errors
      throw new GraphQLError('We are unable to process your request at this time. Please try again later.');
    }
    return response;
  });
export const useApolloClient = (userToken?: string) => useMemo(() => {
    const httpLink = new HttpLink({
      uri: '/pfa_graphql',
      fetch: customFetch,

    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${userToken}`,
      },
    }));

    const client = new ApolloClient({
      link: ApolloLink.from([authLink.concat(httpLink)]),
      cache: new InMemoryCache(),
    });

    return client;
  }, [userToken]);
