import * as React from 'react';
import * as querystring from 'querystring';

import { TNetworkIdentifier } from '@components';

import { logger } from '@common';

const { useCallback, useEffect, useState } = React;

export interface ISocialAccount {
  id: number;
  username: string;
  has_social_listening_api_access: boolean;
  has_creator_marketplace_api_access: boolean;
  has_messaging_api_access: boolean;
  has_analytics_api_access: boolean;
  network_identifier: TNetworkIdentifier;
  has_branded_content_tag_access: boolean;
  has_partnership_ads_access: boolean;
  has_messaging_toggle_enabled: boolean;
}

const fetchSocialData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

interface IUseFetchSocialAccountDataOptions {
  url: string;
  clientId: string;
  username: string;
  fetchAll?: boolean;
  network?: TNetworkIdentifier;
}

export function useFetchSocialAccountData(options: IUseFetchSocialAccountDataOptions) {
  const {
    url: baseUrl,
    clientId,
    username,
    fetchAll,
    network,
  } = options;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISocialAccount[]>(null);
  const [error, setError] = useState(null);

  const filterString = querystring.stringify({
    member_username: username,
    client_id: clientId,
    network,
  });
  const url = `${baseUrl}?${filterString}`;

  const fetch = useCallback(() => {
    if (username || fetchAll) {
      setLoading(true);
      fetchSocialData(url)
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [username, fetchAll, url]);

  useEffect(fetch, [fetch]);

  return {
    loading,
    data,
    error,
    refetch: fetch,
  };
}
