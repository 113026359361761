import * as React from 'react';
import cx from 'classnames';
import { Typography } from 'antd';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { Button } from '@revfluence/fresh';
import { ArrowRotateRightIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from '../ThreadList.scss';
import { IGDMThreadListHeaderProps } from './model';

const { Text } = Typography;

export const IGDMThreadListHeader = ({
  count,
  title,
  syncing,
  loadingThreads,
  onRefreshMessages,
}: IGDMThreadListHeaderProps) => {
  const isInboxSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);

  return (
    <div className={cx(
      styles.header,
      {
        [styles.legacy]: !isInboxSearchFilter,
      },
    )}
    >
      <div className={styles.titleIGDM}>
        {!isInboxSearchFilter && (
          <>
            <Text ellipsis className={styles.title}>{`@${title}`}</Text>
            <div className={styles.count}>{count || 0}</div>
          </>
        )}
        <Button
          size="middle"
          type="text"
          icon={<ArrowRotateRightIcon />}
          loading={loadingThreads || syncing}
          disabled={loadingThreads || syncing}
          onClick={onRefreshMessages}
        />
      </div>
    </div>
  );
};
