import gql from 'graphql-tag';

import { MEMBER_FRAGMENT } from './fragments';

export const UPDATE_MEMBER = gql`
  mutation UpdateMemberMutation($id: Int!, $member: MemberInput!) {
    member: updateMember(id: $id, member: $member) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;
