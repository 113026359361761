import {
  QueryHookOptions,
  QueryResult,
  useQuery,
} from '@apollo/client';

import { TERMS_CONFIG_QUERY } from '@frontend/app/queries';
import {
  TermsConfigsQuery,
  TermsConfigsQueryVariables,
} from '@frontend/app/queries/types/TermsConfigsQuery';

type IOptions = QueryHookOptions<TermsConfigsQuery, TermsConfigsQueryVariables>;

export const useTermsConfigQuery = (options: IOptions): QueryResult<TermsConfigsQuery> => (
  useQuery<TermsConfigsQuery, TermsConfigsQueryVariables>(TERMS_CONFIG_QUERY, options)
);
