export const RAW_IMAGE_EXTENSIONS = {
  arw: 'image/x-sony-arw',
  cr2: 'image/x-canon-cr2',
  crw: 'image/x-canon-crw',
  dcr: 'image/x-kodak-dcr',
  dng: 'image/x-adobe-dng',
  erf: 'image/x-epson-erf',
  k25: 'image/x-kodac-k25',
  kdc: 'image/x-kodac-kdc',
  mrw: 'image/x-minolta-mrw',
  nef: 'image/x-nikon-nef',
  orf: 'image/x-olympus-orf',
  pef: 'image/x-pentax-pef',
  raf: 'image/x-fuji-raf',
  raw: 'image/x-panasonic-raw',
  sr2: 'image/x-sony-sr2',
  srf: 'image/x-sony-srf',
  x3f: 'image/x-sigma-x3f',
};
