import * as React from 'react';
import cx from 'classnames';
import {
 filter, isEmpty, map, uniqueId,
} from 'lodash';
import { format } from 'date-fns';
import { Popover } from 'antd';

import { Image } from '@components';

import styles from './ImagesCell.scss';

interface IProps {
  images: IImageData[];
  className?: string;
}

interface IImageData {
  text: string;
  image: string;
  date_posted: string;
  link: string;
}

export const ImagesCell: React.FC<IProps> = React.memo((props) => {
  const {
    className,
    images,
  } = props;

  const renderContent = (image: IImageData) => {
    const cleanText = image.text?.replace(/\s*\n\s*\./g, '') || '';
    return (
      <div
        className={styles.popoverContent}
        onClick={(e) => e.stopPropagation()}
      >
        <a
          href={image.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            className={styles.image}
            src={image.image}
          />
          {image.date_posted && (
            <div className={styles.date}>
              {format(new Date(image.date_posted), 'MMM d, yyyy')}
            </div>
          )}
        </a>
        {cleanText
          .substring(0, 200)
          .concat(cleanText.length > 200 ? '...' : '')
          .split('\n')
          .map((text, i, arr) => (
            <p key={uniqueId('image_text_')}>
              {text}
              {i + 1 === arr.length && image.link && (
              <>
                &nbsp;
                <a
                  href={image.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              </>
)}
            </p>
          ))}
      </div>
    );
  };

  const renderThumbnail = (image: IImageData) => (
    <div
      key={uniqueId('image_')}
      className={styles.image}
    >
      <Popover
        content={renderContent(image)}
        overlayClassName={styles.popoverOverlay}
      >
        <Image
          className={styles.thumb}
          src={image.image}
          onClick={(e) => {
            if (image.link) {
              e.preventDefault();
              e.stopPropagation();
              window.open(image.link, '_blank');
            }
          }}
        />
      </Popover>
    </div>
  );

  return (
    <div
      className={cx(styles.ImagesCell, className)}
      onClick={(e) => e.stopPropagation()}
    >
      {map(
        filter(images, (image) => !isEmpty(image.image)),
        (image) => renderThumbnail(image),
      )}
    </div>
  );
});

ImagesCell.displayName = 'ImagesCell';
