import React from 'react';
import classNames from 'classnames';
import { cva } from 'class-variance-authority';
import { cn } from '@frontend/shadcn/lib/utils';

export type LegendOptionProps = {
  label: string;
  color: string;
  value?: string | number;
  className?: string;
  shape?: 'circle' | 'square';
  direction?: 'horizontal' | 'vertical';
};

const wrapperVariants = cva(
  'flex',
  {
    variants: {
      direction: {
        horizontal: 'flex-row gap-2',
        vertical: 'flex-col',
      },
    },
    defaultVariants: {
      direction: 'horizontal',
    },
  },
);

const indicatorVariants = cva(
  'w-3 h-3',
  {
    variants: {
      shape: {
        circle: 'rounded-full',
        square: 'rounded',
      },
    },
    defaultVariants: {
      shape: 'circle',
    },
  },
);

const valueVariants = cva(
  'font-medium',
  {
    variants: {
      direction: {
        horizontal: '',
        vertical: 'ml-5',
      },
    },
    defaultVariants: {
      direction: 'horizontal',
    },
  },
);

const LegendOption: React.FC<LegendOptionProps> = ({
  label,
  color,
  value,
  className,
  shape = 'circle',
  direction = 'horizontal',
}) => {
  const baseClass = classNames(wrapperVariants({ direction }), className);

  return (
    <div className={baseClass}>
      <div className="flex items-center gap-2">
        <div
          className={cn(indicatorVariants({ shape }))}
          style={{ backgroundColor: color }}
        />
        <span className="text-gray-500">{label}</span>
      </div>
      {value && <span className={cn(valueVariants({ direction }))}>{value}</span>}
    </div>
  );
};

export default LegendOption;
