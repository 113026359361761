import { TWorkItem } from '@frontend/app/containers/Projects/types';

export const getOrderIdFromWorkItem = (workItem: TWorkItem): string => (
  // SendProductWorkletSpecification tasks
  workItem.data?.order_in_transit_task_data?.orderId
  || workItem.data?.send_order_task_data?.orderId
  || workItem.data?.submit_order_task_data?.orderId
  // CreatorProductSelectionWorkletSpecification tasks
  || workItem.data?.send_order_request_task_data?.orderId
  || workItem.data?.waiting_for_order_request_task_data?.orderId
  || workItem.data?.review_order_request_task_data?.orderId
  || workItem.data?.processing_creator_order_task_data?.orderId
  || workItem.data?.creator_order_in_transit_task_data?.orderId
  // OfflineCreatorProductSelectionWorkletSpecification tasks
  || workItem.data?.offline_send_order_request_task_data?.orderId
  || workItem.data?.offline_sending_order_request_task_data?.orderId
  || workItem.data?.offline_waiting_for_order_request_task_data?.orderId
  || workItem.data?.offline_review_order_request_task_data?.orderId
  || workItem.data?.offline_processing_order_request_review_task_data?.orderId
  || workItem.data?.offline_processing_creator_order_task_data?.orderId
  || workItem.data?.offline_process_order_task_data?.orderId
  || workItem.data?.offline_fulfill_order_task_data?.orderId
  || workItem.data?.offline_processing_fulfill_order_task_data?.orderId
  || workItem.data?.offline_order_in_transit_task_data?.orderId
  // SendBrandProductCatalogWorkletSpecification tasks
  || workItem.data?.pfa_v2_send_order_request_task_data?.orderId
  || workItem.data?.pfa_v2_waiting_for_order_request_task_data?.orderId
  || workItem.data?.pfa_v2_review_order_request_task_data?.orderId
  || workItem.data?.pfa_v2_processing_creator_order_task_data?.orderId
  || workItem.data?.pfa_v2_creator_order_in_transit_task_data?.orderId
);
