import { useMutation, MutationHookOptions } from '@apollo/client';
import { DataProxy } from 'apollo-cache';
import { unionBy } from 'lodash';

import { MEMBER_FIELD_SCHEMAS_QUERY } from '@frontend/app/queries';
import {
  MemberFieldSchemasQuery,
} from '@frontend/app/queries/types/MemberFieldSchemasQuery';

import { SAVE_CUSTOM_FIELD } from '@frontend/app/queries';
import {
  SaveMemberFieldSchemaMutation,
  SaveMemberFieldSchemaMutationVariables,
  SaveMemberFieldSchemaMutation_schema as ISchema,
} from '@frontend/app/queries/types/SaveMemberFieldSchemaMutation';

type IOptions = MutationHookOptions<SaveMemberFieldSchemaMutation, SaveMemberFieldSchemaMutationVariables>;

export const useSaveMemberFieldSchema = (options: IOptions = {}) => {
  const mutation = useMutation<SaveMemberFieldSchemaMutation, SaveMemberFieldSchemaMutationVariables>(SAVE_CUSTOM_FIELD, options);

  const [saveFn] = mutation;

  const saveFnWrapper: typeof saveFn = (...args) => {
    const [thisOptions = {}] = args;

    const {
      schema: schemaVars,
    } = thisOptions.variables || options.variables;

    // Rewrite update option to include updating the cache.
    args[0] = {
      ...thisOptions,
      update(...updateArgs) {
        if (options.update) {
          options.update(...updateArgs);
        }

        if (!schemaVars.id) {
          // This is a new schema.
          const [store, result] = updateArgs;
          const schema = result.data.schema;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updateAllSchemaCache(store as any, schema);
        }
      },
    };

    return saveFn(...args);
  };

  mutation[0] = saveFnWrapper;

  return mutation;
};

const updateAllSchemaCache = (store: DataProxy, schema: ISchema) => {
  const cache = store.readQuery<MemberFieldSchemasQuery>({
    query: MEMBER_FIELD_SCHEMAS_QUERY,
  });

  // Do nothing. Cache doesn't exist.
  if (!cache) {
    return;
  }

  const newCache: MemberFieldSchemasQuery = {
    ...cache,
    schemas: unionBy([schema], cache.schemas, 'id'),
  };

  store.writeQuery({
    query: MEMBER_FIELD_SCHEMAS_QUERY,
    data: newCache,
  });
};
