import * as React from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { SendOrderRequestEmail } from '../CreatorProductSelection/components/SendOrderRequestEmail/SendOrderRequestEmail';
import { ProjectConfigType } from '../types/globalTypes';
import { ReviewOrderContainer } from '../NoCatalogueProductSelection/containers/ReviewOrderContainer/ReviewOrderContainer';
import { ShipmentProcessingContainer } from '../NoCatalogueProductSelection/containers/ShipmentProcessingContainer/ShipmentProcessingContainer';

export const OfflineCPS: React.FC = () => {
  const { workflowActionParameters } = useApplication();
  if (workflowActionParameters.taskId === 'offline_send_order_request_task') {
    return <SendOrderRequestEmail type={ProjectConfigType.NoCatalogue} />;
  }
  if (workflowActionParameters.taskId === 'offline_review_order_request_task') {
    return <ReviewOrderContainer />;
  }
  if (workflowActionParameters.taskId === 'offline_fulfill_order_task') {
    return <ShipmentProcessingContainer />;
  }
};
