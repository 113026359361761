import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducer from './reducer';
import { refreshSearchResults } from './middleware';
import { IConnectSearchPage } from '../models';

export function configureStore(initialState?: Partial<IConnectSearchPage>) {

  const middlewares = [thunk, refreshSearchResults];

  /** Add Only Dev. Middlewares */
  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger();
    middlewares.push(logger);
  }

  const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);

  const store = createStoreWithMiddleware<IConnectSearchPage>(reducer, initialState as IConnectSearchPage);

  return store;
}
