export interface IBrand {
  rating: number;
  connect_campaign_ids: number[];
  liked_content_list_id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  last_event_ts?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  integrations: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exclusivity_language?: any;
  balance_in_cents: number[];
  id: number;
  currency_xrate: number;
  can_show_followers_driven: boolean;
  recent_image_search_list_id: number;
  instagram_username: string;
  preferred_pricing_multiplier: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  competitor_accounts: any[];
  fb_analyze_share_url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instagram_hashtag_permission?: any;
  content_analyze_share_url: string;
  rating_count: number;
  logo_url: string;
  website: string;
  direct_offers_remaining: number;
  active_campaign_ids: number[];
  max_campaigns_reached: boolean;
  instagram_previews: string[];
  advertiser_id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  drive_url?: any;
  instagram_id: number;
  license_terms_template: ILicenseTermsTemplate;
  about: string;
  api_key: string;
  name: string;
  campaign_ids: number[];
  content_share_url: string;
  num_active_campaigns: number;
  currency_code: string;
  advertiser_access_usage_limit?: number;
  has_tagged_mentions_access: boolean;
}

interface ILicenseTermsTemplate {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  terms_text?: any;
}
