import * as React from 'react';
import { EarthAmericasIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  FacebookIcon,
  InstagramIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';

const { useMemo } = React;

enum SocialNetworkName {
  Blog = 'Blog',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Pinterest = 'Pinterest',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  YouTube = 'YouTube',
}

interface Props {
  name: string;
}

const SocialIcon = (props: Props) => {
  const icon = useMemo(() => {
    switch (props.name) {
      case SocialNetworkName.Blog:
        return <EarthAmericasIcon />;
      case SocialNetworkName.Facebook:
        return <FacebookIcon />;
      case SocialNetworkName.Instagram:
        return <InstagramIcon />;
      case SocialNetworkName.Pinterest:
        return <PinterestIcon />;
      case SocialNetworkName.TikTok:
        return <TiktokIcon />;
      case SocialNetworkName.Twitter:
        return <TwitterIcon />;
      case SocialNetworkName.YouTube:
        return <YoutubeIcon />;
    }

    return null;
  }, [props.name]);

  return icon;
};

export default SocialIcon;
