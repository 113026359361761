import * as React from 'react';
import { isFunction, size, uniq } from 'lodash';

import { useDeleteMemberById } from './useDeleteMemberById';
import { useDeleteMemberByIdAsync } from './useDeleteMemberByIdAsync';

const { useCallback, useState } = React;

interface IOptions {
  memberIds: number[],
  skip?: boolean,
  isSync?: boolean,
}

interface IDeleteMembers {
  onSuccess?(count: number, message: string),
  onFailure?(error: string[]),
  onFinish?(deletedMemberIds: number[]),
}

interface IUseDeleteMembersById {
  isDeleting: boolean,
  deleteMembers(options: IDeleteMembers),
}

export const useDeleteMembersById = (options: IOptions): IUseDeleteMembersById => {
  const {
    memberIds,
    skip,
    isSync,
  } = options;

  const [isDeleting, setIsDeleting] = useState(false);

  const [deleteMember_sync] = useDeleteMemberById();
  const [deleteMember_async] = useDeleteMemberByIdAsync();

  const deleteMembers = useCallback(async (options: IDeleteMembers) => {
    const {
      onSuccess,
      onFailure,
      onFinish,
    } = options;

    if (skip) {
      return;
    }

    setIsDeleting(true);

    const uniqueMembers = uniq(memberIds);

    let membersDeleted = [];
    let membersWithErrors = [];

    // With this loop we have more control than with Promise.all
    // Maybe needed in the future for processing big requests.
    for (let i = 0; i < size(uniqueMembers); i++) {
      try {
        if (isSync) {
          await deleteMember_sync({
            variables: {
              id: uniqueMembers[i],
            },
          });
        } else {
          await deleteMember_async({
            variables: {
              id: uniqueMembers[i],
            },
          });
        }
        membersDeleted = [...membersDeleted, uniqueMembers[i]];
      } catch (err) {
        membersWithErrors = [...membersWithErrors, err.message];
      }
    }

    const countDeleted = size(membersDeleted);

    if (countDeleted > 0) {
      if (isFunction(onSuccess)) {
        const memberWord = `member${countDeleted > 1 ? 's' : ''}`;
        let message;
        if (isSync) {
          message = `${countDeleted} ${memberWord} deleted`;
        } else {
          const memberVerb = countDeleted === 1 ? 'is' : 'are';
          message = `${countDeleted} ${memberWord} ${memberVerb} being deleted, feel free to continue working`;
        }
        onSuccess(countDeleted, message);
      }
    } else {
      onFailure(membersWithErrors);
    }

    if (isFunction(onFinish)) {
      onFinish(membersDeleted);
    }

    setIsDeleting(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberIds, skip]);

  return {
    isDeleting,
    deleteMembers,
  };
};
