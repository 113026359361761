import { isEmpty, trim } from 'lodash';

import { TSelectedMember } from '../types';

export const sortMembers = (a: TSelectedMember, b: TSelectedMember): number => {
  // sort non forceCheckIn members to bottom
  if (!a.forceCheckIn && b.forceCheckIn) {
    return -1;
  }
  if (a.forceCheckIn && !b.forceCheckIn) {
    return 1;
  }
  // sort users without codes to the top
  if (isEmpty(a.code) && !isEmpty(b.code)) {
    return -1;
  }
  if (!isEmpty(a.code) && isEmpty(b.code)) {
    return 1;
  }
  // fallback to sorting by name
  const aName = trim(a.name).toLowerCase();
  const bName = trim(b.name).toLowerCase();
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};
