import { MutationHookOptions, useMutation } from '@apollo/client';
import { RSYNC_IMPORTED_COLLECTION_BY_ID_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { ResyncImportedCollectionById, ResyncImportedCollectionByIdVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/ResyncImportedCollectionById';

type IOptions = MutationHookOptions<ResyncImportedCollectionById, ResyncImportedCollectionByIdVariables>;

export const useResyncImportedCollectionById = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<ResyncImportedCollectionById, ResyncImportedCollectionByIdVariables>(
    RSYNC_IMPORTED_COLLECTION_BY_ID_MUTATION,
    options,
  );

  return {
    mutate,
    loading,
  };
};
