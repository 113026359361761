import gql from 'graphql-tag';

import { CLIENT_METADATA_FRAGMENT } from './fragments';

export const GET_CURRENT_CLIENT_METADATA_QUERY = gql`
  query GetCurrentClientMetadataQuery {
    clientMetadata: getCurrentClientMetadata {
      ...ClientMetadataFragment
    }
  }
  ${CLIENT_METADATA_FRAGMENT}
`;
