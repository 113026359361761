import * as React from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_SEGMENT_FOLDERS } from '@frontend/app/queries';
import {
  SegmentFoldersQuery,
  SegmentFoldersQueryVariables,
} from '@frontend/app/queries/types/SegmentFoldersQuery';
import { GET_PREDEFINED_SEGMENTS_FOR_COMMUNITY } from '@frontend/app/queries/PredefinedSegmentsQuery';
import {
  PredefinedSegmentsQuery,
  PredefinedSegmentsQueryVariables,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { MEMBER_SEARCH_COUNT_QUERY } from '@frontend/app/queries/SearchMemberCountQuery';
import {
  MemberSearchCountQuery,
  MemberSearchCountQueryVariables,
} from '@frontend/app/queries/types/MemberSearchCountQuery';

import { useMemberSearchQueryVariables } from './useMemberSearchQueryVariables';

const { useCallback } = React;

export const useReloadSegmentCounts = (communityId: number, sourceGroup: string) => {
  const memberSearchQueryVariables = useMemberSearchQueryVariables();

  const [loadPredefinedSegment] = useLazyQuery<PredefinedSegmentsQuery, PredefinedSegmentsQueryVariables>(
    GET_PREDEFINED_SEGMENTS_FOR_COMMUNITY,
    {
      variables: {
        communityId,
        sourcingGroupId: sourceGroup,
      },
      fetchPolicy: 'network-only',
    },
  );

  const [loadSegmentFolders] = useLazyQuery<SegmentFoldersQuery, SegmentFoldersQueryVariables>(
    GET_SEGMENT_FOLDERS,
    {
      variables: {
        communityId,
        sourceGroup,
      },
      fetchPolicy: 'network-only',
    },
  );

  const [loadSearchCount] = useLazyQuery<MemberSearchCountQuery, MemberSearchCountQueryVariables>(
    MEMBER_SEARCH_COUNT_QUERY,
    {
      variables: {
        query: memberSearchQueryVariables.query,
      },
      fetchPolicy: 'network-only',
    },
  );

  return useCallback(() => {
    loadSearchCount();
    loadPredefinedSegment();
    loadSegmentFolders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPredefinedSegment, loadSegmentFolders, loadSearchCount, communityId, sourceGroup]);
};
