/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_66nao_157 {
  display: flex;
}

._justify-content-space-between_66nao_161 {
  justify-content: space-between;
}

._tabular-nums_66nao_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_66nao_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ColorPicker_66nao_178 {
  display: flex;
  align-content: center;
}
._ColorPicker_66nao_178 ._swatch_66nao_182 {
  display: block;
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #FDFDFD;
  border: 0.5px solid #DADCDE;
  cursor: pointer;
}
._ColorPicker_66nao_178 ._value_66nao_192 {
  margin-left: 0.5rem;
  flex: 1;
  border: 0.5px solid #DADCDE;
  border-radius: 6.25rem;
  background-color: #eff5f9;
  color: #5F5F5F;
  font-size: 13px;
  font-weight: 400;
}
._ColorPicker__disabled_66nao_202 {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

._Popover_66nao_208 .ant-popover-inner-content {
  padding: 0 !important;
}
._Popover_66nao_208 .ant-popover-arrow {
  display: none !important;
}