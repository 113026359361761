import * as React from 'react';
import {
  map, filter, get, isEmpty, isFunction,
} from 'lodash';

import { MemberFieldSchemasQuery_schemas } from '@frontend/app/queries/types/MemberFieldSchemasQuery';
import { MemberInput } from '@frontend/app/types/globalTypes';
import SocialRow from './SocialRow';

const { useEffect, useMemo } = React;

interface Props {
  member?: MemberInput;
  schemas?: MemberFieldSchemasQuery_schemas[];
  onInstagramInsightsUpdated?: (value: boolean) => void;
  onSave: (val: Record<number, string>) => Promise<void>;
  viewMore?: boolean;
  onViewMoreVisibilityChange?: (visible: boolean) => void;
  setIsSectionEmpty?: (empty: boolean) => void;
}

const Social = (props: Props) => {
  const {
    member,
    schemas = [],
    onSave,
    viewMore,
    onViewMoreVisibilityChange,
    setIsSectionEmpty,
  } = props;

  const fieldsWithValues = useMemo(() => filter(
    schemas,
    (field) => !isEmpty(get(member, ['fields', field.id])),
  ), [schemas, member]);

  const visibleFields = (viewMore || !fieldsWithValues.length) ? schemas : fieldsWithValues;

  useEffect(() => {
    if (isFunction(onViewMoreVisibilityChange)) {
      onViewMoreVisibilityChange(fieldsWithValues.length < schemas.length && fieldsWithValues.length > 0);
    }
  }, [fieldsWithValues, schemas, onViewMoreVisibilityChange]);

  useEffect(() => {
    setIsSectionEmpty(!fieldsWithValues?.length);
  }, [fieldsWithValues, setIsSectionEmpty]);

  if (!member) {
    return null;
  }

  return (
    <>
      {map(visibleFields, (field) => (
        <SocialRow
          key={field.id}
          schema={field}
          onInstagramInsightsUpdated={props.onInstagramInsightsUpdated}
          value={get(member, ['fields', field.id])}
          onSave={onSave}
        />
      ))}
    </>
  );
};

export default Social;
