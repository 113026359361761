import {
  Button,
} from 'antd';

import * as React from 'react';

interface IProps {
  modalView: boolean;
  clientId: string;
  memberId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deepLinkParameters?: any;
}

const QAApp: React.FunctionComponent<IProps> = () => {
  const showErrorPage = () => {
    throw Error('Show an error page!');
  };

  return (
    <div>
      <Button
        type="primary"
        htmlType="submit"
        onClick={showErrorPage}
      >
        Show Error Page
      </Button>
    </div>
  );
};

export default QAApp;
