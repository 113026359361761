import * as React from 'react';
import cx from 'classnames';

import { KeyboardArrowLeftIcon } from '@components';
import { Button } from '@components';
import { Image } from '@components';

import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

import styles from './HistoryBackButton.scss';

export const HistoryBackButton: React.FC<{ className?: string }> = (props) => {
  const { className } = props;
  const {
    peekHistory,
    popHistory,
    setSocialAccount,
  } = useSocialProfileContext();

  const previousSocialAccount = peekHistory();

  return (
    <div className={cx(className, styles.HistoryBackButton)}>
      {previousSocialAccount && (
        <Button
          className={styles.backButton}
          label={(
            <>
              <KeyboardArrowLeftIcon
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                className={(styles as any).leftArrowIcon}
                size={16}
              />
              <Image
                className={styles.image}
                src={previousSocialAccount.profile_image || previousSocialAccount.profile_picture}
                width={24}
                height={24}
              />
              Go back to @
              {previousSocialAccount.username}
            </>
)}
          onClick={() => setSocialAccount(popHistory())}
        />
      )}
    </div>
  );
};

HistoryBackButton.displayName = 'HistoryBackButton';
