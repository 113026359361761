export const toolbarOptions = [
    'bold',
    'italic',
    'underline',
    'list',
    'bullet',
    'link',
  ];

  export const toolbarModules = [
    [
      'bold',
      'italic',
      'underline',
    ],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ];
