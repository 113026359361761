import gql from 'graphql-tag';

const GET_DASHBOARD_BUDGET_TAB_BUDGET_SPLIT = gql`
  query GetBudgetDashboardBudgetTabBudgetSplitQuery($budgetId: Int!, $fiscalYear: String!) {
    budgetSplit:getBudgetDashboardAllocationData(budgetId: $budgetId,fiscalYear: $fiscalYear) {
        budgetList
        projectBudgetList
    }
  }
`;

export default GET_DASHBOARD_BUDGET_TAB_BUDGET_SPLIT;
