import * as React from 'react';
import cx from 'classnames';
import { Textarea } from '@components';

import styles from './SimpleTextarea.scss';

type TTextareaProps = Partial<Textarea['props']>;
interface IProps extends TTextareaProps {
  className?: string;
  errorMessage?: string;
  hasError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?(value: any);
  value?: string;
}

export const SimpleTextarea: React.FC<IProps> = (props) => {
  const {
    className,
    errorMessage,
    hasError,
    onChange,
    value,
    ...restTextareaProps
  } = props;

  return (
    <div
      className={cx(
        styles.SimpleTextarea,
        className,
        { [styles.error]: hasError },
      )}
    >
      <Textarea
        className={cx(
          styles.textarea,
          { [styles.error]: hasError },
        )}
        onChange={onChange}
        value={value}
        {...restTextareaProps}
      />
      {hasError && (
        <div className={styles.message}>{errorMessage}</div>
      )}
    </div>
  );
};
