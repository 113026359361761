import * as React from 'react';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import { DueDateType, IContentGuidelinesWithDueDates } from '../types/ContentGuidelines';
import PostList from './Post/PostList';
import {
  IContentGuidelineInstruction,
  ISortableGuideline,
  IContentGuidelineTitle,
} from '../hooks/useState/actions';
import { TDateRequirement } from '../types/ContentDateRequirements';

interface IProps {
  daysToApproveContent: number;
  contentGuidelinesWithDueDates: Array<IContentGuidelinesWithDueDates>;
  onAddContentGuidelineInstruction: (contentGuidelineInstruction: IContentGuidelineInstruction) => void;
  onToggleRequiresBrandedContentTag: (
    requiresBrandedContentTag: boolean,
    contentGuidelineInstanceId: number,
  ) => void;
  onToggleReviewBeforeSubmission: (
    requiresReviewBeforeSubmission: boolean,
    contentGuidelineInstanceId: number,
  ) => void;
  onToggleReviewWithinThreeDays: (
    requiresReviewWithinThreeDaysOfCreatorSubmission: boolean,
    contentGuidelineInstanceId: number,
  ) => void;
  onAddAttachment: (
    attachment: TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments,
    id: number,
  ) => void;
  onRemoveAttachment: (
    attachments: Array<TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments>,
    id: number,
  ) => void;
  onUpdateDueDate: (
    dueDateType: DueDateType,
    contentGuidelineInstanceId: number,
    dueDateIndex: number,
    dueDate: TDateRequirement,
  ) => void;
  onSortGuideline: (
    id: number,
    sortableGuideline: ISortableGuideline,
  ) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
  linkInsightsAlertForBrandedContent: boolean;
  linkBCAAccountAlert: boolean;
}

const ContentRequirements: React.FC<IProps> = React.memo((props) => {
 const {
   contentGuidelinesWithDueDates,
   onAddContentGuidelineInstruction,
   onToggleReviewBeforeSubmission,
   onToggleReviewWithinThreeDays,
   onToggleRequiresBrandedContentTag,
   daysToApproveContent,
   onAddAttachment,
   onRemoveAttachment,
   onUpdateDueDate,
   onSortGuideline,
   onUpdateContentGuidelineTitle,
   linkBCAAccountAlert,
   linkInsightsAlertForBrandedContent,
  } = props;

 return (
   <div>
     {contentGuidelinesWithDueDates.length !== 0 && (
       <PostList
         daysToApproveContent={daysToApproveContent}
         contentGuidelinesWithDueDate={contentGuidelinesWithDueDates}
         onAddContentGuidelineInstruction={onAddContentGuidelineInstruction}
         onToggleRequiresBrandedContentTag={onToggleRequiresBrandedContentTag}
         onToggleReviewBeforeSubmission={onToggleReviewBeforeSubmission}
         onToggleReviewWithinThreeDays={onToggleReviewWithinThreeDays}
         onAddAttachment={onAddAttachment}
         onRemoveAttachment={onRemoveAttachment}
         onUpdateDueDate={onUpdateDueDate}
         onSortGuideline={onSortGuideline}
         onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
         linkInsightsAlertForBrandedContent={linkInsightsAlertForBrandedContent}
         linkBCAAccountAlert={linkBCAAccountAlert}
       />
     )}
   </div>
 );
});

ContentRequirements.displayName = 'ContentRequirements';

export default ContentRequirements;
