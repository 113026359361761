import * as React from 'react';
import cx from 'classnames';

import styles from './ListHeaderCount.scss';

interface IProps {
  className?: string;
  count: number;
  selected: boolean;
  updatedCount: number;
}

const ListHeaderCountComponent: React.FunctionComponent<Readonly<IProps>> = (props) => {
  const {
    className,
    count,
    selected,
    updatedCount,
  } = props;
  const plural = updatedCount === 1 ? '' : 's';
  const notSelectedDescription = `${updatedCount} Member${plural}`;
  const isSelectedDescription = `${updatedCount} Member${plural} (${count} selected)`;

  return (
    <>
      {!selected && (
        <div className={cx(className, styles.ListHeaderCount)}>
          {notSelectedDescription}
        </div>
      )}
      {selected && (
        <div className={cx(className, styles.ListHeaderCount)}>
          {isSelectedDescription}
        </div>
      )}
    </>
  );
};
ListHeaderCountComponent.displayName = 'ListHeaderCount';

export const ListHeaderCount = React.memo(ListHeaderCountComponent);
