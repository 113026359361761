import { MutationHookOptions, useMutation } from '@apollo/client';
import { DELETE_IMPORTER_TASK_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { DeleteImporterTask, DeleteImporterTaskVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/DeleteImporterTask';

type IOptions = MutationHookOptions<DeleteImporterTask, DeleteImporterTaskVariables>;

export const useDeleteImporterTask = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<DeleteImporterTask, DeleteImporterTaskVariables>(
    DELETE_IMPORTER_TASK_MUTATION,
    options,
  );

  return {
    mutate,
    loading,
  };
};
