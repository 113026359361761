import * as React from 'react';
import { map } from 'lodash';

import { Spinner } from '@revfluence/fresh';
import { DetailedProductView } from './DetailedProductView';
import { IProduct } from '../hooks';

interface IProps {
  products: IProduct[];
  selectedProducts;
  setSelectedProducts(products);
  fetchMore?(): void;
  isFetchingMore?: boolean;
  resourceId: number;
  setProducts(products: IProduct[]): void;
  bypassShopifyInventory: boolean;
}

const {
  useEffect, useRef, useCallback, useState,
} = React;
import styles from './ProductContainer.scss';
import { GetProductDetailsQuery_productDetail } from '../queries/types/GetProductDetailsQuery';
import { GetProductsV3Query_productCollection_products } from '../queries/types/GetProductsV3Query';

export const ProductContainer: React.FunctionComponent<IProps> = (props) => {
  const {
    products,
    selectedProducts,
    setSelectedProducts,
    fetchMore,
    isFetchingMore,
    resourceId,
    setProducts,
    bypassShopifyInventory,
  } = props;
  const [loadMoreAtIndex, setLoadMoreAtIndex] = useState<number>(0);
  const loader = useRef(null);
  const observer = useRef<IntersectionObserver>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(() => { });
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const handleObserver = useCallback(
    (entries) => {
      if (fetchMore) {
        const target = entries[0];
        if (target.isIntersecting && !isFetchingMore) {
          if (observer.current) {
            observer.current.disconnect();
          }
          fetchMore();
        }
      }
      return () => {
        observer.current.disconnect();
      };
    },
    [fetchMore, isFetchingMore],
  );

  useEffect(() => {
    if (isFetchingMore || !fetchMore) {
      return;
    }

    setLoadMoreAtIndex(products?.length - 4);
    const option = {
      root: null,
      rootMargin: '1px',
      threshold: 0,
    };

    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(handleObserver, option);
    if (loader.current) {
      observer.current.observe(loader.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleObserver, loader, loadMoreAtIndex, isFetchingMore, fetchMore]);

  return (
    <div className={styles.productContainer}>
      {map(products, (product, index) => (
        <>
          <DetailedProductView
            key={product.id}
            product={product}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            resourceId={resourceId}
            onGetMoreVariants={(productData: GetProductDetailsQuery_productDetail) => {
              setProducts(
                products.map((product: GetProductsV3Query_productCollection_products) => {
                  if (product.id === productData.id) {
                    return productData;
                  }
                  return product;
                }),
              );
            }}
            bypassShopifyInventory={bypassShopifyInventory}
          />
          {index === loadMoreAtIndex && <div ref={loader} />}
        </>
      ))}
      {isFetchingMore && (
        <div className={styles.loadMore}>
          <Spinner />
        </div>
      )}
    </div>
  );
};
