import * as React from 'react';
import {
  Button,
  Col,
  Divider,
  Row,
  Skeleton,
  Tooltip,
  Typography,
} from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import cx from 'classnames';
import {
  isEmpty,
  isInteger,
  map,
  some,
} from 'lodash';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import { cn } from '@frontend/shadcn/lib/utils';
import { ErrorOverlay } from './ErrorOverlay';
import { Loading } from './Loading';
import { Statistic } from './Statistic';
import {
  IBasicStat,
  IComplexStat,
  ILink,
} from './types';

import styles from './StatisticsCard.scss';

interface IProps {
  customContent?: React.ReactNode;
  icon: React.ReactNode;
  links: readonly ILink[];
  loading: boolean;
  smallStats: readonly IBasicStat[];
  stats: readonly IComplexStat[];
  subtitle: string;
  title: string;
  tooltip?: React.ReactNode;
  alertComponent?: React.ReactNode;
}

const GUTTERS = [24, 24];

const StatisticsCardContainer: React.FC = ({ children }) => {
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  if (isRefreshUIEnabled) {
    return (
      <ShadCnCard className={cn(styles.StatisticsCard, styles.refreshOverride, 'overflow-hidden')}>
        {children}
      </ShadCnCard>
    );
  }

  return (
    <div className={styles.StatisticsCard}>
      {children}
    </div>
  );
};

const StatisticsHeader = ({
 icon, title, subtitle, loading, tooltip,
}) => {
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const headerContents = React.useMemo((): React.ReactNode => {
    if (loading) {
      return (
        <Skeleton
          active
          paragraph={false}
        />
      );
    }

    if (isRefreshUIEnabled) {
      return (
        <>
          <div className="flex items-center gap-2">
            <span className="text-icon-grey">
              {icon}
            </span>
            <span className="font-medium text-base">
              {title}
              <span className="text-xs whitespace-nowrap overflow-hidden text-ellipsis ml-2 text-subtitle" title={subtitle}>
                {subtitle}
              </span>
            </span>
          </div>
        </>
      );
    }

    return (
      <>
        {icon}
        <Typography.Title level={5} title={title}>
          {title}
          <Typography.Text className={styles.subtitle} title={subtitle}>
            {subtitle}
          </Typography.Text>
        </Typography.Title>
      </>
    );
  }, [icon, isRefreshUIEnabled, loading, subtitle, title]);

  const renderNewUI = () => (
    <CardHeader>
      <div className={cn(styles.headerRow, 'flex items-center justify-between')}>
        <div className={styles.header}>
          {headerContents}
        </div>
        {tooltip && (
          <div className={cx(styles.infoWrapper, { [styles.loading]: loading })}>
            <Tooltip arrowPointAtCenter placement="bottomRight" title={tooltip}>
              <CircleInfoIcon />
            </Tooltip>
          </div>
        )}
      </div>
    </CardHeader>
    );

  const renderOldUI = () => (
    <Row className={styles.headerRow} gutter={8} wrap={false}>
      <Col className={styles.header}>
        {headerContents}
      </Col>
      {tooltip && (
      <Col className={cx(styles.infoWrapper, { [styles.loading]: loading })}>
        <Tooltip arrowPointAtCenter placement="bottomRight" title={tooltip}>
          <CircleInfoIcon />
        </Tooltip>
      </Col>
        )}
    </Row>
    );

  return isRefreshUIEnabled ? renderNewUI() : renderOldUI();
};

const StatisticsContent = ({ children }) => {
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  if (isRefreshUIEnabled) {
    return (
      <CardContent>
        {children}
      </CardContent>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export const StatisticsCard: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    customContent,
    icon,
    links,
    loading,
    smallStats,
    stats,
    subtitle,
    title,
    tooltip,
    alertComponent,
  } = props;

  // this is true if all stats have an error
  const showErrorOverlay = React.useMemo((): boolean => {
    if (isEmpty(stats) && isEmpty(smallStats)) {
      return false;
    }
    return !(some(stats, ({ hasError }) => !hasError) || some(smallStats, ({ hasError }) => !hasError));
  }, [smallStats, stats]);

  if (stats.length > 0 && !isInteger(24 / stats.length)) {
    throw new Error('Number of stats must divide 24.');
  }

  if (smallStats.length > 0 && !isInteger(24 / smallStats.length)) {
    throw new Error('Number of small stats must divide 24.');
  }

  if (links.length > 2) {
    throw new Error('Does not currently support more than two links.');
  }

  return (
    <StatisticsCardContainer>
      <StatisticsHeader
        icon={icon}
        title={title}
        subtitle={subtitle}
        loading={loading}
        tooltip={tooltip}
      />
      <StatisticsContent>
        {alertComponent && !loading && (alertComponent)}
        {!isEmpty(stats) && (
        <Row
          className={cx(styles.statsRow, styles.largeStats, { [styles.loading]: loading })}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          gutter={GUTTERS}
          justify="center"
          wrap
        >
          {map(stats, (stat) => (
            <Col
              className={styles.statsCol}
              key={`stat-${stat.label.toLowerCase()}`}
              xs={24}
              sm={(24 / stats.length) * 2 >> 0}
              md={(24 / stats.length) >> 0}
            >
              <Statistic
                position="largeStat"
                loading={loading}
                showErrorOverlay={showErrorOverlay}
                stat={stat}
              />
            </Col>
          ))}
        </Row>
      )}
        {!isEmpty(stats) && !isEmpty(smallStats) && (
        <Divider style={{ marginBottom: '0' }} />
      )}
        {!isEmpty(smallStats) && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        <Row className={cx(styles.statsRow, styles.smallStats, { [styles.loading]: loading })} gutter={GUTTERS} wrap>
          {map(smallStats, (stat) => (
            <Col
              className={styles.statsCol}
              key={`smallstat-${stat.label.toLowerCase()}`}
              xs={12}
              sm={(24 / smallStats.length) >> 0}
            >
              <Statistic
                position="smallStat"
                loading={loading}
                showErrorOverlay={showErrorOverlay}
                stat={stat}
              />
            </Col>
          ))}
        </Row>
      )}
        {!isEmpty(links) && (
        <Row className={styles.linksRow}>
          {map(links, ({ href, label, onClick }) => (
            <Col
              className={cx({
                [styles.loading]: loading,
                [styles.linkColumn]: true,
                [styles.twoColumn]: links.length === 2,
              })}
              key={`link-${label.toLowerCase()}`}
            >
              {loading ? (
                <Loading position="link" visible />
              ) : (
                <Button
                  href={href}
                  onClick={onClick}
                  type="text"
                >
                  {label}
                </Button>
              )}
            </Col>
          ))}
        </Row>
      )}
        {customContent && (
        <div className={styles.customContentWrapper}>
          {customContent}
        </div>
      )}
        {showErrorOverlay && (
        <ErrorOverlay />
      )}
      </StatisticsContent>
    </StatisticsCardContainer>
  );
});
StatisticsCard.displayName = 'StatisticsCard';
