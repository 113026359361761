import {
  chain, filter, find, isEmpty, map,
} from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import isNil from 'lodash/isNil';

import { useApolloClient as useStaApolloClient } from '@affiliates/hooks';
import { SYNC_ADVERTISER_WITH_SHOPIFY } from '@affiliates/queries/syncAdvertiserWithShopifyMutation';
import { SyncAdvertiser } from '@affiliates/queries/types/SyncAdvertiser';
import { logger } from '@common';
import { LoadSpinner } from '@components';
import Title from '@frontend/app/components/Title/Title';
import { useClientFeatureEnabled, useGetResources, useQueryParams } from '@frontend/app/hooks';
import { ResourceType } from '@frontend/app/types/globalTypes';

import { GET_CONNECTED_SHOPIFY } from '@frontend/applications/AffiliatesApp/queries/getConnectedShopifyQuery';
import { useGetShopifyResourceQuery } from '@frontend/app/hooks/useGetShopifyResourceForClient';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { ClientShopifyConnectionInput } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { CREATE_CLIENT_CONNECTION } from '@frontend/applications/AffiliatesApp/queries/createClientConnectionMutation';
import { CreateClientConnection, CreateClientConnectionVariables, CreateClientConnection_connectedClients } from '@frontend/applications/AffiliatesApp/queries/types/CreateClientConnection';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import { ClientFeature } from '@frontend/app/constants';
import { ShopifyResourceForClient_shopifyResource } from '@frontend/app/queries/types/ShopifyResourceForClient';
import { message } from '@revfluence/fresh';
import { DISCONNECT_CLIENT_SHOPIFY_CONNECTIONS } from '@frontend/applications/AffiliatesApp/queries/disconnectClientShopifyConnectionsMutation';
import { AllShopifyConnection, OnChnageInput, ShopifyConnectionError } from './types';
import styles from './ShopifySettingsPage.scss';
import { SYNC_STA_PARAM } from '../hooks/useAddShopifyAccount';
import { ShopifyAddNew } from '../components/ShopifyAddNew';
import { ShopifyAccountList } from '../components/ShopifyAccountList';

const {
  useEffect, useMemo, useCallback, useState,
} = React;

interface IProps {
  className?: string;
  title?: string;
}

const checkEquality = (payloadOne: AllShopifyConnection[], payloadTwo: AllShopifyConnection[]): boolean => JSON.stringify(payloadOne.sort((a, b) => b.clientId.localeCompare(a.clientId))) != JSON.stringify(payloadTwo.sort((a, b) => b.clientId.localeCompare(a.clientId)));
const checkValidation = (payload: AllShopifyConnection[]): string => {
  const activeConnections = payload.filter((connection) => !connection.isRevoked);
  for (const connection of activeConnections) {
    if (connection.isActive && !connection.label.trim()) {
      return 'You need to add labels to your primary and connected store to support enable Shopify sync.';
    }
  }
  const filteredStores = activeConnections.map((connection) => ({ ...connection, label: connection.label.trim() })).filter((connection) => !!connection.label.length);
  const isAllActiveUnique = chain(filteredStores).uniqBy('label').size()
    .value() === chain(filteredStores).size().value();
  if (!isAllActiveUnique) {
    return 'All active stores should have unique labels.';
  }
  return '';
};

const ShopifySettingsPage: React.FC<IProps> = (props) => {
  const [allShopifyConnection, setAllShopifyConnection] = useState<AllShopifyConnection[]>([]);
  const [initialShopifyConnection, setInitialShopifyConnection] = useState<AllShopifyConnection[]>([]);
  const [connectedShopify, setConnectedShopify] = useState<GetConnectedShopify_clientConnections[]>([]);
  const [shopifyConnectionError, setShopifyConnectionError] = useState<ShopifyConnectionError | null>(null);
  const [connectedShopifyLoading, setConnectedShopifyLoading] = useState<boolean>(false);
  const history = useHistory();
  const query = useQueryParams();
  const { clientId } = useApplication();
  const isEnabledMultipleShopify = useClientFeatureEnabled(ClientFeature.ENABLE_MULTIPLE_SHOPIFY);
  const staApolloClient = useStaApolloClient();

  useEffect(() => {
    (async () => {
      if (query.has(SYNC_STA_PARAM)) {
        query.delete(SYNC_STA_PARAM);
        history.replace({
          search: query.toString(),
        });
        const { data, errors } = await staApolloClient.mutate<SyncAdvertiser>({
          fetchPolicy: 'no-cache',
          mutation: SYNC_ADVERTISER_WITH_SHOPIFY,
        });
        if (!isEmpty(errors)) {
          logger.error('Error syncing advertiser with Shopify in STA', { errors });
          return;
        }
        if (!data?.syncAdvertiserWithShopify) {
          logger.info('Unable to sync advertiser with Shopify in STA');
        }
      }
    })();
  }, [query, history, staApolloClient]);

  useEffect(() => {
    if (query.has('shopifyConnectionError')) {
      const error = JSON.parse(decodeURIComponent(query.get('shopifyConnectionError')));
      setShopifyConnectionError(error as ShopifyConnectionError);
      query.delete('shopifyConnectionError');
    }
    window.history.replaceState({}, document.title, window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shoplessShopifyConnectionEnabled = useClientFeatureEnabled(ClientFeature.SHOPLESS_SHOPIFY_CONNECTION_ENABLED);

  const { resources, loading, refetch } = useGetResources({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: false,
    variables: {
      includeExternalScopes: true,
      ignoreRequestContextUserId: true,
    },
  });
  const onChangeValue = ({ clientId, field, value }: OnChnageInput) => {
    if (field === 'label') {
      const client = allShopifyConnection.find((c) => c.clientId === clientId);
      if (client) {
        setAllShopifyConnection([...allShopifyConnection.filter((c) => c.clientId !== clientId), { ...client, label: value as string }]);
      }
    } else if (field === 'isActive') {
      const client = allShopifyConnection.find((c) => c.clientId === clientId);
      if (client) {
        setAllShopifyConnection([...allShopifyConnection.filter((c) => c.clientId !== clientId), { ...client, isActive: value as boolean }]);
      }
    }
  };
  const createPayloadForClientConnection = useCallback((payload: AllShopifyConnection[], isCloseMutation: boolean = false): ClientShopifyConnectionInput[] => {
    const transformedPayload: ClientShopifyConnectionInput[] = [];
    for (const client of payload) {
      transformedPayload.push({
        masterClientId: clientId, label: client.label.trim(), isPrimary: isCloseMutation ? false : client.isPrimary, isConnected: isCloseMutation ? false : client.isActive, connectedClientId: client.clientId,
      });
    }
    return transformedPayload;
  }, [clientId]);
  const closeConnection = useCallback(async () => {
    const payload = createPayloadForClientConnection(allShopifyConnection, true);
    const { errors } = await staApolloClient.mutate<CreateClientConnection, CreateClientConnectionVariables>({
      fetchPolicy: 'no-cache',
      mutation: CREATE_CLIENT_CONNECTION,
      variables: {
        data: payload,
      },
    });
    if (!isEmpty(errors)) {
      logger.error('Error Removing Client Connection', { errors });
    }
  }, [allShopifyConnection, staApolloClient, createPayloadForClientConnection]);

  const closeClientShopifyConnections = useCallback(async () => {
    const { errors } = await staApolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: DISCONNECT_CLIENT_SHOPIFY_CONNECTIONS,
    });
    if (!isEmpty(errors)) {
      logger.error('Error closing client connection', { errors });
    }
  }, [staApolloClient]);

  const onAddShopifyClient = async () => {
    const { errors } = await staApolloClient.mutate<CreateClientConnection, CreateClientConnectionVariables>({
      fetchPolicy: 'no-cache',
      mutation: CREATE_CLIENT_CONNECTION,
      variables: {
        data: [{
          masterClientId: clientId, label: '', isPrimary: true, isConnected: true, connectedClientId: clientId,
        }],
      },
    });
    if (!isEmpty(errors)) {
      logger.error('Error Adding Client Connection', { errors });
    }
  };
  const initializeShopifyConnections = useCallback((shopifyResources: ShopifyResourceForClient_shopifyResource[], connectedShopifyClients: GetConnectedShopify_clientConnections[] | CreateClientConnection_connectedClients[]) => {
    // const toConnect: AllShopifyConnection[] = [];
    const toConnect: AllShopifyConnection[] = map(shopifyResources, (shopifyResource) => {
      const connectedClientInShopify = find(
        connectedShopifyClients,
        (connectedClient) => connectedClient.connectedClientId === shopifyResource.clientId,
      );
      if (connectedClientInShopify) {
        return {
          clientId: shopifyResource.clientId,
          isPrimary: connectedClientInShopify.isPrimary,
          isActive: connectedClientInShopify.isConnected,
          url: shopifyResource.authProvider.userExternalId,
          label: connectedClientInShopify.label,
          isRevoked: shopifyResource.authProvider.userRevokedAccess || shopifyResource.authProvider.systemRevokedAccess,
        };
      } else {
        return {
          clientId: shopifyResource.clientId,
          isPrimary: shopifyResource.clientId === clientId,
          isActive: shopifyResource.clientId === clientId,
          url: shopifyResource.authProvider.userExternalId,
          label: '',
          isRevoked: shopifyResource.authProvider.userRevokedAccess || shopifyResource.authProvider.systemRevokedAccess,
        };
      }
    });

    setAllShopifyConnection(toConnect);
    setInitialShopifyConnection(toConnect);
  }, [clientId]);
  const onSubmit = async () => {
    try {
      const payload = createPayloadForClientConnection(allShopifyConnection);

      const { data } = await staApolloClient.mutate<CreateClientConnection, CreateClientConnectionVariables>({
        fetchPolicy: 'no-cache',
        mutation: CREATE_CLIENT_CONNECTION,
        variables: {
          data: payload,
        },
      });
      if (data?.connectedClients && shopifyResources.data?.shopifyResource) {
        const shopify = shopifyResources.data?.shopifyResource;
        message.success('Successfully Saved');
        initializeShopifyConnections(shopify, data.connectedClients);
      }
    } catch (error) {
      logger.error('Error during onSubmit', { error });
      message.error('Please check if all the stores are synced with sales tracking application in their corresponding clients before saving connections.');
    }
  };
  const onCancel = () => {
    setAllShopifyConnection(initialShopifyConnection);
  };
  useEffect(() => {
    (async () => {
      const { data, loading: getConnectedShopifyLoading } = await staApolloClient.query({ query: GET_CONNECTED_SHOPIFY });
      if (data?.clientConnections) {
        const allConnections: GetConnectedShopify_clientConnections[] = data.clientConnections;
        setConnectedShopify(allConnections);
        setConnectedShopifyLoading(getConnectedShopifyLoading);
      }
    })();
  }, [query, history, staApolloClient]);
  const shopifyResources = useGetShopifyResourceQuery(clientId);
  useEffect(() => {
    if (shopifyResources.data?.shopifyResource) {
      const shopify = shopifyResources.data?.shopifyResource;
      initializeShopifyConnections(shopify, connectedShopify);
    }
  }, [shopifyResources, connectedShopify, initializeShopifyConnections]);
  const enableChangeButton = useMemo(() => checkEquality(allShopifyConnection, initialShopifyConnection), [allShopifyConnection, initialShopifyConnection]);
  const showNoticeMessage = useMemo(() => checkValidation(allShopifyConnection), [allShopifyConnection]);

  const activeAccounts = useMemo(
    () =>
      filter(
        resources,
        (resource) => !resource.authProvider.userRevokedAccess && resource.type === ResourceType.SHOPIFY,
      ),
    [resources],
  );

  // isNil(shoplessShopifyConnectionEnabled) implies that getFeatureByName used inside useClientFeatureEnabled hasn't yet responded
  // this check has been added to avoid a glitch
  if (loading || isNil(shoplessShopifyConnectionEnabled) || connectedShopifyLoading) {
    return (
      <div className={styles.spinner}>
        <LoadSpinner />
      </div>
    );
  }

  if (!isEmpty(activeAccounts)) {
    return (
      <>
        {props.title && <Title title="Shopify Settings" />}
        <ShopifyAccountList
          activeAccounts={activeAccounts}
          isEnabledMultipleShopify={isEnabledMultipleShopify}
          className={props.className}
          onAccountRemoved={() => refetch()}
          allShopifyConnection={allShopifyConnection}
          enableChangeButton={enableChangeButton}
          showNoticeMessage={showNoticeMessage}
          onChangeValue={onChangeValue}
          onSubmit={onSubmit}
          onCancel={onCancel}
          closeConnection={closeConnection}
          closeClientShopifyConnections={closeClientShopifyConnections}
        />
      </>
    );
  }

  return (
    <>
      {props.title && <Title title="Shopify Settings" />}
      <ShopifyAddNew
        className={styles.addNew}
        isEnabledMultipleShopify={isEnabledMultipleShopify}
        onAddShopifyClient={onAddShopifyClient}
        shopifyConnectionError={shopifyConnectionError}
        setShopifyConnectionError={setShopifyConnectionError}
        shoplessShopifyConnectionEnabled={shoplessShopifyConnectionEnabled}
      />
    </>
  );
};

export default ShopifySettingsPage;
