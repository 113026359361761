import * as React from 'react';
import cx from 'classnames';
import {
 map, mapValues, pickBy, isEmpty,
} from 'lodash';

import {
 endpoints, SearchIcon, SpinnerIcon, Checkbox, Input, Popover,
} from '@components';

import { useAnalyze } from '../../useAnalyze';
import { fetchFilterData } from '../useFetchFilterData';
import { filtersToQueryString } from '../../useFetchAnalyzeData';

import styles from '../Filters.scss';

const { useRef, useState, useEffect } = React;

export const CreatorFilter = ({
 filters, setFilters, removeFilter, isDisabled,
}) => {
  const [open, setOpen] = useState(true);
  const { apiEndpoint } = useAnalyze();
  const creatorState = filters.creators;
  const [loading, setLoading] = useState(isEmpty(creatorState.creators));

  const [textValue, setTextValue] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  /*eslint-disable */
  const toDisplay =
    textValue === ''
      ? creatorState.creators
      : pickBy(creatorState.creators, creator =>
          creator.name.toLowerCase().includes(textValue.toLowerCase())
        );
  /* eslint-enable */
  const url = `${`${apiEndpoint}/${endpoints.reportsEndpoint}/filter/creators`
    + '?'}${
     filtersToQueryString({ brands: filters.brands })}`;
  useEffect(() => {
    setLoading(true);
    fetchFilterData(url)
      .then((creators) => {
        setFilters({ type: 'LOAD_CREATOR_VALUES', payload: creators });
        setLoading(false);
      })
      .catch((error) =>
        setFilters({
          type: 'ERROR_LOADING_API',
          payload: { error, filter: 'Creator' },
        }));
  }, [filters.brands, setFilters, apiEndpoint, url]);
  const pillCircleDisplay = creatorState.selected === 0 ? '' : creatorState.selected;

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {loading ? <SpinnerIcon /> : pillCircleDisplay}
        </span>
        <span className={styles.filterText}>Creator</span>
        <span
          className={cx(styles.filterCircle, styles.right)}
          onClick={() => !isDisabled && removeFilter('creator')}
        >
          ×
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <Input
          type="text"
          value={textValue}
          icon={<SearchIcon />}
          placeholder="Search..."
          buffer={300}
          onChange={setTextValue}
          className={(styles as any).input}
        />
        <ul className={styles.MenuList}>
          <li
            className={styles.MenuItem}
            onClick={() => setFilters({ type: 'CLICK_CLEAR_ALL_CREATORS' })}
          >
            Clear All
          </li>
          {map(toDisplay, (creator, id) => (
            <li
              className={styles.MenuItem}
              key={`${creator.name}-${id}`}
              onClick={() =>
                setFilters({
                  type: 'CLICK_CREATOR',
                  payload: { id, ...creator },
                })}
            >
              <Checkbox
                checked={creator.selected}
                onClick={() =>
                  setFilters({
                    type: 'CLICK_CREATOR',
                    payload: { id, ...creator },
                  })}
              />
              {creator.name}
            </li>
          ))}
        </ul>
      </Popover>
    </>
  );
};

export const creatorReducer = (state, action) => {
  const localState = state.creators;
  switch (action.type) {
    case 'LOAD_CREATOR_VALUES': {
      return {
        creators: action.payload,
        selected: 0,
      };
    }
    case 'REMOVE_ADDITIONAL_FILTER': {
      if (action.payload === 'creator') {
        return { creators: {}, selected: 0 };
      }
      return localState;
    }
    case 'CLICK_CLEAR_ALL_CREATORS': {
      return {
        ...localState,
        selected: 0,
        creators: mapValues(localState.creators, (creator) => ({
          ...creator,
          selected: false,
        })),
      };
    }
    case 'CLICK_CREATOR': {
      const creator = action.payload;
      return {
        ...localState,
        selected: creator.selected
          ? localState.selected - 1
          : localState.selected + 1,
        creators: {
          ...localState.creators,
          [creator.id]: {
            name: creator.name,
            image_url: creator.image_url,
            selected: !creator.selected,
          },
        },
      };
    }
    default: {
      return localState;
    }
  }
};
