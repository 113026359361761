import cx from 'classnames';
import { isNil, map, toString } from 'lodash';
import * as React from 'react';

import {
  ITableColumnConfig,
  LoadSpinner,
  Notice,
  TNetworkIdentifier,
  Table,
} from '@components';

import { usePagination } from '@frontend/hooks';
import { AccessStatus } from '../../hooks/useAccessData';
import { EmptyAccessTable } from '../EmptyAccessTable/EmptyAccessTable';
import { StatusCell } from '../StatusCell/StatusCell';
import { StatusHeader } from '../StatusHeader/StatusHeader';
import { composeAdTypeField } from '../composeAdTypeField';

import styles from './AccessTable.scss';

const {
  useEffect, useMemo, useState,
} = React;

export interface IAccessTableRowData {
  id: number;
  memberId: number;
  member: string;
  accounts: Array<{
    type: TNetworkIdentifier;
    accountName: string;
  }>;
  startTs: number;
  endTs: number;
  selectedForUse?: boolean;
  readyForUse?: boolean;
  pending?: boolean;
  status: AccessStatus;
  type: string;
}

interface IProps {
  fetchMoreData: (page: number, fetchSize: number) => Promise<IAccessTableRowData[]>;
  initialData: IAccessTableRowData[];
  isLoading: boolean;
  pageSize: number;
  totalCount: number;
  usageLimit: number;
  className?: string;
  hasError?: boolean;
  headerActions?: JSX.Element;
}

enum Field {
  AdType = 'adtype',
  Status = 'status',
  Accounts = 'accounts',
  Member = 'member',
  StartTs = 'startTs',
  EndTs = 'endTs',
}

const columns: ITableColumnConfig[] = [
  {
    width: 240,
    field: Field.Member,
    headerName: 'Members',
    searchable: true,
  },
  {
    width: 240,
    cellType: 'network',
    field: Field.Accounts,
    headerName: 'Accounts',
    searchable: true,
    showAccountName: true,
  },
  {
    cellType: 'date',
    field: Field.StartTs,
    headerName: 'Start date',
  },
  {
    cellType: 'date',
    field: Field.EndTs,
    headerName: 'End Date',
  },
  {
    cellType: 'text',
    field: Field.AdType,
    headerName: 'Ad Type',
    sortable: true,
  },
  {
    width: 240,
    field: 'status',
    headerName: <StatusHeader />,
    sortable: true,
  },
];

/**
 * @type {React.FunctionComponent}
 */
export const AccessTable: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    fetchMoreData,
    initialData,
    isLoading,
    pageSize,
    totalCount,
    usageLimit,
    className,
    hasError,
    headerActions,
  } = props;

  const [data, setData] = useState<IAccessTableRowData[] | null>(initialData);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const fetchAndSetMoreData = async (page: number, fetchSize: number) => {
    const newData = await fetchMoreData(page, fetchSize);
    if (isNil(newData)) {
      return;
    }

    if (isNil(data)) {
      setData(newData);
    } else {
      setData([...data, ...newData]);
    }
  };

  const {
    updatePage,
    page,
  } = usePagination(
    data,
    totalCount,
    pageSize,
    pageSize,
    fetchAndSetMoreData,
  );

  const tableData = useMemo(() => map(data, (row) => ({
      ...row,
      id: toString(row.id),
      status: <StatusCell adType={row.type} status={row.status} usageLimit={usageLimit} />,
      adtype: composeAdTypeField(row.type),
      // TODO: There are some known issues with sorting when you are
      // on the barrier of two regions. Also sorting wont workwell for member and accounts
      _raw: row.member,
      _sortableFields: {
        adtype: composeAdTypeField(row.type),
        status: row.status,
        accounts: row.accounts[0]?.accountName,
        member: row.member,
      },
    })), [data, usageLimit]);

  const renderTable = useMemo(() => {
    if (isLoading) {
      return <LoadSpinner />;
    }
    if (hasError) {
      return (
        <Notice showDivider className={cx(styles.notice, styles.center)} type="error">
          <div>
            There was an error when trying to fetch the accounts. If this continues to happen,
            please
            {' '}
            <a className={cx(styles.contactSupport, styles.errorNotice)} href="mailto:help@aspireiq.com">
              contact support
            </a>
            .
          </div>
        </Notice>
      );
    }
    if (tableData) {
      if (tableData.length === 0) {
        return (
          <EmptyAccessTable />
        );
      } else {
        return (
          <>
            <Table
              data={tableData}
              columns={columns}
              config={{
                selectable: false,
                rowHeight: 32,
                headerHeight: 52,
                pageSize,
              }}
              totalRowCount={totalCount}
              page={page}
              onPageChangeCallback={updatePage}
              className={styles.table}
              headerActions={headerActions}
            />
          </>
        );
      }
    }

    return null;
  }, [
    hasError,
    headerActions,
    isLoading,
    page,
    pageSize,
    tableData,
    totalCount,
    updatePage,
  ]);

  return <div className={cx(styles.AccessTable, className)}>{renderTable}</div>;
};
