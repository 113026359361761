import * as React from 'react';
import {
  Input, Form, InputNumber, Typography, Checkbox, Switch, ValidateStatus,
} from '@revfluence/fresh';

import { NoCatalogueFormFieldInput } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { UpdateProjectConfigInput as ProjectConfig } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';

const { Title, Paragraph } = Typography;

import { RichTextEditor } from '@frontend/app/components';
import styles from './ProjectConfigSetup.scss';

interface Validation {
  value?: number;
  validateStatus?: ValidateStatus;
  errorMsg?: string | null;
}

function validateMaxQuantity(quantity: number): Validation {
  if (quantity && quantity < 0) {
    return {
      validateStatus: 'error',
      errorMsg: 'Negative quantities not allowed.',
    };
  }
}

export interface IProps {
  projectConfig: ProjectConfig;
  formFields: Partial<NoCatalogueFormFieldInput>[];
  onChange: (changedFields, allFields) => void;
  setIsSaveFormEnabled: (value: boolean) => void;
}

export const ProjectConfigSetup = (props: IProps) => {
  const {
    projectConfig, onChange, formFields, setIsSaveFormEnabled,
  } = props;
  const initialValues = {
    ...projectConfig,
    productNameLabel: projectConfig.noCatalogueConfig?.formFields.find(
      (field) => field.key === 'productName',
)?.label,
    productPreferencesLabel: projectConfig.noCatalogueConfig?.formFields.find(
      (field) => field.key === 'productPreferences',
)?.label,
  };
  const [maxQuantity, setMaxQuantity] = React.useState<Validation>({
    value: initialValues.quantityMax,
  });
  const onMaxQuantityChange = (value: number) => {
    if (value && value < 0) {
      setIsSaveFormEnabled(false);
    } else {
      setIsSaveFormEnabled(true);
    }
    setMaxQuantity({ ...validateMaxQuantity(value) });
  };
  return (
    <div className={styles.ProjectConfigSetup}>
      <div className={styles.content}>
        <Form onFieldsChange={onChange} layout="vertical" initialValues={initialValues}>
          <div className={styles.formItems}>
            <div>
              <Title level={5}>Provide Instructions</Title>
              <Form.Item name="instructionText" label="Enter clear instructions to help creators choose the right products for your collaboration.">
                <RichTextEditor
                  toolbarOptions={
                    [
                      'bold',
                      'italic',
                      'underline',
                      'list',
                      'bullet',
                      'link']
                  }
                  toolbarModules={
                    [[
                      'bold',
                      'italic',
                      'underline',
                    ],
                    [{ list: 'ordered' }, { list: 'bullet' }], ['link']]
                  }
                  boundSelector="#projectConfigSetup"
                  placeholder={'Example Instructions:\n'
                    + 'Navigate to our website\n'
                    + 'Browse, then choose the product you’d like to receive for this collaboration.'}
                  value={projectConfig.instructionText}
                />

              </Form.Item>
            </div>
          </div>

          <div>
            <Title level={5}>Set Order Form Fields</Title>
            <Paragraph>The following order form will be provided for creators to fill out in order to make their product requests.</Paragraph>
            <div className={styles.checkboxGroup}>

              <div className={styles.checkboxes}>
                <div>
                  <Title level={5}>Quantity</Title>
                  <Form.Item
                    name="quantityMax"
                    label="Set the maximum number of items per request (optional)"
                    validateStatus={maxQuantity.validateStatus}
                    help={maxQuantity.errorMsg}
                  >
                    <InputNumber placeholder="--" onChange={onMaxQuantityChange} />
                  </Form.Item>
                </div>
                <Title level={5}>Product Name or Selections</Title>

                <p>This form field is required so creators can communicate what product they are requesting. </p>
                <Form.Item name="productNameLabel" label="Provide a form field label">
                  <Input />
                </Form.Item>
                {formFields?.filter((item) => !['quantity', 'productName'].includes(item.key)).map((formField) => (
                  <>
                    <Form.Item
                      key={formField.key}
                      required
                      name={formField.key}
                    >
                      <Checkbox checked={formField.enabled}>
                        {formField.key === 'productPreferences' ? 'Product Preferences' : formField.label}
                      </Checkbox>
                    </Form.Item>
                    <div className="fieldConfigurations">
                      {
                        (formField.key === 'productPreferences' && formField.enabled)
                        && (
                          <Form.Item name="productPreferencesLabel" label="Specify the product details you need ">
                            <Input />
                          </Form.Item>
                        )
                      }
                      {formField.enabled
                        && (
                          <Form.Item
                            key={`${formField.key}RequireToggle`}
                            name={`${formField.key}RequireToggle`}
                            label="Make this required"
                          >
                            <Switch checked={formField.required} />
                          </Form.Item>
                        )}
                    </div>
                  </>

                ))}

              </div>

            </div>
          </div>
        </Form>
      </div>

    </div>
  );
};

export default ProjectConfigSetup;
