import * as React from 'react';
import { useGetCurrentClientMetadata, useMemberSearchQuery } from '@frontend/app/hooks';
import { useHistory } from 'react-router-dom';
import { IColumnsType, message } from '@revfluence/fresh';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import { useEffect, useState, useMemo } from 'react';
import { Table } from '@revfluence/fresh';
import { OverlaySpinner } from '@components';
import {
  debounce, filter, find, map,
} from 'lodash';
import * as dateFns from 'date-fns';
import PrimaryNetwork from './PrimaryNetwork';
import CreatorNameCell from './CreatorNameCell';
import CreatorStatusCell from './CreatorStatus';
import { useCommunityByIdQuery } from '../../Communities/AddOrEditCommunity/hooks/useCommunityByIdQuery';
import {
  formatNumberToSuffixString, formatEngagementRateToPerc, formatMember, handleTableNumericSorting, handleTableSorting, handleBrandApprovalSorting,
  formatField,
} from './GcrUtils';
import styles from './GroupCreators.scss';
import CreatorFilter from './CreatorFilter';
import GroupInfo from './GroupInfo';
import { Statuses } from './GroupTypes';

const customColumnFields = ['Table Column 1', 'Table Column 2'];

export const GroupCreators = () => {
  const history = useHistory();
  const communityId = history.location.pathname.split('groups/')[1];
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [communityById, setCommunityById] = useState(null);
  const [brandApprovalError, setBrandApprovalError] = useState<boolean>(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const { data: { community = null } = {}, loading: communityLoading } = useCommunityByIdQuery(Number(communityId), { skip: !communityId });

  const { data: { schemas: fields = [] } = {}, loading: memberFieldLoading } = useMemberFieldSchemasQuery();
  const {
    clientMetadata: { applicantReviewFields = {} } = {}, loading: clientMetadataLoading,
  } = useGetCurrentClientMetadata({
    nextFetchPolicy: 'cache-first',
  });

  const { data: memberData, loading: memberSearchLoading } = useMemberSearchQuery({
    fetchPolicy: 'network-only',
    variables: {
      includeActivations: false,
      includeTags: false,
      includeCommunities: false,
      includeOwners: false,
      includePrograms: false,
      includeHighlights: false,
      includeLastMessage: false,
      includeProgramMemberships: false,
      includeProgramMembershipsLogs: false,
      query: {
        isContact: true,
        communityIds: [communityId],
      },
      take: 1000,
      skip: 0,
    },
  });

  const primaryNetworkRenderer = useMemo(() => ({
    title: 'Primary Network',
    dataIndex: 'metaFields',
    key: 'primary_network',
    render: (_, memberRecord) => <PrimaryNetwork memberRecord={memberRecord} />,
  }), []);
  const initColumns = useMemo(() => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (_, memberRecord) => (
          <CreatorNameCell
            memberRecord={memberRecord}
            groupId={communityId}
            fields={fields}
            setBrandApprovalError={setBrandApprovalError}
          />
        ),
        sorter: handleTableSorting('name'),
      },
      {
        title: 'IG Followers',
        dataIndex: 'metaFields',
        key: 'instagram_followers',
        render: (metaFields) => <span>{formatNumberToSuffixString(metaFields.instagram_followers) || '-'}</span>,
        sorter: {
          compare: handleTableNumericSorting('instagram_followers'),
        },
        align: 'center' as const,
      },
      {
        title: 'IG Engagement Rate',
        dataIndex: 'metaFields',
        key: 'instagram_engagement_rate',
        render: (metaFields) => <span>{formatEngagementRateToPerc(metaFields.instagram_engagement_rate) || '-'}</span>,
        sorter: {
          compare: handleTableNumericSorting('instagram_engagement_rate'),
        },
        align: 'center' as const,
      },
      {
        title: 'TT Followers',
        dataIndex: 'metaFields',
        key: 'tiktok_followers',
        render: (metaFields) => <span>{formatNumberToSuffixString(metaFields.tiktok_followers) || '-'}</span>,
        sorter: {
          compare: handleTableNumericSorting('tiktok_followers'),
        },
        align: 'center' as const,
      },
      {
        title: 'TT Engagement Rate',
        dataIndex: 'metaFields',
        key: 'tiktok_engagement_rate',
        render: (metaFields) => <span>{formatEngagementRateToPerc(metaFields.tiktok_engagement_rate) || '-'}</span>,
        sorter: {
          compare: handleTableNumericSorting('tiktok_engagement_rate'),
        },
        align: 'center' as const,
      },
      {
        title: 'Status',
        dataIndex: 'metaFields',
        key: 'brand_approval',
        render: (_, memberRecord) => <CreatorStatusCell memberRecord={memberRecord} />,
        align: 'left' as const,
        sorter: {
          compare: handleBrandApprovalSorting('brand_approval'),
        },
      },
      {
        title: 'Date Added',
        dataIndex: 'createdDate',
        key: 'date_added',
        render: (value) => <span>{dateFns.format(new Date(value), 'MMM dd, YYY') || '-'}</span>,
        sorter: {
          compare: handleTableSorting('createdDate'),
        },
        align: 'center' as const,
      },
    ], [communityId, fields]);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [displayColumns, setDisplayColumns] = useState<IColumnsType<any>>([]);

  useEffect(() => {
    if (members && applicantReviewFields && fields) {
      const columnsToAdd = filter(
        map(customColumnFields, (currentField) => {
          if (applicantReviewFields[currentField]) {
            const fieldId = applicantReviewFields[currentField];
            const fieldForId = find(fields, (field) => field.id === fieldId);
            if (fieldForId) {
              if (fieldForId.name === 'Primary Network') {
                return primaryNetworkRenderer;
              }
              return {
                title: fieldForId.name,
                dataIndex: 'metaFields',
                key: formatField(fieldForId.name),
                render: (_, memberRecord) => <span>{memberRecord.fields[fieldForId.id] || '-'}</span>,
              };
            }
          }
        }),
        (field) => !!field,
      );
      setDisplayColumns([...initColumns, ...columnsToAdd]);
    }
  }, [members, applicantReviewFields, fields, initColumns, primaryNetworkRenderer]);

  useEffect(() => {
    if (brandApprovalError) {
      message.error('Brand Approval not configured');
      setBrandApprovalError(false);
    }
    if (community) setCommunityById(community);
    if (memberData) {
      const updatedMembers = map(memberData.members, (member) => formatMember(member, fields));
      setMembers(updatedMembers);
    }
  }, [community, memberData, brandApprovalError, fields]);

  const handleSearch = useMemo(() =>
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    [setSearchTerm]);

  const filteredSearchCreators = filter(members, (creator) => {
    const creatorName = creator?.name.toLowerCase();
    return creatorName.includes(searchTerm.toLowerCase());
  });

  const filteredCreators = () => {
    if (selectedStatus === 'All') {
      return filteredSearchCreators;
    }
    if (selectedStatus === Statuses.PENDING) {
      return filter(filteredSearchCreators, (creator) => !creator.brand_approval);
    }
    return filter(filteredSearchCreators, (creator) => creator.brand_approval === selectedStatus);
  };

  const getStatusUserCount = (status) => {
    if (status === Statuses.PENDING) {
      return filter(members, (creator) => !creator.brand_approval).length;
    }
    return filter(members, (creator) => creator.brand_approval === status).length;
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const loading = memberSearchLoading || communityLoading || clientMetadataLoading || memberFieldLoading;

  return (
    <div className={styles.creatorSec}>
      {loading && <OverlaySpinner />}
      {!loading && members && communityById && (
        <>
          <GroupInfo communityById={communityById} history={history} />

          <div className={styles.tableWrapper}>
            <CreatorFilter getStatusUserCount={getStatusUserCount} handleSearch={handleSearch} setSelectedStatus={setSelectedStatus} members={members} />
            <Table
              columns={displayColumns}
              dataSource={filteredCreators()}
              pagination={{
                ...pagination,
                position: ['bottomLeft'],
              }}
              scroll={{ x: true }}
              onChange={handleTableChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

GroupCreators.displayName = 'GroupCreators';
