import * as React from 'react';
import cx from 'classnames';

const { useCallback } = React;

import styles from './Input.scss';

interface IProps {
  className?: string;
  type?: string;
  value: string;
  readOnly?: boolean;
  onBlur?();
  onCancel?();
  onChange?(e: React.ChangeEvent<HTMLInputElement>);
}

const ENTER_KEY = 'Enter';

export const Input = React.forwardRef<HTMLInputElement, IProps>((props, forwardedRef) => {
  const {
    className,
    onBlur,
    onCancel,
  } = props;

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ENTER_KEY && onBlur) {
      onBlur();
    } else if (e.keyCode === 27 && onCancel) {
      // esc is pressed
      onCancel();
    }
  }, [onBlur, onCancel]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <input
      ref={forwardedRef}
      type={props.type}
      className={cx(className, styles.Input)}
      value={props.value}
      autoFocus
      onBlur={props.onBlur}
      onChange={props.onChange}
      onKeyDown={handleKeyDown}
      onClick={handleClick}
      readOnly={props.readOnly}
    />
  );
});

Input.displayName = 'Input';
