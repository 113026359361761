import * as React from 'react';
import {
  get, isEmpty, isNil, map, uniq, chain, some,
} from 'lodash';

const {
 useCallback, useEffect, useMemo, useState,
} = React;

import {
  PlaceTypeOption,
  TPlacesOptions,
  TPlace,
  TPlaceAutocompleteCallback,
} from '@frontend/components/widgets/PlacesInput/types';

const placeTypeMap = {
  [PlaceTypeOption.City]: '(cities)',
  [PlaceTypeOption.State]: '(regions)',
  [PlaceTypeOption.Country]: '(regions)',
};

const predictionFilterMap = {
  [PlaceTypeOption.State]: (prediction: google.maps.places.AutocompletePrediction) => prediction.types[0] === 'administrative_area_level_1',
  [PlaceTypeOption.Country]: (_prediction: google.maps.places.AutocompletePrediction) => true,
  [PlaceTypeOption.City]: (_prediction: google.maps.places.AutocompletePrediction) => true,
};

const convertOptions = (options: TPlacesOptions): google.maps.places.AutocompletionRequest => {
  const { input, types, countryCode } = options;

  const requestOptions: google.maps.places.AutocompletionRequest = { input };

  if (!isEmpty(types)) {
    requestOptions.types = uniq(map(types, (t) => placeTypeMap[t]));
  }

  if (!isEmpty(countryCode)) {
    requestOptions.componentRestrictions = {
      country: countryCode,
    };
  }

  return requestOptions;
};

const mapPrediction = (options: TPlacesOptions, prediction: google.maps.places.AutocompletePrediction): TPlace | null => {
  if (isEmpty(options.types) || some(options.types, (t) => predictionFilterMap[t](prediction))) {
    return {
      label: prediction.description,
      value: prediction.place_id,
    };
  }

  return null;
};

const getPlaces = (
  googleService: google.maps.places.AutocompleteService,
  options: TPlacesOptions,
): Promise<TPlace[]> => new Promise((resolve, reject) => {
    if (!googleService) {
      resolve([]);
      return;
    }

    if (!options.input) {
      resolve([]);
      return;
    }

    const request = convertOptions(options);

    googleService.getPlacePredictions(request, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const places = chain(result)
          .map((prediction) => mapPrediction(options, prediction))
          .omitBy(isEmpty)
          .toArray()
          .value();

        resolve(places);
      } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        resolve([]);
      } else {
        const error = new GoogleAutocompleteError('Failed to fetch places', status);
        reject(error);
      }
    });
  });

export class GoogleAutocompleteError extends Error {
  protected status: string;

  constructor(message = 'Google Autocomplete Error.', status: google.maps.places.PlacesServiceStatus) {
    // Calling parent constructor of base Error class.
    super();

    // Sets the error properties.
    this.name = 'GoogleAutocompleteError';
    this.status = String(status);
    this.message = message;

    if (typeof Error.captureStackTrace === 'function') {
      // Capturing stack trace, excluding constructor call from it.
      Error.captureStackTrace(this, GoogleAutocompleteError);
    } else {
      this.stack = new Error().stack;
    }
  }
}

/**
 * Returns a function to make requests to google's places autocomplete API.
 * Can only use it inside the body of a function component.
 */
export const useGooglePlacesAutocomplete = (): TPlaceAutocompleteCallback => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false); // eslint-disable-line
  useEffect(() => {
    const maxLoop = 20; // 10 seconds
    let step = 1;
    const interval = setInterval(() => {
      if (step > maxLoop) {
        clearInterval(interval);
        return;
      } else {
        step++;
      }
      if (isNil(window) || 'google' in window) {
        clearInterval(interval);
        setIsLoaded(true);
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const googleAutocompleteService: google.maps.places.AutocompleteService = useMemo(() => {
    if (isNil(window) || !('google' in window)) {
      return;
    }
    if (!get(google, 'maps.places.AutocompleteService')) {
      throw new Error('google.maps.places.AutocompleteService does not exist.');
    }

    return new google.maps.places.AutocompleteService();
  }, [isLoaded]); // eslint-disable-line

  const getPlacesCallback: TPlaceAutocompleteCallback = useCallback(
    (options) => getPlaces(googleAutocompleteService, options),
    [googleAutocompleteService],
  );

  return getPlacesCallback;
};
