import * as React from 'react';
import { useBreakpoint } from '@revfluence/fresh';
import { useClientFeatureEnabled, useGetHomeReporting } from '@frontend/app/hooks';
import { HistoricalDataGroupBy } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import { DATE_RANGES, getDateRange } from '@frontend/app/containers/HomePageMetrics/components/DateSelect';

import { HomeLayout } from '../../pages/Home/Home';
import { formatDateToQuery, getPercentDiffVariables } from '../Reporting/Reporting';
import HomeLoading from '../../pages/Home/components/HomeLoading';
import { HomeError } from '../../pages/Home/components/HomeError/HomeError';
import { DEFAULT_RECOMMENDATIONS, getLastFullMonths } from '../utils';
import { useInstagramStatus } from '../../hooks/useInstagramStatus';
import MetricsSecondary from '../../pages/Home/components/MetricsSecondary';
import MetricsPrimary from '../../pages/Home/components/MetricsPrimary';
import Impact from '../../pages/Home/components/Impact';
import { AspireAdvice, IAspireAdviceProps } from '../../pages/Home/components/AspireAdvice';
import Header from '../../pages/Home/components/Header';
import { useGetReportingRecommendation } from '../../../../app/hooks/useGetReportingRecommendation';
import { AdviceLoading } from '../../pages/Home/components/HomeLoading/HomeLoading';

interface IImpactHomeProps {
  clientId: string;
}

export const ImpactHome = ({
  clientId,
}: IImpactHomeProps) => {
  const [startDate, endDate] = getDateRange(DATE_RANGES.LAST_6_MONTHS);
  const [chartStartDate, chartEndDate] = getLastFullMonths(4);
  const [compareStartDate, compareEndDate] = getLastFullMonths(2);
  const AlertBanner = useInstagramStatus(clientId);
  const queryVariables = {
    startDate: formatDateToQuery(startDate, true),
    endDate: formatDateToQuery(endDate, false),
    groupBy: HistoricalDataGroupBy.MONTH,
    chartStartDate: formatDateToQuery(chartStartDate, true),
    chartEndDate: formatDateToQuery(chartEndDate, false),
    ...getPercentDiffVariables(
      [startDate, endDate],
      [compareStartDate, compareEndDate],
      false,
    ),
  };
  const { loading: loadingReportingData, data, error } = useGetHomeReporting({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  const { loading: loadingRecommendation, data: dataRecommendation } = useGetReportingRecommendation({
    fetchPolicy: 'cache-and-network',
  });
  const screens = useBreakpoint();
  const isMdScreen = screens.md && !screens.lg;
  const adviceVariant: IAspireAdviceProps['variant'] = isMdScreen ? 'stacked' : 'default';
  const recommendationData = dataRecommendation?.reportingRecommendation || DEFAULT_RECOMMENDATIONS;
  const Advice = loadingRecommendation ? <AdviceLoading /> : <AspireAdvice variant={adviceVariant} recommendations={recommendationData} />;

  const igStoryEstimationEnabled = useClientFeatureEnabled(ClientFeature.IG_ESTIMATED_IMPRESSIONS);
  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);

  const loading = loadingReportingData || igStoryEstimationEnabled === undefined;

  if (error) {
    return <HomeError startDate={startDate} endDate={endDate} advice={Advice} />;
  }

  if (loading) {
    return <HomeLoading />;
  }
  const impactData = {
    totalValue: data.reporting.impact.total.value,
    investment: data.reporting.impact.investment.value,
    impact: data.reporting.impact.roi.value,
    percentDiff: data.reporting.impact.roi.percentDiff,
  };
  const trends = {
    reach: data.reporting.social.totals.reach,
    views: data.reporting.social.totals.views,
    conversions: data.reporting.salesTracking.totals.conversions,
    creators: data.reporting.social.totals.creatorCount,
    cpm: data.reporting.social.totals.cpm,
    cpe: data.reporting.social.totals.cpe,
    tmv: data.reporting.social.totals.tmv,
  };

  return (
    <HomeLayout
      Header={<Header startDate={startDate} endDate={endDate} />}
      Advice={Advice}
      Alert={AlertBanner}
      ImpactComponent={<Impact impactData={impactData} />}
      MetricsPrimary={(
        <MetricsPrimary
          contentData={data.reporting.content.totalCount}
          impressionsData={data.reporting.social.totals.impressions}
          engagementsData={data.reporting.social.totals.engagements}
          salesData={data.reporting.salesTracking.totals.sales}
          igStoryEstimationEnabled={igStoryEstimationEnabled}
          tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
        />
      )}
      MetricsSecondary={(
        <MetricsSecondary
          trends={trends}
          igStoryEstimationEnabled={igStoryEstimationEnabled}
          tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
        />
      )}
    />
  );
};
