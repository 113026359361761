import * as React from 'react';
import { OFFER_TYPE } from '@affiliates/types/globalTypes';
import { logger } from '@common';

export interface IOfferImage {
  offerSource: OFFER_TYPE;
}

export const OfferImage: React.FC<IOfferImage> = (props) => {
  const { offerSource } = props;
  switch (offerSource) {
    case OFFER_TYPE.PROMO_CODE:
      return <img alt="Promo Code Offer" src="https://storage.googleapis.com/aspirex-affiliates/SetupCodeOffer.png" />;
    case OFFER_TYPE.LINK:
      return <img alt="Affiliate Link Offer" src="https://storage.googleapis.com/aspirex-affiliates/SetupLinkOffer.png" />;
    default:
      logger.warn('Invalid offer type on EmptyOfferImg component!');
      return null;
  }
};
