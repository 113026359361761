import { isEmpty, isEqual, isNumber, map } from 'lodash';
import { Store, AnyAction, Middleware } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  IConnectSearchPage,
  FetchFrom,
} from '../models';
import { fetchCreators, refetchCreators, SearchResultsActionTypes } from './searchResultsActions';
import { ImageSearchActionTypes } from './imageSearchActions';
import { TextSearchActionTypes } from './textSearchActions';
import { getInitialTextSearchFilters } from '../utils/initialFilters';

export type CSThunkDispatch = ThunkDispatch<IConnectSearchPage, unknown, AnyAction>;

export const FIRST_PAGE = 0;

/**
 * Refreshes search results.
 */
export const refreshSearchResults: Middleware = (store: Store<IConnectSearchPage>) => (next) => (
  action: AnyAction,
) => {
  const result = next(action);

  const dispatch: CSThunkDispatch = store.dispatch;
  const state = store.getState();

  switch (action.type) {
    case SearchResultsActionTypes.FETCH_FEATURED_CREATORS:
      dispatch(fetchCreators(
        FIRST_PAGE,
        true,
        false,
        true,
        false,
        action.payload.version,
      ));
      break;

    case SearchResultsActionTypes.APPLY_SEARCH_FILTERS: {
      if (state.imageSearch.imageURL && state.searchResults.isImageSearchSelected) {
        dispatch(fetchCreators(
          FIRST_PAGE,
          false,
          true,
          true,
          false,
          action.payload.version,
          FetchFrom.ApplyFilters
        ));
      } else {
        const initialFilters = getInitialTextSearchFilters({
          placesIds: [],
          networkTypes: ['instagram'],
          acceptedCountryCodes: [],
          acceptedLanguageCodes: [],
          version: action.payload.version,
        });

        /** Get the featured creators if the filters are the same as the initial ones */
        if (isEqual(initialFilters, state.textSearch)) {
          dispatch(fetchCreators(
            FIRST_PAGE,
            true,
            false,
            true,
            false,
            action.payload.version,
            FetchFrom.ApplyFilters,
          ));
        } else {
          dispatch(fetchCreators(
            FIRST_PAGE,
            false,
            false,
            true,
            false,
            action.payload.version,
            FetchFrom.ApplyFilters,
          ));
        }
      }
      break;
    }

    case SearchResultsActionTypes.REFETCH_CREATORS_REQUEST: {
      const { socialAccounts } = action.payload;

      if (!isEmpty(socialAccounts)) {
        const socialAccountsIds = map(socialAccounts, 'id');
        const hasEmail = socialAccounts[0].has_email;

        dispatch(refetchCreators(
          state.external.apiEndpoint,
          socialAccountsIds,
          hasEmail
        ))
      }

      break;
    }

    case SearchResultsActionTypes.FETCH_PAGE: {
      dispatch(
        fetchCreators(
          action.payload.page,
          state.searchResults.isFeaturedSearch,
          state.searchResults.isImageSearch,
          false,
          false,
          action.payload.version,
          FetchFrom.Pagination,
        ),
      );
      break;
    }

    case SearchResultsActionTypes.FETCH_NEXT_PAGE: {
      if (!state.searchResults.isLoading && state.searchResults.hasNextPage) {
        const nextPage = isNumber(state.searchResults.currentPage)
          ? state.searchResults.currentPage + 1
          : FIRST_PAGE;
        dispatch(
          fetchCreators(
            nextPage,
            state.searchResults.isFeaturedSearch,
            state.searchResults.isImageSearch,
            false,
            true,
            action.payload.version,
            FetchFrom.Pagination,
          ),
        );
      }
      break;
    }

    case TextSearchActionTypes.UPDATE_SEARCH_FILTERS: {
      /** Uncomment the lines below if you want to re-enable real-time search for V1 */

      // const networkSearchVersion = state.external.versionByNetwork?.[state.textSearch.network?.channel];
      // if (networkSearchVersion === CreatorSearchVersion.v2) {
      //   /** Prevent real-time search */
      //   break;
      // }

      // if (prevState.textSearch.query !== state.textSearch.query) {
      //   dispatch(fetchCreators(FIRST_PAGE, false, false, true));
      // }
      break;
    }

    case ImageSearchActionTypes.SELECT_IMAGE:
    case ImageSearchActionTypes.UPLOAD_IMAGE_SUCCESS: {
      if (state.imageSearch.imageURL) {
        dispatch(fetchCreators(FIRST_PAGE, false, true, true));
      }
      break;
    }

    default:
      break;
  }

  return result;
};
