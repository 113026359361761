/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gyx13_157 {
  display: flex;
}

._justify-content-space-between_gyx13_161 {
  justify-content: space-between;
}

._tabular-nums_gyx13_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gyx13_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_gyx13_178 ._header_gyx13_178 ._title_gyx13_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_gyx13_178 ._header_gyx13_178 ._title_gyx13_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gyx13_157 {
  display: flex;
}

._justify-content-space-between_gyx13_161 {
  justify-content: space-between;
}

._tabular-nums_gyx13_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gyx13_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_gyx13_178 ._header_gyx13_178 ._title_gyx13_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_gyx13_178 ._header_gyx13_178 ._title_gyx13_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._content_gyx13_178 {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
._content_gyx13_178 ._header_gyx13_178 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._content_gyx13_178 ._header_gyx13_178 ._title_gyx13_178 {
  display: flex;
  flex-direction: column;
}
._content_gyx13_178 ._header_gyx13_178 ._title_gyx13_178 h3 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  color: #2e426d;
}
._content_gyx13_178 ._header_gyx13_178 ._title_gyx13_178 h4 {
  margin: 0 0 0.5rem 0.5rem;
  color: #8f8d91;
}
._content_gyx13_178 ._header_gyx13_178 ._select_gyx13_420 {
  margin-left: auto;
}

._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._container_gyx13_431 {
  display: flex;
  flex-direction: row;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 {
  margin-right: 1rem;
  flex-shrink: 0;
  width: 10rem;
  display: flex;
  flex-direction: column;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435:last-of-type {
  margin-right: 0;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._imageSection_gyx13_445 {
  margin-bottom: 0.5rem;
  height: 10rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 0.25rem;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  cursor: pointer;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._imageSection_gyx13_445:hover {
  transform: translateY(-0.125rem);
  box-shadow: rgba(0, 0, 0, 0.16) 0rem 0.1875rem 0.625rem, rgba(0, 0, 0, 0.23) 0rem 0.1875rem 0.625rem;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._imageSection_gyx13_445 ._image_gyx13_445 {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center top;
  border-radius: 0.25rem;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._imageSection_gyx13_445 ._network_gyx13_467 {
  margin: 0;
  padding: 0.5rem;
  width: 2.25rem;
  height: 2.25rem;
  right: 0.5rem;
  bottom: 0.5rem;
  position: absolute;
  background-color: rgba(253, 253, 253, 0.8);
  border-radius: 624.9375rem;
  box-sizing: border-box;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._imageSection_gyx13_445 ._network_gyx13_467 ._networkIcon_gyx13_479 {
  margin: 0;
  padding: 0;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 ._infoItem_gyx13_489 {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8f8d91;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 ._infoItem_gyx13_489 ._username_gyx13_496 {
  color: inherit;
  text-decoration: none;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 ._infoItem_gyx13_489 ._username_gyx13_496:hover {
  color: #3996e0;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 ._infoItem_gyx13_489 ._type_gyx13_504 {
  color: #8f8d91;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 ._infoItem_gyx13_489 ._count_gyx13_507 {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #3996e0;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 ._infoItem_gyx13_489 ._count_gyx13_507 ._icon_gyx13_514 {
  color: #EACD60;
  margin-right: 0.25rem;
}
._TopCreatorsSection_gyx13_424 ._content_gyx13_178 ._main_gyx13_424 ._item_gyx13_435 ._info_gyx13_483 ._infoItem_gyx13_489 ._ratingIcon_gyx13_518 {
  margin-left: 0.5rem;
  fill: #eacd60;
}