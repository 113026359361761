import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
  Button, Space, Table, Typography,
} from '@revfluence/fresh';
import { Description } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/OrderRequestSetup/components';
import { UserAvatar } from '@frontend/app/components';
import {
  CheckCircleFilled, CloseCircleFilled,
} from '@ant-design/icons';

const { Text } = Typography;
import styles from './OrderTable.scss';

interface AvatarWithNameProps {
  name: string;
  avatar: string;
}

export const AvatarWithName = ({ name, avatar }: AvatarWithNameProps) => {
  const avatarProps = {
    name,
    ...(avatar ? { avatar } : {}),
    size: 40,
  };
  return (
    <div className={styles.nameCell}>
      <UserAvatar {...avatarProps} className={styles.avatar} />
      <div className={styles.name}>{name || ''}</div>
    </div>
  );
};

type OrderTableProps = {
  onPrimaryAction: () => void,
  onSecondaryAction: () => void,
  primaryActionText: string,
  secondaryActionText: string,
  title: string,
  description: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnProps<Record<string, any>>[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSource: Record<string, any>[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowKey: string | ((record: any) => string),
  loading: boolean,
  ordersSelected: number,
};

export const OrderTable = (
  {
    onPrimaryAction,
    onSecondaryAction,
    primaryActionText,
    secondaryActionText,
    title,
    description,
    columns,
    dataSource,
    rowKey,
    loading,
    ordersSelected,
  }: OrderTableProps,
) => (
  <div className={styles.OrderReview}>
    <>
      <div className={styles.mainContentWrapper}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Description
            title={title}
            description={description}
          />
          <Text strong>{`${ordersSelected} Orders selected`}</Text>
          <Table
            columns={columns}
            dataSource={dataSource}
            rowKey={rowKey}
          />
        </Space>
      </div>
      <div className={styles.footer}>
        <Space direction="horizontal" align="center" size="small">

          <Button
            className={styles.footerButton}
            type="default"
            icon={<CloseCircleFilled style={{ fontSize: '16px', color: '#F5222D' }} />}
            onClick={onSecondaryAction}
            loading={loading}
          >
            {secondaryActionText}
          </Button>
          <Button
            className={styles.footerButton}
            type="default"
            icon={<CheckCircleFilled style={{ fontSize: '16px', color: '#52C41A' }} />}
            onClick={onPrimaryAction}
            loading={loading}
          >
            {primaryActionText}
          </Button>
        </Space>
      </div>
    </>
  </div>
);
