import * as React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';

import { ArrowRightIcon, CheckIcon, EllipsisIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Row,
  Spinner,
  Typography,
} from '@revfluence/fresh';
import { STAPaymentGroupInvoice } from '@affiliates/components';
import { GetPaymentGroupLogEntries_paymentGroupLogEntries as IPaymentGroupLog } from '@affiliates/queries/types/GetPaymentGroupLogEntries';
import { DataFormat, formatValue } from '@affiliates/utils/formatValue';
import { PAYMENT_APP_ID } from '@frontend/app/constants/applicationIds';
import { DateFilter, IDateRangeSettings } from '@frontend/app/components';
import { TPaymentsDue } from '../../contexts';

import styles from './PaymentOverview.scss';
// eslint-disable-next-line import/order
import menuStyles from '@frontend/app/components/DateFilter/DateMenu.scss';

const { Link, Title, Text } = Typography;
const paymentsIcon = require('@frontend/app/assets/svgs/payment_circle.svg');

const { useState } = React;

interface IPaymentsOverview {
  clientId: string;
  clientName: string;
  dateFilter: IDateRangeSettings;
  earliestDate: Date | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: any;
  visible: boolean;
  pendingPaymentGroups: IPaymentGroupLog[];
  paidPaymentGroups: IPaymentGroupLog[];
  balance: number;
  totalPaymentsDue: TPaymentsDue;
  forOneOffer: boolean;
  isMakeAPaymentLoading: boolean;
  makeAPayment: () => void;
}

export const PaymentsOverview: React.FC<Readonly<IPaymentsOverview>> = React.memo((props) => {
  const history = useHistory();
  const [paymentInvoicePaymentGroupId, setPaymentInvoicePaymentGroupId] = useState<number>();
  const [isPaymentInvoiceOpen, setIsPaymentInvoiceOpen] = useState(false);

  const header = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div className={(styles as any).header}>
      <Row justify="center">
        <img src={paymentsIcon} />
      </Row>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Row justify="center" className={(styles as any).paymentOverviewTitle}>
        <Title level={4}>Payments Overview</Title>
      </Row>
    </div>
  );

  const renderPaymentGroupRow = (paymentGroupLog: IPaymentGroupLog, index: number) => {
    const onClick = () => {
      if (isNull(paymentGroupLog.externalId)) {
        return;
      }
      setPaymentInvoicePaymentGroupId(paymentGroupLog.externalId);
      setIsPaymentInvoiceOpen(true);
    };

    return (
      <div key={`${index}.${paymentGroupLog.status}`}>
        <Row className={styles.paymentEntry} justify="space-between" align="middle">
          <Col>{paymentGroupLog.name}</Col>
          <Col>
            $
            {formatValue(DataFormat.Money, paymentGroupLog.totalAmount)}
            <span>
              {!isNull(paymentGroupLog.externalId) && (
                <Button
                  style={{
                    padding: '0px 4px',
                    marginLeft: '4px',
                    color: '#3996e0',
                    height: '20px',
                  }}
                  type="text"
                  shape="round"
                  icon={<ArrowRightIcon />}
                  onClick={onClick}
                />
              )}
            </span>
          </Col>
        </Row>
        <Divider className={styles.titleDivider} />
      </div>
    );
  };

  const disableMakePaymentsButton = (
    props.totalPaymentsDue.state !== 'ready' || props.totalPaymentsDue.paymentsDue === 0
  );
  let dollarAmount = null;
  switch (props.totalPaymentsDue.state) {
    case 'loading':
    case 'failed':
      dollarAmount = (
        <Spinner indicator={<LoadingOutlined spin />} />
      );
      break;
    case 'ready':
      dollarAmount = formatValue(DataFormat.Money, props.totalPaymentsDue.paymentsDue);
      break;
  }

  return (
    <>
      <Drawer
        destroyOnClose
        title={header}
        onClose={props.onClose}
        visible={props.visible}
        className={styles.PaymentOverview}
        width="450px"
        mask={false}
      >
        <Row className={styles.spaceBottom} justify="space-between" align="middle">
          <Col className={styles.subtitle1}>
            <Title level={5}>Payments Due</Title>
          </Col>
          <Col className={styles.smallText}>
            <Text>
              Balance: $
              {formatValue(DataFormat.Money, props.balance)}
            </Text>
          </Col>
        </Row>

        <Row justify="space-between" align="middle">
          <Col className={styles.subtitle1} span={24}>
            <DateFilter
              buttonClassName={styles.dateFilterButton}
              menuClassName={menuStyles.paymentsOverviewDateMenu}
              earliestDate={props.earliestDate}
              settings={props.dateFilter}
            />
          </Col>
        </Row>

        <div className={styles.paymentBalance}>
          <Row justify="center" className={styles.cost}>
            $
            {dollarAmount}
          </Row>
          <Row justify="center" className={styles.caption}>
            <Text>{props.forOneOffer ? 'Payments Due from Offer' : 'Total Payments Due'}</Text>
          </Row>
        </div>

        <Row justify="center">
          <Button
            type="primary"
            onClick={props.makeAPayment}
            disabled={disableMakePaymentsButton}
            loading={props.isMakeAPaymentLoading}
          >
            Make a Payment
          </Button>
        </Row>

        <Divider />

        <div className={styles.paymentLog}>
          <div className={styles.paymentLogHeader}>
            <Row justify="space-between">
              <Col className={styles.paymentLogTitle}>
                <Title level={5}>Payment History</Title>
              </Col>
              <Col className={styles.smallText}>
                <Link onClick={() => history.push(`/app/${PAYMENT_APP_ID}`)}>
                  View History
                </Link>
              </Col>
            </Row>
          </div>

          <Row align="middle" justify="start">
            <Col><EllipsisIcon /></Col>
            <Col><Text className={styles.title}>pending</Text></Col>
            <Divider className={styles.titleDivider} />
          </Row>

          {props.pendingPaymentGroups.length === 0
            && <Row className={styles.noPayment}><Text>No Payments are pending</Text></Row>}

          {props.pendingPaymentGroups
            && props.pendingPaymentGroups.map((paymentGroupLog, idx) => renderPaymentGroupRow(paymentGroupLog, idx))}

          <Row className={styles.paymentLog} align="middle" justify="start">
            <Col><CheckIcon /></Col>
            <Col><Text className={styles.title}>Paid</Text></Col>
            <Divider className={styles.titleDivider} />
          </Row>

          {props.paidPaymentGroups.length === 0
            && <Row className={styles.noPayment}><Text>No Payments have been made</Text></Row>}

          {props.paidPaymentGroups
            && props.paidPaymentGroups.map((paymentGroupLog, idx) => renderPaymentGroupRow(paymentGroupLog, idx))}
        </div>

      </Drawer>
      <STAPaymentGroupInvoice
        clientId={props.clientId}
        clientName={props.clientName}
        paymentGroupId={paymentInvoicePaymentGroupId}
        visible={isPaymentInvoiceOpen}
        onClose={() => setIsPaymentInvoiceOpen(false)}
      />
    </>
  );
});

PaymentsOverview.displayName = 'PaymentsOverview';
