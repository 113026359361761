import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { MARK_CONTENT_REVIEW_COMPLETE } from '@frontend/app/queries';
import { MarkContentReviewCompleteMutation, MarkContentReviewCompleteMutationVariables } from '../queries/types/MarkContentReviewCompleteMutation';

type IOptions = MutationHookOptions<MarkContentReviewCompleteMutation, MarkContentReviewCompleteMutationVariables>;

export const useMarkContentReviewComplete = (options: IOptions = {}) => (
  useMutation<MarkContentReviewCompleteMutation, MarkContentReviewCompleteMutationVariables>(MARK_CONTENT_REVIEW_COMPLETE, options)
);
