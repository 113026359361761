import * as React from 'react';
import cx from 'classnames';
import { trim } from 'lodash';

import { Input, Button, SpinnerIcon } from '@components';

import { useMessagingContext } from '@frontend/hooks';
import {
  SimpleField, SimpleForm, SimpleTextarea, Switch, ThumbnailUpload,
} from '@frontend/app/components';
import { useUploadContent, useFeatureFlagVerbiage, IContent } from '@frontend/app/hooks';
import { MY_FAVORITES_TITLE } from '@frontend/app/constants';
import Tooltip from '@frontend/applications/AffiliatesApp/AspireUI/Tooltip/Tooltip';

import { DeleteCommunity } from './DeleteCommunity/DeleteCommunity';

import styles from './CommunityBasics.scss';

const {
  useState, useEffect, useCallback, useRef, useMemo,
} = React;

const INVALID_GROUP_TITLES = [MY_FAVORITES_TITLE];

interface IProps {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  community: any;
  placeholderSplashUrl: string;
  isApplicantReview: boolean;
  isSaving?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onClickSave?(communityInput: any): void;
  className?: string;
  isNew?: boolean;
  handleDeleteCommunity?(id: number): void;
  isDeletingCommunity?: boolean;
}

export const CommunityBasics: React.FunctionComponent<IProps> = React.memo((props) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [communityInput, setCommunityInput] = useState<any>({
    title: '',
    description: '',
    splashImageUrl: '',
    showApplicantReview: false,
  });

  const verbiage = useFeatureFlagVerbiage();

  const formRef = useRef<SimpleForm>();
  const [tempLogoSrc, setTempLogoSrc] = useState<string>('');
  const [tempLogoFile, setTempLogoFile] = useState<File>(null);
  const [titleError, setTitleError] = useState<string>();

  const {
    content: uploadContent, upload: uploadLogo, error: uploadError, isUploading,
  } = useUploadContent({
    serviceName: 'community',
    parentFolder: props.community && `${props.community.id}/logo`,
  });

  const { showErrorMessage } = useMessagingContext();

  useEffect(() => {
    if (uploadError) {
      // Don't show any error if upload is aborted
      if (!uploadError.isUploadAborted) {
        showErrorMessage(uploadError.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const updateCommunityInputWithResult = (result: any) => {
    setTempLogoSrc('');
    setTempLogoFile(null);
    setCommunityInput({
      id: result.id,
      title: result.title,
      description: result.description,
      splashImageUrl: result.splashImageUrl || '',
      showApplicantReview: result.showApplicantReview,
    });
  };

  useEffect(() => {
    if (props.community) {
      updateCommunityInputWithResult(props.community);
      setTempLogoSrc(props.community.splashImageUrl);
    }
  }, [props.community]);

  const getTitleError = useCallback(
    (title: string) => {
      if (INVALID_GROUP_TITLES.includes(title)) {
        return props.isNew
          ? `${title} is a default group name and cannot be recreated.`
          : 'This group is a default group and the name can’t be edited';
      }
      return null;
    },
    [props.isNew],
  );

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const handleChangeForm = useCallback((fieldName: string, value: any) => {
    setCommunityInput((community) => ({
      ...community,
      [fieldName]: value,
    }));
  }, []);

  useEffect(() => {
    const titleError = getTitleError(communityInput.title);
    setTitleError(titleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityInput.title]);

  const handleClickSave = useCallback(() => {
    formRef.current.submit();
  }, [formRef]);

  const handleSubmit = async () => {
    let fileUrl;
    if (tempLogoFile) {
      fileUrl = await uploadLogo(tempLogoFile, 'image', {
        imageCompressionEnabled: true,
      });
    }

    if (props.onClickSave) {
      props.onClickSave({
        ...communityInput,
        splashImageUrl: fileUrl || tempLogoSrc || null,
      });
    }
  };

  const isSaving = props.isSaving || isUploading;

  const isDisabled = !!titleError;

  const disabledErrorTitle = useMemo(() => {
    if (titleError) {
      return titleError;
    }
    return '';
  }, [titleError]);

  const formValues = useMemo(
    () => ({
      ...communityInput,
      thumbnail: tempLogoSrc || communityInput.splashImageUrl,
    }),
    [tempLogoSrc, communityInput],
  );

  const preventDefaultSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const isGroupTitleEditDisabled = useMemo(() => props.community?.title === MY_FAVORITES_TITLE, [props.community]);

  return (
    <form className={cx(styles.CommunityBasics, props.className)} onSubmit={preventDefaultSubmit}>
      <SimpleForm ref={formRef} values={formValues} onChange={handleChangeForm} onSubmit={handleSubmit}>
        <label className={styles.label}>
          Name your
          {' '}
          {verbiage.community}
          :
        </label>
        <SimpleField name="title" type="string" required label={`${verbiage.Community} name`} errorMessage={titleError}>
          <Input className={styles.field} disabled={isGroupTitleEditDisabled} />
        </SimpleField>

        <label className={styles.label}>
          Add an image for your
          {' '}
          {verbiage.community}
          :
        </label>
        <SimpleField name="thumbnail" type="string" label={`${verbiage.Community} image`} required={false}>
          <ThumbnailUpload
            thumbnail={tempLogoSrc}
            onThumbnailSelected={setTempLogoSrc}
            onFileSelected={setTempLogoFile}
            disabled={isSaving}
            progressPercentage={uploadContent && (uploadContent as IContent).progress && (uploadContent as IContent).progress.percentage}
            className={cx(styles.field, styles.thumbnail)}
          />
        </SimpleField>

        <label className={styles.label}>
          What is the purpose of your
          {' '}
          {verbiage.community}
          :
        </label>
        <SimpleField name="description" type="string" required label={`${verbiage.Community} description`}>
          <SimpleTextarea className={styles.field} />
        </SimpleField>
        {props.isApplicantReview && (
          <>
            <label className={styles.label}>
              Show application review
            </label>
            <SimpleField name="showApplicantReview" type="boolean" required label="Show application review">
              <Switch checked={communityInput.showApplicantReview} className={styles.field} />
            </SimpleField>
          </>
        )}
      </SimpleForm>

      <Tooltip title={disabledErrorTitle} placement="top">
        <Button
          theme="primary"
          onClick={handleClickSave}
          label="Save"
          disabled={isSaving || isDisabled}
          icon={isSaving && <SpinnerIcon size={16} />}
        />
      </Tooltip>

      {!props.isNew && trim(props.community?.title) !== MY_FAVORITES_TITLE && (
        <DeleteCommunity
          community={props.community}
          isDeletingCommunity={props.isDeletingCommunity}
          handleDeleteCommunity={props.handleDeleteCommunity}
        />
      )}
    </form>
  );
});

CommunityBasics.defaultProps = {
  isSaving: false,
};
