import { includes } from 'lodash';

/**
 * Returns a sanitized promo code from the user input.
 * Promo codes are all uppercase and all whitespace and punctuation characters
 * are removed.
 * @param input string The user input string.
 * @returns string Returns the sanitized promo code string.
 */
export const cleanPromoCode = (input: string): string => {
  const cleanCode = input.trim().toUpperCase();
  let toReturn = '';
  for (let i = 0; i < cleanCode.length; i++) {
    const char = cleanCode.charAt(i);
    // allow digits, letters only
    // do not allow whitespace, punctuation
    if (Number.isNaN(parseInt(char, 10)) && char === char.toLowerCase() && !includes(['-', '_'], char)) {
      continue;
    }
    toReturn += char;
  }
  return toReturn;
};
