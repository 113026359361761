import * as React from 'react';

import styles from './BaseEmptyState.scss';

interface IProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export const BaseEmptyState: React.FC<IProps> = (props) => (
  <div className={styles.BaseEmptyState}>
    {props.title && (
    <div className={styles.title}>
      {props.title}
    </div>
      )}
    {props.subtitle && (
    <div className={styles.subtitle}>
      {props.subtitle}
    </div>
      )}
  </div>
);

BaseEmptyState.displayName = 'BaseEmptyState';
