import * as React from 'react';
import { isNil, trim } from 'lodash';

import { logger } from '@common';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';

const { useEffect, useMemo, useState } = React;

const request = async (url): Promise<boolean> => {
  try {
    const resp = await fetch(url);
    const json = await resp.json();
    if (json?.status?.code === 200 && !isNil(json?.data)) {
      return json.data;
    }
    throw json?.status?.error_msg;
  } catch (err) {
    logger.error({ message: err });
    throw err;
  }
};

export const useHasAnalyticsApiAccess = (username: string) => {
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const url = useMemo(
    () => (username
      ? `${backendServerApiEndpoint()}/ig_oauth/has_analytics_api_access?username=${username}`
      : undefined),
    [username],
  );

  // Make sure to reset hasAccess if username isn't provided
  useEffect(
    () => {
      if (!trim(username)) {
        setHasAccess(false);
      }
    },
    [username],
  );

  const fetch = () => {
    if (url) {
      setIsLoading(true);
      request(url)
        .then((result) => {
          setHasAccess(result);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(fetch, [url]);

  return {
    hasAccess,
    isLoading,
    error,
    refetch: fetch,
  };
};
