import gql from 'graphql-tag';

export const GET_BUDGET_DISTRIBUTION_FOR_FISCAL_YEAR = gql`
  query GetBudgetDistributionForFiscalYear($fiscalYear: Int!) {
    getBudgetDistributionForFiscalYear(fiscalYear: $fiscalYear) {
      budgetDistributions {
        budgetAccountId
        parentBudgetId
        budgetName
        totalAmount
        allocatedAmount
        spentAmount
        availableAmount
        committedAmount
        paidCommittedAmount
        paidCommissionAmount
        quarterDistributions {
          quarterKey
          quarterLabel
          quarterDistributionId
          totalAmount
          allocatedAmount
          spentAmount
          availableAmount
          committedAmount
          paidCommittedAmount
          paidCommissionAmount
        }
      }
    }
  }
`;
