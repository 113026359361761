import { format } from 'date-fns';
import { find, first, get } from 'lodash';

import {
  UtmSource,
  ApplicantSourceType,
} from '@frontend/app/containers/Projects/hooks/useMembershipSources';

import { renderDefaultCell } from '../DefaultCell';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const renderApplicantSourceCell = (_: any, data: any) => {
  const membershipData = first(get(data, 'rowData.programMemberships'));

  if (!membershipData) {
    return renderDefaultCell('');
  }

  const sourceDictionary = {
    [UtmSource.MemberList]: ApplicantSourceType.Invited,
    [UtmSource.ProgramsList]: ApplicantSourceType.Invited,
    [UtmSource.ChromeExtension]: ApplicantSourceType.Invited,
    [UtmSource.CreatorSearch]: ApplicantSourceType.Invited,
    [UtmSource.Marketplace]: ApplicantSourceType.Marketplace,
    [UtmSource.Maven]: ApplicantSourceType.Maven,
    [UtmSource.Brand]: ApplicantSourceType.Organic,
    [UtmSource.CuratedCreators]: ApplicantSourceType.Suggested,
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  return renderDefaultCell(sourceDictionary[(membershipData as any).utmSource] || 'Other');
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const renderAppliedDateCell = (_: any, data: any, projectId?: number, status?: string) => {
  const membershipData = find(get(data, 'rowData.programMembershipsLogs'), (d) => (
    d.status === (status || 'new') && d.programId === projectId
  ));

  if (!membershipData?.createdDate) {
    return renderDefaultCell('');
  }

  const date = new Date(membershipData.createdDate);

  return renderDefaultCell(format(date, 'MMM dd, yyyy'));
};
