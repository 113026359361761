import { logger } from '@common';
import { isNil } from 'lodash';
import { GetAllTasksQuery_tasks } from '../queries/types/GetAllTasksQuery';

type TTaskUIType = 'error';

export interface ITaskUI {
  isTransient?: boolean;
  isNotifyUser?: boolean;
  type?: TTaskUIType;
}

export const hasError = (task: GetAllTasksQuery_tasks, countWorkItem: number) => {
  try {
    const taskUI: ITaskUI = parseToITaskUI(task);
    return taskUI?.type === 'error' && !isNil(countWorkItem) && countWorkItem > 0;
  } catch (err) {
    logger.error(`Error parsing ${task.taskId} taskUI`, err);
    return false;
  }
};

export const hasNotification = (task: GetAllTasksQuery_tasks, countWorkItem: number) => {
  try {
    const taskUI: ITaskUI = parseToITaskUI(task);
    return (taskUI?.isNotifyUser || task.workletSpecType === 'user_template')
      && !isNil(countWorkItem) && countWorkItem > 0;
  } catch (err) {
    logger.error(`Error parsing ${task.taskId} taskUI`, err);
    return false;
  }
};

export const isTransient = (task: GetAllTasksQuery_tasks) => {
  try {
    const taskUI: ITaskUI = parseToITaskUI(task);
    return taskUI?.isTransient;
  } catch (err) {
    logger.error(`Error parsing ${task.taskId} taskUI`, err);
    return false;
  }
};

export const parseToITaskUI = (task: GetAllTasksQuery_tasks): ITaskUI => {
  try {
    return JSON.parse(task?.taskMetaData?.taskUI);
  } catch (err) {
    logger.error(`Error parsing ${task.taskId} taskUI`, err);
    return undefined;
  }
};
