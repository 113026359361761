import React from 'react';
import { Button, Skeleton } from '@revfluence/fresh';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory } from 'react-router-dom';
import { CatalogStatus } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { TabContainer } from '../../Products/TabContainer/TabContainer';
import { CatalogsTable } from '../CatalogsTable/CatalogsTable';
import { useGetCatalogs } from '../hooks/useGetCatalogs';
import { EmptyScreen } from './EmptyScreen';
import { pfaV2Routes } from '../../routes';
import { useCatalogDetailsContext } from '../CatalogDetails/CatalogDetailsContext';

export const CatalogsList = () => {
  const { catalogs, loading } = useGetCatalogs();
  const { setCatalog } = useCatalogDetailsContext();

  const history = useHistory();

  const handleCreateCatalog = () => {
    history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', 'new'));
    setCatalog({ title: 'Name Your Catalog', description: 'Provide a description', status: CatalogStatus.DRAFT });
  };

  return (
    <div>
      <PageHeader
        title="Brand Catalogs"
        subtitle="Customize you catalogs for a better shopping experience and management for creators"
        rightContent={(
          <Button type="primary" icon={<PlusIcon />} onClick={handleCreateCatalog}>
            Create Catalog
          </Button>
        )}
        showBackButton={false}
      />
      <TabContainer>
        {loading && <div data-testid="skeleton"><Skeleton /></div>}
        {!loading && (catalogs.length ? <CatalogsTable /> : <EmptyScreen />)}
      </TabContainer>
    </div>
  );
};
