import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_IMPORTED_PRODUCTS_STATS } from '@frontend/applications/ProductFulfillmentApp/queries';
import { GetImportedProductsStats } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetImportedProductsStats';

export const useGetAllProductsStats = () => {
  const loading = false;
  const stats = {
    shopifyProducts: {
      total: 18,
      increasedBy: 0,
      decreasedBy: -2,
    },
    csvProducts: {
      total: 18,
      increasedBy: 2,
      decreasedBy: 0,
    },
    totalProducts: {
      total: 18,
      increasedBy: 0,
      decreasedBy: -2,
    },
    totalVariants: {
      total: 18,
      increasedBy: 2,
      decreasedBy: 0,
    },
    outOfStockSKUs: {
      total: 18,
      increasedBy: 0,
      decreasedBy: -2,
    },
    pendingProducts: {
      total: 18,
      increasedBy: 2,
      decreasedBy: 0,
    },
  };
  const error = null;
  const refetch = () => {};

  return {
    loading,
    stats,
    error,
    refetch,
  };
};

type IOptions = QueryHookOptions<GetImportedProductsStats>;

export const useGetImportedProductsStats = (options: IOptions = {}) => {
    const {
   loading, data, error, refetch,
  } = useQuery(GET_IMPORTED_PRODUCTS_STATS, options);

  return {
    loading,
    data: data?.getImportedProductsStats,
    error,
    refetch,
  };
};
