import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { TaskTrackerQuery, TaskTrackerQueryVariables } from '@frontend/app/queries/types/TaskTrackerQuery';
import { TASK_TRACKER_QUERY } from '@frontend/app/queries';

type IOptions = QueryHookOptions<TaskTrackerQuery, TaskTrackerQueryVariables>;

export const useTaskTrackerQuery = (options: IOptions = {}) =>
  useQuery<TaskTrackerQuery, TaskTrackerQueryVariables>(TASK_TRACKER_QUERY, {
    ...options,
  });
