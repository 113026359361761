import gql from 'graphql-tag';

export const FORCE_CHECK_IN_AFFILIATE_OFFER_PROMOS_MUTATION = gql`
  mutation ForceCheckInPromos(
    $offerId: Int!,
    $forceCheckInAffiliates:[AffiliateOfferPromoForceCheckIn!]!
  ) {
    forceCheckInAffiliateOfferPromos(
      offerId:$offerId,
      forceCheckInAffiliates:$forceCheckInAffiliates,
    )
  }
`;

export const FORCE_CHECK_IN_AFFILIATE_OFFER_LINKS_MUTATION = gql`
  mutation ForceCheckInLinks(
    $offerId: Int!,
    $forceCheckInAffiliates:[AffiliateOfferLinkForceCheckIn!]!
  ) {
    forceCheckInAffiliateOfferLinks(
      offerId:$offerId,
      forceCheckInAffiliates:$forceCheckInAffiliates,
    )
  }
`;
