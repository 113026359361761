import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const RejectUserIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M15.42,12.47c2.43,0,4.41-2.08,4.41-4.64s-1.98-4.64-4.41-4.64c-2.43,0-4.41,2.08-4.41,4.64
      S12.99,12.47,15.42,12.47z M15.42,4.75c1.57,0,2.85,1.38,2.85,3.08s-1.28,3.08-2.85,3.08s-2.84-1.38-2.84-3.08
      S13.85,4.75,15.42,4.75z"
    />
    <path
      d="M18.37,13.67c-0.39-0.19-0.86-0.03-1.04,0.36c-0.19,0.39-0.03,0.86,0.36,1.04c1.48,0.72,2.42,2.19,2.69,4.18
      h-9.93c0.2-1.85,1.13-3.33,2.61-4.1c0.38-0.2,0.53-0.67,0.33-1.05c-0.2-0.38-0.67-0.53-1.05-0.33c-2.22,1.16-3.49,3.45-3.49,6.27
      c0,0.43,0.35,0.78,0.78,0.78h11.6c0.21,0,0.42-0.09,0.56-0.24c0.15-0.15,0.23-0.36,0.22-0.57C21.87,17,20.58,14.76,18.37,13.67z"
    />
    <path
      d="M7.4,8.22C7.17,8,6.81,8,6.59,8.22l-1.81,1.8l-1.81-1.8C2.74,8,2.39,8,2.17,8.22c-0.22,0.22-0.22,0.58,0,0.8
      l1.81,1.79l-1.81,1.79c-0.22,0.22-0.22,0.58,0,0.8c0.11,0.11,0.26,0.17,0.4,0.17c0.14,0,0.29-0.05,0.4-0.17l1.81-1.8l1.81,1.8
      c0.11,0.11,0.26,0.17,0.4,0.17c0.15,0,0.29-0.06,0.4-0.17c0.22-0.22,0.22-0.58,0-0.8l-1.81-1.79l1.81-1.79
      C7.61,8.81,7.62,8.45,7.4,8.22z"
    />
  </SvgIcon>
));

RejectUserIcon.displayName = 'RejectUserIcon';
