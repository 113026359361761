import gql from 'graphql-tag';

export const GET_AD_ACCOUNT_SETTINGS = gql`
query GetAdAccountsSettings($adAccountPage: Int, $adAccountResultsPerPage: Int) {
  getSocialAccountClientSettings(
    adAccountPage: $adAccountPage,
    adAccountResultsPerPage: $adAccountResultsPerPage,
  ) {
    metaAdAccountData {
      accounts {
        id
        name
        status
        access_lost
        missing_permissions
        social_account_id
      }
      paging {
        currentPage
        totalResults
        resultsPerPage
      }
    }
  }
}
`;

export const GET_SOCIAL_ACCOUNT_SETTINGS = gql`
query GetSocialAccountSettings($socialAccountPage: Int, $socialAccountResultsPerPage: Int) {
  getSocialAccountClientSettings(
    socialAccountPage: $socialAccountPage,
    socialAccountResultsPerPage: $socialAccountResultsPerPage,
  ) {
    instagramAccountData {
      accounts {
        id
        facebook_page_id
        username
        social_listening_debug {
          enabled
          status
          caption_mention {
            status
            missing_permissions
          }
          tag_mention {
            status
            missing_permissions
          }
          story_mention {
            status
            missing_permissions
          }
          branded_content_mention {
            status
            missing_permissions
          }
        }
        igdm_debug {
          enabled
          status
          messaging {
            status
            missing_permissions
            messaging_toggle_disabled
          }
          outreach {
            status
            missing_permissions
            messaging_toggle_disabled
            not_in_creator_marketplace
          }
        }
        branded_content_ads_debug {
          enabled
          status
          missing_permissions
        }
        token_debug {
          status
          error
        }
        ad_account_names
      }
      paging {
        currentPage
        totalResults
        resultsPerPage
      }
    }
  }
}
`;

export const DESELECT_AD_ACCOUNT = gql`
  mutation deselectAdAccount($network: AdNetworkType!, $adAccountId: String!) {
    deselectAdAccount(network: $network, adAccountId: $adAccountId)
  }
`;

export const GET_AVAILABLE_AD_ACCOUNTS = gql`
  query GetAvailableAdAccounts {
    getAvailableAdAccounts {
      network_id
      name
      social_account_id
    }
  }
`;

export const UPDATE_AD_ACCOUNTS = gql`
  mutation UpdateAdAccounts($data: BackendServerUpdateAdAccountsInput!) {
    updateAdAccounts(data: $data)
  }
`;

export const GET_BUSINESS_SETTINGS = gql`
query GetBusinessSettings($businessPage: Int, $businessResultsPerPage: Int) {
  getSocialAccountClientSettings(
    businessPage: $businessPage,
    businessResultsPerPage: $businessResultsPerPage,
  )
    {
      metaBusinessData {
        paging {
          currentPage
          totalResults
          resultsPerPage
        }
        businesses {
        id
        name
        network_id
        status
      }
    }
  }
}
`;

export const GET_AVAILABLE_BUSINESSES = gql`
  query GetAvailableBusinesses {
    getAvailableBusinesses {
      network_id
      name
    }
  }
`;

export const UPDATE_BUSINESSES = gql`
  mutation UpdateBusinesses($businessNetworkIds: [String!]!) {
    updateBusinesses(businessNetworkIds: $businessNetworkIds)
  }
`;

export const DESELECT_BUSINESS = gql`
  mutation deselectBusiness($businessId: String!) {
    deselectBusiness(businessId: $businessId)
  }
`;
