import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { each, isNumber } from 'lodash';

import { ACTIVATIONS_QUERY } from '@frontend/app/queries/ActivationsQuery';
import {
  ActivationsQuery,
  ActivationsQuery_activations,
} from '@frontend/app/queries/types/ActivationsQuery';

import { tagColors, ITagColor } from '@frontend/app/hooks';

export type IActivation = ActivationsQuery_activations;
type IOptions = QueryHookOptions<ActivationsQuery>;

const { useMemo } = React;

export const useActivationsQuery = (options: IOptions = {}) => {
  const {
    loading, refetch, error,
    data: { activations = null } = {},
  } = useQuery<ActivationsQuery>(ACTIVATIONS_QUERY, options);

  const activationColorMap: Map<string, ITagColor> = useMemo(() => {
    const map = new Map();

    each(activations, (a, index) => map.set(
      a.name, tagColors[(isNumber(a.id) ? a.id : index) % tagColors.length],
    ));

    return map;
  }, [activations]);

  return {
    loading,
    activations,
    activationColorMap,
    error,
    refetch,
  };
};
