import { useQuery, QueryHookOptions } from '@apollo/client';

import { MEMBER_ACTIVATIONS } from '@frontend/app/queries';
import {
  MemberActivationsQuery,
  MemberActivationsQueryVariables,
} from '@frontend/app/queries/types/MemberActivationsQuery';

type IOptions = QueryHookOptions<MemberActivationsQuery, MemberActivationsQueryVariables>;

export const useMemberActivationsQuery = (memberId: number, options: IOptions = {}) => useQuery<MemberActivationsQuery, MemberActivationsQueryVariables>(
    MEMBER_ACTIVATIONS,
    {
      ...options,
      variables: {
        memberId,
      },
      skip: !memberId || options.skip,
    },
  );
