import {
  first,
  isEmpty,
  map,
  reduce,
} from 'lodash';
import * as React from 'react';

import { logger } from '@common';
import { useAddCustomWorkletsMutation } from '@frontend/app/hooks';
import { TWorklet } from '@frontend/app/containers/Projects/types';

type TPartialWorklets = Partial<Pick<TWorklet, 'specKey' | 'specTitle'>>;

const { useState } = React;

interface IUseAddWorklets {
  addWorklets: (worklets: TPartialWorklets[]) => Promise<string[]>,
  loading: boolean,
}

/**
 * Adds worklets for specs that don't have specKeys
 * @returns addWorklets: Promise to add worklets where it returns a list of worklet specKeys
 *                       comprised of existing worklet specKeys and the newly added ones.
 * @returns loading
 */
export const useAddWorklets = (): IUseAddWorklets => {
  const [loading, setLoading] = useState(false);

  const [
    addCustomWorklets,
    { loading: isAddingWorklets },
  ] = useAddCustomWorkletsMutation({
    onCompleted: () => {
      setLoading(false);
    },
    onError: (error) => {
      logger.error(error);
      setLoading(false);
    },
  });

  const addWorklets = (worklets: TPartialWorklets[]) => (
    new Promise<string[]>((resolve, reject) => {
      setLoading(true);

      // Grab task names for new worklets
      const taskNames = reduce<TPartialWorklets, string[]>(
        worklets,
        (result, worklet) => {
          if (!worklet.specKey && worklet.specTitle) {
            result.push(worklet.specTitle);
          }
          return result;
        },
        [],
      );

      if (isEmpty(taskNames)) {
        logger.debug('No new worklets to add from:', { worklets });
        resolve(map(worklets, (worklet) => worklet.specKey));
        return;
      }

      logger.debug('Adding new worklets with task names:', { taskNames });

      addCustomWorklets({
        variables: {
          taskNames,
        },
      })
        .then((result) => result?.data?.specKeys)
        .then((specKeys) => {
          const newSpecKeys = [...specKeys];
          const result = map(worklets, (worklet) => (
            worklet.specKey
              ? worklet.specKey
              : first(newSpecKeys.splice(0, 1))
          ));
          setLoading(false);
          resolve(result);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    })
  );

  return {
    addWorklets,
    loading: loading || isAddingWorklets,
  };
};
