import { isEmpty, map, reduce } from 'lodash';

// tracks a count of how many members have this code
interface ICodeCountMap {
  [code: string]: number;
}

// we only actually use these fields from the member
interface IMemberFields {
  code: string;
  isDuplicate: boolean;
  selected: boolean;
}

/**
 * For a given list of members, updates the member.isDuplicate flag.
 * Additional notes:
 * - Empty codes are never considered duplicates.
 * - Order of the members is preserved but the input list is never mutated.
 * - Only members who are selected are considered as possible duplicates.
 * @param members TSelectedMember[] The list of members to check.
 * @returns TSelectedMember[] Returns the list of members in the same order but
 *          with their isDuplicate flag properly set.
 */
export const markDuplicates = <T extends IMemberFields>(members: T[]): T[] => {
  // map each member to an object where the keys are the member's code and the
  // values are a count of how many members have the code
  const codeMap = reduce(members, (codeCountMap, member) => {
    if (!member.selected) {
      // skip members that are not selected
      return codeCountMap;
    }
    if (member.code in codeCountMap) {
      codeCountMap[member.code]++;
      return codeCountMap;
    }
    codeCountMap[member.code] = 1;
    return codeCountMap;
  }, {} as ICodeCountMap);

  return map(members, (member) => {
    // mark the member as a duplicate if:
    // - the code is not empty
    // - the member is selected
    // - there are at least 2 selected members with the same code
    const isDuplicate = !isEmpty(member.code) && member.selected && codeMap[member.code] >= 2;
    if (member.isDuplicate === isDuplicate) {
      return member;
    }
    return { ...member, isDuplicate };
  });
};
