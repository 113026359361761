import * as React from 'react';
import { isEmpty } from 'lodash';

import { Popover } from 'antd';
import {
  SlidersIcon,
  ArrowRotateRightIcon,
  XmarkIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { MemberSearch } from '@frontend/app/components';

import { SortDirection, ThreadLabelType } from '@frontend/app/types/globalTypes';
import { ASSIGNEE_TYPE } from '@frontend/app/types/MessageTypes';

import { Button, Select } from '@revfluence/fresh';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import styles from '../ThreadList.scss';
import { ThreadTypeFilter } from '../ThreadTypeFilter';
import { IApplication } from '../../MemberDetail/types';
import { TSelectedUserLabel } from '../context/MessagingAppContext';

const { Option } = Select;

interface ThreadListHeaderGenericActionsProps {
  excludeApplicationIdsFilter: string[];
  filterApps: IApplication[];
  setExcludeApplicationIdsFilter(sortDir: SortDirection): void;
  assigneeType: string;
  loadingThreads: boolean;
  status?: TSelectedUserLabel;
  syncing: boolean;
  isAppFilterOpen: boolean;
  applications: IApplication[];
  onRefreshMessages(): void;
  setIsAppFilterOpen(value: boolean): void;
  onThreadUserLabelUpdate?(value: TSelectedUserLabel): void;
}

export const ThreadListHeaderGenericActions = ({
  excludeApplicationIdsFilter, filterApps, setExcludeApplicationIdsFilter,
  assigneeType, loadingThreads, applications,
  syncing, isAppFilterOpen,
  onRefreshMessages, setIsAppFilterOpen,
  onThreadUserLabelUpdate,
  status,
}: ThreadListHeaderGenericActionsProps) => {
  const isInboxSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);
  const messageApplicationFilters = (
    <ThreadTypeFilter
      excludeApplicationIdsFilter={excludeApplicationIdsFilter}
      applications={filterApps}
      setExcludeApplicationIdsFilter={setExcludeApplicationIdsFilter}
    />
  );

  return (
    <div className={styles.actionButtons}>
      {isInboxSearchFilter && assigneeType === 'you' && (
        <Select value={status} onChange={onThreadUserLabelUpdate}>
          <Option value={ThreadLabelType.TODO}>To Do</Option>
          <Option value={ThreadLabelType.DONE}>Done</Option>
          <Option value="ALL">All</Option>
        </Select>
      )}
      {
        !isAppFilterOpen
        && (
          <Button
            size="middle"
            type="text"
            icon={<ArrowRotateRightIcon />}
            loading={loadingThreads || syncing}
            disabled={loadingThreads || syncing}
            onClick={onRefreshMessages}
          />
        )
      }
      {
        !isInboxSearchFilter && assigneeType === ASSIGNEE_TYPE.APPNOTIFICATION
        && (
          <Popover
            align={{ offset: [60, 0] }}
            content={messageApplicationFilters}
            placement="bottomRight"
            trigger="click"
            onVisibleChange={setIsAppFilterOpen}
          >
            {
              isAppFilterOpen
                ? (
                  <Button
                    size="middle"
                    type="text"
                  >
                    <XmarkIcon />
                    Close
                  </Button>
                )
                : (
                  <Button
                    size="middle"
                    type="text"
                    icon={<SlidersIcon />}
                    disabled={loadingThreads || syncing || isEmpty(applications)}
                  />
                )
            }
          </Popover>
        )
      }
      {!isInboxSearchFilter && <MemberSearch />}
    </div>
  );
};
