import gql from 'graphql-tag';

import { WORKLET_FRAGMENT } from './fragments';

export const GET_ALL_WORKLETS_FOR_PARENT_SPEC_QUERY = gql`
  query GetAllWorkletsForParentSpecQuery($specKey: String!) {
    worklets: getAllWorkletsForParentSpec(specKey: $specKey) {
      ...WorkletFragment
    }
  }
  ${WORKLET_FRAGMENT}
`;
