import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const ScrewDriverWrenchIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 33">
    <path d="M13.8406 20.0094L25.5985 8.25148L25.2381 7.89103L26.3629 9.01587L29.3335 5.04476L27.5934 3.30469L23.4918 6.15096L24.3804 7.03964L12.6287 18.7976L10.7768 16.9456C10.4412 16.6101 9.90053 16.6101 9.57116 16.9456C9.23557 17.2812 9.23557 17.8219 9.57116 18.1513L10.7954 19.3818L3.79783 26.3856C3.12045 27.0629 3.12045 28.1629 3.79783 28.8403C4.47522 29.5177 5.5752 29.5177 6.25259 28.8403L13.2564 21.8365L14.4807 23.0608C14.6485 23.2286 14.866 23.3094 15.0835 23.3094C15.301 23.3094 15.5185 23.2286 15.6863 23.0608C16.0219 22.7252 16.0219 22.1845 15.6863 21.8551L13.8406 20.0094Z" />
    <path d="M9.09882 12.2225L13.4366 16.5478L15.8913 14.0806L11.5536 9.74908C12.2682 8.06493 11.9389 6.03899 10.5655 4.66557C9.19204 3.29215 7.25931 2.96278 5.56895 3.67746L7.73162 6.11978V8.43781H5.4198L3.03341 6.21921C2.31874 7.90335 2.64811 9.8858 4.02153 11.2592C5.39494 12.6326 7.41468 12.9371 9.09882 12.2225Z" />
    <path d="M22.6839 20.8788L18.3461 16.541L15.8914 18.9958L20.2291 23.3335C19.5144 25.0177 19.8438 27.0374 21.2172 28.417C22.5907 29.7905 24.6104 30.1136 26.3007 29.4052L23.9454 26.9628V24.6448H26.2635L28.7555 26.9442C29.4702 25.26 29.1408 23.2403 27.7674 21.8669C26.394 20.4935 24.368 20.1641 22.6839 20.8788Z" />
  </SvgIcon>
));

ScrewDriverWrenchIcon.displayName = 'ScrewDriverWrenchIcon';
