import {
  GetShopifyCollections_getShopifyCollections_collections,
  GetShopifyCollections_getShopifyCollections_pageInfo,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/GetShopifyCollections';
import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';

export interface ShopifyImportModalState {
  pageNumber: number;
  search: string;
  collections: Record<
    number,
    {
      collections: GetShopifyCollections_getShopifyCollections_collections[];
      pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
    }
  >;
}

export interface ShopifyImportModalContextProps extends ShopifyImportModalState {
  setSearch: (search: string) => void;
  setPageNumber: (pageNumber: number) => void;
  goToNextPage: (payload: {
    collections: GetShopifyCollections_getShopifyCollections_collections[];
    pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
  }) => void;
  goToPreviousPage: () => void;
  setCollectionsByPage: (payload: {
    collections: GetShopifyCollections_getShopifyCollections_collections[];
    pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
  }) => void;
  setCollectionsBySearch: (payload: {
    collections: GetShopifyCollections_getShopifyCollections_collections[];
    pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
  }) => void;
  updateCollectionById: (payload: {
    id: string;
    collection: Partial<GetShopifyCollections_getShopifyCollections_collections>;
  }) => void;
}

export enum Actions {
  SetPageNumber = 'SET_PAGE_NUMBER',
  SetSearch = 'SET_SEARCH',
  GoToNextPage = 'GO_TO_NEXT_PAGE',
  GoToPreviousPage = 'GO_TO_PREVIOUS_PAGE',
  SetCollectionsByPage = 'SET_COLLECTIONS_BY_PAGE',
  SetCollectionsBySearch = 'SET_COLLECTIONS_BY_SEARCH',
  UpdateCollectionById = 'UPDATE_COLLECTION_BY_ID',
}

type ReducerAction =
  | { type: Actions.SetPageNumber; payload: number }
  | { type: Actions.SetSearch; payload: string }
  | {
      type: Actions.GoToNextPage;
      payload: {
        pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
        collections: GetShopifyCollections_getShopifyCollections_collections[];
      };
    }
  | { type: Actions.GoToPreviousPage }
  | {
      type: Actions.SetCollectionsByPage;
      payload: {
        pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
        collections: GetShopifyCollections_getShopifyCollections_collections[];
      };
    }
  | {
      type: Actions.SetCollectionsBySearch;
      payload: {
        pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
        collections: GetShopifyCollections_getShopifyCollections_collections[];
      };
    }
  | {
      type: Actions.UpdateCollectionById;
      payload: {
        id: string;
        collection: Partial<GetShopifyCollections_getShopifyCollections_collections>;
      };
    };

const initialState: ShopifyImportModalState = {
  pageNumber: 1,
  search: '',
  collections: {},
};

export const reducer = (state: ShopifyImportModalState, action: ReducerAction): ShopifyImportModalState => {
  switch (action.type) {
    case Actions.SetPageNumber:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case Actions.SetSearch:
      return {
        ...state,
        search: action.payload,
      };
    case Actions.GoToNextPage: {
      const { collections, pageInfo } = action.payload;
      const newCollections = { ...state.collections };
      newCollections[state.pageNumber + 1] = { collections, pageInfo };
      return {
        ...state,
        pageNumber: state.pageNumber + 1,
        collections: newCollections,
      };
    }
    case Actions.GoToPreviousPage: {
      return {
        ...state,
        pageNumber: state.pageNumber - 1,
      };
    }
    case Actions.SetCollectionsByPage: {
      const { collections, pageInfo } = action.payload;
      const newCollections = { ...state.collections };
      newCollections[state.pageNumber] = { collections, pageInfo };
      return {
        ...state,
        collections: newCollections,
      };
    }
    case Actions.SetCollectionsBySearch: {
      const { collections, pageInfo } = action.payload;
      const newCollections = {};
      newCollections[1] = { collections, pageInfo };
      return {
        ...state,
        pageNumber: 1,
        collections: newCollections,
      };
    }
    case Actions.UpdateCollectionById: {
      const { id, collection } = action.payload;
      const collections = state.collections[state.pageNumber].collections;
      const updatedCollections = collections.map((c) => (c.id === id ? { ...c, ...collection } : c));
      const newCollections = {
        ...state.collections,
        [state.pageNumber]: { collections: updatedCollections, pageInfo: state.collections[state.pageNumber].pageInfo },
      };
      return {
        ...state,
        collections: newCollections,
      };
    }
    default:
      return state;
  }
};

export const useShopifyImportModalReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setSearch: (search: string) => {
        dispatch({ type: Actions.SetSearch, payload: search });
      },
      setPageNumber: (pageNumber: number) => {
        dispatch({ type: Actions.SetPageNumber, payload: pageNumber });
      },
      goToNextPage: (payload: {
        collections: GetShopifyCollections_getShopifyCollections_collections[];
        pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
      }) => {
        dispatch({ type: Actions.GoToNextPage, payload });
      },
      goToPreviousPage: () => {
        dispatch({ type: Actions.GoToPreviousPage });
      },
      setCollectionsByPage: (payload: {
        collections: GetShopifyCollections_getShopifyCollections_collections[];
        pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
      }) => {
        dispatch({ type: Actions.SetCollectionsByPage, payload });
      },
      setCollectionsBySearch: (payload: {
        collections: GetShopifyCollections_getShopifyCollections_collections[];
        pageInfo: GetShopifyCollections_getShopifyCollections_pageInfo;
      }) => {
        dispatch({ type: Actions.SetCollectionsBySearch, payload });
      },
      updateCollectionById: (payload: {
        id: string;
        collection: Partial<GetShopifyCollections_getShopifyCollections_collections>;
      }) => {
        dispatch({ type: Actions.UpdateCollectionById, payload });
      },
    }),
    [],
  );

  return useMemo(() => ({ ...state, ...actions }), [state, actions]);
};

export const ShopifyImportModalContext = createContext<ShopifyImportModalContextProps>(null);

export interface ShopifyImportModalProviderProps {
  children: React.ReactNode;
  initialValue?: Partial<ShopifyImportModalContextProps>;
}

export const ShopifyImportModalProvider = ({
  children,
  initialValue = {},
}: ShopifyImportModalProviderProps) => {
  const value = useShopifyImportModalReducer();

  return (
    <ShopifyImportModalContext.Provider value={{ ...value, ...initialValue }}>
      {children}
    </ShopifyImportModalContext.Provider>
  );
};

export const useShopifyImportModalContext = () => useContext(ShopifyImportModalContext);
