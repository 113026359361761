._CreatorActionButtonGroup_158q6_1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorActionButtonGroup_158q6_1 ._button_158q6_6 {
  margin-right: 12px;
  flex: 1;
}
._CreatorActionButtonGroup_158q6_1 ._button_158q6_6:last-child {
  margin-right: 0;
}
._CreatorActionButtonGroup_158q6_1 ._inviteButton_158q6_13 {
  width: 6.25rem;
}
._CreatorActionButtonGroup_158q6_1 ._offerManageButton_158q6_16 {
  width: 6.25rem;
}