import * as React from 'react';
import { isNull, trim } from 'lodash';
import { Collapse } from '@revfluence/fresh';
import {
  OfferCodeGroupName,
  OfferCodePrefix,
  OfferCodeSuffix,
  OfferCodeDiscountOptions,
  OfferCodeLimitNewCustomersOnly,
  OfferCodeLimitOnePerCustomer,
  OfferCodeLimitCodeUses,
  OfferCodePurchaseRestrictions,
  OfferCodeCollectionOptions,
} from './FormElements';
import {
  IShopifyPromoCodeFormOptions,
  IShopifyPromoCodeFormValues,
  PrefixTypeStrategy,
  TDisabledMap,
} from '../../types';

import styles from '../../OfferForm.scss';

const { useMemo } = React;
const { Panel } = Collapse;

interface IProps {
  disabled: TDisabledMap<IShopifyPromoCodeFormValues>;
  onFieldFocused: (type: string, selected: boolean) => void;
  showStatus: boolean;
  values: IShopifyPromoCodeFormValues;
  formOptionData: IShopifyPromoCodeFormOptions;
}

export const ShopifyPromoCodeSection: React.FC<Readonly<IProps>> = ({
  disabled,
  values,
  formOptionData,
}) => {
  const codeSuffix = useMemo((): React.ReactNode => {
    let prefix = '';
    switch (values.prefixType) {
      case PrefixTypeStrategy.INSTAGRAM_PLUS_SUFFIX:
        prefix = 'Instagramusername';
        break;
      case PrefixTypeStrategy.FULL_NAME_PLUS_SUFFIX:
        prefix = 'Fullname';
        break;
      case PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_PLUS_SUFFIX:
        prefix = 'Jsmith';
        break;
      case PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_PLUS_SUFFIX:
        prefix = 'Sarahm';
        break;
      default:
        return null;
    }
    const suffix = values.codeSuffix || 'summer20';
    const example = trim(prefix + suffix).toUpperCase();
    const help = (
      <>
        Promo code example:
        <br />
        <strong>
          {example}
        </strong>
      </>
    );
    return (
      <div className={styles.codeSuffixWrapper}>
        <OfferCodeSuffix
          disabled={disabled.codeSuffix}
          help={help}
          name="codeSuffix"
          prefixType={values.prefixType}
          required={false}
        />
      </div>
    );
  }, [disabled.codeSuffix, values.prefixType, values.codeSuffix]);
  return (
    <>
      <Collapse defaultActiveKey={['1', '2']} ghost>
        <Panel className={styles.formSection} key={1} header="Promo Code Details">
          <OfferCodeGroupName disabled={disabled.groupName} name="groupName" />
          <OfferCodePrefix
            disabled={disabled.prefixType}
            halfRow={!isNull(codeSuffix)}
            name="prefixType"
          />
          {codeSuffix}
          <OfferCodeDiscountOptions
            disabled={disabled.priceRuleType}
            name="priceRuleType"
            value={values.priceRuleType}
          />
        </Panel>
        <Panel className={styles.formSection} key={2} header="Promo Code Exclusivity (optional)">
          <OfferCodeCollectionOptions
            collections={formOptionData.productCollectionOptions}
            name="productCollections"
            value={values.productCollections}
          />
          <OfferCodePurchaseRestrictions
            disabled={disabled.usageLimitRule}
            name="usageLimitRule"
            value={values.usageLimitRule}

          />
          <OfferCodeLimitOnePerCustomer
            disabled={disabled.specialLimitOnePerSale}
            name="specialLimitOnePerSale"

          />
          <OfferCodeLimitNewCustomersOnly
            disabled={disabled.specialLimitNewCustomersOnly}
            name="specialLimitNewCustomersOnly"

          />
          <OfferCodeLimitCodeUses
            disabled={disabled.specialLimitUsageCapEnabled}
            name="specialLimitUsageCapEnabled"
            value={!!values.specialLimitUsageCapEnabled}

          />
        </Panel>
      </Collapse>
    </>
  );
};
