/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_cjx5v_157 {
  display: flex;
}

._justify-content-space-between_cjx5v_161 {
  justify-content: space-between;
}

._tabular-nums_cjx5v_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_cjx5v_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._FormActions_cjx5v_209._disabled_cjx5v_209 {
  opacity: 0.7;
  pointer-events: none;
}
._FormActions_cjx5v_209 ._titleWrapper_cjx5v_213 {
  border-bottom: 0.0625rem solid #f0f0f0;
  margin-bottom: 1.875rem;
}
._FormActions_cjx5v_209 ._titleWrapper_cjx5v_213 ._title_cjx5v_213 {
  font-size: 1.25rem;
  padding: 1.125rem 0.625rem 0.5rem 1.5rem;
}
._FormActions_cjx5v_209 ._subtitle_cjx5v_221 {
  display: block;
  padding: 0rem 1.5rem;
}
._FormActions_cjx5v_209 ._content_cjx5v_225 {
  margin-top: 0.625rem;
  padding: 0rem 1.5rem 0.5rem 1.5rem;
  border-bottom: 1px solid #f0f0f0;
}
._FormActions_cjx5v_209 ._actions_cjx5v_230 {
  display: flex;
  padding: 0rem 1.5rem;
  gap: 0.75rem;
  justify-content: flex-end;
  margin-top: var(--spacing-md);
  margin-bottom: var(--spacing-md);
}