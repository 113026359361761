import gql from 'graphql-tag';

export const GET_FEATURED_PRODUCTS_QUERY = gql`
  query GetFeaturedProductsQuery($catalogId: Int!, $includeProductDetails: Boolean = false) {
    getFeatureProductsInCatalog(catalogId: $catalogId, includeProductDetails: $includeProductDetails) {
      id
      productId
      variantIds
      product @include(if: $includeProductDetails) {
        id
        name
        images
        totalVariants
      }
    }
  }
`;

export const CREATE_FEATURED_PRODUCTS_MUTATION = gql`
  mutation CreateFeaturedProductsMutation($catalogId: Int!, $products: [CatalogProductsInput!]!) {
    addFeatureProductsInCatalog(catalogId: $catalogId, products: $products) {
      id
    }
  }
`;
