import { INetworkSearchVersion } from '@frontend/applications/SocialDiscoveryApp/pages/InfluencerSearchPage';
import { IImageSearch } from './imageSearch';
import { IExternalVariables } from './external';
import { ISearchResults } from './searchResults';
import { ICreatorSearchFilters } from './textSearch';
import { ISavedSearches } from './savedSearches';

export enum CreatorSearchVersion {
  v1 = 1,
  v2 = 2,
}

export interface IConnectSearchPage {
  external: IExternalVariables;
  imageSearch: IImageSearch;
  searchResults: ISearchResults;
  textSearch: ICreatorSearchFilters;
  savedSearches: ISavedSearches;
  version?: CreatorSearchVersion;
  versionByNetwork?: INetworkSearchVersion;
}

export enum SearchType {
  TextSearch = 'text_search',
  ImageSearch = 'image_search',
  Featured = 'featured',
}

export enum FetchFrom {
  Pagination = 'pagination',
  ApplyFilters = 'apply_filters',
}
