import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_TABLE_COLUMNS } from '@frontend/app/queries';
import {
  GetAllTableColumns,
} from '@frontend/app/queries/types/GetAllTableColumns';

type IOptions = QueryHookOptions<GetAllTableColumns>;

export const useGetAllTableColumns = (options: IOptions = {}) => (
  useQuery<GetAllTableColumns>(GET_ALL_TABLE_COLUMNS, options)
);
