import { isEmpty, map, some } from 'lodash';

import { TSelectedMember } from '@affiliates/components/MembersWizard/types';
import { TState } from '../types';

interface IAction {
  members: readonly TSelectedMember[];
  type: 'SELECT MEMBERS';
}
export type TSelectMembersAction = Readonly<IAction>;

export const selectMembers = (state: TState, action: TSelectMembersAction): TState => {
  if (isEmpty(state.members)) {
    return state;
  }
  return {
    ...state,
    members: map(state.members, (member) => ({
      ...member,
      selected: some(action.members, (m) => Number(m.id) === member.id),
    })),
  };
};
