import { IMember } from '@frontend/app/hooks';
import {
  get,
  isEmpty,
  split,
  slice,
  trim,
} from 'lodash';

export const getMemberFieldValue = (member: IMember, field: string) => {
  const splitKey = split(field, '.');
  const fieldKey = slice(splitKey, 0, 2).join('.');
  const fieldKeyInPath = slice(splitKey, 2).join('.');

  let fieldValue;

  if (isEmpty(fieldKeyInPath)) {
    fieldValue = trim(get(member, fieldKey));
  } else {
    try {
      const jsonData = JSON.parse(trim(get(member, fieldKey)));
      fieldValue = trim(get(jsonData, fieldKeyInPath));
    } catch (_) {
      fieldValue = undefined;
    }
  }

  return fieldValue;
};
