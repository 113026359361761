// used for renaming variable labels in UI
const variableLabelMap = {
  'Client Name': 'Brand Name',
  'Project Custom Landing Page Path': 'Project Landing Page URL',
  'Project Title': 'Project Name',
};
const variableLabelRegex = new RegExp(Object.keys(variableLabelMap).join('|'), 'gi');

export const replaceVariableLabels = (str) => str.replace(
  variableLabelRegex,
  (label) => variableLabelMap[label],
);
