import * as React from 'react';
import {
  Typography, Space,
} from '@revfluence/fresh';

import { IValue } from '../../Reporting';
import Statistics from '../../../../components/Statistics';

const { Text } = Typography;

export const ValueRow = ({ title, value }: { title: string, value: IValue }) => (
  <Space>
    <Text type="secondary">
      {title}
      {' '}
      (
      <Statistics value={value.dollarMultiplier} prefix="$" />
      {' '}
      x
      {' '}
      <Statistics value={value.value} />
      )
    </Text>
    <Statistics value={value.dollarValue.toFixed(2)} prefix="$" size="default" />
  </Space>
);
