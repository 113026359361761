import * as React from 'react';
import {
 isNil, isUndefined, map, isEmpty, indexOf, isNumber,
} from 'lodash';
import { Modal } from 'antd';
import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';

import { Input, Button } from '@components';
import { useMessagingContext } from '@frontend/hooks';
import { useMemberSearchCountQuery } from '@frontend/app/hooks';
import { Operator } from '@frontend/app/types/MemberSearch';

import { IFieldForm } from './FieldForm';

const { useCallback, useState, useRef } = React;

import styles from './SelectOptions.scss';
import parentStyles from './FieldForm.scss';

interface IProps {
  onChange(options: string[]);
  choices: string[];
  field?: IFieldForm
}

export const SelectOptions: React.FunctionComponent<IProps> = (props) => {
  const isNewField = isNil(props.field?.id);

  const {
    showErrorMessage,
  } = useMessagingContext();

  const { onChange, choices } = props;
  const inputsRef = useRef([]);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [value, setValue] = useState<string>('');
  const [valueToRemove, setValueToRemove] = useState<string>('');

  const queryCount = {
    fields: {
      [Operator.AND]: [{
        memberFieldSchemaId: props.field.id,
        [Operator.CONTAINS]: [valueToRemove],
      }],
    },
  };

  const confirmRemove = (removeIndex: number) => {
    const newChoices = choices.filter((_value, elementIndex) => removeIndex !== elementIndex);
    onChange(newChoices);
    setValueToRemove('');
  };

  const intentRemove = (removeIndex: number) => {
    // Omit confirm if is new field
    if (isNewField) {
      confirmRemove(removeIndex);
      return;
    }

    const choice = choices[removeIndex];
    setValueToRemove(choice);
  };

  const showRemoveConfirmModal = (count?: number) => {
    const isPlural = count > 0
      || isUndefined(count);
const choice = valueToRemove;
    const removeIndex = indexOf(choices, choice);

    const confirmTitle = `Remove ${choice} from ${isPlural ? 'all your members' : 'a member'}?`;
    const confirmMemberCount = isNumber(count) ? `(${count} member${isPlural ? 's' : ''})` : '';
    const confirmContent = `The option ${choice} will be removed from everybody in the system ${confirmMemberCount}.`;

    Modal.confirm({
      title: confirmTitle,
      content: confirmContent,
      centered: true,
      mask: false,
      icon: <ExclamationCircleFilled style={{ color: '#0075FF' }} />,
      okText: 'Remove',
      cancelText: 'Cancel',
      onOk: () => confirmRemove(removeIndex),
      okButtonProps: {
        danger: true,
      },
    });
  };

  useMemberSearchCountQuery({
    onCompleted: ({
      count = 0,
    }) => showRemoveConfirmModal(count),
    onError: () => showRemoveConfirmModal(),
    variables: {
      query: queryCount,
    },
    skip: isNewField
      || !valueToRemove,
  });

  const addOption = useCallback(() => {
    const findDuplicate = choices.find((choice) => choice === value);

    if (!isUndefined(findDuplicate)) {
      showErrorMessage('This name is used in another field. Custom field names must be unique.');
      return;
    }

    setValue('');
    onChange([...choices, value]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, onChange, choices, value]);

  const handleInputChange = useCallback((_value) => {
    setValue(_value);
  }, [setValue]);

  const saveEditInput = (i: number) => {
    if (isNil(inputsRef.current) || !inputsRef.current[i]) return;

    const element = inputsRef.current[i];

    if (element.value) {
      // Check for duplicate values.
      const findDuplicate = choices.find((value, index) => (
        value === element.value
        && index !== i
      ));

      if (!isUndefined(findDuplicate)) {
        showErrorMessage('This name is used in another field. Custom field names must be unique.');
        return;
      }

      const newChoices = [...choices];
      newChoices[i] = element.value;

      onChange(newChoices);
      setEditIndex(-1);
    } else {
      showErrorMessage('Option cannot be empty.');
    }
  };

  const renderOption = (choice, index) => (
    <div className={styles.choice} key={choice}>
      <div className={styles.choiceLabel}>
        {index === editIndex
          ? (
            <Input
              theme="light"
              value={choice}
              ref={(ref) =>
                (inputsRef.current[index] = ref)}
            />
          )
          : choice}
      </div>
      <div className={styles.choiceActions}>
        <Button
          onClick={() => (
            index === editIndex
              ? saveEditInput(index)
              : setEditIndex(index)
          )}
          theme="info"
          label={index === editIndex ? 'Save' : 'Edit'}
          round
        />
        <Button
          onClick={() => intentRemove(index)}
          theme="danger"
          label="Remove"
          round
        />
      </div>
    </div>
  );

  return (
    <div className={styles.SelectOptions}>
      {!isEmpty(choices) && (
        <div className={styles.choices}>
          <div className={parentStyles.name}>
            <label className={parentStyles.label}>
              Current options
            </label>
          </div>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <div className={(styles as any).choicesList}>
            {map(choices, (choice, index) => renderOption(choice, index))}
          </div>
        </div>
      )}
      <div className={parentStyles.form}>
        <div className={parentStyles.name}>
          <label className={parentStyles.label}>
            Option name
          </label>
          <Input
            theme="light"
            value={value}
            onChange={handleInputChange}
          />
        </div>
        <div className={parentStyles.type}>
          <Button
            disabled={!value}
            theme="info"
            icon={<PlusCircleOutlined />}
            label="Add"
            onClick={addOption}
            className={styles.button}
          />
        </div>
      </div>
    </div>
  );
};
