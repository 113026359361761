import * as React from 'react';
import {
  Button,
  IButtonProps,
  Space,
  Typography,
} from '@revfluence/fresh';
import { ChartColumnIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './SetupSalesTracking.scss';

interface IProps {
  onClickDismissSetup: IButtonProps['onClick'];
}

export const SetupSalesTracking: React.FC<IProps> = React.memo((props) => {
  const { onClickDismissSetup } = props;

  return (
    <div className={styles.SetupSalesTracking}>
      <Space direction="vertical" size="large">
        <ChartColumnIcon className={styles.icon} />
        <Space direction="vertical">
          <Typography.Text strong>
            See conversions as they are generated.
          </Typography.Text>
          <Typography.Text type="secondary">
            You&rsquo;ll start to see conversions as they are generated via your
            creators links/codes.
          </Typography.Text>
        </Space>
        <Button
          onClick={onClickDismissSetup}
          type="default"
        >
          Sounds Good
        </Button>
      </Space>
    </div>
  );
});
SetupSalesTracking.displayName = 'SetupSalesTracking';
