import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const HeartIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <g transform="translate(0, -2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.5287 13.804C7.65336 13.93 7.82336 14 8.00003 14C8.17736 14 8.3467 13.93 8.47203
            13.8047L13.6507 8.61734C15.008 7.25734 15.008 5.04467 13.65 3.68467C12.9947 3.02867
            12.1194 2.66667 11.1874 2.66667C10.254 2.66667 9.37936 3.02867 8.72336 3.68534L8.00003
            4.40934L7.2767 3.68467C6.62136 3.02867 5.74603 2.66667 4.81336 2.66667C3.8807 2.66667
            3.00603 3.02867 2.35003 3.68467C0.992698 5.04467 0.992698 7.25734 2.35003 8.61734L7.5287
            13.804ZM3.29323 4.62734C3.69723 4.22267 4.23723 4.00001 4.81323 4.00001C5.3899 4.00001 5.92923
            4.22267 6.33323 4.62667L7.5279 5.824C7.77856 6.07534 8.22123 6.07534 8.4719 5.824L9.66656
            4.62734C10.4746 3.81867 11.8986 3.81734 12.7066 4.62734C13.5459 5.46734 13.5459 6.83467 12.7066
            7.676L7.9999 12.39L3.2939 7.676C2.45457 6.83467 2.45457 5.46734 3.29323 4.62734Z
          "
          style={{ fill: '#8F8D91' }}
        />
        <mask
          id={id}
          maskUnits="userSpaceOnUse"
          x="1"
          y="2"
          width="14"
          height="12"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5287 13.804C7.65336 13.93 7.82336 14 8.00003 14C8.17736 14 8.3467 13.93 8.47203 13.8047L13.6507
              8.61734C15.008 7.25734 15.008 5.04467 13.65 3.68467C12.9947 3.02867 12.1194 2.66667 11.1874 2.66667C10.254
              2.66667 9.37936 3.02867 8.72336 3.68534L8.00003 4.40934L7.2767 3.68467C6.62136 3.02867 5.74603 2.66667
              4.81336 2.66667C3.8807 2.66667 3.00603 3.02867 2.35003 3.68467C0.992698 5.04467 0.992698 7.25734 2.35003
              8.61734L7.5287 13.804ZM3.29323 4.62734C3.69723 4.22267 4.23723 4.00001 4.81323 4.00001C5.3899 4.00001 5.92923
              4.22267 6.33323 4.62667L7.5279 5.824C7.77856 6.07534 8.22123 6.07534 8.4719 5.824L9.66656 4.62734C10.4746 3.81867
              11.8986 3.81734 12.7066 4.62734C13.5459 5.46734 13.5459 6.83467 12.7066 7.676L7.9999 12.39L3.2939 7.676C2.45457
              6.83467 2.45457 5.46734 3.29323 4.62734Z"
            style={{ fill: 'black' }}
          />
        </mask>
        <g mask={`url(#${id})`} />
      </g>
    </SvgIcon>
  );
});

HeartIcon.displayName = 'HeartIcon';

export { HeartIcon };
