import React from 'react';
import { ActionType, TState } from '../types';

interface IAction {
    selectedBudgetReassign: React.Key[];
    type: ActionType.SET_BUDGET_REASSIGN;
}
export type TSetSelectedBudgetReassign = Readonly<IAction>;

export const setSelectedBudgetReassign = (state: TState, action: TSetSelectedBudgetReassign): TState => ({
    ...state,
    selectedBudgetReassign: action.selectedBudgetReassign,
});
