import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_SELECTION_CRITERIA_BY_ID } from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import { GetCatalogSelectionRuleById, GetCatalogSelectionRuleByIdVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetCatalogSelectionRuleById';

type IOptions = QueryHookOptions<GetCatalogSelectionRuleById, GetCatalogSelectionRuleByIdVariables>;

export const useGetSelectionCriteriaById = (options: IOptions) => {
  const {
    loading, data, error, refetch,
   } = useQuery(GET_SELECTION_CRITERIA_BY_ID, options);

  return {
    loading,
    criteria: data?.getCatalogSelectionRuleById,
    error,
    refetch,
  };
};
