import { useQuery, QueryHookOptions } from '@apollo/client';
import {
    GetBudgetDashboardBudgetTabSummaryQuery,
    GetBudgetDashboardBudgetTabSummaryQueryVariables,
} from '../queries/types/GetBudgetDashboardBudgetTabSummaryQuery';
import GET_DASHBOARD_BUDGET_TAB_SUMMARY from '../queries/GetBudgetDashboardBudgetTabSummaryQuery';

type IOptions = QueryHookOptions<GetBudgetDashboardBudgetTabSummaryQuery, GetBudgetDashboardBudgetTabSummaryQueryVariables>;

export function useGetBudgetDashboardBudgetTabSummaryQuery(options: IOptions = {}) {
  return useQuery<GetBudgetDashboardBudgetTabSummaryQuery, GetBudgetDashboardBudgetTabSummaryQueryVariables>(
    GET_DASHBOARD_BUDGET_TAB_SUMMARY,
    options,
  );
}
