import gql from 'graphql-tag';

const SETUP_BUDGET_FISCAL_SETTINGS_MUTATION = gql`
    mutation SetupBudgetFiscalSettingsMutation($budgetFiscalSettings: SetupBudgetFiscalSettingsInput!) {
        BudgetSettingsResponse: setupBudgetFiscalSettings(budgetFiscalSettings: $budgetFiscalSettings) {
            startMonth
            fiscalYears
            quarterNames
        }
    }
`;

export default SETUP_BUDGET_FISCAL_SETTINGS_MUTATION;
