import * as React from 'react';
import {
  isNaN,
  isNil,
  isFunction,
  isString,
  isUndefined,
} from 'lodash';

import { EllipsisLabel } from '@frontend/app/components';
import { TableContext, ITableColumnConfig } from '../tableContext';
import { TableRowContext, ITableCellRenderContext } from '../rowContext';

import { EditableHover, EditInput } from './Editable';

const {
 useContext, useMemo, useState, useEffect, useRef,
} = React;

interface IProps {
  config: ITableColumnConfig;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  value: any;
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const isEmptyValue = (value: any) => isUndefined(value) || isNil(value) || isNaN(value) || value === '';

export const CellContent: React.FC<IProps> = React.memo((props) => {
  const { value: valueProp, config: columnConfig } = props;
  const [isCellSelected] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const prevEditing = useRef<boolean>(isEditing);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [valueState, setValueState] = useState<any>(null);

  // So we can show edited value after editing has ended.
  const value = valueState === null ? valueProp : valueState;

  useEffect(() => {
    setValueState(null);
  }, [valueProp]);

  const {
    cellType,
    field,
    render,
    formatValue,
  } = columnConfig;

  const tableContext = useContext(TableContext);
  const {
    config: tableConfig,
    onChangeCellValue,
    editing: isEditingTable,
    updateIsEditing: updateTableIsEditing,
  } = tableContext;
  const { renderEmptyCellContent } = tableConfig;

  const rowContext = useContext(TableRowContext);
  const {
    rowData,
    rowId,
    onToggleRowSelected,
    onToggleShift,
    isSelected: isRowSelected,
  } = rowContext;

  // cellRenderContext is used to give extra context information and callbacks when using a custom render function.
  const cellRenderContext = useMemo<ITableCellRenderContext>(() => ({
      rowData,
      // is in edit mode.
      isEditing,
      // is cell selected (TODO).
      isCellSelected,
      // is row selected.
      isRowSelected,
      // used to notify cell contents have changed due to editing.
      // TODO: Fix in Node upgrade typing bash!
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      onChangeCellValue: (data: any) => isFunction(onChangeCellValue) && onChangeCellValue(rowId, field, data),
      // used to toggle isEditing state.
      onToggleEditing: () => setIsEditing(!isEditing),
      // used if we want to have a custom checkbox in a cell to select a row.
      onToggleRowSelected: () => isFunction(onToggleRowSelected) && onToggleRowSelected(),
      onToggleShift: (isPressed, isRowSelected) => isFunction(onToggleShift) && onToggleShift(isPressed, isRowSelected),
    }), [
      rowData,
      isEditing,
      isCellSelected,
      isRowSelected,
      onChangeCellValue,
      rowId,
      field,
      onToggleRowSelected,
      onToggleShift,
    ]);

  const cellCustomRenderedContent = useMemo(() => {
    if (isFunction(render)) {
      return render(value, cellRenderContext);
    }
  }, [render, value, cellRenderContext]);

  const formattedValue = useMemo(() => {
    if (isFunction(formatValue)) {
      let valueFormatted = value;
      try {
        valueFormatted = formatValue(value);
      } catch (error) {
        valueFormatted = null;
      }
      return valueFormatted;
    }
  }, [formatValue, value]);

  const cellInnerContent = useMemo(() => {
    if (isFunction(renderEmptyCellContent) && isEmptyValue(value)) {
      return renderEmptyCellContent(cellType, field, value);
    } else if (formattedValue) {
      return formattedValue;
    } else {
      if (cellType === 'date') {
        setValueState(null);
        return null;
      }

      // @TODO Do we need to handle more cases?
      return value;
    }
  }, [value, renderEmptyCellContent, formattedValue, field, cellType]);

  // Update table global state with which table cell is currently being edited.
  useEffect(() => {
    if (isEditing && !isEditingTable) {
      updateTableIsEditing(true);
    } else if (!isEditing && prevEditing.current) {
      updateTableIsEditing(false);
    }
    prevEditing.current = isEditing;
  }, [isEditing, prevEditing, updateTableIsEditing, isEditingTable]);

  // Always render custom cell content if "render" was provided.
  if (cellCustomRenderedContent) {
    // Return null if custom render function returned undefined.
    return cellCustomRenderedContent || null;
  }

  // Show default string edit input if editing.
  if (isEditing) {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const handleBlur = (newValue: any) => {
      setIsEditing(false);
      setValueState(newValue);

      // Don't fire onChange callback if values haven't changed.
      if (newValue === value) {
        return;
      }

      // Consider all "empty" values as the same.
      if (isEmptyValue(newValue) && isEmptyValue(value)) {
        return;
      }

      if (onChangeCellValue) {
        onChangeCellValue(rowId, field, newValue, value, rowData);
      }
    };
    return (
      <EditInput
        cellType={columnConfig.cellType}
        defaultValue={value}
        onBlur={handleBlur}
        onCancel={() => setIsEditing(false)}
        formattedValue={formattedValue}
        nullStr={columnConfig.nullStr}
        trueStr={columnConfig.trueStr}
        falseStr={columnConfig.falseStr}
        maxValue={columnConfig.maxValue}
        minValue={columnConfig.minValue}
      />
    );
  }

  // Show a border around the cell content when hovering if this cell is editable.
  if (columnConfig.editable) {
    return (
      <EditableHover
        onClick={() => setIsEditing(true)}
        cellType={cellType}
      >
        <EllipsisLabel tooltipPlacement="top">
          {cellInnerContent}
        </EllipsisLabel>
      </EditableHover>
    );
  }
  if (isString(cellInnerContent)) {
    return (
      <EllipsisLabel tooltipPlacement="top">
        {cellInnerContent}
      </EllipsisLabel>
    );
  }
  // Return null if no content to show (i.e. undefined or null)
  return cellInnerContent || null;
});

CellContent.displayName = 'CellContent';
