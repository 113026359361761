import { Button as WidgetsButton } from '@components';
import {
  Button,
  Dropdown,
  Menu,
} from 'antd';
import cx from 'classnames';
import * as React from 'react';
import { map, sortBy, filter } from 'lodash';

import { useRouteMatch, useHistory } from 'react-router-dom';

import { useInstalledApplicationInstances, useGetApplicationsByIds } from '@frontend/app/hooks';
import { AppIcon } from '@frontend/applications/Shared/components/AppIcon';
import { ArrowDownIcon, FourSquaresIcon } from '@frontend/app/components';

import styles from './ApplicationsDropDown.scss';

const { useMemo } = React;

interface IProps {
  className?: string;
  isWorkflowEnabled?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const ApplicationDropDown: React.FunctionComponent<IProps> = React.memo(({
  className,
  isWorkflowEnabled,
}) => {
  const {
    data: {
      instances: applicationInstances = [],
    } = {},
  } = useInstalledApplicationInstances({
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      applications = null,
    } = {},
  } = useGetApplicationsByIds(applicationInstances ? map(applicationInstances, 'applicationId') : [], {
    fetchPolicy: 'network-only',
  });

  type IApplication = typeof applications[0];

  const match = useRouteMatch();
  const history = useHistory();

  const onSelectApplication = (application: IApplication) => {
    history.push(`${match.url}/app/${application.id}`);
  };

  const sortedShownApplications = useMemo(() => {
    if (!applications) {
      return null;
    }
    const filteredApplications = filter(applications, (application) => application.visibility.showOnMember);
    return sortBy(filteredApplications, 'id');
  }, [applications]);

  const menu = (
    <Menu>
      {map(sortedShownApplications, (application) => (
        <Menu.Item key={application.id}>
          <div
            className={styles.menuRow}
            onClick={() => onSelectApplication(application)}
          >
            <AppIcon iconUrl={application.icon} />
            <span className={styles.appName}>
              {application.name}
            </span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      overlayStyle={{ minWidth: '280px' }}
      trigger={['click']}
    >
      {isWorkflowEnabled
        ? (
          <Button
            className={cx(className, styles.dropDownButton)}
            icon={(
              <FourSquaresIcon
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                className={(styles as any).fourSquares}
                size={14}
              />
            )}
          >
            Other Apps
            <ArrowDownIcon
              className={styles.downArrow}
              size={14}
            />
          </Button>
        )
        : (
          <WidgetsButton
            className={className}
            icon={(
              <FourSquaresIcon
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                className={(styles as any).fourSquares}
                size={14}
              />
            )}
            label={(
              <>
                Other Apps
                <ArrowDownIcon
                  className={styles.downArrow}
                  size={14}
                />
              </>
)}
            theme="light"
          />
        )}
    </Dropdown>
  );
});
