import gql from 'graphql-tag';

export const CANCEL_BRIEFS_MUTATION = gql`
  mutation CancelBriefs($axProjectIds: [Float!]!) {
    results: cancelBriefs(axProjectIds: $axProjectIds) {
        successes
        failures
        errors
    }
  }
`;
