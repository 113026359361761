import * as React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';
import {
  IconButton,
} from '@components';

import { PersonAddIcon } from '@frontend/app/components';

import {
  useMemberPageContext,
} from '@frontend/app/containers/Members/hooks';
import { UserPlus } from 'lucide-react';
import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import styles from './AddMemberButton.scss';

interface IProps {
  disabled?: boolean;
  className?: string;
  refreshUi?: boolean;
}

export const AddMemberButton: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    sourcingGroupId,
    toggleOpenAddMember,
  } = useMemberPageContext();

  const disabled = props.disabled;

  if (sourcingGroupId) {
    // Don't show for sourcing groups.
    return null;
  }

  return (
    <Tooltip title="Add Member" placement="bottomRight">
      {props.refreshUi ? (
        <ShadCnBtn
          onClick={toggleOpenAddMember}
          variant="outline"
          size="headerIcon"
          disabled={disabled}
        >
          <UserPlus className="h-4 w-4" />
        </ShadCnBtn>
      ) : (
        <IconButton
          icon={<PersonAddIcon size={18} className={styles.personIcon} />}
          disabled={disabled}
          className={cx(props.className, styles.AddMemberButton)}
          onClick={toggleOpenAddMember}
          round={false}
          theme="info"
        />
      )}
    </Tooltip>
  );
});

AddMemberButton.defaultProps = {
  disabled: false,
};

AddMemberButton.displayName = 'AddMemberButton';
