export interface IDeliverableDueDateType {
  submission?: IDueDate;
  completion: IDueDate;
}

export interface IDueDate {
  description: string;
  start_date: string;
  type: TDueDateType;
  due_ts: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  end_date?: any;
}

export type TDueDateType = 'On' | 'Between' | 'Before';
