import { useMutation, MutationHookOptions } from '@apollo/client';

import { DELETE_PROGRAM_APPLICATION_PAGE } from '@frontend/app/queries/DeleteProgramApplicationPageMutation';
import {
  DeleteProgramApplicationPageMutation,
  DeleteProgramApplicationPageMutationVariables,
} from '@frontend/app/queries/types/DeleteProgramApplicationPageMutation';

type IOptions = MutationHookOptions<DeleteProgramApplicationPageMutation, DeleteProgramApplicationPageMutationVariables>;

export const useDeleteProgramApplicationPage = (options?: IOptions) => useMutation<DeleteProgramApplicationPageMutation, DeleteProgramApplicationPageMutationVariables>(DELETE_PROGRAM_APPLICATION_PAGE, options);
