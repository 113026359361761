import { Button, Input } from '@revfluence/fresh';
import React from 'react';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './CreatorFilter.scss';
import { Statuses } from './GroupTypes';

const CreatorFilter = ({
    getStatusUserCount,
    handleSearch,
    setSelectedStatus,
    members,
}) => {
    const statusOptions = [
        { value: 'All', label: 'All Statuses' },
        { value: Statuses.PENDING, label: 'Pending Review' },
        { value: Statuses.MAYBE, label: 'Maybe' },
        { value: Statuses.APPROVED, label: 'Approved' },
        { value: Statuses.REJECTED, label: 'Rejected' },
    ];

    return (
      <div className={styles.tHead}>
        <div className={styles.allstatus}>
          {statusOptions.map((option) => (
            <Button key={option.value} onClick={() => setSelectedStatus(option.value)}>
              {option.label}
              <small>
                {
                                option.value === 'All' ? members.length : getStatusUserCount(option.value)
                            }
              </small>
            </Button>
                ))}
        </div>
        <div className={styles.search}>
          <Input
            placeholder="Search by Name"
            prefix={<MagnifyingGlassIcon />}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
    );
};

export default CreatorFilter;
