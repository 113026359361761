import gql from 'graphql-tag';

const GET_BUDGET_DASHBOARD_SPEND_SUMMARY = gql`
  query GetBudgetDashboardSpendSummaryQuery($budgetId: Int, $fiscalYear: String!, $programId: Int) {
    summary: getBudgetDashboardSpendSummaryData(budgetId: $budgetId, fiscalYear: $fiscalYear, programId: $programId) {
      averageSpendData
      memberSegmentSpendList
      memberSpendList
      monthlySpendData
      spendActivityChartData
      totalSpendData
    }
  }
`;

export default GET_BUDGET_DASHBOARD_SPEND_SUMMARY;
