import React from 'react';
import styles from './tabs.scss';

const Tabs = ({ selectedTab, setSelectedTab, tabs }) => (
  <div className={styles.tabContainer}>
    {tabs.map((tab, index) => (
      <div
        key={index}
        onClick={() => setSelectedTab(tab)}
        className={selectedTab === tab ? styles.selectedTab : styles.tab}
      >
        {tab}
      </div>
      ))}
  </div>
  );

export default Tabs;
