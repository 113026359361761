import * as React from 'react';
import { CheckboxOptionType, CheckboxProps, Checkbox } from 'antd';
import {
  Button, Modal, Space, Input, Typography, Tooltip,
} from '@revfluence/fresh';

import styles from '@frontend/app/components/InstagramMeta/MetaItems/MetaItems.module.scss';

const { useState } = React;
const { Text } = Typography;

interface IAccount {
  networkId: string;
  socialAccountId: string;
  name: string;
  selected: boolean;
}

interface ISelectedAccounts {
  [key: string]: boolean;
}

interface IAdAccountInput {
  ad_account_network_id: string;
  social_account_id: string;
}

interface IAdAccountButtonProps {
  adAccounts: IAccount[];
  selectAccount: (accounts: IAdAccountInput[]) => void;
}

export const AdAccountButton: React.FC<IAdAccountButtonProps> = (props) => {
  const { adAccounts, selectAccount } = props;

  const [isModal1Open, setIsModal1Open] = useState(false);
  const [input, setInput] = useState<string | null>(null);
  const [selectedAccounts, setSelectedAccounts] = useState<ISelectedAccounts>({});

  const filteredAccounts = React.useMemo(() => {
    if (!input) return adAccounts;
    return adAccounts.filter(({ name }) =>
      name.toLowerCase().includes(input.toLowerCase()));
  }, [input, adAccounts]);
  const allChecked = React.useMemo(() =>
    adAccounts.every((account) => !!selectedAccounts[account.networkId]), [adAccounts, selectedAccounts]);
  const checkBoxOptions: CheckboxOptionType[] = React.useMemo(() => filteredAccounts.map((account) => ({
    label: (
      <>
        <Text>{account.name}</Text>
        <Text type="secondary">
          {' (ID '}
          {account.networkId}
          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
          {')'}
        </Text>
      </>
    ),
    value: account.networkId,
  })), [filteredAccounts]);
  const selectedIds = adAccounts.filter(({ networkId }) => selectedAccounts[networkId]).map(({ networkId, socialAccountId }) => ({
    ad_account_network_id: networkId,
    social_account_id: socialAccountId,
  }));
  const checkedList: string[] = React.useMemo(() => adAccounts
    .map((account) => {
      if (selectedAccounts[account.networkId]) {
        return account.networkId;
      }
      return null;
    })
    .filter((accountId: string | null) => !!accountId),
  [adAccounts, selectedAccounts]);
  const handleCheckAll: CheckboxProps['onChange'] = (e) => {
    const selectedAccounts: ISelectedAccounts = adAccounts.reduce((prev, current) => ({
      ...prev,
      [current.networkId]: e.target.checked,
    }), {});
    setSelectedAccounts(selectedAccounts);
  };
  const handleCheckGroup = (e: number[]) => {
    const selectedAccounts: ISelectedAccounts = e.reduce((prev, current) => ({
      ...prev,
      [current]: true,
    }), {});
    setSelectedAccounts(selectedAccounts);
  };

  const showModal = () => {
    setIsModal1Open(true);
  };
  const handleOk = () => {
    selectAccount(selectedIds);
    setIsModal1Open(false);
  };
  const handleCancel = () => {
    setIsModal1Open(false);
  };

  const renderButton = adAccounts.length === 0;
  const visible = renderButton ? undefined : false;

  return (
    <>
      <Tooltip visible={visible} title="There are no ad accounts to select associated with your connected Meta account(s)">
        <Button onClick={showModal} disabled={renderButton}>Select Ad Accounts</Button>
      </Tooltip>
      <Modal
        title="Select Ad Accounts"
        open={isModal1Open}
        onOk={handleOk}
        onCancel={handleCancel}
        className={styles.TableModal}
        okText="Done"
      >
        <Space size="large" direction="vertical">
          Choose the account(s) that will be the source of data for ad insights. Select all relevant brand accounts to be able to calculate the total impact of your campaigns.
          <Input.Search
            placeholder="Search"
            onChange={(e) => setInput(e.currentTarget.value)}
            value={input}
          />
          <Space direction="vertical" size="small">
            <Checkbox
              onChange={handleCheckAll}
              checked={allChecked}
            >
              Select All
            </Checkbox>
            <Checkbox.Group
              style={{ display: 'grid', gap: '12px' }}
              options={checkBoxOptions}
              value={checkedList}
              onChange={handleCheckGroup}
            />
          </Space>
        </Space>
      </Modal>
    </>
  );
};
