export const onFinish = (id, updatedAmountToPay, amountToPay, dataSource, setDataSource, onUpdatedData) => {
  const updatedDataSource = dataSource.map((record) => {
    if (record.id === id) {
      const lengthBegoreDecimal = updatedAmountToPay.toString().split('.')[0].length;
      if (lengthBegoreDecimal > 4) {
        return { ...record, amountToPay: Math.abs(amountToPay) };
      }
      return { ...record, amountToPay: Math.abs(updatedAmountToPay) };
    }
    return record;
  });
  setDataSource(updatedDataSource);
  onUpdatedData(updatedDataSource);
};
