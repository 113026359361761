import moment from 'moment';

const nFormatter = (number: number | string) => {
  interface Alias {
    value: number;
    alias: string;
  }
  const aliases: Alias[] = [
    { value: 1, alias: '' },
    { value: 1e3, alias: 'K' },
    { value: 1e6, alias: 'MM' },
    { value: 1e9, alias: 'B' },
    { value: 1e12, alias: 'T' },
  ];
  // Trim trailing zeros e.g. 1.0 becomes 1 and 1.10 becomes 1.1.
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const num = Number(number);
  if (Number.isNaN(num)) {
    return '';
  }
  const digits = 1;
  const item = aliases
    .slice()
    .reverse()
    .find((alias) => Math.abs(num) >= alias.value);
  return item
    ? (Math.sign(num) * (Math.abs(num) / item.value))
        .toFixed(digits)
        .replace(rx, '$1') + item.alias
    : '0';
};

const moneyFormatter = (value: string | number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const num = Number(value);
  if (Number.isNaN(num)) {
    return '';
  }
  return formatter.format(num);
};

const dateFormatter = (value: string | number, format: string) => {
  const date = moment(value);
  if (!date.isValid()) {
    return '';
  }
  return date.format(format);
};

const dayFormatter = (value: string | number) => dateFormatter(value, 'MMM D');

const weekdayFormatter = (value: string | number) => {
  const date = moment(value);
  if (date.isSame(Date.now(), 'day')) {
    return 'Today';
  }
  return dateFormatter(value, 'ddd');
};

export type CustomTickFormat =
  | 'string'
  | 'number'
  | 'money'
  | 'date'
  | 'weekday'
  | 'usNumber';

type ITickFormatters = Record<CustomTickFormat, (v: number | string) => string>;

export const tickFormatters: ITickFormatters = {
  number: nFormatter,
  string: (v: number | string) => v.toString(),
  date: dayFormatter,
  weekday: weekdayFormatter,
  money: moneyFormatter,
  usNumber: (v: number | string) => Number(v).toLocaleString('en-US', { maximumFractionDigits: 2 }),
};
