import { useMutation, MutationHookOptions } from '@apollo/client';

import { CREATE_PROJECT_CONFIG_MUTATION } from '../queries';
import {
  CreateProjectConfigMutation,
  CreateProjectConfigMutationVariables,
} from '../queries/types/CreateProjectConfigMutation';

type IOptions = MutationHookOptions<CreateProjectConfigMutation, CreateProjectConfigMutationVariables>;

export function useCreateProjectConfig(options: IOptions = {}) {
  const [createProjectConfig, { loading, error }] = useMutation<
  CreateProjectConfigMutation,
  CreateProjectConfigMutationVariables
  >(CREATE_PROJECT_CONFIG_MUTATION, options);

  return {
    createProjectConfig,
    loading,
    error,
  };
}
