/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_40b00_157 {
  display: flex;
}

._justify-content-space-between_40b00_161 {
  justify-content: space-between;
}

._tabular-nums_40b00_165 {
  font-variant-numeric: tabular-nums;
}

._Progress_40b00_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_40b00_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Progress_40b00_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Progress_40b00_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Progress_40b00_169 ::-webkit-scrollbar-track, ._Progress_40b00_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Progress_40b00_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Progress_40b00_169 ._background_40b00_235 {
  position: relative;
  height: 18px;
  overflow: hidden;
  border-radius: 100px;
  background-color: #dedede;
}
._Progress_40b00_169 ._background_40b00_235 ._progress_40b00_242 {
  position: relative;
  height: 100%;
  border-radius: 100px;
  will-change: width;
  transition: width 0.2s ease;
}
._Progress_40b00_169 ._background_40b00_235 ._progress_40b00_242._info_40b00_249 {
  background-color: #3996e0;
}
._Progress_40b00_169 ._background_40b00_235 ._progress_40b00_242._success_40b00_252 {
  background-color: #eacd60;
}
._Progress_40b00_169 ._background_40b00_235 ._progress_40b00_242._error_40b00_255 {
  background-color: #f1515f;
}
._Progress_40b00_169 ._background_40b00_235 ._progress_40b00_242 ._text_40b00_258 {
  position: absolute;
  right: 5px;
  top: -1px;
  color: #fdfdfd;
  font-size: 12px;
  font-weight: 600;
}
._Progress_40b00_169 ._background_40b00_235 ._progress_40b00_242 ._text_40b00_258._right_40b00_266 {
  right: -24px;
}
._Progress_40b00_169 ._label_40b00_269 {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
}