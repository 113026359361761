import 'css-chunk:src/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesLoading/ContentGuidelinesLoading.module.scss';export default {
  "spacing-xxxl": "80px",
  "spacing-xxl": "48px",
  "spacing-xl": "32px",
  "spacing-lg": "24px",
  "spacing-md": "16px",
  "spacing-sm": "12px",
  "spacing-xs": "8px",
  "spacing-xxs": "4px",
  "loadingContentContainer": "_loadingContentContainer_1l110_23",
  "loadingContentRow": "_loadingContentRow_1l110_30",
  "loadingContentWrapper": "_loadingContentWrapper_1l110_34",
  "contentGuidelinesLoadingHeader": "_contentGuidelinesLoadingHeader_1l110_41",
  "loadingLineXXS": "_loadingLineXXS_1l110_47",
  "loadingButton": "_loadingButton_1l110_54"
};