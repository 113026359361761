import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  PROSPECTS_REMOVE_FROM_COMMUNITY_MUTATION,
} from '@frontend/app/queries';
import {
  ProspectsRemoveFromCommunityMutation,
  ProspectsRemoveFromCommunityMutationVariables,
} from '@frontend/app/queries/types/ProspectsRemoveFromCommunityMutation';

type IOptions = MutationHookOptions<ProspectsRemoveFromCommunityMutation, ProspectsRemoveFromCommunityMutationVariables>;

export const useProspectsRemoveFromCommunity = (options: IOptions = {}) => useMutation<ProspectsRemoveFromCommunityMutation, ProspectsRemoveFromCommunityMutationVariables>(PROSPECTS_REMOVE_FROM_COMMUNITY_MUTATION, options);
