import * as React from 'react';
import { debounce, filter, find } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  List,
  Row,
  Space,
  Tag,
  Typography,
} from '@revfluence/fresh';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';

import { InfiniteList, LoadSpinner } from '@components';

import GetMoreVariants from '@frontend/applications/ProductFulfillmentApp/components/GetMoreVariants';
import { GetProductDetailsQuery_productDetail } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductDetailsQuery';
import { useEffect } from 'react';
import { GetProductsV3Query_productCollection_products } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductsV3Query';
import styles from './SearchResults.module.scss';
import { IProduct } from '../../../../../hooks';
import { ISelectedVariant } from '../../utils';

const { Text } = Typography;

const { useCallback, useState, useRef } = React;

interface IProps {
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>;
  products: IProduct[];
  fetchMoreProducts: () => void;
  searchValue: string;
  setSearchValue(value: string): void;
  isLoadingProducts: boolean;
  selectedVariants: ISelectedVariant[];
  setSelectedVariants(selectedVariants: ISelectedVariant[]): void;
  addSelectedVariantsToCurrentRequest(): void;
  resourceId: number;
}

export const SearchResults = ({
  setShowResults,
  products,
  fetchMoreProducts,
  searchValue,
  setSearchValue,
  isLoadingProducts,
  selectedVariants,
  setSelectedVariants,
  addSelectedVariantsToCurrentRequest,
  resourceId,
}: React.PropsWithChildren<IProps>) => {
  const [innerSearchValue, setInnerSearchValue] = useState<string>(searchValue);
  const [productsData, setProductsData] = useState<GetProductsV3Query_productCollection_products[]>(null);
  useEffect(() => {
    if (products) {
      setProductsData(products);
    }
  }, [products]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchRequest = useCallback(
    debounce(async (text: string) => {
      setSearchValue(text);
    }, 600),
    [setSearchValue],
  );

  const handleChangeSearchText = (text: string) => {
    handleSearchRequest(text);
    setInnerSearchValue(text);
  };

  const onGetMoreVariants = (productData: GetProductDetailsQuery_productDetail) => {
    setProductsData(products?.map((product: GetProductDetailsQuery_productDetail) => {
      if (product.id === productData.id) {
        return productData;
      }
      return product;
    }));
  };

  const listRef = useRef<HTMLDivElement>(null);

  return (
    <Card className={styles.searchResultsCard}>
      <Input
        value={innerSearchValue}
        onChange={(e) => handleChangeSearchText(e.target.value)}
        size="large"
        placeholder="Search for a product to add"
        prefix={<MagnifyingGlassIcon className={styles.searchIcon} />}
      />
      {isLoadingProducts
        ? <LoadSpinner />
        : (
          <>
            {productsData.length === 0 && (
              <Row justify="center" className={styles.mainSection}>
                <Text className={styles.emptyText}>Search for a specific product to add</Text>
              </Row>
            )}
            {productsData.length !== 0 && (
              <Row align="top" className={styles.mainSection}>
                <InfiniteList
                  ref={listRef}
                  onReachedBottom={fetchMoreProducts}
                  style={{
                    flex: 1,
                    overflowY: 'auto',
                    height: '100%',
                  }}
                >
                  {productsData.map((product) => (
                    <Col span={24} key={product.id}>
                      <Space direction="horizontal">
                        <Avatar shape="square" size={48} src={product.image?.src} />
                        <Text>{product.title}</Text>
                      </Space>
                      <Col span={24} className={styles.listContainer}>
                        <List split={false}>
                          {product.variants.map((item) => {
                          const onCheckboxChange = (e: CheckboxChangeEvent) => {
                            if (e.target.checked) {
                              setSelectedVariants([...selectedVariants, {
                                productId: product.id,
                                variantId: item.id,
                              }]);
                            } else {
                              setSelectedVariants(filter(selectedVariants, (variant) => variant.variantId !== item.id));
                            }
                          };

                          return (
                            <List.Item key={item.id}>
                              <Row style={{ width: '100%' }}>
                                <Col span={8} className={styles.firstColumn}>
                                  <Checkbox
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore TODO: Fix in Node upgrade typing bash!
                                    checked={find(selectedVariants, { productId: product.id, variantId: item.id })}
                                    onChange={onCheckboxChange}
                                  />
                                  <Text>{item.title}</Text>
                                </Col>
                                <Col span={3}>
                                  <Text>
                                    $
                                    {item.price}
                                  </Text>
                                </Col>
                                <Col span={3}>
                                  <Text>
                                    {item.sellableOnlineQuantity}
                                    {' '}
                                    In Stock
                                  </Text>
                                </Col>
                              </Row>
                            </List.Item>
                          );
                        })}
                        </List>
                        {product.variants.length >= 90
                        && (
                        <GetMoreVariants
                          onGetMoreVariants={onGetMoreVariants}
                          resourceId={resourceId}
                          productId={product.id}
                        />
)}
                      </Col>
                    </Col>
                ))}
                </InfiniteList>
              </Row>
            )}
          </>
        )}
      <Divider className={styles.divider} />
      <Row justify="space-between" className={styles.stickyFooter}>
        <Space direction="horizontal">
          <Tag>
            {selectedVariants.length}
            {' '}
            product
            {selectedVariants.length === 1 ? '' : 's'}
            {' '}
            Selected
          </Tag>
        </Space>
        <Space direction="horizontal">
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            size="medium"
            onClick={() => setShowResults(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            size="medium"
            disabled={selectedVariants.length === 0}
            onClick={() => addSelectedVariantsToCurrentRequest()}
          >
            Add Product
          </Button>
        </Space>
      </Row>
    </Card>
  );
};
