import pLimit from 'p-limit';
import { map } from 'lodash';

const DEFAULT_CONCURRENCY = parseInt(process.env.PROMISE_CONCURRENCY_LIMIT, 10) || 100;

export const isRejected = (input: PromiseSettledResult<unknown>): input is PromiseRejectedResult => 
  input.status === 'rejected'

export const isFulfilled = <T>(input: PromiseSettledResult<T>): input is PromiseFulfilledResult<T> => 
  input.status === 'fulfilled'

Promise.limitedAllSettled = <Item, PromiseResult>(
  items: readonly Item[],
  promiseGenerator: (item: Item) => Promise<PromiseResult>,
  concurrency: number = DEFAULT_CONCURRENCY,
): Promise<PromiseSettledResult<PromiseResult>[]> => {
  const limit = pLimit(concurrency);
  const promiseList = map(items, (item) => limit(promiseGenerator, item));
  return Promise.allSettled(promiseList);
};

Promise.limitedAll = <Item, PromiseResult>(
  items: readonly Item[],
  promiseGenerator: (item: Item) => Promise<PromiseResult>,
  concurrency: number = DEFAULT_CONCURRENCY,
): Promise<PromiseResult[]> => {
  const limit = pLimit(concurrency);
  const promiseList = map(items, (item) => limit(promiseGenerator, item));
  return Promise.all(promiseList);
};

declare global {
  interface Promise<T> {
      limitedAllSettled<Item, PromiseResult>(
        items: readonly Item[],
        promiseGenerator: (item: Item) => Promise<PromiseResult>,
        concurrency?: number,
      ): Promise<PromiseSettledResult<PromiseResult>[]>;
      limitedAll<Item, PromiseResult>(
        items: readonly Item[],
        promiseGenerator: (item: Item) => Promise<PromiseResult>,
        concurrency?: number,
      ): Promise<PromiseResult[]>;
  }
  interface PromiseConstructor {
    limitedAllSettled<Item, PromiseResult>(
      items: readonly Item[],
      promiseGenerator: (item: Item) => Promise<PromiseResult>,
      concurrency?: number,
    ): Promise<PromiseSettledResult<PromiseResult>[]>;
    limitedAll<Item, PromiseResult>(
      items: readonly Item[],
      promiseGenerator: (item: Item) => Promise<PromiseResult>,
      concurrency?: number,
    ): Promise<PromiseResult[]>;
  }
}

export { }
