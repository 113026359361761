import * as React from 'react';
import cx from 'classnames';

import { Tag } from '@revfluence/fresh';

import { CONTENT_REVIEW_STATE } from './constants';
import { getContentReviewStateColor, getContentReviewStateIcon, getContentReviewStateText } from './utils';

import styles from './ContentReviewStatusTag.scss';

interface IProps {
  state: CONTENT_REVIEW_STATE;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ContentReviewStatusTag: React.FC<IProps> = React.memo(({ state, className }) => {
  const color = useMemo(() => getContentReviewStateColor(state), [state]);
  const text = useMemo(() => getContentReviewStateText(state), [state]);
  const icon = useMemo(() => getContentReviewStateIcon(state), [state]);

  return (
    <Tag
      className={cx(styles.ContentReviewStatusTag, className)}
      icon={icon}
      color={color}
    >
      {text}
    </Tag>
  );
});

ContentReviewStatusTag.defaultProps = {
  className: null,
};

ContentReviewStatusTag.displayName = 'ContentReviewStatusTag';
