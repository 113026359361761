import { ActionType, TState } from '../types';

interface IAction {
    fieldName: string,
    value: number | string
    type: ActionType.SET_FIELD_VALUE;
}
export type TSetFieldValueAction = Readonly<IAction>;

export const setFieldValue = (state: TState, action: TSetFieldValueAction): TState => ({
        ...state,
        [action.fieldName]: action.value,
    });
