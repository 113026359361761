/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vi7i2_157 {
  display: flex;
}

._justify-content-space-between_vi7i2_161 {
  justify-content: space-between;
}

._tabular-nums_vi7i2_165 {
  font-variant-numeric: tabular-nums;
}

._TokenInput_vi7i2_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_vi7i2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._TokenInput_vi7i2_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._TokenInput_vi7i2_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._TokenInput_vi7i2_169 ::-webkit-scrollbar-track, ._TokenInput_vi7i2_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._TokenInput_vi7i2_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TokenInput_vi7i2_169 {
  padding: 8px;
  width: 100%;
  min-height: 40px;
  border-radius: 10px;
  background-color: #eff5f9;
}
._TokenInput_vi7i2_169:hover, ._TokenInput_vi7i2_169:focus {
  background-color: #e6edf2;
}
._TokenInput_vi7i2_169 ._inputWrapper_vi7i2_245 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 5px 2px 4px;
  padding-right: 30px;
}
._TokenInput_vi7i2_169 ._inputWrapper_vi7i2_245 ._icon_vi7i2_253 {
  flex-shrink: 0;
  display: flex;
  margin-right: 6px;
}
._TokenInput_vi7i2_169 ._input_vi7i2_245 {
  flex: 1;
  min-width: 100px;
  outline: none;
  border: none;
  background: none;
  font-size: 15px;
  font-weight: 400;
  height: 26px;
  line-height: 26px;
}
._TokenInput_vi7i2_169 ._spinner_vi7i2_269 {
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: -10px;
}
._TokenInput_vi7i2_169 ._popoverAnchor_vi7i2_275 {
  display: block;
  position: relative;
  width: 300px;
  left: -6px;
}
._TokenInput_vi7i2_169 ._tokens_vi7i2_281 {
  margin: 0;
  padding: 0;
  list-style: none;
}
._TokenInput_vi7i2_169 ._tokens_vi7i2_281 ._token_vi7i2_281 {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 3px;
  border-radius: 12px;
  padding: 0 7px 0 12px;
  background-color: #3996e0;
  color: #fdfdfd;
}
._TokenInput_vi7i2_169 ._tokens_vi7i2_281 ._token_vi7i2_281 ._close_vi7i2_295 {
  padding: 0 5px;
  vertical-align: -4px;
  line-height: 26px;
  font-size: 26px;
  cursor: pointer;
}
._TokenInput_vi7i2_169 ._tokens_vi7i2_281 ._token_vi7i2_281._single_vi7i2_302 {
  display: flex;
  margin: 0;
  background-color: transparent;
  color: #2e426d;
  width: 100%;
  align-items: center;
}
._TokenInput_vi7i2_169 ._tokens_vi7i2_281 ._token_vi7i2_281._single_vi7i2_302 span:first-child {
  flex: 1;
}
._TokenInput_vi7i2_169 ._tokens_vi7i2_281 ._token_vi7i2_281._single_vi7i2_302 ._close_vi7i2_295 {
  line-height: 22px;
}

._Popover_vi7i2_317 {
  min-width: 300px;
}
._Popover_vi7i2_317 ._options_vi7i2_320 ._option_vi7i2_320 {
  padding: 5px 10px;
  cursor: pointer;
}
._Popover_vi7i2_317 ._options_vi7i2_320 ._option_vi7i2_320:hover {
  background-color: #4fa7ee;
  color: #fdfdfd;
}
._Popover_vi7i2_317 ._options_vi7i2_320 ._emptyOptions_vi7i2_328 {
  padding: 5px 10px;
  font-style: italic;
}