import { ISocialAccount } from './socialAccount';

export interface IMember {
  id: number;
  clientId: string;
  email: string;
  name: string;
  profilePicture?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notes?: any;
  isImportant?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any;
  createdDate?: string;
  updatedDate?: string;
  socialAccount?: ISocialAccount;
}

export interface IProspect extends Omit<Partial<IMember>, 'socialAccount'> {
  socialAccount: Partial<ISocialAccount>;
}
