import * as React from 'react';
import { isEmpty, size } from 'lodash';
import {
  Typography,
  Space,
} from '@revfluence/fresh';

import { ContentType, TermsType } from '@frontend/app/types/globalTypes';
import { getContentTypeLabel } from '@frontend/applications/TermsApp/components/BulkTerms/utils';
import { IState } from '@frontend/app/containers/Projects/TermsPage/components/types/state';
import { ALLOWEDCONTENTTYPES } from '@frontend/app/containers/Projects/TermsPage/components/ContentTypeList';

import { useGetTermsName } from '@frontend/app/hooks';
import styles from './PanelHeader.scss';

export enum PanelKey {
  Intro = 'intro',
  ContentRequirements = 'content_requirements',
  ContentGuidelines = 'content_guidelines',
  Compensation = 'compensation',
  ContentUsageRights = 'content_usage_rights',
  AdditionalTerms = 'additional_terms',
  EmailTemplate = 'email_template',
  WriteMessage = 'write_message',
}

interface IProps {
  panelKey: PanelKey;
  state: IState;
  termsType?: TermsType
}

interface IPanelHeader {
  title: string;
  subtitle: string;
}

const { useMemo } = React;

const PanelHeader: React.FC<IProps> = (props) => {
  const { panelKey, state, termsType } = props;

  const getContentTypesSelected = useMemo(() => {
    if (isEmpty(state.contentRequirements.contentTypes)) {
      return 'None social network';
    }
    if (size(state.contentRequirements.contentTypes) === ALLOWEDCONTENTTYPES.length) {
      return 'Any social network';
    }
    const contentTypesSelected = state.contentRequirements.contentTypes.map((contentType) => getContentTypeLabel(1, ContentType[contentType]));
    return contentTypesSelected.join(', ');
  }, [state.contentRequirements.contentTypes]);

  const getPanelHeaderSubtitle = React.useCallback((panelKey: PanelKey) => {
    switch (panelKey) {
      case PanelKey.ContentRequirements: {
        if (!state.contentRequirements.areDueDatesSelected) {
          return `${state.contentRequirements.deliverablesWithNoDueDates} deliverables
          | ${getContentTypesSelected} | No due dates`;
        }
        if (isEmpty(state.contentRequirements.deliverablesDueDates)) {
          return `None deliverable | ${getContentTypesSelected}`;
        }
        const dueDatesSelected = state
          .contentRequirements
          .deliverablesDueDates
          .join(', ');
        return `
          ${state.contentRequirements.deliverablesDueDates.length} deliverables
          | ${getContentTypesSelected} | due ${dueDatesSelected} days after brief is sent
        `;
      }
      case PanelKey.Compensation: {
        const subtitle = [];
        Object.entries(state.compensation).forEach(([key, value]) => {
          if (key === 'payment' && value.toggleNewPrice) {
            subtitle.push('Payment');
          }
          if (key === 'freeProduct' && value.toggleDescription) {
            subtitle.push('Free Product');
          }
          if (key === 'commission' && value.toggleDescription) {
            subtitle.push('Commission');
          }
          if (key === 'noCompensation' && value.toggleNoCompensation) {
            subtitle.push('No Compensation');
          }
        });
        return isEmpty(subtitle) ? 'None selected' : subtitle.join(', ');
      }
      case PanelKey.EmailTemplate:
      default:
        return '';
    }
  }, [
    getContentTypesSelected,
    state.compensation,
    state.contentRequirements.deliverablesDueDates,
    state.contentRequirements.areDueDatesSelected,
    state.contentRequirements.deliverablesWithNoDueDates,
  ]);
  const { pluralTermsName } = useGetTermsName();
  const panelHeaderSettings: IPanelHeader = React.useMemo(() => {
    switch (panelKey) {
      case PanelKey.Intro:
        return state.defaultActiveKey.includes(panelKey)
          ? {
            title: 'Intro',
            subtitle: 'Write a brief intro describing the collaboration for the creator. This will show at the top of briefs in their creator portal.',
          } : {
            title: 'Intro',
            subtitle: state.introMessage === '' ? 'None' : state.introMessage,
          };
      case PanelKey.ContentRequirements:
        return state.defaultActiveKey.includes(panelKey)
          ? {
            title: 'Content Requirements',
            subtitle: 'Select the minimum number of deliverables you want the creator to provide, and which types are accepted.',
          } : {
            title: 'Content Requirements',
            subtitle: getPanelHeaderSubtitle(panelKey),
          };
      case PanelKey.ContentGuidelines:
        return state.defaultActiveKey.includes(panelKey)
          ? {
            title: 'Content Guidelines',
            subtitle: '',
          } : {
            title: 'Content Guidelines',
            subtitle: '',
          };
      case PanelKey.Compensation:
        return state.defaultActiveKey.includes(panelKey)
          ? {
            title: 'Compensation',
            subtitle: termsType === TermsType.BASIC
              ? `Choose how you would like to compensate creators. If you would like to configure payments per creator, switch to Standard ${pluralTermsName}.`
              : 'Choose how you would like to compensate creators.',
          } : {
            title: 'Compensation',
            subtitle: getPanelHeaderSubtitle(panelKey),
          };
      case PanelKey.ContentUsageRights:
        return state.defaultActiveKey.includes(panelKey)
          ? {
            title: 'Content Usage Rights',
            subtitle: '',
          } : {
            title: 'Content Usage Rights',
            subtitle: getPanelHeaderSubtitle(panelKey),
          };
      case PanelKey.AdditionalTerms:
        return {
          title: 'Additional Terms',
          subtitle: '',
        };
      case PanelKey.EmailTemplate:
        return state.defaultActiveKey.includes(panelKey)
          ? {
            title: 'Email Template',
            subtitle: '',
          } : {
            title: 'Email Template',
            subtitle: getPanelHeaderSubtitle(panelKey),
          };
      case PanelKey.WriteMessage:
        return {
          title: 'Write Message',
          subtitle: '',
        };
      default:
        return {
          title: '',
          subtitle: '',
        };
    }
  }, [
    panelKey,
    state,
    termsType,
    getPanelHeaderSubtitle,
    pluralTermsName,
  ]);

  return (
    <Space
      direction="vertical"
      className={styles.PanelHeader}
    >
      <Typography.Title
        level={4}
        className={styles.title}
      >
        {panelHeaderSettings.title}
      </Typography.Title>
      {!isEmpty(panelHeaderSettings.subtitle) && (
        <Typography.Text className={styles.subtitle}>
          <div className={styles.content}>
            {panelHeaderSettings.subtitle}
          </div>
        </Typography.Text>
      )}
    </Space>
  );
};

PanelHeader.displayName = 'PanelHeader';

export default PanelHeader;
