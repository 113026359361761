import React from 'react';
import { Row, Col, Typography } from '@revfluence/fresh';
import { ChartBarIcon } from '@revfluence/fresh-icons/regular/esm';

import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import styles from './Header.scss';

const { Title } = Typography;

export const Header = ({ title, refreshUi }: { title?: string, refreshUi: boolean }) => (

  <>
    {refreshUi ? (
      <AppHeader title={`Embedded Reporting - ${title}`} />
    ) : (
      <Row className={styles.Header} gutter={0}>
        <Col>
          <span className={styles.icon}>
            <ChartBarIcon />
          </span>
        </Col>
        <Col flex={1}>
          <Title level={4} style={{ margin: 0 }}>
            Embedded Reporting -
            {title}
          </Title>
        </Col>
      </Row>
    )}
  </>
);
