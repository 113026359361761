import { useMutation, MutationHookOptions } from '@apollo/client';

import { DEACTIVATE_ALLOY_WORKFLOW_MUTATION } from '@frontend/app/queries';

import {
  DeactivateAlloyWorkflowMutation,
  DeactivateAlloyWorkflowMutationVariables,
} from '@frontend/app/queries/types/DeactivateAlloyWorkflowMutation';

type IOptions = MutationHookOptions<DeactivateAlloyWorkflowMutation, DeactivateAlloyWorkflowMutationVariables>;

export function useDeactivateAlloyWorkflow(options: IOptions = {}) {
  const [deactivateAlloyWorkflow, { loading, error }] = useMutation<
    DeactivateAlloyWorkflowMutation,
    DeactivateAlloyWorkflowMutationVariables
  >(DEACTIVATE_ALLOY_WORKFLOW_MUTATION, {
    ...options,
  });

  return {
    deactivateAlloyWorkflow,
    loading,
    error,
  };
}
