import * as React from 'react';
import { QuillOptionsStatic } from 'quill';
import { ClassValue } from 'classnames/types';

import 'react-quill/dist/quill.snow.css';
import classNames from 'classnames';
import { useState } from 'react';
import styles from './styles.scss';

const { useEffect, useMemo } = React;

export interface IRichTextEditor {
  onChange?: (JSONStringContents: string) => void;
  value?: string;
  placeholder?: string;
  toolbarOptions?: QuillOptionsStatic['formats'];
  toolbarModules?: QuillOptionsStatic['modules'];
  boundSelector?: string;
  className?: ClassValue
}

/**
 * This component used Quill JS under the hood,
 * nevertheless is important to abstract it in case
 * we need to switch libraries.
 *
 * Given that, don't reference Quill outside this
 * and the visualizer component.
 *
 * Both the editor and the visualizer should be responsible of
 * abstracting Quill.
 */
export const RichTextEditor: React.FC<IRichTextEditor> = React.memo((props) => {
  const {
    onChange,
    value,
    className,
    placeholder = '',
    toolbarOptions = [
      'bold',
      'italic',
      'underline',
      'strike',
      'link',
    ],
    toolbarModules = [
      'bold',
      'italic',
      'underline',
      'strike',
      'link',
    ],
    boundSelector,
  } = props;

  // https://github.com/zenoamaro/react-quill/issues/814
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => setIsInitialRender(false), []);

  /** react-quill doesn't play good with SSR */
  const ReactQuill = useMemo(() => (
    (typeof window === 'object' && typeof document === 'object')
      ? require('react-quill')
      : () => null
  ), []);

  useEffect(() => {
    const interceptLink = () => {
      if (typeof window !== 'object' || typeof document !== 'object') {
        return;
      }

      const Quill = require('quill');

      const Link = Quill.import('formats/link');
      Link.sanitize = function (_url: string) {
        let url = _url;

        if (!/^http(s?)\:\/\//g.test(url)) {
          url = `https://${url}`;
        }

        return url;
      };
    };

    interceptLink();
  }, []);
  return (
    <div className={classNames(styles.RichTextEditor, className)} id={boundSelector}>
      {!isInitialRender && (
        <ReactQuill
          formats={toolbarOptions}
          modules={{
            toolbar: toolbarModules,
          }}
          theme="snow"
          placeholder={placeholder}
          defaultValue={value}
          className={styles.input}
          onChange={onChange}
          bounds={boundSelector}
        />
      )}
    </div>
  );
});

RichTextEditor.displayName = 'RichTextEditor';
