import { IState } from '../../../../types/state';
import { IUpdatePaymentOptionDescriptionAction } from './index';

export const updateDescription = (state: IState, action: IUpdatePaymentOptionDescriptionAction): IState => {
  if (state.collaborationDetails.reward[action.paymentType].description === action.description) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      reward: {
        ...state.collaborationDetails.reward,
        [action.paymentType]: {
          ...state.collaborationDetails.reward[action.paymentType],
          description: action.description,
        },
      },
    },
  };
};
