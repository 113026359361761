import gql from 'graphql-tag';

export const GET_APPLICATION_INSTANCES = gql`
  query GetApplicationInstances{
    instances: getAllApplicationsInstances {
      id
      applicationId
      clientId
    }
  }
`;

export const GET_APPLICATIONS_BY_IDS = gql`
  query GetApplicationsByIds($applicationIds: [String!]!) {
    applications: getApplicationsByIds(applicationIds: $applicationIds) {
      id
      name
      icon
      visibility {
        showOnNav
        showOnMember
        showOnMemberList
      }
    }
  }
`;
