import * as React from 'react';
import cx from 'classnames';
import xss from 'xss';
import { isUndefined } from 'lodash';
import { ITemplateProps } from '../types';

import styles from './Persona.scss';
import { RichTextVisualizer } from '../../RichTextEditor/Visualizer';

type BulletStyle = 'dashes' | 'bullets' | 'none';

export type IPersonaProps = ITemplateProps['persona'];

export const Persona: React.FC<IPersonaProps> = ({
  show = true,
  cover_image,
  bullet_style,
  features,
  title,
  componentStyles,
}) => {
  if (!show) {
    return null;
  }

  const renderFeatures = (features: string[], bulletStyle: BulletStyle) => features.map((feature, index) => (
    <div
      key={index}
      className={styles.item}
    >
      <div
        className={cx(
              styles.itemBullet,
              {
                [styles.bullets]: bulletStyle === 'bullets',
                [styles.hide]: bulletStyle === 'none',
              },
            )}
      >
        <div
          className={styles.itemBulletSquare}
          style={{ backgroundColor: componentStyles.heading.color }}
        />
      </div>

      <RichTextVisualizer
        className={styles.itemValue}
        style={componentStyles.body}
      >
        {isUndefined(feature) ? 'List out characteristics you are looking for' : xss(feature)}
      </RichTextVisualizer>
    </div>
  ));

  const coverImage = {
    backgroundImage: `url(${cover_image})`,
  };

  return (
    <div className={cx(styles.Persona)}>
      <div className={styles.featuresContainer}>
        <h1
          className={styles.title}
          style={componentStyles.heading}
        >
          {title}
        </h1>

        <div className={styles.list}>
          {renderFeatures(features, bullet_style as BulletStyle)}
        </div>
      </div>
      <div style={coverImage} className={styles.coverImage} />
    </div>
  );
};
