import * as React from 'react';

import { Upload } from 'antd';
import { useCSVUploadContext } from '@frontend/app/containers/Members/hooks';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

interface IProps {
  disabled?: boolean;
  className?: string;
}

export const UploadButton: React.FunctionComponent<IProps> = React.memo((props) => {
  const addEvent = useEventContext();

  const {
    antdUploadProps,
  } = useCSVUploadContext();

  return (
    <>
      <Upload {...antdUploadProps} showUploadList={false}>
        <span
          className={props.className}
          onClick={() => addEvent(EventName.ImportMembers, {})}
        >
          {props.children || 'Upload'}
        </span>
      </Upload>
    </>
  );
});

UploadButton.displayName = 'UploadButton';
