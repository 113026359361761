import gql from 'graphql-tag';
import { PAGING_DATA_FRAGMENT } from './fragments/Pagination';
import { TIKTOK_SETTINGS_ACCOUNT_FRAGMENT } from './fragments/TikTokSettings';

export const LIST_TIKTOK_SETTINGS_ACCOUNTS = gql`
  query ListTikTokSettingsAccountsQuery($paging: PagingParams!) {
    tiktokSettingsListAccounts(paging: $paging) {
      results {
        ...TikTokSettingsAccountFragment
      }
      paging {
        ...PagingDataFragment
      }
    }
  }
  ${TIKTOK_SETTINGS_ACCOUNT_FRAGMENT}
  ${PAGING_DATA_FRAGMENT}
`;
