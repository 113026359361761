import React, { useMemo } from 'react';
import {
 DatePicker, Typography, Row, Card, Space, Popover, Checkbox,
} from '@revfluence/fresh';
import { CalendarCheckIcon, CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import moment from 'moment';
import styles from '../ManageOffer.scss';
import { TMember } from '../../MembersWizard/types';

const { Title, Text } = Typography;

type ActiveDate = {
    startDate: Date,
    endDate: Date,
    showEndDate: boolean
  };

interface IProps {
  data: readonly TMember[];
  activeDates: ActiveDate;
  handleActiveDatesChange: (key: string, value: Date | boolean) => void;
}

const OfferBulkDateAction: React.FC<IProps> = ({ data, activeDates, handleActiveDatesChange }) => {
  const disabledEndDate = useMemo(() => {
    const givenDate = activeDates.startDate;
    return (current) => current && current < givenDate;
  }, [activeDates]);

  const disableStartDate = (current: moment.Moment) => current && current < moment().startOf('day');

  return (
    <div className={styles.manageOfferContainer}>
      <Title level={5}>Select active dates</Title>
      <Text>You can choose the active dates for all the selected members</Text>
      <Row className={styles.memberCount}>
        <Text className={styles.boldText}>
          {data.length}
          {' '}
          {data.length === 1 ? 'Member' : 'Members'}
          {' '}
          Selected
        </Text>
      </Row>
      <Row className={styles.activeDateContainer}>
        <Card size="small" className={styles.card}>
          <Space className={styles.dateHeader}>
            <Text className={styles.boldText}>
              <CalendarCheckIcon style={{ marginRight: '8px' }} />
              Set Active Dates
            </Text>
            <Popover
              content="Now you can directly set active/end dates for all the selected members in bulk. "
              title="Set Dates in Bulk"
              overlayStyle={{ maxWidth: '200px' }}
              placement="right"
            >
              <CircleInfoIcon />
            </Popover>
          </Space>
          <Space direction="vertical" className={styles.dateContainer}>
            <Text>Active Date</Text>
            <DatePicker
              showTime={{ format: 'hh:mm a' }}
              format="MMM D, YYYY hh:mm a"
              disabledDate={disableStartDate}
              value={activeDates.startDate ? moment(activeDates.startDate) : null}
              onChange={(date) => handleActiveDatesChange('startDate', date ? date.toDate() : null)}
              className={styles.dateInput}
            />
            <Checkbox
              onChange={() => handleActiveDatesChange('showEndDate', !activeDates.showEndDate)}
              checked={activeDates.showEndDate}
            >
              Set End Date
            </Checkbox>
            {activeDates.showEndDate && (
              <>
                <Text>End Date</Text>
                <DatePicker
                  showTime={{ format: 'hh:mm a' }}
                  format="MMM D, YYYY hh:mm a"
                  disabledDate={disabledEndDate}
                  value={activeDates.endDate ? moment(activeDates.endDate) : null}
                  onChange={(date) => handleActiveDatesChange('endDate', date ? date.toDate() : null)}
                  className={styles.dateInput}
                />
              </>
            )}
          </Space>
        </Card>
      </Row>
    </div>
  );
};

export default OfferBulkDateAction;
