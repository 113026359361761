import { isUndefined, isNil } from 'lodash';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_SEGMENT_FOLDERS } from '@frontend/app/queries';
import {
  SegmentFoldersQuery,
  SegmentFoldersQueryVariables,
} from '@frontend/app/queries/types/SegmentFoldersQuery';

type IOptions = QueryHookOptions<SegmentFoldersQuery, SegmentFoldersQueryVariables>;

export const useSegmentFoldersQuery = (communityId?: number, sourceGroup?: string, options: IOptions = {}) => useQuery<SegmentFoldersQuery, SegmentFoldersQueryVariables>(
    GET_SEGMENT_FOLDERS,
    {
      ...options,
      errorPolicy: 'all',
      variables: {
        communityId,
        sourceGroup,
      },
      skip: options.skip || (isUndefined(communityId) && isNil(sourceGroup)),
    },
  );
