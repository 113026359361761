import React, { useEffect, useState } from 'react';

interface TikTokEmbedProps {
  url: string;
  className?: string;
}

declare global {
  interface Window {
    ttEmbed?: {
      init: () => void;
    };
  }
}

export const TikTokEmbed: React.FC<TikTokEmbedProps> = ({ url, className }) => {
  const [embedHtml, setEmbedHtml] = useState<string | null>(null);

  useEffect(() => {
    const fetchEmbedHtml = async () => {
      try {
        const response = await fetch(`https://www.tiktok.com/oembed?url=${encodeURIComponent(url)}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch TikTok embed code: ${response.status}`);
        }
        const data = await response.json();
        setEmbedHtml(data.html);
      } catch (error) {
        console.error('Error fetching TikTok embed code:', error);
      }
    };

    fetchEmbedHtml();
  }, [url]);

  useEffect(() => {
    // Load TikTok's embed script if it's not already loaded
    const scriptId = 'tiktok-embed-script';

    if (embedHtml) {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://www.tiktok.com/embed.js';
        script.async = true;
        script.onload = () => {
          if (window.ttEmbed && typeof window.ttEmbed.init === 'function') {
            window.ttEmbed.init();
          }
        };
        document.body.appendChild(script);
      } else {
        // Re-initialize TikTok embeds
        if (window.ttEmbed && typeof window.ttEmbed.init === 'function') {
          window.ttEmbed.init();
        }
      }
    }
  }, [embedHtml]);

  if (!embedHtml) {
    return null;
  }

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: embedHtml }}
    />
  );
};
