import * as React from 'react';
import { Input } from 'antd';

import styles from './ImagesEqual.scss';

const { useImperativeHandle, useRef } = React;

interface IProps {
  value: string;
  onChange?(value: string);
}

export interface IImagesEqualHandles {
  focus(): void;
}

const ImagesEqualComponent: React.RefForwardingComponent<IImagesEqualHandles, IProps> = (props, ref) => {
  const {
    value,
    onChange,
  } = props;

  const inputRef = useRef<React.ElementRef<typeof Input>>(null);
  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
  }));

  return (
    <Input
      className={styles.ImagesEqual}
      size="large"
      autoFocus
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      ref={inputRef}
    />
  );
};
export const ImagesEqual = React.forwardRef(ImagesEqualComponent);
