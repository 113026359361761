import React from 'react';
import { Tag } from '@revfluence/fresh';
import {
  find,
  isBoolean, isNull, map, pick,
} from 'lodash';
import styles from './CreatorAttribute.scss';

const CreatorAttribute = ({ memberRecord, applicantReviewFields = {}, memberFieldSchemas = [] }) => {
  const attributeFields = ['Attribute 1', 'Attribute 2', 'Attribute 3', 'Attribute 4', 'Attribute 5'];
  const matchedFields = isNull(applicantReviewFields)
    ? {}
    : pick(applicantReviewFields, attributeFields);

  const getDisplayForAttribute = (fieldId: string): JSX.Element => {
    const fieldValue = memberRecord.fields[fieldId];

    let displayValue;
    if (fieldValue) {
      if (isBoolean(fieldValue)) {
        displayValue = find(memberFieldSchemas, ({ id }) => id === fieldId)?.name;
      } else {
        displayValue = fieldValue;
      }
      return (
        <Tag size="default" key={fieldId}>
          {displayValue}
        </Tag>
);
    }
    return null;
  };

  return (
    <div className={styles.socialDemographics}>
      {map(matchedFields, (fieldId, _key) => getDisplayForAttribute(fieldId))}
    </div>
  );
};

export default CreatorAttribute;
