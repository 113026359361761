import * as React from 'react';
import {
  Space, Checkbox, Typography, Popover,
} from '@revfluence/fresh';
import { ContentType } from '@frontend/app/types/globalTypes';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import { AlertAdsPermissionsNeeded, AlertInstagramInsightsMissing } from '../../Alerts/Alerts';
import { isInstagram, isTiktok } from '../../utils/contentTypeUtils';

interface IBrandedContentToggleProps {
  contentType: ContentType;
  checked: boolean;
  onToggle: (checked: boolean) => void;
  linkInsightsAlertForBrandedContent: boolean;
  linkBCAAccountAlert: boolean;
}

const shouldHide = (
  type: ContentType,
): boolean => {
  if (isInstagram(type)) {
    return false;
  }
  if (isTiktok(type)) {
    return false;
  }
  return true;
};

const showError = (
  type: ContentType,
): boolean => {
  if (isInstagram(type)) {
    return true;
  }
  return false;
};

// eslint-disable-next-line max-len
const helpArticle = 'https://intercom.help/aspireiq_elevate/en/articles/7031689-branded-content-and-branded-content-ads-overview';

const tooltipContent = (type: ContentType): React.ReactNode => {
  if (isInstagram(type)) {
    return (
      <>
        The &apos;Paid Partnership&apos; label lets consumers know when a brand sponsors content.
        {' '}
        They can also use the label easily navigate to your brand&apos;s Instagram account.
        {' '}
        <Typography.Link target="_blank" href={helpArticle}>Learn More.</Typography.Link>
      </>
    );
  }
  if (isTiktok(type)) {
    return (
      <>
        Turning on the &apos;Branded Content&apos;
        {' '}
        toggle lets consumers know when content is sponsored by a brand.
        {' '}
        <Typography.Link target="_blank" href={helpArticle}>Learn More.</Typography.Link>
      </>
    );
  }
  return false;
};

const checkBoxLabel = (type: ContentType): React.ReactNode => {
  if (isInstagram(type)) {
    return 'The \'Paid Partnership\' label is required for all sponsored content posted to Instagram.';
  }
  if (isTiktok(type)) {
    return 'The \'Paid Partnership\' label is required for all sponsored content posted to TikTok.';
  }
  return false;
};

export const BrandedContentToggle: React.FC<IBrandedContentToggleProps> = ({
  contentType,
  checked,
  onToggle,
  linkBCAAccountAlert,
  linkInsightsAlertForBrandedContent,
}) => {
  if (shouldHide(contentType)) {
    return null;
  }
  return (
    <Space align="start" direction="vertical">
      <Space align="center">
        <Typography.Title level={5}>
          Branded Content
          {' '}
          <Popover
            content={tooltipContent(contentType)}
            overlayStyle={{ width: '300px' }}
          >
            <CircleInfoIcon />
          </Popover>
        </Typography.Title>
      </Space>
      <Checkbox
        checked={checked}
        onChange={(e) => onToggle(e.target.checked)}
      >
        {checkBoxLabel(contentType)}
      </Checkbox>
      {showError(contentType) && linkInsightsAlertForBrandedContent && checked && (
        <AlertInstagramInsightsMissing />
      )}
      {showError(contentType) && linkBCAAccountAlert && checked && (
        <AlertAdsPermissionsNeeded />
      )}
    </Space>
  );
};
