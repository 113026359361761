import { useMutation, MutationHookOptions } from '@apollo/client';

import { PROMOTE_TO_GCR } from '@frontend/app/queries';
import { PromoteToGCR, PromoteToGCRVariables } from '@frontend/app/queries/types/PromoteToGCR';

type IOptions = MutationHookOptions<PromoteToGCR, PromoteToGCRVariables>;

export function usePromoteToGCR(options: IOptions = {}) {
  const [promoteToGCR, { loading, error }] = useMutation<
    PromoteToGCR,
    PromoteToGCRVariables
  >(PROMOTE_TO_GCR, {
    ...options,
  });

  return {
    promoteToGCR,
    loading,
    error,
  };
}
