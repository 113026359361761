import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const AlertIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path d="M9.99984 2.48392C14.1449 2.48392 17.5153 5.85429 17.5153 9.99935C17.5153 14.1444 14.1449 17.5148 9.99984 17.5148C5.85478 17.5148 2.48441 14.1444 2.48441 9.99935C2.48441 5.85429 5.85478 2.48392 9.99984 2.48392ZM9.99984 1.66602C5.39799 1.66602 1.6665 5.3975 1.6665 9.99935C1.6665 14.6012 5.39799 18.3327 9.99984 18.3327C14.6017 18.3327 18.3332 14.6012 18.3332 9.99935C18.3332 5.3975 14.6017 1.66602 9.99984 1.66602Z" />
    <path d="M10.0403 12.0065C10.5187 12.0065 10.9107 12.4077 10.9107 12.8861C10.9107 13.3521 10.5187 13.7565 10.0403 13.7565C9.5619 13.7565 9.16992 13.3552 9.16992 12.8861C9.16992 12.4108 9.5619 12.0065 10.0403 12.0065ZM9.19153 6.39844H10.8644L10.6483 11.2904H9.42918L9.19153 6.39844Z" />
  </SvgIcon>
));

AlertIcon.displayName = 'AlertIcon';
