import * as React from 'react';
import cx from 'classnames';
import {
 get, has, indexOf, isEmpty, isFunction, map, sortBy,
} from 'lodash';

import { Tooltip } from '@components';
import { ISocialAccount } from '@components';
import { UniqueImpressions } from '@components';
import { useInviteContext } from '@components';
import { Loading } from '@components';

const { useEffect, useRef, useMemo } = React;
import styles from './ProgramsList.scss';

interface IProps {
  className?: string;
  /**
   * Social account
   */
  socialAccount: ISocialAccount;
  /**
   * Render without container
   */
  inline?: boolean;
  /**
   * Tooltip
   */
  showTooltip?: boolean;
  /**
   * Hide icon
   */
  hideIcon?: boolean;
  /**
   * Callback when programs are loaded
   */
  onProgramsLoaded?: () => void;
}

/**
 * List of programs
 * Only used for AspireX
 */
export const ProgramsList: React.FC<IProps> = (props: IProps) => {
  const {
    className,
    hideIcon = false,
    inline = false,
    onProgramsLoaded,
    showTooltip = false,
    socialAccount,
  } = props;
  const containerRef = useRef<HTMLDivElement>();

  const {
    memberPrograms,
  } = useInviteContext();

  const programs = useMemo(() => (
    sortBy(
      get(memberPrograms, socialAccount.id),
      (program) => indexOf(['approved', 'new', 'invited'], program.status),
    )
  ), [memberPrograms, socialAccount.id]);

  const programsIds = useMemo(
    () => map(programs, (p) => p.id).sort().join(),
    [programs],
  );

  useEffect(() => {
    if (has(memberPrograms, socialAccount.id) && isFunction(onProgramsLoaded)) {
      console.debug(
        'Updating programs list for',
        { socialAccountId: socialAccount.id, username: socialAccount.username },
      );
      onProgramsLoaded();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programsIds]);

  const isFetching = useMemo(() => (
    !has(memberPrograms, socialAccount.id) && !isEmpty(programs)
  ), [memberPrograms, socialAccount.id, programs]);

  const listElem = isFetching
    ? (
      <div className={styles.placeholder}>
        <Loading
          show
          theme="grey"
          borderRadius={4}
        />
      </div>
    )
    : (
      <>
        {!hideIcon && !inline && programs.length > 0 && (
        <UniqueImpressions
          size={20}
          className={cx(styles.icon, styles[programs[0].status])}
        />
      )}
        {map(programs, (program, i) => (
          <span
            key={`program-${socialAccount.username}-${i}`}
            className={cx(styles.program, styles[program.status])}
          >
            {program.title + (i + 1 < programs.length ? ',' : '')}
          </span>
      ))}
      </>
);

  const tooltipElem = showTooltip && !inline && (
    <Tooltip
      mountRef={containerRef}
      maxWidth={600}
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      className={(styles as any).Tooltip}
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      contentClassName={(styles as any).content}
    >
      {map(programs, (p) => {
        if (p.status === 'invited') {
          return `${p.title} (Invited)`;
        }
        return p.title;
      }).join(', ')}
    </Tooltip>
  );

  return (
    <>
      {inline
      ? listElem
      : (
        <div
          className={cx(styles.ProgramsList, className, {
            [styles.hideIcon]: hideIcon,
          })}
          ref={containerRef}
        >
          {listElem}
        </div>
      )}
      {tooltipElem}
    </>
);
};

ProgramsList.displayName = 'ProgramsList';
