import * as React from 'react';
import cx from 'classnames';
import {
 chain, each, filter, find, get, isEmpty, isEqual, isNil, slice, split, startsWith, trim, uniq,
} from 'lodash';
import { RawDraftContentState } from 'draft-js';

import {
 Editor, IEditor, IEditorState, IAttachment,
} from '@frontend/app/components';
import { GetMembersForRestrictionQuery_members } from '@frontend/app/queries/types/GetMembersForRestrictionQuery';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import {
 MessageBackupPlan, BackupType, MessageBackupPlanEntity, ResourceType,
} from '@frontend/app/types/globalTypes';
import {
 useMemberSearchQuery, useGetVariables, IMessageTemplate, IMember,
} from '@frontend/app/hooks';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';

import { getMemberFieldValue } from '@frontend/app/utils';
import { InvalidDataNotice } from './InvalidDataNotice';
import { VariableBlock, findVariableStrategy } from './decorator';

import { IEmailEditorProviderProps, EmailEditorProvider } from './EmailEditorContext';
import { useEmailEditorContext } from './EmailEditorContext';

import styles from './EmailEditor.module.scss';

const URL_REGEX = new RegExp(/https?:\/\/.+/);

const {
 useRef, useMemo, useState, useEffect,
} = React;

export interface IEmailEditorState extends IEditorState {
  clearBackupPlans?: () => void;
  backupPlans: MessageBackupPlan[];
  hasError: boolean;
}

const EMPTY_STATE: IEmailEditorState = {
  backupPlans: [],
  raw: null,
  html: '',
  hasError: false,
};

export interface IEmailEditorProps extends IEmailEditorProviderProps {
  attachments?: IAttachment[];
  plaintextOnly?: boolean;
  resourceType?: ResourceType;
  onAttachmentsSelected?(files: FileList): void;
  onDeleteAttachment?(id: string): void;

  readOnly?: boolean;
  initialValue?: string | RawDraftContentState;
  currentValue?: string | RawDraftContentState;
  isInitialMessageHTML?: boolean;
  signaturePreview?: string;
  disabled?: boolean;
  editorRef?: React.MutableRefObject<IEditor>;
  onChange?(state: IEmailEditorState): void;
  actions?: JSX.Element[];

  // preview mode
  previewMember?: IMember;
  backupPlans?: MessageBackupPlan[];
  enableVariableReplacement?: boolean;
  additionalVariables?: { [key: string]: { label: string } };
  replaceTemplateVariables?: boolean;
  enableMessageTemplate?: boolean;
  members?: IMember[];
  memberQuery?: IMemberSearchQuery;

  showProgramVariables?: boolean;
  onTemplateSelected?(template: IMessageTemplate): void;

  hasFixedHeight?: boolean;
  className?: string;

  actionsDisabled?: boolean;
  onClose?: () => void;
}

function getFieldValue(member: GetMembersForRestrictionQuery_members, variable): string {
  if (!variable.jsonParent) {
    return trim(get(member, variable.field));
  }
  const splitKey = split(variable.field, '.');
  const jsonParentField = slice(splitKey, 0, 2).join('.');
  const jsonDataKey = slice(splitKey, 2).join('.');
  const jsonData = JSON.parse(trim(get(member, jsonParentField)));
  return trim(get(jsonData, jsonDataKey));
}

/**
 * @type {React.FC}
 */
const _EmailEditor: React.FC<IEmailEditorProps> = React.memo((props) => {
  const { additionalVariables } = props;
  const newRef = useRef<IEditor>(null);
  const editorRef = props.editorRef || newRef;

  const {
    backupPlans,
    setPreviewMember,
    setBackupPlans,
    setInvalidMemberIdsForField,
  } = useEmailEditorContext();

  // TODO: - the PFA using proxy so need to pass via context
  const pfaContextVariables = useProductFulfillmentContext()?.variables;
  const pfaVariables = useProductFulfillmentContext()?.usePfaContextVariables && pfaContextVariables;
  const { variables: queryVariables } = useGetVariables({ skip: !!pfaVariables });
  const variables = useMemo(() => {
    if (pfaVariables) {
      return {
        ...pfaVariables,
        additionalVariables,
      };
    }
    return {
      ...queryVariables,
      additionalVariables,
    };
  }, [pfaVariables, queryVariables, additionalVariables]);

  const { data: { members: membersData } = {} } = useMemberSearchQuery({
    variables: {
      query: props.memberQuery,
      skip: 0,
      take: props.memberQuery?.includeMemberIds ? props.memberQuery.includeMemberIds.length : 100,
    },
    skip: !props.memberQuery,
    fetchPolicy: 'no-cache',
  });

  const clearBackupPlans = () => {
    setBackupPlans([]);
  };

  const [state, setState] = useState<IEmailEditorState>({
    ...EMPTY_STATE,
    clearBackupPlans,
  });

  const readOnly = props.readOnly || !!props.previewMember;
  const members = isEmpty(props.members) ? membersData : props.members;

  const memberVariableFields: string[] = useMemo(
    () =>
      chain(state?.raw?.entityMap)
        .filter((m) => m.type === 'variable' && startsWith(m.data.data.field, 'MEMBER'))
        .map((m) => m.data.data.field)
        .uniq()
        .value(),
    [state],
  );

  const invalidMemberIdsForField: {
    [field: string]: number[];
  } = useMemo(() => {
    const result = {};
    each(variables?.member, (variable, field) => {
      each(members, (member) => {
        try {
          const fieldValue = getFieldValue(member, variable);
          if (isNil(fieldValue) || isEmpty(fieldValue)) {
            if (!result[field]) {
              result[field] = [];
            }
            result[field].push(member.id);
          }
        } catch (e) {
          if (!result[field]) {
            result[field] = [];
          }
          result[field].push(member.id);
        }
      });
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, queryVariables, pfaVariables]);

  const requiredMemberFields: string[] = useMemo(
    () =>
      chain(backupPlans)
        .filter((p) => p.entity === MessageBackupPlanEntity.MEMBER && p.backupType === BackupType.REMOVE_INVALID)
        .map((p) => variables?.member[p.field]?.field)
        .value(),
    [backupPlans, variables],
  );

  const allHaveBackupPlans = useMemo(() => {
    for (const field of memberVariableFields) {
      // if the field has invalid members
      // and we cant find a backup plan for it
      if (!!invalidMemberIdsForField[field] && !find(state?.backupPlans, (p) => p.field === field)) {
        return false;
      }
    }

    return true;
  }, [invalidMemberIdsForField, state, memberVariableFields]);

  const allMembersExcluded = useMemo(() => {
    if (isEmpty(requiredMemberFields) || isEmpty(members)) {
      return false;
    }

    for (const field of requiredMemberFields) {
      let flag = true;
      for (const member of members) {
        const fieldValue = getMemberFieldValue(member, field);
        if (!isNil(fieldValue) && !isEmpty(fieldValue)) {
          flag = false;
        }
      }

      if (flag) {
        return true;
      }
    }

    return false;
  }, [requiredMemberFields, members]);

  const containsInvalidURL = useMemo(
    () => !!find(state?.raw?.entityMap, (m) => m.type === 'link' && !URL_REGEX.test(m.data.src)),
    [state],
  );

  const canSend = !allMembersExcluded && allHaveBackupPlans && !containsInvalidURL;

  const errorMessage = !allHaveBackupPlans
    ? 'Must choose backup plans for all before proceeding.'
    : allMembersExcluded
    ? 'All recipients have been excluded.'
    : containsInvalidURL
    ? 'Email body contains invalid URL(s).'
    : '';

  const invalidMemberIds = useMemo(() => {
    const ids = [];
    each(memberVariableFields, (field) => {
      ids.push(...(invalidMemberIdsForField[field] || []));
    });

    return uniq(ids);
  }, [invalidMemberIdsForField, memberVariableFields]);

  const hasError = !(isEmpty(invalidMemberIds) || allHaveBackupPlans);

  useEffect(() => {
    if (!isEqual(backupPlans, state?.backupPlans) || hasError !== state?.hasError) {
      const newState: IEmailEditorState = {
        ...state,
        backupPlans,
        clearBackupPlans,
        hasError,
      };

      setState(newState);
      props.onChange(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backupPlans, state, setState, hasError]);

  // for variable block preview
  useEffect(() => {
    if (props.previewMember) {
      setPreviewMember(props.previewMember);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.previewMember]);

  useEffect(() => {
    if (props.backupPlans) {
      setBackupPlans(props.backupPlans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.backupPlans]);

  useEffect(() => {
    setInvalidMemberIdsForField(invalidMemberIdsForField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidMemberIdsForField]);

  /**
   * Reset the value on prop change
   */
  useEffect(() => {
    editorRef?.current?.setValue(props.currentValue || props.initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue, editorRef]);

  return (
    <div className={cx(styles.EmailEditor, props.className)}>
      <Editor
        plaintextOnly={props.plaintextOnly}
        ref={editorRef}
        onClose={props.onClose}
        errorHeader={
          invalidMemberIds.length && !allHaveBackupPlans ? (
            <InvalidDataNotice invalidMemberIds={invalidMemberIds} allHaveBackupPlans={allHaveBackupPlans} />
          ) : null
        }
        enableMessageTemplate={props.enableMessageTemplate}
        initialValue={props.initialValue}
        currentValue={props.currentValue}
        signaturePreview={props.signaturePreview}
        convertFromHTML={props.isInitialMessageHTML}
        disabled={props.disabled}
        readOnly={readOnly}
        className={styles.editor}
        hasFixedHeight={props.hasFixedHeight}
        attachments={props.attachments}
        resourceType={props.resourceType}
        onDeleteAttachment={props.onDeleteAttachment}
        onFilesSelected={props.onAttachmentsSelected}
        showProgramVariables={props.showProgramVariables}
        enableVariableReplacement={props.enableVariableReplacement}
        replaceTemplateVariables={props.replaceTemplateVariables}
        members={props.members}
        invalidMemberIdsForField={invalidMemberIdsForField}
        additionalVariables={props.additionalVariables}
        canSend={canSend}
        errorMessage={errorMessage}
        actions={props.actions}
        actionsDisabled={props.actionsDisabled}
        onTemplateSelected={props.onTemplateSelected}
        onChange={(editorState) => {
          const prevBackupPlans = state?.backupPlans;
          const { entityMap } = editorState.raw;
          const validBackupPlans = filter(
            prevBackupPlans,
            (p) => !!find(entityMap, (m) => m.type === 'variable' && m.data.data?.field === p.field),
          );
          const newState: IEmailEditorState = {
            ...editorState,
            backupPlans: validBackupPlans,
            clearBackupPlans,
            hasError,
          };

          if (!isEqual(prevBackupPlans, validBackupPlans)) {
            setBackupPlans(validBackupPlans);
          }
          props.onChange(newState);
          setState(newState);
        }}
        decorators={[
          {
            strategy: findVariableStrategy,
            component: VariableBlock,
          },
        ]}
      />
    </div>
  );
});

_EmailEditor.defaultProps = {
  onChange: () => undefined,
  actions: [],
  enableVariableReplacement: false,
  enableMessageTemplate: false,
  hasFixedHeight: false,
};

export const EmailEditor: React.FC<IEmailEditorProps> = React.memo((props) => {
  const { disableRemoveRecipients, ...editorProps } = props;

  return (
    <EmailEditorProvider disableRemoveRecipients={disableRemoveRecipients}>
      <_EmailEditor {...editorProps} />
    </EmailEditorProvider>
  );
});

EmailEditor.displayName = 'EmailEditor';
