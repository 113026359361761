import { find } from 'lodash';
import { useMutation, MutationHookOptions } from '@apollo/client';

import { SAVE_PROGRAM } from '@frontend/app/queries/SaveProgramMutation';
import {
  SaveProgramMutation,
  SaveProgramMutationVariables,
} from '@frontend/app/queries/types/SaveProgramMutation';

import { PROGRAMS_FOR_COMMUNITY } from '@frontend/app/queries/ProgramsForCommunityQuery';
import {
  ProgramsForCommunityQuery,
  ProgramsForCommunityQueryVariables,
} from '@frontend/app/queries/types/ProgramsForCommunityQuery';
import { ProjectByIdQuery, ProjectByIdQueryVariables } from '@frontend/app/queries/types/ProjectByIdQuery';
import { PROJECT_BY_ID_QUERY } from '@frontend/app/queries';

export const useSaveProgramMutation = (
  communityId?: number,
  options: MutationHookOptions<SaveProgramMutation, SaveProgramMutationVariables> = {},
) => useMutation<SaveProgramMutation, SaveProgramMutationVariables>(SAVE_PROGRAM, {
    ...options,
    update(...args) {
      const [
        cache,
        { data },
      ] = args;
      const newProgram = data?.program;
      if (communityId && newProgram !== undefined) {
        cache.writeQuery<ProjectByIdQuery, ProjectByIdQueryVariables>({
          query: PROJECT_BY_ID_QUERY,
          variables: {
            id: newProgram.id,
          },
          data: {
            project: newProgram,
          },
        });

        const data = cache.readQuery<ProgramsForCommunityQuery, ProgramsForCommunityQueryVariables>({
          query: PROGRAMS_FOR_COMMUNITY,
          variables: {
            communityId,
          },
        });

        // Do nothing. Cache doesn't exist.
        if (!data) {
          return;
        }

        const { programs } = data;

        const oldProgram = find(programs, { id: newProgram.id });

        if (!oldProgram) {
          cache.writeQuery<ProgramsForCommunityQuery, ProgramsForCommunityQueryVariables>({
            query: PROGRAMS_FOR_COMMUNITY,
            variables: {
              communityId,
            },
            data: {
              programs: [
                {
                  ...newProgram,
                  communityId,
                },
                ...programs,
              ],
            },
          });
        }
      }

      cache.evict({ fieldName: 'getAutomationSummary' });
      cache.evict({ fieldName: 'getAutomationStats' });
      cache.evict({ fieldName: 'listTemplates' });
      cache.gc();

      if (options.update) {
        options.update(...args);
      }
    },
  });
