import { useMutation, MutationHookOptions } from '@apollo/client';

import { DELETE_MEMBER_BY_ID } from '../queries';
import {
  DeleteMemberMutation,
  DeleteMemberMutationVariables,
} from '../queries/types/DeleteMemberMutation';

type IOptions = MutationHookOptions<DeleteMemberMutation, DeleteMemberMutationVariables>;

export const useDeleteMemberById = (options: IOptions = {}) => useMutation<DeleteMemberMutation, DeleteMemberMutationVariables>(DELETE_MEMBER_BY_ID, options);
