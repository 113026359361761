import {
 Avatar, Col, Row, Space, Typography,
} from '@revfluence/fresh';
import React from 'react';

const { Text } = Typography;

export interface INameWithImageRendererProps {
  title: string;
  image: string;
  subTitle?: string;
  rightContent?: React.ReactNode;
}

export const NameWithImageRenderer = ({
 title, image, subTitle, rightContent,
}: INameWithImageRendererProps) => (
  <Row align="middle" justify="space-between">
    <Col>
      <Space>
        <Avatar shape="square" src={image || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'} size={32} />
        <Space direction="vertical" size={0}>
          <Text>{title}</Text>
          <Text type="secondary">{subTitle}</Text>
        </Space>
      </Space>
    </Col>
    <Col>{rightContent}</Col>
  </Row>
);
