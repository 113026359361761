import { MutationHookOptions, useMutation } from '@apollo/client';
import { UPDATE_IMPORTED_PRODUCT } from '@frontend/applications/ProductFulfillmentApp/queries';
import { UpdateImportedProduct, UpdateImportedProductVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/UpdateImportedProduct';

type IOptions = MutationHookOptions<UpdateImportedProduct, UpdateImportedProductVariables>;

export const useUpdateProduct = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<UpdateImportedProduct, UpdateImportedProductVariables>(UPDATE_IMPORTED_PRODUCT, options);

  return {
    updateProduct: mutate,
    isUpdatingProduct: loading,
  };
};
