import { ISocialPost } from './socialPost';
import { TNetworkIdentifier } from './networkIdentifier';
import { TPostType } from './postType';

/**
 * Raw social account data from back end
 */
export interface ISocialAccount {
  audience_authenticity_label?: string;
  average_clicks?: number;
  average_comments_count?: number;
  average_like_ratio?: number;
  bio?: string;
  bounce_rate?: number;
  can_contact?: boolean;
  content_count?: number;
  country_code?: string;
  demographics_report?: IDemographicsReport;
  description?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  device_data?: any;
  display_image?: string;
  display_name?: string;
  engagement_quality?: number;
  engagement_ratio_benchmark?: number;
  engagement_ratio_segment_range?: [number] | [number, number];
  engagement_ratio_segment?: string;
  engagement_ratio?: number;
  engagement?: number;
  estimated_price?: string;
  favorite_element?: IFavoriteElement;
  favorites_list?: IFavoritesList;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  featured_posts?: any[];
  follower_count?: number;
  follower_history?: IFollowerHistory[];
  follower_score?: number;
  full_display_name?: string;
  full_name?: string;
  gender?: string;
  has_analytics_api_access?: boolean;
  has_email?: boolean;
  has_lookalikes?: boolean;
  has_pinterest_access_token?: boolean;
  has_relationship?: boolean;
  id?: number;
  image?: string;
  images?: string[];
  impressions_ratio?: number;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  impressions?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ineligible_reason?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  warning_reason?: any;
  interests?: string[];
  invite?: IInvite;
  is_ambassador?: boolean;
  last_post?: string;
  like_history?: ILikeHistory[];
  likes_comments_ratio?: number;
  likes?: number;
  link?: string;
  youtube_id?: string;
  location_name?: string;
  minimum_offer?: number;
  minimum_recommended_prices?: TMinimumRecommendedPrices;
  name?: string;
  network_id?: string;
  network_identifier?: TNetworkIdentifier;
  original_pins_percentage?: number;
  page_views?: number;
  playlists?: IPlaylist[];
  posts?: ISocialPost[];
  predicted_emv?: number;
  price?: number;
  profile_image?: string;
  profile_picture?: string;
  profile_reach?: number;
  projects_completed?: number;
  publisher_id?: number;
  rating?: number;
  reach?: number;
  recent_geometric_mean_view_count?: number;
  recent_engagement_rate?: number;
  recent_stories_impressions?: number;
  recommended_price_ranges?: IRecommendedPriceRanges;
  recommended_prices?: TMinimumRecommendedPrices;
  relation_id?: number;
  relevant_post?: ISocialPost;
  report?: IReport;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  saves?: any;
  sponsored_engagement_ratio_benchmark?: number;
  sponsored_engagement_ratio?: number;
  sponsored_posts?: ISocialPost[];
  stories_impressions_ratio?: number;
  stories?: ISocialPost[];
  tags?: string[];
  time_on_site?: number;
  top_hashtags?: string[];
  type?: string;
  username?: string;
  mentioned_posts?: ISocialPost[];
  popular_posts?: ISocialPost[];
  email?: string;
  group_ids?: number[];
  project_ids?: number[];
  is_in_instagram_marketplace?: boolean;
}

export interface IPinterestAccount extends ISocialAccount {
  network_identifier: 'pinterest';

  average_clicks?: number;
  board_count?: number;
  closeups?: number;
  comments?: number;
  original_pins_percentage?: number;
  pin_count?: number;
  profile_react?: number;
}

export interface IYoutubeAccount extends ISocialAccount {
  network_identifier: 'youtube';

  playlists?: IPlaylist[];
  recent_average_comment_count?: number;
  recent_average_dislikes?: number;
  recent_average_likes?: number;
  shares?: number;
  top_categories?: string[];
}

export interface ITiktokAccount extends ISocialAccount {
  network_identifier: 'tiktok';

  recent_average_likes?: number;
  recent_linear_average_view_count?: number;
  viral_post_ratio?: number;
}

export interface IDemographicsReport {
  age?: ISocialAccountInsight[];
  city?: ISocialAccountInsight[];
  country?: ISocialAccountInsight[];
  ethnicity?: ISocialAccountInsight[];
  gender_age?: ISocialAccountInsight[];
  gender?: ISocialAccountInsight[];
  interest?: ISocialAccountInsight[];
  language?: ISocialAccountInsight[];
  state?: ISocialAccountInsight[];
}

export interface ISocialInsight {
  metric: SocialInsightMetric;
  dimension_key: SocialInsightDimensionKey;
  timeframe: SocialInsightTimeframe;
  total_value: number;
  values: ISocialInsightValue[];
}

export interface ISocialInsightValue {
  value: number;
  dimension_values: (string | SocialInsightAge | SocialInsightGender)[];
  percentage: number;
}

export interface ISocialAccountInsight extends ISocialInsight {
  social_account_key: string;
}

export enum SocialInsightTimeframe {
  ALL = 'all',
  ESTIMATED = 'estimated',
  PAST_14_DAYS = 'past_14_days',
  PAST_30_DAYS = 'past_30_days',
  PAST_90_DAYS = 'past_90_days',
}

export enum SocialInsightMetric {
  ENGAGED = 'engaged',
  FOLLOWERS = 'followers',
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  TOTAL_INTERACTIONS = 'total_interactions',
  LIKES = 'likes',
  COMMENTS = 'comments',
  SAVED = 'saved',
  SHARES = 'shares',
  REPLIES = 'replies',
  FOLLOWS_AND_UNFOLLOWS = 'follows_and_unfollows',
}

export enum SocialInsightDimensionKey{
  AGE = 'age',
  AGE_GENDER = 'age_gender',
  CITY = 'city',
  COUNTRY = 'country',
  DEVICE = 'device',
  ETHNICITY = 'ethnicity',
  GENDER = 'gender',
  INTEREST = 'interest',
  LANGUAGE = 'language',
  NONE = 'none',
  STATE = 'state',
}

export enum SocialInsightGender{
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
}

export enum SocialInsightAge{
  AGE_13_17 = 'age_13_17',
  AGE_18_24 = 'age_18_24',
  AGE_25_34 = 'age_25_34',
  AGE_35_PLUS = 'age_35_plus',
  AGE_35_44 = 'age_35_44',
  AGE_45_54 = 'age_45_54',
  AGE_45_64 = 'age_45_64',
  AGE_55_64 = 'age_55_64',
  AGE_65_PLUS = 'age_65_plus',
  AGE_UNKNOWN = 'age_unknown',
}

interface IPlaylist {
  count: number;
  title: string;
  url: string;
}

export interface IGenderData {
  female?: number;
  FEMALE?: number;
  male?: number;
  MALE?: number;
  unknown?: number;
}

interface IRecommendedPriceRanges {
  instagram_story: number[];
  instagram_reel: number[];
  instagram: number[];
  instagram_video: number[];
}

interface IFavoriteElement {
  by_user_name: string;
  id: number;
  account_id: number;
  favorites_list_id: number;
}

interface IFavoritesList {
  brand_id: number;
  last_added_to_ts: number;
  element_count: number;
  id: number;
  name: string;
}

type TMinimumRecommendedPrices = {
  [key in keyof TPostType]: number;
};

interface IInvite {
  approved?: boolean;
  weighted_r?: number;
  qa_manager_approved?: boolean;
  account_id?: number;
  manager_acted_ts?: number;
  manager_approved?: boolean;
  publisher_id?: number;
  id?: number;
  rejected?: boolean;
  campaign_id?: number;
}

interface IReport {
  top_brands: ITopbrand[];
  related_accounts: string[];
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  topic_data?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  comment_topic_data?: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  comment_sentiment?: any;
  top_hashtags: string[];
}

interface ITopbrand {
  username: string;
  logo_url: string;
  name: string;
  mention_count: number;
}

export interface IFollowerHistory {
  created_ts: number;
  count: number;
}

export interface ILikeHistory {
  created_ts: number;
  count: number;
}
