/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_7mg5j_157 {
  display: flex;
}

._justify-content-space-between_7mg5j_161 {
  justify-content: space-between;
}

._tabular-nums_7mg5j_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_7mg5j_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._manageOfferwrapper_7mg5j_178 ._manageOfferButtons_7mg5j_178 .ant-btn {
  height: auto;
  width: 100%;
  border: none;
  text-align: unset;
  padding: 0px;
  font-weight: initial;
  margin-bottom: 0.5rem;
}
._manageOfferwrapper_7mg5j_178 ._manageOfferButtons_7mg5j_178 ._buttonsAction_7mg5j_187 {
  padding: 1rem;
  border: 0.0625rem solid rgb(240, 240, 240);
  border-radius: 0.375rem;
}
._manageOfferwrapper_7mg5j_178 ._manageOfferButtons_7mg5j_178 ._buttonsAction_7mg5j_187 .ant-avatar {
  background-color: #006462;
  vertical-align: middle;
}
._manageOfferwrapper_7mg5j_178 ._manageOfferButtons_7mg5j_178 ._buttonsAction_7mg5j_187 ._buttonTitle_7mg5j_196 {
  margin-left: 0.75rem;
}
._manageOfferwrapper_7mg5j_178 ._manageOfferButtons_7mg5j_178 ._buttonsAction_7mg5j_187 ._buttonTitle_7mg5j_196 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._manageOfferwrapper_7mg5j_178 ._manageOfferButtons_7mg5j_178 ._SelectOfferButtonWrapper_7mg5j_203 .ant-alert {
  text-wrap: wrap;
}
._manageOfferwrapper_7mg5j_178 ._img_7mg5j_206 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 0.5rem;
}