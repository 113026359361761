._ManagerSelect_kr7y5_1 {
  width: 100%;
}

._ManagerSelectDropdown_kr7y5_5 {
  min-width: 300px !important;
}

._SelectOption_kr7y5_9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
._SelectOption_kr7y5_9 ._avatar_kr7y5_15 {
  flex-shrink: 0;
}