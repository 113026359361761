import gql from 'graphql-tag';

import { MEMBER_FIELD_SCHEMA_FRAGMENT } from '@frontend/app/queries/fragments';

export const MEMBER_FIELD_SCHEMAS_BY_SECTIONS_QUERY = gql`
  query MemberFieldSchemasBySectionsQuery {
    sections: memberFieldSchemasBySections {
      social {
        ...MemberFieldSchemaFragment
      }
      permissions {
        ...MemberFieldSchemaFragment
      }
      socialPerformance {
        ...MemberFieldSchemaFragment
      }
      memberData {
        ...MemberFieldSchemaFragment
      }
      customData {
        ...MemberFieldSchemaFragment
      }
      salesTracking {
        ...MemberFieldSchemaFragment
      }
      payments {
        ...MemberFieldSchemaFragment
      }
      socialAnalytics {
        ...MemberFieldSchemaFragment
      }
      recommendedPayments {
        ...MemberFieldSchemaFragment
      }
      otherApps {
        appId
        appName
        fields {
          ...MemberFieldSchemaFragment
        }
      }
    }
  }
  ${MEMBER_FIELD_SCHEMA_FRAGMENT}
`;
