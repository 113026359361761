import * as React from 'react';
import { map } from 'lodash';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import {
 FileSelector, LoadSpinner, Notice, Progress, Button,
} from '@components';
import { useUploadContent } from '@frontend/app/hooks';
import { useFetchContractTemplatesData } from '../useFetchContractTemplates';

import styles from './NewContractFileSelector.scss';

interface IProps {
  onSelect(templateId: string, fileUrl: string): void;
}

const { useState, useEffect } = React;

const CONTRACT_MAX_FILE_SIZE = 24 * 1024 * 1024; // 24MB

export const NewContractFileSelector: React.FunctionComponent<IProps> = (props) => {
  const [error, setError] = useState<string>(null);
  const { onSelect } = props;
  const { clientId, backendServerApiEndpoint } = useApplication();

  const { data, loading } = useFetchContractTemplatesData(
    `${backendServerApiEndpoint}/contract_template`, clientId,
  );

  const {
    content,
    upload,
    error: uploadError,
    isUploading,
  } = useUploadContent({
    serviceName: 'contract_app',
    parentFolder: `${clientId}/contracts`,
  });

  useEffect(() => {
    if (content && content.fileUrl) {
      onSelect(null, content.fileUrl);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content && content.fileUrl]);

  const handleFilesSelected = (files: FileList) => {
    if (files.length > 1) {
      setError('Please upload only one contract at a time');
      return;
    }

    upload(files[0], 'application');
  };

  const setTemplate = (templateId: string) => {
    onSelect(templateId, null);
  };

  return (
    <div className={styles.NewContractFileSelector}>
      <div className={styles.newFileSection}>
        <h1>Upload your contract</h1>
        <FileSelector
          acceptTypes={['application']}
          onFilesSelected={handleFilesSelected}
          disabled={isUploading}
          className={styles.fileSelector}
          fileBytesLimit={CONTRACT_MAX_FILE_SIZE} // 24MB
        />
        <div className={styles.fileSubtitle}>
          24MB file size maximum
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {content && content.progress && <Progress percentage={content.progress as any} />}
        {(error || uploadError)
          && <Notice type="error">{error || uploadError.message}</Notice>}
      </div>
      <div className={styles.newTemplateSection}>
        <h1>
          Or select a template
        </h1>
        {loading ? <LoadSpinner /> : (
          <>
            {data && data.length > 0 ? (
              <div>
                {map(data, (template, index) => (
                  <div
                    className={styles.templateCell}
                    key={index}
                  >
                    <div className={styles.nameWrap}>
                      <span className={styles.contractName}>{template.name}</span>
                    </div>
                    <div className={styles.actionsWrap}>
                      <Button
                        label="Use Template"
                        onClick={() => setTemplate(template.id)}
                        theme="primary"
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.noTemplatesNotice}>
                <Notice type="info">
                  You have not set up any templates yet. Speak to your
                  implementation manager or help@aspireiq.com to get started
                </Notice>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
