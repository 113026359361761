import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const ImageStackIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M13.73,8.23c-0.07-0.17-0.22-0.3-0.41-0.32c-0.19-0.02-0.37,0.06-0.47,0.22l-2.92,4.38l-1.63-1.95
      c-0.1-0.12-0.26-0.19-0.42-0.18c-0.16,0.01-0.31,0.1-0.39,0.24l-2.47,4.11c-0.09,0.15-0.09,0.35-0.01,0.5
      c0.09,0.16,0.25,0.25,0.43,0.25h10.28c0.16,0,0.32-0.08,0.41-0.21c0.09-0.13,0.11-0.31,0.06-0.46L13.73,8.23z M6.34,14.48
      l1.65-2.76l1.61,1.93c0.1,0.12,0.25,0.19,0.41,0.18c0.16-0.01,0.3-0.09,0.39-0.22l2.75-4.12l1.87,4.99H6.34z"
    />
    <path
      d="M6.69,8.08c0.95,0,1.73-0.78,1.73-1.73c0-0.95-0.78-1.73-1.73-1.73c-0.95,0-1.73,0.78-1.73,1.73
      C4.96,7.3,5.73,8.08,6.69,8.08z M6.69,5.61c0.41,0,0.74,0.33,0.74,0.74S7.09,7.08,6.69,7.08S5.95,6.75,5.95,6.35
      S6.28,5.61,6.69,5.61z"
    />
    <path
      d="M18.61,16.36V4.03C18.61,2.91,17.7,2,16.58,2H4.25C3.13,2,2.22,2.91,2.22,4.03v12.33
      c0,1.12,0.91,2.03,2.03,2.03h12.33C17.7,18.39,18.61,17.48,18.61,16.36z M3.21,16.36V4.03C3.21,3.46,3.68,3,4.25,3h12.33
      c0.57,0,1.03,0.46,1.03,1.03v12.33c0,0.57-0.46,1.03-1.03,1.03H4.25C3.68,17.4,3.21,16.93,3.21,16.36z"
    />
    <path
      d="M21.33,7.2c-0.34-0.42-0.82-0.69-1.36-0.75c-0.27-0.03-0.52,0.17-0.55,0.44c-0.03,0.27,0.17,0.52,0.44,0.55
      c0.27,0.03,0.52,0.17,0.69,0.38c0.17,0.22,0.25,0.49,0.22,0.76l-1.26,11.5c-0.06,0.57-0.57,0.98-1.14,0.91l-11.5-1.26
      C6.6,19.7,6.36,19.9,6.33,20.18C6.3,20.45,6.5,20.7,6.77,20.73l11.5,1.26C18.34,22,18.42,22,18.49,22c1.02,0,1.9-0.77,2.02-1.81
      l1.26-11.5C21.83,8.15,21.67,7.63,21.33,7.2z"
    />
  </SvgIcon>
));

ImageStackIcon.displayName = 'ImageStackIcon';
