import * as React from 'react';
import { Form } from 'antd';

import { IOption } from '@components';
import { Select } from '@revfluence/fresh';

import { FormItemStatus } from '../../containers/Onboarding/OnboardingWizard/components/types';
import styles from './FormSelect.scss';

const { Option } = Select;
const { useState, memo } = React;

export interface IRule {
  required: boolean;
  message: string;
}

interface IProps {
  label: string;
  value: string | number,
  placeholder: string;
  options: IOption[];
  isRequired?: boolean;
  onFieldChange?: (value: string | number) => void;
  rules?: IRule[];
  suffixIcon?: React.ReactElement;
  className?: string;
  name: string;
  disabled?: boolean;
  validateTrigger?: string;
}

const FormSelect: React.FC<IProps> = ({
  label,
  value,
  placeholder,
  options = [],
  isRequired = false,
  onFieldChange,
  rules,
  suffixIcon,
  className,
  name,
  disabled = false,
  validateTrigger,
}: IProps) => {
  const [inputStatus, setInputStatus] = useState<FormItemStatus>('');

  const handleChange = (value: string) => {
    if (isRequired) {
      setInputStatus(value.length === 0 ? 'error' : '');
    }
    onFieldChange(value);
  };

  return (
    <Form.Item label={label} validateStatus={inputStatus} rules={rules} className={className} name={name} validateTrigger={validateTrigger}>
      <Select
        className={styles.FormSelect}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        suffixIcon={suffixIcon}
        options={options}
        defaultValue={value}
        disabled={disabled}
      >
        {options.map((option) => (
          <Option value={option.value} key={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const MemoizedFormSelect = memo(FormSelect);

export { MemoizedFormSelect as FormSelect };
