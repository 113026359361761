import * as React from 'react';

const { useMemo } = React;

type TProps = {
  icon: string;
};

const templateEnvelopSvg = require('@frontend/app/assets/svgs/template_envelope.svg');
const templateContractSvg = require('@frontend/app/assets/svgs/template_contract.svg');
const templateShoppingSvg = require('@frontend/app/assets/svgs/template_shopping.svg');
const templateUserRejectedSvg = require('@frontend/app/assets/svgs/template_user_rejected.svg');
const templateUserAcceptedSvg = require('@frontend/app/assets/svgs/template_user_accepted.svg');
const templatePackageSvg = require('@frontend/app/assets/svgs/template_package.svg');
const templateImageSvg = require('@frontend/app/assets/svgs/template_image.svg');
const templateFollowUpSvg = require('@frontend/app/assets/svgs/template_follow_up.svg');

const TemplateIcon = (props: TProps) => {
  const { icon } = props;

  const iconImage = useMemo(() => {
    switch (icon) {
      case 'envelope':
        return templateEnvelopSvg;
      case 'contract':
        return templateContractSvg;
      case 'shopping':
        return templateShoppingSvg;
      case 'user_rejected':
        return templateUserRejectedSvg;
      case 'user_accepted':
        return templateUserAcceptedSvg;
      case 'package':
        return templatePackageSvg;
      case 'image':
        return templateImageSvg;
      case 'follow_up':
        return templateFollowUpSvg;
      default:
        return templateEnvelopSvg;
    }
  }, [icon]);

  return (
    <img src={iconImage} />
  );
};

export default TemplateIcon;
