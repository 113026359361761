/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_19iks_157 {
  display: flex;
}

._justify-content-space-between_19iks_161 {
  justify-content: space-between;
}

._tabular-nums_19iks_165 {
  font-variant-numeric: tabular-nums;
}

._ContentPreviewer_19iks_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_19iks_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._ContentPreviewer_19iks_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._ContentPreviewer_19iks_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._ContentPreviewer_19iks_169 ::-webkit-scrollbar-track, ._ContentPreviewer_19iks_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._ContentPreviewer_19iks_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ContentPreviewer_19iks_169 {
  color: #fdfdfd;
  line-height: 14px;
}
._ContentPreviewer_19iks_169 ._gridView_19iks_286 {
  margin-bottom: -12px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 6px;
}
._ContentPreviewer_19iks_169 ._gridView_19iks_286 ._gridItem_19iks_296 {
  margin-right: 12px;
  margin-bottom: 12px;
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 0.5rem;
}
._ContentPreviewer_19iks_169 ._gridView_19iks_286 iframe::-webkit-scrollbar {
  display: none;
}

._CarouselOverlay_19iks_308 ._detailView_19iks_308 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 {
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._actionButton_19iks_321 {
  margin: 0 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  cursor: pointer;
  border-radius: 100px;
  color: #fdfdfd;
  background-color: rgba(253, 253, 253, 0.18);
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._actionButton_19iks_321._disabled_19iks_334 {
  opacity: 0.5;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._actionButton_19iks_321:hover {
  opacity: 0.8;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._actionButton_19iks_321:active {
  opacity: 1;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._actionButton_19iks_321 ._rightArrow_19iks_343 {
  transform: rotate(180deg);
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._itemWrapper_19iks_346 {
  height: 600px;
  width: 60vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._itemWrapper_19iks_346 ._item_19iks_346 {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 6px;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._itemWrapper_19iks_346 ._item_19iks_346._youtube_19iks_361 {
  cursor: pointer;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._itemWrapper_19iks_346 ._item_19iks_346._blog_19iks_364 {
  cursor: pointer;
  background-color: white;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._contentWrapper_19iks_314 ._itemWrapper_19iks_346 ._notSupportPreviewMsg_19iks_368 {
  color: #fdfdfd;
  font-size: larger;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._fileName_19iks_372 {
  margin-bottom: 40px;
  position: relative;
  width: 800px;
  text-align: center;
  color: #fdfdfd;
  font-size: 14px;
  font-weight: bold;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._fileName_19iks_372 ._downloadButton_19iks_381 {
  position: absolute;
  right: -60px;
  top: -10px;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._text_19iks_386 {
  margin-bottom: 40px;
  position: relative;
  width: 800px;
  text-align: center;
  color: #fdfdfd;
  font-size: 14px;
  font-weight: bold;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._indexList_19iks_395 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._indexList_19iks_395 ._indexItem_19iks_401 {
  margin: 0 10px;
  width: 22px;
  height: 4px;
  border-radius: 6px;
  background-color: #c6c4c8;
  will-change: background-color;
  transition: background-color 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._CarouselOverlay_19iks_308 ._detailView_19iks_308 ._indexList_19iks_395 ._indexItem_19iks_401._active_19iks_410 {
  background-color: #3996e0;
}