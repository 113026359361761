import 'css-chunk:src/components/common/FavoriteListButton.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "FavoriteListButton": "_FavoriteListButton_1qr17_79",
  "saveFavorite": "_saveFavorite_1qr17_82",
  "active": "_active_1qr17_87",
  "star-pulse": "_star-pulse_1qr17_1",
  "starBorder": "_starBorder_1qr17_90",
  "star": "_star_1qr17_90",
  "FavoriteListPopover": "_FavoriteListPopover_1qr17_132",
  "content": "_content_1qr17_132",
  "header": "_header_1qr17_136",
  "spinner": "_spinner_1qr17_147",
  "list": "_list_1qr17_151",
  "option": "_option_1qr17_155",
  "disabled": "_disabled_1qr17_169",
  "label": "_label_1qr17_173",
  "actions": "_actions_1qr17_182",
  "item": "_item_1qr17_188",
  "addList": "_addList_1qr17_197",
  "addIconWrapper": "_addIconWrapper_1qr17_216",
  "addIcon": "_addIcon_1qr17_216",
  "editList": "_editList_1qr17_232",
  "back": "_back_1qr17_235",
  "arrowIcon": "_arrowIcon_1qr17_256",
  "editContent": "_editContent_1qr17_261",
  "button": "_button_1qr17_267"
};