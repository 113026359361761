import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_COMMUNITY_BY_ID } from '@frontend/app/queries/CommunityByIdQuery';
import {
  CommunityByIdQuery,
  CommunityByIdQueryVariables,
} from '@frontend/app/queries/types/CommunityByIdQuery';

type IOptions = QueryHookOptions<CommunityByIdQuery, CommunityByIdQueryVariables>;

export const useCommunityByIdQuery = (communityId: number, options: IOptions = {}) => useQuery<CommunityByIdQuery, CommunityByIdQueryVariables>(GET_COMMUNITY_BY_ID, {
    ...options,
    variables: {
      id: communityId,
    },
    skip: !communityId || options.skip,
  });
