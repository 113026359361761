import { MutationHookOptions } from '@apollo/client';

import { useMutation } from '@frontend/app/hooks';
import { BULK_MEMBER_APPLICANT_OPERATION_MUTATION } from '@frontend/app/queries';
import {
  BulkMemberApplicantOperationMutation,
  BulkMemberApplicantOperationMutationVariables,
} from '@frontend/app/queries/types/BulkMemberApplicantOperationMutation';

type IOptions = MutationHookOptions<BulkMemberApplicantOperationMutation, BulkMemberApplicantOperationMutationVariables>;

export const useBulkMemberApplicantOperationMutation = (options: IOptions = {}) => (
  useMutation<BulkMemberApplicantOperationMutation, BulkMemberApplicantOperationMutationVariables>(
    BULK_MEMBER_APPLICANT_OPERATION_MUTATION,
    options,
  )
);
