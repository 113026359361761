import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_SHOPIFY_COLLECTIONS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/shopifyCollections';
import { GetShopifyCollections, GetShopifyCollectionsVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetShopifyCollections';

type IOptions = QueryHookOptions<GetShopifyCollections, GetShopifyCollectionsVariables>;

export const useGetShopifyCollections = (options: IOptions) => {
  const {
 loading, data, error, refetch, fetchMore,
} = useQuery<GetShopifyCollections, GetShopifyCollectionsVariables>(GET_SHOPIFY_COLLECTIONS_QUERY, options);

  return {
    loading,
    collections: data?.getShopifyCollections?.collections ?? [],
    pageInfo: data?.getShopifyCollections?.pageInfo ?? { hasNextPage: false, endCursor: '', __typename: 'PageInfoType' },
    error,
    refetch,
    fetchMore,
  };
};
