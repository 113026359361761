/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._LinkCell_x5wii_79 {
  user-select: none;
  cursor: pointer;
  font-weight: 400;
  color: #3996e0;
  will-change: color;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
}
._LinkCell_x5wii_79:hover {
  color: #4fa7ee;
}
._LinkCell_x5wii_79._useAnchor_x5wii_90 {
  user-select: all;
}
._LinkCell_x5wii_79._useAnchor_x5wii_90 a {
  color: #3996e0;
  text-decoration: none;
  will-change: color;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
}
._LinkCell_x5wii_79._useAnchor_x5wii_90 a:hover {
  color: #4fa7ee;
}