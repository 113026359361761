import * as React from 'react';
import { isFunction } from 'lodash';

import { logger } from '@common';
import {
  ConnectedImageVideoUpload,
  Collapse,
  CollapsePanel,
  ColorPicker,
} from '@frontend/app/components';

import { Select } from '@revfluence/fresh';

import {
  useCopyToClipboard,
  useProjectByIdQuery,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';

import { TTemplateProps } from '../../types';
import {
  TransparentColorPicker,
  PageURLInput,
} from '../FormComponents';
import { useUploadProps } from '../hooks/useUploadProps';
import { bodyFontFamilyOptions, ctaFontFamilyOptions, headlineFontFamilyOptions } from '../../constants/fonts';
import { IMAGES_HINTS } from './imagesHints';

import styles from './Settings.scss';
import { ApplicationPageBuilderComponent, isBuilderComponentUsedByTemplate, ProjectApplicationPageTemplateName } from '../../../applicationPageUtils';

const {
 useCallback, useMemo, useRef, useState,
} = React;

const headingFontSizeOptions = [
  { value: 24, label: '24' },
  { value: 32, label: '32' },
  { value: 36, label: '36' },
  { value: 40, label: '40' },
  { value: 48, label: '48' },
  { value: 64, label: '64' },
];

const bodyFontSizeOptions = [
  { value: 14, label: '14' },
  { value: 16, label: '16' },
  { value: 18, label: '18' },
  { value: 24, label: '24' },
  { value: 28, label: '28' },
  { value: 32, label: '32' },
];

const defaultHeadingFontSize = { value: 48, label: '48' };
const defaultBodyFontSize = { value: 18, label: '18' };
const defaultFormColor = '#fdfdfd';

type TSettingsProps = TTemplateProps['settings'];

interface IProps {
  projectId: number;
  template?: ProjectApplicationPageTemplateName,
  settingsProps?: TSettingsProps;
  onChange?(values: TSettingsProps);
  onChangeCustomPath?(path: string);
  clientId?: string;
  clientHostname?: string;
  parent?: {
    current?: HTMLDivElement,
  }
}

export const Settings: React.FC<IProps> = (props) => {
  const [isSavingCustomPath, setIsSavingCustomPath] = useState(false);
  const panelsRef = useRef([]);

  const {
    projectId,
    settingsProps,
    onChange,
    onChangeCustomPath,
    clientId,
    clientHostname,
    parent,
  } = props;

  const {
    data: {
      project = undefined,
    } = {},
  } = useProjectByIdQuery({
    variables: {
      id: projectId || undefined,
    },
    skip: !projectId,
  });

  const baseUrl = useMemo(() => {
    const url = `${window?.location?.origin}/join/`;
    return clientHostname ? url.replace('community', clientHostname) : url;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, clientHostname]);

  const fullUrl = useMemo(() => {
    const landingPagePath = encodeURIComponent(project?.customLandingPagePath || '');
    const url = `${baseUrl}${landingPagePath}`;
    return clientHostname ? url : `${url}?clientId=${clientId}`;
  }, [baseUrl, clientId, clientHostname, project]);

  const {
    showSuccessMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  const { copyToClipboard } = useCopyToClipboard();

  const uploadProps = useUploadProps(projectId);

  const handleChangeSettings = useCallback((values: Partial<TSettingsProps>) => {
    if (onChange) {
      onChange({ ...settingsProps, ...values });
    }
  }, [settingsProps, onChange]);

  const handleChangePageURL = useCallback((url: string) => {
    setIsSavingCustomPath(true);

    const pagePath = url.replace(baseUrl, '');
    handleChangeSettings({ url });

    if (isFunction(onChangeCustomPath)) {
      onChangeCustomPath(pagePath)
        .then(() => {
          setIsSavingCustomPath(false);
        })
        .catch(logger.error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChangeSettings]);

  const handleChangeFavicon = useCallback((favicon: string) => {
    handleChangeSettings({ favicon });
  }, [handleChangeSettings]);

  const handleChangeLogo = useCallback((logo: string) => {
    handleChangeSettings({ logo });
  }, [handleChangeSettings]);

  const handleChangePageColor = useCallback((color: string) => {
    handleChangeSettings({ page_color: { background_color: color } });
  }, [handleChangeSettings]);

  const handleChangeFormColor = useCallback((color: string, transparent: boolean) => {
    handleChangeSettings({ form_color: { background_color: color, is_transparent: transparent } });
  }, [handleChangeSettings]);

  const handleChangeHeadlineFillColor = useCallback((color: string) => {
    handleChangeSettings({
      headline_styling: {
        ...settingsProps?.headline_styling,
        fill_color: color,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeHeadlineFontSize = useCallback((fontSize: number) => {
    handleChangeSettings({
      headline_styling: {
        ...settingsProps?.headline_styling,
        font_size: fontSize,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeHeadlineFontFamily = useCallback((fontFamily: string) => {
    handleChangeSettings({
      headline_styling: {
        ...settingsProps?.headline_styling,
        font_family: fontFamily,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeBodyFillColor = useCallback((color: string) => {
    handleChangeSettings({
      body_styling: {
        ...settingsProps?.body_styling,
        fill_color: color,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeBodyFontSize = useCallback((fontSize: number) => {
    handleChangeSettings({
      body_styling: {
        ...settingsProps?.body_styling,
        font_size: fontSize,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeBodyFontFamily = useCallback((fontFamily: string) => {
    handleChangeSettings({
      body_styling: {
        ...settingsProps?.body_styling,
        font_family: fontFamily,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeCTAButtonColor = useCallback((buttonColor: string) => {
    handleChangeSettings({
      cta_styling: {
        ...settingsProps?.cta_styling,
        button_color: buttonColor,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeCTAFontFamily = useCallback((fontFamily: string) => {
    handleChangeSettings({
      cta_styling: {
        ...settingsProps?.cta_styling,
        font_family: fontFamily,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleChangeCTAFontColor = useCallback((fontColor: string) => {
    handleChangeSettings({
      cta_styling: {
        ...settingsProps?.cta_styling,
        font_color: fontColor,
      },
    });
  }, [handleChangeSettings, settingsProps]);

  const handleCollapseOpen = (key: string | number) => {
    const el = panelsRef.current?.[key];

    if (el) {
      const { offsetTop } = el;
      const {
        current: parentRef,
      } = parent;

      const antdContainer = parentRef.querySelector('.ant-tabs-content-holder');

      if (antdContainer) {
        /** We need to wait to animation to finish */
        setTimeout(() => {
          antdContainer.scrollTo({
            behavior: 'smooth',
            top: offsetTop - 50,
          });
        }, 250);
      }
    }
  };

  const handleCopyLink = useCallback(() => {
    copyToClipboard({
      value: fullUrl,
      onSuccess: () => {
        showSuccessMessage('Link copied!');
      },
      onFailure: () => {
        showGenericErrorMessage();
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    copyToClipboard,
    fullUrl,
    project,
    clientId,
  ]);

  return (
    <div className={styles.Settings}>
      <div className={styles.PageURLContainer}>
        <div className={styles.subtitle}>Page URL</div>
        <PageURLInput
          baseUrl={baseUrl}
          initialLandingPagePath={project?.customLandingPagePath}
          fullUrl={fullUrl}
          onChange={handleChangePageURL}
          onCopyLink={handleCopyLink}
          projectSubmittedForReview={project?.submittedForReview}
          isLoading={isSavingCustomPath}
        />
      </div>
      {
        isBuilderComponentUsedByTemplate(ApplicationPageBuilderComponent.SettingsFavicon, props.template)
          && (
            <div className={styles.row}>
              <div className={styles.subtitle}>Favicon</div>
              <ConnectedImageVideoUpload
                defaultImageSrc={settingsProps?.favicon}
                uploadProps={uploadProps}
                acceptedLabel="Only .png and .ico files are allowed."
                accept="image/png, image/x-icon"
                acceptedTypes={[]}
                onUpload={handleChangeFavicon}
                onRemove={handleChangeFavicon.bind(this, undefined)}
                hintText={IMAGES_HINTS.FAVICON}
                hideMaxSizeNote
                display="inline"
              />
            </div>
          )
      }
      <div className={styles.row}>
        <div className={styles.subtitle}>Logo</div>
        <ConnectedImageVideoUpload
          defaultImageSrc={settingsProps?.logo}
          uploadProps={uploadProps}
          onUpload={handleChangeLogo}
          onRemove={handleChangeLogo.bind(this, undefined)}
          hintText={IMAGES_HINTS.LOGO}
          hideMaxSizeNote
          display="inline"
        />
      </div>
      {
        isBuilderComponentUsedByTemplate(ApplicationPageBuilderComponent.SettingsSimplifiedFonts, props.template)
          && (
            <div className={styles.row}>
              <div className={styles.subrow}>
                <div className={styles.subtitle}>Headline Text Font</div>
                <div>
                  <Select
                    defaultValue={headlineFontFamilyOptions[0].value}
                    value={
                          settingsProps?.headline_styling?.font_family
                            || headlineFontFamilyOptions[0].value
                        }
                    options={headlineFontFamilyOptions}
                    className={styles.select}
                    onChange={handleChangeHeadlineFontFamily}
                  />
                </div>
              </div>
              <div className={styles.subrow}>
                <div className={styles.subtitle}>Body Text Font</div>
                <div>
                  <Select
                    defaultValue={bodyFontFamilyOptions[0].value}
                    value={
                      settingsProps?.body_styling?.font_family
                        || bodyFontFamilyOptions[0].value
                    }
                    options={bodyFontFamilyOptions}
                    className={styles.select}
                    onChange={handleChangeBodyFontFamily}
                  />
                </div>
              </div>
            </div>
          )
      }
      {
        isBuilderComponentUsedByTemplate(ApplicationPageBuilderComponent.SettingsAccordions, props.template)
          && (
            <div className={styles.row}>
              <Collapse
                onOpen={handleCollapseOpen}
              >
                <div ref={(el) => panelsRef.current['1'] = el} />
                <CollapsePanel header="Page Color" key="1">
                  <div className={styles.subtitle}>Background Color</div>
                  <ColorPicker
                    color={settingsProps?.page_color?.background_color}
                    defaultColor="#000000"
                    onChange={handleChangePageColor}
                  />
                </CollapsePanel>
                <div ref={(el) => panelsRef.current['2'] = el} />
                <CollapsePanel header="Form Color" key="2">
                  <div className={styles.subtitle}>Background Color</div>
                  <TransparentColorPicker
                    defaultColor={defaultFormColor}
                    value={{
                      color: settingsProps?.form_color?.background_color || defaultFormColor,
                      transparent: settingsProps?.form_color?.is_transparent,
                    }}
                    onChange={handleChangeFormColor}
                    hasTransparent
                  />
                </CollapsePanel>
                <div ref={(el) => panelsRef.current['3'] = el} />
                <CollapsePanel header="Headline Styling" key="3">
                  <div>
                    <div className={styles.subtitle}>Font family</div>
                    <Select
                      defaultValue={headlineFontFamilyOptions[0].value}
                      value={
                        settingsProps?.headline_styling?.font_family
                          || headlineFontFamilyOptions[0].value
                      }
                      options={headlineFontFamilyOptions}
                      className={styles.select}
                      onChange={handleChangeHeadlineFontFamily}
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.subtitle}>Font size</div>
                    <Select
                      defaultValue={defaultHeadingFontSize.value}
                      value={
                          settingsProps?.headline_styling?.font_size
                            || defaultHeadingFontSize.value
                      }
                      options={headingFontSizeOptions}
                      className={styles.select}
                      onChange={handleChangeHeadlineFontSize}
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.subtitle}>Font color</div>
                    <ColorPicker
                      onChange={handleChangeHeadlineFillColor}
                      defaultColor="#FDFDFD"
                      color={
                        settingsProps?.headline_styling?.fill_color
                          || '#FDFDFD'
                      }
                    />
                  </div>
                </CollapsePanel>
                <div ref={(el) => panelsRef.current['4'] = el} />
                <CollapsePanel header="Body Text Styling" key="4">
                  <div>
                    <div className={styles.subtitle}>Font family</div>
                    <Select
                      defaultValue={bodyFontFamilyOptions[0].value}
                      value={
                        settingsProps?.body_styling?.font_family
                          || bodyFontFamilyOptions[0].value
                      }
                      options={bodyFontFamilyOptions}
                      className={styles.select}
                      onChange={handleChangeBodyFontFamily}
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.subtitle}>Font size</div>
                    <Select
                      defaultValue={defaultBodyFontSize.value}
                      value={
                        settingsProps?.body_styling?.font_size
                          || defaultBodyFontSize.value
                      }
                      options={bodyFontSizeOptions}
                      className={styles.select}
                      onChange={handleChangeBodyFontSize}
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.subtitle}>Font color</div>
                    <ColorPicker
                      onChange={handleChangeBodyFillColor}
                      defaultColor="#FDFDFD"
                      color={
                        settingsProps?.body_styling?.fill_color
                          || '#FDFDFD'
                      }
                    />
                  </div>
                </CollapsePanel>
                <div ref={(el) => panelsRef.current['5'] = el} />
                <CollapsePanel header="CTA Styling" key="5">
                  <div>
                    <div className={styles.subtitle}>Button color</div>
                    <ColorPicker
                      onChange={handleChangeCTAButtonColor}
                      defaultColor="#1a1818"
                      color={
                        settingsProps?.cta_styling?.button_color
                          || '#1a1818'
                      }
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.subtitle}>Font family</div>
                    <Select
                      defaultValue={ctaFontFamilyOptions[0].value}
                      value={
                        settingsProps?.cta_styling?.font_family
                          || ctaFontFamilyOptions[0].value
                      }
                      options={ctaFontFamilyOptions}
                      className={styles.select}
                      onChange={handleChangeCTAFontFamily}
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.subtitle}>Font color</div>
                    <ColorPicker
                      onChange={handleChangeCTAFontColor}
                      defaultColor="#FDFDFD"
                      color={
                        settingsProps?.cta_styling?.font_color
                          || '#FDFDFD'
                      }
                    />
                  </div>
                </CollapsePanel>
              </Collapse>
            </div>
          )
      }

    </div>
  );
};
