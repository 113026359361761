import * as React from 'react';
import { useIsTextTruncated } from '@frontend/app/hooks/common/useIsTruncated';
import { Tooltip } from '@revfluence/fresh';

const { useMemo, useRef } = React;

export interface TruncatedTextProps {
  className?: string;
  children: React.ReactNode;
}

const styles: React.CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const TruncatedText = React.memo(({ className, children }: TruncatedTextProps) => {
  const ref = useRef<HTMLDivElement>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const textContent = useMemo(() => ref?.current?.innerText || '', [ref?.current?.innerText]);
  const isTruncated = useIsTextTruncated(ref);

  return (
    <Tooltip title={isTruncated ? textContent : ''} placement="left">
      <div className={className} style={styles} ref={ref}>
        {children}
      </div>
    </Tooltip>
  );
});

TruncatedText.displayName = 'TruncatedText';
