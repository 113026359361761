/* eslint-disable import/no-named-as-default */
/* eslint-disable react/require-default-props */
/* eslint-disable react/destructuring-assignment */
import { filter, size, toNumber } from 'lodash';
import cx from 'classnames';

import * as React from 'react';

import { LoadSpinner } from '@components';
import {
  useClientFeatureEnabled,
  useGetAllProjectsQuery,
} from '@frontend/app/hooks';
import Title from '@frontend/app/components/Title/Title';

import { useLocation } from 'react-router-dom';

import { ClientFeature } from '@frontend/app/constants';
import { ProgramStatusType } from '@services/communities/types';
import styles from './TermsGuidelinesSettings.scss';
import TermsGuidelinesSettingsEmpty from './TermsGuidelinesSettingsEmpty';
import TermsGuidelinesSettingsNotEmpty from './TermsGuidelinesSettingsNotEmpty';

interface IProps {
  className?: string;
  title?: string;
}

const { useMemo } = React;

export const TermsGuidelinesSettings: React.FC<IProps> = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = toNumber(searchParams.get('projectId'));
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);

  const {
    data: {
      projects = undefined,
    } = {},
    loading,
  } = useGetAllProjectsQuery();

  const activeProjects = useMemo(() => {
    if (isArchiveProjectEnabled) {
      return filter(projects, (project) => project.status === ProgramStatusType.ACTIVE);
    }
    return projects;
  }, [
    projects,
    isArchiveProjectEnabled,
  ]);

  return (
    <>
      { props.title && <Title title={props.title} />}
      <div className={cx(styles.TermsGuidelinesSettings, props.className)}>
        { loading && <LoadSpinner /> }
        { !loading && size(activeProjects) === 0 && <TermsGuidelinesSettingsEmpty /> }
        { !loading && size(activeProjects) > 0 && (
          <TermsGuidelinesSettingsNotEmpty
            projects={activeProjects}
            projectId={projectId}
          />
        )}
      </div>
    </>
  );
};
