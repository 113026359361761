import { IContentRequirement } from './contentRequirement';
import { IContentReview } from './contentReview';
import { IDeliverable } from './deliverable';
import { IPostProjectProposal } from './postProjectProposal';
import { IProductShipment } from './productShipment';

export interface IPostProject {
  has_agreement: boolean;
  has_terms: boolean;
  campaign_id: number;
  use_legal_contracts: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  latest_posts: any[];
  active: boolean;
  campaign_networks: string[];
  stage: IProjectStage;
  campaign_name: string;
  post_count: number;
  proposal: IPostProjectProposal;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  summary_overrides?: any;
  can_complete: boolean;
  relation_id: string;
  managed: boolean;
  has_proposal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject_details?: any;
  proposed_by_buyer: boolean;
  has_product_choices: boolean;
  has_viewed_macromeasure_report: boolean;
  tools: ITool[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  past_collab_summary: Record<string, any>;
  complete: boolean;
  has_collaboration: boolean;
  name: string;
  requirements: IContentRequirement[];
  hide_autocomplete_option: boolean;
  content_requires_approval: boolean;
  has_viewed_proposal: boolean;
  brand_name: string;
  brand_image: string;
  brand_instagram_username: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delivered_ts?: any;
  project_type: string;
  verified_content_count: number;
  negotiation_closed: boolean;
  created_at: number;
  id: number;
  campaign_products: string[];
}

interface ITool {
  active: boolean;
  summaries: ISummary[];
  total_payment?: number;
  pending_payment?: number;
  type: string;
  expected_payment?: number;
  amount_owed?: number;
  requested_payment?: number;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payment_requests?: any[];
  can_request_payment?: boolean;
  latest_iteration_id?: number;
  iteration_diff?: IIterationDiff;
  agreement?: IAgreement;
  latest_iteration?: IIterationDiff;
  date_agreed_upon?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  review?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackers?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product_choices?: any;
  product_shipment?: IProductShipment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  posts?: any[];
  content_reviews?: IContentReview[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submission_disabled_until_ts?: any;
}

interface IAgreement {
  latest_iteration_id: number;
  date_agreed_upon: number;
}

interface IIterationDiff {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target_ts?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payment_terms?: any;
  require_content_rights: boolean;
  sender_image_url: string;
  has_segmented_guidelines: boolean;
  can_edit: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message?: any;
  products: IDeliverable[];
  exclusivity_language: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  negotiation_message?: any;
  target_date: string;
  agreed_date: number;
  invalid: boolean;
  can_agree: boolean;
  requires_approval: boolean;
  latest_iteration_id: number;
  project_id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content_guidelines: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  caption?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brand_requirements: any[];
  require_exclusivity: boolean;
  sent_by_supplier: boolean;
  approval_terms_agreed: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post_bundles: any[];
  content_rights_language: string;
  price: number;
  is_agreed: boolean;
  creator_requirements: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brief_url?: any;
  created_ts: number;
  can_be_modified: boolean;
  id: number;
}

interface ISummary {
  tool: string;
  campaign_id: number;
  identifier: string;
  description: string;
  alert: string;
  project_id: number;
  target_state: string;
  quiet_alert: string;
  can_skip: string;
  alert_text: string;
  complete: string;
  primary_cta: string;
}

export interface IProjectStage {
  priority: number;
  project_id: number;
  campaign_id: number;
  display_name: string;
  can_complete: string;
  stage_identifier: string;
  identifier: string;
  active_tool_identifier: string;
  tool_identifier: string;
}
