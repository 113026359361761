import gql from 'graphql-tag';

export const GET_KLAVIYO_SYNC_STATUS = gql`
  query GetKlaviyoSyncStatus($id: Float!) {
    getKlaviyoSyncStatus(id: $id) {
      id
      name
      completeItems
      failedItems
      createdDate
      totalItems
      status
    }
  }
`;
