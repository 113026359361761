import * as React from 'react';
import { Alert } from '@revfluence/fresh';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';

import { SocialColumnKey } from '../../types/Member';

interface IProps {
  column: SocialColumnKey;
}

export const MissingSocialTag: React.FC<IProps> = React.memo((props) => {
  const { column } = props;
  let label: React.ReactNode;
  switch (column) {
    case 'blog':
      label = 'Blog';
      break;
    case 'facebook':
      label = 'Facebook';
      break;
    case 'instagram':
      label = 'Instagram';
      break;
    case 'pinterest':
      label = 'Pinterest';
      break;
    case 'snapchat':
      label = 'Snapchat';
      break;
    case 'tiktok':
      label = 'TikTok';
      break;
    case 'twitter':
      label = 'Twitter';
      break;
    case 'youtube':
      label = 'YouTube';
      break;
    default:
      break;
  }

  if (!label) {
    return null;
  }

  return (
    <Alert
      closable={false}
      icon={<CircleExclamationIcon />}
      message={`No ${label}`}
      showIcon
      type="warning"
    />
  );
});
MissingSocialTag.displayName = 'MissingSocialTag';
