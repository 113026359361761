import * as React from 'react';
import {
 Avatar, Typography, Tooltip, Button,
} from '@revfluence/fresh';
import { fromUnixTime, format } from 'date-fns';
import styles from './BriefDueItem.scss';

const { Text } = Typography;
interface IProps {
  title: string;
  createdDate: number;
  briefAgreedDate: number;
  briefAgreedAmount: number;
  briefAgreedDue?: number;
  splashImageUrl?: string;
  onClick?: () => void;
}

export const BriefDueItem: React.FC<IProps> = (props) => {
  const {
    title, createdDate, briefAgreedDate, briefAgreedAmount, splashImageUrl, briefAgreedDue, onClick,
  } = props;
  return (
    <div className={styles.termContainer}>
      <div className={styles.programInfo}>
        {splashImageUrl && (
          <Avatar src={splashImageUrl} className={styles.programIcon} />
        )}
        <div className={styles.programName}>
          <Tooltip title={title}>
            <Text>{title.length > 12 ? `${title.slice(0, 12)}...` : title}</Text>
          </Tooltip>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <Text className={styles.fieldHeading}>Brief Sent On</Text>
        <Text className={styles.createdDate}>
          {createdDate ? format(fromUnixTime(createdDate), 'MMMM d, yyyy') : ''}
        </Text>
      </div>
      <div className={styles.fieldContainer}>
        <Text className={styles.fieldHeading}>Brief Status</Text>
        <BriefStatus timeStamp={briefAgreedDate} />
      </div>
      <div className={styles.fieldContainer}>
        <Text className={styles.fieldHeading}>Brief Agreed</Text>
        <Text className={styles.amount}>
          $
          {briefAgreedAmount}
        </Text>
      </div>

      <div className={styles.fieldContainer}>
        <Text className={styles.fieldHeading}>Brief Due</Text>
        <Text className={styles.amount}>
          $
          {briefAgreedDue}
        </Text>
      </div>
      <Button className={`${styles.payButton} ${(briefAgreedDue > 0) ? styles.showPayButton : ''}`} type="primary" onClick={onClick}>Pay</Button>
    </div>
  );
};

const BriefStatus = ({ timeStamp }: { timeStamp?: number }) => (
  <div className={styles.briefStatusContainer}>
    {/* <EyeIcon /> */}
    <Text className={styles.agreedDate}>
      {timeStamp ? `Agreed on ${format(fromUnixTime(timeStamp), 'MMMM d, yyyy')}` : ''}
    </Text>
  </div>
);
