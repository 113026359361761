import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_IMPORTER_TASK_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import {
  CreateImporterTask,
  CreateImporterTaskVariables,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/CreateImporterTask';

type IOptions = MutationHookOptions<CreateImporterTask, CreateImporterTaskVariables>;

export const useCreateImporterTask = (options: IOptions = {}) => {
  const [mutate, { loading }] = useMutation<CreateImporterTask, CreateImporterTaskVariables>(CREATE_IMPORTER_TASK_MUTATION, options);

  return {
    mutate,
    loading,
  };
};
