import 'css-chunk:src/components/widgets/SocialProfile/Content/PlaceholderContent.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_lke2w_157",
  "justify-content-space-between": "_justify-content-space-between_lke2w_161",
  "tabular-nums": "_tabular-nums_lke2w_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_lke2w_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_lke2w_166",
  "Card": "_Card_lke2w_170",
  "Content": "_Content_lke2w_170",
  "Placeholder": "_Placeholder_lke2w_209",
  "summary": "_summary_lke2w_216",
  "analyze": "_analyze_lke2w_217",
  "show": "_show_lke2w_1",
  "blog": "_blog_lke2w_224",
  "facebook": "_facebook_lke2w_224",
  "twitter": "_twitter_lke2w_224",
  "ProfileCard": "_ProfileCard_lke2w_228",
  "SummaryCard": "_SummaryCard_lke2w_232",
  "instagram": "_instagram_lke2w_241",
  "EngagementRateCard": "_EngagementRateCard_lke2w_249",
  "GenderCard": "_GenderCard_lke2w_261",
  "AgeCard": "_AgeCard_lke2w_265",
  "CountryCard": "_CountryCard_lke2w_269",
  "CityCard": "_CityCard_lke2w_273",
  "PostPerformanceCard": "_PostPerformanceCard_lke2w_277",
  "GrowthCard": "_GrowthCard_lke2w_281",
  "pinterest": "_pinterest_lke2w_285",
  "youtube": "_youtube_lke2w_301",
  "detailList": "_detailList_lke2w_313",
  "HashtagsCard": "_HashtagsCard_lke2w_316",
  "TopCategoriesCard": "_TopCategoriesCard_lke2w_320",
  "TopPlaylistsCard": "_TopPlaylistsCard_lke2w_324",
  "items": "_items_lke2w_332",
  "item": "_item_lke2w_332",
  "StateCard": "_StateCard_lke2w_359",
  "tiktok": "_tiktok_lke2w_363",
  "tooltip": "_tooltip_lke2w_697",
  "cardHeader": "_cardHeader_lke2w_711",
  "tabs": "_tabs_lke2w_722",
  "tabButton": "_tabButton_lke2w_726",
  "activeTab": "_activeTab_lke2w_745",
  "separator": "_separator_lke2w_749",
  "cardTab": "_cardTab_lke2w_756",
  "cardTitle": "_cardTitle_lke2w_764",
  "visible": "_visible_lke2w_772",
  "noData": "_noData_lke2w_777",
  "title": "_title_lke2w_787",
  "PapaCard": "_PapaCard_lke2w_787",
  "posts": "_posts_lke2w_787",
  "post": "_post_lke2w_787",
  "accountInfo": "_accountInfo_lke2w_787",
  "details": "_details_lke2w_787",
  "name2": "_name2_lke2w_787",
  "name": "_name_lke2w_787",
  "image": "_image_lke2w_787",
  "MamaCard": "_MamaCard_lke2w_853",
  "BabyCard": "_BabyCard_lke2w_857"
};