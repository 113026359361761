import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';

import { Input } from '@components';

import styles from './RangeSlider.scss';

const { useMemo } = React;

export interface IRangeInputProps {
  className?: string;
  formatStr?: string;
  max?: number;
  onChange(value: string);
  showPlusOnMaxRange?: boolean;
  value: number;
  disabled?: boolean;
}

export const RangeInput: React.FC<IRangeInputProps> = (props) => {
  const {
    className,
    formatStr: formatStrProp,
    max,
    onChange,
    showPlusOnMaxRange,
    value,
    disabled,
  } = props;
  const formatStr = formatStrProp || '0,0[.][00]';

  const formattedValue = useMemo(() => {
    const plusSuffix = (showPlusOnMaxRange && value === max) ? '+' : '';
    return numeral(value).format(formatStr) + plusSuffix;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, showPlusOnMaxRange]);

  return (
    <Input
      className={cx(styles.Input, className)}
      value={formattedValue}
      triggerChangeOnBlur
      onChange={onChange}
      disabled={disabled}
    />
  );
};

RangeInput.defaultProps = {
  showPlusOnMaxRange: false,
};
RangeInput.displayName = 'RangeInput';
