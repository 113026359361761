import React, { useEffect, useState } from 'react';
import { BudgetGranularity } from '@frontend/app/types/Budget';
import {
 Menu, Dropdown, Button,
} from '@revfluence/fresh';
import { EllipsisVerticalIcon, CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './FiscalYearAdditionalConfig.scss';

const { SubMenu } = Menu;

const FiscalYearAdditionalConfig = ({
  fiscalYearBudget,
  handleFiscalYearBudgetChange,
  budgetAccountDistribution,
  parentBudgetId,
}) => {
  const parentBudget = budgetAccountDistribution?.find((budget) => budget.budgetId == parentBudgetId);
  const parentFiscalYearBudget = parentBudget?.fiscalYearsBudget?.find(
    (fiscalYear) => fiscalYear.fiscalYear == fiscalYearBudget.fiscalYear,
  );
  const parentFiscalBudgetGranularity = parentFiscalYearBudget?.granularity;

  const [selectedKey, setSelectedKey] = useState(() => {
    if (fiscalYearBudget?.granularity) return fiscalYearBudget.granularity;
    if (parentFiscalBudgetGranularity) {
      return parentFiscalBudgetGranularity;
    }
    return BudgetGranularity.YEARLY;
  });

  useEffect(() => {
    if (fiscalYearBudget?.granularity) setSelectedKey(fiscalYearBudget.granularity);
    // if (parentFiscalBudgetGranularity) setSelectedKey(parentFiscalBudgetGranularity);
  }, [fiscalYearBudget.granularity, parentFiscalBudgetGranularity]);

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
    if (e.key === 'isArchived') {
      handleFiscalYearBudgetChange({ ...fiscalYearBudget, isArchived: !fiscalYearBudget.isArchived });
    } else {
      handleFiscalYearBudgetChange({ ...fiscalYearBudget, granularity: e.key });
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]}>
      <SubMenu title={<div>Split</div>} className={styles.subMenu}>
        {(!parentBudget || parentFiscalBudgetGranularity === BudgetGranularity.YEARLY) && (
          <Menu.Item
            key={BudgetGranularity.YEARLY}
            icon={selectedKey === BudgetGranularity.YEARLY ? <CheckIcon /> : null}
          >
            Yearly
          </Menu.Item>
        )}
        {(!parentBudget
          || parentFiscalBudgetGranularity === BudgetGranularity.YEARLY
            || parentFiscalBudgetGranularity === BudgetGranularity.QUARTERLY) && (
            <Menu.Item
              key={BudgetGranularity.QUARTERLY}
              icon={selectedKey === BudgetGranularity.QUARTERLY ? <CheckIcon /> : null}
            >
              Quarterly Budget Breakdown
            </Menu.Item>
        )}
      </SubMenu>
      {/* <Menu.Item key="isArchived">
        {'  '}
        <Text className={styles.archiveFiscalYear}>{fiscalYearBudget?.isArchived ? 'Unarchive Budget' : 'Archive Budget'}</Text>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={(<EllipsisVerticalIcon />)} style={{ width: '40px' }} />
    </Dropdown>
  );
};

export default FiscalYearAdditionalConfig;

FiscalYearAdditionalConfig.displayName = 'FiscalYearAdditionalConfig';
