import React, {
  useState,
} from 'react';
import { LoadSpinner } from '@components';
import { Pagination } from '@frontend/app/components';
import { isEmpty } from 'lodash';
import {
  Col, Row, Space, Table,
} from '@revfluence/fresh';
import { BoltIcon } from '@revfluence/fresh-icons/regular/esm';
import { map } from 'lodash';
import { useListExecutions } from '@frontend/app/hooks/automations';
import { TABLE_PAGE_SIZE } from '@frontend/app/constants';
import { AutomationVariableType } from '@frontend/app/types/globalTypes';
import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';
import {
  useGetMembersByIdsQuery,
} from '@frontend/app/hooks';
import { ListExecutions_listExecutions_results_automation } from '@frontend/app/queries/types/ListExecutions';
import { useTerminateAutomationExecutionMutation } from '@frontend/app/hooks/automations';
import { useAutomationAuditTableColumns } from '.';
import styles from './AutomationAuditTable.scss';
import { AUTOMATION_EXECUTION_ENDED_STATUSES } from './AutomationAuditTableTypes';

interface AuditTableProps {
  projectId?: number;
}

interface AuditTable {
  id: string;
  key: string;
  automation: ListExecutions_listExecutions_results_automation;
  member: GetMemberQuery_member;
  memberId: string;
  status: string;
  createdDate: string;
  updatedDate: string;
}

export const AutomationAuditTable = (props: AuditTableProps) => {
  const { projectId } = props;
  const [page, setPage] = useState(1);
  const memberIds = [];
  const dataSource: AuditTable[] = [];
  const [terminateAutomationExecution] = useTerminateAutomationExecutionMutation();

  const {
    loading: loadingExecutions,
    refetch: refetchExecutions,
    data: {
      listExecutions: {
        results: executions = [],
        paging,
      },
    } = {
      listExecutions: {
        results: [],
      },
    },
  } = useListExecutions(
    {
      variables: {
        paging: {
          offset: (page - 1) * TABLE_PAGE_SIZE,
          limit: TABLE_PAGE_SIZE,
        },
        filters: {
          automation: {
            program: {
            id: projectId,
            },
          },
        },
      },
      fetchPolicy: 'no-cache',
    },
  );

  for (const execution of executions) {
    const member = execution?.variables?.find((variable) => variable.type === AutomationVariableType.MEMBER);
    memberIds.push(parseInt(member?.value, 10));
    dataSource.push({
      automation: execution.automation,
      id: execution.id,
      key: execution.id,
      memberId: member?.value,
      member: null, // Empty for now as we need to fetch member details
      status: execution.status,
      createdDate: execution.createdDate,
      updatedDate: AUTOMATION_EXECUTION_ENDED_STATUSES.includes(execution.status) ? execution.updatedDate : null,
    });
  }

  const {
    loading: loadingMembersByIds,
    data: { members: membersByIds = [] } = {},
  } = useGetMembersByIdsQuery(
    memberIds,
    {
      skip: !!loadingExecutions || !memberIds.length,
      fetchPolicy: 'no-cache',
    },
  );

  dataSource.forEach((execution) => {
    execution.member = membersByIds.find((member) => member?.id === parseInt(execution?.memberId, 10));
  });
  const tableColumns = useAutomationAuditTableColumns(projectId, terminateAutomationExecution, refetchExecutions);
  if (loadingExecutions || loadingMembersByIds) {
    return <LoadSpinner />;
  }

  return (
    <div>
      {isEmpty(dataSource)
        ? (
          <div className={styles.emptyAutomation}>
            <div className={styles.emptyAutomationIcon}>
              <BoltIcon />
            </div>
            <div className={styles.emptyTitle}>No automations have run in this project.</div>
            <div className={styles.emptyDescription}>Once automations start running, you will see a history of every run here</div>
          </div>
)
        : (
          <div>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Row
                justify="end"
              >
                <Col>
                  <Space>
                    <Pagination
                      total={paging?.count || 0}
                      page={page}
                      setPage={setPage}
                      pageSize={TABLE_PAGE_SIZE}
                      showTotal
                    />
                  </Space>
                </Col>
              </Row>
              <div>
                <Table.Draggable<AuditTable>
                  columns={tableColumns}
                  selectedKeys={map(dataSource, (automation) => automation.id)}
                  dataSource={dataSource}
                  size="small"
                  pagination={false}
                />
              </div>
            </Space>
          </div>
      )}
    </div>
  );
};

AutomationAuditTable.displayName = 'AutomationAuditTable';
