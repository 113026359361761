import * as React from 'react';
import { uniqueId } from 'lodash';

import { SvgIcon, ISvgIconProps } from '@components';

export const TimerIcon = React.memo((props: ISvgIconProps) => {
  const { fill } = props;
  const timerIconFillId = uniqueId('TimerIcon_Fill');
  const timerIconMaskId = uniqueId('TimerIcon_Mask');

  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <g id="timer-fill">
        <path
          id={timerIconFillId}
          fillRule="evenodd"
          clipRule="evenodd"
          d="
            M10.8283 3.35987C10.8262 3.36685 10.8241 3.37391 10.8233 3.38183C14.5725 3.7935 17.5 6.97683 17.5
            10.8335C17.5 14.9693 14.135 18.3335 10 18.3335C5.865 18.3335 2.5 14.9693 2.5 10.8335C2.5 6.97683 5.4275
            3.7935 9.17667 3.38183C9.17591 3.37391 9.17379 3.36685 9.1717 3.35987C9.16917 3.35141 9.16667 3.34308
            9.16667 3.3335V2.50016H8.33333C7.87333 2.50016 7.5 2.12766 7.5 1.66683C7.5 1.206 7.87333 0.833496 8.33333
            0.833496H11.6667C12.1267 0.833496 12.5 1.206 12.5 1.66683C12.5 2.12766 12.1267 2.50016 11.6667
            2.50016H10.8333V3.3335C10.8333 3.34308 10.8308 3.35141 10.8283 3.35987ZM10 11.6668H12.5C12.96 11.6668 13.3333
            11.2943 13.3333 10.8335C13.3333 10.3727 12.96 10.0002 12.5 10.0002H10.8333V8.3335C10.8333 7.87266 10.46
            7.50016 10 7.50016C9.54 7.50016 9.16667 7.87266 9.16667 8.3335V10.8335C9.16667 11.2943 9.54 11.6668 10
            11.6668Z
          "
          fill={fill}
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="2"
          y="0"
          width="16"
          height="19"
        >
          <path
            id={timerIconMaskId}
            fillRule="evenodd"
            clipRule="evenodd"
            d="
              M10.8283 3.35987C10.8262 3.36685 10.8241 3.37391 10.8233 3.38183C14.5725 3.7935 17.5 6.97683 17.5
              10.8335C17.5 14.9693 14.135 18.3335 10 18.3335C5.865 18.3335 2.5 14.9693 2.5 10.8335C2.5 6.97683 5.4275
              3.7935 9.17667 3.38183C9.17591 3.37391 9.17379 3.36685 9.1717 3.35987C9.16917 3.35141 9.16667 3.34308
              9.16667 3.3335V2.50016H8.33333C7.87333 2.50016 7.5 2.12766 7.5 1.66683C7.5 1.206 7.87333 0.833496 8.33333
              0.833496H11.6667C12.1267 0.833496 12.5 1.206 12.5 1.66683C12.5 2.12766 12.1267 2.50016 11.6667
              2.50016H10.8333V3.3335C10.8333 3.34308 10.8308 3.35141 10.8283 3.35987ZM10 11.6668H12.5C12.96 11.6668
              13.3333 11.2943 13.3333 10.8335C13.3333 10.3727 12.96 10.0002 12.5 10.0002H10.8333V8.3335C10.8333 7.87266
              10.46 7.50016 10 7.50016C9.54 7.50016 9.16667 7.87266 9.16667 8.3335V10.8335C9.16667 11.2943 9.54 11.6668
              10 11.6668Z
            "
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)" />
      </g>
    </SvgIcon>
  );
});

TimerIcon.displayName = 'TimerIcon';
