import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const RemoveFromCollectionIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="8 10 16 16"
  >
    <path d="M16.7422 19.5625C19.3672 19.5625 21.5 21.6953 21.5 24.3203C21.5 24.8398 21.0625 25.25 20.543 25.25H10.1797C9.66016 25.25 9.25 24.8398 9.25 24.3203C9.25 21.6953 11.3555 19.5625 13.9805 19.5625H16.7422ZM10.5625 23.9375H20.1602C19.9414 22.2422 18.5195 20.875 16.7422 20.875H13.9805C12.2031 20.875 10.7539 22.2422 10.5625 23.9375ZM15.375 18.25C13.4336 18.25 11.875 16.6914 11.875 14.75C11.875 12.8359 13.4336 11.25 15.375 11.25C17.2891 11.25 18.875 12.8359 18.875 14.75C18.875 16.6914 17.2891 18.25 15.375 18.25ZM15.375 12.5625C14.1445 12.5625 13.1875 13.5469 13.1875 14.75C13.1875 15.9805 14.1445 16.9375 15.375 16.9375C16.5781 16.9375 17.5625 15.9805 17.5625 14.75C17.5625 13.5469 16.5781 12.5625 15.375 12.5625ZM26.0938 16.7188C26.4492 16.7188 26.75 17.0195 26.75 17.375C26.75 17.7578 26.4492 18.0312 26.0938 18.0312H22.1562C21.7734 18.0312 21.5 17.7578 21.5 17.375C21.5 17.0195 21.7734 16.7188 22.1562 16.7188H26.0938Z" />
  </SvgIcon>
  ));

RemoveFromCollectionIcon.displayName = 'RemoveFromCollectionIcon';
