import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_SELECTION_CRITERIA } from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import { CreateCatalogSelectionRule, CreateCatalogSelectionRuleVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/CreateCatalogSelectionRule';

type IOptions = MutationHookOptions<CreateCatalogSelectionRule, CreateCatalogSelectionRuleVariables>;

export const useCreateSelectionCriteria = (options: IOptions = {}) => {
  const [createSelectionCriteria, { loading, data, error }] = useMutation(CREATE_SELECTION_CRITERIA, options);

  return {
    loading,
    selectionCriteria: data?.createCatalogSelectionRule,
    error,
    createSelectionCriteria,
  };
};
