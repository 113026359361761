import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const NumberedListIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip-numbered-list)">
      <path d="M10.3908 5.65191H21.1573C21.6214 5.65191 22 5.27329 22 4.80916C22 4.34504 21.6214 3.96642 21.1573 3.96642H10.3908C9.92672 3.96642 9.5481 4.34504 9.5481 4.80916C9.5481 5.27329 9.92672 5.65191 10.3908 5.65191Z" />
      <path d="M21.1634 11.1237H10.3908C9.92672 11.1237 9.5481 11.5023 9.5481 11.9664C9.5481 12.4305 9.92672 12.8092 10.3908 12.8092H21.1573C21.6214 12.8092 22 12.4305 22 11.9664C22 11.5023 21.6275 11.1237 21.1634 11.1237Z" />
      <path d="M21.1634 18.3359H10.3908C9.92672 18.3359 9.5481 18.7145 9.5481 19.1786C9.5481 19.6427 9.92672 20.0214 10.3908 20.0214H21.1573C21.6214 20.0214 22 19.6427 22 19.1786C22 18.7145 21.6275 18.3359 21.1634 18.3359Z" />
      <path d="M3.20305 3.22138H3.19084L2.81221 3.47176C2.62901 3.5939 2.52519 3.63054 2.40916 3.63054C2.17099 3.63054 2 3.45344 2 3.22138C2 3.04428 2.10992 2.89161 2.31756 2.75726L2.90382 2.36642C3.17863 2.18321 3.43511 2.10382 3.67328 2.10382C4.09466 2.10382 4.36947 2.38474 4.36947 2.82443V6.4397C4.36947 6.84275 4.15573 7.06871 3.78321 7.06871C3.40458 7.06871 3.19695 6.83664 3.19695 6.4397V3.22138H3.20305Z" />
      <path d="M2.65342 13.2672L3.89311 11.997C4.27785 11.6061 4.43663 11.3496 4.43663 11.0748C4.43663 10.7267 4.18014 10.4886 3.80762 10.4886C3.50227 10.4886 3.28243 10.6412 3.12365 10.9588C2.96487 11.2031 2.83052 11.2947 2.62288 11.2947C2.34197 11.2947 2.14044 11.0992 2.14044 10.8305C2.14044 10.1649 2.89159 9.54199 3.86869 9.54199C4.85189 9.54199 5.5725 10.1405 5.5725 10.9649C5.5725 11.4718 5.32823 11.9115 4.72975 12.4977L3.70991 13.5298V13.5786H5.19998C5.52976 13.5786 5.73739 13.7557 5.73739 14.0489C5.73739 14.3359 5.53586 14.513 5.19998 14.513H2.75724C2.40914 14.513 2.18319 14.3176 2.18319 14.0183C2.17708 13.8046 2.29922 13.6214 2.65342 13.2672Z" />
      <path d="M2.56185 20.2779C2.73284 20.2779 2.87941 20.3573 3.03819 20.5588C3.25803 20.8519 3.53895 21.0046 3.88704 21.0046C4.35727 21.0046 4.66872 20.7603 4.66872 20.3756C4.66872 19.997 4.35727 19.7405 3.89315 19.7405H3.60002C3.35574 19.7405 3.17254 19.5512 3.17254 19.2886C3.17254 19.0382 3.34964 18.8428 3.60002 18.8428H3.88093C4.26567 18.8428 4.55269 18.5985 4.55269 18.2687C4.55269 17.9389 4.27177 17.7069 3.87483 17.7069C3.56948 17.7069 3.32521 17.8412 3.10536 18.1344C2.97712 18.3054 2.82445 18.3786 2.64124 18.3786C2.36643 18.3786 2.17101 18.1954 2.17101 17.9267C2.17101 17.3344 2.93437 16.8153 3.89926 16.8153C4.94353 16.8153 5.67635 17.3527 5.67635 18.1221C5.67635 18.6473 5.28552 19.1176 4.79086 19.1786V19.258C5.37712 19.3008 5.81681 19.7954 5.81681 20.4183C5.81681 21.2794 5.01681 21.8901 3.89315 21.8901C2.89773 21.8901 2.11605 21.3527 2.11605 20.7359C2.10994 20.4672 2.29926 20.2779 2.56185 20.2779Z" />
    </g>
    <defs>
      <clipPath id="clip-numbered-list">
        <rect width="20" height="19.7924" fill="white" transform="translate(2 2.10382)" />
      </clipPath>
    </defs>
  </SvgIcon>
));

NumberedListIcon.displayName = 'NumberedListIcon';
