import React from 'react';
import { Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { useAuth } from '@frontend/context/authContext';
import { useApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { pfaV2Routes } from './routes';
import { ProductsProvider } from './Products/ProductsContext';
import { Products } from './Products/Products/Products';
import { CollectionDetails } from './Products/CollectionDetails/CollectionDetails';
import { ImportLogs } from './Products/ImportLogs/ImportLogs';
import { CatalogRouter } from './Catalogs/CatalogRouter/CatalogRouter';

export const ProductFulfillmentRouter = () => {
  const pfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);

  const { token } = useAuth();
  const pfaApolloClient = useApolloClient(token);

  if (!pfaV2Enabled) {
    return null;
  }

  return (
    <>
      <ProductsProvider>
        <Route
          path={pfaV2Routes.settings.imports}
          exact
          render={() => (
            <ApolloProvider client={pfaApolloClient}>
              <Products />
            </ApolloProvider>
        )}
        />
        <Route
          path={pfaV2Routes.settings.collectionDetails}
          render={() => (
            <ApolloProvider client={pfaApolloClient}>
              <CollectionDetails />
            </ApolloProvider>
        )}
        />
        <Route
          path={pfaV2Routes.settings.importLogs}
          render={() => (
            <ApolloProvider client={pfaApolloClient}>
              <ImportLogs />
            </ApolloProvider>
        )}
        />
      </ProductsProvider>
      <Route path={pfaV2Routes.settings.brandCatalogs} render={() => <CatalogRouter />} />
    </>
  );
};
