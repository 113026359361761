import { IManager } from '../models';

/**
 * Returns the name of a manager.
 *
 * @param {IManager} user the manager.
 *
 * @return {String}
 */
export function getManagerName(user: IManager) {
  return user && (user.display_name || user.full_name);
}
