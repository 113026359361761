import { isString } from 'lodash';

import { IMedia } from '../models';

const PREVIEW_RESOLUTION = 'aiq_preview_resolution';

export function getThumbnailMedia(medias: IMedia[]): string {
  if (medias && medias.length > 0) {
    const previewMedia = medias.find(
      (media) => isString(media.url) && media.resolution === PREVIEW_RESOLUTION,
    );

    if (previewMedia) {
      return previewMedia.url;
    } else {
      return medias[0].url;
    }
  }

  return '';
}
