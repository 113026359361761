import { getInstagramEmbedData } from '@frontend/app/utils/getInstagramEmbedUrl';
import React, { useEffect, useRef, useState } from 'react';

declare global {
  interface Window {
    instgrm?: {
      Embeds: {
        process: () => void;
      };
    };
  }
}

interface InstagramContentProps {
  url: string;
  className?: string;
  renderAsImage?: boolean;
}

export const InstagramContent: React.FC<InstagramContentProps> = ({
  url,
  className = '',
  renderAsImage = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (renderAsImage) {
      // Fetch the thumbnail_url from Instagram's oEmbed API
      const fetchThumbnail = async () => {
        try {
          const data = await getInstagramEmbedData(url);
          if (data.thumbnail_url) {
            setThumbnailUrl(data.thumbnail_url);
          } else {
            setError('Thumbnail URL not available.');
          }
        } catch (err) {
          console.error('Error fetching thumbnail:', err);
          setError('Failed to load thumbnail.');
        }
      };

      fetchThumbnail();
    } else {
      // Embed the Instagram post as before
      const embedInstagramPost = () => {
        if (typeof window !== 'undefined' && window.instgrm) {
          window.instgrm.Embeds.process();
        } else {
          // Create the script tag
          const script = document.createElement('script');
          script.async = true;
          script.defer = true;
          script.src = 'https://www.instagram.com/embed.js';
          script.onload = () => {
            if (window.instgrm) {
              window.instgrm.Embeds.process();
            }
          };
          document.body.appendChild(script);
        }
      };

      embedInstagramPost();
    }
  }, [url, renderAsImage]);

  if (renderAsImage) {
    if (error) {
      return null;
    }

    if (!thumbnailUrl) {
      return null;
    }

    return <img src={thumbnailUrl} alt="Instagram thumbnail" className={className} />;
  }

  return (
    <div ref={containerRef} className={className}>
      <blockquote
        className={`instagram-media ${className}`}
        data-instgrm-permalink={url}
        data-instgrm-version="14"
        data-instgrm-captioned
        style={{
          background: '#FFF',
          border: '0',
          margin: '1px auto',
          maxWidth: '540px',
          minWidth: '326px',
          width: '100%',
        }}
      />
    </div>
  );
};
