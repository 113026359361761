import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_SHOPIFY_PROJECT_CONFIG_QUERY } from '../queries';
import {
  GetShopifyProjectConfigByProjectIdAndType,
  GetShopifyProjectConfigByProjectIdAndTypeVariables,
  GetShopifyProjectConfigByProjectIdAndType_projectConfig,
} from '../queries/types/GetShopifyProjectConfigByProjectIdAndType';

type IOptions = QueryHookOptions<GetShopifyProjectConfigByProjectIdAndType, GetShopifyProjectConfigByProjectIdAndTypeVariables>;
export type IProjectConfig = GetShopifyProjectConfigByProjectIdAndType_projectConfig;

export function useGetShopifyProjectConfig(options: IOptions = {}) {
  const {
    loading,
    data: {
      projectConfig,
    } = {},
    error,
    refetch,
  } = useQuery<GetShopifyProjectConfigByProjectIdAndType, GetShopifyProjectConfigByProjectIdAndTypeVariables>(
    GET_SHOPIFY_PROJECT_CONFIG_QUERY,
    {
      ...options,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    loading,
    projectConfig,
    error,
    refetch,
  };
}
