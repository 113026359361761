/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_uui6i_157 {
  display: flex;
}

._justify-content-space-between_uui6i_161 {
  justify-content: space-between;
}

._tabular-nums_uui6i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_uui6i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ShareLinkPane_uui6i_178 ._title_uui6i_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ShareLinkPane_uui6i_178 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 45%;
  max-width: 26.5625rem;
}
._ShareLinkPane_uui6i_178 ._title_uui6i_178 {
  color: #1a1818;
  margin-bottom: 0.625rem;
  font-size: 1.25rem;
}
._ShareLinkPane_uui6i_178 ._text_uui6i_232 {
  font-size: 1rem;
  margin-bottom: 2rem;
}
._ShareLinkPane_uui6i_178 img {
  height: auto;
  max-height: 6.75rem;
  width: auto;
  margin-bottom: 1.25rem;
}
._ShareLinkPane_uui6i_178 ._button_uui6i_242 {
  margin-left: auto;
  margin-right: auto;
  width: 15.625rem;
  border-radius: 0.5rem;
}
._ShareLinkPane_uui6i_178._shopifyCodes_uui6i_248 img {
  max-height: 12.5rem;
  width: auto;
}

@media screen and (max-width: 1366px) and (min-width: 767px) {
  ._ShareLinkPane_uui6i_178 {
    justify-content: flex-start;
  }
  ._ShareLinkPane_uui6i_178._shopifyCodes_uui6i_248 img {
    max-height: 9rem;
  }
}