import gql from 'graphql-tag';

export const META_FIELD_FRAGMENT = gql`
  fragment MetaFieldFragment on MetaFieldValue {
    id
    name
    key
    value
    type
  }
`;
