import * as React from 'react';

interface IProps {
  className?: string;
}

export const RefreshDates: React.FC<Readonly<IProps>> = (props) => (
  <svg
    className={props.className}
    fill="none"
    height="1em"
    viewBox="0 0 19 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M17.1667 10.1298V16.4907C17.1667 18.0222 15.9204 19.2685 14.3889 19.2685H3.27778C1.7463 19.2685 0.5 18.0222 0.5 16.4907V5.37963C0.5 3.84815 1.7463 2.60185 3.27778 2.60185H4.2037V1.67593C4.2037 1.16667 4.62037 0.75 5.12963 0.75C5.63889 0.75 6.05556 1.16667 6.05556 1.67593V2.60185H11.6111V1.67593C11.6111 1.16667 12.0278 0.75 12.537 0.75C13.0463 0.75 13.463 1.16667 13.463 1.67593V2.60185H14.3889C14.4686 2.60185 14.5475 2.60522 14.6254 2.61183L11.639 5.60473C11.6208 5.5326 11.6111 5.45719 11.6111 5.37962V4.4537H6.05558V5.37962C6.05558 5.88888 5.63891 6.30555 5.12965 6.30555C4.62039 6.30555 4.20373 5.88888 4.20373 5.37962V4.4537H3.2778C2.76762 4.4537 2.35187 4.86944 2.35187 5.37962V9.08333H8.16787L7.91935 9.33238L7.49095 10.9352H2.35187V16.4907C2.35187 17.0009 2.76762 17.4167 3.2778 17.4167H14.3889C14.8991 17.4167 15.3148 17.0009 15.3148 16.4907V12.2331L17.1667 10.1298ZM5.12961 12.787C4.62035 12.787 4.20368 13.2037 4.20368 13.713C4.20368 14.2222 4.62035 14.6389 5.12961 14.6389C5.63887 14.6389 6.05553 14.2222 6.05553 13.713C6.05553 13.2037 5.63887 12.787 5.12961 12.787Z"
      fillRule="evenodd"
      fill="currentColor"
    />
    <path
      d="M17.7993 4.55247C16.8781 3.63095 15.3716 3.63095 14.4408 4.55247L9.08071 9.91508C9.01354 9.99188 8.95597 10.0687 8.91758 10.1647L7.2287 14.1003C7.10396 14.3979 7.17113 14.7435 7.39183 14.9643C7.54537 15.1179 7.74688 15.1947 7.9484 15.1947C8.05395 15.1947 8.15951 15.1755 8.25547 15.1275L12.1898 13.438C12.2857 13.3996 12.3625 13.342 12.4393 13.2748L17.7993 7.9122C18.2503 7.47064 18.4998 6.86589 18.4998 6.23234C18.4998 5.59879 18.2503 5.00364 17.7993 4.55247ZM16.6958 6.80829L11.4413 12.0653L9.45495 12.9196L10.309 10.9326L15.5635 5.67558C15.8802 5.35881 16.3887 5.35881 16.6958 5.67558C16.8493 5.82917 16.9357 6.03075 16.9357 6.24194C16.9261 6.45312 16.8493 6.6547 16.6958 6.80829Z"
      fill="currentColor"
    />
  </svg>
  );
