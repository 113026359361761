import { useQuery, QueryHookOptions } from '@apollo/client';

import { GetStoreLocations } from '../queries/types/GetStoreLocations';
import { GET_STORE_LOCATIONS_QUERY } from '../queries/storeLocations';

type IOptions = QueryHookOptions<GetStoreLocations>;

export function useGetStoreLocations(options: IOptions = {}) {
  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<GetStoreLocations>(
    GET_STORE_LOCATIONS_QUERY,
    options,
  );

  return {
    loading,
    locations: data?.getStoreLocations,
    error,
    refetch,
  };
}
