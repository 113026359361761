import gql from 'graphql-tag';

export const GET_MEMBER_FIELD_SCHEMAS_FOR_COMMUNITY = gql`
  query CommunityMemberFieldSchemasQuery($communityId: Int!) {
    community: communityById(id: $communityId) {
      memberFieldSchemas {
        id
        name
        type
        applicationId
        isDefault
        choices
        metaData
      }
    }
  }
`;
