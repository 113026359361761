import * as React from 'react';
import {
 Route, Switch, Redirect, useRouteMatch, useHistory, useLocation,
} from 'react-router-dom';
import {
 isEmpty, isString, map, cloneDeep, mergeWith, isArray,
} from 'lodash';

import { GetMemberQuery_member as IMember } from '@frontend/app/queries/types/GetMemberQuery';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import {
  useMemberSearchQuery,
  useGetMemberQuery,
  useGetMembersByIdsQuery,
  useMemberSearchCountQuery,
} from '@frontend/app/hooks';
import { LoadSpinner } from '@components';
import { logger } from '../../../../../common/Logger';

import MemberMissingDetails from '../components/MemberMissingDetails';
import NewBulkUpdateRequest from '../components/NewBulkUpdateRequest';

const { useEffect } = React;

const MemberDataUpdateAppModal: React.FunctionComponent = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const {
 bulkActionParameters, workflowActionParameters, memberId, closeModal,
} = useApplication();
  const [members, setMembers] = React.useState<IMember[]>(null);

  const { query, invalidMembersQuery } = React.useMemo(() => {
    const hasQuery = isEmpty(bulkActionParameters?.memberIds) && bulkActionParameters?.memberQueryJson;
    const query = isString(bulkActionParameters?.memberQueryJson) && JSON.parse(bulkActionParameters?.memberQueryJson);
    const invalidMembersQuery = mergeWith(
      { fields: { and: [{ is_null: true, column: 'email' }] } },
      cloneDeep(query),
      (objValue, srcValue) => {
        if (isArray(objValue)) {
          return objValue.concat(srcValue);
        }
      },
);
    return hasQuery ? { query, invalidMembersQuery } : {};
  }, [bulkActionParameters?.memberQueryJson, bulkActionParameters?.memberIds]);

  const {
    data: {
      count: totalMembersCount,
    } = {},
  } = useMemberSearchCountQuery({
    variables: {
      query,
    },
    skip: !query,
  });
  const {
    data: {
      count: invalidMembersCount,
    } = {},
  } = useMemberSearchCountQuery({
    variables: {
      query: invalidMembersQuery,
    },
    skip: !invalidMembersQuery,
    fetchPolicy: 'no-cache',
  });

  /**
   * Validate data
   */
  useEffect(() => {
    if (!bulkActionParameters && !memberId && !workflowActionParameters) {
      closeModal();
    }
    const activeTypes = (memberId ? 1 : 0) + (workflowActionParameters ? 1 : 0) + (bulkActionParameters ? 1 : 0);
    if (activeTypes !== 1) {
      logger.error('Data update app modal was launched with multiple input types.',
        memberId, workflowActionParameters, bulkActionParameters);
      closeModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetching data for three cases. Only one prop type should be active. Others will be null
   */
  const {
    loading: memberLoading,
    data: memberData,
  } = useGetMemberQuery(Number(memberId));

  const {
    loading: workflowActionMembersLoading,
    data: { members: workflowActionMembersData } = {},
  } = useGetMembersByIdsQuery(map(workflowActionParameters?.memberIds, (id) => parseInt((id as unknown) as string, 10)), {
    skip: !workflowActionParameters,
  }); // TODO remove cast to int

  const {
    loading: bulkActionMembersLoading,
    data: { members: bulkActionMembersData } = {},
  } = useMemberSearchQuery({
    variables: {
      query: query && JSON.parse(bulkActionParameters?.memberQueryJson),
      skip: 0,
      take: 100,
    },
    skip: !query,
    fetchPolicy: 'no-cache',
  });

  /**
   * Set state following fetches for each of the three cases. Again only one state should ever be active
   */
  useEffect(() => {
    if (!memberLoading && memberData && memberId) {
      setMembers([memberData.member]);
    }
  }, [memberLoading, memberData, memberId]);

  useEffect(() => {
    if (!bulkActionMembersLoading && bulkActionParameters) {
      let membersArr = [];
      if (query) {
        membersArr = bulkActionMembersData;
      } else {
        membersArr = bulkActionParameters?.members;
      }
      setMembers(membersArr);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkActionMembersLoading, bulkActionParameters]);

  useEffect(() => {
    if (!workflowActionMembersLoading && workflowActionParameters) {
      setMembers(workflowActionMembersData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowActionMembersLoading, workflowActionParameters]);

  /**
   * Check for missing emails once we have members
   */
  const missingEmailCount = query ? invalidMembersCount : members?.filter((m) => !m.email).length;
  const membersCount = query ? totalMembersCount : members?.length;
  const redirect = missingEmailCount !== 0 ? 'missing_emails' : 'new_bulk';

  const onContinue = () => {
    history.push({
      ...location,
      pathname: './new_bulk',
    });
  };

  if (!members) {
    return <LoadSpinner />;
  }

  return (
    <section style={{ height: '100%' }}>
      <Switch>
        <Route
          path={`${match.path}/new_bulk`}
          render={(props) => <NewBulkUpdateRequest {...props} members={members} membersCount={membersCount - missingEmailCount} memberQueryJson={bulkActionParameters?.memberQueryJson} closeModal={closeModal} />}
        />
        <Route
          path={`${match.path}/missing_emails`}
          render={(props) => <MemberMissingDetails {...props} membersCount={membersCount} missingCount={missingEmailCount} onContinue={onContinue} closeModal={closeModal} />}
        />
        <Redirect from={`${match.path}`} to={{ ...location, pathname: `${match.path}/${redirect}` }} />
      </Switch>
    </section>
  );
};

export default MemberDataUpdateAppModal;
