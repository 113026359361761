import * as React from 'react';
import cx from 'classnames';
import { Switch } from '@frontend/app/components';

import styles from './ShowSection.scss';

interface IProps {
  show?: boolean;
  onChange?(show: boolean);
  className?: string;
}

export const ShowSection: React.FC<IProps> = (props) => (
  <div className={cx(styles.ShowSection, props.className)}>
    <div>Show Section</div>
    <Switch checked={props.show} onChange={props.onChange} />
  </div>
  );

ShowSection.defaultProps = {
  show: true,
};
