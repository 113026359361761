import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';
import * as React from 'react';

import {
  CityStateCard,
  CountryCard,
  EngagementRateCard,
  GenderAgeCard,
  PostPerformanceCard,
  ProfileCard,
  SummaryCard,
  GrowthCard,
} from '../Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import { Tab } from '../models';

import styles from './Content.scss';

export const InstagramContent: React.FC<{ version: CreatorSearchVersion }> = (props) => {
  const {
    version,
  } = props;

  const { activeTab } = useSocialProfileContext();

  return (
    <>
      {(!activeTab || activeTab === Tab.SUMMARY) && (
      <div className={styles.summary}>
        <ProfileCard className={styles.ProfileCard} />
        <EngagementRateCard className={styles.EngagementRateCard} />
        <SummaryCard className={styles.SummaryCard} version={version} />
      </div>
    )}
      {activeTab === Tab.ANALYZE && (
      <div className={styles.analyze}>
        <GenderAgeCard className={styles.GenderCard} hideAge />
        <GenderAgeCard className={styles.AgeCard} hideGender />
        <CountryCard className={styles.CountryCard} />
        <CityStateCard className={styles.CityCard} hideState />
        <PostPerformanceCard className={styles.PostPerformanceCard} />
        <GrowthCard className={styles.GrowthCard} />
      </div>
    )}
    </>
);
};

InstagramContent.displayName = 'InstagramContent';
