._IntroModal_1rhoa_1 {
  text-align: center;
}
._IntroModal_1rhoa_1 ._dialog_1rhoa_4 {
  display: inline-block;
  max-width: 620px;
  padding: 0 !important;
  margin: 0 20px !important;
}
._IntroModal_1rhoa_1 ._hidden_1rhoa_10 {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}