import * as React from 'react';
import * as qs from 'qs';
import { Link } from 'react-router-dom';

import { Button } from '@revfluence/fresh';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { TProject } from '@frontend/app/containers/Projects/types';

import {
  SOCIAL_DISCOVERY_APP_ID,
} from '@frontend/app/constants/applicationIds';

import { ClientFeature } from '@frontend/app/constants';
import { BaseStep } from './BaseStep';
import styles from './DiscoverCreatorsStep.scss';

const { useMemo } = React;

interface IProps {
  project: TProject;
}

const DiscoverCreatorsStepComponent: React.FC<IProps> = ({ project }) => {
  const addEvent = useEventContext();
  const isTopNavigationEnabled = useClientFeatureEnabled(ClientFeature.TOP_NAVIGATION);

  const textContent = useMemo(
    () => {
      if (!project.published) {
        return (
          <p>
            Browse our powerful database of influencers,
            {' '}
            and send an invitation to creators you’re interested in to apply to
            {' '}
            {project.title}
          </p>
        );
      } else {
        let linkUrl = isTopNavigationEnabled ? '/settings/messageTemplates' : '/email/templates';
        if (project.emailTemplateId) {
          linkUrl += `?templateId=${project.emailTemplateId}`;
        }
        return (
          <p>
            Browse over influencers and
            {' '}
            <Link to={linkUrl}>send an invitation</Link>
            {' '}
            for them to apply to
            {' '}
            {project.title}
          </p>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLinkClick = () => {
    addEvent(EventName.FindCreatorsBrowseClick, { source: 'Browse' });
  };

  const linkUrl = (() => {
    const searchQuery = {
      projectId: project.id,
    };

    return `/app/${SOCIAL_DISCOVERY_APP_ID}?${qs.stringify(searchQuery)}`;
  })();

  const actionContent = useMemo(() => (
    <Button
      type="primary"
      onClick={handleLinkClick}
    >
      <Link to={linkUrl}>
        Browse
      </Link>
    </Button>
  ), [linkUrl, handleLinkClick]);

  const iconContent = useMemo(() => (
    <img src="https://storage.googleapis.com/aspirex-static-files/find-creators/discover_creators.png" />
  ), []);

  return (
    <div className={styles.DiscoverCreatorsStep}>
      <BaseStep
        label="Step 3: Discover Creators"
        iconContent={iconContent}
        textContent={textContent}
        actionContent={actionContent}
      />
    </div>
  );
};

const DiscoverCreatorsStep = React.memo(DiscoverCreatorsStepComponent);

DiscoverCreatorsStep.displayName = 'DiscoverCreatorsStep';

export { DiscoverCreatorsStep };
