import gql from 'graphql-tag';

import { MESSAGE_FRAGMENT_WITH_PAYLOAD } from './message';

export const MESSAGE_THREAD_FRAGMENT = gql`
  fragment MessageThreadFragment on MessageThread {
    id
    type
    assignees {
      id
    }
    createdDate
    updatedDate
    messagesCount
    containsAttachment
    lastMessage {
      ...MessageFragmentWithPayload
    }
    application {
      icon
    }
  }
  ${MESSAGE_FRAGMENT_WITH_PAYLOAD}
`;
