import React from 'react';
import { Input } from 'antd';

interface NumericInputProps {
  value: string;
  onChange: (value: string) => void;
  prefix?: string;
  disabled?: boolean;
  max?: number;
}

const NumericInput: React.FC<NumericInputProps> = ({
  value, onChange, prefix, disabled, max,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
      if (newValue === '' || (max === undefined || parseFloat(newValue) <= max)) {
        onChange(newValue);
      }
    }
  };

  const handleBlur = () => {
    const numberValue = parseFloat(value);
    if (!isNaN(numberValue)) {
      onChange(numberValue.toFixed(2));
    } else {
      onChange('0.00');
    }
  };

  return (
    <Input
      value={value}
      type="text"
      onChange={handleChange}
      onBlur={handleBlur}
      prefix={prefix}
      disabled={disabled}
    />
  );
};

export default NumericInput;
