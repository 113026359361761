import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cx from 'classnames';
import { map, isEqual } from 'lodash';

import { RoundAddCircleIcon } from '@components';
import { Notice } from '@components';

import { RAW_IMAGE_EXTENSIONS, getFileExtension } from '@components';
import { sleep } from '@frontend/utils';
import { FileSelector } from './FileSelector';
import { ImageContent } from './Content/ImageContent';
import { VideoContent } from './Content/VideoContent';
import { ApplicationContent } from './Content/ApplicationContent';

import { IUploaderContent, TContentType } from './redux/contentUploaderModel';

import styles from './ContentUploader.scss';

const MAX_FILE_SIZE = 500 * 1024 * 1024;

/**
 * Sets the 'type' field for given file if not exists.
 * Some raw image files do not have type.
 *
 * @param {File} file the input file.
 *
 * @return {File}
 */
const setFileTypeIfNotExists = (file: File): File => {
  // if it already has type
  if (file.type) {
    return file;
  }

  const fileExt = getFileExtension(file.name);

  let fileType = file.type;
  if (!fileType && fileExt in RAW_IMAGE_EXTENSIONS) {
    fileType = RAW_IMAGE_EXTENSIONS[fileExt];
  }

  return new File([file], file.name, {
    type: fileType,
  });
};

export interface IOwnProps {
  // content type
  acceptImage?: boolean;
  acceptVideo?: boolean;
  acceptApplication?: boolean;
  classNames?: string[];
}

interface IProps {
  contents: IUploaderContent[];
  limitReached: boolean;
  uploadErrorMessage: string;

  // actions
  uploadContent(file: File, type?: TContentType);
  deleteContent(id: string);
  deleteContents();
}

interface IState {
  acceptTypes: TContentType[];
  isDragging: boolean;

  hasError: boolean;
  errorMessage: JSX.Element;
}

/**
 * @class
 * @extends {React.Component}
 */
export class ContentUploader extends React.Component<IProps & IOwnProps, IState> {
  public static defaultProps: Pick<IProps & IOwnProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IProps & IOwnProps) {
    super(props);

    this.state = {
      acceptTypes: [],
      isDragging: false,

      hasError: false,
      errorMessage: null,
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IProps & IOwnProps, prevState: IState) {
    const { acceptImage, acceptVideo, acceptApplication } = nextProps;
    const { acceptTypes: prevAcceptTypes } = prevState;

    const acceptTypes = [];
    if (acceptImage) {
      acceptTypes.push('image');
    }
    if (acceptVideo) {
      acceptTypes.push('video');
    }
    if (acceptApplication) {
      acceptTypes.push('application');
    }

    // only re-assign if not equal
    if (!isEqual(prevAcceptTypes.sort(), acceptTypes.sort())) {
      return {
        acceptTypes,
      };
    }

    return null;
  }

  /**
   * @inheritDoc
   */
  public componentDidUpdate(prevProps) {
    const { uploadErrorMessage } = this.props;

    if (uploadErrorMessage && uploadErrorMessage !== prevProps.uploadErrorMessage) {
      setTimeout(() => {
        this.handleUploadError(uploadErrorMessage);
      }, 500);
    }
  }

  /**
   * @inheritDoc
   */
  public UNSAFE_componentWillUnmount() {
    const { deleteContents } = this.props;

    deleteContents();
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { contents, limitReached, classNames } = this.props;
    const {
 acceptTypes, isDragging, hasError, errorMessage,
} = this.state;

    return (
      <div className={cx(classNames.concat(styles.ContentUploader))}>
        {hasError && (
          <Notice type="error" showDivider className={styles.errorNotice}>
            <div>{errorMessage}</div>
          </Notice>
        )}
        <div
          className={cx(styles.dragContainer, {
            [styles.dragging]: isDragging,
          })}
          onDrop={this.handleDrop}
          onDragEnter={this.handleDragEnter}
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
        >
          <TransitionGroup className={styles.contentContainer}>
            {map(contents, (content) => (
              <CSSTransition key={content.id} timeout={300} classNames="fade">
                {this.renderContentItem(content)}
              </CSSTransition>
              ))}
            {acceptTypes.length > 0 && (
              <FileSelector
                className={styles.FileSelector}
                disabled={limitReached}
                acceptTypes={acceptTypes}
                onFilesSelected={this.handleFileSelected}
              />
              // File Selector has size limit check as well, however since this file already has a
              // legacy size limit check, it is not necessary to add another for now.
            )}
          </TransitionGroup>
        </div>
      </div>
    );
  }

  /**
   * @private
   * Renders the content item using given content config.
   *
   * @param {IUploaderContent} content the content config.
   *
   * @return {JSX}
   */
  private renderContentItem = (content: IUploaderContent) => {
    let Content = null;

    switch (content.type) {
      case 'image': {
        Content = ImageContent;
        break;
      }

      case 'video': {
        Content = VideoContent;
        break;
      }

      case 'application': {
        Content = ApplicationContent;
        break;
      }

      default: {
        break;
      }
    }

    if (Content) {
      return (
        <div className={styles.contentWrapper}>
          <Content key={content.id} content={content} classNames={[styles.Content]} />
          {!content.disableRemove && (
            <RoundAddCircleIcon
              className={styles.remove}
              onClick={this.deleteContent.bind(this, content.id)}
            />
          )}
        </div>
      );
    }

    return null;
  };

  /**
   * @private
   * Callback for selected files change.
   *
   * @param {FileList} files the selected files.
   */
  private handleFileSelected = (files: FileList) => {
    const { uploadContent } = this.props;
    const { acceptTypes } = this.state;

    this.setState({
      hasError: false,
      errorMessage: null,
    });

    // throttle upload rate
    Array.from(files)
      .map(setFileTypeIfNotExists)
      .map((file) => ({
        file,
        type: file.type.split('/')[0] as TContentType,
      }))
      .filter((config) => {
        let hasError = false;
        let errorMessage = null;

        if (config.file.size >= MAX_FILE_SIZE) {
          // checks if file is too large.
          hasError = true;
          errorMessage = (
            <>
              <div className={styles.title}>The file you are uploading is too large</div>
              <div className={styles.text}>
                We currently do not support files greater than 500MB. We suggest sending it over
                <a href="https://www.dropbox.com/">Dropbox</a>
                .
              </div>
            </>
          );
        } else if (!acceptTypes.includes(config.type)) {
          // checks if file type is not allowed.
          hasError = true;
          errorMessage = (
            <>
              <div className={styles.title}>The file type you are uploading is not allowed</div>
              <div className={styles.text}>
                Please upload
                {' '}
                {acceptTypes.map((acceptType) => `${acceptType}`).join('/')}
                {' '}
                files.
              </div>
            </>
          );
        }

        if (hasError) {
          this.setState({
            hasError: true,
            errorMessage,
          });

          setTimeout(() => {
            this.setState({
              hasError: false,
              errorMessage: null,
            });
          }, 5000);
        }

        return !hasError;
      })
      .reduce(
        (prev, config) =>
          prev.then(async () => {
            await uploadContent(config.file, config.type);
            await sleep(100);
          }),
        Promise.resolve(),
      );
  };

  private handleUploadError = (message) => {
    const errorMessage = (
      <>
        <div className={styles.title}>Error uploading the file</div>
        <div className={styles.text}>{message}</div>
      </>
    );
    this.setState({
      hasError: true,
      errorMessage,
    });
  };

  /**
   * @private
   * Deletes a content with specific id.
   */
  private deleteContent = (id: string) => {
    const { deleteContent } = this.props;

    deleteContent(id);
  };

  /**
   * @private
   * Handler for 'drop' event.
   *
   * @param {React.DragEvent<HTMLDivElement>} event the event object.
   */
  private handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    // prevent browser from opening files
    event.stopPropagation();
    event.preventDefault();

    const { dataTransfer } = event;
    const { files } = dataTransfer;

    this.handleFileSelected(files);

    this.setState({
      isDragging: false,
    });
  };

  /**
   * @private
   * Handler for 'dragenter' event.
   */
  private handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    // prevent children from receiving events
    event.stopPropagation();
    event.preventDefault();
  };

  /**
   * @private
   * Handler for 'dragleave' event.
   */
  private handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    // prevent children from receiving events
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      isDragging: false,
    });
  };

  /**
   * @private
   * Handler for 'dragover' event.
   *
   * @param {React.DragEvent<HTMLDivElement>} event the event object.
   */
  private handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    const { isDragging } = this.state;

    // prevent children from receiving events
    event.stopPropagation();
    event.preventDefault();

    if (!isDragging) {
      this.setState({
        isDragging: true,
      });
    }
  };
}
