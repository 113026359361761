import { Button, Modal, Typography } from '@revfluence/fresh';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import React from 'react';

import styles from './CSVErrorModal.scss';

const { Title, Text } = Typography;

interface IProps {
  handleOk: () => void;
  handleCancel: () => void;
  isModalOpen: boolean;
  defaultFileName: string;
}

export const CSVErrorModal = ({
  isModalOpen, handleOk, handleCancel, defaultFileName,
}: IProps) => (
  <div className={styles.CSVErrorModal}>
    <Modal
      open={isModalOpen}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} danger>
          Try Again
        </Button>,
      ]}
      onCancel={handleCancel}
      wrapClassName={styles.CSVErrorModal}
    >
      <>
        <TriangleExclamationIcon type="danger" width={80} height={80} fill="#ff4d4f" />
        <Title level={5} type="danger">{`Your ${defaultFileName}.csv did not download`}</Title>
        <Text type="secondary">Please try downloading your file again</Text>
      </>
    </Modal>
  </div>
);
