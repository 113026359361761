export const IQAllowedLanguages = {
  'af': 'Afrikaans',
  'als': 'Albanian (Tosk)',
  'am': 'Amharic',
  'ar': 'Arabic',
  'arz': 'Arabic (Egyptian)',
  'as': 'Assamese',
  'ast': 'Asturian',
  'av': 'Avaric',
  'az': 'Azerbaijani',
  'azb': 'Azerbaijani, South',
  'ba': 'Bashkir',
  'bar': 'Bavarian',
  'be': 'Belarusian',
  'bg': 'Bulgarian',
  'bh': 'Bihari',
  'bn': 'Bengali',
  'bo': 'Tibetan',
  'bpy': 'Bishnupriya Manipuri',
  'bs': 'Bosnian',
  'bxr': 'Buriat, Russia',
  'ca': 'Catalan',
  'cbk': 'Chavacano',
  'ce': 'Chechen',
  'ceb': 'Cebuano',
  'ckb': 'Kurdish, Central',
  'co': 'Corsican',
  'cs': 'Czech',
  'cv': 'Chuvash',
  'da': 'Danish',
  'de': 'German',
  'diq': 'Dimli',
  'dsb': 'Sorbian, Lower',
  'dty': 'Dotyali',
  'dv': 'Divehi',
  'el': 'Greek',
  'eml': 'Emiliano-Romagnolo',
  'en': 'English',
  'es': 'Spanish',
  'et': 'Estonian',
  'fa': 'Persian',
  'fi': 'Finnish',
  'fr': 'French',
  'frr': 'Frisian, Northern',
  'fy': 'Frisian, Western',
  'ga': 'Irish',
  'gd': 'Gaelic (Scots)',
  'gl': 'Galician',
  'gn': 'Guarani',
  'gom': 'Konkani, Goan',
  'gu': 'Gujarati',
  'gv': 'Manx',
  'he': 'Hebrew',
  'hi': 'Hindi',
  'hr': 'Croatian',
  'hsb': 'Sorbian, Upper',
  'ht': 'Haitian',
  'hu': 'Hungarian',
  'hy': 'Armenian',
  'ia': 'Interlingua',
  'id': 'Indonesian',
  'ie': 'Interlingue',
  'ilo': 'Iloko',
  'io': 'Ido',
  'is': 'Icelandic',
  'it': 'Italian',
  'ja': 'Japanese',
  'jbo': 'Lojban',
  'jv': 'Javanese',
  'ka': 'Georgian',
  'kk': 'Kazakh',
  'km': 'Central Khmer',
  'kn': 'Kannada',
  'ko': 'Korean',
  'krc': 'Karachay-Balkar',
  'ku': 'Kurdish',
  'kv': 'Komi',
  'kw': 'Cornish',
  'ky': 'Kirghiz',
  'lez': 'Lezghian',
  'li': 'Limburgish',
  'lmo': 'Lombard',
  'lo': 'Lao',
  'lrc': 'Luri, Northern',
  'lt': 'Lithuanian',
  'lv': 'Latvian',
  'mai': 'Maithili',
  'mg': 'Malagasy',
  'mhr': 'Mari, Eastern',
  'min': 'Minangkabau',
  'mk': 'Macedonian',
  'ml': 'Malayalam',
  'mn': 'Mongolian',
  'mr': 'Marathi',
  'mrj': 'Mari, Western',
  'ms': 'Malay',
  'mwl': 'Mirandese',
  'my': 'Burmese',
  'myv': 'Erzya',
  'mzn': 'Mazanderani',
  'nah': 'Nahuatl languages',
  'nap': 'Neapolitan',
  'nds': 'Low German',
  'ne': 'Nepali',
  'new': 'Newari',
  'nl': 'Dutch',
  'no': 'Norwegian',
  'or': 'Oriya',
  'os': 'Ossetian',
  'pa': 'Panjabi',
  'pam': 'Pampanga',
  'pfl': 'Pfaelzisch',
  'pl': 'Polish',
  'pms': 'Piedmontese',
  'pnb': 'Panjabi, Western',
  'ps': 'Pushto',
  'pt': 'Portuguese',
  'rm': 'Raeto-Romance',
  'ro': 'Romanian',
  'ru': 'Russian',
  'rue': 'Rusyn',
  'rw': 'Kinyarwanda',
  'sa': 'Sanskrit',
  'sah': 'Sakha',
  'sc': 'Sardinian',
  'scn': 'Sicilian',
  'sco': 'Scots',
  'sd': 'Sindhi',
  'sh': 'Serbo-Croatian',
  'si': 'Sinhala',
  'sk': 'Slovak',
  'sl': 'Slovenian',
  'so': 'Somali',
  'sq': 'Albanian',
  'sr': 'Serbian',
  'su': 'Sundanese',
  'sv': 'Swedish',
  'sw': 'Swahili',
  'ta': 'Tamil',
  'te': 'Telugu',
  'tg': 'Tajik',
  'th': 'Thai',
  'tk': 'Turkmen',
  'tl': 'Tagalog',
  'tr': 'Turkish',
  'tt': 'Tatar',
  'tyv': 'Tuvinian',
  'ug': 'Uighur',
  'uk': 'Ukrainian',
  'ur': 'Urdu',
  'uz': 'Uzbek',
  'vec': 'Venetian',
  'vep': 'Veps',
  'vi': 'Vietnamese',
  'vls': 'West Flemish',
  'war': 'Waray',
  'wuu': 'Wu Chinese',
  'xal': 'Kalmyk',
  'xh': 'Xhosa',
  'xmf': 'Mingrelian',
  'yi': 'Yiddish',
  'yo': 'Yoruba',
  'yue': 'Yue Chinese',
  'zh': 'Chinese',
  'zu': 'Zulu',
  'bcl': 'Bicolano, Central',
}
