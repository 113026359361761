import * as React from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';

import {
  Col,
  Form,
  Row,
  Select,
} from '@revfluence/fresh';
import { OFFER_PRICE_RULE_TYPE } from '@affiliates/types/globalTypes';
import { OfferCodeDiscountAmount } from './OfferCodeDiscountAmount';
import { OfferCodeDiscountPercentAmount } from './OfferCodeDiscountPercentAmount';
import { IShopifyFormElementProps, IShopifyPromoCodeFormValues, OfferFormModes } from '../../../types';

import styles from '../../../OfferForm.scss';

const { Option } = Select;

const { useMemo } = React;

interface IProps extends IShopifyFormElementProps<'priceRuleType'> {
  value: IShopifyPromoCodeFormValues['priceRuleType'];
  isNewFlow?: boolean;
  isMultipleShopifyEnabled?: boolean;
  mode?: OfferFormModes,
}

export const OfferCodeDiscountOptions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
  isNewFlow,
  isMultipleShopifyEnabled,
}) => {
  let enableDiscountValue = disabled;
  if (isNewFlow) {
    enableDiscountValue = false;
  }
  const rules = [
    { required: true, message: 'Please select a discount option!' },
  ];
  const renderAmount = useMemo(() => {
    switch (value) {
      case OFFER_PRICE_RULE_TYPE.AMOUNT:
        return (
          <OfferCodeDiscountAmount
            disabled={enableDiscountValue}
            name="priceRuleAmount"
          />
        );
      case OFFER_PRICE_RULE_TYPE.PERCENTAGE:
        return (
          <OfferCodeDiscountPercentAmount
            disabled={enableDiscountValue}
            name="priceRuleAmount"
          />
        );
      default:
        return null;
    }
  }, [value, enableDiscountValue]);
  return (
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item
          className={cx({
            [styles.halfRow]: !isNull(value),
          })}
          label="Discount Type"
          name={name}
          rules={rules}
        >
          <Select
            disabled={disabled || isMultipleShopifyEnabled}
            placeholder="Select"
            size="large"
          >
            <Option value={OFFER_PRICE_RULE_TYPE.PERCENTAGE}>
              Percentage
            </Option>
            {!isMultipleShopifyEnabled && (
              <Option value={OFFER_PRICE_RULE_TYPE.AMOUNT}>
                Flat
              </Option>
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        {renderAmount}
      </Col>
    </Row>
  );
});
