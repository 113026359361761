import {
 isNumber, toLower, trim, isString, size, includes,
} from 'lodash';
import { isValid, format } from 'date-fns';

export const sortStr = (a: string, b: string) => {
  const lowerA = toLower(trim(a));
  const lowerB = toLower(trim(b));
  if (lowerA === '' || lowerA === null || lowerA === undefined) return 1;
  if (lowerB === '' || lowerB === null || lowerB === undefined) return -1;

  if (lowerA === lowerB) return 0;
  return lowerA < lowerB ? -1 : 1;
};

export const formatDate = (val: number | string) => {
  let parsedDate;
  if (!val) return '';

  if (isString(val)) {
    parsedDate = Date.parse(val as string);
  } else if (isNumber(val)) {
    const length = size(`${val}`);
    if (!includes([10, 13], length)) {
      return '';
    }
    parsedDate = new Date(length === 10 ? (val * 1000) : val);
  }

  return isValid(parsedDate) ? format(parsedDate, 'PP') : '';
};
