import gql from 'graphql-tag';

export const SAVE_SEGMENT_METADATA = gql`
  mutation SaveSegmentMetadataMutation($metadata: SegmentMetadataInput!) {
    metadata: saveSegmentMetadata(metadata: $metadata) {
      id
      segmentId
      predefinedSegmentId
      columns {
        memberFieldSchemaIds
        dbColumns
        projectColumns
        order {
          memberFieldSchemaId
          dbColumn
          projectColumn
        }
      }
    }
  }
`;
