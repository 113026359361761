import * as React from 'react';
import { every, map } from 'lodash';

import { parseCSV } from '@frontend/app/utils/parseCSV';

const { useEffect, useMemo, useState } = React;

export const useReadDataPreviewFromFile = (file: File) => {
  const [headers, setHeaders] = useState<string[]>();
  const [dataPreview, setDataPreview] = useState<string[][]>();
  const [dataSize, setDataSize] = useState(0);
  const isDataPreviewComplete = useMemo(
    () => (dataPreview?.length
      ? every(dataPreview, (previews) => (
        previews?.length === 3
        && every(previews, (s) => s)
      ))
      : false),
    [dataPreview],
  );
  useEffect(
    () => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const text = e.target.result as string;
          const [headers, ...restData] = parseCSV(text);
          const tempData = map(new Array(headers.length), () => []);

          for (let i = 0; i < 100 && i < restData.length && !isDataPreviewComplete; i++) {
            restData[i].forEach((data, dIndex) => {
              if (data) {
                if (!tempData[dIndex]?.length) {
                  tempData[dIndex] = [data];
                } else if (!tempData[dIndex].includes(data) && tempData[dIndex].length < 3) {
                  tempData[dIndex].push(data);
                }
              }
            });
          }

          // Reorder columns -- 'id', 'uid', '__uid' go first
          const orderedHeaders = [];
          const orderedDataPreview = [];
          const idIndex = headers.indexOf('id');
          if (idIndex >= 0) {
            orderedHeaders.push('id');
            orderedDataPreview.push(tempData[idIndex]);
          }
          const uidIndex = headers.indexOf('uid');
          if (uidIndex >= 0) {
            orderedHeaders.push('uid');
            orderedDataPreview.push(tempData[uidIndex]);
          }
          const __uidIndex = headers.indexOf('__uid');
          if (__uidIndex >= 0) {
            orderedHeaders.push('__uid');
            orderedDataPreview.push(tempData[__uidIndex]);
          }
          orderedHeaders.push(...headers.filter((_, i) => i !== idIndex && i !== uidIndex && i !== __uidIndex));
          orderedDataPreview.push(...tempData.filter((_, i) => i !== idIndex && i !== uidIndex && i !== __uidIndex));

          setHeaders(orderedHeaders);
          setDataPreview(orderedDataPreview);
          setDataSize(restData.length);
        };
        reader.readAsText(file);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file],
  );

  if (!file) {
    return;
  }
  return headers && dataPreview
    ? {
      dataPreview,
      dataSize,
      fieldCount: headers?.length,
      headers,
    }
    : {};
};
