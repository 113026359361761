import gql from 'graphql-tag';

import { REQUIREMENT_FRAGMENT } from './fragments';

export const REQUIREMENTS_FOR_MEMBER = gql`
  query RequirementsForMemberQuery($memberId: Int!) {
    requirements: requirementsForMember(memberId: $memberId) {
      ...RequirementFragment
    }
  }
  ${REQUIREMENT_FRAGMENT}
`;
