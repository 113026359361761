import * as React from 'react';
import { find, isEmpty, map } from 'lodash';
import { Link } from 'react-router-dom';
import { Select, Tooltip } from '@revfluence/fresh';
import { CircleInfoIcon, EnvelopeIcon } from '@revfluence/fresh-icons/solid/esm';

import {
  GMAIL_APP_ID,
  OUTLOOK_APP_ID,
} from '@frontend/app/constants/applicationIds';
import { IResource } from '@frontend/components/common';
import { useGetInstalledApplicationIds } from '@frontend/app/hooks';

import styles from './InvitesResourcesPicker.scss';
import { useResourceContext } from '@frontend/app/context';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { InvitesResourcesPickerIGDM } from './InvitesResourcesPickerIGDM';

const { useMemo } = React;

const TooltipLabel = "Creators that can receive direct messages will get all invites via Instagram DM. All others will receive invites via email.";

export interface InvitesResourcesPickerProps {
  channel?: string;
  emailResources: IResource[];
  selectedEmailId: number;
  onSelectEmailId: (resourceId: number) => void;
}

export const InvitesResourcesPicker: React.FC<InvitesResourcesPickerProps> = (props) => {
  const {
    channel,
    emailResources,
    selectedEmailId,
    onSelectEmailId,
  } = props;
  const installedApps = useGetInstalledApplicationIds();
  const isInstagram = useMemo(() => channel === 'instagram', [channel]);

  const emailSettingsUrl = useMemo(() => {
    if (installedApps[GMAIL_APP_ID]) {
      return `/settings/${encodeURIComponent(GMAIL_APP_ID)}`;
    } else if (installedApps[OUTLOOK_APP_ID]) {
      return `/settings/${encodeURIComponent(OUTLOOK_APP_ID)}`;
    }
    return null;
  }, [GMAIL_APP_ID, OUTLOOK_APP_ID, installedApps]);

  const {
    resources,
  } = useResourceContext();

  const igResource = useMemo(() => {
    return find(resources, (r) => (
      r.type === ResourceType.IGDM
      && !r.authProvider.userRevokedAccess
      && !r.authProvider.systemRevokedAccess
    ))
  }, [resources]);

  const emailResourcesOptions = useMemo(() => {
    return map(emailResources, (resource) => {
      return {
        label: resource.authProvider.userExternalDisplayId,
        value: resource.id,
      }
    })
  }, [emailResources]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span className={styles.label}>
          Sending invites from
        </span>
        {
          isInstagram
          && (
            <Tooltip
              title={TooltipLabel}
            >
              <span className={styles.icon}>
                <CircleInfoIcon />
              </span>
            </Tooltip>
          )
        }
      </div>
      <div className={styles.resourceRow}>
        <div className={styles.resourceIcon}>
          <EnvelopeIcon />
        </div>
        <div className={styles.resourceContent}>
          {isEmpty(emailResourcesOptions) && (
            <Link to={`${emailSettingsUrl}`}>
              Connect an Email Address
            </Link>
          )}
          {!isEmpty(emailResourcesOptions) && (
            <Select
              options={emailResourcesOptions}
              value={selectedEmailId}
              onChange={onSelectEmailId}
              className={styles.select}
            />
          )}
        </div>
      </div>
      {isInstagram &&
        <InvitesResourcesPickerIGDM
          igResource={igResource}
        />
      }
    </div>
  )
}
