import { forEach, isArray, isEmpty } from 'lodash';

import { logger } from '@common';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';

export interface IVariable {
  [key: string]: {
    field: string;
    key: string;
    label: string;
  };
}

export const MEMBER_FIRST_NAME = 'First Name';

export interface IPFAComposerMemberFieldIds {
  [MEMBER_FIRST_NAME]: number;
}

export const useGetPfaComposerMemberFields = (): IPFAComposerMemberFieldIds | null => {
  const memberFieldQuery = useMemberFieldSchemasQuery();

  const toReturn = {
    [MEMBER_FIRST_NAME]: -1,
  };

  if (memberFieldQuery.loading) {
    return null;
  }

  if (memberFieldQuery.error) {
    logger.error(memberFieldQuery.error);
    return toReturn;
  }

  const schemas = memberFieldQuery.data?.schemas;
  if (!isArray(schemas) || isEmpty(schemas)) {
    logger.error('Unable to retrieve member field.');
    return toReturn;
  }
  forEach(schemas, (field) => {
    if (field.name === MEMBER_FIRST_NAME) {
      toReturn[MEMBER_FIRST_NAME] = field.id;
    }
  });
  return toReturn;
};
