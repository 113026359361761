import * as React from 'react';
import { ApplicationPageBuilderComponent } from '@frontend/app/containers/Projects/applicationPageUtils';

import { SocialLinks } from './SocialLinks';
import { IQuickTemplateStyles, ITemplateProps } from '../../types';

import styles from './Intro.scss';

type IProps = ITemplateProps['intro'] & {
  logo: string;
  isFieldVisible: (fieldName: ApplicationPageBuilderComponent) => boolean;
  componentStyles: IQuickTemplateStyles,
};

const aspireIconSvg = require('@frontend/app/assets/svgs/aspire_icon.svg');

export const Intro: React.FC<IProps> = React.memo((props) => (
  <div className={styles.Intro}>
    <div className={styles.title}>
      <img src={props.logo ?? aspireIconSvg} />
      <div
        className={styles.text}
        style={props.componentStyles.heading}
      >
        {props.title}
      </div>
    </div>
    {
      props.subtitle
        && props.isFieldVisible(ApplicationPageBuilderComponent.IntroSubtitle)
        && (
          <div className={styles.subtitle} style={props.componentStyles.body}>
            {props.subtitle}
          </div>
        )
    }
    <SocialLinks
      brand_instagram={props.brand_instagram}
      brand_pinterest={props.brand_pinterest}
      brand_tiktok={props.brand_tiktok}
      brand_website={props.brand_website}
      brand_youtube={props.brand_youtube}
    />
  </div>
));

Intro.displayName = 'QuickTemplateIntro';
