import { IState } from '../../../../types/state';
import { IUpdateInfluencerWhiteListStartDate } from './index';

export const updateInfluencerWhitelistStartDate = (
  state: IState, action: IUpdateInfluencerWhiteListStartDate,
): IState => ({
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      bam: {
        ...state.collaborationDetails.bam,
        startDateAccess: action.startDateAccess,
      },
    },
  });
