import {
  MemberDataRestrictionFieldName,
  MemberDataRestrictionFieldType,
} from '@frontend/app/types/globalTypes';

interface IFilterOption {
  filterFieldId: MemberDataRestrictionFieldName;
  filterType: MemberDataRestrictionFieldType;
  displayName: string;
}

export const filterOptions: IFilterOption[] = [{
  filterFieldId: MemberDataRestrictionFieldName.EMAIL,
  filterType: MemberDataRestrictionFieldType.CONTAINS,
  displayName: 'Email contains',
}, {
  filterFieldId: MemberDataRestrictionFieldName.EMAIL,
  filterType: MemberDataRestrictionFieldType.EXACT_MATCH,
  displayName: 'Email matches',
}, {
  filterFieldId: MemberDataRestrictionFieldName.EMAIL,
  filterType: MemberDataRestrictionFieldType.PREFIX,
  displayName: 'Email starts with',
}, {
  filterFieldId: MemberDataRestrictionFieldName.EMAIL,
  filterType: MemberDataRestrictionFieldType.POSTFIX,
  displayName: 'Email ends with',
}];
