import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const AlertIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M21.735 11.698c.095 5.45-4.416 9.946-9.867 10.041-5.449.094-9.772-4.247-9.867-9.697-.095-5.449 4.418-9.946 9.867-10.04 5.45-.096 9.773 4.248 9.867 9.696z" />
    <path
      fill="#FFF"
      d="M11.139 13.421l-.301-6.158h2.107l-.273 6.158h-1.533zm-.328 2.012a1.11 1.11 0 0 1 1.094-1.109A1.11 1.11 0 0 1 13 15.433c0 .588-.493 1.094-1.095 1.094a1.107 1.107 0 0 1-1.094-1.094z"
    />
  </SvgIcon>
));

AlertIcon.displayName = 'AlertIcon';

export { AlertIcon };
