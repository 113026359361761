import React from 'react';
import { ArrowTrendDownIcon, ArrowTrendUpIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { cn } from '@/shadcn/lib/utils';

interface TrendIconProps {
  trend: 'up' | 'down' | 'flat' | 'alert';
  value: string;
}

const TrendIcon: React.FC<TrendIconProps> = ({ trend, value }) => {
  const iconStyles = {
    up: 'text-green-700',
    down: 'text-red-700',
    flat: 'text-blue-700',
    alert: 'text-orange-700',
  };

  const icon = {
    up: <ArrowTrendUpIcon />,
    down: <ArrowTrendDownIcon />,
    flat: <ArrowTrendUpIcon />,
    alert: <TriangleExclamationIcon />,
  };

  return (
    <div
      className={cn(
        'inline-flex items-center gap-1 bg-transparent border border-border rounded-md px-1',
        iconStyles[trend],
      )}
    >
      <span>{icon[trend]}</span>
      <span className="text-xs">{value}</span>
    </div>
  );
};

export default TrendIcon;
