/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1l4se_157 {
  display: flex;
}

._justify-content-space-between_1l4se_161 {
  justify-content: space-between;
}

._tabular-nums_1l4se_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1l4se_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178 ._sectionHeader_1l4se_178 ._title_1l4se_178,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 ._sectionHeader_1l4se_178 ._title_1l4se_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

:root {
  --primary: #167cf4;
  --primary-40: #3d73d7;
  --primary-10: #e6f7ff;
  --secondary: #f58984;
  --secondary-40: #fcbbae;
  --secondary-10: #fef0ed;
  --bay-grey: #989ea8;
  --grey-10: #fafafa;
  --grey-25: #e9e8ea;
  --grey-50: #aeaeae;
  --grey-75: #8f8f8f;
  --grey-100: #1a1b1c;
  --white: #fff;
  --black: #1a1b1c;
  --green: #006462;
  --sand: #f6f3ef;
  --yellow: #ffcd5b;
  --magenta-base: #ffddff;
  --red-base: #f5b3b4;
  --volcano-base: #ff9c93;
  --orange-base: #fabf95;
  --gold-base: #f1e5ac;
  --lime-base: #ebffd0;
  --sage-base: #c1e1c1;
  --cyan-base: #c9f2f2;
  --blue-base: #70d1f1;
  --geekblue-base: #a2aff1;
  --purple-base: #c5b3e3;
  --mauve-base: #c6b0ca;
  --coral-base: #ff8a8a;
  --apricot-base: #f5a287;
  --marigold-base: #fdaf1c;
  --butter-base: #f9eda1;
  --apple-base: #b7d275;
  --asparagus-base: #9db17c;
  --tiffany-base: #5fcdca;
  --baby-base: #a3ddf9;
  --carolina-base: #7eb3d8;
  --rose-base: #ffb4c7;
  --watermelon-base: #ffa1b6;
  --blue-0: #f4fcff;
  --blue-1: #e6f7ff;
  --blue-3: #91d5ff;
  --blue-5: #499dff;
  --blue-6: #167cf4;
  --blue-7: #2771c9;
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #505256;
  --gray-9: #1f1f21;
  --red-1: #fff1f0;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --gold-1: #fffbe6;
  --gold-3: #ffe58f;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --green-1: #f6ffed;
  --green-3: #b7eb8f;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --text-color: #505256;
  --text-color-secondary: #8c8c8c;
  --text-color-inverse: #fff;
  --icon-color: inherit;
  --icon-color-hover: rgba(26, 27, 28, 0.75);
  --heading-color: #1f1f21;
  --text-color-dark: rgba(255, 255, 255, 0.85);
  --text-color-secondary-dark: rgba(255, 255, 255, 0.65);
  --text-selection-bg: #167cf4;
  --link-color: #167cf4;
  --modal-mask-bg: rgba(79, 90, 114, 0.45);
}

._OfferDetails_1l4se_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
._OfferDetails_1l4se_178 section {
  min-height: 0.0625rem;
  flex: 0 0 auto;
  padding: 1.5rem;
  margin-bottom: 0rem;
}
._OfferDetails_1l4se_178 section:last-child {
  min-height: 31.25rem;
  flex: 1 1 auto;
}
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 {
  height: 100%;
}
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178 ._sectionHeader_1l4se_178,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 ._sectionHeader_1l4se_178 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 2.375rem;
}
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178 ._sectionHeader_1l4se_178 ._title_1l4se_178,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 ._sectionHeader_1l4se_178 ._title_1l4se_178 {
  font-size: 1.25rem;
}
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178 ._statsCard_1l4se_390,
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178 ._detailCard_1l4se_391,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 ._statsCard_1l4se_390,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 ._detailCard_1l4se_391 {
  height: 100%;
}
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178 ._statsCard_1l4se_390 .ant-card,
._OfferDetails_1l4se_178 ._OfferStatisticsCard_1l4se_178 ._detailCard_1l4se_391 .ant-card,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 ._statsCard_1l4se_390 .ant-card,
._OfferDetails_1l4se_178 ._OfferDetailsSummary_1l4se_179 ._detailCard_1l4se_391 .ant-card {
  height: 100%;
}
._OfferDetails_1l4se_178 ._zeroState_1l4se_402 {
  display: flex;
  height: 100%;
}
._OfferDetails_1l4se_178 ._zeroState_1l4se_402 ._conversionPane_1l4se_406,
._OfferDetails_1l4se_178 ._zeroState_1l4se_402 ._pixelPane_1l4se_407 {
  display: flex;
  flex: 0 0 100%;
  height: 100%;
  justify-content: center;
}
._OfferDetails_1l4se_178 ._zeroState_1l4se_402 ._conversionPane_1l4se_406._twoColumn_1l4se_413,
._OfferDetails_1l4se_178 ._zeroState_1l4se_402 ._pixelPane_1l4se_407._twoColumn_1l4se_413 {
  flex: 0 0 50%;
}
._OfferDetails_1l4se_178 ._zeroState_1l4se_402 ._pixelPane_1l4se_407._twoColumn_1l4se_413 {
  border-left: solid thin #c4c4c4;
}
._OfferDetails_1l4se_178 ._salesStatsWrapper_1l4se_420 {
  background-color: #fafafa;
  border: 1px solid var(--gray-4);
}
._OfferDetails_1l4se_178 ._archiveOfferAlert_1l4se_424 {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}

._migrationModalContainer_1l4se_428 {
  width: 546px !important;
}
._migrationModalContainer_1l4se_428 .ant-typography {
  color: #1f1f21;
}
._migrationModalContainer_1l4se_428 .ant-modal-body {
  padding: 1rem 1.5rem;
}
._migrationModalContainer_1l4se_428 ._modalList_1l4se_437 {
  padding: 0.625rem 1.5rem 0rem 1.5rem;
}
._migrationModalContainer_1l4se_428 ._modalList_1l4se_437 ul {
  list-style: disc;
}
._migrationModalContainer_1l4se_428 ._modalList_1l4se_437 ul li {
  margin-bottom: 0.625rem;
}
._migrationModalContainer_1l4se_428 ._modalList_1l4se_437 ul li:last-child {
  margin-bottom: 0;
}
._migrationModalContainer_1l4se_428 ._modalHighlightWrapper_1l4se_449 {
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #faad14;
  background: #fffbe6;
  margin: 1rem 0;
}
._migrationModalContainer_1l4se_428 ._modalHighlightWrapper_1l4se_449 ._highlightWrapper_1l4se_456 {
  padding-top: 16px;
}
._migrationModalContainer_1l4se_428 ._modalHighlightWrapper_1l4se_449 ._highlightWrapper_1l4se_456 .ant-list-split .ant-list-item {
  border-bottom: none;
  padding: 0;
}
._migrationModalContainer_1l4se_428 ._modalHighlightWrapper_1l4se_449 ._highlightWrapper_1l4se_456 .ant-list-split .ant-list-item:first-child {
  margin-bottom: 0.75rem;
}