import React, { useState } from 'react';
import { Empty, WidgetContainer } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { DashboardSortBy } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useGetCreatorTypeOrderCost } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCreatorTypeOrderCost';
import {
 Cell, Pie, PieChart, ResponsiveContainer,
} from 'recharts';
import {
 EarthAmericasIcon, GlobeIcon, TelescopeIcon, UserGroupIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { largeIntegerAbbreviator } from '@frontend/utils';
import { cva } from 'class-variance-authority';
import { cn } from '@/shadcn/lib/utils';
import Switcher from '../refresh-components/Switcher';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

enum CreatorType {
  MEGA = 'VIP',
  MACRO = 'Macro',
  MID = 'Mid',
  MICRO = 'Micro',
  NANO = 'Nano',
}

const CREATOR_TYPE_LABELS = {
  [CreatorType.MEGA]: 'Mega',
  [CreatorType.MACRO]: 'Macro',
  [CreatorType.MID]: 'Mid',
  [CreatorType.MICRO]: 'Micro',
  [CreatorType.NANO]: 'Nano',
};

const CREATOR_TYPE_ICONS = {
  [CreatorType.MEGA]: <EarthAmericasIcon />,
  [CreatorType.MACRO]: <TelescopeIcon />,
  [CreatorType.MID]: <GlobeIcon />,
  [CreatorType.MICRO]: <GlobeIcon />,
  [CreatorType.NANO]: <span className="text-xs text-primary font-medium">{'<1K'}</span>,
};

const colorVariants = cva(
  'h-14 w-8 rounded-lg text-primary-foreground flex items-center justify-center',
  {
    variants: {
      creatorType: {
        [CreatorType.MEGA]: 'bg-blue-6',
        [CreatorType.MACRO]: 'bg-blue-5',
        [CreatorType.MID]: 'bg-blue-4',
        [CreatorType.MICRO]: 'bg-blue-3',
        [CreatorType.NANO]: 'bg-blue-2',
      },
    },
  },
);

interface PFACreatorTypeOrderCostProps {
  className?: string;
}
export default function PFACreatorTypeOrderCost({ className }: PFACreatorTypeOrderCostProps) {
  const [selectedTab, setSelectedTab] = useState<DashboardSortBy[]>([DashboardSortBy.ORDER]);

  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const { creatorTypeOrderCost, isCreatorTypeLoading } = useGetCreatorTypeOrderCost({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        sortBy: selectedTab[0],
      },
    },
  });

  const isDataEmpty = !creatorTypeOrderCost?.length || creatorTypeOrderCost?.every((i) => !i.orders);

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );

  const widgetActions = (
    <Switcher
      items={[
        { label: 'Orders', key: DashboardSortBy.ORDER },
        { label: 'Spend', key: DashboardSortBy.COST },
      ]}
      type="single"
      activeKeys={selectedTab}
      onChange={(keys) => setSelectedTab(keys as DashboardSortBy[])}
    />
  );

  const emptyContent = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={UserGroupIcon}
        title="No data available"
        description="Once orders are created, you’ll be able to track the top loved product by creator here."
      />
    </div>
  );

  const dataKey = selectedTab[0] === DashboardSortBy.ORDER ? 'orders' : 'spend';

  const mainContent = (
    <div className="flex flex-col justify-evenly gap-16 p-6">
      <ResponsiveContainer width="100%" height={200}>
        <PieChart width={100} height={100}>
          <Pie
            data={[
              CreatorType.MEGA,
              CreatorType.MACRO,
              CreatorType.MID,
              CreatorType.MICRO,
              CreatorType.NANO,
            ].map((creatorType) => creatorTypeOrderCost?.find((i) => i.creatorType === creatorType))}
            dataKey={dataKey}
            cx="50%"
            cy="50%"
            stroke="none"
            outerRadius={100}
            innerRadius={60}
          >
            <Cell fill="hsl(var(--refresh-blue-6))" />
            <Cell fill="hsl(var(--refresh-blue-5))" />
            <Cell fill="hsl(var(--refresh-blue-4))" />
            <Cell fill="hsl(var(--refresh-blue-3))" />
            <Cell fill="hsl(var(--refresh-blue-2))" />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="flex flex-wrap">
        {
          [CreatorType.MEGA,
            CreatorType.MACRO,
            CreatorType.MID,
            CreatorType.MICRO,
            CreatorType.NANO,
          ].map((creatorType) => {
            const item = creatorTypeOrderCost?.find((i) => i.creatorType === creatorType);

            return (
              <div key={creatorType} className="flex-[50%] flex items-center gap-2 mb-4">
                <div className={colorVariants({ creatorType })}>
                  {CREATOR_TYPE_ICONS[creatorType as CreatorType]}
                </div>
                <div className="flex flex-col">
                  <div className="text-sm text-muted-foreground">{CREATOR_TYPE_LABELS[creatorType]}</div>
                  <div className="text-base font-bold">
                    {dataKey === 'spend' ? '$' : ''}
                    {largeIntegerAbbreviator(item?.[dataKey] || 0).value}
                    {largeIntegerAbbreviator(item?.[dataKey] || 0).symbol}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );

  return (
    <WidgetContainer
      widgetTitle="Creator Type"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
    >
      {isCreatorTypeLoading ? loadingContent : isDataEmpty ? emptyContent : mainContent}
    </WidgetContainer>
  );
}
