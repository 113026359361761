import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const PersonDoneIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.66667 7.33333C8.13733 7.33333 9.33333 6.13733 9.33333 4.66667C9.33333 3.196 8.13733 2 6.66667 2C5.196 2 4 3.196 4 4.66667C4 6.13733 5.196 7.33333 6.66667 7.33333ZM14.4375 2.8304C14.1595 2.58907 13.7389 2.61773 13.4969 2.89573L12.2529 4.32573L11.8302 3.85307C11.5855 3.5784 11.1642 3.55507 10.8889 3.80107C10.6149 4.0464 10.5909 4.4684 10.8369 4.7424L11.7629 5.77773C11.8895 5.91907 12.0702 5.99973 12.2595 5.99973H12.2642C12.4555 5.9984 12.6375 5.91507 12.7629 5.77107L14.5035 3.77107C14.7449 3.49307 14.7155 3.07173 14.4375 2.8304ZM11.3333 13.3333C11.3333 13.7013 11.0353 14 10.6667 14H2.66667C2.298 14 2 13.7013 2 13.3333C2 10.76 4.094 8.66667 6.66667 8.66667C9.23933 8.66667 11.3333 10.76 11.3333 13.3333Z" />
  </SvgIcon>
));

PersonDoneIcon.displayName = 'PersonDoneIcon';
