import * as React from 'react';
import { IButtonProps } from '@revfluence/fresh';

import {
  TermsWizard,
  IProps as TermsWizardProps,
} from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard';
import SwitchTermsType from '@frontend/applications/TermsApp/components/shared/SwitchTermsType/SwitchTermsType';
import {
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { MainContent } from './MainContent';
import { TContentGuideline } from './types/ContentGuidelines';
import {
  IState,
  TDispatch,
} from './types/state';
import { TContentUsageRights, WhitelistingLabel } from './types/CollaborationDetails';

export interface IProps {
  advancedTerms: boolean;
  daysToApproveContent: number;
  paymentPeriod: number;
  isBrandedContentEnabled: boolean;
  whitelistingLabel: WhitelistingLabel;
  isCreatorEditEnabled: boolean;
  contentRightsTemplates: Array<TContentUsageRights>;
  contentGuidelinesTemplates: Array<TContentGuideline>;
  dispatch: TDispatch;
  footerSettings: TermsWizardProps['footerSettings'];
  linkInsightsAlertForBrandedContent: boolean;
  linkInsightsAlertForPartnershipAds: boolean;
  linkBCAAccountAlert: boolean;
  tiktokSparkAdsAccountMissing: boolean;
  businessAccountMissing: boolean;
  initialMessage: string;
  templateId: number;
  onClickConnectInstagram: IButtonProps['onClick'];
  renderEmailComposer: (
    templateId: number,
    initialMessage: string,
    state: IState,
    dispatch: TDispatch,
  ) => React.ReactElement;
  state: IState;
  onSwitchTermsType: () => void;
  programId?:number;
}

export const LoadedBulkTerms: React.FC<IProps> = React.memo((props) => {
  const {
    advancedTerms,
    daysToApproveContent,
    paymentPeriod,
    isBrandedContentEnabled,
    whitelistingLabel,
    isCreatorEditEnabled,
    contentRightsTemplates,
    contentGuidelinesTemplates,
    dispatch,
    footerSettings,
    linkInsightsAlertForBrandedContent,
    linkInsightsAlertForPartnershipAds,
    linkBCAAccountAlert,
    businessAccountMissing,
    tiktokSparkAdsAccountMissing,
    onClickConnectInstagram,
    state,
    renderEmailComposer,
    initialMessage,
    templateId,
    onSwitchTermsType,
    programId,
  } = props;
  const isBasicTermsEnabled = useClientFeatureEnabled(ClientFeature.BASIC_TERMS);

  return (
    <TermsWizard footerSettings={footerSettings} state={state}>
      { isBasicTermsEnabled && (
        <SwitchTermsType
          text="Switch to Flexible Briefs"
          onSwitchTermsType={onSwitchTermsType}
        />
      )}
      <MainContent
        paymentPeriod={paymentPeriod}
        linkInsightsAlertForBrandedContent={linkInsightsAlertForBrandedContent}
        linkInsightsAlertForPartnershipAds={linkInsightsAlertForPartnershipAds}
        linkBCAAccountAlert={linkBCAAccountAlert}
        businessAccountMissing={businessAccountMissing}
        tiktokSparkAdsAccountMissing={tiktokSparkAdsAccountMissing}
        advancedTerms={advancedTerms}
        daysToApproveContent={daysToApproveContent}
        isBrandedContentEnabled={isBrandedContentEnabled}
        whitelistingLabel={whitelistingLabel}
        isCreatorEditEnabled={isCreatorEditEnabled}
        contentRightsTemplates={contentRightsTemplates}
        contentGuidelinesTemplates={contentGuidelinesTemplates}
        dispatch={dispatch}
        onClickConnectInstagram={onClickConnectInstagram}
        state={state}
        renderEmailComposer={renderEmailComposer}
        initialMessage={initialMessage}
        templateId={templateId}
        programId={programId}
      />
    </TermsWizard>
  );
});

LoadedBulkTerms.displayName = 'LoadedBulkTerms';
