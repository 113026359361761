import * as React from 'react';
import { Divider } from 'antd';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  Row,
  Space,
} from '@revfluence/fresh';
import {
  IToastRefHandles,
  Toast,
} from '@components';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { TProject } from '@frontend/app/containers/Projects/types';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { useOverviewPage } from '../../hooks';

import { Steps } from './steps/Steps';
import { AdditionalMethods } from './additional/AdditionalMethods';

import styles from './FindCreatorsPage.scss';

const { useEffect, useRef } = React;

interface IProps {
  project: TProject;
}

export const FindCreatorsPage: React.FC<IProps> = ({ project }) => {
  const toastRef = useRef<IToastRefHandles>();
  const addEvent = useEventContext();
  const history = useHistory();
  const location = useLocation();
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
  const { renderArchivedNotice } = useOverviewPage(project);

  useEffect(() => {
    if (location.state === 'project_created') {
      const toast = toastRef.current;
      history.replace({ ...location, state: null });
      toast.showMessage({
        content: 'Your project was successfully created.',
        type: 'success',
      });
    }
    addEvent(EventName.FindCreatorsPageVisit, {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.FindCreatorsPage}>
      {!isRefreshUIEnabled ? (
        <>
          <div className={styles.title}>
            <Space direction="vertical" size={[32, 32]}>
              {isArchiveProjectEnabled && renderArchivedNotice}
              <Row justify="space-between">
                <h1>Find Creators</h1>
              </Row>
            </Space>
          </div>
          <Divider className={styles.divider} />
        </>
      ) : <div className="p-4" />}

      <Steps project={project} />
      <Divider className={styles.divider} />
      <AdditionalMethods />
      <Toast useFresh ref={toastRef} />
    </div>
  );
};

FindCreatorsPage.displayName = 'FindCreatorsPage';
