import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_DASHBOARD_STATS_QUERY } from '@affiliates/queries';
import {
  GetDashboardStatistics,
  GetDashboardStatisticsVariables,
} from '@affiliates/queries/types/GetDashboardStatistics';

type TOptions = QueryHookOptions<GetDashboardStatistics> & {
  dateRange?: [string, string];
};

export const useGetDashboardStatsQuery = (options: TOptions = {}) => useQuery<GetDashboardStatistics, GetDashboardStatisticsVariables>(GET_DASHBOARD_STATS_QUERY, options);
