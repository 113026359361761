import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import WooCommerceSettingsPage from './WooSettingsPage';

const WooCommerceAppDashboard: React.FunctionComponent = () => {
  const navSettings = [
    {
      route: 'settings',
      displayName: 'Settings',
      component: WooCommerceSettingsPage,
    },
  ];

  const application = {
    displayName: 'WooCommerce',
    iconUrl: 'https://storage.googleapis.com/aspirex-static-files/woo-app/woo.svg',
  };

  return (
    <AppDashboardNav application={application} navLinks={navSettings} defaultRoute="settings" />
  );
};

export default WooCommerceAppDashboard;
