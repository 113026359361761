._SpinnerIcon_k25lr_1 {
  animation: _spin_k25lr_1 0.8s infinite linear;
}
@keyframes _spin_k25lr_1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}