import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUpsertBudgetAccountDistribution } from '@frontend/app/hooks/budgetAllocation/useUpsertBudgetAccountDistribution';
import { BudgetGranularity, FiscalYearBudgetBreakdown as FiscalYearBudgetBreakdownType } from '@frontend/app/types/Budget';
import { SackDollarIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetBudgetFiscalSettings } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalSettings';
import { GetBudgetFiscalSettingsQuery_budgetSettingsResponse } from '@frontend/app/queries/types/GetBudgetFiscalSettingsQuery';
import { v4 as uuidv4 } from 'uuid';
import BudgetAccountForm, { FormValues } from '../budgetAccountForm';
import PageSkeleton from '../PageSkeleton';

import styles from './CreateBudget.scss';

const renderIcon = () => (
  <div className={styles.iconContainer}>
    <SackDollarIcon className={styles.icon} />
  </div>
);

const INITIAL_VALUES: FormValues = {
  budgetName: '',
  budgetDescription: '',
  fiscalYearsBudget: [],
};

const CreateBudget: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const { upsertBudgetAccountDistribution, loading: saving } = useUpsertBudgetAccountDistribution();

  const { budgetSettingsResponse, loading }: { budgetSettingsResponse: GetBudgetFiscalSettingsQuery_budgetSettingsResponse, loading: boolean } = useGetBudgetFiscalSettings({});

  const [values, setValues] = useState<FormValues>({
    ...INITIAL_VALUES,
    budgetName: INITIAL_VALUES.budgetName || '',
    budgetDescription: INITIAL_VALUES.budgetDescription || '',
    parentBudgetId: undefined,
  });
  const isSettingsPage = useMemo(() => location.pathname.includes('settings'), [location.pathname]);
  useEffect(() => {
    if (budgetSettingsResponse) {
      const defaultFiscalYear = budgetSettingsResponse?.fiscalYears.find((fiscalYear) => fiscalYear?.isCurrentYear);
      setValues((prevValues) => ({
        ...prevValues,
        fiscalYearsBudget: [
          {
            id: uuidv4(),
            granularity: BudgetGranularity.YEARLY,
            fiscalYear: defaultFiscalYear.year,
            isNew: true,
            totalAmount: 0,
            availableAmount: 0,
            quarterBudgets: [0, 0, 0, 0],
            monthBudgets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          } as FiscalYearBudgetBreakdownType,
        ],
      }));
    }
  }, [budgetSettingsResponse, setValues]);

  const onSave = useCallback(async () => {
    try {
      await upsertBudgetAccountDistribution({
        variables: {
          budgetAccountInput: {
            parentBudgetId: values.parentBudgetId,
            ...values,
          },
        },
      });
      history.push(isSettingsPage ? '/settings/budget/dashboard' : '/budget/dashboard');
    } catch (e) {
      console.log(e);
    }
  }, [values, upsertBudgetAccountDistribution, history, isSettingsPage]);

  const getPageContent = useCallback(
    () => (
      <BudgetAccountForm
        values={values}
        setValues={setValues}
        budgetSettingsResponse={budgetSettingsResponse}
        showBudgetToLink
        disabledBudgetToLinkIfPresent={false}
        loading={loading}
        saving={saving}
        onSave={onSave}
        title="Budget Details"
        description="Provide basic details for your new budget account."
        saveButtonText="Save"
        isSettingsPage={isSettingsPage}
      />
    ),
    [values, setValues, onSave, saving, loading, budgetSettingsResponse, isSettingsPage],
  );
  return (
    <PageSkeleton title="Create Budget Account" backRedirectLink={`${isSettingsPage ? '/settings' : ''}/budget/dashboard`} renderIcon={renderIcon}>
      {getPageContent()}
    </PageSkeleton>
  );
};

export default CreateBudget;
