import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { Menu, Dropdown, Typography } from 'antd';
import { UserAvatar } from '@frontend/app/components';

import { useGetCurrentClient, useGetProfile } from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';

import styles from './TopNavbar.scss';
import dropdownStyles from './TopbarDropdown.scss';
import { NavItem } from './NavItem';
import { useTopNavigation } from './useTopNavigation';

interface IProps {
  className?: string;
}

const { useMemo } = React;
const { Title, Text } = Typography;

export const TopNavbar: React.FC<IProps> = React.memo(({ className }) => {
  const {
    mainNavItems,
    handleNavItemClick,
  } = useTopNavigation();

  const { user, logout } = useAuth();
  const { profile, loading: loadingProfile } = useGetProfile();
  const { client: currentClient, loading: loadingClient } = useGetCurrentClient();

  const loading = useMemo(() => loadingProfile || loadingClient, [loadingProfile, loadingClient]);

  const userSettingsMenu = useMemo(
    () => (
      <Menu className={dropdownStyles.TopNavbarSubmenu}>
        {!loading && (
          <>
            <Menu.Item key="userMenu">
              <div className={dropdownStyles.userMenuContent}>
                <Title level={4}>{currentClient?.name}</Title>
                <Text type="secondary" className={dropdownStyles.userEmail}>{profile.email}</Text>
              </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" onClick={logout}>
              Log Out
            </Menu.Item>
          </>
        )}
      </Menu>
    ),
    [loading, currentClient, profile, logout],
  );

  return (
    <div className={cx(styles.TopNavbar, className)}>
      <div className={styles.Left}>
        <div className={styles.logo}>
          <Link to="/">
            <img src="https://storage.googleapis.com/aspirex-static-files/new_logo.png" />
          </Link>
        </div>
        {mainNavItems.map((item) => (
          <NavItem key={item.id} item={item} onClick={handleNavItemClick} />
        ))}
      </div>
      <div className={styles.Right}>
        {/* <Text className={styles.name}>Group Review Portal</Text> */}
        <Dropdown
          overlay={userSettingsMenu}
          overlayClassName={dropdownStyles.TopNavbarDropdown}
          placement="bottomRight"
          mouseEnterDelay={0}
          mouseLeaveDelay={0.05}
        >
          <div className={styles.userMenu}>
            <UserAvatar profilePicture={user.picture} name={user.name} size={32} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
});

TopNavbar.displayName = 'TopNavbar';
