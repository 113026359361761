export type TNetworkIdentifier =
  | 'blog'
  | 'instagram'
  | 'youtube'
  | 'twitter'
  | 'facebook'
  | 'twitch'
  | 'snapchat'
  | 'pinterest'
  | 'tiktok';
export type TNetworkIconType =
  | TNetworkIdentifier
  | 'additional_images'
  | 'additional_videos'
  | 'other';
