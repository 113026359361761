import * as React from 'react';
import cx from 'classnames';
import { isNil, map, isEmpty } from 'lodash';

import {
  ISavedSearch,
  IToastRefHandles,
  Toast,
  ArrowDownFilledIcon,
  ArrowLeftIcon,
  TrashcanIcon,
  Button,
  IconButton,
  LazyImage,
  Input,
  OverlaySpinner,
  Popover,
} from '@components';
import { ISavedSearches } from './models/savedSearches';
import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';

import styles from './SavedSearches.scss';

const { useEffect, useRef, useState } = React;

interface IProps extends ISavedSearches {
  onSaveNewSearch(searchName: string);
  onUpdateSearch(savedSearch: ISavedSearch);
  onSelectSavedSearch(savedSearch: ISavedSearch);
  onDeleteSavedSearch(savedSearch: ISavedSearch);
}

export const SavedSearches: React.FunctionComponent<IProps> = (props) => {
  const {
    isSaving,
    isDeleting,
    onDeleteSavedSearch,
    onSaveNewSearch,
    onSelectSavedSearch,
    onUpdateSearch,
    savedSearches,
    selectedSearch,
    toast,
  } = props;

  const arrowRef = useRef();
  const toastRef = useRef<IToastRefHandles>();
  const [show, setShow] = useState(false);
  const [showNewSearchForm, setShowNewSearchForm] = useState(false);
  const addEvent = useEventContext();

  useEffect(() => {
    if (toast && toastRef.current) {
      toastRef.current.showMessage(toast);
    }
  }, [toast]);

  const handleClick = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShowNewSearchForm = () => {
    setShowNewSearchForm(true);
  };

  const handleHideNewSearchForm = () => {
    setShowNewSearchForm(false);
  };

  const handleSaveNewSearch = (searchName: string) => {
    handleHideNewSearchForm();
    onSaveNewSearch(searchName);
    addEvent(EventName.CreatorSearchSearchSaved, {});
  };

  const handleSelectSavedSearch = (savedSearch: ISavedSearch) => {
    handleClose();
    onSelectSavedSearch(savedSearch);
    addEvent(EventName.CreatorSearchSearchSelected, { 
      search_term: savedSearch.params.query,
    });
  };

  const handleUpdateSearch = (savedSearch: ISavedSearch) => {
    handleClose();
    onUpdateSearch(savedSearch);
    addEvent(EventName.CreatorSearchSearchSaved, { 
      search_term: savedSearch.params.query,
    });
  };

  const handleDeleteSavedSearch = (savedSearch: ISavedSearch, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onDeleteSavedSearch(savedSearch);
    addEvent(EventName.CreatorSearchSearchDeleted, { 
      search_term: savedSearch.params.query,
    });
  };

  const isSelected = (savedSearch: ISavedSearch) =>
    selectedSearch && selectedSearch.id === savedSearch.id;

  const renderLabel = () => (
    <div className={styles.label}>
      <span
        className={cx(styles.text, {
          [(styles as any).text__hasSelectedSearch]: !isNil(selectedSearch),
        })}
      >
        {selectedSearch?.id ? (
          <span className={styles.name}>
            {selectedSearch.name}
          </span>
        ) : (
          'Save & Load Searches'
        )}
      </span>
      <span ref={arrowRef} className={styles.arrow}>
        <ArrowDownFilledIcon size={10} />
      </span>
    </div>
  );

  const renderSavedSearchesList = () => (
    <>
      <div
        key={-1}
        className={styles.savedSearch}
        onClick={() => handleSelectSavedSearch({ params: {} })}
      >
        {'Save & Load Searches'}
      </div>
      {map(savedSearches, (savedSearch) => (
        <div
          key={savedSearch.id}
          className={cx(styles.savedSearch, {
            [styles.selected]: isSelected(savedSearch),
          })}
          onClick={() => handleSelectSavedSearch(savedSearch)}
        >
          {!!savedSearch.params.image_url && (
            <LazyImage src={savedSearch.params.image_url} className={styles.image} />
          )}
          <span className={styles.name}>
            {savedSearch.name}
          </span>
          <span
            className={styles.closeWrapper}
            onClick={(event) => handleDeleteSavedSearch(savedSearch, event)}
          >
            <TrashcanIcon className={styles.close} size={20} />
          </span>
        </div>
      ))}
    </>
  );

  const renderActionButtons = () => (
    <div className={styles.actionButtonsContainer}>
      {selectedSearch?.id ? (
        <>
          <Button
            theme="info"
            label="Update"
            onClick={() => handleUpdateSearch(selectedSearch)}
          />
          <Button
            theme="primary"
            label="New Search"
            onClick={() => handleShowNewSearchForm()}
          />
        </>
      ) : (
        <Button
          fullWidth
          label="Save New Search"
          onClick={() => handleShowNewSearchForm()}
        />
      )}
    </div>
  );

  return (
    <div className={styles.SavedSearches}>
      {(isSaving || isDeleting) && <OverlaySpinner />}
      <Button theme="info" fullWidth label={renderLabel()} onClick={handleClick} />
      <Popover
        className={styles.popover}
        mountRef={arrowRef}
        show={show}
        onRequestClose={handleClose}
        minWidth={310}
        maxWidth={310}
        placement="bottom"
      >
        {showNewSearchForm ? (
          <NewSearchForm onClickSave={handleSaveNewSearch} onClickBack={handleHideNewSearchForm} />
        ) : (
          <>
            {renderSavedSearchesList()}
            {renderActionButtons()}
          </>
        )}
      </Popover>
      <Toast useFresh ref={toastRef} />
    </div>
  );
};

interface INewSearchFormProps {
  onClickBack?();
  onClickSave(searchName: string);
}

const NewSearchForm: React.FunctionComponent<INewSearchFormProps> = (props) => {
  const { onClickSave, onClickBack } = props;

  const [searchName, setSearchName] = useState('');

  const handleSave = () => onClickSave(searchName);

  const handleChange = (text: string) => setSearchName(text);

  return (
    <div className={styles.NewSavedSearchForm}>
      <div className={styles.header}>
        <IconButton
          icon={<ArrowLeftIcon size={18} />}
          onClick={onClickBack}
          className={styles.iconButton}
        />
        Enter a name for the new search
      </div>
      <div className={styles.form}>
        <Input className={styles.input} value={searchName} onChange={handleChange} />
        <Button
          label="Save"
          onClick={handleSave}
          disabled={isEmpty(searchName)}
        />
      </div>
    </div>
  );
};
