import * as React from 'react';
import { Button } from '@revfluence/fresh';

interface IReconnectButtonProps {
  disconnectAccount: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export const ReconnectButton = (props: IReconnectButtonProps) => {
  const { disconnectAccount, disabled, onClick } = props;
  const buttonType = !!disconnectAccount;

  return (
    <Button onClick={onClick} danger={buttonType} disabled={disabled} size="small">Reconnect</Button>
  );
};
