import * as React from 'react';
import { filter } from 'lodash';
import { Typography, Space } from '@revfluence/fresh';

import { getContentTypeLabel } from '../utils';
import { TContentGuideline, IContentGuidelinesWithDueDates } from '../types/ContentGuidelines';

import ContentDeliverableTemplate from './ContentDeliverableTemplate';

const { useCallback } = React;
const { Text } = Typography;

interface IProps {
  contentGuidelinesTemplates: Array<TContentGuideline>;
  contentGuidelinesWithDueDates: Array<IContentGuidelinesWithDueDates>;
  onAddPostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
  onRemovePostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
}

const ContentDeliverableType: React.FC<IProps> = React.memo((props) => {
  const {
    contentGuidelinesTemplates,
    contentGuidelinesWithDueDates,
    onAddPostType,
    onRemovePostType,
  } = props;

  const filterByContentGuideline = useCallback(
    (
      contentGuidelineWithDueDates: IContentGuidelinesWithDueDates,
      contentGuideline: TContentGuideline,
    ) => contentGuidelineWithDueDates.id === contentGuideline?.id,
    [],
  );

  const getContentTypeInstances = useCallback((contentGuideline: TContentGuideline) => filter(
      contentGuidelinesWithDueDates,
      (contentGuidelineDueDate: IContentGuidelinesWithDueDates) => contentGuidelineDueDate.contentGuidelineInstanceId
        && filterByContentGuideline(contentGuidelineDueDate, contentGuideline),
    ), [contentGuidelinesWithDueDates, filterByContentGuideline]);

  return (
    <Space direction="vertical">
      <Text>
        {getContentTypeLabel(1, contentGuidelinesTemplates[0].type)}
      </Text>
      <Text strong>Select a guideline template</Text>
      <div>
        {
          contentGuidelinesTemplates.map((contentGuideline) => (
            <>
              {
                getContentTypeInstances(contentGuideline).map(
                  (contentGuidelineInstance) => (
                    <ContentDeliverableTemplate
                      key={contentGuidelineInstance.contentGuidelineInstanceId}
                      contentGuidelineInstanceId={contentGuidelineInstance.contentGuidelineInstanceId}
                      contentGuideline={contentGuidelineInstance}
                      contentGuidelinesWithDueDates={contentGuidelinesWithDueDates}
                      onAddPostType={onAddPostType}
                      onRemovePostType={onRemovePostType}
                    />
                  ),
                )
              }
              <ContentDeliverableTemplate
                key={contentGuideline.id}
                contentGuideline={contentGuideline}
                contentGuidelinesWithDueDates={contentGuidelinesWithDueDates}
                onAddPostType={onAddPostType}
                onRemovePostType={onRemovePostType}
              />
            </>
            ))
        }
      </div>
    </Space>
  );
});

ContentDeliverableType.displayName = 'ContentDeliverableType';

export default ContentDeliverableType;
