import React from 'react';
import { Button, Space } from '@revfluence/fresh';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useMessagingContext } from '@frontend/hooks';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { useBulkSendShopifyOrderRequest } from '@frontend/applications/ProductFulfillmentApp/hooks/useBulkSendShopifyOrderRequest';
import styles from './SendEmail.scss';
import getInitialEmailState from '../../utils/initialEmailStateSendOrderRequest';
import { CPSEmailComposer } from '../EmailComposer/EmailComposer';
import { isEmailAddressValid } from '../../../../../../../common/Utils';
import { useSendCatalogContext } from './SendCatalogContext';

export const SendEmail = ({ handleScreenChange }: { handleScreenChange: () => void }) => {
  const { shopifyResource, workItems, allMembers } = useProductFulfillmentContext();

  const { workflowActionParameters, closeModal } = useApplication();

  const {
 selectedCatalog, selectedCatalogs, selectedRule, selectedRules, selectionType,
} = useSendCatalogContext();

  const programId = workflowActionParameters.programId;

  const { showMessage } = useMessagingContext();

  const { bulkSendShopifyOrderRequest } = useBulkSendShopifyOrderRequest({
    onCompleted: () => {
      closeModal();
      showMessage({
        type: 'success',
        content: 'Order requests are being sent, feel free to continue working',
      });
    },
  });

  const createOrderRequest = async (messageParams: SendMessageInput) => {
    await bulkSendShopifyOrderRequest({
      variables: {
        members: allMembers.map((m) => ({
          id: m.id,
          name: m.name,
          email: m.email,
          workItemId: workItems?.find((wi) => wi?.data?.memberId === m.id)?.id,
          brandCatalogId: selectionType === 'bulk' ? selectedCatalog : selectedCatalogs[m.id],
          selectionRuleId:
            selectionType === 'bulk'
              ? selectedRule
                ? Number(selectedRule)
                : undefined
              : selectedRules[m.id]
              ? Number(selectedRules[m.id])
              : undefined,
        })),
        messageParams: {
          attachments: messageParams.attachments,
          membersSearchQuery: messageParams.membersSearchQuery,
          message: messageParams.message,
          resourceId: messageParams.resourceId,
          subject: messageParams.subject,
          type: messageParams.type.toLowerCase(),
          additionalCc: messageParams.additionalCc,
        },
        params: {
          programId,
          resourceId: shopifyResource.id,
          specURI: workflowActionParameters.workletSpecUri,
        },
      },
    });
  };

  const validMembers = allMembers ? allMembers.filter((m) => isEmailAddressValid(m.email)) : [];
  const memberIds = validMembers.map((m) => m.id);

  const CPSEmailComposerParams = {
    className: styles.EmailComposer,
    memberIds,
    customSend: createOrderRequest,
    getInitialState: getInitialEmailState,
  };

  return (
    <div className={styles.EmailContainer}>
      <div className={styles.MessageComposer}>
        <CPSEmailComposer {...CPSEmailComposerParams} />
      </div>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button type="default" onClick={handleScreenChange}>
          Back
        </Button>
      </Space>
    </div>
  );
};
