import * as React from 'react';
import cx from 'classnames';

import { EditorState } from 'draft-js';
import { IMessageTemplate } from '@frontend/app/hooks';
import { InlineStyles } from './InlineStyles';
import { ListTypes } from './ListTypes';
import { MediaTypes } from './MediaTypes';
import { AdditionalTypes } from './AdditionalTypes';
import {
 TBlockType, TInlineStyle, TMediaType,
} from './types';

import styles from './StyleControls.module.scss';

interface IProps {
  editorState: EditorState;
  getSelectedEntityType(): string;
  toggleBlockType(type: TBlockType): void;
  toggleInlineStyle(style: TInlineStyle): void;
  toggleMediaType(type: TMediaType, ref?: React.RefObject<HTMLInputElement>): void;
  onFilesSelected(files: FileList): void;
  showProgramVariables: boolean;
  enableVariableReplacement?: boolean;
  enableEmailTemplate?: boolean;
  invalidMemberIdsForField: {
    [field: string]: number[];
  };
  additionalVariables?: { [key: string]: { label: string } };
  className?: string;
  onTemplateSelected?: (template: IMessageTemplate) => void;
  enableMessageTemplate?: boolean;
}

const StyleControlsComponent = (props: IProps, ref) => {
  const { editorState } = props;

  return (
    <div className={cx(styles.StyleControls, props.className)}>
      <InlineStyles
        editorState={editorState}
        toggleInlineStyle={props.toggleInlineStyle}
      />
      <div className={styles.divider} />
      <ListTypes
        editorState={editorState}
        toggleBlockType={props.toggleBlockType}
      />
      <div className={styles.divider} />
      <MediaTypes
        editorState={editorState}
        getSelectedEntityType={props.getSelectedEntityType}
        toggleMediaType={props.toggleMediaType}
        onFilesSelected={props.onFilesSelected}
      />
      {(props.enableVariableReplacement || props.enableEmailTemplate) && (
        <>
          <div className={styles.divider} />
          <AdditionalTypes
            ref={ref}
            showProgramVariables={props.showProgramVariables}
            enableVariableReplacement={props.enableVariableReplacement}
            invalidMemberIdsForField={props.invalidMemberIdsForField}
            additionalVariables={props.additionalVariables}
            onTemplateSelected={props.onTemplateSelected}
            enableMessageTemplate={props.enableMessageTemplate}
          />
        </>
      )}
    </div>
  );
};

StyleControlsComponent.displayName = 'StyleControls';

export const StyleControls = React.forwardRef(StyleControlsComponent);
