import { IStepInfo as Step } from '@frontend/applications/Shared/Wizard/container';
import { TActions, TState } from './state/types';
import { IPaymentProps } from '../../types';
import { useState } from './useState';
import { useGetPaymentsSteps } from './getPaymentsSteps';

interface IReturnVal {
  actions: TActions;
  state: TState;
  steps: readonly Step[];
}

export const usePayments = (
  props: IPaymentProps,
): Readonly<IReturnVal> => {
  const { actions, state } = useState(props);
  const steps = useGetPaymentsSteps(props, state, actions);
  return { actions, state, steps };
};
