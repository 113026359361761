import * as React from 'react';
import {
 Card, Button, Typography, Tag,
} from '@revfluence/fresh';
import { ArrowsRotateIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './KlaviyoResource.scss';

interface IProps {
  userExternalDisplayId: string;
  startSync: () => void;
  disconnectResource: () => void;
  inProgress: boolean;
  isRevoking: boolean;
}

const KlaviyoResource: React.FC<IProps> = ({
  userExternalDisplayId,
  startSync,
  inProgress,
  isRevoking,
  disconnectResource,
}) => (
  <Card title="Connected Klaviyo Acount" className={styles.klaviyoSync}>
    <div className={styles.syncHeader}>
      <Typography.Text>{userExternalDisplayId}</Typography.Text>
      <div className={styles.actions}>
        <Button
          onClick={disconnectResource}
          type="primary"
          danger
          ghost
          loading={isRevoking}
        >
          Disconnect
        </Button>
        <Button
          onClick={() => startSync()}
          icon={<ArrowsRotateIcon />}
          type="primary"
          loading={inProgress}
        >
          {inProgress ? 'Syncing' : 'Sync'}
        </Button>
      </div>
    </div>
    <p>
      <Tag color="success">Connected</Tag>
    </p>
  </Card>
);

KlaviyoResource.displayName = 'KlaviyoResource';

export default React.memo(KlaviyoResource);
