const capitalize = (str) =>
(str
    ? str
        .split('_')
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(' ')
    : '');

export const getContentDeliverableLabel = (contentReview) => {
    const label = contentReview?.post?.post_type_display_name || `${capitalize(contentReview?.product?.deliverable_guidelines?.post_type)}` || contentReview?.product?.product_description;
    if (label === 'Flexible') return 'Deliverable';
    if (label === 'Additional Images') return 'Additional Image';
    if (label === 'Additional Videos') return 'Additional Video';
    if (label === 'Instagram') return 'Instagram Post';
    return label;
};
