import { ResourceType } from '@frontend/app/types/globalTypes';

const sizeLimits = {
  [ResourceType.GMAIL]: 25,
  [ResourceType.OUTLOOK]: 20,
};

export const isTooLarge = (size: number, resourceType: ResourceType): boolean =>
  sizeLimits[resourceType] && size > sizeLimits[resourceType] * 1000 * 1000;

export const sizeLimit = (resourceType: ResourceType): string =>
  (sizeLimits[resourceType] ? `${sizeLimits[resourceType]} MB` : '');
