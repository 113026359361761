import 'css-chunk:src/components/widgets/SocialProfile/Card/ListCard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1xp3p_157",
  "justify-content-space-between": "_justify-content-space-between_1xp3p_161",
  "tabular-nums": "_tabular-nums_1xp3p_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_1xp3p_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_1xp3p_166",
  "Card": "_Card_1xp3p_170",
  "tooltip": "_tooltip_1xp3p_306",
  "cardHeader": "_cardHeader_1xp3p_320",
  "tabs": "_tabs_1xp3p_331",
  "tabButton": "_tabButton_1xp3p_335",
  "activeTab": "_activeTab_1xp3p_354",
  "separator": "_separator_1xp3p_358",
  "cardTab": "_cardTab_1xp3p_365",
  "cardTitle": "_cardTitle_1xp3p_373",
  "visible": "_visible_1xp3p_381",
  "show": "_show_1xp3p_1",
  "noData": "_noData_1xp3p_386",
  "ListCard": "_ListCard_1xp3p_396",
  "item": "_item_1xp3p_396",
  "inline": "_inline_1xp3p_400",
  "items": "_items_1xp3p_400",
  "vertical": "_vertical_1xp3p_411"
};