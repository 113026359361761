/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_hua19_157 {
  display: flex;
}

._justify-content-space-between_hua19_161 {
  justify-content: space-between;
}

._tabular-nums_hua19_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_hua19_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferFormToolTip_hua19_178 {
  position: fixed;
  padding-left: 1.5rem;
  top: 38.125rem;
  width: 30.75rem;
}
._OfferFormToolTip_hua19_178 p {
  margin: 0;
  font-size: 12px;
}
._OfferFormToolTip_hua19_178 p._lead_hua19_188 {
  font-weight: bold;
}
._OfferFormToolTip_hua19_178 .anticon {
  position: absolute;
  left: 0;
  top: 0.25rem;
}