import 'css-chunk:src/applications/ReportsApp/components/MetricChart/MetricChart.module.scss';export default {
  "blue": "_blue_3fcl8_1",
  "chartColors-blue": "_chartColors-blue_3fcl8_8",
  "plum": "_plum_3fcl8_15",
  "chartColors-plum": "_chartColors-plum_3fcl8_22",
  "orange": "_orange_3fcl8_29",
  "chartColors-orange": "_chartColors-orange_3fcl8_36",
  "green": "_green_3fcl8_43",
  "chartColors-green": "_chartColors-green_3fcl8_50",
  "tooltipTitle": "_tooltipTitle_3fcl8_57",
  "tooltipDescription": "_tooltipDescription_3fcl8_62"
};