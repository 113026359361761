import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const NodeSegmentIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      clipRule="evenodd"
      d="
        M7 1C7 0.447715 7.44772 0 8 0C8.55228 0 9 0.447715 9 1V4C9 4.04201 8.99741 4.08342 8.99238
        4.12406C10.7215 4.56545 12 6.13342 12 8C12 9.86658 10.7215 11.4345 8.99238 11.8759C8.99741
        11.9166 9 11.958 9 12V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V12C7 11.958
        7.00259 11.9166 7.00762 11.8759C5.27853 11.4345 4 9.86658 4 8C4 6.13342 5.27853 4.56545
        7.00762 4.12406C7.00259 4.08342 7 4.04201 7 4V1ZM10 8C10 9.10457 9.10457 10 8 10C6.89543 10
        6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8Z
      "
      fillRule="evenodd"
    />
  </SvgIcon>
));

NodeSegmentIcon.displayName = 'NodeSegmentIcon';
