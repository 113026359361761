import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ALLOY_METADATA_QUERY } from '@frontend/app/queries';

import {
  GetAlloyMetadataQuery,
} from '@frontend/app/queries/types/GetAlloyMetadataQuery';

type IOptions = QueryHookOptions<GetAlloyMetadataQuery>;

export function useGetAlloyMetadata(options: IOptions = {}) {
  const {
    loading, data: { metadata } = {}, error, refetch,
  } = useQuery<GetAlloyMetadataQuery>(
    GET_ALLOY_METADATA_QUERY,
    options,
  );

  return {
    loading,
    metadata,
    error,
    refetch,
  };
}
