import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_DASHBOARD_PROJECT_TAB_SUMMARY from '../queries/GetBudgetDashboardProjectTabSummaryQuery';
import { GetBudgetDashboardProjectTabSummaryQuery, GetBudgetDashboardProjectTabSummaryQueryVariables } from '../queries/types/GetBudgetDashboardProjectTabSummaryQuery';

type IOptions = QueryHookOptions<GetBudgetDashboardProjectTabSummaryQuery, GetBudgetDashboardProjectTabSummaryQueryVariables>;

export function useGetBudgetDashboardProjectTabSummaryQuery(options: IOptions = {}) {
  return useQuery<GetBudgetDashboardProjectTabSummaryQuery, GetBudgetDashboardProjectTabSummaryQueryVariables>(
    GET_DASHBOARD_PROJECT_TAB_SUMMARY,
    options,
  );
}
