import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const PulseIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M11.99,18.26c-0.23,0-0.45-0.14-0.54-0.35L8.38,10.8l-2.15,3.07c-0.11,0.16-0.29,0.25-0.48,0.25H2.59
      C2.26,14.12,2,13.85,2,13.53s0.26-0.59,0.59-0.59h2.86l2.57-3.68c0.12-0.18,0.33-0.27,0.54-0.25c0.21,0.02,0.4,
      0.16,0.48,0.35 l2.85,6.6l2.97-9.8c0.07-0.24,0.28-0.4,0.53-0.42c0.25-0.01,0.47,0.13,0.57,0.35l2.99,
      6.85h2.46c0.32,0,0.59,0.26,0.59,0.59 s-0.26,0.59-0.59,0.59h-2.84c-0.23,0-0.45-0.14-0.54-0.35l-2.51-5.74l-2.97,
      9.81c-0.07,0.24-0.28,0.4-0.53,0.42 C12.02,18.26,12.01,18.26,11.99,18.26z"
    />
  </SvgIcon>
));

PulseIcon.displayName = 'PulseIcon';
