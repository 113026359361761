import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT from '@frontend/app/queries/GetBudgetDistributionsForBudgetAccount';
import { GetBudgetDistributionsForBudgetAccountQuery, GetBudgetDistributionsForBudgetAccountQueryVariables } from '@frontend/app/queries/types/GetBudgetDistributionsForBudgetAccountQuery';

type IOptions = QueryHookOptions<GetBudgetDistributionsForBudgetAccountQuery, GetBudgetDistributionsForBudgetAccountQueryVariables>;

export function useGetBudgetDistributionsForBudgetAccount(options: IOptions) {
    const {
        loading, data: { budgetDistributionsForBudgetAccount } = {}, error, refetch,
} = useQuery<GetBudgetDistributionsForBudgetAccountQuery, GetBudgetDistributionsForBudgetAccountQueryVariables>(GET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT, {
  ...options,
  fetchPolicy: 'no-cache',
});

    return {
      loading,
      budgetDistributionsForBudgetAccount,
      error,
      refetch,
    };
  }
