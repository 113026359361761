import * as React from 'react';
import {
  Empty,
  Typography,
  Row,
  Button,
  Space,
} from '@revfluence/fresh';
import {
  ListCheckIcon,
  PlusIcon,
} from '@revfluence/fresh-icons/regular/esm';

const ContentGuidelinesEmpty = React.memo(() => (
  <Empty
    style={{
      alignSelf: 'center',
    }}
    image={(
      <ListCheckIcon
        color="#F0F0F0"
        width={80}
        height={80}
      />
    )}
    imageStyle={{
      height: '48px',
      marginBottom: '52px',
    }}
    description={(
      <>
        <Typography.Title level={5}>
          No Projects
        </Typography.Title>
        <Typography.Paragraph
          type="secondary"
          style={{
            fontSize: '14px',
          }}
        >
          Content guidelines are associated with projects.
          Create your first project to start sending terms using content guideline templates.
        </Typography.Paragraph>
        <Row justify="center">
          <Space size={12}>
            <Button
              type="primary"
              icon={<PlusIcon />}
              href="../projects/new/templates"
            >
              New Project
            </Button>
          </Space>
        </Row>
      </>
    )}
  />

));

ContentGuidelinesEmpty.displayName = 'ContentGuidelinesEmpty';

export default ContentGuidelinesEmpty;
