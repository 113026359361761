/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_i8k32_157 {
  display: flex;
}

._justify-content-space-between_i8k32_161 {
  justify-content: space-between;
}

._tabular-nums_i8k32_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_i8k32_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ContentActionBar_i8k32_178 ._contentCount_i8k32_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ContentActionBar_i8k32_178 ._contentCount_i8k32_178 {
  margin-left: 10px;
  margin-bottom: 10px;
}
._ContentActionBar_i8k32_178 ._actions_i8k32_192 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
._ContentActionBar_i8k32_178 ._selectAll_i8k32_197 {
  margin-right: 16px;
  display: inline-flex;
  vertical-align: middle;
}
._ContentActionBar_i8k32_178 ._button_i8k32_202 {
  margin-right: 4px;
  vertical-align: middle;
}
._ContentActionBar_i8k32_178 ._contentSelected_i8k32_206 {
  margin-left: 8px;
}
._ContentActionBar_i8k32_178 ._badge_i8k32_209 {
  font-size: 12px;
}
._ContentActionBar_i8k32_178 ._sort_i8k32_212 {
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
}
._ContentActionBar_i8k32_178 ._sort_i8k32_212 ._sortLabel_i8k32_217 {
  display: flex;
  align-items: center;
  justify-content: center;
}

._sortOptionLabel_i8k32_223 {
  margin-left: 4px;
}