import * as React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';
import {
  LoadSpinner, Button, CheckCircleIcon, ClockIcon,
  SpinnerIcon,
} from '@components';
import { AlertIcon, RequirementsModal } from '@frontend/app/components';
import { midnight } from '@frontend/app/utils/date';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { useRequirementDetailQuery } from './hooks/useRequirementDetailQuery';
import { useMarkRequirementCompleteMutation } from './hooks/useMarkRequirementCompleteMutation';
import { FileDeliverable } from './FileDeliverable';

import styles from './RequirementDetail.scss';

const { useMemo, useState, useCallback } = React;

interface IProps {
  requirementId: number;
  onDelete?();
}

export const RequirementDetail: React.FC<IProps> = React.memo((props) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const addEvent = useEventContext();
  const {
    loading: isLoading,
    data: {
      requirement = null,
    } = {},
  } = useRequirementDetailQuery(props.requirementId);

  const [markComplete, {
    loading: isMarkingComplete,
  }] = useMarkRequirementCompleteMutation();

  const handleMarkComplete = () => {
    markComplete({
      variables: {
        requirementId: props.requirementId,
      },
    });
    const dueInDays = () => {
      const input = new Date(requirement.dueDate);
      const today = new Date();
      return input.getDate() - today.getDate() + 1;
    };
    addEvent(
      EventName.CompleteRequirement,
      {
        days_until_due: dueInDays(),
      },
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggleEditModal = useCallback(setShowEditModal.bind(this, (s) => !s), []);

  const isPastDue = useMemo(() => {
    if (!requirement) {
      return;
    }

    if (requirement.completed) {
      return false;
    }

    return moment(requirement.dueDate).isBefore(midnight(new Date()));
  }, [requirement]);

  return (
    <div className={styles.RequirementDetail}>
      {isLoading && (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <LoadSpinner className={(styles as any).spinner} />
      )}
      {requirement && (
        <div>
          <div className={styles.header}>
            <div className={styles.title}>{requirement.name}</div>

            <div className={cx(styles.badge, {
              [styles.completed]: requirement.completed,
              [styles.pastDue]: isPastDue,
            })}
            >
              {requirement.completed && <CheckCircleIcon className={styles.icon} size={20} />}
              {isPastDue && <AlertIcon className={styles.icon} size={20} />}
              {!requirement.completed && !isPastDue && <ClockIcon className={styles.icon} size={20} />}

              <span className={styles.dueDate}>
                {isPastDue && 'Was due by'}
                {!isPastDue && 'Due by'}
                &nbsp;
                {moment(requirement.dueDate).format('M/D/YY')}
              </span>
            </div>
          </div>

          {!isEmpty(requirement.deliverables) && (
            <>
              <div className={styles.subtitle}>
                Deliverables:
              </div>

              <div className={styles.deliverables}>
                {map(requirement.deliverables, (deliverable) => {
                  switch (deliverable.type) {
                    case 'file':
                      return (
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        <FileDeliverable key={deliverable.id} dueDate={requirement.dueDate as any} files={deliverable.payload.files} />
                      );

                    default:
                      return null;
                  }
                })}
              </div>
            </>
          )}

          <div className={styles.actions}>
            <Button
              label="Mark Complete"
              theme="primary"
              icon={isMarkingComplete && <SpinnerIcon size={16} />}
              disabled={isMarkingComplete || requirement.completed}
              onClick={handleMarkComplete}
            />

            <Button
              label="Edit"
              theme="info"
              disabled={isMarkingComplete}
              onClick={handleToggleEditModal}
            />
          </div>

          <RequirementsModal
            show={showEditModal}
            requirement={requirement}
            onRequestClose={handleToggleEditModal}
            onDelete={props.onDelete}
          />
        </div>
      )}
    </div>
  );
});

RequirementDetail.displayName = 'RequirementDetail';
