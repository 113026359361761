import { useCallback } from 'react';
import { useAuth } from '@frontend/context/authContext';

export const SYNC_STA_PARAM = 'sync_sta';

interface IParams {
  supportCreateOrder: boolean;
  supportPromoCodes: boolean;
  supportPromoCodeCustomerSegmentation: boolean;
  shoplessEnabled: boolean;
  redirectionURL?: string;
  shop?: string;
}

export const useAddShopifyAccount = () => {
  const { token } = useAuth();

  return useCallback(async (params: IParams) => {
    const encodedParams = encodeURIComponent(JSON.stringify(params));
    const redirectURL = encodeURIComponent(`${window.location.origin + window.location.pathname}?${SYNC_STA_PARAM}=true`);
    try {
      const res = await fetch(
        `/api/resource_authentication/auth_url?redirectURL=${redirectURL}&resourceType=shopify&params=${encodedParams}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
);

      const data = await res.json();

      if (data && data.url) {
        window.location.assign(data.url);
      }
    } catch (err) {
      console.log('err', err);
    }
  }, [token]);
};
