// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveSocialPostToDeliverable = async (url: string, params: any) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};
