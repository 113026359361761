import 'css-chunk:src/app/containers/Projects/LandingPages/LeftPanel/FormFields/AllSchemas.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_bzcl9_157",
  "justify-content-space-between": "_justify-content-space-between_bzcl9_161",
  "tabular-nums": "_tabular-nums_bzcl9_165",
  "AllSchemas": "_AllSchemas_bzcl9_178",
  "search": "_search_bzcl9_184",
  "field": "_field_bzcl9_191",
  "header": "_header_bzcl9_194",
  "schemas": "_schemas_bzcl9_199",
  "linksContainer": "_linksContainer_bzcl9_205",
  "link": "_link_bzcl9_205",
  "CreateFieldModal": "_CreateFieldModal_bzcl9_217",
  "show": "_show_bzcl9_1"
};