/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1oc0z_157 {
  display: flex;
}

._justify-content-space-between_1oc0z_161 {
  justify-content: space-between;
}

._tabular-nums_1oc0z_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1oc0z_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._buttonWithGap_1oc0z_178 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
._buttonWithGap_1oc0z_178 ._buttonWithoutGap_1oc0z_183 {
  display: flex;
  align-items: center;
}
._buttonWithGap_1oc0z_178._selected_1oc0z_187 {
  color: #167CF4;
  border-color: #167CF4;
  background-color: #F5F5F5;
}
._buttonWithGap_1oc0z_178:hover {
  color: #499DFF;
  border-color: #499DFF;
}
._buttonWithGap_1oc0z_178:active {
  color: #2771C9;
  border-color: #2771C9;
}

._buttonWithoutGap_1oc0z_183 {
  display: flex;
  align-items: center;
}
._buttonWithoutGap_1oc0z_183._selected_1oc0z_187 {
  color: #167CF4;
  border-color: #167CF4;
  background-color: #F5F5F5;
}
._buttonWithoutGap_1oc0z_183:hover {
  color: #499DFF;
  border-color: #499DFF;
}
._buttonWithoutGap_1oc0z_183:active {
  color: #2771C9;
  border-color: #2771C9;
}

._searchBar_1oc0z_219 {
  max-width: 18.75rem;
}

._toolBarContainer_1oc0z_223 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

._searchContainer_1oc0z_230 {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 20rem;
}