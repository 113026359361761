import * as React from 'react';
import cx from 'classnames';
import {
  ErrorTag,
  SuccessTag,
  DeactivateButton,
  ActivateButton,
  InactiveText,
  WarningTag,
  MetaSettingsPopover,
  PopoverType,
  NAText,
  IMetaSettingsPopoverProps,
} from '@frontend/app/components/InstagramMeta/MetaItems';
import { IInstagramAccount, disableInstagramTableButton } from '@frontend/app/components/InstagramMeta/MetaItems/InstagramAccountTable';

import styles from '@frontend/app/components/InstagramMeta/MetaItems/MetaItems.module.scss';
import { CellStatus } from './utils/composeCells';
import { IAdAccount } from './AdAccountTable';
import { IBusiness } from './AllowlistingTable';

export const renderFeatures = (popoverProps: IMetaSettingsPopoverProps, TagComponent: React.ReactNode) => (
  <MetaSettingsPopover {...popoverProps}>
    {TagComponent}
  </MetaSettingsPopover>
);

const renderTagComponent = (type: CellStatus) => {
  switch (type) {
    case CellStatus.ACTIVE:
      return SuccessTag;
    case CellStatus.INACTIVE:
      return InactiveText;
    case CellStatus.WARNING:
      return WarningTag;
    case CellStatus.DISCONNECTED:
      return ErrorTag;
    case CellStatus.NA:
      return NAText;
  }
};

export const renderSocialListeningTag = (
  record: IInstagramAccount,
  igEstimatedImpressionsEnabled: boolean,
) => {
  const { features } = record.socialListening;
  const TagComponent = renderTagComponent(record.socialListening.cell);
  let popoverType = PopoverType.FEATURES;
  if (record.socialListening.cell === CellStatus.DISCONNECTED) {
    popoverType = PopoverType.DISCONNECTED;
  }
  if (
    record.socialListening.cell === CellStatus.NA
    || record.socialListening.cell === CellStatus.INACTIVE
  ) {
    popoverType = PopoverType.HIDDEN;
  }
  const filteredFeatures = features.filter((feature) => {
    if (feature.feature === 'Story mentions') {
      return igEstimatedImpressionsEnabled;
    }
    return true;
  });
  return renderFeatures({
    features: filteredFeatures,
    popoverType,
  }, <TagComponent />);
};

export const renderAdTag = (
  record: IAdAccount,
) => {
  const TagComponent = renderTagComponent(record.cell);
  let popoverType = PopoverType.FEATURES;
  if (
    record.accessLost
    && record.features.filter(
      (feature) => feature.errorMessage,
    ).length > 0
  ) {
    popoverType = PopoverType.AD_ACCOUNT_ACCESS_LOST;
  } else if (record.cell === CellStatus.DISCONNECTED) {
    popoverType = PopoverType.DISCONNECTED;
  } else if (
    record.cell === CellStatus.NA
    || record.cell === CellStatus.INACTIVE
  ) {
    popoverType = PopoverType.HIDDEN;
  }
  return renderFeatures({
    features: record.features,
    popoverType,
  }, <TagComponent />);
};

export const renderBusinessTag = (
  record: IBusiness,
) => {
  const TagComponent = renderTagComponent(record.cell);
  const popoverType = PopoverType.FEATURES;

  return renderFeatures({
    features: record.features,
    popoverType,
  }, <TagComponent />);
};

export const renderInstagramDirectMessageTag = (
  isLoadingIGDM: boolean,
  record: IInstagramAccount,
  activateAccount: () => void,
  deactivateAccount: () => void,
) => {
  const CellComponent = renderTagComponent(record.directMessaging.cell);
  const shouldDisable = !record.loading?.igdmLoading && isLoadingIGDM;
  let popoverType = PopoverType.FEATURES;
  if (record.directMessaging.cell === CellStatus.DISCONNECTED) {
    popoverType = PopoverType.DISCONNECTED;
  }
  if (
    record.directMessaging.cell === CellStatus.NA
    || record.directMessaging.cell === CellStatus.INACTIVE
  ) {
    popoverType = PopoverType.HIDDEN;
  }
  if (record.directMessaging.appNotInstalled) {
    popoverType = PopoverType.IGDM_APP_NOT_INSTALLED;
  }
  if (record.directMessaging.otherClientConnected) {
    popoverType = PopoverType.IGDM_ACCOUNT_ALREADY_CONNECTED;
  }
  let TagComponent = <CellComponent />;
  if (record.directMessaging.cell === CellStatus.INACTIVE) {
    TagComponent = (
      <span className={styles.Main}>
        {!record.loading?.igdmLoading && <CellComponent className={styles.Hide} />}
        <ActivateButton
          className={cx({ [styles.Show]: !record.loading?.igdmLoading })}
          activateAccount={activateAccount}
          loading={record.loading?.igdmLoading}
          disabled={shouldDisable || disableInstagramTableButton(record.loading, 'igdm')}
        />
      </span>
    );
  }
  if (record.directMessaging.cell === CellStatus.ACTIVE
      || record.directMessaging.cell === CellStatus.WARNING) {
    TagComponent = (
      <span className={styles.Main}>
        {!record.loading?.igdmLoading && <CellComponent className={styles.Hide} />}
        <DeactivateButton
          className={cx({ [styles.Show]: !record.loading?.igdmLoading })}
          deactivateAccount={deactivateAccount}
          loading={record.loading?.igdmLoading}
          disabled={shouldDisable || disableInstagramTableButton(record.loading, 'igdm')}
        />
      </span>
    );
  }
  return renderFeatures({
    features: record.directMessaging.features,
    popoverType,
  }, TagComponent);
};

export const renderBCATag = (
  isLoadingBCA: boolean,
  record: IInstagramAccount,
  activateAccount: () => void,
) => {
  const shouldDisable = !record.loading?.bcaLoading && isLoadingBCA;
  const CellComponent = renderTagComponent(record.brandedContentAds.cell);
  let popoverType = PopoverType.BCA;
  if (record.brandedContentAds.cell === CellStatus.DISCONNECTED) {
    popoverType = PopoverType.DISCONNECTED;
  }
  if (
    record.brandedContentAds.cell === CellStatus.NA
    || record.brandedContentAds.cell === CellStatus.INACTIVE
  ) {
    popoverType = PopoverType.HIDDEN;
  }
  if (record.brandedContentAds.otherClientConnected) {
    popoverType = PopoverType.BCA_ACCOUNT_ALREADY_CONNECTED;
  }
  let TagComponent = <CellComponent />;
  if (record.brandedContentAds.cell === CellStatus.INACTIVE) {
    TagComponent = (
      <span className={styles.Main}>
        {!record.loading?.bcaLoading && <CellComponent className={styles.Hide} />}
        <ActivateButton
          className={cx({ [styles.Show]: !record.loading?.bcaLoading })}
          activateAccount={activateAccount}
          loading={record.loading?.bcaLoading}
          disabled={shouldDisable || disableInstagramTableButton(record.loading, 'bca')}
        />
      </span>
    );
  }
  if (record.brandedContentAds.cell === CellStatus.ACTIVE) {
    TagComponent = (
      <CellComponent />
    );
  }
  return renderFeatures({
    features: record.brandedContentAds.features,
    popoverType,
  }, TagComponent);
};
