import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const ZoomIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M9.2,14.8c3.1,0,5.6-2.5,5.6-5.6c0-3.1-2.5-5.6-5.6-5.6S3.6,6.1,3.6,9.2C3.6,12.3,6.1,14.8,9.2,14.8z
      M21.8,20.6c0.3,0.3,0.3,0.8,0,1.2c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4-0.1-0.6-0.2l-6.9-6.9c-1.2,1-2.8,1.6-4.5,1.6
      c-4,0-7.2-3.2-7.2-7.2S5.2,2,9.2,2s7.2,3.2,7.2,7.2c0,1.7-0.6,3.2-1.6,4.5L21.8,20.6z M12.3,8.5c0.5,0,0.8,0.4,0.8,0.8
      c0,0.5-0.4,0.8-0.8,0.8h-2.2v2.2c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8v-2.2H6.3c-0.5,0-0.8-0.4-0.8-0.8
      c0-0.5,0.4-0.8,0.8-0.8h2.2V6.3c0-0.5,0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8v2.2H12.3z"
    />
  </SvgIcon>
));

ZoomIcon.displayName = 'ZoomIcon';

export { ZoomIcon };
