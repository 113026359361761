import * as React from 'react';
import cx from 'classnames';

import styles from './AppIcon.scss';

interface IProps {
  iconUrl: string;
  className?: string;
}

export const AppIcon: React.FunctionComponent<IProps> = (props) => (
  <img className={cx(props.className, styles.AppIcon)} src={props.iconUrl} />
  );
