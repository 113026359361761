import * as React from 'react';
import { map, reject } from 'lodash';
import * as qs from 'qs';
import { useHistory } from 'react-router-dom';

import {
  Button, Space, Spinner, Tooltip,
} from '@revfluence/fresh';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { useFeatureFlagVerbiage, useMemberProgramsAndCommunitiesQuery, useModifyCommunityMembersMutation } from '@frontend/app/hooks';
import { ModifyCommunityMembersModal } from '@frontend/app/components';
import { MemberProgramsAndCommunitiesQuery_member_communities } from '@frontend/app/queries/types/MemberProgramsAndCommunitiesQuery';
import { TagItem } from '../TagItem';

const { useState, useMemo } = React;

type ICommunity = MemberProgramsAndCommunitiesQuery_member_communities;

interface Props {
  memberId?: number;
  selectedCommunities?: ICommunity[];
  onChangeCommunities?(communities: ICommunity[]);
}

const Groups = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removingCommunityId, setRemovingCommunityId] = useState<number>(null);
  const history = useHistory();
  const verbiage = useFeatureFlagVerbiage();

  const {
    data: {
      member: {
        communities: memberCommunities = null,
      } = {},
    } = {},
    loading: isLoadingMemberData,
    refetch: refetchMember,
  } = useMemberProgramsAndCommunitiesQuery(props.memberId, {
    fetchPolicy: 'no-cache',
  });

  const handleAddCommunities = (communities: ICommunity[]) => {
    if (props.onChangeCommunities) {
      props.onChangeCommunities(communities);
    }

    if (props.memberId) {
      refetchMember();
    }
  };

  const [removeMemberFromCommunity] = useModifyCommunityMembersMutation('remove', {
    async onCompleted() {
      await refetchMember();
      setRemovingCommunityId(null);
    },
    onError() {
      setRemovingCommunityId(null);
    },
  });

  const handleRemoveCommunity = async (communityId: number) => {
    if (!props.memberId) {
      if (props.onChangeCommunities) {
        const nextCommunities = reject(props.selectedCommunities, { id: communityId });
        props.onChangeCommunities(nextCommunities);
      }
      return;
    }

    setRemovingCommunityId(communityId);

    await removeMemberFromCommunity({
      variables: {
        memberIds: [props.memberId],
        communityIds: [communityId],
      },
    });
  };

  const memberIds = useMemo(() => {
    if (props.memberId) {
      return [props.memberId];
    } else {
      return [];
    }
  }, [props.memberId]);

  const communities = memberCommunities || props.selectedCommunities;

  return (
    <Space direction="vertical" size="small">
      {isLoadingMemberData && (
        <Spinner />
      )}

      {map(communities, (community) => (
        <TagItem
          key={community.id}
          color="processing"
          closable
          loading={removingCommunityId === community.id}
          onClick={() => {
            history.push({
              pathname: '/member_table',
              search: qs.stringify({
                communityId: community.id,
              }),
            });
          }}
          onClose={(e) => {
            e.preventDefault();
            handleRemoveCommunity(community.id);
          }}
        >
          {community.title}
        </TagItem>
      ))}

      <Tooltip title={`Add to ${verbiage.Community}`}>
        <Button
          icon={<PlusIcon />}
          size="small"
          onClick={() => setIsModalOpen(true)}
        />
      </Tooltip>

      <ModifyCommunityMembersModal
        show={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        type="add"
        memberIds={memberIds}
        memberCount={1}
        onModifyComplete={handleAddCommunities}
      />
    </Space>
  );
};

export default Groups;
