import { isNull } from 'lodash';
import moment from 'moment';

import {
  ACTIVE_DATE_FORMAT,
  ACTIVE_TIME_FORMAT,
  TActiveDates,
  TMember,
} from '../types';

export const mapMemberToActiveDates = (member: TMember): TActiveDates => {
  let toReturn: TActiveDates = {};

  if (!isNull(member.previousCode.start)) {
    const startMoment = moment(member.previousCode.start);
    if (startMoment.isValid()) {
      toReturn = {
        startDate: startMoment.format(ACTIVE_DATE_FORMAT),
        startTime: startMoment.format(ACTIVE_TIME_FORMAT),
      };
    }
  }

  if (!isNull(member.previousCode.end)) {
    const endMoment = moment(member.previousCode.end);
    if (endMoment.isValid()) {
      toReturn = {
        ...toReturn,
        endDate: endMoment.format(ACTIVE_DATE_FORMAT),
        endTime: endMoment.format(ACTIVE_TIME_FORMAT),
      };
    }
  }

  return toReturn;
};
