import * as React from 'react';
import cx from 'classnames';
import {
 filter, map, pick, trim,
} from 'lodash';
import { Button, OverlaySpinner } from '@components';

import { ProgramInput } from '@api/src/graphql/inputs';
import { EventName } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { SimpleForm } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context/EventContext';

import {
  ProgramByIdQuery_program as IProgram,
} from '@frontend/app/queries/types/ProgramByIdQuery';
import {
  CommunityByIdQuery_community as ICommunity,
} from '@frontend/app/queries/types/CommunityByIdQuery';
import { ProgramBasics } from './ProgramBasics';
import { ProgramOnboarding } from './ProgramOnboarding';
import { useSaveProgramMutation } from '../hooks/useSaveProgramMutation';

import styles from './ProgramDetails.scss';

const { useState, useEffect, useRef } = React;

enum ProgramTab {
  Basics = 'basics',
  Onboarding = 'onboarding',
}

interface IProps {
  community: ICommunity;
  program: ProgramInput;
  onSave(program: IProgram);
  className?: string;
}

export const ProgramDetails: React.FunctionComponent<IProps> = (props) => {
  const [programInput, setProgramInput] = useState<ProgramInput>(null);
  const [selectedTab, setSelectedTab] = useState<ProgramTab>(ProgramTab.Basics);
  const [didEdit, setDidEdit] = useState<boolean>(false);
  const basicFormRef = useRef<SimpleForm>();
  const onboardingFormRef = useRef<SimpleForm>();
  const whichWasSavedRef = useRef<'form' | 'customLandingPagePath'>();

  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();

  const getProgramInputFromProps = (): ProgramInput => {
    const { program } = props;
    if (program) {
      return {
        id: program.id || undefined,
        title: program.title,
        communityId: program.id ? undefined : props.community.id,
        columns: {
          memberFieldSchemaIds: program.columns.memberFieldSchemaIds,
          dbColumns: program.columns.dbColumns,
        },
        applicationFormFields: {
          memberFieldSchemas: map(program.applicationFormFields?.memberFieldSchemas, (schema) => (
            pick(schema, 'schemaId', 'label', 'required')
          )),
          dbColumns: map(program.applicationFormFields?.dbColumns, (col) => (
            pick(col, 'name', 'label', 'required')
          )),
        },
        customLandingPagePath: program.customLandingPagePath,
        owner: program.owner,
      };
    }
    return null;
  };

  useEffect(() => {
    const program = getProgramInputFromProps();
    setProgramInput(program);
    setDidEdit(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.program]);

  const [saveProgram, {
    loading: savingProgram,
  }] = useSaveProgramMutation(props.community.id, {
    onCompleted(result) {
      const { program } = result;
      props.onSave({
        ...program,
        specKey: null,
      });
      if (whichWasSavedRef.current === 'customLandingPagePath') {
        showSuccessMessage('Successfully saved the custom program page URL');
      } else {
        showSuccessMessage('Successfully saved your changes');
      }
      setDidEdit(false);
    },
    onError(error) {
      showError(error);
    },
  });

  const handleChangeProgramInput = (programInput: ProgramInput) => {
    setDidEdit(true);
    setProgramInput(programInput);
  };

  const handleSaveForm = () => {
    const program: ProgramInput = {
      ...programInput,
      columns: {
        dbColumns: map(programInput.applicationFormFields.dbColumns, (col) => col.name),
        memberFieldSchemaIds: map(programInput.applicationFormFields.memberFieldSchemas, (field) => field.schemaId),
      },
      applicationFormFields: {
        ...programInput.applicationFormFields,
        memberFieldSchemas: filter(programInput.applicationFormFields.memberFieldSchemas, (schema) => schema.schemaId > 0),
      },
    };
    whichWasSavedRef.current = 'form';
    return saveProgram({
      variables: {
        program,
      },
    });
  };

  const handleSaveCustomLandingPagePath = (customLandingPagePath: string) => {
    const program = getProgramInputFromProps(); // Ignore temporary changes on programInput
    if (program) {
      whichWasSavedRef.current = 'customLandingPagePath';
      return saveProgram({
        variables: {
          program: {
            ...program,
            customLandingPagePath: trim(customLandingPagePath),
          },
        },
      });
    }
  };

  const addEvent = useEventContext();

  const handleSave = () => {
    const program = getProgramInputFromProps();
    if (program.id) {
      addEvent(EventName.EditProgram, {});
    } else {
      addEvent(EventName.CreateProgram, {});
    }
    switch (selectedTab) {
      case ProgramTab.Basics: {
        return basicFormRef.current.submit();
      }

      case ProgramTab.Onboarding:
        return onboardingFormRef.current.submit();

      default:
        break;
    }
  };

  return (
    <div className={cx(styles.ProgramDetails, props.className)}>
      <div className={styles.tabs}>
        <div
          onClick={setSelectedTab.bind(this, ProgramTab.Basics)}
          className={cx(styles.tab, { [styles.selected]: selectedTab === ProgramTab.Basics })}
        >
          Basics
        </div>
        <div
          onClick={setSelectedTab.bind(this, ProgramTab.Onboarding)}
          className={cx(styles.tab, { [styles.selected]: selectedTab === ProgramTab.Onboarding })}
        >
          Onboarding
        </div>
      </div>
      <div className={styles.content}>
        <ProgramBasics
          ref={basicFormRef}
          communityId={props.community.id}
          programInput={programInput}
          onSubmit={handleSaveForm}
          onChange={handleChangeProgramInput}
          className={cx({ [styles.selected]: selectedTab === ProgramTab.Basics })}
        />
        <ProgramOnboarding
          ref={onboardingFormRef}
          programInput={programInput}
          onChange={handleChangeProgramInput}
          onSubmit={handleSaveForm}
          onCustomLandingPagePathSave={handleSaveCustomLandingPagePath}
          className={cx({ [styles.selected]: selectedTab === ProgramTab.Onboarding })}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.save}>
        <Button
          label="Save"
          onClick={handleSave}
          disabled={!didEdit}
        />
      </div>
      {savingProgram && <OverlaySpinner />}
    </div>
  );
};
