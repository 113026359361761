import * as React from 'react';
import * as qs from 'qs';
import {
 filter, flatten, forEach, isArray, map, size,
} from 'lodash';

import { endpoints } from '@components';
import { IMemberProgram } from '@components';
import { useGet } from '@frontend/utils';

import { useInviteContext } from './useInviteContext';
import { IInviteProps } from '../Invite';
import { getSocialAccountsFromProspects } from '../utils';

const { useEffect } = React;

/**
 * Fetch programs for a specific member
 * All are mapped within `memberPrograms` by respective usernames
 */
export const useUpdateMemberPrograms = (member: IInviteProps['prospect']) => {
  const socialAccounts = isArray(member)
    ? getSocialAccountsFromProspects(...member)
    : getSocialAccountsFromProspects(member);
  const socialAccountIds = map(socialAccounts, (socialAccount) => socialAccount.id);

  const {
    apiEndpoint,
    clientId,
    updateFetchingSocialAccountIds,
    updateMemberPrograms,
  } = useInviteContext();

  const fetchMemberProgramsURL: string = (() => {
    if (socialAccountIds.length > 0) {
      updateFetchingSocialAccountIds(...socialAccountIds);
      const query = {
        client_id: clientId,
        network_type: socialAccounts[0].network_identifier,
        account_ids: socialAccountIds,
      };
      const queryString = qs.stringify(query, { arrayFormat: 'brackets' });
      return `${apiEndpoint}/${endpoints.programs}/for_social_accounts?${queryString}`;
    }
  })();

  const {
    loading: isFetchingMemberPrograms,
    data: fetchedMemberPrograms,
    error: fetchMemberProgramsError,
    refetch: refetchMemberPrograms,
  } = useGet<IMemberProgram[][]>({ url: fetchMemberProgramsURL });

  useEffect(() => {
    if (isArray(fetchedMemberPrograms)) {
      const fetchedPrograms = flatten(fetchedMemberPrograms);
      if (size(fetchedPrograms) > 0) {
        forEach(socialAccounts, (socialAccount) => {
          updateMemberPrograms(
            socialAccount.id,
            filter(
              fetchedPrograms,
              (program) => (
                program.username
                  ? program.username === socialAccount.username
                  : program.social_account_id === socialAccount.id
              ),
            ),
          );
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedMemberPrograms]);

  return {
    isFetching: isFetchingMemberPrograms,
    error: fetchMemberProgramsError,
    refetchMemberPrograms,
  };
};
