import * as React from 'react';
import cn from 'classnames';
import { Card, Col, Row } from '@revfluence/fresh';

import Statistics from '../Statistics';
import MetricChart from '../MetricChart';

import { MetricProps } from './Metric.types';
import styles from './Metric.module.scss';

const Metric = ({
  primaryStatistics,
  secondaryStatistics,
  color = 'blue',
  chart = [{ value: 1 }, { value: 1 }, { value: 1 }, { value: 1 }],
}: MetricProps) => {
  const classes = cn(styles[color]);

  return (
    <Card bordered={false} className={classes}>
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <Statistics className={cn(styles.statistic)} {...primaryStatistics} />
        </Col>
        <Col xs={24}>
          <Row justify="space-between" align="bottom">
            <Col>
              <Statistics size="small" {...secondaryStatistics} className={cn(styles.trend)} />
            </Col>
            <Col>
              <MetricChart color={color} chart={chart} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default Metric;
