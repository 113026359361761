import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
 map, slice, sortBy, sumBy, filter, truncate,
} from 'lodash';
import { Tooltip } from '@components';

import { IBarChartProps } from './interfaces';

const { useMemo, useRef } = React;
import styles from './BarChartHorizontal.scss';

/**
 * @type {React.FunctionComponent}
 */
export const BarChartHorizontal: React.FunctionComponent<IBarChartProps> = React.memo(
  (props) => {
    const {
      className,
      data,
      disableSort,
      height,
      maxRecords: maxRecordsProp,
      showRawValue,
      showPercentage,
    } = props;

    const maxRecords = useMemo(
      () => maxRecordsProp || Math.floor(height / 40),
      [height, maxRecordsProp],
    );

    const ref = useRef<HTMLDivElement>();

    const [records, totalValue, otherValuesLabels = null] = useMemo(() => {
      const sortedRawData = disableSort ? data : sortBy(data, (d) => -d.value);
      const sum = sumBy(data, 'value');
      const otherValues = slice(sortedRawData, maxRecords - 1);
      const otherValuesLabels = filter(
        otherValues,
        (item) => item.value > 0,
      ).map((d) => [truncate(d.label, { length: 10 }), d.value]);

      if (sortedRawData.length > maxRecords) {
        return [
          [
            ...slice(sortedRawData, 0, maxRecords - 1),
            {
              label: 'Other',
              value: sumBy(otherValues, (d) => d.value),
            },
          ],
          sum,
          otherValuesLabels,
        ];
      } else {
        return [sortedRawData, sum];
      }
    }, [data, maxRecords, disableSort]);

    return (
      <div className={cx(className, styles.BarChartHorizontal)}>
        <div className={styles.list}>
          {map(records, (record) => {
            const percentage = (record.value / totalValue) * 100;
            const adjustedPercentage = Math.max(
              0,
              Math.min(100, percentage || 0),
            );

            return (
              <div className={styles.listItem} key={record.label}>
                <div className={styles.label}>{record.label}</div>
                <div className={styles.bar}>
                  <div
                    ref={record.label === 'Other' ? ref : null}
                    className={styles.progress}
                    style={{
                      width: `${adjustedPercentage}%`,
                    }}
                  />
                  {otherValuesLabels && (
                    <Tooltip
                      mountRef={ref}
                      placement="bottom"
                      tooltipColor="black"
                    >
                      <div className={styles.tooltipBody}>
                        {otherValuesLabels.map((value) => (
                          <div
                            className={styles.tooltipRow}
                            key={`${value[0]}${value[1]}`}
                          >
                            <span>{value[0]}</span>
                            <span className={styles.tooltipNumber}>
                              {value[1]}
                            </span>
                          </div>
                        ))}
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className={styles.valueSection}>
                  {showRawValue && (
                    <div className={styles.value}>
                      {numeral(record.value)
                        .format('0.[0]a')
                        .toUpperCase()}
                    </div>
                  )}
                  {showPercentage && (
                    <div className={styles.percentage}>
                      {showRawValue && '('}
                      {numeral(record.value / totalValue).format('0.0%')}
                      {showRawValue && ')'}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);

BarChartHorizontal.displayName = 'BarChartHorizontal';
