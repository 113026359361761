import * as React from 'react';
import cx from 'classnames';
import {
  first,
  map,
} from 'lodash';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import ChevronLeftIcon from '@revfluence/fresh-icons/solid/ChevronLeftIcon';

import { useMutation } from '@apollo/client';
import { useResourceContext } from '@frontend/app/context';
import {
  GetThreadQuery_thread as IThread,
} from '@frontend/app/queries/types/GetThreadQuery';
import {
  ASSIGN_TO_THREAD_MUTATION,
  UNASSIGN_FROM_THREAD_MUTATION,
} from '@frontend/app/queries';
import {
  AssignToThread, AssignToThreadVariables,
} from '@frontend/app/queries/types/AssignToThread';
import {
  UnassignFromThread, UnassignFromThreadVariables,
} from '@frontend/app/queries/types/UnassignFromThread';

import {
  Button,
  Typography,
} from '@revfluence/fresh';
import { CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { ThreadLabelType } from '@frontend/app/types/globalTypes';
import { ThreadMembers } from '@frontend/app/containers/MessagingApp/MessageList/ThreadMembers';
import { ThreadAssignees } from '@frontend/app/containers/MessagingApp/MessageList/ThreadAssignees';
import MessageActions from '@frontend/app/containers/MessagingApp/MessageList/MessageActions';
import { useMessagingContext } from '../context/MessagingAppContext';
import { getThreadTitle, isIGDMThread } from '../utils';

import styles from './ThreadHeader.scss';
import { useMemberFieldPartitions } from '../../MemberDetail/MemberInfo/MemberOverview/hooks';

const { Text } = Typography;

interface IProps {
  thread: IThread;
  onThreadStatusChange(): void;
  onBackButtonClicked?: () => void;
  className?: string;
  onOpenOverview: () => void;
  setSelectedMemberId: (memberId: number) => void;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ThreadHeader: React.FC<IProps> = React.memo((props) => {
  const addEvent = useEventContext();
  const { thread, onOpenOverview, setSelectedMemberId } = props;
  const { users, refetchNotificationCount } = useResourceContext();
  const { resetMessagingState } = useMessagingContext();

  const [assignToThread, {
    loading: assigning,
  }] = useMutation<AssignToThread, AssignToThreadVariables>(ASSIGN_TO_THREAD_MUTATION, {
    onCompleted() {
      props.onThreadStatusChange();
      resetMessagingState();
      refetchNotificationCount();
    },
  });

  const [unassignFromThread, {
    loading: unassigning,
  }] = useMutation<UnassignFromThread, UnassignFromThreadVariables>(UNASSIGN_FROM_THREAD_MUTATION, {
    onCompleted() {
      props.onThreadStatusChange();
      resetMessagingState();
      refetchNotificationCount();
    },
  });

  const toggleAssignee = (userId: string, isChecked: boolean) => {
    if (isChecked) {
      addEvent(
        EventName.ActOnThread,
        { threadType: thread.type, actionType: 'assignThread' },
      );
      assignToThread({
        variables: {
          threadId: thread.id,
          userId,
        },
      });
    } else {
      unassignFromThread({
        variables: {
          threadId: thread.id,
          userId,
        },
      });
    }
  };

  const {
    sortedSocialSchemasByName,
  } = useMemberFieldPartitions({});

  const { messageResources } = useResourceContext();
  const isUpdatingAssignees = assigning || unassigning;
  const userLabel = useMemo(() => {
    if (!thread || !thread?.userLabels) return '';
    return first(thread.userLabels)?.label;
  }, [thread]);

  return (
    <div className={cx(styles.ThreadHeader, props.className)}>
      <div className={styles.top}>
        {props.onBackButtonClicked && (
          <Button
            className={styles.backButton}
            onClick={props.onBackButtonClicked}
            icon={<ChevronLeftIcon />}
          />
        )}
        <Text className={styles.title} ellipsis>
          {isIGDMThread(thread)
            ? getThreadTitle(thread, sortedSocialSchemasByName, messageResources)
            : thread?.lastMessage.title || thread?.lastMessage.subject}
        </Text>
        { thread?.messages
          && (
          <div className={styles.messageAction}>
            {userLabel === ThreadLabelType.DONE && <CheckIcon />}
            <MessageActions
              thread={thread}
              messages={thread.messages}
            />
          </div>
)}
      </div>
      <div className={styles.bottom}>
        <ThreadMembers
          thread={thread}
          setSelectedMemberId={setSelectedMemberId}
          onOpenOverview={onOpenOverview}
        />
        <ThreadAssignees
          users={users}
          isFromSharedEmail={thread?.isFromSharedEmail}
          selectedAssigneesIds={map(thread?.assignees, 'id')}
          isUpdatingAssignees={isUpdatingAssignees}
          onToggle={toggleAssignee}
        />
      </div>
    </div>
  );
});
