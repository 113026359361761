import gql from 'graphql-tag';
import { REQUIREMENT_FRAGMENT } from './fragments/RequirementFragment';

export const UPDATE_REQUIREMENT = gql`
  mutation UpdateRequirementMutation($id: Int!, $requirement: RequirementInput!) {
    requirement: updateRequirement(id: $id, requirement: $requirement) {
      ...RequirementFragment
    }
  }
  ${REQUIREMENT_FRAGMENT}
`;
