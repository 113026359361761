import cx from 'classnames';
import * as React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Avatar, Button, Dropdown, Menu,
} from 'antd';
import { chain, isNumber } from 'lodash';
import { Link } from 'react-router-dom';

import { EllipsisLabel } from '@frontend/app/components';
import { TopNavItem } from '../../types/TopNavItem';

import styles from './NavItem.scss';
import dropdownStyles from './TopbarDropdown.scss';

const { useMemo } = React;
export interface INavItemProps {
  item: TopNavItem;
  onClick: (id: string) => void;
}

export const NavItem: React.FC<INavItemProps> = ({ item, onClick }) => {
  const { icon: IconComponent, text, children } = item;
  const isNavItemProject = item.id === 'projects';

  const menuItems = useMemo(() => {
    if (isNavItemProject) {
      return chain(children).sortBy((child) => child.text.toLocaleLowerCase());
    }

    return chain(children);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const menu = useMemo(
    () => (
      <Menu className={cx(dropdownStyles.TopNavbarSubmenu)}>
        <div className={cx({ [dropdownStyles.TopNavbarSubmenuLong]: isNavItemProject })}>
          {menuItems
            .map((subItem, index) => (
              <Menu.Item
                className={cx(dropdownStyles.TopNavbarSubmenuItem, { [dropdownStyles.selected]: subItem.selected })}
                key={subItem.id + index}
              >
                <Link to={subItem.route}>
                  {subItem.imageUrl && (
                    <Avatar src={subItem.imageUrl} className={dropdownStyles.TopNavbarSubmenuImage} />
                  )}
                  <div className={dropdownStyles.TopNavbarSubmenuText}>
                    <EllipsisLabel align={[20, 0]} textOnlyTitle tooltipPlacement="right">
                      {subItem.node || subItem.text}
                    </EllipsisLabel>
                  </div>
                </Link>
              </Menu.Item>
            ))
            .value()}
        </div>
        {isNavItemProject && (
          <Menu.Item icon={<PlusIcon />} className={dropdownStyles.TopNavbarSubmenuItemCreate}>
            <Link to="/projects/new/templates">Create New Project</Link>
          </Menu.Item>
        )}
      </Menu>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuItems],
  );

  if (children || isNavItemProject) {
    return (
      <div className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
        <Dropdown
          overlay={menu}
          overlayClassName={dropdownStyles.TopNavbarDropdown}
          placement="bottomLeft"
          mouseEnterDelay={0}
          mouseLeaveDelay={0.05}
        >
          <Button type="text" onClick={() => isNavItemProject && onClick(item.id)}>
            {IconComponent && <IconComponent className={styles.navIcon} />}
            <span>{text}</span>
            <DownOutlined className={styles.arrow} />
          </Button>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
      <Link to={item.route} className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
        {IconComponent && <IconComponent className={styles.navIcon} />}
        <span>{text}</span>
        {isNumber(item.badge) && item.badge !== 0 && <div className={styles.badge}>{item.badge}</div>}
      </Link>
    </div>
  );
};
