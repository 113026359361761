import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const NodeIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <circle
      cx="8"
      cy="8"
      r="3"
      strokeWidth="2"
    />
  </SvgIcon>
));

NodeIcon.displayName = 'NodeIcon';
