import { map, filter, trim } from 'lodash';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_guidelines as Guideline,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import {
  IContentGuidelinesWithDueDates,
  TContentGuideline,
} from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import { ISortableGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';

const getId = <T extends TContentGuideline | IContentGuidelinesWithDueDates> (
  contentGuideline: T,
): number => {
  if ('contentGuidelineInstanceId' in contentGuideline) {
    return contentGuideline.contentGuidelineInstanceId;
  }
  return contentGuideline.id;
};

export const addGuidelineAttachment = <T extends TContentGuideline | IContentGuidelinesWithDueDates>(
  contentGuidelines: Array<T>,
  id: number,
  attachment: Attachment,
): Array<T> => contentGuidelines.map((contentGuideline) => {
    if (getId(contentGuideline) === id) {
      return {
        ...contentGuideline,
        attachments: [
          ...contentGuideline.attachments,
          attachment,
        ],
      };
    }
    return contentGuideline;
  });

export const deleteGuidelineAttachment = <T extends TContentGuideline | IContentGuidelinesWithDueDates>(
  contentGuidelines: Array<T>,
  id: number,
  attachments: Array<Attachment>,
): Array<T> => contentGuidelines.map((contentGuideline) => {
    if (getId(contentGuideline) === id) {
      return {
        ...contentGuideline,
        attachments: [...attachments],
      };
    }
    return contentGuideline;
  });

export const updateGuidelinesInstructions = (
  guidelines: Array<Guideline>,
  guidelineIndex: number,
  instructionIndex: number,
  instruction: string,
): Array<Guideline> => {
  const updatedGuidelines = [...guidelines];
  const guideline = { ...updatedGuidelines[guidelineIndex] };
  let instructions = [...guideline.instructions];
  if (instruction !== '') {
    // Split text and detect line break
    let instructionArr = filter(map(instruction.split(/\r?\n/), (text) => trim(text)), (text) => !!text);
    // The regex identifies and remove any type of bullet in the Text
    // The second regex has been copied from here
    // https://stackoverflow.com/questions/6479059/how-to-identify-and-remove-any-type-of-bullet-in-the-text
    instructionArr = instructionArr
      .map(
        (newInstruction) => newInstruction
          .replace(/^\s*(?:[\dA-Z]+\.|[a-z]\)|•)\s+/gm, '')
          .replace(/\r/g, ' '),
      );
    instructions = [
      ...instructions.slice(0, instructionIndex),
      ...instructionArr,
      ...instructions.slice(instructionIndex + 1),
    ];
  }
  if (instruction === '') {
    instructions.splice(instructionIndex, 1);
  }
  guideline.instructions = instructions;
  updatedGuidelines[guidelineIndex] = { ...guideline };
  return updatedGuidelines;
};

export const updateGuidelineInstruction = <T extends TContentGuideline | IContentGuidelinesWithDueDates>(
  contentGuidelines: Array<T>,
  id: number,
  guidelineIndex: number,
  instructionIndex: number,
  instruction: string,
): Array<T> => contentGuidelines.map((contentGuideline) => {
  if (getId(contentGuideline) === id) {
    const guidelines = [...contentGuideline.guidelines];
    const updatedGuidelines = updateGuidelinesInstructions(
      guidelines,
      guidelineIndex,
      instructionIndex,
      instruction,
    );
    return { ...contentGuideline, guidelines: updatedGuidelines };
  }
  return contentGuideline;
});

export const sortGuidelineInstructions = <T extends TContentGuideline | IContentGuidelinesWithDueDates>(
  contentGuidelines: Array<T>,
  id: number,
  guidelineType: string,
  sortableGuideline: ISortableGuideline,
): Array<T> => contentGuidelines.map((contentGuideline) => {
  if (getId(contentGuideline) === id) {
    const updatedGuidelines = contentGuideline.guidelines.map(
      (guideline) => {
        if (guideline.type === guidelineType) {
          return {
            ...guideline,
            instructions: sortableGuideline[guidelineType],
          };
        }
        return guideline;
      },
    );
    return {
      ...contentGuideline,
      guidelines: updatedGuidelines,
    };
  }
  return contentGuideline;
});

export const updateGuidelineLabel = <T extends TContentGuideline | IContentGuidelinesWithDueDates>(
  contentGuidelines: Array<T>,
  id: number,
  label: string,
): Array<T> => contentGuidelines.map((contentGuideline) => {
    if (getId(contentGuideline) === id) {
      return {
        ...contentGuideline,
        label,
      };
    }
    return contentGuideline;
  });

  export const updateGuidelineTitle = <T extends TContentGuideline | IContentGuidelinesWithDueDates>(
    contentGuidelines: Array<T>,
    id: number,
    guidelineIndex: number,
    title: string,
  ): Array<T> => contentGuidelines.map((contentGuideline) => {
    if (getId(contentGuideline) === id) {
      const guidelines = [...contentGuideline.guidelines];
      const guideline = { ...guidelines[guidelineIndex] };
      guideline.title = title;
      guidelines[guidelineIndex] = { ...guideline };
      return { ...contentGuideline, guidelines };
    }
    return contentGuideline;
  });

  export const moveCaretAtEnd = (e: React.FocusEvent<HTMLInputElement>): void => {
    const auxValue = e.target.value;
    e.target.value = '';
    e.target.value = auxValue;
  };
