import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const XCircleIconV2 = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM11.3125 10.7812C11.5625 11.0938 11.5312 11.5625 11.2188 11.8438C11.0938 11.9688 10.9062 12 10.75 12C10.5312 12 10.3125 11.9375 10.1562 11.75L8 9.1875L5.8125 11.75C5.65625 11.9375 5.4375 12 5.21875 12C5.0625 12 4.90625 11.9688 4.75 11.8438C4.4375 11.5625 4.40625 11.0938 4.65625 10.7812L7 8L4.65625 5.25C4.40625 4.9375 4.4375 4.46875 4.75 4.1875C5.0625 3.9375 5.53125 3.96875 5.8125 4.28125L8 6.84375L10.1562 4.28125C10.4375 3.96875 10.9062 3.9375 11.2188 4.1875C11.5312 4.46875 11.5625 4.9375 11.3125 5.25L8.96875 8L11.3125 10.7812Z" />
  </SvgIcon>
));

XCircleIconV2.displayName = 'XCircleIconV2';

export { XCircleIconV2 };
