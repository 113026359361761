import * as React from 'react';
import cx from 'classnames';
import { isEmpty, isUndefined, map } from 'lodash';
import { Popover, Button, Divider } from 'antd';
import { IconButton } from '@components';
import { PlusOutlined } from '@ant-design/icons';
import { IncludeSelect } from '@frontend/app/components/FilterList/fieldTypes/IncludeSelect';
import { EllipsisIcon } from '@frontend/app/components';

const { useMemo, useState } = React;

import styles from '../TokenCell/TokenCell.scss';

interface IProps {
  values: string[];
  className?: string;
  options?: string[];
  onChange(values: string[] | string);
  isSingle?: boolean;
}

export const TokenCellSelection: React.FC<IProps> = React.memo((props) => {
  const {
 values, options, onChange, isSingle,
} = props;
  const [isEditing, setIsEditing] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [stateValues, setStateValues] = useState(values);
  const [memoryValues, setMemoryValues] = useState(values);

  const handleMouseOver = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleMouseOut = () => {
    setMemoryValues(stateValues);
    setIsEditing(false);
  };

  const mappedOptions = useMemo(() => (
    map(options, (option) => ({
      title: option,
      id: option,
    }))
  ), [options]);

  const handleSelect = (selectedValues: string[]) => {
    setMemoryValues(selectedValues);
  };

  const handleClick = () => {
    const values = isSingle ? memoryValues[0] : memoryValues;
    onChange(values);
    setStateValues(memoryValues);
    setPopoverVisible(false);
  };

  const handleEmptyValues = () => {
    setMemoryValues([]);
  };

  const renderSelect = () => (
    <div>
      <div className={styles.tokenTitle}>
        Current options
      </div>
      <Divider className={styles.divider} />
      <IncludeSelect
        showSearch={false}
        options={mappedOptions}
        multi={!isSingle}
        defaultSelectedIds={memoryValues}
        mapOptionToId={(option) => option.id}
        mapOptionToLabel={(option) => option.title}
        onChange={handleSelect}
      />
      <Divider className={styles.divider} />
      <Button
        type="primary"
        shape="round"
        onClick={handleClick}
        className={styles.save}
      >
        Save
      </Button>
      <Button
        danger
        type="primary"
        shape="round"
        onClick={handleEmptyValues}
        disabled={isEmpty(memoryValues)}
        className={styles.emptyButton}
      >
        Clear current options
      </Button>
    </div>
    );

  const renderReadValues = () => (
    <div>
      <div className={styles.valuesList}>
        {
            map(stateValues, (value) => (
              <div className={styles.listValue}>
                {value}
              </div>
            ))
          }
      </div>
      <Divider className={styles.divider} />
      <Button
        type="primary"
        shape="round"
        onClick={() => setIsEditing(true)}
        className={styles.save}
      >
        Edit options
      </Button>
    </div>
    );

  const renderPopOver = () => {
    const showSelect = isEditing || isUndefined(stateValues[0]);

    return (
      <div
        onMouseOver={handleMouseOver}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        { showSelect ? renderSelect() : renderReadValues()}
      </div>
    );
  };

  const renderValue = () => (
    <div className={cx(
        styles.value, {
          [styles.singleValue]: isSingle || isEmpty(stateValues),
          [styles.muted]: isEmpty(stateValues) || isUndefined(stateValues[0]),
        },
      )}
    >
      {isUndefined(stateValues[0]) ? 'N/A' : stateValues[0]}
    </div>
    );

  const renderButton = () => {
    if (!isEmpty(options)) {
      if (isSingle && isUndefined(stateValues[0])) {
        return (
          <div className={styles.right} onMouseOver={handleMouseOver}>
            <IconButton
              className={styles.btn}
              icon={<PlusOutlined size={12} />}
            />
          </div>
        );
      } else if (!isSingle) {
        return (
          <div className={styles.right} onMouseOver={handleMouseOver}>
            <IconButton
              className={styles.btn}
              icon={isEmpty(stateValues)
                ? <PlusOutlined size={12} />
                : <EllipsisIcon size={12} />}
            />
          </div>
        );
      }
    }
  };

  return (
    <div onMouseLeave={handleMouseOut}>
      <Popover
        placement="top"
        content={renderPopOver()}
        visible={popoverVisible || isEditing}
        onVisibleChange={(visible) => setPopoverVisible(visible)}
      >
        <div className={cx(styles.TokenCell, props.className)}>
          {renderValue()}
          {renderButton()}
        </div>
      </Popover>
    </div>
  );
});

TokenCellSelection.displayName = 'TokenCellSelection';
