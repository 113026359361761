import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_BUDGET_FISCAL_PERIOD_QUERY from '@frontend/app/queries/GetBudgetFiscalPeriodQuery';
import { GetBudgetFiscalPeriodData, GetBudgetFiscalPeriodDataVariables } from '@frontend/app/queries/types/GetBudgetFiscalPeriodData';

type IOptions = QueryHookOptions<GetBudgetFiscalPeriodData, GetBudgetFiscalPeriodDataVariables>;

export function useGetBudgetFiscalPeriod(options: IOptions) {
    const {
        loading, data: { budgetFiscalPeriod } = {}, error, refetch,
    } = useQuery<GetBudgetFiscalPeriodData, GetBudgetFiscalPeriodDataVariables>(GET_BUDGET_FISCAL_PERIOD_QUERY, {
        ...options,
        fetchPolicy: 'no-cache',
    });
    return {
        loading,
        budgetFiscalPeriod,
        error,
        refetch,
    };
}
