import React, { useMemo } from 'react';
import { IColumnsType } from '@revfluence/fresh';
import { GetProductDetails } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductDetails';
import { IProductVariantData, IVariantsRow } from '../../Products/types';
import { NameWithImageRenderer } from '../../components/NameWithImageRenderer/NameWithImageRenderer';

export interface IUseVariantTableProps {
  variants: GetProductDetails['getProductVariantsByProductId']['productVariants']
}

export const useVariantTableProps = ({ variants }: IUseVariantTableProps) => {
  const columnConfig = useMemo<IColumnsType<IVariantsRow>>(
    () => [
      {
        key: '_raw',
        dataIndex: '_raw',
        render: ({ title, image, sku }: IVariantsRow) => (
          <NameWithImageRenderer title={title} image={image} subTitle={sku} />
        ),
      },
    ],
    [],
  );

  const tableData = useMemo<Partial<IVariantsRow>[]>(
    () =>
      variants.map((variant) => {
        const transformedData: Partial<IProductVariantData> = {
          key: String(variant.id),
          id: String(variant.id),
          title: variant.name,
          image: variant.images?.[0],
          sku: variant.sku,
        };
        const variantRow: Partial<IVariantsRow> = {
          ...transformedData,
          _raw: transformedData,
        };
        return variantRow;
      }),
    [variants],
  );

  return {
    columnConfig,
    tableData,
  };
};
