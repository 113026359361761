import { IAddress } from './address';
import { ISocialAccount } from './socialAccount';

export interface IPublisher {
  id: string;
  email: string;
  payment_setup: boolean;
  automatic_profile_image_url: string;
  phone: string;
  influential: boolean;
  referral_credited: boolean;
  city: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delinquent_projects: any[];
  uninvited_application_limit: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bio?: any;
  paypal: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cover_image?: any;
  rating: number;
  is_phone_verified: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  about?: any;
  address_description: string;
  address: IAddress;
  profile_image_url: string;
  sms_settings: ISmsSettings;
  age_data: IAgeData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  introduction_video_embed_code?: any;
  requested_withdrawal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflow?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  device_data?: any;
  is_active: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  about_other?: any;
  full_name: string;
  display_name: string;
  balance: number;
  should_block_on_tos: boolean;
  is_proposals_limited: boolean;
  city_lon: number;
  declined_sms: boolean;
  month_one_ts: number;
  city_lat: number;
  cohort_day: number;
  country: string;
  city_data: ICityData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location?: any;
  profile_picture: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  talent_agency?: any;
  email_settings: IPublisherEmailSettings;
  username: string;
  reach: number;
  has_seen_resume: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  full_resolution_profile_image_url?: any;
  country_data: ICountryData;
  pending_phone_verification: boolean;
  is_agency: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tinder_start_ts?: any;
  uninvited_applications: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  media_kit_preview_url?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  media_kit_filename?: any;
  is_qa: boolean;
  is_proposals_allowed: boolean;
  rating_count: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  media_kit?: any;
  eligible: boolean;
  public_url: string;
  gender_data: IGenderData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referred_by_code?: any;
  social_accounts: ISocialAccount[];
  first_aspireiq_login_ts: number;
}

interface IGenderData {
  female: number;
  male: number;
  unknown: number;
}

interface ICountryData {
  [country: string]: number;
}

interface IPublisherEmailSettings {
  newsletter: boolean;
  campaign_notification: boolean;
  reminders: boolean;
  message: boolean;
  system_message: boolean;
  campaign_invite: boolean;
}

interface ICityData {
  icon: string;
  name: string;
  place_id: string;
  scope: string;
  adr_address: string;
  geometry: IGeometry;
  utc_offset: number;
  address_components: IAddressComponent[];
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  html_attributions: any[];
  vicinity: string;
  reference: string;
  types: string[];
  id: string;
  formatted_address: string;
  photos: IPhoto[];
}

interface IPhoto {
  height: number;
  html_attributions: string[];
  width: number;
}

interface IAddressComponent {
  short_name: string;
  long_name: string;
  types: string[];
}

interface IGeometry {
  viewport: IViewport;
  location: ILocation;
}

interface ILocation {
  lng: number;
  lat: number;
}

interface IViewport {
  south: number;
  north: number;
  east: number;
  west: number;
}

interface IAgeData {
  '55-64': number;
  '45-54': number;
  '18-24': number;
  '13-17': number;
  '65+': number;
  '25-34': number;
  '35-44': number;
}

interface ISmsSettings {
  message: boolean;
}
