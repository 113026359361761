/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_15uqc_157 {
  display: flex;
}

._justify-content-space-between_15uqc_161 {
  justify-content: space-between;
}

._tabular-nums_15uqc_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_15uqc_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._statsRowBlock_15uqc_178 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1.5rem;
}

._statsItemContainer_15uqc_185 {
  border-top: 0.0625rem solid #D9D9D9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0rem !important;
  padding-top: 0.5rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex: 1;
  min-width: 7.5rem;
}
._statsItemContainer_15uqc_185 ._statsItemTitle_15uqc_197 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
}
._statsItemContainer_15uqc_185 ._statsItemDescription_15uqc_203 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.75rem;
}

@media screen and (max-width: 992px) {
  ._statsItemContainer_15uqc_185 {
    min-width: 6.25rem; /* Adjust width for smaller screens */
  }
}
@media screen and (max-width: 768px) {
  ._statsItemContainer_15uqc_185 {
    min-width: 5rem; /* Adjust width for even smaller screens */
  }
}