import { useMutation, MutationHookOptions } from '@apollo/client';

import { REVOKE_RESOURCE_ACCESS_MUTATION } from '@frontend/app/queries';
import {
  RevokeResourceAccessMutation, RevokeResourceAccessMutationVariables,
} from '@frontend/app/queries/types/RevokeResourceAccessMutation';

export type IRevokeAccessOptions = MutationHookOptions<RevokeResourceAccessMutation, RevokeResourceAccessMutationVariables>;

export function useRevokeResourceAccess(options: IRevokeAccessOptions = {}) {
  const [revokeAccess, {
    loading,
    error,
  }] = useMutation<RevokeResourceAccessMutation, RevokeResourceAccessMutationVariables>(REVOKE_RESOURCE_ACCESS_MUTATION, options);

  return {
    loading,
    revokeAccess,
    error,
  };
}
