import { useMutation, MutationHookOptions } from '@apollo/client';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { GET_COMMUNITIES_QUERY } from '@frontend/app/queries';
import { GetCommunitiesQuery } from '@frontend/app/queries/types/GetCommunitiesQuery';

import { SAVE_COMMUNITY } from '@frontend/app/queries/SaveCommunityMutation';
import {
  SaveCommunityMutation,
  SaveCommunityMutationVariables,
  SaveCommunityMutation_community as ICommunity,
} from '@frontend/app/queries/types/SaveCommunityMutation';

const updateCache = (store: DataProxy, community: ICommunity) => {
  // Read the data from our cache for this query.
  const data = store.readQuery<GetCommunitiesQuery>({ query: GET_COMMUNITIES_QUERY });

  // Do nothing. Cache doesn't exist.
  if (!data) {
    return;
  }

  // Write our data back to the cache.
  store.writeQuery({
    query: GET_COMMUNITIES_QUERY,
    data: {
      ...data,
      communities: unionBy(data.communities, [community], 'id'),
    },
  });
};

type IOptions = MutationHookOptions<SaveCommunityMutation, SaveCommunityMutationVariables>;

export const useSaveCommunityMutation = (options: IOptions = {}) => useMutation<SaveCommunityMutation, SaveCommunityMutationVariables>(SAVE_COMMUNITY, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateCache(store as any, result.data.community);
    },
  });
