import * as React from 'react';
import cx from 'classnames';
import {
 map, sortBy, find, filter, includes,
} from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';
import { Tooltip, Typography } from 'antd';

import {
 Switch, useRouteMatch, useHistory, useLocation, Route,
} from 'react-router-dom';

import {
  IconButton,
} from '@components';

import { useInstalledApplicationInstances, useGetApplicationsByIds, useClientFeatureEnabled } from '@frontend/app/hooks';
import { AppIcon } from '@frontend/applications/Shared/components/AppIcon';

import { ClientFeature } from '@frontend/app/constants';
import { IApplication } from '../MemberDetail/types';
import { ApplicationDrawer } from './ApplicationDrawer';

import styles from './ApplicationList.scss';

const { useState, useMemo, useEffect } = React;
const { Text } = Typography;

interface IProps {
  className?: string;
  memberId: string;
  hideLauncher?: boolean;
  disabledApplicationIds?: string[];
}

/**
 * @type {React.FunctionComponent}
 */
export const ApplicationList: React.FunctionComponent<IProps> = React.memo((props) => {
  const { memberId, hideLauncher, disabledApplicationIds } = props;
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const {
    data: {
      instances: applicationInstances = [],
    } = {},
  } = useInstalledApplicationInstances({
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      applications = null,
    } = {},
  } = useGetApplicationsByIds(applicationInstances ? map(applicationInstances, 'applicationId') : [], {
    fetchPolicy: 'network-only',
  });

  const [selectedApplication, setSelectedApplication] = useState<IApplication>(null);

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const onSelectApplication = (application: IApplication) => {
    if (isApplicationDisabled(application)) {
      return;
    }

    history.push({
      ...location,
      pathname: `${match.url}/app/${application.id}`,
    });
    setSelectedApplication(application);
  };

  const isApplicationDisabled = (application: IApplication) => includes(disabledApplicationIds, application.id);

  const linkParameters = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const params = query.get('link_params');
    return params ? JSON.parse(decodeURIComponent(params)) : null;
  }, [location]);

  const sortedShownApplications = useMemo(() => {
    if (!applications) {
      return null;
    }
    const filteredApplications = filter(applications, (application) => application.visibility.showOnMember);
    return sortBy(filteredApplications, 'id');
  }, [applications]);

  useHotkeys('1,2,3,4,5,6,7,8,9', (_, handler) => {
    if (!sortedShownApplications) {
      return;
    }

    const i = Number(handler.key) - 1;
    onSelectApplication(sortedShownApplications[i]);
  }, [sortedShownApplications]);

  // Listen for changes in case others route to an app modal
  useEffect(() => {
    const appIds = history.location.pathname.match(/\/app\/(\w+)/);
    if (appIds && appIds.length > 0) {
      const application = find(applications, {
        id: appIds[1],
      });
      setSelectedApplication(application);
    }
  }, [history.location.pathname, applications]);

  return (
    <div className={cx(styles.ApplicationList, props.className)}>
      {!hideLauncher
        && map(sortedShownApplications, (application, index) => (
          <Tooltip
            key={application.id}
            overlayStyle={{
              zIndex: 9999,
            }}
            placement="top"
            title={isApplicationDisabled(application) ? (
              <>
                This application is not available because it is the active Project step. Please close this modal and return to the project to take action
              </>
              ) : (
                <>
                  {application.name}
                  {' '}
                  <Text keyboard>{index + 1}</Text>
                </>
              )}
          >
            <div>
              <IconButton
                key={application.id}
                icon={<AppIcon iconUrl={application.icon} />}
                onClick={() => onSelectApplication(application)}
                disabled={isApplicationDisabled(application)}
              />
            </div>
          </Tooltip>
        ))}

      <section>
        <Switch>
          <Route path={`${match.path}/app/:appId`}>
            <ApplicationDrawer
              application={selectedApplication}
              memberId={memberId}
              deepLinkParameters={linkParameters}
              paymentAppPaddingEnabled={isBudgetAllocationEnabled}
            />
          </Route>
        </Switch>
      </section>
    </div>
  );
});
