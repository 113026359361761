import * as React from 'react';
import {
    Form,
    Select,
} from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../types';

const { Option } = Select;

interface IProps extends IShopifyFormElementProps<'status'> {
}

export const OfferStatus: React.FC<Readonly<IProps>> = React.memo(({
    disabled,
    name,
}) => {
    const rules = [
        { required: true, message: 'Please input your status!' },
    ];
    return (
      <>
        <Form.Item label="Offer Status" name={name} rules={rules}>
          <Select
            disabled={disabled}
            placeholder="Select"
            size="large"
          >
            <Option value="ACTIVE">
              Active
            </Option>
            <Option value="PAUSED" data-testid="postback">
              Paused
            </Option>
          </Select>
        </Form.Item>

      </>
    );
});
