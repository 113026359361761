import gql from 'graphql-tag';
import { CONTENT_FIELD_WITH_PROGRAM_ID_FRAGMENT } from './fragments';

export const CREATE_CONTENT_FIELD_MUTATION = gql`
  mutation CreateContentFieldMutation($data: UpsertContentFieldInput!) {
    createContentField(data: $data) {
      ...ContentFieldWithProgramId
    }
  }
  ${CONTENT_FIELD_WITH_PROGRAM_ID_FRAGMENT}
`;

export const UPDATE_CONTENT_FIELD_MUTATION = gql`
  mutation UpdateContentFieldMutation($id: Int!, $data: UpsertContentFieldInput!) {
    updateContentField(id: $id, data: $data) {
      ...ContentFieldWithProgramId
    }
  }
  ${CONTENT_FIELD_WITH_PROGRAM_ID_FRAGMENT}
`;

export const DELETE_CONTENT_FIELD_MUTATION = gql`
  mutation DeleteContentFieldMutation($id: Int!) {
    deleteContentField(id: $id)
  }
`;

export const GET_CONTENT_FIELD_BY_ID_QUERY = gql`
  query GetContentFieldById($id: Int!) {
    getContentFieldById(id: $id) {
      ...ContentFieldWithProgramId
    }
  }
  ${CONTENT_FIELD_WITH_PROGRAM_ID_FRAGMENT}
`;

export const FIND_ALL_CONTENT_FIELDS_QUERY = gql`
  query FindAllContentFields($data: FindContentFieldsInput!) {
    findAllContentFields(data: $data) {
      ...ContentFieldWithProgramId
    }
  }
  ${CONTENT_FIELD_WITH_PROGRAM_ID_FRAGMENT}
`;
