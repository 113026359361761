import { IState } from '../../../../types/state';
import { IToggleBrief } from './index';

export const toggleBrief = (state: IState, action: IToggleBrief): IState => {
  if (state.collaborationDetails.brief.toggleBrief === action.toggleBrief) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      brief: {
        ...state.collaborationDetails.brief,
        toggleBrief: action.toggleBrief,
      },
    },
  };
};
