export const OfferFormTestIds = {
  offerForm: 'offer-form',
  offerName: 'offer-name',
  offerUrl: 'offer-url',
  conversionTracking: 'conversion-tracking',
  conversionType: 'conversion-type',
  offerDescription: 'offer-description',
  expirationDate: 'expiration-date',
  conversionCost: 'ConversionCost',
  clickCost: 'ClickCost',
  commission: 'Commission',
  costAndCommission: 'Cost+Commission',
  status: 'status',
  submit: 'submit',
  payoutType: 'payoutType',
  delete: 'delete',
  update: 'update',
  payout: 'payout',
  undelete: 'undelete',
  utmSource: 'utmSource',
  utmTerm: 'utmTerm',
  utmMedium: 'utmMedium',
  utmCampaign: 'utmCampaign',
  utmContent: 'utmContent',
};
