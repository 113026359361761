import * as React from 'react';
import { Alert, Button, Select } from '@revfluence/fresh';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { chain, map } from 'lodash';
import { useHistory } from 'react-router-dom';

import { ContentCard } from '@frontend/app/components/ContentCard/ContentCard';
import { LoadingCard } from '@frontend/app/components/ContentCard/LoadingCard';
import { useClientFeatureEnabled, useGetAllProjectsWithNotificationsQuery } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { ProgramStatusType } from '@services/communities/types';
import { RefreshContentCard } from '@frontend/app/components/ContentCard/RefreshContentCard';
import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import {
  Select as ShadCnSelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import ShadCnAlert from '@frontend/app/refresh-components/Alert';
import { IHomePageSection } from '../types';
import { ProjectStatus } from '../../Projects/OverviewPage/Header/constants';

const FALLBACK_PROJECT_URL = 'https://storage.googleapis.com/aspirex-static-files/home/projects.png';

const { useCallback, useState, useMemo } = React;

export const useHomePageProjects = (): IHomePageSection => {
  const projectsQuery = useGetAllProjectsWithNotificationsQuery();
  const [projectStatusFilter, setProjectStatusFilter] = useState(ProjectStatus.Active);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const retryProjectsQuery = useCallback(() => {
    projectsQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsQuery.refetch]);

  const history = useHistory();
  const goToNewProject = useCallback((e) => {
    e.preventDefault();
    history.push('projects/new/templates');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterProjects = useCallback((value: ProgramStatusType) => setProjectStatusFilter(value), []);

  const archiveDropdown = useMemo(() => {
    const getPopupContainer = (triggerNode) => triggerNode.parentNode;

    return (
      refreshUi ? (
        <ShadCnSelect onValueChange={handleFilterProjects} defaultValue={ProjectStatus.Active}>
          <SelectTrigger className="w-[160px] text-primary font-medium">
            <SelectValue placeholder="Select a project status" />
          </SelectTrigger>
          <SelectContent className="text-primary rounded-lg">
            <SelectItem value={ProjectStatus.Active}>Active Project</SelectItem>
            <SelectItem value={ProjectStatus.Archived}>Archived Projects</SelectItem>
          </SelectContent>
        </ShadCnSelect>
      )
        : (
          <Select
            getPopupContainer={getPopupContainer}
            defaultValue={ProjectStatus.Active}
            key="filter_project_select"
            onChange={handleFilterProjects}
            defaultActiveFirstOption
            style={{
              width: '160px',
            }}
            options={[
              {
                label: 'Active Projects',
                value: ProjectStatus.Active,
              },
              {
                label: 'Archived Projects',
                value: ProjectStatus.Archived,
              },
            ]}
          />
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProjectStatus, handleFilterProjects, refreshUi]);

  const newProjectButton = useMemo(
    () => (
      refreshUi ? (
        <ShadCnBtn
          key="new_project_button"
          onClick={goToNewProject}
          variant="outlineHeader"
          className="text-primary hover:text-primary"
        >
          <PlusIcon className="mr-2" />
          New Project
        </ShadCnBtn>
      )
        : (
          <Button
            href="projects/new/templates"
            key="new_project_button"
            onClick={goToNewProject}
            type="default"
            style={{
              fontWeight: 'normal',
            }}
          >
            <PlusIcon />
            <span>New Project</span>
          </Button>
        )
    ),
    [goToNewProject, refreshUi],
  );

  const actions = useMemo(() => (isArchiveProjectEnabled ? [archiveDropdown, newProjectButton] : [newProjectButton]), [
    isArchiveProjectEnabled,
    archiveDropdown,
    newProjectButton,
  ]);

  const error = useMemo(
    () => ({
      actions,
      cards: [],
      error: (
        refreshUi
        ? <ShadCnAlert title="Oops! Something went wrong fetching your list of projects." onClick={retryProjectsQuery} buttonText="Retry" closeable={false} buttonSize="sm" />
          : (
            <Alert
              action={(
                <Button onClick={retryProjectsQuery} size="small" type="ghost">
                  Retry
                </Button>
            )}
              message="Oops! Something went wrong fetching your list of projects."
              showIcon
              type="error"
            />
        )
      ),
      title: 'Projects',
    }),
    [actions, retryProjectsQuery, refreshUi],
  );

  const loading = useMemo(
    () => ({
      actions,
      cards: [
        <LoadingCard key="project_loading_1" size="large" />,
        <LoadingCard key="project_loading_2" size="large" />,
        <LoadingCard key="project_loading_3" size="large" />,
        <LoadingCard key="project_loading_3" size="large" />,
      ],
      title: 'Projects',
    }),
    [actions],
  );

  return useMemo((): IHomePageSection => {
    const title = 'Projects';

    if (projectsQuery.loading && !projectsQuery.data) {
      return loading;
    }

    if (projectsQuery.error) {
      return error;
    }

    const { projects } = projectsQuery.data;

    const processedProjects = chain(projects)
      .filter({
        status: projectStatusFilter,
      })
      .orderBy(['title', 'id'], ['asc', 'asc'])
      .value();

    return {
      actions,
      cards: map(processedProjects, (project) => {
        const {
          activeMemberCount, id, notificationCount, splashImageUrl, status, title,
        } = project;

        let image = {
          fallbackUrl: FALLBACK_PROJECT_URL,
          title,
          url: 'https://www.example.com/fallback',
        };
        if (splashImageUrl) {
          image = {
            fallbackUrl: FALLBACK_PROJECT_URL,
            title,
            url: splashImageUrl,
          };
        }
        let description = '1 Active Member';
        if (activeMemberCount !== 1) {
          description = `${activeMemberCount.toLocaleString()} Active Members`;
        }

        return (
          refreshUi ? (
            <RefreshContentCard
              cardId={`project-${id}`}
              description={description}
              key={id.toString()}
              href={`projects/${encodeURIComponent(id)}`}
              image={image}
              notificationCount={notificationCount || 0}
              settingsHref={`projects/${encodeURIComponent(id)}/settings/details`}
              size="large"
              status={status}
              title={title}
            />
          ) : (
            <ContentCard
              cardId={`project-${id}`}
              description={description}
              key={id.toString()}
              href={`projects/${encodeURIComponent(id)}`}
              image={image}
              notificationCount={notificationCount || 0}
              settingsHref={`projects/${encodeURIComponent(id)}/settings/details`}
              size="large"
              status={status}
              title={title}
            />
          )
        );
      }),
      title,
    };
  }, [actions, error, loading, projectsQuery, projectStatusFilter, refreshUi]);
};
