import { isArray } from 'lodash';

import { TMember } from '@affiliates/components/MembersWizard/types';
import { TState } from './types';

export const getDefaultState = (members?: readonly TMember[]): TState => ({
  activeDates: {
    endTime: '00:00:00',
    startTime: '00:00:00',
  },
  currentStep: 1,
  error: null,
  members: isArray(members) ? members : null,
  selectedMembers: [],
  searchKey: '',
  programs: null,
  saving: false,
  selectedPrograms: {},
  showCloseConfirmation: false,
  showNoMembersWarning: false,
  bulkPayoutId: null,
  bulkStartDate: null,
  bulkEndDate: null,
  showEndDate: false,
  isLoading: false,
});
