import * as React from 'react';
import cx from 'classnames';
import * as qs from 'querystring';
import {
  isEmpty,
  map,
  filter,
  forEach,
} from 'lodash';

import {
  ISocialAccount,
  Button,
  Popover,
  Loading,
  ArrowDownFilledIcon,
  SpinnerIcon,
  NetworkIcon,
  IToastRefHandles,
  Toast,
} from '@components';

import { getSocialAccountLink } from '@frontend/utils/socialAccount';
import { useGetCurrentClient } from '@frontend/app/hooks';
import { DetailedSocialAccountCaller } from '@frontend/components/widgets/SocialProfile/hooks/useDetailedSocialAccount';

import {
  Action as IAction,
  ActionType as PaginationAction,
} from '../PaginationReducer';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import { useFetchPublisherSocialAccounts } from '../hooks/useFetchPublisherSocialAccounts';
import { getDisplayName, getSocialAccountUsername } from '../utils';

import styles from './NetworkPicker.scss';

const {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} = React;

interface IProps {
  className?: string;
  paginationDispatch?: (value: IAction) => void;
}

export const NetworkPicker: React.FC<IProps> = React.memo((props) => {
  const loadedSocialAccounts = useRef<{ [network: string]: ISocialAccount }>({});
  const toastrRef = useRef<IToastRefHandles>();
  const { className, paginationDispatch } = props;
  const [showPopover, setShowPopover] = useState(false);

  const {
    apiEndpoint,
    setSocialAccount,
    socialAccount,
    hasData,
    memberSocialAccounts,
    fetchingDetailForId,
  } = useSocialProfileContext();
  const { client } = useGetCurrentClient();

  const cacheAccount = useCallback((account: ISocialAccount): void => {
    if (!account || !account.network_identifier) return;

    const key = `${account.network_identifier}_${getSocialAccountUsername(account)}`;
    loadedSocialAccounts.current[key] = account;
  }, []);

  const getAccountFromCache = useCallback((account: ISocialAccount): ISocialAccount => {
    const key = `${account.network_identifier}_${getSocialAccountUsername(account)}`;
    return loadedSocialAccounts.current[key] || null;
  }, []);

  const getSocialAccountData = useCallback(async (account: ISocialAccount): Promise<ISocialAccount> => {
    const cachedAccount = getAccountFromCache(account);
    if (cachedAccount) {
      return cachedAccount;
    }

    const query = qs.stringify({
      client_id: client.id,
      member_username: account.username,
      network: account.network_identifier,
      include_social_profile_data: true,
      caller: DetailedSocialAccountCaller.SOCIAL_PROFILE,
    });
    const url = `${apiEndpoint}/social_account?${query}`;

    const response = await fetch(url);
    const json = await response.json();

    if (json?.status?.code !== 200 || !json?.data[0]) {
      throw new Error(`failed to load social account for ${account.network_identifier}`);
    }

    const data = json.data[0];
    cacheAccount(data as ISocialAccount);
    return data;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint]);

  const data = useFetchPublisherSocialAccounts({
    apiEndpoint,
    publisherId: socialAccount?.publisher_id,
  });

  const socialAccounts = useMemo(
    () => memberSocialAccounts || data?.socialAccounts,
    [memberSocialAccounts, data?.socialAccounts],
  );

  useEffect(() => {
    if (!isEmpty(socialAccounts)) {
      // cache pre-loaded socialAccounts if any
      forEach(socialAccounts, (account) => {
        if (account.type) { // type is added from backend
          cacheAccount(account);
        }
      });
    }

    // memoize initial loaded social account
    cacheAccount(socialAccount);
  }, [socialAccount, socialAccounts, cacheAccount]);

  // build a list of the other social network accounts of the member
  const otherSocialAccounts = useMemo(() => {
    const otherAccounts = filter(
      socialAccounts,
      (account) => account?.network_identifier !== socialAccount?.network_identifier,
    );

    return map(otherAccounts, (account) => getAccountFromCache(account) || account);
  }, [socialAccount, socialAccounts, getAccountFromCache]);

  const buttonRef = useRef<HTMLDivElement>();
  const renderButton = useCallback(() => {
    const hasPublisherAccounts = !isEmpty(otherSocialAccounts);
    const username = hasData && (
      getDisplayName({
        socialAccount,
        usernameOnly: true,
      })
    );
    const link = hasData && (
      socialAccount?.link
      || getSocialAccountLink(
        socialAccount?.network_identifier,
        username?.replaceAll('@', ''),
      )
    );
    const usernameLabel = hasData && (
    <>
      {link
        ? (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.usernameLink}
          >
            {username}
          </a>
        )
        : username}
      {hasPublisherAccounts && (
      <ArrowDownFilledIcon
        size={10}
        className={styles.arrowDownIcon}
      />
      )}
    </>
);
    const label = hasData
      ? usernameLabel
      : (
        <div className={styles.placeholder}>
          <Loading show theme="grey" />
        </div>
      );
    return (
      <Button
        className={cx(styles.button, {
          [styles.disableClick]: !hasPublisherAccounts,
        })}
        label={label}
        icon={hasData && (
          <>
            {fetchingDetailForId
              ? (
                <SpinnerIcon className={styles.loadingIcon} size={20} />
              )
              : (
                <NetworkIcon
                  identifier={socialAccount?.network_identifier}
                  className={styles.publisherNetworkIcon}
                />
              )}
          </>
        )}
        onClick={() => hasPublisherAccounts && setShowPopover((show) => !show)}
        theme="light"
        ref={buttonRef}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, otherSocialAccounts, socialAccount]);

  const renderPopover = useCallback((show) => (
    <Popover
      contentClassName={styles.popoverContent}
      mountRef={buttonRef}
      anchorOrigin="end"
      arrowPosition="end"
      minWidth={200}
      maxWidth={300}
      show={show}
      onRequestClose={() => setShowPopover(false)}
      offset={{
        x: 0,
        y: 6,
      }}
    >
      {map(otherSocialAccounts, (account, index) => (
        <Button
          key={index}
          className={styles.publisherAccountsButton}
          label={getDisplayName({
            socialAccount: account,
            usernameOnly: true,
          })}
          theme="light"
          icon={(
            <NetworkIcon
              identifier={account?.network_identifier}
              className={styles.publisherNetworkIcon}
            />
          )}
          onClick={async () => {
            setShowPopover((show) => !show);
            try {
              paginationDispatch({
                type: PaginationAction.ShowPlaceholder,
                payload: true,
              });
              setSocialAccount(account);
              const socialAccountData = await getSocialAccountData(account);
              paginationDispatch({
                type: PaginationAction.ShowPlaceholder,
                payload: false,
              });
              setSocialAccount(socialAccountData);
            } catch (error) {
              paginationDispatch({
                type: PaginationAction.ShowPlaceholder,
                payload: false,
              });
              setSocialAccount(account);
              toastrRef.current?.showMessage({
                content: error.message,
                type: 'error',
              });
            }
          }}
        />
      ))}
    </Popover>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [otherSocialAccounts, socialAccount]);

  return (
    <div className={cx(styles.NetworkPicker, className)}>
      {renderButton()}
      {renderPopover(showPopover)}
      <Toast ref={toastrRef} useFresh />
    </div>
  );
});

NetworkPicker.displayName = 'NetworkPicker';
