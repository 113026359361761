import * as React from 'react';
import { isNumber } from 'lodash';
import { Notice } from '@frontend/applications/AffiliatesApp/AspireUI';
import { Button } from '@revfluence/fresh';
import { AccessTable, IAccessTableRowData } from '../../components/AccessTable/AccessTable';
import styles from './AdvertiserAccessPage.scss';
import { AccessLimitHeader } from '../../components/AccessLimitHeader/AccessLimitHeader';

interface IAdvertiserAccessPageProps {
  currentMonthlyUsage: number;
  fetchMoreData: (page: number, fetchSize: number) => Promise<IAccessTableRowData[]>;
  pageSize: number;
  tableData: IAccessTableRowData[];
  totalCount: number;
  usageLimit: number;
  errorMessage?: string;
  isLoading?: boolean;
}

export const AdvertiserAccessPage: React.FC<IAdvertiserAccessPageProps> = ({
  currentMonthlyUsage,
  fetchMoreData,
  pageSize,
  tableData,
  totalCount,
  usageLimit,
  errorMessage,
  isLoading,
}) => {
  const hasLimit = isNumber(usageLimit);
  const showAccessLimitHeader = !isLoading && !errorMessage;
  const showLimitNotice = hasLimit && currentMonthlyUsage >= usageLimit;

  return (
    <div className={styles.AdvertiserAccessPage}>
      {showAccessLimitHeader && <AccessLimitHeader />}
      {showLimitNotice && (
        <Notice
          className={styles.notice}
          type="error"
          messageClassName={styles.message}
          actions={(
            <a className={styles.contactSupport} href="mailto:help@aspireiq.com">
              <Button
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                shape="rounded"
              >
                Contact Support
              </Button>
            </a>
          )}
          message="You are currently over your available monthly whitelist permissions. Contact support or your CSM to discuss an upgrade."
        />
      )}
      <div className={styles.content}>
        <AccessTable
          isLoading={isLoading}
          initialData={tableData}
          totalCount={totalCount}
          pageSize={pageSize}
          fetchMoreData={fetchMoreData}
          hasError={!!errorMessage}
          usageLimit={usageLimit}
          headerActions={(
            <div className={styles.headerAction}>
              <p>
                Automated Facebook/Instagram ads permissions management to quickly drive paid ad results using creator content.
                Use this page to get an overview of all connections you've made.
                {' '}
                <a
                  href="https://business.facebook.com/latest/settings/business_assets"
                  target="_blank"
                  rel="noreferrer"
                >
                  Go to Facebook
                </a>
                {' '}
                to manage these connections. Need help? Contact
                {' '}
                <a href="mailto:help@aspireiq.com">
                  support
                </a>
                .
              </p>
            </div>
          )}
        />

      </div>
    </div>
  );
};
