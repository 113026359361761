import * as React from 'react';
import { Form, Tooltip, Radio } from 'antd';
import { InfoIcon } from '@components';
import { OFFER_TRACKING_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { useClientFeature } from '@frontend/applications/AffiliatesApp/contexts/ClientFeatureContext';
import styles from '../../../OfferForm.scss';

const AllowMultipleconversions = ({ values, handleFormAsyncActions }) => {
  const { allowMultipleConversions } = useClientFeature();
  return (
        (values.conversionTrackingType === OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK
            || values.conversionTrackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID)
        && allowMultipleConversions && (
        <div className={styles.multipleConversionWrapper}>
          <div className={styles.urlwrapper}>
            <Form.Item className="mb-0">
              <div className={styles.boxwrapper}>
                <div className={styles.urltitle}>Allow Multiple Conversions</div>
                <Tooltip
                  title="Enable multiple conversions to be recorded per user from a single active session. An active session is
                                created each time a user clicks on a tracking link."
                >
                  <InfoIcon size={16} className="centered" />
                </Tooltip>
              </div>
              <>
                <Radio.Group
                  onChange={(e) =>
                                    handleFormAsyncActions({
                                        action: 'UpdateField',
                                        payload: { key: 'allowMultipleConversions', value: e.target.value === 'enabled' },
                                    })}
                  value={values.allowMultipleConversions ? 'enabled' : 'disabled'}
                >
                  <Radio value="enabled">Enabled</Radio>
                  <Radio value="disabled">Disabled</Radio>
                </Radio.Group>
              </>
            </Form.Item>
          </div>
        </div>
        )
    );
};

export default AllowMultipleconversions;
