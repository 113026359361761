import { ActionType, TState } from '../types';

interface IAction {
  id: number;
  type: ActionType.SET_SELECTED_TERM_ID
}
export type TSetSelectedTermIdAction = Readonly<IAction>;

export const setSelectedTermId = (state: TState, action: TSetSelectedTermIdAction): TState => ({
    ...state,
    selectedTermId: action.id,
  });
