import React, { useMemo } from 'react';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import { largeIntegerAbbreviator, largeNumberAbbreviatorWithDecimal } from '@frontend/utils';

interface SecondaryMetricProps {
  size: 'large' | 'medium' | 'small' | 'xsmall';
  heading?: string;
  value: number;
  metricType?: 'number' | 'currency' | 'count';
  icon?: React.ReactNode;
  className?: string;
}

const valueTextStyles = cva(
  'm-0',
  {
    variants: {
      size: {
        large: 'text-5xl font-bold',
        medium: 'text-4xl font-bold',
        small: 'text-2xl font-bold',
        xsmall: 'text-xl font-semibold',
      },
    },
  },
);

const currencyTextStyles = cva(
  'm-0',
  {
    variants: {
      size: {
        large: 'text-4xl font-bold',
        medium: 'text-3xl font-bold',
        small: 'text-lg font-bold',
        xsmall: 'text-base font-semibold',
      },
    },
  },
);

const iconStyles = cva(
  'flex items-center',
  {
    variants: {
      size: {
        large: 'text-xl',
        medium: 'text-lg',
        small: 'text-base',
        xsmall: 'text-sm',
      },
    },
  },
);

const SecondaryMetric: React.FC<SecondaryMetricProps> = ({
  size,
  heading,
  value,
  metricType = 'number',
  icon,
  className,
}) => {
  const result = useMemo(() => {
    if (metricType === 'count') {
      return largeIntegerAbbreviator(value || 0);
    }
    return largeNumberAbbreviatorWithDecimal(value || 0);
  }, [metricType, value]);
  const symbol = metricType === 'currency' ? result.symbol : '';

  return (
    <div className={classNames('flex flex-col gap-[1px]', className)}>
      {heading && <span className="text-gray-500">{heading}</span>}
      <div className="flex items-baseline gap-1">
        {metricType === 'currency' && (
          <span className={currencyTextStyles({ size })}>$</span>
        )}
        <span className={valueTextStyles({ size })}>{result.value}</span>
        <span className={currencyTextStyles({ size })}>{symbol}</span>
        {icon && (
          <div className={iconStyles({ size })}>
            {icon}
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondaryMetric;
