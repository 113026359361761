import { differenceBy } from 'lodash';
import { IImageSearch } from '../models';
import { IImageSearchAction, ImageSearchActionTypes } from './imageSearchActions';
import { getInitialImageSearchFilters } from '../utils/initialFilters';

const initialState: IImageSearch = getInitialImageSearchFilters([]);

export const imageSearchReducer = (
  state = initialState,
  action: IImageSearchAction,
) => {
  switch (action.type) {
    case ImageSearchActionTypes.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        isLoadingImage: true,
      };

    case ImageSearchActionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageURL: action.payload.imageURL,
        isLoadingImage: false,
      };

    case ImageSearchActionTypes.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        isLoadingImage: false,
      };

    case ImageSearchActionTypes.SELECT_IMAGE:
      return {
        ...state,
        imageURL: action.payload.imageURL,
      };

    case ImageSearchActionTypes.REMOVE_IMAGE:
      return {
        ...state,
        imageURL: null,
      };

    case ImageSearchActionTypes.UPDATE_COUNTRIES:
      return {
        ...state,
        countries: action.payload.countries,
      };

    case ImageSearchActionTypes.UPDATE_HIDE_INVITED:
      return {
        ...state,
        hideInvited: action.payload.hideInvited,
      };

    case ImageSearchActionTypes.FETCH_CONTENT_LISTS_REQUEST:
      return {
        ...state,
        isLoadingContentLists: true,
        hasContentListError: false,
      };

    case ImageSearchActionTypes.FETCH_CONTENT_LISTS_SUCCESS:
      return {
        ...state,
        contentLists: action.payload.contentLists,
        isLoadingContentLists: false,
        hasContentListError: false,
      };

    case ImageSearchActionTypes.FETCH_CONTENT_LISTS_FAILURE:
      return {
        ...state,
        isLoadingContentLists: false,
        hasContentListError: true,
      };

    case ImageSearchActionTypes.FETCH_CONTENT_REQUEST:
      return {
        ...state,
        isLoadingContent: true,
        hasContentError: false,
      };

    case ImageSearchActionTypes.FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        contentByListId: {
          ...state.contentByListId,
          [action.payload.contentListId]: action.payload.content,
        },
        isLoadingContent: false,
        hasContentError: false,
      };

    case ImageSearchActionTypes.FETCH_CONTENT_FAILURE:
      return {
        ...state,
        isLoadingContent: false,
        hasContentError: true,
      };

    case ImageSearchActionTypes.REMOVE_CONTENT_FROM_LIST_REQUEST: {
      const listId = action.payload.contentListId;
      return {
        ...state,
        contentByListId: {
          ...state.contentByListId,
          [listId]: differenceBy(state.contentByListId[listId], action.payload.content, 'id'),
        },
      };
    }

    default:
      return state;
  }
};
