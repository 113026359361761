import * as React from 'react';
import {
  Button, Alert, Typography,
} from '@revfluence/fresh';
import { Link } from 'react-router-dom';
import {
  SOCIAL_POST_APP_ID,
} from '@frontend/app/constants/applicationIds';
import styles from './Alerts.scss';

// eslint-disable-next-line max-len
const instagramHelpArticle = 'https://intercom.help/aspireiq_elevate/en/articles/8104130-how-to-connect-your-brand-s-instagram-account';
// TODO: Update this link to the correct TikTok Spark Ads help article
// eslint-disable-next-line max-len
const tiktokSparkAdsHelpArticle = 'https://intercom.help/aspireiq_elevate/en/articles/8104130-how-to-connect-your-brand-s-instagram-account';

export const AlertInstagramInsightsMissing: React.FC = () => (
  <Alert
    message="Instagram Insights Missing"
    showIcon
    description={(
      <>
        The Instagram account selected for Ads Permissions is missing
        {' '}
        a connection to Instagram Insights. Please link insights to continue.
      </>
    )}
    type="error"
    subAction
    action={(
      <Link target="_blank" rel="noopener noreferrer" to={`/settings/${SOCIAL_POST_APP_ID}`}>
        <Button className={styles.AlertBtn} ghost>Link Insights</Button>
      </Link>
    )}
  />
);

export const AlertAdsPermissionsNeeded: React.FC = () => (
  <Alert
    message="Ads Permissions Needed"
    showIcon
    description={(
      <>
        To continue, please go to your Aspire settings and change Ads Permissions for your preferred
        {' '}
        Instagram account from inactive to active. Need help?
        {' '}
        <Typography.Link href={instagramHelpArticle} target="_blank">Learn more</Typography.Link>
      </>
    )}
    type="error"
    subAction
    action={(
      <Link target="_blank" rel="noopener noreferrer" to={`/settings/${SOCIAL_POST_APP_ID}`}>
        <Button className={styles.AlertBtn} ghost>Fix Settings</Button>
      </Link>
    )}
  />
);

export const AlertBusinessAccountMissing: React.FC = () => (
  <Alert
    message="Business Account Missing"
    showIcon
    description={(
      <>
        To continue, please go to your Aspire settings and select which
        {' '}
        business account to use for allowlisting. Need help?
        {' '}
        <Typography.Link href={instagramHelpArticle} target="_blank">Learn more</Typography.Link>
      </>
    )}
    type="error"
    subAction
    action={(
      <Link target="_blank" rel="noopener noreferrer" to={`/settings/${SOCIAL_POST_APP_ID}`}>
        <Button className={styles.AlertBtn} ghost>Select Business</Button>
      </Link>
    )}
  />
);

export const AlertTikTokSparkAdsMissing: React.FC = () => (
  <Alert
    message="No TikTok Ad Account Selected"
    showIcon
    description={(
      <>
        To continue, please select an Ad Account for Spark Ads. Need help?
        {' '}
        <Typography.Link href={tiktokSparkAdsHelpArticle} target="_blank">Learn more</Typography.Link>
      </>
    )}
    type="error"
    subAction
    action={(
      <Link target="_blank" rel="noopener noreferrer" to="/settings/tiktok">
        <Button className={styles.AlertBtn} ghost>Settings</Button>
      </Link>
    )}
  />
);
