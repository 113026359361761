import * as React from 'react';
import { Button, Typography } from '@revfluence/fresh';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './AffiliateTableSTAPagination.scss';

const { useMemo } = React;
const { Text } = Typography;
interface IProps {
  pageSize: number,
  page: number,
  dataSourceLength: number,
  setPage: (value: React.SetStateAction<number>) => void,
}

export const AffiliateTableSTAPagination: React.FC<Readonly<IProps>> = (props) => {
  const {
    pageSize, page, dataSourceLength, setPage,
  } = props;
  const itemsDisplayed = useMemo(() => {
    const firstItem = pageSize * page - pageSize + 1;
    const lastItem = pageSize * page < dataSourceLength ? pageSize * page : dataSourceLength;
    return `${firstItem}-${lastItem}`;
  }, [page, pageSize, dataSourceLength]);

  return (
    <div className={styles.customPagination}>
      <Text>{`${itemsDisplayed} of ${dataSourceLength}`}</Text>
      <Button className={styles.paginationButton} onClick={() => setPage(page - 1)} disabled={page === 1} icon={<ChevronLeftIcon fontSize={24} />} />
      <Button className={styles.paginationButton} onClick={() => setPage(page + 1)} disabled={page === Math.ceil(dataSourceLength / pageSize) || dataSourceLength === 0} icon={<ChevronRightIcon fontSize={24} />} />
    </div>
  );
};
