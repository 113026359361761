import 'css-chunk:src/app/components/ModifyTagMembersModal/TagActions.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1y5de_157",
  "justify-content-space-between": "_justify-content-space-between_1y5de_161",
  "tabular-nums": "_tabular-nums_1y5de_165",
  "TagActions": "_TagActions_1y5de_178",
  "Icon": "_Icon_1y5de_178",
  "Active": "_Active_1y5de_182",
  "DeleteModal": "_DeleteModal_1y5de_186",
  "Popover": "_Popover_1y5de_190",
  "FormAction": "_FormAction_1y5de_193",
  "FormActionTitle": "_FormActionTitle_1y5de_199",
  "FormActionInput": "_FormActionInput_1y5de_205",
  "hasError": "_hasError_1y5de_212",
  "FormActionButton": "_FormActionButton_1y5de_215",
  "Menu": "_Menu_1y5de_226",
  "MenuItem": "_MenuItem_1y5de_229",
  "MenuItemIcon": "_MenuItemIcon_1y5de_238",
  "Delete": "_Delete_1y5de_186",
  "show": "_show_1y5de_1"
};