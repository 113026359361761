import * as React from 'react';

import { IState, TDispatch } from '../types/state';
import { ReviewTable } from './ReviewTable/ReviewTable';
import { useGetReviewTableProps } from './ReviewTable/useGetReviewTableProps';
import { Summary } from './Summary/Summary';

import styles from './ReviewAndSummary.scss';

interface IProps {
  collaborationDetails: IState['collaborationDetails'];
  contentGuidelines: IState['contentGuidelines'];
  dispatch: TDispatch;
  members: IState['members'];
}

export const ReviewAndSummary: React.FC<IProps> = React.memo((props) => {
  const {
    collaborationDetails,
    contentGuidelines,
    dispatch,
    members,
  } = props;

  const reviewTableProps = useGetReviewTableProps(
    dispatch,
    collaborationDetails,
    contentGuidelines,
    members,
  );

  return (
    <div className={styles.ReviewAndSummary}>
      <Summary
        collaborationDetails={collaborationDetails}
        contentGuidelines={contentGuidelines}
        members={members}
      />
      <ReviewTable {...reviewTableProps} />
    </div>
  );
});
ReviewAndSummary.displayName = 'ReviewAndSummary';
