import * as React from 'react';
import cx from 'classnames';
import {
 first, isFunction, isNumber, last, slice,
} from 'lodash';
import numeral from 'numeral';
import { Input, InputNumber } from 'antd';

import styles from './NumberBetween.scss';

const { useCallback, useImperativeHandle, useRef } = React;

interface IProps {
  value: [number, number];
  isPercentage?: boolean;
  onChange?(value: [number, number]);
}

export interface INumberBetweenHandles {
  focus(): void;
}

const percentageFormatter = (value) => numeral(value / 100).format('0.[00]%').replace('%', '');

const NumberBetweenComponent: React.RefForwardingComponent<INumberBetweenHandles, IProps> = (props, ref) => {
  const {
    value,
    isPercentage,
    onChange,
  } = props;

  if (isNumber(value)) {
    onChange([value, value]);
  }

  // Used to focus the first input field
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const firstInputNumberRef = useRef<any>();
  useImperativeHandle(ref, () => ({
    focus: () => firstInputNumberRef.current.focus(),
  }));

  const handleChangeBetween = useCallback((idx: number, newValue: number) => {
    if (isFunction(onChange)) {
      const newBetweenValue = slice(value) as [number, number];
      newBetweenValue[idx] = Number(newValue);
      onChange(newBetweenValue);
    }
  }, [value, onChange]);

  return (
    <div className={styles.NumberBetween}>
      <Input.Group className={styles.inputGroup} compact>
        <InputNumber
          className={cx([styles.input, { [styles.percentage]: isPercentage }])}
          value={first(value)}
          onChange={(val) => handleChangeBetween(0, val)}
          ref={firstInputNumberRef}
          min={isPercentage ? 0 : undefined}
          max={isPercentage ? 100 : undefined}
          formatter={isPercentage ? percentageFormatter : undefined}
        />
        <Input
          className={styles.and}
          placeholder="and"
          disabled
        />
        <InputNumber
          className={cx([styles.input, { [styles.percentage]: isPercentage }])}
          value={last(value)}
          onChange={(val) => handleChangeBetween(1, val)}
          min={isPercentage ? 0 : undefined}
          max={isPercentage ? 100 : undefined}
          formatter={isPercentage ? percentageFormatter : undefined}
        />
      </Input.Group>
    </div>
  );
};

export const NumberBetween = React.forwardRef(NumberBetweenComponent);
