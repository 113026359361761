import { isUndefined } from 'lodash';
import { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { GET_PREDEFINED_SEGMENTS_FOR_PROGRAM } from '../queries';
import {
  PredefinedSegmentsForProgramQuery,
  PredefinedSegmentsForProgramQueryVariables,
} from '../queries/types/PredefinedSegmentsForProgramQuery';

type IOptions = QueryHookOptions<PredefinedSegmentsForProgramQuery, PredefinedSegmentsForProgramQueryVariables>;

export const usePredefinedSegmentsForProgram = (
  vars: PredefinedSegmentsForProgramQueryVariables = {},
  options: IOptions = {},
) => useQuery<PredefinedSegmentsForProgramQuery, PredefinedSegmentsForProgramQueryVariables>(
    GET_PREDEFINED_SEGMENTS_FOR_PROGRAM,
    {
      ...options,
      variables: vars,
      skip: options.skip || isUndefined(vars.programId),
    },
  );
