import * as React from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useMessagingContext } from '@frontend/hooks';

const { useState, useCallback } = React;

interface IExportPaymentsParams {
  email: string;
  client_id: string;
}

interface IExportPaymentsResponse {
  id: number;
}

const exportPayments = async (url: string, params: IExportPaymentsParams) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export const useExportPayments = () => {
  const { backendServerApiEndpoint, clientId } = useApplication();
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();

  const url = `${backendServerApiEndpoint}/payment/export`;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IExportPaymentsResponse>(null);
  const [error, setError] = useState(null);

  const save = useCallback(async (email: string) => {
    setLoading(true);

    const params: IExportPaymentsParams = {
      email,
      client_id: clientId,
    };

    let result;
    try {
      result = await exportPayments(url, params);
      setData(result);
      showSuccessMessage(`A CSV of payments will be sent to ${email} within a few minutes.`, 10000);
    } catch (err) {
      setError(err);
      showErrorMessage('There was an unexpected error exporting payments');
    }

    setLoading(false);

    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, clientId]);

  return {
    save,
    loading,
    data,
    error,
  };
};
