import { formatDistanceToNow } from 'date-fns';
import {
  compact, find, map, sortBy, startCase,
} from 'lodash';

import { IClient, IContentReviewDetail, IProfile } from '@frontend/app/hooks';
import { ContentReviewEventType } from '@frontend/app/types/globalTypes';
import { TContentComment, TContentCommentStatus } from '../ContentDetails';

const StatusForEventType: Record<ContentReviewEventType, TContentCommentStatus> = {
  [ContentReviewEventType.ContentsApproved]: 'success',
  [ContentReviewEventType.ContentsApprovedWithComments]: 'warning',
  [ContentReviewEventType.ContentsRejected]: 'error',
  [ContentReviewEventType.ContentsUpdated]: 'info',
};

export function getContentReviewCommments({
  review, client,
}: {
  review: IContentReviewDetail;
  client: IClient;
  profile: IProfile;
}): TContentComment[] {
  if (!review) {
    return [];
  }

  const comments = compact(map(review.comments, (comment) => {
    const user = find(client.users, (u) => u.id === comment.userId);
    // if for some reason the user is deleted
    if (!user) {
      return null;
    }

    return {
      id: `comment_${comment.id}`,
      title: null,
      status: 'default' as TContentCommentStatus,
      date: new Date(comment.createdDate).getTime(),
      user: user.name,
      content: comment.info.text,
      guidelines: [],
    };
  }));
  const events = map(review.events, (event) => {
    const user = event.type === ContentReviewEventType.ContentsUpdated
      ? review.member
      : find(client.users, (u) => u.id === event.userId);

    return {
      id: `event_${event.id}`,
      title: startCase(event.type),
      status: StatusForEventType[event.type],
      date: new Date(event.createdDate).getTime(),
      user: user?.name,
      content: event.info?.comment,
      guidelines: event.info?.guidelines,
    };
  });

  // format the date before returning
  return map(sortBy([
    ...comments,
    ...events,
  ], (i) => i.date), (item) => ({
    ...item,
    date: `${formatDistanceToNow(new Date(item.date))} ago`,
  }));
}
