import * as React from 'react';
import { last } from 'lodash';
import {
  Collapse,
  Space,
  Button,
  Tooltip,
  Divider,
  Input,
  Typography,
} from '@revfluence/fresh';
import {
  CopyIcon,
  TrashIcon,
} from '@revfluence/fresh-icons/regular/esm';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import ContentGuidelineIcon from '@frontend/applications/TermsApp/components//shared/ContentGuidelineIcon';
import PostInstructions from '@frontend/applications/TermsApp/components/shared/PostInstructions/PostInstructions';
import PostAttachments from '@frontend/applications/TermsApp/components/shared/PostAttachments/PostAttachments';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import {
  ISortableGuideline,
  IContentGuidelineInstruction,
  IContentGuidelineTitle,
} from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';
import { TemplateType } from '@frontend/applications/TermsApp/components/ContentGuidelines/types/state';
import { moveCaretAtEnd } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import styles from './GuidelinesListItem.module.scss';

const { Panel } = Collapse;
const { Text } = Typography;
const {
  useState,
  useCallback,
} = React;

interface IProps {
  isReadOnly: boolean;
  shouldListSectionsOnly: boolean;
  templateType: TemplateType;
  contentGuideline: TContentGuideline;
  defaultActiveKey: number;
  onDuplicateContentGuideline: (contentGuideline: TContentGuideline) => void;
  onDeleteContentGuideline: (contentGuideline: TContentGuideline) => void;
  onSaveContentGuideline: (contentGuideline: TContentGuideline) => void;
  onUpdateContentGuidelineInstruction: (contentGuidelineInstruction: IContentGuidelineInstruction) => void
  onSortContentGuideline: (
    id: number,
    sortableGuideline: ISortableGuideline,
  ) => void;
  onAddContentGuidelineAttachment: (
    attachment: Attachment,
    id: number,
  ) => void;
  onDeleteContentGuidelineAttachment: (
    attachments: Array<Attachment>,
    id: number,
  ) => void;
  onUpdateContentGuidelineLabel: (id: number, label: string) => void;
  onUpdateContentGuidelineActiveKey: (defaultActiveKey: number) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
}

const GuidelinesListItem: React.FC<IProps> = React.memo((props) => {
  const {
    isReadOnly,
    shouldListSectionsOnly,
    contentGuideline,
    defaultActiveKey,
    onDuplicateContentGuideline,
    onDeleteContentGuideline,
    onSaveContentGuideline,
    onUpdateContentGuidelineInstruction,
    onSortContentGuideline,
    onAddContentGuidelineAttachment,
    onDeleteContentGuidelineAttachment,
    onUpdateContentGuidelineLabel,
    onUpdateContentGuidelineActiveKey,
    onUpdateContentGuidelineTitle,
  } = props;
  const [value, setValue] = useState(contentGuideline?.label);
  const [shouldDisplayInput, setShouldDisplayInput] = useState(false);

  const handleShouldDisplayInputTitle = useCallback((key: number) => {
    onUpdateContentGuidelineActiveKey(key);
    setShouldDisplayInput(key === contentGuideline?.id);
  }, [
    contentGuideline,
    onUpdateContentGuidelineActiveKey,
  ]);

  return (
    <Collapse
      ghost
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="right"
      className={styles.GuidelinesListItem}
      onChange={(keys) => handleShouldDisplayInputTitle(
        parseInt(last(keys), 10),
      )}
      destroyInactivePanel
    >
      <Panel
        header={(
          <>
            <Space
              direction="horizontal"
              style={{ width: '100%' }}
            >
              <ContentGuidelineIcon contentGuidelineType={contentGuideline.type} />
              {(isReadOnly || !shouldDisplayInput) && (
                <span>
                  {contentGuideline.label}
                </span>
              )}
              { (!isReadOnly && shouldDisplayInput) && (
              <Input
                value={value}
                autoFocus
                onFocus={moveCaretAtEnd}
                bordered
                className={styles.input}
                onChange={(event) => setValue(event.target.value)}
                onClick={(event) => event.stopPropagation()}
                onPressEnter={(event) => {
                  onUpdateContentGuidelineLabel(
                    contentGuideline.id,
                    event.currentTarget.value,
                  );
                  event.preventDefault();
                  event.stopPropagation();
                }}
                onBlur={(event) => {
                  onUpdateContentGuidelineLabel(
                    contentGuideline.id,
                    event.currentTarget.value,
                  );
                  event.preventDefault();
                  event.stopPropagation();
                }}
              />
              )}
            </Space>
            {
              !isReadOnly && (
                <Space>
                  <Tooltip title="Duplicate">
                    <Button
                      onClick={(event) => {
                        contentGuideline.isCopy = true;
                        onDuplicateContentGuideline(contentGuideline);
                        event.stopPropagation();
                      }}
                      icon={<CopyIcon />}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      onClick={(event) => {
                        onDeleteContentGuideline(contentGuideline);
                        event.stopPropagation();
                      }}
                      icon={<TrashIcon />}
                    />
                  </Tooltip>
                </Space>
              )
            }
          </>
      )}
        key={contentGuideline.id}
      >
        { shouldListSectionsOnly && (
          <div className={styles.indentedBlankTemplate}>
            <Text strong>
              Template Sections
            </Text>
            <ul className={styles.listedSections}>
              { contentGuideline.guidelines.map((guideline) => (
                <li key={`${contentGuideline.id}-${guideline.type}`}>
                  {guideline.title}
                </li>
              ))}
              <li>Attachments</li>
            </ul>
          </div>
        )}
        { !shouldListSectionsOnly && (
          <PostInstructions
            isReadOnly={isReadOnly}
            id={contentGuideline.id}
            guidelines={contentGuideline.guidelines}
            onAddContentGuidelineInstruction={onUpdateContentGuidelineInstruction}
            onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
            onSortGuideline={onSortContentGuideline}
          />
        )}
        { !isReadOnly && (
          <PostAttachments
            id={contentGuideline.id}
            attachments={contentGuideline.attachments}
            onAddAttachment={onAddContentGuidelineAttachment}
            onRemoveAttachment={onDeleteContentGuidelineAttachment}
          />
        )}
        { isReadOnly && (
          <Button
            type="primary"
            onClick={() => onDuplicateContentGuideline(contentGuideline)}
            className={shouldListSectionsOnly && styles.indentedBlankTemplate}
          >
            Use Template
          </Button>
        )}
        { !isReadOnly && <Button type="primary" onClick={() => onSaveContentGuideline(contentGuideline)}>Save</Button> }
      </Panel>
      <Divider />
    </Collapse>
  );
});

GuidelinesListItem.displayName = 'GuidelinesListItem';

export default GuidelinesListItem;
