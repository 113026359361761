import * as React from 'react';
import { isEmpty, find } from 'lodash';

import { useQuery } from '@apollo/client';
import {
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { SEARCH_MEMBERS_QUERY } from '@frontend/app/queries';
import {
  SearchMembersQuery,
  SearchMembersQueryVariables,
} from '@frontend/app/queries/types/SearchMembersQuery';

const { useMemo } = React;
const INSTAGRAM_FIELD_NAME = 'Instagram';

export function useSearchMemberByInstagram(values: string[]) {
  const {
    data: {
      schemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();
  const instagramFieldId = useMemo(() => {
    const instagramSchema = find(schemas, (s) => s.name === INSTAGRAM_FIELD_NAME);

    return instagramSchema?.id;
  }, [schemas]);

  const fields = values.map((value) => ({
    // Remove the @ from the beginning.
    contains: value.startsWith('@') ? value.substring(1) : value,
    memberFieldSchemaId: instagramFieldId,
  }));

  const query: IMemberSearchQuery = {
    fields: {
      or: fields,
    },
  };

  const {
    loading: loadingMembers,
    error,
    data,
    refetch,
  } = useQuery<SearchMembersQuery, SearchMembersQueryVariables>(SEARCH_MEMBERS_QUERY, {
    variables: {
      query,
      take: 100,
      skip: 0,
    },
    skip: isEmpty(values),
    fetchPolicy: 'no-cache',
  });

  const loading = loadingMembers;

  return {
    loading,
    error,
    data,
    refetch,
    instagramFieldId,
  };
}
