._Content_1hnvq_1 {
  position: relative;
  overflow: hidden;
}
._Content_1hnvq_1 ._content_1hnvq_5 {
  width: 100%;
  height: 100%;
  will-change: filter, transform;
  transform-origin: center center;
  transition: all 0.2s ease;
}
._Content_1hnvq_1 ._content_1hnvq_5._loading_1hnvq_12 {
  filter: blur(5px);
}
._Content_1hnvq_1 ._content_1hnvq_5._hover_1hnvq_15 {
  background-color: #999;
  filter: brightness(0.5);
  transform: scale(1.05, 1.05);
}
._Content_1hnvq_1 ._progressWrapper_1hnvq_20 {
  padding: 0 15px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  pointer-events: auto;
}
._Content_1hnvq_1 ._progressWrapper_1hnvq_20 ._progressLabel_1hnvq_33 {
  color: white;
}
._Content_1hnvq_1 ._info_1hnvq_36 {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  font-size: 13px;
}
._Content_1hnvq_1 ._info_1hnvq_36 ._fileType_1hnvq_43 {
  position: absolute;
  top: 7px;
  left: 5px;
  color: rgb(57, 122, 242);
}
._Content_1hnvq_1 ._info_1hnvq_36 ._fileType_1hnvq_43._image_1hnvq_49 {
  color: rgb(251, 179, 9);
}
._Content_1hnvq_1 ._info_1hnvq_36 ._fileType_1hnvq_43._pdf_1hnvq_52 {
  color: rgb(231, 58, 47);
}
._Content_1hnvq_1 ._info_1hnvq_36 ._fileType_1hnvq_43._video_1hnvq_55 {
  color: rgb(231, 58, 47);
}
._Content_1hnvq_1 ._info_1hnvq_36 ._detail_1hnvq_58 {
  position: relative;
  top: 6px;
  left: 30px;
  width: 90px;
  display: flex;
  flex-direction: column;
  color: white;
}
._Content_1hnvq_1 ._info_1hnvq_36 ._name_1hnvq_67 {
  font-weight: bold;
  word-break: break-all;
}
._Content_1hnvq_1 ._info_1hnvq_36 ._size_1hnvq_71 {
  margin-top: 5px;
  color: #d3d3d3;
  font-size: 11px;
  font-weight: bold;
}