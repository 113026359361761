import * as React from 'react';

import {
  TermsWizard,
  IProps as TermsWizardProps,
} from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard';

import { Loading } from './Loading';

export interface IProps {
  footerSettings: TermsWizardProps['footerSettings'],
}

export const LoadingBulkTerms: React.FC<IProps> = React.memo((props) => {
  const { footerSettings } = props;

  return (
    <TermsWizard footerSettings={footerSettings}>
      <Loading />
    </TermsWizard>
  );
});

LoadingBulkTerms.displayName = 'LoadingBulkTerms';
