import * as React from 'react';
import { Drawer } from 'antd';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';

import { useAppContext } from '@frontend/context/AppContext';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';
import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import { PaymentGroupInvoice } from '@frontend/applications/PaymentsApp/BulkPaymentsApp/containers';

import styles from './STABulkPayments.scss';

interface ISTAPaymentGroupInvoice {
  clientId: string;
  clientName: string;
  paymentGroupId?: number;
  visible: boolean;
  onClose: () => void;
}

export const STAPaymentGroupInvoice: React.FC<Readonly<ISTAPaymentGroupInvoice>> = (props) => {
  const { apolloClient } = useAppContext();

  return (
    <Drawer
      visible={props.visible}
      className={styles.STABulkPayments}
      onClose={props.onClose}
      width="80%"
    >
      <ApplicationProvider
        backendServerApiEndpoint={backendServerApiEndpoint()}
        analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
        backendServerWebEndpoint={backendServerWebEndpoint()}
        clientId={props.clientId}
        clientName={props.clientName}
      >
        {apolloClient
          && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          <ApolloHooksProvider client={apolloClient}>
            {props.paymentGroupId
              && (
              <PaymentGroupInvoice
                clientId={props.clientId}
                paymentGroupId={props.paymentGroupId}
                onClose={props.onClose}
              />
)}
          </ApolloHooksProvider>
)}
      </ApplicationProvider>
    </Drawer>
  );
};
