import * as React from 'react';
import { Select } from '@revfluence/fresh';
import {
 startOfWeek, subDays, startOfMonth, subMonths, startOfYear, endOfMonth, startOfQuarter,
 endOfQuarter, subQuarters, differenceInDays,
} from 'date-fns';

export enum DATE_RANGES {
  ALL_TIME = 'ALL_TIME',
  THIS_WEEK = 'THIS_WEEK',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  LAST_QUARTER = 'LAST_QUARTER',
  QUARTER_TO_DATE = 'QUARTER_TO_DATE',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  CUSTOM = 'CUSTOM',

}
type TDateRanges = keyof typeof DATE_RANGES;

export const DateRangesLabel = {
  [DATE_RANGES.ALL_TIME]: 'All Time',
  [DATE_RANGES.THIS_WEEK]: 'This Week',
  [DATE_RANGES.LAST_WEEK]: 'Last Week',
  [DATE_RANGES.LAST_MONTH]: 'Last Month',
  [DATE_RANGES.MONTH_TO_DATE]: 'Month to Date',
  [DATE_RANGES.LAST_QUARTER]: 'Last Quarter',
  [DATE_RANGES.QUARTER_TO_DATE]: 'Quarter to Date',
  [DATE_RANGES.LAST_6_MONTHS]: 'Last 6 Months',
  [DATE_RANGES.YEAR_TO_DATE]: 'Year to Date',
};

export const getCustomCompareRange = (clientStartDate: Date, dateRange: [Date?, Date?]) : [Date?, Date?] => {
  const [startDate, endDate] = dateRange;
  const dateDiff = differenceInDays(endDate, startDate);
  const compareRange = [subDays(startDate, dateDiff + 1), subDays(endDate, dateDiff + 1)];
  return differenceInDays(clientStartDate, compareRange[0]) >= 0 ? [null, null] : [compareRange[0], compareRange[1]];
};

export const getDateRange = (value: string): [Date?, Date?] => {
  const today = new Date();
  switch (value) {
    case DATE_RANGES.ALL_TIME:
      return [undefined, undefined];
    case DATE_RANGES.THIS_WEEK:
      return [startOfWeek(today, { weekStartsOn: 1 }), today];
    case DATE_RANGES.LAST_WEEK: {
      const endDay = subDays(startOfWeek(today, { weekStartsOn: 1 }), 1);
      const startDay = startOfWeek(endDay, { weekStartsOn: 1 });
      return [startDay, endDay];
    }
    case DATE_RANGES.LAST_MONTH: {
      const lastMonth = subMonths(today, 1);
      const endDay = endOfMonth(lastMonth);
      const startDay = startOfMonth(lastMonth);
      return [startDay, endDay];
    }
    case DATE_RANGES.MONTH_TO_DATE: {
      const endDay = today;
      const startDay = startOfMonth(today);
      return [startDay, endDay];
    }
    case DATE_RANGES.LAST_QUARTER: {
      const startDay = subQuarters(startOfQuarter(today), 1);
      const endDay = endOfQuarter(startDay);
      return [startDay, endDay];
    }
    case DATE_RANGES.QUARTER_TO_DATE: {
      const endDay = today;
      const startDay = startOfQuarter(today);
      return [startDay, endDay];
    }
    case DATE_RANGES.LAST_6_MONTHS: {
      const endDay = today;
      const startDay = subMonths(today, 6);
      return [startDay, endDay];
    }
    case DATE_RANGES.YEAR_TO_DATE: {
      const endDay = today;
      const startDay = startOfYear(today);
      return [startDay, endDay];
    }
    default:
      return [undefined, undefined];
  }
};

export type DateSelectOnChange = (dateRange: [Date?, Date?], label: string) => void;
export interface IDateSelectProps {
  onChange: DateSelectOnChange;
}
export const DateSelect: React.FC<IDateSelectProps> = ({ onChange }) => {
  const handleChange = React.useCallback((value: TDateRanges) => {
    const dateRange = getDateRange(value);
    const label = DateRangesLabel[value];
    return onChange(dateRange, label);
  }, [onChange]);
  return (
    <Select onChange={handleChange} defaultValue={DATE_RANGES.ALL_TIME}>
      <Select.Option value={DATE_RANGES.ALL_TIME}>
        {DateRangesLabel.ALL_TIME}
      </Select.Option>
      <Select.Option value={DATE_RANGES.THIS_WEEK}>
        {DateRangesLabel.THIS_WEEK}
      </Select.Option>
      <Select.Option value={DATE_RANGES.LAST_WEEK}>
        {DateRangesLabel.LAST_WEEK}
      </Select.Option>
      <Select.Option value={DATE_RANGES.LAST_MONTH}>
        {DateRangesLabel.LAST_MONTH}
      </Select.Option>
      <Select.Option value={DATE_RANGES.MONTH_TO_DATE}>
        {DateRangesLabel.MONTH_TO_DATE}
      </Select.Option>
      <Select.Option value={DATE_RANGES.LAST_QUARTER}>
        {DateRangesLabel.LAST_QUARTER}
      </Select.Option>
      <Select.Option value={DATE_RANGES.QUARTER_TO_DATE}>
        {DateRangesLabel.QUARTER_TO_DATE}
      </Select.Option>
      <Select.Option value={DATE_RANGES.LAST_6_MONTHS}>
        {DateRangesLabel.LAST_6_MONTHS}
      </Select.Option>
      <Select.Option value={DATE_RANGES.YEAR_TO_DATE}>
        {DateRangesLabel.YEAR_TO_DATE}
      </Select.Option>
    </Select>
  );
};
