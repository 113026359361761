import * as React from 'react';
import { Col, CollapsePanelProps, Row } from 'antd';
import {
  Space, Collapse, Spinner,
  Tag, Typography,
} from '@revfluence/fresh';
import { randomiseColorsForTags } from '../../utils';
import styles from './DeeplinkSection.scss';

// import styles from './Section.scss';

const { useState, useCallback } = React;
const { Panel } = Collapse;

type RenderProps = {
  viewMore: boolean;
  setViewMoreVisibility: (visible: boolean) => void;
  setIsSectionEmpty: (empty: boolean) => void;
};

type Props = CollapsePanelProps & {
  loading: boolean;
  render: (props?: RenderProps) => React.ReactNode;
};

export const Section: React.FC<Props> = ({
  render,
  loading,
  ...otherProps
}) => {
  const [viewMore, setViewMore] = useState(false);
  const [, setVisible] = useState(false);
  const [isSectionEmpty, setIsSectionEmpty] = useState(false);

  const setSectionEmpty = useCallback(
    (empty: boolean) => {
      setIsSectionEmpty(empty);
      if (isSectionEmpty && !empty) {
        setViewMore(true);
      }
    },
    [isSectionEmpty, setIsSectionEmpty, setViewMore],
  );

  return (
    <Panel {...otherProps}>
      <Space direction="vertical">
        {loading ? (
          <Spinner />
        ) : (
          render({
            viewMore: viewMore || isSectionEmpty,
            setViewMoreVisibility: setVisible,
            setIsSectionEmpty: setSectionEmpty,
          })
        )}
      </Space>
    </Panel>
  );
};
export const SectionHeader = ({ label, shortLink }) =>
  // const { label, shortLink, affiliateUrl } = props;
   (
     <Row gutter={16}>
       <Col span={8}>
         <Tag color={randomiseColorsForTags(label)}>{label}</Tag>
       </Col>

       <Col
         span={16}
         className={`justify-between items-center text-sm font-normal underline ${styles.copyBtn}`}
         style={{ display: 'flex' }}
       >
         <Typography.Link target="_blank" rel="noreferrer noopener" href={shortLink} copyable>
           {shortLink}
         </Typography.Link>
       </Col>
     </Row>
  );
