/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._StepIndicator_1t1y5_32 ._dash_1t1y5_32 {
  display: inline-block;
  background-color: #e9e8ea;
  width: 20px;
  height: 4px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
}
._StepIndicator_1t1y5_32 ._dash_1t1y5_32:not(:last-of-type):not(._selected_1t1y5_41) {
  transition: background-color 0.3s ease-out;
}
._StepIndicator_1t1y5_32 ._dash_1t1y5_32._selected_1t1y5_41 {
  transition: background-color 0.3s ease-in;
  background-color: #3996e0;
}