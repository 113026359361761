import gql from 'graphql-tag';

import { CLIENT_FEATURE_FRAGMENT } from './fragments';

export const GET_CLIENT_FEATURE_BY_NAME = gql`
  query GetClientFeatureByName($name: String!) {
    clientFeature: getClientFeatureByName(name: $name) {
        ...ClientFeatureFragment
    }
  }
  ${CLIENT_FEATURE_FRAGMENT}
`;

export const IS_CLIENT_FEATURE_ENABLED = gql`
  query IsClientFeatureEnabled($name: String!) {
    isClientFeatureEnabled(name: $name)
  }
`;
