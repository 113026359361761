/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1bext_157 {
  display: flex;
}

._justify-content-space-between_1bext_161 {
  justify-content: space-between;
}

._tabular-nums_1bext_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1bext_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._STABulkPayments_1bext_178 .ant-drawer-content {
  float: right;
}
._STABulkPayments_1bext_178 .ant-drawer-body {
  padding: 0;
}
._STABulkPayments_1bext_178 ._BulkPaymentApp_1bext_184 {
  height: 100%;
}