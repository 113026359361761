/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useMemo } from 'react';
import {
  isEmpty, first, keyBy, size, trim, toString,
} from 'lodash';
import {
  useMemberFieldSchemasQuery,
  useSaveMemberMutation,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { MemberFieldSchemasQuery_schemas as ISchema } from '@frontend/app/queries/types/MemberFieldSchemasQuery';
import { FieldType } from '@frontend/app/types/Fields';
import { getSocialHandle, isSocialField } from '@frontend/app/utils/getSocialHandle';
import {
  localToUTC,
} from '@frontend/app/utils/date';
import { IRowData } from '@components';
import { parseUrl } from '@frontend/app/utils/url';

const FIRST_NAME_FIELD = 'First Name';
const LAST_NAME_FIELD = 'Last Name';
const YOUTUBE_FIELD = 'YouTube';

export const useChangeCellValue = () => {
  const {
    data: {
      schemas: memberFieldSchemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const [saveMember] = useSaveMemberMutation({
    onError(err) {
      showError(err);
    },
  }, { showMembersHaveChanged: false });

  const {
    showError,
    showMessage,
  } = useMessagingContext();

  const memberFieldSchemasById = useMemo(() => (
    keyBy(memberFieldSchemas, 'id')
  ), [memberFieldSchemas]);

  const schemaByName = useMemo(() => (
    memberFieldSchemas && keyBy(memberFieldSchemas, 'name')
  ), [memberFieldSchemas]);

  return useCallback(async (rowId: string, field: string, newValue: any, oldValue: any, rowData: IRowData) => {
    const memberId = parseInt(rowId, 10);
    let schemaId = parseInt(field, 10);
    newValue = trim(newValue);
    oldValue = trim(oldValue);
    schemaId = isNaN(schemaId) ? null : schemaId;
    let schema: ISchema = null;

    if (schemaId !== null) {
      schema = memberFieldSchemasById[schemaId];

      if (schema?.type === FieldType.DATE || schema?.type === FieldType.ANNUAL) {
        newValue = localToUTC(newValue);
      } else if (newValue && isSocialField(schema?.name)) {
        newValue = getSocialHandle({
          network: schema.name,
          username: newValue,
          shouldValidateUsername: true,
          shouldPrependAtSymbol: false,
        });
      }
    }

    // Prevent sending up empty strings as value. Use null instead.
    if (newValue === '') {
      newValue = null;
    }

    const memberInput: MemberInput = {};
    if (schemaId) {
      memberInput.fields = {
        [schemaId]: newValue,
      };
    } else {
      memberInput[field] = newValue;
    }

    if (rowData) {
      const { _raw: member } = rowData;
      // If first name changes, change name as well to keep it in sync
      if (schema?.name === FIRST_NAME_FIELD) {
        const lastNameSchemaId = schemaByName[LAST_NAME_FIELD].id;
        const lastName = member.fields[lastNameSchemaId];
        let existingFirstName = trim(member.name);
        if (!isEmpty(lastName)) {
          existingFirstName = existingFirstName.split(lastName).filter(Boolean).join(' ');
        }

        if (oldValue && existingFirstName.includes(oldValue)) {
          existingFirstName = existingFirstName.replace(oldValue, toString(newValue));
          memberInput.name = `${trim(existingFirstName)} ${trim(lastName)}`;
        } else {
          const lastNameSchemaId = schemaByName[LAST_NAME_FIELD].id;
          let lastName = member.fields[lastNameSchemaId];
          if (!lastName && member.name) {
            lastName = member.name.split(' ').filter((s) => s).slice(1).join(' ');
          }
          memberInput.name = `${trim(newValue)} ${trim(lastName)}`;
        }
      }

      // If last name changes, change name as well to keep it in sync
      if (schema?.name === LAST_NAME_FIELD) {
        const firstNameSchemaId = schemaByName[FIRST_NAME_FIELD].id;
        const firstName = member.fields[firstNameSchemaId];
        let existingLastName = trim(member.name);
        if (!isEmpty(firstName)) {
          existingLastName = existingLastName.split(firstName).filter(Boolean).join(' ');
        }

        if (oldValue && existingLastName.includes(oldValue)) {
          existingLastName = existingLastName.replace(oldValue, toString(newValue));
          memberInput.name = `${trim(firstName)} ${trim(existingLastName)}`;
        } else {
          const firstNameSchemaId = schemaByName[FIRST_NAME_FIELD].id;
          let firstName = member.fields[firstNameSchemaId];
          if (!firstName && member.name) {
            firstName = first(member.name.split(' '));
          }
          memberInput.name = `${trim(firstName)} ${trim(newValue)}`;
        }
      }
    }

    // If youtube handle is updated, filter username from link
    if (schema?.name === YOUTUBE_FIELD) {
      const youtubeSchemaId = schemaByName[YOUTUBE_FIELD].id;
      if (newValue?.includes('youtube.com')) {
        const parsedUrl = parseUrl(newValue);
        try {
          const usernameParts = parsedUrl.pathname
            .split('youtube.com')
            .pop()
            .split('/')
            .filter(Boolean);

          let username = null;
          if (size(usernameParts) === 1) {
            username = usernameParts[0]; // url provided like youtube.com/test123
          } else if (size(usernameParts) > 1) {
            username = usernameParts[1]; // url provided like youtube.com/{user or c or channel}/test123
          }
          memberInput.fields[youtubeSchemaId] = username;
        } catch {
          memberInput.fields[youtubeSchemaId] = oldValue;
        }
      } else {
        memberInput.fields[youtubeSchemaId] = newValue;
      }
    }

    await saveMember({
      variables: {
        id: memberId,
        member: memberInput,
      },
    });

    if (schema?.choices) {
      showMessage({
        type: 'success',
        content: 'Values updated successfully',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveMember, showMessage, memberFieldSchemasById]);
};
