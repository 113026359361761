/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';
import {
 isFunction, replace, toLower, trim,
} from 'lodash';

import { Button, FormInstance } from '@revfluence/fresh';
import {
  CheckCircleIcon,
  HelpIcon,
  InstagramIcon,
  SpinnerIcon,
  TooltipIcon,
} from '@components';
import { backendServerWebEndpoint } from '@frontend/applications/Shared/serviceHosts';

import { INSTAGRAM_USERNAME_FIELD } from '@frontend/applications/SocialPostApp/appFields';
import { useGetOauthLink } from '../../hooks/useGetOauthLink';
import { useHasAnalyticsApiAccess } from '../../hooks/useHasAnalyticsApiAccess';

import styles from './InstagramInsights.scss';

const { useEffect, useImperativeHandle, useState } = React;

interface IProps {
  clientId: string;
  value?: string;
  onChange?: (isLinked: boolean) => void;
  form: FormInstance<any>;
}

export interface IInstagramInsightsHandles {
  validate?: (isRequired: boolean) => boolean;
}

export const InstagramInsights = React.forwardRef<IInstagramInsightsHandles, IProps>((props, ref) => {
  const {
    clientId,
    value,
    form,
    onChange,
  } = props;

  const [usernameState, setUsername] = useState<string>();
  const [markAsRequired, setMarkAsRequired] = useState<boolean>();
  const username = trim(usernameState);

  // Check for username changes
  useEffect(() => {
    const check = setInterval(() => {
      const value = form.getFieldValue(INSTAGRAM_USERNAME_FIELD);

      if (value !== username) {
        setUsername(toLower(replace(value, '@', '')));
      }
    }, 2000);
    return () => clearInterval(check);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, value]);

  const {
    hasAccess: isLinked,
    refetch: refetchHasAnalyticsApiAccess,
  } = useHasAnalyticsApiAccess(username);

  useEffect(() => {
    onChange?.(isLinked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLinked]);

  const {
    oauthLink,
    isLoading: isOauthLinkFetching,
  } = useGetOauthLink(username, clientId);

  useEffect(() => {
    const check = setInterval(() => {
      if (username && !isLinked && isFunction(refetchHasAnalyticsApiAccess)) {
        refetchHasAnalyticsApiAccess();
      }
    }, 5000);
    return () => clearInterval(check);
  }, [isLinked, refetchHasAnalyticsApiAccess, username]);

  // Reset validation
  useEffect(
    () => {
      setMarkAsRequired(false);
    },
    [username],
  );

  useImperativeHandle(
    ref,
    () => ({
      validate: (isRequired: boolean) => {
        setMarkAsRequired(isRequired && !isLinked);
        return !isRequired || isLinked;
      },
    }),
    [isLinked],
  );

  const linkInsights = () => {
    if (oauthLink) {
      window.open(
        backendServerWebEndpoint() + oauthLink,
        '_blank',
      );
    }
  };

  const renderAlreadyAuthorized = () => (
    <>
      <CheckCircleIcon
        size={22}
        className={styles.checkIcon}
      />
      @
      {username}
      {' '}
      has been validated
    </>
  );

  const buttonLabel = isOauthLinkFetching
    ? <SpinnerIcon size={20} className={styles.igIcon} />
    : (
      <div className={styles.buttonLabel}>
        <InstagramIcon size={20} className={styles.igIcon} />
        <div>
          Validate
          {' '}
          {username ? `@${username}` : 'Instagram Account'}
        </div>
      </div>
    );

  const renderButton = () => (
    <>
      <div className={styles.label}>
        {!isOauthLinkFetching && (
          `Click below to validate ${username ? ` @${username}` : ' your Instagram'}`
        )}
      </div>
      <Button
        type="primary"
        disabled={isLinked || isOauthLinkFetching || !form.getFieldValue(INSTAGRAM_USERNAME_FIELD)}
        onClick={linkInsights}
        style={{
          height: '50px',
          border: 'none',
          whiteSpace: 'pre-wrap',
          background: 'linear-gradient(90deg, #ffc550 0%, #f54347 51.91%, #982fbf 100%)',
        }}
      >
        {buttonLabel}
      </Button>
      <TooltipIcon
        className={styles.tooltipIcon}
        tooltipText={(
          'Share your Instagram Insights with us so we can validate your account. '
          + 'Learn more by clicking the Help icon.'
        )}
        tooltipProps={{
          className: styles.tooltip,
          placement: 'top',
          maxWidth: 240,
          mountRef: null,
        }}
        icon={(
          <a
            href="https://intercom.help/aspireiq_elevate/en/articles/4499822-instagram-insights-faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HelpIcon size={18} />
          </a>
        )}
      />
    </>
  );

  return (
    <div className={cx(styles.InstagramInsights, {
      [styles.markAsRequired]: markAsRequired,
    })}
    >
      {isLinked
        ? renderAlreadyAuthorized()
        : renderButton()}
    </div>
  );
});

InstagramInsights.displayName = 'InstagramInsights';
