import { useMutation, MutationHookOptions } from '@apollo/client';

import { CREATE_SHOPIFY_ORDER_CONFIG_MUTATION } from '../queries';
import { CreateShopifyOrderConfig, CreateShopifyOrderConfigVariables } from '../queries/types/CreateShopifyOrderConfig';

type IOptions = MutationHookOptions<CreateShopifyOrderConfig, CreateShopifyOrderConfigVariables>;

export function useCreateShopifyOrderConfig(options: IOptions = {}) {
  const [createShopifyOrderConfig, { loading, data, error }] = useMutation<
    CreateShopifyOrderConfig,
    CreateShopifyOrderConfigVariables
  >(CREATE_SHOPIFY_ORDER_CONFIG_MUTATION, options);

  return {
    createShopifyOrderConfig,
    isCreatingShopifyOrderConfig: loading,
    clientConfig: data?.createShopifyOrderConfig,
    error,
  };
}
