import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { ADD_CUSTOM_WORKLETS } from '../queries';
import {
  AddCustomWorkletsMutation,
  AddCustomWorkletsMutationVariables,
} from '../queries/types/AddCustomWorkletsMutation';

type IOptions = MutationHookOptions<
  AddCustomWorkletsMutation,
  AddCustomWorkletsMutationVariables
>;

export const useAddCustomWorkletsMutation = (options: IOptions = {}) => (
  useMutation<AddCustomWorkletsMutation, AddCustomWorkletsMutationVariables>(
    ADD_CUSTOM_WORKLETS,
    options,
  )
);
