import * as React from 'react';
import cx from 'classnames';

import { Popover, Select } from '@components';

import options, { optionsToSymbols } from './options';
import { SingleValue, ValueRange } from './SingleValuePicker';
import { monetaryReducer } from './monetaryReducer';

import styles from '../Filters.scss';

const { useRef, useState } = React;

export const productCostReducer = monetaryReducer(
  'PRODUCT_COST',
  'productCost',
);

const OptionsToComponent = (option, setFilters, values) => {
  if (
    option === 'greaterThan'
    || option === 'lessThan'
    || option === 'equalTo'
  ) {
    return (
      <SingleValue
        setFilters={setFilters}
        values={values}
        filterName="PRODUCT_COST"
      />
    );
  }
  if (option === 'isBetween') {
    return (
      <ValueRange
        setFilters={setFilters}
        values={values}
        filterName="PRODUCT_COST"
      />
    );
  }
};

export const ProductCostFilter = ({
 filters, setFilters, removeFilter, isDisabled,
}) => {
  const [open, setOpen] = useState(true);
  const productCostState = filters.productCost;
  const option = productCostState.option;
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={() => !isDisabled && setOpen(true)}
      >
        <span className={styles.filterCircle}>
          {optionsToSymbols[option.label]}
        </span>
        <span className={styles.filterText}>Product Cost</span>
        <span
          className={cx(styles.filterCircle, styles.right)}
          onClick={() => !isDisabled && removeFilter('productCost')}
        >
          ×
        </span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={() => setOpen(false)}
        className={styles.DateFilterPopover}
      >
        <Select
          hintText="Select Comparison..."
          options={options}
          selectedIndex={option.index}
          onChange={(option, index) =>
            setFilters({
              type: 'CHOOSE_PRODUCT_COST_FILTER_COMPARISON',
              payload: { label: option, index },
            })}
        />
        {OptionsToComponent(option.label, setFilters, productCostState)}
        {/* <Button fullWidth label="Apply" /> */}
      </Popover>
    </>
  );
};
