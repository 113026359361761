import { useQuery } from '@apollo/client';
import { GetTermsSettingsForProgramQuery, GetTermsSettingsForProgramQueryVariables } from '@frontend/app/queries/types/GetTermsSettingsForProgramQuery';
import { GET_TERMS_SETTINGS_FOR_PROGRAM } from '@frontend/app/queries/GetTermsSettingsForProgramQuery';

export function useGetTermsSettingsForProgram(options = {}) {
  return useQuery<GetTermsSettingsForProgramQuery, GetTermsSettingsForProgramQueryVariables>(
    GET_TERMS_SETTINGS_FOR_PROGRAM,
    options,
  );
}
