/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xbsy_157 {
  display: flex;
}

._justify-content-space-between_1xbsy_161 {
  justify-content: space-between;
}

._tabular-nums_1xbsy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xbsy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Wizard_1xbsy_178 ._title_1xbsy_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._Wizard_1xbsy_178 ._instructionTitle_1xbsy_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._Wizard_1xbsy_178 {
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
._Wizard_1xbsy_178 ._header_1xbsy_203 {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  height: 3.75rem;
  margin-top: 0.5rem;
}
._Wizard_1xbsy_178 ._instructionDescription_1xbsy_209 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  letter-spacing: -0.4px;
  margin-bottom: 2rem;
}
._Wizard_1xbsy_178 ._bodyNoPadding_1xbsy_218 {
  padding: 0rem;
}
._Wizard_1xbsy_178 ._body_1xbsy_218 {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}
._Wizard_1xbsy_178 ._bodyMarginBottom_1xbsy_225 {
  margin-bottom: 5.0625rem;
}
._Wizard_1xbsy_178 ._footer_1xbsy_228 {
  padding: 1.25rem 3.125rem;
  max-width: 100%;
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
}
._Wizard_1xbsy_178 .ant-divider-horizontal {
  margin-top: 0rem;
}