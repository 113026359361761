import React from 'react';
import { cn } from '@/shadcn/lib/utils';

export const LeftSidebarContent = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('overflow-y-auto z-[180] px-2 py-4 w-20 h-full bg-background border-r', className)}
      {...props}
    >
      {children}
    </div>
  ),
);
LeftSidebarContent.displayName = 'LeftSidebarContent';

export const LeftSidebar = React.forwardRef<
  HTMLBaseElement,
  React.ComponentPropsWithoutRef<'aside'> & { isOpen?: boolean }
>(({
 className, isOpen, children, ...props
}, ref) => {
  const sideNavTranslate = isOpen ? 'transform-none' : '-translate-x-full';
  return (
    <aside
      ref={ref}
      className={cn(
        'flex z-[190] fixed top-[3.5rem] sm:top-0 left-0 h-full transition-transform sm:translate-x-0',
        sideNavTranslate,
        className,
      )}
      {...props}
    >
      {children}
    </aside>
  );
});
LeftSidebar.displayName = 'LeftSidebar';

export const Navbar = React.forwardRef<HTMLBaseElement, React.ComponentPropsWithoutRef<'nav'>>(
  ({ className, children, ...props }, ref) => (
    <nav
      ref={ref}
      className={cn(
        'sm:hidden bg-background border-b px-[1.4rem] h-[57px] py-2.5 fixed left-0 right-0 top-0 z-[200]',
        className,
      )}
      {...props}
    >
      {children}
    </nav>
  ),
);
Navbar.displayName = 'Navbar';

export const SecondaryNav = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & { isOpen?: boolean }
>(({
 className, isOpen, children, ...props
}, ref) => {
  const sideNavTranslate = isOpen ? 'transform-none' : '-translate-x-full';
  return (
    <div
      ref={ref}
      className={cn(
        'overflow-y-auto absolute left-20 py-5 px-3 w-64 h-full bg-background border-r transition-transform top-0 -translate-x-full',
        sideNavTranslate,
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});
SecondaryNav.displayName = 'SecondaryNav';

export const MainContent = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'main'>>(
  ({ className, children, ...props }, ref) => (
    <main
      ref={ref}
      className={cn(
        'bg-background p-0 sm:ml-20 sm:mr-0 sm:pt-0 pt-[57px] h-screen flex-col flex overflow-y-scroll',
        className,
      )}
      {...props}
    >
      {children}
    </main>
  ),
);
MainContent.displayName = 'MainContent';

export const Layout = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn('bg-muted/40 antialiased shadcn', className)} {...props}>
      {children}
    </div>
  ),
);
Layout.displayName = 'Layout';
