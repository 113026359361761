import gql from 'graphql-tag';
import { OFFER_FRAGMENT } from './fragments/OfferFragment';

export const UPDATE_OFFER = gql`
  mutation UpdateOffer($id: Int!, $data: UpdateOfferInput!) {
    offer: updateOffer(id: $id, data: $data) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`;
