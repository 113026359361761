import * as React from 'react';
import {
  Avatar,
  Button, Drawer, Space,
} from '@revfluence/fresh';
import { MODES } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/OrderRequestSetup/components';
import { GiftIcon, XmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import styles from './ProjectConfigSetupDrawer.styles.scss';

interface IProps {
  children: React.ReactNode;
  onSubmit: () => void;
  visible: boolean;
  onOpen: () => void;
  mode: MODES;
  onClose: () => void;
  isSaveFormEnabled: boolean;
}

export const ProjectConfigSetupDrawer: React.FC<Readonly<IProps>> = (props: IProps) => {
  const {
    children,
    onSubmit,
    visible,
    onOpen,
    mode,
    onClose,
    isSaveFormEnabled,
  } = props;
  const closeButton = <Button icon={<XmarkIcon />} onClick={onClose} type="text" />;
  const formAction = mode === MODES.EDIT ? 'Edit' : 'Set Up';

  return (
    <>
      <Button type="primary" onClick={onOpen}>
        {formAction}
        {' '}
        Form
      </Button>
      <Drawer visible={visible} bodyStyle={{ padding: 0 }} closable={false} width={1024} className={styles.Wizard}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.headerContentWrapper} />
            <div className={styles.headerContentWrapper}>
              <Avatar icon={<GiftIcon />} className={styles.headerIcon} style={{ backgroundColor: '#09375A' }} />
              {formAction}
              {' '}
              Order Form
            </div>
            <div className={styles.headerContentWrapper}>{closeButton}</div>
          </div>
          <div className={styles.mainContentWrapper}>
            {children}
          </div>

          <div className={styles.footer}>
            <Space direction="horizontal" align="center" size="small">
              <Button className={styles.footerButton} type="default" onClick={onClose}>
                Cancel
              </Button>
              <Button className={styles.footerButton} type="primary" onClick={onSubmit} disabled={!isSaveFormEnabled}>
                Save Form
              </Button>
            </Space>
          </div>
        </div>
      </Drawer>
    </>
  );
};
