import * as React from 'react';
import {
  Button, Col, Row, Input, Typography, Space, Alert,
} from '@revfluence/fresh';
import { isNil } from 'lodash';
import { assign } from 'lodash';

import { useSendMessage } from '@frontend/app/hooks';
import {
  GetThreadQuery_thread as IThread,
  GetThreadQuery_thread_messages as IThreadMessages,
} from '@frontend/app/queries/types/GetThreadQuery';
import {
  SendMessageInput,
} from '@frontend/app/types/globalTypes';

import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';

import { CloseIcon } from '@components';
import styles from './InstagramReplyComposer.scss';
import { InstagramReplyItem } from '../../model';

interface IProps {
  thread: IThread;
  recepient?: string;
  sender?: string;
  resourceId: number;
  instagramReplyItem?: InstagramReplyItem
  clearReplyItem(): void;
  onMessageSent: (result: IThreadMessages) => void;
  composerErrorMessage: string;
  isInvalidIGDMThread: boolean;
  bottomRef: React.MutableRefObject<HTMLDivElement>;
}

const {
  useState,
  useCallback,
  useMemo,
} = React;

const { Text } = Typography;
const { TextArea } = Input;

const DEFAULT_TEXTAREA_ROWCOUNT = 10;
const MAX_COMPUTED_CHARACTERS_LENGTH = 1000;
const ERROR_MESSAGE_DELAY_IN_MS = 5000; // 5 seconds

export const InstagramReplyComposer: React.FC<IProps> = React.memo(({
  thread,
  resourceId,
  instagramReplyItem,
  clearReplyItem,
  onMessageSent,
  composerErrorMessage,
  isInvalidIGDMThread,
  bottomRef,
}) => {
  const [text, setText] = useState<string>('');
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);

  const getMembersProp = useCallback((thread) => (
    thread.members.map((member) => ({
      id: member.id,
      email: member.email,
      fields: member.fields,
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [thread]);

  const parsedText = useMemo(() => text.replace(/\n/g, '\\n'), [text]);
  const displayErrorMessage = useCallback(() => {
    setIsErrorVisible(true);
    setTimeout(() => setIsErrorVisible(false), ERROR_MESSAGE_DELAY_IN_MS);
  }, []);

  const { sendMessage, loading: sendingMessage } = useSendMessage({
    onCompleted: async (result) => {
      const newMessage: IThreadMessages = assign(
        result.message,
        { members: getMembersProp(thread) },
      );
      onMessageSent(newMessage);
      setText('');
    },
    onError: () => displayErrorMessage(),
  });

  const onSendMessage = async () => {
    const params: SendMessageInput = {
      threadId: thread.id,
      type: thread.type,
      message: parsedText,
      subject: '',
      resourceId,
      members: getMembersProp(thread),
    };
    sendMessage({
      variables: {
        message: params,
      },
    });
  };

  return (
    <div
      className={styles.replyComponentWrapper}
      ref={bottomRef}
    >
      <Row className={styles.header}>
        <Col>
          {buildHeaderText(instagramReplyItem)}
        </Col>
        {instagramReplyItem && (
          <Col>
            <CloseIcon
              onClick={clearReplyItem}
              size={12}
            />
          </Col>
        )}
      </Row>
      <Row>
        <TextArea
          placeholder="Message..."
          bordered={false}
          value={text}
          onChange={(event) => setText(event.target.value)}
          rows={DEFAULT_TEXTAREA_ROWCOUNT}
          className={styles.textArea}
          disabled={sendingMessage || isInvalidIGDMThread}
        />
      </Row>
      <Row
        className={styles.footer}
        justify="space-between"
        align="middle"
      >
        <Text type="secondary">
          {!isInvalidIGDMThread && `Character limit: ${parsedText.length}/${MAX_COMPUTED_CHARACTERS_LENGTH}`}
          {isInvalidIGDMThread && (
            <Alert
              message={composerErrorMessage}
              type="error"
              action={composerErrorMessage.includes('direct messaging')
                ? (
                  <Button
                    href={`../settings/${SOCIAL_POST_APP_ID}`}
                    type="ghost"
                  >
                    Settings
                  </Button>
                ) : null}
            />
          )}
        </Text>
        {isErrorVisible && <Alert message="Message failed to send" type="error" />}
        <Button
          type="primary"
          disabled={
            text.length === 0
            || (parsedText.length > MAX_COMPUTED_CHARACTERS_LENGTH)
            || isInvalidIGDMThread
          }
          loading={sendingMessage}
          onClick={onSendMessage}
        >
          Send
        </Button>
      </Row>
    </div>
  );
});

const buildHeaderText = (instagramReplyItem?: InstagramReplyItem) => {
  if (isNil(instagramReplyItem)) {
    return (
      <Text
        type="secondary"
        className={styles.headerText}
      >
        Message
      </Text>
    );
  }

  let description = '';
  const title = `Replying to ${instagramReplyItem.threadMessage.payload.from !== instagramReplyItem.audienceHandler ? 'yourself' : `@${instagramReplyItem.threadMessage.payload.from}`}:`;

  if (isNil(instagramReplyItem.threadMessage.payload.story)) {
    // Replying a message
    description = instagramReplyItem.threadMessage.payload.textHtml ?? instagramReplyItem.threadMessage.payload.textPlain;
  } else {
    // Replying to a story
    description = `${instagramReplyItem.threadMessage.payload.from !== instagramReplyItem.audienceHandler
      ? `You mentioned @${instagramReplyItem.threadMessage.payload.to[0]} in your story`
      : `@${instagramReplyItem.threadMessage.payload.from} mentioned you in their story`}`;
  }

  return (
    <Space direction="horizontal">
      <Text type="secondary" className={styles.headerText}>
        {title}
      </Text>
      <Text type="secondary" className={styles.headerDetails}>
        {description}
      </Text>
    </Space>
  );
};
