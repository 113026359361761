import * as React from 'react';
import Fuse from 'fuse.js';

const { useMemo } = React;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const useFuzzySearchByKeys = <T = any>(objects: T[], keys: string[]) =>
  useMemo(() => {
    const options = {
      shouldSort: true,
      threshold: 0.2,
      location: 0,
      distance: 300,
      keys,
    };

    const fuse = new Fuse(objects, options);

    return (text: string) => {
      if (text) {
        return fuse.search(text);
      } else {
        return objects;
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects]);
