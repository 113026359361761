import {
  isEmpty,
  join, map, reduce, some,
} from 'lodash';

import {
  IAddPromoCodeMembers, TAddMembersProps, TMember, TSelectedMember,
} from '@affiliates/components/MembersWizard/types';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { generateCodeForMember, markDuplicates, sortMembers } from '../../../../utils';
import { TState } from '../types';

interface IAction {
  codeGenerationStrategy?: IAddPromoCodeMembers['codeGenerationStrategy'];
  members: readonly TMember[];
  promoCodeSuffix?: IAddPromoCodeMembers['promoCodeSuffix'];
  promoCodePrefix?: IAddPromoCodeMembers['promoCodePrefix'];
  type: 'POPULATE MEMBERS';
  offerSource: TAddMembersProps['offerSource'];
  defaultPayoutId: number;
  isWorkflow: boolean;
}
export type TPopulateMembersAction = Readonly<IAction>;

export const populateMembers = (state: TState, action: TPopulateMembersAction): TState => {
  const isShopify = action.offerSource === OFFER_SOURCE.SHOPIFY;
  const members = markDuplicates(map(action.members, (member): TSelectedMember => {
    const selectedPrograms = join(
      reduce(member.programIds, (prev, next) => {
        const idStr = next.toString();
        if (idStr in state.selectedPrograms) {
          prev.push(state.selectedPrograms[idStr].name);
        }
        return prev;
      }, [] as string[]).sort(),
      ', ',
    );
    return {
      ...member,
      code: generateCodeForMember(
        member,
        action.codeGenerationStrategy,
        action.promoCodeSuffix || '',
        action.promoCodePrefix || '',
      ),
      isDuplicate: false, // handle duplicate checks in the markDuplicates call
      selected: action.isWorkflow && !member.forceCheckIn,
      selectedPrograms,
      payoutId: action.defaultPayoutId,
      startDate: null,
      endDate: null,
    };
  }).sort(sortMembers));

  let totalMembers = [];
  if (state.members?.length) {
    totalMembers = [...state.members];
  }
  map(members, (newMember) => {
    if (!totalMembers.find((existingMember) => existingMember.id === newMember.id)) {
      totalMembers.push(newMember);
    }
  });
  return {
    ...state,
    members: totalMembers.sort(sortMembers),
    selectedMembers: action.isWorkflow ? members.filter((member) => member.selected && !member.forceCheckIn) : state.selectedMembers,
    showNoMembersWarning: !action.isWorkflow ? isShopify && !isEmpty(members) && !some(members, (m) => m.code.length > 0) : false,
  };
};
