import { first, isEmpty } from 'lodash';

import { TActiveDates, TMember } from '../types';
import { mapMemberToActiveDates } from './mapMemberToActiveDates';

const DEFAULT_FALLBACK: TActiveDates = {
  endTime: '00:00:00',
  startTime: '00:00:00',
};

export const getActiveDatesFromMembers = (
  members: readonly TMember[],
): TActiveDates => {
  if (isEmpty(members)) {
    return DEFAULT_FALLBACK;
  }

  let toReturn = mapMemberToActiveDates(first(members));

  for (let i = 1; i < members.length; i++) {
    const nextActiveDates = mapMemberToActiveDates(members[i]);
    if (
      'startDate' in toReturn && (
        toReturn.startDate !== nextActiveDates.startDate
        || toReturn.startTime !== nextActiveDates.startTime
      )
    ) {
      if (!('endDate' in toReturn)) {
        return DEFAULT_FALLBACK;
      }
      toReturn = {
        endDate: toReturn.endDate,
        endTime: toReturn.endTime,
      };
    }
    if (
      'endDate' in toReturn && (
        toReturn.endDate !== nextActiveDates.endDate
        || toReturn.endTime !== nextActiveDates.endTime
      )
    ) {
      if (!('startDate' in toReturn)) {
        return DEFAULT_FALLBACK;
      }
      toReturn = {
        startDate: toReturn.startDate,
        startTime: toReturn.startTime,
      };
    }
  }

  return {
    ...DEFAULT_FALLBACK,
    ...toReturn,
  };
};
