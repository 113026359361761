/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1y5de_157 {
  display: flex;
}

._justify-content-space-between_1y5de_161 {
  justify-content: space-between;
}

._tabular-nums_1y5de_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1y5de_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TagActions_1y5de_178 ._Icon_1y5de_178 {
  transform: translateY(0.125rem);
  color: #2e426d;
}
._TagActions_1y5de_178 ._Icon_1y5de_178._Active_1y5de_182 {
  color: #3996e0;
}

._DeleteModal_1y5de_186 {
  z-index: 10010;
}

._Popover_1y5de_190 {
  z-index: 10000;
}
._Popover_1y5de_190 ._FormAction_1y5de_193 {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(26, 24, 24, 0.12);
  padding: 1rem;
}
._Popover_1y5de_190 ._FormAction_1y5de_193 ._FormActionTitle_1y5de_199 {
  font-size: 0.875rem;
  font-weight: 600;
  color: #1a1818;
  text-align: center;
}
._Popover_1y5de_190 ._FormAction_1y5de_193 ._FormActionInput_1y5de_205 {
  background-color: #ffffff;
  margin: 1rem 0;
}
._Popover_1y5de_190 ._FormAction_1y5de_193 ._FormActionInput_1y5de_205:focus {
  border-color: #3996e0;
}
._Popover_1y5de_190 ._FormAction_1y5de_193 ._FormActionInput_1y5de_205._hasError_1y5de_212 {
  border-color: #d9534f;
}
._Popover_1y5de_190 ._FormAction_1y5de_193 ._FormActionButton_1y5de_215 {
  width: 100%;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  line-height: 0.875rem;
  border: 0;
  border-radius: 0.25rem;
  color: #ffffff;
  background-color: #3996e0;
  box-shadow: none;
}
._Popover_1y5de_190 ._Menu_1y5de_226 {
  padding: 0;
}
._Popover_1y5de_190 ._Menu_1y5de_226 ._MenuItem_1y5de_229 {
  display: flex;
  align-content: center;
  min-width: 10rem;
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.625rem 1rem;
  cursor: pointer;
}
._Popover_1y5de_190 ._Menu_1y5de_226 ._MenuItem_1y5de_229 ._MenuItemIcon_1y5de_238 {
  margin-right: 0.375rem;
}
._Popover_1y5de_190 ._Menu_1y5de_226 ._MenuItem_1y5de_229._Delete_1y5de_186 {
  color: #F1515F;
}