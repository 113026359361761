import { useQuery, QueryHookOptions } from '@apollo/client';

import {
  MemberCountForQuickFilters,
  MemberCountForQuickFiltersVariables,
} from '@frontend/app/queries/types/MemberCountForQuickFilters';
import { MEMBER_SEARCH_QUICK_FILTERS_COUNTS_QUERY } from '../queries/SearchQuickFiltersCountsQuery';

type IOptions = QueryHookOptions<MemberCountForQuickFilters, MemberCountForQuickFiltersVariables>;

export const useMemberQuickFiltersCount = (options: IOptions = {}) => useQuery<MemberCountForQuickFilters, MemberCountForQuickFiltersVariables>(
  MEMBER_SEARCH_QUICK_FILTERS_COUNTS_QUERY,
  options,
);
