import * as React from 'react';
import cx from 'classnames';
import { ResizableBox } from 'react-resizable';
import { isFunction, isNumber } from 'lodash';

import { ICellProps } from './Cell';
import { TableContext } from '../tableContext';
import { calculateCellWidth } from '../utils';
import { MIN_COLUMN_WIDTH, MAX_COLUMN_WIDTH } from '../utils/constants';

const { useContext } = React;
import styles from './ResizableCell.scss';

interface IProps extends Pick<ICellProps, 'className' | 'config'> {
  // Calculated by the row (defaults to row width)
  height?: number;
  // To grab the headerHeight table config
  isHeaderCell?: boolean;
  onResizeStop?: () => void;
}

/**
 * Reorderable cell -- only used as a wrapper for a Cell-type component
 */
export const ResizableCell: React.FC<IProps> = React.memo((props) => {
  const {
    children,
    className,
    config,
    height: heightProp,
    isHeaderCell,
    onResizeStop,
  } = props;
  const {
    config: tableConfig,
    setColumnWidth,
    columnWidths,
  } = useContext(TableContext);

  const columnWidth = columnWidths[config.field];
  const width = isNumber(columnWidth) ? columnWidth : calculateCellWidth({
    width: config.width,
    minWidth: config.minWidth,
    maxWidth: config.maxWidth,
    setWidth: config.setWidth,
  });
  const height = (() => {
    if (isNumber(heightProp)) {
      return heightProp;
    }
    if (isHeaderCell) {
      return tableConfig.headerHeight || tableConfig.rowHeight;
    }
    return tableConfig.rowHeight;
  })();

  return (
    <ResizableBox
      key={config.field}
      className={cx(styles.ResizableCell, className)}
      handle={() => <button className={styles.handle} />}
      axis="x"
      resizeHandles={['e']}
      width={width}
      height={height}
      minConstraints={[
        isNumber(config.minWidth) ? config.minWidth : MIN_COLUMN_WIDTH,
        height,
      ]}
      maxConstraints={[
        isNumber(config.maxWidth) ? config.maxWidth : MAX_COLUMN_WIDTH,
        height,
      ]}
      onResize={(_, data) => setColumnWidth(config.field, data.size.width)}
      onResizeStop={() => {
        if (isFunction(onResizeStop)) {
          onResizeStop();
        }
      }}
    >
      {children}
    </ResizableBox>
  );
});

ResizableCell.displayName = 'ResizableCell';
