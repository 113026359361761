import { MutationHookOptions, useMutation } from '@apollo/client';
import { DELETE_SELECTION_CRITERIA_BY_ID } from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import { DeleteCatalogSelectionRule, DeleteCatalogSelectionRuleVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/DeleteCatalogSelectionRule';

type IOptions = MutationHookOptions<DeleteCatalogSelectionRule, DeleteCatalogSelectionRuleVariables>;

export const useDeleteSelectionCriteria = (options: IOptions = {}) => {
  const [deleteSelectionCriteria, { loading, data, error }] = useMutation(DELETE_SELECTION_CRITERIA_BY_ID, options);

  return {
    loading,
    selectionCriteria: data?.deleteCatalogSelectionRule,
    error,
    deleteSelectionCriteria,
  };
};
