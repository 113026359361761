._GridView_195dw_1 {
  position: relative;
  overflow-x: hidden;
}
._GridView_195dw_1 ._loadSpinner_195dw_5 {
  margin-top: 20px;
}
._GridView_195dw_1 ._noContents_195dw_8 {
  margin: 20px auto;
  width: 650px;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
}
._GridView_195dw_1 ._noContents_195dw_8 ._title_195dw_16 {
  margin-bottom: 10px;
  font-weight: 600;
}