import * as React from 'react';

import { IProps as ConversionTrackingPaneProps, ConversionTrackingPane } from '@affiliates/components/ConversionTrackingPane/ConversionTrackingPane';
import { IOfferDetailsProps } from '@affiliates/components/OfferSummaryCard/types';
import { OfferSummarySection } from './OfferSummarySection';

import styles from './OfferConfirmation.scss';
import { OFFER_TRACKING_TYPE } from '../../types/globalTypes';

interface IProps {
  conversionTrackingProps: ConversionTrackingPaneProps;
  onRemoveOffer: () => void;
  offerDetails: IOfferDetailsProps;
  onChangeOffer: () => void;
  canMigrateProgram?: boolean;
}
const CONVERSION_TRACKING_TYPES = [
  OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK,
  OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID,
  OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID,
];

export const OfferLinksConfirmation = (props: IProps) => {
  const {
    conversionTrackingProps,
    canMigrateProgram,
    offerDetails,
    onRemoveOffer,
    onChangeOffer,
  } = props;

  const text = CONVERSION_TRACKING_TYPES.includes(conversionTrackingProps.offerTrackingType)
    ? 'add conversion tracking to your site'
    : 'follow the instructions below to add your pixel';

  return (
    <div className={styles.OfferConfirmation}>
      <div className={styles.testingSection}>
        <div className={styles.pixelCodeSection}>
          <h3>
            Nice! Now that your offer is created,
            {' '}
            {text}
            .
          </h3>
          <ConversionTrackingPane {...conversionTrackingProps} parentStyle={styles.ConversionTrackingPane} />
        </div>
        <div className={styles.summaryCard}>
          <OfferSummarySection
            offerDetails={offerDetails}
            onChangeOffer={onChangeOffer}
            onRemoveOffer={onRemoveOffer}
            canMigrateProgram={canMigrateProgram}
          />
        </div>
      </div>
    </div>
  );
};
