import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const EmailComposerIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="10 10 16 16"
  >
    <path d="M11 14.75C11 13.793 11.7656 13 12.75 13H23.25C24.207 13 25 13.793 25 14.75V21.75C25 22.7344 24.207 23.5 23.25 23.5H12.75C11.7656 23.5 11 22.7344 11 21.75V14.75ZM12.3125 14.75V15.3789L17.0156 19.2344C17.5898 19.6992 18.3828 19.6992 18.957 19.2344L23.6875 15.3789V14.75C23.6875 14.5312 23.4688 14.3125 23.25 14.3125H12.75C12.5039 14.3125 12.3125 14.5312 12.3125 14.75ZM12.3125 17.0742V21.75C12.3125 21.9961 12.5039 22.1875 12.75 22.1875H23.25C23.4688 22.1875 23.6875 21.9961 23.6875 21.75V17.0742L19.8047 20.2461C18.7383 21.1211 17.2344 21.1211 16.168 20.2461L12.3125 17.0742Z" />
  </SvgIcon>
  ));

EmailComposerIcon.displayName = 'EmailComposerIcon';
