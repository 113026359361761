import * as React from 'react';
import cx from 'classnames';
import {
 Typography, Button, Input, List,
} from '@revfluence/fresh';
import { useOutsideClick } from '@frontend/app/hooks/common';
import { moveCaretAtEnd } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import Linkify from 'react-linkify';

import styles from './PostInstruction.scss';

interface IProps {
  instruction: string;
  placeholder: string;
  guidelineIndex: number;
  instructionIndex: number;
  isEditing: boolean;
  onToggleEditing: (value: boolean, guidelineIndex: number, instructionIndex: number) => void;
  onUpdateInstruction: (value: string, guidelineIndex: number, instructionIndex: number) => void;
}
const { useState, useCallback, useRef } = React;

const { Text } = Typography;

const InstructionLink = ({ key, href, text }) => (
  <Button type="link" key={key} className="linkify-component" onClick={() => window.open(href, '_blank')}>
    <span className={styles.link}>{text}</span>
  </Button>
);

const PostInstruction: React.FC<IProps> = React.memo((props) => {
  const {
    instruction,
    placeholder,
    guidelineIndex,
    instructionIndex,
    isEditing,
    onUpdateInstruction,
    onToggleEditing,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const [value, setValue] = useState(instruction);

  const handleClickOutside = useCallback(() => {
    if (value !== instruction) {
      onToggleEditing(false, guidelineIndex, instructionIndex);
      onUpdateInstruction(value, guidelineIndex, instructionIndex);
    }
  }, [instruction, value, guidelineIndex, instructionIndex, onToggleEditing, onUpdateInstruction]);

  useOutsideClick(ref, handleClickOutside);

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      const text = e.clipboardData.getData('text');
      const textarea = e.target as HTMLTextAreaElement;
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      const beforePaste = value.slice(0, startPos);
      const afterPaste = value.slice(endPos);

      const updatedValue = beforePaste + text + afterPaste;

      onToggleEditing(false, guidelineIndex, instructionIndex);
      setValue(updatedValue);
      onUpdateInstruction(updatedValue, guidelineIndex, instructionIndex);
    },
    [value, onUpdateInstruction, guidelineIndex, instructionIndex, onToggleEditing],
  );

  if (!isEditing) {
    return (
      <List
        size="small"
        dataSource={[value || placeholder]}
        renderItem={(item: string) => (
          <List.Item
            className={cx(styles.Item, {
              [styles.highlighting]: !isEditing && value !== '',
              [styles.editing]: isEditing,
            })}
            onClick={() => onToggleEditing(true, guidelineIndex, instructionIndex)}
          >
            <Linkify
              componentDecorator={(href: string, text: string, key: string) => (
                <InstructionLink href={href} text={text} key={key} />
              )}
            >
              {value || <Text type="secondary">{item}</Text>}
            </Linkify>
          </List.Item>
        )}
      />
    );
  }

  return (
    <div ref={ref}>
      <Input.TextArea
        autoFocus
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        onFocus={moveCaretAtEnd}
        showCount
        autoSize
        maxLength={500}
        placeholder={placeholder}
        onChange={(event) => setValue(event.target.value)}
        value={value}
        onPaste={handlePaste}
        onPressEnter={() => {
          onToggleEditing(false, guidelineIndex, instructionIndex);
          onUpdateInstruction(value, guidelineIndex, instructionIndex);
        }}
        onBlur={() => {
          onToggleEditing(false, guidelineIndex, instructionIndex);
          onUpdateInstruction(value, guidelineIndex, instructionIndex);
        }}
        style={{ resize: 'vertical' }}
      />
    </div>
  );
});

PostInstruction.displayName = 'PostInstruction';

export default PostInstruction;
