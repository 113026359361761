/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_wje4h_157 {
  display: flex;
}

._justify-content-space-between_wje4h_161 {
  justify-content: space-between;
}

._tabular-nums_wje4h_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_wje4h_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DeeplinkDrawer_wje4h_178 ._deeplinkDrawerTitle_wje4h_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._DeeplinkDrawer_wje4h_178 .ant-drawer-content {
  background-color: white;
}