/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lke2w_157 {
  display: flex;
}

._justify-content-space-between_lke2w_161 {
  justify-content: space-between;
}

._tabular-nums_lke2w_165, ._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._Card_lke2w_170 ::selection, ._Content_lke2w_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_lke2w_170 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_lke2w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Card_lke2w_170, ._Content_lke2w_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Card_lke2w_170 ::-webkit-scrollbar, ._Content_lke2w_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Card_lke2w_170 ::-webkit-scrollbar-track, ._Content_lke2w_170 ::-webkit-scrollbar-track, ._Card_lke2w_170 ::-webkit-scrollbar-track-piece, ._Content_lke2w_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Card_lke2w_170 ::-webkit-scrollbar-thumb, ._Content_lke2w_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Placeholder_lke2w_209, ._Content_lke2w_170 {
  height: 42.5rem;
  position: relative;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

._Placeholder_lke2w_209, ._Content_lke2w_170 ._summary_lke2w_216,
._Content_lke2w_170 ._analyze_lke2w_217 {
  height: 100%;
  display: grid;
  grid-gap: 1rem;
  animation: _show_lke2w_1 0.4s;
}

._Content_lke2w_170._blog_lke2w_224 ._summary_lke2w_216, ._Content_lke2w_170._facebook_lke2w_224 ._summary_lke2w_216, ._Content_lke2w_170._twitter_lke2w_224 ._summary_lke2w_216 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
._Content_lke2w_170._blog_lke2w_224 ._summary_lke2w_216 ._ProfileCard_lke2w_228, ._Content_lke2w_170._facebook_lke2w_224 ._summary_lke2w_216 ._ProfileCard_lke2w_228, ._Content_lke2w_170._twitter_lke2w_224 ._summary_lke2w_216 ._ProfileCard_lke2w_228 {
  grid-column: 1;
  grid-row: 1/6 span;
}
._Content_lke2w_170._blog_lke2w_224 ._summary_lke2w_216 ._SummaryCard_lke2w_232, ._Content_lke2w_170._facebook_lke2w_224 ._summary_lke2w_216 ._SummaryCard_lke2w_232, ._Content_lke2w_170._twitter_lke2w_224 ._summary_lke2w_216 ._SummaryCard_lke2w_232 {
  grid-column: 2;
}
._Content_lke2w_170._blog_lke2w_224 ._summary_lke2w_216 ._SummaryCard_lke2w_232, ._Content_lke2w_170._facebook_lke2w_224 ._summary_lke2w_216 ._SummaryCard_lke2w_232 {
  grid-row: 1/4;
}
._Content_lke2w_170._twitter_lke2w_224 ._summary_lke2w_216 ._SummaryCard_lke2w_232 {
  grid-row: 1/3;
}
._Content_lke2w_170._instagram_lke2w_241 ._summary_lke2w_216 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
._Content_lke2w_170._instagram_lke2w_241 ._summary_lke2w_216 ._ProfileCard_lke2w_228 {
  grid-column: 1;
  grid-row: 1/2 span;
}
._Content_lke2w_170._instagram_lke2w_241 ._summary_lke2w_216 ._EngagementRateCard_lke2w_249 {
  grid-column: 2;
  grid-row: 1;
}
._Content_lke2w_170._instagram_lke2w_241 ._summary_lke2w_216 ._SummaryCard_lke2w_232 {
  grid-column: 2;
  grid-row: 2;
}
._Content_lke2w_170._instagram_lke2w_241 ._analyze_lke2w_217 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(9, minmax(0, 1fr));
}
._Content_lke2w_170._instagram_lke2w_241 ._analyze_lke2w_217 ._GenderCard_lke2w_261 {
  grid-column: 1;
  grid-row: 1/4 span;
}
._Content_lke2w_170._instagram_lke2w_241 ._analyze_lke2w_217 ._AgeCard_lke2w_265 {
  grid-column: 1;
  grid-row: 5/5 span;
}
._Content_lke2w_170._instagram_lke2w_241 ._analyze_lke2w_217 ._CountryCard_lke2w_269 {
  grid-column: 2;
  grid-row: 1/3 span;
}
._Content_lke2w_170._instagram_lke2w_241 ._analyze_lke2w_217 ._CityCard_lke2w_273 {
  grid-column: 2;
  grid-row: 4/3 span;
}
._Content_lke2w_170._instagram_lke2w_241 ._analyze_lke2w_217 ._PostPerformanceCard_lke2w_277 {
  grid-column: 3;
  grid-row: 1/6 span;
}
._Content_lke2w_170._instagram_lke2w_241 ._analyze_lke2w_217 ._GrowthCard_lke2w_281 {
  grid-column: 2/2 span;
  grid-row: 7/3 span;
}
._Content_lke2w_170._pinterest_lke2w_285 ._summary_lke2w_216 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(8, 1fr);
}
._Content_lke2w_170._pinterest_lke2w_285 ._summary_lke2w_216 ._ProfileCard_lke2w_228 {
  grid-column: 1;
  grid-row: 1/8 span;
}
._Content_lke2w_170._pinterest_lke2w_285 ._summary_lke2w_216 ._SummaryCard_lke2w_232 {
  grid-column: 2;
  grid-row: 1/3 span;
}
._Content_lke2w_170._pinterest_lke2w_285 ._summary_lke2w_216 ._PostPerformanceCard_lke2w_277 {
  grid-column: 2;
  grid-row: 4/5 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(9, 1fr);
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._ProfileCard_lke2w_228 {
  grid-column: 1/2 span;
  grid-row: 1/9 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._PostPerformanceCard_lke2w_277 {
  grid-column: 3/2 span;
  grid-row: 1/5 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._PostPerformanceCard_lke2w_277 ._detailList_lke2w_313 {
  margin: 0;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._HashtagsCard_lke2w_316 {
  grid-column: 3/2 span;
  grid-row: 6/1 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._TopCategoriesCard_lke2w_320 {
  grid-column: 3;
  grid-row: 7/3 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._TopPlaylistsCard_lke2w_324 {
  grid-column: 4;
  grid-row: 7/3 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._TopPlaylistsCard_lke2w_324 a {
  color: #3996e0;
  text-decoration: none;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._TopCategoriesCard_lke2w_320 ._items_lke2w_332,
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._TopPlaylistsCard_lke2w_324 ._items_lke2w_332 {
  overflow: hidden;
}
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._TopCategoriesCard_lke2w_320 ._items_lke2w_332 ._item_lke2w_332,
._Content_lke2w_170._youtube_lke2w_301 ._summary_lke2w_216 ._TopPlaylistsCard_lke2w_324 ._items_lke2w_332 ._item_lke2w_332 {
  margin-bottom: 0.375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._Content_lke2w_170._youtube_lke2w_301 ._analyze_lke2w_217 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, 1fr);
}
._Content_lke2w_170._youtube_lke2w_301 ._analyze_lke2w_217 ._GenderCard_lke2w_261 {
  grid-column: 1;
  grid-row: 1/1 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._analyze_lke2w_217 ._AgeCard_lke2w_265 {
  grid-column: 1;
  grid-row: 2/1 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._analyze_lke2w_217 ._CountryCard_lke2w_269 {
  grid-column: 2;
  grid-row: 1/1 span;
}
._Content_lke2w_170._youtube_lke2w_301 ._analyze_lke2w_217 ._StateCard_lke2w_359 {
  grid-column: 2;
  grid-row: 2/1 span;
}
._Content_lke2w_170._tiktok_lke2w_363 ._summary_lke2w_216 {
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
}
._Content_lke2w_170._tiktok_lke2w_363 ._summary_lke2w_216 ._ProfileCard_lke2w_228 {
  grid-column: 1;
  grid-row: 1/3 span;
}
._Content_lke2w_170._tiktok_lke2w_363 ._summary_lke2w_216 ._SummaryCard_lke2w_232 {
  grid-column: 2/4;
  grid-row: 1;
}
._Content_lke2w_170._tiktok_lke2w_363 ._summary_lke2w_216 ._GenderCard_lke2w_261 {
  grid-column: 2;
  grid-row: 2;
}
._Content_lke2w_170._tiktok_lke2w_363 ._summary_lke2w_216 ._AgeCard_lke2w_265 {
  grid-column: 3;
  grid-row: 2;
}
._Content_lke2w_170._tiktok_lke2w_363 ._summary_lke2w_216 ._CountryCard_lke2w_269 {
  grid-column: 2;
  grid-row: 3;
}
._Content_lke2w_170._tiktok_lke2w_363 ._summary_lke2w_216 ._StateCard_lke2w_359 {
  grid-column: 3;
  grid-row: 3;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lke2w_157 {
  display: flex;
}

._justify-content-space-between_lke2w_161 {
  justify-content: space-between;
}

._tabular-nums_lke2w_165, ._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._Card_lke2w_170 ::selection, ._Content_lke2w_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_lke2w_170 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_lke2w_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Card_lke2w_170, ._Content_lke2w_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Card_lke2w_170 ::-webkit-scrollbar, ._Content_lke2w_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Card_lke2w_170 ::-webkit-scrollbar-track, ._Content_lke2w_170 ::-webkit-scrollbar-track, ._Card_lke2w_170 ::-webkit-scrollbar-track-piece, ._Content_lke2w_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Card_lke2w_170 ::-webkit-scrollbar-thumb, ._Content_lke2w_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_lke2w_165,
._audienceAgeBarChart_lke2w_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_lke2w_165 > [class*=list],
._audienceAgeBarChart_lke2w_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_lke2w_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_lke2w_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_lke2w_697 {
  cursor: help;
}

._Card_lke2w_170 {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  break-inside: avoid-column;
  box-sizing: border-box;
}
._Card_lke2w_170 ._cardHeader_lke2w_711 {
  margin-top: -0.5rem;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._Card_lke2w_170 ._cardHeader_lke2w_711:empty {
  display: none;
  margin: unset;
}
._Card_lke2w_170 ._tabs_lke2w_722 {
  margin-right: -0.5rem;
  align-self: flex-end;
}
._Card_lke2w_170 ._tabs_lke2w_722 ._tabButton_lke2w_726 {
  min-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  letter-spacing: 0.0625rem;
}
._Card_lke2w_170 ._tabs_lke2w_722 ._tabButton_lke2w_726, ._Card_lke2w_170 ._tabs_lke2w_722 ._tabButton_lke2w_726:hover {
  background-color: transparent;
}
._Card_lke2w_170 ._tabs_lke2w_722 ._tabButton_lke2w_726:hover > div {
  color: #3996e0;
}
._Card_lke2w_170 ._tabs_lke2w_722 ._tabButton_lke2w_726 > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aeaeae;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Card_lke2w_170 ._tabs_lke2w_722 ._tabButton_lke2w_726._activeTab_lke2w_745 > div {
  color: #3996e0;
}

._separator_lke2w_749 {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  height: 0.0625rem;
  border-bottom: 0.0625rem solid #eff5f9;
}

._cardTab_lke2w_756 {
  display: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._cardTitle_lke2w_764 {
  margin: 0 auto 0 0;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

._visible_lke2w_772 {
  display: flex !important;
  animation: _show_lke2w_1 0.4s;
}

._noData_lke2w_777::after {
  position: absolute;
  top: calc(50% + 0.6666666667rem);
  left: 50%;
  display: block;
  color: #8f8d91;
  content: "No data yet";
  transform: translate(-50%, -50%);
}

._Placeholder_lke2w_209 ._title_lke2w_787, ._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._posts_lke2w_787 ._post_lke2w_787, ._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 ._details_lke2w_787 ._name2_lke2w_787, ._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 ._details_lke2w_787 ._name_lke2w_787, ._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 ._image_lke2w_787 {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
}

._Placeholder_lke2w_209 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 {
  grid-column: 1;
  grid-row: 1/2 span;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 {
  height: 4rem;
  display: flex;
  flex-direction: row;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 ._image_lke2w_787 {
  width: 4rem;
  height: 4rem;
  border-radius: 624.9375rem;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 ._details_lke2w_787 {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 ._details_lke2w_787 ._name_lke2w_787 {
  margin-bottom: 0.5rem;
  width: 15rem;
  height: 1rem;
  display: inline-block;
  position: relative;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._accountInfo_lke2w_787 ._details_lke2w_787 ._name2_lke2w_787 {
  width: 10rem;
  height: 1rem;
  display: inline-block;
  position: relative;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._posts_lke2w_787 {
  display: grid;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 0.25rem;
  place-items: start;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._posts_lke2w_787 ._post_lke2w_787 {
  width: 100%;
  height: 100%;
  display: block;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._posts_lke2w_787 ._post_lke2w_787::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
._Placeholder_lke2w_209 ._PapaCard_lke2w_787 ._posts_lke2w_787::after {
  display: block;
  padding-bottom: 100%;
  content: "";
}
._Placeholder_lke2w_209 ._MamaCard_lke2w_853 {
  grid-column: 2;
  grid-row: 1/1 span;
}
._Placeholder_lke2w_209 ._BabyCard_lke2w_857 {
  grid-column: 2;
  grid-row: 2/1 span;
}
._Placeholder_lke2w_209 ._title_lke2w_787 {
  margin-top: 0.75rem;
  width: 15rem;
  height: 1rem;
}