import gql from 'graphql-tag';

export const SEGMENT_FRAGMENT = gql`
  fragment MemberPageSegment on Segment {
    id
    folderId
    title
    filter
    columns {
      memberFieldSchemaIds
      dbColumns
      projectColumns
      order {
        memberFieldSchemaId
        dbColumn
        projectColumn
      }
    }
    memberCount
  }
`;
