import { useMutation } from '@apollo/client';

import { DELETE_PROGRAM_TERMS_TEMPLATE_MUTATION } from '@frontend/app/queries';
import {
  DeleteProgramTermsTemplateMutation,
  DeleteProgramTermsTemplateMutationVariables,
} from '@frontend/app/queries/types/DeleteProgramTermsTemplateMutation';

export const useDeleteProgramTermsTemplateMutation = () =>
  useMutation<DeleteProgramTermsTemplateMutation, DeleteProgramTermsTemplateMutationVariables>(
    DELETE_PROGRAM_TERMS_TEMPLATE_MUTATION,
  );
