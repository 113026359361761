import * as React from 'react';

import { OfferImage } from '@affiliates/components';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { Typography } from '@revfluence/fresh';

import styles from './OfferNameCell.scss';

const { Text } = Typography;
interface IProps {
  name: string;
  imageUrl: string;
  source: OFFER_SOURCE;
}

export const OfferNameCell: React.FC<Readonly<IProps>> = React.memo((props) => (
  <div className={styles.OfferNameCell}>
    <div className={styles.imageWrapper}>
      <OfferImage
        className={styles.image}
        imageUrl={props.imageUrl}
        source={props.source}
      />
    </div>
    <div className={styles.name}>
      <Text style={{ width: 240 }} ellipsis={{ tooltip: props.name }}>
        {props.name}
      </Text>
    </div>
  </div>
));

OfferNameCell.displayName = 'OfferNameCell';
