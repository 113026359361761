import * as React from 'react';
import {
  Col,
  Form,
  Input,
  Row,
} from '@revfluence/fresh';

import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { ICommonFormElementProps } from '../../../types';
import { validateNonNegativeInteger } from '../../../utils';

interface IProps extends ICommonFormElementProps<'percentPayout'> { }

export const OfferPayoutSaleAmount: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your offer payout!' },
    { validator: validateNonNegativeInteger },
  ];
  return (
    <Row align="middle">
      <Col span={24}>
        <Form.Item label="Payout per Sale" name={name} rules={rules}>
          <Input
            data-testid={testIds.commission}
            disabled={disabled}
            onKeyDown={UseNaturalNumbers}
            suffix="%"
            size="large"
            type="number"
          />
        </Form.Item>
      </Col>
    </Row>
  );
});
