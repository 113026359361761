import * as React from 'react';
import {
 filter, differenceBy, sortBy, toLower,
} from 'lodash';
import { OperationVariables } from '@apollo/client';

import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import {
  MemberFieldSchemasQuery_schemas as ISchema,
} from '@frontend/app/queries/types/MemberFieldSchemasQuery';

export interface ICommunityIdentityContext {
  appSchemas: ISchema[];
  defaultSchemas: ISchema[];
  customSchemas: ISchema[];
  allSchemas: ISchema[];
  refetchMemberFields(variables?: Partial<OperationVariables>);
}

const { useMemo, useCallback } = React;

export const CommunityIdentityContext = React.createContext<ICommunityIdentityContext>(null);

export const CommunityIdentityContextProvider = ({ children }) => {
  const {
    data: {
      schemas: allSchemas = null,
    } = {},
    refetch: refetchMemberFields,
  } = useMemberFieldSchemasQuery();

  const sortingFunction = useCallback((field) => toLower(field.name), []);

  const sortedAllSchemas = useMemo(() => sortBy(allSchemas, sortingFunction, 'name'), [allSchemas, sortingFunction]);

  const appSchemas = useMemo(() => filter(sortedAllSchemas, (schema) => !!schema.applicationId), [sortedAllSchemas]);

  const defaultSchemas = useMemo(() => filter(sortedAllSchemas, { isDefault: true }), [sortedAllSchemas]);

  const customSchemas = useMemo(() => differenceBy(sortedAllSchemas, appSchemas, defaultSchemas, 'id'), [sortedAllSchemas, appSchemas, defaultSchemas]);

  const value: ICommunityIdentityContext = {
    appSchemas,
    defaultSchemas,
    customSchemas,
    allSchemas: sortedAllSchemas,
    refetchMemberFields,
  };

  return (
    <CommunityIdentityContext.Provider value={value}>
      {children}
    </CommunityIdentityContext.Provider>
  );
};
