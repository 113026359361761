import * as React from 'react';
import {
 size, map, uniq, findIndex, indexOf,
} from 'lodash';

import { Modal } from '@revfluence/fresh';

import {
  ILicensedContent, Textarea,
  Button, Checkbox, Select,
} from '@components';

import { permissionOptions } from './constants';

import styles from './EditPermissionsModal.scss';

const { useState, useMemo, useEffect } = React;

export interface IPermissionUpdate {
  level: string;
  adsPermission: boolean;
  notes: string;
}

interface IProps {
  show: boolean;
  content: ILicensedContent[];

  onRequestClose();
  onClickSave(content: ILicensedContent[], update: IPermissionUpdate);
}

const EditPermissionsModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const levelOptions = useMemo(() => map(permissionOptions, (label, value) => ({ label, value })), []);

  const initialLevelIndex = useMemo(() => {
    const levels = uniq(map(props.content, 'permission_level'));
    return size(levels) === 1 ? findIndex(levelOptions, { value: levels[0] }) : -1;
  }, [props.content, levelOptions]);

  const initialAdsPermission = useMemo(() => {
    const adsPermissions = uniq(
      map(props.content, (content) => {
        if (!content.limited_permissions) {
          return false;
        }
        return indexOf(content.limited_permissions, 'ads') >= 0;
      }),
    );
    return size(adsPermissions) === 1 ? adsPermissions[0] : false;
  }, [props.content]);

  const initialNotes = useMemo(() => {
    const notes = uniq(map(props.content, 'permission_notes'));
    return size(notes) === 1 ? notes[0] || '' : '';
  }, [props.content]);

  const [levelIndex, setLevelIndex] = useState<number>(initialLevelIndex);
  const [adsPermission, setAdsPermission] = useState<boolean>(initialAdsPermission);
  const [notes, setNotes] = useState<string>(initialNotes);

  useEffect(() => {
    setLevelIndex(initialLevelIndex);
    setAdsPermission(initialAdsPermission);
    setNotes(initialNotes);
  }, [initialLevelIndex, initialAdsPermission, initialNotes]);

  const { value: level = null } = levelOptions[levelIndex] || {};

  const handleClickSave = () => {
    props.onClickSave(props.content, {
      level,
      adsPermission: level === 'limited' ? adsPermission : false,
      notes: level !== 'none' ? notes : null,
    });
  };

  const handleChangeLevel = (_, index: number) => {
    setLevelIndex(index);
  };

  const contentSize = size(props.content);

  return (
    <Modal
      title="Assign Content Usage Rights"
      open={props.show}
      onCancel={props.onRequestClose}
      footer={null}
    >
      <div className={styles.editPermissionsModalContent}>
        <div className={styles.body}>
          Indicate what usage rights you have to the selected&nbsp;
          {contentSize > 1 ? `${contentSize} pieces` : 'piece'}
          {' '}
          of content
        </div>
        <div className={styles.fields}>
          <div className={styles.label}>Choose license type:</div>
          <Select
            hintText="Choose Permission Level"
            options={levelOptions}
            selectedIndex={levelIndex}
            onChange={handleChangeLevel}
            popoverProps={{
              minWidth: 440,
            }}
            className={styles.field}
          />
          {level === 'limited' ? (
            <Checkbox
              checked={adsPermission}
              onChange={setAdsPermission}
              label="May re-use for ads"
              className={styles.field}
            />
          ) : null}
          {level !== 'none' ? (
            <>
              <div className={styles.label}>Assign license terms:</div>
              <div className={styles.field}>
                <Textarea
                  value={notes}
                  onChange={setNotes}
                  placeholder="Enter your license terms..."
                />
                {level !== 'none' ? (
                  <div className={styles.note}>
                    Include the exact language of the license terms that were agreed upon with the
                    creator
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          <Button label="Save" onClick={handleClickSave} />
        </div>
      </div>
    </Modal>
  );
});

export default EditPermissionsModal;
