import { useQuery, QueryHookOptions } from '@apollo/client';

import { MEMBER_FIELD_SCHEMAS_QUERY } from '@frontend/app/queries/MemberFieldSchemasQuery';
import {
  MemberFieldSchemasQuery,
  MemberFieldSchemasQuery_schemas,
} from '@frontend/app/queries/types/MemberFieldSchemasQuery';

type IOptions = QueryHookOptions<MemberFieldSchemasQuery>;
export type IMemberFieldSchema = MemberFieldSchemasQuery_schemas;

export const useMemberFieldSchemasQuery = (options: IOptions = {}) => useQuery(MEMBER_FIELD_SCHEMAS_QUERY, options);
