import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const ReplyIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M10.073 17.2813C10.2 17.3833 10.352 17.4328 10.503 17.4328C10.704 17.4328 10.9035 17.3448 11.0395
      17.1758C11.277 16.8798 11.23 16.4473 10.934 16.2093L4.41402 10.9716C14.7659 9.9866 18.7182 12.3022 20.3752
      19.974C20.4452 20.296 20.7302 20.5165 21.0467 20.5165C21.0947 20.5165 21.1437 20.5115 21.1927 20.501C21.5637
      20.4205 21.7997 20.055 21.7197 19.6835C19.9495 11.4916 15.4677 8.69114 4.92723 9.54871L10.934 4.72329C11.23
      4.48529 11.277 4.05279 11.0395 3.75679C10.8015 3.46079 10.3685 3.41379 10.073 3.65179L2.26828 9.92173C2.24956
      9.93615 2.23163 9.95151 2.21456 9.96772C2.14171 10.0366 2.08547 10.1201 2.04888 10.2119C2.03237 10.2532 2.0198
      10.2963 2.01157 10.3408C2.00393 10.3819 2 10.4239 2 10.4663C2 10.6748 2.0945 10.8718 2.257 11.0023L10.073
      17.2813Z"
    />
  </SvgIcon>
));

ReplyIcon.displayName = 'ReplyIcon';
