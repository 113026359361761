import React from 'react';

import { format as formatDate } from 'date-fns';
import { NameCell } from '@frontend/app/containers/Projects/ProjectsPage/Cell/NameCell';
import {
  Typography, Tag, Button, Modal,
} from '@revfluence/fresh';
import { AutomationStatus } from '@frontend/app/types/globalTypes';
import { ProjectsRouteRoot } from '@frontend/app/containers/Projects/constants';
import { useHistory } from 'react-router-dom';
import { useMessagingContext } from '@frontend/hooks';
import styles from './useAutomationAuditTableColumns.scss';
import { AUTOMATION_EXECUTION_TERMINABLE_STATUSES } from './AutomationAuditTableTypes';

const ExecutionStatusMap = {
  COMPLETED: { text: 'Success', color: 'success' },
  FAILED: { text: 'Failed', color: 'error' },
  PENDING: { text: 'Pending', color: 'warning' },
  RUNNING: { text: 'Running', color: 'processing' },
  TERMINATED: { text: 'Canceled', color: 'default' },
};
const { Text } = Typography;

const formatAuditTableDate = (date) => {
  if (!date) {
    return '';
  }
  return `${formatDate(new Date(date), 'MM/dd/yyyy')} at ${formatDate(new Date(date), 'hh:mm a')}`;
};

export const useAutomationAuditTableColumns = (projectId: number, terminateAutomationExecution: Function, refetchExecutions: Function) => {
  const history = useHistory();
  const goToMember = (memberId: string) => {
    history.push({
      pathname: `/members/${memberId}`,
    });
  };

  const goToConfig = (automationId: string) => {
    history.push({
      pathname: `${ProjectsRouteRoot}/${projectId}/automation_detail/automation/${automationId}`,
    });
  };
  const {
    showSuccessMessage,
    showErrorMessage,
  } = useMessagingContext();

  const columns = [
    {
      key: 'createdDate',
      title: 'Date Triggered',
      dataIndex: 'createdDate',
      render: (createdDate) => <Text>{formatAuditTableDate(createdDate)}</Text>,
    },
    {
      key: 'updatedDate',
      title: 'Date Ended',
      dataIndex: 'updatedDate',
      render: (updatedDate) => <Text>{formatAuditTableDate(updatedDate)}</Text>,
    },
    {
      key: 'automationName',
      title: 'Automation Name',
      dataIndex: 'automation',
      render: (automation) => (
        automation.deletedDate === null
          ? (
            <Button size="middle" type="link" className={styles.AutomationButton} onClick={() => (goToConfig(automation.id))}>
              {automation.name}
            </Button>
)
          : (
            <Text>
              {automation.name}
              <span style={{ color: 'gray' }}>{automation.status === AutomationStatus.DISABLED && '(deleted)'}</span>
            </Text>
)
      ),
    },
    {
      key: 'member',
      title: 'Member',
      dataIndex: 'member',
      render: (member) => (
        <NameCell
          imageUrl={member?.profilePicture}
          name={member?.name}
          onClick={() => goToMember(member?.id)}
        />
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        ExecutionStatusMap[status]
          ? (
            <Tag
              color={ExecutionStatusMap[status].color}
            >
              {ExecutionStatusMap[status].text}
            </Tag>
          )
          : <></>
      ),
    },
    {
      key: 'action',
      title: 'Action',
      dataIndex: '',
      render: (automationExecution) => {
        const memberName = automationExecution?.member?.name;
        const automationName = automationExecution?.automation?.name;
        if (AUTOMATION_EXECUTION_TERMINABLE_STATUSES.includes(automationExecution.status)) {
          return (
            <Button
              size="middle"
              type="link"
              onClick={() => {
                Modal.confirm({
                  title: 'Are you sure you want to cancel this automation run?',
                  content: (
                    <>
                      <span className={styles.ConfirmationModal}>{memberName}</span>
                      {' '}
                      will be removed from the
                      {' '}
                      <span className={styles.ConfirmationModal}>{automationName}</span>
                      {' '}
                      automation and the action will not be performed.
                    </>
                  ),
                  okText: 'Confirm',
                  cancelText: 'Cancel',
                  onOk: () => {
                    terminateAutomationExecution(
                      {
                        variables: {
                          executionId: automationExecution.id,
                          reason: 'User Initiated',
                        },
                      },
                    ).then(() => {
                      showSuccessMessage('Automation run successfully canceled');
                      refetchExecutions();
                    }).catch(() => {
                      showErrorMessage('We were unable to cancel this run. Please try again or contact our support team.');
                    });
                  },
                });
              }}
            >
              Cancel
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
  ];
  return columns;
};
