import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CURRENT_CLIENT_METADATA_QUERY } from '@frontend/app/queries';

import {
  GetCurrentClientMetadataQuery,
  GetCurrentClientMetadataQuery_clientMetadata,
} from '@frontend/app/queries/types/GetCurrentClientMetadataQuery';

type IOptions = QueryHookOptions<GetCurrentClientMetadataQuery>;
export type IClientMetadata = GetCurrentClientMetadataQuery_clientMetadata;

export function useGetCurrentClientMetadata(options: IOptions = {}) {
  const {
 loading, data: { clientMetadata } = {}, error, refetch,
} = useQuery<GetCurrentClientMetadataQuery>(
    GET_CURRENT_CLIENT_METADATA_QUERY,
    options,
  );

  return {
    loading,
    clientMetadata,
    error,
    refetch,
  };
}
