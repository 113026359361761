import gql from 'graphql-tag';

export const TERMS_TEMPLATE_FRAGMENT = gql`
  fragment TermsTemplateFragment on TermsTemplate {
    id
    emailTemplate {
      id
      subject
      title
      text
      attachments {
        id
        name
        url
        size
        type
        uuid
        fileUrl
        localSrc
      }
    }
    compensationPaymentAvailable
    compensationPaymentValue
    compensationFreeProductAvailable
    compensationFreeProductValue
    compensationCommissionAvailable
    compensationCommissionValue
    contentUsageRightValue
    contentUsageRightType
    termsType
    contentRequirements {
      areDueDatesSelected
      deliverablesWithNoDueDates
      deliverablesDueDates
      contentTypes
    }
    contentGuidelineId
    default
    introMessage
    noCompensationAvailable
  }
`;

export const FLEXIBLE_BRIEF_TEMPLATE = gql`
  fragment FlexibleBriefFragment on FlexibleBrief {
    id
    emailTemplate {
      id
      subject
      title
      text
      attachments {
        id
        name
        url
        size
        type
        uuid
        fileUrl
        localSrc
      }
    }
    compensationPaymentAvailable
    compensationPaymentValue
    compensationFreeProductAvailable
    compensationFreeProductValue
    compensationCommissionAvailable
    compensationCommissionValue
    contentUsageRightValue
    contentUsageRightType
    canceled
    canceled_by
    contentRequirements {
      areDueDatesSelected
      deliverablesWithNoDueDates
      deliverablesDueDates
      contentTypes
    }
    contentGuidelineId
    date_canceled
    default
    introMessage
    noCompensationAvailable
    termsType
  }
`;
