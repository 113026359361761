import { IState, IUpdateEmailAuthorAction } from '../../../../types/state';

export const updateEmailAuthor = (state: IState, action: IUpdateEmailAuthorAction): IState => {
  if (state.resourceId === action.resourceId) {
    return state;
  }
  return {
    ...state,
    resourceId: action.resourceId,
  };
};
