import * as React from 'react';
import * as qs from 'qs';
import { useLocation } from 'react-router-dom';

const { useMemo } = React;

export const useParsedRouterSearch = () => {
  const location = useLocation();

  return useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);
};
