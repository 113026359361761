import React from 'react';
import { Checkbox, Form, Typography } from '@revfluence/fresh';
import styles from '../../../OfferForm.scss';
import { FormAsyncAction, IShopifyPromoCodeFormValues } from '../../../types';
import { LEARN_MORE_URL } from '../../../../../utils/constant';

const { Text } = Typography;

interface OfferPromoCodeCombinationsProps {
  value: IShopifyPromoCodeFormValues;
  handleFormAsyncActions: (value: FormAsyncAction) => void;
}

export const OfferPromoCodeCombinations: React.FC<OfferPromoCodeCombinationsProps> = ({
  value,
  handleFormAsyncActions,
}) => {
  const handleChange = (event, key) => {
    handleFormAsyncActions({ action: 'UpdateField', payload: { key, value: event.target.checked } });
  };
  return (
    <div>
      <Form.Item className={styles.halfRow} valuePropName="checked" name="productDiscounts">
        <Checkbox checked={value?.productDiscounts} onChange={(e) => handleChange(e, 'productDiscounts')}>
          Product Discount
        </Checkbox>
        {value?.productDiscounts && (
          <div className={styles.descriptionWrapper}>
            <Text className={styles.descriptionText}>
              If an item is eligible for multiple product discounts, only the largest will apply.
              <Typography.Link target="_blank" href={LEARN_MORE_URL}>
                {' '}
                Learn More
              </Typography.Link>
            </Text>
          </div>
        )}
      </Form.Item>
      <Form.Item className={styles.halfRow} name="orderDiscounts">
        <Checkbox checked={value?.orderDiscounts} onChange={(e) => handleChange(e, 'orderDiscounts')}>
          Order Discount
        </Checkbox>
        {value?.orderDiscounts && (
          <div className={styles.descriptionWrapper}>
            <Text className={styles.descriptionText}>
              To let customers use more than one discount, set up at least one order discount that combines with product
              discounts.
              <Typography.Link target="_blank" href={LEARN_MORE_URL}>
                {' '}
                Learn More
              </Typography.Link>
            </Text>
          </div>
        )}
      </Form.Item>
      <Form.Item className={styles.halfRow} name="shippingDiscounts">
        <Checkbox checked={value?.shippingDiscounts} onChange={(e) => handleChange(e, 'shippingDiscounts')}>
          Shipping Discount
        </Checkbox>
        {value?.shippingDiscounts && (
          <div className={styles.descriptionWrapper}>
            <Text className={styles.descriptionText}>
              To let customers use more than one discount, set up at least one shipping discount that combines with
              product discounts.
              <Typography.Link target="_blank" href={LEARN_MORE_URL}>
                {' '}
                Learn More
              </Typography.Link>
            </Text>
          </div>
        )}
      </Form.Item>
    </div>
  );
};
