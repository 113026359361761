import { LazyImage } from '@components';
import {
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
} from 'antd';
import {
  Button,
} from '@revfluence/fresh';
import cx from 'classnames';
import {
  defer,
  filter,
  get,
  indexOf,
  isEmpty,
  isFunction,
  keyBy,
  map,
  pullAt,
  reduce,
  isNil,
  trim,
  size,
} from 'lodash';
import * as React from 'react';
import { DownOutlined, UpOutlined, PlusOutlined } from '@ant-design/icons';

import { logger } from '@common';
import {
  EllipsisLabel,
  LoadSpinner,
  SortableList,
  HORIZONTAL_TOOLTIP_OFFSET,
  VERTICAL_TOOLTIP_OFFSET,
} from '@frontend/app/components';
import {
  IRenderParams,
  ISortableItem,
} from '@frontend/app/components/SortableList/SortableList';
import { SortableWorklet } from '@frontend/app/containers/Projects/types';
import { useGetAllReservedTaskNamesQuery } from '@frontend/app/hooks/useGetAllReservedTaskNamesQuery';
import { IInstalledApplications, useClientFeatureEnabled } from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import styles from './WorkletsList.scss';

import { useWorklets } from '../hooks';
import { filterWorkletsForUninstalledApps } from '../utils';

const {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} = React;

type TWorklet = Partial<SortableWorklet>;
type TSortableWorkletItem = ISortableItem<TWorklet>;

interface IProps {
  onChange: (worklets: TWorklet[], shouldSubmit?: boolean) => void;
  onDelete?: (workletSpecKey: string, workItemCount: number) => void;
  onEditing?: (isEditing: boolean) => void;
  readOnly?: boolean;
  worklets: TWorklet[];
  installedApps: IInstalledApplications;
  counts: Record<string, number>;
}

const NEW_ITEM_HEIGHT_OFFSET = 84;
const NEW_ITEM_PADDING_BOTTOM = 20;

const CustomWorkletTemplate = Object.freeze({
  specKey: null,
  specLogoURL: 'https://storage.googleapis.com/aspirex-static-files/new_logo.png',
  specTitle: 'Custom Stage',
  specURI: null,
});

const isCustomWorklet = (worklet: TWorklet): boolean => (
  (worklet && !worklet.specKey)
  || worklet?.specURI?.startsWith('CustomWorklet-')
  || false
);

export const WorkletsList: React.FC<IProps> = ({
  onChange,
  onDelete,
  onEditing,
  readOnly,
  worklets,
  installedApps,
  counts,
}) => {
  const sortableListRef = useRef<HTMLDivElement>();
  const customNameWrapperRef = useRef<HTMLDivElement>();
  const editSpecRef = useRef<HTMLDivElement>();

  /**
   * Worklet specs
   */
  const {
    loading: isWorkletsLoading,
    workletByKey: defaultWorkletSpecsByKey = {},
    worklets: defaultWorklets = [],
  } = useWorklets();

  const isPfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);
  const allDefaultWorklets = useMemo(
    () => {
      // filtering the default worklets on basis of feature flag
      let updatedWorkletList = defaultWorklets;
        if (!isPfaV2Enabled) {
          updatedWorkletList = defaultWorklets.filter((worklet) => worklet.specURI !== 'SendBrandProductCatalogWorkletSpecification');
        }
      return [...filterWorkletsForUninstalledApps(updatedWorkletList, installedApps), CustomWorkletTemplate];
    },
    [defaultWorklets, installedApps, isPfaV2Enabled],
  );

  const workletByKey = useMemo(
    () => ({
      ...defaultWorkletSpecsByKey,
      ...keyBy(filter(worklets), ({ specKey }) => specKey),
    }),
    [defaultWorkletSpecsByKey, worklets],
  );

  /**
   * Reserved task names
   */
  const {
    data: {
      taskNames: reservedTaskNames = [],
    } = {},
  } = useGetAllReservedTaskNamesQuery();

  /**
   * State
   */
  const selectedSpecKeys = useMemo(
    () => reduce(
      worklets,
      (result, worklet) => {
        const specKey = worklet?.specKey;
        return specKey ? [...result, specKey] : result;
      },
      [],
    ),
    [worklets],
  );
  const remainingWorkletOptions = useMemo(
    () => filter(
      allDefaultWorklets,
      ({ specKey }) => !selectedSpecKeys.includes(specKey),
    ),
    [allDefaultWorklets, selectedSpecKeys],
  );

  const workletsNamesLower = useMemo(
    () => map(worklets, (worklet) => (
      trim(worklet?.specTitle).toLocaleLowerCase()
    )),
    [worklets],
  );

  /**
   * Custom worklet name form
   */
  const [customNameForm] = Form.useForm();
  const validateCustomNameForm = useCallback(async () => {
    try {
      return await customNameForm.validateFields();
    } catch (errorInfo) {
      logger.error(errorInfo);
      return undefined;
    }
  }, [customNameForm]);

  /**
   * Editing state
   */
  const [editingAtIndex, setEditingAtIndex] = useState<number>(-1);
  const isEditing = !isNil(worklets[editingAtIndex]);
  const isAddingNewStage = readOnly && worklets[editingAtIndex]?.canEdit;

  useEffect(
    () => {
      if (isFunction(onEditing)) {
        onEditing(isEditing);
      }
      if (isEditing) {
        customNameForm.resetFields();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing],
  );

  /**
   * Scroll
   */
  const scrollToNewItem = useCallback(() => {
    // Scroll to new item
    // NOTE: .content is what scrolls, not the window
    defer(() => {
      const newItemElem = sortableListRef.current?.querySelector<HTMLDivElement>('div[class^="item"]:last-child');
      const contentRef = document.querySelector<HTMLDivElement>('#app > div > div > div[class^="content"]');
      if (newItemElem && contentRef) {
        const heightOffset = Math.max(contentRef.clientHeight, newItemElem.clientHeight + NEW_ITEM_HEIGHT_OFFSET);
        const newScrollTop = newItemElem.offsetTop + newItemElem.clientHeight + NEW_ITEM_PADDING_BOTTOM - heightOffset;
        contentRef.scrollTo({
          behavior: 'smooth',
          top: newScrollTop,
        });
      }
    });
  }, [sortableListRef]);

  /**
   * Handlers
   */
  const handleAddWorklet = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onChange([...worklets, { canEdit: true }]);
    setEditingAtIndex(worklets.length);
    scrollToNewItem();
  };

  const handleChangeSpecAtIndex = useCallback((specKey: TWorklet['specKey'], index: number) => {
    const newWorklets = [...worklets];
    if (!specKey) {
      newWorklets[index] = {
        specTitle: customNameForm.getFieldValue('customName'),
        canEdit: true,
      };
    } else {
      // Existing spec
      const updatedWorklet = {
        ...workletByKey[specKey],
        canEdit: newWorklets[index].canEdit,
      };
      if (isEmpty(updatedWorklet)) {
        logger.warn(`Unable to find spec for key: ${specKey}`);
        return;
      }
      newWorklets[index] = updatedWorklet;
    }
    onChange(newWorklets);
  }, [
    customNameForm,
    onChange,
    workletByKey,
    worklets,
  ]);

  const handleUpdateSort = useCallback(
    (newItems: TSortableWorkletItem[], newIndex: number, oldIndex: number) => {
      if (editingAtIndex === newIndex) {
        setEditingAtIndex(oldIndex);
      } else if (editingAtIndex === oldIndex) {
        setEditingAtIndex(newIndex);
      }
      onChange(map(newItems, (item) => item.data));
    },
    [onChange, editingAtIndex],
  );

  const handleRemoveAtIndex = useCallback((index: number) => {
    if (readOnly && !worklets[index]?.canEdit && worklets[index]?.specKey && onDelete) {
      const workletSpecKey = worklets[index].specKey;
      handleToggleEditingAtIndex(false, index);
      onDelete(workletSpecKey, counts[workletSpecKey]);
      return;
    }

    logger.debug(`Deleting worklet at index ${index}`);
    const newWorklets = [...worklets];
    pullAt(newWorklets, index);
    onChange(newWorklets, readOnly && !isAddingNewStage);
    setEditingAtIndex(-1);
  }, [
    readOnly,
    worklets,
    counts,
    isAddingNewStage,
    onChange,
    onDelete,
    handleToggleEditingAtIndex,
  ]);

  const handleDoneClicked = useCallback(async (index: number, isCustom: boolean): Promise<boolean> => {
    logger.debug(`Done editing ${isCustom ? 'custom ' : ''}worklet at index ${index}`);

    // Add new worklet if available
    const newWorklets = [...worklets];

    // Set custom title on new worklet
    if (isCustom) {
      const values = await validateCustomNameForm();
      if (!values?.customName) {
        return false;
      }
      const { customName } = values;
      newWorklets[index].specTitle = trim(customName);
      customNameForm.resetFields();
    }

    // If editing worklet is empty, remove it
    if (isEmpty(newWorklets[index])) {
      pullAt(newWorklets, index);
      handleRemoveAtIndex(index);
    }

    onChange(newWorklets, isAddingNewStage);
    setEditingAtIndex(-1);
    return true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customNameForm,
    handleRemoveAtIndex,
    onChange,
    validateCustomNameForm,
    worklets,
  ]);

  // this is an intentional var for declaration to allow hoisting
  // due to the dependency circle with other functions that cannot be solved without
  // full refactoring of the component.
  var handleToggleEditingAtIndex = useCallback(async (toggle: boolean, index: number) => {
    // Unfinished business
    if (isEditing && editingAtIndex !== index) {
      const workletBeingEdited = worklets[editingAtIndex];
      const didFinishEditing = await handleDoneClicked(editingAtIndex, isCustomWorklet(workletBeingEdited));
      if (!didFinishEditing) {
        return;
      }
      setEditingAtIndex(-1);
      customNameForm.resetFields();
    }

    // Toggle
    if (toggle) {
      setEditingAtIndex(index);
    } else {
      setEditingAtIndex(-1);
      customNameForm.resetFields();
    }
  }, [
    customNameForm,
    editingAtIndex,
    handleDoneClicked,
    isEditing,
    worklets,
  ]);

  /**
   * Renderers
   */
  const renderLogoLabel = useCallback(
    (params: { worklet: TWorklet, showMembers?: boolean }) => {
      const {
        worklet: { specLogoURL, specTitle, specKey },
        showMembers,
      } = params;

      return (
        <div className={styles.worklet}>
          <LazyImage
            className={styles.specLogo}
            fallbackSrc={CustomWorkletTemplate.specLogoURL}
            src={specLogoURL}
          />
          <div>
            <div>
              <EllipsisLabel
                align={[
                  HORIZONTAL_TOOLTIP_OFFSET,
                  VERTICAL_TOOLTIP_OFFSET,
                ]}
                tooltipPlacement="right"
              >
                {specTitle}
              </EllipsisLabel>
            </div>
            {showMembers ? (
              <div className={styles.memberCount}>
                {counts[specKey] > 1 ? `${counts[specKey]} members` : `${counts[specKey]} member`}
              </div>
            ) : null}
          </div>
        </div>
      );
    },
    [counts],
  );

  const reservedTaskNamesLower = useMemo(
    () => map(reservedTaskNames, (name) => name?.toLocaleLowerCase()),
    [reservedTaskNames],
  );

  const renderCustomNameInput = useCallback(
    (index: number, disabled: boolean) => (
      <Form form={customNameForm} layout="vertical">
        <Form.Item
          name="customName"
          rules={[
            () => ({
              validator: async (_, rawValue: string) => {
                const value = trim(rawValue).toLocaleLowerCase();
                if (!value) {
                  return Promise.reject(new Error('Name is required'));
                }
                const names = [...workletsNamesLower];
                names[index] = undefined; // Don't mark current index as duplicate
                if (indexOf(reservedTaskNamesLower, value) > -1) {
                  return Promise.reject(new Error('A custom stage with that name is not allowed'));
                } if (indexOf(names, value) > -1) {
                  return Promise.reject(new Error('A custom stage with that name already exists'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            className={styles.customNameInput}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleDoneClicked(index, true);
              }
            }}
            size="large"
            disabled={disabled}
          />
        </Form.Item>
      </Form>
    ),
    [
      customNameForm,
      workletsNamesLower,
      handleDoneClicked,
      reservedTaskNamesLower,
    ],
  );

  const renderSpecSelect = useCallback((index: number) => {
    const worklet = get(worklets, index);
    const isCustom = !isEmpty(worklet) && isCustomWorklet(worklet);
    const editDisabled = !worklet.canEdit && readOnly;

    const canRemove = worklet.canEdit || size(worklets) > 1;
    const count = counts[worklet.specKey];
    const removeDisabled = !canRemove || count > 20;
    const removeBtnTooltip = canRemove
      ? count > 20
        ? 'We’re sorry, you cannot delete a stage with more than 20 members in it. Please return to the project and move members out of this stage before deleting it.'
        : ''
      : 'You cannot delete the last stage in a workflow. Please add at least one other stage to remove this one.';

    const inputElem = isCustom
      ? renderCustomNameInput(index, editDisabled)
      : undefined;
    if (worklet && worklet.specTitle && isCustom) {
      customNameForm.setFieldsValue({
        customName: worklet.specTitle,
      });
    }

    return (
      <div
        className={cx(styles.editSpec, {
          [styles.sortable]: !readOnly || isAddingNewStage,
        })}
        ref={editSpecRef}
      >
        <div
          className={cx(styles.label, {
            [styles.hasArrow]: !readOnly || !isAddingNewStage,
          })}
          onClick={() => {
            if (!isAddingNewStage) {
              handleDoneClicked(index, isCustom);
            }
          }}
        >
          <span>Stage Action</span>
          <UpOutlined className={styles.arrow} />
        </div>
        <Select
          className={styles.select}
          labelInValue
          onChange={({ value }) => handleChangeSpecAtIndex(value, index)}
          placeholder="Select a stage"
          size="large"
          disabled={editDisabled}
          getPopupContainer={() => editSpecRef.current}
          value={!isEmpty(worklet)
            ? {
              value: worklet.specKey,
              label: renderLogoLabel({
                worklet: isCustom ? CustomWorkletTemplate : worklet,
              }),
            }
            : undefined}
        >
          {map(remainingWorkletOptions, (w) => (
            <Select.Option
              value={w.specKey}
              key={w.specKey}
            >
              {renderLogoLabel({ worklet: w })}
            </Select.Option>
          ))}
        </Select>
        <div ref={customNameWrapperRef}>
          {isCustom && inputElem && (
            <>
              <div className={styles.label}>Stage Name</div>
              {inputElem}
            </>
          )}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.buttons}>
          <Tooltip
            className={styles.Tooltip}
            title={removeBtnTooltip}
            placement="top"
          >
            <Button
              danger
              onClick={() => handleRemoveAtIndex(index)}
              type="link"
              disabled={removeDisabled}
            >
              {isAddingNewStage ? 'Cancel' : 'Remove'}
            </Button>
          </Tooltip>
          <Button
            onClick={() => handleDoneClicked(index, isCustom)}
            type="link"
          >
            {isAddingNewStage ? 'Save' : 'Done'}
          </Button>
        </div>
      </div>
    );
  }, [
    customNameForm,
    handleChangeSpecAtIndex,
    handleDoneClicked,
    handleRemoveAtIndex,
    remainingWorkletOptions,
    renderCustomNameInput,
    renderLogoLabel,
    worklets,
    readOnly,
    counts,
    isAddingNewStage,
  ]);

  const renderWorklet = useCallback(({
    index,
    item: {
      data: worklet,
    },
  }: IRenderParams<TWorklet>) => (
    editingAtIndex === index
      ? renderSpecSelect(index)
      : (
        <div
          className={cx(styles.workletWrapper, {
            [styles.disabled]: isAddingNewStage,
          })}
          onClick={(event) => {
            if (isAddingNewStage) {
              return;
            }

            event.preventDefault();
            event.stopPropagation();
            handleToggleEditingAtIndex(true, index);
          }}
          onKeyDown={() => { }}
          role="button"
          tabIndex={-1}
        >
          {renderLogoLabel({ worklet, showMembers: true })}
          <DownOutlined className={styles.arrow} />
        </div>
      )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    editingAtIndex,
    handleToggleEditingAtIndex,
    readOnly,
    renderLogoLabel,
    renderSpecSelect,
  ]);

  /**
   * Sortable items
   */
  const items: TSortableWorkletItem[] = useMemo(
    () => map(
      worklets,
      (worklet: TWorklet, index: number): TSortableWorkletItem => ({
        id: editingAtIndex === index
          ? `edit-${index}`
          : worklet?.specKey || worklet?.specTitle || `edit-${index}`,
        data: worklet,
        render: renderWorklet,
        className: cx({
          [styles.editing]: editingAtIndex === index,
        }),
        disableSort: readOnly && !worklet.canEdit,
      }),
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editingAtIndex, worklets, readOnly, isEditing, counts],
  );

  const addStageBtnTooltip = isEditing
    ? 'You can only add one stage at a time. Please cancel or save the active new stage'
    : '';

  return isWorkletsLoading
    ? <LoadSpinner />
    : (
      <div
        className={cx(
          styles.WorkletsList,
          {
            [styles.loading]: isWorkletsLoading,
            [styles.editing]: isEditing,
          },
        )}
      >
        <SortableList<TWorklet>
          itemClassName={styles.item}
          onChange={handleUpdateSort}
          options={items}
          sortableListRef={sortableListRef}
        />
        <Tooltip
          title={addStageBtnTooltip}
          placement="top"
        >
          <Button
            disabled={isEditing}
            onClick={handleAddWorklet}
            icon={<PlusOutlined />}
          >
            Add stage
          </Button>
        </Tooltip>
      </div>
    );
};

WorkletsList.defaultProps = {
  onEditing: () => { },
  readOnly: false,
};
