import { TGroup } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
  groups: TGroup[];
  type: ActionType.SET_GROUPS;
}
export type TSetGroupsAction = Readonly<IAction>;

export const setGroups = (state: TState, action: TSetGroupsAction): TState => ({
  ...state,
  groups: action.groups,
});
