import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_META_FIELDS_FROM_SHOPIFY_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/importerManager';
import { GetMetaFieldsFromShopify, GetMetaFieldsFromShopifyVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetMetaFieldsFromShopify';

type IOptions = QueryHookOptions<GetMetaFieldsFromShopify, GetMetaFieldsFromShopifyVariables>;

export const useGetMetaFieldsFromShopify = (options: IOptions) => {
  const {
    data, loading, error,
  } = useQuery<GetMetaFieldsFromShopify, GetMetaFieldsFromShopifyVariables>(GET_META_FIELDS_FROM_SHOPIFY_QUERY, options);

  return {
    metaFields: data?.getMetaFieldsFromShopify,
    isMetaFieldsLoading: loading,
    metaFieldsError: error,
  };
};
