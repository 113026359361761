import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_HOME_REPORTING } from '../queries';
import {
  GetHomeReporting,
  GetHomeReportingVariables,
} from '../queries/types/GetHomeReporting';

type IOptions = QueryHookOptions<GetHomeReporting, GetHomeReportingVariables>;

export const useGetHomeReporting = (options: IOptions = {}) => (
  useQuery<GetHomeReporting>(GET_HOME_REPORTING, options)
);
