import gql from 'graphql-tag';

export const UPDATE_IGDM_AND_SOCIAL_ACCOUNT_CLIENT_SETTINGS_MUTATION = gql`
  mutation UpdateIGDMAndSocialAccountClientSettings(
    $socialAccountId: String!,
    $facebookPageId: String,
    $disconnectAccount: Boolean,
    $socialListeningEnabled: Boolean,
    $IGDMEnabled: Boolean,
    $brandedContentAdsEnabled: Boolean,
  ) {
    updateIGDMAndSocialAccountClientSettings(
      socialAccountId: $socialAccountId,
      facebookPageId: $facebookPageId,
      disconnectAccount: $disconnectAccount,
      socialListeningEnabled: $socialListeningEnabled,
      IGDMEnabled: $IGDMEnabled,
      brandedContentAdsEnabled: $brandedContentAdsEnabled,
    )
  }
`;
