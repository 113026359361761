._socialRow_1pzfk_1 {
  margin-top: 4px;
  margin-bottom: 4px;
}
._socialRow_1pzfk_1 > div > div {
  overflow: hidden;
}
._socialRow_1pzfk_1 > div > div:first-child {
  display: flex;
  align-items: center;
  flex: none;
}
._socialRow_1pzfk_1 > div > div:first-child svg {
  font-size: 16px;
}

._socialValue_1pzfk_17 {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
}
._socialValue_1pzfk_17 ._link_1pzfk_22 {
  margin-right: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
._socialValue_1pzfk_17 ._btn_1pzfk_28 {
  flex: none;
}