import * as React from 'react';
import {
  Modal, ModalSize, Button, SpinnerIcon,
} from '@components';

import styles from './DeleteCommunity.scss';

const { useState } = React;

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  community: any;
  handleDeleteCommunity?(id: number);
  isDeletingCommunity?: boolean;
}

export const DeleteCommunity = (props: IProps) => {
  const [isIntentOpen, setIsIntentOpen] = useState(false);

  const handleIntentDeleteCommunity = () => {
    props.handleDeleteCommunity(props.community.id);
  };

  const handleIntenetOpen = () => {
    setIsIntentOpen(true);
  };

  const handleIntenetClose = () => {
    setIsIntentOpen(false);
  };

  return (
    <div className={styles.DeleteContainer}>
      <label className={styles.label}>Delete this Group</label>
      <p>
        People in this group will still remain in All Contacts and any other group or Project they’re part of. Deleting
        this group will not remove the data of people in this group.
      </p>
      <Button
        theme="danger"
        onClick={handleIntenetOpen}
        label="Delete group"
        disabled={props.isDeletingCommunity}
        icon={props.isDeletingCommunity && <SpinnerIcon size={16} />}
      />

      <Modal
        onRequestClose={handleIntenetClose}
        show={isIntentOpen}
        width={ModalSize.Small}
      >
        <div className={styles.intentContent}>
          <h1 className={styles.header}>
            Permanently delete
            {' '}
            {props.community?.title}
            ?
          </h1>

          <ul>
            <li>People in this group will still remain in All Contacts and any other group they’re part of.</li>
            <li>Deleting this group will not remove the data of people in this group.</li>
          </ul>

          <div className={styles.intentActions}>
            <Button
              className={styles.btn}
              theme="info"
              onClick={handleIntenetClose}
              label="Cancel"
              disabled={props.isDeletingCommunity}
              icon={props.isDeletingCommunity && <SpinnerIcon size={16} />}
            />

            <Button
              className={styles.btn}
              theme="danger"
              onClick={handleIntentDeleteCommunity}
              label="Yes, Delete"
              disabled={props.isDeletingCommunity}
              icon={props.isDeletingCommunity && <SpinnerIcon size={16} />}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};
