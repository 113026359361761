/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_148mm_157 {
  display: flex;
}

._justify-content-space-between_148mm_161 {
  justify-content: space-between;
}

._tabular-nums_148mm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_148mm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_148mm_157 {
  display: flex;
}

._justify-content-space-between_148mm_161 {
  justify-content: space-between;
}

._tabular-nums_148mm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_148mm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AlertGap_148mm_355 {
  margin-bottom: 32px;
}