import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Spinner, message } from '@revfluence/fresh';
import { useGetProductDetails } from '../hooks/useGetProductQuery';
import { GetProductDetailsQuery_productDetail } from '../queries/types/GetProductDetailsQuery';

interface IProps {
  resourceId: number;
  onGetMoreVariants(productData: GetProductDetailsQuery_productDetail): void;
  productId: number;
}

const GetMoreVariants: React.FC<IProps> = ({ resourceId, onGetMoreVariants, productId }) => {
  const [loadData, setLoadData] = useState(false); // Initial state is false
  const { data, loading, error } = useGetProductDetails({
    variables: { resourceId, productId },
    skip: !loadData,
    onCompleted: (fetchedData) => {
      // Your logic here
      onGetMoreVariants(fetchedData.productDetail);
    },
    onError: (fetchError) => {
      // Handle error if you want
      console.error('Error occurred while fetching:', fetchError);
    },
});

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (error?.message) {
      messageApi.error(
        error.message,
        10,
      );
    }
  }, [error?.message, messageApi]);

  if (loading) {
return (
  <>
    {contextHolder}
    <Spinner />
  </>
);
}
  if (error) {
return (
  <div>
    {contextHolder}
    Error fetching product details!
  </div>
);
}

  const getMoreVariants = () => {
    setLoadData(true);
  };

  return (
    <>
      {contextHolder}
      {!data
        && (
          <Typography.Text onClick={getMoreVariants}>
            Get more variants
          </Typography.Text>
        )}
      {/* we grab the maximum variants with product details, so if data has been populated,
all the variants have been retrieved */}
      {data
        && (
          <Typography.Text>
            Retrieved all product options
          </Typography.Text>
        )}
    </>
  );
};

export default GetMoreVariants;
