import gql from 'graphql-tag';

export const PRODUCT_VARIANT_FRAGMENT = gql`
  fragment ProductVariantFragment on ProductVariantV2 {
    id
    name
    price
    sku
    inventory {
      allLocationsInventory
      inventoryItemId
      inventoryPolicy
      inventoryQuantity
      sellableOnlineQuantity
    }
    images
    metadata {
      cost
      displayName
    }
    externalId
  }
`;
