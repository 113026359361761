import * as React from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import styles from './IFrameComponent.module.scss';

interface IProps {
  title: string;

  className?: string;
}

const { useState, useCallback, useMemo } = React;
const iframeStyleContent = `
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.414;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}
`;

/**
 * @type {React.FC}
 */
export const IFrameComponent: React.FC<IProps> = React.memo((props) => {
  const { title, className } = props;
  const [ref, setRef] = useState<HTMLIFrameElement>(null);
  const [frameHeight, setFrameHeight] = useState(0);

  const content = useMemo(() => {
    const mountNode = ref?.contentWindow?.document?.body;
    if (!mountNode) {
      return null;
    }

    return createPortal(props.children, mountNode);
  }, [ref, props.children]);
  const onFrameLoad = useCallback(() => {
    const frameDocument = ref.contentWindow.document;
    const mountNode = frameDocument.body;

    // this makes sure we can open links in parent window
    const baseNode = frameDocument.createElement('base');
    baseNode.target = '_parent';
    frameDocument.head.appendChild(baseNode);

    // apply some base styles
    const styleNode = frameDocument.createElement('style');
    styleNode.textContent = iframeStyleContent;
    frameDocument.head.appendChild(styleNode);

    setFrameHeight(mountNode.scrollHeight);
  }, [ref]);

  return (
    <iframe
      className={cx(styles.IFrameComponent, className)}
      ref={setRef}
      title={title}
      onLoad={onFrameLoad}
      style={{ height: `${frameHeight}px` }}
    >
      {content}
    </iframe>
  );
});

IFrameComponent.displayName = 'IFrameComponent';
