._NumberEqual_13d0h_1 {
  width: 100%;
}
._NumberEqual_13d0h_1._percentage_13d0h_4 .ant-input-number-handler-wrap {
  display: none;
}
._NumberEqual_13d0h_1._percentage_13d0h_4 .ant-input-number-input-wrap {
  display: flex;
  align-items: center;
}
._NumberEqual_13d0h_1._percentage_13d0h_4 .ant-input-number-input-wrap::after {
  content: "%";
  position: absolute;
  right: 6px;
  color: var(--grey-75);
}
._NumberEqual_13d0h_1._percentage_13d0h_4 .ant-input-number-input-wrap input {
  padding-right: 24px;
}