import React, {
 useEffect, useMemo, useState,
} from 'react';
import ConditionValue from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/ConditionValue';
import FormFooter from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormFooter';
import FormHeader from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/FormHeader';
import { FormattedOption, TConditionNode, TSelectedNode } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { getOperatorOptions } from '@frontend/app/containers/Projects/AutomationConfig/utils';
import { AutomationVariableValue, IAutomationVariable } from '@frontend/app/types/automations/condition/AutomationVariable';

import { Select } from '@revfluence/fresh';
import { CheckIcon } from '@revfluence/fresh-icons/regular/esm';
import {
 isArray, isEmpty, isNil, isString,
} from 'lodash';
import { ComboOperator, ComparisonOperator } from '@frontend/app/types/automations/condition/Operator';
import { FieldType } from '@frontend/app/types/globalTypes';
import { GetNodeOptions_getNodeOptions_conditionOptions } from '@frontend/app/queries/types/GetNodeOptions';
import { convertToTitleCase } from '@frontend/app/utils/strings';
import {
  getAllowedAutomationVariables,
  getFieldsForGroup,
  getGroupsForVariable,
} from '@frontend/app/containers/Projects/AutomationConfig/utils/condition';
import { isEmailAddressValid } from '@common';
import styles from './ConfigDrawer.scss';

const { Option } = Select;
const displayAutomationVariable = false; // tech debt

type TProps = {
  onClose: () => void;
  onAddConditionNode: (targetNodeId: string, conditionParentNodeUUID: string, newNode: TConditionNode) => void;
  onRmoveConditionNode: (targetNodeId: string, conditionParentNodeUUID: string, conditionNodeId: string) => void;
  onUpdateConditionNode: (targetNodeId: string, conditionNodeId: string, updatedConditionNode: TConditionNode) => void;
  automationVariables: IAutomationVariable[];
  conditionOptions: GetNodeOptions_getNodeOptions_conditionOptions[];
  selectedNode: TSelectedNode,
};

const ConditionForm = (props: TProps) => {
  const {
    onClose,
    onAddConditionNode,
    onRmoveConditionNode,
    onUpdateConditionNode,
    automationVariables,
    conditionOptions,
    selectedNode,
  } = props;

  const formattedOptions = useMemo<FormattedOption[]>(() => conditionOptions.map((option) => ({
    ...option,
    group: convertToTitleCase(option.group),
  })), [conditionOptions]);

  const [selectedAutomationVariable, setSelectedAutomationVariable] = useState<IAutomationVariable>(null);
  const [allowedAutomationVariables, setAllowedAutomationVariables] = useState<IAutomationVariable[]>([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupsForVariable, setGroupsForVariable] = useState([]);
  const [fieldsForGroup, setFieldsForGroup] = useState<FormattedOption[]>([]);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [value, setValue] = useState<AutomationVariableValue>(null);
  const [validationError, setValidationError] = useState<string>('');

  const isNewCondition = isNil(selectedNode?.conditionNode.attribute);
  const selectedField = fieldsForGroup.find((field) => field.id === selectedFieldId);

  useEffect(() => {
    if (!isNil(selectedNode) && formattedOptions.length > 0) {
      if (!isNil(selectedNode.conditionNode.attribute)) {
        const allowedAutomationVariables = getAllowedAutomationVariables(automationVariables);
        setAllowedAutomationVariables(allowedAutomationVariables);

        const variableId = selectedNode.conditionNode.variableId;
        const selectedAutomationVariable = allowedAutomationVariables.find((variable) => variable.id === variableId);
        setSelectedAutomationVariable(selectedAutomationVariable);

        const groupsForVariables = getGroupsForVariable(selectedAutomationVariable?.type, formattedOptions);
        setGroupsForVariable(groupsForVariables);

        const group = formattedOptions.find((field) => field.id === selectedNode.conditionNode.attribute).group;
        setSelectedGroup(group);

        const fieldId = selectedNode.conditionNode.attribute;
        setSelectedFieldId(fieldId);

        const fieldsForGroup = getFieldsForGroup(group, formattedOptions, selectedAutomationVariable?.type);
        setFieldsForGroup(fieldsForGroup);

        const selectedOperator = selectedNode.conditionNode.mappedOperator;
        setSelectedOperator(selectedOperator);

        const fieldForGroup = fieldsForGroup.find((field) => field.id === selectedNode.conditionNode.attribute);
        const operators = getOperatorOptions(fieldForGroup?.attributeType, fieldForGroup?.choices);
        setOperators(operators);

        const value = selectedNode.conditionNode.value;
        setValue(value);
      } else {
        const allowedAutomationVariables = getAllowedAutomationVariables(automationVariables);
        setAllowedAutomationVariables(allowedAutomationVariables);

        const selectedAutomationVariable = allowedAutomationVariables[0];
        setSelectedAutomationVariable(selectedAutomationVariable);

        const groupsForVariables = getGroupsForVariable(selectedAutomationVariable?.type, formattedOptions);
        setGroupsForVariable(groupsForVariables);

        const group = groupsForVariables[0];
        setSelectedGroup(group);

        const fieldsForGroup = getFieldsForGroup(group, formattedOptions, selectedAutomationVariable?.type);
        setFieldsForGroup(fieldsForGroup);

        const field = fieldsForGroup[0];
        const fieldId = field?.id;
        setSelectedFieldId(fieldId);

        const attributeType = field?.attributeType;
        const operators = getOperatorOptions(attributeType, field?.choices);
        setOperators(operators);

        const selectedOperator = operators[0]?.value;
        setSelectedOperator(selectedOperator);

        const value = field?.defaultValue;
        setValue(value);
      }
    }
  }, [automationVariables, formattedOptions, selectedNode]);

  const onChangeAutomationVariable = (variableId) => {
    const selectedAutoamtionVariable = allowedAutomationVariables.find((variable) => variable.id === variableId);
    setSelectedAutomationVariable(selectedAutoamtionVariable);

    const groupsForVariables = getGroupsForVariable(selectedAutoamtionVariable?.type, formattedOptions);
    setGroupsForVariable(groupsForVariables);

    const group = groupsForVariables[0];
    setSelectedGroup(group);

    const fieldsForGroup = getFieldsForGroup(group, formattedOptions, selectedAutoamtionVariable?.type);
    setFieldsForGroup(fieldsForGroup);

    const field = fieldsForGroup[0];
    const fieldId = field?.id;
    setSelectedFieldId(fieldId);

    const attributeType = field?.attributeType;
    const operators = getOperatorOptions(attributeType, field?.choices);
    setOperators(operators);

    const selectedOperator = operators[0]?.value;
    setSelectedOperator(selectedOperator);

    const value = field?.defaultValue;
    setValue(value);
  };

  const onChangeGroup = (group) => {
    setSelectedGroup(group);

    const fieldsForGroup = getFieldsForGroup(group, formattedOptions, selectedAutomationVariable?.type);
    setFieldsForGroup(fieldsForGroup);

    const field = fieldsForGroup[0];
    const fieldId = field?.id;
    setSelectedFieldId(fieldId);

    const attributeType = field?.attributeType;
    const operators = getOperatorOptions(attributeType, field?.choices);
    setOperators(operators);

    const selectedOperator = operators[0]?.value;
    setSelectedOperator(selectedOperator);

    const value = field?.defaultValue;
    setValue(value);
  };

  const onChangeField = (fieldId, field) => {
    setSelectedFieldId(fieldId);

    const attributeType = field.data.attributeType;
    const operators = getOperatorOptions(attributeType, field.data?.choices);
    setOperators(operators);

    const selectedOperator = operators[0]?.value;
    setSelectedOperator(selectedOperator);

    const value = field.data.defaultValue;
    setValue(value);
  };

  const onChangeOperator = (operator) => {
    setSelectedOperator(operator);
    if (operator === ComparisonOperator.HAS_VALUE || operator === ComboOperator.IS_NULL) {
      setValue(null);
    }
  };

  const onDelete = () => {
    onRmoveConditionNode(
      selectedNode.targetNodeId,
      selectedNode.conditionNode.parentId,
      selectedNode.conditionNode.uuid,
    );
  };

  const validateCondition = (condition: TConditionNode) => {
    const invalidValueError = 'Please enter a valid value';
    const noValueError = 'Please enter a value';
    const noVariableError = 'Please select a variable';
    const negativeNumberError = 'Please enter a positive number';
    const betweenValueError = 'Please enter a valid range';
    const invalidEmailFormatError = 'Please enter a valid email';
    const noError = '';
    let errorString = noError;

    if (isNil(condition.attribute)) {
      errorString = noVariableError;
    }
    switch (condition.mappedOperator) {
      case ComparisonOperator.CONTAINS:
      case ComboOperator.NOT_CONTAINS:
        if (isNil(condition.value)) errorString = noValueError;
        if (condition.value === '') errorString = noValueError;
        if (isArray(condition.value) && condition.value.length === 0) errorString = noValueError;
        break;
      case ComparisonOperator.HAS_VALUE:
      case ComboOperator.IS_NULL:
        if (!isNil(condition.value)) errorString = noValueError;
        break;
      case ComparisonOperator.EQUALS:
      case ComboOperator.NOT_EQUALS:
        if (isNil(condition.value)) errorString = noValueError;
        if (condition.value === '') errorString = noValueError;
        if (typeof condition.value === 'string' && condition.value !== '' && selectedField.attributeType === FieldType.EMAIL && !isEmailAddressValid(condition.value)) {
          errorString = invalidEmailFormatError;
        }
        if (isArray(condition.value) && isEmpty(condition.value)) errorString = noValueError;
        break;
      case ComparisonOperator.IN_THE_LAST:
      case ComparisonOperator.IN_THE_NEXT:
      case ComparisonOperator.MORE_THAN_AGO:
      case ComboOperator.NOT_IN_THE_LAST:
      case ComboOperator.NOT_IN_THE_NEXT:
      case ComboOperator.NOT_MORE_THAN_AGO:
        if (isNil(condition.value)) errorString = noValueError;
        if (Number(condition.value) < 0) errorString = negativeNumberError;
        break;
      case ComparisonOperator.STARTS_WITH:
      case ComparisonOperator.ENDS_WITH:
      case ComboOperator.NOT_STARTS_WITH:
      case ComboOperator.NOT_ENDS_WITH:
        if (isNil(condition.value)) errorString = noValueError;
        if (!isString(condition.value)) errorString = noValueError;
        if (condition.value === '') errorString = noValueError;
        break;
      case ComparisonOperator.GREATER_THAN:
      case ComparisonOperator.GREATER_THAN_EQUALS:
      case ComparisonOperator.LESS_THAN:
      case ComparisonOperator.LESS_THAN_EQUALS:
        if (isNil(condition.value)) errorString = noValueError;
        break;
      case ComboOperator.BETWEEN:
        if (isNil(condition.value)) errorString = noValueError;
        if (!isArray(condition.value)) errorString = invalidValueError;
        if (isArray(condition.value) && condition.value.length !== 2) errorString = invalidValueError;
        if (isArray(condition.value) && condition.value.length === 2 && condition.value[0] > condition.value[1]) errorString = betweenValueError;
        if (isArray(condition.value) && typeof condition.value[0] !== typeof condition.value[1]) errorString = invalidValueError;
        break;
    }
    setValidationError(errorString);
    return errorString === noError;
  };

  const onUpdate = () => {
    const condition: TConditionNode = {
      ...selectedNode.conditionNode,
      attribute: selectedFieldId,
      mappedOperator: selectedOperator,
      value,
      variableId: selectedAutomationVariable?.id,
      op: selectedOperator,
    };
    if (!validateCondition(condition)) return;
    onUpdateConditionNode(
      selectedNode.targetNodeId,
      selectedNode.conditionNode.uuid,
      condition,
    );
  };

  const onAdd = () => {
    const condition: TConditionNode = {
      ...selectedNode.conditionNode,
      attribute: selectedFieldId,
      mappedOperator: selectedOperator,
      value,
      variableId: selectedAutomationVariable?.id,
      op: selectedOperator,
    };
    if (!validateCondition(condition)) return;
    onAddConditionNode(
      selectedNode.targetNodeId,
      selectedNode.conditionNode.parentId,
      condition,
    );
  };

  return (
    <div className={styles.ConfigDrawer}>
      <div>
        <FormHeader
          title={isNewCondition ? 'Add Condition' : 'Edit Condition'}
          description="Conditions are rules that must be true in order to move to the next step."
          onClose={onClose}
          icon={<CheckIcon fontSize={20} />}
        />
        <div className={styles.configBody}>
          <div className={styles.conditionHeader}>
            Condition Type
          </div>
          {
            displayAutomationVariable && (
              <div>
                Variable Type:
                <Select
                  style={{ width: '100%' }}
                  value={selectedAutomationVariable?.id}
                  onChange={onChangeAutomationVariable}
                >
                  {allowedAutomationVariables.map((type) => (
                    <Option
                      value={type.id}
                      label={convertToTitleCase(type.type)}
                      key={type.id}
                    >
                      {convertToTitleCase(type.type)}
                    </Option>
                  ))}
                </Select>
              </div>
            )
          }
          <div>
            <Select
              style={{ width: '100%' }}
              showSearch
              value={selectedGroup}
              onChange={onChangeGroup}
            >
              {groupsForVariable.map((group) => (
                <Option
                  value={group}
                  label={convertToTitleCase(group)}
                  key={group}
                >
                  {convertToTitleCase(group)}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.conditionConfigContainer}>
            <Select
              showSearch
              value={selectedFieldId}
              optionFilterProp="label"
              onChange={onChangeField}
            >
              {fieldsForGroup && fieldsForGroup.map((field) => (
                <Option
                  key={field.id}
                  value={field.id}
                  label={convertToTitleCase(field.displayName)}
                  data={field}
                >
                  {convertToTitleCase(field.displayName)}
                </Option>
              ))}
            </Select>
            <Select
              value={selectedOperator}
              onChange={(value) => onChangeOperator(value)}
            >
              {operators.map((operator) => (
                <Option key={operator.value} value={operator.value}>{operator.operatorLabel}</Option>
              ))}
            </Select>
            <ConditionValue
              selectedOperator={selectedOperator}
              selectedField={selectedField}
              value={value}
              setValue={setValue}
            />
            <div className={styles.validationError}>{validationError}</div>
          </div>
        </div>
      </div>
      <FormFooter
        onSave={isNewCondition ? onAdd : onUpdate}
        onCancel={onClose}
        hasDeleteButton={!isNewCondition}
        onDelete={onDelete}
      />
    </div>
  );
};

export default ConditionForm;
