import * as React from 'react';

import { Alert } from '@revfluence/fresh';
import {
  SubmitButton, Notice,
} from '@components';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { SUPPORT_EMAIL } from '@frontend/app/components/ApplicationPageTemplates/CustomizedTemplate/constants';
import styles from './ReviewPayment.scss';

import { ISendPaymentRequest, savePaymentData } from '../savePayment';
import { PaymentReducerActionType } from '../pages/NewPayment';
import { CreditCardSelector } from './CreditCardSelector';
import { IPaymentSources } from '../models';
import { dollarsForCentsValue, centsForDollarValue } from '../constants';
import { usePaymentSentEvent } from '../hooks/usePaymentSentEvent';

const { useState } = React;
interface IProps {
  newPayment: ISendPaymentRequest;
  paymentSources: IPaymentSources;
  payeeAddress: string;
  dispatch(action): void;
  memberEmail?: string;
  requireHandlingFee?: boolean;
}

export const ReviewPayment: React.FunctionComponent<IProps> = (props) => {
  const {
    newPayment, paymentSources, dispatch, payeeAddress, memberEmail, requireHandlingFee,
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string>(null);

  const { backendServerApiEndpoint, workflowActionParameters } = useApplication();

  const paymentSentHandler = usePaymentSentEvent();

  const amountInCents = centsForDollarValue(newPayment.amount);
  const balanceInCents = paymentSources.balance_in_cents;
  const amountToDeductInCents = Math.min(balanceInCents, amountInCents);
  let amountToChargeInCents = amountInCents - amountToDeductInCents;

  const transactionFeeInCents = amountToChargeInCents * 0.03;
  const handlingFees = amountToChargeInCents * 0.02;
  const totalAmountInCents = amountInCents + transactionFeeInCents + (requireHandlingFee ? handlingFees : 0);

  const remainingBalanceInCents = balanceInCents - amountToDeductInCents;
  amountToChargeInCents += transactionFeeInCents + (requireHandlingFee ? handlingFees : 0);

  const displayAmountInCents = (amount: number) => `$${(dollarsForCentsValue(amount)).toFixed(2)}`;

  const sendPayment = () => {
    const url = `${backendServerApiEndpoint}/payment`;
    setErrorString(null);
    setLoading(true);
    const newPaymentWithWorkflow = {
      ...newPayment,
      work_item_id: workflowActionParameters?.workItems?.[0]?.id,
    };
    savePaymentData(url, newPaymentWithWorkflow, paymentSentHandler)
      .then((payment) => dispatch({
        type: PaymentReducerActionType.SetPayment,
        value: payment,
      }))
      .catch(setError).finally(() => setLoading(false));
  };

  const setError = (error: Error) => {
    setErrorString(error.message);
  };

  const editPayeeAddress = () => {
    dispatch({
      type: PaymentReducerActionType.SetEditPayeeAddress,
      value: true,
    });
  };

  const cards = paymentSources.cards;

  const canMakePayment = !amountToChargeInCents || newPayment.card_id;
  const sendToLabel = payeeAddress ? `Send to ${payeeAddress}`
    : `Aspire will email the member at ${memberEmail} to request PayPal address`;
  return (
    <div className={styles.ReviewPayment}>
      {errorString
        && (
          <Alert
            message="An unexpected error occured."
            description={errorString}
            type="error"
          />
        )}
      <h1>Review Payment</h1>
      <div className={styles.BodyWrap}>
        <ul className={styles.PaymentList}>
          <li>
            <span>Payment for Member</span>
            {' '}
            <span className={styles.Amount}>{displayAmountInCents(amountInCents)}</span>
            <div className={styles.Creator}>
              <span>
                {sendToLabel}
                {' '}
                <a onClick={editPayeeAddress}>Edit</a>
              </span>
            </div>
          </li>
          {transactionFeeInCents > 0
            && (
              <li>
                <span>Transaction Fee (3%)</span>
                {' '}
                <span>{displayAmountInCents(transactionFeeInCents)}</span>
              </li>
            )}
          {requireHandlingFee
            && (
              <li>
                <span>Handling fee (2%)</span>
                {' '}
                <span>{displayAmountInCents(handlingFees)}</span>
              </li>
            )}
          <li className={styles.Total}>
            <span>Total Amount</span>
            {' '}
            <span>{displayAmountInCents(totalAmountInCents)}</span>
          </li>
          {amountToDeductInCents > 0
            && (
              <li>
                <span>Amount to deduct from your balance</span>
                {' '}
                <span>{displayAmountInCents(amountToDeductInCents)}</span>
              </li>
            )}
          {amountToChargeInCents > 0
            && (
              <li className={styles.Total}>
                <span>Amount to charge your card</span>
                {' '}
                <span>{displayAmountInCents(amountToChargeInCents)}</span>
              </li>
            )}
          {amountToDeductInCents > 0
            && (
              <li>
                <span>Your remaining balance</span>
                {' '}
                <span>{displayAmountInCents(remainingBalanceInCents)}</span>
              </li>
            )}
        </ul>

        {amountToChargeInCents > 0 && (
          cards.length > 0
            ? (
              <CreditCardSelector
                selectedCardId={newPayment.card_id}
                cards={cards}
                dispatch={dispatch}
              />
            ) : (
              <Notice>
                <p>
                  You must add a credit card to make payments.
                  Please click on your initials, then go to Workspace Settings to add a credit card on file for creator payments.
                  If you have any questions, please contact
                  {' '}
                  <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank">
                    {SUPPORT_EMAIL}
                  </a>
                  .
                </p>
              </Notice>
            )
        )}
        <div className={styles.SubmitWrap}>
          {canMakePayment && (
            <SubmitButton
              label="Send Payment"
              submittingLabel="Sending..."
              isSubmitting={loading}
              disabled={loading}
              onClick={sendPayment}
            />
          )}
        </div>

      </div>
    </div>
  );
};
