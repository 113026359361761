import {
  AutomationNodeData,
  TActionNode,
  TConditionNodeInput,
  TDelayNode,
} from '@frontend/app/containers/Projects/AutomationConfig/types';
import { parseConditionNode } from '@frontend/app/containers/Projects/AutomationConfig/utils/condition';
import {
 ArrayConditionOperators, BooleanConditionOperators, DateConditionOperators, NumberConditionOperators, SingleSelectConditionOperators, TextConditionOperators,
} from '@frontend/app/types/automations/condition/ConditionOperator';
import { AutomationNodeType, FieldType } from '@frontend/app/types/globalTypes';
import { isArray, isEmpty } from 'lodash';
import { Edge, MarkerType, Node } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';
import { AutomationBlueprint } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { IAutomationVariable } from '@frontend/app/types/automations/condition/AutomationVariable';
import { format, isToday } from 'date-fns';
import { GetNodeOptions_getNodeOptions_conditionOptions_choices } from '@frontend/app/queries/types/GetNodeOptions';
import { NODE_BASE_HEIGHT } from '../constants';

export const createAutomationFlow = (
  automationId: string,
  templateId: string,
  node,
  className = '',
  onNodeItemClick,
  parentId = null,
  level = 0,
) => {
  let nodes: Node<AutomationNodeData>[] = [];
  let edges: Edge[] = [];
  if (parentId === null) {
    const nodeId = node.id;
    nodes.push({
      id: nodeId,
      type: 'TRIGGER',
      data: {
        isEditing: false,
        metadata: node.type,
        nodeId,
        isLeafNode: false,
        nodeType: 'TRIGGER',
      },
      className,
      position: { x: 0, y: level * NODE_BASE_HEIGHT },
    });

    // process the actual root node
    const rootNode = node.root;
    if (rootNode) {
      const { nodes: childNodes, edges: childEdges } = createAutomationFlow(
        automationId,
        templateId,
        rootNode,
        className,
        onNodeItemClick,
        nodeId,
        level + 1,
      );
      nodes = nodes.concat(childNodes);
      edges = edges.concat(childEdges);
    }
  } else {
    const nodeId = node.id;
    let parsedConditionNode = null;

    if (node.type === AutomationNodeType.CONDITION) {
      const rootId = uuidv4();
      parsedConditionNode = {
        ...node.metadata.condition,
        uuid: rootId,
        children: node.metadata.condition.children.map((conditionNode) => parseConditionNode(conditionNode, { ...node.metadata, uuid: rootId })),
      };
    }

    nodes.push({
      id: nodeId,
      type: node.type,
      data: {
        isEditing: false,
        metadata: node.type === AutomationNodeType.CONDITION ? parsedConditionNode : node.metadata,
        nodeId,
        isLeafNode: node.children && node.children.length === 0,
        nodeType: node.type.toUpperCase(),
        automationId,
        templateId,
        onNodeItemClick,
      },
      className,
      position: { x: 0, y: level * NODE_BASE_HEIGHT },
    });

    edges.push({
      id: `edge_${parentId}_${nodeId}`,
      source: parentId,
      target: nodeId,
      style: { stroke: '#389E0D' },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        color: '#389E0D',
      },
    });

    if (node.children) {
      node.children.forEach((childNode) => {
        const childElments = createAutomationFlow(
          automationId,
          templateId,
          childNode,
          className,
          onNodeItemClick,
          nodeId,
          level + 1,
        );
        nodes.push(...childElments.nodes);
        edges.push(...childElments.edges);
      });
    }
  }
  return { nodes, edges };
};

export const getOperatorOptions = (type: string, choices: GetNodeOptions_getNodeOptions_conditionOptions_choices[]) => {
  switch (type) {
    case FieldType.TEXT:
    case FieldType.EMAIL:
      if (!isEmpty(choices)) {
        return SingleSelectConditionOperators.sort((a, b) => a.weight - b.weight);
      }
      return TextConditionOperators.sort((a, b) => a.weight - b.weight);
    case FieldType.ARRAY:
      return ArrayConditionOperators.sort((a, b) => a.weight - b.weight);
    case FieldType.BOOLEAN:
      return BooleanConditionOperators.sort((a, b) => a.weight - b.weight);
    case FieldType.PERCENTAGE:
    case FieldType.NUMBER:
      return NumberConditionOperators.sort((a, b) => a.weight - b.weight);
    case FieldType.ANNUAL:
    case FieldType.DATE:
      return DateConditionOperators.sort((a, b) => a.weight - b.weight);
    default:
      return [];
  }
};

export const updateAutomationBluePrint = (
  node: TConditionNodeInput | TDelayNode | TActionNode,
  nodeId: string,
  blueprint,
) => {
  if (blueprint.id === nodeId) {
    return {
      ...blueprint,
      metadata: {
        ...blueprint.metadata,
        ...node,
      },
    };
  }
  if (blueprint.children) {
    return {
      ...blueprint,
      children: blueprint.children.map((child) => updateAutomationBluePrint(node, nodeId, child)),
    };
  }
};

export const addUUIDs = (node) => {
  const parsedNode = {
    ...node,
    id: uuidv4(),
  };

  if (parsedNode.children && isArray(parsedNode.children)) {
    parsedNode.children = parsedNode.children.map((child) => addUUIDs(child));
  }

  return parsedNode;
};

export const getAutomationVariables = (blueprint: AutomationBlueprint): IAutomationVariable[] => {
  // TODO(jb) we should not aggregate variables from the entire blueprint
  //          they are explicit to a specific trigger
  const variables: IAutomationVariable[] = [];
  const getVariables = (node) => {
    if (node.variables) {
      variables.push(...node.variables);
    }
    if (node.triggers) {
      node.triggers.forEach((trigger) => getVariables(trigger));
    }
    if (node.children) {
      node.children.forEach((child) => getVariables(child));
    }
  };
  getVariables(blueprint);
  return variables;
};

export const formatAutomationDate = (date) => {
  // TODO(jb) we should use a generalized date formatter
  //          not specifc to automations
  if (isToday(new Date(date))) {
    return `Today at ${format(new Date(date), 'h:mm a')}`;
  } else {
    return format(new Date(date), 'MMM d, yyyy');
  }
};
