import { IAddress } from './address';
import { IPublisher } from './publisher';
import { IProjectStage } from './postProject';
import { ISocialAccount } from './socialAccount';
import { TNetworkIdentifier } from './networkIdentifier';

export interface IAdvertiserPublisherRelation {
  proposed_by_buyer: boolean;
  advertiser: IAdvertiser;
  liked: boolean;
  alerts: IAlert[];
  show: boolean;
  is_subscribed: boolean;
  user_type: string;
  is_tutorial: boolean;
  active: boolean;
  featured: boolean;
  modified_ts: number;
  conversation_id: number;
  project_metadata: {
    [id: string]: IProjectMetadata;
  };
  last_update: number;
  id: number;
  categories: ICategories;
  publisher: IPublisher;
  created_ts: number;
  supplier_email: string;
  display_name: string;
  owners: IOwner[];
  notification_count: number;
  referrer: string;
  latest_message: string;
  email_sharing: boolean;
  stash: boolean;
  archive: boolean;
  adjacent_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  latest_note?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  referrer_params?: any;
  stages: IProjectStage[];
  type: string;
  metadata: IMetadata;
}

export interface IProjectMetadata {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publisher_selection?: any;
  address_dict: IAddress;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  agreed_products: any[];
  campaign_id: number;
  agreed_price?: number;
  agreed_price_usd?: number;
  amount_owed?: number;
  amount_owed_usd?: number;
  images: string[];
  has_proposal: boolean;
  proposal_created_ts?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  review?: any;
  proposed_networks: TNetworkIdentifier[];
  post_bundles: IPostBundle[];
  price?: number;
  price_usd?: number;
  amount_sent?: number;
  amount_sent_usd?: number;
  address: string;
  posts_count: number;
  coupon_codes: string;
  country: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post_images: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brand_images: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target_ts?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delivered_ts?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product_received_date?: any;
}

interface IMetadata {
  campaigns: number[];
  accounts: ISocialAccount[];
}

interface IOwner {
  type: string;
  id: number;
}

interface ICategories {
  campaigns: string[];
}

interface IPostBundle {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  price?: any;
  post_type: string;
  account_id: number;
}

interface IAlert {
  project_id: number;
  target_url: string;
  complete: string;
  tool: string;
  can_skip: string;
  campaign_id: number;
  alert: string;
  target_state: string;
  primary_cta: string;
  quiet_alert: string;
  identifier: string;
  alert_text: string;
  description: string;
}

interface IAdvertiser {
  website: string;
  display_name: string;
  name: string;
  brand: IBrand;
  brand_id: number;
  profile_picture: string;
  id: number;
}

interface IBrand {
  logo_url: string;
  name: string;
  id: number;
  currency_code: number;
  currency_xrate: number;
}
