import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const GoogleDriveVerifiedIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 42 35">
    <path d="M29.2949728,24.668784 L19.7150635,8.00816697 L10.2740472,8.00816697 L19.8539564,24.668784 L29.2949728,24.668784 Z M11.8012704,26.0571688 L7.08076225,34.3874773 L25.2686025,34.3874773 L29.9891652,26.0571688 L11.8012704,26.0571688 Z M9.02450091,10.0907441 L0,26.0571688 L4.72050817,34.3874773 L13.8838475,18.4210526 L9.02450091,10.0907441 Z" />
    <path
      fill="#ffffff"
      d="M30,22 C35.5228475,22 40,17.5228475 40,12 C40,6.4771525 35.5228475,2 30,2 C24.4771525,2 20,6.4771525 20,12 C20,17.5228475 24.4771525,22 30,22 Z M30,24 C23.372583,24 18,18.627417 18,12 C18,5.372583 23.372583,0 30,0 C36.627417,0 42,5.372583 42,12 C42,18.627417 36.627417,24 30,24 Z"
    />
    <circle fill="#ffffff" cx="29.9949999" cy="11.9949999" r="9.99499989" />
    <path
      fill="#3996E0"
      d="M35.01549,8.55037 L29.03019,16.72497 L25.05759,12.66507 C24.75089,12.35167 24.75639,11.84897 25.06979,11.54217 C25.38329,11.23557 25.88609,11.24107 26.19269,11.55447 L28.85529,14.27557 L33.73409,7.61217 C33.99319,7.25827 34.48999,7.18147 34.84389,7.44057 C35.19769,7.69967 35.27459,8.19647 35.01549,8.55037 M29.99699,2.00607 C24.47579,2.00607 19.99999,6.48187 19.99999,12.00307 C19.99999,17.52417 24.47579,21.99997 29.99699,21.99997 C35.51819,21.99997 39.99389,17.52417 39.99389,12.00307 C39.99389,6.48187 35.51819,2.00607 29.99699,2.00607"
    />
  </SvgIcon>
));

GoogleDriveVerifiedIcon.displayName = 'GoogleDriveVerifiedIcon';
