import * as React from 'react';

import {
  Row,
  Space,
  Col,
} from '@revfluence/fresh';

import { useLocation } from 'react-router-dom';

import { ListOnMarketplaceProvider } from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/context';
import { MarketplaceDrawer } from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/MarketplaceDrawer';

import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import {
  useClientFeatureEnabled,
  useGetCountsByTaskIdsQuery,
} from '@frontend/app/hooks';
import {
  TTask,
  TProject,
} from '@frontend/app/containers/Projects/types';
import {
  ICounts,
  useOverviewPage,
} from '@frontend/app/containers/Projects/hooks';

import { useAuth } from '@frontend/context/authContext';

import {
  IComplexStat,
} from '@frontend/app/components/StatisticsCard';
import { useTermsDashboardQuery } from '@frontend/app/hooks';

import {
  CONTENT_APP_ID,
  SALES_APP_ID,
  SOCIAL_POST_APP_ID,
} from '@frontend/app/constants/applicationIds';

import { ClientFeature } from '@frontend/app/constants';
import styles from './OverviewPage.scss';

import WidgetProgress from './WidgetProgress';
import WidgetPerformance from './WidgetPerformance';
import WidgetAcceptedTerms from './WidgetAcceptedTerms';
import { ModalType } from '../ProjectsPage/AddMembersToProgramModal/AddMembersToCollectionModal';
import { TWorklet } from '../types';
import WidgetTopPerformingContent from './WidgetTopPerformingContent/WidgetTopPerformingContent';
import AutomationSummary from '../AutomationSummary/AutomationSummary';
import WidgetProjectBudget from './WidgetProjectBudget';
import WidgetTikTokSparkAdsRecommendations from './WidgetTikTokSparkAdsRecommendations/WidgetTikTokSparkAdsRecommendations';

interface IProps {
  counts: ICounts;
  tasks: TTask[];
  project: TProject;
  worklets: TWorklet[];
  onTaskSelected: THandleTaskSelected;
  openAddToCollectionModal(modalType: ModalType): void;
}

const { useMemo } = React;

enum AcceptedTerms {
  Terms = 'count',
  Deliverables = 'deliverableCount',
  EstimatedReach = 'estimatedReach',
  CommittedPayment = 'paymentSummary',
}

const statFields = [
  {
    label: 'Terms',
    key: AcceptedTerms.Terms,
    hasError: false,
  },
  {
    label: 'Deliverables',
    key: AcceptedTerms.Deliverables,
    hasError: false,
  },
  {
    label: 'Estimated Reach',
    key: AcceptedTerms.EstimatedReach,
    hasError: false,
  },
  {
    label: 'Committed Payment',
    key: AcceptedTerms.CommittedPayment,
    hasError: false,
  },
];

export const OverviewPage: React.FC<IProps> = React.memo(
  ({
    onTaskSelected,
    project,
    counts,
    tasks,
    worklets,
    openAddToCollectionModal,
  }) => {
    const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
    const isProjectDashboardTermsContentEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_DASHBOARD_TERMS_CONTENT);
    const isProjectDashboardAcceptedTermsEnabled = useClientFeatureEnabled(ClientFeature.PROJECT_DASHBOARD_ACCEPTED_TERMS);
    const isTikTokSparkAdsRecommendationsEnabled = useClientFeatureEnabled(ClientFeature.TIKTOK_SPARK_ADS_RECOMMENDATIONS);
    const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
    const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
    const location = useLocation();
    const taskIds = (tasks || []).map((task) => task.taskId);
    const auth = useAuth();
    const { renderArchivedNotice } = useOverviewPage(project);

    /**
     * Counts
     */
    const {
      data: {
        counts: countsNeedAttention = undefined,
      } = {},
    } = useGetCountsByTaskIdsQuery({
      fetchPolicy: 'no-cache',
      variables: { projectId: project.id, taskIds },
      skip: !project.id,
    });

    const { data, loading } = useTermsDashboardQuery({
      skip: !project.id || !isProjectDashboardAcceptedTermsEnabled,
      variables: {
        programId: project.id,
        detailed: true,
      },
      fetchPolicy: 'no-cache',
    });

    const formattedStats: IComplexStat[] = useMemo(() => statFields.map((statField) => ({
      ...statField,
      format: statField.key === 'paymentSummary' ? 'wholeDollars' : 'default',
      value: data?.getTermsDashboardDataForProgram?.accepted[statField.key] || 0,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })), [statFields, data]);

    return (
      <>
        <Space
          direction="vertical"
          size={isRefreshUIEnabled ? [16, 16] : [32, 32]}
          className={styles.OverviewPage}
        >
          {isArchiveProjectEnabled && renderArchivedNotice}
          <Row gutter={isRefreshUIEnabled ? [16, 16] : [32, 32]}>
            <Col
              xs={24}
              lg={16}
            >
              <WidgetProgress
                projectId={project.id}
                onTaskSelected={onTaskSelected}
                counts={counts}
                countsNeedAttention={countsNeedAttention}
                tasks={tasks}
                worklets={worklets}
                openAddToCollectionModal={openAddToCollectionModal}
              />
            </Col>
            <Col
              xs={24}
              lg={8}
            >
              <AutomationSummary projectId={project.id} />
            </Col>
          </Row>
          {
            isBudgetAllocationEnabled && (
              <Row gutter={isRefreshUIEnabled ? [16, 16] : [30, 32]}>
                <Col
                  span={24}
                >
                  <WidgetProjectBudget
                    programId={project.id}
                  />
                </Col>
              </Row>
            )
          }
          {isRefreshUIEnabled && isProjectDashboardTermsContentEnabled && (
            <WidgetTopPerformingContent
              clientId={auth.clientInfo.id}
              projectId={project.id}
            />
          )}
          <WidgetPerformance
            affiliatesAppId={SALES_APP_ID}
            clientId={auth.clientInfo.id}
            contentAppId={CONTENT_APP_ID}
            socialAppId={SOCIAL_POST_APP_ID}
            project={project}
          />
          {isProjectDashboardTermsContentEnabled && (
            <>
              {!isRefreshUIEnabled && (
                <WidgetTopPerformingContent
                  clientId={auth.clientInfo.id}
                  projectId={project.id}
                />
              )}
              {isTikTokSparkAdsRecommendationsEnabled && (
                <WidgetTikTokSparkAdsRecommendations
                  programId={project.id}
                />
              )}
              {isProjectDashboardAcceptedTermsEnabled && (
                <WidgetAcceptedTerms
                  loading={loading}
                  data={formattedStats}
                />
              )}
            </>
          )}
          <ListOnMarketplaceProvider openFrom={location}>
            <MarketplaceDrawer />
          </ListOnMarketplaceProvider>
        </Space>
      </>
    );
  },
);

OverviewPage.displayName = 'OverviewPage';
