import * as React from 'react';

import { SketchPicker, ColorResult } from 'react-color';
import { Input, Popover } from 'antd';
import cx from 'classnames';

import styles from './ColorPicker.scss';

export interface IColorPickerProps {
  /**
   * Callback when a color is selected.
   */
  onChange?(value: string);
  /**
   * Color controls what color is active on the color picker.
   */
  color?: string;
  defaultColor?: string;
  /**
   * Whether the control is disabled
   */
  disabled?: boolean;
}

const { useState } = React;

export const ColorPicker: React.FC<IColorPickerProps> = ({
  color,
  defaultColor = '#000000',
  onChange,
  disabled,
}) => {
  // If the color come with the #, this is removed
  const [colorState, setColorState] = useState(defaultColor);
  const isControlled = typeof color !== 'undefined';

  const formatColor = (color: string) => (!color.startsWith('#') ? `#${color}` : color).toUpperCase();

  const selectedColor = formatColor(isControlled ? color : colorState);

  const handleChange = (color: ColorResult) => {
    const hex = color.hex.toUpperCase();

    if (!isControlled) {
      setColorState(hex);
    }

    if (onChange) {
      onChange(hex);
    }
  };

  const displayStyles = {
    backgroundColor: selectedColor,
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  const renderColorPicker = () => (
    <SketchPicker color={selectedColor} disableAlpha onChange={handleChange} />
    );

  const handleInputChange = (event) => {
    handleChange({
        hex: formatColor(event.target.value),
        hsl: null,
        rgb: null,
      });
  };

  return (
    <div className={cx(styles.ColorPicker, { [styles.ColorPicker__disabled]: disabled })}>
      <Popover
        content={renderColorPicker()}
        trigger={disabled ? [] : 'click'}
        overlayClassName={styles.Popover}
        placement="bottomLeft"
      >
        <div
          className={styles.swatch}
          style={displayStyles}
        />
      </Popover>

      <Input
        className={styles.value}
        size="large"
        value={selectedColor}
        onChange={handleInputChange}
        maxLength={7}
        disabled={disabled}
      />
    </div>
  );
};
