import { KeyboardArrowDownIcon } from '@components';
import {
  Dropdown,
  Menu,
  Typography,
  Tooltip,
  TMenuProps,
} from '@revfluence/fresh';
import cx from 'classnames';
import {
  countBy,
  each,
  find,
  first,
  isFunction,
  isNil,
  map,
  size,
} from 'lodash';
import * as React from 'react';

import { logger } from '@common';

import styles from './ContextPicker.scss';
import {
  EllipsisLabel,
  HORIZONTAL_TOOLTIP_OFFSET,
  VERTICAL_TOOLTIP_OFFSET,
} from '..';

const {
  useCallback,
  useEffect,
  useMemo,
  useState,
} = React;

export const OVERFLOW_MAX_CHARACTERS_LIMIT = 30;
export const SELECT_OVERFLOW_MAX_CHARACTERS_LIMIT = 23;
const { Title, Text } = Typography;

type TKey = string | null;

export interface IContextPickerProps {
  /**
   * Class name
   */
  className?: string;
  /**
   * Default selected option
   */
  defaultOption?: TKey;
  /**
   * Disabled
   */
  disabled?: boolean;
  /**
   * Footer container class name
   */
  footerClassName?: string;
  /**
   * Menu item class name
   */
  itemClassName?: string;
  /**
   * Callback when the footer item is selected
   */
  onFooterSelect: () => void;
  /**
   * Callback when an option is selected
   */
  onSelect: (key: TKey) => void;
  /**
   * Overlay class name
   */
  overlayClassName?: string;
  /**
   * Element to appear at the bottom of the overlay
   */
  footer?: React.ReactNode;
  /**
   * Override how an option is rendered
   */
  renderOption?: (key: TKey) => React.ReactNode;
  /**
   * Override how the select label is rendered
   */
  renderSelectLabel?: (key: TKey) => React.ReactNode;
  /**
   * Selected option
   */
  selectedOption?: TKey;
  /**
   * Options
   */
  options: readonly IContextPickerOption[];
}

export interface IContextPickerOption {
  /**
   * Source of the image which rendered is on the left side if `renderOption` is not provided
   */
  icon?: React.ReactNode;
  /**
   * Unique item key
   */
  key: TKey;
  /**
   * Label
   */
  label: React.ReactNode;
}

export const ContextPicker: React.FC<Readonly<IContextPickerProps>> = (props) => {
  const {
    className,
    defaultOption,
    disabled,
    footer,
    footerClassName,
    itemClassName,
    onFooterSelect,
    onSelect,
    options,
    overlayClassName,
    renderOption,
    renderSelectLabel,
    selectedOption: selectedOptionProp,
  } = props;

  // Warn if there are duplicate IDs
  useEffect(
    () => {
      const idCount = countBy(options, (option) => option.key);
      each(idCount, (count, key) => {
        if (count >= 2) {
          logger.warn(`ContextPicker: Duplicate key "${key}"`);
        }
      });
    },
    [options],
  );

  // UI states
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // State
  const isControlled = typeof selectedOptionProp !== 'undefined';
  const [selectedKeyState, setSelectedKeyState] = useState<TKey>(
    (() => {
      if (isControlled) {
        return selectedOptionProp;
      } else if (typeof defaultOption !== 'undefined') {
        return defaultOption;
      }
      return first(options)?.key;
    })(),
  );
  useEffect(
    () => {
      if (selectedOptionProp) {
        setSelectedKeyState(selectedOptionProp);
      }
    },
    [selectedOptionProp],
  );

  const handleOptionClicked: TMenuProps['onClick'] = useCallback(
    (selectedOption) => {
      setDropdownOpen(false);
      const { key } = selectedOption;
      if (!isControlled) {
        // 1. `key` can be null
        // 2. `selectedOption.key` can be a number, so force a `.toLocaleString()` if possible
        setSelectedKeyState(key !== null ? key?.toLocaleString?.() : null);
      }
      if (isFunction(onSelect)) {
        onSelect(key);
      }
    },
    [isControlled, onSelect],
  );

  // Selected item
  const selectedKey = isControlled ? selectedOptionProp : selectedKeyState;
  const selectedOption = find(options, (option) => option.key === selectedKey);

  // Render a single menu item
  const defaultRenderOption = useCallback(
    (option: IContextPickerOption) => {
      /** By doing this we prevent a complex calculation of DOM */
      const shouldShowTooltip = size((option.label as string)) > OVERFLOW_MAX_CHARACTERS_LIMIT;

      return (
        <Tooltip
          placement="right"
          title={shouldShowTooltip ? option.label : undefined}
        >
          {!isNil(option.icon) && (
          <span className={styles.icon}>
            {option.icon}
          </span>
        )}

          <Text
            className={styles.itemLabel}
          >
            {option.label}
          </Text>
        </Tooltip>
      );
    },
    [],
  );
  const renderItem = (option: IContextPickerOption) => (
    <Menu.Item
      className={cx(
        styles.item,
        itemClassName,
        {
          [styles.selected]: option.key === selectedKey,
        },
      )}
      key={option.key}
    >
      {
        isFunction(renderOption)
          ? renderOption(option.key)
          : defaultRenderOption(option)
      }
    </Menu.Item>
  );

  // Menu list
  const menuElements = useMemo(() => (
    <Menu
      className={styles.menu}
      onClick={handleOptionClicked}
      selectedKeys={[selectedKey]}
    >
      {map(options, (option) => renderItem(option))}
      {footer && (
      <div className={styles.menuFooter}>
        <Menu.Divider className={styles.footerDivider} />
        <Menu.Item
          className={footerClassName}
          key="ContextPicker__footer"
          onClick={() => {
                if (isFunction(onFooterSelect)) {
                  onFooterSelect();
                }
              }}
        >
          {footer}
        </Menu.Item>
      </div>
    )}
    </Menu>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [options, selectedKey, footer, onFooterSelect]);

  // Selected item label
  const defaultRenderSelectLabel = () => (
    <div>
      {selectedOption?.icon && (
        <div className={styles.selectIcon}>
          {selectedOption.icon}
        </div>
      )}
      <Title
        className={styles.selectLabel}
        level={5}
        noMargin
      >
        <EllipsisLabel
          align={[
            HORIZONTAL_TOOLTIP_OFFSET,
            VERTICAL_TOOLTIP_OFFSET,
          ]}
          tooltipPlacement="right"
        >
          {selectedOption?.label}
        </EllipsisLabel>
      </Title>
    </div>
  );

  return (
    <Dropdown
      arrow
      disabled={disabled}
      onVisibleChange={(visible) => setDropdownOpen(visible)}
      overlay={menuElements}
      overlayClassName={cx(styles.overlay, overlayClassName)}
      placement="bottomRight"
      trigger={['click']}
    >
      <div
        className={cx(
          styles.ContextPicker,
          className,
          {
            [styles.active]: isDropdownOpen,
            [styles.disabled]: disabled,
          },
        )}
      >
        {isFunction(renderSelectLabel)
          ? renderSelectLabel(selectedKey)
          : defaultRenderSelectLabel()}
        <KeyboardArrowDownIcon
          className={styles.downIcon}
          size={12}
        />
      </div>
    </Dropdown>
  );
};
