import * as React from 'react';
import cx from 'classnames';

import { Radio } from 'antd';
import { RadioGroupProps, RadioChangeEvent } from 'antd/lib/radio';

import styles from './RadioGroup.scss';

export interface IRadioGroupProps extends RadioGroupProps { }
export type IRadioChangeEvent = RadioChangeEvent;

export const RadioGroup: React.FC<IRadioGroupProps> = (props) => (
  <Radio.Group
    {...props}
    className={cx(styles.RadioGroup, props.className)}
    buttonStyle="solid"
    optionType="button"
  />
  );
