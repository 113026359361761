import { reduce } from 'lodash';

import { IState } from '../../../../types/state';
import { IUpdateNewPriceAction } from './index';

export const updateNewPrice = (state: IState, action: IUpdateNewPriceAction): IState => {
  if (state.collaborationDetails.reward.payment.newPrice === action.newPrice) {
    return state;
  }
  const currentPaymentAmount = state.collaborationDetails.reward.payment.newPrice;
  const members = reduce(
    state.members,
    (acc, next, index) => {
      if (next.paymentAmount && next.paymentAmount !== currentPaymentAmount) {
        return acc;
      }
      const updatedMember = {
        ...next,
        comment: null,
        paymentAmount: action.newPrice,
      };

      if (acc !== state.members) {
        acc[index] = updatedMember;
        return acc;
      }

      return [
        ...acc.slice(0, index),
        updatedMember,
        ...acc.slice(index + 1),
      ];
    },
    state.members,
  );
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      reward: {
        ...state.collaborationDetails.reward,
        payment: {
          ...state.collaborationDetails.reward.payment,
          newPrice: action.newPrice,
        },
      },
    },
    members,
  };
};
