import { unescape, upperCase } from 'lodash';
import { GetMemberActivitiesQuery_activities_payload as ActivitiesPayload } from '@frontend/app/queries/types/GetMemberActivitiesQuery';
import { GetThreadQuery_thread_messages_payload as MessagesPayload } from '@frontend/app/queries/types/GetThreadQuery';
import { UserFragment } from '@frontend/app/queries/fragments/types/UserFragment';

export const stripStyle = (message: string) => message.replace(/<style\b[^<>]*>[\s\S]*?<\/style\s*>/gi, '');

export const getMessagePayload = (payload: ActivitiesPayload | MessagesPayload) => {
  if (payload.textHtml) {
    return stripStyle(payload.textHtml);
  }
  return unescape(payload.textPlain);
};

export const getUserInitials = (user: Partial<UserFragment>) =>
  (user.name || user.email)
    .split(' ')
    .slice(0, 2)
    .map((letter) => upperCase(letter.substring(0, 1)))
    .join('');
