import * as React from 'react';
import {
  Statistic,
  Tooltip,
} from '@revfluence/fresh';
import {
  BadgePercentIcon,
  BoxIcon,
  CircleDollarIcon,
} from '@revfluence/fresh-icons/solid/esm';
import {
  filter,
  flatten,
  map,
  sum,
  values,
} from 'lodash';

import { formatMoney } from '@affiliates/utils';
import { ContentType } from '@frontend/app/types/globalTypes';
import { IContentGuidelinesWithDueDates } from '../../types/ContentGuidelines';
import { IState } from '../../types/state';
import { SummaryContentItem } from './SummaryContentItem';
import { SummaryRewardItem } from './SummaryRewardItem';

import styles from './Summary.scss';

const {
  useMemo,
} = React;

type TContentTypeDueDates = {
  [key in ContentType]: IContentGuidelinesWithDueDates['dueDates']['completeDate'];
};

export interface IProps {
  collaborationDetails: IState['collaborationDetails'];
  contentGuidelines: IState['contentGuidelines'];
  members: IState['members'];
}

export const Summary: React.FC<IProps> = React.memo((props) => {
  const {
    collaborationDetails: {
      contentRight,
      reward: {
        commission,
        freeProduct,
      },
    },
    contentGuidelines,
    members,
  } = props;

  const selectedMembers = useMemo(() => filter(members, 'selected'), [members]);
  const {
    commissionDescription,
    freeProductDescription,
    reach,
    totalSpend,
  } = useMemo(() => ({
    commissionDescription: commission.showHideDescription ? commission.description : null,
    freeProductDescription: freeProduct.showHideDescription ? freeProduct.description : null,
    reach: sum(map(selectedMembers, 'reach')),
    totalSpend: `$${formatMoney(sum(map(selectedMembers, 'paymentAmount'))).toString()}`,
  }), [
    commission,
    freeProduct,
    selectedMembers,
  ]);

  const contentTypes = useMemo((): TContentTypeDueDates => {
    const toReturn = {};
    for (const type of values(ContentType)) {
      toReturn[type] = flatten(
        map(filter(contentGuidelines, { type }), (guideline) => guideline.dueDates.completeDate),
      );
    }
    return toReturn as TContentTypeDueDates;
  }, [contentGuidelines]);

  const totalContent = useMemo(() => (
    sum(map(values(contentTypes), (completeDate) => completeDate.length)) * selectedMembers.length
  ), [contentTypes, selectedMembers]);

  const content = useMemo(() => {
    const filteredKeys = filter(values(ContentType), (contentType) => (
      contentTypes[contentType].length > 0
    ));
    return map(filteredKeys, (contentType) => (
      <SummaryContentItem
        contentType={contentType}
        dueDates={contentTypes[contentType]}
        key={contentType}
      />
    ));
  }, [contentTypes]);

  const usageRightsMessage = useMemo((): string => {
    const { type } = contentRight;
    switch (type) {
      case 'BROAD':
        return 'Broad usage rights.';
      case 'LIMITED':
        return 'Limited usage rights.';
      case 'CUSTOM':
        return 'Custom usage rights.';
      case 'NONE':
      default:
        return 'No usage rights.';
    }
  }, [contentRight]);

  return (
    <div className={styles.Summary}>
      <div className={styles.column}>
        <div className={styles.summaryStat}>
          <Statistic
            size="large"
            title={`Member${selectedMembers.length !== 1 ? 's' : ''}`}
            value={selectedMembers.length}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.summaryStat}>
          <Statistic
            size="large"
            title="Estimated Reach"
            value={reach}
          />
        </div>
      </div>
      <div className={styles.column}>
        <Tooltip placement="bottom" title={usageRightsMessage}>
          <div className={styles.summaryStat}>
            <Statistic
              size="large"
              title={`Content Piece${totalContent !== 1 ? 's' : ''}`}
              value={totalContent}
            />
          </div>
        </Tooltip>
      </div>
      <div className={styles.column}>
        <div className={styles.itemListWrapper}>
          <SummaryRewardItem
            icon={<CircleDollarIcon />}
            label={`${totalSpend || '--'}`}
            tooltip={`Payment: ${totalSpend || '--'}`}
          />
          <SummaryRewardItem
            icon={<BoxIcon />}
            label={`${freeProductDescription || '--'}`}
            tooltip={`Product: ${freeProductDescription || '--'}`}
          />
          <SummaryRewardItem
            icon={<BadgePercentIcon />}
            label={`${commissionDescription || '--'}`}
            tooltip={`Commission: ${commissionDescription || '--'}`}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.itemListWrapper}>
          {content}
        </div>
      </div>
    </div>
  );
});
Summary.displayName = 'Summary';
