import * as React from 'react';
import { Skeleton } from '@revfluence/fresh';

export const Loading = React.memo(() => (
  <>
    <Skeleton active />
    <Skeleton active />
    <Skeleton active />
    <Skeleton active />
  </>
));
Loading.displayName = 'Loading';
