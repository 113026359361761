import gql from 'graphql-tag';
import { TERMS_TEMPLATE_FRAGMENT } from './fragments/TermsTemplateFragment';

export const GET_TERMS_SETTINGS_FOR_PROGRAM = gql`
  query GetTermsSettingsForProgramQuery($programId: Int!, $termsType: TermsType) {
    termsTemplate: getTermsSettingsForProgram(programId: $programId, termsType: $termsType) {
      ...TermsTemplateFragment
    }
  }
  ${TERMS_TEMPLATE_FRAGMENT}
`;
