/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vyxzy_157 {
  display: flex;
}

._justify-content-space-between_vyxzy_161 {
  justify-content: space-between;
}

._tabular-nums_vyxzy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_vyxzy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CreateLinksDrawer_vyxzy_178 .ant-drawer-body {
  padding: 0;
}
._CreateLinksDrawer_vyxzy_178 ._header_vyxzy_181 {
  position: absolute;
  left: 1.5rem;
  display: flex;
  align-items: center;
}
._CreateLinksDrawer_vyxzy_178 ._linkDetails_vyxzy_187 {
  border-bottom: 0.0625rem solid #f0f0f0;
  padding: 1rem 1.5rem;
}
._CreateLinksDrawer_vyxzy_178 ._createLinkContainer_vyxzy_191 {
  padding: 1.5rem;
}
._CreateLinksDrawer_vyxzy_178 ._createLinkContainer_vyxzy_191 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._CreateLinksDrawer_vyxzy_178 ._createLinkContainer_vyxzy_191 .ant-select {
  width: 100%;
  margin-bottom: 0.5rem;
}
._CreateLinksDrawer_vyxzy_178 ._createLinkContainer_vyxzy_191 .ant-collapse {
  margin-top: 2rem;
}