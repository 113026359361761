import * as React from 'react';
import cx from 'classnames';
import {
  Button, Card, Col, Row, Spinner, Typography,
} from '@revfluence/fresh';

import { ICard } from '../../models';

import styles from './PaymentSource.scss';

const { Title } = Typography;

const masterCardIcon = require('@frontend/app/assets/images/mastercard-card-icon@2x.png');
const visaIcon = require('@frontend/app/assets/images/visa-card-icon@2x.png');
const amexIcon = require('@frontend/app/assets/images/american-express-card-icon@2x.png');
const discoverIcon = require('@frontend/app/assets/images/discover-card-icon.png');

interface IProps {
  card: ICard;
  isRemoving?: boolean;
  className?: string;
  onClickRemove?();
}

export const PaymentSource: React.FC<Readonly<IProps>> = (props) => (
  <Card className={cx(styles.PaymentSource, props.className)}>
    <Row align="middle" justify="space-between">
      <Col>
        <Title level={5} weight="regular" noMargin>
          {props.card.brand === 'Visa' && <img src={visaIcon} className={styles.cardIcon} />}
          {props.card.brand === 'MasterCard' && <img src={masterCardIcon} className={styles.cardIcon} />}
          {props.card.brand === 'AmericanExpress' && <img src={amexIcon} className={styles.cardIcon} />}
          {props.card.brand === 'Discover' && <img src={discoverIcon} className={styles.cardIcon} />}
          {props.card.brand}
          {' '}
          card ending in
          {' '}
          {props.card.last4}
        </Title>
      </Col>
      <Col>
        <Button
          danger
          className={cx(styles.removeBtn, { [styles.visible]: props.isRemoving })}
          icon={props.isRemoving && <Spinner />}
          disabled={props.isRemoving}
          onClick={props.onClickRemove}
        >
          Remove
        </Button>
      </Col>
    </Row>
  </Card>
);

PaymentSource.defaultProps = {
  isRemoving: false,
};
