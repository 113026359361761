import { cn } from '@frontend/shadcn/lib/utils';
import React from 'react';

interface SecondaryCardMetricProps {
  color: string;
  children: React.ReactNode;
  className?: string;
}

const SecondaryCardMetric = ({ color, children, className }: SecondaryCardMetricProps) => (
  <div
    className={cn('border border-border rounded-lg border-t-0 pt-1 overflow-hidden', className)}
    style={{ backgroundColor: color }}
  >
    <div className="flex min-w-full h-full py-4 px-5 rounded-t-xl bg-primary-foreground -mx-1">
      {children}
    </div>
  </div>
  );

export default SecondaryCardMetric;
