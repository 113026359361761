/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_9wsau_157 {
  display: flex;
}

._justify-content-space-between_9wsau_161 {
  justify-content: space-between;
}

._tabular-nums_9wsau_165, ._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._Card_9wsau_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_9wsau_170 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_9wsau_496 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Card_9wsau_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Card_9wsau_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Card_9wsau_170 ::-webkit-scrollbar-track, ._Card_9wsau_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Card_9wsau_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_9wsau_165,
._audienceAgeBarChart_9wsau_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_9wsau_165 > [class*=list],
._audienceAgeBarChart_9wsau_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_9wsau_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_9wsau_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_9wsau_306 {
  cursor: help;
}

._Card_9wsau_170 {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  break-inside: avoid-column;
  box-sizing: border-box;
}
._Card_9wsau_170 ._cardHeader_9wsau_320 {
  margin-top: -0.5rem;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._Card_9wsau_170 ._cardHeader_9wsau_320:empty {
  display: none;
  margin: unset;
}
._Card_9wsau_170 ._tabs_9wsau_331 {
  margin-right: -0.5rem;
  align-self: flex-end;
}
._Card_9wsau_170 ._tabs_9wsau_331 ._tabButton_9wsau_335 {
  min-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  letter-spacing: 0.0625rem;
}
._Card_9wsau_170 ._tabs_9wsau_331 ._tabButton_9wsau_335, ._Card_9wsau_170 ._tabs_9wsau_331 ._tabButton_9wsau_335:hover {
  background-color: transparent;
}
._Card_9wsau_170 ._tabs_9wsau_331 ._tabButton_9wsau_335:hover > div {
  color: #3996e0;
}
._Card_9wsau_170 ._tabs_9wsau_331 ._tabButton_9wsau_335 > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aeaeae;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Card_9wsau_170 ._tabs_9wsau_331 ._tabButton_9wsau_335._activeTab_9wsau_354 > div {
  color: #3996e0;
}

._separator_9wsau_358 {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  height: 0.0625rem;
  border-bottom: 0.0625rem solid #eff5f9;
}

._cardTab_9wsau_365 {
  display: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._cardTitle_9wsau_373 {
  margin: 0 auto 0 0;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

._visible_9wsau_381 {
  display: flex !important;
  animation: _show_9wsau_496 0.4s;
}

._noData_9wsau_386::after {
  position: absolute;
  top: calc(50% + 0.6666666667rem);
  left: 50%;
  display: block;
  color: #8f8d91;
  content: "No data yet";
  transform: translate(-50%, -50%);
}

._ProfileCard_9wsau_396 ._hashtags_9wsau_396 {
  margin: auto 0;
  padding-top: 0.25rem;
}

._AccountInfo_9wsau_401 {
  min-height: 4rem;
  display: flex;
  flex-direction: row;
}
._AccountInfo_9wsau_401 ._image_9wsau_406 {
  margin-right: 1rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 0 0.25rem 0.75rem rgba(46, 66, 109, 0.2);
  transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._AccountInfo_9wsau_401 ._image_9wsau_406:hover {
  transform: scale(1.05);
}
._AccountInfo_9wsau_401 ._info_9wsau_417 {
  width: calc(100% - 5rem);
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  line-height: 1.3333333333rem;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._username_9wsau_427,
._AccountInfo_9wsau_401 ._info_9wsau_417 ._location_9wsau_428,
._AccountInfo_9wsau_401 ._info_9wsau_417 ._stats_9wsau_429 {
  margin: 0 auto 0 0;
  display: inline-flex;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._username_9wsau_427 {
  max-width: 100%;
  color: #1a1818;
  text-decoration: none;
  transition: color 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._username_9wsau_427:hover {
  color: #3996e0;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._username_9wsau_427 h4 {
  margin: 0;
  display: inline;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._username_9wsau_427 h4 ._ambIcon_9wsau_450 {
  margin-left: 0.5rem;
  display: inline-flex;
  align-self: center;
  vertical-align: middle;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._icon_9wsau_456 {
  width: 1.125rem;
  margin-right: 0.375rem;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._stats_9wsau_429 ._verticalSeparator_9wsau_460 {
  margin: 0 0.5rem;
  color: #dadcde;
  font-weight: bold;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._stats_9wsau_429 ._checkCircleIcon_9wsau_465 {
  transform: translateY(0.125rem);
  fill: #3996e0;
}
._AccountInfo_9wsau_401 ._info_9wsau_417 ._stats_9wsau_429 ._starIcon_9wsau_469 {
  transform: translateY(0.125rem);
  fill: #EACD60;
}

._Posts_9wsau_474 {
  width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
  user-select: none;
}
._Posts_9wsau_474._carousel_9wsau_481 {
  margin-top: -0.125rem;
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484 {
  width: 200%;
  height: calc(100% - 2.75rem);
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484 ._recentPosts_9wsau_488,
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484 ._sponsoredPosts_9wsau_489,
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484 ._popularPosts_9wsau_490 {
  width: 50%;
  float: left;
  position: relative;
  transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showRecent_9wsau_496 {
  margin-left: 0;
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showRecent_9wsau_496 ._recentPosts_9wsau_488 {
  opacity: 1;
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showRecent_9wsau_496 ._sponsoredPosts_9wsau_489,
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showRecent_9wsau_496 ._popularPosts_9wsau_490 {
  opacity: 0;
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showSponsored_9wsau_506, ._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showPopular_9wsau_506 {
  margin-left: -100%;
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showSponsored_9wsau_506 ._recentPosts_9wsau_488, ._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showPopular_9wsau_506 ._recentPosts_9wsau_488 {
  opacity: 0;
}
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showSponsored_9wsau_506 ._sponsoredPosts_9wsau_489,
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showSponsored_9wsau_506 ._popularPosts_9wsau_490, ._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showPopular_9wsau_506 ._sponsoredPosts_9wsau_489,
._Posts_9wsau_474._carousel_9wsau_481 ._content_9wsau_484._showPopular_9wsau_506 ._popularPosts_9wsau_490 {
  opacity: 1;
}
._Posts_9wsau_474 ._carouselButtons_9wsau_517 {
  margin-bottom: 0.75rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._recentButton_9wsau_523,
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._sponsoredButton_9wsau_524,
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._popularButton_9wsau_525 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 2rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  border: 0;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._recentButton_9wsau_523 > [class*=label],
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._sponsoredButton_9wsau_524 > [class*=label],
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._popularButton_9wsau_525 > [class*=label] {
  color: #8f8d91;
  font-size: 0.75rem;
  font-weight: 500;
}
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._recentButton_9wsau_523._active_9wsau_354,
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._sponsoredButton_9wsau_524._active_9wsau_354,
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._popularButton_9wsau_525._active_9wsau_354 {
  background-color: #eff5f9;
}
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._recentButton_9wsau_523._active_9wsau_354 > [class*=label],
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._sponsoredButton_9wsau_524._active_9wsau_354 > [class*=label],
._Posts_9wsau_474 ._carouselButtons_9wsau_517 ._popularButton_9wsau_525._active_9wsau_354 > [class*=label] {
  color: #3996e0;
}
._Posts_9wsau_474 ._content_9wsau_484 {
  width: 100%;
  height: 100%;
  transition: margin-left 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
._Posts_9wsau_474 ._content_9wsau_484::after {
  clear: both;
  content: "";
}
._Posts_9wsau_474 ._content_9wsau_484 ._recentPosts_9wsau_488 {
  width: 100%;
  height: 100%;
  position: relative;
}
._Posts_9wsau_474._youtube_9wsau_565 {
  overflow-x: hidden;
  overflow-y: initial;
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569 {
  width: 100%;
  display: grid;
  grid-gap: 0.125rem;
  place-items: start;
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569 ._Post_9wsau_474 {
  width: 100%;
  height: 100%;
  display: block;
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569 ._Post_9wsau_474::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569 ._Post_9wsau_474 ._image_9wsau_406 {
  height: 100%;
  position: absolute;
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._blog_9wsau_589, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._facebook_9wsau_589, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._instagram_9wsau_589, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._twitter_9wsau_589 {
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._blog_9wsau_589::after, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._facebook_9wsau_589::after, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._instagram_9wsau_589::after, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._twitter_9wsau_589::after {
  content: "";
  width: 0;
  padding-bottom: 100%;
  grid-row: 1/1;
  grid-column: 1/1;
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._blog_9wsau_589 > *:first-child, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._facebook_9wsau_589 > *:first-child, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._instagram_9wsau_589 > *:first-child, ._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._twitter_9wsau_589 > *:first-child {
  grid-row: 1/1;
  grid-column: 1/1;
}
._Posts_9wsau_474._grid_9wsau_569 ._PostsGrid_9wsau_569._youtube_9wsau_565 {
  grid-template-rows: initial;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(2, 1fr);
}
._Posts_9wsau_474._masonry_9wsau_609 ._recentPosts_9wsau_488,
._Posts_9wsau_474._masonry_9wsau_609 ._sponsoredPosts_9wsau_489 {
  overflow-x: hidden;
  overflow-y: scroll;
}
._Posts_9wsau_474._masonry_9wsau_609 ._PostsGrid_9wsau_569 ._masonryGrid_9wsau_614 {
  width: 100%;
}
._Posts_9wsau_474._masonry_9wsau_609 ._PostsGrid_9wsau_569 ._masonryGrid_9wsau_614 ._Post_9wsau_474 {
  width: 100%;
  height: 100%;
  padding: 0.0625rem;
}
._Posts_9wsau_474._masonry_9wsau_609 ._PostsGrid_9wsau_569 ._masonryGrid_9wsau_614 ._Post_9wsau_474 ._postDetails_9wsau_622 {
  top: 0.0625rem;
  left: 0.0625rem;
  width: calc(100% - 0.125rem);
  height: calc(100% - 0.125rem);
}
._Posts_9wsau_474 ._noDataPulled_9wsau_628 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
._Posts_9wsau_474 ._noDataPulled_9wsau_628 span {
  color: #8f8d91;
  text-align: center;
}
._Posts_9wsau_474 ._noDataPulled_9wsau_628 span a {
  color: #3996e0;
  text-decoration: none;
}
._Posts_9wsau_474 ._noDataPulled_9wsau_628 span a:hover {
  color: #4fa7ee;
}

._Post_9wsau_474 {
  display: flex;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
._Post_9wsau_474 ._image_9wsau_406 {
  width: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
}
._Post_9wsau_474:hover ._postDetails_9wsau_622 {
  opacity: 1;
}
._Post_9wsau_474 ._postDetails_9wsau_622 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(26, 24, 24, 0.5);
  color: #fdfdfd;
  border-radius: 0.25rem;
  z-index: 1;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._Post_9wsau_474 ._postDetails_9wsau_622 div {
  margin: auto;
  display: flex;
  flex-flow: column wrap;
}
._Post_9wsau_474 ._postDetails_9wsau_622 div span {
  margin: 0.25rem;
  display: flex;
  align-items: center;
  filter: drop-shadow(0 0 0.25rem #1a1818);
}
._Post_9wsau_474 ._postDetails_9wsau_622 div span ._icon_9wsau_456 {
  margin-right: 0.25rem;
  width: 1.75rem !important;
}
._Post_9wsau_474 ._postDetails_9wsau_622 div span ._icon_9wsau_456._thumbsDown_9wsau_693 {
  transform: rotate(180deg);
}
._Post_9wsau_474._youtube_9wsau_565::after {
  padding-bottom: 56.25% !important;
}