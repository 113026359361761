import * as React from 'react';
import { pick, map, reduce } from 'lodash';

import {
  TGender, ILocation, countries, languages, TokenInput, LocationInput, LocationInputIQData,
} from '@components';

import { FilterSection } from '../FilterSection';
import CreatorAgeSection, { CREATOR_AGE_FILTERS } from './CreatorAgeSection';
import CreatorInterestSection, { CREATOR_INTEREST_FILTERS } from './CreatorInterestSection';
import CreatorGenderSection, { CREATOR_GENDER_FILTERS } from './CreatorGenderSection';
import { IQAllowedLanguages } from './IQAllowedLanguages';

import {
  ICreatorAgeFilters,
  ICreatorDemographicFilters,
  ICreatorDemographicOptions,
  ILanguageOption,
} from '../models';
import { PlaceTypeOption, TPlacesOptions } from '@frontend/components/widgets/PlacesInput/types';
import { TNetworkIdentifier } from '@frontend/components/common';
import { MAX_LOCATIONS_LIMIT } from './CreatorSearchFilters';

const { useMemo } = React;

const FILTERS = [
  ...CREATOR_AGE_FILTERS,
  ...CREATOR_INTEREST_FILTERS,
  ...CREATOR_GENDER_FILTERS,
  'countries',
  'languages',
  'locations',
];

const pickFilters = (obj: any): ICreatorDemographicFilters => pick(obj, FILTERS);

const DEFAULT_CREATOR_DEMOGRAPHICS_COUNTRY_CHOICES = [
  ...map(countries, (label, code) => ({ label, value: code })),
];

const DEFAULT_CREATOR_DEMOGRAPHICS_LANGUAGE_CHOICES = [
  ...map(languages, (label, code) => ({ label, value: code })),
];

interface IProps extends ICreatorDemographicOptions, ICreatorDemographicFilters {
  showIQDataFilters?: boolean;
  onChange(demographics: ICreatorDemographicFilters);
  isIQSearch: boolean;
  shouldShowDemoFeatures: boolean;
  network?: TNetworkIdentifier;
}

const CreatorDemographicsSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeAgeRanges = (ageFilters: ICreatorAgeFilters) => {
    const newFilters = {
      ...pickFilters(props),
      ...ageFilters,
    };
    props.onChange(newFilters);
  };

  const handleChangeInterests = (interests: string[]) => {
    const newFilters = {
      ...pickFilters(props),
      interests,
    };
    props.onChange(newFilters);
  };

  const handleChangeGender = (gender: TGender) => {
    const newFilters = {
      ...pickFilters(props),
      gender,
    };
    props.onChange(newFilters);
  };

  const handleChangeLocations = (locations: ILocation[]) => {
    if (locations.length <= MAX_LOCATIONS_LIMIT) {
      const newFilters = {
        ...pickFilters(props),
        locations,
      };
      props.onChange(newFilters);
    }
  };

  const handleChangeLanguages = (languages: ILanguageOption[]) => {
    const newFilters = {
      ...pickFilters(props),
      languages,
    };
    props.onChange(newFilters);
  };

  const iqLocationOptionsForNetwork = useMemo(() => {
    switch (props.network) {
      case 'instagram':
        return {
          types: [PlaceTypeOption.Country, PlaceTypeOption.State, PlaceTypeOption.City],
        } as TPlacesOptions;
      case 'tiktok':
        return {
          types: [PlaceTypeOption.Country],
        } as TPlacesOptions;
      case 'youtube':
        return {
          types: [PlaceTypeOption.Country],
        } as TPlacesOptions;
      default:
        return {
          types: [],
        } as TPlacesOptions;
    };
  }, [props.network]);

  const hide = props.hide
    || (props.hideCreatorAge
      && props.hideCreatorLocations
      && props.hideCreatorCountries
      && props.hideCreatorGender
      && props.hideCreatorLanguages
      && props.hideCreatorInterests);

  const languageChoices = useMemo(() => {
    if (!props.isIQSearch) {
      return props.languageChoices;
    }

    return reduce(IQAllowedLanguages, (tokens, langName, langValue) => {
      tokens.push({
        label: langName,
        value: langValue,
      });

      return tokens;
    }, []);
  }, [props.isIQSearch, props.languageChoices]);

  return (
    <FilterSection
      header="Creator Demographics"
      hide={hide}
      collapsible
      defaultIsOpen={props.isExpanded}
    >
      <CreatorAgeSection
        ageRanges={props.ageRanges}
        ageRangeChoices={props.ageRangeChoices}
        onChange={handleChangeAgeRanges}
        hide={props.hideCreatorAge}
        showIQDataFilters={props.showIQDataFilters}
        ageMin={props.ageMin}
        ageMax={props.ageMax}
      />

      {
        !props.shouldShowDemoFeatures
        && (
          <CreatorInterestSection
            interests={props.interests}
            interestChoices={props.interestChoices}
            onChange={handleChangeInterests}
            hide={props.hideCreatorInterests}
          />
        )
      }

      <CreatorGenderSection
        gender={props.gender}
        onChangeGender={handleChangeGender}
        hide={props.hideCreatorGender}
      />

      {
        !props.shouldShowDemoFeatures
        && (
          <FilterSection
            header="Locations"
            caption={`Max: ${MAX_LOCATIONS_LIMIT} locations`}
            isSubsection
            hide={props.hideCreatorLocations}
          >
            {
              props.showIQDataFilters ?
                <LocationInputIQData
                  onChange={handleChangeLocations}
                  locations={props.locations}
                  options={iqLocationOptionsForNetwork}
                /> :
                <LocationInput
                  onChange={handleChangeLocations}
                  locations={props.locations}
                />
            }
          </FilterSection>
        )
      }

      <FilterSection
        header={props.showIQDataFilters ? "Language" : "Languages"}
        hide={props.hideCreatorLanguages}
        isSubsection
      >
        <TokenInput
          tokens={props.languages}
          placeholder="Enter a language"
          onChange={handleChangeLanguages}
          emptyOptionsMessage="Start typing a language name"
          options={languageChoices}
          hideOptionsOnEmptyInput
          isSingle={props.showIQDataFilters}
          allowTokenCreation={false}
        />
      </FilterSection>
    </FilterSection>
  );
};

CreatorDemographicsSection.defaultProps = {
  countryChoices: DEFAULT_CREATOR_DEMOGRAPHICS_COUNTRY_CHOICES,
  languageChoices: DEFAULT_CREATOR_DEMOGRAPHICS_LANGUAGE_CHOICES,
  ageRanges: [],
  ageMin: '',
  ageMax: '',
  locations: [],
  countries: [],
  interests: [],
  languages: [],
};

export default CreatorDemographicsSection;
