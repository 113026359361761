import { MutationHookOptions } from '@apollo/client';

import { useMutation } from '@frontend/app/hooks';
import { DELETE_REQUIREMENT_BY_ID } from '@frontend/app/queries';
import {
  DeleteRequirementByIdMutation,
  DeleteRequirementByIdMutationVariables,
} from '@frontend/app/queries/types/DeleteRequirementByIdMutation';

type IOptions = MutationHookOptions<DeleteRequirementByIdMutation, DeleteRequirementByIdMutationVariables>;

export const useDeleteRequirementByIdMutation = (options: IOptions = {}) => useMutation<DeleteRequirementByIdMutation, DeleteRequirementByIdMutationVariables>(DELETE_REQUIREMENT_BY_ID, options);
