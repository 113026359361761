import {
  isNil,
  keys,
  omitBy,
} from 'lodash';
import * as React from 'react';
import { match } from 'react-router-dom';

import { ProjectsPageState } from '../constants';

interface IRouteParams {
  projectId: string;
  taskId: string;
}

const {
  useEffect,
  useMemo,
  useState,
} = React;

export const useProjectsPageState = (
  { params }: match<IRouteParams>,
  pathname: string,
): ProjectsPageState => {
  const [pageState, setPageState] = useState<ProjectsPageState>(ProjectsPageState.Overview);

  const paramsWithValues = useMemo(
    () => omitBy<IRouteParams>(params, isNil),
    [params],
  );

  // Assess the page state from the current route
  useEffect(
    () => {
      const routeKeys = new Set(keys(paramsWithValues) as [keyof IRouteParams]);
      if (
        routeKeys.size === 1
        && routeKeys.has('projectId')
      ) {
        if (pathname.match(/^\/projects\/\d+\/applicants/)) {
          setPageState(ProjectsPageState.Applicants);
        } else if (pathname.match(/^\/projects\/\d+\/completed/)) {
          setPageState(ProjectsPageState.Completed);
        } else if (pathname.match(/^\/projects\/\d+\/overview/)) {
          setPageState(ProjectsPageState.Overview);
        }
      } else if (
        routeKeys.size === 1
        && routeKeys.has('projectId')
      ) {
        setPageState(ProjectsPageState.AllInProgress);
      } else if (
        routeKeys.size === 2
        && routeKeys.has('projectId')
        && routeKeys.has('taskId')
      ) {
        setPageState(ProjectsPageState.Task);
      }
    },
    [paramsWithValues, pathname, setPageState],
  );

  return pageState;
};
