/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_srweu_157 {
  display: flex;
}

._justify-content-space-between_srweu_161 {
  justify-content: space-between;
}

._tabular-nums_srweu_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_srweu_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SimpleButton_srweu_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._SimpleButton_srweu_178 {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  color: #3996e0;
  cursor: pointer;
}
._SimpleButton_srweu_178._disabled_srweu_195 {
  opacity: 0.5;
  pointer-events: none;
}
._SimpleButton_srweu_178 ._inner_srweu_199 {
  display: flex;
  align-items: center;
  justify-content: center;
}
._SimpleButton_srweu_178:hover {
  background-color: #e6edf2;
}
._SimpleButton_srweu_178 ._icon_srweu_207 {
  display: flex;
  margin-right: 0.5rem;
  font-weight: lighter;
  font-size: 1rem;
}