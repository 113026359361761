import * as React from 'react';
import cx from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';

import { IResource } from '@frontend/app/hooks';

import { SendEmailForm, IEmailEditorState } from '@frontend/app/components';
import { Editor, IEditor } from '@frontend/app/components';
import { EmailEditorProvider } from '@frontend/app/components/MessageComposer/EmailEditor/EmailEditorContext';
import { VariableBlock, findVariableStrategy } from '@frontend/app/components/MessageComposer/EmailEditor/decorator';

import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';

import styles from './MessageComposer.scss';

const {
  useRef,
  useMemo,
  useCallback,
  useState,
} = React;

interface IProps {

  editorState: IEmailEditorState;
  onEditorStateChange(state: IEmailEditorState): void;

  subject: string;
  onSubjectChange(subject: string): void

  resource?: IResource;
  onResourceChange?(resourceId: number): void
  members?: IMember[];
  className?: string;

  // sending from members page
  memberCount?: number;

  readOnly?: boolean;
}

export interface IEmailComposer {
  sendMessage(bulk?: boolean, excludeMemberIds?: number[]): void;
}

export interface IPreviewConfig {
  resource: IResource;
  subject: string;
  editorState: IEmailEditorState;

  members?: IMember[];
}

const INIT_MESSAGE_HTML = `<div>Hello,</div>
<br />
<div>We want to make sure your information is up-to-date so we can collaborate better. Please take a look and let us know if it is correct and if not, update it using the link below. Thank you!</div>
<br />
<a href="https://landingpageurl/" readonly>Confirm My Information</a>
`;

/**
 * @type {React.FunctionComponent}
 */
const EmailComposer: React.FunctionComponent<IProps> = (props) => {
  const editorRef = useRef<IEditor>(null);
  const [isEditorExpanded, setIsEditorExpanded] = useState<boolean>(false);

  useHotkeys('enter', () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, [editorRef]);

  const toggleExpandEditor = useCallback(() => {
    setIsEditorExpanded((prev) => !prev);
  }, [setIsEditorExpanded]);

  const signaturePreview = useMemo(() => {
    switch (props.resource?.config.signature?.mode) {
      case 'html':
        return props.resource.config.signature?.fromHTML;
      case 'editor':
        return props.resource.config.signature?.fromEditor;
    }
  }, [props.resource]);

  const toFieldString = props.memberCount > 1
    ? `${props.memberCount} Members`
    : `${props.memberCount} Member`;

  if (props.readOnly) {
    let value = props.editorState.html;
    if (props.resource?.config?.signature?.fromHTML) {
      value += `<p></p>${props.resource?.config?.signature?.fromHTML}`;
    }
    return (
      <EmailEditorProvider>
        <Editor
          key={props.resource?.id} // force comp to update
          className={styles.editor}
          readOnly
          initialValue={value}
          convertFromHTML
          decorators={[{
            strategy: findVariableStrategy,
            component: VariableBlock,
          }]}
        />
      </EmailEditorProvider>
    );
  }

  return (
    <div className={cx(styles.EmailComposer, props.className, {
      [styles.editorExpanded]: isEditorExpanded,
    })}
    >
      <SendEmailForm
        toFieldString={toFieldString}
        members={props.members}
        subject={props.subject}
        resourceId={props.resource?.id}
        resourceType={props.resource?.type}
        // memberQuery={props.memberQuery}
        editorRef={editorRef}
        initialValue={INIT_MESSAGE_HTML}
        currentValue={props.editorState?.html}
        signaturePreview={signaturePreview}
        isInitialMessageHTML
        onResourceIdChange={props.onResourceChange}
        onSubjectChange={props.onSubjectChange}
        onEditorStateChange={props.onEditorStateChange}
        enableVariableReplacement
        enableExpandingEmailEditor
        toggleExpandEditor={toggleExpandEditor}
      />
    </div>
  );
};

export default EmailComposer;
