import * as React from 'react';
import cx from 'classnames';
import { find, map } from 'lodash';

import {
  Modal, SubmitButton, Button, InfiniteList,
} from '@components';
import { Typography } from 'antd';
import { logger } from '@common';
import { UserAvatar } from '@frontend/app/components';

import { useScrollableStatus } from '@frontend/app/hooks/common';
import {
  IMember,
  useSaveMemberRestriction,
  useGetMembersForRestriction,
} from '@frontend/app/hooks';
import { MemberRestrictionInput } from '@frontend/app/types/globalTypes';
import { filterOptions } from './constants';

const { Text } = Typography;
const { useState, useRef, useEffect } = React;

import styles from './ConfirmationModal.scss';

export interface IRuleInfo {
  restriction?: MemberRestrictionInput;
  restrictions?: MemberRestrictionInput[];
  count: number;
}

interface IProps {
  open: boolean;
  onRemoveEmails(): void;
  onRequestClose(): void;
  ruleInfo: IRuleInfo;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const ConfirmationModal: React.FC<IProps> = React.memo((props) => {
  const { ruleInfo } = props;
  const { refetch: getMembers } = useGetMembersForRestriction({
    skip: true,
    variables: {
      restrictions: ruleInfo?.restriction ? [ruleInfo?.restriction] : ruleInfo?.restrictions,
    },
    fetchPolicy: 'no-cache',
  });
  const { loading: saving } = useSaveMemberRestriction();
  const [members, setMembers] = useState<IMember[]>([]);
  const listRef = useRef<HTMLDivElement>(null);
  const { canScrollDown, canScrollUp } = useScrollableStatus(listRef);

  useEffect(() => {
    if (props.open) {
      getMembers().then(({ data: { members } }) => {
        setMembers(members);
      }).catch(logger.error);
    } else {
      setMembers([]);
    }
  }, [props.open, getMembers, setMembers]);

  const option = find(
    filterOptions,
    (o) => o.filterType === ruleInfo?.restriction?.filterType
      && o.filterFieldId === ruleInfo?.restriction?.filterFieldId,
  );

  const domains = map(
    ruleInfo?.restrictions, (restriction) => restriction.filterValue,
  ).join(', ');

  return (
    <Modal
      className={props.className}
      show={props.open}
      onRequestClose={props.onRequestClose}
      width={650}
    >
      <div className={styles.ConfirmationModal}>
        <div className={styles.title}>
          Remove email for
          {' '}
          {ruleInfo?.count}
          {' '}
          member
          {ruleInfo?.count > 1 ? 's' : ''}
          ?
        </div>
        {
          ruleInfo?.restrictions
            ? (
              <div className={styles.description}>
                {ruleInfo?.count}
                {' '}
                member
                {ruleInfo?.count > 1 ? 's' : ''}
                {' '}
                have email addresses that match the restriction you've entered:
                <div className={styles.ruleInfo}>
                  Email ends with "
                  {domains}
                  "
                </div>
                By saving this rule you will delete the email addresses from these members.
              </div>
)
            : (
              <div className={styles.description}>
                {ruleInfo?.count}
                {' '}
                member
                {ruleInfo?.count > 1 ? 's' : ''}
                {' '}
                have email addresses that match the restriction you've entered:
                <div className={styles.ruleInfo}>
                  {option?.displayName}
                  {' '}
                  "
                  {ruleInfo?.restriction?.filterValue}
                  "
                </div>
                By saving this rule you will delete the email addresses from these members.
              </div>
)
        }
        <div className={styles.listContainer}>
          <InfiniteList
            ref={listRef}
            className={styles.memberList}
            passVisibleInfo={false}
          >
            {map(members, (member) => (
              <div
                key={member.id}
                className={styles.memberItem}
              >
                <UserAvatar
                  size={28}
                  className={styles.avatar}
                  profilePicture={member.profilePicture}
                  name={member.name}
                />
                <Text ellipsis className={styles.name}>{member.name}</Text>
                <Text ellipsis className={styles.email}>{member.email}</Text>
              </div>
            ))}
          </InfiniteList>
          <div className={styles.mask}>
            <div className={cx(styles.topFade, {
              [styles.active]: canScrollUp,
            })}
            />
            <div className={cx(styles.bottomFade, {
              [styles.active]: canScrollDown,
            })}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <SubmitButton
            className={styles.button}
            theme="danger"
            label="Save Restriction & Remove Emails"
            submittingLabel="Saving..."
            isSubmitting={saving}
            onClick={props.onRemoveEmails}
          />
          <Button
            label="Cancel"
            onClick={props.onRequestClose}
            theme="info"
          />
        </div>
      </div>
    </Modal>
  );
});
