import * as React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { Button } from '@revfluence/fresh';

import { CoinsIcon } from '@revfluence/fresh-icons/regular/esm';
import { DollarSign } from 'lucide-react';
import HeaderButton from '@frontend/app/refresh-components/HeaderButton';
import styles from './SideBarButton.scss';

interface ISideBarButtonProps extends ButtonProps {
  icon: string;
  text: string;
  refreshUi: boolean;
}

export const SideBarButton: React.FC<Readonly<ISideBarButtonProps>> = (props) => {
  const {
    text,
    refreshUi,
    onClick,
    ...restprops
  } = props;

  return (
    <>
      {refreshUi ? (
        <HeaderButton
          onClick={onClick}
          icon={<DollarSign className="h-4 w-4" />}
        />
      ) : (
        <Button
          {...restprops}
          icon={<CoinsIcon />}
          size="middle"
          className={styles.SideBarButton}
          onClick={onClick}
        >
          {text}
        </Button>
      )}
    </>
  );
};
