import * as React from 'react';

import { useState } from 'react';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { ISocialPost } from '@components';

import {
  Notice, SubmitButton,
} from '@components';
import { validateUrl } from '@frontend/app/utils/url';
import { saveSocialData } from '../saveSocialPostData';

interface IProps {
  onSuccess(post: ISocialPost): void;
}

import styles from './AddPostLink.scss';

interface ISavePostByLinkPayload {
  link: string;
  client_id: string;
  member_ids: number[];
  source: string;
  sub_source: string;
}

const AddPostLink: React.FunctionComponent<IProps> = (props) => {
  const { onSuccess } = props;
  const { backendServerApiEndpoint, clientId, memberId } = useApplication();

  const [values, setValues] = useState({ link: '' });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);

  const handleChange = (event) => {
    const { value } = event.target;
    setValues({ link: value });
  };

  const addPost = () => {
    const url = `${backendServerApiEndpoint}/social_mention`;
    const params: ISavePostByLinkPayload = {
      link: values.link.trim(),
      client_id: clientId,
      member_ids: [parseInt(memberId, 10)],
      source: 'brand',
      sub_source: 'member_social_post_app',
    };

    if (!validateUrl(params.link)) {
      setError('Invalid link. Please provide a valid URL.');
      return;
    }

    setError(null);
    setLoading(true);
    saveSocialData(url, params)
      .then(onSuccess)
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.addPostLink}>
      <div className={styles.header}>
        <h1>New post</h1>
        <p>Keep track of everything happening on social</p>
      </div>
      <div className={styles.form_wrap}>
        <label>Enter link to the post</label>
        <input className={styles.input_standard} type="text" value={values.link} onChange={handleChange} />
        <div className={styles.disclaimer}>Instagram stories (links) cannot be added from this page</div>
        <SubmitButton
          label="Add Post"
          submittingLabel="Adding Post..."
          isSubmitting={loading}
          disabled={loading}
          className={styles.next_button}
          onClick={addPost}
        />
        {error && (<Notice>{error.toString()}</Notice>)}
      </div>
    </div>
  );
};

export default AddPostLink;
