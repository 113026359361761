import { Alert } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';
import {
  Environment,
  getEnvironment,
} from '@frontend/utils/Envionment/Environment';

import styles from './ErrorTrace.scss';

interface IProps {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorInfo: any;
}

export const ErrorTrace: React.FC<IProps> = React.memo(({
  className,
  error,
  errorInfo,
}) => {
  const { user } = useAuth();
  const environment = getEnvironment();

  // Show error on production ONLY for @aspireiq.com users
  const showTrace = error && (
    environment !== Environment.PRODUCTION
    || user?.email?.includes('@aspireiq.com')
  );

  return showTrace && (
    <Alert
      type="error"
      className={cn(className, styles.ErrorTrace)}
      message={error.toString()}
      description={errorInfo.componentStack}
    />
  );
});

ErrorTrace.displayName = 'ErrorTrace';
