/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_okmbj_157 {
  display: flex;
}

._justify-content-space-between_okmbj_161 {
  justify-content: space-between;
}

._tabular-nums_okmbj_165 {
  font-variant-numeric: tabular-nums;
}

._AvatarList_okmbj_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_okmbj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._AvatarList_okmbj_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._AvatarList_okmbj_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._AvatarList_okmbj_169 ::-webkit-scrollbar-track, ._AvatarList_okmbj_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._AvatarList_okmbj_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AvatarList_okmbj_169 ._list_okmbj_282 {
  z-index: 1;
  display: flex;
  flex-flow: row;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
._AvatarList_okmbj_169 ._item_okmbj_290 {
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #fdfdfd;
  cursor: pointer;
  transition: transform cubic-bezier(0.4, 0, 0.2, 1) 0.25s;
}
._AvatarList_okmbj_169 ._item_okmbj_290:hover {
  z-index: 99 !important;
  transform: scale(1.05);
}
._AvatarList_okmbj_169 ._item_okmbj_290._extraSmall_okmbj_301 {
  width: 16;
  height: 16;
  margin-left: calc(-3.2 / 16)rem;
  box-shadow: 0 0 0 calc(1.2 / 16)rem white;
}
._AvatarList_okmbj_169 ._item_okmbj_290._extraSmall_okmbj_301:first-child {
  margin-left: 0;
}
._AvatarList_okmbj_169 ._item_okmbj_290._small_okmbj_310 {
  width: 24;
  height: 24;
  margin-left: calc(-4.8 / 16)rem;
  box-shadow: 0 0 0 calc(1.8 / 16)rem white;
}
._AvatarList_okmbj_169 ._item_okmbj_290._small_okmbj_310:first-child {
  margin-left: 0;
}
._AvatarList_okmbj_169 ._item_okmbj_290._medium_okmbj_319 {
  width: 40;
  height: 40;
  margin-left: calc(-8 / 16)rem;
  box-shadow: 0 0 0 calc(3 / 16)rem white;
}
._AvatarList_okmbj_169 ._item_okmbj_290._medium_okmbj_319:first-child {
  margin-left: 0;
}
._AvatarList_okmbj_169 ._item_okmbj_290._large_okmbj_328 {
  width: 64;
  height: 64;
  margin-left: calc(-12.8 / 16)rem;
  box-shadow: 0 0 0 calc(4.8 / 16)rem white;
}
._AvatarList_okmbj_169 ._item_okmbj_290._large_okmbj_328:first-child {
  margin-left: 0;
}
._AvatarList_okmbj_169 ._item_okmbj_290._extraLarge_okmbj_337 {
  width: 128;
  height: 128;
  margin-left: calc(-25.6 / 16)rem;
  box-shadow: 0 0 0 calc(9.6 / 16)rem white;
}
._AvatarList_okmbj_169 ._item_okmbj_290._extraLarge_okmbj_337:first-child {
  margin-left: 0;
}
._AvatarList_okmbj_169 ._overflowIcon_okmbj_346 > div {
  color: #8f8d91;
}
._AvatarList_okmbj_169 ._addMoreIcon_okmbj_349 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color cubic-bezier(0.4, 0, 0.2, 1) 0.25s;
}
._AvatarList_okmbj_169 ._addMoreIcon_okmbj_349 ._addMoreIconBorder_okmbj_361 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
._AvatarList_okmbj_169 ._addMoreIcon_okmbj_349 ._addMoreIconBorder_okmbj_361 circle {
  fill: transparent;
  stroke: #dadcde;
  stroke-width: 3;
  stroke-dasharray: 10;
  transition: stroke cubic-bezier(0.4, 0, 0.2, 1) 0.25s;
}
._AvatarList_okmbj_169 ._addMoreIcon_okmbj_349:hover {
  background-color: rgba(79, 167, 238, 0.1);
}
._AvatarList_okmbj_169 ._addMoreIcon_okmbj_349:hover ._addMoreIconBorder_okmbj_361 circle {
  stroke: #4fa7ee;
}
._AvatarList_okmbj_169 ._addMoreIcon_okmbj_349 ._addMoreIconPlus_okmbj_381 {
  width: 35% !important;
  height: 35% !important;
  transform: rotate(45deg);
}
._AvatarList_okmbj_169 ._addMoreIcon_okmbj_349 ._addMoreIconPlus_okmbj_381 path {
  fill: #3996e0;
}