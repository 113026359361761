import { OFFER_PAYOUT_TYPE, OFFER_PAYOUT_TYPE_PROMO } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { useMemo } from 'react';
import { isNull, isNumber } from 'lodash';
import { formatInteger, formatMoney } from '@frontend/applications/AffiliatesApp/utils';
import { IPayoutVariant } from '../../OfferForm';

export const getMultiplePayoutLabel = (
    label: string,
    payoutType: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO,
    flatPayout: number | null,
    percentPayout: number | null,
): string | null => {
    flatPayout = Number(flatPayout);
    percentPayout = Number(percentPayout);
    if (!label) return null;
    const isValidNumber = (value: number) => isNumber(value) && !isNaN(value) && value >= 0;
    switch (payoutType) {
        case OFFER_PAYOUT_TYPE.SALE:
        case OFFER_PAYOUT_TYPE_PROMO.SALE: {
            if (isValidNumber(percentPayout)) {
                return `${label} (${formatInteger(percentPayout)}%)`;
            }
            break;
        }
        case OFFER_PAYOUT_TYPE.CONVERSION:
        case OFFER_PAYOUT_TYPE_PROMO.CONVERSION: {
            if (isValidNumber(flatPayout)) {
                return `${label} ($${formatMoney(flatPayout)})`;
            }
            break;
        }
        case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        case OFFER_PAYOUT_TYPE_PROMO.CONVERSION_AND_SALE: {
            if (isValidNumber(flatPayout) && isValidNumber(percentPayout)) {
                return `${label} ($${formatMoney(flatPayout)}+${Math.round(percentPayout)}%)`;
            }
            break;
        }
        case OFFER_PAYOUT_TYPE.CLICK: {
            if (isValidNumber(flatPayout)) {
                return `${label} ($${formatMoney(flatPayout)})`;
            }
            break;
        }
    }
    return null;
};

export const usePayoutLabels = (
    payoutOptions?: IPayoutVariant[],
    migrateToGraphQL?: boolean,
): string[] | [] => useMemo(
    () => {
        if (migrateToGraphQL && payoutOptions.length) {
            return payoutOptions
                .map((payout) => getMultiplePayoutLabel(payout.label, payout.payoutType as OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO, payout.flatPayout, payout.percentPayout))
                .filter((payout) => !isNull(payout));
        }
        return [];
    },
    [payoutOptions, migrateToGraphQL],
);
