import 'css-chunk:src/components/pages/ContentPage/ContentTile.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1iffb_157",
  "justify-content-space-between": "_justify-content-space-between_1iffb_161",
  "tabular-nums": "_tabular-nums_1iffb_165",
  "permissionNotesHeader": "_permissionNotesHeader_1iffb_178",
  "ContentTile": "_ContentTile_1iffb_188",
  "image": "_image_1iffb_197",
  "video": "_video_1iffb_197",
  "permission": "_permission_1iffb_178",
  "media": "_media_1iffb_203",
  "assignButton": "_assignButton_1iffb_209",
  "checkbox": "_checkbox_1iffb_229",
  "search": "_search_1iffb_234",
  "like": "_like_1iffb_241",
  "liked": "_liked_1iffb_249",
  "favoriteOutline": "_favoriteOutline_1iffb_249",
  "favorite": "_favorite_1iffb_249",
  "typeWrapper": "_typeWrapper_1iffb_282",
  "type": "_type_1iffb_282",
  "imageType": "_imageType_1iffb_298",
  "videoType": "_videoType_1iffb_301",
  "lockIcon": "_lockIcon_1iffb_313",
  "permissionText": "_permissionText_1iffb_322",
  "details": "_details_1iffb_328",
  "avatar": "_avatar_1iffb_335",
  "avatarImage": "_avatarImage_1iffb_341",
  "source": "_source_1iffb_345",
  "sourceImage": "_sourceImage_1iffb_358",
  "info": "_info_1iffb_361",
  "name": "_name_1iffb_372",
  "date": "_date_1iffb_389",
  "actions": "_actions_1iffb_392",
  "chat": "_chat_1iffb_398",
  "visit": "_visit_1iffb_408",
  "admin": "_admin_1iffb_417",
  "TagList": "_TagList_1iffb_432",
  "addTag": "_addTag_1iffb_438",
  "plusIcon": "_plusIcon_1iffb_441",
  "moreTag": "_moreTag_1iffb_451",
  "ellipsisIcon": "_ellipsisIcon_1iffb_454",
  "addTagText": "_addTagText_1iffb_464",
  "tag": "_tag_1iffb_476",
  "visionTag": "_visionTag_1iffb_493",
  "removeTag": "_removeTag_1iffb_496",
  "removeTagImage": "_removeTagImage_1iffb_505",
  "show": "_show_1iffb_1"
};