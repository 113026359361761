/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_t8vt6_157 {
  display: flex;
}

._justify-content-space-between_t8vt6_161 {
  justify-content: space-between;
}

._tabular-nums_t8vt6_165 {
  font-variant-numeric: tabular-nums;
}

._DatePicker_t8vt6_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_t8vt6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._DatePicker_t8vt6_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._DatePicker_t8vt6_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._DatePicker_t8vt6_169 ::-webkit-scrollbar-track, ._DatePicker_t8vt6_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._DatePicker_t8vt6_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._DatePicker_t8vt6_169 ._dateInput_t8vt6_235 {
  padding: 0 1rem;
  width: 100%;
  height: 2.5rem;
  min-width: 11.5625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  line-height: 1.0625rem;
  color: gray;
  border-radius: 6.25rem;
  background-color: #eff5f9;
  will-change: background-color;
  transition: background-color 0.1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._DatePicker_t8vt6_169 ._dateInput_t8vt6_235._dateSelected_t8vt6_253 {
  color: #1a1818;
}
._DatePicker_t8vt6_169 ._dateInput_t8vt6_235:hover {
  color: #4fa7ee;
  background-color: #e6edf2;
}
._DatePicker_t8vt6_169 ._dateInput_t8vt6_235:active {
  color: #3996e0;
  background-color: #eff5f9;
}
._DatePicker_t8vt6_169 ._dateInput_t8vt6_235 ._icon_t8vt6_264 {
  margin-right: 0.3125rem;
}
._DatePicker_t8vt6_169 ._dateInput_t8vt6_235 ._formattedDate_t8vt6_267 {
  padding-right: 20px;
}
._DatePicker_t8vt6_169 ._dateInput_t8vt6_235._hasError_t8vt6_270 {
  border: 0.0625rem solid #f96b77 !important;
  background-color: #f6e4e5 !important;
}
._DatePicker_t8vt6_169 ._close_t8vt6_274 {
  fill: #8f8d91;
}
._DatePicker_t8vt6_169 ._errorMessage_t8vt6_277 {
  margin-top: 0.4375rem;
  color: #f1515f;
  font-size: 0.8125rem;
}

._Popover_t8vt6_283 ._content_t8vt6_283 {
  padding: 0.5rem 0;
  width: 20.625rem;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 {
  margin-bottom: 2rem;
  padding: 0 1.3125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._action_t8vt6_294 {
  flex-shrink: 0;
  display: flex;
  width: 0.875rem;
  cursor: pointer;
  color: #8f8d91;
  will-change: color;
  transition: color 0.1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._action_t8vt6_294:hover {
  color: #3996e0;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._action_t8vt6_294:active {
  color: #4fa7ee;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._prevYear_t8vt6_309 {
  margin-right: 2.25rem;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._prevYear_t8vt6_309 ._icon_t8vt6_264 {
  transform: rotate(180deg);
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._prevMonth_t8vt6_315 ._icon_t8vt6_264 {
  transform: rotate(90deg);
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._label_t8vt6_318 {
  flex: 1;
  user-select: none;
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0px 0.0625rem;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._nextMonth_t8vt6_326 ._icon_t8vt6_264 {
  transform: rotate(-90deg);
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._yearAndMonth_t8vt6_287 ._nextYear_t8vt6_329 {
  margin-left: 2.25rem;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._weekdaysLabel_t8vt6_332 {
  margin-bottom: 0.75rem;
  padding: 0 0.375rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  user-select: none;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._weekdaysLabel_t8vt6_332 ._labelColumn_t8vt6_340 {
  flex-shrink: 0;
  flex-basis: 14.2%;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: bold;
  letter-spacing: 0.09375rem;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._calendarDates_t8vt6_349 {
  padding: 0 0.375rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._calendarDates_t8vt6_349 ._calendarDate_t8vt6_349 {
  flex-shrink: 0;
  flex-basis: 14.2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 1.625rem;
  cursor: pointer;
  user-select: none;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.01875rem;
  will-change: color, background-color;
  transition: all 0.1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._calendarDates_t8vt6_349 ._calendarDate_t8vt6_349._disabled_t8vt6_372 {
  pointer-events: none;
  color: #bdbdbd;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._calendarDates_t8vt6_349 ._calendarDate_t8vt6_349:hover {
  color: #3996e0;
  background-color: #eff5f9;
}
._Popover_t8vt6_283 ._content_t8vt6_283 ._calendarDates_t8vt6_349 ._calendarDate_t8vt6_349._active_t8vt6_380 {
  color: #fdfdfd;
  background-color: #3996e0;
}