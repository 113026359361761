import { useMemo } from 'react';

import {
  IAffiliateLinksFormValues,
  IShopifyPromoCodeFormValues,
  OfferFormModes,
  TDisabledMap,
  TFormValues,
} from '@affiliates/components/OfferForm/types';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { IShopifyCredentials } from '@frontend/applications/AffiliatesApp/hooks';

const getAffiliateLinkDisabledFieldMap = (
  mode: OfferFormModes,
  hasAffiliateLinks: boolean,
  isDeleted: boolean,
  isExpired: boolean,
  canEditConversionType: boolean,
): TDisabledMap<IAffiliateLinksFormValues> => {
  if (mode === OfferFormModes.Edit && !isDeleted && hasAffiliateLinks) {
    // disable the fields where the flags are true
    return {
      conversionTrackingType: canEditConversionType !== undefined ? !canEditConversionType : false,
      conversionType: true,
      description: false,
      expirationDate: false,
      flatPayout: true,
      imageUrl: false,
      name: false,
      payoutType: true,
      percentPayout: true,
      status: isExpired,
      url: false,
      utmSource: false,
      utmMedium: false,
      utmCampaign: false,
      utmContent: false,
      utmTerm: false,
      handleFormAsyncActions: false,
      isAdvanceUrlEnable: false,
      utmFields: false,
      payoutOptions: false,
      customUTMParameters: false,
      multipleDomain: false,
      creatorDeeplink: false,
      allowMultipleConversions: false,
      thirdPartyTracking: false,
      isThirdPartyIntegration: false,
    };
  }
  return {
    conversionTrackingType: false,
    conversionType: false,
    description: false,
    expirationDate: false,
    flatPayout: false,
    imageUrl: false,
    name: false,
    payoutType: false,
    percentPayout: false,
    status: false,
    url: false,
    utmSource: false,
    utmMedium: false,
    utmCampaign: false,
    utmContent: false,
    utmTerm: false,
    customUTMParameters: false,
    handleFormAsyncActions: false,
    isAdvanceUrlEnable: false,
    utmFields: false,
    payoutOptions: false,
    multipleDomain: false,
    creatorDeeplink: false,
    allowMultipleConversions: false,
    thirdPartyTracking: false,
    isThirdPartyIntegration: false,
  };
};

const getShopifyPromoCodeDisabledFieldMap = (
  mode: OfferFormModes,
  hasPromoCodes: boolean,
  isDeleted: boolean,
  shopifyCredentials: IShopifyCredentials,
): TDisabledMap<IShopifyPromoCodeFormValues> => {
  if (mode === OfferFormModes.Edit && !isDeleted && hasPromoCodes) {
    // disable the fields where the flags are true
    return {
      codeSuffix: true,
      // conversionType is not actually used but required
      conversionType: false,
      description: false,
      flatPayout: true,
      groupName: false,
      imageUrl: false,
      name: false,
      payoutType: true,
      percentPayout: true,
      prefixType: true,
      priceRuleAmount: true,
      priceRuleType: true,
      specialLimitNewCustomersOnly: true,
      specialLimitOnePerSale: true,
      specialLimitUsageCapAmount: true,
      specialLimitUsageCapEnabled: true,
      usageLimitAmount: true,
      usageLimitRule: true,
      productCollections: true,
      codePrefix: true,
      purchaseType: true,
      status: true,
      activeTime: true,
      activeDate: true,
      endDate: true,
      endTime: true,
      offerCodePurchaseRestrictionsRule: true,
      recurringCycleLimitAmount: true,
      isEndDateEnable: false,
      isPrefixSelected: false,
      isSuffixSelected: false,
      multiShopifyEnabled: false,
      payoutOptions: false,
      handleFormAsyncActions: false,
      clientsForSync: true,
      connectedAdvertiserForSync: false,
      isSameDiscountMultipleShopify: false,
      isMultipleShopifySyncEnabled: false,
      allClientForSync: false,
      isNewFlow: false,
      isUngrouped: false,
      lockEditing: false,
        productDiscounts: false,
        shippingDiscounts: false,
        orderDiscounts: false,
    };
  }
  return {
    codeSuffix: false,
    // conversionType is not actually used but required
    conversionType: false,
    description: false,
    flatPayout: false,
    groupName: false,
    imageUrl: false,
    name: false,
    payoutType: false,
    percentPayout: false,
    prefixType: false,
    priceRuleAmount: false,
    priceRuleType: false,
    specialLimitNewCustomersOnly: !shopifyCredentials?.hasSegmentationScopes,
    specialLimitOnePerSale: false,
    specialLimitUsageCapAmount: false,
    specialLimitUsageCapEnabled: false,
    usageLimitAmount: false,
    usageLimitRule: false,
    productCollections: false,
    codePrefix: false,
    purchaseType: true,
    status: true,
    activeTime: true,
    activeDate: true,
    endDate: true,
    endTime: true,
    offerCodePurchaseRestrictionsRule: true,
    recurringCycleLimitAmount: true,
    isEndDateEnable: false,
    isPrefixSelected: false,
    isSuffixSelected: false,
    multiShopifyEnabled: false,
    clientsForSync: false,
    payoutOptions: false,
    handleFormAsyncActions: false,
    connectedAdvertiserForSync: false,
    isSameDiscountMultipleShopify: false,
    isMultipleShopifySyncEnabled: false,
    allClientForSync: false,
    isNewFlow: false,
    isUngrouped: false,
    lockEditing: false,
      productDiscounts: false,
      shippingDiscounts: false,
      orderDiscounts: false,
  };
};

// export as a separate helper function for testing
export const getDisabledFieldMap = (
  source: OFFER_SOURCE,
  mode: OfferFormModes,
  hasAffiliateLinks: boolean,
  isDeleted: boolean,
  isExpired: boolean,
  shopifyCredentials?: IShopifyCredentials,
  canEditConversionType?: boolean,
): TDisabledMap<TFormValues> => {
  switch (source) {
    case OFFER_SOURCE.SHOPIFY:
      return getShopifyPromoCodeDisabledFieldMap(mode, hasAffiliateLinks, isDeleted, shopifyCredentials);
    case OFFER_SOURCE.TUNE:
      return getAffiliateLinkDisabledFieldMap(mode, hasAffiliateLinks, isDeleted, isExpired, canEditConversionType);
  }
};

export const useDisabledMap = (
  source: OFFER_SOURCE,
  mode: OfferFormModes,
  hasAffiliateLinks: boolean,
  isDeleted: boolean,
  isExpired: boolean,
  shopifyCredentials?: IShopifyCredentials,
  canEditConversionType?: boolean,
): TDisabledMap<TFormValues> => useMemo(
  () => getDisabledFieldMap(source, mode, hasAffiliateLinks, isDeleted, isExpired, shopifyCredentials, canEditConversionType),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [hasAffiliateLinks, isDeleted, isExpired, mode, source],
);
