import {
  QueryHookOptions,
  QueryResult,
  useQuery,
} from '@apollo/client';

import { TERMS_DASHBOARD_QUERY } from '@frontend/app/queries';
import {
  TermsDashboardQuery,
  TermsDashboardQueryVariables,
} from '@frontend/app/queries/types/TermsDashboardQuery';

type IOptions = QueryHookOptions<TermsDashboardQuery, TermsDashboardQueryVariables>;

export const useTermsDashboardQuery = (options: IOptions): QueryResult<TermsDashboardQuery> => (
  useQuery<TermsDashboardQuery, TermsDashboardQueryVariables>(TERMS_DASHBOARD_QUERY, options)
);
