import { TemplateTransportType } from './TemplateTransportType';
import { TemplateType } from './TemplateType';

export interface IFindMessageTemplateOptions {
  clientId?: string;
  programIds?: number[];
  programNonSpecificTemplates?: boolean;
  types?: TemplateType[];
  transportTypes?: TemplateTransportType[];
}
