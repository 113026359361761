import * as React from 'react';
import { Input } from 'antd';

import styles from './TextEqual.scss';

const { useImperativeHandle, useRef } = React;
interface IProps {
  value: string;
  onChange?(value: string);
}

export interface ITextEqualHandles {
  focus(): void;
}

const TextEqualComponent: React.RefForwardingComponent<ITextEqualHandles, IProps> = (props, ref) => {
  const {
    value,
    onChange,
  } = props;

  const inputRef = useRef<React.ElementRef<typeof Input>>();
  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
  }));

  return (
    <Input
      className={styles.TextEqual}
      size="large"
      autoFocus
      value={value}
      onChange={(event) => onChange(event.currentTarget.value)}
      ref={inputRef}
    />
  );
};
export const TextEqual = React.forwardRef(TextEqualComponent);
