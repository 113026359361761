import gql from 'graphql-tag';

export const HOME_PAGE_CLIENT_STATUS_QUERY = gql`
  query HomePageClientStatusQuery {
    clientHomePageStatsStatus {
      isInstagramConnected
      isSalesTrackingSetup
    }
  }
`;
