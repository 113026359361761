import {
  filter,
  isEmpty,
  isNaN,
  keyBy,
  map,
} from 'lodash';
import * as React from 'react';

import {
  TProject,
  TWorkItem,
} from '@frontend/app/containers/Projects/types';
import { useMemberSearchQuery } from '@frontend/app/hooks';
import {
  MemberSearchQuery_members as IMember,
  MemberSearchQueryVariables,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { ProgramMembershipStatusType } from '@frontend/app/types/MemberSearch';

import { ProjectsPageState } from '../constants';

const { useMemo } = React;

interface IOptions {
  pageState: ProjectsPageState;
  projectId: TProject['id'];
  workItems: TWorkItem[];
}

interface IUseMembersSearch {
  isLoading: boolean;
  members: IMember[];
  memberById: { [key: number]: IMember };
  refetchMembers: () => Promise<unknown>;
}

const LIMIT = 50;

export const useProjectMembersSearch = ({
  pageState,
  projectId,
  workItems,
}: IOptions): IUseMembersSearch => {
  const memberSearchQuery = useMemo(
    (): MemberSearchQueryVariables => {
      if (
        pageState === ProjectsPageState.Applicants
        && projectId
      ) {
        return {
          query: {
            programIds: [projectId],
            programStatus: ProgramMembershipStatusType.NEW,
          },
          take: LIMIT,
          skip: 0,
        };
      }

      if (
        (pageState === ProjectsPageState.AllInProgress || pageState === ProjectsPageState.Task || pageState === ProjectsPageState.Worklet)
        && workItems?.length > 0
      ) {
        return {
          query: {
            includeMemberIds: filter(
              map(workItems, (workItem) => parseInt(workItem.data?.memberId, 10)),
              (memberId) => !isNaN(memberId),
            ),
          },
          take: workItems.length,
          skip: 0,
        };
      }

      return memberSearchQuery;
    },
    [pageState, projectId, workItems],
  );

  const {
    data: {
      members = undefined,
    } = {},
    loading,
    refetch: refetchMembers,
  } = useMemberSearchQuery({
    variables: { ...memberSearchQuery },
    skip: isEmpty(memberSearchQuery),
  });
  const memberById = useMemo(
    () => keyBy(members, (member) => member.id),
    [members],
  );

  return {
    isLoading: loading,
    members,
    memberById,
    refetchMembers,
  };
};
