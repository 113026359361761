import * as React from 'react';
import cx from 'classnames';

import {
 InfoIcon, CheckCircleIcon, WandIcon, AlertIcon,
} from '@components';

import styles from './Notice.scss';

type INoticeType = 'info' | 'success' | 'feature' | 'alert' | 'error' | 'disabled';

interface IProps {
  type?: INoticeType;
  showDivider?: boolean;
  className?: string;
  icon?: JSX.Element;
}

type TDefaultProp = 'type' | 'showDivider' | 'className' | 'icon';

/**
 * @class
 * @extends {React.PureComponent}
 */
export class Notice extends React.PureComponent<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    type: 'info',
    showDivider: false,
    className: null,
    icon: null,
  };

  /**
   * @inheritdoc
   */
  public render() {
    const {
 type, showDivider, children, className, icon,
} = this.props;

    const getIcon = () => {
      if (icon !== null) {
        return icon;
      } else {
        switch (type) {
          case 'info':
          case 'disabled':
            return <InfoIcon />;
          case 'success':
            return <CheckCircleIcon />;
          case 'feature':
            return <WandIcon />;
          case 'error':
          case 'alert':
            return <AlertIcon />;
        }
      }
    };

    return (
      <div
        className={cx(styles.Notice, className, styles[type], {
          [styles.showDivider]: showDivider,
        })}
      >
        <div className={styles.icon}>{getIcon()}</div>
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
}
