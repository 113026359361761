import gql from 'graphql-tag';

import { GUIDELINE_FRAGMENT } from './fragments/GuidelineFragment';

export const DUPLICATE_CONTENT_GUIDELINES_INTO_PROJECT_MUTATION = gql`
  mutation DuplicateContentGuidelinesIntoProject($guidelinesId: Int!, $programId: Int!, $isCopy: Boolean) {
    guidelines: duplicateContentGuidelinesIntoProject(guidelinesId: $guidelinesId, programId: $programId, isCopy: $isCopy) {
      id
      type
      label
      networkType
      priority
      attachments {
        filename
        url
        type
      }
      guidelines {
        ...GuidelineFragment
      }
    }
  }
  ${GUIDELINE_FRAGMENT}
`;
