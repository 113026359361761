import * as React from 'react';
import numeral from 'numeral';
import { format } from 'date-fns';
import { isNumber } from 'lodash';

import { Group } from '@vx/group';
import { curveCatmullRom as curveType } from '@vx/curve';
import { LinearGradient } from '@vx/gradient';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { AreaClosed, Line } from '@vx/shape';
import { scaleTime, scaleLinear } from '@vx/scale';
import { extent, bisector } from 'd3-array';
import { localPoint } from '@vx/event';
import { withTooltip, Tooltip } from '@vx/tooltip';

import { numTicksForWidth, numTicksForHeight } from '../utils';

const { useMemo } = React;
import styles from './AreaChart.scss';

interface IData {
  x: Date;
  y: number;
}
export interface IAreaChartProps {
  data: IData[];

  // Display
  width?: number;
  height?: number;
  margin?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  colorGradient?: {
    from: string;
    to: string;
  };
  showCircle?: boolean;
  lineType?: 'dotted' | 'area';
  tooltipTopOffset?: number;

  // Override ticks instead of calculating it from width and height
  xTicks?: number;
  yTicks?: number;
  xTickValues?: Date[];
  yTickValues?: number[];

  // Text formatting
  yAxisNumeralFormat?: string;
  xAxisDateFormat?: string;

  /**
   * Tooltip text layout
   * y - show data detail based on the y axis
   * x - show data detail based on the x axis
   * yx - show data details based on the y and x axis
   * xy - show data details based on the x and y axis
   */
  tooltipTextLayout?: 'y' | 'x' | 'yx' | 'xy';

  // injected by withTooltip
  hideTooltip?(): void;
  showTooltip?(props: {
    tooltipData: IData;
    tooltipLeft: number;
    tooltipTop: number;
  }): void;
  tooltipData?: IData;
  tooltipLeft?: number;
  tooltipTop?: number;
  tooltipOpen?: boolean;
  updateTooltip?(): void;
}

const bisectDate = bisector((d: IData) => new Date(d.x)).left;

export const AreaChart: React.FunctionComponent<IAreaChartProps> = React.memo(
  (props) => {
    const {
      data,
      width,
      height,
      margin: marginProp,
      colorGradient = {
        from: 'rgba(57, 150, 224, 0.6)',
        to: 'rgba(255, 233, 217, 0.2)',
      },
      showCircle = false,
      lineType = 'dotted',
      yAxisNumeralFormat = '0.[0]a',
      xAxisDateFormat = 'MM/dd',
      tooltipData,
      tooltipLeft,
      tooltipTop,
      tooltipTopOffset = 0,
      xTicks,
      yTicks,
      xTickValues,
      yTickValues,
      tooltipTextLayout = 'y',
    } = props;

    // Let margin prop override individual margin values
    const margin = {
      top: 0,
      right: 30,
      bottom: 60,
      left: 80,
      ...marginProp,
    };

    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    // scales
    const xScale = useMemo(
      () =>
        scaleTime({
          range: [0, xMax],
          domain: extent(data, (d: IData) => d.x),
        }),
      [data, xMax],
    );
    const yScale = useMemo(
      () =>
        scaleLinear({
          range: [yMax, 0],
          domain: [0, Math.max(...data.map((d) => d.y))],
          nice: true,
        }),
      [data, yMax],
    );
    const updateTooltipPosition = (event) => {
      const { x } = localPoint(event);
      const x0: Date = xScale.invert(x - margin.left);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d0;
      if (d1 && d1.x) {
        d = x0.getTime() - d0.x.getTime() > d1.x.getTime() - x0.getTime()
            ? d1
            : d0;
      }

      props.showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: yScale(d.y),
      });
    };

    const tooltipDataFormatted = useMemo(() => {
      if (!tooltipData) {
        return;
      }
      const tX = format(tooltipData.x, xAxisDateFormat).toUpperCase();
      const tY = numeral(tooltipData.y)
        .format(yAxisNumeralFormat)
        .toUpperCase();
      switch (tooltipTextLayout) {
        case 'y': return tY;
        case 'x': return tX;
        case 'yx': return `${tY} - ${tX}`;
        case 'xy': return `${tX} - ${tY}`;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tooltipData]);

    return (
      <>
        <svg className={styles.AreaChart} width={width} height={height}>
          <LinearGradient
            id="linear-gradient-area"
            from={colorGradient.from}
            to={colorGradient.to}
          />
          <Group top={margin.top} left={margin.left}>
            <AreaClosed
              data={data}
              x={(d: IData) => xScale(d.x)}
              y0={yMax}
              y1={(d: IData) => yScale(d.y)}
              strokeWidth={2}
              stroke="transparent"
              fill="url(#linear-gradient-area)"
              curve={curveType}
              onMouseMove={updateTooltipPosition}
              onMouseLeave={() => props.hideTooltip()}
            />
          </Group>
          {tooltipData && (
            <g>
              {lineType === 'dotted' && (
                <Line
                  from={{ x: tooltipLeft, y: 0 + margin.top }}
                  to={{ x: tooltipLeft, y: yMax + margin.top }}
                  stroke="rgba(57, 150, 224, 1.0)"
                  strokeWidth={2}
                  style={{ pointerEvents: 'none' }}
                  strokeDasharray="2,2"
                />
              )}
              {lineType === 'area' && (
                <Line
                  from={{ x: tooltipLeft, y: 0 + margin.top }}
                  to={{ x: tooltipLeft, y: yMax + margin.top }}
                  stroke="rgba(255, 255, 255, 0.4)"
                  strokeWidth={32}
                  style={{ pointerEvents: 'none' }}
                />
              )}
              {showCircle && (
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop + margin.top}
                  r={8}
                  fill="rgba(57, 150, 224, 1.0)"
                  stroke="rgba(255, 255, 255, 1.0)"
                  strokeWidth={4}
                  style={{ pointerEvents: 'none' }}
                />
              )}
            </g>
          )}
          <Group left={margin.left}>
            <AxisLeft
              top={margin.top}
              left={0}
              scale={yScale}
              numTicks={isNumber(yTicks) ? yTicks : numTicksForHeight(height)}
              tickValues={yTickValues}
              stroke="transparent"
              tickStroke="transparent"
              tickLabelProps={() => ({
                fill: '#4F4F4F',
                textAnchor: 'end',
              })}
              tickFormat={(value: number) => (
                numeral(value)
                  .format(yAxisNumeralFormat)
                  .toUpperCase()
              )}
            />
            <AxisBottom
              top={height - margin.bottom}
              left={0}
              scale={xScale}
              numTicks={isNumber(xTicks) ? xTicks : numTicksForWidth(width)}
              tickValues={xTickValues}
              stroke="transparent"
              tickStroke="transparent"
              tickLabelProps={() => ({
                fill: '#4F4F4F',
                textAnchor: 'middle',
              })}
              tickFormat={(value: Date) => (
                format(value, xAxisDateFormat).toUpperCase()
              )}
            />
          </Group>
        </svg>
        {tooltipData && (
          <Tooltip
            className={styles.tooltip}
            top={tooltipTop + 16 + tooltipTopOffset}
            left={tooltipLeft}
          >
            {tooltipDataFormatted}
          </Tooltip>
        )}
      </>
    );
  },
);

AreaChart.displayName = 'AreaChart';

export default withTooltip(AreaChart);
