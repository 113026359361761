import * as React from 'react';
import {
  Card,
  Col,
  Row,
  Skeleton,
} from '@revfluence/fresh';
import cx from 'classnames';

import styles from './ContentCard.scss';

interface IProps {
  size: 'small' | 'large';
}

export const LoadingCard: React.FC<IProps> = React.memo((props) => {
  const { size } = props;

  const cardClassName = cx({
    [styles.ContentCard]: true,
    [styles.loading]: true,
    [styles.smallContentCard]: size === 'small',
    [styles.largeContentCard]: size === 'large',
  });

  return (
    <Card
      cover={(
        <div className={styles.loadingImageWrapper}>
          <Skeleton.Image
            active
          />
        </div>
      )}
      className={cardClassName}
    >
      <div className={styles.cardBody}>
        <Row>
          <Col flex="auto">
            <Skeleton
              active
              paragraph={false}
              title
            />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">
            <Skeleton
              active
              paragraph={false}
              title
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
});
LoadingCard.displayName = 'LoadingCard';
