import * as React from 'react';
import { map, size } from 'lodash';
import { FileIcon, LazyImage } from '@components';

import { Deliverable } from './Deliverable';

import styles from './FileDeliverable.scss';

interface IProps {
  files: string[];
  dueDate: Date;
}

export const FileDeliverable: React.FC<IProps> = React.memo((props) => (
  <Deliverable icon={<FileIcon />} header={`You uploaded ${size(props.files)} files`} dueDate={props.dueDate}>
    <div className={styles.FileDeliverable}>
      {map(props.files, (file, idx) => (
        <LazyImage key={`${idx}-${file}`} src={file} className={styles.img} />
          ))}
    </div>
  </Deliverable>
));

FileDeliverable.displayName = 'FileDeliverable';
