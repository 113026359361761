import {
  Badge,
  ClickableStatistic,
} from '@revfluence/fresh';
import { ITaskUI } from '@frontend/app/utils/task';

import * as React from 'react';
import styles from './WorkItemItemBox.scss';

interface WorkItemItemBoxPros {
  name: string;
  count: number;
  isActive: boolean;
  hasError: boolean;
  hasNotification: boolean;
  isTransient?: boolean;
  taskUI?: ITaskUI;
  onClick(): void;
}

export const WorkItemItemBox: React.FC<WorkItemItemBoxPros> = ({
  name, isTransient, taskUI, count, isActive, hasError, hasNotification, onClick,
}) => {
  const showTask = (
    !taskUI
    || !isTransient
    || (isTransient && count > 0)
  );
  if (!showTask) {
    return null;
  }

  return (
    <ClickableStatistic
      onClick={onClick}
      title={name}
      value={count}
      isActive={isActive}
      valueStyle={{ color: hasError ? 'red' : undefined }}
      suffix={hasNotification ? <div className={styles.taskNotificationDot}><Badge dot /></div> : undefined}
    />
  );
};
