/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';
import xss from 'xss';
import moment from 'moment';
import {
  Select,
  Alert,
  Input,
  Button,
  Form,
  useForm,
  FormItem,
  FormInstance,
  DatePicker,
  Row,
} from '@revfluence/fresh';
import { startOfDay } from 'date-fns';
import {
  chain,
  each,
  filter,
  find,
  get,
  includes,
  isArray,
  isEmpty,
  isUndefined,
  map,
  reduce,
  some,
  sortBy,
  toString,
} from 'lodash';
import { Space } from 'antd';
import { Rule } from 'antd/lib/form';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { MemberAgentUpsertInput } from '@frontend/app/types/globalTypes';
import { EventName } from '@common';
import {
 Checkbox, SpinnerIcon, CheckCircleIcon, emailRegex,
} from '@components';

import { TalentAgents } from '@frontend/app/containers/MemberDetail/components/MemberDetails/components';
import { useEventContext } from '@frontend/app/context';
import { useParsedRouterSearch } from '@frontend/app/hooks';

import { FieldType } from '@frontend/app/types/Fields';
import { useMessagingContext } from '@frontend/hooks';
import { SUPPORT_EMAIL } from '@frontend/app/components/ApplicationPageTemplates/CustomizedTemplate/constants';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import { localToUTC } from '@frontend/app/utils';

import { safeJSONParse } from '@frontend/app/utils/strings';

import { RichTextVisualizer } from '@frontend/app/components/RichTextEditor/Visualizer';
import {
  GetMemberJoinPageQuery,
  GetMemberJoinPageQueryVariables,
  GetMemberJoinPageQuery_data_fields as IMemberFields,
} from '@frontend/app/queries/types/GetMemberJoinPageQuery';
import {
  MemberJoinProgramMutation,
  MemberJoinProgramMutationVariables,
} from '@frontend/app/queries/types/MemberJoinProgramMutation';
import {
  GET_MEMBER_JOIN_PAGE,
  MEMBER_JOIN_PROGRAM,
} from '@frontend/app/queries';
import { RecaptchaContextProvider } from './context/RecaptchaContext';
import { IInstagramInsightsHandles, InstagramInsights } from './fields/InstagramInsights/InstagramInsights';
import { useRecaptcha } from './hooks/useRecaptcha';

import { AlreadySubmittedAlert } from './AlreadySubmittedAlert';

import { ProjectApplicationPageTemplateName } from '../../Projects/applicationPageUtils';

import styles from './SignupForm.scss';

const { Option } = Select;
const {
  useCallback, useEffect, useMemo, useRef, useState,
} = React;

const INSTAGRAM_FIELD = 'Instagram';
const INSTAGRAM_INSIGHTS_FIELD = 'Insights Linked';
const SOCIAL_FIELDS_WITH_HANDLER = [INSTAGRAM_FIELD, 'TikTok', 'Pinterest', 'Twitter'];

export enum ApplicationPageType {
  Detailed = 'Detailed',
  Simple = 'Simple',
}

declare global {
  interface Window { grecaptcha: any }
}

interface IFormValues {
  [fieldName: string]: any;
}

interface IProperties {
  primaryColor: string;
}

interface IRouteParams {
  customLandingPagePath: string;
}

type TField = Omit<IMemberFields, '__typename'>;

interface IProps {
  className?: string;
  clientId?: string;
  memberId?: number;
  utmSource?: string;
  fields?: TField[];
  disableSubmit?: boolean;
  isLandingPageV2?: boolean;
  isPreview?: boolean;
  confirmationColor?: string;
  supportEmail?: string;
  hasUnpaidOffer?: boolean;
  projectId?: number;
  projectTitle?: string;
  unpaidLabel?: string;
  unpaidDescription?: string;
  backgroundColor?: string;
  isBackgroundTransparent?: boolean;
  applicationPageType?: ApplicationPageType;
  useFreshComponents?: boolean;
  applyLabel?: string;
}

const helmetHead = {
  script: [{
    type: 'text/javascript',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.2.10/iframeResizer.contentWindow.min.js',
  }],
};

const DEFAULT_CONFIRMATION_COLOR = '#1a1818';
const DEFAULT_BACKGROUND_COLOR = '#fdfdfd';

const SignupForm: React.FC<IProps> = React.memo((props) => {
  const [form] = useForm();
  const signUpFormRef = useRef();
  const history = useHistory();
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [acceptedUnpaidCollaboration, setAcceptedUnpaidCollaboration] = useState<boolean>(false);
  const [showUnpaidCollaborationErrorMessage, setShowUnpaidCollaborationErrorMessage] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [successful, setSuccessful] = useState<boolean>(false);
  const [firstTimeFieldClicked, setFirstTimeFieldClicked] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [talentAgents, setTalentAgents] = useState<MemberAgentUpsertInput[]>([]);
  const [hasTalentAgents, setHasTalentAgents] = useState(false);

  const {
    showErrorMessage,
  } = useMessagingContext();

  const addEvent = useEventContext();

  // Custom fields
  const igInsightsFieldRef = useRef<IInstagramInsightsHandles>();

  const { customLandingPagePath } = useParams<IRouteParams>();

  const confirmationColor = props.confirmationColor || DEFAULT_CONFIRMATION_COLOR;
  const backgroundColor = props.backgroundColor || DEFAULT_BACKGROUND_COLOR;

  const {
    redirectUri,
    clientId = props.clientId,
    memberId = props.memberId,
    utmSource = props.utmSource,
    properties: jsonProperties,
    token,
  } = useParsedRouterSearch();

  const properties = useMemo<IProperties>(() => {
    try {
      return JSON.parse(jsonProperties);
    } catch (err) {
      return {
        primaryColor: confirmationColor,
      };
    }
  }, [confirmationColor, jsonProperties]);

  const applicationPageType = props.applicationPageType || ApplicationPageType.Detailed;

  const context = useMemo(() => (
    {
      headers: {
        'requested-client-id': clientId,
      },
    }
  ), [clientId]);

  const memberIdString = memberId as string;

  const {
    data: {
      data = null,
    } = {},
    loading,
  } = useQuery<GetMemberJoinPageQuery, GetMemberJoinPageQueryVariables>(
    GET_MEMBER_JOIN_PAGE,
    {
      variables: {
        customLandingPagePath: encodeURIComponent(customLandingPagePath),
        memberId: memberIdString,
        token,
        utmSource,
      },
      context,
      skip: !!props.fields, // use provided member fields instead of those associated with program.
    },
  );

  /** Destructuring props here so we can use `data` */
  const {
    applyLabel = get(data, 'onboardingTemplateConfig.application.apply_label'),
    hasUnpaidOffer = get(data, 'hasUnpaidOffer', false),
    unpaidLabel = get(data, 'onboardingTemplateConfig.application.unpaidLabel', ''),
    unpaidDescription = get(data, 'onboardingTemplateConfig.application.unpaidDescription', ''),
  } = props;

  const unorderedMemberFields = props.fields || data?.fields;
  const { alreadySubmitted, resolvedFields: memberResolvedFields } = data || {};

  const shouldOrderFields = useMemo(() => {
    const hasProperty = some(unorderedMemberFields, (field) => !isUndefined(field.order));

    return hasProperty || props.isLandingPageV2;
  }, [props.isLandingPageV2, unorderedMemberFields]);

  const memberFields = useMemo(() => (
    shouldOrderFields
      ? sortBy(unorderedMemberFields, ['order', 'id'], ['DESC', 'ASC'])
      : unorderedMemberFields
  ), [shouldOrderFields, unorderedMemberFields]);

  const exitStateRef = useRef({
    isPreview: props.isPreview,
    memberFields,
    fieldValues: {},
    formSubmitted,
  });

  const applicationPageSessionRef = useRef({
    startTime: null,
  });

  useEffect(() => {
    exitStateRef.current = {
      isPreview: props.isPreview,
      memberFields,
      fieldValues: {},
      formSubmitted,
    };
  }, [props.isPreview, memberFields, formSubmitted]);

  const eventTrackingOnPageUnload = () => {
    const exitState = exitStateRef.current;

    if (exitState.isPreview) {
      return;
    }

    // capture how long user stayed on the page
    const session_time = (new Date().getTime() - applicationPageSessionRef.current.startTime) / 1000;
    addEvent(EventName.ApplicationPageFormSession, {
      session_time: Math.round(session_time * 10) / 10,
      project_id: props.projectId,
      project_name: props.projectTitle,
      utm_source: utmSource,
      application_page_type: applicationPageType.toString(),
      application_template_name: data.applicationPageTemplateName || ProjectApplicationPageTemplateName.Customizable,
    });

    if (exitState.formSubmitted) {
      return;
    }

    // capture field values as fieldName:value and those that were left empty as a list of fieldNames
    const fieldsWithValues = reduce(
      exitState.memberFields,
      (acc, field) => (isEmpty(exitState.fieldValues[field.name]) ? acc : ({ ...acc, [field.name]: exitState.fieldValues[field.name] })),
      {},
    );
    const fieldsWithoutValues = filter(exitState.memberFields, (field) => isEmpty(exitState.fieldValues[field.name]));
    addEvent(EventName.ApplicationPageFieldsFilledExit, {
      fields_with_values: fieldsWithValues,
      fields_without_values: map(fieldsWithoutValues, (field) => field.name),
      project_id: props.projectId,
      project_name: props.projectTitle,
      utm_source: utmSource,
      application_page_type: applicationPageType.toString(),
      application_template_name: data.applicationPageTemplateName || ProjectApplicationPageTemplateName.Customizable,
    });
  };

  useEffect(() => {
    applicationPageSessionRef.current.startTime = new Date().getTime();

    window.addEventListener('beforeunload', eventTrackingOnPageUnload, false);

    return () => {
      window.removeEventListener('beforeunload', eventTrackingOnPageUnload, false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [submitMember] = useMutation<MemberJoinProgramMutation, MemberJoinProgramMutationVariables>(
    MEMBER_JOIN_PROGRAM,
    {
      onCompleted() {
        setSuccessful(true);
      },
      onError(err) {
        let msg = getErrorMessageFromGraphQL(err);
        if (includes(msg, 'for email')) {
          msg = 'Invalid email address entered';
        }
        showErrorMessage(msg);
      },
    },
  );

  const socialFieldIds = useMemo((): string[] => (
    map(
      filter(memberFields, (field) => includes(SOCIAL_FIELDS_WITH_HANDLER, field?.name)),
      (field) => toString(field?.id),
    )
  ), [memberFields]);

  const getFieldLabel = (id) => {
    const field = find(memberFields, (field) => field?.id === +id);
    return field?.label;
  };

  const getFieldIdByName = (name: string) => {
    const field = find(memberFields, (field) => field?.name === name);

    if (field?.id && field.id > 0) {
      return toString(field.id);
    }

    return name;
  };

  const handleChangeAcceptedUnpaidCollaboration = (value: boolean) => {
    setAcceptedUnpaidCollaboration(value);
    setShowUnpaidCollaborationErrorMessage(false);
  };

  const isSocialField = (id) => includes(socialFieldIds, id);

  const sanitizeSocialValue = (value) => chain(value)
      .toLower()
      .replace('@', '')
      .trim()
      .value();

  const isValidSocialHandle = (value) => /^[a-z0-9._]+$/.test(value);

  const validateForm = (values: IFormValues): { isValid: boolean, errorMessage: string } => {
    let errorMessage;
    let isValid = true;

    each(values, (value, id) => {
      if (
        isSocialField(id)
          && !isEmpty(value)
          && !isValidSocialHandle(value)
      ) {
        isValid = false;
        errorMessage = `The social handle format for ${getFieldLabel(id)} is incorrect. Please try again.`;
      }
    });

    return {
      errorMessage,
      isValid,
    };
  };

  const handleOnSubmit = useCallback(async (values: IFormValues) => {
    setSubmitting(true);

    const isAnnualDateField = (id) => {
      const annualDateField = memberFields.find((field) => field.id === parseInt(id, 10))?.type;
      return annualDateField === FieldType.ANNUAL || annualDateField === FieldType.DATE;
    };

    const sanitizedValues = reduce(values, (obj, value, nameOrColumnId) => {
      const columnId = getFieldIdByName(nameOrColumnId);

      if (isSocialField(columnId)) {
        obj[columnId] = sanitizeSocialValue(value);
      } else if (isAnnualDateField(columnId)) {
        if (moment.isMoment(value)) {
          value = new Date(value.toISOString());
        }
        obj[columnId] = localToUTC(startOfDay(value));
      } else {
        obj[columnId] = value;
      }

      return obj;
    }, {});

    const { errorMessage, isValid } = validateForm(sanitizedValues);

    if (!isValid) {
      showErrorMessage(errorMessage);

      setSubmitting(false);
      return;
    }

    await submitMember({
      variables: {
        customLandingPagePath: encodeURIComponent(customLandingPagePath),
        fields: sanitizedValues,
        memberId,
        token,
        utmSource,
        // There is no member id at this point so pass it as -1 and we can
        // reassign it in the backend
        talentAgents: talentAgents.map((talentAgent) => ({
          ...talentAgent,
          memberId: memberId || -1,
        })),
      },
      context,
    });

    setSubmitting(false);
    setFormSubmitted(true);

    if (redirectUri) {
      window.top.location.href = redirectUri;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submitMember,
    setSubmitting,
    customLandingPagePath,
    redirectUri,
    memberFields,
    history,
    socialFieldIds,
    isSocialField,
  ]);

  const {
    loading: loadingRecaptcha,
    onSubmitRecaptcha,
  } = useRecaptcha({
    onSuccess: handleOnSubmit,
    skip: props.isPreview,
  });

  const handleClickLink = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  const handleFieldClicked = () => {
    if (!firstTimeFieldClicked) {
      addEvent(EventName.ApplicationPageFormStart, {
        project_id: props.projectId,
        project_name: props.projectTitle,
        utm_source: utmSource,
        application_page_type: applicationPageType.toString(),
        application_template_name: data?.applicationPageTemplateName || ProjectApplicationPageTemplateName.Customizable,
      });
      setFirstTimeFieldClicked(true);
    }
  };

  const initialValues = useMemo(() => {
    if (!isEmpty(memberResolvedFields)) {
      const parsedResolver = reduce(memberResolvedFields, (prev, curr) => {
        prev[curr.name] = safeJSONParse(curr?.value);
        return prev;
      }, {} as Record<string, unknown>);

      return parsedResolver;
    }

    return {};
  }, [memberResolvedFields]);

  useEffect(() => {
    if (!loading) {
      form.setFieldsValue(initialValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, initialValues]);

  const sending = submitting || loadingRecaptcha;

  const disabled = props.disableSubmit
    || !acceptedTerms
    || loading
    || sending
    || isEmpty(memberFields)
    || alreadySubmitted;
  const disableInputs = alreadySubmitted;

  const getPopupContainer = () => signUpFormRef?.current;

  const getPlaceholderForFieldName = (name: string): string | undefined => {
    if (SOCIAL_FIELDS_WITH_HANDLER.includes(name) || name === 'YouTube') {
      return 'yourhandle';
    }

    if (name === 'Blog') {
      return 'Enter full URL';
    }
  };

  const getPrefixForFieldName = (name: string): React.ReactNode | undefined => {
    if (SOCIAL_FIELDS_WITH_HANDLER.includes(name)) {
      return '@';
    }

    if (name === 'YouTube') {
      return 'youtube.com/';
    }
  };

  const renderFieldInput = (field: any) => {
    const { name, type, choices } = field;

    if (name === INSTAGRAM_INSIGHTS_FIELD) {
      return (
        <InstagramInsights
          clientId={clientId}
          form={form as FormInstance<any>}
          ref={igInsightsFieldRef}
        />
      );
    }

    if (isArray(choices)) {
      const options = choices.map((choice) => (
        <Option value={choice} key={choice} disabled={disableInputs}>
          {choice}
        </Option>
      ));

      if (FieldType[type] === FieldType.ARRAY) {
        return (
          <Select
            allowClear
            mode="multiple"
            placeholder="Pick multiple"
            showArrow
            showSearch={false}
            className={styles.input}
            getPopupContainer={getPopupContainer}
            disabled={disableInputs}
          >
            {options}
          </Select>
        );
      } else {
        return (
          <Select
            allowClear
            placeholder="Pick one"
            className={styles.input}
            getPopupContainer={getPopupContainer}
            disabled={disableInputs}
          >
            {options}
          </Select>
        );
      }
    } else {
      switch (FieldType[type]) {
        case FieldType.BOOLEAN:
          return (
            <Select
              className={styles.input}
              getPopupContainer={getPopupContainer}
              disabled={disableInputs}
            >
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          );
        case FieldType.DATE:
        case FieldType.ANNUAL:
          return (
            <DatePicker
              getPopupContainer={getPopupContainer}
              format="MM-DD-YYYY"
              popupStyle={{
                top: '200px',
                left: '20px',
              }}
              className={styles.dateField}
            />
          );
        default:
          return (
            <Input
              disabled={disableInputs}
              placeholder={getPlaceholderForFieldName(name)}
              prefix={getPrefixForFieldName(name)}
            />
          );
      }
    }
  };

  const supportEmail = props.supportEmail || data?.onboardingTemplateConfig?.application?.support_email;

  if (successful) {
    const style = { color: properties.primaryColor };
    return (
      <div className={styles.Submitted}>
        <div style={style}>
          <CheckCircleIcon size={36} />
        </div>
        <div style={style} className={styles.submitted}>
          Applicant Sent
        </div>
        <div
          className={styles.moreInfo}
          style={{ color: confirmationColor }}
        >
          Thank you for your application. We’ll carefully review your information and a team member will be in touch if there’s a good fit.
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.SignupForm, props.className)}
      ref={signUpFormRef}
      style={{ backgroundColor: props.isBackgroundTransparent ? 'transparent' : backgroundColor }}
    >
      {alreadySubmitted && <AlreadySubmittedAlert supportEmail={props.supportEmail || SUPPORT_EMAIL} />}
      <Helmet {...helmetHead} />
      {
        !loading
          && (
            <Form
              form={form}
              layout="vertical"
              onFinish={props.isPreview ? handleOnSubmit : onSubmitRecaptcha}
            >
              {map(memberFields, (field) => {
                if (isUndefined(field)) {
                  return field;
                }

                const label = (() => {
                  if (field.name === 'email') {
                    return field.label || "What's your email?";
                  }
                  return field.label || field.name;
                })();

                const rules: Rule[] = [{ required: field.required }];

                if (field.name === INSTAGRAM_INSIGHTS_FIELD && field.required) {
                  rules.push({
                    message: 'Instagram Insights Linked is required.',
                    validateTrigger: 'onSubmit',
                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject()),
                  });
                }

                if (field.name === 'email' || field.type === FieldType.EMAIL) {
                  rules.push({
                    type: 'email',
                    message: 'Please enter a valid email address.',
                    validateTrigger: 'onSubmit',
                    validator: (_, value) => (value && !emailRegex.test(value)
                      ? Promise.reject()
                      : Promise.resolve()),
                  });
                }

                return (
                  <div onClick={handleFieldClicked}>
                    {field.type === FieldType.TALENT_AGENTS
                      ? (
                        <>
                          <Form.Item label="Do you have a talent manager?" name="select">
                            <Select
                              onSelect={(value) => {
                                setHasTalentAgents(value !== 'true');
                            }}
                            >
                              <Option value="false">Yes</Option>
                              <Option value="true">No</Option>
                            </Select>
                            {hasTalentAgents && (
                              <Row className={styles.talentAgentSection}>
                                <TalentAgents
                                  curMember={null}
                                  existingTalentAgents={[]}
                                  refetch={() => {}}
                                  isAddingMember
                                  // Add one empty panel to start off, there is no member id yet so we
                                  // are setting to -1
                                  selectedAgents={talentAgents.length === 0 ? [{
                                    memberId: memberId || -1,
                                    agentName: '',
                                    agentEmail: '',
                                    alwaysCC: false,
                                  }] : talentAgents}
                                  setSelectedAgents={setTalentAgents}
                                />
                              </Row>
                            )}
                          </Form.Item>
                        </>
                      )
                      : (
                        <FormItem
                          name={field.name}
                          label={label}
                          key={field.id || field.name}
                          required={field.required}
                          rules={rules}
                          shouldUpdate
                        >
                          {renderFieldInput(field)}
                        </FormItem>
                        )}
                  </div>
                );
              })}
              {hasUnpaidOffer && (
              <Alert
                message={unpaidLabel}
                description={(
                  <Space>
                    <Checkbox
                      className={styles.unpaidCollaborationCheckbox}
                      checked={acceptedUnpaidCollaboration}
                      onChange={handleChangeAcceptedUnpaidCollaboration}
                    />
                    <RichTextVisualizer className={styles.unpaidDescriptionLabel}>
                      {xss(unpaidDescription)}
                    </RichTextVisualizer>
                  </Space>
                )}
                showIcon
                type="info"
              />
              )}
              {hasUnpaidOffer && showUnpaidCollaborationErrorMessage && (
                <div className={styles.error}>
                  You must check the above before submitting your application
                </div>
              )}
              <Checkbox
                className={styles.checkbox}
                checked={acceptedTerms}
                onChange={setAcceptedTerms}
                label={(
                  <div className={styles.checkboxLabel}>
                    Our program is managed by Aspire. By applying to our program, you accept the Aspire&nbsp;
                    <a
                      href="https://www.aspireiq.com/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClickLink}
                    >
                      Terms of Service
                    </a>
                    &nbsp;and
                    {' '}
                    <a
                      href="https://www.aspireiq.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleClickLink}
                    >
                      Privacy Policy
                    </a>
                  </div>
                )}
                disabled={disableInputs}
              />
              <Button
                type="primary"
                htmlType="submit"
                disabled={disabled}
                icon={sending && <SpinnerIcon size={18} />}
                block
              >
                {applyLabel || 'Apply'}
              </Button>
            </Form>
          )
      }
      {
        supportEmail
          && (
            <p className={styles.copyright}>
              <div>
                Questions? Reach us at
                {' '}
                <a
                  className={styles.supportEmail}
                  href={`mailto:${supportEmail}`}
                  target="_blank"
                >
                  {supportEmail}
                </a>
              </div>
            </p>
          )
      }
    </div>
  );
});

SignupForm.defaultProps = {
  disableSubmit: false,
};

const SignupFormWrapper: React.FC<IProps> = React.memo((props) => {
  if (props.isPreview) {
    return (
      <SignupForm {...props} />
    );
  }

  return (
    <RecaptchaContextProvider sitekey={process.env.GOOGLE_RECAPTCHA_SITEKEY}>
      <SignupForm {...props} />
    </RecaptchaContextProvider>
  );
});

SignupFormWrapper.displayName = 'SignupForm';

export {
  SignupFormWrapper as SignupForm,
};
