import React from 'react';
import classNames from 'classnames';

type SectionHeaderProps = {
  title: string;
  description: string;
  icon?: React.ReactNode;
  className?: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  const {
    title,
    description,
    icon,
    className,
  } = props;
  return (
    <div className={classNames('', className)}>
      <div className="flex items-center gap-2">
        {icon && <div>{icon}</div>}
        <h2 className={classNames('text-2xl font-semibold m-0')}>{title}</h2>
      </div>
      <p className="text-gray-600 mt-1 mb-0">{description}</p>
    </div>
  );
};

export default SectionHeader;
