/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1r54o_157 {
  display: flex;
}

._justify-content-space-between_1r54o_161 {
  justify-content: space-between;
}

._tabular-nums_1r54o_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1r54o_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BulkPaymentsApp_1r54o_178 {
  height: 100%;
}