import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  BULK_SEND_ORDER_REQUEST_MUTATION,
} from '../queries';
import {
  BulkSendOrderRequestMutation,
  BulkSendOrderRequestMutationVariables,
} from '../queries/types/BulkSendOrderRequestMutation';

type IOptions = MutationHookOptions<BulkSendOrderRequestMutation, BulkSendOrderRequestMutationVariables>;

export function useBulkSendOrderRequest(options: IOptions = {}) {
  const [bulkSendOrderRequest, { loading, error }] = useMutation<
    BulkSendOrderRequestMutation,
    BulkSendOrderRequestMutationVariables
  >(BULK_SEND_ORDER_REQUEST_MUTATION, options);

  return {
    bulkSendOrderRequest,
    loading,
    error,
  };
}
