import { IState } from '../../../../types/state';
import { isInstagram } from '../../../../utils/contentTypeUtils';
import { IToggleInfluencerWhiteList } from './index';

export const toggleInstagramContentBC = (
  contentGuideline: IState['contentGuidelines'][0],
  toggleInfluencerWhiteList: boolean,
): boolean => {
  if (isInstagram(contentGuideline?.type)) {
    return toggleInfluencerWhiteList;
  }
  return contentGuideline?.requiresBrandedContentTag;
};

export const toggleInfluencerWhiteList = (state: IState, action: IToggleInfluencerWhiteList): IState => {
  if (state.collaborationDetails.bam.toggleInfluencerWhitelist === action.toggleInfluencerWhitelist) {
    return state;
  }
  return {
    ...state,
    contentGuidelines: state.contentGuidelines.map((contentGuideline) => ({
      ...contentGuideline,
      requiresBrandedContentTag: toggleInstagramContentBC(
        contentGuideline,
        action.toggleInfluencerWhitelist,
      ),
    })),
    collaborationDetails: {
      ...state.collaborationDetails,
      bam: {
        ...state.collaborationDetails.bam,
        toggleInfluencerWhitelist: action.toggleInfluencerWhitelist,
        toggleInstagramInsights:
          action.toggleInfluencerWhitelist
          || state.collaborationDetails.bam.toggleInstagramInsights,
      },
    },
  };
};
