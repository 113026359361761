import * as React from 'react';
import {
  pick, omit, isUndefined, findIndex,
} from 'lodash';

import { Checkbox, Toggle, RadioGroup } from '@components';

import { FilterSection } from '../FilterSection';
import ActivitySection from './ActivitySection';

import { IOtherFilters, IOtherOptions } from '../models';
import HasSponsoredPostsSection from './HasSponsoredPostsSection';

const { useMemo } = React;

const FILTERS = ['lastPostActivity', 'hideInvited', 'tags', 'canContact', 'hasSponsoredPosts'];

const pickFilters = (obj: any): IOtherFilters => pick(obj, FILTERS);

const CONTACT_OPTIONS = [
  { label: 'With Email', value: true },
  { label: 'Without Email', value: false },
];
interface IProps extends IOtherFilters, IOtherOptions {
  onChange(otherFilters: IOtherFilters);
  selfServeExperiment: boolean;
  showIQDataFilters?: boolean;
  shouldShowDemoFeatures?: boolean;
  isInstagramDMEnabled?: boolean;
  channel?: string;
}

const OtherSection: React.FunctionComponent<IProps> = (props) => {
  const handleChangeLastPostActivity = (value: number) => {
    const oldFilters = pickFilters(props);
    const newFilters = isUndefined(value)
      ? omit(oldFilters, 'lastPostActivity')
      : {
        ...oldFilters,
        lastPostActivity: value,
      };
    props.onChange(newFilters);
  };

  const handleChangeHideInvited = (hideInvited: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      hideInvited,
    };
    props.onChange(newFilters);
  };

  const handleChangeCanContact = (canContact: boolean) => {
    const newFilters = {
      ...pickFilters(props),
      canContact,
    };
    props.onChange(newFilters);
  };

  const handleChangeCanContactIQData = (value: boolean) => {
    const oldFilters = pickFilters(props);
    const newFilters = isUndefined(value)
      ? omit(oldFilters, 'canContact')
      : {
        ...oldFilters,
        canContact: value,
      };
    props.onChange(newFilters);
  };

  const selectedIndex = useMemo(() => {
    if (isUndefined(props.canContact)) {
      handleChangeCanContactIQData(true);
      return 0;
    } else {
      return findIndex(CONTACT_OPTIONS, { value: props.canContact })
    }
  }, [props.canContact]);

  const handleChangeSponsoredPosts = (hasSponsoredPosts: boolean) => {
    props.onChange({
      ...pickFilters(props),
      hasSponsoredPosts,
    });
  };

  const hide = props.hide
    || (props.hideInvitedToggle && props.hideActivity && props.hideTags && props.hideCanContact);

  return (
    <FilterSection header="Other" hide={hide} collapsible defaultIsOpen={props.isExpanded}>
      {!props.selfServeExperiment
        && (
          <FilterSection
            header="Hide Invited Creators"
            hide={props.hideInvitedToggle}
            isSubsection
          >
            <Toggle checked={props.hideInvited} onChange={handleChangeHideInvited} />
          </FilterSection>
        )}

      <HasSponsoredPostsSection
        isOn={!!props.hasSponsoredPosts}
        onChange={handleChangeSponsoredPosts}
        hide={props.hideHasSponsoredPosts}
      />

      <ActivitySection
        lastPostActivity={props.lastPostActivity}
        onChangeLastPostActivity={handleChangeLastPostActivity}
        hide={props.hideActivity}
        showIQDataFilters={props.showIQDataFilters}
      />
      {
        !props.shouldShowDemoFeatures &&
        props.showIQDataFilters &&
        (
          <FilterSection header="Contact Information" isSubsection>
            <RadioGroup
              alignment="vertical"
              options={CONTACT_OPTIONS}
              onChange={handleChangeCanContactIQData}
              selectedIndex={selectedIndex}
            />
          </FilterSection>
        )
      }
      {
        !props.shouldShowDemoFeatures &&
        !props.showIQDataFilters &&
        !props.isInstagramDMEnabled &&
           (
            <FilterSection header="Can Contact" isSubsection>
              <Checkbox
                checked={props.canContact}
                label="Has contact info"
                onChange={handleChangeCanContact}
              />
            </FilterSection>
          )
      }
    </FilterSection>
  );
};

OtherSection.defaultProps = {
  hideInvited: true,
};

export default OtherSection;
