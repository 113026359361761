import gql from 'graphql-tag';

import { USER_FRAGMENT } from './fragments';

export const GET_USERS_QUERY = gql`
  query GetUsersQuery {
    users: getUsersForCurrentClient {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_ALL_USERS_QUERY = gql`
  query GetAllUsersQuery {
    users: getUsers {
      id,
      email,
      name
    }
  }
`;

export const CHECK_FIRST_LOGIN_MUTATION = gql`
  mutation CheckFirstLogin($placeHolder: String) {
    firstLogin: checkFirstLogin(placeHolder: $placeHolder)
  }
`;

export const INVITE_USER_MUTATION = gql`
  mutation InviteUserMutation($email: String!, $name: String!) {
    invited: inviteUser(email: $email, name: $name)
  }
`;

export const REMOVE_USER_MUTATION = gql`
  mutation RemoveUserMutation($id: String!) {
    removed: removeUser(id: $id)
  }
`;
