import React from 'react';
import { Typography } from '@revfluence/fresh';
import { every } from 'lodash';
import { formatEngagementRateToPerc, formatNumberToSuffixString } from '../GcrUtils';
import styles from './SocialMetrics.scss';

const { Text, Title } = Typography;

const TiktokStates = ({ memberRecord }) => {
    const listItems = [
        {
            label: 'Followers',
            valueKey: 'tiktok_followers',
            formatFunction: formatNumberToSuffixString,
        },
        {
            label: 'Engagements',
            valueKey: 'tiktok_engagement',
            formatFunction: formatNumberToSuffixString,
        },
        {
            label: 'Engagement Rate',
            valueKey: 'tiktok_engagement_rate',
            formatFunction: formatEngagementRateToPerc,
        },
        {
            label: 'Views per video',
            valueKey: 'tiktok_views_per_video',
            formatFunction: formatNumberToSuffixString,
        },
    ];

    // Check if all valueKeys have no data
    const hasNoData = every(listItems, (item) => !memberRecord?.metaFields[item.valueKey]);

    return (
      <div>

        {
                hasNoData ? <Text className={styles.noData}>No data yet</Text>
                : (
                  <ul>
                    {listItems.map((item, index) => (
                      <li key={index}>
                        <Title level={4} weight="regular">
                          {memberRecord?.metaFields[item.valueKey]
                                    ? item.formatFunction(memberRecord?.metaFields[item.valueKey])
                                    : '-'}
                        </Title>
                        <Text>{item.label}</Text>
                      </li>
                    ))}
                  </ul>
)
            }
      </div>
    );
};

export default TiktokStates;
