import { ProgramStatusType } from '@services/communities/types';

export enum ProjectMarketplaceStatus {
  AccomplishApplicationPage = 'AccomplishApplicationPage',
  PublishListingToCreatorMarketplace = 'PublishListingToCeatorMarketplace',
  ListingPublishedToCreatorMarketplace = 'ListingPublishedToCreatorMarketplace',
  AutoUnlistedFromCreatorMarketplace = 'AutoUnlistedFromCreatorMarketplace',
  UserUnlistedFromCreatorMarketplace = 'UserUnlistedFromCreatorMarketplace',
}

export const ProjectStatus = {
  Active: ProgramStatusType.ACTIVE,
  Archived: ProgramStatusType.ARCHIVED,
  Deleted: ProgramStatusType.DELETED,
};

export const STATUS = {
  [ProjectMarketplaceStatus.AccomplishApplicationPage]: {
    message: 'Complete your application page to start accepting creators.',
    messageResponsive: 'Complete application page.',
    color: 'orange',
    cta: {
      label: 'Setup Application Page',
    },
    statusLabel: 'Not setup',
  },
  [ProjectMarketplaceStatus.PublishListingToCreatorMarketplace]: {
    message: 'Publish your project to the marketplace to start accepting applicants.',
    messageResponsive: 'Publish application page.',
    color: 'orange',
    cta: {
      label: 'Get Started',
    },
    statusLabel: 'Not published',
  },
  [ProjectMarketplaceStatus.ListingPublishedToCreatorMarketplace]: {
    message: 'Your project is live and accepting applicants.',
    messageResponsive: 'Accepting applicants.',
    color: 'green',
    cta: {
      label: 'View Application Page',
    },
    statusLabel: 'Live',
  },
  [ProjectMarketplaceStatus.AutoUnlistedFromCreatorMarketplace]: {
    message: 'Your project was auto unlisted from the marketplace on',
    messageResponsive: 'Listing expired.',
    color: 'red',
    cta: {
      label: 'Relist Project',
    },
    statusLabel: 'Expired',
  },
  [ProjectMarketplaceStatus.UserUnlistedFromCreatorMarketplace]: {
    message: 'Your project was unlisted from the marketplace on',
    messageResponsive: 'Project Unlisted.',
    color: 'red',
    cta: {
      label: 'Relist Project',
    },
    statusLabel: 'Unlisted',
  },
  [ProjectStatus.Archived]: {
    message: 'Your project is archived',
    messageResponsive: 'Your project is archived',
    color: '#8C8C8C',
    cta: null,
    statusLabel: 'archived',
  },
};

export enum ScreenSizes {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  ExtraExtralarge = 'xxl',
}
