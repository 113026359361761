import React, { useEffect, useState } from 'react';
import { Empty, WidgetContainer } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { useGetDemographicRegionOrders } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetDemographicRegionOrders';
import {
 ComposableMap, Geographies, Geography, ZoomableGroup,
} from 'react-simple-maps';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toLowerAndTrim } from '@frontend/utils';
import { Spinner } from '@revfluence/fresh';
import { EarthAmericasIcon } from '@revfluence/fresh-icons/regular/esm';
import * as d3 from 'd3';
import { cn } from '@/shadcn/lib/utils';
import Switcher from '../refresh-components/Switcher';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

const RANGE_COLORS = [
  'hsl(120, 100%, 20%)',
  'hsl(120, 95%, 28%)',
  'hsl(120, 90%, 36%)',
  'hsl(120, 85%, 44%)',
  'hsl(120, 80%, 52%)',
  'hsl(120, 75%, 60%)',
  'hsl(120, 70%, 68%)',
  'hsl(120, 65%, 76%)',
  'hsl(120, 60%, 84%)',
  'hsl(120, 55%, 92%)',
];

interface PFAOrderDemographicRegionsProps {
  className?: string;
}
export default function PFAOrderDemographicRegions({ className }: PFAOrderDemographicRegionsProps) {
  const [selectedTab, setSelectedTab] = useState<Array<'country' | 'province'>>(['country']);

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const topoJson = selectedTab[0] === 'country'
    ? 'https://storage.googleapis.com/aspirex-dev-community/ne_50m_admin_0_countries.json'
    : 'https://storage.googleapis.com/aspirex-dev-community/ne_10m_admin_1_states_provinces.json';

  useEffect(() => {
    let observer = new MutationObserver(() => {
      const rendered = document.querySelector('[data-tooltip-id="map-tooltip"]');
      if (rendered) {
        setIsMapLoaded(true);
      } else {
        setIsMapLoaded(false);
      }
    });

    observer.observe(document.querySelector('div[id="map-container"]'), {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });

    return () => {
      observer.disconnect();
      observer = null;
    };
  }, [selectedTab]);

  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const { demographicRegionOrders, isDemographicRegionOrdersLoading } = useGetDemographicRegionOrders({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        groupBy: selectedTab[0],
      },
    },
  });

  const colorScale = d3.scaleQuantile()
    .domain(demographicRegionOrders?.map((d) => d.orderCountPerCountry || 0) || [0, 0])
    // @ts-ignore
    .range(RANGE_COLORS.toReversed());

  // const colorScale = scaleQuantile(
  //   demographicRegionOrders?.map((d) => d.orderCountPerCountry || 0) || [0, 0],
  //   RANGE_COLORS.toReversed(),
  // );

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );

  const widgetActions = (
    <Switcher
      items={[
        { label: 'Country', key: 'country' },
        { label: 'State', key: 'province' },
      ]}
      type="single"
      activeKeys={selectedTab}
      onChange={(keys) => setSelectedTab(keys as Array<'country' | 'province'>)}
    />
  );

  const emptyContent = (
    <div className="h-[260px] flex items-center justify-center">
      <Empty
        icon={EarthAmericasIcon}
        title="No shipments created"
        description="No orders have been shipped. Once orders are fulfilled, you’ll see a geographic breakdown of shipping regions here."
      />
    </div>
  );

  const dataKey = selectedTab[0] === 'country' ? 'orderCountry' : 'orderProvince';

  const mainContent = (
    <div className="flex flex-col justify-evenly gap-16 relative">
      {!isMapLoaded && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <ComposableMap
        projection="geoEquirectangular"
        style={{
          height: '600px',
        }}
      >
        <ZoomableGroup>
          <Geographies geography={topoJson}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const cur = demographicRegionOrders?.find((d) => !!d?.[dataKey] && [
                  toLowerAndTrim(geo.properties.NAME),
                  toLowerAndTrim(geo.properties.ABBREV),
                  toLowerAndTrim(geo.properties.name),
                  toLowerAndTrim(geo.properties.ADM0_A3),
                  toLowerAndTrim(geo.properties.NAME_LONG),
                ].includes(toLowerAndTrim(d?.[dataKey])));
                return (
                  <Geography
                    data-tooltip-id="map-tooltip"
                    data-name={geo.properties.NAME || geo.properties.name}
                    data-total-orders={cur?.orderCountPerCountry || 'NA'}
                    key={geo.rsmKey}
                    geography={geo}
                    // @ts-ignore
                    fill={cur ? colorScale(cur.orderCountPerCountry) : 'hsl(var(--border))'}
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none', opacity: 0.5 },
                      pressed: { outline: 'none' },
                    }}
                    onLoad={() => console.log('Loaded the geography')}
                  />
                );
              })}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip
        id="map-tooltip"
        place="top"
        render={({ activeAnchor }) => {
          if (!activeAnchor) return null;
          const { name, totalOrders } = activeAnchor.dataset;
          return (
            <div className="p-2 flex flex-col gap-2">
              <div className="font-bold">{name}</div>
              <div>
                Total Orders:
                {totalOrders}
              </div>
            </div>
          );
        }}
      />
    </div>
  );

  return (
    <WidgetContainer
      widgetTitle="Order Demographic Regions"
      className={cn(className, '')}
      bgColor="bg-white"
      textColor="text-gray-700"
      widgetActions={widgetActions}
      id="map-container"
    >
      {isDemographicRegionOrdersLoading ? loadingContent : demographicRegionOrders?.length ? mainContent : emptyContent}
    </WidgetContainer>
  );
}
