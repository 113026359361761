import 'css-chunk:src/components/widgets/SocialProfile/Card/ProfileCard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1iosq_157",
  "justify-content-space-between": "_justify-content-space-between_1iosq_161",
  "tabular-nums": "_tabular-nums_1iosq_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_1iosq_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_1iosq_166",
  "Card": "_Card_1iosq_170",
  "tooltip": "_tooltip_1iosq_306",
  "cardHeader": "_cardHeader_1iosq_320",
  "tabs": "_tabs_1iosq_331",
  "tabButton": "_tabButton_1iosq_335",
  "activeTab": "_activeTab_1iosq_354",
  "separator": "_separator_1iosq_358",
  "cardTab": "_cardTab_1iosq_365",
  "cardTitle": "_cardTitle_1iosq_373",
  "visible": "_visible_1iosq_381",
  "show": "_show_1iosq_1",
  "noData": "_noData_1iosq_386",
  "ProfileCard": "_ProfileCard_1iosq_396",
  "hashtags": "_hashtags_1iosq_396",
  "AccountInfo": "_AccountInfo_1iosq_401",
  "image": "_image_1iosq_406",
  "info": "_info_1iosq_417",
  "username": "_username_1iosq_427",
  "location": "_location_1iosq_428",
  "stats": "_stats_1iosq_429",
  "ambIcon": "_ambIcon_1iosq_450",
  "icon": "_icon_1iosq_456",
  "verticalSeparator": "_verticalSeparator_1iosq_460",
  "checkCircleIcon": "_checkCircleIcon_1iosq_465",
  "starIcon": "_starIcon_1iosq_469"
};