import moment from 'moment';
import React from 'react';
import { TimerIcon } from '@revfluence/fresh-icons/regular/esm';
import { Tag } from '@revfluence/fresh';
import { Handle, Position } from 'reactflow';
import { NODE_EDGE_COLOR } from '@frontend/app/containers/Projects/AutomationConfig/constants';
import { AutomationNodeData } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { formatDuration, intervalToDuration } from 'date-fns';
import styles from './AutomationNode.scss';

function formatDays(days) {
  if (days === 0) {
    return '0 day';
  }
  const duration = intervalToDuration({ start: 0, end: days * 24 * 60 * 60 * 1000 });

  const formattedDuration = formatDuration(duration, { delimiter: ', ' });

  return formattedDuration;
}

const DelayNode = (props: { data: AutomationNodeData }) => {
  const {
    data: {
      isEditing,
      metadata,
      nodeType,
      nodeId,
      onNodeItemClick,
    },
  } = props;

  const handleBackground = isEditing ? NODE_EDGE_COLOR.EDITING : NODE_EDGE_COLOR.PRIMARY;
  const durationMs = metadata.durationMs;
  const durationDays = moment.duration(durationMs).asDays();

  const onDurationClick = () => {
    if (!isEditing) return;
    onNodeItemClick({
      nodeType,
      targetNodeId: nodeId,
      delayNode: {
        durationMs,
      },
    });
  };

  return (
    <>
      <Handle
        type="target"
        isConnectable={false}
        position={Position.Top}
        style={{ background: 'transparent' }}
      />
      <div className={styles.nodeDecorator}>
        <div className={styles.delayNodeIcon}>
          <TimerIcon />
        </div>
      </div>
      <div className={styles.nodeContent}>
        <div>Wait...</div>
        <Tag onClick={onDurationClick}>
          {formatDays(durationDays)}
        </Tag>
      </div>
      <Handle
        type="source"
        isConnectable={false}
        position={Position.Bottom}
        style={{ background: handleBackground }}
        id="delay-resource"
      />
    </>
  );
};

export default DelayNode;
