/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_111n8_157 {
  display: flex;
}

._justify-content-space-between_111n8_161 {
  justify-content: space-between;
}

._tabular-nums_111n8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_111n8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Application_111n8_178 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem auto 0;
  color: #fdfdfd;
  max-width: 93.75rem;
}
@media only screen and (min-width: 20rem) {
  ._Application_111n8_178 {
    margin-bottom: 3rem;
    padding: 0 2rem;
  }
  ._Application_111n8_178 ._title_111n8_191 {
    margin-bottom: 0;
    color: #fdfdfd;
    font-weight: 900;
    font-style: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    text-align: center;
    word-break: break-word;
  }
  ._Application_111n8_178 ._description_111n8_201 {
    width: 100%;
    margin: 1.875rem 0.625rem;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
    white-space: break-spaces;
    word-break: break-word;
  }
  ._Application_111n8_178 ._ApplicationForm_111n8_212 {
    box-sizing: border-box;
    margin: 0.75rem 0.625rem auto;
    padding: 1.5rem 1rem;
    border: 0.0625rem solid #E9E8EA;
    border-radius: 0.75rem;
    background-color: #fdfdfd;
  }
  ._Application_111n8_178 ._ApplicationForm_111n8_212 .ant-select-selector,
  ._Application_111n8_178 ._ApplicationForm_111n8_212 .ant-input,
  ._Application_111n8_178 ._ApplicationForm_111n8_212 .ant-picker {
    border: 0.03125rem solid #dadcde;
    border-radius: 1.25rem;
    background-color: #EFF5F9;
  }
  ._Application_111n8_178 ._ApplicationForm_111n8_212 [class*=Button_primary] {
    border: 0.125rem solid #1c1c1c;
    border-radius: unset;
    background-color: #fdfdfd !important;
    color: #1A1818;
  }
  ._Application_111n8_178 ._copyright_111n8_233 {
    width: 100%;
    margin: 1.5rem auto 0;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
  }
  ._Application_111n8_178 ._supportEmail_111n8_242 {
    color: inherit;
    text-decoration: underline;
  }
}
@media only screen and (orientation: landscape) {
  ._Application_111n8_178 {
    margin-bottom: 4rem;
    padding: 0 5rem;
  }
  ._Application_111n8_178 ._description_111n8_201 {
    margin: 1.875rem auto;
    max-width: 56.25rem;
  }
  ._Application_111n8_178 ._ApplicationForm_111n8_212 {
    width: 50%;
    padding: 2.8% 6.2%;
  }
  ._Application_111n8_178 ._copyright_111n8_233 {
    width: 30%;
  }
}