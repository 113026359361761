import { Redirect, Route, Switch } from 'react-router-dom';

import Welcome from '@frontend/app/containers/Onboarding/Welcome/Welcome';
import * as React from 'react';

import { OnboardingWizard } from './OnboardingWizard/OnboardingWizard';

const Onboarding: React.FC = React.memo(() => (
  <Switch>
    <Route path="/onboarding/steps" component={OnboardingWizard} />
    <Route path="/onboarding" component={Welcome} />
    <Redirect to="/onboarding" />
  </Switch>
  ));

Onboarding.displayName = 'Onboarding';

export default Onboarding;
