/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1hljj_157 {
  display: flex;
}

._justify-content-space-between_1hljj_161 {
  justify-content: space-between;
}

._tabular-nums_1hljj_165 {
  font-variant-numeric: tabular-nums;
}

._Popover_1hljj_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Popover_1hljj_169 ._contentWrapper_1hljj_174 {
  filter: drop-shadow(0 0.5rem 0.5rem rgba(46, 66, 109, 0.2));
}

._Popover_1hljj_169 ._contentWrapper_1hljj_174._shadow-large_1hljj_178 {
  filter: drop-shadow(0 1rem 1rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_1hljj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Popover_1hljj_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Popover_1hljj_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Popover_1hljj_169 ::-webkit-scrollbar-track, ._Popover_1hljj_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Popover_1hljj_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Popover_1hljj_169 {
  position: fixed !important;
  z-index: 9500;
  pointer-events: none;
  transform-origin: top right;
}
._Popover_1hljj_169._active_1hljj_218 {
  pointer-events: initial;
}
._Popover_1hljj_169._active_1hljj_218 ._arrow_1hljj_221 {
  display: block;
}
._Popover_1hljj_169._active_1hljj_218 ._mask_1hljj_224 {
  display: block;
}
._Popover_1hljj_169._active_1hljj_218 > ._contentWrapper_1hljj_174._contentWrapper_1hljj_174 {
  width: 100%;
  opacity: 1;
  transform: scale(1) translate(0, 0);
}
._Popover_1hljj_169 ._arrow_1hljj_221 {
  position: absolute;
  width: 0;
  height: 0;
}
._Popover_1hljj_169._bottom_1hljj_237 ._arrow_1hljj_221 {
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid white;
}
._Popover_1hljj_169._bottom_1hljj_237 ._contentWrapper_1hljj_174 {
  transform: scale(0.9) translate(0, -2.25rem);
}
._Popover_1hljj_169._top_1hljj_248 ._arrow_1hljj_221 {
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 8px solid white;
}
._Popover_1hljj_169._top_1hljj_248 ._contentWrapper_1hljj_174 {
  transform: scale(0.9) translate(0, 2.25rem);
}
._Popover_1hljj_169._left_1hljj_259 ._arrow_1hljj_221 {
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 8px solid white;
}
._Popover_1hljj_169._left_1hljj_259 ._contentWrapper_1hljj_174 {
  transform: scale(0.9) translate(2.25rem, 0);
}
._Popover_1hljj_169._right_1hljj_270 ._arrow_1hljj_221 {
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 8px solid white;
}
._Popover_1hljj_169._right_1hljj_270 ._contentWrapper_1hljj_174 {
  transform: scale(0.9) translate(-2.25rem, 0);
}
._Popover_1hljj_169 ._mask_1hljj_224 {
  position: fixed;
  display: none;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}
._Popover_1hljj_169 ._contentWrapper_1hljj_174 {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(255, 255, 255);
  border-radius: 0.25rem;
  opacity: 0;
  transition: transform 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s, opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Popover_1hljj_169 ._content_1hljj_174 {
  width: 100%;
  padding: 8px 0;
  position: relative;
  overflow-y: auto;
}