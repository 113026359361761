import 'css-chunk:src/applications/AffiliatesApp/components/DeeplinkDrawer/DetailDeeplink.scss';export default {
  "formGroup": "_formGroup_zvu6v_1",
  "styledInput": "_styledInput_zvu6v_5",
  "socialContainer": "_socialContainer_zvu6v_10",
  "title": "_title_zvu6v_20",
  "editDeepLinkButtons": "_editDeepLinkButtons_zvu6v_29",
  "ant-input-disabled": "_ant-input-disabled_zvu6v_29",
  "styledButtonGroup": "_styledButtonGroup_zvu6v_38",
  "ant-btn-primary": "_ant-btn-primary_zvu6v_48",
  "deepLinkButtons": "_deepLinkButtons_zvu6v_57",
  "customButton": "_customButton_zvu6v_63",
  "editFormContainer": "_editFormContainer_zvu6v_70",
  "ant-form-item": "_ant-form-item_zvu6v_70",
  "ant-form-item-label": "_ant-form-item-label_zvu6v_73",
  "ant-form": "_ant-form_zvu6v_70",
  "ant-form-item-required": "_ant-form-item-required_zvu6v_82",
  "ant-form-item-explain": "_ant-form-item-explain_zvu6v_85",
  "ant-form-item-row": "_ant-form-item-row_zvu6v_88",
  "ant-form-item-margin-offset": "_ant-form-item-margin-offset_zvu6v_91",
  "customEditText": "_customEditText_zvu6v_95",
  "ant-typography": "_ant-typography_zvu6v_98"
};