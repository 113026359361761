import gql from 'graphql-tag';

export const JOB_STATUS_QUERY = gql`
  query JobStatusQuery($parentJobId: String!) {
    status: jobStatus(parentJobId: $parentJobId) {
      count
      success
    }
  }
`;
