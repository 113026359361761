import * as React from 'react';
import {
  TNetworkIdentifier,
} from '@components';
import {
  Row,
  Col,
  Avatar,
  Card as FreshCard,
} from '@revfluence/fresh';
import numeral from 'numeral';

import styles from './TopPostMeta.module.scss';

export interface ITopPost {
  network: TNetworkIdentifier;
  image_url: string;
  link: string;
  likes: number;
  engagements: number;
  reach: number;
  impressions: number;
  profile_image_url: string;
}

interface IProps {
  post: ITopPost;
}

const TopPostMeta: React.FC<IProps> = React.memo((props) => {
  const { post } = props;
  return (
    <FreshCard.Meta
      avatar={<Avatar shape="square" size={40} src={post.profile_image_url} />}
      className={styles.TopPostMeta}
      description={(
        <>
          <Row justify="space-between">
            <Col />
            <Col>
              { numeral(post.likes).format('0.[0]a').toUpperCase() }
              {' '}
              Likes
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>Engagements</Col>
            <Col className={styles.count}>{numeral(post.engagements).format('0.[0]a').toUpperCase()}</Col>
          </Row>
          <Row justify="space-between">
            <Col>Impressions</Col>
            <Col className={styles.count}>{numeral(post.impressions).format('0.[0]a').toUpperCase()}</Col>
          </Row>
        </>
      )}
    />
  );
});

export default TopPostMeta;
TopPostMeta.displayName = 'TopPostMeta';
