import * as React from 'react';
import {
  Checkbox, InputNumber, Space, Typography,
} from '@revfluence/fresh';

const { Title, Text } = Typography;

interface IProps {
  priceMax: number;
  hidePrice: boolean;
  quantityMax: number;
  onPriceMaxChange: (priceMax: number) => void;
  onQuantityMaxChange: (quantityMax: number) => void;
  onHidePriceChange: (hidePrice: boolean) => void;
}

export const Limitations = ({
  priceMax, hidePrice, quantityMax, onPriceMaxChange, onQuantityMaxChange, onHidePriceChange,
}: IProps) => (
  <Space direction="vertical" size="middle" style={{ width: '100%' }}>
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Title level={5}>Order Request Limitations</Title>
      <Text>
        Set the maximum number of items a creator can request and/or the maximum cost. If there are no limits, you can
        choose to leave the fields blank.
      </Text>
    </Space>
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Text strong>
        Maximum Number of Items Per Creator
        <Text type="secondary"> (optional)</Text>
      </Text>
      <InputNumber size="large" min={0} style={{ width: '100%' }} onChange={onQuantityMaxChange} defaultValue={quantityMax} />
    </Space>
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Text strong>
        Maximum Product Value per Creator
        <Text type="secondary"> (optional)</Text>
      </Text>

      <InputNumber min={0} step="0.01" placeholder="0.00" size="large" prefix="$" style={{ width: '100%' }} onChange={onPriceMaxChange} defaultValue={priceMax} />
      <Checkbox
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        disabled={priceMax}
        checked={hidePrice}
        value={hidePrice}
        onChange={(e) => onHidePriceChange(e.target.checked)}
      >
        <Text strong>
          Hide Price From Creator
        </Text>
      </Checkbox>
    </Space>
  </Space>
);
