/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1trcz_157 {
  display: flex;
}

._justify-content-space-between_1trcz_161 {
  justify-content: space-between;
}

._tabular-nums_1trcz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1trcz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Dashboard_1trcz_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._Dashboard_1trcz_178 section {
  flex: 0 0 auto;
  padding: 1.5rem;
}
._Dashboard_1trcz_178 section:last-child {
  flex: 1 1 auto;
  min-height: 0.0625rem;
}