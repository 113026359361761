import gql from 'graphql-tag';

export const REMOVE_BULK_DEEPLINKS = gql`
  mutation RemoveBulkDeeplinks($affiliateDeeplinkIds: [Int!]!) {
    bulkRemoveAdditionalDeeplinks(affiliateDeeplinkIds: $affiliateDeeplinkIds) {
        affiliateDeepLinkUrl,
        createdDate,
        label,
        url,
        deletedDate,
        id,
        shortLink,
        tuneShortLinkId,
    }
  }
`;
