import * as React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { LazyImage } from '@components';
import {
  EllipsisLabel,
  VERTICAL_TOOLTIP_OFFSET,
  HORIZONTAL_TOOLTIP_OFFSET,
} from '..';
import styles from './CommunityDropdownLabel.scss';

const defaultCommunityLogo = 'https://storage.googleapis.com/aspirex-static-files/default_community.png';

interface IProps {
  title: string;
  imageUrl: string;
  disabled?: boolean;
}

export const CommunityDropdownLabel: React.FC<IProps> = React.memo((props) => (
  <div className={styles.CommunityDropdownLabel}>
    <div className={styles.logo}>
      <LazyImage
        className={styles.img}
        key={props.imageUrl}
        src={props.imageUrl}
        fallbackSrc={defaultCommunityLogo}
      />
    </div>
    <EllipsisLabel
      align={[
        -HORIZONTAL_TOOLTIP_OFFSET,
        VERTICAL_TOOLTIP_OFFSET,
      ]}
    >
      <span className={styles.title}>
        {props.title}
      </span>
    </EllipsisLabel>
    {!props.disabled && (
      <div className={styles.arrow}>
        <DownOutlined />
      </div>
    )}
  </div>
));

CommunityDropdownLabel.displayName = 'CommunityDropdownLabel';
