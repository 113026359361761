import * as React from 'react';
import {
 size, map, toLower,
} from 'lodash';

import { Modal } from '@revfluence/fresh';

import {
 ILicensedContent, TokenInput, TToken, Button,
} from '@components';

import { getCommonTags } from './utils/tags';

import styles from './TagsModal.scss';

const { useState, useEffect, useMemo } = React;

interface IProps {
  show: boolean;
  content: ILicensedContent[];

  onRequestClose();
  onClickSave?(content: ILicensedContent[], tags: string[]);
}

const transformTag = (tag: string): TToken => ({ value: toLower(tag), label: tag });
const formatTags = (tags: string[]): TToken[] => map(tags, transformTag);

const TagsModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const contentSize = size(props.content);

  const initialTags = useMemo<TToken[]>(() => formatTags(getCommonTags(props.content)), [props.content]);

  const [tags, setTags] = useState<TToken[]>(initialTags);
  const [inputValue, setInputValue] = useState<string>('');

  const handleChangeTags = (newTags: TToken[]) => {
    setTags(newTags);
  };

  const handleClickSave = () => {
    let savedTags = tags;
    // In case user doesn't press enter before saving.
    if (inputValue) {
      const newTag = transformTag(inputValue);
      savedTags = [...tags, newTag];
      setInputValue('');
      setTags(savedTags);
    }
    props.onClickSave(props.content, map(savedTags, 'value'));
  };

  useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  useEffect(() => {
    if (!props.show) {
      setTimeout(() => {
        setInputValue('');
      }, 400);
    }
  }, [props.show]);

  return (
    <Modal title="Edit Tags"
      open={props.show}
      onCancel={props.onRequestClose}
      footer={null}
    >
      <div className={styles.tagsModalContent}>
        <div className={styles.body}>
          Update tags for
          {' '}
          {contentSize > 1 ? 'content' : '1 selected content'}
        </div>
        <TokenInput
          value={inputValue}
          tokens={tags}
          transformInputIntoToken={transformTag}
          onChange={handleChangeTags}
          onChangeValue={setInputValue}
          placeholder="Enter a tag..."
          hidePopover
          className={styles.input}
        />
        <Button label="Save" onClick={handleClickSave} />
        {contentSize > 1 ? (
          <div className={styles.note}>
            Note: The saved changes will add/remove tags for all selected content.
          </div>
        ) : null}
      </div>
    </Modal>
  );
});

export default TagsModal;
