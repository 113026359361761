import { sortGuidelineInstructions } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IState } from '../../../../types/state';
import { ISortGuidelineAction } from './index';

export const sortGuideline = (state: IState, action: ISortGuidelineAction): IState => ({
  ...state,
  contentGuidelines: sortGuidelineInstructions(
    state.contentGuidelines,
    action.contentGuidelineInstanceId,
    Object.keys(action.sortableGuideline)[0],
    action.sortableGuideline,
  ),
});
