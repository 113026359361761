import { useQuery, QueryHookOptions } from '@apollo/client';
import {
    GetBudgetDashboardSpendSummaryQuery,
    GetBudgetDashboardSpendSummaryQueryVariables,
} from '../queries/types/GetBudgetDashboardSpendSummaryQuery';
import GET_BUDGET_DASHBOARD_SPEND_SUMMARY from '../queries/GetBudgetDashboardSpendSummaryQuery';

type IOptions = QueryHookOptions<GetBudgetDashboardSpendSummaryQuery, GetBudgetDashboardSpendSummaryQueryVariables>;

export function useGetBudgetDashboardSpendSummaryQuery(options: IOptions = {}) {
  return useQuery<GetBudgetDashboardSpendSummaryQuery, GetBudgetDashboardSpendSummaryQueryVariables>(
    GET_BUDGET_DASHBOARD_SPEND_SUMMARY,
    options,
  );
}
