import * as React from 'react';
import cx from 'classnames';
import {
  Button, StarBorderIcon, StarIcon, SpinnerIcon,
} from '@components';

import { useMarkMembersAsImportantMutation } from '@frontend/app/hooks';
import { useReloadSegmentCounts } from '@frontend/app/containers/Members/hooks';

import styles from './ImportantButton.scss';

interface IProps {
  memberId?: number;
  isImportant: boolean;
  onChangeIsImportant?(isImportant: boolean): void;
  className?: string;
}

export const ImportantButton: React.FC<IProps> = (props) => {
  const reloadSegmentCounts = useReloadSegmentCounts(null, null);

  const [markMemberAsImportant, {
    loading: isLoading,
  }] = useMarkMembersAsImportantMutation();

  const handleClick = async () => {
    if (props.onChangeIsImportant) {
      props.onChangeIsImportant(!props.isImportant);
    }

    if (!props.memberId) {
      return;
    }

    await markMemberAsImportant({
      variables: {
        memberIds: [props.memberId],
        isImportant: !props.isImportant,
      },
    });

    await reloadSegmentCounts();
  };

  let icon;
  if (isLoading) {
    icon = <SpinnerIcon size={12} />;
  } else if (props.isImportant) {
    icon = <StarIcon size={12} />;
  } else {
    icon = <StarBorderIcon size={12} />;
  }

  return (
    <Button
      className={cx(styles.ImportantButton, props.className)}
      label="Important"
      icon={icon}
      theme="light"
      onClick={handleClick}
    />
  );
};
