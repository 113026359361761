import { filter, orderBy } from 'lodash';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as qs from 'qs';
import { message } from 'antd';
import { SetupOffers as SetupOffersComponent } from '@affiliates/components';
import { useGetOffersForSelectionQuery, useUpdateOfferMutation } from '@affiliates/hooks';
import { OFFER_TYPE, OFFER_SOURCE } from '@affiliates/types/globalTypes';

import { SALES_APP_ID } from '@frontend/app/constants/applicationIds';
import { useAddProjectEvent } from '@frontend/app/containers/Projects/hooks';
import { EventName, logger } from '@common';

const { useState, useCallback, useMemo } = React;

export interface ISetupOffers {
  offerType: OFFER_TYPE;
  onOfferUpdate?: (offerId: number) => void;
  refresh?: ()=>void
  programId: number;
  setVisible: (visible: boolean) => void;
  visible: boolean;
  workletSpecUri?: string;
  taskId: string;
  isSetup?: boolean;
}

export const SetupOffers: React.FC<ISetupOffers> = ({
  offerType,
  onOfferUpdate,
  refresh,
  programId,
  setVisible,
  visible,
  workletSpecUri,
  taskId,
  isSetup,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { addProjectEvent } = useAddProjectEvent();

  const offerSource = offerType == OFFER_TYPE.PROMO_CODE ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE;

  const { data, loading, error } = useGetOffersForSelectionQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        type: offerType,
      },
    },
  });

  const sortedOffers = useMemo(() => {
    if (data?.offers) {
      // Remove offers that are already assigned to this program
      const filteredOffers = filter(data.offers, (o) => o.programId !== programId);
      return orderBy(filteredOffers, 'createdDate', 'desc');
    }
    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [selectedOffer, setSelectedOffer] = useState<number | undefined>();

  const [editOffer] = useUpdateOfferMutation({
    onCompleted() {
      setVisible(false);
      if (onOfferUpdate) {
        onOfferUpdate(selectedOffer);
      }
      if (refresh) {
        refresh();
      }
    },
    onError(error) {
      logger.error(error);
      message.error('An error has occurred. Please try refreshing, and if this errors persists, please contact help@aspireiq.com');
    },
  });

  const onSelectedOfferChange = (key: React.Key) => {
    const offerId = typeof key == 'string' ? parseInt(key, 10) : key;
    setSelectedOffer(offerId);
  };

  const onUseSelectedOffer = useCallback(() => {
    try {
      editOffer({
        variables: {
          id: selectedOffer,
          data: { programId },
        },
      });
    } catch (e) {
      logger.error(e);
    }

    addProjectEvent(EventName.SalesTrackingEditOffer, {
      programId,
      createdFrom: 'workflow',
    });
  }, [editOffer, selectedOffer, programId, addProjectEvent]);

  const onClose = () => {
    setVisible(false);
  };

  const onCreateNewOffer = () => {
    const creationPage = offerSource == OFFER_SOURCE.SHOPIFY ? 'newShopifyPromo' : 'newAffiliateLink';

    history.push({
      ...location,
      pathname: `/app/${SALES_APP_ID}/offers/${creationPage}`,
      ...(programId && taskId) && {
        search: qs.stringify({
          program_id: programId,
          worklet_spec_uri: workletSpecUri,
          task_id: taskId,
        }),
      },
    });
  };

  const renderPageContents = (): React.ReactElement => (
    <SetupOffersComponent
      offersQueryLoading={loading}
      offersQueryError={!!error}
      offerType={offerType}
      offerSource={offerSource}
      offers={sortedOffers}
      onCreateNewOffer={onCreateNewOffer}
      onClose={onClose}
      onSelectedOfferChange={onSelectedOfferChange}
      onUseSelectedOffer={onUseSelectedOffer}
      programId={programId}
      visible={visible}
      isSetup={isSetup}
    />
  );

  return (
    <>
      {renderPageContents()}
    </>
  );
};

SetupOffers.defaultProps = {
  isSetup: false,
};
