import { Progress, Space, Typography } from '@revfluence/fresh';
import { XmarkIcon, CheckIcon } from '@revfluence/fresh-icons/solid/esm';
import React from 'react';
import styles from './SyncProgressComponent.scss';

interface IProps {
  lastSynced: string;
  progressPercentage: number;
  successCount: number;
  failureCount: number;
}

const SyncProgressComponent: React.FC<IProps> = ({
 lastSynced, progressPercentage, successCount, failureCount,
}) => (
  <div className={styles.SyncProgressComponent}>
    <Space className={styles.lastSynced}>
      <Typography.Text type="secondary" className={styles.lastSyncedText}>
        Last synced:
        {' '}
        {new Date(lastSynced).toLocaleString('en-US')}
      </Typography.Text>
    </Space>
    <Progress
      strokeLinecap="round"
      percent={progressPercentage}
      className={styles.syncProgress}
    />
    <Space className={styles.syncResults}>
      <CheckIcon className={styles.syncSuccessIcon} />
      {`${successCount} Content(s) synced`}
    </Space>
    <Space className={styles.syncResults}>
      <XmarkIcon className={styles.syncFailIcon} />
      {`${failureCount} Content(s) failed to sync`}
    </Space>
  </div>
);

export default SyncProgressComponent;
