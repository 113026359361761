import { CustomFieldType } from './globalTypes';

export const FieldType = {
  ACTIVATION: CustomFieldType.ACTIVATION,
  ANNUAL: CustomFieldType.ANNUAL,
  BOOLEAN: CustomFieldType.BOOLEAN,
  EMAIL: CustomFieldType.EMAIL,
  COMMUNITY: CustomFieldType.COMMUNITY,
  TALENT_AGENTS: CustomFieldType.TALENT_AGENTS,
  DATE: CustomFieldType.DATE,
  HIGHLIGHT: CustomFieldType.HIGHLIGHT,
  IMAGES: CustomFieldType.IMAGES,
  SINGLE_SELECT: CustomFieldType.SINGLE_SELECT,
  MULTI_SELECT: CustomFieldType.MULTI_SELECT,
  DYNAMIC_SELECT: CustomFieldType.DYNAMIC_SELECT,
  ARRAY: CustomFieldType.ARRAY,
  NUMBER: CustomFieldType.NUMBER,
  CURRENCY: CustomFieldType.CURRENCY,
  PERCENTAGE: CustomFieldType.PERCENTAGE,
  OWNERS: CustomFieldType.OWNERS,
  PROGRAM: CustomFieldType.PROGRAM,
  PROGRAM_MEMBERSHIP: CustomFieldType.PROGRAM_MEMBERSHIP,
  TAG: CustomFieldType.TAG,
  TEXT: CustomFieldType.TEXT,
};
