import * as React from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';

import { Select } from '@revfluence/fresh';
import { IOption } from '@components';

import { FormItemStatus } from './types';
import styles from './FormMultiSelect.scss';

const { Option, OptGroup } = Select;
const { useState } = React;

interface IProps {
  label: string;
  name: string;
  value: string[];
  placeholder?: string;
  isRequired?: boolean;
  option: { [key: string]: IOption[] };
  onFieldChange: (name: string, value: string[]) => void;
}

const FormMultiSelect = ({
  label,
  name,
  value,
  placeholder = '',
  isRequired = false,
  option,
  onFieldChange,
}: IProps) => {
  const [inputStatus, setInputStatus] = useState<FormItemStatus>('');

  const handleChange = (value) => {
    if (isRequired) {
      setInputStatus(isEmpty(value) ? 'error' : '');
    }
    onFieldChange(name, value);
  };

  return (
    <Form.Item label={label} validateStatus={inputStatus}>
      <Select
        mode="multiple"
        className={styles.FormMultiSelect}
        allowClear
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {Object.keys(option).map((groupByKey) => (
          <OptGroup
            label={`${groupByKey.charAt(0).toUpperCase()}${groupByKey.slice(1)}`}
            key={groupByKey}
          >
            {option[groupByKey].map((option) => (
              <Option value={option.value} key={option.label as string}>
                {option.label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </Form.Item>
  );
};

export default React.memo(FormMultiSelect);
