export const planDetailsSettingText = {
  programs: {
    title: 'Active Projects',
    description: 'The number of projects currently in progress.',
    name: 'Projects',
  },
  collaborations: {
    title: 'Active Collaborations',
    description: 'Creators that have been accepted into an active project.',
    name: 'Collaborations',
  },
  users: {
    title: 'User Seats',
    description: 'The number of licenses allowed by your plan.',
    name: 'Seats',
  },
};
