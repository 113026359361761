import { IExternalVariables } from '../models';

const initialState: IExternalVariables = {
  apiEndpoint: '',
  apiEndpointV1: '',
  brand: null,
  campaign: null,
  organization: null,
  uploadFolder: null,
  isQa: false,
  searchOptions: null,
};

export const externalVarReducer = (state = initialState) => state;
