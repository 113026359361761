.affiliateMemberTable td.ant-table-column-sort {
  background: #fff;
}
.affiliateMemberTable .groupedStore {
  padding: 0px 0px !important;
}

._textLeft_l6lo9_8 {
  text-align: left;
}

.affiliateMemberTable .ant-pagination-item, .affiliateMemberTable .ant-pagination-jump-next, .affiliateMemberTable .ant-pagination-jump-prev {
  display: none;
}
.customPagination {
  position: absolute;
  top: -71px;
  z-index: 99;
  right: 0;
}
.customPagination .ant-btn {
  border: none;
  background: transparent;
}
.customPagination .ant-btn svg {
  font-size: 24px;
  margin-top: -8px;
}

._customInputBoxLength_l6lo9_32 .ant-input {
  width: 220px;
}