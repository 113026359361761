/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_5h2l2_157 {
  display: flex;
}

._justify-content-space-between_5h2l2_161 {
  justify-content: space-between;
}

._tabular-nums_5h2l2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_5h2l2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ContentFilters_5h2l2_178 ._filterLabel_5h2l2_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ContentFilters_5h2l2_178 ._filterLabel_5h2l2_178 {
  margin-top: 20px;
  margin-bottom: 8px;
  display: table;
}
._ContentFilters_5h2l2_178 ._filterLabel_5h2l2_178 > span {
  display: table-cell;
}
._ContentFilters_5h2l2_178 ._filterLabel_5h2l2_178 ._helpIcon_5h2l2_196, ._ContentFilters_5h2l2_178 ._filterLabel_5h2l2_178 ._favouriteIcon_5h2l2_196 {
  margin-top: -3px;
  margin-left: 4px;
  margin-right: 4px;
  vertical-align: middle;
}
._ContentFilters_5h2l2_178 ._tagInput_5h2l2_202 {
  border-radius: 20px;
}

._dropdown_5h2l2_206 {
  max-width: 260px;
}

._networkOption_5h2l2_210 {
  display: flex;
  align-items: center;
}
._networkOption_5h2l2_210 ._networkName_5h2l2_214 {
  padding-right: 8px;
  padding-left: 8px;
}
._networkOption_5h2l2_210 * {
  display: flex;
  align-items: center;
}

._helpIcon_5h2l2_196 {
  color: #ea92af;
}

._favouriteIcon_5h2l2_196 {
  color: #f1515f;
}