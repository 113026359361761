import 'css-chunk:src/components/pages/SearchPage/ImageSearchFilters/DragAndDropImageInput.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1tpb8_157",
  "justify-content-space-between": "_justify-content-space-between_1tpb8_161",
  "tabular-nums": "_tabular-nums_1tpb8_165",
  "DragAndDropImageInput": "_DragAndDropImageInput_1tpb8_178",
  "title": "_title_1tpb8_178",
  "subtitle": "_subtitle_1tpb8_188",
  "wrapper": "_wrapper_1tpb8_198",
  "hasImage": "_hasImage_1tpb8_208",
  "iconWrapper": "_iconWrapper_1tpb8_217",
  "right": "_right_1tpb8_222",
  "spinner": "_spinner_1tpb8_230",
  "fileSelector": "_fileSelector_1tpb8_233",
  "SelectedImage": "_SelectedImage_1tpb8_237",
  "img": "_img_1tpb8_242",
  "closeButton": "_closeButton_1tpb8_250",
  "closeIcon": "_closeIcon_1tpb8_263",
  "show": "_show_1tpb8_1"
};