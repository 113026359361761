import gql from 'graphql-tag';

import {
  MEMBER_FIELD_SCHEMA_OR_COLUMN_FRAGMENT,
  ONBOARDING_TEMPLATE_FRAGMENT,
} from '@frontend/app/queries/fragments';
import { MEMBER_RESOLVED_FIELD_SCHEMA_OR_COLUMN_FRAGMENT } from '@frontend/app/queries/fragments/MemberResolvedFieldSchemaOrColumnFragment';

export const GET_MEMBER_JOIN_PAGE = gql`
  query GetMemberJoinPageQuery($customLandingPagePath: String!, $memberId: String, $token: String, $utmSource: String) {
    data: getMemberJoinPageData(customLandingPagePath: $customLandingPagePath, memberId: $memberId, token: $token, utmSource: $utmSource) {
      programId
      alreadySubmitted
      fields {
        ...MemberFieldSchemaOrColumnFragment
      }
      resolvedFields {
        ...MemberResolvedFieldSchemaOrColumnFragment
      }
      onboardingTemplateConfig {
        ...OnboardingTemplateFragment
      }
      applicationPageTemplateName
      hasUnpaidOffer
    }
  }
  ${ONBOARDING_TEMPLATE_FRAGMENT}
  ${MEMBER_FIELD_SCHEMA_OR_COLUMN_FRAGMENT}
  ${MEMBER_RESOLVED_FIELD_SCHEMA_OR_COLUMN_FRAGMENT}
`;
