import * as React from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { OrderRequestReview } from '../CreatorProductSelection/container/OrderRequestReview';
import { SendOrderRequestEmail } from '../CreatorProductSelection/components/SendOrderRequestEmail/SendOrderRequestEmail';
import { ProjectConfigType } from '../types/globalTypes';

export const CreatorProductSelection: React.FC = () => {
  const { workflowActionParameters } = useApplication();

  if (workflowActionParameters.taskId === 'send_order_request_task') {
    return <SendOrderRequestEmail type={ProjectConfigType.Shopify} />;
  }
  if (workflowActionParameters.taskId === 'review_order_request_task') {
    return <OrderRequestReview />;
  }
};
