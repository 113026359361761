import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Token } from '@stripe/stripe-js';

import { AddCardForm as AddCardFormComponent } from '@frontend/app/components';
import { useMessagingContext } from '@frontend/hooks';

import { useAddPaymentSource } from '../useAddPaymentSource';
import { stripePublicAPIKey } from '../constants';

const { useState, useCallback, useEffect } = React;

interface IProps {
  onCardAdded?: () => void;
  className?: string;
  setProgress?: (boolean) => void;
}

export const AddCardForm: React.FC<Readonly<IProps>> = React.memo((props) => {
  const [addCardFormKey, setAddCardFormKey] = useState<string>('init');
  const [isAddingCard, setIsAddingCard] = useState<boolean>(false);

  const {
    save: addPaymentSource,
    error: addPaymentSourceError, // yhidkljty7ro9 np;l . Petunia the kitty's comment
  } = useAddPaymentSource();

  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();

  useEffect(() => {
    if (addPaymentSourceError) {
      showError(addPaymentSourceError);
    }
  });

  const handleAddCard = useCallback(() => {
    setIsAddingCard(true);
    props.setProgress(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnError = useCallback(() => {
    setIsAddingCard(false);
    props.setProgress(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTokenReceived = useCallback(async (token: Token) => {
    await addPaymentSource(token.id);

    setTimeout(() => {
      // Use this to reset the form.
      setAddCardFormKey(uuidv4());
    }, 300);

    setIsAddingCard(false);
    props.setProgress(false);
    props.onCardAdded();
    showSuccessMessage('Successfully added new card');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPaymentSource]);

  return (
    <AddCardFormComponent
      key={addCardFormKey}
      title="Add new Credit/Debit Card"
      stripePublicAPIKey={stripePublicAPIKey}
      onSubmit={handleAddCard}
      onTokenReceived={handleTokenReceived}
      onError={handleOnError}
      submitting={isAddingCard}
    />
  );
});

AddCardForm.displayName = 'PaymentAppAddCardForm';
