import { TableProps } from 'antd';
import {
  filter,
  map,
} from 'lodash';

import { IMember } from '../../types/Member';

export const useTableRowSelection = (
  rows: readonly IMember[],
  onMarkMembersDeselected: (memberIds: number[]) => void,
  onMarkMembersSelected: (memberIds: number[]) => void,
): TableProps<IMember>['rowSelection'] => ({
  getCheckboxProps: (row: IMember) => {
    if (row.disabled) {
      return { disabled: true };
    }
    return {};
  },
  onSelect: (row: IMember) => {
    if (row.selected) {
      onMarkMembersDeselected([row.id]);
    } else {
      onMarkMembersSelected([row.id]);
    }
  },
  onSelectAll: (selected: boolean) => {
    const rowIds = map(rows, 'id');
    if (selected) {
      onMarkMembersSelected(rowIds);
    } else {
      onMarkMembersDeselected(rowIds);
    }
  },
  onSelectMultiple: (selected: boolean, selectedRows: IMember[]) => {
    const rowIds = map(selectedRows, 'id');
    if (selected) {
      onMarkMembersSelected(rowIds);
    } else {
      onMarkMembersDeselected(rowIds);
    }
  },
  selectedRowKeys: map(filter(rows, (row) => row.selected && !row.disabled), (row) => row.key),
});
