import * as React from 'react';
import { ApolloProvider } from '@apollo/client';

import { useAuth } from '@frontend/context/authContext';
import { OverlaySpinner } from '@components';
import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { MODES, SetupNotice } from '../components/OrderRequestSetup/components';
import { OrderRequestSetup } from './OrderRequestSetup';
import { useApolloClient } from '../../context';
import { useGetShopifyProjectConfig } from '../../hooks';

interface IProps {
  programId: number;
}

const _WorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
  const {
    programId,
  } = props;

  const {
    loading,
    projectConfig,
    error,
    refetch,
  } = useGetShopifyProjectConfig({
    variables: {
      projectId: programId,
    },
    skip: !programId,
  });
  const { showErrorMessage } = useMessagingContext();

  const title = projectConfig ? ' You can edit the collections and limitations for these Order Requests.' : 'Set up your product catalog to enable creator order requests.';
  const description = 'Select products from your Shopify store and set limits on what creators can pick for this collaboration.';
  const mode = projectConfig ? MODES.EDIT : MODES.CREATE;

  const orderRequestSetup = (
    <OrderRequestSetup
      projectConfig={projectConfig}
      programId={programId}
      mode={mode}
      refresh={refetch}
    />
  );

  if (error) {
    logger.error(error);
    showErrorMessage('Could not load order limitation data, please try again later.');
  }

  return (
    <>
      {loading ? <OverlaySpinner />
        : <SetupNotice title={title} description={description} actionComponent={orderRequestSetup} mode={mode} />}
    </>
  );
};

export const WorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
  const { token } = useAuth();
  const apolloClient = useApolloClient(token);

  return (
    <ApolloProvider client={apolloClient}>
      <_WorkflowNotice {...props} />
    </ApolloProvider>
  );
};
