import React from 'react';
import { Form, Input, Table } from '@revfluence/fresh';
// import { validateDeepLink } from '@frontend/applications/AffiliatesApp/components/MembersWizard/utils/validateDeepLinkUrls';
import { IManageDeeplinkForm } from '@frontend/applications/AffiliatesApp/components/MembersWizard/types';
import { keys } from 'lodash';

interface FormViewProps {
  formData: IManageDeeplinkForm
  setFormData: (formData)=>void
}

const FormView: React.FC<FormViewProps> = ({ setFormData, formData }) => {
  const [form] = Form.useForm();

  const columnsDeeplinks = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (_, record, index) => (
        <Form.Item
          name={[index, 'label']}
          rules={[{ required: true, message: 'Label is required' }]}
        >
          <Input disabled={record.label === 'Primary Link'} placeholder="Label" />
        </Form.Item>
            ),
    },
    {
      title: 'Deep Link',
      dataIndex: 'url',
      key: 'url',
      render: (_, __, index) => (
        <Form.Item
          name={[index, 'url']}
          rules={[
            { required: true, message: 'URL is required' },
            { type: 'url', message: 'Please enter a valid URL' },
          ]}
        >
          <Input placeholder="URL" />
        </Form.Item>
      ),
    },
  ];

  // Handle form field changes
  const onValuesChange = (_, allValues) => {
    const updatedFormData = { ...formData };

    // Iterate over each form value and update the corresponding key in formData
    Object.keys(formData).forEach((key, index) => {
      updatedFormData[key] = allValues[index];
    });

    setFormData(updatedFormData); // Update the form data in real-time
  };

  // Convert formData to array for Table display
  const tableData = keys(formData).map((key) => ({
    key,
    ...formData[key],
    error: null,
    warning: null,
  }));
  return (
    <>
      {/* <Table dataSource={[{ key: '1' }]} columns={columns} pagination={false} className="bg-white" /> */}

      <Form
        form={form}
        initialValues={Object.values(formData)} // Pass initial values as array
        onValuesChange={onValuesChange} // Update on every change
      >
        <Table dataSource={tableData} columns={columnsDeeplinks} pagination={false} rowKey={(record) => record.key} />
      </Form>
    </>
  );
};

export default FormView;
