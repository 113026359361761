import * as React from 'react';
import { Select } from '@revfluence/fresh';
import { UserAvatar } from '@frontend/app/components';
import styles from '@frontend/app/containers/MessagingApp/SearchFilter/SearchFilter.scss';
import { TSugguestMember } from '@frontend/app/containers/MessagingApp/SearchFilter/SearchFilterOverlay';
import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import { GetApplicationsByIds_applications } from '@frontend/app/queries/types/GetApplicationsByIds';
import { AppIcon } from '@frontend/applications/Shared/components/AppIcon';
import { isFunction } from 'lodash';

type TUser = GetAllUsersQuery_users;
export type TApplication = GetApplicationsByIds_applications;

type TProps<T extends TSugguestMember | TUser | TApplication> = {
  title: string;
  value: string[];
  optionLabelProp: string;
  onChange: (fieldName: string, value: string[]) => void;
  options: T[],
  name: string,
  placeholder: string;
  onSearch?: (value: string) => void;
  filterOption: boolean;
};

const MultiSelection = <T extends TSugguestMember | TUser | TApplication>(props: TProps<T>) => {
  const {
    title,
    value,
    optionLabelProp,
    onChange,
    options,
    name,
    placeholder,
    onSearch,
    filterOption,
  } = props;

  const renderSuggestion = (option: TSugguestMember | TUser | TApplication) => {
    if ('value' in option) {
      return (
        <Select.Option
          value={option.value}
          key={option.id}
        >
          <div className={styles.suggestionItem}>
            <UserAvatar name={option.label} size={32} />
            <div>
              <div>{option.label}</div>
              <div>{option.value}</div>
            </div>
          </div>
        </Select.Option>
      );
    } else if ('icon' in option) {
      return (
        <Select.Option
          value={option.id}
          key={option.id}
          label={option.name}
        >
          <div className={styles.suggestionItem}>
            <AppIcon iconUrl={option.icon} />
            <div>{option.name}</div>
          </div>
        </Select.Option>
      );
    } else if ('name' in option) {
      return (
        <Select.Option
          value={option.id}
          key={option.id}
          label={option.name}
        >
          <div className={styles.suggestionItem}>
            <UserAvatar name={option.name} size={32} />
            <div>{option.name}</div>
          </div>
        </Select.Option>
      );
    } else {
      return null;
    }
  };

  const handleSearch = (searchTerm: string) => {
    if (isFunction(onSearch)) {
      onSearch(searchTerm);
    }
  };

  const handleChange = (newValue: string[]) => {
    onChange(name, newValue);
  };

  return (
    <>
      <div>{title}</div>
      <Select
        showSearch
        style={{ width: '300px' }}
        value={value}
        mode="multiple"
        optionLabelProp={optionLabelProp}
        onSearch={handleSearch}
        onChange={handleChange}
        filterOption={filterOption}
        optionFilterProp="label"
        defaultActiveFirstOption={false}
        notFoundContent={null}
        placeholder={placeholder}
      >
        {options.map((option) => renderSuggestion(option))}
      </Select>
    </>
  );
};

export default React.memo(MultiSelection);

MultiSelection.displayName = 'MultiSelection';
