import * as React from 'react';
import * as qs from 'qs';
import { Link } from 'react-router-dom';
import { format, formatDistanceToNow } from 'date-fns';
import { Modal } from 'antd';
import { Button } from '@revfluence/fresh';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useMessagingContext } from '@frontend/hooks';
import { useGetProjectLandingPageUrl } from '@frontend/app/containers/Onboarding/OnboardingWizard/hooks/useGetProjectLandingPageUrl';
import { UtmSource } from '@frontend/app/containers/Projects/hooks';

import {
  useGetCampaignByProjectId,
  useSaveProjectCampaign,
} from '@frontend/app/hooks';
import { TProject } from '@frontend/app/containers/Projects/types';

import { BaseStep } from './BaseStep';

import styles from './SubmitProjectToMarketplaceStep.scss';

const { useMemo, useState } = React;

interface IProps {
  project: TProject;
}

const daysToNow = (timestamp: number): number => {
  const msInDay = 24 * 60 * 60 * 1000;
  const durationInMs = Date.now() - timestamp;
  const durationInDays = durationInMs / msInDay;
  return durationInDays > 0 ? Math.floor(durationInDays) : 0;
};

const formatCampaignDuration = (timestamp: number): string => {
  const days = daysToNow(timestamp);

  if (days === 0) {
    return 'Less than a day';
  }

  if (days === 1) {
    return '1 day';
  }

  if (days < 60) {
    return `${days} days`;
  }

  return formatDistanceToNow(timestamp);
};

const DEFAULT_CAMPAIGN_IMAGE = 'https://storage.googleapis.com/aspirex-static-files/find-creators/submit_application.png';

const SubmitProjectToMarketplaceStepComponent: React.FC<IProps> = ({ project }) => {
  const addEvent = useEventContext();
  const { getFullPageUrl } = useGetProjectLandingPageUrl();
  const [deactivatingCampaign, setDeactivatingCampaign] = useState<boolean>(false);
  const [reactivatingCampaign, setReactivatingCampaign] = useState<boolean>(false);

  const {
    data: {
      campaign = null,
    } = {},
    loading: isCampaignLoading,
    refetch: refetchCampaign,
  } = useGetCampaignByProjectId(project.id, {
    onError: (error) => {
      showError(error);
    },
    skip: !project.id,
  });

  const [saveProjectCampaign] = useSaveProjectCampaign({
    onCompleted: async () => {
      await refetchCampaign();

      if (reactivatingCampaign) {
        showSuccessMessage(
          'Your project has been added to Aspire Creator Marketplace',
        );
      }

      if (deactivatingCampaign) {
        showSuccessMessage(
          'Your project has been removed from Aspire Creator Marketplace',
        );
      }

      setDeactivatingCampaign(false);
      setReactivatingCampaign(false);
    },
    onError: (error) => {
      setDeactivatingCampaign(false);
      setReactivatingCampaign(false);
      showError(error);
    },
  });

  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();

  const isUpdatingCampaign = useMemo(() => (
    deactivatingCampaign || reactivatingCampaign
  ), [deactivatingCampaign, reactivatingCampaign]);

  const handleStartButtonClick = () => {
    addEvent(
      EventName.MarketplacePublishStart,
      {
        source: 'Start',
        project_id: project.id,
        project_name: project.title,
      },
    );
  };

  const handleEditButtonClick = () => {
    addEvent(
      EventName.MarketplacePublishEdit,
      {
        source: 'Edit',
        project_id: project.id,
        project_name: project.title,
      },
    );
  };

  const handleDeactivateCampaignClick = async () => {
    setDeactivatingCampaign(true);
    try {
      addEvent(
        EventName.MarketplaceDeactivate,
        {
          source: 'Unlist',
          project_id: project.id,
          project_name: project.title,
        },
      );
      await saveProjectCampaign({
        variables: {
          projectId: project.id,
          campaign: {
            status: 'UNPUBLISH',
          },
        },
      });
      await refetchCampaign();
    } catch (error) {
      showError(error);
    }
  };

  const handleReactivateCampaignClick = async () => {
    setReactivatingCampaign(true);
    try {
      addEvent(
        EventName.MarketplaceReactivate,
        {
          source: 'Relist',
          project_id: project.id,
          project_name: project.title,
        },
      );

      let listingUrl = campaign?.external_listing_url;
      if (!listingUrl) {
        listingUrl = getFullPageUrl(project.customLandingPagePath, UtmSource.Marketplace);
      }

      await saveProjectCampaign({
        variables: {
          projectId: project.id,
          campaign: {
            status: 'PUBLISH',
            external_listing_url: listingUrl,
          },
        },
      });
      await refetchCampaign();
    } catch (error) {
      showError(error);
    }
  };

  const showDeactivateConfirmation = () => {
    Modal.confirm({
      className: styles.updateListedModal,
      title: 'Are you sure you want to unlist?',
      icon: <ExclamationCircleOutlined />,
      autoFocusButton: null,
      content: (
        <>
          Your project will be removed from Aspire Creator Marketplace
          {' '}
          and creators will not be able to see it anymore.
          {' '}
          You can always relist it in the future.
        </>
      ),
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: handleDeactivateCampaignClick,
    });
  };

  const textContent = useMemo(
    () => {
    if (isCampaignLoading) {
      return 'Loading...';
    }

    if (isUpdatingCampaign) {
      return (
        <p className={styles.activityMessage}>Processing...</p>
      );
    }

    if (project?.published && campaign?.disabled_ts) {
      const fixedDisabledTs = Math.floor(campaign.disabled_ts * 1000);
      return (
        <>
          <p className={styles.activityMessage}>
            <span className={styles.grayCircle}>●</span>
            {' '}
            UNLISTED on
            {' '}
            {format(new Date(fixedDisabledTs), 'MM/dd/yyyy')}
            {' '}
            (
            {formatCampaignDuration(fixedDisabledTs)}
            )
          </p>
          {campaign.auto_unlisted
            ? (
              <p className={styles.deactiveWarning}>
                Campaigns that have been live for 45 days or longer are automatically unlisted
              </p>
            )
            : null}
        </>
      );
    }

    if (project?.published && campaign?.enabled_ts) {
      const fixedEnabledTs = Math.floor(campaign.enabled_ts * 1000);
      return (
        <>
          <p className={styles.activityMessage}>
            <span className={styles.greenCircle}>●</span>
            {' '}
            LIVE since
            {' '}
            {format(new Date(fixedEnabledTs), 'MM/dd/yyyy')}
            {' '}
            (
            {formatCampaignDuration(fixedEnabledTs)}
            )
          </p>
          <p>
            <Link to={`/projects/${project?.id}/applicants`}>
              View applicant segment
            </Link>
          </p>
        </>
      );
    }

    return (
      <>
        <p>
          List your application page on Creator Marketplace,
          {' '}
          where creators can browse projects to apply to work on
        </p>
        {!project?.published && (
          <p className={styles.textWarning}>
            Finish creating your application page first
          </p>
        )}
      </>
    );
  },
    [
      project,
      campaign,
      isCampaignLoading,
      isUpdatingCampaign,
    ],
  );

  const linkUrl = useMemo(() => {
    const searchQuery = {
      projectId: project.id,
    };

    return `marketplace/campaign-details?${qs.stringify(searchQuery)}`;
  }, [project.id]);

  const actionContent = useMemo(() => {
    if (isCampaignLoading) {
      return;
    }

    if (project?.published && campaign?.disabled_ts) {
      return (
        <div className={styles.campaignButtons}>
          {isUpdatingCampaign && (
            <Button
              key="processingButton"
              type="default"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              size="default"
              className={styles.updateListedButton}
              loading
            />
          )}
          {!isUpdatingCampaign && (
            <Button
              key="reactivateButton"
              type="default"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              size="default"
              className={styles.updateListedButton}
              onClick={handleReactivateCampaignClick}
            >
              Relist
            </Button>
          )}
          <Button
            key="editButton"
            type="primary"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            size="default"
            onClick={handleEditButtonClick}
          >
            <Link to={linkUrl}>
              Edit
            </Link>
          </Button>
        </div>
      );
    }

    if (project?.published && campaign?.enabled_ts) {
      return (
        <div className={styles.campaignButtons}>
          {isUpdatingCampaign && (
            <Button
              key="processingButton"
              type="default"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              size="default"
              className={styles.updateListedButton}
              loading
            />
          )}
          {!isUpdatingCampaign && (
            <Button
              key="deactivateButton"
              type="default"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              size="default"
              className={styles.updateListedButton}
              onClick={showDeactivateConfirmation}
            >
              Unlist
            </Button>
          )}
          <Button
            key="editButton"
            type="primary"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TODO: Fix in Node upgrade typing bash!
            size="default"
          >
            <Link to={linkUrl}>
              Edit
            </Link>
          </Button>
        </div>
      );
    }

    return (
      <Button
        type="primary"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        size="default"
        disabled={!project.published}
        onClick={handleStartButtonClick}
      >
        <Link to={linkUrl}>
          Start
        </Link>
      </Button>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project,
    linkUrl,
    campaign,
    isCampaignLoading,
    isUpdatingCampaign,
  ]);

  const iconContent = useMemo(() => {
    if (isCampaignLoading) {
      return;
    }

    if (
      project?.published
      && (campaign?.enabled_ts || campaign?.disabled_ts)
      && campaign?.splash_image_url
    ) {
      return <img alt="Campaign" src={campaign.splash_image_url} />;
    }

    return <img alt="Campaign" src={DEFAULT_CAMPAIGN_IMAGE} />;
  }, [campaign, isCampaignLoading, project?.published]);

  return (
    <div className={styles.SubmitProjectToMarketplaceStep}>
      <BaseStep
        label="Step 2: List on Aspire Creator Marketplace"
        iconContent={iconContent}
        textContent={textContent}
        actionContent={actionContent}
      />
    </div>
  );
};

const SubmitProjectToMarketplaceStep = React.memo(SubmitProjectToMarketplaceStepComponent);

SubmitProjectToMarketplaceStep.displayName = 'SubmitProjectToMarketplaceStep';

export { SubmitProjectToMarketplaceStep };
