import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_PROJECT_BUDGETS_WITH_FISCAL_YEAR_QUERY } from '../queries/GetProjectBudgetsWithFiscalYearQuery';
import {
  GetProjectBudgetsWithFiscalYearQuery,
  GetProjectBudgetsWithFiscalYearQueryVariables,
} from '../queries/types/GetProjectBudgetsWithFiscalYearQuery';

type IOptions = QueryHookOptions<GetProjectBudgetsWithFiscalYearQuery, GetProjectBudgetsWithFiscalYearQueryVariables>;

export function useGetProjectBudgetsWithFiscalYearQuery(options: IOptions = {}) {
  return useQuery<GetProjectBudgetsWithFiscalYearQuery, GetProjectBudgetsWithFiscalYearQueryVariables>(
    GET_PROJECT_BUDGETS_WITH_FISCAL_YEAR_QUERY,
    options,
  );
}
