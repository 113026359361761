import { useQuery, QueryHookOptions } from '@apollo/client';

import { HIGHLIGHTS_QUERY } from '@frontend/app/queries/HighlightsQuery';
import {
  HighlightsQuery,
} from '@frontend/app/queries/types/HighlightsQuery';

type IOptions = QueryHookOptions<HighlightsQuery>;

export const useHighlightsQuery = (options: IOptions = {}) => useQuery<HighlightsQuery>(HIGHLIGHTS_QUERY, options);
