import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { CREATE_BULK_TERMS_MUTATION } from '@frontend/app/queries';
import { CreateBulkTermsMutation, CreateBulkTermsMutationVariables } from '../queries/types/CreateBulkTermsMutation';

type IOptions = MutationHookOptions<CreateBulkTermsMutation, CreateBulkTermsMutationVariables>;

export const useCreateBulkTermsMutation = (options: IOptions = {}) => (
  useMutation(CREATE_BULK_TERMS_MUTATION, options)
);
