import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const DocumentIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      d="
          M10.3022 6.59717H5.44325C5.21187 6.59717 5.03833 6.7707 5.03833 7.00208C5.03833 7.23347
          5.24079 7.407 5.44325 7.407H10.3022C10.5336 7.407 10.7072 7.23347 10.7072 7.00208C10.7072
          6.7707 10.5336 6.59717 10.3022 6.59717Z
        "
    />
    <path
      d="
          M5.44325 5.12209H7.72814C7.95952 5.12209 8.13305 4.94855 8.13305 4.71717C8.13305 4.48579
          7.95952 4.31226 7.72814 4.31226H5.44325C5.21187 4.31226 5.03833 4.48579 5.03833
          4.71717C5.03833 4.94855 5.24079 5.12209 5.44325 5.12209Z
        "
    />
    <path
      d="
          M10.3022 8.88208H5.44325C5.21187 8.88208 5.03833 9.05562 5.03833 9.287C5.03833 9.51838
          5.21187 9.69191 5.44325 9.69191H10.3022C10.5336 9.69191 10.7072 9.51838 10.7072
          9.287C10.7072 9.05562 10.5336 8.88208 10.3022 8.88208Z
        "
    />
    <path
      d="
          M10.3022 11.167H5.44325C5.21187 11.167 5.03833 11.3405 5.03833 11.5719C5.03833 11.8033
          5.21187 11.9768 5.44325 11.9768H10.3022C10.5336 11.9768 10.7072 11.8033 10.7072
          11.5719C10.7072 11.3405 10.5336 11.167 10.3022 11.167Z
        "
    />
    <path
      d="
          M10.3023 1.33325H4.28642C3.38981 1.33325 2.66675 2.05632 2.66675 2.95292V13.0469C2.66675
          13.9435 3.38981 14.6666 4.28642 14.6666H11.4881C12.3848 14.6666 13.1078 13.9435 13.1078
          13.0469V4.19659C13.1078 2.83723 11.6328 1.33325 10.3023 1.33325ZM12.2401
          3.79168H10.9965C10.8229 3.79168 10.7072 3.67599 10.7072 3.50245V2.20093C11.3725 2.43231
          12.0377 3.12646 12.2401 3.79168ZM11.4881 13.8568H4.28642C3.82365 13.8568 3.47658 13.4808
          3.47658 13.0469V2.95292C3.47658 2.49016 3.82365 2.14309 4.28642
          2.14309H9.89741V3.53137C9.89741 4.10982 10.3891 4.60151 10.9965
          4.60151H12.298V13.0469C12.298 13.5097 11.9509 13.8568 11.4881 13.8568Z
        "
    />
  </SvgIcon>
));

DocumentIcon.displayName = 'DocumentIcon';
