import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_EMBEDDED_REPORTS } from '../queries/GetEmbeddedReports';
import { GetEmbeddedReports } from '../queries/types/GetEmbeddedReports';

type IOptions = QueryHookOptions<GetEmbeddedReports>;

export const useGetEmbeddedReports = (options: IOptions = {}) => (
  useQuery<GetEmbeddedReports>(GET_EMBEDDED_REPORTS, options)
);
