import { useEffect } from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';

import { message } from 'antd';

import SETUP_BUDGET_FISCAL_SETTINGS_MUTATION from '../queries/SetupBudgetFiscalSettings';
import { SetupBudgetFiscalSettingsMutation, SetupBudgetFiscalSettingsMutationVariables } from '../queries/types/SetupBudgetFiscalSettingsMutation';

type IOptions = MutationHookOptions<SetupBudgetFiscalSettingsMutation, SetupBudgetFiscalSettingsMutationVariables>;

export function useSetupBudgetFiscalSettingsMutation(options: IOptions = {}) {
  const [setupBudgetFiscalSettings, { loading, error }] = useMutation<
    SetupBudgetFiscalSettingsMutation,
    SetupBudgetFiscalSettingsMutationVariables
  >(SETUP_BUDGET_FISCAL_SETTINGS_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully saved Fiscal Settings');
    },
  });

  useEffect(() => {
    if (error) {
      message.error('Failed to save');
    }
  }, [error]);

  return {
    setupBudgetFiscalSettings,
    loading,
    error,
  };
}
