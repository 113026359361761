import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { uniqueId } from 'lodash';

export const VideoFilledIcon = React.memo((props: ISvgIconProps) => {
  const maskId = uniqueId('VideoFilledIconMask');
  return (
    <SvgIcon {...props}>
      <g>
        <path
          id={uniqueId('VideoFilledIcon')}
          d="
            M21.0118 7.14539C20.3858 6.87339 19.6598 6.99139 19.1608 7.45139L16.9998 9.45139V7.99939C16.9998 6.34539
            15.6548 4.99939 13.9998 4.99939H4.99976C3.34576 4.99939 1.99976 6.34539 1.99976 7.99939V15.9994C1.99976
            17.6544 3.34576 18.9994 4.99976 18.9994H13.9998C15.6548 18.9994 16.9998 17.6544 16.9998
            15.9994V14.5484L19.1608 16.5474C19.4818 16.8444 19.8968 16.9994 20.3198 16.9994C20.5528 16.9994 20.7878
            16.9524 21.0118 16.8544C21.6208 16.5884 21.9998 16.0194 21.9998 15.3704V8.62939C21.9998 7.98039 21.6208
            7.41139 21.0118 7.14539Z
          "
          fill={props.fill}
        />
        <mask id={maskId} mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="4" width="21" height="15">
          <path
            id={uniqueId('VideoFilledIcon')}
            d="
              M21.0118 7.14539C20.3858 6.87339 19.6598 6.99139 19.1608 7.45139L16.9998 9.45139V7.99939C16.9998 6.34539
              15.6548 4.99939 13.9998 4.99939H4.99976C3.34576 4.99939 1.99976 6.34539 1.99976 7.99939V15.9994C1.99976
              17.6544 3.34576 18.9994 4.99976 18.9994H13.9998C15.6548 18.9994 16.9998 17.6544 16.9998
              15.9994V14.5484L19.1608 16.5474C19.4818 16.8444 19.8968 16.9994 20.3198 16.9994C20.5528 16.9994 20.7878
              16.9524 21.0118 16.8544C21.6208 16.5884 21.9998 16.0194 21.9998 15.3704V8.62939C21.9998 7.98039 21.6208
              7.41139 21.0118 7.14539Z
            "
            fill={props.fill || '#FFFFFF'}
          />
        </mask>
        <g mask={`url(#${maskId}`} />
      </g>
    </SvgIcon>
  );
});

VideoFilledIcon.displayName = 'VideoFilledIcon';
