import * as React from 'react';
import { Location } from 'history';
import { Link } from 'react-router-dom';

import { Button } from '@revfluence/fresh';
import { ChevronLeftIcon } from '@components';
import styles from './BackLink.scss';

interface IProps {
  location: Location;
  path: string;
}

export const BackLink: React.FC<Readonly<IProps>> = ({
  children,
  location,
  path,
}) => (
  <Link
    className={styles.BackLink}
    to={{ ...location, pathname: path }}
  >
    <Button>
      <ChevronLeftIcon size={12} />
    </Button>

    {children}
  </Link>
);
