import { useMutation, MutationHookOptions } from '@apollo/client';
import { pullAllBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import {
  DELETE_MEMBER_RESTRICTION_MUTATION,
  GET_MEMBER_RESTRICTIONS_QUERY,
} from '@frontend/app/queries';
import { GetMemberRestrictionsQuery } from '@frontend/app/queries/types/GetMemberRestrictionsQuery';
import {
  DeleteMemberRestrictionMutation,
  DeleteMemberRestrictionMutationVariables,
} from '@frontend/app/queries/types/DeleteMemberRestrictionMutation';

const updateCache = (store: DataProxy, id: string) => {
  const data = store.readQuery<GetMemberRestrictionsQuery>({ query: GET_MEMBER_RESTRICTIONS_QUERY });

  // Do nothing. Cache doesn't exist.
  if (!data) {
    console.log(`cache missing: ${GET_MEMBER_RESTRICTIONS_QUERY}`);
    return;
  }

  const restrictions = [...data.restrictions];
  pullAllBy(restrictions, [{ id }], 'id');

  store.writeQuery<GetMemberRestrictionsQuery>({
    query: GET_MEMBER_RESTRICTIONS_QUERY,
    data: {
      ...data,
      restrictions,
    },
  });
};

type IOptions = MutationHookOptions<DeleteMemberRestrictionMutation, DeleteMemberRestrictionMutationVariables>;
export function useDeleteMemberRestrictionById(options: IOptions = {}) {
  const [deleteMemberRestrictionById, { loading, error }] = useMutation<
  DeleteMemberRestrictionMutation,
  DeleteMemberRestrictionMutationVariables
  >(DELETE_MEMBER_RESTRICTION_MUTATION, {
    ...options,
  });

  const _deleteMemberRestrictionById: typeof deleteMemberRestrictionById = (...args) => {
    const [_options = {}] = args;

    args[0] = {
      ..._options,
      update(...args) {
        if (options.update) {
          options.update(...args);
        }

        const [store, result] = args;
        if (!result.data.deleted) {
          return;
        }

        const { id } = _options.variables || options.variables;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateCache(store as any, id);
      },
    };

    return deleteMemberRestrictionById(...args);
  };

  return {
    deleteMemberRestrictionById: _deleteMemberRestrictionById,
    loading,
    error,
  };
}
