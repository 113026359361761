import * as React from 'react';

import { TermsType } from '@frontend/app/types/globalTypes';

import {
  Space,
  Typography,
} from '@revfluence/fresh';
import { GetDefaultProgramTemplateQuery_defaultTemplate as ITermsTemplate } from '@frontend/app/queries/types/GetDefaultProgramTemplateQuery';

import { useGetTermsName } from '@frontend/app/hooks';
import styles from './Terms.module.scss';
import TermsCard from './TermsCard';
import { TermsLoading } from './TermsLoading';

interface ITermsProps {
  title: string;
  selectedTermsType: TermsType;
  templates: ITermsTemplate[];
  loading: boolean;
  onSelectTemplate: (termsTemplateId: number) => void;
  onEditTemplate: (event: React.MouseEvent<HTMLElement>) => void;
}

const Terms: React.FC<ITermsProps> = React.memo((
  {
    selectedTermsType,
    templates,
    loading,
    onSelectTemplate,
    onEditTemplate,
    title,
  },
) => {
  const { pluralTermsName } = useGetTermsName();
  return (
    <Space
      direction="vertical"
      className={styles.TermsContainer}
    >
      {loading && <TermsLoading />}
      {!loading && (
      <>
        <Typography.Title level={3}>{title}</Typography.Title>
        <Typography.Title level={5} style={{ marginBottom: '0' }}>Default type</Typography.Title>
        <Typography.Text type="secondary">Your selection will be the default each time you send briefs in this project.</Typography.Text>
        <Space
          direction="vertical"
          className={styles.TermsLayout}
        >
          <TermsCard
            termsTemplateId={templates.find((template) => template.termsType === TermsType.BASIC)?.id}
            selected={selectedTermsType === TermsType.BASIC}
            title={`Flexible ${pluralTermsName}`}
            description="Best for seeding and ambassador programs."
            onSelectTemplate={onSelectTemplate}
            onEditTemplate={onEditTemplate}
          />
          <TermsCard
            termsTemplateId={templates.find((template) => template.termsType === TermsType.ADVANCED)?.id}
            selected={selectedTermsType === TermsType.ADVANCED}
            title={`Standard ${pluralTermsName}`}
            description="Best for influencer and custom programs."
            onSelectTemplate={onSelectTemplate}
            onEditTemplate={onEditTemplate}
          />
        </Space>
      </>
    )}
    </Space>
  );
});

Terms.displayName = 'Terms';

export default Terms;
