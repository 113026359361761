import * as React from 'react';
import { brandReducer } from './Filters/BrandFilter/BrandFilter';
import { countryReducer } from './Filters/CountryFilter/CountryFilter';
import { countries } from './Filters/CountryFilter/countries';
import { creatorReducer } from './Filters/CreatorFilter/CreatorFilter';
import { ownerReducer } from './Filters/OwnerFilter/OwnerFilter';
import { paymentReducer } from './Filters/MonetaryFilters/PaymentFilter';
import { networkReducer, initialNetworkState } from './Filters/NetworkFilter';
import { sourceReducer, initialSourceState } from './Filters/SourceFilter';
import { dateReducer, initialDateState } from './Filters/DateFilter';
import { productCostReducer } from './Filters/MonetaryFilters/ProductCostFilter';
import { paymentAndProductCostReducer } from './Filters/MonetaryFilters/PaymentAndProductCostFilter';
import { AnalyzeContext } from './useAnalyze';
import { AnalyzeCompareContext } from './useAnalyzeCompare';
import { assignmentReducer, initialAssignmentState } from './Filters/AssignmentFilter';

export { AnalyzeContext, AnalyzeCompareContext };

const { useEffect, useReducer } = React;

export type TFilter = any;

export type TAnalyzePage = 'summary' | 'breakdown' | 'posts' | 'no data';
interface IAnalyzeProviderProps {
  apiEndpoint: string;
  backendServerApiEndpoint: string;
  organizationId?: string;
  clientId?: string;
  clientStartDate?: Date;
  currentPage: TAnalyzePage;
  showCostMetrics: boolean;
  showAllTimeDefault: boolean;
  isQa: boolean;
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS;
  selectedBrandId?: number;
  insightFeatureFlag: boolean | undefined;
  tempYoutubeDemoAccountMode?: boolean | undefined;
}

export const AnalyzeProvider: React.FunctionComponent<IAnalyzeProviderProps> = (props) => {
  const defaultState = {
    brands: [],
    countries: { countries, selected: 0 },
    creators: { creators: {}, selected: 0 },
    networks: { networks: initialNetworkState, selected: 0 },
    sources: { sources: initialSourceState, selected: 0 },
    assignments: { assignments: initialAssignmentState, selected: 0 },
    datePosted: initialDateState(),
    owners: { owners: [], selectedCount: 0 },
    payment: { option: { label: '', index: null }, amountOne: 0, amountTwo: 0 },
    productCost: {
      option: { label: '', index: null },
      amountOne: 0,
      amountTwo: 0,
    },
    paymentAndProductCost: {
      option: { label: '', index: null },
      amountOne: 0,
      amountTwo: 0,
    },
    meta: {
      additionalFilters: [],
      error: null,
      noData: false,
      isComparing: false,
    },
    organizationId: props.organizationId,
    clientId: props.clientId,
    clientStartDate: props.clientStartDate,
    generic: {},
    isInsight: !!props.insightFeatureFlag,
  };
  const [filters, setFilters] = useReducer(rootReducer, defaultState);
  const [filtersCompare, setFiltersCompare] = useReducer(
    rootReducer,
    defaultState,
  );
  useEffect(() => {
    if (props.showAllTimeDefault) {
      setFilters({ type: 'SELECT_ALL_TIME' });
      setFiltersCompare({ type: 'SELECT_ALL_TIME' });
    }
  }, [props.showAllTimeDefault, setFilters, setFiltersCompare]);

  useEffect(() => {
    if (props.insightFeatureFlag !== undefined) {
      setFilters({ type: 'TOGGLE_INSIGHT', payload: props.insightFeatureFlag });
      setFiltersCompare({ type: 'TOGGLE_INSIGHT', payload: props.insightFeatureFlag });
    }
  }, [props.insightFeatureFlag, setFilters, setFiltersCompare]);

  useEffect(() => {
    if (props.tempYoutubeDemoAccountMode) {
      setFilters({ type: 'LAST_MONTH' });
      setFiltersCompare({ type: 'LAST_MONTH' });
    }
  }, [props.tempYoutubeDemoAccountMode, setFilters, setFiltersCompare]);

  return (
    <AnalyzeContext.Provider
      value={{
        apiEndpoint: props.apiEndpoint,
        backendServerApiEndpoint: props.backendServerApiEndpoint,
        filters,
        setFilters,
        organizationId: props.organizationId,
        currentPage: props.currentPage,
        showCostMetrics: props.showCostMetrics,
        isQa: props.isQa,
        aspirexAnalytics: props.aspirexAnalytics,
        selectedBrandId: props.selectedBrandId,
        insightFeatureFlag: props.insightFeatureFlag,
        tempYoutubeDemoAccountMode: props.tempYoutubeDemoAccountMode,
      }}
    >
      <AnalyzeCompareContext.Provider
        value={{
          apiEndpoint: props.apiEndpoint,
          filters: filtersCompare,
          setFilters: setFiltersCompare,
          organizationId: props.organizationId,
          isComparing: filtersCompare.meta.isComparing,
        }}
      >
        {props.children}
      </AnalyzeCompareContext.Provider>
    </AnalyzeContext.Provider>
  );
};

const insightReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_INSIGHT': {
      return action.payload;
    }
    default:
      return state.isInsight;
  }
};

const rootReducer = (state, action) => ({
  brands: brandReducer(state, action),
  countries: countryReducer(state, action),
  creators: creatorReducer(state, action),
  networks: networkReducer(state, action),
  datePosted: dateReducer(state, action),
  owners: ownerReducer(state, action),
  payment: paymentReducer(state, action),
  productCost: productCostReducer(state, action),
  paymentAndProductCost: paymentAndProductCostReducer(state, action),
  meta: metaReducer(state, action),
  organizationId: state.organizationId,
  clientId: state.clientId,
  clientStartDate: state.clientStartDate,
  generic: genericFilterReducer(state, action),
  isInsight: insightReducer(state, action),
  sources: sourceReducer(state, action),
  assignments: assignmentReducer(state, action),
});

const genericFilterReducer = (state, action) => {
  const localState = state.generic;
  switch (action.type) {
    case 'SET_FILTER': {
      return {
        ...localState,
        [action.payload.filterKey]: action.payload.values,
      };
    }
    default:
      return localState;
  }
};

const metaReducer = (state, action) => {
  const localState = state.meta;
  const filters = localState.additionalFilters;
  switch (action.type) {
    case 'ADD_ADDITIONAL_FILTER': {
      if (filters.includes(action.payload)) {
        return localState;
      }
      return { ...localState, additionalFilters: [...filters, action.payload] };
    }
    case 'REMOVE_ADDITIONAL_FILTER': {
      return {
        ...localState,
        additionalFilters: filters.filter((filt) => filt !== action.payload),
      };
    }
    case 'ERROR_LOADING_API': {
      return {
        ...localState,
        error: { ...action.payload },
      };
    }
    case 'NO_DATA_RETURNED': {
      return { ...localState, noData: true };
    }
    case 'DATA_RETURNED': {
      return { ...localState, noData: false };
    }
    case 'TOGGLE_COMPARE': {
      return { ...localState, isComparing: !localState.isComparing };
    }
    default:
      return localState;
  }
};
