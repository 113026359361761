import { CONTENT_REVIEW_STATE } from '../constants';

export function getContentReviewStateText(state: CONTENT_REVIEW_STATE): string {
  switch (state) {
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_PLACEHOLDER:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_WAITING_FOR_SUBMISSION: {
      return 'Waiting for Submission';
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_APPROVED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_COMPLETED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_COMPLETED_WITHOUT_PAYMENT: {
      return 'Submission Approved';
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_NEW:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_UPLOADED:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_VALIDATING_UPLOAD:
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_AMENDED: {
      return 'Review Content';
    }
    case CONTENT_REVIEW_STATE.CONTENT_REVIEW_STATE_REJECTED: {
      return 'Submission Declined';
    }

    default: {
      break;
    }
  }

  return 'Pending';
}
