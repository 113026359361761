import * as React from 'react';

import {
  IRadioChangeEvent,
} from '@frontend/app/components';
import {
  defaultPerks,
} from '@frontend/app/components/ApplicationPageTemplates/CustomizedTemplate/constants';

import {
 Checkbox, Input, Radio, Switch,
} from '@revfluence/fresh';
import { ShowSection, TextItemsOptions } from '../FormComponents';
import { TTemplateProps } from '../../types';

import styles from './Perks.scss';
import {
  ApplicationAvailabilityOptions,
 ApplicationCompensationOptions, ApplicationPageBuilderComponent, isBuilderComponentUsedByTemplate, ProjectApplicationPageTemplateName,
} from '../../../applicationPageUtils';

const { useCallback } = React;

type TPerksProps = TTemplateProps['perks'];

interface IProps {
  perksProps?: TPerksProps;
  onChange?: (values: TPerksProps) => void;
  template?: ProjectApplicationPageTemplateName;
  isFieldVisible: (fieldName: string) => boolean;
  onChangeFieldVisibility: (fieldName: string, isVisible: boolean) => void;
}

const bulletOptions = [
  { value: 'numbers', label: 'Numbers' },
  { value: 'bullets', label: 'Bullets' },
  { value: 'none', label: 'None' },
];

export const Perks: React.FC<IProps> = (props) => {
  const {
    perksProps,
    onChange,
    template,
    isFieldVisible,
    onChangeFieldVisibility,
  } = props;

  const handleChange = useCallback((values: Partial<TPerksProps>) => {
    if (onChange) {
      onChange({ ...perksProps, ...values });
    }
  }, [perksProps, onChange]);

  const handleChangeShow = useCallback((show: boolean) => {
    handleChange({ show });
  }, [handleChange]);

  const handleChangePerks = useCallback((items: string[]) => {
    handleChange({ items });
  }, [handleChange]);

  const handleChangeBulletStyle = useCallback((e: IRadioChangeEvent) => {
    const bulletStyle = e.target.value as TPerksProps['bullet_style'];
    handleChange({ bullet_style: bulletStyle });
  }, [handleChange]);

  const handleCompensationChange = useCallback((values: string[]) => {
    handleChange({ compensation: values });
  }, [handleChange]);

  const handleAvailabilityChange = useCallback((values: string[]) => {
    handleChange({ availability: values });
  }, [handleChange]);

  const handleChangeCompensationTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ compensationTitle: e.target.value });
  }, [handleChange]);

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ title: e.target.value });
  }, [handleChange]);

  const handleChangeAvailabilityTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ availabilityTitle: e.target.value });
  }, [handleChange]);

  return (
    <div className={styles.Perks}>
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.PerksSectionToggle,
          template,
        ) && (
          <ShowSection show={!(perksProps?.show === false)} onChange={handleChangeShow} />
        )
      }
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.PerksTitle,
          template,
        )
          && (
            <div className={styles.row}>
              <div className={styles.rowDescription}>
                Perks Title
              </div>
              <Input
                placeholder="Program Perks"
                value={perksProps?.title}
                onChange={handleChangeTitle}
              />
            </div>
          )
      }
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.PerksCompensation,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.rowDescription}>
              Indicate how creators that are accepted will be compensated.
            </div>
            <div className={styles.subtitle}>
              Compensation
              {
                isBuilderComponentUsedByTemplate(
                  ApplicationPageBuilderComponent.PerksCompensationToggle,
                  template,
                ) && (
                  <Switch
                    size="small"
                    checked={isFieldVisible(ApplicationPageBuilderComponent.PerksCompensation)}
                    onChange={(isVisible: boolean) => (
                      onChangeFieldVisibility(ApplicationPageBuilderComponent.PerksCompensation, isVisible)
                    )}
                  />
                )
              }
            </div>
            <Input
              placeholder="Program Perks"
              value={perksProps?.compensationTitle}
              onChange={handleChangeCompensationTitle}
            />
            <Checkbox.Group
              value={perksProps?.compensation}
              options={ApplicationCompensationOptions}
              onChange={handleCompensationChange}
            />
          </div>
        )
      }
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.PerksMediaAvailability,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.rowDescription}>
              Indicate which social media platforms, if applicable, this collaboration is available for.
            </div>
            <div className={styles.subtitle}>
              Availability
              {
                isBuilderComponentUsedByTemplate(
                  ApplicationPageBuilderComponent.PerksMediaAvailabilityToggle,
                  template,
                ) && (
                  <Switch
                    size="small"
                    checked={isFieldVisible(ApplicationPageBuilderComponent.PerksMediaAvailability)}
                    onChange={(isVisible: boolean) => (
                      onChangeFieldVisibility(ApplicationPageBuilderComponent.PerksMediaAvailability, isVisible)
                    )}
                  />
                )
              }
            </div>
            <Input
              placeholder="Available For"
              value={perksProps?.availabilityTitle}
              onChange={handleChangeAvailabilityTitle}
            />
            <Checkbox.Group
              value={perksProps?.availability}
              options={ApplicationAvailabilityOptions}
              onChange={handleAvailabilityChange}
            />
          </div>
        )
      }

      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.PerksBulletStyle,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>Bullet style</div>
            <Radio.Group
              buttonStyle="solid"
              optionType="button"
              options={bulletOptions}
              onChange={handleChangeBulletStyle}
              value={perksProps?.bullet_style || defaultPerks.bullet_style}
            />
          </div>
        )
      }
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.PerksBulletStyle,
          template,
        ) && (
          <div className={styles.row}>
            <TextItemsOptions
              items={perksProps?.items || Array(5).fill(undefined)}
              onChange={handleChangePerks}
              placeholder="What are awesome things your community can expect from joining?"
              min={1}
              max={6}
              type="Perk"
            />
          </div>
        )
      }
    </div>
  );
};
