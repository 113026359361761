import * as React from 'react';
import { map, findIndex } from 'lodash';
import { Select } from '@components';

import { PaymentReducerActionType } from '../pages/NewPayment';

import { ICard } from '../models';

import styles from './CreditCardSelector.scss';

const { useEffect } = React;
interface IProps {
  cards: ICard[];
  dispatch(action): void;
  selectedCardId: string;
}

export const CreditCardSelector: React.FunctionComponent<IProps> = (props) => {
  const { cards, dispatch, selectedCardId } = props;

  const selectOptions = map(cards, (card) => ({
    value: card.id,
    label: `${card.brand} - ${card.last4}`,
  }));

  const onCardSelected = (cardId) => {
    dispatch({
      type: PaymentReducerActionType.SetCard,
      value: cardId,
    });
  };

  const selectedIndex = findIndex(selectOptions, {
    value: selectedCardId,
  });

  // Default to first card
  useEffect(() => {
    if (selectOptions.length > 0) {
      dispatch({
        type: PaymentReducerActionType.SetCard,
        value: selectOptions[0].value,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.CreditCardSelector}>
      <section className={styles.CardToCharge}>
        <div className={styles.StandardLabel}>Card to be charged</div>
        <Select
          className={styles.SelectDropdown}
          options={selectOptions}
          onChange={onCardSelected}
          selectedIndex={selectedIndex}
        />
      </section>
    </div>
  );
};

export default CreditCardSelector;
