import { clamp } from 'lodash';

import { IPopoverProps, TPopoverPlacement } from './Popover';

const WINDOW_BOUNDS = 16;
const ARROW_HEIGHT = 10;
const ARROW_WIDTH = 20;

interface IMountPosition {
  left: number;
  top: number;
  arrowRelativeLeft?: number;
  arrowRelativeTop?: number;
  doFlip?: boolean;
  maxHeight?: number;
}

export const flipPlacement = (p: TPopoverPlacement): TPopoverPlacement => {
  switch (p) {
    case 'top': return 'bottom';
    case 'bottom': return 'top';
    case 'left': return 'right';
    case 'right': return 'left';
  }
};

const getWindowEdge = (isVertical: boolean) => (
  (isVertical ? window.innerWidth : window.innerHeight) - WINDOW_BOUNDS
);

const isOutOfBounds = (location: number, isVertical: boolean) => (
  location < WINDOW_BOUNDS || getWindowEdge(isVertical) < location
);

/**
 * Calculates the mounting position, for both content and arrow.
 *
 * @return {Object}
 */
export const calculateMountPosition = (
  params: Partial<IPopoverProps> & { ref: React.MutableRefObject<HTMLDivElement> },
): IMountPosition => {
  const {
    anchorOrigin,
    arrowPosition,
    mountRef,
    offset,
    placement,
    ref,
  } = params;

  const parentNode = mountRef && mountRef.current;
  if (!parentNode) {
    return {
      top: 0,
      left: 0,
    };
  }

  const parentBoundingRect = parentNode.getBoundingClientRect();
  const selfBoundingRect = ref.current.getBoundingClientRect();

  const isVertical = placement === 'left' || placement === 'right';

  let originStart: number;
  let placementStart: number;
  let arrowRelativeStart: number;
  let maxHeight: number;

  /**
   * Placement
   */
  const placementStartEdge = isVertical ? 'left' : 'top';
  const placementEndEdge = isVertical ? 'right' : 'bottom';
  const placementSizeKey = isVertical ? 'width' : 'height';

  const calculatePlacementStart = (p: TPopoverPlacement): number => {
    let result;
    if (p === placementEndEdge) {
      result = Math.round(
        parentBoundingRect[placementEndEdge] + ARROW_HEIGHT,
      );
    } else if (p === placementStartEdge) {
      result = Math.round(
        parentBoundingRect[placementStartEdge] - selfBoundingRect[placementSizeKey] - ARROW_HEIGHT,
      );
    }

    // Offset
    const placementOffset = isVertical
      ? (offset ? offset.x : 0)
      : (offset ? offset.y : 0);
    result += placementOffset;
    return result;
  };
  placementStart = calculatePlacementStart(placement);

  // Flip if necessary
  const placementEdge = (
    placementStart
    + (placement === placementEndEdge ? selfBoundingRect[placementSizeKey] : 0)
  );
  let doFlip = false;
  if (isOutOfBounds(placementEdge, isVertical)) {
    // Try flipping
    const flippedPlacementStart = calculatePlacementStart(flipPlacement(placement));
    const flippedPlacementEdge = (
      flippedPlacementStart
      + (flipPlacement(placement) === placementEndEdge ? selfBoundingRect[placementSizeKey] : 0)
    );

    // NOTE: If flipping doesn't help, control max height to force scrollbar
    if (isOutOfBounds(flippedPlacementEdge, isVertical)) {
      maxHeight = (
        selfBoundingRect[placementSizeKey]
        - (placementEdge - (placement === 'bottom' ? getWindowEdge(isVertical) : 0))
        - WINDOW_BOUNDS
      );
    } else {
      doFlip = true;
      placementStart = flippedPlacementStart;
    }
  }

  /**
   * Origin
   */
  const originStartEdge = isVertical ? 'top' : 'left';
  const originEndEdge = isVertical ? 'bottom' : 'right';
  const originSizeKey = isVertical ? 'height' : 'width';

  if (anchorOrigin === 'start') {
    originStart = Math.max(
      WINDOW_BOUNDS,
      Math.round(parentBoundingRect[originStartEdge]),
    );
  } else if (anchorOrigin === 'middle') {
    const diff = parentBoundingRect[originSizeKey] - selfBoundingRect[originSizeKey];
    originStart = Math.max(
      WINDOW_BOUNDS,
      Math.round(parentBoundingRect[originStartEdge] + diff / 2),
    );
  } else if (anchorOrigin === 'end') {
    originStart = Math.max(
      WINDOW_BOUNDS,
      Math.round(parentBoundingRect[originEndEdge] - selfBoundingRect[originSizeKey]),
    );
  }

  // Offset
  const originOffset = isVertical
    ? (offset ? offset.y : 0)
    : (offset ? offset.x : 0);
  originStart += originOffset;

  /**
   * Arrow position
   */
  const arrowMinStart = ARROW_WIDTH;
  const arrowMaxStart = Math.round(selfBoundingRect[originSizeKey] - ARROW_WIDTH);
  if (arrowPosition === 'start') {
    arrowRelativeStart = clamp(
      Math.round(parentBoundingRect[originStartEdge] - originStart + ARROW_WIDTH),
      arrowMinStart,
      arrowMaxStart,
    );
  } else if (arrowPosition === 'middle') {
    arrowRelativeStart = clamp(
      parentBoundingRect[originStartEdge] + parentBoundingRect[originSizeKey] / 2 - originStart,
      arrowMinStart,
      arrowMaxStart,
    );
  } else if (arrowPosition === 'end') {
    arrowRelativeStart = clamp(
      Math.round(parentBoundingRect[originEndEdge] - originStart - ARROW_WIDTH),
      arrowMinStart,
      arrowMaxStart,
    );
  }

  if (isVertical) {
    return {
      top: originStart,
      left: placementStart,
      arrowRelativeTop: arrowRelativeStart,
      doFlip,
    };
  } else {
    return {
      top: placementStart,
      left: originStart,
      arrowRelativeLeft: arrowRelativeStart,
      doFlip,
      maxHeight,
    };
  }
};
