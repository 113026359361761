/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wknn_157 {
  display: flex;
}

._justify-content-space-between_1wknn_161 {
  justify-content: space-between;
}

._tabular-nums_1wknn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wknn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1wknn_157 {
  display: flex;
}

._justify-content-space-between_1wknn_161 {
  justify-content: space-between;
}

._tabular-nums_1wknn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1wknn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._button_1wknn_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._button_1wknn_355 {
  min-width: 2.25rem;
}
._button_1wknn_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._button_1wknn_355:focus, ._button_1wknn_355:hover {
  border-color: #167cf4;
  color: #167cf4;
}

._TableHeader_1wknn_377 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -0.5rem;
}
._TableHeader_1wknn_377 ._count_1wknn_384 {
  margin: 0.5rem 1.5rem 1rem 0;
}
._TableHeader_1wknn_377 ._buttonRow_1wknn_387 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: auto;
}
._TableHeader_1wknn_377 ._right_1wknn_394 {
  flex-wrap: wrap;
  align-self: flex-end;
  margin-left: 0.5rem;
}

._button_1wknn_355,
._buttonTooltip_1wknn_401,
._primaryCTA_1wknn_402 {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
._button_1wknn_355 ._icon_1wknn_406:not(:only-child),
._buttonTooltip_1wknn_401 ._icon_1wknn_406:not(:only-child),
._primaryCTA_1wknn_402 ._icon_1wknn_406:not(:only-child) {
  margin-right: 0.25rem;
}
._button_1wknn_355._isPrimaryTheme_1wknn_411:hover, ._button_1wknn_355._isPrimaryTheme_1wknn_411:focus,
._buttonTooltip_1wknn_401._isPrimaryTheme_1wknn_411:hover,
._buttonTooltip_1wknn_401._isPrimaryTheme_1wknn_411:focus,
._primaryCTA_1wknn_402._isPrimaryTheme_1wknn_411:hover,
._primaryCTA_1wknn_402._isPrimaryTheme_1wknn_411:focus {
  color: #fff !important;
}

._button_1wknn_355._disabled_1wknn_419 {
  background-color: #fdfdfd !important;
  cursor: no-drop;
  pointer-events: none;
}

._applicantOperationWrapper_1wknn_425 {
  display: flex;
}

._actionButton_1wknn_429 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
._actionButton_1wknn_429._disabled_1wknn_419 {
  font-weight: 400;
}

._approveIcon_1wknn_439 {
  margin-top: 0.375rem;
  margin-right: 0 !important;
  fill: #52c41a;
}

._rejectIcon_1wknn_445 {
  margin-top: 0.375rem;
  margin-right: 0 !important;
  fill: #f5222d;
}

._inline_1wknn_451 {
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._inline_1wknn_451:hover {
  transform: scale(1.1);
}
._inline_1wknn_451._disabled_1wknn_419 {
  opacity: 0.4;
}
._inline_1wknn_451 ._approveIcon_1wknn_439,
._inline_1wknn_451 ._rejectIcon_1wknn_445 {
  margin-top: 0.625rem;
}