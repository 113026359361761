import * as React from 'react';

import {
  Space,
  Input,
  Typography,
} from '@revfluence/fresh';
import PostInstructions from '@frontend/applications/TermsApp/components/shared/PostInstructions/PostInstructions';
import PostAttachments from '@frontend/applications/TermsApp/components/shared/PostAttachments/PostAttachments';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import {
  ISortableGuideline,
  IContentGuidelineInstruction,
  IContentGuidelineTitle,
} from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
} from '@frontend/app/queries/types/TermsConfigsQuery';

interface IProps {
  contentGuideline: TContentGuideline;
  onUpdateContentGuidelineInstruction: (
    contentGuidelineInstruction: IContentGuidelineInstruction,
  ) => void
  onSortContentGuideline: (
    sortableGuideline: ISortableGuideline,
  ) => void;
  onAddContentGuidelineAttachment: (
    attachment: Attachment,
  ) => void;
  onDeleteContentGuidelineAttachment: (
    attachments: Array<Attachment>,
  ) => void;
  onUpdateContentGuidelineLabel: (label: string) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
}

const ContentGuidelines: React.FC<IProps> = React.memo((props) => {
  const {
    contentGuideline,
    onUpdateContentGuidelineLabel,
    onUpdateContentGuidelineInstruction,
    onUpdateContentGuidelineTitle,
    onSortContentGuideline,
    onAddContentGuidelineAttachment,
    onDeleteContentGuidelineAttachment,
  } = props;

  return (
    <Space
      direction="vertical"
      style={{ width: '100%' }}
      size={[24, 24]}
    >
      <Typography.Text
        style={{ fontWeight: 400 }}
      >
        Title
      </Typography.Text>
      <Input
        style={{ width: '50%' }}
        value={contentGuideline?.label}
        placeholder="Enter title..."
        autoFocus
        bordered
        onChange={(event) => onUpdateContentGuidelineLabel(event.target.value)}
      />
      <PostInstructions
        isReadOnly={false}
        id={contentGuideline?.id}
        guidelines={contentGuideline?.guidelines}
        onAddContentGuidelineInstruction={onUpdateContentGuidelineInstruction}
        onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
        onSortGuideline={(_id, sortableGuideline) => onSortContentGuideline(sortableGuideline)}
      />
      <PostAttachments
        attachments={contentGuideline?.attachments}
        onAddAttachment={onAddContentGuidelineAttachment}
        onRemoveAttachment={onDeleteContentGuidelineAttachment}
      />
    </Space>
  );
});

ContentGuidelines.displayName = 'ContentGuidelines';

export default ContentGuidelines;
