import { map } from 'lodash';
import { IRowData, ISocialAccount } from '@components';

export interface IState {
  showNextAction: boolean,
  showPreviousAction: boolean,
  currentSocialAccount?: ISocialAccount,
  allSocialAccounts?: ISocialAccount[],
  tableRows: IRowData[],
  currentRowData: {
    rowIndex: number,
    rowId: string,
    rowApplicantName: string,
  },
  showPlaceholder: boolean,
}

export enum ActionType {
  ToggleArrowVisibility = 'TOGGLE_ARROW_VISIBILITY',
  CurrentSocialAccount = 'CURRENT_SOCIAL_ACCOUNT',
  UpdateSocialAccounts = 'UPDATE_SOCIAL_ACCOUNTS',
  CurrentRowData = 'CURRENT_ROW_DATA',
  ShowPlaceholder = 'SHOW_PLACEHOLDER',
  RemoveRowById = 'REMOVE_ROW_BY_ID',
}

export interface Action {
  type: ActionType;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  payload: any;
}

export const reducer = (state: IState, action: Action): IState => {
  switch (action.type) {
    case ActionType.ToggleArrowVisibility:
      const { showNextAction, showPreviousAction } = action.payload;
      return { ...state, showNextAction, showPreviousAction };
    case ActionType.ShowPlaceholder:
      return { ...state, showPlaceholder: action.payload };
    case ActionType.CurrentRowData:
      return { ...state, currentRowData: action.payload };
    case ActionType.CurrentSocialAccount:
      return { ...state, currentSocialAccount: action.payload };
    case ActionType.UpdateSocialAccounts:
      return { ...state, allSocialAccounts: action.payload };
    case ActionType.RemoveRowById:
      return {
        ...state,
        tableRows: map(state.tableRows, (row) => row?.id !== action.payload && row || null),
      };
    default:
      return state;
  }
};
