import { IPaymentSentEventProperties } from '@common';

import { ISendPaymentRequest } from './savePayment';

export interface IMixpanelFields {
  [key: string]: unknown;
}

export const createPaymentSentEvent = (
  params: ISendPaymentRequest,
  source: IPaymentSentEventProperties['source'],
  mixpanelFields?: IMixpanelFields,
): IPaymentSentEventProperties => {
  let paymentType: IPaymentSentEventProperties['payment_type'] = 'paypal';
  if (params.card_id) {
    paymentType = 'stripe';
  }
  const event: IPaymentSentEventProperties = {
    client_id: params.client_id,
    currency: 'USD',
    member_email: params.member_email || '',
    member_id: String(params.member_id),
    member_name: params.member_name || '',
    payment_amount: params.amount,
    payment_date: new Date().toUTCString(),
    payment_type: paymentType,
    source,
    ...mixpanelFields,
  };
  return event;
};
