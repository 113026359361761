import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_MEMBERS_COUNT_FOR_RESTRICTION_QUERY } from '@frontend/app/queries';
import {
  GetMembersCountForRestrictionQuery,
  GetMembersCountForRestrictionQueryVariables,
} from '@frontend/app/queries/types/GetMembersCountForRestrictionQuery';

type IOptions = QueryHookOptions<GetMembersCountForRestrictionQuery, GetMembersCountForRestrictionQueryVariables>;

export function useGetMembersCountForRestriction(options: IOptions = {}) {
  const {
 loading, data: { count } = {}, error, refetch,
} = useQuery<GetMembersCountForRestrictionQuery, GetMembersCountForRestrictionQueryVariables>(
    GET_MEMBERS_COUNT_FOR_RESTRICTION_QUERY,
    options,
  );

  return {
    loading,
    count,
    error,
    refetch,
  };
}
