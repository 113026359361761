import * as React from 'react';
import cn from 'classnames';
import { noop } from 'lodash';

import { Badge } from 'antd';
import {
 Button, Col, Row, Input,
} from '@revfluence/fresh';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';

import { TContentTable } from '../../types';

import styles from './Toolbar.module.scss';

export const Toolbar = ({ counters, onSearchTextChange, searchText }: TContentTable) => (
  <Row
    gutter={64}
    style={{
      marginLeft: 0,
      marginRight: 0,
    }}
  >
    <div className={styles.toolBarContainer}>
      <Row gutter={[8, 16]}>
        {counters?.map(({
 id, name, count = 0, selected, onClick = noop,
}) => (
  <Col key={`${id}`}>
    <Button
      className={cn({
                [styles.selected]: selected,
                [styles.buttonWithGap]: count > 0,
                [styles.buttonWithoutGap]: !count,
              })}
      onClick={onClick}
    >
      {name}
      <Badge count={count} overflowCount={99} style={{ backgroundColor: '#167CF4' }} />
    </Button>
  </Col>
        ))}
      </Row>

      <div className={styles.searchContainer}>
        <Input
          prefix={<MagnifyingGlassIcon />}
          onChange={onSearchTextChange}
          placeholder="Search by name or handle"
          value={searchText}
          className={styles.searchBar}
        />
      </div>
    </div>
  </Row>
);
