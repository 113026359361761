import gql from 'graphql-tag';

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    title
    variants {
      id
      product_id
      position
      title
      price
      sku
      inventory_quantity
      inventoryPolicy
      sellableOnlineQuantity
    }
    image {
      src
    }
  }
`;
