import { TNetworkIdentifier } from '../models/networkIdentifier';

interface INetworkConfig {
  networkName: string;
  contentName: string;
  contentNamePlural: string;
  audienceName: string;
  audienceNamePlural: string;
  engagementName: string;
  engagementNamePlural: string;
  longEngagementNamePlural: string;
  longContentName: string;
}

export const networkConfig: { [network in TNetworkIdentifier]: INetworkConfig } = {
  youtube: {
    networkName: 'YouTube',
    contentName: 'video',
    contentNamePlural: 'videos',
    audienceName: 'subscriber',
    audienceNamePlural: 'subscribers',
    engagementName: 'view',
    engagementNamePlural: 'views',
    longEngagementNamePlural: 'video views',
    longContentName: 'a YouTube video',
  },
  instagram: {
    networkName: 'Instagram',
    contentName: 'post',
    contentNamePlural: 'posts',
    audienceName: 'follower',
    audienceNamePlural: 'followers',
    engagementName: 'like',
    engagementNamePlural: 'likes',
    longEngagementNamePlural: 'likes and comments',
    longContentName: 'an Instagram post',
  },
  twitter: {
    networkName: 'Twitter',
    contentName: 'tweet',
    contentNamePlural: 'tweets',
    audienceName: 'follower',
    audienceNamePlural: 'followers',
    engagementName: 'retweet',
    engagementNamePlural: 'retweets',
    longEngagementNamePlural: 'favorites and retweets',
    longContentName: 'a Tweet',
  },
  facebook: {
    networkName: 'Facebook',
    contentName: 'post',
    contentNamePlural: 'posts',
    audienceName: 'page like',
    audienceNamePlural: 'page likes',
    engagementName: 'reaction',
    engagementNamePlural: 'reactions',
    longEngagementNamePlural: 'reactions and comments',
    longContentName: 'a Facebook post',
  },
  blog: {
    networkName: 'Blog',
    contentName: 'post',
    contentNamePlural: 'posts',
    audienceName: 'visitor',
    audienceNamePlural: 'monthly visitors',
    engagementName: 'comment',
    engagementNamePlural: 'comments',
    longEngagementNamePlural: 'comments',
    longContentName: 'a Blog post',
  },
  twitch: {
    networkName: 'Twitch',
    contentName: 'broadcast',
    contentNamePlural: 'broadcasts',
    audienceName: 'follower',
    audienceNamePlural: 'followers',
    engagementName: 'view',
    engagementNamePlural: 'views',
    longEngagementNamePlural: 'broadcast views',
    longContentName: 'a Twitch broadcast',
  },
  snapchat: {
    networkName: 'Snapchat',
    contentName: 'story',
    contentNamePlural: 'stories',
    audienceName: 'follower',
    audienceNamePlural: 'followers',
    engagementName: 'view',
    engagementNamePlural: 'views',
    longEngagementNamePlural: 'story views',
    longContentName: 'a Snapchat story',
  },
  pinterest: {
    networkName: 'Pinterest',
    contentName: 'pin',
    contentNamePlural: 'pins',
    audienceName: 'view',
    audienceNamePlural: 'monthly viewers',
    engagementName: 'engagement',
    engagementNamePlural: 'engagement',
    longEngagementNamePlural: 'pin views',
    longContentName: 'a Pinterest pin',
  },
  tiktok: {
    networkName: 'TikTok',
    contentName: 'video',
    contentNamePlural: 'videos',
    audienceName: 'follower',
    audienceNamePlural: 'followers',
    engagementName: 'view',
    engagementNamePlural: 'views',
    longEngagementNamePlural: 'video views',
    longContentName: 'a TikTok video',
  }
};
