/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_tykkb_157 {
  display: flex;
}

._justify-content-space-between_tykkb_161 {
  justify-content: space-between;
}

._tabular-nums_tykkb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_tykkb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_tykkb_157 {
  display: flex;
}

._justify-content-space-between_tykkb_161 {
  justify-content: space-between;
}

._tabular-nums_tykkb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_tykkb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._table_tykkb_355 {
  width: 100%;
  margin-top: 0.5rem;
  border: 0.0625rem solid #ececec;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 0.5rem;
}
._table_tykkb_355 thead tr th {
  text-transform: uppercase;
}
._table_tykkb_355 thead tr th:first-of-type {
  border-top-left-radius: 0.5rem;
}
._table_tykkb_355 thead tr th:last-of-type {
  border-top-right-radius: 0.5rem;
}
._table_tykkb_355 tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 0.5rem;
}
._table_tykkb_355 tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 0.5rem;
}
._table_tykkb_355 thead tr th,
._table_tykkb_355 tbody tr:not(:last-of-type) td {
  border-bottom: 0.0625rem solid #ececec;
}
._table_tykkb_355 tr th,
._table_tykkb_355 tr td {
  min-height: 2.5rem;
  padding: 1rem;
  text-align: left;
}
._table_tykkb_355 tr th {
  color: #8f8d91;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

._footer_tykkb_396 {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4.5rem;
  margin: 0 0 0 -2.25rem;
  padding: 1rem 2.25rem;
  border-top: 0.0625rem solid #dadcde;
  background-color: #fdfdfd;
}
._footer_tykkb_396 > div:last-of-type {
  margin-left: auto;
}

._UploadFile_tykkb_412 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
}
._UploadFile_tykkb_412 ._art_tykkb_420 {
  margin: 1.5rem auto 2.25rem;
}
._UploadFile_tykkb_412 h4,
._UploadFile_tykkb_412 p {
  display: inline-block;
  margin: 0 auto 0.5rem;
}
._UploadFile_tykkb_412 h4 {
  font-weight: 600;
  font-size: 1rem;
}
._UploadFile_tykkb_412 ._requirements_tykkb_432 {
  margin: 0.75rem auto;
  padding: 0;
  list-style-type: none;
}
._UploadFile_tykkb_412 ._requirements_tykkb_432 li {
  display: flex;
  width: 26.25rem;
  margin: 0 auto 0.75rem;
  padding: 0.75rem 1.5rem;
  border: 0.0625rem solid #e9e8ea;
  border-radius: 0.25rem;
  color: #4f4949;
}
._UploadFile_tykkb_412 ._requirements_tykkb_432 li:last-of-type {
  margin-bottom: 0;
}
._UploadFile_tykkb_412 ._requirements_tykkb_432 li._hasError_tykkb_449 {
  border-color: #d9534f;
  color: #d9534f;
}
._UploadFile_tykkb_412 ._requirements_tykkb_432 li ._xcircleIcon_tykkb_453,
._UploadFile_tykkb_412 ._requirements_tykkb_432 li ._checkIcon_tykkb_454 {
  vertical-align: middle;
  margin: 0 -0.75rem 0 auto;
}
._UploadFile_tykkb_412 ._requirements_tykkb_432 li ._checkIcon_tykkb_454 {
  fill: #3996e0;
}
._UploadFile_tykkb_412 ._upload_tykkb_461 {
  margin: 0.5rem auto 2rem;
}
._UploadFile_tykkb_412 ._upload_tykkb_461 ._button_tykkb_464 {
  width: 11.25rem;
  font-size: 0.875rem;
}
._UploadFile_tykkb_412 ._loading_tykkb_468 {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 4.75rem;
}
._UploadFile_tykkb_412 ._loading_tykkb_468 ._spinnerIcon_tykkb_473 {
  color: #3996e0;
}
._UploadFile_tykkb_412 ._downloadTemplateLink_tykkb_476 {
  color: #aeaeae;
}