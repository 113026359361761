import React from 'react';
import { Button } from '@revfluence/fresh';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/solid';

interface CustomPaginationProps {
  total: number;
  page: number;
  setPage: (page: number) => void;
  pageSize: number;
}

export const CustomPagination: React.FC<CustomPaginationProps> = ({
 total, page, setPage, pageSize,
}) => {
  const totalPages = Math.ceil(total / pageSize);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <span>{`${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, total)} of ${total} Payments`}</span>
      <div style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>
        <Button type="text" icon={<ChevronLeftIcon />} onClick={handlePrevPage} disabled={page <= 1} />
        <Button type="text" icon={<ChevronRightIcon />} onClick={handleNextPage} disabled={page >= totalPages} />
      </div>
    </div>
  );
};
