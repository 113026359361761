/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_t4szd_157 {
  display: flex;
}

._justify-content-space-between_t4szd_161 {
  justify-content: space-between;
}

._tabular-nums_t4szd_165 {
  font-variant-numeric: tabular-nums;
}

._EmailInput_t4szd_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_t4szd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._EmailInput_t4szd_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._EmailInput_t4szd_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._EmailInput_t4szd_169 ::-webkit-scrollbar-track, ._EmailInput_t4szd_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._EmailInput_t4szd_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204 ._name_t4szd_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204 ._email_t4szd_214 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._EmailInput_t4szd_169 {
  padding: 0.375rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 1.8125rem;
  max-height: 6.1875rem;
  overflow-y: auto;
  border-radius: 10px;
  background-color: #f5f5f5;
}
._EmailInput_t4szd_169:hover, ._EmailInput_t4szd_169:focus {
  background-color: #f5f5f5;
}
._EmailInput_t4szd_169 ._placeholder_t4szd_239 {
  position: absolute;
  top: 15px;
  left: 8px;
  user-select: none;
  color: #777;
  font-weight: 400;
}
._EmailInput_t4szd_169 ._item_t4szd_247 {
  margin: 0.1875rem 0.3125rem 0.1875rem 0;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  background-color: #f5f5f5;
  border-radius: 6px;
  color: #1f1f21;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  height: 1.8125rem;
  line-height: 1.8125rem;
}
._EmailInput_t4szd_169 ._item_t4szd_247 ._alertIcon_t4szd_263 {
  margin-right: 3px;
  color: #ea92af;
}
._EmailInput_t4szd_169 ._item_t4szd_247 ._deleteIcon_t4szd_267 {
  margin-left: 4px;
  cursor: pointer;
  will-change: color;
  transition: color 0.1s ease-out;
}
._EmailInput_t4szd_169 ._item_t4szd_247 ._deleteIcon_t4szd_267:hover {
  color: gray;
}
._EmailInput_t4szd_169 ._item_t4szd_247._error_t4szd_276 {
  color: white;
  background-color: #f1515f;
}
._EmailInput_t4szd_169 ._item_t4szd_247._error_t4szd_276 ._deleteIcon_t4szd_267 {
  color: #eee;
}
._EmailInput_t4szd_169 ._item_t4szd_247._error_t4szd_276 ._deleteIcon_t4szd_267:hover {
  color: gray;
}
._EmailInput_t4szd_169 ._input_t4szd_286 {
  margin: 2px 5px 2px 0;
  padding: 0 0 0 4px;
  flex: 1;
  min-width: 100px;
  outline: none;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: 400;
  height: 1.8125rem;
  line-height: 1.8125rem;
}

._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204 {
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204 ._email_t4szd_214 {
  color: #8f8d91;
}
._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204._active_t4szd_312 {
  background-color: #eff5f9;
}
._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204._active_t4szd_312 ._name_t4szd_204 {
  color: #3996e0;
}
._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204:hover {
  background-color: #e6edf2;
}
._Popover_t4szd_204 ._list_t4szd_204 ._option_t4szd_204:hover ._name_t4szd_204 {
  color: #3996e0;
  text-shadow: 0.5px 0 0 #3996e0;
}