import { isFunction } from 'lodash';
import * as React from 'react';

import { LoadSpinner } from '@frontend/app/components';
import { TProject } from '@frontend/app/containers/Projects/types';
import { useGetAllWorkletsForParentSpecQuery } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';

import styles from './EditPage.scss';

import { ProjectDetailsForm } from './ProjectDetailsForm';

const {
  useEffect,
  useState,
} = React;

interface IProps {
  onSave: () => void;
  project: TProject;
  refetchProjects?: () => void;
}

/**
 * Edit Page
 */
export const EditPage: React.FC<IProps> = React.memo(({
  onSave,
  project,
  refetchProjects,
}) => {
  const { showSuccessMessage } = useMessagingContext();

  const {
    loading: isWorkletsLoading,
    data: {
      worklets: specWorklets = undefined,
    } = {},
    refetch: refetchWorklets,
  } = useGetAllWorkletsForParentSpecQuery({
    variables: {
      specKey: project?.specKey,
    },
    skip: !project?.specKey,
  });

  useEffect(
    () => {
      if (project?.specKey) {
        refetchWorklets({
          specKey: project.specKey,
        });
      }
    },
    [project?.specKey, refetchWorklets],
  );

  /**
   * Submit form
   */
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = async (projectArg: TProject) => {
    await refetchWorklets({
      specKey: projectArg.specKey,
    });
    setSubmitting(false);
    showSuccessMessage(`Successfully edited "${projectArg.title}"`, 6000);
    if (isFunction(onSave)) {
      onSave();
    }
  };

  return isWorkletsLoading
    ? <LoadSpinner className={styles.loadSpinner} />
    : (
      <ProjectDetailsForm
        className={styles.EditPage}
        defaultWorklets={specWorklets}
        disabled={isSubmitting}
        mode="edit"
        onSubmit={handleSubmit}
        onSubmitting={setSubmitting}
        parentSpecKey={project?.specKey}
        projectId={project?.id}
        projectInput={project}
        refetchProjects={refetchProjects}
      />
    );
});
