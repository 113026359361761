import { gql } from '@apollo/client';

export const GET_PROJECT_BUDGETS_WITH_FISCAL_YEAR_QUERY = gql`
  query GetProjectBudgetsWithFiscalYearQuery($fiscalYear: String) {
    getBudgetDashboardDetailedViewTabProjectData(fiscalYear: $fiscalYear) {
      allocatedAmount
      availableAmount
      programId
      programName
      programSplashImageUrl
      quarterDistributions
      sources
      spentAmount
      totalAmount
    }
  }
`;
