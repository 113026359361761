import gql from 'graphql-tag';

export const GET_CAMPAIGN_BY_PROJECT_ID_QUERY = gql`
  query GetCampaignByProjectIdQuery($projectId: Int!) {
    campaign: getCampaignForProgram(projectId: $projectId) {
      id
      accepted_place_ids {
        label
        value
      }
      brand_id
      brand_logo_url
      brand_name
      brand_website
      campaign_id
      disabled_ts
      enabled_ts
      external_listing_url
      name
      network_details {
        fixed_compensation_is_negotiable
        fixed_compensation_per_engagement
        maximum_budget
        maximum_engagements
        maximum_followers
        minimum_engagements
        minimum_followers
        minimum_views
        maximum_views
        minimum_authenticity_score
        minimum_engagement_ratio
        network_type
      }
      offers_payment
      offers_product
      offers_commission
      offers_other
      auto_unlisted
      network_types
      product_types
      program_id
      splash_image_url
      summary
      categories
    }
  }
`;
