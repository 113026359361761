/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1tax4_157 {
  display: flex;
}

._justify-content-space-between_1tax4_161 {
  justify-content: space-between;
}

._tabular-nums_1tax4_165 {
  font-variant-numeric: tabular-nums;
}

._IntroModalStep_1tax4_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1tax4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._IntroModalStep_1tax4_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._IntroModalStep_1tax4_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._IntroModalStep_1tax4_169 ::-webkit-scrollbar-track, ._IntroModalStep_1tax4_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._IntroModalStep_1tax4_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._IntroModalStep_1tax4_169 ._title_1tax4_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._IntroModalStep_1tax4_169 ._subtitle_1tax4_214 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._IntroModalStep_1tax4_169 {
  text-align: center;
  position: relative;
  overflow-x: hidden;
}
._IntroModalStep_1tax4_169 ._textContent_1tax4_260 {
  padding: 32px 16px 92px 16px;
  margin: 0 auto;
  max-width: 440px;
}
._IntroModalStep_1tax4_169 ._title_1tax4_204 {
  padding-bottom: 16px;
}
._IntroModalStep_1tax4_169 ._buttonWrapper_1tax4_268 {
  position: absolute;
  width: 100%;
  bottom: 32px;
  text-align: center;
}
._IntroModalStep_1tax4_169 ._buttonWrapper_1tax4_268 > :first-child:not(:last-child) {
  margin-right: 10px;
}
._IntroModalStep_1tax4_169 ._bannerWrapper_1tax4_277 {
  display: block;
  width: 100%;
}
._IntroModalStep_1tax4_169 ._bannerWrapper_1tax4_277 ._banner_1tax4_277 {
  padding-bottom: 24px;
}
._IntroModalStep_1tax4_169 ._bannerWrapper_1tax4_277 ._bannerImg_1tax4_284 {
  display: block;
  padding-bottom: 16px;
  width: 100%;
}
._IntroModalStep_1tax4_169 .step-enter.forward {
  transform: translateX(100%);
}
._IntroModalStep_1tax4_169 .step-enter.backward {
  transform: translateX(-100%);
}
._IntroModalStep_1tax4_169 .step-enter-active {
  transform: translateX(0) !important;
  transition: transform 0.5s ease-in-out;
}
._IntroModalStep_1tax4_169 .step-exit {
  transform: translateX(0);
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
}
._IntroModalStep_1tax4_169 .step-exit-active {
  transition: transform 0.5s ease-in-out;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
}
._IntroModalStep_1tax4_169 .step-exit-active.forward {
  transform: translateX(-100%);
}
._IntroModalStep_1tax4_169 .step-exit-active.backward {
  transform: translateX(100%);
}