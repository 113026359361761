import * as React from 'react';
import cx from 'classnames';

import { ExternalLinkIcon } from '@components';
import { IFrame } from '@frontend/app/containers/Projects/LandingPages/RightPanel/IFrame';
import { ProjectApplicationPage, TextInput } from '@frontend/app/components';
import { useFields } from '@frontend/app/containers/Projects/LandingPages/LeftPanel/hooks';
import { CREATOR_OFFERS } from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/context/model';

import { IPreviewProps, OnboardingStep } from '../../types';
import styles from '../styles.scss';

import previewStyles from './styles.scss';

const Preview = (props: IPreviewProps) => {
  const { state, applicationFormFields, template } = props;

  const { title, summary, description } = state[OnboardingStep.Setup].data;

  const perkItems = CREATOR_OFFERS.filter((offer) => state[OnboardingStep.Requirements].data[offer.key] === true)
    .map((offer) => offer.label);

  const {
    logo_thumbnail,
    hero_image_thumbnail,
    brand_image_1_thumbnail,
    brand_image_2_thumbnail,
    brand_image_3_thumbnail,
  } = state[OnboardingStep.MarketplaceListing].data;

  const fields = useFields(applicationFormFields);

  const templateConfig = {
    settings: {
      logo: logo_thumbnail,
      page_online: false,
      page_color: {
        background_color: '#ffffff',
      },
      form_color: {
        background_color: '#fdfdfd',
      },
      cta_styling: {
        font_color: '#000000',
        button_color: '#ffffff',
      },
      headline_styling: {
        fill_color: '#000000',
      },
      body_styling: {
        fill_color: '#000000',
      },
    },
    intro: {
      title,
      description: summary,
      hero_image: hero_image_thumbnail,
    },
    about: {
      description,
      brand_images: [
        brand_image_1_thumbnail,
        brand_image_2_thumbnail,
        brand_image_3_thumbnail,
      ],
    },
    perks: {
      items: perkItems,
    },
    persona: {
      show: false,
    },
  };

  return (
    <div className={styles.StepContent}>
      <div className={cx(styles.stepForm, previewStyles.stepForm)}>
        <div className={previewStyles.urlPanel}>
          <TextInput className={previewStyles.url} />
        </div>
        <div className={previewStyles.previewFrame}>
          <IFrame>
            <ProjectApplicationPage
              template={template}
              isPreview
              disableSubmit
              config={templateConfig}
              applicationFormFields={fields}
            />
          </IFrame>
        </div>
      </div>
      <div className={cx(styles.stepInfo, previewStyles.stepInfo)}>
        <h3 className={styles.title}>Your Application Page</h3>
        <p className={styles.section}>
          Based on the information you have provided, we have generated an application page
          that is ready to be listed on our Creator Marketplace.
          {' '}
          <a href="https://intercom.help/aspireiq_elevate/en/articles/6023393-an-overview-of-the-creator-marketplace" target="_blank">
            Learn more
            {' '}
            <ExternalLinkIcon size={14} className={styles.externalLinkIcon} />
          </a>
        </p>
        <p className={styles.section}>
          Additionally, you can also invite creators from Creator Search to apply
          to your project with this page.
        </p>
        <p className={styles.section}>
          <h4>Not ready to promote your project yet?</h4>
          {' '}
          Skip and save the settings for now if you wish to continue customizing your page.
        </p>
      </div>
    </div>
  );
};

const PreviewComponent = React.memo(Preview);

PreviewComponent.displayName = 'Preview';

export default PreviewComponent;
