/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_z8l5b_157 {
  display: flex;
}

._justify-content-space-between_z8l5b_161 {
  justify-content: space-between;
}

._tabular-nums_z8l5b_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_z8l5b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._StatisticsCard_z8l5b_178 {
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  display: flex;
}
._StatisticsCard_z8l5b_178 ._item_z8l5b_183 {
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  border-right: 0.0625rem solid #dadcde;
}
._StatisticsCard_z8l5b_178 ._item_z8l5b_183:last-child {
  border-right: 0;
}
._StatisticsCard_z8l5b_178 ._item_z8l5b_183 ._content_z8l5b_193 {
  width: 100%;
}
._StatisticsCard_z8l5b_178 ._item_z8l5b_183 ._content_z8l5b_193 ._statistic_z8l5b_196 {
  color: #1a1818;
  font-size: 1rem;
}
._StatisticsCard_z8l5b_178 ._item_z8l5b_183 ._content_z8l5b_193 ._title_z8l5b_200 {
  color: #8f8d91;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

._StatsColumn_z8l5b_206 {
  text-align: center;
}

._statsRow_z8l5b_210 {
  row-gap: 1.5rem !important;
}
._statsRow_z8l5b_210 ._currencyData_z8l5b_213 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
._statsRow_z8l5b_210 ._currencyData_z8l5b_213 h4 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  margin-bottom: 0rem;
}
._statsRow_z8l5b_210 ._currencyData_z8l5b_213 span {
  color: var(--action-blue-blue-6, #167CF4);
  font-size: 12px;
  font-weight: 400;
}
._statsRow_z8l5b_210 .ant-col {
  line-height: 0;
}
._statsRow_z8l5b_210 .ant-statistic {
  gap: 0.75rem;
}
._statsRow_z8l5b_210 .ant-statistic {
  display: block;
}
._statsRow_z8l5b_210 ._statisticContainer_z8l5b_236 {
  display: block;
}

._StatsCardPromosCard_z8l5b_240 {
  margin-bottom: 1rem;
}

._BulkPayStatsCard_z8l5b_244 {
  text-align: center;
}

._StatsColumn_z8l5b_206 .ant-statistic-title {
  font-weight: 600;
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  font-size: 12px;
}

._statsItemContainer_z8l5b_254 {
  margin-bottom: 1.5rem;
}
._statsItemContainer_z8l5b_254 ._payoutItemContainer_z8l5b_257 {
  border-top: 0.0625rem solid #D9D9D9;
  display: flex;
  flex-direction: column;
}
._statsItemContainer_z8l5b_254 ._payoutItemContainer_z8l5b_257 ._payoutItemTitle_z8l5b_262 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding-top: 0.5rem;
}
._statsItemContainer_z8l5b_254 ._payoutItemContainer_z8l5b_257 ._payoutItemDescription_z8l5b_269 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
}