export interface IAddress {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  first_name?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  last_name?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address1?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address2?: any;
  city: string;
  state: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postal_code?: any;
  country: string;
}
