import * as React from 'react';
import { useClientFeatureEnabled, useGetCurrentClient, useGetHomeReporting } from '@frontend/app/hooks';
import { HistoricalDataGroupBy } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import { DATE_RANGES, getDateRange } from '@frontend/app/containers/HomePageMetrics/components/DateSelect';

import { RefreshHomeLayout } from '../../pages/Home/RefreshHome';
import { formatDateToQuery, getPercentDiffVariables } from '../Reporting/Reporting';
import HomeLoading from '../../pages/Home/components/HomeLoading';
import { HomeError } from '../../pages/Home/components/HomeError/HomeError';
import { getLastFullMonths } from '../utils';
import { useInstagramStatus } from '../../hooks/useInstagramStatus';
import RefreshMetricsSecondary from '../../pages/Home/components/MetricsSecondary/RefreshMetricsSecondary';
import RefreshMetricsPrimary from '../../pages/Home/components/MetricsPrimary/RefreshMetricsPrimary';
import RefreshHeader from '../../pages/Home/components/Header/RefreshHeader';
import RefreshImpact from '../../pages/Home/components/Impact/RefreshImpact';

interface IImpactHomeProps {
  clientId: string;
  refreshUi: boolean;
}

export const RefreshImpactHome = ({
  clientId, refreshUi,
}: IImpactHomeProps) => {
  const [startDate, endDate] = getDateRange(DATE_RANGES.LAST_6_MONTHS);
  const [chartStartDate, chartEndDate] = getLastFullMonths(4);
  const [compareStartDate, compareEndDate] = getLastFullMonths(2);
  const AlertBanner = useInstagramStatus(clientId, refreshUi);
  const { client: currentClient } = useGetCurrentClient();
  const queryVariables = {
    startDate: formatDateToQuery(startDate, true),
    endDate: formatDateToQuery(endDate, false),
    groupBy: HistoricalDataGroupBy.MONTH,
    chartStartDate: formatDateToQuery(chartStartDate, true),
    chartEndDate: formatDateToQuery(chartEndDate, false),
    ...getPercentDiffVariables(
      [startDate, endDate],
      [compareStartDate, compareEndDate],
      false,
    ),
  };
  const { loading: loadingReportingData, data, error } = useGetHomeReporting({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });

  const igStoryEstimationEnabled = useClientFeatureEnabled(ClientFeature.IG_ESTIMATED_IMPRESSIONS);
  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);

  const loading = loadingReportingData || igStoryEstimationEnabled === undefined;

  if (error) {
    return <HomeError startDate={startDate} endDate={endDate} clientName={currentClient.name} refreshUi />;
  }

  if (loading) {
    return <HomeLoading refreshUi />;
  }
  const impactData = {
    totalValue: data.reporting.impact.total.value,
    investment: data.reporting.impact.investment.value,
    impact: data.reporting.impact.roi.value,
    percentDiff: data.reporting.impact.roi.percentDiff,
  };
  const trends = {
    reach: data.reporting.social.totals.reach,
    views: data.reporting.social.totals.views,
    conversions: data.reporting.salesTracking.totals.conversions,
    creators: data.reporting.social.totals.creatorCount,
    cpm: data.reporting.social.totals.cpm,
    cpe: data.reporting.social.totals.cpe,
    tmv: data.reporting.social.totals.tmv,
  };

  return (
    <RefreshHomeLayout
      Header={<RefreshHeader startDate={startDate} endDate={endDate} clientName={currentClient.name} />}
      Alert={AlertBanner}
      ImpactComponent={<RefreshImpact impactData={impactData} />}
      MetricsPrimary={(
        <RefreshMetricsPrimary
          contentData={data.reporting.content.totalCount}
          impressionsData={data.reporting.social.totals.impressions}
          engagementsData={data.reporting.social.totals.engagements}
          salesData={data.reporting.salesTracking.totals.sales}
          igStoryEstimationEnabled={igStoryEstimationEnabled}
          tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
        />
      )}
      MetricsSecondary={(
        <RefreshMetricsSecondary
          trends={trends}
          igStoryEstimationEnabled={igStoryEstimationEnabled}
          tempYoutubeDemoAccountMode={tempYoutubeDemoAccountMode}
        />
      )}
    />
  );
};
