import * as React from 'react';
import cx from 'classnames';
import { map, size } from 'lodash';
import {
  IconButton, RoundAddCircleOutlineIcon, KeyboardArrowLeftIcon,
} from '@components';

import { useFeatureFlagVerbiage } from '@frontend/app/hooks';
import { pluralize } from '@frontend/app/utils/strings';
import { ProgramInput } from '@frontend/app/types/globalTypes';
import {
  ProgramsForCommunityQuery_programs as IProgram,
} from '@frontend/app/queries/types/ProgramsForCommunityQuery';

import styles from './ProgramList.scss';

interface IProps {
  programs: Array<IProgram | ProgramInput>;
  selectedProgramId: number;
  onSelectProgram(programId: number);
  onClickNewProgram();
  className?: string;
}

export const ProgramList: React.FunctionComponent<IProps> = (props) => {
  const verbiage = useFeatureFlagVerbiage();
  return (
    <div className={cx(styles.ProgramList, props.className)}>
      <div className={styles.header}>
        <span>
          {size(props.programs)}
          {' '}
          {pluralize(size(props.programs), verbiage.Program, verbiage.Programs)}
        </span>
        <IconButton
          icon={<RoundAddCircleOutlineIcon />}
          className={styles.add}
          onClick={props.onClickNewProgram}
        />
      </div>

      <div className={styles.list}>
        {map(props.programs, (program) => (
          <div
            key={program.id}
            className={cx(styles.item, { [styles.selected]: program.id === props.selectedProgramId })}
            onClick={props.onSelectProgram.bind(this, program.id)}
          >
            <div className={styles.title}>{program.title}</div>
            <KeyboardArrowLeftIcon className={styles.icon} size={18} />
          </div>
          ))}
      </div>
    </div>
  );
};
