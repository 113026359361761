/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1t6ek_157 {
  display: flex;
}

._justify-content-space-between_1t6ek_161 {
  justify-content: space-between;
}

._tabular-nums_1t6ek_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1t6ek_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ManageOfferDrawer_1t6ek_178 .ant-drawer-body {
  padding: 0;
}
._ManageOfferDrawer_1t6ek_178 ._header_1t6ek_181 {
  position: absolute;
  left: 1.5rem;
  display: flex;
  align-items: center;
}
._ManageOfferDrawer_1t6ek_178 ._offerDetails_1t6ek_187 {
  border-bottom: 0.0625rem solid #f0f0f0;
  padding: 1rem 1.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._offerDetails_1t6ek_187 .ant-avatar {
  margin-right: 0.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._offerDetails_1t6ek_187 .ant-tag {
  border-radius: 0.375rem;
}
._ManageOfferDrawer_1t6ek_178 ._offerDetails_1t6ek_187 ._offerName_1t6ek_197 {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_1t6ek_178 ._offerDetails_1t6ek_187 ._img_1t6ek_201 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 {
  padding: 1.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._manageOfferButtons_1t6ek_212 {
  margin-top: 1.125rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._manageOfferButtons_1t6ek_212 .ant-btn {
  height: auto;
  width: 100%;
  border: none;
  text-align: unset;
  padding: 0px;
  font-weight: initial;
  margin-bottom: 0.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._manageOfferButtons_1t6ek_212 ._buttonsAction_1t6ek_224 {
  padding: 1rem;
  border: 0.0625rem solid rgb(240, 240, 240);
  border-radius: 0.375rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._manageOfferButtons_1t6ek_212 ._buttonsAction_1t6ek_224 .ant-avatar {
  background-color: #006462;
  vertical-align: middle;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._manageOfferButtons_1t6ek_212 ._buttonsAction_1t6ek_224 ._buttonTitle_1t6ek_233 {
  margin-left: 0.75rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._manageOfferButtons_1t6ek_212 ._buttonsAction_1t6ek_224 ._buttonTitle_1t6ek_233 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._manageOfferButtons_1t6ek_212 .ant-alert {
  text-wrap: wrap;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._img_1t6ek_201 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferwrapper_1t6ek_209 ._mb0_1t6ek_251 {
  margin-bottom: 0;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 {
  padding: 1.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 .ant-select {
  width: 100%;
  margin-bottom: 0.5rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 .ant-collapse {
  margin-top: 2rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._boldText_1t6ek_268 {
  font-size: 1rem;
  font-weight: 600;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._memberCount_1t6ek_272 {
  color: #505256;
  font-weight: 400;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._ManageOfferAction_1t6ek_276 {
  padding: 1rem;
  border: 0.0625rem solid rgb(240, 240, 240);
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._customInputBox_1t6ek_284 {
  border-radius: 0.375rem;
  border: 0.0625rem solid rgb(217, 217, 217);
  background: rgb(255, 255, 255);
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._customInputBox_1t6ek_284 input {
  text-transform: uppercase;
  border: 0.0625rem solid rgb(217, 217, 217);
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._customInputBox_1t6ek_284 ._tooltipError_1t6ek_293 {
  position: absolute;
  right: 1.75rem;
  top: 1.3125rem;
  color: #F1515F;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._customInputBoxError_1t6ek_299 {
  color: #F1515F;
  font-size: 0.75rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._memberCount_1t6ek_272 {
  margin-top: 1rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._activeDateContainer_1t6ek_306 {
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._activeDateContainer_1t6ek_306 ._card_1t6ek_311 {
  width: 100%;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._activeDateContainer_1t6ek_306 ._dateHeader_1t6ek_314 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._activeDateContainer_1t6ek_306 ._dateContainer_1t6ek_320 {
  width: 100%;
}
._ManageOfferDrawer_1t6ek_178 ._manageOfferContainer_1t6ek_254 ._activeDateContainer_1t6ek_306 ._dateInput_1t6ek_323 {
  border-color: none;
  width: 100%;
}
._ManageOfferDrawer_1t6ek_178 ._noticeContainer_1t6ek_327 {
  margin-bottom: 12px;
}
._ManageOfferDrawer_1t6ek_178 ._noticeContainer_1t6ek_327 .ant-typography {
  color: #F1515F;
}
._ManageOfferDrawer_1t6ek_178 ._noticeContainer_1t6ek_327 ._iconWrapper_1t6ek_333 span {
  color: #F1515F;
  width: 100%;
  font-size: 21px;
}

._defaultLabel_1t6ek_339 {
  color: #1F1211;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 400;
}

._collapseBg_1t6ek_346 {
  background-color: white;
}
._collapseBg_1t6ek_346 .ant-collapse-content-box {
  background-color: white;
  padding: 8px;
}