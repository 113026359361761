/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_15ye9_157 {
  display: flex;
}

._justify-content-space-between_15ye9_161 {
  justify-content: space-between;
}

._tabular-nums_15ye9_165 {
  font-variant-numeric: tabular-nums;
}

._SelectListPicker_15ye9_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_15ye9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._SelectListPicker_15ye9_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._SelectListPicker_15ye9_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._SelectListPicker_15ye9_169 ::-webkit-scrollbar-track, ._SelectListPicker_15ye9_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._SelectListPicker_15ye9_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._SelectListPicker_15ye9_169 {
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.25rem;
  cursor: pointer;
}
._SelectListPicker_15ye9_169._disabled_15ye9_213 {
  cursor: not-allowed;
}
._SelectListPicker_15ye9_169 ._label_15ye9_216 {
  display: flex;
  flex: 1;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._SelectListPicker_15ye9_169 ._label_15ye9_216._placeholder_15ye9_224 {
  color: #8f8d91;
}
._SelectListPicker_15ye9_169 ._downIcon_15ye9_227 {
  width: 0.5rem;
  margin-right: 0.1875rem;
  margin-left: 0.75rem;
  white-space: nowrap;
}

._overlay_15ye9_234 .ant-popover-inner-content {
  padding: 1rem;
}