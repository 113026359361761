import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { AddToListIcon } from '@components';
import { Button } from '@components';
import { Toast, IToastRefHandles } from '@components';

import { IFavoriteList } from '@components';
import { SelectListPopover } from './SelectListPopover';

import { ICreatorList } from './redux/creatorListModel';
import creatorListActions, { FLPThunkDispatch } from './redux/creatorListActions';

import styles from './AddToListButton.scss';

interface IOwnProps {
  accountIds: number[];
  classNames?: string[];
}
interface IStateProps {
  favoriteLists: IFavoriteList[];
}
interface IDispatchProps {
  addToFavoriteList(accountIds: number[], listId: number): Promise<void>;
  createFavoriteList(name: string): Promise<void>;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;
type TDefaultProp = 'classNames';
interface IState {
  // add to list popover
  showSelectListPopover: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
class AddToListButton extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private buttonRef: React.RefObject<any>;

  private toastRef: React.RefObject<IToastRefHandles>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.buttonRef = React.createRef();
    this.toastRef = React.createRef();

    this.state = {
      showSelectListPopover: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { favoriteLists, accountIds, classNames } = this.props;
    const { showSelectListPopover } = this.state;

    return (
      <>
        <Button
          label="Add to favorites list..."
          icon={<AddToListIcon size={18} />}
          className={cx(classNames.concat(styles.AddToListButton))}
          theme="info"
          ref={this.buttonRef}
          disabled={isEmpty(accountIds)}
          onClick={this.openSelectListPopover}
        />
        <SelectListPopover
          favoriteLists={favoriteLists}
          mountRef={this.buttonRef}
          show={showSelectListPopover}
          onRequestClose={this.closeSelectListPopover}
          addToFavoriteList={this.addToFavoriteList}
          createFavoriteList={this.createFavoriteList}
        />
        <Toast ref={this.toastRef} />
      </>
    );
  }

  private openSelectListPopover = () => {
    this.setState({
      showSelectListPopover: true,
    });
  };

  private closeSelectListPopover = () => {
    this.setState({
      showSelectListPopover: false,
    });
  };

  private addToFavoriteList = (listId: number) => {
    const { accountIds, favoriteLists, addToFavoriteList } = this.props;
    const toast = this.toastRef.current;
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === listId);

    return new Promise((resolve, reject) => addToFavoriteList(accountIds, listId)
        .then(() => {
          if (toast) {
            toast.showMessage({
              type: 'success',
              content: (
                <div>
                  Added
                  <span>
                    {' '}
                    {accountIds.length}
                    {' '}
                  </span>
                  creator
                  {accountIds.length > 1 ? 's' : ''}
                  {' '}
                  to favorite list
                  {' '}
                  <span>{favoriteList.name}</span>
                  .
                </div>
              ),
            });
          }

          resolve(true);
        })
        .catch(() => {
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to add creators to the favorite list.',
              type: 'error',
            });
          }

          reject();
        }));
  };

  private createFavoriteList = (name: string) => {
    const { createFavoriteList } = this.props;
    const toast = this.toastRef.current;

    return new Promise((resolve, reject) => createFavoriteList(name)
        .then(() => {
          if (toast) {
            toast.showMessage({
              type: 'success',
              content: (
                <div>
                  Created new favorite list
                  {' '}
                  <span>{name}</span>
                  .
                </div>
              ),
            });
          }

          resolve(true);
        })
        .catch(() => {
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to create new favorite list.',
              type: 'error',
            });
          }

          reject(new Error());
        }));
  };
}

const mapStateToProps = (state: ICreatorList, ownProps: IOwnProps): IStateProps & IOwnProps => ({
    favoriteLists: state.favoriteLists,

    accountIds: ownProps.accountIds,
    classNames: ownProps.classNames,
  });
const mapDispatchToProps = (dispatch: FLPThunkDispatch): IDispatchProps => ({
    createFavoriteList: (...args) => dispatch(creatorListActions.createFavoriteList(...args)),
    addToFavoriteList: (...args) =>
      dispatch(creatorListActions.addSocialAccountsToFavoriteList(...args)),
  });

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AddToListButton);
