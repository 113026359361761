import * as React from 'react';

import {
  Button,
  Form,
} from '@revfluence/fresh';

import styles from '../OfferForm.scss';

export const AdjustExpirationDateButton: React.FC = React.memo(() => (
  <Form.Item>
    <Button
      className={styles.expiredMessage}
      block
      htmlType="submit"
      type="primary"
      disabled
    >
      Adjust expiration date to reactivate offer
    </Button>
  </Form.Item>
));

AdjustExpirationDateButton.displayName = 'AdjustExpirationDateButton';
