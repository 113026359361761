import cx from 'classnames';
import {
  isFunction,
  isUndefined,
  map,
  filter,
} from 'lodash';
import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import { TrashIcon } from '@revfluence/fresh-icons/regular/esm';

import { DragHandleIcon } from '@components';

import styles from './SortableList.scss';

const {
  useMemo,
  useState,
  useCallback,
} = React;

export interface ISortableListProps<T> {
  /**
   * Class name of the list container
   */
  className?: string;
  /**
   * Default list of options
   */
  defaultOptions?: readonly ISortableItem<T>[];
  /**
   * Disable sorting
   */
  disableSort?: boolean;
  /**
   * Handlebar class name
   */
  handleClassName?: string;
  /**
   * Handle is just a visual indication of a draggable item
   * The item itself is draggable handle now
   */
  handlePosition?: 'left' | 'right' | 'none';
  /**
   * Item class name
   */
  itemClassName?: string;
  /**
   * Item on drag class name
   */
  itemOnDragClassName?: string;
  /**
   * Callback on list change
   */
  onChange: (result: ISortableItem<T>[], newIndex?: number, oldIndex?: number) => void;
  /**
   * Options
   */
  options: readonly ISortableItem<T>[];
  /**
   * Ref
   */
  sortableListRef?: React.MutableRefObject<HTMLDivElement>;

  onItemRemoved?: (result: ISortableItem<T>[], removedItem: ISortableItem) => void;

  fixedItemCount?: number;

  withHoverBackgroundColor?: boolean;

  flex?: boolean;
}

export interface IRenderParams<T> {
  item: ISortableItem<T>;
  index: number;
  snapshot: DraggableStateSnapshot;
}

export interface IRenderWithoutContainerParams<T> extends IRenderParams<T> {
  provided: DraggableProvided;
}

export interface ISortableItem<T = {}> {
  className?: string;
  data: T;
  disableSort?: boolean;
  id: string;
  removable?: boolean;
  render?: (params: IRenderParams<T>) => React.ReactNode;
  renderWithoutContainer?: (params: IRenderWithoutContainerParams<T>) => React.ReactNode;
}

const reorder = <T extends {}>(
  list: readonly ISortableItem<T>[],
  startIndex: number,
  endIndex: number,
): readonly ISortableItem<T>[] => {
  const result = Array.from<ISortableItem<T>>(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const SortableList = <T extends {}>({
  className,
  defaultOptions,
  disableSort,
  handleClassName,
  handlePosition = 'left',
  itemClassName,
  itemOnDragClassName,
  onChange,
  onItemRemoved,
  options,
  sortableListRef,
  fixedItemCount,
  withHoverBackgroundColor,
  flex,
}: React.PropsWithChildren<Readonly<ISortableListProps<T>>>) => {
  /**
   * State
   */
  const isControlled = !isUndefined(options);
  const [listState, setListState] = useState<readonly ISortableItem<T>[]>(defaultOptions);
  const list = isControlled ? options : listState;

  /**
   * On drag
   */
  const onDragEnd = (result) => {
    if (
      !result.destination
      || result.destination.index === result.source.index
      || (fixedItemCount && result.destination.index < fixedItemCount)
      || (fixedItemCount && result.source.index < fixedItemCount)
    ) {
      return;
    }
    const newList = reorder<T>(list, result.source.index, result.destination.index);
    if (!isControlled) {
      setListState(newList);
    }
    if (isFunction(onChange)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: Fix in Node upgrade typing bash!
      onChange(newList, result.destination.index, result.source.index);
    }
  };

  const handleItemRemoved = useCallback((removedItem: ISortableItem) => {
    if (!removedItem) return;

    const newList = filter(list, (item) => item.id !== removedItem.id);
    if (!isControlled) {
      setListState(newList);
    }

    if (isFunction(onItemRemoved)) {
      onItemRemoved(newList, removedItem);
    }
  }, [list, isControlled, onItemRemoved]);

  /**
   * Droppable item
   */
  const showHandles = handlePosition === 'left' || handlePosition === 'right';
  const renderItemContainer = ({
    children,
    item,
    provided,
    snapshot,
  }: {
    children: React.ReactNode;
    item: ISortableItem;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
  }) => (
    <div
      className={cx(
        styles.item,
        itemClassName,
        item.className,
        {
          [styles.disabled]: disableSort || item.disableSort,
          [styles.dragging]: snapshot.isDragging,
          [itemOnDragClassName]: snapshot.isDragging,
          [styles.withHoverBackgroundColor]: withHoverBackgroundColor,
          [styles.flex]: flex,
        },
      )}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...provided.draggableProps.style }}
    >
      {children}
      {showHandles && !disableSort && !item.disableSort && (
        <div
          className={cx(
            styles.handle,
            styles[handlePosition],
            handleClassName,
          )}
        >
          <DragHandleIcon size={14} />
        </div>
      )}
      {item.removable && (
        <div
          onClick={() => handleItemRemoved(item)}
          className={styles.trashIcon}
        >
          <TrashIcon fill="#f5232d" />
        </div>
      )}
    </div>
  );

  const renderDraggable = ({
    index,
    item,
    provided,
    snapshot,
  }: {
    index: number;
    item: ISortableItem;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
  }) => {
    if (isFunction(item.renderWithoutContainer)) {
      return item.renderWithoutContainer({
        index,
        item,
        provided,
        snapshot,
      });
    }
    const children = isFunction(item.render)
      ? item.render({
        index,
        item,
        snapshot,
      })
      : <>{item.data}</>;
    return renderItemContainer({
      children,
      item,
      provided,
      snapshot,
    });
  };

  const renderDroppableElem = (item: ISortableItem, index: number) => (
    <Draggable
      draggableId={`${index}-${item.id}`}
      index={index}
      isDragDisabled={disableSort || item.disableSort}
      key={`${index}-${item.id}`}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <>
          {renderDraggable({
          index,
          item,
          provided,
          snapshot,
        })}
        </>
)}
    </Draggable>
  );

  const droppableId = useMemo(() => uuidv4(), []);
  return (
    <div
      className={cx(styles.SortableList, className)}
      onClick={(e) => {
        // Prevent events from propagating
        e.stopPropagation();
        e.preventDefault();
      }}
      ref={sortableListRef}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided: DroppableProvided) => (
            <div
              data-chromatic="ignore"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {map(list, renderDroppableElem)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
