import * as React from 'react';

import { Avatar, Dropdown, Space } from '@revfluence/fresh';
import cx from 'classnames';
import { map, size } from 'lodash';

import {
  GetThreadQuery_thread as IThread,
} from '@frontend/app/queries/types/GetThreadQuery';

import { UserAvatar } from '@frontend/app/components';

import styles from './ThreadMembers.scss';

const { useMemo } = React;

const MAX_AVATARS_TO_DISPLAY = 3;

export interface IThreadMembers {
  thread: IThread,
  onOpenOverview: () => void;
  setSelectedMemberId: (memberId: number) => void;
}

export const ThreadMembers: React.FC<IThreadMembers> = (props) => {
  const { thread, onOpenOverview, setSelectedMemberId } = props;

  const members = useMemo(() => {
    if (!thread) return [];
    return thread.members;
  }, [thread]);

  const handleMemberClick = (memberId: number) => {
    setSelectedMemberId(memberId);
    onOpenOverview();
  };

  const innerContent = useMemo(() => {
    if (!size(members)) {
      return null;
    }

    const memberAvatars = map(members, (member) => (
      <div
        onClick={() => handleMemberClick(member.id)}
        key={member.id}
        className={styles.item}
      >
        <Space>
          <UserAvatar
            profilePicture={member?.profilePicture}
            name={member?.name}
          />
          {
            size(members) === 1 && (
              <span className={styles.memberNav}>
                {member.name || member.email}
              </span>
            )
          }
        </Space>
      </div>
    )).slice(0, MAX_AVATARS_TO_DISPLAY);

    if (size(members) > MAX_AVATARS_TO_DISPLAY) {
      memberAvatars.push(
        <div className={cx(styles.item, styles.moreContentItem)}>
          <Avatar>
            {`+${size(members) - MAX_AVATARS_TO_DISPLAY}`}
          </Avatar>
        </div>,
      );
    }

    if (size(members) === 1) {
      return (
        <div className={styles.group}>
          {memberAvatars}
        </div>
      );
    }

    const dropdownItems = map(members, (member) => ({
      key: member.id,
      label: (
        <div
          onClick={() => handleMemberClick(member.id)}
        >
          {member.name || member.email}
        </div>
      ),
      icon: (
        <UserAvatar
          profilePicture={member?.profilePicture}
          name={member?.name}
        />
      ),
    }));

    /**
     * Fresh! provides an Avatar.Group component
     * but the functionality is constrained to what we
     * want to achieve here, hence we're using a custom style solution
     */
    return (
      <Dropdown
        menu={{ items: dropdownItems }}
        placement="bottomLeft"
      >
        <div className={styles.group}>
          {memberAvatars}
        </div>
      </Dropdown>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  return (
    <div className={styles.ThreadMembers}>
      {innerContent}
    </div>
  );
};
