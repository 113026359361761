/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1beoz_157 {
  display: flex;
}

._justify-content-space-between_1beoz_161 {
  justify-content: space-between;
}

._tabular-nums_1beoz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1beoz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Popconfirm_1beoz_178 ._title_1beoz_178 ._header_1beoz_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._Popconfirm_1beoz_178 ._title_1beoz_178 ._text_1beoz_188, ._ActivationItem_1beoz_188 ._description_1beoz_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ActivationItem_1beoz_188 {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding: 0.75rem 2.25rem 0.75rem 1.125rem;
  cursor: pointer;
  transition: background-color 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: background-color;
}
._ActivationItem_1beoz_188:hover {
  background-color: #eff5f9;
}
._ActivationItem_1beoz_188 ._tag_1beoz_244 {
  margin-bottom: 0.75rem;
}
._ActivationItem_1beoz_188 ._description_1beoz_188 {
  margin-bottom: 0;
  color: #8f8d91;
}
._ActivationItem_1beoz_188 ._actions_1beoz_251 {
  position: absolute;
  top: 0;
  right: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 1.125rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: opacity;
}
._ActivationItem_1beoz_188 ._actions_1beoz_251._active_1beoz_266 {
  opacity: 1;
  pointer-events: auto;
}
._ActivationItem_1beoz_188 ._actions_1beoz_251 ._button_1beoz_270 {
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._ActivationItem_1beoz_188 ._actions_1beoz_251 ._button_1beoz_270:hover {
  color: #3996e0;
}
._ActivationItem_1beoz_188 ._actions_1beoz_251 ._editButton_1beoz_278 {
  margin-bottom: 1rem;
}

._Popconfirm_1beoz_178 ._title_1beoz_178 {
  margin-left: -1.375rem;
}
._Popconfirm_1beoz_178 ._okButton_1beoz_285 {
  width: 5rem;
  height: 2rem;
  border-radius: 6.25rem;
}
._Popconfirm_1beoz_178 ._cancelButton_1beoz_290 {
  width: 5rem;
  height: 2rem;
  border-radius: 6.25rem;
}