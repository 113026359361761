import { message } from '@revfluence/fresh';

const minetypeExtensionMap = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'video/mp4': 'mp4',
  'video/quicktime': 'mov',
  'video/webm': 'webm',
  'video/ogg': 'ogg',
  'audio/mpeg': 'mp3',
  'audio/wav': 'wav',
  'audio/ogg': 'ogg',
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'application/x-rar-compressed': 'rar',
  'application/x-tar': 'tar',
  'application/x-7z-compressed': '7z',
  'application/x-msdownload': 'exe',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
};

export const clickToDownload = async (mediaUrl) => {
  try {
    const response = await fetch(mediaUrl);
    const blob = await response.blob();
    const extension = minetypeExtensionMap[blob.type];

    const url = URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;

    // Extract the last part of the URL to use as the filename
    const segments = mediaUrl.split('/');
    const filename = segments[segments.length - 1];

    // Use the extracted filename for the download attribute
    link.download = /\.\w+$/.test(filename) ? filename : `${filename}.${extension}`;

    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    link.remove();
  } catch (e) {
    message.error(e.message);
  }
};
