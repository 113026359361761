import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ALL_PROGRAMS } from '@frontend/app/queries';
import {
  GetAllPrograms,
} from '@frontend/app/queries/types/GetAllPrograms';

type IOptions = QueryHookOptions<GetAllPrograms>;

export const useProgramsQuery = (options: IOptions = {}) => useQuery<GetAllPrograms>(GET_ALL_PROGRAMS, options);
