import { ActionType, TState } from '../types';

interface IAction {
  step: number;
  type: ActionType.SET_CURRENT_STEP;
}
export type TSetCurrentStepAction = Readonly<IAction>;

export const setCurrentStep = (state: TState, action: TSetCurrentStepAction): TState => {
  if (state.currentStep === action.step) {
    return state;
  }
  return {
    ...state,
    currentStep: action.step,
  };
};
