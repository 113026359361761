import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const FollowerIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M6.25602 9.6899C4.47902 9.6899 3.06787 8.17422 3.06787 6.34495C3.06787 4.51568 4.47902 3 6.25602 3C8.03303 3 9.44418 4.51568 9.44418 6.34495C9.44418 8.17422 7.98076 9.6899 6.25602 9.6899ZM6.25602 4.14983C5.1062 4.14983 4.2177 5.14286 4.2177 6.34495C4.2177 7.54704 5.15846 8.54007 6.25602 8.54007C7.35359 8.54007 8.29435 7.54704 8.29435 6.34495C8.29435 5.14286 7.35359 4.14983 6.25602 4.14983Z" />
    <path d="M10.4373 15.7001H2.07491C1.76132 15.7001 1.5 15.4388 1.5 15.1252C1.5 13.0869 2.44077 11.4667 4.00871 10.6304C4.27004 10.4737 4.63589 10.5782 4.79268 10.8395C4.94948 11.1008 4.84495 11.4667 4.53136 11.6235C3.48606 12.1984 2.80662 13.2437 2.64983 14.6026H9.8101C9.60105 13.1914 8.9216 12.0939 7.87631 11.5712C7.61498 11.4144 7.45819 11.1008 7.61498 10.8395C7.77178 10.5782 8.08537 10.4214 8.34669 10.5782C9.9669 11.3622 10.8554 12.9824 10.9599 15.1252C10.9599 15.282 10.9077 15.4388 10.8031 15.5433C10.6986 15.6479 10.5418 15.7001 10.4373 15.7001Z" />
    <path d="M15.9252 14.2895C15.089 14.2895 13.1552 14.2895 13.1029 14.2895C12.9461 14.2895 12.7893 14.2372 12.6848 14.1327C12.5803 14.0281 12.528 13.8714 12.528 13.7146C12.528 13.2964 11.9531 12.2511 11.744 11.9376C11.535 11.6762 11.4827 11.3626 11.6395 11.0491C11.8486 10.6309 12.3712 10.4219 13.312 10.4219C15.1935 10.4219 16.4479 11.7285 16.5001 13.6623C16.5001 13.8191 16.4479 13.9759 16.3433 14.0804C16.2388 14.2372 16.082 14.2895 15.9252 14.2895ZM12.8939 11.624C13.1552 12.0421 13.4688 12.6693 13.6256 13.1919C14.096 13.1919 14.7754 13.1919 15.298 13.1919C15.089 12.1989 14.3573 11.5717 13.312 11.5717C13.1029 11.5717 12.9984 11.5717 12.8939 11.624Z" />
    <path d="M13.3119 9.84649C11.953 9.84649 10.9077 8.74893 10.9077 7.39004C10.9077 6.03115 12.0053 4.93359 13.3119 4.93359C14.6185 4.93359 15.7161 6.03115 15.7161 7.39004C15.7161 8.74893 14.6185 9.84649 13.3119 9.84649ZM13.3119 6.08342C12.5802 6.08342 12.0053 6.65833 12.0053 7.39004C12.0053 8.12175 12.5802 8.69666 13.3119 8.69666C14.0436 8.69666 14.6185 8.12175 14.6185 7.39004C14.6185 6.65833 13.9913 6.08342 13.3119 6.08342Z" />
  </SvgIcon>
));

FollowerIcon.displayName = 'FollowerIcon';
