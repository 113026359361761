import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { groupBy, isEmpty } from 'lodash';
import { OFFER_PROMO_CODE_STATUS } from '@affiliates/types/globalTypes';

export type OfferPromoCodeErrorTypes = OFFER_PROMO_CODE_STATUS[];

const { useMemo } = React;
const failedMemberDuplicateMessage = (failedMemberDuplicatedCount: number): string | null => {
  if (failedMemberDuplicatedCount === 0) {
    return null;
  }
  let memberCodeExists = ' members\' codes already exist ';
  if (failedMemberDuplicatedCount === 1) {
    memberCodeExists = ' member code already exists ';
  }
  return `
      ${failedMemberDuplicatedCount.toLocaleString()} ${memberCodeExists}
      in Shopify. Promo codes must be unique.
    `.trim();
};
const failedMemberOtherMessage = (failedMemberOtherCount: number): string | null => {
  if (failedMemberOtherCount === 0) {
    return null;
  }
  let membersFailed = ' members\' codes generation failed ';
  if (failedMemberOtherCount === 1) {
    membersFailed = ' member code generation failed ';
  }
  return `
      ${failedMemberOtherCount.toLocaleString()} ${membersFailed}. Please try again.
    `.trim();
};
const failedMemberTooLongMessage = (failedMemberTooLongCount: number): string | null => {
  if (failedMemberTooLongCount === 0) {
    return null;
  }
  let membersFailed = ' members\' codes generation took too long ';
  if (failedMemberTooLongCount === 1) {
    membersFailed = ' member code generation took too long ';
  }
  return `
      ${failedMemberTooLongCount.toLocaleString()} ${membersFailed}. Please try again.
    `.trim();
};
export const usePromoCodeErrorMessage = (errors: OfferPromoCodeErrorTypes) => useMemo((): string[] => {
  const errorsCounter = groupBy(errors);
  const failedMemberDuplicatedCount = errorsCounter[OFFER_PROMO_CODE_STATUS.FAILED_DUPLICATE]?.length || 0;
  const failedMemberOtherCount = errorsCounter[OFFER_PROMO_CODE_STATUS.FAILED_OTHER]?.length || 0;
  const failedMemberTooLongCount = errorsCounter[OFFER_PROMO_CODE_STATUS.FAILED_TOO_LONG]?.length || 0;

  const errorMessages = [
    failedMemberDuplicateMessage(failedMemberDuplicatedCount),
    failedMemberOtherMessage(failedMemberOtherCount),
    failedMemberTooLongMessage(failedMemberTooLongCount),
  ].filter((error) => !isEmpty(error));
  return errorMessages;
}, [errors]);

export const PromoCodeErrorMessage: React.FC<{ errors: OfferPromoCodeErrorTypes }> = ({ errors }) => {
  const errorMessages = usePromoCodeErrorMessage(errors);
  if (isEmpty(errorMessages)) {
    return null;
  }
  return (
    <div>
      {errorMessages.map((message) => (
        <div key={uuidv4()}>
          {message}
        </div>
      ))}
    </div>
  );
};
