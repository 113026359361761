import {
  IconButton, ArrowLeftIcon, CloseIcon,
} from '@components';
import * as React from 'react';

import styles from './ApplicationHeader.scss';

export interface IApplicationHeaderProps {
  icon?: React.ReactNode;
  applicationId?: string;
  iconUrl?: string;
  name?: string;
  goBack?: () => void;
  title?: React.ReactElement;
  closeModal?: () => void;
}

const { useCallback } = React;
const SEND_BRIEF_HEADER_TEXT = 'send brief';

export const ApplicationHeader: React.FC<Readonly<IApplicationHeaderProps>> = (props) => {
  const renderIcon = useCallback(() => {
    if (props.icon) {
      return props.icon;
    }
    if (props.iconUrl) {
      return <img src={props.iconUrl} className={styles.icon} />;
    }
    return null;
  }, [props.iconUrl, props.icon]);
  return (
    <div className={styles.ApplicationHeader}>
      <div className={styles.headerContentWrapper}>
        { !props.name?.toLowerCase().includes(SEND_BRIEF_HEADER_TEXT) && (
          <IconButton
            icon={<ArrowLeftIcon size={16} />}
            onClick={props.goBack}
          />
        )}
      </div>
      <div className={styles.headerContentWrapper}>
        {renderIcon()}
        <span className={styles.title}>{props.title || props.name}</span>
      </div>
      <div className={styles.headerContentWrapper}>
        <IconButton
          icon={<CloseIcon size={16} />}
          onClick={props.closeModal}
        />
      </div>
    </div>
  );
};
