import { isNumber } from 'lodash';
import numeral from 'numeral';

import { renderEmptyCell } from '../EmptyCell';

export const renderPercentageCell = (rawValue: string) => {
  if (isNumber(rawValue)) {
    return numeral(rawValue).format('0.0%');
  }
  return renderEmptyCell();
};
