import { toString } from 'lodash';

import { IBrand } from '@components';

export const getLikedContentIds = (activeBrand: IBrand) => {
  if (!activeBrand) {
    return [];
  }

  const contentIds: string[] = [
    toString(activeBrand.liked_content_list_id),
  ];

  return contentIds;
};
