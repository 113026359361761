import { isEmpty } from 'lodash';
import { TUseGetAdAccountSettingsQuery, TUseGetSocialAccountSettingsQuery } from '@frontend/app/hooks';

type SocialListeningDebug = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0]['social_listening_debug'];
type IGDMDebug = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0]['igdm_debug'];
type BrandedContentAdsDebug = TUseGetSocialAccountSettingsQuery['data']['getSocialAccountClientSettings']['instagramAccountData']['accounts'][0]['branded_content_ads_debug'];
type AdAccountDebug = TUseGetAdAccountSettingsQuery['data']['getSocialAccountClientSettings']['metaAdAccountData']['accounts'][0];

const permissionsMap = {
  instagram_basic: 'Access profile and post from the Instagram account connected to your page',
  instagram_manage_comments: 'Manage comments from the Instagram account connected to your page',
  pages_read_engagement: 'Read content posted on the page',
  pages_show_list: 'Show a list of the Pages you manage',
  pages_manage_metadata: 'Manage accounts, setting, and webhooks for a Page',
  instagram_manage_messages: 'Manage and access messages for the Instagram account connected to your page',
  pages_messaging: 'Manage and access page conversations in Messenger',
  instagram_branded_content_ads_brand: 'Manage ads for ad accounts that your have access to',
  instagram_branded_content_brand: 'Access or modify a Instagram creators branded content setting',
  business_management: 'Manage your business',
  ads_management: 'Manage ads for ad accounts that your have access to',
  creator_marketplace: 'Access Instagram\'s creator marketplace with the Instagram accounts you selected',
};

export type SocialListeningFeatures = [
  { feature: 'Tag Mentions', errorMessage: string },
  { feature: 'Story mentions', errorMessage: string },
  { feature: 'Caption mentions', errorMessage: string },
  { feature: 'Branded Content mentions', errorMessage: string },
];

export type DirectMessagingFeatures = [
  { feature: 'Creator outreach', errorMessage: string },
  { feature: 'DM Inbox', errorMessage: string },
];

export type BrandedContentAdsFeatures = [
  { feature: 'Sending terms with Paid Partnership Ads', errorMessage: string },
];

export type AdsAccountFeatures = [
  { feature: 'Viewing ad insight data', errorMessage: string },
];

export type BusinessFeatures = [
  { feature: 'Granting allowlisting permissions from creators' },
];

const composeMissingPermissionsErrorMessage = (missingPermissions: string[]): string => {
  if (isEmpty(missingPermissions)) {
    return '';
  }
  const missingPermissionsText = missingPermissions
    .map((permission) => permissionsMap[permission] || permission)
    .join(', and ,');
  return `Please reconnect and enable |${missingPermissionsText}`;
};

type IGDMFeatures = 'Creator outreach' | 'DM Inbox';

const composeIGDMErrorMessage = (debug: IGDMDebug, feature: IGDMFeatures) => {
  switch (feature) {
    case 'Creator outreach':
      if (debug.outreach.not_in_creator_marketplace) {
        return 'Disabled because this account is ineligible for the Instagram Creator Marketplace.';
      }
      return '';
    case 'DM Inbox':
      if (debug.messaging.messaging_toggle_disabled) {
        return 'Please enable the messaging toggle in your Instagram App settings.';
      }
      if (!isEmpty(debug.messaging.missing_permissions)) {
        return composeMissingPermissionsErrorMessage(debug.messaging.missing_permissions);
      }
      return '';
  }
};

export const composeSocialListeningFeatures = (debug: SocialListeningDebug): SocialListeningFeatures => {
  const {
    tag_mention,
    story_mention,
    caption_mention,
    branded_content_mention,
  } = debug;
  return [
    { feature: 'Tag Mentions', errorMessage: composeMissingPermissionsErrorMessage(tag_mention.missing_permissions) },
    { feature: 'Story mentions', errorMessage: composeMissingPermissionsErrorMessage(story_mention.missing_permissions) },
    { feature: 'Caption mentions', errorMessage: composeMissingPermissionsErrorMessage(caption_mention.missing_permissions) },
    { feature: 'Branded Content mentions', errorMessage: composeMissingPermissionsErrorMessage(branded_content_mention.missing_permissions) },
  ];
};

export const composeDirectMessagingFeatures = (debug: IGDMDebug): DirectMessagingFeatures => [
  { feature: 'Creator outreach', errorMessage: composeIGDMErrorMessage(debug, 'Creator outreach') },
  { feature: 'DM Inbox', errorMessage: composeIGDMErrorMessage(debug, 'DM Inbox') },
];

export const composeAdsPermissionsFeatures = (debug: BrandedContentAdsDebug): BrandedContentAdsFeatures => {
  const {
    missing_permissions,
  } = debug;
  return [
    { feature: 'Sending terms with Paid Partnership Ads', errorMessage: composeMissingPermissionsErrorMessage(missing_permissions) },
  ];
};

export const composeAdsAccountsFeatures = (debug: AdAccountDebug): AdsAccountFeatures => {
  const {
    missing_permissions,
  } = debug;
  return [
    { feature: 'Viewing ad insight data', errorMessage: composeMissingPermissionsErrorMessage(missing_permissions) },
  ];
};

export const composeBusinessFeatures = (): BusinessFeatures => [{ feature: 'Granting allowlisting permissions from creators' }];
