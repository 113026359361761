import React from 'react';
import {
  Card,
  Divider, Typography,
} from '@revfluence/fresh';
import { ChartPieIcon } from '@revfluence/fresh-icons/regular/esm';
import { TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { AssignPaymentTo, PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import styles from './BudgetTrackerSuccess.scss';
import { SectionHeader } from '../SectionHeader';
import { SubItemSmall } from '../SubItemSmall';

const { useMemo } = React;
const { Text } = Typography;

interface IProps {
  state: TState;
}

export const BudgetTrackerSuccess: React.FC<IProps> = (props) => {
  const {
    state,
  } = props;
  const payments = useMemo(() => {
    if (state.assignPaymentTo === AssignPaymentTo.Other) {
      return state.payments.map((payment) => {
        const budget = state.budgetAccounts.find((budget) => budget.id === payment.budgetId);
        return {
          name: budget?.name || '',
          amount: payment.amount,
          label: payment.budgetGranularityLabel,
        };
      });
    } else {
      return state.payments.map((payment) => ({
          name: 'Project’s Total Budget',
          amount: payment.amount,
          label: payment.budgetGranularityLabel,
        }));
    }
  }, [state]);
  const overFlowPayments = useMemo(() => {
    if (state.paymentCreationSource !== PaymentCreationSource.SALES_TRACKING) {
      return [];
    }
    return state.overflowPayments.map((payment) => {
      const budget = state.budgetAccounts.find((budget) => budget.id === payment.budgetId);
      return {
        name: budget?.name || '',
        amount: payment.amount,
        label: payment.budgetGranularityLabel,
      };
    });
  }, [state]);
  return (
    <div className={styles.budgetTrackerSuccessContainer}>
      <SectionHeader
        title="Budget Tracker"
        sectionIcon={<ChartPieIcon />}
      />
      <Divider className={styles.divider} />
      <Text>This payment is assigned to</Text>
      <div>
        {
          payments.map((payment, index) => (
            <Card className={styles.summaryCard} key={index}>
              <div className={styles.summaryCardInner}>
                <SubItemSmall
                  name="Budget Account"
                  value={payment.name}
                />
                <SubItemSmall
                  name="Fiscal Year Period"
                  value={payment.label}
                />
                <SubItemSmall
                  name="Amount Spent"
                  value={`$${payment.amount} USD`}
                />
              </div>
            </Card>
            ))
        }
      </div>
      {
        state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING && (
          <div>
            <Divider className={styles.divider} />
            <Text>If a member is not part of any project, their payments will be fully assigned to the selected budget below.</Text>
            <div>
              {
                overFlowPayments.map((payment, index) => (
                  <Card className={styles.summaryCard} key={index}>
                    <div className={styles.summaryCardInner}>
                      <SubItemSmall
                        name="Budget Account"
                        value={payment.name}
                      />
                      <SubItemSmall
                        name="Fiscal Year Period"
                        value={payment.label}
                      />
                    </div>
                  </Card>
                  ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
};
