import 'css-chunk:src/app/components/MessageComposer/SendEmailForm.module.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1docd_157",
  "justify-content-space-between": "_justify-content-space-between_1docd_161",
  "tabular-nums": "_tabular-nums_1docd_165",
  "SendEmailForm": "_SendEmailForm_1docd_178",
  "info": "_info_1docd_178",
  "memberCount": "_memberCount_1docd_178",
  "fullHeight": "_fullHeight_1docd_227",
  "noTopBorder": "_noTopBorder_1docd_239",
  "cursor": "_cursor_1docd_242",
  "validMember": "_validMember_1docd_245",
  "additionalMembers": "_additionalMembers_1docd_246",
  "memberCcForMemberCount": "_memberCcForMemberCount_1docd_260",
  "memberCc": "_memberCc_1docd_260",
  "resourceSettingsButton": "_resourceSettingsButton_1docd_270",
  "messageTypeSelect": "_messageTypeSelect_1docd_273",
  "editor": "_editor_1docd_278",
  "headerButtons": "_headerButtons_1docd_284",
  "formSpace": "_formSpace_1docd_287",
  "headerForcedClose": "_headerForcedClose_1docd_292",
  "memberInput": "_memberInput_1docd_292",
  "newThread": "_newThread_1docd_299",
  "sharedIcon": "_sharedIcon_1docd_303",
  "show": "_show_1docd_1"
};