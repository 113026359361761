/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_d0yhv_157 {
  display: flex;
}

._justify-content-space-between_d0yhv_161 {
  justify-content: space-between;
}

._tabular-nums_d0yhv_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_d0yhv_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SplashPage_d0yhv_178 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 3.125rem;
}
._SplashPage_d0yhv_178 ._textArea_d0yhv_185 {
  width: 30.5625rem;
}
._SplashPage_d0yhv_178 ._textArea_d0yhv_185 ._title_d0yhv_188 {
  font-size: 1.875rem;
  margin-bottom: 1.25rem;
}
._SplashPage_d0yhv_178 ._textArea_d0yhv_185 ._description_d0yhv_192 {
  font-size: 1rem;
  margin-bottom: 1.25rem;
}
._SplashPage_d0yhv_178 ._textArea_d0yhv_185 ._ctaButton_d0yhv_196 button {
  width: 90%;
}