import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const DragHandleIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g>
      <circle cx="8" cy="4" r="2" />
      <circle cx="8" cy="20" r="2" />
      <circle cx="8" cy="12" r="2" />
      <circle cx="16" cy="4" r="2" />
      <circle cx="16" cy="20" r="2" />
      <circle cx="16" cy="12" r="2" />
    </g>
  </SvgIcon>
));

DragHandleIcon.displayName = 'DragHandleIcon';

export { DragHandleIcon };
