import { deleteGuidelineAttachment } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IState } from '../../../../types/state';
import { IRemoveAttachmentAction } from './index';

export const removeAttachment = (state: IState, action: IRemoveAttachmentAction): IState => ({
  ...state,
  contentGuidelines: deleteGuidelineAttachment(
    state.contentGuidelines,
    action.contentGuidelineInstanceId,
    action.attachments,
  ),
});
