/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_rz0x8_157 {
  display: flex;
}

._justify-content-space-between_rz0x8_161 {
  justify-content: space-between;
}

._tabular-nums_rz0x8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_rz0x8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DateMenu_rz0x8_178 {
  max-width: 21.875rem;
}
._DateMenu_rz0x8_178 .ant-menu-item {
  margin-bottom: 0.25rem !important;
}
._DateMenu_rz0x8_178 ._customRangeDatePicker_rz0x8_184 {
  height: 2.625rem;
}
._DateMenu_rz0x8_178 ._customRangeDatePicker_rz0x8_184 ._customRangeWrapper_rz0x8_187 {
  display: flex;
}
._DateMenu_rz0x8_178 ._customRangeDatePicker_rz0x8_184 ._customRangeWrapper_rz0x8_187 .ant-picker {
  flex: 1 1 auto;
}
._DateMenu_rz0x8_178 ._customRangeDatePicker_rz0x8_184 ._customRangeWrapper_rz0x8_187 .ant-btn {
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
}
._DateMenu_rz0x8_178._paymentsOverviewDateMenu_rz0x8_200 {
  /* 402 px is the exact width of the dropdown button for the date picker */
  max-width: 25.125rem;
}
._DateMenu_rz0x8_178 ._instructions_rz0x8_204 {
  padding: 0.25rem 1rem 0.5rem;
  line-height: 1.25rem;
}

._CircleXIcon_rz0x8_209 {
  margin-top: 0.5rem;
}