import * as React from 'react';
import { Input } from '@revfluence/fresh';

interface IProps {
  className?: string;
  onQueryChange: (query: string) => void;
  onPerformSearch: () => void;
  placeholder?: string;
  query: string;
}

export const TextQueryInput = React.memo((props: IProps) => {
  const {
    className,
    onQueryChange,
    onPerformSearch,
    placeholder,
    query,
  } = props;

  const handlePressEnter = () => {
    onQueryChange(query);
    onPerformSearch();
  };

  const onChange = (e) => {
    onQueryChange(e.target.value);
  };

  return (
    <Input
      value={query}
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      onPressEnter={handlePressEnter}
    />
  );
});
