import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_CATALOG_COLLECTIONS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import {
  GetCatalogCollectionsQuery,
  GetCatalogCollectionsQueryVariables,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/GetCatalogCollectionsQuery';

type IOptions = QueryHookOptions<GetCatalogCollectionsQuery, GetCatalogCollectionsQueryVariables>;

export const useGetCatalogCollections = (options: IOptions = {}) => {
  const {
 loading, data, error, refetch,
} = useQuery(GET_CATALOG_COLLECTIONS_QUERY, options);

  return {
    loading,
    collections: data?.getCatalogCollectionsByCatalogId ?? [],
    error,
    refetch,
  };
};
