import TriggerNode from '@frontend/app/containers/Projects/AutomationConfig/AutomationNode/TriggerNode';
import DelayNode from '@frontend/app/containers/Projects/AutomationConfig/AutomationNode/DelayNode';
import ConditionNode from '@frontend/app/containers/Projects/AutomationConfig/AutomationNode/ConditionNode';
import ActionNode from '@frontend/app/containers/Projects/AutomationConfig/AutomationNode/ActionNode';
import { ModalProps } from 'antd';
import { AutomationTriggerType } from '@frontend/app/types/globalTypes';

export const nodeTypes = {
  TRIGGER: TriggerNode,
  DELAY: DelayNode,
  CONDITION: ConditionNode,
  ACTION: ActionNode,
};

export const NODE_EDGE_COLOR = {
  PRIMARY: '#389E0D',
  EDITING: '#BDD7C8',
};

export enum AutomationAction {
  DISABLE = 'disable',
  DELETE = 'delete',
  RESUME = 'resume',
}

export const actionItems = [{
  name: 'Turn Off',
  action: AutomationAction.DISABLE,
}, {
  name: 'Delete Automation',
  action: AutomationAction.DELETE,
}, {
  name: 'Turn On',
  action: AutomationAction.RESUME,
}];

export const NODE_BASE_HEIGHT = 130;
export const NODE_HEIGHT_OFFSET = 25;

export const UNSAVE_ALERT_CUSTOM_MODAL_PROPS: ModalProps = {
  okButtonProps: {
    type: 'primary',
  },
};

export const automationTriggerDisplayName = {
  [AutomationTriggerType.BRIEF_ACCEPTED]: 'A brief is accepted',
  [AutomationTriggerType.BRIEF_SENT]: 'A member is sent a brief',
  [AutomationTriggerType.DELIVERABLE_COMPLETED]: 'A deliverable is completed',
  [AutomationTriggerType.DELIVERABLE_REJECTED]: 'A deliverable is rejected',
  [AutomationTriggerType.DELIVERABLE_SUBMITTED]: 'A deliverable is submitted',
  [AutomationTriggerType.PRODUCT_ORDER_DELIVERED]: 'A product order is delivered',
  [AutomationTriggerType.PRODUCT_ORDER_REQUEST_SENT]: 'A product order request is sent',
  [AutomationTriggerType.PROGRAM_APPLIED]: 'A member applies to this project',
  [AutomationTriggerType.PROGRAM_APPLICANT_APPROVED]: 'A member that applied is accepted',
  [AutomationTriggerType.PROGRAM_INVITED]: 'A member is invited to this project',
  [AutomationTriggerType.PROGRAM_REJECTED]: 'A member is rejected from this project',
  [AutomationTriggerType.SALES_TRACKING_FIRST_CONVERSION]: 'sales tracking first conversion',
};
