import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { trim } from 'lodash';

import { EventName } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { useEventContext } from '@frontend/app/context';
import { Wizard } from '@frontend/app/components/WizardV2/Wizard';

import {
  useGetCurrentClient,
  useSaveCurrentClient,
} from '@frontend/app/hooks';
import { ProjectsRouteRoot } from '../../Projects/constants';

import { steps } from './config';
import {
  Objective,
  Setup,
  Requirements,
  MarketplaceListing,
  Preview,
} from './steps';

import { useCreateProject } from './hooks/useCreateProject';
import { initOnboardingState, OnboardingReducer } from './reducer';

import { CampaignStatus, OnboardingStep } from './types';

import { useCreateMarketplaceCampaign } from './hooks/useCreateMarketplaceCampaign';
import { useGetApplicationFormFields } from './hooks/useGetApplicationFormFields';
import { useRefetch } from './hooks/useRefetch';
import { ProjectApplicationPageTemplateName } from '../../Projects/applicationPageUtils';

const {
 useReducer, useState, useMemo, useCallback,
} = React;

export const OnboardingWizard = () => {
  const history = useHistory();
  const [onboardingState, dispatch] = useReducer(OnboardingReducer, initOnboardingState);
  const [isCreatingProject, setIsCreatingProject] = useState<boolean>(false);
  const [isCreatingAndListingProject, setIsCreatingAndListingProject] = useState<boolean>(false);
  const addEvent = useEventContext();
  const { refetch } = useRefetch();
  const { client: currentClient } = useGetCurrentClient();
  const { saveClient } = useSaveCurrentClient();
  const objectiveInput = onboardingState[OnboardingStep.Objective].data;
  const setupInput = onboardingState[OnboardingStep.Setup].data;
  const requirementsInput = onboardingState[OnboardingStep.Requirements].data;
  const marketplaceListingInput = onboardingState[OnboardingStep.MarketplaceListing].data;

  const applicationFormFieldsInput = useGetApplicationFormFields(requirementsInput);

  const { createProject } = useCreateProject(
    setupInput.projectType,
    applicationFormFieldsInput,
  );

  const { showErrorMessage } = useMessagingContext();

  const {
    createMarketplaceCampaign,
  } = useCreateMarketplaceCampaign(trim(setupInput.title));

  const submit = async () => {
    try {
      setIsCreatingAndListingProject(true);

      saveClient({
        variables: {
          client: {
            id: currentClient.id,
            objective: objectiveInput?.objective,
            brandCategory: objectiveInput?.brandCategory,
          },
        },
      });

      const project = await createProject(
        setupInput,
        requirementsInput,
        marketplaceListingInput,
        true,
      );
      await createMarketplaceCampaign(
        project,
        setupInput,
        objectiveInput,
        requirementsInput,
        marketplaceListingInput,
        CampaignStatus.Publish,
      );

      addEvent(
        EventName.OnboardingFlowList,
        eventProperties,
      );

      history.replace({
        pathname: '/onboarding',
        search: `?${new URLSearchParams({ projectId: project.id.toString() })}`,
        state: 'project_created_and_listed',
      });
      setIsCreatingAndListingProject(false);
    } catch (error) {
      showErrorMessage(error.message);
      setIsCreatingAndListingProject(false);
    }
  };

  const skip = async () => {
    try {
      setIsCreatingProject(true);
      saveClient({
        variables: {
          client: {
            id: currentClient.id,
            objective: objectiveInput?.objective,
            brandCategory: objectiveInput?.brandCategory,
          },
        },
      });

      const project = await createProject(
        setupInput,
        requirementsInput,
        marketplaceListingInput,
        true,
      );
      await createMarketplaceCampaign(
        project,
        setupInput,
        objectiveInput,
        requirementsInput,
        marketplaceListingInput,
        CampaignStatus.Unpublish,
      );
      await refetch();
      setIsCreatingProject(false);

      addEvent(
        EventName.OnboardingFlowSkip,
        eventProperties,
      );

      history.replace({
        pathname: `${ProjectsRouteRoot}/${project.id}/overview`,
        state: 'project_created',
      });
    } catch (error) {
      showErrorMessage(error.message);
      setIsCreatingProject(false);
    }
  };

  const eventProperties = useMemo(() => ({
      project_type: setupInput.projectType,
      content: requirementsInput.product_types,
      creator_location: requirementsInput.accepted_place_ids,
      offers_payment: requirementsInput.offers_payment,
      offers_product: requirementsInput.offers_product,
      offers_commission: requirementsInput.offers_commission,
      offers_other: requirementsInput.offers_other,
    }), [
    setupInput.projectType,
    requirementsInput,
  ]);

  const onNext = useCallback((targetStepIndex) => {
    const eventName = steps[targetStepIndex].eventName;

    addEvent(
      eventName,
      eventProperties,
    );
  }, [addEvent, eventProperties]);

  const checkNextButtonDisabled = (currentStepName: string) => (
    !onboardingState[currentStepName].isCompleted
  );

  const renderContent = (currentStepIndex) => {
    const currentStepConfig = steps[currentStepIndex];
    const currentStepState = onboardingState[currentStepConfig.name];

    switch (currentStepIndex) {
      case 0:
        return (
          <Objective
            state={currentStepState}
            dispatch={dispatch}
            config={currentStepConfig}
          />
        );
      case 1:
        return (
          <Setup
            state={currentStepState}
            dispatch={dispatch}
            config={currentStepConfig}
          />
        );
      case 2:
        return (
          <Requirements
            state={currentStepState}
            dispatch={dispatch}
            config={currentStepConfig}
          />
        );
      case 3:
        return (
          <MarketplaceListing
            state={currentStepState}
            dispatch={dispatch}
            config={currentStepConfig}
          />
        );
      case 4:
        // In onboarding case, preview step need entire state
        return (
          <Preview
            state={onboardingState}
            config={currentStepConfig}
            applicationFormFields={applicationFormFieldsInput}
            template={ProjectApplicationPageTemplateName.Customizable}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Wizard
      steps={steps}
      submit={submit}
      checkNextButtonDisabled={checkNextButtonDisabled}
      skip={skip}
      isSkipLoading={isCreatingProject}
      isSubmitLoading={isCreatingAndListingProject}
      onNext={onNext}
      scrollableContent
      showLogo
    >
      {renderContent}
    </Wizard>
  );
};

OnboardingWizard.displayName = 'OnboardingWizard';
