import { Table as AntdTable } from 'antd';
import cx from 'classnames';

import * as React from 'react';

import styles from './Table.scss';

export const Table = (props) => {
  const { className, ...restProps } = props;
  return (
    <AntdTable
      className={cx(styles.Table, className)}
      {...restProps}
    />
  );
};
