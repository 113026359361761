import * as React from 'react';
import {
  isEmpty, isNil, first, toString, uniqBy,
} from 'lodash';
import { Empty } from 'antd';

import { LoadSpinner } from '@components';

import { message } from '@revfluence/fresh';
import { GetProductsV3Query } from '../queries/types/GetProductsV3Query';
import { ProductContainer } from '../components/ProductContainer';
import { SearchBarV2 } from '../components/SearchBarV2';
import { Footer } from '../components/Footer';
import { useGetProductsV3, IResource, IProduct } from '../hooks';

import styles from './SelectProductForm.scss';
import { logger } from '../../../../../common/Logger';

const { useState, useEffect, useCallback } = React;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second
interface IProps {
  resources: IResource[];
  incrementStep(): void;
  selectedProducts: IProduct[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<IProduct[]>>;
  selectedItemCount: number;
  loadingResources: boolean;
  selectedResource: IResource;
  setSelectedResource(resource: IResource): void;
  setTokenIsInvalid(isInvalid: boolean): void;
  bypassShopifyInventory: boolean;
}
interface IPageInfo {
  cursor: string | null;
  hasNextPage: boolean;
}

const DEFAULT_PAGE_INFO: IPageInfo = {
  cursor: null,
  hasNextPage: false,
};

export const SelectProductFormV3: React.FunctionComponent<IProps> = (props) => {
  const {
    selectedResource,
    setSelectedResource,
    resources,
    incrementStep,
    selectedProducts,
    setSelectedProducts,
    selectedItemCount,
    loadingResources,
    setTokenIsInvalid,
    bypassShopifyInventory,
  } = props;
  const [localProducts, setLocalProducts] = useState<IProduct[]>([]);

  const [messageApi, contextHolder] = message.useMessage();

  const [searchValue, setSearchValue] = useState<string>('');
  const [pageInfo, setPageInfo] = useState<IPageInfo>(DEFAULT_PAGE_INFO);
  const {
    productCollection, loading: loadingProducts, refetch, error, fetchMore,
  } = useGetProductsV3({
    variables: {
      resourceId: selectedResource?.id,
      query: '',
    },
    skip: !selectedResource?.id,
  });
  const { products } = productCollection;
  useEffect(() => {
    if (products) {
      setLocalProducts(products);
    }
  }, [products]);
  const updateSelectedResouce = useCallback(
    (id: number) => {
      setSelectedResource(resources.find((r) => r.id === id));
    },
    [resources, setSelectedResource],
  );
  useEffect(() => {
    if (error?.message) {
      messageApi.error(error.message, 10);
    }
  }, [error?.message, messageApi]);

  useEffect(() => {
    setPageInfo((prevPageInfo) => {
      if (
        productCollection.pageInfo?.cursor
        && (prevPageInfo.cursor !== productCollection?.pageInfo.cursor
          || prevPageInfo.hasNextPage !== productCollection?.pageInfo.hasNextPage)
      ) {
        return productCollection.pageInfo;
      }
      return prevPageInfo;
    });
  }, [productCollection]);

  const fetchSomeMore = useCallback(() => {
    if (!pageInfo.hasNextPage || !pageInfo.cursor || loadingProducts) {
      return;
    }

    let retries = 0;

    const executeFetch = () => {
      fetchMore({
        variables: {
          resourceId: selectedResource?.id,
          cursor: pageInfo.cursor,
        },
        updateQuery: (previousResult: GetProductsV3Query, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            if (retries < MAX_RETRIES) {
              retries++;
              setTimeout(executeFetch, RETRY_DELAY);
              return previousResult; // Return the previous result until successful
            }
            // Handle max retries reached scenario if needed
            return previousResult;
          }

          // Combine the products
          const newProducts = uniqBy(
            [...localProducts, ...fetchMoreResult.productCollection.products],
            'id',
          );

          // Update the local state
          setLocalProducts(newProducts);

          return {
            productCollection: {
              products: newProducts,
              pageInfo: fetchMoreResult.productCollection.pageInfo,
              __typename: 'ProductCollection',
            },
          };
        },
      }).catch((error) => {
        logger.error(`Error fetching more products: ${error}`);
        if (retries < MAX_RETRIES) {
          retries++;
          setTimeout(executeFetch, RETRY_DELAY);
        }
      });
    };

    executeFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo, loadingProducts, fetchMore, selectedResource]);

  useEffect(() => {
    if (!isNil(resources) && !isEmpty(resources) && isNil(selectedResource)) {
      setSelectedResource(resources[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  useEffect(() => {
    if (selectedResource && !isNil(searchValue)) {
      refetch({
        resourceId: selectedResource?.id,
        query: searchValue,
      });
    }
  }, [selectedResource, refetch, searchValue]);

  useEffect(() => {
    if (error) {
      if (error.message.includes('Invalid API key or access token (unrecognized login or wrong password)')) {
        setTokenIsInvalid(true);
      }
      const errCode = parseInt(first(toString(error.message).match(/(\d+)/)), 10);

      if (errCode === 401 || errCode === 403) {
        setTokenIsInvalid(true);
      } else {
        logger.warn(`Product search error ${error}`);
      }
    }
  }, [error, setTokenIsInvalid]);

  // if loading just return the spinner
  if (loadingResources) {
    return (
      <>
        {contextHolder}
        <LoadSpinner />
      </>
    );
  }

  return (
    <>
      {contextHolder}
      <div className={styles.selectProductForm}>
        <div className={styles.scrollable}>
          <div className={styles.products}>
            <div className={styles.title}>1. Select product</div>
            {selectedResource && (
              <SearchBarV2
                initialSearchValue={searchValue}
                setSearchValue={setSearchValue}
                resources={resources}
                setSelectedResouce={updateSelectedResouce}
                selectedResource={selectedResource}
              />
            )}

            {!isEmpty(products) && (
              <ProductContainer
                setProducts={setLocalProducts}
                resourceId={selectedResource.id}
                products={localProducts}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                fetchMore={fetchSomeMore}
                isFetchingMore={loadingProducts && !isEmpty(localProducts)}
                bypassShopifyInventory={bypassShopifyInventory}
              />
            )}
            {loadingProducts && isEmpty(localProducts) && <LoadSpinner />}
            {!loadingProducts && isEmpty(localProducts) && (
              <Empty
                className={styles.empty}
                description="No products match your search. Please try searching for complete words."
              />
            )}
          </div>
        </div>
        <Footer
          className={styles.footer}
          disablePrimaryAction={selectedItemCount === 0}
          numberOfProductsSelected={selectedItemCount}
          primaryButtonText="Next"
          primaryButtonAction={incrementStep}
        />
      </div>
    </>
  );
};
