import * as React from 'react';
import { Tooltip } from '@revfluence/fresh';
import { InfoIcon } from '@components';
import styles from './StatusHeader.scss';

export const StatusHeader = () => (
  <>
    Status
    {' '}
    <Tooltip
      title="Status is determined based on your monthly active permissions."
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: Fix in Node upgrade typing bash!
      zIndex="10000"
    >
      <InfoIcon size={16} className={styles.icon} />
    </Tooltip>
  </>
);
