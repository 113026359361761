import { find } from 'lodash';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import { updateGuidelineLabel } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IUpdateContentGuidelineLabelAction, IState } from '../../../types/state';

export const updateContentGuidelineLabel = (
  state: IState,
  action: IUpdateContentGuidelineLabelAction,
): IState => {
  const foundContentGuideline = find(
    state.contentGuidelines,
    (
      contentGuideline: TContentGuideline,
    ) => contentGuideline.id === action.id,
  );
  if (!foundContentGuideline || foundContentGuideline.label === action.label) {
    return state;
  }
  return {
    ...state,
    contentGuidelines: updateGuidelineLabel(
      state.contentGuidelines,
      action.id,
      action.label,
    ),
    isEditMode: true,
  };
};
