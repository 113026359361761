import 'css-chunk:src/app/containers/MessagingApp/MessageList/SystemMessageItem.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_l9b2l_157",
  "justify-content-space-between": "_justify-content-space-between_l9b2l_161",
  "tabular-nums": "_tabular-nums_l9b2l_165",
  "SystemMessageItem": "_SystemMessageItem_l9b2l_178",
  "header": "_header_l9b2l_178",
  "subject": "_subject_l9b2l_178",
  "date": "_date_l9b2l_188",
  "collapsed": "_collapsed_l9b2l_241",
  "avatar": "_avatar_l9b2l_254",
  "messageDetail": "_messageDetail_l9b2l_267",
  "content": "_content_l9b2l_278",
  "message": "_message_l9b2l_267",
  "notice": "_notice_l9b2l_288",
  "image": "_image_l9b2l_292",
  "action": "_action_l9b2l_299",
  "attachements": "_attachements_l9b2l_302",
  "attachment": "_attachment_l9b2l_306",
  "show": "_show_l9b2l_1"
};