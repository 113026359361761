import * as React from 'react';
import { pick } from 'lodash';
import { logger } from '@common';

import { Modal } from '@revfluence/fresh';

import {
  IModalProps, Input, SubmitButton,
} from '@components';

import { SimpleForm, SimpleField } from '@frontend/app/components';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { saveSignerData } from '../useSaveSigner';
import { IDesignatedContractSigner } from '../useFetchSigners';

import styles from './NewSignerModal.scss';

const { useState, useRef } = React;

interface IProps extends IModalProps {
  className?: string;
}

export const NewSignerModal: React.FunctionComponent<IProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { backendServerApiEndpoint, clientId } = useApplication();

  const EMPTY_INPUT = {
    first_name: '',
    last_name: '',
    email_address: '',
    client_id: clientId,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [input, setInput] = useState<IDesignatedContractSigner>(EMPTY_INPUT as any);

  const formRef = useRef<SimpleForm>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const firstNameRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lastNameRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailAddressRef = useRef<any>();

  const handleChangeFields = (fieldName: string, value: string) => {
    setInput((i) => ({ ...i, [fieldName]: value }));
  };

  const handleClickSave = () => {
    formRef.current.submit();
  };

  const formValues = pick(input, ['first_name', 'last_name', 'email_address']);

  const handleSubmit = () => {
    setLoading(true);
    saveSignerData(`${backendServerApiEndpoint}/contract_signer`, {
      first_name: formValues.first_name,
      last_name: formValues.last_name,
      name: `${formValues.first_name} ${formValues.last_name}`,
      email_address: formValues.email_address,
      client_id: clientId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any).then(props.onRequestClose).finally(() => setLoading(false))
    .catch(logger.error);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const focus = (field: any) => {
    field.current.focus();
  };

  return (
    <Modal
      className={props.className}
      open={props.show}
      footer={null}
      onCancel={props.onRequestClose}
    >
      <div className={styles.NewSignerModal}>
        <div className={styles.title}>Add a Signer</div>
        <div className={styles.subtitle}>
          Add an authorized signer for your organization
        </div>
        <SimpleForm
          ref={formRef}
          values={formValues}
          onChange={handleChangeFields}
          onSubmit={handleSubmit}
        >
          <div className={styles.form}>
            <div className={styles.label} onClick={() => focus(firstNameRef)}>
              First Name:
            </div>
            <SimpleField name="first_name" type="name">
              <Input ref={firstNameRef} />
            </SimpleField>

            <div className={styles.label} onClick={() => focus(lastNameRef)}>
              Last Name:
            </div>
            <SimpleField name="last_name" type="name">
              <Input ref={lastNameRef} />
            </SimpleField>

            <div className={styles.label} onClick={() => focus(emailAddressRef)}>
              Email:
            </div>
            <SimpleField name="email_address" type="email">
              <Input ref={emailAddressRef} />
            </SimpleField>
          </div>
        </SimpleForm>

        <div className={styles.buttons}>
          <SubmitButton
            isSubmitting={loading}
            submittingLabel="Saving..."
            label="Save"
            onClick={handleClickSave}
          />
        </div>
      </div>
    </Modal>
  );
};
