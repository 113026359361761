import gql from 'graphql-tag';

export const GET_OFFER_LOG_BY_ID_QUERY = gql`
query GetOfferLogById($offerId: Int!) {
    offerLogs:OfferLog(offerId:$offerId){
        advertiserId,
        createdDate,
        id,
        offerId,
        updatePayload {
            offerType
            fields {
                name
                prevValue
                newValue
            }
        },
        userInfo,
    }
}

`;
