import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const ChevronDownIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 20 20"
    >
      <path
        d="M9.99891 12.9166C9.78557 12.9166 9.57224 12.835 9.40974 12.6725L6.07641 9.33912C5.75057
          9.01329 5.75057 8.48662 6.07641 8.16079C6.40224 7.83495 6.92891 7.83495 7.25474 8.16079L10.0089
          10.915L12.7531 8.26495C13.0856 7.94579 13.6114 7.95495 13.9314 8.28579C14.2514 8.61662 14.2422 9.14495
          13.9114 9.46412L10.5781 12.6825C10.4156 12.8391 10.2072 12.9166 9.99891 12.9166Z
        "
        style={{ fill: '#8F8D91' }}
      />
      <mask
        id={id}
        maskUnits="userSpaceOnUse"
        x="5"
        y="7"
        width="10"
        height="6"
      >
        <path
          d="M9.99891 12.9166C9.78557 12.9166 9.57224 12.835 9.40974 12.6725L6.07641 9.33912C5.75057 9.01329 5.75057
            8.48662 6.07641 8.16079C6.40224 7.83495 6.92891 7.83495 7.25474 8.16079L10.0089 10.915L12.7531 8.26495C13.0856
            7.94579 13.6114 7.95495 13.9314 8.28579C14.2514 8.61662 14.2422 9.14495 13.9114 9.46412L10.5781 12.6825C10.4156
            12.8391 10.2072 12.9166 9.99891 12.9166Z
          "
          style={{ fill: 'white' }}
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

ChevronDownIcon.displayName = 'ChevronDownIcon';

export { ChevronDownIcon };
