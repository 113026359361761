import * as React from 'react';
import { format, isBefore } from 'date-fns';

import { ILineChartROIData, ILineChartROIProps, LineChartROI } from '@frontend/app/components/Charts/components/ROI/LineChart';
import { HistoricalDataGroupBy } from '@frontend/app/types/globalTypes';

export interface IHistoricalData {
  dateTime: Date;
  value: number;
}
export interface ILineChartContainerProps {
  engagements: IHistoricalData[];
  sales: IHistoricalData[];
  impressions: IHistoricalData[];
  investment: IHistoricalData[];
  content: IHistoricalData[];
  dateRange: [Date, Date];
  filter: ILineChartROIProps['filter'];
  type: HistoricalDataGroupBy;
}

function getChartType(type: HistoricalDataGroupBy): ILineChartROIProps['type'] {
  switch (type) {
    case HistoricalDataGroupBy.DAY:
      return 'daily';
    case HistoricalDataGroupBy.MONTH:
      return 'monthly';
    case HistoricalDataGroupBy.WEEK:
      return 'weekly';
    default:
      return 'monthly';
  }
}

export const formatDateTimeString = (dateString: string) => dateString.replace('T00:00:00.000Z', '').replace(/-/g, '/');

function groupHistoricalData(
  map: Map<string, ILineChartROIData>,
  historicalData: IHistoricalData[],
  field: 'content' | 'engagements' | 'impressions' | 'investment' | 'sales',
  chartStartDate: Date,
) {
  historicalData.forEach((data) => {
    const dateString = formatDateTimeString(data.dateTime.toString());
    const dataDate = new Date(dateString);
    const isBeforeChartDate = isBefore(dataDate, chartStartDate);
    const usedDate = isBeforeChartDate ? chartStartDate : dataDate;
    const numberValue = (n: number) => (Number.isFinite(n) ? n : 0);
    const key = format(usedDate, 'yyyy/MM/dd');
    const chartData = map.get(key);
    if (chartData) {
      map.set(key, { ...chartData, [field]: numberValue(data.value) });
    } else {
      map.set(key, { date: key, [field]: numberValue(data.value) });
    }
  });
}

export const LineChartContainer = ({
  engagements,
  content,
  impressions,
  investment,
  sales,
  dateRange,
  filter,
  type,
}: ILineChartContainerProps) => {
  const [startDate, endDate] = dateRange;
  const map = new Map<string, ILineChartROIData>();
  groupHistoricalData(map, engagements, 'engagements', startDate);
  groupHistoricalData(map, content, 'content', startDate);
  groupHistoricalData(map, impressions, 'impressions', startDate);
  groupHistoricalData(map, investment, 'investment', startDate);
  groupHistoricalData(map, sales, 'sales', startDate);
  const values = Array.from(map.values());
  return (
    <LineChartROI
      data={values}
      type={getChartType(type)}
      filter={filter}
      startDate={startDate}
      endDate={endDate}
    />
  );
};
