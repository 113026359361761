/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_gnmjf_157 {
  display: flex;
}

._justify-content-space-between_gnmjf_161 {
  justify-content: space-between;
}

._tabular-nums_gnmjf_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_gnmjf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._GetStarted_gnmjf_178 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 3.125rem;
}
._GetStarted_gnmjf_178 ._space_gnmjf_186 {
  max-width: 26.25rem;
  text-align: center;
}
._GetStarted_gnmjf_178 ._space_gnmjf_186 img {
  max-height: 11.875rem;
  width: auto;
}
._GetStarted_gnmjf_178 ._space_gnmjf_186 ._title_gnmjf_194 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
._GetStarted_gnmjf_178 ._space_gnmjf_186 ._text_gnmjf_198 {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #4f4f4f;
}
._GetStarted_gnmjf_178 ._buttonWrapper_gnmjf_203 {
  display: inline-flex;
  gap: 1.5rem;
}
._GetStarted_gnmjf_178 ._buttonWrapper_gnmjf_203 ._button_gnmjf_203 {
  border-radius: 0.5rem;
  width: 17rem;
}