import * as React from 'react';
import { useQuery } from '@apollo/client';

import { message } from 'antd';

import { GET_RESOURCES_QUERY } from '../queries';
import { GetResourcesQuery_resources, GetResourcesQuery } from '../queries/types/GetResourcesQuery';

export type IResource = GetResourcesQuery_resources;
const { useEffect } = React;

export function useGetResources() {
  const {
 loading, data: { resources = [] } = {}, error, refetch,
} = useQuery<GetResourcesQuery>(GET_RESOURCES_QUERY);
  useEffect(() => {
    if (error) {
      message.error('We are having trouble connecting to the server. Please try again.');
    }
  }, [error]);

  return {
    loading,
    resources,
    error,
    refetch,
  };
}
