import * as React from 'react';
import { useState } from 'react';

import { isEmpty, map } from 'lodash';

import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';

import { InvalidMembersCPS, BasicMember } from '@frontend/applications/ProductFulfillmentApp/pages/InvalidMembersCPS';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { CPSEmailComposer } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/EmailComposer/EmailComposer';
import getInitialEmailState from '../../utils/initialEmailStateShipment';

import styles from './MessageComposer.scss';

interface IProps {
  sendShippingEmail: (messageParams: SendMessageInput) => void | Promise<void>;
}

export const ShipmentEmail: React.FC<Readonly<IProps>> = ({ sendShippingEmail }: IProps) => {
  const {
    allMembers,
    getMemberShippingAddress,
  } = useProductFulfillmentContext();
  const [showInvalidMembers, setShowInvalidMembers] = useState(true);

  const validMembers = allMembers ? allMembers.filter((m) => m.email) : [];
  const invalidMembers = allMembers ? allMembers.filter((m) => !m.email) : [];
  const memberIds = validMembers.map((m) => m.id);
  if (!isEmpty(invalidMembers) && showInvalidMembers) {
    const memberInfo: BasicMember[] = map(invalidMembers, (member) => ({
      first_name: getMemberShippingAddress(member)?.first_name,
      last_name: getMemberShippingAddress(member)?.last_name,
      email: member.email,
    }));
    return (
      <InvalidMembersCPS
        memberInfo={memberInfo}
        onContinue={() => setShowInvalidMembers(false)}
        canContinue={!isEmpty(validMembers)}
        emailSubject="shipment emails"
        requiredFields={['email']}
      />
    );
  }
  return (
    <>
      <div className={styles.MessageComposer}>
        <CPSEmailComposer
          className={styles.EmailComposer}
          memberIds={memberIds}
          customSend={sendShippingEmail}
          getInitialState={getInitialEmailState}
        />
      </div>
    </>
  );
};
