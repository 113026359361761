import * as React from 'react';
import { first, isEmpty } from 'lodash';

import { OFFER_SOURCE, OFFER_STATUS } from '@affiliates/types/globalTypes';
import { RawOfferSummaryCard } from './RawOfferSummaryCard';
import { IOfferDetailsProps } from './types';

import styles from '../OfferDetails/OfferDetails.scss';

import { GetOfferById_offer_payouts } from '../../queries/types/GetOfferById';

interface IPayout extends GetOfferById_offer_payouts {
  isDefault: boolean;
}

export const OfferDetailsSummaryCard: React.FC<Readonly<IOfferDetailsProps>> = (props) => {
  switch (props.source) {
    case OFFER_SOURCE.SHOPIFY:
      if (isEmpty(props.offer.promos)) {
        throw new Error('Offer promos should not be empty.');
      }
      const promo = first(props.offer.promos);
      return (
        <RawOfferSummaryCard
          className={styles.detailCard}
          description={props.offer.description}
          expired={props.offer.expired}
          flatPayout={promo.flatPayout}
          imageUrl={props.offer.imageUrl}
          offerFormVariant={false}
          onClickEdit={props.missingShopifyCredentials ? undefined : props.onClickEdit}
          payoutType={promo.payoutType}
          percentPayout={promo.percentPayout}
          priceRuleAmount={promo.priceRuleAmount || 0}
          priceRuleType={promo.priceRuleType}
          status={props.missingShopifyCredentials ? OFFER_STATUS.PAUSED : promo.status}
          source={OFFER_SOURCE.SHOPIFY}
          title={props.offer.name}
          startDate={(promo.startDate as unknown) as Date}
          endDate={(promo.endDate as unknown) as Date}
          migrateToGraphQL={props.migrateToGraphQL}
          isNewFlow={props.isNewFlow}
          payoutOptions={props.offer.payouts as IPayout[]}
          isOfferArchived={props.isOfferArchived}
        />
      );
    case OFFER_SOURCE.TUNE:
      if (isEmpty(props.offer.links)) {
        throw new Error('Offer links should not be empty.');
      }
      const link = first(props.offer.links);
      return (
        <RawOfferSummaryCard
          className={styles.detailCard}
          conversionTrackingType={link.conversionTrackingType}
          conversionType={link.conversionType}
          description={props.offer.description}
          expirationDate={props.offer.expirationDate}
          expired={props.offer.expired}
          flatPayout={link.flatPayout}
          imageUrl={props.offer.imageUrl}
          offerFormVariant={false}
          onClickEdit={props.onClickEdit}
          payoutType={link.payoutType}
          percentPayout={link.percentPayout}
          pixelCode={link.pixelCode}
          postbackUrl={link.postbackUrl}
          source={OFFER_SOURCE.TUNE}
          status={link.status}
          title={props.offer.name}
          url={link.url}
          migrateToGraphQL={props.migrateToGraphQL}
          isNewFlow={props.isNewFlow}
          payoutOptions={props.offer.payouts as IPayout[]}
          isOfferArchived={props.isOfferArchived}
        />
      );
  }
};
