import {
  IState,
  TAction,
} from '../../types/state';
import { getInitialState } from './getInitialState';
import { loadInitialState } from './loadInitialState';
import { nextStep } from './nextStepAction';
import { prevStep } from './prevStepAction';
import { toggleSaving } from './toggleSavingAction';
import { updateShowHideNewPrice } from './actions/CollaborationDetails/updateShowHideNewPrice';
import { addPostType } from './actions/ContentRequirements/addPostType';
import { removePostType } from './actions/ContentRequirements/removePostType';
import { updateNewPrice } from './actions/CollaborationDetails/updateNewPrice';
import { updateShowHidePaymentOption } from './actions/CollaborationDetails/updateShowHidePaymentOption';
import { updateDescription } from './actions/CollaborationDetails/updatePaymentOptionDescription';
import {
  deselectMembers,
  selectMembers,
} from './actions/ReviewTable/selectMembers';
import { updateMemberValue } from './actions/ReviewTable/updateMemberValue';
import { addContentGuidelineInstruction } from './actions/CollaborationDetails/addContentGuidelineInstruction';
import { addContentGuidelines } from './actions/ContentRequirements/addContentGuidelines';
import { toggleReviewBeforeSubmission } from './actions/CollaborationDetails/toggleReviewBeforeSubmission';
import { toggleReviewWithinThreeDays } from './actions/CollaborationDetails/toggleReviewWithinThreeDays';
import { addAttachment } from './actions/CollaborationDetails/addAttachment';
import { removeAttachment } from './actions/CollaborationDetails/removeAttachment';
import { updateDueDate } from './actions/CollaborationDetails/updateDueDate';
import { sortGuideline } from './actions/CollaborationDetails/sortGuideline';
import { updateEmailSubject } from './actions/MessageComposer/updateEmailSubject';
import { updateEmailContent } from './actions/MessageComposer/updateEmailContent';
import { updateEmailAuthor } from './actions/MessageComposer/updateEmailAuthor';
import { updateContentRight } from './actions/CollaborationDetails/updateContentUsageRights';
import { toggleDisableCreatorEdits } from './actions/CollaborationDetails/toggleDisableCreatorEdits';
import { toggleInfluencerWhiteList } from './actions/CollaborationDetails/toggleInfluencerWhitelist';
import { updateInfluencerWhitelistStartDate } from './actions/CollaborationDetails/updateInfluencerWhitelistStartDate';
import { updateInfluencerWhitelistEndDate } from './actions/CollaborationDetails/updateInfluencerWhitelistEndDate';
import { toggleInfluencerBrandedContent } from './actions/CollaborationDetails/toggleInfluencerBrandedContent';
import { toggleInstagramInsights } from './actions/CollaborationDetails/toggleInstagramInsights';
import { toggleBrief } from './actions/CollaborationDetails/toggleBrief';
import { updateBriefUrl } from './actions/CollaborationDetails/updateBriefUrl';
import { toggleSpecialPaymentTerms } from './actions/CollaborationDetails/toggleSpecialPaymentTerms';
import { updateSpecialPaymentTerms } from './actions/CollaborationDetails/updateSpecialPaymentTerms';
import { updateEmailAttachments } from './actions/MessageComposer/updateEmailAttachments';
import { updateContentGuidelineTitle } from './actions/CollaborationDetails/updateContentGuidelineTitle';
import { toggleRequiresBrandedContentTag } from './actions/CollaborationDetails/toggleRequiresBrandedContentTag';
import { updateAdsPermissions } from './actions/CollaborationDetails/updateAdsPermissions';

// we deliberately want typescript to handle the fact we do not have a default
// case through its typechecking of `action.type`
/* eslint-disable default-case */
/* eslint-disable consistent-return */
export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case 'NEXT STEP':
      return nextStep(state, action);
    case 'PREV STEP':
      return prevStep(state, action);
    case 'TOGGLE SAVING':
      return toggleSaving(state, action);
    case 'UPDATE SHOW HIDE NEW PRICE':
      return updateShowHideNewPrice(state, action);
    case 'UPDATE NEW PRICE':
      return updateNewPrice(state, action);
    case 'UPDATE SHOW HIDE PAYMENT OPTION':
      return updateShowHidePaymentOption(state, action);
    case 'UPDATE PAYMENT OPTION DESCRIPTION':
      return updateDescription(state, action);
    case 'ADD POST TYPE':
      return addPostType(state, action);
    case 'REMOVE POST TYPE':
      return removePostType(state, action);
    case 'ADD CONTENT GUIDELINE INSTRUCTION':
      return addContentGuidelineInstruction(state, action);
    case 'ADD CONTENT GUIDELINES':
      return addContentGuidelines(state);
    case 'TOGGLE REVIEW BEFORE SUBMISSION':
      return toggleReviewBeforeSubmission(state, action);
    case 'TOGGLE REVIEW WITHIN THREE DAYS OF CREATOR SUBMISSION':
      return toggleReviewWithinThreeDays(state, action);
    case 'ADD ATTACHMENT':
      return addAttachment(state, action);
    case 'REMOVE ATTACHMENT':
      return removeAttachment(state, action);
    case 'UPDATE DUE DATE':
      return updateDueDate(state, action);
    case 'SORT GUIDELINE':
      return sortGuideline(state, action);
    case 'RESET STATE':
      return getInitialState(state.featureFlags);
    case 'DESELECT MEMBERS':
      return deselectMembers(state, action);
    case 'SELECT MEMBERS':
      return selectMembers(state, action);
    case 'UPDATE MEMBER VALUE':
      return updateMemberValue(state, action);
    case 'LOAD INITIAL STATE':
      return loadInitialState(state, action);
    case 'UPDATE EMAIL SUBJECT':
      return updateEmailSubject(state, action);
    case 'UPDATE EMAIL CONTENT':
      return updateEmailContent(state, action);
    case 'UPDATE EMAIL AUTHOR':
      return updateEmailAuthor(state, action);
    case 'UPDATE EMAIL ATTACHMENTS':
      return updateEmailAttachments(state, action);
    case 'UPDATE CONTENT RIGHT':
      return updateContentRight(state, action);
    case 'TOGGLE DISABLE CREATOR EDITS':
      return toggleDisableCreatorEdits(state, action);
    case 'TOGGLE REQUIRES BRANDED CONTENT TAG':
      return toggleRequiresBrandedContentTag(state, action);
    case 'TOGGLE INFLUENCER WHITELIST':
      return toggleInfluencerWhiteList(state, action);
    case 'UPDATE INFLUENCER WHITELIST START DATE ACCESS':
      return updateInfluencerWhitelistStartDate(state, action);
    case 'UPDATE INFLUENCER WHITELIST END DATE ACCESS':
      return updateInfluencerWhitelistEndDate(state, action);
    case 'TOGGLE INFLUENCER BRANDED CONTENT':
      return toggleInfluencerBrandedContent(state, action);
    case 'TOGGLE INSTAGRAM INSIGHTS':
      return toggleInstagramInsights(state, action);
    case 'TOGGLE BRIEF':
      return toggleBrief(state, action);
    case 'UPDATE BRIEF URL':
      return updateBriefUrl(state, action);
    case 'TOGGLE SPECIAL PAYMENT TERMS':
      return toggleSpecialPaymentTerms(state, action);
    case 'UPDATE SPECIAL PAYMENT TERMS':
      return updateSpecialPaymentTerms(state, action);
    case 'UPDATE CONTENT GUIDELINE TITLE':
      return updateContentGuidelineTitle(state, action);
    case 'UPDATE ADS PERMISSIONS':
      return updateAdsPermissions(state, action);
  }
};
