._ProjectConfigSetupContainer_mtr6x_1 {
  display: flex;
  flex-direction: column;
}
._ProjectConfigSetupContainer_mtr6x_1 ._content_mtr6x_5 {
  display: flex;
  flex-direction: row;
}
._ProjectConfigSetupContainer_mtr6x_1 ._content_mtr6x_5 ._setup_mtr6x_9 {
  display: flex;
  flex-grow: 2;
}
._ProjectConfigSetupContainer_mtr6x_1 ._content_mtr6x_5 ._preview_mtr6x_13 {
  display: flex;
  flex-grow: 1;
}