import { useMutation, MutationHookOptions } from '@apollo/client';

import { DELETE_MEMBER_BY_ID_ASYNC } from '../queries';
import {
  DeleteMemberMutationAsync,
  DeleteMemberMutationAsyncVariables,
} from '../queries/types/DeleteMemberMutationAsync';

type IOptions = MutationHookOptions<DeleteMemberMutationAsync, DeleteMemberMutationAsyncVariables>;

export const useDeleteMemberByIdAsync = (options: IOptions = {}) => useMutation<DeleteMemberMutationAsync, DeleteMemberMutationAsyncVariables>(
    DELETE_MEMBER_BY_ID_ASYNC,
    options,
  );
