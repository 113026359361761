import gql from 'graphql-tag';

export const MEMBER_FIELD_SCHEMA_FRAGMENT = gql`
  fragment MemberFieldSchemaFragment on MemberFieldSchema {
    id
    name
    type
    applicationId
    isDefault
    choices
    metaData
  }
`;
