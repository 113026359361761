type IActionTypes =
  | '@creatorList/SET_SOCIAL_ACCOUNTS'
  | '@creatorList/SET_FAVORITE_LISTS'
  | '@creatorList/ADD_FAVORITE_LIST'
  | '@creatorList/UPDATE_FAVORITE_LIST'
  | '@creatorList/REMOVE_FAVORITE_LIST'
  | '@creatorList/SET_IS_FETCHING_FAVORITE_LISTS'
  | '@creatorList/SET_IS_EDITING_FAVORITE_LIST'
  | '@creatorList/INVITED_TO_CAMPAIGN';

import { ICampaign } from '@components';
import { IOrganization } from '@components';
import { IFavoriteList } from '@components';
import { ISocialAccount } from '@components';

export interface ILoadingStatus {
  // whether should wait until all the social accounts are loaded.
  // waitUntilLoaded?: boolean;
  isLoading: boolean;
  showProgress?: boolean;
  total?: number;
}

/**
 * Action.
 */
export interface ICreatorListAction {
  type: IActionTypes;
  payload?: {
    id?: number;
    accountIds?: number[];
    socialAccounts?: ISocialAccount[];
    pageCount?: number;
    currentPage?: number;
    favoriteList?: IFavoriteList;
    favoriteLists?: IFavoriteList[];
    isFetchingFavoriteLists?: boolean;
    isEditingFavoriteList?: boolean;
    isInvitingToCampaign?: boolean;
  };
}

/* States */
export interface ICreatorList {
  // brand id and campaign id
  brandId: string | number;
  campaign: ICampaign;
  org: IOrganization;
  isQa: boolean;

  // endpoints
  apiEndpoint: string;

  // socialAccounts and favorite lists
  socialAccounts: ISocialAccount[];
  favoriteLists: IFavoriteList[];
  allowFavorite: boolean;

  // loading flags
  isFetchingFavoriteLists: boolean;
  isEditingFavoriteList: boolean;
}

/**
 * Initial state.
 */
export const initialState: ICreatorList = {
  allowFavorite: false,
  brandId: null,
  campaign: null,
  org: null,
  isQa: false,

  apiEndpoint: null,

  socialAccounts: [],
  favoriteLists: [],

  isFetchingFavoriteLists: false,
  isEditingFavoriteList: false,
};
