import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const FilterIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 14">
    <path d="M1.32854 4.08934H4.35013C4.64269 5.12531 5.58274 5.8927 6.71463 5.8927C7.84653 5.8927 8.78658 5.12531 9.07914 4.08934H16.6763C17.0408 4.08934 17.3381 3.79198 17.3381 3.42747C17.3381 3.06296 17.0408 2.7656 16.6763 2.7656H9.07435C8.78178 1.73443 7.84173 0.967041 6.70984 0.967041C5.57794 0.967041 4.6379 1.73443 4.34533 2.7704H1.32375C0.964034 2.7704 0.666672 3.06776 0.666672 3.43227C0.666672 3.79678 0.964034 4.08934 1.32854 4.08934ZM6.70984 2.28599C7.34293 2.28599 7.85612 2.79918 7.85612 3.43227C7.85612 4.06536 7.34293 4.57855 6.70984 4.57855C6.07675 4.57855 5.56356 4.06536 5.56356 3.43227C5.56356 2.79918 6.07675 2.28599 6.70984 2.28599Z" />
    <path d="M16.6763 10.3004H14.2254C13.9329 9.26446 12.9928 8.49707 11.8609 8.49707C10.729 8.49707 9.78898 9.26446 9.49641 10.3004H1.32854C0.964034 10.3004 0.666672 10.593 0.666672 10.9575C0.666672 11.322 0.964034 11.6194 1.32854 11.6194H9.49641C9.78898 12.6553 10.729 13.4227 11.8609 13.4227C12.9928 13.4227 13.9329 12.6553 14.2254 11.6194H16.6763C17.0408 11.6194 17.3381 11.322 17.3381 10.9575C17.3381 10.593 17.036 10.3004 16.6763 10.3004ZM11.8609 12.1038C11.2278 12.1038 10.7146 11.5906 10.7146 10.9575C10.7146 10.3244 11.2278 9.81122 11.8609 9.81122C12.494 9.81122 13.0072 10.3244 13.0072 10.9575C13.0072 11.5906 12.4892 12.1038 11.8609 12.1038Z" />
  </SvgIcon>
));

FilterIcon.displayName = 'FilterIcon';
