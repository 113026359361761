/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_j82op_157 {
  display: flex;
}

._justify-content-space-between_j82op_161 {
  justify-content: space-between;
}

._tabular-nums_j82op_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_j82op_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PopoverContent_j82op_178 {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 25rem;
  min-width: 25rem;
  height: 25rem;
  margin: -0.75rem -1rem;
  color: #1a1818;
  font-size: 0.875rem;
  line-height: 0.875rem;
  background-color: #fdfdfd;
  border-radius: 0.375rem;
}
._PopoverContent_j82op_178 ._title_j82op_192 {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1rem;
}
._PopoverContent_j82op_178 ._item_j82op_197 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 2.75rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 1, 1);
  will-change: color, background-color;
  padding: 0 1rem;
  /* Add a class to disable hover when isKeyboardNavigation is true */
}
._PopoverContent_j82op_178 ._item_j82op_197 :not(._keyboard-navigation_j82op_210):hover {
  background-color: #eff5f9;
  color: #3996e0;
}
._PopoverContent_j82op_178 ._item_j82op_197 ._keyboard-navigation_j82op_210 {
  pointer-events: none;
}
._PopoverContent_j82op_178 ._item_j82op_197 ._error_j82op_217 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  min-width: 6.25rem;
  margin-left: auto;
  color: #f1515f;
}
._PopoverContent_j82op_178 ._item_j82op_197 ._error_j82op_217 ._icon_j82op_226 {
  margin-right: 0.375rem;
}
._PopoverContent_j82op_178 ._focused_j82op_229 {
  background-color: #eff5f9;
  color: #3996e0;
}
._PopoverContent_j82op_178 ._StickytTop_j82op_233 {
  position: sticky;
  top: 0px;
  background-color: #fdfdfd;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}
._PopoverContent_j82op_178 ._empty_j82op_243 {
  display: flex;
  justify-content: center;
  height: 100%;
}

._searchInput_j82op_249 {
  width: 100%;
}

._flexColumn_j82op_253 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 25rem;
  max-height: 15.625rem;
}
._flexColumn_j82op_253 ._noVar_j82op_261 {
  font-size: 12px;
  color: #1F1F21;
  font-weight: 600;
}
._flexColumn_j82op_253 ._textEmptyStat_j82op_266 {
  font-size: 12px;
  color: #8C8C8C;
  font-weight: 400;
}

._NoEvent_j82op_272 {
  pointer-events: none;
}
._NoEvent_j82op_272 :hover {
  background-color: transparent;
}

.ant-popover {
  pointer-events: all !important;
}