import React from 'react';
import classNames from 'classnames';
import LegendOption from './LegendOption';

export type LegendGroupProps = {
  options: { label: string; color: string; value?: string | number }[];
  direction?: 'horizontal' | 'vertical';
  className?: string;
  optionShape?: 'circle' | 'square';
  optionDirection?: 'horizontal' | 'vertical';
};

const LegendGroup: React.FC<LegendGroupProps> = ({
 options, direction = 'horizontal', className, optionShape = 'circle', optionDirection = 'horizontal',
}) => {
  const baseClass = classNames(
    'flex',
    direction === 'vertical' ? 'flex-col space-y-2' : 'flex-wrap items-center gap-4',
    className,
  );

  return (
    <div className={baseClass}>
      {options.map((option, index) => (
        <LegendOption key={index} label={option.label} color={option.color} value={option.value} shape={optionShape} direction={optionDirection} />
      ))}
    </div>
  );
};
export default LegendGroup;
