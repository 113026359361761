export const useBulkPaymentComputation = (amountToPay: number, availableBalance: number, requireHandlingFee?: boolean) => {
  const insufficientBalance = availableBalance < amountToPay;
  const amountToCharge = insufficientBalance ? Number((amountToPay - availableBalance)) : 0;
  const cardTransactionFee = 0.03 * Number(amountToCharge);
  const handlingFee = 0.02 * Number(amountToCharge);
  const totalAmountToCharge = Number(amountToCharge) + Number(cardTransactionFee) + (requireHandlingFee ? Number(handlingFee) : 0);
  const totalAmountToPay = Number(amountToPay) + Number(cardTransactionFee) + (requireHandlingFee ? Number(handlingFee) : 0);
  const remainingBalance = insufficientBalance ? 0 : Number((availableBalance - amountToPay));
  const amountToDeduct = amountToPay < availableBalance ? amountToPay : availableBalance;

  return {
    insufficientBalance,
    amountToCharge,
    cardTransactionFee,
    totalAmountToCharge,
    totalAmountToPay,
    remainingBalance,
    amountToDeduct,
    handlingFee,
  };
};
