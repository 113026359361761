/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ShareModal_1hmtg_32 ._modalContent_1hmtg_32 ._linkSection_1hmtg_32 {
  margin-top: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid #dadcde;
}
._ShareModal_1hmtg_32 ._modalContent_1hmtg_32 ._text_1hmtg_37 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #1a1818;
  font-size: 16px;
  font-weight: bold;
}
._ShareModal_1hmtg_32 ._button_1hmtg_44 {
  width: 120px;
}
._ShareModal_1hmtg_32 ._button_1hmtg_44._left_1hmtg_47 {
  margin-right: 20px;
}