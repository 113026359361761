/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._wrapper_gjdrw_32 {
  border-bottom: 1px solid var(--gray-5);
  padding: 12px 0;
}
._wrapper_gjdrw_32 ._title_gjdrw_36 {
  display: flex;
  align-items: center;
}
._wrapper_gjdrw_32 ._title_gjdrw_36 ._label_gjdrw_40 {
  font-size: 14px;
  margin-right: 8px;
  color: var(--grey-100);
}
._wrapper_gjdrw_32 ._title_gjdrw_36 ._icon_gjdrw_45 {
  font-size: 12px;
  line-height: 1;
  color: var(--grey-100);
}
._wrapper_gjdrw_32 ._resourceRow_gjdrw_50 {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
._wrapper_gjdrw_32 ._resourceRow_gjdrw_50 ._resourceIcon_gjdrw_55 {
  font-size: 16px;
  line-height: 1;
  color: var(--grey-100);
  width: 16px;
  margin-right: 12px;
}
._wrapper_gjdrw_32 ._resourceRow_gjdrw_50 ._invalidResource_gjdrw_62 {
  background-color: #fffbe6;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  display: inline-block;
  cursor: pointer;
}
._wrapper_gjdrw_32 ._resourceRow_gjdrw_50 ._invalidResource_gjdrw_62 ._invalidResourceLabel_gjdrw_71 {
  color: #d48806;
}
._wrapper_gjdrw_32 ._resourceRow_gjdrw_50 ._resourceContent_gjdrw_74 {
  flex: 1;
}
._wrapper_gjdrw_32 ._resourceRow_gjdrw_50 ._resourceContent_gjdrw_74 ._select_gjdrw_77 {
  width: 100%;
}
._wrapper_gjdrw_32 ._resourceRow_gjdrw_50 ._resourceContent_gjdrw_74 ._resourceLabel_gjdrw_80 {
  color: var(--grey-100);
}