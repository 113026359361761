import * as React from 'react';
import moment from 'moment';
import { find } from 'lodash';

import { useGetResources } from '@frontend/app/hooks';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { SourcingGroupId } from '@frontend/app/constants/sourcingGroups';

const { useMemo } = React;

const SourcingGroups = {
 [SourcingGroupId.SHOPIFY]: ResourceType.SHOPIFY,
 [SourcingGroupId.WOOCOMMERCE]: ResourceType.WOOCOMMERCE,
};

export const useResourceLastTimeSynced = (sourcingGroupId: string) => {
  const {
    resources,
  } = useGetResources();

  return useMemo(() => {
    const resource = find(resources, (res) => (res.type === SourcingGroups[sourcingGroupId]));
    const timestamp = resource?.metadata?.lastSyncedTimeMs;

    if (timestamp) {
      const momentObject = moment(timestamp);
      const isToday = moment().isSame(momentObject, 'day');

      if (isToday) {
        return momentObject.format('hh:mm a');
      } else {
        return momentObject.format('MMM DD hh:mm a');
      }
    }

    return '';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);
};
