const isLocal = () => process.env.PLATFORM === 'localhost';

const isDevelopment = () => process.env.PLATFORM === 'development';

const isTest = () => process.env.PLATFORM === 'test';

const isStaging = () => process.env.PLATFORM === 'staging';

const isProduction = () => process.env.PLATFORM === 'production';

const isDynamic = () => process.env.PLATFORM === 'development' && !!process.env.DYNAMIC_PREFIX;

const isOneOf = (...platforms: string[]) => platforms.includes(process.env.PLATFORM);

export const Environment = {
  isLocal,
  isDevelopment,
  isStaging,
  isProduction,
  isOneOf,
  isDynamic,
  isTest,
}
