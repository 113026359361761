import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  GET_CURRENT_USAGE,
  GET_ENTITY_USAGE,
  GET_FEATRUE_GATE,
} from '../queries';
import {
  GetCurrentUsageQuery,
} from '../queries/types/GetCurrentUsageQuery';
import {
  GetEntityUsageQuery,
  GetEntityUsageQueryVariables,
} from '../queries/types/GetEntityUsageQuery';
import { GetFeatureGateQuery, GetFeatureGateQueryVariables } from '../queries/types/GetFeatureGateQuery';

type IEntityUsageQueryOption = QueryHookOptions<GetEntityUsageQuery, GetEntityUsageQueryVariables>;
type IFeatureGateQueryOption = QueryHookOptions<GetFeatureGateQuery, GetFeatureGateQueryVariables>;

export const useGetCurrentUsageQuery = () => (
  useQuery<GetCurrentUsageQuery>(GET_CURRENT_USAGE)
);

export const useGetEntityUsageQuery = (options: IEntityUsageQueryOption = {}) => (
  useQuery<GetEntityUsageQuery, GetEntityUsageQueryVariables>(GET_ENTITY_USAGE, {
    ...options,
  })
);

export const useGetFeatureGateQuery = (options: IFeatureGateQueryOption = {}) => (
  useQuery<GetFeatureGateQuery, GetFeatureGateQueryVariables>(GET_FEATRUE_GATE, {
    ...options,
  })
);
