import * as React from 'react';
import cx from 'classnames';
import {
 findIndex, isArray, map, size,
} from 'lodash';

import {
  ArrowDownFilledIcon,
  CheckCircleIcon,
  EnvelopeIcon,
  GmailIcon,
  OutlookIcon,
} from '@components';
import { Popover } from '@components';
import { useElementResize } from '@frontend/utils';

import { EmailIcon } from './EmailIcon';

import styles from './EmailAddressPicker.scss';

const {
 useMemo, useCallback, useRef, useState, useEffect,
} = React;

interface IEmailAddress {
  email: string;
  type: string;
}

interface IProps {
  addresses: IEmailAddress[];
  selectedEmail?: string;
  onSelectEmail?(email: string);
  onVisibleChange?(visible: boolean);
  onClickAddEmail?(type: 'gmail' | 'outlook');
  showAddEmail?: boolean;
  label?: string | JSX.Element;
  className?: string;
}

export const EmailAddressPicker: React.FC<IProps> = React.memo((props) => {
  const {
    addresses,
    onSelectEmail,
    onClickAddEmail,
    showAddEmail,
    selectedEmail,
    onVisibleChange,
  } = props;

  const mountRef = useRef<HTMLDivElement>();

  const mountRect = useElementResize(mountRef);

  const [showOptions, setShowOptions] = useState<boolean>(false);

  useEffect(() => {
    onVisibleChange && onVisibleChange(showOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  const toggleShowOptions = useCallback(() => {
    setShowOptions((s) => !s);
  }, [setShowOptions]);

  const selectedIndex = useMemo(() => findIndex(addresses, { email: selectedEmail }), [addresses, selectedEmail]);

  const selectedAddress = isArray(addresses) ? addresses[selectedIndex] : null;

  const handleSelectEmail = useCallback((email: string) => {
    toggleShowOptions();
    onSelectEmail && onSelectEmail(email);
  }, [onSelectEmail, toggleShowOptions]);

  const handleClickAddEmail = useCallback((type: 'gmail' | 'outlook') => {
    toggleShowOptions();
    onClickAddEmail && onClickAddEmail(type);
  }, [toggleShowOptions, onClickAddEmail]);

  const options = useMemo(() => {
    const n = size(addresses);
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <div className={(styles as any).options}>
        {map(addresses, (address, index) => (
          <div
            key={address.email}
            className={cx(styles.option, { [styles.last]: n === index + 1 })}
            onClick={() => handleSelectEmail(address.email)}
          >
            <EmailIcon type={address.type} className={styles.icon} size={24} />
            <div className={styles.email}>{address.email}</div>
            {index === selectedIndex && (
              <div className={styles.checkmark}>
                <CheckCircleIcon size={22} />
              </div>
            )}
          </div>
        ))}
        {showAddEmail && (
          <div className={styles.add}>
            <span>Add email...</span>
            <div className={styles.icon} onClick={() => handleClickAddEmail('gmail')}>
              <GmailIcon />
            </div>
            <div className={styles.icon} onClick={() => handleClickAddEmail('outlook')}>
              <OutlookIcon />
            </div>
          </div>
        )}
      </div>
    );
  }, [addresses, selectedIndex, showAddEmail, handleClickAddEmail, handleSelectEmail]);

  return (
    <div
      className={cx(styles.EmailAddressPicker, props.className, {
        [styles.showOptions]: showOptions,
      })}
    >
      <div ref={mountRef} className={styles.button} onClick={toggleShowOptions}>
        <div className={styles.content}>
          {selectedAddress
            ? (
              <EmailIcon
                type={selectedAddress.type}
                className={styles.icon}
                size={24}
              />
            )
            : (
              <EnvelopeIcon
                className={cx(styles.icon, styles.noEmailIcon)}
                size={24}
              />
            )}
          <div className={styles.selection}>
            <div className={styles.label}>{props.label}</div>
            <div className={styles.email}>
              {selectedAddress && selectedAddress.email}
              {!selectedAddress && isArray(addresses) && (
                <span className={styles.noEmail}>
                  Please connect an account to send
                </span>
              )}
            </div>
          </div>
          <div className={styles.arrow}>
            <ArrowDownFilledIcon size={10} />
          </div>
        </div>
      </div>
      <Popover
        className={cx(styles.Popover, {
          [styles.showOptions]: showOptions,
        })}
        mountRef={mountRef}
        anchorOrigin="start"
        showArrow={false}
        minWidth={mountRect ? mountRect.width + 2 : 320} // + 2 for border width
        show={showOptions}
        contentWrapperClassName={styles.contentWrapper}
        contentClassName={styles.content}
        onRequestClose={toggleShowOptions}
        offset={{
          x: 0,
          y: -11,
        }}
      >
        {options}
      </Popover>
    </div>
  );
});

EmailAddressPicker.defaultProps = {
  showAddEmail: false,
};
EmailAddressPicker.displayName = 'EmailAddressPicker';
