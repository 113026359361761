import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { map } from 'lodash';
import { Token } from '@stripe/stripe-js';
import {
 Alert, Button, Row, Col, Modal, Space, Typography,
} from '@revfluence/fresh';
import { LoadSpinner } from '@components';

import { AddCardForm } from '@frontend/app/components';
import { useMessagingContext } from '@frontend/hooks';

import { PaymentSource } from './PaymentSource';
import { PaymentBalance } from './PaymentBalance';
import { useFetchPaymentSourceData } from '../../useFetchPaymentSources';
import { useAddPaymentSource } from '../../useAddPaymentSource';
import { useRemovePaymentSource } from '../../useRemovePaymentSource';
import { IPaymentSources } from '../../models';
import { stripePublicAPIKey } from '../../constants';

import styles from './PaymentSources.scss';

const { useState, useCallback, useEffect } = React;
const { Title } = Typography;

interface IProps {
  title?: string;
  showAddCard?: boolean;
  modalOnly?: boolean;
  onCardAdded?: () => void;
  className?: string;
}

export const PaymentSources: React.FC<Readonly<IProps>> = React.memo((props) => {
  const [showAddCard, setShowAddCard] = useState<boolean>(false);
  const [addCardFormKey, setAddCardFormKey] = useState<string>('init');
  const [isAddingCard, setIsAddingCard] = useState<boolean>(false);
  const [paymentSources, setPaymentSources] = useState<IPaymentSources>();
  const [removingCardIds, setRemovingCardIds] = useState<Set<string>>(new Set());

  const { showErrorMessage, showSuccessMessage, showInfoMessage } = useMessagingContext();

  const {
    loading: paymentSourcesLoading,
    data: paymentSourcesData,
    error: paymentSourcesError,
  } = useFetchPaymentSourceData();

  const { save: addPaymentSource, error: addPaymentSourceError } = useAddPaymentSource();

  const { remove: removePaymentSource, error: removePaymentSourceError } = useRemovePaymentSource();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const openModal = useCallback(setShowAddCard.bind(this, true), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const closeModal = useCallback(setShowAddCard.bind(this, false), []);

  const handleAddCard = useCallback(() => {
    setIsAddingCard(true);
  }, []);

  const handleOnSuccess = useCallback(() => {
    setIsAddingCard(false);
    closeModal();
  }, [closeModal]);

  const handleOnError = useCallback(() => {
    setIsAddingCard(false);
  }, []);

  const handleTokenReceived = useCallback(
    async (token: Token) => {
      const sources = await addPaymentSource(token.id);
      if (sources) {
        setPaymentSources(sources);
        props.onCardAdded && props.onCardAdded();
        showSuccessMessage('Successfully added new card');
        closeModal();
        setTimeout(() => {
          // Use this to reset the form.
          setAddCardFormKey(uuidv4());
        }, 300);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addPaymentSource, props.onCardAdded, closeModal, setAddCardFormKey, addPaymentSourceError],
  );

  const handleRemoveCard = useCallback(
    async (cardId: string) => {
      setRemovingCardIds((cardIds: Set<string>) => {
        const newSet = new Set(cardIds);
        newSet.add(cardId);
        return newSet;
      });

      const sources = await removePaymentSource(cardId);
      setPaymentSources(sources);

      setRemovingCardIds((cardIds: Set<string>) => {
        const newSet = new Set(cardIds);
        newSet.delete(cardId);
        return newSet;
      });

      showInfoMessage('Successfully removed card');
    },
    [removePaymentSource, showInfoMessage],
  );

  useEffect(() => {
    if (paymentSourcesData) {
      setPaymentSources(paymentSourcesData);
    }
  }, [paymentSourcesData]);

  useEffect(() => {
    if (addPaymentSourceError) {
      closeModal();
      handleOnError();
      setTimeout(() => {
        // Use this to reset the form.
        setAddCardFormKey(uuidv4());
      }, 300);
      showErrorMessage(addPaymentSourceError.message);
    } else if (removePaymentSourceError) {
      showErrorMessage(removePaymentSourceError.message);
    }
  }, [addPaymentSourceError, removePaymentSourceError, closeModal, handleOnError, showErrorMessage]);

  return (
    <Space direction="vertical" className={props.className}>
      {paymentSourcesLoading && <LoadSpinner />}
      {!paymentSourcesLoading && paymentSourcesError && <Alert type="error">{paymentSourcesError.message}</Alert>}

      {!paymentSourcesLoading && paymentSources && !props.modalOnly && (
        <Row>
          <Col>
            <Row align="middle" justify="space-between" className={styles.PaymentSourcesTitle}>
              <Col>
                <Title level={3} noMargin>
                  {props.title}
                </Title>
              </Col>
              <Col>
                <Button onClick={openModal}>Add a Card</Button>
              </Col>
            </Row>

            {map(paymentSources.cards, (card) => (
              <PaymentSource
                key={card.id}
                card={card}
                isRemoving={removingCardIds.has(card.id)}
                onClickRemove={handleRemoveCard.bind(this, card.id)}
              />
            ))}
            <PaymentBalance
              balanceInCents={paymentSources.balance_in_cents}
              currencyCode={paymentSources.brand_currency_code}
              requireHandlingFee={!paymentSources.handling_fee_disabled}

            />
          </Col>
        </Row>
      )}

      <Modal open={showAddCard} footer={null} onCancel={closeModal}>
        <AddCardForm
          key={addCardFormKey}
          title="Add new Credit/Debit Card"
          stripePublicAPIKey={stripePublicAPIKey}
          onSubmit={handleAddCard}
          onSuccess={handleOnSuccess}
          onTokenReceived={handleTokenReceived}
          onError={handleOnError}
          submitting={isAddingCard}
        />
      </Modal>
    </Space>
  );
});

PaymentSources.defaultProps = {
  modalOnly: false,
  showAddCard: false,
};

PaymentSources.displayName = 'PaymentSources';
