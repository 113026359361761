import * as React from 'react';
import cx from 'classnames';

import { GraphContainer } from '../utils/GraphContainer';

import AreaChartInternal, { IAreaChartProps } from './AreaChart';

interface IProps extends IAreaChartProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const AreaChart: React.FunctionComponent<IProps> = React.memo((props) => {
  const { className, ...restProps } = props;

  return (
    <GraphContainer className={cx(className)}>
      <AreaChartInternal className={className} {...restProps} />
    </GraphContainer>
  );
});

AreaChart.displayName = 'AreaChart';
