/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Col,
  DatePicker,
  Row,
  Form,
  TimePicker,
} from '@revfluence/fresh';
import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import { isNull, isUndefined } from 'lodash';

import { validateStartAndTimeRange } from '@affiliates/components/MembersWizard/utils/validatePromoCodeDates';
import {
  ACTIVE_DATE_FORMAT,
  ACTIVE_TIME_FORMAT,
  TActiveDates,
} from '../types';

import styles from './MembersWizard.scss';

interface IProps {
  activeDates: TActiveDates;
  updateDates: (dateField: keyof IProps['activeDates'], value?: string) => void;
}

const getDatePickerValue = (
  activeDates: IProps['activeDates'],
  key: keyof IProps['activeDates'],
  format: string,
): any => {
  if (isUndefined(activeDates[key])) {
    return null;
  }
  return moment(activeDates[key], format);
};

export const ActiveDates: React.FC<Readonly<IProps>> = (props) => {
  const {
    activeDates,
    updateDates,
  } = props;

  const { isInValidTimeRange, isInvalidStartRange } = validateStartAndTimeRange(activeDates);

  const disableStartDates = (current: moment.Moment): boolean =>
    // Can not select days before today
    current && current < moment(startOfDay(new Date()));

  const disableEndDates = (current: moment.Moment): boolean => {
    // Can not select days before today and today
    if (isUndefined(activeDates.startDate)) {
      return current && current < moment();
    }
    const startDateAsDate = moment(activeDates.startDate, ACTIVE_DATE_FORMAT).toDate();
    return current && current <= moment(endOfDay(startDateAsDate));
  };

  let displayStartDateError = '';
  let displayStartTimeError = '';

  if (activeDates.startDate && activeDates.startTime && activeDates.endDate && activeDates.endTime) {
    displayStartDateError = isInvalidStartRange ? 'Your start date must be before or the same as your end date' : '';
    displayStartTimeError = isInValidTimeRange ? 'If your start and end dates are the same, your start time must be before your end time' : '';
  }

  return (
    <>
      <Form className={styles.activeDatesForm} layout="vertical">
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              help={displayStartDateError}
              label="Start Date"
              name="startDate"
              validateStatus="error"
            >
              <DatePicker
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                status={!isInvalidStartRange ? 'null' : 'error'}
                defaultPickerValue={moment(new Date()) as any}
                defaultValue={getDatePickerValue(activeDates, 'startDate', ACTIVE_DATE_FORMAT)}
                disabledDate={disableStartDates}
                format={ACTIVE_DATE_FORMAT}
                onChange={(value) => {
                  if (isNull(value)) {
                    updateDates('startDate');
                    return;
                  }
                  updateDates('startDate', value.format(ACTIVE_DATE_FORMAT));
                }}
                placeholder="Select or type date"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              help={displayStartTimeError}
              label="Start Time"
              name="startTime"
              validateStatus="error"
            >
              <TimePicker
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                status={!isInValidTimeRange ? 'null' : 'error'}
                className={styles.dateTimePicker}
                defaultValue={getDatePickerValue(activeDates, 'startTime', ACTIVE_TIME_FORMAT)}
                format={ACTIVE_TIME_FORMAT}
                onChange={(value) => {
                  if (isNull(value)) {
                    updateDates('startTime');
                    return;
                  }
                  updateDates('startTime', value.format(ACTIVE_TIME_FORMAT));
                }}
                placeholder="Select or type time"
                showNow={false}
                size="large"
                use12Hours
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="End Date" name="endDate">
              <DatePicker
                defaultPickerValue={moment(new Date()) as any}
                defaultValue={getDatePickerValue(activeDates, 'endDate', ACTIVE_DATE_FORMAT)}
                disabledDate={disableEndDates}
                format={ACTIVE_DATE_FORMAT}
                onChange={(value) => {
                  if (isNull(value)) {
                    updateDates('endDate');
                    return;
                  }
                  updateDates('endDate', value.format(ACTIVE_DATE_FORMAT));
                }}
                placeholder="Select or type date"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="End Time" name="endTime">
              <TimePicker
                className={styles.dateTimePicker}
                defaultValue={getDatePickerValue(activeDates, 'endTime', ACTIVE_TIME_FORMAT)}
                format={ACTIVE_TIME_FORMAT}
                onChange={(value) => {
                  if (isNull(value)) {
                    updateDates('endTime');
                    return;
                  }
                  updateDates('endTime', value.format(ACTIVE_TIME_FORMAT));
                }}
                placeholder="Select or type time"
                showNow={false}
                size="large"
                use12Hours
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
