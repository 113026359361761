import * as React from 'react';
import cx from 'classnames';

import { Typography } from 'antd';

import { IMember } from '@frontend/app/hooks';
import { useHover } from '@frontend/app/hooks/common';

const { Text } = Typography;

import styles from './ListItem.scss';

interface IProps {
  member: IMember;
  value: string;

  onExcludeMember(memberId: number): void;
  disableRemoveRecipient?: boolean;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const ListItem: React.FC<IProps> = React.memo((props) => {
  const { member, value, disableRemoveRecipient } = props;
  const [hovered, listeners] = useHover();

  return (
    <div
      className={cx(styles.ListItem, props.className)}
      {...listeners}
    >
      <div className={styles.variable}>
        <Text ellipsis className={styles.text}>
          {value}
        </Text>
      </div>
      <div className={styles.email}>{member.email}</div>
      {hovered && !disableRemoveRecipient && (
        <div
          className={styles.remove}
          onClick={() => props.onExcludeMember(member.id)}
        >
          Remove
        </div>
      )}
    </div>
  );
});

ListItem.displayName = 'EmailViewerListItem';
