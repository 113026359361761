/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_r02po_157 {
  display: flex;
}

._justify-content-space-between_r02po_161 {
  justify-content: space-between;
}

._tabular-nums_r02po_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_r02po_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FormSelect_r02po_178 {
  width: 100%;
}