import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { message } from 'antd';

import {
  CREATE_CONTENT_FIELD_MUTATION,
} from '@frontend/app/queries';
import {
  CreateContentFieldMutation,
  CreateContentFieldMutationVariables,
} from '@frontend/app/queries/types/CreateContentFieldMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<CreateContentFieldMutation, CreateContentFieldMutationVariables>;

export function useCreateContentField(options: IOptions = {}) {
  const [createContentField, { loading, error }] = useMutation<
  CreateContentFieldMutation,
  CreateContentFieldMutationVariables
  >(CREATE_CONTENT_FIELD_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully Created Content Field');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    createContentField,
    loading,
    error,
  };
}
