/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_106ej_157 {
  display: flex;
}

._justify-content-space-between_106ej_161 {
  justify-content: space-between;
}

._tabular-nums_106ej_165 {
  font-variant-numeric: tabular-nums;
}

._Loading_106ej_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_106ej_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Loading_106ej_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Loading_106ej_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Loading_106ej_169 ::-webkit-scrollbar-track, ._Loading_106ej_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Loading_106ej_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
@keyframes _loadingShimmy_106ej_1 {
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -50% 0;
  }
}
._Loading-enter_106ej_212 {
  opacity: 0;
}

._Loading-enter-active_106ej_216 {
  opacity: 1;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}

._Loading-exit_106ej_221 {
  opacity: 1;
}

._Loading-exit-active_106ej_225 {
  opacity: 0;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}

._Loading_106ej_169 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  z-index: 1;
  animation: _loadingShimmy_106ej_1 1.5s ease-in-out infinite forwards;
}
._Loading_106ej_169._light_106ej_241 {
  background: linear-gradient(to right, rgba(253, 253, 253, 0.2) 25%, rgba(253, 253, 253, 0.8) 50%, rgba(253, 253, 253, 0.2) 75%);
  background-size: 200% 100%;
}
._Loading_106ej_169._grey_106ej_245 {
  background: linear-gradient(to right, rgba(233, 232, 234, 0.4) 25%, rgba(253, 253, 253, 0.9) 50%, rgba(233, 232, 234, 0.4) 75%);
  background-size: 200% 100%;
}
._Loading_106ej_169._blue_106ej_249 {
  background: linear-gradient(to right, rgba(239, 245, 249, 0.5) 25%, rgba(57, 150, 224, 0.2) 50%, rgba(239, 245, 249, 0.5) 75%);
  background-size: 200% 100%;
}
._Loading_106ej_169:hover {
  cursor: wait;
}
._Loading_106ej_169:hover + * {
  pointer-events: none;
}