import { logger } from '../../../../common/Logger';

export enum Environment {
  LOCALHOST = 'localhost',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

const environmentSegmentWriteKey = {
  production: 'FGCwHx1ymHtt3sqwBUnbikuNcA0fsQsR',
  staging: 'Lgp2ZGi79nHlbCVYhVSOwrWTkTjMe1Y3',
  development: 'gPthGyAuIN8VEi607YLgbonhnpTZ5JkH',
};

export const getEnvironment = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname.split('.').slice(-2)[0]) {
      case 'aspireiq':
        return Environment.PRODUCTION;
      case 'aspireiq-staging':
        return Environment.STAGING;
      case 'aspireiq-dev':
        return Environment.DEVELOPMENT;
      default:
        return Environment.LOCALHOST;
    }
  }

  console.error('Unable to determine environment because window is undefined');
  // Worst case is that this happens on prod, so return that
  // as the minimum regret choice (on test/staging/local, developer can debug)
  return Environment.PRODUCTION;
};

export const getSegmentWriteKey = () => {
  const environment = getEnvironment();

  const segmentWriteKey = environmentSegmentWriteKey[environment];

  if (!segmentWriteKey) {
    logger.debug(`segment not configured for ${window.location.hostname}`);
  }

  return segmentWriteKey || '';
};
