import * as React from 'react';
import {
  DatePicker,
  Form,
} from '@revfluence/fresh';
import moment, { Moment } from 'moment';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'endDate'> {
  startDate: Moment;
}

export const OfferEndDate: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  startDate,
}) => {
  const disabledDate = (current: moment.Moment) =>
    current && current.isBefore(startDate, 'day');

const rules = [
  { required: true, message: 'Please Select Date' },
];
return (
  <>
    <Form.Item name={name} rules={rules}>
      <DatePicker
        showTime={{ format: 'hh:mm a' }}
        format="MMM D, YYYY hh:mm a"
        disabledDate={disabledDate}
        size="large"
        disabled={disabled}
      />
    </Form.Item>

  </>
);
});
OfferEndDate.displayName = 'OfferEndDate';
