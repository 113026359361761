import * as React from 'react';
import {
  HomeOutlined,
  ProjectOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  InboxOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import { useMemo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  isNumber,
  values,
  filter,
  has,
} from 'lodash';
import { IInstalledApplications, useClientFeatureEnabled } from '@frontend/app/hooks';

import {
  CONTENT_APP_ID,
  FULFILLMENT_APP_ID,
  PAYMENT_APP_ID,
  SALES_APP_ID,
  SOCIAL_DISCOVERY_APP_ID,
  SOCIAL_POST_APP_ID,
  GMAIL_APP_ID,
  OUTLOOK_APP_ID,
  CONTRACT_APP_ID,
  SHOPIFY_APP_ID,
} from '@frontend/app/constants/applicationIds';
import { useAuth } from '@frontend/context/authContext';
import { useResourceContext } from '@frontend/app/context';
import { useGetInstalledApplicationIds } from '@frontend/app/hooks';
import { useProjectsApp } from '@frontend/app/containers/Projects/hooks';
import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { IntegrationMenuItem } from '@frontend/app/components';

import { ClientFeature } from '@frontend/app/constants';
import { TopNavItem } from '../types/TopNavItem';
import { useGetEmbeddedReports } from './useGetEmbeddedReports';
import { pfaV2Routes } from '../containers/Settings/ProductFulfillment/routes';

const BUDGET_ALLOCATION_CONFIG = {
  id: 'budget',
  text: 'Budget',
  route: '/budget/dashboard',
};

const getReportingConfig = (installedApps: IInstalledApplications, isBudgetAllocationEnabled: boolean) => {
  const children = [{
    id: 'impact_dashboard',
    text: 'Impact Dashboard',
    route: '/impact_dashboard',
  },
  {
    id: 'sales_tracking',
    text: 'Sales Tracking',
    route: `/app/${SALES_APP_ID}`,
  },
  {
    id: 'social_analytics',
    text: 'Social Analytics',
    route: `/app/${SOCIAL_POST_APP_ID}`,
  },
  {
    id: 'content',
    text: 'Content',
    route: `/app/${CONTENT_APP_ID}`,
  }];
  if (isBudgetAllocationEnabled) children.push(BUDGET_ALLOCATION_CONFIG);
  if (has(installedApps, CONTRACT_APP_ID)) {
    children.push({
      id: 'contracts',
      text: 'Contracts',
      route: `/app/${CONTRACT_APP_ID}`,
    });
  }
  children.push(...[
    {
      id: 'payments',
      text: 'Payments',
      route: `/app/${PAYMENT_APP_ID}`,
    },
    {
      id: 'product_fulfillment',
      text: 'Product Fulfillment',
      route: `/app/${FULFILLMENT_APP_ID}`,
    },
    {
      id: 'influencer_whitelist',
      text: 'Ads Permissions',
      route: '/influencer_whitelist',
    }]);
  return {
    id: 'reporting',
    icon: BarChartOutlined,
    text: 'Reporting',
    children,
  };
};

const getTopNavItems = (installedApps: IInstalledApplications, isBudgetAllocationEnabled: boolean) => {
  const reportingConfig = getReportingConfig(installedApps, isBudgetAllocationEnabled);
  return [
  {
    id: 'home',
    icon: HomeOutlined,
    text: 'Home',
    route: '/home',
  },
  {
    id: 'projects',
    icon: ProjectOutlined,
    text: 'Projects',
  },
  {
    id: 'members',
    icon: TeamOutlined,
    text: 'Members',
    route: '/member_table?&switchSourcing=true',
  },
  {
    id: 'recruit',
    icon: UsergroupAddOutlined,
    text: 'Recruit',
    children: [
      {
        id: 'creator_search',
        text: 'Creator Search',
        route: `/app/${SOCIAL_DISCOVERY_APP_ID}`,
      },
      {
        id: 'social_listening',
        text: '@ Mentions',
        route:
          '/member_table?filters={"segmentId":"source_social listening","value":[]}&sourcingId=social listening&switchSourcing=true',
      },
      {
        id: 'customers',
        text: 'Customers',
        route: '/member_table?filters={"segmentId":"source_shopify","value":[]}&sourcingId=shopify&switchSourcing=true',
      },
    ],
  },
  {
    id: 'inbox',
    icon: InboxOutlined,
    text: 'Inbox',
    route: '/messages',
  },
  reportingConfig,
];
};

const sourceGroups = {
  social_listening: 'sourcingId=social listening',
  customers: 'sourcingId=shopify',
};

const isItemSelected = (item: TopNavItem, route: string) => {
  if (!item.route) {
    return false;
  }

  if (route.includes('/member_table') && item.route.includes('/member_table')) {
    return (
      (sourceGroups[item.id] && route.includes(sourceGroups[item.id]))
      || (item.id === 'members' && values(sourceGroups).every((sourceGroup) => !route.includes(sourceGroup)))
    );
  }

  return route.includes(item.route);
};

const getNavItemsWithSelectedStatus = (items: TopNavItem[], route: string, allThreadsCount?: number) =>
  items.map((item) => {
    const children = getNavItemsWithSelectedStatus(item.children || [], route);
    const isSelected = isItemSelected(item, route) || children.some((subitem) => subitem.selected);

    if (isNumber(allThreadsCount) && item.id === 'inbox') {
      item.badge = allThreadsCount;
    }

    return {
      ...item,
      children: children.length > 0 ? children : undefined,
      selected: isSelected,
    };
  });

const findItemPathById = (items: TopNavItem[], id: string) => {
  for (const item of items) {
    if (item.id === id) {
      return [item];
    } else if (item.children) {
      const subpath = findItemPathById(item.children, id);
      if (subpath.length > 0) {
        return [...subpath, item];
      }
    }
  }

  return [];
};

export const useTopNavigation = () => {
  const location = useLocation();
  const history = useHistory();
  const { logout, user } = useAuth();
  const { allThreadsCount } = useResourceContext();
  const isCreatorSearchHidden = useClientFeatureEnabled(ClientFeature.HIDE_CREATOR_SEARCH);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);
  const isBudgetAllocationEnabled = useClientFeatureEnabled(ClientFeature.BUDGET_ALLOCATION);
  const pfaV2Enabled = useClientFeatureEnabled(ClientFeature.PFA_V2);
  const installedApps = useGetInstalledApplicationIds();
  const { projects } = useProjectsApp();
  const navItems = useMemo(() => getTopNavItems(installedApps, isBudgetAllocationEnabled), [installedApps, isBudgetAllocationEnabled]);
  const isEmbeddedReportingEnabled = useClientFeatureEnabled(ClientFeature.EMBEDDED_REPORTING);
  const {
    data: { embeddedReportingItems } = {},
    loading: embeddedReportsLoading,
  } = useGetEmbeddedReports({ skip: !isEmbeddedReportingEnabled });

  const filteredNavItems = useMemo(() => {
    const items = navItems;
    if (isCreatorSearchHidden) {
      const recruitOptions: TopNavItem = navItems.find((item) => item.id === 'recruit');
      recruitOptions.children = filter(recruitOptions.children, (option: TopNavItem) => option.id !== 'creator_search');
    }
    if (tempYoutubeDemoAccountMode) {
      const reportingOptions: TopNavItem = navItems.find((item) => item.id === 'reporting');
      reportingOptions.children = filter(reportingOptions.children, (option: TopNavItem) => option.id !== 'impact_dashboard');
    }

    // If Embedded Reporting is enabled, load all the Embedded Reporting routes and add them to the navigation
    if (isEmbeddedReportingEnabled && embeddedReportingItems && !embeddedReportsLoading) {
      const reportingOptions: TopNavItem = navItems.find((item) => item.id === 'reporting');
      // Prevent duplicate routes by name
      const existingRoutes = reportingOptions.children.map((child) => child.text);
      const newEmbeddedReportingItems = embeddedReportingItems.filter((item) => !existingRoutes.includes(item.name));
      reportingOptions.children = [
        ...reportingOptions.children,
        ...newEmbeddedReportingItems.map((item) => ({
          id: item.id.toString(),
          text: item.name,
          route: `/embedded_reporting/${item.id}`,
        })),
      ];
    }
    return items;
  },
  [
    isCreatorSearchHidden,
    tempYoutubeDemoAccountMode,
    isEmbeddedReportingEnabled,
    embeddedReportingItems,
    embeddedReportsLoading,
    navItems,
  ]);

  /* Include Projects into Main Navigation */
  const navItemProjects: TopNavItem = filteredNavItems.find((item) => item.id === 'projects');
  const projectsChildren = projects?.map((item) => ({
    id: String(item.id),
    text: item.title,
    route: `/projects/${item.id}`,
    imageUrl: item.splashImageUrl,
    status: item.status,
  }));

  navItemProjects.children = useMemo(() => projectsChildren?.filter((item) => (
    !isArchiveProjectEnabled || (isArchiveProjectEnabled && item.status === ProjectStatus.Active)
  )), [
    projectsChildren,
    isArchiveProjectEnabled,
  ]);

  const userMenuItems: TopNavItem[] = [
    {
      id: 'account',
      text: 'My Account',
      route: '/settings/account',
    },
    {
      id: 'workspaces',
      text: 'Workspace Settings',
      route: `/settings/${PAYMENT_APP_ID}`,
    },
    ...(pfaV2Enabled && installedApps[SHOPIFY_APP_ID] ? [{
      id: 'product_fulfillment_settings',
      text: 'Product Fulfillment',
      route: pfaV2Routes.settings.imports,
    }] : []),
    {
      id: 'integrations',
      text: 'Integrations',
      node: <IntegrationMenuItem>Integrations</IntegrationMenuItem>,
      route: installedApps[GMAIL_APP_ID] ? `/settings/${GMAIL_APP_ID}` : `/settings/${OUTLOOK_APP_ID}`,
    },
    {
      id: 'organizations',
      text: 'Organization Settings',
      route: '/settings/conversationPrivacy',
    },
    {
      id: 'logout',
      text: 'Log Out',
    },
  ];

  const path = decodeURIComponent(location.pathname + location.search);
  const mainNavItems = getNavItemsWithSelectedStatus(filteredNavItems, path, allThreadsCount);

  const handleNavItemClick = useCallback(
    (id: string) => {
      const [item] = findItemPathById([...mainNavItems, ...userMenuItems], id);
      if (item && item.route) {
        history.push(item.route);
      }
      if (item && item.id === 'logout') {
        logout();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, mainNavItems, userMenuItems],
  );

  return useMemo(
    () => ({
      mainNavItems,
      userMenuItems,
      handleNavItemClick,
      user,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mainNavItems, handleNavItemClick, user],
  );
};
