import { ICampaign } from './campaign';
import { IDeliverable } from './deliverable';
import { ISocialAccount } from './socialAccount';

export interface IPostProjectProposal {
  target_date: string;
  accountProducts: IAccountProduct[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  address?: any;
  campaign: ICampaign;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  text?: any;
  from_buyer: boolean;
  created_ts: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public_creator_profile_json?: any;
}

interface IAccountProduct {
  account: ISocialAccount;
  products: IDeliverable[];
}
