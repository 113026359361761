import * as React from 'react';

const { useRef, useEffect } = React;

interface IAutosizingTextFieldProps extends React.HTMLProps<HTMLTextAreaElement> {
  defaultHeight?: number;
}

export const AutosizingTextField = React.forwardRef<HTMLTextAreaElement, IAutosizingTextFieldProps>(
  (props, forwardedRef: React.RefObject<HTMLTextAreaElement>) => {
    const localRef = useRef<HTMLTextAreaElement>();

    const resize = () => {
      const textarea = forwardedRef ? forwardedRef.current : localRef.current;
      textarea.style.height = '';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(resize, []);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (props.onChange) {
        props.onChange(e);
      }
      resize();
    };

    const propOverrides: React.HTMLProps<HTMLTextAreaElement> = {
      onChange: handleChange,
      style: {
        ...props.style,
        overflow: 'hidden',
        resize: 'none',
      },
    };

    const { defaultHeight, ...rest } = props;

    if (defaultHeight !== undefined) {
      propOverrides.style = {
        ...propOverrides.style,
        height: defaultHeight,
      };
    }

    return <textarea {...rest} ref={forwardedRef || localRef} {...propOverrides} />;
  },
);

AutosizingTextField.displayName = 'AutosizingTextField';
