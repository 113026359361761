import * as React from 'react';

import {
  Button, Col, Row, Space, Typography,
} from '@revfluence/fresh';

const { Title } = Typography;
import {
  Tags, Limitations, OrderRequestSetupDrawer, CatalogueConfig,
} from './components';
import { MODES } from './components/SetupNotice';

interface IProps {
  onTagChange: (tags: string[]) => void;
  tags: string[];
  priceMax: number;
  quantityMax: number;
  onPriceMaxChange: (priceMax: number) => void;
  onQuantityMaxChange: (quantityMax: number) => void;
  onSubmit: () => void;
  mode: MODES;
  visible: boolean
  setVisible: (visible: boolean) => void;
  hidePrice: boolean;
  instructionText: string;
  onInstructionTextChange: (text: string) => void;
  onHidePriceChange: (hidePrice: boolean) => void;
}

export const OrderRequestSetup: React.FC<Readonly<IProps>> = (props: IProps) => {
  const {
    onTagChange,
    tags,
    onPriceMaxChange,
    onQuantityMaxChange,
    priceMax,
    quantityMax,
    onSubmit,
    mode,
    visible,
    setVisible,
    hidePrice,
    instructionText,
    onInstructionTextChange,
    onHidePriceChange,
  } = props;
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        {(mode === MODES.EDIT ? 'Edit' : 'Set Up')}
        {' '}
        Catalog
      </Button>
      <OrderRequestSetupDrawer title="Edit Catalog for Order Requests" onClose={onClose} visible={visible} onSubmit={onSubmit}>
        <Title level={4}>Catalogue Configs</Title>
        <Row>
          <Col xs={24} sm={18}>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Tags tags={tags} onTagChange={onTagChange} />
              <Limitations
                priceMax={priceMax}
                quantityMax={quantityMax}
                onQuantityMaxChange={onQuantityMaxChange}
                onPriceMaxChange={onPriceMaxChange}
                hidePrice={hidePrice}
                onHidePriceChange={onHidePriceChange}
              />
              <CatalogueConfig
                instructionText={instructionText}
                onInstructionTextChange={onInstructionTextChange}
              />
            </Space>
          </Col>
        </Row>
      </OrderRequestSetupDrawer>
    </>
  );
};
