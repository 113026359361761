import gql from 'graphql-tag';

export const GET_MULTIPLE_SHOPIFY_AFFLIATES_STATS_QUERY = gql`
  query GetMultipleShopifyAffiliateStats($affiliateOfferPromoId: Int!, $endDate: DateTime,$startDate: DateTime) {
    affliateStats: multipleShopifyAffiliateStats(affiliateOfferPromoId: $affiliateOfferPromoId,endDate: $endDate,startDate: $startDate) {
      avgSale
      avgSaleBase
      storeName
      clientShopifyConnectionId
      conversions
      currency
      payoutEarned
      payoutEarnedBase
      sales
      salesBase
    } 
  }
`;
