import 'css-chunk:src/components/widgets/TimePicker/TimePicker.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "TimePicker": "_TimePicker_1ckzq_32",
  "icon": "_icon_1ckzq_35",
  "timeInput": "_timeInput_1ckzq_38",
  "timeSelected": "_timeSelected_1ckzq_53",
  "close": "_close_1ckzq_67",
  "hasError": "_hasError_1ckzq_70",
  "errorMessage": "_errorMessage_1ckzq_74"
};