import gql from 'graphql-tag';

export const GET_REPORTING = gql`
query GetReporting(
  $startDate: DateTime!,
  $endDate: DateTime!,
  $percentDiffStartDate: DateTime,
  $percentDiffEndDate: DateTime,
  $compareStartDate: DateTime,
  $compareEndDate: DateTime,
  $groupBy:  HistoricalDataGroupBy!,
  $programIds: [Int!],
) {
    reporting {
      impact {
        content {
          historicalData(
            groupBy: $groupBy,
            startDate: $startDate,
            endDate: $endDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
        impressions {
          historicalData(
            groupBy: $groupBy,
            startDate: $startDate,
            endDate: $endDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
        engagements {
          historicalData(
            groupBy: $groupBy,
            startDate: $startDate,
            endDate: $endDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
        sales {
          historicalData(
            groupBy: $groupBy,
            startDate: $startDate,
            endDate: $endDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
        investment {
          historicalData(
            groupBy: $groupBy,
            startDate: $startDate,
            endDate: $endDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
        total {
          historicalData(
            groupBy: $groupBy,
            startDate: $startDate,
            endDate: $endDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
        roi {
          historicalData(
            groupBy: $groupBy,
            startDate: $startDate,
            endDate: $endDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
      }
      content {
        image {
          broad {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          broadLimited {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          custom {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          none {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
        }
        longFormVideo {
          broad {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          broadLimited {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          custom {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          none {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
        }
        shortFormVideo {
          broad {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          broadLimited {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          custom {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
          none {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
          }
        }
        totalCount {
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
      }
      social {
        instagramPost {
          likes {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          comments {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          views {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          reach {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        instagramReel {
          likes {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          comments {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          views {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          reach {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        instagramVideo {
          likes {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          comments {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          views {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          reach {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        instagramStory {
          likes {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          comments {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          impressions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          estimatedImpressions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        tiktok {
          likes {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          comments {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          shares {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          views {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        youtube {
          likes {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          comments {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          views {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        pinterest {
          favorites {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          impressions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          clicks {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          closeups {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        twitter {
          likes {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          comments {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          shares {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          reach {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            dollarMultiplier
            dollarValue(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        totals {
          cpe {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          cpm {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          tmv {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          engagements {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          impressions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          postCount {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          creatorCount {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          reach {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          views {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
        }
      }
      salesTracking {
        promoTotals {
          sales {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          conversions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        linkTotals {
          sales {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
          conversions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          }
        }
        totals {
          conversions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
        }
      }
      investment {
        payments {
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          historicalData(startDate: $startDate, endDate: $endDate, programIds: $programIds, groupBy: $groupBy) {
            value
            dateTime
          }
        }
        productCost {
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          historicalData(startDate: $startDate, endDate: $endDate, programIds: $programIds, groupBy: $groupBy) {
            value
            dateTime
          }
        }
      }
    }
  }
`;
