import { useMutation, MutationHookOptions } from '@apollo/client';

import { REJECT_CONTENT_REVIEW_MUTATION } from '@frontend/app/queries';

import {
  RejectContentReviewMutation,
  RejectContentReviewMutationVariables,
} from '@frontend/app/queries/types/RejectContentReviewMutation';

type IOptions = MutationHookOptions<RejectContentReviewMutation, RejectContentReviewMutationVariables>;

export function useRejectContentReview(options: IOptions = {}) {
  const [rejectReview, { loading, error }] = useMutation<
    RejectContentReviewMutation,
    RejectContentReviewMutationVariables
  >(REJECT_CONTENT_REVIEW_MUTATION, {
    ...options,
  });

  return {
    rejectReview,
    loading,
    error,
  };
}
