import gql from 'graphql-tag';

export const GET_CURRENT_USAGE = gql`
  query GetCurrentUsageQuery {
    currentUsage: getCurrentUsage {
      entities {
        BAM_creators {
          current
          limit
        }
        collaborations {
          current
          limit
        }
        programs {
          current
          limit
        }
        users {
          current
          limit
        }
      }
      features {
        sales_tracking {
          enabled
        }
        shopify_customer_sync {
          enabled
        }
      }
    }
  }
`;

export const GET_ENTITY_USAGE = gql`
  query GetEntityUsageQuery($entity: String!) {
    entityUsage: getEntityUsage(entity: $entity) {
      current
      limit
    }
  }
`;

export const GET_FEATRUE_GATE = gql`
  query GetFeatureGateQuery($feature: String!) {
    featureGate: getFeatureGate(feature: $feature) {
      enabled
    }
  }
`;
