import * as React from 'react';
import cx from 'classnames';
import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';

import styles from './GroupsPage.scss';
import { GroupCreators } from './GroupCreators';

interface IProps {
  className?: string;
}

export const GroupsPagePage: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();
  return (
    <div className={cx(styles.ProgramsPage, props.className)}>
      <Switch>
        <Route path={`${match.path}/:id`} component={GroupCreators} />
      </Switch>
    </div>
  );
});

GroupsPagePage.displayName = 'GroupsPagePage';
