import { AssignPaymentTo } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
    assignPaymentTo: AssignPaymentTo;
    type: ActionType.SET_ASSIGN_PAYMENT_TO;
}
export type TSetAssignPaymentToAction = Readonly<IAction>;

export const setAssignPaymentTo = (state: TState, action: TSetAssignPaymentToAction): TState => ({
        ...state,
        assignPaymentTo: action.assignPaymentTo,
    });
