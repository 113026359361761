import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_WORKLETS_QUERY } from '@frontend/app/queries';
import {
  GetAllWorkletsQuery,
  GetAllWorkletsQueryVariables,
} from '@frontend/app/queries/types/GetAllWorkletsQuery';

type IOptions = QueryHookOptions<GetAllWorkletsQuery, GetAllWorkletsQueryVariables>;

export const useGetAllWorkletsQuery = (options: IOptions = {}) => (
  useQuery<GetAllWorkletsQuery, GetAllWorkletsQueryVariables>(GET_ALL_WORKLETS_QUERY, options)
);
