import * as React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';

import { unescape } from 'lodash';
import { renderEmptyCell } from '../EmptyCell';

import styles from './styles.scss';

const { useRef } = React;

interface IDefaultCellProps {
  value: string;
}

export const DefaultCell: React.FC<IDefaultCellProps> = (props) => {
  const {
    value,
  } = props;

  const cellRef = useRef<HTMLDivElement>(null);

  const isOverflowing = (() => {
    if (cellRef?.current) {
      const element = cellRef.current;
      return element.clientWidth < element.scrollWidth;
    }
    return true;
  })();

  if (!value) {
    return renderEmptyCell();
  }

  return (
    <div
      ref={cellRef}
      className={styles.DefaultCell}
    >
      <Tooltip
        title={unescape(value)}
        placement="topLeft"
        overlayClassName={cx(
          styles.tooltip,
          {
            [styles.hideTooltip]: !isOverflowing,
          },
        )}
      >
        {unescape(value)}
      </Tooltip>
    </div>
  );
};

export const renderDefaultCell = (value: string | undefined) => <DefaultCell value={value} />;
