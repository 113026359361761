import { TBudgetPayment } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
    payments: TBudgetPayment[];
    type: ActionType.SET_BUDGET_PAYMENTS;
}
export type TSetBudgetPaymentsAction = Readonly<IAction>;

export const setBudgetPayments = (state: TState, action: TSetBudgetPaymentsAction): TState => ({
    ...state,
    payments: action.payments,
});
