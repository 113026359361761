import * as React from 'react';

import {
  Button,
  Form,
} from '@revfluence/fresh';
import { OfferFormTestIds as testIds } from '../OfferFormTestIds';

interface IProps {
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
}

export const UndeleteButton: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  loading,
  onClick,
}) => (
  <Form.Item>
    <Button
      block
      data-testid={testIds.undelete}
      disabled={disabled}
      htmlType="submit"
      onClick={onClick}
      loading={loading}
      type="primary"
      size="large"
    >
      Un-delete Offer
    </Button>
  </Form.Item>
));

UndeleteButton.displayName = 'UndeleteButton';
