import { TNetworkIdentifier } from '@components';
import { TLocationType, TGender } from '@components';
import { CreatorSearchVersion } from './searchPage';

export interface ICreatorSkillset {
  identifier: string;
  displayName: string;
  order: number;
}

export interface ICountryRegionOption {
  label: string;
  value: string;
}

export interface INetworkInfo {
  identifier: TNetworkIdentifier;
  audienceNamePlural: string;
  engagementNamePlural: string;
  contentName: string;
}

export interface ICreatorSkillsetFilters {
  selectedIdentifiers?: string[];
}

export interface ICreatorSkillsetOptions {
  choices?: ICreatorSkillset[];
  hide?: boolean;
}

export type TRange = [number, number];

export interface IEngagementFilters {
  reachRange?: TRange;
  engagementRange?: TRange;
  impressionRange?: TRange;
  engagementRatioRange?: TRange;
}

export interface IEngagementOptions {
  reachLimitsByNetwork?: { [network: string]: TRange };
  engagementLimitsByNetwork?: { [network: string]: TRange };
  impressionLimitsByNetwork?: { [network: string]: TRange };
  reachStep?: number;
  engagementStep?: number;
  impressionStep?: number;
  hideEngagement?: boolean;
  hideImpression?: boolean;
  hideEngagementRatio?: boolean;

  networkInfos?: INetworkInfo[];
  hide?: boolean;
  isExpanded?: boolean;
}

export interface IOtherOptions {
  tagOptions?: string[];
  hide?: boolean;
  hideInvitedToggle?: boolean;
  hideTags?: boolean;
  hideActivity?: boolean;
  hideCanContact?: boolean;
  isExpanded?: boolean;
  hideHasSponsoredPosts?: boolean;
}

export interface IActivityFilters {
  lastPostActivity?: number;
}

export interface IOtherFilters extends IActivityFilters {
  hideInvited?: boolean;
  tags?: string[];
  canContact?: boolean;
  hasSponsoredPosts?: boolean;
}

export interface IRelationshipFilters {
  projectIds?: number[];
  groupIds?: number[];
}

export interface IContactOptions {
  hide?: boolean;
  isExpanded?: boolean;
}
export interface IContactFilters {
  canContactEmail?: boolean;
  canContactInstagramDM?: boolean;
}

export interface INetworkFilters {
  channel?: TNetworkIdentifier;
}

export interface INetworkOptions {
  channelOptions?: TNetworkIdentifier[];
  hide?: boolean;
  hideChannels?: boolean;
  isExpanded?: boolean;
  version?: CreatorSearchVersion;
}

export interface ILanguageOption {
  label: string;
  value: string;
}

export interface ICreatorAgeFilters {
  ageRanges?: string[];
  ageMin?: string;
  ageMax?: string;
}

export interface ICreatorAgeOptions {
  ageRangeChoices?: string[];
}

export interface ICreatorInterestFilters {
  interests?: string[];
}

export interface ICreatorInterestOptions {
  interestChoices?: string[];
}

export interface ICreatorDemographicOptions extends ICreatorAgeOptions, ICreatorInterestOptions {
  countryChoices?: ICountryRegionOption[];
  languageChoices?: ILanguageOption[];

  hide?: boolean;
  hideCreatorAge?: boolean;
  hideCreatorInterests?: boolean;
  hideCreatorGender?: boolean;
  hideCreatorLocations?: boolean;
  hideCreatorCountries?: boolean;
  hideCreatorLanguages?: boolean;
  isExpanded?: boolean;
}

export interface ILocation {
  label: string;
  value: string;
}

export interface ICreatorGenderFilters {
  gender?: TGender;
}

export interface ICreatorDemographicFilters extends ICreatorAgeFilters, ICreatorGenderFilters, ICreatorInterestFilters {
  locations?: ILocation[];
  countries?: ICountryRegionOption[];
  languages?: ILanguageOption[];
}

export interface IAudienceAgeOptions {
  ageRangeChoices?: string[];
}

export interface IAudienceAgeFilters {
  ageRange?: string;
  ageLessThan?: boolean;
  agePercentage?: number;
  ageMin?: string;
  ageMax?: string;
}

export interface IAudienceEthnicityOptions {
  ethnicityChoices?: string[];
  showIQDataFilters?: boolean;
}

export interface IAudienceEthnicityFilters {
  ethnicity?: string;
  ethnicityLessThan?: boolean;
  ethnicityPercentage?: number;
}

export interface IAudienceLocationOptions {
  countryChoices?: ICountryRegionOption[];
  regionChoices?: ICountryRegionOption[];
}

export interface IAudienceLocationFilters {
  locationType?: TLocationType;
  countryRegion?: string;
  countryRegionLessThan?: boolean;
  countryRegionPercentage?: number;
  audienceLocations?: ILocation[];
}

export interface IAudienceDemographicOptions extends
  IAudienceLocationOptions, IAudienceAgeOptions, IAudienceEthnicityOptions {
  hide?: boolean;
  hideAudienceLocation?: boolean;
  hideAudienceGender?: boolean;
  hideAudienceAge?: boolean;
  hideAudienceEthnicity?: boolean;
  hideAudiencePercentage?: boolean;
  isExpanded?: boolean;
  showIQDataFilters?: boolean;
}

export interface IAudienceGenderFilters {
  gender?: TGender;
  genderLessThan?: boolean;
  genderPercentage?: number;
}

export type IAudienceDemographicFilters =
  & IAudienceLocationFilters
  & IAudienceGenderFilters
  & IAudienceAgeFilters
  & IAudienceEthnicityFilters;

export enum SearchTermType {
  Username = 'username',
  Keywords = 'keywords',
  Topics = 'relevance',
  Lookalike = 'lookalike',
  Mentions = 'mentions',
}

export interface ISocialSearchTag {
  tag: string;
  value: string;
}

export interface ICreatorSearchFilters {
  query?: string;
  creatorSkillsets?: ICreatorSkillsetFilters;
  network?: INetworkFilters;
  engagement?: IEngagementFilters;
  creatorDemographics?: ICreatorDemographicFilters;
  audienceDemographics?: IAudienceDemographicFilters;
  other?: IOtherFilters;
  termType?: SearchTermType;
  isIQSearch?: boolean;
  contact?: IContactFilters;
  relationships?: IRelationshipFilters;
}

export interface ICreatorSearchOptions {
  query?: {
    placeholder?: string;
    buffer?: number;
  };
  creatorSkillsets?: ICreatorSkillsetOptions;
  network?: INetworkOptions;
  engagement?: IEngagementOptions;
  creatorDemographics?: ICreatorDemographicOptions;
  audienceDemographics?: IAudienceDemographicOptions;
  other?: IOtherOptions;
  contact?: IContactOptions;
}
