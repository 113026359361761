/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_l2ddf_157 {
  display: flex;
}

._justify-content-space-between_l2ddf_161 {
  justify-content: space-between;
}

._tabular-nums_l2ddf_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_l2ddf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._fiscalYearFieldInfo_l2ddf_178 {
  display: flex;
  flex-direction: column;
  align-items: start;
}

._fiscalYearTitle_l2ddf_184 {
  font-size: 1rem;
  font-weight: 600;
  color: #1F1F21;
}

._fiscalYearsContainer_l2ddf_190 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: start;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

._fiscalYearsAndAddButton_l2ddf_199 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
}

._addFiscalYearButton_l2ddf_206 {
  padding-left: 0;
}

._fiscalYearRow_l2ddf_210 {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: flex-end;
}
._fiscalYearRow_l2ddf_210 .ant-btn {
  height: 2.5rem;
}

._field_l2ddf_220 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

._fiscalYearName_l2ddf_226 {
  width: 15.125rem;
}
._fiscalYearName_l2ddf_226 .ant-input {
  height: 2.5rem;
}

._referenceText_l2ddf_233 {
  color: #8C8C8C;
  font-size: 0.875rem;
  font-weight: 400;
}

._fieldLabel_l2ddf_239 {
  font-size: 0.875rem;
  font-weight: 400;
  color: #1F1F21;
}

._fiscalYearDate_l2ddf_245 .ant-picker {
  height: 2.5rem;
  display: flex;
  width: 15.125rem;
}

._removeFiscalYear_l2ddf_251 {
  color: red;
  font-size: 0.875rem;
  font-weight: 400;
}

._archiveFiscalYear_l2ddf_257 {
  color: #8C8C8C;
  font-size: 0.875rem;
  font-weight: 400;
}

._fiscalYearContainer_l2ddf_263 {
  color: #1F1F21;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;
  padding: 1rem;
  border: 1px solid #f0f0f0;
  border-radius: 1rem;
}
._fiscalYearContainer_l2ddf_263 .ant-tag-has-color {
  color: #1F1F21;
}