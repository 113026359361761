import * as React from 'react';
import { filter, isNull } from 'lodash';

import { IStepInfo as Step } from '@frontend/applications/Shared/Wizard/container';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { ReviewMembers } from '../../components/ReviewMembers';
import { SelectMembers } from '../../components/SelectMembers';
import { StatsCardPromos } from '../../components/StatsCardPromos';
import { TDeactivateMembersProps } from '../../types';
import { TActions, TState } from './state';
import { StatsCardLinks } from '../../components/StatsCardLinks';

interface IStepConfig {
  instructions: Step['instructions'];
  nextButtonConfig: Step['nextButtonConfig'];
  previousButtonConfig: Step['previousButtonConfig'];
  stepNum: Step['stepNum'];
}

type TStepFactory = (config: IStepConfig, overrides?: Partial<Step>) => Step;

export const getSteps = (props: TDeactivateMembersProps, state: TState, actions: TActions) => {
  const {
    offerName,
    programLabel,
  } = props;
  let title = '';
  let statsCard: React.ReactNode;
  const selectedMembers = isNull(state.members) ? [] : filter(state.members, (m) => m.selected);
  switch (props.offerSource) {
    case OFFER_SOURCE.SHOPIFY:
      title = 'Deactivate Codes';
      statsCard = (
        <StatsCardPromos
          discountAmount={props.discountAmount}
          discountType={props.discountType}
          members={selectedMembers}
          mode="deactivate"
          offerName={offerName}
          isNewFlow={props.isNewFlow}
          payoutType={props.payoutType}
          percentPayout={props.percentPayout}
          flatPayout={props.flatPayout}
          startDate={props.startDate}
          endDate={props.endDate}
        />
);
      break;
    case OFFER_SOURCE.TUNE:
      title = 'Deactivate Links';
      statsCard = (
        <StatsCardLinks
          payoutType={props.payoutType}
          expirationDate={props.expirationDate}
          flatPayout={props.flatPayout}
          percentPayout={props.percentPayout}
          members={state.members}
          mode="deactivate"
        />
);
      break;
  }

  const selectMembers: TStepFactory = (config, overrides = {}): Step => ({
      actionComponents: (
        <>
          {statsCard}
          <SelectMembers
            offerSource={props.offerSource}
            markMembersSelected={actions.selectMembers}
            members={state.members}
            mode="deactivate"
            offerName={offerName}
            programLabel={programLabel}
            isNewFlow={props.isNewFlow}

          />
        </>
      ),
      title,
      ...config,
      ...overrides,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any;

  const reviewMembers: TStepFactory = (config, overrides = {}): Step => ({
      actionComponents: (
        <>
          {statsCard}
          <ReviewMembers
            offerSource={props.offerSource}
            members={selectedMembers}
            mode="deactivate"
            offerName={offerName}
            programLabel={programLabel}
            isWorkflow={false}
            isNewFlow={props.isNewFlow}
          />
        </>
      ),
      title,
      ...config,
      ...overrides,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any;

  const completed: TStepFactory = (config, overrides = {}): Step => ({
      actionComponents: (
        <>
          {statsCard}
          <ReviewMembers
            offerSource={props.offerSource}
            members={selectedMembers}
            mode="deactivate"
            offerName={offerName}
            programLabel={programLabel}
            isWorkflow={false}
            isNewFlow={props.isNewFlow}
          />
        </>
      ),
      title,
      ...config,
      ...overrides,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any;

  return {
    selectMembers,
    reviewMembers,
    completed,
    selectedMembers,
  };
};
