import * as React from 'react';
import { InstagramIcon, TiktokIcon } from '@revfluence/fresh-icons/brands/esm';
import { useCallback, useState, useEffect } from 'react';
import styles from './GroupCreators.scss';
import { generateLinkFromUsername } from './GcrUtils';

const PrimaryNetwork = ({ memberRecord }) => {
  const { metaFields: { preferred_platform, tiktok, instagram } } = memberRecord;
  const [platformToShow, setPlatform] = useState(preferred_platform?.toLowerCase());

  useEffect(() => {
    setPlatform(preferred_platform?.toLowerCase());
  }, [memberRecord, preferred_platform]);

  const renderPlatform = useCallback(() => {
    switch (platformToShow) {
      case 'tiktok':
        return (
          <a href={generateLinkFromUsername('tiktok', `@${tiktok}`)} target="_blank" title={tiktok}>
            <TiktokIcon />
          </a>
        );
      case 'instagram':
        return (
          <a href={generateLinkFromUsername('instagram', instagram)} target="_blank" title={instagram}>
            <InstagramIcon />
          </a>
);
      case 'both':
        return (
          <>
            <a href={generateLinkFromUsername('instagram', instagram)} target="_blank" title={instagram}>
              <InstagramIcon />
            </a>
            <a href={generateLinkFromUsername('tiktok', `@${tiktok}`)} target="_blank" title={tiktok}>
              <TiktokIcon />
            </a>
          </>
);
      default:
          return <span>-</span>;
      }
    }, [platformToShow, instagram, tiktok]);

  return (
    memberRecord && (
    <div className={styles.nameHandle}>
      {renderPlatform()}
    </div>
)
  );
};

export default PrimaryNetwork;
