import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import { ASSIGN_OWNERS_TO_MEMBERS_MUTATION } from '@frontend/app/queries';
import {
  AssignOwnersToMembersMutation,
  AssignOwnersToMembersMutationVariables,
} from '@frontend/app/queries/types/AssignOwnersToMembersMutation';

type IOptions = MutationHookOptions<AssignOwnersToMembersMutation, AssignOwnersToMembersMutationVariables>;

export const useAssignOwnersToMembersMutation = (options: IOptions = {}) => useMemberMutation(ASSIGN_OWNERS_TO_MEMBERS_MUTATION, options);
