import * as React from 'react';
import {
  isEmpty,
} from 'lodash';
import {
  Divider,
  Modal,
} from '@revfluence/fresh';
import GuidelinesListEmpty
  from '@frontend/applications/TermsApp/components/ContentGuidelines/GuidelinesListEmpty/GuidelinesListEmpty';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments as Attachment,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import {
  ISortableGuideline,
  IContentGuidelineInstruction,
  IContentGuidelineTitle,
} from '@frontend/applications/TermsApp/components/BulkTerms/hooks/useState/actions';
import GuidelinesListItem
  // eslint-disable-next-line max-len
  from '@frontend/applications/TermsApp/components/ContentGuidelines/ContentGuidelinesList/GuidelinesListItems/GuidelinesListItem/GuidelinesListItem';
import { TemplateType } from '@frontend/applications/TermsApp/components/ContentGuidelines/types/state';
import { ContentType } from '@frontend/app/types/globalTypes';

interface IProps {
  isReadOnly: boolean;
  shouldListSectionsOnly?: boolean;
  templateType: TemplateType;
  contentGuidelines: Array<TContentGuideline>;
  premadeTemplates: Array<TContentGuideline>;
  blankTemplates: Array<TContentGuideline>;
  otherProjectContentGuidelines: Array<TContentGuideline>;
  defaultActiveKey: number;
  isEditMode: boolean;
  onDuplicateContentGuideline: (contentGuideline: TContentGuideline) => void;
  onDeleteContentGuideline: (contentGuideline: TContentGuideline) => void;
  onSaveContentGuideline
  onUpdateContentGuidelineInstruction: (contentGuidelineInstruction: IContentGuidelineInstruction) => void
  onSortContentGuideline: (
    id: number,
    sortableGuideline: ISortableGuideline,
  ) => void;
  onAddContentGuidelineAttachment: (
    attachment: Attachment,
    id: number,
  ) => void;
  onDeleteContentGuidelineAttachment: (
    attachments: Array<Attachment>,
    id: number,
  ) => void;
  onUpdateContentGuidelineLabel: (id: number, label: string) => void;
  onUpdateContentGuidelineActiveKey: (defaultActiveKey: number) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
}

const {
  useCallback,
  useMemo,
  createRef,
  useEffect,
} = React;
const { confirm } = Modal;

const GuidelinesListItems: React.FC<IProps> = React.memo((props) => {
  const {
    isReadOnly,
    shouldListSectionsOnly = false,
    contentGuidelines,
    premadeTemplates,
    blankTemplates,
    templateType,
    otherProjectContentGuidelines,
    defaultActiveKey,
    isEditMode,
    onDuplicateContentGuideline,
    onDeleteContentGuideline,
    onSaveContentGuideline,
    onUpdateContentGuidelineInstruction,
    onSortContentGuideline,
    onAddContentGuidelineAttachment,
    onDeleteContentGuidelineAttachment,
    onUpdateContentGuidelineLabel,
    onUpdateContentGuidelineActiveKey,
    onUpdateContentGuidelineTitle,
  } = props;

  const guidelinesListItems = useMemo(() => {
    if (!isReadOnly) {
      return contentGuidelines;
    }
    if ([TemplateType.BlankTemplates, TemplateType.PremadeTemplates].includes(templateType)) {
      return templateType === TemplateType.BlankTemplates ? blankTemplates : premadeTemplates;
    }
    return otherProjectContentGuidelines || [];
  }, [
    isReadOnly,
    templateType,
    contentGuidelines,
    blankTemplates,
    premadeTemplates,
    otherProjectContentGuidelines,
  ]);

  const guidelinesListItemsRef = useMemo(
    () => guidelinesListItems.reduce(
      (acc, value) => {
        acc[value.id] = createRef<HTMLDivElement>();
        return acc;
      },
      {},
    ),
    [guidelinesListItems],
  );

  useEffect(() => {
    if (
      !isReadOnly
      && !isEditMode
      && defaultActiveKey !== 0
      && guidelinesListItemsRef[defaultActiveKey]?.current) {
      guidelinesListItemsRef[defaultActiveKey].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [
    isReadOnly,
    isEditMode,
    defaultActiveKey,
    guidelinesListItemsRef,
  ]);

  const onConfirmDeleteGuideline = useCallback((contentGuideline: TContentGuideline) => {
    confirm({
      title: 'Are you sure you want to delete?',
      icon: <TriangleExclamationIcon color="#F5222D" />,
      content: `${contentGuideline.label} will be permanently deleted.`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => onDeleteContentGuideline(contentGuideline),
      onCancel: () => {},
      autoFocusButton: null,
    });
  }, [onDeleteContentGuideline]);

  if (isEmpty(guidelinesListItems)) {
    return <GuidelinesListEmpty />;
  }

  return (
    <>
      {
        !isReadOnly
        && (
          <Divider style={{ margin: '0' }} />
        )
      }
      {
        guidelinesListItems.map((contentGuideline: TContentGuideline) => {
          if (ContentType.TWITTER_POST === contentGuideline.type) return null;
          return (
            <div
              key={contentGuideline.id}
              ref={guidelinesListItemsRef[contentGuideline.id]}
            >
              <GuidelinesListItem
                shouldListSectionsOnly={shouldListSectionsOnly}
                isReadOnly={isReadOnly}
                templateType={templateType}
                contentGuideline={contentGuideline}
                defaultActiveKey={defaultActiveKey}
                onDuplicateContentGuideline={onDuplicateContentGuideline}
                onDeleteContentGuideline={onConfirmDeleteGuideline}
                onSaveContentGuideline={onSaveContentGuideline}
                onUpdateContentGuidelineInstruction={onUpdateContentGuidelineInstruction}
                onSortContentGuideline={onSortContentGuideline}
                onAddContentGuidelineAttachment={onAddContentGuidelineAttachment}
                onDeleteContentGuidelineAttachment={onDeleteContentGuidelineAttachment}
                onUpdateContentGuidelineLabel={onUpdateContentGuidelineLabel}
                onUpdateContentGuidelineActiveKey={onUpdateContentGuidelineActiveKey}
                onUpdateContentGuidelineTitle={onUpdateContentGuidelineTitle}
              />
            </div>
          );
        })
      }
    </>
  );
});

GuidelinesListItems.displayName = 'GuidelinesListItems';

export default GuidelinesListItems;
