import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { MARK_WORK_ITEMS_VIEWED_MUTATION } from '@frontend/app/queries';
import { MarkWorkItemsViewedMutation, MarkWorkItemsViewedMutationVariables } from '../queries/types/MarkWorkItemsViewedMutation';

type IOptions = MutationHookOptions<MarkWorkItemsViewedMutation, MarkWorkItemsViewedMutationVariables>;

export const useMarkWorkItemsViewedMutation = (options: IOptions = {}) => (
  useMutation<MarkWorkItemsViewedMutation, MarkWorkItemsViewedMutationVariables>(MARK_WORK_ITEMS_VIEWED_MUTATION, options)
);
