import React, { useCallback, useMemo, useState } from 'react';
import {
 Button, Card, Checkbox, Col, Dropdown, Empty, Form, IMenuProps, Input, InputNumber, message, Modal, Row, Skeleton, Space, Tag, Typography, useForm,
} from '@revfluence/fresh';
import { EllipsisIcon, TriangleExclamationIcon, TruckIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { useCreateShopifyOrderConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useCreateShopifyOrderConfig';
import { useAuth } from '@frontend/context/authContext';
import styles from './ShopifyShippingLines.scss';
import { ShopifyShippingLine } from './types';

const { Title, Text } = Typography;

export const ShopifyShippingLines = () => {
  const [addShippingLinesModalOpen, setAddShippingLinesModalOpen] = useState(false);

  const [form] = useForm<ShopifyShippingLine>();

  const { clientInfo } = useAuth();
  const { clientConfig, loading: isClientConfigLoading, refetch: refetchClientConfig } = useGetClientConfig();
  const [editingId, setEditingId] = useState<number | null>(null);

  const { createShopifyOrderConfig, isCreatingShopifyOrderConfig } = useCreateShopifyOrderConfig({
    onError: (error) => {
      message.error(error.message || 'Something went wrong');
    },
  });

  const shippingLines = useMemo<ShopifyShippingLine[]>(() => clientConfig?.shopifyOrderPayload?.shippingLines || [], [clientConfig]);

  const handleAddShippingLine = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    if (editingId) {
      if (shippingLines.find((shippingLine) => shippingLine.title === values.title && shippingLine.id !== editingId)) {
        message.error('Shipping Line with this title already exists');
        return;
      }
    } else if (shippingLines.find((shippingLine) => shippingLine.title === values.title)) {
      message.error('Shipping Line with this title already exists');
      return;
    }

    const isAnyDefault = shippingLines.some((shippingLine) => shippingLine.isDefault);

    const shippingLinesWithoutDefault = values.isDefault ? shippingLines.map((shippingLine) => ({
      ...shippingLine,
      isDefault: false,
    })) : shippingLines;
    const shippingLinesPayload = editingId
      ? shippingLinesWithoutDefault.map((shippingLine) => {
        if (shippingLine.id === editingId) {
          return {
            ...shippingLine,
            title: values.title,
            code: values.title,
            price: String(values.price),
            isDefault: isAnyDefault ? values.isDefault : true,
          };
        }
        return shippingLine;
      }) : [
        ...shippingLinesWithoutDefault,
        {
          id: shippingLines.length + 1,
          title: values.title,
          code: values.title,
          price: String(values.price),
          isDefault: isAnyDefault ? values.isDefault : true,
        },
      ];
    createShopifyOrderConfig({
      variables: {
        clientId: clientInfo.id,
        shopifyOrderPayload: {
          ...clientConfig.shopifyOrderPayload,
          shippingLines: shippingLinesPayload,
        },
      },
      onCompleted: () => {
        refetchClientConfig();
        setAddShippingLinesModalOpen(false);
        form.resetFields();
        setEditingId(null);
        message.success(editingId ? 'Shipping Line updated successfully' : 'Shipping Line added successfully');
      },
    });
  };

  const menuItems = useCallback<(id: number) => IMenuProps['items']>((id) => [
    {
      label: 'Edit',
      key: 'edit',
      onClick: () => {
        setEditingId(id);
        form.setFieldsValue(shippingLines.find((shippingLine) => shippingLine.id === id));
        setAddShippingLinesModalOpen(true);
      },
    },
    {
      label: 'Make Default',
      key: 'makeDefault',
      onClick: () => {
        createShopifyOrderConfig({
          variables: {
            clientId: clientInfo.id,
            shopifyOrderPayload: {
              ...clientConfig.shopifyOrderPayload,
              shippingLines: shippingLines.map((shippingLine) => ({
                ...shippingLine,
                isDefault: shippingLine.id === id,
              })),
            },
          },
          onCompleted: () => {
            const shippingLine = shippingLines.find((line) => line.id === id);
            message.success(`Shipping line "${shippingLine.title}" marked as default`);
          },
        });
      },
    },
    {
      label: 'Remove',
      key: 'remove',
      danger: true,
      onClick: () => {
        Modal.confirm({
          title: 'Are you sure you want to remove this shipping line?',
          icon: <TriangleExclamationIcon />,
          okText: 'Yes',
          okType: 'danger',
          onOk: () => {
            createShopifyOrderConfig({
              variables: {
                clientId: clientInfo.id,
                shopifyOrderPayload: {
                  ...clientConfig.shopifyOrderPayload,
                  shippingLines: shippingLines.filter((shippingLine) => shippingLine.id !== id),
                },
              },
              onCompleted: () => {
                message.success('Shipping Line removed successfully');
              },
            });
          },
        });
      },
    },
  ], [clientConfig?.shopifyOrderPayload, clientInfo.id, createShopifyOrderConfig, form, shippingLines]);

  const onClose = () => {
    setAddShippingLinesModalOpen(false);
    form.resetFields();
    setEditingId(null);
  };

  return (
    <div className={styles.ShopifyShippingLines}>
      <Space direction="vertical" size="large">
        <Space direction="vertical" size={0}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={5}>Manage Shipping Lines</Title>
            </Col>
            <Col>
              {shippingLines.length > 0 && (
              <Button
                type="primary"
                onClick={() => setAddShippingLinesModalOpen(true)}
              >
                Add Shipping Line
              </Button>
            )}
            </Col>
          </Row>
          <Text className={styles.grayText}>You can add or manage the shipping lines here.</Text>
        </Space>
        {
        isClientConfigLoading && (
          <Skeleton active />
        )
      }
        {
        !isClientConfigLoading
        && !shippingLines.length && (
          <Empty
            image={<TruckIcon />}
            description={(
              <Space direction="vertical">
                <Typography.Title level={5}>No Shipping Lines added</Typography.Title>
                <Typography.Text className={styles.grayText}>
                  Add custom shipping lines or directly use the existing shipping lines from your shopify store
                </Typography.Text>
                <Button
                  type="primary"
                  onClick={() => setAddShippingLinesModalOpen(true)}
                >
                  Create New
                </Button>
              </Space>
            )}
          />
        )
      }
        {
        !isClientConfigLoading
        && shippingLines.length > 0 && (
          <Space direction="vertical">
            {
              shippingLines.map((shippingLine) => (
                <Card key={`${shippingLine.id}-${shippingLine.title}`}>
                  <Space>
                    <Text>{shippingLine.title}</Text>
                    <Text>{Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(shippingLine.price))}</Text>
                    <Row align="middle" justify="space-between">
                      <Col>
                        {
                          shippingLine.isDefault && (
                            <Tag color="success">Default</Tag>
                          )
                        }
                      </Col>
                      <Col>
                        <Dropdown menu={{ items: menuItems(shippingLine.id) }} trigger={['click']}>
                          <Button
                            onClick={(e) => e.preventDefault()}
                            type="text"
                            icon={<EllipsisIcon />}
                          />
                        </Dropdown>
                      </Col>
                    </Row>
                  </Space>
                </Card>
              ))
            }
          </Space>
        )
      }
        <Modal
          title={editingId ? 'Edit Shipping Line' : 'Add Shipping Line'}
          open={addShippingLinesModalOpen}
          onCancel={onClose}
          footer={[
            <Button
              key="cancel"
              onClick={onClose}
              disabled={isCreatingShopifyOrderConfig}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleAddShippingLine}
              loading={isCreatingShopifyOrderConfig}
            >
              {editingId ? 'Save' : 'Add'}
            </Button>,
        ]}
          cancelButtonProps={{ disabled: isCreatingShopifyOrderConfig }}
          className={styles.ShopifyShippingLines}
        >
          <Space direction="vertical">
            {!editingId && <Text>Add a custom shipping line</Text>}
            <Form layout="vertical" form={form}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Please input a title!' }]}
              >
                <Input
                  placeholder="Enter Title"
                />
              </Form.Item>
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: 'Please input a price!' }]}
              >
                <InputNumber
                  prefix="$"
                  placeholder="0.00"
                  min={0}
                  className={styles.numberInput}
                />
              </Form.Item>
              <Form.Item
                name="isDefault"
                valuePropName="checked"
              >
                <Checkbox>Make default</Checkbox>
              </Form.Item>
            </Form>
          </Space>
        </Modal>
      </Space>
    </div>
  );
};
