import { addGuidelineAttachment } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IState } from '../../../../types/state';
import { IAddAttachmentAction } from './index';

export const addAttachment = (state: IState, action: IAddAttachmentAction): IState => ({
  ...state,
  contentGuidelines: addGuidelineAttachment(
    state.contentGuidelines,
    action.contentGuidelineInstanceId,
    action.attachment,
  ),
});
