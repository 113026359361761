import {
 Alert, Avatar, Button, Row, Typography,
} from '@revfluence/fresh';
import { isEmpty } from 'lodash';
import React from 'react';
import { ChevronRightIcon } from '@revfluence/fresh-icons/solid/esm';
import styles from './ManageOffer.scss';

const { Text, Title } = Typography;
interface IProps {
    title: string,
    description: string,
    icon: React.ReactNode,
    onClick: React.MouseEventHandler<HTMLElement>,
    disabled?: boolean,
    errorMessage?: string,
}
export const ManageOfferItem: React.FC<Readonly<IProps>> = React.memo((props) => {
    const {
 title, description, icon, onClick, disabled, errorMessage,
} = props;
    return (
      <Button disabled={disabled} onClick={onClick}>
        <Row
          align="middle"
          justify="space-between"
          className={styles.buttonsAction}
        >
          <Row align="middle" gutter={12}>
            <Avatar shape="square" size={46} icon={icon} />
            <div className={styles.buttonTitle}>
              <Title level={5}>{title}</Title>
              <Text>{description}</Text>
            </div>
          </Row>
          <ChevronRightIcon />
        </Row>
        {!isEmpty(errorMessage) && <Alert type="info" message={errorMessage} />}
      </Button>
    );
});

ManageOfferItem.displayName = 'ManageOfferItem';
