import gql from 'graphql-tag';
import { MEMBER_FIELD_SCHEMA_FRAGMENT } from './MemberFieldSchemaFragment';

export const COMMUNITY_FRAGMENT = gql`
  fragment CommunityPageCommunity on Community {
    id
    title
    description
    splashImageUrl
    clientIds
    createdDate
    updatedDate
    memberCount
    memberFieldSchemas {
      ...MemberFieldSchemaFragment
    }
  showApplicantReview
  }
  ${MEMBER_FIELD_SCHEMA_FRAGMENT}
`;
