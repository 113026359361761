/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ApolloError } from 'apollo-client';
import {
  filter as _filter,
  findIndex,
  isEmpty,
  isNil,
  map,
  reduce,
  size,
  slice,
} from 'lodash';
import { makeVar, useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@frontend/context/authContext';
import {
  useClientFeatureEnabled,
  useCommunitySwitcherContext,
  useFetchThreads,
  useGetAllResourceThreadsCount,
  useGetAllThreadsCount,
  useGetApplicationsByIds,
  useInstalledApplicationInstances,
} from '@frontend/app/hooks';
import {
  SortDirection,
  ThreadFilterInput,
  ThreadLabelType,
} from '@frontend/app/types/globalTypes';
import { GetThreadsQuery_threads as IThread } from '@frontend/app/queries/types/GetThreadsQuery';
import { useResourceContext } from '@frontend/app/context';
import { ClientFeature } from '@frontend/app/constants';
import {
  searchFilterReactiveVars,
  getMessageFilter,
  getApplicationIds,
  getAssignees,
} from '../reativeVars/searchFilter';

export type TAssigneeType = 'you' | 'all' | 'shared' | 'appNotification' | 'sent';
export type TSelectedUserLabel = ThreadLabelType | 'ALL';

const {
 useState, useContext, useEffect, useMemo,
} = React;
const THREAD_COUNT_PAGE_PAGE = 20;

interface IMessagingAppContext {
  assigneeType: TAssigneeType;
  currentFolderLabel: string;
  applications: any;
  setAssigneeType(assigneeType: TAssigneeType): void;
  setCurrentFolderLabel(label: string): void;
  resourceId: number;
  setResourceId(resourceId: number): void;
  resourceEmail: string;
  setResourceEmail(resourceEmail: string): void;
  sortDir: SortDirection;
  setSortDir(sortDir: SortDirection): void;
  setResetFilter(resetFilter: boolean): void;
  excludeApplicationIdsFilter: string[];
  setExcludeApplicationIdsFilter(sortDir: any): void;
  status: TSelectedUserLabel;
  setStatus(status: TSelectedUserLabel): void;
  filter: ThreadFilterInput;

  // selected thread
  selectedThread: IThread;
  setSelectedThread(selectedThread: IThread): void;

  // Folder click
  isAllFolderOpen: boolean;
  setIsAllFolderOpen(clicked: boolean): void;

  // threads
  userThreadsCount: number;
  threadCountsByResourceId: Record<number, number>;
  sharedThreadsCount: number;
  threads: IThread[];
  loadingThreads: boolean;
  loadThreadsError: ApolloError;

  // load more and update messaging state
  loadMoreThreads(): void;
  refetchAndUpdateThreads(skip?: number, limit?: number, shouldUseFullSearch?: boolean): void;
  removeThread(id: string): void;
  resetMessagingState(): void;
  refetchThreadsCount(): void;
  goToNextThread(): void;
  setNoMoreThreads(value: boolean): void
}

export const selectedThreadsVar = makeVar<{ id: string, label?: string, isRead?: boolean }[]>([]);
export const isSelectAllThreadsVar = makeVar<boolean>(false);
export const needRefetchMessageVar = makeVar<boolean>(false);
export const expandedMessagesVar = makeVar<string[]>([]);

const MessagingContext = React.createContext<IMessagingAppContext>(null);
export const useMessagingContext = () => useContext(MessagingContext);
export const messageThreadTypes = ['Gmail', 'Creator Search', 'Outlook', 'IGDM', 'Automation'];
export const MessagingProvider = ({ children }) => {
  const { user: authUser } = useAuth();
  const history = useHistory();

  const {
    selectedCommunityId,
  } = useCommunitySwitcherContext();
  const [assigneeType, setAssigneeType] = useState<TAssigneeType>('you');
  const [currentFolderLabel, setCurrentFolderLabel] = useState<string>('Assigned to Me');
  const [resourceId, setResourceId] = useState<number>(null);
  const [resourceEmail, setResourceEmail] = useState<string>('');
  const [sortDir, setSortDir] = useState<SortDirection>(SortDirection.DESC);
  const [excludeApplicationIdsFilter, setExcludeApplicationIdsFilter] = useState([]);
  const [status, setStatus] = useState<TSelectedUserLabel>(ThreadLabelType.TODO);
  const [allThreads, setAllThreads] = useState<IThread[]>([]);
  const [isAllFolderOpen, setIsAllFolderOpen] = useState(true);
  const [resetFilter, setResetFilter] = useState<boolean>(false);
  const searchFilter = useReactiveVar(searchFilterReactiveVars);
  const isInboxSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);

  // selected thread
  const [selectedThread, setSelectedThread] = useState<IThread>(null);

  const [noMoreThreads, setNoMoreThreads] = useState(false);
  const filter = useMemo(
    () => ({
      assignees: assigneeType === 'you' ? [authUser.sub] : undefined,
      sort: sortDir,
      community: selectedCommunityId,
      excludeApplicationIds: excludeApplicationIdsFilter,
      isRead: false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authUser.sub, assigneeType, sortDir, status, selectedCommunityId, excludeApplicationIdsFilter],
  );

  // counts for resources
  const {
    activeMessageResources,
    sharedMessageResources,
    individualMessageResources,
  } = useResourceContext();

  const {
    data: {
      instances: applicationInstances = [],
    } = {},
  } = useInstalledApplicationInstances({
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      applications = [],
    } = {},
  } = useGetApplicationsByIds(applicationInstances ? map(applicationInstances, 'applicationId') : [], {
    fetchPolicy: 'network-only',
  });

  const nonAppNotificationIds = useMemo(
    () =>
      applications.filter((application) => messageThreadTypes.includes(application.name)).map((application) => application.id),
    [applications],
  );

  const appNotificationIds = useMemo(
    () =>
      applications.filter((application) => (
        application.visibility.showOnMember || application.visibility.showOnNav)
        && !messageThreadTypes.includes(application.name)).map((application) => application.id),
    [applications],
  );

  // counts for you
  const {
    count: userThreadsCount,
    refetch: refetchUserThreadsCount,
  } = useGetAllThreadsCount({
    assignees: [authUser.sub],
    community: selectedCommunityId,
    userLabel: ThreadLabelType.TODO,
    applicationIds: nonAppNotificationIds,
    isContact: true,
  });

  const sharedMessageExternalDisplayIds = map(sharedMessageResources, (resource) => resource.authProvider.userExternalDisplayId);
  // Counts for shared emails
  const { refetch: refetchSharedEmailsThreadsCounts, result: sharedThreadsCounts } = useGetAllResourceThreadsCount({
    variables: {
      filter: {
        ...filter,
        assignees: undefined,
        userLabel: undefined,
        resourceIds: map(
          sharedMessageResources,
          (resource) => (resource.authProvider.isShared && !resource.isAdmin ? resource.adminResourceId : resource.id),
        ),
        applicationIds: nonAppNotificationIds,
        excludeApplicationIds: [],
        isContact: true,
        messageFilter: {},
      },
    },
  });

  // Counts for individual emails
  const { refetch: refetchIndividualEmailsThreadsCounts, result: individualThreadsCounts } = useGetAllResourceThreadsCount({
    variables: {
      filter: {
        ...filter,
        assignees: [authUser.sub],
        resourceIds: map(
          individualMessageResources,
          (resource) => (resource.authProvider.isShared && !resource.isAdmin ? resource.adminResourceId : resource.id),
        ),
        applicationIds: nonAppNotificationIds,
        excludeApplicationIds: [],
        isContact: true,
        messageFilter: {},
      },
    },
  });

  const threadCountsByResourceId = useMemo<Record<number, number>>(() => {
    const threadCountsById = reduce(
      [...(sharedThreadsCounts || []), ...(individualThreadsCounts || [])],
      (countsDict, resourceCount) => ({
        ...countsDict,
        [resourceCount.resourceId]: resourceCount.count,
      }),
      {},
    );

    return reduce(
      activeMessageResources,
      (countsDict, resource) => {
        if (!resource.isAdmin && resource.authProvider.isShared) {
          return {
            ...countsDict,
            [resource.id]: threadCountsById[resource.adminResourceId],
          };
        }

        return {
          ...countsDict,
          [resource.id]: threadCountsById[resource.id],
        };
      },
      {},
    );
  }, [sharedThreadsCounts, individualThreadsCounts, activeMessageResources]);

  // counts for shared accounts
  const sharedThreadsCount = reduce(
    sharedMessageResources,
    (total, resource) => total + threadCountsByResourceId[resource.id],
    0,
  );

  const refetchThreadsCount = () => {
    if (assigneeType === 'you' || assigneeType === 'sent') {
      refetchUserThreadsCount();
      refetchSharedEmailsThreadsCounts();
      refetchIndividualEmailsThreadsCounts();
    } else if (assigneeType === 'all') {
      if (sharedMessageExternalDisplayIds.includes(resourceEmail)) {
        refetchSharedEmailsThreadsCounts();
      } else {
        refetchIndividualEmailsThreadsCounts();
      }
      refetchUserThreadsCount();
    }
  };

  const {
    loading: loadingThreads,
    error: loadThreadsError,
    refetch,
  } = useFetchThreads();

  // TODO RL this function fetches the 20 threads with skip as the offset
  // setting fetchAllPreviousThreads to true will fetch all the previous threads
  // 20 count at a time, we might want to refactor this later on
  const refetchAndUpdateThreads = async (skip: number = 0, limit: number = 20, shouldUseFullSearch: boolean = true) => {
    const updateThreads = (threads) => {
      if (size(threads) < THREAD_COUNT_PAGE_PAGE) {
        setNoMoreThreads(true);
      }
      if (skip === 0) {
        setAllThreads(threads);
      } else {
        setAllThreads([
          ...allThreads,
          ...threads,
        ]);
      }

      setResetFilter(false);
    };

    const threads = await refetch({
      filter: {
        ...filter,
        userLabel: status === 'ALL' || assigneeType !== 'you' ? undefined : status,
        resourceIds: resourceId ? [resourceId] : undefined,
        applicationIds: getApplicationIds({
          searchFilter,
          assigneeType,
          appNotificationIds,
          nonAppNotificationIds,
          applications,
        }),
        excludeApplicationIds: excludeApplicationIdsFilter,
        assignees: getAssignees({
          searchFilter,
          assigneeType,
          authUserId: authUser.sub,
        }),
        isContact: true,
        messageFilter: getMessageFilter({
            assigneeType,
            activeMessageResources,
            isInboxSearchFilter,
            searchFilter,
            resourceEmail,
            shouldUseFullSearch,
        }),
        isRead: null,
      },
      includeReadStatus: true,
      skip,
      limit,
    });
    updateThreads(threads);
  };

  const loadMoreThreads = () => {
    if (loadingThreads || noMoreThreads) {
      return;
    }

    refetchAndUpdateThreads(size(allThreads));
    isSelectAllThreadsVar(false);
  };
  const removeThread = (id: string) => {
    const threadIndex = findIndex(allThreads, (t) => t.id === id);

    setAllThreads([
      ...slice(allThreads, 0, threadIndex),
      ...slice(allThreads, threadIndex + 1),
    ]);
  };
  const resetMessagingState = () => {
    if (!isEmpty(nonAppNotificationIds) && !isEmpty(appNotificationIds) && !isNil(assigneeType)) {
      setAllThreads([]);
      setNoMoreThreads(false);
      refetchAndUpdateThreads();
      refetchUserThreadsCount();
      refetchSharedEmailsThreadsCounts();
      refetchIndividualEmailsThreadsCounts();
    }
  };

  // fetch threads count on mount
  useEffect(() => {
    if (!isEmpty(nonAppNotificationIds) && !isEmpty(appNotificationIds) && !isNil(assigneeType)) {
      setAllThreads([]);
      setNoMoreThreads(false);
      refetchAndUpdateThreads();
      refetchUserThreadsCount();
      refetchSharedEmailsThreadsCounts();
      refetchIndividualEmailsThreadsCounts();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, resourceId, nonAppNotificationIds, appNotificationIds]);

  useEffect(() => {
    if (resetFilter) {
      refetchAndUpdateThreads();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilter]);

  const goToNextThread = () => {
    const remainingThreads = _filter(allThreads, (thread) => thread.id !== selectedThread?.id);
    if (isEmpty(remainingThreads)) {
      history.push({ ...location, pathname: '/messages' });

      return;
    }

    const selectedThreadIndex = findIndex(allThreads, (thread) => thread.id === selectedThread?.id);
    const nextThread = selectedThreadIndex < allThreads.length - 1
      ? allThreads[selectedThreadIndex + 1] : allThreads[selectedThreadIndex - 1];

    history.push({ ...location, pathname: `/messages/${nextThread.id}` });
  };

  return (
    <MessagingContext.Provider
      value={{
        assigneeType,
        currentFolderLabel,
        setAssigneeType,
        setCurrentFolderLabel,
        resourceId,
        setResourceId,
        resourceEmail,
        setResourceEmail,
        setResetFilter,
        sortDir,
        setSortDir,
        setExcludeApplicationIdsFilter,
        excludeApplicationIdsFilter,
        status,
        setStatus,
        filter,
        applications,
        selectedThread,
        setSelectedThread,
        isAllFolderOpen,
        setIsAllFolderOpen,

        userThreadsCount,
        threadCountsByResourceId,
        sharedThreadsCount,
        threads: allThreads,
        loadingThreads,
        loadThreadsError,

        removeThread,
        loadMoreThreads,
        refetchAndUpdateThreads,
        resetMessagingState,
        refetchThreadsCount,
        goToNextThread,
        setNoMoreThreads,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
};
