import {
  Notice, Button,
} from '@components';
import * as React from 'react';
import cx from 'classnames';
import {
  uniq, flatMap, size,
  map, find, each,
  sortBy, filter, isEmpty,
} from 'lodash';

import {
  EmailComposer,
  EmailComposerModal,
  IPreviewConfig,
  IEmailComposer,
} from '@frontend/app/components';
import { formatMessageDate } from '@frontend/app/utils';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';

import { useResourceContext } from '@frontend/app/context';
import { useClientFeatureEnabled, useApplicationNavigation, IMember } from '@frontend/app/hooks';
import { GetThreadQuery_thread as IThread } from '@frontend/app/queries/types/GetThreadQuery';
import {
  GMAIL_APP_ID,
} from '@frontend/app/constants/applicationIds';
import { ReplyAllIcon, ReplyIcon } from '@revfluence/fresh-icons/regular/esm';
import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import { ClientFeature } from '@frontend/app/constants';
import { useExcludeMemberIds } from '@frontend/app/components/MessageComposer/useExcludeMemberIds';
import { ThreadHeader } from './ThreadHeader';
import { EmailMessageItem } from './EmailMessageItem';
import { ApplicationDropDown } from '../../Application/ApplicationsDropDown';
import styles from './EmailMessageList.scss';

interface IProps {
  thread: IThread;
  refetch(): void;
  refetchAndUpdateThreads: () => void;
  refetchThreadsCount: () => void;
  hideAdditionalActions: boolean;
  className?: string;
  onRequestClose?(): void;
  onBackButtonClicked?: () => void;
  onToggleMessageCollapsed?: (isCollapsed: boolean, messageId: string) => void;
  users: GetAllUsersQuery_users[];
  onOpenOverview: () => void;
  setSelectedMemberId: (memberId: number) => void;
  fetchMembers?: () => void;
}

const {
  useMemo,
  useRef,
  useEffect,
  useState,
} = React;

/**
 * @type {React.FunctionComponent}
 */
export const EmailMessageList: React.FunctionComponent<IProps> = React.memo((props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, [ref]);
  const {
    thread,
    refetch,
    hideAdditionalActions,
    onRequestClose,
    refetchAndUpdateThreads,
    refetchThreadsCount,
  } = props;
  const { emailResources, activeEmailResources = [], addAccount } = useResourceContext();
  const messageFormRef = useRef<IEmailComposer>(null);
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);
  const { excludeMemberIds, onExcludeMember } = useExcludeMemberIds();
  const [receipients, setReceipients] = useState<IMember[]>([]);

  const isInboxCcEnabled = useClientFeatureEnabled(ClientFeature.INBOX_CC);
  const isWorkflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  const {
    goTo: navigateTo,
  } = useApplicationNavigation();

  const [isReplyComposerOpen, setIsReplyComposerOpen] = useState<boolean>(false);
  const [isReplyAll, setIsReplyAll] = useState<boolean>(false);
  const hasCCedMembers = useMemo(() => !isEmpty(thread?.lastMessage?.payload?.cc) && isInboxCcEnabled === true,
  [thread, isInboxCcEnabled]);
  const resource = useMemo(() => {
    const emails = uniq(flatMap(map(thread && thread.messages, (message) => [
        message.payload.from,
        ...message.payload.to,
      ])));

    return find(emailResources, (resource) => emails.includes(resource.authProvider.userExternalDisplayId));
  }, [thread, emailResources]);
  const members = useMemo(() => {
    if (thread) {
      const threadMembers = thread.members;
      const lastMessage = thread.lastMessage;
      const allEmails = isReplyAll && hasCCedMembers
        ? [
          lastMessage.payload.from,
          ...lastMessage.payload.to,
          ...lastMessage.payload.cc,
        ]
        : [
          lastMessage.payload.from,
          ...lastMessage.payload.to,
        ];
      const replyMembers = [];
      const emails = filter(allEmails,
        (email) => !resource || email !== resource.authProvider.userExternalDisplayId);
      each(emails, (email, index) => {
        const threadMember = find(threadMembers, (member) => member.email === email);
        if (threadMember) {
          replyMembers.push({
            ...threadMember,
          });
        } else {
          replyMembers.push({
            email,
            name: email,
            id: index,
            profilePicture: null,
            fields: [],
            isImportant: false,
            __typename: 'Member',
          });
        }
      });

      return replyMembers;
    }

    return [];
  }, [thread, resource, isReplyAll, hasCCedMembers]);

  const goToSettings = () => {
    navigateTo(GMAIL_APP_ID, '/settings');
  };
  const onReplyClick = () => {
    setIsReplyAll(false);
    setIsReplyComposerOpen(true);
  };
  const onReplyAllClick = () => {
    setIsReplyAll(true);
    setIsReplyComposerOpen(true);
  };
  const onMessageSent = () => {
    refetch();
    refetchAndUpdateThreads();
    refetchThreadsCount();
    setIsReplyComposerOpen(false);
    props?.fetchMembers?.();
  };

  const replyThreadEnabled = useMemo(() => !isEmpty(resource), [resource]);
  const systemRevokedAccess = useMemo(() => resource?.authProvider.systemRevokedAccess, [resource]);
  const userRevokedAccess = useMemo(() => resource?.authProvider.userRevokedAccess, [resource]);
  const resourceEnabled = useMemo(() => !systemRevokedAccess && !userRevokedAccess, [systemRevokedAccess, userRevokedAccess]);

  const memberCount = useMemo(
    () => {
      if (size(receipients) > 0) return size(receipients);

      if (size(members) > 0) return size(members) - size(excludeMemberIds);

      return 0;
    },
    [members, excludeMemberIds, receipients],
  );

  const { filteredMembers, filteredMemberQuery } = useMemo(() => {
    let filteredMembers = undefined;
    let filteredMemberQuery;
    if (members) {
      filteredMembers = members.filter((m) => !excludeMemberIds.includes(m.id) && m.email);
    }

    return {
      filteredMembers,
      filteredMemberQuery,
    };
  }, [excludeMemberIds, members]);

  return (
    <div className={cx(styles.EmailMessageList, props.className)}>
      <ThreadHeader
        onThreadStatusChange={refetch}
        thread={thread}
        className={styles.threadHeader}
        onBackButtonClicked={props.onBackButtonClicked}
        onOpenOverview={props.onOpenOverview}
        setSelectedMemberId={props.setSelectedMemberId}
      />
      <div className={styles.list}>
        {thread && map(sortBy(thread.messages, 'internalDate'), (message) => (
          <EmailMessageItem
            key={message.id}
            className={styles.item}
            resourceType={resource?.type}
            members={thread.members}
            message={message}
            onToggleMessageCollapsed={props.onToggleMessageCollapsed}
            users={props.users}
          />
          ))}
        <div ref={ref} />
        {!replyThreadEnabled && !isEmpty(activeEmailResources) && (
          <Notice type="info" className={styles.infoNotice}>
            <div className={styles.noticeContent}>
              <div className={styles.title}>
                Continue the conversation
              </div>
              <div className={styles.description}>
                In order to communicate with everyone in this conversation, you’ll need to compose a new message. We’ll automatically include them in your new message.
              </div>
              <EmailComposerModal
                isWorkflowEnabled={isWorkflowEnabled}
                label="Compose"
                members={members}
                subject={thread?.lastMessage?.subject}
                source="joinconversationerror"
                onMessageSent={onRequestClose}
                enableExpandingEmailEditor
              />
            </div>
          </Notice>
        )}
        {replyThreadEnabled && resourceEnabled && isReplyComposerOpen && (
          <>
            <EmailComposer
              className={cx(styles.createMessageForm, {
                [styles.hide]: !!previewConfig,
              })}
              ref={messageFormRef}
              threadId={thread.id}
              messageId={thread.lastMessage?.externalMessageId}
              internalMessageId={thread.lastMessage?.payload.internalMessageId}
              resourceId={resource?.id}
              subject={thread.lastMessage?.subject}
              members={filteredMembers}
              onChangeReplyAll={(isReplyAll) => (isReplyAll ? onReplyAllClick() : onReplyClick())}
              canReplyAll={hasCCedMembers}
              isReplyAll={isReplyAll}
              onMessageSent={onMessageSent}
              replaceTemplateVariables
              onMembersChange={setReceipients}
              memberQuery={filteredMemberQuery}
              onPreview={setPreviewConfig}
              enableExpandingEmailEditor
            />
            {previewConfig && (
              <EmailPreviewer
                className={styles.createMessageForm}
                excludeMemberIds={excludeMemberIds}
                onExcludeMember={onExcludeMember}
                previewConfig={previewConfig}
                onBack={() => setPreviewConfig(null)}
                memberCount={memberCount}
                memberQuery={filteredMemberQuery}
                onConfirm={() => {
                  setPreviewConfig(null);
                  messageFormRef.current.sendMessage(true);
                }}
              />
            )}
          </>
        )}
        {replyThreadEnabled && resourceEnabled && !isReplyComposerOpen && (
          <>
            <Button
              className={styles.replyButton}
              onClick={onReplyClick}
              theme="light"
              label="Reply"
              icon={<ReplyIcon />}
            />
            {hasCCedMembers && (
            <Button
              onClick={onReplyAllClick}
              theme="light"
              label="Reply All"
              icon={<ReplyAllIcon />}
            />
)}
          </>
        )}
        {(thread?.readStatus?.lastReadByUserName && thread?.readStatus?.lastReadByDate) && (
          <div className={cx(styles.lastRead)}>
            <p>
              <span>Last read by:</span>
              {' '}
              {thread.readStatus.lastReadByUserName}
              {' '}
              on
              {' '}
              {formatMessageDate(new Date(thread.readStatus.lastReadByDate))}
            </p>
          </div>
        )}
        {systemRevokedAccess && (
          <Notice type="error" className={styles.errorNotice}>
            <div className={styles.noticeContent}>
              <div className={styles.title}>
                Your email account is not connected
              </div>
              <div className={styles.text}>
                There is a problem with
                {' '}
                {resource.authProvider.userExternalDisplayId}
              </div>
              <div className={styles.actions}>
                <Button
                  theme="light"
                  label="Reconnect"
                  className={cx(styles.button, styles.reconnect)}
                  round={false}
                  fullWidth={false}
                  onMouseUp={() => addAccount(resource?.type, resource?.authProvider.isShared)}
                />
                <Button
                  theme="light"
                  label="Settings"
                  className={styles.button}
                  round={false}
                  fullWidth={false}
                  onMouseUp={goToSettings}
                />
              </div>
            </div>
          </Notice>
        )}
        {!systemRevokedAccess && userRevokedAccess && (
          <Notice type="error" className={styles.errorNotice}>
            <div className={styles.noticeContent}>
              <div className={styles.title}>
                Your email account is not connected
              </div>
              <div className={styles.text}>
                This message was originally synced from
                {' '}
                {resource.authProvider.userExternalDisplayId}
                .
                In order to reply to this message, please reauthorize
                {' '}
                {resource.authProvider.userExternalDisplayId}
                .
              </div>
              <div className={styles.actions}>
                <Button
                  theme="light"
                  label="Reconnect"
                  className={cx(styles.button, styles.reconnect)}
                  round={false}
                  fullWidth={false}
                  onMouseUp={() => addAccount(resource?.type, resource?.authProvider.isShared)}
                />
                <Button
                  theme="light"
                  label="Settings"
                  className={styles.button}
                  round={false}
                  fullWidth={false}
                  onMouseUp={goToSettings}
                />
              </div>
            </div>
          </Notice>
        )}
        {size(thread?.members) === 1 && !hideAdditionalActions && replyThreadEnabled && (
          <div className={styles.followUp}>
            <div className={styles.title}>
              Need to take additional action?
            </div>
            <div className={styles.replyOptions}>
              <EmailComposerModal
                isWorkflowEnabled={isWorkflowEnabled}
                label="New Thread"
                members={thread && thread.members ? [thread.members[0]] : []}
                source="emailmessageList"
              />
              <ApplicationDropDown
                className={styles.otherApps}
                isWorkflowEnabled={isWorkflowEnabled}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

EmailMessageList.displayName = 'EmailMessageList';
