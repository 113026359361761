import * as React from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

import styles from './Skeleton.scss';
import styleVariables from '../../styles/_variables.scss';

export interface ILoadingProps {
  className?: string;
  /**
   * Shape the border radius to its parent component
   * @default 0
   */
  borderRadius?: number; // TODO: Detect parent's border radius?
  /**
   * Round corners -- uses max border-radius value
   * @default false
   */
  rounded?: boolean;
  /**
   * Show loading with animation
   * @default false
   */
  show?: boolean; // TODO: Disable animation via flag
  /**
   * Loading theme
   * * `light` on a component or a dark background
   * * `grey` on a light background
   * @default 'light'
   */
  theme?: 'blue' | 'light' | 'grey';
}

/**
 * Use motion to convey that the page is not stuck
 * and that data is being loaded.
 *
 * **NOTE: Parent element must be `position: relative;`**
 */
export const Loading: React.FC<ILoadingProps> = React.memo((props: ILoadingProps) => {
  const {
    borderRadius,
    className,
    rounded,
    show,
    theme,
  } = props;

  return (
    <CSSTransition
      in={show}
      classNames={{
        enter: styles['Loading-enter'],
        enterActive: styles['Loading-enter-active'],
        exit: styles['Loading-exit'],
        exitActive: styles['Loading-exit-active'],
      }}
      timeout={250} // $transition-duration: 0.25s
      unmountOnExit
    >
      <div
        className={cx(className, styles.Loading, styles[theme])}
        style={{
          borderRadius: rounded ? styleVariables.borderRadiusMax : `${borderRadius}px`,
        }}
      />
    </CSSTransition>
  );
});
Loading.defaultProps = {
  borderRadius: 0,
  rounded: false,
  show: false,
  theme: 'light',
};
Loading.displayName = 'Loading';
