import React from 'react';
import { cn } from '@/shadcn/lib/utils';

interface H4Props extends React.ComponentPropsWithoutRef<'h4'> {
  fontWeight?: 'font-normal' | 'font-medium' | 'font-semibold' | 'font-bold'; // Optional font weight prop
}

export const H4 = React.forwardRef<HTMLHeadingElement, H4Props>(
  ({
    className, fontWeight, children, ...props
  }, ref) => {
    // Default classes for H4
    const defaultClasses = 'text-base leading-relaxed tracking-tight scroll-m-20';

    // Determine the appropriate font weight class, default to 'font-medium'
    const fontWeightClass = fontWeight || 'font-medium';

    return (
      <h4
        ref={ref}
        className={cn(defaultClasses, fontWeightClass, className)} // Combine default classes, font weight, and additional class names
        {...props}
      >
        {children}
      </h4>
    );
  },
);

H4.displayName = 'H4';
