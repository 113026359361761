/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1857l_157 {
  display: flex;
}

._justify-content-space-between_1857l_161 {
  justify-content: space-between;
}

._tabular-nums_1857l_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1857l_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ListItem_1857l_178 ._variable_1857l_178 ._text_1857l_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ListItem_1857l_178 ._remove_1857l_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._ListItem_1857l_178 ._email_1857l_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ListItem_1857l_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  padding: 0 1.875rem 0 1.5rem;
  border-top: 0.0625rem solid #dadcde;
}
._ListItem_1857l_178 ._variable_1857l_178 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  width: 11.25rem;
  height: 2.25rem;
  margin-right: 2rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  background: #eff5f9;
}
._ListItem_1857l_178 ._email_1857l_198 {
  flex: 1;
  margin-right: 2rem;
  color: #8f8d91;
}
._ListItem_1857l_178 ._remove_1857l_188 {
  flex-shrink: 0;
  margin-left: auto;
  color: #f1515f;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}