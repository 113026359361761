import * as React from 'react';
import cx from 'classnames';

import { useMobileStatus } from '@frontend/utils';
import { SubmitButton } from '@components';

import { IModalProps, Modal } from './Modal';

import styles from './SubmitModal.scss';

type TTheme = 'primary' | 'danger';
interface IProps extends IModalProps {
  onSubmit();
  submitLabel?: string;
  submittingLabel?: string;
  theme?: TTheme;
  classNames?: string[];
  isSubmitting?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const SubmitModal: React.FC<Readonly<IProps>> = (props) => {
  const mobileType = useMobileStatus();

  const {
    theme,
    submitLabel,
    onRequestClose,
    onSubmit,
    width,
    classNames,
    isSubmitting,
    submittingLabel,
    ...restProps
  } = props;

  return (
    <Modal
      className={cx(classNames, styles.SubmitModal)}
      onRequestClose={onRequestClose}
      footer={(
        <SubmitButton
          label={submitLabel}
          submittingLabel={submittingLabel}
          theme={theme}
          isSubmitting={isSubmitting}
          className={cx(styles.button, styles.submit)}
          onClick={onSubmit}
        />
      )}
      {...restProps}
      width={mobileType ? null : width}
    />
  );
};

SubmitModal.defaultProps = {
  theme: 'primary',
  submitLabel: 'Yes',
  classNames: [],
};
SubmitModal.displayName = 'SubmitModal';
