import { useMutation, MutationHookOptions } from '@apollo/client';
import { isFunction } from 'lodash';

import { message } from 'antd';

import { REMOVE_USER_MUTATION } from '@frontend/app/queries';
import {
  RemoveUserMutation,
  RemoveUserMutationVariables,
} from '@frontend/app/queries/types/RemoveUserMutation';

type IOptions = MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>;

export const useRemoveUser = (options: IOptions = {}) => {
  const [removeUser, { loading, error }] = useMutation<
    RemoveUserMutation,
    RemoveUserMutationVariables
  >(REMOVE_USER_MUTATION, {
    ...options,
    onCompleted(data: RemoveUserMutation) {
      if (isFunction(options.onCompleted)) {
        options.onCompleted(data);
      }

      message.info('User has been removed.');
    },
  });

  return {
    removeUser,
    loading,
    error,
  };
};
