import {
  size,
  keyBy,
  constant,
  mapValues,
  map,
  chain,
  indexOf,
  concat,
  differenceBy,
  flatten,
  keys,
} from 'lodash';
import { IStore } from './models';
import { IContentPageAction, ActionTypes } from './actions';

const reducer = (state: IStore, action: IContentPageAction): IStore => {
  switch (action.type) {
    case ActionTypes.CHANGE_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };

    case ActionTypes.CHANGE_SORT:
      return {
        ...state,
        sort: action.payload.sort,
      };

    case ActionTypes.TOGGLE_CONTENT_DETAIL:
      return {
        ...state,
        showContentDetail: !state.showContentDetail,
        contentDetailIndex: action.payload.contentIndex,
      };

    case ActionTypes.FETCH_CAMPAIGNS_SUCCESS: {
      const page = action.meta.page;
      const campaignsByPage = page < 2 ? {} : { ...state.__campaignsByPage };
      campaignsByPage[page] = action.payload.campaigns;
      return {
        ...state,
        campaigns: flatten(
          keys(campaignsByPage)
            .sort()
            .map((i) => campaignsByPage[i]),
        ),
        __campaignsByPage: campaignsByPage,
      };
    }

    case ActionTypes.FETCH_CONTENT_REQUEST: {
      if (action.meta.cursor) {
        return {
          ...state,
          isLoading: true,
          errorMessage: null,
          _xhr: action.meta.xhr,
        };
      } else {
        return {
          ...state,
          content: [],
          selectedContent: {},
          isAllSelected: false,
          isLoading: true,
          errorMessage: null,
          _xhr: action.meta.xhr,
        };
      }
    }

    case ActionTypes.FETCH_CONTENT_SUCCESS: {
      const { previousCursor } = action.meta;
      let { content } = action.payload;
      const likedContentListId = state.activeBrand ? state.activeBrand.liked_content_list_id : null;
      if (previousCursor && previousCursor === state.cursor) {
        content = concat(state.content, content);
      }
      return {
        ...state,
        content,
        isLoading: false,
        hasNext: action.meta.hasNext,
        cursor: action.meta.cursor,
        totalCount: action.meta.totalCount,
        _xhr: null,
        likedContent: chain(action.payload.content)
          .keyBy('id')
          .mapValues((content) => indexOf(content.content_list_ids, likedContentListId) >= 0)
          .value(),
      };
    }

    case ActionTypes.FETCH_CONTENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.meta.errorMessage,
        _xhr: null,
      };

    case ActionTypes.SAVE_MULTI_CONTENT_REQUEST: {
      const indexed = keyBy(action.payload.content, 'id');
      return {
        ...state,
        showSpinner: true,
        content: map(state.content, (c) => indexed[c.id] || c),
        saveContentErrorMessage: null,
      };
    }

    case ActionTypes.SAVE_MULTI_CONTENT_FAILURE: {
      const indexed = keyBy(action.payload.content, 'id');
      return {
        ...state,
        showSpinner: false,
        saveContentErrorMessage: action.meta.errorMessage || 'Error',
        // Revert all changes
        content: map(state.content, (c) => indexed[c.id] || c),
      };
    }

    case ActionTypes.SAVE_MULTI_CONTENT_SUCCESS:
      return {
        ...state,
        showSpinner: false,
      };

    case ActionTypes.SELECT_CONTENT: {
      const { content, selectedContent } = state;
      const { contentId, selected } = action.payload;
      const newSelectedContent = { ...selectedContent };

      if (selected) {
        newSelectedContent[contentId] = true;
      } else {
        delete newSelectedContent[contentId];
      }

      return {
        ...state,
        selectedContent: newSelectedContent,
        isAllSelected: size(newSelectedContent) === size(content),
      };
    }

    case ActionTypes.TOGGLE_SELECT_ALL_CONTENT: {
      if (state.isAllSelected) {
        return {
          ...state,
          selectedContent: {},
          isAllSelected: false,
        };
      } else {
        return {
          ...state,
          selectedContent: mapValues(keyBy(state.content, 'id'), constant(true)),
          isAllSelected: true,
        };
      }
    }

    case ActionTypes.TOGGLE_LIKED_CONTENT: {
      const { likedContent } = state;
      const { contentId } = action.payload;
      const newLikedContent = { ...likedContent };
      newLikedContent[contentId] = !newLikedContent[contentId];
      return {
        ...state,
        likedContent: newLikedContent,
        toast: newLikedContent[contentId]
          ? {
            type: 'info',
            content: 'Note: Liked images will show up in content library and image search.',
          }
          : null,
      };
    }

    case ActionTypes.REMOVE_TAG_SUCCESS:
      return {
        ...state,
        toast: {
          type: 'success',
          content: `Removed Tag "${action.payload.tag}"`,
        },
      };

    case ActionTypes.UPDATE_TAGS_SUCCESS: {
      const n = size(action.payload.content);
      return {
        ...state,
        toast: {
          type: 'success',
          content: `Updated Tags for ${n} Content${n > 1 ? 's' : ''}`,
        },
      };
    }

    case ActionTypes.UPDATE_PERMISSIONS_SUCCESS: {
      const n = size(action.payload.content);
      return {
        ...state,
        toast: {
          type: 'success',
          content: `Updated Permissions for ${n} Content${n > 1 ? 's' : ''}`,
        },
      };
    }

    case ActionTypes.CREATE_CONTENT_SUCCESS:
      return {
        ...state,
        content: concat(action.payload.content, state.content),
        toast: {
          type: 'success',
          content: `Uploaded ${size(action.payload.content)} Content`,
        },
      };

    case ActionTypes.DELETE_CONTENT_SUCCESS: {
      const count = size(action.payload.content);
      return {
        ...state,
        content: differenceBy(state.content, action.payload.content, 'id'),
        selectedContent: {},
        isAllSelected: false,
        toast: {
          type: 'success',
          content: `Deleted ${count} piece${count > 1 ? 's' : ''} of content`,
        },
      };
    }

    case ActionTypes.DOWNLOAD_CONTENT_REQUEST:
      return {
        ...state,
        showSpinner: true,
      };

    case ActionTypes.DOWNLOAD_CONTENT_SUCCESS:
      return {
        ...state,
        showSpinner: false,
        toast: {
          type: 'success',
          content: `Downloaded ${size(action.payload.content)} Content`,
        },
      };

    case ActionTypes.DOWNLOAD_CONTENT_FAILURE:
      return {
        ...state,
        showSpinner: false,
        toast: {
          type: 'success',
          content: action.meta.errorMessage,
        },
      };

    case ActionTypes.REMOVE_TAG_FAILURE:
    case ActionTypes.UPDATE_TAGS_FAILURE:
    case ActionTypes.UPDATE_PERMISSIONS_FAILURE:
    case ActionTypes.DELETE_CONTENT_FAILURE:
    case ActionTypes.CREATE_CONTENT_FAILURE:
      return {
        ...state,
        showSpinner: false,
        toast: {
          type: 'error',
          content: action.meta.errorMessage,
        },
      };

    default:
      return state;
  }
};

export default reducer;
