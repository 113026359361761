import 'css-chunk:src/components/widgets/SocialProfile/Content/Content.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_3aj29_157",
  "justify-content-space-between": "_justify-content-space-between_3aj29_161",
  "tabular-nums": "_tabular-nums_3aj29_165",
  "Content": "_Content_3aj29_169",
  "summary": "_summary_3aj29_211",
  "analyze": "_analyze_3aj29_212",
  "show": "_show_3aj29_1",
  "blog": "_blog_3aj29_219",
  "facebook": "_facebook_3aj29_219",
  "twitter": "_twitter_3aj29_219",
  "ProfileCard": "_ProfileCard_3aj29_223",
  "SummaryCard": "_SummaryCard_3aj29_227",
  "instagram": "_instagram_3aj29_236",
  "EngagementRateCard": "_EngagementRateCard_3aj29_244",
  "GenderCard": "_GenderCard_3aj29_256",
  "AgeCard": "_AgeCard_3aj29_260",
  "CountryCard": "_CountryCard_3aj29_264",
  "CityCard": "_CityCard_3aj29_268",
  "PostPerformanceCard": "_PostPerformanceCard_3aj29_272",
  "GrowthCard": "_GrowthCard_3aj29_276",
  "pinterest": "_pinterest_3aj29_280",
  "youtube": "_youtube_3aj29_296",
  "detailList": "_detailList_3aj29_308",
  "HashtagsCard": "_HashtagsCard_3aj29_311",
  "TopCategoriesCard": "_TopCategoriesCard_3aj29_315",
  "TopPlaylistsCard": "_TopPlaylistsCard_3aj29_319",
  "items": "_items_3aj29_327",
  "item": "_item_3aj29_327",
  "StateCard": "_StateCard_3aj29_354",
  "tiktok": "_tiktok_3aj29_358"
};