import React from 'react';

interface ComponentItem {
  content: React.ReactNode;
  columns: number;
}

interface DashboardLayoutProps {
  components: ComponentItem[];
  gap: string;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ components, gap }) => {
  const getColumnClass = (columns: number) => {
    switch (columns) {
      case 1:
        return 'md:col-span-1';
      case 2:
        return 'md:col-span-2';
      case 3:
        return 'md:col-span-3';
      case 4:
        return 'md:col-span-4';
      case 5:
        return 'md:col-span-5';
      case 6:
        return 'md:col-span-6';
      case 7:
        return 'md:col-span-7';
      case 8:
        return 'md:col-span-8';
      case 9:
        return 'md:col-span-9';
      case 10:
        return 'md:col-span-10';
      case 11:
        return 'md:col-span-11';
      case 12:
        return 'md:col-span-12';
      default:
        return 'md:col-span-12';
    }
  };

  return (
    <div className={`grid md:grid-cols-12 gap-${gap}`}>
      {components.map((item, index) => (
        <div className={getColumnClass(item.columns)} key={index}>
          <div className="h-full">{item.content}</div>
        </div>
      ))}
    </div>
  );
};

export default DashboardLayout;
