import * as React from 'react';
import {
  Select,
  Typography,
} from '@revfluence/fresh';
import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';

const { Option } = Select;
const { useCallback } = React;

interface IProps {
  contentGuidelines: Array<TContentGuideline>;
  contentGuideline: TContentGuideline;
  onUpdateContentGuideline: (contentGuideline: TContentGuideline) => void;
}

const SelectContentGuideline: React.FC<IProps> = React.memo((props) => {
  const {
    contentGuideline,
    contentGuidelines,
    onUpdateContentGuideline,
  } = props;

  const handleChange = useCallback((id: number) => {
    const contentGuidelineSelected = contentGuidelines.find(
      (contentGuidelineItem) => contentGuidelineItem.id === id,
    );
    onUpdateContentGuideline(contentGuidelineSelected);
  }, [
    contentGuidelines,
    onUpdateContentGuideline,
  ]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Select
      value={contentGuideline?.id}
      style={{ width: 250 }}
      onChange={handleChange}
      onClick={handleClick}
    >
      {
        contentGuidelines.map((contentGuidelineItem) => (
          <Option key={contentGuidelineItem.id} value={contentGuidelineItem.id}>
            <Typography.Text strong>{ contentGuidelineItem.label }</Typography.Text>
          </Option>
          ))
      }
    </Select>
  );
});

SelectContentGuideline.displayName = 'SelectContentGuideline';

export default SelectContentGuideline;
