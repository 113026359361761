import gql from 'graphql-tag';

export const GET_TIKTOK_SPARK_ADS_RECOMENDATIONS = gql`
  query GetTikTokSparkAdsRecommendations($programId: Int!) {
    getTikTokSparkAdsRecommendations(programId: $programId) {
      recommendations {
        id
        network
        mediaId
        socialAccountName
        datetimePosted
        imageUrl
        link
        engagementRate
        profileImageUrl
        recommendationLevel
        hasSparkAdPermission
      }
      advertiserId
    }
  }
`;
