import { useQuery, QueryHookOptions } from '@apollo/client';

import { MESSAGE_TEMPLATES_QUERY } from '@frontend/app/queries';
import {
  MessageTemplatesQuery,
  MessageTemplatesQuery_templates,
} from '@frontend/app/queries/types/MessageTemplatesQuery';

type IOptions = QueryHookOptions<MessageTemplatesQuery>;
export type IMessageTemplate = MessageTemplatesQuery_templates;

export function useGetMessageTemplates(options: IOptions = {}) {
  const {
 loading, data, error, refetch,
} = useQuery(
    MESSAGE_TEMPLATES_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    loading,
    templates: data?.templates || [],
    error,
    refetch,
  };
}
