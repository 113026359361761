import { useMutation, MutationHookOptions } from '@apollo/client';

import { SAVE_CURRENT_CLIENT_MUTATION } from '@frontend/app/queries';

import {
  SaveCurrentClientMutation,
  SaveCurrentClientMutationVariables,
} from '@frontend/app/queries/types/SaveCurrentClientMutation';

type IOptions = MutationHookOptions<SaveCurrentClientMutation, SaveCurrentClientMutationVariables>;

export function useSaveCurrentClient(options: IOptions = {}) {
  const [saveClient, { loading, error }] = useMutation<
  SaveCurrentClientMutation,
  SaveCurrentClientMutationVariables
  >(SAVE_CURRENT_CLIENT_MUTATION, {
    ...options,
  });

  return {
    saveClient,
    loading,
    error,
  };
}
