import { useMutation, MutationHookOptions, ApolloCache } from '@apollo/client';
import { unionBy } from 'lodash';

import { CREATE_OFFER, GET_OFFERS_QUERY } from '@affiliates/queries';
import { CreateOffer, CreateOfferVariables } from '@affiliates/queries/types/CreateOffer';
import { GetOffers } from '@affiliates/queries/types/GetOffers';

type TOptions = MutationHookOptions<CreateOffer, CreateOfferVariables>;

export const useCreateOfferMutation = (options: TOptions = {}) => useMutation<CreateOffer, CreateOfferVariables>(CREATE_OFFER, {
    ...options,
    update: (...args) => {
      // We're overriding update, so make sure to call options.update if its defined.
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      updateCache(store, result.data);
    },
  });

// Here we update the offers cache with the newly created offer.
const updateCache = (store: ApolloCache<CreateOffer>, data: CreateOffer) => {
  // Read the data from our cache for the offers query.
  const cache = store.readQuery<GetOffers>({
    query: GET_OFFERS_QUERY,
  });

  // Do nothing. Cache doesn't exist.
  if (!cache) {
    return;
  }

  const offers = cache ? cache.offers : [];

  const newCache = {
    ...data,
    offers: unionBy(offers, [data.offer], 'id'),
  };
  // Add the offer (merge them by id).

  store.writeQuery<GetOffers>({
    query: GET_OFFERS_QUERY,
    data: newCache,
  });
};
