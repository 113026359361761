import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const ArrowheadDownIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.8766 6C3.63826 6 2.9333 7.41572 3.67957 8.40395L10.7885 17.8177C11.3879 18.6115 12.5799 18.6126 13.1808 17.8199L20.317 8.40615C21.0658 7.41828 20.3612 6 19.1216 6H4.8766Z" />
  </SvgIcon>
));

ArrowheadDownIcon.displayName = 'ArrowheadDownIcon';
