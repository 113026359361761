import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_MEMBER_RESTRICTIONS_QUERY } from '@frontend/app/queries';
import {
  GetMemberRestrictionsQuery,
  GetMemberRestrictionsQuery_restrictions,
} from '@frontend/app/queries/types/GetMemberRestrictionsQuery';

type IOptions = QueryHookOptions<GetMemberRestrictionsQuery>;
export type IMemberRestriction = GetMemberRestrictionsQuery_restrictions;

export function useGetMembersRestrictions(options: IOptions = {}) {
  const {
 loading, data: { restrictions } = {}, error, refetch,
} = useQuery<GetMemberRestrictionsQuery>(
    GET_MEMBER_RESTRICTIONS_QUERY,
    options,
  );

  return {
    loading,
    restrictions,
    error,
    refetch,
  };
}
