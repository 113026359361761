import { IBrand } from './brand';
import { IContentReview } from './contentReview';
import { IManager } from './manager';

export interface IClientContentReview {
  id: number;
  brand: IBrand;
  user: IManager;
  content_reviews: IContentReview[];
  date_created: string;
}
