import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { message } from 'antd';

import { DELETE_CONTENT_FIELD_MUTATION } from '@frontend/app/queries';
import {
  DeleteContentFieldMutation,
  DeleteContentFieldMutationVariables,
} from '@frontend/app/queries/types/DeleteContentFieldMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<DeleteContentFieldMutation, DeleteContentFieldMutationVariables>;

export function useDeleteContentField(options: IOptions = {}) {
  const [deleteContentField, { loading, error }] = useMutation<
    DeleteContentFieldMutation,
    DeleteContentFieldMutationVariables
  >(DELETE_CONTENT_FIELD_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully Deleted Content Field');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteContentField,
    loading,
    error,
  };
}
