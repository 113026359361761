import * as React from 'react';
import { isEmpty, size } from 'lodash';
import cx from 'classnames';

/** We're still using antd for these components as AUI doesn't support childs yet */
import { Checkbox, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { Tooltip } from '@revfluence/fresh';

import { IPlaceId } from '@services/backend-server';

import { ConnectedImageVideoUpload } from '@frontend/app/components';
import { useUploadProps } from '@frontend/app/containers/Projects/LandingPages/LeftPanel/hooks';
import { ITabField } from './model';

import { useListOnMarketplace } from '../../context';
import LocationField from './LocationField';

import commonStyles from '../common.scss';
import styles from './styles.scss';

const { useMemo } = React;

interface ITabFieldProps extends ITabField {}

const TabFieldComponent: React.FC<ITabFieldProps> = (props) => {
  const {
    label,
    helpText,
    isRequired,
    type,
    options,
    maxCharacters,
    name,
    placeholder,
    footerText,
  } = props;

  const {
    getFieldValue,
    setFieldValue,
    setHasBeenTouched,
    hasBeenTouched,
    projectId,
    isCampaignLoading,
  } = useListOnMarketplace();

  const uploadProps = useUploadProps(projectId);

  const handleChange = (newValue: string | string[] | IPlaceId[]) => {
    setFieldValue(name, newValue);
    setHasBeenTouched(true);
  };

  const value = getFieldValue(name, type === 'radio-group' ? [] : '');

  const renderRadioGroup = () => {
    if (!options) {
      return null;
    }

    return (
      <Checkbox.Group
        options={options}
        className={styles.radioGroupContainer}
        value={value as unknown as string[]}
        onChange={(values) => handleChange(values as string[])}
      />
    );
  };

  const charactersLeft = useMemo(() => {
    if (!maxCharacters) {
      return Infinity;
    }

    return Math.max(maxCharacters - size(value as string), 0);
  }, [maxCharacters, value]);

  const charactersCount = useMemo(() => {
    if (!maxCharacters) {
      return null;
    }

    const countLabel = `${charactersLeft} character${charactersLeft === 1 ? '' : 's'} left`;

    return (
      <div className={cx(commonStyles.formElementFooter, commonStyles.alignRight)}>
        {countLabel}
      </div>
    );
  }, [charactersLeft, maxCharacters]);

  const validatedField = useMemo(() => {
    const validatedInformation = {
      isValid: true,
      error: '',
    };

    if (
      isEmpty(value)
        && isRequired
        && !isCampaignLoading
        && hasBeenTouched
    ) {
      return {
        isValid: false,
        error: 'required',
      };
    }

    return validatedInformation;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    charactersLeft,
    isRequired,
    value,
    isCampaignLoading,
    hasBeenTouched,
  ]);

  const renderTextArea = () => (
    <>
      <Input.TextArea
        placeholder={placeholder || label}
        value={value as string}
        maxLength={maxCharacters ?? Infinity}
        onChange={(e) => handleChange(e.target.value)}
        className={cx(!validatedField.isValid && commonStyles.requiredInput)}
      />
      {charactersCount}
    </>
  );

  const renderTextInput = () => (
    <>
      <Input
        placeholder={placeholder || label}
        value={value as string}
        maxLength={maxCharacters ?? Infinity}
        onChange={(e) => handleChange(e.target.value)}
        className={cx(!validatedField.isValid && commonStyles.requiredInput)}
      />
      {charactersCount}
    </>
  );

  const renderLocationInput = () => (
    <LocationField
      value={value as IPlaceId[]}
      onChange={handleChange}
      placeholder={placeholder || label}
    />
  );

  const renderImageUpload = () => (
    <ConnectedImageVideoUpload
      className={commonStyles.thumbnailUpload}
      uploadProps={uploadProps}
      cropperProps={{
        aspectRatio: 1,
      }}
      showCropper
      hintText="The recommended image size is 1000px x 1000px"
      onUpload={handleChange}
      onRemove={() => handleChange('')}
      defaultImageSrc={value as string}
      hasError={!validatedField.isValid}
    />
  );

  const renderInput = () => {
    switch (type) {
      case 'radio-group':
        return renderRadioGroup();
      case 'textarea':
        return renderTextArea();
      case 'location':
        return renderLocationInput();
      case 'image':
        return renderImageUpload();
      default:
        return renderTextInput();
    }
  };

  return (
    <div className={cx(commonStyles.formElement, styles.container)}>
      <div className={commonStyles.formElementHead}>
        <div className={commonStyles.formElementLabel}>
          {isRequired && (
            <span className={commonStyles.requiredIndicator}>*</span>
          )}
          {label}
        </div>
        {
          helpText && (
            <div className={commonStyles.formElementHelp}>
              <Tooltip title={helpText}>
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
          )
        }
      </div>
      <div
        className={cx(
          commonStyles.inputContainer,
          {
            [styles.hasError]: !validatedField.isValid,
          },
        )}
      >
        {renderInput()}
      </div>
      {
        footerText && (
          <div className={commonStyles.formElementFooter}>
            {footerText}
          </div>
        )
      }
    </div>
  );
};

const TabField = React.memo(TabFieldComponent);

export default TabField;
