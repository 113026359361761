import * as React from 'react';
import { Tooltip, Space } from '@revfluence/fresh';
import {
  Avatar,
  TableColumnType,
  TableProps,
} from 'antd';
import { CircleInfoIcon, DollarSignIcon } from '@revfluence/fresh-icons/solid/esm';
import {
  filter,
  map,
  some,
} from 'lodash';

import { ClientFeature } from '@frontend/app/constants';
import { ContentType } from '@frontend/app/types/globalTypes';
import { useClientFeatureEnabled } from '@frontend/app/hooks';

import { ColumnKey, ContentColumnKey, IMember } from '../../types/Member';
import { IState } from '../../types/state';
import { DollarInput } from '../../utils/DollarInput';
import { OnUpdateColumnValue } from './types';
import {
  caseInsensitiveStringSort,
  getContentCountColumn,
  getSocialHandleColumn,
  isContentColumnKey,
  isSocialColumnKey,
  numericSort,
} from './utils';

import styles from './ReviewTable.scss';

const { useMemo } = React;

// the order in which the columns should appear in the table
const COLUMN_ORDER: Array<ColumnKey> = [
  'name',
  'instagram',
  'tiktok',
  'facebook',
  'pinterest',
  'snapchat',
  'twitter',
  'youtube',
  'blog',
  'instagramPosts',
  'instagramReels',
  'instagramStories',
  'instagramVideos',
  'tiktokVideos',
  'facebookPosts',
  'pinterestPins',
  'snapchatStories',
  'twitterTweets',
  'youtubeVideos',
  'youtubeProductMentions',
  'blogPosts',
  'blogPostMentions',
  'additionalImages',
  'additionalVideos',
  'customWork',
  'paymentAmount',
  'recommendedPaymentAmount',
  'comment',
];

const columnToProductMap: Record<ContentColumnKey, ContentType> = {
  additionalImages: ContentType.ADDITIONAL_IMAGES,
  additionalVideos: ContentType.ADDITIONAL_VIDEOS,
  blogPosts: ContentType.BLOG_DEDICATED,
  blogPostMentions: ContentType.BLOG_MENTION,
  customWork: ContentType.OTHER,
  facebookPosts: ContentType.FACEBOOK_POST,
  instagramPosts: ContentType.INSTAGRAM_POST,
  instagramReels: ContentType.INSTAGRAM_REEL,
  instagramStories: ContentType.INSTAGRAM_STORY,
  instagramVideos: ContentType.INSTAGRAM_VIDEO,
  pinterestPins: ContentType.PINTEREST_PIN,
  snapchatStories: ContentType.SNAPCHAT_STORY,
  tiktokVideos: ContentType.TIKTOK_VIDEO,
  twitterTweets: ContentType.TWITTER_POST,
  youtubeProductMentions: ContentType.YOUTUBE_MENTION,
  youtubeVideos: ContentType.YOUTUBE_DEDICATED,
  flexibleDeliverable: ContentType.FLEXIBLE,
};

export const useTableColumns = (
  collaborationDetails: IState['collaborationDetails'],
  contentGuidelines: IState['contentGuidelines'],
  onUpdateColumnValue: OnUpdateColumnValue,
): TableProps<IMember>['columns'] => {
  const isTermsRecommendedPrincingEnabled = useClientFeatureEnabled(ClientFeature.TERMS_RECOMMENDED_PRICING);

  // use the collaboration details and content guidelines to filter the list
  // of column keys to the ones that should be included in the table
  const enabledColumnKeys = useMemo((): Array<ColumnKey> => (
    filter(COLUMN_ORDER, (key: ColumnKey): boolean => {
      if (isContentColumnKey(key)) {
        return some(contentGuidelines, ({ type }) => (
          type === columnToProductMap[key]
        ));
      }
      switch (key) {
        case 'blog':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.blogPosts
            || type === columnToProductMap.blogPostMentions
          ));
        case 'facebook':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.facebookPosts
          ));
        case 'instagram':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.instagramPosts
            || type === columnToProductMap.instagramReels
            || type === columnToProductMap.instagramStories
            || type === columnToProductMap.instagramVideos
          ));
        case 'pinterest':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.pinterestPins
          ));
        case 'snapchat':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.snapchatStories
          ));
        case 'tiktok':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.tiktokVideos
          ));
        case 'twitter':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.twitterTweets
          ));
        case 'youtube':
          return some(contentGuidelines, ({ type }) => (
            type === columnToProductMap.youtubeProductMentions
            || type === columnToProductMap.youtubeVideos
          ));
        case 'paymentAmount': {
          const { reward: { payment } } = collaborationDetails;
          return payment.showHideNewPrice && payment.newPrice > 0;
        }
        case 'recommendedPaymentAmount': {
          const { reward: { payment } } = collaborationDetails;

          return payment.showHideNewPrice && payment.newPrice > 0 && isTermsRecommendedPrincingEnabled;
        }
        default:
          // any unfiltered columns are always present
          return true;
      }
    })
  ), [
    collaborationDetails,
    contentGuidelines,
    isTermsRecommendedPrincingEnabled,
  ]);

  // map the enabled column keys to proper column configs
  return useMemo((): ReturnType<typeof useTableColumns> => (
    map(enabledColumnKeys, (key): TableColumnType<IMember> => {
      if (isSocialColumnKey(key)) {
        return getSocialHandleColumn(key);
      }
      if (isContentColumnKey(key)) {
        return getContentCountColumn(key);
      }
      switch (key) {
        case 'comment':
          return {
            className: styles.comment,
            dataIndex: 'comment',
            key: 'comment',
            render: (comment: string) => (
              <Tooltip placement="top" title={comment}>
                {comment}
              </Tooltip>
            ),
            sorter: caseInsensitiveStringSort('comment'),
            title: 'Comment',
            width: 250,
          };
        case 'name':
          return {
            className: styles.showDisabled,
            dataIndex: 'name',
            defaultSortOrder: 'ascend',
            key: 'name',
            render: (name: string, row: IMember) => (
              <>
                {row.imageUrl && <Avatar size={24} src={row.imageUrl} />}
                {name}
              </>
            ),
            sorter: caseInsensitiveStringSort('name'),
            title: 'Name',
            width: 150,
          };
        case 'paymentAmount':
          return {
            className: styles.showDisabled,
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            render: (paymentAmount: number, member: IMember, index: number) => {
              if (member.disabled) {
                return <div style={{ textAlign: 'center' }}>&mdash;</div>;
              }
              return (
                <DollarInput
                  disabled={!member.selected}
                  onChange={(value) => (
                    onUpdateColumnValue(member.id, 'paymentAmount', value)
                  )}
                  tabIndex={100 + index}
                  value={paymentAmount}
                />
              );
            },
            sorter: numericSort('paymentAmount'),
            title: (
              <>
                <DollarSignIcon />
                Payment
              </>
            ),
            width: 120,
          };
        case 'recommendedPaymentAmount':
          return {
            className: styles.showDisabled,
            dataIndex: 'recommendedPaymentAmount',
            key: 'recommendedPaymentAmount',
            showSorterTooltip: false,
            render: (_, member: IMember, __) => {
              if (member.disabled) {
                return (<Space>&mdash;</Space>);
              }

              return (
                <>
                  <DollarSignIcon />
                  {member.recommendedPaymentAmount}
                </>
              );
            },
            sorter: numericSort('recommendedPaymentAmount'),
            title: (
              <>
                <DollarSignIcon />
                Recommended Payment
                {' '}
                <Tooltip
                  title={(
                    <>
                      Recommended payments are calculated using an
                      engagement based pricing model which considers
                      the expected performance from a given creator
                      relative to other creators on Aspire.
                    </>
                  )}
                >
                  <CircleInfoIcon />
                </Tooltip>
              </>
            ),
            width: 250,
          };
        default:
          throw new Error(`Unhandled column config for key: ${key}.`);
      }
    })
  ), [
    enabledColumnKeys,
    onUpdateColumnValue,
  ]);
};
