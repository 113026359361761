import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_TASK_TRACKERS_QUERY } from '@frontend/app/queries';
import {
  GetTaskTrackersQuery,
  GetTaskTrackersQuery_trackers,
  GetTaskTrackersQueryVariables,
} from '@frontend/app/queries/types/GetTaskTrackersQuery';
import { isNil } from 'lodash';

export type ITaskTracker = GetTaskTrackersQuery_trackers;
type IOptions = QueryHookOptions<GetTaskTrackersQuery, GetTaskTrackersQueryVariables>;

export function useGetTaskTrackersQuery(options: IOptions = {}) {
  const {
    loading, data: { trackers } = {}, error, refetch,
  } = useQuery<GetTaskTrackersQuery, GetTaskTrackersQueryVariables>(GET_TASK_TRACKERS_QUERY, {
    ...options,
    skip: isNil(options.variables?.params),
    });

  return {
    loading,
    trackers,
    error,
    refetch,
  };
}
