import { useQuery } from '@apollo/client';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';

import { GET_MEMBER_FIELD_SCHEMAS_FOR_COMMUNITY } from '@frontend/app/containers/Members/queries/CommunityMemberFieldSchemasQuery';
import {
  CommunityMemberFieldSchemasQuery,
  CommunityMemberFieldSchemasQueryVariables,
} from '@frontend/app/containers/Members/queries/types/CommunityMemberFieldSchemasQuery';

export const useCommunityMemberFieldSchemas = (communityId?: number) => {
  const {
    data: {
      community: {
        memberFieldSchemas: communityFieldData = null,
      } = {},
    } = {},
    loading: communityFieldLoading,
    error: communityFieldError,
  } = useQuery<CommunityMemberFieldSchemasQuery, CommunityMemberFieldSchemasQueryVariables>(
    GET_MEMBER_FIELD_SCHEMAS_FOR_COMMUNITY,
    {
      variables: { communityId },
      skip: !communityId,
    },
  );

  const {
    data: {
      schemas: clientFieldData = null,
    } = {},
    loading: clientFieldLoading,
    error: clientFieldError,
    refetch,
  } = useMemberFieldSchemasQuery();

  return communityId
    ? {
      data: communityFieldData,
      loading: communityFieldLoading,
      error: communityFieldError,
      refetch,
    }
    : {
      data: clientFieldData,
      loading: clientFieldLoading,
      error: clientFieldError,
      refetch,
    };
};
