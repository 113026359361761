import gql from 'graphql-tag';

export const MESSAGE_VARIABLE_FRAGMENT = gql`
  fragment MessageVariableFragment on MessageVariable {
    global
    member
    program
    user
    client
  }
`;
