/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._FavoriteListButton_1qr17_79 {
  margin: auto;
}
._FavoriteListButton_1qr17_79 ._saveFavorite_1qr17_82 {
  position: relative;
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
._FavoriteListButton_1qr17_79 ._saveFavorite_1qr17_82._active_1qr17_87 {
  animation: _star-pulse_1qr17_1 0.25s 1 cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteListButton_1qr17_79 ._saveFavorite_1qr17_82._active_1qr17_87 ._starBorder_1qr17_90 {
  opacity: 0;
  color: #3996e0;
}
._FavoriteListButton_1qr17_79 ._saveFavorite_1qr17_82._active_1qr17_87 ._star_1qr17_90 {
  opacity: 1;
  transform: scale(1);
}
._FavoriteListButton_1qr17_79 ._saveFavorite_1qr17_82 ._starBorder_1qr17_90 {
  opacity: 1;
  color: #8f8d91;
  will-change: opacity, color;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1), color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteListButton_1qr17_79 ._saveFavorite_1qr17_82 ._starBorder_1qr17_90:hover {
  color: #3996e0;
}
._FavoriteListButton_1qr17_79 ._saveFavorite_1qr17_82 ._star_1qr17_90 {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  color: #3996e0;
  transform: scale(0);
  will-change: opacity, transform;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1), transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes _star-pulse_1qr17_1 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

._FavoriteListPopover_1qr17_132 ._content_1qr17_132 {
  display: flex;
  flex-direction: column;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._header_1qr17_136 {
  padding: 0 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 37px;
  border-bottom: solid 1px #e9e9e9;
  font-size: 15px;
  font-weight: 600;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._header_1qr17_136 ._spinner_1qr17_147 {
  margin-left: 10px;
  color: #3996e0;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 {
  flex: 1;
  overflow-y: overlay;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155 {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 37px;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155:hover {
  background-color: #f0f4f8;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155._disabled_1qr17_169 {
  pointer-events: none;
  color: gray;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155._active_1qr17_87 ._label_1qr17_173 {
  color: #3996e0;
  text-shadow: 0.5px 0 0 #3996e0;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155 ._label_1qr17_173 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155 ._actions_1qr17_182 {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155 ._actions_1qr17_182 ._item_1qr17_188 {
  margin-left: 5px;
  color: #999;
  will-change: color;
  transition: color 0.1s ease-out;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._list_1qr17_151 ._option_1qr17_155 ._actions_1qr17_182 ._item_1qr17_188:hover {
  color: #3996e0;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._addList_1qr17_197 {
  padding: 10px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._addList_1qr17_197:hover {
  color: #3996e0;
  background-color: #f0f4f8;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._addList_1qr17_197._disabled_1qr17_169 {
  pointer-events: none;
  color: gray;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._addList_1qr17_197 ._addIconWrapper_1qr17_216 {
  margin-right: 10px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #e2e2e2;
  border-radius: 100px;
  background-color: white;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._addList_1qr17_197 ._addIcon_1qr17_216 {
  transform: rotate(45deg);
  color: #3996e0;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._editList_1qr17_232 {
  padding: 0 10px;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._editList_1qr17_232 ._back_1qr17_235 {
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-weight: bold;
  font-size: 15px;
  color: #1a1818;
  border-radius: 4px;
  will-change: color, background-color;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._editList_1qr17_232 ._back_1qr17_235:hover {
  color: #3996e0;
  background-color: #f0f4f8;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._editList_1qr17_232 ._back_1qr17_235 ._arrowIcon_1qr17_256 {
  position: absolute;
  left: 10px;
  top: 8px;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._editList_1qr17_232 ._editContent_1qr17_261 {
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 280px;
  line-height: 20px;
}
._FavoriteListPopover_1qr17_132 ._content_1qr17_132 ._editList_1qr17_232 ._button_1qr17_267 {
  margin-top: 10px;
}