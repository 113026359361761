import * as React from 'react';
import { Typography } from '@revfluence/fresh';
import styles from './SubItemLarge.scss';

const { Title } = Typography;
interface IProps {
    name: string;
    value: string;
}

export const SubItemLarge: React.FC<IProps> = (props) => {
    const {
        name,
        value,
    } = props;

    return (
      <div className={styles.subItemLargeContainer}>
        <Title level={5}>{name}</Title>
        <Title level={3}>{value}</Title>
      </div>
    );
};
