import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_MEMBERS_FOR_RESTRICTION_QUERY } from '@frontend/app/queries';
import {
  GetMembersForRestrictionQuery,
  GetMembersForRestrictionQueryVariables,
  GetMembersForRestrictionQuery_members,
} from '@frontend/app/queries/types/GetMembersForRestrictionQuery';

type IOptions = QueryHookOptions<GetMembersForRestrictionQuery, GetMembersForRestrictionQueryVariables>;
export type IMember = GetMembersForRestrictionQuery_members;

export function useGetMembersForRestriction(options: IOptions = {}) {
  const {
 loading, data: { members } = {}, error, refetch,
} = useQuery<GetMembersForRestrictionQuery, GetMembersForRestrictionQueryVariables>(
    GET_MEMBERS_FOR_RESTRICTION_QUERY,
    options,
  );

  return {
    loading,
    members,
    error,
    refetch,
  };
}
