/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_19kyi_157 {
  display: flex;
}

._justify-content-space-between_19kyi_161 {
  justify-content: space-between;
}

._tabular-nums_19kyi_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_19kyi_235 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Popover_19kyi_178 ._option_19kyi_178, ._EmailAddressPicker_19kyi_178 ._selection_19kyi_178 ._email_19kyi_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._EmailAddressPicker_19kyi_178 ._selection_19kyi_178 ._label_19kyi_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._EmailAddressPicker_19kyi_178 ._button_19kyi_198 {
  height: 4rem;
  background-color: white;
  border: 0.0625rem solid #dadcde;
  border-radius: 0.25rem;
  cursor: pointer;
}
._EmailAddressPicker_19kyi_178 ._button_19kyi_198 ._content_19kyi_205 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
._EmailAddressPicker_19kyi_178 ._button_19kyi_198 ._icon_19kyi_213 {
  margin-right: 0.75rem;
}
._EmailAddressPicker_19kyi_178 ._button_19kyi_198 ._icon_19kyi_213._noEmailIcon_19kyi_216 {
  fill: #8f8d91;
}
._EmailAddressPicker_19kyi_178 ._selection_19kyi_178 {
  flex: 1;
}
._EmailAddressPicker_19kyi_178 ._selection_19kyi_178 ._label_19kyi_188 {
  font-weight: 600;
  margin-bottom: 2px;
}
._EmailAddressPicker_19kyi_178 ._selection_19kyi_178 ._email_19kyi_178 ._noEmail_19kyi_216 {
  color: #d9534f;
  font-size: 0.75rem;
}
._EmailAddressPicker_19kyi_178 ._arrow_19kyi_230 {
  padding-left: 15px;
  color: #A2A1A4;
  margin-left: auto;
}
._EmailAddressPicker_19kyi_178._showOptions_19kyi_235 ._button_19kyi_198 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

._Popover_19kyi_178 ._contentWrapper_19kyi_240 {
  border: 0.0625rem solid #dadcde;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
._Popover_19kyi_178 ._content_19kyi_205 {
  padding: 0 !important;
}
._Popover_19kyi_178 ._option_19kyi_178 {
  padding: 1rem;
  height: 4rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}
._Popover_19kyi_178 ._option_19kyi_178:not(._last_19kyi_257) {
  border-bottom: 1px solid #dadcde;
}
._Popover_19kyi_178 ._option_19kyi_178 ._icon_19kyi_213 {
  margin-right: 0.75rem;
}
._Popover_19kyi_178 ._option_19kyi_178 ._email_19kyi_178 {
  flex: 1;
}
._Popover_19kyi_178 ._option_19kyi_178 ._checkmark_19kyi_266 {
  color: #3996e0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Popover_19kyi_178 ._add_19kyi_273 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #dadcde;
  padding: 10px 13px;
}
._Popover_19kyi_178 ._add_19kyi_273 > span {
  margin-right: auto;
}
._Popover_19kyi_178 ._add_19kyi_273 > ._icon_19kyi_213 {
  display: inline-flex;
  margin-left: 0.625rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid #dadcde;
  padding: 0.125rem;
  cursor: pointer;
}