import * as React from 'react';
import cx from 'classnames';

import { IMember } from '@frontend/app/hooks';

import { EmailEditor } from '../EmailEditor';
import { IPreviewConfig } from '../EmailComposer';

import styles from './DetailView.scss';

interface IProps {
  previewConfig: IPreviewConfig;
  member: IMember;
  onExcludeMember(memberId: number): void;

  disableRemoveRecipient?: boolean;

  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const DetailView: React.FC<IProps> = React.memo((props) => {
  const {
    member,
    disableRemoveRecipient,
    previewConfig,
  } = props;

  const signaturePreview = useMemo(() => {
    if (!previewConfig || !previewConfig.resource) return '';

    const { resource } = previewConfig;
    switch (resource.config.signature?.mode) {
      case 'html':
        return resource.config.signature?.fromHTML;
      case 'editor':
        return resource.config.signature?.fromEditor;
    }
  }, [previewConfig]);

  return (
    <div className={cx(styles.DetailView, props.className)}>
      <div className={styles.section}>
        From:
        {' '}
        {previewConfig.resource.authProvider.userExternalDisplayId}
      </div>
      <div className={cx(styles.section, styles.toSection)}>
        <span>
          To:
          {member?.name && (
            <b>
              {' '}
              {member.name}
            </b>
          )}
          {' '}
          {member?.email || ''}
        </span>
        {!disableRemoveRecipient && (
          <span
            className={styles.remove}
            onClick={() => props.onExcludeMember(member.id)}
          >
            Remove Recipient
          </span>
        )}
      </div>
      <div className={styles.section}>
        Subject:
        {' '}
        {previewConfig.subject}
      </div>
      <div className={cx(styles.section, styles.messageSection)}>
        <EmailEditor
          className={styles.emailComposer}
          disabled
          previewMember={member}
          backupPlans={previewConfig.editorState.backupPlans}
          initialValue={previewConfig.editorState.raw}
          signaturePreview={signaturePreview}
        />
      </div>
    </div>
  );
});

DetailView.displayName = 'DetailView';
