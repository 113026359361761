import * as React from 'react';

import { TPlace } from './types';
import { PlacesInput } from './PlacesInput';

interface IProps {
  className?: string;
  onChange(newLocations: TPlace[]): void;
  locations?: TPlace[];
  placeholder?: string;
  debounceWait?: number;
}

const OPTIONS = {
  types: [],
};

export const LocationInput: React.FunctionComponent<IProps> = (props) => (
  <PlacesInput
    className={props.className}
    places={props.locations}
    placeholder={props.placeholder}
    onChange={props.onChange}
    emptyOptionsMessage="Start typing location name"
    options={OPTIONS}
    debounceWait={props.debounceWait}
  />
  );

LocationInput.defaultProps = {
  placeholder: 'Enter a location',
};
LocationInput.displayName = 'LocationInput';
