import * as React from 'react';
import { Select as AntdSelect } from 'antd';
import { map } from 'lodash';
import { ArrowDownFilledIcon } from '@components';
import cx from 'classnames';

interface IProps {
  options;
  className;
  onChange;
  selectedValue;
  placeholder?: string;
}

import styles from './Select.scss';

export const Select: React.FunctionComponent<IProps> = (props) => {
  const {
 options, className, selectedValue, onChange, placeholder,
} = props;
  return (
    <AntdSelect
      defaultValue={selectedValue}
      placeholder={placeholder}
      defaultActiveFirstOption
      className={cx(className, styles.select)}
      onChange={onChange}
      suffixIcon={<ArrowDownFilledIcon size={10} />}
      size="large"
    >
      {map(options, (option) => (
        <AntdSelect.Option
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          className={(styles as any).shopSelect}
          key={option.label}
          value={option.value}
        >
          {option.label}
        </AntdSelect.Option>
        ))}

    </AntdSelect>
  );
};
