import * as React from 'react';
import cx from 'classnames';
import {
 Button, SpinnerIcon, IconButton, KeyboardArrowLeftIcon,
} from '@components';
import { Input } from 'antd';

import { useMessagingContext } from '@frontend/hooks';
import { useSaveSegmentFolderMutation } from '@frontend/app/hooks';

import { SaveSegmentFolderMutation_folder as IFolder } from '@frontend/app/queries/types/SaveSegmentFolderMutation';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import styles from './NewFolderForm.scss';

const { useState, useCallback } = React;
export interface IOPtionFolder {
  value: string;
  label: string;
}
interface IProps {
  communityId: number;
  sourcingGroupId: string;
  folderName?: string;
  folders: IOPtionFolder[];
  onSave?(newFolder: IFolder);
  onCancel?();
}

export const NewFolderForm: React.FC<IProps> = React.memo((props) => {
  const [folderName, setFolderName] = useState<string>(props.folderName || '');
  const [alreadyExists, setAlreadyExists] = useState<boolean>(true);
  const {
    showSuccessMessage,
  } = useMessagingContext();

  const resetForm = () => {
    setFolderName('');
  };

  const [saveSegmentFolder, {
    loading: isSaving,
  }] = useSaveSegmentFolderMutation({
    onCompleted: (result) => {
      showSuccessMessage(`Successfully created group ${result.folder.title}`);
      resetForm();
      props.onSave && props.onSave(result.folder);
    },
  });

  const addEvent = useEventContext();

  const handleClickSave = useCallback(() => {
    if (isSaving || !alreadyExists) {
      return;
    }
    saveSegmentFolder({
      variables: {
        segmentFolder: {
          title: folderName,
          communityId: props.communityId,
          sourceGroup: props.sourcingGroupId,
        },
      },
    });
    addEvent(EventName.CreateSegmentGroup, {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving, alreadyExists, folderName, props.communityId]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setFolderName(value);
    const folderAlreadyExist = props.folders.some(
      ({ label }) => value.toLowerCase() === label.toLowerCase(),
    );
    return setAlreadyExists(!folderAlreadyExist);
  };

  return (
    <div className={cx(styles.NewFolderForm, { [styles.saving]: isSaving })}>
      <div className={styles.header}>
        <IconButton
          className={styles.icon}
          onClick={props.onCancel}
          icon={<KeyboardArrowLeftIcon size={12} />}
        />
        <span>Create New Filter Group</span>
      </div>
      <div className={styles.form}>
        <Input
          size="large"
          value={folderName}
          onChange={handleInputChange}
          className={cx(styles.input, { [styles.inputError]: !alreadyExists })}
          disabled={isSaving}
          onPressEnter={handleClickSave}
        />
        {!alreadyExists && <span className={styles.invalid}>Name already exists</span>}
      </div>
      <Button
        disabled={isSaving || !alreadyExists}
        label="Save"
        onClick={handleClickSave}
        className={styles.button}
        icon={isSaving && <SpinnerIcon size={16} />}
      />
    </div>
  );
});
