import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/client';

import { message } from 'antd';

import { DELETE_PROGRAM_MUTATION } from '@frontend/app/queries';
import {
  DeleteProgramMutation,
  DeleteProgramMutationVariables,
} from '@frontend/app/queries/types/DeleteProgramMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<DeleteProgramMutation, DeleteProgramMutationVariables>;
export function useDeleteProgramById(options: IOptions = {}) {
  const [deleteProgramById, { loading, error }] = useMutation<
    DeleteProgramMutation,
    DeleteProgramMutationVariables
  >(DELETE_PROGRAM_MUTATION, options);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    deleteProgramById,
    loading,
  };
}
