import * as React from 'react';
import {
  Space, Row, Col, Skeleton,
} from '@revfluence/fresh';
import Card from '../../../../components/Card';
import styles from './PageLoading.scss';
import { TrendLoading } from '../../../Home/components/HomeLoading/HomeLoading';
import { MetricsSecondaryLayout } from '../MetricsSecondary/MetricsSecondary';

const MetricsSecondary = () => (
  <MetricsSecondaryLayout StatisticsComponents={[
    <Col key="1" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="2" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="3" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="4" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="5" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="6" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="7" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="8" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="8" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="9" flex={1}>
      <TrendLoading />
    </Col>,
    <Col key="10" flex={1}>
      <TrendLoading />
    </Col>,
  ]}
  />
);

const RowValue = ({ hiddenCircle = false }: { hiddenCircle?: boolean }) => (
  <Row justify="space-between">
    <Space align="start">
      {!hiddenCircle && <Skeleton.Avatar active size="small" />}
      <Skeleton.Input active size="small" />
    </Space>
    <Space>
      <Skeleton.Button active size="small" />
    </Space>
  </Row>
);

export const MetricsSecondaryLoading = () => (
  <Card title="" subtitle="" icon={null} dark>
    <Skeleton paragraph={{ rows: 1, width: '30%' }} title={false} />
    <MetricsSecondary />
  </Card>
);

export const MetricsTabsLoading = () => (
  <Card title="" subtitle="" icon={null}>
    <Skeleton paragraph={{ rows: 1, width: '30%' }} title={false} />
    <Row gutter={[12, 12]}>
      <Col flex={1}>
        <Skeleton.Button active className={styles.MetricTab} />
      </Col>
      <Col flex={1}>
        <Skeleton.Button active className={styles.MetricTab} />
      </Col>
      <Col flex={1}>
        <Skeleton.Button active className={styles.MetricTab} />
      </Col>
      <Col flex={1}>
        <Skeleton.Button active className={styles.MetricTab} />
      </Col>
      <Col flex={1}>
        <Skeleton.Button active className={styles.MetricTab} />
      </Col>
    </Row>
  </Card>
);

export const ChartLabelLoading = () => (
  <Space size={0} align="center" direction="vertical" style={{ width: '100%' }}>
    <Skeleton.Input active size="small" style={{ width: 200 }} />
    <Skeleton.Input active size="small" style={{ width: 100 }} />
  </Space>
);

export const ImpactChartLoading = () => (
  <>
    <Space size={70} direction="vertical" style={{ width: '100%' }}>
      <div className={styles.Divider} />
      <div className={styles.Divider} />
      <div className={styles.Divider} />
      <div className={styles.Divider} />
      <div className={styles.Divider} />
    </Space>
    <Row justify="space-around" style={{ marginTop: 12 }}>
      <Skeleton.Button active />
      <Skeleton.Button active />
      <Skeleton.Button active />
      <Skeleton.Button active />
      <Skeleton.Button active />
    </Row>
  </>
);

export const HowItsCalculatedLoading = () => (
  <>
    <Skeleton active paragraph={{ rows: 1, width: '30%' }} title={false} />
    <Space direction="vertical" size={14} style={{ width: '100%' }}>
      <Skeleton active paragraph={{ rows: 1, width: '50%' }} title={false} />
      <div className={styles.Divider} />
      <RowValue />
      <RowValue />
      <RowValue />
      <RowValue />
      <RowValue />
      <div className={styles.Divider} />
      <RowValue hiddenCircle />
      <RowValue hiddenCircle />
      <div className={styles.Divider} />
      <RowValue hiddenCircle />
    </Space>
  </>
);
