import * as React from 'react';
import { Avatar, Tooltip } from '@revfluence/fresh';
import {
  words, size, first, last,
} from 'lodash';

type RenderFunction = () => React.ReactNode;

const { useMemo } = React;

interface IProps {
  name: string;
  profilePicture?: string;
  className?: string;
  size?: 'large' | 'small' | 'default' | number;
  tooltipTitle?: React.ReactNode | RenderFunction;
  onClick?: () => void;
}

function stringToHslColor(str: string, saturation: number, lightness: number): string {
  let hash = 0;
  for (let i = 0; i < size(str); i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${saturation}%, ${lightness}%)`;
}

export const UserAvatar: React.FunctionComponent<IProps> = (props) => {
  const renderAvatarWithPicture = () => (
    <Avatar
      size={props.size}
      src={props.profilePicture}
      className={props.className}
      onClick={props.onClick}
    />
    );

  const hsl = useMemo(() => stringToHslColor(props.name, 60, 60), [props.name]);
  const renderAvatarDefault = () => {
    const names = words(props.name);
    const firstName = first(names);
    const lastName = names.length > 1 ? last(names) : null;

    // eslint-disable-next-line react-hooks/exhaustive-deps

    return (
      <Avatar
        size={props.size}
        style={{ color: '#ffffff', backgroundColor: hsl }}
        className={props.className}
        onClick={props.onClick}
      >
        {firstName && firstName[0].toUpperCase()}
        {lastName && lastName[0].toUpperCase()}
      </Avatar>
    );
  };

  const renderAvatar = () => {
    if (props.profilePicture) {
      return renderAvatarWithPicture();
    }
    return renderAvatarDefault();
  };

  if (props.tooltipTitle) {
    return (
      <Tooltip title={props.tooltipTitle}>
        {renderAvatar()}
      </Tooltip>
    );
  }
  return renderAvatar();
};
