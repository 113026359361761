import * as React from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { Button } from '@components';

import { PaymentTable } from './PaymentTable';

import styles from './MemberPaymentList.scss';

const MemberPaymentList: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { memberId } = useApplication();

  const sendNewPayment = () => {
    history.push({
      ...location,
      pathname: './new',
    });
  };

  return (
    <div className={styles.MemberPaymentList}>
      <div className={styles.toolbar}>
        <Button label="New Payment" className={styles.new_payment_button} onClick={sendNewPayment} />
      </div>

      <PaymentTable memberId={memberId} />
    </div>
  );
};

export default MemberPaymentList;
