import * as React from 'react';
import cx from 'classnames';
import { isNull } from 'lodash';

import { Image } from '@revfluence/fresh';
import { LinkSimpleIcon, CertificateIcon } from '@revfluence/fresh-icons/regular/esm';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';

import styles from './OfferImage.scss';

interface IProps {
  className?: string;
  imageUrl: string | null;
  source: OFFER_SOURCE;
  rounded?: boolean;
}

export const OfferImage: React.FC<Readonly<IProps>> = (props) => {
  const {
    className,
    imageUrl,
    source,
  } = props;

  if (!isNull(imageUrl)) {
    return (
      <Image
        className={cx(className)}
        src={imageUrl}
      />
    );
  }

  let icon;
  let backgroundClass;
  switch (source) {
    case OFFER_SOURCE.SHOPIFY:
      backgroundClass = styles.promoCode;
      icon = <CertificateIcon className={styles.icon} />;
      break;
    case OFFER_SOURCE.TUNE:
      backgroundClass = styles.affiliateLink;
      icon = <LinkSimpleIcon className={styles.icon} />;
      break;
  }
  return (
    <div className={cx(styles.OfferImageFallback, backgroundClass, className, {
      [styles.rounded]: props.rounded,
    })}
    >
      {icon}
    </div>
  );
};
