import * as React from 'react';
import {
 Instagram, Pinterest, Tiktok, Website, Youtube,
} from '@components';
import { isFunction } from 'lodash';
import { BrandSocialField } from '@frontend/app/components/ApplicationPageTemplates/utils/socialLinks';

export const useRenderSocialLinkIcon = () => {
  const renderIcon = (fieldName: string, size: number, fill: string, onClick = undefined) => {
    const onIconClick = () => {
      if (isFunction(onClick)) {
        onClick(fieldName);
      }
    };

    switch (fieldName) {
      case BrandSocialField.Instagram:
          return <Instagram fill={fill} size={size} onClick={onIconClick} />;
      case BrandSocialField.Tiktok:
          return <Tiktok fill={fill} size={size} onClick={onIconClick} />;
      case BrandSocialField.Youtube:
          return <Youtube fill={fill} size={size} onClick={onIconClick} />;
      case BrandSocialField.Pinterest:
          return <Pinterest fill={fill} size={size} onClick={onIconClick} />;
      case BrandSocialField.Website:
          return <Website fill={fill} size={size} onClick={onIconClick} />;
      default:
          return null;
      }
  };

  return renderIcon;
};
