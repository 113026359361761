import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import {
  DESELECT_AD_ACCOUNT,
} from '@frontend/app/queries';

import {
  deselectAdAccount,
  deselectAdAccountVariables,
} from '@frontend/app/queries/types/deselectAdAccount';

type IOptions = MutationHookOptions<deselectAdAccount, deselectAdAccountVariables>;

export const useDeselectAdAccountMutation = (options: IOptions = {}) => (
  useMutation<deselectAdAccount>(DESELECT_AD_ACCOUNT, options)
);

export type TUseDeselectAdAccountMutation = ReturnType<typeof useDeselectAdAccountMutation>;
