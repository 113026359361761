import * as React from 'react';
import { Spinner } from '@revfluence/fresh';
import { TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import styles from './PaymentsProcessing.scss';
import { StepHeader } from '../common/StepHeader';

interface IProps {
  state: TState;
}

export const PaymentsProcessing: React.FC<IProps> = (props) => {
  const { state } = props;
  const {
    workflowActionParameters,
  } = useApplication();

  const currentProgram = state.programs.find((program) => program.id === workflowActionParameters?.programId);

  return (
    <div className={styles.paymentsProcessingContainer}>
      {
        state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING ? null : (
          <StepHeader
            name={state.memberInfo.name}
            profileImage={state.memberInfo.profileImage}
            programImage={currentProgram?.splashImageUrl}
            programName={currentProgram?.title}
          />
        )
      }
      <div className={styles.main}>
        <h2>Processing payment</h2>
        <p>Do not close or refresh this browser tab while payment is processing.</p>
        <div className={styles.progressContainer}>
          <Spinner size="large" />
        </div>
      </div>
    </div>
  );
};
