import * as React from 'react';
import cx from 'classnames';
import {
 each, find, map, findIndex, includes, isUndefined, filter,
} from 'lodash';

import { ISortableItem, SortableList } from '@frontend/app/components/SortableList/SortableList';
import { requiredFieldNames } from '@frontend/app/containers/Projects/LandingPages/hooks';

import { Field, IEditFields } from './Field';
import { IApplicationFormFields, IField } from '../../types';
import { useFields } from '../hooks';

import styles from './FormFields.scss';

const {
 useCallback, useEffect, useMemo, useState,
} = React;

interface IProps {
  applicationFormFields?: IApplicationFormFields;
  onChange?(formFields: IApplicationFormFields);
  onClickSeeAllFields?(e: React.MouseEvent<HTMLDivElement>);
  className?: string;
}

export const FormFields: React.FC<IProps> = (props) => {
  const {
    applicationFormFields,
    className,
    onClickSeeAllFields,
    onChange,
  } = props;

  const activeFields = useFields(applicationFormFields);

  const [sortableOrderedFields, setSortableOrderedFields] = useState<IField[]>([]);

  useEffect(() => {
    setSortableOrderedFields(activeFields);
  }, [activeFields]);

  const activeFieldNames = useMemo(() => new Set(
    map(activeFields, (f) => f?.name)
      .filter(Boolean),
  ), [activeFields]);

  const existingRequiredFieldNames = useMemo(
    () => filter(requiredFieldNames, (name) => activeFieldNames.has(name)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requiredFieldNames, activeFieldNames],
  );

  const onChangeField = (field: IField, newProps: IEditFields) => {
    if (!onChange) {
      return;
    }

    const deepCopy: typeof applicationFormFields = JSON.parse(JSON.stringify(applicationFormFields));

    // TODO: Disabling this any as it's not trivial to fix.
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    let formField: any = find(deepCopy.memberFieldSchemas, { schemaId: field.id });

    if (!formField) {
      formField = find(deepCopy.dbColumns, { name: field.name });
    }

    if (formField) {
      each(newProps, (value, key) => {
        formField[key] = value;
      });

      if (formField.active === false) {
        // Remove field from application form fields.
        let idx = findIndex(deepCopy.memberFieldSchemas, { schemaId: field.id });
        if (idx < 0) {
          idx = findIndex(deepCopy.dbColumns, { name: field.name });
          if (idx >= 0) {
            deepCopy.dbColumns.splice(idx, 1);
          }
        } else {
          deepCopy.memberFieldSchemas.splice(idx, 1);
        }
      }

      onChange(deepCopy);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeFieldsOrder = (newFields: IField[]) => {
    if (!onChange) {
      return;
    }

    const deepCopy: typeof applicationFormFields = JSON.parse(JSON.stringify(applicationFormFields));

    each((newFields), (field) => {
      // TODO: Disabling this any as it's not trivial to fix.
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      let formField: any = find(deepCopy.memberFieldSchemas, { schemaId: field.id });

      if (!formField) {
        formField = find(deepCopy.dbColumns, { name: field.name });
      }

      formField.order = field.order || formField.order;
    });

    onChange(deepCopy);
  };

  const handleToggleActive = (field: IField, active: boolean) => {
    onChangeField(field, { active });
  };

  const handleEdit = (schema: IField, fields: IEditFields) => {
    onChangeField(schema, fields);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderField = (field: IField): React.ReactNode => (
    <Field
      key={field.id}
      name={field.name}
      label={field.label || field.name}
      type={field.type}
      choices={field.choices}
      active={field.active}
      required={field.required}
      onEdit={(fields) => handleEdit(field, fields)}
      onToggleActive={handleToggleActive.bind(this, field)}
      className={styles.field}
      disableRequired={(field.source === 'dbColumn' && field.name === 'email') || (includes(requiredFieldNames, field.name) && field.required === true)}
      disableSwitch={(field.source === 'dbColumn' && field.name === 'email') || includes(existingRequiredFieldNames, field.name)}
      editable
    />
    );

  const sortableItems: ISortableItem[] = useMemo(() => {
    // This is to prevent a race condition if user remove the field from project
    // @TODO Remove if BE solves this.
    const filteredSortable = filter(sortableOrderedFields, (field) => !isUndefined(field));

    return map(filteredSortable, (field) => ({
        data: field as IField,
        render: () => renderField(field),
        id: `reorder_field_${field.id}`,
        className: styles.sortableField,
      }));
  }, [sortableOrderedFields, renderField]);

  const handleSortChange = useCallback((result: ISortableItem[]) => {
    const newOrderData = map(result, (r) => r.data as IField);
    const newOrder = map(newOrderData, (r, index) => {
      const newItem = { ...r };
      newItem.order = index + 1;
      return newItem;
    });

    setSortableOrderedFields(newOrder);
    onChangeFieldsOrder(newOrder);
  }, [setSortableOrderedFields, onChangeFieldsOrder]);

  return (
    <div className={cx(styles.FormFields, className)}>
      <div className={styles.header}>
        <div className={styles.title}>Active form fields</div>
        <div className={styles.seeAll} onClick={onClickSeeAllFields}>See all fields</div>
      </div>

      <SortableList
        onChange={handleSortChange}
        options={sortableItems}
        handleClassName={styles.handle}
        handlePosition="left"
      />
    </div>
  );
};
