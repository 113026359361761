import { useMutation, MutationHookOptions } from '@apollo/client';

import { SEND_BULK_MESSAGE_MUTATION } from '@frontend/app/queries';
import {
  SendBulkMessageMutation, SendBulkMessageMutationVariables,
} from '@frontend/app/queries/types/SendBulkMessageMutation';

type IOptions = MutationHookOptions<SendBulkMessageMutation, SendBulkMessageMutationVariables>;

export function useSendBulkMessage(options: IOptions = {}) {
  const [
    sendBulkMessage,
    { loading, error },
  ] = useMutation<SendBulkMessageMutation, SendBulkMessageMutationVariables>(SEND_BULK_MESSAGE_MUTATION, options);

  return {
    loading,
    sendBulkMessage,
    error,
  };
}
