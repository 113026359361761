import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { GetStatsByCatalogCollectionIdQuery, GetStatsByCatalogCollectionIdQueryVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetStatsByCatalogCollectionIdQuery';

type IOptions = QueryHookOptions<GetStatsByCatalogCollectionIdQuery, GetStatsByCatalogCollectionIdQueryVariables>;

export const useGetStatsByCatalogCollectionId = (options: IOptions = {}) => {
  const {
 loading, data, error, refetch,
} = useQuery(GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY, options);

  return {
    loading,
    stats: data?.getStatsByCatalogCollectionId,
    error,
    refetch,
  };
};
