import 'css-chunk:src/app/containers/Settings/SettingsContent/MemberRestrictionSettings/MemberRestrictionSettings.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_154yo_157",
  "justify-content-space-between": "_justify-content-space-between_154yo_161",
  "tabular-nums": "_tabular-nums_154yo_165",
  "MemberRestrictionSettings": "_MemberRestrictionSettings_154yo_178",
  "title": "_title_154yo_178",
  "emailSection": "_emailSection_154yo_188",
  "header": "_header_154yo_188",
  "toggleSection": "_toggleSection_154yo_188",
  "left": "_left_154yo_188",
  "description": "_description_154yo_198",
  "emptyNotice": "_emptyNotice_154yo_208",
  "text": "_text_154yo_208",
  "domains": "_domains_154yo_243",
  "toggle": "_toggle_154yo_188",
  "addRule": "_addRule_154yo_259",
  "select": "_select_154yo_265",
  "input": "_input_154yo_270",
  "button": "_button_154yo_274",
  "notice": "_notice_154yo_278",
  "table": "_table_154yo_291",
  "removeCell": "_removeCell_154yo_291",
  "ConfirmationModal": "_ConfirmationModal_154yo_304",
  "show": "_show_154yo_1"
};