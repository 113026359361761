import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { LoadSpinner } from '@components';

import styles from './OverlaySpinner.scss';

export const OverlaySpinner: React.FunctionComponent = React.memo(() => {
  const spinner = (
    <div className={styles.OverlaySpinner}>
      <LoadSpinner className={styles.spinner} />
    </div>
  );

  if (typeof window !== 'undefined') {
    return ReactDOM.createPortal(spinner, document.body);
  } else {
    return spinner;
  }
});

OverlaySpinner.displayName = 'OverlaySpinner';
