import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const BankIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon viewBox="0 0 30 30" {...props}>
    <path d="M3.62022 12.9957H7.16748V21.2104C7.16748 21.6713 7.54087 22.0389 7.99595 22.0389C8.45102 22.0389 8.82442 21.6655 8.82442 21.2104V12.9957H14.1686V21.5022C14.1686 21.9631 14.542 22.3306 14.9971 22.3306C15.4522 22.3306 15.8256 21.9572 15.8256 21.5022V12.9957H21.1698V21.5022C21.1698 21.9631 21.5432 22.3306 21.9983 22.3306C22.4534 22.3306 22.8268 21.9572 22.8268 21.5022V12.9957H26.2282C26.5899 12.9957 26.9108 12.7624 27.0216 12.4123C27.1325 12.0681 26.9983 11.6888 26.7007 11.4788L15.7614 3.89422C15.4872 3.70168 15.1196 3.69585 14.8338 3.88255L3.16514 11.4671C2.85592 11.6655 2.7159 12.0447 2.82092 12.4006C2.93177 12.7507 3.25266 12.9957 3.62022 12.9957ZM15.2772 5.58033L23.5735 11.333H6.42652L15.2772 5.58033Z" />
    <path d="M26.6657 25.6289H3.32847C2.8734 25.6289 2.5 26.0023 2.5 26.4632C2.5 26.9241 2.8734 27.2917 3.32847 27.2917H26.6657C27.1266 27.2917 27.4942 26.9183 27.4942 26.4632C27.4942 26.0081 27.1266 25.6289 26.6657 25.6289Z" />
  </SvgIcon>
));

BankIcon.displayName = 'BankIcon';
