import { useMutation, MutationHookOptions } from '@apollo/client';

import { INVITE_MEMBERS_TO_PROGRAMS_MUTATION } from '@frontend/app/queries';
import {
  InviteMembersToProgramsMutation,
  InviteMembersToProgramsMutationVariables,
} from '@frontend/app/queries/types/InviteMembersToProgramsMutation';

type IOptions = MutationHookOptions<InviteMembersToProgramsMutation, InviteMembersToProgramsMutationVariables>;

export function useInviteMembersToProgramsMutation(options: IOptions = {}) {
  const [
    inviteMembersToPrograms,
    { loading, error },
  ] = useMutation<InviteMembersToProgramsMutation, InviteMembersToProgramsMutationVariables>(
    INVITE_MEMBERS_TO_PROGRAMS_MUTATION,
    options,
  );
  return {
    inviteMembersToPrograms,
    loading,
    error,
  };
}
