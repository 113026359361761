import * as React from 'react';

import {
  RecaptchaContext,
} from '../context/RecaptchaContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TRecaptchaSuccessCallback = (...args: any[]) => void;

interface IOptions {
  onSuccess?: TRecaptchaSuccessCallback;
  skip?: boolean;
}

const { useContext } = React;

export const useRecaptcha = (opts: IOptions = {}) => {
  const context = useContext(RecaptchaContext);

  if (!opts.skip) {
    const {
      onRecaptchaSuccessRef,
      onSubmitRecaptcha,
      loading,
    } = context;

    onRecaptchaSuccessRef.current = opts.onSuccess;

    return {
      onSubmitRecaptcha,
      loading,
    };
  } else {
    return {
      onSubmitRecaptcha: () => null,
      loading: false,
    };
  }
};
