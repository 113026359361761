import gql from 'graphql-tag';

export const SAVE_CLIENT_FEATURE_MUTATION = gql`
  mutation SaveClientFeatureMutation($clientFeature: ClientFeatureInput!) {
    feature: saveClientFeature(clientFeature: $clientFeature) {
      id
      name
      state
    }
  }
`;
