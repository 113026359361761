import {
  QueryHookOptions,
  QueryResult,
  useQuery,
} from '@apollo/client';
import { PROJECT_OVERVIEW_QUERY } from '../queries/ProjectOverviewQuery';
import { ProjectOverviewQuery, ProjectOverviewQueryVariables } from '../queries/types/ProjectOverviewQuery';

export const useProjectOverviewQuery = (
  params: QueryHookOptions<ProjectOverviewQuery, ProjectOverviewQueryVariables>,
): QueryResult<ProjectOverviewQuery, ProjectOverviewQueryVariables> => useQuery(PROJECT_OVERVIEW_QUERY, params);
