import * as React from 'react';
import cx from 'classnames';

import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';

import styles from './TextareaInput.scss';

const { TextArea } = Input;

export interface ITextareaInputProps extends TextAreaProps { }

export const TextareaInput: React.FC<ITextareaInputProps> = (props) => (
  <TextArea {...props} className={cx(styles.TextareaInput, props.className)} />
  );
