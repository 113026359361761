/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1jrsj_157 {
  display: flex;
}

._justify-content-space-between_1jrsj_161 {
  justify-content: space-between;
}

._tabular-nums_1jrsj_165 {
  font-variant-numeric: tabular-nums;
}

._Input_1jrsj_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1jrsj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Input_1jrsj_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Input_1jrsj_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Input_1jrsj_169 ::-webkit-scrollbar-track, ._Input_1jrsj_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Input_1jrsj_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Input_1jrsj_169 {
  margin: 0 -0.75rem;
  padding: 0.625rem;
  width: calc(100% + 1.5rem);
  border-radius: 0.375rem;
  border-width: 0.0625rem;
  border-color: #dadcde;
  border-style: solid;
  outline: none;
}