import * as React from 'react';

import { UploadMembersCSV, UploadMembersCSVVariables } from '@frontend/app/queries/types/UploadMembersCSV';
import { useMutation } from '@apollo/client';
import { UPLOAD_MEMBERS_CSV } from '@frontend/app/queries';
import * as Papa from 'papaparse';
import { logger } from '../../../../../../common/Logger';

import { IUploadCsvParams } from '../UploadCSVContext';

const { useCallback } = React;

interface IUploadCSVDataChunk {
  uploadCSVDataChunk: (
    chunkData: Record<string, string>[],
    index: string,
    uploadCsvParams: IUploadCsvParams,
  ) => Promise<UploadMembersCSV>
}

export const useUploadCSVDataChunk = (): IUploadCSVDataChunk => {
  const [uploadMemberCSV] = useMutation<UploadMembersCSV, UploadMembersCSVVariables>(UPLOAD_MEMBERS_CSV);

  const uploadCSVDataChunk = useCallback(async (
    chunkData: Record<string, string>[],
    index: string,
    uploadCsvParams: IUploadCsvParams,
  ): Promise<UploadMembersCSV> => {
    const fileName = uploadCsvParams.file?.name;
    const chunkFileName = `${index}-${fileName}`;

    logger.debug(`Processing ${chunkFileName} CSV chunk.`);

    const csvString = Papa.unparse(chunkData);
    const blob = new Blob([csvString], {
      type: 'text/csv;charset=utf-8;',
    });

    const file = new File([blob], chunkFileName);

    const result = await uploadMemberCSV({
      variables: {
        ...uploadCsvParams,
        file,
      },
    });

    logger.debug(`Finished uploading ${chunkFileName} CSV chunk.`);

    return result.data;
  }, [uploadMemberCSV]);

  return {
    uploadCSVDataChunk,
  };
};
