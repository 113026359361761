export enum DateRangeOptions {
  THIS_WEEK = 'This Week',
  LAST_WEEK = 'Last Week',
  LAST_MONTH = 'Last Month',
  MTD = 'Month to Date',
  LAST_QUARTER = 'Last Quarter',
  QTD = 'Quarter to Date',
  LAST_6_MONTHS = 'Last 6 Months',
  YTD = 'Year to Date',
  ALL_TIME = 'All Time',
  CUSTOM = 'Custom Range',
}

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export interface IDateRangeSettings {
  dateRange?: IDateRange;
  onChange: (option: DateRangeOptions, range?: IDateRange) => void;
  selectedOption: DateRangeOptions;
  earliestDate: Date;
  setEarliestDate: (date: Date) => void;
}
