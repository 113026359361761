/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1nk3i_157 {
  display: flex;
}

._justify-content-space-between_1nk3i_161 {
  justify-content: space-between;
}

._tabular-nums_1nk3i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1nk3i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._alert_1nk3i_178 {
  background-color: #fff3cd;
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-family: 14;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.125rem;
  border: 0.0625rem solid var(--Warning-Gold-gold-3, #FFE58F);
  background: var(--Warning-Gold-gold-1, #FFFBE6);
  display: flex;
  align-items: center;
}

._budgetToLink_1nk3i_195 {
  flex-grow: 1;
  margin-right: 0.5rem;
}
._budgetToLink_1nk3i_195 .ant-row {
  flex-grow: 1;
  margin-right: 0.5rem;
}

._budgetToLinkContainer_1nk3i_204 .ant-card-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}
._budgetToLinkContainer_1nk3i_204 .ant-card-body .ant-form-item {
  width: 100%;
}
._budgetToLinkContainer_1nk3i_204 .ant-card-body .ant-form-item .ant-form-item-control-input-content {
  display: flex;
  width: 100%;
}

._budgetToLinkInfo_1nk3i_217 {
  display: flex;
  gap: 0.5rem;
}
._budgetToLinkInfo_1nk3i_217 .anticon {
  margin-top: 0.25rem;
}

._parentBudgetDetailsPopover_1nk3i_225 {
  width: 18.75rem;
}

._parentBudgetPopoverTitle_1nk3i_229 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._parentBudgetAmountsContainer_1nk3i_235 {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._parentBudgetAmount_1nk3i_235 {
  font-size: 1rem;
  font-weight: 600;
  color: #1F1F21;
}

._availableBudgetIndicator_1nk3i_248 {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.25rem;
  background: #5DB884;
}

._parentBudgetAmountLabelContainer_1nk3i_255 {
  margin-top: 0.1875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._warningIcon_1nk3i_262 {
  margin-right: 0.5rem;
  border-radius: 0.125rem;
  height: 1rem;
  width: 1rem;
  background: var(--Warning-Gold-gold-1, #FFFBE6);
}

._budgetAccountContainer_1nk3i_270 {
  padding: 2rem 2rem;
  background-color: #FAFAFA;
  height: 100%;
  display: flex;
  justify-content: start;
  gap: 3.75rem;
  width: 100%;
}

._formContainer_1nk3i_280 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 7;
  max-width: 43.75rem;
}

._form_1nk3i_280 {
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

._rightSectionContainer_1nk3i_294 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 3;
  max-width: 37.5rem;
}

._loadSpinner_1nk3i_302 {
  margin: auto;
}