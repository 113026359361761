import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import {
  ADD_MEMBERS_TO_ACTIVATIONS_MUTATION,
} from '@frontend/app/queries';
import {
  AddMembersToActivationsMutation,
  AddMembersToActivationsMutationVariables,
} from '@frontend/app/queries/types/AddMembersToActivationsMutation';

type IOptions = MutationHookOptions<AddMembersToActivationsMutation, AddMembersToActivationsMutationVariables>;

export const useAddMembersToActivationsMutation = (options: IOptions = {}) => useMemberMutation<AddMembersToActivationsMutation, AddMembersToActivationsMutationVariables>(
    ADD_MEMBERS_TO_ACTIVATIONS_MUTATION,
    options,
  );
