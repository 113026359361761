import gql from 'graphql-tag';

export const GET_COLLECTION_DETAILS = gql`
  query GetCollectionDetails($collectionId: Float!) {
    getCollectionByCollectionId(collectionId: $collectionId) {
      id
      name
      productCount
      images
      lastSyncDate
    }
  }
`;
