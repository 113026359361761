import gql from 'graphql-tag';

export const AUTOMATION_NODE_FRAGMENT = gql`
  fragment AutomationNodeFragment on AutomationNode {
    id
    type
    subType
    children
    variables {
      id
      name
      type
      value
    }
    metadata
  }
`;
