import React, { useEffect, useState } from 'react';
import {
  Button, Col, Row, Skeleton, Space, Typography,
} from '@revfluence/fresh';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { useRouteMatch } from 'react-router-dom';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { isEmpty, isNil, map } from 'lodash';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useMessagingContext } from '@frontend/hooks';
import { pluralize } from '@frontend/app/utils/strings';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { BasicMember, InvalidMembersCPS } from '@frontend/applications/ProductFulfillmentApp/pages/InvalidMembersCPS';
import styles from './SendCatalog.scss';
import { BulkSelection } from './BulkSelection';
import { PerMemberSelection } from './PerMemberSelection';
import { SendEmail } from './SendEmail';
import { useSendCatalogContext } from './SendCatalogContext';
import { isEmailAddressValid } from '../../../../../../../common/Utils';

const { Title, Text } = Typography;

export const SendCatalog = ({ handleScreenChange }: { handleScreenChange: () => void }) => {
  const {
 setSelectionType,
 selectionType,
 selectedRule,
 selectedCatalog,
 selectedCatalogs,
 setSelectedCatalogsBulk,
 setSelectedRulesBulk,
} = useSendCatalogContext();
  const {
    allMembers,
  } = useProductFulfillmentContext();
  const validMembers = allMembers.filter((m) => isEmailAddressValid(m.email));
  const memberCount = validMembers.length;

  const { closeModal } = useApplication();

  const handleSelectionTypeChange = () => {
    if (selectionType === 'bulk') {
      setSelectedCatalogsBulk(validMembers.reduce((acc, member) => ({
        ...acc,
        [member.id]: selectedCatalog,
      }), {}));
      setSelectedRulesBulk(validMembers.reduce((acc, member) => ({
        ...acc,
        [member.id]: selectedRule,
      }), {}));
    }
    setSelectionType(selectionType === 'bulk' ? 'perMember' : 'bulk');
  };

  const disableNextStep = selectionType === 'bulk'
    ? isNil(selectedCatalog)
    : memberCount !== Object.keys(selectedCatalogs).length;

  return (
    <>
      <Space direction="vertical" className={styles.SendCatalog} size="large">
        <Space direction="vertical" size={4}>
          <Title level={5}>Edit the Order Request Catalog</Title>
          <Text>
            Enable creators to pick and choose products for this project from your catalogs. The catalog you select
            below will reflect the items available for creators to choose from with any selection criteria when
            submitting an Order Request.
          </Text>
        </Space>
        <Space size="middle" direction="vertical">
          <Title level={5}>
            {memberCount}
            {' '}
            {pluralize(memberCount, 'Member')}
            {' '}
            Selected
          </Title>
          {selectionType === 'bulk' ? <BulkSelection /> : <PerMemberSelection />}
        </Space>
      </Space>
      <Row gutter={16} className={styles.Footer}>
        <Col onClick={closeModal}>
          <Button>Cancel</Button>
        </Col>
        {memberCount > 1 && (
          <Col>
            <Button onClick={handleSelectionTypeChange}>
              {selectionType === 'bulk' ? 'Customize Per Member' : 'Back: Bulk Select'}
            </Button>
          </Col>
        )}
        <Col>
          <Button type="primary" onClick={handleScreenChange} disabled={disableNextStep}>
            Next: Customize Email
          </Button>
        </Col>
      </Row>
    </>
  );
};

export const SendCatalogWrapper = () => {
  const { screen, setScreen } = useSendCatalogContext();
  const {
    params: {
      projectId,
    },
  } = useRouteMatch<{ projectId: string }>();

  const { showErrorMessage } = useMessagingContext();
  const { closeModal } = useApplication();

  const {
    allMembers, getMemberShippingAddress,
  } = useProductFulfillmentContext();

  const [showInvalidMembers, setShowInvalidMembers] = useState(true);

  const { projectConfig, loading: projectConfigLoading } = useGetProjectConfigByType({
    variables: {
      projectId: Number(projectId),
      type: ProjectConfigType.ProductCatalog,
    },
    skip: !projectId,
  });

  useEffect(() => {
    if (!projectConfigLoading) {
      if (isNil(projectConfig) || isEmpty(projectConfig?.brandCatalogs)) {
        showErrorMessage('Please connect a catalog to this project from project settings before sending an order request.', 10000);
        closeModal();
      }
    }
  }, [projectConfigLoading, projectConfig, closeModal, showErrorMessage]);

  const handleScreenChange = () => {
    setScreen(screen === 'sendCatalog' ? 'sendOrderRequestEmail' : 'sendCatalog');
  };

  if (projectConfigLoading) {
    return <Skeleton />;
  }

  const validMembers = allMembers ? allMembers.filter((m) => isEmailAddressValid(m.email)) : [];
  const invalidMembers = allMembers ? allMembers.filter((m) => !m.email) : [];

  if (!isEmpty(invalidMembers) && showInvalidMembers) {
    const memberInfo: BasicMember[] = map(invalidMembers, (member) => ({
      first_name: getMemberShippingAddress(member)?.first_name,
      last_name: getMemberShippingAddress(member)?.last_name,
      email: member.email,
    }));
    return (
      <InvalidMembersCPS
        memberInfo={memberInfo}
        onContinue={() => setShowInvalidMembers(false)}
        canContinue={!isEmpty(validMembers)}
        emailSubject="order requests"
        requiredFields={['email']}
      />
    );
  }

  return screen === 'sendCatalog' ? (
    <SendCatalog handleScreenChange={handleScreenChange} />
  ) : (
    <SendEmail handleScreenChange={handleScreenChange} />
  );
};
