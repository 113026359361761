import React from 'react';
import {
  Button,
 Input, Modal, Row, Select, Space, Typography,
} from '@revfluence/fresh';
import { CircleExclamationIcon, MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import { CatalogSelectionRuleConditionOperationType, CatalogType, SelectionType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { GET_CATALOG_COLLECTIONS_QUERY, GET_CATALOG_COLLECTION_BY_ID_QUERY, GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { useMessagingContext } from '@frontend/hooks';
import { GET_BRAND_CATALOGS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { useGetImportedCollections } from '../../Products/hooks/useGetImportedCollections';
import styles from './CatalogSelectionModal.scss';
import { ProductsTable } from './ProductsTable';
import { useBasicSelectionContext } from './BasicSelectionContext';
import { useCreateCatalogCollection } from '../hooks/useCreateCatalogCollection';
import { useUpdateCatalogCollection } from '../hooks/useUpdateCatalogCollection';
import { useCreateCatalog } from '../hooks/useCreateCatalog';
import { useCatalogDetailsContext } from '../CatalogDetails/CatalogDetailsContext';
import { pfaV2Routes } from '../../routes';
import { useGetCatalogCollections } from '../hooks/useGetCatalogCollections';
import { useAdvancedSelectionContext } from '../AdvancedSelectionModal/AdvancedSelectionContext';
import { useGetCatalogCollectionByIdQuery } from '../hooks/useGetCatalogCollectionById';
import { reloadPreviewIframe } from '../reloadPreviewIframe';

const { Text } = Typography;

export const CatalogSelectionModal = () => {
  const { collections } = useGetImportedCollections();
  const {
    search,
    setSearch,
    selectedProducts,
    resetBasicSelection,
    resetEditing,
    isOpen,
    collectionId,
    isEditing,
    setCollectionId,
    catalogCollectionId,
    bulkSelect,
  } = useBasicSelectionContext();
  const {
    setIsOpen: setAdvancedSelectionIsOpen,
    setState: setAdvancedSelectionState,
  } = useAdvancedSelectionContext();
  const { title, status, description } = useCatalogDetailsContext();
  const history = useHistory();

  const { catalogId } = useParams<{ catalogId: string }>();

  const { collectionItem } = useGetCatalogCollectionByIdQuery({
    variables: {
      id: catalogCollectionId,
    },
    skip: !catalogCollectionId,
  });

  const { collections: catalogCollectionItems } = useGetCatalogCollections({
    variables: {
      brandCatalogId: Number(catalogId),
    },
    skip: !catalogId || Number.isNaN(Number(catalogId)),
  });

  const {
    showErrorMessage,
  } = useMessagingContext();

  const { createCatalog } = useCreateCatalog({
    onCompleted: (data) => {
      history.push(pfaV2Routes.settings.brandCatalogCollections.replace(':catalogId', String(data?.createBrandCatalog?.id)));
    },
    onError: (error) => {
      console.error(error);
      showErrorMessage('Failed to create catalog');
    },
    refetchQueries: [GET_BRAND_CATALOGS_QUERY],
  });
  const { createCollectionItem, loading: isCreateCollectionLoading } = useCreateCatalogCollection();
  const { updateCollectionItem, loading: isUpdateCollectionLoading } = useUpdateCatalogCollection();

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleCollectionChange = (value: number) => {
    setCollectionId(value);
    bulkSelect({ products: [] });
    setSearch('');
  };

  const resetDrawer = () => {
    if (isEditing) {
      return resetEditing();
    }
    resetBasicSelection();
  };

  const handleCreateCollectionItem = async () => {
    let newCatalogId = Number(catalogId);
    if (catalogId === 'new') {
      const { data: catalog } = await createCatalog({
        variables: {
          input: {
            name: title,
            type: CatalogType.CUSTOM,
            status,
            description,
          },
        },
      });
      if (!catalog) {
        return;
      }
      newCatalogId = (catalog.createBrandCatalog.id);
    }
    const payload = {
      brandCatalogId: newCatalogId,
      selectionType: SelectionType.BASIC,
      catalogProductInput: selectedProducts.map((product) => ({
          productId: Number(product.productId),
          variantIds: product.variantIds.map((variantId) => Number(variantId)),
        })),
      collectionId,
      label: collections.find((col) => col.collectionId === collectionId)?.collection.name,
      imageUrl: collections.find((col) => col.collectionId === collectionId)?.collection.images?.[0],
    };
    if (isEditing) {
      return updateCollectionItem({
        refetchQueries: [
          GET_CATALOG_COLLECTIONS_QUERY,
          GET_CATALOG_COLLECTION_BY_ID_QUERY,
          GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY,
        ],
        variables: {
          input: payload,
          catalogCollectionId,
        },
        onCompleted: () => {
          resetDrawer();
          reloadPreviewIframe();
        },
        update: (cache) => {
          cache.evict({ fieldName: 'getStatsForAllCatalogs' });
          cache.evict({ fieldName: 'getStatsByCatalogId' });
        },
      });
    }
    createCollectionItem({
      refetchQueries: [
        GET_CATALOG_COLLECTIONS_QUERY,
        GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY,
      ],
      variables: {
        input: payload,
      },
      onCompleted: () => {
        resetDrawer();
        reloadPreviewIframe();
      },
      update: (cache) => {
        cache.evict({ fieldName: 'getStatsForAllCatalogs' });
        cache.evict({ fieldName: 'getStatsByCatalogId' });
      },
    });
  };

  const switchToAdvancedSelection = () => {
    if (isEditing) {
      Modal.confirm({
        title: 'Are you sure you want to switch to advance products selection for this item?',
        icon: <CircleExclamationIcon />,
        content: "Please note, switching to advance selection will remove all the existing products from this collection item. You can always switch back to manual selection, however, you'll need to re-add the products.",
        onOk: () => {
          updateCollectionItem({
            refetchQueries: [
              GET_CATALOG_COLLECTIONS_QUERY,
              GET_CATALOG_COLLECTION_BY_ID_QUERY,
              GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY,
            ],
            variables: {
              input: {
                brandCatalogId: Number(catalogId),
                selectionType: SelectionType.ADVANCED,
              },
              catalogCollectionId,
            },
            update: (cache) => {
              cache.evict({ fieldName: 'getStatsForAllCatalogs' });
              cache.evict({ fieldName: 'getStatsByCatalogId' });
            },
            onCompleted: () => {
              setAdvancedSelectionState({
                isOpen: true,
                isEditing: true,
                catalogCollectionId,
                label: collectionItem.label,
                operation: CatalogSelectionRuleConditionOperationType.OR,
                collections: [],
                vendors: [],
                productTypes: [],
                priceMin: null,
                priceMax: null,
                tags: [],
                categories: [],
                options: [],
              });
              resetBasicSelection();
              reloadPreviewIframe();
            },
          });
        },
        okText: 'Switch',
        width: '570px',
      });
      return;
    }
    setAdvancedSelectionIsOpen(true);
    resetBasicSelection();
  };

  const availableCollections = isEditing
    ? collections
    : collections.filter((col) => !catalogCollectionItems.find((item) => item.collectionId === col.collectionId));

  return (
    <Modal
      title={isEditing ? 'Manage Products' : 'Collection Item'}
      open={isOpen}
      onCancel={resetDrawer}
      onOk={handleCreateCollectionItem}
      okText={isEditing ? 'Update' : 'Add Collection Item'}
      confirmLoading={isCreateCollectionLoading || isUpdateCollectionLoading}
      cancelText="Cancel"
      width={620}
      className={styles.CatalogSelectionModal}
      okButtonProps={{
        disabled: !selectedProducts.length || !collectionId,
      }}
      cancelButtonProps={{
        disabled: isCreateCollectionLoading || isUpdateCollectionLoading,
      }}
    >
      <Space direction="vertical" size="large">
        <Space direction="vertical">
          <Row align="middle" justify="space-between">
            <Text weight="semibold">Select Collection</Text>
            <Button
              onClick={switchToAdvancedSelection}
              type="link"
              size="small"
              loading={isUpdateCollectionLoading}
            >
              Switch To Advance Selection
            </Button>
          </Row>
          <Select
            placeholder="Select Collection to add products from"
            value={collectionId}
            onChange={handleCollectionChange}
            className={styles.selectCollection}
            disabled={collectionItem.collectionId && isEditing}
            showSearch
            filterOption={(input, option) => String(option.children).toLowerCase().includes(input.toLowerCase())}
          >
            {availableCollections.map((collection) => (
              <Select.Option key={collection.collectionId} value={collection.collectionId}>
                {collection.collection.name}
              </Select.Option>
            ))}
          </Select>
        </Space>
        <Space direction="vertical">
          <Text weight="semibold">Select Products</Text>
          <Input.Group compact>
            <Select defaultValue="productName" className={styles.selectFilters} disabled={!collectionId}>
              <Select.Option value="productName">Product Name</Select.Option>
            </Select>
            <Input
              placeholder="Search"
              suffix={<MagnifyingGlassIcon />}
              className={styles.filtersInput}
              disabled={!collectionId}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Input.Group>
        </Space>
        <ProductsTable collectionId={collectionId} />
      </Space>
    </Modal>
  );
};
