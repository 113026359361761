import gql from 'graphql-tag';

import { GUIDELINE_FRAGMENT } from './fragments/GuidelineFragment';

export const SAVE_CONTENT_GUIDELINES_MUTATION = gql`
  mutation SaveContentGuidelines($guidelines: ContentGuidelineInput!, $programId: Int!) {
    guidelines: saveContentGuidelines(guidelines: $guidelines, programId: $programId) {
      id
      type
      label
      networkType
      priority
      scope
      attachments {
        filename
        url
        type
      }
      guidelines {
        ...GuidelineFragment
      }
    }
  }
  ${GUIDELINE_FRAGMENT}
`;
