import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as qs from 'qs';

import { SALES_APP_ID } from '@frontend/app/constants/applicationIds';
import { OFFER_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { logger } from '@common';
import { IWorkflowOfferErrorOffer } from '..';

const { useMemo } = React;

export const useButton = (
  offerSource: OFFER_TYPE,
  offer: IWorkflowOfferErrorOffer,
  programId: number,
  taskId: string,
) => {
  const location = useLocation();
  const history = useHistory();
  return useMemo(() => {
  switch (offerSource) {
    case OFFER_TYPE.PROMO_CODE: {
      return {
        onEditOfferClick() {
          history.push({
            ...location,
            pathname: `/app/${SALES_APP_ID}/offers/${offer.id}/edit`,
            ...(programId && taskId) && {
              search: qs.stringify({
                program_id: programId,
                task_id: taskId,
              }),
            },
            state: { isNewFlow: offer.isNewFlow },
          });
        },
      };
    }
    case OFFER_TYPE.LINK: {
      return {
        onEditOfferClick() {
          history.push({
            ...location,
            pathname: `/app/${SALES_APP_ID}/offers/${offer.id}/edit`,
            ...(programId && taskId) && {
              search: qs.stringify({
                program_id: programId,
                task_id: taskId,
              }),
            },
          });
        },
      };
    }
    default: {
      logger.warning('Invalid offer type on useButton');
      return {
        onEditOfferClick() { },
      };
    }
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [offerSource, offer]);
};
