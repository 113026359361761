import * as React from 'react';
import { isEmpty, isUndefined, some } from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { validateStartAndTimeRange } from '@affiliates/components/MembersWizard/utils/validatePromoCodeDates';
import { IRefreshPromoCodeMembers } from '../../types';
import { TActions, TState } from './state';
import { getSteps } from './getSteps';
import { getWorkflowPromoCodeSteps } from './getWorkflowPromoCodeSteps';

import styles from '../../components/MembersWizard.scss';

export const getPromoCodeSteps = (props: IRefreshPromoCodeMembers, state: TState, actions: TActions) => {
  const {
    onClose,
    offerSource,
    refreshDatesOnly,
    isWorkflow,
  } = props;

  const { isInValidTimeRange, isInvalidStartRange } = validateStartAndTimeRange(state.activeDates);

  if (isWorkflow) {
    return getWorkflowPromoCodeSteps(props, state, actions);
  }

  const {
    selectMembers,
    reviewMembers,
    completed,
    selectedMembers,
    selectActiveDates,
  } = getSteps(props, state, actions);
  if (offerSource !== OFFER_SOURCE.SHOPIFY) {
    return [];
  }
  let nextButtonDisabled = true;
  if (!isEmpty(selectedMembers)) {
    nextButtonDisabled = some(selectedMembers, (m) => m.code.length < 1 || m.isDuplicate);
  }

  const reviewButtonDisabled = isUndefined(state.activeDates.startDate)
    || isUndefined(state.activeDates.startTime)
    || isUndefined(state.activeDates.endDate)
    || isUndefined(state.activeDates.endTime)
    || isInValidTimeRange
    || isInvalidStartRange;

  const newStepsenable = props.isNewFlow;

  const newSteps = [
    selectMembers({
      instructions: {
        description: refreshDatesOnly ? (
          'Confirm these are the members whose promo code active dates you want to change.'
        ) : (
          'Add codes for the members who will be receiving new codes for this promotion.'
        ),
        title: refreshDatesOnly ? (
          'Confirm the active dates for these members.'
        ) : (
          'Give these members new codes.'
        ),
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Confirm Member',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    reviewMembers({
      instructions: {
        description: refreshDatesOnly ? (
          'Make sure everything looks good before you change the dates for these promo codes.'
        ) : (
          'Make sure everything looks good before you send the promo codes.'
        ),
        title: refreshDatesOnly ? (
          'Review your promo code changes.'
        ) : (
          'Review your promo code details.'
        ),
      },
      nextButtonConfig: {
        action: actions.save,
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Select Member',
      },
      stepNum: 3,
    }),
    completed({
      instructions: {
        description: refreshDatesOnly ? (
          'Once the dates are updated for these codes, you can email the members their new dates.'
        ) : (
          `
            Their unique codes are starting to be generated, it should only take a
            few minutes. You can close this window. Once all codes have been
            generated, you can email these members their codes using the
            “Email Members” button above the table on this offer page.
          `.trim()
        ),
        title: refreshDatesOnly ? (
          `
            Congrats! You are updating the active dates for ${`${selectedMembers.length.toLocaleString()} `}
            member${selectedMembers.length !== 1 ? 's' : ''}.
          `.trim()
        ) : (
          `
            Congrats! You are creating new promo codes for ${`${selectedMembers.length.toLocaleString()} `}
            member${selectedMembers.length !== 1 ? 's' : ''}.
          `.trim()
        ),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 4,
    }),
  ];
  const steps = [
    selectMembers({
      instructions: {
        description: refreshDatesOnly ? (
          'Confirm these are the members whose promo code active dates you want to change.'
        ) : (
          'Add codes for the members who will be receiving new codes for this promotion.'
        ),
        title: refreshDatesOnly ? (
          'Confirm the active dates for these members.'
        ) : (
          'Give these members new codes.'
        ),
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Set Active Dates',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    selectActiveDates({
      instructions: {
        description: refreshDatesOnly ? (
          'Set new active dates for the members you selected.'
        ) : (
          'These dates are for this set of members. You will be able to change the dates for new members you give codes to or when you replace a member\'s code.'
        ),
        title: refreshDatesOnly ? (
          'Change their promo code’s active dates.'
        ) : (
          'Set how long these promo codes will be active.'
        ),
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: reviewButtonDisabled,
        text: 'Review',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: false,
        text: 'Confirm Members',
      },
      stepNum: 2,
    }),
    reviewMembers({
      instructions: {
        description: refreshDatesOnly ? (
          'Make sure everything looks good before you change the dates for these promo codes.'
        ) : (
          'Make sure everything looks good before you send the promo codes.'
        ),
        title: refreshDatesOnly ? (
          'Review your promo code changes.'
        ) : (
          'Review your promo code details.'
        ),
      },
      nextButtonConfig: {
        action: actions.save,
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Set Active Dates',
      },
      stepNum: 3,
    }),
    completed({
      instructions: {
        description: refreshDatesOnly ? (
          'Once the dates are updated for these codes, you can email the members their new dates.'
        ) : (
          `
            Their unique codes are starting to be generated, it should only take a
            few minutes. You can close this window. Once all codes have been
            generated, you can email these members their codes using the
            “Email Members” button above the table on this offer page.
          `.trim()
        ),
        title: refreshDatesOnly ? (
          `
            Congrats! You are updating the active dates for ${`${selectedMembers.length.toLocaleString()} `}
            member${selectedMembers.length !== 1 ? 's' : ''}.
          `.trim()
        ) : (
          `
            Congrats! You are creating new promo codes for ${`${selectedMembers.length.toLocaleString()} `}
            member${selectedMembers.length !== 1 ? 's' : ''}.
          `.trim()
        ),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 4,
    }),
  ];
  let currentSteps = newStepsenable ? newSteps : steps;

  if (state.error) {
    currentSteps = currentSteps.map((currentSteps) => ({
      ...currentSteps,
      actionComponents: (
        <Notice type="error" message={state.error.message} />
      ),
    }));
  }
  return currentSteps;
};
