import { IState } from '../../../../types/state';
import { IToggleDisableCreatorEdits } from './index';

export const toggleDisableCreatorEdits = (state: IState, action: IToggleDisableCreatorEdits): IState => {
  if (state.collaborationDetails.disableCreatorEdits === action.value) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      disableCreatorEdits: action.value,
    },
  };
};
