import React from 'react';
import {
 Select, SelectContent, SelectItem, SelectTrigger, SelectValue,
} from '@frontend/shadcn/components/ui/select';
import { SelectProps, SelectTriggerProps } from '@radix-ui/react-select';
import { cn } from '@frontend/shadcn/lib/utils';
import { Input, InputProps } from '@frontend/shadcn/components/ui/input';

interface Item {
  value: string;
  label: string;
}
interface SelectWithInputProps {
  items: Item[];
  wrapperProps?: React.ComponentProps<'div'>;
  selectProps?: SelectProps
  inputProps?: InputProps
  selectTriggerProps?: SelectTriggerProps;
}
const SelectWithInput = ({
 items, wrapperProps, selectProps, inputProps, selectTriggerProps,
}: SelectWithInputProps) => (
  <div {...wrapperProps} className={cn('flex items-center', wrapperProps?.className)}>
    <Select {...selectProps}>
      <SelectTrigger {...selectTriggerProps} className={cn('rounded-r-none', selectTriggerProps?.className)}>
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {
            items.map((item) => (
              <SelectItem key={item.value} value={item.value}>
                {item.label}
              </SelectItem>
            ))
          }
      </SelectContent>
    </Select>
    <Input {...inputProps} className={cn('rounded-l-none', inputProps?.className)} />
  </div>
  );

export default SelectWithInput;
