import * as React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from 'lodash';

const {
 useCallback, useMemo, useState, useEffect,
} = React;

// override it for now
// https://github.com/que-etc/resize-observer-polyfill/issues/53
interface IDOMRectReadOnly {
  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly left: number;
}
interface IOptions {
  delay?: number;
}

/**
 * Listens to the element's resize event.
 *
 * @return {IDOMRectReadOnly}
 */
export const useElementResize = (
  ref: React.MutableRefObject<HTMLElement>,
  options: IOptions = { delay: 0 },
): IDOMRectReadOnly => {
  const [domRect, setDomRect] = useState<IDOMRectReadOnly>(null);
  const debouncedSetDomRect = useMemo(() => debounce(setDomRect, options.delay), [options.delay]);

  /**
   * Callback for ResizeObserver.
   *
   * @param {ResizeObserverEntry} entries the resize observer entries.
   */
  const resizeCallback = useCallback((entries: ResizeObserverEntry[]) => {
    const entry = entries[0];
    const node = ref.current;

    if (entry && node) {
      debouncedSetDomRect(entry.contentRect);
    }
  }, [ref, debouncedSetDomRect]);

  useEffect(() => {
    const node = ref && ref.current;
    if (!node) {
      return;
    }

    const observer = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => window.requestAnimationFrame(() => resizeCallback(entries)),
    );
    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [ref, resizeCallback]);

  return domRect;
};
