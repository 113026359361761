import * as React from 'react';

import {
  ConnectedImageVideoUpload,
  IRadioChangeEvent,
} from '@frontend/app/components';
import {
  defaultPersona,
} from '@frontend/app/components/ApplicationPageTemplates/CustomizedTemplate/constants';

import { Input, Radio } from '@revfluence/fresh';
import { ShowSection, TextItemsOptions } from '../FormComponents';
import { useUploadProps } from '../hooks/useUploadProps';
import { TTemplateProps } from '../../types';
import { IMAGES_HINTS } from './imagesHints';

import styles from './Persona.scss';
import { ApplicationPageBuilderComponent, isBuilderComponentUsedByTemplate, ProjectApplicationPageTemplateName } from '../../../applicationPageUtils';

const { useCallback } = React;

type TPersonaProps = TTemplateProps['persona'];

interface IProps {
  projectId: number;
  personaProps?: TPersonaProps;
  onChange?(values: TPersonaProps);
  template: ProjectApplicationPageTemplateName;
}

const bulletOptions = [
  { value: 'dashes', label: 'Dashes' },
  { value: 'bullets', label: 'Bullets' },
  { value: 'none', label: 'None' },
];

export const Persona: React.FC<IProps> = (props) => {
  const {
    projectId,
    personaProps,
    onChange,
    template,
  } = props;

  const uploadProps = useUploadProps(projectId);

  const handleChange = useCallback((values: Partial<TPersonaProps>) => {
    if (onChange) {
      onChange({ ...personaProps, ...values });
    }
  }, [personaProps, onChange]);

  const handleChangeShow = useCallback((show: boolean) => {
    handleChange({ show });
  }, [handleChange]);

  const handleChangeCoverImage = useCallback((coverImage: string) => {
    handleChange({ cover_image: coverImage });
  }, [handleChange]);

  const handleChangeBulletStyle = useCallback((e: IRadioChangeEvent) => {
    const bulletStyle = e.target.value as TPersonaProps['bullet_style'];
    handleChange({ bullet_style: bulletStyle });
  }, [handleChange]);

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ title: e.target.value });
  }, [handleChange]);

  const handleChangeFeatures = useCallback((features: string[]) => {
    handleChange({ features });
  }, [handleChange]);

  return (
    <div className={styles.Persona}>
      <ShowSection show={!(personaProps?.show === false)} onChange={handleChangeShow} />
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.PersonaTitle,
          template,
        )
          && (
            <div className={styles.row}>
              <div className={styles.rowDescription}>
                Persona Title
              </div>
              <Input
                placeholder="Who you are"
                value={personaProps?.title}
                onChange={handleChangeTitle}
              />
            </div>
          )
      }
      <div className={styles.row}>
        <div className={styles.subtitle}>Cover image</div>
        <ConnectedImageVideoUpload
          defaultImageSrc={personaProps?.cover_image}
          uploadProps={uploadProps}
          showCropper
          cropperProps={{ aspectRatio: 0.8 }}
          onUpload={handleChangeCoverImage}
          onRemove={handleChangeCoverImage.bind(this, undefined)}
          hintText={IMAGES_HINTS.PERSONA_IMAGE}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.subtitle}>Bullet style</div>
        <Radio.Group
          buttonStyle="solid"
          optionType="button"
          options={bulletOptions}
          onChange={handleChangeBulletStyle}
          value={personaProps?.bullet_style || defaultPersona.bullet_style}
        />
      </div>
      <div className={styles.row}>
        <TextItemsOptions
          items={personaProps?.features || Array(4).fill(undefined)}
          onChange={handleChangeFeatures}
          min={1}
          max={6}
          placeholder="List out characteristics you are looking for"
          type="Criteria"
        />
      </div>
    </div>
  );
};
