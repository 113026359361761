import * as React from 'react';
import { difference, isNil } from 'lodash';
import cx from 'classnames';

import { Collapse as AntCollapse } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';
import { CaretRightOutlined } from '@ant-design/icons';

import styles from './Collapse.scss';

const { useState } = React;

export interface ICollapseProps extends CollapseProps {
  onOpen?: (key: string | number) => void
}

export const Collapse: React.FC<ICollapseProps> = (props) => {
  const [previousCollapsed, setPreviousCollapsed] = useState([]);

  const handleChange = (collapsed) => {
    const newCollapsedArr = difference(collapsed, previousCollapsed);
    const newCollapsed = newCollapsedArr.length
      ? newCollapsedArr[0]
      : null;

    /** This means, new element has been opened */
    if (!isNil(newCollapsed)) {
      props.onOpen?.(newCollapsed);
    }

    setPreviousCollapsed(collapsed);
  };

  return (
    <AntCollapse
      {...props}
      onChange={handleChange}
      className={cx(styles.Collapse, props.className)}
      expandIconPosition="right"
      expandIcon={({ isActive }) => <CaretRightOutlined className={styles.arrow} rotate={isActive ? -90 : 90} />}
      bordered={false}
      ghost
    />
  );
};

export const CollapsePanel = AntCollapse.Panel;
