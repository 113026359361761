import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const MentionIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path d="M16.2097 4.5194C15.0986 3.21763 13.113 1.66602 9.95065 1.66602C4.54631 1.66602 1.66663 6.00527 1.66663 9.13479C1.66663 11.9717 2.49174 14.1611 4.11896 15.6502C5.71002 17.1065 8.03086 17.8396 11.0355 17.8396C11.6338 17.8396 12.2616 17.81 12.9158 17.7508C13.1887 17.7278 13.3859 17.4878 13.3629 17.215C13.3366 16.9422 13.0999 16.7416 12.827 16.7679C9.18471 17.0934 6.47925 16.4721 4.78628 14.9237C3.36945 13.6285 2.65282 11.6825 2.65282 9.13479C2.65282 6.87312 4.79286 2.65221 9.95065 2.65221C12.9322 2.65221 17.3438 5.0125 17.3438 10.0782C17.3438 12.5109 15.6508 12.5832 15.4569 12.5832C15.4405 12.5832 15.4207 12.5832 15.3912 12.5865C15.2465 12.593 14.8553 12.616 14.4576 12.2314C14.1222 11.9093 13.7574 11.2683 13.5667 10.0158C13.5634 9.77581 13.5371 9.54241 13.4878 9.31559C13.4483 8.82249 13.4286 8.25379 13.4483 7.59961C13.4549 7.32677 13.2412 7.09994 12.9684 7.09337C12.735 7.07693 12.5213 7.25773 12.4753 7.48784C11.8244 6.85339 10.9336 6.45892 9.95394 6.45892C7.95854 6.45892 6.33461 8.08285 6.33461 10.0782C6.33461 12.0736 7.95854 13.6976 9.95394 13.6976C11.2853 13.6976 12.4457 12.9744 13.0769 11.8994C13.2675 12.3202 13.4977 12.6686 13.7738 12.9349C14.2439 13.3919 14.8093 13.6088 15.4602 13.5661C16.5286 13.5661 18.3333 12.8297 18.3333 10.075C18.3267 8.15517 17.5345 6.07759 16.2097 4.5194ZM9.95065 12.7114C8.49766 12.7114 7.31751 11.5312 7.31751 10.0782C7.31751 8.62525 8.49766 7.44511 9.95065 7.44511C11.1965 7.44511 12.2386 8.31625 12.5115 9.47995C12.5312 9.69034 12.5542 9.89416 12.5838 10.0914C12.5739 11.5345 11.3971 12.7114 9.95065 12.7114Z" />
  </SvgIcon>
));

MentionIcon.displayName = 'MentionIcon';
