import * as React from 'react';
import { XAxisProps, YAxisProps } from 'recharts';
import styles from '../Charts.module.scss';
import { tickFormatters, CustomTickFormat } from '../utils/tickFormatters';

const { useMemo } = React;

interface ITickProps {
  payload: { value: string | number };
  className: string;
  height: number;
  width: number;
  orientation: string;
  textAnchor: string;
  x: number;
  y: number;
  tickFormatter(v: number | string): string;
}
const fontSizeSm = Number(styles['font-size-sm'].replace('px', ''));
const spacingXS = Number(styles['spacing-xs'].replace('px', ''));
const gray6 = styles['gray-6'];
const green7 = styles['green-7'];

export type AxisProps = {
  customTickFormat?: CustomTickFormat;
  referenceValues?: (number | string)[];
  referenceColor?: string;
  dateFormat?: string;
} & XAxisProps &
  YAxisProps;

export const useAxisProps = (props?: AxisProps) =>
  useMemo(() => {
    if (!props) {
      return {};
    }
    const {
      fontSize = fontSizeSm,
      fill: fillProp = gray6,
      referenceColor = green7,
      referenceValues = [],
      customTickFormat = 'string',
      tickMargin = spacingXS,
      ...rest
    } = props;
    const returnProps = {
      axisLine: false,
      tickLine: false,
      tickMargin,
      tick: (tickProps: ITickProps) => {
        const payload: string | number = tickProps.payload.value;
        const customTickFormatter = tickFormatters[customTickFormat];
        const value = tickProps.tickFormatter
          ? tickProps.tickFormatter(payload)
          : customTickFormatter(payload);
        const fill = referenceValues.includes(payload as number)
          ? referenceColor
          : fillProp;
        return (
          <text
            className={tickProps.className}
            height={tickProps.height}
            width={tickProps.width}
            orientation={tickProps.orientation}
            textAnchor={tickProps.textAnchor}
            x={tickProps.x}
            y={tickProps.y}
            fill={fill}
            fontSize={fontSize}
          >
            <tspan x={tickProps.x} dy="0.355em">
              {value}
            </tspan>
          </text>
        );
      },
      ...rest,
    };
    return returnProps;
  }, [props]);
