import * as querystring from 'querystring';

import { ISocialAccount, TNetworkIdentifier } from '@components';

import { logger } from '@common';

export interface ISocialAccountInstagram extends ISocialAccount {
  id: number;
  username: string;
  has_social_listening_api_access: boolean;
  has_creator_marketplace_api_access: boolean;
  has_messaging_api_access: boolean;
  has_analytics_api_access: boolean;
  network_identifier: TNetworkIdentifier;
  has_branded_content_tag_access: boolean;
  has_partnership_ads_access: boolean;
  has_messaging_toggle_enabled: boolean;
}

const fetchSocialData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

interface IFetchSocialAccountDataOptions {
  url: string;
  clientId: string;
  username: string;
  fetchAll?: boolean;
  network?: TNetworkIdentifier;
  include_social_profile_data?: boolean;
  version?: string;
}

export async function fetchSocialDataForMember(options: IFetchSocialAccountDataOptions) {
  const {
 url: baseUrl, clientId, username, network, include_social_profile_data,
} = options;
  if (!username) {
    return { data: null, loading: true, error: 'user name required' };
  }
  try {
    const filterString = querystring.stringify({
      member_username: username,
      client_id: clientId,
      network,
      include_social_profile_data,
    });
    const url = `${baseUrl}?${filterString}`;
    const socialData = await fetchSocialData(url);
    const { id } = socialData[0];

    const filterStringDetail = querystring.stringify({
      social_account_id: id,
      brand_instagram_username: username || undefined,
      client_id: clientId,
      include_social_profile_data: true,
    });
    const detailUrl = `${baseUrl}?${filterStringDetail}`;
    const detailedSocialDataForMember = await fetchSocialData(detailUrl);
    return {
      data: detailedSocialDataForMember.data,
      error: null,
    };
  } catch (error) {
    throw new Error(('Error while fetching social account data'));
  }
}
