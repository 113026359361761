import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const ToolsIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M22.4,3l-1.3-1.3c-0.2-0.2-0.4-0.2-0.6-0.1l-3.1,2.1c-0.1,0.1-0.2,0.2-0.2,0.4s0,0.3,0.1,0.4L18,5.3l-8.6,8.6
      L8,12.4c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l0.6,0.6L2.5,19C2.2,19.4,2,19.8,2,20.3s0.2,0.9,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5
      c0,0,0,0,0,0c0.5,0,0.9-0.2,1.3-0.5l5.4-5.4l0.6,0.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.5-1.5L18.7,6
      l0.7,0.7c0.1,0.1,0.2,0.1,0.4,0.1c0,0,0,0,0,0c0.1,0,0.3-0.1,0.4-0.2l2.2-3C22.5,3.5,22.5,3.2,22.4,3z M4.3,20.9L4.3,20.9
      C4.2,21,4,21.1,3.8,21.1c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.6-0.2C3,20.7,3,20.5,3,20.3s0.1-0.4,0.2-0.6l5.4-5.4l1.1,1.1L4.3,20.9z
      M19.7,5.6l-1.3-1.3l2.2-1.6l0.7,0.7L19.7,5.6z"
    />
    <path
      d="M6.7,9.3l3,3l0.7-0.7L7.2,8.4C7,8.3,6.8,8.2,6.6,8.3C5.5,8.8,4.2,8.5,3.4,7.7C2.7,7,2.4,6.1,2.5,5.2l1.2,1.1
      c0.1,0.1,0.2,0.1,0.3,0.1h1.7c0.3,0,0.5-0.2,0.5-0.5V4.2c0-0.1,0-0.2-0.1-0.3L5.1,2.6C6,2.5,6.9,2.8,7.6,3.5
      c0.9,0.9,1.1,2.1,0.6,3.3c-0.1,0.2,0,0.4,0.1,0.5l3.3,3.3l0.7-0.7l-3-3c0.5-1.4,0.1-3-1-4C7.1,1.6,5.5,1.3,4,1.9
      C3.8,2,3.7,2.1,3.7,2.2c0,0.2,0,0.3,0.1,0.4l1.5,1.7v1h-1L2.6,3.9C2.5,3.8,2.3,3.7,2.2,3.8C2,3.8,1.9,3.9,1.8,4.1
      C1.2,5.6,1.5,7.3,2.7,8.4C3.7,9.5,5.3,9.8,6.7,9.3z"
    />
    <path
      d="M17.1,14.7l-3-3l-0.7,0.7l3.3,3.3c0.1,0.1,0.4,0.2,0.5,0.1c1.1-0.5,2.4-0.2,3.3,0.6c0.7,0.7,1,1.6,0.8,2.5
      L20,17.7c-0.1-0.1-0.2-0.1-0.3-0.1H18c-0.3,0-0.5,0.2-0.5,0.5v1.7c0,0.1,0,0.3,0.1,0.3l1.2,1.2c-0.9,0.1-1.8-0.2-2.5-0.8
      c-0.9-0.9-1.1-2.1-0.6-3.3c0.1-0.2,0-0.4-0.1-0.5l-3.3-3.3l-0.7,0.7l3,3c-0.5,1.4-0.1,3,1,4c0.8,0.8,1.8,1.2,2.8,1.2
      c0.5,0,1.1-0.1,1.6-0.3c0.2-0.1,0.3-0.2,0.3-0.4c0-0.2,0-0.3-0.1-0.4l-1.6-1.7v-1h1l1.7,1.6c0.1,0.1,0.3,0.2,0.4,0.1
      c0.2,0,0.3-0.1,0.4-0.3c0.6-1.5,0.3-3.2-0.8-4.4C20.1,14.6,18.6,14.2,17.1,14.7z"
    />
  </SvgIcon>
));

ToolsIcon.displayName = 'ToolsIcon';

export { ToolsIcon };
