import * as React from 'react';
import ShopifyStoreDisconnectionAlert from '@frontend/applications/AffiliatesApp/components/ShopifyStoreDisconnectionAlert/ShopifyStoreDisconnectionAlert';
import { IRecommendation } from './components/AspireAdvice';

export interface IHistoricalData {
  dateTime: string;
  value: number;
}

interface IValue {
  value: number;
  percentDiff: number | null;
}

type TValueWithHistoricalData = IValue & { historicalData: IHistoricalData[] };

export interface IImpactHomeProps {
  Alert: React.ReactNode;
  clientStartDate: Date;
  impactData: {
    totalValue: number;
    investment: number;
    impact: number | null;
    percentDiff: number;
  };
  contentData: TValueWithHistoricalData;
  impressionsData: TValueWithHistoricalData;
  engagementsData: TValueWithHistoricalData;
  salesData: TValueWithHistoricalData;
  trends: {
    reach: IValue;
    views: IValue;
    conversions: IValue;
    creators: IValue;
    cpm: IValue;
    cpe: IValue;
    tmv: IValue;
  };
  recommendation: IRecommendation;
}

interface IHomeLayout {
  Header: React.ReactNode;
  Alert: React.ReactNode;
  ImpactComponent: React.ReactNode;
  MetricsPrimary: React.ReactNode;
  MetricsSecondary: React.ReactNode;
}

export const RefreshHomeLayout = ({
  Header,
  Alert,
  ImpactComponent,
  MetricsPrimary,
  MetricsSecondary,
}: IHomeLayout) => (
  <div className="space-y-12">
    <div className="w-full">{Header}</div>
    {Alert}
    <ShopifyStoreDisconnectionAlert refreshUi />
    <div className="hidden lg:grid grid-cols-1 gap-6 lg:grid-cols-8 xl:grid-cols-12">
      <div className="lg:col-span-3 xl:col-span-4">
        {ImpactComponent}
      </div>
      <div className="lg:col-span-5 xl:col-span-8 space-y-8 flex flex-col justify-center">
        {MetricsPrimary}
        {MetricsSecondary}
      </div>
    </div>
    <div className="lg:hidden space-y-8">
      {ImpactComponent}
      {MetricsPrimary}
      {MetricsSecondary}
    </div>
  </div>
);
