import * as React from 'react';
import cx from 'classnames';

import { Checkbox } from '@components';
import { map, times, isEmpty } from 'lodash';

import { Select } from './Select';

import { IProduct } from '../hooks';

interface IProps {
  product: IProduct;
  selectedProducts
  setSelectedProducts(products: IProduct[]): void;
  resourceId: number;
  onGetMoreVariants(productData: GetProductDetailsQuery_productDetail): void;
  bypassShopifyInventory: boolean;
}

// This prevents us giving us an unboundedly large dropdown to select quantity from
import styles from './DetailedProductView.scss';
import GetMoreVariants from './GetMoreVariants';
import { GetProductDetailsQuery_productDetail } from '../queries/types/GetProductDetailsQuery';
import { ProductVariantInventoryPolicy } from '../types/globalTypes';

const MAXIMUM_PRODUCTS_TO_SEND = 100;

export const DetailedProductView: React.FunctionComponent<IProps> = (props) => {
  const {
    setSelectedProducts,
    selectedProducts,
    product,
    resourceId,
    onGetMoreVariants,
    bypassShopifyInventory,
  } = props;

  const toggleVarient = (variant, flag) => {
    if (flag) {
      setSelectedProducts({ ...selectedProducts, [variant.id]: { product, variant, quantity: 1 } });
    } else {
      const tmp = { ...selectedProducts };
      delete tmp[variant.id];
      setSelectedProducts(tmp);
    }
  };

  const setQuantity = (variant, quantity) => {
    setSelectedProducts({ ...selectedProducts, [variant.id]: { product, variant, quantity } });
  };

  return (
    <div className={styles.detailedProductView}>
      <div className={styles.header}>
        <img src={product.image?.src} className={styles.image} />
        <div className={styles.details}>
          <div className={styles.title}>{product.title}</div>
        </div>
      </div>
      {
        map(product.variants, (variant) => {
          const ignoreInventory = bypassShopifyInventory || variant.inventoryPolicy === ProductVariantInventoryPolicy.CONTINUE;
          const maxCount = ignoreInventory
            ? MAXIMUM_PRODUCTS_TO_SEND
            : Math.min(variant?.sellableOnlineQuantity, MAXIMUM_PRODUCTS_TO_SEND);
          const options = times(maxCount, (index) => ({ label: index + 1, value: index + 1 }));
          const selected = !!selectedProducts[variant.id];

          return (
            <div key={variant.id} className={styles.variantRow}>
              <div className={styles.variantDetails}>
                <Checkbox
                  checked={selected}
                  onChange={(flag) => toggleVarient(variant, flag)}
                  disabled={!ignoreInventory && isEmpty(options)}
                  label={(
                    <div className={styles.productTitle}>
                      <span>{variant?.title}</span>
                      <span>
                        {variant?.sellableOnlineQuantity}
                        {' '}
                        in stock
                      </span>
                    </div>
                  )}
                />
              </div>

              <div className={styles.variantPrice}>
                <div className={styles.row}>
                  {
                    selected
                    && (
                      <>
                        <div className={cx([styles.priceDetails])}>
                          $
                          {variant.price}
                        </div>
                        <div className={styles.multiply}>x</div>
                        <Select
                          placeholder="Select"
                          options={options}
                          className={[styles.priceDetails, styles.quantitySelect]}
                          onChange={(q) => setQuantity(variant, q)}
                          selectedValue={selectedProducts[variant.id]?.quantity}
                        />
                      </>
                    )
                  }
                  <div className={cx([styles.total, styles.priceDetails, !selected && styles.grayText])}>{`$${(selectedProducts[variant.id]?.quantity || 1) * Number(variant.price)}`}</div>
                </div>
              </div>
            </div>
          );
        })
      }
      {product?.variants?.length >= 90 && (
        <GetMoreVariants
          resourceId={resourceId}
          onGetMoreVariants={onGetMoreVariants}
          productId={product.id}
        />
      )}
    </div>
  );
};
