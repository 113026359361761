import gql from 'graphql-tag';

export const GET_RESOURCES_QUERY = gql`
  query GetResourcesQuery {
    resources: getResources {
      id
      type
      authProviderId
      scopes
    }
  }
`;
