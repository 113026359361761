import * as React from 'react';
import {
  every, filter, isNil, map, join, identity, reduce, isFunction, some, trim,
} from 'lodash';
import {
  Button, Space, Typography, Spinner, Tooltip,
} from '@revfluence/fresh';
import { PencilIcon, XmarkIcon, CheckIcon } from '@revfluence/fresh-icons/solid/esm';
import { CopyIcon } from '@revfluence/fresh-icons/regular/esm';

import { MemberInput } from '@frontend/app/types/globalTypes';
import { IMemberFieldSchema } from '@frontend/app/hooks';
import FieldRow from './FieldRow';

import styles from './FieldRow.scss';

const {
  useMemo, useState, useEffect, useCallback,
} = React;
const { Text } = Typography;

interface Props {
  label: string;
  member: MemberInput;
  schemas: IMemberFieldSchema[];
  separator?: string;
  copyable?: boolean;
  onSave: (val: Record<number, string>) => Promise<void>;
  renderValues?: (values: string[]) => React.ReactNode;
  copyValues?: (values: string[]) => void;
}

const GroupFieldRow: React.FunctionComponent<Props> = ({
  label, member, schemas, separator, copyable, copyValues, onSave, renderValues,
}) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState<Record<number, string>>({});

  const renderedValue = useMemo(() => {
    const isFieldsValid = every(schemas, (field) => !isNil(field));
    if (!isFieldsValid || !member) {
      return '-';
    }

    const values = map(schemas, (field) => member.fields[field.id] || '');

    if (isFunction(renderValues)) {
      return renderValues(values);
    } else {
      const result = trim(join(filter(values, identity), separator));

      return result || '-';
    }
  }, [member, schemas, separator, renderValues]);

  const hasValues = useMemo(() => {
    const isFieldsValid = every(schemas, (field) => !isNil(field));
    if (!isFieldsValid || !member) {
      return false;
    }

    return some(schemas, (schema) => member.fields[schema.id] || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member, schemas, separator]);

  const handleCopy = () => {
    const values = map(schemas, (field) => member.fields[field.id] || '');
    if (isFunction(copyValues)) {
      copyValues(values);
      return;
    }

    const result = trim(join(filter(values, identity), separator));

    navigator.clipboard.writeText(result);
  };

  const handleUpdate = (field: IMemberFieldSchema, val: string) => {
    setValue((value) => ({
      ...value,
      [field.id]: val,
    }));
  };

  const handleSave = async () => {
    setEditing(false);
    setSaving(true);
    try {
      await onSave(value);
    } catch {
      setValueFromMember();
    }

    setSaving(false);
  };

  const setValueFromMember = useCallback(
    () => {
      if (isNil(member)) {
        setValue({});
        return;
      }

      const valueFromMember = reduce(
        schemas,
        (values, field) => ({
          ...values,
          [field.id]: member.fields[field.id],
        }),
        {},
      );
      setValue(valueFromMember);
    },
    [schemas, member],
  );

  useEffect(() => {
    setValueFromMember();
  }, [setValueFromMember]);

  if (!member) {
    return <Spinner />;
  }

  if (editing) {
    return (
      <Space direction="vertical" className={styles.row}>
        {map(schemas, (field) => (
          <FieldRow
            key={field.id}
            direction="vertical"
            label={field.name}
            value={value[field.id]}
            editable
            type={field.type}
            choices={field.choices}
            editing
            hideActions
            onUpdate={(val: string) => {
              handleUpdate(field, val);
            }}
          />
        ))}

        <div className={styles.actions}>
          <Button
            type="text"
            size="small"
            icon={<XmarkIcon />}
            onClick={() => {
              setEditing(false);
              setValueFromMember();
            }}
          />
          <Button
            type="link"
            size="small"
            icon={<CheckIcon />}
            onClick={handleSave}
          />
        </div>
      </Space>
    );
  }

  return (
    <Space
      className={styles.row}
      direction="vertical"
      size={0}
    >
      <Text type="secondary" ellipsis>{label}</Text>
      <Text className={styles.value}>
        {renderedValue}
        {saving ? (
          <Spinner />
        ) : (
          <Button
            className={styles.edit}
            type="link"
            size="small"
            icon={<PencilIcon />}
            onClick={() => {
              setEditing(true);
            }}
          />
        )}
      </Text>
      {copyable && !saving && hasValues && (
        <Tooltip title="Copy">
          <Button
            icon={<CopyIcon />}
            size="small"
            onClick={handleCopy}
          />
        </Tooltip>
      )}
    </Space>
  );
};

export default GroupFieldRow;
