import { MutationHookOptions } from '@apollo/client';

import { useMutation } from '@frontend/app/hooks';
import { UPDATE_REQUIREMENT } from '@frontend/app/queries';
import {
  UpdateRequirementMutation,
  UpdateRequirementMutationVariables,
} from '@frontend/app/queries/types/UpdateRequirementMutation';

type IOptions = MutationHookOptions<UpdateRequirementMutation, UpdateRequirementMutationVariables>;

export const useUpdateRequirementMutation = (options: IOptions = {}) => useMutation<UpdateRequirementMutation, UpdateRequirementMutationVariables>(UPDATE_REQUIREMENT, options);
