/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Image_hs4k3_48 {
  opacity: 0;
  will-change: all;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._Image_hs4k3_48._completed_hs4k3_53 {
  opacity: 1;
  transition: all 0s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}