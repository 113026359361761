import * as React from 'react';
import cx from 'classnames';
import { map, capitalize } from 'lodash';

import {
  BarChart,
  LoadSpinner,
  Notice,
  PieChart,
} from '@components';

import { useAnalyzeCompare } from '../../../useAnalyzeCompare';

const { useState, useMemo } = React;
import styles from './AudienceDemoSection.scss';

const colors = ['#FFE9D9', '#EA92AF'];
export interface IAudienceDemoData {
  gender: {
    male: number;
    female: number;
  };
  age: {
    [key: string]: number;
  };
}
type TDisplayMode = 'age' | 'gender';
export interface IProps {
  className?: string;
  data: { loading: boolean, data?: IAudienceDemoData, error: Error | null };
  dataCompare: { loading: boolean, data?: IAudienceDemoData, error: Error | null };
  isComparing: boolean;
}

export const AudienceDemoSection: React.FunctionComponent<IProps> = React.memo(
  (props) => {
    const { loading, data, error } = props.data;
    const { loading: loadingCompare, data: dataCompare } = props.dataCompare;
    const { isComparing } = useAnalyzeCompare();

    const [displayMode, setDisplayMode] = useState<TDisplayMode>('gender');
    const records = useMemo(
      () =>
        map(data && data[displayMode], (count, label) => ({
          value: count,
          label,
        })),
      [data, displayMode],
    );
    const recordsCompare = useMemo(
      () =>
        map(dataCompare && dataCompare[displayMode], (count, label) => ({
          value: count,
          label,
        })),
      [dataCompare, displayMode],
    );
    const onDisplayModeSelect = (displayMode) => setDisplayMode(displayMode);

    if (error) {
      return (
        <Notice className={(styles as any).notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    return (
      <div className={cx(styles.AudienceDemoSection, props.className)}>
        {(loading || loadingCompare) ? <LoadSpinner centered /> : (
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>
                <h3>
                  Audience
                  {' '}
                  {capitalize(displayMode)}
                </h3>
              </div>
              <div className={styles.select}>
                {!isComparing && (
                  <div
                    className={cx(styles.item, {
                      [styles.active]: displayMode === 'age',
                    })}
                    onClick={() => onDisplayModeSelect('age')}
                  >
                    Age
                  </div>
                )}
                <div
                  className={cx(styles.item, {
                    [styles.active]: displayMode === 'gender',
                  })}
                  onClick={() => onDisplayModeSelect('gender')}
                >
                  Gender
                </div>
              </div>
            </div>
            <div className={styles.main}>
              {displayMode === 'gender' && (
                <PieChart
                  showTotal={false}
                  innerRadius={0}
                  data={records}
                  vertical
                />
              )}
              {displayMode === 'gender' && isComparing && (
                <PieChart
                  showTotal={false}
                  innerRadius={0}
                  data={recordsCompare.map((record, idx) => ({
                    ...record,
                    color: colors[idx],
                  }))}
                  vertical
                />
              )}
              {displayMode === 'age' && (
                <BarChart data={records} disableSort={true} maxRecords={8} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

AudienceDemoSection.defaultProps = {
  className: null,
};
