/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_g7a6l_157 {
  display: flex;
}

._justify-content-space-between_g7a6l_161 {
  justify-content: space-between;
}

._tabular-nums_g7a6l_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_g7a6l_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Collapse_g7a6l_178 ._arrow_g7a6l_178 {
  color: #dadcde !important;
  font-size: 16px !important;
  right: 0.375rem !important;
}
._Collapse_g7a6l_178 .ant-collapse-header {
  padding: 1.5rem 0 !important;
  font-weight: 600;
  font-size: 16px;
  color: #42474B;
  border-top: 1px solid #dadcde;
}
._Collapse_g7a6l_178 .ant-collapse-content-box {
  padding: 0 0 1.5rem 0 !important;
}