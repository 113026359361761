import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { DISCONNECT_TIKTOK_SETTINGS_ACCOUNT } from '@frontend/app/queries';
import {
  DisconnectTikTokSettingsAccountMutation,
  DisconnectTikTokSettingsAccountMutationVariables,
} from '@frontend/app/queries/types/DisconnectTikTokSettingsAccountMutation';

type IOptions = MutationHookOptions<
  DisconnectTikTokSettingsAccountMutation,
  DisconnectTikTokSettingsAccountMutationVariables
>;

export const useTikTokSettingsDisconnectAccountMutation = (options: IOptions = {}) => (
  useMutation<
    DisconnectTikTokSettingsAccountMutation,
    DisconnectTikTokSettingsAccountMutationVariables
  >(DISCONNECT_TIKTOK_SETTINGS_ACCOUNT, options)
);
