import * as React from 'react';
import {
  Button,
  Image,
  Row,
  Space,
  Typography,
} from '@revfluence/fresh';

import styles from '@frontend/applications/SocialPostApp/components/BrandAuth.scss';

const { Title, Link } = Typography;

interface IBrandAuthSuccessPage {
  clientRedirect: () => void,
  insightsArticle: string,
  heroImage: string,
  logoImage: string,
}

export const BrandAuthSuccess: React.FunctionComponent<IBrandAuthSuccessPage> = (props) => {
  const {
    clientRedirect,
    insightsArticle,
    heroImage,
    logoImage,
  } = props;
  return (
    <Row justify="center">
      <Space
        direction="vertical"
        size="large"
        align="center"
      >
        <Image
          preview={false}
          width={546}
          src={heroImage}
        />
      </Space>
      <Space
        direction="vertical"
        size="large"
        align="center"
      >
        <Image
          className={styles.imgLogo}
          preview={false}
          width={309}
          src={logoImage}
        />
        <Title level={4} className={styles.titleStyle}>
          You have successfully linked your brand’s
          Instagram Insights to Aspire! If you have any questions
          about what it means to have your Insights linked, check out these
          {' '}
          <Link rel="noopener noreferrer" href={insightsArticle} target="_blank">
            Help Articles
          </Link>
          .
        </Title>
        <Button
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          label="Try Again"
          onClick={clientRedirect}
          size="large"
          type="primary"
        >
          Back to my Account
        </Button>
      </Space>
    </Row>
  );
};
