/* eslint-disable react/display-name */
import React from 'react';
import {
  BudgetGranularity,
  FiscalYearBudgetBreakdown as FiscalYearBudgetBreakdownType,
} from '@frontend/app/types/Budget';
import { map } from 'lodash';
import { Tooltip, Button, Card } from '@revfluence/fresh';
import { InfoCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { useGetBudgetFiscalSettings } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalSettings';
import { GetBudgetAccountDistribution_budgetAccountDistribution } from '@frontend/app/queries/types/GetBudgetAccountDistribution';
import styles from './FiscalYearBudgetBreakdown.scss';
import BudgetBreakdown from './components/BudgetBreakdown';
import FiscalYearProperties from './components/FiscalYearProperties';

interface IProps {
  fiscalYearsBudget: FiscalYearBudgetBreakdownType[];
  budgetAccountDistribution: GetBudgetAccountDistribution_budgetAccountDistribution[];
  parentBudgetId?: number;
  setFiscalYearsBudget: (e: FiscalYearBudgetBreakdownType[]) => void;
}

const renderFiscalYearBudget = (
  setFiscalYearsBudget,
  fiscalYearsBudget,
  budgetAccountDistribution,
  parentBudgetId,
  parentBudget,
  fiscalYearsOptions,
) => (fiscalYearBudget: FiscalYearBudgetBreakdownType) => {
  const handleFiscalYearChange = (updateFiscalYearBudget) => {
      setFiscalYearsBudget(
        fiscalYearsBudget.map((fiscalYear) => {
          if (fiscalYear.id === updateFiscalYearBudget.id) {
            return updateFiscalYearBudget;
          }
          return fiscalYear;
        }),
      );
    };

  return (
    <Card style={{ marginBottom: '24px' }}>
      <div className={styles.fiscalYearBudgetContainer}>
        <FiscalYearProperties
          parentBudgetId={parentBudgetId}
          parentBudget={parentBudget}
          budgetAccountDistribution={budgetAccountDistribution}
          fiscalYearBudget={fiscalYearBudget}
          fiscalYearsBudget={fiscalYearsBudget}
          handleFiscalYearBudgetChange={handleFiscalYearChange}
          fiscalYearsOptions={fiscalYearsOptions}
        />
        <BudgetBreakdown
          id={fiscalYearBudget.id}
          granularity={fiscalYearBudget.granularity}
          totalAmount={fiscalYearBudget.totalAmount}
          monthlyBudgets={fiscalYearBudget.monthBudgets}
          quarterBudgets={fiscalYearBudget.quarterBudgets}
          fiscalYear={fiscalYearBudget.fiscalYear}
          handleFiscalYearChange={handleFiscalYearChange}
        />
      </div>
    </Card>
  );
};

const FiscalYearBudgetBreakdown: React.FC<IProps> = (props) => {
  const {
 fiscalYearsBudget = [], setFiscalYearsBudget, budgetAccountDistribution, parentBudgetId,
} = props;
  const { budgetSettingsResponse } = useGetBudgetFiscalSettings({});

  const fiscalYearsInSetup = budgetSettingsResponse?.fiscalYears || [];
  const parentBudget = budgetAccountDistribution?.find((budget) => budget.budgetId === parentBudgetId);
  const fiscalYearsOptions = !parentBudget?.fiscalYearsBudget
    ? fiscalYearsInSetup
    : fiscalYearsInSetup.filter((year) => parentBudget?.fiscalYearsBudget?.some((fy) => fy.fiscalYear === year.year));
  const newFiscalYear = fiscalYearsOptions?.find(
    (year) => !fiscalYearsBudget?.some((budget) => budget.fiscalYear === year.year),
  );

  const handleAddFiscalYear = () => {
    if ((fiscalYearsBudget?.length || 0) >= (fiscalYearsInSetup?.length || 0)) {
      return;
    }
    if (newFiscalYear) {
      setFiscalYearsBudget(
          [
            ...fiscalYearsBudget,
            {
              id: uuidv4(),
              granularity:
                parentBudget?.fiscalYearsBudget?.find((fy) => fy.fiscalYear === newFiscalYear.year)?.granularity
                || BudgetGranularity.YEARLY,
              fiscalYear: newFiscalYear.year,
              isNew: true,
              totalAmount: 0,
              availableAmount: 0,
              quarterBudgets: [0, 0, 0, 0],
              monthBudgets: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            } as FiscalYearBudgetBreakdownType,
          ] as FiscalYearBudgetBreakdownType[],
      );
    }
  };

  return (
    <div>
      {map(
        fiscalYearsBudget,
        renderFiscalYearBudget(
          setFiscalYearsBudget,
          fiscalYearsBudget,
          budgetAccountDistribution,
          parentBudgetId,
          parentBudget,
          fiscalYearsOptions,
        ),
      )}
      {fiscalYearsInSetup.length > fiscalYearsBudget.length && (
        <div>
          <Button type="link" className={styles.addFiscalYearButton} onClick={handleAddFiscalYear}>
            + Add Fiscal Year
          </Button>
          <Tooltip title="Go to Manage Fiscal Year settings to add more years">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default FiscalYearBudgetBreakdown;

FiscalYearBudgetBreakdown.displayName = 'FiscalYearBudgetBreakdown';
