import React, { useCallback, useEffect, useState } from 'react';
import {
  Col, Empty, Radio, Row, Tooltip, Typography,
} from '@revfluence/fresh';
import { InstagramIcon, TiktokIcon } from '@revfluence/fresh-icons/brands/esm';
import { ChartPieIcon, HashtagIcon } from '@revfluence/fresh-icons/regular/esm';
import { some } from 'lodash';
import styles from './SocialMetrics.scss';
import InstagramStates from './InstagramStates';
import TiktokStates from './TiktokStates';
import SocialNetworks from './SocialNetworks';
import CreatorDemographicSummary from './CreatorDemographicSummary';
import AudienceAgeGroupChart from './AudienceAgeGroupChart';
// import { isEmpty } from 'lodash';
const { Text, Title } = Typography;

const SocialMetrics = ({ memberRecord, socialProfileData, client }) => {
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const [socialNetworkData, setSocialNetworkData] = useState(null);
  const [hasNetwork, setHasNetwork] = useState(false);

  useEffect(() => {
    setSelectedNetwork(memberRecord.metaFields?.instagram ? 'instagram' : 'tiktok');
  }, [memberRecord.metaFields?.instagram, memberRecord.metaFields?.tiktok]);

  useEffect(() => {
    const networkToCheck = ['instagram', 'tiktok', 'youtube', 'pinterest'];
    setHasNetwork(some(networkToCheck, (network) => !!memberRecord?.metaFields?.[network]));
  }, [memberRecord]);

  useEffect(() => {
    setSocialNetworkData(socialProfileData?.instagram || socialProfileData?.tiktok);
  }, [socialProfileData]);

  const toggleNetwork = useCallback((e) => {
    setSelectedNetwork(e.target.value);
    setSocialNetworkData(e.target.value === 'instagram' ? socialProfileData?.instagram : socialProfileData?.tiktok);
  }, [socialProfileData]);

  return (
    <div className={styles.ContentHighlightWrapper}>
      {/* networks */}
      <div className={styles.socialNetworksWrapper}>
        <Title level={5} className={styles.strongText}>Key Networks</Title>
        {hasNetwork ? <SocialNetworks memberRecord={memberRecord} client={client} /> : (
          <Empty
            image={<HashtagIcon />}
            size="small"
            description={(
              <Typography.Paragraph type="secondary">
                No Social Network Connected
              </Typography.Paragraph>
            )}
          />
        )}
      </div>
      <div className={styles.socialStatsWrapper}>
        {(socialProfileData?.instagram && socialProfileData?.tiktok)
          && (
            <div className={styles.toggleBtn}>
              <Radio.Group defaultValue={selectedNetwork} buttonStyle="solid" onChange={toggleNetwork}>
                <Tooltip title="View stats for Instagram">
                  <Radio.Button value="instagram">
                    {' '}
                    <InstagramIcon />
                    {' '}
                  </Radio.Button>
                </Tooltip>
                <Tooltip title="View stats for Tiktok">
                  <Radio.Button value="tiktok">
                    {' '}
                    <TiktokIcon />
                    {' '}
                  </Radio.Button>
                </Tooltip>
              </Radio.Group>
            </div>
          )}
        {/* Key Social Stats */}
        <div className={styles.socialStatsBox}>
          <Title level={5} className={styles.strongText}>Key Social Stats</Title>
          {socialNetworkData ? (
            <div className={styles.keySocialStates}>
              <div className={styles.sHandles}>
                <Row>
                  {selectedNetwork === 'instagram' && memberRecord?.metaFields.instagram
                    && (
                      <Col>
                        {/* instagram states */}
                        <InstagramStates memberRecord={memberRecord} />
                      </Col>
                    )}
                  {selectedNetwork === 'tiktok' && memberRecord?.metaFields.tiktok
                    && (
                      <Col>
                        {/* tiktok states */}
                        <TiktokStates memberRecord={memberRecord} />
                      </Col>
                    )}
                </Row>
                {
                  !memberRecord?.metaFields.instagram && !memberRecord?.metaFields.tiktok && (
                    <Text className={styles.noData}>No data yet</Text>
                  )
                }
              </div>
            </div>
          )
            : <Empty image={<ChartPieIcon />} size="small" description={<Typography.Paragraph type="secondary">No stats to display</Typography.Paragraph>} />}

          {/* demographic summary */}
          {socialNetworkData && <CreatorDemographicSummary socialProfileData={socialNetworkData} />}
        </div>

        {/* Adult age group component  */}
        {socialNetworkData
          && (
            <div className={styles.audienceAgeGroupData}>
              <Title level={5} className={styles.strongText}>Audience Age Group</Title>
              <AudienceAgeGroupChart socialProfileData={socialNetworkData} selectedNetwork={selectedNetwork} />
            </div>
          )}
      </div>
    </div>
  );
};

export default SocialMetrics;
