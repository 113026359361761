import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Main from './app/containers/Main';

const render = (clientInfo) => {
  if (clientInfo) {
    return (
      <BrowserRouter basename={`/client/${clientInfo.id}`}>
        <Main clientInfo={clientInfo} />
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    );
  }
};

// In case we will sync live-reload with server-side rendering, we will need to use ReactDOM.hydrate
const renderMethod = ReactDOM.render;

renderMethod(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render((window as any).__CLIENT_INFO),
  document.getElementById('app'),
);
