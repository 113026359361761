import { useMemo } from 'react';

import { ISTAComposerMemberFieldIds } from '@affiliates/hooks';
import { GetOffersBySearchQuery_offers } from '@affiliates/queries/types/GetOffersBySearchQuery';
import { OFFER_SOURCE, SEND_STA_TASK_ID, UserInfoInput } from '@affiliates/types/globalTypes';
import getInitialEmailState from '../STASendItems/initialEmailState';

interface InitialEmailState {
  content: ReturnType<typeof getInitialEmailState>;
  subject?: string;
}

const PROMO_SUBJECT = 'Welcome to the program! Here is your sales tracking promo code!';
const LINK_SUBJECT = 'Welcome to the program! Here is your sales tracking link!';
const LINK_SUBJECT_FOR_EDIT = 'Update Sales Offer';

export const useGetInitialEmailMessage = (
  memberPortalFeatureFlagEnabled: boolean,
  offer: GetOffersBySearchQuery_offers,
  source: OFFER_SOURCE,
  staComposerMemberFieldIds: ISTAComposerMemberFieldIds,
  profile?: UserInfoInput,
  isOfferEdited?: boolean,
  brandName?: String,
  migrateToGraphQL?: boolean,
): InitialEmailState => useMemo((): InitialEmailState => {
  switch (source) {
    case OFFER_SOURCE.SHOPIFY:
      return {
        content: getInitialEmailState(
          SEND_STA_TASK_ID.send_promo_task,
          [offer],
          staComposerMemberFieldIds,
          memberPortalFeatureFlagEnabled,
          migrateToGraphQL,
        ),
        subject: PROMO_SUBJECT,
      };
    case OFFER_SOURCE.TUNE:
      return {
        content: getInitialEmailState(
          SEND_STA_TASK_ID.send_link_task,
          [offer],
          staComposerMemberFieldIds,
          memberPortalFeatureFlagEnabled,
          migrateToGraphQL,
          profile,
          isOfferEdited,
          brandName,
        ),
        subject: isOfferEdited ? LINK_SUBJECT_FOR_EDIT : LINK_SUBJECT,
      };
  }
}, [
  memberPortalFeatureFlagEnabled,
  offer,
  source,
  staComposerMemberFieldIds,
  isOfferEdited,
  brandName,
  profile,
  migrateToGraphQL,
]);
