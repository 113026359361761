import * as React from 'react';
import { ProjectApplicationPageTemplateName } from '@frontend/app/containers/Projects/applicationPageUtils';
import { CustomizedTemplate } from './CustomizedTemplate';
import { IApplicationPageTemplateProps } from './types';
import { QuickTemplate } from './QuickTemplate';

interface IProjectApplicationPageProps extends IApplicationPageTemplateProps {
  template: ProjectApplicationPageTemplateName | null,
}

export const ProjectApplicationPage: React.FC<IProjectApplicationPageProps> = (props) => {
  const {
    template,
    ...rest
  } = props;

  switch (template) {
    case ProjectApplicationPageTemplateName.Quick:
      return <QuickTemplate {...rest} />;
    default:
      return <CustomizedTemplate {...rest} />;
  }
};
