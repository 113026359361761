import 'css-chunk:src/app/components/GroupContentReview/Comments.module.scss';export default {
  "Comments": "_Comments_3exlk_1",
  "divider": "_divider_3exlk_6",
  "comments": "_comments_3exlk_9",
  "comment": "_comment_3exlk_9",
  "default": "_default_3exlk_20",
  "guidelines": "_guidelines_3exlk_35",
  "reply": "_reply_3exlk_41",
  "actionButton": "_actionButton_3exlk_44",
  "commentEditor": "_commentEditor_3exlk_53",
  "instructionDesc": "_instructionDesc_3exlk_66",
  "feedbackFormCollapsePanel": "_feedbackFormCollapsePanel_3exlk_70"
};