import * as React from 'react';
import {
  Card,
  Checkbox,
  CheckboxChangeEvent,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Space,
  Typography,
} from '@revfluence/fresh';
import { DollarCircleOutlined } from '@ant-design/icons';
import { CalendarCheckIcon, CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import moment from 'moment';
import { OFFER_PAYOUT_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { isBefore } from 'date-fns';
import { IPayoutVariant } from '../../OfferForm';
import { TSelectedMember } from '../types';
import styles from './BulkUpdate.scss';
import { getMultiplePayoutLabel } from '../../OfferSummaryCard/hooks';

const { Text } = Typography;
const { Option } = Select;
const { useEffect, useMemo } = React;
interface IProps {
  members: readonly TSelectedMember[] | null;
  payouts: IPayoutVariant[] | null;
  defaultPayoutId: number | null;
  bulkPayoutId: number;
  bulkStartDate: Date,
  bulkEndDate: Date;
  offerStartDate: string;
  showEndDate?: boolean;
  onBulkPayoutIdChange: (payoutId: number) => void;
  onBulkActiveDateChange: (field: string, value: Date) => void;
  updateShowEndDate: (showEndDate: boolean) => void;
}

export const BulkUpdate: React.FC<Readonly<IProps>> = (props) => {
  const {
    members, payouts, defaultPayoutId, onBulkPayoutIdChange, onBulkActiveDateChange, bulkStartDate, bulkEndDate, offerStartDate, showEndDate, updateShowEndDate,
  } = props;
  useEffect(() => {
    onBulkPayoutIdChange(defaultPayoutId);
  }, [defaultPayoutId, onBulkPayoutIdChange]);
  useEffect(() => {
    onBulkActiveDateChange('bulkStartDate', offerStartDate ? new Date(offerStartDate) : new Date());
  }, [onBulkActiveDateChange, offerStartDate]);
  const onEndDateChecboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      updateShowEndDate(true);
    } else {
      onBulkActiveDateChange('bulkEndDate', null);
      updateShowEndDate(false);
    }
  };
  const disableEndDate = useMemo(() => {
    const givenDate = bulkStartDate ? isBefore(bulkStartDate.getTime(), new Date()) ? new Date() : new Date(bulkStartDate.getTime() + 60000) : bulkStartDate;
    return (current) => current && current < givenDate;
  }, [bulkStartDate]);
  const disableStartDate = (current) => {
    if (!offerStartDate) {
      return true;
    }
    const startDate = new Date(offerStartDate);
    return current && current < startDate;
  };
  const endDateErrorMessage = useMemo(() => {
    if (showEndDate) {
      if (!bulkEndDate) return 'Add End Date';
      if (showEndDate && isBefore(bulkEndDate, new Date())) {
        return 'End Date show be greater than todays date';
      }
      if (isBefore(bulkEndDate, bulkStartDate)) {
        return 'End Date show be greater than Active Date';
      }
    }
    return '';
  }, [showEndDate, bulkEndDate, bulkStartDate]);
  return (
    <>
      <Row>
        <Text className={styles.boldText}>
          {members.length}
          {' '}
          Members Selected
        </Text>
      </Row>
      <Row className={styles.bulkUpdateContainer}>
        <Col
          span={24}
          className={styles.bulkUpdateContainerContent}
        >
          <Card size="small" className={styles.bulkUpdateItemContainer}>
            <Space className={styles.itemContainerContent}>
              <Text className={styles.boldText}>
                <DollarCircleOutlined style={{ marginRight: '8px' }} />
                Set Payout Value
              </Text>
              <Popover
                content="Choose the payout value for all the selected member"
                title="Set Payout in Bulk"
                overlayStyle={{ maxWidth: '200px' }}
                placement="right"
              >
                <CircleInfoIcon />
              </Popover>
            </Space>
            <Select
              defaultValue={defaultPayoutId}
              onChange={(e) => onBulkPayoutIdChange(e)}
              className={styles.payoutInput}
            >
              {
                payouts.map((payout) => (
                  <Option key={payout.id} value={payout.id}>{getMultiplePayoutLabel(payout.label, payout.payoutType as OFFER_PAYOUT_TYPE, payout.flatPayout, payout.percentPayout)}</Option>
                ))
              }
            </Select>
          </Card>
          <Card size="small" className={styles.bulkUpdateItemContainer}>
            <Space className={styles.itemContainerContent}>
              <Text className={styles.boldText}>
                <CalendarCheckIcon style={{ marginRight: '8px' }} />
                Set Active Dates
              </Text>
              <Popover
                content="Now you can directly set active/end dates for all the selected members in bulk. "
                title="Set Dates in Bulk"
                overlayStyle={{ maxWidth: '200px' }}
                placement="right"
              >
                <CircleInfoIcon />
              </Popover>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text>Active Date</Text>
              <DatePicker
                showTime={{ format: 'hh:mm a' }}
                format="MMM D, YYYY hh:mm a"
                disabledDate={disableStartDate}
                value={bulkStartDate ? moment(bulkStartDate) : null}
                status={!bulkStartDate ? 'error' : ''}
                onChange={(date) => {
                  onBulkActiveDateChange('bulkStartDate', date ? date.toDate() : null);
                }}
                className={styles.dateInput}
              />
              {
                !bulkStartDate && (
                  <p className={styles.error}>Add Start Date</p>
                )
              }
              <Checkbox onChange={onEndDateChecboxChange} checked={showEndDate}>Set End Date</Checkbox>
              {
                showEndDate && (
                  <>
                    <Text>End Date</Text>
                    <DatePicker
                      showTime={{ format: 'hh:mm a' }}
                      format="MMM D, YYYY hh:mm a"
                      disabledDate={disableEndDate}
                      value={bulkEndDate ? moment(bulkEndDate) : null}
                      onChange={(date) => onBulkActiveDateChange('bulkEndDate', date ? date.toDate() : null)}
                      className={styles.dateInput}
                      status={endDateErrorMessage ? 'error' : ''}
                    />
                    {
                      !!endDateErrorMessage && (
                        <p className={styles.error}>{endDateErrorMessage}</p>
                      )
                    }
                  </>
                )
              }
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  );
};
