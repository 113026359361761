import gql from 'graphql-tag';

import { SEGMENT_FRAGMENT } from './fragments';

export const SAVE_SEGMENT = gql`
  mutation SaveSegmentMutation($segment: SegmentInput!) {
    segment: saveSegment(segment: $segment) {
      ...MemberPageSegment
    }
  }
  ${SEGMENT_FRAGMENT}
`;
