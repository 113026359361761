._tabContainer_1ra3a_1 {
  display: flex;
  border-bottom: 1px solid #ccc;
}

._tab_1ra3a_1 {
  padding: 10px 20px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
}

._selectedTab_1ra3a_13 {
  color: blue;
  border-bottom: 2px solid blue;
  font-weight: bold;
  padding: 10px 20px;
}