export interface IIntroModal {
  show?: boolean;
  defaultShow?: boolean;
  showCloseIcon?: boolean;
  classNames?: string[];
  onFinish?();
  onRequestClose?();
}

export interface IStepConfig {
  title?: string;
  subtitle?: string;
  bannerSrc?: string;
  banner?: JSX.Element;
  nextButtonLabel?: string;
  backButtonLabel?: string;
}
