import * as React from 'react';
import { isEmpty, chain } from 'lodash';
import {
  useIQDataLocationAutocomplete,
  useGooglePlacesAutocomplete,
} from '@frontend/utils';
import {
  TPlace,
  TPlaceAutocompleteCallback,
  IPlacesInputProps,
} from './types';
import { TokenInput } from '../Input/TokenInput';

interface IInternalPlacesInputProps extends IPlacesInputProps {
  autocompleter: TPlaceAutocompleteCallback;
}

const InternalPlacesInput: React.FunctionComponent<IInternalPlacesInputProps> = (props) => {
  const places = props.places ?? [];

  const getOptions = async (value: string) => {
    const predictions = await props.autocompleter({ ...props.options, input: value });
    return chain(predictions)
      .omitBy(isEmpty)
      .value();
  };

  const handleChange = (newTokens: TPlace[]): void => {
    props.onChange(newTokens);
  };

  return (
    <TokenInput
      className={props.className}
      tokens={places}
      placeholder={props.placeholder}
      debounceWait={props.debounceWait}
      onChange={handleChange}
      optionsForValue={getOptions}
      allowTokenCreation={false}
      emptyOptionsMessage={props.emptyOptionsMessage}
    />
  );
};

InternalPlacesInput.defaultProps = {
  places: [],
  placeholder: 'Enter a location',
  debounceWait: 300,
};

export const PlacesInput: React.FunctionComponent<IPlacesInputProps> = (props) => {
  const autocompleter = useGooglePlacesAutocomplete();

  return (
    <InternalPlacesInput {...props} autocompleter={autocompleter} />
  );
};
PlacesInput.displayName = 'PlacesInput';

export const PlacesInputIQData: React.FunctionComponent<IPlacesInputProps> = (props) => {
  const autocompleter = useIQDataLocationAutocomplete();

  return (
    <InternalPlacesInput {...props} autocompleter={autocompleter} />
  );
};
PlacesInputIQData.displayName = 'PlacesInputIQData';
