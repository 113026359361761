import * as React from 'react';
import cx from 'classnames';
import { isFunction, isEmpty } from 'lodash';

import {
  Button,
  Invite,
  CreatorActionButtonGroup,
  TNetworkIdentifier,
  FavoriteButton,
  Favorite,
  IProspect,
  TProgram,
  Toast,
  IToastRefHandles,
} from '@components';

import {
  useCommunitiesQuery,
  useGetAllProjectsQuery,
} from '@frontend/app/hooks';

import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import { Tab } from '../models';
import { NetworkPicker } from '../NetworkPicker/NetworkPicker';
import {
  Action as IAction,
} from '../PaginationReducer';

import styles from './Header.scss';
import { DataTimeframeTag } from '../DataTimeframeTag/DataTimeframeTag';

const { useRef, useState } = React;

export interface ISocialProfileTab {
  type: Tab;
  networks: TNetworkIdentifier[];
  onClick: () => void;
}

export interface ISocialProfileHeader {
  headerActions?: React.ReactNode;
  placeholder?: boolean;
  paginationDispatch?: (value: IAction) => void;
}

export const Header: React.FC<ISocialProfileHeader> = (props: ISocialProfileHeader) => {
  const { headerActions, paginationDispatch } = props;
  const [timeframeLabel, setTimeframeLabel] = useState<string>('');

  const {
    activeTab,
    allowFavorite,
    apiEndpoint,
    campaign,
    goToManage,
    hasData,
    hideActions,
    inviteToCampaign,
    isInviting,
    network,
    noDataPulled,
    selfServeExperiment,
    sendOffer,
    setActiveTab,
    setIsInviting,
    showCreateFeatures,
    socialAccount,
  } = useSocialProfileContext();
  const toastRef = useRef<IToastRefHandles>();

  const {
    loading: isFetchingCommunities,
    data: {
      communities = null,
    } = {},
  } = useCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      projects = [],
    } = {},
    loading: isFetchingAllPrograms,
  } = useGetAllProjectsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const inviteCallback = () => {
    if (isFunction(inviteToCampaign)) {
      setIsInviting(true);
      return inviteToCampaign(socialAccount)
        .finally(() => {
          setIsInviting(false);
        });
    }
  };
  const offerCallback = isFunction(sendOffer) && socialAccount
    ? sendOffer.bind({}, socialAccount.id)
    : null;
  const manageCallback = isFunction(goToManage) && socialAccount
    ? goToManage.bind({}, socialAccount.relation_id)
    : null;

  const tabs: ISocialProfileTab[] = [
    {
      type: Tab.SUMMARY,
      networks: ['instagram', 'youtube'],
      onClick: () => setActiveTab(Tab.SUMMARY),
    },
    {
      type: Tab.ANALYZE,
      networks: ['instagram', 'youtube'],
      onClick: () => setActiveTab(Tab.ANALYZE),
    },
  ];

  const renderTabs = () => tabs.length > 0 && !noDataPulled && (
    <nav
      className={cx(styles.tabs, {
        [styles.noLabel]: isEmpty(timeframeLabel),
        [styles.dataLabel]: !isEmpty(timeframeLabel),
      })}
    >
      {tabs
        .filter((tab) => tab.networks.includes(network))
        .map((tab, i) => (
          <Button
            key={`social-profile-tab-${i}`}
            className={cx(styles.tab, {
              [styles.activeTab]: tab.type === activeTab,
            })}
            label={tab.type}
            onClick={tab.onClick}
            theme="light"
          />
        ))}
    </nav>
  );

  const renderActionButtons = () => {
    if (headerActions) {
      return (
        <div className={styles.actions}>
          <div className={styles.actionButtonGroup}>
            {headerActions}
          </div>
        </div>
      );
    }

    if (!selfServeExperiment && !hideActions && !noDataPulled) {
      if (showCreateFeatures) {
        return (
          <div className={styles.actions}>
            <CreatorActionButtonGroup
              className={styles.actionButtonGroup}
              socialAccount={socialAccount}
              isInviting={isInviting}
              showInviteButton={isFunction(inviteToCampaign)}
              inviteCallback={inviteCallback}
              inviteButtonType="primary"
              showOfferButton={isFunction(sendOffer)}
              offerCallback={offerCallback}
              manageCallback={manageCallback}
            />
            {allowFavorite && campaign && (
            <FavoriteButton
              className={styles.favoriteButton}
              size={24}
              apiEndpoint={apiEndpoint}
              campaign={campaign}
              socialAccount={socialAccount}
            />
          )}
          </div>
        );
      }

      return (
        <div className={styles.actions}>
          <Toast ref={toastRef} />
          <Favorite
            className={styles.favorite}
            prospect={{ socialAccount }}
            communities={communities}
          />
          <Invite
            className={styles.invite}
            prospect={{ socialAccount }}
            communities={communities}
            projects={projects}
            isFetchingCommunities={isFetchingCommunities}
            isFetchingAllPrograms={isFetchingAllPrograms}
            onInvite={(prospect: IProspect, programs: TProgram[]) => {
              if (toastRef && toastRef.current) {
                toastRef.current.showMessage({
                  type: 'success',
                  content: (
                    <>
                      Invited
                      {' '}
                      <span>
                        {prospect?.socialAccount?.username || prospect?.socialAccount?.display_name}
                      </span>
                      {' '}
                      to
                      {' '}
                      {programs[0]?.title}
                    </>
                  ),
                });
              }
            }}
          />
        </div>
      );
    }
  };

  return (
    <header className={styles.Header}>
      <div className={styles.DataTimeframe}>
        <NetworkPicker paginationDispatch={paginationDispatch} />
        <DataTimeframeTag
          onDataTimeframeLabelChange={setTimeframeLabel}
          socialAccount={socialAccount}
        />
      </div>
      {hasData && (
        <>
          {renderTabs()}
          {renderActionButtons()}
        </>
      )}
    </header>
  );
};

Header.displayName = 'Header';
