export enum BrandSocialField {
  Instagram = 'brand_instagram',
  Tiktok = 'brand_tiktok',
  Youtube = 'brand_youtube',
  Pinterest = 'brand_pinterest',
  Website = 'brand_website',
}

export const socialLinkFields = [
  {
    name: BrandSocialField.Instagram,
    placeholder: '@brandsinstagram',
  },
  {
    name: BrandSocialField.Tiktok,
    placeholder: '@brandTiktok',
  },
  {
    name: BrandSocialField.Youtube,
    placeholder: 'youtube.com/brandsprofile',
  },
  {
    name: BrandSocialField.Pinterest,
    placeholder: 'pinterest.com/brandspinterest',
  },
  {
    name: BrandSocialField.Website,
    placeholder: 'brandswebsite.com',
  },
];

export const INSTAGRAM_DOMAIN = 'instagram.com';
export const TIKTOK_DOMAIN = 'tiktok.com';
export const PINTEREST_DOMAIN = 'pinterest.com';
export const YOUTUBE_DOMAIN = 'youtube.com';

export const getNetworkUrl = (propsUrl: string, fieldName: BrandSocialField) => {
    let url = propsUrl;

    if (fieldName === BrandSocialField.Instagram) {
      url = url.replace('@', '');
      if (!url.includes(INSTAGRAM_DOMAIN)) {
        url = `${INSTAGRAM_DOMAIN}/${url}`;
      }
    } else if (fieldName === BrandSocialField.Tiktok) {
      if (!url.includes(TIKTOK_DOMAIN)) {
        if (!url.startsWith('@')) {
          url = `@${url}`;
        }
        url = `${TIKTOK_DOMAIN}/${url}`;
      }
    } else if (fieldName === BrandSocialField.Pinterest) {
      url = url.replace('@', '');
      if (!url.includes(PINTEREST_DOMAIN)) {
        url = `${PINTEREST_DOMAIN}/${url}`;
      }
    } else if (fieldName === BrandSocialField.Youtube && !url.includes(YOUTUBE_DOMAIN)) {
      url = `${YOUTUBE_DOMAIN}/${url}`;
    }

    if (!(url.startsWith('http') || url.startsWith('https'))) {
      url = `https://${url}`;
    }

    return url;
  };
