import * as React from 'react';

import { Avatar } from '@revfluence/fresh';
import { CheckIcon, InfoIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';

const trimName = (name: string) =>
  name
    .split(' ')
    .map((s) => s[0])
    .join('');

interface ICommentIcon {
  status?: string;
  user: string;
  icon?: React.ReactElement;
}

export const Icon = ({ status = 'default', user, icon }: ICommentIcon): React.ReactElement => {
  const colors: { [key: string]: string } = {
    info: '#167CF4',
    success: '#389E0D',
    warning: '#FAAD14',
    error: '#F5222D',
    default: '#923562',
  };
  const icons: { [key: string]: React.ReactElement } = {
    info: <InfoIcon />,
    success: <CheckIcon />,
    warning: <CheckIcon />,
    error: <XmarkIcon />,
  };

  if (icon) {
    return <Avatar icon={icon} style={{ backgroundColor: colors[status], marginRight: '16px' }} />;
  }

  if (status !== 'default') {
    return <Avatar icon={icons[status]} style={{ backgroundColor: colors[status], marginRight: '16px' }} />;
  }

  return <Avatar style={{ backgroundColor: colors[status], marginRight: '16px' }}>{trimName(user)}</Avatar>;
};
