import {
  GetApplicationsByIds_applications as IApplicationRaw,
} from '@frontend/app/queries/types/GetApplicationsByIds';
import { IMemberFilter } from '@frontend/app/types/MemberSearch';

export enum FieldSource {
  All = 'All Data',
  Default = 'Default Member Data',
  Custom = 'Custom Member Data',
  Relationship = 'Relationship Status',
}

type IApplicationSource = Pick<IApplicationRaw, 'id' | 'name'>;

export interface DynamicChoice {
  value: unknown;
  label: string;
}

export interface IFilterField {
  source?: FieldSource | IApplicationSource;
  schemaId?: number;
  headerName: string;
  field: string;
  type: string;
  editable?: boolean;
  choices?: string[] | DynamicChoice[];
}

export interface IFilter extends IMemberFilter {
  id: string;
}
