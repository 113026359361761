import { matchPath } from 'react-router-dom';

import { EventName, IPaymentSentEventProperties } from '@common';
import { useEventContext } from '@frontend/app/context';
import { createPaymentSentEvent, IMixpanelFields } from '../createPaymentSentEvent';
import { ISendPaymentRequest } from '../savePayment';

export type TPaymentSentHandler = (params: ISendPaymentRequest, mixpanelFields?: IMixpanelFields) => void;

export const usePaymentSentEvent = (): TPaymentSentHandler => {
  const addEvent = useEventContext();

  const paymentSentEventSource = matchPath<{ clientId: string; source: string }>(
    window?.location?.pathname || '',
    {
      path: '/client/:clientId/:source',
      exact: false,
      strict: false,
    },
  )?.params?.source?.toLowerCase() || 'members';
  let source: IPaymentSentEventProperties['source'] = 'member details';
  switch (paymentSentEventSource) {
    case 'members':
      source = 'member details';
      break;
    case 'projects':
      source = 'workflow';
      break;
    default:
      break;
  }

  return (params: ISendPaymentRequest, mixpanelFields?: IMixpanelFields) => {
    addEvent(EventName.PaymentSent, createPaymentSentEvent(params, source, mixpanelFields));
  };
};
