import { useMutation } from '@apollo/client';

import { SYNC_MESSAGES_MUTATION } from '@frontend/app/queries';
import {
  SyncMessagesMutation, SyncMessagesMutationVariables,
} from '@frontend/app/queries/types/SyncMessagesMutation';

export function useSyncMessages() {
  const [
    syncMessages,
    { loading, error },
  ] = useMutation<SyncMessagesMutation, SyncMessagesMutationVariables>(SYNC_MESSAGES_MUTATION);

  return {
    loading,
    syncMessages,
    error,
  };
}
