import {
  EditorState, ContentState,
  CompositeDecorator, RawDraftContentState,
  convertFromRaw,
} from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import { isEmpty, isString, isPlainObject } from 'lodash';

export function editorStateFromValue({
  value, decorator, isHtml = false,
}: {
  value: string | RawDraftContentState;
  decorator: CompositeDecorator;
  isHtml?: boolean;
}) {
  if (isEmpty(value)) {
    return EditorState.createEmpty(decorator);
  } else if (isString(value)) {
    if (isHtml) {
      return EditorState.createWithContent(convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
          if (nodeName === 'a') {
            return createEntity(
              'link',
              'MUTABLE',
              {
                src: node.href,
                readonly: !!node?.attributes?.readonly,
              },
            );
          }
        },
      })(value), decorator);
    }

    try {
      const rawState = JSON.parse(value);

      return EditorState.createWithContent(convertFromRaw(rawState), decorator);
    } catch (err) {
      return EditorState.createWithContent(ContentState.createFromText(value), decorator);
    }
  } else if (isPlainObject(value)) {
    try {
      const contentState = convertFromRaw(value as RawDraftContentState);

      return EditorState.createWithContent(contentState, decorator);
    } catch (err) {
      console.log('invalid state', err);

      return EditorState.createEmpty(decorator);
    }
  }

  return EditorState.createEmpty(decorator);
}
