import { isEmpty } from 'lodash';
import {
  Col, Form, Row, Select, Switch, Tooltip, Typography,
} from '@revfluence/fresh';
import * as React from 'react';
import { InfoIcon } from '@components';
import { FormAsyncAction, IAffiliateLinksFormElementProps } from '../../../types';

interface IProps extends IAffiliateLinksFormElementProps<'multipleDomain'> {
  isCreatorDeeplinkAllowed: boolean;
  initialAllowedDomains: string[];
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
}
const { Option } = Select;

export const AllowMultipleDomain: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  isCreatorDeeplinkAllowed,
  initialAllowedDomains,
  handleFormAsyncActions,
}) => {
  const [isMultipleDomainAllowed, setIsMultipleDomainAllowed] = React.useState(isCreatorDeeplinkAllowed);
  const handleChange = (event) => {
    setIsMultipleDomainAllowed(event);
    handleFormAsyncActions({ action: 'UpdateField', payload: { key: 'creatorDeeplink', value: event } });
  };

  return (
    <>
      <Row gutter={8}>
        <Form.Item name="creatorDeeplink">
          <Col>
            <Switch size="default" disabled={disabled} onChange={handleChange} checked={isMultipleDomainAllowed} />
          </Col>
        </Form.Item>
        <Col>
          <Typography.Title level={5}>Allow creators to generate deep links</Typography.Title>
        </Col>
      </Row>
      {isMultipleDomainAllowed && (
        <Row gutter={8} align="middle" justify="center">
          <Col span={22}>
            <Form.Item name={name}>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select Additional Domain"
                optionLabelProp="label"
                size="large"
              >
                {initialAllowedDomains?.map((domain) => (
                  <Option key={domain} value={domain}>
                    {domain}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2} style={{ marginBottom: 24 }}>
            {isEmpty(initialAllowedDomains) && (
              <Tooltip title="Please contact support to configure additional domains">
                <InfoIcon />
              </Tooltip>
            )}
          </Col>
        </Row>
      )}
    </>
  );
});

AllowMultipleDomain.displayName = 'AllowMultipleDomain';
