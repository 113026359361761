import * as React from 'react';
import cx from 'classnames';

import { SpinnerIcon } from '@components';
import { Button, IButtonProps } from './Button';

import styles from './SubmitButton.scss';

export interface ISubmitButtonProps extends IButtonProps {
  isSubmitting: boolean;
  submittingLabel: string;
}

/**
 * @class
 * @extends {React.Component}
 */
export const SubmitButton: React.FC<ISubmitButtonProps> = (props: ISubmitButtonProps) => {
  const {
    className,
    label,
    icon = null,
    ref,
    isSubmitting,
    submittingLabel,
    ...buttonProps
  } = props;

  return (
    <Button
      className={cx(className, styles.SubmitButton)}
      label={isSubmitting ? submittingLabel : label}
      icon={
        isSubmitting ? (
          <SpinnerIcon
            className={cx(styles.spinner, {
              [styles.white]: ['primary', 'danger'].includes(props.theme),
            })}
            size={20}
          />
        ) : (
          icon
        )
      }
      ref={ref as React.RefObject<HTMLDivElement>}
      {...buttonProps}
    />
  );
};
SubmitButton.defaultProps = {
  theme: 'primary',
  fullWidth: false,
  round: true,
  icon: null,
};
SubmitButton.displayName = 'SubmitButton';
