/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import cx from 'classnames';
import { ClassValue } from 'classnames/types';

import styles from './HeroMedia.scss';

interface IProps {
  heroSrc: string;
  isVideo: boolean;
  className?: ClassValue;
}

export const HeroMedia: React.FC<IProps> = React.memo((props) => {
  const {
    heroSrc,
    isVideo,
    className,
  } = props;

  return (
    <div className={cx(styles.HeroImage, className)}>
      {
        isVideo
          ? (
            <video
              src={heroSrc}
              className={styles.video}
              controls
            />
          )
          : (
            <img src={heroSrc} className={styles.image} />
          )
      }
    </div>
  );
});

HeroMedia.displayName = 'QuickTemplateHeroImage';
