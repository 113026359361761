import gql from 'graphql-tag';

export const ARCHIVE_OFFER_MUTATION = gql`
mutation ArchiveOffer($archiveOfferInput: ArchiveOfferInput!) {
    archiveOffer(archiveOfferInput: $archiveOfferInput){
        id
        archivedDate
        archivedUser {
            name
            email
        }
    }
}
`;
