import * as React from 'react';
import { isEmpty, pick } from 'lodash';

import {
  Alert, Button, Form, Input, Space, Typography,
} from '@revfluence/fresh';

import { LoadSpinner } from '@components';
import { useGetProfile, useUpdateProfile } from '@frontend/app/hooks';
import { UpdateProfileInput as _UpdateProfileInput } from '@frontend/app/types/globalTypes';

interface IProps {
  className?: string;
}
interface UpdateProfileInput extends _UpdateProfileInput {
  confirmPassword?: string;
}

const { useState, useEffect } = React;
const { Text, Title } = Typography;

/**
 * @type {React.FC}
 */
export const AccountSettings: React.FC<IProps> = React.memo((props) => {
  const { loading, profile } = useGetProfile();
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const { updateProfile, loading: updating } = useUpdateProfile({
    onError(err) {
      setErrorMessage(err.message);
    },
  });

  const handleSubmit = async (values: UpdateProfileInput) => {
    if (!isEmpty(values.password)) {
      if (values.password !== values.confirmPassword) {
        setErrorMessage('The two passwords that you entered do not match!');
        return;
      }
    }

    if (!isEmpty(values.name)) {
      updateProfile({
        variables: {
          params: {
            id: profile.id,
            name: values.name,
            password: values.password,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (profile) {
      form.setFieldsValue(pick(profile, ['name', 'password', 'confirmPassword']));
    }
  }, [profile, form]);

  if (loading) {
    return <LoadSpinner />;
  }

  return (
    <Space direction="vertical" size="large" className={props.className}>
      <Title level={3} noMargin>
        Account Settings
      </Title>

      <Form wrapperCol={{ span: 24 }} form={form} layout="vertical" onFinish={handleSubmit}>
        {errorMessage && (
          <Form.Item>
            <Alert type="error" description={errorMessage} />
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label={<Text strong>Your name</Text>}
          rules={[
            {
              required: true,
              message: 'Name is required',
            },
          ]}
        >
          <Input placeholder="Your name" />
        </Form.Item>
        <Form.Item label={<Text strong>Your email</Text>}>
          Your current email address is
          {' '}
          <b>{profile.email}</b>
        </Form.Item>
        <Form.Item>
          <Alert
            type="info"
            message="Managed account"
            description={(
              <>
                <div>Your account uses single sign-on.</div>
                <div>Contact your identity provider administrator to change your email address.</div>
              </>
            )}
          />
        </Form.Item>
        <Form.Item label={<Text strong>Change password</Text>}>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.Password placeholder="New password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Password"
            dependencies={['password']}
            rules={[
              {
                required: false,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm password" />
          </Form.Item>
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit">
            {updating ? 'Saving' : 'Save'}
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
});
