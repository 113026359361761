import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { AUTOMATION_EXECUTION_ENDED_STATUSES } from '@frontend/app/components/AutomationAuditTable/AutomationAuditTableTypes';
import { Button } from '@/shadcn/components/ui/button';
import {
 Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/shadcn/components/ui/tabs';
import {
 Card, CardDescription, CardFooter, CardHeader, CardTitle,
} from '@/shadcn/components/ui/card';

import { H3 } from '@/shadcn/components/typography/h3';
import { H4 } from '@/shadcn/components/typography/h4';
import { P } from '@/shadcn/components/typography/p';
import { Badge } from '@/shadcn/components/ui/badge';
import { DataTable } from './data-table';
import { OrderingDirection, AutomationVariableType } from '@/app/types/globalTypes';
import {
  TAutomation,
  useListAutomationsQuery,
  ListExecution,
  useListExecutions,
  useTerminateAutomationExecutionMutation,
} from '@/app/hooks/automations';

import { GetMemberQuery_member } from '@/app/queries/types/GetMemberQuery';
import { useGetMembersByIdsQuery } from '@/app/hooks';
import { TABLE_PAGE_SIZE } from '@/app/constants';
import { AutomationExecution, getColumns } from './columns';
import { useMessagingContext } from '@/hooks';
import { Pagination } from './pagination';
import TemplateModal from '../templateModal/TemplateModal';

type TProps = {
  projectId: number;
};

const AutomationPageLayout = ({ children }) => (
  <div className="flex flex-col">
    <div className="flex-1  space-y-4 p-8 pt-6">{children}</div>
  </div>
);

const BadgeOn = () => <Badge>On</Badge>;

const BadgeOff = () => <Badge variant="outline">Off</Badge>;

const useAutomationsList = ({ projectId }: { projectId: number }) => {
  const {
    data: { listAutomations: { results: automations } } = {
      listAutomations: {
        results: [] as TAutomation[],
      },
    },
    loading: isLoadingAutomations,
  } = useListAutomationsQuery({
    variables: {
      paging: {
        offset: 0,
        limit: 10,
      },
      filters: {
        program: {
          id: projectId,
        },
      },
      ordering: {
        name: OrderingDirection.ASC,
      },
    },
    fetchPolicy: 'network-only',
  });
  return { automations, isLoadingAutomations };
};

const useAutomationExecutions = ({ projectId, page }: { projectId: number; page: number }) => {
  const {
    loading: loadingExecutions,
    refetch: refetchExecutions,
    data: { listExecutions: { results: executions = [], paging } } = {
      listExecutions: {
        results: [] as ListExecution[],
      },
    },
  } = useListExecutions({
    variables: {
      paging: {
        offset: (page - 1) * TABLE_PAGE_SIZE,
        limit: TABLE_PAGE_SIZE,
      },
      filters: {
        automation: {
          program: {
            id: projectId,
          },
        },
      },
    },
    fetchPolicy: 'no-cache',
  });
  return {
    executions,
    loadingExecutions,
    refetchExecutions,
    paging,
  };
};

const useMembersByIds = ({ memberIds, loadingExecutions }: { memberIds: number[]; loadingExecutions: boolean }) => {
  const {
    loading: loadingMembersByIds,
    data: { members: membersByIds = [] as GetMemberQuery_member[] } = {},
  } = useGetMembersByIdsQuery(memberIds, {
    skip: !!loadingExecutions || !memberIds.length,
    fetchPolicy: 'no-cache',
  });
  return { membersByIds, loadingMembersByIds };
};

// Utils
const listExecutionToAutomationExecutionAdapter = (
  execution: ListExecution,
  member?: GetMemberQuery_member,
): AutomationExecution => ({
  automation: execution?.automation,
  member,
  status: execution.status,
  dateTriggered: execution.createdDate,
  dateEnded: AUTOMATION_EXECUTION_ENDED_STATUSES.includes(execution.status) ? execution.updatedDate : null,
  id: execution.id,
});
const getMemberIdFromAutomationExecution = (execution: ListExecution) => {
  const member = execution?.variables?.find((variable) => variable.type === AutomationVariableType.MEMBER);
  return parseInt(member?.value, 10);
};

const useAutomationExecutionsTableAdapter = ({
  executions,
  members,
}: {
  executions: ListExecution[];
  members?: GetMemberQuery_member[];
}) =>
  executions.map((execution) => {
    const member = members?.find((member) => member.id === getMemberIdFromAutomationExecution(execution));
    return listExecutionToAutomationExecutionAdapter(execution, member);
  });

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const AutomationPage = (props: TProps) => {
  const location = useLocation();
  const query = useQuery();
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const openTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };

  const closeTemplateModal = () => {
    setIsTemplateModalVisible(false);
  };
  const page = parseInt(query.get('page') || '1', 10);

  const routeMatchHistory = location.pathname.includes('history');
  const tab = routeMatchHistory ? 'history' : 'automations';
  const { projectId } = props;
  const automationsRoute = `/projects/${projectId}/settings/automation`;
  const historyRoute = `/projects/${projectId}/settings/automation/history`;

  const { automations } = useAutomationsList({ projectId });

  const {
 executions, loadingExecutions, refetchExecutions, paging,
} = useAutomationExecutions({ projectId, page });

  const { membersByIds } = useMembersByIds({
    memberIds: executions.map((execution) => getMemberIdFromAutomationExecution(execution)),
    loadingExecutions,
  });
  const automationExecutions = useAutomationExecutionsTableAdapter({ executions, members: membersByIds });
  const [terminateAutomationExecution] = useTerminateAutomationExecutionMutation();
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();
  const columns = getColumns({
    projectId,
    terminateAutomationExecution: async (executionId: string, reason: string) => {
      try {
        await terminateAutomationExecution({
          variables: {
            executionId,
            reason,
          },
        });
        showSuccessMessage('Automation execution terminated successfully');
        refetchExecutions();
      } catch (error) {
        showErrorMessage('Failed to terminate automation execution');
        throw error;
      }
    },
  });
  return (
    <AutomationPageLayout>
      <div className="flex items-center justify-between">
        <H3 className="text-foreground">Automations</H3>
        <div>
          <Button onClick={openTemplateModal} variant="default">
            Add Automation
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <P>Automate project tasks to easily scale your campaigns. Add a new automation or configure an existing one.</P>
        <Tabs value={tab} defaultValue="automations">
          <TabsList>
            <TabsTrigger asChild value="automations">
              <Link className="text-muted-foreground hover:text-foreground" to={automationsRoute}>
                All Automations
              </Link>
            </TabsTrigger>
            <TabsTrigger asChild value="history">
              <Link className="text-muted-foreground hover:text-foreground" to={historyRoute}>
                Run History
              </Link>
            </TabsTrigger>
          </TabsList>
          <TabsContent value="automations">
            <div className="grid sm:grid-cols-3 gap-4 mt-4">
              {automations.map((automation) => (
                <Card key={automation.id}>
                  <Link className="group" to={`/projects/${projectId}/automation_detail/automation/${automation.id}`}>
                    <CardHeader>
                      <CardTitle>
                        <div>
                          <H4 className="text-foreground">{automation.name}</H4>
                          <div>{automation.status === 'ACTIVE' ? <BadgeOn /> : <BadgeOff />}</div>
                        </div>
                      </CardTitle>
                      <CardDescription>{automation.description}</CardDescription>
                    </CardHeader>
                    <CardFooter>
                      <Button className="group-hover:underline pl-0" variant="link" asChild>
                        <Link to={`/projects/${projectId}/automation_detail/automation/${automation.id}`}>View</Link>
                      </Button>
                    </CardFooter>
                  </Link>
                </Card>
              ))}
            </div>
          </TabsContent>
          <TabsContent value="history">
            <div className="flex items-center justify-between">
              <div className="flex justify-end ml-auto">
                <Pagination paging={paging} />
              </div>
            </div>
            <DataTable columns={columns} data={automationExecutions} draggable />
          </TabsContent>
        </Tabs>
        <TemplateModal projectId={projectId} isVisible={isTemplateModalVisible} onCancel={closeTemplateModal} />
      </div>
    </AutomationPageLayout>
  );
};
