import { useMutation, MutationHookOptions } from '@apollo/client';

import { DELETE_CONTENT_GUIDELINES } from '@frontend/app/queries';
import {
  DeleteContentGuidelinesById,
  DeleteContentGuidelinesByIdVariables,
} from '@frontend/app/queries/types/DeleteContentGuidelinesById';

type IOptions = MutationHookOptions<DeleteContentGuidelinesById, DeleteContentGuidelinesByIdVariables>;

export const useDeleteContentGuidelinesById = (options: IOptions = {}) => useMutation<DeleteContentGuidelinesById, DeleteContentGuidelinesByIdVariables>(DELETE_CONTENT_GUIDELINES, options);
