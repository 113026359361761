import * as React from 'react';

import cx from 'classnames';
import { map } from 'lodash';

import { CAMPAIGN_DETAILS_FIELDS } from './model';
import TabField from '../../TabField';

import styles from './styles.scss';
import CreatorOffers from '../../CreatorOffers/CreatorOffers';
import { useListOnMarketplace } from '../../../context';

const { useCallback } = React;

const CampaignDetailsComponent: React.FC = () => {
  const {
    getFieldValue,
    setFieldValue,
    setHasBeenTouched,
  } = useListOnMarketplace();

  const updateCompensationValue = useCallback((fieldName: string, checked: boolean) => {
    const currentChecked = getFieldValue(fieldName);
    if (checked !== currentChecked) {
      setHasBeenTouched(true);
    }
    setFieldValue(fieldName, checked);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, setHasBeenTouched]);

  return (
    <div className={styles.container}>
      <div className={cx(styles.col, styles.left)}>
        <TabField
          key="splash_image_url"
          name="splash_image_url"
          label="Image"
          type="image"
          helpText="Upload the image you would like to show in the Aspire Marketplace"
          isRequired
        />
      </div>
      <div className={cx(styles.col, styles.right)}>
        <div className={styles.fields}>
          {map(CAMPAIGN_DETAILS_FIELDS, (field) => (
            <TabField {...field} />
          ))}
          <CreatorOffers
            title="What are you looking to offer creators?"
            shouldDisplayRequiredIndicator
            setFieldValue={updateCompensationValue}
            getFieldValue={getFieldValue}
          />
        </div>
      </div>
    </div>
  );
};

const CampaignDetails = React.memo(CampaignDetailsComponent);

CampaignDetails.displayName = 'CampaignDetails';

export default CampaignDetails;
