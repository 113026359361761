/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_xml2b_157 {
  display: flex;
}

._justify-content-space-between_xml2b_161 {
  justify-content: space-between;
}

._tabular-nums_xml2b_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_xml2b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ModifyCommunityMembersModal_xml2b_178 {
  z-index: 9600 !important;
}
._ModifyCommunityMembersModal_xml2b_178 ._label_xml2b_181 {
  display: flex;
  flex-direction: row;
}
._ModifyCommunityMembersModal_xml2b_178 ._img_xml2b_185 {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
  border-radius: 0.5rem;
}