import * as React from 'react';
import { isUndefined } from 'lodash';

import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from './useClientFeatureEnabled';

const { useMemo } = React;

export interface InstagramDMFlagValue {
  isInstagramDMEnabled: boolean
  isLoading: boolean
}

export const useInstagramDMFlag = (): InstagramDMFlagValue => {
  const isEnabled = useClientFeatureEnabled(ClientFeature.INSTAGRAM_DM);

  return useMemo(() => ({
    isInstagramDMEnabled: isEnabled,
    isLoading: isUndefined(isEnabled),
  }), [isEnabled]);
};
