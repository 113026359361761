import * as React from 'react';
import xss from 'xss';
import {
  ConnectedImageVideoUpload, RichTextEditor,
} from '@frontend/app/components';

import { Input, Switch } from '@revfluence/fresh';
import { ShowSection, BrandSocialLinks } from '../FormComponents';
import { useUploadProps } from '../hooks/useUploadProps';
import { TTemplateProps } from '../../types';
import { IMAGES_HINTS } from './imagesHints';

import styles from './Intro.scss';
import {
 ApplicationPageBuilderComponent, BrandLinksDescriptionForTemplate, isBuilderComponentUsedByTemplate, ProjectApplicationPageTemplateName,
} from '../../../applicationPageUtils';

const { useCallback } = React;

type TIntroProps = TTemplateProps['intro'];

interface IProps {
  projectId: number;
  introProps?: TIntroProps;
  onChange?(values: TIntroProps);
  template?: ProjectApplicationPageTemplateName;
  isFieldVisible: (fieldName: string) => boolean;
  onChangeFieldVisibility: (fieldName: string, isVisible: boolean) => void;
}

const HERO_MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

export const Intro: React.FC<IProps> = (props) => {
  const {
    projectId,
    introProps,
    onChange,
    template,
    isFieldVisible,
    onChangeFieldVisibility,
  } = props;

  const uploadProps = useUploadProps(projectId);

  const handleChange = useCallback((values: Partial<TIntroProps>) => {
    if (onChange) {
      onChange({ ...introProps, ...values });
    }
  }, [introProps, onChange]);

  const handleChangeShow = useCallback((show: boolean) => {
    handleChange({ show });
  }, [handleChange]);

  const handleChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ title: e.target.value });
  }, [handleChange]);

  const handleChangeSubtitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ subtitle: e.target.value });
  }, [handleChange]);

  const handleChangeHero = useCallback((hero: string, isVideo: boolean) => {
    handleChange({ hero_image: hero, hero_is_video: isVideo });
  }, [handleChange]);

  const handleChangeDescription = useCallback((value: string) => {
    handleChange({ description: value });
  }, [handleChange]);

  const handleChangeButtontext = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ button_text: e.target.value });
  }, [handleChange]);

  return (
    <div className={styles.Intro}>
      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.IntroSectionToggle,
          template,
        ) && (
          <ShowSection show={!(introProps?.show === false)} onChange={handleChangeShow} />
        )
      }

      <div className={styles.row}>
        <div className={styles.subrow}>
          <div className={styles.subtitle}>Title</div>
          <Input
            placeholder="Your Page Title"
            value={introProps?.title}
            onChange={handleChangeTitle}
          />
        </div>
        {
          isBuilderComponentUsedByTemplate(
            ApplicationPageBuilderComponent.IntroSubtitle,
            template,
          ) && (
            <div className={styles.subrow}>
              <div className={styles.subtitle}>
                Subtitle
                {
                  isBuilderComponentUsedByTemplate(
                    ApplicationPageBuilderComponent.IntroSubtitleToggle,
                    template,
                  ) && (
                    <Switch
                      size="small"
                      checked={isFieldVisible(ApplicationPageBuilderComponent.IntroSubtitle)}
                      onChange={(isVisible: boolean) => (
                        onChangeFieldVisibility(ApplicationPageBuilderComponent.IntroSubtitle, isVisible)
                      )}
                    />
                  )
                }
              </div>
              <Input
                placeholder="Your Page Subtitle"
                value={introProps?.subtitle}
                onChange={handleChangeSubtitle}
              />
            </div>
          )
        }
      </div>

      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.IntroHeroImageVideo,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>
              Hero Image or Video
              {
                isBuilderComponentUsedByTemplate(
                  ApplicationPageBuilderComponent.IntroHeroImageVideoToggle,
                  template,
                ) && (
                  <Switch
                    size="small"
                    checked={isFieldVisible(ApplicationPageBuilderComponent.IntroHeroImageVideo)}
                    onChange={(isVisible: boolean) => (
                      onChangeFieldVisibility(ApplicationPageBuilderComponent.IntroHeroImageVideo, isVisible)
                    )}
                  />
                )
              }
            </div>
            <div className={styles.rowDescription}>
              Attract creators by uploading an image or video that best describes your brand and campaign.
            </div>
            <ConnectedImageVideoUpload
              defaultImageSrc={introProps?.hero_image}
              uploadProps={uploadProps}
              acceptedTypes={['image', 'video']}
              onUpload={handleChangeHero}
              hideMaxSizeNote
              display="expanded"
              onRemove={() => handleChangeHero(undefined, false)}
              hintText={IMAGES_HINTS.HERO_IMAGE_VIDEO}
              isDefaultVideo={introProps?.hero_is_video}
              uploadLabel="Upload a hero image or video"
              fileBytesLimit={HERO_MAX_FILE_SIZE}
              cropperProps={{
                aspectRatio: 1920 / 806,
              }}
            />
          </div>
        )
      }

      <div className={styles.row}>
        <BrandSocialLinks
          introProps={introProps}
          handleChange={handleChange}
          description={BrandLinksDescriptionForTemplate[template]}
        />
      </div>

      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.IntroHeroJustImage,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>Hero image</div>
            <ConnectedImageVideoUpload
              defaultImageSrc={introProps?.hero_image}
              uploadProps={uploadProps}
              onUpload={handleChangeHero}
              onRemove={() => handleChangeHero(undefined, false)}
              showCropper
              cropperProps={{
                aspectRatio: 1920 / 806,
                rotatable: false,
              }}
              hintText={IMAGES_HINTS.HERO_IMAGE}
              fileBytesLimit={HERO_MAX_FILE_SIZE}
            />
          </div>
        )
      }

      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.IntroDescription,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>Description</div>
            <RichTextEditor
              placeholder="Welcome to your page, put something amazing here about your brand. It can be your tag line or something your community cares about joining"
              value={xss(introProps?.description)}
              onChange={handleChangeDescription}
              boundSelector="#steps"
            />
          </div>
        )
      }

      {
        isBuilderComponentUsedByTemplate(
          ApplicationPageBuilderComponent.IntroJoinButtonLabel,
          template,
        ) && (
          <div className={styles.row}>
            <div className={styles.subtitle}>Button text</div>
            <Input
              placeholder="Join"
              value={introProps?.button_text}
              onChange={handleChangeButtontext}
            />
          </div>
        )
      }
    </div>
  );
};
