import * as React from 'react';
import * as qs from 'querystring';
import {
  isEmpty, filter, find, isFunction, reduce,
} from 'lodash';

import { IToastMessage, IToastRefHandles } from '@components';
import { IOverlayProps, Overlay } from '@components';
import { Toast } from '@components';
import { ICampaign } from '@components';
import { ISocialAccount as ISocialAccountRequest } from '@frontend/applications/SocialPostApp/useFetchSocialAccountData';
import { ISocialAccount } from '@components';

import { useGetCurrentClient } from '@frontend/app/hooks';
import { useMemberFieldPartitions } from '@frontend/app/containers/MemberDetail/MemberInfo/MemberOverview/hooks';
import { logger } from '@common';
import { ProspectsListContext } from '@frontend/app/containers/Projects/ProjectsPage/ProspectsList/ProspectsListContext';
import { ProfileApplicationOperationButtons } from '@frontend/components/widgets/SocialProfile/Header/ProfileApplicationOperationsButtons';
import {
  IState as IPaginationState,
  ActionType as PaginationAction,
  reducer as paginationReducer,
} from './PaginationReducer';
import { HistoryBackButton } from './HistoryBackButton/HistoryBackButton';
import { SimilarCreatorList } from './SimilarCreatorList/SimilarCreatorList';
import { SocialProfile } from './SocialProfile';
import { SocialProfileContextProvider } from './context/SocialProfileContext';

import { IRowData, TableContext, TableRowContext } from '../Table';

import { PaginationArrow, PaginationArrowDirection } from './PaginationArrow';
import styles from './SocialProfile.scss';
import { DetailedSocialAccountCaller } from './hooks/useDetailedSocialAccount';

const {
  useState,
  useReducer,
  useContext,
  useEffect,
  useCallback,
  useRef,
} = React;

type TOverlayProps = Partial<Pick<IOverlayProps, 'onRequestClose' | 'showCloseIcon'>>;
const OVERLAY_CLOSE_DELAY_MS = 1500;

export interface ISocialProfileOverlayProps extends TOverlayProps {
  // Create
  apiEndpoint?: string;
  clientId?: string;
  campaign?: ICampaign;
  socialAccount?: ISocialAccount;
  memberSocialAccounts?: ISocialAccount[];
  brandInstagramUsername?: string;
  caller?: DetailedSocialAccountCaller;
  showPlaceholder?: boolean;

  // Create actions
  goToManage?: (relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  inviteToCampaign?: (socialAccount: ISocialAccount) => Promise<void>;
  sendOffer?: (accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  // Actions
  onCreatorSelected?: (socialAccount: ISocialAccount) => void; // When a similar creator is selected

  // Flags
  allowFavorite?: boolean;
  isQa?: boolean;
  loadAccountDetails?: boolean; // Extra fetch for full account details
  selfServeExperiment?: boolean;
  show?: boolean;
  showCreateFeatures?: boolean;
  showSimilarCreators?: boolean;
  hideActions?: boolean;
  enablePagination?: boolean;

  // Toast
  toastRef?: React.RefObject<IToastRefHandles>; // If not provided, Social Profile will have its own Toast
  showToastMessage?(message: IToastMessage);

  // TODO
  reportAsIncorrect?(accountName: string);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  refetchProjectCounts?: () => Promise<any>;
}

export const SocialProfileOverlay: React.FC<ISocialProfileOverlayProps> = (props) => {
  const {
    apiEndpoint,
    loadAccountDetails,
    onCreatorSelected: onCreatorSelectedProp,
    onRequestClose,
    showCloseIcon,
    showSimilarCreators,
    show,
    socialAccount,
    toastRef: toastRefProp,
    brandInstagramUsername,
    caller,
    enablePagination,
    memberSocialAccounts,
    refetchProjectCounts,
  } = props;

  const toastRef = useRef<IToastRefHandles>(toastRefProp ? toastRefProp.current : null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const {
    sortedSocialSchemasByName,
  } = useMemberFieldPartitions({});

  // in-memory cache for the loaded accounts during the card swipe, clears when closing the card
  const loadedSocialAccounts = useRef<{ [rowIndex: number]: { [network: string]: ISocialAccount } }>({});

  const [processedMemberIds, setProcessedMemberIds] = useState<string[]>([]);
  const [forceOverlayClose, setForceOverlayClose] = useState(false);

  const prospectsListContext = useContext(ProspectsListContext);
  const { data: initialTableRows } = useContext(TableContext) || {};
  const { rowIndex, rowId } = useContext(TableRowContext) || {};
  const { client } = useGetCurrentClient();
  const [prevRowWithSocialAccount, setPrevRowWithSocialAccount] = useState<Map<number, number>>(new Map<number, number>()); // index->index
  const [nextRowWithSocialAccount, setNextRowWithSocialAccount] = useState<Map<number, number>>(new Map<number, number>()); // index->index
  const [rowsWithSocialHandleCount, setRowsWithSocialHandleCount] = useState<number>(-1);

  const [paginationState, paginationDispatch] = useReducer(paginationReducer, {
    showNextAction: false,
    showPreviousAction: false,
    currentSocialAccount: socialAccount,
    allSocialAccounts: memberSocialAccounts,
    tableRows: initialTableRows,
    currentRowData: { rowIndex, rowId },
    showPlaceholder: false,
  } as IPaginationState);

  // Grabs all social handles and networks from row data.
  /**
   *
   * @param rowData
   * @returns
   */
   const getRowSocialSchema = (rowData: IRowData) => reduce(sortedSocialSchemasByName, (accounts, schema) => {
    const username = rowData?.[schema.id];
    if (username) {
      accounts.push({
        id: schema.id,
        username,
        network_identifier: schema.name?.toLowerCase(),
      });
    }
    return accounts;
  }, []);

  /**
   * Update state with resolved social account promise.
   * Required even if pagination is not enabled.
   */
  useEffect(() => {
    paginationDispatch({
      type: PaginationAction.CurrentSocialAccount,
      payload: socialAccount,
    });
  }, [socialAccount]);

  useEffect(() => {
    if (isEmpty(socialAccount) || isEmpty(paginationState.tableRows)) {
      return;
    }

    let rowsWithSocialHandle = 0;
    const prevRows = new Map<number, number>();
    paginationState.tableRows.forEach((row, index) => {
      const hasAccount = !!find(
        filter(getRowSocialSchema(row)),
        (acc) => acc.network_identifier === socialAccount?.network_identifier,
      );

      if (hasAccount) {
        rowsWithSocialHandle += 1;
      }
      prevRows.set(index, hasAccount ? index : (prevRows.get(index - 1) >= 0 ? prevRows.get(index - 1) : -1));
    });
    setRowsWithSocialHandleCount(rowsWithSocialHandle);
    setPrevRowWithSocialAccount(prevRows);

    const nextRows = new Map<number, number>();
    for (let i = paginationState.tableRows.length - 1; i >= 0; i -= 1) {
      const hasAccount = !!find(
        filter(getRowSocialSchema(paginationState.tableRows[i])),
        (acc) => acc.network_identifier === socialAccount?.network_identifier,
      );
      nextRows.set(i, hasAccount ? i : (nextRows.get(i + 1) >= 0 ? nextRows.get(i + 1) : -1));
    }
    setNextRowWithSocialAccount(nextRows);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialAccount?.network_identifier, paginationState.tableRows]);

  // Check apiEndpoint
  if (loadAccountDetails && !apiEndpoint) {
    throw new Error('`apiEndpoint` is required for fetching full account details');
  }

  // Show toast message -- fire and forget
  const showToastMessage = useCallback((message: IToastMessage) => {
    toastRef.current.showMessage(message);
  }, [toastRef]);

  // Scroll to top when creator is selected
  const onCreatorSelected = (account: ISocialAccount) => {
    const overlayElem = overlayRef && overlayRef.current;
    if (overlayElem) {
      overlayElem.scroll({ top: 0, behavior: 'smooth' });
    }

    if (isFunction(onCreatorSelectedProp)) {
      onCreatorSelectedProp(account);
    }
  };

  const handleRequestClose = () => {
    if (enablePagination && prospectsListContext) {
      // Because this rerenders the table, we can only do this after we close overlay.
      prospectsListContext.tableRef?.current?.removeProcessedMemberApplicants(
        processedMemberIds.map((id) => parseInt(id, 10)),
      );
    }
    if (onRequestClose) onRequestClose();
  };

  const updateArrowVisibility = () => {
    const {
      currentRowData: {
        rowIndex,
      },
    } = paginationState;

    paginationDispatch({
      type: PaginationAction.ToggleArrowVisibility,
      payload: {
        showNextAction: nextRowWithSocialAccount.get(rowIndex + 1) > 0,
        showPreviousAction: prevRowWithSocialAccount.get(rowIndex - 1) >= 0,
      },
    });
  };

  /**
   * Handles loading new content, for arrow clicks or approve/decline.
   * @param directionIncrement positive for next, negative for previous, 0 for reload.
   */
  const handlePagination = async (directionIncrement: number) => {
    const {
      tableRows,
      currentRowData: {
        rowIndex,
      },
      showPlaceholder,
    } = paginationState;

    if (showPlaceholder) return;

    paginationDispatch({
      type: PaginationAction.ShowPlaceholder,
      payload: true,
    });

    let newRowIndex;
    if (directionIncrement === 1) {
      const nextIndex = nextRowWithSocialAccount.get(rowIndex + 1);
      newRowIndex = nextIndex >= 0 ? nextIndex : rowIndex;
    } else if (directionIncrement === -1) {
      const prevIndex = prevRowWithSocialAccount.get(rowIndex - 1);
      newRowIndex = prevIndex >= 0 ? prevIndex : rowIndex;
    } else if (directionIncrement === 0) {
      newRowIndex = rowIndex;
    }
    const nextRowData = tableRows[newRowIndex];

    if (nextRowData) {
      paginationDispatch({
        type: PaginationAction.CurrentRowData,
        payload: {
          rowIndex: newRowIndex,
          rowId: nextRowData.id,
          rowApplicantName: nextRowData.name.name,
        },
      });

      const memberSocialSchema = getRowSocialSchema(nextRowData);
      const selectedAccount = find(memberSocialSchema, (acc) => acc?.network_identifier === socialAccount?.network_identifier);
      paginationDispatch({
        type: PaginationAction.CurrentSocialAccount,
        payload: selectedAccount,
      });
      paginationDispatch({
        type: PaginationAction.UpdateSocialAccounts,
        payload: memberSocialSchema,
      });

      const selectedAccountWithData = await getSocialAccountData(selectedAccount, newRowIndex);
      paginationDispatch({
        type: PaginationAction.CurrentSocialAccount,
        payload: selectedAccountWithData,
      });

      paginationDispatch({
        type: PaginationAction.ShowPlaceholder,
        payload: false,
      });
    }
  };

  /**
   * Pagination functionality
   * Safely disabled by setting/leaving enablePagination to false.
   * Update arrow visibility when content (row pointer) changes.
   */
  useEffect(() => {
    if (enablePagination) {
      updateArrowVisibility();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevRowWithSocialAccount, nextRowWithSocialAccount, paginationState.currentRowData?.rowIndex]);

  /**
   * Update content and arrows after an approve/decline has occurred.
   */
  useEffect(() => {
    if (enablePagination) {
      const {
        tableRows,
        currentRowData: {
          rowIndex,
        },
      } = paginationState;

      // Ensure new content is loaded.
      if (rowsWithSocialHandleCount === -1) {
        return;
      }

      if (rowsWithSocialHandleCount === 0) {
        setTimeout(() => setForceOverlayClose(true), OVERLAY_CLOSE_DELAY_MS);
      } else if (!tableRows[rowIndex]) {
        if (nextRowWithSocialAccount.get(rowIndex + 1) > 0) {
          handlePagination(1);
        } else {
          handlePagination(-1);
        }
      } else {
        handlePagination(0);
      }

      updateArrowVisibility();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enablePagination,
    nextRowWithSocialAccount,
    rowsWithSocialHandleCount,
    paginationState.tableRows,
    paginationState.currentRowData?.rowIndex,
  ]);

  /**
   * Fetches data for each social account of the current member.
   */
  const getSocialAccountData = async (
    account: ISocialAccountRequest,
    rowIndex: number,
  ): Promise<ISocialAccount> => {
    const network = account.network_identifier;
    const currentProfile = loadedSocialAccounts.current[rowIndex];
    if (currentProfile && currentProfile[network]) {
      return currentProfile[network];
    }

    const query = qs.stringify({
      client_id: client.id,
      member_username: account.username,
      network,
      include_social_profile_data: true,
      caller,
    });
    const url = `${apiEndpoint}/social_account?${query}`;

    let accountData;
    try {
      const response = await fetch(url);
      const json = await response.json();
      if (json?.status?.code === 200 && json?.data[0]) {
        accountData = json.data[0];
      }
    } catch (error) {
      logger.error(error);
      throw new Error('Failed to retrieve social account data');
    }
    if (!loadedSocialAccounts.current[rowIndex]) {
      loadedSocialAccounts.current[rowIndex] = {};
    }
    loadedSocialAccounts.current[rowIndex][network] = accountData;
    return accountData;
  };

  /**
   * Removes a member (row) from the tableRows state.
   * @param id The row/member id to remove.
   */
  const removeMemberFromState = (id: string) => {
    setProcessedMemberIds([...processedMemberIds, id]);
    paginationDispatch({
      type: PaginationAction.RemoveRowById,
      payload: id,
    });
  };

  const renderHeaderActions = (): JSX.Element => {
    if (enablePagination) {
      const {
        currentRowData: {
          rowId,
          rowApplicantName,
        },
        showPlaceholder,
      } = paginationState;
      return (
        <ProfileApplicationOperationButtons
          id={rowId}
          applicantName={rowApplicantName}
          isLoading={showPlaceholder}
          toastRef={toastRef}
          onRequestClose={onRequestClose}
          refetchProjectCounts={refetchProjectCounts}
          removeMemberFromState={removeMemberFromState}
        />
      );
    }
  };

  return (
    <Overlay
      className={styles.SocialProfileOverlay}
      onRequestClose={handleRequestClose}
      showCloseIcon={showCloseIcon}
      ref={overlayRef}
      show={show}
      closeOnBackdropClick={!enablePagination}
      forceClose={forceOverlayClose}
    >

      {paginationState.showPreviousAction && (
        <PaginationArrow
          arrowDirection={PaginationArrowDirection.Prev}
          handleClick={() => handlePagination(-1)}
        />
      )}

      <SocialProfileContextProvider
        {...props}
        socialAccount={paginationState.currentSocialAccount}
        memberSocialAccounts={paginationState.allSocialAccounts}
        showToastMessage={showToastMessage}
        onCreatorSelected={onCreatorSelected}
      >
        {showSimilarCreators && <HistoryBackButton />}
        <SocialProfile
          showPlaceholder={paginationState.showPlaceholder}
          socialAccount={paginationState.currentSocialAccount}
          memberSocialAccounts={paginationState.allSocialAccounts}
          headerActions={renderHeaderActions()}
          brandInstagramUsername={brandInstagramUsername}
          caller={caller}
          paginationDispatch={paginationDispatch}
        />
        {showSimilarCreators && <SimilarCreatorList />}
      </SocialProfileContextProvider>

      {paginationState.showNextAction && (
        <PaginationArrow
          arrowDirection={PaginationArrowDirection.Next}
          handleClick={() => handlePagination(1)}
        />
      )}

      {!toastRefProp ? <Toast useFresh ref={toastRef} /> : null}
    </Overlay>
  );
};

SocialProfileOverlay.defaultProps = {
  allowFavorite: false,
  hideActions: false,
  loadAccountDetails: false,
  onRequestClose: () => undefined,
  selfServeExperiment: false,
  show: false,
  showCreateFeatures: true,
  showCloseIcon: true,
  showSimilarCreators: false,
  socialAccount: null,
};

SocialProfileOverlay.displayName = 'SocialProfileOverlay';
