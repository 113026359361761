export const pfaV2Routes = {
    settings: {
        imports: '/settings/products',
        collectionDetails: '/settings/products/collections/:collectionId',
        importLogs: '/settings/products/import/logs',
        brandCatalogs: '/settings/brandCatalogs',
        brandCatalogsDetails: '/settings/brandCatalogs/:catalogId',
        brandCatalogCollections: '/settings/brandCatalogs/:catalogId/collections',
        brandCatalogFeaturedProducts: '/settings/brandCatalogs/:catalogId/featuredProducts',
        brandCatalogsDetailsCollection: '/settings/brandCatalogs/:catalogId/collections/:collectionId',
        brandCatalogsCustomization: '/settings/brandCatalogs/:catalogId/customization',
    },
    projectSettings: {
        productCatalogs: '/projects/:projectId/settings/product_catalog',
    },
};
