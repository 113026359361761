/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._OverlaySpinner_xfel4_79 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(253, 253, 253, 0.7);
  z-index: 9999;
  text-align: center;
}
._OverlaySpinner_xfel4_79 ._spinner_xfel4_89 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
._OverlaySpinner_xfel4_79 ._spinner_xfel4_89::after {
  position: absolute;
  top: calc(50% - 0.25rem);
  left: 50%;
  width: 6.875rem;
  height: 6.875rem;
  display: block;
  background: rgba(253, 253, 253, 0.9);
  content: "";
  border-radius: 0.5rem;
  transform: translate(-50%, -50%);
  z-index: -1;
}