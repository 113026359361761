._BreakdownView_1ujm0_1 ._GroupByLabel_1ujm0_1 {
  color: #1A1818;
  font-size: 13px;
  margin-right: 8px;
}
._BreakdownView_1ujm0_1 ._GroupByButton_1ujm0_6 {
  color: #1A1818;
  font-size: 12px;
  border: 0.5px solid #D8DDE4;
  border-radius: 4px;
  height: 32px;
  margin: auto 2px;
}
._BreakdownView_1ujm0_1 ._GroupByButton_1ujm0_6:hover, ._BreakdownView_1ujm0_1 ._GroupByButton_1ujm0_6:active {
  color: #fff;
  border: 0.5px solid #3996E0;
  background-color: #3996E0;
  cursor: pointer;
}
._BreakdownView_1ujm0_1 ._active_1ujm0_20 {
  color: #fff;
  border: 0.5px solid #3996E0;
  background-color: #3996E0;
}