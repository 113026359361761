import gql from 'graphql-tag';
import { v4 as uuidv4 } from 'uuid';
import {
 MutationHookOptions, useMutation, ApolloCache, defaultDataIdFromObject,
} from '@apollo/client';

import {
  SegmentFoldersQuery_folders_segments as ISegment,
} from '@frontend/app/queries/types/SegmentFoldersQuery';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';

import { SAVE_SEGMENT_METADATA } from '@frontend/app/queries/SaveSegmentMetadataMutation';
import {
  SaveSegmentMetadataMutation,
  SaveSegmentMetadataMutationVariables,
  SaveSegmentMetadataMutation_metadata as IMetadata,
} from '@frontend/app/queries/types/SaveSegmentMetadataMutation';

type IOptions = MutationHookOptions<SaveSegmentMetadataMutation, SaveSegmentMetadataMutationVariables>;

export const useSaveSegmentColumns = (options: IOptions = {}) => useMutation<SaveSegmentMetadataMutation, SaveSegmentMetadataMutationVariables>(
    SAVE_SEGMENT_METADATA, {
      ...options,
      update: (...updateArgs) => {
        if (options.update) {
          options.update(...updateArgs);
        }

        const [store, result] = updateArgs;

        const {
          data: {
            metadata,
          },
        } = result;

        if (metadata.predefinedSegmentId) {
          updatePredefinedSegmentCache(store, metadata);
        } else {
          updateSegmentCache(store, metadata);
        }
      },
    },
);

const fragmentId = uuidv4().replace(/-/g, '');

const SegmentFragment = gql`
  fragment SegmentFragment_${fragmentId} on Segment {
    __typename
    columns {
      memberFieldSchemaIds
      dbColumns
      order {
        memberFieldSchemaId
        dbColumn
      }
    }
  }
`;

const updateSegmentCache = (store: ApolloCache<SaveSegmentMetadataMutation>, metadata: IMetadata) => {
  const __typename: ISegment['__typename'] = 'Segment';
  try {
    store.writeFragment<Partial<ISegment>>({
      id: defaultDataIdFromObject({ id: metadata.segmentId, __typename }),
      fragment: SegmentFragment,
      data: {
        __typename,
        columns: metadata.columns,
      },
    });
  } catch (err) {
    // Do nothing. Cache doesn't exist.
  }
};

const PredefinedSegmentFragment = gql`
  fragment PredefinedSegmentFragment_${fragmentId} on PredefinedSegment {
    __typename
    columns {
      memberFieldSchemaIds
      dbColumns
      projectColumns
      order {
        memberFieldSchemaId
        dbColumn
        projectColumn
      }
    }
  }
`;

const updatePredefinedSegmentCache = (store: ApolloCache<SaveSegmentMetadataMutation>, metadata: IMetadata) => {
  const __typename: IPredefinedSegment['__typename'] = 'PredefinedSegment';
  try {
    store.writeFragment<Partial<IPredefinedSegment>>({
      id: defaultDataIdFromObject({ id: metadata.predefinedSegmentId, __typename }),
      fragment: PredefinedSegmentFragment,
      data: {
        __typename,
        columns: metadata.columns,
      },
    });
  } catch (err) {
    // Do nothing. Cache doesn't exist.
  }
};
