import gql from 'graphql-tag';

export const GET_PREDEFINED_SEGMENTS_FOR_COMMUNITY = gql`
  query PredefinedSegmentsQuery($communityId: Int, $sourcingGroupId: String, $isContact: Boolean) {
    segments: predefinedSegmentsForCommunity(communityId: $communityId, sourcingGroupId: $sourcingGroupId, isContact: $isContact) {
      id
      title
      description
      folderId
      filter
      columns {
        memberFieldSchemaIds
        dbColumns
        projectColumns
        order {
          memberFieldSchemaId
          dbColumn
          projectColumn
        }
      }
      memberCount
    }
  }
`;
