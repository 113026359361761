/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1hbkq_157 {
  display: flex;
}

._justify-content-space-between_1hbkq_161 {
  justify-content: space-between;
}

._tabular-nums_1hbkq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1hbkq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._memberPaymentSummaryContainer_1hbkq_178 {
  width: 100%;
}
._memberPaymentSummaryContainer_1hbkq_178 ._divider_1hbkq_181 {
  margin: 1rem 0rem;
}
._memberPaymentSummaryContainer_1hbkq_178 ._summmary_1hbkq_184 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
._memberPaymentSummaryContainer_1hbkq_178 .ant-tag {
  margin: 0rem !important;
}