import * as React from 'react';
import { MemberSearchQuery_members_owners as IOwners } from '@frontend/app/queries/types/MemberSearchQuery';
import { Avatar } from '@revfluence/fresh';
import { UserAvatar } from '@frontend/app/components';
import { map, orderBy } from 'lodash';
import styles from './OwnersCell.scss';

interface IOwnersCellProps {
  className?: string;
  owners: IOwners[];
}

export const OwnersCell: React.FC<IOwnersCellProps> = React.memo(({ className, owners }) => (
  <div className={styles.OwnersCell}>
    <Avatar.Group className={className} maxCount={3}>
      {map(orderBy(owners, 'name', 'asc'), (owner) => (
        <UserAvatar key={owner.id} name={owner.name} tooltipTitle={owner.name} />
        ))}
    </Avatar.Group>
  </div>
  ));

OwnersCell.displayName = 'ProjectsPageOwnersCell';
