import React from 'react';
import { Row, Space, Typography } from '@revfluence/fresh';
import styles from './StatCard.scss';

const { Text, Title } = Typography;

export const StatCard = ({ title, count, outOff }: { title: string; count: number, outOff?: number }) => (
  <Space direction="vertical" className={styles.StatCard} size={0}>
    <Text type="secondary" className={styles.statTitle}>
      {title}
    </Text>
    <Row align="bottom">
      <Title level={5}>{count}</Title>
      {outOff ? (
        <Text type="secondary" className={styles.outOff}>
          /
          {outOff}
        </Text>
      ) : null}
    </Row>
  </Space>
);
