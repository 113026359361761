import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_BUDGET_ACCOUNT_DISTRIBUTION } from '@frontend/app/queries/GetBudgetAccountDistribution';
import { GetBudgetAccountDistributionVariables, GetBudgetAccountDistribution } from '@frontend/app/queries/types/GetBudgetAccountDistribution';

type IOptions = QueryHookOptions<GetBudgetAccountDistribution, GetBudgetAccountDistributionVariables>;

export function useGetBudgetAccountDistribution(options: IOptions) {
    const {
        loading, data: { budgetAccountDistribution } = {}, error, refetch,
} = useQuery<GetBudgetAccountDistribution, GetBudgetAccountDistributionVariables>(GET_BUDGET_ACCOUNT_DISTRIBUTION, {
  ...options,
  fetchPolicy: 'cache-and-network',
});

    return {
      loading,
      budgetAccountDistribution,
      error,
      refetch,
    };
  }
