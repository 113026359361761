import gql from 'graphql-tag';

export const ADVERTISER_STAT_DETAIL_QUERY = gql`
  query AdvertiserStatDetail($startDate: DateTime, $endDate: DateTime) {
    offers: advertiserStatDetail(startDate: $startDate, endDate: $endDate) {
      offerId
      offerName
      offerImageUrl
      url
      members
      clicks
      conversions
      salesAmount
      payoutEarned
      payoutMade
      avgSale
      status
    }
  }
`;
