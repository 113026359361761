._ProductOrderForm_sdcmy_1 {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  width: 400px;
  background-color: #fafafa;
  border-radius: 8px;
  border-color: #000000;
  border-width: 4px;
  border-style: solid;
  height: 656px;
  display: flex;
  padding: 24px 24px 25px 24px;
  gap: 16px;
  overflow: scroll;
}
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: 5%;
  width: 100%;
  margin-top: 11em;
}
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 .ant-divider-horizontal {
  margin: 5px;
}
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 .ant-typography {
  margin-bottom: 0px;
}
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._instruction_sdcmy_32,
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._form_sdcmy_33 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: #fff;
  width: 100%; /* Span the maximum width */
  padding: 16px; /* Add padding as desired */
  box-sizing: border-box;
}
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._instruction_sdcmy_32 ._mandatoryFields_sdcmy_43,
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._form_sdcmy_33 ._mandatoryFields_sdcmy_43 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._instruction_sdcmy_32 ._mandatoryFields_sdcmy_43 ._quantity_sdcmy_50,
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._form_sdcmy_33 ._mandatoryFields_sdcmy_43 ._quantity_sdcmy_50 {
  flex-grow: 1;
}
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._instruction_sdcmy_32 ._mandatoryFields_sdcmy_43 ._productName_sdcmy_54,
._ProductOrderForm_sdcmy_1 ._content_sdcmy_17 ._form_sdcmy_33 ._mandatoryFields_sdcmy_43 ._productName_sdcmy_54 {
  flex-grow: 3;
}