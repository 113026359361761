import { useQuery, QueryHookOptions } from '@apollo/client';
import { isNil } from 'lodash';

import { GET_GROUP_CONTENT_REVIEWS_QUERY } from '@frontend/app/queries';

import {
  GetContentReviewsQuery,
  GetContentReviewsQueryVariables,
  GetContentReviewsQuery_reviews,
} from '@frontend/app/queries/types/GetContentReviewsQuery';

export type IContentReview = GetContentReviewsQuery_reviews;
type IOptions = QueryHookOptions<GetContentReviewsQuery, GetContentReviewsQueryVariables>;

export function useGetGroupContentReviews(options: IOptions = {}) {
  const {
    loading, data: { reviews } = {}, error, refetch,
  } = useQuery<GetContentReviewsQuery, GetContentReviewsQueryVariables>(
    GET_GROUP_CONTENT_REVIEWS_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: false,
      skip: isNil(options.variables?.programId) && isNil(options.variables?.memberId),
    },
  );

  return {
    loading,
    reviews,
    error,
    refetch,
  };
}
