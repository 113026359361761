import * as React from 'react';
import cx from 'classnames';
import { isUndefined } from 'lodash';

import { KeyboardArrowLeftIcon } from '@components';
import { IconButton } from '@components';

import { TableContext } from './tableContext';

const { useContext, useState, useLayoutEffect } = React;
import styles from './Pagination.scss';

interface IProps {
  total: number;
  page?: number;
  onPageChange(page: number): void;
  rowDisplayName?: string;

  className?: string;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export const TablePagination: React.FunctionComponent<IProps> = React.memo((props) => {
  const { total, rowDisplayName } = props;
  const tableContext = useContext(TableContext);
  const { config } = tableContext;
  const [pageState, setPageState] = useState(0);

  const isControlled = !isUndefined(props.page);
  const page = isControlled ? props.page : pageState;

  useLayoutEffect(() => {
    setPageState(0);
  }, [total]);

  const goToPrevPage = () => {
    props.onPageChange(page - 1);

    if (!isControlled) {
      setPageState(pageState - 1);
    }
  };

  const goToNextPage = () => {
    props.onPageChange(page + 1);

    if (!isControlled) {
      setPageState(page + 1);
    }
  };

  const maxPage = Math.floor((total - 1) / config.pageSize);

  return (
    <div className={cx(styles.Pagination, props.className)}>
      <div className={styles.label}>
        {page * config.pageSize + 1}
        {' '}
        -
        {' '}
        {Math.min(total, (page + 1) * config.pageSize)}
        {' '}
        of
        {' '}
        {total}
        {' '}
        {rowDisplayName}
      </div>
      <IconButton
        className={styles.button}
        icon={<KeyboardArrowLeftIcon />}
        disabled={page === 0}
        onClick={goToPrevPage}
        tooltip="Previous Page"
      />
      <IconButton
        className={styles.button}
        icon={<KeyboardArrowLeftIcon className={styles.nextPageIcon} />}
        disabled={page === maxPage}
        onClick={goToNextPage}
        tooltip="Next Page"
      />
    </div>
  );
});

TablePagination.defaultProps = {
  className: null,
};
TablePagination.displayName = 'TablePagination';
