/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1og0e_157 {
  display: flex;
}

._justify-content-space-between_1og0e_161 {
  justify-content: space-between;
}

._tabular-nums_1og0e_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1og0e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PopoverContent_1og0e_178._CustomText_1og0e_178 ._header_1og0e_178, ._PopoverContent_1og0e_178 ._title_1og0e_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._PopoverContent_1og0e_178._CustomText_1og0e_178 ._text_1og0e_188, ._PopoverContent_1og0e_178 ._text_1og0e_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._VariableBlock_1og0e_229 {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #1a1818;
  font-weight: bold;
}
._VariableBlock_1og0e_229._variable_1og0e_237 {
  height: 1.5rem;
  margin-bottom: 0.125rem;
  margin-left: 0.0625rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  background: #eff5f9;
  color: #3996e0;
  font-weight: bold;
  font-size: 0.75rem;
}
._VariableBlock_1og0e_229._variable_1og0e_237._invalid_1og0e_248 {
  padding-left: 1.875rem;
  background: #f5e8e9;
  color: #f1515f;
}
._VariableBlock_1og0e_229._variable_1og0e_237._invalid_1og0e_248._hasPlan_1og0e_253 {
  background: #eff5f9;
  color: #3996e0;
}
._VariableBlock_1og0e_229._variable_1og0e_237._invalid_1og0e_248 ._icon_1og0e_257 {
  position: absolute;
  left: 0.5rem;
  cursor: pointer;
}

._PopoverContent_1og0e_178 {
  display: flex;
  flex-direction: column;
  width: 25rem;
  margin: -0.75rem -1rem;
  padding: 1.5rem 0;
  color: #1a1818;
}
._PopoverContent_1og0e_178 ._title_1og0e_178 {
  margin: 0 1rem 0.625rem;
}
._PopoverContent_1og0e_178 ._text_1og0e_188 {
  margin: 0 1rem 1.25rem;
}
._PopoverContent_1og0e_178 ._item_1og0e_277 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.75rem;
  padding: 0 1rem;
  cursor: pointer;
  user-select: none;
  transition: all 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: background-color, color;
}
._PopoverContent_1og0e_178 ._item_1og0e_277:hover {
  background-color: #eff5f9;
  color: #4fa7ee;
}
._PopoverContent_1og0e_178 ._item_1og0e_277:hover ._icon_1og0e_257 {
  color: #4fa7ee;
}
._PopoverContent_1og0e_178 ._item_1og0e_277._active_1og0e_295 {
  color: #3996e0;
}
._PopoverContent_1og0e_178 ._item_1og0e_277 ._icon_1og0e_257 {
  margin-right: 1.5rem;
  margin-left: auto;
  color: #8f8d91;
  transform: rotate(180deg);
}
._PopoverContent_1og0e_178._CustomText_1og0e_178 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem 1rem 2rem;
}
._PopoverContent_1og0e_178._CustomText_1og0e_178 ._header_1og0e_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
._PopoverContent_1og0e_178._CustomText_1og0e_178 ._header_1og0e_178 ._backButton_1og0e_316 {
  display: flex;
  margin-right: 0.875rem;
  margin-left: 0.625rem;
  cursor: pointer;
}
._PopoverContent_1og0e_178._CustomText_1og0e_178 ._header_1og0e_178 ._backButton_1og0e_316:hover {
  color: #4fa7ee;
}
._PopoverContent_1og0e_178._CustomText_1og0e_178 ._text_1og0e_188 {
  margin: 0 0 1.375rem;
}
._PopoverContent_1og0e_178._CustomText_1og0e_178 ._input_1og0e_328 {
  width: 100%;
  margin-bottom: 1rem;
}