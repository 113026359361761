import { MutationHookOptions, useMutation } from '@apollo/client';
import { UPDATE_SELECTION_CRITERIA } from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import { UpdateCatalogSelectionRule, UpdateCatalogSelectionRuleVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/UpdateCatalogSelectionRule';

type IOptions = MutationHookOptions<UpdateCatalogSelectionRule, UpdateCatalogSelectionRuleVariables>;

export const useUpdateSelectionCriteria = (options: IOptions = {}) => {
  const [updateSelectionCriteria, { loading, data, error }] = useMutation(UPDATE_SELECTION_CRITERIA, options);

  return {
    loading,
    selectionCriteria: data?.updateCatalogSelectionRule,
    error,
    updateSelectionCriteria,
  };
};
