import * as React from 'react';

import { Drawer } from '@revfluence/fresh';
import { ApplicationHeader } from '@frontend/app/containers/Application/ApplicationHeader';
import { useConfirmOnExit } from '@frontend/app/hooks';

import MarketplaceTabs from './MarketplaceTabs';
import { useListOnMarketplace } from './context';

import styles from './MarketplaceDrawer.scss';

const { useMemo } = React;

const DRAWER_WIDTH = 720;

export const MarketplaceDrawer: React.FC = () => {
  const {
    hasDataChanged,
    isDrawerVisible,
    close,
    goBack,
    hasBeenSubmitted,
  } = useListOnMarketplace();

  const showConfirmOnExit = useMemo(() => (
    hasDataChanged
      && isDrawerVisible
      && !hasBeenSubmitted
  ), [hasDataChanged, isDrawerVisible, hasBeenSubmitted]);

  useConfirmOnExit({
    show: showConfirmOnExit,
    omitTransitions: [
      {
        from: 'campaign-details',
        to: 'eligibility-restrictions',
      },
      {
        from: 'eligibility-restrictions',
        to: 'campaign-details',
      },
    ],
  });

  return (
    <Drawer
      className={styles.drawer}
      onClose={close}
      width={DRAWER_WIDTH}
      title={(
        <ApplicationHeader
          name="List on Marketplace"
          goBack={goBack}
          closeModal={close}
        />
      )}
      closable={false}
      visible={isDrawerVisible}
      destroyOnClose
    >
      <MarketplaceTabs />
    </Drawer>
  );
};

MarketplaceDrawer.displayName = 'MarketplaceDrawer';
