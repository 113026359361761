import * as React from 'react';

import { Row, Space, useBreakpoint } from '@revfluence/fresh';
import styles from './StatisticsBlock.scss';
import { StatisticsItem } from './StatisticsItem';
import { IStatisticsItem } from './types';

interface IProps {
    statisticsItem: IStatisticsItem[];
}

const { useMemo } = React;

export const StatisticsBlock: React.FC<Readonly<IProps>> = React.memo((props) => {
    const screens = useBreakpoint();
    const { statisticsItem } = props;

    const statsItems = useMemo(() => statisticsItem.map((stats, index) => <StatisticsItem key={index} {...stats} />), [statisticsItem]);

    let itemsPerRow = 3;

    if (screens.xl) {
        itemsPerRow = 8;
    } else if (screens.sm) {
        itemsPerRow = 4;
    }

    const rows = [];
    for (let i = 0; i < statsItems.length; i += itemsPerRow) {
        const rowItems = statsItems.slice(i, i + itemsPerRow);
        rows.push(
          <Row key={i} justify="space-between" className={styles.statsRowBlock}>
            {rowItems}
          </Row>,
        );
    }

    return (
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        {rows.map((row, index) => (
          <div key={index}>{row}</div>
            ))}
      </Space>
    );
});

StatisticsBlock.displayName = 'StatisticsBlock';
