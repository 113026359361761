import * as React from 'react';
import cx from 'classnames';

import styles from './PaginationArrow.scss';

const leftArrowSvg = require('@frontend/app/assets/svgs/arrowCircle_left.svg');
const rightArrowSvg = require('@frontend/app/assets/svgs/arrowCircle_right.svg');

export enum PaginationArrowDirection {
  Next = 'next',
  Prev = 'previous',
}

const arrowConfig = {
  [PaginationArrowDirection.Next]: {
    icon: rightArrowSvg,
    style: styles.arrowRight,
  },
  [PaginationArrowDirection.Prev]: {
    icon: leftArrowSvg,
    style: styles.arrowLeft,
  },
};

interface IProps {
  arrowDirection: PaginationArrowDirection,
  handleClick: () => void,
}

export const PaginationArrow = React.memo((props: IProps) => {
  const {
    arrowDirection,
    handleClick,
  } = props;
  const { style, icon } = arrowConfig[arrowDirection];
  return (
    <button
      className={cx(styles.PaginationArrow, style)}
      onClick={handleClick}
    >
      <img
        src={icon}
        alt={`Go to ${arrowDirection}`}
      />
    </button>
  );
});

PaginationArrow.displayName = 'PaginationArrow';
