import { gql } from '@apollo/client';

export const GET_BUDGETS_WITH_FISCAL_YEAR_QUERY = gql`
  query GetBudgetsWithFiscalYearQuery($fiscalYear: String) {
    getBudgetDashboardDetailedViewTabBudgetData(fiscalYear: $fiscalYear) {
      allocatedAmount
      availableAmount
      budgetAccountId
      budgetName
      childBudgetsList
      parentBudgetId
      parentBudgetName
      quarterDistributions
      spentAmount
      totalAmount
    }
  }
`;
