import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const VideoIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="
        M21.4,16.4c-0.1,0-0.2,0-0.3-0.1l-4-2c-0.3-0.1-0.4-0.5-0.3-0.8c0.1-0.3,0.5-0.4,0.8-0.3l3.2,1.6V9.5
        L17.7,11c-0.3,0.1-0.6,0-0.8-0.3c-0.1-0.3,0-0.6,0.3-0.8l4-2c0.2-0.1,0.4-0.1,0.5,0C21.9,8.2,22,8.3,22,8.5v7.3
        c0,0.2-0.1,0.4-0.3,0.5C21.6,16.4,21.5,16.4,21.4,16.4z
        M4.3,7.4c-0.6,0-1.2,0.5-1.2,1.2v6.9c0,0.6,0.5,1.2,1.2,
        1.2h9.2 c0.6,0,1.2-0.5,1.2-1.2V8.5c0-0.6-0.5-1.2-1.2-1.2H4.3z
        M13.5,17.8H4.3c-1.3,0-2.3-1-2.3-2.3V8.5c0-1.3,1-2.3,2.3-2.3h9.2
        c1.3,0,2.3,1,2.3,2.3v6.9C15.8,16.7,14.8,17.8,13.5,17.8z
      "
      fill={props.fill}
    />
  </SvgIcon>
));

VideoIcon.displayName = 'VideoIcon';
