/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fj1z_157 {
  display: flex;
}

._justify-content-space-between_1fj1z_161 {
  justify-content: space-between;
}

._tabular-nums_1fj1z_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fj1z_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178 ._tab_1fj1z_165 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178 {
  display: table;
  table-layout: fixed;
}
._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178._fullWidth_1fj1z_192 {
  width: 100%;
}
._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178 ._tab_1fj1z_165 {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 16px;
  cursor: pointer;
}
._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178 ._tab_1fj1z_165:hover {
  background-color: rgba(230, 237, 242, 0.5);
}
._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178 ._tab_1fj1z_165._selected_1fj1z_206 {
  font-weight: 600;
  color: #3996e0;
}
._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178 ._tab_1fj1z_165._selected_1fj1z_206::before {
  content: "";
  position: absolute;
  background-color: #3996e0;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
}
._Tabs_1fj1z_178 ._tabWrapper_1fj1z_178 ._tab_1fj1z_165._disabled_1fj1z_219 {
  opacity: 0.3;
  pointer-events: none;
}
._Tabs_1fj1z_178 ._tabContent_1fj1z_223 {
  display: none;
  border-top: 1px solid #ececec;
  padding-top: 16px;
  margin-top: -1px;
}
._Tabs_1fj1z_178 ._tabContent_1fj1z_223._selected_1fj1z_206 {
  display: block;
}