/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17jub_157 {
  display: flex;
}

._justify-content-space-between_17jub_161 {
  justify-content: space-between;
}

._tabular-nums_17jub_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17jub_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17jub_157 {
  display: flex;
}

._justify-content-space-between_17jub_161 {
  justify-content: space-between;
}

._tabular-nums_17jub_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17jub_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._table_17jub_355 {
  width: 100%;
  margin-top: 0.5rem;
  border: 0.0625rem solid #ececec;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 0.5rem;
}
._table_17jub_355 thead tr th {
  text-transform: uppercase;
}
._table_17jub_355 thead tr th:first-of-type {
  border-top-left-radius: 0.5rem;
}
._table_17jub_355 thead tr th:last-of-type {
  border-top-right-radius: 0.5rem;
}
._table_17jub_355 tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 0.5rem;
}
._table_17jub_355 tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 0.5rem;
}
._table_17jub_355 thead tr th,
._table_17jub_355 tbody tr:not(:last-of-type) td {
  border-bottom: 0.0625rem solid #ececec;
}
._table_17jub_355 tr th,
._table_17jub_355 tr td {
  min-height: 2.5rem;
  padding: 1rem;
  text-align: left;
}
._table_17jub_355 tr th {
  color: #8f8d91;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
}

._footer_17jub_396 {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4.5rem;
  margin: 0 0 0 -2.25rem;
  padding: 1rem 2.25rem;
  border-top: 0.0625rem solid #dadcde;
  background-color: #fdfdfd;
}
._footer_17jub_396 > div:last-of-type {
  margin-left: auto;
}

._ReviewImport_17jub_412._isLoading_17jub_412 ._table_17jub_355 {
  opacity: 0.25;
}
._ReviewImport_17jub_412 ._alert_17jub_415 {
  margin: 0.5rem 0;
}
._ReviewImport_17jub_412 ._checkIcon_17jub_418,
._ReviewImport_17jub_412 ._errorIcon_17jub_419 {
  vertical-align: middle;
}
._ReviewImport_17jub_412 ._checkIcon_17jub_418 > path {
  fill: #3996e0;
}
._ReviewImport_17jub_412 ._errorIcon_17jub_419 {
  fill: #d9534f;
}
._ReviewImport_17jub_412 ._identifierTag_17jub_428 {
  cursor: pointer;
}
._ReviewImport_17jub_412 ._fieldSelect_17jub_431 {
  width: 16.25rem;
}
._ReviewImport_17jub_412 ._fieldSelect_17jub_431._hasError_17jub_434 {
  border-color: #d9534f;
}
._ReviewImport_17jub_412 ._unassignedMessage_17jub_437,
._ReviewImport_17jub_412 ._errorMessage_17jub_438 {
  display: block;
  margin: 0.25rem 0 -0.75rem;
}
._ReviewImport_17jub_412 ._unassignedMessage_17jub_437 {
  color: #8f8d91;
}
._ReviewImport_17jub_412 ._errorMessage_17jub_438 {
  color: #d9534f;
}
._ReviewImport_17jub_412 ._hasError_17jub_434 ._dataPreviewCell_17jub_448 {
  color: #d9534f;
}
._ReviewImport_17jub_412 ._dataPreviewCell_17jub_448 ._filler_17jub_451 {
  color: #aeaeae;
}
._ReviewImport_17jub_412 ._table_17jub_355 {
  transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
._ReviewImport_17jub_412 ._table_17jub_355 tbody tr td:first-of-type {
  text-align: center;
}

._footer_17jub_396 {
  position: sticky;
  margin: 0 !important;
}