import React from 'react';
import { Typography } from '@revfluence/fresh';
import styles from './AutomationStatCard.scss';

const { Title } = Typography;

type IProps = {
  stat: number;
  title: string;
};

const AutomationStatCard = (props: IProps) => {
  const { stat, title } = props;

  return (
    <div className={styles.AutomationStatCard}>
      <Title level={5}>{stat}</Title>
      <div className={styles.title}>{title}</div>
    </div>
  );
};

export default AutomationStatCard;
