import * as React from 'react';
import { LazyImage } from '@components';

import styles from './CommunitySelectItem.scss';
import {
  EllipsisLabel,
} from '..';

const defaultCommunityLogo = 'https://storage.googleapis.com/aspirex-static-files/default_community.png';

interface IProps {
  title: string;
  imageUrl?: string;
  icon?: React.ReactNode;
}

export const CommunitySelectItem: React.FunctionComponent<IProps> = React.memo((props) => (
  <div className={styles.CommunitySelectItem}>
    {!!props.icon && (
    <div className={styles.logo}>{props.icon}</div>
      )}
    {!props.icon && (
    <LazyImage
      className={styles.logo}
      key={props.imageUrl}
      src={props.imageUrl}
      fallbackSrc={defaultCommunityLogo}
    />
      )}
    <div>
      <EllipsisLabel tooltipPlacement="right">
        {props.title}
      </EllipsisLabel>
    </div>
  </div>
));

CommunitySelectItem.displayName = 'CommunitySelectItem';
