import { IMedia } from './media';

export interface IContent {
  date_created: string;
  media?: IMedia[];
  content_class?: string;
  saved_media_list?: IMedia[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preview_map?: Record<string, any>;
  image_link?: string;
  link?: string;
  caption?: string;
}
