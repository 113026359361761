import * as React from 'react';
import cx from 'classnames';
import {
  isNil,
} from 'lodash';
import {
  GetMemberQuery_member as IMember,
} from '@frontend/app/queries/types/GetMemberQuery';
import styles from './MemberIGDMActivityItem.scss';
import { IApplication, IMemberActivity } from './types';
import { IGDMActivityItemHeader } from './IGDMActivityItemHeader';
import { IGDMActivityItemBody } from './IGDMActivityItemBody';

const { useMemo } = React;

interface IProps {
  member: IMember;
  message: IMemberActivity;
  applications: IApplication[];
  className?: string;
  instagramFieldId?: number;
}

export const MemberIGDMActivityItem: React.FC<IProps> = React.memo((props) => {
  const {
    message, applications, member, instagramFieldId,
  } = props;

  const isStory = useMemo(() => !isNil(message.payload.story?.mention), [message.payload]);

  return (
    <div className={cx(styles.MemberIGDMActivityItem, props.className)}>
      <IGDMActivityItemHeader
        applications={applications}
        member={member}
        isStory={isStory}
        message={message}
        instagramFieldId={instagramFieldId}
      />
      <IGDMActivityItemBody
        message={message}
        isStory={isStory}
      />
    </div>
  );
});

MemberIGDMActivityItem.displayName = 'MemberIGDMActivityItem';
MemberIGDMActivityItem.defaultProps = {
  className: null,
};
