import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const CalendarIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 361.77 361.77">
    <path
      d="M323.885,43.77h-27.5V25c0-13.807-11.193-25-25-25h-1c-13.807,0-25,11.193-25,25v18.77h-129V25c0-13.807-11.193-25-25-25
      h-1c-13.807,0-25,11.193-25,25v18.77h-27.5c-13.807,0-25,11.193-25,25v268c0,13.809,11.193,25,25,25h286
      c13.807,0,25-11.191,25-25v-268C348.885,54.963,337.691,43.77,323.885,43.77z M306.885,322.27h-252v-203h252V322.27z"
    />
    <path
      d="M89.136,211.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4H89.136c-2.209,0-4,1.791-4,4v43.498
      C85.136,209.343,86.927,211.134,89.136,211.134z"
    />
    <path
      d="M159.136,211.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498
      C155.136,209.343,156.927,211.134,159.136,211.134z"
    />
    <path
      d="M229.136,211.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498
      C225.136,209.343,226.927,211.134,229.136,211.134z"
    />
    <path
      d="M89.136,281.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4H89.136c-2.209,0-4,1.791-4,4v43.498
      C85.136,279.343,86.927,281.134,89.136,281.134z"
    />
    <path
      d="M159.136,281.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498
      C155.136,279.343,156.927,281.134,159.136,281.134z"
    />
    <path
      d="M229.136,281.134h43.498c2.209,0,4-1.791,4-4v-43.498c0-2.209-1.791-4-4-4h-43.498c-2.209,0-4,1.791-4,4v43.498
      C225.136,279.343,226.927,281.134,229.136,281.134z"
    />
  </SvgIcon>
));

CalendarIcon.displayName = 'CalendarIcon';

export { CalendarIcon };
