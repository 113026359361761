import gql from 'graphql-tag';

import { MEMBER_FRAGMENT, MESSAGE_FRAGMENT_WITH_PAYLOAD } from './fragments';

export const GET_MEMBERS_QUERY = gql`
  query GetMembersQuery($take: Int!, $skip: Int!) {
    members(take: $take, skip: $skip) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_MEMBER_QUERY = gql`
  query GetMemberQuery($id: Int!) {
    member: memberById(id: $id) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_MEMBER_BY_EMAIL_QUERY = gql`
  query GetMemberByEmailQuery($email: String!) {
    member: memberByEmail(email: $email) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_MEMBERS_BY_EMAIL = gql`
  query GetMembersByEmail($email: String!) {
    members: membersByEmail(email: $email) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_MEMBERS_FOR_IDS_QUERY = gql`
  query GetMembersForIdsQuery($ids: [Int!],$take: Int, $skip: Int) {
    members: members(ids: $ids, take: $take, skip: $skip) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const GET_MEMBER_ACTIVITIES_QUERY = gql`
  query GetMemberActivitiesQuery($id: Int!, $limit: Int, $offset: Int, $messageTypes: [MessageType!]) {
    activities: memberActivities(id: $id, limit: $limit, offset: $offset, messageTypes: $messageTypes) {
      ...MessageFragmentWithPayload
    }
  }
  ${MESSAGE_FRAGMENT_WITH_PAYLOAD}
`;

export const UPLOAD_MEMBERS_CSV = gql`
  mutation UploadMembersCSV($file: Upload!, $params: JSONObject){
    result: uploadMemberCSV(file: $file, params: $params) {
      successfulMembersCount
      failedMembersCount
      failedMemberErrors
      failedRelationWarnings
    }
  }
`;
