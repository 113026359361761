import {
  camelCase,
  isEmpty,
  isNull,
  reduce,
} from 'lodash';

import { TDateRequirement } from '../types/ContentDateRequirements';
import {
  ContentColumnKey,
  CONTENT_MEMBER_FIELD_MAP,
  IMember,
  SocialColumnKey,
  SOCIAL_MEMBER_FIELD_MAP,
} from '../types/Member';
import { IState } from '../types/state';
import { getMemberComment } from './getMemberComment';

const BLANK_CONTENT_FIELDS: Record<string, Array<TDateRequirement>> = {
  additionalImages: [],
  additionalVideos: [],
  blogPostMentions: [],
  blogPosts: [],
  customWork: [],
  facebookPosts: [],
  instagramPosts: [],
  instagramReels: [],
  instagramStories: [],
  instagramVideos: [],
  pinterestPins: [],
  snapchatStories: [],
  tiktokVideos: [],
  twitterTweets: [],
  youtubeProductMentions: [],
  youtubeVideos: [],
};

export const applyContentGuidelinesToMembers = (
  state: IState,
) => (member: IMember): IMember => {
  const {
    collaborationDetails,
    contentGuidelines,
  } = state;

  const populatedContentFields: Partial<IMember> = {};

  // assume the user is disabled unless we find a valid content type they can submit
  let disabled = true;
  let comment = 'Missing network info.';

  for (const guideline of contentGuidelines) {
    const {
      dueDates,
      type,
    } = guideline;

    const memberContentField: ContentColumnKey = CONTENT_MEMBER_FIELD_MAP[type];
    if (!memberContentField) {
      throw new Error(`Unhandled content type: "${type}".`);
    }

    const memberSocialField: SocialColumnKey | undefined = SOCIAL_MEMBER_FIELD_MAP[type];
    if (memberSocialField && (isNull(member[memberSocialField]) || isEmpty(member[memberSocialField]))) {
      // user is missing the social handle for this content type
      continue;
    }

    populatedContentFields[memberContentField] = [
      ...(populatedContentFields[memberContentField] || []),
      ...dueDates.completeDate,
    ];
    disabled = false;
    comment = null;
  }

  if (!member.email) {
    disabled = true;
    comment = 'Member is missing email.';
  }

  const recommendedPaymentAmount = reduce(contentGuidelines, (priceAccumulator, guideline) => {
    const postType = camelCase(guideline.type.toLowerCase());
    const postPrice = member[`${postType}RecommendedPayment`] || 0;
    return priceAccumulator + (postPrice * guideline.dueDates.completeDate.length);
  }, 0);

  const updatedMember = {
    ...member,
    ...BLANK_CONTENT_FIELDS,
    ...populatedContentFields,
    disabled,
    comment,
    selected: disabled ? false : member.selected,
    recommendedPaymentAmount,
  };
  if (!disabled) {
    updatedMember.comment = getMemberComment(
      updatedMember,
      collaborationDetails,
      contentGuidelines,
    );
  }

  return updatedMember;
};
