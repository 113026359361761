import * as React from 'react';
import { isFinite } from 'lodash';

import { Col, Row } from '@revfluence/fresh';
import {
  endOfMonth, isAfter, startOfMonth, format, addMonths,
} from 'date-fns';

import { formatters } from '@frontend/app/components/StatisticsCard/formatters';
import { getLastFullMonths, getTrendTooltip } from '@frontend/applications/ReportsApp/containers/utils';
import Trend from '../../../../components/Trend';
import Metric from '../../../../components/Metric';
import { IHistoricalData, IImpactHomeProps } from '../../Home';
import { getTrendProps, getTrendTooltips } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary';
import { formatDateTimeString } from '../../../../containers/Chart/LineChartContainer';

interface IMetricsPrimaryProps {
  contentData: IImpactHomeProps['contentData']
  impressionsData: IImpactHomeProps['impressionsData']
  engagementsData: IImpactHomeProps['engagementsData']
  salesData: IImpactHomeProps['contentData']
  igStoryEstimationEnabled?: boolean
  tempYoutubeDemoAccountMode?: boolean
}

type TPrimaryMetric = 'content' | 'impressions' | 'engagements' | 'sales';

const getBarChartTooltip = (value: number, metricType: TPrimaryMetric): string => {
  const valueString = formatters.default(value);

  switch (metricType) {
    case 'content': {
      return `${valueString} piece${value !== 1 ? 's' : ''} of content`;
    }
    case 'impressions': {
      return `${valueString} impression${value !== 1 ? 's' : ''}`;
    }
    case 'engagements': {
      return `${valueString} engagement${value !== 1 ? 's' : ''}`;
    }
    case 'sales': {
      return `$${valueString} of sales`;
    }
  }
};

const getEmptyHistoricalData = (): IHistoricalData[] => {
  const [startDate] = getLastFullMonths(4);
  const formatDate = (d: Date) => format(d, 'yyyy/MM/dd');
  return [
    {
      dateTime: formatDate(startDate),
      value: 0,
    },
    {
      dateTime: formatDate(addMonths(startDate, 1)),
      value: 0,
    },
    {
      dateTime: formatDate(addMonths(startDate, 2)),
      value: 0,
    },
    {
      dateTime: formatDate(addMonths(startDate, 3)),
      value: 0,
    },
  ];
};

const mapBarChartData = (data: IHistoricalData[], metricType: TPrimaryMetric) => {
  const historicalData = data.length === 0 ? getEmptyHistoricalData() : data;

  return historicalData.slice(-4).map(({ dateTime, value }) => {
    const date = new Date(formatDateTimeString(dateTime));
    const today = new Date();
    const monthEnd = endOfMonth(date);
    const isAfterToday = isAfter(monthEnd, today);
    const startDate = startOfMonth(date);
    const endDate = isAfterToday ? today : monthEnd;
    const startDateString = format(startDate, 'MMM d');
    const endDateString = format(endDate, 'd');
    return {
      value,
      tooltipTitle: `${startDateString} - ${endDateString}`,
      tooltipDescription: getBarChartTooltip(value, metricType),
    };
  });
};

const tooltipTrend = getTrendTooltip(getLastFullMonths(2));

const getTrendValue = (value: number | null) => (isFinite(value) ? formatters.default(Math.abs(value), { precision: 0 }) : '0');

const getValue = (n?: number) => (n ? formatters.default(n) : '-');

const MetricsPrimary = ({
  contentData,
  impressionsData,
  engagementsData,
  salesData,
  igStoryEstimationEnabled,
  tempYoutubeDemoAccountMode,
}: IMetricsPrimaryProps) => {
  const trendTooltips = getTrendTooltips(igStoryEstimationEnabled, tempYoutubeDemoAccountMode);

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
        <Metric
          primaryStatistics={{
            title: 'Content',
            valueRender: () => getValue(contentData.value),
            tooltip: trendTooltips.content,
          }}
          secondaryStatistics={{
            valueRender: () => getTrendValue(contentData.percentDiff),
            suffix: '%',
            color: getTrendProps(contentData.percentDiff).color,
            prefix: <Trend {...getTrendProps(contentData.percentDiff)} />,
            tooltip: tooltipTrend,
          }}
          color="blue"
          chart={mapBarChartData(contentData.historicalData, 'content')}
        />
      </Col>
      <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
        <Metric
          primaryStatistics={{
            title: 'Impressions',
            valueRender: () => getValue(impressionsData.value),
            tooltip: trendTooltips.impressions,
          }}
          secondaryStatistics={{
            valueRender: () => getTrendValue(impressionsData.percentDiff),
            suffix: '%',
            color: getTrendProps(impressionsData.percentDiff).color,
            prefix: <Trend {...getTrendProps(impressionsData.percentDiff)} />,
            tooltip: tooltipTrend,
          }}
          color="plum"
          chart={mapBarChartData(impressionsData.historicalData, 'impressions')}
        />
      </Col>
      <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
        <Metric
          primaryStatistics={{
            title: 'Engagements',
            valueRender: () => getValue(engagementsData.value),
            tooltip: trendTooltips.engagements,
          }}
          secondaryStatistics={{
            valueRender: () => getTrendValue(engagementsData.percentDiff),
            suffix: '%',
            color: getTrendProps(engagementsData.percentDiff).color,
            prefix: <Trend {...getTrendProps(engagementsData.percentDiff)} />,
            tooltip: tooltipTrend,
          }}
          color="orange"
          chart={mapBarChartData(engagementsData.historicalData, 'engagements')}
        />
      </Col>
      <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
        <Metric
          primaryStatistics={{
            title: 'Sales',
            valueRender: () => getValue(salesData.value),
            prefix: salesData.value ? '$' : '',
            tooltip: trendTooltips.sales,
          }}
          secondaryStatistics={{
            valueRender: () => getTrendValue(salesData.percentDiff),
            suffix: '%',
            color: getTrendProps(salesData.percentDiff).color,
            prefix: <Trend {...getTrendProps(salesData.percentDiff)} />,
            tooltip: tooltipTrend,
          }}
          color="green"
          chart={mapBarChartData(salesData.historicalData, 'sales')}
        />
      </Col>
    </Row>
  );
};

export default MetricsPrimary;
