import { useMutation, MutationHookOptions } from '@apollo/client';

import { BULK_FULFILL_ORDER_MUTATION } from '../queries';
import { BulkFulfillOrderMutation, BulkFulfillOrderMutationVariables } from '../queries/types/BulkFulfillOrderMutation';

type IOptions = MutationHookOptions<BulkFulfillOrderMutation, BulkFulfillOrderMutationVariables>;

export function useBulkFulfillOrder(options: IOptions = {}) {
  const [bulkFulfillOrder, { loading, error }] = useMutation<
    BulkFulfillOrderMutation,
    BulkFulfillOrderMutationVariables
  >(BULK_FULFILL_ORDER_MUTATION, options);

  return {
    bulkFulfillOrder,
    loading,
    error,
  };
}
