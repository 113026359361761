/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_18uns_48,
._audienceAgeBarChart_18uns_49 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_18uns_48 > [class*=list],
._audienceAgeBarChart_18uns_49 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_18uns_98 {
  cursor: help;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_18uns_258 {
  display: flex;
}

._justify-content-space-between_18uns_262 {
  justify-content: space-between;
}

._tabular-nums_18uns_266, ._detail_18uns_266._average_engagement_18uns_266 ._value_18uns_266, ._detail_18uns_266._impression_18uns_266 ._value_18uns_266, ._detail_18uns_266._age_range_18uns_266 ._value_18uns_266, ._detail_18uns_266._audience_authenticity_18uns_266 ._value_18uns_266, ._detail_18uns_266._impression_per_story_18uns_266 ._value_18uns_266, ._detail_18uns_266 ._value_18uns_266, ._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._detail_18uns_266 ._title_18uns_271::selection, ._detail_18uns_266 ::selection, ._Card_18uns_271 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_18uns_271 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_18uns_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._detail_18uns_266, ._Card_18uns_271 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._detail_18uns_266 ::-webkit-scrollbar, ._Card_18uns_271 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._detail_18uns_266 ::-webkit-scrollbar-track, ._Card_18uns_271 ::-webkit-scrollbar-track, ._detail_18uns_266 ::-webkit-scrollbar-track-piece, ._Card_18uns_271 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._detail_18uns_266 ::-webkit-scrollbar-thumb, ._Card_18uns_271 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_18uns_48,
._audienceAgeBarChart_18uns_49 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_18uns_48 > [class*=list],
._audienceAgeBarChart_18uns_49 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_18uns_98 {
  cursor: help;
}

._Card_18uns_271 {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  break-inside: avoid-column;
  box-sizing: border-box;
}
._Card_18uns_271 ._cardHeader_18uns_421 {
  margin-top: -0.5rem;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._Card_18uns_271 ._cardHeader_18uns_421:empty {
  display: none;
  margin: unset;
}
._Card_18uns_271 ._tabs_18uns_432 {
  margin-right: -0.5rem;
  align-self: flex-end;
}
._Card_18uns_271 ._tabs_18uns_432 ._tabButton_18uns_436 {
  min-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  letter-spacing: 0.0625rem;
}
._Card_18uns_271 ._tabs_18uns_432 ._tabButton_18uns_436, ._Card_18uns_271 ._tabs_18uns_432 ._tabButton_18uns_436:hover {
  background-color: transparent;
}
._Card_18uns_271 ._tabs_18uns_432 ._tabButton_18uns_436:hover > div {
  color: #3996e0;
}
._Card_18uns_271 ._tabs_18uns_432 ._tabButton_18uns_436 > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aeaeae;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Card_18uns_271 ._tabs_18uns_432 ._tabButton_18uns_436._activeTab_18uns_455 > div {
  color: #3996e0;
}

._separator_18uns_459 {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  height: 0.0625rem;
  border-bottom: 0.0625rem solid #eff5f9;
}

._cardTab_18uns_466 {
  display: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._cardTitle_18uns_474 {
  margin: 0 auto 0 0;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

._visible_18uns_482 {
  display: flex !important;
  animation: _show_18uns_1 0.4s;
}

._noData_18uns_487::after {
  position: absolute;
  top: calc(50% + 0.6666666667rem);
  left: 50%;
  display: block;
  color: #8f8d91;
  content: "No data yet";
  transform: translate(-50%, -50%);
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_18uns_258 {
  display: flex;
}

._justify-content-space-between_18uns_262 {
  justify-content: space-between;
}

._tabular-nums_18uns_266, ._detail_18uns_266._average_engagement_18uns_266 ._value_18uns_266, ._detail_18uns_266._impression_18uns_266 ._value_18uns_266, ._detail_18uns_266._age_range_18uns_266 ._value_18uns_266, ._detail_18uns_266._audience_authenticity_18uns_266 ._value_18uns_266, ._detail_18uns_266._impression_per_story_18uns_266 ._value_18uns_266, ._detail_18uns_266 ._value_18uns_266, ._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._detail_18uns_266 ._title_18uns_271::selection, ._detail_18uns_266 ::selection, ._Card_18uns_271 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_18uns_271 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_18uns_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._detail_18uns_266, ._Card_18uns_271 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._detail_18uns_266 ::-webkit-scrollbar, ._Card_18uns_271 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._detail_18uns_266 ::-webkit-scrollbar-track, ._Card_18uns_271 ::-webkit-scrollbar-track, ._detail_18uns_266 ::-webkit-scrollbar-track-piece, ._Card_18uns_271 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._detail_18uns_266 ::-webkit-scrollbar-thumb, ._Card_18uns_271 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_18uns_48,
._audienceAgeBarChart_18uns_49 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_18uns_48 > [class*=list],
._audienceAgeBarChart_18uns_49 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_18uns_48 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_18uns_49 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_18uns_98 {
  cursor: help;
}

._detail_18uns_266 {
  display: grid;
  grid-template-columns: 0 1fr;
  grid-template-rows: repeat(2, 1rem);
  grid-column-gap: 0.625rem;
  align-content: center;
}
._detail_18uns_266 ._icon_18uns_813 {
  grid-column: 1/2;
  grid-row: 1/2 span;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #eff5f9;
  border-radius: 0.5rem;
}
._detail_18uns_266 ._title_18uns_271 {
  grid-column: 2;
  grid-row: 1;
  color: #767378;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.8rem;
  letter-spacing: 0.03125rem;
}
._detail_18uns_266 ._value_18uns_266 {
  font-weight: 500;
  line-height: 1rem;
}
._detail_18uns_266._horizontal_18uns_835 {
  grid-template-columns: 0 1fr 4rem;
  grid-template-rows: 2rem;
}
._detail_18uns_266._horizontal_18uns_835 ._title_18uns_271,
._detail_18uns_266._horizontal_18uns_835 ._value_18uns_266 {
  display: flex;
  align-items: center;
}
._detail_18uns_266._horizontal_18uns_835 ._value_18uns_266 {
  justify-content: flex-end;
  text-align: right;
}
._detail_18uns_266._audience_authenticity_18uns_266 ._value_18uns_266 strong {
  margin-left: 0.25rem;
  font-weight: 600;
}
._detail_18uns_266._gender_18uns_852 {
  text-transform: capitalize;
}
._detail_18uns_266._dislikes_per_video_18uns_855 ._icon_18uns_813 svg {
  transform: scaleY(-1);
}

._high_18uns_859 {
  color: #4eb468;
}

._good_18uns_863 {
  color: #3996e0;
}

._suspicious_18uns_867,
._low_18uns_868 {
  color: #da3737;
}

._positive_18uns_872 {
  color: #4eb468;
}

._neutral_18uns_876 {
  color: #5c5a5e;
}

._negative_18uns_880 {
  color: #da3737;
}

._reviewedIcon_18uns_884 {
  margin-right: 0.25rem;
  fill: #3996e0;
  vertical-align: bottom;
}

._SummaryCard_18uns_890 ._audienceTab_18uns_890,
._SummaryCard_18uns_890 ._creatorTab_18uns_891 {
  height: 100%;
}
._SummaryCard_18uns_890 ._audienceTab_18uns_890 ._detailGrid_18uns_894 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(4rem, 1fr));
}
._SummaryCard_18uns_890._blog_18uns_901 ._audienceTab_18uns_890 ._detailGrid_18uns_894, ._SummaryCard_18uns_890._facebook_18uns_901 ._audienceTab_18uns_890 ._detailGrid_18uns_894, ._SummaryCard_18uns_890._twitter_18uns_901 ._audienceTab_18uns_890 ._detailGrid_18uns_894 {
  grid-template-columns: 1fr;
}
._SummaryCard_18uns_890._tiktok_18uns_904 ._detailGrid_18uns_894 {
  grid-template-rows: repeat(auto-fill, minmax(2.875rem, 1fr));
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._interests_18uns_913,
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._brands_18uns_914 {
  position: relative;
  flex: 1;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._interests_18uns_913 h5,
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._brands_18uns_914 h5 {
  margin: 0.75rem 0 1rem;
  color: #8f8d91;
  font-weight: 500;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._interests_18uns_913 {
  margin-right: 1rem;
  position: relative;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._interests_18uns_913 ._interestsWrapper_18uns_928 {
  height: calc(100% - 3rem);
  position: absolute;
  flex-flow: wrap;
  overflow: hidden;
  line-height: 1.25rem;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._brands_18uns_914 ._brandGrid_18uns_935 {
  display: grid;
  grid-template-columns: repeat(4, 3rem);
  grid-template-rows: repeat(4, 3rem);
  grid-gap: 0.25rem;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._brands_18uns_914 ._brandGrid_18uns_935 ._brand_18uns_914,
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._brands_18uns_914 ._brandGrid_18uns_935 ._moreBrands_18uns_942 {
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._brands_18uns_914 ._brandGrid_18uns_935 ._brand_18uns_914 {
  cursor: pointer;
}
._SummaryCard_18uns_890 ._creatorTab_18uns_891 ._creatorSummary_18uns_907 ._brands_18uns_914 ._brandGrid_18uns_935 ._moreBrands_18uns_942 {
  padding-right: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff5f9;
  font-size: 1rem;
  color: #3996e0;
}