import React from 'react';

import {
  ApolloClient,
  from,
  gql,
  InMemoryCache,
  NormalizedCacheObject,
  ServerParseError,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

const { useMemo } = React;

export function useInitializeApolloClient(token: string) {
  const apolloClient = useMemo<ApolloClient<NormalizedCacheObject>>(() => {
    if (!token) {
      return null;
    }

    const errorLink = onError(({ networkError }) => {
      if ((networkError as ServerParseError)?.statusCode === 401) {
        /**
         * Reload page if token has expired.
         * We have configured the token expiration to equal inactivity timeout,
         * so reloading the page will require the user to log in again.
         */
        window.location.reload();
      }
    });
    const httpLink = createUploadLink({ uri: '/api/graphql' });
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));

    const client = new ApolloClient({
      link: from([authLink, errorLink, httpLink]),
      cache: new InMemoryCache({
        addTypename: true,
      }),
      resolvers: {},
    });

    const data = {
      membersHaveChanged: false,
    };

    client.cache.writeQuery({
      query: gql`
          query GetMembersHaveChanged {
            membersHaveChanged
          }
        `,
      data,
    });

    client.onResetStore(async () => {
      client.cache.writeQuery({
        query: gql`
            query GetMembersHaveChanged {
              membersHaveChanged
            }
          `,
        data,
      });
    });

    return client;
  }, [token]);

  return apolloClient;
}
