import { CustomFieldType } from '@services/communities';

export interface IProgramSchema {
  id: string | number;
  name: string;
  type?: CustomFieldType;
  metadata?: {
    [key: string]: unknown;
  }
}
