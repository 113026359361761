import { updateGuidelineInstruction } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IState } from '../../../../types/state';
import { IUpdateContentGuidelineInstruction } from './index';

export const addContentGuidelineInstruction = (state: IState, action: IUpdateContentGuidelineInstruction): IState => ({
  ...state,
  contentGuidelines: updateGuidelineInstruction(
    state.contentGuidelines,
    action.contentGuidelineInstruction.id,
    action.contentGuidelineInstruction.guidelineIndex,
    action.contentGuidelineInstruction.instructionIndex,
    action.contentGuidelineInstruction.instruction,
  ),
});
