import gql from 'graphql-tag';
import { TERMS_TEMPLATE_FRAGMENT } from './fragments/TermsTemplateFragment';

export const SET_DEFAULT_PROGRAM_TEMPLATE_MUTATION = gql`
  mutation SetDefaultProgramTemplateMutation($programId: Int!, $termsTemplateId: Int!) {
    newDefaultProgramTemplate: setDefaultProgramTemplate(programId: $programId, termsTemplateId: $termsTemplateId) {
      ...TermsTemplateFragment
    }
  }
  ${TERMS_TEMPLATE_FRAGMENT}
`;
