/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ncvy_157 {
  display: flex;
}

._justify-content-space-between_1ncvy_161 {
  justify-content: space-between;
}

._tabular-nums_1ncvy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ncvy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._filter_1ncvy_209 {
  height: 32px;
  background: #3996e0;
  color: white;
  margin: 2px;
  padding: 2px 4px 2px 4px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._filter_1ncvy_209:hover {
  background-color: #18619b;
}
._filter_1ncvy_209 ._filterText_1ncvy_223 {
  margin-right: 4px;
}
._filter_1ncvy_209 ._filterCircle_1ncvy_226 {
  background: rgba(255, 255, 255, 0.2);
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-right: 8px;
}
._filter_1ncvy_209 ._filterCircle_1ncvy_226._right_1ncvy_236 {
  margin-right: 0;
  margin-left: 4px;
}

._Popover_1ncvy_241 {
  z-index: 9401 !important;
}

._MenuList_1ncvy_245 {
  list-style-type: none;
  padding: 0;
}

._MenuItem_1ncvy_250 {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  font-size: 1rem;
}
._MenuItem_1ncvy_250:hover {
  background-color: rgba(0, 0, 0, 0.08);
}