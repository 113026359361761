import { IState } from '../../../../types/state';
import { IToggleInstagramInsights } from './index';

export const toggleInstagramInsights = (state: IState, action: IToggleInstagramInsights): IState => {
  if (state.collaborationDetails.bam.toggleInstagramInsights === action.toggleInstagramInsights) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      bam: {
        ...state.collaborationDetails.bam,
        toggleInstagramInsights: action.toggleInstagramInsights,
      },
    },
  };
};
