import { logger } from '@common';

import { IMixpanelFields } from './createPaymentSentEvent';
import { IAssignmentAPIPayload } from '../Shared/components/ArtifactAssignmentForm';
import { TPaymentSentHandler } from './hooks/usePaymentSentEvent';
import { IPayment } from './models';

export interface ISendPaymentRequest {
  amount: number;
  member_id: number;
  client_id: string;
  card_id?: string;
  paypal_address?: string;
  member_name?: string;
  member_email?: string;
  amount_due?: number;
  metadata?: Object;
  payment_group_id?: number;
  aspirex_application?: string;
  work_item_id?: string;
  program_ids?: number[]
  program_names?: string[]
  assigned?: boolean;
  project_id?: number;
  payment_initiated_by?: string;
}

export interface IUpdatePaymentAssignemntRequest extends IAssignmentAPIPayload {
  client_id: string;
}

const SAVE_PAYMENT_UNEXPECTED_ERROR_MESSAGE = 'We are sincerely sorry, something seems to have gone wrong. '
  + 'It is possible that your payment went through successfully, but there may be some other issues. '
  + 'Please email help@aspireiq.com to get more assistance.';

export const savePaymentData = async (
  url: string,
  params: ISendPaymentRequest,
  addEvent: TPaymentSentHandler,
  mixpanelFields?: IMixpanelFields,
): Promise<IPayment> => {
  let json = null;
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    json = await resp.json();
  } catch (err) {
    logger.error('Save payment unexpected API error', err);
    throw new Error(SAVE_PAYMENT_UNEXPECTED_ERROR_MESSAGE);
  }

  if (!json || !json.status?.code) {
    throw new Error(SAVE_PAYMENT_UNEXPECTED_ERROR_MESSAGE);
  }

  if (json.status.code === 200 && json.data) {
    addEvent(params, mixpanelFields);
    return json.data;
  } else {
    throw new Error(json.status.error_msg || SAVE_PAYMENT_UNEXPECTED_ERROR_MESSAGE);
  }
};

export const updatePaymentData = async (url: string, params: IUpdatePaymentAssignemntRequest) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();

    if (json.status.code === 200) {
      return json.data;
    } else {
      throw json.status.error_msg;
    }
  } catch (err) {
    console.log(err);

    throw err;
  }
};
