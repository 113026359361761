import { isEmpty, times } from 'lodash';
import { ContentType } from '@frontend/app/types/globalTypes';
import { ActionTypes } from '@frontend/app/containers/Projects/TermsPage/components/types/actionTypes';
import {
  IState,
  TAction,
} from '@frontend/app/containers/Projects/TermsPage/components/types/state';
import { updateGuidelinesInstructions } from '@frontend/applications/TermsApp/components/shared/utils/utils';

export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case ActionTypes.ToggleNoCompensation:
      return {
        ...state,
        hasUnsavedChanges: true,
        compensation: {
          ...state.compensation,
          noCompensation: {
            ...state.compensation.noCompensation,
            toggleNoCompensation: action.toggleNoCompensation,
          },
        },
      };
    case ActionTypes.UpdateShowHideNewPrice:
      if (action.showHideNewPrice === state.compensation.payment.toggleNewPrice) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        compensation: {
          ...state.compensation,
          payment: {
            ...state.compensation.payment,
            toggleNewPrice: action.showHideNewPrice,
          },
        },
      };
    case ActionTypes.UpdateNewPrice:
      if (action.newPrice === state.compensation.payment.newPrice) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        compensation: {
          ...state.compensation,
          payment: {
            ...state.compensation.payment,
            newPrice: action.newPrice,
          },
        },
      };
    case ActionTypes.UpdateShowHidePaymentOption:
      if (action.showHideDescription === state.compensation[action.paymentType].toggleDescription) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        compensation: {
          ...state.compensation,
          [action.paymentType]: {
            ...state.compensation[action.paymentType],
            toggleDescription: action.showHideDescription,
          },
        },
      };
    case ActionTypes.UpdatePaymentOptionDescription:
      if (action.description === state.compensation[action.paymentType].description) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        compensation: {
          ...state.compensation,
          [action.paymentType]: {
            ...state.compensation[action.paymentType],
            description: action.description,
          },
        },
      };
    case ActionTypes.UpdateContentRight:
      if (action.contentRight === state.contentRight) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        contentRight: action.contentRight,
      };
    case ActionTypes.UpdateEmailSubject:
      if (action.emailSubject === state.emailSubject) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        emailSubject: action.emailSubject,
      };
    case ActionTypes.UpdateEmailContent: {
      if (action.emailContent.html === state.emailContent?.html) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: !state.hasUnsavedChanges ? state.emailContent !== null : state.hasUnsavedChanges,
        emailContent: action.emailContent,
      };
    }
    case ActionTypes.UpdateAdditionalCc:
      return {
        ...state,
        additionalCc: action.additionalCc.map((cc) => cc.email),
      };
    case ActionTypes.SetSettingsTerms:
      return {
        ...state,
        introMessage: action.introMessage,
        contentRequirements: !isEmpty(action.contentRequirements)
          ? action.contentRequirements
          : state.contentRequirements,
        contentGuideline: action.contentGuideline,
        compensation: {
          payment: {
            toggleNewPrice: action.compensationPaymentAvailable,
            newPrice: action.compensationPaymentValue,
          },
          freeProduct: {
            toggleDescription: action.compensationFreeProductAvailable,
            description: action.compensationFreeProductValue,
          },
          commission: {
            toggleDescription: action.compensationCommissionAvailable,
            description: action.compensationCommissionValue,
          },
          noCompensation: {
            toggleNoCompensation: action.noCompensationAvailable,
          },
        },
        contentRight: {
          type: action.contentUsageRightType,
          text: action.contentUsageRightValue,
        },
        emailSubject: action.emailSubject,
        defaultActiveKey: action.defaultActiveKey,
      };
    case ActionTypes.UpdateHasUnsavedChanges:
      if (action.hasUnsavedChanges === state.hasUnsavedChanges) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: action.hasUnsavedChanges,
      };
    case ActionTypes.ToggleSaving:
      return {
        ...state,
        saving: !state.saving,
      };
    case ActionTypes.UpdateDefaultActiveKey:
      return {
        ...state,
        defaultActiveKey: [...action.defaultActiveKey],
      };
    case ActionTypes.ToggleDueDates:
      return {
        ...state,
        hasUnsavedChanges: true,
        contentRequirements: {
          ...state.contentRequirements,
          areDueDatesSelected: !action.areNoDueDatesChecked,
          deliverablesDueDates: action.areNoDueDatesChecked
            ? []
            : times(state.contentRequirements.deliverablesWithNoDueDates, (index) => 15 + index * 15),
          deliverablesWithNoDueDates: action.areNoDueDatesChecked
            ? state.contentRequirements.deliverablesDueDates.length
            : 0,
        },
      };
    case ActionTypes.AddDeliverableDueDate:
      return {
        ...state,
        hasUnsavedChanges: true,
        contentRequirements: {
          ...state.contentRequirements,
          deliverablesDueDates: [
            ...state.contentRequirements.deliverablesDueDates,
            action.days,
          ],
        },
      };
      case ActionTypes.RemoveDeliverableDueDate:
        return {
          ...state,
          hasUnsavedChanges: true,
          contentRequirements: {
            ...state.contentRequirements,
            deliverablesDueDates: [
              ...state.contentRequirements.deliverablesDueDates.slice(0, action.index),
              ...state.contentRequirements.deliverablesDueDates.slice(action.index + 1),
            ],
          },
        };
      case ActionTypes.UpdateDeliverableDueDate:
        return {
          ...state,
          hasUnsavedChanges: true,
          contentRequirements: {
            ...state.contentRequirements,
            deliverablesDueDates: [
              ...state.contentRequirements.deliverablesDueDates.slice(0, action.index),
              action.value,
              ...state.contentRequirements.deliverablesDueDates.slice(action.index + 1),
            ],
          },
        };
      case ActionTypes.AddContentType:
        return {
          ...state,
          hasUnsavedChanges: true,
          contentRequirements: {
            ...state.contentRequirements,
            contentTypes: [
              ...state.contentRequirements.contentTypes,
              action.contentType,
            ],
          },
        };
      case ActionTypes.RemoveContentType:
        return {
          ...state,
          hasUnsavedChanges: true,
          contentRequirements: {
            ...state.contentRequirements,
            contentTypes: state.contentRequirements.contentTypes.filter(
              (contentType: ContentType) => contentType !== action.contentType,
            ),
          },
        };
      case ActionTypes.SelectContentGuideline:
        if (action.contentGuideline.id === state.contentGuideline?.id) {
          return state;
        }
        return {
          ...state,
          hasUnsavedChanges: true,
          contentGuideline: { ...action.contentGuideline },
        };
      case ActionTypes.UpdateContentGuidelineLabel:
        if (action.label === state.contentGuideline?.label) {
          return state;
        }
        return {
          ...state,
          hasUnsavedChanges: true,
          contentGuideline: {
            ...state.contentGuideline,
            label: action.label,
          },
        };
      case ActionTypes.UpdateContentGuidelineInstruction: {
        const { guidelineIndex, instructionIndex, instruction } = action.contentGuidelineInstruction;
        const guideline = state.contentGuideline?.guidelines[guidelineIndex];
        const currentInstruction = guideline.instructions[instructionIndex] || '';
        if (currentInstruction === instruction) {
          return state;
        }
        const updatedGuidelines = updateGuidelinesInstructions(
          state.contentGuideline?.guidelines,
          guidelineIndex,
          instructionIndex,
          instruction,
        );
        return {
          ...state,
          hasUnsavedChanges: true,
          contentGuideline: {
            ...state.contentGuideline,
            guidelines: updatedGuidelines,
          },
        };
      }
    case ActionTypes.UpdateContentGuidelineTitle: {
      const { guidelineIndex, title } = action.contentGuidelineTitle;
      const guideline = state.contentGuideline?.guidelines[guidelineIndex];
      if (guideline.title === title) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        contentGuideline: {
          ...state.contentGuideline,
          guidelines: [
            ...state.contentGuideline?.guidelines.slice(0, guidelineIndex),
            {
              ...guideline,
              title,
            },
            ...state.contentGuideline?.guidelines.slice(guidelineIndex + 1),
          ],
        },
      };
    }
    case ActionTypes.SortContentGuideline: {
      const guidelineType = Object.keys(action.sortableGuideline)[0];
      const updatedGuidelines = state.contentGuideline?.guidelines.map(
        (guideline) => {
          if (guideline.type === guidelineType) {
            return {
              ...guideline,
              instructions: action.sortableGuideline[guidelineType],
            };
          }
          return guideline;
        },
      );
      return {
        ...state,
        hasUnsavedChanges: true,
        contentGuideline: {
          ...state.contentGuideline,
          guidelines: updatedGuidelines,
        },
      };
    }
    case ActionTypes.AddContentGuidelineAttachment: {
      return {
        ...state,
        hasUnsavedChanges: true,
        contentGuideline: {
          ...state.contentGuideline,
          attachments: [
            ...state.contentGuideline.attachments,
            action.attachment,
          ],
        },
      };
    }
    case ActionTypes.DeleteContentGuidelineAttachment: {
      return {
        ...state,
        hasUnsavedChanges: true,
        contentGuideline: {
          ...state.contentGuideline,
          attachments: [...action.attachments],
        },
      };
    }
    case ActionTypes.UpdateIntro: {
      if (action.introMessage === state.introMessage) {
        return state;
      }
      return {
        ...state,
        hasUnsavedChanges: true,
        introMessage: action.introMessage,
      };
    }
    case ActionTypes.AddDeliverableWithNoDueDate: {
      return {
        ...state,
        hasUnsavedChanges: true,
        contentRequirements: {
          ...state.contentRequirements,
          deliverablesWithNoDueDates: state.contentRequirements.deliverablesWithNoDueDates + 1,
        },
      };
    }
    case ActionTypes.RemoveDeliverableWithNoDueDate: {
      return {
        ...state,
        hasUnsavedChanges: true,
        contentRequirements: {
          ...state.contentRequirements,
          deliverablesWithNoDueDates: state.contentRequirements.deliverablesWithNoDueDates - 1,
        },
      };
    }
    case ActionTypes.UpdateEmailAttachments: {
      return {
        ...state,
        hasUnsavedChanges: true,
        emailAttachments: action.emailAttachments,
      };
    }
    case ActionTypes.UpdateEmailAuthor: {
      return {
        ...state,
        hasUnsavedChanges: true,
        resourceId: action.resourceId,
      };
    }
    default:
      return state;
  }
};
