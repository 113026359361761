import * as React from 'react';
import cx from 'classnames';

import { KeyboardArrowLeftIcon } from '@components';

import { useDragging, useMobileStatus } from '@frontend/utils';

const {
 useState, useEffect, useRef, useCallback,
} = React;
import styles from './ScrollableMask.scss';

interface IProps {
  scrollLeft: number;
  containerWidth: number;
  maxScrollLeft: number;

  onScrollLeftChange(scrollLeft: number): void;
  shouldCaptureWheel: boolean;

  classNames?: string[];
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export const ScrollableMask: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    scrollLeft,
    containerWidth,
    maxScrollLeft,
    shouldCaptureWheel,
    onScrollLeftChange,
  } = props;
  const mobileType = useMobileStatus();
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const [isDragging, mousePosition] = useDragging(thumbRef);
  const previousClientX = useRef<number>(null);
  const latestScrollLeft = useRef(0);
  latestScrollLeft.current = scrollLeft;

  // initialize scrollbar width and thumb width
  const [thumbWidth, setThumbWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  useEffect(() => {
    const scrollbar = scrollbarRef.current;
    const { clientWidth } = scrollbar;

    setClientWidth(clientWidth);

    const newThumbWidth = clientWidth * (containerWidth / (containerWidth + maxScrollLeft));
    if (newThumbWidth !== thumbWidth) {
      setThumbWidth(newThumbWidth);
    }
  }, [containerWidth, maxScrollLeft, thumbWidth]);

  const calculateNewScrollLeft = useCallback(
    (clientX: number) => {
      const scrollbar = scrollbarRef.current;
      const { left, width } = scrollbar.getBoundingClientRect();

      // need to consider the thumb width
      const clientXInRange = Math.max(
        left + thumbWidth / 2,
        Math.min(left + width - thumbWidth / 2, clientX),
      );
      const newThumbLeft = clientXInRange - thumbWidth / 2;
      const ratio = (newThumbLeft - left) / (width - thumbWidth);
      const newScrollLeft = ratio * maxScrollLeft;

      return newScrollLeft;
    },
    [scrollbarRef, thumbWidth, maxScrollLeft],
  );

  useEffect(() => {
    if (isDragging) {
      const { clientX } = mousePosition;

      // mousedown
      if (previousClientX.current === null) {
        previousClientX.current = clientX;
        return;
      }

      if (previousClientX.current !== clientX) {
        const newScrollLeft = calculateNewScrollLeft(clientX);

        onScrollLeftChange(newScrollLeft);
      }
    } else {
      previousClientX.current = null;
    }
  }, [mousePosition, isDragging, calculateNewScrollLeft, onScrollLeftChange]);

  const handleMouseWheel = useCallback((event: WheelEvent) => {
    const { deltaX, deltaY } = event;

    // don't block vertical scroll
    if (deltaY === 0) {
      event.preventDefault();
    }

    const newScrollLeft = Math.max(0, Math.min(maxScrollLeft, latestScrollLeft.current + deltaX));

    if (scrollLeft !== newScrollLeft) {
      onScrollLeftChange(newScrollLeft);
    }
  }, [maxScrollLeft, onScrollLeftChange, scrollLeft]);

  useEffect(() => {
    if (shouldCaptureWheel) {
      window.addEventListener('wheel', handleMouseWheel, { passive: false });

      return () => {
        window.removeEventListener('wheel', handleMouseWheel);
      };
    }
  }, [shouldCaptureWheel, handleMouseWheel]);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { clientX } = event;

    event.preventDefault();
    event.stopPropagation();

    const newScrollLeft = calculateNewScrollLeft(clientX);

    onScrollLeftChange(newScrollLeft);
  };

  return (
    <div
      className={cx(styles.ScrollableMask, props.classNames, {
        [styles.active]: maxScrollLeft > 0,
        [styles.dragging]: isDragging,
      })}
    >
      {mobileType === 'phone' && (
        <>
          <div
            className={cx(styles.button, {
              [styles.active]: scrollLeft > 0,
            })}
            onMouseDown={handleScrollLeft}
          >
            <KeyboardArrowLeftIcon />
          </div>
          <div
            className={cx(styles.button, styles.right, {
              [styles.active]: scrollLeft < maxScrollLeft,
            })}
            onMouseDown={handleScrollRight}
          >
            <KeyboardArrowLeftIcon className={styles.arrowRight} />
          </div>
        </>
      )}
      <div
        ref={scrollbarRef}
        className={cx(styles.scrollbar, {
          [styles.dragging]: isDragging,
        })}
        onClick={handleMouseDown}
      >
        <div
          ref={thumbRef}
          className={styles.thumb}
          style={{
            width: `${thumbWidth}px`,
            transform: `translateX(${clientWidth
              * (scrollLeft / (containerWidth + maxScrollLeft))}px)`,
          }}
        />
      </div>
    </div>
  );

  function handleScrollLeft() {
    const newScrollLeft = Math.max(0, scrollLeft - containerWidth * 0.75);

    if (newScrollLeft !== scrollLeft) {
      onScrollLeftChange(newScrollLeft);
    }
  }

  function handleScrollRight() {
    const newScrollLeft = Math.min(maxScrollLeft, scrollLeft + containerWidth * 0.75);

    if (newScrollLeft !== scrollLeft) {
      onScrollLeftChange(newScrollLeft);
    }
  }
});

ScrollableMask.defaultProps = {
  classNames: [],
};
ScrollableMask.displayName = 'ScrollableMask';
