import * as React from 'react';
import cx from 'classnames';
import {
  map,
  debounce,
  filter,
  groupBy,
  isEmpty,
  isFunction,
  isNil,
  uniq,
} from 'lodash';
import {
 Checkbox, Divider, Input, List, Select,
} from '@revfluence/fresh';
import { SearchOutlined } from '@ant-design/icons';
import { Notice } from '@components';

import styles from './SelectList.scss';

const {
 useEffect, useState, useCallback, useMemo,
} = React;

export interface ISelectListProps<T> {
  className?: string;
  /**
   * Class name of the list container
   */
  contentClassName?: string;
  defaultSelectedIds?: (string | number)[];
  disabled?: boolean;
  emptyMessage?: React.ReactNode;
  groupSelectPlaceholder?: string;
  isLoading?: boolean;
  mapGroupIdToLabel?(groupId: string | number): JSX.Element | string;
  mapOptionToGroupId?(option: T): string | number;
  mapOptionToId(option: T): string | number;
  mapOptionClasses?(option: T): string | string[];
  mapOptionToLabel(option: T): React.ReactNode;
  /**
   * Enables multi-select
   */
  multi?: boolean;
  onChange?(selectedIds: (string | number)[]);
  onSearchRequest?(searchText: string): T[] | Promise<T[]>;
  options: T[];
  /**
   * Placeholder for the search input
   */
  searchPlaceholder?: string;
  /**
   * Controlled
   */
  selectedIds?: (string | number)[];
  /**
   * Whether to show the group selector; need to provide `mapOptionToGroupId`
   */
  showGroupSelect?: boolean;
  /**
   * Whether to show the search bar; need to provide `onSearchRequest`
   */
  showSearch?: boolean;

  /**
   * clear search text and selection if indicated from parent component
   */
  clearSelection?: boolean;

  /**
   * notify parent component when selection cleared
   */
  onClearSelection?: () => void;
}

/**
 * Select List Component
 */
// https://github.com/microsoft/TypeScript/issues/15713
/* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/comma-dangle */
export const SelectList = <T,>(props: React.PropsWithChildren<ISelectListProps<T>>) => {
  const {
    className,
    contentClassName,
    defaultSelectedIds,
    disabled,
    emptyMessage,
    groupSelectPlaceholder,
    isLoading,
    mapGroupIdToLabel,
    mapOptionToGroupId,
    mapOptionToId,
    mapOptionClasses,
    mapOptionToLabel,
    multi,
    onChange,
    onSearchRequest,
    options,
    searchPlaceholder,
    selectedIds,
    showGroupSelect,
    showSearch,
    clearSelection,
    onClearSelection,
  } = props;
  const isControlled = typeof selectedIds !== 'undefined';

  const valueSet = useMemo(
    () => {
      if (isControlled) {
        return new Set(selectedIds);
      }
      return new Set(Array.isArray(defaultSelectedIds) ? defaultSelectedIds : []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds],
  );

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [selectedValues, setSelectedValues] = useState<Set<any>>(valueSet);
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [searchOptions, setSearchOptions] = useState<T[]>(null);

  useEffect(
    () => setSelectedValues(valueSet),
    [valueSet],
  );

  useEffect(() => {
    if (clearSelection) {
      setSearchOptions(null);
      setSearchText('');
      if (isFunction(onClearSelection)) {
        // notify parent component of selection & search clearing
        onClearSelection();
      }
    }
  }, [
    clearSelection,
    setSearchOptions,
    setSearchText,
    onClearSelection,
  ]);

  // Definitions
  const groups = useMemo(
    () => (
      options && mapOptionToGroupId
        ? groupBy(options, mapOptionToGroupId)
        : undefined
    ),
    [options, mapOptionToGroupId],
  );
  const groupIds = useMemo(
    () => (
      options && mapOptionToGroupId
        ? uniq(map(options, mapOptionToGroupId))
        : undefined
    ),
    [options, mapOptionToGroupId],
  );

  useEffect(() => {
    const valueSet = new Set(Array.isArray(props.defaultSelectedIds) ? props.defaultSelectedIds : []);
    setSelectedValues(valueSet);
  }, [props.defaultSelectedIds]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchRequest = useCallback(
    debounce(async (text: string) => {
      if (onSearchRequest) {
        const result = await onSearchRequest(text);
        setSearchOptions(result);
      }
    }, 300),
    [options],
  );

  const handleChangeSearchText = (text: string) => {
    handleSearchRequest(text);
    setSearchText(text);
  };

  const handleSelectOption = (option: T) => {
    const newValues = new Set(selectedValues);
    const id = mapOptionToId(option);
    const hasValue = newValues.has(id);

    if (!multi) {
      newValues.clear();
    } else if (hasValue) {
      newValues.delete(id);
    }
    if (!hasValue) {
      newValues.add(id);
    }
    if (!isControlled) {
      setSelectedValues(newValues);
    }
    if (onChange) {
      onChange(Array.from(newValues));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ops = searchOptions || options || [];

  const showGroupLabelOnSearch = useMemo(
    () => searchText && isNil(selectedGroup) && isFunction(mapGroupIdToLabel) && isFunction(mapOptionToGroupId),
    [searchText, selectedGroup, mapGroupIdToLabel, mapOptionToGroupId],
  );

  const filteredOptions = useMemo(
    () => (isNil(selectedGroup)
      ? ops
      : filter(ops, (o) => mapOptionToGroupId(o) === selectedGroup)),
    [mapOptionToGroupId, ops, selectedGroup],
  );

  const renderList = () => (
    <List
      className={styles.list}
      bordered={false}
      dataSource={filteredOptions || []}
      itemLayout="vertical"
      renderItem={(option) => {
        const id = mapOptionToId(option);

        const additionalClasses = isFunction(mapOptionClasses)
          ? mapOptionClasses(option)
          : null;

        return (
          <List.Item
            className={cx(
              styles.item,
              additionalClasses,
            )}
            key={id}
          >
            <Checkbox
              className={styles.checkbox}
              checked={selectedValues.has(id)}
              onChange={() => handleSelectOption(option)}
            >
              {mapOptionToLabel(option)}
            </Checkbox>
            {showGroupLabelOnSearch && (
              <div className={styles.fieldLabel}>
                &nbsp;in
                {' '}
                {mapGroupIdToLabel(mapOptionToGroupId(option))}
              </div>
            )}
          </List.Item>
        );
      }}
      size="small"
    />
  );

  const empty = isEmpty(groups || ops);

  return (
    <div
      className={cx(styles.SelectList, className, {
        [styles.disabled]: disabled,
      })}
    >
      {showGroupSelect && isFunction(mapOptionToGroupId) && (
      <>
        <Select
          defaultValue={null}
          onChange={(value) => {
            setSelectedGroup(value);
          }}
          className={styles.groupSelect}
        >
          <Select.Option value={null}>
            {groupSelectPlaceholder || 'Show All'}
          </Select.Option>
          {map(groupIds, (groupId, i) => (
            <Select.Option value={groupId} key={`group-${i}`}>
              {mapGroupIdToLabel(groupId)}
            </Select.Option>
          ))}
        </Select>
        <Divider className={styles.divider} />
      </>
)}
      {showSearch && (
        <Input
          placeholder={searchPlaceholder}
          value={searchText}
          onChange={(e) => handleChangeSearchText(e.target.value)}
          prefix={<SearchOutlined />}
          allowClear
          className={styles.searchInput}
        />
      )}
      <div className={cx(styles.content, contentClassName)}>
        {!isLoading && empty && emptyMessage && (
          <Notice type="disabled">
            {emptyMessage}
          </Notice>
        )}
        {renderList()}
      </div>
    </div>
  );
};

SelectList.defaultProps = {
  defaultSelectedIds: [],
  disabled: false,
  isLoading: false,
  multi: true,
  showSearch: true,
};

SelectList.displayName = 'SelectList';
