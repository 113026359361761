import { reduce } from 'lodash';

import { CONTENT_TYPE } from '../../../context/model';
import { ITabField } from '../../TabField/model';

export const CAMPAIGN_DETAILS_FIELDS: ITabField[] = [
  {
    key: 'name',
    name: 'name',
    label: 'Marketplace Listing Name',
    helpText: 'Enter the name you would like to show in the Aspire Marketplace',
    isRequired: true,
    type: 'text',
    maxCharacters: 30,
  },
  {
    key: 'summary',
    name: 'summary',
    label: 'Project Blurb',
    helpText: 'Enter the blurb you would like to show in the Aspire Marketplace',
    type: 'text',
    isRequired: true,
    maxCharacters: 40,
  },
  {
    key: 'product_types',
    name: 'product_types',
    label: 'What content do you want creators to make?',
    type: 'radio-group',
    isRequired: true,
    options: reduce(CONTENT_TYPE, (result, data, key) => {
      result.push({
        value: key,
        label: data.title,
      });
      return result;
    }, []),
  },
];
