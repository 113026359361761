import * as React from 'react';
import cx from 'classnames';
import { unionBy, reject, size } from 'lodash';
import {
  ContentUploader as WidgetContentUploader,
  IContentUploaderState,
} from '@components';

import { useUploadContent } from '@frontend/app/hooks';
import { IAttachment } from '../MessageComposer';

const { useState, useEffect } = React;

interface IProps {
  acceptImage?: boolean;
  acceptVideo?: boolean;
  acceptApplication?: boolean;
  serviceName: string;
  className?: string;

  onContentsChanged?(contents: IAttachment[]): void;
}

export const ContentUploader: React.FunctionComponent<IProps> = (props) => {
  const [contents, setContents] = useState<IAttachment[]>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const {
    content,
    upload: uploadContent,
    error,
  } = useUploadContent({
    serviceName: props.serviceName,
    isTemp: true,
  });

  useEffect(() => {
    if (content) {
      setContents((ctns) => unionBy([content], ctns, 'id'));
    }
  }, [content]);

  useEffect(() => {
    if (props.onContentsChanged && contents) {
      props.onContentsChanged(contents);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  useEffect(() => {
    if (error && content) {
      setContents((ctns) => reject(ctns, { id: content.id }));

      // Don't show any error if upload is aborted
      if (!error.isUploadAborted) {
        setErrorMessage(error.message);
      }
    }
  }, [error, content]);

  const handleUploadContent: typeof uploadContent = (...args) => {
    setErrorMessage(null);
    return uploadContent(...args);
  };

  const handleStoreChange = (state: IContentUploaderState) => {
    const changed = size(contents) !== size(state.contents);
    if (changed) {
      setContents(state.contents);
    }
  };

  return (
    <WidgetContentUploader
      acceptImage={props.acceptImage}
      acceptVideo={props.acceptVideo}
      acceptApplication={props.acceptApplication}
      classNames={cx(props.className).split(' ')}
      contents={contents}
      uploadErrorMessage={errorMessage}
      uploadContent={handleUploadContent}
      onStoreChange={handleStoreChange}
    />
  );
};
