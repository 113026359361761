import * as React from 'react';
import cx from 'classnames';
import { KeyboardArrowDownIcon, CheckCircleIcon } from '@components';

import styles from './AccordionHeader.scss';

// TODO extract this component from here and Workflows

interface IProps {
  title: string;
  onClick(): void;
  className?: string;
  active?: boolean;
}

const AccordionHeader: React.FC<IProps> = React.memo((props) => {
  const {
 title, active, className, onClick,
} = props;

  return (
    <div className={cx(className, styles.accordionHeader)} onClick={onClick}>
      <KeyboardArrowDownIcon className={cx({ [styles.upArrow]: !active })} size={20} />
      <div className={cx(styles.title, { [styles.active]: active })}>{title}</div>
      {!active
        && <CheckCircleIcon className={styles.completedIcon} size={20} />}
    </div>
  );
});

AccordionHeader.displayName = 'MemberDataUpdateAppAccordionHeader';

export default AccordionHeader;
