import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { DISCONNECT_TIKTOK_SETTINGS_AD_ACCOUNT } from '@frontend/app/queries';
import {
  DisconnectTikTokSettingsAdAccountMutation,
  DisconnectTikTokSettingsAdAccountMutationVariables,
} from '@frontend/app/queries/types/DisconnectTikTokSettingsAdAccountMutation';

type IOptions = MutationHookOptions<
  DisconnectTikTokSettingsAdAccountMutation,
  DisconnectTikTokSettingsAdAccountMutationVariables
>;

export const useTikTokSettingsDisconnectAdAccountMutation = (options: IOptions = {}) => (
  useMutation<
    DisconnectTikTokSettingsAdAccountMutation,
    DisconnectTikTokSettingsAdAccountMutationVariables
  >(DISCONNECT_TIKTOK_SETTINGS_AD_ACCOUNT, options)
);
