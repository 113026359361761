import * as React from 'react';
import styles from './BudgetStatisticsBar.module.scss';
import { IBudgetStat } from '../../types/IBudgetStat';
import BudgetStatisticsBarItem from '../BudgetStatisticsBarItem';

interface IProps {
    stats: IBudgetStat[],
}

const BudgetStatisticsBar: React.FC<IProps> = (props) => {
    const { stats } = props;
    return (
      <div className={styles.budgetStatisticsBarContainer}>
        {
                stats?.map((stat, index) => <BudgetStatisticsBarItem stat={stat} key={index} />)
            }
      </div>
    );
};
export default BudgetStatisticsBar;
BudgetStatisticsBar.displayName = 'BudgetStatisticsBar';
