import * as React from 'react';
import { AUIInfoCircleFilled as InfoCircleFilled } from '@affiliates/AspireUI/Icons/InfoCircleFilled/InfoCircleFilled';
import { OFFER_TRACKING_TYPE } from '@affiliates/types/globalTypes';
import { Typography } from '@revfluence/fresh';
import styles from './OfferFormToolTip.scss';

const { Link } = Typography;

export enum OfferFormToolTips {
  conversionTrackingInfo = 'conversionTrackingInfo',
  urlInfo = 'urlInfo',
  ppcInfo = 'ppcInfo',
  cpccpsInfo = 'cpccpsInfo',
  cps = 'cps',
  advanceUrlSettingType = 'advanceUrlSettingType',
  utmSource = 'utmSource',
  utmMedium = 'utmMedium',
  utmCampaign = 'utmCampaign',
  utmTerm = 'utmTerm',
  utmContent = 'utmContent',
}
const { Text } = Typography;

interface IProps {
  trackingType: OFFER_TRACKING_TYPE | null;
  type: OfferFormToolTips;
}
export const OfferFormTooltip: React.FC<IProps> = (props) => {
  if (!props.type) {
    return null;
  }
  let tooltip = null;
  switch (props.type) {
    case OfferFormToolTips.conversionTrackingInfo:
      tooltip = (
        <>
          <p className={styles.lead}>
            Conversion Tracking
          </p>
          <p>
            Consider using a "Javascript Postback" for tracking links and sales. It's a popular method that provides client-side functionality for session storage and creator conversions. By using the Javascript postback, you get the accuracy of server-side postback without the need for complex technical setup.
            {' '}
            <Link href="https://intercom.help/aspireiq_elevate/en/collections/3990852-javascript-postback-tracking" target="_blank">Learn more! </Link>
          </p>
          <p>
            If you have any questions, feel free to email  help@aspireiq.com.

          </p>
        </>
      );
      break;
    case OfferFormToolTips.urlInfo:
      tooltip = (
        <>
          <p className={styles.lead}>
            Offer URL Destination
          </p>
          <p>
            This is the landing page where the tracking link will redirect to.
          </p>
          {props.trackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID && (
            <p>
              The URL in &ldquo;Offer URL Tracking&rdquo; needs to include the variable
              {' '}
              <code>
                {'{'}
                transaction_id
                {'}'}
              </code>
              .
            </p>
          )}
          {props.trackingType === OFFER_TRACKING_TYPE.JAVASCRIPT_POSTBACK && (
            <p>
              The URL in &ldquo;Offer URL Tracking&rdquo; needs to include the variable
              {' '}
              <code>
                {'{'}
                transaction_id
                {'}'}
              </code>
              .
            </p>
          )}
          {props.trackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID && (
            <p>
              The URL in &ldquo;Offer URL Tracking&rdquo; needs to include the variable
              {' '}
              <code>
                {'{'}
                affiliate_id
                {'}'}
              </code>
              .
            </p>
          )}
        </>
      );
      break;

    case OfferFormToolTips.ppcInfo:
      tooltip = (
        <>
          <p className={styles.lead}>
            Payout Per Conversion.
          </p>
          <p>
            This is the affiliate payout amount for members for each click, conversion, or sale they generate.
          </p>
          <p>
            Aspire does not automatically pay members. You have complete control over when and how much you pay members.
          </p>
        </>
      );
      break;

    case OfferFormToolTips.cpccpsInfo:
      tooltip = (
        <>
          <p className={styles.lead}>
            Cost per Conversion + Cost per Sales
          </p>
          <p>
            The amount paid per conversion and the percentage of sale per conversion paid to members.
          </p>
        </>
      );
      break;

    case OfferFormToolTips.cps:
      tooltip = (
        <>
          <p className={styles.lead}>
            Commision Per Sale
          </p>
          <p>
            The percentage of sales(ie: sales commision) paid to members.
          </p>
        </>
      );
      break;

      case OfferFormToolTips.advanceUrlSettingType:
      tooltip = (
        <>
          <p className={styles.lead}>
            Advance URL Settings
          </p>
          <p>
            Append and manage the UTM parameters in to the Offer URL.
          </p>
        </>
      );
      break;
      case OfferFormToolTips.utmSource:
      tooltip = (
        <>
          <p className={styles.lead}>
            UTM Source
          </p>
          <Text>
            utm_source  – identifies the traffic source, such as Google, Facebook, or a newsletter.
          </Text>
        </>
      );
      break;
      case OfferFormToolTips.utmMedium:
      tooltip = (
        <>
          <p className={styles.lead}>
            UTM Medium
          </p>
          <Text>
            utm_medium  – identifies the medium of the traffic, such as email, social media, or CPC (cost-per-click) advertising.
          </Text>
        </>
      );
      break;
      case OfferFormToolTips.utmCampaign:
      tooltip = (
        <>
          <p className={styles.lead}>
            UTM Campaign
          </p>
          <Text>
            utm_campaign  – identifies the name of the campaign, such as "Summer Sale" or "Holiday Promotions".
          </Text>
        </>
      );
      break;
      case OfferFormToolTips.utmTerm:
      tooltip = (
        <>
          <p className={styles.lead}>
            UTM Term
          </p>
          <Text>
            utm_term – identifies the keyword used in paid search campaigns, influencer campaigns, gifting, and ambassador programs.
          </Text>
        </>
      );
      break;
      case OfferFormToolTips.utmContent:
      tooltip = (
        <>
          <p className={styles.lead}>
            UTM Content
          </p>
          <Text>
            utm_content – identifies the specific content used in an ad, such as a banner or text link
          </Text>
        </>
      );
      break;
  }

  return (
    <div className={styles.OfferFormToolTip}>
      <InfoCircleFilled />
      {tooltip}
    </div>
  );
};
