import gql from 'graphql-tag';

import { AUTOMATION_NODE_FRAGMENT } from './AutomationNode';

export const AUTOMATION_TRIGGER_FRAGMENT = gql`
  fragment AutomationTriggerFragment on AutomationTrigger {
    id
    type
    root {
      ...AutomationNodeFragment
    }
    variables {
      id
      type
      name
      value
    }
    metadata
  }
  ${AUTOMATION_NODE_FRAGMENT}
`;
