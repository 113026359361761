import { useCallback } from 'react';
import { useAuth } from '@frontend/context/authContext';

interface IParams {
  storeURL: string;
}

function stripHTTP(url: string) {
  return url?.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
}

export const useAddWooAccount = () => {
  const { token } = useAuth();

  return useCallback(async (params: IParams) => {
    const storeURL = stripHTTP(params.storeURL);
    const encodedParams = encodeURIComponent(JSON.stringify({ ...params, storeURL }));
    const redirectURL = encodeURIComponent(window.location.origin + window.location.pathname);
    try {
      const res = await fetch(
        `/api/resource_authentication/auth_url?redirectURL=${redirectURL}&resourceType=woocommerce&params=${encodedParams}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
);

      const data = await res.json();

      if (data && data.url) {
        window.location.assign(data.url);
      }
    } catch (err) {
      console.log('err', err);
    }
  }, [token]);
};
