import React, { useEffect, useState } from 'react';
import {
  Card,
  Progress,
  Typography,
  Select,
  Spinner,
} from '@revfluence/fresh';
import { useGetBudgetDistributionsForBudgetAccount } from '@frontend/app/hooks/budgetAllocation/useGetBudgetDistributionsForBudgetAccount';
import { map } from 'lodash';
import styles from './ProjectBudgetInfo.scss';

const { Text } = Typography;
const { Option } = Select;

interface BudgetInfo {
  fiscalYear: string;
  totalBudget: number;
  availableBudget: number;
}

interface IProjectBudgetInfoProps {
  projectId: number;
  paymentAmount?: number;
}

const ProjectBudgetInfo: React.FC<IProjectBudgetInfoProps> = (props) => {
  const { projectId, paymentAmount } = props;
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<string | null>(null);
  const [budgets, setBudgets] = useState<BudgetInfo[]>([]);

  const {
    loading: loadingBudgetDistribution,
    budgetDistributionsForBudgetAccount,
  } = useGetBudgetDistributionsForBudgetAccount({
    variables: {
      programId: projectId,
    },
    skip: !projectId,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (budgetDistributionsForBudgetAccount?.length) {
      const budgets: BudgetInfo[] = budgetDistributionsForBudgetAccount?.map((budget) => ({
        fiscalYear: budget?.fiscalYearLabel,
        totalBudget: budget?.totalAmount,
        availableBudget: budget?.availableAmount,
      }));
      setBudgets(budgets);
      setSelectedFiscalYear(budgets?.[0]?.fiscalYear);
    }
  }, [budgetDistributionsForBudgetAccount]);

  if (loadingBudgetDistribution) {
    return (
      <div className={styles.loaderContainer}>
        <Spinner size="small" />
      </div>
    );
  }

  const selectedBudget = budgets?.find((budget) => budget.fiscalYear === selectedFiscalYear);
  const adjustedAvailableBudget = selectedBudget && paymentAmount
      ? selectedBudget.availableBudget - paymentAmount
      : selectedBudget?.availableBudget;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text weight="semibold">Project Budget Overview</Text>
        <div className="">
          <Select
            value={selectedFiscalYear}
            onChange={(year) => setSelectedFiscalYear(year)}
            placeholder="Fiscal Year"
            bordered={false}
          >
            {map(budgets, (budget) => (
              <Option key={budget.fiscalYear} value={budget.fiscalYear}>
                {budget.fiscalYear}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className={styles.cardContainer}>
        {selectedBudget ? (
          <Card bodyStyle={{ padding: '8px' }} className={styles.fiscalYearCard}>
            <Text type="secondary" className={styles.fiscalYearTitle}>
              {selectedBudget.fiscalYear}
            </Text>
            <div className={styles.budgetAmountContainer}>
              <Text className={styles.budgetAmount}>{`$ ${selectedBudget.totalBudget.toLocaleString()}`}</Text>
              <Text className={styles.budgetAmount}>{`$ ${adjustedAvailableBudget?.toLocaleString()}`}</Text>
            </div>
            <div className={styles.budgetLabelContainer}>
              <Text>Total Budget</Text>
              <div className={styles.availableIndicatorContainer}>
                <div className={styles.availableIndicator} />
                <Text className={styles.availableText}>Available</Text>
              </div>
            </div>
            <Progress
              percent={(adjustedAvailableBudget! / selectedBudget.totalBudget) * 100}
              showInfo={false}
              strokeColor="#5DB884"
            />
          </Card>
        ) : (
          'No budget connected to this Project'
        )}
      </div>
    </div>
  );
};

export default ProjectBudgetInfo;
