import * as React from 'react';
import createAuthClient, {
  Auth0Client,
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  GetUserOptions,
  IdToken,
  LogoutOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js';
import { logger } from '@common';
import { getEnvironment, Environment } from '@frontend/utils/Envionment/Environment';
import { LoadSpinner } from '@components';

interface IClientSelectContext {
  loading: boolean;
  isAuthenticated: boolean;
  user: IUser;
  token: string;
  authError: IAuthError;
  logout(): void;
}

type TAuthError = 'unauthorized' | 'internal_error';
export interface IAuthError {
  type: TAuthError;
  message: string;
}
interface IAuthClient {
  loginWithPopup(options: PopupLoginOptions): Promise<void>;
  getUser(options?: GetUserOptions): Promise<IUser>;
  getIdTokenClaims(options?: getIdTokenClaimsOptions): Promise<IdToken>;
  loginWithRedirect(options: RedirectLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<RedirectLoginResult>;
  getTokenSilently(options?: GetTokenSilentlyOptions): Promise<string>;
  getTokenWithPopup(options?: GetTokenWithPopupOptions): Promise<string>;
  isAuthenticated(): Promise<boolean>;
  logout(options?: LogoutOptions): void;
}

export interface IUser {
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;

  family_name?: string;
  given_name?: string;
  locale?: string;

  // auth0 related
  'https://aspirex.api.com/clients'?: string;
  'https://aspirex.api.com/roles'?: string[];
  'https://aspirex.api.com/gapiToken'?: string;
}

declare global {
  interface Window {
    isUnderTest?: boolean;
  }
}

const {
  useState, useContext, useLayoutEffect, useEffect,
} = React;
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
const { AUTH_API_AUDIENCE } = process.env;
let REDIRECT_URI: string;
let AUTH_DOMAIN: string;
let BRAND_CLIENT_ID: string;
if (typeof window !== 'undefined') {
  REDIRECT_URI = `${window.location.origin}/domain_select`;

  // TODO: FN-787 Move into environment variables.
  // determine auth0 tenant from domain name
  switch (getEnvironment()) {
    case Environment.PRODUCTION:
      // https://manage.auth0.com/dashboard/us/aspireiq/
      AUTH_DOMAIN = 'aspireiq.auth0.com';
      BRAND_CLIENT_ID = 'c4jvneUijNp5u7mvGpQGn8Y16l7uKgtH';
      break;
    case Environment.STAGING:
      // https://manage.auth0.com/dashboard/us/aspireiq-staging/
      AUTH_DOMAIN = 'aspireiq-staging.auth0.com';
      BRAND_CLIENT_ID = 'ydozfXz6ZUFL2NWnuPKdECcbWVf2SZGx';
      break;
    default:
      // https://manage.auth0.com/dashboard/us/aspireiq-development/
      AUTH_DOMAIN = 'aspireiq-development.auth0.com';
      BRAND_CLIENT_ID = 'VTNptPPHfQRyqO8fgcJFAM0JIIISnhx2';
  }
}

/**
 * Helper function which converts query string to json.
 */
const queryStringToJSON = () => {
  const pairs = location.search.slice(1).split('&');

  const result = {};
  pairs.forEach((pair) => {
    const parts = pair.split('=');
    result[parts[0]] = decodeURIComponent(parts[1] || '');
  });

  return JSON.parse(JSON.stringify(result));
};

export const ClientSelectContext = React.createContext<IClientSelectContext>(null);
export const useClientSelectContext = () => useContext(ClientSelectContext);
export const ClientSelectProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<IUser>(null);
  const [token, setToken] = useState<string>(null);
  const [authClient, setAuthClient] = useState<IAuthClient>(null);
  const [authError, setAuthError] = useState<IAuthError>(null);

  const logout = () => {
    if (authClient) {
      authClient.logout({
        returnTo: window.location.origin,
      });
    } else {
      const auth0Client = new Auth0Client({
        audience: AUTH_API_AUDIENCE,
        domain: AUTH_DOMAIN,
        client_id: BRAND_CLIENT_ID,
        redirect_uri: REDIRECT_URI,
        leeway: 30,
        useRefreshTokens: true,
        cacheLocation: window.isUnderTest ? 'localstorage' : 'memory',
      });
      auth0Client.logout({
        returnTo: window.location.origin,
      });
    }
  };

  useIsomorphicLayoutEffect(() => {
    const initAuth = async () => {
      let authClient;
      try {
        authClient = await createAuthClient({
          audience: AUTH_API_AUDIENCE,
          domain: AUTH_DOMAIN,
          client_id: BRAND_CLIENT_ID,
          redirect_uri: REDIRECT_URI,
          leeway: 30,
          useRefreshTokens: true,
          cacheLocation: window.isUnderTest ? 'localstorage' : 'memory',
        });
      } catch (e) {
        setLoading(false);
        setIsAuthenticated(false);
        if (e.error === 'unauthorized') {
          setAuthError({
            type: e.error,
            message: e.error_description,
          });
        } else {
          logger.error('Internal error when authenticating with auth0', e);
          setAuthError({
            type: 'internal_error',
            message: 'An unexpected error has occurred. Please contact help@aspireiq.com if this continues.',
          });
        }
        return;
      }

      setAuthClient(authClient);

      const search = queryStringToJSON();
      // if this is a 302
      if (search.error) {
        setAuthError({
          type: search.error,
          message: search.error_description,
        });
      } else if (search.code) {
        await authClient.handleRedirectCallback();
      }

      // check if user is authenticated
      // 1. from successful login redirect 2. from cache (refresh page)
      const isAuthenticated = await authClient.isAuthenticated();
      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await authClient.getUser();
        const token = await authClient.getTokenSilently();

        setUser(user);
        setToken(token);
      } else if (!search.error) {
        await authClient.loginWithRedirect({
          redirect_uri: REDIRECT_URI,
          prompt: 'select_account',
        });
      }

      setLoading(false);
    };

    initAuth();
  }, []);

  return (
    <ClientSelectContext.Provider
      value={{
        loading,
        isAuthenticated,
        user,
        token,
        authError,
        logout,
      }}
    >
      {loading && <LoadSpinner />}
      {!loading && children}
    </ClientSelectContext.Provider>
  );
};
