import 'css-chunk:src/components/widgets/Slider/RangeSlider.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_19yrm_158",
  "justify-content-space-between": "_justify-content-space-between_19yrm_162",
  "tabular-nums": "_tabular-nums_19yrm_166",
  "RangeSlider": "_RangeSlider_19yrm_170",
  "label": "_label_19yrm_205",
  "description": "_description_19yrm_297",
  "descriptionWithInput": "_descriptionWithInput_19yrm_302",
  "noDescription": "_noDescription_19yrm_305",
  "Input": "_Input_19yrm_305",
  "isDragging": "_isDragging_19yrm_309",
  "trackWrapper": "_trackWrapper_19yrm_313",
  "Track": "_Track_19yrm_319",
  "selected": "_selected_19yrm_330",
  "Handle": "_Handle_19yrm_343",
  "focused": "_focused_19yrm_362",
  "disabled": "_disabled_19yrm_365",
  "grabbed": "_grabbed_19yrm_369",
  "show": "_show_19yrm_1"
};