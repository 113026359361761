import * as React from 'react';
import cx from 'classnames';
import { filter } from 'lodash';
import { useHistory } from 'react-router-dom';
import { IEmailEditorState } from '@frontend/app/components/MessageComposer/EmailEditor';
import { EmailComposer } from '@frontend/app/components';
import { IPreviewConfig } from '@frontend/app/components/MessageComposer/EmailComposer';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';
import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';
import {
  Alert,
  Button,
  Space,
  Row,
  Col,
} from '@revfluence/fresh';
import {
  ArrowUpRightFromSquareIcon,
} from '@revfluence/fresh-icons/regular/esm';

import {
  IContent,
  useClientFeatureEnabled,
  useGetMessageTemplateById,
} from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import {
  IState,
  TDispatch,
} from '../types/state';
import { ActionTypes } from '../types/actionTypes';

import styles from './MessageComposer.scss';

const {
  useCallback,
  useMemo,
  useState,
} = React;

interface IProps {
  state: IState;
  dispatch: TDispatch;
  initialMessage: string;
  templateId: number;
}

const MessageComposer: React.FC<IProps> = React.memo((props) => {
  const {
    dispatch,
    state,
    initialMessage,
    templateId,
  } = props;
  const isBasicTermsEnabled = useClientFeatureEnabled(ClientFeature.BASIC_TERMS);
  const history = useHistory();
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);

  const {
    data: {
      template = null,
    } = {},
   } = useGetMessageTemplateById({
    variables: {
      templateId,
    },
    skip: !templateId,
  });

  const handleSubjectChange = useCallback(
    (emailSubject: string) => {
      dispatch({ type: ActionTypes.UpdateEmailSubject, emailSubject });
    }, [dispatch],
  );

  const handleAdditionalCcChange = useCallback(
    (additionalCc: Partial<IMember>[]) => {
      dispatch({ type: ActionTypes.UpdateAdditionalCc, additionalCc });
    }, [dispatch],
  );

  const handlePreviewConfigChange = useCallback(
    (config: IPreviewConfig) => {
      setPreviewConfig(config);
    }, [setPreviewConfig],
  );

  const memberCount = useMemo(() => filter(state.members, 'selected').length, [state.members]);
  const memberIds = useMemo(() => filter(state.members, 'selected').map((member) => member.id), [state.members]);

  const handleEditorStateChange = useCallback(
    (emailContent: IEmailEditorState) => {
      dispatch({ type: ActionTypes.UpdateEmailContent, emailContent });
    }, [dispatch],
  );

  const handleAttachmentsChange = useCallback(
    (attachments: IContent[]) => {
      dispatch({ type: ActionTypes.UpdateEmailAttachments, attachments });
    }, [dispatch],
  );

  const handleResourceIdChange = useCallback(
    (resourceId: number) => {
      dispatch({ type: ActionTypes.UpdateEmailAuthor, resourceId });
    }, [dispatch],
    );

  return (
    <div className={styles.MessageComposer}>
      <Space
        direction="vertical"
        style={{ width: '97%', marginTop: isBasicTermsEnabled ? '20px' : '0px' }}
      >
        <Row gutter={15}>
          <Col xs={20}>
            <Alert
              message="Each member will get their own separate email with a unique link to accept the Brief."
              type="info"
            />
          </Col>
          <Col xs={4}>
            <Button
              icon={<ArrowUpRightFromSquareIcon />}
              size="large"
              onClick={() => history.push(`/settings/messageTemplates?templateId=${templateId}`)}
            >
              Edit Template
            </Button>
          </Col>
        </Row>
      </Space>
      <EmailComposer
        additionalVariables={{ LINK_TO_TERMS: { label: 'Link To Brief' } }}
        subjectIsEditable
        initialMessage={initialMessage}
        currentValue={JSON.stringify(state.emailContent?.raw)}
        memberQuery={{
          communityIds: [-999],
          includeMemberIds: memberIds,
        }}
        memberCount={memberCount}
        onMessageSent={() => {}}
        className={cx(styles.EmailComposer, {
          [styles.hide]: !!previewConfig,
        })}
        subject={state.emailSubject}
        onSubjectChange={handleSubjectChange}
        onEditorStateChange={handleEditorStateChange}
        initialAttachments={template?.attachments || []}
        onAttachmentsChanged={handleAttachmentsChange}
        onAdditionalCcStateChange={handleAdditionalCcChange}
        onResourceIdChange={handleResourceIdChange}
        hideSendButton
        onPreview={handlePreviewConfigChange}
        enableExpandingEmailEditor
      />
      {
        previewConfig && (
          <EmailPreviewer
            canSendMessages={false}
            previewConfig={previewConfig}
            onBack={() => setPreviewConfig(null)}
            memberCount={memberCount}
            memberQuery={{
              communityIds: [-999],
              includeMemberIds: memberIds,
            }}
            disableRemoveRecipient
          />
        )
      }
    </div>
  );
});

MessageComposer.displayName = 'MessageComposer';

export default MessageComposer;
