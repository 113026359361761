import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  SOCIAL_POST_APP_ID,
  SHOPIFY_APP_ID,
  PAYMENT_APP_ID,
  CONTRACT_APP_ID,
  TERMS_APP_ID,
  GMAIL_APP_ID,
  OUTLOOK_APP_ID,
} from '@frontend/app/constants/applicationIds';

const TOP_NAVIGATION_SETTINGS = [
  PAYMENT_APP_ID,
  CONTRACT_APP_ID,
  TERMS_APP_ID,
  GMAIL_APP_ID,
  SOCIAL_POST_APP_ID,
  SHOPIFY_APP_ID,
  OUTLOOK_APP_ID,
];

export const useApplicationNavigation = () => {
  const history = useHistory();

  const goTo = useCallback((appId: string, path: string = '') => {
    if (path === '/settings' && TOP_NAVIGATION_SETTINGS.includes(appId)) {
      history.push(`/settings/${appId}`);
    } else {
      history.push(`/app/${appId}${path}`);
    }
  }, [history]);

  return {
    goTo,
  };
};
