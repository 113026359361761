/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._CopyLinkInput_16q3l_32 {
  position: relative;
}
._CopyLinkInput_16q3l_32 ._header_16q3l_35 {
  margin-bottom: 10px;
  color: #1a1818;
  font-size: 16px;
  font-weight: bold;
}
._CopyLinkInput_16q3l_32 ._linkWrapper_16q3l_41 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CopyLinkInput_16q3l_32 ._linkWrapper_16q3l_41 ._linkInput_16q3l_46 {
  flex: 1;
}
._CopyLinkInput_16q3l_32 ._linkWrapper_16q3l_41 ._viewButton_16q3l_49 {
  margin-left: 8px;
  width: 85px;
}
._CopyLinkInput_16q3l_32 ._linkWrapper_16q3l_41 ._copyButton_16q3l_53 {
  margin-left: 8px;
  width: 105px;
}