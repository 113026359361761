import * as React from 'react';
import {
 isEmpty, noop, size, times,
} from 'lodash';

import {
  Avatar, Card, Typography,
  Button, Col, Divider, Row,
} from '@revfluence/fresh';
import { List, Checkbox, Input } from 'antd';
import { CheckIcon } from '@revfluence/fresh-icons/solid/esm';
import { TContent, TContentIssues } from '../../types';

import styles from './Issues.module.scss';

const { useState, useCallback } = React;

export const Issues = ({
  issues,
  onClose = noop,
  onSendToGCR = noop,
  onRequestChanges = noop,
  loading,
}: TContent) => {
  const [rejecting, setRejecting] = useState(false);
  const [comment, setComment] = useState('');
  const [checkOffs, setCheckOffs] = useState<boolean[]>(times(size(issues), () => false));

  const onGuidelineChecked = useCallback((index: number, checked: boolean) => {
    setCheckOffs((checkOffs) => {
      const newCheckOffs = [...checkOffs];

      newCheckOffs[index] = checked;

      return newCheckOffs;
    });
  }, []);

  return (
    <Card
      className={styles.Issues}
      bodyStyle={{ padding: '24px 24px 8px' }}
    >
      <Typography.Title level={5} style={{ fontSize: '14px' }}>
        {rejecting ? 'Select which guidelines are not met:' : 'This content should meet these agreed upon guidelines:'}
      </Typography.Title>
      {!rejecting && (
        <>
          <List
            dataSource={issues}
            renderItem={({ id, description }: TContentIssues) => (
              <List.Item key={id}>
                <div className={styles.item}>
                  <Avatar
                    className={styles.avatar}
                    style={{ backgroundColor: '#FFC53D' }}
                    icon={<CheckIcon />}
                    size={24}
                  />
                  <div className={styles.info}>
                    <Typography.Text>{description}</Typography.Text>
                    <Typography.Link onClick={() => setRejecting(true)}>Request Changes</Typography.Link>
                  </div>
                </div>
              </List.Item>
            )}
          />
          <div style={{ backgroundColor: 'inherit', paddingBottom: '24px' }}>
            <Divider />
            <Row justify="center" align="middle" gutter={[16, 24]}>
              <Col span={6}>
                <Button block onClick={onClose}>Close</Button>
              </Col>
              <Col span={6}>
                <Button type="primary" block loading={loading} onClick={onSendToGCR}>
                  Send to Group Review
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
      {rejecting && (
        <>
          <List
            dataSource={issues}
            renderItem={({ id, description }: TContentIssues, index) => (
              <List.Item key={id}>
                <Checkbox
                  checked={checkOffs[index]}
                  onChange={(e) => onGuidelineChecked(index, e.target.checked)}
                >
                  {description}
                </Checkbox>
              </List.Item>
            )}
          />
          <Divider />
          <Typography.Title level={5} style={{ fontSize: '14px' }}>
            Add a friendly comment about the changes you want:
          </Typography.Title>
          <Input.TextArea
            placeholder="Enter a comment about your requested changes..."
            autoSize={{ minRows: 3, maxRows: 6 }}
            onChange={(e) => setComment(e.target.value)}
          />
          <Divider />
          <div style={{ backgroundColor: 'inherit', paddingBottom: '24px' }}>
            <Row justify="center" align="middle" gutter={[16, 24]}>
              <Col span={6}>
                <Button block onClick={() => setRejecting(false)}>Cancel</Button>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  danger
                  block
                  loading={loading}
                  disabled={isEmpty(comment)}
                  onClick={() => onRequestChanges(comment, checkOffs)}
                >
                  Request Changes
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Card>
  );
};
