._checkboxHeaderCell_th7ht_1 {
  background-color: unset !important;
}

._checkboxCell_th7ht_5,
._checkboxHeaderCell_th7ht_1 {
  pointer-events: none;
}
._checkboxCell_th7ht_5 .ant-checkbox-wrapper,
._checkboxCell_th7ht_5 .ant-checkbox,
._checkboxHeaderCell_th7ht_1 .ant-checkbox-wrapper,
._checkboxHeaderCell_th7ht_1 .ant-checkbox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._checkboxCell_th7ht_5 .ant-checkbox-checked .ant-checkbox-inner,
._checkboxHeaderCell_th7ht_1 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0075FF;
  border-color: #0075FF;
}
._checkboxCell_th7ht_5 .ant-checkbox-indeterminate .ant-checkbox-inner:after,
._checkboxHeaderCell_th7ht_1 .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #0075FF;
}
._checkboxCell_th7ht_5 .ant-checkbox-checked:after,
._checkboxHeaderCell_th7ht_1 .ant-checkbox-checked:after {
  border-color: transparent !important;
}
._checkboxCell_th7ht_5 .ant-checkbox:hover .ant-checkbox-inner,
._checkboxHeaderCell_th7ht_1 .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #0075FF;
}
._checkboxCell_th7ht_5 > div,
._checkboxHeaderCell_th7ht_1 > div {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  pointer-events: none;
}
._checkboxCell_th7ht_5 > div > label,
._checkboxHeaderCell_th7ht_1 > div > label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: initial;
}