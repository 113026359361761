import { useMutation, MutationHookOptions } from '@apollo/client';
import { DataProxy } from 'apollo-cache';
import { unionBy } from 'lodash';

import { GET_SEGMENT_FOLDERS } from '@frontend/app/queries';
import {
  SegmentFoldersQuery,
  SegmentFoldersQueryVariables,
} from '@frontend/app/queries/types/SegmentFoldersQuery';

import { SAVE_SEGMENT_FOLDER } from '@frontend/app/queries/SaveSegmentFolderMutation';
import {
  SaveSegmentFolderMutation,
  SaveSegmentFolderMutationVariables,
} from '@frontend/app/queries/types/SaveSegmentFolderMutation';

type IOptions = MutationHookOptions<SaveSegmentFolderMutation, SaveSegmentFolderMutationVariables>;

export const useSaveSegmentFolderMutation = (options: IOptions = {}) => useMutation<SaveSegmentFolderMutation, SaveSegmentFolderMutationVariables>(SAVE_SEGMENT_FOLDER, {
    ...options,
    update: (...args) => {
      const [store, result] = args;

      if (options.update) {
        options.update(...args);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateCache(store as any, result.data.folder);
    },
  });

const updateCache = (store: DataProxy, folder: SaveSegmentFolderMutation['folder']) => {
  const cache = store.readQuery<SegmentFoldersQuery, SegmentFoldersQueryVariables>({
    query: GET_SEGMENT_FOLDERS,
    variables: {
      communityId: folder.communityId,
      sourceGroup: folder.sourceGroup,
    },
  });

  // Do nothing. Cache doesn't exist.
  if (!cache) {
    return;
  }

  const newCache: typeof cache = {
    ...cache,
    folders: unionBy(cache.folders, [folder], 'id'),
  };

  store.writeQuery({
    query: GET_SEGMENT_FOLDERS,
    data: newCache,
    variables: {
      communityId: folder.communityId,
      sourceGroup: folder.sourceGroup,
    },
  });
};
