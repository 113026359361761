import { useMutation, MutationHookOptions } from '@apollo/client';

import { ARCHIVE_PROGRAM_MUTATION } from '../queries';
import { ArchiveProgramMutation, ArchiveProgramMutationVariables } from '../queries/types/ArchiveProgramMutation';

type IOptions = MutationHookOptions<ArchiveProgramMutation, ArchiveProgramMutationVariables>;

export const useArchiveProgramById = (options: IOptions = {}) => (
  useMutation<ArchiveProgramMutation, ArchiveProgramMutationVariables>(ARCHIVE_PROGRAM_MUTATION, options)
);
