/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_104up_157 {
  display: flex;
}

._justify-content-space-between_104up_161 {
  justify-content: space-between;
}

._tabular-nums_104up_165 {
  font-variant-numeric: tabular-nums;
}

._Header_104up_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Header_104up_169 ._actions_104up_174 ._invite_104up_174 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_104up_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Header_104up_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Header_104up_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Header_104up_169 ::-webkit-scrollbar-track, ._Header_104up_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Header_104up_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Header_104up_169 {
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  user-select: none;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Header_104up_169._isLoading_104up_217 {
  opacity: 0.5;
  cursor: wait;
}
._Header_104up_169._isLoading_104up_217 * {
  pointer-events: none;
}
._Header_104up_169 ._DataTimeframe_104up_224 {
  display: flex;
  gap: 12px;
}
._Header_104up_169 ._tabs_104up_228 {
  position: absolute;
  left: 50%;
}
._Header_104up_169 ._tabs_104up_228._noLabel_104up_232 {
  transform: translateX(calc(-50% - 0.375rem));
}
._Header_104up_169 ._tabs_104up_228._dataLabel_104up_235 {
  transform: translateX(calc(-25% - 0.375rem));
}
._Header_104up_169 ._tabs_104up_228 ._tab_104up_165 {
  background-color: transparent;
  border: 0;
}
._Header_104up_169 ._tabs_104up_228 ._tab_104up_165 > div {
  position: relative;
  color: #8f8d91;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.03125rem;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._Header_104up_169 ._tabs_104up_228 ._tab_104up_165 > div:after {
  position: absolute;
  left: 50%;
  bottom: -0.625rem;
  width: 1.5rem;
  height: 0.125rem;
  display: block;
  background-color: #3996e0;
  content: "";
  transform: translateX(-50%) scaleX(0);
  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._Header_104up_169 ._tabs_104up_228 ._tab_104up_165._activeTab_104up_262 > div {
  color: #3996e0;
  font-weight: 600;
}
._Header_104up_169 ._tabs_104up_228 ._tab_104up_165._activeTab_104up_262 > div:after {
  transform: translateX(-50%) scaleX(1);
}
._Header_104up_169 ._favorite_104up_269 {
  margin-right: 0.5rem;
}
._Header_104up_169 ._actions_104up_174 {
  display: flex;
  flex-direction: row;
}
._Header_104up_169 ._actions_104up_174 ._actionButtonGroup_104up_276 {
  display: flex;
}
._Header_104up_169 ._actions_104up_174 ._actionButtonGroup_104up_276 > [class*=offerManageButton] {
  background-color: #fdfdfd !important;
}
._Header_104up_169 ._actions_104up_174 ._favoriteButton_104up_282 {
  margin-left: 0.5rem;
  margin-right: -0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
._Header_104up_169 ._actions_104up_174 ._invite_104up_174 {
  width: 16.25rem;
  border-radius: 624.9375rem;
}