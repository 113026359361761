import * as React from 'react';
import {
  Alert,
  Card,
  Space,
  Typography,
} from '@revfluence/fresh';

import { BankIcon } from '@frontend/app/components';
import { ACCOUNTS_RECEIVABLE_EMAIL } from '@frontend/app/components/ApplicationPageTemplates/CustomizedTemplate/constants';
import { moneyNumToString } from '../../constants';

const { Link, Text, Title } = Typography;

interface IProps {
  balanceInCents: number;
  currencyCode: string;
  className?: string;
  requireHandlingFee?: boolean;
}

export const PaymentBalance: React.FC<IProps> = (props) => {
  const ALTER_INFO_COPY = (
    <Text>
      "Add a Card" to add a credit card on file for creator payments. Please note that there is a 3% processing fee
      {props.requireHandlingFee && (
        <span> and 2% handling </span>
      )}
      {' '}
      per each transaction via Stripe.
      <br />
      <br />
      If you have any questions about payment methods, please reach out to
      {' '}
      <Link href={`mailto:${ACCOUNTS_RECEIVABLE_EMAIL}`} target="_blank" rel="noopener noreferrer">
        {ACCOUNTS_RECEIVABLE_EMAIL}
      </Link>
      . For more information about how payments work or how to pay out a creator, please check out this
      {' '}
      <Link
        href="https://intercom.help/aspireiq_elevate/en/articles/6326989-how-do-payments-work-on-aspire"
        target="_blank"
        rel="noopener noreferrer"
      >
        Help Article
      </Link>
      .
    </Text>
  );

  return (
    <Card className={props.className}>
      <Space size="middle">
        <span>
          <BankIcon />
        </span>
        <Title level={5} weight="regular" noMargin>
          Balance of $
          {moneyNumToString(props.balanceInCents / 100)}
          {' '}
          {props.currencyCode}
        </Title>
      </Space>
      <Alert type="info" description={ALTER_INFO_COPY} />
    </Card>
  );
};
