import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_DEMOGRAPHIC_REGION_ORDERS_QUERY } from '../queries';
import { GetDemographicRegionOrdersSummary, GetDemographicRegionOrdersSummaryVariables } from '../queries/types/GetDemographicRegionOrdersSummary';

type IOptions = QueryHookOptions<GetDemographicRegionOrdersSummary, GetDemographicRegionOrdersSummaryVariables>;

export function useGetDemographicRegionOrders(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetDemographicRegionOrdersSummary, GetDemographicRegionOrdersSummaryVariables>(
    GET_DEMOGRAPHIC_REGION_ORDERS_QUERY,
    {
      ...options,
    },
  );

  return {
    isDemographicRegionOrdersLoading: loading,
    demographicRegionOrders: data?.getDemographicRegionOrdersSummary,
    demographicRegionOrdersError: error,
    refetchDemographicRegionOrders: refetch,
  };
}
