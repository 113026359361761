/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';
import {
 findIndex, toLower, find, isArray, isEmpty, isFunction,
} from 'lodash';
import {
  Select, Input, Button, HelpIcon, Tooltip,
  Notice, SpinnerIcon,
} from '@components';

import { useMessagingContext } from '@frontend/hooks';
import { SimpleForm, SimpleField } from '@frontend/app/components';
import { FieldType } from '@frontend/app/types/Fields';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { getLabelForFieldType } from './utils';
import { useCommunityIdentityContext } from '../hooks/useCommunityIdentityContext';

import { SelectOptions } from './SelectOptions';

import styles from './FieldForm.scss';

const {
 useState, useMemo, useEffect, useRef, useCallback,
} = React;

export interface IFieldForm {
  id?: number | null;
  type: string;
  name: string;
  choices?: string[];
}

const SELECT_OPTIONS: Array<{ value: string; label: string }> = [
  { value: FieldType.TEXT, label: getLabelForFieldType(FieldType.TEXT) },
  { value: FieldType.NUMBER, label: getLabelForFieldType(FieldType.NUMBER) },
  { value: FieldType.BOOLEAN, label: getLabelForFieldType(FieldType.BOOLEAN) },
  { value: FieldType.DATE, label: getLabelForFieldType(FieldType.DATE) },
  { value: FieldType.SINGLE_SELECT, label: getLabelForFieldType(FieldType.SINGLE_SELECT) },
  { value: FieldType.MULTI_SELECT, label: getLabelForFieldType(FieldType.MULTI_SELECT) },
];

interface IProps {
  field: IFieldForm;
  saving?: boolean;
  disabled?: boolean;
  showDelete?: boolean;
  vertical?: boolean;
  className?: string;

  onSaveField?: (newField: IFieldForm) => void;
  onRemoveField?: (field: IFieldForm) => void;
  onAddExistingSchema?: (schemaId: number) => void;
  onCancel?: () => void;
}

export const FieldForm: React.FunctionComponent<IProps> = React.memo((props) => {
  const isNewField = !props.field?.id;

  const [newField, setNewField] = useState<IFieldForm>(props.field);
  const [waitingForField, setWaitingForField] = useState<boolean>(props.saving);
  const [uniquePropertyError, setUniquePropertyError] = useState<boolean>(false);
  const helpIconRef = useRef();
  const formRef = useRef<SimpleForm>();

  const {
    allSchemas,
    customSchemas,
  } = useCommunityIdentityContext() || {};

  const {
    showErrorMessage,
  } = useMessagingContext();

  const isSelectField = (
    newField.type === FieldType.MULTI_SELECT
      || newField.type === FieldType.SINGLE_SELECT
  );

  const showOptions = isSelectField || isArray(newField.choices);

  useEffect(() => {
    if (isSelectField && !isArray(newField.choices) && isNewField) {
      setNewField({ ...newField, choices: [] });
    } else if (isNewField && !isSelectField) {
      setNewField({ ...newField, choices: null });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectField]);

  useEffect(() => {
    if (props.saving) {
      setWaitingForField(true);
    }
  }, [props.saving]);

  useEffect(() => {
    if (waitingForField) {
      setWaitingForField(false);
      setNewField(props.field);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.field]);

  useEffect(() => {
    if (uniquePropertyError) {
      setUniquePropertyError(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newField.name]);

  const selectedIndex = useMemo(() => findIndex(SELECT_OPTIONS, { value: newField.type }), [newField.type]);

  const handleChangeFieldType = useCallback((newType: string) => {
    setNewField((field) => ({ ...field, type: newType }));
  }, [setNewField]);

  const handleChangeForm = useCallback((fieldName: string, value: any) => {
    setNewField((field) => ({ ...field, [fieldName]: value }));
  }, [setNewField]);

  const handleClickSave = useCallback(() => {
    formRef.current.submit();
  }, [formRef]);

  const caseInsensitivePredicateFactory = useCallback((name: string) => {
    name = toLower(name);
    return (schema: typeof allSchemas[0]) => toLower(schema.name) === name;
  }, []);

  const addEvent = useEventContext();

  const handleSaveField = () => {
    const predicate = caseInsensitivePredicateFactory(newField.name);

    const customFieldExists = findIndex(customSchemas, predicate) !== -1;

    if (isEmpty(newField.choices) && showOptions) {
      showErrorMessage('You must add at least one option.');
      return;
    }

    if (customFieldExists && !newField.choices) {
      showErrorMessage('This custom property already exists');
      return;
    }

    const existsOnClient = findIndex(allSchemas, predicate) !== -1;

    if (existsOnClient && !newField.choices) {
      setUniquePropertyError(true);
      return;
    }

    const payload = { ...newField };

    if (newField.type === FieldType.MULTI_SELECT) {
      payload.type = FieldType.ARRAY;
    } else if (newField.type === FieldType.SINGLE_SELECT) {
      payload.type = FieldType.TEXT;
    } else if (!showOptions) {
      delete payload.choices;
    }

    if (isFunction(props.onSaveField)) {
      props.onSaveField(payload);
    }

    if (isNewField) {
      addEvent(
        EventName.AddCustomProperty,
        {
          property_type: payload.type,
          name: newField.name,
        },
      );
    }
  };

  const handleRemove = () => {
    if (isFunction(props.onRemoveField)) {
      props.onRemoveField(props.field);
    }
  };

  const handleCancel = () => {
    if (isNewField) {
      handleRemove();
    }

    if (isFunction(props.onCancel)) {
      props.onCancel();
    }

    setNewField(props.field);
  };

  const handleAddExistingSchema = () => {
    const predicate = caseInsensitivePredicateFactory(newField.name);
    const schema = find(allSchemas, predicate);
    if (schema) {
      props.onAddExistingSchema(schema.id);
    }
  };

  const onChangeSelectOptions = (options: string[]) => {
    setNewField({ ...newField, choices: options });
  };

  const isDisabled = props.disabled;

  return (
    <div
      className={cx(
        styles.FieldForm,
        props.className,
        {
          [styles.Vertical]: props.vertical,
        },
      )}
    >
      <div className={styles.form}>
        <div className={cx(styles.name, styles.fieldName)}>
          <SimpleForm ref={formRef} values={newField} onSubmit={handleSaveField} onChange={handleChangeForm}>
            <label className={styles.label}>Enter a name for your field:</label>
            <SimpleField name="name" type="string" required label="Field name" hasError={uniquePropertyError}>
              <Input className={(styles as any).field} />
            </SimpleField>
          </SimpleForm>
        </div>
        <div className={styles.type}>
          <label className={styles.label}>Field type:</label>
          {newField.id ? (
            <div>
              {getLabelForFieldType(newField.type, isArray(newField.choices))}
              <span ref={helpIconRef} className={styles.helpIcon}>
                <HelpIcon />
              </span>
              <Tooltip placement="bottom" mountRef={helpIconRef}>
                Can't edit field type.
              </Tooltip>
            </div>
          ) : (
            <Select
              selectedIndex={selectedIndex}
              onChange={handleChangeFieldType}
              options={SELECT_OPTIONS}
              round
            />
          )}
        </div>
      </div>
      {showOptions && (
        <SelectOptions
          onChange={onChangeSelectOptions}
          choices={newField.choices}
          field={props.field}
        />
      )}
      {uniquePropertyError && (
        <Notice type="alert" showDivider className={styles.notice}>
          <div>
            <div className={styles.title}>This name has already been used</div>
            <div className={styles.subtitle}>
              Custom field names must be unique. Do you want to add an existing field?
            </div>
            <Button
              theme="info"
              label="Add Existing Field"
              onClick={handleAddExistingSchema}
              disabled={props.disabled}
              className={styles.dangerBtn}
              round={false}
            />
          </div>
        </Notice>
      )}
      <div className={styles.buttons}>
        <div className={styles.buttonsContainer}>
          {
            !isNewField
              && props.showDelete
              && (
                <Button
                  theme="info"
                  label="Remove Field"
                  onClick={handleRemove}
                  disabled={props.disabled}
                  className={styles.dangerBtn}
                />
              )
          }
        </div>

        <div className={styles.buttonsContainer}>
          <Button
            theme="info"
            label="Cancel"
            onClick={handleCancel}
            disabled={props.disabled}
          />
          <Button
            label="Save"
            onClick={handleClickSave}
            disabled={isDisabled}
            icon={props.saving && <SpinnerIcon size={16} />}
          />
        </div>
      </div>
    </div>
  );
});

FieldForm.defaultProps = {
  showDelete: false,
};
