import * as React from 'react';

import { CreateAppContainer } from '@frontend/app/components/CreateAppContainer/CreateAppContainer';
import { CREATE_ARCHIVE_APP_ID } from '@frontend/app/constants/applicationIds';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useAuth } from '@frontend/context/authContext';

import styles from './CreateArchiveAppModal.scss';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deepLinkParameters: any;
}

export const CreateArchiveAppModal: React.FC<IProps> = () => {
  const { token } = useAuth();
  const { memberId } = useApplication();

  const params = {
    member_id: memberId,
  };

  return (
    <CreateAppContainer className={styles.CreateArchiveAppModal} applicationId={CREATE_ARCHIVE_APP_ID} token={token} extraParams={params} />
  );
};
