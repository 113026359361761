/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_15g6a_157 {
  display: flex;
}

._justify-content-space-between_15g6a_161 {
  justify-content: space-between;
}

._tabular-nums_15g6a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_15g6a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ImageSelect_15g6a_178 ._error_15g6a_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._ImageSelect_15g6a_178 {
  width: 400px;
  min-height: 400px;
}
._ImageSelect_15g6a_178 ._spinner_15g6a_192 {
  margin-top: 20px;
}
._ImageSelect_15g6a_178 ._error_15g6a_178 {
  margin-top: 20px;
  display: block;
  text-align: center;
}
._ImageSelect_15g6a_178 ._tabContent_15g6a_200 {
  padding: 8px;
  height: 400px;
  overflow: scroll;
}