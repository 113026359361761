import { deleteGuidelineAttachment } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IDeleteContentGuidelineAttachmentAction, IState } from '../../../types/state';

export const deleteContentGuidelineAttachment = (
  state: IState,
  action: IDeleteContentGuidelineAttachmentAction,
): IState => ({
  ...state,
  contentGuidelines: deleteGuidelineAttachment(
    state.contentGuidelines,
    action.id,
    action.attachments,
  ),
  isEditMode: true,
});
