import 'css-chunk:src/components/widgets/FavoriteButton/FavoriteButton.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "FavoriteButton": "_FavoriteButton_1vyt3_79",
  "isProcessing": "_isProcessing_1vyt3_84",
  "button": "_button_1vyt3_87",
  "disabled": "_disabled_1vyt3_100",
  "active": "_active_1vyt3_107",
  "star-pulse": "_star-pulse_1vyt3_1",
  "starBorder": "_starBorder_1vyt3_110",
  "star": "_star_1vyt3_110",
  "FavoriteListPopover": "_FavoriteListPopover_1vyt3_152",
  "content": "_content_1vyt3_152",
  "header": "_header_1vyt3_156",
  "spinner": "_spinner_1vyt3_167",
  "list": "_list_1vyt3_171",
  "option": "_option_1vyt3_176",
  "label": "_label_1vyt3_193",
  "actions": "_actions_1vyt3_201",
  "item": "_item_1vyt3_207",
  "createNewList": "_createNewList_1vyt3_216",
  "addIconWrapper": "_addIconWrapper_1vyt3_235",
  "addIcon": "_addIcon_1vyt3_235",
  "editList": "_editList_1vyt3_252",
  "back": "_back_1vyt3_263",
  "arrowIcon": "_arrowIcon_1vyt3_283",
  "editContent": "_editContent_1vyt3_287"
};