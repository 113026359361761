import gql from 'graphql-tag';

import { MEMBER_FIELD_SCHEMA_FRAGMENT } from '@frontend/app/queries/fragments/MemberFieldSchemaFragment';

export const SAVE_CUSTOM_FIELD = gql`
  mutation SaveMemberFieldSchemaMutation($schema: MemberFieldSchemaInput!) {
    schema: saveMemberFieldSchema(schema: $schema) {
      ...MemberFieldSchemaFragment
    }
  }
  ${MEMBER_FIELD_SCHEMA_FRAGMENT}
`;
