import * as React from 'react';
import { isFunction } from 'lodash';
import { IWizardProps } from './types';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import styles from './styles.scss';

const { useState } = React;

const logoSvg = require('@frontend/app/assets/svgs/logo.svg');

export const Wizard: React.FC<IWizardProps> = (props: IWizardProps) => {
  const {
    children,
    steps,
    submit,
    skip,
    isSkipLoading,
    isSubmitLoading,
    checkNextButtonDisabled,
    onNext,
    scrollableContent,
    showLogo,
  } = props;

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const currentStep = steps[currentStepIndex];
  const prevStep = currentStepIndex > 0 ? steps[currentStepIndex - 1] : null;
  const nextStep = currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1] : null;
  const isNextButtonDisabled = isFunction(checkNextButtonDisabled) ? checkNextButtonDisabled(currentStep.name) : false;

  const renderContent = () => (
    <>
      {showLogo && (
        <div className={styles.logoHeader}>
          <img src={logoSvg} alt="Aspire" className={styles.logo} />
        </div>
      )}
      <div className={styles.header}>
        <Header currentStep={steps[currentStepIndex]} showLogo={showLogo} />
      </div>
      <div className={styles.content}>
        {children(currentStepIndex)}
      </div>
    </>
  );

  return (
    <div className={styles.Wizard}>
      {scrollableContent && (
        <div className={styles.scrollableContent}>
          {renderContent()}
        </div>
      )}
      {!scrollableContent && renderContent()}
      <div className={styles.footer}>
        <Footer
          currentStepIndex={currentStepIndex}
          currentStep={currentStep}
          nextStep={nextStep}
          prevStep={prevStep}
          stepCount={steps.length}
          isNextButtonDisabled={isNextButtonDisabled}
          setCurrentStepIndex={setCurrentStepIndex}
          submit={submit}
          skip={skip}
          isSkipLoading={isSkipLoading}
          isSubmitLoading={isSubmitLoading}
          onNext={onNext}
        />
      </div>
    </div>
  );
};

Wizard.displayName = 'Wizard';
