import { gql } from '@apollo/client';

export const CREATE_PAYMENT_LOGS = gql`
  mutation CreatePaymentLogs($budgetPaymentLogs: CreateBudgetPaymentLogsInput!) {
    createPaymentLogs(budgetPaymentLogs: $budgetPaymentLogs) {
      eventLogs {
        id
        budgetEventGroupId
        createDate
        budgetDistributionId
        amount
      }
      groupLog {
        id
        createdAt
        updatedAt
        paymentType
      }
    }
  }
`;
