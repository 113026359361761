/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1n7vf_157 {
  display: flex;
}

._justify-content-space-between_1n7vf_161 {
  justify-content: space-between;
}

._tabular-nums_1n7vf_165 {
  font-variant-numeric: tabular-nums;
}

._Modal_1n7vf_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1n7vf_274 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Modal_1n7vf_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Modal_1n7vf_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Modal_1n7vf_169 ::-webkit-scrollbar-track, ._Modal_1n7vf_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Modal_1n7vf_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Modal_1n7vf_169 {
  position: fixed !important;
  z-index: 9400;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
._Modal_1n7vf_169 ._mask_1n7vf_213 {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: -100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.85);
  will-change: opacity, left;
  transform: translateZ(0px);
  transition: left 0s cubic-bezier(0.23, 1, 0.32, 1) 0.4s, opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  z-index: 1;
}
._Modal_1n7vf_169 ._dialog_1n7vf_226 {
  margin: 0px auto;
  position: relative;
  z-index: 2;
  top: 50%;
  transform: translate3d(0px, calc(-50% - 100px), 0px);
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
  opacity: 0;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Modal_1n7vf_169 ._dialog_1n7vf_226 ._title_1n7vf_240 {
  margin-bottom: 20px;
  position: relative;
  cursor: default;
  user-select: none;
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}
._Modal_1n7vf_169 ._dialog_1n7vf_226 ._closeButton_1n7vf_250 {
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 10px;
}
._Modal_1n7vf_169 ._dialog_1n7vf_226 ._backButton_1n7vf_256 {
  z-index: 1;
  position: absolute;
  left: 5px;
  top: 5px;
}
._Modal_1n7vf_169 ._dialog_1n7vf_226 ._content_1n7vf_262 {
  z-index: 0;
  position: relative;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Modal_1n7vf_169 ._dialog_1n7vf_226 ._footer_1n7vf_267 {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._Modal_1n7vf_169._show_1n7vf_274 {
  pointer-events: auto;
}
._Modal_1n7vf_169._show_1n7vf_274 ._mask_1n7vf_213 {
  left: 0;
  opacity: 1;
  transition: left 0s cubic-bezier(0.23, 1, 0.32, 1) 0s, opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Modal_1n7vf_169._show_1n7vf_274 ._dialog_1n7vf_226 {
  opacity: 1;
  transform: translateY(-50%);
}