import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CURRENT_USER_METADATA_QUERY } from '@frontend/app/queries';

import {
  GetCurrentUserMetadataQuery,
  GetCurrentUserMetadataQuery_userMetadata,
} from '@frontend/app/queries/types/GetCurrentUserMetadataQuery';

type IOptions = QueryHookOptions<GetCurrentUserMetadataQuery>;
export type IUserMetadata = GetCurrentUserMetadataQuery_userMetadata;

export function useGetCurrentUserMetadata(options: IOptions = {}) {
  const {
 loading, data: { userMetadata } = {}, error, refetch,
} = useQuery<GetCurrentUserMetadataQuery>(
    GET_CURRENT_USER_METADATA_QUERY,
    options,
  );

  return {
    loading,
    userMetadata,
    error,
    refetch,
  };
}
