._Intro_171yt_1 ._title_171yt_1 {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
._Intro_171yt_1 ._title_171yt_1 img {
  width: 60px;
  height: 60px;
  border-radius: 6px;
  overflow: hidden;
  object-fit: cover;
  flex-shrink: 0;
}
._Intro_171yt_1 ._title_171yt_1 ._text_171yt_14 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: var(--gray-9);
  word-break: break-word;
}
._Intro_171yt_1 ._subtitle_171yt_21 {
  margin-top: 16px;
  font-size: 20px;
  line-height: 28px;
  color: var(--gray-9);
  word-break: break-word;
}