import { Decimal } from 'decimal.js';
import { isInteger } from 'lodash';

export const roundToNDigits = (val: number, n: number): number => {
  if (!isInteger(n) || n < 0) {
    throw new Error('Invalid value for digit count.');
  }
  if (isInteger(val)) {
    return val; // don't need to round integers
  }
  if (n === 0) {
    return Math.round(val);
  }

  const powerOfTen = new Decimal(10 ** n);
  const raisedValue = new Decimal(val).times(powerOfTen).round();
  return parseFloat(raisedValue.dividedBy(powerOfTen).toFixed(n));
};

export const roundToNearestCent = (val: number): number => (
  roundToNDigits(val, 2)
);
