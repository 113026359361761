import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import cx from 'classnames';

import { PageHeader } from 'antd';
import { LoadSpinner } from '@components';
import { useProjectByIdQuery } from '@frontend/app/hooks';
import { GroupContentReviewPage } from './GroupContentReviewPage';

import styles from './ProgramDetail.scss';

interface IMatchParams {
  programId: string;
}
interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ProgramDetail: React.FC<IProps> = React.memo((props) => {
  const history = useHistory();
  const { programId } = useParams<IMatchParams>();

  const {
    loading,
    data: {
      project = null,
    } = {},
  } = useProjectByIdQuery({
    variables: {
      id: parseInt(programId, 10),
    },
    skip: !programId,
  });

  return (
    <div className={cx(styles.ProgramDetail, props.className)}>
      {loading && <LoadSpinner />}
      {!loading && project && (
        <>
          <PageHeader
            className={styles.header}
            title={project.title}
            onBack={() => history.goBack()}
          />
          <GroupContentReviewPage project={project} />
        </>
      )}
    </div>
  );
});

ProgramDetail.displayName = 'ProgramDetail';
