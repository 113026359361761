import { ActionType, TState } from '../types';

interface IAction {
    selectedBudgetAccountId: number;
    type: ActionType.SET_SELECTED_BUDGET_ACCOUNT;
}
export type TSetSelectedBudgetAccount = Readonly<IAction>;

export const setSelectedBudgetAccount = (state: TState, action: TSetSelectedBudgetAccount): TState => ({
        ...state,
        selectedBudgetAccountId: action.selectedBudgetAccountId,
    });
