import gql from 'graphql-tag';

export const GET_AFFILIATE_OFFER_CONVERSION_HISTORY_QUERY_BY_ID = gql`
  query GetOfferAffiliateConversionHistoryById($offerId: Int!) {
    offerAffiliateConversionHistory(offerId: $offerId) {
      status
      conversionId
      conversionDate
      sale
      salesBase
      currency
      payoutEarned
      exchangeRate
      storeName
      id
      isPaid
      affiliatInfoOne
      tuneOrderId
      promoCode
      memberName
      isNewCustomer
      discountCode
    }
  }
`;
