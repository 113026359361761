import * as React from 'react';
import cx from 'classnames';
import { RawDraftContentState } from 'draft-js';
import { size } from 'lodash';

import { IMember } from '@frontend/app/hooks';
import { useResourceContext } from '@frontend/app/context';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { EmailPreviewer } from './EmailPreviewer';
import { EmailComposer, IEmailComposer, IPreviewConfig } from './EmailComposer';
import { useExcludeMemberIds } from './useExcludeMemberIds';

const {
 useState, useEffect, useMemo, useRef,
} = React;

import styles from './EmailComposerModal.scss';

interface IProps {
  members?: IMember[];

  allowSendingAsSeparate?: boolean;
  initialMessage?: string | RawDraftContentState;

  // sending from members page
  memberQuery?: IMemberSearchQuery;
  memberCount?: number;

  className?: string;

  // used for event logs
  source?: string;

  onBack: () => void;
}

/**
 * @type {React.FunctionComponent}
 */
export const EmailComposerPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    allowSendingAsSeparate,
    className,
    initialMessage,
    memberCount: memberCountProp,
    memberQuery,
    members,
    source,
    onBack,
  } = props;
  const addEvent = useEventContext();
  const { refetch: refetchResources } = useResourceContext();

  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);
  const messageFormRef = useRef<IEmailComposer>(null);

  const { excludeMemberIds, onExcludeMember, resetExcludeMemberIds } = useExcludeMemberIds();

  const memberCount = (members ? size(members) : memberCountProp) - size(excludeMemberIds);

  const addMessageEventLog = () => {
    if (members || memberQuery) {
      addEvent(EventName.AttemptBulkSendMessage, {
        member_count: memberCount,
        source,
      });
    }
  };

  useEffect(() => {
    setPreviewConfig(null);
    resetExcludeMemberIds();
  }, [setPreviewConfig, resetExcludeMemberIds]);

  useEffect(() => {
    addMessageEventLog();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refetchResources();
  }, [refetchResources]);

  const { filteredMembers, filteredMemberQuery } = useMemo(() => {
    let filteredMembers = undefined;
    let filteredMemberQuery = memberQuery;
    if (members) {
      filteredMembers = members.filter((m) => !excludeMemberIds.includes(m.id) && m.email);
    }
    if (memberQuery) {
      filteredMemberQuery = {
        ...memberQuery,
        includeMemberIds: memberQuery?.includeMemberIds?.filter((id) => !excludeMemberIds.includes(id)),
      };
    }
    return {
      filteredMembers,
      filteredMemberQuery,
    };
  }, [excludeMemberIds, members, memberQuery]);

  return (
    <div className={cx(styles.ComposeModal, className)}>
      <EmailComposer
        ref={messageFormRef}
        className={cx(styles.messageForm, {
          [styles.hide]: !!previewConfig,
        })}
        onMessageSent={onBack}
        onHideComposer={onBack}
        initialMessage={initialMessage}
        allowSendingAsSeparate={allowSendingAsSeparate}
        members={filteredMembers}
        memberCount={memberCount}
        memberQuery={filteredMemberQuery}
        onPreview={(config) => setPreviewConfig(config)}
        source={source}
      />
      {previewConfig && (
        <EmailPreviewer
          excludeMemberIds={excludeMemberIds}
          onExcludeMember={onExcludeMember}
          previewConfig={previewConfig}
          onBack={() => setPreviewConfig(null)}
          onConfirm={() => {
            setPreviewConfig(null);
            messageFormRef.current.sendMessage(true, excludeMemberIds);
          }}
          memberCount={memberCount}
          memberQuery={filteredMemberQuery}
        />
      )}
    </div>
  );
});

EmailComposerPage.displayName = 'EmailComposerPage';
