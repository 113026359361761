/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_s308f_157 {
  display: flex;
}

._justify-content-space-between_s308f_161 {
  justify-content: space-between;
}

._tabular-nums_s308f_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_s308f_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ErrorNotice_s308f_178 {
  padding: 1.25rem;
}