import React, { useMemo } from 'react';
import { WidgetContainer, MainMetric } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { BoxIcon } from '@revfluence/fresh-icons/solid/esm';
import { formatDistanceStrict } from 'date-fns';
import { isNil } from 'lodash';
import { useGetOrdersTypeBreakdown } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrdersTypeBreakdown';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

interface PFATotalOrdersCreatedProps {
  className?: string;
}

export default function PFATotalOrdersCreated({ className }: PFATotalOrdersCreatedProps) {
  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const distanceInWords = formatDistanceStrict(startDate, endDate);

  const { isOrdersTypeBreakdownLoading, ordersTypeBreakdown } = useGetOrdersTypeBreakdown({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const totalOrders = useMemo(() => {
    let total = 0;
    if (ordersTypeBreakdown?.creatorSelected) {
      total += ordersTypeBreakdown.creatorSelected;
    }
    if (ordersTypeBreakdown?.giftOrders) {
      total += ordersTypeBreakdown.giftOrders;
    }
    if (ordersTypeBreakdown?.offlineProcessed) {
      total += ordersTypeBreakdown.offlineProcessed;
    }
    return total;
  }, [ordersTypeBreakdown]);

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <MainMetric
      value={totalOrders}
      size="medium"
      icon={null}
      metricType="count"
      trend={ordersTypeBreakdown?.trendIncrease}
      subHeading={!isNil(ordersTypeBreakdown?.trendIncrease) ? `in last ${distanceInWords}` : undefined}
      className="p-2 pt-0 -mt-2"
    />
  );
  return (
    <WidgetContainer
      widgetTitle="Total Orders Created"
      className={`${className}`}
      bgColor="bg-[#003F5B]"
      widgetIcon={<BoxIcon className="text-white" fontSize={16} />}
    >
      {isOrdersTypeBreakdownLoading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
