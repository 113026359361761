import * as React from 'react';
import {
  useGetTags,
} from '@frontend/app/hooks';
import { renderTokenCell } from '../TokenCell';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}

export const TagsCell: React.FC<IProps> = (props) => {
  const { tagColorMap } = useGetTags();
  return renderTokenCell(props.data, 'name', null, tagColorMap);
};
