import * as React from 'react';
import { useQuery } from '@apollo/client';
import { SearchMembersQuery, SearchMembersQueryVariables } from '@frontend/app/queries/types/SearchMembersQuery';
import { SEARCH_MEMBERS_QUERY } from '@frontend/app/queries';
import { Operator } from '@frontend/app/types/MemberSearch';
import { useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import { keyBy } from 'lodash';

const { useMemo } = React;
export const useFetchMembers = () => {
  const { refetch } = useQuery<SearchMembersQuery, SearchMembersQueryVariables>(SEARCH_MEMBERS_QUERY, { skip: true });

  const {
    data: {
      schemas: allSchemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const schemaByName = useMemo(() => {
    if (allSchemas === null) return null;

    return keyBy(allSchemas, 'name');
  }, [allSchemas]);

  const refetchMembers = async (searchTerm: string) => {
    const instagramMemberFieldId = schemaByName?.Instagram?.id;

    let query;

    if (searchTerm.startsWith('@')) {
      query = {
        fields: {
          and: [{
            memberFieldSchemaId: instagramMemberFieldId, [Operator.CONTAINS]: searchTerm.replace('@', ''),
          }],
        },
      };
    } else {
      query = {
        fields: {
          and: [
            {
              or: [
                { column: 'email', [Operator.CONTAINS]: searchTerm },
                { column: 'name', [Operator.CONTAINS]: searchTerm },
              ],
            },
            { column: 'email', [Operator.NOT_NULL]: true },
          ],
        },
      };
    }

    const result = await refetch({
      query,
      take: 10,
      skip: 0,
    });

    const membersData = result?.data?.members || [];

    return membersData.map((member) => {
      if (searchTerm.startsWith('@')) {
        return {
          id: member.id,
          value: member.fields[instagramMemberFieldId],
          label: member.name,
        };
      }
      return {
        id: member.id,
        value: member.email,
        label: member.name,
      };
    });
  };

  return {
    refetchMembers,
  };
};
