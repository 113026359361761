import * as React from 'react';
import { Alert } from '@revfluence/fresh';
import {
  Table,
  TableProps,
} from 'antd';
import { some } from 'lodash';

import { IMember } from '../../types/Member';
import { IState } from '../../types/state';
import { OnUpdateColumnValue } from './types';
import { useTableAutoHeight } from './useTableAutoHeight';
import { useTableRowSelection } from './useTableRowSelection';
import { useTableColumns } from './useTableColumns';

import styles from './ReviewTable.scss';

const { useCallback } = React;
export interface IProps {
  collaborationDetails: IState['collaborationDetails'];
  contentGuidelines: IState['contentGuidelines'];
  members: IState['members'];
  onMarkMembersDeselected: (memberIds: number[]) => void;
  onMarkMembersSelected: (memberIds: number[]) => void;
  onUpdateColumnValue: OnUpdateColumnValue;
}

const NO_MEMBERS_MESSAGE = 'None of the selected members can fulfill the requirements.';
const NO_MEMBERS_DESCRIPTION = 'Please change the content requirements or return to the project view.';

export const ReviewTable: React.FC<IProps> = React.memo((props) => {
  const {
    collaborationDetails,
    contentGuidelines,
    members,
    onMarkMembersDeselected,
    onMarkMembersSelected,
    onUpdateColumnValue,
  } = props;

  const columns = useTableColumns(
    collaborationDetails,
    contentGuidelines,
    onUpdateColumnValue,
  );

  const rowSelection = useTableRowSelection(
    members,
    onMarkMembersDeselected,
    onMarkMembersSelected,
  );

  const rowClassName: TableProps<IMember>['rowClassName'] = useCallback((row: IMember) => {
    if (row.disabled) {
      return styles.disabled;
    }
    return '';
  }, []);

  const {
    scroll,
    wrapperRef,
  } = useTableAutoHeight();

  const hasEnabledMember = some(members, (m) => !m.disabled);

  if (!hasEnabledMember) {
    return (
      <Alert
        description={NO_MEMBERS_DESCRIPTION}
        message={NO_MEMBERS_MESSAGE}
        showIcon
        type="error"
      />
    );
  }

  return (
    <div className={styles.ReviewTableWrapper} ref={wrapperRef}>
      <Table
        bordered={false}
        className={styles.reviewTable}
        columns={columns}
        dataSource={members}
        pagination={false}
        rowClassName={rowClassName}
        rowSelection={rowSelection}
        scroll={scroll}
        tableLayout="fixed"
      />
    </div>
  );
});
ReviewTable.displayName = 'ReviewTable';
