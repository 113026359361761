import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_WORKLETS_FOR_PARENT_SPEC_QUERY } from '@frontend/app/queries';
import {
  GetAllWorkletsForParentSpecQuery,
  GetAllWorkletsForParentSpecQueryVariables,
} from '@frontend/app/queries/types/GetAllWorkletsForParentSpecQuery';

type IOptions = QueryHookOptions<GetAllWorkletsForParentSpecQuery, GetAllWorkletsForParentSpecQueryVariables>;

export const useGetAllWorkletsForParentSpecQuery = (options: IOptions = {}) => (
  useQuery<GetAllWorkletsForParentSpecQuery, GetAllWorkletsForParentSpecQueryVariables>(GET_ALL_WORKLETS_FOR_PARENT_SPEC_QUERY, options)
);
