import * as React from 'react';
import { v4 } from 'uuid';
import {
  Col,
  Divider,
  Space,
  Typography,
  Popover,
} from '@revfluence/fresh';
import { CheckIcon, XmarkIcon, ArrowUpRightFromSquareIcon } from '@revfluence/fresh-icons/solid/esm';

import styles from '@frontend/app/components/InstagramMeta/MetaItems/MetaItems.module.scss';
import { isEmpty } from 'lodash';

const { Text, Link } = Typography;

interface IFeature {
  feature: string;
  errorMessage?: string;
}
export interface IMetaSettingsPopoverProps {
  features: IFeature[];
  popoverType: PopoverType;
}

export enum Status {
  ERROR = 'error',
  VERIFIED = 'verified',
}

export enum PopoverType {
  FEATURES = 'FEATURES',
  DISCONNECTED = 'DISCONNECTED',
  HIDDEN = 'HIDDEN',
  IGDM_ACCOUNT_ALREADY_CONNECTED = 'IGDM_ACCOUNT_ALREADY_CONNECTED',
  IGDM_APP_NOT_INSTALLED = 'IGDM_APP_NOT_INSTALLED',
  BCA = 'BCA',
  BCA_ACCOUNT_ALREADY_CONNECTED = 'BCA_ACCOUNT_ALREADY_CONNECTED',
  ADS = 'ADS',
  AD_ACCOUNT_ACCESS_LOST = 'AD_ACCOUNT_ACCESS_LOST',
  ALLOWLISTING = 'ALLOWLISTING',
  SPARK_ADS = 'SPARK_ADS',
}

const isPermissionsError = (errorMessage: string): boolean => {
  if (errorMessage.includes('Please reconnect and enable')) {
    return true;
  }
  return false;
};

const composePermissionErrorsArray = (errorMessage: string): [string, string[]] => {
  const [initialMessage, permissions] = errorMessage.split('|');
  const permissionsArray = permissions.split(', and ,');
  return [initialMessage, permissionsArray];
};

interface IErrorMessageProps {
  display?: boolean;
  errorMessage: string;
}

const ErrorMessages: React.FC<IErrorMessageProps> = ({
  errorMessage,
  display,
}) => {
  if (!display) {
    return null;
  }
  if (isPermissionsError(errorMessage)) {
    const [initialMessage, permissions] = composePermissionErrorsArray(errorMessage);
    return (
      <>
        <Text className={styles.FeatureText}>{initialMessage}</Text>
        <ul>
          {permissions.map((permission) => (
            <li key={v4()}>
              <Text>{permission}</Text>
            </li>
          ))}
        </ul>
      </>
    );
  }
  return <Text className={styles.FeatureText}>{errorMessage}</Text>;
};

export const MetaSettingsPopover: React.FC<IMetaSettingsPopoverProps> = (props) => {
  const {
    children,
    features,
    popoverType,
  } = props;

  const renderFeatureItem = (feature: IFeature) => {
    const isVerified = isEmpty(feature.errorMessage);
    const isError = !isVerified;

    return (
      <div key={feature.feature} className={styles.FeatureListDiv}>
        <Space direction="horizontal">
          {isVerified ? (
            <CheckIcon className={styles.CheckIcon} />
          ) : (
            <XmarkIcon className={styles.XIcon} />
          )}
          <Text weight="semibold">{feature.feature}</Text>
        </Space>
        <Col>
          <ErrorMessages display={isError} errorMessage={feature.errorMessage} />
        </Col>
      </div>
    );
  };
  const DisconnectedPopover = (
    <Space direction="vertical" size="middle">
      <Text weight="semibold">Access token expired</Text>
      <Text type="secondary" style={{ display: 'inline-block', textAlign: 'left' }}>
        The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons. Please reconnect the account to fix this error.
        {' '}
        <Link target="_blank" href="https://intercom.help/aspireiq_elevate/en/articles/8104130-how-to-connect-your-brand-s-instagram-account">
          Help
          {' '}
          <ArrowUpRightFromSquareIcon />
        </Link>
      </Text>
    </Space>
  );
  const AdAccountAccessLostPopover = (
    <Space direction="vertical" size="middle">
      <Text weight="semibold">Access Lost</Text>
      <Text type="secondary" style={{ display: 'inline-block', textAlign: 'left' }}>
        You do not have access to this ad account. Please get access to the ad account through the Meta Business Manager and reconnect your account.
        {' '}
        <Link target="_blank" href="https://intercom.help/aspireiq_elevate/en/articles/8104130-how-to-connect-your-brand-s-instagram-account">
          Help
          {' '}
          <ArrowUpRightFromSquareIcon />
        </Link>
      </Text>
    </Space>
  );
  const IGDMAlreadyConnectedPopover = (
    <Space direction="vertical" size="middle">
      <Text weight="semibold">Account already in use</Text>
      <Text type="secondary" style={{ display: 'inline-block', textAlign: 'left' }}>
        This Instagram account has previously been selected for Direct Messaging by another client. Please contact support to resolve this.
      </Text>
    </Space>
  );
  const IGDMAppNotInstalledPopover = (
    <Space direction="vertical" size="middle">
      <Text weight="semibold">App not installed</Text>
      <Text type="secondary" style={{ display: 'inline-block', textAlign: 'left' }}>
        The Instagram Direct Messaging app is not installed on your account. Please contact support to get started.
      </Text>
    </Space>
  );
  const BCAAlreadyConnectedPopover = (
    <Space direction="vertical" size="middle">
      <Text weight="semibold">Account already selected</Text>
      <Text type="secondary" style={{ display: 'inline-block', textAlign: 'left' }}>
        You can only have one account selected for Ads Permissions and it cannot be changed.
      </Text>
    </Space>
  );

  const popoverTitle = (popoverType) => {
    switch (popoverType) {
      case PopoverType.ADS:
        return 'Ads Insights';
      case PopoverType.ALLOWLISTING:
        return 'Allowlisting';
      default:
        return 'Features';
    }
  };

  const PopoverContent = () => {
    const title = popoverTitle(popoverType);

    return (
      <>
        <Space direction="vertical" size="middle">
          <Text weight="semibold">{title}</Text>
          <Col>
            {features.map(renderFeatureItem)}
          </Col>
        </Space>
        {popoverType === PopoverType.BCA && (
          <>
            <Divider className={styles.Divider} />
            <Text type="secondary" style={{ display: 'inline-block', textAlign: 'center' }}>
              Only one account can be selected for Paid Partnership Ads, and cannot be changed.
            </Text>
          </>
        )}
        {popoverType === PopoverType.SPARK_ADS && (
          <>
            <Divider className={styles.Divider} />
            <Text type="secondary" style={{ display: 'inline-block', textAlign: 'left' }}>
              Only one account can be selected for Spark Ads. It can be changed at any time but will only affect new briefs.
            </Text>
          </>
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (popoverType) {
      case PopoverType.DISCONNECTED:
        return DisconnectedPopover;
      case PopoverType.AD_ACCOUNT_ACCESS_LOST:
        return AdAccountAccessLostPopover;
      case PopoverType.IGDM_ACCOUNT_ALREADY_CONNECTED:
        return IGDMAlreadyConnectedPopover;
      case PopoverType.IGDM_APP_NOT_INSTALLED:
        return IGDMAppNotInstalledPopover;
      case PopoverType.BCA_ACCOUNT_ALREADY_CONNECTED:
        return BCAAlreadyConnectedPopover;
      default:
        return PopoverContent;
    }
  };

  if (popoverType === PopoverType.HIDDEN) {
    return <>{children}</>;
  }

  return (
    <Popover
      className={styles.FeaturesPopover}
      placement="top"
      overlayStyle={{ width: '300px' }}
      color="white"
      content={renderContent()}
    >
      <span>{children}</span>
    </Popover>
  );
};
