import { isDate, isNil } from 'lodash';
import { format, isSameDay, isThisYear } from 'date-fns';

export function formatMessageDate(date: Date | number) {
  if (!isDate(date)) {
    date = new Date(date);
  }

  if (isSameDay(date, Date.now())) {
    return format(date, 'h:mm a');
  }
  if (isThisYear(date)) {
    return format(date, 'MM/dd hh:mm');
  }

  return format(date, 'MM/dd/yy');
}

export function formatMessageDateWithTime(
  date: Date | number,
  dateFormat?: string,
) {
  if (!isDate(date)) {
    date = new Date(date);
  }

  // Will format the date like this: Oct 7, 2022 at 6:09 am
  if (isNil(dateFormat)) {
    return `${format(date, 'MMM dd, yyyy')} at ${format(date, "h:mm aaaaa'm'")}`;
  }

  // dateFormat is undefined so the date will be formatted based on dateFormat
  return format(date, dateFormat);
}
