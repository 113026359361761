import numeral from 'numeral';
import {
  capitalize,
  every,
  get,
  first,
  isEmpty,
  isNil,
  isNumber,
  isString,
  last,
  lowerCase,
} from 'lodash';

import { networkConfig } from '@components';
import { ISocialAccount, IFollowerHistory, ILikeHistory } from '@components';
import { ISocialPost } from '@components';

export * from './detailConfig';
export * from './demographicsReport';

export const getSocialAccountUsername = (account?: ISocialAccount): string => {
  if (!account) return '';

  let username = '';
  if (account.network_identifier !== 'youtube' && account.username) {
    username = account.username.split('@').pop();
  } else if (account.network_identifier === 'youtube') {
    username = account.username || account.link;
    return username.split('/').filter(Boolean).pop();
  }

  return username;
};

/**
 * Display name
 */
export const getDisplayName = (params: {
  socialAccount: ISocialAccount;
  nameOnly?: boolean;
  usernameOnly?: boolean;
}): string => {
  const {
    socialAccount,
    nameOnly = false,
    usernameOnly = false,
  } = params;

  if (isEmpty(socialAccount)) {
    return null;
  }

  const displayName = (
    socialAccount.full_display_name
    || socialAccount.display_name
    || socialAccount.name
  );
  const showAtSign = (
    socialAccount.network_identifier === 'instagram'
    || socialAccount.network_identifier === 'twitter'
  );

  const displayUsername = socialAccount.username
    ? (showAtSign ? '@' : '') + socialAccount.username.replaceAll('@', '')
    : null;

  if (nameOnly) {
    return displayName || displayUsername;
  } else if (usernameOnly) {
    return isString(socialAccount.username) // Force isString check on raw username because it might have '@'
      ? displayUsername
      : displayName; // Backup to display name if username isn't available
  }

  return (
    displayName
    && socialAccount.username
    && lowerCase(displayName) !== lowerCase(socialAccount.username) // Avoid redundancy, e.g. @aspire (Aspire)
  )
    ? `${displayUsername} (${displayName})`
    : displayUsername || displayName;
};

/**
 * Audience count + label
 * e.g. '100 Followers'
 */
export const audienceCountLabel = (socialAccount: ISocialAccount): string => {
  if (!socialAccount || isEmpty(socialAccount) || !socialAccount.network_identifier) {
    return '';
  }
  const { audienceNamePlural } = networkConfig[socialAccount.network_identifier];

  const count = numeral(socialAccount.reach)
    .format('0.[0]a')
    .toUpperCase();
  return `${count} ${capitalize(audienceNamePlural)}`;
};

/**
 * Post/Content type
 * e.g. 'Instagram post'
 * TODO: DT-798: Update where this is being sourced
 */
export const getPostType = (post: ISocialPost): string => {
  const { longContentName } = networkConfig[post.network_identifier];
  // Remove the 'a' or 'an' article
  return longContentName.replace(/^an? /, '') || 'post';
};

/**
 * Check if social profile has enough data
 */
export const hasEnoughData = (socialAccount: ISocialAccount): boolean => (
  every(
    [
      'engagement_ratio',
      'username',
      'posts',
    ] as Array<keyof ISocialAccount>,
    (prop) => isNil(socialAccount[prop]),
  )
);

/**
 * Pretty segment name
 */
export const getSegmentName = (socialAccount: ISocialAccount) => {
  const segment = get(socialAccount, 'engagement_ratio_segment');
  switch (segment) {
    case 'MACRO': return 'Macro';
    case 'MID': return 'Mid';
    case 'MICRO': return 'Micro';
    case 'NANO_LARGE': return 'Nano (Large)';
    case 'NANO_MEDIUM': return 'Nano (Medium)';
    case 'NANO_SMALL': return 'Nano (Small)';
    case 'NANO_MICRO': return 'Nano (Micro)';
  }
  return '';
};

/**
 * Higher/Lower text
 */
export const calculateHigherLowerText = (ratio: number, avgRatio: number): string => {
  if (!isNumber(ratio) || !isNumber(avgRatio)) {
    return null;
  }

  if (ratio > avgRatio) {
    return ratio / avgRatio > 1 && ratio / avgRatio < 1.05
      ? 'slightly higher'
      : `${avgRatio === 0 ? '0' : numeral(ratio / avgRatio).format('0.0')}x higher`;
  } else if (ratio < avgRatio) {
    return avgRatio / ratio > 1 && avgRatio / ratio < 1.05
      ? 'slightly lower'
      : `${ratio === 0 ? '0' : numeral(avgRatio / ratio).format('0.0')}x lower`;
  } else {
    return 'on par';
  }
};

/**
 * Calculate MoM for Growth chart
 * Assumes the ff:
 * - data is already sorted
 * - data doesn't have duplicate months
 * - end month has a non-zero value
 */
export const calculateMoM = (data: IFollowerHistory[] | ILikeHistory[]): number => {
  if (data?.length <= 1 || !last(data).count) {
    return;
  }

  // eslint-disable-next-line no-restricted-properties
  return (Math.pow(
    first(data).count / last(data).count,
    1 / data.length,
  ) - 1) * -1;
};

/**
 * Calculate difference in months between two dates
 */
export const monthDiff = (dateFrom: Date, dateTo: Date) => {
  let months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
  months -= dateFrom.getMonth();
  months += dateTo.getMonth();
  return months <= 0 ? 0 : months;
};
