import React from 'react';
import { Tag } from '@revfluence/fresh';
import { CatalogStatus } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';

export const CatalogStatusRenderer = (type: CatalogStatus): React.ReactElement<typeof Tag> => {
    if (type === CatalogStatus.ACTIVE) {
        return <Tag color="success">Active</Tag>;
    } else if (type === CatalogStatus.DRAFT) {
        return <Tag>Draft</Tag>;
    } else {
        return <Tag>-</Tag>;
    }
};
