import * as React from 'react';
import cx from 'classnames';
import * as qs from 'qs';
import { Menu, Dropdown } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { EditIcon } from '@components';
import {
 findIndex, isFunction, map, sortBy, toLower,
} from 'lodash';

import { EllipsisLabel } from '@frontend/app/components';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { useMessagingContext } from '@frontend/hooks/useMessagingContext';
import {
  useParsedRouterSearch,
  useDeleteSegmentFolder,
  useSegmentFoldersQuery,
} from '@frontend/app/hooks';
import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';

import { NewFolderForm } from './NewFolderForm';
import { SegmentItem } from './SegmentItem';

import styles from './SegmentFolder.scss';

const {
 useState, useMemo, useEffect, useRef,
} = React;

interface ISegment {
  id: string | number;
  title: string;
  memberCount: number;
  filter?: IMemberSearchQuery;
}

export interface IFolder {
  id?: number;
  title: string;
  segments: ISegment[];
}

interface IProps {
  communityId: number;
  sourcingGroupId: string;
  folder: IFolder;
  editable?: boolean;
  icon?: React.ReactNode;
  className?: string;
  onDelete?(): void;
  setSelectedMembers?(selectedMembers: IMember[]);
}

export const SegmentFolder: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const searchQuery = useParsedRouterSearch();
  const editFormMountRef = useRef<HTMLDivElement>();
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const { showSuccessMessage, showGenericErrorMessage } = useMessagingContext();
  const selectedSegmentId = Number(searchQuery.segmentId) || searchQuery.segmentId;

  const {
    refetch: refetchSegmentFolders,
  } = useSegmentFoldersQuery(undefined, undefined, { skip: true });

  const [deleteSegmentFolder] = useDeleteSegmentFolder({
    onCompleted: (data) => {
      if (data) {
        refetchSegmentFolders({
          communityId: props.communityId,
          sourceGroup: props.sourcingGroupId,
        });

        showSuccessMessage('Removed');

        if (isFunction(props.onDelete)) {
          props.onDelete();
        }
      }
    },
    onError: () => {
      showGenericErrorMessage();
    },
  });

  const defaultExpanded = useMemo(() => findIndex(props.folder.segments, { id: selectedSegmentId }) !== -1, [props.folder.segments, selectedSegmentId]);

  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);

  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(defaultExpanded);
    }
  }, [defaultExpanded]);

  const handleToggleExpanded = () => {
    if (!showEditForm) {
      setExpanded((s) => !s);
    }
  };

  const handleCloseEditForm = () => {
    setShowEditForm(false);
  };

  const segments = useMemo(
    () => sortBy(props.folder.segments, (segment) => toLower(segment.title)),
    [props.folder],
  );

  const handleClickSegment = (segment: ISegment) => {
    if (selectedSegmentId !== segment.id && isFunction(props.setSelectedMembers)) {
      props.setSelectedMembers([]);
    }
    history.push({
      pathname: '/member_table',
      search: qs.stringify({ ...searchQuery, segmentId: segment.id }),
    });
  };

  const SegmentFolderActions = useMemo(() => {
    const segmentsLength = props.folder.segments.length;
    const confirmText = `Are you sure? This action will permanently delete ${props.folder.title} `
      + `and ${segmentsLength} segment${segmentsLength !== 1 ? 's' : ''} associated with the group`;
    return (
      <Menu>
        <Menu.Item onClick={setShowEditForm.bind(this, true)}>
          Rename
        </Menu.Item>
        <Menu.Item
          danger
          onClick={() => {
            const confirm = () => window.confirm(confirmText);
            if (segmentsLength === 0 || confirm()) {
              deleteSegmentFolder({
                variables: {
                  id: props.folder.id,
                },
              });
            }
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowEditForm, props.folder.segments, props.folder.id, deleteSegmentFolder]);

  return (
    <div className={cx(styles.SegmentFolder, {
      [styles.expanded]: expanded,
      [styles.editable]: props.editable,
    }, props.className)}
    >
      <div ref={editFormMountRef} className={styles.header} onClick={handleToggleExpanded}>
        {props.icon && (
          <div className={styles.icon}>{props.icon}</div>
        )}

        <EllipsisLabel className={styles.title}>
          {props.folder.title}
        </EllipsisLabel>

        <span className={styles.arrow}>
          {expanded && <UpOutlined />}
          {!expanded && <DownOutlined />}
        </span>

        {props.editable && (
          <>
            <span className={styles.editIcon}>
              <Dropdown
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                {...{} as any}
                overlay={SegmentFolderActions}
                placement="bottomCenter"
                trigger={['click']}
                onClick={(e) => e.stopPropagation()}
              >
                <span>
                  <EditIcon size={16} />
                </span>
              </Dropdown>
            </span>
            <NewFolderForm
              mountRef={editFormMountRef}
              header="Rename your Segment Group:"
              show={showEditForm}
              folder={props.folder}
              communityId={props.communityId}
              sourcingGroupId={props.sourcingGroupId}
              onRequestClose={handleCloseEditForm}
              arrowPosition="end"
              anchorOrigin="end"
            />
          </>
        )}
      </div>
      <div className={styles.segmentList}>
        {map(segments, (segment) => (
          <SegmentItem
            key={segment.id}
            className={styles.segment}
            communityId={props.communityId}
            sourcingGroupId={props.sourcingGroupId}
            segment={segment}
            folder={props.folder}
            editable={props.editable}
            selected={segment.id === selectedSegmentId}
            onClick={handleClickSegment.bind(this, segment)}
          />
        ))}
      </div>
    </div>
  );
});

SegmentFolder.defaultProps = {
  editable: false,
};
