import gql from 'graphql-tag';

export const SAVE_REQUESTS_BATCH = gql`
  mutation SaveRequestsBatch($batch: MemberDataUpdateBatchInput!, $resourceId: Int!) {
    batchResponse: saveRequestsBatch(batch: $batch, resourceId: $resourceId) {
      batch {
        id
      }
      membersLandingPageUrls
    }
  }
`;
