import React, { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { AutomationExecutionStatus } from '@services/automations/types/AutomationExecution';
import { format as formatDate } from 'date-fns';
import { ProjectsRouteRoot } from '@frontend/app/containers/Projects/constants';
import { AUTOMATION_EXECUTION_TERMINABLE_STATUSES } from '@frontend/app/components/AutomationAuditTable/AutomationAuditTableTypes';
import { P } from '@/shadcn/components/typography/p';
import { Button } from '@/shadcn/components/ui/button';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/shadcn/components/ui/alert-dialog';

import { Badge } from '@/shadcn/components/ui/badge';
import { Avatar, AvatarImage, AvatarFallback } from '@/shadcn/components/ui/avatar';

const ExecutionStatusMap = {
  COMPLETED: { text: 'Success', color: 'success' },
  FAILED: { text: 'Failed', color: 'error' },
  PENDING: { text: 'Pending', color: 'warning' },
  RUNNING: { text: 'Running', color: 'processing' },
  TERMINATED: { text: 'Canceled', color: 'default' },
};
const ExecutionStatusVariantMap = {
  COMPLETED: 'default',
  FAILED: 'destructive',
  PENDING: 'outline',
  RUNNING: 'outline',
  TERMINATED: 'secondary',
};

const formatAuditTableDate = (date: string) => {
  if (!date) {
    return '';
  }
  return `${formatDate(new Date(date), 'MM/dd/yyyy')} at ${formatDate(new Date(date), 'hh:mm a')}`;
};

const DateTableCellContent = ({ date }: { date: string }) => <P>{formatAuditTableDate(date)}</P>;

export type AutomationExecution = {
  id: string;
  dateTriggered: string;
  dateEnded: string;
  automation: {
    id: string;
    name: string;
  };
  member?: {
    name: string;
    profilePicture: string;
    id: number;
  };
  status: string;
};

export const getColumns = ({
  projectId,
  terminateAutomationExecution,
}: {
  projectId: number;
  terminateAutomationExecution: (executionId: string, reason: string) => Promise<void>;
}) => {
  const columns: ColumnDef<AutomationExecution>[] = [
    {
      accessorKey: 'dateTriggered',
      header: 'Date Triggered',
      cell: ({ row }) => <DateTableCellContent date={row.getValue('dateTriggered')} />,
    },
    {
      accessorKey: 'dateEnded',
      header: 'Date Ended',
      cell: ({ row }) => <DateTableCellContent date={row.getValue('dateEnded')} />,
    },
    {
      accessorKey: 'automationName',
      header: 'Automation Name',
      cell: ({ row }) => (
        <Button className="text-primary underline group hover:text-primary" variant="link" asChild>
          <Link to={`${ProjectsRouteRoot}/${projectId}/automation_detail/automation/${row.original.automation.id}`}>
            {row.original.automation.name}
          </Link>
        </Button>
      ),
    },
    {
      accessorKey: 'member',
      header: 'Member',
      cell: ({ row }) => {
        const name = row.original.member?.name;
        const initials = name
          ?.split(' ')
          .map((n) => n[0]?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ''))
          .join('');
        return (
          <Link className="text-primary group hover:text-primary" to={`/members/${row.original.member?.id}`}>
            <div className="flex items-center gap-2">
              <Avatar className="w-8 h-8">
                <AvatarImage
                  src={row.original.member?.profilePicture}
                  alt={row.original.member?.name}
                  className="object-cover"
                />
                <AvatarFallback className="text-xs">{initials}</AvatarFallback>
              </Avatar>
              <div className="text-sm font-medium underline group-hover:underline">{row.original.member?.name}</div>
            </div>
          </Link>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }) => (
        <Badge variant={ExecutionStatusVariantMap[row.original.status]}>
          {ExecutionStatusMap[row.original.status].text}
        </Badge>
      ),
    },
    {
      accessorKey: 'actions',
      header: 'Actions',
      // no-dd-sa:typescript-code-style/func-name-matching
      cell: function ActionsCell({ row }) {
        const [isOpen, setIsOpen] = useState(false);
        return AUTOMATION_EXECUTION_TERMINABLE_STATUSES.includes(row.original.status as AutomationExecutionStatus) ? (
          <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
            <AlertDialogTrigger asChild>
              <Button size="sm" variant="destructive">
                Cancel
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you want to cancel this automation run?</AlertDialogTitle>
                <AlertDialogDescription>
                  {`${row.original.member?.name} will be removed from the ${row.original.automation.name} automation and
                  the action will not be performed.`}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    (async () => {
                      await terminateAutomationExecution(row.original.id, 'User Initiated');
                      setIsOpen(false);
                    })();
                  }}
                >
                  <Button type="submit">Continue</Button>
                </form>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        ) : null;
      },
    },
  ];

  return columns;
};
