import * as React from 'react';
import cx from 'classnames';
import {
  isEmpty,
} from 'lodash';

import { BarChart } from '@components';

import { Card } from './Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import { countryDataToIDataList } from '../utils';

import styles from './CountryCard.scss';

interface IProps {
  className?: string;
  /**
   * Number of countries to be displayed (including "other")
   */
  maxRecords?: number;
}

export const CountryCard: React.FC<IProps> = (props) => {
  const {
    className,
    maxRecords,
  } = props;
  const {
    hasData,
    socialAccount,
  } = useSocialProfileContext();

  const countryData = (
    hasData
    && !isEmpty(socialAccount.demographics_report)
  )
    ? countryDataToIDataList(socialAccount.demographics_report, true)
    : [];
  const hasCountryData = countryData.length > 0;

  return (
    <Card
      className={cx(className, styles.CountryCard, {
        [styles.noData]: !hasCountryData,
      })}
      header="Audience Country"
    >
      {hasCountryData && (
        <BarChart
          className={styles.audienceLocationBarChart}
          data={countryData}
          maxRecords={maxRecords}
          height={200} // Shows 5 (see maxRecords in BarChartHorizontal.tsx)
          showRawValue={false}
        />
      )}
    </Card>
  );
};

CountryCard.displayName = 'CountryCard';
