import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_CREATOR_ORDERS_SUMMARY_QUERY } from '../queries';
import { GetCreatorOrdersSummary, GetCreatorOrdersSummaryVariables } from '../queries/types/GetCreatorOrdersSummary';

type IOptions = QueryHookOptions<GetCreatorOrdersSummary, GetCreatorOrdersSummaryVariables>;

export function useGetCreatorOrdersSummary(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetCreatorOrdersSummary, GetCreatorOrdersSummaryVariables>(
    GET_CREATOR_ORDERS_SUMMARY_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    isCreatorOrdersSummaryLoading: loading,
    creatorOrdersSummary: data?.summary,
    creatorOrdersSummaryError: error,
    refetchCreatorOrdersSummary: refetch,
  };
}
