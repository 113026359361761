import * as React from 'react';
import cn from 'classnames';

import {
  ArrowTrendUpIcon,
  ArrowTrendDownIcon,
} from '@revfluence/fresh-icons/regular/esm';

import styles from './Trend.module.scss';
import { TrendProps } from './Trend.types';

const Trend = ({ direction = 'up', color = 'gray', hidden }: TrendProps) => {
  const classes = cn(styles[color]);
  if (hidden) {
    return null;
  }
  switch (direction) {
    case 'up':
      return <ArrowTrendUpIcon className={classes} />;
    case 'down':
      return <ArrowTrendDownIcon className={classes} />;
    default:
      return null;
  }
};

export default Trend;
