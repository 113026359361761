import * as React from 'react';
import { QueryResult } from '@apollo/client';

import {
  DashboardStatisticsCardComponent,
  IStatisticsCardData,
} from '@affiliates/components';
import { GetDashboardStatistics } from '@affiliates/queries/types/GetDashboardStatistics';
import { DataFormat } from '@affiliates/utils';

import styles from './DashboardStatisticsCard.scss';

const { useMemo } = React;

interface IProps {
  data: QueryResult<GetDashboardStatistics>['data']['summary'];
}

export const DashboardStatisticsCard: React.FC<Readonly<IProps>> = ({
  data,
}) => {
  const cardData: IStatisticsCardData[] = useMemo(() => [
    {
      title: 'Offers',
      showTime: false,
      value: data.offers,
      format: DataFormat.Integer,
    },
    {
      title: 'Members',
      showTime: false,
      value: data.members,
      format: DataFormat.Integer,
    },
    {
      title: 'Clicks',
      showTime: false,
      value: data.clicks,
      format: DataFormat.Integer,
    },
    {
      title: 'Conversions',
      showTime: false,
      value: data.conversions,
      format: DataFormat.Integer,
    },
    {
      title: 'Sales',
      showTime: false,
      value: data.sales,
      prefix: '$',
      format: DataFormat.Money,
    },
    {
      title: 'Avg. Sale',
      showTime: false,
      value: data.avgSale,
      prefix: '$',
      format: DataFormat.Money,
    },
    {
      title: 'Commissions Earned ',
      showTime: false,
      value: data.payoutEarned,
      prefix: '$',
      format: DataFormat.Money,
    },
    {
      title: 'Payout',
      showTime: false,
      value: data.payoutMade,
      prefix: '$',
      format: DataFormat.Money,
    },
  ], [data]);

  return (
    <div className={styles.DashboardStatisticsCard}>
      <DashboardStatisticsCardComponent data={cardData} className={styles.statsCard} />
    </div>
  );
};
