import * as React from 'react';
import cx from 'classnames';
import { map, findIndex } from 'lodash';

import {
  POST_TYPE_NAME,
  TPostType,
  TReportField,
  LoadSpinner,
  Notice,
  Select,
} from '@components';

import { GraphView } from './GraphView';
import { TableView } from './TableView';

const { useMemo, useState } = React;
import styles from './TMVSection.scss';

const POST_TYPE_NULL = null;

export interface ITMVData {
  post_type: TPostType;
  insights: {
    [key in TReportField]?: {
      count: number;
      multiplier: number;
    };
  };
}

export interface IProps {
  className?: string;
  data: { loading: boolean, data: ITMVData[], error: Error | null };
}

export const tmvMap = [
  ['impressions', 'impression_value'],
  ['views', 'view_value'],
  ['clicks', 'click_value'],
  ['shares', 'share_value'],
  ['likes', 'like_value'],
  ['comments', 'comment_value'],
  ['potential_impressions', 'potential_impression_value'],
  ['closeups', 'closeup_value'],
  ['favorites', 'favorite_value'],
];

/**
 * @type {React.FunctionComponent}
 */
export const TMVSection: React.FunctionComponent<IProps> = React.memo((props) => {
  const { loading, data, error } = props.data;

  const [postType, setPostType] = useState<TPostType>(POST_TYPE_NULL);
  const allPostTypes = useMemo(() => [
    {
      value: POST_TYPE_NULL,
      label: 'View Total',
    },
    ...map(data, (record) => ({
      value: record.post_type,
      label: `By ${POST_TYPE_NAME[record.post_type]}`,
    })),
  ], [data]);
  const selectedIndex = useMemo(() => findIndex(allPostTypes, (p) => p.value === postType), [allPostTypes, postType]);
  const onPostTypeChange = (value) => setPostType(value);

  if (error) {
    return (
      <Notice className={(styles as any).notice} type="error">
        There is an error when trying to fetch the reports.
      </Notice>
    );
  }

  return (
    <div className={cx(styles.TMVSection, props.className)}>
      { loading ? <LoadSpinner centered /> : (
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>
              <h3>Total Media Value</h3>
            </div>
            <Select
              theme="info"
              className={styles.select}
              options={allPostTypes}
              onChange={onPostTypeChange}
              selectedIndex={selectedIndex}
            />
          </div>
          <div className={styles.main}>
            {postType
              ? (
                <TableView
                  postType={postType}
                  data={data}
                />
              )
              : (
                <GraphView
                  className={styles.view}
                  data={data}
                  pieChartProps={{
                    width: 400,
                    height: 400,
                  }}
                />
              )}
          </div>
          <div className={styles.more}>
            <a
              href="https://intercom.help/aspireiq_elevate/en/articles/6676982-understanding-total-media-value"
              target="_blank"
              rel="noopener noreferrer"
            >
              Find more details from our blog
            </a>
          </div>
        </div>
      )}
    </div>
  );
});

TMVSection.defaultProps = {
  className: null,
};

TMVSection.displayName = '<TMVSection />';
