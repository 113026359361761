import { cn } from '@frontend/shadcn/lib/utils';
import React from 'react';

const ColorSwatch: React.FC<{ color?: string, className?: string }> = ({ color, className }) => (
  <div
    className={cn('h-3 w-3 rounded', className)}
    style={{ backgroundColor: color }}
  />
);

export default ColorSwatch;
