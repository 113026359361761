import * as React from 'react';
import {
  Col,
  Space,
  Typography,
  Popover,
} from '@revfluence/fresh';
import { CheckIcon } from '@revfluence/fresh-icons/solid/esm';

import styles from '@frontend/app/components/InstagramMeta/MetaItems/MetaItems.module.scss';
import { SuccessTag } from '@frontend/app/components/InstagramMeta/MetaItems';

const { Text } = Typography;

export const ActiveTag: React.FC = () => {
  const PopoverContent = () => (
    <Space direction="vertical" size="middle">
      <Text weight="semibold">Features</Text>
      <Col>
        <div className={styles.FeatureListDiv}>
          <Space direction="horizontal">
            <CheckIcon className={styles.CheckIcon} />
            <Text weight="semibold">Tag Mentions</Text>
          </Space>
        </div>
      </Col>
    </Space>
  );

  return (
    <Popover
      className={styles.FeaturesPopover}
      placement="top"
      overlayStyle={{ width: '300px' }}
      color="white"
      content={PopoverContent()}
    >
      <span><SuccessTag /></span>
    </Popover>
  );
};
