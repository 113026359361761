import { connect } from 'react-redux';

import { IConnectSearchPage } from '../models';
import {
  selectImageSearch,
  selectTextSearch,
  applySearchFilters,
} from '../redux/searchResultsActions';
import { imageSearchEnabledSelector, showCreateFeaturesSelector } from '../redux/selectors';
import { SearchPage } from '../SearchPage';

const mapStateToProps = (state: IConnectSearchPage) => ({
  isImageSearchSelected: state.searchResults.isImageSearchSelected,
  imageSearchEnabled: imageSearchEnabledSelector(state),
  showCreateFeatures: showCreateFeaturesSelector(state),
});

const mapDispatchToProps = {
  onSelectTextSearch: selectTextSearch,
  onSelectImageSearch: selectImageSearch,
  onClickApplySearch: applySearchFilters,
};

export const ConnectedSearchPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPage);
