import { EditorState, SelectionState, RichUtils } from 'draft-js';

import { getSelectedEntityType } from './getSelectedEntityType';

export async function toggleLinkState(editorState: EditorState): Promise<EditorState> {
  return new Promise((resolve) => {
    const contentState = editorState.getCurrentContent();
    const selectedEntityType = getSelectedEntityType(editorState);
    const isLink = selectedEntityType === 'link';

    // creates link
    if (!isLink) {
      const contentStateWithEntity = contentState.createEntity('link', 'MUTABLE', { src: '' });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });

      resolve(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    } else {
      const selectionState = editorState.getSelection();
      const anchorKey = selectionState.getAnchorKey();
      const currentContent = editorState.getCurrentContent();
      const contentBlock = currentContent.getBlockForKey(anchorKey);

      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();

          return entityKey !== null && contentState.getEntity(entityKey).getType() === 'link';
        },
        (start, end) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          const selectionState = new SelectionState({
            anchorKey,
            focusKey: anchorKey,
            anchorOffset: start,
            focusOffset: end,
          });

          resolve(RichUtils.toggleLink(editorState, selectionState, null));
        },
      );
    }
  });
}
