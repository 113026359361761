import * as React from 'react';
import { AccordionHeader } from './AccordionHeader';

interface IProps {
  active: boolean;
  showHeader: boolean;
  onHeaderClick();
  title: string;
}

export const AccordionSection: React.FC<IProps> = React.memo((props) => {
  const {
 children, active, showHeader, onHeaderClick, title,
} = props;

  return (
    <>
      {showHeader
        && (
        <AccordionHeader
          onClick={onHeaderClick}
          active={active}
          title={title}
        />
)}
      {active
        && <>{children}</>}
    </>
  );
});

AccordionSection.displayName = 'AccordionSection';
