import { IFavoriteList } from './favoriteList';
import { ICampaign } from './campaign';
import { IManager } from './manager';

export interface IClientProposal {
  id: number;
  date_created: string;
  comments: IAgencyComment[];
  campaign: ICampaign;
  favorite_list: IFavoriteList;
  user: IManager;
  reviews: IClientReview[];
}

interface IAgencyComment {
  social_account_id: number;
  comment: string;
}

export interface IClientReview {
  social_account_id: number;
  user: IManager;
  comment: string;
  approved: boolean;
}
