/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_6n15k_157 {
  display: flex;
}

._justify-content-space-between_6n15k_161 {
  justify-content: space-between;
}

._tabular-nums_6n15k_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_6n15k_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ReorderableCell_6n15k_178:hover {
  cursor: pointer;
}
._ReorderableCell_6n15k_178._isDragging_6n15k_181 {
  cursor: grabbing;
}