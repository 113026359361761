import { useMemo } from 'react';
import {
  find,
  includes,
  isArray,
  isNull,
  map,
  trim,
} from 'lodash';

import { IEnabledOfferSources } from '@affiliates/types';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { useGetResources } from '@frontend/app/hooks';
import { GetResourcesQuery_resources } from '@frontend/app/queries/types/GetResourcesQuery';
import { ResourceType } from '@frontend/app/types/globalTypes';

export interface IShopifyCredentials {
  hasPromoScopes: boolean;
  hasSegmentationScopes: boolean;
}

const resourceHasPromoCodeScopes = (resource: GetResourcesQuery_resources): IShopifyCredentials => {
  if (resource.type !== ResourceType.SHOPIFY || resource.authProvider.userRevokedAccess) {
    return {
      hasPromoScopes: false,
      hasSegmentationScopes: false,
    };
  }
  const resourceScopes = resource.scopes.split(',').map((s) => trim(s).toLowerCase());
  const hasPromoScopes = (includes(resourceScopes, 'read_orders') || includes(resourceScopes, 'write_orders'))
    && includes(resourceScopes, 'write_price_rules')
    && includes(resourceScopes, 'write_discounts');
  const hasSegmentationScopes = includes(resourceScopes, 'write_customers');
  return {
    hasPromoScopes,
    hasSegmentationScopes,
  };
};

export const useCheckShopifyCredentials = (enabledSources: IEnabledOfferSources | null): IShopifyCredentials | undefined | null => {
  const resourceQuery = useGetResources({
    skip: isNull(enabledSources) || !enabledSources[OFFER_SOURCE.SHOPIFY],
    variables: {
      ignoreRequestContextUserId: true,
    },
  });

  return useMemo((): IShopifyCredentials | undefined | null => {
    if (resourceQuery.loading) {
      return null;
    }

    if (resourceQuery.error || !isArray(resourceQuery.resources)) {
      return {
        hasPromoScopes: false,
        hasSegmentationScopes: false,
      };
    }

    const mappedResources = map(resourceQuery.resources, resourceHasPromoCodeScopes);
    return find(mappedResources, (resource) => resource.hasPromoScopes);
  }, [resourceQuery]);
};
