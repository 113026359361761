import * as React from 'react';

import { TWorkItem } from '@frontend/app/containers/Projects/types';
import { useAuth } from '@frontend/context/authContext';
import { useApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { useMessagingContext } from '@frontend/hooks';

import { BULK_MARK_ORDERS_AS_DELIVERED_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries';
import {
  BulkMarkDelivered,
  BulkMarkDeliveredVariables,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/BulkMarkDelivered';
import { getOrderIdFromWorkItem } from '@frontend/applications/ProductFulfillmentApp/utils';

const { useCallback } = React;

// This has logic to allow for use outside of PFA ApolloProvider
export const useBulkMarkAsDelivered = () => {
  const { token } = useAuth();
  const client = useApolloClient(token);
  const { showSuccessMessage } = useMessagingContext();

  return useCallback(async (workItems: TWorkItem[]) => {
    const params = {
      orderInputs: workItems.map((workItem) => {
        const orderId = getOrderIdFromWorkItem(workItem);

        return {
          orderId,
          workItemId: workItem.id,
        };
      }),
    };

    await client.mutate<BulkMarkDelivered, BulkMarkDeliveredVariables>({
      mutation: BULK_MARK_ORDERS_AS_DELIVERED_MUTATION,
      variables: { params },
    });

    showSuccessMessage(`${workItems.length} order${workItems.length > 1 ? 's' : ''} marked as delivered.`);
  }, [client, showSuccessMessage]);
};
