import { makeVar } from '@apollo/client';
import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import { isEmpty } from 'lodash';
import { Moment } from 'moment';
import { MessageFilterInput } from '@frontend/app/types/globalTypes';
import { GetResourcesQuery_resources } from '@frontend/app/queries/types/GetResourcesQuery';
import { TAssigneeType } from '../context/MessagingAppContext';
import { TApplication } from '../SearchFilter/MultiSelection';

export type TSearchFilter = {
  selectedSenders: string[];
  selectedRecipients: string[];
  selectedAssignees: string[];
  selectedThreads: string[];
  startDate: Moment | null;
  endDate: Moment | null;
  selectedMessageTypes: string[];
  fullSearch: string;
  parsedFullSearch: string;
};

export const initSearchFilter: TSearchFilter = {
  selectedSenders: [],
  selectedRecipients: [],
  selectedAssignees: [],
  selectedThreads: [],
  startDate: null,
  endDate: null,
  selectedMessageTypes: [],
  fullSearch: '',
  parsedFullSearch: '',
};

export const searchFilterReactiveVars = makeVar<TSearchFilter>(initSearchFilter);

export const getStrippedFullSearch = (fullSearch: string) => {
  const pattern = /(From:\([^)]+\)|To:\([^)]+\)|Assignee:\([^)]+\)|Message Type:\([^)]+\)|Status:\([^)]+\)|(After|Before):\d{4}-\d{2}-\d{2})/g;
  return fullSearch.replace(pattern, '').replace(/\s+/g, ' ').trim();
};

export const getParsedFullSearch = (searchFilter: TSearchFilter, users?: GetAllUsersQuery_users[], applications?: TApplication[], shouldUseFullSearch: boolean = true) => {
  const {
    selectedSenders,
    selectedRecipients,
    selectedAssignees,
    selectedThreads,
    startDate,
    endDate,
    selectedMessageTypes,
    fullSearch,
  } = searchFilter;

  const parsedFullSearch = [
    shouldUseFullSearch ? fullSearch : getStrippedFullSearch(searchFilter.parsedFullSearch),
    selectedSenders.length > 0 ? `From:(${selectedSenders.join(',')})` : '',
    selectedRecipients.length > 0 ? `To:(${selectedRecipients.join(',')})` : '',
    selectedAssignees.length > 0 ? `Assignee:(${selectedAssignees.map((assigneeId) => {
      const user = users.find((user) => user.id === assigneeId);
      return user ? user.name : '';
    }).join(',')})` : '',
    selectedThreads.length > 0 ? `Thread:(${selectedThreads.map((thread) => {
      const application = applications.find((application) => application.id === thread);
      return application ? application.name : '';
    }).join(',')})` : '',
    startDate ? `After:${startDate.format('YYYY-MM-DD')}` : '',
    endDate ? `Before:${endDate.format('YYYY-MM-DD')}` : '',
    selectedMessageTypes.length > 0 ? `Message Type:(${selectedMessageTypes.join(',')})` : '',
  ].filter((item) => item !== '').join(' ');

  return parsedFullSearch;
};

export const getMessageFilter = (options: {
  assigneeType: TAssigneeType,
  activeMessageResources: GetResourcesQuery_resources[],
  isInboxSearchFilter: boolean,
  searchFilter: TSearchFilter,
  resourceEmail: string,
  shouldUseFullSearch: boolean,
}) => {
  const {
    assigneeType,
    activeMessageResources,
    isInboxSearchFilter,
    searchFilter,
    resourceEmail,
    shouldUseFullSearch,
  } = options;

  const messageFilter: MessageFilterInput = {};
  if (assigneeType === 'sent') {
    const activeMessageIDs = activeMessageResources.map((resource) => resource.authProvider.userExternalDisplayId);
    if (isInboxSearchFilter) {
      const from = [...activeMessageIDs, ...searchFilter.selectedSenders].filter((value, index, self) => self.indexOf(value) === index);
      messageFilter.from = isEmpty(from) ? undefined : from;
    } else {
      messageFilter.from = isEmpty(activeMessageIDs) ? undefined : activeMessageIDs;
    }
  } else {
    messageFilter.from = isEmpty(searchFilter.selectedSenders) || !isInboxSearchFilter ? undefined : searchFilter.selectedSenders;
  }

  if (assigneeType === 'all') {
    messageFilter.excludeFromEmails = isEmpty(resourceEmail) ? undefined : [resourceEmail];
  }

  if (isInboxSearchFilter) {
    messageFilter.to = isEmpty(searchFilter.selectedRecipients) ? undefined : searchFilter.selectedRecipients;
    messageFilter.fullSearch = shouldUseFullSearch ? searchFilter.fullSearch || undefined : getStrippedFullSearch(searchFilter.parsedFullSearch) || undefined;
    messageFilter.startTime = searchFilter.startDate?.valueOf();
    messageFilter.endTime = searchFilter.endDate?.valueOf();
  }

  return messageFilter;
};

export const getApplicationIds = (options: {
  searchFilter: TSearchFilter,
  assigneeType: TAssigneeType,
  appNotificationIds: string[],
  nonAppNotificationIds: string[],
  applications: TApplication[],
}) => {
  const {
    searchFilter,
    assigneeType,
    appNotificationIds,
    nonAppNotificationIds,
    applications,
  } = options;
  let applicationIds: string[] = [];

  const nonAppNotificationIdsLookup = {
    Email: applications.filter((application) => ['Gmail', 'Outlook'].includes(application.name)).map((application) => application.id),
    IGDM: applications.find((application) => application.name === 'IGDM')?.id,
  };

  if (assigneeType === 'appNotification') {
    applicationIds = isEmpty(searchFilter.selectedThreads) ? appNotificationIds : searchFilter.selectedThreads;
  } else {
    if (isEmpty(searchFilter.selectedMessageTypes)) {
      applicationIds = nonAppNotificationIds;
    } else {
      searchFilter.selectedMessageTypes.forEach((messageType) => {
        applicationIds = applicationIds.concat(nonAppNotificationIdsLookup[messageType] || []);
      });
    }
  }
  return applicationIds;
};

export const getAssignees = (options: {
  searchFilter: TSearchFilter,
  assigneeType: TAssigneeType,
  authUserId: string,
}) => {
  const {
    searchFilter,
    assigneeType,
    authUserId,
  } = options;

  if (assigneeType === 'you') {
    return [...searchFilter.selectedAssignees, authUserId].filter((value, index, self) => self.indexOf(value) === index);
  }
  return searchFilter.selectedAssignees;
};
