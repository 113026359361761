/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1f440_157 {
  display: flex;
}

._justify-content-space-between_1f440_161 {
  justify-content: space-between;
}

._tabular-nums_1f440_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1f440_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._iconContainer_1f440_178 {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #5DB884;
}

._icon_1f440_178 {
  color: #FFFFFF;
  height: 1rem;
  width: 1rem;
}