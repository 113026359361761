import 'css-chunk:src/app/containers/GuestHome/Groups/GroupCreatorDetail/CreatorProfile.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "screen-xs": "480px",
  "screen-sm": "576px",
  "screen-md": "768px",
  "screen-lg": "992px",
  "screen-xl": "1200px",
  "screen-xxl": "1600px",
  "flex": "_flex_1v268_157",
  "justify-content-space-between": "_justify-content-space-between_1v268_161",
  "tabular-nums": "_tabular-nums_1v268_165",
  "creatorProfile": "_creatorProfile_1v268_196",
  "profileRow": "_profileRow_1v268_203",
  "profileInfo": "_profileInfo_1v268_208",
  "profileImage": "_profileImage_1v268_213",
  "info": "_info_1v268_252",
  "tags": "_tags_1v268_294",
  "socialHandle": "_socialHandle_1v268_302",
  "statusSocialAnalytics": "_statusSocialAnalytics_1v268_318",
  "statusButtons": "_statusButtons_1v268_323",
  "accept": "_accept_1v268_343",
  "maybe": "_maybe_1v268_346",
  "reject": "_reject_1v268_349",
  "socialDemographics": "_socialDemographics_1v268_352",
  "show": "_show_1v268_1"
};