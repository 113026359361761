import gql from 'graphql-tag';

export const GET_HOME_REPORTING = gql`
query GetHomeReporting(
  $startDate: DateTime!,
  $endDate: DateTime!,
  $percentDiffStartDate: DateTime,
  $percentDiffEndDate: DateTime,
  $compareStartDate: DateTime,
  $compareEndDate: DateTime,
  $groupBy:  HistoricalDataGroupBy!,
  $programIds: [Int!],
  $chartStartDate: DateTime!,
  $chartEndDate: DateTime!,
) {
    reporting {
      impact {
        total {
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
        }
        investment {
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
        }
        roi {
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
        }
      }
      content {
        totalCount {
          value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
          percentDiff(
            startDate: $percentDiffStartDate,
            endDate: $percentDiffEndDate,
            compareStartDate: $compareStartDate,
            compareEndDate: $compareEndDate,
            programIds: $programIds
          )
          historicalData(
            groupBy: $groupBy,
            startDate: $chartStartDate,
            endDate: $chartEndDate,
            programIds: $programIds
          ) {
            dateTime
            value
          }
        }

      }
      social {
        totals {
          reach {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          creatorCount {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          views {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          cpe {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          cpm {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          tmv {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          impressions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
            historicalData(
              groupBy: $groupBy,
              startDate: $chartStartDate,
              endDate: $chartEndDate,
              programIds: $programIds
            ) {
              dateTime
              value
            }
          }
          engagements {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
            historicalData(
              groupBy: $groupBy,
              startDate: $chartStartDate,
              endDate: $chartEndDate,
              programIds: $programIds
            ) {
              dateTime
              value
            }
          }
        }
      }
      salesTracking {
        totals {
          conversions {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
          }
          sales {
            value(startDate: $startDate, endDate: $endDate, programIds: $programIds)
            percentDiff(
              startDate: $percentDiffStartDate,
              endDate: $percentDiffEndDate,
              compareStartDate: $compareStartDate,
              compareEndDate: $compareEndDate,
              programIds: $programIds
            )
            historicalData(
              groupBy: $groupBy,
              startDate: $chartStartDate,
              endDate: $chartEndDate,
              programIds: $programIds
            ) {
              dateTime
              value
            }
          }
        }
      }
    }
  }
`;
