import * as React from 'react';
import { Button } from '@frontend/applications/AffiliatesApp/AspireUI';
import { OFFER_TYPE } from '@affiliates/types/globalTypes';

import styles from './WorkflowEmptyOffers.scss';
import { useText } from './hooks/useText';
import { EmptyOfferImg } from './EmptyOfferImg';

export interface IWorkflowEmptyOffersProps {
  offerSource: OFFER_TYPE;
  buttonText: string;
  onButtonClick: () => void;
}

export const WorkflowEmptyOffers: React.FC<IWorkflowEmptyOffersProps> = (props) => {
  const { offerSource, buttonText, onButtonClick } = props;
  const { title, paragraph } = useText(offerSource);
  return (
    <div className={styles.WorkflowEmptyOffers}>
      <div className={styles.textContainer}>
        <h1>{title}</h1>
        <p>{paragraph}</p>
        <Button onClick={onButtonClick} type="primary" className={styles.Button}>
          {buttonText}
        </Button>
      </div>
      <div className={styles.imageContainer}>
        <EmptyOfferImg offerSource={offerSource} />
      </div>
    </div>
  );
};
