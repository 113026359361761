import { useMemo } from 'react';
import { IStepInfo as Step } from '@frontend/applications/Shared/Wizard/container';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { TAddMembersProps } from '../../types';
import { TActions, TState } from './state';
import { useState } from './useState';
import { getPromoCodeSteps } from './getPromoCodeSteps';
import { getTrackingLinksSteps } from './getTrackingLinksSteps';

interface IReturnVal {
  actions: TActions;
  state: TState;
  steps: readonly Step[];
}

export const useAddMembers = (
  props: TAddMembersProps,
): Readonly<IReturnVal> => {
  const { actions, state } = useState(props);
  const steps = useMemo(
    () => {
      switch (props.offerSource) {
        case OFFER_SOURCE.SHOPIFY:
          return getPromoCodeSteps(props, state, actions);
        case OFFER_SOURCE.TUNE:
          return getTrackingLinksSteps(props, state, actions);
      }
    },
    [props, actions, state],
  );
  return { actions, state, steps };
};
