import * as React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import { Cell, ICellProps } from './Cell';
import { useLinkableCell } from './hooks/useLinkableCell';

import styles from './LinkCell.scss';

interface IProps extends ICellProps {
  href?: string;
  handler?(): void;
  nullStr?: string;
  openInNewTab?: boolean;
  title?: string;
  useAnchor?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const LinkCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
 className, href, handler, nullStr, openInNewTab, title, useAnchor, value, ...restProps
} = props;
  const { cellValue, onClick } = useLinkableCell({
    href,
    handler,
    useAnchor,
    openInNewTab,
    title: title || value,
    value,
  });

  if (isNil(value)) {
    return (
      <Cell value={nullStr || ''} {...restProps} />
    );
  }

  const classNames = cx(styles.LinkCell, {
    [styles.useAnchor]: useAnchor,
  }, className);
  return (
    <Cell className={classNames} onClick={onClick} value={cellValue} {...restProps} />
  );
});

LinkCell.defaultProps = {
  href: null,
  handler: () => undefined,
  openInNewTab: true,
  useAnchor: false,
  nullStr: '-',
};
LinkCell.displayName = 'LinkCell';
