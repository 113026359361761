import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_SPECIFICATION_TEMPLATES } from '../queries/GetAllSpecificationTemplatesQuery';
import { GetAllSpecificationTemplatesQuery } from '../queries/types/GetAllSpecificationTemplatesQuery';

type IOptions = QueryHookOptions<GetAllSpecificationTemplatesQuery>;

export const useGetAllSpecificationTemplatesQuery = (options: IOptions = {}) => (
  useQuery<GetAllSpecificationTemplatesQuery>(GET_ALL_SPECIFICATION_TEMPLATES, options)
);
