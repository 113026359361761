import gql from 'graphql-tag';

export const MEMBER_FIELD_SCHEMA_OR_COLUMN_FRAGMENT = gql`
  fragment MemberFieldSchemaOrColumnFragment on MemberFieldSchemaOrColumn {
    id
    name
    type
    label
    required
    choices
    order
  }`;
