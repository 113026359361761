import * as React from 'react';
import {
  first,
  split,
} from 'lodash';
import { useHistory } from 'react-router-dom';

import { SOCIAL_DISCOVERY_APP_ID } from '@frontend/app/constants/applicationIds';
import {
  useClientFeatureEnabled,
  useGetCurrentClient,
  useGetProfile,
} from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';
import HomeLoading from '@frontend/applications/ReportsApp/pages/Home/components/HomeLoading';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import { RefreshImpactHome } from '@frontend/applications/ReportsApp/containers/ImpactHome/RefreshImpactHome';
import { Col, Row, Typography } from '@revfluence/fresh';
import cx from 'classnames';
import { TooltipProvider } from '@frontend/shadcn/components/ui/tooltip';
import { Layout } from './components/Layout';
import { useHomePageGroups } from './hooks/useHomePageGroups';
import { useHomePageProjects } from './hooks/useHomePageProjects';
import { ImpactHome } from '../../../applications/ReportsApp/containers/ImpactHome/ImpactHome';
import styles from './components/GetStarted.scss';
import { RefreshLayout } from './components/RefreshLayout';

export const GetStarted = () => {
  const { profile } = useGetProfile();
  const auth = useAuth();
  const history = useHistory();
  const refreshUi = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const welcome = React.useMemo((): React.ReactNode => {
    const tokens = split(profile.name, ' ');
    const firstName = first(tokens);

    const welcomeMessage = firstName
      ? `Welcome back, ${firstName}.`
      : 'Welcome back.';
    if (!refreshUi) {
      return (
        <Row className={cx(styles.sectionContent, styles.welcomeSection)}>
          <Col>
            <Typography.Title className={styles.title} level={2}>
              {welcomeMessage}
            </Typography.Title>
          </Col>
        </Row>
      );
    }
  }, [profile, refreshUi]);

  const projects = useHomePageProjects();
  const groups = useHomePageGroups(SOCIAL_DISCOVERY_APP_ID);

  const clientLookup = useGetCurrentClient();

  const onClickNewProject = React.useCallback((e) => {
    e.preventDefault();
    history.push(e.target.getAttribute('href'));
  }, [history]);

  const metrics = React.useMemo((): React.ReactNode => {
    if (clientLookup.loading) {
      return <HomeLoading />;
    }

    if (!clientLookup?.client?.createdDate) {
      return undefined;
    }

    return refreshUi ? <RefreshImpactHome clientId={auth.clientInfo.id} refreshUi={refreshUi} /> : <ImpactHome clientId={auth.clientInfo.id} />;
  }, [
    auth.clientInfo.id,
    clientLookup,
    refreshUi,
  ]);

  return (
    refreshUi ? (
      <TooltipProvider>
        <RefreshLayout
          groups={groups}
          metrics={metrics}
          projects={projects}
          welcome={welcome}
          refreshUi={refreshUi}
        />
      </TooltipProvider>
    ) : (
      <Layout
        groups={groups}
        metrics={metrics}
        onClickNewProject={onClickNewProject}
        projects={projects}
        welcome={welcome}
        refreshUi={refreshUi}
      />
    )
  );
};
