/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8pprv_157 {
  display: flex;
}

._justify-content-space-between_8pprv_161 {
  justify-content: space-between;
}

._tabular-nums_8pprv_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8pprv_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._budgetLogo_8pprv_178 {
  height: 2rem;
  width: 2rem;
}

._accountsTable_8pprv_183 {
  overflow: auto;
}
._accountsTable_8pprv_183 .ant-table-head ._ant-table-column-sort_8pprv_186 {
  padding-top: 0.625rem;
}

._ant-table-body_8pprv_190::-webkit-scrollbar {
  width: 2px;
}

._ant-table-body_8pprv_190::-webkit-scrollbar-thumb {
  background-color: #888;
}

._ant-table-body_8pprv_190::-webkit-scrollbar-track {
  background: #f1f1f1;
}

._iconContainer_8pprv_202 {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: #5DB884;
}

._sackDollar_8pprv_212 {
  color: #FFFFFF;
  font-size: 1rem;
}

._accountsTableRow_8pprv_217 {
  height: 3.125rem;
}
._accountsTableRow_8pprv_217 .ant-table-cell {
  padding: 0.5rem 1rem;
}

._accountsTableWrapperCard_8pprv_224 {
  margin: 1.5rem;
  padding: 1rem;
}

._quarter_8pprv_229 {
  padding: 0 !important;
}

._quarter_8pprv_229 {
  border-left: 1px solid #F0F0F0;
}

._quarter_8pprv_229:last-child {
  border-right: 1px solid #F0F0F0;
}

._quarterAmount_8pprv_241 {
  border-left: 1px solid #F0F0F0;
}

._quarterAmount_8pprv_241:last-child {
  border-right: 1px solid #F0F0F0;
}

._quarterAmount_8pprv_241 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  background: #F0F0F0;
}

._quarterAmountCell_8pprv_255 {
  background: #F0F0F0;
}

._budgetNameColumn_8pprv_259 {
  min-width: 25rem;
  max-width: 25rem;
}

._sourceBudgetColumn_8pprv_264 {
  min-width: 18.75rem;
  max-width: 18.75rem;
}

._amountColumn_8pprv_269 {
  min-width: 14.0625rem;
  max-width: 14.0625rem;
}

._loadSpinner_8pprv_274 {
  margin-top: 20%;
}