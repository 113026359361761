import * as React from 'react';

import { Checkbox, Form } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../types';

import styles from '../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'specialLimitNewCustomersOnly'> { }

export const OfferCodeLimitNewCustomersOnly: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => (
  // the label has to be outside of the AUIForm.Item because we don't want the user
  // clicking the label to trigger the checkbox
  <>
    <Form.Item className={styles.halfRow} name={name} valuePropName="checked">
      <Checkbox disabled={disabled}>
        Limit usage to new customers only
      </Checkbox>
    </Form.Item>
    {
      disabled // TODO: Don't show this in edit mode!
      && (
        <label className="ant-form-item-label">
          Note: This option is only available with the "Shopify Promo Code User Segmentation" credentials.
          Please try re-authing Shopify if you would like to enable this option.
        </label>
      )
    }
  </>
));

OfferCodeLimitNewCustomersOnly.displayName = 'OfferCodeLimitNewCustomersOnly';
