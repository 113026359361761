import { isNull, values } from 'lodash';
import { RawDraftContentState } from 'draft-js';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { creatorPortalDomain } from '@frontend/applications/Shared/serviceHosts';
import { useGetPfaComposerMemberFields, MEMBER_FIRST_NAME, IVariable } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetPfaComposerMemberFields';

export default (): [string | null, RawDraftContentState | null] => {
  const memberFieldIds = useGetPfaComposerMemberFields();
  if (isNull(memberFieldIds)) {
    return [null, null];
  }

  const { workflowActionParameters } = useApplication();
  const { programName, programId } = workflowActionParameters;

  const creatorPortalDomainLink = `${creatorPortalDomain()}/programs/${programId}`;

  const variables: IVariable = {
    firstName: {
      field: `MEMBER_FIELDS.${memberFieldIds[MEMBER_FIRST_NAME]}`,
      key: 'FIRST NAME',
      label: 'First Name',
    },
  };

  const blocks: RawDraftContentState['blocks'] = [
    {
      key: '8an9f',
      text: `Hello ${variables.firstName.label},`,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [
        {
          offset: 6,
          length: variables.firstName.label.length,
          key: variables.firstName.key,
        },
      ],
      data: {},
    },
    {
      key: '34ho3',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '1dgtl',
      text: `I'm reaching out because you can now select the product(s) you would like for the collaboration ${programName}.`,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '5d8tl',
      text: `Make your product selections here: ${creatorPortalDomainLink}`,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'd1t0p',
      text: 'Instructions: From the link above, click on the Select Products tab under your project tasks, and then pick the products you want to submit your request. To ensure you select the best item(s) for this project, we review every request carefully and will be in touch after the review.',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [
        {
          style: 'BOLD',
          offset: 0,
          length: 12,
        },
      ],
      entityRanges: [],
      data: {},
    },
  ];

  const entityMap: RawDraftContentState['entityMap'] = {};

  for (const variable of values(variables)) {
    entityMap[variable.key] = {
      type: 'variable',
      mutability: 'IMMUTABLE',
      data: {
        data: {
          field: variable.field,
        },
      },
    };
  }
  return ['Please select your products', { blocks, entityMap }];
};
