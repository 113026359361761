import gql from 'graphql-tag';
import { PROGRAM_FRAGMENT } from './fragments/ProgramFragment';

export const DELETE_PROGRAM_MUTATION = gql`
  mutation DeleteProgramMutation($id: Int!) {
    deleted: deleteProgramById(id: $id)
  }
`;

export const ARCHIVE_PROGRAM_MUTATION = gql`
  mutation ArchiveProgramMutation($id: Int!) {
    program: archiveProgramById(id: $id) {
      ...ProgramFragment
    }
  }
  ${PROGRAM_FRAGMENT}
`;
