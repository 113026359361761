import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';
import { InventorySource } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { IMetaField } from '../types';

export interface SelectedLocation {
  id: string;
  name: string;
}
export interface ImportSettingsContextProps {
  autoSyncFromShopify?: boolean;
  inventorySource: InventorySource;
  selectedLocations: SelectedLocation[];
  stockAvailabilityCheck: boolean;
  maintainMinimumInventory: boolean;
  minimumStockLevel: number;
  collectionMetaFields: IMetaField[];
  productMetaFields: IMetaField[];
  variantMetaFields: IMetaField[];
  showMetaFieldsValidationWarning: boolean;
  setAutoSyncFromShopify?: (autoSyncFromShopify: boolean) => void;
  setSelectedLocations: (locations: SelectedLocation[]) => void;
  setInventorySource: (inventorySource: InventorySource) => void;
  setStockAvailabilityCheck: (stockAvailabilityCheck: boolean) => void;
  setState: (state: Partial<ImportSettingsContextState>) => void;
  setMaintainMinimumInventory: (maintainMinimumInventory: boolean) => void;
  setMinimumStockLevel: (minimumStockLevel: number) => void;
  addCollectionMetaField: (field: IMetaField) => void;
  addProductMetaField: (field: IMetaField) => void;
  addVariantMetaField: (field: IMetaField) => void;
  removeCollectionMetaField: (fieldId: string) => void;
  removeProductMetaField: (fieldId: string) => void;
  removeVariantMetaField: (fieldId: string) => void;
  setMetaFieldById: (fieldId: string, field: IMetaField) => void;
  setShowMetaFieldsValidationWarning: (showMetaFieldsValidationWarning: boolean) => void;
}

export type ImportSettingsContextState = Omit<
  ImportSettingsContextProps,
  | 'setSelectedLocations'
  | 'setInventorySource'
  | 'setStockAvailabilityCheck'
  | 'setState'
  | 'setMaintainMinimumInventory'
  | 'setMinimumStockLevel'
  | 'setAutoSyncFromShopify'
  | 'addCollectionMetaField'
  | 'addProductMetaField'
  | 'addVariantMetaField'
  | 'removeCollectionMetaField'
  | 'removeProductMetaField'
  | 'removeVariantMetaField'
  | 'setMetaFieldById'
  | 'setShowMetaFieldsValidationWarning'
>;

const initialState: ImportSettingsContextState = {
  inventorySource: InventorySource.ONLINE_SELLABLE_INVENTORY,
  selectedLocations: [],
  stockAvailabilityCheck: false,
  maintainMinimumInventory: false,
  minimumStockLevel: 0,
  autoSyncFromShopify: false,
  collectionMetaFields: [],
  productMetaFields: [],
  variantMetaFields: [],
  showMetaFieldsValidationWarning: false,
};

export const ImportSettingsContext = createContext<ImportSettingsContextProps>({
  ...initialState,
  setSelectedLocations: () => {},
  setInventorySource: () => {},
  setStockAvailabilityCheck: () => {},
  setState: () => {},
  setMaintainMinimumInventory: () => {},
  setMinimumStockLevel: () => {},
  setAutoSyncFromShopify: () => {},
  addCollectionMetaField: () => {},
  addProductMetaField: () => {},
  addVariantMetaField: () => {},
  removeCollectionMetaField: () => {},
  removeProductMetaField: () => {},
  removeVariantMetaField: () => {},
  setMetaFieldById: () => {},
  setShowMetaFieldsValidationWarning: () => {},
});

export enum ActionType {
  SetInventorySource = 'SetInventorySource',
  SetSelectedLocations = 'SetSelectedLocations',
  SetStockAvailabilityCheck = 'SetStockAvailabilityCheck',
  SetState = 'SetState',
  SetMaintainMinimumInventory = 'SetMaintainMinimumInventory',
  SetMinimumStockLevel = 'SetMinimumStockLevel',
  SetAutoSyncFromShopify = 'SetAutoSyncFromShopify',
  AddCollectionMetaField = 'AddCollectionMetaField',
  AddProductMetaField = 'AddProductMetaField',
  AddVariantMetaField = 'AddVariantMetaField',
  RemoveCollectionMetaField = 'RemoveCollectionMetaField',
  RemoveProductMetaField = 'RemoveProductMetaField',
  RemoveVariantMetaField = 'RemoveVariantMetaField',
  SetMetaFieldById = 'SetMetaFieldById',
  SetShowMetaFieldsValidationWarning = 'SetShowMetaFieldsValidationWarning',
}

export type Action =
  | { type: ActionType.SetInventorySource; payload: InventorySource }
  | { type: ActionType.SetSelectedLocations; payload: SelectedLocation[] }
  | { type: ActionType.SetState; payload: Partial<ImportSettingsContextState> }
  | { type: ActionType.SetStockAvailabilityCheck; payload: boolean }
  | { type: ActionType.SetMaintainMinimumInventory; payload: boolean }
  | { type: ActionType.SetMinimumStockLevel; payload: number }
  | { type: ActionType.SetAutoSyncFromShopify; payload: boolean }
  | { type: ActionType.AddCollectionMetaField; payload: IMetaField }
  | { type: ActionType.AddProductMetaField; payload: IMetaField }
  | { type: ActionType.AddVariantMetaField; payload: IMetaField }
  | { type: ActionType.RemoveCollectionMetaField; payload: string }
  | { type: ActionType.RemoveProductMetaField; payload: string }
  | { type: ActionType.RemoveVariantMetaField; payload: string }
  | { type: ActionType.SetMetaFieldById; payload: { fieldId: string; field: IMetaField } }
  | { type: ActionType.SetShowMetaFieldsValidationWarning; payload: boolean };

export const ImportSettingsProvider: React.FC = ({ children }) => {
  const reducer = (state: ImportSettingsContextState, action: Action): ImportSettingsContextState => {
    switch (action.type) {
      case ActionType.SetInventorySource:
        return { ...state, inventorySource: action.payload };
      case ActionType.SetSelectedLocations:
        return { ...state, selectedLocations: action.payload };
      case ActionType.SetState:
        return { ...state, ...action.payload };
      case ActionType.SetStockAvailabilityCheck:
        return { ...state, stockAvailabilityCheck: action.payload };
      case ActionType.SetMaintainMinimumInventory:
        return { ...state, maintainMinimumInventory: action.payload };
      case ActionType.SetMinimumStockLevel:
        return { ...state, minimumStockLevel: action.payload };
      case ActionType.SetAutoSyncFromShopify:
        return { ...state, autoSyncFromShopify: action.payload };
      case ActionType.AddCollectionMetaField:
        return { ...state, collectionMetaFields: [...state.collectionMetaFields, action.payload] };
      case ActionType.AddProductMetaField:
        return { ...state, productMetaFields: [...state.productMetaFields, action.payload] };
      case ActionType.AddVariantMetaField:
        return { ...state, variantMetaFields: [...state.variantMetaFields, action.payload] };
      case ActionType.RemoveCollectionMetaField:
        return { ...state, collectionMetaFields: state.collectionMetaFields.filter((field) => field.id !== action.payload) };
      case ActionType.RemoveProductMetaField:
        return { ...state, productMetaFields: state.productMetaFields.filter((field) => field.id !== action.payload) };
      case ActionType.RemoveVariantMetaField:
        return { ...state, variantMetaFields: state.variantMetaFields.filter((field) => field.id !== action.payload) };
      case ActionType.SetMetaFieldById:
        return {
          ...state,
          collectionMetaFields: state.collectionMetaFields.map((field) => (field.id === action.payload.fieldId ? action.payload.field : field)),
          productMetaFields: state.productMetaFields.map((field) => (field.id === action.payload.fieldId ? action.payload.field : field)),
          variantMetaFields: state.variantMetaFields.map((field) => (field.id === action.payload.fieldId ? action.payload.field : field)),
        };
      case ActionType.SetShowMetaFieldsValidationWarning:
        return { ...state, showMetaFieldsValidationWarning: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setSelectedLocations: (locations: SelectedLocation[]) => dispatch({ type: ActionType.SetSelectedLocations, payload: locations }),
      setInventorySource: (inventorySource: InventorySource) => dispatch({ type: ActionType.SetInventorySource, payload: inventorySource }),
      setState: (newState: Partial<ImportSettingsContextState>) => dispatch({ type: ActionType.SetState, payload: newState }),
      setStockAvailabilityCheck: (stockAvailabilityCheck: boolean) =>
        dispatch({ type: ActionType.SetStockAvailabilityCheck, payload: stockAvailabilityCheck }),
      setMaintainMinimumInventory: (maintainMinimumInventory: boolean) =>
        dispatch({ type: ActionType.SetMaintainMinimumInventory, payload: maintainMinimumInventory }),
      setMinimumStockLevel: (minimumStockLevel: number) => dispatch({ type: ActionType.SetMinimumStockLevel, payload: minimumStockLevel }),
      setAutoSyncFromShopify: (autoSyncFromShopify: boolean) =>
        dispatch({ type: ActionType.SetAutoSyncFromShopify, payload: autoSyncFromShopify }),
      addCollectionMetaField: (field: IMetaField) => dispatch({ type: ActionType.AddCollectionMetaField, payload: field }),
      addProductMetaField: (field: IMetaField) => dispatch({ type: ActionType.AddProductMetaField, payload: field }),
      addVariantMetaField: (field: IMetaField) => dispatch({ type: ActionType.AddVariantMetaField, payload: field }),
      removeCollectionMetaField: (fieldId: string) => dispatch({ type: ActionType.RemoveCollectionMetaField, payload: fieldId }),
      removeProductMetaField: (fieldId: string) => dispatch({ type: ActionType.RemoveProductMetaField, payload: fieldId }),
      removeVariantMetaField: (fieldId: string) => dispatch({ type: ActionType.RemoveVariantMetaField, payload: fieldId }),
      setMetaFieldById: (fieldId: string, field: IMetaField) => dispatch({ type: ActionType.SetMetaFieldById, payload: { fieldId, field } }),
      setShowMetaFieldsValidationWarning: (showMetaFieldsValidationWarning: boolean) =>
        dispatch({ type: ActionType.SetShowMetaFieldsValidationWarning, payload: showMetaFieldsValidationWarning }),
    }),
    [],
  );

  const memoizedValue = useMemo<ImportSettingsContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <ImportSettingsContext.Provider value={memoizedValue}>{children}</ImportSettingsContext.Provider>;
};

export const useImportSettingsContext = () => useContext(ImportSettingsContext);
