/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ooan5_157 {
  display: flex;
}

._justify-content-space-between_ooan5_161 {
  justify-content: space-between;
}

._tabular-nums_ooan5_165, ._detail_ooan5_165._average_engagement_ooan5_165 ._value_ooan5_165, ._detail_ooan5_165._impression_ooan5_165 ._value_ooan5_165, ._detail_ooan5_165._age_range_ooan5_165 ._value_ooan5_165, ._detail_ooan5_165._audience_authenticity_ooan5_165 ._value_ooan5_165, ._detail_ooan5_165._impression_per_story_ooan5_165 ._value_ooan5_165, ._detail_ooan5_165 ._value_ooan5_165, ._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._detail_ooan5_165 ._title_ooan5_170::selection, ._detail_ooan5_165 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_ooan5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._detail_ooan5_165 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._detail_ooan5_165 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._detail_ooan5_165 ::-webkit-scrollbar-track, ._detail_ooan5_165 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._detail_ooan5_165 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_ooan5_165,
._audienceAgeBarChart_ooan5_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_ooan5_165 > [class*=list],
._audienceAgeBarChart_ooan5_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_ooan5_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_ooan5_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_ooan5_302 {
  cursor: help;
}

._detail_ooan5_165 {
  display: grid;
  grid-template-columns: 0 1fr;
  grid-template-rows: repeat(2, 1rem);
  grid-column-gap: 0.625rem;
  align-content: center;
}
._detail_ooan5_165 ._icon_ooan5_313 {
  grid-column: 1/2;
  grid-row: 1/2 span;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #eff5f9;
  border-radius: 0.5rem;
}
._detail_ooan5_165 ._title_ooan5_170 {
  grid-column: 2;
  grid-row: 1;
  color: #767378;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.8rem;
  letter-spacing: 0.03125rem;
}
._detail_ooan5_165 ._value_ooan5_165 {
  font-weight: 500;
  line-height: 1rem;
}
._detail_ooan5_165._horizontal_ooan5_335 {
  grid-template-columns: 0 1fr 4rem;
  grid-template-rows: 2rem;
}
._detail_ooan5_165._horizontal_ooan5_335 ._title_ooan5_170,
._detail_ooan5_165._horizontal_ooan5_335 ._value_ooan5_165 {
  display: flex;
  align-items: center;
}
._detail_ooan5_165._horizontal_ooan5_335 ._value_ooan5_165 {
  justify-content: flex-end;
  text-align: right;
}
._detail_ooan5_165._audience_authenticity_ooan5_165 ._value_ooan5_165 strong {
  margin-left: 0.25rem;
  font-weight: 600;
}
._detail_ooan5_165._gender_ooan5_352 {
  text-transform: capitalize;
}
._detail_ooan5_165._dislikes_per_video_ooan5_355 ._icon_ooan5_313 svg {
  transform: scaleY(-1);
}

._high_ooan5_359 {
  color: #4eb468;
}

._good_ooan5_363 {
  color: #3996e0;
}

._suspicious_ooan5_367,
._low_ooan5_368 {
  color: #da3737;
}

._positive_ooan5_372 {
  color: #4eb468;
}

._neutral_ooan5_376 {
  color: #5c5a5e;
}

._negative_ooan5_380 {
  color: #da3737;
}

._reviewedIcon_ooan5_384 {
  margin-right: 0.25rem;
  fill: #3996e0;
  vertical-align: bottom;
}