import * as React from 'react';
import { Button, IButtonProps } from '@revfluence/fresh';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

export const ReLinkAccountButton = (props: IButtonProps) => {
  const OAUTH_TYPE = 'aspirex_add_brand_account_mentions';
  const addEvent = useEventContext();
  const { backendServerWebEndpoint, clientId } = useApplication();
  const oauthEndpoint = `${backendServerWebEndpoint}brands/${clientId}/facebook/aspirex_oauth`;
  const redirect = window.location.href;
  const directOauthEndpoint = `${oauthEndpoint}?oauth_type=${OAUTH_TYPE}&redirect=${redirect}`;

  return (
    <Button
      {...props}
      type="primary"
      ghost
      href={directOauthEndpoint}
      onClick={() => {
        addEvent(EventName.OAuthGrantStart, { app: 'social_post' });
        window?.localStorage?.removeItem('ig_connect_success_track');
      }}
    >
      Re-link Account
    </Button>
  );
};
