import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_COLLABORATION_DETAILS_FOR_PROJECT_QUERY } from '@frontend/app/queries';
import { GetCollaborationDetailsForProjectQuery, GetCollaborationDetailsForProjectQueryVariables } from '../queries/types/GetCollaborationDetailsForProjectQuery';

type IOptions = QueryHookOptions<GetCollaborationDetailsForProjectQuery, GetCollaborationDetailsForProjectQueryVariables>;

export const useGetCollaborationDetailsForProject = (options: IOptions = {}) => (
  useQuery<GetCollaborationDetailsForProjectQuery, GetCollaborationDetailsForProjectQueryVariables>(
    GET_COLLABORATION_DETAILS_FOR_PROJECT_QUERY,
    options,
));
