import { Avatar, AvatarFallback } from '@frontend/shadcn/components/ui/avatar';
import { TabComponent } from '@frontend/app/refresh-components';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@frontend/shadcn/components/ui/select';
import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useGetBudgetsForClientQuery, useGetDashboardConfig, useProgramsQuery } from '@frontend/app/hooks';
import { ToggleGroup, ToggleGroupItem } from '@frontend/shadcn/components/ui/toggle-group';
import { useGetBudgetFiscalSettings } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalSettings';
import { useFetchPaymentSourceData } from '@frontend/applications/PaymentsApp/useFetchPaymentSources';
import { useAuth } from '@frontend/context/authContext';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { LoadSpinner } from '@components';
import { PAYMENT_APP_ID } from '@frontend/app/constants/applicationIds';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from '@frontend/shadcn/components/ui/tooltip';
import { ArrowUpRightFromSquareIcon, BuildingColumnsIcon } from '@revfluence/fresh-icons/solid/esm';
import { BudgetTab } from './BudgetTab';
import { BudgetDetailedView } from './BudgetDetailedView';
import { PaymentHistoryTab } from './PaymentHistoryTab';
import { ProjectTab } from './ProjectTab';

enum BudgetDashboardTabEnum {
  Budgets = 'Budgets',
  Projects = 'Projects',
  DetailedView = 'DetailedView',
  PaymentHistory = 'PaymentHistory',
}
type FiscalYearOption = {
  id: string;
  name: string;
};
export type ProjectFilterOption = {
  id: number;
  name: string;
  programImage: string;
};
type BudgetFilterOption = {
  id: number;
  name: string;
  isChildBudget: boolean;
  subOptions?: BudgetFilterOption[];
};
type FilterOptions = {
  Budgets: {
    budgetOptions: BudgetFilterOption[];
    fiscalYears: FiscalYearOption[];
  };
  Projects: {
    projectsOptions: ProjectFilterOption[];
    fiscalYears: FiscalYearOption[];
  };
  BudgetDetailed: {
    budgetType: string[];
    fiscalYears: FiscalYearOption[];
  };
};

export const BudgetDashboard: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currentTab = (new URLSearchParams(location.search).get('tab') as BudgetDashboardTabEnum) || BudgetDashboardTabEnum.Budgets;
  const [activeTab, setActiveTab] = useState<BudgetDashboardTabEnum>(currentTab);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    Budgets: {
      budgetOptions: [],
      fiscalYears: [],
    },
    Projects: { projectsOptions: [], fiscalYears: [] },
    BudgetDetailed: {
      budgetType: ['Budget', 'Project'],
      fiscalYears: [],
    },
  });
  const [filters, setFilters] = useState({
    Budgets: { budgetId: null, fiscalYear: '' },
    Projects: { projectId: null, fiscalYear: '' },
    BudgetDetailed: { budgetType: 'Budget', fiscalYear: '' },
  });
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [dashboardConfg, setDashboardConfg] = useState<any>(null);
  const { clientInfo, loading: isAuthDataLoading } = useAuth();
  const { data: dashboardConfigData, loading: isDashboardConfigLoading } = useGetDashboardConfig();
  const { data: budgetList, loading: isFetchingBudgetsLoading } = useGetBudgetsForClientQuery();
  const { budgetSettingsResponse, loading: isLoadingBudgetFiscalYears } = useGetBudgetFiscalSettings({});
  const { loading: isPaymentsSourceLoading, data: paymentSources } = useFetchPaymentSourceData({
    backendServerApiEndpoint: backendServerApiEndpoint(),
    clientId: clientInfo.id,
  });
  const { data: programs, loading: isProgramsLoading } = useProgramsQuery();

  const tabs = useMemo(() => {
    const isChildBudgetSelected = filterOptions?.Budgets?.budgetOptions?.some((budget) =>
      (budget.id === filters?.Budgets?.budgetId && budget.isChildBudget)
      || (budget.subOptions?.some((b) => b.id === filters?.Budgets?.budgetId && b.isChildBudget))) || false;
    return [
      {
        name: BudgetDashboardTabEnum.Budgets,
        label: 'Budgets',
        content: (
          <BudgetTab
            fiscalYear={filters.Budgets.fiscalYear}
            budgetId={filters.Budgets.budgetId}
            dashboardConfg={dashboardConfg}
            isChildBudget={isChildBudgetSelected}
          />
        ),
      },
      {
        name: BudgetDashboardTabEnum.Projects,
        label: 'Projects',
        content: (
          <ProjectTab
            projects={filterOptions.Projects.projectsOptions}
            fiscalYear={filters.Projects.fiscalYear}
            projectId={filters.Projects.projectId}
            dashboardConfg={dashboardConfg}
          />
        ),
      },
      {
        name: BudgetDashboardTabEnum.DetailedView,
        label: 'Detailed View',
        content: (
          <BudgetDetailedView
            source={filters.BudgetDetailed.budgetType}
            fiscalYear={filters.BudgetDetailed.fiscalYear}
          />
        ),
      },
      {
        name: BudgetDashboardTabEnum.PaymentHistory,
        label: 'Payment History',
        content: <PaymentHistoryTab />,
      },
    ];
  }, [filters, dashboardConfg, filterOptions]);

  useEffect(() => {
    if (!isLoadingBudgetFiscalYears && budgetSettingsResponse.fiscalYears.length) {
      const fiscalYears = budgetSettingsResponse.fiscalYears
        .sort((a, b) => b.year - a.year)
        .map((year) => ({
          id: year.year,
          name: year.yearName,
        }));

      const currentFiscalYear = budgetSettingsResponse.fiscalYears.find((year) => year.isCurrentYear)?.year;

      const updateFilterOptions = (prevOptions) => ({
        ...prevOptions,
        BudgetDetailed: { ...prevOptions.BudgetDetailed, fiscalYears },
        Budgets: { ...prevOptions.Budgets, fiscalYears },
        Projects: { ...prevOptions.Projects, fiscalYears },
      });

      const updateFilters = (prevFilters) => ({
        ...prevFilters,
        BudgetDetailed: { ...prevFilters.BudgetDetailed, fiscalYear: currentFiscalYear },
        Budgets: { ...prevFilters.Budgets, fiscalYear: currentFiscalYear },
        Projects: { ...prevFilters.Projects, fiscalYear: currentFiscalYear },
      });

      setFilterOptions(updateFilterOptions);
      setFilters(updateFilters);
    }
  }, [isLoadingBudgetFiscalYears, budgetSettingsResponse]);

  useEffect(() => {
    if (!isFetchingBudgetsLoading && budgetList?.budgets.length) {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        Budgets: {
          ...prevFilterOptions.Budgets,
          budgetOptions: budgetList.budgets.map((budget) => ({
            id: budget.budgetId,
            name: budget.budgetName,
            isChildBudget: false,
            subOptions: !budget.childBudgetsList?.length
              ? []
              : budget.childBudgetsList.map((childBudgets) => ({
                  id: childBudgets.budgetId,
                  name: childBudgets.budgetName,
                  isChildBudget: true,
                  subOptions: [],
                })),
          })),
        },
      }));

      setFilters((prevFilters) => ({
        ...prevFilters,
        Budgets: {
          ...prevFilters.Budgets,
          budgetId: budgetList.budgets[0].budgetId,
        },
      }));
    }
  }, [isFetchingBudgetsLoading, budgetList]);

  useEffect(() => {
    if (!isProgramsLoading && programs?.programs?.length) {
      setFilterOptions((prevFilterOptions) => ({
        ...prevFilterOptions,
        Projects: {
          ...prevFilterOptions.Projects,
          projectsOptions: programs.programs.map((program) => ({
            id: program.id,
            name: program.title,
            programImage: program.splashImageUrl,
          }))
          .sort((firstProgram, secondProgram) => firstProgram.name.localeCompare(secondProgram.name)),
        },
      }));

      setFilters((prevFilters) => ({
        ...prevFilters,
        Projects: {
          ...prevFilters.Projects,
          projectId: programs?.programs[0].id,
        },
      }));
    }
  }, [programs, isProgramsLoading]);

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  const handleTabChange = (tabName: BudgetDashboardTabEnum) => {
    // Create a new URLSearchParams object based on the current location
    const params = new URLSearchParams(location.search);

    // Set the active tab in the query parameters
    params.set('tab', tabName);

    // Clear query parameters for the other tabs (besides the active tab)
    Object.keys(filters).forEach((tab) => {
      if (tab !== tabName) {
        Object.keys(filters[tab]).forEach((filterKey) => {
          params.delete(`${tab}_${filterKey}`);
        });
      }
    });

    // Update the browser URL without a full reload
    history.push({ search: params.toString() });

    // Update the active tab state
    setActiveTab(tabName);
  };

  const handleFilterChange = (tabName: string, filterName: string, filterValue: string | number) => {
    const updatedFilters = {
      ...filters,
      [tabName]: {
        ...filters[tabName],
        [filterName]: filterValue,
      },
    };
    setFilters(updatedFilters);

    const params = new URLSearchParams(location.search);
    Object.entries(updatedFilters[tabName]).forEach(([key, value]) => {
      if (value) {
        params.set(`${tabName}_${key}`, value as string);
      } else {
        params.delete(`${tabName}_${key}`);
      }
    });
    history.push({ search: params.toString() });
  };

  const renderFilter = () => {
    switch (activeTab) {
      case BudgetDashboardTabEnum.Budgets:
        return (
          <div className="flex gap-2">
            <div className="bg-white rounded-lg overflow-hidden">
              <Select
                value={filters.Budgets?.budgetId?.toString()}
                onValueChange={(value) => handleFilterChange('Budgets', 'budgetId', parseInt(value, 10))}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select Budget" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filterOptions.Budgets.budgetOptions.map((budget) => (
                      <div key={budget.id}>
                        <SelectItem value={budget.id.toString()}>{budget.name}</SelectItem>
                        {budget.subOptions.length > 0 && (
                          <div className="ml-4">
                            {budget.subOptions.map((subOption) => (
                              <SelectItem key={subOption.id} value={subOption.id.toString()}>
                                {subOption.name}
                              </SelectItem>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="bg-white rounded-lg overflow-hidden">
              <Select
                value={filters.Budgets.fiscalYear}
                onValueChange={(value) => handleFilterChange('Budgets', 'fiscalYear', value)}
              >
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Select FY" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filterOptions.Budgets.fiscalYears.map((year) => (
                      <SelectItem key={year.id} value={year.id}>
                        {year.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        );
      case BudgetDashboardTabEnum.Projects:
        return (
          <div className="flex gap-2">
            <div className="bg-white rounded-lg overflow-hidden">
              <Select
                value={filters.Projects?.projectId?.toString()}
                onValueChange={(value) => handleFilterChange('Projects', 'projectId', parseInt(value, 10))}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select Program" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filterOptions.Projects.projectsOptions.map((program) => (
                      <div key={program.id}>
                        <SelectItem value={program.id.toString()}>
                          {program.name}
                        </SelectItem>
                      </div>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="bg-white rounded-lg overflow-hidden">
              <Select
                value={filters.Projects.fiscalYear}
                onValueChange={(value) => handleFilterChange('Projects', 'fiscalYear', value)}
              >
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Select FY" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filterOptions.Projects.fiscalYears.map((year) => (
                      <SelectItem key={year.id} value={year.id}>
                        {year.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        );
      case BudgetDashboardTabEnum.DetailedView:
        return (
          <div className="flex gap-2">
            <div className="bg-white rounded-lg overflow-hidden">
              <ToggleGroup
                className="border border-gray-300 rounded-lg overflow-hidden inline-flex"
                type="single"
                value={filters.BudgetDetailed.budgetType}
                onValueChange={(value) => value && handleFilterChange('BudgetDetailed', 'budgetType', value)}
              >
                <ToggleGroupItem value="Budget">Budgets</ToggleGroupItem>
                <ToggleGroupItem value="Project">Projects</ToggleGroupItem>
              </ToggleGroup>
            </div>
            <div className="bg-white rounded-lg overflow-hidden">
              <Select
                value={filters.BudgetDetailed.fiscalYear}
                onValueChange={(value) => handleFilterChange('BudgetDetailed', 'fiscalYear', value)}
              >
                <SelectTrigger className="w-[120px]">
                  <SelectValue placeholder="Select FY" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {filterOptions.BudgetDetailed.fiscalYears.map((year) => (
                      <SelectItem key={year.id} value={year.id}>
                        {year.name}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        );
      case BudgetDashboardTabEnum.PaymentHistory:
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isDashboardConfigLoading) {
      setDashboardConfg(dashboardConfigData?.dashboardConfig?.dashboardConfig);
    }
  }, [isDashboardConfigLoading, dashboardConfigData]);

  const balance = useMemo(() => {
    const balanceInCents = isPaymentsSourceLoading || !paymentSources?.balance_in_cents ? 0 : paymentSources.balance_in_cents;

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(balanceInCents / 100);
  }, [isPaymentsSourceLoading, paymentSources]);
  const loading = isDashboardConfigLoading
    || isLoadingBudgetFiscalYears
    || isFetchingBudgetsLoading
    || isPaymentsSourceLoading
    || isAuthDataLoading
    || isProgramsLoading;

  if (loading) {
    return (
      <div className="h-full relative bg-secondary">
        <LoadSpinner />
      </div>
    );
  }
  return (
    <TooltipProvider>
      <div className="h-full relative bg-secondary shadcn">
        <div className="w-full h-[140px] bg-primary px-8">
          <div className="flex justify-between items-center pt-9">
            <div className="flex justify-between items-center gap-3">
              <Avatar className="h-[45px] w-[45px] rounded-none">
                <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-[#4E7E7E] text-[20px] text-secondary">
                  <BuildingColumnsIcon />
                </AvatarFallback>
              </Avatar>
              <p className="text-secondary text-3xl font-bold m-0">
                {tabs.find((tab) => tab.name === activeTab)?.label}
              </p>
            </div>
            <div className="flex justify-between items-center text-white">
              <div className="text-sm m-0 flex items-center">
                <span>Available Balance:</span>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link to={`/settings/${PAYMENT_APP_ID}`} className="text-white flex gap-2 items-center flex-row">
                      <span className="underline ml-2">{balance}</span>
                      <ArrowUpRightFromSquareIcon />
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent>Payments Settings</TooltipContent>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-2 absolute top-[95px] w-full">
          <TabComponent tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} filter={renderFilter()} />
        </div>
      </div>
    </TooltipProvider>
  );
};
