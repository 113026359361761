import 'css-chunk:src/app/containers/MemberDetail/components/MemberThreads/Thread.scss';export default {
  "Thread": "_Thread_ovxvz_1",
  "content": "_content_ovxvz_7",
  "mainMessage": "_mainMessage_ovxvz_17",
  "subject": "_subject_ovxvz_24",
  "snippet": "_snippet_ovxvz_28",
  "reply": "_reply_ovxvz_35",
  "replyContent": "_replyContent_ovxvz_42",
  "replySnippet": "_replySnippet_ovxvz_51",
  "replyDate": "_replyDate_ovxvz_52",
  "messageHTMLContent": "_messageHTMLContent_ovxvz_57",
  "composerContainer": "_composerContainer_ovxvz_62",
  "composer": "_composer_ovxvz_62",
  "emailComposer": "_emailComposer_ovxvz_73",
  "hide": "_hide_ovxvz_76",
  "replyButton": "_replyButton_ovxvz_79",
  "mainDate": "_mainDate_ovxvz_82",
  "callToAction": "_callToAction_ovxvz_86"
};