import * as React from 'react';
import { PercentagePanel } from '@frontend/app/components/PercentagePanel/PercentagePanel';
import { useGetCurrentUsageQuery } from '@frontend/app/hooks';
import { UpgradeButton } from '@frontend/app/components';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { UsageEntity } from '@frontend/context/MessagingContext';
import styles from './PlanDetailSettings.scss';
import { planDetailsSettingText } from './constants';

export const PlanDetailSettings = React.memo(() => {
  const { state } = useLocation<{ upgradeReason: UsageEntity }>();

  const { data: { currentUsage } = {}, loading: isLoading } = useGetCurrentUsageQuery();

  if (isLoading && isEmpty(currentUsage)) return null;

  return (
    <div className={styles.PlanDetailSettings}>
      <div className={styles.header}>
        <div className={styles.title}>Plan Details</div>
        {state?.upgradeReason && <UpgradeButton reason={state?.upgradeReason} />}
      </div>
      {Object.keys(planDetailsSettingText).map((plan) => {
        const limitText = planDetailsSettingText[plan as keyof typeof planDetailsSettingText];

        const response = currentUsage?.entities[plan as keyof typeof currentUsage.entities];
        const canGetLimit = typeof response === 'object' && response !== null;
        const { current = 0, limit = 0 } = canGetLimit ? response : { current: 0, limit: 0 };

        return (
          <PercentagePanel
            key={limitText.name}
            currentCount={current}
            totalCount={limit}
            title={limitText.title}
            description={limitText.description}
            infoName={limitText.name}
            className={styles.planItem}
          />
        );
      })}
    </div>
  );
});

PlanDetailSettings.displayName = 'PlanDetailSettings';
