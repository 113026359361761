import * as React from 'react';
import cx from 'classnames';
import { map, capitalize, isFunction } from 'lodash';

import { Tooltip } from 'antd';
import { EditorState } from 'draft-js';

import { LinkSimpleIcon, PaperclipIcon } from '@revfluence/fresh-icons/solid/esm';
import { TMediaType } from './types';

import styles from './MediaTypes.module.scss';

const MEDIA_TYPE_ICON: {
  [key in TMediaType]: React.ReactNode;
} = {
  link: <LinkSimpleIcon />,
  attachment: <PaperclipIcon />,
};
const MEDIA_TYPE_HOTKEY: {
  [key in TMediaType]: string;
} = {
  link: '⌘K',
  attachment: '⌘F',
};

interface IProps {
  editorState: EditorState;
  getSelectedEntityType(): string;
  toggleMediaType(type: TMediaType, ref?: React.RefObject<HTMLInputElement>): void;
  onFilesSelected(files: FileList): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const MediaTypes: React.FC<IProps> = React.memo((props) => {
  const inputRef = React.useRef();
  const { getSelectedEntityType } = props;
  const selectedEntityType = getSelectedEntityType();

  const handleSelectedFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    props.onFilesSelected(files);

    event.target.value = '';
  };

  const filteredTypes = React.useMemo(() => {
    const types: TMediaType[] = ['link'];

    if (isFunction(props.onFilesSelected)) {
      types.push('attachment');
    }

    return types;
  }, [props.onFilesSelected]);

  return (
    <div className={cx(styles.MediaTypes, props.className)}>
      {map(filteredTypes, (type) => {
        const isActive = selectedEntityType === type;

        return (
          <Tooltip
            key={type}
            overlayClassName={styles.Tooltip}
            title={(
              <div className={styles.content}>
                {capitalize(type)}
                <span className={styles.hotkey}>
                  {MEDIA_TYPE_HOTKEY[type]}
                </span>
              </div>
            )}
          >
            <div
              className={cx(styles.item, {
                [styles.active]: isActive,
              })}
              onMouseDown={(event) => {
                event.preventDefault();

                props.toggleMediaType(type, inputRef);
              }}
            >
              {MEDIA_TYPE_ICON[type]}
            </div>
            <input
              className={styles.file}
              type="file"
              ref={inputRef}
              onChange={handleSelectedFilesChange}
            />
          </Tooltip>
        );
      })}
    </div>
  );
});

MediaTypes.displayName = 'MediaTypes';
