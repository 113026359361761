import gql from 'graphql-tag';

export const UPDATE_PROMO_MEMBER_PAYOUT_MUTATION = gql`
  mutation UpdatePayoutMemberPromo($affiliates: [UpdateAffiliatePayoutInput!]!) {
    updatePromoPayoutForAffiliates(affiliates: $affiliates) {
        id
    }
  }
`;

export const UPDATE_LINK_MEMBER_PAYOUT_MUTATION = gql`
  mutation UpdatePayoutMemberLink($affiliates: [UpdateAffiliatePayoutInput!]!) {
  updateLinkPayoutForAffiliates(affiliates: $affiliates) {
        id
    }
  }
`;
