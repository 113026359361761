import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_ALL_RESERVED_TASK_NAMES_QUERY } from '@frontend/app/queries';
import { GetAllReservedTaskNamesQuery } from '@frontend/app/queries/types/GetAllReservedTaskNamesQuery';

type IOptions = QueryHookOptions<GetAllReservedTaskNamesQuery>;

export const useGetAllReservedTaskNamesQuery = (options: IOptions = {}) => (
  useQuery<GetAllReservedTaskNamesQuery>(
    GET_ALL_RESERVED_TASK_NAMES_QUERY,
    options,
  )
);
