import { isEmpty, isString, trim } from 'lodash';

import { OFFER_PROMO_PREFIX_TYPE } from '@affiliates/types/globalTypes';
import { TMember, IAddPromoCodeMembers } from '../types';
import { cleanPromoCode } from './cleanPromoCode';

const getCodePrefix = (
  member: TMember,
  codeGenerationStrategy: IAddPromoCodeMembers['codeGenerationStrategy'],
): string => {
  switch (codeGenerationStrategy) {
    case OFFER_PROMO_PREFIX_TYPE.IG_USERNAME:
      return isString(member.instagramUsername) ? cleanPromoCode(member.instagramUsername) : '';
    case OFFER_PROMO_PREFIX_TYPE.FULL_NAME:
      if (
        isString(member.firstName)
        && !isEmpty(member.firstName)
        && isString(member.lastName)
        && !isEmpty(member.lastName)
      ) {
        return cleanPromoCode(`${member.firstName} ${member.lastName}`);
      }
      return isString(member.name) ? cleanPromoCode(member.name) : '';
    case OFFER_PROMO_PREFIX_TYPE.FIRST_INITIAL_LAST_NAME:
      if (
        isString(member.firstName)
        && !isEmpty(member.firstName)
        && isString(member.lastName)
        && !isEmpty(member.lastName)
      ) {
        return cleanPromoCode(`${member.firstName.charAt(0)}${member.lastName}`);
      }
      return '';
    case OFFER_PROMO_PREFIX_TYPE.FIRST_NAME_LAST_INITIAL:
      if (
        isString(member.firstName)
        && !isEmpty(member.firstName)
        && isString(member.lastName)
        && !isEmpty(member.lastName)
      ) {
        return cleanPromoCode(`${member.firstName}${member.lastName.charAt(0)}`);
      }
      return '';
  }
};

export const generateCodeForMember = (
  member: TMember,
  codeGenerationStrategy: IAddPromoCodeMembers['codeGenerationStrategy'],
  suffix: string,
  prefix: string,
): string => {
  const code = getCodePrefix(member, codeGenerationStrategy);
  return isEmpty(code) ? '' : cleanPromoCode(`${prefix}${code}${trim(suffix)}`);
};
