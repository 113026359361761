import * as React from 'react';
import { format, isSameYear } from 'date-fns';
import {
  Row, Col, Typography,
} from '@revfluence/fresh';
import cx from 'classnames';

import { ILineChartContainerProps, LineChartContainer } from '../containers/Chart/LineChartContainer';
import { ImpactChartLoading, ChartLabelLoading } from '../pages/Reporting/components/PageLoading/PageLoading';
import pageStyles from '../pages/Reporting/Reporting.scss';
import styles from '../pages/Reporting/components/TabContent/TabContent.module.scss';

const { Text } = Typography;

interface IUseImpactChart extends ILineChartContainerProps {
  loading: boolean;
}

export const useImpactChart = ({ loading, ...props }: IUseImpactChart) => {
  if (loading) {
    return <ImpactChartLoading />;
  }

  return (<LineChartContainer {...props} />);
};

export const LabelTitles = {
  0: 'Impact Values and Investment',
  content: 'Content Value and Investment',
  impressions: 'Awareness Value and Investment',
  engagements: 'Engagements Value and Investment',
  sales: 'Sales and Investment and Investment',
};

export interface ILabelProps {
  dateRange: [Date, Date];
  timeLabel: string;
  type: string | undefined;
  isDateFilter?: boolean;
}

export const Label = ({
  dateRange: [startDate, endDate],
  timeLabel,
  type,
  isDateFilter,
}: ILabelProps) => {
  const sameYear = isSameYear(startDate, endDate);
  const timeFormat = 'MMM d';
  const timeFormatWithYear = 'MMM d, yyyy';
  const formattedStartDate = format(startDate, sameYear ? timeFormat : timeFormatWithYear);
  const formattedEndDate = format(endDate, timeFormatWithYear);
  const timeString = `${formattedStartDate} - ${formattedEndDate}`;

  return (
    <Col className={cx(styles.TabContent, pageStyles.chartLabel)} flex={1}>
      <Row justify="center">
        <Text className={styles.chartTitle}>
          {LabelTitles[type] || LabelTitles[0]}
        </Text>
      </Row>
      <Row justify="center">
        <Text className={styles.chartTimeLabel}>
          {isDateFilter ? 'Date Selected' : timeLabel}
          :
          {' '}
          {timeString}
        </Text>
      </Row>
    </Col>
  );
};

interface IUseChartLabel extends ILabelProps {
  loading: boolean;
  isDateFilter?: boolean;
}

export const useChartLabel = ({ loading, ...props }: IUseChartLabel) => {
  if (loading) {
    return <ChartLabelLoading />;
  }

  return (<Label {...props} />);
};
