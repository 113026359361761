import React, { useMemo, useState } from 'react';
import {
 Button, Card, Checkbox, CheckboxChangeEvent, Col, Empty, Input, Modal, Row, Skeleton, Space, Tag, Typography,
} from '@revfluence/fresh';
import {
 CartCircleCheckIcon, MagnifyingGlassIcon, TrashIcon,
 TriangleExclamationIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useParams } from 'react-router-dom';
import { GET_SELECTION_CRITERIA } from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import styles from './SelectionCriteria.scss';
import { useGetSelectionCriteria } from '../hooks/useGetSelectionCriteria';
import { SelectionCriteriaDrawer } from './SelectionCriteriaDrawer';
import { useSelectionCriteriaContext } from './SelectionCriteriaContext';
import { useDeleteSelectionCriteria } from '../hooks/useDeleteSelectionCriteria';

const { Text, Title, Link } = Typography;

const EmptyScreen = () => {
  const { setIsDrawerOpen } = useSelectionCriteriaContext();

  return (
    <div className={styles.emptyScreen}>
      <Empty
        size="large"
        image={<CartCircleCheckIcon />}
        description={(
          <Space direction="vertical" size="middle" align="center">
            <Space direction="vertical" size={0} className={styles.description}>
              <Title level={5}>Add product selection criteria </Title>
              <Text type="secondary">
                You can set specific rules for essential or key items in the catalog. This could mean minimum or maximum
                quantities, special pricing, or prioritization in the selection process.
              </Text>
            </Space>
            <Button onClick={() => setIsDrawerOpen({ isDrawerOpen: true })}>Create a Rule</Button>
          </Space>
        )}
      />
    </div>
  );
};

const CriteriaRow = ({ id, title, conditions }: { id: string; title: string; conditions: number }) => {
  const { setIsDrawerOpen, setCheckedCriteriaIds, checkedCriteriaIds } = useSelectionCriteriaContext();

  const handleOpenDrawer = () => {
    setIsDrawerOpen({ isDrawerOpen: true, selectedCriteriaId: Number(id) });
  };

  const handleCheck = (e: CheckboxChangeEvent) => {
    setCheckedCriteriaIds(Number(id), e.target.checked);
  };

  return (
    <Card>
      <Row align="middle" justify="space-between">
        <Col flex="60%">
          <Row align="middle">
            <Checkbox checked={checkedCriteriaIds.includes(Number(id))} onChange={handleCheck} />
            <Button type="link" onClick={handleOpenDrawer}>
              {title}
            </Button>
          </Row>
        </Col>
        <Col>
          <Tag>
            {conditions}
            {' '}
            Conditions
          </Tag>
        </Col>
      </Row>
    </Card>
  );
};

export const SelectionCriteria = () => {
  const { catalogId } = useParams<{ catalogId: string }>();
  const { criteria, loading } = useGetSelectionCriteria({
    variables: {
      brandCatalogId: Number(catalogId),
    },
    fetchPolicy: 'cache-first',
    skip: catalogId === 'new',
  });
  const { setIsDrawerOpen, checkedCriteriaIds, setBulkCheckedCriteriaIds } = useSelectionCriteriaContext();
  const [search, setSearch] = useState('');

  const { deleteSelectionCriteria, loading: isDeleting } = useDeleteSelectionCriteria();

  const handleDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete these rules?',
      icon: <TriangleExclamationIcon />,
      okText: 'Delete',
      okType: 'danger',
      onOk: () => {
        checkedCriteriaIds.forEach((id) => {
          deleteSelectionCriteria({
            variables: {
              id,
            },
            refetchQueries: [GET_SELECTION_CRITERIA],
          });
        });
        setBulkCheckedCriteriaIds([]);
      },
      width: '570px',
    });
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const filteredCriteria = useMemo(() => criteria.filter((c) => c.label.toLowerCase().includes(search.toLowerCase())), [
    criteria,
    search,
  ]);

  return (
    <>
      <Space size="large" direction="vertical" className={styles.SelectionCriteria}>
        <Space direction="vertical" size={0}>
          <Title level={5}>Products Selection Criteria</Title>
          <Text className={styles.information}>
            Catalog offers helps you define what creators are allowed to select and set granular rules for minimum
            selection on key items. This helps you customize the ordering experience for different creators in your
            campaign.
            {' '}
            <Link>Learn more</Link>
          </Text>
        </Space>
        {(loading || isDeleting) && <Skeleton />}
        {!(loading || isDeleting) && (
          <Space direction="vertical">
            <Row align="middle" justify="space-between">
              <Col>
                <Space size="middle">
                  <Text weight="semibold">
                    {criteria?.length ?? 0}
                    {' '}
                    Rules
                  </Text>
                  <Input placeholder="Search" prefix={<MagnifyingGlassIcon />} className={styles.searchBar} value={search} onChange={handleSearch} />
                </Space>
              </Col>
              <Col>
                <Space>
                  <Button icon={<TrashIcon />} onClick={handleDelete} disabled={!checkedCriteriaIds.length} />
                  <Button onClick={() => setIsDrawerOpen({ isDrawerOpen: true, selectedCriteriaId: null })}>
                    Create New Rule
                  </Button>
                </Space>
              </Col>
            </Row>
            {criteria.length ? (
              <Space direction="vertical" size={4}>
                {filteredCriteria.map((c) => {
                  let conditions = 0;
                  if (c.minQuantity || c.maxQuantity) conditions += 1;
                  if (c.maxPrice) conditions += 1;
                  conditions += c.conditions.length;
                  return <CriteriaRow key={c.id} id={c.id} title={c.label} conditions={conditions} />;
                })}
              </Space>
            ) : (
              <EmptyScreen />
            )}
          </Space>
        )}
      </Space>
      <SelectionCriteriaDrawer />
    </>
  );
};
