import * as React from 'react';
import cx from 'classnames';
import { Drawer as AntdDrawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

import styles from './Drawer.scss';

export interface IDrawerProps extends DrawerProps { }

export const Drawer: React.FC<IDrawerProps> = (props) => {
  const { className, ...restProps } = props;
  return (
    <AntdDrawer {...restProps} className={cx(className, styles.AppDrawer)} />
  );
};
