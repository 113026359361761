import * as React from 'react';
import { InputProps } from 'antd/lib/input';
import { Input } from 'antd';

import { isEmpty } from 'lodash';
import { FormatPayment, FormatUSMoney, ValidatePaymentInput } from '@frontend/app/utils/payment';
import styles from './DollarInput.scss';

const {
  useCallback,
  useState,
} = React;

interface IProps extends Omit<InputProps, 'onChange'> {
  onChange: (value: number) => void;
  value: number;
}

export const DollarInput = React.memo(React.forwardRef<React.ElementRef<typeof Input>, IProps>((props, forwardRef) => {
  const {
    onChange,
    onBlur,
    value,
  } = props;
  const [textValue, setTextValue] = useState(value ? value.toString() : '');

  const onTextChange = useCallback((e) => {
    setTextValue(e.target.value);
    if (!isEmpty(ValidatePaymentInput(e.target.value))) {
      return;
    }
    const numericValue = parseFloat(e.target.value);
    onChange(numericValue);
  }, [onChange]);

  const onBlurHandle = useCallback((e) => {
    if (onBlur) {
      const textAmountValue = e.target.value;
      const numericValue = parseFloat(textAmountValue);
      if (ValidatePaymentInput(textAmountValue) === '') {
        const formattedAmountValue = FormatPayment(numericValue);
        onChange(formattedAmountValue); // It will display the correct amount on the screen.
        setTextValue(FormatUSMoney(formattedAmountValue));
      }

      onBlur(e); // It will display error message or continue the flow in the parent component.
    }
  }, [onBlur, onChange]);

  return (
    <Input
      {...props}
      ref={forwardRef}
      className={styles.DollarInput}
      min={0}
      onChange={onTextChange}
      onBlur={onBlurHandle}
      onWheel={(event) => event.currentTarget.blur()}
      placeholder="0"
      prefix="$"
      type="number"
      value={textValue}
    />
  );
}));
DollarInput.displayName = 'DollarInput';
