import * as React from 'react';
import cx from 'classnames';
import { capitalize } from 'lodash';

import {
  Redirect, NavLink, Switch,
  Route, useLocation, useRouteMatch,
} from 'react-router-dom';

import { ResourceType } from '@frontend/app/types/globalTypes';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { AccountsPage } from './AccountsPage';

import styles from './EmailApp.scss';

const { useEffect } = React;

interface IProps {
  type?: ResourceType;

  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const EmailApp: React.FunctionComponent<IProps> = React.memo((props) => {
  const location = useLocation();
  const match = useRouteMatch();
  const { type } = props;

  const addEvent = useEventContext();
  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'email' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(styles.EmailApp, props.className)}>
      <div className={styles.header}>
        <div className={styles.nav}>
          <div className={cx(styles.icon, {
            [styles.gmail]: type === ResourceType.GMAIL,
            [styles.outlook]: type === ResourceType.OUTLOOK,
          })}
          />
          <div className={styles.title}>
            {capitalize(type)}
          </div>
          <NavLink
            to={{ ...location, pathname: `${match.url}` }}
            className={styles.item}
            activeClassName={styles.active}
          >
            Settings
          </NavLink>
          {/* <NavLink
            to={{ ...location, pathname: `${match.url}/about` }}
            className={styles.item}
            activeClassName={styles.active}
          >
            About
          </NavLink> */}
        </div>
      </div>
      <div className={styles.children}>
        <Switch>
          <Route
            path={`${match.path}`}
            exact
            render={
              () => <AccountsPage type={type} />
            }
          />
          <Redirect from={`${match.path}`} to={{ ...location, pathname: match.path }} />
        </Switch>
      </div>
    </div>
  );
});

EmailApp.defaultProps = {
  type: ResourceType.GMAIL,
};

EmailApp.displayName = 'EmailApp';

export default EmailApp;
