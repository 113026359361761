import * as React from 'react';
import {
 Typography, Modal, Card, Button,
} from '@revfluence/fresh';

const { Text, Link } = Typography;
import { BudgetGranularity } from '@frontend/app/types/Budget';
import { ArrowUpRightFromSquareIcon } from '@revfluence/fresh-icons/regular';
import styles from './ConnectBudgetAccountModal.module.scss';

interface IProps {
    showModal: boolean;
    granularity: BudgetGranularity;
    setShowModal: (isOpen: boolean) => void;
    setBudgetGranularity: (granularity: BudgetGranularity) => void;
    onOk: () => void;
}
const budgetOptions = [
    {
        type: BudgetGranularity.MONTHLY,
        title: 'Track Monthly',
        description: 'Manage your budget in monthly increments for detailed and responsive financial oversight.',
    },
    {
        type: BudgetGranularity.QUARTERLY,
        title: 'Track Quarterly',
        description: 'Divide your budget across four quarters to balance detail with manageability.',
    },
    {
        type: BudgetGranularity.YEARLY,
        title: 'Track Yearly',
        description: 'Oversee your budget at an annual level for broad strategic planning.',
    },
];

const ConnectBudgetAccountModal: React.FC<IProps> = (props) => {
    const {
 granularity, showModal, setShowModal, setBudgetGranularity, onOk,
} = props;
    const handleCancel = () => {
        setShowModal(false);
    };
    return (
      <>
        <Modal
          title="Set Up Budget Tracking for Your Project"
          open={showModal}
          onCancel={handleCancel}
          width={616}
          footer={(
            <div className={styles.footerContainer}>
              <span>
                <Link target="_blank" rel="noopener noreferrer">
                  <ArrowUpRightFromSquareIcon />
                  {' '}
                  Learn more
                </Link>
              </span>
              <div>
                <Button key="cancel" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button key="confirm" type="primary" onClick={() => onOk()}>
                  Continue
                </Button>
              </div>
            </div>
                )}
        >
          <Text weight="semibold">How would you prefer to track budget for this project?</Text>
          <div className={styles.modalBody}>
            {budgetOptions.map((option) => (
              <Card
                key={option.type}
                hoverable
                className={granularity === option.type ? styles.selectedCard : ''}
                onClick={() => setBudgetGranularity(option.type)}
                style={{ width: '30%' }}
              >
                <Text weight="semibold">{option.title}</Text>
                <Text className={styles.cardSubTitle}>{option.description}</Text>
              </Card>
                    ))}
          </div>
          <p className={styles.modalTail}>
            You can adjust this setting later under Budget Setting in your project.
          </p>
        </Modal>
      </>
    );
};
export default ConnectBudgetAccountModal;
ConnectBudgetAccountModal.displayName = 'ConnectBudgetAccountModal';
