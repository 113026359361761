import * as React from 'react';
import { map, reject } from 'lodash';
import {
  Button, Spinner, Tooltip,
} from '@revfluence/fresh';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';

import {
  ITag, useGetTags, useMemberActivationsQuery, useRemoveMembersFromTagsMutation,
} from '@frontend/app/hooks';
import { ModifyTagMembersModal } from '@frontend/app/components';

import styles from './Styles.module.scss';
import { TagItem } from '../TagItem';

const { useState, useMemo } = React;

interface Props {
  memberId?: number;
  selectedTags?: ITag[];
  onChangeTags?(tags: ITag[]);
}

const Tags: React.FunctionComponent<Props> = (props: Props) => {
  const { memberId, onChangeTags } = props;

  const { tagColorMap } = useGetTags();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletingTagId, setDeletingTagId] = useState<number>(null);

  const {
    data: {
      member: {
        tags: memberTags = null,
      } = {},
    } = {},
    loading: isLoadingActivations,
    refetch: refetchMember,
  } = useMemberActivationsQuery(memberId, {
    fetchPolicy: 'no-cache',
  });

  const handleAddTags = (tags: ITag[]) => {
    if (onChangeTags) {
      onChangeTags(tags);
    }

    if (memberId) {
      refetchMember();
    }
  };

  const [removeMemberFromTag] = useRemoveMembersFromTagsMutation({
    async onCompleted() {
      await refetchMember();
      setDeletingTagId(null);
    },
    onError() {
      setDeletingTagId(null);
    },
  });

  const handleRemoveTag = async (tagId: number) => {
    if (!props.memberId) {
      if (props.onChangeTags) {
        const nextTags = reject(props.selectedTags, { id: tagId });
        props.onChangeTags(nextTags);
      }
      return;
    }

    setDeletingTagId(tagId);

    await removeMemberFromTag({
      variables: {
        memberIds: [props.memberId],
        tagIds: [tagId],
      },
    });
  };

  const memberIds = useMemo(() => {
    if (memberId) {
      return [memberId];
    } else {
      return [];
    }
  }, [memberId]);

  const tags = memberTags || props.selectedTags;

  return (
    <div className={styles.tagsContainer}>
      {isLoadingActivations && (
        <Spinner />
      )}
      {map(tags, (tag) => {
        const tagColor = tagColorMap.get(tag.name);

        return (
          <TagItem
            key={tag.id}
            closable
            style={tagColor || {}}
            loading={deletingTagId === tag.id}
            onClose={(e) => {
              e.preventDefault();
              handleRemoveTag(tag.id);
            }}
          >
            {tag.name}
          </TagItem>
        );
      })}
      <Tooltip title="Add Tag">
        <Button
          icon={<PlusIcon />}
          size="small"
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
      </Tooltip>
      <ModifyTagMembersModal
        show={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        type="add"
        memberIds={memberIds}
        memberCount={1}
        showNewTagButton
        onModifyComplete={handleAddTags}
      />
    </div>
  );
};

export default Tags;
