import * as React from 'react';

import { IValidateProjectName, IValidateProjectNameOptions, validateProjectName } from '../utils';

const { useState } = React;

interface IOptions extends IValidateProjectNameOptions { }

interface IUseValidateProjectName {
  validate: (duplicate?: boolean) => IValidateProjectName;
  shouldValidate: boolean;
}

export const useValidateProjectName = (options: IOptions): IUseValidateProjectName => {
  const {
    projectName,
    emptyMessage,
  } = options;

  const [initialProjectName] = useState(projectName);

  /** Only validate if the input has changed */
  const shouldValidate = initialProjectName !== projectName;

  const validate = (duplicate?: boolean) => validateProjectName({
 projectName, emptyMessage, skip: !shouldValidate, duplicate,
});

  return {
    validate,
    shouldValidate,
  };
};
