import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const TagsIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="10 9 16 16"
  >
    <path d="M14.0625 15.1875C14.0625 14.7227 14.4453 14.3125 14.9375 14.3125C15.4023 14.3125 15.8125 14.7227 15.8125 15.1875C15.8125 15.6797 15.4023 16.0625 14.9375 16.0625C14.4453 16.0625 14.0625 15.6797 14.0625 15.1875ZM17.2617 12.125C17.7266 12.125 18.1641 12.3164 18.4922 12.6445L23.3047 17.457C23.9883 18.1406 23.9883 19.2617 23.3047 19.9453L19.668 23.582C18.9844 24.2656 17.8633 24.2656 17.1797 23.582L12.3672 18.7695C12.0391 18.4414 11.875 18.0039 11.875 17.5391V13.4375C11.875 12.7266 12.4492 12.125 13.1875 12.125H17.2617ZM13.2969 17.8398L18.1094 22.6523C18.2734 22.8438 18.5742 22.8438 18.7383 22.6523L22.375 19.0156C22.5664 18.8516 22.5664 18.5508 22.375 18.3867L17.5625 13.5742C17.4805 13.4922 17.3711 13.4375 17.2617 13.4375H13.1875V17.5391C13.1875 17.6484 13.2148 17.7578 13.2969 17.8398Z" />
  </SvgIcon>
  ));

TagsIcon.displayName = 'TagsIcon';
