import * as React from 'react';
import { Alert } from '@revfluence/fresh';

import styles from './ErrorOverlay.scss';

export const ErrorOverlay: React.FC = React.memo(() => (
  <div className={styles.ErrorOverlay}>
    <Alert
      message={(
        <>
          Sorry, there was an issue loading this data. You can try refreshing this page or please feel free to
          {' '}
          <a href="mailto:help@aspireiq.com">contact support</a>
          .
        </>
      )}
      showIcon
      style={{ borderRadius: '2px' }} // this should be removed when Fresh! fixes border radius
      type="error"
    />
  </div>
));
ErrorOverlay.displayName = 'ErrorOverlay';
