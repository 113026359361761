import * as React from 'react';
import {
  FacebookIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
  InstagramIcon,
  SnapchatIcon,
 } from '@revfluence/fresh-icons/brands/esm';
 import {
  BlogIcon,
  ImageIcon,
  VideoIcon,
  ScrewdriverWrenchIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { PhotoFilmIcon } from '@revfluence/fresh-icons/regular/esm';
import { ContentType } from '@frontend/app/types/globalTypes';

interface IProps {
  contentGuidelineType: ContentType;
}

const ContentGuidelineIcon: React.FC<IProps> = React.memo((props) => {
  const { contentGuidelineType } = props;
  switch (contentGuidelineType) {
    case ContentType.INSTAGRAM:
    case ContentType.INSTAGRAM_POST:
      return <InstagramIcon />;
    case ContentType.INSTAGRAM_REEL:
      return <InstagramIcon />;
    case ContentType.INSTAGRAM_STORY:
      return <InstagramIcon />;
    case ContentType.INSTAGRAM_VIDEO:
      return <InstagramIcon />;
    case ContentType.ADDITIONAL_IMAGES:
      return <ImageIcon />;
    case ContentType.ADDITIONAL_VIDEOS:
      return <VideoIcon />;
    case ContentType.BLOG_DEDICATED:
      return <BlogIcon />;
    case ContentType.BLOG_MENTION:
      return <BlogIcon />;
    case ContentType.FACEBOOK_POST:
      return <FacebookIcon />;
    case ContentType.OTHER:
      return <ScrewdriverWrenchIcon />;
    case ContentType.PINTEREST_PIN:
    case ContentType.PINTEREST:
      return <PinterestIcon />;
    case ContentType.SNAPCHAT_STORY:
      return <SnapchatIcon />;
    case ContentType.TIKTOK_VIDEO:
      return <TiktokIcon />;
    case ContentType.TWITTER_POST:
      return <TwitterIcon />;
    case ContentType.YOUTUBE_DEDICATED:
      return <YoutubeIcon />;
    case ContentType.YOUTUBE_MENTION:
      return <YoutubeIcon />;
    case ContentType.FLEXIBLE:
      return <PhotoFilmIcon />;
    default:
      return null;
  }
});

ContentGuidelineIcon.displayName = 'ContentGuidelineIcon';

export default ContentGuidelineIcon;
