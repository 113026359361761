import * as React from 'react';

import { Space, Typography } from '@revfluence/fresh';

const { Title, Paragraph } = Typography;
interface IProps {
  rename: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
const ContentGuidelinesHeader: React.FunctionComponent<IProps> = React.memo((props) => {
  const termsName = props.rename ? 'briefs' : 'terms';
  return (
    <Space direction="vertical">
      <Title level={3}>Content Guidelines</Title>
      <Paragraph>
        {`Create content guidelines to include in the ${termsName} that you send creators. You can include 
      information about your brand style, ideas and inspiration for the content, and any 
      content specifications (resolution, file format, etc).`}
      </Paragraph>
    </Space>
);
});

ContentGuidelinesHeader.displayName = 'ContentGuidelinesHeader';

export default ContentGuidelinesHeader;
