import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_PROJECTS_FOR_MEMBERS_QUERY } from '@frontend/app/queries';
import {
  GetProjectsForMembers,
  GetProjectsForMembersVariables,
} from '@frontend/app/queries/types/GetProjectsForMembers';

type IOptions = QueryHookOptions<GetProjectsForMembers, GetProjectsForMembersVariables>;

export const useGetProjectsForMembers = (options: IOptions = {}) => (
  useQuery<GetProjectsForMembers, GetProjectsForMembersVariables>(GET_PROJECTS_FOR_MEMBERS_QUERY, options)
);
