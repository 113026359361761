import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';

type SelectionType = 'bulk' | 'perMember';

type Screen = 'sendCatalog' | 'sendOrderRequestEmail';

export interface SendCatalogContextState {
  selectedCatalog: number;
  selectedCatalogs: Record<number, number>;
  screen: Screen;
  selectionType: SelectionType;
  selectedRules: Record<number, number>;
  selectedRule: number;
}

export interface SendCatalogContextActions {
  setSelectedCatalog: (catalogId: number) => void;
  setSelectedCatalogs: (payload: { memberId: number; catalogId: number }) => void;
  setSelectedCatalogsBulk: (payload: Record<number, number>) => void;
  setSelectionType: (selectionType: SelectionType) => void;
  setScreen: (screen: Screen) => void;
  setSelectedRules: (payload: { memberId: number; ruleId: number }) => void;
  setSelectedRulesBulk: (payload: Record<number, number>) => void;
  setSelectedRule: (ruleId: number) => void;
}

export interface SendCatalogContextProps extends SendCatalogContextState, SendCatalogContextActions {}

export enum Actions {
  SetSelectedCatalog = 'SET_SELECTED_CATALOG',
  SetSelectedCatalogs = 'SET_SELECTED_CATALOGS',
  SetSelectedCatalogsBulk = 'SET_SELECTED_CATALOGS_BULK',
  SetSelectionType = 'SET_SELECTION_TYPE',
  SetScreen = 'SET_SCREEN',
  SetSelectedRules = 'SET_SELECTED_RULES',
  SetSelectedRulesBulk = 'SET_SELECTED_RULES_BULK',
  SetSelectedRule = 'SET_SELECTED_RULE',
}

type ReducerAction =
  | { type: Actions.SetSelectedCatalog; payload: number }
  | { type: Actions.SetSelectedCatalogs; payload: { memberId: number; catalogId: number } }
  | { type: Actions.SetSelectedCatalogsBulk; payload: Record<number, number> }
  | { type: Actions.SetSelectionType; payload: SelectionType }
  | { type: Actions.SetScreen; payload: Screen }
  | { type: Actions.SetSelectedRules; payload: { memberId: number; ruleId: number } }
  | { type: Actions.SetSelectedRulesBulk; payload: Record<number, number> }
  | { type: Actions.SetSelectedRule; payload: number };

const initialState: SendCatalogContextState = {
  selectedCatalog: null,
  selectedCatalogs: {},
  screen: 'sendCatalog',
  selectionType: 'bulk',
  selectedRules: {},
  selectedRule: null,
};

export const reducer = (state: SendCatalogContextState, action: ReducerAction): SendCatalogContextState => {
  switch (action.type) {
    case Actions.SetSelectedCatalog:
      return { ...state, selectedCatalog: action.payload };
    case Actions.SetSelectedCatalogs:
      return {
        ...state,
        selectedCatalogs: {
          ...state.selectedCatalogs,
          [action.payload.memberId]: action.payload.catalogId,
        },
      };
    case Actions.SetSelectedCatalogsBulk:
      return { ...state, selectedCatalogs: action.payload };
    case Actions.SetSelectionType:
      return { ...state, selectionType: action.payload };
    case Actions.SetScreen:
      return { ...state, screen: action.payload };
    case Actions.SetSelectedRules:
      return {
        ...state,
        selectedRules: {
          ...state.selectedRules,
          [action.payload.memberId]: action.payload.ruleId,
        },
      };
    case Actions.SetSelectedRulesBulk:
      return { ...state, selectedRules: action.payload };
    case Actions.SetSelectedRule:
      return { ...state, selectedRule: action.payload };
    default:
      return state;
  }
};

export const useSendCatalogContextReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo<SendCatalogContextActions>(
    () => ({
      setSelectedCatalog: (payload) => {
        dispatch({ type: Actions.SetSelectedCatalog, payload });
      },
      setSelectedCatalogs: (payload) => {
        dispatch({ type: Actions.SetSelectedCatalogs, payload });
      },
      setSelectedCatalogsBulk: (payload) => {
        dispatch({ type: Actions.SetSelectedCatalogsBulk, payload });
      },
      setSelectionType: (selectionType) => {
        dispatch({ type: Actions.SetSelectionType, payload: selectionType });
      },
      setScreen: (screen) => {
        dispatch({ type: Actions.SetScreen, payload: screen });
      },
      setSelectedRules: (payload) => {
        dispatch({ type: Actions.SetSelectedRules, payload });
      },
      setSelectedRulesBulk: (payload) => {
        dispatch({ type: Actions.SetSelectedRulesBulk, payload });
      },
      setSelectedRule: (payload) => {
        dispatch({ type: Actions.SetSelectedRule, payload });
      },
    }),
    [],
  );

  return useMemo(() => ({ ...state, ...actions }), [state, actions]);
};

export const SendCatalogContext = createContext<SendCatalogContextProps>(null);

export const SendCatalogContextProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useSendCatalogContextReducer();

  return <SendCatalogContext.Provider value={value}>{children}</SendCatalogContext.Provider>;
};

export const useSendCatalogContext = () => useContext(SendCatalogContext);
