/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ContentPage_itp3m_32 {
  min-height: 700px;
  width: 100%;
  padding: 0 20px 20px 20px;
}
._ContentPage_itp3m_32._mobile_itp3m_37 ._leftCol_itp3m_37 {
  display: none;
}
._ContentPage_itp3m_32._mobile_itp3m_37 ._rightCol_itp3m_40 {
  margin-left: 0;
}
._ContentPage_itp3m_32 ._leftCol_itp3m_37 {
  position: relative;
}
._ContentPage_itp3m_32 ._leftCol_itp3m_37 ._leftFixed_itp3m_46 {
  position: fixed;
  max-width: 315px;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 130px;
  overflow: hidden;
}
._ContentPage_itp3m_32 ._leftCol_itp3m_37 ._leftFixed_itp3m_46:hover {
  overflow: auto;
}
._ContentPage_itp3m_32 ._leftCol_itp3m_37 ._leftFixed_itp3m_46 > * {
  width: 295px;
}
._ContentPage_itp3m_32 ._rightCol_itp3m_40 {
  margin-left: 315px;
}
._ContentPage_itp3m_32 ._toolbar_itp3m_63 {
  position: sticky;
  width: 100%;
  z-index: 1;
  top: 0;
  padding-bottom: 8px;
  padding-top: 20px;
  background-color: rgba(255, 255, 255, 0.95);
}

._favouriteIcon_itp3m_73 {
  color: #f1515f;
}