import { useState, useEffect } from 'react';
import { IAssignmentAPIPayload } from '@frontend/applications/Shared/components/ArtifactAssignmentForm';

import { logger } from '@common';
import { IArtifact } from '@frontend/applications/Shared/context/applicationContext';

const querystring = require('querystring');

export interface IContract extends IArtifact {
  id: string;
  title: string;
  remote_identifier: string;
  is_complete: boolean;
  status: string;
  created_ts: number;
  cancelled: boolean;
  signers: IContractSigner[];
  activation_names: string[];
}

export interface IUpdateContractPayload extends IAssignmentAPIPayload {
  cancelled?: boolean;
}

interface IDownloadableContract extends IContract {
  file_url: string;
}

export interface IContractSigner {
  name: string;
  email: string;
  role: SignerRole;
}

interface IContractFetchQueryParams {
  client_id: string;
  member_id?: string;
}

interface IInvalidMember {
  id: number;
  email: string;
  name: string;
}

export enum SignerRole {
  BrandRole = 'Brand',
  CreatorRole = 'Creator',
}

export interface IValidateBulkContractRequest {
  client_id: string;
  member_ids: string;
  search_query: string;
}

const fetchContractsData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

export function useFetchContractsData(url: string, params: IContractFetchQueryParams) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IContract[]>(null);
  const [error, setError] = useState(null);

  const filterString = querystring.stringify(params);

  const finalUrl = `${url}?${filterString}`;

  useEffect(() => {
    setLoading(true);
    fetchContractsData(finalUrl)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [finalUrl]);

  return {
    loading,
    data,
    error,
  };
}

export function useFetchContractDownloadData(url: string, clientId: string, skip: boolean) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IDownloadableContract>(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (skip) {
      return;
    }

    const filterString = querystring.stringify({ client_id: clientId });
    const finalUrl = `${url}?${filterString}`;

    setLoading(true);
    fetchContractsData(finalUrl)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, skip]);

  return {
    loading,
    data,
    error,
  };
}

export function useValidateBulkSendMembers(url: string, params: IValidateBulkContractRequest) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IInvalidMember[]>(null);
  const [error, setError] = useState(null);

  const filterString = querystring.stringify(params);

  const finalUrl = `${url}?${filterString}`;

  useEffect(() => {
    setLoading(true);
    fetchContractsData(finalUrl)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [finalUrl]);

  return {
    loading,
    data,
    error,
  };
}
