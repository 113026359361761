import { IState } from '../../../../types/state';
import { IUpdateContentRightAction } from './index';

export const updateContentRight = (state: IState, action: IUpdateContentRightAction): IState => ({
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      contentRight: { ...action.contentRight },
    },
  });
