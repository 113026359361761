import gql from 'graphql-tag';

const GET_DASHBOARD_BUDGET_TAB_SUMMARY = gql`
  query GetBudgetDashboardBudgetTabSummaryQuery($budgetId: Int!, $fiscalYear: String!) {
    summary:getBudgetDashboardBudgetTabSummary(budgetId: $budgetId,fiscalYear: $fiscalYear) {
      allocatedBudget
      spentBudget
      totalBudget
    }
  }
`;

export default GET_DASHBOARD_BUDGET_TAB_SUMMARY;
