import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Modal, IModalProps,
} from '@components';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { RequirementFragment } from '@frontend/app/queries/fragments/types/RequirementFragment';

import { AddEditRequirementForm } from './AddEditRequirementForm';

const { useCallback, useState } = React;

type IRequirement = RequirementFragment;

interface IProps extends IModalProps {
  requirement?: IRequirement;
  query?: IMemberSearchQuery;
  memberIds?: number[];
  memberCount?: number;
  source?: string;
  onDelete?();
}

export const RequirementsModal: React.FunctionComponent<IProps> = (props) => {
  const {
 requirement, query, memberIds, memberCount, source, ...modalProps
} = props;

  const [key, setKey] = useState<string>('init');

  const handleOnClose = useCallback(() => {
    setTimeout(() => {
      setKey(uuidv4());
    }, 300);

    if (props.onRequestClose) {
      props.onRequestClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onRequestClose]);

  const handleOnDelete = useCallback(() => {
    handleOnClose();

    if (props.onDelete) {
      props.onDelete();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOnClose, props.onDelete]);

  return (
    <Modal {...modalProps}>
      <AddEditRequirementForm
        key={key}
        requirement={requirement}
        query={query}
        memberIds={memberIds}
        memberCount={memberCount}
        source={source}
        onClickCancel={handleOnClose}
        onComplete={handleOnClose}
        onDelete={handleOnDelete}
      />
    </Modal>
  );
};
