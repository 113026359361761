import 'css-chunk:src/app/components/MemberSearch/MemberSearch.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1kabb_157",
  "justify-content-space-between": "_justify-content-space-between_1kabb_161",
  "tabular-nums": "_tabular-nums_1kabb_165",
  "MemberSearch": "_MemberSearch_1kabb_178",
  "overlay": "_overlay_1kabb_178",
  "content": "_content_1kabb_178",
  "inputArea": "_inputArea_1kabb_178",
  "input": "_input_1kabb_178",
  "suggestions": "_suggestions_1kabb_188",
  "item": "_item_1kabb_188",
  "info": "_info_1kabb_188",
  "sub": "_sub_1kabb_188",
  "name": "_name_1kabb_198",
  "memberCount": "_memberCount_1kabb_208",
  "active": "_active_1kabb_234",
  "mask": "_mask_1kabb_238",
  "icon": "_icon_1kabb_270",
  "noticeWrapper": "_noticeWrapper_1kabb_302",
  "selected": "_selected_1kabb_319",
  "avatar": "_avatar_1kabb_322",
  "MemberSearchTooltip": "_MemberSearchTooltip_1kabb_342",
  "show": "_show_1kabb_1"
};