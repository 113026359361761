import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { isFunction } from 'lodash';

import { CloseIcon } from '@components';
import { IconButton } from '@components';

const {
  useCallback,
  useLayoutEffect,
  useEffect,
} = React;
import styles from './Overlay.scss';

export interface IOverlayProps {
  className?: string;
  show: boolean;
  onRequestClose();

  showCloseIcon?: boolean;
  closeOnBackdropClick?: boolean;
  contentClassName?: string;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  forceClose?: boolean;
}

const OverlayComponent = (props: React.PropsWithChildren<IOverlayProps>) => {
  const {
    children,
    className,
    closeOnBackdropClick = true,
    contentClassName = null,
    forwardedRef,
    onRequestClose: onRequestCloseProp,
    show,
    showCloseIcon = true,
    forceClose,
  } = props;
  const ref = forwardedRef || React.createRef<HTMLDivElement>();

  const toggleBodyScroll = (toggle = true) => {
    if (document && document.body) {
      document.body.classList.toggle(styles['body-disable-scroll'], !toggle);
    }
  };

  useEffect(() => {
    /**
     * TODO: Refactor to merge OverlayComponent and Overlay, use ref, and
     * useImperativeHandle to expose a close method.
     */
    if (forceClose) {
      onRequestClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceClose]);

  // Disable scroll on <body>
  useLayoutEffect(() => {
    if (show) {
      toggleBodyScroll(false);
    }
    return () => toggleBodyScroll(true);
  }, [show]);

  const onRequestClose = useCallback(() => {
    toggleBodyScroll(true);
    if (isFunction(onRequestCloseProp)) {
      onRequestCloseProp();
    }
  }, [onRequestCloseProp]);

  // skip server side rendering
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <CSSTransition
      in={show}
      classNames={{
        enter: styles['Overlay-enter'],
        enterActive: styles['Overlay-enter-active'],
        exit: styles['Overlay-exit'],
        exitActive: styles['Overlay-exit-active'],
      }}
      timeout={400} // $transition-duration-slow: 0.4s
      unmountOnExit
    >
      <div
        className={cx(styles.Overlay, className)}
        ref={ref}
        onClick={(event) => {
          if (closeOnBackdropClick && event.target === event.currentTarget) {
            onRequestClose();
          }
        }}
        role="dialog"
        aria-modal="true"
      >
        <div className={styles.contentWrapper}>
          <div className={cx(contentClassName, styles.content)}>
            {children}
          </div>
        </div>
        {showCloseIcon && (
          <IconButton
            className={styles.close}
            onClick={onRequestClose}
            icon={<CloseIcon size={24} />}
            theme="light"
          />
        )}
      </div>
    </CSSTransition>
  );
};

export const Overlay = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IOverlayProps>>(
  (props: React.PropsWithChildren<IOverlayProps>, forwardRef: React.RefObject<HTMLDivElement>) => (
    ReactDOM.createPortal(
      <OverlayComponent {...props} forwardedRef={forwardRef} />,
      document.body,
    )
  ),
);

Overlay.displayName = 'Overlay';
