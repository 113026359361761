import cx from 'classnames';
import * as React from 'react';
import { Tooltip } from '@revfluence/fresh';
import { TooltipPlacement } from 'antd/lib/tooltip';
import reactNodeToString from 'react-node-to-string';

import styles from './EllipsisLabel.scss';

const {
  useRef,
  useLayoutEffect,
  useState,
  useCallback,
} = React;

interface IProps {
  showTooltip?: boolean;
  tooltipPlacement?: TooltipPlacement;
  className?: string;
  align?: number[];
  textOnlyTitle?: boolean;
}

export const HORIZONTAL_TOOLTIP_OFFSET = 30;
export const VERTICAL_TOOLTIP_OFFSET = 0;

export const EllipsisLabel: React.FC<IProps> = (props) => {
  const spanRef = useRef<HTMLSpanElement>();
  const divRef = useRef<HTMLDivElement>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const computeShowTooltip = useCallback(() => {
    const span = spanRef.current;
    const div = divRef.current;

    if (span && span.offsetWidth === 0) {
      // need to recalculate this when display becomes visible. Keep checking every 2secs.
      timeoutRef.current = setTimeout(() => {
        computeShowTooltip();
      }, 2000);
      return;
    }

    if (span && div) {
      setShowTooltip(span.offsetWidth > div.offsetWidth);
    }
  }, [spanRef, divRef, timeoutRef, setShowTooltip]);

  useLayoutEffect(() => {
    computeShowTooltip();
    return () => {
      clearTimeout(timeoutRef.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.children]);

  return (
    <Tooltip
      overlayStyle={{
        zIndex: 9999,
        display: props.showTooltip && showTooltip ? undefined : 'none',
      }}
      placement={props.tooltipPlacement}
      title={props.textOnlyTitle ? reactNodeToString(props.children) : props.children}
      align={{ offset: props.align }}
    >
      <div className={cx(props.className, styles.EllipsisLabel)}>
        <div ref={divRef}>
          <span ref={spanRef}>{props.children}</span>
        </div>
      </div>
    </Tooltip>
  );
};

EllipsisLabel.defaultProps = {
  showTooltip: true,
  tooltipPlacement: 'left',
};
