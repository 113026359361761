import * as React from 'react';
import {
  find, get, isEmpty, isEqual, isFinite, nth, pick, startCase,
} from 'lodash';

import { TNetworkIdentifier, MultiRangeSlider, RangeSlider } from '@components';

import { FilterSection } from '../FilterSection';
import { IEngagementFilters, IEngagementOptions, TRange } from '../models';
import { engagementRatioLimits } from '../utils/searchParams';

const { useEffect, useMemo } = React;
import styles from './EngagementSection.scss';

const FILTERS = ['reachRange', 'engagementRange', 'impressionRange', 'engagementRatioRange'];

export const REACH_LIMITS_IQ_VALUES = [1000, 1000000] as TRange;
const REACH_STEP_IQ_VALUE = 1000;
export const ENGAGEMENT_LIMITS_IQ_VALUES = [0, 50000] as TRange;
const ENGAGEMENT_STEP_IQ_VALUE = 500;
const IMPRESSION_STEP_IQ_VALUE = 1000;

const pickFilters = (obj: any): IEngagementFilters => pick(obj, FILTERS);

interface IProps extends IEngagementFilters, IEngagementOptions {
  network?: TNetworkIdentifier;
  onChange(engagement: IEngagementFilters);
  showIQDataFilters?: boolean;
}

const isRangeValid = (range: any) => range && isFinite(range[0]) && isFinite(range[1]);

const EngagementSection: React.FunctionComponent<IProps> = (props) => {
  const {
    engagementLimitsByNetwork,
    engagementStep: engagementStepProp,
    hideEngagement,
    hide: hideProp,
    isExpanded,
    network = 'instagram',
    networkInfos,
    onChange,
    reachLimitsByNetwork,
    reachStep: reachStepProp,
    impressionLimitsByNetwork,
    impressionStep: impressionStepProp,
    hideImpression,
    hideEngagementRatio,
    showIQDataFilters,
    reachRange: savedReachRange,
    engagementRange: savedEngagementRange,
    impressionRange: savedImpressionRange,
    engagementRatioRange: savedEngagementRatioRange,
  } = props;

  const reachLimits = useMemo(() => get(reachLimitsByNetwork, network) as TRange, [network]);

  const reachRange = useMemo(() => (
    !isEmpty(savedReachRange)
      && !isEqual(savedReachRange, get(reachLimitsByNetwork, network))
      ? savedReachRange
      : reachLimits
  ), [reachLimits, savedReachRange, reachLimitsByNetwork, network]);

  const reachStep = showIQDataFilters
    ? REACH_STEP_IQ_VALUE
    : reachStepProp;

  const engagementLimits = useMemo(() => get(engagementLimitsByNetwork, network) as TRange, [network]);

  const engagementRange = useMemo(() => (
    !isEmpty(savedEngagementRange)
      && !isEqual(savedEngagementRange, get(engagementLimitsByNetwork, network))
      ? savedEngagementRange
      : engagementLimits
  ), [engagementLimits, savedEngagementRange, engagementLimitsByNetwork, network]);

  const engagementStep = showIQDataFilters
    ? ENGAGEMENT_STEP_IQ_VALUE
    : engagementStepProp;

  const impressionLimits = useMemo(() => get(impressionLimitsByNetwork, network) as TRange, [network]);

  const impressionRange = useMemo(() => (
    !isEmpty(savedImpressionRange)
      && !isEqual(savedImpressionRange, get(impressionLimitsByNetwork, network))
      ? savedImpressionRange
      : impressionLimits
  ), [savedImpressionRange, impressionLimits, impressionLimitsByNetwork, network]);

  const impressionStep = showIQDataFilters
    ? IMPRESSION_STEP_IQ_VALUE
    : impressionStepProp;

  const engagementRatioRange = useMemo(() => (
    !isEmpty(savedEngagementRatioRange)
      ? savedEngagementRatioRange
      : engagementRatioLimits as TRange
  ), [engagementRatioLimits, savedEngagementRatioRange]);

  const hideReachSlider = !isRangeValid(reachRange);
  const hideEngagementSlider = hideEngagement || !isRangeValid(engagementRange);
  const hideImpressionSlider = hideImpression || !isRangeValid(impressionRange);
  const hideEngagementRatioSlider = hideEngagementRatio || !isRangeValid(engagementRatioRange);

  const handleChangeReach = (reachRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      reachRange,
    };
    onChange(newFilters);
  };

  const handleChangeEngagement = (engagementRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      engagementRange,
    };
    onChange(newFilters);
  };

  const handleChangeImpression = (impressionRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      impressionRange,
    };
    onChange(newFilters);
  };

  const handleChangeEngagementRatio = (engagementRatioRange: TRange) => {
    const newFilters = {
      ...pickFilters(props),
      engagementRatioRange,
    };
    onChange(newFilters);
  };

  const handleChangeMinEngagementRatio = (minRatio: number) => {
    const newFilters = {
      ...pickFilters(props),
      engagementRatioRange: [minRatio, 1] as TRange,
    };
    onChange(newFilters);
  };

  useEffect(() => {
    const defaultParams: Partial<IEngagementFilters> = {};

    if (!hideReachSlider) {
      defaultParams.reachRange = reachRange;
    }

    if (!hideImpressionSlider) {
      defaultParams.impressionRange = impressionRange;
    } else {
      // TODO: remove this comment after code review
      // add this else block because when we switch to tiktok and switch back
      // to instagram, impressionRange is presented in the payload, which will fail
      // the request
      defaultParams.impressionRange = null;
    }

    if (!hideEngagementSlider) {
      defaultParams.engagementRange = engagementRange;
    }

    onChange({
      ...pickFilters(props),
      ...defaultParams,
    })
  }, [
    network,
    hideImpressionSlider,
    hideEngagementSlider,
    hideReachSlider,
    engagementRange,
    reachRange,
    impressionRange,
  ]);

  const {
    audienceNamePlural,
    engagementNamePlural,
    contentName,
  } = useMemo(() => (
    find(networkInfos, { identifier: network }) || {
      audienceNamePlural: '',
      engagementNamePlural: '',
      contentName: '',
    }
  ), [networkInfos, network]);

  const hide = hideProp
    || (hideEngagementSlider && hideReachSlider && hideImpressionSlider && hideEngagementRatioSlider);

  return (
    <FilterSection
      header="Reach & Engagement"
      hide={hide}
      collapsible
      defaultIsOpen={isExpanded}
    >
      <FilterSection isSubsection hide={hideReachSlider}>
        <MultiRangeSlider
          className={styles.multiRangeSlider}
          subClassNames={{
            description: styles['justify-content-space-between'],
          }}
          label={startCase(audienceNamePlural)}
          defaultValue={reachRange}
          step={reachStep}
          min={nth(reachLimits, 0)}
          max={nth(reachLimits, 1)}
          onChange={handleChangeReach}
          onStoppedDragging={handleChangeReach}
          idempotencyKey={network}
          showInput
          showPlusOnMaxRange
        />
      </FilterSection>
      <FilterSection isSubsection hide={hideEngagementSlider}>
        <MultiRangeSlider
          className={styles.multiRangeSlider}
          subClassNames={{
            description: styles['justify-content-space-between'],
          }}
          label={startCase(`${engagementNamePlural} per ${contentName}`)}
          defaultValue={engagementRange}
          step={engagementStep}
          min={nth(engagementLimits, 0)}
          max={nth(engagementLimits, 1)}
          onChange={handleChangeEngagement}
          onStoppedDragging={handleChangeEngagement}
          idempotencyKey={network}
          showInput
          showPlusOnMaxRange
        />
      </FilterSection>
      <FilterSection isSubsection hide={hideImpressionSlider}>
        <MultiRangeSlider
          className={styles.multiRangeSlider}
          subClassNames={{
            description: styles['justify-content-space-between'],
          }}
          label={startCase(`${engagementNamePlural} per ${contentName}`)}
          defaultValue={impressionRange}
          step={impressionStep}
          min={nth(impressionLimits, 0)}
          max={nth(impressionLimits, 1)}
          onChange={handleChangeImpression}
          onStoppedDragging={handleChangeImpression}
          idempotencyKey={network}
          showInput
          showPlusOnMaxRange
        />
      </FilterSection>
      <FilterSection isSubsection hide={hideEngagementRatioSlider}>
      {
          showIQDataFilters ?
            <RangeSlider
              className={styles.multiRangeSlider}
              subClassNames={{
                description: styles['justify-content-space-between'],
              }}
              formatStr="0%"
              label={startCase('Minimum Engagement Rate')}
              defaultValue={engagementRatioRange[0]}
              step={0.01}
              min={nth(engagementRatioLimits, 0)}
              max={nth(engagementRatioLimits, 1)}
              onChange={handleChangeMinEngagementRatio}
              onStoppedDragging={handleChangeMinEngagementRatio}
              showInput
            /> :
            <MultiRangeSlider
              className={styles.multiRangeSlider}
              subClassNames={{
                description: styles['justify-content-space-between'],
              }}
              formatStr="0%"
              label={startCase('Engagement Rate')}
              defaultValue={engagementRatioRange as TRange}
              step={0.01}
              min={nth(engagementRatioLimits, 0)}
              max={nth(engagementRatioLimits, 1)}
              onChange={handleChangeEngagementRatio}
              onStoppedDragging={handleChangeEngagementRatio}
              idempotencyKey={network}
              showInput
            />
        }
      </FilterSection>
    </FilterSection>
  );
};

export default EngagementSection;
