import * as React from 'react';

import { TFilter, TAnalyzePage } from './AnalyzeContext';

const { createContext, useContext } = React;

export interface IAnalyzeContext {
  apiEndpoint: string;
  backendServerApiEndpoint: string;
  filters: TFilter;
  organizationId?: string;
  setFilters(filters: TFilter): void;
  currentPage?: TAnalyzePage;
  showCostMetrics: boolean;
  isQa: boolean;
  aspirexAnalytics?: SegmentAnalytics.AnalyticsJS;
  selectedBrandId?: number;
  insightFeatureFlag: boolean | undefined;
  tempYoutubeDemoAccountMode?: boolean | undefined;
}

export const AnalyzeContext = createContext<IAnalyzeContext>(null);

export const useAnalyze = () => useContext(AnalyzeContext);
