/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_iwioy_157 {
  display: flex;
}

._justify-content-space-between_iwioy_161 {
  justify-content: space-between;
}

._tabular-nums_iwioy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_iwioy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_iwioy_157 {
  display: flex;
}

._justify-content-space-between_iwioy_161 {
  justify-content: space-between;
}

._tabular-nums_iwioy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_iwioy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_iwioy_157 {
  display: flex;
}

._justify-content-space-between_iwioy_161 {
  justify-content: space-between;
}

._tabular-nums_iwioy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_iwioy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._boldText_iwioy_532 {
  font-size: 1rem;
  font-weight: 600;
}

._bulkUpdateContainer_iwioy_537 {
  margin-top: 1rem;
}
._bulkUpdateContainer_iwioy_537 ._bulkUpdateContainerContent_iwioy_540 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
._bulkUpdateContainer_iwioy_537 ._bulkUpdateItemContainer_iwioy_547 {
  width: 29.0625rem;
}
._bulkUpdateContainer_iwioy_537 ._bulkUpdateItemContainer_iwioy_547 ._itemContainerContent_iwioy_550 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
._bulkUpdateContainer_iwioy_537 ._bulkUpdateItemContainer_iwioy_547 ._payoutInput_iwioy_556 {
  width: 100%;
}
._bulkUpdateContainer_iwioy_537 ._bulkUpdateItemContainer_iwioy_547 ._dateInput_iwioy_559 {
  border-color: none;
  width: 100%;
}
._bulkUpdateContainer_iwioy_537 ._bulkUpdateItemContainer_iwioy_547 ._error_iwioy_563 {
  margin-top: -0.25rem;
  color: red;
}