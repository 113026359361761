import React, { ComponentPropsWithoutRef, useMemo } from 'react';
import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import { largeIntegerAbbreviator, largeNumberAbbreviatorWithDecimal } from '@frontend/utils';
import { isNil } from 'lodash';
import TrendIcon from './TrendIcon';

interface MainMetricProps {
  size: 'large' | 'medium' | 'small' | 'xsmall';
  heading?: string;
  value: number;
  icon: React.ReactNode;
  metricType?: 'number' | 'currency' | 'count';
  subHeading?: string;
  totalValue?: number;
  className?: string;
  colorSwatch?: string;
  trend?: number;
}

const valueTextStyles = cva(
  'm-0',
  {
    variants: {
      size: {
        large: 'text-5xl font-bold',
        medium: 'text-4xl font-bold',
        small: 'text-2xl font-bold',
        xsmall: 'text-xl font-semibold',
      },
    },
  },
);

const currencyTextStyles = cva(
  'm-0',
  {
    variants: {
      size: {
        large: 'text-4xl font-bold',
        medium: 'text-3xl font-bold',
        small: 'text-lg font-bold',
        xsmall: 'text-base font-semibold',
      },
    },
  },
);

const MainMetric: React.FC<MainMetricProps> = ({
  size,
  heading,
  value,
  totalValue,
  metricType = 'number',
  icon,
  subHeading,
  className,
  colorSwatch,
  trend,
}) => {
  const totalValueWithAbbreviation = useMemo(() => {
    if (metricType === 'count') {
      return largeIntegerAbbreviator(totalValue || 0);
    }
    return largeNumberAbbreviatorWithDecimal(totalValue || 0);
  }, [metricType, totalValue]);
  const valueWithAbbreviation = useMemo(() => {
    if (metricType === 'count') {
      return largeIntegerAbbreviator(value || 0);
    }
    return largeNumberAbbreviatorWithDecimal(value || 0);
  }, [metricType, value]);

  const trendProps = useMemo<ComponentPropsWithoutRef<typeof TrendIcon>>(() => {
    if (!isNil(trend)) {
      const cleanTrend = Math.round(trend);

      return {
        trend: cleanTrend > 0 ? 'up' : cleanTrend < 0 ? 'down' : 'flat',
        value: `${new Intl.NumberFormat('en-US').format(cleanTrend)}%`,
      };
    }
    return undefined;
  }, [trend]);

  return (
    <div className={classNames('flex flex-col gap-[1px]', className)}>
      {heading && (
        <div className="flex gap-2 items-center">
          {colorSwatch && <div className="h-3 w-3 rounded" style={{ backgroundColor: colorSwatch }} />}
          <span>{heading}</span>
        </div>
      )}
      <div className="flex items-baseline gap-1">
        {metricType === 'currency' && <span className={currencyTextStyles({ size })}>$</span>}
        <span className={valueTextStyles({ size })}>{valueWithAbbreviation.value}</span>
        <span className={currencyTextStyles({ size })}>{valueWithAbbreviation.symbol}</span>
        {totalValue && (
          <span className="text-gray-500">
            {`/ ${
              metricType === 'currency'
                ? `$${totalValueWithAbbreviation.value}${totalValueWithAbbreviation.symbol}`
                : `${totalValueWithAbbreviation.value}${totalValueWithAbbreviation.symbol}`
            } Total Used`}
          </span>
        )}
      </div>
      <div className="flex items-center gap-2">
        {icon}
        {trendProps && <TrendIcon {...trendProps} />}
        {subHeading && <span className="text-gray-500">{subHeading}</span>}
      </div>
    </div>
  );
};

export default MainMetric;
