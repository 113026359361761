/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14fsu_157 {
  display: flex;
}

._justify-content-space-between_14fsu_161 {
  justify-content: space-between;
}

._tabular-nums_14fsu_165 {
  font-variant-numeric: tabular-nums;
}

._Tooltip_14fsu_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_14fsu_213 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Tooltip_14fsu_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Tooltip_14fsu_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Tooltip_14fsu_169 ::-webkit-scrollbar-track, ._Tooltip_14fsu_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Tooltip_14fsu_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Tooltip_14fsu_169 {
  position: fixed !important;
  z-index: 9600;
  top: 0;
  left: 0;
  pointer-events: none;
  will-change: transform;
  transform: translateZ(0);
}
._Tooltip_14fsu_169._show_14fsu_213 {
  pointer-events: auto;
}
._Tooltip_14fsu_169._show_14fsu_213 ._content_14fsu_216 {
  opacity: 1;
}
._Tooltip_14fsu_169._show_14fsu_213 ._content_14fsu_216._contentTop_14fsu_219, ._Tooltip_14fsu_169._show_14fsu_213 ._content_14fsu_216._contentBottom_14fsu_219 {
  transform: translateY(0);
}
._Tooltip_14fsu_169 ._content_14fsu_216 {
  position: relative;
  padding: 12px;
  z-index: 1;
  opacity: 0;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  will-change: opacity, transform;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1) 0s, transform 0.15s cubic-bezier(0.4, 0s, 1, 1) 0s;
}
._Tooltip_14fsu_169 ._content_14fsu_216._contentTop_14fsu_219 {
  transform: translateY(-10px);
}
._Tooltip_14fsu_169 ._content_14fsu_216._contentBottom_14fsu_219 {
  transform: translateY(10px);
}
._Tooltip_14fsu_169 ._content_14fsu_216._contentRight_14fsu_239 {
  transform: translateX(10px);
}
._Tooltip_14fsu_169 ._content_14fsu_216 ._arrow_14fsu_242 {
  position: absolute;
  width: 0;
  height: 0;
}
._Tooltip_14fsu_169 ._content_14fsu_216 ._arrow_14fsu_242._top_14fsu_247 {
  left: 50%;
  transform: translateX(-50%);
  top: -8px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid white;
  filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.12));
}
._Tooltip_14fsu_169 ._content_14fsu_216 ._arrow_14fsu_242._bottom_14fsu_256 {
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 8px solid white;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.12));
}
._Tooltip_14fsu_169 ._content_14fsu_216 ._arrow_14fsu_242._right_14fsu_265 {
  left: -4px;
  top: 18px;
  transform: translateX(-50%) rotate(-90deg);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 8px solid white;
  filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.12));
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorWhite_14fsu_274 {
  background-color: white;
  color: rgba(26, 24, 24, 0.95);
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorWhite_14fsu_274 ._arrow_14fsu_242._top_14fsu_247 {
  border-bottom-color: white;
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorWhite_14fsu_274 ._arrow_14fsu_242._bottom_14fsu_256 {
  border-top-color: white;
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorWhite_14fsu_274 ._arrow_14fsu_242._right_14fsu_265 {
  border-top-color: white;
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorBlack_14fsu_287 {
  background-color: rgba(26, 24, 24, 0.95);
  color: white;
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorBlack_14fsu_287 ._arrow_14fsu_242._top_14fsu_247 {
  border-bottom-color: rgba(26, 24, 24, 0.95);
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorBlack_14fsu_287 ._arrow_14fsu_242._bottom_14fsu_256 {
  border-top-color: rgba(26, 24, 24, 0.95);
}
._Tooltip_14fsu_169 ._content_14fsu_216._colorBlack_14fsu_287 ._arrow_14fsu_242._right_14fsu_265 {
  border-top-color: rgba(26, 24, 24, 0.95);
}