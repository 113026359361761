import React from 'react';
import {
 ColumnDef, flexRender, getCoreRowModel, useReactTable,
} from '@tanstack/react-table';

import ReactDragListView from 'react-drag-listview';

import {
 Table, TableHeader, TableRow, TableHead, TableCell, TableBody,
} from '@/shadcn/components/ui/table';

import styles from './data-table.scss';

const { useState, useCallback } = React;

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  draggable?: boolean;
  onColumnDragEnd?: (fromIndex: number, toIndex: number) => void;
}

export function DataTable<TData, TValue>({
  columns: initialColumns,
  data,
  onColumnDragEnd,
  draggable,
}: DataTableProps<TData, TValue>) {
  const [columns, setColumns] = useState<ColumnDef<TData, TValue>[]>(
    initialColumns || [],
  );

  const handleColumnDragEnd = useCallback(
    (fromIndex: number, toIndex: number) => {
      const cols = [...columns];
      const item = cols.splice(fromIndex, 1)[0];
      cols.splice(toIndex, 0, item);
      setColumns(cols);

      if (onColumnDragEnd) {
        onColumnDragEnd(fromIndex, toIndex);
      }
    },
    [onColumnDragEnd, columns, setColumns],
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const TableDef = () => (
    <Table className={styles.TableDef}>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  if (draggable) {
    return (
      <ReactDragListView.DragColumn
        onDragEnd={handleColumnDragEnd}
        lineClassName={styles.DragLine}
        nodeSelector="th"
      >
        <TableDef />
      </ReactDragListView.DragColumn>
    );
  }

  return <TableDef />;
}
