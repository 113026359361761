import { isUndefined } from 'lodash';
import { IUpdateAdsPermissionsAction } from '.';
import { IState } from '../../../../types/state';
import { isInstagram, isTiktok } from '../../../../utils/contentTypeUtils';

export const updateAdsPermissions = (state: IState, action: IUpdateAdsPermissionsAction): IState => {
  let { collaborationDetails: { bam }, contentGuidelines } = state;
  if (!isUndefined(action.adsPermissions.metaBamAccess)) {
    bam = {
      ...bam,
      toggleInstagramInsights: action.adsPermissions.metaBamAccess.toggle || bam.toggleInstagramInsights,
    };
  }
  if (!isUndefined(action.adsPermissions.bcPromoteAccess) && action.adsPermissions.bcPromoteAccess.toggle) {
    contentGuidelines = contentGuidelines.map((contentGuideline) => {
      if (isInstagram(contentGuideline?.type)) {
        return {
          ...contentGuideline,
          requiresBrandedContentTag: action.adsPermissions.bcPromoteAccess.toggle,
        };
      }
      return contentGuideline;
    });
    bam = {
      ...bam,
      toggleInstagramInsights: action.adsPermissions.bcPromoteAccess.toggle || bam.toggleInstagramInsights,
    };
  }
  if (action.adsPermissions?.tiktokSparkAdsAccess?.toggle) {
    contentGuidelines = contentGuidelines.map((contentGuideline) => {
      if (isTiktok(contentGuideline?.type)) {
        return {
          ...contentGuideline,
          requiresBrandedContentTag: action.adsPermissions.tiktokSparkAdsAccess.toggle,
        };
      }
      return contentGuideline;
    });
  }

  return ({
    ...state,
    contentGuidelines,
    collaborationDetails: {
      ...state.collaborationDetails,
      bam,
      adsPermissions: {
        ...state.collaborationDetails.adsPermissions,
        ...action.adsPermissions,
      },
    },
  });
};
