import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const ChevronRightIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 6 10"
    >
      <path
        d="
        M1.18945 9.5C0.978516 9.5 0.791016 9.42969 0.650391 9.28906C0.345703 9.00781 0.345703 8.51562 0.650391 8.23438L3.86133 5L0.650391 1.78906C0.345703 1.50781 0.345703 1.01562 0.650391 0.734375C0.931641 0.429688 1.42383 0.429688 1.70508 0.734375L5.45508 4.48438C5.75977 4.76562 5.75977 5.25781 5.45508 5.53906L1.70508 9.28906C1.56445 9.42969 1.37695 9.5 1.18945 9.5Z
        "
      />
      <mask
        id={id}
        maskUnits="userSpaceOnUse"
      >
        <path
          d="
          M1.18945 9.5C0.978516 9.5 0.791016 9.42969 0.650391 9.28906C0.345703 9.00781 0.345703 8.51562 0.650391 8.23438L3.86133 5L0.650391 1.78906C0.345703 1.50781 0.345703 1.01562 0.650391 0.734375C0.931641 0.429688 1.42383 0.429688 1.70508 0.734375L5.45508 4.48438C5.75977 4.76562 5.75977 5.25781 5.45508 5.53906L1.70508 9.28906C1.56445 9.42969 1.37695 9.5 1.18945 9.5Z
          "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

ChevronRightIcon.displayName = 'ChevronRightIcon';

export { ChevronRightIcon };
