import * as React from 'react';
import { isEmpty } from 'lodash';

import { TTask, TWorkItem } from '@frontend/app/containers/Projects/types';
import { useBulkMarkAsDelivered } from '@frontend/applications/ProductFulfillmentApp/hooks';

const { useMemo } = React;

export const useCustomCTA = (
  projectId?: number,
  task?: TTask,
): ((workItems: TWorkItem[]) => Promise<void>
) => {
  const bulkMarkAsDelivered = useBulkMarkAsDelivered();

  return useMemo(() => {
    if (!projectId || isEmpty(task)) {
      return null;
    }

    switch (task.workletSpecUri) {
      case 'OfflineCreatorProductSelectionWorkletSpecification': {
        switch (task.taskId) {
          case 'offline_order_in_transit_task': {
            return (workItems) => bulkMarkAsDelivered(workItems);
          }
          default: {
            return null;
          }
        }
      }
      default: {
        return null;
      }
    }
  }, [bulkMarkAsDelivered, projectId, task]);
};
