import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/client';

import { message } from 'antd';

import { SET_NOTIFICATION_SETTINGS_MUTATION } from '@frontend/app/queries';
import {
  SetNotificationSettingsMutation,
  SetNotificationSettingsMutationVariables,
} from '@frontend/app/queries/types/SetNotificationSettingsMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<SetNotificationSettingsMutation, SetNotificationSettingsMutationVariables>;

export const useSetNotificationSettings = (options: IOptions = {}) => {
  const [setNotificationSettings, { loading, error }] = useMutation<
  SetNotificationSettingsMutation,
  SetNotificationSettingsMutationVariables
  >(SET_NOTIFICATION_SETTINGS_MUTATION, {
    ...options,
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    setNotificationSettings,
    loading,
    error,
  };
};
