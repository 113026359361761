import * as React from 'react';
import cx from 'classnames';
import {
  filter,
  includes,
  isArray,
  map,
  reduce,
} from 'lodash';

import { LazyImage } from '@components';
import { Tooltip } from '@components';
import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';

import {
  ICardTabConfig,
  Card,
} from './Card';
import { Detail } from './Detail';
import { List } from './ListCard';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import {
  DetailConfigType,
  getDetailConfigs,
} from '../utils';

const { useCallback, useMemo, useRef } = React;
import styles from './SummaryCard.scss';

const BRAND_LIMIT = 15;

interface ISummaryCardProps {
  className?: string,
  version?: CreatorSearchVersion,
}

export const SummaryCard: React.FC<ISummaryCardProps> = (props) => {
  const {
    className,
    version = CreatorSearchVersion.v1,
  } = props;

  const {
    hasData,
    network,
  } = useSocialProfileContext();
  const audienceTabRef = useRef<HTMLDivElement>(null);
  const creatorTabRef = useRef<HTMLDivElement>(null);

  const tabs: ICardTabConfig[] = (() => {
    switch (network) {
      case 'blog':
      case 'facebook':
      case 'pinterest':
      case 'tiktok':
      case 'twitter':
        return [
          {
            key: 'AUDIENCE',
            header: 'Summary',
            contentRef: audienceTabRef,
            content: <AudienceSummary version={version} />,
            tabClassName: styles.audienceTab,
          },
        ];
      case 'instagram':
        return [
          {
            key: 'AUDIENCE',
            header: 'Audience Summary',
            tabClassName: styles.audienceTab,
            contentRef: audienceTabRef,
            content: <AudienceSummary version={version} />,
          },
          {
            key: 'CREATOR',
            header: 'Creator Summary',
            tabClassName: styles.creatorTab,
            contentRef: creatorTabRef,
            content: <CreatorSummary />,
          },
        ];
    }
    return [];
  })();

  return (
    <Card
      className={cx(className, styles.SummaryCard, styles[network])}
      header={hasData ? `${tabs[0].header}` : ''}
      tabConfigs={hasData ? tabs : []}
    />
  );
};

SummaryCard.displayName = 'SummaryCard';

interface IAudienceSummaryProps {
  version?: CreatorSearchVersion,
}

const AudienceSummary: React.FC<IAudienceSummaryProps> = (props) => {
  const {
    version = CreatorSearchVersion.v1,
  } = props;

  const {
    hasData,
    network,
    socialAccount,
    fetchingDetailForId,
  } = useSocialProfileContext();

  const isLoading = !!fetchingDetailForId;

  const filterDetailsByVersion = useCallback((detailTypes: DetailConfigType[]) => (
    filter(detailTypes, (detail) => {
      if (version === CreatorSearchVersion.v1) {
        return !includes([
          DetailConfigType.TopState,
          DetailConfigType.Interests,
        ], detail);
      }

      return true;
    })
  ), [version]);

  // Set detail order and values
  const detailConfigs = useMemo(() => {
    if (!hasData) {
      return [];
    }
    switch (network) {
      case 'blog':
        return getDetailConfigs({
          detailTypes: filterDetailsByVersion([
            DetailConfigType.Followers,
            DetailConfigType.BounceRate,
            DetailConfigType.PageViewsPerVisit,
            DetailConfigType.SecondsOnSite,
          ]),
          socialAccount,
          styles,
          isLoading,
        });
      case 'facebook':
        return getDetailConfigs({
          detailTypes: filterDetailsByVersion([
            DetailConfigType.Likes,
            DetailConfigType.FollowerBasedEngagementRate,
            DetailConfigType.AverageEngagementPerPost,
          ]),
          socialAccount,
          styles,
          isLoading,
        });
      case 'instagram':
        return getDetailConfigs({
          detailTypes: filterDetailsByVersion([
            DetailConfigType.AverageEngagementPerPost,
            DetailConfigType.ImpressionPerStory,
            DetailConfigType.AudienceAuthenticity,
            DetailConfigType.Gender,
            DetailConfigType.TopCountry,
            DetailConfigType.AgeRange,
            DetailConfigType.TopState,
            DetailConfigType.Interests,
          ]),
          socialAccount,
          styles,
          isLoading,
        });
      case 'pinterest':
        return getDetailConfigs({
          detailTypes: filterDetailsByVersion([
            DetailConfigType.AverageEngagementPerPost,
            DetailConfigType.ViewBasedEngagementRate,
            DetailConfigType.OriginalContentRatio,
            DetailConfigType.Followers,
          ]),
          socialAccount,
          styles,
          isLoading,
        });
      case 'tiktok':
        return getDetailConfigs({
          detailTypes: filterDetailsByVersion([
            DetailConfigType.TypicalViewsPerPost,
            DetailConfigType.LikesPerPost,
            DetailConfigType.AverageViewsPerPost,
            DetailConfigType.CommentsPerPost,
            DetailConfigType.ViralPostRatio,
            DetailConfigType.ViewBasedEngagementRate,
          ]),
          socialAccount,
          styles,
          isLoading,
        });
      case 'twitter':
        return getDetailConfigs({
          detailTypes: filterDetailsByVersion([
            DetailConfigType.AverageEngagementPerPost,
            DetailConfigType.FollowerBasedEngagementRate,
          ]),
          socialAccount,
          styles,
          isLoading,
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasData, network, socialAccount]);

  return (
    <div className={styles.detailGrid}>
      {map(detailConfigs, (data, i) => (
        <Detail
          data={data}
          key={`audience-summary-data-${i}`}
        />
      ))}
    </div>
  );
};

const CreatorSummary: React.FC = () => {
  const {
    hasData,
    socialAccount,
  } = useSocialProfileContext();

  // Interests
  const interests = hasData && isArray(socialAccount.interests)
    ? socialAccount.interests.slice(0, 4)
    : [];
  const hasInterests = interests.length > 0;
  const renderInterests = () => (
    <div className={styles.interestsWrapper}>
      <List
        flow="vertical"
        items={interests}
      />
    </div>
  );

  // Top brands
  const topBrands = hasData && socialAccount.report && isArray(socialAccount.report.top_brands)
    ? socialAccount.report.top_brands
    : [];
  const hasTopBrands = topBrands.length > 0;
  const renderBrandGrid = () => {
    const brandTooltipsRef: { [name: string]: React.RefObject<HTMLImageElement> } = (
      reduce(topBrands.slice(0, BRAND_LIMIT), (refs, brand) => {
        refs[brand.username] = React.createRef<HTMLImageElement>();
        return refs;
      }, {})
    );
    return (
      <div className={styles.brandGrid}>
        {topBrands.slice(0, BRAND_LIMIT).map((brand, i) => (
          <React.Fragment key={`brand-${i}`}>
            <LazyImage
              className={styles.brand}
              src={brand.logo_url}
              ref={brandTooltipsRef[brand.username]}
            />
            <Tooltip
              placement="bottom"
              mountRef={brandTooltipsRef[brand.username]}
              autoRegisterListener
              tooltipColor="black"
            >
              {brand.name}
            </Tooltip>
          </React.Fragment>
        ))}
        {topBrands.length > BRAND_LIMIT && (
          <div className={styles.moreBrands}>
            +
            {topBrands.length - BRAND_LIMIT}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.creatorSummary}>
      <div
        className={cx(styles.interests, {
          [styles.noData]: !hasInterests,
        })}
      >
        <h5>Interests</h5>
        {hasInterests && renderInterests()}
      </div>
      <div
        className={cx(styles.brands, {
          [styles.noData]: !hasTopBrands,
        })}
      >
        <h5>Brand Mentions</h5>
        {hasTopBrands && renderBrandGrid()}
      </div>
    </div>
  );
};
