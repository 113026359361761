/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14juj_157 {
  display: flex;
}

._justify-content-space-between_14juj_161 {
  justify-content: space-between;
}

._tabular-nums_14juj_165 {
  font-variant-numeric: tabular-nums;
}

._SimilarCreatorList_14juj_169 ::selection, ._SocialProfile_14juj_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_14juj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._SimilarCreatorList_14juj_169, ._SocialProfile_14juj_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._SimilarCreatorList_14juj_169 ::-webkit-scrollbar, ._SocialProfile_14juj_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._SimilarCreatorList_14juj_169 ::-webkit-scrollbar-track, ._SocialProfile_14juj_169 ::-webkit-scrollbar-track, ._SimilarCreatorList_14juj_169 ::-webkit-scrollbar-track-piece, ._SocialProfile_14juj_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._SimilarCreatorList_14juj_169 ::-webkit-scrollbar-thumb, ._SocialProfile_14juj_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14juj_157 {
  display: flex;
}

._justify-content-space-between_14juj_161 {
  justify-content: space-between;
}

._tabular-nums_14juj_165 {
  font-variant-numeric: tabular-nums;
}

._SimilarCreatorList_14juj_169 ::selection, ._SocialProfile_14juj_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_14juj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._SimilarCreatorList_14juj_169, ._SocialProfile_14juj_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._SimilarCreatorList_14juj_169 ::-webkit-scrollbar, ._SocialProfile_14juj_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._SimilarCreatorList_14juj_169 ::-webkit-scrollbar-track, ._SocialProfile_14juj_169 ::-webkit-scrollbar-track, ._SimilarCreatorList_14juj_169 ::-webkit-scrollbar-track-piece, ._SocialProfile_14juj_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._SimilarCreatorList_14juj_169 ::-webkit-scrollbar-thumb, ._SocialProfile_14juj_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._SocialProfileOverlay_14juj_407 [class*=content] {
  position: relative;
}
._SocialProfileOverlay_14juj_407 [class*=content] ._SocialProfile_14juj_169 {
  margin-bottom: 3rem;
}

._SocialProfile_14juj_169 {
  padding: 1.5rem 1.5rem 3rem;
  position: relative;
  width: 68.5rem;
  background-color: #eff5f9;
  border-radius: 0.75rem;
  box-sizing: border-box;
}

._SimilarCreatorList_14juj_169 {
  width: 68.5rem;
}
._SimilarCreatorList_14juj_169 ._loadSpinner_14juj_426 {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
._SimilarCreatorList_14juj_169 header h4 {
  margin: 0;
  color: #aeaeae;
  font-size: 1rem;
  font-weight: 500;
}
._SimilarCreatorList_14juj_169 ._creatorList_14juj_436 {
  display: block;
  flex: 1;
  column-count: 3;
  column-width: auto;
  column-gap: 1.5rem;
}
._SimilarCreatorList_14juj_169 ._creatorList_14juj_436 ._creatorTile_14juj_443 {
  margin-bottom: 1.5rem;
  padding: 0;
  break-inside: avoid;
}
._SimilarCreatorList_14juj_169 ._creatorList_14juj_436 ._creatorTile_14juj_443 ._content_14juj_448 ._details_14juj_448 ._metrics_14juj_448 ._reach_14juj_448 ._amount_14juj_448 {
  color: #fdfdfd !important;
}
._SimilarCreatorList_14juj_169 ._creatorList_14juj_436 ._creatorTile_14juj_443:hover {
  background-color: transparent;
}
._SimilarCreatorList_14juj_169 ._creatorList_14juj_436 ._creatorTile_14juj_443 [class*=details],
._SimilarCreatorList_14juj_169 ._creatorList_14juj_436 ._creatorTile_14juj_443 [class*=details] a {
  color: #fdfdfd !important;
}
._SimilarCreatorList_14juj_169 ._creatorList_14juj_436:not(:empty) {
  margin-top: 2rem;
  margin-bottom: 4rem;
}