import gql from 'graphql-tag';

import { AUTOMATION_TRIGGER_FRAGMENT } from './AutomationTrigger';

export const AUTOMATION_FRAGMENT = gql`
  fragment AutomationFragment on Automation {
    id
    name
    description
    status
    previousStatus
    triggers {
      ...AutomationTriggerFragment
    }
    clientId
    programId
    templateId
    version
    revision
    metadata {
      icon
    }
    createdDate
    updatedDate
  }
  ${AUTOMATION_TRIGGER_FRAGMENT}
`;

export const AUTOMATION_BLUEPRINT_FRAGMENT = gql`
  fragment AutomationBlueprintFragment on AutomationBlueprint {
    id
    name
    description
    triggers {
      ...AutomationTriggerFragment
    }
    programId
    templateId
    metadata {
      icon
    }
  }
  ${AUTOMATION_TRIGGER_FRAGMENT}
`;
