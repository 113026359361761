import { START_GDRIVE_SYNC_MUTATION } from '@frontend/app/queries';

import { useMutation, MutationHookOptions } from '@apollo/client';
import { StartGDriveSyncMutation } from '../queries/types/StartGDriveSyncMutation';

type IOptions = MutationHookOptions<StartGDriveSyncMutation>;

export const useStartGDriveSyncMutation = (
  options: IOptions = {},
  ) => useMutation<StartGDriveSyncMutation>(START_GDRIVE_SYNC_MUTATION, options);
