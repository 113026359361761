import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_APPLICATION_INSTANCES } from '@frontend/app/queries';
import {
  GetApplicationInstances,
} from '@frontend/app/queries/types/GetApplicationInstances';

type IOptions = QueryHookOptions<GetApplicationInstances>;

export function useInstalledApplicationInstances(options: IOptions = {}) {
  return useQuery<GetApplicationInstances>(
    GET_APPLICATION_INSTANCES,
    options,
  );
}
