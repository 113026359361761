import gql from 'graphql-tag';

import {
  MEMBER_FRAGMENT,
  MESSAGE_FRAGMENT_WITH_PAYLOAD,
  MESSAGE_THREAD_FRAGMENT,
  MESSAGE_THREAD_USER_LABEL_FRAGMENT,
} from './fragments';

export const GET_THREADS_QUERY = gql`
  query GetThreadsQuery($skip: Int!, $filter: ThreadFilterInput, $includeReadStatus: Boolean = false, $limit: Int!) {
    threads: getAllThreads(skip: $skip, filter: $filter, limit: $limit) {
      ...MessageThreadFragment
      messages {
        ...MessageFragmentWithPayload
      }
      userLabels {
        ...MessageThreadUserLabelFragment
      }
      members {
        ...MemberFragment
      }
      readStatus(filter: $filter) @include(if: $includeReadStatus) {
        isRead
        lastReadByDate
        lastReadByUserEmail
        lastReadByUserName
      }
    }
  }
  ${MESSAGE_THREAD_FRAGMENT}
  ${MEMBER_FRAGMENT}
  ${MESSAGE_THREAD_USER_LABEL_FRAGMENT}
`;

export const GET_THREADS_FOR_MEMBER_QUERY = gql`
  query GetThreadsForMember($memberId: Int!, $messageTypes: [MessageType!]) {
    threads: getThreadsForMember(memberId: $memberId, messageTypes: $messageTypes) {
      ...MessageThreadFragment
      messagesCount
      messages {
        ...MessageFragmentWithPayload
      }
    }
  }
  ${MESSAGE_THREAD_FRAGMENT}
`;

export const GET_ALL_THREADS_COUNT_QUERY = gql`
  query GetAllThreadsCountQuery($filter: ThreadFilterInput) {
    count: getAllThreadsCount(filter: $filter)
  }
`;

export const GET_ALL_RESOURCE_THREADS_COUNT_QUERY = gql`
  query GetAllResourceThreadsCountQuery($filter: ThreadFilterInput) {
    result: getAllResourceThreadsCount(filter: $filter) {
      resourceId
      count
    }
  }
`;

export const GET_THREAD_QUERY = gql`
  query GetThreadQuery($id: String!, $includeReadStatus: Boolean = false) {
    thread: getThreadById(id: $id) {
      ...MessageThreadFragment
      messages {
        ...MessageFragmentWithPayload
        members {
          id
          email
          name
          profilePicture
        }
      }
      members {
        ...MemberFragment
      }
      userLabels {
        ...MessageThreadUserLabelFragment
      }
      readStatus @include(if: $includeReadStatus) {
        isRead
        lastReadByDate
        lastReadByUserEmail
        lastReadByUserName
      }
      isFromSharedEmail
    }
  }
  ${MESSAGE_THREAD_FRAGMENT}
  ${MESSAGE_FRAGMENT_WITH_PAYLOAD}
  ${MEMBER_FRAGMENT}
  ${MESSAGE_THREAD_USER_LABEL_FRAGMENT}
`;

export const THREAD_MARK_DONE_MUTATION = gql`
  mutation ThreadMarkDone($id: String!) {
    threadMarkDone(id: $id)
  }
`;

export const THREAD_MARK_DONE_ALL_MUTATION = gql`
  mutation ThreadMarkDoneAll($filter: ThreadFilterInput!) {
    success: threadMarkDoneAll(filter: $filter)
  }
`;

export const THREAD_LABEL_UPDATE_BY_IDS_MUTATION = gql`
  mutation ThreadLabelUpdateByIds($threadIds: [String!]!, $label: ThreadLabelType!) {
    succcess: threadLabelUpdateByIds(threadIds: $threadIds, label: $label)
  }
`;

export const THREAD_MARK_TODO_MUTATION = gql`
  mutation ThreadMarkTodo($id: String!) {
    threadMarkTodo(id: $id)
  }
`;

export const ASSIGN_TO_THREAD_MUTATION = gql`
  mutation AssignToThread($threadId: String!, $userId: String!) {
    assignToThread(threadId: $threadId, userId: $userId)
  }
`;

export const UNASSIGN_FROM_THREAD_MUTATION = gql`
  mutation UnassignFromThread($threadId: String!, $userId: String!) {
    unassignFromThread(threadId: $threadId, userId: $userId)
  }
`;

export const THREAD_MARK_READ_MUTATION = gql`
  mutation UpdateThreadToReadStatus($threadIds: [String!]!) {
    updateMessageThreadReadStatus(threadIds: $threadIds, isRead: true)
  }
`;

export const THREAD_MARK_UNREAD_MUTATION = gql`
  mutation UpdateThreadToUnreadStatus($threadIds: [String!]!) {
    updateMessageThreadReadStatus(threadIds: $threadIds, isRead: false)
  }
`;
