import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { GetConnectedShopify_clientConnections } from '@frontend/applications/AffiliatesApp/queries/types/GetConnectedShopify';
import {
  Checkbox, Form, Input,
} from '@revfluence/fresh';
import * as React from 'react';
import { FormInstance } from 'antd';
import { FormAsyncAction, IShopifyPromoCodeFormValues, OfferFormAsyncActions } from '../../../types';
import { validateNonNegativeInteger } from '../../../utils';

interface IProps {
  disabled: boolean,
  values: IShopifyPromoCodeFormValues;
  handleFormAsyncActions: (value: FormAsyncAction) => void,
  formRef: FormInstance,
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
}

export const MultipleDiscountOptions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  values,
  formRef,
  handleFormAsyncActions,
  connectedAdvertiserForSync,
}) => {
  const clientsForSync = values.clientsForSync;
  const isSameDiscountMultipleShopify = values.isSameDiscountMultipleShopify;
  return (
    <>
      {
        clientsForSync.length >= 1 && (
          <Form.Item name="isSameDiscountMultipleShopify">
            <Checkbox
              onChange={(e) => handleFormAsyncActions({ action: OfferFormAsyncActions.UPDATE_FIELD, payload: { key: 'isSameDiscountMultipleShopify', value: e.target.checked } })}
              checked={isSameDiscountMultipleShopify}
              disabled={disabled}
            >
              Use the same Discount value across other Shopify stores
            </Checkbox>
          </Form.Item>
        )
      }
      {
        !isSameDiscountMultipleShopify && (
          <Form.List
            name="clientsForSync"
          >
            {(fields) => (
              <>
                {fields.map(({ key, name }, index) => (
                  <MultipleDiscountOption
                    key={key}
                    name={name}
                    index={index}
                    connectedAdvertiserForSync={connectedAdvertiserForSync}
                    formRef={formRef}
                    disabled={disabled}
                  />
                ))}
              </>
            )}
          </Form.List>
        )
      }
    </>
  );
});
MultipleDiscountOptions.displayName = 'MultipleDiscountOptions';

interface IProp {
  name: number,
  index: number,
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[],
  formRef: FormInstance,
  disabled: boolean,
}
export const MultipleDiscountOption: React.FC<Readonly<IProp>> = React.memo(({
  name, index, connectedAdvertiserForSync, formRef, disabled,
}) => {
  const rules = [
    { required: true, message: 'Please input discount value' },
    { validator: validateNonNegativeInteger },
  ];

  const currentValues = formRef.getFieldValue('clientsForSync');
  const client = connectedAdvertiserForSync.find((c) => c.connectedAdvertiserId === currentValues[index].advertiserId);

  return (
    <>
      <Form.Item
        label={`${client?.label} Discount Value`}
        name={[name, 'priceRuleAmount']}
        rules={rules}
      >
        <Input
          disabled={disabled}
          size="large"
          type="number"
          max={100}
          suffix="%"
          onKeyDown={UseNaturalNumbers}
          placeholder="Discount Value"
        />
      </Form.Item>
    </>
  );
});
