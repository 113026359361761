import React from 'react';
import { cn } from '@/shadcn/lib/utils';

interface H3Props extends React.ComponentPropsWithoutRef<'h3'> {
  fontWeight?: 'font-normal' | 'font-medium' | 'font-semibold' | 'font-bold'; // Optional font weight prop
}

export const H3 = React.forwardRef<HTMLHeadingElement, H3Props>(
  ({
    className, fontWeight, children, ...props
  }, ref) => {
    // Default classes for H3
    const defaultClasses = 'text-lg leading-relaxed tracking-tight scroll-m-20';

    // Determine the appropriate font weight class, default to 'font-normal'
    const fontWeightClass = fontWeight || 'font-normal';

    return (
      <h3
        ref={ref}
        className={cn(defaultClasses, fontWeightClass, className)} // Combine default classes, font weight, and additional class names
        {...props}
      >
        {children}
      </h3>
    );
  },
);

H3.displayName = 'H3';
