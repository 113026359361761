import * as React from 'react';
import { Typography, Badge, Button } from '@revfluence/fresh';
import { Link } from 'react-router-dom';

import { useOverviewPage } from '@frontend/app/containers/Projects/hooks';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import {
 DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { Button as ShadCnButton } from '@frontend/shadcn/components/ui/button';
import { ChevronDown } from 'lucide-react';
import { ProjectMarketplaceStatus } from '../../constants';
import { STATUS } from '../../constants';
import styles from './Status.module.scss';

const { useMemo } = React;
const { Text } = Typography;

type Props = {
  status: string;
  date?: string;
  url?: string;
  handleCTAClick?(): void;
};

const Status: React.FC<Props> = React.memo(({
 status, date, url, handleCTAClick,
}) => {
  const {
 message, messageResponsive, color, cta, statusLabel,
} = useMemo(() => {
    if (status) return STATUS[status];
    return {
      message: '',
      messageResponsive: '',
      color: '',
      cta: null,
      statusLabel: '',
    };
  }, [status]);

  const { handleCheckScreenSize } = useOverviewPage();
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const { isMobile } = handleCheckScreenSize();

  if (isRefreshUIEnabled) {
    const colorVariantMap = {
      orange: 'warning',
      red: 'destructive',
      green: 'success',
    };

    if (!statusLabel) {
      return null;
    }

    return (
      <DropdownMenu>
        <DropdownMenuTrigger>
          <ShadCnButton size="xs" variant={colorVariantMap[color] ?? 'secondary'}>
            <div className="flex gap-1 items-center">
              {statusLabel}
              {!!cta?.label && <ChevronDown size={12} />}
            </div>
          </ShadCnButton>
        </DropdownMenuTrigger>
        {!!cta?.label && (
          <DropdownMenuContent className="shadcn">
            <DropdownMenuItem>{url ? <Link className="text-primary hover:text-primary" to={url}>{cta.label}</Link> : cta.label}</DropdownMenuItem>
          </DropdownMenuContent>
        )}
      </DropdownMenu>
    );
  }

  return (
    <Text className={styles.statusWrapper}>
      <Badge color={color} className={styles.badgeIcon} />
      <Text style={{ color }}>
        {isMobile ? messageResponsive : message}
        {[
          ProjectMarketplaceStatus.AutoUnlistedFromCreatorMarketplace,
          ProjectMarketplaceStatus.UserUnlistedFromCreatorMarketplace,
        ].includes(status as ProjectMarketplaceStatus) && date}
        {' '}
      </Text>
      {!!cta?.label && (
        <Button className={styles.buttonWrapper} type="link" onClick={handleCTAClick}>
          {url && <Link to={url}>{cta.label}</Link>}
          {!url && cta.label}
        </Button>
      )}
    </Text>
  );
});

Status.displayName = 'Status';

export default Status;
