import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const AppsIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M8.99842 2.01758H3.90047C2.85116 2.01758 2 2.86874 2 3.91805V9.016C2 10.0653 2.85116 10.921 3.90047 10.921H8.99842C10.0477 10.921 10.8989 10.0653 10.8989 9.016V3.91805C10.9034 2.86874 10.0522 2.01758 8.99842 2.01758ZM9.80005 9.016C9.80005 9.45734 9.43977 9.81312 8.99842 9.81312H3.90047C3.45913 9.81312 3.09885 9.45284 3.09885 9.016V3.91805C3.09885 3.47671 3.45913 3.12093 3.90047 3.12093H8.99842C9.43977 3.12093 9.80005 3.48121 9.80005 3.91805V9.016Z" fill="#1A1818" />
    <path d="M20.0453 2H14.9473C13.898 2 13.0469 2.85566 13.0469 3.90498V9.00293C13.0469 10.0522 13.898 10.9079 14.9473 10.9079H20.0453C21.0946 10.9079 21.9458 10.0522 21.9458 9.00293V3.90498C21.9458 2.85566 21.0946 2 20.0453 2ZM20.8424 9.00293C20.8424 9.44427 20.4821 9.80004 20.0408 9.80004H14.9473C14.506 9.80004 14.1457 9.43977 14.1457 9.00293V3.90498C14.1457 3.46363 14.506 3.10786 14.9473 3.10786H20.0453C20.4866 3.10786 20.8469 3.46814 20.8469 3.90498V9.00293H20.8424Z" fill="#1A1818" />
    <path d="M8.99842 13.0918H3.90047C2.85116 13.0918 2 13.9475 2 14.9968V20.0947C2 21.144 2.85116 21.9997 3.90047 21.9997H8.99842C10.0477 21.9997 10.8989 21.144 10.8989 20.0947V14.9968C10.9034 13.9475 10.0522 13.0918 8.99842 13.0918ZM9.80005 20.0947C9.80005 20.5361 9.43977 20.8918 8.99842 20.8918H3.90047C3.45913 20.8918 3.09885 20.5316 3.09885 20.0947V14.9968C3.09885 14.5554 3.45913 14.1997 3.90047 14.1997H8.99842C9.43977 14.1997 9.80005 14.5599 9.80005 14.9968V20.0947Z" fill="#1A1818" />
    <path d="M20.0453 13.0781H14.9473C13.898 13.0781 13.0469 13.9338 13.0469 14.9831V20.0811C13.0469 21.1304 13.898 21.986 14.9473 21.986H20.0453C21.0946 21.986 21.9458 21.1304 21.9458 20.0811V14.9786C21.9458 13.9293 21.0946 13.0781 20.0453 13.0781ZM20.8424 20.0765C20.8424 20.5179 20.4821 20.8737 20.0408 20.8737H14.9473C14.506 20.8737 14.1457 20.5134 14.1457 20.0765V14.9786C14.1457 14.5373 14.506 14.1815 14.9473 14.1815H20.0453C20.4866 14.1815 20.8469 14.5418 20.8469 14.9786V20.0765H20.8424Z" fill="#1A1818" />
  </SvgIcon>
));

AppsIcon.displayName = 'AppsIcon';
