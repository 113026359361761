import React, { useEffect, useMemo } from 'react';
import { Checkbox, ITableProps, Table } from '@revfluence/fresh';
import { useParams } from 'react-router-dom';
import { useVariantTableProps } from './useVariantTableProps';
import { IVariantsRow } from '../../Products/types';
import { useBasicSelectionContext } from './BasicSelectionContext';
import { useGetProductDetails } from '../../Products/hooks/useGetProductDetails';
import { useGetCatalogCollectionByIdQuery } from '../hooks/useGetCatalogCollectionById';

export interface IVariantTableProps {
  productId: string;
  pageNumber: number;
  isReadonly?: boolean;
}

export const VariantTable = ({ productId, pageNumber, isReadonly = false }: IVariantTableProps) => {
  const { product, loading } = useGetProductDetails({
    variables: {
      productId: Number(productId),
    },
    skip: !productId,
  });
  const { collectionId } = useParams<{ collectionId: string }>();
  const { collectionItem } = useGetCatalogCollectionByIdQuery({
    variables: {
      id: Number(collectionId),
      includeProductDetails: true,
      page: pageNumber,
      pageSize: 50,
    },
    fetchPolicy: 'cache-only',
    skip: !collectionId,
    notifyOnNetworkStatusChange: true,
  });
  const {
    selectedProducts,
    toggleVariant,
    bulkSelectVariants,
    isEditing,
  } = useBasicSelectionContext();

  const { columnConfig, tableData } = useVariantTableProps({
    variants: product?.productVariants ?? [],
  });

  const onSelectChange = (id: string, checked: boolean) => {
    toggleVariant({
      variantId: id,
      productId: String(product?.id),
      checked,
      allVariantIds: product?.productVariants?.map((v) => String(v.id)) ?? [],
    });
  };

  const selectedProduct = useMemo(() => selectedProducts.find((selectedProduct) => selectedProduct.productId === productId), [selectedProducts, productId]);

  const selectedRowKeys = useMemo(() => {
    if (isReadonly) {
      const selectedProductFromBE = collectionItem.catalogProducts.find((product) => product.productId === Number(productId));
      if (!selectedProductFromBE) {
        return [];
      }
      const isAllVariantsSelected = selectedProductFromBE.variantIds.length === 0;
      return isAllVariantsSelected ? product.productVariants?.map((v) => String(v.id)) : selectedProductFromBE.variantIds.map((v) => String(v));
    } else {
      const isAllVariantsSelected = selectedProduct?.variantIds.length === 0;
      return isAllVariantsSelected ? product.productVariants?.map((v) => String(v.id)) : selectedProduct?.variantIds;
    }
  }, [collectionItem.catalogProducts, isReadonly, product.productVariants, productId, selectedProduct?.variantIds]);

  const rowSelection: ITableProps<IVariantsRow>['rowSelection'] = {
    selectedRowKeys,
    renderCell: (value, record) => <Checkbox disabled={isReadonly} checked={value} onChange={(e) => onSelectChange(record.id, e.target.checked)} />,
  };

  useEffect(() => {
    if (collectionItem?.catalogProducts.length && isEditing && product?.id && selectedProduct && selectedProduct.variantIds.length === 0) {
      const selectedProductFromBE = collectionItem.catalogProducts.find((product) => product.productId === Number(productId));
      if (!selectedProductFromBE) {
        return;
      }
      let selectedVariants = selectedProductFromBE?.variantIds ?? [];
      if (selectedVariants.length === 0) {
        selectedVariants = product?.productVariants?.map((v) => (v.id)) ?? [];
      }
      bulkSelectVariants({
        productId,
        variantIds: selectedVariants.map((v) => String(v)),
      });
    }
  }, [bulkSelectVariants, collectionItem?.catalogProducts, isEditing, product?.id, product?.productVariants, productId, selectedProduct]);

  return (
    <div>
      <Table
        columns={columnConfig}
        dataSource={tableData}
        size="small"
        rowSelection={rowSelection}
        showHeader={false}
        rowKey="id"
        pagination={false}
        loading={loading}
      />
    </div>
  );
};
