/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1gcl3_157 {
  display: flex;
}

._justify-content-space-between_1gcl3_161 {
  justify-content: space-between;
}

._tabular-nums_1gcl3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1gcl3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NewSavedSearchForm_1gcl3_178 ._header_1gcl3_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._SavedSearches_1gcl3_188 ._label_1gcl3_188 ._text_1gcl3_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SavedSearches_1gcl3_188 {
  margin-bottom: 20px;
}
._SavedSearches_1gcl3_188 ._label_1gcl3_188 {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
._SavedSearches_1gcl3_188 ._label_1gcl3_188 ._text_1gcl3_188 {
  padding-right: 12px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._SavedSearches_1gcl3_188 ._label_1gcl3_188 ._text_1gcl3_188 ._name_1gcl3_293 {
  color: #3996e0;
  font-weight: bold;
}
._SavedSearches_1gcl3_188 ._label_1gcl3_188 ._text_1gcl3_188 ._pageNumber_1gcl3_297 {
  color: #8f8d91;
  font-style: italic;
  font-weight: normal;
}
._SavedSearches_1gcl3_188 ._label_1gcl3_188 ._arrow_1gcl3_302 {
  position: absolute;
  right: -16px;
  top: 0;
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._NewSavedSearchForm_1gcl3_178 {
  padding-left: 8px;
  padding-right: 8px;
}
._NewSavedSearchForm_1gcl3_178 ._header_1gcl3_178 {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
._NewSavedSearchForm_1gcl3_178 ._header_1gcl3_178 ._iconButton_1gcl3_322 {
  margin-right: 4px;
}
._NewSavedSearchForm_1gcl3_178 ._form_1gcl3_325 {
  display: flex;
}
._NewSavedSearchForm_1gcl3_178 ._form_1gcl3_325 ._input_1gcl3_328 {
  margin-right: 8px;
  flex: 1;
}

._popover_1gcl3_333 {
  max-width: auto !important;
}
._popover_1gcl3_333 ._actionButtonsContainer_1gcl3_336 {
  padding: 0 8px;
  display: flex;
}
._popover_1gcl3_333 ._actionButtonsContainer_1gcl3_336:not(:only-child) {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #ececec;
}
._popover_1gcl3_333 ._actionButtonsContainer_1gcl3_336 > div {
  flex: 1;
}
._popover_1gcl3_333 ._actionButtonsContainer_1gcl3_336 > div:not(:last-child) {
  margin-right: 4px;
}
._popover_1gcl3_333 ._actionButtonsContainer_1gcl3_336 > div:not(:first-child) {
  margin-left: 4px;
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354 {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354 ._image_1gcl3_360 {
  width: 40px;
  max-width: 40px;
  max-height: 40px;
  margin-right: 8px;
  border-radius: 4px;
  object-fit: cover;
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354 ._name_1gcl3_293 {
  flex: 1;
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354 ._pageNumber_1gcl3_297 {
  color: #8f8d91;
  font-style: italic;
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354 ._closeWrapper_1gcl3_375 {
  line-height: 12px;
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354 ._close_1gcl3_375 {
  color: #1a1818;
  will-change: color;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354 ._close_1gcl3_375:hover {
  color: #d9534f;
}
._popover_1gcl3_333 ._savedSearch_1gcl3_354:hover, ._popover_1gcl3_333 ._savedSearch_1gcl3_354._selected_1gcl3_386 {
  background-color: #e6edf2;
  color: #3996e0;
}