import React, { useMemo } from 'react';
import { Menu } from '@revfluence/fresh';
import { AutomationStatus } from '@frontend/app/types/globalTypes';
import ActionItem from './ActionItem';
import { AutomationAction, actionItems } from '../constants';

type IProps = {
  automationStatus: AutomationStatus;
  onDisableAutomation: () => void;
  onDeleteAutomation: () => void;
  onResumeAutomation: () => void;
};

const ActionMenu = (props: IProps) => {
  const {
    automationStatus,
    onDeleteAutomation,
    onDisableAutomation,
    onResumeAutomation,
  } = props;

  const displayedActionItems = useMemo(() => actionItems.filter((item) => {
    if (automationStatus === AutomationStatus.DISABLED) {
      return item.action !== AutomationAction.DISABLE;
    } else if (automationStatus === AutomationStatus.ACTIVE) {
      return item.action !== AutomationAction.RESUME;
    }
    return true;
  }), [automationStatus]);

  return (
    <Menu>
      {
        displayedActionItems.map((item, idx) => (
          <ActionItem
            key={idx}
            name={item.name}
            action={item.action}
            onDeleteAutomation={onDeleteAutomation}
            onDisableAutomation={onDisableAutomation}
            onResumeAutomation={onResumeAutomation}
          />
        ))
      }
    </Menu>
  );
};

export default ActionMenu;
