/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_585rk_157 {
  display: flex;
}

._justify-content-space-between_585rk_161 {
  justify-content: space-between;
}

._tabular-nums_585rk_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_585rk_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SelectList_585rk_178 {
  display: flex;
  flex-direction: column;
}
._SelectList_585rk_178._disabled_585rk_182 {
  opacity: 0.7;
  pointer-events: none;
}
._SelectList_585rk_178 ._list_585rk_186 {
  max-height: 60vh;
}
._SelectList_585rk_178 ._item_585rk_189 {
  padding: var(--spacing-xs) 0;
  border: 0;
}
._SelectList_585rk_178 ._item_585rk_189:hover {
  background-color: var(--primary-10);
}
._SelectList_585rk_178 ._item_585rk_189:hover ._checkbox_585rk_196 .ant-checkbox + span {
  color: var(--primary-40);
}
._SelectList_585rk_178 ._item_585rk_189 ._fieldLabel_585rk_199 {
  margin-left: 1.875rem;
}
._SelectList_585rk_178 ._checkbox_585rk_196 {
  display: flex;
  align-items: center;
  padding-left: var(--spacing-xs);
}
._SelectList_585rk_178 ._checkbox_585rk_196 .ant-checkbox {
  top: 0;
}
._SelectList_585rk_178 ._checkbox_585rk_196 .ant-checkbox + span {
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
}
._SelectList_585rk_178 ._checkbox_585rk_196 .ant-checkbox + span > div {
  min-width: 0;
}
._SelectList_585rk_178 ._groupSelect_585rk_219 {
  width: 100%;
}
._SelectList_585rk_178 ._divider_585rk_222 {
  margin: var(--spacing-xs) 0;
}
._SelectList_585rk_178 ._searchInput_585rk_225 {
  margin-bottom: 0.625rem;
}
._SelectList_585rk_178 ._content_585rk_228 {
  flex: 1;
  overflow-y: auto;
}