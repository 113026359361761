import * as React from 'react';
import moment from 'moment';
import {
  Space, Typography, Checkbox, DatePicker, Divider,
  Select,
} from '@revfluence/fresh';
import { useAuth } from '@frontend/context/authContext';
import {
  AlertAdsPermissionsNeeded,
  AlertBusinessAccountMissing,
  AlertInstagramInsightsMissing,
  AlertTikTokSparkAdsMissing,
} from '../../Alerts/Alerts';

const { Option } = Select;

const { Title, Text } = Typography;

const enum ErrorTypes {
  AdsPermissionsNeeded = 'AdsPermissionsNeeded',
  InstagramInsightsMissing = 'InstagramInsightsMissing',
  BusinessAccountMissing = 'BusinessAccountMissing',
  TiktokSparkAdsAccountMissing = 'TiktokSparkAdsAccountMissing',
}
type ErrorNames = keyof typeof ErrorTypes;

interface ISectionProps {
  hidden: boolean;
  title: React.ReactNode;
  description: React.ReactNode;
  checkbox: {
    label: React.ReactNode;
    checked: boolean;
    onChange: (checked: boolean) => void;
  };
  datePicker?: {
    dateRange: [Date?, Date?];
    onDateRangeChange: (dateRange: [Date?, Date?]) => void;
  };
  sparkAdsSelect?: {
    sparkAdsSelected: boolean;
    options: number[];
    value: number;
    onChange: (value: number) => void;
  };
  error?: ErrorNames;
}

const SectionError: React.FC<{ error?: ErrorNames }> = ({ error }) => {
  switch (error) {
    case ErrorTypes.AdsPermissionsNeeded:
      return <AlertAdsPermissionsNeeded />;
    case ErrorTypes.InstagramInsightsMissing:
      return <AlertInstagramInsightsMissing />;
    case ErrorTypes.BusinessAccountMissing:
      return <AlertBusinessAccountMissing />;
    case ErrorTypes.TiktokSparkAdsAccountMissing:
      return <AlertTikTokSparkAdsMissing />;
    default:
      return null;
  }
};
SectionError.defaultProps = { error: undefined };

const Section: React.FC<ISectionProps> = ({
  hidden,
  title,
  description,
  checkbox,
  datePicker,
  sparkAdsSelect,
  error,
}) => {
  if (hidden) {
    return null;
  }
  return (
    <Space direction="vertical">
      <Title level={5}>{title}</Title>
      <Text>
        {description}
      </Text>
      <Checkbox
        onChange={(event) => checkbox.onChange(event.target.checked)}
        checked={checkbox.checked}
      >
        <Text>{checkbox.label}</Text>
      </Checkbox>
      <Space hidden={!sparkAdsSelect?.sparkAdsSelected} direction="horizontal">
        <Text>Authorization Period:</Text>
        <Select
          value={sparkAdsSelect?.value}
          onChange={(value) => sparkAdsSelect?.onChange(value)}
        >
          {sparkAdsSelect?.options?.map((option) => (
            <Option key={option} value={option}>
              {option}
              {' '}
              Days
            </Option>
                    ))}
        </Select>
      </Space>
      <Space hidden={!datePicker} direction="horizontal">
        <Space direction="vertical">
          <Text>Access start date:</Text>
          <DatePicker
            onChange={(startDate) => datePicker.onDateRangeChange([startDate?.toDate(), datePicker.dateRange[1]])}
            defaultValue={datePicker?.dateRange[0] ? moment(datePicker?.dateRange[0]) : undefined}
            format="MM/DD/YYYY"
            disabledDate={(current) => {
              if (!datePicker?.dateRange[1]) {
                return false;
              }
              return current && current > moment(datePicker?.dateRange[1]);
            }}
          />
        </Space>
        <Space direction="vertical">
          <Text>Access end date:</Text>
          <DatePicker
            onChange={(endDate) => datePicker.onDateRangeChange([datePicker.dateRange[0], endDate?.toDate()])}
            defaultValue={datePicker?.dateRange[1] ? moment(datePicker?.dateRange[1]) : undefined}
            format="MM/DD/YYYY"
            disabledDate={(current) => {
              if (!datePicker?.dateRange[0]) {
                return false;
              }
              return current && current < moment(datePicker?.dateRange[0]);
            }}
          />
        </Space>
      </Space>
      <SectionError error={checkbox.checked ? error : undefined} />
    </Space>
  );
};

Section.defaultProps = {
  datePicker: undefined,
  error: undefined,
  sparkAdsSelect: undefined,
};

interface ISection {
  hidden: boolean;
  featureFlag: boolean;
  toggle: boolean;
  onToggle: (toggle: boolean) => void;
}

interface ISectionWithDateRange extends ISection {
  dateRange: [Date, Date];
  onDateRangeChange: (dateRange: [Date, Date]) => void;
}

interface ITiktokSparkAds extends ISection {
  duration: number | undefined;
  onSparkAdsDurationChange: (duration: number) => void;
}

interface IAdsPermissionsSectionProps {
  hidden: boolean;
  metaAuthIssues: {
    adsPermissionsNeeded: boolean;
    instagramInsightsMissing: boolean;
    businessAccountMissing: boolean;
  }
  instagramPaidPartnershipAds: ISection;
  instagramAllowlistening: ISectionWithDateRange;
  instagramBrandedContentAds: ISectionWithDateRange;
  tiktokSparkAdsAccountMissing: boolean;
  tiktokSparkAds: ITiktokSparkAds;
}

export const AdsPermissionsSection: React.FC<IAdsPermissionsSectionProps> = ({
  hidden,
  metaAuthIssues: {
    adsPermissionsNeeded,
    instagramInsightsMissing,
    businessAccountMissing,
  },
  instagramPaidPartnershipAds,
  instagramAllowlistening,
  instagramBrandedContentAds,
  tiktokSparkAdsAccountMissing,
  tiktokSparkAds,
}) => {
  const { clientInfo } = useAuth();
  const clientName = clientInfo.name;
  if (
    ((!instagramPaidPartnershipAds.featureFlag || instagramPaidPartnershipAds.hidden)
    && (!instagramAllowlistening.featureFlag || instagramAllowlistening.hidden)
    && (!instagramBrandedContentAds.featureFlag || instagramBrandedContentAds.hidden)
    && (!tiktokSparkAds.featureFlag || tiktokSparkAds.hidden))
    || hidden
  ) {
    return null;
  }
  const isAdsPermissionsNeeded = adsPermissionsNeeded ? 'AdsPermissionsNeeded' : undefined;
  const isInstagramInsightsMissing = instagramInsightsMissing ? 'InstagramInsightsMissing' : undefined;
  const isBusinessAccountMissing = businessAccountMissing ? 'BusinessAccountMissing' : undefined;
  const isTiktokSparkAdsAccountMissing = tiktokSparkAdsAccountMissing ? 'TiktokSparkAdsAccountMissing' : undefined;
  return (
    <>
      <section>
        <Space direction="vertical">
          <Title level={4}>Ads Permissions</Title>
          <Section
            hidden={!instagramPaidPartnershipAds.featureFlag || instagramPaidPartnershipAds.hidden}
            title="Instagram Paid Partnership Ads"
            description={(
              <>
                Would you like to require the creator to grant
                {' '}
                {clientName}
                {' '}
                permission
                {' '}
                to their Instagram Account for Paid Partnership Ads?
                {' '}
                {clientName}
                {' '}
                will agree to only promote
                {' '}
                mutually agreed upon content through the
                {' '}
                {clientName}
                {' '}
                and/or creator&apos;s Instagram accounts.
              </>
            )}
            checkbox={{
              label: 'Creator must grant access to Instagram Paid Partnership Ads',
              checked: instagramPaidPartnershipAds.toggle,
              onChange: instagramPaidPartnershipAds.onToggle,
            }}
            error={(isInstagramInsightsMissing || isAdsPermissionsNeeded)}
          />
          <Section
            hidden={!instagramAllowlistening.featureFlag || instagramAllowlistening.hidden}
            title="Instagram Allowlisting"
            description={(
              <>
                Would you like to require the creator to grant
                {' '}
                {clientName}
                {' '}
                permission to their Facebook page?
                {' '}
                {clientName}
                {' '}
                will agree to only promote mutually agreed upon content through the
                {' '}
                {clientName}
                {' '}
                and/or creator&apos;s Instagram accounts.
              </>
            )}
            checkbox={{
              label: 'Creator must grant access to Instagram Allowlisting',
              checked: instagramAllowlistening.toggle,
              onChange: instagramAllowlistening.onToggle,
            }}
            datePicker={{
              dateRange: instagramAllowlistening.dateRange,
              onDateRangeChange: instagramAllowlistening.onDateRangeChange,
            }}
            error={isBusinessAccountMissing}
          />
          <Section
            hidden={!instagramBrandedContentAds.featureFlag || instagramBrandedContentAds.hidden}
            title="Instagram Branded Content Ads"
            description={(
              <>
                Would you like to require the creator to grant
                {' '}
                {clientName}
                {' '}
                permission for Branded Content Ads.
                {' '}
                {clientName}
                {' '}
                agrees to only promote mutually agreed upon content through the creator&apos;s Instagram accounts.
              </>
            )}
            checkbox={{
              label: 'Creator must grant access to Branded Content Ads',
              checked: instagramBrandedContentAds.toggle,
              onChange: instagramBrandedContentAds.onToggle,
            }}
            datePicker={{
              dateRange: instagramBrandedContentAds.dateRange,
              onDateRangeChange: instagramBrandedContentAds.onDateRangeChange,
            }}
            error={isInstagramInsightsMissing || isAdsPermissionsNeeded}
          />
          <Section
            hidden={!tiktokSparkAds.featureFlag || tiktokSparkAds.hidden}
            title="TikTok Spark Ads"
            description={(
              <>
                Would you like to require the creator to grant
                {' '}
                {clientName}
                {' '}
                permission
                {' '}
                for TikTok Spark Ads?
                {' '}
                {clientName}
                {' '}
                will agree to only promote
                {' '}
                mutually agreed upon posts tagged as Branded Content through their TikTok Ad Account.
              </>
            )}
            checkbox={{
              label: 'Creator must grant access to TikTok Spark Ads',
              checked: tiktokSparkAds.toggle,
              onChange: tiktokSparkAds.onToggle,
            }}
            sparkAdsSelect={{
              sparkAdsSelected: tiktokSparkAds.toggle,
              options: [7, 30, 60, 365],
              value: tiktokSparkAds.duration,
              onChange: tiktokSparkAds.onSparkAdsDurationChange,
            }}
            error={isTiktokSparkAdsAccountMissing}
          />
        </Space>
      </section>
      <Divider />
    </>
  );
};
