import * as React from 'react';
import {
  chain,
  every,
  first,
  get,
  intersectionWith,
  isEmpty,
  isNumber,
  isString,
  keys,
  map,
  mapValues,
  pick,
  some,
  upperFirst,
} from 'lodash';
import { Button, Checkbox, Tooltip } from 'antd';
import { Button as WidgetsButton } from '@components';
import cx from 'classnames';

import {
  ColumnsIcon,
  ColumnsIconV3,
  FilterMenuPopover,
} from '@frontend/app/components';
import { useSaveSegmentColumns } from '@frontend/app/hooks';

import {
  IFilterMenuPopoverHandles,
  ISearchableItem,
  ISource,
} from '@frontend/app/components/FilterMenuPopover/FilterMenuPopover';
import { MemberPageSegment as ISegment } from '@frontend/app/queries/fragments/types/MemberPageSegment';
import { useEventContext } from '@frontend/app/context/EventContext';
import { columnsInputFromVisibility } from '@frontend/app/utils/columns';
import { IColumnVisibility } from '@frontend/app/types/Columns';
import { useMemberFieldsWithSources } from '@frontend/app/hooks/memberList';
import { EventName } from '@common';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';

import {
  FieldSource,
  IField,
} from '../types/MemberFieldsWithSources';

import styles from './EditColumns.scss';

const {
 useEffect, useMemo, useRef, useState,
} = React;

interface IProps {
  communityId?: number;
  programId?: number;
  segment: ISegment | IPredefinedSegment;
  visibility?: IColumnVisibility;
  className?: string;
  onChange?(columnVisibility: IColumnVisibility);
  isWorkflowEnabled: boolean;
}

export const EditColumns: React.FunctionComponent<IProps> = React.memo((props) => {
  const { isWorkflowEnabled, communityId, programId } = props;

  const {
    appsWithFields,
    fields: fieldsWithSources,
  } = useMemberFieldsWithSources(communityId, programId);

  const filterMenuHandles = useRef<IFilterMenuPopoverHandles<IField>>();
  const [dataSource, setDataSource] = useState<ISearchableItem<IField>[]>();
  const [isPopoverVisible, setPopoverVisible] = useState<boolean>(false);
  const addEvent = useEventContext();

  const fields = useMemo(() => (
    chain(fieldsWithSources)
      .reject({ field: 'name' })
      .reject({ showInEditColumns: false })
      .sortBy((field) => field.headerName.toLocaleLowerCase())
      .map((col) => ({
        label: upperFirst(col.headerName),
        value: col,
        source: {
          label: isString(col.source) ? col.source : col.source?.name,
          value: isString(col.source) ? col.source : col.source?.id,
        },
      }))
      .value()
  ), [fieldsWithSources]);

  useEffect(() => {
    setDataSource(fields);
  }, [fields]);

  /**
   * Saving
   */
  const [saveSegmentColumns] = useSaveSegmentColumns();

  const saveColumnsVisibility = (columnsVisibility: typeof props.visibility) => {
    // Run this on next run loop.
    setTimeout(() => {
      const customSegment = props.segment as ISegment;
      const segmentId = customSegment && isNumber(customSegment.id) ? customSegment.id : null;
      const predefinedSegmentId = isNumber(segmentId) ? null : props.segment.id as string;
      const columnsInput = columnsInputFromVisibility(columnsVisibility, customSegment.columns.order);
      saveSegmentColumns({
        variables: {
          metadata: {
            segmentId,
            predefinedSegmentId,
            columns: columnsInput,
          },
        },
      });
    }, 100);

    if (props.onChange) {
      props.onChange(columnsVisibility);
    }
  };

  const handleColumnVisibilityChange = (field: string, name: string, checked: boolean) => {
    const newColumnsVisibility = {
      ...props.visibility,
      [field]: checked,
    };
    saveColumnsVisibility(newColumnsVisibility);
    if (checked) {
      addEvent(EventName.MemberColumnSelect, { field_name: name });
    }
  };

  const handleAllColumnsVisibilityChange = (checked: boolean) => {
    const newColumnsVisibility = {
      ...props.visibility,
      ...mapValues(visibility, () => checked),
    };
    saveColumnsVisibility(newColumnsVisibility);
  };

  /**
   * Item renderer
   */
  const visibility = useMemo(() => {
    if (isEmpty(dataSource)) {
      return props.visibility;
    }
    const filteredKeys = intersectionWith(
      keys(props.visibility),
      map(dataSource, (item) => item.value?.field),
    );
    return pick(props.visibility, filteredKeys);
  }, [dataSource, props.visibility]);

  const selectedAll = useMemo(() => every(visibility), [visibility]);
  const selectedSome = useMemo(() => some(visibility), [visibility]);

  const renderItem = (item: ISearchableItem<IField>, index: number) => {
    const column = item.value;
    const isChecked = item.isSelectAll
      ? selectedAll
      : get(visibility, column.field);
    const isIndeterminate = item.isSelectAll
      ? selectedSome && !selectedAll
      : undefined;

    return (
      <Checkbox
        key={`${column.field}-${index}`}
        checked={isChecked}
        indeterminate={isIndeterminate}
        onChange={(e) => {
          if (item.isSelectAll) {
            handleAllColumnsVisibilityChange(!selectedSome);
          } else {
            handleColumnVisibilityChange(column.field, column.headerName, e.target.checked);
          }
        }}
      >
        {filterMenuHandles.current?.renderItemLabel(item)}
      </Checkbox>
    );
  };

  /**
   * Items and sources
   */
  const sources: ISource[] = useMemo(() => [
    ...map(FieldSource, (s) => ({
      label: s,
      value: s === FieldSource.All ? null : s,
      isSelectAll: true,
    })),
    ...map(appsWithFields, (app) => ({
      label: app.name,
      value: app.id,
    })),
  ].filter((source) => source.label !== FieldSource.Project), [appsWithFields]);
  // TODO disable project for member page for now

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectAllItem: ISearchableItem<IField> = {
    label: selectedSome ? 'Unselect all' : 'Select all',
    value: {
      headerName: selectedSome ? 'Unselect all' : 'Select all',
      field: undefined,
      type: undefined,
    },
    isSelectAll: true,
  };

  const items: ISearchableItem<IField>[] = useMemo(() => [
    selectAllItem,
    ...fields,
  ], [fields, selectAllItem]);

  const handleButtonClick = () => {
    addEvent(EventName.MemberColumnClick, {});
  };

  const renderButton = () => {
    if (isWorkflowEnabled) {
      return (
        <Tooltip title="Edit Columns">
          <Button
            icon={<ColumnsIconV3 size={16} />}
            className={cx(
              styles.workflowButton,
              props.className,
              {
                [styles.active]: isPopoverVisible,
              },
            )}
            onClick={handleButtonClick}
          />
        </Tooltip>
      );
    }

    return (
      <WidgetsButton
        onClick={handleButtonClick}
        icon={<ColumnsIcon size={16} />}
        theme="light"
        label="Columns"
        round={false}
      />
    );
  };

  return (
    <FilterMenuPopover<IField>
      sources={sources}
      defaultSource={first(sources)}
      items={items}
      renderItem={renderItem}
      onSourceUpdate={setDataSource}
      popoverProps={{
        visible: isPopoverVisible,
        onVisibleChange: (visible) => setPopoverVisible(visible),
      }}
      handlesRef={filterMenuHandles}
      visibility={visibility}
    >
      {renderButton()}
    </FilterMenuPopover>
  );
});

EditColumns.defaultProps = {
  visibility: {},
};

EditColumns.displayName = 'EditColumns';
