export interface Address {
  first_name: string;
  last_name: string;
  address1: string;
  address2?: string;
  city: string;
  province?: string;
  country: string;
  zip: string;
  country_code?: string;
  province_code?: string;
  phone?: string;
}

export function getAddressString(address: Address): string {
  if (!address) {
    return '';
  }

  // filter out null values
  return [address.address1, address.address2, address.city, address.province, address.zip, address.country]
    .filter(Boolean)
    .join(', ');
}
