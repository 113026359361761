import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const FunnelIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 17 17">
    <path d="M3.10837 2.71224C3.05231 2.71224 3.02504 2.74557 3.0117 2.77375C2.99867 2.80133 2.99049 2.84406 3.02625 2.88709L7.55625 8.36436C7.69534 8.53285 7.77201 8.74557 7.77201 8.96375V14.098C7.77201 14.1571 7.81989 14.205 7.87867 14.205H8.43958C8.49837 14.205 8.54625 14.1571 8.54625 14.098V8.96375C8.54625 8.74588 8.62292 8.53315 8.7617 8.36497L13.292 2.88709C13.3278 2.84406 13.3196 2.80133 13.3066 2.77375C13.2932 2.74557 13.2659 2.71224 13.2099 2.71224H3.10837ZM8.43958 15.0383H7.87867C7.36019 15.0383 6.93867 14.6165 6.93867 14.098V8.96375C6.93867 8.93891 6.92988 8.91466 6.91413 8.89557L2.38382 3.4183C2.14928 3.13466 2.1011 2.75163 2.25776 2.41891C2.41443 2.08588 2.74049 1.87891 3.10837 1.87891H13.2099C13.5778 1.87891 13.9038 2.08588 14.0605 2.41891C14.2172 2.75163 14.169 3.13466 13.9344 3.4183L9.40413 8.89557C9.38837 8.91466 9.37958 8.93891 9.37958 8.96375V14.098C9.37958 14.6165 8.95807 15.0383 8.43958 15.0383Z" />
  </SvgIcon>
));

FunnelIcon.displayName = 'FunnelIcon';
