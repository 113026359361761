import * as React from 'react';
import { useElementResize } from '@frontend/app/hooks/common';

const { useMemo, useState } = React;

interface IOptions {
  ref: React.MutableRefObject<HTMLElement>
}

interface IForceDesktop {
  force: boolean
}

export const useForceDesktop = ({ ref }: IOptions) : IForceDesktop => {
  const [hasBeenForced, setHasBeenForced] = useState(false);

  const {
    width,
    height,
  } = useElementResize(ref)
    || { width: 0, height: 0 };

  const force = useMemo(() => {
    if (hasBeenForced) {
      return true;
    }

    const shouldForce = width <= height;

    if (shouldForce
      && width
      && height
    ) {
      setHasBeenForced(true);
    }

    return shouldForce;
  }, [width, height, hasBeenForced]);

  return {
    force,
  };
};
