import gql from 'graphql-tag';

export const GET_PAYMENT_GROUP_LOG_ENTRIES = gql`
  query GetPaymentGroupLogEntries {
    paymentGroupLogEntries  {
      id
      externalId
      name
      externalDateCreated
      status
      totalAmount
    }
  }
`;
