import { MessageType } from '@services/messages';

export enum EventName {
  ActOnThread = 'act_on_thread',
  AddCustomProperty = 'add_custom_property',
  AddMemberToProject = 'add_member_to_project',
  AttemptBulkAction = 'attempt_bulk_action',
  AttemptBulkSendMessage = 'attempt_bulk_send_message',
  AutoWorkflowActionTaken = 'auto_workflow_action_taken',
  AutoWorkflowActivate = 'auto_workflow_activate',
  AutoWorkflowCreated = 'auto_workflow_created',
  AutoWorkflowDeactivate = 'auto_workflow_deactivate',
  AutomationInstanceTriggered = 'automation_instance_triggered',
  BrandEditTerms = 'brand_edit_terms',
  CSVUploadAssignColumnsCompleted = 'csv_upload_assign_columns_completed',
  CSVUploadBulkAssignCompleted = 'csv_upload_bulk_assign_completed',
  CSVUploadImportCompleted = 'csv_upload_import_completed',
  CSVUploadLoadFileCompleted = 'csv_upload_load_file_completed',
  CSVUploadOpenModule = 'csv_upload_open_module',
  ClickSourcingGroup = 'click_sourcing_group',
  ClientActivatedDate = 'client_activated_date',
  ClientChurned = 'client_churned',
  ClientCompletedOnboarding = 'client_completed_onboarding',
  ClientSalesForceMetaDataUpdated = 'client_salesforce_metadata_updated',
  CompleteBulkAction = 'complete_bulk_action',
  CompleteBulkSendMessage = 'complete_bulk_send',
  CompleteRequirement = 'complete_requirement',
  ContentCreated = 'content_created',
  CreateArtifact = 'create_artifact',
  CreateCommunity = 'create_community',
  CreateProgram = 'create_program',
  CreateRequirement = 'create_requirement',
  CreateSegment = 'create_segment',
  CreateSegmentGroup = 'create_segment_group',
  CreatedProject = 'created_project',
  CreatorSearch = 'creator_search',
  CreatorSearchSession = 'creator_search_session',
  ApplicationPageFormSession = 'application_page_form_session',
  ApplicationPageFormStart = 'application_page_form_start',
  ApplicationPageFormScroll = 'application_page_form_scroll',
  ApplicationPageFieldsFilledExit = 'application_page_fields_filled_exit',
  CreatorSearchSearchSelected = 'creator_search_search_selected',
  CreatorSearchSearchDeleted = 'creator_search_search_deleted',
  CreatorSearchSearchSaved = 'creator_search_search_saved',
  DataRequestCompleted = 'data_request_completed',
  DataRequestOpened = 'data_request_opened',
  DataRequestSent = 'data_request_sent',
  DataRequestVisited = 'data_request_visited',
  DeleteMember = 'delete_member',
  EditCommunity = 'edit_community',
  EditMember = 'edit_member',
  EditProgram = 'edit_program',
  EditSegment = 'edit_segment',
  MessageTemplates = 'message_templates',
  EmailEditorFocused = 'email_editor_focused',
  ExportMembers = 'export_members',
  FindCreatorsApplicationClick = 'find_creators_application_click',
  FindCreatorsBrowseClick = 'find_creators_browse_click',
  FindCreatorsPageVisit = 'find_creators_page_visit',
  FindCreatorsSubmitLandingPage = 'find_creators_submit_landing_page',
  ImportErrors = 'import_errors',
  ImportMembers = 'import_members',
  InstagramInviteBannerCTA = 'instagram_invite_banner',
  LandingPagePublish = 'landing_page_publish',
  LandingPageSentForReview = 'landing_page_sent_for_review',
  LoadOnboardingPage = 'load_onboarding_page',
  LoadPage = 'load_page',
  LoadSession = 'load_session',
  MarkThreadDone = 'mark_thread_done',

  MarkThreadTodo = 'mark_thread_todo',
  MarketplaceDeactivate = 'marketplace_deactivate',
  MarketplaceEditComplete = 'marketplace_edit_complete',
  MarketplacePublishComplete = 'marketplace_publish_complete',
  MarketplacePublishEdit = 'marketplace_edit_start',
  MarketplacePublishStart = 'marketplace_publish_start',
  MarketplaceReactivate = 'marketplace_reactivate',
  MemberAddToCommunity = 'member_add_to_community',
  MemberApplicantOperation = 'approve_or_reject_applicants',
  MemberColumnClick = 'member_column_click',
  MemberColumnSelect = 'member_column_select',
  MemberDetailView = 'member_detail_view',
  MemberFilterClick = 'member_filter_click',
  MemberFilterSelect = 'member_filter_select',
  MemberInviteToProject = 'member_invite_to_project',
  MemberInviteToProjectResult = 'member_invite_to_project_result',
  MovedCaseStage = 'moved_case_stage',
  OAuthGrantError = 'oauth_grant_error',
  OAuthGrantStart = 'oauth_grant_start',
  OAuthGrantSuccess = 'oauth_grant_success',
  OAuthRevokeError = 'oauth_revoke_error',
  OAuthRevokeStart = 'oauth_revoke_start',
  OAuthRevokeSuccess = 'oauth_revoke_success',
  OnboardingFlowCreators = 'onboarding_flow_creators',
  OnboardingFlowFinish = 'onboarding_flow_finish',
  OnboardingFlowIntro = 'onboarding_flow_intro',
  OnboardingFlowObjective = 'onboarding_flow_objective',
  OnboardingFlowList = 'onboarding_flow_list',
  OnboardingFlowPersonalize = 'onboarding_flow_personalize',
  OnboardingFlowPreview = 'onboarding_flow_preview',
  OnboardingFlowSkip = 'onboarding_flow_skip',
  OnboardingFlowWelcome = 'onboarding_flow_welcome',
  OpenApp = 'open_app',
  OpenSegment = 'open_segment',
  PFAMissingAddress = 'pfa_missing_address',
  PFAMissingResource = 'pfa_missing_resource',
  PFAOrdersSubmitted = 'pfa_orders_submitted',
  PFASendEmailFailed = 'pfa_send_email_failed',
  PaymentSent = 'payment_sent',
  ProductScore = 'product_score',
  ProspectsRemoveFromCommunity = 'prospects_remove_from_community',
  ReceiveMessage = 'receive_message',
  ResourceAuthStart = 'resource_auth_start',
  SalesTrackingCreateOffer = 'salestracking_create_offer',
  SalesTrackingEditOffer = 'salestracking_edit_offer',
  SalesTrackingGenerateLink = 'salestracking_generate_link',
  SalesTrackingGeneratePromoCodes = 'salestracking_generate_promo_codes',
  SalesTrackingTableExport = 'salestracking_table_export',
  SelectProjectTemplate = 'select_project_template',
  SendMessage = 'send_message',
  SendMessageEventually = 'send_message_eventually',
  ShopifyInvalidToken = 'shopify_invalid_token',
  SocialPostAssigned = 'social_post_assigned',
  SocialPostCreated = 'social_post_created',
  SocialProfileApprove = 'social_profile_approve',
  SocialProfileReject = 'social_profile_reject',
  StartCreateNewProject = 'start_create_new_project',
  SubmitOnboardingPage = 'submit_onboarding_page',
  SubmitBulkTerms = 'submit_bulk_terms',
  UserLogin = 'user_login',
  ViewGroupInMemberList = 'view_group_in_member_list',
  ViewGroupsListPage = 'view_groups_list_page',
  ViewThread = 'view_thread',
  WorkflowCaseCanceled = 'case_canceled',
  WorkflowCaseCompleted = 'case_completed',
  WorkflowCaseCreated = 'case_created',
  WorkflowCaseTaskCompleted = 'case_task_completed',
  WorkflowCaseTaskStarted = 'case_task_started',
  WorkflowCaseWorkletCompleted = 'case_worklet_completed',
  WorkflowCaseWorkletCreated = 'case_worklet_created',
  // alloy integrations
  InstallIntegrationWorkflow = 'install_integration_workflow',
  DeactivateIntegrationWorkflow = 'deactivate_integration_workflow',
  RunIntegrationSyncWorkflow = 'run_integration_sync_workflow',
  ContentGuidelineCreated = 'Content_guideline_created',
}

export enum CreatorSearchSessionUserAction {
  ENTER_SEARCH_PAGE = 'enter search page',
  LEAVE_SEARCH_PAGE = 'leave search page',
};

export interface IProperties {}

/**
 * @category User
 */
export interface IClientProperties extends IProperties {
  id: string;
}

export interface IViewThreadProperties {
  threadType: string;
}

export interface IMarkThreadDoneProperties {
  threadType: string;
}
export interface IMarkThreadTodoProperties {
  threadType: string;
}

export interface IActOnThreadProperties {
  threadType: string;
  actionType: string;
}

export interface ILoadPageProperties {
  pageName: string
}

export interface ISendMessageProperties {
  thread_type: string;
  length: number;
  attachments: number;
  thread_size: number;
}

export interface ISendMessageEventuallyProperties {
  source: string;
  type: MessageType;
  result: string;
  statusCode: string;
  resourceInactiveError: string;
}

export interface IOpenAppProperties {
  source: 'member' | 'nav' | 'inbox';
  app: 'messages' | 'analytics' | 'shopify' | 'contract' |
  'content' | 'messages' | 'email' | 'woo' | 'payment' |
  'socialdiscovery' | 'socialpost' | 'memberdataupdate';
}

export interface ISocialPostCreatedProperties {
  post_id?: string;
  post_type?: string;
  network?: string;
  mention_id?: number;
  reach?: string;
  datetime_posted?: string;
  profile_image_url?: string;
  social_account_name?: string;
  social_account_link?: string;
  image_url?: string;
  link?: string;
  creator?: string;
  post_text?: string;
  member_id?: number;
  caption_length?: number;
  social_account_username: string;
  sponsored: boolean;
  hashtags: string[];
  number_of_hash_tags: number;
  program_ids?: number[];
  activation_ids?: number[];
  community_ids?: number[];
}

export interface ISocialPostAssignedProperties {
  post_id?: string;
  post_type?: string;
  network?: string;
  mention_id?: number;
  reach?: string;
  datetime_posted?: string;
  profile_image_url?: string;
  social_account_name?: string;
  social_account_link?: string;
  image_url?: string;
  link?: string;
  creator?: string;
  post_text?: string;
  member_id?: number;
  program_id?: number;
  activation_id?: number;
  community_id?: number;
  activation_ids?: string[];
  program_ids?: string[];
  community_ids?: string[];
  tags?: string[];
}

export interface IShopifyInvalidTokenProperties {
  resourceId: number;
  error: any;
}

export interface IContentCreatedProperties {
  // num_content - count of how many files are uploaded NOTE: I'm pretty certain this is always 1
  // file_types - set of all file extensions being uploaded NOTE: This is unaccessible because it's just put in google storage?
  // file_size_in_mb - total file size being uploaded (in MB)
  has_sku?: boolean;
  member_id?: number;
  // activation_id - unavailable
  // program_id - unavailable
  // community_id - unavailable
  // share_to_destinations - repeated_enum (gdrive, dropbox, dam, etc)

}
export interface IAddCustomPropertyProperties {
  property_type: string;
  name: string;
}

export interface IAttemptBulkActionProperties {
  /**
   * The action being taken:
   * - add_to_community
   * - add_to_program (deprecated, since programs are now projects)
   * - add_to_project
   * - add_to_activation (deprecated since no more activations)
   * - add_to_tag
   * - add_requirement (deprecated, no more requirements)
   * - remove_from_community
   * - remove_from_program
   * - remove_from_activation (deprecated)
   * - remove_from_tag
   */
  action: string;
  /**
   * The count of members selected when the action was attempted
   */
  member_count: number;
}

export interface ICompleteBulkActionProperties {
  /**
   * See
   */
  action: string;
  member_count: number;
  due_in_days?: number;
  details_char_count?: number;
}

export interface ICreateSegmentProperties {
  member_count: number;
  filter_count: number;
}

export interface IOpenSegmentProperties {
  member_count: number;
  filter_count: number;
  segment_type: string;
  segment_name: string;
  group_name: string;
  group_type: string;
}

export interface IAttemptBulkSendMessageProperties {
  member_count: number;
  source: string;
}

export interface ICompleteBulkSendMessageProperties {
  member_count: number;
  attachments: number;
  length: number;
  as_one_message: boolean;
  source: string;
}

export interface IExportMembersProperties {
  member_count: number;
  segment_type: string;
}

export interface IFindCreatorsClickProperties {
  source?: string;
}

export interface IMarketplaceClickProperties extends IFindCreatorsClickProperties {
  client_id?: string;
  client_name?: string;
  user_id?: string;
  user_email?: string;
  user_name?: string;
  project_id: number;
  project_name: string;
}

export interface IMarketplaceCompletionClickProperties extends IMarketplaceClickProperties {
  marketplace_campaign_name: string;
  required_content: string[];
  location: string[];
}

export interface IImportErrorsProperties {
  error_type: string;
}

export interface ICreateRequirementProperties {
  due_in_days: number;
  details_char_count: number;
}

export interface ICompleteRequirementProperties {
  days_until_due: number;
}

export interface IEditMemberProperties {
  field_name: string;
}

export interface ILoadOnboardingPageProgrerties {
  invite_funnel_id: string;
  program_id: number;
  member_id: number;
  utm_source: string;
  required_fields: string[];
  optional_fields: string[];
}

export interface ISubmitOnboardingPageProperties {
  invite_funnel_id: string;
  program_id: number;
  member_id: number;
  utm_source: string;
  required_fields: string[];
  optional_fields: string[];
  completed_optional_fields: string[];
  incomplete_optional_fields: string[];
}

interface ISubmitBulkTermsProperties {
  program_id: number;
  member_count: number;
  base_payment: number;
  product_description: string;
  offers_product: boolean;
  offers_payment: boolean;
  offers_commission: boolean;
  content_rights_type: string;
  commission_description: string;
  disable_creator_edits_on_terms: boolean;
  guidelines_per_member: number;
  content_count_per_member: number;
  networks: string[];
}

export interface IMemberApplicantOperationProperites {
  invite_funnel_id: string;
  program_id: number;
  member_id: number;
  type: string;
  utm_source: string;
  member_email?: string;
}

export interface IReceiveMessageProperties {
  thread_type: string;
  assigned_by: 'owner' | 'inbox';
  age: number;
  thread_size: number;
  contains_question: boolean;
  message_id: string;
  thread_id: string;
}

export interface IResourceAuthStartProperties {
  type: 'shopify';
  shop?: string;
  supportCreateOrder: boolean;
  supportPromoCodes: boolean;
  supportPromoCodeCustomerSegmentation: boolean;
}

export interface ILandingPagePublish extends IProperties {
  project_name: string;
  project_id: number;
  template_resource: 'new' | 'duplicated';
}

export interface ILandingPageSentForReview extends IProperties {
  project_name: string;
  project_id: number;
}

export interface IMemberFilterSelectProperties {
  field_name: string;
}

export interface IMemberColumnSelectProperties {
  field_name: string;
}

export interface IMemberInviteToProjectProperties {
  invite_funnel_id: string;
  member_id: number;
  program_id: number;
  utm_source: string;
}

export interface IMemberInviteToProjectResultProperties {
  programId: number;
  success: number;
  failed: number;
  failedMemberIds: number[];
  invalidStatusChange: number;
  invalidStatusChangeMemberIds: number[];
}

export interface IProspectsRemoveFromCommunityProperties {
  member_id: number;
  community_id: number;
}

export interface IOAuthGrantStartProperties {
  app: string;
  params?: any;
}

export interface IOAuthGrantSuccessProperties {
  app: string;
  params?: any;
  isShared?: boolean;
  domain?: string;
  userExternalDisplayId?: string;
}

export interface IOAuthGrantErrorProperties {
  app: string;
}

export interface IOAuthRevokeStartProperties {
  app: string;
}

export interface IOAuthRevokeSuccessProperties {
  app: string;
}

export interface IOAuthRevokeErrorProperties {
  app: string;
}

export interface IOnboardingFlowProperties {
  project_type?: string;
  content?: string[],
  creator_location?: {label: string, value: string}[],
  offers_payment?: boolean,
  offers_product?: boolean,
  offers_commission?: boolean,
  offers_other?: boolean,
}

export interface IAutoWorkflowCreatedProperties {
  autoWorkflowId: string;
  recurrence: string;
  triggerServiceName: string;
  triggerName: string;
  actionServiceName: string;
  actionName: string;
}

export enum AutomationTypeEventLog {
  INVITATION_REMINDER = 'invitation_reminder',
}

export interface IAutomationInstanceTriggeredProperties {
  brand: string;
  type: AutomationTypeEventLog;
  member_id: number;
  project_id: number;
  trigger_time: string;
  result: 'success' | 'failure';
}

export interface IAutoWorkflowActionTakenProperties {
  autoWorkflowId: string;
  recurrence: string;
  triggerServiceName: string;
  triggerName: string;
  actionServiceName: string;
  actionName: string;
  clientId: string;
}

// common properties
export interface ISalesTrackingOfferBaseProperties {
  description: string;
  flatPayout?: number;
  imageUrl?: string;
  payoutType?: string;
  percentPayout?: number;
  name: string;
  source: string;
  status: string;
  programId: number | null;
  createdFrom: 'sales tracking' | 'workflow';
}

// affiliate links properties
export interface ISalesTrackingCreateAffiliateLinkOfferProperties extends ISalesTrackingOfferBaseProperties {
  conversionTrackingType?: string;
  conversionType?: string;
  expirationDate?: string;
  source: 'TUNE';
  url?: string;
}
export interface ISalesTrackingEditAffiliateLinkOfferProperties extends ISalesTrackingCreateAffiliateLinkOfferProperties {}

// promo codes properties
export interface ISalesTrackingCreateShopifyPromoCodeOfferProperties extends ISalesTrackingOfferBaseProperties {
  codeSuffix: string;
  prefixType: string;
  priceRuleAmount: number;
  priceRuleType: string;
  source: 'SHOPIFY';
  specialLimitNewCustomersOnly: boolean;
  specialLimitOnePerSale: boolean;
  specialLimitUsageCapAmount?: number;
  specialLimitUsageCapEnabled: boolean;
  usageLimitAmount?: number;
  usageLimitRule?: string;
  discountCombination?: DiscountCombinationInput;
}
export interface DiscountCombinationInput {
  orderDiscounts?: boolean;
  productDiscounts?: boolean;
  shippingDiscounts?: boolean;
}

export interface ISalesTrackingEditShopifyPromoCodeOfferProperties extends ISalesTrackingCreateShopifyPromoCodeOfferProperties {}

export type TSalesTrackingCreateOfferProperties =
  | ISalesTrackingCreateAffiliateLinkOfferProperties
  | ISalesTrackingCreateShopifyPromoCodeOfferProperties
;

export type TSalesTrackingEditOfferProperties =
  | ISalesTrackingEditAffiliateLinkOfferProperties
  | ISalesTrackingEditShopifyPromoCodeOfferProperties
;

export type TSalesTrackingEditOfferProgramProperties = {
  programId: number;
  createdFrom: 'sales tracking' | 'workflow';
};

export interface ISalesTrackingGenerateLinkProperties {
  offerId: number;
  memberCount: number;
  programIds?: string;
  source: 'sales tracking' | 'workflow';
  createdFrom: 'sales tracking' | 'workflow';
}

export interface ISalesTrackingGenerateShopifyPromoCodesProperties {
  offerId: number;
  memberCount: number;
  programIds?: string;
  source: 'sales tracking' | 'workflow';
  createdFrom: 'sales tracking' | 'workflow';
}

export interface ISalesTrackingTableExportProperties {
  exportDate: string;
  exportedTable: 'offers' | 'members';
}

export interface ISocialProfileApplicantOperationProperties {
  project_id: number;
  project_title: string;
  applicant_id: string;
}

export interface ICreatorSearchProperties {
  search_term?: string;
}

export interface ICreatorSearchSessionProperties {
  user_action: CreatorSearchSessionUserAction,
  searched_terms?: string[];
  session_time?: string;
}

export interface IApplicationPageProperties {
  project_id: number;
  project_name: string;
  utm_source?: string;
  application_page_type?: string;
  application_template_name?: string;
}

export interface IApplicationPageFormSessionProperties extends IApplicationPageProperties {
  session_time?: number; // seconds
}

export interface IApplicationPageFormStartProperties extends IApplicationPageProperties {}

export interface IApplicationPageFormScrollProperties extends IApplicationPageProperties {}

export interface IApplicationPageFieldsFilledExitProperties extends IApplicationPageProperties {
  fields_with_values: { [fieldName: string]: any },
  fields_without_values: string[];
}

export interface IDataRequestOpenedProperties {
  member_count: number
}

export interface IDataRequestSentProperties {
  member_count: number,
  fields_count: number,
  fields: any[],
  used_last_update_column: boolean,
  batch_id: string
}

export interface IDataRequestVisitedProperties {
  member_id: number;
  request_id: string;
}

export interface IDataRequestCompletedProperties {
  member_id: number;
  request_id: string;
  is_confirmation: boolean;
}
export interface IClickSourcingGroupProperties {
  source: string;
}

export interface IViewGroupInMemberListProperties {
  groupName: string;
  groupType: string;
}

export interface ICSVUploadOpenModule {
  uploadIdentifier: string;
}

export interface ICSVUploadLoadFileCompleted {
  uploadIdentifier: string;
  success: Boolean;
  isCSV: Boolean;
  isCorrectSize: Boolean;
}

export interface ICSVUploadAssignColumnsCompleted {
  uploadIdentifier: string;
  identifierColumn: string;
  totalColumnCount: number;
  matchedColumnCount: number;
  unmatchedColumnCount: number;
}

export interface ICSVUploadBulkAssignCompleted {
  uploadIdentifier: string;
  communitiesCount: number;
  programsCount: number;
  activationsCount: number;
  ownerCount: number;
}

export interface ICSVUploadImportCompleted {
  uploadIdentifier: string;
  success: boolean;
  successfulRecords: number;
  failedRecords: number;
  errorType: string;
}

export interface IWorkflowSharedProperties {
  case_id: string;
  case_cohort_day: number;
  loop_count: number;
  program_id: number;
  template?: string;
}

export interface IWorkflowCaseCreated extends IWorkflowCaseCompleted {
  // remaining_worklets: number;
}

export interface IWorkflowCaseCompleted extends IWorkflowSharedProperties {}

export interface IWorkflowCaseWorkletCreated extends IWorkflowSharedProperties {
  worklet_name: string;
}

export interface IWorkflowCaseWorkletCompleted extends IWorkflowCaseWorkletCreated {
  // skipped: boolean;
}

export interface IWorkflowCaseCanceled extends IWorkflowSharedProperties {
  // completed_worklets: number;
  // remaining_worklets: number;
  // task_id: string;
}

export interface IWorkflowCaseTaskStarted extends IWorkflowSharedProperties {
  task_id: string;
}

export interface IWorkflowCaseTaskCompleted extends IWorkflowCaseTaskStarted {
  skipped: boolean;
}

export interface IAddMemberToProjectProperties {
  added_to_stage?: number;
  count: number;
}

export interface ICreatedProjectProperties {
  spec_size: number;
  spec: string[];
  projectId: number;
  template?: string;
}

export interface IMovedCaseStageProperties {
  action: 'mark_done' | 'move';
  case_count: number;
  from_task_id: string;
  to_worklet_id?: string;
}

export interface IStartCreateNewProjectProperties {
  source: string;
}

export interface ISelectProjectTemplateProperties {
  template: string;
}

export interface IMessageTemplatesEventProperties {
  template_id: number,
  template_name: string,
  action: string,
}

export interface IPaymentSentEventProperties {
  client_id: string;
  currency: 'USD';
  member_email: string;
  member_id: string;
  member_name: string;
  payment_amount: number;
  payment_date: string;
  payment_type: 'paypal' | 'stripe' | 'off platform';
  source: 'sales tracking' | 'workflow' | 'member details';
}
export interface ISTAPaymentSentEventProperties extends IPaymentSentEventProperties {
  source: 'sales tracking';
  offerSource: 'TUNE' | 'SHOPIFY';
  offerId: number;
}

export interface IPFAMissingAddressProperties {
  member_count: number;
  invalid_count: number;
}

export interface IPFAMissingResourceProperties {
}

export interface IPFASendEmailFailedProperties {
  params: any;
  errorMessage: string;
}

export interface IPFAOrdersSubmittedProperties {
  member_count: number;
  value_per_member: number;
  total_value: number;
  qty_per_member: number;
  send_receipt: boolean;
}

export interface IUserLoginProperties {
  first_login: boolean;
}

export interface IIntegrationWorkflowProperties {
  integration: string;
  workflowId: string;
  childWorkflowId: string;
}

export interface IEmailEditorFocused {
  pathname: string;
}

export interface IInstagramInviteBannerCTA {
  userId: string;
  clientId: string;
  dateTime?: string;
  source: string;
}

export interface EventProperties extends Record<EventName, IProperties> {
  /**
   * When brand reassigns a thread or replies to it via sending an email message
   * @category Inbox
   */
  [EventName.ActOnThread]: IActOnThreadProperties;
  /**
   * When brand creates a new custom property on their Member database
   * @category Members
   */
  [EventName.AddCustomProperty]: IAddCustomPropertyProperties;
  /**
   * When brand adds member(s) to a project
   * @category Projects
   */
  [EventName.AddMemberToProject]: IAddMemberToProjectProperties;
  /**
   * When brand clicks button (but has not yet completed) bulk action on the Member table.
   * Specifically adding to group, adding tags, or adding to project
   * @category Members
   */
  [EventName.AttemptBulkAction]: IAttemptBulkActionProperties;
  /**
   * When brand opens the bulk send email modal. This could happen e.g. from member table or projects table
   * @category Messaging
   */
  [EventName.AttemptBulkSendMessage]: IAttemptBulkSendMessageProperties;
  /**
   * When a triggered auto-workflow action is executed by the system (not by the user).
   * @category AutoWorkflow
   */
  [EventName.AutoWorkflowActionTaken]: IAutoWorkflowActionTakenProperties;
  /**
   * When a user activates an auto-workflow
   * @category AutoWorkflow
   */
  [EventName.AutoWorkflowActivate]: IAutoWorkflowCreatedProperties;
  /**
   * When a user creates a new auto-workflow
   * @category AutoWorkflow
   */
  [EventName.AutoWorkflowCreated]: IAutoWorkflowCreatedProperties;
  /**
   * When a user deactivates an auto-workflow
   * @category AutoWorkflow
   */
  [EventName.AutoWorkflowDeactivate]: IAutoWorkflowCreatedProperties;
  /**
   * When a user finishes assigning columns after uploading a member CSV
   * @category Members
   */
  [EventName.CSVUploadAssignColumnsCompleted]: ICSVUploadAssignColumnsCompleted;
  /**
   * When a user finishes assigning relations (programs/groups/activations/owners) after uploading a member CSV
   * @category Members
   */
  [EventName.CSVUploadBulkAssignCompleted]: ICSVUploadBulkAssignCompleted;
  /**
   * When a user successfully completes all steps to upload members via CSV OR the
   * server returns an error during the upload process
   * @category Members
   */
  [EventName.CSVUploadImportCompleted]: ICSVUploadImportCompleted;
  /**
   * When a user successfully uploads a CSV file (but has not created/updated members yet)
   * OR if the CSV upload failed due to not being a .csv file (S.B. note: why????)
   * @category Members
   */
  [EventName.CSVUploadLoadFileCompleted]: ICSVUploadLoadFileCompleted;
  /**
   * When a user clicks on the button to select a CSV file to upload.
   * S.B. note: I suspect that if user drag-drop file, this event will not fire
   * so funnels here may not be reliable.
   * @category Members
   */
  [EventName.CSVUploadOpenModule]: ICSVUploadOpenModule;
  /**
   * This event is not implemented
   * @category Other
   */
  [EventName.ClickSourcingGroup]: IClickSourcingGroupProperties;
  /**
   * Fired by the system for all users of a client after the client has 20 approved members in a project
   * @category ClientStatus
   */
  [EventName.ClientActivatedDate]: IProperties;
  /**
   * Fired by the system if Salesforce record type changes from "customer" to "churned"
   * @category ClientStatus
   */
  [EventName.ClientChurned]: IClientProperties;
  /**
   * When admin presses button to indicate that the client has completed onboarding
   * SB note: I believe this is no longer used, but can confirm with Claire/Simma
   * @category ClientStatus
   */
  [EventName.ClientCompletedOnboarding]: IProperties;
  /**
   * Fired any time that the SFDC sync pulls data from SFDC that does not match the current data set
   * @category ClientStatus
   */
  [EventName.ClientSalesForceMetaDataUpdated]: IClientProperties;
  /**
   * Related to AttemptBulkAction.
   * When brand completes the bulk action on the Member table.
   * Specifically adding to group, adding tags, or adding to project
   * @category Member
   */
  [EventName.CompleteBulkAction]: ICompleteBulkActionProperties;
  /**
   * Related to AttemptBulkSendMessage.
   * When brand completes the action to bulk send a message (e.g. from member table or projects table)
   * SB Note: I believe this event is not firing correctly
   * @category Messaging
   */
  [EventName.CompleteBulkSendMessage]: ICompleteBulkSendMessageProperties;
  /**
   * Deprecated because requirements are deprecated. Fired when a user marks a requirement as completed
   * @category Requirements
   */
  [EventName.CompleteRequirement]: ICompleteRequirementProperties;
  /**
   * When user manually uploads content via the content app. Specifically doing so from within the member
   * context and NOT from the dashboard context.
   * @category Content
   */
  [EventName.ContentCreated]: IContentCreatedProperties;
  /**
   * This is not implemented. SB note: FWIW, we had some vision early on to group all things like
   * payments/content/posts as Artifacts, so this event should have covered all types and then can
   * break down by artifact type. Could be a way to analyze outputs when workflows become more diverse
   * (i.e. total artifact creation volume + having some universal notion of "value" for the artifact)
   * @category Other
   */
  [EventName.CreateArtifact]: IProperties;
  /**
   * When user creates a new group
   * @category Members
   */
  [EventName.CreateCommunity]: IProperties; // No Event Specific Properties
  /**
   * Deprecated. Fired when user created an old pre-workflow Program/Project
   * @category Programs
   */
  [EventName.CreateProgram]: IProperties; // No Event Specific Properties
  /**
   * Deprecated because requirements are deprecated. When user creates a new requirement.
   * @category Requirements
   */
  [EventName.CreateRequirement]: ICreateRequirementProperties;
  /**
   * When user creates a new folder of segments in the Members table
   * @category Members
   */
  [EventName.CreateSegmentGroup]: IProperties; // No Event Specific Properties
  /**
   * When user creates a new saved segment in the Members table
   * @category Members
   */
  [EventName.CreateSegment]: ICreateSegmentProperties;
  /**
   * When user creates a new project.
   * S.B. note: because of how it is implemented, I am a little worried this event fires twice
   * every time someone makes a new Project
   * @category Projects
   */
  [EventName.CreatedProject]: ICreatedProjectProperties;
  /**
   * When user either opens OR leaves the creator search page
   * @category CreatorSearch
   */
  [EventName.CreatorSearchSession]: ICreatorSearchSessionProperties;
  /**
   * When prospective member leaves the application form
   * @category LandingPages
   */
  [EventName.ApplicationPageFormSession]: IApplicationPageFormSessionProperties;
  /**
   * When prospective member first clicks into a form field during any session on the landing page
   * @category LandingPages
   */
  [EventName.ApplicationPageFormStart]: IApplicationPageFormStartProperties;
  /**
   * When prospective member scrolls to within 10 px of the bottom of the application page
   * @category LandingPages
   */
  [EventName.ApplicationPageFormScroll]: IApplicationPageFormScrollProperties;
  /**
   * When prospective member leaves the application form, and that user has not submitted the
   * application form
   * @category LandingPages
   */
  [EventName.ApplicationPageFieldsFilledExit]: IApplicationPageFieldsFilledExitProperties,
  /**
   * When user clicks to load a saved search, including clicking to clear the saved search by clicking
   * "Save & Load Searches" (S.B. note, this is really strange UX that hopefully gets fixed soon
   * to simply say "Clear Saved Search")
   * @category CreatorSearch
   */
  [EventName.CreatorSearchSearchSelected]: ICreatorSearchProperties;
  /**
   * When user clicks to delete a saved search
   * @category CreatorSearch
   */
  [EventName.CreatorSearchSearchDeleted]: ICreatorSearchProperties;
  /**
   * When user successfully saves a new saved search, either by updating an existing one, or by creating and
   * naming a ne wone
   * @category CreatorSearch
   */
  [EventName.CreatorSearchSearchSaved]: ICreatorSearchProperties;
  /**
   * When a member successfully confirms or updates their information via the Info Update app
   * @category InfoUpdate
   */
  [EventName.DataRequestCompleted]: IDataRequestCompletedProperties;
  /**
   * When a user opens the form to create a new bulk info update request
   * @category InfoUpdate
   */
  [EventName.DataRequestOpened]: IDataRequestOpenedProperties;
  /**
   * When a user successfully sends a bulk info update request
   * @category InfoUpdate
   */
  [EventName.DataRequestSent]: IDataRequestSentProperties;
  /**
   * In theory -- when a member opens the info update request form.
   * S.B note: "In theory" because actually this event fires any time we fetch the data from the server.
   * Currently this does seem aligned with opening the page, but that is not guaranteed in the future
   * @category InfoUpdate
   */
  [EventName.DataRequestVisited]: IDataRequestVisitedProperties;
  /**
   * When user edits a Group
   * @category Members
   */
  [EventName.EditCommunity]: IProperties; // No Event Specific Properties
  /**
   * When user directly edits the fields on the member detail right-side panel
   * @category Members
   */
  [EventName.EditMember]: IEditMemberProperties;
  /**
   * Deprecated. Fired when user modifies an old pre-workflow Program/Project
   * @category Programs
   */
  [EventName.EditProgram]: IProperties; // No Event Specific Properties
  /**
   * When user modifies an existing saved segment in the Members table
   * @category Members
   */
  [EventName.EditSegment]: IProperties; // No Event Specific Properties
  /**
   * When user clicks into the main editor window of the email editor.
   * S.B. note: I added this event in 2022 to test a hypthesis to see if users
   * were taking a long time to write emails or were not actualy sending emails despite starting to write them.
   * Mostly my hypothesis was proven false.
   * @category Messaging
   */
  [EventName.EmailEditorFocused]: IEmailEditorFocused,
  /**
   * When user takes any of 4 actions on email templates:
   * 1. Create
   * 2. Update
   * 3. Reset
   * 4. Delete
   * @category Messaging
   */
  [EventName.MessageTemplates]: IMessageTemplatesEventProperties;
  /**
   * When user exports the currently selected members to CSV from the member table
   * @category Members
   */
  [EventName.ExportMembers]: IExportMembersProperties;
  /**
   * When user clicks on the link to setup their application page from the "Find Creators" page
   * @category Projects
   */
  [EventName.FindCreatorsApplicationClick]: IFindCreatorsClickProperties;
  /**
   * When user clicks on the link to browse creators on search from the "Find Creators" page
   * @category Projects
   */
  [EventName.FindCreatorsBrowseClick]: IFindCreatorsClickProperties;
  /**
   * When user opens the "Find Creators" page
   * @category Projects
   */
  [EventName.FindCreatorsPageVisit]: IProperties;
  /**
   * Not implemented
   * @category Other
   */
  [EventName.FindCreatorsSubmitLandingPage]: ILandingPageSentForReview,
  /**
   * Triggered when there are errors with importing a CSV on the members table. See "error_type" field for more
   * info on the errors
   * @category Members
   */
  [EventName.ImportErrors]: IImportErrorsProperties;
  /**
   * When user clicks on the button to start a new import process from the Member table.
   * @category Members
   */
  [EventName.ImportMembers]: IProperties; // No Event Specific Properties
  /**
   * When user clicks on the Settings button of the IGDM Adoption Invite Banner
   * from the Creator Search or Member Page
   */
  [EventName.InstagramInviteBannerCTA] : IInstagramInviteBannerCTA;
  /**
   * When user clicks to publish a landing page for the first time for that Project
   * @category LandingPages
   */
  [EventName.LandingPagePublish]: ILandingPagePublish;
  /**
   * Not implemented
   * @category Other
   */
  [EventName.LandingPageSentForReview]: ILandingPageSentForReview,
  /**
   * In theory -- When a prospective member loads an application page
   * S.B. note; "In theory" because it actually fires based on server fetch of the data. Currently
   * this does appear to be correlated with loading the application page, but is not guaranteed
   * @category LandingPages
   */
  [EventName.LoadOnboardingPage]: ILoadOnboardingPageProgrerties;
  /**
   * Fired whenever the user changes pages within the single-page application
   * @category General
   */
  [EventName.LoadPage]: ILoadPageProperties;
  /**
   * Fired once whenever the user launches the single-page application. Refreshing would cause this event
   * to fire again.
   * @category General
   */
  [EventName.LoadSession]: IProperties; // No Event Specific Properties
  /**
   * When the user marks a thread as completed in the inbox.
   * @category Inbox
   */
  [EventName.MarkThreadDone]: IMarkThreadDoneProperties;
  /**
   * When the user marks a thread as incompleted in the inbox.
   * @category Inbox
   */
  [EventName.MarkThreadTodo]: IMarkThreadTodoProperties;
  /**
   * When the user chooses to unlist their Project from the marketplace
   * @category Marketplace
   */
  [EventName.MarketplaceDeactivate]: IMarketplaceClickProperties,
  /**
   * When the user chooses to modify their marketplace listing (and campaign has already been published)
   * @category Marketplace
   */
  [EventName.MarketplaceEditComplete]: IMarketplaceCompletionClickProperties,
  /**
   * When the user chooses to save their marketplace listing and publish to marketplace for the first
   * time for this Project
   * @category Marketplace
   */
  [EventName.MarketplacePublishComplete]: IMarketplaceCompletionClickProperties,
  /**
   * When user edits the Project after the project has been delisted due to being in marketplace for
   * 45 days (or automatic delist time).
   * S.B. note: I might be understanding this event wrong, because it seems really niche and oddly named
   * @category Marketplace
   */
  [EventName.MarketplacePublishEdit]: IMarketplaceClickProperties,
  /**
   * When user clicks "Start" button to start entering information to list their Project into the marketplace
   * @category Marketplace
   */
  [EventName.MarketplacePublishStart]: IMarketplaceClickProperties,
  /**
   * When user clicks to relist a campaign that was previously unlisted from the marketplace
   * @category Marketplace
   */
  [EventName.MarketplaceReactivate]: IMarketplaceClickProperties,
  /**
   * When user clicks to change a member application status (e.g. accept or reject)
   * S.B. note: based on how it is implemented, I am very concerned this is firing double if the user
   * takes the action from inside SPv2, because current implementation fires both from FE in SPv2 and from
   * BE in the actual operation application.
   * @category Projects
   */
  [EventName.MemberApplicantOperation]: IMemberApplicantOperationProperites;
  /**
   * When user clicks to modify what columns are shown in the Members table or Projects table
   * @category Members
   */
  [EventName.MemberColumnClick]: IProperties;
  /**
   * When user clicks to select a column to be shown in the Members/Projects table
   * @category Members
   */
  [EventName.MemberColumnSelect]: IMemberColumnSelectProperties;
  /**
   * When user views the member detail pane (could be from any context like Members table/Projects table)
   * S.B. Note: Based on event implementation, I have no confidence that this is not firing multiple times per view
   * @category Members
   */
  [EventName.MemberDetailView]: IProperties;
  /**
   * When user clicks on "Add Filters" button on Member table
   * @category Members
   */
  [EventName.MemberFilterClick]: IProperties;
  /**
   * When user actually adds a filter on Member table
   * @category Members
   */
  [EventName.MemberFilterSelect]: IMemberFilterSelectProperties;
  /**
   * Rather odd event that seems to fire after Project invites are sent (or not) from the server.
   * Looks to be mostly for debugging purposes or to understand possible reasons that invites
   * might not be sending successfully.
   * @category Projects
   */
  [EventName.MemberInviteToProjectResult]: IMemberInviteToProjectResultProperties;
  /**
   * When Project invites are sent (or not) from the server. Note that this event appears to fire
   * even if the invite is not successfully sent.
   * @category Projects
   */
  [EventName.MemberInviteToProject]: IMemberInviteToProjectProperties;
  /**
   * When user clicks "Mark as Done" bulk action in the Projects table
   * @category Projects
   */
  [EventName.MovedCaseStage]: IMovedCaseStageProperties;
  /**
   * When server encounters an error processing the OAuth callback for any of our auths (e.g. GMail/Shopify/IG)
   * @category Setup
   */
  [EventName.OAuthGrantError]: IOAuthGrantErrorProperties;
  /**
   * When user attempts to connect any resource through authentication (e.g. GMail/Shopify/IG)
   * @category Setup
   */
  [EventName.OAuthGrantStart]: IOAuthGrantStartProperties;
  /**
   * When server successfully authenticates the user's third party account (e.g. GMail/Shopify/IG)
   * @category Setup
   */
  [EventName.OAuthGrantSuccess]: IOAuthGrantSuccessProperties;
  /**
   * When server encounters an error trying to process user's request to revoke access to a third-party account
   * (e.g. Gmail/Shopify/IG)
   * @category Setup
   */
  [EventName.OAuthRevokeError]: IOAuthRevokeErrorProperties;
  /**
   * When server receives a request from user to revoke access to a third party account (e.g. Gmail/Shopify/IG)
   * @category Setup
   */
  [EventName.OAuthRevokeStart]: IOAuthRevokeStartProperties;
  /**
   * When server revokes access to a third party account (e.g. Gmail/Shopify/IG) based on user request
   * @category Setup
   */
  [EventName.OAuthRevokeSuccess]: IOAuthRevokeSuccessProperties;
  /**
   * When user completes "Influencer Brief and Compensation" section of new onboarding flow
   * @category Onboarding
   */
  [EventName.OnboardingFlowCreators]: IOnboardingFlowProperties;
  /**
   * When user completes new onboarding flow, and is landed into the Applicants section of their newly created Project
   * @category Onboarding
   */
  [EventName.OnboardingFlowFinish]: IProperties;
  /**
   * When user completes intro ("Setup your first Project") section of new onboarding flow
   * @category Onboarding
   */
  [EventName.OnboardingFlowIntro]: IProperties;
  /**
   * When user completes objective ("Discover The Right Campaign Strategy For Your First Project")
   * section of new onboarding flow
   * @category Onboarding
   */
  [EventName.OnboardingFlowObjective]: IProperties;
  /**
   * When user lists their new project into the marketplace in the new onboarding flow
   * @category Onboarding
   */
  [EventName.OnboardingFlowList]: IOnboardingFlowProperties;
  /**
   * When user completes listing ("Personalize Your Creator Marketplace Listing")
   * section of new onboarding flow
   * @category Onboarding
   */
  [EventName.OnboardingFlowPersonalize]: IOnboardingFlowProperties;
  /**
   * When user completes preview ("Preview and List Your Application Page")
   * section of new onboarding flow
   * @category Onboarding
   */
  [EventName.OnboardingFlowPreview]: IOnboardingFlowProperties;
  /**
   * When user chooses to skip parts of new onboarding such that Project will be created, but will not
   * be listed in the marketplace.
   * @category Onboarding
   */
  [EventName.OnboardingFlowSkip]: IOnboardingFlowProperties;
  /**
   * When user starts the new onboarding flow
   * @category Onboarding
   */
  [EventName.OnboardingFlowWelcome]: IProperties;
  /**
   * When user opens any application (from any context, e.g. Projects, member detail, or opens the app's dashboard view)
   * @category General
   */
  [EventName.OpenApp]: IOpenAppProperties;
  /**
   * When user opens the member table with a saved segment selected.
   * @category Members
   */
  [EventName.OpenSegment]: IOpenSegmentProperties;
  /**
   * When user opens the PFA app to send a new order, but not all members have valid addresses
   * @category PFA
   */
  [EventName.PFAMissingAddress]: IPFAMissingAddressProperties;
  /**
   * When user opens the PFA app to send a new order, but no valid (non-revoked) Shopify account is available
   * to send with
   * @category PFA
   */
  [EventName.PFAMissingResource]: IPFAMissingResourceProperties;
  /**
   * When user successfully creates orders via PFA
   * @category PFA
   */
  [EventName.PFAOrdersSubmitted]: IPFAOrdersSubmittedProperties;
  /**
   * When server encounters an error trying to send emails to members following the creation of orders in PFA
   * @category PFA
   */
  [EventName.PFASendEmailFailed]: IPFASendEmailFailedProperties;
  /**
   * When a user sends either a single payment (from payment app) or a bulk payment (from STA)
   * @category Payments
   */
  [EventName.PaymentSent]: IPaymentSentEventProperties | ISTAPaymentSentEventProperties;
  /**
   * Not implemented
   * @category Other
   */
  [EventName.ProductScore]: IProperties;
  /**
   * SB note: I genuinely don't know how this gets used. It does seem to get called when members are removed from
   * community, and this appears to be in search code logic. But I actually don't know where in product one does this
   * @category CreatorSearch
   */
  [EventName.ProspectsRemoveFromCommunity]: IProspectsRemoveFromCommunityProperties;
  /**
   * Server syncs a message from a user's GMail/Outlook inbox in which they receive a message from a member
   * @category Messaging
   */
  [EventName.ReceiveMessage]: IReceiveMessageProperties;
  /**
   * User starts the process to authorize a Shopify account.
   * SB Note: I guess this should just merge with OAuthGrantStart, and it's just legacy that this is here
   * as a separate event
   * @category Setup
   */
  [EventName.ResourceAuthStart]: IResourceAuthStartProperties;
  /**
   * User tried to create a Sales Tracking offer. This event will fire even if the attempt results in an error
   * from the server
   * @category STA
   */
  [EventName.SalesTrackingCreateOffer]: TSalesTrackingCreateOfferProperties;
  /**
   * User tried to create a Sales Tracking offer. This event will fire even if the attempt results in an error
   * from the server
   * @category STA
   */
  [EventName.SalesTrackingEditOffer]: TSalesTrackingEditOfferProperties | TSalesTrackingEditOfferProgramProperties;
  /**
   * When user generates link(s) for a member(s)
   * @category STA
   */
  [EventName.SalesTrackingGenerateLink]: ISalesTrackingGenerateLinkProperties;
  /**
   * When user generates promo code(s) for member(s)
   * @category STA
   */
  [EventName.SalesTrackingGeneratePromoCodes]: ISalesTrackingGenerateShopifyPromoCodesProperties;
  /**
   * When user exports results from the STA dashboard or offer tables
   * @category STA
   */
  [EventName.SalesTrackingTableExport]: ISalesTrackingTableExportProperties;
  /**
   * When user creates a new project, and selects which template to base their project (workflow) off of
   * @category STA
   */
  [EventName.SelectProjectTemplate]: ISelectProjectTemplateProperties;
  /**
   * When user attempts to send an email-type (this may change in future if we support other message channels) message to a member
   * @category Messaging
   */
  [EventName.SendMessage]: ISendMessageProperties;
  /**
   * When user sends an email message to a member
   * @category Messaging
   */
  [EventName.SendMessageEventually]: ISendMessageEventuallyProperties;
  /**
   * When user opens PFA, and we are unable to fetch products.
   * SB note: Based on event name, it seems thought is this should mostly due to invalid token, though I'm not sure
   * that necessarily must be the case
   * @category PFA
   */
  [EventName.ShopifyInvalidToken]: IShopifyInvalidTokenProperties;
  /**
   * When user manually assigns a social post to a Project/Group
   * @category SocialPosts
   */
  [EventName.SocialPostAssigned]: ISocialPostAssignedProperties;
  /**
   * Not implemented
   * @category Other
   */
  [EventName.SocialPostCreated]: ISocialPostCreatedProperties;
  /**
   * When user approves an applicant from the new SPv2 header
   * @category Projects
   */
  [EventName.SocialProfileApprove]: ISocialProfileApplicantOperationProperties;
  /**
   * When user rejects an applicant from the new SPv2 header
   * @category Projects
   */
  [EventName.SocialProfileReject]: ISocialProfileApplicantOperationProperties;
  /**
   * When user starts creation of a new Project. Specifically this appears to definitely cover starting a project
   * from the Project selection dropdown and from Members table.
   * SB Note: As there are now many ways to create Projects, I am not sure that this event covers
   * all of them any more (e.g. home or new onboarding flow)
   * @category Projects
   */
  [EventName.StartCreateNewProject]: IStartCreateNewProjectProperties;
  /**
   * When prospective member submits the Project application form
   * @category LandingPages
   */
  [EventName.SubmitOnboardingPage]: ISubmitOnboardingPageProperties;
  /**
   * When user submits request to server to send bulk terms to member(s)
   * @category Terms
   */
  [EventName.SubmitBulkTerms]: ISubmitBulkTermsProperties;
  /**
   * When user opens the application for the first time in a specific browser session (i.e. one specific tab)
   * @category General
   */
  [EventName.UserLogin]: IUserLoginProperties;
  /**
   * When user navigates to a specific group in the Members table
   * @category Members
   */
  [EventName.ViewGroupInMemberList]: IViewGroupInMemberListProperties;
  /**
   * Not implemented
   * @category Other
   */
  [EventName.ViewGroupsListPage]: IProperties;
  /**
   * When user views a thread in the inbox. This would fire multiple times if user views multiple threads
   * @category Inbox
   */
  [EventName.ViewThread]: IViewThreadProperties;
  /**
   * When user removes a member from a Project
   * @category Projects
   */
  [EventName.WorkflowCaseCanceled]: IWorkflowCaseCanceled;
  /**
   * When a member/user completes all tasks for a given Project. Note this could happen multiple times if the
   * project is restarted for that member
   * @category Projects
   */
  [EventName.WorkflowCaseCompleted]: IWorkflowCaseCompleted;
  /**
   * When a member is added to a Project's workflow (i.e. they are approved) or the user restarts a Project for
   * a member
   * @category Projects
   */
  [EventName.WorkflowCaseCreated]: IWorkflowCaseCreated;
  /**
   * When a member or user completes a specific task for that member within a Project's workflow. E.g. if they
   * complete the SendTerms task (but have not agreed to terms yet, just sent them)
   * @category Projects
   */
  [EventName.WorkflowCaseTaskCompleted]: IWorkflowCaseTaskCompleted;
  /**
   * When a member moves into a new task to be completed within a Project's workflow
   * @category Projects
   */
  [EventName.WorkflowCaseTaskStarted]: IWorkflowCaseTaskStarted;
  /**
   * When a member or user completes all tasks for a given workflow. E.g. for the terms worklet, the member/user
   * successfully agree to terms. Note that this fires even if the member is "marked as done" or skipped out of the
   * current worklet.
   * @category Projects
   */
  [EventName.WorkflowCaseWorkletCompleted]: IWorkflowCaseWorkletCompleted;
  /**
   * When a member or user begins a new worklet. E.g. if the member enters the Send Terms worklet, then it is expected
   * the member/user will now try to take steps to agree to terms.
   * @category Projects
   */
  [EventName.WorkflowCaseWorkletCreated]: IWorkflowCaseWorkletCreated;
  // alloy integrations
  [EventName.InstallIntegrationWorkflow]: IIntegrationWorkflowProperties;
  [EventName.DeactivateIntegrationWorkflow]: IIntegrationWorkflowProperties;
  [EventName.RunIntegrationSyncWorkflow]: IIntegrationWorkflowProperties;
}
