import React from 'react';
import { Card, Progress, Typography } from '@revfluence/fresh';
import styles from './BudgetQuickInfoContent.module.scss';

const { Text, Title } = Typography;

interface IBudgetQuickInfoContentProps {
  budgetName: string;
  fiscalYears: {
    fiscalYear: string;
    totalBudget: number;
    availableBudget: number;
  }[];
}

const BudgetQuickInfoContent: React.FC<IBudgetQuickInfoContentProps> = (props) => {
  const { budgetName, fiscalYears } = props;

  if (!budgetName || !fiscalYears?.length) {
    return <div>Please select a budget</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Title level={5} style={{ margin: 0 }}>
          {budgetName}
        </Title>
      </div>
      <div className={styles.cardContainer}>
        {fiscalYears.map((year, index) => (
          <Card key={index} bodyStyle={{ padding: '8px' }} className={styles.fiscalYearCard}>
            <Text type="secondary" className={styles.fiscalYearTitle}>
              {year.fiscalYear}
            </Text>
            <div className={styles.budgetAmountContainer}>
              <Text className={styles.budgetAmount}>
                $
                {' '}
                {year.totalBudget.toLocaleString()}
              </Text>
              <Text className={styles.budgetAmount}>
                $
                {' '}
                {year.availableBudget.toLocaleString()}
              </Text>
            </div>
            <div className={styles.budgetLabelContainer}>
              <Text>Total Budget</Text>
              <div className={styles.availableIndicatorContainer}>
                <div className={styles.availableIndicator} />
                <Text className={styles.availableText}>Available</Text>
              </div>
            </div>
            <Progress
              percent={(year.availableBudget / year.totalBudget) * 100}
              showInfo={false}
              strokeColor="#5DB884"
            />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default BudgetQuickInfoContent;
