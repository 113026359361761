import * as React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Progress } from 'antd';
import cx from 'classnames';

import { Modal, Button, SubmitButton } from '@components';
import { DeleteStageStatus } from '../hooks/useDeleteStage';

import styles from './DeleteStageModal.scss';

interface IProps {
  open: boolean;
  count: number;
  onRequestClose(): void;
  onDelete(): void;
  status: DeleteStageStatus;
  percentage: number;
}

/**
 * @type {React.FC}
 */
export const DeleteStageModal: React.FC<Readonly<IProps>> = (props) => {
  const {
 status, percentage, onDelete, open, onRequestClose, count,
} = props;

  return (
    <Modal
      show={open}
      onRequestClose={onRequestClose}
      width={650}
      dialogClassName={styles.DeleteStageModal}
      showCloseIcon={false}
      disableCloseOnMaskClick
    >
      <div className={styles.title}>
        <ExclamationCircleOutlined className={styles.icon} />
        <span>Delete Stage</span>
      </div>
      <div className={styles.body}>
        <div className={styles.text}>Are you sure you want to delete this stage?</div>
        <ul className={styles.descriptions}>
          <li>
            You currently have
            {' '}
            {count}
            {' '}
            members in this stage that will be move to the next step.
          </li>
          <li>If you delete, they will not lose deliverables such as content, terms, contracts, payment data, etc.</li>
          <li>Members in this stage will not receive any notification of this occurrence.</li>
        </ul>
        {status === 'none' && (
          <div className={styles.actions}>
            <Button
              className={styles.button}
              label="Keep Stage"
              onClick={onRequestClose}
              theme="info"
            />
            <SubmitButton
              theme="primary"
              label="Delete Stage"
              onClick={onDelete}
              isSubmitting={false}
              submittingLabel="Deleting"
            />
          </div>
        )}
        {status === 'progress' && (
          <div className={styles.status}>
            <div className={styles.label}>Removing Stage...</div>
            <Progress className={styles.progressBar} percent={percentage} />
          </div>
        )}
        {status === 'failed' && (
          <>
            <div className={styles.status}>
              <div className={cx(styles.label, styles.failed)}>Removing stage failed</div>
              <div className={styles.instructions}>
                Please contact
                {' '}
                <a href="mailto:help@aspireiq.com">help@aspireiq.com</a>
                {' '}
                or try again.
              </div>
              <Progress percent={percentage} status="exception" />
            </div>
            <div className={styles.actions}>
              <Button
                className={styles.button}
                label="Keep Stage"
                onClick={onRequestClose}
                theme="info"
              />
              <SubmitButton
                theme="primary"
                label="Try Again"
                onClick={onDelete}
                isSubmitting={false}
                submittingLabel="Deleting"
              />
            </div>
          </>
        )}
        {status === 'success' && (
          <>
            <div className={styles.status}>
              <div className={cx(styles.label, styles.success)}>Stage removed!</div>
              <Progress percent={100} />
            </div>
            <div className={styles.actions}>
              <Button
                className={styles.button}
                label="Close"
                onClick={onRequestClose}
                theme="primary"
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
