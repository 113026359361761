import * as React from 'react';
import * as qs from 'qs';
import { flattenDeep, isEmpty, map } from 'lodash';

import { endpoints } from '@components';
import { useGet, IFetchResult } from '@frontend/utils';

import { ISocialAccount } from '@components';
import { ICommunity } from '@components';
import { useInviteContext } from './useInviteContext';

const { useMemo, useState } = React;

interface IProps {
  socialAccounts: ISocialAccount[];
}

export const useGroupsForSocialAccounts = (props: IProps) => {
  const { socialAccounts } = props;

  const [isAdding, setIsAdding] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [targetGroupId, setTargetGroupId] = useState(0);

  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();

  const hasSocialAccounts = !isEmpty(socialAccounts);

  const { network_identifier } = hasSocialAccounts
    ? socialAccounts[0]
    : { network_identifier: null };

  const socialAccountIds = useMemo(() => (
    map(socialAccounts, (socialAccount) => socialAccount.id)
  ), [socialAccounts]);

  const names = useMemo(() => (
    map(socialAccounts, (socialAccount) => socialAccount.full_display_name)
  ), [socialAccounts]);

  const attemptRetrieveEmails = useMemo(() => (
    map(socialAccounts, (socialAccount) => socialAccount.has_email)
  ), [socialAccounts]);

  /**
   * Get invited groups
   */
  const fetchGroupsUrl = useMemo(() => {
    if (!apiEndpoint || !clientId) {
      return;
    }

    const params = {
      client_id: clientId,
      account_ids: socialAccountIds,
      network_type: network_identifier,
    };

    const urlParams = qs.stringify(params, { arrayFormat: 'brackets' });

    return `${apiEndpoint}/${endpoints.communitiesForSocialAccounts}?${urlParams}`;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialAccounts, apiEndpoint, clientId]);

  const {
    loading: isFetching,
    data: fetchedGroups,
    error,
    refetch,
  } = useGet({
    url: fetchGroupsUrl,
    skip: !hasSocialAccounts,
    cache: true,
    showError: false,
  });

  const groups: ICommunity[] = useMemo(() => (
    flattenDeep(fetchedGroups)
  ), [fetchedGroups]);

  const addToGroup = async (communityId) => {
    if (!apiEndpoint || !clientId) {
      return;
    }

    const params = {
      client_id: clientId,
      account_ids: socialAccountIds,
      names,
      community_id: communityId,
      network_type: network_identifier,
      attempt_retrieve_emails: attemptRetrieveEmails,
      source: 'creator_search',
    };
    const urlParams = qs.stringify(params, { arrayFormat: 'brackets' });
    const url = `${apiEndpoint}/${endpoints.memberAddToCommunity}?${urlParams}`;

    const abortController = new AbortController();
    setIsAdding(true);
    setTargetGroupId(communityId);

    const response = await fetch(url, {
      method: 'POST',
      signal: abortController.signal,
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const json = await response.json() as IFetchResult<any>;

    setIsAdding(false);
    setTargetGroupId(0);
    if (refetch) {
      await refetch();
    }

    return json;
  };

  const removeFromGroup = async (communityId) => {
    if (!apiEndpoint || !clientId) {
      return;
    }

    const params = {
      client_id: clientId,
      account_ids: socialAccountIds,
      names,
      community_id: communityId,
      network_type: network_identifier,
    };
    const urlParams = qs.stringify(params, { arrayFormat: 'brackets' });
    const url = `${apiEndpoint}/${endpoints.memberRemoveFromCommunity}?${urlParams}`;

    const abortController = new AbortController();
    setIsRemoving(true);
    setTargetGroupId(communityId);

    const response = await fetch(url, {
      method: 'POST',
      signal: abortController.signal,
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const json = await response.json() as IFetchResult<any>;

    setIsRemoving(false);
    setTargetGroupId(0);
    if (refetch) {
      await refetch();
    }

    return json;
  };

  return {
    groups: Object.freeze(groups),
    isFetching,
    error,
    addToGroup,
    isAdding,
    removeFromGroup,
    refetch,
    targetGroupId,
    isRemoving,
  };
};
