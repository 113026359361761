import * as React from 'react';
import { Typography } from '@revfluence/fresh';
import styles from './StepDescription.scss';

const { Text, Title, Link } = Typography;
interface IProps {
    title: string;
    description: string;
    linkText?: string;
    linkUrl?: string;
}

export const StepDescription: React.FC<IProps> = (props) => {
    const {
        title,
        description,
        linkText,
        linkUrl,
    } = props;

    return (
      <div className={styles.stepDescriptionContainer}>
        <Title level={5}>{title}</Title>
        <Text>
          {description}
          {' '}
          {linkText && (<Link href={linkUrl}>{linkText}</Link>)}
        </Text>
      </div>
    );
};
