import { GetOrdersByIds_orders } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetOrdersByIds';
import {
  Input, InputNumber, Tag,
} from '@revfluence/fresh';
import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { getDataSource } from '../../utils/getDataSource';
import { AvatarWithName, OrderTable } from '../OrderTable/OrderTable';

interface IProps {
  onOrderChange: (value: string | number, orderId: string, type: string) => void;
  onMarkAsShipped: () => void;
  onCancel: () => void;
  orders: GetOrdersByIds_orders[];
  loading: boolean;
}

interface ShipmentRow {
  key: string;
  orderId: string;
  name?: string;
  avatar?: string;
  quantity?: number;
  productName?: string;
  sku?: string;
  preferences?: string;
  productUrl?: string;
  trackingNumber?: string;
  productCost?: string;
  productLength: number;
}

export const ShipmentProcessing = ({
  onOrderChange, onCancel, onMarkAsShipped, orders, loading,
}: IProps) => {
  const columns: ColumnProps<ShipmentRow>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_text: string, record: ShipmentRow) => (
        record.name ? <AvatarWithName name={record.name} avatar={record.avatar} /> : null
      ),

    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Original Order',
      dataIndex: 'productName',
      key: 'productName',
      render: (text: string, record: ShipmentRow) => (
        <>
          <Tag>{text}</Tag>
          {(record?.key?.indexOf('parent') > -1 && record.productLength > 1)
            && <Tag>{`+ ${record.productLength - 1}`}</Tag>}
        </>
      ),

    },
    {
      title: 'Tracking Number',
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      render: (_text: string, record: ShipmentRow) => (
        <>
          {(record?.key?.indexOf('parent') > -1)
            && (
              <Input
                defaultValue={record.trackingNumber}
                onChange={(e) => onOrderChange(e.target.value, record.orderId, 'trackingNumber')}
              />
            )}
        </>

      ),
    },
    {
      title: 'Total Order Product Cost',
      dataIndex: 'productCost',
      key: 'productCost',
      render: (_text: string, record: ShipmentRow) => (
        <>
          {(record?.key?.indexOf('parent') > -1)
            && (
              <InputNumber
                prefix="$"
                defaultValue={record.productCost}
                min={0}
                onChange={(value) => {
                  onOrderChange(value || 0, record.orderId, 'cost');
                }}
              />
            )}
        </>
      ),
    },

  ];
  const dataSource = getDataSource(orders); return (
    <OrderTable
      ordersSelected={orders.length}
      onPrimaryAction={onMarkAsShipped}
      onSecondaryAction={onCancel}
      primaryActionText="Mark as Shipped"
      secondaryActionText="Cancel"
      title="Shipment Processing"
      description="Add tracking numbers and product costs to orders."
      columns={columns}
      dataSource={dataSource}
      rowKey="key"
      loading={loading}
    />
  );
};
