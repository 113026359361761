import React, { useEffect } from 'react';
import {
 Button, Card, Col, message, Row, Select, Skeleton, Space, Tabs, Typography,
} from '@revfluence/fresh';
import { ChevronRightIcon, PlusIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { ShopifyOwnerTypeEnum } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { v4 as uuidv4 } from 'uuid';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { GET_CLIENT_CONFIG_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries';
import { isEqual, pick } from 'lodash';
import drawerStyles from './ImportSettingsDrawer.scss';
import styles from './ProductDataSettings.scss';
import { IMetaField } from '../types';
import { DEFAULT_META_FIELDS, OWNER_TYPE_TO_META_FIELD_KEY } from './constants';
import { useGetMetaFieldsFromShopify } from '../hooks/useGetMetaFieldsFromShopify';
import { useImportSettingsContext } from './ImportSettingsContext';
import { useCreateMetaFields } from '../hooks/useCreateMetaFields';

const { Text } = Typography;

const useMetaFieldsValid = () => {
  const { collectionMetaFields, productMetaFields, variantMetaFields } = useImportSettingsContext();

  return collectionMetaFields.every((field) => !!field.key)
    && productMetaFields.every((field) => !!field.key)
    && variantMetaFields.every((field) => !!field.key);
};

const useIsChanged = () => {
  const { collectionMetaFields, productMetaFields, variantMetaFields } = useImportSettingsContext();

  const { clientConfig } = useGetClientConfig();

  if (!clientConfig?.metaFieldsMapping) {
    return !!(collectionMetaFields.length || productMetaFields.length || variantMetaFields.length);
  }

  return !isEqual(
    collectionMetaFields,
    clientConfig.metaFieldsMapping.collectionMetaFields?.map((f) => pick(f, ['id', 'key', 'name'])),
  ) || !isEqual(
    productMetaFields,
    clientConfig.metaFieldsMapping.productMetaFields?.map((f) => pick(f, ['id', 'key', 'name'])),
  ) || !isEqual(
    variantMetaFields,
    clientConfig.metaFieldsMapping.variantMetaFields?.map((f) => pick(f, ['id', 'key', 'name'])),
  );
};

const DefaultField = ({ field }: { field: IMetaField }) => (
  <Card key={field.id} className={styles.fieldCard}>
    <Row justify="space-between" align="middle">
      <Col>
        <Space>
          <Text>{field.name}</Text>
          {/* <Tag color="processing">Required</Tag> */}
        </Space>
      </Col>
      {/* <Col>
        <Switch
          checked={field.isEnabled}
          disabled
          onChange={(checked) => onChange(field.id, 'isEnabled', checked)}
          size="small"
        />
      </Col> */}
    </Row>
  </Card>
);

const CustomField = ({
  type,
  field,
  index,
}: {
  type: ShopifyOwnerTypeEnum;
  field: IMetaField;
  index: number;
}) => {
  const {
    collectionMetaFields, productMetaFields, variantMetaFields,
    showMetaFieldsValidationWarning, setMetaFieldById, removeCollectionMetaField, removeProductMetaField, removeVariantMetaField,
  } = useImportSettingsContext();

  const { metaFields, isMetaFieldsLoading } = useGetMetaFieldsFromShopify({
    variables: {
      ownerType: type,
    },
  });

  const customFields = {
    [ShopifyOwnerTypeEnum.COLLECTION]: collectionMetaFields,
    [ShopifyOwnerTypeEnum.PRODUCT]: productMetaFields,
    [ShopifyOwnerTypeEnum.PRODUCTVARIANT]: variantMetaFields,
  }[type];

  const handleFieldChange = (shopifyKey: string) => {
    const newField = metaFields?.[OWNER_TYPE_TO_META_FIELD_KEY[type]]?.find((metaField) => metaField.key === shopifyKey);
    setMetaFieldById(field.id, {
      id: newField.id,
      key: newField.key,
      name: newField.name,
    });
  };

  const handleRemoveField = () => {
    switch (type) {
      case ShopifyOwnerTypeEnum.COLLECTION:
        removeCollectionMetaField(field.id);
        break;
      case ShopifyOwnerTypeEnum.PRODUCT:
        removeProductMetaField(field.id);
        break;
      case ShopifyOwnerTypeEnum.PRODUCTVARIANT:
        removeVariantMetaField(field.id);
        break;
      default:
        break;
    }
  };

  const isFieldValid = !!field.key;

  return (
    <Card className={styles.fieldCard}>
      <Row justify="space-between" align="middle">
        <Col>
          <Text>
            Additional Data
            {' '}
            {index + 1}
          </Text>
        </Col>
        <Col>
          {
            isMetaFieldsLoading ? <Skeleton.Input className={styles.selectField} active /> : (
              <Space align="center">
                <Select
                  value={field.key ? field.key : undefined}
                  className={styles.selectField}
                  placeholder="Select"
                  onChange={handleFieldChange}
                  status={showMetaFieldsValidationWarning && !isFieldValid && 'error'}
                >
                  {
                  metaFields?.[OWNER_TYPE_TO_META_FIELD_KEY[type]]?.map((metaField) => (
                    <Select.Option key={metaField.id} value={metaField.key} disabled={!!customFields.find((f) => f.key === metaField.key)}>
                      {metaField.name}
                    </Select.Option>
                  ))
                }
                </Select>
                <Button icon={<XmarkIcon />} type="text" onClick={handleRemoveField} />
              </Space>
            )
          }
        </Col>
        {showMetaFieldsValidationWarning && !isFieldValid && (
          <Col xs={24}>
            <Text type="danger">Please select any field!</Text>
          </Col>
        )}
      </Row>
    </Card>
  );
};

const FieldSettings = ({ type, title }: { type: ShopifyOwnerTypeEnum, title: string }) => {
  const {
    collectionMetaFields,
    productMetaFields,
    variantMetaFields,
    addCollectionMetaField,
    addProductMetaField,
    addVariantMetaField,
    setState,
  } = useImportSettingsContext();

  const { clientConfig, loading: isClientConfigLoading } = useGetClientConfig();

  const customFields = {
    [ShopifyOwnerTypeEnum.COLLECTION]: collectionMetaFields,
    [ShopifyOwnerTypeEnum.PRODUCT]: productMetaFields,
    [ShopifyOwnerTypeEnum.PRODUCTVARIANT]: variantMetaFields,
  }[type];

  const handleAddCustomField = () => {
    switch (type) {
      case ShopifyOwnerTypeEnum.COLLECTION:
        addCollectionMetaField({ id: uuidv4(), key: '', name: '' });
        break;
      case ShopifyOwnerTypeEnum.PRODUCT:
        addProductMetaField({ id: uuidv4(), key: '', name: '' });
        break;
      case ShopifyOwnerTypeEnum.PRODUCTVARIANT:
        addVariantMetaField({ id: uuidv4(), key: '', name: '' });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (clientConfig?.metaFieldsMapping?.collectionMetaFields) {
      setState({
        collectionMetaFields: clientConfig.metaFieldsMapping.collectionMetaFields.map((field) => ({
          id: field.id,
          key: field.key,
          name: field.name,
        })),
      });
    }
    if (clientConfig?.metaFieldsMapping?.productMetaFields) {
      setState({
        productMetaFields: clientConfig.metaFieldsMapping.productMetaFields.map((field) => ({
          id: field.id,
          key: field.key,
          name: field.name,
        })),
      });
    }
    if (clientConfig?.metaFieldsMapping?.variantMetaFields) {
      setState({
        variantMetaFields: clientConfig.metaFieldsMapping.variantMetaFields.map((field) => ({
          id: field.id,
          key: field.key,
          name: field.name,
        })),
      });
    }
  }, [clientConfig, setState]);

  if (isClientConfigLoading) {
    return <Skeleton active />;
  }

  return (
    <Space direction="vertical" className={`${drawerStyles.spaceFlex} ${styles.ProductDataSettings}`}>
      <Text weight="semibold">{title}</Text>
      {
        DEFAULT_META_FIELDS[type].map((field) => (
          <DefaultField key={field.id} field={field} />
        ))
      }
      {
        type !== ShopifyOwnerTypeEnum.COLLECTION && customFields.map((field, index) => (
          <CustomField key={field.id} field={field} type={type} index={index} />
        ))
      }
      {type !== ShopifyOwnerTypeEnum.COLLECTION && customFields.length < 10 && (
        <Button type="link" icon={<PlusIcon />} onClick={handleAddCustomField}>
          Add Additional Field
        </Button>
      )}
      {customFields.length === 10 && (
        <Text type="secondary">You have reached the maximum limit of 10 additional fields</Text>
      )}
    </Space>
  );
};

export const ProductDataSettingsFooter = () => {
  const {
 collectionMetaFields, productMetaFields, variantMetaFields, showMetaFieldsValidationWarning, setState, setShowMetaFieldsValidationWarning,
} = useImportSettingsContext();

  const isMetaFieldsValid = useMetaFieldsValid();

  const isChanged = useIsChanged();

  const { createMetaFields, isCreatingMetaFields } = useCreateMetaFields({
    refetchQueries: [GET_CLIENT_CONFIG_QUERY],
    onCompleted: () => {
      message.success('Meta fields updated successfully');
      setState({
        collectionMetaFields: [],
        productMetaFields: [],
        variantMetaFields: [],
      });
    },
    onError: () => {
      message.error('Failed to update meta fields');
    },
  });

  const handleCreateMetaFields = () => {
    if (!isMetaFieldsValid) {
      setShowMetaFieldsValidationWarning(true);
      return;
    }
    createMetaFields({
      variables: {
        metaFieldsMapping: {
          collectionMetaFields,
          productMetaFields,
          variantMetaFields,
        },
      },
    });
  };

  return (
    <Row align="middle" justify="center" gutter={24}>
      <Col flex="215px">
        <Button className={drawerStyles.footerBtn} size="large" disabled={isCreatingMetaFields}>Cancel</Button>
      </Col>
      <Col flex="215px">
        <Button
          type="primary"
          className={drawerStyles.footerBtn}
          size="large"
          onClick={handleCreateMetaFields}
          loading={isCreatingMetaFields}
          disabled={!isChanged || (showMetaFieldsValidationWarning && !isMetaFieldsValid)}
        >
          Update
          <ChevronRightIcon />
        </Button>
      </Col>
    </Row>
    );
  };

export const ProductDataSettings = () => (
  <Space direction="vertical" size="large" style={{ width: '100%' }}>
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          children: <FieldSettings type={ShopifyOwnerTypeEnum.PRODUCT} title="Import Product Data Fields " />,
          key: ShopifyOwnerTypeEnum.PRODUCT,
          label: 'Product Data',
        },
        {
          children: <FieldSettings type={ShopifyOwnerTypeEnum.PRODUCTVARIANT} title="Import Product Variant Data Fields " />,
          key: ShopifyOwnerTypeEnum.PRODUCTVARIANT,
          label: 'Variant Data',
        },
        {
          children: <FieldSettings type={ShopifyOwnerTypeEnum.COLLECTION} title="Import Collection Data Fields " />,
          key: ShopifyOwnerTypeEnum.COLLECTION,
          label: 'Collection Data',
        },
      ]}
    />
  </Space>
);
