import {
    QueryHookOptions,
    useQuery,
  } from '@apollo/client';

  import { GET_KLAVIYO_SYNC_SETTINGS } from '@frontend/app/queries';
  import {
    GetKlaviyoSyncSettings,
  } from '@frontend/app/queries/types/GetKlaviyoSyncSettings';

  type IOptions = QueryHookOptions<GetKlaviyoSyncSettings>;

  export const useGetKlaviyoSyncSettings = (options: IOptions = {}) => (
    useQuery<GetKlaviyoSyncSettings>(GET_KLAVIYO_SYNC_SETTINGS, options)
  );
