import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_PRODUCT_ATTRIBUTES_BY_CLIENT_ID } from '@frontend/applications/ProductFulfillmentApp/queries/selectionCriteria';
import {
  GetProductAttributesByClientId,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductAttributesByClientId';

type IOptions = QueryHookOptions<GetProductAttributesByClientId>;

export const useGetProductAttributesByClientId = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch,
} = useQuery(GET_PRODUCT_ATTRIBUTES_BY_CLIENT_ID, options);

  return {
    productAttributes: data?.getProductAttributesByClientId,
    loading,
    error,
    refetch,
  };
};
