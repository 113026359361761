import gql from 'graphql-tag';

import { GUIDELINE_FRAGMENT } from './fragments/GuidelineFragment';

export const GET_CONTENT_GUIDELINES_BY_PROJECT_QUERY = gql`
  query GetContentGuidelinesByProject($programId: Int!) {
    guidelines: getContentGuidelinesByProject(programId: $programId) {
      contentGuidelines {
        id
        type
        label
        networkType
        priority
        attachments {
          filename
          url
          type
        }
        guidelines {
          ...GuidelineFragment
        }
        scope
      }
      defaultContentGuidelines {
        id
        type
        label
        networkType
        priority
        attachments {
          filename
          url
          type
        }
        guidelines {
          ...GuidelineFragment
        }
        scope
      }
      globalContentGuidelines {
        id
        type
        label
        networkType
        priority
        attachments {
          filename
          url
          type
        }
        guidelines {
          ...GuidelineFragment
        }
        scope
      }
      memberContentGuidelines {
        id
        type
        label
        networkType
        priority
        attachments {
          filename
          url
          type
        }
        guidelines {
          ...GuidelineFragment
        }
        scope
      }
      termsContentGuidelines {
        id
        type
        label
        networkType
        priority
        attachments {
          filename
          url
          type
        }
        guidelines {
          ...GuidelineFragment
        }
        scope
      }
    }

  }
  ${GUIDELINE_FRAGMENT}
`;
