import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  BULK_SEND_SHOPIFY_ORDER_REQUEST_MUTATION,
} from '../queries';
import { BulkSendShopifyOrderRequestMutation, BulkSendShopifyOrderRequestMutationVariables } from '../queries/types/BulkSendShopifyOrderRequestMutation';

type IOptions = MutationHookOptions<BulkSendShopifyOrderRequestMutation, BulkSendShopifyOrderRequestMutationVariables>;

export function useBulkSendShopifyOrderRequest(options: IOptions = {}) {
  const [bulkSendShopifyOrderRequest, { loading, error }] = useMutation<
  BulkSendShopifyOrderRequestMutation, BulkSendShopifyOrderRequestMutationVariables
  >(BULK_SEND_SHOPIFY_ORDER_REQUEST_MUTATION, options);

  return {
    bulkSendShopifyOrderRequest,
    isBulkSendShopifyOrderRequestLoading: loading,
    bulkSendShopifyOrderRequestError: error,
  };
}
