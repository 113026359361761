import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_COUNTS_FOR_PROJECT_QUERY } from '@frontend/app/queries';
import {
  GetCountsForProjectQuery,
  GetCountsForProjectQueryVariables,
} from '@frontend/app/queries/types/GetCountsForProjectQuery';

type IOptions = QueryHookOptions<GetCountsForProjectQuery, GetCountsForProjectQueryVariables>;

export const useGetCountsForProjectQuery = (options: IOptions = {}) => (
  useQuery<GetCountsForProjectQuery, GetCountsForProjectQueryVariables>(GET_COUNTS_FOR_PROJECT_QUERY, options)
);
