/* eslint-disable */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_19yrm_158 {
  display: flex;
}

._justify-content-space-between_19yrm_162 {
  justify-content: space-between;
}

._tabular-nums_19yrm_166 {
  font-variant-numeric: tabular-nums;
}

._RangeSlider_19yrm_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_19yrm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._RangeSlider_19yrm_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._RangeSlider_19yrm_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._RangeSlider_19yrm_170 ::-webkit-scrollbar-track, ._RangeSlider_19yrm_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._RangeSlider_19yrm_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._label_19yrm_205 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._label_19yrm_205 {
  padding-bottom: 8px;
}

._description_19yrm_297 {
  padding-top: 8px;
  display: flex;
  align-items: center;
}
._description_19yrm_297._descriptionWithInput_19yrm_302 {
  padding-top: 12px;
}
._description_19yrm_297._noDescription_19yrm_305 ._Input_19yrm_305:last-of-type {
  margin-right: 0;
}

._isDragging_19yrm_309 {
  cursor: grabbing;
}

._trackWrapper_19yrm_313 {
  position: relative;
  height: 20px;
  margin: 0 10px;
  cursor: pointer;
}
._trackWrapper_19yrm_313 ._Track_19yrm_319 {
  position: relative;
  top: calc(50% - 3px);
  left: -10px;
  right: -10px;
  overflow: hidden;
  border-radius: 624.9375rem;
  width: calc(100% + 20px);
  height: 6px;
  background-color: #eff5f9;
}
._trackWrapper_19yrm_313 ._selected_19yrm_330 {
  position: absolute;
  height: 100%;
  background-color: #3996e0;
  transition-property: left, right;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0s;
}
._trackWrapper_19yrm_313._isDragging_19yrm_309 {
  cursor: grabbing;
}

._Handle_19yrm_343 {
  position: absolute;
  top: 0;
  z-index: 1;
  border: 1px solid #dadcde;
  width: 20px;
  height: 20px;
  border-radius: 624.9375rem;
  background: #fdfdfd;
  transition-property: background-color, border-color, left, transform;
  transform-origin: 0% 50%;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0s;
  transform: translateX(-10px);
  outline: none;
  cursor: move;
  cursor: grab;
}
._Handle_19yrm_343._focused_19yrm_362 {
  z-index: 2;
}
._Handle_19yrm_343._disabled_19yrm_365 {
  pointer-events: none;
  background-color: #eff5f9;
}
._Handle_19yrm_343._grabbed_19yrm_369 {
  border-color: #3996e0;
  background-color: #eff5f9;
  cursor: grabbing;
}

._Input_19yrm_305 {
  width: 120px;
  height: 36px;
  display: inline-block;
}
._Input_19yrm_305:first-of-type {
  margin-right: 16px;
}
._Input_19yrm_305:last-of-type:not(:first-of-type) {
  margin-left: 16px;
  margin-right: 16px;
}
._Input_19yrm_305 input {
  text-align: center;
  letter-spacing: 1px;
}