/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_yx1dd_157 {
  display: flex;
}

._justify-content-space-between_yx1dd_161 {
  justify-content: space-between;
}

._tabular-nums_yx1dd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_yx1dd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TopNavbar_yx1dd_209 {
  display: flex;
  justify-content: space-between;
  height: 3.25rem;
  box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 0.25rem;
}

._Left_yx1dd_217 {
  display: flex;
  align-items: center;
}
._Left_yx1dd_217 ._logo_yx1dd_221 {
  height: 2rem;
  padding: 0 1rem;
}
._Left_yx1dd_217 ._logo_yx1dd_221 img {
  height: 100%;
}

._Right_yx1dd_229 {
  display: flex;
  align-items: center;
}
._Right_yx1dd_229 .ant-btn {
  margin-right: 0.5rem;
}
._Right_yx1dd_229 .ant-icon {
  font-size: 1.5rem;
}
._Right_yx1dd_229 ._userMenu_yx1dd_239 {
  margin-right: 1.5rem;
  width: 2.75rem;
  line-height: 3.25rem;
  text-align: center;
  cursor: pointer;
}
._Right_yx1dd_229 ._userMenu_yx1dd_239:hover {
  background-color: #f4fcff;
  color: #1890ff;
}
._Right_yx1dd_229 ._userMenu_yx1dd_239._selected_yx1dd_250:not(:hover) {
  background-color: #e6f7ff;
  color: #002766;
}
._Right_yx1dd_229 ._userMenu_yx1dd_239 > span {
  font-size: 14px !important;
}
._Right_yx1dd_229 .ant-dropdown-open {
  background-color: #f4fcff !important;
  color: #1890ff !important;
}

._TopNavMemberSearch_yx1dd_262 > div:first-child {
  height: 3.25rem;
  border-radius: 0 !important;
}
._TopNavMemberSearch_yx1dd_262 > div:first-child:hover {
  background-color: #f4fcff !important;
  color: #1890ff !important;
}