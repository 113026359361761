import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CONTENT_GUIDELINES_BY_PROJECT_QUERY } from '@frontend/app/queries';
import { GetContentGuidelinesByProject, GetContentGuidelinesByProjectVariables } from '@frontend/app/queries/types/GetContentGuidelinesByProject';

type IOptions = QueryHookOptions<GetContentGuidelinesByProject, GetContentGuidelinesByProjectVariables>;

export const useGetContentGuidelineTemplatesForProject = (options: IOptions = {}) => (
  useQuery<GetContentGuidelinesByProject, GetContentGuidelinesByProjectVariables>(
    GET_CONTENT_GUIDELINES_BY_PROJECT_QUERY,
    options,
));
