import * as React from 'react';
import { Form } from 'antd';

import FormSelect from '../../components/FormSelect';
import FormTextInput from '../../components/FormTextInput';
import {
  OnboardingStep,
  OnboardingDispatchActionType,
  ISetupProps,
  ProjectType,
} from '../../types';
import styles from '../styles.scss';

const { useCallback, useState } = React;

const Setup = (props: ISetupProps) => {
  const { state, dispatch } = props;

  const [projectTypeOptions] = useState(() => {
    const getRecommendedSurfix = (projectType) => (projectType === state.data?.preselectedProjectType ? '(recommended)' : '');

    return [
      {
        value: ProjectType.InfluencerCampaign,
        label: `Short Term Influencer Project ${getRecommendedSurfix(ProjectType.InfluencerCampaign)}`,
      },
      {
        value: ProjectType.ProductSeeding,
        label: `Product Gifting Project ${getRecommendedSurfix(ProjectType.ProductSeeding)}`,
      },
      {
        value: ProjectType.AmbassadorProgram,
        label: `Long Term Influencer Project ${getRecommendedSurfix(ProjectType.AmbassadorProgram)}`,
      },
      {
        value: ProjectType.CustomProject,
        label: `Custom Project ${getRecommendedSurfix(ProjectType.CustomProject)}`,
      },
    ];
  });

  const onFieldChange = useCallback((name: string, value: string) => {
    dispatch({
      type: OnboardingDispatchActionType.UpdateFieldValue,
      payload: {
        step: OnboardingStep.Setup,
        field: name,
        value,
      },
    });
  }, [dispatch]);

  return (
    <div className={styles.StepContent}>
      <div className={styles.stepForm}>
        <p>All fields are required unless marked optional.</p>
        <Form layout="vertical">
          <FormSelect
            label="Project Type"
            name="projectType"
            placeholder="Select the type of project you want to create"
            onFieldChange={onFieldChange}
            value={state.data?.projectType}
            isRequired
            options={projectTypeOptions}
          />
          <FormTextInput
            name="title"
            label="Project Title"
            placeholder="Name your project"
            maxLength={30}
            onFieldChange={onFieldChange}
            value={state.data?.title}
            allowSpecialChars={false}
            isRequired
          />
          <FormTextInput
            name="summary"
            label="Project Tagline (this can be edited later)"
            placeholder="A short tagline explaining what your campaign is about"
            maxLength={40}
            onFieldChange={onFieldChange}
            value={state.data?.summary}
            isRequired
          />
          <FormTextInput
            name="description"
            label="Project Brief (this can be edited later)"
            placeholder="What is involved in taking part? E.g. What are the expectations, outline your goals, why should they be excited to take part?"
            maxLength={1500}
            onFieldChange={onFieldChange}
            value={state.data?.description}
            isTextArea
            isRequired
          />
        </Form>
      </div>
      <div className={styles.stepInfo}>
        <h3 className={styles.title}>Project Types Explained</h3>
        <div className={styles.section}>
          <h4>Short Term Influencer Project:</h4>
          {' '}
          Work with creators in exchange for content or promotion of your products.
        </div>
        <div className={styles.section}>
          <h4>Product Gifting Project:</h4>
          {' '}
          Gift your products with no obligation to post, great for getting creators and customers familiar with your brand.
        </div>
        <div className={styles.section}>
          <h4>Long Term Influencer Project:</h4>
          {' '}
          Work with creators over an extended period of time in exchange for content or promotion of your products.
        </div>
        <div className={styles.section}>
          <h4>Custom Project:</h4>
          {' '}
          Create a unique project with your own custom stages depending on your campaign goals.
        </div>
      </div>
    </div>
  );
};

const SetupComponent = React.memo(Setup);

SetupComponent.displayName = 'Introduction';

export default SetupComponent;
