import React from 'react';
import {
  Button,
  Dropdown,
  Modal,
  Tag,
} from '@revfluence/fresh';
import {
  ChevronLeftIcon,
  CircleExclamationIcon,
  CircleInfoIcon,
  EllipsisIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useHistory } from 'react-router-dom';
import { AutomationStatus } from '@frontend/app/types/globalTypes';
import ActionMenu from './ActionMenu';
import styles from './ConfigHeader.scss';

const { confirm } = Modal;

type TProps = {
  automationStatus: AutomationStatus;
  isAutomation: boolean;
  isEditing: boolean;
  isSaving: boolean;
  onEdit: () => void;
  onSave: () => void;
  title: string;
  toggleState: () => void;
  onDisableAutomation: () => void;
  onDeleteAutomation: () => void;
  onResumeAutomation: () => void;
};

const ConfigHeader = (props: TProps) => {
  const {
    automationStatus,
    isAutomation,
    isEditing,
    isSaving,
    onEdit,
    onSave,
    title,
    toggleState,
    onDisableAutomation,
    onDeleteAutomation,
    onResumeAutomation,
  } = props;

  const history = useHistory();

  const saveButtonText = isAutomation ? 'Save' : 'Create';

  const onSaveAutomation = () => {
    if (isAutomation) {
      showConfirmOnSaveAutomation();
    } else {
      onSave();
    }
  };

  const showConfirmOnSaveAutomation = () => {
    if (isAutomation) {
      confirm({
        title: 'Edit Automation',
        content: 'Editing this automation will only affect future triggers. All in-flight triggered actions will continue with the automation settings from the time it was triggered.',
        icon: <CircleExclamationIcon />,
        okText: 'Continue',
        okType: 'primary',
        centered: true,
        onOk() {
          onSave();
        },
      });
    }
  };

  const gotoAutomationListPage = () => {
    history.goBack();
  };

  return (
    <div className={styles.ConfigHeader}>
      <div className={styles.header}>
        <Button
          size="middle"
          onClick={gotoAutomationListPage}
          icon={<ChevronLeftIcon />}
        />
        <div className={styles.title}>
          {title}
        </div>
      </div>
      <div className={styles.headerAction}>
        {isEditing
          ? (<Tag color="processing">Editing</Tag>)
          : (<Tag>Viewing</Tag>)}
        {isAutomation && (
          <>
            <Button
              size="middle"
              onClick={toggleState}
              icon={<CircleInfoIcon />}
            />
            <Dropdown
              overlay={(
                <ActionMenu
                  automationStatus={automationStatus}
                  onDeleteAutomation={onDeleteAutomation}
                  onDisableAutomation={onDisableAutomation}
                  onResumeAutomation={onResumeAutomation}
                />
              )}
              trigger={['click']}
            >
              <Button
                size="middle"
                icon={<EllipsisIcon />}
              />
            </Dropdown>
          </>
        )}
        {isEditing
          ? (
            <Button
              size="middle"
              type="primary"
              loading={isSaving}
              onClick={onSaveAutomation}
            >
              {saveButtonText}
            </Button>
)
          : (<Button size="middle" type="primary" onClick={onEdit}>Edit Automation</Button>)}
      </div>
    </div>
  );
};

export default ConfigHeader;
