import * as React from 'react';

import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import styles from './CustomMenu.scss';
import { IItem, ISubItem } from './types';

interface IProps {
  items: IItem[];
  selectedKeys: string[];
}

const CustomMenu: React.FC<IProps> = (props) => (
  <div className={styles.CustomMenu}>
    <Menu selectedKeys={props.selectedKeys}>
      { props.items.map((item: IItem) => (
        item.subItems.length > 0 ? (
          <Menu.ItemGroup title={item.text} key={item.id}>
            {
              item.subItems.map((subItem: ISubItem) => (
                <Menu.Item key={subItem.id}>
                  <NavLink to={subItem.route}>
                    {subItem.text}
                  </NavLink>
                </Menu.Item>
              ))
            }
          </Menu.ItemGroup>
        ) : null
      ))}
    </Menu>
  </div>
  );

export default React.memo(CustomMenu);
