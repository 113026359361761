import * as React from 'react';
import cx from 'classnames';
import {
  Button,
  ModalSize,
} from '@components';

import { Modal, IModalProps } from '@revfluence/fresh';

import styles from './NoContactModal.scss';

interface IProps extends IModalProps {
  onContinue?();
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const NoContactModal: React.FunctionComponent<IProps> = (props) => {
  const {
    onCancel,
    onContinue,
    className,
    ...restProps
  } = props;

  return (
    <Modal
      className={cx(styles.NoContactModal, className)}
      onCancel={onCancel}
      width={ModalSize.Medium}
      title="You didn't enter any contact info"
      footer={(
        <>
          <Button
            label="Add Contact Info"
            theme="primary"
            className={styles.button}
            onClick={onCancel}
          />
          <Button
            label="Continue without Contact Info"
            theme="light"
            className={styles.button}
            onClick={onContinue}
          />
        </>
      )}
      {...restProps}
    >
      <div>
        We recommend that you at least enter a name along with either an email, phone, address or social handle
      </div>
    </Modal>
  );
};
