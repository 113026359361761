import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import { TermsGuidelinesSettings } from './TermsGuidelinesSettings';

export const TermsAppDashboard: React.FunctionComponent = () => {
  const navSettings = [
    {
      route: 'settings',
      displayName: 'Settings',
      component: TermsGuidelinesSettings,
    },
  ];

  const application = {
    displayName: 'Terms',
    iconUrl: 'https://storage.googleapis.com/aspirex-static-files/terms.svg',
  };

  return (
    <AppDashboardNav
      application={application}
      navLinks={navSettings}
      defaultRoute="settings"
      fixToScreenHeight
    />
  );
};
