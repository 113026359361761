/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._FavoriteButton_1vyt3_79 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
._FavoriteButton_1vyt3_79._isProcessing_1vyt3_84 {
  cursor: wait;
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87 {
  padding: 0;
  width: 100%;
  height: auto;
  min-width: unset;
  position: relative;
  overflow: visible;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87, ._FavoriteButton_1vyt3_79 ._button_1vyt3_87:hover, ._FavoriteButton_1vyt3_79 ._button_1vyt3_87:active {
  background: none;
  border: 0;
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87._disabled_1vyt3_100 {
  opacity: 0.5;
  cursor: wait;
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87._disabled_1vyt3_100 > [class*=mask] {
  display: none;
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87._active_1vyt3_107 {
  animation: _star-pulse_1vyt3_1 0.25s 1 cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87._active_1vyt3_107 ._starBorder_1vyt3_110 {
  opacity: 0;
  color: #3996e0;
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87._active_1vyt3_107 ._star_1vyt3_110 {
  opacity: 1;
  transform: scale(1);
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87 ._starBorder_1vyt3_110 {
  opacity: 1;
  fill: #8f8d91;
  will-change: opacity, fill;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1), fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87 ._starBorder_1vyt3_110:hover {
  fill: #3996e0;
}
._FavoriteButton_1vyt3_79 ._button_1vyt3_87 ._star_1vyt3_110 {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  fill: #3996e0;
  transform: scale(0);
  will-change: opacity, transform;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1), transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes _star-pulse_1vyt3_1 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 {
  display: flex;
  flex-direction: column;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._header_1vyt3_156 {
  padding: 0 0.75rem 0.5rem;
  height: 2.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 0.0625rem #dadcde;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._header_1vyt3_156 ._spinner_1vyt3_167 {
  margin-right: 0.75rem;
  color: #3996e0;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 {
  padding-top: 0.5rem;
  flex: 1;
  overflow-y: overlay;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176 {
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  will-change: color, background-color;
  transition: color 0.1s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176:hover {
  background-color: #eff5f9;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176._disabled_1vyt3_100 {
  pointer-events: none;
  color: #8f8d91;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176._active_1vyt3_107 ._label_1vyt3_193 {
  color: #3996e0;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176 ._label_1vyt3_193 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176 ._actions_1vyt3_201 {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176 ._actions_1vyt3_201 ._item_1vyt3_207 {
  margin-left: 0.25rem;
  color: #8f8d91;
  will-change: color;
  transition: color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._list_1vyt3_171 ._option_1vyt3_176 ._actions_1vyt3_201 ._item_1vyt3_207:hover {
  color: #3996e0;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._createNewList_1vyt3_216 {
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  will-change: color, background-color;
  transition: color 0.1s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._createNewList_1vyt3_216:hover {
  color: #3996e0;
  background-color: #eff5f9;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._createNewList_1vyt3_216._disabled_1vyt3_100 {
  pointer-events: none;
  color: #8f8d91;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._createNewList_1vyt3_216 ._addIconWrapper_1vyt3_235 {
  margin-left: -0.125rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0.0625rem solid #dadcde;
  border-radius: 50%;
  background-color: #fdfdfd;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._createNewList_1vyt3_216 ._addIcon_1vyt3_235 {
  transform: rotate(45deg);
  color: #3996e0;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252 {
  padding: 0 0.5rem;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252._disabled_1vyt3_100 {
  opacity: 0.5;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252._disabled_1vyt3_100 > * {
  pointer-events: none;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252 ._back_1vyt3_263 {
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
  will-change: color, background-color;
  transition: color 0.1s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252 ._back_1vyt3_263:hover {
  color: #3996e0;
  background-color: #eff5f9;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252 ._back_1vyt3_263 ._arrowIcon_1vyt3_283 {
  position: absolute;
  left: 0.25rem;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252 ._editContent_1vyt3_287 {
  margin: 0.75rem 0;
}
._FavoriteListPopover_1vyt3_152 ._content_1vyt3_152 ._editList_1vyt3_252 ._button_1vyt3_87 {
  margin-bottom: 0.25rem;
}

._disabled_1vyt3_100 {
  cursor: wait;
}