import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const EnvelopeIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M19.23,18.38H4.77C3.24,18.38,2,17.14,2,15.61V8.39c0-1.52,1.24-2.77,2.77-2.77h14.47
      c1.52,0,2.77,1.24,2.77,2.77v7.22C22,17.14,20.76,18.38,19.23,18.38z M4.77,6.98c-0.78,0-1.41,0.63-1.41,1.41v7.22
      c0,0.78,0.63,1.41,1.41,1.41h14.47c0.78,0,1.41-0.63,1.41-1.41V8.39c0-0.78-0.63-1.41-1.41-1.41H4.77z"
    />
    <path
      d="M12.2,14.04c-0.14,0-0.29-0.05-0.41-0.14L5.23,8.94C4.93,8.72,4.87,8.29,5.1,7.99
      c0.23-0.3,0.65-0.36,0.95-0.13l6.14,4.65l6.11-4.65c0.3-0.23,0.72-0.17,0.95,0.13c0.23,0.3,0.17,0.72-0.13,0.95l-6.52,4.96
      C12.49,13.99,12.34,14.04,12.2,14.04z"
    />
    <path
      d="M5.64,16.6c-0.25,0-0.5-0.14-0.61-0.38c-0.16-0.34-0.02-0.74,0.32-0.91l3.59-1.73
      c0.34-0.16,0.74-0.02,0.91,0.32c0.16,0.34,0.02,0.74-0.32,0.91l-3.59,1.73C5.84,16.58,5.74,16.6,5.64,16.6z"
    />
    <path
      d="M18.71,16.6c-0.09,0-0.19-0.02-0.28-0.06c-3.62-1.62-3.74-1.78-3.86-1.95c-0.22-0.3-0.16-0.73,0.15-0.95
      c0.26-0.19,0.62-0.17,0.85,0.04c0.3,0.19,1.95,0.96,3.42,1.61c0.34,0.15,0.5,0.55,0.34,0.9C19.22,16.45,18.97,16.6,18.71,16.6z
      M15.67,13.79C15.67,13.79,15.67,13.79,15.67,13.79C15.67,13.79,15.67,13.79,15.67,13.79z M15.56,13.67L15.56,13.67L15.56,13.67z"
    />
  </SvgIcon>
));

EnvelopeIcon.displayName = 'EnvelopeIcon';

export { EnvelopeIcon };
