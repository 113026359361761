import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TableProps } from 'antd';
import { first } from 'lodash';
import ResizeObserver from 'resize-observer-polyfill';

interface IAutoHeight<T> {
  scroll: TableProps<T>['scroll'],
  wrapperRef: (el: HTMLDivElement) => void;
}

export const useTableAutoHeight = <T>(): IAutoHeight<T> => {
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | undefined>();
  const [scroll, setScroll] = useState<TableProps<T>['scroll']>();

  const updateHeightAndScroll = useCallback((el: HTMLElement) => {
    if (!el) {
      return;
    }

    const thead = first(el.getElementsByTagName('thead'));
    if (!thead) {
      return;
    }

    setScroll((current) => {
      const scrollHeight = el.clientHeight - thead.clientHeight;
      if (current && current.y === scrollHeight) {
        return current;
      }
      return {
        y: scrollHeight,
      };
    });
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (wrapperRef) {
        updateHeightAndScroll(wrapperRef);
      }
    });
    if (wrapperRef) {
      updateHeightAndScroll(wrapperRef);
      observer.observe(wrapperRef);
    }
    return () => {
      if (wrapperRef) {
        observer.unobserve(wrapperRef);
      }
    };
  }, [
    updateHeightAndScroll,
    wrapperRef,
  ]);

  return useMemo(() => ({
    scroll,
    wrapperRef: setWrapperRef,
  }), [
    scroll,
  ]);
};
