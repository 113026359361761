import 'css-chunk:src/applications/AffiliatesApp/containers/STASendItems/STASendItems.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_zy8v1_157",
  "justify-content-space-between": "_justify-content-space-between_zy8v1_161",
  "tabular-nums": "_tabular-nums_zy8v1_165",
  "STASendItems": "_STASendItems_zy8v1_178",
  "content": "_content_zy8v1_178",
  "contentTop": "_contentTop_zy8v1_178",
  "headline6": "_headline6_zy8v1_178",
  "contentMain": "_contentMain_zy8v1_188",
  "subtitle1": "_subtitle1_zy8v1_188",
  "list": "_list_zy8v1_248",
  "composer": "_composer_zy8v1_254",
  "hide": "_hide_zy8v1_258",
  "footer": "_footer_zy8v1_261",
  "button": "_button_zy8v1_266",
  "show": "_show_zy8v1_1"
};