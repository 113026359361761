import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
  isEmpty,
  isNumber,
  map,
  size,
  isNull,
} from 'lodash';

import {
  BarChart,
  PieChart,
  TNetworkIdentifier,
} from '@components';

import {
  ICardTabConfig,
  Card,
} from './Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import {
  ageDataToIDataList,
  genderAgeDataFromDemographicsReport,
  genderDataFromDemographicsReport,
} from '../utils';

const { useRef } = React;
import styles from './GenderAgeCard.scss';

interface IData {
  label: string;
  value: number;
  color?: string | string[];
}
interface IAgeData {
  age: string;
  male: number;
  female: number;
}

interface IProps {
  className?: string;
  hideAge?: boolean;
  hideGender?: boolean;
  hideUnknown?: boolean;
  showAgeAsBarChart?: boolean;
}

export const GenderAgeCard: React.FC<IProps> = (props) => {
  const {
    className,
    hideAge,
    hideGender,
    hideUnknown,
    showAgeAsBarChart,
  } = props;
  const {
    hasData,
    network,
    socialAccount,
  } = useSocialProfileContext();
  const genderTabRef = useRef<HTMLDivElement>(null);
  const ageTabRef = useRef<HTMLDivElement>(null);

  const showBoth = !hideAge && !hideGender;

  // Gender data
  const aggGenderData = (
    !isNull(socialAccount)
    ? genderDataFromDemographicsReport(socialAccount.demographics_report)
    : null
  );
  const genderData: IData[] = !isEmpty(aggGenderData)
    ? [
      {
        label: 'Male',
        value: aggGenderData.male,
        color: ['rgba(57, 150, 224, 0.8)', 'rgba(57, 150, 224, 0.4)'],
      },
      {
        label: 'Female',
        value: aggGenderData.female,
        color: ['#FFD9B9', '#FFE9D9'],
      },
    ]
    : [];
  const hasGenderData = size(genderData) > 0;
  if (!hideUnknown && hasGenderData && isNumber(aggGenderData.unknown) && aggGenderData.unknown > 0) {
    genderData.push({
      label: 'Unknown',
      value: aggGenderData.unknown,
      color: ['#CCCCCC', '#DDDDDD'],
    });
  }

  // Age data
  const ageData: IAgeData[] = (
    hasData
    && !isEmpty(socialAccount.demographics_report)
  ) ? genderAgeDataFromDemographicsReport(socialAccount.demographics_report)
  : [];

  const simpleAgeData: IData[] = !isNull(socialAccount)
    ? ageDataToIDataList(socialAccount.demographics_report, true)
    : [];

  const hasAgeData = size(ageData) > 0 || size(simpleAgeData) > 0;

  const renderGenderChart = () => !hideGender && hasGenderData && (
    <GenderChart
      genderData={genderData}
      network={network}
    />
  );
  const renderAgeChart = () => !hideAge && hasAgeData && (
    <AgeChart
      ageData={ageData}
      genderData={genderData.filter((data) => data.label !== 'Unknown')}
      network={network}
      simpleAgeData={simpleAgeData}
      showAsBarChart={showAgeAsBarChart}
    />
  );

  const tabs: ICardTabConfig[] = [
    {
      key: 'GENDER',
      header: 'Audience Gender',
      tabClassName: cx({ [styles.noData]: !hasGenderData }),
      contentRef: genderTabRef,
      content: renderGenderChart(),
    },
    {
      key: 'AGE',
      header: 'Audience Age',
      tabClassName: cx({ [styles.noData]: !hasGenderData || !hasAgeData }),
      contentRef: ageTabRef,
      content: renderAgeChart(),
    },
  ];

  return (
    <Card
      className={cx(
        className,
        styles.GenderAgeCard,
        styles[network],
        {
          // If either is shown, set no data state if necessary
          [styles.noData]: !showBoth && (
            (!hideGender && !hasGenderData)
            || (!hideAge && !hasAgeData)
          ),
        },
      )}
      header={showBoth || !hideGender
        ? `${tabs[0].header}`
        : `${tabs[1].header}`}
      tabConfigs={showBoth ? tabs : null}
    >
      {!showBoth && (renderAgeChart() || renderGenderChart())}
    </Card>
  );
};

GenderAgeCard.displayName = 'GenderAgeCard';

const GenderChart = React.memo<{
  genderData: IData[];
  network: TNetworkIdentifier;
}>((props) => {
  const chartHeight = (() => {
    switch (props.network) {
      case 'youtube': return 230;
      case 'tiktok': return 160;
      default: return 200;
    }
  })();

  return !isEmpty(props.genderData) && (
    <div className={styles.gender}>
      <PieChart
        className={styles.pieChart}
        data={props.genderData}
        innerRadius={0}
        width={250}
        height={chartHeight}
        gradientColors={false}
        showTotal={false}
        sortByValue={false}
        vertical
      />
    </div>
  );
});

GenderChart.displayName = 'GenderChart';

const AgeChart = React.memo<{
  ageData: IAgeData[];
  genderData: IData[];
  network: TNetworkIdentifier;
  showAsBarChart: boolean;
  simpleAgeData: IData[];
}>((props) => {
  const chartSize = (() => {
    switch (props.network) {
      case 'youtube': return 60;
      default: return 40;
    }
  })();

  const renderBarChart = () => props.simpleAgeData && (
    <BarChart
      className={styles.audienceAgeBarChart}
      data={props.simpleAgeData}
      maxRecords={6}
      height={200}
      showRawValue={false}
      disableSort
    />
  );

  const renderTable = () => (
    <table className={styles.ageTable}>
      <thead>
        <tr>
          <th>
            <PieChart
              className={styles.pieChart}
              data={map(props.genderData, (data) => ({
                // Hide female data
                ...data,
                color: data.label === 'Female' ? 'rgba(255, 255, 255, 0)' : data.color,
              }))}
              innerRadius={0}
              width={chartSize}
              height={chartSize}
              gradientColors={false}
              showTotal={false}
              showLegend={false}
              vertical
            />
          </th>
          <th>
            <PieChart
              className={styles.pieChart}
              data={props.genderData}
              innerRadius={0}
              width={chartSize}
              height={chartSize}
              gradientColors={false}
              showTotal={false}
              showLegend={false}
              vertical
            />
          </th>
          <th>
            <PieChart
              className={styles.pieChart}
              data={map(props.genderData, (data) => ({
                // Hide male data
                ...data,
                color: data.label === 'Male' ? 'rgba(255, 255, 255, 0)' : data.color,
              }))}
              innerRadius={0}
              width={chartSize}
              height={chartSize}
              gradientColors={false}
              showTotal={false}
              showLegend={false}
              vertical
            />
          </th>
        </tr>
        <tr>
          <th>MALE</th>
          <th>AGE</th>
          <th>FEMALE</th>
        </tr>
      </thead>
      <tbody>
        {map(props.ageData, (data, i) => (
          <tr key={`age-${i}`}>
            <td>
              <span>
                {/* CSS used to right-align the numbers */}
                {isNumber(data.male) ? numeral(data.male).format('0%') : '-- %'}
              </span>
            </td>
            <td>{data.age.replace('-', ' – ')}</td>
            <td>
              {isNumber(data.female) ? numeral(data.female).format('0%') : '-- %'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className={cx(styles.age, {
      [styles.ageBarChart]: props.showAsBarChart && props.simpleAgeData,
    })}
    >
      {props.showAsBarChart
        ? renderBarChart()
        : renderTable()}
    </div>
  );
});

AgeChart.displayName = 'AgeChart';
