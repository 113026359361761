import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const PieChartIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M11.1429 3.71484C6.11429 3.71484 2 7.82913 2 12.8577C2 17.8863 6.11429 22.0006 11.1429 22.0006C16.1714 22.0006 20.2857 17.8863 20.2857 12.8577H11.1429V3.71484ZM18.5714 14.4577C17.8286 17.8863 14.8 20.4577 11.1429 20.4577C6.97143 20.4006 3.6 17.0291 3.6 12.8577C3.6 9.20056 6.17143 6.17199 9.6 5.42913V12.8577V14.4577H11.1429H18.5714Z" />
    <path d="M12.8569 2V11.1429C12.8569 11.1429 19.7141 11.1429 21.9998 11.1429C21.9998 6.11429 17.8855 2 12.8569 2ZM14.4569 3.71429C17.3712 4.34286 19.6569 6.62857 20.2855 9.54286H14.4569V3.71429Z" />
  </SvgIcon>
));

PieChartIcon.displayName = 'PieChartIcon';
