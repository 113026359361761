import { Input } from '@revfluence/fresh';
import * as React from 'react';
import { socialLinkFields } from '@frontend/app/components/ApplicationPageTemplates/utils/socialLinks';
import { useRenderSocialLinkIcon } from '../../../hooks';
import { TTemplateProps } from '../../../types';
import styles from './BrandSocialLinks.scss';

type TIntroProps = TTemplateProps['intro'];

interface IProps {
  introProps?: TIntroProps;
  handleChange?(values: Partial<TIntroProps>);
  description?: string
}

export const BrandSocialLinks: React.FC<IProps> = (props) => {
  const {
    introProps,
    handleChange,
    description = "Share your brand's social account to attract the type of creators you want to collaborate with by showcasing what your brand is about.",
  } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange({ [e.target.name]: e.target.value });
  };

  const renderIcon = useRenderSocialLinkIcon();

  return (
    <div className={styles.BrandSocialLinks}>
      <div>
        Social Accounts
      </div>
      <div className={styles.description}>
        {description}
      </div>
      <div className={styles.links_input}>
        {socialLinkFields.map((field) => (
          <Input
            key={field.name}
            className={styles.link}
            placeholder={field.placeholder}
            value={introProps && introProps[field.name]}
            name={field.name}
            onChange={onChange}
            addonBefore={renderIcon(field.name, 18, 'black')}
          />
        ))}
      </div>
    </div>
  );
};
