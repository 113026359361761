import { useMemo } from 'react';
import {
 keyBy, find, map, some, groupBy,
} from 'lodash';

import {
  useGetResources,
} from '@frontend/app/hooks';
import { sourcingGroups as allSourcingGroups } from '@frontend/app/constants/sourcingGroups';
import { ResourceType } from '@frontend/app/types/globalTypes';

import {
  SHOPIFY_APP_ID,
  WOO_COMMERCE_APP_ID,
} from '@frontend/app/constants/applicationIds';

const sourcingGroupById = keyBy(allSourcingGroups, (group) => group.id);

const RESOURCE_TYPE_BY_APP_ID = Object.freeze({
  [SHOPIFY_APP_ID]: ResourceType.SHOPIFY,
  [WOO_COMMERCE_APP_ID]: ResourceType.WOOCOMMERCE,
});

interface IOptions {
  skip?: boolean;
}

export const useIsSourceGroupEnabled = (sourcingGroupIds: string[], options: IOptions = {}) => {
  const sourcingGroups = useMemo(() => map(sourcingGroupIds, (id) => sourcingGroupById[id]), [sourcingGroupIds]);

  const appIds = useMemo(() => map(sourcingGroups, (group) => group?.appId), [sourcingGroups]);

  const skipLoadingResources = useMemo(() => !some(appIds, (appId) => !!RESOURCE_TYPE_BY_APP_ID[appId]), [appIds]);

  const {
    skip = false,
  } = options;

  const {
    resources,
    loading: loadingResources,
  } = useGetResources({
    variables: {
      ignoreRequestContextUserId: true,
    },
    skip: skipLoadingResources || skip,
  });

  const isEnabled = useMemo(() => {
    if (skip) {
      return;
    }

    if (skipLoadingResources) {
      // none of the sourcing groups have associated resources, so consider them enabled.
      return map(appIds, () => true);
    } else if (!resources) {
      return;
    }

    const resourcesByType = groupBy(resources, (res) => res.type);

    return map(appIds, (appId) => {
      const type = RESOURCE_TYPE_BY_APP_ID[appId];
      if (!type) {
        // no resource for this app id, consider it enabled.
        return true;
      }
      const typeResources = resourcesByType[type];
      return !!find(typeResources, (res) => !res.authProvider.userRevokedAccess);
    });
  }, [
    resources,
    appIds,
    skipLoadingResources,
    skip,
  ]);

  return {
    enabled: isEnabled,
    loading: loadingResources,
  };
};
