import _ from 'lodash';

function getDarkHexCodeFromString(inputString) {
    if (typeof inputString !== 'string') {
        throw new Error('Input must be a string');
    }

    const darkColors = [
        '#FF5733', // Coral
        '#3498DB', // Dodger Blue
        '#2ECC71', // Emerald
        '#E74C3C', // Alizarin Crimson
        '#9B59B6', // Amethyst
        '#F39C12', // Orange
        '#1ABC9C', // Turquoise
        '#E67E22', // Carrot
        '#34495E', // Wet Asphalt
        '#FFC0CB', // Pink
        '#8E44AD', // Wisteria
        '#D35400', // Pumpkin
        '#2980B9', // Belize Hole
        '#C0392B', // Pomegranate
        '#8E44AD', // Wisteria
        '#27AE60', // Nephritis
        '#884EA0', // Studio
        '#9B59B6', // Amethyst
        '#1F618D', // Midnight Blue
        '#F39C12', // Orange
    ];

    const hashCode = _.reduce(inputString, (acc, char) => (acc << 5) - acc + char.charCodeAt(0), 0);

    const index = Math.abs(hashCode % darkColors.length);
    const selectedColor = darkColors[index];

    return selectedColor;
}

export default getDarkHexCodeFromString;
