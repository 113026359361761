/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_wdtxg_157 {
  display: flex;
}

._justify-content-space-between_wdtxg_161 {
  justify-content: space-between;
}

._tabular-nums_wdtxg_165 {
  font-variant-numeric: tabular-nums;
}

._ContentUploader_wdtxg_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_wdtxg_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._ContentUploader_wdtxg_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._ContentUploader_wdtxg_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._ContentUploader_wdtxg_169 ::-webkit-scrollbar-track, ._ContentUploader_wdtxg_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._ContentUploader_wdtxg_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ContentUploader_wdtxg_169 {
  line-height: 14px;
}
._ContentUploader_wdtxg_169 ._errorNotice_wdtxg_238 {
  margin-left: 22px;
  margin-right: 10px;
  line-height: 20px;
}
._ContentUploader_wdtxg_169 ._errorNotice_wdtxg_238 ._title_wdtxg_243 {
  margin-bottom: 10px;
  font-weight: bold;
}
._ContentUploader_wdtxg_169 ._errorNotice_wdtxg_238 ._text_wdtxg_247 {
  font-weight: normal;
}
._ContentUploader_wdtxg_169 ._errorNotice_wdtxg_238 ._text_wdtxg_247 a {
  margin-left: 3px;
  text-decoration: none;
  color: #3996e0;
}
._ContentUploader_wdtxg_169 ._dragContainer_wdtxg_255 {
  margin: 10px;
  padding: 0 0 12px 12px;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}
._ContentUploader_wdtxg_169 ._dragContainer_wdtxg_255._dragging_wdtxg_264 {
  transform: translateY(-4px);
  box-shadow: rgba(2, 132, 255, 0.4) 0px 1px 8px, rgba(2, 132, 255, 0.48) 0px 3px 14px;
}
._ContentUploader_wdtxg_169 ._dragContainer_wdtxg_255 ._FileSelector_wdtxg_268 {
  margin-top: 12px;
}
._ContentUploader_wdtxg_169 ._contentContainer_wdtxg_271 {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
._ContentUploader_wdtxg_169 ._contentContainer_wdtxg_271 ._contentWrapper_wdtxg_277 {
  position: relative;
  padding: 12px 12px 0 0;
}
._ContentUploader_wdtxg_169 ._contentContainer_wdtxg_271 ._contentWrapper_wdtxg_277 ._Content_wdtxg_169 {
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 6px;
}
._ContentUploader_wdtxg_169 ._contentContainer_wdtxg_271 ._contentWrapper_wdtxg_277 ._remove_wdtxg_287 {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  pointer-events: auto;
  color: #c9c9c9;
  background-color: white;
  border: solid 1px #e2e2e2;
  border-radius: 20px;
  transform: rotate(45deg);
}
._ContentUploader_wdtxg_169 ._contentContainer_wdtxg_271 ._contentWrapper_wdtxg_277 ._remove_wdtxg_287:hover {
  color: #999;
}
._ContentUploader_wdtxg_169 .fade-exit {
  opacity: 1;
}
._ContentUploader_wdtxg_169 .fade-exit-active {
  opacity: 0.01;
  transition: opacity 0.3s ease-in;
}