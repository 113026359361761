import { createElement, useCallback, useMemo } from 'react';
import { isFunction } from 'lodash';
import { useHistory } from 'react-router-dom';

type TClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

interface IArgs {
  href?: string;
  handler: TClickHandler | undefined;
  useAnchor?: boolean;
  openInNewTab?: boolean;
  title: string;
  value: React.ReactNode;
}

interface IReturnValue {
  cellValue: React.ReactNode;
  onClick?: TClickHandler;
}

export const useLinkableCell = ({
  href,
  handler,
  useAnchor,
  openInNewTab,
  title,
  value,
}: Readonly<IArgs>): Readonly<IReturnValue> => {
  const history = useHistory();
  const defaultClickHandler = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (href) {
      if (openInNewTab) {
        window.open(href, '_blank');
      } else {
        history.push(href);
      }
    } else if (isFunction(handler)) {
      handler(event);
    }
  }, [handler, history, href, openInNewTab]);
  const cellValue = useMemo(() => {
    if (!useAnchor) {
      return value;
    }
    const anchorProps = {
      href: href || value,
      rel: 'noreferrer',
      target: '_blank',
      title,
    };
    if (!openInNewTab) {
      delete anchorProps.target;
      delete anchorProps.rel;
    }
    return createElement('a', anchorProps, value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href, openInNewTab, useAnchor, value]);
  return {
    cellValue,
    onClick: useAnchor ? undefined : defaultClickHandler,
  };
};
