import {
  assignIn,
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  has,
  isEmpty,
  isNil,
  map,
  maxBy,
  uniqBy,
  toLower,
} from 'lodash';

import { IProspect, ISocialAccount } from '@components';
import { IMemberProgramMap, TProspectProgramStatus } from '@components';
import { isMemberProgram, IMemberProgram, TProgram } from '@components';

import {
  GetCommunitiesQuery_communities as ICommunity,
} from '@frontend/app/queries/types/GetCommunitiesQuery';

import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';

// Copied from aspirex/services/members/types/MemberFields.ts, used to allow creator to be invited via IGDM
// if they have this field enabled
const MEMBER_FIELD_CREATOR_MARKETPLACE = 'Instagram Creator Marketplace';
/**
 * Get program status
 * @param program
 */
export const getProgramStatus = (program: TProgram): IMemberProgram['status'] => (
  isMemberProgram(program) ? program.status : null
);

export const networkMemberFieldName = {
  instagram: 'Instagram',
  youtube: 'YouTube',
  pinterest: 'Pinterest',
  tiktok: 'TikTok',
  twitter: 'Twitter',
};

/**
 * Extract all socialAccount objects from a list of prospects
 * @param prospects
 */
export const getSocialAccountsFromProspects = (...prospects: IProspect[]): IProspect['socialAccount'][] => (
  !isEmpty(prospects)
    ? map(
      filter(prospects, (p) => !isEmpty(p)),
      (p) => p.socialAccount,
    )
    : []
);

/**
 * Check if the social account belongs to the provided program ID
 * @param socialAccount
 * @param programId
 * @param memberPrograms
 */
export const getProgramStatusOfAccount = (
  socialAccount: IProspect['socialAccount'],
  programId: TProgram['id'],
  memberPrograms: IMemberProgramMap,
): TProspectProgramStatus => {
  if (isEmpty(socialAccount) || isEmpty(memberPrograms)) {
    return null;
  } else if (!has(memberPrograms, socialAccount.id)) {
    return null;
  }
  const program = find(
    get(memberPrograms, socialAccount.id),
    (program) => program.id === programId,
  );
  return !isEmpty(program) ? program.status : null;
};

/**
 * Simpler version of `getProgramStatusOfAccount` but only checks if a status is available or not
 */
export const doesAccountBelongToProgram = (
  socialAccount: IProspect['socialAccount'],
  programId: TProgram['id'],
  memberPrograms: IMemberProgramMap,
): boolean => (
  !isNil(getProgramStatusOfAccount(socialAccount, programId, memberPrograms))
);

/**
 * Get most recently created program and return its id
 */
export const getRecentlyCreatedProgramId = (programs: readonly IProject[]): IProject['id'] => {
  const program = maxBy(programs, (program) => program.id);
  return program ? program.id : null;
};

/**
 * Consolidated list of communities with respective programs
 * - map programs to communities
 */
export const mapProgramsToCommunities = (
  communities: readonly ICommunity[],
  programs: readonly IProject[],
): readonly ICommunity[] => Object.freeze(
  map(communities, (c) => ({
    ...c,
    programs: filter(programs, (p) => p.communityId === c.id),
  })),
);

/**
 * Consolidated list of communities with respective programs and statuses
 * - map prospect's programs to programs;
 * - map programs to communities
 */
export const getCommunitiesAndProgramsOfProspect = (
  communities: readonly ICommunity[],
  memberPrograms: Readonly<IMemberProgramMap>,
  programs: readonly IProject[],
  prospect: IProspect,
): readonly ICommunity[] => {
  if (isEmpty(communities)) {
    return [];
  }

  // local list of programs for this prospect
  const thisProspectsPrograms = cloneDeep(programs);
  const socialAccountId = prospect && prospect.socialAccount ? prospect.socialAccount.id : null;
  forEach(
    get(memberPrograms, socialAccountId),
    (program) => {
      assignIn(
        find((thisProspectsPrograms), (p) => p.id === program.id),
        program,
      );
    },
  );

  return Object.freeze(
    map(communities, (c) => ({
      ...cloneDeep(c),
      programs: filter(thisProspectsPrograms, (p) => p.communityId === c.id),
    })),
  );
};

/**
 * Remove duplicate programs
 */
export const uniqueMemberPrograms = (...programs: IMemberProgram[]): IMemberProgram[] => (
  uniqBy(programs, 'id')
);

export const getProspectFromSocialAccount = (account: ISocialAccount, updatedEmail?: string) => {
  let uid = account.username;
  let username = account.username;
  const network = toLower(account.network_identifier);
  if (!username && network == 'youtube') {
    uid = account.network_id || account.youtube_id;
    username = account.link.replace('http:', 'https:');
  }

  return {
    name: account.full_display_name,
    email: account.email || updatedEmail || '',
    fields: {
      [networkMemberFieldName[network] || network]: username,
      [MEMBER_FIELD_CREATOR_MARKETPLACE]: account?.is_in_instagram_marketplace || false,
    },
    __uid: uid,
  };
};
