import * as React from 'react';
import cx from 'classnames';
import { map, split, first } from 'lodash';

import {
  BarChart,
  BarGroup,
  countries,
  GraphContainer,
  LoadSpinner,
  Notice,
} from '@components';

import { useAnalyzeCompare } from '../../../useAnalyzeCompare';

const { useState, useMemo } = React;
import styles from './AudienceLocationSection.scss';

export interface IAudienceLocationData {
  city: Array<{
    grouping: string;
    count: number;
  }>;
  country: Array<{
    grouping: string;
    count: number;
  }>;
}
type TDisplayMode = 'city' | 'country';
export interface IAudienceLocationSectionProps {
  className?: string;
  data: { loading: boolean, data?: IAudienceLocationData, error: Error | null };
  dataCompare: { loading: boolean, data?: IAudienceLocationData, error: Error | null };
  isComparing: boolean;
}

export const AudienceLocationSection: React.FunctionComponent<IAudienceLocationSectionProps> = React.memo(
  (props) => {
    const { loading, data, error } = props.data;
    const { loading: loadingCompare, data: dataCompare } = props.dataCompare;
    const { isComparing } = useAnalyzeCompare();

    const [displayMode, setDisplayMode] = useState<TDisplayMode>('country');
    const records = useMemo(
      () =>
        map(data && data[displayMode], (record) => ({
          value: record.count,
          label:
            displayMode === 'city'
              ? first(split(record.grouping, ','))
              : countries[record.grouping],
        })),
      [data, displayMode],
    );
    const recordsCompare = useMemo(
      () =>
        map(dataCompare && dataCompare[displayMode], (record) => ({
          value: record.count,
          label:
            displayMode === 'city'
              ? first(split(record.grouping, ','))
              : countries[record.grouping],
        })),
      [dataCompare, displayMode],
    );
    const onDisplayModeSelect = (displayMode) => setDisplayMode(displayMode);

    if (error) {
      return (
        <Notice className={(styles as any).notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    /* eslint-disable @typescript-eslint/indent */
    const barGroupRecords = records && dataCompare && isComparing
      ? records.map((item) => ({
        ...item,
        value2: recordsCompare.find((el) => el.label === item.label)
          ? recordsCompare.find((el) => el.label === item.label).value
          : 0,
      }))
      : null;
    /* eslint-enable */

    return (
      <div className={cx(styles.AudienceLocationSection, props.className)}>
      {(loading || loadingCompare) ? <LoadSpinner centered /> : (
          <ComponentUI
            displayMode={displayMode}
            onDisplayModeSelect={onDisplayModeSelect}
            isComparing={isComparing}
            records={records}
            barGroupRecords={barGroupRecords}
          />
        )}
      </div>
    );
  },
);

AudienceLocationSection.defaultProps = {
  className: null,
};

export const ComponentUI = ({
  displayMode,
  onDisplayModeSelect,
  isComparing,
  records,
  barGroupRecords,
}) => (
  <div className={styles.content}>
    <div className={styles.header}>
      <div className={styles.title}>
        <h3>Audience Location</h3>
      </div>
      <div className={styles.select}>
        <div
          className={cx(styles.item, {
            [styles.active]: displayMode === 'city',
          })}
          onClick={() => onDisplayModeSelect('city')}
        >
          City
        </div>
        <div
          className={cx(styles.item, {
            [styles.active]: displayMode === 'country',
          })}
          onClick={() => onDisplayModeSelect('country')}
        >
          Country
        </div>
      </div>
    </div>
    <div className={styles.main}>
      {isComparing ? (
        <GraphContainer
          fixedHeight={380}
          children={<BarGroup data={barGroupRecords} />} // eslint-disable-line
        />
      ) : (
        <BarChart data={records} />
      )}
    </div>
  </div>
);
