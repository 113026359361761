import {
  useMemo,
  useReducer,
} from 'react';

import {
  IState,
  TDispatch,
} from '../../types/state';
import { getInitialState } from './getInitialState';
import { reducer } from './reducer';

interface IUseState {
  dispatch: TDispatch;
  state: IState;
}

export const useState = (): IUseState => {
  const initialState = useMemo(getInitialState, []);
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    dispatch,
    state,
  };
};
