/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_nvns3_157 {
  display: flex;
}

._justify-content-space-between_nvns3_161 {
  justify-content: space-between;
}

._tabular-nums_nvns3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_nvns3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._conversionHeaderContainer_nvns3_178 {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

._cellItemContainer_nvns3_185 {
  display: flex;
  align-items: center;
  justify-content: center;
}

._conversionHeaderTitle_nvns3_191 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

> div {
  height: 100%;
  max-height: 100% !important;
}

._conversionHeaderdesc_nvns3_204 {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 1rem;
}

._tableCount_nvns3_210 {
  margin-bottom: 0.5rem;
}