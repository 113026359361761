import * as React from 'react';
import {
  filter,
  isArray,
  isUndefined,
} from 'lodash';
import { format, parseISO } from 'date-fns';
import { IStatisticsCardData, StatsCardPromosComponent } from '@affiliates/components';
import { OFFER_PRICE_RULE_TYPE } from '@affiliates/types/globalTypes';
import { DataFormat } from '@affiliates/utils';
import {
  TActiveDates,
  IAddPromoCodeMembers,
  TSelectedMember,
  TMode,
} from '../types';

import styles from './MembersWizard.scss';

interface IProps {
  activeDates?: Readonly<TActiveDates>;
  currentActiveDatesLabel?: string;
  discountAmount: IAddPromoCodeMembers['discountAmount'];
  discountType: IAddPromoCodeMembers['discountType'];
  mode: TMode;
  members: readonly TSelectedMember[] | null;
  offerName: string;
  isNewFlow?: boolean;
  payoutType?: string;
  startDate?: string;
  endDate?: string;
  percentPayout?: number;
  flatPayout?: number;
  offerStartDate?: string;
  offerEndDate?: string;
}

export const StatsCardPromos: React.FC<Readonly<IProps>> = (props) => {
  const {
    activeDates,
    currentActiveDatesLabel,
    members,
    mode,
    offerName,
  } = props;
  const stats: IStatisticsCardData[] = [];
  const prefix = props.discountType === OFFER_PRICE_RULE_TYPE.PERCENTAGE ? '' : '$';
  const suffix = props.discountType === OFFER_PRICE_RULE_TYPE.PERCENTAGE ? '% Off' : ' Off';
  stats.push(
    {
      format: DataFormat.None,
      title: 'Offer',
      value: offerName,
    },
  );
  stats.push(
    {
      format: DataFormat.None,
      prefix,
      suffix,
      title: !props.isNewFlow ? 'Promo Code Type' : 'Discount',
      value: props.discountAmount.toLocaleString(),
    },
  );
  const newMemberCount = isArray(members) ? filter(members, (m) => m.selected).length : 0;
  let memberTitle = newMemberCount === 1 ? 'Code to Add' : 'Codes to Add';
  switch (mode) {
    case 'refresh':
      memberTitle = newMemberCount === 1 ? 'Member Getting New Code' : 'Members Getting New Codes';
      break;
    case 'refreshDates':
      memberTitle = newMemberCount === 1 ? 'Member Getting New Date' : 'Members Getting New Dates';
      break;
    case 'deactivate':
      memberTitle = newMemberCount === 1 ? 'Code to Deactivate' : 'Codes to Deactivate';
      break;
  }
  stats.push({
    format: DataFormat.Integer,
    title: memberTitle,
    value: newMemberCount,
  });

  if (mode === 'refreshDates') {
    stats.push({
      format: DataFormat.None,
      title: 'Current Active Dates',
      value: currentActiveDatesLabel,
    });
  }
  const activeDatesTitle = mode === 'refreshDates' ? 'New Active Dates' : 'Active Dates';
  if (!isUndefined(activeDates) && !props.isNewFlow) {
    if (isUndefined(activeDates.startDate) && isUndefined(activeDates.endDate)) {
      stats.push({
        format: DataFormat.None,
        title: activeDatesTitle,
        value: 'TBD',
      });
    } else {
      stats.push({
        format: DataFormat.None,
        title: activeDatesTitle,
        value: `${activeDates.startDate || 'TBD'} - ${activeDates.endDate || 'TBD'}`,
      });
    }
  } else if (props.startDate) {
    stats.push({
      format: DataFormat.None,
      title: activeDatesTitle,
      value: `${format(parseISO(props.startDate), 'MMM d, yyyy')}${props.endDate ? ` - ${format(parseISO(props.endDate), 'MMM d, yyyy')}` : ''}`,
    });
  } else if (props.isNewFlow) {
    stats.push({
      format: DataFormat.None,
      title: activeDatesTitle,
      value: `${format(parseISO(props.offerStartDate), 'MMM d, yyyy')}${props.offerEndDate ? ` - ${format(parseISO(props.offerEndDate), 'MMM d, yyyy')}` : ''}`,
    });
  }
  return (
    <StatsCardPromosComponent className={styles.statsCard} data={stats} />
  );
};
