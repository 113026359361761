import { isUndefined } from 'lodash';
import moment from 'moment';
import { TActiveDates } from '../types';

export const validateStartAndTimeRange = (activeDates: TActiveDates) => {
  const startTimes = moment(activeDates.startTime, ['h:mm A']).format('HH:mm');
  const endTimes = moment(activeDates.endTime, ['h:mm A']).format('HH:mm');

  const datesAreEqual = activeDates.startDate === activeDates.endDate;
  const startTimeIsGreater = startTimes >= endTimes;
  const startAndEndDateHaveValue = !isUndefined(activeDates.startDate) && !isUndefined(activeDates.startDate);
  const invalidTimeRange = (datesAreEqual && startTimeIsGreater) && (startAndEndDateHaveValue);

  const startDateValue = activeDates.startDate;
  const endDateValue = activeDates.endDate;
  const invalidStartRange = moment(startDateValue).isAfter(endDateValue);
  return {
    isInValidTimeRange: invalidTimeRange,
    isInvalidStartRange: invalidStartRange,
  };
};
