import gql from 'graphql-tag';

import { AUTOMATION_BLUEPRINT_FRAGMENT } from './Automation';

export const AUTOMATION_REVISION_FRAGMENT = gql`
  fragment AutomationRevisionFragment on AutomationRevision {
    id
    automationId
    revision
    blueprint {
      ...AutomationBlueprintFragment
    }
    metadata
    createdDate
    updatedDate
  }
  ${AUTOMATION_BLUEPRINT_FRAGMENT}
`;
