import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import { findIndex } from 'lodash';
import { IDuplicateContentGuidelineAction, IState } from '../../../types/state';

export const duplicateContentGuideline = (state: IState, action: IDuplicateContentGuidelineAction): IState => {
  const {
    contentGuideline: {
      label,
      networkType,
      type,
    },
  } = action;
  const contentGuidelineIndex = findIndex(
    state.contentGuidelines,
    (
      contentGuideline: TContentGuideline,
    ) => contentGuideline?.type === type
    && contentGuideline?.label === label
    && contentGuideline?.networkType === networkType,
  );
  if (contentGuidelineIndex === -1) {
    return {
      ...state,
      contentGuidelines: [
        ...state.contentGuidelines,
        {
          ...action.contentGuideline,
        },
      ],
      isEditMode: false,
      defaultActiveKey: action.contentGuideline?.id,
    };
  }
  return {
    ...state,
    contentGuidelines: [
      ...state.contentGuidelines.slice(0, contentGuidelineIndex),
      {
        ...action.contentGuideline,
      },
      ...state.contentGuidelines.slice(contentGuidelineIndex),
    ],
    isEditMode: false,
    defaultActiveKey: action.contentGuideline.id,
  };
};
