import * as React from 'react';
import {
  Form,
  TimePicker,
} from '@revfluence/fresh';
import { Moment } from 'moment';
import { IShopifyFormElementProps } from '../../../types';

interface IProps extends IShopifyFormElementProps<'endTime'> {
  startDate: Moment;
  startTime: Moment;
  endDate: Moment;
}

export const OfferEndTime: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  startDate,
  startTime,
  endDate,
}) => {
  const rules = [
    { required: true, message: 'Please Select Time' },
    {
      asyncValidator: async (_, value) => {
        if (startDate.isSame(endDate, 'day') && (startTime.isSame(value, 'minute') || startTime.isAfter(value, 'minute'))) {
          return Promise.reject(new Error('End Date time must be After start Date Time '));
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <>
      <Form.Item name={name} rules={rules}>
        <TimePicker size="large" disabled={disabled} format="hh:mm a" />
      </Form.Item>
    </>
  );
});
OfferEndTime.displayName = 'OfferEndTime';
