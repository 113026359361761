import * as React from 'react';
import cx from 'classnames';
import {
  find, isEmpty, size, filter, isUndefined,
} from 'lodash';

import { Button } from '@revfluence/fresh';

import {
  IModalProps, Modal, ModalSize,
} from '@components';
import { logger } from '@common';
import {
  SelectList,
  FormActions,
  EllipsisLabel,
} from '@frontend/app/components';
import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { useFeatureFlagVerbiage } from '@frontend/app/hooks';
import { useMemberPageContext } from '@frontend/app/containers/Members/hooks';
import { useResourceContext } from '@frontend/app/context/useResourceContext';
import { useAuth } from '@frontend/context/authContext';
import {
  GetAllPrograms_programs as IProject,
} from '@frontend/app/queries/types/GetAllPrograms';
import { useMessagingContext } from '@frontend/hooks';
import {
  useFuzzySearchByKeys,
  useInviteMembersToProgramsMutation,
  useProgramsQuery,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';

const { useEffect, useMemo, useState } = React;

import { ClientFeature } from '@frontend/app/constants';
import styles from './InviteToProgramModal.scss';

interface IProps extends IModalProps {
  memberIds: number[];
  memberCount: number;
}

export const InviteToProgramModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>();
  const { user } = useAuth();
  const { activeEmailResources: resources } = useResourceContext();
  const [firstResource] = resources;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { searchQuery } = useMemberPageContext() as any || {};
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);

  const verbiage = useFeatureFlagVerbiage();

  const {
    memberIds = [],
    memberCount,
    ...modalProps
  } = props;
  const count = size(memberIds) || memberCount;

  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();

  const {
    loading: isProjectsLoading,
    data: {
      programs: projects = [],
    } = {},
  } = useProgramsQuery({ fetchPolicy: 'no-cache' });

  const activeProjects = useMemo(() => {
    if (isUndefined(isArchiveProjectEnabled)) return [];

    let active = projects;
    if (isArchiveProjectEnabled) {
      active = filter(
        projects,
        (project) => project.status === ProjectStatus.Active,
      );
    }

    return filter(
      active,
      (project: IProject) => project.published,
    );
  }, [isArchiveProjectEnabled, projects]);

  const handleSearch = useFuzzySearchByKeys(activeProjects, ['title']);

  const mapOptionToLabel = (project: IProject) => (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div className={(styles as any).label}>
      <EllipsisLabel tooltipPlacement="right">
        {project.title}
      </EllipsisLabel>
    </div>
  );

  const {
    inviteMembersToPrograms,
    loading: isInviting,
    error,
  } = useInviteMembersToProgramsMutation();

  const handleClick = () => {
    if (isEmpty(selectedProjectIds)) {
      return;
    }

    inviteMembersToPrograms({
      variables: {
        memberIds,
        programIds: selectedProjectIds,
        userId: user.sub,
        resourceId: firstResource?.id,
        query: searchQuery,
        source: 'member_list',
      },
    })
      .then(() => {
        const programsString = selectedProjectIds.length === 1
          ? find(activeProjects, (p) => p.id === selectedProjectIds[0])?.title
          : `${selectedProjectIds.length} ${verbiage.programs}`;
        showSuccessMessage(`Invited ${count} members to ${programsString}`);

        modalProps.onRequestClose();
      }).catch(logger.error);
  };

  useEffect(() => {
    if (error) {
      showError(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const disabled = isProjectsLoading || isInviting;

  const primaryAction = {
    button: (
      <Button
        type="primary"
        size="middle"
        onClick={handleClick}
        disabled={disabled || isEmpty(selectedProjectIds)}
        className={styles.primaryAction}
      >
        Invite
      </Button>
    ),
  };

  return (
    <Modal
      width={ModalSize.Small}
      dialogClassName={cx(styles.modalDialog, modalProps.className)}
      onRequestClose={modalProps.onRequestClose}
      {...modalProps}
    >
      <FormActions
        title={`Invite to ${verbiage.Programs}`}
        subtitle={`Selected ${count} member${count > 1 ? 's' : ''}`}
        primaryAction={primaryAction}
        tertiaryAction={{
          label: 'Cancel',
          onClick: modalProps.onRequestClose,
        }}
      >
        <SelectList
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          className={(styles as any).selectList}
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          contentClassName={(styles as any).content}
          searchPlaceholder={`Search ${verbiage.programs}...`}
          showSearch
          onSearchRequest={handleSearch}
          options={activeProjects}
          mapOptionToId={(project: IProject) => project.id}
          mapOptionToLabel={mapOptionToLabel}
          disabled={disabled}
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          onChange={setSelectedProjectIds as any}
          isLoading={isProjectsLoading}
          emptyMessage={`There are no available ${verbiage.programs} to invite to`}
        />
      </FormActions>
    </Modal>
  );
});
