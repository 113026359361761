/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1uflc_157 {
  display: flex;
}

._justify-content-space-between_1uflc_161 {
  justify-content: space-between;
}

._tabular-nums_1uflc_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1uflc_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._archiveFiscalYear_1uflc_178 {
  color: #8C8C8C;
  font-size: 0.875rem;
  font-weight: 400;
}

._subMenu_1uflc_184 {
  width: 5rem;
}
._subMenu_1uflc_184 .ant-dropdown-menu-submenu-title {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}