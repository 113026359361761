import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  PROSPECTS_INVITE_TO_PROGRAM_MUTATION,
} from '@frontend/app/queries';
import {
  ProspectsInviteToProgramMutation,
  ProspectsInviteToProgramMutationVariables,
} from '@frontend/app/queries/types/ProspectsInviteToProgramMutation';

type IOptions = MutationHookOptions<ProspectsInviteToProgramMutation, ProspectsInviteToProgramMutationVariables>;

export const useProspectsInviteToProgram = (options: IOptions = {}) => useMutation<ProspectsInviteToProgramMutation, ProspectsInviteToProgramMutationVariables>(PROSPECTS_INVITE_TO_PROGRAM_MUTATION, options);
