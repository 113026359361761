import * as React from 'react';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import ShopifyAppDashboard from './ShopifyAppDashboard';

const { useEffect } = React;

interface IProps {
  clientId: string;
  clientName: string;
}

const ShopifyApp: React.FunctionComponent<IProps> = (props) => {
  const addEvent = useEventContext();
  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'shopify' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={props.clientId}
      clientName={props.clientName}
    >
      <ShopifyAppDashboard />
    </ApplicationProvider>
  );
};

export default ShopifyApp;
