._FilterFormPopover_nkkx4_1 {
  width: 280px;
}
._FilterFormPopover_nkkx4_1 .ant-popover-title {
  padding-top: var(--spacing-sm);
  padding-bottom: 0;
  border: 0;
  text-align: center;
}
._FilterFormPopover_nkkx4_1 .ant-popover-inner-content {
  padding: var(--spacing-md);
}

._FilterForm_nkkx4_1 ._select_nkkx4_14 {
  width: 100%;
  margin-bottom: var(--spacing-md);
}
._FilterForm_nkkx4_1 ._select_nkkx4_14:hover, ._FilterForm_nkkx4_1 ._select_nkkx4_14:focus {
  border-color: var(--primary-10);
}
._FilterForm_nkkx4_1 ._select_nkkx4_14:hover .ant-select-selection-item, ._FilterForm_nkkx4_1 ._select_nkkx4_14:focus .ant-select-selection-item {
  color: var(--primary-40) !important;
}
._FilterForm_nkkx4_1 ._select_nkkx4_14:hover .ant-select-arrow, ._FilterForm_nkkx4_1 ._select_nkkx4_14:focus .ant-select-arrow {
  color: var(--primary-40) !important;
}
._FilterForm_nkkx4_1 ._divider_nkkx4_27 {
  margin: var(--spacing-md) 0;
}
._FilterForm_nkkx4_1 ._button_nkkx4_30 {
  width: 100%;
  margin-top: var(--spacing-md);
}

._selectItem_nkkx4_35:hover {
  background-color: var(--primary-10);
  color: var(--primary-40);
}