import * as React from 'react';

interface IProps {
  className?: string;
}

export const ArrowIosRightOutlined: React.FC<Readonly<IProps>> = ({ className }) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4065 18.9998C10.1741 18.9998 9.94075 18.9238 9.7485 18.7678C9.31259 18.4148 9.25399 17.7838 9.6169 17.3598L14.2186 11.9888L9.78242 6.62683C9.42671 6.19683 9.49662 5.56683 9.93869 5.22083C10.3818 4.87483 11.0285 4.94283 11.3852 5.37283L16.3487 11.3728C16.6551 11.7438 16.651 12.2738 16.3374 12.6398L11.1971 18.6398C10.9935 18.8768 10.7015 18.9998 10.4065 18.9998"
      fill="currentColor"
    />
    <mask
      id="mask0_433:12591"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="5"
      width="8"
      height="14"
    >
      <path
        d="M10.4065 18.9998C10.1741 18.9998 9.94075 18.9238 9.7485 18.7678C9.31259 18.4148 9.25399 17.7838 9.6169 17.3598L14.2186 11.9888L9.78242 6.62683C9.42671 6.19683 9.49662 5.56683 9.93869 5.22083C10.3818 4.87483 11.0285 4.94283 11.3852 5.37283L16.3487 11.3728C16.6551 11.7438 16.651 12.2738 16.3374 12.6398L11.1971 18.6398C10.9935 18.8768 10.7015 18.9998 10.4065 18.9998"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0_433:12591)" />
  </svg>
);
