import gql from 'graphql-tag';

export const CLIENT_METADATA_FRAGMENT = gql`
  fragment ClientMetadataFragment on ClientMetadata {
    id
    activationDate
    firstLoginDate
    onboardingCompletedDate
    lastOnDemandSocialAnalyticsRefreshDate
    applicantReviewFields
    allowedDomains
  }
`;
