import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import { REMOVE_OWNERS_FROM_MEMBERS_MUTATION } from '@frontend/app/queries';
import {
  RemoveOwnersFromMembersMutation,
  RemoveOwnersFromMembersMutationVariables,
} from '@frontend/app/queries/types/RemoveOwnersFromMembersMutation';

type IOptions = MutationHookOptions<RemoveOwnersFromMembersMutation, RemoveOwnersFromMembersMutationVariables>;

export const useRemoveOwnersFromMembersMutation = (options: IOptions = {}) => useMemberMutation(REMOVE_OWNERS_FROM_MEMBERS_MUTATION, options);
