import * as React from 'react';
import {
 Redirect, Route, Switch, useHistory, useRouteMatch, useLocation,
} from 'react-router-dom';

import { useAuth } from '@frontend/context/authContext';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { CONTENT_APP_ID } from '@frontend/app/constants/applicationIds';
import { CreateAppContainer } from '@frontend/app/components/CreateAppContainer/CreateAppContainer';

import { Deliverables } from '@frontend/app/containers/MemberDetail/components/Deliverables';
import {
 useCopyToClipboard, useGetCollaborationDetailsForProject, useMarkContentReviewComplete,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { LoadSpinner } from '@frontend/app/components';
import { MessageComposer } from '../components/MessageComposer';
import { IAxProjectInfo } from '../types/IAxProjectInfo';

import styles from './ContentApprovalContainer.scss';

const {
 useEffect, useState, useMemo, useCallback,
} = React;

interface IDeepLinkParams {
  context: string;
  id: string;
  member_id?: number;
  program_id?: number;
  work_item_id?: string;
}

interface IProps {
  deepLinkParameters?: IDeepLinkParams;
}

const ContentApprovalContainer: React.FunctionComponent<IProps> = ({ deepLinkParameters }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { workflowActionParameters } = useApplication();

  const { token } = useAuth();
  const [projectInfo, setProjectInfo] = useState<IAxProjectInfo>(null);
  const [contentReviewId, setContentReviewId] = useState<string>(null);

  const memberId = deepLinkParameters?.member_id || workflowActionParameters?.memberIds?.[0];
  const workItemId = deepLinkParameters?.work_item_id || workflowActionParameters?.workItems?.[0]?.id;
  const programId = deepLinkParameters?.program_id || workflowActionParameters?.programId;

  const params = useMemo(() => ({
      token,
      member_id: memberId,
      work_item_id: workItemId,
      program_id: programId,
      content_review_id: contentReviewId,
    }), [token, memberId, workItemId, programId, contentReviewId]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const linkParams = query.get('link_params');
    const parsedParams = JSON.parse(decodeURIComponent(linkParams)) || null;
    if (parsedParams) {
      setContentReviewId(parsedParams.id);
    }
  }, [location]);
  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.data && event.data.msgType === 'ax_content_send_reminder') {
        setProjectInfo(event.data.project);
        history.push(`${match.url}/send_reminder`);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    data: { details = [] } = {},
    refetch,
    loading: loadingCollabDetails,
  } = useGetCollaborationDetailsForProject({
    fetchPolicy: 'no-cache',
    variables: { projectId: programId, memberId },
    skip: !programId,
  });
  const { showMessage, showGenericErrorMessage } = useMessagingContext();
  const { copyToClipboard } = useCopyToClipboard();
  const [markContentReviewComplete] = useMarkContentReviewComplete({
    onCompleted: async (data) => {
      showMessage({
        type: 'success',
        content: data.markedForCompletion ? 'Pending marked as complete. You have 5 minutes to undo this action.'
        : 'Mark as complete cancelled',
      });
      refetch();
    },
    onError: () => {
      showGenericErrorMessage();
    },
  });
  const onReviewClick = useCallback((contentReviewId) => {
      history.push({
        ...location,
        pathname: `${match.url}/content`,
        search: `link_params=${encodeURIComponent(JSON.stringify({
          in_approval_mode: true,
          member_id: memberId,
          program_id: programId,
          context: 'viewContext',
          id: contentReviewId,
        }))}`,
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, match, programId]);

  const markComplete = useCallback((contentReviewId: number, shouldMarkComplete: boolean) => {
    markContentReviewComplete({
      variables: {
        projectId: programId,
        contentReviewId,
        shouldMarkComplete,
      },
    });
  }, [markContentReviewComplete, programId]);

  const copyTermsLink = useCallback((termsUrl: string) => {
    copyToClipboard({
      value: termsUrl,
      onSuccess: () => {
        showMessage({
          type: 'success',
          content: 'Link copied to clipboard',
        });
      },
      onFailure: () => {
        showGenericErrorMessage();
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    copyToClipboard,
  ]);

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (
        event.data
        && event.data.msgType === 'refresh_content_page'
      ) {
        const segments = match.url.split('/');
        const newPath = segments.slice(0, -2).join('/');
        history.push({
          ...location,
          pathname: newPath,
        });
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location]);

  return (
    <div className={styles.ContentApprovalContainer}>
      <Switch>
        <Route path={`${match.url}/send_reminder`}>
          <>
            <div className={styles.title}>Send Reminder</div>
            <div className={styles.subtitle}>Provide a friendly reminder to the creator to complete their upcoming deliverables</div>
            <MessageComposer memberId={memberId} project={projectInfo} />
          </>
        </Route>
        <Route path={`${match.url}/content_list`}>
          {loadingCollabDetails && <LoadSpinner />}
          {!loadingCollabDetails && (
            <Deliverables
              onMarkCompleteAction={markComplete}
              onCopyTermsLinkAction={copyTermsLink}
              collaborationDetails={details}
              onReviewClick={onReviewClick}
              onTermsClick={null}
              isInSentTermsStage={false}
              sendTermsAction={() => {}}
            />
          )}
        </Route>
        <Route path={`${match.url}/content`}>
          <CreateAppContainer
            className={styles.ContentApprovalContainer}
            applicationId={CONTENT_APP_ID}
            token={token}
            extraParams={params}
          />

        </Route>
        <Route>
          <Redirect to={`${match.url}/content_list`} />
        </Route>

      </Switch>
    </div>
  );
};

export default ContentApprovalContainer;
