import { useQuery } from '@apollo/client';

import { GetDefaultProgramTemplateQuery, GetDefaultProgramTemplateQueryVariables } from '@frontend/app/queries/types/GetDefaultProgramTemplateQuery';
import { GET_DEFAULT_PROGRAM_TEMPLATE } from '@frontend/app/queries/GetDefaultProgramTemplateQuery';

export function useGetDefaultProgramTemplate(options = {}) {
  return useQuery<GetDefaultProgramTemplateQuery, GetDefaultProgramTemplateQueryVariables>(
    GET_DEFAULT_PROGRAM_TEMPLATE,
    options,
  );
}
