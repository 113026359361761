import * as React from 'react';
import {
  Avatar, Button, Drawer, Space,
} from '@revfluence/fresh';
import type { DrawerProps } from 'antd/lib/drawer';
import { XmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import { GiftIcon } from '@revfluence/fresh-icons/regular/esm';

import { isFunction } from 'lodash';

import styles from './Drawer.styles.scss';

interface IProps extends DrawerProps {
  onSubmit: () => void;
  children;
  onClose;
  title;
}

export const OrderRequestSetupDrawer = (props: IProps) => {
  const {
    children, onClose, title, onSubmit, ...remainingProps
  } = props;

  const closeButton = () => {
    if (!isFunction(onClose)) {
      return null;
    }

    return <Button icon={<XmarkIcon />} onClick={onClose} type="text" />;
  };

  return (
    <Drawer bodyStyle={{ padding: 0 }} closable={false} width={1024} className={styles.Wizard} {...remainingProps}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerContentWrapper} />
          <div className={styles.headerContentWrapper}>
            <Avatar icon={<GiftIcon />} className={styles.headerIcon} style={{ backgroundColor: '#09375A' }} />
            {title}
          </div>
          <div className={styles.headerContentWrapper}>{closeButton()}</div>
        </div>
        <div className={styles.mainContentWrapper}>{children}</div>

        <div className={styles.footer}>
          <Space direction="horizontal" align="center" size="small">
            <Button className={styles.footerButton} type="default" onClick={onClose}>
              Cancel
            </Button>
            <Button className={styles.footerButton} type="primary" onClick={onSubmit}>
              Update Catalog
            </Button>
          </Space>
        </div>
      </div>
    </Drawer>
  );
};
