import { H4 } from '@frontend/shadcn/components/typography/h4';
import { P } from '@frontend/shadcn/components/typography/p';
import { Alert, AlertTitle } from '@frontend/shadcn/components/ui/alert';
import { Button } from '@frontend/shadcn/components/ui/button';
import { XmarkIcon } from '@revfluence/fresh-icons/regular';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/solid';
import React, { useState, MouseEventHandler } from 'react';

interface IAlertProps {
  title: string;
  description?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  buttonText?: string;
  closeable?: boolean;
  buttonSize?: 'sm' | 'lg' | 'default' | 'xs' | 'icon' | 'headerIcon'
}

const ShadCnAlert: React.FC<IAlertProps> = ({
  title, description, onClick, buttonText, closeable, buttonSize = 'default',
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Alert role="alert" className="w-full bg-alert-error flex items-center font-inter text-primary border border-red-4">
      {closeable && (
      <div className="cursor-pointer absolute right-2 top-2" onClick={handleClose}>
        <XmarkIcon className="w-4 h-4" />
      </div>
)}
      <div className="flex flex-col md:flex-row md:items-center justify-center items-start w-full pr-6">
        <div className="flex-grow">
          <AlertTitle className="flex items-center">
            <TriangleExclamationIcon className="w-4 h-4 mr-2 text-destructive" />
            <H4 className="m-0">{title}</H4>
          </AlertTitle>
          <P>{description}</P>
        </div>
        {onClick && (
          <Button variant="outline" size={buttonSize} onClick={onClick} className="m-0 text-primary rounded-lg border border-grey-3 md:ml-4">
            {buttonText}
          </Button>
        )}
      </div>
    </Alert>
  );
};

export default ShadCnAlert;
