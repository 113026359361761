import gql from 'graphql-tag';

export const SAVE_PROJECT_CAMPAIGN_MUTATION = gql`
  mutation SaveProjectCampaignMutation($projectId: Int!, $campaign: BackendServerCampaignInput!) {
    campaign: saveProjectCampaign(projectId: $projectId, campaign: $campaign) {
      id
      accepted_place_ids {
        label
        value
      }
      brand_id
      campaign_id
      disabled_ts
      enabled_ts
      external_listing_url
      name
      network_details {
        fixed_compensation_is_negotiable
        fixed_compensation_per_engagement
        maximum_budget
        maximum_engagements
        maximum_followers
        minimum_engagements
        minimum_followers
        network_type
      }
      network_types
      product_types
      program_id
      splash_image_url
      summary
      categories
    }
  }
`;
