import * as React from 'react';
import { IImageProps, Image } from './Image';
import styles from './ImageWithTag.scss';

interface IImageWithTag extends IImageProps {
  Tag: React.ReactNode;
}

export const ImageWithTag = ({
  Tag,
  ...imageProps
}: IImageWithTag) => (
  <div className={styles.imageContainer}>
    <Image
      {...imageProps}
    />
    <div className={styles.tagContainer}>
      {Tag}
    </div>
  </div>
);
