import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { GET_REPORTING_RECOMMENDATION } from '../queries';
import {
  ReportingRecommendation,
} from '../queries/types/ReportingRecommendation';

type IOptions = QueryHookOptions<ReportingRecommendation>;

export const useGetReportingRecommendation = (options: IOptions = {}) => (
  useQuery<ReportingRecommendation>(GET_REPORTING_RECOMMENDATION, options)
);
