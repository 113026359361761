import { PopoverProps } from 'antd/lib/popover';
import * as React from 'react';
import {
  upperFirst,
  chain,
  filter,
  findIndex,
  first,
  isEmpty,
  isEqual,
  isString,
  map,
} from 'lodash';

import { EventName } from '@common';
import { FilterMenuPopover } from '@frontend/app/components';
import {
  ISearchableItem,
  ISource,
} from '@frontend/app/components/FilterMenuPopover/FilterMenuPopover';
import { useEventContext } from '@frontend/app/context/EventContext';
import { FieldType } from '@frontend/app/types/Fields';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';

import {
  FilterFormPopover,
  IFilterFormPopoverHandles,
} from './FilterFormPopover';
import {
  IFilter,
  FieldSource,
} from './model';

const {
 useEffect, useMemo, useState, useRef,
} = React;

interface IProps {
  additionalSources?: ISource[];
  fields?: IField[];
  filters: IFilter[];
  onAddFilter: (filter: IFilter) => void;
  popoverProps?: PopoverProps;
}

export const AddFilterPopover: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    additionalSources = [],
    children,
    fields: fieldsProp,
    filters,
    onAddFilter,
    popoverProps,
  } = props;

  const [visible, setVisible] = useState(false);
  const [selectedFieldDOMRect, setSelectedFieldDOMRect] = useState<DOMRect>();
  const [selectedField, setSelectedField] = useState<IField>(null);
  const filterFormPopoverRef = useRef<IFilterFormPopoverHandles>();
  const popoverAnchorRef = useRef<HTMLDivElement>();

  const fields = useMemo(() => (
    // Only allow one programs, activations and tags filter.
    filter(fieldsProp, (col) => {
      if (
        col.type === FieldType.PROGRAM
        || col.type === FieldType.ACTIVATION
        || col.type === FieldType.TAG
      ) {
        return findIndex(filters, { column: col.field }) === -1;
      }
      return true;
    })
  ), [filters, fieldsProp]);

  const addEvent = useEventContext();
  const handleAddFilter = (newFilter: IFilter) => {
    onAddFilter(newFilter);
    setSelectedField(null);
    setVisible(false);
    addEvent(EventName.MemberFilterSelect, { field_name: selectedField.headerName });
  };

  const onPopoverVisibleChange = (visible: boolean) => {
    setVisible(visible);
    if (!visible) {
      setSelectedField(null);
      setSelectedFieldDOMRect(null);
    }
  };

  const onFilterFormPopoverVisibleChange = (visible: boolean) => {
    if (!visible) {
      setSelectedField(null);
      setSelectedFieldDOMRect(null);
    }
  };

  /**
   * Items and sources
   */
  const sources: ISource[] = useMemo(() => [
    ...map(FieldSource, (s) => ({
      label: s,
      value: s === FieldSource.All ? null : s,
      isSelectAll: true,
    })),
    ...additionalSources,
  ], [additionalSources]);

  const items: ISearchableItem<IField>[] = useMemo(() => (
    chain(fields)
      .map((col) => ({
        label: upperFirst(col.headerName),
        value: col,
        source: {
          label: isString(col.source) ? col.source : col.source?.name,
          value: isString(col.source) ? col.source : col.source?.id,
        },
      }))
      .value()
  ), [fields]);

  /**
   * Filter form
   */
  const filterFormPopoverOffset = useMemo((): [number, number] => {
    const popoverRect = popoverAnchorRef.current?.getBoundingClientRect();
    if (selectedFieldDOMRect && popoverRect) {
      // Align with the selected item's DOM
      return [
        12,
        selectedFieldDOMRect.top - popoverRect.top + (selectedFieldDOMRect.height / 2),
      ];
    }
    return null;
  }, [selectedFieldDOMRect]);

  const renderFilterFormPopover = () => (
    <FilterFormPopover
      field={selectedField}
      fields={fields}
      onAddFilter={handleAddFilter}
      popoverProps={{
        placement: 'right',
        // visible: !isEmpty(selectedField),
        onVisibleChange: onFilterFormPopoverVisibleChange,
        align: {
          offset: filterFormPopoverOffset,
        },
      }}
      ref={filterFormPopoverRef}
    >
      <div ref={popoverAnchorRef}>{/* Popover's anchor container */}</div>
    </FilterFormPopover>
  );

  useEffect(() => {
    if (!isEmpty(selectedField)) {
      filterFormPopoverRef.current?.show();
    }
  }, [selectedField]);

  return (
    <FilterMenuPopover
      sources={sources}
      defaultSource={first(sources)}
      items={items}
      onItemClick={(item, e) => {
        if (!isEqual(item.value, selectedField)) {
          setSelectedField(item.value);
          setSelectedFieldDOMRect(e.currentTarget?.getBoundingClientRect());
        }
      }}
      header={renderFilterFormPopover()}
      popoverProps={{
        onVisibleChange: onPopoverVisibleChange,
        visible,
        ...popoverProps,
      }}
    >
      {children}
    </FilterMenuPopover>
  );
});

AddFilterPopover.defaultProps = {
  additionalSources: [],
};

AddFilterPopover.displayName = 'AddFilterPopover';
