import * as React from 'react';
import { useResourceLastTimeSynced } from '@frontend/app/hooks';

import styles from './LastSynced.scss';

interface IProps {
  sourcingGroupId: string
}

export const LastSynced: React.FC<IProps> = React.memo((props) => {
  const lastSyncedTime = useResourceLastTimeSynced(props.sourcingGroupId);

  return lastSyncedTime && (
    <div className={styles.LastSyncedTime}>
      Synced
      {' '}
      {lastSyncedTime}
    </div>
  );
});

LastSynced.displayName = 'LastSynced';
