import * as React from 'react';

import { Toggle } from '@components';
import { FilterSection } from '../FilterSection';

interface IProps {
  isOn?: boolean;
  onChange(isOn: boolean);
  hide?: boolean;
}

const HasSponsoredPostsSection: React.FunctionComponent<IProps> = (props) => (
  <FilterSection header="Has Sponsored Posts" hide={props.hide} isSubsection>
    <Toggle checked={props.isOn} onChange={props.onChange} />
  </FilterSection>
  );

export default HasSponsoredPostsSection;
