import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { Alert, Button } from '@revfluence/fresh';
import { useGetInstalledApplicationIds } from '@frontend/app/hooks';
import { GMAIL_APP_ID, OUTLOOK_APP_ID } from '@frontend/app/constants/applicationIds';

const { useMemo } = React;

interface IProps {
  className?: string;
}

export const NoResourcesAlert: React.FC<IProps> = React.memo((props) => {
  const installedApps = useGetInstalledApplicationIds();
  const history = useHistory();

  const emailSettingsUrl = useMemo(() => {
    if (installedApps[GMAIL_APP_ID]) {
      return `/settings/${encodeURIComponent(GMAIL_APP_ID)}`;
    } else if (installedApps[OUTLOOK_APP_ID]) {
      return `/settings/${encodeURIComponent(OUTLOOK_APP_ID)}`;
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GMAIL_APP_ID, OUTLOOK_APP_ID, encodeURIComponent, installedApps]);

  return (
    <Alert
      message="No email accounts found"
      description=""
      className={props.className}
      banner={false}
      showIcon
      action={emailSettingsUrl ? (
        <Button
          type="default"
          onClick={() => history.push(emailSettingsUrl)}
        >
          Manage Accounts
        </Button>
      ) : null}
      type="warning"
    />
  );
});
NoResourcesAlert.displayName = 'NoResourcesAlert';
