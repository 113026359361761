import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const UnderlineIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 53">
    <g clipPath="url(#clip-underline)">
      <path d="M16.0068 33.2549C12.1032 33.2549 9.83575 30.8177 9.83575 27.2196V17.6473C9.83575 16.9413 10.2295 16.5 10.8745 16.5C11.5194 16.5 11.9267 16.9413 11.9267 17.6473V27.2196C11.9267 29.7722 13.5153 31.3812 16.0068 31.3812C18.5119 31.3812 20.1005 29.7587 20.1005 27.2196V17.6473C20.1005 16.9413 20.4943 16.5 21.1392 16.5C21.7842 16.5 22.1915 16.9413 22.1915 17.6473V27.2671C22.1915 30.8449 19.7068 33.2549 16.0068 33.2549Z" />
      <path d="M21.5261 35.1422H10.4739C10.1005 35.1422 9.79498 35.4477 9.79498 35.8211C9.79498 36.1945 10.1005 36.5 10.4739 36.5H21.5261C21.8995 36.5 22.205 36.1945 22.205 35.8211C22.205 35.4477 21.9063 35.1422 21.5261 35.1422Z" />
    </g>
    <defs>
      <clipPath id="clip-underline">
        <rect width="12.41" height="20" fill="white" transform="translate(9.79498 16.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
));

UnderlineIcon.displayName = 'UnderlineIcon';
