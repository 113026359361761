import * as React from 'react';
import cx from 'classnames';
import {
  filter, isEmpty, map,
} from 'lodash';
import { IImageData, Thumbnail } from './ImageThumbnail';

import styles from './ImagesCell.scss';

interface IProps {
  images: IImageData[];
  className?: string;
}

export const ImagesCell: React.FC<IProps> = React.memo((props) => {
  const {
    className,
    images,
  } = props;

  return (
    <div
      className={cx(styles.ImagesCell, className)}
      onClick={(e) => e.stopPropagation()}
    >
      {map(
        filter(images, (image) => !isEmpty(image.image)),
        (image, i) => <Thumbnail key={i} image={image} />,
      )}
    </div>
  );
});

ImagesCell.displayName = 'ImagesCell';
