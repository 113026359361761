import * as React from 'react';

import { map } from 'lodash';

import { useListOnMarketplace } from '../../../context';
import TabField from '../../TabField';

import NetworkRestriction from '../../NetworkRestriction';

import styles from './styles.scss';

const EligibilityRestricitionsComponent: React.FC = () => {
  const {
    selectedNetworks,
  } = useListOnMarketplace();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Eligibility Restrictions
        </div>
        <div className={styles.headerContent}>
          Set up restrictions for who may apply for your project.
          {' '}
          Strict filters will result in fewer proposals from creators and fewer creators that can be recommended to you.
          {' '}
          However, if filters are too lax, then you may need to spend more time to review and reject creators.
        </div>
      </div>
      <div className={styles.networks}>
        {map(selectedNetworks, (network) => (
          <NetworkRestriction
            key={`network_restriction_${network}`}
            type={network}
          />
        ))}
      </div>
      <TabField
        key="accepted_place_ids"
        name="accepted_place_ids"
        label="Enter location"
        type="location"
        helpText="Enter the location you'd like your creators to be located in"
        footerText="Leave this field blank to not restrict creators by their city, state, or country of residence"
      />
      <div className={styles.eligibilityNotice}>
        *Your project will be automatically unlisted once it is 45 days old
      </div>
    </div>
  );
};

const EligibilityRestricitions = React.memo(EligibilityRestricitionsComponent);

EligibilityRestricitions.displayName = 'EligibilityRestricitions';

export default EligibilityRestricitions;
