import { TSelectedMember } from '@affiliates/components/MembersWizard/types';

export type TState = Readonly<{
  currentStep: number;
  error: Error | null;
  members: readonly TSelectedMember[];
  saving: boolean;
  showCloseConfirmation: boolean;
}>;

export type TActions = Readonly<{
  dismissCloseConfirmation: () => void;
  save: (step: number) => void;
  selectMembers: (members: readonly TSelectedMember[]) => void;
  setCurrentStep: (step: number) => void;
  setError: (error: Error) => void;
  showCloseConfirmation: () => void;
  reset: () => void;
}>;
