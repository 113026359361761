import * as React from 'react';
import { UnregisterCallback } from 'history';

import { isFunction, includes, some } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { ModalFuncProps, ModalProps } from 'antd/lib/modal';

const { useCallback, useEffect, useState } = React;

enum ConfirmType {
  CUSTOM,
  NATIVE,
}

interface IConfirmOnExitProps {
  /** Whether the confirmation dialog needs to show */
  show: boolean,

  /** Whether to show custom modal */
  showCustomModal?: boolean,

  /** Antd modal props for custom modal */
  customModalProps?: ModalFuncProps | ModalProps,

  /** Native confirm message */
  confirmMessage?: string,

  /** default to "true", if it should navigate first then call OK callback */
  shouldOkNavigate?: boolean,

  /** default to "Leave page" */
  okText?: string,

  /** default to "Cancel" */
  cancelText?: string,

  /** Action to perform after accepting custom modal */
  onCustomModalOk?(unblock: UnregisterCallback, transition),

  /** Action to perform after canceling custom modal */
  onCustomModalCancel?(unblock: UnregisterCallback, transition),

  /** Callback called when a modal shows */
  onModalShown?(type: ConfirmType),

  /** Omit transition paths */
  omitTransitions?: { from: string, to: string }[],
}

interface IConfirmOnExit {
  /** Count the times a confirmation dialog has shown */
  count: number,
}

export const useConfirmOnExit = (props: IConfirmOnExitProps): IConfirmOnExit => {
  const {
    show,
    showCustomModal = true,
    customModalProps,
    confirmMessage = 'You have unsaved changes. Changes that are not saved will be discarded once you leave.',
    onCustomModalOk,
    onCustomModalCancel,
    onModalShown,
    okText = 'Leave page',
    cancelText = 'Cancel',
    shouldOkNavigate = true,
    omitTransitions,
  } = props;

  const [count, setCount] = useState(0);

  const history = useHistory();

  const handleOnModalShow = useCallback((type: ConfirmType) => {
    setCount(count + 1);

    if (isFunction(onModalShown)) {
      onModalShown(type);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleHardExit = useCallback((event: Event) => {
    if (show) {
      event.preventDefault();
      handleOnModalShow(ConfirmType.NATIVE);

      return undefined;
    }
  }, [show, handleOnModalShow]);

  const handleCustomModalOk = useCallback((unblock: UnregisterCallback, transition): void => {
    if (shouldOkNavigate) {
      unblock();
      history.push({
        pathname: transition.pathname,
        search: transition.search,
        state: transition.state,
      });
    }

    if (isFunction(onCustomModalOk)) {
      onCustomModalOk(unblock, transition);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCustomModalOk, shouldOkNavigate]);

  const handleCustomModalCancel = useCallback((unblock: UnregisterCallback, transition): void => {
    if (isFunction(onCustomModalCancel)) {
      onCustomModalCancel(unblock, transition);
    }
  }, [onCustomModalCancel]);

  const defaultModalProps = useCallback((unblock: UnregisterCallback, transition): ModalFuncProps | ModalProps => ({
    title: 'You have unsaved changes',
    content: confirmMessage,
    centered: true,
    okText,
    cancelText,
    okButtonProps: {
      danger: true,
    },
    onOk: () => {
      handleCustomModalOk(unblock, transition);
    },
    onCancel: () => {
      handleCustomModalCancel(unblock, transition);
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [handleCustomModalOk, handleCustomModalCancel]);

  const displayCustomModal = useCallback((unblock: UnregisterCallback, transition) => {
    if (!show) {
      return;
    }

    const shouldOmit = omitTransitions && some(omitTransitions, (omitTransition) => {
      if (
        includes(history.location?.pathname, omitTransition.from)
          && includes(transition?.pathname, omitTransition.to)
      ) {
        return true;
      }

      return false;
    });

    if (shouldOmit) {
      unblock();
      return;
    }

    if (showCustomModal) {
      const defaultProps = defaultModalProps(unblock, transition);

      Modal.confirm({ ...defaultProps, ...(customModalProps || {}) });

      handleOnModalShow(ConfirmType.CUSTOM);
      return false;
    } else {
      handleOnModalShow(ConfirmType.NATIVE);
      const confirm = window?.confirm(confirmMessage);

      // Weird return to comply with history.block types.
      return confirm ? '' : false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    show,
    showCustomModal,
    defaultModalProps,
    customModalProps,
    handleOnModalShow,
    window,
    history?.location,
  ]);

  useEffect(() => {
    setCount(0);
  }, []);

  useEffect(() => {
    window?.addEventListener('beforeunload', handleHardExit);

    const unblockHistory: UnregisterCallback = history.block((transition) => displayCustomModal(unblockHistory, transition));

    return () => {
      unblockHistory();
      window?.removeEventListener('beforeunload', handleHardExit);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, history, show, displayCustomModal, handleHardExit]);

  return {
    count,
  };
};
