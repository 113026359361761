import * as React from 'react';
import cx from 'classnames';
import {
 filter,
 includes,
 isEmpty,
 map,
 orderBy,
 size,
} from 'lodash';
import {
 Checkbox,
 CheckboxChangeEvent,
 Empty,
 Input,
 Popover,
 Tooltip,
} from '@revfluence/fresh';
import { MagnifyingGlassIcon, PencilIcon } from '@revfluence/fresh-icons/regular/esm';

import { GetUsersQuery_users } from '@frontend/app/queries/types/GetUsersQuery';
import { useFuzzySearchByKeys } from '@frontend/app/hooks';

import { UserAvatar } from '@frontend/app/components';
import styles from './styles.scss';

const { useEffect, useMemo, useState } = React;

export const MAX_ASSIGNEES_TO_DISPLAY = 2;

export interface IThreadAssignees {
  users: GetUsersQuery_users[];
  isFromSharedEmail: boolean,
  selectedAssigneesIds: string[];
  isUpdatingAssignees: boolean;
  onToggle: (userId: string, isChecked: boolean) => void;
}

export const ThreadAssignees: React.FC<IThreadAssignees> = (props) => {
  const {
    users: baseUsers,
    isFromSharedEmail,
    selectedAssigneesIds,
    isUpdatingAssignees,
    onToggle,
  } = props;

  const getSearchResults = useFuzzySearchByKeys(baseUsers, ['name', 'email']);

  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState(baseUsers);

  const assignees = useMemo(() => filter(baseUsers, (user) => includes(selectedAssigneesIds, user.id)), [baseUsers, selectedAssigneesIds]);

  const selectedAssigneesLabel = useMemo(() => {
    const hasOverflow = size(assignees) > MAX_ASSIGNEES_TO_DISPLAY;

    let label = map(
      assignees.slice(0, MAX_ASSIGNEES_TO_DISPLAY),
      'name',
    ).join(', ');

    if (hasOverflow) {
      label += ` + ${size(assignees) - MAX_ASSIGNEES_TO_DISPLAY}`;
    }

    return label;
  }, [assignees]);

  useEffect(() => {
    setUsers(getSearchResults(searchQuery));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const popoverContent = useMemo(() => {
    const sortedUsers = orderBy(users, 'name', 'asc');

    return (
      <div className={styles.popoverContent}>
        <Input
          type="search"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={searchQuery}
          prefix={<MagnifyingGlassIcon />}
          placeholder="Search..."
        />
        <div className={styles.popoverList}>
          {map(sortedUsers, (user) => (
            <Checkbox
              disabled={isUpdatingAssignees}
              key={user.id}
              checked={includes(selectedAssigneesIds, user.id)}
              onChange={(e: CheckboxChangeEvent) => onToggle(user.id, e.target.checked)}
              className={styles.popoverElementWrapper}
            >
              <div className={styles.popoverElement}>
                <UserAvatar
                  name={user.name}
                  size="small"
                />
                {user.name}
              </div>
            </Checkbox>
          ))}
          {isEmpty(sortedUsers) && (
            <Empty description="No users found." />
          )}
        </div>
      </div>
    );
  }, [isUpdatingAssignees, onToggle, searchQuery, selectedAssigneesIds, users]);

  return (
    <div className={styles.ThreadAssignees}>
      <Tooltip
        title={!isFromSharedEmail && 'Assignment can only be set and edited for Shared Emails'}
        overlayStyle={{
          display: isFromSharedEmail ? 'none' : undefined,
          pointerEvents: 'none',
        }}
      >
        <div style={{ display: 'flex' }}>
          <span className={styles.header}>Assigned to:</span>
          <Popover
            content={popoverContent}
            trigger={isFromSharedEmail ? 'click' : []}
            placement="bottom"
          >
            <div className={cx(styles.popoverTrigger, {
            [styles.sharedEmail]: isFromSharedEmail,
            })}
            >
              {selectedAssigneesLabel}
              {
              isFromSharedEmail
                && <span className={styles.editIcon}><PencilIcon /></span>
            }
            </div>
          </Popover>
        </div>
      </Tooltip>
    </div>
  );
};
