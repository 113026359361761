import { MutationHookOptions } from '@apollo/client';
import { useMutation } from '@frontend/app/hooks';
import { reject } from 'lodash';

import { generateMemberSearchGraphQLQuery } from '@frontend/app/queries';
import {
  MemberSearchQuery,
  MemberSearchQueryVariables,
} from '@frontend/app/queries/types/MemberSearchQuery';

import { useReloadSegmentCounts } from './useReloadSegmentCounts';
import { useMemberSearchQueryVariables } from './useMemberSearchQueryVariables';
import { MEMBER_APPLICANT_OPERATION } from '../queries/MemberApplicantOperationMutation';
import {
  MemberApplicantOperationMutation,
  MemberApplicantOperationMutationVariables,
} from '../queries/types/MemberApplicantOperationMutation';

type IOptions = MutationHookOptions<MemberApplicantOperationMutation, MemberApplicantOperationMutationVariables>;

export const useMemberApplicantOperationMutation = (
  communityId: number,
  reloadCounts: boolean = true,
  options: IOptions = {},
) => {
  const memberSearchQueryVariables = useMemberSearchQueryVariables();
  const reloadSegmentCounts = useReloadSegmentCounts(communityId, null);

  return useMutation<MemberApplicantOperationMutation, MemberApplicantOperationMutationVariables>(
    MEMBER_APPLICANT_OPERATION,
    {
      ...options,
      update(store, result) {
        const cache = store.readQuery<MemberSearchQuery, MemberSearchQueryVariables>({
          query: generateMemberSearchGraphQLQuery(),
          variables: memberSearchQueryVariables,
        });

        // Do nothing. Cache doesn't exist.
        if (!cache) {
          return;
        }

        const newCache = {
          members: reject(cache.members, { id: result.data.member.id }),
        };

        store.writeQuery<MemberSearchQuery, MemberSearchQueryVariables>({
          query: generateMemberSearchGraphQLQuery(),
          variables: memberSearchQueryVariables,
          data: newCache,
        });

        // Refetch all segment counts
        if (reloadCounts) {
          reloadSegmentCounts();
        }
      },
    },
  );
};
