// MetricsPrimary.tsx
import * as React from 'react';
import { getLastFullMonths, getTrendTooltip } from '@frontend/applications/ReportsApp/containers/utils';
import { IImpactHomeProps } from '../../Home';
import PrimaryMetricsCard from './Card/PrimaryMetricsCard';
import { getTrendTooltips } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary';

interface IMetricsPrimaryProps {
  contentData: IImpactHomeProps['contentData'];
  impressionsData: IImpactHomeProps['impressionsData'];
  engagementsData: IImpactHomeProps['engagementsData'];
  salesData: IImpactHomeProps['contentData'];
  igStoryEstimationEnabled?: boolean;
  tempYoutubeDemoAccountMode?: boolean;
}

export interface IHistoricalData {
  dateTime: string;
  value: number;
}

const RefreshMetricsPrimary = ({
  contentData,
  impressionsData,
  engagementsData,
  salesData,
  igStoryEstimationEnabled,
  tempYoutubeDemoAccountMode,
}: IMetricsPrimaryProps) => {
  const trendTooltips = getTrendTooltips(igStoryEstimationEnabled, tempYoutubeDemoAccountMode);
  const tooltipTrend = getTrendTooltip(getLastFullMonths(2));

  const chartColor = {
    blue: 'chartColors-blue',
    green: 'chartColors-green',
    orange: 'chartColors-orange',
    plum: 'chartColors-plum',
  };

  const metrics: { title: 'content' | 'impressions' | 'engagements' | 'sales', value: number, historicalData: IHistoricalData[], trendPercentDiff: number | null, trendTooltip: string, chartColor: string }[] = [
    {
      title: 'content',
      value: contentData.value,
      historicalData: contentData.historicalData,
      trendPercentDiff: contentData.percentDiff,
      trendTooltip: trendTooltips.content,
      chartColor: chartColor.blue,
    },
    {
      title: 'impressions',
      value: impressionsData.value,
      historicalData: impressionsData.historicalData,
      trendPercentDiff: impressionsData.percentDiff,
      trendTooltip: trendTooltips.impressions,
      chartColor: chartColor.plum,
    },
    {
      title: 'engagements',
      value: engagementsData.value,
      historicalData: engagementsData.historicalData,
      trendPercentDiff: engagementsData.percentDiff,
      trendTooltip: trendTooltips.engagements,
      chartColor: chartColor.orange,
    },
    {
      title: 'sales',
      value: salesData.value,
      historicalData: salesData.historicalData,
      trendPercentDiff: salesData.percentDiff,
      trendTooltip: trendTooltips.sales,
      chartColor: chartColor.green,
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
      {metrics.map((metric) => (
        <PrimaryMetricsCard
          key={metric.title}
          title={metric.title}
          value={metric.value}
          historicalData={metric.historicalData}
          trendPercentDiff={metric.trendPercentDiff}
          trendTooltip={metric.trendTooltip}
          statsTooltip={tooltipTrend}
          chartColor={metric.chartColor}
        />
      ))}
    </div>
  );
};

export default RefreshMetricsPrimary;
