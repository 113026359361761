/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14o8n_157 {
  display: flex;
}

._justify-content-space-between_14o8n_161 {
  justify-content: space-between;
}

._tabular-nums_14o8n_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_14o8n_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._HeaderCell_14o8n_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8f8d91 !important;
  text-transform: uppercase;
  font-size: 0.75rem !important;
  font-weight: 500;
  letter-spacing: 0.03125rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  user-select: none;
  transition: color 0.15s cubic-bezier(0.4, 0, 0.2, 1), background 0.15s cubic-bezier(0.4, 0, 0.2, 1), font 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._HeaderCell_14o8n_178:hover, ._HeaderCell_14o8n_178:focus {
  background-color: #e6edf2;
  color: #3996e0;
}
._HeaderCell_14o8n_178._sortable_14o8n_196 {
  cursor: pointer;
}
._HeaderCell_14o8n_178._active_14o8n_199 {
  color: #3996e0;
}
._HeaderCell_14o8n_178 ._arrowIcon_14o8n_202 {
  margin-left: 4px;
}
._HeaderCell_14o8n_178 ._arrowUp_14o8n_205 {
  transform: rotate(90deg);
}
._HeaderCell_14o8n_178 ._arrowDown_14o8n_208 {
  transform: rotate(-90deg);
}