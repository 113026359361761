import * as React from 'react';
import {
  Empty,
  Typography,
} from '@revfluence/fresh';
import { ListCheckIcon } from '@revfluence/fresh-icons/regular/esm';

const GuidelinesListEmptyNoProject = React.memo(() => (
  <Empty
    style={{
      alignSelf: 'center',
    }}
    image={(
      <ListCheckIcon
        color="#F0F0F0"
        width={80}
        height={80}
      />
    )}
    imageStyle={{
      height: '48px',
      marginBottom: '52px',
    }}
    description={(
      <>
        <Typography.Title level={5}>
          No project selected
        </Typography.Title>
        <Typography.Paragraph
          type="secondary"
          style={{
            fontSize: '14px',
          }}
        >
          Select a project to view and edit content guidelines.
        </Typography.Paragraph>
      </>
    )}
  />

));

GuidelinesListEmptyNoProject.displayName = 'GuidelinesListEmptyNoProject';

export default GuidelinesListEmptyNoProject;
