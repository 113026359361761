import React, { useCallback, useMemo } from 'react';
import {
 Button, IColumnsType, Space, Table, TableRowSelection,
 Tooltip,
} from '@revfluence/fresh';
import { ArrowUpRightFromSquareIcon, LinkSlashIcon } from '@revfluence/fresh-icons/regular/esm';
import { GetBrandCatalogsQuery_getBrandCatalogs } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetBrandCatalogsQuery';
import { useHistory } from 'react-router-dom';
import { pfaV2Routes } from '@frontend/app/containers/Settings/ProductFulfillment/routes';
import { useUpdateProjectConfig } from '@frontend/applications/ProductFulfillmentApp/hooks';
import { GET_PROJECT_CONFIG_BY_TYPE_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries';
import { GET_BRAND_CATALOGS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { GetStatsForAllCatalogs_getStatsForAllCatalogs } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetStatsForAllCatalogs';
import { CatalogDetails, CatalogRow } from './types';

export interface CatalogsListProps {
  projectConfigId: string;
  catalogs: GetBrandCatalogsQuery_getBrandCatalogs[];
  allCatalogStats: GetStatsForAllCatalogs_getStatsForAllCatalogs[];
  rowSelection?: TableRowSelection<CatalogRow>;
  hideActionColumn?: boolean;
}

export const CatalogsList = ({
 projectConfigId, catalogs, allCatalogStats, rowSelection, hideActionColumn,
}: CatalogsListProps) => {
  const history = useHistory();

  const { updateProjectConfig } = useUpdateProjectConfig({
    refetchQueries: [GET_PROJECT_CONFIG_BY_TYPE_QUERY, GET_BRAND_CATALOGS_QUERY],
  });

  const handleCatalogClick = useCallback(
    (id: string) => {
      history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', id));
    },
    [history],
  );

  const handleRemoveCatalog = useCallback(
    (id: string) => {
      updateProjectConfig({
        variables: {
          id: projectConfigId,
          updates: {
            brandCatalogs: catalogs.filter((catalog) => catalog.id !== Number(id)).map((catalog) => catalog.id),
          },
        },
      });
    },
    [projectConfigId, catalogs, updateProjectConfig],
  );

  const columns = useMemo<IColumnsType<CatalogRow>>(
    () => [
      {
        title: 'Catalogs',
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: 'Total Products',
        key: 'totalProducts',
        dataIndex: 'totalProducts',
      },
      {
        title: 'Total Variants',
        key: 'totalVariants',
        dataIndex: 'totalVariants',
      },
      ...(hideActionColumn
        ? []
        : [
            {
              title: 'Action',
              key: 'action',
              dataIndex: '_raw',
              render: (raw: CatalogDetails) => (
                <Space>
                  <Tooltip title="View Catalog">
                    <Button onClick={() => handleCatalogClick(raw.id)} icon={<ArrowUpRightFromSquareIcon />} />
                  </Tooltip>
                  <Tooltip title="Unlink Catalog">
                    <Button onClick={() => handleRemoveCatalog(raw.id)} icon={<LinkSlashIcon />} />
                  </Tooltip>
                </Space>
              ),
            },
          ]),
    ],
    [handleCatalogClick, handleRemoveCatalog, hideActionColumn],
  );

  const dataSource = useMemo<CatalogRow[]>(
    () =>
      catalogs.map((catalog) => {
        const stats = allCatalogStats?.find((stat) => stat.brandCatalogId === catalog.id);
        const transformedData: CatalogDetails = {
          id: String(catalog.id),
          key: String(catalog.id),
          type: catalog.type,
          status: catalog.status,
          name: catalog.name,
          totalProducts: stats?.productCount || 0,
          totalVariants: stats?.variantCount || 0,
        };
        const rowData: CatalogRow = {
          ...transformedData,
          _raw: transformedData,
        };
        return rowData;
      }),
    [allCatalogStats, catalogs],
  );

  return <Table columns={columns} dataSource={dataSource} pagination={false} rowSelection={rowSelection} />;
};
