import * as React from 'react';
import {
  Space,
  Table,
  Typography,
  Button,
  Empty,
} from '@revfluence/fresh';
import cx from 'classnames';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { TUseListTikTokSettingsAdAccountsQuery } from '@frontend/app/hooks/useListTikTokSettingsAdAccountsQuery';
import { Modal } from 'antd';
import {
  TiktokIcon,
} from '@revfluence/fresh-icons/brands/esm';
import styles from './TikTokAdAccountsTable.scss';
import { renderTikTokSparkAdsTag } from './RenderTag';
import { AdAccountLoadingState, AdAccountTableLoadingRows } from '../pages/TiktokSettingsPage';

const { Title } = Typography;

type TikTokAdAccountTableData = TUseListTikTokSettingsAdAccountsQuery['data']['tiktokSettingsListAdAccounts']['results']['0'];

interface IUpdateAccountInput {
  accountId: string,
  sparkAdsEnabled: boolean,
}

export interface ITiktokAdAccountRow {
  accountId: string,
  networkId: string,
  username: string,
  sparkAds: {
    enabled: boolean,
  },
}

interface ITikTokAdAccountsTableProps {
  tiktokAdAccountsFeatureEnabled: boolean,
  data: TikTokAdAccountTableData[],
  pagination: TablePaginationConfig,
  loadingRows: AdAccountTableLoadingRows,
  selectedTiktokSparkAdsAccount: string,
  removeAccount: (accountId: string) => void,
  updateAccount: (input: IUpdateAccountInput) => void;
}

const SparkAdsSelectConfirmModal = (selectedAccount: string, newAccount: string, confirmAction: () => void) => {
  if (selectedAccount) {
    return Modal.confirm({
      title: `Are you sure you want to select ${newAccount}?`,
      content: `Only one account can be used for Spark Ads at this time. ${selectedAccount} will be disabled from Spark Ads.`,
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: confirmAction,
    });
  }
  return confirmAction();
};

const disableTikTokAdAccountsTableButton = (loadingState?: AdAccountLoadingState, currentBtn?: 'sparkAds' | 'remove') => {
  if (currentBtn === 'sparkAds' && loadingState?.sparkAdsLoading) {
    return false;
  }
  if (currentBtn === 'remove' && loadingState?.removingLoading) {
    return false;
  }
  if (loadingState) {
    return loadingState.sparkAdsLoading || loadingState.removingLoading || false;
  }
  return false;
};

export const TikTokAdAccountsTable: React.FC<ITikTokAdAccountsTableProps> = (props) => {
  const {
    data,
    removeAccount,
    loadingRows,
    pagination,
    updateAccount,
    selectedTiktokSparkAdsAccount,
    tiktokAdAccountsFeatureEnabled,
  } = props;

  if (!tiktokAdAccountsFeatureEnabled) {
    return null;
  }

  const isEmpty = data.length === 0;

  const columns: ColumnsType<TikTokAdAccountTableData> = [
    {
      title: 'Account',
      dataIndex: 'username',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Advertiser Id',
      dataIndex: 'networkId',
      key: 'networkId',
      width: '20%',
    },
    {
      title: 'Spark Ads',
      dataIndex: 'sparkAds',
      key: 'sparkAds',
      width: '20%',
      render: (_, record) => renderTikTokSparkAdsTag(
        loadingRows[record.accountId]?.sparkAdsLoading,
        record,
        () => SparkAdsSelectConfirmModal(
          selectedTiktokSparkAdsAccount,
          record.username,
          () => {
            updateAccount({
              accountId: record.accountId,
              sparkAdsEnabled: true,
            });
          },
        ),
        () => {
          updateAccount({
            accountId: record.accountId,
            sparkAdsEnabled: false,
          });
        },
      ),
    },
    {
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            className={cx(styles.RemoveButton, {
              [styles.loading]: loadingRows[record.accountId]?.removingLoading,

            })}
            onClick={() => removeAccount(record.accountId)}
            loading={loadingRows[record.accountId]?.removingLoading}
            disabled={disableTikTokAdAccountsTableButton(loadingRows[record.accountId], 'remove')}
          >
            Remove
          </Button>
        </Space>
      ),
    },
  ];

  if (isEmpty) {
    return (
      <>
        <Title level={5} className={styles.TableTitle}>Connected TikTok Ad Accounts</Title>
        <Empty
          size="small"
          image={<TiktokIcon />}
          description={(
            <>
              <Typography.Title level={5}>
                No connected TikTok ad accounts.
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Connect TikTok ad accounts to leverage Spark Ads.
              </Typography.Paragraph>
            </>
          )}
        />
      </>
    );
  }

  return (
    <>
      <Title level={5} className={styles.TableTitle}>Connected TikTok Ad Accounts</Title>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          hideOnSinglePage: true,
          ...pagination,
        }}
        rowClassName={() => styles.RowTable}
      />
    </>
  );
};
