import { trim } from 'lodash';

import { TOfferMember } from '@affiliates/components/MemberTable/types';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';

/**
 * By default, sort the affiliates by creation date (1-minute resolution) descendingly.
 * Fall back to sorting by their name.
 * @param a IAffiliate Affiliate A.
 * @param b IAffiliate Affiliate B.
 * @return number Returns a number less than, equal to, or greater than 0 depending on
 *         whether "A" sorts before "B".
 */
export const defaultAffiliateSort = (a: TOfferMember, b: TOfferMember): number => {
  if (a.source === OFFER_SOURCE.TUNE && b.source === OFFER_SOURCE.TUNE) {
    // compare timestamps with a 1 minute resolution accuracy
    const aTimestamp = Math.floor(new Date(a.linkCreationDate).getTime() / (1000 * 60));
    const bTimestamp = Math.floor(new Date(b.linkCreationDate).getTime() / (1000 * 60));
    const timestampDiff = bTimestamp - aTimestamp;
    if (timestampDiff !== 0) {
      return timestampDiff;
    }
  }
  // ensure the name comparison is case-insensitive and ignore whitespace
  const aName = trim(a.name).toLowerCase();
  const bName = trim(b.name).toLowerCase();
  // fallback to comparing by name
  if (aName < bName) {
    return -1;
  } else if (aName > bName) {
    return 1;
  }
  return 0;
};
