/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_zy8v1_157 {
  display: flex;
}

._justify-content-space-between_zy8v1_161 {
  justify-content: space-between;
}

._tabular-nums_zy8v1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_zy8v1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentTop_zy8v1_178 ._headline6_zy8v1_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentMain_zy8v1_188 ._subtitle1_zy8v1_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._STASendItems_zy8v1_178 {
  display: flex;
  flex-direction: column;
  height: 100%;
}
._STASendItems_zy8v1_178 ._content_zy8v1_178 {
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #8f8d91;
  flex-grow: 1;
}
._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentTop_zy8v1_178 {
  margin-bottom: 2rem;
}
._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentTop_zy8v1_178 ._headline6_zy8v1_178 {
  margin-bottom: 0.5rem;
}
._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentMain_zy8v1_188 ._list_zy8v1_248 {
  padding-left: 1.25rem;
}
._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentMain_zy8v1_188 ._list_zy8v1_248 > li {
  line-height: 1.5rem;
}
._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentMain_zy8v1_188 ._composer_zy8v1_254 {
  border: 1px solid #dadcde;
  border-radius: 0.5rem;
}
._STASendItems_zy8v1_178 ._content_zy8v1_178 ._contentMain_zy8v1_188 ._composer_zy8v1_254._hide_zy8v1_258 {
  display: none;
}
._STASendItems_zy8v1_178 ._footer_zy8v1_261 {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
._STASendItems_zy8v1_178 ._footer_zy8v1_261 ._button_zy8v1_266 {
  min-width: 13.125rem;
}