import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';
import {
  isEmpty,
  isEqual,
  startCase,
  chain,
} from 'lodash';
import { message } from 'antd';

import { IResource } from '@frontend/app/hooks';
import { LoadSpinner } from '@components';
import { useResourceContext } from '@frontend/app/context';
import { ResourceType } from '@frontend/app/types/globalTypes';
import Title from '@frontend/app/components/Title/Title';
import AccountsPageEmpty from './AccountsPageEmpty';

import styles from './AccountsPage.scss';
import AccountsPageNotEmpty from './AccountsPageNotEmpty';

const { useEffect, useMemo } = React;

interface IProps {
  type: ResourceType;
  className?: string;
  title?: React.ReactNode;
}

/**
 * @type {React.FC}
 */
export const AccountsPage: React.FC<IProps> = React.memo((props) => {
  const { type } = props;
  const {
    emailResources: resources,
    loading,
  } = useResourceContext();
  const urlQuery = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const storedAddAccountFlag = localStorage.getItem('addAccountFlag');
  // use all resources here
  // because we want to show if an account is disabled by system
  const activeAccounts: IResource[] = useMemo(
    () => chain(resources)
      .filter((resource) => !resource.authProvider.userRevokedAccess && resource.type === type)
      .sortBy([
        (resource) => (resource.authProvider.isShared ? 0 : 1),
        'authProvider.userExternalId',
      ])
      .value(),
    [resources, type],
  );

  useEffect(() => {
    if (loading || !isEqual(storedAddAccountFlag, 'true')) {
      return;
    }
    const errorMessageFromQuery = urlQuery.get('error');
    if (errorMessageFromQuery) {
      message.warn(decodeURIComponent(errorMessageFromQuery));
    } else if (!isEmpty(activeAccounts)) {
      message.success(`Your ${startCase(type.toLowerCase())} account is now connected.`);
    }
    localStorage.removeItem('addAccountFlag');
    urlQuery.delete('error');
    history.replace({ search: urlQuery.toString() });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedAddAccountFlag, loading, activeAccounts]);

  return (
    <>
      { props.title && <Title title={props.title} />}
      <div className={cx(styles.AccountsPage, props.className)}>
        {loading && isEmpty(activeAccounts) && (
          <LoadSpinner />
        )}
        {!loading && isEmpty(activeAccounts) && <AccountsPageEmpty type={type} /> }
        {!isEmpty(activeAccounts) && <AccountsPageNotEmpty type={type} activeAccounts={activeAccounts} /> }
      </div>
    </>
  );
});

AccountsPage.displayName = 'AccountsPage';
