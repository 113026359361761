import { IOption } from '@components';

export enum NETWORK_RESTICTION_TYPES {
  ENGAGEMENTS = 'engagements',
  FOLLOWERS = 'followers',
  MAX_BUDGET = 'maxBudget',
  VIEWS = 'views',
  AUTHENTICITY = 'authenticity_score',
  ENGAGEMENT_RATIO = 'engagement_ratio',
  FEMALE_AUDIENCE_DEMO = 'female_demo',
  COUNTRY_DEMO = 'country_demo',
}

export enum NETWORK_TYPE {
  INSTAGRAM = 'instagram',
  TIKTOK = 'tiktok',
  PINTEREST = 'pinterest',
  YOUTUBE = 'youtube',
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
}

export const CREATOR_OFFERS = [{
  key: 'offers_payment',
  label: 'Payment upon publishing or delivery of content',
}, {
  key: 'offers_product',
  label: 'Free products / Store Credit',
}, {
  key: 'offers_commission',
  label: 'Commissions via Affiliate Program',
}, {
  key: 'offers_other',
  label: 'Other Compensation',
}];

export const CONTENT_TYPE = {
  instagram: {
    title: 'Instagram Post',
    network: NETWORK_TYPE.INSTAGRAM,
  },
  instagram_reel: {
    title: 'Instagram Reel',
    network: NETWORK_TYPE.INSTAGRAM,
  },
  instagram_video: {
    title: 'Instagram Video',
    network: NETWORK_TYPE.INSTAGRAM,
  },
  instagram_story: {
    title: 'Instagram Story',
    network: NETWORK_TYPE.INSTAGRAM,
  },
  tiktok_video: {
    title: 'TikTok Video',
    network: NETWORK_TYPE.TIKTOK,
  },
  pinterest: {
    title: 'Pinterest Pin',
    network: NETWORK_TYPE.PINTEREST,
  },
  youtube_mention: {
    title: 'YouTube Product Mention',
    network: NETWORK_TYPE.YOUTUBE,
  },
  youtube_dedicated: {
    title: 'YouTube Dedicated Video',
    network: NETWORK_TYPE.YOUTUBE,
  },
  // twitter_post: {
  //   title: 'Tweet',
  //   network: NETWORK_TYPE.TWITTER,
  // },
  // facebook_post: {
  //   title: 'Facebook Post',
  //   network: NETWORK_TYPE.FACEBOOK,
  // },
  additional_videos: {
    title: 'Video',
  },
  additional_images: {
    title: 'Image',
  },
  other: {
    title: 'Custom Work',
  },
};

export const CONTENT_TYPE_BY_NETWORK: { [key: string]: IOption[] } = Object.keys(CONTENT_TYPE).reduce((acc, key) => {
  const network = CONTENT_TYPE[key].network || 'other';
  if (acc[network] === undefined) {
    acc[network] = [];
  }

  acc[network].push({
    value: key,
    label: CONTENT_TYPE[key].title,
  });

  return acc;
}, {});

export type TFieldValue = string | number | [number, number] | Record<string, unknown>[] | unknown;
