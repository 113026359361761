/* eslint-disable */
import * as React from 'react';
import { ColumnDef, flexRender, getCoreRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { FilePenLine, RefreshCw } from 'lucide-react';

import { Button } from '@frontend/shadcn/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@frontend/shadcn/components/ui/table';

import { Card } from '@frontend/shadcn/components/ui/card';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { useGetPaymenstHistoryWithBudgetLog } from '@frontend/app/hooks/budgetAllocation/useGetPaymenstHistoryWithBudgetLog';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { PaymentStatus } from '@frontend/applications/PaymentsApp/constants';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@revfluence/fresh-icons/solid/esm';
import EditPaymentsDrawer from './EditPaymentsDrawer';
import { Checkbox } from '@frontend/shadcn/components/ui/checkbox';

const { useEffect, useState } = React;

export type Payment = {
  id: number;
  member: string;
  paymentDate: Date;
  budgetAccounts: string[];
  amountPaid: number;
  email: string;
  paypal: string;
  source: string;
  paymentStatus: PaymentStatus;
  paymentInitiatedBy: string;
  paymentTransferDate?: Date;
  programName?: string;
  programId?: number;
  projectId?: number;
  memberId?: number;
  communityId?: number;
};

const COL_NAME_TO_PROPERTY = {
  paymentDate: 'date_created',
  paypal: 'paypal',
  amountPaid: 'amount_spent',
};
const PAGE_SIZE = 100;
export const PaymentHistoryTab: React.FC = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [totalPaymentSize, setTotalPaymentSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isEditPaymentDrawerOpen, setIsEditPaymentDrawerOpen] = useState(false);

  const columns: ColumnDef<Payment>[] = [
    // Will enable with edit functionality
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ? true : table.getIsSomePageRowsSelected() ? 'indeterminate' : false
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'member',
      header: 'Member',
      enableSorting: false,
      cell: ({ row }) => <div>{row.getValue('member')}</div>,
    },
    {
      accessorKey: 'paymentDate',
      header: 'Payment Date',
      cell: ({ row }) => {
        const paymentDate = new Date(row.getValue('paymentDate'));
        const formattedDate = paymentDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        return <div>{formattedDate}</div>;
      },
    },
    {
      accessorKey: 'budgetAccounts',
      header: 'Budget Accounts',
      enableSorting: false,
      cell: ({ row }) => {
        const budgetAccounts = row.getValue('budgetAccounts') as string[];
        const extraBudgetAccounts = budgetAccounts.length > 2 ? budgetAccounts.length - 2 : 0;
        return (
          <div className="flex gap-1">
            {budgetAccounts.slice(0, 2).map((budget, index) => (
              <Badge className="rounded-md font-normal" key={index} variant="secondary">
                {budget}
              </Badge>
            ))}
            {extraBudgetAccounts > 0 && (
              <Badge className="rounded-md font-normal" variant="secondary">
                +{extraBudgetAccounts}
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'amountPaid',
      header: () => <div className="text-right w-full">Amount Paid</div>,
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue('amountPaid'));

        // Format the amount as a dollar amount
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(amount);

        return <div className="text-right">{formatted}</div>;
      },
    },
    {
      accessorKey: 'paypal',
      header: 'Paypal',
      cell: ({ row }) => <div className="lowercase">{row.getValue('paypal')}</div>,
    },
    {
      accessorKey: 'programName',
      header: 'Project',
      enableSorting: false,
      cell: ({ row }) => <div>{row.getValue('programName')}</div>,
    },
    // will enable later
    // {
    //   accessorKey: 'source',
    //   header: 'Source',
    //   cell: ({ row }) => {
    //     const source = row.getValue('source') as string;

    //     const sourceMapping = {
    //       card: {
    //         icon: CreditCard,
    //       },
    //       wallet: {
    //         icon: Wallet,
    //       },
    //     };

    //     const SourceIcon = sourceMapping[source]?.icon;

    //     return SourceIcon ? <SourceIcon className="text-gray-700]" /> : null;
    //   },
    // },
    {
      accessorKey: 'paymentStatus',
      header: 'Payment Status',
      enableSorting: false,
      cell: ({ row }) => {
        const paymentStatus = row.getValue('paymentStatus') as PaymentStatus;
        const statusMapping = {
          [PaymentStatus.PENDING]: {
            label: 'Pending Info',
            textColor: '#D48806',
            bgColor: '#FFFBE6',
          },
          [PaymentStatus.PAID]: {
            label: 'Paid',
            textColor: '#389E0D',
            bgColor: '#F6FFED',
          },
          [PaymentStatus.CANCELED]: {
            label: 'Canceled',
            textColor: '#CF1322',
            bgColor: '#FFF1F0',
          },
          [PaymentStatus.PROCESSING]: {
            label: 'Processing',
            textColor: '#096DD9',
            bgColor: '#E6F7FF',
          },
        };

        const status = statusMapping[paymentStatus];

        return status ? (
          <Badge
            className="rounded-md font-normal"
            style={{ color: status.textColor, backgroundColor: status.bgColor }}
            variant="secondary"
          >
            {status.label}
          </Badge>
        ) : null;
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const payment = row.original;

        return (
          <Button variant="outline" className="w-7 h-6" size="icon" onClick={() => onEditAndReassign(payment.id)}>
            <FilePenLine className="h-4 w-4" />
          </Button>
        );
      },
    },
  ];

  const table = useReactTable({
    data: payments,
    columns,
    getCoreRowModel: getCoreRowModel(),
    // Will enable with edit functionality
    onRowSelectionChange: setRowSelection,
    state: {
      // Will enable with edit functionality
      rowSelection,
      pagination: { pageIndex: currentPage - 1, pageSize: PAGE_SIZE },
      sorting,
    },
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil(totalPaymentSize / PAGE_SIZE),
    onSortingChange: setSorting,
  });

  const { paymentsLog, loading: paymentsHistoryLoading, refetch } = useGetPaymenstHistoryWithBudgetLog({
    variables: {
      pagination: {
        page: currentPage,
        orderBy: sorting.length > 0 ? COL_NAME_TO_PROPERTY[sorting[0].id] : null,
        orderDirection: sorting.length > 0 ? (sorting[0].desc ? 'DESC' : 'ASC') : null,
      },
    },
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [sorting]);

  useEffect(() => {
    if (!paymentsHistoryLoading) {
      const payments: Payment[] = paymentsLog?.paymentHistory.map((payment) => ({
        id: payment.paymentId,
        amountPaid: Number((payment.amount / 100).toFixed(2)),
        member: payment.memberName,
        budgetAccounts: payment.budgetAccountNames,
        email: payment?.memberEmail || '',
        paypal: payment?.paypal || '',
        paymentStatus: (payment.status || PaymentStatus.PENDING) as PaymentStatus,
        programId: payment.programId,
        programName: payment.programName,
        paymentDate: new Date(parseInt(payment.paidDate, 10) * 1000),
        paymentTransferDate: payment.transferedDate ? new Date(parseInt(payment.transferedDate, 10) * 1000) : null,
        paymentInitiatedBy: payment.paymentInitiatedBy,
        source: '',
        communityId: payment.communityId,
        projectId: payment.projectId,
        memberId: payment.memberId,
      }));
      setPayments(payments || []);
      setTotalPaymentSize(paymentsLog?.totalCount || 0);
    }
  }, [paymentsHistoryLoading, currentPage, paymentsLog]);

  useEffect(() => {
    const ids = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map((key) => payments[Number(key)].id);
    setSelectedIds(ids);
  }, [rowSelection, payments]);

  const onEditAndReassign = (paymentId?: number) => {
    if (paymentId) {
      setSelectedIds([paymentId]);
    }
    setIsEditPaymentDrawerOpen(true);
  };
  const onCloseDrawer = () => {
    setRowSelection({});
    setSelectedIds([]);
    refetch();
    setIsEditPaymentDrawerOpen(false);
  };
  const loading = paymentsHistoryLoading;

  if (loading) {
    return (
      <div className="p-8 bg-secondary">
        <Card className="p-2">
          <Skeleton className="h-8 w-full my-4" />
          <Skeleton className="h-8 w-full my-4" />
          <Skeleton className="h-8 w-full my-4" />
          <Skeleton className="h-8 w-full my-4" />
        </Card>
      </div>
    );
  }

  return (
    <div className="p-8 bg-secondary">
      <Card className="p-6">
        <div className="flex items-center justify-between py-2">
          <div>
            <span>{payments.length} Payments</span>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              className="w-8 h-8"
              size="icon"
              onClick={() => onEditAndReassign()}
              disabled={!selectedIds.length}
            >
              <RefreshCw className="text-[#5c5c5c]" size={16} />
            </Button>
            {/* Will enable later after backend server update */}
            {/* <Button variant="outline" className="w-8 h-8" size="icon">
              <Filter className="text-[#5c5c5c]" size={16} />
            </Button>
            <Button variant="outline" className="w-8 h-8" size="icon">
              <Download className="text-[#5c5c5c]" size={16} />
            </Button>
            <div className="relative ml-auto flex-1 md:grow-0">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search..."
                className="w-full rounded-lg bg-background pl-8 md:w-[200px]"
              />
            </div> */}
            <div className="flex gap-2 items-center paginationContainer">
              <span>
                {currentPage * PAGE_SIZE - PAGE_SIZE + 1}-{Math.min(currentPage * PAGE_SIZE, totalPaymentSize)} of{' '}
                {totalPaymentSize}
              </span>
              <div className="flex gap-2">
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1 || totalPaymentSize === 0}
                >
                  <ChevronLeftIcon className="text-[#5c5c5c]" fontSize={16} />
                </Button>
                <Button
                  variant="ghost"
                  className="w-8 h-8"
                  size="icon"
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalPaymentSize / PAGE_SIZE)))}
                  disabled={currentPage === Math.ceil(totalPaymentSize / PAGE_SIZE) || totalPaymentSize === 0}
                >
                  <ChevronRightIcon className="text-[#5c5c5c]" fontSize={16} />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className="cursor-pointer select-none px-1"
                  >
                    <div className="flex items-center">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getIsSorted() === 'asc' && <ChevronUpIcon className="ml-2 h-4 w-4" />}
                      {header.column.getIsSorted() === 'desc' && <ChevronDownIcon className="ml-2 h-4 w-4" />}
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
      {isEditPaymentDrawerOpen && (
        <EditPaymentsDrawer
          selectedPaymentIds={selectedIds}
          payments={payments}
          isEditPaymentDrawerOpen={isEditPaymentDrawerOpen}
          onCloseDrawer={onCloseDrawer}
        />
      )}
    </div>
  );
};
