import * as React from 'react';
import moment from 'moment';
import cx from 'classnames';
import { ArrowDownFilledIcon } from '@components';

import styles from './Deliverable.scss';

const { useState } = React;

interface IProps {
  header: JSX.Element | string;
  icon?: React.ReactNode;
  dueDate: Date;
}

export const Deliverable: React.FC<IProps> = React.memo((props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  return (
    <div className={cx(styles.Deliverable, { [styles.collapsed]: collapsed })}>
      <div className={styles.header} onClick={setCollapsed.bind(this, !collapsed)}>
        <div className={styles.arrow}>
          <ArrowDownFilledIcon size={12} />
        </div>
        {props.icon && (
          <span className={styles.icon}>{props.icon}</span>
        )}
        <span className={styles.text}>{props.header}</span>
        <span className={styles.dueDate}>{moment(props.dueDate).format('M/D/YY')}</span>
      </div>

      <div className={styles.content}>
        {props.children}
      </div>
    </div>
  );
});

Deliverable.displayName = 'RequirementDetailModalDeliverable';
