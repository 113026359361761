import * as React from 'react';
import { filter, isArray } from 'lodash';
import { format } from 'date-fns';

import { IStatisticsCardData, StatsCardPromosComponent } from '@affiliates/components';
import { OFFER_PAYOUT_TYPE } from '@affiliates/types/globalTypes';
import { DataFormat, formatMoney } from '@affiliates/utils';
import {
  IAddTrackingLinksMembers,
  TSelectedMember,
  TMode,
} from '../types';

import styles from './MembersWizard.scss';

const { useMemo } = React;
interface IProps {
  mode: TMode;
  members: readonly TSelectedMember[] | null;
  payoutType: IAddTrackingLinksMembers['payoutType'];
  flatPayout: IAddTrackingLinksMembers['flatPayout'];
  expirationDate?: IAddTrackingLinksMembers['expirationDate'];
  percentPayout: IAddTrackingLinksMembers['percentPayout'];
}

export const StatsCardLinks: React.FC<Readonly<IProps>> = (props) => {
  const {
    members,
    mode,
    payoutType,
    expirationDate,
    flatPayout,
    percentPayout,
  } = props;
  const stats: IStatisticsCardData[] = [];
  const prefix = useMemo(() => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.CONVERSION:
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
      case OFFER_PAYOUT_TYPE.CLICK:
        return '$';
      default:
        return '';
    }
  }, [payoutType]);
  const suffix = useMemo(() => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.SALE:
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return '%';
      default:
        return '';
    }
  }, [payoutType]);
  const payoutTypeName = useMemo(() => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.SALE:
        return 'Payout per Sale';
      case OFFER_PAYOUT_TYPE.CONVERSION:
        return 'Payout per Conversion';
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return 'Payout per Conversion + Commission per Sale';
      case OFFER_PAYOUT_TYPE.CLICK:
        return 'Payout per Click';
      default:
        return '';
    }
  }, [payoutType]);
  const value = useMemo(() => {
    switch (payoutType) {
      case OFFER_PAYOUT_TYPE.SALE:
        return percentPayout;
      case OFFER_PAYOUT_TYPE.CONVERSION:
      case OFFER_PAYOUT_TYPE.CLICK:
        return formatMoney(flatPayout);
      case OFFER_PAYOUT_TYPE.CONVERSION_AND_SALE:
        return `${formatMoney(flatPayout)} + ${percentPayout}`;
      default:
        return '';
    }
  }, [flatPayout, payoutType, percentPayout]);
  stats.push(
    {
      format: DataFormat.None,
      prefix,
      suffix,
      title: payoutTypeName,
      value: `${value}`.toLocaleString(),
    },
);
  const newMemberCount = isArray(members) ? filter(members, (m) => m.selected).length : 0;
  let memberTitle = 'Members to Add';
  switch (mode) {
    case 'refresh':
      memberTitle = 'Links Getting Reactivated';
      break;
    case 'deactivate':
      memberTitle = 'Links to Deactivate';
      break;
  }
  if (newMemberCount < 1) {
    stats.push({
      format: DataFormat.None,
      title: memberTitle,
      value: 'TBD',
    });
  } else {
    stats.push({
      format: DataFormat.Integer,
      title: memberTitle,
      value: newMemberCount,
    });
  }
  if (mode === 'add') {
    stats.push({
      format: DataFormat.None,
      title: 'Link Expiration Date',
      value: format(new Date(expirationDate), 'MMM d, yyyy @ha'),
    });
  }
  return (
    <StatsCardPromosComponent className={styles.statsCard} data={stats} />
  );
};
