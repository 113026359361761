import * as React from 'react';
import { isNil } from 'lodash';
import { Notice } from '@affiliates/AspireUI';
import { logger } from '@common';
import { TTask } from '@frontend/app/containers/Projects/types';

import {
  EmailFilledIcon,
  LightningFilledIcon,
} from '@frontend/app/components';

import styles from './WorkflowNotice.scss';

const { useState, useEffect } = React;
interface IProps {
  programId?: number;
  workletSpecKey: string;
  taskMetaData: TTask['taskMetaData'];
  taskId?: string;
  memberIds?: number[];
  clientName?: string;
}

const matchWelcomeEmailWorkletSpecKeys = [
  'UID_e80552e8-f105-4dd4-bdde-f082f8fd35b1', // dev
  'UID_ba236371-321c-4e59-9169-c76e0d5d67d7', // prod
];

export const WorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
  const {
    workletSpecKey,
    taskMetaData,
  } = props;

  const [workflowNoticeMessage, setWorkflowNoticeMessage] = useState<React.ReactNode>(null);

  useEffect(() => {
    if (isNil(taskMetaData.serviceId)) {
      if (matchWelcomeEmailWorkletSpecKeys.includes(workletSpecKey)) {
        const sendWelcomeMailInfoText = (
          <span>
            Select the creators you want to send the welcome email to and click the
            {' '}
            <EmailFilledIcon size={16} className={styles.messageWrapperInlineIcon} />
            {' '}
            icon. Click the
            {' '}
            <LightningFilledIcon size={16} className={styles.messageWrapperInlineIcon} />
            {' '}
            Mark as Done button to move creators forward in the project.
          </span>
        );
        setWorkflowNoticeMessage(sendWelcomeMailInfoText);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskMetaData, workletSpecKey, matchWelcomeEmailWorkletSpecKeys]);

  if (!matchWelcomeEmailWorkletSpecKeys.includes(workletSpecKey)) {
    logger.error(`Project WorkflowNotice does not recognize workletSpecKey ${workletSpecKey}`);
    return null;
  }

  return (
    <Notice
      type="info"
      message={workflowNoticeMessage}
    />
  );
};
