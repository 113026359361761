import { useMutation, MutationHookOptions } from '@apollo/client';
import { SAVE_MEMBER_COMMENT } from '@frontend/app/queries';

import {
  SaveMemberCommentMutation,
  SaveMemberCommentMutationVariables,
} from '@frontend/app/queries/types/SaveMemberCommentMutation';

type IOptions = MutationHookOptions<SaveMemberCommentMutation, SaveMemberCommentMutationVariables>;

export const useSaveMemberCommentMutation = (options: IOptions = {}) => useMutation<SaveMemberCommentMutation, SaveMemberCommentMutationVariables>(SAVE_MEMBER_COMMENT, options);
