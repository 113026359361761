import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';
import {
  analyticsServerApiEndpoint,
  backendServerApiEndpoint,
  backendServerWebEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import * as React from 'react';
import { IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import { CreateArchiveAppModal } from './CreateArchiveAppModal';

interface IProps {
  clientId: string;
  clientName: string;
  memberId: string;
  workflowActionParameters?: IWorkflowActionParameters;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deepLinkParameters?: any;
  closeModal?(): void;
}

const CreateArchiveApp: React.FC<IProps> = ({
  clientId,
  clientName,
  memberId,
  closeModal,
  workflowActionParameters,
  deepLinkParameters,
}) => (
  <ApplicationProvider
    backendServerApiEndpoint={backendServerApiEndpoint()}
    analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
    backendServerWebEndpoint={backendServerWebEndpoint()}
    clientId={clientId}
    clientName={clientName}
    memberId={memberId}
    closeModal={closeModal}
    workflowActionParameters={workflowActionParameters}
  >
    <CreateArchiveAppModal deepLinkParameters={deepLinkParameters} />
  </ApplicationProvider>
  );

export default CreateArchiveApp;
