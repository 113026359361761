/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1mcqz_157 {
  display: flex;
}

._justify-content-space-between_1mcqz_161 {
  justify-content: space-between;
}

._tabular-nums_1mcqz_165 {
  font-variant-numeric: tabular-nums;
}

._AudienceDropdown_1mcqz_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1mcqz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._AudienceDropdown_1mcqz_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._AudienceDropdown_1mcqz_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._AudienceDropdown_1mcqz_169 ::-webkit-scrollbar-track, ._AudienceDropdown_1mcqz_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._AudienceDropdown_1mcqz_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AudienceDropdown_1mcqz_169 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}
._AudienceDropdown_1mcqz_169 ._customText_1mcqz_241 {
  color: #1a1818;
  align-items: center;
  display: flex;
  margin-left: 15px;
  font-size: 12px;
}
._AudienceDropdown_1mcqz_169 ._customLabel_1mcqz_248 {
  color: #3996e0;
  align-items: center;
  display: flex;
}
._AudienceDropdown_1mcqz_169 ._customLabel_1mcqz_248 button {
  color: inherit;
  background: none;
  padding: 4px;
  text-decoration: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
  white-space: nowrap;
  vertical-align: middle;
  font-size: 12px;
}
._AudienceDropdown_1mcqz_169 ._customLabel_1mcqz_248 ._arrow_1mcqz_265 {
  color: #8f8d91;
}
._AudienceDropdown_1mcqz_169 ._select_1mcqz_268 {
  margin-left: 15px;
}
._AudienceDropdown_1mcqz_169 ._audienceIcon_1mcqz_271 {
  margin-right: 10px;
  margin-top: -1px;
  color: #8f8d91;
}
._AudienceDropdown_1mcqz_169._disabled_1mcqz_276 {
  opacity: 0.5;
}
._AudienceDropdown_1mcqz_169._disabled_1mcqz_276 ._customLabel_1mcqz_248 {
  cursor: default !important;
}
._AudienceDropdown_1mcqz_169:not(._disabled_1mcqz_276) ._customLabel_1mcqz_248:hover {
  text-decoration: underline;
  cursor: pointer;
}