import * as React from 'react';
import {
 cloneDeep, filter, find, forEach, isArray, isFunction, map, uniqBy, orderBy, flattenDeep,
} from 'lodash';

import {
  IMemberProgram,
  IProspect,
} from '@components';

import {
  GetAllProjectsQuery_projects as IProject,
} from '@frontend/app/queries/types/GetAllProjectsQuery';
import { TProspectProgramStatus } from '@revfluence/widgets/lib/common/models/program';
import { useInviteContext } from './useInviteContext';
import { useInviteMember } from './useInviteMember';
import { usePrograms } from './usePrograms';
import { useResources } from './useResources';
import { useUpdateMemberPrograms } from './useUpdateMemberPrograms';
import { IInviteProps } from '../Invite';

const { useCallback, useEffect, useMemo } = React;

type TProps = Pick<IInviteProps, 'onInvite' | 'preloadProspects' | 'communities' | 'prospect' | 'projects' | 'isFetchingCommunities' | 'isFetchingAllPrograms'>;

export const useInvite = (props: TProps) => {
  const {
    onInvite,
    preloadProspects,
    prospect,
    communities,
    projects,
    isFetchingAllPrograms,
    isFetchingCommunities,
  } = props;

  const { updateMemberPrograms } = useInviteContext();
  const {
    loading: isFetchingResources,
    resourceId,
  } = useResources();
  const {
    isFetching: isFetchingPrograms,
    programs,
  } = usePrograms({
    communities,
    isFetchingCommunities,
  });

  const {
    isFetching: isFetchingProspectPrograms,
  } = useUpdateMemberPrograms(preloadProspects);
  const {
    sendInvite,
    ...inviteStatus
  } = useInviteMember({ prospect });

  const allPrograms: IProject[] = useMemo(() => (
    orderBy(flattenDeep(projects), ['title'], ['asc'])
  ), [projects]);

  /**
   * Update programs on invite
   */
  const programIds = map(allPrograms, (p) => p.id).sort().join(',');
  const updateProgramStatusForProspects = useCallback(
    (
      invitedProspect: IInviteProps['prospect'],
      programId: IMemberProgram['id'],
      membershipStatus: TProspectProgramStatus,
    ) => {
      const prospects: IProspect[] = isArray(invitedProspect) ? invitedProspect : [invitedProspect];
      const updatedPrograms: IMemberProgram[] = map(prospects, (prospect) => {
        const program = find(allPrograms, (program) => program.id === programId) as unknown as IMemberProgram;
        return {
          ...cloneDeep(program),
          username: prospect.socialAccount.username,
          social_account_id: prospect.socialAccount.id,
          status: membershipStatus,
        };
      });
      if (updatedPrograms.length > 0) {
        if (isFunction(onInvite)) {
          onInvite(invitedProspect, uniqBy(updatedPrograms, (p) => p.title));
        }
        forEach(
          prospects,
          ({ socialAccount }) => updateMemberPrograms(
            socialAccount.id,
            filter(
              updatedPrograms,
              (program) => (
                program.username
                  ? program.username === socialAccount.username
                  : program.social_account_id === socialAccount.id
              ),
            ),
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [programIds],
  );

  useEffect(() => {
    if (inviteStatus && inviteStatus.isInvited) {
      updateProgramStatusForProspects(inviteStatus.prospect, inviteStatus.programId, 'invited');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteStatus.isInvited]);

  return {
    programs,
    resourceId,
    inviteStatus,
    sendInvite,
    allPrograms,

    isFetching: (
      isFetchingCommunities
      || isFetchingPrograms
      || isFetchingResources
      || isFetchingProspectPrograms
      || isFetchingAllPrograms
    ),
    error: (
      inviteStatus.error
    ),
  };
};
