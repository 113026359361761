import * as React from 'react';
import { Tooltip as FreshTooltip } from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './EstImpressionsLabel.scss';

interface IEstImpressionsLabel {
  onlyIcon?: boolean,
  iconSize?: number,
}

export const EstImpressionsLabel = ({ onlyIcon = false, iconSize }: IEstImpressionsLabel) => (
  <div className={styles.EstImpressionsLabel}>
    {!onlyIcon && <div style={{ width: 'max-content' }}>Est. Impressions</div>}
    <FreshTooltip title="Estimated impressions are calculated from the creator’s follower count if they have not authenticated their Instagram account with Aspire.">
      <CircleInfoIcon fontSize={iconSize} />
    </FreshTooltip>
  </div>
);
