import { useMutation, MutationHookOptions } from '@apollo/client';

import { REJECT_ORDER_REQUEST_MUTATION } from '../queries';
import {
  RejectOrderRequestMutation,
  RejectOrderRequestMutationVariables,
} from '../queries/types/RejectOrderRequestMutation';

type IOptions = MutationHookOptions<RejectOrderRequestMutation, RejectOrderRequestMutationVariables>;

export function useRejectOrderRequest(options: IOptions = {}) {
  const [rejectOrderRequest, { loading, error }] = useMutation<
    RejectOrderRequestMutation,
    RejectOrderRequestMutationVariables
  >(REJECT_ORDER_REQUEST_MUTATION, options);

  return {
    rejectOrderRequest,
    loading,
    error,
  };
}
