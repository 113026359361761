import * as React from 'react';

import { Spin, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
 find, identity, isArray, map, uniqBy,
} from 'lodash';

import { Select } from '@revfluence/fresh';

import { IPlaceId } from '@services/backend-server';
import usePlaceSearch from '@frontend/app/hooks/usePlaceSearch';

import commonStyles from '../../common.scss';

const { useMemo, useState } = React;

interface ILocationFieldComponent {
  placeholder: string;
  onChange: (places: IPlaceId[]) => void;
  value: IPlaceId[];
}

const LocationFieldComponent: React.FC<ILocationFieldComponent> = (props) => {
  const {
    placeholder,
    onChange,
    value = [],
  } = props;

  const [locationSearchValue, setLocationSearchValue] = useState(undefined);

  const {
    isFetching,
    results = [],
  } = usePlaceSearch({
    search: locationSearchValue,
  });

  const parsedResults = useMemo(() => {
    const resultsMap = map(results, (result) => ({
      label: result.formattedAddress,
      value: result.id,
    }));

    const parsedValue = isArray(value)
      ? value
      : [];

    return uniqBy([...parsedValue, ...resultsMap], 'value');
  }, [results, value]);

  const handleChange = (values: string[]) => {
    const mappedValues = map(values, (id: string) => find(parsedResults, ['value', id])).filter(identity);

    onChange(mappedValues);
    setLocationSearchValue(undefined);
  };

  const valuesIds = useMemo(() => map(value, 'value'), [value]);

  const renderTag = (tagProps) => {
    const { value, closable, onClose } = tagProps;

    const label = find(parsedResults, ['value', value])?.label || value;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        key={`tag_${value.value}`}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <>
      <Select
        showSearch
        allowClear
        mode="multiple"
        searchValue={locationSearchValue}
        value={valuesIds}
        options={parsedResults}
        onSearch={setLocationSearchValue}
        onChange={handleChange}
        tagRender={renderTag}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        placeholder={placeholder}
        virtual={false}
        style={{ width: '100%' }}
      />
      <div className={commonStyles.inputSpinner}>
        {
          isFetching && (
            <Spin
              indicator={<LoadingOutlined />}
            />
          )
        }
      </div>
    </>
  );
};

const LocationField = React.memo(LocationFieldComponent);

export default LocationField;
