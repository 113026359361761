import * as React from 'react';
import {
  Button,
  Drawer,
  IDrawerProps,
} from '@revfluence/fresh';
import { XmarkIcon } from '@revfluence/fresh-icons/solid/esm';
import { isFunction } from 'lodash';

import styles from '@frontend/applications/TermsApp/components/shared/Wizard/TermsWizard.scss';
import { useGetTermsName } from '@frontend/app/hooks';

export const TermsDrawer: React.FC<IDrawerProps> = (props) => {
  const {
    children,
    onClose,
    title,
    ...remainingProps
  } = props;
  const { singularTermsName } = useGetTermsName();
  const closeButton = () => {
    if (!isFunction(onClose)) {
      return null;
    }

    return (
      <Button
        icon={<XmarkIcon />}
        onClick={onClose}
        type="text"
      />
    );
  };

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      className={styles.TermsWizard}
      closable={false}
      width={1024}
      onClose={onClose}
      {...remainingProps}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerContentWrapper} />
          <div className={styles.headerContentWrapper}>
            <img
              alt={`Send ${singularTermsName}`}
              className={styles.titleIcon}
              src="https://storage.googleapis.com/aspirex-static-files/terms.svg"
            />
            {title}
          </div>
          <div className={styles.headerContentWrapper}>
            {closeButton()}
          </div>
        </div>
        {children}
      </div>
    </Drawer>
  );
};
