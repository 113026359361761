import 'css-chunk:src/app/components/UploadCSVModal/steps/UploadFile.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_tykkb_157",
  "justify-content-space-between": "_justify-content-space-between_tykkb_161",
  "tabular-nums": "_tabular-nums_tykkb_165",
  "table": "_table_tykkb_355",
  "footer": "_footer_tykkb_396",
  "UploadFile": "_UploadFile_tykkb_412",
  "art": "_art_tykkb_420",
  "requirements": "_requirements_tykkb_432",
  "hasError": "_hasError_tykkb_449",
  "xcircleIcon": "_xcircleIcon_tykkb_453",
  "checkIcon": "_checkIcon_tykkb_454",
  "upload": "_upload_tykkb_461",
  "button": "_button_tykkb_464",
  "loading": "_loading_tykkb_468",
  "spinnerIcon": "_spinnerIcon_tykkb_473",
  "downloadTemplateLink": "_downloadTemplateLink_tykkb_476",
  "show": "_show_tykkb_1"
};