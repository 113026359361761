import { MutationHookOptions } from '@apollo/client';

import { useMutation } from '@frontend/app/hooks';
import { REFRESH_CLIENT_SOCIAL_ANALYTICS } from '@frontend/app/queries/RefreshClientSocialAnalyticsMutation';
import { RefreshClientSocialAnalyticsMutation } from '@frontend/app/queries/types/RefreshClientSocialAnalyticsMutation';

type IOptions = MutationHookOptions<RefreshClientSocialAnalyticsMutation>;

export const useRefreshClientSocialAnalytics = (options: IOptions = {}) =>
  useMutation(REFRESH_CLIENT_SOCIAL_ANALYTICS, options);
