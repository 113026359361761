/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._LeaveCommentSection_1lci0_32 ._editMode_1lci0_32 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._LeaveCommentSection_1lci0_32 ._editMode_1lci0_32 ._input_1lci0_37 {
  flex: 1;
}
._LeaveCommentSection_1lci0_32 ._editMode_1lci0_32 ._submitButton_1lci0_40 {
  margin-left: 10px;
}
._LeaveCommentSection_1lci0_32 ._editMode_1lci0_32 ._cancelButton_1lci0_43 {
  margin-left: 10px;
}
._LeaveCommentSection_1lci0_32 ._displayMode_1lci0_46 {
  padding: 25px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 1px #e6edf2;
}
._LeaveCommentSection_1lci0_32 ._displayMode_1lci0_46 ._messageIcon_1lci0_55 {
  flex-basis: 24px;
  margin-right: 15px;
}
._LeaveCommentSection_1lci0_32 ._displayMode_1lci0_46 ._feedback_1lci0_59 {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
}
._LeaveCommentSection_1lci0_32 ._displayMode_1lci0_46 ._feedback_1lci0_59 ._feedbackTitle_1lci0_66 {
  margin-bottom: 4px;
  font-weight: bold;
}
._LeaveCommentSection_1lci0_32 ._displayMode_1lci0_46 ._actions_1lci0_70 {
  margin-left: 15px;
  flex-basis: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
._LeaveCommentSection_1lci0_32 ._displayMode_1lci0_46 ._actions_1lci0_70 ._icon_1lci0_78 {
  cursor: pointer;
  color: gray;
  will-change: color;
  transition: color 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._LeaveCommentSection_1lci0_32 ._displayMode_1lci0_46 ._actions_1lci0_70 ._icon_1lci0_78:hover {
  color: #3996e0;
}