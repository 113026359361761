import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import {
  GET_ALL_PROJECTS,
  GET_ALL_PROJECTS_WITH_NOTIFICATIONS,
  GET_ALL_PROJECTS_WITH_DELETED_COUNT,
  GET_ALL_PUBLISHED_PROJECTS,
  GET_ALL_PROJECTS_WITH_CONTENT_GUIDELINES,
  GET_ALL_CONTENT_APPROVAL_MANAGER_PROJECTS,
} from '@frontend/app/queries';
import {
  GetAllProjectsQuery,
  GetAllProjectsQueryVariables,
} from '@frontend/app/queries/types/GetAllProjectsQuery';
import { GetAllProjectsWithNotificationsQuery } from '@frontend/app/queries/types/GetAllProjectsWithNotificationsQuery';
import { GetAllProjectsWithDeletedCountQuery } from '@frontend/app/queries/types/GetAllProjectsWithDeletedCountQuery';
import { GetAllPublishedProjectsQuery } from '@frontend/app/queries/types/GetAllPublishedProjectsQuery';
import { GetAllProjectsWithContentGuidelinesQuery } from '@frontend/app/queries/types/GetAllProjectsWithContentGuidelinesQuery';
import {
  GetAllContentApprovalManagerProjectsQuery,
  GetAllContentApprovalManagerProjectsQueryVariables,
} from '@frontend/app/queries/types/GetAllContentApprovalManagerProjectsQuery';

type IOptions = QueryHookOptions<GetAllProjectsQuery, GetAllProjectsQueryVariables>;

export const useGetAllProjectsQuery = (options: IOptions = {}) => (
  useQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(GET_ALL_PROJECTS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: false,
  })
);

export const useGetAllProjectsWithNotificationsQuery = () => (
  useQuery<GetAllProjectsWithNotificationsQuery>(GET_ALL_PROJECTS_WITH_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
  })
);

export const useGetAllProjectsWithDeletedCountQuery = () => (
  useQuery<GetAllProjectsWithDeletedCountQuery>(GET_ALL_PROJECTS_WITH_DELETED_COUNT)
);

export const useGetAllPublishedProjectsQuery = () => (
  useQuery<GetAllPublishedProjectsQuery>(GET_ALL_PUBLISHED_PROJECTS, {
    fetchPolicy: 'no-cache',
  })
);

export const useGetAllContentApprovalManagerProjectsQuery = (options: QueryHookOptions<GetAllContentApprovalManagerProjectsQuery, GetAllContentApprovalManagerProjectsQueryVariables> = {}) => (
  useQuery<GetAllContentApprovalManagerProjectsQuery, GetAllContentApprovalManagerProjectsQueryVariables>(GET_ALL_CONTENT_APPROVAL_MANAGER_PROJECTS, {
    ...options,
    fetchPolicy: 'no-cache',
  })
);

export const useGetAllProjectsWithContentGuidelinesQuery = (options: QueryHookOptions<GetAllProjectsWithContentGuidelinesQuery> = {}) => (
  useQuery<GetAllProjectsWithContentGuidelinesQuery>(GET_ALL_PROJECTS_WITH_CONTENT_GUIDELINES, {
    ...options,
    fetchPolicy: 'no-cache',
  })
);
