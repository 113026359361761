/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ScrollableMask_1p09i_32 {
  z-index: 3;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}
._ScrollableMask_1p09i_32._active_1p09i_44 {
  display: flex;
}
._ScrollableMask_1p09i_32._dragging_1p09i_47 {
  cursor: grabbing;
  pointer-events: auto;
}
._ScrollableMask_1p09i_32 ._button_1p09i_51 {
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  color: white;
  border: 1px solid #e2e2e2;
  border-radius: 100px;
  background-color: #3996e0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  opacity: 0;
  will-change: opacity, background-color;
  transition: all 0.15s ease-out;
}
._ScrollableMask_1p09i_32 ._button_1p09i_51._right_1p09i_69 {
  margin-left: auto;
}
._ScrollableMask_1p09i_32 ._button_1p09i_51._active_1p09i_44 {
  pointer-events: auto;
  opacity: 0.6;
}
._ScrollableMask_1p09i_32 ._button_1p09i_51._active_1p09i_44:hover {
  opacity: 0.9;
  background-color: #4fa7ee;
}
._ScrollableMask_1p09i_32 ._button_1p09i_51._active_1p09i_44:active {
  opacity: 0.6 !important;
  background-color: #3996e0 !important;
}
._ScrollableMask_1p09i_32 ._button_1p09i_51 ._arrowRight_1p09i_84 {
  transform: rotate(180deg);
}
._ScrollableMask_1p09i_32 ._scrollbar_1p09i_87 {
  position: absolute;
  bottom: 0;
  width: 100%;
  cursor: pointer;
  pointer-events: auto;
  overflow: hidden;
  height: 6px;
  border-radius: 100px;
  transform-origin: center bottom;
  will-change: transform, background-color;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._ScrollableMask_1p09i_32 ._scrollbar_1p09i_87._dragging_1p09i_47, ._ScrollableMask_1p09i_32 ._scrollbar_1p09i_87:hover {
  transform: scaleY(2);
  background-color: #e9e8ea;
}
._ScrollableMask_1p09i_32 ._scrollbar_1p09i_87._dragging_1p09i_47 {
  cursor: grabbing;
}
._ScrollableMask_1p09i_32 ._scrollbar_1p09i_87._dragging_1p09i_47 ._thumb_1p09i_107 {
  cursor: grabbing;
}
._ScrollableMask_1p09i_32 ._scrollbar_1p09i_87 ._thumb_1p09i_107 {
  height: 100%;
  cursor: grab;
  background-color: #c6c6c6;
  border-radius: 100px;
  will-change: transform;
  transition: transform 0.3s ease-out;
}