import * as React from 'react';

import { BarGroup as BarGroupInternal, IBarGroupProps } from './BarGroup';

interface IProps extends IBarGroupProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const BarGroup: React.FunctionComponent<IProps> = React.memo((props) => {
  const { height, width, ...restProps } = props;
  return <BarGroupInternal height={height} width={width} {...restProps} />;
});

BarGroup.displayName = 'BarGroup';
