import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';

import {
  ArrowLeftIcon,
  CloseIcon,
  SpinnerIcon,
  StarBorderIcon,
  StarIcon,
} from '@components';
import { SubmitButton } from '@components';
import { IconSize } from '@components';
import { Input } from '@components';
import { Popover } from '@components';
import { IFavoriteList } from '@components';

const { useState, useCallback, useRef } = React;

import styles from './FavoriteListButton.scss';

interface IProps {
  className?: string;
  addToFavoriteList(listId: number): Promise<void>;
  createFavoriteList(name: string): Promise<void>;
  favoriteLists: IFavoriteList[];
  /**
   * Icon size
   * default: 18px
   */
  size?: IconSize | number;
}

type TEditListMode = 'add';

export const FavoriteListButton: React.FunctionComponent<IProps> = (props) => {
  const starRef = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState<TEditListMode>(null);
  const [newListName, setNewListName] = useState<string>('');
  const [showPopover, setShowPopover] = useState(false);
  const [isAddingNewList, setIsAddingNewList] = useState(false);
  const [isAddingToList, setIsAddingToList] = useState(false);

  // handleOptionClick relies on latest favorites list
  const latestFavoriteLists = useRef(props.favoriteLists);
  latestFavoriteLists.current = props.favoriteLists;

  const openPopover = useCallback(() => setShowPopover(true), []);

  const closePopover = useCallback(() => setShowPopover(false), []);

  const setEditModeAdd = useCallback(() => setEditMode('add'), []);

  const unsetEditMode = useCallback(() => {
    setEditMode(null);
    setNewListName('');
  }, []);

  const onNewListNameChange = useCallback((newListName) => setNewListName(newListName), []);

  /**
   * @private
   * Adds the creator to a list.
   *
   * @param {Number} index the selected list index.
   */
  const handleOptionClick = (index: number) => {
    const favoriteLists = latestFavoriteLists.current;
    const selectedList = favoriteLists[index];

    setIsAddingToList(true);
    return props.addToFavoriteList(selectedList.id).finally(() => {
      setIsAddingToList(false);

      closePopover();
    });
  };

  const confirmAddList = () => {
    const { favoriteLists, createFavoriteList } = props;
    const newIndex = favoriteLists.length;

    setIsAddingNewList(true);
    createFavoriteList(newListName)
      .then(() => {
        setEditMode(null);
        setNewListName('');
        setIsAddingNewList(false);

        return handleOptionClick(newIndex);
      })
      .catch(() => {
        setEditMode(null);
        setNewListName('');
        setIsAddingNewList(false);

        closePopover();
      });
  };

  return (
    <div className={cx(styles.FavoriteListButton, props.className)}>
      <div
        ref={starRef}
        className={cx(styles.saveFavorite, {
          [styles.active]: showPopover,
        })}
        onClick={openPopover}
      >
        <StarBorderIcon size={props.size} className={styles.starBorder} />
        <StarIcon size={props.size} className={styles.star} />
      </div>
      <Popover
        mountRef={starRef}
        minWidth={300}
        show={showPopover}
        onRequestClose={closePopover}
        contentClassName={styles.content}
        className={styles.FavoriteListPopover}
      >
        {!editMode && (
          <>
            <div className={styles.header}>
              {isAddingToList ? 'Adding to List' : 'Add to Your Favorites'}
              {isAddingToList && <SpinnerIcon className={styles.spinner} size={20} />}
            </div>
            <div className={styles.list}>
              {map(props.favoriteLists, (favoriteList, index) => (
                <div
                  key={index}
                  className={cx(styles.option, {
                    [styles.disabled]: isAddingToList,
                  })}
                  onClick={() => handleOptionClick(index)}
                >
                  <div className={styles.label}>
                    {favoriteList.name}
                    {' '}
                    (
                    {favoriteList.element_count}
                    )
                  </div>
                </div>
              ))}
            </div>
            <div
              className={cx(styles.addList, {
                [styles.disabled]: isAddingToList,
              })}
              onClick={setEditModeAdd}
            >
              <div className={styles.addIconWrapper}>
                <CloseIcon size={12} className={styles.addIcon} />
              </div>
              New List...
            </div>
          </>
        )}
        {editMode === 'add' && (
          <div className={styles.editList}>
            <div className={styles.back} onClick={unsetEditMode}>
              <ArrowLeftIcon className={styles.arrowIcon} />
              Back to Existing Lists
            </div>
            <Input
              className={styles.editContent}
              onChange={onNewListNameChange}
              onPressEnter={confirmAddList}
              defaultValue={newListName}
              focusOnMount
              placeholder="Create and add to list..."
            />
            <SubmitButton
              className={styles.button}
              label="Create"
              submittingLabel="Creating..."
              isSubmitting={isAddingNewList}
              onClick={confirmAddList}
              disabled={isEmpty(newListName) || isAddingNewList}
              fullWidth
              round={false}
            />
          </div>
        )}
      </Popover>
    </div>
  );
};

FavoriteListButton.defaultProps = {
  size: 18,
};
FavoriteListButton.displayName = 'FavoriteListButton';
