import { DataFormat } from '@affiliates/utils';

// interface IValue {
//   valueInUsd?: number;
//   valueInBase?: number;
//   currency?: string;
// }

export interface IStatisticsCardData {
  title: string;
  showTime?: boolean;
  // value: number | string | IValue;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  value: any;
  prefix?: string;
  suffix?: string;
  format: DataFormat;
}
