import * as React from 'react';
import {
  ClipboardUserIcon,
  ImageIcon,
  UserIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useHistory } from 'react-router-dom';
import * as qs from 'qs';
import {
  orderBy,
  reduce,
  startCase,
} from 'lodash';

import {
  Card as FreshCard,
  List,
  Button,
  Row,
  Col,
  Empty,
} from '@revfluence/fresh';
import { useFetchSummaryData } from '@frontend/components/pages/AnalyzePage/useFetchAnalyzeData';
import { initialDateState } from '@frontend/components/pages/AnalyzePage/Filters/DateFilter';
import { analyticsServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { endpoints, LazyImage } from '@components';

import {
  SOCIAL_POST_APP_ID,
} from '@frontend/app/constants/applicationIds';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import { Empty as RefreshEmpty } from '@frontend/app/refresh-components';
import Card from '../Card';
import TopPostMeta, { ITopPost } from './TopPostMeta';
import TopCreatorMeta, { ITopCreator } from './TopCreatorMeta';
import {
  WidgetTopPerformingContentLoading as LoadingSkeleton,
} from './WidgetTopPerformingContentLoading';

import styles from './WidgetTopPerformingContent.module.scss';

interface IProps {
  projectId: number;
  clientId: string;
}

const { useMemo } = React;

const WidgetTopPerformingCard: React.FC = ({ children }) => {
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  if (isRefreshUIEnabled) {
    return (
      <ShadCnCard>
        <CardHeader>
          <div className="flex items-center gap-2">
            <ClipboardUserIcon fontSize={16} className="text-icon-grey" />
            <span className="font-medium text-base">
              Top Performing Content
              <span className="text-xs whitespace-nowrap overflow-hidden text-ellipsis ml-2 text-subtitle">
                Last 30 Days
              </span>
            </span>
          </div>
        </CardHeader>
        <CardContent className="pt-4 pb-0">
          {children}
        </CardContent>
      </ShadCnCard>
    );
  }

  return (
    <Card
      title="Top Performing Content"
      subtitle="Last 30 Days"
      icon={<ClipboardUserIcon />}
      bodyStyle={{ padding: '0' }}
    >
      {children}
    </Card>
  );
};

const WidgetTopPerformingContent: React.FC<IProps> = React.memo((props) => {
  const { projectId, clientId } = props;
  const history = useHistory();
  const filters = {
    clientId,
    datePosted: initialDateState(),
    generic: {
      programIds: [projectId],
    },
  };
  const topPosts = useFetchSummaryData<ITopPost[]>(
    `${analyticsServerApiEndpoint()}/${endpoints.reportsEndpoint}/summary/top-posts`,
    false,
    filters,
  );
  const topCreators = useFetchSummaryData<ITopCreator[]>(
    `${analyticsServerApiEndpoint()}/${endpoints.reportsEndpoint}/summary/top-creators`,
    false,
    filters,
  );

  const isRefreshUiEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const emptyComponent = (componentType: string) => {
    const imageIcon = componentType === 'posts'
      ? <ImageIcon color="#F0F0F0" />
      : <UserIcon color="#F0F0F0" />;

    const imageIconComp = componentType === 'posts'
      ? ImageIcon
      : UserIcon;

    if (isRefreshUiEnabled) {
      return (
        <RefreshEmpty
          icon={imageIconComp}
          description={`Top performing ${componentType} will appear here.`}
        />
      );
    }

    return (
      <Empty
        image={imageIcon}
        size="small"
        style={{
          color: '#8C8C8C',
        }}
        description={`Top performing ${componentType} will appear here.`}
      />
    );
  };

  const summedPostMetrics = useMemo(() => reduce(
    topPosts.data,
    (sumMetrics, post) => ({
      engagements: sumMetrics.engagements + Number(post.engagements),
      impressions: sumMetrics.impressions + Number(post.impressions),
    }),
    {
      engagements: 0,
      impressions: 0,
    },
  ), [topPosts]);

  const summedCreatorMetrics = useMemo(() => reduce(
    topCreators.data,
    (sumMetrics, creator) => ({
      engagements: sumMetrics.engagements + Number(creator.engagements),
      engagementRate: sumMetrics.engagementRate + Number(creator.engagement_rate),
    }),
    {
      engagements: 0,
      engagementRate: 0,
    },
  ), [topCreators]);

  const renderTopPosts = useMemo(() => {
    const hasZeroMetricData = summedPostMetrics.engagements === 0
      && summedPostMetrics.impressions === 0;

    if (topPosts?.data?.length === 0 || hasZeroMetricData) {
      return emptyComponent('posts');
    }
    const sortedTopPosts = orderBy(
      topPosts?.data,
      [
        (post: ITopPost) => Number(post.engagements),
        (post: ITopPost) => Number(post.impressions),
      ],
      [
        'desc',
        'desc',
      ],
    );

    return (
      <List
        bordered={false}
        dataSource={sortedTopPosts}
        grid={{ gutter: 16, column: 3 }}
        renderItem={(post: ITopPost) => (
          <List.Item>
            <FreshCard
              title=""
              cover={(
                <a
                  href={post.link}
                  target="_blank"
                >
                  <LazyImage src={post.image_url} />
                </a>
              )}
              bodyStyle={{ padding: '5px 0 0 0' }}
              style={{ height: '100%' }}
              bordered={false}
            >
              <TopPostMeta post={post} />
            </FreshCard>
          </List.Item>
        )}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topPosts.data,
    emptyComponent,
  ]);

  const renderTopCreators = useMemo(() => {
    const hasZeroMetricData = summedCreatorMetrics.engagements === 0
      && summedCreatorMetrics.engagementRate === 0;

    if (topCreators?.data?.length === 0 || hasZeroMetricData) {
      return emptyComponent('creators');
    }
    const sortedTopCreators = orderBy(
      topCreators?.data,
      [
        (creator: ITopCreator) => Number(creator.engagement_rate),
        (creator: ITopCreator) => Number(creator.engagements),
      ],
      [
        'desc',
        'desc',
      ],
    );

    return (
      <List
        bordered={false}
        dataSource={sortedTopCreators}
        grid={{ gutter: 16, column: 3 }}
        renderItem={(creator: ITopCreator) => (
          <List.Item>
            <FreshCard
              title=""
              cover={(
                <a
                  href={creator.link}
                  target="_blank"
                >
                  <LazyImage src={creator.profile_image_url} />
                </a>
              )}
              bodyStyle={{ padding: '5px 0 0 0' }}
              style={{ height: '100%' }}
              bordered={false}
            >
              <TopCreatorMeta creator={creator} />
            </FreshCard>
          </List.Item>
        )}
      />
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topCreators.data,
    emptyComponent,
  ]);

  const renderActions = (buttonType: string) => {
    const rerouteDetails = buttonType === 'posts'
      ? {
        pathname: `/app/${SOCIAL_POST_APP_ID}/reports`,
        search: qs.stringify({
          projectId,
          socialsTabPage: 'breakdown',
          breakdownGroupBy: 'aspirexPost',
        }),
      } : {
        pathname: `/app/${SOCIAL_POST_APP_ID}/reports`,
        search: qs.stringify({
          projectId,
          socialsTabPage: 'breakdown',
          breakdownGroupBy: 'member',
        }),
      };
    const data = buttonType === 'posts'
      ? topPosts.data
      : topCreators.data;

    const hasZeroMetricData = buttonType === 'posts'
      ? (summedPostMetrics.engagements === 0 && summedPostMetrics.impressions === 0)
      : (summedCreatorMetrics.engagements === 0 && summedCreatorMetrics.engagementRate === 0);
    return (
      data?.length > 0 && !hasZeroMetricData && (
        <Row
          justify="center"
          align="middle"
          className={styles.actionButton}
        >
          <Button
            type="text"
            key={buttonType}
            onClick={() => history.push(rerouteDetails)}
          >
            {`View All Project ${startCase(buttonType)}`}
          </Button>
        </Row>
      )
    );
  };

  if (topPosts.loading || topCreators.loading) {
    return <LoadingSkeleton />;
  }

  return (
    <WidgetTopPerformingCard>
      <Row gutter={16}>
        <Col xs={24} xl={12}>
          { !topPosts.loading && (
            <FreshCard
              className={styles.TopPerformingWidgetCard}
              headStyle={{
                fontSize: '12px',
                fontWeight: '600',
                borderBottom: '0',
              }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              title="Top Posts"
              bordered={false}
            >
              { renderTopPosts }
              { renderActions('posts') }
            </FreshCard>
          )}
        </Col>
        <Col xs={24} xl={12}>
          { !topCreators.loading && (
            <FreshCard
              className={styles.TopPerformingWidgetCard}
              headStyle={{
                fontSize: '12px',
                fontWeight: '600',
                borderBottom: '0',
              }}
              bodyStyle={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              title="Top Creators"
              bordered={false}
            >
              { renderTopCreators }
              { renderActions('creators') }
            </FreshCard>
          )}
        </Col>
      </Row>
    </WidgetTopPerformingCard>
  );
});

export default WidgetTopPerformingContent;
WidgetTopPerformingContent.displayName = 'WidgetTopPerformingContent';
