/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_alieb_157 {
  display: flex;
}

._justify-content-space-between_alieb_161 {
  justify-content: space-between;
}

._tabular-nums_alieb_165 {
  font-variant-numeric: tabular-nums;
}

._Toast_alieb_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_alieb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Toast_alieb_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Toast_alieb_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Toast_alieb_169 ::-webkit-scrollbar-track, ._Toast_alieb_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Toast_alieb_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Toast_alieb_169 ._content_alieb_204 ._message_alieb_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Toast_alieb_169 {
  padding: 10px 12px 10px 20px;
  position: fixed !important;
  z-index: 9700;
  left: 50%;
  top: 0;
  visibility: hidden;
  min-height: 48px;
  max-height: 100px;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.8;
  border-radius: 100px;
  box-shadow: 0px 2px 12px rgba(26, 24, 24, 0.39);
  transform: translate(-50%, -80px);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0s, visibility 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Toast_alieb_169._mobile_alieb_264 {
  min-height: unset;
  max-height: unset;
  max-width: unset;
  min-width: unset;
  width: 100%;
  border-radius: 0;
}
._Toast_alieb_169._mobile_alieb_264._active_alieb_272 {
  transform: translate(-50%, 0px);
}
._Toast_alieb_169._info_alieb_275 {
  background-color: #1a1818;
}
._Toast_alieb_169._error_alieb_278 {
  background-color: #da3737;
}
._Toast_alieb_169._warning_alieb_281 {
  background-color: #d48806;
}
._Toast_alieb_169._active_alieb_272 {
  transform: translate(-50%, 50px);
  visibility: visible;
}
._Toast_alieb_169._fresh_alieb_288 {
  font-weight: 600;
  font-size: 14px;
  color: #fdfdfd;
  border-radius: 4px;
  opacity: 1;
}
._Toast_alieb_169._freshSuccess_alieb_295 {
  background-color: #389e0d;
}
._Toast_alieb_169._freshInfo_alieb_298 {
  background-color: #4b9eff;
}
._Toast_alieb_169._freshError_alieb_301 {
  background-color: #ff4d4e;
}
._Toast_alieb_169 ._content_alieb_204 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._Toast_alieb_169 ._content_alieb_204 ._icon_alieb_311 {
  flex-shrink: 0;
  margin-right: 8px;
  width: 20px;
}
._Toast_alieb_169 ._content_alieb_204 ._icon_alieb_311._infoIcon_alieb_316 {
  color: #eacd60;
}
._Toast_alieb_169 ._content_alieb_204 ._icon_alieb_311._alertIcon_alieb_319 {
  color: #ea92af;
}
._Toast_alieb_169 ._content_alieb_204 ._icon_alieb_311._fresh_alieb_288 {
  color: #fdfdfd;
}
._Toast_alieb_169 ._content_alieb_204 ._message_alieb_204 {
  color: #fdfdfd;
}
._Toast_alieb_169 ._content_alieb_204 ._message_alieb_204 span {
  color: #dec8b8;
}
._Toast_alieb_169 ._content_alieb_204 ._message_alieb_204._fresh_alieb_288 {
  margin-right: 0.625rem;
  margin-left: 0.3125rem;
  font-weight: 600;
}
._Toast_alieb_169 ._content_alieb_204 ._message_alieb_204._fresh_alieb_288 span {
  color: #e7e6e6;
}
._Toast_alieb_169 ._close_alieb_339 {
  flex-shrink: 0;
  margin: 0 8px;
  cursor: pointer;
  width: 16px;
  color: #8f8d91;
  transition: color 0.1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Toast_alieb_169 ._close_alieb_339:hover {
  color: #fdfdfd;
}
._Toast_alieb_169 ._close_alieb_339._fresh_alieb_288 {
  color: #fdfdfd;
}