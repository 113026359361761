import gql from 'graphql-tag';
import { ONBOARDING_TEMPLATE_FRAGMENT } from './fragments';

export const GET_PROGRAM_BY_LANDING_PAGE_PATH = gql`
  query GetProgramByLandingPagePathQuery($customLandingPagePath: String!, $clientId: String!) {
    programForLandingPage: programForLandingPage(customLandingPagePath: $customLandingPagePath, clientId: $clientId) {
      id
      title
      description
      splashImageUrl
      specKey
      published
      submittedForReview
      legacyLandingPage
      customLandingPagePath
      templateName,
      applicationPageTemplateName
      columns {
        memberFieldSchemaIds
        dbColumns
      }
      applicationFormFields {
        memberFieldSchemas {
          schemaId
          required
          label
          order
        }
        dbColumns {
          name
          required
          label
          order
        }
      }
      onboardingTemplateConfig {
        ...OnboardingTemplateFragment
      },
      hasUnpaidOffer
      gcrEnabled
    }
  }
  ${ONBOARDING_TEMPLATE_FRAGMENT}
`;
