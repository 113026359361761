import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const PaletteIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.92188 1.668C12.3452 1.66216 14.681 2.5855 16.2835 4.23883C17.6785 5.678 18.4044 7.5255 18.3277 9.44216C18.2269 12.0038 15.8944 14.1688 13.2344 14.1688H11.8585C11.6294 14.1688 11.4419 14.3563 11.4419 14.5855C11.4419 14.6813 11.481 14.7813 11.5485 14.8563C12.0152 15.383 12.1877 16.078 12.0219 16.763C11.7977 17.6788 10.916 18.3347 9.91521 18.3347H9.88437C7.50604 18.3022 5.24854 17.2497 3.69187 15.4463C2.13521 13.6447 1.42521 11.2438 1.74354 8.8605C2.28437 4.79466 5.80104 1.703 9.92104 1.668H9.92188ZM9.16687 5.83466C8.47687 5.83466 7.91687 5.27466 7.91687 4.58466C7.91687 3.89466 8.47687 3.33466 9.16687 3.33466C9.85687 3.33466 10.4169 3.89466 10.4169 4.58466C10.4169 5.27466 9.85687 5.83466 9.16687 5.83466ZM13.9585 6.5005C13.3602 6.8455 12.596 6.6405 12.251 6.043C11.906 5.44466 12.111 4.6805 12.7085 4.3355C13.3069 3.9905 14.071 4.1955 14.416 4.793C14.761 5.39133 14.556 6.1555 13.9585 6.5005ZM5.20853 8.16716C5.80686 8.51216 6.57103 8.30716 6.91603 7.70966C7.26103 7.11133 7.05603 6.34716 6.45853 6.00216C5.8602 5.65716 5.09603 5.86216 4.75103 6.45966C4.40603 7.058 4.61103 7.82216 5.20853 8.16716ZM5.69937 12.2872C5.12687 12.6738 4.35021 12.523 3.96437 11.9505C3.57771 11.3788 3.72937 10.6013 4.30104 10.2155C4.87354 9.82883 5.65021 9.9805 6.03604 10.5522C6.42271 11.1247 6.27104 11.9013 5.69937 12.2872Z" fill="currentColor" />
  </SvgIcon>
));

PaletteIcon.displayName = 'PaletteIcon';
