import { connect } from 'react-redux';
import { hasFeature } from '@components';
import { IConnectSearchPage, ICreatorSearchOptions, ICreatorSearchFilters, CreatorSearchVersion } from '../models';
import { updateSearchFilters } from '../redux/textSearchActions';
import { CreatorSearchFilters } from '../TextSearchFilters/CreatorSearchFilters';
import { textSearchOptionsSelector } from '../redux/selectors';
import { applySearchFilters } from '../redux/searchResultsActions';
import { clearSelectedSearch } from '../redux/savedSearchesActions';

interface IState {
  options: ICreatorSearchOptions;
  filters: ICreatorSearchFilters;
  selfServeExperiment: boolean;
  version: CreatorSearchVersion;
}

interface IDispatchProps {
  onChange(newFilters: ICreatorSearchFilters);
  performSearch: () => void;
  clearSelectedSearch: () => void;
}

const mapStateToProps = (state: IConnectSearchPage) => ({
  options: textSearchOptionsSelector(state),
  filters: state.textSearch,
  version: state.external.version,
  selfServeExperiment: hasFeature(state.external.organization, 'self_serve_experiment'),
});

const mapDispatchToProps = {
  onChange: updateSearchFilters,
  performSearch: applySearchFilters,
  clearSelectedSearch: clearSelectedSearch,
};

export const ConnectedTextSearch = connect<IState, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreatorSearchFilters);
