import * as React from 'react';

import { Checkbox, Form } from '@revfluence/fresh';
import { IShopifyFormElementProps } from '../../../types';

import styles from '../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'specialLimitOnePerSale'> { }

export const OfferCodeLimitOnePerCustomer: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => (
  // the label has to be outside of the AUIForm.Item because we don't want the user
  // clicking the label to trigger the checkbox
  <>
    <label className="ant-form-item-label">
      Usage Limits
    </label>
    <Form.Item className={styles.halfRow} name={name} valuePropName="checked">
      <Checkbox disabled={disabled}>
        Limit to one use per customer
      </Checkbox>
    </Form.Item>
  </>
));

OfferCodeLimitOnePerCustomer.displayName = 'OfferCodeLimitOnePerCustomer';
