import * as React from 'react';
import { map, isEmpty } from 'lodash';

import {
 IMasonryItemProps, MasonryGrid, LoadSpinner, CloseIcon, LazyImage,
} from '@components';

import styles from './ImageSelectList.scss';

const { useMemo } = React;

interface IImageTileProps extends IMasonryItemProps {
  image: string;
  onSelectImage(image: string);
  onRemoveImage();
}

const COLUMN_WIDTH = 180;

class ImageTile extends React.PureComponent<IImageTileProps, any> {
  private ref: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  private handleSizeDetected = (naturalWidth, naturalHeight) => {
    const ratio = COLUMN_WIDTH / naturalWidth;
    this.props.onItemHeightChanged(ratio * naturalHeight);
  };

  private handleClick = () => this.props.onSelectImage(this.props.image);

  private handleRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onRemoveImage();
  };

  public render() {
    return (
      <div ref={this.ref} className={styles.ImageTile} onClick={this.handleClick}>
        <div className={styles.removeImage}>
          <span onClick={this.handleRemove} className={styles.close}>
            <CloseIcon size={14} />
          </span>
        </div>
        <LazyImage src={this.props.image} onSizeDetected={this.handleSizeDetected} />
      </div>
    );
  }
}

interface IImage {
  key: any;
  url: string;
}

interface IProps {
  images: IImage[];
  isLoading?: boolean;
  errorMessage?: string | JSX.Element;
  emptyMessage?: string | JSX.Element;
  onSelectImage(imageURL: string);
  onRemoveImageForKey(key: any);
}

export const ImageSelectList: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    emptyMessage,
    errorMessage,
    images,
    isLoading,
    onRemoveImageForKey,
    onSelectImage,
  } = props;

  const itemProps: IImageTileProps[] = useMemo(() => map(images, (image) => ({
      key: image.key,
      image: image.url,
      onSelectImage,
      onRemoveImage: () => onRemoveImageForKey(image.key),
    })), [images, onSelectImage, onRemoveImageForKey]);

  if (isLoading) {
    return <LoadSpinner className={styles.spinner} />;
  } else if (errorMessage) {
    return <div className={styles.error}>{errorMessage}</div>;
  } else if (isEmpty(images)) {
    return <div className={styles.empty}>{emptyMessage}</div>;
  }

  return <MasonryGrid itemComponent={ImageTile} itemProps={itemProps} columnWidth={COLUMN_WIDTH} />;
});

ImageSelectList.defaultProps = {
  isLoading: false,
  errorMessage: null,
};
