import * as React from 'react';

import { useState } from 'react';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { copyToClipboard } from '@frontend/utils/uiUtils';

import {
  Notice, Button,
} from '@components';
import { ISocialAccount } from '../useFetchSocialAccountData';

interface IProps {
  account: ISocialAccount;
  onAuth(account: ISocialAccount, url: string): void;
}

import styles from './SocialAccountAuthCell.scss';

export const SocialAccountAuthCell: React.FunctionComponent<IProps> = (props) => {
  const [copyText, setCopyText] = useState<string>(null);
  const { backendServerWebEndpoint, clientId } = useApplication();

  const { account } = props;
  const authUrl = `${backendServerWebEndpoint}creators/client_auth/${clientId}?account_id=${account.id}`;
  const copyLink = () => {
    copyToClipboard(authUrl);
    setCopyText('Copied to Clipboard');
  };

  const askToAuthorize = () => {
    props.onAuth(account, authUrl);
  };

  return (
    <div className={styles.SocialAccountAuthCell}>
      <div className={styles.NameWrap}>
        <span className={styles.UserName}>
          @
          {account.username}
        </span>
      </div>
      {!account.has_analytics_api_access ? (
        <div className={styles.ActionsWrap}>
          <Button
            label="Copy Auth Link"
            onClick={copyLink}
            theme="light"
            className={styles.CopyButton}
          />
          <Button
            label="Ask to Authorize"
            onClick={askToAuthorize}
            theme="primary"
          />
          {copyText && (
            <Notice className={styles.Notice} type="success">
              {copyText}
            </Notice>
          )}
        </div>
      ) : <span>Insights Linked</span>}
    </div>
  );
};
