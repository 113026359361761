import * as React from 'react';
import { Typography } from '@revfluence/fresh';

import { IContentGuidelinesWithDueDates, TContentGuideline } from '../types/ContentGuidelines';
import styles from './AddContentGuidelines.scss';
import ContentDeliverableTypes from './ContentDeliverableTypes';

interface IProps {
  contentGuidelinesTemplates: Array<TContentGuideline>;
  contentGuidelinesWithDueDates: Array<IContentGuidelinesWithDueDates>;
  onAddPostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
  onRemovePostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
}

const { Title } = Typography;

const AddContentGuidelines: React.FC<IProps> = React.memo((props) => {
 const {
   contentGuidelinesTemplates,
   contentGuidelinesWithDueDates,
   onAddPostType,
   onRemovePostType,
  } = props;

 return (
   <div className={styles.AddContentGuidelines}>
     <Title level={5} style={{ margin: 'auto' }}>Select the content deliverables</Title>
     <ContentDeliverableTypes
       onAddPostType={onAddPostType}
       onRemovePostType={onRemovePostType}
       contentGuidelinesTemplates={contentGuidelinesTemplates}
       contentGuidelinesWithDueDates={contentGuidelinesWithDueDates}
     />
   </div>
 );
});

AddContentGuidelines.displayName = 'AddContentGuidelines';

export default AddContentGuidelines;
