import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';
import * as React from 'react';

import {
  CityStateCard,
  CountryCard,
  GenderAgeCard,
  ProfileCard,
  SummaryCard,
} from '../Card';

import styles from './Content.scss';

export const TiktokContent: React.FC<{ version: CreatorSearchVersion }> = ({ version }) => (
  <div className={styles.summary}>
    <ProfileCard className={styles.ProfileCard} />
    <SummaryCard className={styles.SummaryCard} version={version} />
    <GenderAgeCard className={styles.GenderCard} hideAge hideUnknown />
    <GenderAgeCard className={styles.AgeCard} hideGender showAgeAsBarChart />
    <CountryCard className={styles.CountryCard} />
    <CityStateCard className={styles.StateCard} hideCity />
  </div>
);

TiktokContent.displayName = 'TiktokContent';
