import * as React from 'react';
import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';

import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import ContentAppReport from './ContentAppReport';

const application = {
  displayName: 'Content',
  iconUrl: 'https://storage.googleapis.com/aspirex-static-files/content.svg',
};
interface IProps {
  refreshUi: boolean;
}

const ContentAppDashboard: React.FunctionComponent<IProps> = ({ refreshUi }) => {
  const navSettings = [
    {
      route: 'reports',
      displayName: 'My Content',
      component: ContentAppReport,
    },
  ];

  return (
    <>
      {refreshUi ? (
        <>
          <AppHeader
            title="Content Library"
          />
          <div className="px-6" style={{ padding: 24 }}>
            <ContentAppReport />
          </div>
        </>
      ) : (
        <AppDashboardNav
          application={application}
          navLinks={navSettings}
          hideTabs
          defaultRoute="reports"
          fixToScreenHeight
        />
      )}
    </>
  );
};

export default ContentAppDashboard;
