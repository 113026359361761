import { useQuery, QueryHookOptions } from '@apollo/client';

import {
 GetCreatorsOrderCatalogInfoQuery,
  GetCreatorsOrderCatalogInfoQueryVariables,
} from '../queries/types/GetCreatorsOrderCatalogInfoQuery';
import { GET_CREATORS_ORDER_CATALOG_INFO_QUERY } from '../queries/brandCatalog';

type IOptions = QueryHookOptions<GetCreatorsOrderCatalogInfoQuery, GetCreatorsOrderCatalogInfoQueryVariables>;

export function useGetCreatorsOrderCatalogInfo(options: IOptions) {
  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<GetCreatorsOrderCatalogInfoQuery, GetCreatorsOrderCatalogInfoQueryVariables>(
    GET_CREATORS_ORDER_CATALOG_INFO_QUERY,
    options,
  );

  return {
    isCreatorsOrderCatalogInfoLoading: loading,
    creatorsOrderCatalogInfo: data?.getCreatorsOrderCatalogInfo,
    creatorsOrderCatalogInfoError: error,
    refetchCreatorsOrderCatalogInfo: refetch,
  };
}
