import { useMemo } from 'react';

import { ProjectOverviewQuery } from '@frontend/app/queries/types/ProjectOverviewQuery';
import { QueryResult } from '@apollo/client';
import {
  IComplexMetric,
  ISimpleMetric,
  StatsAPIState,
} from './types';

type SimpleSalesStats =
  | 'affiliates'
  | 'conversions';
type ComplexSalesStats =
  | 'sales';
type SalesStat =
  | SimpleSalesStats
  | ComplexSalesStats;

const isSalesStat = (stat: string): stat is SalesStat => {
  switch (stat) {
    case 'affiliates':
    case 'conversions':
    case 'sales':
      return true;
    default:
      return false;
  }
};

type SimpleSocialStat =
  | 'creatorCount'
  | 'postCount'
  | 'tmv'
  | 'ugc'
  | 'views';
type ComplexSocialStat =
  | 'engagements'
  | 'impressions'
  | 'reach';
type SocialStat =
  | SimpleSocialStat
  | ComplexSocialStat;

const isSocialStat = (stat: string): stat is SocialStat => {
  switch (stat) {
    case 'creatorCount':
    case 'engagements':
    case 'impressions':
    case 'postCount':
    case 'reach':
    case 'tmv':
    case 'ugc':
    case 'views':
      return true;
    default:
      return false;
  }
};

type ProjectOverviewStat =
  | SalesStat
  | SocialStat
  | 'followers';

type MetricType<T extends ProjectOverviewStat> = (
  T extends (ComplexSalesStats | ComplexSocialStat | 'followers') ? IComplexMetric : ISimpleMetric
);

type QueryResultType<T extends ProjectOverviewStat> = {
  homePageStats: (
    T extends SalesStat ? ({ sales: { totals: { [key in T]: MetricType<T> } } }) : (
      T extends SocialStat ? ({ social: { totals: { [key in T]: MetricType<T> } } }) : (
        never
      )
    )
  ),
};

export const useProjectOverviewStat = <T extends ProjectOverviewStat>(
  stat: T,
  statsApiResult: QueryResult<ProjectOverviewQuery>,
): StatsAPIState<MetricType<T>> | undefined => useMemo((): StatsAPIState<MetricType<T>> | undefined => {
    if (!statsApiResult) {
      return undefined;
    }

    if (statsApiResult.error) {
      return {
        error: statsApiResult.error,
        state: 'failed',
      };
    }

    if (statsApiResult.loading && !statsApiResult.data) {
      return {
        state: 'loading',
      };
    }

    if (isSalesStat(stat)) {
      const { data } = statsApiResult as { data: QueryResultType<SalesStat> };
      return {
        data: data.homePageStats.sales.totals[stat as SalesStat],
        state: 'ready',
      };
    }

    if (isSocialStat(stat)) {
      const { data } = statsApiResult as { data: QueryResultType<SocialStat> };
      return {
        data: data.homePageStats.social.totals[stat as SocialStat],
        state: 'ready',
      };
    }

    throw new Error(`Unhandled stat: ${stat}.`);
  }, [
    stat,
    statsApiResult,
  ]);
