/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1uf7e_157 {
  display: flex;
}

._justify-content-space-between_1uf7e_161 {
  justify-content: space-between;
}

._tabular-nums_1uf7e_165 {
  font-variant-numeric: tabular-nums;
}

._Table_1uf7e_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1uf7e_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Table_1uf7e_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Table_1uf7e_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Table_1uf7e_169 ::-webkit-scrollbar-track, ._Table_1uf7e_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Table_1uf7e_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Table_1uf7e_169 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
._Table_1uf7e_169._scrollable_1uf7e_240 {
  overflow: hidden;
}
._Table_1uf7e_169._scrollable_1uf7e_240 ._tableBody_1uf7e_243 {
  overflow-y: auto;
  overflow-x: hidden;
}
._Table_1uf7e_169 ._tableHeader_1uf7e_247 {
  margin-bottom: 0.625rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
._Table_1uf7e_169 ._tableHeader_1uf7e_247 ._headerActions_1uf7e_255 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
._Table_1uf7e_169 ._tableHeader_1uf7e_247 ._right_1uf7e_262 {
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Table_1uf7e_169 ._tableHeader_1uf7e_247 ._right_1uf7e_262 ._searchInput_1uf7e_269 {
  width: auto;
  margin-left: 0.9375rem;
}
._Table_1uf7e_169 ._tableHeader_1uf7e_247 ._right_1uf7e_262 ._exportButton_1uf7e_273 {
  margin-left: 0.9375rem;
}
._Table_1uf7e_169 ._tableHeader_1uf7e_247 ._right_1uf7e_262 ._pagination_1uf7e_276 {
  margin-left: 0.9375rem;
}
._Table_1uf7e_169 ._tableHeader_1uf7e_247 ._right_1uf7e_262 ._columnConfig_1uf7e_279 {
  margin-left: 0.9375rem;
}
._Table_1uf7e_169 ._tableBody_1uf7e_243 {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._Table_1uf7e_169 ._tableBody_1uf7e_243 ._bodyRow_1uf7e_288 {
  background-color: #fdfdfd;
}
._Table_1uf7e_169 ._tableBody_1uf7e_243 ._bodyRow_1uf7e_288._odd_1uf7e_291 {
  background-color: #f7f7f7;
}
._Table_1uf7e_169 ._tableBody_1uf7e_243 ._bodyRow_1uf7e_288._border_1uf7e_294 {
  border-bottom: 1px solid #e6edf2;
}
._Table_1uf7e_169 ._tableBody_1uf7e_243 ._bodyRow_1uf7e_288._clickable_1uf7e_297 {
  cursor: pointer;
}
._Table_1uf7e_169 ._tableBody_1uf7e_243 ._bodyRow_1uf7e_288._highlightOnHover_1uf7e_300:hover {
  background-color: #eff5f9;
}
._Table_1uf7e_169 ._noResultNotice_1uf7e_303 {
  margin: 0.875rem auto;
  width: 25rem;
}
._Table_1uf7e_169 ._mask_1uf7e_307 {
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}