._AudienceAgeSection_d75ab_1 ._multiSelectContainer_d75ab_1 {
  width: 100%;
  display: flex;
  align-items: center;
}
._AudienceAgeSection_d75ab_1 ._multiSelectContainer_d75ab_1 ._multiSelectSelect_d75ab_6 {
  flex: 1;
}
._AudienceAgeSection_d75ab_1 ._multiSelectContainer_d75ab_1 ._multiSelectSeparator_d75ab_9 {
  margin: 0 16px;
}