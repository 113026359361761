import { TState } from '../types';

interface IAction {
  showEndDate: boolean;
  type: 'SET SHOW ENDDATE';
}
export type TSetShowEndDate = Readonly<IAction>;

export const setShowEndDate = (state: TState, action: TSetShowEndDate): TState => ({
    ...state,
    showEndDate: action.showEndDate,
  });
