import { TPostType } from './postType';
import { IPublisher } from './publisher';
import { ISocialAccount } from './socialAccount';
import { ICampaignStage } from './campaign';

interface IPostBundle {
  account_id: number;
  price: string;
  post_type: TPostType;
}

export interface IRelation {
  id: string;
  // Only present on AdvertiserPublisherRelation.
  publisher: IPublisher;
  social_account: ISocialAccount;
  stages: ICampaignStage[];
  metadata: {
    accounts: ISocialAccount[];
  };
  project_metadata: {
    [key: number]: {
      post_bundles: IPostBundle[];
      has_proposal: boolean;
      campaign_id: number;
    };
  };
}
