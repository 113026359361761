import {
  IState,
} from '../../types/state';

export const getInitialState = (): IState => ({
  contentGuidelines: [],
  premadeTemplates: [],
  blankTemplates: [],
  projectId: null,
  templateType: null,
  isTemplatesModalVisible: false,
  isEditMode: false,
  step: null,
  otherProjectId: null,
  defaultActiveKey: 0,
});
