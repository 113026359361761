import * as React from 'react';

import {
  ISvgIconProps,
  SvgIcon,
} from '@components';

export const ThumbsUpIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      d="
        M24.2,21c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5h-1.6c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,1.6-2.5,1.6h-9
        c-4.7,0-7.7-2.4-13.4-3.2V15.2c4,0,12.6-6.5,12.6-13.1c0-2.6,3.6-3.6,4.9,1.2c0.8,2.9-1.6,7-1.6,7h11.5c1.4,0,2.5,1.1,2.5,2.5
        c0,1.4-1.1,3.3-2.5,3.3h-1.6c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5H24.2
      "
      fill={props.fill}
    />
  </SvgIcon>
));

ThumbsUpIcon.displayName = 'ThumbsUpIcon';
