import * as React from 'react';
import {
  Button,
  Form,
  Tooltip,
} from '@revfluence/fresh';
import { OfferFormTestIds as testIds } from '../OfferFormTestIds';

interface IProps {
  disabled: boolean;
  loading: boolean;
  requiredFields: React.ReactNode;
}

export const CreateButton: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  loading,
  requiredFields,
}) => (
  <Tooltip title={requiredFields}>
    <Form.Item>
      <Button
        block
        data-testid={testIds.submit}
        disabled={disabled}
        loading={loading}
        htmlType="submit"
        size="large"
        type="primary"
      >
        Create Offer
      </Button>
    </Form.Item>
  </Tooltip>
));

CreateButton.displayName = 'CreateButton';
