import * as React from 'react';
import cx from 'classnames';

import {
 map, groupBy, isEmpty, sumBy,
} from 'lodash';
import { CalendarCircleExclamationIcon, ListCheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { Typography, Button, Skeleton } from '@revfluence/fresh';
import { useGetTermsName } from '@frontend/app/hooks';
import { ContentCard } from './ContentCard';
import { ICollaborationDetails } from '../types';

import { EmptyState } from './EmptyState';
import styles from './Deliverables.scss';

const { Title } = Typography;

interface IProps {
  collaborationDetails: ICollaborationDetails[];
  className?: String;
  onCopyTermsLinkAction(termsLink: string): void;
  onMarkCompleteAction(contentReviewId: number, shouldMarkComplete: boolean): void;
  onReviewClick(contentReviewId: number): void;
  onTermsClick(agreementIterationId: string, termsType: string, agreementId: string): void;
  isInSentTermsStage: boolean;
  sendTermsAction(): void;
  loading?: boolean;
  hideDecorators?: boolean;
}

const completedStages = ['COMPLETED_WITHOUT_PAYMENT', 'MARKED_COMPLETE', 'COMPLETED'];

const getTitleString = (
  collaborations: ICollaborationDetails[],
  onTermsClick: (agreementIterationId: string, termsType: string, agreementId: string) => void,
  singularTermsName: string,
) => {
  const dateString = collaborations[0].agreementDate
    ? new Date(collaborations[0].agreementDate * 1000).toLocaleDateString()
    : '';
  const total = sumBy(collaborations, 'totalContentCount');
  const submitted = sumBy(collaborations, 'submittedContentCount');
  const termsLink = onTermsClick ? (
    <a onClick={() => onTermsClick(
      collaborations[0].agreementIterationId.toString(),
      collaborations[0].termsType,
      collaborations[0].agreementId,
      )}
    >
      {singularTermsName}
    </a>
  ) : singularTermsName;
  return (
    <div>
      {`${submitted}/${total} Content Submitted for `}
      {termsLink}
      {` signed ${dateString}`}
    </div>
  );
};

export const Deliverables: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    collaborationDetails,
    onMarkCompleteAction,
    onCopyTermsLinkAction,
    onReviewClick,
    isInSentTermsStage,
    sendTermsAction,
    loading,
    onTermsClick,
    hideDecorators,
  } = props;

  const collaborationDetailsByProjectID = groupBy(collaborationDetails, 'backendServerProjectId');

  const { pluralTermsName, singularTermsName } = useGetTermsName();
  const sortedTerms = Object.values(collaborationDetailsByProjectID).sort((a, b) => b[0].agreementDate - a[0].agreementDate);
  const sortedTermsWithDeliverables = map(sortedTerms, (deliverables) =>
    deliverables.sort((a, b) => {
      const isAComplete = completedStages.includes(a.state) ? 1 : 0;
      const isBComplete = completedStages.includes(b.state) ? 1 : 0;
      if (isAComplete - isBComplete !== 0) {
        return isAComplete - isBComplete;
      }

      return a.nextDeadlineTimeStamp - b.nextDeadlineTimeStamp;
    }));
  if (loading) {
    return (
      <div className={cx(styles.Deliverables, props.className)}>
        <Title
          level={5}
          className={styles.title}
        >
          <Skeleton.Input active size="small" />
        </Title>
        <div className={cx(styles.termsSection, styles.loading)}>
          <div className={styles.skTitleLong}>
            <Skeleton.Input active size="small" />
          </div>
          <div className={styles.skBlock}>
            <Skeleton.Input active />
          </div>
          <div className={styles.skBlock}>
            <Skeleton.Input active />
          </div>
          <div className={styles.skBlock}>
            <Skeleton.Input active />
          </div>
          <div className={styles.skBlock}>
            <Skeleton.Input active />
          </div>
          <div className={styles.skTitleLong}>
            <Skeleton.Input active size="small" />
          </div>
          <div className={styles.skBlock}>
            <Skeleton.Input active />
          </div>
          <div className={styles.skBlock}>
            <Skeleton.Input active />
          </div>
          <div className={styles.skBlock}>
            <Skeleton.Input active />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={cx(styles.Deliverables, props.className, {
        [styles.flat]: hideDecorators,
      })}
    >
      {
        !hideDecorators
          && (
            <Title
              level={5}
              className={styles.title}
            >
              <CalendarCircleExclamationIcon style={{ marginRight: 'var(--spacing-xs)' }} />
              Status of Deliverables
            </Title>
          )
      }
      <div
        className={cx(styles.termsSection, { [styles.emptyTermsSection]: isEmpty(collaborationDetailsByProjectID) })}
      >
        {isEmpty(collaborationDetailsByProjectID) ? (
          <EmptyState
            icon={ListCheckIcon}
            title="No Deliverables Required for this Member"
            description={`Send ${pluralTermsName} to the member to require content deliverables.`}
            actionComponent={isInSentTermsStage && (
            <Button className={styles.termsEmptyAction} onClick={sendTermsAction}>
              Send
              {' '}
              {pluralTermsName}
            </Button>
              )}
          />
        ) : (
          map(sortedTermsWithDeliverables, (projectDetails, index) => (
            <div key={index}>
              <Title level={5}>{getTitleString(projectDetails, onTermsClick, singularTermsName)}</Title>
              <div className={styles.contentSection}>
                {map(projectDetails, (content) => (
                  <ContentCard
                    key={content.id}
                    onCopyTermsLinkAction={onCopyTermsLinkAction}
                    onMarkCompleteAction={onMarkCompleteAction}
                    content={content}
                    onReviewClick={onReviewClick}
                    className={styles.contentCard}
                  />
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
});

Deliverables.displayName = 'Deliverables';
