import { useQuery, QueryHookOptions } from '@apollo/client';

import { isNull } from 'lodash';

import { GET_CLIENT_FEATURE_BY_NAME } from '@frontend/app/queries';
import {
  GetClientFeatureByName,
  GetClientFeatureByNameVariables,
} from '@frontend/app/queries/types/GetClientFeatureByName';
import { ClientFeature } from '@frontend/app/constants';

export type IOptions = QueryHookOptions<GetClientFeatureByName, GetClientFeatureByNameVariables>;

export function useGetClientFeatureByName(name: ClientFeature, options: IOptions = {}) {
  return useQuery<GetClientFeatureByName>(
    GET_CLIENT_FEATURE_BY_NAME,
    {
      ...options,
      variables: {
        name,
      },
      skip: isNull(name) || options.skip,
    },
  );
}
