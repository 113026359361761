import { useMutation, MutationHookOptions } from '@apollo/client';

import { DUPLICATE_CONTENT_GUIDELINES_INTO_PROJECT_MUTATION } from '@frontend/app/queries';
import {
  DuplicateContentGuidelinesIntoProject,
  DuplicateContentGuidelinesIntoProjectVariables,
} from '@frontend/app/queries/types/DuplicateContentGuidelinesIntoProject';

type IOptions = MutationHookOptions<DuplicateContentGuidelinesIntoProject, DuplicateContentGuidelinesIntoProjectVariables>;

export const useDuplicateContentGuidelinesIntoProjectMutation = (options: IOptions = {}) =>
  useMutation<DuplicateContentGuidelinesIntoProject, DuplicateContentGuidelinesIntoProjectVariables>(
    DUPLICATE_CONTENT_GUIDELINES_INTO_PROJECT_MUTATION,
    options,
  );
