import * as React from 'react';

import { MessageComposer } from '../components/MessageComposer';
import { IAxProjectInfo } from '../types/IAxProjectInfo';

import styles from './TermsAppMessage.scss';

interface ITermsAppMessageProps {
  projectInfo: IAxProjectInfo;
  memberId: number;
}

export const TermsAppMessage: React.FC<ITermsAppMessageProps> = (
  { projectInfo, memberId },
) => {
  const title = projectInfo ? '(Optional) Add a personal message' : 'Send Brief';
  const subtitle = projectInfo
    ? 'Provide a more personal feeling to your collab'
    : 'Set up deliverables, content guidelines, payment and more';

  return (
    <div className={styles.TermsAppMessage}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <MessageComposer memberId={memberId} project={projectInfo} />
    </div>
  );
};
