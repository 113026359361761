import 'css-chunk:src/components/common/CreatorList/CreatorList.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1lctr_157",
  "justify-content-space-between": "_justify-content-space-between_1lctr_161",
  "tabular-nums": "_tabular-nums_1lctr_165",
  "CreatorList": "_CreatorList_1lctr_169",
  "tableMode": "_tableMode_1lctr_243",
  "gridView": "_gridView_1lctr_247",
  "tableView": "_tableView_1lctr_248",
  "loadSpinner": "_loadSpinner_1lctr_254",
  "pagination": "_pagination_1lctr_262",
  "header": "_header_1lctr_269",
  "title": "_title_1lctr_276",
  "mode": "_mode_1lctr_280",
  "modeItem": "_modeItem_1lctr_289",
  "active": "_active_1lctr_308",
  "progress": "_progress_1lctr_313",
  "notice": "_notice_1lctr_317",
  "SelectFooter": "_SelectFooter_1lctr_326",
  "addList": "_addList_1lctr_326",
  "addIconWrapper": "_addIconWrapper_1lctr_340",
  "addIcon": "_addIcon_1lctr_340",
  "editList": "_editList_1lctr_356",
  "back": "_back_1lctr_359",
  "goBackButton": "_goBackButton_1lctr_372",
  "editContent": "_editContent_1lctr_377",
  "listName": "_listName_1lctr_381",
  "button": "_button_1lctr_384",
  "show": "_show_1lctr_1"
};