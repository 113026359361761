import * as React from 'react';

import { map } from 'lodash';

import { PostCard, Notice } from '@components';
import { pluralize } from '@frontend/app/utils/strings';

import { ISocialPostArtifact } from '../useFetchSocialPostData';

import styles from './SocialPostsList.scss';

const { useCallback } = React;

interface IProps {
  posts: ISocialPostArtifact[];
  onPostClick?(post: ISocialPostArtifact): void;
  postDisplayName: string;
}

export const SocialPostsList: React.FunctionComponent<IProps> = (props) => {
  const { posts, postDisplayName } = props;

  const onPostClick = useCallback((postData: ISocialPostArtifact, event: React.MouseEvent) => {
    if (props.onPostClick) {
      props.onPostClick(postData);
      event.preventDefault();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPostClick]);

  return (
    <div className={styles.SocialPostsList}>
      <div>
        {posts && posts.length === 0 && (
          <Notice type="disabled">
            This member has no
            {' '}
            {postDisplayName}
            .
          </Notice>
        )}
        {posts && posts.length > 0 && (
          <span>
            Showing
            {' '}
            {posts.length}
            {' '}
            {pluralize(posts.length, postDisplayName)}
          </span>
        )}
        <div className={styles.postListContainer}>
          {posts && posts.length > 0 && map(posts, (postData, index) => (
            <div
              key={index}
              className={styles.postContainer}
              onClick={(event) => onPostClick(postData, event)}
            >
              <PostCard
                isQa={false}
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                post={postData as any}
                hideValueSection
                key={index}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
