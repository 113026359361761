import * as React from 'react';
import { map } from 'lodash';

import { Button, Table, ITableColumnConfig } from '@components';
import { Typography } from '@revfluence/fresh';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

const { Title, Paragraph } = Typography;

export interface BasicMember {
  first_name: string;
  last_name: string;
  email: string;
}
interface IProps {
  memberInfo: BasicMember[];
  onContinue(): void;
  canContinue: boolean;
  emailSubject: string;
  requiredFields: string[];
}

const { useCallback } = React;

import styles from './InvalidMembers.scss';

function formatArrayToSentence(arr) {
  if (arr.length === 0) {
    return '';
  } else if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return `${arr[0]} and ${arr[1]}`;
  } else {
    const lastElement = arr.pop();
    return `${arr.join(', ')} and ${lastElement}`;
  }
}

const COLS = [
  {
    field: 'first_name',
    headerName: 'First Name',
    type: 'TEXT',
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    type: 'TEXT',
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'TEXT',
  },

];

export const InvalidMembersCPS: React.FunctionComponent<IProps> = (props) => {
  const {
 canContinue, memberInfo, onContinue, requiredFields, emailSubject,
} = props;
  const { closeModal } = useApplication();

  const renderBodyRow = useCallback((_, cellContent) => cellContent, []);

  const renderEmptyCell = useCallback(() => (
    <div />
  ), []);

  const columnConfig = React.useMemo<ITableColumnConfig[]>(() => (
    map(COLS, (column) => ({
      ...column,
      grow: true,
      width: 200,
      minWidth: 200,
      maxWidth: 400,
      render: (value) => value,
      lockPosition: true,
    }))
  ), []);

  return (
    <div className={styles.InvalidMembers}>
      <Title level={5}>
        {canContinue && (
          `Some members cannot be sent ${emailSubject}`
        )}
        {!canContinue && (
          `Cannot send ${emailSubject} to selected members`
        )}
      </Title>
      <Paragraph>
        {`All members must have ${formatArrayToSentence(requiredFields)} in order to get a ${emailSubject} email.`}
      </Paragraph>
      <div className={styles.summary}>
        <Table
          columns={columnConfig}
          className={styles.table}
          config={{
            rowHeight: 56,
            allowSearch: false,
            configurableColumns: false,
            selectable: false,
            striped: false,
            rowBorder: true,
            renderBodyRow,
            renderEmptyCellContent: renderEmptyCell,
            reorderableColumns: true,
            resizableColumns: true,
          }}
          paddingBottom={20}
          data={map(memberInfo, (m) => ({
            ...m,
            _raw: m,
            id: `${m.first_name} ${m.last_name} ${m.email}`,
          }))}
        />
        <div className={styles.footer}>
          <Button
            label="Cancel"
            theme="info"
            className={styles.button}
            onClick={closeModal}
          />
          {canContinue && (
            <Button
              label="Continue"
              theme="primary"
              className={styles.button}
              onClick={onContinue}
            />
          )}
        </div>
      </div>
    </div>
  );
};
