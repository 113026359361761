import { useQuery, QueryHookOptions } from '@apollo/client';
import {
  GET_MEMBER_ACTIVITIES_QUERY,
} from '@frontend/app/queries';
import {
  GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables,
} from '@frontend/app/queries/types/GetMemberActivitiesQuery';

type IOptions = QueryHookOptions<GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables>;

export function useGetMemberActivities(options: IOptions = {}) {
  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables>(
    GET_MEMBER_ACTIVITIES_QUERY,
    {
      fetchPolicy: 'no-cache',
      ...options,
    },
  );

  return {
    loading,
    activities: data?.activities,
    error,
    refetch,
  };
}
