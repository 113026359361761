import { IState, IUpdateEmailSubjectAction } from '../../../../types/state';

export const updateEmailSubject = (state: IState, action: IUpdateEmailSubjectAction): IState => {
  if (state.emailSubject === action.emailSubject) {
    return state;
  }
  return {
    ...state,
    emailSubject: action.emailSubject,
  };
};
