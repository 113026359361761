import * as React from 'react';
import cx from 'classnames';
import { isUndefined } from 'lodash';

import styles from './Toggle.scss';

interface IProps {
  onChange(checked: boolean);

  checked?: boolean;
  classNames?: string[];
}
type TDefaultProp = 'classNames';
interface IState {
  checked: boolean;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class Toggle extends React.PureComponent<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private controlled: boolean;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.controlled = !isUndefined(props.checked);

    this.state = {
      checked: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { classNames } = this.props;
    const checked = this.getChecked();

    return (
      <div
        className={cx(classNames.concat(styles.Toggle), {
          [styles.active]: checked,
        })}
        onClick={this.toggleChecked}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <div className={(styles as any).background} />
        <div className={styles.circle} />
      </div>
    );
  }

  /**
   * @private
   * Toggles the checked state, also notifies parent.
   */
  private toggleChecked = () => {
    const { onChange } = this.props;
    const checked = this.getChecked();

    onChange(!checked);

    this.setState({
      checked: !checked,
    });
  };

  /**
   * @private
   * Returns the checked state based on whether the toggle is controlled or not.
   *
   * @return {Boolean}
   */
  private getChecked = () => (this.controlled ? this.props.checked : this.state.checked);
}
