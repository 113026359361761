import React, { useEffect } from 'react';
import { Typography, Row, Col } from 'antd';
import { isEmpty, map } from 'lodash';
import TableView from './Sections/TableView';
import { IAdditionalDeeplinkGroupedMember, IManageDeeplinkForm, TMember } from '../../../MembersWizard/types';
import FormView from './Sections/FormView';

interface IProps {
  setDefaultDeeplinkUrl: (value: string) => void;
  selectedActiveMembers: (TMember & { status: string })[];
  isFormViewVisible: boolean;
  setFormViewVisible: (isFormViewVisible: boolean) => void;
  additionalDeeplinkGroupedMembers: IAdditionalDeeplinkGroupedMember;
  formData: IManageDeeplinkForm;
  setFormData: (val: IManageDeeplinkForm) => void;
  setLinkValidation: (val: boolean) => void;
  selectedLabels: string[];
  setSelectedLabels: (val: string[]) => void;
}

const { Title, Text } = Typography;

const ManageDeepLink: React.FC<IProps> = ({
  setDefaultDeeplinkUrl,
  selectedActiveMembers = [],
  isFormViewVisible,
  setFormViewVisible,
  additionalDeeplinkGroupedMembers,
  formData,
  setFormData,
  setLinkValidation,
  selectedLabels,
  setSelectedLabels,
}) => {
  useEffect(() => {
    setFormViewVisible(false);
    setDefaultDeeplinkUrl('');
    setFormData({});
  }, [setFormViewVisible, setDefaultDeeplinkUrl, setFormData]);

  // update form data
  useEffect(() => {
    const initialData = {};
    map(selectedLabels, (selectedLabel) => {
      initialData[selectedLabel] = {
        label: selectedLabel,
        url: '',
      };
    });
    setFormData(initialData);
  }, [selectedActiveMembers, additionalDeeplinkGroupedMembers, selectedLabels, setFormData]);

  // update final grouped
  useEffect(() => {
    let validationFlag = false;
    map(formData, (value) => {
      if (isEmpty(value.label)) {
        validationFlag = true;
      }
      // if url exists and url is not valid then link validation = true;
      if (!isEmpty(value.url)) {
        try {
          const url = new URL(value.url);
          console.log({ url });
        } catch (err) {
          validationFlag = true;
        }
      }
    });
    setLinkValidation(validationFlag);
  }, [formData, setLinkValidation]);

  return (
    <div className="p-6">
      <Title level={5}>Select Links you want to edit</Title>
      <Text>You can select one or multiple links to edit at a time</Text>
      <Row justify="space-between" align="middle" style={{ marginTop: '16px' }}>
        <Col>
          <Text strong>
            {selectedActiveMembers?.length ?? 0}
            {' '}
            Members Selected
          </Text>
        </Col>
      </Row>
      <div className="mt-6 flex flex-col gap-2">
        {!isFormViewVisible ? (
          <TableView
            selectedMembers={selectedActiveMembers}
            additionalDeeplinkGroupedMembers={additionalDeeplinkGroupedMembers}
            // additionalDeeplinkGroupedState={additionalDeeplinkGroupedState}
            setSelectedLabels={setSelectedLabels}
          />
        ) : (
          <FormView
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </div>
    </div>
  );
};

export default ManageDeepLink;
