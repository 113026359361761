/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_a3nvq_157 {
  display: flex;
}

._justify-content-space-between_a3nvq_161 {
  justify-content: space-between;
}

._tabular-nums_a3nvq_165 {
  font-variant-numeric: tabular-nums;
}

._SocialPostTile_a3nvq_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_a3nvq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._SocialPostTile_a3nvq_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._SocialPostTile_a3nvq_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._SocialPostTile_a3nvq_169 ::-webkit-scrollbar-track, ._SocialPostTile_a3nvq_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._SocialPostTile_a3nvq_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._SocialPostTile_a3nvq_169 {
  padding: 1rem;
  border-radius: 1rem;
  will-change: background-color;
  transition: background-color 0.15s ease-out;
}
._SocialPostTile_a3nvq_169:hover {
  background-color: rgba(239, 245, 249, 0.5);
}
._SocialPostTile_a3nvq_169:hover ._image_a3nvq_213 {
  filter: brightness(0.3);
}
._SocialPostTile_a3nvq_169:hover ._media_a3nvq_216 ._text_a3nvq_216 {
  opacity: 1;
}
._SocialPostTile_a3nvq_169 ._media_a3nvq_216 {
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
}
._SocialPostTile_a3nvq_169 ._media_a3nvq_216 ._image_a3nvq_213 {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ececec;
}
._SocialPostTile_a3nvq_169 ._media_a3nvq_216 ._text_a3nvq_216 {
  padding: 15px 12px;
  position: absolute;
  bottom: 0;
  color: #fdfdfd;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.1s ease-out;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 {
  margin-bottom: 20px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._avatar_a3nvq_245 {
  margin-right: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  cursor: pointer;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._avatar_a3nvq_245 ._avatarImage_a3nvq_252 {
  width: 100%;
  height: 100%;
  border-radius: 624.9375rem;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._avatar_a3nvq_245 ._source_a3nvq_257 {
  position: absolute;
  bottom: -7px;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  background-color: white;
  border: 1px solid #ececec;
  border-radius: 100px;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._avatar_a3nvq_245 ._source_a3nvq_257 ._sourceImage_a3nvq_270 {
  width: 14px;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._info_a3nvq_273 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 0.875rem;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._info_a3nvq_273 ._name_a3nvq_283 {
  width: 100%;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._info_a3nvq_273 ._name_a3nvq_283 a {
  color: #1a1818;
  text-decoration: none;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._info_a3nvq_273 ._name_a3nvq_283 a:hover {
  color: #4fa7ee;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._info_a3nvq_273 ._date_a3nvq_300 {
  font-weight: 300;
  color: #8f8d91;
}
._SocialPostTile_a3nvq_169 ._details_a3nvq_238 ._info_a3nvq_273 ._date_a3nvq_300:not(:empty) {
  margin-top: 0.375rem;
}
._SocialPostTile_a3nvq_169 ._metrics_a3nvq_307 {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._SocialPostTile_a3nvq_169 ._metrics_a3nvq_307 ._reach_a3nvq_314 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  will-change: color;
  transition: color 0.1s ease-out;
}
._SocialPostTile_a3nvq_169 ._metrics_a3nvq_307 ._reach_a3nvq_314:hover {
  color: #3996e0;
}
._SocialPostTile_a3nvq_169 ._metrics_a3nvq_307 ._reach_a3nvq_314 ._amount_a3nvq_328 {
  margin-left: 5px;
}
._SocialPostTile_a3nvq_169 ._metrics_a3nvq_307 ._engagement_a3nvq_331 {
  margin-left: auto;
  margin-right: 0;
}
._SocialPostTile_a3nvq_169 ._metrics_a3nvq_307 ._engagement_a3nvq_331 ._unit_a3nvq_335 {
  margin-left: 5px;
  color: gray;
}