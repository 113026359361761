import {
  filter, map, reject, isEmpty, maxBy, includes, split, last, size,
} from 'lodash';
import {
  ILicensedContent, IMedia, ISocialPost, TLicensedContentType,
 } from '../models';

export const PREVIEW_RESOLUTION = 'aiq_preview_resolution';
export const PREVIEW_RESOLUTION_ORIGINAL = 'aiq_preview_resolution_original';
export const AIQ_PREVIEW_RESOLUTIONS = [PREVIEW_RESOLUTION, PREVIEW_RESOLUTION_ORIGINAL];
const PREVIEWABLE_FILE_TYPES = ['jpg', 'jpeg', 'png'];

const getHighestQualityMedia = (mediaList: IMedia[], contentType: 'image' | 'video', multiContentType?: boolean): IMedia[] => {
  // Keep only corresponding media types if not multiContentType.
  // Example: IG carousel content can include images and videos, but contentType will be Image.
  if (!multiContentType) mediaList = filter(mediaList, { media_type: contentType });

  // Filter out the preview resolution.
  mediaList = reject(mediaList, { resolution: PREVIEW_RESOLUTION });

  if (isEmpty(mediaList)) {
    return [];
  }
  if (multiContentType) {
    return mediaList;
  }
  const originalBySize = maxBy(mediaList, 'size');

  if (originalBySize && originalBySize.size !== null) {
    return [originalBySize];
  }

  return [maxBy(mediaList, (media) => media.width || 0)];
};

// storage urls are in either of these two keys
function getDownloadableMediaArray(content: ILicensedContent): IMedia[] {
  return !isEmpty(content.social_post?.media) ? content.social_post.media : content.downloadable_media || [];
}

function getSocialPostContentType(socialPost: ISocialPost): TLicensedContentType {
  return socialPost.media_type === 'video' ? 'video' : 'image';
}

const isIGStoryVideo = (content: ILicensedContent, content_type: TLicensedContentType) => (content_type === 'video' && content.social_post.post_type === 'InstagramStory');

export const getDownloadableMedia = (content: ILicensedContent): IMedia[] => {
  const multi = content.social_post?.media_type === 'carousel';
  const downloadable_media = getDownloadableMediaArray(content);
  const content_type: TLicensedContentType = content.social_post?.media_type ? getSocialPostContentType(content.social_post) : content.content_type;
  if (!isEmpty(downloadable_media)) {
    let media = getHighestQualityMedia(downloadable_media, content_type, multi);
    // if there is not a cached video for an instagram story, just return all downloadable media
    if (isEmpty(media) && isIGStoryVideo(content, content_type)) {
      media = downloadable_media;
    }
    // there seems to be an issue capturing the video for some content so if there is no media, fallback to image
    if (isEmpty(media)) {
      return getHighestQualityMedia(downloadable_media, 'image', multi);
    }
    return media;
  }
  // instagram video urls for stories are not downloadable because they expire
  // so return image in this scenario.
  if (isIGStoryVideo(content, content_type)) {
    return getHighestQualityMedia(content.media, 'image', multi);
  }
  return getHighestQualityMedia(content.media, content_type, multi);
};

export const getDownloadableMediaURLs = (content: ILicensedContent): string[] => {
  const medias = getDownloadableMedia(content);

  if (medias) {
    return Array.isArray(medias) ? map(filter(medias, (m) => !!m), (m) => m.url) : [];
  } else if (content.social_post) {
    return [content.social_post[content.content_type]];
  }
  return [];
};

export const getImage = (content: ILicensedContent): string => {
  if (content.social_account) {
    return content.social_post.image;
  }
  if (size(content.image) > 0) {
    return content.image;
  }
  if (size(content.image_link) > 0) {
    return content.image_link;
  }
  if (size(content.media) > 0) {
    return content.media[0].url;
  }
  return null;
};

const canBrowserPreviewContent = (content: ILicensedContent): boolean => includes(PREVIEWABLE_FILE_TYPES, last(split(content.image, '.')));

export const getPreviewableMediaUrl = (content: ILicensedContent, resolution: string): string => {
  /** We check against canBrowserPreviewContent(content) because some file types cannot be displayed in browser.
   *  If it cannot, we need to find a media that is previewable (based on the resolution provided)
   */
  if (resolution && size(content.media) > 0 && !canBrowserPreviewContent(content)) {
    const media = filter(content.media, { resolution });
    if (size(media) > 0) {
      return media[0].url;
    }
  }
  return getImage(content);
};

export const getDetailedMediaUrl = (content: ILicensedContent): string =>
  getPreviewableMediaUrl(content, PREVIEW_RESOLUTION_ORIGINAL);

export enum MediaTypes {
  MEDIA_IMAGE,
  MEDIA_VIDEO,
  SOCIAL_POST_INSTAGRAM,
  SOCIAL_POST_YOUTUBE,
  SOCIAL_POST_SNAPCHAT,
}

export const getMediaTypeForContent = (content: ILicensedContent): MediaTypes => {
  if (!content) {
    return null;
  }

  if (!content.social_post && content.content_type === 'image') {
    return MediaTypes.MEDIA_IMAGE;
  } else if (!content.social_post && content.content_type === 'video') {
    return MediaTypes.MEDIA_VIDEO;
  } else if (content.social_post && content.social_post.network_identifier === 'youtube') {
    return MediaTypes.SOCIAL_POST_YOUTUBE;
  } else if (content.social_post && content.social_post.network_identifier === 'instagram') {
    return MediaTypes.SOCIAL_POST_INSTAGRAM;
  } else if (content.social_post && content.social_post.network_identifier === 'snapchat') {
    return MediaTypes.SOCIAL_POST_SNAPCHAT;
  }

  // Default show image
  return MediaTypes.MEDIA_IMAGE;
};
