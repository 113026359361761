import * as React from 'react';
import cx from 'classnames';
import {
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
} from '@revfluence/fresh';

import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { IShopifyFormElementProps, IShopifyPromoCodeFormValues } from '../../../types';
import { validatePositiveInteger } from '../../../utils';

import styles from '../../../OfferForm.scss';

interface IProps extends IShopifyFormElementProps<'specialLimitUsageCapEnabled'> {
  value: IShopifyPromoCodeFormValues['specialLimitUsageCapEnabled'];

}

export const OfferCodeLimitCodeUses: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
}) => {
  const className = cx({
    [styles.halfRow]: value,
  });
  const rules = [
    { validator: validatePositiveInteger },
  ];
  return (
    <>
      <Form.Item className={className} name={name} valuePropName="checked">
        <Checkbox disabled={disabled}>
          Limit number of times code can be used in total
        </Checkbox>
      </Form.Item>
      {value && (
        <Row>
          <Col className={styles.indented} span={24}>
            <Form.Item label="Maximum #" name="specialLimitUsageCapAmount" rules={rules}>
              <InputNumber
                disabled={disabled}
                min={0}
                onKeyDown={UseNaturalNumbers}
                placeholder="0"
                precision={0}
                size="large"
                type="number"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
});
