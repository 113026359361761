import { getSocialHandle, IGetSocialHandle } from './getSocialHandle';

/**
 * Properly formats the username depending on the social network
 * e.g. prepend with @ on instagram and twitter accounts
 * @param network Social network
 * @param username Username
 * @param shouldValidateUsername Wheter validation for username should take place
 * @param shouldPrependAtSymbol = true Wheter @ symbol should prepend on Twitter and IG
 */
export const formatUsername = (props: IGetSocialHandle) => getSocialHandle(props);
