import React from 'react';
import {
 Button, Space, Tooltip, Typography, Modal,
} from '@revfluence/fresh';
import { PlusIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { MemberFragment_talentAgents } from '@frontend/app/queries/fragments/types/MemberFragment';
import { UPSERT_MEMBER_AGENT } from '@frontend/app/queries/UpsertMemberAgent';
import { DELETE_MEMBER_AGENT } from '@frontend/app/queries/DeleteMemberAgent';
import { useMutation } from '@apollo/react-hooks';
import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { UpsertMemberAgent, UpsertMemberAgentVariables } from '@frontend/app/queries/types/UpsertMemberAgent';
import { DeleteMemberAgent, DeleteMemberAgentVariables } from '@frontend/app/queries/types/DeleteMemberAgent';
import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';
import { MemberInput, MemberAgentUpsertInput } from '@frontend/app/types/globalTypes';
import { last, size, map } from 'lodash';
import { TalentAgentsPanel, formValues } from './TalentAgentsPanel';
import styles from './TalentAgents.scss';

const { Text } = Typography;

const { useState } = React;

interface Props {
  curMember: GetMemberQuery_member | MemberInput; // member we want assign agent relation to
  existingTalentAgents: MemberFragment_talentAgents[]; // existing agents relation with the member
  refetch: () => void; // refetch function to update the member data once we submit
  isAddingMember?: boolean; // flag for the add new member flow
  selectedAgents?: MemberAgentUpsertInput[]; // agents to be added during add new member flow
  setSelectedAgents?: (agents: MemberAgentUpsertInput[]) => void; // set agents to be added during add new member flow
}

const TalentAgents: React.FunctionComponent<Props> = (props: Props) => {
  const {
    curMember, existingTalentAgents = [], refetch, selectedAgents = [], setSelectedAgents = () => {}, isAddingMember = false,
  } = props;

  const curMemberId = (curMember as GetMemberQuery_member)?.id;
  const { showMessage, showErrorMessage } = useMessagingContext();

  // Existing and new agents panel to be shown, if for the add agent flow, use the selectedAgents.length
  const getNewAgentPanelArray = (agentsCount: number) => Array.from({ length: agentsCount }, (_, index) => index + 1);
  const [newAgentsPanel, setNewAgentsPanel] = useState<number[]>(getNewAgentPanelArray(selectedAgents.length));

  // Function to add new agent panel
  const handleAddNewAgentPanel = () => {
    // only allow 3 new agent panels for now
    if ((existingTalentAgents?.length || 0) + newAgentsPanel.length < 3) {
      const newPanel = (last(newAgentsPanel) || 0) + 1;
      setNewAgentsPanel([...newAgentsPanel, newPanel]);
    }
  };

  // Mutations and function for upserting agents
  const [upsertMemberAgent] = useMutation<UpsertMemberAgent, UpsertMemberAgentVariables>(UPSERT_MEMBER_AGENT, {
    onCompleted: async (data) => {
      if (data) {
        logger.debug('Upsert Member Agent successful', data);
        showMessage({
          type: 'success',
          content: 'Talent manager successfully updated',
        });
      }
    },
    onError: (error) => {
      showErrorMessage(`Unable to update talent manager. Please try again or contact our support team. ${error.message}`);
      logger.error('Error updating talent manager', error);
    },
  });
  const onInsertAgent = async (values: formValues) => {
    const {
      agentEmail, agentName, alwaysCC, agentId, agentIndex,
    } = values;
    const params = {
      agentEmail,
      agentName,
      alwaysCC,
      agentId,
      memberId: curMemberId,
    };
    if (isAddingMember) {
      selectedAgents[agentIndex - 1] = params;
      setSelectedAgents(selectedAgents);
    } else {
      await upsertMemberAgent({
        variables: {
          params,
        },
      });
      if (!agentId) {
        setNewAgentsPanel([]);
      }
      refetch();
    }
  };

  // Mutations and function for deleting agents
  const [deleteMemberAgent] = useMutation<DeleteMemberAgent, DeleteMemberAgentVariables>(DELETE_MEMBER_AGENT, {
    onCompleted: async (data) => {
      if (data) {
        logger.debug('Delete Member Agent successful', data);
        showMessage({
          type: 'success',
          content: 'Talent manager successfully deleted from member profile',
        });
        refetch();
      }
    },
    onError: (error) => {
      showErrorMessage('Unable to delete talent manager. Please try again or contact our support team.');
      logger.error('Error updating talent manager', error);
    },
  });
  const onDeleteAgent = async (agentIndex: number) => {
    // Only call delete mutation if we are deleting an existing agent
    if (agentIndex <= existingTalentAgents.length) {
      const agent = existingTalentAgents[agentIndex - 1];
      const agentDisplay = agent?.agentName || agent?.agentEmail;
      const memberName = curMember?.name;
      Modal.confirm({
        title: 'Are you sure you want to delete this talent manager?',
        content: (
          <>
            <span className={styles.ConfirmationModal}>{agentDisplay}</span>
            {' '}
            will be removed from
            {' '}
            <span className={styles.ConfirmationModal}>{memberName}</span>
            ’s profile.
            {' '}
            You will lose the ability to
            automatically CC them on all messages.
          </>
        ),
        icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
        okText: 'Delete',
        cancelText: 'Cancel',
        closable: true,
        okButtonProps: {
          danger: true,
          type: 'ghost',
        },
        onOk: async () => {
          await deleteMemberAgent({
            variables: {
              params: {
                memberId: curMemberId,
                agentId: agent.agentId,
              },
            },
          });
        },
      });
    } else {
      const newPanelNumber = agentIndex - existingTalentAgents.length;
      setNewAgentsPanel(newAgentsPanel.filter((item) => item !== newPanelNumber));
      setSelectedAgents(selectedAgents.map((agent, index) => (index === newPanelNumber - 1 ? undefined : agent)));
    }
  };

  // Functions for rendering agents panel
  const renderManagerPanel = (talentAgents: MemberFragment_talentAgents[]) => map(talentAgents, (agent, index) => (
    <TalentAgentsPanel
      key={agent.agentId}
      agent={agent}
      agentIndex={index + 1}
      onFinish={onInsertAgent}
      onDelete={onDeleteAgent}
      existingAgentEmails={talentAgents
          .map((otherAgent) => otherAgent.agentEmail.toLowerCase())
          .filter((email) => email !== agent.agentEmail.toLowerCase())}
    />
  ));
  const renderNewManagerPanel = (newAgentsPanel: number[]) => newAgentsPanel.map((newAgentIndex) => (
    <TalentAgentsPanel
      key={newAgentIndex + existingTalentAgents.length}
      agentIndex={newAgentIndex + existingTalentAgents.length}
      openModal
      onFinish={onInsertAgent}
      onDelete={onDeleteAgent}
      existingAgentEmails={existingTalentAgents.map((otherAgent) => otherAgent.agentEmail.toLowerCase())}
    />
    ));

  const talentAgentCount = size(existingTalentAgents);
  const newAgentCount = size(newAgentsPanel);
  const totalAgents = talentAgentCount + newAgentCount;

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {renderManagerPanel(existingTalentAgents)}
      {renderNewManagerPanel(newAgentsPanel)}
      {totalAgents >= 3 ? (
        <Text type="secondary">Max 3 talent managers per member.</Text>
      ) : (
        <Tooltip title="Add Talent Manager">
          <Button icon={<PlusIcon />} size="small" onClick={handleAddNewAgentPanel} />
        </Tooltip>
      )}
    </Space>
  );
};

export default TalentAgents;
