/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Toggle_lr7ue_32 {
  position: relative;
  width: 45px;
  height: 22px;
  border-radius: 100px;
  background-color: #e6e4e9;
  will-change: background-color;
  transition: background-color 0.1s ease;
  cursor: pointer;
}
._Toggle_lr7ue_32._active_lr7ue_42 {
  background-color: #3996e0;
}
._Toggle_lr7ue_32._active_lr7ue_42 ._circle_lr7ue_45 {
  transform: translateX(22px);
}
._Toggle_lr7ue_32 ._circle_lr7ue_45 {
  position: absolute;
  left: 4px;
  top: 3px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 100px;
  transform: translateX(0);
  will-change: transform;
  transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}