import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const KeyboardArrowLeftIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M17.3,22c-0.3,0-0.5-0.1-0.8-0.3L5.9,12.9c-0.3-0.2-0.4-0.6-0.4-0.9c0-0.4,0.2-0.7,0.4-0.9l10.6-8.8
      c0.5-0.4,1.3-0.4,1.7,0.2c0.4,0.5,0.4,1.3-0.2,1.7L8.6,12l9.5,7.9c0.5,0.4,0.6,1.2,0.2,1.7C18,21.9,17.7,22,17.3,22"
    />
  </SvgIcon>
));

KeyboardArrowLeftIcon.displayName = 'KeyboardArrowLeftIcon';

export { KeyboardArrowLeftIcon };
