import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_MEMBERS_BY_EMAIL } from '@frontend/app/queries';
import { GetMemberByEmailQuery, GetMemberByEmailQueryVariables } from '@frontend/app/queries/types/GetMemberByEmailQuery';

type IOptions = QueryHookOptions<GetMemberByEmailQuery, GetMemberByEmailQueryVariables>;
export const useGetMembersByEmailQuery = (options: IOptions = {}) => (
  useQuery<GetMemberByEmailQuery, GetMemberByEmailQueryVariables>(GET_MEMBERS_BY_EMAIL, {
    ...options,
    fetchPolicy: 'cache-and-network',
  })
);
