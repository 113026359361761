import * as React from 'react';
import { map, filter } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { useMessagingContext } from '@frontend/hooks';
import { ArtifactAssignmentForm, IAssignmentAPIPayload } from '@frontend/applications/Shared/components/ArtifactAssignmentForm';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { LoadSpinner, Button } from '@components';
import { useMemo, useState } from 'react';
import { IContract, IUpdateContractPayload } from '../useFetchContracts';

import { saveContractData } from '../useSendContract';
import { useFetchContractsData } from '../useFetchContracts';

import { ContractCell } from './ContractCell';

import styles from './MemberContractList.scss';

const MemberContractList: React.FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const { backendServerApiEndpoint, clientId, memberId } = useApplication();

  const {
    showErrorMessage,
  } = useMessagingContext();

  const [contractToEdit, setContractToEdit] = useState<IContract>(null);
  const [savingContract, setSavingContract] = useState<boolean>(false);
  const [canceledContractCount, setCanceledContractCount] = useState<number>(0);

  const { data, loading } = useFetchContractsData(
    `${backendServerApiEndpoint}/contract`,
    {
      client_id: clientId,
      member_id: memberId,
    },
  );

  const activeContracts = useMemo(() => {
    if (!data) {
      return null;
    }
    return filter(data, (contract) => !contract.cancelled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, canceledContractCount]);

  const sendNewContract = () => {
    history.push({
      ...location,
      pathname: './new',
    });
  };

  const onEditContract = (contract) => {
    setContractToEdit(contract);
  };

  const onArtifactAssignmentSave = (assignment: IAssignmentAPIPayload) => {
    saveContract(contractToEdit.id, assignment, goBackToListView);
  };

  const goBackToListView = () => {
    setContractToEdit(null);
  };

  const saveContract = (contractId: string, payload: IUpdateContractPayload, success) => {
    setSavingContract(true);
    const contractUrl = `${backendServerApiEndpoint}/contract/${contractId}`;
    const params = { ...payload, client_id: clientId };
    saveContractData(contractUrl, params).then((response) => {
      if (response.status.error_msg) {
        showErrorMessage('Your contract could not be cancelled at this time. Executed contracts cannot be cancelled.');
      } else {
        success();
      }
    }).catch(() => {
      showErrorMessage('Your contract could not be cancelled at this time. Executed contracts cannot be cancelled.');
    }).finally(() => {
      setSavingContract(false);
    });
  };

  const onCancelContract = (contract) => {
    const payload = { ...contract, cancelled: true };
    saveContract(contract.id, payload, () => {
      contract.cancelled = true;
      setCanceledContractCount(canceledContractCount + 1);
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formParams: any = {};
  return (
    <div className={styles.MemberContractList}>
      {contractToEdit ? (
        <ArtifactAssignmentForm
          {...formParams}
          artifact={contractToEdit}
          artifactName="contract"
          onSave={onArtifactAssignmentSave}
          onSkip={goBackToListView}
          loading={savingContract}
        />
      ) : (
        <div>
          <div className={styles.toolbar}>
            {activeContracts && (
            <span className={styles.displaying_disclaimer}>
              Showing
              {' '}
              {activeContracts.length}
              {' '}
              contracts
            </span>
)}
            <div className={styles.new_button}>
              <Button label="Send New Contract" onClick={sendNewContract} />
            </div>
          </div>

          {loading || !activeContracts ? <LoadSpinner /> : activeContracts.length === 0
            ? <div className={styles.none_placeholder}>You have no contracts yet</div>
            : (
              <ul className={styles.contractList}>
                {map(activeContracts, (contract, index) => (
                  <ContractCell
                    contract={contract}
                    key={index}
                    onEditContract={onEditContract}
                    onCancelContract={onCancelContract}
                  />
              ))}
              </ul>
)}
        </div>
)}
    </div>
  );
};

export default MemberContractList;
