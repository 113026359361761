import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import {
  map, keys, pull, find, omit, mapValues, filter, isNull,
} from 'lodash';

import {
  NetworkIcon,
  Notice,
  LoadSpinner,
  Tooltip,
  EstImpressionsLabel,
} from '@components';
import { getNetworkByPostType, POST_TYPE_NAME_PLURAL, TPostType } from '@components';

import { useAnalyzeCompare } from '../../../useAnalyzeCompare';

import styles from './PostTypeSummarySection.scss';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

const { useRef } = React;

export interface ISummaryData {
  post_type: TPostType;
  tmv: number;
  impressions?: number;
  estimated_impressions?: number;
  likes?: number;
  comments?: number;
  favorites?: number;
  exits?: number;
  taps?: number;
  clicks?: number;
  views?: number;
  dislikes?: number;
  shares?: number;
  reactions?: number;
  closeups?: number;
  unique_visitors?: number;
  roi_pct?: number;
  impression_cpm?: number;
}

const SPECICIAL_NAME_MAP: {
  [key in keyof ISummaryData]?: string | React.ReactNode;
} = {
  roi_pct: 'TMV ROI',
  impression_cpm: 'Impression CPM',
  estimated_impressions: <EstImpressionsLabel />,
  unique_visitors: 'Unique Visitors',
  favorites: 'Saves',
};
const DATA_DOLLAR_FIELD: {
  [key in keyof ISummaryData]?: boolean;
} = {
  tmv: true,
};
const DATA_PERCENTAGE_FIELD: {
  [key in keyof ISummaryData]?: boolean;
} = {
  roi_pct: true,
};
interface IProps {
  className?: string;
  data: { loading: boolean, data?: ISummaryData[], error: Error | null };
  dataCompare: { loading: boolean, data?: ISummaryData[], error: Error | null };
  isComparing: boolean;
  renderZeroState?: boolean;
}
/**
 * @type {React.FunctionComponent}
 */

// Order from doc https://aspireiq.atlassian.net/wiki/spaces/AIQ/pages/71041088/Summary#Network-Cards

const POST_TYPE_TOOLTIPS = {
  instagram: {
    impressions: 'Total number of times the post has been seen',
  },
  instagram_reel: {
    views: 'Total number of times the reel has been seen',
  },
  instagram_video: {
    views: 'Total number of times the video has been seen',
  },
  instagram_story: {
    impressions: 'Total number of times the post has been seen',
    taps: 'Total number of taps to see the Instagram story\'s previous or next photo or video',
  },
  facebook_post: {
    reactions:
      'Number of reactions on a Facebook post, including likes, loves and cares',
  },
  pinterest: {
    impressions: 'Total number of times a Pin was shown',
    closeups:
      'Total number of times people viewed a close-up version of the Pin',
    clicks:
      'Total number of times people have clicked on the Pin to a destination on or off of Pinterest',
    favorites: 'Total number of times people saved a Pin to a board',
  },
};

const postOrder = [
  'instagram',
  'instagram_reel',
  'instagram_video',
  'instagram_story',
  'facebook_post',
  'youtube_mention',
  'pinterest',
  'twitter_post',
  'blog_mention',
  'tiktok_video',
];

const facebookReactions = (record: ISummaryData) => {
  const facebook_post: TPostType = 'facebook_post';
  return record.post_type === facebook_post
    ? { ...omit(record, ['likes']), reactions: record.likes }
    : record;
};

const useIgEstimationDataFeatureFlag = (data: ISummaryData[], loading: boolean) => {
  const featureFlag = useClientFeatureEnabled(ClientFeature.IG_ESTIMATED_IMPRESSIONS);
  return React.useMemo(() => {
    if (featureFlag === undefined || loading) {
      return { data: [], loading: true };
    }
    if (!featureFlag) {
      // remove estimated impressions
      return { data: (data || []).map((d) => omit(d, 'estimated_impressions')), loading };
    }
    return { data, loading };
  }, [data, loading, featureFlag]);
};


export const PostTypeSummarySection: React.FunctionComponent<IProps> = React.memo(
  (props) => {
    const { isComparing } = useAnalyzeCompare();

    const refs = mapValues(POST_TYPE_TOOLTIPS, (value) =>
      mapValues(value, () => useRef<HTMLDivElement>()));

    const { loading: loadingRequest, data: requestData, error } = props.data
    const { loading: loadingCompareRequest, data: requestDataCompare } = props.dataCompare;
    const { loading, data } = useIgEstimationDataFeatureFlag(requestData, loadingRequest);
    const { loading: loadingCompare, data: dataCompare } = useIgEstimationDataFeatureFlag(requestDataCompare, loadingCompareRequest);

    if (error) {
      return (
        <Notice className={(styles as any).notice} type="error">
          There is an error when trying to fetch the reports.
        </Notice>
      );
    }

    const newData = map(data, facebookReactions);
    const newCompareData = map(dataCompare, facebookReactions);

    let records = map(postOrder, (postType) => newData.find((r) => r.post_type === postType)).filter((record) => record);

    if (props.renderZeroState && records.length === 0) {
      records = filter(map(postOrder, (networkType) => {
        switch (networkType) {
          case 'instagram': return { tmv: 0, post_type: networkType, impressions: 0, likes: 0, comments: 0, saves: 0 };
          case 'instagram_reel': return { tmv: 0, post_type: networkType, likes: 0, comments: 0, views: 0, saves: 0  };
          case 'instagram_story': return { tmv: 0, post_type: networkType, impressions: 0, exits: 0, taps: 0};
          case 'instagram_video': return { tmv: 0, post_type: networkType, views: 0, likes: 0, comments: 0, saves: 0 };
          case 'pinterest': return { tmv: 0, post_type: networkType, impressions: 0, closeups: 0, clicks: 0, saves: 0 };
          case 'youtube_mention': return { tmv: 0, post_type: networkType, views: 0, likes: 0, dislikes: 0, comments: 0 };
          case 'tiktok_video': return { tmv: 0, post_type: networkType, views: 0, comments: 0, likes: 0, shares: 0, cpm: 0 };
          case 'twitter_post': return { tmv: 0, post_type: networkType, likes: 0, shares: 0 };
          default: return null;
        }
      }), (data) => !isNull(data));
    }

    return (
      <div className={cx(styles.PostTypeSummarySection, props.className)}>
        {(loading || loadingCompare) ? <LoadSpinner centered /> : (
          <div className={styles.postGrid}>
            {map(records, (record) => {
              const infoKeys = pull(keys(record), 'post_type');

              /* eslint-disable */
              const compareRecord = isComparing
                ? find(
                  newCompareData,
                  item => item.post_type === record.post_type
                )
                : null;

              /* eslint-enable */
              return (
                <div className={styles.item} key={record.post_type}>
                  <NetworkIcon
                    identifier={getNetworkByPostType(record.post_type)}
                    size={32}
                    className={styles.icon}
                  />
                  <div className={styles.name}>
                    {POST_TYPE_NAME_PLURAL[record.post_type]}
                  </div>

                  <div className={styles.info}>
                    {map(infoKeys, (key) => {
                      const hasTooltip = POST_TYPE_TOOLTIPS[record.post_type]
                        && POST_TYPE_TOOLTIPS[record.post_type][key];

                      return (
                        <div key={key} className={styles.infoItem}>
                          <div
                            ref={
                              hasTooltip ? refs[record.post_type][key] : null
                            }
                            className={cx(styles.key, {
                              [styles.withTooltip]: hasTooltip,
                            })}
                          >
                            {SPECICIAL_NAME_MAP[key] || key}
                          </div>

                          {hasTooltip && (
                            <Tooltip
                              mountRef={refs[record.post_type][key]}
                              placement="bottom"
                              tooltipColor="black"
                            >
                              <div className={styles.tooltipBody}>
                                <span className="">
                                  {POST_TYPE_TOOLTIPS[record.post_type][key]}
                                </span>
                              </div>
                            </Tooltip>
                          )}
                          {/* !eslint is mad at prettier */}
                          {/* eslint-disable */}
                          {props.renderZeroState && record[key] && numeral(record[key]).value() <= 0 ? (
                            <div className={cx(styles.value, styles.compare)}>-</div>
                          ) : (
                            <div className={styles.value}>
                              {DATA_PERCENTAGE_FIELD[key]
                                ? numeral(record[key] / 100).format('0%')
                                : numeral(record[key]).format(
                                  `${DATA_DOLLAR_FIELD[key] ? '$' : ''}0,0`
                                )}
                            </div>
                          )}
                          {isComparing && compareRecord && (
                            <div className={cx(styles.value, styles.compare)}>
                              {DATA_PERCENTAGE_FIELD[key]
                                ? numeral(compareRecord[key] / 100).format('0%')
                                : numeral(compareRecord[key]).format(
                                  `${DATA_DOLLAR_FIELD[key] ? '$' : ''}0,0`
                                )}
                              {/* eslint-enable */}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  },
);
PostTypeSummarySection.defaultProps = {
  className: null,
};

PostTypeSummarySection.displayName = '<PostTypeSummarySection />';
