import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import {
  REMOVE_MEMBERS_FROM_ACTIVATIONS_MUTATION,
} from '@frontend/app/queries';
import {
  RemoveMembersFromActivationsMutation,
  RemoveMembersFromActivationsMutationVariables,
} from '@frontend/app/queries/types/RemoveMembersFromActivationsMutation';

type IOptions = MutationHookOptions<RemoveMembersFromActivationsMutation, RemoveMembersFromActivationsMutationVariables>;

export const useRemoveMembersFromActivationsMutation = (options: IOptions = {}) => useMemberMutation<RemoveMembersFromActivationsMutation, RemoveMembersFromActivationsMutationVariables>(
    REMOVE_MEMBERS_FROM_ACTIVATIONS_MUTATION,
    options,
  );
