import * as React from 'react';
import { isEmpty, startCase } from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { IAddTrackingLinksMembers } from '../../types';
import { TActions, TState } from './state';
import { getSteps } from './getSteps';

import styles from '../../components/MembersWizard.scss';
import { getWorkflowTrackingLinksSteps } from './getWorkflowTrackingLinksSteps';

const { useMemo } = React;

export const getTrackingLinksSteps = (props: IAddTrackingLinksMembers, state: TState, actions: TActions) => {
  const {
    onClose,
    programLabel,
    isWorkflow,
    migrateToGraphQL,
  } = props;

  if (isWorkflow) {
    return getWorkflowTrackingLinksSteps(props, state, actions);
  }

  const {
    selectPrograms,
    selectMembers,
    reviewMembers,
    completed,
    selectedMembers,
  } = getSteps(props, state, actions);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasMemberSelectedWithError = useMemo(() =>
    (state.members || []).some((member) => !isEmpty(member.deepLink?.error) && member.selected),
    [state.members]);
  const nextButtonDisabled = isEmpty(selectedMembers) || hasMemberSelectedWithError;

  const steps = [
    selectPrograms({
      instructions: {
        description: 'You can confirm which members will get these codes in the next step.',
        title: 'Select which projects you would like to be part of this offer.',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: isEmpty(state.selectedPrograms),
        text: 'Confirm Members',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    selectMembers({
      instructions: {
        description: 'These are the members who will be receiving links for this offer.',
        title: 'Who will receive these sales tracking links?',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Review',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        text: `Select ${startCase(programLabel)}s`,
      },
      stepNum: 2,
    }),
    reviewMembers({
      instructions: {
        description: state.saving ? '' : 'Make sure everything looks good before you create the unique tracking links. You can email them to the members from this offer page.',
        title: state.saving ? '' : 'Review your link tracking details.',
      },
      nextButtonConfig: {
        action: () => actions.save(4),
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Links',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Confirm Members',
      },
      stepNum: 3,
    }, { disableNextStep: true }),
    completed({
      instructions: {
        description: `
        Their unique tracking links are starting to be generated, it should only take a
        few minutes. You can close this window. Once all tracking links have been
        generated, you can email these members their links using the
        “Email Members” button above the table on this offer page.
       `.trim(),
        title: `
        Congrats! You are generating unique tracking links for ${`${selectedMembers.length.toLocaleString()} `}
        member${selectedMembers.length !== 1 ? 's' : ''}.
      `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 4,
    }),
  ];

  const newSteps = [
    selectMembers({
      instructions: {
        description: 'These are the members who will be receiving links for this offer.',
        title: 'Who will receive these sales tracking links?',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Review',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        action: onClose,
        text: migrateToGraphQL ? 'Close' : `Select ${startCase(programLabel)}s`,
      },
      stepNum: 1,
    }),
    reviewMembers({
      instructions: {
        description: state.saving ? '' : 'Make sure everything looks good before you create the unique tracking links. You can email them to the members from this offer page.',
        title: state.saving ? '' : 'Review your link tracking details.',
      },
      nextButtonConfig: {
        action: () => actions.save(4),
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Links',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Confirm Members',
      },
      stepNum: 2,
    }, { disableNextStep: true }),
    completed({
      instructions: {
        description: `
        Their unique tracking links are starting to be generated, it should only take a
        few minutes. You can close this window. Once all tracking links have been
        generated, you can email these members their links using the
        “Email Members” button above the table on this offer page.
       `.trim(),
        title: `
        Congrats! You are generating unique tracking links for ${`${selectedMembers.length.toLocaleString()} `}
        member${selectedMembers.length !== 1 ? 's' : ''}.
      `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 3,
    }),
    completed({
      instructions: {
        description: `
        Their unique tracking links are starting to be generated, it should only take a
        few minutes. You can close this window. Once all tracking links have been
        generated, you can email these members their links using the
        “Email Members” button above the table on this offer page.
       `.trim(),
        title: `
        Congrats! You are generating unique tracking links for ${`${selectedMembers.length.toLocaleString()} `}
        member${selectedMembers.length !== 1 ? 's' : ''}.
      `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 4,
    }),
    completed({
      instructions: {
        description: `
        Their unique tracking links are starting to be generated, it should only take a
        few minutes. You can close this window. Once all tracking links have been
        generated, you can email these members their links using the
        “Email Members” button above the table on this offer page.
       `.trim(),
        title: `
        Congrats! You are generating unique tracking links for ${`${selectedMembers.length.toLocaleString()} `}
        member${selectedMembers.length !== 1 ? 's' : ''}.
      `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 5,
    }),
  ];
  let currentSteps = migrateToGraphQL ? newSteps : steps;
  if (state.error) {
    currentSteps = currentSteps.map((currentSteps) => ({
      ...currentSteps,
      actionComponents: (
        <Notice type="error" message={state.error.message} />
      ),
    }));
  }

  return currentSteps;
};
