import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const BookmarkIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="
        M6 21C5.83 21 5.66 20.957 5.507 20.87C5.194 20.693 5 20.36 5 20V5.333C5 4.047 5.987 3 7.2
        3H16.8C18.013 3 19 4.047 19 5.333V20C19 20.355 18.811 20.685 18.504 20.864C18.197 21.044
        17.818 21.046 17.508 20.871L11.836 17.663L6.515 20.857C6.356 20.952 6.178 21 6 21Z
      "
      fill={props.fill}
    />
    <path
      d="
        M6 21C5.83 21 5.66 20.957 5.507 20.87C5.194 20.693 5 20.36 5 20V5.333C5
        4.047 5.987 3 7.2 3H16.8C18.013 3 19 4.047 19 5.333V20C19 20.355 18.811 20.685
        18.504 20.864C18.197 21.044 17.818 21.046 17.508 20.871L11.836 17.663L6.515 20.857C6.356
        20.952 6.178 21 6 21Z
      "
      fill={props.fill}
    />
  </SvgIcon>
));

BookmarkIcon.displayName = 'BookmarkIcon';
