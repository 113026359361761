import * as React from 'react';
import { debounce } from 'lodash';

const { useState, useLayoutEffect, useRef } = React;

interface IScrollableStatus {
  canScrollUp: boolean;
  canScrollDown: boolean;
  canScrollLeft: boolean;
  canScrollRight: boolean;
}

/**
 * Checks if a scrollable element can scroll up/down/left/right.
 *
 * @return {IScrollableStatus}
 */
export const useScrollableStatus = (ref: React.MutableRefObject<HTMLElement>): IScrollableStatus => {
  const [scrollableStatus, setScrollableStatus] = useState<IScrollableStatus>({
    canScrollUp: undefined,
    canScrollDown: undefined,
    canScrollLeft: undefined,
    canScrollRight: undefined,
  });
  const prevScrollableStatus = useRef(scrollableStatus);

  useLayoutEffect(() => {
    const node = ref && ref.current;
    if (!node) {
      return;
    }

    window.addEventListener('resize', checkScrollableStatus, { passive: true });
    node.addEventListener('scroll', checkScrollableStatus, { passive: true });

    // checks scrollable status immediately
    checkScrollableStatus();

    return () => {
      window.removeEventListener('resize', checkScrollableStatus);
      node && node.removeEventListener('scroll', checkScrollableStatus);
    };
  });

  prevScrollableStatus.current = scrollableStatus;

  /**
   * Checks the scrollable status.
   */
  const checkScrollableStatus = debounce(
    () => {
      const node = ref && ref.current;
      if (!node) {
        return;
      }

      const {
 scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth,
} = node;

      const canScrollUp = scrollTop > 0;
      const canScrollDown = scrollTop + clientHeight < scrollHeight;
      const canScrollLeft = scrollLeft > 0;
      const canScrollRight = scrollLeft + clientWidth < scrollWidth;

      if (
        canScrollUp !== prevScrollableStatus.current.canScrollUp
        || canScrollDown !== prevScrollableStatus.current.canScrollDown
        || canScrollLeft !== prevScrollableStatus.current.canScrollLeft
        || canScrollRight !== prevScrollableStatus.current.canScrollRight
      ) {
        setScrollableStatus({
          canScrollUp,
          canScrollDown,
          canScrollLeft,
          canScrollRight,
        });
      }
    },
    150,
    { trailing: true },
  );

  return scrollableStatus;
};
