import * as React from 'react';
import { Radio } from 'antd';
import {
  Col,
  Form, Input, Row, Typography,
} from '@revfluence/fresh';
import { IShopifyFormElementProps, IShopifyPromoCodeFormValues, RecurringCycleLimit } from '../../../types';
import styles from '../../../OfferForm.scss';
import { validateNonNegativeDecimal } from '../../../utils';

const { Text } = Typography;
interface IProps extends IShopifyFormElementProps<'offerCodePurchaseRestrictionsRule'> {
  value: IShopifyPromoCodeFormValues['offerCodePurchaseRestrictionsRule'];
}

export const OfferRecurringPaymentForSubscriptions: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
  value,
}) => {
  const rules = [
    { validator: validateNonNegativeDecimal },
    {
      validator: (_, value) => {
        if (value < 2) {
          return Promise.reject(new Error('Discount limit must be greater than 1'));
        }
        return Promise.resolve();
      },
    },
  ];
  return (
    <>
      <Form.Item className="add" label="Recurring payments for subscriptions" name={name}>
        <Radio.Group className={styles.radioGroup} disabled={disabled} name={name} value={RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT}>
          <Radio className={styles.radio} value={RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT}>
            {RecurringCycleLimit.LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT}
          </Radio>

          <Radio className={styles.radio} value={RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS}>
            {RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS}
          </Radio>
          {value === RecurringCycleLimit.LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS && (
            <Row>
              <Col className={styles.indented} span={24}>
                <Form.Item
                  rules={rules}
                  name="recurringCycleLimitAmount"

                >
                  <Input
                    className={styles.fullWidth}
                    defaultValue={0}
                    disabled={disabled}
                    size="large"

                  />
                </Form.Item>
                <Text type="secondary">Includes payment on first order.</Text>
              </Col>
            </Row>
          )}
          <Radio className={styles.radio} value={RecurringCycleLimit.DISCOUNT_APPLIES_TO_ALL_RECURRING_PAYMENTS}>
            {RecurringCycleLimit.DISCOUNT_APPLIES_TO_ALL_RECURRING_PAYMENTS}
          </Radio>
        </Radio.Group>
      </Form.Item>

    </>
  );
});
OfferRecurringPaymentForSubscriptions.displayName = 'OfferRecurringPaymentForSubscriptions';
