import { useMutation, QueryHookOptions } from '@apollo/client';

import { DELETE_TAG_MUTATION } from '@frontend/app/queries';
import {
  DeleteTagMutation, DeleteTagMutationVariables,
} from '@frontend/app/queries/types/DeleteTagMutation';

type IOptions = QueryHookOptions<DeleteTagMutation, DeleteTagMutationVariables>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDeleteTagById = (options: IOptions = {}) => useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DELETE_TAG_MUTATION, options as any);
