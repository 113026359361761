import { useQuery } from '@apollo/client';

import { GET_TERMS_TEMPLATES_BY_PROGRAM_ID } from '@frontend/app/queries/TermsTemplatesByProgramIdQuery';
import { TermsTemplatesByProgramIdQuery, TermsTemplatesByProgramIdQueryVariables } from '@frontend/app/queries/types/TermsTemplatesByProgramIdQuery';

export function useGetTermsTemplatesByProgramId(options = {}) {
  return useQuery<TermsTemplatesByProgramIdQuery, TermsTemplatesByProgramIdQueryVariables>(
    GET_TERMS_TEMPLATES_BY_PROGRAM_ID,
    options,
  );
}
