import { first, isArray } from 'lodash';

import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import {
 IFavoriteList, endpoints, ISocialAccount, addEventLog,
} from '@components';
import { ICreatorListAction, ICreatorList } from './creatorListModel';

type CLThunkAction<T> = ThunkAction<Promise<T>, ICreatorList, unknown, ICreatorListAction>;
export type FLPThunkDispatch = ThunkDispatch<ICreatorList, unknown, ICreatorListAction>;

/** *************************
 ***** Private actions *****
 ************************* */
const setFavoriteLists = (favoriteLists: IFavoriteList[]): ICreatorListAction => ({
    type: '@creatorList/SET_FAVORITE_LISTS',
    payload: {
      favoriteLists,
    },
  });

const addFavoriteList = (favoriteList: IFavoriteList): ICreatorListAction => ({
    type: '@creatorList/ADD_FAVORITE_LIST',
    payload: {
      favoriteList,
    },
  });

const updateFavoriteList = (id: number, favoriteList: IFavoriteList): ICreatorListAction => ({
    type: '@creatorList/UPDATE_FAVORITE_LIST',
    payload: {
      id,
      favoriteList,
    },
  });

const removeFavoriteList = (id: number): ICreatorListAction => ({
    type: '@creatorList/REMOVE_FAVORITE_LIST',
    payload: {
      id,
    },
  });

const setIsFetchingFavoriteLists = (isFetchingFavoriteLists: boolean): ICreatorListAction => ({
    type: '@creatorList/SET_IS_FETCHING_FAVORITE_LISTS',
    payload: {
      isFetchingFavoriteLists,
    },
  });

const setIsEditingFavoriteList = (isEditingFavoriteList: boolean): ICreatorListAction => ({
    type: '@creatorList/SET_IS_EDITING_FAVORITE_LIST',
    payload: {
      isEditingFavoriteList,
    },
  });

const setInvitedToCampaign = (accountIds: number[]): ICreatorListAction => ({
    type: '@creatorList/INVITED_TO_CAMPAIGN',
    payload: {
      accountIds,
    },
  });

/** *************************
 ***** public actions *****
 ************************* */
const setSocialAccounts = (socialAccounts: ISocialAccount[]): ICreatorListAction => ({
    type: '@creatorList/SET_SOCIAL_ACCOUNTS',
    payload: {
      socialAccounts,
    },
  });

const fetchFavoriteList = (): CLThunkAction<IFavoriteList[]> => async (dispatch, getState): Promise<IFavoriteList[]> => {
    const state = getState();
    const { brandId, apiEndpoint } = state;

    dispatch(setIsFetchingFavoriteLists(true));
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}?brand_id=${brandId}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      },
    );
    dispatch(setIsFetchingFavoriteLists(false));

    const json = await resp.json();
    const list: IFavoriteList[] = (json && json.data && json.data.data) || [];
    dispatch(setFavoriteLists(list));

    return list;
  };

const createFavoriteList = (name: string): CLThunkAction<void> => async (dispatch, getState): Promise<void> => {
    const state: ICreatorList = getState();
    const { brandId, apiEndpoint } = state;

    const payload = {
      brand_id: brandId,
      name,
    };

    dispatch(setIsEditingFavoriteList(true));
    const resp = await fetch(`${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    dispatch(setIsEditingFavoriteList(false));

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch(addFavoriteList(json.data));
    } else {
      throw new Error(json.status && json.status.error_msg);
    }
  };

const renameFavoriteList = (id: number, name: string): CLThunkAction<void> => async (dispatch, getState): Promise<void> => {
    const state: ICreatorList = getState();
    const { brandId, favoriteLists, apiEndpoint } = state;
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === id);

    const payload = {
      brand_id: brandId,
      name,
    };

    dispatch(setIsEditingFavoriteList(true));
    const resp = await fetch(`${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}/${id}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    dispatch(setIsEditingFavoriteList(false));

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch(
        updateFavoriteList(id, {
          ...favoriteList,
          name,
        }),
      );
    } else {
      throw new Error(json.status && json.status.error_msg);
    }
  };

const deleteFavoriteList = (id: number): CLThunkAction<void> => async (dispatch, getState): Promise<void> => {
    const state: ICreatorList = getState();
    const { apiEndpoint } = state;

    dispatch(setIsEditingFavoriteList(true));
    const resp = await fetch(`${apiEndpoint}/${endpoints.creatorFavoritesListEndpoint}/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });
    dispatch(setIsEditingFavoriteList(false));

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch(removeFavoriteList(id));
    } else {
      throw new Error(json.status && json.status.error_msg);
    }
  };

const inviteToCampaign = (...socialAccounts: ISocialAccount[]): CLThunkAction<void> => async (dispatch, getState): Promise<void> => {
    const count = socialAccounts.length;
    if (count === 0) {
      return;
    }
    const accountIds = socialAccounts.map((socialAccount) => socialAccount.id);

    const state: ICreatorList = getState();
    const { campaign, apiEndpoint } = state;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      approved: true,
      campaign_id: campaign.id,
      source: 'connect.invite_creators',
    };

    if (count > 1) {
      payload.account_ids = accountIds;
    } else {
      payload.account_id = first(accountIds);
    }

    const resp = await fetch(`${apiEndpoint}/${endpoints.campaignInviteEndpoint}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    if (json.status && json.status.code === 200) {
      dispatch(setInvitedToCampaign(accountIds));

      if (count > 1) {
        addEventLog('bulk_invite_creators', {
          count,
          state: 'connect.invite_creators',
          source: 'connect.invite_creators',
        });
      } else {
        const socialAccount = first(socialAccounts);
        addEventLog('invite_creator', {
          account_id: socialAccount.id,
          can_contact: socialAccount.can_contact,
          campaign_id: campaign.id,
          state: 'connect.invite_creators',
          source: 'connect.invite_creators',
        });
      }
    } else {
      throw new Error(json.status && json.status.error_msg);
    }
  };

const addSocialAccountsToFavoriteList = (
  accountIds: number | number[],
  listId: number,
): CLThunkAction<void> => async (dispatch, getState): Promise<void> => {
    const state: ICreatorList = getState();
    const { campaign, favoriteLists, apiEndpoint } = state;
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === listId);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: any = {
      list_id: listId,
      campaign_id: campaign.id,
    };

    let count;
    if (isArray(accountIds)) {
      payload.account_ids = accountIds;
      count = accountIds.length;
    } else {
      payload.account_id = accountIds;
      count = 1;
    }

    const resp = await fetch(`${apiEndpoint}/${endpoints.favoritesListElementEndpoint}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch(
        updateFavoriteList(listId, {
          ...favoriteList,
          element_count: favoriteList.element_count + count,
        }),
      );

      if (count === 1) {
        addEventLog('add_to_favorites_list', {
          state: 'connect.browse_creators_v2',
        });
      } else if (count > 1) {
        addEventLog('bulk_add_to_favorites_list', {
          count,
          state: 'connect.browse_creators_v2',
        });
      }
    } else {
      throw new Error(json.status && json.status.error_msg);
    }
  };

export default {
  setSocialAccounts,
  fetchFavoriteList,
  createFavoriteList,
  renameFavoriteList,
  deleteFavoriteList,
  addSocialAccountsToFavoriteList,
  inviteToCampaign,
};
