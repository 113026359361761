import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { IAffiliateLinksFormValues, TFormValues } from '../types';

export const getRequiredFields = (formValues: TFormValues, formType: OFFER_SOURCE, isAdvanceUrlEnabled: boolean = false, migrateToGraphQL: boolean = false) => {
    const requiredFieldConfiguration = {
        requiredFields: [
            'name',
            'description',
        ],
        requiredFieldsErrorText: {
            name: 'Please add an Offer Name',
            description: 'Please add an Offer Description',
        },
    };
    if (formType === OFFER_SOURCE.TUNE) {
        const tuneRequiredFields = [
            'conversionType',
            'conversionTrackingType',
            'url',
            'expirationDate',
            'status',
        ];
        const advanceUrlRequiredField = [
            'utmRequired',
        ];
        if (!migrateToGraphQL) tuneRequiredFields.push('payoutType');
        requiredFieldConfiguration.requiredFields = requiredFieldConfiguration.requiredFields.concat(tuneRequiredFields);
        if (isAdvanceUrlEnabled) {
            const utmFormValues = formValues as IAffiliateLinksFormValues;
            if (!utmFormValues.utmSource && !utmFormValues.utmMedium && !utmFormValues.utmCampaign && !utmFormValues.utmContent && !utmFormValues.utmTerm) {
                requiredFieldConfiguration.requiredFields = requiredFieldConfiguration.requiredFields.concat(advanceUrlRequiredField);
            }
        }

        const tuneRequiredFieldsErrorText = {
            conversionType: 'Please select a Conversion Type',
            payoutType: 'Please select a Payout Option',
            conversionTrackingType: 'Please select a Conversion Tracking type',
            url: 'Please add an Offer URL',
            expirationDate: 'Please select an Expiration Date',
            status: 'Please select a Status',
            utmRequired: 'Please add one UTM Parameter',
            payoutOptions: 'Please add payout options details',
        };
        if (migrateToGraphQL) {
            requiredFieldConfiguration.requiredFields.push('payoutOptions');
            delete tuneRequiredFieldsErrorText.payoutType;
        }
        requiredFieldConfiguration.requiredFieldsErrorText = { ...requiredFieldConfiguration.requiredFieldsErrorText, ...tuneRequiredFieldsErrorText };
    } if (formType === OFFER_SOURCE.SHOPIFY) {
        const shopifyRequireFields = [
            'prefixType',
            'priceRuleType',
            'priceRuleAmount',
        ];
        if (!migrateToGraphQL) shopifyRequireFields.push('payoutType');
        requiredFieldConfiguration.requiredFields = requiredFieldConfiguration.requiredFields.concat(shopifyRequireFields);
        const shopifyRequiredFieldsErrorText = {
            payoutType: 'Please select one of the Payout Options',
            prefixType: 'Please select a Promo Code Unique Naming Strategy',
            priceRuleType: 'Please select one of the Discount Options',
            priceRuleAmount: 'Please select one of the Discount Values',
            payoutOptions: 'Please add payout options details',
        };
        if (migrateToGraphQL) {
            requiredFieldConfiguration.requiredFields.push('payoutOptions');
            delete shopifyRequiredFieldsErrorText.payoutType;
        }
        requiredFieldConfiguration.requiredFieldsErrorText = { ...requiredFieldConfiguration.requiredFieldsErrorText, ...shopifyRequiredFieldsErrorText };
    }
    return requiredFieldConfiguration;
};
