import * as React from 'react';
import {
  isEmpty, size, filter, keyBy, find, each, trim,
} from 'lodash';

import { LoadSpinner } from '@components';

import { EventName } from '@common';
import { useAddProjectEvent } from '@frontend/app/containers/Projects/hooks';
import {
  IMember,
  IResource,
  IMemberFieldSchema,
  useGetResources,
  useActivationsQuery,
  useProgramsQuery,
  useCommunitiesQuery,
  useGetVariables,
  useSendBulkMessage,
  useGetMembersByIdsQuery,
  useMemberFieldSchemasQuery,
  useMemberSearchQuery,
  useFeatureFlagVerbiage,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { useResourceContext } from '@frontend/app/context';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import { ApolloQueryResult } from '@apollo/client';
import { GetMembersForIdsQuery, GetMembersForIdsQueryVariables } from '@frontend/app/queries/types/GetMembersForIdsQuery';
import { isUSA } from '../utils';

interface IProps {
  memberIds: number[];
  memberQueryJson: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workItems: any[];
}
interface IProductFulfillmentContext {
  productCostEnabled: boolean;
  addAspireShippingLine: boolean;
  addBillingAddress: boolean;
  bypassShopifyInventory: boolean;
  allMembers: IMember[];
  members: IMember[];
  invalidMembers: IMember[];
  schemas: IMemberFieldSchema[];
  refetchMembersByIds: (variables?: Partial<GetMembersForIdsQueryVariables>) => Promise<ApolloQueryResult<GetMembersForIdsQuery>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  communities: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  programs: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activations: any[];

  usePfaContextVariables: boolean;
  setUsePfaContextVariables(usePfaContextVariables: boolean): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variables: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loading: boolean;

  addressSchemas: Record<string, IMemberFieldSchema>;
  getMemberShippingAddress(member: IMember): IShippingAddress;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendBulkMessage(options?: any): Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workItems: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  verbiage: any;
  workflowEnabled: boolean;

  tokenIsInvalid: boolean;
  setTokenIsInvalid(isInvalid: boolean): void;
  shopifyResource: IResource;
  activeEmailResources: IResource[];
}
export interface IShippingAddress {
  // required fields
  first_name: string;
  last_name: string;
  address1: string;
  country: string;
  province: string;
  city: string;
  zip: string;

  // optional fields
  address2?: string;
  phone?: string;
}

const {
  useContext, useState, useEffect, useMemo,
} = React;

const ProductFulfillmentContext = React.createContext<IProductFulfillmentContext>(null);
export const useProductFulfillmentContext = () => useContext<IProductFulfillmentContext>(ProductFulfillmentContext);
export const ProductFulfillmentProvider: React.FC<IProps> = (props) => {
  const {
    children, memberIds, memberQueryJson, workItems,
  } = props;
  const { addProjectEvent } = useAddProjectEvent();
  const { sendBulkMessage } = useSendBulkMessage();
  const [usePfaContextVariables, setUsePfaContextVariables] = useState(false);
  const { variables } = useGetVariables();
  const addBillingAddress = useClientFeatureEnabled(ClientFeature.ADD_BILLING_ADDRESS);
  const addAspireShippingLine = useClientFeatureEnabled(ClientFeature.ADD_ASPIRE_SHIPPING_LINE);
  const bypassShopifyInventory = useClientFeatureEnabled(ClientFeature.BYPASS_SHOPIFY_INVENTORY);
  const verbiage = useFeatureFlagVerbiage();
  const workflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const [tokenIsInvalid, setTokenIsInvalid] = useState(false);
  const {
    activeEmailResources,
  } = useResourceContext();
  const {
    resources,
    loading: loadingResources,
  } = useGetResources({
    variables: {
      ignoreRequestContextUserId: true,
    },
  });
  const {
    loading: communityLoading,
    data: communitiesData,
  } = useCommunitiesQuery();
  const {
    loading: programLoading,
    data: programsData,
  } = useProgramsQuery();
  const {
    loading: activationsLoading,
    activations,
  } = useActivationsQuery();
  const {
    loading: loadingSchemas,
    data: {
      schemas = [],
    } = {},
  } = useMemberFieldSchemasQuery();

  const productCostEnabled = useClientFeatureEnabled(ClientFeature.PRODUCT_COST);

  const hasQuery = isEmpty(memberIds) && memberQueryJson;
  const {
    loading: loadingMembersByIds,
    data: { members: membersByIds = [] } = {},
    refetch: refetchMembersByIds,
  } = useGetMembersByIdsQuery(
    memberIds,
    {
      skip: !!hasQuery,
      fetchPolicy: 'no-cache',
    },
  );
  const {
    loading: loadingMembersByQuery,
    data: { members: membersByQuery = [] } = {},
  } = useMemberSearchQuery({
    variables: {
      query: hasQuery && JSON.parse(memberQueryJson),
      skip: 0,
      take: 100,
    },
    skip: !hasQuery,
    fetchPolicy: 'no-cache',
  });

  const loadingMembers = loadingMembersByIds || loadingMembersByQuery;
  const members = hasQuery ? membersByQuery : membersByIds;
  const loading = communityLoading || programLoading || activationsLoading
    || loadingSchemas || loadingMembers;

  const addressSchemas = useMemo(() => {
    const filtered = filter(schemas, (schema) => schema.isDefault === true && (
      schema.name === 'Address First Name'
      || schema.name === 'First Name'
      || schema.name === 'Address Last Name'
      || schema.name === 'Last Name'
      || schema.name === 'Address1'
      || schema.name === 'Address2'
      || schema.name === 'State'
      || schema.name === 'PostalCode'
      || schema.name === 'City'
      || schema.name === 'Country'
      || schema.name === 'Phone'
    ));

    return keyBy(filtered, 'name');
  }, [schemas]);
  const shopifyResource = useMemo(() => {
    if (loadingResources) {
      return null;
    }

    const shopifyResource = find(
      resources,
      (r) => r.type === ResourceType.SHOPIFY
        && !r.authProvider.userRevokedAccess
        && !r.authProvider.systemRevokedAccess,
    );

    if (!shopifyResource) {
      addProjectEvent(EventName.PFAMissingResource, {});
    }

    return shopifyResource;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources, loadingResources]);

  const getMemberShippingAddress = (member: IMember): IShippingAddress => ({
    first_name: member.fields[addressSchemas['Address First Name'].id]
      || member.fields[addressSchemas['First Name'].id],
    last_name: member.fields[addressSchemas['Address Last Name'].id]
      || member.fields[addressSchemas['Last Name'].id],
    address1: member.fields[addressSchemas.Address1.id],
    address2: member.fields[addressSchemas.Address2.id],
    city: member.fields[addressSchemas.City.id],
    province: (member.fields[addressSchemas.State.id] || '').trim(),
    country: isUSA(member.fields[addressSchemas.Country.id] || '')
    ? 'US'
    : (member.fields[addressSchemas.Country.id] || '').trim(),
    zip: member.fields[addressSchemas.PostalCode.id],
    phone: member.fields[addressSchemas.Phone.id],
  });

  const [validMembers, invalidMembers] = useMemo(() => {
    if (loadingMembers || loadingSchemas) {
      return [[], []];
    }

    function isMemberFromUK(country: string) {
      return ['UK', 'GB', 'Great Britain', 'United Kingdom'].includes(country);
    }

    const validMembers = [];
    const invalidMembers = [];

    each(members, (member) => {
      const shippingAddress = getMemberShippingAddress(member);
      const isEmptyOrWhiteSpace = (dirtyString: string): boolean => isEmpty(trim(dirtyString));
      if (
        isEmptyOrWhiteSpace(member.email)
        || isEmptyOrWhiteSpace(shippingAddress.first_name)
        || isEmptyOrWhiteSpace(shippingAddress.last_name)
        || isEmptyOrWhiteSpace(shippingAddress.address1)
        || isEmptyOrWhiteSpace(shippingAddress.city)
        || (!isMemberFromUK(shippingAddress.country) && isEmptyOrWhiteSpace(shippingAddress.province))
        || isEmptyOrWhiteSpace(shippingAddress.country)
        || isEmptyOrWhiteSpace(shippingAddress.zip)
      ) {
        invalidMembers.push(member);
      } else {
        validMembers.push(member);
      }
    });

    return [validMembers, invalidMembers];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMembers, loadingSchemas, members, addressSchemas]);

  useEffect(() => {
    if (!loadingMembers && !isEmpty(invalidMembers)) {
      addProjectEvent(EventName.PFAMissingAddress, {
        member_count: size(members),
        invalid_count: size(invalidMembers),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMembers, members, invalidMembers]);

  return (
    <ProductFulfillmentContext.Provider
      value={{
        productCostEnabled,
        addAspireShippingLine,
        bypassShopifyInventory,
        loading,
        communities: communitiesData?.communities,
        programs: programsData?.programs,
        activations,

        usePfaContextVariables,
        setUsePfaContextVariables,
        variables,

        schemas,
        allMembers: members,
        members: validMembers,
        refetchMembersByIds,
        invalidMembers,
        addBillingAddress,
        sendBulkMessage,
        workItems,
        verbiage,
        workflowEnabled,

        addressSchemas,
        getMemberShippingAddress,

        tokenIsInvalid,
        setTokenIsInvalid,
        shopifyResource,
        activeEmailResources,
      }}
    >
      {loading && <LoadSpinner />}
      {!loading && children}
    </ProductFulfillmentContext.Provider>
  );
};
