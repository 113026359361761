import * as React from 'react';
import styles from './styles.scss';

interface IProps {
  order: number;
  count: number;
}

const completedCheckSvg = require('@frontend/app/assets/svgs/completed_check.svg');

export const Progressbar: React.FC<IProps> = React.memo((props: IProps) => {
  const { order, count } = props;
  const width = `${order / count * 100}%`;

  return (
    <div className={styles.Progressbar}>
      <div className={styles.base}>
        <div className={styles.progress} style={{ width }} />
      </div>
      <div className={styles.status}>
        {order === count ? (
          <img src={completedCheckSvg} />
        ) : (
          <>
            {order}
            {' '}
            /
            {' '}
            {count}
          </>
        )}
      </div>
    </div>
  );
});

Progressbar.displayName = 'Progressbar';
