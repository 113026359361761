import * as React from 'react';

import { Col, Row } from '@revfluence/fresh';

import {
  TContentSubmitted,
  TContentSubmittedCard,
} from './types';

import { ContentSubmittedCard } from './ContentSubmittedCard';

export const ContentSubmitted = ({ items }: TContentSubmitted) => (
  <Row gutter={[0, 16]}>
    {items.map((entry: TContentSubmittedCard) => (
      <Col span={24} key={entry.id}>
        <ContentSubmittedCard {...entry} />
      </Col>
      ))}
  </Row>
  );
