import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  BULK_CREATE_ORDER_MUTATION,
} from '../queries';
import {
  BulkCreateOrderMutation,
  BulkCreateOrderMutationVariables,
} from '../queries/types/BulkCreateOrderMutation';

type IOptions = MutationHookOptions<BulkCreateOrderMutation, BulkCreateOrderMutationVariables>;

export function useBulkCreateOrder(options: IOptions = {}) {
  const [bulkCreateOrder, { loading, error }] = useMutation<
    BulkCreateOrderMutation,
    BulkCreateOrderMutationVariables
  >(BULK_CREATE_ORDER_MUTATION, options);

  return {
    bulkCreateOrder,
    loading,
    error,
  };
}
