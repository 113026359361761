import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled } from '@frontend/app/hooks';

export const useFeatureFlags = () => {
    const metaBrandedContentFF = useClientFeatureEnabled(ClientFeature.META_BRANDED_CONTENT);
    const UCEInfluencerWhitelistingFF = useClientFeatureEnabled(ClientFeature.UCE_INFLUENCER_WHITELISTING);
    const InstagramPartnershipAdsFF = useClientFeatureEnabled(ClientFeature.INSTAGRAM_PARTNERSHIP_ADS);

    const metaBrandedContentEnabled = metaBrandedContentFF;
    const allowlistingFF = UCEInfluencerWhitelistingFF;
    const InstagramPartnershipAdsEnabled = InstagramPartnershipAdsFF;

    const isEmptyBrandedContentAds = metaBrandedContentEnabled && !allowlistingFF && !InstagramPartnershipAdsEnabled;
    const isEmptyAllowlisting = allowlistingFF && !metaBrandedContentEnabled && !InstagramPartnershipAdsEnabled;
    const isEmptyInstagramPartnershipAds = InstagramPartnershipAdsEnabled && !metaBrandedContentEnabled && !allowlistingFF;
    const isEmptyMultipleAgreements = metaBrandedContentEnabled && allowlistingFF && InstagramPartnershipAdsEnabled;
    return {
        allowlistingFF,
        metaBrandedContentEnabled,
        InstagramPartnershipAdsEnabled,
        isEmptyBrandedContentAds,
        isEmptyAllowlisting,
        isEmptyInstagramPartnershipAds,
        isEmptyMultipleAgreements,
    };
};
