import * as React from 'react';
import cx from 'classnames';
import { map } from 'lodash';

import { Tooltip } from 'antd';
import { EditorState } from 'draft-js';
import { BulletListIcon, NumberedListIcon } from '@frontend/app/components';

import { TBlockType } from './types';

import styles from './ListTypes.module.scss';

const LIST_TYPE_ICON: {
  [key in TBlockType]?: React.ReactNode;
} = {
  'ordered-list-item': <NumberedListIcon size={14} />,
  'unordered-list-item': <BulletListIcon size={14} />,
};
const LIST_TYPES: TBlockType[] = [
  'ordered-list-item', 'unordered-list-item',
];
const LIST_TYPE_NAME: {
  [key in TBlockType]?: string;
} = {
  'ordered-list-item': 'Numbered list',
  'unordered-list-item': 'Bullet list',
};
const LIST_TYPE_HOTKEY: {
  [key in TBlockType]?: string;
} = {
  'ordered-list-item': '⌘7',
  'unordered-list-item': '⌘8',
};

interface IProps {
  editorState: EditorState;
  toggleBlockType(type: TBlockType): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const ListTypes: React.FC<IProps> = React.memo((props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className={cx(styles.ListTypes, props.className)}>
      {map(LIST_TYPES, (type) => (
        <Tooltip
          key={type}
          overlayClassName={styles.Tooltip}
          title={(
            <div className={styles.content}>
              {LIST_TYPE_NAME[type]}
              <span className={styles.hotkey}>
                {LIST_TYPE_HOTKEY[type]}
              </span>
            </div>
          )}
        >
          <div
            className={cx(styles.item, {
              [styles.active]: blockType === type,
            })}
            onMouseDown={(event) => {
              event.preventDefault();

              props.toggleBlockType(type);
            }}
          >
            {LIST_TYPE_ICON[type]}
          </div>
        </Tooltip>
      ))}
    </div>
  );
});

ListTypes.displayName = 'ListTypes';
