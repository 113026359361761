import { IDeliverableDueDateType } from './deliverableDueDate';
import { IDeliverableGuidelines } from './deliverableGuidelines';
import { TPostType } from './postType';
import { ISocialAccount } from './socialAccount';

export interface IDeliverable {
  approval_terms_agreed: boolean;
  brand_guideline_checkoff: boolean[];
  network?: string;
  creator_guideline_checkoff: boolean[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coupon_code?: any;
  product_description: string;
  default_content_guidelines: string[];
  uuid: string;
  to_legal_english: string;
  requires_approval: boolean;
  account_name?: string;
  account_display_image?: string;
  count: number;
  due_dates: IDeliverableDueDateType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content_guidelines: any[];
  checkoff_guidelines: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotion_links?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name?: any;
  deliverable_guidelines: IDeliverableGuidelines;
  account_id?: number;
  post_type: TPostType;
  account: ISocialAccount;
}
