/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1xobd_157 {
  display: flex;
}

._justify-content-space-between_1xobd_161 {
  justify-content: space-between;
}

._tabular-nums_1xobd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1xobd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AppDrawer_1xobd_209 {
  margin: 0;
  z-index: 1000;
}
._AppDrawer_1xobd_209 .ant-drawer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  background-color: #fdfdfd;
}
._AppDrawer_1xobd_209 .ant-drawer-header-title, ._AppDrawer_1xobd_209 .ant-drawer-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
._AppDrawer_1xobd_209 .ant-drawer-body {
  padding: 2.25rem;
}
._AppDrawer_1xobd_209 .ant-drawer-content-wrapper {
  max-width: 62.5rem;
  min-width: 37.5rem;
}