import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const LockIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12,13.46c-0.55,0-1,0.45-1,1c0,0.33,0.16,0.62,0.41,0.8v2.06c0,0.33,0.26,0.59,0.59,0.59
      c0.32,0,0.59-0.26,0.59-0.59v-2.06c0.25-0.18,0.41-0.47,0.41-0.8C13,13.91,12.55,13.46,12,13.46z"
    />
    <path
      d="M17.09,9.37V7.09C17.09,4.28,14.81,2,12,2C9.19,2,6.91,4.28,6.91,7.09v2.29c-1.37,0.09-2.46,1.23-2.46,2.62
      v7.38c0,1.45,1.18,2.63,2.63,2.63h9.83c1.45,0,2.63-1.18,2.63-2.63v-7.38C19.54,10.6,18.46,9.46,17.09,9.37z M8.09,7.09
      c0-2.16,1.76-3.91,3.91-3.91s3.91,1.76,3.91,3.91v2.28H8.09V7.09z M18.37,19.37c0,0.8-0.65,1.45-1.45,1.45H7.08
      c-0.8,0-1.45-0.65-1.45-1.45v-7.38c0-0.8,0.65-1.45,1.45-1.45H7.5h9h0.42c0.8,0,1.45,0.65,1.45,1.45V19.37z"
    />
  </SvgIcon>
));

LockIcon.displayName = 'LockIcon';
