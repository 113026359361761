import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const LightningIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 15.35 20"
  >
    <path
      d="
        M15.2912 8.1428C15.2012 7.9428 15.0012 7.8228 14.7812 7.8228H10.0512L10.1112
        0.572802C10.1112 0.332802 9.96117 0.112802 9.74117 0.0328021C9.51117 -0.0471979 9.26117
        0.0228021 9.10117 0.202802L0.131165 10.9728C-0.0088349 11.1428 -0.0388349 11.3828 0.0511651
        11.5828C0.141165 11.7828 0.341165 11.9128 0.571165 11.9128H5.38117L5.40117 19.4428C5.40117
        19.6828 5.55117 19.9028 5.78117 19.9828C5.84117 19.9928 5.90116 20.0028 5.97116
        20.0028C6.14116 20.0028 6.30117 19.9228 6.42116 19.7928L15.2312 8.7528C15.3612 8.5728
        15.3812 8.3428 15.2912 8.1428ZM6.53117 17.8128L6.52116 11.3428C6.52116 11.0328 6.26116
        10.7728 5.95116 10.7728H1.79117L8.96117 2.1728L8.91117 8.3828C8.91117 8.5328 8.97117 8.6828
        9.08117 8.7928C9.19116 8.9028 9.33117 8.9628 9.48116 8.9628H13.6012L6.53117 17.8128Z
      "
    />
  </SvgIcon>
));

LightningIcon.displayName = 'LightningIcon';
