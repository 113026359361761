import * as React from 'react';
import { useMutation } from '@apollo/client';
import { UploadProps } from 'antd/lib/upload';

import { logger, EventName } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { useGetCurrentClient } from '@frontend/app/hooks';
import { useMemberPageContext } from '@frontend/app/containers/Members/hooks';
import { UploadCSVModal } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context/EventContext';
import { UploadMembersCSV, UploadMembersCSVVariables } from '@frontend/app/queries/types/UploadMembersCSV';
import { UPLOAD_MEMBERS_CSV } from '@frontend/app/queries';

const { useMemo, useState, useCallback } = React;

interface ICSVUploadContext {
  antdUploadProps: UploadProps;
  showCSVUpload: boolean;
  onClickUpload();
}

interface IProps {
  onComplete?();
}

export const CSVUploadContext = React.createContext<ICSVUploadContext>(null);

export const CSVUploadContextProvider: React.FC<IProps> = ({ children, onComplete }) => {
  const addEvent = useEventContext();

  const {
    sourcingGroupId,
  } = useMemberPageContext();

  const {
    showMessage,
  } = useMessagingContext();

  const {
    client = null,
  } = useGetCurrentClient();

  // Show csv upload only if feature flag exists and this isn't a sourcing group (i.e. Shopify, WooCommerce etc).
  const showCSVUpload = !sourcingGroupId;

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const handleToggleCsvUploadModal = useCallback(() => {
    setShowUploadModal((show) => !show);
  }, [setShowUploadModal]);

  const uploadIdentifier = useMemo(() => {
    const currentTimeString = new Date().getTime().toLocaleString();
    return client?.id?.concat('_', currentTimeString);
  }, [client]);

  const handleClickUpload = useCallback(() => {
    if (!client) {
      return;
    }

    if (showCSVUpload) {
      addEvent(EventName.CSVUploadOpenModule, {
        uploadIdentifier,
      });
      handleToggleCsvUploadModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleToggleCsvUploadModal, showCSVUpload, uploadIdentifier, client]);

  const [uploadMemberCSV] = useMutation<UploadMembersCSV, UploadMembersCSVVariables>(UPLOAD_MEMBERS_CSV, {
    onCompleted: (data) => {
      if (data) {
        logger.debug('Successfully uploaded CSV!');

        if (onComplete) {
          onComplete();
        }

        showMessage({
          content: 'Successfully imported file',
          type: 'success',
        });
      }
    },

    onError(error) {
      logger.error(error);

      if (error.message.includes('GraphQL error:')) {
        error.message = error.message.substring(15);
      }

      showMessage({
        content: error.message,
        type: 'error',
      });

      addEvent(
        EventName.ImportErrors,
        {
          error_type: error.message,
        },
      );
    },
  });

  const antdUploadProps: UploadProps = useMemo(() => ({
    beforeUpload: (file: File) => {
      const fileSizeLimit = file.size / 1024 / 1024 < 100;

      if (!fileSizeLimit) {
        const errorMessage = 'File cannot exceed 100MB';
        logger.error(errorMessage);

        showMessage({
          content: errorMessage,
          type: 'error',
        });

        addEvent(
          EventName.ImportErrors,
          {
            error_type: errorMessage,
          },
        );

        return false;
      }

      // Check file type
      const regex = /^.+(\.csv)$/g;
      const isCSV = file.type === 'text/csv' || regex.test(file.name);

      if (!isCSV) {
        const errorMessage = 'File format must be .csv';
        logger.error(errorMessage);

        showMessage({
          content: errorMessage,
          type: 'error',
        });

        addEvent(
          EventName.ImportErrors,
          {
            error_type: errorMessage,
          },
        );
        return false;
      }

      uploadMemberCSV({
        variables: { file },
      });

      showMessage({
        content: 'CSV upload initiated',
        type: 'success',
      });

      return false;
    },
  }), [showMessage, uploadMemberCSV, addEvent]);

  const value: ICSVUploadContext = {
    antdUploadProps,
    showCSVUpload,
    onClickUpload: handleClickUpload,
  };

  return (
    <CSVUploadContext.Provider value={value}>
      {children}
      {showCSVUpload && (
        <UploadCSVModal
          show={showUploadModal}
          onRequestClose={handleToggleCsvUploadModal}
          onSuccessRefreshMembers={onComplete}
          uploadIdentifier={uploadIdentifier}
        />
      )}
    </CSVUploadContext.Provider>
  );
};
