import { assign } from 'lodash';
import { IContentUploaderAction, IContentUploader, ActionTypes } from './contentUploaderModel';

const reducer = (state: IContentUploader, action: IContentUploaderAction): IContentUploader => {
  switch (action.type) {
    case ActionTypes.REMOVE_CONTENTS: {
      return assign({}, state, {
        contents: [],
      });
    }

    case ActionTypes.SET_CONTENTS: {
      return assign({}, state, {
        contents: action.payload.contents,
      });
    }

    case ActionTypes.ADD_CONTENT: {
      const { content } = action.payload;
      return assign({}, state, {
        contents: [
          ...state.contents,
          {
            ...content,
            progress: {
              percentage: 0,
              uploaded: 0,
            },
          },
        ],
      });
    }

    case ActionTypes.REMOVE_CONTENT: {
      const { id } = action.payload;
      const { contents } = state;
      const contentIndex = contents.findIndex((content) => content.id === id);

      // NOTE: Do nothing when `id` doesn't exist -- it may have already been removed before
      // This could happen when REMOVE_CONTENT is called twice with the same `id`
      if (contentIndex < 0) {
        return state;
      }

      // note that we cannot simply mutating the 'contents' from state (using splice)
      // and clone is slow
      return assign({}, state, {
        contents: [...contents.slice(0, contentIndex), ...contents.slice(contentIndex + 1)],
      });
    }

    case ActionTypes.UPDATE_PROGRESS: {
      const { id, progress } = action.payload;
      const { contents } = state;
      const content = contents.find((content) => content.id === id);
      const contentIndex = contents.indexOf(content);

      if (!content) {
        // throw new Error(`Couldn't find content with id: ${id}`);

        // do nothing
        return assign({}, state);
      }

      return assign({}, state, {
        contents: [
          ...contents.slice(0, contentIndex),
          {
            ...content,
            progress,
          },
          ...contents.slice(contentIndex + 1),
        ],
      });
    }

    case ActionTypes.UPDATE_FILE_URL: {
      const { id, fileUrl, previewUrl } = action.payload;
      const { contents } = state;
      const content = contents.find((content) => content.id === id);
      const contentIndex = contents.indexOf(content);

      if (!content) {
        // throw new Error(`Couldn't find content with id: ${id}`);

        // do nothing
        return assign({}, state);
      }

      return assign({}, state, {
        contents: [
          ...contents.slice(0, contentIndex),
          {
            ...content,
            fileUrl,
            previewUrl,
          },
          ...contents.slice(contentIndex + 1),
        ],
      });
    }

    case ActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };

    default:
      return state;
  }
};

export default reducer;
