import * as React from 'react';
import cx from 'classnames';
import {
 Button, Dropdown, Menu, Modal,
} from 'antd';
import { isEmpty, isFunction } from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { TrashcanIcon } from '@components';
import { TagsQuery_tags as ITag } from '@frontend/app/queries/types/TagsQuery';
import { useMessagingContext } from '@frontend/hooks';
import { useDeleteTagById, useSaveTag } from '@frontend/app/hooks';

import { getErrorMessageFromGraphQL } from '@frontend/utils';

import { EllipsisIcon } from '../Icons';
import { TextInput } from '../TextInput/TextInput';

import styles from './TagActions.scss';

const { useState, useCallback } = React;

enum OVERLAY_TYPES {
  MENU = 'MENU',
  EDIT = 'EDIT',
  REMOVE = 'REMOVE',
}

interface IProps {
  tag: ITag;
  onDelete?: () => void;
  onSave?: () => void;
  className?: string;
}

const MSG_DURATION = 3000;

export const TagActions: React.FC<IProps> = React.memo((props) => {
  const {
    tag,
    className,
    onDelete,
    onSave,
  } = props;

  const {
    showErrorMessage,
    showSuccessMessage,
  } = useMessagingContext();
  const [deleteTag] = useDeleteTagById();
  const [saveTag] = useSaveTag();

  const [currentTag, setCurrentTag] = useState<ITag>(tag);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasEditNameError, setHasEditNameError] = useState(false);
  const [isPopoverShown, setIsPopoverShown] = useState(false);
  const [currentOverlayType, setCurrentOverlayType] = useState<OVERLAY_TYPES>(OVERLAY_TYPES.MENU);

  const reset = useCallback((throttleType?: boolean) => {
    setIsUpdating(false);
    setIsPopoverShown(false);
    if (throttleType) {
      setTimeout(() => {
        setCurrentOverlayType(OVERLAY_TYPES.MENU);
      }, 500);
    } else {
      setCurrentOverlayType(OVERLAY_TYPES.MENU);
    }
  }, [
    setIsUpdating,
    setIsPopoverShown,
    setCurrentOverlayType,
  ]);

  const handlePopoverShown = (visible: boolean) => {
    setIsPopoverShown(visible);

    if (!visible) {
      // This timeout prevents a UI jump on close
      setTimeout(() => {
        setCurrentOverlayType(OVERLAY_TYPES.MENU);
      }, 500);
    }
  };

  const handleDelete = useCallback(async () => {
    try {
      setIsUpdating(true);
      await deleteTag({
        variables: {
          id: tag.id,
        },
      });

      if (isFunction(onDelete)) {
        onDelete();
      }
      showSuccessMessage('Tag deleted successfully.', MSG_DURATION);
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    } finally {
      reset();
    }
  }, [
    tag,
    deleteTag,
    showSuccessMessage,
    showErrorMessage,
    onDelete,
    setIsUpdating,
    reset,
  ]);

  const handleChangeName = useCallback(async () => {
    try {
      setIsUpdating(true);
      await saveTag({
        variables: {
          tag: {
            id: currentTag?.id,
            name: currentTag?.name,
          },
        },
      });

      if (isFunction(onSave)) {
        onSave();
      }
      setHasEditNameError(false);
      showSuccessMessage('Tag name updated successfully.', MSG_DURATION);
    } catch (error) {
      let message = getErrorMessageFromGraphQL(error);
      if (message.includes('duplicate key')) {
        message = `${currentTag?.name} exists already, please select a different tag name.`;
      }
      setHasEditNameError(true);
      showErrorMessage(message, MSG_DURATION);
    } finally {
      reset(true);
    }
  }, [
    currentTag,
    setIsUpdating,
    saveTag,
    setHasEditNameError,
    showErrorMessage,
    showSuccessMessage,
    reset,
    onSave,
  ]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleChangeName();
    }
  };

  const renderChangeTagNameOverlay = () => (
    <div className={styles.FormAction}>
      <div className={styles.FormActionTitle}>
        Edit Tag
      </div>

      <TextInput
        className={cx(
          styles.FormActionInput,
          {
            [styles.hasError]: isEmpty(currentTag?.name) || hasEditNameError,
          },
        )}
        value={currentTag?.name}
        onChange={(e) => {
          setCurrentTag({
            ...currentTag,
            ...{ name: e.target.value },
          });
          setHasEditNameError(false);
        }}
        onKeyDown={handleKeyDown}
      />
      <Button
        disabled={isEmpty(currentTag?.name)}
        className={styles.FormActionButton}
        loading={isUpdating}
        onClick={handleChangeName}
      >
        Save
      </Button>
    </div>
  );

  const handleDeleteCancel = () => {
    reset();
  };

  const handleRemoveIntent = () => {
    setCurrentOverlayType(OVERLAY_TYPES.REMOVE);

    Modal.confirm({
      title: 'Are you sure you want to delete this tag?',
      content: 'Deleting this tag will also delete it for all members',
      centered: true,
      icon: <QuestionCircleOutlined color="#FAAD14" />,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: handleDelete,
      onCancel: handleDeleteCancel,
      okButtonProps: {
        danger: true,
      },
      zIndex: 99999999,
    });
  };

  const renderMenu = () => (
    <Menu
      className={styles.Menu}
      selectedKeys={[]}
    >
      <Menu.Item
        className={styles.MenuItem}
        key={OVERLAY_TYPES.EDIT}
        onClick={() => setCurrentOverlayType(OVERLAY_TYPES.EDIT)}
      >
        Rename
      </Menu.Item>

      <Menu.Item
        className={cx(
            styles.MenuItem,
            styles.Delete,
          )}
        key={OVERLAY_TYPES.REMOVE}
        onClick={handleRemoveIntent}
      >
        <TrashcanIcon
          className={styles.MenuItemIcon}
          size={14}
        />
        Delete
      </Menu.Item>
    </Menu>
    );

  const renderOverlay = () => {
    switch (currentOverlayType) {
      case OVERLAY_TYPES.EDIT:
        return renderChangeTagNameOverlay();
      case OVERLAY_TYPES.MENU:
      default:
        return renderMenu();
    }
  };

  return (
    <div
      className={cx(
        styles.TagActions,
        className,
      )}
      onClick={(e: React.SyntheticEvent) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Dropdown
        trigger={['click']}
        overlay={renderOverlay()}
        placement="bottomCenter"
        overlayClassName={styles.Popover}
        visible={isPopoverShown}
        onVisibleChange={handlePopoverShown}
      >
        <EllipsisIcon
          className={cx(
            styles.Icon,
            {
              [styles.Active]: isPopoverShown,
            },
          )}
        />
      </Dropdown>
    </div>
  );
});

TagActions.displayName = 'TagActions';
