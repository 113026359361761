/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1yhza_157 {
  display: flex;
}

._justify-content-space-between_1yhza_161 {
  justify-content: space-between;
}

._tabular-nums_1yhza_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1yhza_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ZeroStateAnalyticsInsights_1yhza_178 {
  margin-left: 28%;
  margin-right: 28%;
  text-align: center;
  color: #8c8c8c;
}

._Buttons_1yhza_185 {
  display: flex;
  justify-content: center;
  gap: 24px;
}
._Buttons_1yhza_185 ._ConnectInstagramButton_1yhza_190 {
  background: linear-gradient(270deg, #8D27B4 0%, #E33659 45.83%, #FDBE4B 100%);
  border-color: #ffffff;
}
._Buttons_1yhza_185 ._ConnectInstagramButton_1yhza_190:hover {
  border-color: #9028B2 !important;
}
._Buttons_1yhza_185 ._CreateProjectButton_1yhza_197 {
  margin-top: 0.3125rem;
}