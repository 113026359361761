import { updateGuidelineTitle } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IState } from '../../../../types/state';
import { IUpdateContentGuidelineTitle } from './index';

export const updateContentGuidelineTitle = (state: IState, action: IUpdateContentGuidelineTitle): IState => ({
  ...state,
  contentGuidelines: updateGuidelineTitle(
    state.contentGuidelines,
    action.contentGuidelineTitle.id,
    action.contentGuidelineTitle.guidelineIndex,
    action.contentGuidelineTitle.title,
  ),
});
