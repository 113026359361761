const arrangeTableColumns = (inputArray, orderArray = []) => {
    const orderMap = {};
    orderArray.forEach((field, index) => {
        orderMap[field] = index;
    });
    const sortedArray = inputArray.slice().sort((a, b) => {
        const indexA = orderMap[a.key];
        const indexB = orderMap[b.key];
        if (indexA === undefined && indexB === undefined) {
            return 0;
        } else if (indexA === undefined) {
            return 1;
        } else if (indexB === undefined) {
            return -1;
        } else {
            return indexA - indexB;
        }
    });
    return sortedArray;
};
export { arrangeTableColumns };
