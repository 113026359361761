import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';

interface IIntercomInitializeParams {
  user_id: string;
  email?: string;
  name?: string;
  company?: {
    company_id: string;
    name: string;
  };
}

declare global {
  interface Window {
    Intercom?: {
      (command: 'boot', params: IIntercomInitializeParams): void;
      (command: 'shutdown'): void;
    };
  }
}

const { useEffect } = React;

export function useInitIntercom(): void {
  const { user, clientInfo } = useAuth();

  useEffect(() => {
    if (!window.Intercom || window?.isUnderTest) {
      return;
    }

    window.Intercom('boot', {
      user_id: user.sub,
      email: user.email,
      name: user.name,
      company: {
        company_id: clientInfo.id,
        name: clientInfo.name,
      },
    });

    // Cleanup function to shutdown Intercom when component unmounts
    return () => {
      window.Intercom('shutdown');
    };
  }, [clientInfo, user]);
}
