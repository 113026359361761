import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_BRAND_CATALOG_STATS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { GetBrandCatalogStatsQuery, GetBrandCatalogStatsQueryVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetBrandCatalogStatsQuery';

type IOptions = QueryHookOptions<GetBrandCatalogStatsQuery, GetBrandCatalogStatsQueryVariables>;

export const useGetCatalogStats = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch,
} = useQuery(GET_BRAND_CATALOG_STATS_QUERY, options);

  return {
    loading,
    catalogStats: data?.getStatsByCatalogId ?? ({} as GetBrandCatalogStatsQuery['getStatsByCatalogId']),
    error,
    refetch,
  };
};
