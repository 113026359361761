import * as React from 'react';
import { find, isArray, map } from 'lodash';

import { EmailAddressPicker } from '@components';

import { useResources } from './hooks';

const { useMemo, useCallback } = React;

interface IAddress {
  email: string;
  type: string;
}

interface IProps {
  className?: string;
}

export const InviteAddressPicker: React.FC<IProps> = (props) => {
  const {
    resources,
    resourceId,
    selectResourceId,
    onRequestAddEmail,
  } = useResources();

  const cepAddresses = useMemo<IAddress[]>(() => (
    isArray(resources)
      ? map(resources, (resource) => ({
        email: resource.authProvider.userExternalDisplayId,
        type: resource.type,
      }))
      : null
  ), [resources]);

  const selectedEmail = useMemo<string>(() => {
    const resource = find(resources, { id: resourceId });
    if (resource) {
      return resource.authProvider.userExternalDisplayId;
    }
  }, [resources, resourceId]);

  const handleSelectEmail = useCallback((email: string) => {
    const resource = find(resources, (resource) => resource.authProvider.userExternalDisplayId === email);

    if (resource) {
      selectResourceId(resource.id);
    }
  }, [selectResourceId, resources]);

  return (
    <EmailAddressPicker
      className={props.className}
      label="Sending invites from"
      addresses={cepAddresses}
      onSelectEmail={handleSelectEmail}
      selectedEmail={selectedEmail}
      showAddEmail
      onClickAddEmail={onRequestAddEmail}
    />
  );
};
InviteAddressPicker.displayName = 'InviteAddressPicker';
