import * as React from 'react';
import { Tooltip } from 'antd';
import { HelpIcon } from '@components';

import styles from './PotentialCodeHeader.scss';

export const PotentialCodeHeader = () => {
  const tooltip = 'This is the code we will try to generate based on your '
    + 'bulk naming rules for this Offer. You may edit it if you like. We will '
    + 'verify with Shopify that this code is unique.';
  return (
    <div className={styles.PotentialCodeHeader}>
      <p>Potential Code</p>
      <Tooltip title={tooltip} className={styles.icon}>
        <HelpIcon size={16} />
      </Tooltip>
    </div>
);
};
