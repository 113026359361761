import { useMutation, MutationHookOptions } from '@apollo/client';
import { DataProxy } from 'apollo-cache';
import { unionBy } from 'lodash';

import { SAVE_TAG_MUTATION } from '@frontend/app/queries';
import {
  SaveTagMutation,
  SaveTagMutationVariables,
} from '@frontend/app/queries/types/SaveTagMutation';

import { TAGS_QUERY } from '@frontend/app/queries';
import {
  TagsQuery,
} from '@frontend/app/queries/types/TagsQuery';
import { ITag } from '.';

type IOptions = MutationHookOptions<SaveTagMutation, SaveTagMutationVariables>;

const updateCache = (store: DataProxy, tag: ITag) => {
  const data = store.readQuery<TagsQuery>({
    query: TAGS_QUERY,
  });

  // Sometimes useGetTags is used with 'no-cache' option, so data is null
  // in that case, we don't need to update the cache
  if (!data) {
    return;
  }

  const newCache: TagsQuery = {
    ...data,
    tags: unionBy(data.tags, [tag], 'id'),
  };

  store.writeQuery({
    query: TAGS_QUERY,
    data: newCache,
  });
};

export const useSaveTag = (options: IOptions = {}) => useMutation<SaveTagMutation, SaveTagMutationVariables>(SAVE_TAG_MUTATION, {
    ...options,
    update: (...args) => {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateCache(store as any, result.data.tag);
    },
  });
