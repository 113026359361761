import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Checkbox, Col, Popover, Row, Space, Table, Tooltip, Empty, Typography, Button, ITableProps,
} from '@revfluence/fresh';
import { isUndefined } from 'lodash';
import { Columns3Icon } from '@revfluence/fresh-icons/solid/esm';
import { AffiliateTableSTAPagination } from './AffiliateTableSTAPagination';

interface IPaginationConfig {
  pageSize: number;
  total: number;
  page: number;
  setPage: (page: number) => void;
  isDynamicPaginationEnabled: boolean | false;
}

interface AffiliateTableSTAProps<T> extends ITableProps<T> {
  headerActions?: React.ReactNode;
  searchBox?: React.ReactNode;
  searchText?: string;
  sortField?: string;
  enableCustomPagination?: IPaginationConfig;
  enableEditColumn?: boolean | false;
  totalRows?: number;
}
const { Text } = Typography;
const AffiliateTableSTA = <T extends object>(props: AffiliateTableSTAProps<T>) => {
  const {
    columns, headerActions, searchBox, enableCustomPagination, enableEditColumn, totalRows, ...tableProps
  } = props;
  const { dataSource } = tableProps;
  const [selectedColumns, setSelectedColumns] = useState(columns.map((col) => col.key));
  const [sortedData, setSortedData] = useState<readonly T[]>(dataSource);

  useEffect(() => {
    setSelectedColumns(columns.map((col) => col.key));
  }, [columns]);
  useEffect(() => {
    setSortedData(dataSource);
  }, [dataSource]);
  const handleCheckboxChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };
  const columnCheckbox = () => (
    <>
      <Checkbox.Group value={selectedColumns} onChange={handleCheckboxChange}>
        <Space direction="vertical">
          {columns.map((col) => (
            <Checkbox key={col.key} value={col.key}>
              {col.title}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </>
  );
  const paginate = useCallback((array: readonly T[], pageNumber: number, pageSize: number): T[] => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = pageNumber * pageSize;
    return array.slice(startIndex, endIndex);
  }, []);

  const finalData = useMemo(() => {
    if (isUndefined(enableCustomPagination)) {
      return sortedData;
    } else if (!enableCustomPagination?.isDynamicPaginationEnabled) {
      return paginate(sortedData, enableCustomPagination?.page, enableCustomPagination?.pageSize);
    } else {
      return sortedData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedData, enableCustomPagination, paginate, dataSource]);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 24 }}>
        <Row>
          <Col>{headerActions}</Col>
        </Row>
        <Row gutter={8} align="middle">
          {searchBox && (
            <Col>
              {searchBox}
            </Col>
          )}
          <Col>
            {enableEditColumn && (
              <Popover content={columnCheckbox} trigger="click" placement="bottomLeft">
                <Tooltip
                  title="Edit Columns"
                  placement="top"
                >
                  <Button
                    icon={<Columns3Icon />}
                    style={{
                    marginRight: (totalRows || dataSource.length) < 9 ? 128 : (totalRows || dataSource.length) > 999 ? 200 : 174,
                  }}
                  />
                </Tooltip>
              </Popover>
            )}
          </Col>
          <Col>
            {enableCustomPagination && (
              <AffiliateTableSTAPagination
                page={enableCustomPagination?.page}
                dataSourceLength={enableCustomPagination?.total}
                pageSize={enableCustomPagination?.pageSize}
                setPage={enableCustomPagination?.setPage}
              />
            )}
          </Col>
        </Row>
      </Row>
      {selectedColumns.length ? (
        <Table.Draggable<T>
          key={JSON.stringify(selectedColumns)}
          {...tableProps}
          columns={columns.filter((col) => selectedColumns.includes(col.key))}
          dataSource={finalData as readonly T[]}
          size="small"
        />
      ) : (
        <Empty description={(
          <>
            <Text type="secondary">Select columns in order to show data</Text>
          </>
        )}
        />
      )}
    </>
  );
};
export default AffiliateTableSTA;
