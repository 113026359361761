/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lhfj2_157 {
  display: flex;
}

._justify-content-space-between_lhfj2_161 {
  justify-content: space-between;
}

._tabular-nums_lhfj2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lhfj2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._InvalidDataNotice_lhfj2_178 ._content_lhfj2_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._InvalidDataNotice_lhfj2_178 {
  margin-bottom: 1.25rem;
}
._InvalidDataNotice_lhfj2_178 ._content_lhfj2_178 {
  display: flex;
  flex-direction: column;
  color: #fdfdfd;
}
._InvalidDataNotice_lhfj2_178 ._content_lhfj2_178 ._title_lhfj2_227 {
  margin-bottom: 0.25rem;
  font-weight: bold;
}