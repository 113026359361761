import {
  useGetResources,
  useRevokeResourceAccess,
} from '@frontend/app/hooks';

export const useRevokeShopifyAccount = () => {
  const {
    refetch: refetchAccounts,
  } = useGetResources({
    skip: true,
    notifyOnNetworkStatusChange: false,
  });

  return useRevokeResourceAccess({
    onCompleted() {
      refetchAccounts();
    },
  });
};
