import gql from 'graphql-tag';
import { TERMS_TEMPLATE_FRAGMENT } from './fragments/TermsTemplateFragment';

export const GET_TERMS_TEMPLATES_BY_PROGRAM_ID = gql`
  query TermsTemplatesByProgramIdQuery($programId: Int!) {
    templates: getByProgram(programId: $programId) {
      ...TermsTemplateFragment
    }
  }
  ${TERMS_TEMPLATE_FRAGMENT}
`;
