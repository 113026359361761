import gql from 'graphql-tag';

export const GET_TASK_TRACKERS_QUERY = gql`
  query GetTaskTrackersQuery($params: TaskTrackerParams!, $limit: Int) {
    trackers: getTaskTrackers(params: $params, limit: $limit) {
      id
      clientId
      parentId
      url
      success
      createdDate
      taskProgress {
        id
        progressPercentage
        status
        details {
          total
          success
          fail
        }
      }
    }
  }
`;
