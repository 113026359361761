import * as React from 'react';
import { LinkIcon } from '@revfluence/fresh-icons/solid/esm';
import { Button, Tooltip, Typography } from '@revfluence/fresh';
import { useCopyToClipboard } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { creatorPortalDomain } from '@frontend/applications/Shared/serviceHosts';

interface IProps {
  projectId: number;
}

const { Link } = Typography;

const DeepLink: React.FC<IProps> = React.memo((props) => {
  const { projectId } = props;
  const { copyToClipboard } = useCopyToClipboard();
  const creatorDeepLinkUrl = `${creatorPortalDomain()}/programs/${projectId}`;
  const intercomUrl = 'https://intercom.help/aspireiq_elevate/en/articles/6565403-creator-portal-overview';
  const linkText = (
    <>
      Copy
      {' '}
      <Link href={intercomUrl} target="_blank">creator portal</Link>
      {' '}
      link to share with accepted members
    </>
  );
  const {
    showSuccessMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  const handleCopyToClipboard = () => {
    copyToClipboard({
      value: creatorDeepLinkUrl,
      onSuccess: () => {
        showSuccessMessage('Project link copied!');
      },
      onFailure: () => {
        showGenericErrorMessage();
      },
    });
  };

  return (
    <Tooltip title={linkText}>
      <Button
        size="small"
        type="text"
        style={{
          marginLeft: '4px',
        }}
        icon={
          <LinkIcon />
        }
        onClick={handleCopyToClipboard}
      />
    </Tooltip>

  );
});

export default DeepLink;
DeepLink.displayName = 'DeepLink';
