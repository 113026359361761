import * as React from 'react';
import { reduce } from 'lodash';

import { IColumn } from '@frontend/app/types/Columns';
import { FieldType } from '@frontend/app/types/Fields';
import { DynamicChoice } from '@frontend/app/components';

const { useMemo } = React;

export enum UtmSource {
  MemberList = 'member_list',
  CreatorSearch = 'creator_search',
  ProgramsList = 'programs_list',
  CuratedCreators = 'curatedcreators',
  Maven = 'maven',
  Marketplace = 'marketplace',
  Brand = 'brand',
  ChromeExtension = 'chrome_extension',
}

export enum ApplicantSourceType {
  Organic = 'Organic',
  Invited = 'Invited',
  Other = 'Other',
  Suggested = "Aspire's Pick",
  Marketplace = 'Marketplace',
  Maven = 'Maven',
}

interface SourceConfig {
  name: string;
  sortOrder: number;
}

interface MembershipSources {
  sourceConfigs: Record<string, SourceConfig>;
  sourceColumns: IColumn[];
  sourcesFilterChoices: DynamicChoice[];
}

const defaultSourcesFilterChoices: DynamicChoice[] = [
  {
    label: "Aspire's Pick",
    value: ApplicantSourceType.Suggested,
  },
  {
    label: 'Organic',
    value: ApplicantSourceType.Organic,
  },
  {
    label: 'Invited',
    value: ApplicantSourceType.Invited,
  },
  {
    label: 'Marketplace',
    value: ApplicantSourceType.Marketplace,
  },
  {
    label: 'Maven',
    value: ApplicantSourceType.Maven,
  },
  {
    label: 'Other',
    value: ApplicantSourceType.Other,
  },
];

const defaultSortedApplicantSource = [
  { source: [UtmSource.Brand].toString(), label: ApplicantSourceType.Organic },
  { source: [UtmSource.Marketplace].toString(), label: ApplicantSourceType.Marketplace },
  { source: [UtmSource.Maven].toString(), label: ApplicantSourceType.Maven },
  { source: [UtmSource.CuratedCreators].toString(), label: ApplicantSourceType.Suggested },
  { source: [UtmSource.ChromeExtension].toString(), label: ApplicantSourceType.Invited },
  { source: [UtmSource.ProgramsList].toString(), label: ApplicantSourceType.Invited },
  { source: [UtmSource.MemberList].toString(), label: ApplicantSourceType.Invited },
  { source: [UtmSource.CreatorSearch].toString(), label: ApplicantSourceType.Invited },
];

export const useMembershipSources = (): MembershipSources => {
  const sourceConfigs = useMemo(() => reduce(
    defaultSortedApplicantSource,
    (acc, sorted, index) => (
      {
        ...acc,
        ...{ [sorted.source]: { name: sorted.label, sortOrder: index } },
      }
    ),
    {},
  ), []);

  const sourceColumns = useMemo(() => (
    [
      {
        headerName: 'Applicant Source',
        field: 'applicantSource',
        type: FieldType.DYNAMIC_SELECT,
        showInEditColumns: true,
      },
    ]
  ), []);

  return useMemo(() => ({
    sourceConfigs,
    sourceColumns,
    sourcesFilterChoices: defaultSourcesFilterChoices,
  }), [sourceConfigs, sourceColumns]);
};
