import * as React from 'react';
import cx from 'classnames';

import { isEmpty, map, orderBy } from 'lodash';
import { Spinner } from '@revfluence/fresh';

import { useGetMemberThreads, useGetUsersQuery } from '@frontend/app/hooks';
import { MessageType } from '@frontend/app/types/globalTypes';
import { EmailComposerModal } from '@frontend/app/components';
import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';

import { PenToSquareIcon, EnvelopeIcon, PenIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetUserResources } from '@frontend/chrome-extension/script/content-script/ContentApp/hooks';
import { MemberThread } from './Thread';
import { EmptyState } from '../EmptyState';

import styles from './styles.scss';

const { useEffect, useMemo, useRef } = React;

export interface IMemberThreads {
  member: GetMemberQuery_member;
  isLoadingMember: boolean;
}

export const MemberThreads: React.FC<IMemberThreads> = (props) => {
  const {
    member,
    isLoadingMember,
  } = props;

  const messagesContainer = useRef<HTMLDivElement>(null);

  const {
    data: {
      users = [],
    } = {},
    loading: isFetchingUsers,
  } = useGetUsersQuery();

  const {
    emailResourceId: emailResources,
    loading: isFetchingResources,
  } = useGetUserResources({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: false,
  });

  const {
    data: {
      threads = [],
    } = {},
    loading: isFetching,
    refetch: refetchThreads,
  } = useGetMemberThreads({
    variables: {
      memberId: member?.id || 0,
      messageTypes: [MessageType.GMAIL, MessageType.OUTLOOK],
    },
    skip: !member?.id,
  });

  const isLoading = useMemo(() => (
    isFetching
    || isLoadingMember
    || isFetchingUsers
    || isFetchingResources
  ), [isFetching, isFetchingResources, isFetchingUsers, isLoadingMember]);

  const orderedThreads = useMemo(() => (
    orderBy(threads, (t) => t.lastMessage.internalDate, 'desc')
  ), [threads]);

  const content = useMemo(() => {
    if (isLoading) {
      return <div className={styles.content} />;
    }

    return (
      <div className={styles.content}>
        <div ref={messagesContainer} className={cx(styles.messages, { [styles.emptyMessagesSection]: isEmpty(orderedThreads) })}>
          {
            !isEmpty(orderedThreads)
              ? map(orderedThreads,
                  (thread) => (
                    <MemberThread
                      thread={thread}
                      key={thread.id}
                      member={member}
                      users={users}
                      emailResources={emailResources}
                      onRefetch={refetchThreads}
                    />
                  ))
              : (
                <EmptyState
                  icon={EnvelopeIcon}
                  title="No Messages"
                  description="When you communicate with this member, messages will appear here."
                  actionComponent={(
                    <div className={styles.footer}>
                      <EmailComposerModal
                        label="Compose Email"
                        icon={<PenIcon />}
                        members={member ? [member] : undefined}
                        onMessageSent={refetchThreads}
                      />
                    </div>
              )}
                />

              )
          }
        </div>
      </div>
    );
  }, [isLoading, orderedThreads, member, users, emailResources, refetchThreads]);

  useEffect(() => {
    if (
      isLoading
        || isEmpty(threads)
        || !messagesContainer.current
    ) {
      return;
    }

    setTimeout(() => {
      messagesContainer.current.scrollTo({
        top: messagesContainer.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 700);
  }, [isFetching, isFetchingUsers, isLoading, isLoadingMember, threads]);

  return (
    <div className={styles.MemberThreads}>
      {
        isLoading
          && (
            <div className={styles.spinContainer}>
              <Spinner />
            </div>
          )
      }
      {content}
      {!isEmpty(threads) && (
      <div className={styles.footer}>
        <EmailComposerModal
          label="Create New Thread"
          icon={<PenToSquareIcon />}
          members={member ? [member] : undefined}
          onMessageSent={refetchThreads}
        />
      </div>
)}
    </div>
  );
};
