import * as React from 'react';
import { isEmpty, size } from 'lodash';
import { Button, Tooltip } from '@revfluence/fresh';
import {
  CircleCheckIcon, CirclePlusIcon, EnvelopeIcon, EnvelopeOpenIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { useEventContext } from '@frontend/app/context/EventContext';

import { EventName, logger } from '@common';

import { IThread } from '@frontend/app/hooks';
import { useMutation } from '@apollo/client';
import { THREAD_MARK_DONE_MUTATION, THREAD_MARK_TODO_MUTATION } from '@frontend/app/queries';
import { ThreadMarkDone, ThreadMarkDoneVariables } from '@frontend/app/queries/types/ThreadMarkDone';
import { THREAD_MARK_READ_MUTATION, THREAD_MARK_UNREAD_MUTATION } from '@frontend/app/queries';
import { UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables } from '@frontend/app/queries/types/UpdateThreadToReadStatus';
import { UpdateThreadToUnreadStatus, UpdateThreadToUnreadStatusVariables } from '@frontend/app/queries/types/UpdateThreadToUnreadStatus';
import { ThreadMarkTodo, ThreadMarkTodoVariables } from '@frontend/app/queries/types/ThreadMarkTodo';
import { useResourceContext } from '@frontend/app/context';
import { needRefetchMessageVar, useMessagingContext } from './context/MessagingAppContext';

interface IProps {
   thread: IThread;
   frameless?: boolean;
}

export const ThreadSingleActionButtons: React.FC<IProps> = (props) => {
    const {
        refetchThreadsCount,
        refetchAndUpdateThreads,
        assigneeType,
        threads,
        status,
        goToNextThread,
      } = useMessagingContext();
    const {
    refetchNotificationCount,
    } = useResourceContext();
    const addEvent = useEventContext();
    const [threadMarkDone] = useMutation<ThreadMarkDone, ThreadMarkDoneVariables>(THREAD_MARK_DONE_MUTATION);
    const [threadMarkTodo] = useMutation<ThreadMarkTodo, ThreadMarkTodoVariables>(THREAD_MARK_TODO_MUTATION);
    const [markThreadAsRead] = useMutation<UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables>(THREAD_MARK_READ_MUTATION);
    const [markThreadAsUnread] = useMutation<UpdateThreadToUnreadStatus, UpdateThreadToUnreadStatusVariables>(THREAD_MARK_UNREAD_MUTATION);
    const markReadStatus = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        try {
          if (!thread?.readStatus?.isRead) {
            await markThreadAsRead({ variables: { threadIds: [thread?.id] } });
          } else {
            await markThreadAsUnread({ variables: { threadIds: [thread?.id] } });
          }
          refetchAndUpdateThreads(0, size(threads));
          refetchThreadsCount();
          needRefetchMessageVar(true);
        } catch (error) { logger.error(error.message); }
      };

      const markToDone = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!threadInTodo) {
          addEvent(
            EventName.MarkThreadDone,
            { threadType: thread.type },
          );
        }

        try {
          if (!threadInTodo) {
            await threadMarkTodo({ variables: { id: thread?.id } });
          } else {
            await threadMarkDone({ variables: { id: thread?.id } });
          }
          refetchAndUpdateThreads(0, size(threads));
          refetchThreadsCount();
          refetchNotificationCount();
          needRefetchMessageVar(true);
          if (status !== 'ALL' && assigneeType === 'you') {
            goToNextThread();
          }
        } catch (error) { logger.error(error.message); }
      };

    const { thread, frameless = false } = props;
    const threadInTodo = thread.userLabels[0]?.label === 'TODO';
    return (
      <>
        {!isEmpty(thread.userLabels) && (
        <Tooltip title={threadInTodo ? 'Mark as Done' : 'Mark as Todo'}>
          <Button
            size="small"
            type={frameless ? 'text' : 'default'}
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => (markToDone(event))}
            icon={threadInTodo ? <CircleCheckIcon /> : <CirclePlusIcon />}
          />
        </Tooltip>
         )}
        <Tooltip title={!thread?.readStatus?.isRead ? 'Mark as Read' : 'Mark as Unread'}>
          <Button
            size="small"
            type={frameless ? 'text' : 'default'}
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => markReadStatus(event)}
            icon={!thread?.readStatus?.isRead ? <EnvelopeOpenIcon /> : <EnvelopeIcon />}
          />
        </Tooltip>
        {props.children}
      </>
);
};
