import { isUndefined } from 'lodash';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_SEGMENT_FOLDERS_FOR_PROGRAM } from '@frontend/app/queries';
import {
  SegmentFoldersForProgramQuery,
  SegmentFoldersForProgramQueryVariables,
} from '@frontend/app/queries/types/SegmentFoldersForProgramQuery';

type IOptions = QueryHookOptions<SegmentFoldersForProgramQuery, SegmentFoldersForProgramQueryVariables>;

export const useSegmentFoldersForProgram = (programId?: number, options: IOptions = {}) => useQuery<SegmentFoldersForProgramQuery, SegmentFoldersForProgramQueryVariables>(
    GET_SEGMENT_FOLDERS_FOR_PROGRAM,
    {
      ...options,
      variables: {
        programId,
      },
      skip: options.skip || isUndefined(programId),
    },
  );
