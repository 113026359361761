import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_PROJECT_CONFIG_BY_TYPE_QUERY } from '../queries';
import {
  GetProjectConfigByProjectIdAndType,
  GetProjectConfigByProjectIdAndTypeVariables,
  GetProjectConfigByProjectIdAndType_projectConfig,
} from '../queries/types/GetProjectConfigByProjectIdAndType';

type IOptions = QueryHookOptions<GetProjectConfigByProjectIdAndType, GetProjectConfigByProjectIdAndTypeVariables>;
export type IProjectConfig = GetProjectConfigByProjectIdAndType_projectConfig;

export function useGetProjectConfigByType(options: IOptions = {}) {
  const {
    loading,
    data: {
      projectConfig,
    } = {},
    error,
    refetch,
  } = useQuery<GetProjectConfigByProjectIdAndType, GetProjectConfigByProjectIdAndTypeVariables>(
    GET_PROJECT_CONFIG_BY_TYPE_QUERY,
    {
      ...options,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    loading,
    projectConfig,
    error,
    refetch,
  };
}
