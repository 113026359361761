import * as React from 'react';
import cx from 'classnames';
import {
  format,
  subMonths,
  subWeeks,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  subQuarters,
  endOfQuarter,
  startOfYear,
  startOfWeek,
} from 'date-fns';
import moment from 'moment';

import {
  CalendarIcon as DateRangeIcon, Accordion, Popover,
} from '@components';
import {
  DatePicker,
  TDatePickerProps,
  Checkbox,
  Divider,
  Tooltip,
  Typography,
} from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './Filters.scss';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

const { RangePicker } = DatePicker;
const { Link } = Typography;
const { useRef, useState } = React;

export const DateFilter = ({ filters, setFilters, isDisabled, toggleInsights }) => {
  const featureFlagInsightsDateFilter = useClientFeatureEnabled(ClientFeature.SPA_INSIGHTS_DATE_FILTER);
  const tempYoutubeDemoAccountMode = useClientFeatureEnabled(ClientFeature.DEMO_ACCOUNT_MODE);

  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { datePosted: dateState } = filters;

  const toggleOpen = () => {
    if (!isDisabled) {
      setOpen(true);
    }
  };

  const close = () => {
    setOpen(false);
  };

  const onDateFilterChange = (
    values: [moment.Moment | null, moment.Moment | null] | null,
  ) => {
    setFilters({ type: 'SELECT_START_DATE', payload: values[0] && values[0].toDate() });
    setFilters({ type: 'SELECT_END_DATE', payload: values[1] && values[1].toDate() });
  };

  const disabledDate: TDatePickerProps['disabledDate'] = current => current && current > moment();

  const menuItems: { type: string; label: string }[] = !tempYoutubeDemoAccountMode ?
  [
    {
      type: 'THIS_WEEK',
      label: 'This Week',
    },
    {
      type: 'LAST_WEEK',
      label: 'Last Week',
    },
    {
      type: 'LAST_MONTH',
      label: 'Last Month',
    },
    {
      type: 'MTD',
      label: 'Month to Date',
    },
    {
      type: 'LAST_QUARTER',
      label: 'Last Quarter',
    },
    {
      type: 'QTD',
      label: 'Quarter to Date',
    },
    {
      type: 'LAST_6_MONTHS',
      label: 'Last 6 Months',
    },
    {
      type: 'YTD',
      label: 'Year to Date',
    },
    {
      type: 'SELECT_ALL_TIME',
      label: 'All Time',
    },
  ]
  :   [
    {
      type: 'THIS_WEEK',
      label: 'This Week',
    },
    {
      type: 'LAST_WEEK',
      label: 'Last Week',
    },
    {
      type: 'LAST_MONTH',
      label: 'Last Month',
    },
  ];

  const tooltipTitle = (
    <div>
      Metrics are currently shown based on when they occur regardless of when the post was live.
      {' '}
      <Link
        // Using the below method to open a new link due to a react-dom parent click event that is breaking the href attribute
        onClick={() => {
          window.open(
            'https://intercom.help/aspireiq_elevate/en/articles/6989812-post-date-vs-insight-date', '_blank', 'noopener'
          )
        }}
        className={styles.LinkStyled}
      >
        Learn More.
      </Link>
    </div>
  );

  const isDateRange = (date: string): boolean => (
    /^\d{2}\/\d{2}\/\d{2} - \d{2}\/\d{2}\/\d{2}$/.test(date)
  );
  return (
    <>
      <div
        ref={ref}
        className={cx(styles.filter, {
          [styles.disabled]: isDisabled,
        })}
        onClick={toggleOpen}
      >
        <span className={styles.filterCircle}>
          <DateRangeIcon size={16} />
        </span>
        <span className={styles.filterText}>{dateState.text}</span>
      </div>
      <Popover
        mountRef={ref}
        show={open}
        onRequestClose={close}
        className={styles.DateFilterPopover}
      >
        <ul className={styles.MenuList}>
          {menuItems.map((item, i) => (
            <li
              className={cx(styles.MenuItem, {
                [styles.selected]: dateState.text === item.label,
              })}
              onClick={() => {
                setFilters({ type: item.type });
                setOpen(false);
              }}
              key={i}
            >
              {item.label}
            </li>
          ))}
          {!tempYoutubeDemoAccountMode && 
            <li className={cx(styles.MenuItem, styles.customRange, {
              [styles.selected]: isDateRange(dateState.text),
            })}
            >
              <Accordion
                className={(styles as any).accordion}
                labelClassName={styles.customRangeLabel}
                contentsClassName={styles.customRangeContent}
                isOpen={dateState.customDateOpen}
                label="Custom Range"
                onToggle={() => setFilters({ type: 'OPEN_CUSTOM_DATE' })}
                arrowPlacement="right"
              >
                <div className={styles.dateRange}>
                  <RangePicker
                    // @ts-ignore TODO: Fix in Node upgrade typing bash!
                    classNames={styles.datePicker as any}
                    disabledDate={disabledDate}
                    format="MMM D, YYYY"
                    onChange={onDateFilterChange}
                    value={[moment(dateState.date.start), moment(dateState.date.end)]}
                  />
                </div>
              </Accordion>
            </li>  
          }
          {featureFlagInsightsDateFilter && (
            <>
              <Divider />
              <div className={styles.CheckBoxStyled}>
                <li
                  className={cx(styles.CheckboxMenuItem, {
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleInsights(!filters.isInsight);
                  }}
                >
                  <Checkbox checked={!filters.isInsight}>
                    Only show metrics for posts that have gone live during the above time ranges.{' '}
                  </Checkbox>
                </li>
                <Tooltip
                  zIndex={9999}
                  placement='bottom'
                  title={tooltipTitle}
                >
                  <CircleInfoIcon color='#8C8C8C' />
                </Tooltip>
              </div>
            </>
          )}
        </ul>
      </Popover>
    </>
  );
};

export const initialDateState = () => ({
  text: 'Last 6 months',
  customDateOpen: false,
  date: {
    start: subMonths(new Date(), 6),
    end: new Date(),
  },
});

const createDateString = (date) =>
  `${date.start ? format(date.start, 'MM/dd/yy') : 'Please Select'} - ${date.end ? format(date.end, 'MM/dd/yy') : 'Please Select'
  }`;

export const dateReducer = (state, action) => {
  const localState = state.datePosted;
  switch (action.type) {
    case 'SELECT_START_DATE': {
      const date = { ...localState.date, start: action.payload };
      return {
        ...localState,
        text: createDateString(date),
        date,
      };
    }
    case 'SELECT_END_DATE': {
      const date = { ...localState.date, end: action.payload };
      return {
        ...localState,
        date,
        text: createDateString(date),
      };
    }
    case 'THIS_WEEK': {
      const start = startOfWeek(new Date());
      const end = new Date();
      return {
        ...localState,
        date: { start, end },
        text: 'This Week',
        customDateOpen: false,
      };
    }
    case 'LAST_WEEK': {
      const start = subWeeks(startOfDay(new Date()), 1);
      const end = endOfDay(new Date());
      return {
        ...localState,
        date: { start, end },
        text: 'Last Week',
        customDateOpen: false,
      };
    }
    case 'LAST_MONTH': {
      const start = startOfMonth(subMonths(new Date(), 1));
      const end = endOfMonth(subMonths(new Date(), 1));
      return {
        ...localState,
        date: { start, end },
        text: 'Last Month',
        customDateOpen: false,
      };
    }
    case 'MTD': {
      const start = startOfMonth(new Date());
      const end = new Date();
      return {
        ...localState,
        date: { start, end },
        text: 'Month to Date',
        customDateOpen: false,
      };
    }
    case 'LAST_QUARTER': {
      const date = new Date();
      const start = startOfQuarter(subQuarters(date, 1));
      const end = endOfQuarter(subQuarters(date, 1));
      return {
        ...localState,
        date: { start, end },
        text: 'Last Quarter',
        customDateOpen: false,
      };
    }
    case 'QTD': {
      const start = startOfQuarter(new Date());
      const end = new Date();
      return {
        ...localState,
        date: { start, end },
        text: 'Quarter to Date',
        customDateOpen: false,
      };
    }
    case 'LAST_6_MONTHS': {
      const start = subMonths(startOfDay(new Date()), 6);
      const end = endOfDay(new Date());
      return {
        ...localState,
        date: { start, end },
        text: 'Last 6 Months',
        customDateOpen: false,
      };
    }
    case 'YTD': {
      const start = startOfYear(new Date());
      const end = new Date();
      return {
        ...localState,
        date: { start, end },
        text: 'Year to Date',
        customDateOpen: false,
      };
    }
    case 'SELECT_ALL_TIME': {
      const date = {
        start: state?.clientStartDate || new Date(),
        end: new Date(),
      };
      return {
        ...localState,
        date,
        text: `All Time (${createDateString(date)})`,
        customDateOpen: false,
      };
    }
    case 'OPEN_DROPDOWN': {
      return { ...localState, open: true };
    }
    case 'CLOSE_DROPDOWN': {
      return { ...localState, open: false };
    }
    case 'OPEN_CUSTOM_DATE': {
      return { ...localState, customDateOpen: true };
    }
    default:
      return localState;
  }
};
