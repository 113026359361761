import gql from 'graphql-tag';

export const AUTOMATION_EXECUTION_LIFECYCLE_STATS_FRAGMENT = gql`
  fragment AutomationExecutionLifecycleStatsFragment on AutomationExecutionLifecycleStats {
    total
    pending
    running
    failed
    terminated
    completed
  }
`;
