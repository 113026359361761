import React from 'react';
import { Card } from '@revfluence/fresh';
import { useParams } from 'react-router-dom';
import { useGetCollectionDetails } from '../hooks/useGetCollectionDetails';
import styles from './CollectionDetails.scss';
import { TabContainer } from '../TabContainer/TabContainer';
import { ProductsTable } from '../../components/ProductsTable/ProductsTable';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { pfaV2Routes } from '../../routes';
import { ProductDetailsDrawerProvider } from '../ProductDetails/ProductDetailsDrawerContext';

export const CollectionDetails = () => {
  const { collectionId } = useParams<{ collectionId: string }>();

  const { collectionDetails } = useGetCollectionDetails({
    variables: {
      collectionId: Number(collectionId),
    },
  });

  return (
    <div className={styles.CollectionDetails}>
      <PageHeader
        title={collectionDetails.name}
        image={collectionDetails.images?.[0] || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'}
        backButtonUrl={pfaV2Routes.settings.imports}
      />
      <TabContainer>
        <Card>
          {/* <Row gutter={[16, 16]} className={styles.metricsRow}>
            <Col flex={1}>
              <StatsRenderer title="Products" {...stats.totalProducts} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Variants" {...stats.totalVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="In Stock Variants" {...stats.inStockVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Out of Stock Variants" {...stats.outOfStockVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Low Inventory (<10)" {...stats.lowStockVariants} />
            </Col>
            <Col flex={1}>
              <StatsRenderer title="Pending Products" {...stats.pendingProducts} />
            </Col>
          </Row> */}
          <ProductDetailsDrawerProvider>
            <ProductsTable />
          </ProductDetailsDrawerProvider>
        </Card>
      </TabContainer>
    </div>
  );
};
