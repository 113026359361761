import 'css-chunk:src/app/components/UploadCSVModal/steps/ReviewImport.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_17jub_157",
  "justify-content-space-between": "_justify-content-space-between_17jub_161",
  "tabular-nums": "_tabular-nums_17jub_165",
  "table": "_table_17jub_355",
  "footer": "_footer_17jub_396",
  "ReviewImport": "_ReviewImport_17jub_412",
  "isLoading": "_isLoading_17jub_412",
  "alert": "_alert_17jub_415",
  "checkIcon": "_checkIcon_17jub_418",
  "errorIcon": "_errorIcon_17jub_419",
  "identifierTag": "_identifierTag_17jub_428",
  "fieldSelect": "_fieldSelect_17jub_431",
  "hasError": "_hasError_17jub_434",
  "unassignedMessage": "_unassignedMessage_17jub_437",
  "errorMessage": "_errorMessage_17jub_438",
  "dataPreviewCell": "_dataPreviewCell_17jub_448",
  "filler": "_filler_17jub_451",
  "show": "_show_17jub_1"
};