/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1p2b4_157 {
  display: flex;
}

._justify-content-space-between_1p2b4_161 {
  justify-content: space-between;
}

._tabular-nums_1p2b4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1p2b4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._customDeepHead_1p2b4_178 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._emptyDeepLink_1p2b4_184 {
  text-align: center;
  margin-top: 32px;
}
._emptyDeepLink_1p2b4_184 img {
  border: 1px solid #d9d9d9;
}

._emptyDeepLinkMessage_1p2b4_192 {
  margin-top: 12px;
}

._customLinkForm_1p2b4_196 {
  padding: 16px 0 0 0;
}
._customLinkForm_1p2b4_196 ._antFormItem_1p2b4_199 {
  margin: 0;
}
._customLinkForm_1p2b4_196 ._antForm_1p2b4_199 {
  border-radius: 6px;
  background: var(--neutral-color-palette-gray-2, #fafafa);
  padding: 16px 8px;
}
._customLinkForm_1p2b4_196 ._antFormItemRequired_1p2b4_207::before {
  content: "" !important;
}
._customLinkForm_1p2b4_196 ._antFormItemExplain_1p2b4_210 {
  color: #f5222d;
}
._customLinkForm_1p2b4_196 ._antFormItemRow_1p2b4_213 {
  display: block;
}
._customLinkForm_1p2b4_196 ._antFormItemLabel_1p2b4_216 {
  font-weight: 600;
}

._styledInput_1p2b4_220 {
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
}

._styledButtonGroup_1p2b4_225 {
  background: none;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
}
._styledButtonGroup_1p2b4_225 ._antBtnPrimary_1p2b4_235[disabled] {
  opacity: 0.65;
  background: var(--action-blue-blue-5, #499DFF);
  color: #fff;
}
._styledButtonGroup_1p2b4_225:hover {
  background: none;
}