import * as React from 'react';

import { logger } from '@common';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { useMemberSearchQuery } from '@frontend/app/hooks';

import { IMembersTableSearchQuery } from '../MembersTable';

const {
  useCallback,
  useState,
  useEffect,
} = React;

interface IOptions {
  searchQuery: IMembersTableSearchQuery;
}

interface IUseFetchMembers {
  fetchMembers: (queryVariables: IMembersTableSearchQuery) => Promise<void>;
  isLoading: boolean;
  members: IMember[];
}

export const useFetchMembers = ({
  searchQuery,
}: IOptions, dynamicQueryFields = new Set([])): IUseFetchMembers => {
  const [members, setMembers] = useState<IMember[]>();
  const {
    loading: isLoading,
    refetch: refetchMembers,
  } = useMemberSearchQuery({
    variables: {
      query: null,
      ...searchQuery,
    },
    fetchPolicy: 'network-only',
    skip: !searchQuery,
  }, dynamicQueryFields);
  const [localLoading, setLocalLoading] = useState(isLoading);
  useEffect(() => setLocalLoading(isLoading), [isLoading]);
  const fetchMembers = useCallback(
    (queryVariables: IMembersTableSearchQuery): Promise<void> => {
      if (queryVariables) {
        setLocalLoading(true);
        return refetchMembers(queryVariables)
          .then((result) => {
            setMembers(result?.data?.members);
            setLocalLoading(false);
          })
          .catch((error) => {
            logger.error(error);
            setLocalLoading(false);
          });
      } else {
        setMembers([]);
        setLocalLoading(false);
      }
    },
    [refetchMembers],
  );

  return {
    fetchMembers,
    isLoading: localLoading,
    members,
  };
};
