import * as React from 'react';
import { Row, Col } from 'antd';
import { format, fromUnixTime } from 'date-fns';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { IRowData } from '@components';
import { WizardContainer, IStepInfo } from '@frontend/applications/Shared/Wizard/container';
import { PAYMENT_APP_ID } from '@frontend/app/constants/applicationIds';
import { IPaymentGroup } from '@frontend/applications/PaymentsApp/models';
import { IHeader, useExportToCsv } from '@affiliates/hooks';

import { BulkPaymentsTable } from '../BulkPaymentsTable/BulkPaymentsTable';
import { renderStatsCard, renderFooterInfoComponent } from '../BulkPaymentsApp/BulkPaymentsApp';

import styles from './PaymentGroupInvoice.scss';

const paymentsIcon = require('@frontend/app/assets/svgs/payment_circle.svg');

const { useMemo, useCallback } = React;

interface IPaymentGroupInvoiceProps {
  paymentGroup: IPaymentGroup;
  amountPaid: number;
  amountDue: number;
  tableData: IRowData[];
  onCancel: Function;
}

const PAYMENT_GROUP_DATE_FORMAT = 'MMMM dd, yyyy';

const defaultCsvColumnConfig: IHeader[] = [
  {
    headerName: 'Member',
    field: 'memberName',
  },
  {
    headerName: 'Amt Paid',
    field: 'amountPaid',
  },
  {
    headerName: 'Status',
    field: 'status',
  },
  {
    headerName: 'Paypal Email',
    field: 'paypalEmail',
  },
  {
    headerName: 'Date Initiated',
    field: 'createdDate',
  },
];

export const PaymentGroupInvoice: React.FC<Readonly<IPaymentGroupInvoiceProps>> = (props) => {
  const history = useHistory();
  const showAmountDue = props.amountDue > 0;
  const customTableColumns = props.paymentGroup.metadata?.columns;
  const exportToCsv = useExportToCsv();

  const downloadInvoice = useCallback(() => {
    // Create csv column configs based on table column settings.
    const csvColumnConfig = [...defaultCsvColumnConfig];
    if (showAmountDue) {
      csvColumnConfig.splice(1, 0, { headerName: 'Amt Due', field: 'amountDue' } as IHeader);
    }
    if (customTableColumns && customTableColumns.length > 0) {
      csvColumnConfig.splice(
        1,
        0,
        ...customTableColumns.map(({ headerName, field }) => ({ headerName, field } as IHeader)),
      );
    }
    exportToCsv(`invoices-${Date.now()}`, csvColumnConfig, props.tableData);
  }, [exportToCsv, showAmountDue, customTableColumns, props.tableData]);

  const statsCard = useMemo(() => renderStatsCard({
      forInvoice: true,
      memberCount: props.tableData.length,
      amountPay: props.amountPaid,
      amountDue: props.amountDue,
      showAmountDue,
    }), [props.tableData, props.amountPaid, props.amountDue, showAmountDue]);

  const paymentInvoiceStep = (
    <Row gutter={[10, 30]}>
      <Col span={24}>{statsCard}</Col>
      {!isEmpty(props.tableData) && (
        <Col span={24}>
          <BulkPaymentsTable
            selectable={false}
            forInvoice
            extraColumns={customTableColumns}
            data={props.tableData}
            showAmountDue={showAmountDue}
          />
        </Col>
      )}
    </Row>
  );

  const goToPaymentHistory = () => history.push({ ...location, pathname: `/app/${PAYMENT_APP_ID}/reports` });

  const stepsInfo: IStepInfo[] = [
    {
      title: 'Payment Invoice',
      stepNum: 1,
      instructions: {
        title: `Congrats! You sent ${props.tableData.length} payment${props.tableData.length > 1 ? 's' : ''}.`,
        description: `Payment date: ${format(fromUnixTime(props.paymentGroup.created_ts), PAYMENT_GROUP_DATE_FORMAT)}.`,
      },
      actionComponents: paymentInvoiceStep,
      previousButtonConfig: {
        text: isEmpty(props.tableData) ? '' : 'Download Invoice', // Hide the download invoice button if there is no invoice.
        action: () => downloadInvoice(),
        showIcon: false,
      },
      nextButtonConfig: {
        text: 'View Payment History',
        action: goToPaymentHistory,
        showIcon: false,
      },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
  ];

  const footerInfoComponent = useMemo(() => renderFooterInfoComponent(), []);

  return (
    <div className={styles.BulkPaymentsApp}>
      <WizardContainer
        icon={paymentsIcon}
        stepsInfo={stepsInfo}
        footerInfoComponent={footerInfoComponent}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onCancel={props.onCancel as any}
      />
    </div>
  );
};
