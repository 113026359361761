import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const ChatIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 60 60">
    <path
      d="M30,1.5c-16.542,0-30,12.112-30,27c0,5.205,1.647,10.246,4.768,14.604c-0.591,6.537-2.175,11.39-4.475,13.689
      c-0.304,0.304-0.38,0.769-0.188,1.153C0.276,58.289,0.625,58.5,1,58.5c0.046,0,0.093-0.003,0.14-0.01
      c0.405-0.057,9.813-1.412,16.617-5.338C21.622,54.711,25.738,55.5,30,55.5c16.542,0,30-12.112,30-27S46.542,1.5,30,1.5z M16,19.515
      h15c0.552,0,1,0.448,1,1s-0.448,1-1,1H16c-0.552,0-1-0.448-1-1S15.448,19.515,16,19.515z M44,37.515H16c-0.552,0-1-0.448-1-1
      s0.448-1,1-1h28c0.552,0,1,0.448,1,1S44.552,37.515,44,37.515z M44,29.515H16c-0.552,0-1-0.448-1-1s0.448-1,1-1h28
      c0.552,0,1,0.448,1,1S44.552,29.515,44,29.515z"
    />
  </SvgIcon>
));

ChatIcon.displayName = 'ChatIcon';

export { ChatIcon };
