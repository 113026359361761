/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1eh0r_157 {
  display: flex;
}

._justify-content-space-between_1eh0r_161 {
  justify-content: space-between;
}

._tabular-nums_1eh0r_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1eh0r_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TableView_1eh0r_178 {
  position: relative;
  flex: 1;
  display: flex;
}
._TableView_1eh0r_178 ._usernameField_1eh0r_183 {
  user-select: none;
  cursor: pointer;
  font-weight: 400;
  color: #3996e0;
  will-change: color;
  transition: color 0.1s ease-out;
}
._TableView_1eh0r_178 ._usernameField_1eh0r_183:hover {
  color: #4fa7ee;
}
._TableView_1eh0r_178 ._favorite_1eh0r_194 {
  margin-right: 0.5rem;
}
._TableView_1eh0r_178 ._button_1eh0r_197 {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
._TableView_1eh0r_178 ._sendBulkOffer_1eh0r_201 {
  width: 152px;
}
._TableView_1eh0r_178 ._noContents_1eh0r_204 {
  margin: 20px auto;
  width: 650px;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
}
._TableView_1eh0r_178 ._noContents_1eh0r_204 ._title_1eh0r_212 {
  margin-bottom: 10px;
  font-weight: 600;
}
._TableView_1eh0r_178 ._programsList_1eh0r_216 {
  width: 100%;
  max-height: 100%;
}
._TableView_1eh0r_178 ._selectedRowsCount_1eh0r_220 {
  margin-right: 0.625rem;
  color: #1A1818;
}