import { toLower } from 'lodash';

export enum OsPlatform {
  ANDROID = 'android',
  IOS = 'ios',
  LINUX = 'linux',
  MAC = 'mac',
  OTHER = 'other',
  WINDOWS = 'windows',
}

// typescript's Navigator type doesn't yet include userAgentData
interface ExtendedNavigator extends Navigator {
  userAgentData?: {
    platform?: string;
  }
}

export const detectOsPlatform = (): OsPlatform => {
  const { platform, userAgentData } = (navigator as ExtendedNavigator);
  const platformString = toLower(userAgentData?.platform || platform);

  if (platformString.startsWith('mac')) {
    return OsPlatform.MAC;
  }
  if (platformString.startsWith('win')) {
    return OsPlatform.WINDOWS;
  }
  if (platformString.startsWith('iphone') || platformString.startsWith('ipad') || platformString.startsWith('ipod')) {
    return OsPlatform.IOS;
  }
  if (platformString.startsWith('android')) {
    return OsPlatform.ANDROID;
  }
  if (platformString.startsWith('linux')) {
    return OsPlatform.LINUX;
  }

  return OsPlatform.OTHER;
};
