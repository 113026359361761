import * as React from 'react';
import {
  orderBy, map, slice, isFunction, trim,
} from 'lodash';
import moment from 'moment';
import xss from 'xss';
import { logger } from '@common';
import {
  Avatar, Button, Input, Space, Typography, Spinner,
} from '@revfluence/fresh';
import { UserAvatar } from '@frontend/app/components';
import { useMemberCommentsQuery, useSaveMemberCommentMutation } from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';
import { useMessagingContext } from '@frontend/hooks';

import { RichTextVisualizer } from '@frontend/app/components/RichTextEditor/Visualizer';
import styles from './Notes.scss';

const { useMemo, useState, useEffect } = React;
const { Text } = Typography;
const { TextArea } = Input;
const aiqLogo = require('@frontend/app/assets/svgs/aiq-logomark.svg');

interface Props {
  memberId: number;
  viewMore?: boolean;
  onViewMoreVisibilityChange?: (visible: boolean) => void;
}

const Notes: React.FC<Props> = ({
  memberId,
  viewMore,
  onViewMoreVisibilityChange,
}) => {
  const [commentText, setCommentText] = useState<string>('');

  const {
    data: {
      member: {
        comments = null,
      } = {},
    } = {},
    refetch: refetchComments,
    loading: isLoading,
  } = useMemberCommentsQuery(memberId);
  const [saveMemberComment, { loading: isSaving }] = useSaveMemberCommentMutation();
  const { showGenericErrorMessage } = useMessagingContext();
  const { user } = useAuth();

  const canSave = useMemo(() => !!trim(commentText), [commentText]);

  const addComment = async () => {
    try {
      await saveMemberComment({
        variables: {
          memberId,
          comment: {
            userId: user.sub,
            text: commentText,
          },
        },
      });
      setCommentText('');
      await refetchComments();
    } catch (error) {
      logger.error(error);
      showGenericErrorMessage();
    }
  };

  const sortedComments = useMemo(() => {
    const orderedComments = orderBy(comments, 'createdDate', 'desc');

    return viewMore ? orderedComments : slice(orderedComments, 0, 2);
  }, [comments, viewMore]);

  useEffect(() => {
    if (isFunction(onViewMoreVisibilityChange)) {
      onViewMoreVisibilityChange(comments?.length > 2);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Space direction="vertical" style={{ width: '100%' }}>
        <TextArea
          rows={2}
          placeholder="Enter an internal note for your team about this member"
          value={commentText}
          onChange={(ev) => {
            const { value } = ev.target;
            setCommentText(value);
          }}
        />
        <Button
          type="primary"
          size="small"
          loading={isSaving}
          disabled={!canSave}
          onClick={addComment}
        >
          Save Note
        </Button>
      </Space>

      {isLoading && (
        <Spinner />
      )}

      {map(sortedComments, (comment) => (
        <Space
          key={comment.id}
          direction="vertical"
          style={{ width: '100%' }}
        >
          <Space
            direction="horizontal"
            align="center"
            style={{ justifyContent: 'space-between' }}
          >
            <Space direction="horizontal" size={4}>
              {comment.user ? (
                <>
                  <UserAvatar name={comment.user.name} />
                  <Text className={styles.username}>{comment.user.name}</Text>
                </>
              ) : (
                <Avatar src={aiqLogo} />
              )}
            </Space>
            <Text type="secondary">
              {moment(comment.createdDate).format('MMM D YYYY, h:mm a')}
            </Text>
          </Space>
          <RichTextVisualizer className={styles.comment}>
            {xss(comment.text)}
          </RichTextVisualizer>
        </Space>
      ))}
    </Space>
  );
};

export default Notes;
