import * as React from 'react';
import cx from 'classnames';
import { MenuList } from '@frontend/app/components';

import { CustomFieldsList } from './CustomFieldsList';
import { DefaultFieldsList } from './DefaultFieldsList';

import styles from './CommunityIdentity.scss';

const { useState, useCallback } = React;

enum ListIdentifiers {
  Custom = 'custom',
  Default = 'default',
}

const ListOptions = [
  { id: ListIdentifiers.Custom, title: 'Custom Fields' },
  { id: ListIdentifiers.Default, title: 'Default Fields' },
];

interface IProps {
  className?: string;
}

export const CommunityIdentity: React.FunctionComponent<IProps> = (props) => {
  const [selectedListId, setSelectedListId] = useState<ListIdentifiers>(ListIdentifiers.Custom);

  const handleSelectList = useCallback((listId: ListIdentifiers) => {
    setSelectedListId(listId);
  }, [setSelectedListId]);

  return (
    <div className={cx(styles.CommunityIdentity, props.className)}>
      <MenuList
        className={styles.left}
        items={ListOptions}
        selectedId={selectedListId}
        onSelectItem={handleSelectList}
      />
      <div className={styles.right}>
        {selectedListId === ListIdentifiers.Custom && (
          <CustomFieldsList />
        )}

        {selectedListId === ListIdentifiers.Default && (
          <DefaultFieldsList />
        )}
      </div>
    </div>
  );
};
