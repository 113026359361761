/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_18r0l_157 {
  display: flex;
}

._justify-content-space-between_18r0l_161 {
  justify-content: space-between;
}

._tabular-nums_18r0l_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_18r0l_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._fiscalYearBudget_18r0l_178 {
  display: flex;
  align-items: end;
  gap: 1rem;
}