/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dwow_157 {
  display: flex;
}

._justify-content-space-between_1dwow_161 {
  justify-content: space-between;
}

._tabular-nums_1dwow_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dwow_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._card_1dwow_178 {
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  height: fit-content;
  width: 100%;
  margin: 0 auto;
}

._content_1dwow_186 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
._content_1dwow_186 ._fiscalYearContainer_1dwow_191 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

._titleSection_1dwow_197 {
  display: flex;
  flex-direction: column;
}

._title_1dwow_197 {
  margin-bottom: 0 !important;
}

._status_1dwow_206 {
  width: 3.375rem;
}

._details_1dwow_210 {
  display: flex;
  justify-content: start;
  gap: 1rem 1.5rem;
  flex-wrap: wrap;
}

._detail_1dwow_210 {
  display: flex;
  flex-direction: column;
}

._detailValue_1dwow_222 {
  margin: 0 !important;
}

._detailLabel_1dwow_226 {
  font-weight: 400;
  color: #8c8c8c;
  font-size: 0.875rem;
}

._iconContainer_1dwow_232 {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #5DB884;
}

._icon_1dwow_232 {
  color: #FFFFFF;
  height: 1.25rem;
  width: 1.25rem;
}