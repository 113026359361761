import { CHECK_FIRST_LOGIN_MUTATION } from '@frontend/app/queries';
import { CheckFirstLogin, CheckFirstLoginVariables } from '@frontend/app/queries/types/CheckFirstLogin';
import { useMutation, MutationHookOptions } from '@apollo/client';
import { IUser } from '@frontend/context/authContext';
import { useCallback, useEffect } from 'react';
import { EventName, logger } from '@common';
import { get } from 'lodash';
import { useEventContext } from '../context';

type IOptions = MutationHookOptions<CheckFirstLogin, CheckFirstLoginVariables>;

export function useCheckFirstLogin(user: IUser | undefined, options: IOptions = {}) {
  const [checkFirstLogin, { loading, error }] = useMutation<CheckFirstLogin>(
    CHECK_FIRST_LOGIN_MUTATION, {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...options as any,
    },
  );

  const addEvent = useEventContext();

  const firstLogin = useCallback(async (user: IUser | undefined) => {
    if (!user) {
      return;
    }
    if (!window.sessionStorage || (window?.sessionStorage && window.sessionStorage.getItem('login') == user.sub)) {
      return;
    }
    try {
      window.sessionStorage.setItem('login', user.sub);
    } catch (err) {
      logger.error(err);
      return;
    }
    const { data = {} } = await checkFirstLogin();
    const isFirstLogin = get(data, 'firstLogin');
    addEvent(EventName.UserLogin, { first_login: isFirstLogin || false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFirstLogin]);

  useEffect(
    () => {
      firstLogin(user).catch(logger.error);
    }, [user, firstLogin],
  );

  return {
    loading,
    checkFirstLogin,
    error,
  };
}
