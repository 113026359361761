import { useMutation, MutationHookOptions } from '@apollo/client';

import {
  PROSPECTS_ADD_TO_COMMUNITY_MUTATION,
} from '@frontend/app/queries';
import {
  ProspectsAddToCommunityMutation,
  ProspectsAddToCommunityMutationVariables,
} from '@frontend/app/queries/types/ProspectsAddToCommunityMutation';

type IOptions = MutationHookOptions<ProspectsAddToCommunityMutation, ProspectsAddToCommunityMutationVariables>;

export const useProspectsAddToCommunity = (options: IOptions = {}) => useMutation<ProspectsAddToCommunityMutation, ProspectsAddToCommunityMutationVariables>(PROSPECTS_ADD_TO_COMMUNITY_MUTATION, options);
