import gql from 'graphql-tag';

const GET_DASHBOARD_PROJECT_TAB_SUMMARY = gql`
  query GetBudgetDashboardProjectTabSummaryQuery($programId: Int!, $fiscalYear: String!) {
    summary:getBudgetDashboardProjectTabSummary(programId: $programId,fiscalYear: $fiscalYear) {
      allocatedBudget
      spentBudget
      totalBudget
    }
  }
`;

export default GET_DASHBOARD_PROJECT_TAB_SUMMARY;
// GetBudgetDashboardProjectTabSummaryQuery.ts
// useGetBudgetDashboardProjectTabSummaryQuery.ts
