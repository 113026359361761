import { v4 as uuidv4 } from 'uuid';
import { IOrdersByIds } from '../../hooks';
import { getAddressString } from '../../utils';

export interface OfflineOrderRow {
  key: string;
  orderId: string;
  name?: string;
  avatar?: string;
  quantity?: number;
  productName?: string;
  sku?: string;
  preferences?: string;
  productUrl?: string;
  trackingNumber?: string;
  productCost?: string;
}

export const getDataSource = (orders: IOrdersByIds[]): OfflineOrderRow[] =>
  orders.map((order) => {
    const products = order.creatorOrderRequest?.no_catalogue_line_items || [];
    const parentProduct = products[0] || {};

    const baseOrderInfo = {
      key: `parent_${order.id}`,
      orderId: order.id,
      name: order.member?.name || '--',
      avatar: '--',
      address: getAddressString(order.creatorOrderRequest?.shipping_address) || '--',
      country: order.creatorOrderRequest.shipping_address?.country || '--',
    };

    if (products.length <= 1) {
      return {
        ...baseOrderInfo,
        quantity: parentProduct.quantity || 0,
        productName: parentProduct.productName || '--',
        sku: parentProduct.productSKU || '--',
        preferences: parentProduct.productPreferences || '--',
        productUrl: parentProduct.productURL || '--',
        productLength: products.length,
      };
    }

    return {
      ...baseOrderInfo,
      productLength: products.length,
      quantity: products.length,
      productName: parentProduct.productName || '--',
      sku: '--',
      preferences: '--',
      productUrl: '--',
      children: products.map((product) => ({
        key: `${order.id}_${product.productSKU}_${uuidv4()}`,
        name: '',
        avatar: '',
        quantity: product.quantity || 0,
        productName: product.productName || '--',
        sku: product.productSKU || '--',
        preferences: product.productPreferences || '--',
        productUrl: product.productURL || '--',
      })),
    };
  });
