import { isEmpty, isString } from 'lodash';
import { checkMultipleDomainHasDeepLink, checkUrlsHasSameDomain, createUrlObj } from '@frontend/applications/AffiliatesApp/utils';

interface ValidationResult {
  error: string | null;
  warning: string | null;
}

export const validateDeepLink = (deepLink: string, offerLink?: string, isCreatorDeepLinkAllowed?: boolean, domains?: string[]): ValidationResult => {
  const offerLinkObj = createUrlObj(offerLink);
  const notUndefined = !isEmpty(deepLink);
  const hasValidLink = notUndefined ? !isString(createUrlObj(deepLink)) : true;
  const hasValidLength = notUndefined && hasValidLink ? deepLink.length < 500 : null;
  const hasSameDomain = notUndefined
    ? ((isCreatorDeepLinkAllowed && !isEmpty(domains))
      ? checkMultipleDomainHasDeepLink(deepLink, [...domains, offerLink])
      : checkUrlsHasSameDomain(offerLink, deepLink)) : true;

  let error = null;
  if (!hasValidLink) {
    error = 'Deep Link must be a valid URL';
  }
  if (hasValidLength !== null && !hasValidLength) {
    error = 'Deep link must be less than 500 characters';
  }

  const warning = hasSameDomain ? null : `Ensure proper tracking is set up for your Deep Link URL, as it differs from ${!isString(offerLinkObj) && offerLinkObj.origin} ${(isCreatorDeepLinkAllowed && !isEmpty(domains)) ? `, ${domains.join(', ')}` : ''}.`;
  return { error, warning };
};
