import 'css-chunk:src/app/components/ContentReviewTable/components/Table/Table.module.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1bcyf_157",
  "justify-content-space-between": "_justify-content-space-between_1bcyf_161",
  "tabular-nums": "_tabular-nums_1bcyf_165",
  "table": "_table_1bcyf_178",
  "deliverables": "_deliverables_1bcyf_211",
  "status": "_status_1bcyf_217",
  "ContentCell": "_ContentCell_1bcyf_224",
  "icon": "_icon_1bcyf_229",
  "link": "_link_1bcyf_233",
  "OverlayContent": "_OverlayContent_1bcyf_237",
  "image": "_image_1bcyf_246",
  "video": "_video_1bcyf_246",
  "nameColumn": "_nameColumn_1bcyf_253",
  "instagramEmbedAvatar": "_instagramEmbedAvatar_1bcyf_260",
  "expandButton": "_expandButton_1bcyf_265",
  "show": "_show_1bcyf_1"
};