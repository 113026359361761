import { map } from 'lodash';
import moment from 'moment';
import {
  useMemo, useEffect, useState, useRef, useCallback,
} from 'react';

import { TNetworkIdentifier } from '@components';
import * as qs from 'qs';

export interface IAdvertiserAccountAccessRequirement {
  id: number;
  member_id: number;
  member: string;
  account_type: TNetworkIdentifier;
  account_name: string;
  access_start_ts: number;
  access_end_ts: number;
  ready_for_use?: boolean;
  in_error_state: boolean;
  has_access: boolean;
  has_ended: boolean;
  type: string;
}

interface FetchedData {
  'advertiser_access_current_monthly_usage': number;
  'advertiser_access_usage_limit': number;
  'requirements': IAdvertiserAccountAccessRequirement[];
  'total_count': number;
}

export type AccessStatus = 'disabled' | 'auth_error' | 'active' | 'pending' | 'over_limit' | 'unknown_error';

export const getAccessStatus = (
  req: IAdvertiserAccountAccessRequirement,
): AccessStatus => {
  // After end date is over
  if (req.has_ended) {
    return 'disabled';
  }

  if (req.ready_for_use) {
    if (req.in_error_state) {
      return 'auth_error';
    }

    if (req.has_access) {
      return 'active';
    } else if (moment().isBefore(moment.unix(req.access_start_ts).add(1, 'hours'))) {
      // Give the backend 1 hour to grant access after it has been marked as ready_for_use
      return 'pending';
    } else {
      // After 1 hour, assume there is an error that requires investigation
      return 'unknown_error';
    }
  }
  return 'pending';
};

export const getAdvertiserAccessTableRowData = (
  requirements: IAdvertiserAccountAccessRequirement[],
) => map(requirements, (r) => ({
  id: r.id,
  memberId: r.member_id,
  member: r.member,
  accounts: [{
    type: r.account_type,
    accountName: r.account_name,
  }],
  startTs: r.access_start_ts,
  endTs: r.access_end_ts,
  readyForUse: r.ready_for_use,
  status: getAccessStatus(r),
  type: r.type,
}));

export const useAccessData = (
  baseUrl: string,
  clientId: string,
  limit: number,
  page: number,
  sortDirection?: string,
  sortField?: string,
) => {
  const [data, setData] = useState<FetchedData | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const controllerRef = useRef<AbortController | null>();
  useEffect(() => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    const controller = new AbortController();
    controllerRef.current = controller;
    setLoading(true);
    const filterString = qs.stringify({
      client_id: clientId,
      limit,
      page,
      sort_direction: sortDirection,
      sort_field: sortField,
    });
    fetch(`${baseUrl}?${filterString}`, {
        signal: controllerRef.current?.signal,
    })
      .then(async (data) => {
        const json = await data.json();
        setError(null);
        setData(json.data);
      })
      .catch((error) => {
        setData(null);
        setError(error);
      })
      .finally(() => setLoading(false));
    return () => controller.abort();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, refetchCounter]);
  const refetch = useCallback(
    () => setRefetchCounter(refetchCounter + 1),
    [refetchCounter, setRefetchCounter],
  );
  const tableData = useMemo(() => {
    if (data) {
      return getAdvertiserAccessTableRowData(
        data.requirements,
      );
    } else {
      return null;
    }
  }, [data]);
  return useMemo(() => {
    const currentMonthlyUsage = data?.advertiser_access_current_monthly_usage;
    const usageLimit = data?.advertiser_access_usage_limit;
    return {
      refetch,
      isLoading,
      data: {
        requirements: tableData,
        totalCount: data?.total_count,
        currentMonthlyUsage,
        usageLimit,
      },
      error,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tableData, error, isLoading]);
};
