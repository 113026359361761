import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { Notice } from '@components';

import styles from './InvalidDataNotice.scss';

interface IProps {
  invalidMemberIds: number[];
  allHaveBackupPlans: boolean;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const InvalidDataNotice: React.FC<IProps> = React.memo((props) => {
  const { invalidMemberIds, allHaveBackupPlans } = props;

  if (isEmpty(invalidMemberIds) || allHaveBackupPlans) {
    return null;
  }

  return (
    <Notice
      className={cx(styles.InvalidDataNotice, props.className)}
      type="error"
      showDivider
    >
      <div className={styles.content}>
        <div className={styles.title}>
          {invalidMemberIds.length}
          {' '}
          recipient
          {invalidMemberIds.length > 1 ? 's' : ''}
          {' '}
          have missing personalization data
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <div className={(styles as any).text}>Please modify or remove those variables to send your personalized, mass message.</div>
      </div>
    </Notice>
  );
});

InvalidDataNotice.displayName = 'InvalidDataNotice';
