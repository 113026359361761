import * as React from 'react';
import { Tooltip, Typography } from '@revfluence/fresh';
import { DocumentIcon } from '@frontend/app/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { PaymentCreationSource, PaymentsProgram } from '@frontend/applications/PaymentsApp/types';
import { TActions } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import styles from './BriefsDue.scss';
import { BriefDueItem } from '../BriefDueItem';

const { Title } = Typography;
const { useMemo } = React;
interface IProps {
  terms: IAgreement[];
  programs: PaymentsProgram[];
  actions: TActions;
  paymentCreationSource: PaymentCreationSource;
}

export const BriefsDue: React.FC<IProps> = (props) => {
  const {
    terms,
    programs,
    actions,
    paymentCreationSource,
  } = props;
  const termsDataTransformed = useMemo(() => terms.map((term) => {
    const program = programs.find((program) => program.id === term.program_id);
    return {
      id: term.id,
      title: program?.title || term.campaign_name || '-',
      splashImageUrl: program?.splashImageUrl || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png',
      briefAgreedAmount: term.price,
      briefAgreedDue: (term.price * 100 - term.amount_marked_paid) / 100,
      briefAgreedDate: term.agreed_ts,
      createdDate: term.created_at,
    };
  }), [terms, programs]);

  const isProjectSource = paymentCreationSource === PaymentCreationSource.PROJECT;
  const titleText = isProjectSource ? 'Briefs in Project' : 'Briefs';
  const tooltipText = isProjectSource
    ? 'Briefs agreed by the member in current project. To see all the briefs, go to member profile.'
    : 'Briefs agreed by the member in all projects.';

  return (
    <div className={styles.briefsDueContainer}>
      <div className={styles.briefsDueHeader}>
        <DocumentIcon size={16} />
        <Title level={5}>{titleText}</Title>
        <Tooltip title={tooltipText}>
          <InfoCircleOutlined className={styles.infoIcon} />
        </Tooltip>
      </div>
      <div className={styles.briefsDueItems}>
        {
          termsDataTransformed.map((term) => (
            <BriefDueItem
              key={term.createdDate}
              title={term.title}
              splashImageUrl={term.splashImageUrl}
              briefAgreedAmount={term.briefAgreedAmount}
              briefAgreedDate={term.briefAgreedDate}
              briefAgreedDue={term.briefAgreedDue}
              createdDate={term.createdDate}
              onClick={() => {
                actions.setSelectedTermId(term.id);
                actions.setAmountPaying(term.briefAgreedDue);
                actions.setCurrentStep(2);
              }}
            />
          ))
        }
      </div>
    </div>
  );
};
