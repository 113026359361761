import * as React from 'react';

import { ProjectByIdQuery_project } from '@frontend/app/queries/types/ProjectByIdQuery';

type IProject = ProjectByIdQuery_project;

interface ISearchPageContext {
  project: IProject;
  setProject(project: IProject): void;
}

const { useContext, useState } = React;

const ProjectContext = React.createContext<ISearchPageContext>(null);
export const useProjectContext = () => useContext(ProjectContext);
export const ProjectContextProvider = ({ children }) => {
  const [project, setProject] = useState<IProject>(null);

  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
