import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_NODE_OPTIONS_QUERY } from '@frontend/app/queries';
import {
  GetNodeOptions,
  GetNodeOptions_getNodeOptions_actionOptions,
} from '@frontend/app/queries/types/GetNodeOptions';

export type TAutomationActionOption = GetNodeOptions_getNodeOptions_actionOptions;
type IOptions = QueryHookOptions<GetNodeOptions>;

export const useGetNodeOptionsQuery = (options: IOptions = {}) =>
  useQuery<GetNodeOptions>(GET_NODE_OPTIONS_QUERY, options);
