import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classnames';
import { TriangleExclamationIcon, CircleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';

import { AlertIcon, CheckCircleIcon, CloseIcon } from '@components';

import { useMobileStatus } from '@frontend/utils';

const {
 useState, useEffect, useRef, useCallback, useImperativeHandle,
} = React;
import styles from './Toast.scss';

export const TOAST_CLOSE_DURATION = 300;
type TToastMessageType = 'info' | 'warning' | 'success' | 'error';
export interface IToastMessage {
  type?: TToastMessageType;
  content: string | JSX.Element;
  duration?: number;
}
export interface IToastRefHandles {
  showMessage(message: IToastMessage);
}
interface IProps {
  useFresh?: boolean;
  className?: string;
}

/**
 * @type {React.RefForwardingComponent}
 */
const ToastComponent: React.RefForwardingComponent<IToastRefHandles, IProps> = (
  props,
  forwardedRef,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const showTimer = useRef<number>(null);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<IToastMessage>(null);
  const mobileType = useMobileStatus();

  // clears timer unmount
  useEffect(() => () => clearTimeout(showTimer.current), []);

  const closeToast = useCallback(() => setShow(false), []);

  useImperativeHandle(forwardedRef, () => ({
    showMessage: (message: IToastMessage) => {
      const messageDuration = message.duration || 5000;

      if (showTimer.current) {
        clearTimeout(showTimer.current);

        showTimer.current = null;
      }

      if (show) {
        setShow(false);

        setTimeout(() => {
          setShow(true);
          setMessage(message);

          showTimer.current = window.setTimeout(closeToast, messageDuration);
        }, TOAST_CLOSE_DURATION);
      } else {
        setShow(true);
        setMessage(message);

        showTimer.current = window.setTimeout(closeToast, messageDuration);
      }
    },
  }));

  // skip server side rendering
  if (typeof window === 'undefined') {
    return null;
  }

  const messageContent = (message && message.content) || '';
  const messageType = (message && message.type) || 'info';

  const renderContent = () => {
    if (props.useFresh) {
      return (
        <>
          <div className={styles.content}>
            {messageType === 'info' && (
              <CircleExclamationIcon height={20} width={20} className={styles.icon} />
            )}
            {messageType === 'error' && (
              <TriangleExclamationIcon width={20} height={20} className={cx(styles.icon, styles.fresh)} />
            )}
            {messageType !== 'error' && messageType !== 'info' && (
              <CheckCircleIcon size={20} className={cx(styles.icon, styles.infoIcon, styles.fresh)} />
            )}
            <div className={cx(styles.message, styles.fresh)}>{React.Children.toArray(messageContent)}</div>
          </div>
          <CloseIcon
            size={16}
            className={cx(styles.close, styles.fresh)}
            onMouseDown={closeToast}
          />
        </>
      );
    }

    return (
      <>
        <div className={styles.content}>
          {messageType === 'error' && (
            <AlertIcon size={20} className={cx(styles.icon, styles.alertIcon)} />
          )}
          {messageType !== 'error' && (
            <CheckCircleIcon size={20} className={cx(styles.icon, styles.infoIcon)} />
          )}
          <div className={styles.message}>{React.Children.toArray(messageContent)}</div>
        </div>
        <CloseIcon
          size={16}
          className={styles.close}
          onMouseDown={closeToast}
        />
      </>
    );
  };

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={cx(styles.Toast, props.className, {
        [styles.mobile]: mobileType === 'phone',
        [styles.active]: show,
        [styles.info]: messageType === 'info' || messageType === 'success',
        [styles.error]: messageType === 'error',
        [styles.warning]: messageType === 'warning',
        [styles.fresh]: props.useFresh,
        [styles.freshError]: props.useFresh && messageType === 'error',
        [styles.freshSuccess]: props.useFresh && messageType === 'success',
        [styles.freshInfo]: props.useFresh && messageType === 'info',
      })}
    >
      {renderContent()}
    </div>,
    document.body,
  );
};

ToastComponent.displayName = 'ToastComponent';

export const Toast = React.forwardRef(ToastComponent);
