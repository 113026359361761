import * as React from 'react';

import {
  Avatar, Button, Col, Image, Row,
} from '@revfluence/fresh';
import { AngleLeftIcon, AngleRightIcon } from '@revfluence/fresh-icons/regular/esm';

import { TContentType } from '../../../types';

interface Props {
  resources: TContentType[];
  onChange?: (index: number) => void;
  onSelect?: (index: number) => void;
}

/**
 * 7 - default visible items count
 * 6 - visible items with navigation
 */
const DEFAULT_COUNT = 7;

export const Carousel = ({ resources, onChange, onSelect }: Props) => {
  const showNavigation = resources.length > DEFAULT_COUNT;
  const visibleItems = showNavigation ? DEFAULT_COUNT - 1 : DEFAULT_COUNT;
  const [active, setActive] = React.useState(0);
  const [previews, setPreviews] = React.useState(
    resources.slice(0, visibleItems),
  );

  const goPrev = () => {
    let newActiveIndex = active - 1;

    if (newActiveIndex < 0) {
      newActiveIndex = resources.length - 1;
    }

    setActive(newActiveIndex);
  };

  const goNext = () => {
    let newActiveIndex = active + 1;

    if (newActiveIndex > resources.length - 1) {
      newActiveIndex = 0;
    }

    setActive(newActiveIndex);
  };

  React.useEffect(() => {
    setPreviews(
      [...resources].slice(active, active + visibleItems),
    );
    if (onChange) onChange(active);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, resources]);

  return (
    <Row align="middle" justify="center" gutter={10}>
      {showNavigation && (
        <Col flex={1}>
          <Row justify="start">
            <Button icon={<AngleLeftIcon />} onClick={goPrev} />
          </Row>
        </Col>
      )}

      {previews.map(({ src, type }, index) => (
        <Col key={index}>
          <Avatar
            shape="square"
            src={
              type === 'image' ? (
                <Image
                  src={src}
                  alt={src}
                  preview={false}
                  onClick={() => {
                    if (onSelect) onSelect((active + index) % resources.length);
                  }}
                />
              ) : (
                /* eslint-disable jsx-a11y/media-has-caption */
                <video
                  src={src}
                  controls={false}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  onClick={() => {
                    if (onSelect) onSelect((active + index) % resources.length);
                  }}
                />
              )
            }
            size={48}
            style={{ cursor: 'pointer' }}
            key={`gcr-portal-gallery-resource-${index}`}
          />
        </Col>
      ))}

      {showNavigation && (
        <Col flex={1}>
          <Row justify="end">
            <Button icon={<AngleRightIcon />} onClick={goNext} />
          </Row>
        </Col>
      )}
    </Row>
  );
};
