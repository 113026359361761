import * as React from 'react';
import cx from 'classnames';

import { Form, Select } from '@revfluence/fresh';
import { IShopifyFormElementProps, PrefixTypeStrategy } from '../../../types';

import styles from '../../../OfferForm.scss';

const { Option } = Select;

interface IProps extends IShopifyFormElementProps<'prefixType'> {
  halfRow: boolean;
}

export const OfferCodeOption: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  halfRow,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please select a unique prefix option!' },
  ];
  return (
    <Form.Item
      className={cx({
        [styles.halfRow]: halfRow,
      })}
      label="Naming Strategy"
      name={name}
      rules={rules}
    >
      <Select
        disabled={disabled}
        placeholder="Select"
        size="large"
      >
        <Option value={PrefixTypeStrategy.FIRST_INITIAL_LAST_NAME_ONLY}>
          Use First Initial + Last Name
        </Option>
        <Option value={PrefixTypeStrategy.FIRST_NAME_LAST_INITIAL_ONLY}>
          Use First Name + Last Initial
        </Option>
        <Option value={PrefixTypeStrategy.FULL_NAME_ONLY}>
          Use Full Name
        </Option>
        <Option value={PrefixTypeStrategy.INSTAGRAM_ONLY}>
          Use Instagram Username
        </Option>
      </Select>
    </Form.Item>
  );
});
