import * as React from 'react';
import cx from 'classnames';
import { isEmpty, size } from 'lodash';

import {
  ILicensedContent,
  getDownloadableMediaURLs,
  getFilenameFromUrl,
  ArrowLeftIcon,
  DownloadIcon,
  IconButton,
  Media,
  Overlay,
} from '@components';

import styles from './ContentOverlay.scss';

const { useState, useEffect } = React;

interface IProps {
  show: boolean;
  contents: ILicensedContent[];
  initialContentIndex: number;

  onRequestClose();
  onClickDownload(content: ILicensedContent);
}

const ContentOverlay: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    contents,
    initialContentIndex,
    onClickDownload,
    onRequestClose,
    show,
  } = props;

  const [contentIndex, setContentIndex] = useState<number>(initialContentIndex);

  useEffect(() => {
    if (show) {
      setContentIndex(initialContentIndex);
    } else {
      setTimeout(() => {
        setContentIndex(null);
      }, 400);
    }
  }, [show, initialContentIndex]);

  const currentContent = contents[contentIndex];

  const contentSize = size(contents);
  const handleNextPreview = () => setContentIndex((contentIndex + 1) % contentSize);
  const handlePreviousPreview = () =>
    setContentIndex(contentIndex - 1 < 0 ? contentSize - 1 : contentIndex - 1);

  const handleClickDownload = () => onClickDownload(currentContent);

  let fileName;
  if (currentContent) {
    const mediaUrls = getDownloadableMediaURLs(currentContent);
    if (!isEmpty(mediaUrls)) {
      fileName = getFilenameFromUrl(mediaUrls[0]);
    } else {
      console.log(`Empty dlMediaUrl for ${currentContent.id}`);
    }
  }

  return (
    <Overlay
      show={show}
      onRequestClose={onRequestClose}
      className={styles.ContentOverlay}
      closeOnBackdropClick
    >
      <div className={cx(styles.navigation, { [styles.hidden]: contentSize < 2 })}>
        <IconButton
          icon={<ArrowLeftIcon />}
          className={styles.leftArrow}
          onClick={handlePreviousPreview}
        />
        <IconButton
          icon={<ArrowLeftIcon />}
          className={styles.rightArrow}
          onClick={handleNextPreview}
        />
      </div>
      <Media content={currentContent} className={styles.content} />
      {fileName && (
        <>
          <div className={styles.fileName}>{fileName}</div>
          <div className={styles.download} onClick={handleClickDownload}>
            <DownloadIcon size={16} />
            <span>Download</span>
          </div>
        </>
      )}
    </Overlay>
  );
});

export default ContentOverlay;
