import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const BulletListIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip-bullet-list)">
      <path d="M3.91974 3.5666H2.88604C2.39807 3.5666 2 3.96468 2 4.45264C2 4.9406 2.39807 5.33868 2.88604 5.33868H3.91974C4.4077 5.33868 4.80578 4.9406 4.80578 4.45264C4.80578 3.96468 4.4077 3.5666 3.91974 3.5666Z" />
      <path d="M9.79454 5.33225H21.114C21.6019 5.33225 22 4.93418 22 4.44622C22 3.95826 21.6019 3.5666 21.114 3.5666H9.79454C9.30658 3.5666 8.90851 3.96468 8.90851 4.45264C8.90851 4.9406 9.30658 5.33225 9.79454 5.33225Z" />
      <path d="M3.91974 11.0851H2.88604C2.39807 11.0851 2 11.4831 2 11.9711C2 12.4591 2.39807 12.8571 2.88604 12.8571H3.91974C4.4077 12.8571 4.80578 12.4591 4.80578 11.9711C4.80578 11.4831 4.4077 11.0851 3.91974 11.0851Z" />
      <path d="M21.114 11.0851H9.79454C9.30658 11.0851 8.90851 11.4831 8.90851 11.9711C8.90851 12.4591 9.30658 12.8571 9.79454 12.8571H21.114C21.6019 12.8571 22 12.4591 22 11.9711C22 11.4831 21.6019 11.0851 21.114 11.0851Z" />
      <path d="M3.91974 18.6677H2.88604C2.39807 18.6677 2 19.0658 2 19.5538C2 20.0417 2.39807 20.4398 2.88604 20.4398H3.91974C4.4077 20.4398 4.80578 20.0417 4.80578 19.5538C4.80578 19.0658 4.4077 18.6677 3.91974 18.6677Z" />
      <path d="M21.114 18.6677H9.79454C9.30658 18.6677 8.90851 19.0658 8.90851 19.5538C8.90851 20.0417 9.30658 20.4398 9.79454 20.4398H21.114C21.6019 20.4398 22 20.0417 22 19.5538C22 19.0658 21.6019 18.6677 21.114 18.6677Z" />
    </g>
    <defs>
      <clipPath id="clip-bullet-list">
        <rect width="20" height="16.8668" fill="white" transform="translate(2 3.5666)" />
      </clipPath>
    </defs>
  </SvgIcon>
));

BulletListIcon.displayName = 'BulletListIcon';
