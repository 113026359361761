import gql from 'graphql-tag';
import { OFFER_FRAGMENT } from './fragments/OfferFragment';

export const MIGRATE_TO_MULTIPLE_PAYOUT = gql`
mutation MigrateToMultiplePayouts($id: Int!) {
    offer: migrateToMultiplePayout(id: $id) {
      ...OfferFragment
    }
  }
  ${OFFER_FRAGMENT}
`;
