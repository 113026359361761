import * as React from 'react';
import { Button } from '@revfluence/fresh';

interface IActivateButtonProps {
  activateAccount: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const ActivateButton = (props: IActivateButtonProps) => {
  const {
 activateAccount, className, loading, disabled,
} = props;

  return (
    <Button onClick={activateAccount} className={className} loading={loading} disabled={disabled} size="small">Activate</Button>
  );
};
