import * as React from 'react';
import cx from 'classnames';
import {
  filter as lodashFilter,
  find,
  first,
  isEmpty,
  isNil,
  map,
  sortBy,
  size,
} from 'lodash';
import { useMutation } from '@apollo/client';
import { THREAD_MARK_READ_MUTATION } from '@frontend/app/queries';
import { UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables } from '@frontend/app/queries/types/UpdateThreadToReadStatus';
import { useAuth } from '@frontend/context/authContext';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { ErrorNotice } from '@frontend/app/components';

import { message } from 'antd';

import {
  InfiniteList,
  LoadSpinner,
  Notice,
  OverlaySpinner,
} from '@components';
import { useResourceContext } from '@frontend/app/context';
import {
  useClientFeatureEnabled,
  useGetAllUsers,
  useInstagramDMFlag,
  useSyncMessages,
  useThreadMarkDoneAllMutation,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { MessageType, ResourceType } from '@frontend/app/types/globalTypes';
import { GetThreadsQuery_threads as IThread } from '@frontend/app/queries/types/GetThreadsQuery';
import { ASSIGNEE_TYPE } from '@frontend/app/types/MessageTypes';
import { messageThreadTypes, selectedThreadsVar, useMessagingContext } from './context/MessagingAppContext';

import { ThreadListItem } from './ThreadListItem';
import { TagCarousel } from './TagCarousel';

import styles from './ThreadList.scss';
import { YouThreadListHeader } from './ThreadListHeader/YouThreadListHeader';
import { SharedThreadListHeader } from './ThreadListHeader/SharedThreadListHeader';
import { IGDMThreadListHeader } from './ThreadListHeader/IGDMThreadListHeader';

interface IProps {
  className?: string;
}

const {
  useMemo,
  useState,
  useEffect,
  useCallback,
} = React;
/**
 * @type {React.FunctionComponent}
 */
export const ThreadList: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { user } = useAuth();
  const isAspireUser = user?.email ? user.email.split('@')[1] === 'aspireiq.com' : false;

  const {
    excludeApplicationIdsFilter,
    setExcludeApplicationIdsFilter,
    status,
    setStatus,
    assigneeType,
    currentFolderLabel,
    selectedThread,
    resetMessagingState,
    loadingThreads,
    userThreadsCount,
    threadCountsByResourceId,
    threads,
    loadThreadsError,
    loadMoreThreads,
    applications,
    filter,
    resourceId,
    refetchAndUpdateThreads,
    refetchThreadsCount,
    goToNextThread,
  } = useMessagingContext();
  const [markThreadAsRead] = useMutation<UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables>(THREAD_MARK_READ_MUTATION);

  const [isAppFilterOpen, setIsAppFilterOpen] = useState(false);
  const [segmentSwitched, setSegmentSwitched] = useState(false);
  const {
    messageResources: resources,
    refetchNotificationCount,
  } = useResourceContext();
  const { isInstagramDMEnabled } = useInstagramDMFlag();
  const readUnreadForAspireEmailFeatureFlag = useClientFeatureEnabled(ClientFeature.READ_UNREAD_FOR_ASPIRE_EMAIL);
  const showInstagramMeta = useClientFeatureEnabled(ClientFeature.SHOW_INSTAGRAM_META);
  const isInboxSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);
  const {
    users,
  } = useGetAllUsers();
  const selectedResource = useMemo(
    () => (resourceId
      ? find(resources, (resource) => resource.id === resourceId || resource.adminResourceId === resourceId)
      : null),
    [resourceId, resources],
  );
  const filterApps = useMemo(
    // apps for thread filter should have either showOnMember or showOnNav set to true.
    () => lodashFilter(
      sortBy(applications, ['name']),
      (a) => (a.visibility.showOnMember || a.visibility.showOnNav) && !messageThreadTypes.includes(a.name),
      ),
    [applications],
  );
  const [threadMarkDoneAll, {
    loading: markingAllAsDone,
  }] = useThreadMarkDoneAllMutation({
    onCompleted() {
      resetMessagingState();
    },
  });

  const count = useMemo(() => {
    if (assigneeType === ASSIGNEE_TYPE.YOU) {
      if (selectedResource) {
        return threadCountsByResourceId[selectedResource.id];
      }
      return userThreadsCount;
    } else if (assigneeType === ASSIGNEE_TYPE.ALL) {
      if (selectedResource) {
        return threadCountsByResourceId[selectedResource.id];
      }
      return null;
    } else if (assigneeType === ASSIGNEE_TYPE.SENT) {
      return null;
    } else if (assigneeType === ASSIGNEE_TYPE.APPNOTIFICATION) {
      return null;
    }

    return null;
  }, [
    assigneeType,
    selectedResource,
    userThreadsCount,
    threadCountsByResourceId,
  ]);

  const { syncMessages, loading: syncing } = useSyncMessages();

  const selectThread = useCallback(async (thread: IThread, _type?: IThread['type']) => {
    const threadId = thread.id;
    history.push({ ...location, pathname: `${match.path}/${threadId}` });

    const isCurrentThreadRead = thread?.readStatus?.isRead;
    if (!isCurrentThreadRead) {
      if (!isAspireUser || readUnreadForAspireEmailFeatureFlag) {
        await markThreadAsRead({ variables: { threadIds: [threadId] } });
      }
      refetchAndUpdateThreads(0, size(threads));
      refetchThreadsCount();
    }
  }, [markThreadAsRead, refetchAndUpdateThreads, refetchThreadsCount, history, location, match.path, threads, isAspireUser, readUnreadForAspireEmailFeatureFlag]);

  useEffect(() => {
    if (!isEmpty(threads) && (segmentSwitched || !selectedThread?.id)) {
      selectThread(first(threads));
      setSegmentSwitched(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads]);

  const filteredThread: IThread[] = useMemo(() => (
    (!isInstagramDMEnabled && !showInstagramMeta)
      ? lodashFilter(threads, (thread:IThread) => thread.type !== MessageType.IGDM)
      : threads
  ), [
    threads,
    showInstagramMeta,
    isInstagramDMEnabled,
  ]);

  const onRefreshMessages = () => {
    syncMessages({
      variables: {
        resourceId: undefined,
        count: 1000,
      },
    }).then(resetMessagingState).catch(() => {
      message.error('There\'s an error when trying to update the messages.');
    });
  };

  const handleClickMarkDoneAll = () => {
    threadMarkDoneAll({
      variables: {
        filter,
      },
    });
  };

  const onSelectAllThreadUpdate = (isSelectAllChecked: boolean) => {
    if (isSelectAllChecked) {
      // TODO RL Consider move this to utils along with onThreadItemSelectUpdate in ThreadListItem.tsx
      selectedThreadsVar(filteredThread.map((thread) => {
        const selectedThreadObjects: { id: string, label?: string, isRead?: boolean } = {
          id: thread.id,
        };
        if (!isEmpty(thread?.userLabels)) {
          selectedThreadObjects.label = thread.userLabels[0].label;
        }
        if (!isEmpty(thread?.readStatus?.isRead)) {
          selectedThreadObjects.isRead = thread?.readStatus?.isRead;
        }
        return selectedThreadObjects;
      }));
    } else {
      selectedThreadsVar([]);
    }
  };

  const handleReachedBottom = () => {
    if (loadingThreads || syncing) {
      return;
    }

    loadMoreThreads();
  };

  const removeApplicationFilterForId = (appId) => {
    setExcludeApplicationIdsFilter([...excludeApplicationIdsFilter, appId]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const title = useMemo(() => (selectedResource ? selectedResource.authProvider.userExternalDisplayId : currentFolderLabel), [selectedResource, assigneeType]);

  if (loadThreadsError) {
    return <ErrorNotice />;
  }
  const loadYouHeader = () => {
    if (selectedResource
      && selectedResource.type === ResourceType.IGDM) {
      return (
        <IGDMThreadListHeader
          title={title}
          count={count}
          syncing={syncing}
          loadingThreads={loadingThreads}
          onRefreshMessages={onRefreshMessages}
        />
      );
    }

    if (assigneeType !== ASSIGNEE_TYPE.YOU) return null;

    return (
      <YouThreadListHeader
        title={title}
        count={count}
        assigneeType={assigneeType}
        loadingThreads={loadingThreads}
        syncing={syncing}
        isAppFilterOpen={isAppFilterOpen}
        status={status}
        excludeApplicationIdsFilter={excludeApplicationIdsFilter}
        applications={applications}
        filterApps={filterApps}
        setExcludeApplicationIdsFilter={setExcludeApplicationIdsFilter}
        setStatus={setStatus}
        handleClickMarkDoneAll={handleClickMarkDoneAll}
        onSelectAllThreadUpdate={onSelectAllThreadUpdate}
        onRefreshMessages={onRefreshMessages}
        refetchThreadsCount={refetchThreadsCount}
        refetchNotificationCount={refetchNotificationCount}
        setIsAppFilterOpen={setIsAppFilterOpen}
        goToNextThread={goToNextThread}
      />
    );
  };

  return (
    <div className={cx(styles.ThreadList, props.className)}>
      {loadYouHeader()}
      {assigneeType !== ASSIGNEE_TYPE.YOU
        && (isNil(selectedResource) || selectedResource.type !== ResourceType.IGDM) && (
          <SharedThreadListHeader
            title={title}
            count={count}
            assigneeType={assigneeType}
            loadingThreads={loadingThreads}
            syncing={syncing}
            isAppFilterOpen={isAppFilterOpen}
            status={status}
            excludeApplicationIdsFilter={excludeApplicationIdsFilter}
            filterApps={filterApps}
            applications={applications}
            setExcludeApplicationIdsFilter={setExcludeApplicationIdsFilter}
            onRefreshMessages={onRefreshMessages}
            setIsAppFilterOpen={setIsAppFilterOpen}
            onSelectAllThreadUpdate={onSelectAllThreadUpdate}
            refetchThreadsCount={refetchThreadsCount}
            shouldShowActions={assigneeType !== ASSIGNEE_TYPE.APPNOTIFICATION}
          />
        )}
      {!isInboxSearchFilter && !isEmpty(excludeApplicationIdsFilter) && excludeApplicationIdsFilter.length !== applications.length && assigneeType === ASSIGNEE_TYPE.APPNOTIFICATION
        && (
          <TagCarousel
            selectedTagIds={excludeApplicationIdsFilter}
            tags={filterApps}
            closeTag={removeApplicationFilterForId}
            invertSelection={false}
          />
        )}
      {!loadingThreads && isEmpty(filteredThread) && (
        <Notice className={styles.notice} type="disabled" showDivider>
          You do not have any threads.
        </Notice>
      )}
      {!isEmpty(filteredThread) && (
        <InfiniteList
          className={styles.threadList}
          passVisibleInfo={false}
          onReachedBottom={handleReachedBottom}
        >
          {map(filteredThread, (thread) => (
            <ThreadListItem
              key={thread.id}
              userExternalDisplayId={selectedResource ? selectedResource.authProvider.userExternalDisplayId : ''}
              thread={thread}
              selectedThread={selectedThread}
              onSelectThread={selectThread.bind(null, thread)}
              shouldShowActions={assigneeType !== ASSIGNEE_TYPE.APPNOTIFICATION}
              users={users}
            />
          ))}
        </InfiniteList>
      )}
      {loadingThreads && (
        <LoadSpinner
          className={cx(styles.loadSpinner, {
            [styles.initialLoad]: isEmpty(filteredThread),
          })}
          centered={false}
        />
      )}
      {markingAllAsDone && (
        <OverlaySpinner />
      )}
    </div>
  );
});
