import { IMemberSearchQuery } from '@services/members';

import { ISelectedColumns } from './SelectedColumns';

export interface IPredefinedSegment {
  title: string;
  description?: string;
  id: string;
  folderId?: string;
  filter: IMemberSearchQuery;
  columns: ISelectedColumns;
  programId?: number;
  isDefaultColumn?: boolean;
}
