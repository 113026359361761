import * as React from 'react';
import cx from 'classnames';
import { isString } from 'lodash';

import { StepperButtons } from '@frontend/applications/Shared/Wizard/components/StepperButtons/StepperButtons';
import {
  Col,
  Divider,
  Row,
  Typography,
} from '@revfluence/fresh';

import styles from './WizardComponent.scss';

const { Title, Paragraph } = Typography;

interface instructions {
  title: string
  description: string
}
export interface WizardProps {
  actionComponents: React.ReactNode;
  nextButtonClassName: string;
  nextStepText: string;
  previousText: string;
  nextStepDisabled?: boolean;
  nextStepLoading?: boolean;
  previousButtonClassName: string;
  previousStepDisabled?: boolean;
  showNextStepIcon?: boolean;
  showPreviousStepIcon?: boolean;
  hideNavigation: boolean;
  instructions: instructions;
  icon: React.ReactNode;
  key: string;
  title: string;
  footerInfoComponent?: React.ReactNode;
  onNext: () => void;
  onPrevious: () => void;
  noHeader?: boolean;
  noBodyPadding?: boolean;
  oneStep: boolean;
  actionButtonClassName?: string;
  actionButtonText?: string;
  actionButtonDisabled?: boolean;
  actionButtonLoading?: boolean;
  onActionButton?: () => void;
}

export const Wizard: React.FC<Readonly<WizardProps>> = (props) => {
  let icon = props.icon;
  if (isString(props.icon)) {
    icon = <img src={props.icon} />;
  }

  const header = (
    <div>
      <Row className={styles.header} justify="center" align="middle" gutter={4}>
        <Col>
          <Title level={3}>
            {icon}
          </Title>
        </Col>
        <Col className={styles.title}>
          <Title level={4}>
            {props.title}
          </Title>
        </Col>
      </Row>
      <Divider />
    </div>
  );

  return (
    <div className={styles.Wizard}>
      <div key={props.key}>
        {!props.noHeader
          && header}
        <div className={cx(styles.bodyMarginBottom, props.noBodyPadding ? styles.bodyNoPadding : styles.body)}>
          <Row>
            <Col span={24}>
              <Title level={4}>{props.instructions?.title}</Title>
            </Col>
            <Col span={24}>
              <Paragraph>{props.instructions?.description}</Paragraph>
            </Col>
          </Row>
          {' '}
          {/* Caption */}
          <Row gutter={[10, 10]}>
            <Col span={24}>
              {props.actionComponents}
            </Col>
          </Row>
        </div>
        <div>
          {!props.hideNavigation && (
            <Row className={styles.footer}>
              <Col span={24}>
                <StepperButtons
                  oneStep={props.oneStep}
                  nextButtonClassName={props.nextButtonClassName}
                  nextStepText={props.nextStepText}
                  nextStepDisabled={props.nextStepDisabled}
                  nextStepLoading={props.nextStepLoading}
                  showNextStepIcon={props.showNextStepIcon}
                  onNext={props.onNext}
                  previousButtonClassName={props.previousButtonClassName}
                  previousStepDisabled={props.previousStepDisabled}
                  previousText={props.previousText}
                  showPreviousIcon={props.showPreviousStepIcon}
                  onPrevious={props.onPrevious}
                  actionButtonClassName={props.actionButtonClassName}
                  actionButtonText={props.actionButtonText}
                  actionButtonDisabled={props.actionButtonDisabled}
                  actionButtonLoading={props.actionButtonLoading}
                  onActionButton={props.onActionButton}
                />
              </Col>
              <Col span={24}>
                {props.footerInfoComponent}
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );
};

Wizard.defaultProps = {
  nextStepDisabled: false,
  nextStepLoading: false,
  previousStepDisabled: false,
  showNextStepIcon: true,
  showPreviousStepIcon: true,
};
