import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { IColumnVisibility } from '@frontend/app/types/Columns';

const SERVER_PAGE_SIZE = 200;

export const useMemberSearchQueryVariables = (searchQuery: IMemberSearchQuery, columnVisibility: IColumnVisibility, size?: number) => ({
    query: searchQuery,
    take: size || SERVER_PAGE_SIZE,
    skip: 0,
    includeActivations: columnVisibility && !!columnVisibility.activations,
    includeTags: columnVisibility && !!columnVisibility.tags,
    includeHighlights: columnVisibility && !!columnVisibility.highlights,
    includeCommunities: columnVisibility && !!columnVisibility.communities,
    includeOwners: columnVisibility && !!columnVisibility.owners,
    includePrograms: columnVisibility && !!(
      columnVisibility.programs
      || columnVisibility.invitedPrograms
      || columnVisibility.submittedPrograms
      || columnVisibility.rejectedPrograms
    ),
    includeProgramMemberships: columnVisibility
      && (!!columnVisibility.programMemberships || !!columnVisibility.applicantSource),
    includeProgramMembershipsLogs: columnVisibility && (!!columnVisibility.appliedDate || !!columnVisibility.rejectedDate),
  });
