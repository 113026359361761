import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_VARIANTS_BY_IDS_QUERY } from '../queries';
import {
  GetVariantsByIds,
  GetVariantsByIdsVariables,
  GetVariantsByIds_variants,
} from '../queries/types/GetVariantsByIds';

type IOptions = QueryHookOptions<GetVariantsByIds, GetVariantsByIdsVariables>;
export type IVariant = GetVariantsByIds_variants;

export function useGetVariantsByIds(options: IOptions = {}) {
  const {
 loading, data, error, refetch,
} = useQuery<GetVariantsByIds, GetVariantsByIdsVariables>(
    GET_VARIANTS_BY_IDS_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    variants: data?.variants ?? [],
    error,
    refetch,
  };
}
