import * as React from 'react';
import cx from 'classnames';
import { Menu, Dropdown } from 'antd';

import {
  Button, SpinnerIcon,
} from '@components';

import { EventName } from '@common';
import { useCSVUploadContext } from '@frontend/app/containers/Members/hooks';
import { useEventContext } from '@frontend/app/context/EventContext';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import { ArrowDownToLine } from 'lucide-react';
import HeaderButton from '@frontend/app/refresh-components/HeaderButton';
import {
  useExportToCSV,
  useColumnsInput,
} from '../../hooks';

import styles from './CsvDropdown.scss';

const { useCallback, useMemo } = React;

interface IProps {
  disabled?: boolean;
  className?: string;
  memberCount?: number;
  segmentType?: string;
  selectedMemberIds?: number[];
  refreshUi?: boolean;
}

export const CsvDropdown: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    columnVisibility,
    searchQuery,
    segment,
  } = useMemberListContext();

  const {
    showCSVUpload,
    onClickUpload,
  } = useCSVUploadContext();

  const columnsInput = useColumnsInput(columnVisibility, segment?.columns?.order);

  const {
    exportToCSV,
    loading: exportingToCSV,
  } = useExportToCSV(searchQuery, columnsInput, props.selectedMemberIds);

  const addEvent = useEventContext();

  const handleClickDownload = useCallback(() => {
    exportToCSV();
    addEvent(EventName.ExportMembers, {
      member_count: props.memberCount,
      segment_type: props.segmentType,
    });
  }, [exportToCSV, addEvent, props.memberCount, props.segmentType]);

  const uploadButton = useMemo(() => {
    if (showCSVUpload) {
      return (
        <span className={styles.menuText}>Upload</span>
      );
    }
  }, [showCSVUpload]);

  const menu = useMemo(() => {
    const disabledDownload = props.memberCount <= 0;
    return (
      <Menu>
        {uploadButton && (
          <Menu.Item onClick={onClickUpload} className={styles.menuItem}>
            {uploadButton}
          </Menu.Item>
        )}
        <Menu.Item
          onClick={handleClickDownload}
          className={cx(styles.menuItem, { [styles.disabled]: disabledDownload })}
          disabled={disabledDownload}
        >
          <span className={styles.menuText}>Download</span>
        </Menu.Item>
      </Menu>
    );
  }, [handleClickDownload, onClickUpload, props.memberCount, uploadButton]);

  const disabled = props.disabled || exportingToCSV;

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={disabled} placement="bottomRight">
      {props.refreshUi ? (
        <HeaderButton
          disabled={disabled}
          icon={exportingToCSV ? (<SpinnerIcon size={18} />) : (<ArrowDownToLine className="h-4 w-4" />)}
        />
      ) : (
        <Button
          theme="info"
          disabled={disabled}
          label="File"
          icon={exportingToCSV ? <SpinnerIcon size={18} /> : null}
          className={cx(styles.CsvDropdown, props.className)}
          round={false}
        />
      )}
    </Dropdown>
  );
});

CsvDropdown.defaultProps = {
  disabled: false,
};

CsvDropdown.displayName = 'CsvDropdown';
