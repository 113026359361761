import {
  filter,
  findIndex,
  get,
  has,
  isEmpty,
  isNil,
  isUndefined,
  last,
} from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom';

import {
  Layout,
  Modal,
} from '@revfluence/fresh';
import { logger } from '@common';
import {
  useProjectsApp,
  useOverviewPage,
} from '@frontend/app/containers/Projects/hooks';
import { GetAllTasksQuery_tasks as ITask } from '@frontend/app/queries/types/GetAllTasksQuery';
import { MemberListContextProvider } from '@frontend/app/context/MemberListContext';
import { AppHeader } from '@frontend/app/refresh-components/AppHeader';

import { format } from 'date-fns';
import { useMessagingContext } from '@frontend/hooks';
import { useClientFeatureEnabled, useGetCampaignByProjectId } from '@frontend/app/hooks';

import { CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { ClientFeature } from '@frontend/app/constants';
import { getWorkletNameBySpecURI } from '@frontend/app/utils/worklet';
import ContentWithSideMenu from '@/shadcn/layouts/ContentWithSideMenu/ContentWithSideMenu';

import {
  ProjectsPageState,
  ProjectsRouteRoot,
} from '../constants';

import {
  ProjectStatus,
  ProjectMarketplaceStatus,
} from '../OverviewPage/Header/constants';

import { Sidebar } from './Sidebar/Sidebar';
import { MarketplaceDrawer } from './ListOnMarketplacePage/MarketplaceDrawer';
import { ProjectsPageContent } from './ProjectsPageContent';
import { ListOnMarketplaceProvider } from './ListOnMarketplacePage/context';

import styles from './ProjectsPage.scss';

import Header from '../OverviewPage/Header';
import { formatProjectURL, isApplicationPageEditable } from '../utils';
import QuickLinks from '../OverviewPage/Header/components/QuickLinks';
import Status from '../OverviewPage/Header/components/Status';

const {
  useCallback,
  useEffect,
  useMemo,
  useState,
} = React;

export interface IProjectsPageProps {
  pageState?: ProjectsPageState;
  routeProps?: RouteComponentProps;
  shouldRedirect?: boolean;
}

export type THandleTaskSelected = (workletSpecUri: ITask['workletSpecUri'], taskId: ITask['taskId']) => void;

export interface IProjectsRouteMatch {
  applicationId?: string;
  workletSpecUri?: string;
  projectId?: string;
  taskId?: string;
}

export const ProjectsPage: React.FC<IProjectsPageProps> = React.memo(({
  pageState,
  routeProps: {
    history,
    location,
    match,
  },
  shouldRedirect,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const isNewWorkletMenuEnabled = useClientFeatureEnabled(ClientFeature.WORKET_MENU);
  const [shouldShowArchiveModal, toggleShowArchiveModal] = useState(true);
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  /**
   * Route
   */
  const {
    params: {
      applicationId = undefined,
      workletSpecUri: routeWorkletSpecUri = undefined,
      projectId: routeProjectId = undefined,
      taskId: routeTaskId = undefined,
    } = {},
  } = useRouteMatch<IProjectsRouteMatch>(match);

  /**
   * Page state
   */
  const {
    isProspectsView,
    isWorkItemsView,
    isOverviewPage,
    isFindCreatorsPage,
    isGroupContentReviewPage,
  } = useMemo(() => ({
    isProspectsView: (
      pageState === ProjectsPageState.Applicants
      || pageState === ProjectsPageState.Invited
      || pageState === ProjectsPageState.Rejected
    ),
    isWorkItemsView: (
      pageState === ProjectsPageState.AllInProgress
      || pageState === ProjectsPageState.Task
      || pageState === ProjectsPageState.Completed
      || pageState === ProjectsPageState.App
      || pageState === ProjectsPageState.Worklet
    ),
    isOverviewPage: (
      pageState === ProjectsPageState.Overview
    ),
    isFindCreatorsPage: (
      pageState === ProjectsPageState.FindCreators
    ),
    isGroupContentReviewPage: (
      pageState === ProjectsPageState.GroupContentReview
    ),
  }), [pageState]);

  /**
   * Projects App State
   */
  const {
    counts,
    getTaskBySpecUriAndId,
    isLoading,
    project,
    projects,
    refetchCounts,
    setProjectId,
    setWorkletSpecUri,
    setTaskBySpecUriAndId,
    task,
    tasks,
    worklets,
    presetConditions,
    getTasksNeedAttention,
  } = useProjectsApp({
    defaultProjectId: parseInt(routeProjectId, 10) || undefined,
    defaultWorkletSpecUri: routeWorkletSpecUri || undefined,
    defaultTaskId: routeTaskId || undefined,
    fetchWorkItems: isWorkItemsView,
  });

  const {
    handleReactivateCampaign,
    handleStartMarketplace,
  } = useOverviewPage(project);

  useEffect(() => {
    setProjectId(
      routeProjectId
        ? parseInt(routeProjectId, 10)
        : undefined,
    );
  }, [
    routeProjectId,
    setProjectId,
  ]);

  useEffect(() => {
    setWorkletSpecUri(routeWorkletSpecUri || undefined);
  }, [
    setWorkletSpecUri,
    routeWorkletSpecUri,
  ]);

  useEffect(() => {
    setTaskBySpecUriAndId(routeWorkletSpecUri || undefined, routeTaskId || undefined);
  }, [
    setTaskBySpecUriAndId,
    routeWorkletSpecUri,
    routeTaskId,
  ]);

  const allInProgressCount = useMemo(() => get(counts, 'all_in_progress', 0) as number, [counts]);
  const applicantsCount = useMemo(() => get(counts, 'invited', 0) as number, [counts]);

  const shouldRedirectToFindCreators = useMemo(() => {
    if (pageState === ProjectsPageState.FindCreators) {
      return true;
    }

    return (
      project?.id
      && !allInProgressCount
      && !applicantsCount
    );
  }, [
    project?.id,
    allInProgressCount,
    applicantsCount,
    pageState,
  ]);

  const shouldRedirectToApplicants = useMemo(() => {
    if (pageState === ProjectsPageState.Applicants) {
      return true;
    }

    if (!project?.id) {
      return false;
    }

    const isTasksEmpty = (isWorkItemsView && isEmpty(tasks))
      || (pageState === ProjectsPageState.Task && !getTaskBySpecUriAndId(routeWorkletSpecUri, routeTaskId));

    const hasNoTasksAvailable = !isUndefined(tasks) && isTasksEmpty;

    return hasNoTasksAvailable
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: Fix in Node upgrade typing bash!
      || (!allInProgressCount && applicantsCount > 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project?.id,
    allInProgressCount,
    applicantsCount,
    tasks,
    pageState,
  ]);

  const {
    showError,
  } = useMessagingContext();

  const {
    data: {
      campaign = null,
    } = {},
  } = useGetCampaignByProjectId(project?.id, {
    onError: (error) => {
      showError(error);
    },
    skip: !project?.id,
  });

  const status = useMemo(() => {
    if (project?.status === ProjectStatus.Active) {
      if (!isApplicationPageEditable(project)) {
        return ProjectMarketplaceStatus.AccomplishApplicationPage;
      }
      if (isNil(campaign?.enabled_ts) && isNil(campaign?.disabled_ts)) {
        return ProjectMarketplaceStatus.PublishListingToCreatorMarketplace;
      }
      if (campaign?.enabled_ts) {
        return ProjectMarketplaceStatus.ListingPublishedToCreatorMarketplace;
      }
      if (campaign?.disabled_ts) {
        if (campaign?.auto_unlisted) {
          return ProjectMarketplaceStatus.AutoUnlistedFromCreatorMarketplace;
        } else {
          return ProjectMarketplaceStatus.UserUnlistedFromCreatorMarketplace;
        }
      }
    }
    if (project?.status === ProjectStatus.Archived && isArchiveProjectEnabled) {
      return ProjectStatus.Archived;
    }
    return undefined;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project,
    campaign,
  ]);

  const campaignUnlistDate = useMemo(() => {
    if (campaign?.disabled_ts) {
      return format(
        new Date(Math.floor(campaign.disabled_ts * 1000)),
        ' MM/dd/yyyy.',
      );
    }
  }, [campaign]);

  const activeProjects = useMemo(() => {
    if (isArchiveProjectEnabled) {
      return filter(projects, (project) => project.status === ProjectStatus.Active);
    }
    return [...projects];
  }, [
    projects,
    isArchiveProjectEnabled,
  ]);

  const url = useMemo(() => formatProjectURL(
    ProjectsRouteRoot,
    project,
    campaign,
  ), [
    project,
    campaign,
  ]);

  const ctaHandler = useCallback(() => {
    if (status === ProjectMarketplaceStatus.PublishListingToCreatorMarketplace) {
      return handleStartMarketplace();
    }
    if ([
      ProjectMarketplaceStatus.AutoUnlistedFromCreatorMarketplace,
      ProjectMarketplaceStatus.UserUnlistedFromCreatorMarketplace,
    ].includes(status as ProjectMarketplaceStatus)) {
      return handleReactivateCampaign();
    }
  }, [status, handleStartMarketplace, handleReactivateCampaign]);

  const renderProjectHeader = useMemo(() => (
    project && (
      <Header
        campaignName={project?.title}
        campaignUnlistDate={campaignUnlistDate}
        handleCTAClick={ctaHandler}
        projectId={project?.id}
        url={url}
        status={status}
        projectImage={project.splashImageUrl}
        project={project}
      />
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [
    project,
    campaign,
    url,
  ]);

  /*
   * Handle automatic redirection if user goes to abstract state
   */
  useEffect(() => {
    if (urlParams.has('disableRedirect') || !shouldRedirect) return;

    if (isLoading) {
      return;
    }

    // Needed because this effect can trigger before the effect to refetch project/applicants/work items
    if (routeProjectId && project?.id !== parseInt(routeProjectId, 10)) {
      logger.debug('Incorrect project loaded, do not attempt to redirect');
      return;
    }

    if (isEmpty(projects)) {
      // No projects, redirect to templates page
      logger.debug('No projects found');
      history.replace({
        ...location,
        pathname: `${ProjectsRouteRoot}/new/templates`,
      });
    } else if (
      !(routeProjectId || project?.id)
      || findIndex(projects, (p) => p.id === parseInt(routeProjectId, 10)) === -1
    ) {
      const lastProject = last(projects);
      setProjectId(lastProject.id);
      logger.debug(`Project ID from route (${routeProjectId}) is invalid. Redirecting to latest project: ${lastProject.id}`);
      history.replace({
        ...location,
        pathname: `${ProjectsRouteRoot}/${lastProject.id}/overview`,
      });
    } else if (shouldRedirectToApplicants) {
      if (pageState !== ProjectsPageState.Applicants) {
        // No tasks, redirect to applicants page
        logger.debug('No tasks or no members In Progress. Redirecting to applicants');
        history.replace({
          ...location,
          pathname: `${ProjectsRouteRoot}/${routeProjectId || project?.id}/overview`,
        });
      }
    } else if (shouldRedirectToFindCreators) {
      if (pageState !== ProjectsPageState.FindCreators) {
        logger.debug('No members in progress and no applicants. Still redirecting to find_creators');
        history.replace({
          ...location,
          pathname: `${ProjectsRouteRoot}/${routeProjectId || project?.id}/overview`,
        });
      }
    } else {
      history.replace({
        ...location,
        pathname: `${ProjectsRouteRoot}/${routeProjectId || project?.id}/overview`,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    isWorkItemsView,
    pageState,
    project,
    projects,
    routeProjectId,
    routeWorkletSpecUri,
    routeTaskId,
    tasks,
    shouldRedirectToFindCreators,
    shouldRedirectToApplicants,
    shouldRedirect,
  ]);

  useEffect(() => {
    const isFromSettingsPage = has(location.state, 'shouldNotShowArchiveModal');
    const hasArchiveDetails = !isNil(project?.archivedByUser) && !isNil(project?.archivedDate);

    if (project?.status === ProjectStatus.Archived
      && isArchiveProjectEnabled
      && !isFromSettingsPage
      && shouldShowArchiveModal
      && hasArchiveDetails
    ) {
      Modal.warning({
        title: 'Project Archived',
        content: `This project was archived on ${moment(project?.archivedDate).format('MM/DD/YY')} by ${project?.archivedByUser?.name}. All project insights are still available but you can no longer add new members.`,
        icon: <CircleExclamationIcon />,
        okText: 'Continue',
      });
      toggleShowArchiveModal(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project,
    toggleShowArchiveModal,
    shouldShowArchiveModal,
    isArchiveProjectEnabled,
  ]);

  const currentStageTitle = useMemo(() => {
    if (isWorkItemsView) {
      switch (pageState) {
        case ProjectsPageState.AllInProgress:
          return 'All In Progress';
        case ProjectsPageState.Completed:
          return 'Completed';
        case ProjectsPageState.Worklet:
          return getWorkletNameBySpecURI(routeWorkletSpecUri, worklets);
        case ProjectsPageState.Task:
          if (isNewWorkletMenuEnabled) {
            return getWorkletNameBySpecURI(routeWorkletSpecUri, worklets);
          }
          return task?.taskName;
      }
    }

    return pageState;
  }, [isNewWorkletMenuEnabled, pageState, task?.taskName, routeWorkletSpecUri, worklets, isWorkItemsView]);

  /**
   * Event handlers
   */
  const handleTaskSelected: THandleTaskSelected = (workletSpecUri, taskId) => {
    let path = `${ProjectsRouteRoot}/${project.id}/all_in_progress`;
    if (!isNil(workletSpecUri) && isNil(taskId)) {
      path = `${ProjectsRouteRoot}/${project.id}/${workletSpecUri}/worklet`;
    } else if (!isNil(workletSpecUri) && !isNil(taskId)) {
      const task = getTaskBySpecUriAndId(workletSpecUri, taskId);
      if (!isEmpty(task)) {
        path = `${ProjectsRouteRoot}/${project.id}/${task.workletSpecUri}/${task.taskId}`;
      }
    }

    history.push({
      ...location,
      pathname: path,
      search: undefined,
    });

    setTaskBySpecUriAndId(workletSpecUri, taskId);
  };

  if (isRefreshUIEnabled) {
    return (
      <MemberListContextProvider programId={project?.id}>
        <AppHeader
          title={project?.title}
          subTitle={currentStageTitle}
          actions={[
            <Status status={status} url={url} handleCTAClick={ctaHandler} key="status" />,
            <QuickLinks projectId={project?.id} key="quick-links" project={project} />,
          ]}
          imgIconUrl={project?.splashImageUrl}
        />
        <ContentWithSideMenu
          menu={(
            <Sidebar
              className={styles.sidebar}
              counts={counts}
              isLoading={isLoading}
              onTaskSelected={handleTaskSelected}
              pageState={pageState}
              project={project}
              task={task}
              tasks={tasks}
              workletSpecUri={routeWorkletSpecUri}
              worklets={worklets}
            />
          )}
          main={(
            <>
              <ProjectsPageContent
                applicationId={applicationId}
                counts={counts}
                isLoading={isLoading}
                isProspectsView={isProspectsView}
                isWorkItemsView={isWorkItemsView}
                isOverviewPage={isOverviewPage}
                isFindCreatorsPage={isFindCreatorsPage}
                isGroupContentReviewPage={isGroupContentReviewPage}
                presetConditions={presetConditions}
                pageState={pageState}
                project={project}
                projects={activeProjects}
                refetchCounts={refetchCounts}
                getTaskBySpecUriAndId={getTaskBySpecUriAndId}
                task={task}
                getTasksNeedAttention={getTasksNeedAttention}
                onTaskSelected={handleTaskSelected}
                worklets={worklets}
                tasks={tasks}
                workletSpecUri={routeWorkletSpecUri}
              />
              <ListOnMarketplaceProvider openFrom={location}>
                <MarketplaceDrawer />
              </ListOnMarketplaceProvider>
            </>
          )}
        />
      </MemberListContextProvider>
    );
  }

  return (
    <MemberListContextProvider programId={project?.id}>
      {renderProjectHeader}
      <Layout
        className={styles.ProjectsPage}
        hasSider
      >
        <Layout.Sider
          className={styles.sider}
          theme="light"
          width={240}
        >
          <Sidebar
            className={styles.sidebar}
            counts={counts}
            isLoading={isLoading}
            onTaskSelected={handleTaskSelected}
            pageState={pageState}
            project={project}
            task={task}
            tasks={tasks}
            workletSpecUri={routeWorkletSpecUri}
            worklets={worklets}
          />
        </Layout.Sider>
        <Layout.Content className={styles.content}>
          <ProjectsPageContent
            applicationId={applicationId}
            counts={counts}
            isLoading={isLoading}
            isProspectsView={isProspectsView}
            isWorkItemsView={isWorkItemsView}
            isOverviewPage={isOverviewPage}
            isFindCreatorsPage={isFindCreatorsPage}
            isGroupContentReviewPage={isGroupContentReviewPage}
            presetConditions={presetConditions}
            pageState={pageState}
            project={project}
            projects={activeProjects}
            refetchCounts={refetchCounts}
            getTaskBySpecUriAndId={getTaskBySpecUriAndId}
            task={task}
            getTasksNeedAttention={getTasksNeedAttention}
            onTaskSelected={handleTaskSelected}
            worklets={worklets}
            tasks={tasks}
            workletSpecUri={routeWorkletSpecUri}
          />
          <ListOnMarketplaceProvider openFrom={location}>
            <MarketplaceDrawer />
          </ListOnMarketplaceProvider>
        </Layout.Content>
      </Layout>
    </MemberListContextProvider>
  );
});
