import * as React from 'react';
import cn from 'classnames';
import {
  BarChart, Bar, Rectangle, TooltipProps,
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@frontend/shadcn/components/ui/tooltip';

import { MetricChartProps } from './MetricChart.types';
import styles from './MetricChart.module.scss';

const BarTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length > 0) {
    return (
      <TooltipContent>
        {payload[0].payload.tooltipTitle && (
          <div className={styles.tooltipTitle}>
            {payload[0].payload.tooltipTitle}
          </div>
        )}
        {payload[0].payload.tooltipDescription && (
          <p className={styles.tooltipDescription}>
            {payload[0].payload.tooltipDescription}
          </p>
        )}
      </TooltipContent>
    );
  }
  return null;
};

const _defaultChart = [{ value: 1 }, { value: 1 }, { value: 1 }, { value: 1 }];

const CustomBar = (props) => {
  const {
    x, y, width, height, payload, index, onMouseEnter, onMouseLeave,
  } = props;

  return (
    <g
      onMouseEnter={(e) => onMouseEnter(payload, index, e)}
      onMouseLeave={onMouseLeave}
    >
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill="inherit"
        className="recharts-bar-rectangle"
      />
    </g>
  );
};

const RefreshMetricChart = ({ color = 'blue', chart = _defaultChart }: MetricChartProps) => {
  const [tooltipProps, setTooltipProps] = React.useState<TooltipProps<ValueType, NameType> | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMouseEnter = (data: any, e: React.MouseEvent) => {
    setTooltipProps({
      active: true,
      payload: [{ payload: data }],
      coordinate: { x: e.clientX, y: e.clientY },
    });
  };

  const handleMouseLeave = () => {
    setTooltipProps(null);
  };

  return (
    <TooltipProvider>
      <Tooltip open={!!tooltipProps}>
        <TooltipTrigger asChild>
          <div className={cn(styles[color])}>
            <BarChart
              width={16 * chart.length}
              height={40}
              data={chart}
              barGap={1}
              barCategoryGap={1}
              margin={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <Bar
                dataKey="value"
                barSize={100}
                minPointSize={1}
                shape={<CustomBar onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />}
              />
            </BarChart>
          </div>
        </TooltipTrigger>
        {tooltipProps && <BarTooltip {...tooltipProps} />}
      </Tooltip>
    </TooltipProvider>
  );
};

export default RefreshMetricChart;
