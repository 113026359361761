._ApplicationContent_16a8l_1 {
  position: relative;
  background-color: #d3d3d3;
}
._ApplicationContent_16a8l_1 ._fileIconWrapper_16a8l_5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}