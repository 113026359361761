/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14omm_157 {
  display: flex;
}

._justify-content-space-between_14omm_161 {
  justify-content: space-between;
}

._tabular-nums_14omm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_14omm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._PayoutDetails_14omm_209 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
._PayoutDetails_14omm_209 ._addCardFormModal_14omm_214 {
  padding: 2.25rem;
}
._PayoutDetails_14omm_209 ._details_14omm_217 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._PayoutDetails_14omm_209 ._details_14omm_217 ._breakdown_14omm_222 {
  width: 47.5%;
}
._PayoutDetails_14omm_209 ._details_14omm_217 ._cardSelector_14omm_225 {
  width: 47.5%;
}
._PayoutDetails_14omm_209 ._alertIcon_14omm_228 {
  color: #1890ff;
  padding-right: 0.625rem;
  font-size: 1.25rem;
}
._PayoutDetails_14omm_209 ._Alert_14omm_233 {
  margin: 1rem 0 1rem 0;
}
._PayoutDetails_14omm_209 ._Alert_14omm_233 ._alertBox_14omm_236 {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.375rem;
  align-items: center;
  border: 0.0625rem solid #91d5ff;
  background-color: #e6f7ff;
  border-style: solid;
  height: 3.5rem;
}
._PayoutDetails_14omm_209 ._Alert_14omm_233 p {
  text-align: center;
  vertical-align: middle;
}

._addCardFormModalDialog_14omm_252 {
  padding: 2rem;
}