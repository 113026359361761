import React from 'react';
import classNames from 'classnames';

interface TextProps {
  visualVariant: 'h1' | 'h2' | 'h3' | 'h4' | 'paragraph' | 'caption' | 'label' | 'link' | 'button' | 'menu';
  className?: string; // Accepts custom classes like `text-white`
  children: React.ReactNode;
}

const getVariantClasses = (variant: TextProps['visualVariant']) => {
  switch (variant) {
    case 'h1':
      return 'text-xl leading-xxl-loose font-medium';
    case 'h2':
      return 'text-xl leading-relaxed font-regular';
    case 'h3':
      return 'text-lg font-normal leading-relaxed';
    case 'h4':
      return 'text-base font-medium leading-relaxed';
    case 'paragraph':
      return 'text-sm font-regular leading-normal';
    case 'caption':
      return 'text-xs leading-tight font-regular';
    case 'label':
      return 'text-xs leading-tight font-medium';
    case 'link':
      return 'text-sm leading-normal font-regular underline';
    case 'button':
      return 'text-sm leading-normal font-medium';
    case 'menu':
      return 'text-base leading-tight font-medium';
    default:
      return '';
  }
};

const Text: React.FC<TextProps> = ({ visualVariant, className, children }) => {
  const variantClasses = getVariantClasses(visualVariant);

  return (
    <span className={classNames(variantClasses, className)}>
      {children}
    </span>
  );
};

export default Text;
