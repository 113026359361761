import * as React from 'react';
import { isEmpty } from 'lodash';

import { logger } from '@common';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';

const { useEffect, useMemo, useState } = React;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const request = async (url): Promise<any> => {
  try {
    const resp = await fetch(url);
    const json = await resp.json();
    if (json?.status?.code === 200 && json?.data) {
      return json.data;
    }
    throw json?.status?.error_msg;
  } catch (err) {
    logger.error({ message: err });
    throw err;
  }
};

export const useGetOauthLink = (username: string, clientId: string) => {
  const [oauthLink, setOauthLink] = useState<string>();
  const [hasAccount, setHasAccount] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const url = useMemo(
    () => (clientId
      ? `${backendServerApiEndpoint()}/ig_oauth/get_oauth_link?client_id=${clientId}&username=${username}`
      : undefined),
    [clientId, username],
  );

  const fetch = () => {
    if (url) {
      setIsLoading(true);
      request(url)
        .then((result) => {
          if (!isEmpty(result)) {
            const { has_account, url } = result;
            setHasAccount(has_account);
            setOauthLink(url);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(fetch, [url]);

  return {
    oauthLink,
    hasAccount,
    isLoading,
    error,
  };
};
