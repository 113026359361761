import { reduce } from 'lodash';

import { TProgram } from '@affiliates/components/MembersWizard/types';
import { TState } from '../types';

interface IAction {
  programs: readonly TProgram[];
  type: 'SELECT PROGRAMS';
}
export type TSelectProgramsAction = Readonly<IAction>;

export const selectPrograms = (state: TState, action: TSelectProgramsAction): TState => {
  const selectedPrograms: TState['selectedPrograms'] = reduce(
    action.programs,
    (prev: TState['selectedPrograms'], next: TProgram): TState['selectedPrograms'] => {
      prev[next.id.toString()] = next;
      return prev;
    },
    {},
  );
  return {
    ...state,
    members: null,
    selectedPrograms,
  };
};
