import {
  SearchType,
  FetchFrom,
} from '../models';

// create in-memory cache for pagination
const searchResultsPageCache = {
  [SearchType.TextSearch]: new Map<number, unknown>(),
  [SearchType.ImageSearch]: new Map<number, unknown>(),
  [SearchType.Featured]: new Map<number, unknown>(),
};

const clear = () => {
  searchResultsPageCache[SearchType.TextSearch] = new Map<number, unknown>();
  searchResultsPageCache[SearchType.ImageSearch] = new Map<number, unknown>();
  searchResultsPageCache[SearchType.Featured] = new Map<number, unknown>();
};

const has = (page: number, searchType: SearchType, fetchFrom: FetchFrom) => (
  fetchFrom === FetchFrom.Pagination && searchResultsPageCache[searchType].has(page)
);

const add = (page: number, searchType: SearchType, fetchFrom: FetchFrom, data: unknown) => {
  if (fetchFrom === FetchFrom.Pagination || (fetchFrom === FetchFrom.ApplyFilters && page === 0)) {
    searchResultsPageCache[searchType].set(page, data);
  }
};

const get = (page: number, searchType: SearchType) => (
  searchResultsPageCache[searchType].get(page)
);

export const searchResultPageCache = {
  clear,
  has,
  add,
  get,
};
