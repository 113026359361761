import gql from 'graphql-tag';

export const GET_PRODUCT_COLLECTIONS = gql`
query GetAdvertiserProductCollections {
  advertiser {
    shopifyResources{
      collections {
        id
        title
      }
    }
  }
}
`;
