import 'css-chunk:src/components/common/LeaveCommentSection.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "LeaveCommentSection": "_LeaveCommentSection_1lci0_32",
  "editMode": "_editMode_1lci0_32",
  "input": "_input_1lci0_37",
  "submitButton": "_submitButton_1lci0_40",
  "cancelButton": "_cancelButton_1lci0_43",
  "displayMode": "_displayMode_1lci0_46",
  "messageIcon": "_messageIcon_1lci0_55",
  "feedback": "_feedback_1lci0_59",
  "feedbackTitle": "_feedbackTitle_1lci0_66",
  "actions": "_actions_1lci0_70",
  "icon": "_icon_1lci0_78"
};