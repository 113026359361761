/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13v86_157 {
  display: flex;
}

._justify-content-space-between_13v86_161 {
  justify-content: space-between;
}

._tabular-nums_13v86_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13v86_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AddMember_13v86_178 {
  position: relative;
}
._AddMember_13v86_178 ._content_13v86_181 {
  overflow-y: scroll;
  height: 100%;
  padding: 1.875rem 0.9375rem 5rem;
}
._AddMember_13v86_178._disabled_13v86_186 ._content_13v86_181 {
  opacity: 0.8;
  pointer-events: none;
}
._AddMember_13v86_178 ._saveContainer_13v86_190 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem;
  background: #fdfdfd;
}
._AddMember_13v86_178 ._footer_13v86_198 {
  background: #fdfdfd;
  position: sticky;
  bottom: 0;
  z-index: 2;
  padding: 12px;
}

._successToast_13v86_206 {
  padding: 4px 0;
}

._viewLink_13v86_210 {
  margin-left: 0.625rem;
  color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 6px 10px;
}
._viewLink_13v86_210:hover {
  color: #d9d9d9;
}

._newMemberDetails_13v86_221 {
  overflow-y: scroll;
  height: 100%;
}