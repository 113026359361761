import gql from 'graphql-tag';

const GET_BUDGET_FISCAL_SETTINGS_QUERY = gql`
    query GetBudgetFiscalSettingsQuery ($isCurrentYear: Boolean) {
        budgetSettingsResponse: getBudgetFiscalSettings (isCurrentYear: $isCurrentYear) {
            startMonth
            fiscalYears
            quarterNames
        }
    }
`;

export default GET_BUDGET_FISCAL_SETTINGS_QUERY;
