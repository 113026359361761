/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._LinkButton_pwks4_32 {
  padding: 8px 16px;
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 40px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  font-size: 13px;
  line-height: 20px;
  color: #3996e0;
  border-radius: 100px;
  transition: all 0.1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._LinkButton_pwks4_32:hover, ._LinkButton_pwks4_32:focus {
  background-color: #eff5f9;
}
._LinkButton_pwks4_32:active {
  background-color: transparent;
}
._LinkButton_pwks4_32._disabled_pwks4_57 {
  pointer-events: none;
  color: gray;
}
._LinkButton_pwks4_32 a {
  user-select: none;
  text-decoration: none;
  color: #3996e0;
}