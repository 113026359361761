import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { ActionType, TState } from '../types';

interface IAction {
  terms: IAgreement[];
  type: ActionType.SET_TERMS;
}
export type TSetTermsAction = Readonly<IAction>;

export const setTerms = (state: TState, action: TSetTermsAction): TState => ({
  ...state,
  terms: action.terms,
});
