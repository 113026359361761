import * as React from 'react';
import {
  Typography,
  Space,
} from '@revfluence/fresh';
import Payment from './Payment';
import {
  PaymentTypes,
  IPayment,
  IPaymentOption,
} from '../../types/CollaborationDetails';
import PaymentOption from './PaymentOption';

import styles from './Reward.scss';

const { Title } = Typography;

interface IProps {
  payment: IPayment;
  paymentPeriod: number;
  freeProduct: IPaymentOption;
  commission: IPaymentOption;
  noCompensation: IPaymentOption;
  advancedTerms: boolean;
  onShowHideNewPrice: (showHideNewPrice: boolean) => void;
  onUpdateNewPrice: (updatedNewPrice: number) => void;
  onShowHideDescription: (paymentType: PaymentTypes, showHideDescription: boolean) => void;
  onUpdateDescription: (paymentType: PaymentTypes, description: string) => void;
  onToggleSpecialPaymentTerms: (toggleSpecialPaymentTerms: boolean) => void;
  onUpdateSpecialPaymentTerms: (specialPaymentTerms: string) => void;
}

const Reward: React.FC<IProps> = React.memo((props) => {
  const {
    advancedTerms,
    payment,
    paymentPeriod,
    freeProduct,
    commission,
    noCompensation,
    onShowHideNewPrice,
    onUpdateNewPrice,
    onShowHideDescription,
    onUpdateDescription,
    onUpdateSpecialPaymentTerms,
    onToggleSpecialPaymentTerms,
  } = props;
  return (
    <div className={styles.Reward}>
      <Space direction="vertical">
        <Title level={4}>Compensation</Title>
        <Payment
          paymentPeriod={paymentPeriod}
          advancedTerms={advancedTerms}
          toggleSpecialPaymentTerms={payment.toggleSpecialPaymentTerms}
          specialPaymentTerms={payment.specialPaymentTerms}
          showHideNewPrice={payment.showHideNewPrice}
          newPrice={payment.newPrice}
          onShowHideNewPrice={onShowHideNewPrice}
          onUpdateNewPrice={onUpdateNewPrice}
          onToggleSpecialPaymentTerms={onToggleSpecialPaymentTerms}
          onUpdateSpecialPaymentTerms={onUpdateSpecialPaymentTerms}
        />
        <PaymentOption
          type={PaymentTypes.FreeProduct}
          showHideDescription={freeProduct.showHideDescription}
          description={freeProduct.description}
          onShowHideDescription={onShowHideDescription}
          onUpdateDescription={onUpdateDescription}
        />
        <PaymentOption
          type={PaymentTypes.Commission}
          showHideDescription={commission.showHideDescription}
          description={commission.description}
          onShowHideDescription={onShowHideDescription}
          onUpdateDescription={onUpdateDescription}
        />
        <PaymentOption
          type={PaymentTypes.NoCompensation}
          showHideDescription={noCompensation.showHideDescription}
          onShowHideDescription={onShowHideDescription}
        />
      </Space>
    </div>
  );
});

Reward.displayName = 'Reward';

export default Reward;
