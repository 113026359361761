import * as React from 'react';
import cx from 'classnames';
import { ClassValue } from 'classnames/types';

import { isArray } from 'lodash';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { safeJSONParse } from '@frontend/app/utils/strings';

const { useMemo } = React;

export interface IRichTextVisualizer {
  /** Expects either a simple string or a JSON string containing Quill's Delta */
  children: string;
  className?: ClassValue;
  style?: React.CSSProperties;
}

export const RichTextVisualizer: React.FC<IRichTextVisualizer> = (props) => {
  const {
    children,
    className,
    style,
  } = props;

  const parsedContent = useMemo(() => {
    const parsedJSON = safeJSONParse(children);

    if (typeof parsedJSON !== 'string' && isArray(parsedJSON?.ops)) {
      const converter = new QuillDeltaToHtmlConverter(parsedJSON.ops);
      return converter.convert();
    }

    return children;
  }, [children]);

  /**
   * Note about "dangerouslySetInnerHTML":
   *
   * We use the QuillDelta to sanitize the value
   * and prevent potential XSS or SQL injection attacks.
   * The result of the QuillDeltaToHtmlConverter is safe
   * to use inside this React component "as it is".
   */
  return (
    <div
      className={cx('rich-text-visualizer', className)}
      style={style}
      dangerouslySetInnerHTML={{ __html: parsedContent }}
    />
  );
};
