import * as React from 'react';

import { Button } from '@components';

import { ContentUploader } from '@frontend/app/components';
import { IContent } from '@frontend/app/hooks/useUploadContent';

import { ContentReducerActionType } from '../pages/NewContent';

import styles from './UploadContent.scss';

const { useState } = React;

interface IProps {
  dispatch(action): void;
}

export const UploadContent: React.FunctionComponent<IProps> = (props) => {
  const { dispatch } = props;
  const [contents, setContents] = useState<IContent[]>([]);

  const onClickNext = () => {
    dispatch({
      type: ContentReducerActionType.SetMedia,
      value: contents,
    });
  };

  const onContentsChanged = (contents) => {
    setContents(contents);
  };

  return (
    <div className={styles.upload_content}>
      <h1>Upload New Content</h1>
      <p>Upload content created by this member</p>
      <ContentUploader
        acceptApplication
        serviceName="message"
        onContentsChanged={onContentsChanged}
      />
      {contents && contents.length > 0
          && <Button theme="primary" label="Next" className={styles.skip_button} onClick={onClickNext} />}
    </div>
  );
};
