import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_COST_CONFIG_QUERY } from '../queries';
import {
  GetCostConfiguration,
  GetCostConfigurationVariables,
  GetCostConfiguration_clientConfig,
} from '../queries/types/GetCostConfiguration';

type IOptions = QueryHookOptions<GetCostConfiguration, GetCostConfigurationVariables>;
export type ICatalogueConfig = GetCostConfiguration_clientConfig;

export function useGetCostConfig(options: IOptions = {}) {
  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<GetCostConfiguration, GetCostConfigurationVariables>(
    GET_COST_CONFIG_QUERY,
    {
      ...options,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    data,
    error,
    refetch,
  };
}
