import { size } from 'lodash';

export const segmentFilterCount = (searchQuery) => {
  let count = 0;
  if (!searchQuery) {
    return count;
  }
  const keys = Object.keys(searchQuery);
  count = size(keys);
  if (keys.includes('communityId')) {
    count -= 1;
  }
  if (keys.includes('orderBy')) {
    count -= 1;
  }
  if (keys.includes('fields')) {
    count -= 1;
    count += searchQuery.fields?.and ? size(searchQuery.fields.and) : 0;
    count += searchQuery.fields?.or ? size(searchQuery.fields.or) : 0;
  }
  return count;
};

export const allSegmentId = (
  communityId?: number,
  sourcingGroupId?: string,
  programId?: number,
) => {
  if (sourcingGroupId) {
    return `source_${sourcingGroupId}`;
  } else if (communityId) {
    return `all_members_${communityId}`;
  } else if (programId) {
    return `new_applicants_${programId}`;
  } else {
    return 'all_members';
  }
};
