import * as React from 'react';
import cx from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import {
 map, findIndex, isEmpty, some, toLower,
} from 'lodash';
import { Input, Select } from '@revfluence/fresh';

import { EventName } from '@common';
import {
  MemberPageSegment as ISegment,
} from '@frontend/app/queries/fragments/types/MemberPageSegment';
import {
  SaveSegmentFolderMutation_folder as IFolder,
} from '@frontend/app/queries/types/SaveSegmentFolderMutation';

import { useSaveSegmentFolderMutation } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';

import { useEventContext } from '@frontend/app/context/EventContext';
import { NewFolderForm } from './NewFolderForm';

import styles from './EditSegmentForm.scss';

const {
 useState, useEffect, useRef, useMemo, useCallback,
} = React;
const { Option } = Select;
export interface IEditSegment {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  folderId: any;
}

interface IProps {
  communityId: number;
  sourcingGroupId: string;
  segment?: ISegment;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  folders?: any;
  onChange(result: IEditSegment);
  onPressEnter?();
  className?: string;
}

export const EditSegmentForm: React.FunctionComponent<IProps> = React.memo((props) => {
  const [editSegment, setEditSegment] = useState<IEditSegment>({
    title: '',
    folderId: '',
  });

  const selectRef = useRef(null);

  const {
    showSuccessMessage,
  } = useMessagingContext();

  const addEvent = useEventContext();

  const [showNewFolderForm, setShowNewFolderForm] = useState(false);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    const {
      folderId,
    } = props.segment || {};

    setShowNewFolderForm(false);

    setEditSegment({
      ...editSegment,
      folderId,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.segment]);

  useEffect(() => {
    const {
      folderId,
    } = editSegment;

    let selectedFolderId = folderId;
    // Default to first folder
    if (!selectedFolderId && !isEmpty(props.folders)) {
      selectedFolderId = props.folders[0].id;
    }

    if (folderId !== selectedFolderId) {
      setEditSegment((e) => ({ ...e, folderId: selectedFolderId }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.folders, props.segment]);

  useEffect(() => {
    props.onChange({
      ...editSegment,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSegment]);

  const folderOptions = useMemo(() => {
    if (isEmpty(props.folders)) {
      const defaultOptions = [{ value: 'default', label: 'Saved Filters' }];
      return defaultOptions;
    }
    return map(props.folders, (folder) => ({
      value: folder.id,
      label: folder.title,
    }));
  }, [props.folders]);

  const hasResults = useMemo(() => some(folderOptions,
      ({ label }) => label.toLowerCase().indexOf(search) >= 0), [search, folderOptions]);

  const selectedFolderIndex = useMemo(() => findIndex(folderOptions, { value: editSegment.folderId }), [folderOptions, editSegment]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeTitle = useCallback((event: any) => {
    const { value } = event.target;
    setEditSegment((e) => ({ ...e, title: value }));
  }, []);

  const handleChangeFolder = useCallback((folderId: number) => {
    setEditSegment((e) => ({ ...e, folderId }));
  }, []);

  const handleToggleNewFolderForm = useCallback(() => {
    setShowNewFolderForm((show) => !show);
  }, []);

  const handleSaveNewFolder = useCallback((newFolder: IFolder) => {
    setEditSegment((e) => ({ ...e, folderId: newFolder.id }));
    selectRef.current.blur();
    handleToggleNewFolderForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [saveSegmentFolder, {
    loading: isSaving,
  }] = useSaveSegmentFolderMutation({
    onCompleted: (result) => {
      showSuccessMessage(`Successfully created group ${result.folder.title}`);
    },
  });

  const handleClickSave = useCallback(async () => {
    if (isSaving) {
      return;
    }

    const {
      data: {
        folder,
      },
    } = await saveSegmentFolder({
      variables: {
        segmentFolder: {
          title: search,
          communityId: props.communityId,
          sourceGroup: props.sourcingGroupId,
        },
      },
    });

    selectRef.current.blur();

    setSearch('');
    setEditSegment((e) => ({ ...e, folderId: folder.id }));
    addEvent(EventName.CreateSegmentGroup, {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving, selectRef, props.communityId, search]);

  const handleSearchFilter = (input: string, option) => toLower(option.children).includes(toLower(input));

  const renderCustomDropdown = (menu) => (
    <div>
      {!showNewFolderForm && menu}
      {!showNewFolderForm && (
        <a className={styles.newGroup}>
          {hasResults
            ? (
              <div onClick={() => setShowNewFolderForm(true)}>
                Create New Filter Group
              </div>
            )
            : (
              <div onClick={handleClickSave}>
                <PlusOutlined />
                {' '}
                Create New Filter Group:
                <span className={styles.searchText}>{search}</span>
              </div>
            )}
        </a>
      )}
      {showNewFolderForm && (
        <NewFolderForm
          folders={folderOptions}
          communityId={props.communityId}
          sourcingGroupId={props.sourcingGroupId}
          onSave={handleSaveNewFolder}
          onCancel={handleToggleNewFolderForm}
        />
      )}
    </div>
  );

  const handleDropDownVisible = (visible: boolean) => {
    if (!visible) {
      setShowNewFolderForm(false);
    }
  };

  return (
    <div className={cx(styles.EditSegmentForm, props.className)}>
      <Select
        showSearch
        value={folderOptions[Math.max(selectedFolderIndex, 0)].value}
        onSearch={setSearch}
        className={styles.select}
        optionFilterProp="children"
        onChange={handleChangeFolder}
        onDropdownVisibleChange={handleDropDownVisible}
        dropdownMatchSelectWidth
        dropdownStyle={{ zIndex: 90000 }}
        filterOption={handleSearchFilter}
        dropdownRender={renderCustomDropdown}
      >
        {folderOptions.map(
          ({ value, label }) => (
            <Option key={value} value={value}>{label}</Option>
          ),
        )}
      </Select>
      <Input
        placeholder="Name your filter"
        onChange={handleChangeTitle}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        className={(styles as any).field}
        value={editSegment.title}
        disabled={props.disabled}
        onPressEnter={props.onPressEnter}
      />
    </div>
  );
});

EditSegmentForm.defaultProps = {
  disabled: false,
};
