import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_COMMUNITIES_QUERY } from '@frontend/app/queries';
import {
  GetCommunitiesQuery,
} from '@frontend/app/queries/types/GetCommunitiesQuery';

type IOptions = QueryHookOptions<GetCommunitiesQuery>;

export const useCommunitiesQuery = (options: IOptions = {}) => useQuery<GetCommunitiesQuery>(GET_COMMUNITIES_QUERY, options);
