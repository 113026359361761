import * as React from 'react';

import { Divider } from '@revfluence/fresh';

import { Table } from './components/Table';
import { Toolbar } from './components/Toolbar';

import { TContentTable } from './types';

export const ContentReviewTable = ({
 columns, rows, counters, onSearchTextChange, searchText,
}: TContentTable) => (
  <>
    <Toolbar columns={columns} rows={rows} counters={counters} onSearchTextChange={onSearchTextChange} searchText={searchText} />
    <Divider
      style={{
          marginTop: 'var(--spacing-lg)',
          marginBottom: 'var(--spacing-sm)',
        }}
    />
    <Table columns={columns} rows={rows} />
  </>
  );
