import { useQuery } from '@apollo/client';

import { HOME_PAGE_CLIENT_STATUS_QUERY } from '../queries/HomePageClientStatusQuery';
import { HomePageClientStatusQuery } from '../queries/types/HomePageClientStatusQuery';

export const useHomePageClientStatusQuery = (skip: boolean) => (
  useQuery<HomePageClientStatusQuery>(
    HOME_PAGE_CLIENT_STATUS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip,
    },
  )
);
