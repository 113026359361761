import { findIndex } from 'lodash';

import {
  IDeselectMembersAction,
  ISelectMembersAction,
  IState,
} from '../../../../types/state';

type Action<SelectionState extends boolean> =
  SelectionState extends true ? ISelectMembersAction : IDeselectMembersAction;

const toggleMemberSelection = <SelectionState extends boolean>(selectionState: SelectionState) => (
  state: IState,
  action: Action<SelectionState>,
): IState => {
  const { memberIds } = action;

  let updatedMembers = state.members;

  for (const id of memberIds) {
    const memberIndex = findIndex(state.members, { id });
    if (memberIndex < 0) {
      // somehow we failed to find any member matching this ID..
      continue;
    }

    const member = state.members[memberIndex];
    if (member.disabled || member.selected === selectionState) {
      // member is already disabled or selection state does not need to be toggled
      continue;
    }

    const updatedMember = {
      ...member,
      selected: selectionState,
    };

    // check if we've mutated the reference already or not
    if (updatedMembers === state.members) {
      updatedMembers = [
        ...state.members.slice(0, memberIndex),
        updatedMember,
        ...state.members.slice(memberIndex + 1),
      ];
    } else {
      updatedMembers[memberIndex] = updatedMember;
    }
  }

  if (updatedMembers === state.members) {
    // if the reference never got mutated, none of the members needed updates
    return state;
  }

  return {
    ...state,
    members: updatedMembers,
  };
};

export const selectMembers = toggleMemberSelection(true);
export const deselectMembers = toggleMemberSelection(false);
