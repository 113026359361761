import { isNull, values } from 'lodash';
import { RawDraftContentState } from 'draft-js';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { creatorPortalDomain } from '@frontend/applications/Shared/serviceHosts';
import { useGetPfaComposerMemberFields, MEMBER_FIRST_NAME, IVariable } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetPfaComposerMemberFields';

export default (

): [string | null, RawDraftContentState | null] => {
    const memberFieldIds = useGetPfaComposerMemberFields();
    if (isNull(memberFieldIds)) {
        return [null, null];
    }

    const { workflowActionParameters } = useApplication();
    const { programId } = workflowActionParameters;
    const creatorPortalDomainLink = `${creatorPortalDomain()}/programs/${programId}`;

    const variables: IVariable = {
        firstName: {
            field: `MEMBER_FIELDS.${memberFieldIds[MEMBER_FIRST_NAME]}`,
            key: 'FIRST NAME',
            label: 'First Name',
        },
    };

    let subject = '';
    let blocks: RawDraftContentState['blocks'] = [];

    subject = 'Your order has shipped';
    blocks = [
        {
            key: '8an9f',
            text: `Hello ${variables.firstName.label},`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [
                {
                    offset: 6,
                    length: variables.firstName.label.length,
                    key: variables.firstName.key,
                },
            ],
            data: {},
        },
        {
            key: '571i2',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'dvilg',
            text: 'Good things are coming your way. Your order has shipped and should arrive within a few days.',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'ekhau',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },
        {
            key: 'ag3ns',
            text: `You can track your package on your creator portal: ${creatorPortalDomainLink}`,
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        },

    ];

    const entityMap: RawDraftContentState['entityMap'] = {};

    for (const variable of values(variables)) {
        entityMap[variable.key] = {
            type: 'variable',
            mutability: 'IMMUTABLE',
            data: {
                data: {
                    field: variable.field,
                },
            },
        };
    }
    return [subject, { blocks, entityMap }];
};
