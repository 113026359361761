import * as React from 'react';
import cx from 'classnames';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CREATOR_OFFERS, TFieldValue } from '../../context/model';
import commonStyles from '../common.scss';
import tabStyles from '../TabField/styles.scss';
import creatorOffersStyles from './styles.scss';

interface ICreatorOfferProps {
  name: string;
  label: string;
  getFieldValue?: (fieldKey: string, defaultValue?: TFieldValue) => TFieldValue;
  setFieldValue?: (fieldKey: string, fieldValue: TFieldValue) => void;
}

const CreatorOffer: React.FC<ICreatorOfferProps> = React.memo(({
  name,
  label,
  setFieldValue = null,
  getFieldValue = null,
}) => {
  const isChecked = getFieldValue(name) as boolean;

  const onChange = (event: CheckboxChangeEvent): void => {
    if (setFieldValue) {
      setFieldValue(name, event.target.checked);
    }
  };

  return (
    <div className={creatorOffersStyles.creatorOfferItem}>
      <Checkbox onChange={onChange} checked={isChecked}>{label}</Checkbox>
    </div>
  );
});

CreatorOffer.displayName = 'CreatorOffer';

interface ICreatorOffersProps {
  title?: string;
  shouldDisplayRequiredIndicator?: boolean;
  getFieldValue?: (fieldKey: string, defaultValue?: TFieldValue) => TFieldValue;
  setFieldValue?: (fieldKey: string, fieldValue: TFieldValue) => void;
}

const CreatorOffers: React.FC<ICreatorOffersProps> = ({
  title,
  shouldDisplayRequiredIndicator = false,
  setFieldValue,
  getFieldValue,
}) => (
  <div className={creatorOffersStyles.CreatorOffers}>
    <div className={cx(commonStyles.formElement, tabStyles.container)}>
      <div className={commonStyles.formElementHead}>
        <div className={commonStyles.formElementLabel}>
          {
            shouldDisplayRequiredIndicator
              ? <span className={commonStyles.requiredIndicator}>*</span>
              : null
            }
          {title}
        </div>
      </div>
      <div className={cx(commonStyles.inputContainer)}>
        {CREATOR_OFFERS.map((offer) => (
          <CreatorOffer
            key={offer.key}
            name={offer.key}
            label={offer.label}
            setFieldValue={setFieldValue}
            getFieldValue={getFieldValue}
          />
        ))}
      </div>
    </div>
  </div>
);

CreatorOffers.displayName = 'CreatorOffers';

export default CreatorOffers;
