/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import cx from 'classnames';
import memoize from 'memoize-one';
import {
  assign,
  each,
  find,
  get,
  includes,
  isEmpty,
  isFunction,
  isNumber,
  isUndefined,
  keys,
  map,
  pick,
  toLower,
  values,
  reduce,
  without,
  union,
  chain,
  filter,
  size,
} from 'lodash';
import { Input } from '@revfluence/fresh';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';

import {
 Button, Notice, InfiniteList, useInviteContext, IMemberProgramMap,
} from '@components';
import { useScrollableStatus, useHover } from '@frontend/utils';

import { usePreventOverflowScroll } from '@frontend/app/hooks';

import { ExportToCsv } from 'export-to-csv';
import { TablePagination } from './Pagination';
import { ColumnConfig } from './ColumnConfig';
import { HeaderRow } from './HeaderRow';
import { BodyRow } from './BodyRow';
import { ScrollableMask } from './ScrollableMask';

export { EditInput as TableCellEditInput } from './Cell/Editable';

import { SortedDataList, SortTypes, TSortType } from './utils/SortedDataList';
import {
  TableContextProvider,
  ITableCellType,
  ITableConfig,
  defaultTableConfig,
  ITableColumnConfig,
  IRowData,
  IShowColumns,
  IColumnSortDir,
  INetworkCellConfig,
  ISelectableCellConfig,
} from './tableContext';

const {
 useState, useEffect, useLayoutEffect, useCallback, useRef, useImperativeHandle, useMemo,
} = React;

import styles from './Table.scss';

const getCellInvitedPrograms = (cell: React.ReactElement, memberProjects: IMemberProgramMap) => {
  const id = cell.props.socialAccount.id;
  const allInvited = chain(memberProjects)
    .values()
    .flatMap()
    .value();

  return filter(allInvited, (invited) => invited.social_account_id === id);
};

const cellValueCompareFn = (cellType: ITableCellType, valueA: any, valueB: any, sortDir: string, memberProjects: IMemberProgramMap) => {
  let sortableValueA = valueA;
  let sortableValueB = valueB;

  if (cellType === 'projects') {
    sortableValueA = size(getCellInvitedPrograms(sortableValueA, memberProjects));
    sortableValueB = size(getCellInvitedPrograms(sortableValueB, memberProjects));
  }

  if (cellType === 'network') {
    sortableValueA = map(valueA as INetworkCellConfig[], (network) => network.type)
      .sort()
      .join(',');
    sortableValueB = map(valueB as INetworkCellConfig[], (network) => network.type)
      .sort()
      .join(',');
  } else if (cellType === 'selectable') {
    sortableValueA = (valueA as ISelectableCellConfig).value;
    sortableValueB = (valueB as ISelectableCellConfig).value;
  } else if (cellType === 'date') {
    if (typeof valueA === 'string') {
      sortableValueA = new Date(valueA).getTime();
    } else if (isNumber(valueA)) {
      sortableValueA = valueA;
    }

    if (typeof valueB === 'string') {
      sortableValueB = new Date(valueB).getTime();
    } else if (isNumber(valueB)) {
      sortableValueB = valueB;
    }
  }

  let order = 0;
  const sortModifier = sortDir === SortTypes.ASC ? 1 : -1;
  if (sortableValueA > sortableValueB) {
    order = 1 * sortModifier;
  } else if (sortableValueA < sortableValueB) {
    order = -1 * sortModifier;
  } else if (cellType === 'numeric' && !isNaN(sortableValueA) && isNaN(sortableValueB)) {
    order = -1;
  } else if (cellType === 'numeric' && isNaN(sortableValueA) && !isNaN(sortableValueB)) {
    order = 1;
  }
  return order;
};

/**
 * Extracts the "sortable" value for a given row and column.
 * Checks for any explicit "sortableField" property, falling back to using the value
 * directly in the case where no sortableField is specified.
 * @param data IRowData[] The data frame.
 * @param index number The row index.
 * @param key string The column key.
 * @return Returns the sortable value for the given row and column.
 */
const extractSortableValue = (data: IRowData[], index: number, key: string): any =>
  get(data, [index, '_sortableFields', key], get(data, [index, key], ''));

/**
 * @private
 * Creates a new SortedDataList based on current state.
 *
 * @return {SortedDataList}
 */
const createSortedDataList = memoize(
  (
    data: IRowData[],
    columns: ITableColumnConfig[],
    colSortDirs: IColumnSortDir,
    showColumns: IShowColumns,
    filter: string,
    memberProjects: IMemberProgramMap,
    maintainSortedOrders?: boolean,
  ): SortedDataList<IRowData> => {
    const columnKeys = keys(showColumns);
    // contruct the index map by applying the filter
    const indexMap = [];
    each(data, (d, index) => {
      let foundMatch = false;

      // apply filter
      each(columnKeys, (columnKey) => {
        // skips the hidden columns or if match has been found
        if (!showColumns[columnKey] || foundMatch) {
          return;
        }

        if (!filter) {
          foundMatch = true;
        } else {
          const column = find(columns, (column) => column.field === columnKey);
          if (column?.searchable) {
            const value = column.searchValue ? column.searchValue(d[columnKey], d) : d[columnKey];

            if (includes(toLower(value), toLower(filter))) {
              foundMatch = true;
            }
          }
        }
      });

      if (foundMatch) {
        indexMap.push(index);
      }
    });

    // if no sort or enforced
    if (isEmpty(colSortDirs) || maintainSortedOrders) {
      return new SortedDataList<IRowData>(indexMap, data);
    }

    // use first key/value as sort direction for now
    const columnKey = keys(colSortDirs)[0];
    const sortDir = values(colSortDirs)[0];
    const column = find(columns, (column) => column.field === columnKey);

    if (column) {
      const { cellType } = column;

      indexMap.sort((indexA, indexB) => {
        // get data values
        const valueA = extractSortableValue(data, indexA, columnKey);
        const valueB = extractSortableValue(data, indexB, columnKey);
        // sort value
        return cellValueCompareFn(cellType, valueA, valueB, sortDir, memberProjects);
      });
    }

    return new SortedDataList<IRowData>(indexMap, data);
  },
);

export interface ITableProps {
  disabled?: boolean;
  // to stop currying rows selections when user change pages
  resetSelectionOnPaging?: boolean;
  emptyMessage?: string;
  className?: string;
  headerActionsClassName?: string;
  headerClassName?: string;

  // data
  data: IRowData[];
  columns: ITableColumnConfig[];
  rowDisplayName?: string;
  initialSelectedIds?: readonly string[];

  // maintain the selected ids even if they would be filtered out
  maintainFilteredSelections?: boolean;

  // maintain the sorted order
  maintainSortedOrders?: boolean;

  // callback on click
  onRowClicked?(rowData: IRowData, metaKey: boolean): void;

  // table body related config
  config?: ITableConfig;
  headerActions?: JSX.Element;
  tableHeader?: JSX.Element;
  onSelectedDataChange?(selectedData: any[]): void;

  // whether to leave some empty space when calculating ideal table height
  paddingBottom?: number;
  onReachedBottom?(): void;

  // for tables that are paged and sorted by remote query
  page?: number;
  pageSize?: number;
  onPageChangeCallback?(page: number): void;
  onSortDirChangeCallback?(columnField: string, sortDir: TSortType): void;
  totalRowCount?: number;
  isDataForCurrentPage?: boolean;
  initialColumnSort?: IColumnSortDir;

  // whether or not to display the export button
  exportable?: boolean;
  // function to call after exporting
  onExportCallback?(exportOptions: Record<string, any>): void;

  // editable cells onChange callback
  onChangeCellValue?(rowId: string, field: string, newValue: any, oldValue: any, rowData: IRowData);

  // column reordering
  onColumnReorder?: (
    orderedColumns: ITableColumnConfig[],
    selectedColumn: ITableColumnConfig,
    destinationIndex: number,
  ) => void;

  // column resizing
  onColumnResize?: (columnWidths: number[]) => void;

  // whether or not to show the header row when the table is empty
  // (defaults to false)
  showHeaderWhenEmpty?: boolean;
  // a custom component to render when empty instead of the usual notice
  renderEmptyPlaceholder?: () => React.ReactElement;

  search?: string;
  onSearchChange?: (value: string) => void;
  clearSearchText?: boolean;
  onClearSearchText?: () => void;

  disableInnerSearch?: boolean;
  // set cell width to 100%.
  fullWidthCell?: boolean;

  setDisplayedTotalRowCount?: (value: number) => void;
}

export interface ITableRefHandles {
  selectRowsWithIds(ids: string[], isControlled?: boolean): void;
  selectAllRows(): void;
  unsetSelectedRows(): void;
}

/**
 * @type {React.RefForwardingComponent}
 */
const TableComponent: React.RefForwardingComponent<ITableRefHandles, ITableProps> = (props, forwardedRef) => {
  const {
    className,
    columns,
    config: initialConfig,
    data,
    disabled,
    resetSelectionOnPaging,
    emptyMessage,
    exportable,
    onChangeCellValue,
    onColumnReorder,
    onColumnResize,
    onExportCallback,
    onPageChangeCallback,
    onReachedBottom,
    onSelectedDataChange,
    onSortDirChangeCallback,
    paddingBottom,
    renderEmptyPlaceholder,
    rowDisplayName,
    showHeaderWhenEmpty,
    headerActionsClassName,
    headerClassName,
    totalRowCount,
    initialColumnSort,
    maintainFilteredSelections,
    maintainSortedOrders,
    search,
    disableInnerSearch,
    fullWidthCell = false,
    setDisplayedTotalRowCount,
    clearSearchText,
    onClearSearchText,
    pageSize,
  } = props;
  const config = assign({}, defaultTableConfig, initialConfig);

  const [localPage, setLocalPage] = useState(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const controlledPage = !isUndefined(props.page);
  const page = controlledPage ? props.page : localPage;

  // used for calculating ideal table height
  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(0);
  usePreventOverflowScroll(ref);

  // used for handling shift-pressed select/unselect checkboxes
  const [lastClicked, setLastClicked] = useState<string>(null);
  const [currentClicked, setCurrentClicked] = useState<string>(null);
  const [isShiftPressed, setShiftPressed] = useState<boolean>(false);
  const [toSelectCheckBoxes, setToSelectCheckBoxes] = useState<boolean>(true);

  // used for checking if table body has scrolled to bottom
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const [tableBodyHovered, listeners] = useHover();
  const { canScrollDown } = useScrollableStatus(tableBodyRef);
  usePreventOverflowScroll(tableBodyRef);

  const inviteContext = useInviteContext();
  const memberProjects = inviteContext?.memberPrograms;

  const onWindowResize = () => {
    updateTableHeight();
  };

  useEffect(() => {
    addEventListener('resize', onWindowResize);

    return () => {
      removeEventListener('resize', onWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTableHeight = useCallback(() => {
    if (config.scrollable) {
      const node = ref.current;
      const { top } = node.getBoundingClientRect();
      const idealHeight = window.innerHeight - top - paddingBottom;

      setTableHeight(idealHeight);
    }
  }, [config.scrollable, paddingBottom]);

  useLayoutEffect(() => {
    updateTableHeight();
  }, [updateTableHeight]);

  // notify parent if table body has reached bottom
  useEffect(() => {
    if (canScrollDown === false && isFunction(onReachedBottom)) {
      onReachedBottom();
    }
  }, [canScrollDown, onReachedBottom]);

  // sorting and filtering
  const [colSortDirs, setColSortDirs] = useState<IColumnSortDir>(isEmpty(initialColumnSort) ? {} : initialColumnSort);

  const defaultShowColumns = useMemo<IShowColumns>(() => {
    const cols = {};
    each(columns, (column) => {
      cols[column.field] = true;
    });
    return cols;
  }, [columns]);

  const [showColumnsState, setShowColumnsState] = useState<IShowColumns>({});
  const [filter, setFilter] = useState<string>('');

  const searchText = useMemo(() => {
    if (clearSearchText && isFunction(onClearSearchText)) {
      onClearSearchText();
      return '';
    }

    return search || filter;
  }, [search, filter, clearSearchText, onClearSearchText]);

  const showColumns = useMemo(() => assign({}, defaultShowColumns, showColumnsState), [
    defaultShowColumns,
    showColumnsState,
  ]);

  // used for manually scrolling horizontally
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [maxScrollLeft, setMaxScrollLeft] = useState(0);
  const scrollLeftRef = useRef<number>(scrollLeft);
  scrollLeftRef.current = scrollLeft;
  const headerRowRef = useRef<HTMLDivElement>(null);
  const checkboxRef = useRef<HTMLDivElement>(null);
  const cellContainerRef = useRef<HTMLDivElement>(null);

  const { innerWidth } = window;

  const updateMaxScrollLeft = useCallback(() => {
    const headerRow = headerRowRef.current;
    const checkbox = checkboxRef.current;
    const cellContainer = cellContainerRef.current;

    if (!headerRow) {
      return;
    }

    // checkbox could be hidden
    const checkboxWidth = (checkbox && checkbox.clientWidth) || 0;

    const containerWidth = headerRow.clientWidth - checkboxWidth;
    setContainerWidth(containerWidth);

    const maxScroll = cellContainer.clientWidth - containerWidth;

    if (scrollLeftRef.current > maxScroll) {
      setScrollLeft(maxScroll);
    }

    setMaxScrollLeft(maxScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerWidth, data, headerRowRef.current, showColumns, scrollLeftRef]);

  useLayoutEffect(() => {
    updateMaxScrollLeft();
  }, [updateMaxScrollLeft]);

  // create sorted data list based on current state
  const sortedDataList = createSortedDataList(
    data,
    columns,
    colSortDirs,
    showColumns,
    disableInnerSearch ? '' : searchText,
    memberProjects,
    maintainSortedOrders,
  );

  // create sorted data list without the search filter so selection can be maintained
  const unfilteredData = createSortedDataList(
    data,
    columns,
    colSortDirs,
    showColumns,
    '',
    memberProjects,
    maintainSortedOrders,
  );
  // selected rows, only works when selectable is true
  const [selectedIds, setSelectedIds] = useState<readonly string[]>(props.initialSelectedIds || []);

  const selectableData = maintainFilteredSelections ? unfilteredData : sortedDataList;

  useImperativeHandle(forwardedRef, () => ({
    selectRowsWithIds: (ids: string[], isControlled?: boolean) => {
      setSelectedIds(ids);
      if (isFunction(onSelectedDataChange) && !isControlled) {
        onSelectedDataChange(selectableData.getSelectedDataByIds(ids));
      }
    },
    selectAllRows: () => {
      const ids = map(sortedDataList.getObjects(), (d) => d.id);
      let selectedPageIds = ids;
      if (pageSize && size(ids) > pageSize) {
        selectedPageIds = ids.splice(page * pageSize, pageSize);
      }
      setSelectedIds(selectedPageIds);
      if (isFunction(onSelectedDataChange)) {
        onSelectedDataChange(selectableData.getSelectedDataByIds(selectedPageIds));
      }
    },
    unsetSelectedRows: () => {
      setSelectedIds([]);
      if (isFunction(onSelectedDataChange)) {
        onSelectedDataChange([]);
      }
    },
  }));

  /**
   * Toggles check all/none.
   */
  const toggleAllSelected = useCallback(() => {
    const selectableIds = reduce(
      sortedDataList.getObjects(),
      (accSelectableIds, row) => {
        if (!row.disableSelection) {
          accSelectableIds.push(row.id);
        }
        return accSelectableIds;
      },
      [],
    );

    const newSelectedIds: string[] = selectedIds.length === selectableIds.length ? [] : selectableIds;

    if (isFunction(onSelectedDataChange)) {
      onSelectedDataChange(sortedDataList.getSelectedDataByIds(newSelectedIds));
    }

    setSelectedIds(newSelectedIds);
  }, [selectedIds, sortedDataList, onSelectedDataChange]);

  /**
   * Highlight/Unhighlight a row when selected.
   */
  const toggleRowSelected = useCallback(
    (id: string) => {
      let newSelectedIds = without(selectedIds, id);
      if (newSelectedIds.length === selectedIds.length) {
        // push the new ID to the list
        newSelectedIds = selectedIds.concat([id]);
      }
      if (isFunction(onSelectedDataChange)) {
        onSelectedDataChange(selectableData.getSelectedDataByIds(newSelectedIds));
      }

      setSelectedIds(newSelectedIds);
      setLastClicked(currentClicked || id);
      setCurrentClicked(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds, onSelectedDataChange, sortedDataList, setLastClicked, setCurrentClicked],
  );

  const toggleShiftPressed = (shiftPressed: boolean, isRowSelected: boolean) => {
    setToSelectCheckBoxes(!isRowSelected);
    setShiftPressed(shiftPressed);
  };

  /**
   * Set the page when user clicks on paging control
   */
  const setPage = useCallback(
    (page: number) => {
      if (resetSelectionOnPaging) {
        setSelectedIds([]);
        setLastClicked(null);
        setCurrentClicked(null);
        setShiftPressed(false);
        setToSelectCheckBoxes(true);
        if (isFunction(onSelectedDataChange)) {
          onSelectedDataChange([]);
        }
      }

      if (onPageChangeCallback) {
        onPageChangeCallback(page);
      }

      if (!controlledPage) {
        setLocalPage(page);
      }

      tableBodyRef.current.scrollTop = 0;
    },
    [
      onPageChangeCallback,
      controlledPage,
      resetSelectionOnPaging,
      setSelectedIds,
      onSelectedDataChange,
      setLastClicked,
      setCurrentClicked,
      setShiftPressed,
      setToSelectCheckBoxes,
    ],
  );

  const handleSearchTextChange = useCallback(
    (value) => {
      if (isFunction(props.onSearchChange)) {
        props.onSearchChange(value);
      }

      setFilter(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSearchChange, setFilter],
  );

  /**
   * Sorts by selected column.
   *
   * @param {String} columnField the selected column field.
   * @param {TSortType} sortDir sort direction.
   */
  const onSortDirChange = useCallback(
    (columnField: string, sortDir: TSortType) => {
      if (onSortDirChangeCallback) {
        onSortDirChangeCallback(columnField, sortDir);
      }

      // for now, only support sort by one column
      const newColSortDirs = {};
      if (sortDir) {
        newColSortDirs[columnField] = sortDir;
      }

      setColSortDirs(newColSortDirs);
    },
    [onSortDirChangeCallback],
  );

  const onShowColumnsChange = useCallback((showColumns: IShowColumns) => setShowColumnsState(showColumns), []);

  const tableData = isNumber(config.pageSize) && !props.isDataForCurrentPage
      ? sortedDataList.getObjects(page * config.pageSize, config.pageSize)
      : sortedDataList.getObjects();

  useEffect(
    () => {
      if (!isShiftPressed) {
        return;
      }
      const tableDataIds = map(tableData, (row) => row.id);
      const lastClickedIndex = tableDataIds.indexOf(lastClicked);
      const currentClickedIndex = tableDataIds.indexOf(currentClicked);
      const startIndex = Math.min(lastClickedIndex, currentClickedIndex);
      const lastIndex = Math.max(lastClickedIndex, currentClickedIndex);
      const idsToProcess = tableDataIds.slice(startIndex, lastIndex + 1);
      let newSelectedIds = toSelectCheckBoxes
        ? union(selectedIds, idsToProcess)
        : selectedIds.filter((id) => !idsToProcess.includes(id));
      if (newSelectedIds.length === selectedIds.length) {
        newSelectedIds = selectedIds.concat(newSelectedIds);
      }
      if (isFunction(onSelectedDataChange)) {
        onSelectedDataChange(selectableData.getSelectedDataByIds(newSelectedIds));
      }
      setSelectedIds(newSelectedIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShiftPressed, lastClicked, currentClicked, setSelectedIds, toSelectCheckBoxes],
  );

  const handleColumnResize = (columnWidths: number[]) => {
    if (isFunction(onColumnResize)) {
      onColumnResize(columnWidths);
    }
    updateMaxScrollLeft();
  };

  const renderHeaderRow = () => {
    if (!showHeaderWhenEmpty && sortedDataList.getSize() === 0) {
      return null;
    }
    return (
      <HeaderRow
        cellContainerRef={cellContainerRef}
        checkboxRef={checkboxRef}
        colSortDirs={colSortDirs}
        onSortDirChange={onSortDirChange}
        scrollLeft={scrollLeft}
        selectedIds={selectedIds}
        sortedDataList={sortedDataList}
        toggleAllSelected={toggleAllSelected}
        onColumnReorder={onColumnReorder}
        onColumnResize={handleColumnResize}
        ref={headerRowRef}
      />
    );
  };

  const renderEmptyTableBody = () => {
    if (sortedDataList.getSize() > 0) {
      return null;
    }
    let emptyContents;
    if (isFunction(renderEmptyPlaceholder)) {
      emptyContents = renderEmptyPlaceholder();
    } else {
      emptyContents = (
        <Notice type="disabled" className={styles.noResultNotice}>
          {isEmpty(searchText) ? emptyMessage : `There are no records that match the applied filter "${searchText}"`}
        </Notice>
      );
    }
    return (
      <>
        {renderHeaderRow()}
        {emptyContents}
      </>
    );
  };

  useEffect(() => {
    if (isFunction(setDisplayedTotalRowCount)) {
      setDisplayedTotalRowCount(totalRowCount || sortedDataList.getSize());
    }
  }, [totalRowCount, sortedDataList, setDisplayedTotalRowCount]);

  // because of useLayoutEffect
  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div
      ref={ref}
      className={cx(styles.Table, className, {
        [styles.scrollable]: config.scrollable,
      })}
      style={{
        maxHeight: config.scrollable ? `${tableHeight}px` : undefined,
      }}
    >
      <TableContextProvider
        data={tableData}
        config={config}
        columns={columns}
        showColumns={showColumns}
        onChangeCellValue={onChangeCellValue}
        editing={isEditing}
        updateIsEditing={setIsEditing}
        fullWidthCell={fullWidthCell}
      >
        {renderTableHeader()}
        {renderEmptyTableBody()}
        {sortedDataList.getSize() > 0 && (
          <>
            {renderHeaderRow()}
            <InfiniteList ref={tableBodyRef} className={styles.tableBody} {...listeners}>
              {map(tableData, (rowData, index) => (
                <BodyRow
                  // use index for key
                  // intersectionRatio is 0 if use rowData.id for key when sorting
                  key={rowData.id || index}
                  scrollLeft={scrollLeft}
                  selected={selectedIds.includes(rowData.id)}
                  rowIndex={index}
                  rowData={rowData}
                  onRowClicked={props.onRowClicked}
                  toggleRowSelected={toggleRowSelected}
                  toggleShiftPressed={toggleShiftPressed}
                  className={cx(
                    styles.bodyRow,
                    {
                      [styles.odd]: config.striped && index % 2 === 0,
                      [styles.border]: config.rowBorder,
                      // Clicking the row does nothing, why is this here? https://aspireiq.atlassian.net/browse/DT-3416
                      [styles.clickable]: /* !rowData.disableSelection */ false,
                      [styles.highlightOnHover]: config.highlightOnHover && !rowData.disableSelection,
                    },
                    rowData._bodyRowClassName,
                  )}
                />
              ))}
            </InfiniteList>
            {config.scrollable && (
              <ScrollableMask
                scrollLeft={scrollLeft}
                maxScrollLeft={maxScrollLeft}
                containerWidth={containerWidth}
                shouldCaptureWheel={tableBodyHovered}
                onScrollLeftChange={setScrollLeft}
              />
            )}
          </>
        )}
      </TableContextProvider>
      {disabled && <div className={styles.mask} />}
    </div>
  );

  /**
   * Renders the table mask, contains scroll left/right action buttons.
   *
   * @return {JSX.Element}
   */
  function renderTableHeader(): JSX.Element {
    const { headerActions, tableHeader } = props;

    const shouldRenderPagination = isNumber(config.pageSize) && sortedDataList.getSize() > 0;

    const shouldRenderTableHeader = tableHeader || headerActions || config.allowSearch || config.configurableColumns || shouldRenderPagination;

    if (!shouldRenderTableHeader) {
      return null;
    }

    const headersToExport = columns.filter((column) => showColumns[column.field]);

    const tableDataToExport = sortedDataList.getObjects().map((rowData) => {
      const row = pick(
        rowData,
        headersToExport.map((header) => header.field),
      );
      return map(row, (item) => item || '');
    });

    const options = {
      headers: headersToExport.map((header) => header.columnFilterLabel || header.headerName),
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'Export',
    };

    const csvExporter = new ExportToCsv(options as any);

    const onClickExport = () => {
      csvExporter.generateCsv(tableDataToExport);
      if (onExportCallback) {
        onExportCallback(options);
      }
    };

    return (
      <>
        <div className={cx(styles.tableHeader, headerClassName)}>
          {headerActions && <div className={cx(styles.headerActions, headerActionsClassName)}>{headerActions}</div>}
          <div className={styles.right}>
            {config.allowSearch && (
              <Input
                className={styles.searchInput}
                placeholder="Search..."
                prefix={<MagnifyingGlassIcon />}
                value={searchText}
                onChange={(ev) => {
                  handleSearchTextChange(ev.target.value);
                }}
              />
            )}
            {exportable && (
              <Button onClick={() => onClickExport()} label="Export" theme="info" className={styles.exportButton} />
            )}
            {shouldRenderPagination && (
              <TablePagination
                total={totalRowCount || sortedDataList.getSize()}
                page={page}
                onPageChange={setPage}
                className={styles.pagination}
                rowDisplayName={rowDisplayName}
              />
            )}
            {config.configurableColumns && (
              <ColumnConfig className={styles.columnConfig} onChange={onShowColumnsChange} />
            )}
          </div>
        </div>
        {tableHeader}
      </>
    );
  }
};

export const Table = React.forwardRef(TableComponent);
Table.defaultProps = {
  disabled: false,
  emptyMessage: 'There are no records.',
  exportable: false,
  paddingBottom: 140,
  showHeaderWhenEmpty: false,
  disableInnerSearch: false,
};
Table.displayName = 'Table';
