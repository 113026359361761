import * as React from 'react';

import { SocialListeningMethod } from './SocialListeningMethod';

import styles from './AdditionalMethods.scss';

export const AdditionalMethods: React.FC = () => (
  <div className={styles.AdditionalMethods}>
    <h2>Other Methods to Invite or Add Creators</h2>
    <p>Here are a few additional ways that you can invite or directly add a creator to work with</p>
    <div className={styles.methodsList}>
      <SocialListeningMethod />
    </div>
  </div>
  );
