import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';
import {
  Notice, Button, TrashcanIcon, IconButton, SpinnerIcon,
  CheckCircleIcon, ConfirmationModal, RoundAddCircleOutlineIcon,
  Modal,
} from '@components';
import { useAuth } from '@frontend/context/authContext';
import { MenuList } from '@frontend/app/components';
import {
  GetResourcesQuery_resources as IResource,
} from '@frontend/app/queries/types/GetResourcesQuery';

import { WooAddNew } from './WooAddNew';
import { useAddWooAccount } from '../hooks/useAddWooAccount';
import { useRevokeWooAccount } from '../hooks/useRevokeWooAccount';

import styles from './WooAccountList.scss';

interface IProps {
  activeAccounts: IResource[];
}

const { useCallback, useState } = React;

const MenuItems = [{
  id: 1,
  title: 'My WooCommerce',
}];

export const WooAccountList: React.FC<IProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [revokingId, setRevokingId] = useState<any>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const { user } = useAuth();

  const {
    revokeAccess,
    loading: isRevoking,
  } = useRevokeWooAccount();

  const handleClickRemoveAccount = useCallback((account: IResource) => {
    setRevokingId(account.id);
    setShowConfirmationModal(true);
  }, [setRevokingId, setShowConfirmationModal]);

  const handleCloseConfirmationModal = useCallback(() => {
    setRevokingId(null);
    setShowConfirmationModal(false);
  }, [setRevokingId, setShowConfirmationModal]);

  const handleRevokeAccess = async () => {
    await revokeAccess({
      variables: {
        resourceId: revokingId,
      },
    });
    handleCloseConfirmationModal();
  };

  const addAccount = useAddWooAccount();

  const handleAddAccount = useCallback((account: IResource) => {
    const name = account.authProvider.userExternalId;
    addAccount({ storeURL: name });
  }, [addAccount]);

  return (
    <div className={styles.WooAccountList}>
      <ConfirmationModal
        show={showConfirmationModal}
        onConfirm={handleRevokeAccess}
        onRequestClose={handleCloseConfirmationModal}
        title="Remove WooCommerce account"
        confirmLabel="Remove Account"
        theme="danger"
      />
      <MenuList
        className={styles.menu}
        selectedId={1}
        items={MenuItems}
      />
      <div className={styles.content}>
        {map(props.activeAccounts, (account) => {
          const revoked = account.authProvider.systemRevokedAccess;
          return (
            <div key={account.id} className={cx(styles.listItem, { [styles.disabled]: isRevoking })}>
              <div className={cx(styles.store, { [styles.error]: revoked })}>
                <div className={styles.icon}>
                  {!revoked && <CheckCircleIcon size={14} className={styles.checkmark} />}
                  <img src="https://storage.googleapis.com/aspirex-static-files/woo-app/woo.svg" />
                </div>
                <div className={styles.title}>
                  {account.authProvider.userExternalDisplayId}
                </div>
                <div className={styles.remove}>
                  <IconButton
                    icon={revokingId === account.id ? <SpinnerIcon size={22} /> : <TrashcanIcon size={22} />}
                    disabled={revokingId === account.id || account.userId !== user.sub}
                    onClick={() => handleClickRemoveAccount(account)}
                  />
                </div>
              </div>
              {revoked && (
                <Notice type="error" showDivider className={styles.errorNotice}>
                  <div className={styles.noticeContent}>
                    <div className={styles.title}>
                      We are having errors syncing your account,
                      {' '}
                      {account.authProvider.userExternalDisplayId}
                    </div>
                    <div className={styles.text}>
                      Please try authorizing your account again
                    </div>
                    <Button
                      theme="light"
                      label="Reconnect"
                      className={styles.reconnect}
                      round={false}
                      fullWidth={false}
                      onClick={() => handleAddAccount(account)}
                      disabled={account.userId !== user.sub}
                    />
                  </div>
                </Notice>
              )}
            </div>
          );
        })}
        {isEmpty(props.activeAccounts) && (
          <Button
            label="Add WooCommerce Store"
            icon={<RoundAddCircleOutlineIcon size={18} />}
            iconSide="left"
            className={styles.addBtn}
            theme="info"
            onClick={setShowAddModal.bind(this, true)}
          />
        )}
        <Modal show={showAddModal} onRequestClose={setShowAddModal.bind(this, false)}>
          <WooAddNew key={String(showAddModal)} className={styles.addNew} />
        </Modal>
      </div>
    </div>
  );
};
