import * as React from 'react';
import { Typography } from '@revfluence/fresh';
import { format } from 'date-fns';

const { Title, Text } = Typography;

interface IHeaderProps {
  startDate: Date;
  endDate: Date;
}

const Header = ({
  startDate,
  endDate,
}: IHeaderProps) => (
  <>
    <Title level={4}>Performance Impact</Title>
    <Text>
      The impact from all projects and posts we found via social listening tags and mentions from
      <strong>
        {' '}
        {format(startDate, 'MMM dd, yyyy')}
        {' '}
        to
        {' '}
        {format(endDate, 'MMM dd, yyyy')}
        .
      </strong>
    </Text>
  </>
);

export default Header;
