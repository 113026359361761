import React from 'react';
import { Menu, Modal } from '@revfluence/fresh';
import { AutomationAction } from '@frontend/app/containers/Projects/AutomationConfig/constants';
import { CircleExclamationIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';

const { confirm } = Modal;

type TProps = {
  name: string;
  action: string;
  onDeleteAutomation: () => void;
  onDisableAutomation: () => void;
  onResumeAutomation: () => void;
};

const ActionItem = (props: TProps) => {
  const {
    name,
    action,
    onDeleteAutomation,
    onDisableAutomation,
    onResumeAutomation,
  } = props;

  const showConfirmOnDeleteAutomation = () => {
    confirm({
      title: 'Are you sure you want to delete this automation?',
      content: 'This will cancel all in-flight executions of this automation.',
      icon: <TriangleExclamationIcon style={{ color: '#F5222D' }} />,
      okText: 'Delete',
      okType: 'danger',
      centered: true,
      onOk() {
        onDeleteAutomation();
      },
    });
  };

  const showConfirmOnDisableAutomation = () => {
    confirm({
      title: 'Are you sure you want to turn this automation off?',
      content: 'This will cancel all in-flight executions of this automation.',
      icon: <CircleExclamationIcon />,
      okText: 'Turn Off',
      okType: 'primary',
      centered: true,
      onOk() {
        onDisableAutomation();
      },
    });
  };

  const showConfirmOnResumeAutomation = () => {
    confirm({
      title: 'Are you sure you want to turn this automation on?',
      content: 'Only new triggers that occur after turning the automation on will execute.',
      icon: <CircleExclamationIcon />,
      okText: 'Turn On',
      okType: 'primary',
      centered: true,
      onOk() {
        onResumeAutomation();
      },
    });
  };

  const onActionClick = () => {
    switch (action) {
      case AutomationAction.DISABLE:
        showConfirmOnDisableAutomation();
        break;
      case AutomationAction.DELETE:
        showConfirmOnDeleteAutomation();
        break;
      case AutomationAction.RESUME:
        showConfirmOnResumeAutomation();
        break;
      default:
    }
  };

  return (
    <Menu.Item>
      <div onClick={onActionClick}>
        {name}
      </div>
    </Menu.Item>
  );
};

export default ActionItem;
