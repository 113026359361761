/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1g9rm_157 {
  display: flex;
}

._justify-content-space-between_1g9rm_161 {
  justify-content: space-between;
}

._tabular-nums_1g9rm_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1g9rm_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferImage_1g9rm_178 {
  object-fit: cover;
}

._OfferImageFallback_1g9rm_182 {
  align-items: center;
  background-color: blue;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
}
._OfferImageFallback_1g9rm_182 ._icon_1g9rm_191 {
  color: #fdfdfd;
  font-size: 1.5rem;
  transform: rotate(135deg);
}
._OfferImageFallback_1g9rm_182._rounded_1g9rm_196 {
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
}
._OfferImageFallback_1g9rm_182._affiliateLink_1g9rm_201 {
  background-color: #F1BEB2;
}
._OfferImageFallback_1g9rm_182._promoCode_1g9rm_204 {
  background-color: #F6CD75;
}
._OfferImageFallback_1g9rm_182 .anticon {
  margin-right: 0;
}