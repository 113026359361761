import { useEffect, useRef } from 'react';
import { History } from 'history';

export const useMemberPagePrevUrl = (history: History<unknown>) => {
  const prevUrl = useRef('');
  const currentMemberPageUrl = useRef('_');

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (location.pathname.startsWith(currentMemberPageUrl.current)) {
        return;
      }

      const matches = location.pathname.match(/^\/members\/(\d)+$/);
      if (matches) {
        localStorage.setItem('MEMBER_PAGE_BACK_URL', prevUrl.current);
        currentMemberPageUrl.current = location.pathname;
      } else {
        currentMemberPageUrl.current = '_';
      }

      prevUrl.current = `${location.pathname}${location.search}${location.hash}`;
    });

    return () => {
      unlisten();
    };
  }, [history]);
};
