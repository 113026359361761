import { ContentType } from '@frontend/app/types/globalTypes';
import { IState, Step } from '../../../../types/state';
import { hasSelectedInstagramStories } from '../../../../utils';
import { isTiktok, isInstagram } from '../../../../utils/contentTypeUtils';

export const checkContentFF = (type: ContentType): boolean => {
  if (isInstagram(type)) {
    return true;
  }
  if (isTiktok(type)) {
    return true;
  }
  return false;
};

export const addContentGuidelines = (state: IState): IState => {
  const contentGuidelinesNotEmpty = state.contentGuidelines.filter(
    (contentGuideline) => contentGuideline.dueDates.completeDate.length > 0,
  );
  const contentGuidelines = contentGuidelinesNotEmpty.map(
    (contentGuideline) => {
      if (!contentGuideline.contentGuidelineInstanceId) {
        return {
          ...contentGuideline,
          requiresBrandedContentTag: checkContentFF(contentGuideline.type),
          contentGuidelineInstanceId: Math.floor(Math.random() * 1000000),
        };
      }
      return contentGuideline;
    },
  );
  const hasIgStories = hasSelectedInstagramStories(contentGuidelines);
  const hasIgBCRequired = contentGuidelines.some((content) => isInstagram(content.type)
    && content.requiresBrandedContentTag);
  return {
    ...state,
    step: Step.CollaborationDetails,
    contentGuidelines,
    collaborationDetails: {
      ...state.collaborationDetails,
      bam: {
        ...state.collaborationDetails.bam,
        toggleInstagramInsights: hasIgBCRequired || hasIgStories,
      },
    },
  };
};
