import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { Drawer } from '@frontend/app/components';
import { NewMemberDetailContainer } from '../../MemberDetail/NewMemberDetailContainer';

import styles from './NewMemberDrawer.scss';

const { useCallback } = React;

export interface NewMemberDrawerProps {
  memberId: string;
  backUrl?: string;

  // TODO: remove this prop when implementing the new deliverables tab
  // this was used to disable the tab when the user is not in Projects.
  isDeliverablesHidden?: boolean;
}

export const NewMemberDrawer = ({ memberId, backUrl, isDeliverablesHidden }: NewMemberDrawerProps) => {
  const history = useHistory();

  const onBackButtonClicked = useCallback(() => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  }, [history, backUrl]);

  return (
    <section className={cx(styles.content, 'new-member-drawer')}>
      <Drawer className={styles.memberDetailsDrawer} hideToggle>
        <NewMemberDetailContainer
          memberId={parseInt(memberId, 10)}
          onBackButtonClicked={onBackButtonClicked}
          isDeliverablesHidden={isDeliverablesHidden}
        />
      </Drawer>
    </section>
  );
};
