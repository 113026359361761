import gql from 'graphql-tag';

export const PROJECT_OVERVIEW_QUERY = gql`
query ProjectOverviewQuery($startDate: DateTime, $endDate: DateTime, $projectIds: [Int!]) {
  homePageStats: reporting {
    social {
      totals {
        reach {
           diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        impressions {
           diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        engagements {
           diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        creatorCount {
           diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        views {
          diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        postCount {
          diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        tmv {
          diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
      }
    }
    sales: salesTracking {
      totals {
        sales {
          diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        conversions {
          diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
        affiliates {
          diff: value(programIds: $projectIds, endDate: $endDate, startDate: $startDate)
        }
      }
    }
  }
}`;
