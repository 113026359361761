import * as React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { OverlaySpinner } from '@components';

import { useGetMessageTemplateForProgramQuery } from '@frontend/app/hooks';
import { EmailEditorProvider } from '@frontend/app/components/MessageComposer/EmailEditor/EmailEditorContext';
import { Editor } from '@frontend/app/components';
import { VariableBlock, findVariableStrategy } from '@frontend/app/components/MessageComposer/EmailEditor/decorator';
import {
  TemplateType,
} from '@frontend/app/types/globalTypes';

import styles from './InviteEmailPage.scss';

import { TProject } from '../types';

interface IProps {
  project: TProject;
}

export const InviteEmailPage: React.FC<IProps> = (props) => {
  const { project } = props;

  const {
    loading,
    data: {
      template,
    } = {},
  } = useGetMessageTemplateForProgramQuery({
    variables: {
      programId: project.id,
      type: TemplateType.INVITE,
    },
  });

  if (loading) {
    return <OverlaySpinner />;
  }

  return (
    <div className={styles.InviteTemplatePage}>
      <div className={styles.EmailComposer}>
        {
          template ? (
            <>
              <div className={styles.section}>
                <span className={styles.label}>Subject:</span>
                <span>{template?.subject}</span>
              </div>
              <div className={cx(styles.section, styles.editor)}>
                <EmailEditorProvider>
                  <Editor
                    readOnly
                    initialValue={template.text}
                    decorators={[{
                      strategy: findVariableStrategy,
                      component: VariableBlock,
                    }]}
                  />
                </EmailEditorProvider>
              </div>
            </>
          ) : (
            <div>No template</div>
          )
        }
      </div>
      <div className={styles.footer}>
        Please go
        {' '}
        <Link to={`/settings/messageTemplates?templateId=${template?.id}`}>here</Link>
        {' '}
        to customize your invite email message
      </div>
    </div>
  );
};
