import React, { useEffect, useMemo, useState } from 'react';
import { isNil, isNumber } from 'lodash';
import { Input } from '@revfluence/fresh';
import { Tooltip } from '@revfluence/fresh';

import { ExternalLinkIcon, EditIcon } from '@components';
import { useAuth } from '@frontend/context/authContext';
import { OUTLOOK_APP_ID, GMAIL_APP_ID } from '@frontend/app/constants/applicationIds';
import {
  useGetInstalledApplicationIds,
  MessageTemplate,
  MessageTemplateInput,
} from '@frontend/app/hooks';
import { TemplateBodyType, TemplateType } from '@frontend/app/types/globalTypes';
import {
  EmailEditor,
  IEmailEditorState,
} from '@frontend/app/components/MessageComposer/EmailEditor';
import { useManageAttachments } from '@frontend/app/components';

type MessageTemplateEditorProps = {
  messageTemplate: MessageTemplate | MessageTemplateInput;
  onUpdateMessageTemplate: (template: MessageTemplate | MessageTemplateInput) => void;
  resetMessageTemplate: boolean;
};

import styles from './MessageTemplateEditor.scss';

export const MessageTemplateEditor: React.FC<MessageTemplateEditorProps> = (props) => {
  const { messageTemplate, onUpdateMessageTemplate, resetMessageTemplate } = props;
  const { clientInfo } = useAuth();
  const installedApps = useGetInstalledApplicationIds();
  const [tempTitle, setTempTitle] = useState<string>('');
  const [tempSubject, setTempSubject] = useState<string>('');
  const [editorState, setEditorState] = useState<IEmailEditorState>(null);
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const {
    attachments,
    handleSelectedFilesChange,
    handleDeleteAttachment,
    setInitialAttachments,
  } = useManageAttachments({});

  useEffect(() => {
    setTempTitle(messageTemplate.title);
    setTempSubject(messageTemplate.subject);
    onUpdateMessageTemplate({ ...messageTemplate });
  }, [messageTemplate, onUpdateMessageTemplate]);

  useEffect(() => {
    setInitialAttachments(messageTemplate.attachments);
  }, [messageTemplate.attachments, setInitialAttachments]);

  const emailAppId = useMemo(() => (
    installedApps[GMAIL_APP_ID] ? GMAIL_APP_ID : OUTLOOK_APP_ID
  ), [installedApps]);

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSubject = e.target.value;
    setTempSubject(updatedSubject);
    onUpdateMessageTemplate({
      ...messageTemplate,
      title: tempTitle,
      subject: updatedSubject,
      text: editorState ? JSON.stringify(editorState.raw) : messageTemplate.text,
      attachments,
    });
  };

  const handleBodyChange = (editorState: IEmailEditorState) => {
    setEditorState(editorState);
    onUpdateMessageTemplate({
      ...messageTemplate,
      title: tempTitle,
      subject: tempSubject,
      text: editorState ? JSON.stringify(editorState.raw) : messageTemplate.text,
      attachments,
    });
  };

  const handleTitleChange = (e) => {
    const updatedTitle = e.target.value;
    setTempTitle(updatedTitle);
    onUpdateMessageTemplate({
      ...messageTemplate,
      title: updatedTitle,
      subject: tempSubject,
      text: editorState ? JSON.stringify(editorState.raw) : messageTemplate.text,
      attachments,
    });
  };

  useEffect(() => {
    if (resetMessageTemplate) {
      setTempTitle(messageTemplate.title);
      setTempSubject(messageTemplate.subject);
      setEditorState(null);
      setIsEditingTitle(false);
      onUpdateMessageTemplate(null);
    }
  }, [
    resetMessageTemplate,
    messageTemplate,
    onUpdateMessageTemplate,
  ]);

  const additionalVariables = useMemo(() => {
    switch (messageTemplate.type) {
      case TemplateType.BRIEF_ACCEPTED_FOLLOW_UP:
      case TemplateType.BRIEF_REMINDER:
      case TemplateType.TERMS:
        return { LINK_TO_TERMS: { label: 'Link To Brief' } };
      case TemplateType.ORDER_SELECTION_REMINDER:
        return { PRODUCT_SELECTION_PAGE: { label: 'Product Selection Page' } };
      default:
        return {};
    }
  }, [messageTemplate]);

  return (
    <div className={styles.MessageTemplateEditor}>
      <div className={styles.template}>
        <div className={styles.templateHeader}>
          <div className={styles.templateTitle}>
            {isEditingTitle && (
              <Input
                autoFocus
                className={styles.titleInput}
                value={tempTitle}
                onChange={handleTitleChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setIsEditingTitle(false);
                  }
                }}
                onBlur={() => setIsEditingTitle(false)}
              />
            )}
            {!isEditingTitle && (
              <>
                <span>{tempTitle}</span>
                <span
                  className={styles.editIcon}
                  onClick={() => {
                    setIsEditingTitle(true);
                  }}
                >
                  <EditIcon size={18} />
                </span>
              </>
            )}
          </div>
          {isNumber(messageTemplate.id) && (
            <div>
              <Tooltip
                placement="topRight"
                title="Open template in new tab"
              >
                <a
                  className={styles.icon}
                  target="_blank"
                  href={`/client/${clientInfo.id}/settings/messageTemplates?templateId=${messageTemplate.id}`}
                >
                  <ExternalLinkIcon size={14} className={styles.externalLinkIcon} />
                </a>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles.emailComposer}>
          <div className={styles.subject}>
            <div className={styles.label}>Subject:</div>
            <div className={styles.text}>
              <Input
                placeholder="Enter email subject"
                value={tempSubject}
                onChange={handleSubjectChange}
              />
            </div>
          </div>
          <EmailEditor
            key={messageTemplate.id}
            initialValue={messageTemplate.text}
            plaintextOnly={messageTemplate.bodyType === TemplateBodyType.PLAINTEXT}
            onChange={handleBodyChange}
            enableVariableReplacement
            additionalVariables={additionalVariables}
            showProgramVariables={!isNil(messageTemplate.programId)}
            attachments={attachments}
            onAttachmentsSelected={handleSelectedFilesChange}
            onDeleteAttachment={handleDeleteAttachment}
          />
        </div>
      </div>
      <div className={styles.signature}>
        Your signature will be included in all your templates.
        {' '}
        <a target="_blank" href={`/client/${clientInfo.id}/settings/${emailAppId}`}>
          Edit Signature
          <ExternalLinkIcon size={14} className={styles.externalLinkIcon} />
        </a>
      </div>
    </div>
  );
};
