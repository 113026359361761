export type TNetwork =
  | 'blog'
  | 'instagram'
  | 'youtube'
  | 'twitter'
  | 'facebook'
  | 'twitch'
  | 'snapchat'
  | 'pinterest'
  | 'tiktok';

export const NETWORK_NAME: {
  [key in TNetwork]?: string;
} = {
  blog: 'Blog',
  instagram: 'Instagram',
  youtube: 'YouTube',
  twitter: 'Twitter',
  facebook: 'Facebook',
  twitch: 'Twitch',
  snapchat: 'Snapchat',
  pinterest: 'Pinterest',
  tiktok: 'TikTok',
};
