/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import { useHistory } from 'react-router-dom';

import styles from './TermsGuidelinesSettingsEmpty.scss';

const { useCallback } = React;

const TermsGuidelinesSettingsEmpty: React.FC = () => {
  const history = useHistory();
  const createProject = useCallback(() => {
    history.push({ ...window.location, pathname: '/projects/new' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.empty}>
      Guideline templates for briefs are connected to Projects. Please
      {' '}
      <a href="#" onClick={createProject}>
        create your
        first project
      </a>
      {' '}
      to get started
    </div>
  );
};

export default TermsGuidelinesSettingsEmpty;
