import * as React from 'react';
import cn from 'classnames';

import {
  Typography, Divider, Space, Badge,
} from '@revfluence/fresh';
import { ScaleBalancedIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  InstagramIcon, TiktokIcon, YoutubeIcon, PinterestIcon, TwitterIcon,
} from '@revfluence/fresh-icons/brands/esm';

import Statistics from '../../../../../components/Statistics';
import styles from '../TabContent.module.scss';
import { IInvestmentValues, IReportingPageProps } from '../../../Reporting';
import { ValueRow } from '../ValueRow';
import { InvestmentRow } from '../InvestmentRow';

const { Title, Text } = Typography;

interface IEngagementProps {
  engagementsValue: IReportingPageProps['engagementValues'];
  investmentValue: IInvestmentValues;
  engagementTotalValue: number;
}

const Engagements = ({
  engagementTotalValue,
  engagementsValue,
  investmentValue,
}: IEngagementProps) => (
  <Space direction="vertical" className={cn(styles.TabContent)} size="small">
    <Title level={4}>Engagements Value</Title>
    <Title level={5}>
      <ScaleBalancedIcon style={{ marginRight: '8px' }} />
      How it's calculated
    </Title>
    <Space
      direction="vertical"
      size={13}
      style={{
        overflowY: 'scroll', maxHeight: 300, paddingRight: 8, width: '100%',
      }}
    >
      <Divider />
      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Posts
        </Text>
        <ValueRow title="Likes" value={engagementsValue.instagramPosts.likes} />
        <ValueRow title="Comments" value={engagementsValue.instagramPosts.comments} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Reels
        </Text>
        <ValueRow title="Likes" value={engagementsValue.instagramReels.likes} />
        <ValueRow title="Comments" value={engagementsValue.instagramReels.comments} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Stories
        </Text>
        <ValueRow title="Likes" value={engagementsValue.instagramStories.likes} />
        <ValueRow title="Comments" value={engagementsValue.instagramStories.comments} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <InstagramIcon />
          {' '}
          Instagram Videos
        </Text>
        <ValueRow title="Likes" value={engagementsValue.instagramVideos.likes} />
        <ValueRow title="Comments" value={engagementsValue.instagramVideos.comments} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <TiktokIcon />
          {' '}
          Tiktok
        </Text>
        <ValueRow title="Likes" value={engagementsValue.tiktok.likes} />
        <ValueRow title="Comments" value={engagementsValue.tiktok.comments} />
        <ValueRow title="Shares" value={engagementsValue.tiktok.shares} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <YoutubeIcon />
          {' '}
          YouTube
        </Text>
        <ValueRow title="Views" value={engagementsValue.youtube.views} />
        <ValueRow title="Likes" value={engagementsValue.youtube.likes} />
        <ValueRow title="Comments" value={engagementsValue.youtube.comments} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <PinterestIcon />
          {' '}
          Pinterest
        </Text>
        <ValueRow title="Saves" value={engagementsValue.pinterest.favorites} />
        <ValueRow title="Clicks" value={engagementsValue.pinterest.clicks} />
        <ValueRow title="Closeups" value={engagementsValue.pinterest.closeups} />
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          <TwitterIcon />
          {' '}
          Twitter
        </Text>
        <ValueRow title="Likes" value={engagementsValue.twitter.likes} />
        <ValueRow title="Comments" value={engagementsValue.twitter.comments} />
        <ValueRow title="Shares" value={engagementsValue.twitter.shares} />
      </div>

    </Space>
    <Divider />
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.orange)} />
        Engagement Total Value
      </Text>
      <Statistics value={engagementTotalValue.toFixed(2)} prefix="$" size="default" />
    </Space>
    <Divider />
    <InvestmentRow values={investmentValue} />
    <Divider />
    <Space>
      <Title level={5}>
        Engagements Net Value
      </Title>
      <Title level={5}>
        <Statistics value={(engagementTotalValue - investmentValue.total).toFixed(2)} prefix="$" size="large" strong />
      </Title>
    </Space>
  </Space>
);

export default Engagements;
