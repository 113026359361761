import { Button as ButtonClass } from 'antd';

import * as React from 'react';
import cx from 'classnames';
import { ButtonProps } from 'antd/lib/button';

import styles from './Button.scss';

interface IButtonProps extends Omit<ButtonProps, 'type'> {
  type?: ButtonProps['type'] | 'danger';
}

export const Button: React.FC<IButtonProps> = (props) => {
  const { className, type, ...restProps } = props;
  return (
    <ButtonClass
      className={cx(styles.Button, className)}
      danger={type === 'danger'}
      type={type === 'danger' ? 'primary' : type}
      {...restProps}
    />
  );
};
