import * as React from 'react';
import { isEmpty, map } from 'lodash';

import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useMessagingContext } from '@frontend/hooks';

import { Alert } from '@revfluence/fresh';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { Skeleton } from 'antd';
import { InvalidMembersCPS, BasicMember } from '@frontend/applications/ProductFulfillmentApp/pages/InvalidMembersCPS';

import { useBulkSendOrderRequest } from '@frontend/applications/ProductFulfillmentApp/hooks/useBulkSendOrderRequest';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { useBulkSendShopifyOrderRequest } from '@frontend/applications/ProductFulfillmentApp/hooks/useBulkSendShopifyOrderRequest';
import { CPSEmailComposer } from '../EmailComposer/EmailComposer';
import getInitialEmailState from '../../utils/initialEmailStateSendOrderRequest';
import styles from './MessageComposer.scss';

interface IProps {
  type: ProjectConfigType;
}

export const SendOrderRequestEmail: React.FC<Readonly<IProps>> = (props: IProps) => {
  const { type } = props;
  const {
 shopifyResource, workItems, allMembers, getMemberShippingAddress,
} = useProductFulfillmentContext();
  const { workflowActionParameters, closeModal } = useApplication();
  const [showInvalidMembers, setShowInvalidMembers] = React.useState(true);
  const programId = workflowActionParameters.programId;

  const { showMessage } = useMessagingContext();

  const { loading: isLoadingProjectConfig, projectConfig, error: projectConfigError } = useGetProjectConfigByType({
    variables: {
      projectId: programId,
      type,
    },
    skip: !programId,
  });

  const {
    bulkSendOrderRequest: _bulkSendOrderRequest,
    loading: bulkSendingOrderRequest,
    error: bulkSendingOrderRequestError,
  } = useBulkSendOrderRequest({
    onCompleted: () => {
      closeModal();
      showMessage({
        type: 'success',
        content: 'Order request sent',
      });
    },
  });

  const {
    bulkSendShopifyOrderRequest,
    isBulkSendShopifyOrderRequestLoading,
    bulkSendShopifyOrderRequestError,
  } = useBulkSendShopifyOrderRequest({
    onCompleted: () => {
      closeModal();
      showMessage({
        type: 'success',
        content: 'Order requests are being sent, feel free to continue working',
      });
    },
  });
  const createShopifyOrderRequest = async (messageParams: SendMessageInput) => {
    await bulkSendShopifyOrderRequest({
      variables: {
        members: allMembers.map((m) => ({
          id: m.id,
          name: m.name,
          email: m.email,
          workItemId: workItems?.find((wi) => wi?.data?.memberId === m.id)?.id,
        })),
        messageParams: {
          attachments: messageParams.attachments,
          membersSearchQuery: messageParams.membersSearchQuery,
          message: messageParams.message,
          resourceId: messageParams.resourceId,
          subject: messageParams.subject,
          type: messageParams.type.toLowerCase(),
          additionalCc: messageParams.additionalCc,
        },
        params: {
          programId,
          resourceId: shopifyResource.id,
          specURI: workflowActionParameters.workletSpecUri,
        },
      },
    });
  };

  const createOfflineOrderRequest = async (messageParams: SendMessageInput) => {
    if (type === ProjectConfigType.NoCatalogue) {
      await _bulkSendOrderRequest({
        variables: {
          params: {
            programId,
            type: ProjectConfigType.NoCatalogue,
            messageParams: {
              attachments: messageParams.attachments,
              members: messageParams.members,
              membersSearchQuery: messageParams.membersSearchQuery,
              message: messageParams.message,
              resourceId: messageParams.resourceId,
              subject: messageParams.subject,
              type: messageParams.type.toLowerCase(),
              additionalCc: messageParams.additionalCc,
            },
            members: allMembers.map((m) => ({
              member: m,
              workItemId: workItems?.find((wi) => wi?.data?.memberId === m.id)?.id,
            })),
          },
        },
      });
    }
  };

  const validMembers = allMembers ? allMembers.filter((m) => m.email) : [];
  const invalidMembers = allMembers ? allMembers.filter((m) => !m.email) : [];
  const memberIds = validMembers.map((m) => m.id);

  if (!isLoadingProjectConfig && !projectConfig) {
    closeModal();
    showMessage({
      type: 'error',
      content: 'You must complete setup before sending an order request.',
    });
    return <></>;
  }

  if (!isEmpty(invalidMembers) && showInvalidMembers) {
    const memberInfo: BasicMember[] = map(invalidMembers, (member) => ({
      first_name: getMemberShippingAddress(member)?.first_name,
      last_name: getMemberShippingAddress(member)?.last_name,
      email: member.email,
    }));
    return (
      <InvalidMembersCPS
        memberInfo={memberInfo}
        onContinue={() => setShowInvalidMembers(false)}
        canContinue={!isEmpty(validMembers)}
        emailSubject="order requests"
        requiredFields={['email']}
      />
    );
  }
  const CPSEmailComposerParams = {
    className: styles.EmailComposer,
    memberIds,
    customSend: type === ProjectConfigType.Shopify ? createShopifyOrderRequest : createOfflineOrderRequest,
    getInitialState: getInitialEmailState,
  };
  return (
    <>
      {type === ProjectConfigType.Shopify && (
        <>{!shopifyResource && <Alert type="error" message="Shopify resource not found" />}</>
      )}
      {projectConfigError && <Alert type="error" message="There was an error fetching order limitations" />}
      {(bulkSendingOrderRequestError || bulkSendShopifyOrderRequestError) && (
        <Alert type="error" message="There was an error creating order request" />
      )}
      {(isLoadingProjectConfig || bulkSendingOrderRequest || isBulkSendShopifyOrderRequestLoading) && <Skeleton />}
      <div className={styles.MessageComposer}>
        <CPSEmailComposer {...CPSEmailComposerParams} />
      </div>
    </>
  );
};
