import * as React from 'react';
import {
  Button,
  Row,
} from '@revfluence/fresh';

interface IProps {
  text: string;
  onSwitchTermsType: () => void;
}

const SwitchTermsType: React.FC<IProps> = React.memo((props) => {
  const {
    text,
    onSwitchTermsType,
  } = props;

  return (
    <Row justify="end">
      <Button onClick={onSwitchTermsType}>{text}</Button>
    </Row>
  );
});

SwitchTermsType.displayName = 'SwitchTermsType';

export default SwitchTermsType;
