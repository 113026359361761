import gql from 'graphql-tag';

import { SPECIFICATION_TEMPLATE_FRAGMENT } from './fragments';

export const GET_ALL_SPECIFICATION_TEMPLATES = gql`
  query GetAllSpecificationTemplatesQuery {
    templates: getAllSpecificationTemplates {
      ...SpecificationTemplateFragment
    }
  }
  ${SPECIFICATION_TEMPLATE_FRAGMENT}
`;
