import gql from 'graphql-tag';

export const PROGRAMS_FOR_COMMUNITY = gql`
  query ProgramsForCommunityQuery($communityId: Int!) {
    programs: programsForCommunity(communityId: $communityId) {
      id
      title
      communityId
      status
      applicationPageTemplateName
    }
  }
`;
