import * as React from 'react';

import { Typography } from 'antd';
import { Card } from '@revfluence/fresh';
import { ContentPreviewer } from '@components';
import { TContent } from '../../types';

import styles from './Preview.module.scss';

const { Title } = Typography;

export const Preview = ({ caption, previews }: TContent) => (
  <>
    <Card className={styles.Preview} bodyStyle={{ padding: '24px 24px 16px' }}>
      <div className={styles.content}>
        <Title level={5}>Content Preview:</Title>
        <ContentPreviewer contents={previews} enableDetailView />
        {caption && (
          <div className={styles.caption}>{caption}</div>
        )}
      </div>
    </Card>
  </>
  );
