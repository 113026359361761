import {
  hasAnyEmptyDeadline,
  hasAnyEndDateBeforeStartDate,
  hasApprovedAndNotReviewedContent,
  hasCompleteDateBeforeSubmissionDate,
} from '.';
import { IState } from '../types/state';
import { isInstagram } from './contentTypeUtils';

interface IValidateParams {
  clientNeedsBCAAccount: boolean;
  clientNeedsAuthForBrandedContent: boolean;
  clientNeedsAuthForPartnershipAds: boolean;
  businessAccountMissing: boolean;
  tiktokSparkAdsAccountMissing: boolean;
}

type TErrorFunction = (message: string[]) => string[];

function pipe(...args: TErrorFunction[]) {
  return (x: string[]) => args.reduce((prev, current) => current(prev), x);
}

function pushError(errorMessage: string, condition: boolean): TErrorFunction {
  return (errors) => {
    if (condition) {
      return [
        ...errors,
        errorMessage,
      ];
    }
    return errors;
  };
}

export function validateCollaborationDetails(state: IState, params: IValidateParams) {
  const {
    contentGuidelines,
    collaborationDetails: {
      adsPermissions,
      bam,
      reward: {
        payment: {
          showHideNewPrice,
          newPrice,
        },
        freeProduct: {
          description: freeProductDescription,
          showHideDescription: showHideFreeProductDescription,
        },
        commission: {
          description: commissionDescription,
          showHideDescription: showHideCommissionDescription,
        },
        noCompensation: {
          showHideDescription: showHideNoCompensation,
        },
      },
    },
    featureFlags,
  } = state;
  const {
    businessAccountMissing,
    clientNeedsBCAAccount,
    clientNeedsAuthForBrandedContent,
    clientNeedsAuthForPartnershipAds,
    tiktokSparkAdsAccountMissing,
  } = params;

  const adsPermissionsFFs = (featureFlags.MetaBrandedContent || featureFlags.InstagramPartnershipAds);
  const linkBCAAccountAlert = clientNeedsBCAAccount && (bam.toggleInfluencerBrandedContent || adsPermissionsFFs);
  const linkInsightsAlertForBrandedContent = (
    clientNeedsAuthForBrandedContent
    && !clientNeedsBCAAccount
    && !adsPermissions.requireIgPartnershipAdsAccess
  );
  const linkInsightsAlertForPartnershipAds = clientNeedsAuthForPartnershipAds && !clientNeedsBCAAccount;
  const igBCisRequired = contentGuidelines.some(
    (content) => isInstagram(content.type) && content.requiresBrandedContentTag,
  );
  const needsIgAccount = linkBCAAccountAlert && (bam.toggleInfluencerBrandedContent
    || (adsPermissions.bcPromoteAccess.toggle)
    || (adsPermissions.requireIgPartnershipAdsAccess)
    || igBCisRequired);

  const needsBcEnabled = (
    linkInsightsAlertForBrandedContent && (
      bam.toggleInfluencerBrandedContent
      || adsPermissions.bcPromoteAccess.toggle
      || igBCisRequired
    )
  ) || (
    linkInsightsAlertForPartnershipAds && adsPermissions.requireIgPartnershipAdsAccess
  );

  const needsBusinessAccount = businessAccountMissing && adsPermissions.metaBamAccess.toggle;

  const needsTiktokSparkAdsAccount = tiktokSparkAdsAccountMissing && adsPermissions.tiktokSparkAdsAccess.toggle;

  const needsTiktokSparkAdsDuration = (
    adsPermissions.tiktokSparkAdsAccess.toggle
    && !adsPermissions.tiktokSparkAdsAccess.duration
  );

  const errors = pipe(
    pushError('You need to add content guidelines', contentGuidelines.length < 1),
    pushError('You need to select the start date for Influencer Whitelist',
      bam?.toggleInfluencerWhitelist && !bam.startDateAccess),
    pushError('You need to select the end date for Influencer Whitelist',
      bam?.toggleInfluencerWhitelist && !bam.endDateAccess),
    pushError('You need to select a form of compensation',
      !(showHideNewPrice || showHideFreeProductDescription || showHideCommissionDescription || showHideNoCompensation)),
    pushError('You need to add a valid price', (showHideNewPrice && (!newPrice || newPrice <= 0))),
    pushError('You need to add a product description',
      (showHideFreeProductDescription && freeProductDescription === '')),
    pushError('You need to add a commission description',
      (showHideCommissionDescription && commissionDescription === '')),
    pushError('You have content approved but not reviewed', contentGuidelines.some(hasApprovedAndNotReviewedContent)),
    pushError('You have content without a deadline', contentGuidelines.some(
      (contentGuideline) => hasAnyEmptyDeadline(
        contentGuideline.dueDates.completeDate,
        contentGuideline.dueDates.submissionDate,
      ),
    )),
    pushError('You have content with invalid dates', contentGuidelines.some(
      (contentGuideline) => hasAnyEndDateBeforeStartDate(
        contentGuideline.dueDates.completeDate,
        contentGuideline.dueDates.submissionDate,
      ),
    )),
    pushError('You have content with invalid complete and submission dates', !contentGuidelines.every(
      (contentGuideline) => hasCompleteDateBeforeSubmissionDate(
        contentGuideline.requiresReviewBeforeSubmission,
        contentGuideline.dueDates.completeDate,
        contentGuideline.dueDates.submissionDate,
      ),
    )),
    pushError('Start date is required for Instagram Allowlisting',
      adsPermissions.metaBamAccess.toggle && !adsPermissions.metaBamAccess.startDate),
    pushError('End date is required for Instagram Allowlisting',
      adsPermissions.metaBamAccess.toggle && !adsPermissions.metaBamAccess.endDate),
    pushError('Start date is required for Branded Content',
      adsPermissions.bcPromoteAccess.toggle && !adsPermissions.bcPromoteAccess.startDate),
    pushError('End date is required for Branded Content',
      adsPermissions.bcPromoteAccess.toggle && !adsPermissions.bcPromoteAccess.endDate),
    pushError('You need to connect an Instagram account', needsIgAccount),
    pushError('You need an Instagram account with Branded Content enabled', needsBcEnabled),
    pushError('You need to connect an Meta Business account', needsBusinessAccount),
    pushError('You need to connect a TikTok Spark Ads account', needsTiktokSparkAdsAccount),
    pushError('You need to select an authorization period for TikTok Spark Ads', needsTiktokSparkAdsDuration),
  )([]);

  return errors;
}
