import { DateRangeOptions, IDateRangeSettings } from '@frontend/app/components';
import { useDateFilterSettings } from '@frontend/app/hooks';
import { endOfDay, startOfDay, subYears } from 'date-fns';
import React, { createContext, useContext, useMemo } from 'react';

export interface PFADashboardContextProps {
  dateRangeSettings: IDateRangeSettings;
}

const initialState: PFADashboardContextProps = {
  dateRangeSettings: null,
};

export const PFADashboardContext = createContext<PFADashboardContextProps>(initialState);

export const PFADashboardProvider: React.FC = ({ children }) => {
  const dateRangeSettings = useDateFilterSettings(DateRangeOptions.LAST_6_MONTHS);

  const memoizedValue = useMemo<PFADashboardContextProps>(
    () => ({
      dateRangeSettings: {
        ...dateRangeSettings,
        dateRange: {
          ...dateRangeSettings.dateRange,
          startDate: startOfDay(dateRangeSettings.dateRange?.startDate || subYears(new Date(), 10)),
          endDate: endOfDay(dateRangeSettings.dateRange?.endDate || new Date()),
        },
      },
    }),
    [dateRangeSettings],
  );

  return <PFADashboardContext.Provider value={memoizedValue}>{children}</PFADashboardContext.Provider>;
};

export const usePFADashboardContext = () => useContext(PFADashboardContext);
