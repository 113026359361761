import * as React from 'react';
import {
  Typography,
  Checkbox,
  Space,
} from '@revfluence/fresh';

const { Title, Text } = Typography;

const { useCallback } = React;

interface IProps {
  daysToApproveContent: number;
  contentGuidelineInstanceId: number;
  requiresReviewBeforeSubmission: boolean;
  requiresReviewWithinThreeDaysOfCreatorSubmission: boolean;
  onToggleReviewBeforeSubmission: (value: boolean, contentGuidelineInstanceId: number) => void;
  onToggleReviewWithinThreeDays: (value: boolean, contentGuidelineInstanceId: number) => void;
}

const ContentApproval: React.FC<IProps> = React.memo((props) => {
  const {
    daysToApproveContent,
    requiresReviewBeforeSubmission,
    requiresReviewWithinThreeDaysOfCreatorSubmission,
    onToggleReviewBeforeSubmission,
    onToggleReviewWithinThreeDays,
    contentGuidelineInstanceId,
  } = props;

  const handleReviewBeforeSubmission = useCallback((ev) => {
    onToggleReviewBeforeSubmission(ev.target.checked, contentGuidelineInstanceId);
  }, [onToggleReviewBeforeSubmission, contentGuidelineInstanceId]);

  const handleToggleReviewWithinThreeDays = useCallback((ev) => {
    onToggleReviewWithinThreeDays(ev.target.checked, contentGuidelineInstanceId);
  }, [onToggleReviewWithinThreeDays, contentGuidelineInstanceId]);

  return (
    <Space direction="vertical">
      <Title level={5}>
        Content Approval
      </Title>
      <Checkbox
        onChange={handleReviewBeforeSubmission}
        checked={requiresReviewBeforeSubmission}
      >
        Content must be submitted for review before being posted
      </Checkbox>
      {
        requiresReviewBeforeSubmission && (
          <Checkbox
            onChange={handleToggleReviewWithinThreeDays}
            checked={requiresReviewWithinThreeDaysOfCreatorSubmission}
          >
            <Text strong>Required:</Text>
            <Text>
              {' '}
              {`I agree to review content within ${daysToApproveContent} business days of the creator submitting it.`}
              {'I understand that content will automatically be approved after '
              + `${daysToApproveContent} business days if I take no action.`}
            </Text>
            <sup>*</sup>
          </Checkbox>
        )
      }
    </Space>
  );
});

ContentApproval.displayName = 'ContentApproval';

export default ContentApproval;
