import * as React from 'react';
import { format } from 'date-fns';
import { H3 } from '@frontend/shadcn/components/typography/h3';

interface IHeaderProps {
  startDate: Date;
  endDate: Date;
  clientName: string;
}

const RefreshHeader = ({
  startDate,
  endDate,
  clientName,
}: IHeaderProps) => (
  <>
    <H3 className="text-primary-foreground" fontWeight="font-medium">
      {clientName}
      {' '}
      Performance Impact
    </H3>
    <div className="text-primary-foreground text-sm">
      The impact from all projects and posts we found via social listening tags and mentions from
      <span className="font-bold text-green-2">
        {' '}
        {format(startDate, 'MMM dd, yyyy')}
        {' '}
      </span>
      to
      <span className="font-bold text-green-2">
        {' '}
        {format(endDate, 'MMM dd, yyyy')}
      </span>
      .
    </div>

  </>
);

export default RefreshHeader;
