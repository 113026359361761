import { TState } from '../types';

interface IAction {
    isLoading: boolean;
    type: 'UPDATE ISLOADING';
}
export type TUpdateIsLoadingAction = Readonly<IAction>;

export const updateIsLoading = (state: TState, action: TUpdateIsLoadingAction): TState => ({
    ...state,
    isLoading: action.isLoading,
});
