._NumberBetween_1sfzd_1 {
  width: 100%;
}
._NumberBetween_1sfzd_1 ._inputGroup_1sfzd_4 {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}
._NumberBetween_1sfzd_1 ._inputGroup_1sfzd_4 ._input_1sfzd_4 {
  display: inline-flex;
  flex: 1;
}
._NumberBetween_1sfzd_1 ._inputGroup_1sfzd_4 ._input_1sfzd_4._percentage_1sfzd_13 .ant-input-number-handler-wrap {
  display: none;
}
._NumberBetween_1sfzd_1 ._inputGroup_1sfzd_4 ._input_1sfzd_4._percentage_1sfzd_13 .ant-input-number-input-wrap {
  display: flex;
  align-items: center;
}
._NumberBetween_1sfzd_1 ._inputGroup_1sfzd_4 ._input_1sfzd_4._percentage_1sfzd_13 .ant-input-number-input-wrap::after {
  content: "%";
  position: absolute;
  right: 6px;
  color: var(--grey-75);
}
._NumberBetween_1sfzd_1 ._inputGroup_1sfzd_4 ._input_1sfzd_4._percentage_1sfzd_13 .ant-input-number-input-wrap input {
  padding-right: 24px;
}
._NumberBetween_1sfzd_1 ._inputGroup_1sfzd_4 ._and_1sfzd_29 {
  width: 50px;
  padding-right: 0;
  padding-left: 0;
  border: 0;
  background: transparent;
  text-align: center;
  pointer-events: none;
}