import gql from 'graphql-tag';

export const ASPIREX_COMMUNITIES_QUERY = gql`
  query AspireXCommunities {
    communities: aspirexCommunities {
      id
      title
      description
    }
  }
`;
