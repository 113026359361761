import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { noop } from 'lodash';
import {
 Typography, Space, Tag, Button, Input, Select, InputNumber, DatePicker,
} from '@revfluence/fresh';
import { useAuth } from '@frontend/context/authContext';
import { useFindAllContentFields } from '@frontend/app/hooks';
import { useUpdateContentReviewMutation } from '@frontend/app/hooks/useUpdateContentReview';
import { ContentFieldEnum } from '@frontend/app/types/globalTypes';
import { PencilIcon, CheckIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { ContentReviewStatusTag } from '../ContentReviewStatusTag';
import { UpdateContentReviewParams } from '../../types/globalTypes';

const { Text, Link } = Typography;

export const Info = ({
 programId, review, refetchContentReview = noop, allowEditingContentFields = false,
}) => {
  const contentFieldValues = review?.info?.raw?.content_field_values;

  const [contentFieldBeingEdited, setContentFieldBeingEdited] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const auth = useAuth();

  const { contentFields } = useFindAllContentFields({
    variables: {
      data: {
        clientId: auth?.clientInfo?.id,
        postTypes: review?.info?.raw?.accepted_post_types,
        programIds: [programId],
      },
    },
  });

  const [updateContentReview] = useUpdateContentReviewMutation();

  const handleInputChange = (id, value) => {
    setEditedValues((prevValues) => ({ ...prevValues, [id]: value }));
  };

  const handleCancelEdit = () => {
    setContentFieldBeingEdited(null);
    setEditedValues(null);
  };

  const handleSaveEdit = async () => {
    await updateContentReview({
      variables: {
        id: review?.id,
        params: {
          id: review.id,
          internalStatus: review?.internalStatus,
          isGCR: review?.isGCR,
          status: review?.status,
          info: {
            ...review.info,
            raw: {
              ...review?.info?.raw,
              content_field_values: {
                ...review?.info?.raw?.content_field_values,
                ...editedValues,
              },
            },
          },
        } as UpdateContentReviewParams,
      },
    });
    await refetchContentReview();
    setContentFieldBeingEdited(null);
  };

  const renderContentFieldValue = useCallback(
    (field, value) => {
      let displayValue = value || '-';

      if (contentFieldValues?.[`na-${field.id}`]) displayValue = 'N/A';

      // Only render this field in edit mode if it is currently being edited
      if (contentFieldBeingEdited != field.id) {
        if (displayValue === 'N/A' || displayValue === '-') return <Text>{displayValue}</Text>;

        switch (field.fieldType) {
          case ContentFieldEnum.TEXT:
          case ContentFieldEnum.NUMBER:
            return <Text>{displayValue}</Text>;

          case ContentFieldEnum.URL:
            return (
              <Link
                href={displayValue !== '-' ? (displayValue as string) : '#'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {displayValue}
              </Link>
            );

          case ContentFieldEnum.MULTIPLE_SELECT:
          case ContentFieldEnum.SINGLE_SELECT:
            return (
              <Space size="small" wrap>
                {displayValue !== '-' ? (
                  (displayValue as string[])?.map((option: string) => <Tag key={option}>{option}</Tag>)
                ) : (
                  <Text>-</Text>
                )}
              </Space>
            );

          case ContentFieldEnum.DATE:
            const date = new Date(displayValue as string);
            const formattedDate = displayValue !== '-' && !Number.isNaN(date.getTime())
                ? new Intl.DateTimeFormat('en-US').format(date)
                : '-';
            return <Text>{formattedDate}</Text>;

          default:
            return <Text>{displayValue}</Text>;
        }
      }

      // If the current field is being edited
      return (
        <Space direction="vertical" style={{ width: '100%', gap: '4px' }}>
          {field.fieldType === ContentFieldEnum.TEXT && (
            <Input
              value={editedValues?.[field.id] ?? displayValue}
              onChange={(e) => handleInputChange(field.id, e.target.value)}
              style={{ width: '100%' }}
              placeholder="Enter text"
            />
          )}

          {field.fieldType === ContentFieldEnum.SINGLE_SELECT && (
            <Select
              value={editedValues?.[field.id] ?? displayValue}
              onChange={(value) => handleInputChange(field.id, value)}
              options={field.selectOptions?.map((option) => ({
                label: option,
                value: option,
              }))}
              placeholder={`Select ${field.name}`}
              allowClear
              style={{ width: '100%' }}
            />
          )}

          {field.fieldType === ContentFieldEnum.MULTIPLE_SELECT && (
            <Select
              mode="multiple"
              value={editedValues?.[field.id] ?? displayValue}
              onChange={(value) => handleInputChange(field.id, value)}
              options={field.selectOptions?.map((option) => ({
                label: option,
                value: option,
              }))}
              style={{ width: '100%' }}
            />
          )}

          {field.fieldType === ContentFieldEnum.NUMBER && (
            <InputNumber
              value={editedValues?.[field.id] ?? displayValue}
              onChange={(value) => handleInputChange(field.id, value)}
              placeholder="Enter a number"
              style={{ width: '100%' }}
            />
          )}

          {field.fieldType === ContentFieldEnum.DATE && (
            <DatePicker
              value={editedValues?.[field.id] ? moment(editedValues[field.id] as string) : undefined}
              onChange={(date) => handleInputChange(field.id, date?.toString() ?? null)}
              style={{ width: '100%' }}
              format="MM/DD/YYYY"
            />
          )}

          {field.fieldType === ContentFieldEnum.URL && (
            <Input
              value={editedValues?.[field.id] ?? displayValue}
              onChange={(e) => handleInputChange(field.id, e.target.value)}
              type="url"
              placeholder="https://"
              style={{ width: '100%' }}
            />
          )}
        </Space>
      );
    },
    [contentFieldBeingEdited, editedValues, contentFieldValues],
  );

  const renderStatus = useCallback(() => {
    const status = review.info.raw.state;
    return <ContentReviewStatusTag key="status" state={status} />;
  }, [review]);

  const handleEditClick = useCallback((id) => {
    setEditedValues(null);
    setContentFieldBeingEdited(id);
  }, []);

  return (
    <div style={{ marginTop: '24px' }}>
      <Space size="large" direction="vertical">
        <Space size="small" direction="vertical">
          <Text strong style={{ color: 'black' }}>
            Status
          </Text>
          {renderStatus()}
        </Space>
        {contentFields
          && contentFields.map((field) => (
            <Space key={field.id} size="small" direction="vertical">
              <Text strong style={{ color: 'black' }}>
                {field.name}
              </Text>
              <Space key={field.id} size="small" direction="horizontal">
                {renderContentFieldValue(field, contentFieldValues?.[field?.id])}
                {allowEditingContentFields && review?.status != 'Approved' && (
                  <>
                    {contentFieldBeingEdited === field.id ? (
                      <>
                        <Button type="link" icon={<CheckIcon />} onClick={() => handleSaveEdit()} />
                        <Button type="link" icon={<XmarkIcon />} onClick={handleCancelEdit} />
                      </>
                    ) : (
                      <Button type="link" style={{ padding: 0 }} onClick={() => handleEditClick(field.id)}>
                        <PencilIcon />
                      </Button>
                    )}
                  </>
                )}
              </Space>
            </Space>
          ))}
      </Space>
    </div>
  );
};
