import {
    Button,
    Form,
    Input,
    Typography,
    message,
  } from '@revfluence/fresh';
  import React, { useEffect, useState } from 'react';
  import {
    filter,
    isEmpty, orderBy,
  } from 'lodash';
  import { PlusIcon } from '@revfluence/fresh-icons/regular';
  import { RuleObject } from 'antd/lib/form';
  import { useMutation } from '@apollo/client';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { TMemberTableRow } from '../MemberTable/types';
import { GetOfferById_offer } from '../../queries/types/GetOfferById';
import { mapErrToMessage, validateDeepLinkWithDomain } from '../../utils';
import styles from './CustomDeeplink.scss';
import { DisplayDeeplink } from './DisplayDeeplinks';
import { CREATE_DEEPLINK_MUTATION } from '../../queries/createDeeplinkMutation';

  const { Title } = Typography;

  interface CustomDeepLinkProps {
    memberData: TMemberTableRow;
    offer: GetOfferById_offer;
    refresh?: () => void;
    setDeeplinkDrawerVisible?: (val: boolean) => void;
  }

  export const CustomDeepLinks = ({
     memberData, offer, refresh, setDeeplinkDrawerVisible,
    }: CustomDeepLinkProps) => {
    const { additionalDeepLinks: deepLinks = [], affiliateOffer } = memberData;
    const { profile = null } = useOfferDetailsContext() || {};
    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
      if (!profile) {
        setUserInfo({
          name: '',
          email: '',
        });
      } else {
        setUserInfo({
          name: profile.name,
          email: profile.email,
        });
      }
    }, [profile]);
    const offerLink = offer?.links[0];
    const offerLinkUrl = offerLink.url;
    const offerUrl = offerLinkUrl ? new URL(offerLinkUrl) : null;
    const [expandOnCreated, setExpandOncreated] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [formDeepLinkLabel, setDeepLinkLabel] = useState<string>('');
    const [formDeepLinkUrl, setDeepLinkUrl] = useState<string>('');
    const [isUrlValidated, setUrlValidated] = useState(false);
    const finalDeepLinks = filter(
      orderBy(deepLinks, [(deepLink) => new Date(deepLink.createdDate)]),
      (deepLink) => !deepLink.deletedDate,
    );

    useEffect(() => {
      setExpandOncreated(false);
    }, [showCreateForm, formDeepLinkLabel, formDeepLinkUrl]);

    const [createDeeplinkMutation, { loading }] = useMutation(CREATE_DEEPLINK_MUTATION);

    const onCreateDeepLinkClick = async () => {
      if (isEmpty(formDeepLinkLabel) || isEmpty(formDeepLinkUrl)) {
        return;
      }
      const deepLinkCreationObject = {
        affiliateOfferLinkId: affiliateOffer.id ? affiliateOffer.id : -1,
        deepLinkLabel: formDeepLinkLabel,
        deepLinkUrl: formDeepLinkUrl,
        userInfo,
        isBrandCreated: true,
      };
      try {
        await createDeeplinkMutation({
          variables: { data: deepLinkCreationObject },
          onCompleted() {
            setShowCreateForm(false);
            setExpandOncreated(true);
            setDeeplinkDrawerVisible(false);
            if (refresh) {
              refresh();
            }
            message.success('Link created successfully. It may take some time to reflect.', 5);
          },
        });
      } catch (error) {
        message.error(mapErrToMessage(error.message));
      }
    };

    const validationError = new Error(
      'To ensure proper tracking is set up for your deep link,'
      + ` please ensure the link begins with {${offerUrl?.protocol}//${offerUrl?.hostname}${
          offerLink ? `,${offerLink.domains?.join(', ')}}` : ''
        }`,
    );

    const validateDeepLinkUrl = (_: RuleObject, value: string) => {
      if (!value.length) {
        return Promise.resolve();
      }

      const result = validateDeepLinkWithDomain(value, offerUrl, offerLink.domains, offerLink.isCreatorDeepLinkEnabled);

      if (result.isValid) {
        setUrlValidated(true);
        return Promise.resolve();
      }
      setUrlValidated(false);
      return Promise.reject(validationError);
    };

    const customLinkForm = (
      <div className="">
        <Form layout="vertical" className="border rounded">
          <div className="p-4">
            <div className="mb-4">
              <Form.Item
                name="customLabel"
                label="Name Your Link"
                rules={[{ required: true, message: 'Please Enter Link Name' }]}
              >
                <Input
                  className={styles.styledInput}
                  placeholder="Label"
                  onChange={(event) => {
                    setDeepLinkLabel(event.target.value);
                  }}
                  value={formDeepLinkLabel}
                />
              </Form.Item>
            </div>
            <div className="mb-4">
              <Form.Item
                name="url"
                label="Deep Link Url"
                rules={[{ required: true, message: 'Please Enter URL' }, { validator: validateDeepLinkUrl }]}
              >
                <Input
                  className={styles.styledInput}
                  placeholder="Custom URL"
                  onChange={(event) => {
                    setDeepLinkUrl(event.target.value);
                  }}
                  value={formDeepLinkUrl}
                />
              </Form.Item>
            </div>

            <div className={styles.styledButtonGroup}>
              <Button
                type="text"
                onClick={() => {
                  setShowCreateForm(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                loading={loading}
                onClick={onCreateDeepLinkClick}
                htmlType="submit"
                disabled={!isUrlValidated || isEmpty(formDeepLinkLabel) || isEmpty(formDeepLinkUrl)}
              >
                Generate Link
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );

    const onAddClick = () => {
      setShowCreateForm(true);
    };

    const CustomDeepHeadContainer = () => (
      <div className="flex flex-row justify-between mb-4">
        <Title level={5}>Manage Member Links</Title>
        {!offer.archivedDate && (
          <Button onClick={onAddClick} disabled={showCreateForm} icon={<PlusIcon />}>
            Add
          </Button>
        )}
      </div>
    );
    return (
      <div>
        <CustomDeepHeadContainer />
        {showCreateForm && customLinkForm}
        <div>
          <DisplayDeeplink
            primaryShortlink={memberData.affiliateShortLink}
            affiliateOfferLinkId={affiliateOffer.id}
            deepLinks={finalDeepLinks}
            expandOnCreated={expandOnCreated}
            offerLinkUrl={offerLinkUrl}
            domains={offerLink.domains}
            primaryDeeplinkUrl={memberData.affiliateLinkAbbrev.defaultDeeplink}
            primaryCreationDate={memberData.linkCreationDate}
            refresh={refresh}
            setDeeplinkDrawerVisible={setDeeplinkDrawerVisible}
            isOfferArchived={!!offer.archivedDate}
          />
        </div>
      </div>
    );
  };
