import * as React from 'react';
import cx from 'classnames';
import { range } from 'lodash';
import { KeyboardArrowLeftIcon } from '@components';

const { useState } = React;

import styles from './Pagination.scss';

interface IProps {
  className: string;
  currentPage: number;
  startPage: number;
  endPage: number;
  pagesDisplayed?: number;
  onSelectPage?(pageNumber: number);
}

export const Pagination = React.forwardRef<HTMLDivElement, IProps>(
  (props, forwardedRef: React.RefObject<HTMLDivElement>) => {
    const dp = props.currentPage - props.startPage;
    const initialDisplayedPageStart = props.startPage + dp - (dp % props.pagesDisplayed);
    const [displayedPageStart, setDisplayedPageStart] = useState(initialDisplayedPageStart);

    const handleClickItem = (idx: number) => {
      if (props.onSelectPage) {
        props.onSelectPage(idx);
      }
    };

    const displayedPageEnd = Math.min(displayedPageStart + props.pagesDisplayed, props.endPage + 1);

    const canGoBack = displayedPageStart >= props.startPage + props.pagesDisplayed;
    const canGoForward = displayedPageStart <= props.endPage - props.pagesDisplayed;

    const handleClickPrevDisplayedPages = () => {
      if (canGoBack) {
        setDisplayedPageStart(displayedPageStart - props.pagesDisplayed);
      }
    };

    const handleClickNextDisplayedPages = () => {
      if (canGoForward) {
        setDisplayedPageStart(displayedPageStart + props.pagesDisplayed);
      }
    };

    return (
      <div ref={forwardedRef} className={cx(styles.Pagination, props.className)}>
        <div className={styles.title}>
          Page
          {' '}
          {props.currentPage}
          {' '}
          of
          {' '}
          {props.endPage}
        </div>
        <div>
          {canGoBack && (
            <div className={styles.item} onClick={handleClickPrevDisplayedPages}>
              <KeyboardArrowLeftIcon size={16} />
            </div>
          )}
          {range(displayedPageStart, displayedPageEnd).map((idx) => (
            <div
              key={idx}
              className={cx(styles.item, { [styles.selected]: props.currentPage === idx })}
              onClick={() => handleClickItem(idx)}
            >
              {idx}
            </div>
          ))}
          {canGoForward && (
            <div className={styles.item} onClick={handleClickNextDisplayedPages}>
              <KeyboardArrowLeftIcon className={styles.rightArrow} size={16} />
            </div>
          )}
        </div>
        <div className={styles.emptySpacer} />
      </div>
    );
  },
);

Pagination.defaultProps = {
  pagesDisplayed: 10,
};
Pagination.displayName = 'Pagination';
