import React, { useEffect, useState } from 'react';
import { lowerCase, map } from 'lodash';
import { Tag } from '@revfluence/fresh';
import styles from './CreatorProfile.scss';

interface ITag {
    tagName?: string;
    tagColor?: string;
}

const Tags = ({ tags, eligibleTags = [] }) => {
    const [tagsToShow, setTagsToShow] = useState<ITag[]>([]);
    useEffect(() => {
        const tagSet = new Set(map(tags, (tag) => lowerCase(tag)));
        const tag_ar = [];
        map(eligibleTags, (tagRule) => {
            if (tagSet.has(lowerCase(tagRule.tagName))) {
                tag_ar.push(tagRule);
            }
        });
        setTagsToShow(tag_ar);
    }, [tags, eligibleTags]);
    return (
        tagsToShow.length
            ? (
              <div className={styles.tags}>
                {
                    map(tagsToShow, (tag) => <Tag color={tag.tagColor}>{tag.tagName}</Tag>)
                }
              </div>
)
        : null
    );
};

export default Tags;
