import * as React from 'react';
import {
 filter, flatten, get, has, isEmpty, indexOf, keys, uniq, first,
} from 'lodash';

import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';
import { IMemberProgram, IMemberProgramMap, TProgram } from '@components';
import { IResource } from '@components';
import { FetchContextProvider } from '@frontend/utils';
import { ISocialAccount } from '@components';
import { INetworkSearchVersion } from '@frontend/applications/SocialDiscoveryApp/pages/InfluencerSearchPage';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useFetchSocialAccountData } from '@frontend/applications/SocialPostApp/useFetchSocialAccountData';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { uniqueMemberPrograms } from '../utils';

const {
 createContext, useContext, useEffect, useState,
} = React;

interface IResourceContext {
  resourceId: IResource['id'];
  onRequestAddEmail?(type: TEmailType);
}

type TEmailType = 'gmail' | 'outlook';

export interface IInviteContext {
  apiEndpoint: string;
  clientId: string;
  userId: string;
  resourceContext: IResourceContext;
  memberPrograms: IMemberProgramMap;
  selectedProgramId: TProgram['id'];
  fetchingSocialAccountIds: number[];
  updateResourceContext: React.Dispatch<React.SetStateAction<IResourceContext>>;
  updateMemberPrograms: (socialAccountId: number, programs: IMemberProgram[]) => void;
  updateSelectedProgramId: React.Dispatch<React.SetStateAction<TProgram['id']>>;
  updateFetchingSocialAccountIds: (...socialAccountIds: number[]) => void;
  updatedSelectedSocialAccounts: (socialAccounts: ISocialAccount[]) => void;
  isWorkflowEnabled?: boolean;
  selectedSocialAccounts: ISocialAccount[];
  version: CreatorSearchVersion;
  versionByNetwork: INetworkSearchVersion;
  instagramAccounts: ISocialAccount[];
  isFetchingInstagramAccounts: boolean;
  selectedInstagramAccountId: ISocialAccount['id'] | undefined;
  setSelectedInstagramAccountId: (accountId: ISocialAccount['id']) => void;
}

export const InviteContext = createContext<IInviteContext>({} as IInviteContext);

interface IProps {
  apiEndpoint?: string;
  clientId: string;
  userId: string;
  onRequestAddEmail?(type: TEmailType);
  isWorkflowEnabled?: boolean;
  initialSelectedProjectId?: TProgram['id'];
  version?: CreatorSearchVersion;
  versionByNetwork?: INetworkSearchVersion;
}

export const InviteContextProvider: React.FC<IProps> = (props) => {
  const {
    version = CreatorSearchVersion.v1,
    versionByNetwork = {} as INetworkSearchVersion,
  } = props;

  const isInstagramDMInviteEnabled = useClientFeatureEnabled(ClientFeature.INSTAGRAM_DM);

  const [resourceContext, setResourceContext] = useState<IResourceContext>({
    resourceId: null,
    onRequestAddEmail: props.onRequestAddEmail,
  });
  const [fetchingSocialAccountIds, setFetchingSocialAccountIds] = useState<number[]>([]);
  const [memberPrograms, setMemberPrograms] = useState<IMemberProgramMap>({});
  const [selectedProgramId, setSelectedProgramId] = useState<TProgram['id']>();
  const [selectedSocialAccounts, setSelectedSocialAccounts] = useState<ISocialAccount[]>([]);
  const [selectedInstagramAccountId, setSelectedInstagramAccountId] = useState<ISocialAccount['id']>();

  const { backendServerApiEndpoint, clientId } = useApplication();

  const { loading: isFetchingInstagramAccounts, data: instagramAccounts } = useFetchSocialAccountData({
    url: `${backendServerApiEndpoint}/social_account`,
    clientId,
    username: null,
    fetchAll: true,
  });

  useEffect(() => {
    if (
      !selectedInstagramAccountId
        && !isFetchingInstagramAccounts
        && !isEmpty(instagramAccounts)
    ) {
      setSelectedInstagramAccountId(first(instagramAccounts).id);
    }
  }, [instagramAccounts, isFetchingInstagramAccounts, selectedInstagramAccountId]);

  // Remove account IDs in `fetchingSocialAccountIds` when `memberPrograms` is updated
  const socialAccountIds = keys(memberPrograms).sort().join();
  useEffect(() => {
    if (!isEmpty(fetchingSocialAccountIds) && !isEmpty(memberPrograms)) {
      setFetchingSocialAccountIds((accountIds) => filter(
        accountIds,
        (accountId) => !has(memberPrograms, accountId),
      ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialAccountIds, memberPrograms]);

  useEffect(() => (
    props.initialSelectedProjectId && setSelectedProgramId(props.initialSelectedProjectId)
  ), [props.initialSelectedProjectId]);

  const value: IInviteContext = {
    apiEndpoint: props.apiEndpoint || '/api',
    clientId: props.clientId,
    userId: props.userId,
    isWorkflowEnabled: props.isWorkflowEnabled,
    resourceContext,
    memberPrograms,
    selectedProgramId,
    fetchingSocialAccountIds,
    selectedSocialAccounts,
    version,
    versionByNetwork,
    updateResourceContext: setResourceContext,
    updateMemberPrograms: (socialAccountId: number, programs: IMemberProgram[]) => {
      setMemberPrograms((currentMemberPrograms) => ({
        ...currentMemberPrograms,
        [socialAccountId]: uniqueMemberPrograms(
          ...programs,
          ...get(currentMemberPrograms, socialAccountId, []),
        ),
      }));
    },
    updatedSelectedSocialAccounts: (socialAccounts: ISocialAccount[]) => {
      setSelectedSocialAccounts(flatten(socialAccounts));
    },
    updateFetchingSocialAccountIds: (...socialAccountIds: number[]) => {
      const flattenedAccountIds = flatten(socialAccountIds);

      if (flattenedAccountIds.length > 0) {
        const filteredAccountIds = filter(flattenedAccountIds, (socialAccountId) => (
          !has(memberPrograms, socialAccountId)
          && indexOf(fetchingSocialAccountIds, socialAccountId) < 0
        ));
        if (filteredAccountIds.length > 0) {
          setFetchingSocialAccountIds((accountIds) => uniq([...accountIds, ...filteredAccountIds]));
        }
      }
    },
    updateSelectedProgramId: setSelectedProgramId,
    selectedInstagramAccountId,
    setSelectedInstagramAccountId,
    instagramAccounts: isInstagramDMInviteEnabled ? instagramAccounts : [],
    isFetchingInstagramAccounts,
  };

  return (
    <FetchContextProvider>
      <InviteContext.Provider value={value}>
        {props.children}
      </InviteContext.Provider>
    </FetchContextProvider>
  );
};

export const useInviteContext = () => useContext(InviteContext);
