import * as React from 'react';
import { find, upperCase } from 'lodash';

import { ResourceType, MessageType } from '@frontend/app/types/globalTypes';
import {
  GetThreadQuery_thread as IThread,
} from '@frontend/app/queries/types/GetThreadQuery';

import {
  GetThreadsQuery_threads as IThreads,
} from '@frontend/app/queries/types/GetThreadsQuery';

import { InstagramIcon } from '@revfluence/fresh-icons/brands/esm';
import { UserGroupIcon, UserIcon } from '@revfluence/fresh-icons/regular/esm';
import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import { GetResourcesQuery_resources } from '@frontend/app/queries/types/GetResourcesQuery';

const AVAILABLE_EMAILS = [ResourceType.GMAIL, ResourceType.OUTLOOK];

export function getThreadIcon(resourceType: ResourceType, isIndividual?: boolean) {
  if (AVAILABLE_EMAILS.includes(resourceType)) {
    return isIndividual ? <UserIcon /> : <UserGroupIcon />;
  }

  return <InstagramIcon />;
}

export function getThreadDisplayName(name: string, resourceType: ResourceType) {
  if (resourceType === ResourceType.IGDM) return `@${name}`;
  return name;
}

export function getResourceDisplayName(
  resource: GetResourcesQuery_resources,
  users: GetAllUsersQuery_users[] = [],
) {
  const user = find(users, ['email', resource.authProvider.userExternalDisplayId]);

  return user?.name || getThreadDisplayName(resource.authProvider.userExternalDisplayId, resource.type);
}

export function isEmailThread(thread: IThread) {
  if (!thread) return false;
  return [MessageType.GMAIL, MessageType.OUTLOOK].includes(thread.type);
}

/**
 * thread was assigned IThread or IThreads because it was used in different
 * locations where thread is either one of those types
 */
export function isIGDMThread(thread: IThread | IThreads) {
  if (!thread) return false;
  return thread.type === MessageType.IGDM;
}

export const getMemberInitials = (member: IThread['members'][number]) =>
  (member.name || member.email)
    .split(' ')
    .slice(0, 2)
    .map((letter) => upperCase(letter.substring(0, 1)))
    .join('');
