import * as React from 'react';

import { Typography } from '@revfluence/fresh';

import styles from './EmptyState.scss';

const { Text } = Typography;

interface IEmptyStateProps {
  icon: React.ComponentType<{ className: string }>;
  title: string;
  description: string;
  actionComponent?: React.ReactNode;
}

export const EmptyState: React.FC<IEmptyStateProps> = React.memo(({
 icon: Icon, title, description, actionComponent,
}) => (
  <div className={styles.EmptyState}>

    <Icon className={styles.emptyIcon} />
    <Text strong type="secondary" className={styles.emptyTitle}>
      {title}
    </Text>
    <Text type="secondary">
      {description}
    </Text>
    {actionComponent}
  </div>
  ));

EmptyState.displayName = 'ActivationItem';
