import { useMemo } from 'react';

export const useAllSegmentId = (
  communityId?: number,
  sourcingGroupId?: string,
) => useMemo(() => {
    if (sourcingGroupId) {
      return `source_${sourcingGroupId}`;
    } else if (communityId) {
      return `all_members_${communityId}`;
    } else {
      return 'all_members';
    }
  }, [communityId, sourcingGroupId]);
