import * as React from 'react';

import { TMediaType } from '@components';

export interface TContentTableColumn {
  [key: string]:
  | string
  | ((text: string, record: TContentTableRow) => React.ReactElement)
  | ((a: TContentTableRow, b: TContentTableRow) => number)
  | React.ReactNode
  | React.ReactElement;
}
export interface TContentDeliverable {
  type: TMediaType;
  url: string;
}
export interface IMemberData{
  name: string;
  creatorId: number;
  creatorProfilePicture?: string;
}
export interface TContentTableRow {
  id: string | number;
  key: string | number;
  name: string;
  date: string;
  avatar?: string;
  totalContent?: number;
  totalDeliverables?: number;
  totalComments?: number;
  lastCommentDate?: string;
  lastActionDateTime?: Date;
  lastReviewer?: string;
  instagram?: string;
  tiktok?: string;
  content?: {
    icon: React.ReactElement;
    description: string;
    link: string;
    deliverables: TContentDeliverable[];
    onClick(): void;
  };
  statusTags?: React.ReactNode | React.ReactNode[];
  children?: TContentTableRow[];
  memberData?: IMemberData;
}
export interface TContentToolbarCounter {
  id: string;
  name: string;
  count: number;

  selected?: boolean;
  onClick?(): void;
}
export interface TContentTable {
  columns: TContentTableColumn[];
  rows: TContentTableRow[];
  counters?: TContentToolbarCounter[];
  searchText?: string;
  onSearchTextChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
