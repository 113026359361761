import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_ALL_PROJECTS } from '@frontend/app/queries';

import { GetAllProjectsQuery, GetAllProjectsQueryVariables } from '@frontend/app/queries/types/GetAllProjectsQuery';

type IOptions = QueryHookOptions<GetAllProjectsQuery, GetAllProjectsQueryVariables>;

export const useClientProjects = (options: IOptions = {}) =>
  useQuery<GetAllProjectsQuery, GetAllProjectsQueryVariables>(GET_ALL_PROJECTS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
