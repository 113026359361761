export interface IContentGuideline {
  text: string;
}

export interface IContentGuidelineSection {
  header: string;
  guidelineSuggestion: string;
}

export interface IGuidelinePosition {
  sectionIndex: number;
  listIndex: number;
}
