import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { UPDATE_TIKTOK_SETTINGS_AD_ACCOUNT } from '@frontend/app/queries';
import {
  UpdateTikTokSettingsAdAccountMutation,
  UpdateTikTokSettingsAdAccountMutationVariables,
} from '@frontend/app/queries/types/UpdateTikTokSettingsAdAccountMutation';

type IOptions = MutationHookOptions<
  UpdateTikTokSettingsAdAccountMutation,
  UpdateTikTokSettingsAdAccountMutationVariables
>;

export const useTikTokSettingsUpdateAdAccountMutation = (options: IOptions = {}) => (
  useMutation<
    UpdateTikTokSettingsAdAccountMutation,
    UpdateTikTokSettingsAdAccountMutationVariables
  >(UPDATE_TIKTOK_SETTINGS_AD_ACCOUNT, options)
);
