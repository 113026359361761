/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_e63t4_157 {
  display: flex;
}

._justify-content-space-between_e63t4_161 {
  justify-content: space-between;
}

._tabular-nums_e63t4_165 {
  font-variant-numeric: tabular-nums;
}

._Textarea_e63t4_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_e63t4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Textarea_e63t4_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Textarea_e63t4_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Textarea_e63t4_169 ::-webkit-scrollbar-track, ._Textarea_e63t4_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Textarea_e63t4_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Textarea_e63t4_169 {
  padding: 8px 14px;
  overflow-y: overlay;
  background-color: #eff5f9;
  outline: none;
  border: none;
  border-radius: 10px;
  will-change: border-color, background-color;
  transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Textarea_e63t4_169._focus_e63t4_292 {
  background-color: #e6edf2;
}
._Textarea_e63t4_169:hover {
  background-color: #e6edf2;
}
._Textarea_e63t4_169 ._textarea_e63t4_298 {
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: none;
  resize: none;
  font-size: 15px;
  line-height: 22px;
}