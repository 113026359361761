import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ORDERS_QUERY } from '../queries';
import {
  GetOrdersQuery,
  GetOrdersQueryVariables,
  GetOrdersQuery_orders,
} from '../queries/types/GetOrdersQuery';

type IOptions = QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>;
export type IOrder = GetOrdersQuery_orders;

export function useGetOrders(options: IOptions = {}) {
  const {
 loading, data: { orders = [] } = {}, error, refetch,
} = useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GET_ORDERS_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    orders,
    error,
    refetch,
  };
}
