import * as React from 'react';
import { Tag } from '@revfluence/fresh';

interface IInactiveTextProps {
  className?: string;
}

export const InactiveText = (props: IInactiveTextProps) => {
  const { className } = props;
  return (
    <Tag color="default" className={className}>Inactive</Tag>
  );
};
