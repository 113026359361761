import * as React from 'react';
import * as qs from 'querystring';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { IPaymentSources } from './models';

const {
 useState, useEffect, useMemo, useCallback,
} = React;

interface IPaymentSourceFetchParams {
  client_id: string;
}

const fetchPaymentSourceData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

interface UseFetchPaymentSourceDataParams {
  backendServerApiEndpoint?: string;
  clientId?: string;
}

export function useFetchPaymentSourceData({
  backendServerApiEndpoint: paramBackendServerApiEndpoint,
  clientId: paramClientId,
}: UseFetchPaymentSourceDataParams = {}) {
  // Provide default values if useApplication returns null
  const { backendServerApiEndpoint: contextBackendServerApiEndpoint, clientId: contextClientId } = useApplication() || {};

  const backendServerApiEndpoint = paramBackendServerApiEndpoint || contextBackendServerApiEndpoint;
  const clientId = paramClientId || contextClientId;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaymentSources>(null);
  const [error, setError] = useState<Error>(null);

  const finalUrl = useMemo(() => {
    if (!backendServerApiEndpoint || !clientId) return null;

    const url = `${backendServerApiEndpoint}/payment_source`;

    const params: IPaymentSourceFetchParams = {
      client_id: clientId,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterString = qs.stringify(params as any);

    return `${url}?${filterString}`;
  }, [backendServerApiEndpoint, clientId]);

  const fetchPaymentSources = useCallback(async () => {
    if (!finalUrl) return;

    setLoading(true);

    try {
      const result = await fetchPaymentSourceData(finalUrl);
      setData(result);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  }, [finalUrl]);

  useEffect(() => {
    if (finalUrl) {
      fetchPaymentSources();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalUrl]);

  return {
    loading,
    data,
    error,
    refetch: fetchPaymentSources,
  };
}
