import { Space, Typography } from '@revfluence/fresh';
import React from 'react';
import { BoxesStackedIcon, CloudArrowDownIcon, GearIcon } from '@revfluence/fresh-icons/regular/esm';
import { useProductsContext } from '../ProductsContext';
import { SettingsCard } from './SettingsCard';

const { Text } = Typography;

export const RootScreen = () => {
  const { setImportSettingScreen } = useProductsContext();

  return (
    <Space direction="vertical" size="large">
      <Text>Select the settings you wish to define for product imports.</Text>
      <Space direction="vertical">
        <SettingsCard
          Icon={CloudArrowDownIcon}
          title="Product Metadata Settings"
          subtitle="Set default data fields to be imported for each product, ensuring consistency across your product catalog."
          onClick={() => setImportSettingScreen('metadata')}
        />
        <SettingsCard
          Icon={GearIcon}
          title="Sync & Data Import Preferences"
          subtitle="Access advanced settings to manage automatic syncing and product data customization."
          onClick={() => setImportSettingScreen('sync')}
        />
        <SettingsCard
          Icon={BoxesStackedIcon}
          title="Inventory Control & Management"
          subtitle="Configure rules for stock levels to optimize your inventory control and avoid overselling."
          onClick={() => setImportSettingScreen('inventory')}
        />
      </Space>
    </Space>
  );
};
