import * as React from 'react';
import {
  Button, Popover, Input, TPopoverAnchorOrigin,
  TPopoverArrowPosition, SpinnerIcon,
} from '@components';

import { useMessagingContext } from '@frontend/hooks';
import { useSaveSegmentFolderMutation } from '@frontend/app/hooks';
import { SegmentFolderInput } from '@frontend/app/types/globalTypes';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import styles from './NewFolderForm.scss';

const { useState } = React;

interface IProps {
  communityId: number;
  sourcingGroupId: string;
  show: boolean;
  header?: string;
  folder: SegmentFolderInput;
  mountRef: React.RefObject<HTMLElement>;
  anchorOrigin?: TPopoverAnchorOrigin;
  arrowPosition?: TPopoverArrowPosition;
  onRequestClose();
}

export const NewFolderForm: React.FunctionComponent<IProps> = React.memo((props) => {
  const [folderName, setFolderName] = useState<string>(props.folder.title);

  const {
    showSuccessMessage,
  } = useMessagingContext();

  const resetAndCloseForm = () => {
    props.onRequestClose();

    if (!props.folder.id) {
      setFolderName('');
    }
  };

  const [saveSegmentFolder, {
    loading: isSaving,
  }] = useSaveSegmentFolderMutation({
    onCompleted: (result) => {
      showSuccessMessage(`Successfully created group ${result.folder.title}`);
      resetAndCloseForm();
    },
  });

  const addEvent = useEventContext();
  const handleClickSave = () => {
    if (isSaving) {
      return;
    }

    if (props.folder.title === folderName) {
      resetAndCloseForm();
      return;
    }

    saveSegmentFolder({
      variables: {
        segmentFolder: {
          id: props.folder.id,
          title: folderName,
          communityId: props.communityId,
          sourceGroup: props.sourcingGroupId,
        },
      },
    });
    addEvent(EventName.CreateSegmentGroup, {});
  };

  return (
    <Popover
      mountRef={props.mountRef}
      show={props.show}
      onRequestClose={props.onRequestClose}
      minWidth={220}
      anchorOrigin={props.anchorOrigin}
      arrowPosition={props.arrowPosition}
    >
      <div className={styles.NewFolderForm}>
        <div className={styles.label}>{props.header}</div>
        <Input
          value={folderName}
          onChange={setFolderName}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          className={(styles as any).field}
          onPressEnter={handleClickSave}
          blurOnEnter
        />
        <Button
          label="Save"
          className={styles.saveBtn}
          onClick={handleClickSave}
          disabled={isSaving}
          icon={isSaving && <SpinnerIcon size={18} />}
        />
      </div>
    </Popover>
  );
});

NewFolderForm.defaultProps = {
  header: 'Name your Filter Group:',
  anchorOrigin: 'end',
  arrowPosition: 'middle',
};
