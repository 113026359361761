import * as React from 'react';
import cx from 'classnames';

import {
  ITooltipProps,
  Tooltip,
} from '@components';

interface IProps {
  className?: string;
  icon: JSX.Element;
  tooltipProps?: ITooltipProps;
  tooltipText: string | JSX.Element;
}

const { useRef } = React;
import styles from './TooltipIcon.scss';

/**
 * @type {React.FunctionComponent}
 */
export const TooltipIcon: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    className,
    icon,
    tooltipProps,
    tooltipText,
  } = props;
  const iconWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className={cx(styles.TooltipIcon, className)}
        ref={iconWrapperRef}
      >
        {icon}
      </div>
      <Tooltip
        mountRef={iconWrapperRef}
        tooltipColor="black"
        maxWidth={380}
        {...tooltipProps}
      >
        {tooltipText}
      </Tooltip>
    </>
  );
});

TooltipIcon.displayName = 'TooltipIcon';
