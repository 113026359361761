import * as React from 'react';
import cx from 'classnames';
import {
  join, isEmpty, upperFirst,
 } from 'lodash';
import { Button } from 'antd';
import { Switch, EllipsisLabel, TextInput } from '@frontend/app/components';
import {
  getLabelForFieldType,
} from '@frontend/app/containers/Communities/AddOrEditCommunity/CommunityIdentity/utils';
import { Checkbox, EditIcon } from '@components';

import styles from './Field.scss';

const { useState } = React;
export interface IEditFields {
  label?: string;
  required?: boolean;
  active?: boolean;
  order?: number;
}
interface IProps {
  name: string;
  label: string;
  type: string;
  choices?: string[];
  active: boolean;
  required?: boolean;
  disableRequired?: boolean;
  disableSwitch?: boolean;
  onToggleActive?: (active: boolean) => void;
  onEdit?: (fields: IEditFields) => void;
  className?: string;
  editable?: boolean;
}

export const Field: React.FC<IProps> = (props) => {
  const {
    name,
    label,
    type,
    choices,
    active,
    required,
    editable,
    disableRequired,
    disableSwitch,
    onToggleActive,
    onEdit,
    className,
  } = props;

  const [editLabel, setEditLabel] = useState(label);
  const [editRequired, setEditRequired] = useState(required);
  const [isEditing, setIsEditing] = useState(false);

  const handleLabelClick = () => {
    if (editable) {
      setIsEditing(true);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditLabel(label);
    setEditRequired(required);
  };

  const handleSubmit = () => {
    onEdit({
      label: editLabel,
      required: editRequired,
      active: true,
    });

    setIsEditing(false);
  };

  const renderLabelBlock = () => (
    <div
      className={cx(
          styles.labelContainer,
          {
            [styles.editable]: editable,
          },
        )}
      onClick={handleLabelClick}
    >
      <EllipsisLabel
        className={cx(
            styles.label,
            {
              [styles.isRequired]: required && editable,
            },
          )}
      >
        {upperFirst(name)}
      </EllipsisLabel>
      <EditIcon
        className={styles.editIcon}
        size={14}
      />
    </div>
    );

  const renderDisplayBlock = () => (
    <>
      <div className={styles.header}>
        {renderLabelBlock()}
        <div className={styles.right}>
          <div className={styles.type}>{getLabelForFieldType(type, !isEmpty(choices))}</div>
          <Switch
            checked={active}
            disabled={disableSwitch}
            onChange={onToggleActive}
          />
        </div>
      </div>
      {!isEmpty(choices) && (
      <div className={styles.choices}>
        {join(choices, ', ')}
      </div>
        )}
    </>
  );

  const renderEditBlock = () => {
    const hasError = !editLabel;

    return (
      <div className={styles.editBlock}>

        <div
          className={styles.inputContainer}
        >
          <div className={styles.inputLabel}>
            Field title
          </div>
          <TextInput
            placeholder="Field title"
            value={editLabel}
            className={cx({
              [styles.error]: hasError,
            })}
            onChange={(e) => setEditLabel(e.target.value)}
          />
          <div
            className={styles.checkboxContainer}
          >
            <Checkbox
              checked={editRequired}
              disabled={disableRequired}
              onChange={setEditRequired}
            />
            <div className={styles.checkboxLabel}>
              Required question
            </div>
          </div>
        </div>

        <div
          className={styles.editBtns}
        >
          <Button
            type="text"
            onClick={handleCancelEdit}
            className={styles.cancelBtn}
          >
            Cancel
          </Button>

          <Button
            type="link"
            onClick={handleSubmit}
            disabled={hasError}
          >
            Save
          </Button>
        </div>

      </div>
    );
  };

  return (
    <div
      className={cx(
        styles.Field,
        className,
        {
          [styles.isEditing]: isEditing,
        },
      )}
    >
      {
        isEditing
          ? renderEditBlock()
          : renderDisplayBlock()
      }
    </div>
  );
};
