import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import * as React from 'react';

import '@revfluence/fresh/dist/aui.min.css';
import 'simplebar/dist/simplebar.min.css';
import 'reactflow/dist/style.css';

import { OverlaySpinner } from '@components';
import { Home, GuestHome, SettingsContextProvider } from '@frontend/app/containers';
import { useAuth } from '@frontend/context/authContext';
import { ClientContextProvider, ResourceProvider, ProjectContextProvider } from '@frontend/app/context';
import { EventProvider } from '@frontend/app/context/EventContext';
import { useAppContext } from '@frontend/context/AppContext';

import { Toaster } from '@frontend/shadcn/components/ui/toaster';
import { LoginView } from './LoginView';

import styles from './App.scss';
/**
 * @type {React.FunctionComponent}
 */
export const App: React.FunctionComponent = React.memo(() => {
  const { isGuest, loading, isAuthenticated } = useAuth();
  const { apolloClient } = useAppContext();

  return (
    <div className={styles.App}>
      <Toaster />
      {loading && <OverlaySpinner />}
      {!loading && !isAuthenticated && <LoginView />}
      {apolloClient && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        <ApolloHooksProvider client={apolloClient}>
          <ClientContextProvider>
            <ResourceProvider>
              <EventProvider>
                <SettingsContextProvider>
                  <ProjectContextProvider>
                    {!isGuest && <Home />}
                    {isGuest && <GuestHome />}
                  </ProjectContextProvider>
                </SettingsContextProvider>
              </EventProvider>
            </ResourceProvider>
          </ClientContextProvider>
        </ApolloHooksProvider>
      )}
    </div>
  );
});

App.displayName = 'App';
