import * as React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

import { useHistory, useParams } from 'react-router-dom';

import {
  MemberListContextProvider,
} from '@frontend/app/context/MemberListContext';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { NewMemberDetailContainer } from './NewMemberDetailContainer';

import styles from './MemberDetailPage.scss';

interface IProps {
  className?: string;
}
interface IMatchParams {
  memberId: string;
}

/**
 * @type {React.FunctionComponent}
 */
const MemberDetailPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const { memberId } = useParams<IMatchParams>();
  const addEvent = useEventContext();
  addEvent(EventName.MemberDetailView, {});
  const history = useHistory();

  const handleBack = () => {
    const backUrl = localStorage.getItem('MEMBER_PAGE_BACK_URL');
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
  };

  return (
    <div className={cx(styles.MemberDetailPage, props.className)}>
      <Helmet title="Member Detail" />
      <NewMemberDetailContainer key={memberId} memberId={parseInt(memberId, 10)} onBackButtonClicked={handleBack} isDeliverablesHidden />
    </div>
  );
});

const MemberDetailPageWrapper: React.FunctionComponent<IProps> = React.memo((props) => (
  <MemberListContextProvider>
    <MemberDetailPage {...props} />
  </MemberListContextProvider>
  ));

MemberDetailPage.displayName = 'MemberDetailPage';

MemberDetailPageWrapper.displayName = 'MemberDetailPageWrapper';

export default MemberDetailPageWrapper;
