/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_94iv3_157 {
  display: flex;
}

._justify-content-space-between_94iv3_161 {
  justify-content: space-between;
}

._tabular-nums_94iv3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_94iv3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
:root {
  --spacing-xxxl: 80px;
  --spacing-xxl: 48px;
  --spacing-xl: 32px;
  --spacing-lg: 24px;
  --spacing-md: 16px;
  --spacing-sm: 12px;
  --spacing-xs: 8px;
  --spacing-xxs: 4px;
}

:root {
  --screen-xs: 480px;
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1600px;
}

._OverviewPage_94iv3_218 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 48px;
  overflow-y: scroll;
}

._archivedNotice_94iv3_228 {
  max-width: 1600px !important;
}