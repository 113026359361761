import gql from 'graphql-tag';

const GET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT = gql`
    query GetBudgetDistributionsForBudgetAccountQuery ($budgetAccountId: Int, $programId: Int) {
        budgetDistributionsForBudgetAccount:getBudgetDistributionsForBudgetAccount(budgetAccountId: $budgetAccountId,programId: $programId) {
            fiscalYear
            fiscalYearBudgetDistributionId
            fiscalYearLabel
            fiscalGranularity
            allocatedAmount
            availableAmount
            committedAmount
            paidCommissionAmount
            paidCommittedAmount
            spentAmount
            totalAmount
            quarterDistributions {
                quarterDistributionId
                quarterKey
                quarterLabel
                allocatedAmount
                availableAmount
                committedAmount
                paidCommissionAmount
                paidCommittedAmount
                spentAmount
                totalAmount
            }
        }
    }
`;

export default GET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT;
