import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const AccessIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M6.99,12.32c1.84,0,3.33-1.57,3.33-3.51S8.83,5.3,6.99,5.3S3.66,6.88,3.66,8.81S5.16,12.32,6.99,12.32z
      M6.99,6.41c1.22,0,2.22,1.07,2.22,2.4s-0.99,2.4-2.22,2.4s-2.22-1.07-2.22-2.4S5.77,6.41,6.99,6.41z"
    />
    <path
      d="M9.23,13.32c-0.28-0.13-0.61-0.02-0.74,0.26c-0.13,0.28-0.02,0.61,0.26,0.74c1.16,0.57,1.89,1.72,2.08,3.27
      H3.14c0.14-1.45,0.86-2.61,2.02-3.22c0.27-0.14,0.38-0.48,0.23-0.75c-0.14-0.27-0.48-0.38-0.75-0.23C2.96,14.27,2,16,2,18.14
      c0,0.31,0.25,0.56,0.56,0.56h8.87c0.15,0,0.3-0.06,0.4-0.17c0.1-0.11,0.16-0.26,0.15-0.41C11.89,15.84,10.91,14.14,9.23,13.32z"
    />
    <path
      d="M21.6,11.12h-3.97c-0.03,0-0.05,0.01-0.08,0.02c-0.21-0.99-1.09-1.74-2.14-1.74c-1.21,0-2.19,0.98-2.19,2.19
      s0.98,2.19,2.19,2.19c1.09,0,2-0.81,2.16-1.86c0.02,0,0.04,0.01,0.06,0.01h2v0.97c0,0.22,0.18,0.4,0.4,0.4s0.4-0.18,0.4-0.4v-0.97
      h1.16c0.22,0,0.4-0.18,0.4-0.4S21.82,11.12,21.6,11.12z M15.41,12.97c-0.76,0-1.38-0.62-1.38-1.38c0-0.76,0.62-1.38,1.38-1.38
      s1.38,0.62,1.38,1.38C16.79,12.35,16.17,12.97,15.41,12.97z"
    />
  </SvgIcon>
));

AccessIcon.displayName = 'AccessIcon';
