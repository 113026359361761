import * as React from 'react';
import { Skeleton } from '@revfluence/fresh';
import cx from 'classnames';

import styles from './Loading.scss';

interface IProps {
  position: 'largeStat' | 'smallStat' | 'link';
  visible: boolean;
}

export const Loading: React.FC<IProps> = React.memo((props) => {
  const {
    position,
    visible,
  } = props;

  const skeletonClassName = cx({
    [styles.Loading]: true,
    [styles.largeStat]: position === 'largeStat',
    [styles.smallStat]: position === 'smallStat',
    [styles.link]: position === 'link',
    [styles.visible]: visible,
  });

  return (
    <div className={skeletonClassName}>
      <Skeleton
        active
        loading
        paragraph={false}
        title
      />
    </div>
  );
});
Loading.displayName = 'Loading';
