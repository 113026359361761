/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_un13h_157 {
  display: flex;
}

._justify-content-space-between_un13h_161 {
  justify-content: space-between;
}

._tabular-nums_un13h_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_un13h_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CommunityDropdown_un13h_178 {
  cursor: pointer;
}
._CommunityDropdown_un13h_178._disabled_un13h_181 {
  background-color: transparent;
  cursor: default;
}
._CommunityDropdown_un13h_178._disabled_un13h_181 > div:last-child {
  background-color: transparent !important;
}

._isAddNew_un13h_189 {
  margin-top: 0.625rem;
  position: relative;
}
._isAddNew_un13h_189::before {
  content: "";
  height: 0.0625rem;
  top: -0.3125rem;
  left: 0;
  right: 0;
  background-color: #e9e8ea;
  position: absolute;
}