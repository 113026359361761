/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_temz8_157 {
  display: flex;
}

._justify-content-space-between_temz8_161 {
  justify-content: space-between;
}

._tabular-nums_temz8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_temz8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ModifyTagMembersModal_temz8_178 {
  z-index: 9600 !important;
  overflow: auto;
}
._ModifyTagMembersModal_temz8_178 ._option_temz8_182 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._ModifyTagMembersModal_temz8_178 ._highlighted_temz8_188 {
  background-color: var(--secondary-10);
}