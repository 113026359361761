export interface ISocialListeningWatchList {
  active: boolean;
  watchers: IWatcher[];
  id: number;
  name: string;
}

interface IWatcher {
  name: string;
  id: number;
}
