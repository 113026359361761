import * as React from 'react';
import { Tooltip } from 'antd';
import { isFunction, map } from 'lodash';
import { BulbOutlined as BulbIcon, QuestionCircleOutlined } from '@ant-design/icons';

import { useUploadContent, IContent } from '@frontend/app/hooks';
import { ThumbnailUpload, ThumbnailUploadProps } from '@frontend/app/components';

import styles from './ImageUpload.scss';

const { useMemo, useState } = React;

interface IUploadProps {
  serviceName: string;
  parentFolder: string;
  isTemp: boolean;
}

interface IImageUploadProps extends Pick<ThumbnailUploadProps, 'showCropper' | 'cropperProps'> {
  label?: string;
  urlFieldNames: string[];
  thumbnailFieldNames: string[];
  dimensions?: [number, number];
  helpText?: string;
  hintText?: string;
  thumbnails?: string[];
  uploadProps: IUploadProps;
  onFileSelected: (fieldName: string, imageUrl: string) => void;
  onThumbnailSelected?: (fieldName: string, thumbnail: string) => void;
}

const ImageUpload = (props: IImageUploadProps) => {
  const {
    label,
    helpText,
    hintText,
    dimensions,
    thumbnails,
    urlFieldNames,
    thumbnailFieldNames,
    uploadProps,
    onFileSelected,
    onThumbnailSelected,
  } = props;
  const aspectRatio = dimensions ? dimensions[0] / dimensions[1] : null;
  const [fieldUploading, setFieldUploading] = useState<string>();

  const {
    upload,
    isUploading,
    content: uploadContent,
    error: uploadError,
  } = useUploadContent(uploadProps);

  const progressPercentage = useMemo(() => {
    if (isUploading) {
      return (uploadContent as IContent)?.progress?.percentage;
    }
  }, [isUploading, uploadContent]);

  const handleFileSelected = (urlFieldName: string) => async (image: File) => {
    setFieldUploading(urlFieldName);
    const imageUrl = await upload(image, 'image');
    setFieldUploading('');
    onFileSelected(urlFieldName, imageUrl);
  };

  const handleThumbnailSelected = (thumbnailFieldName: string) => (thumbnail: string) => {
    if (isFunction(onThumbnailSelected)) {
      onThumbnailSelected(thumbnailFieldName, thumbnail);
    }
  };

  return (
    <div className={styles.ImageUpload}>
      {label && (
        <h2 className={styles.header}>
          {label}
          {helpText && (
            <span className={styles.helpText}>
              <Tooltip title={helpText} placement="rightTop">
                <QuestionCircleOutlined className={styles.icon} size={14} />
              </Tooltip>
            </span>
          )}
        </h2>
      )}
      <div className={styles.fields}>
        {map(urlFieldNames, (urlFieldName, i) => (
          <ThumbnailUpload
            key={urlFieldName}
            thumbnail={thumbnails && thumbnails[i] || ''}
            showCropper={props.showCropper}
            cropperProps={{ aspectRatio }}
            onFileSelected={handleFileSelected(urlFieldName)}
            onThumbnailSelected={handleThumbnailSelected(thumbnailFieldNames[i])}
            fileSelectorClassName={styles.fieldSizeFix}
            thumbnailClassName={styles.fieldSizeFix}
            hideMaxSizeNote
            hasError={urlFieldName === fieldUploading && !!uploadError}
            errorMessage={urlFieldName === fieldUploading && uploadError?.message}
            progressPercentage={urlFieldName === fieldUploading && progressPercentage || undefined}
            className={styles.thumbnailUpload}
            acceptedTypes={[]}
            accept=".png, .jpg, .jpeg"
          />
        ))}
      </div>
      {hintText && (
        <p className={styles.hintText}>
          <BulbIcon
            size={10}
            className={styles.icon}
          />
          {hintText}
        </p>
      )}
    </div>
  );
};

const ImageUploadComponent = React.memo(ImageUpload);

ImageUploadComponent.displayName = 'ImageUpload';

export default ImageUploadComponent;
