import * as React from 'react';
import { TriangleExclamationIcon, CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHasIssueOnSocialAccount } from '@frontend/app/hooks';
import { SocialAccountSettingsStatus } from '@frontend/app/types/globalTypes';
import { Badge } from '@revfluence/fresh';

import styles from './MenuAlert.scss';

type AlertType = 'error' | 'warning';

const renderAlertIcon = (type?: AlertType) => {
  switch (type) {
    case 'error':
      return <TriangleExclamationIcon fontSize={16} fill={styles['red-6']} />;
    case 'warning':
      return <CircleExclamationIcon fontSize={16} fill={styles['gold-6']} />;
    default:
      return null;
  }
};

interface IMenuAlertProps {
  type?: AlertType;
}

export const MenuAlert: React.FC<IMenuAlertProps> = ({ type, children }) => (
  <div className={styles.menuItem}>
    {children}
    {renderAlertIcon(type)}
  </div>
);

export const IntegrationMenuItem: React.FC = ({ children }) => {
  // Change this logic to be generic for all integrations, for now only checks for Instagram/Social Listening.
  const { data } = useHasIssueOnSocialAccount();
  const hasDisconnectedSocialAccounts = data?.hasIssueOnSocialAccount === SocialAccountSettingsStatus.ERROR;
  const hasWarningSocialAccounts = data?.hasIssueOnSocialAccount === SocialAccountSettingsStatus.WARNING;
  const type = hasDisconnectedSocialAccounts ? 'error' : hasWarningSocialAccounts ? 'warning' : undefined;

  return <MenuAlert type={type}>{children}</MenuAlert>;
};

export const InstagramMenuAlert: React.FC = ({ children }) => {
  const { data } = useHasIssueOnSocialAccount();
  const hasDisconnectedSocialAccounts = data?.hasIssueOnSocialAccount === SocialAccountSettingsStatus.ERROR;
  const hasWarningSocialAccounts = data?.hasIssueOnSocialAccount === SocialAccountSettingsStatus.WARNING;
  const type = hasDisconnectedSocialAccounts ? 'error' : hasWarningSocialAccounts ? 'warning' : undefined;

  return <MenuAlert type={type}>{children}</MenuAlert>;
};

export const BadgeHasUserError: React.FC = ({ children }) => {
  const { data } = useHasIssueOnSocialAccount();
  const hasDisconnectedSocialAccounts = data?.hasIssueOnSocialAccount === SocialAccountSettingsStatus.ERROR;
  return <Badge dot={hasDisconnectedSocialAccounts} size="default" offset={[-4, 4]}>{children}</Badge>;
};
