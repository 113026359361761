import * as React from 'react';
import xss from 'xss';
import { SignupForm } from '@frontend/app/containers/Public/Onboarding/SignupForm';

import { IField } from '@frontend/app/containers/Projects/LandingPages/types';
import { ITemplateProps } from '../types';

import styles from './Application.scss';
import { RichTextVisualizer } from '../../RichTextEditor/Visualizer';

export type IApplicationProps = ITemplateProps['application'] & {
  className?: string;
  fields?: IField[];
  isPreview?: boolean;
  hasUnpaidOffer?: boolean;
  projectId?: number;
  projectTitle?: string;
  disableSubmit?: boolean;
  unpaidLabel?: string;
  unpaidDescription?: string;
};

const DEFAULT_CONFIRMATION_COLOR = '#fdfdfd';

export const Application: React.FC<IApplicationProps> = (props) => {
  const { componentStyles } = props;

  const signupFormConfirmationColor = componentStyles.body.color || DEFAULT_CONFIRMATION_COLOR;

  return (
    <div className={styles.Application}>
      <h1
        className={styles.title}
        style={componentStyles.heading}
      >
        {props.title}
      </h1>

      <RichTextVisualizer
        className={styles.description}
        style={componentStyles.body}
      >
        {xss(props.description)}
      </RichTextVisualizer>

      <SignupForm
        fields={props.fields}
        isLandingPageV2
        isPreview={props.isPreview}
        confirmationColor={signupFormConfirmationColor}
        supportEmail={props.support_email}
        hasUnpaidOffer={props.hasUnpaidOffer}
        projectId={props.projectId}
        projectTitle={props.projectTitle}
        unpaidLabel={props.unpaidLabel}
        unpaidDescription={props.unpaidDescription}
        disableSubmit={props.disableSubmit}
        backgroundColor={componentStyles.form.backgroundColor}
        isBackgroundTransparent={componentStyles.form.isTransparent}
        applyLabel={props.apply_label}
      />
    </div>
  );
};
