import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const EllipsisIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <circle cx="4" cy="12" r="2" />
    <circle cx="12" cy="12" r="2" />
    <circle cx="20" cy="12" r="2" />
  </SvgIcon>
));

EllipsisIcon.displayName = 'EllipsisIcon';
