/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_428ok_157 {
  display: flex;
}

._justify-content-space-between_428ok_161 {
  justify-content: space-between;
}

._tabular-nums_428ok_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_428ok_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PayoutBreakdown_428ok_178 ._entry_428ok_178 {
  display: flex;
  justify-content: space-between;
}
._PayoutBreakdown_428ok_178 ._alert_428ok_182 {
  color: #d9534f;
}
._PayoutBreakdown_428ok_178 ._disable_428ok_185 {
  color: #aeaeae;
}