import * as qs from 'qs';

import { getAdvertiserAccessTableRowData } from './useAccessData';

const fetchData = async (url: string) => {
  const resp = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'text/plain',
    }),
  });

  const json = await resp.json();
  return json.data;
};

export async function getPagedAccessData(
  baseUrl: string,
  clientId: string,
  limit: number,
  page: number,
) {
  const filterString = qs.stringify({
    client_id: clientId,
    limit,
    page,
  });
  const url = `${baseUrl}?${filterString}`;
  const data = await fetchData(url);
  return getAdvertiserAccessTableRowData(data.requirements);
}
