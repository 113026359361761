/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_18pwz_157 {
  display: flex;
}

._justify-content-space-between_18pwz_161 {
  justify-content: space-between;
}

._tabular-nums_18pwz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_18pwz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Popover_18pwz_178 ._editLink_18pwz_178 ._section_18pwz_178 ._title_18pwz_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Link_18pwz_219 {
  color: #15c;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._Link_18pwz_219:hover {
  color: #3996e0;
}
._Link_18pwz_219:active {
  color: #15c;
}
._Link_18pwz_219 ._icon_18pwz_232 {
  color: #fbca27;
}

._Popover_18pwz_178 ._editLink_18pwz_178 {
  display: flex;
  flex-direction: column;
  width: 31.25rem;
  padding: 0.75rem 1rem;
}
._Popover_18pwz_178 ._editLink_18pwz_178 ._section_18pwz_178 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
._Popover_18pwz_178 ._editLink_18pwz_178 ._section_18pwz_178 ._title_18pwz_178 {
  margin-bottom: 0.75rem;
}
._Popover_18pwz_178 ._editLink_18pwz_178 ._section_18pwz_178 ._input_18pwz_250 {
  height: 2.25rem;
  border: 0 !important;
  border-radius: 6.25rem;
  background: #eff5f9;
  box-shadow: none !important;
  outline: none !important;
}
._Popover_18pwz_178 ._editLink_18pwz_178 ._section_18pwz_178 ._hint_18pwz_258 {
  margin-top: 0.25rem;
  color: #8f8d91;
  font-style: italic;
}
._Popover_18pwz_178 ._editLink_18pwz_178 ._editActions_18pwz_263 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
._Popover_18pwz_178 ._editLink_18pwz_178 ._editActions_18pwz_263 ._button_18pwz_269 {
  width: 7.5rem;
}
._Popover_18pwz_178 ._editLink_18pwz_178 ._editActions_18pwz_263 ._cancel_18pwz_272 {
  margin-right: 0.75rem;
  color: #da3737;
}
._Popover_18pwz_178 ._actions_18pwz_276 {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  max-width: 31.25rem;
}
._Popover_18pwz_178 ._actions_18pwz_276 ._link_18pwz_283 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
._Popover_18pwz_178 ._actions_18pwz_276 ._link_18pwz_283 ._linkValue_18pwz_290 {
  flex: 1;
  margin-left: 0.5rem;
  color: #3996e0;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._Popover_18pwz_178 ._actions_18pwz_276 ._link_18pwz_283 ._linkValue_18pwz_290:hover {
  color: #4fa7ee;
}
._Popover_18pwz_178 ._actions_18pwz_276 ._action_18pwz_276 {
  flex-shrink: 0;
  margin-left: 1.25rem;
  color: #3996e0;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._Popover_18pwz_178 ._actions_18pwz_276 ._action_18pwz_276:hover {
  color: #4fa7ee;
}