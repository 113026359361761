import { ICreatorSearchFilters } from '../models';
import { ITextSearchAction, TextSearchActionTypes } from './textSearchActions';

const initialState: ICreatorSearchFilters = null;

export const textSearchReducer = (
  state = initialState,
  action: ITextSearchAction,
): ICreatorSearchFilters => {
  switch (action.type) {
    case TextSearchActionTypes.UPDATE_SEARCH_FILTERS:
      return {
        ...state,
        ...action.payload.searchFilters,
      };

    default:
      return state;
  }
};
