export interface ISaveLicensedContentDeliverableRequest {
  client_id: string;
  content_ids: number[];
  requirement_id: number;
}

export const saveContentAsDeliverable = async (url: string, params: ISaveLicensedContentDeliverableRequest) => {
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
      body: JSON.stringify(params),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};
