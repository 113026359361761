import React from 'react';

interface StatisticProps {
  title: string;
  value: string | number;
  color?: string;
}
const Statistic = ({ title, value, color }: StatisticProps) => (
  <div className="flex flex-col gap-1 text-center items-center w-full">
    <div className="text-2xl text-foreground font-semibold" style={{ color }}>{value}</div>
    <div className="text-muted-foreground">{title}</div>
  </div>
  );

export default Statistic;
