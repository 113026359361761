import * as React from 'react';
import {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { CheckIcon, PencilIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import {
 Button, Input, Space, Typography,
} from '@revfluence/fresh';

const { Text } = Typography;

interface IProps {
  value: string;
  onChange: (newValue: string) => void;
  type?: 'text' | 'email'; // Optional type prop
}

export const EditableInput: React.FC<IProps> = (props) => {
  const { value, onChange, type = 'text' } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [error, setError] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleEditClick = () => {
    setTempValue(value);
    setIsEditing(true);
  };

  const handleCheckClick = () => {
    if (type === 'email' && !validateEmail(tempValue)) {
      setError('Please enter a valid email address.');
      return;
    }
    onChange(tempValue);
    setIsEditing(false);
    setError(null);
  };

  const handleCloseClick = useCallback(() => {
    setTempValue(value);
    setIsEditing(false);
    setError(null);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(e.target.value);
    if (error) setError(null); // Clear error when user starts typing
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      handleCloseClick();
    }
  }, [containerRef, handleCloseClick]);

  useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing, handleClickOutside]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div ref={containerRef}>
      <Space>
        {isEditing ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <Input
                value={tempValue}
                onChange={handleInputChange}
                style={{ width: 200 }}
                type={type}
              />
              <Button
                icon={<CheckIcon style={{ color: '#167CF4' }} />}
                onClick={handleCheckClick}
                type="text"
                style={{ padding: 0 }}
              />
              <Button
                icon={<XmarkIcon style={{ color: 'red' }} />}
                onClick={handleCloseClick}
                type="text"
                style={{ padding: 0 }}
              />
            </div>
            {error && <Text type="danger">{error}</Text>}
          </div>
        ) : (
          <>
            <span>{value}</span>
            <Button
              icon={<PencilIcon style={{ color: '#167CF4', fontSize: '14px' }} />}
              onClick={handleEditClick}
              type="text"
              style={{ padding: 0, width: '20px', height: '20px' }}
            />
          </>
        )}
      </Space>
    </div>
  );
};
