import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import { SAVE_MEMBER } from '@frontend/app/queries';
import {
  SaveMemberMutation,
  SaveMemberMutationVariables,
} from '@frontend/app/queries/types/SaveMemberMutation';

type IOptions = MutationHookOptions<SaveMemberMutation, SaveMemberMutationVariables>;

interface IConfig {
  showMembersHaveChanged?: boolean;
}

export const useSaveMemberMutation = (options: IOptions = {}, config: IConfig = {}) => useMemberMutation<SaveMemberMutation, SaveMemberMutationVariables>(SAVE_MEMBER, options, config.showMembersHaveChanged);
