import gql from 'graphql-tag';

export const GET_ALL_TABLE_COLUMNS = gql`
  query GetAllTableColumns {
    columns: allTableColumns {
      title
      field
      type
      source
      from
      fixed
      width
      maxWidth
      minWidth
      isDefault
      dataIndex
      itemProp
      readonly
    }
  }
`;
