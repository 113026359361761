import React, { useEffect, useMemo } from 'react';
import {
 Checkbox, ITableProps, Table, Tooltip,
} from '@revfluence/fresh';
import { useParams } from 'react-router-dom';
import { useVariantTableProps } from './useVariantTableProps';
import { IVariantsRow } from '../../Products/types';
import { useGetProductDetails } from '../../Products/hooks/useGetProductDetails';
import { useBasicSelectionContext } from '../CatalogSelectionModal/BasicSelectionContext';
import { useGetFeaturedProducts } from '../hooks/useGetFeaturedProducts';

export interface IVariantTableProps {
  productId: string;
  isReadonly?: boolean;
}

export const VariantTable = ({ productId, isReadonly = false }: IVariantTableProps) => {
  const { product, loading } = useGetProductDetails({
    variables: {
      productId: Number(productId),
    },
    skip: !productId,
  });
  const {
    selectedProducts,
    toggleVariant,
    bulkSelectVariants,
    isEditing,
  } = useBasicSelectionContext();

  const { catalogId } = useParams<{ catalogId: string }>();
  const { featuredProducts } = useGetFeaturedProducts({
    variables: {
      catalogId: Number(catalogId),
      includeProductDetails: true,
    },
  });

  const { columnConfig, tableData } = useVariantTableProps({
    variants: product?.productVariants ?? [],
  });

  const onSelectChange = (id: string, checked: boolean) => {
    toggleVariant({
      variantId: id,
      productId: String(product?.id),
      checked,
      allVariantIds: product?.productVariants?.map((v) => String(v.id)) ?? [],
    });
  };

  const selectedProduct = useMemo(() => selectedProducts.find((selectedProduct) => selectedProduct.productId === productId), [selectedProducts, productId]);

  const selectedRowKeys = useMemo(() => {
    if (isReadonly) {
      const selectedProductFromBE = featuredProducts.find((product) => product.productId === Number(productId));
      if (!selectedProductFromBE) {
        return [];
      }
      const isAllVariantsSelected = selectedProductFromBE.variantIds.length === 0;
      return isAllVariantsSelected ? product.productVariants?.map((v) => String(v.id)) : selectedProductFromBE.variantIds.map((v) => String(v));
    } else {
      const isAllVariantsSelected = selectedProduct?.variantIds.length === 0;
      return isAllVariantsSelected ? product.productVariants?.map((v) => String(v.id)) : selectedProduct?.variantIds;
    }
  }, [featuredProducts, isReadonly, product.productVariants, productId, selectedProduct?.variantIds]);

  const disabled = !selectedProduct && selectedProducts.length >= 20;

  const rowSelection: ITableProps<IVariantsRow>['rowSelection'] = {
    selectedRowKeys,
    renderCell: (value, record) => (
      <Tooltip title={disabled ? 'You can only add upto 20 featured products in a catalog' : ''}>
        <Checkbox disabled={isReadonly || disabled} checked={value} onChange={(e) => onSelectChange(record.id, e.target.checked)} />
      </Tooltip>
    ),
  };

  useEffect(() => {
    if (featuredProducts.length && isEditing && product?.id && selectedProduct && selectedProduct.variantIds.length === 0) {
      const selectedProductFromBE = featuredProducts.find((product) => product.productId === Number(productId));
      if (!selectedProductFromBE) {
        return;
      }
      let selectedVariants = selectedProductFromBE?.variantIds ?? [];
      if (selectedVariants.length === 0) {
        selectedVariants = product?.productVariants?.map((v) => (v.id)) ?? [];
      }
      bulkSelectVariants({
        productId,
        variantIds: selectedVariants.map((v) => String(v)),
      });
    }
  }, [bulkSelectVariants, featuredProducts, isEditing, product?.id, product?.productVariants, productId, selectedProduct]);

  return (
    <div>
      <Table
        columns={columnConfig}
        dataSource={tableData}
        size="small"
        rowSelection={rowSelection}
        showHeader={false}
        rowKey="id"
        pagination={false}
        loading={loading}
      />
    </div>
  );
};
