/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import { size } from 'lodash';
import { useHistory } from 'react-router-dom';

import { CreateAppContainer } from '@frontend/app/components/CreateAppContainer/CreateAppContainer';
import { TERMS_APP_ID } from '@frontend/app/constants/applicationIds';
import { useAuth } from '@frontend/context/authContext';
import { useClientFeatureEnabled, useGetMemberQuery, useGetTermsName } from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import { IAxProjectInfo } from '../types/IAxProjectInfo';

import styles from './AgreementIterationView.scss';

const { useEffect } = React;

interface IAgreementIterationViewProps {
  memberId: number;
  programId: number;
  projectId: number;
  workItemId?: string;
  iterationId?: number;
  disableEditTerms?: string;
  onSetProjectInfo: (info: IAxProjectInfo) => void;
}

export const AgreementIterationView: React.FC<IAgreementIterationViewProps> = ({
  memberId,
  programId,
  iterationId,
  workItemId,
  projectId,
  disableEditTerms,
  onSetProjectInfo,
}) => {
  const { token } = useAuth();
  const UCEInfluencerWhitelistingFlag = useClientFeatureEnabled(ClientFeature.UCE_INFLUENCER_WHITELISTING);
  const MetaBrandedContentFlag = useClientFeatureEnabled(ClientFeature.META_BRANDED_CONTENT);
  const history = useHistory();

  const { singularTermsName } = useGetTermsName();
  const { data, loading } = useGetMemberQuery(memberId as number);

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.data && event.data.msgType === 'ax_post_project_success') {
        onSetProjectInfo(event.data.project);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const noMemberEmail = size(data?.member.email) === 0;
  if (!loading && noMemberEmail) {
    return (
      <div className={styles.AgreementIterationView}>
        <div className={styles.header}>Member cannot be sent briefs</div>
        <div className={styles.message}>
          In order to send
          {' '}
          {singularTermsName}
          {' '}
          to this member, please add a valid email address for the member
        </div>
      </div>
      );
  }

  return (
    <CreateAppContainer
      applicationId={TERMS_APP_ID}
      token={token}
      extraParams={{
        member_id: memberId,
        program_id: programId,
        work_item_id: workItemId,
        project_id: projectId,
        iteration_id: iterationId,
        UCEInfluencerWhitelisting: (UCEInfluencerWhitelistingFlag || false).toString(),
        MetaBrandedContent: (MetaBrandedContentFlag || false).toString(),
        disableEditTerms,
      }}
    />
  );
};
