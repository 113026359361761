import * as React from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Notice } from '@components';
import { Button } from '@revfluence/fresh';
import {
 loadStripe, StripeError, Token, StripeCardElementOptions,
} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import styles from './AddCardForm.scss';

const { useMemo, useState } = React;

const CARD_ELEMENT_BASE_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      'color': '#32325d',
      'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
      'fontSmoothing': 'antialiased',
      'fontSize': '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface IProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  stripePublicAPIKey: string;
  disabled?: boolean;
  submitting?: boolean;
  submitButtonText?: string;
  className?: string;
  onSubmit?();
  onTokenReceived(token: Token);
  onError?(error: StripeError);
  onSuccess?();
}

const StripeElementsForm: React.FC<IProps> = React.memo((props) => {
  const [error, setError] = useState<StripeError>(null);
  const stripe = useStripe();
  const elements = useElements();

  const elementId = useMemo(() => `card-element-${uuidv4()}`, []);

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error);
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (props.onSubmit) {
      props.onSubmit();
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setError(result.error);

      if (props.onError) {
        props.onError(result.error);
      }
    } else {
      setError(null);
      props.onTokenReceived(result.token);
      props.onSuccess();
    }
  };

  const disabled = props.submitting || props.disabled;

  const elementOptions = useMemo(() => ({
      ...CARD_ELEMENT_BASE_OPTIONS,
      disabled,
    }), [disabled]);

  return (
    <div className={cx(styles.AddCardForm, props.className)}>
      {props.title && (
        <div className={styles.title}>
          {props.title}
        </div>
      )}
      {props.subtitle && (
        <div className={styles.subtitle}>
          {props.subtitle}
        </div>
      )}
      {error && (
        <Notice type="error" showDivider className={styles.error}>
          {error.message}
        </Notice>
      )}
      <CardElement
        id={elementId}
        className={styles.element}
        onChange={handleChange}
        options={elementOptions}
      />
      <div className={styles.btnWrapper}>
        <Button
          onClick={handleSubmit}
          disabled={disabled}
          loading={props.submitting}
        >
          {props.submitButtonText}
        </Button>
      </div>
    </div>
  );
});
StripeElementsForm.defaultProps = {
  disabled: false,
  submitting: false,
  submitButtonText: 'Add Card',
};

StripeElementsForm.displayName = 'StripeElementsForm';

export const AddCardForm: React.FC<IProps> = React.memo((props) => {
  const stripePromise = useMemo(() => loadStripe(props.stripePublicAPIKey), [props.stripePublicAPIKey]);

  return (
    <Elements stripe={stripePromise}>
      <StripeElementsForm {...props} />
    </Elements>
  );
});

AddCardForm.displayName = 'AddCardForm';
