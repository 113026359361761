/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import * as Joi from '@hapi/joi';
import memoizeOne from 'memoize-one';
import {
 keyBy, mapValues, pick, keys, isUndefined,
} from 'lodash';

import { logger } from '@common';

import { SimpleField } from './SimpleField';

interface IFormValues {
  [fieldName: string]: any;
}

interface IErrorMessages {
  [fieldName: string]: string;
}

interface IProps {
  schema?: Joi.ObjectSchema;
  values?: IFormValues;
  defaultValues?: IFormValues;
  onChange?(fieldName: string, value: string);
  onSubmit?(formValues: IFormValues, ...extraArgs: any[]);
}

interface IState {
  fieldSchemaMap: Joi.SchemaMap;
  fieldValues: IFormValues;
  errors: IErrorMessages;
}

/** @deprecated Replace with Fresh! */
export class SimpleForm extends React.PureComponent<IProps, IState> {
  public static defaultProps = {
    defaultValues: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      fieldSchemaMap: {},
      fieldValues: props.defaultValues,
      errors: {},
    };
  }

  private getSchema = memoizeOne((fieldSchemaMap: Joi.SchemaMap, schema?: Joi.ObjectSchema) => {
    if (schema) {
      return schema;
    }

    return Joi.object(fieldSchemaMap);
  });

  static getDerivedStateFromProps = (props, state) => {
    if (props.defaultValues) {
      return {
        ...state,
        fieldValues: props.defaultValues,
      };
    }

    return null;
  };

  private get isControlled() {
    return !isUndefined(this.props.values);
  }

  public get fieldValues() {
    return this.isControlled ? this.props.values : this.state.fieldValues;
  }

  private get schema() {
    return this.getSchema(this.state.fieldSchemaMap, this.props.schema);
  }

  private handleChangeValue = (fieldName: string, value: string) => {
    if (this.props.onChange) {
      this.props.onChange(fieldName, value);
    }

    if (!this.isControlled) {
      this.setState((state) => ({
        ...state,
        fieldValues: {
          ...state.fieldValues,
          [fieldName]: value,
        },
      }));
    }

    this.setState((state) => ({
      ...state,
      errors: {
        ...state.errors,
        [fieldName]: null,
      },
    }));
  };

  private handleChangeFieldSchema = (fieldName: string, fieldSchema: Joi.Schema) => {
    if (this.props.schema) {
      return;
    }

    this.setState((state) => ({
      ...state,
      fieldSchemaMap: {
        ...state.fieldSchemaMap,
        [fieldName]: fieldSchema,
      },
    }));
  };

  private async validate() {
    const fieldNames = keys(this.state.fieldSchemaMap);
    const fieldValues = pick(this.fieldValues, fieldNames);
    const values = await this.schema.validateAsync(fieldValues, { abortEarly: false });

    this.setState((state) => ({
      ...state,
      errors: {},
    }));

    return values;
  }

  public async submit(...args: any[]) {
    try {
      const values = await this.validate();
      if (this.props.onSubmit) {
        this.props.onSubmit(values, ...args);
      }
    } catch (err) {
      logger.error(err);
      this.setState((state) => ({
        ...state,
        errors: mapValues(keyBy(err.details, 'context.key'), 'message'),
      }));
    }
  }

  private mapFields = (children: React.ReactChild) => React.Children.map(children, (child: React.ReactElement) => {
      if (!child) {
        return child;
      }

      if (child.type === SimpleField) {
        const errorMessage = this.state.errors[child.props.name] || child.props.errorMessage;
        const hasError = !!errorMessage || child.props.hasError;
        const defaultValue = child.props.type === 'array' ? [] : '';

        const mergedProps = {
          value: this.fieldValues[child.props.name] || defaultValue,
          hasError,
          errorMessage,
          onChange: this.handleChangeValue,
          _onChangeFieldSchema: this.handleChangeFieldSchema,
        };

        return React.cloneElement(child, mergedProps);
      } else if (child.props && child.props.children) {
        return React.cloneElement(child, {
          children: this.mapFields(child.props.children),
        });
      } else {
        return child;
      }
    });

  public render() {
    return React.Children.map(
      this.props.children,
      (child: React.ReactElement) => this.mapFields(child),
    );
  }
}

export {
  SimpleField,
};
