export const regions = {
  Africa: 'Africa',
  Asia: 'Asia',
  Caribbean: 'Caribbean',
  'Central America': 'Central America',
  Europe: 'Europe',
  'North America': 'North America',
  Oceania: 'Oceania',
  'South America': 'South America',
};
