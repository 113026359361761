import React from 'react';
import { BoltIcon, LockIcon } from '@revfluence/fresh-icons/regular/esm';
import { Tag } from '@revfluence/fresh';
import { Handle, Position } from 'reactflow';
import { NODE_EDGE_COLOR, automationTriggerDisplayName } from '@frontend/app/containers/Projects/AutomationConfig/constants';
import styles from './AutomationNode.scss';

const TriggerNode = ({ data }) => (
  <>
    <div className={styles.nodeDecorator}>
      <div className={styles.triggerNodeIcon}>
        <BoltIcon />
      </div>
    </div>
    <div className={styles.nodeContent}>
      <div>When...</div>
      <Tag
        icon={<LockIcon />}
        loading
      >
        { automationTriggerDisplayName[data.metadata] }
      </Tag>
    </div>
    <Handle
      type="source"
      isConnectable={false}
      position={Position.Bottom}
      style={{ background: data.isEditing ? NODE_EDGE_COLOR.EDITING : NODE_EDGE_COLOR.PRIMARY }}
      id="trigger-resource"
    />
  </>
);

export default TriggerNode;
