import gql from 'graphql-tag';

export const CLIENT_ALLOY_METADATA_FRAGMENT = gql`
  fragment ClientAlloyMetadataFragment on ClientAlloyMetadata {
    clientId
    alloyUserId
    workflows
    availableIntegrations
  }
`;
