import * as React from 'react';

import { TPlace, PlaceTypeOption } from './types';
import { PlacesInput } from './PlacesInput';

interface IProps {
  className?: string;
  onChange(newCities: TPlace[]): void;
  cities?: TPlace[];
  placeholder?: string;
  debounceWait?: number;
}

const OPTIONS = {
  types: [PlaceTypeOption.City],
};

export const CityInput: React.FunctionComponent<IProps> = (props) => (
  <PlacesInput
    className={props.className}
    places={props.cities}
    placeholder={props.placeholder}
    onChange={props.onChange}
    emptyOptionsMessage="Start typing city name"
    options={OPTIONS}
    debounceWait={props.debounceWait}
  />
  );

CityInput.defaultProps = {
  placeholder: 'Enter a city',
};
CityInput.displayName = 'CityInput';
