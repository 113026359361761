import {
  Col, Empty, Row, Typography,
} from '@revfluence/fresh';
import { PersonDressSimpleIcon, PersonIcon } from '@revfluence/fresh-icons/solid/esm';
import {
  filter,
  isUndefined, map, some, values,
} from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import { ChartPieIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatEngagementRateToPerc, sortDataArray } from '../GcrUtils';
import styles from './SocialMetrics.scss';

const { Text } = Typography;
const AudienceAgeGroupChart = ({ socialProfileData, selectedNetwork }) => {
  const [ageData, setAgeData] = useState([]);
  useEffect(() => {
    const ageToLabelMap = {
      '13-17': '13-17',
      '18-24': '18-24',
      '25-34': '25-34',
      '35-44': '35-44',
      '45-64': '45-64',
      '65-': '65+',
    };
    if (selectedNetwork == 'instagram') {
      const data = socialProfileData[0].gender_age_data;
      const dataArray = filter(
        map(data, (genderData, ageRange) => ({
          name: ageToLabelMap[ageRange],
          uv: genderData.MALE || genderData.male || 0,
          pv: genderData.FEMALE || genderData.female || 0,
        })),
        (ageData) => values(ageToLabelMap).includes(ageData.name),
      );
      setAgeData(dataArray);
    } else if (selectedNetwork == 'tiktok') {
      const data = socialProfileData[0].age_data;
      const dataArray = filter(map(data, (percentage, ageRange) => ({
        name: ageToLabelMap[ageRange],
        uv: percentage,
      })), (ageData) => values(ageToLabelMap).includes(ageData.name));
      dataArray.sort();
      setAgeData(dataArray);
    }
  }, [selectedNetwork, socialProfileData]);

  const legendWithIcons = useCallback(() => {
    if (selectedNetwork === 'tiktok') {
      return null;
    }
    const totalFemalePercentage = ageData.reduce((acc, item) => (item.pv ? acc + item.pv : acc), 0);
    const totalMalePercentage = ageData.reduce((acc, item) => (item.uv ? acc + item.uv : acc), 0);
    return (

      <div className={styles.legendWrapper}>
        <Row gutter={6} justify="center" align="middle">
          <Col><PersonIcon className={styles.maleChartIcon} width={15} height={22} /></Col>
          <Col>
            {' '}
            <Text strong>{formatEngagementRateToPerc(totalMalePercentage)}</Text>
            <br />
            {' '}
            <Text strong>Male</Text>
          </Col>

        </Row>
        <Row gutter={6} justify="center" align="middle">
          <Col>
            {' '}
            <PersonDressSimpleIcon className={styles.femaleChartIcon} width={15} height={22} />
          </Col>
          <Col>
            <Text strong>
              {' '}
              {formatEngagementRateToPerc(totalFemalePercentage)}
            </Text>
            <br />
            {' '}
            <Text strong>Female</Text>
          </Col>
        </Row>
      </div>
    );
  }, [selectedNetwork, ageData]);

  const hasUndefinedValues = useMemo(() => some(ageData, (obj) => values(obj).some((value) => isUndefined(value))), [ageData]);
  return (
    <div>
      {hasUndefinedValues ? <Empty image={<ChartPieIcon />} size="small" description={<Typography.Paragraph type="secondary">No stats to display</Typography.Paragraph>} /> : (

        <ResponsiveContainer width="100%" height={330}>
          <BarChart
            layout="vertical" // Set the layout to vertical for a horizontal bar chart
            data={sortDataArray(ageData)}
            barGap={4}
          >
            <CartesianGrid strokeDasharray="1 1" horizontal={false} />
            <YAxis dataKey="name" type="category" axisLine={false} />
            <XAxis type="number" tickFormatter={(value) => `${(value * 100).toFixed()}%`} />
            <Tooltip
              formatter={(value, _, props) => {
                const { dataKey } = props;
                if (selectedNetwork === 'instagram') {
                  const customLabels = {
                    uv: 'Male',
                    pv: 'Female',
                    name: 'Age', // Add a custom label for the "name" property
                    // Add more custom labels as needed
                  };
                  return [
                    `${(value * 100).toFixed(2)}%`,
                    customLabels[dataKey] || dataKey, // Use the custom label if available, otherwise use the dataKey
                  ];
                } else {
                  return [`${(value * 100).toFixed(2)}%`];
                }
              }}
            />
            <Legend content={legendWithIcons} />
            <Bar dataKey="uv" stackId="a" fill="#16282D" barSize={14} />
            <Bar dataKey="pv" stackId="a" fill="#F58984" barSize={14} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default AudienceAgeGroupChart;
