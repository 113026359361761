import { map, sortBy } from 'lodash';

import { CreateAppContainer } from '@frontend/app/components/CreateAppContainer/CreateAppContainer';
import { TERMS_APP_ID } from '@frontend/app/constants/applicationIds';
import { useAuth } from '@frontend/context/authContext';
import * as React from 'react';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { MenuList } from '@frontend/app/components';

import { ClientFeature } from '@frontend/app/constants';
import styles from './TermsGuidelinesSettingsNotEmpty.scss';

const {
  useState,
  useMemo,
} = React;

interface IProject {
  id: number;
  title: string
}

interface IProps {
  projects: IProject[];
  projectId?: number;
}

export const TermsGuidelinesSettingsNotEmpty: React.FC<IProps> = React.memo((props) => {
  const { token } = useAuth();
  const {
    projects,
    projectId,
  } = props;
  const [selectedProjectId, setSelectedProjectId] = useState<number>(projectId || projects[0].id);

  const UCEInfluencerWhitelistingFlag = useClientFeatureEnabled(ClientFeature.UCE_INFLUENCER_WHITELISTING);
  const MetaBrandedContentFlag = useClientFeatureEnabled(ClientFeature.META_BRANDED_CONTENT);

  const extraParams = useMemo(() => ({
      program_id: selectedProjectId,
      state: 'ax_edit_deliverable_guideline_templates',
      UCEInfluencerWhitelisting: (UCEInfluencerWhitelistingFlag || false).toString(),
      MetaBrandedContent: (MetaBrandedContentFlag || false).toString(),
  }), [selectedProjectId, UCEInfluencerWhitelistingFlag, MetaBrandedContentFlag]);

  const sortedProjects = useMemo(() => {
      if (!projects) {
        return [];
      }
      return sortBy(projects, [(project) => project?.title?.toLowerCase()]);
    }, [projects]);

  const listItems = useMemo(() => map(sortedProjects, (project) => ({
      id: project.id,
      title: project.title,
    })), [sortedProjects]);

  return (
    <>
      <div className={styles.left}>
        <div className={styles.header}>
          Projects
        </div>
        <MenuList
          items={listItems}
          selectedId={selectedProjectId}
          onSelectItem={setSelectedProjectId}
          className={styles.programList}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.contentContainer}>
          {extraParams.program_id
              && (
              <CreateAppContainer
                applicationId={TERMS_APP_ID}
                token={token}
                extraParams={extraParams}
              />
            )}
        </div>
      </div>
    </>
  );
});

TermsGuidelinesSettingsNotEmpty.displayName = 'TermsGuidelinesSettingsNotEmpty';

export default TermsGuidelinesSettingsNotEmpty;
