import * as React from 'react';
import { Modal } from 'antd';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { genericAddEventLog } from '@components';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useAnalytics } from '@frontend/hooks';
import styles from './MetaItems.module.scss';

const ConfirmationModal = (action: () => void) => Modal.confirm({
  title: 'Before you connect',
  content: 'Connecting your Instagram business, Facebook page, and ad account to Aspire enhances influencer marketing campaigns by enabling advanced features, insights, and allowlisting functionality for reusing user-generated content.',
  okText: 'Continue',
  cancelButtonProps: {
    hidden: true,
  },
  onOk: action,
  icon: <CircleExclamationIcon fill={styles.primary} />,
  width: 530,
});

export const useHandleAddSocialListeningAccount = (showModal: boolean) => {
  const { backendServerWebEndpoint, clientId } = useApplication();
  const analytics = useAnalytics();
  const reconnectFunction = React.useCallback(() => {
    const redirect = window.location.href;
    const oauthEndpoint = `${backendServerWebEndpoint}brands/${clientId}/facebook/aspirex_oauth`;
    const redirectQueryString = redirect ? `&redirect=${redirect}` : '';
    const oauthType = 'aspirex_add_brand_account_mentions';
    const parameters = `?oauth_type=${oauthType}${redirectQueryString}`;

    genericAddEventLog(
      'oauth_grant_start',
      {
        app: 'social_post',
      },
      analytics,
    );
    window.location.replace(
      oauthEndpoint + parameters,
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendServerWebEndpoint, clientId]);
  if (showModal) {
    return () => ConfirmationModal(reconnectFunction);
  }
  return reconnectFunction;
};
