import * as React from 'react';
import cx from 'classnames';
import { format } from 'date-fns';

import {
  Avatar,
} from 'antd';

import {
  Checkbox, SocialIcon, ImageWithTag, SocialPostTag,
} from '@components';
import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import { ISocialPostArtifact } from '../useFetchSocialPostData';

import styles from './AssignPostTile.scss';

export interface IContentTileProps {
  post: ISocialPostArtifact;

  selected: boolean;
  onSelectedChange(selected: boolean);

  classNames?: string[];

  clientId?: string;
  memberId?: number;
  isInstagramStory?: boolean;
  isInsightsAuthed?: boolean;
  onError?: () => void;
  isFailed?: boolean;
}

type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.Component}
 */
export class AssignPostTile extends React.Component<IContentTileProps> {
  private ref: React.RefObject<HTMLDivElement>;

  public static defaultProps: Pick<IContentTileProps, TDefaultProp> = {
    classNames: [],
  };

  constructor(props: IContentTileProps) {
    super(props);

    this.ref = React.createRef();
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      clientId,
      memberId,
      post,
      classNames,
      selected,
      onSelectedChange,
      onError,
      isFailed,
    } = this.props;

    const imageStyle = {
      backgroundImage: `url(${post.image_url}) , url('https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'`,
    };

    const isInsightsAuthed = post?.image_url?.includes('https://storage.googleapis.com/');

    return (
      <div ref={this.ref} className={cx(classNames.concat(styles.AssignPostTile))}>
        <div className={styles.media} onMouseDown={() => onSelectedChange(!selected)}>
          <div className={styles.imageContainer}>
            <ImageWithTag
              src={post.image_url}
              className={styles.image}
              style={imageStyle}
              onError={onError}
              Tag={(
                <SocialPostTag
                  clientId={clientId}
                  appId={SOCIAL_POST_APP_ID}
                  memberId={memberId}
                  postType={post.post_type}
                  isInsightsAuthed={isInsightsAuthed}
                  programNames={post.program_names}
                  communityNames={post.community_names}
                  isFailed={isFailed}
                />
              )}
            />
          </div>
          <div>
            <Checkbox
              className={styles.checkbox}
              checked={selected}
              onChange={onSelectedChange}
            />
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.avatarContainer}>
            <a href={post.link} target="_blank" rel="noopener noreferrer">
              <div className={styles.networkIcon}>
                {SocialIcon(post.network)}
              </div>
              <Avatar className={styles.avatar} src={post.profile_image_url} />
            </a>
          </div>
          <div className={styles.info}>
            <div className={styles.name}>{post.social_account_name}</div>
            <div className={styles.date}>{format(new Date(post.datetime_posted), 'MMM d, yyyy')}</div>
          </div>
          <div className={styles.actions}>
            <a
              href={post.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.visit} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
