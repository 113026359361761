export abstract class BaseError extends Error {
    // abstract static property support would be useful here,
    // but probably getting rid of this as soon as we do not need
    // backwards compat, so not a big complaint.
    public abstract readonly status: number;
    public readonly context: Record<string, any>;

    constructor(message?: string, context?: Record<string, any>) {
      super();
      this.message = message;
      this.name = this.constructor.name;
      this.context = context || {};

      if (typeof Error.captureStackTrace === 'function') {
        // Capturing stack trace, excluding constructor call from it.
        Error.captureStackTrace(this, this.constructor);
      } else {
        this.stack = (new Error(message)).stack;
      }
    }
  }
