/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1u2cy_157 {
  display: flex;
}

._justify-content-space-between_1u2cy_161 {
  justify-content: space-between;
}

._tabular-nums_1u2cy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1u2cy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AttachmentItem_1u2cy_178 ._name_1u2cy_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._AttachmentItem_1u2cy_178 ._size_1u2cy_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AttachmentItem_1u2cy_178 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0 0.375rem;
  border-radius: 0.5rem;
  background: #f3f4f6;
  cursor: pointer;
  user-select: none;
}
._AttachmentItem_1u2cy_178._hovered_1u2cy_275 ._name_1u2cy_178 {
  color: #3996e0;
}
._AttachmentItem_1u2cy_178._hovered_1u2cy_275 ._deleteButton_1u2cy_278 {
  opacity: 1;
  pointer-events: auto;
}
._AttachmentItem_1u2cy_178 ._progress_1u2cy_282 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background: #3996e0;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: left;
  will-change: opacity, transform;
}
._AttachmentItem_1u2cy_178 ._progress_1u2cy_282._completed_1u2cy_293 {
  opacity: 0;
}
._AttachmentItem_1u2cy_178 ._deleteButton_1u2cy_278 {
  position: absolute;
  right: 0.625rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 6.25rem;
  background: #f1515f;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
  transition: all 0.1s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: opacity, background;
}
._AttachmentItem_1u2cy_178 ._deleteButton_1u2cy_278:hover {
  background: #da3737;
}
._AttachmentItem_1u2cy_178 ._deleteButton_1u2cy_278 ._icon_1u2cy_316 {
  color: #fdfdfd;
}
._AttachmentItem_1u2cy_178 ._type_1u2cy_319 {
  margin-right: 0.25rem;
  font-size: 1rem;
}
._AttachmentItem_1u2cy_178 ._name_1u2cy_178 {
  flex: 1;
  margin-right: 0.375rem;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
}
._AttachmentItem_1u2cy_178 ._size_1u2cy_188 {
  color: #8f8d91;
}
._AttachmentItem_1u2cy_178._warning_1u2cy_332 {
  background-color: #d48806;
  color: #fffbe6;
}
._AttachmentItem_1u2cy_178._warning_1u2cy_332 ._name_1u2cy_178,
._AttachmentItem_1u2cy_178._warning_1u2cy_332 ._size_1u2cy_188 {
  color: #fffbe6;
}