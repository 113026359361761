import { useMutation, MutationHookOptions } from '@apollo/client';
import { UPSERT_BUDGET_ACCOUNT_DISTRIBUTION } from '@frontend/app/queries/UpsertBudgetAccountDistribution';
import { useEffect } from 'react';
import { message } from 'antd';
import { UpsertBudgetAccountDistributionVariables, UpsertBudgetAccountDistribution } from '@frontend/app/queries/types/UpsertBudgetAccountDistribution';

type MutationOptions = MutationHookOptions<UpsertBudgetAccountDistribution, UpsertBudgetAccountDistributionVariables>;

export function useUpsertBudgetAccountDistribution(options?: MutationOptions) {
    const [upsertBudgetAccountDistribution, { loading, error }] = useMutation<UpsertBudgetAccountDistribution, UpsertBudgetAccountDistributionVariables>(UPSERT_BUDGET_ACCOUNT_DISTRIBUTION, {
        ...options,
        onCompleted() {
          message.info('Successfully saved Budget Account');
        },
        fetchPolicy: 'no-cache',
      });

    useEffect(() => {
        if (error) {
          message.error('Failed to save');
        }
      }, [error]);

    return {
        loading,
        upsertBudgetAccountDistribution,
        error,
    };
}
