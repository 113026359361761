import 'css-chunk:src/components/common/CreatorList/SelectListPopover.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "SelectListPopover": "_SelectListPopover_rjj31_32",
  "header": "_header_rjj31_32",
  "spinner": "_spinner_rjj31_42",
  "list": "_list_rjj31_46",
  "option": "_option_rjj31_46",
  "disabled": "_disabled_rjj31_60",
  "active": "_active_rjj31_64",
  "label": "_label_rjj31_64",
  "actions": "_actions_rjj31_73",
  "item": "_item_rjj31_79",
  "addList": "_addList_rjj31_88",
  "addIconWrapper": "_addIconWrapper_rjj31_102",
  "addIcon": "_addIcon_rjj31_102",
  "editList": "_editList_rjj31_122",
  "back": "_back_rjj31_125",
  "arrowIcon": "_arrowIcon_rjj31_146",
  "editContent": "_editContent_rjj31_151",
  "button": "_button_rjj31_157"
};