import { findIndex } from 'lodash';

import { cleanPromoCode, markDuplicates } from '../../../../utils';
import { TState } from '../types';

interface IAction {
  code: string;
  memberId: number;
  type: 'UPDATE MEMBER CODE';
}
export type TUpdateMemberCodeAction = Readonly<IAction>;

export const updateMemberCode = (state: TState, action: TUpdateMemberCodeAction): TState => {
  const cleanCode = cleanPromoCode(action.code);
  const memberIndex = findIndex(state.members, (m) => m.id === action.memberId);
  if (memberIndex < 0) {
    // cannot find the member in the list..
    return state;
  }
  return {
    ...state,
    members: markDuplicates([
      ...state.members.slice(0, memberIndex),
      {
        ...state.members[memberIndex],
        code: cleanCode,
      },
      ...state.members.slice(memberIndex + 1),
    ]),
  };
};
