import * as React from 'react';
import { first, includes, map } from 'lodash';

import {
 Button, Col, Popconfirm, Row, Select, Space, Table, Typography,
} from '@revfluence/fresh';
import { LoadSpinner } from '@components';

import {
  IUser,
  useClientFeatureEnabled,
  useGetCurrentClient,
  useRemoveUser,
  useUpdateManagerRole,
} from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';
import { ClientFeature } from '@frontend/app/constants';
import { InviteUserModal } from './InviteUserModal';

interface IProps {
  className?: string;
}

const { useState, useCallback, useMemo } = React;
const { Title } = Typography;
const { Column } = Table;
const { Option } = Select;

/**
 * @type {React.FC}
 */
export const TeammateSettings: React.FC<IProps> = React.memo((props) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { user } = useAuth();
  const { loading: removing, removeUser } = useRemoveUser();
  const { loading: updating, updateManagerRole } = useUpdateManagerRole();

  const isGCREnabled = useClientFeatureEnabled(ClientFeature.GROUP_CONTENT_REVIEW);
  const { loading, client, refetch } = useGetCurrentClient();

  const roleOptions = useMemo(() => {
    const roles: Array<{
      name: string;
      description: string;
    }> = [
      {
        name: 'manager:default',
        description: 'Default',
      },
      {
        name: 'manager:admin',
        description: 'Admin',
      },
    ];

    if (isGCREnabled) {
      roles.push(
        ...[
          {
            name: 'manager:content_approver',
            description: 'Content Approver',
          },
          {
            name: 'manager:content_viewer',
            description: 'Content Viewer',
          },
        ],
      );
    }

    return roles;
  }, [isGCREnabled]);
  const isBrandAdmin = includes(user['https://aspirex.api.com/roles'], 'manager:admin');
  const openInviteModal = useCallback(() => setShowInviteModal(true), []);
  const closeInviteModal = useCallback(() => setShowInviteModal(false), []);
  const onUpdateManagerRole = useCallback(
    async (id: string, role: string) => {
      await updateManagerRole({
        variables: {
          id,
          role,
        },
      });

      refetch();
    },
    [updateManagerRole, refetch],
  );

  if (loading) {
    return <LoadSpinner />;
  }

  return (
    <Space direction="vertical" size="middle" className={props.className} style={{ width: '100%' }}>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={3}>
            Team Members
          </Title>
        </Col>
        <Col>
          <Button onClick={openInviteModal}>Invite Team Member</Button>
        </Col>
      </Row>
      <Table
        rowKey={(user: IUser) => user.id}
        dataSource={client.users}
        loading={updating || removing}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
        }}
      >
        <Column title="Name" dataIndex="name" />
        <Column title="Email Address" dataIndex="email" />
        {isBrandAdmin && (
          <>
            <Column
              title="Role"
              key="role"
              render={(u: IUser) => {
                const clientsData = u.auth0User?.appMetadata?.clients || {};
                const currentRole = first(clientsData[client.id]?.roles) as string;
                const isSelf = u.id === user.sub;

                return (
                  <Select
                    bordered={false}
                    disabled={isSelf}
                    value={currentRole}
                    onChange={(role) => onUpdateManagerRole(u.id, role)}
                  >
                    {map(roleOptions, (role) => (
                      <Option key={role.name} value={role.name}>
                        {role.description}
                      </Option>
                    ))}
                  </Select>
                );
              }}
            />
            <Column
              title="Actions"
              key="actions"
              fixed="right"
              render={(u: IUser) => {
                const isSelf = u.id === user.sub;
                if (isSelf) {
                  return null;
                }

                return (
                  <Popconfirm
                    title={(
                      <div>
                        Are you sure you want to remove this user?
                        <br />
                        This will disconnect all email associated with the user as well.
                      </div>
                    )}
                    onConfirm={async (event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      await removeUser({
                        variables: {
                          id: u.id,
                        },
                      });

                      refetch();
                    }}
                    onCancel={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    okText="Yes, remove"
                    cancelText="Cancel"
                    okButtonProps={{
                      danger: true,
                    }}
                  >
                    <Button danger type="link">
                      Remove
                    </Button>
                  </Popconfirm>
                );
              }}
            />
          </>
        )}
      </Table>
      <InviteUserModal onComplete={refetch} onRequestClose={closeInviteModal} open={showInviteModal} />
    </Space>
  );
});

TeammateSettings.displayName = 'TeammateSettings';
