import {
  isEmpty,
  isNull,
  map,
  reduce,
  uniq,
} from 'lodash';

import {
  IMember,
  SocialColumnKey,
  SOCIAL_MEMBER_FIELD_MAP,
} from '../types/Member';
import { IState } from '../types/state';

export const getMemberComment = (
  member: IMember,
  collaborationDetails: IState['collaborationDetails'],
  contentGuidelines: IState['contentGuidelines'],
): IMember['comment'] => {
  const {
    reward: {
      payment,
    },
  } = collaborationDetails;

  if (payment.showHideNewPrice && member.paymentAmount && payment.newPrice !== member.paymentAmount) {
    return 'Custom payment amount.';
  }

  // hold a list of missing social handles to use as part of the comment
  const missingSocialHandles: Array<SocialColumnKey> = uniq(reduce(
    contentGuidelines,
    (acc, next) => {
      const { type } = next;
      const memberSocialField: SocialColumnKey | undefined = SOCIAL_MEMBER_FIELD_MAP[type];
      if (memberSocialField && (isNull(member[memberSocialField]) || isEmpty(member[memberSocialField]))) {
        return acc.concat([memberSocialField]);
      }
      return acc;
    },
    [],
  ));

  if (!isEmpty(missingSocialHandles)) {
    const handles = map(missingSocialHandles, (key): string => {
      switch (key) {
        case 'blog':
          return 'Blog';
        case 'facebook':
          return 'Facebook';
        case 'instagram':
          return 'Instagram';
        case 'pinterest':
          return 'Pinterest';
        case 'snapchat':
          return 'Snapchat';
        case 'tiktok':
          return 'TikTok';
        case 'twitter':
          return 'Twitter';
        case 'youtube':
          return 'YouTube';
        default:
          throw new Error(`Unhandled social key: "${key}".`);
      }
    });

    if (handles.length === 1) {
      return `Missing ${handles[0]}.`;
    }

    if (handles.length === 2) {
      return `Missing ${handles[0]} and ${handles[1]}.`;
    }

    return (
      `Missing ${handles.slice(0, handles.length - 1).join(',')}`
      + ` and ${handles[handles.length - 1]}.`
    );
  }

  return null;
};
