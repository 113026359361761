import React, { useMemo } from 'react';
import {
  AnnualBetween,
  AnnualEqual,
  AnnualRelative,
  BooleanEqual,
  DateBetween,
  DateEqual,
  DaysRelative,
  NumberBetween,
  NumberEqual,
  TextEqual,
} from '@frontend/app/components/FilterList/fieldTypes';
import { AutomationVariableValue } from '@frontend/app/types/automations/condition/AutomationVariable';
import { ComboOperator, ComparisonOperator, MappedOperator } from '@frontend/app/types/automations/condition/Operator';
import { Select } from '@revfluence/fresh';
import { isEmpty } from 'lodash';
import { FieldType } from '@frontend/app/types/globalTypes';
import { FormattedOption } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { IncludeSelect } from '@frontend/app/components/FilterList/fieldTypes/IncludeSelect';
import { useFuzzySearchByKeys } from '@frontend/app/hooks';
import { GetNodeOptions_getNodeOptions_conditionOptions_choices } from '@frontend/app/queries/types/GetNodeOptions';

const { Option } = Select;

type TProps = {
  selectedOperator: MappedOperator;
  selectedField: FormattedOption;
  value: AutomationVariableValue;
  setValue: (value: AutomationVariableValue) => void;
};

const ConditionValue = (props: TProps) => {
  const {
    selectedOperator,
    selectedField,
    value,
    setValue,
  } = props;

  const showTextInput = useMemo(() => {
    const fields = [FieldType.TEXT, FieldType.EMAIL];
    const operators: MappedOperator[] = [ComparisonOperator.EQUALS, ComboOperator.NOT_EQUALS, ComparisonOperator.CONTAINS, ComboOperator.NOT_CONTAINS];
    return (
      selectedField
      && fields.includes(selectedField.attributeType)
      && (operators).includes(selectedOperator)
      && isEmpty(selectedField.choices)
    );
  }, [selectedField, selectedOperator]);

  const showSingleSelectInput = useMemo(() => {
    const fields = [FieldType.TEXT];
    const operators: MappedOperator[] = [ComparisonOperator.EQUALS, ComboOperator.NOT_EQUALS, ComparisonOperator.CONTAINS, ComboOperator.NOT_CONTAINS];
    return (
      selectedField
      && fields.includes(selectedField.attributeType)
      && (operators).includes(selectedOperator)
      && !isEmpty(selectedField.choices)
    );
  }, [selectedField, selectedOperator]);

  const showNumberDefaultInput = useMemo(() => {
    const fields = [FieldType.NUMBER, FieldType.PERCENTAGE];
    const operators: MappedOperator[] = [
      ComparisonOperator.EQUALS,
      ComboOperator.NOT_EQUALS,
      ComparisonOperator.GREATER_THAN,
      ComparisonOperator.GREATER_THAN_EQUALS,
      ComparisonOperator.LESS_THAN,
      ComparisonOperator.LESS_THAN_EQUALS,
    ];
    return (
      selectedField
      && fields.includes(selectedField.attributeType)
      && (operators).includes(selectedOperator)
    );
  }, [selectedField, selectedOperator]);

  const showNumberBetweenInput = useMemo(() => {
    const fields = [FieldType.NUMBER, FieldType.PERCENTAGE];
    const operators: MappedOperator[] = [ComboOperator.BETWEEN];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showBooleanInput = useMemo(() => {
    const fields = [FieldType.BOOLEAN];
    const operators: MappedOperator[] = [ComparisonOperator.EQUALS, ComboOperator.NOT_EQUALS];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showArrayInput = useMemo(() => {
    const fields = [FieldType.ARRAY];
    const operators: MappedOperator[] = [ComparisonOperator.CONTAINS, ComboOperator.NOT_CONTAINS, ComparisonOperator.EQUALS, ComboOperator.NOT_EQUALS];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showDateDefaultInput = useMemo(() => {
    const fields = [FieldType.DATE];
    const operators: MappedOperator[] = [ComparisonOperator.EQUALS, ComboOperator.NOT_EQUALS, ComparisonOperator.GREATER_THAN, ComparisonOperator.LESS_THAN];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showDateBetweenInput = useMemo(() => {
    const fields = [FieldType.DATE];
    const operators: MappedOperator[] = [ComboOperator.BETWEEN];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showAnnualDefaultInput = useMemo(() => {
    const fields = [FieldType.ANNUAL];
    const operators: MappedOperator[] = [ComparisonOperator.EQUALS, ComboOperator.NOT_EQUALS, ComparisonOperator.GREATER_THAN, ComparisonOperator.LESS_THAN];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showAnnualBetweenInput = useMemo(() => {
    const fields = [FieldType.ANNUAL];
    const operators: MappedOperator[] = [ComboOperator.BETWEEN];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showAnnualNumberInput = useMemo(() => {
    const fields = [FieldType.ANNUAL];
    const operators: MappedOperator[] = [
      ComparisonOperator.IN_THE_LAST,
      ComparisonOperator.IN_THE_NEXT,
      ComparisonOperator.MORE_THAN_AGO,
      ComparisonOperator.LESS_THAN,
      ComboOperator.NOT_IN_THE_LAST,
      ComboOperator.NOT_IN_THE_NEXT,
      ComboOperator.NOT_MORE_THAN_AGO,

    ];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const showDateNumberInput = useMemo(() => {
    const fields = [FieldType.DATE];
    const operators: MappedOperator[] = [
      ComparisonOperator.IN_THE_LAST,
      ComparisonOperator.IN_THE_NEXT,
      ComparisonOperator.MORE_THAN_AGO,
      ComparisonOperator.LESS_THAN,
      ComboOperator.NOT_IN_THE_LAST,
      ComboOperator.NOT_IN_THE_NEXT,
      ComboOperator.NOT_MORE_THAN_AGO,
    ];
    return selectedField && fields.includes(selectedField.attributeType) && (operators).includes(selectedOperator);
  }, [selectedField, selectedOperator]);

  const handleSearch = useFuzzySearchByKeys(selectedField?.choices, ['displayName']);

  const renderForm = () => {
    if (showTextInput) {
      return (
        <TextEqual value={value as string} onChange={setValue} />
      );
    } else if (showBooleanInput) {
      return (
        <BooleanEqual value={value as boolean} onChange={setValue} />
      );
    } else if (showNumberDefaultInput) {
      return (
        <NumberEqual value={value as number} onChange={setValue} />
      );
    } else if (showNumberBetweenInput) {
      return (
        <NumberBetween value={value as [number, number]} onChange={setValue} />
      );
    } else if (showDateDefaultInput) {
      return (
        <DateEqual value={value as Date} onChange={setValue} />
      );
    } else if (showAnnualDefaultInput) {
      return (
        <AnnualEqual value={value as Date} onChange={setValue} />
      );
    } else if (showAnnualBetweenInput) {
      return (
        <AnnualBetween value={value as [Date, Date]} onChange={setValue} />
      );
    } else if (showDateBetweenInput) {
      return (
        <DateBetween value={value as [Date, Date]} onChange={setValue} />
      );
    } else if (showDateNumberInput) {
      return (
        <DaysRelative value={value as number} onChange={setValue} />
      );
    } else if (showAnnualNumberInput) {
      return (
        <AnnualRelative value={value as number} onChange={setValue} />
      );
    } else if (showArrayInput) {
      const options = selectedField.choices.map((option) => ({
        value: option.value,
        label: option.displayName,
      }));
      return (
        <Select
          mode="multiple"
          value={value || []}
          showSearch
          onChange={setValue}
        >
          {options.map((option) => (
            <Option key={option.label} value={option.value}>{option.label}</Option>
          ))}
        </Select>
      );
    } else if (showSingleSelectInput) {
      const handleChange = (selectedChoices: string[]) => {
        setValue(selectedChoices[0]);
      };

      return (
        <IncludeSelect
          showSearch
          onSearchRequest={handleSearch}
          options={selectedField.choices}
          defaultSelectedIds={value ? [value] : []}
          multi={false}
          mapOptionToId={(option: GetNodeOptions_getNodeOptions_conditionOptions_choices) => option.value}
          mapOptionToLabel={(option: GetNodeOptions_getNodeOptions_conditionOptions_choices) => option.displayName}
          onChange={handleChange}
        />
      );
    } else {
      return null;
    }
  };

  return renderForm();
};

export default ConditionValue;
