import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { MESSAGE_TEMPLATE_FOR_PROGRAM_QUERY } from '@frontend/app/queries';
import {
  MessageTemplateForProgramQuery,
  MessageTemplateForProgramQueryVariables,
} from '@frontend/app/queries/types/MessageTemplateForProgramQuery';

type IOptions = QueryHookOptions<MessageTemplateForProgramQuery, MessageTemplateForProgramQueryVariables>;

export const useGetMessageTemplateForProgramQuery = (options: IOptions = {}) => (
  useQuery<MessageTemplateForProgramQuery, MessageTemplateForProgramQueryVariables>(MESSAGE_TEMPLATE_FOR_PROGRAM_QUERY, options)
);
