import 'css-chunk:src/applications/AffiliatesApp/components/MemberConversionDrawer/MemberConversionDrawer.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1fqee_157",
  "justify-content-space-between": "_justify-content-space-between_1fqee_161",
  "tabular-nums": "_tabular-nums_1fqee_165",
  "flexCol": "_flexCol_1fqee_178",
  "drawerHeader": "_drawerHeader_1fqee_183",
  "drawerTitle": "_drawerTitle_1fqee_192",
  "drawerTitleLeft": "_drawerTitleLeft_1fqee_195",
  "drawerTitleText": "_drawerTitleText_1fqee_201",
  "divider": "_divider_1fqee_208",
  "mainContent": "_mainContent_1fqee_213",
  "description": "_description_1fqee_219",
  "descriptionPrimary": "_descriptionPrimary_1fqee_219",
  "descriptionSecondary": "_descriptionSecondary_1fqee_226",
  "memberInfoCard": "_memberInfoCard_1fqee_234",
  "memberInfoItemContainer": "_memberInfoItemContainer_1fqee_239",
  "memberInfoItemTitle": "_memberInfoItemTitle_1fqee_245",
  "memberInfoItemValue": "_memberInfoItemValue_1fqee_252",
  "memberInfoItemValueMargin": "_memberInfoItemValueMargin_1fqee_259",
  "memberInfoPromo": "_memberInfoPromo_1fqee_262",
  "payoutSummaryContainer": "_payoutSummaryContainer_1fqee_266",
  "payoutSummaryDescriptionContainer": "_payoutSummaryDescriptionContainer_1fqee_273",
  "payoutSummaryTitle": "_payoutSummaryTitle_1fqee_278",
  "show": "_show_1fqee_1"
};