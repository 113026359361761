import * as React from 'react';
import cx from 'classnames';
import xss from 'xss';
import { Button, Spinner } from '@revfluence/fresh';
import { TrashIcon, NoteIcon } from '@revfluence/fresh-icons/regular/esm';

import { isEmpty, map, orderBy } from 'lodash';
import { format } from 'date-fns';

import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';
import { useMemberCommentsQuery, useSaveMemberCommentMutation } from '@frontend/app/hooks';

import { RichTextEditor, UserAvatar } from '@frontend/app/components';

import { useAuth } from '@frontend/context/authContext';
import { RichTextVisualizer } from '@frontend/app/components/RichTextEditor/Visualizer';
import { EmptyState } from '../EmptyState';

import styles from './styles.scss';

const {
 useCallback, useEffect, useMemo, useRef, useState,
} = React;

export interface IMemberNotes {
  member: GetMemberQuery_member;
  isLoadingMember: boolean;
}

export const MemberNotes: React.FC<IMemberNotes> = (props) => {
  const {
    member,
    isLoadingMember,
  } = props;

  const [isDisplayRichTextEditor, setIsDisplayRichTextEditor] = useState<boolean>(false);

  const listRef = useRef<HTMLDivElement>();
  const [newNote, setNewNote] = useState<string>();

  const { user } = useAuth();

  const {
    data: {
      member: {
        comments = [],
      } = {},
    } = {},
    loading: isFetchingComments,
    refetch: refetchComments,
  } = useMemberCommentsQuery(member?.id);

  const [postNote, { loading: isSaving }] = useSaveMemberCommentMutation();

  const submitNote = useCallback(() => {
    if (member) {
      postNote({
        variables: {
          comment: {
            text: newNote,
            userId: user.sub,
          },
          memberId: member.id,
        },
        onCompleted: () => {
          setNewNote(undefined);
          refetchComments();
        },
      });
    }
  }, [member, newNote, postNote, refetchComments, user.sub]);

  const renderNote = useCallback((note: typeof comments[number]) => {
    const formattedDate = format(
      new Date(note.createdDate),
      "PPP' at 'p",
    );

    return (
      <div className={styles.note} key={note.id}>
        <UserAvatar name={note.user?.name} />
        <div className={styles.noteContent}>
          <RichTextVisualizer className={styles.noteText}>
            {xss(note.text)}
          </RichTextVisualizer>
          <div>
            {formattedDate}
          </div>
        </div>
      </div>
    );
  }, []);

  useEffect(() => {
    setIsDisplayRichTextEditor(!isEmpty(comments));
  }, [comments]);

  const content = useMemo(() => {
    if (isLoadingMember || isFetchingComments) {
      return (
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      );
    }

    if (isEmpty(comments)) {
      return (
        <EmptyState
          title="No Notes"
          description="Notes about this member will appear here. All notes are internal and will not be shared with this creator."
          icon={NoteIcon}
          actionComponent={(
            <Button
              className={styles.notesEmptyAction}
              onClick={() => setIsDisplayRichTextEditor(true)}
            >
              Add a Note
            </Button>
          )}
        />
      );
    }

    const sortedNotes = orderBy(comments, 'createdDate', 'asc');
    return (
      <div className={styles.notesContainer}>
        {map(sortedNotes, renderNote)}
      </div>
    );
  }, [comments, isFetchingComments, isLoadingMember, renderNote]);

  useEffect(() => {
    if (isLoadingMember || isFetchingComments) {
      return;
    }

    setTimeout(() => {
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 500);
  }, [isFetchingComments, isLoadingMember, comments]);

  return (
    <div className={styles.MemberNotes}>
      <div ref={listRef} className={cx(styles.list, { [styles.emptyNotesSection]: isEmpty(comments) })}>

        {content}
      </div>
      {isDisplayRichTextEditor && (
      <div className={styles.footer}>
        <div className={styles.editor}>
          <RichTextEditor
            onChange={setNewNote}
            className={styles.textEditor}
            placeholder="Add a note"
            value={newNote}
          />
          <div className={styles.editorFooter}>
            {isEmpty(comments) && (
              <Button
                className={styles.closeButton}
                onClick={() => setIsDisplayRichTextEditor(false)}
                icon={<TrashIcon />}
              />
            )}

            <Button
              loading={isSaving}
              disabled={!newNote}
              onClick={submitNote}
            >
              Add Note
            </Button>
          </div>
        </div>
      </div>
       )}
    </div>
  );
};
