import { connect } from 'react-redux';
import { SearchTermType } from '@frontend/components/pages/SearchPage/models';

import {
  IConnectSearchPage,
  IExternalVariables,
  ISearchResultsProps,
  ISearchResults,
} from '../models';
import { fetchNextPage, fetchPage } from '../redux/searchResultsActions';
import { SearchResults } from '../SearchResults';

type TState = IExternalVariables & ISearchResults;

interface IDispatchProps {
  onSelectPage: (page: number) => void;
  fetchNextPage: () => void;
}

const mapStateToProps = (state: IConnectSearchPage) => ({
  ...state.external,
  ...state.searchResults,
  query: state.textSearch.query,
  searchedMentions: state.searchResults.searchedMentions,
  fetchedSearchTermType: state.searchResults.fetchedSearchTermType || SearchTermType.Keywords,
});

const mapDispatchToProps: IDispatchProps = {
  onSelectPage: fetchPage,
  fetchNextPage,
};

export const ConnectedSearchResults = connect<TState, IDispatchProps, ISearchResultsProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResults);
