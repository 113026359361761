import * as React from 'react';
import {
  filter, isEmpty, isNull, map,
} from 'lodash';
import {
  IColumnsType, Select, Tooltip, Typography,
  DatePicker, Spinner,
} from '@revfluence/fresh';
import moment from 'moment';
import { IRowData, ITableColumnConfig, Table } from '@components';

import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { ExclamationCircleFilled } from '@ant-design/icons';
import cx from 'classnames';
import { Notice } from '@affiliates/AspireUI';
import { arrangeTableColumns } from '@frontend/applications/AffiliatesApp/utils/columnOrder';
import { isBefore } from 'date-fns';
import { SpinnerThirdIcon } from '@revfluence/fresh-icons/solid/esm';
import { PotentialCodeHeader } from './PotentialCodeHeader';
import {
  ACTIVE_DATE_FORMAT,
  ACTIVE_TIME_FORMAT,
  TActiveDates,
  TMode,
  TSelectedMember,
} from '../types';
import {
  cleanPromoCode,
  getMemberOfferStatus,
  isOfferShopify,
  isOfferTune,
} from '../utils';
import styles from './MembersWizard.scss';
import { IPayoutVariant } from '../../OfferForm';
import AffiliateTableSTA from '../../AffiliateTableSTA/AffiliateTableSTA';

const { useMemo } = React;

const TABLE_WIDTH = 900;

interface IProps {
  activeDates?: TActiveDates;
  members: readonly TSelectedMember[] | null;
  mode: TMode;
  offerName: string;
  programLabel: string;
  offerSource: OFFER_SOURCE;
  isWorkflow: boolean;
  deepLinkColumn?: boolean;
  updateMemberCode?: (memberId: string, field: string, value: string) => void;
  updateMemberPayoutId?: (memberId: string, field: string, value: number) => void;
  isEditablerow?: boolean
  isNewFlow: boolean
  payouts?: IPayoutVariant[] | null;
  defaultPayoutId?: number | null;
  isManageOffer?: boolean;
  migrateToGraphQL?: boolean;
  isUngrouped?: boolean;
  bulkPayoutId?: number;
  bulkStartDate?: Date,
  bulkEndDate?: Date;
  offerStartDate?: string;
  updateMemberActiveDate?: (memberId: number, field: string, value: Date) => void;
  isSaving?: boolean;
}
export const ReviewMembers: React.FC<Readonly<IProps>> = (props) => {
  const {
    activeDates,
    members,
    mode,
    isWorkflow,
    offerName,
    programLabel,
    offerSource,
    payouts = [],
    defaultPayoutId,
    migrateToGraphQL,
    isManageOffer = false,
    isUngrouped,
    bulkPayoutId,
    offerStartDate,
    isNewFlow,
    isSaving = false,
  } = props;
  const isShopify = isOfferShopify(offerSource);
  const isTune = isOfferTune(offerSource);
  const data = useMemo(() => map(members, (m): IRowData => {
    let errorMessage = '';
    if (m.selected) {
      if (props.mode === 'add' && isEmpty(m.code)) {
        errorMessage = 'Manual entry needed due to missing member details.';
      } else if (m.isDuplicate) {
        errorMessage = 'Code must be unique.';
      }
    }
    switch (mode) {
      case 'add': {
        let payoutId = null;
        let addData = null;
        let startDate: { startDate: Date, onChange: (date: moment.Moment) => void } = null;
        let endDate: { endDate: Date, onChange: (date: moment.Moment) => void } = null;
        if (migrateToGraphQL && defaultPayoutId) {
          payoutId = {
            payoutId: bulkPayoutId || m.payoutId,
            onChange: (event) => {
              props.updateMemberPayoutId(m.id.toString(), 'payoutId', event);
            },
          };
          if (isUngrouped) {
            startDate = {
              startDate: m?.startDate,
              onChange: (date: moment.Moment) => {
                props.updateMemberActiveDate(m.id, 'startDate', date ? date.toDate() : null);
              },
            };
            endDate = {
              endDate: m?.endDate,
              onChange: (date: moment.Moment) => {
                props.updateMemberActiveDate(m.id, 'endDate', date ? date.toDate() : null);
              },
            };
          }
        }
        if (props.isNewFlow) {
          let code = null;
          if (!m.forceCheckIn) {
            code = {
              code: m.code,
              errorMessage,
              onChange: (event) => {
                const input = event.target;
                const cursor = input.selectionStart;
                const preCursor = cleanPromoCode(input.value.slice(0, cursor));
                const postCursor = cleanPromoCode(input.value.slice(cursor));
                const code = `${preCursor}${postCursor}`;
                if (code !== m.code) {
                  props.updateMemberCode(m.id.toString(), 'code', code);
                }
                setTimeout(() => {
                  input.selectionStart = preCursor.length;
                  input.selectionEnd = preCursor.length;
                }, 0);
              },
            };
          }
          addData = {
            code,
            payoutId,
            startDate,
            endDate,
            id: m.id.toString(),
            isDuplicate: m.selected && m.isDuplicate,
            name: m.name,
            instagramUsername: m.instagramUsername,
            offerStatus: getMemberOfferStatus(m, props.isWorkflow, offerSource),
            programs: m.selectedPrograms,
            _bodyRowClassName: cx({
              [styles.codeHasError]: m.selected && (isEmpty(m.code) || m.isDuplicate),
            }),
            disableSelection: m.forceCheckIn,
            deepLink: m.deepLink?.link,
            _raw: m,
          };
        } else {
          addData = {
            code: m.code,
            payoutId,
            id: m.id.toString(),
            name: m.name,
            offerStatus: getMemberOfferStatus(m, props.isWorkflow, offerSource),
            programs: m.selectedPrograms,
            instagramUsername: m.instagramUsername,
            disableSelection: m.forceCheckIn,
            deepLink: m.deepLink?.link,
            _raw: m,
          };
        }
        if (!migrateToGraphQL && !defaultPayoutId) {
          delete addData.payoutId;
        }
        return addData;
      }
      case 'refresh':
        return {
          code: m.code,
          id: m.id.toString(),
          key: m.id.toString(),
          name: m.name,
          offerName: m.forceCheckIn ? getMemberOfferStatus(m, props.isWorkflow, offerSource) : offerName,
          previousCode: m.previousCode.code,
          _raw: m,
        };
      case 'refreshDates': {
        const startDate = moment(
          `${activeDates.startDate} ${activeDates.startTime}`,
        ).format(
          `${ACTIVE_DATE_FORMAT}, ${ACTIVE_TIME_FORMAT}`,
        );
        const endDate = moment(
          `${activeDates.endDate} ${activeDates.endTime}`,
        ).format(
          `${ACTIVE_DATE_FORMAT}, ${ACTIVE_TIME_FORMAT}`,
        );
        return {
          code: m.code,
          endDate,
          id: m.id.toString(),
          key: m.id.toString(),
          name: m.name,
          offerName,
          previousCode: m.previousCode.code,
          startDate,
          _raw: m,
        };
      }
      case 'deactivate':
        return {
          id: m.id.toString(),
          key: m.id.toString(),
          name: m.name,
          offerName,
          previousCode: m.previousCode.code,
          previousCodeEndDate: moment(m.previousCode.end).format(ACTIVE_DATE_FORMAT),
          previousCodeStartDate: moment(m.previousCode.start).format(ACTIVE_DATE_FORMAT),
          _raw: m,
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [activeDates, members, mode, offerName]);
  const payoutOptions: { id: number, label: string }[] = useMemo(() => payouts.map((payout) => {
    let option = null;
    if (!isNull(payout.percentPayout) && !isNull(payout.flatPayout)) option = { id: payout.id, label: `${payout.label} (${payout.percentPayout}% + $${payout.flatPayout})` };
    else if (!isNull(payout.flatPayout)) option = { id: payout.id, label: `${payout.label} ($${payout.flatPayout})` };
    else if (!isNull(payout.percentPayout)) option = { id: payout.id, label: `${payout.label} (${payout.percentPayout}%)` };
    return option;
  }), [payouts]);
  const columnConfigNew: IColumnsType<IRowData> = useMemo(() => {
    switch (mode) {
      case 'add':
        const columns: IColumnsType<IRowData> = [
          {
            dataIndex: 'name',
            title: 'Member',
            key: 'name',
            width: Math.floor(TABLE_WIDTH / 4),
          },
        ];
        if (!isNewFlow) {
          if (isWorkflow || props.isNewFlow) {
            columns.push({
              title: 'Instagram',
              dataIndex: 'instagramUsername',
              key: 'instagramUsername',
              width: Math.floor(TABLE_WIDTH / 4),
              render: (params) => {
                if (params === '') {
                  return '-';
                } else {
                  return params;
                }
              },
            });
          } else {
            columns.push({
              title: programLabel,
              key: 'programs',
              dataIndex: 'programs',
              width: Math.floor(TABLE_WIDTH / 4),
            });
          }
        }

        if (!migrateToGraphQL && !defaultPayoutId) {
          columns.push({
            title: 'Offer',
            key: 'offerStatus',
            dataIndex: 'offerStatus',
            width: Math.floor(TABLE_WIDTH / 4),
          });
        }
        if (isTune && props.deepLinkColumn) {
          columns.push({
            title: 'Deep Link',
            key: 'deepLink',
            dataIndex: 'deepLink',
            width: Math.floor(TABLE_WIDTH / 4),
            render: (value: string) => (isEmpty(value) ? <Typography.Text type="secondary">N/A</Typography.Text> : value),
          });
        }
        if (isShopify) {
          if (props.isNewFlow) {
            columns.push({
              title: <PotentialCodeHeader />,
              key: 'code',
              dataIndex: 'code',
              render: (data) => {
                if (!data) {
                  return null;
                }
                return (
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.codeInput}
                      onChange={data.onChange}
                      placeholder="TYPE CODE HERE"
                      value={data.code}
                      disabled={!props.isEditablerow}
                    />
                    {data.errorMessage && (
                      <Tooltip
                        arrowPointAtCenter
                        overlayStyle={{
                          maxWidth: 270,
                        }}
                        placement="topRight"
                        title={data.errorMessage}
                      >
                        <ExclamationCircleFilled style={{ right: isUngrouped ? '1rem' : '7rem' }} />
                      </Tooltip>
                    )}
                  </div>
                );
              },
              width: Math.floor((TABLE_WIDTH) / 4),
            });
          } else {
            columns.push({
              title: <PotentialCodeHeader />,
              key: 'code',
              dataIndex: 'code',
              width: Math.floor(TABLE_WIDTH / 4),
            });
          }
        }
        if (migrateToGraphQL && defaultPayoutId) {
          columns.push({
            title: 'Payout',
            key: 'payoutId',
            dataIndex: 'payoutId',
            render: (data) => (
              <Select style={{ width: '100%' }} size="large" defaultValue={data?.payoutId ?? defaultPayoutId} onChange={data.onChange} disabled={!props.isEditablerow}>
                {
                  payoutOptions.map((payout) => (
                    <Select.Option value={payout.id} key={payout.id}>{payout.label}</Select.Option>
                  ))
                }
              </Select>
            ),
            width: Math.floor(TABLE_WIDTH / 4),
          });
          if (isUngrouped) {
            columns.push({
              title: 'Offer Start Date',
              key: 'startDate',
              dataIndex: 'startDate',
              width: Math.floor(TABLE_WIDTH / 4),
              render: (data) => {
                const disableStartDate = (current) => {
                  if (!offerStartDate) {
                    return true;
                  }
                  const startDate = new Date(offerStartDate);
                  return current && current < startDate;
                };
                return (
                  (
                    <DatePicker
                      showTime={{ format: 'hh:mm a' }}
                      format="MMM D, YYYY hh:mm a"
                      size="large"
                      value={data?.startDate ? moment(data.startDate) : null}
                      onChange={(date) => data.onChange(date)}
                      style={{ borderColor: 'none' }}
                      disabledDate={disableStartDate}
                      status={data?.startDate ? '' : 'error'}
                    />
                  )
                );
              },
            });
            columns.push({
              title: 'Offer End Date (Optional)',
              key: 'endDate',
              dataIndex: 'endDate',
              width: Math.floor(TABLE_WIDTH / 4),
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              render: (data, context: any) => {
                const disableEndDate = (current) => {
                  let disableDateTill = context?.startDate?.startDate;
                  if (!disableDateTill) {
                    return true;
                  }
                  disableDateTill = isBefore(new Date(context.startDate.startDate), new Date()) ? new Date() : context.startDate.startDate;
                  const startDate = new Date(disableDateTill);
                  return current && current < startDate;
                };
                return (
                  <DatePicker
                    showTime={{ format: 'hh:mm a' }}
                    format="MMM D, YYYY hh:mm a"
                    size="large"
                    value={data?.endDate ? moment(data.endDate) : null}
                    onChange={(date) => data.onChange(date)}
                    style={{ borderColor: 'none' }}
                    disabledDate={disableEndDate}
                    status={data?.endDate && context?.rowData?.startDate?.startDate && isBefore(data?.endDate, context?.rowData?.startDate?.startDate) ? 'error' : ''}
                  />
                );
              },
            });
          }
        }
        return columns;
      case 'refresh':
        let refreshColumns = [];
        if (isShopify) {
          refreshColumns = [
            {
              title: 'Member',
              key: 'name',
              dataIndex: 'name',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 4)),
            },
            {
              title: 'Offer',
              key: 'offerName',
              dataIndex: 'offerName',
              width: Math.floor(TABLE_WIDTH / 4),
            },
            {
              title: 'Previous Code',
              key: 'previousCode',
              dataIndex: 'previousCode',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 4)),
            },
          ];
          if (isManageOffer) {
            refreshColumns.push({
              title: <PotentialCodeHeader />,
              key: 'code',
              dataIndex: 'code',
              render: (data) => {
                if (!data) {
                  return null;
                }
                return (
                  <div className={styles.MembersWizard}>

                    <div className={styles.inputWrapper}>
                      <input
                        className={styles.codeInput}
                        onChange={data.onChange}
                        placeholder="TYPE CODE HERE"
                        value={data.code}
                      />
                      {data.errorMessage && (
                        <Tooltip
                          arrowPointAtCenter
                          overlayStyle={{
                            maxWidth: 270,
                          }}
                          placement="topRight"
                          title={data.errorMessage}
                        >
                          <ExclamationCircleFilled />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                );
              },
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 4)),
            });
          } else {
            refreshColumns.push({
              title: <PotentialCodeHeader />,
              key: 'code',
              dataIndex: 'code',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 4)),
            });
          }
        }
        if (isTune) {
          refreshColumns = [
            {
              title: 'Member',
              key: 'name',
              dataIndex: 'name',
              width: Math.floor(TABLE_WIDTH / 2),
            },
            {
              title: 'Offer',
              key: 'offerName',
              dataIndex: 'offerName',
              width: Math.floor(TABLE_WIDTH / 2),
            },
          ];
        }
        if (isManageOffer) refreshColumns = refreshColumns.filter((c) => ['Offer'].indexOf(c.headerName) < 0);
        return refreshColumns;
        break;
      case 'refreshDates':
        return [
          {
            title: 'Member',
            key: 'name',
            dataIndex: 'name',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            title: 'Offer',
            key: 'offerName',
            dataIndex: 'offerName',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            title: 'Active Code',
            key: 'previousCode',
            dataIndex: 'previousCode',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            title: 'Start Date',
            key: 'startDate',
            dataIndex: 'startDate',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            title: 'End Date',
            key: 'endDate',
            dataIndex: 'endDate',
            width: Math.floor(TABLE_WIDTH / 5),
          },
        ];
      case 'deactivate':
        if (isShopify) {
          const columns = [
            {
              title: 'Member',
              key: 'name',
              dataIndex: 'name',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 3)),
            },
            {
              title: 'Offer',
              key: 'offerName',
              dataIndex: 'offerName',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 7 : 3)),
            },
            {
              title: 'Current Code',
              key: 'previousCode',
              dataIndex: 'previousCode',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 3)),
            },
          ];
          if (!isManageOffer) {
            if (!props.isNewFlow) {
              columns.push(
                {
                  title: 'Previous Start Date',
                  key: 'previousCodeStartDate',
                  dataIndex: 'previousCodeStartDate',
                  width: Math.floor(TABLE_WIDTH / 3),
                },
                {
                  title: 'Previous End Date',
                  key: 'previousCodeEndDate',
                  dataIndex: 'previousCodeEndDate',
                  width: Math.floor(TABLE_WIDTH / 3),
                },
              );
            }
          }
          return columns;
        }
        if (isTune) {
          return [
            {
              title: 'Member',
              key: 'name',
              dataIndex: 'name',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 2)),
            },
            {
              title: 'Offer',
              key: 'offerName',
              dataIndex: 'offerName',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 2)),
            },
            {
              title: 'Affiliate Link',
              key: 'link',
              dataIndex: 'link',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 2)),
            },

          ];
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, programLabel, isShopify, isTune]);

  const columnConfig: ITableColumnConfig[] = useMemo(() => {
    switch (mode) {
      case 'add':
        const columns: ITableColumnConfig[] = [
          {
            cellType: 'text',
            headerName: 'Member',
            field: 'name',
            width: Math.floor(TABLE_WIDTH / 4),
          },
        ];
        if (!isNewFlow) {
          if (isWorkflow || props.isNewFlow) {
            columns.push({
              cellType: 'text',
              headerName: 'Instagram',
              field: 'instagramUsername',
              width: Math.floor(TABLE_WIDTH / 4),
              render: (params) => {
                if (params.props.children === '') {
                  return '-';
                } else {
                  return params.props.children;
                }
              },
            });
          } else {
            columns.push({
              cellType: 'text',
              headerName: programLabel,
              field: 'programs',
              width: Math.floor(TABLE_WIDTH / 4),
            });
          }
        }

        if (!migrateToGraphQL && !defaultPayoutId) {
          columns.push({
            cellType: 'text',
            headerName: 'Offer',
            field: 'offerStatus',
            width: Math.floor(TABLE_WIDTH / 4),
          });
        }
        if (isTune && props.deepLinkColumn) {
          columns.push({
            headerName: 'Deep Link',
            cellType: 'link',
            field: 'deepLink',
            width: Math.floor(TABLE_WIDTH / 4),
            render: (value: string) => (isEmpty(value) ? <Typography.Text type="secondary">N/A</Typography.Text> : value),
          });
        }
        if (isShopify) {
          if (props.isNewFlow) {
            columns.push({
              headerName: <PotentialCodeHeader />,
              field: 'code',
              render: (data) => {
                if (!data) {
                  return null;
                }
                return (
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.codeInput}
                      onChange={data.onChange}
                      placeholder="TYPE CODE HERE"
                      value={data.code}
                      disabled={!props.isEditablerow}
                    />
                    {data.errorMessage && (
                      <Tooltip
                        arrowPointAtCenter
                        overlayStyle={{
                          maxWidth: 270,
                        }}
                        placement="topRight"
                        title={data.errorMessage}
                      >
                        <ExclamationCircleFilled />
                      </Tooltip>
                    )}
                  </div>
                );
              },
              sortable: false,
              width: Math.floor((TABLE_WIDTH) / 4),
            });
          } else {
            columns.push({
              headerName: <PotentialCodeHeader />,
              field: 'code',
              width: Math.floor(TABLE_WIDTH / 4),
            });
          }
        }
        if (migrateToGraphQL && defaultPayoutId) {
          columns.push({
            headerName: 'Payout',
            field: 'payoutId',
            render: (data) => (
              <Select style={{ width: '100%' }} size="large" defaultValue={data?.payoutId ?? defaultPayoutId} onChange={data.onChange} disabled={!props.isEditablerow}>
                {
                  payoutOptions.map((payout) => (
                    <Select.Option value={payout.id} key={payout.id}>{payout.label}</Select.Option>
                  ))
                }
              </Select>
            ),
            width: Math.floor(TABLE_WIDTH / 4),
          });
          if (isUngrouped) {
            columns.push({
              headerName: 'Offer Start Date',
              field: 'startDate',
              width: Math.floor(TABLE_WIDTH / 4),
              render: (data) => {
                const disableStartDate = (current) => {
                  if (!offerStartDate) {
                    return true;
                  }
                  const startDate = new Date(offerStartDate);
                  return current && current < startDate;
                };
                return (
                  (
                    <DatePicker
                      showTime={{ format: 'hh:mm a' }}
                      format="MMM D, YYYY hh:mm a"
                      size="large"
                      value={data?.startDate ? moment(data.startDate) : null}
                      onChange={(date) => data.onChange(date)}
                      style={{ borderColor: 'none' }}
                      disabledDate={disableStartDate}
                      status={data?.startDate ? '' : 'error'}
                    />
                  )
                );
              },
            });
            columns.push({
              headerName: 'Offer End Date (Optional)',
              field: 'endDate',
              width: Math.floor(TABLE_WIDTH / 4),
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              render: (data, context: any) => {
                const disableEndDate = (current) => {
                  let disableDateTill = context?.rowData?.startDate?.startDate;
                  if (!disableDateTill) {
                    return true;
                  }
                  disableDateTill = isBefore(new Date(context.rowData.startDate.startDate), new Date()) ? new Date() : context.rowData.startDate.startDate;
                  const startDate = new Date(disableDateTill);
                  return current && current < startDate;
                };
                return (
                  <DatePicker
                    showTime={{ format: 'hh:mm a' }}
                    format="MMM D, YYYY hh:mm a"
                    size="large"
                    value={data?.endDate ? moment(data.endDate) : null}
                    onChange={(date) => data.onChange(date)}
                    style={{ borderColor: 'none' }}
                    disabledDate={disableEndDate}
                    status={data?.endDate && context?.rowData?.startDate?.startDate && isBefore(data?.endDate, context?.rowData?.startDate?.startDate) ? 'error' : ''}
                  />
                );
              },
            });
          }
        }
        return columns;
      case 'refresh':
        let refreshColumns = [];
        if (isShopify) {
          refreshColumns = [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 4)),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor(TABLE_WIDTH / 4),
            },
            {
              cellType: 'text',
              headerName: 'Previous Code',
              field: 'previousCode',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 4)),
            },
          ];
          if (isManageOffer) {
            refreshColumns.push({
              headerName: <PotentialCodeHeader />,
              field: 'code',
              render: (data) => {
                if (!data) {
                  return null;
                }
                return (
                  <div className={styles.MembersWizard}>

                    <div className={styles.inputWrapper}>
                      <input
                        className={styles.codeInput}
                        onChange={data.onChange}
                        placeholder="TYPE CODE HERE"
                        value={data.code}
                      />
                      {data.errorMessage && (
                        <Tooltip
                          arrowPointAtCenter
                          overlayStyle={{
                            maxWidth: 270,
                          }}
                          placement="topRight"
                          title={data.errorMessage}
                        >
                          <ExclamationCircleFilled />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                );
              },
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 4)),
            });
          } else {
            refreshColumns.push({
              headerName: <PotentialCodeHeader />,
              field: 'code',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 4)),
            });
          }
        }
        if (isTune) {
          refreshColumns = [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor(TABLE_WIDTH / 2),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor(TABLE_WIDTH / 2),
            },
          ];
        }
        if (isManageOffer) refreshColumns = refreshColumns.filter((c) => ['Offer'].indexOf(c.headerName) < 0);
        return refreshColumns;
        break;
      case 'refreshDates':
        return [
          {
            cellType: 'text',
            headerName: 'Member',
            field: 'name',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            cellType: 'text',
            headerName: 'Offer',
            field: 'offerName',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            cellType: 'text',
            headerName: 'Active Code',
            field: 'previousCode',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            cellType: 'text',
            headerName: 'Start Date',
            field: 'startDate',
            width: Math.floor(TABLE_WIDTH / 5),
          },
          {
            cellType: 'text',
            headerName: 'End Date',
            field: 'endDate',
            width: Math.floor(TABLE_WIDTH / 5),
          },
        ];
      case 'deactivate':
        if (isShopify) {
          const columns: ITableColumnConfig[] = [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 3)),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 7 : 3)),
            },
            {
              cellType: 'text',
              headerName: 'Current Code',
              field: 'previousCode',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 3)),
            },
          ];
          if (!isManageOffer) {
            if (!props.isNewFlow) {
              columns.push(
                {
                  cellType: 'text',
                  headerName: 'Previous Start Date',
                  field: 'previousCodeStartDate',
                  width: Math.floor(TABLE_WIDTH / 3),
                },
                {
                  cellType: 'text',
                  headerName: 'Previous End Date',
                  field: 'previousCodeEndDate',
                  width: Math.floor(TABLE_WIDTH / 3),
                },
              );
            }
          }
          return columns;
        }
        if (isTune) {
          return [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 2)),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 6 : 2)),
            },
            {
              cellType: 'text',
              headerName: 'Affiliate Link',
              field: 'link',
              width: Math.floor(TABLE_WIDTH / (isManageOffer ? 5 : 2)),
            },

          ];
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, programLabel, isShopify, isTune]);

  const getMissingCodesAlert = (
    mode: IProps['mode'],
    offerSource: IProps['offerSource'],
    members: IProps['members'],
  ): React.ReactElement => {
    if (mode !== 'add' || offerSource !== OFFER_SOURCE.SHOPIFY) {
      return null;
    }
    const missingCodeCount = filter(members, (m) => m.selected && m.code.length < 1).length;
    if (missingCodeCount < 1) {
      return null;
    }
    const message = `
        ${missingCodeCount.toLocaleString()} member${missingCodeCount !== 1 ? 's' : ''}
        do${missingCodeCount !== 1 ? '' : 'es'} not have the data required to generate
        a promo code. You can manually enter a promo code for them or leave them
        out of this offer for the time being.
      `.trim();
    return (
      <Notice type="error" className={styles.alert} message={message} />
    );
  };

  const getDuplicateCodesAlert = (members: IProps['members']): React.ReactElement => {
    const duplicateCodeCount = filter(members, (m) => m.selected && m.isDuplicate).length;
    if (duplicateCodeCount < 1) {
      return null;
    }
    const message = `
      ${duplicateCodeCount.toLocaleString()} members will have the same code.
      Each member must have a unique promo code so Shopify can properly
      attribute conversion data to the member.
    `.trim();
    return (
      <Notice type="error" className={styles.alert} message={message} />
    );
  };

  const getActiveDatesAlerts = (members: IProps['members']): React.ReactElement => {
    const duplicateCodeCount = members.filter((m) => !m.startDate || (m.startDate && m.endDate && isBefore(m.endDate, m.startDate))).length;
    if (duplicateCodeCount < 1) {
      return null;
    }
    const message = `
      ${duplicateCodeCount.toLocaleString()} members have wrong dates.
    `.trim();
    return (
      <Notice type="error" className={styles.alert} message={message} />
    );
  };
  if (isSaving) {
    return <Spinner className={styles.loading} indicator={<SpinnerThirdIcon style={{ fontSize: '32px' }} />} />;
  }
  return (
    <>
      {props.isNewFlow ? (
        <>
          {getMissingCodesAlert(props.mode, props.offerSource, members)}
          {getDuplicateCodesAlert(members)}
        </>
      ) : null}
      {props.isUngrouped && props.isNewFlow ? (
        <>
          {getActiveDatesAlerts(members)}
        </>
      ) : null}

      {isNewFlow ? (
        <AffiliateTableSTA<IRowData>
          dataSource={data}
          columns={arrangeTableColumns(columnConfigNew)}
          pagination={false}
          rowClassName={(record) => record._bodyRowClassName}
        />
) : (
  <Table
    columns={columnConfig}
    config={{
          allowSearch: false,
          configurableColumns: false,
          reorderableColumns: false,
          rowHeight: 32,
          scrollable: true,
          selectable: false,
          resizableColumns: true,
          striped: false,
        }}
    data={data}
    className={styles.tablePadding}
    paddingBottom={100}
  />
)}
    </>
  );
};
