import * as React from 'react';
import cx from 'classnames';
import moment from 'moment';

import {
  Checkbox, Button,
} from '@components';

import {
  RequirementsForMemberQuery_requirements as IRequirement,
} from '@frontend/app/queries/types/RequirementsForMemberQuery';

import styles from './RequirementCell.scss';

interface IProps {
  requirementState: IRequirementWithState;
  onRequirementSelected(requirementWithState: IRequirementWithState): void;
  onMarkCompleteSelected(requirementWithState: IRequirementWithState, markComplete: boolean): void;
  selected: boolean;
}

export interface IRequirementWithState {
  requirement: IRequirement;
  markComplete: boolean;
}

export const RequirementCell: React.FunctionComponent<IProps> = (props) => {
  const {
 requirementState, onRequirementSelected, onMarkCompleteSelected, selected,
} = props;
  const requirement = requirementState.requirement;

  const toggleMarkComplete = (event) => {
    onMarkCompleteSelected(requirementState, !requirementState.markComplete);
    event.stopPropagation();
  };

  return (
    <div
      className={cx(selected ? styles.SelectedCell : styles.UnselectedCell, styles.RequirementCell)}
      onClick={() => onRequirementSelected(requirementState)}
    >

      <Checkbox
        checked={selected}
        className={styles.StandardCheckbox}
        onChange={(checked) => checked && onRequirementSelected(requirementState)}
      />
      <div className={styles.TextWrap}>
        <div className={styles.Name}>{requirement.name}</div>
        <span className={styles.DueDate}>
          Due by
          {' '}
          {moment(requirement.dueDate).format('M/D')}
        </span>
        {selected
          && (
          <Button
            label="Mark Requirement Complete"
            onClick={toggleMarkComplete}
            theme={requirementState.markComplete ? 'primary' : 'light'}
          />
)}
      </div>
    </div>
  );
};
