import {
  filter,
  get,
  isBoolean,
  isNaN,
  isNil,
  isString,
  map,
  some,
} from 'lodash';

import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { FieldType } from '@frontend/app/types/Fields';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sampleValues: any[][];
  selectedFields: IField[];
}

export const useFieldValidation = (props: IProps): boolean[] => {
  const {
    sampleValues,
    selectedFields,
  } = props;

  return map(selectedFields, (field, i) => {
    if (!field) {
      return false;
    }
    const values = filter(get(sampleValues, i), (v) => !isNil(v));
    if (field.type === FieldType.BOOLEAN) {
      return some(values, (value) => (
        isString(value)
          ? !['true', 'false', 'yes', 'no'].includes(value.toLocaleLowerCase())
          : !isBoolean(value)
      ));
    } else if (field.type === FieldType.DATE || field.type === FieldType.ANNUAL) {
      return some(values, (value) => isNaN(Date.parse(value)));
    } else if (field.type === FieldType.NUMBER) {
      return some(values, (value) => isNaN(Number(value)));
    } else if (field.type === FieldType.TEXT || field.type === FieldType.EMAIL) {
      return some(values, (value) => !isString(value));
    }
    return false;
  });
};
