import { IRowData } from '@components';

import { OFFER_SOURCE, OFFER_TYPE } from '@affiliates/types/globalTypes';

export type TOnClickAddOffer = Required<{
  [key in OFFER_SOURCE]: () => void;
}>;

export interface IOfferTableRow extends IRowData {
  avgSale: number;
  clicks: number | null;
  conversions: number;
  id: string;
  members: number;
  offerName: {
    imageUrl: string;
    name: string;
  };
  payoutEarned: number;
  payoutMade: number;
  salesAmount: number;
  status: OFFER_STATUS;
  url: string | null;
}

export enum OFFER_STATUS {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  PAUSED = 'PAUSED',
  EXPIRED = 'EXPIRED',
  ARCHIVED = 'ARCHIVED',
}

export interface IOfferTableCsvRow extends Omit<IOfferTableRow, 'offerName'> {
  offerName: string;
}

export interface IDashboardOffer {
  avgSale: number;
  clicks: number | null;
  conversions: number;
  createdDate: Date;
  members: number;
  offerId: number;
  offerImageUrl: string | null;
  offerName: string;
  payoutEarned: number;
  payoutMade: number;
  salesAmount: number;
  source: OFFER_SOURCE;
  status: OFFER_STATUS;
  url: string | null;
  expired: boolean;
  programId: number;
  offerType: OFFER_TYPE;
  archivedDate: Date | null;
}
