import gql from 'graphql-tag';

export const GET_MULTIPLE_SHOPIFY_STATS_QUERY = gql`
  query GetMultipleShopifyStats($endDate: DateTime,$offerId: Int!, $startDate: DateTime) {
    storeStats: multipleShopifyStats(endDate: $endDate,offerId: $offerId,startDate: $startDate) {
      avgSale
      avgSaleBase
      storeName
      clientShopifyConnectionId
      conversions
      currency
      payoutEarned
      payoutEarnedBase
      sales
      salesBase
    } 
  }
`;
