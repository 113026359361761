import * as React from 'react';
import cx from 'classnames';
import { first } from 'lodash';

import {
 FileSelector, IFileSelectorRefs, ImageStackIcon, CloseModalIcon, LoadSpinner,
} from '@components';

import styles from './DragAndDropImageInput.scss';

const { useRef, useEffect } = React;

interface ISelectedImageProps {
  src?: string;

  onRemoveImage?();
}

const SelectedImage: React.FunctionComponent<ISelectedImageProps> = React.memo((props) => (
  <div className={cx(styles.SelectedImage)}>
    <div className={styles.closeButton} onClick={props.onRemoveImage}>
      <CloseModalIcon size={20} className={styles.closeIcon} />
    </div>
    <div key={props.src} style={{ backgroundImage: `url(${props.src})` }} className={styles.img} />
  </div>
));

interface IProps {
  imageSrc?: string;
  isLoading?: boolean;

  onSelectImage(file: File);
  onRemoveImage();
}

export const DragAndDropImageInput: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    imageSrc,
    isLoading,
    onRemoveImage,
    onSelectImage,
  } = props;

  const fileSelectorRef = useRef<IFileSelectorRefs>();
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const current = ref.current;
    const preventDefault = (event: DragEvent) => event.preventDefault();
    // bind drag events
    current.addEventListener('dragover', preventDefault);
    current.addEventListener('dragenter', preventDefault);
    current.addEventListener('dragleave', preventDefault);
    current.addEventListener('dragend', preventDefault);

    const handleDrop = (event: DragEvent) => {
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files;
      const file = first(files);
      if (file) {
        onSelectImage(file);
      }
    };

    current.addEventListener('drop', handleDrop);

    return () => {
      if (current) {
        current.removeEventListener('dragover', preventDefault);
        current.removeEventListener('dragenter', preventDefault);
        current.removeEventListener('dragleave', preventDefault);
        current.removeEventListener('dragend', preventDefault);
        current.removeEventListener('drop', handleDrop);
      }
    };
  }, [onSelectImage]);

  const handleClick = () => {
    if (fileSelectorRef.current) {
      fileSelectorRef.current.openFileSelector();
    }
  };

  const handleFilesSelectedChange = (files: FileList) => {
    onSelectImage(first(files));
  };

  return (
    <div
      ref={ref}
      className={cx(styles.DragAndDropImageInput, {
        [styles.hasImage]: !!imageSrc && !isLoading,
      })}
      onClick={handleClick}
    >
      <div className={styles.wrapper}>
        {isLoading && <LoadSpinner className={styles.spinner} />}

        {!isLoading && imageSrc && (
          <SelectedImage src={imageSrc} onRemoveImage={onRemoveImage} />
        )}

        {!isLoading && !imageSrc && (
          <>
            <div className={styles.iconWrapper}>
              <ImageStackIcon size={28} />
            </div>
            <div className={styles.right}>
              <div className={styles.title}>Drag & Drop</div>
              <div className={styles.subtitle}>
                Drop any .jpg, .png, .gif or
                {' '}
                <span>browse</span>
              </div>
            </div>
            <FileSelector
              className={styles.fileSelector}
              fileSelectorRef={fileSelectorRef}
              accept="image/*"
              onFilesSelected={handleFilesSelectedChange}
            />
          </>
        )}
      </div>
    </div>
  );
});
