// MetricsCard.tsx
import * as React from 'react';
import { formatters } from '@frontend/app/components/StatisticsCard/formatters';
import Trend from '@frontend/applications/ReportsApp/components/Trend';
import {
 endOfMonth, isAfter, startOfMonth, format, addMonths,
} from 'date-fns';
import { getLastFullMonths } from '@frontend/applications/ReportsApp/containers/utils';
import { getTrendProps } from '@frontend/applications/ReportsApp/pages/Reporting/components/MetricsSecondary/MetricsSecondary';
import StatsComponent from '@frontend/applications/Shared/components/RefreshStatsComponent';
import { capitalize } from 'lodash';
import { getBackgroundColor } from '@frontend/applications/ReportsApp/utils/getTrendColor';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import Text from '@frontend/app/refresh-components/Text';
import { P } from '@frontend/shadcn/components/typography/p';
import RefreshMetricChart from '@frontend/applications/ReportsApp/components/MetricChart/RefreshMetricChart';
import { formatDateTimeString } from '../../../../../containers/Chart/LineChartContainer';
import { IHistoricalData } from '../../../Home';

interface IMetricsCardProps {
  title: TPrimaryMetric;
  value: number | undefined;
  historicalData: IHistoricalData[];
  trendPercentDiff: number | null;
  trendTooltip: string;
  chartColor: string;
  statsTooltip: string;
}

const getValue = (n?: number) => (n ? formatters.default(n) : '-');
type TPrimaryMetric = 'content' | 'impressions' | 'engagements' | 'sales';

const getEmptyHistoricalData = (): IHistoricalData[] => {
  const [startDate] = getLastFullMonths(4);
  const formatDate = (d: Date) => format(d, 'yyyy/MM/dd');
  return [
    { dateTime: formatDate(startDate), value: 0 },
    { dateTime: formatDate(addMonths(startDate, 1)), value: 0 },
    { dateTime: formatDate(addMonths(startDate, 2)), value: 0 },
    { dateTime: formatDate(addMonths(startDate, 3)), value: 0 },
  ];
};

const getBarChartTooltip = (value: number, metricType: TPrimaryMetric): string => {
  const valueString = formatters.default(value);

  switch (metricType) {
    case 'content':
      return `${valueString} piece${value !== 1 ? 's' : ''} of content`;
    case 'impressions':
      return `${valueString} impression${value !== 1 ? 's' : ''}`;
    case 'engagements':
      return `${valueString} engagement${value !== 1 ? 's' : ''}`;
    case 'sales':
      return `$${valueString} of sales`;
  }
};

const mapBarChartData = (data: IHistoricalData[], metricType: TPrimaryMetric) => {
  const historicalData = data.length === 0 ? getEmptyHistoricalData() : data;

  return historicalData.slice(-4).map(({ dateTime, value }) => {
    const date = new Date(formatDateTimeString(dateTime));
    const today = new Date();
    const monthEnd = endOfMonth(date);
    const isAfterToday = isAfter(monthEnd, today);
    const startDate = startOfMonth(date);
    const endDate = isAfterToday ? today : monthEnd;
    const startDateString = format(startDate, 'MMM d');
    const endDateString = format(endDate, 'd');
    return {
      value,
      tooltipTitle: `${startDateString} - ${endDateString}`,
      tooltipDescription: getBarChartTooltip(value, metricType),
    };
  });
};

const chartColorClasses = {
  'chartColors-blue': 'bg-chartColors-blueBackground',
  'chartColors-orange': 'bg-chartColors-orangeBackground',
  'chartColors-green': 'bg-chartColors-greenBackground',
  'chartColors-plum': 'bg-chartColors-plumBackground',
};

const PrimaryMetricsCard: React.FC<IMetricsCardProps> = ({
  title,
  value,
  historicalData,
  trendPercentDiff,
  trendTooltip,
  chartColor,
  statsTooltip,
}) => {
  const trendValue = isFinite(trendPercentDiff)
    ? formatters.default(Math.abs(trendPercentDiff), { precision: 0 })
    : '0';
  const { color } = getTrendProps(trendPercentDiff);
  const chartColorClass = chartColorClasses[chartColor] || 'bg-chartColors-blueBackground';
  const getTrendColor = (color: string) => (color === 'gray' ? 'gray' : 'white');
  return (
    <Card className={`p-4 w-full ${chartColorClass}`}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <StatsComponent
              title={(
                <P className="m-0" fontWeight="font-medium">
                  {capitalize(title)}
                </P>
              )}
              valueRender={(
                <Text visualVariant="h1" className="text-primary">
                  {getValue(value)}
                </Text>
              )}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent className="max-w-xs">{trendTooltip}</TooltipContent>
      </Tooltip>
      <div className="flex justify-between w-full items-end pt-4">
        <StatsComponent
          valueRender={(
            <div
              className={`text-primary-foreground px-2 py-1 rounded flex items-center gap-2 ${getBackgroundColor(
                color,
              )}`}
            >
              <Trend {...getTrendProps(trendPercentDiff)} color={getTrendColor(color)} />
              <span className={`text-xs ${color === 'gray' ? 'text-grey-3' : 'text-primary-foreground'}`}>
                {trendValue}
                %
              </span>
            </div>
          )}
          {...getTrendProps(trendPercentDiff)}
          tooltip={statsTooltip}
        />
        <RefreshMetricChart color={chartColor} chart={mapBarChartData(historicalData, title)} />
      </div>
    </Card>
  );
};

export default PrimaryMetricsCard;
