import { useState, useEffect } from 'react';

import { logger } from '@common';

export interface IContractTemplate {
  id: string;
  name: string;
}

const fetchContractTemplatesData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

export function useFetchContractTemplatesData(url: string, clientId: string) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<[IContractTemplate]>(null);
  const [error, setError] = useState(null);

  const finalUrl = `${url}?client_id=${clientId}`;

  useEffect(() => {
    setLoading(true);
    fetchContractTemplatesData(finalUrl)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return {
    loading,
    data,
    error,
  };
}
