import { ContentReviewState, IContentReview } from '../models';

const completedStates = [
  ContentReviewState.COMPLETED_WITHOUT_PAYMENT,
  ContentReviewState.COMPLETED,
];

export function isWaitingForSubmission(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.WAITING_FOR_SUBMISSION;
}

export function isGCRPending(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.GCR_PENDING;
}

export function isSubmitted(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.SUBMITTED;
}

export function isSoftApproved(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.SOFT_APPROVED;
}

export function isApproved(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.APPROVED;
}

export function isResubmitted(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.AMENDED;
}

export function isRejected(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.REJECTED;
}

export function isSoftRejected(contentReview: IContentReview): boolean {
  return contentReview.state === ContentReviewState.SOFT_REJECTED;
}

export function isCompleted(contentReview: IContentReview): boolean {
  return completedStates.includes(contentReview.state);
}

export function isWaitingForApproval(contentReview: IContentReview): boolean {
  return isSubmitted(contentReview) || isResubmitted(contentReview);
}
