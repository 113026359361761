import { ActionType, TState } from '../types';

interface IAction {
    selectedBudgetDistributionId: number;
    type: ActionType.SET_SELECTED_BUDGET_DISTRIBUTION;
}
export type TSetSelectedBudgetDistribution = Readonly<IAction>;

export const setSelectedBudgetDistribution = (state: TState, action: TSetSelectedBudgetDistribution): TState => ({
    ...state,
    selectedBudgetDistributionId: action.selectedBudgetDistributionId,
});
