import * as React from 'react';
import {
  Typography, Form, Input, Divider, InputNumber,
} from '@revfluence/fresh';
import DOMPurify from 'dompurify';

const { Title } = Typography;
import { NoCatalogueFormFieldInput } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import styles from './ProductOrderForm.scss';

export enum MODES {
  EDIT = 'edit',
  CREATE = 'create',
}
export interface FormField {
  formFieldLabel: string;
  isRequired: boolean;
  helpText: string;
}
export interface IProps {
  formFields: NoCatalogueFormFieldInput[];
  instructionText: string;
}

export const ProductOrderForm: React.FC<Readonly<IProps>> = (props) => {
  const {
    formFields,
    instructionText,
  } = props;

  return (
    <div className={styles.ProductOrderForm}>
      <div className={styles.content}>
        <Title level={5}>Preview: Product Order Form</Title>
        <Divider />
        <div className={styles.instruction}>
          <Title level={5}>Instructions</Title>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(instructionText) }} />
        </div>
        <div className={styles.form}>
          <Title level={5}>Product details</Title>
          <Form
            title="Product Details"
            layout="vertical"
            disabled
          >
            <div className={styles.mandatoryFields}>
              <Form.Item
                key="quantity"
                label="Quantity"
                required
                className={styles.quantity}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                key="productName"
                label={formFields?.find((formField) => formField.key == 'productName')?.label || 'Product Name'}
                required={formFields?.find((formField) => formField.key == 'productName')?.required}
                className={styles.productName}
              >
                <Input />
              </Form.Item>
            </div>
            {formFields
            ?.filter((formField) => formField.enabled == true && !['quantity', 'productName']
            .includes(formField.key)).map((formField) => (
              <Form.Item
                key={formField.key}
                label={formField.label || (formField.key === 'productPreferences' ? 'Product Preferences' : formField.key)}
                required={formField.required}
              >
                <Input />
              </Form.Item>
            ))}
          </Form>
        </div>
      </div>
    </div>
  );
};
