import React from 'react';
import { Badge } from '@frontend/shadcn/components/ui/badge';
import { Tooltip, TooltipTrigger, TooltipContent } from '@frontend/shadcn/components/ui/tooltip';
import { tagColors } from '../hooks';
import { simpleHash } from '../containers/Settings/ProductFulfillment/utils';

interface BadgeGroupProps {
  items: string[]
  withColor?: boolean
}
const BadgeGroup = ({ items, withColor = true }: BadgeGroupProps) => {
  if (!items || items.length === 0) {
    return null;
  }

  const firstProduct = items[0];
  const remainingProducts = items.length - 1;

  if (!firstProduct) {
    return null;
  }

  return (
    <div className="flex gap-2 relative">
      <Badge
        variant="secondary"
        style={withColor && tagColors[simpleHash(firstProduct) % tagColors.length]}
      >
        {firstProduct}
      </Badge>
      {remainingProducts > 0 && (
        <Tooltip>
          <TooltipTrigger>
            <Badge variant="secondary" className="cursor-pointer">
              +
              {remainingProducts}
            </Badge>
          </TooltipTrigger>
          <TooltipContent>
            <div className="flex flex-col gap-1">
              {items.slice(1).map((item) => (
                <Badge
                  key={item}
                  variant="secondary"
                  style={withColor && tagColors[simpleHash(item) % tagColors.length]}
                >
                  {item}
                </Badge>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

export default BadgeGroup;
