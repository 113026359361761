/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1pb7d_157 {
  display: flex;
}

._justify-content-space-between_1pb7d_161 {
  justify-content: space-between;
}

._tabular-nums_1pb7d_165 {
  font-variant-numeric: tabular-nums;
}

._NetworkPicker_1pb7d_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._NetworkPicker_1pb7d_169 ._button_1pb7d_174 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_1pb7d_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._NetworkPicker_1pb7d_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._NetworkPicker_1pb7d_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._NetworkPicker_1pb7d_169 ::-webkit-scrollbar-track, ._NetworkPicker_1pb7d_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._NetworkPicker_1pb7d_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._NetworkPicker_1pb7d_169 ._button_1pb7d_174 {
  padding-right: 2rem;
  min-width: 12.5rem;
  max-width: 18.75rem;
  position: relative;
  justify-content: flex-start;
  font-size: 1rem;
  border: 0;
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174:hover {
  color: unset;
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174._disableClick_1pb7d_220:hover {
  background: #fdfdfd;
  cursor: unset;
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174 > [class*=label] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174 ._placeholder_1pb7d_229 {
  width: 8.75rem;
  height: 1rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174 ._icon_1pb7d_236 {
  margin: 0 0.25rem 0 -0.125rem;
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174 ._usernameLink_1pb7d_239 {
  color: #1a1818;
  text-decoration: none;
  transition: color 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174 ._usernameLink_1pb7d_239:hover, ._NetworkPicker_1pb7d_169 ._button_1pb7d_174 ._usernameLink_1pb7d_239:active {
  color: #3996e0;
}
._NetworkPicker_1pb7d_169 ._button_1pb7d_174 ._arrowDownIcon_1pb7d_247 {
  position: absolute;
  top: 50%;
  right: 1rem;
  fill: #8f8d91;
  transform: translateY(-50%);
}
._NetworkPicker_1pb7d_169 ._loadingIcon_1pb7d_254 {
  color: #3996e0;
}

._publisherAccountsButton_1pb7d_258 {
  background: unset !important;
  padding-left: 0.875rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0 !important;
  justify-content: flex-start;
}
._publisherAccountsButton_1pb7d_258:hover {
  color: #3996e0;
}

._publisherNetworkIcon_1pb7d_270 {
  margin-right: 0.25rem;
  max-width: 1.25rem;
}

._popoverContent_1pb7d_275 {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
}