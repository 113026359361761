import { useQuery } from '@apollo/client';

import { GET_PROGRAM_BY_ID } from '@frontend/app/queries/ProgramByIdQuery';
import { ProgramByIdQuery, ProgramByIdQueryVariables } from '@frontend/app/queries/types/ProgramByIdQuery';

export function useGetProgramById(options = {}) {
  return useQuery<ProgramByIdQuery, ProgramByIdQueryVariables>(
    GET_PROGRAM_BY_ID,
    options,
  );
}
