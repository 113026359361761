import React from 'react';
import { Tag } from '@revfluence/fresh';

export enum ProductStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
}

export const ProductStatusRenderer = (type: ProductStatus): React.ReactElement<typeof Tag> => {
    if (type === ProductStatus.ACTIVE) {
        return <Tag color="success">Active</Tag>;
    } else if (type === ProductStatus.ARCHIVED) {
        return <Tag>Archived</Tag>;
    } else {
        return <Tag>{type}</Tag>;
    }
};
