/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_12d07_157 {
  display: flex;
}

._justify-content-space-between_12d07_161 {
  justify-content: space-between;
}

._tabular-nums_12d07_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_12d07_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._TokenCell_12d07_178 {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
._TokenCell_12d07_178 ._right_12d07_185 {
  margin-left: 0.5rem;
}
._TokenCell_12d07_178 ._btn_12d07_188 {
  padding: 0.3125rem;
  border: 0.0625rem solid #dadcde;
  background-color: #fdfdfd;
  color: #3996e0;
}
._TokenCell_12d07_178 ._disabled_12d07_194 {
  pointer-events: none;
}

._value_12d07_198 {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  max-width: 6.25rem;
  padding: 0.375rem 0.75rem;
  border-radius: 3.125rem;
  background-color: #e9e8ea;
  color: #1a1818;
  font-weight: normal;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._value_12d07_198:not(:last-child) {
  margin-right: 0.3125rem;
}
._value_12d07_198._listValue_12d07_219 {
  text-align: center;
  margin-right: 0;
  max-width: 11.25rem;
  width: 100%;
  display: block;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

._singleValue_12d07_229 {
  background-color: transparent;
  padding: 0;
  font-size: 0.875rem;
}

._tokenTitle_12d07_235 {
  width: 100%;
  text-align: center;
  padding-top: 0.375rem;
}

._valuesList_12d07_241 {
  width: 9.375rem;
}

._listValue_12d07_219 {
  font-size: 0.875rem;
  padding: 0.375rem 0.625rem;
  margin-bottom: 1rem;
  text-align: center;
  background-color: #fafafa;
  border-radius: 1rem;
  color: #1a1818;
}
._listValue_12d07_219:last-child {
  margin-bottom: 0;
}

._divider_12d07_258 {
  margin: 0.75rem 0;
}

._all_12d07_262 {
  padding: 0.625rem;
  border-radius: 0.5rem;
  background-color: #fdfdfd;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0.0625rem 0.25rem;
}
._all_12d07_262 ._value_12d07_198 {
  display: block;
  overflow: unset;
  max-width: unset;
  text-overflow: unset;
  white-space: unset;
}
._all_12d07_262 ._value_12d07_198:not(:last-child) {
  margin-bottom: 0.625rem;
}

._save_12d07_279 {
  width: 100%;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

._emptyButton_12d07_286 {
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  width: 100%;
  border: 0;
  font-size: 0.75rem;
}

._muted_12d07_294 {
  color: #1a1818;
  opacity: 0.3;
}