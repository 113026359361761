import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import { unionBy } from 'lodash';

import { UPDATE_MESSAGE_TEMPLATE_MUTATION, MESSAGE_TEMPLATES_QUERY } from '@frontend/app/queries';
import {
  UpdateMessageTemplateMutation,
  UpdateMessageTemplateMutationVariables,
} from '@frontend/app/queries/types/UpdateMessageTemplateMutation';
import { MessageTemplatesQuery } from '../queries/types/MessageTemplatesQuery';

type IOptions = MutationHookOptions<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>;

export const useUpdateMessageTemplateMutation = (options: IOptions = {}) => (
  useMutation<UpdateMessageTemplateMutation, UpdateMessageTemplateMutationVariables>(UPDATE_MESSAGE_TEMPLATE_MUTATION, {
    update(cache, { data: { template } }) {
      const data = cache.readQuery<MessageTemplatesQuery>({ query: MESSAGE_TEMPLATES_QUERY });
      // Do nothing. Cache doesn't exist.
      if (!data) {
        return;
      }
      const existingTemplates: { templates } = data;
      cache.writeQuery({
        query: MESSAGE_TEMPLATES_QUERY,
        data: { templates: unionBy([template], existingTemplates.templates, 'id') },
      });
    },
    ...options,
  })
);
