._wrapper_rnsaw_1 {
  display: inline-flex;
}
._wrapper_rnsaw_1 ._option_rnsaw_4 {
  margin-right: 8px;
}
._wrapper_rnsaw_1 ._option_rnsaw_4 ._countBadge_rnsaw_7 {
  display: inline-flex;
  background-color: #09375a;
  color: white;
  font-weight: 600;
  margin-left: 8px;
  padding: 0 6px;
  border-radius: 10px;
  height: 18px;
  font-size: 10px;
  min-width: 28px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._wrapper_rnsaw_1 ._option_rnsaw_4 .ant-btn {
  display: inline-flex;
  align-items: center;
}
._wrapper_rnsaw_1 ._option_rnsaw_4 .ant-btn span {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._wrapper_rnsaw_1 ._option_rnsaw_4._selected_rnsaw_30 .ant-btn {
  border-color: var(--blue-7);
  background-color: var(--gray-3);
}
._wrapper_rnsaw_1 ._option_rnsaw_4._selected_rnsaw_30 .ant-btn span {
  color: var(--blue-7);
}
._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30) .ant-btn:focus {
  background-color: var(--white) !important;
  border-color: var(--gray-5) !important;
}
._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30):hover ._countBadge_rnsaw_7, ._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30):focus ._countBadge_rnsaw_7 {
  background-color: var(--blue-6);
}
._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30):hover .ant-btn, ._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30):focus .ant-btn {
  border-color: var(--blue-5);
  background-color: var(--gray-2);
}
._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30):hover .ant-btn span, ._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30):focus .ant-btn span {
  color: var(--blue-5);
}
._wrapper_rnsaw_1 ._option_rnsaw_4:not(._selected_rnsaw_30):focus .ant-btn {
  border-width: 2px;
}