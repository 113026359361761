import { EventName, IPaymentSentEventProperties } from '@common';
import { useEventContext } from '@frontend/app/context';
import { createPaymentSentEvent, IMixpanelFields } from '../../createPaymentSentEvent';
import { TPaymentSentHandler } from '../../hooks/usePaymentSentEvent';
import { ISendPaymentRequest } from '../../savePayment';
import { APPLICATION_STRING } from '../containers';

export const useBulkPaymentSentEvent = (
  appString: APPLICATION_STRING,
): TPaymentSentHandler => {
  const addEvent = useEventContext();

  let source: IPaymentSentEventProperties['source'] = 'sales tracking';
  switch (appString) {
    case APPLICATION_STRING.SALES_TRACKING:
      source = 'sales tracking';
      break;
    default:
      break;
  }

  return (params: ISendPaymentRequest, mixpanelFields?: IMixpanelFields) => {
    addEvent(EventName.PaymentSent, createPaymentSentEvent(params, source, mixpanelFields));
  };
};
