import * as React from 'react';
import { Avatar, Button, Typography } from '@revfluence/fresh';
import { UserAvatar } from '@frontend/app/components';
import styles from './StepHeader.scss';

const { Text, Title } = Typography;
interface IProps {
  name: string;
  profileImage: string;
  programName?: string;
  programImage?: string;
  actionText?: string;
  action?: () => void;
}

export const StepHeader: React.FC<IProps> = (props) => {
  const {
    name, profileImage, programName, programImage, actionText, action,
  } = props;

  return (
    <div className={styles.stepHeaderContainer}>
      <div className={styles.stepHeaderLeft}>
        <UserAvatar
          profilePicture={profileImage}
          size="default"
          onClick={() => { }}
          name="profilePic"
        />
        <Title level={5}>{name}</Title>
      </div>
      <div className={styles.stepHeaderRight}>
        {
          action ? (
            <>
              <Button type="link" onClick={() => action()}>{actionText}</Button>
            </>
          ) : programImage && programName ? (
            <>
              <Avatar src={programImage} shape="square" />
              <Text weight="semibold" className={styles.programName}>{programName}</Text>
            </>
          ) : null
        }
      </div>
    </div>
  );
};
