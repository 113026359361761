import * as React from 'react';
import { Col, Row } from 'antd';
import cx from 'classnames';
import { Typography, Space } from '@revfluence/fresh';
import styles from './Layout.scss';

interface ILayoutProps {
  AddTiktokAccountButton: React.ReactNode;
  AddTiktokAdAccountButton: React.ReactNode;
  SettingsInfoCards: React.ReactNode;
  TiktokAccountsTable: React.ReactNode;
  TiktokAdAccountsTable: React.ReactNode;
}

export const Layout: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const {
    AddTiktokAccountButton,
    AddTiktokAdAccountButton,
    SettingsInfoCards,
    TiktokAccountsTable,
    TiktokAdAccountsTable,
  } = props;
  return (
    <Space size={32} direction="vertical" style={{ paddingBottom: 40, background: 'white', width: '100%' }}>
      <Space size={24} direction="vertical" align="start">
        <Space size={16} direction="vertical" align="start">
          <Typography.Title level={3} className={styles.noMargin}>TikTok</Typography.Title>
          <Typography.Title level={5} className={cx(styles.noMargin, styles.regular)}>
            Connect your brand’s TikTok account to leverage listening.
            {' '}
            <Typography.Link target="_blank" href="https://intercom.help/aspireiq_elevate/en/articles/8349506-tiktok-mention-listening">
              Learn more
            </Typography.Link>
            {' '}
            about connecting TikTok.
          </Typography.Title>
        </Space>
        {SettingsInfoCards}
      </Space>
      <Row>
        <Col xs={24}>
          <div style={{ marginBottom: 32 }}>{AddTiktokAccountButton}</div>
          {TiktokAccountsTable}
          <div style={{ marginBottom: 32, marginTop: 32 }}>{AddTiktokAdAccountButton}</div>
          {TiktokAdAccountsTable}
        </Col>
      </Row>
    </Space>
  );
};
