import * as React from 'react';

import { Button, Tooltip } from '@revfluence/fresh';
import { CircleXIcon } from '@revfluence/fresh-icons/regular/esm';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  title: string;
}

export const PauseLinksButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    className,
    disabled,
    onClick,
    title,
  } = props;
  return (
    <Tooltip placement="top" title={title}>
      <Button
        className={className}
        disabled={disabled}
        icon={<CircleXIcon />}
        onClick={onClick}
        type="text"
      >
        {title}
      </Button>
    </Tooltip>
  );
});

PauseLinksButton.defaultProps = {
  disabled: false,
};

PauseLinksButton.displayName = 'PauseLinksButton';
