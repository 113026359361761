/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_os3da_157 {
  display: flex;
}

._justify-content-space-between_os3da_161 {
  justify-content: space-between;
}

._tabular-nums_os3da_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_os3da_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NewActivationModal_os3da_178 ._title_os3da_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._NewActivationModal_os3da_178 ._error_os3da_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._NewActivationModal_os3da_178 ._subtitle_os3da_198 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._NewActivationModal_os3da_178 ._label_os3da_208 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._NewActivationModal_os3da_178 ._title_os3da_178 {
  margin-bottom: 0.625rem;
  text-align: center;
}
._NewActivationModal_os3da_178 ._subtitle_os3da_198 {
  margin-bottom: 0.625rem;
  text-align: center;
}
._NewActivationModal_os3da_178 ._error_os3da_188 {
  color: #d9534f;
  text-align: center;
  word-break: break-all;
}
._NewActivationModal_os3da_178 ._form_os3da_231 {
  max-width: 18.75rem;
  margin: auto;
  margin-top: 1.875rem;
}
._NewActivationModal_os3da_178 ._buttons_os3da_236 {
  margin-top: 1.875rem;
  text-align: center;
}
._NewActivationModal_os3da_178 ._buttons_os3da_236 > * {
  margin-right: 0.9375rem;
}
._NewActivationModal_os3da_178 ._label_os3da_208 {
  margin-top: 1.25rem;
  margin-bottom: 0.3125rem;
}