/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1p54z_157 {
  display: flex;
}

._justify-content-space-between_1p54z_161 {
  justify-content: space-between;
}

._tabular-nums_1p54z_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1p54z_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._newFolderBtn_1p54z_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._EditSegmentForm_1p54z_188 ._header_1p54z_188 {
  margin: var(--spacing-xxs) 0 var(--spacing-md);
}
._EditSegmentForm_1p54z_188 ._label_1p54z_191 {
  margin-bottom: 0.3125rem;
}
._EditSegmentForm_1p54z_188 ._select_1p54z_194 {
  width: 100%;
  margin-bottom: var(--spacing-sm);
}

._dropDownDivider_1p54z_199 {
  margin: 0.25rem 0;
}

._searchText_1p54z_203 {
  color: #1a1818;
}

._newGroup_1p54z_207 {
  display: block;
  padding: 0.5rem;
  cursor: pointer;
}

._newFolderBtn_1p54z_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 1.25rem 0.625rem;
  cursor: pointer;
}
._newFolderBtn_1p54z_178 ._icon_1p54z_220 {
  margin-right: 0.625rem;
}
._newFolderBtn_1p54z_178:not(._noOptions_1p54z_223) {
  margin-top: 0.625rem;
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  border-top: 0.0625rem solid #dadcde;
}