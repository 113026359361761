import 'css-chunk:src/applications/ReportsApp/components/Tab/Tab.module.scss';export default {
  "tab": "_tab_b16bi_1",
  "content": "_content_b16bi_7",
  "green": "_green_b16bi_21",
  "isActive": "_isActive_b16bi_24",
  "blue": "_blue_b16bi_39",
  "plum": "_plum_b16bi_57",
  "orange": "_orange_b16bi_75",
  "teal": "_teal_b16bi_93",
  "statistic": "_statistic_b16bi_111",
  "trend": "_trend_b16bi_121"
};