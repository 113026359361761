import { updateGuidelineInstruction } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IUpdateContentGuidelineInstructionAction, IState } from '../../../types/state';

export const updateContentGuidelineInstruction = (
  state: IState,
  action: IUpdateContentGuidelineInstructionAction,
): IState => ({
  ...state,
  contentGuidelines: updateGuidelineInstruction(
    state.contentGuidelines,
    action.contentGuidelineInstruction.id,
    action.contentGuidelineInstruction.guidelineIndex,
    action.contentGuidelineInstruction.instructionIndex,
    action.contentGuidelineInstruction.instruction,
  ),
  isEditMode: true,
});
