import * as React from 'react';

import { IPlacesInputProps, TPlacesOptions, PlaceTypeOption } from './types';
import { PlacesInput } from './PlacesInput';

type TPlacesInputProps = Pick<IPlacesInputProps, 'debounceWait' | 'emptyOptionsMessage' | 'onChange' | 'placeholder'>;

interface IStateInputProps extends TPlacesInputProps {
  className?: string;
}

const OPTIONS: TPlacesOptions = {
  types: [PlaceTypeOption.State],
  countryCode: 'us',
};

export const StateInput: React.FunctionComponent<IStateInputProps> = (props) => (
  <PlacesInput
    className={props.className}
    placeholder={props.placeholder}
    onChange={props.onChange}
    emptyOptionsMessage={props.emptyOptionsMessage}
    options={OPTIONS}
    debounceWait={props.debounceWait}
  />
  );

StateInput.defaultProps = {
  placeholder: 'Enter a state',
  emptyOptionsMessage: 'Please input more characters',
};
StateInput.displayName = 'StateInput';
