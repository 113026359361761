import * as React from 'react';

import { ISTAComposerMemberFieldIds, useGetOffersBySearchQuery } from '@affiliates/hooks';
import { SEND_STA_TASK_ID } from '@affiliates/types/globalTypes';
import { isSendTask } from '@affiliates/utils';
import { getObjectForTask } from '@affiliates/utils/messageComposer';
import { IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import { subjectLineForClientAndProgram } from '@frontend/applications/Shared/utils';
import { STASendConfirmation } from './STASendConfirmation';
import { STAEmailComposerContainer } from './STAEmailComposerContainer';

import styles from './STASendItems.scss';

interface ISTASendItemsProps {
  workflowActionParameters: IWorkflowActionParameters;
  closeModal?(): void;
  staComposerMemberFieldIds: ISTAComposerMemberFieldIds;
  memberPortalFeatureFlagEnabled: boolean;
  clientName: string;
  migrateToGraphQL?: boolean;
  programId?: number;
}

export const STASendItems: React.FC<Readonly<ISTASendItemsProps>> = ({
  workflowActionParameters: {
    programId,
    programName,
    memberIds,
    workItems,
    taskId,
    workletSpecUri,
  },
  closeModal,
  staComposerMemberFieldIds,
  memberPortalFeatureFlagEnabled,
  clientName,
  migrateToGraphQL,
}) => {
  const offersQuery = useGetOffersBySearchQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      query: { programId },
    },
  });
  const [confirmationScreenViewed, setConfirmationScreenViewed] = React.useState<boolean>(false);

  const emailSubject = React.useMemo(() => {
    if (taskId && programName) {
      if (isSendTask(taskId)) {
        const objectType = getObjectForTask(taskId, false);
        const capitalizedObjectType = objectType.charAt(0).toUpperCase() + objectType.slice(1);
        return subjectLineForClientAndProgram(clientName, programName, capitalizedObjectType);
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programName, taskId, subjectLineForClientAndProgram, clientName]);

  const currentTaskId = React.useMemo(() => {
    switch (taskId) {
      case SEND_STA_TASK_ID.send_link_and_promo_task:
        return SEND_STA_TASK_ID.send_link_and_promo_task;
      case SEND_STA_TASK_ID.send_link_task:
        return SEND_STA_TASK_ID.send_link_task;
      case SEND_STA_TASK_ID.send_promo_task:
        return SEND_STA_TASK_ID.send_promo_task;
      default:
        return undefined;
    }
  }, [taskId]);

  const showSendConfirmationScreen = React.useMemo(() => {
    if (offersQuery.loading || offersQuery.error || !offersQuery.data?.offers || offersQuery.data.offers.length === 0) {
      return false;
    }

    return offersQuery.data.offers[0].showSendConfirmationScreen;
  }, [offersQuery]);

  if (!currentTaskId) {
    return null;
  }

  if (offersQuery.loading || offersQuery.error) {
    return null;
  }

  return (
    <div className={styles.STASendItems}>
      {showSendConfirmationScreen && !confirmationScreenViewed ? (
        <STASendConfirmation
          workletSpecUri={workletSpecUri}
          taskId={currentTaskId}
          programId={programId}
          onNext={() => setConfirmationScreenViewed(true)}
          onCancel={closeModal}
        />
      ) : (
        <STAEmailComposerContainer
          onNext={closeModal}
          onCancel={closeModal}
          taskId={currentTaskId}
          memberIds={memberIds}
          emailSubject={emailSubject}
          staComposerMemberFieldIds={staComposerMemberFieldIds}
          offers={offersQuery.data?.offers || []}
          workItemIds={workItems.map((item) => item.id)}
          memberPortalFeatureFlagEnabled={memberPortalFeatureFlagEnabled}
          migrateToGraphQL={migrateToGraphQL}
        />
      )}
    </div>
  );
};
