import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ORDER_LIMITATION_QUERY } from '../queries';
import {
  GetOrderLimitationQuery,
  GetOrderLimitationQueryVariables,
  GetOrderLimitationQuery_orderLimitation,
} from '../queries/types/GetOrderLimitationQuery';

type IOptions = QueryHookOptions<GetOrderLimitationQuery, GetOrderLimitationQueryVariables>;
export type IOrderLimitation = GetOrderLimitationQuery_orderLimitation;

export function useGetOrderLimitation(options: IOptions = {}) {
  const {
    loading,
    data: {
      orderLimitation = {
        id: null,
        tags: [],
        rules: { priceMax: null, quantityMax: null, __typename: 'OrderRule' },
        __typename: 'OrderLimitation',
      },
    } = {},
    error,
    refetch,
  } = useQuery<GetOrderLimitationQuery, GetOrderLimitationQueryVariables>(
    GET_ORDER_LIMITATION_QUERY,
    {
      ...options,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    loading,
    orderLimitation,
    error,
    refetch,
  };
}
