import * as React from 'react';
import {
 filter, map, get, isNil,
} from 'lodash';

import {
  IMemberSearchQuery,
  IMemberFilter,
  Operator,
  ProgramMembershipStatusType,
} from '@frontend/app/types/MemberSearch';
import { SpecialFilters } from '@frontend/app/constants/specialFilters';
import { TSegment, TPredefinedSegment } from '@frontend/app/types/MemberList';

import { TFilter } from '../../types/MemberList';

const { useMemo, useCallback } = React;

const getProgramColumnForStatus = (status: string): SpecialFilters => {
  switch (status) {
    case ProgramMembershipStatusType.REJECTED:
      return SpecialFilters.REJECTED_PROGRAMS;

    case ProgramMembershipStatusType.INVITED:
      return SpecialFilters.INVITED_PROGRAMS;

    case ProgramMembershipStatusType.SUBMITTED:
      return SpecialFilters.SUBMITTED_PROGRAMS;

    default:
      return SpecialFilters.PROGRAMS;
  }
};

export const useSegmentFilters = (segment: TSegment | TPredefinedSegment) => {
  // Used to identify filters. Keeps the identifiers consistent rather than using a randomly generated id.
  const makeId = useCallback((filterId: string | number, idx?: number) => `${filterId}_${segment.id}${isNil(idx) ? '' : `_${idx}`}`, [segment]);

  return useMemo<TFilter[]>(() => {
    if (!segment || !segment.filter) {
      return [];
    }

    const query: IMemberSearchQuery = segment.filter;

    const fields: IMemberFilter[] = get(query, 'fields.and');

    const validFilters = filter(
      fields,
      (fieldFilter: IMemberFilter) => {
        // Skip showing sourcing group, same way we skip community filter.
        if (fieldFilter.column === SpecialFilters.DATA_SOURCES) {
          return false;
        }
        return !!fieldFilter.memberFieldSchemaId || !!fieldFilter.column || !!fieldFilter.relation;
      },
    );

    const fieldFilters = map(validFilters, (filter, idx) =>
      // add index to id since there could be multiple filters for email for example.
       ({ ...filter, id: makeId(filter.memberFieldSchemaId || filter.column, idx) }));

    const specialFilters: TFilter[] = [];

    const programColumn = getProgramColumnForStatus(query.programStatus);

    if (query.applicantSource) {
      const types = query.applicantSource.types || [];
      specialFilters.push({
        id: makeId('applicantSource'),
        column: 'applicantSource',
        [Operator.EQUAL]: types,
      });
    }

    if (query.programId || query.programIds) {
      specialFilters.push({
        id: makeId(programColumn),
        column: programColumn,
        [Operator.EQUAL]: {
          programIds: query.programIds || [query.programId],
          programStatus: query.programStatus,
        },
      });
    }

    if (query.excludeProgramIds) {
      specialFilters.push({
        id: makeId(programColumn),
        column: programColumn,
        [Operator.NOT_EQUAL]: {
          communityIds: query.excludeProgramIds,
        },
      });
    }

    if (query.hasPrograms) {
      specialFilters.push({
        id: makeId(`has${programColumn}`),
        column: programColumn,
        [Operator.NOT_NULL]: true,
      });
    } else if (query.hasPrograms === false) {
      specialFilters.push({
        id: makeId(`has${programColumn}`),
        column: programColumn,
        [Operator.IS_NULL]: true,
      });
    }

    if (query.activationIds) {
      specialFilters.push({
        id: makeId('activations'),
        column: 'activations',
        [Operator.EQUAL]: query.activationIds,
      });
    }

    if (query.excludeActivationIds) {
      specialFilters.push({
        id: makeId('activations'),
        column: 'activations',
        [Operator.NOT_EQUAL]: query.excludeActivationIds,
      });
    }

    if (query.hasActivations) {
      specialFilters.push({
        id: makeId('hasActivations'),
        column: 'activations',
        [Operator.NOT_NULL]: true,
      });
    } else if (query.hasActivations === false) {
      specialFilters.push({
        id: makeId('hasActivations'),
        column: 'activations',
        [Operator.IS_NULL]: true,
      });
    }

    if (query.communityId || query.communityIds) {
       specialFilters.push({
         id: makeId('communities'),
         column: SpecialFilters.COMMUNITIES,
         [Operator.EQUAL]: {
          communityIds: query.communityIds || [query.communityId],
        },
      });
    }

    if (query.excludeCommunityIds) {
      specialFilters.push({
        id: makeId('communities'),
        column: SpecialFilters.COMMUNITIES,
        [Operator.NOT_EQUAL]: {
          communityIds: query.excludeCommunityIds,
        },
      });
    }

    if (query.hasCommunities) {
      specialFilters.push({
        id: makeId('hasCommunities'),
        column: 'communities',
        [Operator.NOT_NULL]: true,
      });
    } else if (query.hasCommunities === false) {
      specialFilters.push({
        id: makeId('hasCommunities'),
        column: 'communities',
        [Operator.IS_NULL]: true,
      });
    }

    if (query.hasTags) {
      specialFilters.push({
        id: makeId('hasTags'),
        column: 'tags',
        [Operator.NOT_NULL]: true,
      });
    } else if (query.hasTags === false) {
      specialFilters.push({
        id: makeId('hasTags'),
        column: 'tags',
        [Operator.IS_NULL]: true,
      });
    }

    if (query.tagIds) {
      specialFilters.push({
        id: makeId('tags'),
        column: 'tags',
        [Operator.EQUAL]: query.tagIds,
      });
    }

    if (query.excludeTagIds) {
      specialFilters.push({
        id: makeId('tags'),
        column: 'tags',
        [Operator.NOT_EQUAL]: query.excludeTagIds,
      });
    }

    if (query.owners) {
      specialFilters.push({
        id: makeId('owners'),
        column: 'owners',
        [Operator.EQUAL]: query.owners,
      });
    }

    if (query.excludeOwners) {
      specialFilters.push({
        id: makeId('owners'),
        column: 'owners',
        [Operator.NOT_EQUAL]: query.excludeOwners,
      });
    }

    if (query.hasOwners) {
      specialFilters.push({
        id: makeId('hasOwners'),
        column: 'owners',
        [Operator.NOT_NULL]: true,
      });
    } else if (query.hasOwners === false) {
      specialFilters.push({
        id: makeId('hasOwners'),
        column: 'owners',
        [Operator.IS_NULL]: true,
      });
    }

    if (query.orHighlightIds) {
      specialFilters.push({
        id: makeId('highlights'),
        column: 'highlights',
        [Operator.EQUAL]: query.orHighlightIds,
      });
    }

    if (query.excludeHighlightIds) {
      specialFilters.push({
        id: makeId('highlights'),
        column: 'highlights',
        [Operator.NOT_EQUAL]: query.excludeHighlightIds,
      });
    }

    if (query.requirements) {
      if (query.requirements.hasIncomplete) {
        specialFilters.push({
          id: makeId('hasIncompleteRequirements'),
          column: 'incompleteRequirements',
          [Operator.EQUAL]: query.requirements.hasIncomplete,
        });
      }

      if (query.requirements.hasOverdue) {
        specialFilters.push({
          id: makeId('hasOverdueRequirements'),
          column: 'overdueRequirements',
          [Operator.EQUAL]: query.requirements.hasOverdue,
        });
      }
    }

    return [...specialFilters, ...fieldFilters];
  }, [segment, makeId]);
};
