const options = [
  { label: 'Is between', value: 'isBetween' },
  { label: 'Greater than', value: 'greaterThan' },
  { label: 'Less than', value: 'lessThan' },
  { label: 'Equal to', value: 'equalTo' },
];

export const optionsToSymbols = {
  isBetween: '=',
  greaterThan: '>',
  lessThan: '<',
  equalTo: '=',
};

export default options;
