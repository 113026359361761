import {
 ILocation, TLocationType, TGender, TNetworkIdentifier,
} from '@components';

export interface ICreatorSkillset {
  identifier: string;
  displayName: string;
  order: number;
}

export interface ICountryRegionOption {
  label: string;
  value: string;
}

export interface INetworkInfo {
  identifier: TNetworkIdentifier;
  audienceNamePlural: string;
  engagementNamePlural: string;
  contentName: string;
}

export interface ICreatorSkillsetFilters {
  selectedIdentifiers?: string[];
}

export interface ICreatorSkillsetOptions {
  choices?: ICreatorSkillset[];
  hide?: boolean;
}

export type TRange = [number, number];

export interface IEngagementFilters {
  reachRange?: TRange;
  engagementRange?: TRange;
  impressionRange?: TRange;
  engagementRatioRange?: TRange;
}

export interface IEngagementOptions {
  reachLimitsByNetwork?: { [network: string]: TRange };
  engagementLimitsByNetwork?: { [network: string]: TRange };
  impressionLimitsByNetwork?: { [network: string]: TRange };
  reachStep?: number;
  engagementStep?: number;
  impressionStep?: number;
  hideEngagement?: boolean;
  hideImpression?: boolean;
  hideEngagementRatio?: boolean;

  networkInfos?: INetworkInfo[];
  hide?: boolean;
  isExpanded?: boolean;
}

export interface IOtherOptions {
  tagOptions?: string[];
  hide?: boolean;
  hideInvitedToggle?: boolean;
  hideTags?: boolean;
  hideActivity?: boolean;
  hideCanContact?: boolean;
  isExpanded?: boolean;
}

export interface IActivityFilters {
  lastPostActivity?: number;
}

export interface IOtherFilters extends IActivityFilters {
  hideInvited?: boolean;
  tags?: string[];
  canContact?: boolean;
  hasSponsoredPosts?: boolean;
}

export interface INetworkFilters {
  channel?: TNetworkIdentifier;
}

export interface INetworkOptions {
  channelOptions?: TNetworkIdentifier[];
  hide?: boolean;
  hideChannels?: boolean;
  isExpanded?: boolean;
}

export interface ILanguageOption {
  label: string;
  value: string;
}

export interface ICreatorAgeFilters {
  ageRanges?: string[];
  ageMin?: string;
  ageMax?: string;
}

export interface ICreatorAgeOptions {
  ageRangeChoices?: string[];
}

export interface ICreatorInterestFilters {
  interests?: string[];
}

export interface ICreatorInterestOptions {
  interestChoices?: string[];
}

export interface ICreatorDemographicOptions extends ICreatorAgeOptions, ICreatorInterestOptions {
  countryChoices?: ICountryRegionOption[];
  languageChoices?: ILanguageOption[];

  hide?: boolean;
  hideCreatorAge?: boolean;
  hideCreatorInterests?: boolean;
  hideCreatorGender?: boolean;
  hideCreatorLocations?: boolean;
  hideCreatorCountries?: boolean;
  hideCreatorLanguages?: boolean;
  isExpanded?: boolean;
}

export interface ICreatorGenderFilters {
  gender?: TGender;
}

export interface ICreatorDemographicFilters extends ICreatorAgeFilters, ICreatorGenderFilters, ICreatorInterestFilters {
  locations?: ILocation[];
  countries?: ICountryRegionOption[];
  languages?: ILanguageOption[];
}

export interface IAudienceAgeOptions {
  ageRangeChoices?: string[];
}

export interface IAudienceAgeFilters {
  ageRange?: string;
  ageLessThan?: boolean;
  agePercentage?: number;
  ageMin?: string;
  ageMax?: string;
}

export interface IAudienceEthnicityOptions {
  ethnicityChoices?: string[];
}

export interface IAudienceEthnicityFilters {
  ethnicity?: string;
  ethnicityLessThan?: boolean;
  ethnicityPercentage?: number;
}

export interface IAudienceLocationOptions {
  countryChoices?: ICountryRegionOption[];
  regionChoices?: ICountryRegionOption[];
}

export interface IAudienceLocationFilters {
  audienceLocations?: ILocation[];
  countryRegion?: string;
  countryRegionLessThan?: boolean;
  countryRegionPercentage?: number;
  locationType?: TLocationType;
}

export interface IAudienceDemographicOptions extends
  IAudienceLocationOptions, IAudienceAgeOptions, IAudienceEthnicityOptions {
  hide?: boolean;
  hideAudienceLocation?: boolean;
  hideAudienceGender?: boolean;
  hideAudienceAge?: boolean;
  hideAudienceEthnicity?: boolean;
  hideAudiencePercentage?: boolean;
  isExpanded?: boolean;
}

export interface IAudienceGenderFilters {
  gender?: TGender;
  genderLessThan?: boolean;
  genderPercentage?: number;
}

export type IAudienceDemographicFilters =
  & IAudienceLocationFilters
  & IAudienceGenderFilters
  & IAudienceAgeFilters
  & IAudienceEthnicityFilters;
export interface ICreatorSearchFilters {
  query?: string;
  creatorSkillsets?: ICreatorSkillsetFilters;
  network?: INetworkFilters;
  engagement?: IEngagementFilters;
  creatorDemographics?: ICreatorDemographicFilters;
  audienceDemographics?: IAudienceDemographicFilters;
  other?: IOtherFilters;
}

export interface ICreatorSearchOptions {
  query?: {
    placeholder?: string;
    buffer?: number;
  };
  creatorSkillsets?: ICreatorSkillsetOptions;
  network?: INetworkOptions;
  engagement?: IEngagementOptions;
  creatorDemographics?: ICreatorDemographicOptions;
  audienceDemographics?: IAudienceDemographicOptions;
  other?: IOtherOptions;
}
