import * as React from 'react';

import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import cx from 'classnames';
import {
  isEmpty,
  isUndefined,
  find,
  first,
  map,
  size,
  identity,
} from 'lodash';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { Steps, Button, Tooltip } from '@revfluence/fresh';

import { IMarketplaceTab, MARKETPLACE_TABS } from './model';
import { useListOnMarketplace } from '../context';

import styles from './styles.scss';
import { CREATOR_OFFERS } from '../context/model';

const { useEffect, useMemo, useRef } = React;

const MarketplaceTabs: React.FC = () => {
  const {
    getFieldValue,
    submitData,
    campaignInputData,
    isSubmitting,
    goBack,
    isEditingCampaign,
    hasDataChanged,
    hasEmptyRequiredFields,
    baseRoutePath,
  } = useListOnMarketplace();

  const containerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const location = useLocation();

  const mappedTabs = Object.values(MARKETPLACE_TABS);

  const activeTab = useMemo((): IMarketplaceTab => {
    const pathTree = location.pathname.split('/');
    const pathStep = pathTree.pop();

    const step = find(mappedTabs, ['path', pathStep]);

    return (step || first(mappedTabs)) as IMarketplaceTab;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    /** Reset container scroll on tab change */
    if (containerRef?.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [containerRef, activeTab]);

  const renderStep = (tab: IMarketplaceTab) => (
    <Steps.Step key={`step_tab_${tab.key}`} title={tab.title} />
  );

  const renderSteps = () => (
    <Steps
      className={styles.steps}
      current={activeTab?.step}
      type="navigation"
    >
      {map(mappedTabs, renderStep)}
    </Steps>
  );

  const isCreatorOffersAllFalse = CREATOR_OFFERS.map((offer) => offer.key)
    .every((field) => Boolean(getFieldValue(field)) === false);

  const isDetailsIncomplete = useMemo(() => {
    const { requiredFields = [] } = MARKETPLACE_TABS.details;

    return hasEmptyRequiredFields(requiredFields) || isCreatorOffersAllFalse;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInputData]);

  const nextButtonTooltipText = useMemo(() => {
    const reasons = map(activeTab?.requiredFields, (fieldKey) => {
      if (isEmpty(campaignInputData[fieldKey])) {
        return (
          activeTab.requiredFieldsErrorText?.[fieldKey]
            || `${fieldKey} is required.`
        );
      }

      return '';
    }).filter(identity);

    if (size(reasons) === 1) {
      return first(reasons);
    }

    if (size(reasons) > 1) {
      return (
        <ul className={styles.tooltipList}>
          {map(reasons, (reason) => (
            <li key={`reason_${reason}`}>
              {reason}
            </li>
          ))}
        </ul>
      );
    }

    return '';
  }, [campaignInputData, activeTab]);

  const isNextDisabled = useMemo(() => {
    if (isUndefined(activeTab)) {
      return true;
    }

    if (!activeTab.nextTabKey && !hasDataChanged && isEditingCampaign) {
      return true;
    }

    const { requiredFields = [] } = activeTab;

    return activeTab.key === 'details'
      ? isDetailsIncomplete : hasEmptyRequiredFields(requiredFields);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, isDetailsIncomplete, hasDataChanged, isEditingCampaign]);

  const handleNext = () => {
    if (isNextDisabled) {
      return;
    }

    const { nextTabKey } = activeTab;

    if (!nextTabKey) {
      submitData();
      return;
    }

    const nextPath = MARKETPLACE_TABS[nextTabKey].path;

    history.push({
      ...location,
      pathname: nextPath,
    });
  };

  const renderBackButton = () => {
    const isFirst = activeTab?.step === 0;
    const displayIcon = !isFirst;

    return (
      <Button
        onClick={goBack}
        size="large"
        className={styles.footerButton}
        icon={displayIcon ? <LeftOutlined /> : null}
      >
        {
          isFirst
            ? 'Cancel'
            : 'Previous Step'
        }
      </Button>
    );
  };

  const renderNextButton = () => {
    const hasNext = activeTab?.nextTabKey;

    const saveLabel = isEditingCampaign
      ? 'Update'
      : 'Publish to Marketplace';

    const label = !hasNext
      ? saveLabel
      : 'Next';

    return (
      <Tooltip
        title={nextButtonTooltipText}
      >
        <div>
          <Button
            type="primary"
            onClick={handleNext}
            size="large"
            disabled={isNextDisabled}
            className={cx(styles.footerButton, styles.footerButtonRight)}
            icon={hasNext ? <RightOutlined /> : null}
            loading={isSubmitting}
          >
            {!isSubmitting && label}
          </Button>
        </div>
      </Tooltip>
    );
  };

  const renderContent = () => (
    <Route>
      <Switch>
        {
            map(mappedTabs, (tab) => (
              <Route
                key={`route_tab_${tab.key}`}
                exact
                path={`${baseRoutePath}/${tab.path}`}
                component={tab.component}
              />
            ))
          }
      </Switch>
    </Route>
    );

  return (
    <div className={styles.container}>
      {renderSteps()}
      <div
        className={styles.content}
        ref={containerRef}
      >
        {renderContent()}
      </div>
      <div className={styles.footer}>
        {renderBackButton()}
        {renderNextButton()}
      </div>
    </div>
  );
};

MarketplaceTabs.displayName = 'MarketplaceTabs';

export default MarketplaceTabs;
