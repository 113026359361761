import * as React from 'react';
import * as qs from 'qs';
import {
 isEmpty,
 isUndefined,
 differenceBy,
 find,
 size,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { useParsedRouterSearch } from '@frontend/app/hooks';
import {
  TFilter,
  TSegment,
  TPredefinedSegment,
} from '@frontend/app/types/MemberList';

const {
 useMemo, useState, useEffect, useRef,
} = React;

const EXCLUDED_SEARCH_QUERY_PATHS = ['find_creators', 'marketplace'];

const useURLQueryFilters = (segment?: TSegment | TPredefinedSegment) => {
  const searchQuery = useParsedRouterSearch();

  return useMemo<TFilter[]>(() => {
    if (searchQuery.filters) {
      try {
        const { segmentId, value } = JSON.parse(searchQuery.filters);
        // Make sure the segment for the query params match the current segment.
        if (
          !segment
          || (segment && segmentId === segment.id)
        ) {
          return value;
        }
      } catch (err) {
        // Do nothing.
      }
    }
    return null;
  }, [searchQuery.filters, segment]);
};

const filtersHaveChanged = (oldFilters: TFilter[], newFilters: TFilter[]): boolean => {
  if (size(oldFilters) > size(newFilters)) {
    return filtersHaveChanged(newFilters, oldFilters);
  } else {
    return !isEmpty(differenceBy(newFilters, oldFilters, 'id'));
  }
};

export const useFilters = (
  segmentFilters: TFilter[],
  urlSearchRef: React.RefObject<{ filters?: string }>,
  segment?: TSegment | TPredefinedSegment,
) => {
  const previousDefaultFilters = useRef<TFilter[]>(null);
  const urlFilters = useURLQueryFilters(segment);

  const defaultFilters = urlFilters || segmentFilters;

  const result = useState(defaultFilters);
  const [, setFiltersState] = result;

  // Do this so we don't need to wait for setFiltersState to reflect changes to filters.
  // Also make sure defaultFilters isn't just the same list of filters.
  if (
    previousDefaultFilters.current && previousDefaultFilters.current !== defaultFilters
    && filtersHaveChanged(result[0], defaultFilters)
  ) {
    // Default filters changed.
    result[0] = defaultFilters;
  } else {
    previousDefaultFilters.current = defaultFilters;
  }

  useEffect(() => {
    setFiltersState(() => {
      previousDefaultFilters.current = defaultFilters;
      return defaultFilters;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters]);

  const [filters] = result;

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const newSearch = urlSearchRef.current;

    const shouldSkip = find(EXCLUDED_SEARCH_QUERY_PATHS, (path) => (
      location.pathname.includes(path)
    ));

    if (segment && isUndefined(shouldSkip)) {
      newSearch.filters = JSON.stringify({
        segmentId: segment.id,
        value: filters,
      });
      history.replace({ ...location, search: qs.stringify(newSearch) });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, segment]);

  return result;
};
