import { IState } from '../../../../types/state';
import { IToggleInfluencerBrandedContent } from './index';

export const toggleInfluencerBrandedContent = (state: IState, action: IToggleInfluencerBrandedContent): IState => {
  if (
    state.collaborationDetails.bam.toggleInfluencerBrandedContent
    === action.toggleInfluencerBrandedContent) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      bam: {
        ...state.collaborationDetails.bam,
        toggleInstagramInsights:
          action.toggleInfluencerBrandedContent
          || state.collaborationDetails.bam.toggleInstagramInsights,
        toggleInfluencerBrandedContent: action.toggleInfluencerBrandedContent,
      },
    },
  };
};
