import 'css-chunk:src/components/widgets/Invite/Invite.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "buttonWidth": "80",
  "popoverWidth": "320",
  "flex": "_flex_18f39_157",
  "justify-content-space-between": "_justify-content-space-between_18f39_161",
  "tabular-nums": "_tabular-nums_18f39_165",
  "Invite": "_Invite_18f39_169",
  "isFetching": "_isFetching_18f39_213",
  "loading": "_loading_18f39_220",
  "hideButton": "_hideButton_18f39_224",
  "select": "_select_18f39_224",
  "inviteButton": "_inviteButton_18f39_228",
  "withButton": "_withButton_18f39_234",
  "selectButton": "_selectButton_18f39_241",
  "withBorder": "_withBorder_18f39_245",
  "isFocused": "_isFocused_18f39_270",
  "inviting": "_inviting_18f39_293",
  "wait": "_wait_18f39_296",
  "disabled": "_disabled_18f39_300",
  "popover": "_popover_18f39_304",
  "popoverContentWrapper": "_popoverContentWrapper_18f39_308",
  "shown": "_shown_18f39_311",
  "message": "_message_18f39_311",
  "popoverContent": "_popoverContent_18f39_308",
  "option": "_option_18f39_321",
  "noSelect": "_noSelect_18f39_341",
  "communityOption": "_communityOption_18f39_344",
  "programOption": "_programOption_18f39_354",
  "noPrograms": "_noPrograms_18f39_357",
  "accountLink": "_accountLink_18f39_381",
  "ineligible": "_ineligible_18f39_386",
  "error": "_error_18f39_389",
  "noCommunities": "_noCommunities_18f39_393",
  "new": "_new_18f39_415",
  "invited": "_invited_18f39_416",
  "approved": "_approved_18f39_417",
  "tooltip": "_tooltip_18f39_429",
  "ammendAction": "_ammendAction_18f39_436",
  "show": "_show_18f39_311"
};