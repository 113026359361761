import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CLIENT_MAX_MARKETPLACE_PROJECTS_COUNT_QUERY } from '@frontend/app/queries';
import {
  GetClientMaxMarketplaceProjectsCountQuery,
} from '@frontend/app/queries/types/GetClientMaxMarketplaceProjectsCountQuery';

type IOptions = QueryHookOptions<GetClientMaxMarketplaceProjectsCountQuery>;

export const useGetClientMaxMarketplaceProjectsCount = (options: IOptions = {}) => useQuery<GetClientMaxMarketplaceProjectsCountQuery>(
  GET_CLIENT_MAX_MARKETPLACE_PROJECTS_COUNT_QUERY,
  options,
);
