import * as React from 'react';
import { Wizard as WizardComponent } from '@frontend/applications/Shared/Wizard/components/WizardComponent/WizardComponent';
import { isFunction, isNumber } from 'lodash';

const { useEffect, useMemo, useState } = React;

interface IInstructions {
  title: string;
  description: string;
}

interface INavigationButton {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action?: any;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  showIcon?: boolean;
  text?: string;
}

enum ALTER_STEP {
  // thank you,
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
  DONE = 'DONE',
}

interface IInternalNavigationButton extends INavigationButton {
  alterStep: ALTER_STEP | null
}

export interface IStepInfo {
  stepNum: number;
  instructions: IInstructions;
  nextButtonConfig: INavigationButton;
  previousButtonConfig?: INavigationButton;
  actionButtonConfig?: INavigationButton;
  title: string;
  actionComponents: React.ReactNode;
  disableDecrementStep?: boolean;
  disableNextStep?: boolean;
  oneStep: number;
}
export interface WizardProps {
  footerInfoComponent?: React.ReactNode;
  hideNavigation?: boolean;
  icon: React.ReactNode;
  initialStep?: number;
  onCancel?: () => void;
  onStepChange?: (newStep: number) => void;
  step?: number;
  stepsInfo: IStepInfo[];
  noHeader?: boolean;
  noBodyPadding?: boolean;
}

// TODO: Last step functionality should set steps back to 0?
export const WizardContainer: React.FC<Readonly<WizardProps>> = (props) => {
  const defaultStep = useMemo(() => {
    if (isNumber(props.step)) {
      return props.step;
    } else if (isNumber(props.initialStep)) {
      return props.initialStep;
    }
    return 1;
  }, [props.initialStep, props.step]);
  const [step, setStep] = useState<number>(defaultStep);
  const incrementStep = () => {
    setStep(Math.min(step + 1, props.stepsInfo.length));
  };
  const currentStepIdx = step - 1;

  useEffect(() => {
    if (!isFunction(props.onStepChange)) {
      return;
    }
    props.onStepChange(step);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (isNumber(props.step) && step !== props.step) {
      setStep(props.step);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);

  // todo: change the cancel button to a previous
  const decrementStep = () => {
    setStep(Math.max(step - 1, 1));
  };
  const isLastStep = step === props.stepsInfo.length;
  const currentStepInfo = props.stepsInfo[currentStepIdx];

  const nextButtonConfig: IInternalNavigationButton = {
    ...currentStepInfo.nextButtonConfig,

    // if last step or user disables, do not next step
    alterStep: (isLastStep || currentStepInfo.disableNextStep)
      ? null
      : ALTER_STEP.NEXT,
  };
  const actionButtonConfig: IInternalNavigationButton = {
    ...currentStepInfo.actionButtonConfig,
    alterStep: null,
  };
  // default config of previous button is to have no action and just go to previous
  let previousButtonConfig: IInternalNavigationButton;

  if (currentStepInfo.previousButtonConfig) {
    previousButtonConfig = {
      text: 'Cancel',
      ...currentStepInfo.previousButtonConfig,
      alterStep: currentStepInfo.disableDecrementStep ? null : ALTER_STEP.PREVIOUS,
    };
  } else if (step === 1) {
    previousButtonConfig = {
      text: 'Cancel',
      action: props.onCancel,
      disabled: true,
      alterStep: null,
      showIcon: false,
    };
  } else {
    // default config of previous button is to have no action and just go to previous
    const previousStepInfo = props.stepsInfo[currentStepIdx - 1];
    previousButtonConfig = {
      text: previousStepInfo.nextButtonConfig.text,
      action: null,
      alterStep: currentStepInfo.disableDecrementStep ? null : ALTER_STEP.PREVIOUS,
    };
  }

  const onButtonClick = (button: IInternalNavigationButton) => {
    if (button.action) {
      button.action();
    }

    switch (button.alterStep) {
      case ALTER_STEP.PREVIOUS:
        decrementStep();
        break;
      case ALTER_STEP.NEXT:
        incrementStep();
        break;
    }
  };

  return (
    <WizardComponent
      oneStep={props.stepsInfo.length === 1}
      actionComponents={currentStepInfo.actionComponents}
      hideNavigation={props.hideNavigation}
      instructions={currentStepInfo.instructions}
      icon={props.icon}
      key={`step-${step}`}
      title={currentStepInfo.title}
      footerInfoComponent={props.footerInfoComponent}
      onNext={() => onButtonClick(nextButtonConfig)}
      onPrevious={() => onButtonClick(previousButtonConfig)}
      nextButtonClassName={nextButtonConfig.className || ''}
      nextStepText={nextButtonConfig.text}
      nextStepDisabled={nextButtonConfig.disabled}
      nextStepLoading={nextButtonConfig.loading}
      showNextStepIcon={nextButtonConfig.showIcon}
      previousButtonClassName={previousButtonConfig.className || ''}
      previousStepDisabled={previousButtonConfig.disabled}
      previousText={previousButtonConfig.text}
      showPreviousStepIcon={previousButtonConfig.showIcon}
      noHeader={props.noHeader}
      noBodyPadding={props.noBodyPadding}
      actionButtonClassName={actionButtonConfig.className}
      actionButtonText={actionButtonConfig.text}
      actionButtonDisabled={actionButtonConfig.disabled}
      actionButtonLoading={actionButtonConfig.loading}
      onActionButton={() => onButtonClick(actionButtonConfig)}
    />
  );
};
WizardContainer.defaultProps = {
  hideNavigation: false,
};
