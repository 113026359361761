import * as React from 'react';
import { Card, Space, Typography } from '@revfluence/fresh';

import styles from './InfoCard.scss';

const { Title } = Typography;

interface IInfoCardProps {
  title: string;
  description: string;
  icon: React.ReactElement;
  display?: boolean;
  width?: number;
}

export const InfoCard = (props: IInfoCardProps) => {
  const {
    title, description, icon, width, display = true,
  } = props;
  if (!display) {
    return null;
  }
  const formattedTitle = (
    <div className={styles.CardIconDiv}>
      <Space direction="horizontal" align="center" size={12}>
        {icon}
        <Title className={styles.CardTitle} level={5}>{title}</Title>
      </Space>
    </div>
  );
  const formattedDescription = <Title level={5} weight="regular">{description}</Title>;

  return (
    <Card className={styles.Card} style={{ width }}>
      <Card.Meta title={formattedTitle} description={formattedDescription} />
    </Card>
  );
};
