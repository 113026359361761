import { useQuery, QueryHookOptions } from '@apollo/client';
import { isNil } from 'lodash';

import { GET_RAW_CONTENT_REVIEWS_QUERY } from '@frontend/app/queries';

import {
  GetRawContentReviewsQuery,
  GetRawContentReviewsQueryVariables,
} from '@frontend/app/queries/types/GetRawContentReviewsQuery';

type IOptions = QueryHookOptions<GetRawContentReviewsQuery, GetRawContentReviewsQueryVariables>;

export function useGetRawContentReviews(options: IOptions = {}) {
  const {
    loading, data: { reviews } = {}, error, refetch,
  } = useQuery<GetRawContentReviewsQuery, GetRawContentReviewsQueryVariables>(
    GET_RAW_CONTENT_REVIEWS_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: false,
      skip: isNil(options.variables?.programId) || isNil(options.variables?.memberId),
    },
  );

  return {
    loading,
    reviews,
    error,
    refetch,
  };
}
