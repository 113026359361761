import * as React from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { SendCatalogWrapper } from '../CreatorProductSelection/components/SendCatalog/SendCatalog';
import { OrderRequestReview } from '../CreatorProductSelection/container/OrderRequestReview';
import { SendCatalogContextProvider } from '../CreatorProductSelection/components/SendCatalog/SendCatalogContext';

export const CreatorProductSelectionV2: React.FC = () => {
  const { workflowActionParameters } = useApplication();

  if (workflowActionParameters.taskId === 'pfa_v2_send_order_request_task') {
    return (
      <SendCatalogContextProvider>
        <SendCatalogWrapper />
      </SendCatalogContextProvider>
    );
  }
  if (workflowActionParameters.taskId === 'pfa_v2_review_order_request_task') {
    return <OrderRequestReview />;
  }
};
