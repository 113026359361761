import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';

import { AppHeader } from '@frontend/app/refresh-components/AppHeader';
import { ProductFulfillmentShipmentPage } from './ProductFulfillmentShipmentPage';

interface IProps {
  refreshUi: boolean;
}

const application = {
  displayName: 'Product Fulfillment',
  iconUrl: 'https://storage.googleapis.com/aspireiq-widgets/assets/product-shipment.svg',
};

export const ProductFulfillmentAppDashboard: React.FunctionComponent<IProps> = ({ refreshUi }) => {
  const navSettings = [
    {
      route: 'shipments',
      displayName: 'My Shipments',
      component: ProductFulfillmentShipmentPage,
    },
  ];

  return (
    <>
      {refreshUi ? (
        <>
          <AppHeader
            title={application.displayName}
          />
          <div className="px-6" style={{ padding: 24 }}>
            <ProductFulfillmentShipmentPage />
          </div>
        </>
      ) : (
        <AppDashboardNav
          application={application}
          navLinks={navSettings}
          hideTabs
          defaultRoute="shipments"
        />
      )}
    </>
  );
};
