import * as React from 'react';
import cx from 'classnames';
import {
  List,
  Row,
  Col,
  Typography,
  Space,
  Button,
  Tooltip,
} from '@revfluence/fresh';
import { ReplyIcon } from '@revfluence/fresh-icons/regular/esm';

import { UserAvatar } from '@frontend/app/components';
import { formatMessageDateWithTime } from '@frontend/app/utils';
import { GetThreadQuery_thread_messages } from '@frontend/app/queries/types/GetThreadQuery';

import styles from './InstagramMessageItem.scss';
import { InstagramMessageItemDetails } from './InstagramMessageItemDetails';
import { InstagramReplyItem } from '../../model';
import { cleanHtml } from '../../../utils';

const { Item } = List;

const { Text } = Typography;
const { useState } = React;
interface IProps {
  threadMessage: GetThreadQuery_thread_messages;
  audienceHandler: string;
  onReplyHandleClick(replyItem: InstagramReplyItem): void;
}

export const InstagramMessageItem: React.FC<IProps> = (props) => {
  const {
    threadMessage,
    audienceHandler,
    onReplyHandleClick,
  } = props;

  // TODO: Add set the on hover method on the row by using onMouseLeave and onMouseOver
  // once the backend is ready to send reply messages.
  const [isOnHover] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const sentByUser = audienceHandler !== threadMessage.payload?.from;
  const dateSent = threadMessage.internalDate;
  const sender = threadMessage.payload?.from;
  const formattedDate = dateSent && formatMessageDateWithTime(dateSent, 'MM/dd hh:mm aaaa');
  const messageText = threadMessage.payload.textHtml || threadMessage.payload.textPlain;

  return (
    <Item>
      <Row
        wrap={false}
        className={cx([
          { [styles.incomingBox]: !sentByUser },
          { [styles.outgoingBox]: sentByUser },
        ])}
      >
        <Col className={styles.messageBox}>
          <Space
            align="start"
            className={cx([
              { [styles.outgoingBox]: !sentByUser },
            ])}
            size={16}
          >
            <Space
              direction="vertical"
              size={8}
              wrap
            >
              <InstagramMessageItemDetails
                messageItem={{
                  ...threadMessage.payload,
                  textMessage: cleanHtml(messageText),
                }}
                sentByUser={sentByUser}
                setIsExpired={setIsExpired}
                hideMentionTitle={false}
              />
              <Text className={styles.messageDate}>
                {formattedDate}
              </Text>
            </Space>
            <UserAvatar
              name={sender}
              size={32}
            />
          </Space>
        </Col>
        {!isExpired && isOnHover && (
          <Col
            className={cx([styles.replyButton, { [styles.replyButtonVisible]: isOnHover }])}
          >
            <Tooltip title="Reply" placement="top">
              <Button onClick={() => onReplyHandleClick({ audienceHandler, threadMessage })}>
                <ReplyIcon />
              </Button>
            </Tooltip>
          </Col>
        )}
        <Col flex="1 0 80px" />
      </Row>
    </Item>
  );
};
