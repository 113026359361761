import gql from 'graphql-tag';

import { AUTOMATION_EXECUTION_PERIODIC_STATS_FRAGMENT, AUTOMATION_LIFECYCLE_STATS_FRAGMENT } from './fragments';
import { PAGING_DATA_FRAGMENT } from './fragments/Pagination';
import { AUTOMATION_FRAGMENT } from './fragments/automations/Automation';
import { AUTOMATION_REVISION_FRAGMENT } from './fragments/automations/AutomationRevision';
import { AUTOMATION_TEMPLATE_FRAGMENT } from './fragments/automations/AutomationTemplate';
import { AUTOMATION_EXECUTION_FRAGMENT } from './fragments/automations/AutomationExecution';

// #region Templates
export const LIST_TEMPLATES_QUERY = gql`
  query ListTemplates(
    $paging: PagingParams!
    $filters: AutomationTemplateFilters
    $ordering: AutomationTemplateOrdering
    $context: AutomationTemplateContext
  ) {
    listTemplates(paging: $paging, filters: $filters, ordering: $ordering, context: $context) {
      paging {
        ...PagingDataFragment
      }
      results {
        ...AutomationTemplateFragment
      }
    }
  }
  ${PAGING_DATA_FRAGMENT}
  ${AUTOMATION_TEMPLATE_FRAGMENT}
`;

export const GET_TEMPLATE_QUERY = gql`
  query GetTemplate($templateId: String!, $context: AutomationTemplateContext) {
    template: getTemplate(templateId: $templateId, context: $context) {
      ...AutomationTemplateFragment
    }
  }
  ${AUTOMATION_TEMPLATE_FRAGMENT}
`;
// #endregion

// #region Automations
export const LIST_AUTOMATIONS_QUERY = gql`
  query ListAutomations($paging: PagingParams!, $filters: AutomationFilters, $ordering: AutomationOrdering) {
    listAutomations(paging: $paging, filters: $filters, ordering: $ordering) {
      paging {
        ...PagingDataFragment
      }
      results {
        ...AutomationFragment
      }
    }
  }
  ${PAGING_DATA_FRAGMENT}
  ${AUTOMATION_FRAGMENT}
`;

export const GET_AUTOMATION_QUERY = gql`
  query GetAutomation($automationId: String!) {
    automation: getAutomation(automationId: $automationId) {
      ...AutomationFragment
    }
  }
  ${AUTOMATION_FRAGMENT}
`;

export const CREATE_AUTOMATION_MUTATION = gql`
  mutation CreateAutomation($blueprint: AutomationBlueprintInput!, $options: AutomationCreateOptions) {
    automation: createAutomation(blueprint: $blueprint, options: $options) {
      ...AutomationFragment
    }
  }
  ${AUTOMATION_FRAGMENT}
`;

export const DELETE_AUTOMATION_MUTATION = gql`
  mutation DeleteAutomation($automationId: String!) {
    deleteAutomation(automationId: $automationId)
  }
`;

export const PUBLISH_AUTOMATION_MUTATION = gql`
  mutation PublishAutomation($automationId: String!, $options: AutomationPublishOptions) {
    publishAutomation(automationId: $automationId, options: $options) {
      ...AutomationFragment
    }
  }
  ${AUTOMATION_FRAGMENT}
`;

export const RESUME_AUTOMATION_MUTATION = gql`
  mutation ResumeAutomation($automationId: String!) {
    resumeAutomation(automationId: $automationId) {
      ...AutomationFragment
    }
  }
  ${AUTOMATION_FRAGMENT}
`;

export const DISABLE_AUTOMATION_MUTATION = gql`
  mutation DisableAutomation($automationId: String!) {
    disableAutomation(automationId: $automationId) {
      ...AutomationFragment
    }
  }
  ${AUTOMATION_FRAGMENT}
`;

export const GET_AUTOMATION_STATS_QUERY = gql`
  query GetAutomationStats($automationId: String!) {
    getAutomationStats(automationId: $automationId) {
      executions {
        ...AutomationExecutionPeriodicStatsFragment
      }
      lastExecutionDate
    }
  }
  ${AUTOMATION_EXECUTION_PERIODIC_STATS_FRAGMENT}
`;

export const GET_AUTOMATION_SUMMARY_QUERY = gql`
  query GetAutomationSummary($context: AutomationSummaryContext) {
    getAutomationSummary(context: $context) {
      automations {
        ...AutomationLifecycleStatsFragment
      }
      executions {
        ...AutomationExecutionPeriodicStatsFragment
      }
    }
  }
  ${AUTOMATION_LIFECYCLE_STATS_FRAGMENT}
  ${AUTOMATION_EXECUTION_PERIODIC_STATS_FRAGMENT}
`;

// TODO executeAutomation(automationId: string, triggerId: string, metadata: IAutomationExecutionMetadata, variables: IAutomationVariable[]): Promise<IAutomationExecution>;
// #endregion

// #region Revisions
export const LIST_REVISIONS_QUERY = gql`
  query ListRevisions($paging: PagingParams!) {
    listRevisions(paging: $paging) {
      paging {
        ...PagingDataFragment
      }
      results {
        ...AutomationRevisionFragment
      }
    }
  }
  ${PAGING_DATA_FRAGMENT}
  ${AUTOMATION_REVISION_FRAGMENT}
`;

export const GET_REVISION_QUERY = gql`
  query GetRevision($revisionId: String!) {
    revision: getRevision(revisionId: $revisionId) {
      ...AutomationRevisionFragment
    }
  }
  ${AUTOMATION_REVISION_FRAGMENT}
`;

export const CREATE_REVISION_MUTATION = gql`
  mutation CreateRevision($blueprint: AutomationBlueprintInput!, $options: AutomationRevisionCreateOptions) {
    revision: createRevision(blueprint: $blueprint, options: $options) {
      ...AutomationRevisionFragment
    }
  }
  ${AUTOMATION_REVISION_FRAGMENT}
`;
// #endregion

// #region Executions
export const LIST_EXECUTIONS = gql`
  query ListExecutions($filters: AutomationExecutionFilters, $paging: PagingParams!) {
    listExecutions: listExecutions(paging: $paging, filters: $filters) {
      results {
        ...AutomationExecutionFragment
      }
      paging {
        ...PagingDataFragment
      }
      __typename
    }
  }
  ${PAGING_DATA_FRAGMENT}
  ${AUTOMATION_EXECUTION_FRAGMENT}
`;
// TODO getExecution(executionId: string): Promise<IAutomationExecution>;
// TODO markExecution(executionId: string, status: AutomationExecutionStatus): Promise<IAutomationExecution>;

export const TERMINATE_EXECUTION_MUTATION = gql`
  mutation TerminateExecution($executionId: String!, $reason: String!) {
    terminateExecution(executionId: $executionId, reason: $reason)
  }
`;
// #endregion

// #region Nodes
export const GET_NODE_OPTIONS_QUERY = gql`
  query GetNodeOptions($context: AutomationNodeOptionsContext) {
    getNodeOptions(context: $context) {
      actionOptions {
        displayName
        type
        emailResources {
          id
          displayName
          fromPreviousSender
          userId
        }
        messageTemplates {
          id
          displayName
          messageTemplateType
          programId
        }
      }
      conditionOptions {
        id
        displayName
        attributeType
        variableType
        defaultValue
        group
        choices {
          value
          displayName
        }
      }
    }
  }
`;
// #endregion
