import * as React from 'react';
import {
  Button,
  Col, Modal, Popover, Radio, Row, Space, Typography, message,
} from '@revfluence/fresh';
import { createUpdateOfferPromoInput } from '@frontend/app/utils';
import styles from './MigrationModelContent.scss';
import { GetOfferById_offer } from '../../queries/types/GetOfferById';
import {
  OFFER_SOURCE, UserInfoInput,
} from '../../types/globalTypes';
import { useMigrateToMultiplePayout } from '../../hooks/useMigrateToMultiplePayout';
import { useUpdateOfferMutation } from '../../hooks';

const multiplePayoutIcon = require('@frontend/app/assets/images/multiplePayout.png');
const subscriptionIcon = require('@frontend/app/assets/images/subscriptionSupport.png');
const customDatetIcon = require('@frontend/app/assets/images/customDate.png');
const ungroupedPromoIcon = require('@frontend/app/assets/images/ungroupedPromo.png');
const groupedPromoIcon = require('@frontend/app/assets/images/groupedPromo.png');

const { useState, useMemo, useEffect } = React;
const { Text } = Typography;

enum GROUPING_TYPE {
  GROUPED = 'Grouped',
  UNGROUPED = 'UnGrouped',
}
interface IProps {
  baseUri: string,
  showMigrationModal: boolean,
  setShowMigrationModal: React.Dispatch<React.SetStateAction<boolean>>,
  offer: GetOfferById_offer,
  source: OFFER_SOURCE,
  profile: UserInfoInput,
  onComplete: () => void
}
export const MigrationModelContent: React.FC<Readonly<IProps>> = (props) => {
  const {
    showMigrationModal, setShowMigrationModal, offer, source, profile, onComplete,
  } = props;
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [grouped, setGrouped] = useState(GROUPING_TYPE.GROUPED);
  const offerId = offer?.id;

  const offerPayload = source === OFFER_SOURCE.SHOPIFY ? createUpdateOfferPromoInput(offer, profile, grouped === GROUPING_TYPE.UNGROUPED) : {};

  const [migrateOfferToMultiplePayout] = useMigrateToMultiplePayout();
  const [editOffer] = useUpdateOfferMutation();

  const onClickOk = async () => {
    if (step === 1) {
      if (source === OFFER_SOURCE.SHOPIFY) {
        setStep(2);
      } else {
        try {
          setIsLoading(true);
          await migrateOfferToMultiplePayout({
            variables: {
              id: offerId,
            },
          });
          onComplete();
          message.success('The offer has been successfully upgraded.', 5);
        } catch (e) {
          message.error('An error occured while upgrading this offer. Please try again or contact support.', 5);
        } finally {
          setIsLoading(false);
          setShowMigrationModal(false);
        }
      }
    } if (step === 2) {
      try {
        setIsLoading(true);
        await editOffer({
          variables: {
            id: offerId,
            data: offerPayload,
          },
        });
        onComplete();
        message.success('The offer has been successfully upgraded. It might take some time to migrate the existing promo codes.', 5);
      } catch (e) {
        message.error('An error occured while upgrading this offer. Please try again or contact support.', 5);
      } finally {
        setIsLoading(false);
        setShowMigrationModal(false);
      }
    }
  };
  const onClickCancel = () => {
    if (step === 1) {
      setShowMigrationModal(false);
    }
    if (step === 2) {
      setStep(1);
    }
  };
  const title = useMemo(() => {
    if (step === 1) {
      return 'Upgrade Your Sales Tracking Capabilities!';
    }
    if (step === 2) {
      return 'Before You Upgrade ';
    }
  }, [step]);

  const onButtonText = useMemo(() => {
    if (step === 1) {
      return 'Continue';
    }
    if (step === 2) {
      return 'Upgrade Now';
    }
  }, [step]);

  const onCancelButtonText = useMemo(() => {
    if (step === 1) {
      return '';
    }
    if (step === 2) {
      return 'Back';
    }
  }, [step]);

  useEffect(() => () => {
    setStep(1);
  }, []);
  return (
    <Modal
      width={612}
      title={title}
      open={showMigrationModal}
      onCancel={() => {
        setShowMigrationModal(false);
        setStep(1);
      }}
      footer={(
        <div className={styles.footerContainer}>
          <span>
            {/* Will Add when get the new help page  */}
            {/* <a href="https://help.aspireiq.com/en/articles/8535207-promo-code-offer-migration" target="_blank" rel="noopener noreferrer">
              <ArrowUpRightFromSquareIcon />
              {' '}
              Learn more about this in detail.
            </a> */}
          </span>
          <div>
            {
              !!onCancelButtonText.length && (
                <Button key="cancel" onClick={onClickCancel} className={styles.cancelButton} disabled={isLoading}>
                  {onCancelButtonText}
                </Button>
              )
            }
            <Button key="confirm" type="primary" onClick={onClickOk} loading={isLoading}>
              {onButtonText}
            </Button>
          </div>
        </div>
      )}
    >

      <Space className={styles.migrationModalContainer} direction="vertical">
        <Row className={styles.modalHeader}>
          <Text>We're changing how sales tracking works so that you have more control over your affiliate marketing campaigns.</Text>
          <Text>To get started, click on the 'Upgrade Now' button to transition all your existing offers to our latest sales tracking capabilities.</Text>
        </Row>
        {
          step === 1 ? (
            <Row className={styles.stepContainer}>
              <Col span={24}>
                <Row>
                  <Col span={5} className={styles.iconContainer}>
                    <img src={multiplePayoutIcon} />
                  </Col>
                  <Col span={18}>
                    <Row>
                      <Text>
                        Multiple Payout Values in Single Offer
                      </Text>
                      <Text className={styles.colorGrey}>
                        Now edit the commission values and easily move creators from one payout level to another.
                      </Text>
                    </Row>
                  </Col>
                </Row>
                {source === OFFER_SOURCE.SHOPIFY && (
                  <>
                    <Row>
                      <Col span={5} className={styles.iconContainer}>
                        <img src={subscriptionIcon} />
                      </Col>
                      <Col span={18}>
                        <Row>
                          <Text>
                            Subscription Supported Promo Codes
                          </Text>
                          <Text className={styles.colorGrey}>
                            You can now create promo codes for subscriptions based products and recurring orders for your creators.
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={5} className={styles.iconContainer}>
                        <img src={customDatetIcon} />
                      </Col>
                      <Col span={18}>
                        <Row>
                          <Text>
                            Have customized dates for each code
                          </Text>
                          <Text className={styles.colorGrey}>
                            You can either set the same dates for all the codes in this offer or customize the dates for individual codes.
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          )
            : step === 2 ? (
              <Row className={styles.stepContainer}>
                <Col span={24}>
                  <Row className={styles.stepContentHeader}>
                    <Text className={styles.text}>How would you prefer to organise your promo codes:</Text>
                  </Row>
                  <Radio.Group className="radioCustom" value={grouped} onChange={(e) => setGrouped(e.target.value)}>
                    <Popover title="Unified Date" content="In this case, Today’s date and time will be the activation date for the offer and codes, without affecting your reports" overlayStyle={{ maxWidth: '200px' }}>
                      <Radio value={GROUPING_TYPE.GROUPED}>
                        <Space className={styles.radioItemContainer} direction="vertical">
                          <img src={groupedPromoIcon} className={styles.image} />
                          <Text className={styles.text}>Set unified dates for all codes</Text>
                          <Text>You can have a common active and end date for all the promo codes in this offer.</Text>
                        </Space>
                      </Radio>
                    </Popover>
                    <Popover title="Custom Dates" content="Promo Codes current start and end dates will remain same." overlayStyle={{ maxWidth: '200px' }}>
                      <Radio value={GROUPING_TYPE.UNGROUPED}>
                        <Space className={styles.radioItemContainer} direction="vertical">
                          <img src={ungroupedPromoIcon} className={styles.image} />
                          <Text className={styles.text}>Customize dates for each code</Text>
                          <Text>You can have different active and end date for all the promo codes in this offer.</Text>
                        </Space>
                      </Radio>
                    </Popover>
                  </Radio.Group>
                </Col>
              </Row>
            )
              : null
        }
      </Space>
    </Modal>

  );
};
