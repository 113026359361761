import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CREATOR_TYPE_ORDER_COST_QUERY } from '../queries';
import { GetCreatorTypeOrderCost, GetCreatorTypeOrderCostVariables } from '../queries/types/GetCreatorTypeOrderCost';

type IOptions = QueryHookOptions<GetCreatorTypeOrderCost, GetCreatorTypeOrderCostVariables>;

export function useGetCreatorTypeOrderCost(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetCreatorTypeOrderCost, GetCreatorTypeOrderCostVariables>(
    GET_CREATOR_TYPE_ORDER_COST_QUERY,
    {
      ...options,
    },
  );

  return {
    isCreatorTypeLoading: loading,
    creatorTypeOrderCost: data?.creatorTypeOrderCost,
    creatorTypeError: error,
    refetchCreatorTypeOrderCost: refetch,
  };
}
