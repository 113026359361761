import { IDeliverableDueDateType } from './deliverableDueDate';
import { TPostType } from './postType';

export interface IContentRequirement {
  count: number;
  thumbnail_url?: string;
  network: string;
  image_url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link?: any;
  due_date: IDeliverableDueDateType;
  complete: boolean;
  description: string;
  due_ts: number;
  submitted: boolean;
  requires_approval: boolean;
  post_type: TPostType;
  account_name?: string;
  id: number;
}
