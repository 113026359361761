import * as React from 'react';
import cx from 'classnames';
import { Button, Tooltip } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { isEmpty, trim } from 'lodash';

import { TextInput } from '@frontend/app/components';
import { PROJECT_NAME_MAX_LENGTH } from '@frontend/app/containers/Projects/utils';

import { useValidateProjectName } from '@frontend/app/containers/Projects/hooks/useValidateProjectName';
import styles from './PageURLInput.scss';

const {
 useCallback, useEffect, useMemo, useState,
} = React;

interface IProps {
  baseUrl?: string;
  initialLandingPagePath?: string;
  onChange?(url: string);
  onCopyLink?();
  fullUrl: string;
  isLoading?: boolean;
  projectSubmittedForReview?: boolean;
}

export const PageURLInput: React.FC<IProps> = (props) => {
  const {
    baseUrl = '',
    initialLandingPagePath = '',
    onChange,
    onCopyLink,
    fullUrl,
    isLoading,
    projectSubmittedForReview,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [landingPagePath, setLandingPagePath] = useState('');

  useEffect(() => {
    setLandingPagePath(initialLandingPagePath);
  }, [initialLandingPagePath]);

  const {
    validate: validateProjectName,
  } = useValidateProjectName({ projectName: landingPagePath });

  const handleChange = useCallback(() => {
    if (!onChange) {
      return;
    }
    onChange(decodeURIComponent(`${baseUrl}${landingPagePath}`));
    setIsEditing(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, landingPagePath]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLandingPagePath(e.target.value);
  }, []);

  const handleCancel = useCallback(() => {
    setLandingPagePath(initialLandingPagePath);
    setIsEditing(false);
  }, [initialLandingPagePath]);

  const displayURL = useMemo(
    () => `${baseUrl}${landingPagePath}`,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditing, landingPagePath],
  );

  const {
    isValid: isValidPath,
    error: errorMessagePath,
    size: pathSize,
  } = useMemo(
    () => validateProjectName(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [landingPagePath],
  );

  const isDisabled = useMemo(() => (
    isLoading
      || !isValidPath
      || isEmpty(trim(landingPagePath))
  ), [isLoading, isValidPath, landingPagePath]);

  const renderDisplay = () => (
    <div
      className={styles.displayContainer}
    >
      <a href={fullUrl} target="_blank" className={styles.link}>
        {displayURL}
      </a>

      <div className={styles.buttonsContainer}>
        {projectSubmittedForReview && (
        <Tooltip title="Edit Link has been disabled since your project has been submitted to Marketplace. Please contact Aspire Support if you’d like to modify it">
          <Button
            size="small"
            className={styles.editBtn}
            type="link"
            disabled
          >
            Edit Link
          </Button>
        </Tooltip>
          )}

        {!projectSubmittedForReview && (
        <Button
          size="small"
          className={styles.editBtn}
          type="link"
          onClick={() => setIsEditing(true)}
        >
          Edit Link
        </Button>
          )}

        <Button
          size="small"
          className={styles.editBtn}
          type="link"
          onClick={onCopyLink}
        >
          Copy Link
        </Button>
      </div>

    </div>
    );

  const renderEdit = () => (
    <div
      className={styles.inputContainer}
    >
      <TextInput
        className={styles.urlInput}
        prefix="join/"
        value={landingPagePath}
        onChange={handleInputChange}
      />

      <div
        className={cx(
            styles.inputCounter,
            {
              [styles.invalidInput]: pathSize > PROJECT_NAME_MAX_LENGTH,
            },
          )}
      >
        {`${pathSize}/${PROJECT_NAME_MAX_LENGTH}`}
      </div>

      {
          errorMessagePath && (
            <div className={styles.warning}>
              <ExclamationCircleFilled
                className={styles.warningIcon}
              />
              <div
                className={styles.warningLabel}
              >
                {errorMessagePath}
              </div>
            </div>
          )
        }

      <div className={styles.warning}>
        <ExclamationCircleFilled
          className={styles.warningIcon}
        />
        <div
          className={styles.warningLabel}
        >
          Updating your program page URL will make all
          traffic going to the previous link invalid
        </div>
      </div>

      <div
        className={styles.inputBtnsContainer}
      >
        <Button
          type="link"
          className={cx(
              styles.inputBtn,
              styles.cancel,
            )}
          disabled={isLoading}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="link"
          className={cx(
              styles.inputBtn,
              styles.save,
              {
                [styles.disabled]: isDisabled,
              },
            )}
          disabled={isDisabled}
          loading={isLoading}
          onClick={handleChange}
        >
          Save
        </Button>
      </div>
    </div>
    );

  return (
    <div>
      {
        isEditing || isLoading
          ? renderEdit()
          : renderDisplay()
      }
    </div>
  );
};
