import * as React from 'react';
import cx from 'classnames';
import {
  chain,
  debounce,
  find,
  isEmpty,
  isFunction,
  map,
  reject,
  keys,
  pickBy,
  trim,
} from 'lodash';
import {
  Input,
  List,
  Popover,
  Select,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ListProps } from 'antd/lib/list';
import { PopoverProps } from 'antd/lib/popover';

import { useFuzzySearchByKeys } from '@frontend/app/hooks';
import { IColumnVisibility } from '@frontend/app/types/Columns';

import styles from './FilterMenuPopover.scss';

const {
 useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState,
} = React;

interface IProps<T> {
  className?: string;
  defaultSource?: ISource;
  footer?: React.ReactNode;
  handlesRef?: React.Ref<IFilterMenuPopoverHandles<T>>;
  header?: React.ReactNode;
  items: ISearchableItem<T>[];
  listProps?: ListProps<ISearchableItem<T>>;
  onItemClick?: (item: ISearchableItem<T>, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onSourceUpdate?: (items: ISearchableItem<T>[]) => void;
  popoverProps?: PopoverProps;
  renderItem?: (item: ISearchableItem<T>, index: number) => React.ReactNode;
  selectPlaceholderText?: string;
  showSearchInput?: boolean;
  showSourceSelect?: boolean;
  sources?: ISource[];
  visibility?: IColumnVisibility;
}

export interface IFilterMenuPopoverHandles<T> {
  renderItemLabel: (item: ISearchableItem<T>) => React.ReactNode;
}

export interface ISearchableItem<T> {
  /**
   * Search will be based on this label
   */
  label: string;
  /**
   * Custom value
   */
  value: T;
  /**
   * Items will be filtered by the source select
   */
  source?: ISource;
  /**
   * For the "Select All" item
   * This flag will make the item persist on source update but hidden on search mode
   */
  isSelectAll?: boolean;
  /**
   * Force this item to be not searchable
   */
  isSearchable?: boolean;
  /**
   * Disable
   */
  isDisabled?: boolean;
}

export interface ISource {
  /**
   * Display label shown on the Select
   */
  label: string;
  /**
   * Unique value
   */
  value: string;
  /**
   * Show all items
   */
  isSelectAll?: boolean;
}

/**
 * TODO: Create SourceSelectList -- wrap SelectList with a source selection
 * TODO: Use SourceSelectList here and rename to SourceSelectListPopover
 */
export const FilterMenuPopover = <T extends object>(props: React.PropsWithChildren<IProps<T>>) => {
  const {
    children,
    className,
    defaultSource,
    footer,
    handlesRef,
    header,
    items,
    listProps,
    onItemClick,
    onSourceUpdate,
    popoverProps,
    renderItem,
    selectPlaceholderText,
    showSearchInput,
    showSourceSelect,
    sources,
    visibility,
  } = props;
  const searchInputRef = useRef<React.ElementRef<typeof Input>>();
  const [source, setSource] = useState<ISource>(defaultSource);
  const [searchResult, setSearchResult] = useState<typeof items>();

  const selectedVisibleColumns = useMemo(() => {
    if (!visibility) return 0;
    return keys(pickBy(visibility)).length;
  }, [visibility]);
  /**
   * Search
   */
  const dataSource = useMemo(() => (
    chain(items)
      // Reject items forcefully set to not be searchable
      .reject((item) => item.isSearchable === false)
      // Filter by source (if available) but keep the "Select All" item (if available)
      .filter((item) => item.isSelectAll || (
        source?.value
          ? item.source?.value === source.value
          : true // no source, no filter
      ))
      // (If available) set an item count after the "Select All" text
      .map((item, _) => (
        item.isSelectAll
          ? {
            ...item,
            label: visibility ? `${item.label} (${selectedVisibleColumns})` : `${item.label}`,
          }
          : item
      ))
      .value()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [items, source]);
  const searchDataSource = useFuzzySearchByKeys<ISearchableItem<T>>(dataSource, ['label']);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((text: string) => {
      if (trim(text)) {
        const result = searchDataSource(trim(text));
        setSearchResult(result);
      } else {
        setSearchResult(null);
      }
    }, 300),
    [searchDataSource],
  );

  useEffect(() => {
    // Redo search when source is modified
    debouncedSearch(searchInputRef.current?.input?.value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  useEffect(() => {
    if (isFunction(onSourceUpdate)) {
      onSourceUpdate(dataSource);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSourceUpdate, source]);

  /**
   * Select
   */
  const renderSourceSelect = () => (
    showSourceSelect && (
    <>
      <Select
        className={styles.select}
        value={source?.value}
        onChange={(selected: string) => {
          const newSource = find(sources, (s) => s.value === selected);
          if (newSource) {
            setSource(newSource);
          }
        }}
        placeholder={selectPlaceholderText}
      >
        {map(sources, (s) => (
          <Select.Option
            key={s.value}
            value={s.value}
            className={styles.selectItem}
          >
            {s.label}
          </Select.Option>
        ))}
      </Select>
    </>
)
  );

  /**
   * Search
   */
  const renderSearchInput = () => (
    showSearchInput && (
      <Input
        className={styles.searchInput}
        allowClear
        prefix={<SearchOutlined />}
        onChange={(e) => debouncedSearch(e.target.value)}
        ref={searchInputRef}
        // autoFocus attr works only once as the whole popover is always mounted even while its invisble
        autoFocus
      />
    )
  );

  useEffect(() => {
    if (searchInputRef.current && props.popoverProps?.visible) {
      // schedule focus call to garnute it's called while the element is already rendered
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputRef.current, props.popoverProps?.visible]);

  /**
   * List
   */
  const renderList = () => {
    const data = searchResult
      ? reject(searchResult, (item) => item.isSelectAll)
      : dataSource;

    return (
      <List<ISearchableItem<T>>
        className={styles.list}
        size="small"
        bordered={false}
        {...listProps}
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item
            className={cx(styles.item)}
            onClick={(e) => {
              if (
                !item.isDisabled
                  && isFunction(onItemClick)
              ) {
                onItemClick(item, e);
              }
            }}
          >
            {isFunction(renderItem)
              ? renderItem(item, index)
              : renderItemLabel(item)}
          </List.Item>
        )}
      />
    );
  };

  const renderItemLabel = (item: ISearchableItem<T>) => {
    const searchQuery = trim(searchInputRef.current?.input?.value);
    if (isEmpty(searchQuery)) {
      return item.label;
    }
    const pattern = new RegExp(`(${searchQuery})`, 'gi');
    const parts = item.label?.split(pattern);
    const itemSource = item.source?.label;
    return (
      <>
        {map(parts, (part, i) => (
        part.match(pattern)
          ? (
            <span
              key={`${part}-${i}`}
              className={styles.highlight}
            >
              {part}
            </span>
          )
          : part
      ))}
        {itemSource && isEmpty(source?.value) && (
        <span className={styles.source}>
          {` in ${itemSource}`}
        </span>
      )}
      </>
    );
  };

  useImperativeHandle(handlesRef, () => ({
    renderItemLabel,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [dataSource, renderItemLabel]);

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      {...popoverProps}
      overlayClassName={cx(className, styles.FilterMenuPopover)}
      content={(
        <>
          {header}
          {/* Not using Popover's title prop */}
          {renderSourceSelect()}
          {renderSearchInput()}
          {renderList()}
          {footer}
        </>
      )}
    >
      {children}
    </Popover>
  );
};

FilterMenuPopover.defaultProps = {
  selectPlaceholderText: 'All',
  showSearchInput: true,
  showSourceSelect: true,
  sources: [],
};
