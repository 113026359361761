import {
  Menu, Dropdown, Tooltip,
} from 'antd';
import cx from 'classnames';
import { size } from 'lodash';
import * as React from 'react';

import { Button } from '@revfluence/fresh';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import {
  ModifyTagMembersModal,
  TagsIcon,
} from '@frontend/app/components';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';

import styles from './AddTags.scss';

const { useState } = React;

interface IProps {
  className?: string;
  disabled?: boolean;
  memberCount?: number;
  memberIds?: number[]
  searchQuery?: IMemberSearchQuery;
}

type TModalMode = 'add' | 'remove' | null;

export const AddTags: React.FunctionComponent<IProps> = React.memo(({
  className,
  disabled,
  memberCount,
  memberIds,
  searchQuery,
}) => {
  const [shownModal, setShownModal] = useState<TModalMode>();

  const addEvent = useEventContext();

  const handleMenuItemClick = (type) => {
    addEvent(
      EventName.AttemptBulkAction,
      {
        action: type === 'add' ? 'add_to_tag' : (type === 'remove' ? 'remove_from_tag' : null),
        member_count: size(memberIds) > 0 ? size(memberIds) : memberCount,
      },
    );
    setShownModal(type);
  };

  const onCloseModal = () => {
    setShownModal(null);
  };

  const renderButton = () => (
    <Tooltip title="Tags">
      <span
        className={cx(
          styles.wrapper,
          className,
          { [styles.disabled]: disabled },
        )}
      >
        <Button
          disabled={disabled}
          icon={<TagsIcon size={16} />}
          className={styles.button}
        />
      </span>
    </Tooltip>
  );
  const renderContent = () => (
    <Menu
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      className={(styles as any).menu}
      selectedKeys={[]}
    >
      <Menu.Item
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        className={(styles as any).menuItem}
        onClick={() => handleMenuItemClick('add')}
      >
        Assign Tags
      </Menu.Item>
      <Menu.Item
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        className={(styles as any).menuItem}
        onClick={() => handleMenuItemClick('remove')}
      >
        Unassign Tags
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        disabled={disabled}
        overlay={renderContent()}
        placement="bottomLeft"
        trigger={['click']}
      >
        {renderButton()}
      </Dropdown>
      {shownModal && (
        <ModifyTagMembersModal
          memberCount={memberCount}
          memberIds={memberIds}
          onRequestClose={onCloseModal}
          query={searchQuery}
          show={(
            shownModal === 'add'
            || shownModal === 'remove'
          )}
          type={shownModal}
          showNewTagButton
        />
      )}
    </>
  );
});

AddTags.displayName = 'AddTags';
