import React, { useEffect } from 'react';
import {
    Button, Empty, message, Row, Skeleton, Space, Spinner, Table, Typography,
} from '@revfluence/fresh';
import { ArrowLeftIcon, BoxesStackedIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import styles from './EditCollectionItem.scss';
import { StatCard } from './StatCard';
import { pfaV2Routes } from '../../routes';
import { useBasicSelectionContext } from '../CatalogSelectionModal/BasicSelectionContext';
import { useGetFeaturedProducts } from '../hooks/useGetFeaturedProducts';
import { FeaturedProductsSelectionModal } from '../FeaturedProductsSelectionModal/FeaturedProductsSelectionModal';
import { useProductTableProps } from '../FeaturedProductsSelectionModal/useProductTableProps';
import { useGetCatalogDetails } from '../hooks/useGetCatalogDetails';

const { Title, Text } = Typography;

export const FeaturedProducts = () => {
  const { catalogId } = useParams<{ catalogId: string }>();

  const { catalog, loading: isCatalogLoading } = useGetCatalogDetails({
    variables: {
      id: Number(catalogId),
    },
    skip: !catalogId || Number.isNaN(Number(catalogId)),
  });

  const { featuredProducts, loading } = useGetFeaturedProducts({
    variables: {
      catalogId: Number(catalogId),
      includeProductDetails: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  const productsCount = featuredProducts.length;
  const variantsCount = featuredProducts.reduce((acc, product) => acc + (product.variantIds.length || product.product.totalVariants), 0);

  const {
 bulkSelect, openFeaturedProductsEditingModal,
} = useBasicSelectionContext();

  const history = useHistory();

  useEffect(() => {
    bulkSelect({
      products: featuredProducts.map((product) => ({
        id: String(product.productId),
        totalVariants: product.variantIds.length ? product.variantIds.length : product.product.totalVariants,
      })),
    });
  }, [bulkSelect, featuredProducts]);

  const {
    columnConfig, tableData, expandable, pagination,
  } = useProductTableProps({
    // @ts-expect-error we are not using the whole product object
    products: featuredProducts.map((product) => product.product),
    totalProducts: featuredProducts.length,
    onNextPage: () => null,
    onPreviousPage: () => null,
    pageNumber: 1,
    isReadonly: true,
  });

  if (!isCatalogLoading && !catalog?.settings?.isFeatureProductsEnable) {
    message.warning('Featured products are disabled for this catalog', 10);
    history.push(pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', catalogId));
    return null;
  }

  const handleBack = () => {
    history.push(
      pfaV2Routes.settings.brandCatalogsDetails.replace(':catalogId', catalogId),
    );
  };

  const openSelectionModal = () => {
    openFeaturedProductsEditingModal();
  };

  return (
    <>
      <Space direction="vertical" className={styles.EditCollectionItem} size="large">
        <Space size="middle" className={styles.header} align="center">
          <Button icon={<ArrowLeftIcon />} type="text" onClick={handleBack} />
          <Title level={4} className={styles.title}>
            Featured Products
          </Title>
        </Space>
        <Text type="secondary">Feature up to 20 products on your homepage.</Text>
        <>
          {loading ? <Spinner /> : !!productsCount && (
            <Space size={10}>
              <StatCard title="Products" count={productsCount} outOff={20} />
              <StatCard title="Variants" count={variantsCount} />
            </Space>
            )}
          <div>
            <Row align="middle" justify="space-between" className={styles.manageProductsRow}>
              <Text weight="semibold">Products</Text>
              {loading ? <Skeleton.Button /> : (
                <Button type="link" icon={<PlusIcon />} onClick={openSelectionModal}>
                  {productsCount > 0 ? 'Manage Products' : 'Add Products'}
                </Button>
              )}
            </Row>
            {
              loading && <Skeleton />
            }
            {
              !loading && !productsCount && (
                <Empty
                  image={<BoxesStackedIcon />}
                  description={<Text type="secondary">Start adding products here to feature them on your homepage.</Text>}
                  size="small"
                >
                  <Button icon={<PlusIcon />} onClick={openSelectionModal}>Add Products</Button>
                </Empty>
              )
            }
            {!!productsCount && (
              <Table
                columns={columnConfig}
                dataSource={tableData}
                className="offerTable"
                size="small"
                expandable={expandable}
                showHeader={false}
                rowKey="id"
                pagination={pagination}
                loading={loading}
              />
            )}
          </div>
        </>
      </Space>
      <FeaturedProductsSelectionModal />
    </>
  );
};
