import * as React from 'react';
import cx from 'classnames';
import { first, isArray } from 'lodash';
import { InputNumber } from 'antd';

import styles from './NumberEqual.scss';

const { useRef } = React;

interface IProps {
  value: number;
  isPercentage?: boolean;
  onChange?(value: number);
}

export interface INumberEqualHandles {
  focus(): void;
}

const NumberEqualComponent: React.RefForwardingComponent<INumberEqualHandles, IProps> = (props, _) => {
  const {
    value,
    onChange,
    isPercentage,
  } = props;

  if (isArray(value)) {
    onChange(first(value));
  }

  const inputNumberRef = useRef();

  return (
    <InputNumber
      className={cx([styles.NumberEqual, { [styles.percentage]: isPercentage }])}
      size="large"
      autoFocus
      value={isArray(value) ? first(value) : value}
      onChange={onChange}
      ref={inputNumberRef}
      min={isPercentage ? 0 : undefined}
      max={isPercentage ? 100 : undefined}
    />
  );
};

export const NumberEqual = React.forwardRef(NumberEqualComponent);
