import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import {
  ADD_MEMBERS_TO_TAGS_MUTATION,
} from '@frontend/app/queries';
import {
  AddMembersToTagsMutation,
  AddMembersToTagsMutationVariables,
} from '@frontend/app/queries/types/AddMembersToTagsMutation';

type IOptions = MutationHookOptions<AddMembersToTagsMutation, AddMembersToTagsMutationVariables>;

export const useAddMembersToTagsMutation = (options: IOptions = {}) => useMemberMutation<AddMembersToTagsMutation, AddMembersToTagsMutationVariables>(
    ADD_MEMBERS_TO_TAGS_MUTATION,
    options,
  );
