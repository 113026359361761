import * as React from 'react';
import cx from 'classnames';
import {
 isEmpty, find, pullAllBy,
} from 'lodash';

import {
  ContentState,
} from 'draft-js';
import {
  EditIcon, ArrowLeftIcon, Button,
  Input, KeyboardArrowLeftIcon,
} from '@components';
import { Popover } from 'antd';
import { useAuth } from '@frontend/context/authContext';
import { BackupType, MessageBackupPlanEntity } from '@frontend/app/types/globalTypes';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useEmailEditorContext } from '../../EmailEditorContext';
import { getPreviewValue } from './utils';

const { useState } = React;

import styles from './VariableBlock.module.scss';

interface IProps {
  contentState: ContentState;
  entityKey: string;
  blockKey: string;
  updateEntityData(newContentState: ContentState): void;
  setEditorDisabled(disabled: boolean): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const VariableBlock: React.FC<IProps> = React.memo((props) => {
  const {
    disableRemoveRecipients,
    variables: contextVariables, previewMember,
    backupPlans, setBackupPlans,
    invalidMemberIdsForField,
  } = useEmailEditorContext();

  // TODO - the PFA using proxy so need to pass via context
  const pfaContextVariables = useProductFulfillmentContext()?.variables;
  const pfaVariables = useProductFulfillmentContext()?.usePfaContextVariables && pfaContextVariables;
  const variables = pfaVariables || contextVariables;
  const { user } = useAuth();
  const { contentState, setEditorDisabled, entityKey } = props;
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { data } = contentState.getEntity(entityKey).getData();
  const { field } = data;
  const [mode, setMode] = useState<'menu' | 'custom'>('menu');
  const [customText, setCustomText] = useState('');
  const previewMode = !!previewMember;
  const isInvalid = !isEmpty(invalidMemberIdsForField[field]);
  const backupPlanForVariable = find(backupPlans, (plan) => plan.field === field);

  const addBackupPlan = (backupType: BackupType, value?: string) => {
    pullAllBy(backupPlans, [{ field }], 'field');

    setBackupPlans([
      ...backupPlans,
      {
        entity: MessageBackupPlanEntity.MEMBER,
        backupType,
        field,
        value,
      },
    ]);

    setPopoverVisible(false);
    setEditorDisabled(false);
  };

  const MenuContent = (
    <div className={styles.PopoverContent}>
      <div className={styles.title}>Choose a replacement for this variable</div>
      <div className={styles.text}>One or more of the recipients of this message have invalid data for your chosen variable.</div>
      <div
        className={cx(styles.item, {
          [styles.active]: backupPlanForVariable?.backupType === BackupType.CUSTOM_TEXT,
        })}
        onClick={() => {
          if (backupPlanForVariable?.backupType === BackupType.CUSTOM_TEXT) {
            setCustomText(backupPlanForVariable.value);
          }

          setMode('custom');
        }}
      >
        Use custom text
        <KeyboardArrowLeftIcon size={16} className={styles.icon} />
      </div>
      <div
        className={cx(styles.item, {
          [styles.active]: backupPlanForVariable?.backupType === BackupType.LEAVE_BLANK,
        })}
        onClick={() => addBackupPlan(BackupType.LEAVE_BLANK)}
      >
        Leave blank
      </div>
      {!disableRemoveRecipients && (
        <div
          className={cx(styles.item, {
            [styles.active]: backupPlanForVariable?.backupType === BackupType.REMOVE_INVALID,
          })}
          onClick={() => addBackupPlan(BackupType.REMOVE_INVALID)}
        >
          Remove invalid recipients
        </div>
      )}
    </div>
  );

  const CustomTextContent = (
    <div className={cx(styles.PopoverContent, styles.CustomText)}>
      <div className={styles.header}>
        <div
          className={styles.backButton}
          onClick={() => {
            setCustomText('');
            setMode('menu');
          }}
        >
          <ArrowLeftIcon size={20} />
        </div>
        Use custom text
      </div>
      <div className={styles.text}>Enter custom text to replace the variable.</div>
      <Input
        className={styles.input}
        value={customText}
        onChange={setCustomText}
      />
      <Button
        label="Save"
        onClick={() => {
          addBackupPlan(BackupType.CUSTOM_TEXT, customText);

          setCustomText('');
          setMode('menu');
        }}
      />
    </div>
  );

  if (isInvalid && !previewMode) {
    return (
      <div className={cx(styles.VariableBlock, {
        [styles.variable]: true,
        [styles.invalid]: isInvalid,
        [styles.hasPlan]: !!backupPlanForVariable,
      })}
      >
        {isInvalid && (
          <Popover
            content={mode === 'menu' ? MenuContent : CustomTextContent}
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={(visible) => {
              if (visible) {
                setEditorDisabled(true);
              } else {
                setEditorDisabled(false);
              }

              setPopoverVisible(visible);
            }}
          >
            <EditIcon size={16} className={styles.icon} />
          </Popover>
        )}
        {props.children}
      </div>
    );
  }

  return (
    <div className={cx(styles.VariableBlock, {
      [styles.variable]: !previewMode,
    })}
    >
      {previewMode && getPreviewValue({
        field,
        variables,
        member: previewMember,
        backupPlan: backupPlanForVariable,
        user,
      })}
      {!previewMode && props.children}
    </div>
  );
});

VariableBlock.displayName = 'VariableBlock';
