/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_yiltz_157 {
  display: flex;
}

._justify-content-space-between_yiltz_161 {
  justify-content: space-between;
}

._tabular-nums_yiltz_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_yiltz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Wizard_yiltz_178 {
  display: flex;
  flex-flow: column;
  align-content: center;
  justify-content: flex-start;
}
._Wizard_yiltz_178 ._steps_yiltz_184 {
  width: 85%;
  margin: 0 auto 1rem;
}
._Wizard_yiltz_178 > *:not(:first-child) {
  animation: _fadeIn_yiltz_1 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

@keyframes _fadeIn_yiltz_1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}