import { IState } from '../../../../types/state';
import { IUpdateShowHideDescriptionAction } from './index';

export const updateShowHidePaymentOption = (state: IState, action: IUpdateShowHideDescriptionAction): IState => {
  if (state.collaborationDetails.reward[action.paymentType].showHideDescription === action.showHideDescription) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      reward: {
        ...state.collaborationDetails.reward,
        [action.paymentType]: {
          ...state.collaborationDetails.reward[action.paymentType],
          showHideDescription: action.showHideDescription,
        },
      },
    },
  };
};
