import 'css-chunk:src/app/containers/GuestHome/TopNavbar/TopbarDropdown.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_9bkuu_157",
  "justify-content-space-between": "_justify-content-space-between_9bkuu_161",
  "tabular-nums": "_tabular-nums_9bkuu_165",
  "TopNavbarDropdown": "_TopNavbarDropdown_9bkuu_209",
  "TopNavbarSubmenu": "_TopNavbarSubmenu_9bkuu_216",
  "userMenuContent": "_userMenuContent_9bkuu_220",
  "userEmail": "_userEmail_9bkuu_226",
  "switchAccounts": "_switchAccounts_9bkuu_229",
  "TopNavbarSubmenuLong": "_TopNavbarSubmenuLong_9bkuu_236",
  "TopNavbarSubmenuImage": "_TopNavbarSubmenuImage_9bkuu_242",
  "TopNavbarSubmenuItemCreate": "_TopNavbarSubmenuItemCreate_9bkuu_247",
  "TopNavbarSubmenuText": "_TopNavbarSubmenuText_9bkuu_253",
  "UserClientMenu": "_UserClientMenu_9bkuu_261",
  "client": "_client_9bkuu_265",
  "active": "_active_9bkuu_272",
  "TopNavbarSubmenuItem": "_TopNavbarSubmenuItem_9bkuu_247",
  "selected": "_selected_9bkuu_289",
  "show": "_show_9bkuu_1"
};