/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1v8tf_157 {
  display: flex;
}

._justify-content-space-between_1v8tf_161 {
  justify-content: space-between;
}

._tabular-nums_1v8tf_165 {
  font-variant-numeric: tabular-nums;
}

._Checkbox_1v8tf_169 ._box_1v8tf_169 ::selection, ._Checkbox_1v8tf_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1v8tf_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Checkbox_1v8tf_169 ._box_1v8tf_169, ._Checkbox_1v8tf_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Checkbox_1v8tf_169 ._box_1v8tf_169 ::-webkit-scrollbar, ._Checkbox_1v8tf_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Checkbox_1v8tf_169 ._box_1v8tf_169 ::-webkit-scrollbar-track, ._Checkbox_1v8tf_169 ::-webkit-scrollbar-track, ._Checkbox_1v8tf_169 ._box_1v8tf_169 ::-webkit-scrollbar-track-piece, ._Checkbox_1v8tf_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Checkbox_1v8tf_169 ._box_1v8tf_169 ::-webkit-scrollbar-thumb, ._Checkbox_1v8tf_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Checkbox_1v8tf_169 {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Checkbox_1v8tf_169._disabled_1v8tf_244 {
  cursor: not-allowed;
}
._Checkbox_1v8tf_169._disabled_1v8tf_244 ._box_1v8tf_169 {
  background-color: #ececec;
  filter: grayscale(1);
}
._Checkbox_1v8tf_169._disabled_1v8tf_244 ._box_1v8tf_169 *, ._Checkbox_1v8tf_169._disabled_1v8tf_244 ._box_1v8tf_169 + * {
  pointer-events: none;
}
._Checkbox_1v8tf_169._disabled_1v8tf_244:hover {
  background-color: #fafafa;
}
._Checkbox_1v8tf_169._hover_1v8tf_257:not([class*=disabled]) {
  background-color: #f0f4f8;
}
._Checkbox_1v8tf_169._hover_1v8tf_257:not([class*=disabled])._noLabel_1v8tf_260 {
  background-color: transparent;
}
._Checkbox_1v8tf_169._hover_1v8tf_257:not([class*=disabled]) ._box_1v8tf_169 {
  border-color: #dadcde;
}
._Checkbox_1v8tf_169._hover_1v8tf_257:not([class*=disabled]) ._label_1v8tf_266 {
  color: #1a1818;
}
._Checkbox_1v8tf_169._checked_1v8tf_269 ._label_1v8tf_266 {
  color: #1a1818;
}
._Checkbox_1v8tf_169._checked_1v8tf_269 ._checkbox_1v8tf_272 {
  opacity: 1;
  color: #fdfdfd;
  fill: #167cf4;
  transform: scale(1);
  transition: opacity 0s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._Checkbox_1v8tf_169._checked_1v8tf_269 ._box_1v8tf_169, ._Checkbox_1v8tf_169._checked_1v8tf_269:hover ._box_1v8tf_169 {
  background-color: transparent;
  border-color: transparent;
}
._Checkbox_1v8tf_169 ._box_1v8tf_169 {
  width: 1.3125rem;
  height: 1.3125rem;
  position: relative;
  background-color: #fdfdfd;
  border-radius: 0.125rem;
  border: 0.0625rem solid #dadcde;
  will-change: background-color, border-color;
  transition: background-color 0.25s cubic-bezier(0.23, 1, 0.32, 1), border-color 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._Checkbox_1v8tf_169 ._checkbox_1v8tf_272 {
  position: absolute;
  top: -0.28125rem;
  left: -0.28125rem;
  opacity: 0;
  color: #fdfdfd;
  fill: #3996e0;
  transform: scale(0);
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0.25s;
}
._Checkbox_1v8tf_169 ._label_1v8tf_266 {
  flex: 1;
  position: relative;
  margin-left: 0.5rem;
  color: #8f8d91;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  user-select: none;
  will-change: color;
}