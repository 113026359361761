import * as React from 'react';
import { Empty, Typography } from '@revfluence/fresh';
import { BuildingIcon } from '@revfluence/fresh-icons/regular/esm';

const { Title, Paragraph } = Typography;

export const AllowlistingTableZeroState: React.FC = () => (
  <Empty
    size="small"
    image={<BuildingIcon />}
    description={(
      <>
        <Title level={5}>No connected business</Title>
        <Paragraph type="secondary">Select which business to use for allowlisting.</Paragraph>
      </>
    )}
  />
);
