/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._IconButton_f1pbl_79 {
  position: relative;
  padding: 12px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  background-color: transparent;
  border-radius: 0.5rem;
  will-change: color, background-color;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._IconButton_f1pbl_79:hover._black_f1pbl_93 {
  background-color: #eff5f9;
}
._IconButton_f1pbl_79:hover._light_f1pbl_96 {
  background-color: #e6edf2;
}
._IconButton_f1pbl_79:hover._info_f1pbl_99 {
  color: #4fa7ee;
  background-color: #e6edf2;
}
._IconButton_f1pbl_79:hover._primary_f1pbl_103 {
  background-color: #4fa7ee;
}
._IconButton_f1pbl_79:active._black_f1pbl_93 {
  background-color: #eff5f9;
}
._IconButton_f1pbl_79:active._light_f1pbl_96 {
  background-color: #e6edf2;
}
._IconButton_f1pbl_79:active._info_f1pbl_99 {
  color: #3996e0;
  background-color: #e6edf2;
}
._IconButton_f1pbl_79:active._primary_f1pbl_103 {
  background-color: #4fa7ee;
}
._IconButton_f1pbl_79._round_f1pbl_119 {
  border-radius: 624.9375rem;
}
._IconButton_f1pbl_79._border_f1pbl_122 {
  border: 1px solid #dadcde;
}
._IconButton_f1pbl_79._black_f1pbl_93 {
  color: #1a1818;
}
._IconButton_f1pbl_79._light_f1pbl_96 {
  color: white;
}
._IconButton_f1pbl_79._info_f1pbl_99 {
  color: #3996e0;
  background-color: #eff5f9;
}
._IconButton_f1pbl_79._primary_f1pbl_103 {
  color: white;
  background-color: #3996e0;
}
._IconButton_f1pbl_79._disabled_f1pbl_139 {
  pointer-events: none;
}
._IconButton_f1pbl_79 ._mask_f1pbl_142 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(253, 253, 253, 0.5);
}