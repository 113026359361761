import * as React from 'react';

import { Col, Row } from '@revfluence/fresh';
import Trend from '../../../../components/Trend';
import Statistics from '../../../../components/Statistics';
import { IImpactHomeProps } from '../../Home';
import { mapMetric } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary';
import { TypeStatistics } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary.types';
import styles from './MetricsSecondary.scss';

type IMetricsProps = {
  trends: IImpactHomeProps['trends'];
  igStoryEstimationEnabled?: boolean;
  tempYoutubeDemoAccountMode?: boolean;
};

type Keys = keyof IImpactHomeProps['trends'];

const mapTrends = (trends: IImpactHomeProps['trends'], igStoryEstimationEnabled: boolean, tempYoutubeDemoAccountMode: boolean): TypeStatistics[] => {
  const metrics = ['reach', 'views', 'conversions', 'creators', 'cpm',
    'cpe', 'tmv'] as Keys[];
  return metrics.map((metric) => mapMetric(metric, trends[metric], igStoryEstimationEnabled, tempYoutubeDemoAccountMode));
};

const MetricsSecondary = (props: IMetricsProps) => {
  const mappedProps = mapTrends(props.trends, props.igStoryEstimationEnabled, props.tempYoutubeDemoAccountMode);

  return (
    <Row gutter={[24, 24]}>
      {mappedProps.map((item) => (
        <Col flex={1} key={item.id}>
          <Statistics
            className={styles.statistic}
            {...item.statisticProps}
            suffix={<Trend {...item.trendProps} />}
            padded
          />
        </Col>
      ))}
    </Row>
  );
};

export default MetricsSecondary;
