/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_155nc_157 {
  display: flex;
}

._justify-content-space-between_155nc_161 {
  justify-content: space-between;
}

._tabular-nums_155nc_165 {
  font-variant-numeric: tabular-nums;
}

._Cell_155nc_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_155nc_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Cell_155nc_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Cell_155nc_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Cell_155nc_169 ::-webkit-scrollbar-track, ._Cell_155nc_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Cell_155nc_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Cell_155nc_169 {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
._Cell_155nc_169._fixedHeight_155nc_210 {
  justify-content: center;
}
._Cell_155nc_169._fixedHeight_155nc_210 ._content_155nc_213 {
  white-space: nowrap;
  text-overflow: ellipsis;
}
._Cell_155nc_169 ._content_155nc_213 {
  position: relative;
  padding: 0.25rem 0.75rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}