import * as React from 'react';
import { Tag } from '@revfluence/fresh';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';

interface IWarningTagProps {
  className?: string;
}

export const WarningTag: React.FC<IWarningTagProps> = ({ className }) => (
  <Tag color="warning" icon={<CircleExclamationIcon />} className={className}>Active</Tag>
);
