import {
 clone, keys, times, isNumber, isEmpty,
} from 'lodash';

export type TSortType = 'ASC' | 'DESC';

// sort types
export const SortTypes: {
  [key: string]: TSortType;
} = {
  ASC: 'ASC',
  DESC: 'DESC',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TRawDataType = any;
export interface ISortableData {
  // id has to be string, because it will be stored in idToIndex as key
  // and will be converted to string
  id: string;

  // the raw data type, can be anything(most likely object)
  _raw: TRawDataType;

  // list of override sort fields (use these fields to sort instead of values)
  _sortableFields?: {
    [field: string]: string | number;
  };
}

/**
 * @class
 * Sorted data list, used by data table.
 */
export class SortedDataList<T extends ISortableData> {
  private indexMap: number[];

  private data: T[];

  // used for returning selected data by ids.
  private idToIndex: {
    [id: string]: number;
  };

  /**
   * @constructor
   * @param  {Number[]} indexMap the index map for this ordered data list.
   * @param  {Object[]} data     the data in this ordered data list.
   */
  constructor(indexMap: number[], data: T[]) {
    this.indexMap = indexMap;
    this.data = data;

    this.idToIndex = {};
    // use this.indexMap.length (instead of this.data.length)
    // because this.indexMap will be smaller after applied filters
    times(this.indexMap.length, (index) => {
      let id = this.data[this.indexMap[index]].id;

      if (!id || this.idToIndex[id]) {
        console.warn(
          `Unexpected id: ${id}. SortedDataList requires each record to have an unique 'id'.`,
        );

        id = `${id}_${index}`;
      }

      this.idToIndex[id] = index;
    });
  }

  /**
   * @public
   * Returns the size of this ordered data list.
   *
   * @return {Number}
   */
  public getSize = (): number => this.indexMap.length;

  /**
   * @public
   * Returns the stored object at index.
   *
   * @param {Number} index the specified index.
   *
   * @return {T}
   */
  public getObjectAt = (index: number): T => this.data[this.indexMap[index]];

  /**
   * @public
   * Returns the stored raw object at index
   *
   * @param {Number} index the specified index.
   *
   * @return {T}
   */
  public getRawObjectAt = (index: number): TRawDataType => {
    const obj = this.getObjectAt(index);

    return !obj?._raw ? {} : obj._raw;
  };

  /**
   * @public
   * Returns 'maxCount' of stored objects starting from 'fromIndex'.
   *
   * @param {Number} fromIndex the starting index.
   * @param {Number} maxCount max number of records to return.
   *
   * @return {T[]}
   */
  public getObjects = (fromIndex: number = 0, maxCount?: number): T[] => {
    const result: T[] = [];

    const maxIndex = isNumber(maxCount)
      ? Math.min(this.getSize() - 1, fromIndex + maxCount - 1)
      : this.getSize() - 1;
    for (let index = fromIndex; index <= maxIndex; index++) {
      result.push(this.getObjectAt(index));
    }

    return result;
  };

  /**
   * @public
   * Returns all ids. (from index map)
   *
   * @return {string[]}
   */
  public getAllIds = (): string[] => keys(this.idToIndex);

  /**
   * @public
   * Returns a list of raw data by indices.
   *
   * @param {Number[]} indices the selected indices.
   *
   * @return {TRawDataType[]}
   */
  public getSelectedData = (indices: number[]): TRawDataType[] => {
    const sortedIndices = clone(indices).sort();
    const data = [];

    for (const index of sortedIndices) {
      const raw = this.getRawObjectAt(index);
      if (!isEmpty(raw)) {
        data.push(raw);
      }
    }

    return data;
  };

  /**
   * @public
   * Returns a list of raw data by ids.
   *
   * @param {string[]} ids the selected ids.
   *
   * @return {TRawDataType[]}
   */
  public getSelectedDataByIds = (ids: readonly string[]): TRawDataType[] => {
    const data = [];

    for (const id of ids) {
      const raw = this.getRawObjectAt(this.idToIndex[id]);
      if (!isEmpty(raw)) {
        data.push(raw);
      }
    }

    return data;
  };
}
