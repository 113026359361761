import gql from 'graphql-tag';

export const GET_PREDEFINED_SEGMENTS_FOR_PROGRAM = gql`
  query PredefinedSegmentsForProgramQuery($programId: Int, $defaultColumnsOnly: Boolean) {
    segments: predefinedSegmentsForProgram(programId: $programId, defaultColumnsOnly: $defaultColumnsOnly) {
      id
      title
      description
      folderId
      filter
      columns {
        memberFieldSchemaIds
        dbColumns
        projectColumns
        order {
          memberFieldSchemaId
          dbColumn
          projectColumn
        }
      }
      memberCount
    }
  }
`;
