/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ubc0_157 {
  display: flex;
}

._justify-content-space-between_1ubc0_161 {
  justify-content: space-between;
}

._tabular-nums_1ubc0_165 {
  font-variant-numeric: tabular-nums;
}

._AnalyzePage_1ubc0_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1ubc0_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._AnalyzePage_1ubc0_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._AnalyzePage_1ubc0_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._AnalyzePage_1ubc0_169 ::-webkit-scrollbar-track, ._AnalyzePage_1ubc0_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._AnalyzePage_1ubc0_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 ._navItem_1ubc0_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._AnalyzePage_1ubc0_169 {
  width: 100%;
}
._AnalyzePage_1ubc0_169 ._noDataNotice_1ubc0_248 {
  max-width: 21.875rem;
  margin-left: auto;
  margin-right: auto;
  top: 45%;
}
._AnalyzePage_1ubc0_169 ._alignContent_1ubc0_254 {
  padding-left: 1rem; /* aligns with the "Compare Data Sets" button text */
}
._AnalyzePage_1ubc0_169 ._headerWrapper_1ubc0_257 {
  box-shadow: 0 0.75rem 0.75rem rgba(46, 66, 109, 0.08);
  background: #fdfdfd;
  border-radius: 0 0 0.5rem 0.5rem;
  padding-top: 1.25rem;
}
._AnalyzePage_1ubc0_169 ._headerWrapper_1ubc0_257._sticky_1ubc0_263 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
._AnalyzePage_1ubc0_169 ._header_1ubc0_204 {
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dadcde;
  padding: 0 1.25rem;
}
._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 ._navItem_1ubc0_204 {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  will-change: color;
  transition: color 0.1s ease-out;
}
._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 ._navItem_1ubc0_204:after {
  position: absolute;
  padding-top: 12px;
  content: "";
  display: block;
  width: 100%;
  border-bottom: 2px solid #3996e0; /* This creates the border. Replace black with whatever color you want. */
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.15s ease-out;
}
._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 ._navItem_1ubc0_204:hover, ._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 ._navItem_1ubc0_204._active_1ubc0_303 {
  color: #3996e0;
}
._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 ._navItem_1ubc0_204:hover:after, ._AnalyzePage_1ubc0_169 ._header_1ubc0_204 ._navbar_1ubc0_204 ._navItem_1ubc0_204._active_1ubc0_303:after {
  transform: scaleX(1);
}
._AnalyzePage_1ubc0_169 ._children_1ubc0_309 {
  padding: 60px 0px;
}