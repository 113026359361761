import * as React from 'react';

import { Button, Notice } from '@affiliates/AspireUI';
import { OFFER_TYPE } from '../../types/globalTypes';

import styles from './SetupNotice.scss';

interface IProps {
  programId: number;
  offerType: OFFER_TYPE;
  onClick: () => void;
}

export const SetupNotice: React.FC<Readonly<IProps>> = (props) => {
  const {
    offerType,
    onClick,
  } = props;

  const renderMessage = () => {
    const offerVerbiage = offerType === OFFER_TYPE.LINK ? 'links' : 'promo codes';
    const description = offerType === OFFER_TYPE.LINK
      ? (
        <>
          Once you set up or choose an offer, make sure to add the tracking snippet
          to the conversion page on your website, and test a sale. Then you can
          send out links for these people.
        </>
)
      : (
        <>
          Once you set up your offer you can create and send these people their
          unique promo codes.
        </>
);

    return (
      <div>
        <p className={styles.title}>
          You need to set up or choose your
          {' '}
          {offerVerbiage}
          {' '}
          offer
        </p>
        <p className={styles.description}>{description}</p>
      </div>
    );
  };

  const renderActions = () => (
    <div className={styles.actionsWrapper}>
      <Button
        className={styles.button}
        onClick={onClick}
        title="Set Up Offer"
        type="primary"
      >
        Set Up Offer
      </Button>
    </div>
    );

  return (
    <Notice
      className={styles.SetupNotice}
      type="alert"
      message={renderMessage()}
      actions={renderActions()}
    />
  );
};
