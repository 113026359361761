import React from 'react';
import {
 Collapse, Checkbox, Typography, Table, Tag,
} from '@revfluence/fresh';
import {
  IAdditionalDeeplinkGroupedMember,
  TMember,
} from '@frontend/applications/AffiliatesApp/components/MembersWizard/types';
import { keys, map } from 'lodash';
import { randomiseColorsForTags } from '@frontend/applications/AffiliatesApp/utils';
import styles from '../../../ManageOffer.scss';

const { Panel } = Collapse;
const { Text } = Typography;

interface TableViewProps {
  selectedMembers?: readonly TMember[];
  additionalDeeplinkGroupedMembers: IAdditionalDeeplinkGroupedMember;
  setSelectedLabels: (val) => void;
}

const TableView: React.FC<TableViewProps> = ({
  selectedMembers,
  additionalDeeplinkGroupedMembers,
  setSelectedLabels,
}) => {
  const onHandleDefaultClick = (event) => {
    if (event.target.checked) {
      setSelectedLabels((labels) => ['Primary Link', ...labels]);
    } else {
      setSelectedLabels((labels) => {
        const labelSet = new Set(labels);
        labelSet.delete('Primary Link');
        return Array.from(labelSet).sort();
      });
    }
  };
  const columns = [
    {
      title: 'Members',
      dataIndex: 'name',
      key: 'name',
    },
  ];
  const columnsForDeeplinkCard = [
    {
      title: 'Members',
      dataIndex: 'memberName',
      key: 'name',
    },
  ];
  // keep check if default is selected or not.
  return (
    <>
      {/** Default deeplink */}
      <Collapse defaultActiveKey={['0']} expandIconPosition="left" className={styles.collapseBg}>
        <Panel
          header={(
            <div className="flex justify-between items-center">
              <Checkbox onChange={onHandleDefaultClick}>
                <Tag size="small" color={randomiseColorsForTags('Primary Link')}>
                  Primary Link
                </Tag>
              </Checkbox>
              <Text>
                {selectedMembers?.length ?? 0}
                {' '}
                Members
              </Text>
            </div>
          )}
          key="default"
          className={styles.collapseBg}
        >
          <Table
            dataSource={selectedMembers}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.id.toString()}
            bordered={false}
          />
        </Panel>
      </Collapse>
      {/** Additional deeplinks */}
      <>
        {map(keys(additionalDeeplinkGroupedMembers), (label) => {
          const selectedMembers = additionalDeeplinkGroupedMembers[label];
          const onHandleCheck = (event) => {
            setSelectedLabels((selectedLabels) => {
              const uniqueLabels = new Set(selectedLabels);
              if (event.target.checked) {
                if (uniqueLabels.has(label)) {
                  return selectedLabels;
                }
                uniqueLabels.add(label);
              } else {
                uniqueLabels.delete(label);
              }
              return Array.from(uniqueLabels).sort();
            });
          };
          return (
            <Collapse defaultActiveKey={['0']} expandIconPosition="left" className={styles.collapseBg}>
              <Panel
                header={(
                  <div className="flex justify-between items-center">
                    <Checkbox key={label} onChange={onHandleCheck}>
                      <Tag size="small" color={randomiseColorsForTags(label)}>
                        {label}
                      </Tag>
                    </Checkbox>
                    <Text>
                      {selectedMembers?.length ?? 0}
                      {' '}
                      Members
                    </Text>
                  </div>
                )}
                key={label}
                className={styles.collapseBg}
              >
                <Table
                  dataSource={selectedMembers}
                  columns={columnsForDeeplinkCard}
                  pagination={false}
                  rowKey={(record) => record.memberId.toString()}
                  bordered={false}
                />
              </Panel>
            </Collapse>
          );
        })}
      </>
    </>
  );
};

export default TableView;
