import * as React from 'react';
import cx from 'classnames';
import {
 concat, keys, map, size,
} from 'lodash';

import { UploadCloudIcon } from '@components';
import { Tooltip } from '@components';
import { Tooltip as FreshTooltip } from '@revfluence/fresh';

import { RAW_IMAGE_EXTENSIONS } from '@components';
import { TContentType } from './redux/contentUploaderModel';

import styles from './FileSelector.scss';

const {
 useCallback, useImperativeHandle, useMemo, useRef, useState,
} = React;

export interface IFileSelectorProps {
  accept?: string;
  acceptTypes?: TContentType[];
  className?: string;
  disabled?: boolean;
  fileSelectorRef?: React.RefObject<IFileSelectorRefs>;
  inputRef?: React.RefObject<HTMLInputElement>;
  multiple?: boolean;
  onFilesSelected(files: FileList);
  unstyled?: boolean;
  fileBytesLimit?: number;
}

export interface IFileSelectorRefs {
  openFileSelector();
}

type TProps = React.PropsWithChildren<IFileSelectorProps>;

export const FileSelector = React.forwardRef<HTMLDivElement, TProps>((props, forwardedRef) => {
  const {
    accept,
    acceptTypes = [],
    children,
    className,
    disabled,
    fileSelectorRef,
    inputRef: inputRefProp,
    multiple = false,
    onFilesSelected,
    unstyled = false,
    fileBytesLimit = Infinity,
  } = props;
  const [fileSizeReached, setFileSizeReached] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>();
  useImperativeHandle(forwardedRef, () => ref.current);

  const inputRef = useRef<HTMLInputElement>();
  useImperativeHandle(inputRefProp, () => inputRef.current);

  useImperativeHandle(fileSelectorRef, () => ({
    openFileSelector,
  }));

  const acceptString = useMemo(() => concat(
    accept,
    // also adds raw image extensions if image is accepted
    ...(acceptTypes.includes('image')
      ? map(keys(RAW_IMAGE_EXTENSIONS), (ext) => `.${ext}`)
      : []),
    ...acceptTypes.map((acceptType) => `${acceptType}/*`),
  ).join(', '), [accept, acceptTypes]);

  const openFileSelector = useCallback(() => {
    const fileInput = inputRef.current;

    // do not open file selector if disabled
    if (disabled) {
      return;
    }

    // trigger a click
    if (fileInput) {
      fileInput.click();
    }
  }, [disabled]);

  const handleFileSelection = useCallback((files) => {
    let deselectFile = false;

    if (size(files) > 0) {
      Array.from(files).forEach((file: File) => {
        if (file.size >= fileBytesLimit) {
          deselectFile = true;
          setFileSizeReached(true);
        }
      });
      if (!deselectFile) {
        onFilesSelected(files);
      }
    }
  }, [onFilesSelected, fileBytesLimit]);

  const handleSelectedFilesChange = useCallback((event) => {
    const { files } = event.target;
    handleFileSelection(files);
    event.target.value = '';
  }, [handleFileSelection]);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer.files;
    handleFileSelection(files);
  }, [handleFileSelection]);

  return (
    <>
      <div
        ref={ref}
        className={cx(
        className,
        {
          [styles.disabled]: disabled,
          [styles.ContentUploaderFileSelector]: !unstyled,
        },
      )}
        onDrop={handleDrop}
        onClick={openFileSelector}
      >
        <input
          type="file"
          ref={inputRef}
          className={styles.hideInput}
          onChange={handleSelectedFilesChange}
          multiple={multiple}
          accept={acceptString}
        />
        {children || (
        !unstyled && (
          <div className={styles.label}>
            <UploadCloudIcon />
            <div className={styles.text}>
              <div>Upload a File</div>
              <div className={styles.supportedFile}>(Drag and Drop)</div>
            </div>
          </div>
        )
      )}
      </div>
      {disabled && (
        <Tooltip placement="top" mountRef={ref}>
          Maximum number of items reached.
        </Tooltip>
      )}
      {fileSizeReached && (
        <FreshTooltip placement="top">
          <span className={styles.invalid}>Maximum file size reached.</span>
        </FreshTooltip>
      )}

    </>
);
});

FileSelector.displayName = 'FileSelector';
