import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty } from 'lodash';

import { Popover, Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { logger } from '@common';
import {
  IconButton,
} from '@components';
import {
  NewActivationModal, LightningIcon, EmptyNotice,
} from '@frontend/app/components';
import {
  IActivation,
  useActivationsQuery,
  useDeleteActivation,
} from '@frontend/app/hooks';
import { ActivationItem } from './ActivationItem';

import styles from './ActivationPopover.scss';

const { useState, useCallback } = React;

interface IProps {
  className?: string;
}

/**
 * @type {React.FC}
 */
export const ActivationPopover: React.FC<IProps> = React.memo((props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [showNewActivationForm, setShowNewActivationForm] = useState(false);
  const [selectedActivation, setSelectedActivation] = useState<IActivation>(null);
  const {
    activations,
    refetch,
  } = useActivationsQuery();
  const [deleteActivation] = useDeleteActivation();

  const handleCloseModal = useCallback(() => {
    setShowNewActivationForm(false);
  }, []);

  const handleClickAddNewActivation = useCallback(() => {
    setSelectedActivation(null);
    setShowNewActivationForm(true);
  }, []);

  const handleEditClick = useCallback((activation: IActivation) => {
    setSelectedActivation(activation);
    setShowNewActivationForm(true);
  }, []);

  const handleDeleteClick = (activation: IActivation) => {
    deleteActivation({
      variables: {
        id: activation.id,
      },
    }).then(refetch).catch(logger.error);
  };

  const PopoverContent = (
    <div className={styles.content}>
      <div className={styles.header}>
        Activations
        <IconButton
          icon={<PlusCircleOutlined />}
          className={styles.addIcon}
          onMouseDown={handleClickAddNewActivation}
        />
      </div>
      {isEmpty(activations) && (
        <EmptyNotice className={styles.emptyNotice}>
          You have no activations
        </EmptyNotice>
      )}
      {!isEmpty(activations) && (
        <div className={styles.list}>
          {map(activations, (activation) => (
            <ActivationItem
              key={activation.id}
              activation={activation}
              onClick={() => setVisible(false)}
              onEditClick={handleEditClick.bind(this, activation)}
              onDeleteClick={handleDeleteClick.bind(this, activation)}
            />
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className={cx(styles.ActivationPopover, props.className)}>
      <Popover
        content={PopoverContent}
        overlayClassName={styles.PopoverOverlay}
        trigger="click"
        placement="rightBottom"
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Tooltip
          overlayStyle={{
            zIndex: 9999,
          }}
          placement="right"
          title="Activations"
        >
          <IconButton className={styles.icon} icon={<LightningIcon />} />
        </Tooltip>
      </Popover>
      <NewActivationModal
        show={showNewActivationForm}
        activation={selectedActivation}
        onRequestClose={handleCloseModal}
        onActivationSaved={refetch}
      />
    </div>
  );
});

ActivationPopover.displayName = 'ActivationPopover';
