/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_17o1o_157 {
  display: flex;
}

._justify-content-space-between_17o1o_161 {
  justify-content: space-between;
}

._tabular-nums_17o1o_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_17o1o_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._quarterContainer_17o1o_178 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border: 0.0625rem solid;
  border-radius: 0.625rem;
}

._budgetCell_17o1o_186 {
  border-radius: 0.625rem;
  padding: 0.75rem;
  padding-top: 0.125rem;
  height: 3.25rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
._budgetCell_17o1o_186 .ant-typography {
  font-size: 0.75rem;
  font-weight: 400;
  color: #8c8c8c;
}

._budgetBreakdown_17o1o_201 {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

._quarterlyBreakdown_17o1o_208 {
  display: flex;
  gap: 1rem;
}

._quarterlyBreakdownContainer_17o1o_213 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

._budgetInput_17o1o_219 {
  background-color: transparent;
  border: none;
  border-radius: unset;
  padding: 0;
}
._budgetInput_17o1o_219 .ant-input {
  background-color: transparent;
  border: none;
  border-radius: unset;
}

._budgetBreakdownCollapse_17o1o_231 {
  color: #1F1F21 !important;
  font-size: 0.875rem !important;
}
._budgetBreakdownCollapse_17o1o_231 .ant-collapse-header {
  padding-left: 0 !important;
  font-weight: 400 !important;
}
._budgetBreakdownCollapse_17o1o_231 .ant-collapse-content-box {
  padding-left: 0 !important;
}

._remainingAmountContainer_17o1o_243 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

._remainingAmountToDivideText_17o1o_249 {
  font-size: 0.75rem;
  font-weight: 400;
  color: #8C8C8C;
}

._remainingAmountToDivide_17o1o_249 {
  font-size: 0.75rem;
  font-weight: 600;
  color: #8C8C8C;
}