import * as React from 'react';
import cx from 'classnames';
import {
  isNil,
  isFunction,
  isNumber,
} from 'lodash';
import {
  IconButton,
  CheckIcon,
  CloseIcon,
  TableCellEditInput,
} from '@components';
import { UserAvatar } from '@frontend/app/components';
import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';

import { Typography } from '@revfluence/fresh';
import styles from './MemberNameCell.scss';

const { useState, useEffect } = React;

interface IProps {
  name: string;
  photo?: string;
  selected: boolean;
  isApplicant: boolean;
  editable: boolean;
  isEditing: boolean;
  id?: number;
  onToggleIsEditing();
  onChangeName(name: string);
  onSelect();
  onMemberApplicantOperation(operation: MemberApplicantOperation);
  onClickName?(id: number);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isEmptyValue = (value: any) => isNil(value) || value === '';

const { Text } = Typography;

export const MemberNameCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const [nameState, setNameState] = useState<string>(props.name);

  useEffect(() => {
    setNameState(props.name);
  }, [props.name]);

  const handleNameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (props.editable) {
      e.preventDefault();
      e.stopPropagation();
      props.onToggleIsEditing();
    } else if (isFunction(props.onClickName) && isNumber(props.id)) {
      e.preventDefault();
      e.stopPropagation();
      props.onClickName(props.id);
    }
  };

  const handleBlur = (newName: string) => {
    setNameState(newName);
    props.onToggleIsEditing();

    if (newName === nameState) {
      return;
    }

    if (isEmptyValue(newName) && isEmptyValue(nameState)) {
      return;
    }

    props.onChangeName(newName);
  };

  return (
    <div
      className={cx(
        styles.MemberNameCell,
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [(styles as any).selected]: props.selected,
        },
      )}
    >
      <div
        className={styles.avatarWrapper}
        onClick={handleNameClick}
      >
        <UserAvatar
          name={props.name}
          profilePicture={props.photo}
          className={styles.avatar}
        />
      </div>
      {props.isEditing && (
        <div className={styles.name}>
          <TableCellEditInput defaultValue={nameState} onBlur={handleBlur} onCancel={props.onToggleIsEditing} />
        </div>
      )}
      {!props.isEditing && (
        <Text
          className={cx(styles.name, { [styles.editable]: props.editable })}
          onClick={handleNameClick}
          ellipsis={{ tooltip: nameState }}
        >
          {nameState}
        </Text>
      )}
      {props.isApplicant && (
        <div className={styles.applicant}>
          <IconButton
            icon={<CheckIcon size={18} />}
            theme="primary"
            className={styles.checkBtn}
            onClick={props.onMemberApplicantOperation.bind(this, MemberApplicantOperation.Approve)}
          />
          <IconButton
            icon={<CloseIcon size={12} />}
            theme="light"
            className={styles.closeBtn}
            onClick={props.onMemberApplicantOperation.bind(this, MemberApplicantOperation.Reject)}
          />
        </div>
      )}
    </div>
  );
});

MemberNameCell.displayName = 'MemberNameCell';
