import { useMutation, QueryHookOptions } from '@apollo/client';

import { DELETE_ACTIVATION_MUTATION } from '@frontend/app/queries';
import {
  DeleteActivationMutation, DeleteActivationMutationVariables,
} from '@frontend/app/queries/types/DeleteActivationMutation';

type IOptions = QueryHookOptions<DeleteActivationMutation, DeleteActivationMutationVariables>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDeleteActivation = (options: IOptions = {}) => useMutation<DeleteActivationMutation, DeleteActivationMutationVariables>(DELETE_ACTIVATION_MUTATION, options as any);
