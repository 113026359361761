import * as React from 'react';
import { UpdateProjectConfigInput as ProjectConfig } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { Typography } from '@revfluence/fresh';
import { useState } from 'react';
import ProjectConfigSetup from '../../components/ProjectConfigSetup/ProjectConfigSetup';
import { ProductOrderForm } from '../../components/ProductOrderForm/ProductOrderForm';
import styles from './ProjectConfigSetupContainer.styles.scss';

const { Title, Paragraph } = Typography;
interface IProps {
  projectConfig: Partial<ProjectConfig>;
  onChange: (projectConfig: ProjectConfig) => void;
  hasConfig: boolean;
  programName;
  setIsSaveFormEnabled: (value: boolean) => void;
}
export const ProjectConfigSetupContainer = (props: IProps) => {
  const {
    projectConfig,
    onChange,
    hasConfig,
    programName,
    setIsSaveFormEnabled,
  } = props;
  const [isTouched, setIsTouched] = useState(false);

  const handleProjectConfigChange = (changedFields, _allFields) => {
    const newProjectConfig: ProjectConfig = {
      ...(projectConfig || {
        noCatalogueConfig: {
          formFields: [],
        },
      }),
    };
    const optionalFields = ['productSKU', 'productPreferences', 'productURL'];
    changedFields.forEach((item) => {
      const [fieldName] = item.name;
      if (optionalFields.includes(fieldName)) {
        const fieldNameIndex = newProjectConfig.noCatalogueConfig.formFields.findIndex((field) => field.key === fieldName);
        if (fieldNameIndex !== -1) {
          newProjectConfig.noCatalogueConfig.formFields[fieldNameIndex].enabled = !projectConfig.noCatalogueConfig.formFields[fieldNameIndex].enabled;
        }
      }
      if (fieldName.includes('Require')) {
        const index = newProjectConfig.noCatalogueConfig.formFields.findIndex((field) => fieldName.includes(field.key));
        if (index !== -1) {
          newProjectConfig.noCatalogueConfig.formFields[index].required = !projectConfig.noCatalogueConfig.formFields[index].required;
        }
      }
      if (fieldName.includes('Label')) {
        const index = newProjectConfig.noCatalogueConfig.formFields.findIndex((field) => fieldName.includes(field.key));
        newProjectConfig.noCatalogueConfig.formFields[index].label = item.value;
        if (index !== -1) {
          newProjectConfig.noCatalogueConfig.formFields[index].label = item.value;
        }
      }
      if (fieldName === 'quantityMax') {
        newProjectConfig.quantityMax = item.value;
      }
      if (fieldName === 'instructionText') {
        newProjectConfig.instructionText = item.value;
      }
    });
    onChange(newProjectConfig);
    setIsTouched(true);
  };

  return (
    <div className={styles.ProjectConfigSetupContainer}>
      <Title level={4}>{`${programName} Product Request Form`}</Title>
      <Paragraph>
        This form will be displayed to anyone you invite participate in this collaboration. If everything looks good,
        please finish by saving the form.
      </Paragraph>
      <div className={styles.content}>
        <div className={styles.setup}>
          <ProjectConfigSetup formFields={projectConfig?.noCatalogueConfig?.formFields} projectConfig={projectConfig} onChange={handleProjectConfigChange} setIsSaveFormEnabled={setIsSaveFormEnabled} />
        </div>
        {
          (!isTouched && !hasConfig)
          && <img src="https://storage.googleapis.com/aspirex-static-files/PFA-Preview.gif" alt="Loading" /> // Added a loading gif
        }
        {(hasConfig || isTouched)
          && (
            <div className={styles.preview}>
              <ProductOrderForm formFields={projectConfig?.noCatalogueConfig?.formFields} instructionText={projectConfig?.instructionText} />
            </div>
          )}
      </div>
    </div>
  );
};

export default ProjectConfigSetupContainer;
