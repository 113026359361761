import * as React from 'react';

import { ITableCellType } from '../../tableContext';
import { DateEditInput, IProps as IDateProps } from './DateEditInput';
import { StringEditInput, IProps as IStringProps } from './StringEditInput';
import { NumberEditInput, IProps as INumberProps } from './NumberEditInput';
import { BooleanEditInput, IProps as IBooleanProps } from './BooleanEditInput';

type IProps = (
  IDateProps |
  INumberProps |
  IStringProps |
  IBooleanProps
) & {
  cellType?: ITableCellType;
  onCancel?();
};

export const EditInput: React.FC<IProps> = (props) => {
  switch (props.cellType) {
    case 'date':
      return <DateEditInput {...props as IDateProps} />;

    case 'numeric':
      return <NumberEditInput {...props as INumberProps} />;

    case 'boolean':
      return <BooleanEditInput {...props as IBooleanProps} />;

    default:
      return <StringEditInput {...props as IStringProps} />;
  }
};

EditInput.displayName = 'EditInput';
