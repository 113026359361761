/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1770f_157 {
  display: flex;
}

._justify-content-space-between_1770f_161 {
  justify-content: space-between;
}

._tabular-nums_1770f_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1770f_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._fiscalYearSettings_1770f_178 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

._iconContainer_1770f_189 {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #5DB884;
}

._icon_1770f_189 {
  color: #FFFFFF;
  height: 1.25rem;
  width: 1.25rem;
}