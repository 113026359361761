import 'css-chunk:src/app/containers/MessagingApp/ThreadFolderSection/ThreadFolderSection.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1kjkq_157",
  "justify-content-space-between": "_justify-content-space-between_1kjkq_161",
  "tabular-nums": "_tabular-nums_1kjkq_165",
  "ThreadFolderSection": "_ThreadFolderSection_1kjkq_178",
  "threadFilter": "_threadFilter_1kjkq_178",
  "errorNotice": "_errorNotice_1kjkq_178",
  "actions": "_actions_1kjkq_178",
  "actionButton": "_actionButton_1kjkq_178",
  "content": "_content_1kjkq_178",
  "errorText": "_errorText_1kjkq_178",
  "errorTitle": "_errorTitle_1kjkq_178",
  "dropdownWrapper": "_dropdownWrapper_1kjkq_228",
  "actionsWrapper": "_actionsWrapper_1kjkq_233",
  "toggleWrapper": "_toggleWrapper_1kjkq_241",
  "toggleWrapperTitle": "_toggleWrapperTitle_1kjkq_246",
  "composerWrapper": "_composerWrapper_1kjkq_251",
  "messageComposer": "_messageComposer_1kjkq_251",
  "filterItem": "_filterItem_1kjkq_264",
  "iconWrapper": "_iconWrapper_1kjkq_273",
  "avatar": "_avatar_1kjkq_277",
  "label": "_label_1kjkq_280",
  "count": "_count_1kjkq_286",
  "active": "_active_1kjkq_298",
  "secondary": "_secondary_1kjkq_301",
  "reconnect": "_reconnect_1kjkq_350",
  "expanded": "_expanded_1kjkq_353",
  "folderToggleIcon": "_folderToggleIcon_1kjkq_373",
  "show": "_show_1kjkq_1"
};