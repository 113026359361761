import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_VARIABLES_QUERY } from '@frontend/app/queries';
import {
  GetVariablesQuery,
  GetVariablesQuery_variables,
} from '@frontend/app/queries/types/GetVariablesQuery';

export type IMessageVariable = GetVariablesQuery_variables;
type IOptions = QueryHookOptions<GetVariablesQuery>;

export function useGetVariables(options: IOptions = {}) {
  const {
 loading, data, error, refetch,
} = useQuery(
    GET_VARIABLES_QUERY,
    options,
  );

  return {
    loading,
    variables: data?.variables,
    error,
    refetch,
  };
}
