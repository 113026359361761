import * as React from 'react';

import { Tooltip } from '@revfluence/fresh';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
}

export const NotifyMemberButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    className,
    onClick,
    disabled,
  } = props;

  const disabledStyle = {
    opacity: disabled ? '0.5' : '1',
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  return (
    <Tooltip placement="top" title="Notify Member">
      <div className={className} onClick={disabled ? undefined : onClick} style={disabled ? { ...disabledStyle, pointerEvents: 'none' } : undefined}>Compose Message</div>
    </Tooltip>
  );
});

NotifyMemberButton.defaultProps = {
  disabled: false,
};

NotifyMemberButton.displayName = 'NotifyMemberButton';
