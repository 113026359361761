._EllipsisLabel_a07me_1 {
  min-width: 0;
}
._EllipsisLabel_a07me_1 > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._EllipsisLabel_a07me_1 > div > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}