import { useMutation, MutationHookOptions } from '@apollo/client';

import { REJECT_RAW_CONTENT_REVIEW_MUTATION } from '@frontend/app/queries';

import {
  RejectRawContentReviewMutation,
  RejectRawContentReviewMutationVariables,
} from '@frontend/app/queries/types/RejectRawContentReviewMutation';

type IOptions = MutationHookOptions<RejectRawContentReviewMutation, RejectRawContentReviewMutationVariables>;

export function useRejectRawContentReview(options: IOptions = {}) {
  const [rejectRawReview, { loading, error }] = useMutation<
    RejectRawContentReviewMutation,
    RejectRawContentReviewMutationVariables
  >(REJECT_RAW_CONTENT_REVIEW_MUTATION, {
    ...options,
  });

  return {
    rejectRawReview,
    loading,
    error,
  };
}
