import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { lazyLoadComponent } from '@frontend/app/utils/lazyLoadComponent';

// Lazy loading the components
const BudgetReportingPage = lazy(() => import('./components/budgetReportingPage'));
const MasterBudgetSetup = lazy(() => import('./components/masterBudgetSetup'));
const EditBudget = lazy(() => import('./components/editBudget'));
const CreateBudget = lazy(() => import('./components/createBudget'));
const FiscalYearSettings = lazy(() => import('./components/fiscalYearSettings'));

const BudgetReportingContainer: React.FunctionComponent = () => {
  const { path: routePath } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${routePath}/dashboard`} component={lazyLoadComponent(BudgetReportingPage)} />
      <Route path={`${routePath}/masterBudgetSetup`} component={lazyLoadComponent(MasterBudgetSetup)} />
      <Route path={`${routePath}/editBudget/:id`} component={lazyLoadComponent(EditBudget)} />
      <Route path={`${routePath}/createBudget`} component={lazyLoadComponent(CreateBudget)} />
      <Route path={`${routePath}/fiscalYearSettings`} component={lazyLoadComponent(FiscalYearSettings)} />
    </Switch>
  );
};

export default BudgetReportingContainer;
