import * as React from 'react';
import { Tooltip, Typography } from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './SubItemSmall.scss';

const { Text } = Typography;

interface IProps {
  name: string;
  value: string | React.ReactNode;
  tooltipMessage?: string;
}

export const SubItemSmall: React.FC<IProps> = (props) => {
  const {
    name,
    value,
    tooltipMessage,
  } = props;

  return (
    <div className={styles.subItemSmallContainer}>
      <Text className={styles.subItemSmallKey}>
        {name}
        {' '}
        {
        tooltipMessage && <Tooltip title={tooltipMessage}><CircleInfoIcon /></Tooltip>
      }
      </Text>
      {typeof value === 'string' ? <Text>{value}</Text> : value}
    </div>
  );
};
