import gql from 'graphql-tag';

// write mutation for creating order limitation
export const CREATE_CATALOGUE_CONFIG_MUTATION = gql`
mutation CreateCatalogueConfigMutation($catalogueConfig: CreateCatalogueConfigInput!) {
    createCatalogueConfig(catalogueConfig: $catalogueConfig) {
      id
      clientId
      projectId
      instructionText
      productSubtitle
      productSubtitleMetafields
      displayPrice
    }
  }
  `;

export const UPDATE_CATALOGUE_CONFIG_MUTATION = gql`
mutation UpdateCatalogueConfigMutation($id: String!, $updates: CreateCatalogueConfigInput!) {
    updateCatalogueConfig(id: $id, updates: $updates) {
      id
      clientId
      projectId
      instructionText
      productSubtitle
      productSubtitleMetafields
      displayPrice
    }
  }
  `;

export const GET_CATALOGUE_CONFIG_QUERY = gql`
  query GetCatalogueConfigByProjectId($projectId: Float!) {
    catalogueConfig: getCatalogueConfigByProjectId(projectId: $projectId) {
        id
        projectId
        instructionText
        productSubtitle
        productSubtitleMetafields
        displayPrice
    }
  }
`;
