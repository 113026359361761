import * as React from 'react';
import { useRenderSocialLinkIcon } from '@frontend/app/containers/Projects/LandingPages/hooks';

import { getNetworkUrl, socialLinkFields } from '../../utils/socialLinks';

import styles from './SocialLinks.scss';

interface IProps {
  brand_instagram: string;
  brand_pinterest: string;
  brand_tiktok: string;
  brand_website: string;
  brand_youtube: string;
}

export const SocialLinks: React.FC<IProps> = React.memo((props) => {
  const {
    brand_instagram,
    brand_pinterest,
    brand_tiktok,
    brand_website,
    brand_youtube,
  } = props;

  const renderIcon = useRenderSocialLinkIcon();

  if (!brand_instagram && !brand_pinterest && !brand_tiktok && !brand_website && !brand_youtube) {
    return null;
  }

  return (
    <div className={styles.SocialLinks}>
      {socialLinkFields.map((field) => (
        props[field.name]
          ? (
            <a
              key={field.name}
              target="_blank"
              href={getNetworkUrl(props[field.name], field.name)}
            >
              {
                renderIcon(
                  field.name,
                  18,
                  '#1f1f21',
                )
              }
            </a>
          )
          : null
        ))}
    </div>
  );
});

SocialLinks.displayName = 'QuickTemplateSocialLinks';
