import * as React from 'react';
import { DatePicker } from '@components';

const { useMemo } = React;

interface IProps {
  value?: Date;
  errorMessage?: string;
  hasError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?(value: any);
  className?: string;
}

export const SimpleDateField: React.FC<IProps> = (props) => {
  const classNames = useMemo(() => [props.className], [props.className]);

  return (
    <DatePicker
      defaultDate={props.value}
      onDateSelected={props.onChange}
      hasError={props.hasError}
      errorMessage={props.errorMessage}
      classNames={classNames}
    />
  );
};
