import * as React from 'react';
import {
  ArrowUpRightFromSquareIcon,
  CircleInfoIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import {
  TiktokIcon,
} from '@revfluence/fresh-icons/brands/esm';

import {
  Card as FreshCard,
  List,
  Button,
  Row,
  Empty,
  Tooltip,
} from '@revfluence/fresh';
import { LazyImage } from '@components';
import { useClientFeatureEnabled, useGetTikTokSparkAdsRecommendations } from '@frontend/app/hooks';

import { ClientFeature } from '@frontend/app/constants';
import { CardContent, CardHeader, Card as ShadCnCard } from '@frontend/shadcn/components/ui/card';
import { Empty as ShadCnEmpty } from '@frontend/app/refresh-components';
import Card from '../Card';
import {
  WidgetTikTokSparkAdsRecommendationsLoading as LoadingSkeleton,
} from './WidgetTikTokSparkAdsRecommendationsLoading';

import styles from './WidgetTikTokSparkAdsRecommendations.module.scss';
import TIkTokSparkAdsRecommendationMeta, { ITikTokSparkAdsRecommendation } from './TikTokSparkAdsRecommendationMeta';

interface IProps {
  programId: number;
}

const { useMemo } = React;

const recommendationLevelOrder = {
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1,
};

const tiktokAdsUrl = 'https://ads.tiktok.com';
const tiktokAdAccountAdsUrl = `${tiktokAdsUrl}/i18n/material/native`;

const WidgetTikTokSparkAdsRecommendations: React.FC<IProps> = React.memo((props) => {
  const { programId } = props;
  const { data: { getTikTokSparkAdsRecommendations } = {}, loading } = useGetTikTokSparkAdsRecommendations({
      variables: {
      programId,
    },
    skip: !programId,
    fetchPolicy: 'cache-and-network',
  });

  const recommendations = getTikTokSparkAdsRecommendations?.recommendations;
  const advertiserId = getTikTokSparkAdsRecommendations?.advertiserId;

  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  const renderRecommendations = useMemo(() => {
    if (!recommendations || recommendations?.length === 0) {
      if (isRefreshUIEnabled) {
        return (
          <ShadCnEmpty
            icon={TiktokIcon}
            description="Top Recommended Spark Ads will appear here."
          />
        );
      }

      return (
        <Empty
          image={<TiktokIcon color="#F0F0F0" />}
          size="small"
          style={{
            color: '#8C8C8C',
          }}
          description="Top Recommended Spark Ads will appear here."
        />
      );
    }

    const sortedRecommendations = [...recommendations].sort((a, b) => {
      // Compare the recommendation_level with custom order (descending)
      if (recommendationLevelOrder[a.recommendationLevel] !== recommendationLevelOrder[b.recommendationLevel]) {
        return recommendationLevelOrder[b.recommendationLevel] - recommendationLevelOrder[a.recommendationLevel];
      }

      // Then, compare the datetime_posted (descending)
      if (a.datetimePosted !== b.datetimePosted) {
        return new Date(b.datetimePosted).getTime() - new Date(a.datetimePosted).getTime();
      }

      // Then, compare the engagements (descending)
      if (Number(a.engagementRate) !== Number(b.engagementRate)) {
        return Number(b.engagementRate) - Number(a.engagementRate);
      }
      return 0;
    });

    const pagination = {
      total: sortedRecommendations.length,
      pageSize: 5,
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      itemRender: (_current, type, _) => {
        if (type === 'prev') {
          return <Button className={styles.ArrowButton} icon={<LeftOutlined />} />;
        }
        if (type === 'next') {
          return <Button className={styles.ArrowButton} icon={<RightOutlined />} />;
        }
        return null;
      },
      className: 'pagination',
    };

    return (
      <List
        bordered={false}
        dataSource={sortedRecommendations}
        pagination={pagination}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 5,
          xxl: 5,
        }}
        renderItem={(tiktokSparkAdsRecommendation: ITikTokSparkAdsRecommendation) => (
          <List.Item>
            <FreshCard
              title=""
              cover={(
                <div className={styles.nineSixteen}>
                  <a
                    href={tiktokSparkAdsRecommendation.link}
                    target="_blank"
                    className={styles.nineSixteenFill}
                  >
                    <LazyImage src={tiktokSparkAdsRecommendation.imageUrl} />
                  </a>
                </div>
              )}
              bodyStyle={{ padding: '5px 0 0 0' }}
              style={{ height: '50%' }}
              bordered={false}
            >
              <TIkTokSparkAdsRecommendationMeta tiktokSparkAdsRecommendation={tiktokSparkAdsRecommendation} />
            </FreshCard>
          </List.Item>
        )}
      />
    );
  }, [isRefreshUIEnabled, recommendations]);

  const tiktokAdManagerUrl = useMemo(() => {
    if (advertiserId) {
      return `${tiktokAdAccountAdsUrl}?aadvid=${advertiserId}`;
    }
    return tiktokAdsUrl;
  }, [advertiserId]);

  const renderActions = () => (
    recommendations?.length > 0 && (
      <Row
        justify="center"
        align="middle"
      >
        <a href={tiktokAdManagerUrl} target="_blank">
          <Button type="text">
            <ArrowUpRightFromSquareIcon />
            Open TikTok Ads Manager
          </Button>
        </a>
      </Row>
    )
  );

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (isRefreshUIEnabled) {
    return (
      <ShadCnCard>
        <CardHeader>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <TiktokIcon fontSize={16} className="text-icon-grey" />
              <span className="font-medium text-base">
                TikTok Spark Ads Recommendations
                <span className="text-xs whitespace-nowrap overflow-hidden text-ellipsis ml-2 text-subtitle">
                  Last 90 Days
                </span>
              </span>
            </div>
            <Tooltip
              title={(
                <>
                  These posts are recommended by TikTok for Spark Ad use.
                  {' '}
                  <a href="https://help.aspireiq.com/en/articles/9762523-beta-tiktok-spark-ads-recommendation" target="_blank">Learn More</a>
                </>
              )}
              placement="topRight"
            >
              <CircleInfoIcon fontSize={16} className="text-icon-grey" />
            </Tooltip>
          </div>
        </CardHeader>
        <CardContent className="pt-6 pb-0">
          <FreshCard
            className={styles.TikTokSparkAdsRecommendationsWidgetCard}
            headStyle={{
              fontSize: '12px',
              fontWeight: '600',
              borderBottom: '0',
            }}
            bodyStyle={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            bordered={false}
          >
            { renderRecommendations }
            { renderActions() }
          </FreshCard>
        </CardContent>
      </ShadCnCard>
    );
  }

  return (
    <Card
      title="TikTok Spark Ads Recommendations"
      subtitle="Last 90 Days"
      icon={<TiktokIcon />}
      bodyStyle={{ padding: '0' }}
      tooltip={(
        <>
          These posts are recommended by TikTok for Spark Ad use.
          {' '}
          <a href="https://help.aspireiq.com/en/articles/9762523-beta-tiktok-spark-ads-recommendation" target="_blank">Learn More</a>
        </>
      ) as unknown as string}
      tooltipPlacement="topRight"
    >
      <FreshCard
        className={styles.TikTokSparkAdsRecommendationsWidgetCard}
        headStyle={{
            fontSize: '12px',
            fontWeight: '600',
            borderBottom: '0',
          }}
        bodyStyle={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        bordered={false}
      >
        { renderRecommendations }
        { renderActions() }
      </FreshCard>
    </Card>
  );
});

export default WidgetTikTokSparkAdsRecommendations;
WidgetTikTokSparkAdsRecommendations.displayName = 'WidgetTikTokSparkAdsRecommendations';
