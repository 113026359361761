import { QueryHookOptions, useQuery } from '@apollo/client';

import { LIST_AUTOMATIONS_QUERY } from '@frontend/app/queries';
import {
  ListAutomations,
  ListAutomations_listAutomations_results,
  ListAutomationsVariables,
} from '@frontend/app/queries/types/ListAutomations';

export type TAutomation = ListAutomations_listAutomations_results;

type IOptions = QueryHookOptions<ListAutomations, ListAutomationsVariables>;

export const useListAutomationsQuery = (options: IOptions = {}) =>
  useQuery<ListAutomations, ListAutomationsVariables>(LIST_AUTOMATIONS_QUERY, options);
