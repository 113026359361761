import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const FileTypeDoc = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 516.375 516.375">
    <path d="M344.25,133.875h95.625L325.125,0v114.75C325.125,126.225,334.688,133.875,344.25,133.875z" />
    <path
      d="M306,114.75V0H114.75C93.712,0,76.5,17.212,76.5,38.25v439.875c0,21.037,17.212,38.25,38.25,38.25h286.875
      c21.037,0,38.25-17.213,38.25-38.25V153H344.25C323.213,153,306,135.788,306,114.75z M133.875,76.5H267.75v19.125H133.875V76.5z
       M133.875,133.875H229.5V153h-95.625V133.875z M133.875,248.625h191.25v19.125h-191.25V248.625z M133.875,363.375H306V382.5
      H133.875V363.375z M382.5,439.875H133.875V420.75H382.5V439.875z M382.5,325.125H133.875V306H382.5V325.125z M382.5,210.375
      H133.875V191.25H382.5V210.375z"
    />
  </SvgIcon>
));

FileTypeDoc.displayName = 'FileTypeDoc';

export { FileTypeDoc };
