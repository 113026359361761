/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_151he_157 {
  display: flex;
}

._justify-content-space-between_151he_161 {
  justify-content: space-between;
}

._tabular-nums_151he_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_151he_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._divider_151he_178 {
  margin: 0rem;
  padding: 0rem;
}

._payoutSummaryCardContainer_151he_183 ._payoutSummaryCardStats_151he_183 {
  margin-bottom: 1.5rem;
}
._payoutSummaryCardContainer_151he_183 ._payoutSummaryCardStats_151he_183 ._payoutItemContainer_151he_186 {
  border-top: 0.0625rem solid #D9D9D9;
  display: flex;
  flex-direction: column;
}
._payoutSummaryCardContainer_151he_183 ._payoutSummaryCardStats_151he_183 ._payoutItemContainer_151he_186 ._payoutItemTitle_151he_191 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding-top: 0.5rem;
}
._payoutSummaryCardContainer_151he_183 ._payoutSummaryCardStats_151he_183 ._payoutItemContainer_151he_186 ._payoutItemDescription_151he_198 {
  color: var(--Neutral-Color-Palette-gray-9, #1F1F21);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
}
._payoutSummaryCardContainer_151he_183 ._multiStoreConversionContainer_151he_204 ._collapseContainer_151he_204 {
  background-color: transparent;
  border: none;
  padding: 0px !important;
}
._payoutSummaryCardContainer_151he_183 ._multiStoreConversionContainer_151he_204 ._collapseContainer_151he_204 .ant-collapse-header {
  padding: 0 !important;
}
._payoutSummaryCardContainer_151he_183 ._multiStoreConversionContainer_151he_204 ._collapseContainer_151he_204 .ant-collapse-content-box {
  padding: 0 !important;
}
._payoutSummaryCardContainer_151he_183 ._multiStoreConversionContainer_151he_204 ._collapseContainer_151he_204 ._ant-collapse-content-box_151he_212 {
  padding: 0 !important;
}
._payoutSummaryCardContainer_151he_183 ._multiStoreConversionContainer_151he_204 ._collapseContainer_151he_204 ._collapseContainerTable_151he_218 {
  margin-top: 1.5rem;
}

._cellItemContainer_151he_222 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

._cellItemStore_151he_229 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

._tableWrapper_151he_236 {
  height: 100%;
}
._tableWrapper_151he_236 > div {
  height: 100%;
  max-height: 100% !important;
}