import * as React from 'react';
import { Button } from '@revfluence/fresh';

interface IDeactivateButtonProps {
  deactivateAccount?: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const DeactivateButton = (props: IDeactivateButtonProps) => {
  const {
 className, deactivateAccount, loading, disabled,
} = props;

  return (
    <Button onClick={deactivateAccount} className={className} loading={loading} disabled={disabled} size="small">Deactivate</Button>
  );
};
