import { MutationHookOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';

import { THREAD_LABEL_UPDATE_BY_IDS_MUTATION, THREAD_MARK_DONE_ALL_MUTATION } from '@frontend/app/queries';
import {
  ThreadMarkDoneAll,
  ThreadMarkDoneAllVariables,
} from '@frontend/app/queries/types/ThreadMarkDoneAll';
import {
  ThreadLabelUpdateByIds,
  ThreadLabelUpdateByIdsVariables,
} from '@frontend/app/queries/types/ThreadLabelUpdateByIds';

type IOptions = MutationHookOptions<ThreadMarkDoneAll, ThreadMarkDoneAllVariables>;
type IThreadLabelUpdateByIdsOptions = MutationHookOptions<ThreadLabelUpdateByIds, ThreadLabelUpdateByIdsVariables>;

export const useThreadMarkDoneAllMutation = (options: IOptions = {}) => useMutation<ThreadMarkDoneAll, ThreadMarkDoneAllVariables>(THREAD_MARK_DONE_ALL_MUTATION, options);

export const useThreadLabelUpdateByIdsMutation = (options: IThreadLabelUpdateByIdsOptions = {}) => useMutation<ThreadLabelUpdateByIds, ThreadLabelUpdateByIdsVariables>(THREAD_LABEL_UPDATE_BY_IDS_MUTATION, options);
