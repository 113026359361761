import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

import {
  RejectUserIcon,
  CheckIcon,
} from '@components';
import {
  Button,
  SubmitButton,
} from '@components';
import { IconSize } from '@components';
import { TButtonTheme } from '@components';

import styles from '../CreatorActionButtonGroup/CreatorActionButtonGroup.scss';

export interface IGroupCreatorReviewActionButtonGroupProps {
  className?: string;
  /**
   * Is approving a creator
   * This sets the status/styling to the approve button
   */
  isApproving?: boolean;

  /**
   * Show 'Approve' button
   * default: true
   */
  showApproveButton?: boolean;
  /**
   * 'Approve' button props
   */
  approveButtonType?: TButtonTheme;
  /**
   * Callback function on the Approve button
   */
  approveCallback: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  /**
   * Show 'Reject' button
   * default: true
   */
  showRejectButton?: boolean;
  /**
   * 'Reject' button props
   */
  rejectButtonType?: TButtonTheme;
  /**
   * Callback function on the Reject button
   */
  rejectCallback: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const GroupCreatorReviewActionButtonGroup:
React.FunctionComponent<IGroupCreatorReviewActionButtonGroupProps> = React.memo((props) => {
  const {
    className,
    approveButtonType,
    approveCallback,
    isApproving,
    rejectButtonType,
    rejectCallback,
    showApproveButton,
    showRejectButton,
  } = props;

  if (showApproveButton && !isFunction(approveCallback)) {
    throw Error('No callback provided for Approve button');
  } else if (showRejectButton && !isFunction(rejectCallback)) {
    throw Error('No callback provided for Reject button');
  }

  return (
    <div className={cx(className, styles.CreatorActionButtonGroup)}>

      {/* Approve */}
      {showApproveButton && (
        <SubmitButton
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          className={cx(styles.button, (styles as any).approveButton, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [(styles as any).isApproving]: isApproving,
          })}
          label="Approve"
          submittingLabel="Approving..."
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          icon={<CheckIcon className={(styles as any).icon} />}
          isSubmitting={isApproving}
          theme={approveButtonType}
          onClick={approveCallback}
          disabled={isApproving}
        />
      )}

      {/* Reject */}
      {showRejectButton && (
        <Button
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          className={cx(styles.button, (styles as any).rejectManageButton)}
          label="Reject"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          icon={<RejectUserIcon className={(styles as any).icon} size={IconSize.SMALL} />}
          theme={rejectButtonType}
          onClick={(event) => {
            if (isFunction(rejectCallback)) {
              rejectCallback(event);
            } else {
              throw Error('No callback provided for Reject button');
            }
          }}
        />
      )}
    </div>
  );
});

GroupCreatorReviewActionButtonGroup.defaultProps = {
  approveButtonType: 'info',
  rejectButtonType: 'info',
  showApproveButton: true,
  showRejectButton: true,
};

GroupCreatorReviewActionButtonGroup.displayName = 'GroupCreatorReviewActionButtonGroup';
