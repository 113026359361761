import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { SvgIcon, ISvgIconProps } from '@components';

const { useMemo } = React;

export const EmailFilledIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <path
        d="
          M3.33789 2.6665H12.6712C13.7739 2.6665 14.6712 3.56384 14.6712 4.6665V11.3332C14.6712 12.4358
          13.7739 13.3332 12.6712 13.3332H3.33789C2.23522 13.3332 1.33789 12.4358 1.33789 11.3332V4.6665C1.33789
          3.56384 2.23522 2.6665 3.33789 2.6665ZM8.33456 6.9785L12.6712 3.99984H3.33789L7.67456 6.9785C7.87922
          7.09517 8.12989 7.09517 8.33456 6.9785Z
          "
      />
      <mask
        id={id}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="14"
        height="12"
      >
        <path
          d="
            M3.33789 2.6665H12.6712C13.7739 2.6665 14.6712 3.56384 14.6712 4.6665V11.3332C14.6712 12.4358 13.7739
            13.3332 12.6712 13.3332H3.33789C2.23522 13.3332 1.33789 12.4358 1.33789 11.3332V4.6665C1.33789 3.56384
            2.23522 2.6665 3.33789 2.6665ZM8.33456 6.9785L12.6712 3.99984H3.33789L7.67456 6.9785C7.87922 7.09517
            8.12989 7.09517 8.33456 6.9785Z
            "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

EmailFilledIcon.displayName = 'EmailFilledIcon';
