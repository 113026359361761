import {
    QueryHookOptions,
    useQuery,
  } from '@apollo/client';

  import { GET_KLAVIYO_SYNC_STATUS } from '@frontend/app/queries';
  import {
    GetKlaviyoSyncStatus, GetKlaviyoSyncStatusVariables,
  } from '@frontend/app/queries/types/GetKlaviyoSyncStatus';

  type IOptions = QueryHookOptions<GetKlaviyoSyncStatus, GetKlaviyoSyncStatusVariables>;

  export const useGetKlaviyoSyncStatus = (options: IOptions = {}) => (
    useQuery<GetKlaviyoSyncStatus, GetKlaviyoSyncStatusVariables>(GET_KLAVIYO_SYNC_STATUS, options)
  );
