import * as React from 'react';
import { pick } from 'lodash';
import {
  Modal, IModalProps, Input, Button,
  Textarea,
} from '@components';

import { logger } from '@common';
import { SimpleForm, SimpleField } from '@frontend/app/components';
import { ActivationInput } from '@frontend/app/types/globalTypes';
import { useSaveActivationMutation } from '@frontend/app/hooks';

import styles from './NewActivationModal.scss';

const { useState, useRef, useEffect } = React;

interface IProps extends IModalProps {
  onActivationSaved?(): void;
  activation?: ActivationInput;
  className?: string;
}

const EMPTY_INPUT = {
  name: '',
  description: '',
};

export const NewActivationModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const defaultActivationInput = props.activation || EMPTY_INPUT;
  const [input, setInput] = useState<ActivationInput>(defaultActivationInput);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const [saveActivation] = useSaveActivationMutation({
    onCompleted() {
      props.onRequestClose();

      setTimeout(() => {
        setInput(EMPTY_INPUT);
      }, 300);
    },
    onError(err) {
      setErrorMessage(err.message);
    },
  });

  useEffect(() => {
    if (props.activation) {
      setInput(props.activation);
    }
  }, [props.activation]);

  const formRef = useRef<SimpleForm>();

  const handleChangeDescription = (text: string) => {
    setInput((i) => ({ ...i, description: text }));
  };

  const handleChangeFields = (fieldName: string, value: string) => {
    if (errorMessage) {
      setErrorMessage(null);
    }

    setInput((i) => ({ ...i, [fieldName]: value }));
  };

  const handleClickSave = () => {
    formRef.current.submit();
  };

  const handleSubmit = async () => {
    saveActivation({
      variables: {
        activation: {
          id: input.id,
          name: input.name,
          description: input.description,
        },
      },
    }).then(props.onActivationSaved).catch(logger.error);
  };

  const formValues = pick(input, 'name');
  const isEditing = !!props.activation;

  return (
    <Modal
      className={props.className}
      show={props.show}
      showCloseIcon={props.showCloseIcon}
      onRequestClose={props.onRequestClose}
    >
      <div className={styles.NewActivationModal}>
        <div className={styles.title}>
          {isEditing && 'Edit existing activation'}
          {!isEditing && 'Create a new activation'}
        </div>
        <div className={styles.subtitle}>Activations help you organize all incoming deliverables</div>
        {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        <SimpleForm
          ref={formRef}
          values={formValues}
          onChange={handleChangeFields}
          onSubmit={handleSubmit}
        >
          <div className={styles.form}>
            <div className={styles.label}>Name your activation:</div>
            <SimpleField name="name" type="string">
              <Input />
            </SimpleField>

            <div className={styles.label}>Description:</div>
            <Textarea value={input.description} onChange={handleChangeDescription} />
          </div>
        </SimpleForm>

        <div className={styles.buttons}>
          <Button label="Save" onClick={handleClickSave} />
          <Button theme="info" label="Cancel" onClick={props.onRequestClose} />
        </div>
      </div>
    </Modal>
  );
});

NewActivationModal.defaultProps = {
  onActivationSaved: () => undefined,
};
