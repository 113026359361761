import { useCallback, useState } from 'react';
import { size } from 'lodash';
import { ObservableQueryFields } from '@apollo/client';
import { useMessagingContext } from '@frontend/hooks';

type TFetchMore<TData, TVariables> = ObservableQueryFields<TData, TVariables>['fetchMore'];

type TVariablesBase = {
  skip: number;
};

export const useGraphQLFetchMore = <TData, TVariables extends TVariablesBase>(
  fetchMore: TFetchMore<TData, TVariables>,
  mergeResults: (prev: TData, result: TData) => TData,
) => useCallback(async (page: number, fetchSize: number) => {
    await fetchMore<TData, { skip: number }>({
      variables: {
        skip: page * fetchSize,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return mergeResults(prev, fetchMoreResult);
      },
    });
  }, [fetchMore, mergeResults]);

export const usePagination = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[],
  count: number,
  pageSize: number,
  fetchSize: number,
  fetchMore: (page: number, fetchSize: number) => Promise<void>,
) => {
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);

  const {
    showError,
  } = useMessagingContext();

  const updatePage = useCallback(async (newPage: number) => {
    if (isFetchingMore) {
      return;
    }

    if (size(data) >= count) {
      setPage(newPage);
      return;
    }

    const maxPage = Math.floor((size(data) - 1) / pageSize);

    if (newPage <= maxPage) {
      setPage(newPage);
      return;
    }

    setIsFetchingMore(true);

    try {
      await fetchMore(newPage, fetchSize);
      setPage(newPage);
    } catch (err) {
      showError(err);
    }

    setIsFetchingMore(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingMore, setPage, data, count, fetchSize, fetchMore, showError, setIsFetchingMore]);

  return {
    updatePage,
    page,
  };
};
