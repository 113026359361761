import * as React from 'react';
import cx from 'classnames';
import { forEach, isFunction } from 'lodash';

import {
  IModalProps,
  Modal,
} from '@components';

import {
  IWizardHandles,
  IWizardStep,
  IWizardStepHandles,
  Wizard,
} from '@frontend/app/components';

import { useUploadCSVContext } from './hooks/useUploadCSVModalContext';
import { BulkAssign } from './steps/BulkAssign';
import { ImportData } from './steps/ImportData';
import { ReviewImport } from './steps/ReviewImport';
import { UploadFile } from './steps/UploadFile';
import { UploadCSVContextProvider } from './UploadCSVContext';

const {
 useCallback, useMemo, useRef, useEffect,
} = React;

import styles from './UploadCSVModal.scss';

interface IProps extends Partial<IModalProps> {
  onSuccessRefreshMembers?: () => void;
  uploadIdentifier?: string;
}

const UploadCSVModalComponent: React.FunctionComponent<IProps> = (props) => {
  const {
    onRequestClose,
    show,
    onSuccessRefreshMembers,
    uploadIdentifier,
    ...modalProps
  } = props;

  const { reset } = useUploadCSVContext();
  const prevShowRef = useRef<boolean>(show);
  const modalDialogRef = useRef<HTMLDivElement>();
  const wizardRef = useRef<IWizardHandles>();
  const uploadFileRef = useRef<IWizardStepHandles>();
  const importDataRef = useRef<IWizardStepHandles>();

  const handleReset = () => {
    wizardRef.current?.reset();
    uploadFileRef.current?.reset();
  };

  const handleCloseModal = () => {
    if (isFunction(onSuccessRefreshMembers)) {
      onSuccessRefreshMembers();
    }
    if (isFunction(onRequestClose)) {
      onRequestClose();
    }
  };

  const steps = useMemo<IWizardStep[]>(
    () => [
      {
        title: 'Upload .csv file',
        render: () => (
          <UploadFile
            nextStep={() => wizardRef.current?.nextStep()}
            ref={uploadFileRef}
            uploadIdentifier={props.uploadIdentifier}
          />
        ),
        reset: uploadFileRef.current?.reset,
      },
      {
        title: 'Review import',
        render: () => (
          <ReviewImport
            nextStep={() => wizardRef.current?.nextStep()}
            prevStep={() => wizardRef.current?.prevStep()}
            modalDialogRef={modalDialogRef}
            uploadIdentifier={props.uploadIdentifier}
          />
        ),
      },
      {
        title: 'Bulk Assign',
        render: () => (
          <BulkAssign
            nextStep={() => wizardRef.current?.nextStep()}
            prevStep={() => wizardRef.current?.prevStep()}
            uploadIdentifier={props.uploadIdentifier}
          />
        ),
      },
      {
        title: 'Import data',
        render: () => (
          <ImportData
            onCloseModal={handleCloseModal}
            onReviewImport={() => wizardRef.current?.goToStep(2)}
            onTryAgain={handleReset}
            uploadIdentifier={uploadIdentifier}
            ref={importDataRef}
          />
        ),
        reset: importDataRef.current?.reset,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      importDataRef.current,
      uploadFileRef.current,
      wizardRef.current,
    ],
  );

  const handleResetWizard = useCallback(
    () => {
      // Reset context, wizard, and states of all steps
      reset();
      wizardRef.current?.reset();
      forEach(steps, (step) => {
        if (isFunction(step?.reset)) {
          step.reset();
        }
      });
    },
    [reset, steps, wizardRef],
  );

  useEffect(() => {
    if (prevShowRef.current && !show) {
      handleResetWizard();
    }
    prevShowRef.current = show;
  }, [show, handleResetWizard]);

  return (
    <Modal
      dialogClassName={cx(styles.modalDialog, modalProps.className)}
      contentClassName={styles.modalContent}
      onRequestClose={onRequestClose}
      show={show}
      disableCloseOnMaskClick
      {...modalProps}
      className={cx(styles.modal, modalProps?.className)}
      dialogRef={modalDialogRef}
    >
      <Wizard
        steps={steps}
        ref={wizardRef}
        className={styles.wizard}
      />
    </Modal>
  );
};

export const UploadCSVModal: React.FunctionComponent<IProps> = React.memo((props) => (
  <UploadCSVContextProvider>
    <UploadCSVModalComponent {...props} />
  </UploadCSVContextProvider>
));

UploadCSVModal.displayName = 'UploadCSVModal';
