import * as React from 'react';
import {
  TUseDeselectAdAccountMutation,
  TUseGetAdAccountSettingsQuery,
  TUseUpdateAdAccountsMutation,
  TUseGetAvailableAdAccountsQuery,
} from '@frontend/app/hooks';
import { message } from '@revfluence/fresh';
import { AdNetworkType, SocialAccountSettingsStatus } from '@frontend/app/types/globalTypes';
import { IAdAccount, AdAccountTable } from '../AdAccountTable';
import { AdAccountButton } from '../AdAccountButton';
import { composeAdsInsightsDebug } from '../utils/composeCells';
import { composeAdsAccountsFeatures } from '../utils/composeFeatures';
import { ResultsPerPage } from '../pages/InstagramMeta';

export interface IAdAccountsTableProps {
  hidden: boolean;
  query: TUseGetAdAccountSettingsQuery,
  availableAdAccountsQuery: TUseGetAvailableAdAccountsQuery,
  updateAdAccountsMutation: TUseUpdateAdAccountsMutation;
  deselectMutation: TUseDeselectAdAccountMutation,
  reconnectAccount: () => void;
}

export const AdAccountsTable: React.FC<IAdAccountsTableProps> = ({
  hidden,
  query,
  availableAdAccountsQuery,
  updateAdAccountsMutation,
  deselectMutation,
  reconnectAccount,
}) => {
  if (hidden) {
    return null;
  }
  const [deselectAdAccount] = deselectMutation;
  const [updateAdAccount] = updateAdAccountsMutation;
  const { refetch } = query;
  const data = query?.data?.getSocialAccountClientSettings?.metaAdAccountData?.accounts || [];
  const pagination = query?.data?.getSocialAccountClientSettings?.metaAdAccountData?.paging;
  const availableAdAccounts = availableAdAccountsQuery?.data?.getAvailableAdAccounts || [];
  const modalData = availableAdAccounts.map((account) => ({
    networkId: account.network_id,
    name: account.name,
    socialAccountId: account.social_account_id,
    selected: false,
  }));

  const tableData: IAdAccount[] = data.map((account) => ({
    id: account.id,
    socialAccountId: account.social_account_id,
    name: account.name,
    disconnectAccount: account.status === SocialAccountSettingsStatus.ERROR,
    features: composeAdsAccountsFeatures(account),
    accessLost: account.access_lost,
    cell: composeAdsInsightsDebug(account),
  }));
  return (
    <AdAccountTable
      data={tableData}
      removeAccount={(id) => deselectAdAccount({
        variables: {
          network: AdNetworkType.META,
          adAccountId: id,
        },
        update() {
          message.success({
            content: 'Successfully removed Ad Account',
          });
        },
      })}
      pagination={pagination ? {
        total: pagination.totalResults,
        current: pagination.currentPage,
        pageSize: pagination.resultsPerPage,
      } : undefined}
      onPaginationChange={(page) => {
        refetch({
          adAccountPage: page,
          adAccountResultsPerPage: ResultsPerPage,
        });
      }}
      reconnectAccount={reconnectAccount}
      AddAccountsButton={(
        <AdAccountButton
          adAccounts={modalData}
          selectAccount={(input) => updateAdAccount({
            variables: {
              data: {
                accounts: input,
                network: AdNetworkType.META,
              },
            },
          })}
        />
      )}
    />
  );
};
