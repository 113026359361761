import { IAddress } from './address';

export interface IProductShipment {
  publisher_address: IAddress;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tracking_info: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  organization_choices?: any;
  product_selection_off_platform: boolean;
  organization_choices_are_default: boolean;
  id: number;
  project_id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publisher_selection?: any;
}
