import * as React from 'react';
import { findIndex, includes, map, pick, pull, } from 'lodash';

import { Checkbox, Select } from '@components';
import { partitionArrayIntoEqualSubArrays } from '@frontend/utils';
import { FilterSection } from '../FilterSection';

import { ICreatorAgeFilters, ICreatorAgeOptions } from '../models';

import styles from './CreatorAgeSection.scss';

const { useMemo, } = React;

export const CREATOR_AGE_FILTERS = ['ageRanges', 'ageMin', 'ageMax'];

interface IProps extends ICreatorAgeFilters, ICreatorAgeOptions {
  onChange(ageFilters: ICreatorAgeFilters): void;
  hide?: boolean;
  showIQDataFilters?: boolean;
}

const DEFAULT_CREATOR_AGE_RANGES = ['13-17', '18-24', '25-34', '35-44', '45-64', '65+'];

const DEFAULT_AGE_MIN_VALUES = [
  { label: 'Select', value: '' },
  { label: '18', value: '18' },
  { label: '25', value: '25' },
  { label: '35', value: '35' },
  { label: '45', value: '45' },
  { label: '65', value: '65' },
];
const DEFAULT_AGE_MAX_VALUES = [
  { label: 'Select', value: '' },
  { label: '25', value: '25' },
  { label: '35', value: '35' },
  { label: '45', value: '45' },
  { label: '65', value: '65' },
  { label: '65+', value: '65+' },
];

const pickFilters = (obj: any) => pick(obj, CREATOR_AGE_FILTERS);

const CreatorAgeSection: React.FunctionComponent<IProps> = (props) => {
  const {
    onChange,
    ageRanges,
    ageMin,
    ageMax,
    ageRangeChoices,
  } = props

  const handleChangeAgeRanges = (ageRange: string, checked: boolean) => {
    const newSelectedAgeRanges = [...(ageRanges || [])];
    if (checked) {
      newSelectedAgeRanges.push(ageRange);
    } else {
      pull(newSelectedAgeRanges, ageRange);
    }
    const newFilters = {
      ...pickFilters(props),
      ageRanges: newSelectedAgeRanges,
    };

    onChange(newFilters);
  };

  const handleChangeAgeMin = (ageMin: string) => {
    const newFilters = {
      ...pickFilters(props),
      ageMin,
    };
    onChange(newFilters);
  };

  const handleChangeAgeMax = (ageMax: string) => {
    const newFilters = {
      ...pickFilters(props),
      ageMax,
    };
    onChange(newFilters);
  };

  const ageMinIndex = useMemo(() => {
    return findIndex(DEFAULT_AGE_MIN_VALUES, { value: ageMin });
  }, [ageMin]);

  const ageMaxIndex = useMemo(() => {
    return findIndex(DEFAULT_AGE_MAX_VALUES, { value: ageMax });
  }, [ageMax]);

  const columns = partitionArrayIntoEqualSubArrays(ageRangeChoices ?? [], 2);

  return (
    <FilterSection
      header="Age"
      hide={props.hide && !props.showIQDataFilters}
      classNames={[styles.CreatorAgeSection]}
      isSubsection
    >
      {
        props.showIQDataFilters ?
        <div className={styles.multiSelectContainer}>
          <Select
            options={DEFAULT_AGE_MIN_VALUES}
            onChange={handleChangeAgeMin}
            selectedIndex={ageMinIndex}
            className={styles.multiSelectSelect}
          />
          <span className={styles.multiSelectSeparator}>
           to
          </span>
          <Select
            options={DEFAULT_AGE_MAX_VALUES}
            onChange={handleChangeAgeMax}
            selectedIndex={ageMaxIndex}
            className={styles.multiSelectSelect}
          />
        </div>
        :
        <div className={styles.checkboxes}>
          {map(columns, (column, index) => (
            <div key={index} className={styles.column}>
              {map(column, (ageRange) => (
                <Checkbox
                  key={ageRange}
                  className={(styles as any).checkbox}
                  label={ageRange}
                  checked={includes(ageRanges, ageRange)}
                  onChange={(checked) => handleChangeAgeRanges(ageRange, checked)}
                />
              ))}
            </div>
          ))}
        </div>
      }
    </FilterSection>
  );
};

CreatorAgeSection.defaultProps = {
  ageRanges: [],
  ageRangeChoices: DEFAULT_CREATOR_AGE_RANGES,
  ageMin: '',
  ageMax: '',
};

export default CreatorAgeSection;
