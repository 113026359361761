/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1nfu3_157 {
  display: flex;
}

._justify-content-space-between_1nfu3_161 {
  justify-content: space-between;
}

._tabular-nums_1nfu3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1nfu3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._paymentsProcessingContainer_1nfu3_178 ._main_1nfu3_178 {
  text-align: center;
  margin-top: 4rem;
  padding: 1.25rem;
}
._paymentsProcessingContainer_1nfu3_178 ._progressContainer_1nfu3_183 {
  width: 80%;
  margin: 0rem auto;
}