import {
  isUndefined,
} from 'lodash';
import * as React from 'react';

import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';

import { TGetTaskBySpecUriAndId } from '@frontend/app/containers/Projects/hooks';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';

import { useApolloClient } from '@frontend/applications/AffiliatesApp/hooks';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { GET_OFFERS_BY_SEARCH_QUERY } from '@frontend/applications/AffiliatesApp/queries';
import { useEffect } from 'react';
import { logger } from '@common';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import {
  AddMembersToCollectionModal,
  ModalType,
} from './AddMembersToProgramModal/AddMembersToCollectionModal';
import { ProspectsList } from './ProspectsList/ProspectsList';
import { WorkItemsList } from './WorkItemsList/WorkItemsList';
import { OverviewPage } from '../OverviewPage/OverviewPage';
import { FindCreatorsPage } from './FindCreatorsPage/FindCreatorsPage';
import { ProjectsPageState } from '../constants';
import { IProjectsApp } from '../hooks';
import { GroupContentReviewPage } from './GroupContentReviewPage';
import { TTask } from '../types';
import { SelectOffers } from './SelectOffer/SelectOffer';

const PartialProjectsAppProps = [
  'counts',
  'isLoading',
  'project',
  'projects',
  'refetchCounts',
  'getTaskBySpecUriAndId',
  'task',
  'worklets',
  'presetConditions',
  'getTasksNeedAttention',
] as const;
type TPartialProjectsAppProps = Pick<IProjectsApp, typeof PartialProjectsAppProps[number]>;
interface IProjectsPageContentProps extends TPartialProjectsAppProps {
  applicationId: string;
  isProspectsView: boolean;
  isWorkItemsView: boolean;
  isOverviewPage: boolean;
  isFindCreatorsPage: boolean;
  isGroupContentReviewPage: boolean;
  onTaskSelected: THandleTaskSelected;
  getTaskBySpecUriAndId: TGetTaskBySpecUriAndId;
  pageState: ProjectsPageState;
  workletSpecUri?: string;
  tasks: TTask[];
}

const {
  useState,
} = React;

export const ProjectsPageContent: React.FC<IProjectsPageContentProps> = (props) => {
  const {
    applicationId,
    counts,
    isLoading,
    isProspectsView,
    isWorkItemsView,
    isOverviewPage,
    isFindCreatorsPage,
    isGroupContentReviewPage,
    onTaskSelected,
    pageState,
    presetConditions,
    project,
    projects,
    refetchCounts,
    getTaskBySpecUriAndId,
    task,
    worklets,
    getTasksNeedAttention,
    workletSpecUri,
    tasks,
  } = props;
  const staApolloClient = useApolloClient();
  const [offers, setOffers] = useState<GetOfferById_offer[]>([]);
  const [selectedMemberIds, setSelectedMemberIds] = useState<IMember['id'][]>();
  const migrateToGraphQL = useClientFeatureEnabled(ClientFeature.MIGRATE_TO_GRAPHQL);

  useEffect(() => {
    if (migrateToGraphQL && project) {
      staApolloClient.query({
        query: GET_OFFERS_BY_SEARCH_QUERY,
        variables: {
          query: {
            programId: project.id,
          },
        },
        fetchPolicy: 'no-cache',
      }).then((data) => {
        setOffers(data.data.offers);
      }).catch((error) => {
        logger.error(error.message);
      });
    }
  }, [project, staApolloClient, selectedMemberIds, migrateToGraphQL]);
  const [isVisibleSelectOffer, setIsVisibleSelectOffer] = useState(false);
  const onClose = () => {
    setIsVisibleSelectOffer(false);
  };
  /**
   * Modals
   */
  const [modalType, setModalType] = useState<ModalType | undefined>();

  const openAddToCollectionModal = (modalTypeArg: ModalType) => {
    setModalType(modalTypeArg);
  };
  /**
   * Render
   */
  const renderModals = () => (
    <AddMembersToCollectionModal
      loading={isLoading}
      onCloseModal={() => setModalType(undefined)}
      modalType={modalType}
      project={project}
      projects={projects}
      showModal={!isUndefined(modalType)}
      selectedMemberIds={selectedMemberIds}
    />
  );

  return (
    <>
      {isOverviewPage && project && (
        <OverviewPage
          onTaskSelected={onTaskSelected}
          counts={counts}
          tasks={getTasksNeedAttention}
          project={project}
          openAddToCollectionModal={openAddToCollectionModal}
          worklets={worklets}
        />
      )}
      {isFindCreatorsPage && project && (
        <FindCreatorsPage project={project} />
      )}
      {isProspectsView && (
        <ProspectsList
          isLoading={isLoading}
          onSelectMemberIds={setSelectedMemberIds}
          openAddToCollectionModal={openAddToCollectionModal}
          pageState={pageState}
          project={project}
          refetchCounts={refetchCounts}
        />
      )}
      {isGroupContentReviewPage && (
        <GroupContentReviewPage project={project} />
      )}
      {isWorkItemsView && (
        <WorkItemsList
          applicationId={applicationId}
          conditions={presetConditions}
          counts={counts}
          onSelectMemberIds={setSelectedMemberIds}
          onTaskSelected={onTaskSelected}
          openAddToCollectionModal={openAddToCollectionModal}
          pageState={pageState}
          project={project}
          refetchCounts={refetchCounts}
          getTaskBySpecUriAndId={getTaskBySpecUriAndId}
          task={task}
          tasks={tasks}
          worklets={worklets}
          workletSpecUri={workletSpecUri}
          showSelectOffer={setIsVisibleSelectOffer}
          migrateToGraphQL={migrateToGraphQL}
        />
      )}
      <SelectOffers offers={offers} openSelectedOffer={isVisibleSelectOffer} closeSelectedOffer={onClose} selectedMemberIds={selectedMemberIds} setIsVisibleSelectOffer={setIsVisibleSelectOffer} />
      {renderModals()}
    </>
  );
};
