import * as React from 'react';
import cx from 'classnames';

import {
  useClientFeatureEnabled,
} from '@frontend/app/hooks';

import { Typography } from 'antd';
import { EnvelopeIcon, EnvelopeOpenIcon } from '@revfluence/fresh-icons/regular/esm';

import { isNull, isEmpty, size } from 'lodash';
import { UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables } from '@frontend/app/queries/types/UpdateThreadToReadStatus';
import { UpdateThreadToUnreadStatus, UpdateThreadToUnreadStatusVariables } from '@frontend/app/queries/types/UpdateThreadToUnreadStatus';
import { useMutation } from '@apollo/client';
import { THREAD_MARK_READ_MUTATION, THREAD_MARK_UNREAD_MUTATION } from '@frontend/app/queries';
import { logger } from '@common';

import {
  useMessagingContext,
} from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';

import {
  CheckboxChangeEvent,
 } from '@revfluence/fresh';
import { ClientFeature } from '@frontend/app/constants';

import {
  Button,
  Checkbox,
  Tooltip,
} from '@revfluence/fresh';
import { useReactiveVar } from '@apollo/client';
import {
  isSelectAllThreadsVar,
  selectedThreadsVar,
} from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { SharedThreadListHeaderProps } from './model';
import { ThreadListHeaderGenericActions } from './ThreadListHeaderGenericActions';
import styles from '../ThreadList.scss';

const { Text } = Typography;
enum UpdateThreadType {
  READ = 'read',
}

export const SharedThreadListHeader = ({
  applications,
  assigneeType,
  count,
  excludeApplicationIdsFilter,
  filterApps,
  isAppFilterOpen,
  loadingThreads,
  title,
  syncing,
  onRefreshMessages,
  setExcludeApplicationIdsFilter,
  setIsAppFilterOpen,
  onSelectAllThreadUpdate,
  refetchThreadsCount,
  shouldShowActions,
}: SharedThreadListHeaderProps) => {
  const isInboxSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);
  const selectedThreads = useReactiveVar(selectedThreadsVar);
  const isSelectAllThreads = useReactiveVar(isSelectAllThreadsVar);

  const isAnyThreadUnread = selectedThreads.some((thread) => !thread?.isRead);

  const {
    threads,
    refetchAndUpdateThreads,
  } = useMessagingContext();

  const [markThreadAsRead] = useMutation<UpdateThreadToReadStatus, UpdateThreadToReadStatusVariables>(THREAD_MARK_READ_MUTATION);
  const [markThreadAsUnread] = useMutation<UpdateThreadToUnreadStatus, UpdateThreadToUnreadStatusVariables>(THREAD_MARK_UNREAD_MUTATION);
  const onCheckboxUpdate = (event: CheckboxChangeEvent) => {
    isSelectAllThreadsVar(event.target.checked);
    onSelectAllThreadUpdate(event.target.checked);
  };

  const onUpdateThreads = async (type: UpdateThreadType) => {
    try {
      switch (type) {
        case UpdateThreadType.READ:
          const threadIds = selectedThreads.map((item) => item.id);
          if (isAnyThreadUnread) {
            await markThreadAsRead({ variables: { threadIds } });
          } else {
            await markThreadAsUnread({ variables: { threadIds } });
          }
          break;
      }

      selectedThreadsVar([]);
      isSelectAllThreadsVar(false);
      refetchAndUpdateThreads(0, size(threads));
      refetchThreadsCount();
    } catch (error) {
      logger.error(error.message);
    }
  };

  return (
    <div className={cx(
      styles.header,
      {
        [styles.legacy]: !isInboxSearchFilter,
      },
    )}
    >
      {!isInboxSearchFilter && (
        <div className={styles.titleAndSearch}>
          <div className={styles.titleAndCount}>
            <Text ellipsis className={styles.title}>{title}</Text>
            { !isNull(count) && <div className={styles.count}>{count || 0}</div> }
          </div>
        </div>
      )}
      <div className={styles.actions}>
        <div className={styles.bulkActions}>
          {shouldShowActions && <Checkbox checked={isSelectAllThreads} onChange={onCheckboxUpdate} />}
          {!isEmpty(selectedThreads) && (
          <>
            <Tooltip title={isAnyThreadUnread ? 'Mark all as Read' : 'Mark all as Unread'}>
              <Button
                size="middle"
                onClick={() => onUpdateThreads(UpdateThreadType.READ)}
                icon={isAnyThreadUnread ? <EnvelopeOpenIcon /> : <EnvelopeIcon />}
              />
            </Tooltip>
          </>
        )}
        </div>
        <ThreadListHeaderGenericActions
          excludeApplicationIdsFilter={excludeApplicationIdsFilter}
          filterApps={filterApps}
          setExcludeApplicationIdsFilter={setExcludeApplicationIdsFilter}
          assigneeType={assigneeType}
          loadingThreads={loadingThreads}
          syncing={syncing}
          isAppFilterOpen={isAppFilterOpen}
          applications={applications}
          onRefreshMessages={onRefreshMessages}
          setIsAppFilterOpen={setIsAppFilterOpen}
        />
      </div>
    </div>
  );
};
