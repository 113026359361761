import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CONTENT_REVIEW_QUERY } from '@frontend/app/queries';
import {
  GetContentReviewQuery,
  GetContentReviewQueryVariables,
  GetContentReviewQuery_review,
} from '@frontend/app/queries/types/GetContentReviewQuery';

export type IContentReviewDetail = GetContentReviewQuery_review;
type IOptions = QueryHookOptions<GetContentReviewQuery, GetContentReviewQueryVariables>;

export function useGetContentReviewById(options: IOptions = {}) {
  const {
    loading, data: { review } = {}, error, refetch,
  } = useQuery<GetContentReviewQuery, GetContentReviewQueryVariables>(
    GET_CONTENT_REVIEW_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: false,
      skip: !options.variables?.id,
    },
  );

  return {
    loading,
    review,
    error,
    refetch,
  };
}
