import gql from 'graphql-tag';

export const MESSAGE_TEMPLATE_FRAGMENT = gql`
  fragment MessageTemplateFragment on MessageTemplate {
    id
    programId
    title
    subject
    text
    excludeSubject
    transportType
    bodyType
    type
    attachments {
      id
      name
      url
      size
      type
      uuid
      fileUrl
      localSrc
    }
  }
`;

export const MESSAGE_TEMPLATES_QUERY = gql`
  query MessageTemplatesQuery {
    templates: messageTemplates {
      ...MessageTemplateFragment
    }
  }
  ${MESSAGE_TEMPLATE_FRAGMENT}
`;

export const MESSAGE_TEMPLATE_QUERY = gql`
  query MessageTemplateQuery($templateId: Int!) {
    template: messageTemplateById(templateId: $templateId) {
      ...MessageTemplateFragment
    }
  }
  ${MESSAGE_TEMPLATE_FRAGMENT}
`;

export const MESSAGE_TEMPLATE_FOR_PROGRAM_QUERY = gql`
  query MessageTemplateForProgramQuery($programId: Int!, $type: TemplateType!) {
    template: messageTemplateForProgram(programId: $programId, type: $type) {
      ...MessageTemplateFragment
    }
  }
  ${MESSAGE_TEMPLATE_FRAGMENT}
`;

export const UPDATE_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation UpdateMessageTemplateMutation($id: Int!, $data: JSONObject!) {
    template: updateMessageTemplate(id: $id, data: $data) {
      ...MessageTemplateFragment
    }
  }
  ${MESSAGE_TEMPLATE_FRAGMENT}
`;

export const CREATE_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation CreateMessageTemplateMutation($data: JSONObject!) {
    template: createMessageTemplate(data: $data) {
      ...MessageTemplateFragment
    }
  }
  ${MESSAGE_TEMPLATE_FRAGMENT}
`;

export const RESET_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation ResetMessageTemplateMutation($id: Int!) {
    template: resetMessageTemplate(id: $id) {
      ...MessageTemplateFragment
    }
  }
  ${MESSAGE_TEMPLATE_FRAGMENT}
`;

export const DELETE_MESSAGE_TEMPLATE_MUTATION = gql`
  mutation DeleteMessageTemplateMutation($id: Int!) {
    success: deleteMessageTemplate(id: $id)
  }
`;
