import { MutationHookOptions } from '@apollo/client';
import { DataProxy } from 'apollo-cache';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { each } from 'lodash';

import { useMemberMutation } from '@frontend/app/hooks';
import { MARK_MEMBERS_AS_IMPORTANT } from '@frontend/app/queries';
import {
  MarkMembersAsImportantMutation,
  MarkMembersAsImportantMutationVariables,
} from '@frontend/app/queries/types/MarkMembersAsImportantMutation';

type IOptions = MutationHookOptions<MarkMembersAsImportantMutation, MarkMembersAsImportantMutationVariables>;

export const useMarkMembersAsImportantMutation = (options: IOptions = {}) => {
  const mutation = useMemberMutation<MarkMembersAsImportantMutation, MarkMembersAsImportantMutationVariables>(
    MARK_MEMBERS_AS_IMPORTANT,
    options,
  );

  const [markMembers] = mutation;

  // Wrap original method to include updating member cache.
  const handleMarkMembers: typeof markMembers = (...args) => {
    const [thisOptions = {}] = args;

    args[0] = {
      ...thisOptions,
      update: (...updateArgs) => {
        if (thisOptions.update) {
          thisOptions.update(...updateArgs);
        }

        const [store] = updateArgs;
        const {
          memberIds,
          isImportant,
        } = thisOptions.variables || options.variables;

        each(memberIds, (memberId) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          updateCache(store as any, memberId, isImportant);
        });
      },
    };

    return markMembers(...args);
  };

  mutation[0] = handleMarkMembers;

  return mutation;
};

const updateCache = (store: DataProxy, memberId: number, isImportant: boolean) => {
  try {
    store.writeData({
      id: defaultDataIdFromObject({ __typename: 'Member', id: memberId }),
      data: {
        isImportant,
      },
    });
  } catch (err) {
    // Doesn't exist. Do nothing.

  }
};
