import { getEnvironment, Environment } from '@frontend/utils/Envionment/Environment';
import { IPayment } from './models';

const CENTS_PER_DOLLAR = 100;

export enum PaymentStatus {
  CANCELED = 'Canceled',
  PAID = 'Paid',
  PROCESSING = 'Processing',
  PENDING = 'Pending Info',
}

// Adding these as functions to eventually make foreign currency support easier
export const dollarsForCentsValue = (cents: number) => cents / CENTS_PER_DOLLAR;

export const centsForDollarValue = (dollars: number) => dollars * CENTS_PER_DOLLAR;

export const stripePublicAPIKey = (function (environment) {
  switch (environment) {
    case Environment.PRODUCTION:
      return 'pk_live_4P3NTNrvAD0Q3s1Jui4NW7nz';
    case Environment.STAGING:
    case Environment.DEVELOPMENT:
      return 'pk_test_4P3N2NHwroCeoCiadHlHp9M6';
    case Environment.LOCALHOST:
      return 'pk_test_4P3N2NHwroCeoCiadHlHp9M6';
  }
}(getEnvironment()));

export const statusForPayment = (payment: IPayment) => {
  if (payment.canceled) {
    return PaymentStatus.CANCELED;
  }
  if (payment.has_paid_publisher) {
    return PaymentStatus.PAID;
  }
  if (payment.is_payable) {
    return PaymentStatus.PROCESSING;
  }
  return PaymentStatus.PENDING;
};

export const moneyNumToString = (dollars: number) => {
  const asNum = parseFloat(dollars.toString());
  if (isNaN(asNum)) {
    return '---';
  }
  const split = asNum.toFixed(2).split('.');
  const wholeDollars = parseInt(split[0], 10);
  return `${wholeDollars.toLocaleString()}.${split[1]}`;
};
