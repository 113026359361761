import * as React from 'react';
import moment from 'moment';
import { Typography, Alert } from '@revfluence/fresh';
import {
  TDateRequirement,
  DateOperator,
  IDateBefore,
  IDateOn,
  IDateBetween,
} from '../../types/ContentDateRequirements';
import DueDateItem from './DueDateItem';

import styles from './DueDateList.scss';
import { DueDateType, IContentGuidelinesWithDueDates } from '../../types/ContentGuidelines';

const { Title } = Typography;

const { useCallback, useState } = React;

interface IProps {
  requiresReviewBeforeSubmission: boolean;
  contentGuidelineInstanceId: number;
  dueDates: IContentGuidelinesWithDueDates['dueDates'];
  onUpdateDueDate: (
    dueDateType: DueDateType,
    contentGuidelineInstanceId: number,
    dueDateIndex: number,
    dueDate: TDateRequirement,
  ) => void;
}

const DueDateList: React.FC<IProps> = React.memo((props) => {
  const {
 requiresReviewBeforeSubmission, contentGuidelineInstanceId, dueDates, onUpdateDueDate,
} = props;

  const [error, setError] = useState<string[]>(new Array(dueDates.completeDate.length).fill(''));

  const getDateValue = useCallback((operator: DateOperator, date: TDateRequirement) => {
    switch (operator) {
      case DateOperator.BEFORE:
        return (date as IDateBefore).beforeDate;
      case DateOperator.ON:
        return (date as IDateOn).onDate;
      case DateOperator.BETWEEN:
        return (date as IDateBetween).onDate;
      default:
        throw new Error('Unhandled operator');
    }
  }, []);

  const deadlineValidation = useCallback(
    (date: Date, dueDateType: DueDateType, dueDateIndex: number): string => {
      if (requiresReviewBeforeSubmission) {
        if (dueDateType === DueDateType.COMPLETE_DATE) {
          const { submissionDate } = dueDates;
          const { operator } = submissionDate[dueDateIndex];
          const submissionDateValue = moment(getDateValue(operator, submissionDate[dueDateIndex])).format('MM/DD/YYYY');
          const completeDateValue = moment(date).format('MM/DD/YYYY');
          if (moment(submissionDateValue).isAfter(completeDateValue)) {
            return 'Completion deadline must be later than submission deadline';
          }
        } else {
          const { completeDate } = dueDates;
          const { operator } = completeDate[dueDateIndex];
          const completeDateValue = moment(getDateValue(operator, completeDate[dueDateIndex])).format('MM/DD/YYYY');
          const submissionDateValue = moment(date).format('MM/DD/YYYY');
          if (moment(submissionDateValue).isAfter(completeDateValue)) {
            completeDate[dueDateIndex] = {
              operator: DateOperator.BEFORE,
              beforeDate: null,
            };
            return 'Please select a new completion deadline. The deadline must be later that submission deadline.';
          }
        }
      }
      return '';
    },
    [dueDates, requiresReviewBeforeSubmission, getDateValue],
  );

  const isSubmissionDateSelected = useCallback((submissionDate: TDateRequirement) => {
    switch (submissionDate.operator) {
      case DateOperator.ON:
        return !submissionDate.onDate;
      case DateOperator.BEFORE:
        return !submissionDate.beforeDate;
      case DateOperator.BETWEEN:
        return !submissionDate.beforeDate;
      default:
        return false;
    }
  }, []);

  const handleUpdateDueDate = useCallback(
    (
      dueDateType: DueDateType,
      _contentGuidelineInstanceId: number,
      dueDateIndex: number,
      dueDate: TDateRequirement,
    ) => {
      const date = getDateValue(dueDate.operator, dueDate);
      const errorMessage = deadlineValidation(date, dueDateType, dueDateIndex);

      setError([
        ...error.slice(0, dueDateIndex),
        errorMessage,
        ...error.slice(dueDateIndex + 1),
      ]);

      onUpdateDueDate(
        dueDateType,
        _contentGuidelineInstanceId,
        dueDateIndex,
        dueDate,
      );
    },
    [error, deadlineValidation, getDateValue, onUpdateDueDate],
  );

  const getOrdinal = useCallback((index: number) => moment.localeData().ordinal(index + 1), []);

  return (
    <div className={styles.DueDateList}>
      <Title level={5}>Due Dates</Title>
      <ul>
        {dueDates.completeDate.map((completeDate, index) => {
          if (requiresReviewBeforeSubmission) {
            return (
              <>
                <li key={`${contentGuidelineInstanceId}-submissionDate-${index.toString()}`}>
                  <DueDateItem
                    dueDateType={DueDateType.SUBMISSION_DATE}
                    dueDateDescription={`Submit the ${getOrdinal(index)} piece of content`}
                    contentGuidelineInstanceId={contentGuidelineInstanceId}
                    dueDate={dueDates.submissionDate[index]}
                    dueDateIndex={index}
                    onUpdateDueDate={handleUpdateDueDate}
                  />
                </li>
                <li key={`${contentGuidelineInstanceId}-completeDate-${index.toString()}`}>
                  <DueDateItem
                    dueDateType={DueDateType.COMPLETE_DATE}
                    dueDateDescription={`Complete the ${getOrdinal(index)} piece of content`}
                    contentGuidelineInstanceId={contentGuidelineInstanceId}
                    dueDate={completeDate}
                    dueDateIndex={index}
                    disabled={isSubmissionDateSelected(dueDates.submissionDate[index])}
                    disableDateFrom={getDateValue(
                      dueDates.submissionDate[index].operator,
                      dueDates.submissionDate[index],
                    )}
                    onUpdateDueDate={handleUpdateDueDate}
                  />
                </li>
                {error[index] !== '' && (
                  <li key={`${contentGuidelineInstanceId}-error-${index.toString()}`}>
                    <Alert message={error[index]} type="error" />
                  </li>
                )}
              </>
            );
          }
          return (
            <>
              <li key={`${contentGuidelineInstanceId}-completeDate-${index.toString()}`}>
                <DueDateItem
                  dueDateType={DueDateType.COMPLETE_DATE}
                  dueDateDescription={`Complete the ${getOrdinal(index)} piece of content`}
                  contentGuidelineInstanceId={contentGuidelineInstanceId}
                  dueDate={completeDate}
                  dueDateIndex={index}
                  onUpdateDueDate={handleUpdateDueDate}
                />
              </li>
              {error[index] !== '' && (
                <li key={`${contentGuidelineInstanceId}-error-${index.toString()}`}>
                  <Alert message={error} type="error" />
                </li>
              )}
            </>
          );
        })}
      </ul>
    </div>
  );
});

DueDateList.displayName = 'DueDateList';

export default DueDateList;
