import * as React from 'react';
import { filter } from 'lodash';

import { useBreakpoint } from '@revfluence/fresh';

export enum ScreenSizes {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  ExtraExtralarge = 'xxl',
}
export interface TSreenSizeDetails {
  isMobile: boolean;
  isWide: boolean;
  currentBreakpoints: string[];
}

const {
  ExtraSmall,
  Small,
  Medium,
  Large,
  ExtraLarge,
  ExtraExtralarge,
} = ScreenSizes;

const mobileSizes = [
  ExtraSmall,
  Small,
  Medium,
];

const wideSizes = [
  Large,
  ExtraLarge,
  ExtraExtralarge,
];

const { useMemo } = React;

export const useScreenSize = (): TSreenSizeDetails => {
  const screens = useBreakpoint();
  const currentBreakpoints = Object
    .entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => screen[0]);

  const isMobile = useMemo(() => (
    filter(
      currentBreakpoints,
      (breakpoint:ScreenSizes) => mobileSizes.includes(breakpoint),
    ) && !currentBreakpoints.includes(Large)
  ), [currentBreakpoints]);

  const isWide = useMemo(() => (
    filter(
      currentBreakpoints,
      (breakpoint:ScreenSizes) => wideSizes.includes(breakpoint),
    ) && !currentBreakpoints.includes(Medium)
  ), [currentBreakpoints]);

  return {
    isMobile,
    isWide,
    currentBreakpoints,
  };
};
