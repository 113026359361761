import * as React from 'react';

import { useFuzzySearchByKeys } from '@frontend/app/hooks';
import { FieldType } from '@frontend/app/types/Fields';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { isEmpty, isString } from 'lodash';
import { CustomFieldType } from '@frontend/app/types/globalTypes';
import { DynamicChoice } from '../model';
import { IncludeSelect } from './IncludeSelect';

interface IProps {
  defaultValue: string[];
  onChange(value: string[] | string);
  field?: IField;
  isDynamic?: boolean;
}

export const ArrayInclusion: React.FC<IProps> = (props) => {
  const {
    field,
    isDynamic,
  } = props;

  const {
    type: fieldType,
    choices,
  } = field;

  const options = choices.map((choice) => {
    if (isDynamic) {
      return {
        title: (choice as DynamicChoice).label,
        id: (choice as DynamicChoice).value,
      };
    }

    return {
      title: choice,
      id: choice,
    };
  });

  const handleSearch = useFuzzySearchByKeys(options, ['title']);

  const handleChange = (selectedChoices: string[]) => {
    if ([FieldType.ARRAY, FieldType.DYNAMIC_SELECT].includes(fieldType as CustomFieldType)) {
      props.onChange(selectedChoices);
    } else {
      props.onChange(selectedChoices[0]);
    }
  };

  type TOption = typeof options[0];

  let defaultValue = props.defaultValue;

  if (isString(props.defaultValue)) {
    defaultValue = [props.defaultValue];
  } else if (isEmpty(defaultValue)) {
    defaultValue = [];
  }

  return (
    <IncludeSelect
      showSearch
      onSearchRequest={handleSearch}
      options={options}
      multi={[FieldType.ARRAY, FieldType.DYNAMIC_SELECT].includes(fieldType as CustomFieldType)}
      defaultSelectedIds={defaultValue}
      selectedIds={defaultValue}
      mapOptionToId={(option: TOption) => option.id}
      mapOptionToLabel={(option: TOption) => option.title}
      onChange={handleChange}
    />
  );
};
