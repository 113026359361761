import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetBudgetAccountDistribution } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccountDistribution';
import { useUpsertBudgetAccountDistribution } from '@frontend/app/hooks/budgetAllocation/useUpsertBudgetAccountDistribution';
import { GetBudgetAccountDistribution_budgetAccountDistribution } from '@frontend/app/queries/types/GetBudgetAccountDistribution';
import { GetBudgetFiscalSettingsQuery_budgetSettingsResponse } from '@frontend/app/queries/types/GetBudgetFiscalSettingsQuery';
import { SackDollarIcon } from '@revfluence/fresh-icons/regular/esm';
import { LoadSpinner } from '@frontend/app/components';
import { useGetBudgetFiscalSettings } from '@frontend/app/hooks/budgetAllocation/useGetBudgetFiscalSettings';
import PageSkeleton from '../PageSkeleton';
import BudgetAccountForm, { FormValues, mapToFormValues } from '../budgetAccountForm';
import styles from './EditBudget.scss';

const renderIcon = () => (
  <div className={styles.iconContainer}>
    <SackDollarIcon className={styles.icon} />
  </div>
);

interface IParams {
  id?: string;
}

const EditBudget: React.FunctionComponent = () => {
  const { id: budgetId } = useParams<IParams>();
  const [values, setValues] = useState<FormValues>();
  const location = useLocation();
  const { budgetAccountDistribution, loading: budgetAccountLoading }: { budgetAccountDistribution: GetBudgetAccountDistribution_budgetAccountDistribution[], loading: boolean } = useGetBudgetAccountDistribution({
    variables: {
      budgetId: parseInt(budgetId, 10),
    },
  });

  const { budgetSettingsResponse, loading: budgetSettingsLoading }: { budgetSettingsResponse: GetBudgetFiscalSettingsQuery_budgetSettingsResponse, loading: boolean } = useGetBudgetFiscalSettings({});

  const { upsertBudgetAccountDistribution, loading: saving } = useUpsertBudgetAccountDistribution();

  useEffect(() => {
    if (budgetAccountDistribution) {
      setValues(mapToFormValues(budgetAccountDistribution?.[0]));
    }
  }, [budgetAccountDistribution]);

  const onSave = useCallback(async () => {
    try {
      await upsertBudgetAccountDistribution({
        variables: {
          budgetAccountInput: {
            ...values,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [values, upsertBudgetAccountDistribution]);

  const isMasterBudget = !values?.parentBudgetId;
  const isSettingsPage = useMemo(() => location.pathname.includes('settings'), [location.pathname]);
  const getPageContent = useCallback(() => (
    <BudgetAccountForm
      values={values}
      setValues={setValues}
      budgetSettingsResponse={budgetSettingsResponse}
      loading={budgetAccountLoading || budgetSettingsLoading}
      saving={saving}
      onSave={onSave}
      showBudgetToLink={!isMasterBudget}
      disabledBudgetToLinkIfPresent={!!values?.parentBudgetId}
      budgetNameDisabled={isMasterBudget}
      title="Budget Details"
      description="Provide basic details for your new budget account."
      saveButtonText="Save"
      isSettingsPage={isSettingsPage}
    />
    ), [values, setValues, budgetAccountLoading, saving, onSave, isMasterBudget, budgetSettingsResponse, budgetSettingsLoading, isSettingsPage]);
  if (budgetAccountLoading || budgetSettingsLoading) return <LoadSpinner />;

  return (
    <PageSkeleton
      title="Edit Budget Account"
      backRedirectLink={`${isSettingsPage ? '/settings' : ''}/budget/dashboard`}
      renderIcon={renderIcon}
    >
      {getPageContent()}
    </PageSkeleton>
  );
};

export default EditBudget;
