import * as React from 'react';
import cx from 'classnames';

import { DownOutlined } from '@ant-design/icons';
import {
 Avatar, Button, Dropdown, Menu,
} from '@revfluence/fresh';
import { Link } from 'react-router-dom';
import {
 chain, isNumber, replace, trim,
} from 'lodash';

import { TopNavItem } from '@frontend/app/types/TopNavItem';
import styles from './NavItem.scss';
import dropdownStyles from './TopbarDropdown.scss';

const { useMemo } = React;
export interface INavItemProps {
  item: TopNavItem;
  onClick: (id: string) => void;
}

export const NavItem: React.FC<INavItemProps> = ({ item, onClick }) => {
  const { icon: IconComponent, text, children } = item;
  const isNavItemProject = item.id === 'projects' || item.id === 'applicants';

  const menuItems = useMemo(() => {
    const projectMenu = item.id === 'projects';
    if (projectMenu) {
      return chain(children).sortBy((child) => child.text.toLocaleLowerCase());
    }
    return chain(children);
  }, [children, item]);

  const menu = useMemo(
    () => (
      <Menu className={cx(dropdownStyles.TopNavbarSubmenu)}>
        <div className={cx({ [dropdownStyles.TopNavbarSubmenuLong]: isNavItemProject })}>
          {menuItems
            .map((subItem, index) => (
              <Menu.Item
                className={cx(dropdownStyles.TopNavbarSubmenuItem, { [dropdownStyles.selected]: subItem.selected })}
                key={subItem.id + index}
              >
                <Link to={subItem.route}>
                  {subItem.imageUrl && (
                    <Avatar src={subItem.imageUrl} className={dropdownStyles.TopNavbarSubmenuImage} />
                  )}
                  <span className={dropdownStyles.TopNavbarSubmenuText}>
                    { trim(replace(subItem.text, '#AR', ''))}
                  </span>
                </Link>
              </Menu.Item>
            ))
            .value()}
        </div>
      </Menu>
    ),
    [menuItems, isNavItemProject],
  );

  if (children || isNavItemProject) {
    return (
      <div className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
        <Dropdown
          overlay={menu}
          overlayClassName={dropdownStyles.TopNavbarDropdown}
          placement="bottomLeft"
          mouseEnterDelay={0}
          mouseLeaveDelay={0.05}
        >
          <Button type="text" onClick={() => isNavItemProject && onClick(item.id)}>
            {IconComponent && <IconComponent className={styles.navIcon} />}
            <span>{text}</span>
            <DownOutlined className={styles.arrow} />
          </Button>
        </Dropdown>
      </div>
    );
  }

  return (
    <div className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
      <Link to={item.route} className={cx(styles.NavItem, { [styles.selected]: item.selected })}>
        {IconComponent && <IconComponent className={styles.navIcon} />}
        <span>{text}</span>
        {isNumber(item.badge) && item.badge !== 0 && (
          <div className={styles.badge}>{item.badge}</div>
        )}
      </Link>
    </div>
  );
};
