/* eslint-disable @typescript-eslint/no-explicit-any */
// import 'default-passive-events';

import * as React from 'react';
import cx from 'classnames';
import memoize from 'memoize-one';
import {
 debounce, isInteger, isEmpty, times, min, clone, map,
} from 'lodash';

import styles from './MasonryGrid.scss';

interface IPosition {
  top: number;
  left: number;
}
export interface IMasonryItemProps {
  key: string | number;

  onItemHeightChanged?(height: number);
}
interface IProps<T> {
  columnWidth: number;
  itemComponent: React.ComponentClass<IMasonryItemProps>;
  itemProps: (IMasonryItemProps | T)[];

  onReachBottom?();

  calculateHeight?: boolean;
  checkLayoutCapacity?: boolean;
  defaultItemHeight?: number;
  bufferHeight?: number;
  className?: string;
}
type TDefaultProp = 'onReachBottom' | 'calculateHeight' | 'defaultItemHeight' | 'bufferHeight' | 'className' | 'checkLayoutCapacity';

interface IState {
  columns: number;
  viewableHeight: number;

  itemProps: IMasonryItemProps[];
  itemRefs: Array<React.RefObject<HTMLDivElement>>;
  itemHeights: number[];
}

/**
 * Comparing current item props and previous item props,
 * returns the first index which two props are not equal.
 *
 * @param {IMasonryItemProps[]} prevItemProps previous item props.
 * @param {IMasonryItemProps[]} currItemProps current item props.
 *
 * @return {Number}
 */
const firstUnequalPropIndex = (
  prevItemProps: IMasonryItemProps[],
  currItemProps: IMasonryItemProps[],
) => {
  // if any of the props is null/undefined, return 0
  if (!currItemProps || !prevItemProps) {
    return 0;
  }

  // finds the first index which two props are not equal
  for (let index = 0; index < prevItemProps.length; index++) {
    if (!currItemProps[index] || prevItemProps[index].key !== currItemProps[index].key) {
      return index;
    }
  }

  // currItemProps.length >= prevItemProps.length
  // and prevItemProps === currItemProps[...prevItemProps.length]
  return prevItemProps.length;
};

/**
 * @class
 * This grid assumes the scroll anchor is window.
 * TODO: also handle cases where scroll anchor is not window.
 *
 * @extends {React.Component}
 */
export class MasonryGrid<T = any> extends React.PureComponent<IProps<T>, IState> {
  public static defaultProps: Pick<any, TDefaultProp> = {
    onReachBottom: () => undefined,

    calculateHeight: true,
    checkLayoutCapacity: true,
    bufferHeight: 1200,
    defaultItemHeight: 300,
    className: '',
  };

  private ref: React.RefObject<HTMLDivElement>;

  private paginationRef: React.RefObject<HTMLDivElement>;

  // flag for request updating item heights
  private updateItemHeightsRequested: boolean;

  private queuedItemHeights: {
    [index: number]: number;
  };

  // flag for request update viewableTop on scroll
  private onScrollUpdateRequested: boolean;

  // flag for request update viewableHeight and columns on window resize
  private onResizeUpdateRequested: boolean;

  private queuedViewableHeight: number;

  private queuedColumns: number;

  /**
   * @inheritDoc
   */
  constructor(props: any) {
    super(props);

    if (!isInteger(props.columnWidth) || props.columnWidth <= 0) {
      throw new Error(
        `Expect 'columnWidth' to be positive integer, but got '${props.columnWidth}'`,
      );
    }

    this.ref = React.createRef();
    this.paginationRef = React.createRef();

    // item heights change
    this.updateItemHeightsRequested = false;
    this.queuedItemHeights = {};
    // window scroll
    this.onScrollUpdateRequested = false;
    // window resize
    this.onResizeUpdateRequested = false;
    this.queuedColumns = 1;
    this.queuedViewableHeight = 960;

    this.state = {
      columns: 1,
      viewableHeight: 960,

      itemProps: [],
      itemRefs: [],
      itemHeights: [],
    };
  }

  /**
   * @inheritDoc
   */
  public componentDidMount() {
    window.addEventListener('resize', debounce(this.onWindowResize, 200), { passive: true });
    window.addEventListener('scroll', this.onScroll, { capture: true, passive: true });

    // trigger a window resize to calculate the columns
    this.onWindowResize();

    setTimeout(() => {
      this.onWindowResize();
    }, 500);
  }

  /**
   * @inheritDoc
   */
  public UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('scroll', this.onScroll);
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: any, prevState: IState) {
    const { itemProps, defaultItemHeight } = nextProps;
    const {
      itemProps: prevItemProps,
      itemHeights: prevItemHeights,
      itemRefs: prevItemRefs,
    } = prevState;

    // calculates the first unequal index between previous props and current props
    const firstUnequalIndex = firstUnequalPropIndex(prevItemProps, itemProps);
    // re-initialize if needed.
    if (itemProps.length === 0 || firstUnequalIndex !== itemProps.length) {
      // avoid re-calculating existing items' positions
      const itemRefs = [...prevItemRefs.slice(0, firstUnequalIndex)];
      const itemHeights = [...prevItemHeights.slice(0, firstUnequalIndex)];

      // add additional refs if needed
      times(itemProps.length - firstUnequalIndex, () => {
        itemHeights.push(defaultItemHeight);
        itemRefs.push(React.createRef());
      });

      return {
        itemProps,
        itemRefs,
        itemHeights,
      };
    }

    return null;
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      className,
      columnWidth,
      itemProps,
      itemComponent: Item,
      calculateHeight,
      checkLayoutCapacity,
    } = this.props;
    const { columns, itemHeights, itemRefs } = this.state;
    const { itemPositions, containerHeight } = this.calculateLayout(
      columnWidth,
      columns,
      itemHeights,
    );
    const node = this.ref.current;
    let offsetTop;
    if (node) {
      offsetTop = node.getBoundingClientRect().top;
    } else {
      offsetTop = 0;
    }

    return (
      <div
        ref={this.ref}
        className={cx(className, styles.MasonryGrid)}
        style={calculateHeight ? { height: `${containerHeight}px` } : undefined}
      >
        {itemProps.map((props, index) => {
          const itemPosition = itemPositions[index];
          const itemHeight = itemHeights[index];

          if (checkLayoutCapacity && !this.isItemInView(itemPosition, offsetTop)) {
            return null;
          }

          return (
            <div
              key={index}
              className={styles.item}
              style={{
                width: `${columnWidth}px`,
                height: `${itemHeight}px`,
                transform: `translate(${itemPosition.left}px, ${itemPosition.top}px)`,
              }}
              ref={itemRefs[index]}
            >
              <Item {...props as any} onItemHeightChanged={this.onItemHeightChanged.bind(this, index)} />
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * Calculates the item positions and container height.
   * All we need to know is the width of the column,
   * the number of columns and the height of each item.
   *
   * @param {Number} columnWidth the column width.
   * @param {Number} columns number of columns.
   * @param {Number[]} itemHeights height of each item.
   *
   * @return { itemPositions: IPosition[], containerHeight: number }
   */
  private calculateLayout = memoize((columnWidth: number, columns: number, itemHeights: number[]): {
    itemPositions: IPosition[];
    containerHeight: number;
  } => {
    const columnHeights = times(columns, () => 0);
    const itemPositions: IPosition[] = [];
    let containerHeight = 0;

    itemHeights.forEach((itemHeight, index) => {
      let column;
      if (index <= itemHeights.length - 5) {
        // assign column based on index, results in uneven columns height
        // but prevents contents from jumping around
        column = index % columns;
      } else {
        // use the last 5 items to fill the column with lowest height
        column = columnHeights.indexOf(min(columnHeights));
      }

      itemPositions[index] = {
        top: columnHeights[column],
        left: column * columnWidth,
      };

      columnHeights[column] += itemHeight;
      containerHeight = Math.max(containerHeight, columnHeights[column]);
    });

    if (this.paginationRef && this.paginationRef.current) {
      containerHeight += this.paginationRef.current.clientHeight;
    }

    return {
      itemPositions,
      containerHeight,
    };
  });

  /**
   * @private
   * Checks if an item is in view.
   *
   * @param {IPosition} position the item's position.
   * @param {Number} containerTop the container's offset top.
   *
   * @return {Boolean}
   */
  private isItemInView(position: IPosition, containerTop: number) {
    const { bufferHeight } = this.props;
    const { viewableHeight } = this.state;

    const viewableStart = -containerTop - bufferHeight;
    const viewableStop = -containerTop + viewableHeight + bufferHeight;

    return viewableStart <= position.top && position.top <= viewableStop;
  }

  /**
   * @private
   * Handler for window's scroll event.
   * Request update state using requestAnimationFrame.
   */
  private onScroll = () => {
    if (!this.isComponentMounted()) {
      return;
    }

    // skip if onScroll update is already requested
    if (this.onScrollUpdateRequested) {
      return;
    }

    this.onScrollUpdateRequested = true;
    window.requestAnimationFrame(this.onScrollUpdate);
  };

  /**
   * @private
   * Handles the update for 'scroll' event.
   */
  private onScrollUpdate = () => {
    if (!this.isComponentMounted()) {
      return;
    }

    const node = this.ref.current;
    const { onReachBottom } = this.props;
    const { viewableHeight } = this.state;
    const { scrollHeight } = node;
    const { top } = node.getBoundingClientRect();

    // notify parent when reaching bottom
    // potentially load more items
    if (-top + viewableHeight >= scrollHeight) {
      onReachBottom();
    }

    // re-render since offset top changed
    this.forceUpdate();

    // unset the flag
    this.onScrollUpdateRequested = false;
  };

  /**
   * @private
   * Throttled handler for window's resize event.
   * Calculates new column count and viewable height.
   */
  private onWindowResize = () => {
    if (!this.isComponentMounted()) {
      return;
    }

    const { columnWidth } = this.props;

    const gridNode = this.ref.current;
    const newColumns = Math.max(1, Math.floor(gridNode.offsetWidth / columnWidth));
    // let's assume the scroll anchor is window for now
    // will rewrite this part when it's no longer the case
    const newViewableHeight = window.innerHeight;

    // save columns and viewableHeight
    this.queuedColumns = newColumns;
    this.queuedViewableHeight = newViewableHeight;

    if (this.onResizeUpdateRequested) {
      return;
    }

    this.onResizeUpdateRequested = true;
    window.requestAnimationFrame(this.onWindowResizeUpdate);
  };

  /**
   * @private
   * Handles the update for 'resize' event.
   */
  private onWindowResizeUpdate = () => {
    if (!this.isComponentMounted()) {
      return;
    }

    const { columns, viewableHeight } = this.state;

    if (this.queuedColumns !== columns || this.queuedViewableHeight !== viewableHeight) {
      this.setState({
        columns: this.queuedColumns,
        viewableHeight: this.queuedViewableHeight,
      });
    }

    // reset flag
    this.onResizeUpdateRequested = false;
  };

  /**
   * @private
   * When an item's height changed, enqueues the new item height.
   * And requests an update.
   *
   * @param {Number} index the item's index.
   * @param {Number} height the item's height.
   */
  private onItemHeightChanged = (index: number, height: number) => {
    this.queuedItemHeights[index] = Math.ceil(height);

    this.requestUpdateItemHeights();
  };

  /**
   * @private
   * Requests an update with queued item heights.
   */
  private requestUpdateItemHeights = () => {
    if (this.updateItemHeightsRequested) {
      return;
    }

    this.updateItemHeightsRequested = true;
    const queuedItemHeights = clone(this.queuedItemHeights);
    this.queuedItemHeights = {};
    window.requestAnimationFrame(() => {
      this.bulkUpdateItemHeights(queuedItemHeights)
        .then(() => {
          this.updateItemHeightsRequested = false;

          // it could be possible that new item heights are queued
          // while an update is in progress
          // if so, request another update immediately
          if (!isEmpty(this.queuedItemHeights)) {
            this.requestUpdateItemHeights();
          }
        })
        .catch(() => {
          this.updateItemHeightsRequested = false;
        });
    });
  };

  /**
   * @private
   * Bulk updates queued item heights.
   *
   * @param {Object} queuedItemHeights the queued item heights.
   *
   * @return {Promise}
   */
  private bulkUpdateItemHeights = (queuedItemHeights) =>
    new Promise((resolve, reject) => {
      if (!this.isComponentMounted()) {
        return reject();
      }
      const { itemHeights } = this.state;

      // since we're using memoize to avoid unnecessary calculation
      // need to create a new copy of itemHeights to bypass memoize
      const newItemHeights = clone(itemHeights);
      map(queuedItemHeights, (height, index) => {
        if (newItemHeights[index] && newItemHeights[index] !== height) {
          newItemHeights[index] = height;
        }
      });

      this.setState(
        {
          itemHeights: newItemHeights,
        },
        () => {
          resolve(true);
        },
      );
    });

  /**
   * @private
   * Substitute for isMounted() method.
   * Checks if the grid is still mounted.
   *
   * @return {Boolean}
   */
  private isComponentMounted = () => {
    const node = this.ref.current;

    return node !== null;
  };
}
