/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10l1s_157 {
  display: flex;
}

._justify-content-space-between_10l1s_161 {
  justify-content: space-between;
}

._tabular-nums_10l1s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10l1s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_10l1s_178 ._header_10l1s_178 ._title_10l1s_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._TMVSection_10l1s_188 ._content_10l1s_178 ._more_10l1s_188, ._content_10l1s_178 ._header_10l1s_178 ._title_10l1s_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_10l1s_157 {
  display: flex;
}

._justify-content-space-between_10l1s_161 {
  justify-content: space-between;
}

._tabular-nums_10l1s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_10l1s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_10l1s_178 ._header_10l1s_178 ._title_10l1s_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._TMVSection_10l1s_188 ._content_10l1s_178 ._more_10l1s_188, ._content_10l1s_178 ._header_10l1s_178 ._title_10l1s_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._content_10l1s_178 {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
._content_10l1s_178 ._header_10l1s_178 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._content_10l1s_178 ._header_10l1s_178 ._title_10l1s_178 {
  display: flex;
  flex-direction: column;
}
._content_10l1s_178 ._header_10l1s_178 ._title_10l1s_178 h3 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  color: #2e426d;
}
._content_10l1s_178 ._header_10l1s_178 ._title_10l1s_178 h4 {
  margin: 0 0 0.5rem 0.5rem;
  color: #8f8d91;
}
._content_10l1s_178 ._header_10l1s_178 ._select_10l1s_451 {
  margin-left: auto;
}

._TMVSection_10l1s_188 ._content_10l1s_178 ._header_10l1s_178 ._select_10l1s_451 {
  margin-left: auto;
}
._TMVSection_10l1s_188 ._content_10l1s_178 ._main_10l1s_458 {
  margin: auto;
}
._TMVSection_10l1s_188 ._content_10l1s_178 ._main_10l1s_458 ._view_10l1s_461 > div > svg {
  margin-right: 2rem;
}
._TMVSection_10l1s_188 ._content_10l1s_178 ._more_10l1s_188 {
  flex-shrink: 0;
  text-align: center;
}
._TMVSection_10l1s_188 ._content_10l1s_178 ._more_10l1s_188 a {
  color: #3996e0;
  text-decoration: none;
  will-change: color;
  transition: color 0.1s ease-out;
}
._TMVSection_10l1s_188 ._content_10l1s_178 ._more_10l1s_188 a:hover {
  color: #4fa7ee;
}