/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1muwd_157 {
  display: flex;
}

._justify-content-space-between_1muwd_161 {
  justify-content: space-between;
}

._tabular-nums_1muwd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1muwd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._headerLeftSection_1muwd_178 {
  display: flex;
  align-items: center;
  margin: 0.75rem 1rem;
}

._header_1muwd_178 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 2rem;
  height: 4rem;
  padding-left: 1rem;
  border-bottom: 1px solid #D9D9D9;
}

._logoTitle_1muwd_195 {
  display: flex;
  gap: 1rem;
  align-items: center;
  height: 3rem;
}

._logo_1muwd_195 {
  height: 2.5rem;
  width: 2.5rem;
}

._backLink_1muwd_207 {
  height: 2.5rem;
  width: 2.5rem;
}