import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_COLLECTION_DETAILS } from '@frontend/applications/ProductFulfillmentApp/queries/collectionDetails';
import { GetCollectionDetails, GetCollectionDetailsVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetCollectionDetails';

type IOptions = QueryHookOptions<GetCollectionDetails, GetCollectionDetailsVariables>;

export const useGetCollectionDetails = (options: IOptions) => {
  const {
    loading, data, error, refetch,
   } = useQuery<GetCollectionDetails, GetCollectionDetailsVariables>(GET_COLLECTION_DETAILS, options);

  return {
    loading,
    collectionDetails: data?.getCollectionByCollectionId ?? {} as GetCollectionDetails['getCollectionByCollectionId'],
    error,
    refetch,
  };
};
