import { IMemberSearchQuery } from '@services/members';

import { ISelectedColumns } from './SelectedColumns';

export interface ISegmentInput {
  id?: number;
  title?: string;
  folderId?: number;
  filter?: IMemberSearchQuery;
  columns?: ISelectedColumns;
  membersCount?: number;
}
