import * as React from 'react';

import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';
import { IBulkActionParameters, IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import MemberDataUpdateAppModal from './MemberDataUpdateAppModal';

const { useEffect } = React;

interface IProps {
  clientId: string;
  clientName: string;
  memberId?: string;
  bulkActionParameters?: IBulkActionParameters;
  workflowActionParameters?: IWorkflowActionParameters;
  closeModal?(): void;
}

const MemberDataUpdateApp: React.FunctionComponent<IProps> = (props) => {
  // const { modalView } = props;
  const addEvent = useEventContext();
  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'memberdataupdate' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={props.clientId}
      clientName={props.clientName}
      memberId={props.memberId}
      bulkActionParameters={props.bulkActionParameters}
      workflowActionParameters={props.workflowActionParameters}
      closeModal={props.closeModal}
    >
      <div style={{ height: '100%' }}>
        {/* {modalView ? (<MemberDataUpdateAppModal />) : (<MemberDataUpdateAppDashboard />)} */}
        <MemberDataUpdateAppModal />
      </div>
    </ApplicationProvider>
  );
};

export default MemberDataUpdateApp;
