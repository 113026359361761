import * as React from 'react';
import cn from 'classnames';

import {
  Typography, Divider, Space, Badge,
} from '@revfluence/fresh';
import { ScaleBalancedIcon } from '@revfluence/fresh-icons/regular/esm';

import Statistics from '../../../../../components/Statistics';

import styles from '../TabContent.module.scss';
import { IInvestmentValues, IReportingPageProps } from '../../../Reporting';
import { InvestmentRow } from '../InvestmentRow';

const { Title, Text } = Typography;

interface ISalesProps {
  salesValue: IReportingPageProps['salesValues'];
  investmentValue: IInvestmentValues;
  salesTotalValue: number;
}

const Sales = ({
  salesValue,
  investmentValue,
  salesTotalValue,
}: ISalesProps) => (
  <Space direction="vertical" className={cn(styles.TabContent)} size="small">
    <Title level={4}>Sales Value</Title>
    <Title level={5}>
      <ScaleBalancedIcon style={{ marginRight: '8px' }} />
      How it's calculated
    </Title>
    <Divider />
    <Space direction="vertical" size={13} style={{ width: '100%' }}>
      <div>
        <Text type="secondary" weight="semibold">
          Promo code sales
        </Text>
        <Space>
          <Text type="secondary">
            Conversions (
            {salesValue.promos.conversions.value}
            )
          </Text>
          <Statistics value={salesValue.promos.sales.value.toFixed(2)} prefix="$" size="default" />
        </Space>
      </div>

      <div>
        <Text type="secondary" weight="semibold">
          Link sales
        </Text>
        <Space>
          <Text type="secondary">
            Conversions (
            {salesValue.links.conversions.value}
            )
          </Text>
          <Statistics value={salesValue.links.sales.value.toFixed(2)} prefix="$" size="default" />
        </Space>
      </div>
    </Space>

    <Divider />
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.green)} />
        Sales Total
      </Text>
      <Statistics value={salesTotalValue.toFixed(2)} prefix="$" size="default" />
    </Space>
    <Divider />
    <InvestmentRow values={investmentValue} />
    <Divider />
    <Space>
      <Title level={5}>
        Sales Net Amount
      </Title>
      <Title level={5}>
        <Statistics value={(salesTotalValue - investmentValue.total).toFixed(2)} prefix="$" size="large" strong />
      </Title>
    </Space>
  </Space>
);

export default Sales;
