import * as React from 'react';

import { OrderedColumnInput } from '@frontend/app/types/globalTypes';
import { IColumnVisibility } from '@frontend/app/types/Columns';
import { columnsInputFromVisibility } from '@frontend/app/utils/columns';

const { useMemo } = React;

export const useColumnsInput = (
  columnVisibility: IColumnVisibility,
  orderedColumnInput: OrderedColumnInput[],
) => useMemo(() => columnsInputFromVisibility(columnVisibility, orderedColumnInput), [columnVisibility, orderedColumnInput]);
