/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ipi5_157 {
  display: flex;
}

._justify-content-space-between_1ipi5_161 {
  justify-content: space-between;
}

._tabular-nums_1ipi5_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ipi5_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmailEditor_1ipi5_178 {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}
._EmailEditor_1ipi5_178 ._editor_1ipi5_184 {
  height: 100%;
}
._EmailEditor_1ipi5_178 ._actions_1ipi5_187 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.75rem;
  padding: 0 1.125rem;
  border-top: 0.0625rem solid #dadcde;
}
._EmailEditor_1ipi5_178 ._actions_1ipi5_187 ._variableReplacement_1ipi5_195 {
  margin-right: 0.5rem;
}
._EmailEditor_1ipi5_178 ._actions_1ipi5_187 ._additionalActions_1ipi5_198 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}
._EmailEditor_1ipi5_178 ._actions_1ipi5_187 ._additionalActions_1ipi5_198._disabled_1ipi5_204 {
  filter: opacity(0.5);
  cursor: not-allowed;
}
._EmailEditor_1ipi5_178 ._actions_1ipi5_187 ._additionalActions_1ipi5_198._disabled_1ipi5_204 ._buttons_1ipi5_208 {
  pointer-events: none;
}