import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { ADD_SPECIFICATION } from '../queries';
import {
  AddSpecificationMutation,
  AddSpecificationMutationVariables,
} from '../queries/types/AddSpecificationMutation';

type IOptions = MutationHookOptions<
  AddSpecificationMutation,
  AddSpecificationMutationVariables
>;

export const useAddSpecificationMutation = (options: IOptions = {}) => (
  useMutation<AddSpecificationMutation, AddSpecificationMutationVariables>(
    ADD_SPECIFICATION,
    options,
  )
);
