import * as React from 'react';

import {
  TNetworkIdentifier,
  SocialProfileOverlay,
  ISocialAccount,
 } from '@components';
import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import {
  DetailedSocialAccountCaller,
  useDetailedSocialAccount,
} from '@frontend/components/widgets/SocialProfile/hooks/useDetailedSocialAccount';

interface IProps {
  id: number;
  username: string;
  clientId: string;
  network: TNetworkIdentifier;
  onRequestClose: () => void;
}

export const SocialMemberProfile: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    id,
    username,
    clientId,
    onRequestClose,
  } = props;

  const {
    detailedSocialAccount,
  } = useDetailedSocialAccount({
    apiEndpoint: backendServerApiEndpoint(),
    id,
    brandInstagramUsername: username,
    clientId,
    caller: DetailedSocialAccountCaller.CREATOR_SEARCH,
  });

  return (
    <SocialProfileOverlay
      apiEndpoint={backendServerApiEndpoint()}
      show
      socialAccount={detailedSocialAccount as ISocialAccount}
      hideActions
      showCreateFeatures={false}
      showSimilarCreators={false}
      onRequestClose={onRequestClose}
      enablePagination={false}
    />
  );
});
