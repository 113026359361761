export enum CONTENT_REVIEW_STATE {
  CONTENT_REVIEW_STATE_WAITING_FOR_SUBMISSION = 'WAITING_FOR_SUBMISSION',
  CONTENT_REVIEW_STATE_PLACEHOLDER = 'PLACEHOLDER',
  CONTENT_REVIEW_STATE_NEW = 'NEW',
  CONTENT_REVIEW_STATE_REJECTED = 'REJECTED',
  CONTENT_REVIEW_STATE_AMENDED = 'AMENDED',
  CONTENT_REVIEW_STATE_APPROVED = 'ACCEPTED',
  CONTENT_REVIEW_STATE_UPLOADED = 'UPLOADED',
  CONTENT_REVIEW_STATE_VALIDATING_UPLOAD = 'VALIDATING_UPLOAD',
  CONTENT_REVIEW_STATE_COMPLETED = 'COMPLETED',
  CONTENT_REVIEW_STATE_COMPLETED_WITHOUT_PAYMENT = 'COMPLETED_WITHOUT_PAYMENT',
}
export type TContentReviewTagColor = 'gray' | 'blue' | 'green' | 'red';
