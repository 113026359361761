import React, { useEffect } from 'react';
import { useTaskTrackerQuery } from '@frontend/app/hooks';
import SyncProgressComponent from '../components/SyncProgressComponent';

const POLLING_INTERVAL = 5000;

interface IProps {
  taskTrackerId: string;
  onSyncStatusChange?: (isSyncing: boolean) => void;
}

const SyncProgressContainer: React.FC<IProps> = ({ taskTrackerId, onSyncStatusChange }) => {
  const { data: taskData, startPolling, stopPolling } = useTaskTrackerQuery({
    variables: { id: taskTrackerId },
    skip: !taskTrackerId,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const progressPercentage = taskData?.tracker.taskProgress.progressPercentage;

    if (progressPercentage != null && progressPercentage !== 100) {
      startPolling(POLLING_INTERVAL);
      onSyncStatusChange(true);
    } else if (progressPercentage === 100) {
      stopPolling();
      onSyncStatusChange(false);
    }
  }, [taskData, onSyncStatusChange, startPolling, stopPolling]);

  return (
    <SyncProgressComponent
      lastSynced={taskData?.tracker.createdDate}
      progressPercentage={Math.floor(taskData?.tracker.taskProgress.progressPercentage || 0)}
      successCount={taskData?.tracker.taskProgress.details.success}
      failureCount={taskData?.tracker.taskProgress.details.fail}
    />
  );
};

SyncProgressContainer.displayName = 'SyncProgressContainer';

export default SyncProgressContainer;
