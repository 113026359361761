import * as React from 'react';
import { Tag } from '@revfluence/fresh';
import { CircleCheckIcon } from '@revfluence/fresh-icons/regular/esm';

interface ISuccessTagProps {
  className?: string;
}

export const SuccessTag = (props: ISuccessTagProps) => {
  const { className } = props;
  return (
    <Tag color="success" icon={<CircleCheckIcon />} className={className}>Active</Tag>
  );
};
