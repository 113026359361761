import * as React from 'react';

import cx from 'classnames';
import { Notice } from '@components';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { ISocialAccount } from '../useFetchSocialAccountData';

interface IProps {
  account: ISocialAccount;
  oauthEndpoint: string;
}

import styles from './SocialAccountCell.scss';

export const SocialAccountCell: React.FunctionComponent<IProps> = (props) => {
  const { account, oauthEndpoint } = props;
  const addEvent = useEventContext();

  return (
    <div className={cx(styles.SocialAccountCell)}>
      {account.has_social_listening_api_access
        ? <span className={styles.AccountUsername}>{ account.username }</span>
        : (
          <>
            <span className={styles.AccountUsername}>{ account.username }</span>
            <Notice type="alert">
              There was an error fetching information from your @
              {account.username}
              {' '}
              account. Please try
              linking the Facebook Page that is associated with the @
              {account.username}
              {' '}
              Instagram account again.
              <a
                href={oauthEndpoint}
                onClick={() => {
                addEvent(EventName.OAuthGrantStart, { app: 'social_post' });
                window?.localStorage?.removeItem('ig_connect_success_track');
              }}
              >
                Link @
                {account.username}
                {' '}
                now
              </a>
            </Notice>
          </>
)}
    </div>
  );
};
