import * as React from 'react';
import moment from 'moment';
import { Button, SubmitButton } from '@components';
import { IContract } from '../useFetchContracts';
import { ContractDownload } from './ContractDownload';

import styles from './ContractCell.scss';

const signIcon = require('@frontend/app/assets/svgs/sign.svg');

interface IProps {
  contract: IContract;
  onEditContract(contract: IContract): void;
  onCancelContract(contract: IContract): void;
}

export const ContractCell: React.FunctionComponent<IProps> = (props) => {
  const { contract } = props;

  const editContract = () => {
    props.onEditContract(contract);
  };

  const cancelContract = () => {
    props.onCancelContract(contract);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buttonParams: any = {};
  return (
    <li className={styles.ContractCell}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <div className={(styles as any).contractItem}>
        <div className={styles.contractItemHead}>
          <div className={styles.textWrap}>
            <span className={styles.contractName}>{contract.title}</span>
            <span className={styles.dateCreated}>
              Created
              {moment(contract.created_ts * 1000).format('ll')}
            </span>
            <ContractDownload className="rightAlign" contractId={contract.id} />
          </div>
        </div>

        <div className={styles.signed_status}>
          <span className={styles.status_txt}>
            <img src={signIcon} className={styles.signIcon} />
            {{
              executed: <span>Signed by all parties</span>,
              needs_signature: <span>Waiting for signatures...</span>,
              signed: <span>Waiting for others to sign</span>,
              pending: <span>Contract Not Sent</span>,
            }[contract.status]}
          </span>
        </div>
        <div className={styles.actionsContainer}>
          <Button
            className={styles.actionButton}
            label="Edit"
            onClick={editContract}
            theme="light"
          />
          <SubmitButton
            {...buttonParams}
            className={styles.actionButton}
            label="Cancel"
            onClick={cancelContract}
            theme="danger"
          />
        </div>
      </div>
    </li>
  );
};
