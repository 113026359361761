import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { SAVE_PROJECT_CAMPAIGN_MUTATION } from '@frontend/app/queries/SaveProjectCampaignMutation';
import {
  SaveProjectCampaignMutation,
  SaveProjectCampaignMutationVariables,
} from '@frontend/app/queries/types/SaveProjectCampaignMutation';

type IOptions = MutationHookOptions<SaveProjectCampaignMutation, SaveProjectCampaignMutationVariables>;

export const useSaveProjectCampaign = (options: IOptions = {}) => (
  useMutation<SaveProjectCampaignMutation, SaveProjectCampaignMutationVariables>(
    SAVE_PROJECT_CAMPAIGN_MUTATION,
    options,
  )
);
