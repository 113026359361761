import * as React from 'react';
import { isFinite, isNull } from 'lodash';

import {
  Col, Row, Space, useBreakpoint,
} from '@revfluence/fresh';
import { formatters, defaultPrecision } from '@frontend/app/components/StatisticsCard/formatters';
import { TrendProps } from '@frontend/applications/ReportsApp/components/Trend/Trend.types';

import Trend from '../../../../components/Trend';
import Statistics from '../../../../components/Statistics';

import { TypeStatistics } from './MetricsSecondary.types';
import { IMetric, Trends } from '../../Reporting';
import styles from './MetricsSecondary.scss';

export type IMetricsProps = {
  trends: Trends;
  igStoryEstimationEnabled?: boolean;
  showTrend?: boolean;
  tempYoutubeDemoAccountMode?: boolean;
};

type Keys = keyof Trends;

const TrendLabels: { [key in Keys]: string } = {
  content: 'Content',
  conversions: 'Conversions',
  cpe: 'CPE',
  cpm: 'CPM',
  creators: 'Creators',
  engagements: 'Engagements',
  impressions: 'Impressions',
  reach: 'Pot. Reach',
  tmv: 'TMV',
  views: 'Views',
  posts: 'Posts',
  sales: 'Sales',
};

export const getTrendTooltips = (igStoryEstimationEnabled: boolean, tempYoutubeDemoAccountMode?: boolean): { [key in Keys]: string } => ({
  content: 'Total unique pieces of content, like a single image or video.',
  conversions: 'Total number of conversions attributed to a promo code or sales link.',
  cpe: 'Cost per engagement. (Investment / Engagements)',
  cpm: 'Cost per thousand impressions. [Investment / (Impressions / 1000)]',
  creators: 'Total number of creators that accounted for your social data.',
  engagements: 'Total likes, comments, shares, views (YouTube), and clicks/closeups/saves (Pinterest).',
  impressions: igStoryEstimationEnabled ? 'Total impressions and estimated impressions.' : 'Total impressions.',
  reach: tempYoutubeDemoAccountMode ? 'Total combined subscribers for creators of posts at the time of posting.' : 'Total combined followers for creators of posts at the time of posting.',
  tmv: 'An estimate of what an equivalent advertising campaign would cost based on the social output.',
  views: 'Total views.',
  posts: 'Total number of posts that accounted for your social data.',
  sales: 'Total sales generated via promo codes or sales tracking links.',
});

const DollarTrends: Keys[] = ['cpe', 'cpm', 'tmv'];
const InverseTrends: Keys[] = ['cpe', 'cpm'];
const HighPrecisionTrends: Keys[] = ['cpe', 'cpm', 'tmv'];

export const getTrendProps = (value: number | null, inverse = false): TrendProps => {
  const zeroTrend = value === 0 || isNull(value);
  const positiveTrend = value > 0;
  if (inverse) {
    return {
      direction: (!positiveTrend || zeroTrend) ? 'down' : 'up',
      color: zeroTrend ? 'gray' : positiveTrend ? 'red' : 'green',
    };
  } else {
    return {
      direction: (positiveTrend || zeroTrend) ? 'up' : 'down',
      color: zeroTrend ? 'gray' : positiveTrend ? 'green' : 'red',
    };
  }
};

export const mapMetric = (key: Keys, metric: IMetric, igStoryEstimationEnabled: boolean, tempYoutubeDemoAccountMode?: boolean): TypeStatistics => {
  const isNull = !isFinite(metric.value) || metric.value === 0;
  const precision = HighPrecisionTrends.includes(key) ? 4 : defaultPrecision;
  return {
    id: key,
    statisticProps: {
      title: TrendLabels[key],
      valueRender: () => (!isNull ? formatters.default(metric.value, { precision }) : '-'),
      size: 'large',
      prefix: !isNull && DollarTrends.includes(key) ? '$' : null,
      tooltip: getTrendTooltips(igStoryEstimationEnabled, tempYoutubeDemoAccountMode)[key],
    },
    trendProps: getTrendProps(metric.percentDiff, InverseTrends.includes(key)),
  };
};

const mapTrends = (props: Trends, igStoryEstimationEnabled: boolean, tempYoutubeDemoAccountMode?: boolean): TypeStatistics[] => {
  const metrics = ['content', 'impressions', 'engagements', 'posts', 'creators',
    'reach', 'conversions', 'cpm', 'cpe', 'tmv'] as Keys[];
  return metrics.map((metric) => mapMetric(metric, props[metric], igStoryEstimationEnabled, tempYoutubeDemoAccountMode));
};

interface IMetricsSecondaryLayoutProps {
  StatisticsComponents: React.ReactNode[];
}

export const MetricsSecondaryLayout = ({ StatisticsComponents }: IMetricsSecondaryLayoutProps) => {
  const screens = useBreakpoint();

  if (screens.xl) {
    return (
      <Row gutter={[24, 24]}>
        {StatisticsComponents}
      </Row>
    );
  }
  if (screens.sm) {
    return (
      <Space direction="vertical" size={24} style={{ width: '100%' }}>
        <Row gutter={[24, 24]}>
          {StatisticsComponents.slice(0, 5)}
        </Row>
        <Row gutter={[24, 24]}>
          {StatisticsComponents.slice(-5)}
        </Row>
      </Space>
    );
  }
  return (
    <Space direction="vertical" size={24} style={{ width: '100%' }}>
      <Row gutter={[24, 24]}>
        {StatisticsComponents.slice(0, 3)}
      </Row>
      <Row gutter={[24, 24]}>
        {StatisticsComponents.slice(3, 6)}
      </Row>
      <Row gutter={[24, 24]}>
        {StatisticsComponents.slice(-4)}
      </Row>
    </Space>
  );
};

const MetricsSecondary = (props: IMetricsProps) => {
  const mappedProps = mapTrends(props.trends, props.igStoryEstimationEnabled, props.tempYoutubeDemoAccountMode);

  const StatisticsComponents = mappedProps.map((item) => {
    const Suffix = props.showTrend ? <Trend {...item.trendProps} /> : null;
    return (
      <Col flex={1} key={item.id}>
        <Statistics className={styles.statistic} {...item.statisticProps} suffix={Suffix} padded />
      </Col>
    );
  });
  return (
    <MetricsSecondaryLayout StatisticsComponents={StatisticsComponents} />
  );
};

export default MetricsSecondary;
