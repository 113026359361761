import * as React from 'react';
import { merge } from 'lodash';

const { useCallback, useEffect, useState } = React;

/**
 * Checks if element is visible in viewport.
 *
 * @return {Boolean}
 */
export const useElementVisible = (
  ref: React.MutableRefObject<HTMLElement>,
  options: IntersectionObserverInit = {},
): boolean => {
  const [visible, setVisible] = useState(false);

  /**
   * Callback for IntersectionObserver.
   *
   * @param {IntersectionObserverEntry} entries the intersection observer entries.
   */
  const intersectionCallback = useCallback((entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const node = ref.current;

    if (entry && node) {
      if (entry.isIntersecting) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [ref]);

  useEffect(() => {
    const node = ref && ref.current;
    if (!node || typeof IntersectionObserver === 'undefined') {
      setVisible(true);

      return;
    }

    const observer = new IntersectionObserver(
      intersectionCallback,
      merge(
        {
          root: null,
          rootMargin: '0px',
          threshold: [0],
        },
        options,
      ),
    );
    observer.observe(node);

    return () => {
      observer.disconnect();
    };
  }, [ref, intersectionCallback, options]);

  return visible;
};
