import * as React from 'react';
import { Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import cx from 'classnames';

import { Button } from '@affiliates/AspireUI';

import styles from './TableHeader.scss';

interface IProps extends ButtonProps {
  icon: React.ReactNode;
  title: string;
}

export const TableHeaderButton: React.FC<Readonly<IProps>> = (props) => {
  const {
    className,
    disabled,
    icon,
    onClick,
    title,
  } = props;

  const wrapperClassName = cx(
    styles.buttonWrapper,
    { [styles.disabled]: disabled },
  );

  return (
    <Tooltip placement="top" title={title}>
      <span className={wrapperClassName}>
        <Button
          className={className}
          disabled={disabled}
          icon={icon}
          onClick={onClick}
          title={title}
          type="default"
        />
      </span>
    </Tooltip>
  );
};
