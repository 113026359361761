import * as React from 'react';
import { Alert } from '@revfluence/fresh';

import styles from './SetupNotice.scss';

export enum MODES {
  EDIT = 'edit',
  CREATE = 'create',
}
interface IProps {
  actionComponent?: React.ReactNode;
  mode?: MODES;
  title: string;
  description: string;
}

export const SetupNotice: React.FC<Readonly<IProps>> = (props) => {
  const {
    actionComponent,
    mode,
    title,
    description,
  } = props;

  return (
    <div className={styles.AlertGap}>
      <Alert
        type={mode === MODES.CREATE ? 'warning' : 'info'}
        message={title}
        description={description}
        action={actionComponent}
      />
    </div>
  );
};
