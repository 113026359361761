import gql from 'graphql-tag';

import { SPECIFICATION_TEMPLATE_FRAGMENT } from './fragments';

export const GET_SPECIFICATION_TEMPLATE_BY_NAME_QUERY = gql`
  query GetSpecificationTemplateByNameQuery($templateName: String!) {
    template: getSpecificationTemplateByName(templateName: $templateName) {
      ...SpecificationTemplateFragment
    }
  }
  ${SPECIFICATION_TEMPLATE_FRAGMENT}
`;
