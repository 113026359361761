import React from 'react';
import { WidgetContainer, MainMetric } from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { BoxCheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetOrderSummaryV2 } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrderSummaryV2';
import { formatDistanceStrict } from 'date-fns';
import { isNil } from 'lodash';
import { usePFADashboardContext } from '../containers/ProductFulfillmentDashboard/ProductFulfillmentDashboardContext';

interface PFAInTransitProps {
  className?: string;
}

export default function PFADelivered({ className }: PFAInTransitProps) {
  const { dateRangeSettings } = usePFADashboardContext();

  const startDate = dateRangeSettings.dateRange?.startDate;
  const endDate = dateRangeSettings.dateRange?.endDate;

  const distanceInWords = formatDistanceStrict(startDate, endDate);

  const { summary, loading } = useGetOrderSummaryV2({
    variables: {
      dashboardFilter: {
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      },
    },
  });

  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <MainMetric
      value={summary?.delivered}
      size="medium"
      icon={null}
      metricType="count"
      trend={summary?.trendDelivered}
      subHeading={!isNil(summary?.trendDelivered) ? `in last ${distanceInWords}` : undefined}
      className="p-2 pt-0 -mt-2"
    />
  );
  return (
    <WidgetContainer
      widgetTitle="Delivered"
      className={`${className}`}
      bgColor="bg-[#2F7772]"
      widgetIcon={<BoxCheckIcon className="text-white" fontSize={16} />}
    >
      {loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
