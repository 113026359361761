import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
  find, first, isUndefined, map,
} from 'lodash';
import { TMember } from '@frontend/applications/AffiliatesApp/components/MembersWizard/types';

interface IProps {
  offer: GetOfferById_offer | null,
}

export const getProjectMemberData = (props: IProps) => {
  const { offer } = props;
  const offerSource = offer.promos.length ? OFFER_SOURCE.SHOPIFY : OFFER_SOURCE.TUNE;
  switch (offerSource) {
    case OFFER_SOURCE.SHOPIFY:
      const promo = first(offer.promos);
      return map(promo.affiliateStats, (member): TMember => {
        const promoMember = find(promo.affiliates, (affiliate) => affiliate.affiliateId === member.affiliateId);
        if (!isUndefined(promoMember) && promoMember.deletedDate === null) {
          return {
            affiliateOfferId: promoMember.id,
            payoutId: promoMember.offerPayoutId,
            name: member.affiliateName,
            inOffer: true,
            previousCode: {
              code: member.affiliateCode,
              end: null,
              start: null,
            },
            newCode: '',
            id: member.aspirexMemberId,
            forceCheckIn: false,
            programIds: [],
            firstName: '',
            lastName: '',
            instagramUsername: '',
          };
        }
      });
    case OFFER_SOURCE.TUNE:
      const link = first(offer.links);
      return map(link.affiliateStats, (member): TMember => {
        const linkMember = find(link.affiliates, (affiliate) => affiliate.affiliateId === member.affiliateId);
        if (!isUndefined(linkMember) && linkMember.deletedDate === null) {
          return {
            affiliateOfferId: member.affiliateOfferId,
            affiliateId: member.affiliateId,
            payoutId: linkMember.offerPayoutId,
            name: member.affiliateName,
            newCode: '',
            id: member.aspirexMemberId,
            previousCode: {
              code: '',
              end: null,
              start: null,
            },
            inOffer: true,
            forceCheckIn: false,
            programIds: [],
            firstName: '',
            lastName: '',
            instagramUsername: '',
            additionalDeeplinks: map(linkMember.affiliateDeeplinks, (deeplink) => ({
              id: deeplink.id,
              label: deeplink.label,
              url: deeplink.url,
            })),
          };
        }
      });
    default:
      return [];
  }
};
