import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_ALL_CATALOG_STATS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { GetStatsForAllCatalogs } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetStatsForAllCatalogs';

type IOptions = QueryHookOptions<GetStatsForAllCatalogs>;

export const useGetAllCatalogStats = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch,
} = useQuery(GET_ALL_CATALOG_STATS_QUERY, options);

  return {
    loading,
    allCatalogStats: data?.getStatsForAllCatalogs,
    error,
    refetch,
  };
};
