import * as React from 'react';
import { Checkbox } from 'antd';
import {
 isEqual, map, isEmpty, size,
} from 'lodash';
import {
  ITableColumnConfig,
  ITableRefHandles,
} from '@components';

import { MembersTableSelectStatus } from '../../constants';

import styles from './CheckboxCell.scss';

const {
 useMemo, useEffect, useCallback, useState,
} = React;

export const useCheckboxCellConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData: any[],
  selectedIds: string[],
  tableRef: React.MutableRefObject<ITableRefHandles>,
  showSelectAllCheckbox = true,
): ITableColumnConfig => {
  const [selectStatus, setSelectStatus] = useState<MembersTableSelectStatus>();

  const handleSelectAll = useCallback(
    (
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      currentSelectStatus: MembersTableSelectStatus,
    ) => {
      e.stopPropagation();
      e.preventDefault();
      if (currentSelectStatus === MembersTableSelectStatus.None) {
        tableRef.current?.selectAllRows?.();
      } else {
        tableRef.current?.unsetSelectedRows?.();
      }
    },
    [tableRef],
  );

  // Update select all checkbox based on selected rows
  useEffect(
    () => {
      if (isEmpty(selectedIds)) {
        setSelectStatus(MembersTableSelectStatus.None);
      } else if (size(selectedIds) !== size(tableData)) {
        setSelectStatus(MembersTableSelectStatus.Partial);
      } else {
        const selectedSet = new Set(selectedIds);
        const dataSet = new Set(map(tableData, (d) => d.id?.toString()));
        setSelectStatus(
          isEqual(selectedSet, dataSet)
            ? MembersTableSelectStatus.All
            : MembersTableSelectStatus.Partial,
        );
      }
    },
    [tableData, selectedIds],
  );

  return useMemo(() => ({
      cellClassName: styles.checkboxCell,
      field: 'id',
      headerCellClassName: styles.checkboxHeaderCell,
      headerName: showSelectAllCheckbox ? (
        <Checkbox
          onClick={(e) => handleSelectAll(e, selectStatus)}
          indeterminate={selectStatus === MembersTableSelectStatus.Partial}
          checked={selectStatus === MembersTableSelectStatus.All}
        />
      ) : null,
      justify: 'center',
      lockPosition: true,
      lockWidth: true,
      render: (_, context) => (
        <Checkbox
          onChange={context.onToggleRowSelected}
          checked={context.isRowSelected}
          onClick={(e) => {
            e.stopPropagation();
            context.onToggleShift(
              e.shiftKey,
              context.isRowSelected,
            );
          }}
        />
      ),
      sortable: false,
      width: 50,
    }), [
    selectStatus,
    handleSelectAll,
    showSelectAllCheckbox,
  ]);
};
