import { useMutation, MutationHookOptions } from '@apollo/client';

import { UPDATE_BASIC_TERMS_TEMPLATE_MUTATION } from '@frontend/app/queries';
import {
  UpdateBasicTermsTemplateMutation,
  UpdateBasicTermsTemplateMutationVariables,
} from '@frontend/app/queries/types/UpdateBasicTermsTemplateMutation';

type IOptions = MutationHookOptions<UpdateBasicTermsTemplateMutation, UpdateBasicTermsTemplateMutationVariables>;

export const useUpdateBasicTermsTemplateMutation = (
  options: IOptions = {},
  ) => useMutation<UpdateBasicTermsTemplateMutation, UpdateBasicTermsTemplateMutationVariables>(UPDATE_BASIC_TERMS_TEMPLATE_MUTATION, options);
