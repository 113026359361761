import { IState } from '../../../../types/state';
import { IUpdateBriefUrl } from './index';

export const updateBriefUrl = (state: IState, action: IUpdateBriefUrl): IState => {
  if (state.collaborationDetails.brief.briefUrl === action.briefUrl) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      brief: {
        ...state.collaborationDetails.brief,
        briefUrl: action.briefUrl,
      },
    },
  };
};
