import { useMemo } from 'react';
import {
 toLower, sortBy, filter, differenceBy,
} from 'lodash';
import {
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';

const sortingFunction = (field) => toLower(field.name);

export const useSchemas = () => {
  const {
    data: {
      schemas: allSchemas = null,
    } = {},
    refetch,
  } = useMemberFieldSchemasQuery();

  const sortedAllSchemas = useMemo(() => sortBy<typeof allSchemas[0]>(allSchemas, sortingFunction, 'name'), [allSchemas]);

  const appSchemas = useMemo(() => filter(sortedAllSchemas, (schema) => !!schema.applicationId), [sortedAllSchemas]);

  const nonAppSchemas = useMemo(() => differenceBy(sortedAllSchemas, appSchemas, 'id'), [sortedAllSchemas, appSchemas]);

  const defaultSchemas = useMemo(() => filter(sortedAllSchemas, { isDefault: true }), [sortedAllSchemas]);

  const customSchemas = useMemo(() => differenceBy(sortedAllSchemas, appSchemas, defaultSchemas, 'id'), [sortedAllSchemas, appSchemas, defaultSchemas]);

  return useMemo(() => ({
    appSchemas,
    nonAppSchemas,
    defaultSchemas,
    customSchemas,
    allSchemas: sortedAllSchemas,
    refetchSchemas: refetch,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [sortedAllSchemas, customSchemas, defaultSchemas, appSchemas, refetch]);
};
