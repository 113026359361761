import gql from 'graphql-tag';

export const AFFILIATE_PAYMENTS_OWED = gql`
query AffiliatePaymentsOwed($offerIds: [Int!]!, $startDate: DateTime, $endDate: DateTime) {
  affiliatePaymentsOwed(offerIds: $offerIds, startDate: $startDate, endDate: $endDate) {
    amountDue
    amountToPay
    memberId
    metadata {
      affiliateOfferLinkId
      affiliateOfferPromoId
      columns {
        offerName
        previousPayout
      }
      externalNote
      memberEmail
      memberName
    }
    mixpanelFields {
      offerId
      offerSource
    }
  }
}
`;
