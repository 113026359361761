import 'css-chunk:src/applications/MemberDataUpdateApp/components/NewBulkUpdateRequest.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1n9w6_157",
  "justify-content-space-between": "_justify-content-space-between_1n9w6_161",
  "tabular-nums": "_tabular-nums_1n9w6_165",
  "BulkUpdateRequest": "_BulkUpdateRequest_1n9w6_178",
  "label": "_label_1n9w6_178",
  "successHeader": "_successHeader_1n9w6_178",
  "subheader": "_subheader_1n9w6_188",
  "noResourceWarning": "_noResourceWarning_1n9w6_188",
  "stepTitle": "_stepTitle_1n9w6_198",
  "footer": "_footer_1n9w6_218",
  "messageForm": "_messageForm_1n9w6_236",
  "hide": "_hide_1n9w6_242",
  "previewContainer": "_previewContainer_1n9w6_245",
  "summary": "_summary_1n9w6_248",
  "summarySection": "_summarySection_1n9w6_253",
  "container": "_container_1n9w6_262",
  "card": "_card_1n9w6_273",
  "finishWithoutAssignment": "_finishWithoutAssignment_1n9w6_280",
  "indentField": "_indentField_1n9w6_283",
  "radioOption": "_radioOption_1n9w6_286",
  "landing": "_landing_1n9w6_295",
  "externalLinkIcon": "_externalLinkIcon_1n9w6_306",
  "input": "_input_1n9w6_310",
  "hidden": "_hidden_1n9w6_315",
  "copyLinkButton": "_copyLinkButton_1n9w6_318",
  "cancelButton": "_cancelButton_1n9w6_322",
  "disabled": "_disabled_1n9w6_325",
  "requestFields": "_requestFields_1n9w6_329",
  "row": "_row_1n9w6_329",
  "fieldDropdown": "_fieldDropdown_1n9w6_338",
  "fieldCheckbox": "_fieldCheckbox_1n9w6_341",
  "fieldDelete": "_fieldDelete_1n9w6_345",
  "show": "_show_1n9w6_1"
};