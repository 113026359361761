import { isUndefined } from 'lodash';

const validateUniquePayout = (value, newValue, callback) => {
    let payoutOptions: { label: string }[] = value.fields?.payoutOptions || [];
    payoutOptions = payoutOptions.filter((p) => !isUndefined(p.label));
    const fIndex = payoutOptions.findIndex((o) => (o.label as String).toLowerCase() === (newValue as String).toLowerCase());
    const lIndex = payoutOptions.findLastIndex((o) => (o.label as String).toLowerCase() === (newValue as String).toLowerCase());
    if (fIndex !== lIndex) return callback('Label must be unique');
    return callback();
};
export { validateUniquePayout };
