import * as React from 'react';
import cx from 'classnames';
import xss from 'xss';
import { Button } from 'antd';

import { RichTextEditor } from '@frontend/app/components';
import styles from './TextItemsOptions.scss';

const { useCallback, useMemo } = React;

export interface ITextItemsOptionsProps {
  items: string[];
  placeholder?: string;
  onChange(items: string[]);
  min?: number;
  max?: number;
  type: string;
}

export const TextItemsOptions: React.FC<ITextItemsOptionsProps> = (props) => {
  const {
    items,
    placeholder,
    onChange,
    min,
    max,
    type,
  } = props;

  const onRemove = useCallback((index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onChange(newItems);
  }, [items, onChange]);

  const onAdd = () => {
    const newItems = [...items, undefined];
    onChange(newItems);
  };

  const handleChange = useCallback((value: string, index: number) => {
    if (items?.[index] === value) {
      return;
    }

    const newItems = [...items];
    newItems[index] = value;
    onChange(newItems);
  }, [items, onChange]);

  const renderRemoveButton = useCallback((index: number) => (
    <Button
      type="link"
      className={styles.removeItemButton}
      value={index}
      onClick={() => onRemove(index)}
    >
      Remove
    </Button>
  ), [onRemove]);

  const renderedItems = useMemo(() => items.map((item, index) => (
    <div key={index} className={styles.itemOption}>
      <div className={styles.itemHeader}>
        <h4 className={styles.title}>
          {type}
          {' '}
          {index + 1}
        </h4>

        {items.length > min && renderRemoveButton(index)}
      </div>

      <RichTextEditor
        value={xss(item)}
        placeholder={placeholder}
        boundSelector="#steps"
        onChange={(value) => handleChange(value, index)}
      />
    </div>
  )), [items, type, min, renderRemoveButton, placeholder, handleChange]);

  return (
    <div className={cx(styles.TextItemsOptions)}>
      { renderedItems }

      {max && items.length < max && (
        <Button
          type="link"
          className={styles.addItemButton}
          onClick={onAdd}
        >
          + Add
          {' '}
          {type.toLowerCase()}
        </Button>
      )}
    </div>
  );
};

TextItemsOptions.defaultProps = {
  min: 0,
};
