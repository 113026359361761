/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_wgk3s_157 {
  display: flex;
}

._justify-content-space-between_wgk3s_161 {
  justify-content: space-between;
}

._tabular-nums_wgk3s_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_wgk3s_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NoContactModal_wgk3s_178 {
  z-index: 9600 !important;
}
._NoContactModal_wgk3s_178 ._button_wgk3s_181:first-child {
  margin-right: 0.3125rem;
}
._NoContactModal_wgk3s_178 ._button_wgk3s_181:last-child {
  margin-left: 0.3125rem;
}