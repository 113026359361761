import gql from 'graphql-tag';

import { SEGMENT_FOLDER_FRAGMENT } from './fragments/SegmentFolderFragment';

export const GET_SEGMENT_FOLDERS_FOR_PROGRAM = gql`
  query SegmentFoldersForProgramQuery($programId: Int) {
    folders: segmentFoldersForProgram(programId: $programId) {
      ...MemberPageSegmentFolder
    }
  }
  ${SEGMENT_FOLDER_FRAGMENT}
`;
