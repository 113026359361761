import { useMutation, MutationHookOptions } from '@apollo/client';
import { isFunction } from 'lodash';

import { message } from 'antd';

import { UPDATE_PROFILE_MUTATION } from '@frontend/app/queries';
import {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from '@frontend/app/queries/types/UpdateProfileMutation';

type IOptions = MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;

export const useUpdateProfile = (options: IOptions = {}) => {
  const [updateProfile, { loading, error }] = useMutation<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
  >(UPDATE_PROFILE_MUTATION, {
    ...options,
    onCompleted(data: UpdateProfileMutation) {
      if (isFunction(options.onCompleted)) {
        options.onCompleted(data);
      }

      message.info('Successfully updated profile');
    },
  });

  return {
    updateProfile,
    loading,
    error,
  };
};
