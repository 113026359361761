import gql from 'graphql-tag';

export const GET_ALL_PROGRAMS = gql`
  query GetAllPrograms {
    programs: programs {
      id
      title
      status
      splashImageUrl
      published
    }
  }
`;
