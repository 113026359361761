import * as React from 'react';
import * as qs from 'qs';
import { first, isEmpty, filter } from 'lodash';

import { endpoints } from '@components';
import { IResource } from '@components';
import { useGet } from '@frontend/utils';

import { useInviteContext } from './useInviteContext';

const { useEffect, useMemo, useCallback } = React;

export const useResources = () => {
  const {
    apiEndpoint,
    clientId,
    userId,
    resourceContext,
    updateResourceContext,
  } = useInviteContext();

  const fetchResourcesURL = useMemo(() => {
    if (userId) {
      const query = {
        client_id: clientId,
        user_id: userId,
        active: true,
      };
      return `${apiEndpoint}/${endpoints.resourcesEndpoint}?${qs.stringify(query)}`;
    }
  }, [apiEndpoint, clientId, userId]);

  const {
    loading: isFetchingResources,
    data: fetchedResources,
    error: fetchResourcesError,
  } = useGet<IResource[]>({ url: fetchResourcesURL, cache: true });

  const emailResources = useMemo(() => filter(fetchedResources, (resource) => {
      switch (resource.type) {
        case 'gmail':
        case 'outlook':
          return true;
        default:
          return false;
      }
    }), [fetchedResources]);

  const selectResourceId = useCallback((resourceId: IResource['id']) => {
    updateResourceContext((c) => ({ ...c, resourceId }));
  }, [updateResourceContext]);

  useEffect(() => {
    if (!isEmpty(emailResources) && !resourceContext.resourceId) {
      const defaultResource: IResource = first(emailResources);
      selectResourceId(defaultResource.id);
    }
  }, [emailResources, selectResourceId, resourceContext.resourceId]);

  return {
    resources: emailResources,
    resourceId: resourceContext.resourceId,
    selectResourceId,
    onRequestAddEmail: resourceContext.onRequestAddEmail,
    loading: isFetchingResources,
    error: fetchResourcesError,
  };
};
