import gql from 'graphql-tag';

export const GET_BUDGET_ACCOUNT_DISTRIBUTION = gql`query GetBudgetAccountDistribution($budgetId: Int, $fetchMasterBudget: Boolean) {
    budgetAccountDistribution: getBudgetAccountDistribution(budgetId: $budgetId, fetchMasterBudget: $fetchMasterBudget) {
        budgetId
        budgetName
        budgetDescription
        parentBudgetId
        fiscalYearsBudget
    }
}`;
