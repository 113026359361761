import * as React from 'react';
import {
  Row, Col, Space, Card as FreshCard, useBreakpoint,
} from '@revfluence/fresh';

import Impact from './components/TabContent/Impact';
import Content from './components/TabContent/Content';
import Impressions from './components/TabContent/Impressions';
import Engagements from './components/TabContent/Engagements';
import Sales from './components/TabContent/Sales';
import { FilterOption } from './components/Filter/Filter';
import pageStyles from './Reporting.scss';

export interface IValue {
  value: number;
  dollarMultiplier: number;
  dollarValue: number;
}
export interface IMetric {
  percentDiff: number | null;
  value: number;
}

export interface Trends {
  content?: IMetric;
  cpm?: IMetric;
  cpe?: IMetric;
  tmv?: IMetric;
  engagements?: IMetric;
  impressions?: IMetric;
  posts?: IMetric;
  conversions?: IMetric;
  creators?: IMetric;
  reach?: IMetric;
  views?: IMetric;
  sales?: IMetric;
}

export interface IImpact {
  content: IMetric;
  engagements: IMetric;
  impressions: IMetric;
  investment: IMetric;
  roi: IMetric;
  sales: IMetric;
  total: IMetric;
}

export interface IInvestmentValues {
  payments: number,
  productCost: number,
  total: number,
}

export interface IReportingPageProps {
  Alert: React.ReactNode;
  dateRange: [Date, Date];
  timeLabel: string;
  trends: Trends;
  impact: IImpact;
  ImpactChart: React.ReactNode;
  projects: FilterOption<number>[]
  selectedProjects: FilterOption<number>[]
  onProjectChange: (projects: FilterOption<number>[]) => void;
  onDateChange: (
    range: [Date?, Date?],
    compareRange: [Date?, Date?],
    label: string
  ) => void;
  filter: string;
  contentValues: {
    images: {
      broad: IValue,
      broadLimited: IValue,
      custom: IValue,
      none: IValue,
    };
    shortVideos: {
      broad: IValue,
      broadLimited: IValue,
      custom: IValue,
      none: IValue,
    };
    longVideos: {
      broad: IValue,
      broadLimited: IValue,
      custom: IValue,
      none: IValue,
    };
  };
  impressionsValues: {
    instagramPosts: {
      views: IValue,
      reach: IValue,
    };
    instagramReels: {
      views: IValue,
      reach: IValue,
    };
    instagramVideos: {
      views: IValue,
      reach: IValue,
    };
    instagramStories: {
      impressions: IValue,
      estimatedImpressions: IValue,
    };
    tiktok: {
      views: IValue,
    };
    pinterest: {
      impressions: IValue,
    };
    twitter: {
      reach: IValue,
    }
  }
  engagementValues: {
    instagramPosts: {
      likes: IValue,
      comments: IValue,
    };
    instagramReels: {
      likes: IValue,
      comments: IValue,
    };
    instagramVideos: {
      likes: IValue,
      comments: IValue,
    };
    instagramStories: {
      likes: IValue,
      comments: IValue,
    };
    tiktok: {
      likes: IValue,
      comments: IValue,
      shares: IValue,
    };
    youtube: {
      likes: IValue,
      comments: IValue,
      views: IValue,
    };
    pinterest: {
      favorites: IValue,
      clicks: IValue,
      closeups: IValue,
    };
    twitter: {
      likes: IValue,
      comments: IValue,
      shares: IValue,
    }
  }
  salesValues: {
    links: { sales: { value: number; }, conversions: { value: number } }
    promos: { sales: { value: number; }, conversions: { value: number } }
  }
  investmentValues: IInvestmentValues,
  igStoryEstimationEnabled?: boolean;
}

export type THowItsCalculatedProps = Pick<IReportingPageProps, 'impact' |
'contentValues' |
'impressionsValues' |
'engagementValues' |
'salesValues' |
'investmentValues' |
'filter' |
'igStoryEstimationEnabled'>;

export const HowItsCalculated = ({
  impact,
  contentValues,
  impressionsValues,
  engagementValues,
  salesValues,
  investmentValues,
  filter,
  igStoryEstimationEnabled,
}: THowItsCalculatedProps) => {
  switch (filter) {
    case 'content':
      return (
        <Content
          contentValues={contentValues}
          investmentValue={investmentValues}
          contentTotalValue={impact.content.value}
        />
      );
    case 'impressions':
      return (
        <Impressions
          impressionsValue={impressionsValues}
          investmentValue={investmentValues}
          impressionsTotalValue={impact.impressions.value}
          igStoryEstimationEnabled={igStoryEstimationEnabled}
        />
      );
    case 'engagements':
      return (
        <Engagements
          engagementsValue={engagementValues}
          investmentValue={investmentValues}
          engagementTotalValue={impact.engagements.value}
        />
      );
    case 'sales':
      return (
        <Sales
          salesValue={salesValues}
          investmentValue={investmentValues}
          salesTotalValue={impact.sales.value}
        />
      );
    default:
      return (
        <Impact
          content={impact.content.value}
          engagements={impact.engagements.value}
          impactValue={impact.roi.value}
          impressions={impact.impressions.value}
          sales={impact.sales.value}
          investmentValues={investmentValues}
          total={impact.total.value}
        />
      );
  }
};

interface IReportingLayoutProps {
  Header: React.ReactNode;
  Alert: React.ReactNode;
  MetricsSecondary: React.ReactNode;
  MetricsTabs: React.ReactNode;
  HowItsCalculated: React.ReactNode;
  ChartLabel: React.ReactNode;
  ImpactChart: React.ReactNode;
}

export const ReportingLayout = ({
  Header,
  Alert,
  ChartLabel,
  HowItsCalculated,
  ImpactChart,
  MetricsSecondary,
  MetricsTabs,
}: IReportingLayoutProps) => {
  const screens = useBreakpoint();

  return (
    <Space direction="vertical" size={0} style={{ width: '100%', overflowX: 'hidden' }}>
      {Alert}
      <Row gutter={0} style={{ backgroundColor: 'white' }}>
        <Col xs={24}>
          {Header}
        </Col>
      </Row>
      <Row gutter={0}>
        <Col xs={24}>
          {MetricsSecondary}
          {MetricsTabs}
          <div className={pageStyles.pagePadding}>
            <FreshCard className={pageStyles.cardLayout}>
              <Row gutter={{
                xxl: 48, lg: 32, sm: 24, xs: 24,
              }}
              >
                <Col xxl={6} xl={8} lg={10} md={12} xs={24}>
                  {HowItsCalculated}
                </Col>
                <Col xxl={18} xl={16} lg={14} md={12} xs={24} style={{ marginTop: !screens.md ? 16 : 0 }}>
                  {ChartLabel}
                  {ImpactChart}
                </Col>
              </Row>
            </FreshCard>
          </div>
        </Col>
      </Row>
    </Space>
  );
};
