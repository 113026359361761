/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_c76r6_157 {
  display: flex;
}

._justify-content-space-between_c76r6_161 {
  justify-content: space-between;
}

._tabular-nums_c76r6_165 {
  font-variant-numeric: tabular-nums;
}

._Input_c76r6_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_c76r6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Input_c76r6_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Input_c76r6_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Input_c76r6_169 ::-webkit-scrollbar-track, ._Input_c76r6_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Input_c76r6_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Input_c76r6_169 ._inputWrapper_c76r6_251 {
  position: relative;
  padding: 0 12px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  box-sizing: border-box;
  border-radius: 624.9375rem;
  will-change: border-color, background-color;
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._light_c76r6_264 {
  border: solid 1px #ececec;
  background-color: #fdfdfd;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._info_c76r6_268 {
  border: solid 1px #eff5f9;
  background-color: #eff5f9;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251:hover._light_c76r6_264 {
  border-color: #dadcde;
  background-color: #fafafa;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251:hover._info_c76r6_268 {
  border-color: #e6edf2;
  background-color: #e6edf2;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._focus_c76r6_280._light_c76r6_264 {
  border-color: #dadcde;
  background-color: #fafafa;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._focus_c76r6_280._info_c76r6_268 {
  border-color: #e6edf2;
  background-color: #e6edf2;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._focus_c76r6_280 ._icon_c76r6_288 > svg {
  color: #3996e0;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._hasError_c76r6_291 {
  border: 1px solid #f1515f !important;
  background-color: #f6e4e5 !important;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._hasError_c76r6_291 ._input_c76r6_251 {
  color: #da3737 !important;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._hasError_c76r6_291 ._input_c76r6_251::placeholder {
  color: #f1515f !important;
  opacity: 0.8;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251._hasError_c76r6_291 ._icon_c76r6_288 > svg {
  color: #da3737 !important;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251 ._icon_c76r6_288 {
  margin-right: 6px;
  flex-shrink: 0;
  display: flex;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251 ._icon_c76r6_288 > svg {
  color: #8f8d91;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251 ._input_c76r6_251 {
  padding: 0;
  max-width: 100%;
  height: 100%;
  flex: 1;
  outline: none;
  border: none;
  background: none;
  font-size: 15px;
  line-height: 38px;
}
._Input_c76r6_169 ._inputWrapper_c76r6_251 ._alertIcon_c76r6_324 {
  margin-left: 4px;
  flex-shrink: 0;
  display: flex;
  color: #f1515f;
}
._Input_c76r6_169 ._errorMessage_c76r6_330 {
  margin-top: 7px;
  color: #f1515f;
  font-size: 13px;
}