import * as React from 'react';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

export const useOAuthConnectTikTokAdAccount = () => {
  const app = useApplication();
  const { backendServerWebEndpoint, clientId } = app;

  const buildOAuthEndpoint = React.useCallback((redirectUrl) => {
    const oauthEndpoint = new URL(`${backendServerWebEndpoint}brands/${clientId}/tiktok/ad_account/oauth`);

    if (redirectUrl) {
      oauthEndpoint.searchParams.append('redirect_url', encodeURIComponent(redirectUrl));
    }

    return oauthEndpoint.toString();
  }, [backendServerWebEndpoint, clientId]);

  const reconnectFunction = React.useCallback(() => {
    const redirectUrl = window.location.href;
    const oauthUrl = buildOAuthEndpoint(redirectUrl);
    window.location.replace(oauthUrl);
  }, [buildOAuthEndpoint]);

  return reconnectFunction;
};
