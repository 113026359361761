/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w5t8_157 {
  display: flex;
}

._justify-content-space-between_1w5t8_161 {
  justify-content: space-between;
}

._tabular-nums_1w5t8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w5t8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w5t8_157 {
  display: flex;
}

._justify-content-space-between_1w5t8_161 {
  justify-content: space-between;
}

._tabular-nums_1w5t8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w5t8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  color: #1a1818;
}

._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._ButtonSecondary_1w5t8_395, ._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._Button_1w5t8_395 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._Button_1w5t8_395 {
  border-color: #167cf4;
  background-color: #167cf4;
}
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._Button_1w5t8_395:focus, ._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._Button_1w5t8_395:hover {
  background-color: #2e8af5;
  color: #fff;
}
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._Button_1w5t8_395:disabled {
  color: #fff;
  opacity: 0.8;
}

._WorkflowOfferError_1w5t8_178 {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 {
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
  padding-left: 3rem;
}
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._buttonContainer_1w5t8_431 {
  display: flex;
  flex-direction: row;
}
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._Button_1w5t8_395 {
  height: 2.5rem;
  width: 215px;
  margin-right: 0.3125rem;
}
._WorkflowOfferError_1w5t8_178 ._textContainer_1w5t8_178 ._ButtonSecondary_1w5t8_395 {
  height: 2.5rem;
  width: 169px;
  border: 1px solid #D9D9D9;
  color: #1F1F21 #1F1F21;
}
._WorkflowOfferError_1w5t8_178 ._imageContainer_1w5t8_446 {
  padding-right: 3rem;
  padding-left: 3rem;
  display: flex;
}