/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_n7ir2_157 {
  display: flex;
}

._justify-content-space-between_n7ir2_161 {
  justify-content: space-between;
}

._tabular-nums_n7ir2_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_n7ir2_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EditableHover_n7ir2_178 {
  position: relative;
  margin: 0 -0.75rem;
  padding: 0.625rem;
  width: calc(100% + 1.5rem);
  min-height: 2.625rem;
  box-sizing: border-box;
  border: 0.0625rem solid transparent;
  border-radius: 0.375rem;
  cursor: text;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._EditableHover_n7ir2_178:hover {
  background-color: #fdfdfd;
  border-color: #dadcde;
}
._EditableHover_n7ir2_178:hover ._dropDownArrow_n7ir2_194 {
  opacity: 1;
}
._EditableHover_n7ir2_178._boolean_n7ir2_197 {
  cursor: pointer;
}
._EditableHover_n7ir2_178 ._dropDownArrow_n7ir2_194 {
  position: absolute;
  top: 50%;
  right: 0.9375rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  color: #8f8d91;
}