import {
 LazyQueryHookOptions, QueryHookOptions, useLazyQuery, useQuery,
} from '@apollo/client';
import { GET_PRODUCT_DETAILS } from '@frontend/applications/ProductFulfillmentApp/queries/productDetails';
import { GetProductDetails, GetProductDetailsVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductDetails';
import { useMemo } from 'react';

type IOptions = QueryHookOptions<GetProductDetails, GetProductDetailsVariables>;

export const useGetProductDetails = (options: IOptions) => {
  const {
    loading, data, error, refetch,
   } = useQuery<GetProductDetails, GetProductDetailsVariables>(GET_PRODUCT_DETAILS, options);

  return useMemo(() => ({
    loading,
    product: data?.getProductVariantsByProductId ?? {} as GetProductDetails['getProductVariantsByProductId'],
    error,
    refetch,
  }), [loading, data, error, refetch]);
};

type IOptionsLazy = LazyQueryHookOptions<GetProductDetails, GetProductDetailsVariables>;

export const useGetProductDetailsLazy = (options: IOptionsLazy) => {
  const [
    fetchProductDetails,
    { loading, data, error },
   ] = useLazyQuery<GetProductDetails, GetProductDetailsVariables>(GET_PRODUCT_DETAILS, {
     ...options,
   });

  return {
    loading,
    product: data?.getProductVariantsByProductId,
    error,
    fetchProductDetails,
  };
};
