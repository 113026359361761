._header_1j49e_1 {
  position: relative;
  padding: 16px 16px 0;
}
._header_1j49e_1 ._closeBtn_1j49e_5 {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 16px;
  border: none;
}
._header_1j49e_1 ._label_1j49e_12 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
._header_1j49e_1 ._row_1j49e_17 {
  display: flex;
  margin-bottom: 12px;
}