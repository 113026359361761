import * as React from 'react';
import {
  Table,
  Pagination,
  Empty,
  Typography,
  ColumnsType,
  Space,
} from '@revfluence/fresh';
import { RectangleAdIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  ReconnectButton,
  RemoveButton,
} from '@frontend/app/components/InstagramMeta/MetaItems/';
import { CellStatus } from './utils/composeCells';

import styles from './Tables.scss';
import { renderAdTag } from './RenderTag';

const { Title } = Typography;

export type AdsAccountFeatures = [
  { feature: 'Viewing ad insight data', errorMessage: string },
];
export interface IAdAccount {
  id: string;
  name: string;
  socialAccountId: string;
  disconnectAccount: boolean;
  cell: CellStatus,
  features: AdsAccountFeatures,
  accessLost: boolean;
}

interface IAdAccountTableProps {
  data: IAdAccount[];
  pagination?: {
    total: number;
    current: number;
    pageSize: number;
  }
  AddAccountsButton: React.ReactNode;
  removeAccount: (id: string) => void;
  reconnectAccount: () => void;
  onPaginationChange: (page: number) => void;
}

export const AdAccountTable: React.FC<IAdAccountTableProps> = (props: IAdAccountTableProps) => {
  const {
    data,
    pagination,
    AddAccountsButton,
    removeAccount,
    reconnectAccount,
    onPaginationChange,
  } = props;
  const isEmpty = data.length === 0;

  const columns: ColumnsType<IAdAccount> = [
    {
      title: 'Ad Accounts',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: 'Ad Insights',
      dataIndex: 'insights',
      key: 'insights',
      width: '60%',
      // render functions expects text as a parameter, so adding the _ to show it's being ignored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (_, record) => renderAdTag(record),
    },
    {
      key: 'actions',
      width: '20%',
      // render functions expects text as a parameter, so adding the _ to show it's being ignored
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (_text: any, record) => (
        <Space>
          {record.disconnectAccount
            && <ReconnectButton onClick={reconnectAccount} disconnectAccount />}
          <RemoveButton removeAccount={() => removeAccount(record.id)} />
        </Space>
      ),
    },
  ];

  if (isEmpty) {
    return (
      <>
        <Title level={5} className={styles.TableTitle}>Connected Ad Accounts</Title>
        <Empty
          size="small"
          image={<RectangleAdIcon />}
          description={(
            <>
              <Typography.Title level={5}>
                No connected ad accounts
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Connect ad accounts to start viewing ad insights.
              </Typography.Paragraph>
            </>
          )}
        />
        {AddAccountsButton}
      </>
    );
  }

  return (
    <>
      <Title level={5} className={styles.TableTitle}>Connected Ad Accounts</Title>
      <Table
        className={styles.InstagramTable}
        rowClassName={() => styles.RowTable}
        columns={columns}
        dataSource={data}
        pagination={false}
        footer={() => (
          <div className={styles.InstagramTableFooter}>
            {AddAccountsButton}
            {pagination && <Pagination {...pagination} onChange={onPaginationChange} hideOnSinglePage />}
          </div>
        )}
      />
    </>
  );
};
