import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cx from 'classnames';
import { Overlay } from '@components';

import { AddMember } from './AddMember';

import styles from './AddMemberOverlay.scss';

const { useState, useEffect } = React;

interface IProps {
  visible?: boolean;
  onRequestClose(): void;
}

export const AddMemberOverlay: React.FC<IProps> = React.memo((props) => {
  const [visible, setVisible] = useState<boolean>(props.visible);

  useEffect(() => {
    if (props.visible) {
      setVisible(true);
    } else if (visible) {
      setTimeout(() => setVisible(false), 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  return (
    <>
      <Overlay
        show={props.visible}
        onRequestClose={props.onRequestClose}
        closeOnBackdropClick
        showCloseIcon={false}
        className={styles.Overlay}
      />
      {ReactDOM.createPortal(
        <AddMember
          visible={visible}
          className={cx(styles.AddMemberOverlay, { [styles.visible]: props.visible })}
          onSuccess={props.onRequestClose}
          onClose={props.onRequestClose}
        />,
        document.body,
      )}
    </>
  );
});

AddMemberOverlay.defaultProps = {
  visible: false,
};

AddMemberOverlay.displayName = 'AddMemberOverlay';
