import * as React from 'react';

import { TPlace, TPlacesOptions } from './types';
import { PlacesInputIQData } from './PlacesInput';

interface IProps {
  className?: string;
  onChange(newLocations: TPlace[]): void;
  locations?: TPlace[];
  placeholder?: string;
  debounceWait?: number;
  options?: TPlacesOptions;
}

const OPTIONS = {
  types: [],
};

export const LocationInputIQData: React.FunctionComponent<IProps> = (props) => (
  <PlacesInputIQData
    className={props.className}
    places={props.locations}
    placeholder={props.placeholder}
    onChange={props.onChange}
    emptyOptionsMessage="Start typing location name"
    options={props.options || OPTIONS}
    debounceWait={props.debounceWait}
  />
);

LocationInputIQData.defaultProps = {
  placeholder: 'Enter a location',
};
LocationInputIQData.displayName = 'LocationInputIQData';
