import * as React from 'react';
import { upperFirst } from 'lodash';
import {
  Typography,
  Input,
} from '@revfluence/fresh';
import { ContentUsageRightsType } from '@frontend/app/types/globalTypes';
import {
  TContentUsageRights,
} from '@frontend/applications/TermsApp/components/BulkTerms/types/CollaborationDetails';

const { Text } = Typography;
const { TextArea } = Input;
const { useCallback } = React;

interface IProps {
  contentRight: TContentUsageRights;
  onUpdateContentRight: (contentRight: TContentUsageRights) => void;
}

const TextAreaContentUsageRights: React.FC<IProps> = React.memo((props) => {
  const {
    contentRight,
    onUpdateContentRight,
  } = props;

  const handleContentRightTextChange = useCallback((e) => {
    onUpdateContentRight({
      type: contentRight.type,
      text: e.target.value,
    });
  }, [onUpdateContentRight, contentRight.type]);

  return (
    <>
      {
        contentRight.type === ContentUsageRightsType.NONE && (
          <Text>No content usage rights required for this collab</Text>
        )
      }
      {
        contentRight.type !== ContentUsageRightsType.NONE && (
          <TextArea
            placeholder={
              contentRight.type === ContentUsageRightsType.CUSTOM
              && (!contentRight.text || contentRight.text === '')
              ? 'Enter your custom license terms...'
              : ''
            }
            rows={4}
            value={upperFirst(contentRight.text)}
            onChange={handleContentRightTextChange}
          />
        )
      }
    </>
  );
});

TextAreaContentUsageRights.displayName = 'ContentUsageRights';

export default TextAreaContentUsageRights;
