import React, { useEffect, useState } from 'react';
import {
  Typography, Radio, Button, Form, Spinner, InputNumber,
} from '@revfluence/fresh';

const { Title, Text } = Typography;
import { useApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { useAuth } from '@frontend/context/authContext';

import { ApolloProvider } from '@apollo/client';
import { useCreateCostConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useCreateCostConfig';
import { useGetCurrentClient } from '@frontend/app/hooks';
import { useGetCostConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetCostConfig';
import { CostField } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import style from './AnalyticsSettings.styles.scss';

interface IProps {
  clientId: string;
}

export const AnalyticsSettingsInternal: React.FC<IProps> = (props: IProps) => {
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState<string>(null);
  const [percentage, setPercentage] = useState<number>(100);

  const { data, loading: loadingCostConfig, refetch } = useGetCostConfig({
    variables: {
      clientId: props.clientId,
    },
    skip: !props.clientId,
  });

  const { createCostConfig, loading: creatingCostConfig } = useCreateCostConfig({
    onCompleted: () => {
      // Refetch the cost config data after the mutation is complete
      refetch();
    },
  });

  useEffect(() => {
    if (data && data?.clientConfig?.costConfig) {
      // Extract the relevant fields from the fetched data
      const { field, percentage } = data.clientConfig.costConfig;
      if (field) {
        // Set the initial form values
        form.setFieldsValue({
          productCostType: field,
          percentage: field === CostField.WHOLESALE ? 100 : percentage * 100,
        });
        setRadioValue(field);
        setPercentage(percentage * 100);
      }
    }
  }, [data, form]);

  const onFinish = (values) => {
    createCostConfig({
      variables: {
        clientId: props.clientId,
        costConfig: {
          field: values.productCostType,
          percentage: values.productCostType === CostField.WHOLESALE
            ? 1
            : values.percentage / 100,
        },
      },
    });
  };
  if (loadingCostConfig) {
    return (
      <div className={style.AnalyticsSettings}>
        <Spinner />
      </div>
    );
  }

  return (

    <div className={style.AnalyticsSettings}>
      <Title level={3}>Analytics Setting</Title>
      <Text>To customize certain calculations to fit your specific needs for your analytics reports.</Text>
      <Title level={5}>Product Cost</Title>
      <Text>You can set how product cost is calculated for your Impact Dashboard by choosing to use the cost per item or retail price per item. If you’d like to use a percentage of the retail price, you can set that value in the price per item preference below.</Text>
      <div className={style.preference}>
        <Text strong>Product Cost Preference:</Text>
        {' '}
        <Form
          onValuesChange={(changedValues) => {
            if (changedValues.productCostType) {
              setRadioValue(changedValues.productCostType);
              form.setFieldsValue({
                productCostType: changedValues.productCostType,
                percentage: changedValues.productCostType === CostField.WHOLESALE ? 100 : percentage,
              });
            } else if (changedValues.percentage !== undefined) {
              form.setFieldsValue({
                percentage: changedValues.percentage,
              });
              setPercentage(changedValues.percentage);
            }
          }}
          onFinish={onFinish}
          initialValues={{ percentage: 100 }}
          form={form}
        >
          <Form.Item name="productCostType">
            <Radio.Group>
              <Radio value={CostField.WHOLESALE}>Cost Per Item</Radio>
              <Radio value={CostField.MSRP}>Retail price per item</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="percentage"
            rules={[
              {
                required: radioValue === CostField.MSRP,
                type: 'number',
                min: 0,
                max: 100,
                message: 'Please enter a valid percentage between 0 and 100.',
              },
            ]}
          >
            <InputNumber
              placeholder="Enter Percentage"
              addonAfter={<Text>%</Text>}
              disabled={radioValue === CostField.WHOLESALE}
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={creatingCostConfig}
              type="primary"
              htmlType="submit"
              disabled={!radioValue}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const AnalyticsSettings: React.FC = () => {
  const { token } = useAuth();
  const apolloClient = useApolloClient(token);
  const { loading, client } = useGetCurrentClient();
  if (loading) {
    return <Spinner />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AnalyticsSettingsInternal clientId={client.id} />
    </ApolloProvider>
  );
};

export default AnalyticsSettings;
