import * as React from 'react';

import {
  ProgramInput,
} from '@api/src/graphql/inputs';
import {
  useGetCurrentClient,
} from '@frontend/app/hooks';
import { filter, isFunction } from 'lodash';
import { ApplicationPageBuilderComponent, isFieldVisibleInPage, ProjectApplicationPageTemplateName } from '../applicationPageUtils';
import { TTemplateProps, IApplicationFormFields } from './types';
import { StepNames } from './constants';
import { LeftPanel, AllSchemas } from './LeftPanel';
import { RightPanel } from './RightPanel';

import styles from './LandingPages.scss';

const {
 useEffect, useMemo, useState, useCallback,
} = React;

interface IProps {
  applicationFormFields?: IApplicationFormFields;
  projectId: number;
  clientId: string;
  templateData?: TTemplateProps;
  programInput: ProgramInput;
  onSubmit: () => Promise<unknown>;
  onDeleteApplicationPage: () => void;
  onChangeTemplateData: (data: unknown) => void;
  onChange: (programInput: ProgramInput) => void;
  onChangeCustomPath?: (path: string) => void;
}

export const LandingPages: React.FC<IProps> = (props) => {
  const { onChange, programInput } = props;

  const [hasError, setHasError] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>(StepNames.Design);
  const [applicationFormFields, setApplicationFormFields] = useState<IApplicationFormFields>(props.applicationFormFields);
  const [showAllFields, setShowAllFields] = useState<boolean>(false);

  const {
    client = null,
  } = useGetCurrentClient();

  const clientHostname = useMemo(() => client?.hostname, [client]);

  const toggleSeeAllFields = useCallback(() => {
    setShowAllFields((show) => !show);
  }, [setShowAllFields]);

  useEffect(() => {
    setApplicationFormFields(props.applicationFormFields);
  }, [props.applicationFormFields]);

  const onChangeApplicationFormFields = (data: IApplicationFormFields) => {
    setApplicationFormFields(data);

    if (onChange) {
      onChange({
        ...programInput,
        applicationFormFields: {
          dbColumns: data.dbColumns,
          memberFieldSchemas: data.memberFieldSchemas,
        },
      });
    }
  };

  const isFieldVisible = useCallback((fieldName: ApplicationPageBuilderComponent) => isFieldVisibleInPage(fieldName, programInput), [programInput]);

  const onChangeFieldVisibility = useCallback((fieldName: ApplicationPageBuilderComponent, isVisible: boolean) => {
    if (isFunction(onChange)) {
      let fieldsSettings = programInput?.onboardingTemplateConfig?.fieldsSettings || [];
      fieldsSettings = filter(fieldsSettings, (f) => f.fieldName !== fieldName.toString());

      fieldsSettings.push({
        fieldName: fieldName.toString(),
        isHidden: !isVisible,
      });

      onChange({
        ...programInput,
        onboardingTemplateConfig: {
          ...(programInput?.onboardingTemplateConfig || {}),
          fieldsSettings,
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programInput]);

  return (
    <div className={styles.LandingPages}>
      <LeftPanel
        projectId={props.projectId}
        clientId={props.clientId}
        clientHostname={clientHostname}
        className={styles.leftPanel}
        templateProps={props.templateData}
        applicationFormFields={applicationFormFields}
        onChangeApplicationFormFields={onChangeApplicationFormFields}
        onChange={props.onChangeTemplateData}
        onError={setHasError}
        onChangeCustomPath={props.onChangeCustomPath}
        activeKey={activeKey}
        onChangeTab={setActiveKey}
        onToggleSeeAllFields={toggleSeeAllFields}
        template={props.programInput?.applicationPageTemplateName as ProjectApplicationPageTemplateName}
        replaceContent={showAllFields ? (
          <AllSchemas
            applicationFormFields={applicationFormFields}
            onChange={onChangeApplicationFormFields}
            onClickBack={toggleSeeAllFields}
          />
        ) : null}
        isFieldVisible={isFieldVisible}
        onChangeFieldVisibility={onChangeFieldVisibility}
      />
      <RightPanel
        templateProps={props.templateData || {}}
        className={styles.rightPanel}
        activeKey={activeKey}
        applicationFormFields={applicationFormFields}
        onSubmit={props.onSubmit}
        project={props.programInput}
        clientId={props.clientId}
        clientHostname={clientHostname}
        onDelete={props.onDeleteApplicationPage}
        hasError={hasError}
      />
    </div>
  );
};

LandingPages.displayName = 'LandingPages.tsx';
