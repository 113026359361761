import gql from 'graphql-tag';

export const MEMBER_OWNERS = gql`
  query MemberOwnersQuery($memberId: Int!) {
    member: memberById(id: $memberId) {
      id
      owners {
        id
        email
        name
      }
    }
  }
`;
