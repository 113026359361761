import * as React from 'react';
import { connect, Provider } from 'react-redux';
import {
 createStore, compose, applyMiddleware, bindActionCreators,
} from 'redux';
import thunk from 'redux-thunk';
import { map, uniqueId } from 'lodash';
import { IAttachment } from '@frontend/app/components';
import { getFilenameFromUrl } from '@frontend/utils';
import { IContent } from '@frontend/app/hooks';
import {
 IContentUploader, TContentType, initialState,
} from './redux/contentUploaderModel';
import contentUploaderReducer from './redux/contentUploaderReducer';
import contentUploaderActions from './redux/contentUploaderActions';
import { limitReachedSelector } from './redux/contentUploaderSelectors';

import { ContentUploader as ContentUploaderComponent, IOwnProps } from './ContentUploader';

const { useEffect, useMemo, useRef } = React;

export interface IContentUploaderState {
  contents: IAttachment[];
  isUploading: boolean;
}

interface IExternalProps {
  uploadContent?(file: File, type?: TContentType);
}

export interface IContentUploaderProps extends IOwnProps, IExternalProps {
  uploadFolder?: string;
  postEndpoint?: string;

  contents?: IAttachment[];
  uploadErrorMessage?: string;

  onStoreChange?(state: IContentUploaderState);

  initialContents?: IAttachment[];
  maxContents?: number;
}

const mapStateToProps = (state: IContentUploader) => ({
    contents: state.contents,
    limitReached: limitReachedSelector(state),
    uploadErrorMessage: state.errorMessage,
  });

const ConnectedContentUploader = connect(
  mapStateToProps,
  (dispatch, ownProps: IExternalProps) => {
    const actions = {
      uploadContent: contentUploaderActions.uploadContent,
      deleteContent: contentUploaderActions.deleteContent,
      deleteContents: contentUploaderActions.deleteContents,
    };

    const boundActions = bindActionCreators(actions, dispatch);

    if (ownProps.uploadContent) {
      boundActions.uploadContent = ownProps.uploadContent;
    }

    return boundActions;
  },
)(ContentUploaderComponent);

/**
 * @class
 * @extends {React.Component}
 */
export const ContentUploader: React.FC<IContentUploaderProps> = React.memo((props) => {
  const storeChangedRef = useRef<() => void>();

  const {
    acceptImage,
    acceptVideo,
    acceptApplication,
    classNames,
    uploadContent,
    uploadErrorMessage,
    contents,
    maxContents,
    initialContents,
    uploadFolder,
    postEndpoint,
  } = props;

  const contentUploaderStore = useMemo(() => {
    const middleWares = [thunk];

    // only use redux-logger in development
    if (process.env.NODE_ENV !== 'production') {
      const createLogger = require('redux-logger').createLogger;

      middleWares.push(createLogger());
    }

    return createStore(
      contentUploaderReducer,
      {
        ...initialState,
        // set initial contents
        contents: map(initialContents, (content: IContent) => ({
          ...content,
          xhr: null,
          localSrc: content.localSrc || content.fileUrl,
          name: getFilenameFromUrl(content.localSrc || content.fileUrl),
          progress: {
            percentage: 100,
            uploaded: content.size,
          },
          id: uniqueId(content.type),
        })),
        maxContents,
        uploadFolder,
        postEndpoint,
      },
      compose(applyMiddleware(...middleWares)),
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (uploadErrorMessage) {
      contentUploaderStore.dispatch(
        contentUploaderActions.setErrorMessage(uploadErrorMessage),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadErrorMessage]);

  useEffect(() => {
    if (contents) {
      contentUploaderStore.dispatch(
        contentUploaderActions.setContents(contents),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents]);

  /**
   * @private
   * This is getting called whenever store changes.
   */
  const handleStoreChange = () => {
    const { onStoreChange } = props;
    const contentUploaderState = contentUploaderStore.getState();
    const contents = contentUploaderState.contents;

    let isUploading = false;

    for (const content of contents) {
      if (!content.fileUrl) {
        isUploading = true;
        break;
      }
    }

    onStoreChange({
      contents,
      isUploading,
    });
  };

  storeChangedRef.current = handleStoreChange;

  useEffect(() => {
    const unsubscribeFromStore = contentUploaderStore.subscribe(() => {
      storeChangedRef.current();
    });
    return () => {
      unsubscribeFromStore();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeChangedRef]);

  return (
    <Provider store={contentUploaderStore}>
      <ConnectedContentUploader
        acceptImage={acceptImage}
        acceptVideo={acceptVideo}
        acceptApplication={acceptApplication}
        classNames={classNames}
        uploadContent={uploadContent}
      />
    </Provider>
  );
});

ContentUploader.defaultProps = {
  initialContents: [],
  maxContents: null,
  acceptImage: true,
  acceptVideo: true,
  acceptApplication: false,
};
ContentUploader.displayName = 'ContentUploader';

export * from './redux/contentUploaderModel';
export * from './FileSelector';
