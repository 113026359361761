import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import { MEMBERS_CREATE_REQUIREMENT } from '@frontend/app/queries';
import {
  MembersCreateRequirementMutation,
  MembersCreateRequirementMutationVariables,
} from '@frontend/app/queries/types/MembersCreateRequirementMutation';

type IOptions = MutationHookOptions<MembersCreateRequirementMutation, MembersCreateRequirementMutationVariables>;

export const useCreateMembersRequirementMutation = (options: IOptions = {}) => useMemberMutation<MembersCreateRequirementMutation, MembersCreateRequirementMutationVariables>(MEMBERS_CREATE_REQUIREMENT, options);
