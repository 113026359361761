import * as React from 'react';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import { PureQueryOptions } from 'apollo-client';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  useDeselectAdAccountMutation,
  useGetAdAccountSettings,
  useGetAvailableAdAccounts,
  useGetSocialAccountSettings,
  useHasIssueOnSocialAccount,
  useUpdateAdAccountsMutation,
  useDeselectBusinessMutation,
  useUpdateBusinessesMutation,
  useUpdateSocialAccountClientSettings,
  useGetAvailableBusinesses,
  useGetBusinessSettings,
} from '@frontend/app/hooks';
import {
  GET_AD_ACCOUNT_SETTINGS,
  GET_AVAILABLE_AD_ACCOUNTS,
  GET_SOCIAL_ACCOUNT_SETTINGS,
  GET_AVAILABLE_BUSINESSES,
  GET_BUSINESS_SETTINGS,
} from '@frontend/app/queries';
import { SocialAppSettingsLoading } from '@frontend/applications/SocialPostApp/pages/SocialAppSettings';
import { useMessagingContext } from '@frontend/hooks';
import { useLocation } from 'react-router-dom';
import { Layout } from './Layout';
import { InstagramAccountsTable } from '../containers/InstagramAccountsTable';
import { AllowlistingTable } from '../containers/AllowlistingTable';
import { useHandleAddSocialListeningAccount } from '../HandleAddSocialListeningAccount';
import { AdAccountsTable } from '../containers/AdAccountsTable';
import { MetaSettingsInfoCards } from '../MetaSettingsInfoCards';

const { useEffect, useMemo } = React;

export const ResultsPerPage = 10;

enum WarningType {
  ADDITIONAL_ACCOUNTS = 'additional_accounts',
}

export const InstagramMeta = () => {
  const { showMessage } = useMessagingContext();
  const location = useLocation();

  useEffect(() => {
    const warningReason = new URLSearchParams(location.search).get('warning');
    if (warningReason == WarningType.ADDITIONAL_ACCOUNTS) {
        const accountsAdded = new URLSearchParams(location.search).get('accounts_added');
        const message = accountsAdded
          ? `${accountsAdded} accounts are being added. It may take a few minutes for your accounts to appear. Feel free to continue working.`
          : 'Accounts are being added. It may take a few minutes for your accounts to appear. Feel free to continue working.';
        showMessage({
          content: message,
          type: 'info',
        });
      }
  }, [location, showMessage]);

  const getSocialAccountSettingsQuery = useGetSocialAccountSettings({
    variables: {
      socialAccountPage: 1,
      socialAccountResultsPerPage: ResultsPerPage,
    },
  });
  const getAdAccountSettingsQuery = useGetAdAccountSettings({
    variables: {
      adAccountPage: 1,
      adAccountResultsPerPage: ResultsPerPage,
    },
  });
  const getAvailableAdAccountsQuery = useGetAvailableAdAccounts({
    variables: { resultsPerPage: 100 },
  });

  const hasIssueOnSocialAccountQuery = useHasIssueOnSocialAccount();
  const getAvailableBusinessesQuery = useGetAvailableBusinesses();
  const getBusinessSettingsQuery = useGetBusinessSettings({
    variables: {
      businessPage: 1,
      businessResultsPerPage: ResultsPerPage,
    },
    nextFetchPolicy: 'network-only',
  });

  const socialAccountSettingsPaging = getSocialAccountSettingsQuery.data?.getSocialAccountClientSettings?.instagramAccountData?.paging;
  const adAccountsSettingsPaging = getAdAccountSettingsQuery.data?.getSocialAccountClientSettings?.metaAdAccountData?.paging;
  const updateSocialAccountClientSettingsMutation = useUpdateSocialAccountClientSettings({
    refetchQueries: [
      {
        query: GET_SOCIAL_ACCOUNT_SETTINGS,
        variables: {
          socialAccountPage: socialAccountSettingsPaging?.currentPage || 1,
          socialAccountResultsPerPage: socialAccountSettingsPaging?.resultsPerPage || ResultsPerPage,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      hasIssueOnSocialAccountQuery.refetch();
      getAvailableAdAccountsQuery.refetch({ resultsPerPage: 100 });
      getAdAccountSettingsQuery.refetch({
        adAccountPage: adAccountsSettingsPaging?.currentPage || 1,
        adAccountResultsPerPage: adAccountsSettingsPaging?.resultsPerPage || ResultsPerPage,
      });
    },
  });
  const adAccountsRefetchQueries: PureQueryOptions[] = [
    {
      query: GET_AD_ACCOUNT_SETTINGS,
      variables: {
        adAccountPage: adAccountsSettingsPaging?.currentPage || 1,
        adAccountResultsPerPage: adAccountsSettingsPaging?.resultsPerPage || ResultsPerPage,
      },
    },
    {
      query: GET_AVAILABLE_AD_ACCOUNTS,
      variables: { resultsPerPage: 100 },
    },
  ];
  const businessesRefetchQueries: PureQueryOptions[] = [
    {
      query: GET_AVAILABLE_BUSINESSES,
    },
    {
      query: GET_BUSINESS_SETTINGS,
      variables: { businessPage: 1, businessResultsPerPage: ResultsPerPage },
    },
  ];
  const removeAdAccountMutation = useDeselectAdAccountMutation({
    refetchQueries: adAccountsRefetchQueries,
    awaitRefetchQueries: true,
  });
  const updateAdAccountsMutation = useUpdateAdAccountsMutation({
    refetchQueries: adAccountsRefetchQueries,
    awaitRefetchQueries: true,
  });
  const updateBusinessesMutation = useUpdateBusinessesMutation({
    refetchQueries: businessesRefetchQueries,
    awaitRefetchQueries: true,
  });
  const removeBusinessMutation = useDeselectBusinessMutation({
    refetchQueries: businessesRefetchQueries,
    awaitRefetchQueries: true,
  });
  const isFirstLoad = getSocialAccountSettingsQuery.loading && isEmpty(getSocialAccountSettingsQuery.data)
    || getAdAccountSettingsQuery.loading && isEmpty(getAdAccountSettingsQuery.data);

  const igAccountsConnected = getSocialAccountSettingsQuery.data?.getSocialAccountClientSettings?.instagramAccountData?.accounts || [];
  const hasIgAccountsConnected = !isEmpty(igAccountsConnected);
  const reconnectAccount = useHandleAddSocialListeningAccount(!hasIgAccountsConnected);
  const someQueryError = useMemo(() => [
    getSocialAccountSettingsQuery,
    getAdAccountSettingsQuery,
    getAvailableAdAccountsQuery,
  ].find((queryOrMutation) => !!queryOrMutation.error)?.error?.message, [
    getSocialAccountSettingsQuery,
    getAdAccountSettingsQuery,
    getAvailableAdAccountsQuery,
  ]);
  const someMutationError = useMemo(() => {
    const mutation = [
      updateSocialAccountClientSettingsMutation,
      removeAdAccountMutation,
      updateAdAccountsMutation,
    ].find((queryOrMutation) => !!queryOrMutation[1].error);
    if (mutation) {
      return mutation[1]?.error?.message;
    }
  }, [
    updateSocialAccountClientSettingsMutation,
    removeAdAccountMutation,
    updateAdAccountsMutation,
  ]);

  useEffect(() => {
    if (someQueryError || someMutationError) {
      message.error({
        icon: <TriangleExclamationIcon />,
        content: 'We were unable to load your settings. Please try again later, please contact help@aspireiq.com if your problem persists.',
      });
    }
  }, [someQueryError, someMutationError]);

  if (isFirstLoad) {
    return (
      <SocialAppSettingsLoading />
    );
  }

  return (
    <Layout
      MetaSettingsInfoCards={(
        <MetaSettingsInfoCards
          igdmEnabled
          brandedContentAdsEnabled
          adInsightsEnabled
          allowlistingEnabled
        />
      )}
      InstagramAccountsTable={(
        <InstagramAccountsTable
          igdmEnabled
          brandedContentAdsEnabled
          igEstimatedImpressionsEnabled
          query={getSocialAccountSettingsQuery}
          mutation={updateSocialAccountClientSettingsMutation}
          reconnectAccount={reconnectAccount}
        />
      )}
      AllowListingTable={(
        <AllowlistingTable
          hidden={!hasIgAccountsConnected}
          query={getBusinessSettingsQuery}
          deselectMutation={removeBusinessMutation}
          availableBusinessesQuery={getAvailableBusinessesQuery}
          updateBusinessesMutation={updateBusinessesMutation}
        />
      )}
      AdAccountsTable={(
        <AdAccountsTable
          hidden={!hasIgAccountsConnected}
          query={getAdAccountSettingsQuery}
          availableAdAccountsQuery={getAvailableAdAccountsQuery}
          updateAdAccountsMutation={updateAdAccountsMutation}
          deselectMutation={removeAdAccountMutation}
          reconnectAccount={reconnectAccount}
        />
      )}
    />
  );
};
