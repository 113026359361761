import * as React from 'react';
import { Progress } from '@revfluence/fresh';
import cx from 'classnames';
import styles from './PercentagePanel.scss';

interface IProps {
  currentCount: number;
  totalCount: number;
  title?: string;
  description?: string;
  infoName?: string;
  className?: string;
}

export const PercentagePanel = React.memo((props: IProps) => {
  const {
    currentCount,
    totalCount,
    title,
    description,
    infoName,
    className,
  } = props;

  return (
    <div className={cx(styles.PercentagePanel, className)}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div>{`${currentCount}/${totalCount} ${infoName}`}</div>
      </div>
      <Progress
        percent={(currentCount / totalCount) * 100}
        showInfo={false}
        strokeColor="#167cf4"
      />
      <div>{description}</div>
    </div>
  );
});

PercentagePanel.displayName = 'PercentagePanel';
