import { findIndex } from 'lodash';

import { IMember } from '../../../../types/Member';
import {
  IState,
  IUpdateMemberValueAction,
} from '../../../../types/state';
import { getMemberComment } from '../../../../utils/getMemberComment';

export const updateMemberValue = <Field extends keyof IMember, Value extends IMember[Field]>(
  state: IState,
  action: IUpdateMemberValueAction<Field, Value>,
): IState => {
  const {
    field,
    memberId: id,
    value,
  } = action;

  const memberIndex = findIndex(state.members, { id });
  if (memberIndex < 0) {
    return state;
  }

  const member = state.members[memberIndex];
  if (member[field] === value) {
    return state;
  }

  const updatedMember = {
    ...member,
    [field]: value,
  };
  updatedMember.comment = getMemberComment(
    updatedMember,
    state.collaborationDetails,
    state.contentGuidelines,
  );

  return {
    ...state,
    members: [
      ...state.members.slice(0, memberIndex),
      updatedMember,
      ...state.members.slice(memberIndex + 1),
    ],
  };
};
