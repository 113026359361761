import React from 'react';
import { Button } from '@revfluence/fresh';
import { TrashIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/ConfigDrawer.scss';

type TProps = {
  onSave: () => void;
  onCancel: () => void;
  onDelete?: () => void;
  hasDeleteButton?: boolean;
};
const FormFooter = (props: TProps) => {
  const {
    onSave,
    onCancel,
    onDelete = null,
    hasDeleteButton = false,
  } = props;

  return (
    <div className={styles.configFooter}>
      <Button
        type="primary"
        onClick={onSave}
      >
        Done
      </Button>
      <Button
        onClick={onCancel}
      >
        Cancel
      </Button>
      { hasDeleteButton && (
        <Button
          danger
          icon={<TrashIcon />}
          onClick={onDelete}
        />
      ) }
    </div>
  );
};

export default FormFooter;
