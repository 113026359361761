import { useCallback } from 'react';
import { join, map, trim } from 'lodash';

import {
  TFetchMembers,
  TFetchPrograms,
  TMember,
  TMode,
  TOnSave,
  TProgram,
} from '@affiliates/components/MembersWizard/types';
import { useApolloClient } from '@affiliates/hooks';
import {
  GET_PROGRAM_INFO_QUERY,
  GET_PROGRAM_MEMBER_INFO_QUERY,
  GET_PROGRAM_MEMBER_INFO_QUERY_COUNT,
  PAUSE_BULK_AFFILIATE_OFFERS_LINK_ASYNC,
  UNPAUSE_BULK_AFFILIATE_OFFERS_LINK_ASYNC,
} from '@affiliates/queries';
import { GetProgramInfoQuery } from '@affiliates/queries/types/GetProgramInfoQuery';
import { GetProgramMemberInfoQuery } from '@affiliates/queries/types/GetProgramMemberInfoQuery';
import { REMOVE_OFFER_MEMBERS_MUTATION, UPDATE_LINKS_MEMBERS_MUTATION, UPDATE_OFFER_MEMBERS_MUTATION } from '@affiliates/queries/updateOfferMembersMutation';
import { useEventContext } from '@frontend/app/context';
import { notification } from '@revfluence/fresh';
import { EventName } from '@common';
import { OFFER_SOURCE } from '../types/globalTypes';
import { isOfferShopify, isOfferTune } from '../components/MembersWizard/utils';
import { UPDATE_LINK_MEMBER_PAYOUT_MUTATION, UPDATE_PROMO_MEMBER_PAYOUT_MUTATION } from '../queries/updateMemberPayoutMutation';
import { GetProgramMemberInfoQueryCount } from '../queries/types/GetProgramMemberInfoQueryCount';
import { EDIT_PRIMARY_DEEP_LINK_MUTATION } from '../queries/editPrimaryDeepLink';

interface IReturnCallbacks<Mode extends TMode> {
  fetchMembers: TFetchMembers;
  fetchPrograms: TFetchPrograms;
  onSave: TOnSave<Mode, undefined>;
  fetchMembersCount: (programIds: number[], searchByName?: string) => Promise<number>;
}

export const useUpdateOfferMembers = <Mode extends TMode>(
  offerId: number,
  mode: Mode,
  offerSource: OFFER_SOURCE,
  onComplete: () => void,
  defaultPayoutId: number | null,
): Readonly<IReturnCallbacks<Mode>> => {
  const addEvent = useEventContext();
  const client = useApolloClient();
  const isShopify = isOfferShopify(offerSource);
  const isTune = isOfferTune(offerSource);
  const fetchPrograms: TFetchPrograms = useCallback(async (): Promise<readonly TProgram[]> => {
    const programInfo = await client.query<GetProgramInfoQuery>({
      query: GET_PROGRAM_INFO_QUERY,
      variables: { offerId },
      fetchPolicy: 'network-only',
    });
    return map(programInfo.data.programInfoForOffer, (p): TProgram => ({
      id: p.programId || 0,
      memberCount: p.memberCount || 0,
      memberCountInOffer: p.membersInOffer || 0,
      memberCountWithCodes: p.membersWithCodes || 0,
      memberCountWithLinks: p.membersWithLinks || 0,
      name: p.programName || '',
    }));
  }, [client, offerId]);
  const fetchMembers: TFetchMembers = useCallback(async (programIds, page, limit, searchByName?: string, isNewFlow?: boolean): Promise<readonly TMember[]> => {
    const offset = (page - 1) * limit;
    const memberInfo = await client.query<GetProgramMemberInfoQuery>({
      query: GET_PROGRAM_MEMBER_INFO_QUERY,
      variables: {
        offerId,
programIds,
        ...(isNewFlow ? { offset, limit, searchByName } : {}),
      },
      fetchPolicy: 'network-only',
    });
    if (memberInfo.loading) {
      return [];
    }
    return map(memberInfo.data.memberInfoForOfferAndPrograms, (m): TMember => ({
      affiliateOfferId: 0, // unused in the add/refresh flows
      firstName: trim(m.memberFirstName || ''),
      id: m.memberId || 0,
      inOffer: m.inOffer,
      instagramUsername: m.instagramUsername,
      lastName: trim(m.memberLastName || ''),
      name: trim(m.memberName || ''),
      previousCode: {
        code: '',
        end: null,
        start: null,
      },
      payoutId: defaultPayoutId ?? null,
      forceCheckIn: false,
      programIds: m.programIds,
    }));
  }, [client, offerId, defaultPayoutId]);
  const onSave: TOnSave<Mode, undefined> = useCallback(async (variables, programIds) => {
    switch (mode) {
      case 'add':
        if (isShopify) {
          await client.mutate({
            mutation: UPDATE_OFFER_MEMBERS_MUTATION,
            variables,
          });
          addEvent(EventName.SalesTrackingGeneratePromoCodes, {
            offerId,
            memberCount: variables.affiliates.length,
            programIds: join(programIds, ','),
            source: 'sales tracking',
            createdFrom: 'sales tracking',
          });
        }
        if (isTune) {
          await client.mutate({
            mutation: UPDATE_LINKS_MEMBERS_MUTATION,
            variables,
          });
          addEvent(EventName.SalesTrackingGenerateLink, {
            offerId,
            memberCount: variables.affiliates.length,
            programIds: join(programIds, ','),
            source: 'sales tracking',
            createdFrom: 'sales tracking',
          });
        }
        break;
      case 'refresh':
        if (isShopify) {
          await client.mutate({
            mutation: UPDATE_OFFER_MEMBERS_MUTATION,
            variables,
          });
          addEvent(EventName.SalesTrackingGeneratePromoCodes, {
            offerId,
            memberCount: variables.affiliates.length,
            source: 'sales tracking',
            createdFrom: 'sales tracking',
          });
        }
        if (isTune) {
          await client.mutate({
            mutation: UNPAUSE_BULK_AFFILIATE_OFFERS_LINK_ASYNC,
            variables,
          });
        }
        break;
      case 'deactivate': {
        if (isShopify) {
          await client.mutate({
            mutation: REMOVE_OFFER_MEMBERS_MUTATION,
            variables,
          });
        }
        if (isTune) {
          await client.mutate({
            mutation: PAUSE_BULK_AFFILIATE_OFFERS_LINK_ASYNC,
            variables,
          });
        }
        break;
      }
      case 'editPayout': {
        if (isShopify) {
          await client.mutate({
            mutation: UPDATE_PROMO_MEMBER_PAYOUT_MUTATION,
            variables: { affiliates: variables },
          });
        }
        if (isTune) {
          await client.mutate({
            mutation: UPDATE_LINK_MEMBER_PAYOUT_MUTATION,
            variables: { affiliates: variables },
          });
        }
        break;
      }
      case 'deepLink': {
        if (isTune) {
            await client.mutate({
              mutation: EDIT_PRIMARY_DEEP_LINK_MUTATION,
              variables,
            });
            notification.success({
              message: 'Deeplink has been updated successfully.',
            });
          }
        break;
      }
    }
    onComplete();
  }, [
    addEvent,
    client,
    isShopify,
    isTune,
    mode,
    offerId,
    onComplete,
  ]);
  const fetchMembersCount = useCallback(async (programIds: number[], searchByName?: string): Promise<number> => {
    const memberInfoCount = await client.query<GetProgramMemberInfoQueryCount>({
      query: GET_PROGRAM_MEMBER_INFO_QUERY_COUNT,
      variables: {
        offerId, programIds, searchByName,
      },
      fetchPolicy: 'network-only',
    });
    return memberInfoCount.data.memberInfoForOfferAndProgramsCount;
  }, [client, offerId]);
  return {
    fetchMembers,
    fetchPrograms,
    onSave,
    fetchMembersCount,
  };
};
