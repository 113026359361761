import * as React from 'react';
import {
  isEmpty, isNil, find,
} from 'lodash';

import { Alert, Button } from '@revfluence/fresh';
import { InstagramIcon } from '@revfluence/fresh-icons/brands/esm';
import { useAuth } from '@frontend/context/authContext';
import { EventName } from '@common';
import { SOCIAL_POST_APP_ID, IGDM_APP_ID } from '@frontend/app/constants/applicationIds';
import { useEventContext, useResourceContext } from '@frontend/app/context';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import { useClientFeatureEnabled, useGetInstalledApplicationIds } from '@frontend/app/hooks';

import styles from './IgdmInviteBanner.scss';

const { useMemo } = React;

const SCOPES_SEPARATOR = ',';
const OUTREACH_SCOPES = ['creator_marketplace', 'pages_messaging', 'instagram_manage_messages', 'pages_manage_metadata'];

interface IProps {
  paddings: number[];
  ctaSource: string;
}

const notConnectedMsg = (
  <>
    Instagram Creators respond faster when you send the project invitation using Instagram Direct Messaging (IGDM). To learn how to set up IGDM, click
    {' '}
    <a href="https://intercom.help/aspireiq_elevate/en/articles/3851091-how-to-connect-your-brand-s-instagram-account">here</a>
  </>
);

const disconnectedMsg = (
  <>
    Your IGDM has been disconnected. Please setup your IGDM to connect with creators faster.
  </>
);

const missingPermissionsMsg = (
  <>
    Your IG account is missing some permissions, please reconnect your account. To learn how to reconnect you account, click
    {' '}
    <a href="https://intercom.help/aspireiq_elevate/en/articles/3851091-how-to-connect-your-brand-s-instagram-account">here</a>

  </>
);

export const IgdmInviteBanner: React.FC<IProps> = (props) => {
  const isIGDMAdoptionBannerEnabled = useClientFeatureEnabled(ClientFeature.IGDM_ADOPTION_BANNER);
  const {
    paddings: [
      marginTop,
      marginBottom,
    ],
    ctaSource,
  } = props;

  const auth = useAuth();
  const addEvent = useEventContext();
  const {
    resources,
  } = useResourceContext();
  const installedApps = useGetInstalledApplicationIds();
  const showInstagramMeta = useClientFeatureEnabled(ClientFeature.SHOW_INSTAGRAM_META);
  const instagramDM = useClientFeatureEnabled(ClientFeature.INSTAGRAM_DM);

  const igResource = useMemo(() => find(resources, (r) => (
    r.type === ResourceType.IGDM
    && !r.authProvider.userRevokedAccess
    && !r.authProvider.systemRevokedAccess
  )), [resources]);

  const isIgResourceDisconnected = useMemo(() => (
    isEmpty(igResource)
      && !isEmpty(find(resources, (r) => (r.type === ResourceType.IGDM)))), [igResource, resources]);

  const noIgdmAdded = useMemo(() => (
    isEmpty(find(resources, (r) => (r.type === ResourceType.IGDM)))), [resources]);

  if (!isIGDMAdoptionBannerEnabled) {
    return null;
  }

  if ((showInstagramMeta || instagramDM) && !installedApps[IGDM_APP_ID]) {
    return null;
  }

  const message = noIgdmAdded ? notConnectedMsg : (isIgResourceDisconnected ? disconnectedMsg : '');

  const inviteBanner = (msg) => (
    <div className={styles.inviteAlertBanner}>
      <Alert
        type="info"
        message={msg}
        showIcon
        icon={<InstagramIcon fontSize={16} />}
        closable
        style={{
          marginTop,
          marginBottom,
        }}
        action={(
          <Button
            type="primary"
            href={`/client/${auth.clientInfo.id}/settings/${SOCIAL_POST_APP_ID}`}
            onClick={() => addEvent(EventName.InstagramInviteBannerCTA, {
              clientId: auth.clientInfo.id,
              userId: auth.user.sub,
              dateTime: new Date().toUTCString(),
              source: ctaSource,
            })}
          >
            Settings
          </Button>
        )}
      />
    </div>
  );

  if (message) {
    return inviteBanner(message);
  }

  let messageComponent = null;
  const scopes = new Set<string>(igResource.scopes.split(SCOPES_SEPARATOR));
  OUTREACH_SCOPES.forEach((importantScope) => {
    if (!scopes.has(importantScope)) {
      messageComponent = inviteBanner(missingPermissionsMsg);
    }
  });

  if (
    isNil(messageComponent)
    && (isEmpty(igResource.authProvider.access)
    && !igResource.authProvider.access.includes('creator_marketplace'))
  ) {
    return null;
  }

  return messageComponent;
};
