import { CPThunkDispatch } from './store';
import { fetchContent, ActionTypes } from './actions';

/**
 * Refreshes content when filters or sort changes.
 */
export const contentRefresher = (store) => (next) => (action) => {
  const result = next(action);
  switch (action.type) {
    case ActionTypes.CHANGE_FILTERS:
    case ActionTypes.CHANGE_SORT: {
      const dispatch: CPThunkDispatch = store.dispatch;
      dispatch(fetchContent());
      break;
    }
  }
  return result;
};
