import {
  Col,
  Row,
  Skeleton,
  Space,
} from '@revfluence/fresh';
import * as React from 'react';

import styles from './ContentGuidelinesLoading.module.scss';

interface IProps {
  isMobile: boolean;
}

const LoadingLine = () => (
  <Skeleton
    paragraph={false}
    title
    loading
    active
  />
);

const LoadingRow = (contentLength: number) => (
  <Row
    align="middle"
    className={styles.loadingContentRow}
  >
    <Col>
      <Skeleton.Avatar
        shape="square"
        size="small"
      />
    </Col>
    <Col span={contentLength}>
      <LoadingLine />
    </Col>
    <Col className={styles.loadingLineXXS}>
      <LoadingLine />
    </Col>
  </Row>
);

export const LoadingList: React.FC = () => (
  <div className={styles.loadingContentContainer}>
    <Space
      direction="horizontal"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: Fix in Node upgrade typing bash!
      align="stretch"
      className={styles.loadingContentWrapper}
    >
      {LoadingRow(8)}
      {LoadingRow(10)}
      {LoadingRow(4)}
      {LoadingRow(4)}
      {LoadingRow(8)}
      {LoadingRow(8)}
    </Space>
  </div>
);

const LoadingHeader = ({ isMobile }) => (
  <Row
    align="middle"
    className={styles.contentGuidelinesLoadingHeader}
  >
    {isMobile && (
      <Col
        className={styles.loadingButton}
        span={3}
      >
        <Skeleton.Button
          size="large"
          active
          block
        />
      </Col>
    )}
    <Col span={4}>
      <LoadingLine />
    </Col>
    <Col
      className={styles.loadingButton}
      span={6}
    >
      <Skeleton.Button
        size="large"
        active
        block
      />
    </Col>
  </Row>
);

const ContentGuidelinesLoading:React.FC<IProps> = (props) => {
  const { isMobile } = props;
  return (
    <>
      <LoadingHeader isMobile={isMobile} />
      <LoadingList />
    </>
  );
};

export default ContentGuidelinesLoading;
