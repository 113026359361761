import gql from 'graphql-tag';

import {
  NOTIFICATION_SETTING_FRAGMENT,
} from './fragments';

export const GET_NOTIFICATION_SETTINGS_QUERY = gql`
  query GetNotificationSettingsQuery {
    settings: getNotificationSettings {
      ...NotificationSettingFragment
    }
  }
  ${NOTIFICATION_SETTING_FRAGMENT}
`;

export const SET_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation SetNotificationSettingsMutation($params: NotificationSettingsInput!) {
    succeeded: setNotificationSettings(params: $params)
  }
`;
