import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import { Button, SubmitButton } from '@components';
import { Input } from '@components';
import { Modal } from '@components';
import { CopyLinkInput } from '@components';
import { EmailInput, IEmailRecipient, ISuggestedRecipient } from '@components';

const { useState, useCallback } = React;
import styles from './ShareModal.scss';

interface IProps {
  shareLink: string;
  show: boolean;
  isSubmitting: boolean;
  onRequestClose(): void;
  onRequestShare(emails: string[], note: string): void;

  suggestedRecipients?: ISuggestedRecipient[];

  classNames?: string[];
}

/**
 * @type {React.FunctionComponent}
 */
export const ShareModal: React.FunctionComponent<IProps> = (props) => {
  const {
    classNames,
    isSubmitting,
    onRequestClose,
    onRequestShare: onRequestShareProp,
    shareLink,
    show,
    suggestedRecipients,
  } = props;

  const [emails, setEmails] = useState<string[]>([]);
  const [note, setNote] = useState<string>('');
  const onRecipientsChange = useCallback((recipients: IEmailRecipient[]) => {
    const validEmails = recipients
      .filter((recipient) => recipient.isValid)
      .map((recipient) => recipient.email);
    setEmails(validEmails);
  }, []);
  const onNoteChange = useCallback((note: string) => {
    setNote(note);
  }, []);
  const onRequestShare = useCallback(() => {
    onRequestShareProp(emails, note);
  }, [onRequestShareProp, emails, note]);

  return (
    <Modal
      show={show}
      onRequestClose={onRequestClose}
      className={cx(styles.ShareModal, classNames)}
      title="Share with Others"
      footer={(
        <>
          <SubmitButton
            label="Send"
            submittingLabel="Sending..."
            isSubmitting={isSubmitting}
            className={cx(styles.button, styles.left)}
            disabled={isEmpty(emails) || isSubmitting}
            onClick={onRequestShare}
          />
          <Button
            label="Cancel"
            theme="info"
            round
            className={styles.button}
            disabled={isSubmitting}
            onClick={onRequestClose}
          />
        </>
      )}
    >
      <div className={styles.modalContent}>
        <CopyLinkInput className={styles.linkSection} shareLink={shareLink} />
        <div className={styles.text}>Share by email:</div>
        <EmailInput
          suggestedRecipients={suggestedRecipients}
          onRecipientsChange={onRecipientsChange}
        />
        <div className={styles.text}>(Optional) Add a message:</div>
        <Input theme="info" onChange={onNoteChange} />
      </div>
    </Modal>
  );
};

ShareModal.defaultProps = {
  suggestedRecipients: [],
  classNames: [],
};
ShareModal.displayName = 'ShareModal';
