/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_f34gr_157 {
  display: flex;
}

._justify-content-space-between_f34gr_161 {
  justify-content: space-between;
}

._tabular-nums_f34gr_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_f34gr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ModifyProgramMembersModal_f34gr_178 {
  z-index: 9600 !important;
}
._ModifyProgramMembersModal_f34gr_178 ._content_f34gr_181 {
  overflow-y: auto;
  height: 18.75rem;
}
._ModifyProgramMembersModal_f34gr_178 ._label_f34gr_185 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ModifyProgramMembersModal_f34gr_178 ._img_f34gr_190 {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
  border-radius: 0.5rem;
}