import * as React from 'react';
import cx from 'classnames';
import xss from 'xss';
import { ITemplateProps } from '../types';

import styles from './About.scss';
import { RichTextVisualizer } from '../../RichTextEditor/Visualizer';

export type IAboutProps = ITemplateProps['about'];

export const About: React.FC<IAboutProps> = ({
  show = true,
  brand_images,
  description,
  title,
  componentStyles,
}) => {
  if (!show) {
    return null;
  }

  const showBrandImages = (images) => images.map((image, index) => {
    const imageStyles = {
      backgroundImage: `url(${image})`,
    };

    return (
      <div
        key={index}
        style={imageStyles}
        className={styles.brandImage}
      />
    );
  });

  return (
    <div className={cx(styles.About)}>
      <h3
        className={styles.title}
        style={componentStyles.heading}
      >
        {title}
      </h3>

      <RichTextVisualizer
        className={styles.description}
        style={componentStyles.body}
      >
        {xss(description)}
      </RichTextVisualizer>

      <div className={styles.brandImageContainer}>{showBrandImages(brand_images)}</div>
    </div>
  );
};
