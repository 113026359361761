import * as React from 'react';
import {
 map, filter, join, isEmpty,
} from 'lodash';
import { Space, Typography } from '@revfluence/fresh';

import { MemberInput } from '@frontend/app/types/globalTypes';
import { localToUTC } from '@frontend/app/utils';
import { MemberFieldSchemasBySectionsQuery_sections_memberData } from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';

import { FieldRow, GroupFieldRow } from '../FieldRow';

type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_memberData;

const { useMemo } = React;
const { Text } = Typography;

const BIRTHDAY_FIELD = 'Birthday';

function renderAddress(values: string[]) {
  const [
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
  ] = values;
  const name = `${firstName} ${lastName}`.trim();
  const cityLine = `${city}, ${state} ${postalCode}`.trim();

  const empty = values.every((v) => isEmpty(v));
  if (empty) {
    return <Text>-</Text>;
  }

  return (
    <Space direction="vertical" size={0}>
      {name && (
        <Text>
          {`${name}`}
          <br />
        </Text>
      )}
      {address1 && (
        <Text>
          {`${address1}`}
          <br />
        </Text>
      )}
      {address2 && (
        <Text>
          {`${address2}`}
          <br />
        </Text>
      )}
      {cityLine !== ',' && (
        <Text>
          {`${cityLine}`}
          <br />
        </Text>
      )}
      {country && <Text>{country}</Text>}
    </Space>
  );
}

function copyAddress(values: string[]) {
  const [
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
  ] = values;
  const name = `${firstName} ${lastName}`.trim();
  const cityLine = `${city}, ${state} ${postalCode}`.trim();
  const address = join(
    filter([name, address1, address2, cityLine === ',' ? '' : cityLine, country], (val) => !!val),
    '\n',
  );

  navigator.clipboard.writeText(address);
}

interface IProps {
  member?: MemberInput;
  schemas?: MemberFieldSchema[];
  onSave: (value: MemberInput) => Promise<void>;
  viewMore?: boolean;
}

const MemberData: React.FunctionComponent<IProps> = (props) => {
  const {
    member, schemas = [], onSave, viewMore,
  } = props;

  const visibleFields = useMemo(() => (
    viewMore ? schemas.slice(10) : schemas.slice(10, 13)
  ), [schemas, viewMore]);

  const handleSaveNames = async (value: Record<number, string>) => {
    const firstNameField = schemas[0];
    const lastNameField = schemas[1];
    const firstNameInput = value[firstNameField.id];
    const lastNameInput = value[lastNameField.id];

    if (firstNameInput || lastNameInput) {
      const firstName = firstNameInput || member.fields[firstNameField.id] || '';
      const lastName = lastNameInput || member.fields[lastNameField.id] || '';
      await onSave({
        name: `${firstName} ${lastName}`.trim(),
        fields: value,
      });
    }
  };

  const handleSaveField = async (field: MemberFieldSchema, val: string) => {
    if (field.name === BIRTHDAY_FIELD) {
      // validate birthday new value is a valid date
      if (isNaN(Date.parse(val))) {
        throw new Error('Invalid date');
      }

      await onSave({
        fields: {
          [field.id]: localToUTC(new Date(val)),
        },
      });
    } else {
      await onSave({
        fields: {
          [field.id]: val,
        },
      });
    }
  };

  if (!member) {
    return null;
  }

  return (
    <Space direction="vertical">
      <GroupFieldRow
        label="Name"
        member={member}
        schemas={schemas.slice(0, 2)}
        separator={' '}
        onSave={handleSaveNames}
      />
      <FieldRow
        label="Email"
        value={member.email}
        editable
        type="EMAIL"
        copyable
        onSave={(value) => onSave({ email: value })}
      />
      <GroupFieldRow
        label="Address"
        member={member}
        schemas={schemas.slice(2, 10)}
        onSave={(value) => onSave({ fields: value })}
        renderValues={renderAddress}
        copyable
        copyValues={copyAddress}
      />
      {map(visibleFields, (field) => (
        <FieldRow
          key={field.id}
          label={field.name}
          value={member.fields[field.id]}
          editable={field.metaData?.editable}
          type={field.type}
          choices={field.choices}
          onSave={(val) => handleSaveField(field, val)}
        />
      ))}
    </Space>
  );
};

export default MemberData;
