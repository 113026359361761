import gql from 'graphql-tag';

export const REQUIREMENT_FRAGMENT = gql`
  fragment RequirementFragment on Requirement {
    id
    name
    memberId
    description
    dueDate
    completed
  }
`;
