/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1i18k_157 {
  display: flex;
}

._justify-content-space-between_1i18k_161 {
  justify-content: space-between;
}

._tabular-nums_1i18k_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1i18k_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._EmailComposer_1i18k_178 ._info_1i18k_178 ._fromAndTo_1i18k_178 ._to_1i18k_178 ._memberCount_1i18k_178, ._EmailComposer_1i18k_178 ._section_1i18k_178 ._label_1i18k_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._EmailComposer_1i18k_178 {
  height: 25rem;
}
._EmailComposer_1i18k_178._editorExpanded_1i18k_222 {
  height: 45rem;
}
._EmailComposer_1i18k_178 ._section_1i18k_178 {
  padding: 1rem 1.5rem;
  border-bottom: 0.0625rem solid #dadcde;
  flex-shrink: 0;
}
._EmailComposer_1i18k_178 ._section_1i18k_178 ._label_1i18k_178 {
  margin-right: 0.75rem;
  color: #8f8d91;
}
._EmailComposer_1i18k_178 ._info_1i18k_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
._EmailComposer_1i18k_178 ._info_1i18k_178._noTopBorder_1i18k_240 {
  border-top: none;
}
._EmailComposer_1i18k_178 ._info_1i18k_178 ._fromAndTo_1i18k_178 {
  flex: 1;
}
._EmailComposer_1i18k_178 ._info_1i18k_178 ._fromAndTo_1i18k_178 ._from_1i18k_178 {
  margin-bottom: 0.5rem;
}
._EmailComposer_1i18k_178 ._info_1i18k_178 ._fromAndTo_1i18k_178 ._to_1i18k_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._EmailComposer_1i18k_178 ._info_1i18k_178 ._fromAndTo_1i18k_178 ._to_1i18k_178 ._memberCount_1i18k_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  background: #e2e2e2;
}
._EmailComposer_1i18k_178 ._info_1i18k_178 ._fromAndTo_1i18k_178 ._to_1i18k_178 ._memberInput_1i18k_263 {
  flex: 1;
  margin-right: 1.25rem;
}
._EmailComposer_1i18k_178 ._info_1i18k_178 ._messageTypeSelect_1i18k_267 {
  flex-shrink: 0;
  width: 7.8125rem;
  margin-left: auto;
}
._EmailComposer_1i18k_178 ._subject_1i18k_272 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.0625rem solid #dadcde;
}
._EmailComposer_1i18k_178 ._editor_1i18k_222 {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
._EmailComposer_1i18k_178 ._composeButton_1i18k_285 {
  min-width: 9.375rem;
}
._EmailComposer_1i18k_178 ._bulkComposeButton_1i18k_288 {
  margin-left: 0.75rem;
}