/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1egn3_157 {
  display: flex;
}

._justify-content-space-between_1egn3_161 {
  justify-content: space-between;
}

._tabular-nums_1egn3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1egn3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ResizableCell_1egn3_178 {
  position: relative;
}
._ResizableCell_1egn3_178 ._handle_1egn3_181 {
  position: absolute;
  top: 0;
  right: -0.375rem;
  width: 0.75rem;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ew-resize;
  opacity: 0;
  z-index: 1;
  background: transparent;
  border: 0;
  outline: 0;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._ResizableCell_1egn3_178 ._handle_1egn3_181:hover {
  opacity: 1;
}
._ResizableCell_1egn3_178 ._handle_1egn3_181::after {
  width: 0.125rem;
  height: 80%;
  display: block;
  background: rgba(57, 150, 224, 0.8);
  content: " ";
  border-radius: 0.0625rem;
}