/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fz7f_188 {
  display: flex;
}

._justify-content-space-between_1fz7f_192 {
  justify-content: space-between;
}

._tabular-nums_1fz7f_196 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fz7f_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Button_1fz7f_209 {
  height: 2.5rem;
  border-radius: 0.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: -0.025rem;
}
._Button_1fz7f_209.ant-btn-primary {
  background-color: #3996e0;
  color: #fdfdfd;
}
._Button_1fz7f_209.ant-btn-primary:hover {
  background-color: #4fa7ee;
}
._Button_1fz7f_209.ant-btn-primary:disabled {
  opacity: 0.5;
}
._Button_1fz7f_209.ant-btn-primary.ant-btn-dangerous {
  background-color: #f1515f;
}
._Button_1fz7f_209.ant-btn-primary.ant-btn-dangerous:hover {
  background-color: #ff7875;
  border-color: #ff7875;
}
._Button_1fz7f_209._round_1fz7f_233 {
  border-radius: 6.25rem;
}