/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_pp6t4_157 {
  display: flex;
}

._justify-content-space-between_pp6t4_161 {
  justify-content: space-between;
}

._tabular-nums_pp6t4_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_pp6t4_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._charCounter_pp6t4_209 {
  display: flex;
  justify-content: flex-end;
  color: #aeaeae;
  position: relative;
}