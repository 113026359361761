import * as React from 'react';
import cx from 'classnames';
import { each, map } from 'lodash';

import { IContentReview } from '@components';
import { SubmittedContent, TContent } from './SubmittedContent';

import styles from './PreReviewPanel.scss';

interface IProps {
  review: IContentReview;
  onRequestClose(): void;
  onSendToGCR(): void;
  onRequestChanges(comment: string, checkOffs?: boolean[]): void;

  loading?: boolean;
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const PreReviewPanel: React.FC<IProps> = React.memo(({
  review, onRequestClose, onSendToGCR, onRequestChanges, loading, className,
}) => {
  const previewContents = useMemo(() => {
    const mediaList = review.content.media || review.content.saved_media_list;
    const previewMap = review.content.preview_map || {};
    const contentList = [];
    each(mediaList, (media) => {
      if (
        !['aiq_preview_resolution_original', 'aiq_preview_resolution'].includes(media.resolution)
      ) {
        contentList.push({
          type: media.media_type,
          src: media.url,
          downloadableUrl: media.url,
          previewUrl: (previewMap[media.url] || {}).aiq_preview_resolution_original || media.url,
          thumbnailUrl: (previewMap[media.url] || {}).aiq_preview_resolution || media.url,
          text: review.content.caption || '',
        });
      }
    });

    return contentList;
  }, [review]);
  const contentItems: TContent = useMemo(() => ({
      issues: map(review.product.checkoff_guidelines, (guideline, index) => ({
        id: `${index}`,
        description: guideline,
      })),
      previews: previewContents,
      caption: review.content?.caption,
      onClose: onRequestClose,
      onSendToGCR,
      onRequestChanges,
      loading,
    }), [review, previewContents, onRequestClose, onSendToGCR, onRequestChanges, loading]);

  return (
    <div className={cx(styles.PreReviewPanel, className)}>
      <SubmittedContent {...contentItems} />
    </div>
  );
});

PreReviewPanel.defaultProps = {
  className: null,
};

PreReviewPanel.displayName = 'PreReviewPanel';
