import { useQuery, QueryHookOptions } from '@apollo/client';

import { JOB_STATUS_QUERY } from '@frontend/app/queries/JobStatusQuery';
import {
  JobStatusQuery,
  JobStatusQueryVariables,
} from '@frontend/app/queries/types/JobStatusQuery';

type IOptions = QueryHookOptions<JobStatusQuery, JobStatusQueryVariables>;

export const useJobStatusQuery = (parentJobId: string, options: IOptions = {}) => useQuery<JobStatusQuery, JobStatusQueryVariables>(JOB_STATUS_QUERY, {
    ...options,
    variables: {
      parentJobId,
    },
  });
