import gql from 'graphql-tag';

export const TASK_TRACKER_QUERY = gql`
  query TaskTrackerQuery($id: String!) {
    tracker: getTaskTrackerById(id: $id) {
      id
      clientId
      parentId
      url
      success
      createdDate
      taskProgress {
        id
        progressPercentage
        status
        details {
          total
          success
          fail
        }
      }
    }
  }
`;
