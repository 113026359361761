export interface IOnboardingTemplateConfigProperties {
  time?: number;
  version?: string;
  settings?: ISettings;
  intro?: IIntro;
  about?: IAbout;
  perks?: IPerks;
  persona?: IPersona;
  application?: IApplication;
  fieldsSettings?: IFieldSettings[];
}

export interface IFieldSettings {
  fieldName: string;
  isHidden?: boolean;
}

interface ISettings {
  page_online: boolean;
  url?: string;
  favicon?: string;
  logo?: string;
  page_color?: IPageColor;
  form_color?: IPageColor;
  headline_styling?: IBlockStyling;
  body_styling?: IBlockStyling;
  cta_styling?: ICTAStyling;
}

interface IPageColor {
  background_color: string;
  is_transparent?: boolean;
}

interface IBlockStyling {
  font_family?: string;
  font_size?: number;
  fill_color?: string;
}

interface ICTAStyling {
  font_family?: string;
  font_color?: string;
  button_color?: string;
}

interface IIntro {
  show?: boolean;
  title?: string;
  subtitle?: string;
  hero_image?: string;
  description?: string;
  button_text?: string;
}

interface IAbout {
  show?: boolean;
  description?: string;
  brand_images?: string[];
}

interface IPerks {
  show?: boolean;
  bullet_style?: string;
  items?: string[];
  compensation?: string[];
  availability?: string[];
  compensationTitle?: string;
  availabilityTitle?: string;
}

interface IPersona {
  show?: boolean;
  cover_image?: string;
  bullet_style?: string;
  features?: string[];
}

interface IApplication {
  description?: string;
  support_email?: string;
  unpaidLabel?: string;
  unpaidDescription?: string;
  apply_label?: string;
}
