import React, { useCallback, useEffect, useState } from 'react';
import {
 Button, Input, Space, Table, Tooltip, Typography,
} from '@revfluence/fresh';
import { Pagination } from '@frontend/app/components';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular';
import { PencilIcon, SplitIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatValue, DataFormat } from '@affiliates/utils';
import styles from './ProjectBudgetTable.module.scss';
import { IBudget } from '../../types/IBudget';

const { Text } = Typography;

interface IProps {
  budgets: IBudget[];
  onClickBudget: (id: number) => void;
  quarterNames: object;
}

const renderCurrency = (value: number) => `$${formatValue(DataFormat.Money, value)}`;

const getQuarterBorderColor = (quarterKey: string) => {
  switch (quarterKey) {
    case 'Q1':
      return '#FFCD5B';
    case 'Q2':
      return '#167CF4';
    case 'Q3':
      return '#1E4945';
    case 'Q4':
      return '#923562';
    default:
      return '#6C77DE';
  }
};

const getQuarterColumns = (quarterKey) => [
  {
    title: <div>Total Budget</div>,
    key: 'totalAmount' as string,
    dataIndex: 'quarterDistributions',
    className: styles.quarterAmount,
    width: 120,
    render: (data) => {
      const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
      return renderCurrency(quarterData?.totalAmount);
    },
  },
  {
    title: <div>Allocated</div>,
    key: 'allocatedAmount' as string,
    dataIndex: 'quarterDistributions',
    className: styles.quarterAmount,
    width: 120,
    render: (data) => {
      const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
      return renderCurrency(quarterData?.allocatedAmount);
    },
  },
  {
    title: <div>Spent</div>,
    key: 'spentAmount' as string,
    dataIndex: 'quarterDistributions',
    className: styles.quarterAmount,
    width: 120,
    render: (data) => {
      const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
      return renderCurrency(quarterData?.spentAmount);
    },
  },
  {
    title: <div>Remaining</div>,
    key: 'availableAmount' as string,
    dataIndex: 'quarterDistributions',
    className: styles.quarterAmount,
    width: 120,
    render: (data) => {
      const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
      return renderCurrency(quarterData?.availableAmount);
    },
  },
];

const TABLE_PAGE_SIZE = 10;

const ProjectBudgetTable: React.FC<IProps> = (props) => {
  const { budgets, onClickBudget, quarterNames } = props;
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [showQuarterlyAmounts, setShowQuarterlyAmounts] = useState<boolean>(false);
  const toggleQuarterlyAmounts = useCallback(() => setShowQuarterlyAmounts((prev) => !prev), [setShowQuarterlyAmounts]);

  useEffect(() => {
    setPage(1);
  }, [budgets]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e.target.value);
  };

  const filteredBudgets = budgets.filter((budget) => budget.source.toLowerCase().includes(searchQuery.toLowerCase()));

  const columns = [
    {
      title: 'Source' as string,
      dataIndex: 'source',
      key: 'source',
      className: styles.sourceBudgetColumn,
      sorter: (a: IBudget, b: IBudget) => a.source.localeCompare(b.source),
      fixed: 'left' as const,
    },
    {
      title: 'Project’s Budget' as string,
      dataIndex: 'projectBudget',
      key: 'projectBudget',
      className: styles.amountColumn,
      sorter: (a: IBudget, b: IBudget) => a.projectBudget - b.projectBudget,
      render: (value: number) => renderCurrency(value),
    },
    {
      title: 'Total Spent' as string,
      dataIndex: 'totalSpend',
      key: 'totalSpend',
      className: styles.amountColumn,
      sorter: (a: IBudget, b: IBudget) => a.totalSpend - b.totalSpend,
      render: (value: number) => renderCurrency(value),
    },
    {
      title: 'Remaining Budget' as string,
      dataIndex: 'remainingBudget',
      key: 'remainingBudget',
      className: styles.amountColumn,
      sorter: (a: IBudget, b: IBudget) => a.remainingBudget - b.remainingBudget,
      render: (value: number) => renderCurrency(value),
    },
  ];

  if (showQuarterlyAmounts) {
    Object.entries(quarterNames).forEach(([quarterKey, quarterName]) => {
      columns.push({
        title: (
          <div style={{ borderBottom: `2px solid ${getQuarterBorderColor(quarterKey)}`, paddingBottom: '8px' }}>
            {quarterName}
          </div>
        ) as unknown as string,
        key: quarterName as string,
        className: styles.quarter,
        // @ts-ignore
        // eslint-disable-next-line
        children: getQuarterColumns(quarterKey),
      });
    });
    columns.push({
      title: (
        <div style={{ borderBottom: `2px solid ${getQuarterBorderColor('QO')}`, paddingBottom: '8px' }}>Others</div>
      ) as unknown as string,
      key: 'Others',
      className: styles.quarter,
      // @ts-ignore
      // eslint-disable-next-line
      children: getQuarterColumns('QO'),
    });
  }

  columns.push({
    title: 'Action',
    key: 'action',
    // @ts-ignore
    // eslint-disable-next-line
    // disable tsc on next line
    render: (text: unknown, record: IBudget) => (
      <Space direction="horizontal">
        <Button icon={<PencilIcon />} onClick={() => onClickBudget(record.id)} />
        {/* Will use later */}
        {/* <Button icon={<ArrowUpRightFromSquareIcon />} /> */}
      </Space>
    ) as unknown as string,
  });

  return (
    <div className={styles.projectTableContainer}>
      <div className={styles.projectTableHeaderContainer}>
        <Text>{`${filteredBudgets.length} Source`}</Text>
        <div className={styles.projectTableHeaderActions}>
          {/* Will use later */}
          {/* <Button icon={<SplitIcon />} /> */}
          <Tooltip title="View Quarterly Budget Distribution">
            <Button onClick={toggleQuarterlyAmounts} icon={<SplitIcon />} />
          </Tooltip>
          <div>
            <Input placeholder="Search" prefix={<MagnifyingGlassIcon />} onChange={handleSearchChange} />
          </div>
          <Pagination
            total={filteredBudgets.length}
            page={page}
            setPage={setPage}
            pageSize={TABLE_PAGE_SIZE}
            showTotal
          />
        </div>
      </div>
      <Table
        dataSource={filteredBudgets.slice((page - 1) * TABLE_PAGE_SIZE, page * TABLE_PAGE_SIZE)}
        columns={columns}
        pagination={false}
        className={styles.projectBudget}
      />
    </div>
  );
};
export default ProjectBudgetTable;
ProjectBudgetTable.displayName = 'ProjectBudgetTable';
