import * as React from 'react';
import { useState } from 'react';
import { find } from 'lodash';
import cx from 'classnames';

import { ResourceType } from '@frontend/app/types/globalTypes';
import { LoadSpinner } from '@components';
import GdriveSettingsPageEmpty from '@frontend/applications/GoogleDriveApp/pages/GdriveSettingsPageEmpty';
import { useClientFeatureEnabled, useGetResources, useGetTaskTrackersQuery } from '@frontend/app/hooks';
import { Typography } from '@revfluence/fresh';
import styles from '@frontend/applications/GoogleDriveApp/pages/GdriveSettingsPage.scss';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';

import GDriveSyncContainer from '../container/GDriveSyncContainer';
import SyncProgressContainer from '../container/SyncProgressContainer';

const { useMemo } = React;

const GdriveSettingsPage: React.FC = React.memo(() => {
  const type = ResourceType.GDRIVE;
  const {
    resources,
    loading,
  } = useGetResources({
    fetchPolicy: 'no-cache',
  });
  const [isSyncing, setIsSyncing] = useState(false);
  const showProgressBar = useClientFeatureEnabled(ClientFeature.GOOGLE_DRIVE_LIMITS);

  const { clientId } = useApplication();
  useGetTaskTrackersQuery({
    variables: {
      params: {
        clientId,
        url: '/v1/task/google_drive/sync_client_to_drive',
      },
      limit: 1,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.trackers.length > 0) {
          setTaskTrackerId(data.trackers[0].id);
      }
  },
});
  const [taskTrackerId, setTaskTrackerId] = React.useState<string>();
  const gdriveResource = useMemo(
    () =>
      find(resources, (r) => !r.authProvider.userRevokedAccess && r.type === type),
    [resources, type],
  );

  return (
    <>
      <Typography.Title level={3}>Google Drive</Typography.Title>
      <Typography.Text className={cx(styles.text)}>
        Sync creator content to your Google Drive.
      </Typography.Text>
      <div>
        {loading && !gdriveResource && (
          <LoadSpinner />
        )}
        {!loading && !gdriveResource && <GdriveSettingsPageEmpty type={type} />}
        {gdriveResource && (
        <GDriveSyncContainer
          activeAccount={gdriveResource}
          onSyncCompleted={setTaskTrackerId}
          onSyncStart={() => setIsSyncing(true)}
          inProgress={isSyncing}
        />
)}
        {(showProgressBar && taskTrackerId) && (
        <SyncProgressContainer
          taskTrackerId={taskTrackerId}
          onSyncStatusChange={setIsSyncing}
        />
)}
      </div>
    </>
  );
});

GdriveSettingsPage.displayName = 'GdriveSettingsPage';

export default GdriveSettingsPage;
