import * as React from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';

const ASSETS = process.env.ASSETS;
const defaultImageSrc = `${ASSETS}/content_image_placeholder.png`;

import styles from './LazyImage.scss';

const {
 useCallback, useEffect, useImperativeHandle, useRef, useState,
} = React;

type TBaseProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export interface ILazyImageProps extends TBaseProps {
  fadeIn?: boolean;
  fallbackSrc?: string;
  onError?();
  onLoad?();
  onSizeDetected?(naturalWidth: number, naturalHeight: number);
}

export const LazyImage = React.forwardRef<HTMLImageElement, ILazyImageProps>((props, ref) => {
  const {
    className,
    fadeIn = true,
    src,
    fallbackSrc = defaultImageSrc,
    onError = () => undefined,
    onLoad = () => undefined,
    onSizeDetected = () => undefined,
    ...restProps
  } = props;

  const imageRef = useRef<HTMLImageElement>();
  useImperativeHandle(ref, () => imageRef.current);

  const pollRef = useRef<number>();
  const [completed, setCompleted] = useState(false);

  const onErrorHandler = useCallback(() => {
    if (!imageRef?.current) {
      return;
    }
    setCompleted(true);
    if (isFunction(onError)) {
      onError();
    }
    if (pollRef?.current) {
      window.clearInterval(pollRef?.current);
    }
  }, [imageRef, pollRef, onError, setCompleted]);

  const onLoadHandler = useCallback(() => {
    if (!imageRef?.current) {
      return;
    }
    setCompleted(true);
    if (isFunction(onLoad)) {
      onLoad();
    }
    if (pollRef?.current) {
      window.clearInterval(pollRef?.current);
    }
  }, [imageRef, pollRef, onLoad, setCompleted]);

  useEffect(() => {
    const imageSrc = src || fallbackSrc; // in case src is empty

    const tmpImage = new Image();

    tmpImage.onload = () => {
      if (imageRef?.current) {
        imageRef.current.src = imageSrc;
      }
      onLoadHandler();
    };

    tmpImage.onerror = () => {
      if (imageRef?.current) {
        imageRef.current.src = fallbackSrc;
      }
      onErrorHandler();
    };

    tmpImage.src = imageSrc;

    pollRef.current = window.setInterval(() => {
      const image = imageRef?.current;
      // image could be null, when using fallback src.
      if (image && image.naturalWidth) {
        window.clearInterval(pollRef.current);
        onSizeDetected(image.naturalWidth, image.naturalHeight);
      }
    }, 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fallbackSrc, src]);

  return (
    <img
      {...restProps}
      ref={imageRef}
      className={cx(className, styles.LazyImage, {
        [styles.fadeIn]: fadeIn,
        [styles.completed]: completed,
      })}
    />
  );
});

LazyImage.displayName = 'LazyImage';
