import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';

import { message } from 'antd';

import { GET_ROLES_QUERY } from '@frontend/app/queries';
import {
  GetRolesQuery,
} from '@frontend/app/queries/types/GetRolesQuery';

const { useEffect } = React;
type IOptions = QueryHookOptions<GetRolesQuery>;

export function useGetManagerRoles(options: IOptions = {}) {
  const {
    loading, data: { roles = [] } = {}, error, refetch,
  } = useQuery<GetRolesQuery>(
    GET_ROLES_QUERY,
    {
      ...options,
    },
  );

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    loading,
    roles,
    error,
    refetch,
  };
}
