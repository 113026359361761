import { toLower } from 'lodash';

import { ClientFeature } from '@frontend/app/constants';
import { ClientFeatureState } from '@frontend/app/types/globalTypes';
import {
  useGetAllProjectsWithDeletedCountQuery,
  useGetClientFeatureByName,
  useSaveClientFeature,
} from '@frontend/app/hooks';

export const useRefetch = () => {
  const {
    refetch: refetchAllProjectsCount,
  } = useGetAllProjectsWithDeletedCountQuery();

  const {
    data: {
      clientFeature: showOnboardingForTestingFeature = null,
    } = {},
  } = useGetClientFeatureByName(ClientFeature.SHOW_ONBOARDING_FOR_TESTING);

  const [saveClientFeature] = useSaveClientFeature();

  const refetch = async () => {
    await refetchAllProjectsCount();
    if (showOnboardingForTestingFeature.state !== toLower(ClientFeatureState.DISABLED)) {
      // disable testing flag to allow navigating to project
      await saveClientFeature({
        variables: {
          clientFeature: {
            id: showOnboardingForTestingFeature.id,
            name: 'showOnboardingForTesting',
            state: ClientFeatureState.DISABLED,
          },
        },
      });
    }
  };

  return { refetch };
};
