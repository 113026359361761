/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Emoji_1l7nz_48 {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
._Emoji_1l7nz_48 ._character_1l7nz_53 {
  width: 100%;
  height: 100%;
  display: block;
  font-style: normal;
}

._character_1l7nz_53 {
  transform-origin: center;
}