import React from 'react';
import {
 Typography, Alert, Radio, Collapse, Table, RadioChangeEvent,
} from '@revfluence/fresh';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { capitalize, isEmpty } from 'lodash';
import styles from '../ManageOffer.scss';
import { TMember } from '../../MembersWizard/types';

const { Title, Text } = Typography;
const { Panel } = Collapse;
interface ActivateDeactivatePayoutData {
  key: number;
  name: string;
  code: string;
  payoutId?: number;
  status: string;
  affiliateLink?: string;
}
interface IProps {
  offerSource?: OFFER_SOURCE;
  isMigrationRequired: boolean;
  isWorkFlow?: boolean;
  offer: GetOfferById_offer;
  onChange: (e: RadioChangeEvent) => void;
  activeOrDeactive: string;
  memberToOperate: TMember[];
  selectedMembers?: readonly TMember[];
  activateDeactivatePayoutData: ActivateDeactivatePayoutData[];
  columns: CustomColumn[];
}

interface CustomColumn {
  title: string;
  dataIndex: string;
  key: string;
  width: number;
  ellipsis?: {
    showTitle: boolean;
  };
  render?: (text, record) => JSX.Element;
}

const ManageOfferActivation: React.FC<IProps> = ({
  offerSource,
  isMigrationRequired,
  isWorkFlow,
  offer,
  onChange,
  activeOrDeactive,
  memberToOperate,
  selectedMembers,
  activateDeactivatePayoutData,
  columns,
}) => (
  <div className={styles.manageOfferContainer}>
    <Title level={5}>
      Choose
      {offerSource === OFFER_SOURCE.SHOPIFY ? 'promo code' : 'sales link'}
      {' '}
      status
    </Title>
    <Text>
      Activate or deactivate
      {' '}
      {offerSource === OFFER_SOURCE.SHOPIFY ? 'promo code' : 'sales link'}
      {' '}
      for selected members
      within this offer.
    </Text>

    <div className={styles.ManageOfferAction}>
      {isMigrationRequired && (
        <div className={styles.noticeContainer}>
          <Alert
            message={
              isWorkFlow ? (
                'Please upgrade your offer via Sales Tracking to enable editing and access new features'
              ) : (
                <div>
                  <Text>
                    This offer does not support activation of
                    {' '}
                    {offer && offer.promos.length ? 'promo code' : 'sales link'}
                    {' '}
                    from this drawer. Please close this
                    drawer and click on "Edit Active Dates" button to activate codes.
                  </Text>
                  <br />
                  <Text>
                    You can also upgrade your offer to support new functionalities.
                    <a
                      href="https://help.aspireiq.com/en/articles/8535207-how-to-upgrade-existing-promo-code-offers"
                      target="_blank"
                    >
                      {' '}
                      Read more
                    </a>
                  </Text>
                </div>
              )
            }
            type="error"
            icon={<ExclamationCircleFilled width={36} height={36} />}
          />
        </div>
      )}
      <Radio.Group name="radiogroup" onChange={onChange}>
        <Radio value="activate" style={{ marginBottom: 14 }} disabled={isMigrationRequired}>
          Activate
          {' '}
        </Radio>
        <br />
        <Radio value="deactivate">Deactivate </Radio>
      </Radio.Group>
    </div>

    {!isEmpty(activeOrDeactive) && (
      <Collapse ghost expandIconPosition="start">
        <Panel
          header={`${capitalize(activeOrDeactive)} ${memberToOperate.length}/${selectedMembers.length} ${
            memberToOperate.length === 1 ? 'Member' : 'Members'
          }`}
          key="1"
        >
          <Table columns={columns} dataSource={activateDeactivatePayoutData} pagination={false} />
        </Panel>
      </Collapse>
    )}
  </div>
);

export default ManageOfferActivation;
