import { useMutation, MutationHookOptions } from '@apollo/client';

import { CREATE_IGDM_RESOURCE_MUTATION } from '@frontend/app/queries';
import {
  CreateIgdmResourceMutation, CreateIgdmResourceMutationVariables,
} from '@frontend/app/queries/types/CreateIgdmResourceMutation';

export type ICreateIgdmOptions = MutationHookOptions<CreateIgdmResourceMutation, CreateIgdmResourceMutationVariables>;

export function useCreateIgdmResource(options: ICreateIgdmOptions = {}) {
  const [createIgdmResource, {
    loading,
    error,
  }] = useMutation<CreateIgdmResourceMutation, CreateIgdmResourceMutationVariables>(CREATE_IGDM_RESOURCE_MUTATION, options);

  return {
    loading,
    createIgdmResource,
    error,
  };
}
