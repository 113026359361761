import gql from 'graphql-tag';

const GET_BUDGET_ACCOUNTS = gql`
    query GetBudgetAccountsQuery ($id: Int) {
        budgetAccounts:getBudgetAccounts(id: $id) {
            description
            fiscalGranularity
            id
            name
            parentId
        }
    }
`;

export default GET_BUDGET_ACCOUNTS;
