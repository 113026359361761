import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_CLIENT_ACTIVE_MARKETPLACE_PROJECTS_QUERY } from '@frontend/app/queries';
import {
  GetClientActiveMarketplaceProjectsQuery,
} from '@frontend/app/queries/types/GetClientActiveMarketplaceProjectsQuery';

type IOptions = QueryHookOptions<GetClientActiveMarketplaceProjectsQuery>;

export const useGetClientActiveMarketplaceProjects = (options: IOptions = {}) => useQuery<GetClientActiveMarketplaceProjectsQuery>(
  GET_CLIENT_ACTIVE_MARKETPLACE_PROJECTS_QUERY,
  options,
);
