import gql from 'graphql-tag';

export const GET_PROGRAM_INFO_QUERY = gql`
  query GetProgramInfoQuery($offerId: Int!) {
    programInfoForOffer(offerId: $offerId) {
      memberCount
      membersInOffer
      membersWithCodes
      membersWithLinks
      programId
      programName
    }
  }
`;

export const GET_PROGRAM_MEMBER_INFO_QUERY = gql`
  query GetProgramMemberInfoQuery($offerId: Int!, $programIds: [Int!]!, $offset: Int, $limit: Int,$searchByName: String) {
    memberInfoForOfferAndPrograms(offerId: $offerId, programIds: $programIds, offset: $offset, limit: $limit, searchByName: $searchByName) {
      inOffer
      previouslyUnsuccessful
      instagramUsername
      memberFirstName
      memberId
      memberLastName
      memberName
      programIds
    }
  }
`;
export const GET_PROGRAM_MEMBER_INFO_QUERY_COUNT = gql`
  query GetProgramMemberInfoQueryCount($offerId: Int!, $programIds: [Int!]!,$searchByName: String) {
    memberInfoForOfferAndProgramsCount(offerId: $offerId, programIds: $programIds,searchByName: $searchByName)
  }
`;
