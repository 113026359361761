import * as qs from 'qs';
import {
  Button,
  Divider,
} from 'antd';
import cx from 'classnames';
import {
  chain,
  first,
  get,
  isFunction,
  map,
  filter,
  size,
} from 'lodash';
import * as React from 'react';
import {
 useHistory, useRouteMatch, Switch, Route, useLocation,
} from 'react-router-dom';

import { Space } from '@revfluence/fresh';

import {
  Drawer,
  FilterList,
  IFilter,
  IMemberTableRefHandles,
  MemberTable,
} from '@frontend/app/components';
import { useOrderedMemberColumns } from '@frontend/app/hooks/memberList';
import { TProject } from '@frontend/app/containers/Projects/types';

import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';
import { IColumn } from '@frontend/app/types/Columns';
import { defaultRenderCell } from '@frontend/app/components/MemberTable/utils/defaultRenderCell';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import {
  useClientFeatureEnabled,
  useMemberQuickFiltersCount,
  useRefreshSelectedMembers,
} from '@frontend/app/hooks';
import { SpecialFilters } from '@frontend/app/constants/specialFilters';
import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';
import { ClientFeature, TABLE_PAGE_SIZE } from '@frontend/app/constants';

import { SOCIAL_DISCOVERY_APP_ID } from '@frontend/app/constants/applicationIds';
import { FetchContextProvider } from '@frontend/utils';
import { NewMemberDetailContainer } from '@frontend/app/containers/MemberDetail/NewMemberDetailContainer';
import { useMembershipSources, useOverviewPage } from '../../hooks';
import styles from './ProspectsList.scss';

import { NameCell } from '../Cell/NameCell';
import { TableHeader } from '../TableHeader/TableHeader';
import { ApplicantOperationButtons } from '../TableHeader/ApplicantOperationButtons';
import {
  ProjectsPageState,
} from '../../constants';
import { ModalType } from '../AddMembersToProgramModal/AddMembersToCollectionModal';
import { ProspectsListContextProvider } from './ProspectsListContext';

const {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} = React;

interface IProps {
  className?: string;
  isLoading?: boolean;
  onSelectMemberIds?: (memberIds: number[]) => void;
  pageState: ProjectsPageState;
  project: TProject;
  refetchCounts?: () => Promise<void>;
  openAddToCollectionModal(modalType: ModalType): void;
}

export const ProspectsList: React.FC<IProps> = React.memo(({
  className,
  isLoading: isLoadingProp,
  onSelectMemberIds,
  pageState,
  project,
  refetchCounts,
  openAddToCollectionModal,
}) => {
  const [count, setCount] = useState<number>(0);

  const {
    columns: columnsContext,
    columnVisibility,
    segment,
    searchQuery,
    filters,
    fieldsWithSources,
    updateFilters,
    updateSortDirection,
    updateSearchText,
  } = useMemberListContext();

  const {
    sourceConfigs,
  } = useMembershipSources();

  const { renderArchivedNotice } = useOverviewPage(project);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);

  /**
   * Router
   */
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  /**
   * Table selection
   */
  const [members, setMembers] = useState<IMember[]>();
  const membersTableRef = useRef<IMemberTableRefHandles>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<IMember[]>([]);

  const { refreshSelectedMembers } = useRefreshSelectedMembers({
    selectedMembers,
    members,
    updateSelectedMembers: setSelectedMembers,
  });

  const {
    data: {
      counts: quickFiltersCounts = {},
    } = {},
    refetch: refetchQuickFiltersCounts,
  } = useMemberQuickFiltersCount({
    variables: {
      query: searchQuery,
    },
    skip: !searchQuery,
    fetchPolicy: 'no-cache',
  }) || {};

  useEffect(
    () => {
      if (isFunction(onSelectMemberIds)) {
        onSelectMemberIds(selectedIds);
      }
    },
    [onSelectMemberIds, selectedIds],
  );

  useEffect(() => {
    refreshSelectedMembers();
  }, [refreshSelectedMembers]);

  useEffect(() => {
    // only reset when pathname change, won't impact when query params update
    const query = new URLSearchParams(location.search);
    const fromApplicants = query.get('fromApplicants') === 'true';
    if (!fromApplicants) {
      // prevent clearing selection if fromApplicants is passed
      // to prevent clearing selection from only opening a member details modal
      setSelectedMembers([]);
      setSelectedIds([]);
      membersTableRef.current?.unsetSelectedRows();
      refetchQuickFiltersCounts();
    }
  }, [location.pathname, location.search, refetchQuickFiltersCounts]);

  const handleSelectedMembers = useCallback(
    (selectedMembers: IMember[]) => {
      setSelectedMembers(selectedMembers);
      setSelectedIds(map(selectedMembers, (member) => member.id));
    },
    [setSelectedIds],
  );

  /**
   * Table interaction to show member modal when clicking on name
   */
  const handleRowClicked = useCallback((memberId: IMember['id']) => {
    const urlSearch = new URLSearchParams(location.search);
    urlSearch.append('fromApplicants', 'true');
    history.push({
      ...location,
      pathname: `${match.url}/member/${memberId}`,
      search: urlSearch.toString(),
    });
  }, [
    match,
    location,
    history,
  ]);

  const handleBack = useCallback(() => {
    history.push({
      ...location,
      pathname: match.url,
    });
  }, [location, match, history]);

  /**
   * Refetch all data
   */

  const refetchProjectCounts = useCallback(async () => {
    await refetchCounts();
    await refetchQuickFiltersCounts();
  }, [refetchCounts, refetchQuickFiltersCounts]);

  const refetchData = async (skipMemberTableRefetch: boolean = false) => {
    membersTableRef.current?.unsetSelectedRows();
    setReloading(true);
    if (!skipMemberTableRefetch) {
      await membersTableRef.current?.refetchData();
    }

    await refetchProjectCounts();
    setReloading(false);
  };

  /**
   * Approve / Reject
   */
  const [memberApplicantOperation, setMemberApplicantOperation] = useState<MemberApplicantOperation>();

  const handleMemberApplicantOperationSuccess = useCallback(async (membersIds: IMember['id'][]) => {
    membersTableRef.current?.removeProcessedMemberApplicants(membersIds);

    if (size(membersIds) > 1
      || (size(membersIds) === 1 && first(selectedIds) === first(membersIds))) {
      // clear selection from table after bulk operation
      // or when the single row operation is the selected row
      setSelectedIds([]);
      membersTableRef.current?.unsetSelectedRows();
    } else if (size(membersIds) === 1 && first(selectedIds) !== first(membersIds)) {
      // do not clear table selection if the single row of the operation
      // is not a selected row
      const operationId = first(membersIds);
      setSelectedIds(filter(selectedIds, (id) => id !== operationId));
    }

    await refetchProjectCounts();
    setMemberApplicantOperation(undefined);
  }, [refetchProjectCounts, selectedIds]);

  const handleApplicantOperation = useCallback((operation: MemberApplicantOperation) => {
    setMemberApplicantOperation(operation);
  }, []);

  const handleMemberApplicantOperationError = useCallback(() => {
    setMemberApplicantOperation(undefined);
  }, []);

  /**
   * UI State
   */
  const [isReloading, setReloading] = useState(false);
  const isLoading = (
    isLoadingProp
    || !!memberApplicantOperation
    || isReloading
  );

  const handleFiltersChange = useCallback((updatedFilters: IFilter[]) => {
    setSelectedMembers([]);
    setSelectedIds([]);
    membersTableRef.current?.unsetSelectedRows();
    updateFilters(updatedFilters);
  }, [updateFilters]);

  const fields = useMemo(() => (
    chain(fieldsWithSources)
      .reject({ showInFilters: false })
      .sortBy((field) => field.headerName.toLocaleLowerCase())
      .value()
  ), [fieldsWithSources]);

  const isFilterDisabled = (filter: IFilter) => {
    if (
      pageState === ProjectsPageState.Invited
        && filter.column === SpecialFilters.INVITED_PROGRAMS
    ) {
      return true;
    }

    if (
      pageState === ProjectsPageState.Applicants
        && filter.column === SpecialFilters.SUBMITTED_PROGRAMS
    ) {
      return true;
    }

    if (
      pageState === ProjectsPageState.Rejected
        && filter.column === SpecialFilters.REJECTED_PROGRAMS
    ) {
      return true;
    }

    return false;
  };

  const renderTitle = () => (
    <>
      {
        !isRefreshUIEnabled
        && (
        <div className={styles.title}>
          <Space
            direction="vertical"
            size={[32, 32]}
          >
            {isArchiveProjectEnabled && renderArchivedNotice}
            <h1>
              {pageState === ProjectsPageState.Applicants && 'Applicants'}
              {pageState === ProjectsPageState.Invited && 'Invited'}
              {pageState === ProjectsPageState.Rejected && 'Rejected'}
            </h1>
          </Space>
        </div>
)
      }
      <FilterList
        className={styles.filterList}
        fields={fields}
        filters={filters}
        onChangeFilters={handleFiltersChange}
        isFilterDisabled={isFilterDisabled}
        displayQuickFilters={pageState !== ProjectsPageState.Rejected}
        quickFiltersCounts={quickFiltersCounts}
      />
    </>
);
  const visibleColumns = useOrderedMemberColumns(columnsContext, segment);

  if (columnVisibility) {
    columnVisibility.programMemberships = true;
  }

  const renderNameCell = useCallback((data: { id: number; name: string; profile: string; }) => (
    <NameCell
      accessory={(
        <ApplicantOperationButtons
          disabled={isLoading}
          hideApproveButton={pageState !== ProjectsPageState.Applicants}
          hideRejectButton={pageState !== ProjectsPageState.Applicants}
          inline
          memberIds={data?.id
              ? [data.id]
              : undefined}
          onMemberApplicantOperationSuccess={handleMemberApplicantOperationSuccess}
          onButtonClicked={handleApplicantOperation}
          onError={handleMemberApplicantOperationError}
          project={project}
        />
      )}
      imageUrl={data?.profile}
      name={data?.name}
      onClick={() => handleRowClicked(data?.id)}
    />
    ), [
    handleRowClicked,
    handleApplicantOperation,
    handleMemberApplicantOperationSuccess,
    handleMemberApplicantOperationError,
    isLoading,
    pageState,
    project,
  ]);

  const sortableFieldsForMember = (member: IMember) => {
    let sortOrder = -1;
    if (member.programMemberships) {
      const projectMemberships = filter(member.programMemberships, (membership) => membership.programId == project.id);
      const sourceConfig = get(sourceConfigs, first(projectMemberships)?.utmSource);
      if (sourceConfig) {
        sortOrder = sourceConfig.sortOrder;
      }
    }
    return {
      programMemberships: sortOrder,
    };
  };

  const renderProjectSourceCell = useCallback((data) => {
    const projectMemberships = filter(data, (membership) => membership.programId == project.id);
    const sourceConfig = get(sourceConfigs, first(projectMemberships)?.utmSource);
    const sourceName = sourceConfig ? sourceConfig.name : 'Unknown';

    return (
      <div>{sourceName}</div>
    );
  }, [project?.id, sourceConfigs]);

  const customRenderCell = useCallback((column: IColumn) => {
    switch (column.field) {
      case 'name': {
        return {
          ...column,
          grow: true,
          width: 300,
          minWidth: 200,
          maxWidth: 400,
          headerCellClassName: cx(styles.headerCell, styles.nameHeaderCell),
          render: renderNameCell,
          lockPosition: true,
        };
      }

      case 'programMemberships': {
        return {
          ...column,
          render: renderProjectSourceCell,
        };
      }

      default: {
        const config = defaultRenderCell(column, { refetchProjectCounts, projectId: project.id });
        return {
          ...config,
          editable: false,
          headerCellClassName: cx(config.headerCellClassName, styles.headerCell),
        };
      }
    }
  }, [project?.id, refetchProjectCounts, renderNameCell, renderProjectSourceCell]);

  const currentMembersCount = selectedIds?.length
    ? selectedIds?.length
    : count;

  const renderTableHeader = () => (
    <div className={styles.tableHeader}>
      <TableHeader
        buttons={{
          approve: pageState === ProjectsPageState.Applicants,
          reject: pageState === ProjectsPageState.Applicants,
          delete: false,
          applications: false,
          columns: true,
          refresh: true,
          tags: true,
          compose: true,
          cta: true,
          ctaBulkAction: true,
        }}
        count={currentMembersCount}
        countSuffix={selectedIds?.length ? 'selected' : undefined}
        disabledButtons={{
          approve: isLoading,
          refresh: isLoading,
          reject: isLoading,
          tags: isLoading,
        }}
        onButtonClicked={handleApplicantOperation}
        memberApplicantOperation={memberApplicantOperation}
        onMemberApplicantOperationSuccess={handleMemberApplicantOperationSuccess}
        onMemberApplicantOperationError={handleMemberApplicantOperationError}
        pageState={pageState}
        project={project}
        openAddToCollectionModal={openAddToCollectionModal}
        refetchData={refetchData}
        selectedMembers={selectedMembers}
        selectedMemberIds={selectedIds}
      />
    </div>
  );

  const goToCreatorSearch = () => {
    const searchQuery = {
      projectId: project.id,
    };
    history.push(`/app/${SOCIAL_DISCOVERY_APP_ID}?${qs.stringify(searchQuery)}`);
  };

  const renderEmptyPlaceholder = () => {
    if (pageState === ProjectsPageState.Invited) {
      return (
        <div className={styles.empty}>
          <p>
            Invited creators will appear here. In the meantime, discover millions of creators on
            {' '}
            <a onClick={() => goToCreatorSearch()}>Creator Search</a>
            {' '}
            and invite them to apply to your project.
          </p>
          <Button
            onClick={() => goToCreatorSearch()}
            type="primary"
          >
            Discover Creators
          </Button>
        </div>
      );
    }
    if (pageState === ProjectsPageState.Applicants) {
      return (
        <div className={styles.empty}>
          <p>
            Applicants to this project will appear here. In the meantime, discover millions of
            creators on
            {' '}
            <a onClick={() => goToCreatorSearch()}>Creator Search</a>
            {' '}
            and invite them
            to apply to your project.
          </p>
          <Button
            onClick={() => goToCreatorSearch()}
            type="primary"
          >
            Discover Creators
          </Button>
        </div>
      );
    }
    if (pageState === ProjectsPageState.Rejected) {
      return (
        <div className={styles.empty}>
          <p>
            Applicants that are rejected from this project will appear here.
          </p>
        </div>
      );
    }
  };

  const isPredefinedSegment = segment && segment.__typename === 'PredefinedSegment';

  const handleSortChange = (column: string, sortDir: 'ASC' | 'DESC') => {
    updateSortDirection({ columnKey: column, order: sortDir });
  };

  const renderTable = () => (
    <ProspectsListContextProvider
      tableRef={membersTableRef}
    >
      <div className={styles.tableWrapper}>
        <MemberTable
          columns={visibleColumns}
          columnVisibility={columnVisibility}
          headerActions={renderTableHeader()}
          onLoadMembers={(members) => {
            setCount(members.length);
          }}
          allowSearch
          onSelectedMembersChange={handleSelectedMembers}
          pageSize={TABLE_PAGE_SIZE}
          ref={membersTableRef}
          renderCell={customRenderCell}
          renderEmptyState={renderEmptyPlaceholder}
          enableColumnReordering
          searchQuery={searchQuery}
          segmentId={segment?.id}
          segmentType={isPredefinedSegment ? 'predefined' : 'custom'}
          showCheckboxColumn
          showMembersChangedNotice={false}
          showSelectAllCheckbox
          onMembersUpdated={setMembers}
          onSortDirChange={handleSortChange}
          onSearchTextChange={updateSearchText}
          sortableFieldsForMember={sortableFieldsForMember}
          initialColumnSort={{ programMemberships: 'DESC' }}
          refetchProjectCounts={refetchCounts}
        />
      </div>
    </ProspectsListContextProvider>
  );

  return (
    <div
      className={cx(
        styles.ListDrawer,
        className,
        { [styles.loading]: isLoading },
      )}
    >
      <Drawer
        className={styles.drawer}
        expanded
        hideToggle
      >
        {renderTitle()}
        <Divider className={styles.divider} />
        {renderTable()}
      </Drawer>
      <section className={styles.content}>
        <Switch>
          <Route
            path={`${match.path}/member/:memberId`}
            render={(routeProps) => (
              <Drawer
                className={cx(styles.drawer, styles.memberDetailsDrawer)}
                expanded
                hideToggle
              >
                {project && (
                <FetchContextProvider>
                  <NewMemberDetailContainer
                    memberId={parseInt(routeProps.match.params.memberId, 10)}
                    onBackButtonClicked={handleBack}
                    isDeliverablesHidden
                  />
                </FetchContextProvider>
                  )}
              </Drawer>
            )}
          />
        </Switch>
      </section>
    </div>
  );
});

ProspectsList.displayName = 'ProspectsList';
