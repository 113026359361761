/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_nbwdh_157 {
  display: flex;
}

._justify-content-space-between_nbwdh_161 {
  justify-content: space-between;
}

._tabular-nums_nbwdh_165 {
  font-variant-numeric: tabular-nums;
}

._ContentGuidelines_nbwdh_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_nbwdh_254 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._ContentGuidelines_nbwdh_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._ContentGuidelines_nbwdh_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._ContentGuidelines_nbwdh_169 ::-webkit-scrollbar-track, ._ContentGuidelines_nbwdh_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._ContentGuidelines_nbwdh_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._ContentGuidelines_nbwdh_169 ._header_nbwdh_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ContentGuidelines_nbwdh_169 ._header_nbwdh_204 {
  margin: 5px 0;
}
._ContentGuidelines_nbwdh_169 ._suggestion_nbwdh_248 {
  color: #8f8d91;
}
._ContentGuidelines_nbwdh_169 ._list_nbwdh_251 {
  overflow: auto;
}
._ContentGuidelines_nbwdh_169 ._list_nbwdh_251:not(._sorting_nbwdh_254) ._listItemWrapper_nbwdh_254:hover:not(._showingInput_nbwdh_254):not(._emptyGuideline_nbwdh_254) ._hoverBox_nbwdh_254 {
  background-color: #eff5f9;
}
._ContentGuidelines_nbwdh_169 ._list_nbwdh_251:not(._sorting_nbwdh_254) ._listItemWrapper_nbwdh_254:hover:not(._showingInput_nbwdh_254):not(._emptyGuideline_nbwdh_254) ._dragHandle_nbwdh_257 {
  display: block;
}
._ContentGuidelines_nbwdh_169 ._listItemWrapper_nbwdh_254 {
  position: relative;
  padding-right: 5px;
  padding-left: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
}
._ContentGuidelines_nbwdh_169 ._listItemWrapper_nbwdh_254 ._dragHandle_nbwdh_257 {
  display: none;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  cursor: move;
  color: #bdbdbd;
}
._ContentGuidelines_nbwdh_169 ._listItemWrapper_nbwdh_254 ._hoverBox_nbwdh_254 {
  padding: 4px;
  border-radius: 6px;
}
._ContentGuidelines_nbwdh_169 ._listItemWrapper_nbwdh_254._showingInput_nbwdh_254 ._hoverBox_nbwdh_254 {
  padding-top: 0;
  padding-bottom: 0;
}
._ContentGuidelines_nbwdh_169 ._listItemWrapper_nbwdh_254._dragging_nbwdh_284 ._hoverBox_nbwdh_254 {
  background-color: #eff5f9;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.18);
}
._ContentGuidelines_nbwdh_169 ._listItem_nbwdh_254 {
  margin: 0px 4px 0px 20px;
  display: list-item;
  list-style-type: disc;
}
._ContentGuidelines_nbwdh_169 ._guidelineWrapper_nbwdh_293 {
  padding-left: 4px;
  padding-right: 4px;
  cursor: default;
}
._ContentGuidelines_nbwdh_169 ._guideline_nbwdh_293 {
  white-space: pre-wrap;
  word-wrap: break-word;
  vertical-align: top;
  user-select: none;
}
._ContentGuidelines_nbwdh_169 ._inputWrapper_nbwdh_304 {
  border: 1px solid #e9e8ea;
  padding: 3px;
}
._ContentGuidelines_nbwdh_169 ._input_nbwdh_304 {
  border: none;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  font-family: inherit;
  width: 100%;
  outline: none;
  vertical-align: top;
}