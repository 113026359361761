import gql from 'graphql-tag';

export const START_KLAVIYO_SYNC_MUTATION = gql`
  mutation StartKlaviyoSyncMutation($groups: [Int!]!, $projects: [Int!]!) {
    syncJobStatus: startKlaviyoSync(groups: $groups, projects: $projects) {
      id
      name
      completeItems
      totalItems
      failedItems
      status
      createdDate
    }
  }
`;
