import * as React from 'react';
import {
  Route, Switch, useLocation, useRouteMatch,
} from 'react-router-dom';
import { useQueryParams } from '@frontend/app/hooks';

import { BackLink as BackLinkComponent } from '@affiliates/components';

interface IBackLinkProps {
  baseUri: string;

}

export const BackLink: React.FC<IBackLinkProps> = ({ baseUri }) => {
  const location = useLocation();
  const match = useRouteMatch();
  const query = useQueryParams();
  const queryProgramId = query.get('program_id');
  const queryWorkflowSpecUri = query.get('worklet_spec_uri');
  const queryTaskId = query.get('task_id');
  const queryHasWorkflowParams = queryProgramId && queryWorkflowSpecUri && queryTaskId;
  return (
    <Switch>
      <Route
        path={`${match.url}/dashboard`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <BackLinkComponent
                location={location}
                path={`/projects/${queryProgramId}/${queryWorkflowSpecUri}/${queryTaskId}`}
              />
            );
          }
        }}
      />
      <Route
        path={`${match.url}/offers/newAffiliateLink`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <BackLinkComponent
                location={location}
                path={`/projects/${queryProgramId}/${queryWorkflowSpecUri}/${queryTaskId}`}
              />
            );
          }
          return <BackLinkComponent location={location} path={`${baseUri}`} />;
        }}
      />
      <Route
        path={`${match.url}/offers/newShopifyPromo`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <BackLinkComponent
                location={location}
                path={`/projects/${queryProgramId}/${queryWorkflowSpecUri}/${queryTaskId}`}
              />
            );
          }
          return <BackLinkComponent location={location} path={`${baseUri}`} />;
        }}
      />
      <Route
        path={`${match.url}/offers/:offerid/details`}
        render={() => <BackLinkComponent location={location} path={`${baseUri}`} />}
      />
      <Route
        path={`${match.url}/offers/:offerid/edit`}
        render={({
          match: {
            params: { offerid },
          },
        }) => <BackLinkComponent location={location} path={`${match.url}/offers/${offerid}/details`} />}
      />
      <Route
        path={`${match.url}/offers/:offerId/confirmation`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <BackLinkComponent
                location={location}
                path={`/projects/${queryProgramId}/${queryWorkflowSpecUri}/${queryTaskId}`}
              />
            );
          }
          return <BackLinkComponent location={location} path={`${baseUri}`} />;
        }}
      />
    </Switch>
  );
};
