import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const KeyboardArrowDownIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M22,6.68c0,0.27-0.09,0.54-0.28,0.77l-8.79,10.65c-0.23,0.28-0.57,0.44-0.93,0.44c-0.36,0-0.7-0.16-0.93-0.44
      L2.28,7.44c-0.42-0.51-0.35-1.28,0.16-1.7c0.51-0.42,1.28-0.35,1.7,0.16L12,15.43l7.86-9.52c0.42-0.51,1.19-0.59,1.7-0.16
      C21.85,5.98,22,6.33,22,6.68z"
    />
  </SvgIcon>
));

KeyboardArrowDownIcon.displayName = 'KeyboardArrowDownIcon';

export { KeyboardArrowDownIcon };
