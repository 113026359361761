import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const CheckIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
  </SvgIcon>
));

CheckIcon.displayName = 'CheckIcon';

export { CheckIcon };
