import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_CATALOG_PREVIEW_IFRAME_URL_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import {
  GetCatalogPreviewIframeUrl,
  GetCatalogPreviewIframeUrlVariables,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/GetCatalogPreviewIframeUrl';

type IOptions = QueryHookOptions<GetCatalogPreviewIframeUrl, GetCatalogPreviewIframeUrlVariables>;

export const useGetCatalogPreviewIframeUrl = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch,
} = useQuery(GET_CATALOG_PREVIEW_IFRAME_URL_QUERY, options);
  return {
    catalogPreviewIframeUrl: data?.getCatalogPreviewIframeUrl,
    isCatalogPreviewIframeUrlLoading: loading,
    catalogPreviewIframeUrlError: error,
    refetchCatalogPreviewIframeUrl: refetch,
  };
};
