/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1tp6a_157 {
  display: flex;
}

._justify-content-space-between_1tp6a_161 {
  justify-content: space-between;
}

._tabular-nums_1tp6a_165 {
  font-variant-numeric: tabular-nums;
}

._Notice_1tp6a_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1tp6a_282 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Notice_1tp6a_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Notice_1tp6a_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Notice_1tp6a_169 ::-webkit-scrollbar-track, ._Notice_1tp6a_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Notice_1tp6a_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Notice_1tp6a_169 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
}
._Notice_1tp6a_169._info_1tp6a_241 {
  background-color: #d9eff8;
}
._Notice_1tp6a_169._info_1tp6a_241 ._icon_1tp6a_244 {
  color: #60c4ea;
}
._Notice_1tp6a_169._success_1tp6a_247 {
  background-color: #eff5f9;
}
._Notice_1tp6a_169._success_1tp6a_247 ._icon_1tp6a_244 {
  color: #3996e0;
  border-color: #e2d5cc;
}
._Notice_1tp6a_169._feature_1tp6a_254 {
  background-color: #d6f1df;
  color: black;
}
._Notice_1tp6a_169._feature_1tp6a_254 ._icon_1tp6a_244 {
  color: #c6549e;
  border-color: #fef6f9;
}
._Notice_1tp6a_169._alert_1tp6a_262 {
  background-color: #f8edee;
}
._Notice_1tp6a_169._alert_1tp6a_262 ._icon_1tp6a_244 {
  color: #ea92af;
}
._Notice_1tp6a_169._error_1tp6a_268 {
  background-color: #f1515f;
  color: #fdfdfd;
}
._Notice_1tp6a_169._error_1tp6a_268 ._icon_1tp6a_244 {
  color: #F96B77;
}
._Notice_1tp6a_169._disabled_1tp6a_275 {
  background-color: #e9e8ea;
  color: #8f8d91;
}
._Notice_1tp6a_169._disabled_1tp6a_275 ._icon_1tp6a_244 {
  color: #8f8d91;
}
._Notice_1tp6a_169._showDivider_1tp6a_282 ._icon_1tp6a_244 {
  border-right: 1px solid #fdfdfd;
}
._Notice_1tp6a_169._showDivider_1tp6a_282._error_1tp6a_268 ._icon_1tp6a_244 {
  border-right: 1px solid #F67B86;
}
._Notice_1tp6a_169._showDivider_1tp6a_282 ._content_1tp6a_288 {
  padding-left: 20px;
}
._Notice_1tp6a_169 ._icon_1tp6a_244 {
  flex-shrink: 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 44px;
}
._Notice_1tp6a_169 ._content_1tp6a_288 {
  padding: 12px 12px 12px 0;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  word-break: break-word;
}