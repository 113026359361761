import { TState } from '../types';

interface IAction {
    searchKey: string;
    type: 'UPDATE SEARCH KEY';
}
export type TSetSearchKey = Readonly<IAction>;

export const updateSearchKey = (state: TState, action: TSetSearchKey): TState => ({
    ...state,
    searchKey: action.searchKey,
});
