._ProjectConfigSetup_3wxjq_1 {
  display: flex;
}
._ProjectConfigSetup_3wxjq_1 .ant-form-item {
  margin: 0;
}
._ProjectConfigSetup_3wxjq_1 ._content_3wxjq_7 {
  flex-direction: column;
  width: 90%;
  gap: 16px;
}
._ProjectConfigSetup_3wxjq_1 ._content_3wxjq_7 ._formItems_3wxjq_12 {
  flex-direction: column;
  align-content: space-around;
  justify-content: space-around;
}
._ProjectConfigSetup_3wxjq_1 ._content_3wxjq_7 ._formItems_3wxjq_12 .ant-form-item {
  margin-bottom: 16px;
}
._ProjectConfigSetup_3wxjq_1 ._content_3wxjq_7 ._checkboxGroup_3wxjq_20 {
  flex-direction: column;
  gap: 5px;
}
._ProjectConfigSetup_3wxjq_1 ._content_3wxjq_7 ._checkboxGroup_3wxjq_20 ._checkboxes_3wxjq_24 {
  padding: 2%;
  flex-direction: column;
  gap: 5px;
  background-color: #fafafa;
  width: 100%;
}
._ProjectConfigSetup_3wxjq_1 ._content_3wxjq_7 ._checkboxGroup_3wxjq_20 ._checkboxes_3wxjq_24 ._fieldConfigurations_3wxjq_31 {
  padding-left: 5%;
}