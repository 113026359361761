/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_9dpbg_157 {
  display: flex;
}

._justify-content-space-between_9dpbg_161 {
  justify-content: space-between;
}

._tabular-nums_9dpbg_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_9dpbg_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PageSection_9dpbg_178:last-child {
  margin-bottom: 0;
}