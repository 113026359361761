import { useCallback } from 'react';
import { ExportToCsv, Options } from 'export-to-csv';
import { pick, map, isNil } from 'lodash';

export interface IHeader {
  headerName: string;
  field: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useExportToCsv = () => useCallback((title: string, headers: IHeader[], data: any[]) => {
    const options: Options = {
      headers: map(headers, (header) => header.headerName),
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title,
    };

    const csvExporter = new ExportToCsv(options);

    const headerFields = map(headers, (header) => header.field);
    const dataToExport = map(data, (row) => map(pick(row, headerFields), (item) => (isNil(item) ? '' : item)));

    csvExporter.generateCsv(dataToExport);
  }, []);
