import * as React from 'react';
import SecondaryMetricsCard from './Card/SecondaryMetricsCard';
import { IImpactHomeProps } from '../../Home';
import { mapMetric } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary';
import { TypeStatistics } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary.types';

type IMetricsProps = {
  trends: IImpactHomeProps['trends'];
  igStoryEstimationEnabled?: boolean;
  tempYoutubeDemoAccountMode?: boolean;
};

type Keys = keyof IImpactHomeProps['trends'];

const mapTrends = (
  trends: IImpactHomeProps['trends'],
  igStoryEstimationEnabled: boolean,
  tempYoutubeDemoAccountMode: boolean,
): TypeStatistics[] => {
  const metrics = [
    'reach', 'views', 'conversions', 'creators', 'cpm', 'cpe', 'tmv',
  ] as Keys[];
  return metrics.map((metric) =>
    mapMetric(metric, trends[metric], igStoryEstimationEnabled, tempYoutubeDemoAccountMode));
};

const RefreshMetricsSecondary: React.FC<IMetricsProps> = (props) => {
  const mappedMetrics = mapTrends(props.trends, props.igStoryEstimationEnabled, props.tempYoutubeDemoAccountMode);
  const upperCaseIds = ['cpm', 'cpe', 'tmv']; // IDs that should be fully uppercase

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
  const mappedMetricValues = mappedMetrics.map((item) => {
    const transformedId = upperCaseIds.includes(item.id)
      ? item.id.toUpperCase()
      : capitalize(item.id);
    return {
      ...item,
      id: transformedId,
    };
  });

  return (
    <div className="flex flex-wrap gap-6">
      {mappedMetricValues.map((item) => (
        <div
          className="
            flex-grow
            basis-[calc(100%/3-1.5rem)] max-w-[calc(100%/3-1.5rem)]
            sm:basis-[calc(100%/3-1.5rem)] sm:max-w-[calc(100%/3-1.5rem)]
            xl:basis-[calc(100%/7-1.5rem)] xl:max-w-[calc(100%/7-1.5rem)]
            md:basis-[calc(100%/5-1.5rem)] md:max-w-[calc(100%/5-1.5rem)]
            lg:basis-[calc(100%/6-1.5rem)] lg:max-w-[calc(100%/6-1.5rem)]
            "
          key={item.id}
        >
          <SecondaryMetricsCard
            title={item.id}
            value={item.statisticProps.valueRender(item)}
            trendDirection={item.trendProps.direction}
            trendColor={item.trendProps.color}
            tooltip={item.statisticProps.tooltip}
            className="py-1 px-2"
            prefix={item.statisticProps?.prefix}
          />
        </div>
      ))}
    </div>
  );
};

export default RefreshMetricsSecondary;
