import React, { useMemo } from 'react';

import { ListExecutions_listExecutions_paging } from '@frontend/app/queries/types/ListExecutions';
import {
  Pagination as PaginationTW,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from '@frontend/shadcn/components/ui/pagination';

type TPaging = Pick<ListExecutions_listExecutions_paging, 'offset' | 'limit' | 'count' | 'hasMore'>;
const generatePaginationString = (paging?: TPaging) => {
  if (!paging) {
    return '';
  }
  const start = paging.offset + 1;
  const end = Math.min(paging.offset + paging.limit, paging.count);
  return `${start}-${end} of ${paging.count}`;
};
const usePaginationString = (paging?: TPaging) => {
  const string = useMemo(() => generatePaginationString(paging), [paging]);
  return string;
};
const PaginationString = ({ paging }: { paging?: TPaging }) => {
  const string = usePaginationString(paging);
  return <span>{string}</span>;
};

export const generatePaginationLinks = (paging: TPaging) => {
  if (!paging) {
    return {
      previousPage: null,
      nextPage: null,
      currentPage: 1,
      totalPages: 1,
    };
  }
  const currentPage = Math.floor(paging.offset / paging.limit) + 1;
  const totalPages = Math.ceil(paging.count / paging.limit);

  return {
    previousPage: currentPage > 1 ? `?page=${currentPage - 1}` : null,
    nextPage: paging.hasMore ? `?page=${currentPage + 1}` : null,
    currentPage,
    totalPages,
  };
};

const usePaginationLinks = (paging: TPaging) => {
  const paginationLinks = useMemo(() => generatePaginationLinks(paging), [paging]);
  return paginationLinks;
};

export const Pagination = ({ paging }: { paging: TPaging }) => {
  const paginationLinks = usePaginationLinks(paging);
  console.log('paginationLinks', paginationLinks);
  return (
    <PaginationTW>
      <PaginationContent>
        <PaginationItem>
          <PaginationString paging={paging} />
        </PaginationItem>
        <PaginationItem>
          <PaginationPrevious disabled={!paginationLinks.previousPage} to={paginationLinks.previousPage || ''} />
        </PaginationItem>
        <PaginationItem>
          <PaginationNext disabled={!paginationLinks.nextPage} to={paginationLinks.nextPage || ''} />
        </PaginationItem>
      </PaginationContent>
    </PaginationTW>
  );
};
