import * as React from 'react';
import cx from 'classnames';

import styles from './SimpleButton.scss';

interface IProps {
  label?: string;
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?();
}

export const SimpleButton = React.memo(
  React.forwardRef<HTMLDivElement, IProps>((props, ref) => (
    <div
      ref={ref}
      className={cx(styles.SimpleButton, props.className, { [styles.disabled]: props.disabled })}
      onClick={props.onClick}
    >
      <div className={styles.inner}>
        {props.icon && <div className={styles.icon}>{props.icon}</div>}
        {props.label && <span>{props.label}</span>}
      </div>
    </div>
  )),
);
