import React, { useState } from 'react';
import {
 Input, Row, Col, Typography,
} from '@revfluence/fresh';
import { validateDeepLink } from '@frontend/applications/AffiliatesApp/components/MembersWizard/utils/validateDeepLinkUrls';
import { GetOfferById_offer } from '@frontend/applications/AffiliatesApp/queries/types/GetOfferById';

const { Text } = Typography;

interface BulkViewProps {
  onInputChange: (deepLinkLabel: string, deepLinkUrl: string) => void;
  offerData?: GetOfferById_offer;
}

const BulkView: React.FC<BulkViewProps> = ({ onInputChange, offerData }) => {
  const [deepLinkLabel, setDeepLinkLabel] = useState('');
  const [deepLinkUrl, setDeepLinkUrl] = useState('');
  const offerLink = offerData?.links[0];
  const [labelError, setLabelError] = useState<string | null>(null);
  const [linkValidation, setLinkValidation] = useState<{ error: string | null; warning: string | null }>({
    error: null,
    warning: null,
  });

  const handleValidateDeepLink = (value: string) => {
    const validation = validateDeepLink(value, offerLink.url, offerLink.creatorDeeplink, offerLink.domains);
    setLinkValidation({
      error: validation.error,
      warning: validation.warning,
    });
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const label = e.target.value;
    setDeepLinkLabel(label);
    setLabelError(!label ? 'Label is required' : null);
    onInputChange(label, deepLinkUrl);
  };

  const handleUrlChange = (url: string) => {
    setDeepLinkUrl(url);
    handleValidateDeepLink(url);
    onInputChange(deepLinkLabel, url);
  };

  return (
    <>
      <div>
        <Row className="mt-4" gutter={24}>
          <Col span={24}>
            <Text>Enter label for the link</Text>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={24}>
            <Input
              placeholder="Label"
              style={{ width: '100%' }}
              value={deepLinkLabel}
              onChange={handleLabelChange}
              status={labelError ? 'error' : ''}
            />
            {labelError && (
              <Text type="danger" className="text-red-500">
                {labelError}
              </Text>
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Row className="mt-4">
          <Col>
            <Text>Redirect Links</Text>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={24}>
            <Input
              placeholder="Enter deep link URL"
              style={{ width: '100%' }}
              value={deepLinkUrl}
              onChange={(e) => handleUrlChange(e.target.value)}
              status={linkValidation.error ? 'error' : linkValidation.warning ? 'warning' : ''}
            />
            {linkValidation.error && (
              <Text type="danger" className="text-red-500">
                {linkValidation.error}
              </Text>
            )}
            {!linkValidation.error && linkValidation.warning && (
              <Text type="warning" className="text-yellow-500">
                {linkValidation.warning}
              </Text>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BulkView;
