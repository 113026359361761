import {
  SHOPIFY_APP_ID,
  WOO_COMMERCE_APP_ID,
  SOCIAL_POST_APP_ID,
} from './applicationIds';

export const SHOPIFY_IMAGE_URL = 'https://storage.googleapis.com/aspirex-static-files/home-page/Shopify.png';
export const WOO_COMMERCE_IMAGE_URL = 'https://storage.googleapis.com/aspirex-static-files/home-page/Woo_Commerce.png';
export const SOCIAL_DISCOVERY_IMAGE_URL = 'https://storage.googleapis.com/aspirex-static-files/home-page/Find_Influencer.png';
export const SOCIAL_POST_IMAGE_URL = 'https://storage.googleapis.com/aspirex-static-files/home-page/Social_Listening.png';

export enum SourcingGroupId {
  SHOPIFY = 'shopify',
  WOOCOMMERCE = 'woocommerce',
  SOCIAL_LISTENING = 'social listening',
}

export interface ISourcingGroup {
  id: string;
  title: string;
  imageUrl: string;
  appId: string;
}

export const sourcingGroups = Object.freeze<ISourcingGroup[]>([{
  id: SourcingGroupId.SHOPIFY,
  title: 'Shopify',
  imageUrl: SHOPIFY_IMAGE_URL,
  appId: SHOPIFY_APP_ID,
}, {
  id: SourcingGroupId.WOOCOMMERCE,
  title: 'WooCommerce',
  imageUrl: WOO_COMMERCE_IMAGE_URL,
  appId: WOO_COMMERCE_APP_ID,
}, {
  id: SourcingGroupId.SOCIAL_LISTENING,
  title: '@ Mentions',
  imageUrl: SOCIAL_POST_IMAGE_URL,
  appId: SOCIAL_POST_APP_ID,
}]);
