import * as React from 'react';
import cx from 'classnames';
import { IStepConfig } from '../types';
import styles from './styles.scss';

interface IProps {
  currentStep: IStepConfig;
  showLogo?: boolean;
}

export const Header: React.FC<IProps> = React.memo((props: IProps) => {
  const { currentStep, showLogo } = props;

  return (
    <div className={showLogo ? cx(styles.Header, styles.logoVisible) : styles.Header}>
      <div className={styles.title}>{currentStep.title}</div>
      <div className={styles.subTitle}>{currentStep.subTitle || ''}</div>
    </div>
  );
});

Header.displayName = 'WizardHeader';
