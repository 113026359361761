import {
 size, first, includes, each, merge, isNil, isEmpty,
} from 'lodash';
import { createSelector } from 'reselect';
import { hasFeature } from '@components';

import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';

import { IConnectSearchPage, ICreatorSearchOptions } from '../models';
import { mapSearchFiltersToAPIParams } from '../utils/searchParams';

const IQDataNetworks = ['tiktok', 'youtube'];

export const showCreateFeaturesSelector = createSelector(
  (state: IConnectSearchPage) => state.external.organization,
  (organization) => !isEmpty(organization),
);

export const imageSearchEnabledSelector = createSelector(
  (state: IConnectSearchPage) => state.external.organization,
  (state: IConnectSearchPage) => state.external.clientId,
  (organization, clientId) => (
      !isNil(clientId) || (
        organization && (organization.is_beta_tester || hasFeature(organization, 'image_search'))
      )
    ),
);

export const showCreatorDiscoveryFiltersSelector = createSelector(
  (state: IConnectSearchPage) => state.external.campaign,
  (state: IConnectSearchPage) => state.external.organization,
  (state: IConnectSearchPage) => state.external.clientId,
  (campaign, organization, clientId) => {
    const featureEnabled = organization && hasFeature(organization, 'creator_discovery');
    return (
      !isNil(clientId) || (
        featureEnabled
        && campaign
        && size(campaign.network_types) === 1
        && first(campaign.network_types) === 'instagram'
        && campaign.campaign_goal === 'content_creation'
      )
    );
  },
);

const networkTypeSelector = (state: IConnectSearchPage) => (state.textSearch.network ? state.textSearch.network.channel : null);

export const isQaSelector = (state: IConnectSearchPage) => state.external.isQa;

export const showAdvancedFiltersSelector = createSelector(
  networkTypeSelector,
  (networkType) => includes(['instagram', 'youtube', 'pinterest', 'tiktok'], networkType),
);

export const showLocationFilterSelector = createSelector(
  networkTypeSelector,
  (networkType) => includes(['instagram', 'facebook', 'twitter', 'youtube', 'pinterest', 'tiktok'], networkType),
);

export const showCountryFilterSelector = createSelector(
  networkTypeSelector,
  (networkType) => includes(['instagram', 'facebook', 'twitter', 'youtube', 'pinterest', 'tiktok'], networkType),
);

export const showLanguageFilterSelector = createSelector(
  (state: IConnectSearchPage) => state.external.organization,
  (state: IConnectSearchPage) => state.external.clientId,
  (organization, clientId) => !isNil(organization) || !isNil(clientId),
);

export const showFullAudienceFiltersSelector = createSelector(
  (state: IConnectSearchPage) => state.external.organization,
  (state: IConnectSearchPage) => state.external.clientId,
  networkTypeSelector,
  isQaSelector,
  (organization, clientId, networkType, isQa) => (
      includes(['instagram', 'pinterest', 'tiktok'], networkType)
      && (isQa || hasFeature(organization, 'audience_demographics') || !isNil(clientId))
    ),
);

export const showCategorizedFiltersSelector = createSelector(
  (state: IConnectSearchPage) => state.external.organization,
  (state: IConnectSearchPage) => state.external.clientId,
  networkTypeSelector,
  isQaSelector,
  (organization, clientId, networkType, isQa) => (
      networkType !== 'pinterest'
      && (isQa || hasFeature(organization, 'categorized_search') || !isNil(clientId))
    ),
);

export const showLastActivityFiltersSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType !== 'pinterest',
);

export const showAudienceDemographicsFiltersSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType !== 'pinterest',
);

export const showAudienceAgeFiltersSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType !== 'pinterest',
);

export const showInterestsFilterSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType === 'instagram',
);

export const showAudienceEthnicitySelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType === 'instagram',
);

export const showAudienceGenderSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType === 'instagram' || networkType === 'tiktok',
);

export const showEngagementFilterSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType !== 'tiktok',
);

export const showImpressionFilterSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType === 'tiktok',
);

export const showEngagementRatioFilterSelector = createSelector(
  networkTypeSelector,
  (networkType) => networkType !== 'pinterest' && networkType !== 'blog',
);

const roundToNearestStep = (min: number, value: number, step: number) => {
  if (value) {
    const diff = value - min;
    return min + (diff - (diff % step));
  }
  return value;
};

export const textSearchOptionsSelector = createSelector(
  (state: IConnectSearchPage) => state.external.campaign,
  (state: IConnectSearchPage) => state.external.clientId,
  (state: IConnectSearchPage) => state.external.searchOptions,
  (state: IConnectSearchPage) => [
    showCreatorDiscoveryFiltersSelector(state),
    showAdvancedFiltersSelector(state),
    showLocationFilterSelector(state),
    showCountryFilterSelector(state),
    showLanguageFilterSelector(state),
    showFullAudienceFiltersSelector(state),
    showCategorizedFiltersSelector(state),
    showLastActivityFiltersSelector(state),
    showAudienceDemographicsFiltersSelector(state),
    showAudienceAgeFiltersSelector(state),
    showEngagementFilterSelector(state),
    showImpressionFilterSelector(state),
    showInterestsFilterSelector(state),
    showAudienceEthnicitySelector(state),
    showAudienceGenderSelector(state),
    showEngagementRatioFilterSelector(state),
  ],
  isQaSelector,
  (state: IConnectSearchPage) => {
    const showIQDataFilters = (
      state.external.version === CreatorSearchVersion.v2
        && IQDataNetworks.includes(state.textSearch.network?.channel)
    );
    return showIQDataFilters
      ? state.external.defaultIQEngagementLimitsByNetwork
      : state.external.defaultEngagementLimitsByNetwork;
  },
  (state: IConnectSearchPage) => {
    const showIQDataFilters = (
      state.external.version === CreatorSearchVersion.v2
        && IQDataNetworks.includes(state.textSearch.network?.channel)
    );
    return showIQDataFilters
      ? state.external.defaultIQReachLimitsByNetwork
      : state.external.defaultReachLimitsByNetwork;
  },
  (state: IConnectSearchPage) => {
    const showIQDataFilters = (
      state.external.versionByNetwork?.[state.textSearch.network?.channel]
        === CreatorSearchVersion.v2
    );

    return showIQDataFilters
      ? state.external.defaultIQImpressionLimitsByNetwork
      : state.external.defaultImpressionLimitsByNetwork;
  },
  (state: IConnectSearchPage) => (
    state.external.versionByNetwork?.[state.textSearch.network?.channel]
      || CreatorSearchVersion.v1
  ),
  (
    campaign,
    clientId,
    searchOptions,
    showFilters,
    isQa,
    defaultEngagmentLimitsByNetwork,
    defaultReachLimitsByNetwork,
    defaultImpressionLimitsByNetwork,
    networkVersion,
  ): ICreatorSearchOptions => {
    const [
      showCreatorDiscoveryFilters,
      showAdvancedFilters,
      showLocationFilter,
      showCountryFilter,
      showLanguageFilter,
      showFullAudienceFilters,
      showCategorizedFilters,
      showLastActivityFilters,
      showAudienceDemographicsFilters,
      showAudienceAgeFilter,
      showEngagementFilter,
      showImpressionFilter,
      showInterestsFilter,
      showAudienceEthnicityFilter,
      showAudienceGenderFilter,
      showEngagementRatioFilter,
    ]: boolean[] = showFilters;

    const engagementLimitsByNetwork = campaign ? { ...campaign.engagement_ranges } : defaultEngagmentLimitsByNetwork;
    const reachLimitsByNetwork = campaign ? { ...campaign.follower_ranges } : defaultReachLimitsByNetwork;
    const impressionLimitsByNetwork = campaign ? { ...campaign.impression_ranges } : defaultImpressionLimitsByNetwork;

    const rangeStep = 500;
    const impressionStep = 100;

    each(engagementLimitsByNetwork, (limits) => {
      limits[1] = roundToNearestStep(limits[0], limits[1], rangeStep);
    });

    each(reachLimitsByNetwork, (limits) => {
      limits[1] = roundToNearestStep(limits[0], limits[1], rangeStep);
    });

    each(impressionLimitsByNetwork, (limits) => {
      limits[1] = roundToNearestStep(limits[0], limits[1], impressionStep);
    });

    const options: ICreatorSearchOptions = {
      creatorSkillsets: {
        hide: !showCreatorDiscoveryFilters,
      },
      network: {
        version: networkVersion,
      },
      creatorDemographics: {
        hide: !showAdvancedFilters,
        hideCreatorAge: !showFullAudienceFilters || !showAudienceAgeFilter,
        hideCreatorLocations: !showLocationFilter,
        hideCreatorCountries: !showCountryFilter,
        hideCreatorLanguages: !showLanguageFilter,
        hideCreatorInterests: !showInterestsFilter,
      },
      audienceDemographics: {
        hide: !showAdvancedFilters || !showAudienceDemographicsFilters,
        hideAudienceAge: !showFullAudienceFilters || !showAudienceAgeFilter,
        hideAudienceLocation: !showFullAudienceFilters,
        hideAudiencePercentage: !showFullAudienceFilters,
        hideAudienceEthnicity: !showAudienceEthnicityFilter,
        hideAudienceGender: !showAudienceGenderFilter,
      },
      engagement: {
        reachLimitsByNetwork,
        reachStep: rangeStep,
        engagementLimitsByNetwork,
        engagementStep: rangeStep,
        hideEngagement: !showEngagementFilter,
        impressionLimitsByNetwork,
        impressionStep,
        hideImpression: !showImpressionFilter,
        hideEngagementRatio: !showEngagementRatioFilter,
      },
      other: {
        hideInvitedToggle: !isNil(clientId),
        hideTags: !showCategorizedFilters,
        hideCanContact: !isQa,
        hideActivity: !showLastActivityFilters,
        hideHasSponsoredPosts: !showCreatorDiscoveryFilters,
      },
    };

    return merge(options, searchOptions);
  },
);

export const imageSearchAPIParamsSelector = createSelector(
  (state: IConnectSearchPage) => state.external.campaign,
  (state: IConnectSearchPage) => state.imageSearch,
  textSearchOptionsSelector,
  (state: IConnectSearchPage) => state.searchResults.resultsCount,
  (state: IConnectSearchPage) => state.external.clientId,
  (campaign, imageSearch, options, totalCount, clientId) => ({
    ...mapSearchFiltersToAPIParams(
      {
        network: {
          channel: 'instagram',
        },
        creatorDemographics: {
          countries: imageSearch.countries,
        },
        other: {
          hideInvited: imageSearch.hideInvited,
        },
      },
      options,
    ),
    campaign_id: campaign && campaign.id,
    image_url: imageSearch.imageURL,
    totalCount,
    client_id: clientId,
    version: options.network.version,
  }),
);

export const defaultImageSearchAPIParamsSelector = createSelector(
  (state: IConnectSearchPage) => state,
  (state: IConnectSearchPage) => state.external.defaultImageSearchUrl,
  (state, defaultImageSearchUrl) => ({
    ...imageSearchAPIParamsSelector(state),
    image_url: defaultImageSearchUrl,
  }),
);

const filterHiddenParametersSelector = createSelector(
  showLocationFilterSelector,
  showCountryFilterSelector,
  showLanguageFilterSelector,
  (showLocationFilter, showCountryFilter, showLanguageFilter) => (params) => {
      const copy = { ...params };

      if (!showLocationFilter) {
        delete copy.locations;
      }

      if (!showCountryFilter) {
        delete params.country_codes;
      }

      if (!showLanguageFilter) {
        delete params.language_codes;
      }

      return params;
    },
);

export const searchAPIParamsSelector = createSelector(
  (state: IConnectSearchPage) => state.external.campaign,
  (state: IConnectSearchPage) => state.textSearch,
  textSearchOptionsSelector,
  (state: IConnectSearchPage) => state.searchResults.resultsCount,
  (state: IConnectSearchPage) => state.external.clientId,
  filterHiddenParametersSelector,
  (campaign, filters, options, totalCount, clientId, filterHiddenParameters) =>
    filterHiddenParameters({
      ...mapSearchFiltersToAPIParams(filters, options),
      campaign_id: campaign && campaign.id,
      totalCount,
      client_id: clientId,
      version: options.network.version,
    }),
);
