export type TMediaType = 'image' | 'video' | 'application' | 'carousel';

export interface IMedia {
  media_type?: TMediaType;
  md5_hash?: string;
  content_type?: string;
  resolution?: string;
  size?: number;
  width?: number;
  height?: number;
  preview_url?: string;
  transform?: string;
  url: string;
  category?: string;
}
