import * as React from 'react';
import { format } from 'date-fns';
import { Popover, Image } from '@revfluence/fresh';
import { uniqueId } from 'lodash';
import styles from './ImagesCell.scss';

const { useState, useMemo } = React;

const ASSETS = process.env.ASSETS;
const defaultImageSrc = `${ASSETS}/content_image_placeholder.png`;

export interface IImageData {
  text: string;
  image: string;
  date_posted: string;
  link: string;
}

export const Thumbnail = (props: { image: IImageData }) => {
  const { image } = props;
  const [imgPreviewVisible, setImgPreviewVisible] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);

  const datePosted = useMemo(() => format(new Date(image.date_posted), 'MMM d, yyyy'), [image]);

  const renderContent = (image: IImageData) => {
    const cleanText = image.text?.replace(/\s*\n\s*\./g, '') || '';

    return (
      <div
        className={styles.popoverContent}
        onClick={(e) => e.stopPropagation()}
      >
        <Image
          className={styles.image}
          src={image.image}
          fallback={defaultImageSrc}
          onClick={(ev) => {
            ev.preventDefault();
            setImgPreviewVisible(true);
            setPreviewVisible(false);
          }}
        />
        {image.date_posted && (
          <div className={styles.date}>
            {datePosted}
          </div>
        )}
        {cleanText
          .substring(0, 200)
          .concat(cleanText.length > 200 ? '...' : '')
          .split('\n')
          .map((text, i, arr) => (
            <p key={uniqueId('image_text_')}>
              {text}
              {i + 1 === arr.length && image.link && (
                <>
                  &nbsp;
                  <a
                    href={image.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                </>
              )}
            </p>
          ))}
      </div>
    );
  };

  return (
    <div
      key={uniqueId('image_')}
      className={styles.image}
    >
      <Popover
        content={renderContent(image)}
        overlayClassName={styles.popoverOverlay}
        visible={!imgPreviewVisible && previewVisible}
        onVisibleChange={setPreviewVisible}
      >
        <Image
          className={styles.thumb}
          src={image.image}
          fallback={defaultImageSrc}
          preview={{
            visible: imgPreviewVisible,
            src: image.image,
            onVisibleChange: (val) => {
              setImgPreviewVisible(val);
              if (!val) {
                setPreviewVisible(false);
              }
            },
          }}
        />
      </Popover>
    </div>
  );
};
