import * as React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';

import { KeyboardArrowLeftIcon } from '@components';

import { Cell, ICellProps } from './Cell';
import { SortTypes, TSortType } from '../utils/SortedDataList';
import { UnsortableCellTypes } from '../tableContext';

const { useCallback } = React;
import styles from './HeaderCell.scss';

const nextSortDirection = (sortDir: TSortType) => {
  if (!sortDir) {
    return SortTypes.DESC;
  } else if (sortDir === SortTypes.DESC) {
    return SortTypes.ASC;
  }
  return null;
};

interface IProps extends ICellProps {
  sortDir?: TSortType;
  onSortChange?: (sortDir: TSortType) => void;
}

/**
 * @type {React.FunctionComponent}
 */
export const HeaderCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    className,
    config,
    onSortChange,
    sortDir,
    value,
    ...restProps
  } = props;

  /**
   * Sort
   */
  const sortable = !isNil(config.sortable)
    ? config.sortable
    : !UnsortableCellTypes.includes(config.cellType);
  const toggleSortDir = useCallback(() => {
    if (sortable) {
      onSortChange(nextSortDirection(sortDir));
    }
  }, [sortable, sortDir, onSortChange]);

  const content = (
    <>
      {value}
      {' '}
      {sortDir === SortTypes.ASC && (
        <KeyboardArrowLeftIcon
          className={cx(styles.arrowIcon, styles.arrowUp)}
          size={12}
        />
      )}
      {sortDir === SortTypes.DESC && (
        <KeyboardArrowLeftIcon
          className={cx(styles.arrowIcon, styles.arrowDown)}
          size={12}
        />
      )}
    </>
  );

  return (
    <Cell
      className={cx(styles.HeaderCell, className, {
        [styles.sortable]: sortable,
        [styles.active]: !!sortDir,
      })}
      value={content}
      config={config}
      onClick={toggleSortDir}
      isHeaderCell
      {...restProps}
    />
  );
});

HeaderCell.defaultProps = {
  sortDir: null,
  onSortChange: () => undefined,
};
HeaderCell.displayName = 'HeaderCell';
