import { clamp, isNumber } from 'lodash';

import { DEFAULT_COLUMN_WIDTH, MIN_COLUMN_WIDTH, MAX_COLUMN_WIDTH } from './constants';

interface ICellWidthOptions {
  width: number;
  minWidth: number;
  maxWidth: number;
  setWidth: number;
}

export const calculateCellWidth = (options: ICellWidthOptions) => {
  const minWidth = isNumber(options.minWidth) ? options.minWidth : MIN_COLUMN_WIDTH;
  const maxWidth = isNumber(options.maxWidth) ? options.maxWidth : MAX_COLUMN_WIDTH;
  if (isNumber(options.setWidth)) {
    return clamp(options.setWidth, minWidth, maxWidth);
  } else if (isNumber(options.width)) {
    return clamp(options.width, minWidth, maxWidth);
  }
  return DEFAULT_COLUMN_WIDTH;
};
