import * as React from 'react';
import cx from 'classnames';

import { useElementResize } from '@frontend/utils';
import { LoadSpinner } from '@components';

const { useRef } = React;

interface IProps {
  className?: string;
  fixedHeight?: number;
  fixedWidth?: number;
}

export const GraphContainer: React.FC<IProps> = React.memo((props: React.PropsWithChildren<IProps>) => {
  const {
    children,
    className,
    fixedHeight,
    fixedWidth,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const domRect = useElementResize(ref, { delay: 1000 });

  const width = fixedWidth || (domRect && domRect.width) || 0;
  const height = fixedHeight || (domRect && domRect.height) || 0;
  const isCalculating = width === 0 || height === 0;

  return (
    <div
      className={cx(className)}
      style={{ height: '100%' }}
      ref={ref}
    >
      {isCalculating
        ? <LoadSpinner centered />
        : (
          React.cloneElement(children as React.ReactElement, {
            width,
            height,
          })
        )}
    </div>
  );
});
