import * as React from 'react';
import {
  chain,
  chunk,
  each,
  get,
  isEmpty,
  keys,
  map,
  merge,
  reduce,
  size,
  sortBy,
  uniqueId,
  isObject,
} from 'lodash';
import * as Papa from 'papaparse';

import {
 Button, CheckCircleIcon, SpinnerIcon, XCircleIcon,
} from '@components';

import { EventName, logger } from '@common';
import { IWizardStepHandles } from '@frontend/app/components/Wizard/Wizard';
import { useEventContext } from '@frontend/app/context/EventContext';
import { UploadMembersCSV_result } from '@frontend/app/queries/types/UploadMembersCSV';

import { useUploadCSVContext } from '../hooks/useUploadCSVModalContext';
import { useUploadCSVDataChunk } from '../hooks/useUploadCSVDataChunk';

import styles from './ImportData.scss';

const {
 useCallback, useEffect, useImperativeHandle, useState,
} = React;

const womanWorkingSvg = 'https://storage.googleapis.com/aspirex-static-files/woman_working.svg';

const CSV_DATA_CHUNK_SIZE = 200;

type UploadMembersCSVResult = Omit<UploadMembersCSV_result, '__typename'>;

interface IProps {
  onCloseModal: () => void;
  onReviewImport: () => void;
  onTryAgain: () => void;
  uploadIdentifier: string;
}

export const ImportData = React.forwardRef<IWizardStepHandles, IProps>((props, ref) => {
  const {
    onCloseModal,
    onTryAgain,
    uploadIdentifier,
  } = props;

  const addEvent = useEventContext();

  const [isImporting, setIsImporting] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [uploadResponse, setUploadResponse] = useState<UploadMembersCSVResult>();

  const { uploadCSVDataChunk } = useUploadCSVDataChunk();

  useImperativeHandle(
    ref,
    () => ({
      reset: () => {
        setIsImporting(true);
        setErrorMessage(undefined);
      },
    }),
    [setErrorMessage, setIsImporting],
  );

  const { uploadCsvParams } = useUploadCSVContext();

  const startCSVUpload = useCallback(async () => {
    const startTime = performance.now();
    const { file } = uploadCsvParams;

    if (!file) {
      return;
    }

    try {
      logger.debug(`Starting parsing CSV file for file ${file.name}.`);

      const { data: csvData }: Papa.ParseResult<Record<string, string>> = await new Promise((resolve) => {
        Papa.parse<Record<string, string>>(file, {
          header: true,
          complete: (results) => resolve(results),
        });
      });

      const dataChunks = chunk(csvData, CSV_DATA_CHUNK_SIZE);

      logger.debug(
        `CSV parsed file ${file.name} yielded ${size(csvData)} rows divided into ${dataChunks.length} chunk(s)`,
      );

      const results = await Promise.limitedAllSettled(
        dataChunks,
        (chunk) => uploadCSVDataChunk(chunk, uniqueId(), uploadCsvParams),
        5,
      );

      const combinedResult: UploadMembersCSVResult = {
        successfulMembersCount: 0,
        failedMembersCount: 0,
        failedMemberErrors: {},
        failedRelationWarnings: {},
      };

      each(results, (result, index) => {
        if (result.status === 'fulfilled') {
          const { result: chunkResult } = result.value;

          combinedResult.successfulMembersCount += chunkResult.successfulMembersCount;
          combinedResult.failedMembersCount += chunkResult.failedMembersCount;
          combinedResult.failedRelationWarnings = merge(combinedResult.failedRelationWarnings, chunkResult.failedRelationWarnings);

          if (!isEmpty(chunkResult.failedMemberErrors)) {
            const errorRowNumbers = map(keys(chunkResult.failedMemberErrors), (key) => parseInt(key, 10));
            each(errorRowNumbers, (rowNumber) => {
              const adjustedRowNumber = index > 0 ? rowNumber + (index * CSV_DATA_CHUNK_SIZE) : rowNumber;
              combinedResult.failedMemberErrors[adjustedRowNumber] = chunkResult.failedMemberErrors[rowNumber];
            });
          }
        }
      });

      addEvent(
        EventName.CSVUploadImportCompleted,
        {
          uploadIdentifier,
          success: true,
          successfulRecords: combinedResult.successfulMembersCount,
          failedRecords: combinedResult.failedMembersCount,
          errorType: null,
        },
      );

      setUploadResponse(combinedResult);

      chain(combinedResult.failedMemberErrors)
        .values()
        .uniq()
        .forEach((error_type) => {
          addEvent(EventName.ImportErrors, { error_type });
        });

      logger.debug(`CSV file ${file.name} uploaded successfuly.`);
    } catch (error) {
      logger.error(error);

      if (error.message.includes('GraphQL error:')) {
        error.message = error.message.substring(15);
      }

      addEvent(
        EventName.CSVUploadImportCompleted,
        {
          uploadIdentifier,
          success: false,
          successfulRecords: 0,
          failedRecords: 0,
          errorType: error.message,
        },
      );
      setErrorMessage(error.message);
    } finally {
      setIsImporting(false);
      logger.debug(`CSV file ${file.name} process finished in ${Math.ceil((performance.now() - startTime) / 1000)} seconds.`);
    }
  }, [addEvent, uploadCSVDataChunk, uploadCsvParams, uploadIdentifier]);

  useEffect(() => {
    startCSVUpload();
  }, [startCSVUpload]);

  const uploadMessage = (() => {
    if (isImporting) {
      return 'Importing...';
    } else if (errorMessage) {
      return 'Uh oh...';
    }
    return 'Import success';
  })();

  const renderStatus = () => {
    if (isImporting) {
      return (
        <div className={styles.loading}>
          <SpinnerIcon className={styles.spinnerIcon} />
        </div>
      );
    }

    // Grab error messages for failed imports
    const errorCountByMessage = reduce(
      keys(uploadResponse?.failedMemberErrors),
      (errors, lineNumber) => {
        let message = uploadResponse.failedMemberErrors[lineNumber];
        if (isObject(message)) {
          message = get(message, 'message');
        }
        errors[message] = [
          ...get(errors, message, []),
          parseInt(lineNumber, 10) + 1,
        ];
        return errors;
      },
      {},
    );
    // To show most occurrences first
    const sortedErrorMessages = sortBy(
      keys(errorCountByMessage),
      (message) => -errorCountByMessage[message].length,
    );

    // Grab error messages for failed imports
    const warningCountByMessage = reduce(
      uploadResponse?.failedRelationWarnings,
      (errors, message, lineNumber) => {
        errors[message] = [
          ...get(errors, message, []),
          lineNumber,
        ];
        return errors;
      },
      {},
    );
    // To show most occurrences first
    const sortedWarningMessages = sortBy(
      keys(warningCountByMessage),
      (message) => -warningCountByMessage[message].length,
    );

    const willTryAgain = (
      errorMessage // Complete failure message from back end
      || (uploadResponse?.successfulMembersCount === 0 && uploadResponse?.failedMembersCount > 0) // All failed
    );

    return (
      <>
        <ul className={styles.result}>
          {!errorMessage
          ? (
            <li>
              {uploadResponse?.successfulMembersCount}
              {' '}
              records imported
              <CheckCircleIcon size={20} className={styles.checkIcon} />
            </li>
          )
          : (
            <li className={styles.errorMessage}>
              {errorMessage}
              <XCircleIcon size={20} className={styles.xcircleIcon} />
            </li>
          )}
          {map(sortedErrorMessages, (message) => (
            <li className={styles.errorMessage}>
              {message}
              {' '}
              on line
              {errorCountByMessage[message].length > 1 && 's'}
              &nbsp;
              {errorCountByMessage[message].join(', ')}
              .
              {' '}
              {(errorCountByMessage[message].length > 1) ? 'Rows were skipped' : 'Row was skipped'}
              <XCircleIcon size={20} className={styles.xcircleIcon} />
            </li>
        ))}
          {map(sortedWarningMessages, (message) => (
            <li className={styles.errorMessage}>
              {message}
              {' '}
              was not imported for
              {' '}
              {warningCountByMessage[message].join(', ')}
              {' '}
              field
              {warningCountByMessage[message].length > 1 ? 's' : ''}
              .
              {size(uploadResponse?.failedRelationWarnings?.owner) > 0 ? ' Please make sure to use a valid email address for the owner field.' : ''}
              { /* eslint-disable-next-line @typescript-eslint/no-explicit-any */ }
              <XCircleIcon size={20} className={(styles as any).errorIcon} />
            </li>
        ))}
        </ul>
        <Button
          label={willTryAgain ? 'Try again' : 'Done'}
          onClick={willTryAgain ? onTryAgain : onCloseModal}
          theme="primary"
          fullWidth={false}
          className={styles.button}
        />
      </>
);
  };

  return (
    <div className={styles.ImportData}>
      <img
        src={womanWorkingSvg}
        className={styles.art}
      />
      <h4>{uploadMessage}</h4>
      {renderStatus()}
    </div>
  );
});
