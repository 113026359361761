import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { DELETE_WORKLET_MUTATION } from '@frontend/app/queries';
import {
  DeleteWorkletMutation,
  DeleteWorkletMutationVariables,
} from '@frontend/app/queries/types/DeleteWorkletMutation';

type IOptions = MutationHookOptions<DeleteWorkletMutation, DeleteWorkletMutationVariables>;

export const useDeleteWorkletMutation = (options: IOptions = {}) => (
  useMutation<DeleteWorkletMutation, DeleteWorkletMutationVariables>(DELETE_WORKLET_MUTATION, options)
);
