import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { message } from '@affiliates/AspireUI';
import { OFFER_ACTIONS } from '@affiliates/constants/offers';
import { useQueryParams } from '@frontend/app/hooks';
import * as qs from 'qs';

export const useRedirectOnOfferFormSubmit = (baseUri: string, migrateToGraphQL?: boolean) => {
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();

  const onSubmit = useCallback((offerId: number, action: OFFER_ACTIONS) => {
    const isWorkFlow = !!(query.has('program_id') && query.has('task_id'));
    const getWorkflowParam = () => {
      if (isWorkFlow) {
        const programId = query.get('program_id');
        const taskId = query.get('task_id');
        return qs.stringify({
          program_id: programId,
          task_id: taskId,
        });
      }
      return '';
    };

    let messageStr = '';
    let pathname = `${baseUri}/dashboard`;
    let isEdited = false;
    switch (action) {
      case OFFER_ACTIONS.CREATED:
        messageStr = 'Offer created.';
        pathname = `${baseUri}/offers/${offerId}/details`;
        break;
      case OFFER_ACTIONS.UPDATED:
        if (isWorkFlow) {
          messageStr = 'Offer updated.';
          pathname = `${baseUri}/offers/${offerId}/confirmation`;
        } else {
          if (migrateToGraphQL) {
            isEdited = true;
          }
          messageStr = 'Offer updated.';
          pathname = `${baseUri}/offers/${offerId}/details`;
        }
        break;
      case OFFER_ACTIONS.DELETED:
        messageStr = 'Offer deleted.';
        break;
      case OFFER_ACTIONS.RESTORED:
        messageStr = 'Offer restored.';
        break;
    }
    const state = {
      isUpdated: isEdited,
    };
    history.push(
      {
        ...location, pathname, search: getWorkflowParam(), state,
      },
      );
    message.success(messageStr);
  }, [baseUri, history, location, query, migrateToGraphQL]);

  return { onSubmit };
};
