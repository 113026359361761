/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import {
  Typography, Space,
} from '@revfluence/fresh';
import {
  GetThreadQuery_thread_messages_payload_attachments,
  GetThreadQuery_thread_messages_payload_story,
} from '@frontend/app/queries/types/GetThreadQuery';
import { isEmpty, isNil } from 'lodash';
import Linkify from 'react-linkify';
import {
  GetMemberActivitiesQuery_activities_payload_attachments,
  GetMemberActivitiesQuery_activities_payload_story,
} from '@frontend/app/queries/types/GetMemberActivitiesQuery';
import { IGDMStoryMessageItem } from '@frontend/app/components/IGDMMessageItem/IGDMStoryMessageItem';
import { getMentionIGDMMessage } from '@frontend/app/utils/igdm';
import { IGDMAttachmentMessageItem } from '@frontend/app/components/IGDMMessageItem/IGDMAttachmentMessageItem';
import styles from './InstagramMessageItem.scss';

const { Text } = Typography;

interface IProps {
  messageItem: IIGDMMessage;
  sentByUser: boolean;
  hideMentionTitle: boolean;
  setIsExpired(value: boolean): void;
}

interface IIGDMMessage {
  story: GetMemberActivitiesQuery_activities_payload_story | GetThreadQuery_thread_messages_payload_story | null;
  to: (string | null)[] | null;
  from: string | null;
  textMessage: string | null;
  attachments: GetMemberActivitiesQuery_activities_payload_attachments[] | GetThreadQuery_thread_messages_payload_attachments[] | null;
}

export const InstagramMessageItemDetails: React.FC<IProps> = ({
  messageItem, sentByUser, hideMentionTitle, setIsExpired,
}) => {
  if (!isNil(messageItem.story?.mention)) {
    return (
      <IGDMStoryMessageItem
        title={hideMentionTitle ? null : getMentionIGDMMessage(sentByUser, messageItem.to[0], messageItem.from)}
        setIsExpired={setIsExpired}
        story={messageItem.story}
      />
    );
  }

  if (!isNil(messageItem.attachments) && !isEmpty(messageItem.attachments)) {
    return (
      <Space>
        {
          messageItem.attachments.map((attachment, index) => (
            <IGDMAttachmentMessageItem
              key={index}
              attachmentsUrl={attachment.url}
            />
          ))
        }
      </Space>
    );
  }

  return (
    <Text>
      <div className={styles.textWrapper}>
        <Linkify>
          {messageItem.textMessage}
        </Linkify>
      </div>
    </Text>
  );
};
