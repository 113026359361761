import * as React from 'react';
import { Tooltip, Typography } from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './SectionHeader.scss';

const { Text } = Typography;
interface IProps {
    title: string;
    sectionIcon: React.ReactNode;
    tooltipMessage?: string;
    rightContect?: React.ReactNode;
}

export const SectionHeader: React.FC<IProps> = (props) => {
    const {
        title,
        sectionIcon,
        tooltipMessage,
        rightContect,
    } = props;

    return (
      <div className={styles.sectionHeaderContainer}>
        <div className={styles.sectionHeaderLeft}>
          {sectionIcon}
          <Text weight="semibold">{title}</Text>
          {
                    tooltipMessage && <Tooltip title={tooltipMessage}><CircleInfoIcon /></Tooltip>
                }
        </div>
        {
                rightContect
            }
      </div>
    );
};
