import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const AddAppIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path d="M9.67366 15.9043L8.97756 16.3935V10.0093L14.0173 6.85894V8.11987C14.0173 8.43411 14.2719 8.68471 14.5822 8.68471C14.8924 8.68471 15.147 8.43014 15.147 8.11987V5.83666V5.83268V5.8287C15.147 5.7929 15.1391 5.75711 15.1311 5.72131C15.1271 5.70937 15.1311 5.69744 15.1271 5.68551C15.1152 5.63777 15.0953 5.59402 15.0714 5.55026C15.0675 5.54231 15.0595 5.53833 15.0555 5.53038C15.0317 5.49458 15.0078 5.46275 14.9799 5.43491C14.964 5.419 14.9481 5.41104 14.9322 5.39911C14.9123 5.3832 14.8924 5.36331 14.8686 5.3474L14.8606 5.34342L8.69514 1.74358C8.52012 1.64016 8.30135 1.64016 8.12235 1.74358L1.95688 5.33944L1.94892 5.3474C1.92506 5.36331 1.90517 5.37922 1.8813 5.39911C1.86539 5.41104 1.84948 5.419 1.83357 5.43491C1.80572 5.46275 1.78186 5.49855 1.75799 5.53038C1.75401 5.53833 1.75004 5.54231 1.74606 5.55026C1.71821 5.59402 1.7023 5.63777 1.68639 5.68551C1.68241 5.69744 1.68241 5.70937 1.68241 5.72131C1.67446 5.75711 1.6665 5.7929 1.6665 5.8287V5.83268V5.83666V13.6091C1.6665 13.804 1.76595 13.987 1.93301 14.0904L8.10644 17.9647C8.1144 17.9687 8.12235 17.9687 8.12633 17.9727C8.13428 17.9767 8.13826 17.9846 8.14622 17.9846C8.17008 17.9966 8.19395 18.0045 8.21782 18.0125C8.23373 18.0165 8.24566 18.0244 8.26157 18.0284C8.3093 18.0403 8.35704 18.0483 8.40875 18.0483C8.46046 18.0483 8.51217 18.0403 8.56388 18.0244C8.57979 18.0204 8.5957 18.0125 8.61161 18.0045C8.63548 17.9966 8.65934 17.9886 8.68321 17.9767C8.69117 17.9727 8.69912 17.9647 8.71105 17.9568C8.71901 17.9528 8.72697 17.9488 8.73492 17.9449L10.326 16.8311C10.5806 16.6521 10.6442 16.2981 10.4652 16.0395C10.2823 15.785 9.92824 15.7253 9.67366 15.9043ZM8.40875 2.88917L13.4883 5.85257L8.8463 8.75233L8.41272 9.02282L7.24327 8.29092L3.33317 5.84859L8.40875 2.88917ZM2.80016 6.85894L7.83993 10.0093V16.4572L2.80016 13.2949V6.85894Z" />
    <path d="M17.7646 13.4826H15.1512V10.6664C15.1512 10.3522 14.8966 10.1016 14.5864 10.1016C14.2761 10.1016 14.0215 10.3561 14.0215 10.6664V13.4826H11.4042C11.09 13.4826 10.8394 13.7372 10.8394 14.0475C10.8394 14.3577 11.0939 14.6123 11.4042 14.6123H14.0215V17.2296C14.0215 17.5439 14.2761 17.7945 14.5864 17.7945C14.8966 17.7945 15.1512 17.5399 15.1512 17.2296V14.6123H17.7686C18.0828 14.6123 18.3334 14.3577 18.3334 14.0475C18.3334 13.7372 18.0788 13.4826 17.7646 13.4826Z" />
  </SvgIcon>
));

AddAppIcon.displayName = 'AddAppIcon';
