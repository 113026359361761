import * as React from 'react';
import { ExclamationCircleFilled, SearchOutlined } from '@ant-design/icons';
import {
  Input, IInputProps, Typography, IColumnsType, Row, Col, Select, Empty,
} from '@revfluence/fresh';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import cx from 'classnames';
import {
  debounce,
  filter, isArray, isEmpty, isNull, isString, map,
} from 'lodash';
import moment from 'moment';
import {
  IRowData,
  ITableCellType,
  ITableColumnConfig,
  LoadSpinner,
  Table,
} from '@components';

import { Notice } from '@affiliates/AspireUI';
import Tooltip from '@frontend/applications/AffiliatesApp/AspireUI/Tooltip/Tooltip';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { MAX_ALLOWED_MEMBERS, createUrlObj } from '@frontend/applications/AffiliatesApp/utils';
import { useState } from 'react';
import _ from 'lodash';
import { arrangeTableColumns } from '@frontend/applications/AffiliatesApp/utils/columnOrder';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import {
  ACTIVE_DATE_FORMAT, ACTIVE_TIME_FORMAT, TMode, TProgram, TSelectedMember,
} from '../types';
import {
  cleanPromoCode,
  getMemberOfferStatus,
  isOfferShopify,
  isOfferTune,
} from '../utils';
import { PotentialCodeHeader } from './PotentialCodeHeader';

const { Option } = Select;
import styles from './MembersWizard.scss';
import AffiliateTableSTA from '../../AffiliateTableSTA/AffiliateTableSTA';

const { useEffect, useMemo } = React;
const { Title } = Typography;

const TABLE_WIDTH = 924;
const CHECKBOX_COL_WIDTH = 56;

interface IBaseProps {
  markMembersSelected: (members: readonly TSelectedMember[]) => void;
  members: readonly TSelectedMember[] | null;
  mode: TMode;
  programLabel: string;
  offerSource: OFFER_SOURCE;
  isNewFlow?: boolean;
  isWorkflow?: boolean;
  fetchMembers?: (offset?: number, limit?: number, searchByName?: string, programs?: number[], isNewFlow?: boolean) => void;
  fetchMembersCount?: (programIds: number[], searchByName?: string) => Promise<number>;
  programId?: number[]
  programs?: readonly TProgram[] | null;
  selectedPrograms?: {
    [programId: string]: TProgram;
  };
  markProgramsSelected?: (programs: readonly TProgram[]) => void;
  migrateToGraphQL?: boolean;
  updateSearchKey?: (searchKey: string) => void;
  searchKey?: string;
  selectedMembers?: readonly TSelectedMember[];
  resetMember?: () => void;
  isLoading?: boolean;
  updateIsLoading?: (isLoading: boolean) => void;
}

interface IAddProps extends IBaseProps {
  mode: 'add';
  noMembersWarning: React.ReactElement;
  updateMemberCode: (memberId: string, field: string, value: string) => void;
  offerLink?: string;
  updateMemberDeepLink: (memberId: string, deepLink: string, focused: boolean) => void;
  isWorkflow: boolean;
  deepLinkColumn: boolean;
}
interface IRefreshProps extends IBaseProps {
  mode: 'refresh';
  offerName: string;
  refreshDatesOnly: boolean;
  updateMemberCode: (memberId: string, field: string, value: string) => void;
}
interface IDeactivateProps extends IBaseProps {
  mode: 'deactivate';
  offerName: string;
}

type TProps = Readonly<
  | IAddProps
  | IDeactivateProps
  | IRefreshProps
>;

const getMissingCodesAlert = (
  mode: TProps['mode'],
  offerSource: TProps['offerSource'],
  members: TProps['members'],
): React.ReactElement => {
  if (mode !== 'add' || offerSource !== OFFER_SOURCE.SHOPIFY) {
    return null;
  }
  const missingCodeCount = filter(members, (m) => m.selected && m.code.length < 1).length;
  if (missingCodeCount < 1) {
    return null;
  }
  const message = `
      ${missingCodeCount.toLocaleString()} member${missingCodeCount !== 1 ? 's' : ''}
      do${missingCodeCount !== 1 ? '' : 'es'} not have the data required to generate
      a promo code. You can manually enter a promo code for them or leave them
      out of this offer for the time being.
    `.trim();
  return (
    <Notice type="error" className={styles.alert} message={message} />
  );
};

const getDuplicateCodesAlert = (members: TProps['members']): React.ReactElement => {
  const duplicateCodeCount = filter(members, (m) => m.selected && m.isDuplicate).length;
  if (duplicateCodeCount < 1) {
    return null;
  }
  const message = `
      ${duplicateCodeCount.toLocaleString()} members will have the same code.
      Each member must have a unique promo code so Shopify can properly
      attribute conversion data to the member.
    `.trim();
  return (
    <Notice type="error" className={styles.alert} message={message} />
  );
};

const getSelectedMemberSizeAlert = (members: TProps['members'], offerSource: OFFER_SOURCE, isNewFlow: boolean): React.ReactElement => {
  const selectedMember = filter(members, (m) => m.selected).length;
  if (offerSource === OFFER_SOURCE.TUNE || (offerSource === OFFER_SOURCE.SHOPIFY && !isNewFlow)) {
    return null;
  }
  if (selectedMember <= 200) {
    return null;
  }
  const message = 'Please select a maximum of 200 members at a time to generate the promo codes'.trim();
  return (
    <Notice type="error" className={styles.alert} message={message} />
  );
};

export const SelectMembers: React.FC<TProps> = (props) => {
  const {
    markMembersSelected,
    members,
    programLabel,
    offerSource,
    fetchMembersCount,
    isNewFlow,
    programs,
    selectedPrograms,
    markProgramsSelected,
    isWorkflow,
    migrateToGraphQL,
    searchKey,
    updateSearchKey,
    selectedMembers,
    resetMember,
    isLoading,
  } = props;
  // fetch members
  const [totalMember, setTotalMember] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const isShopify = isOfferShopify(offerSource);
  const isTune = isOfferTune(offerSource);
  const { isOldOffer } = useOfferDetailsContext() || {};
  const selectedMemberCount = isArray(members) ? filter(members, (m) => m.selected).length : 0;
  const selectedRowKeys = selectedMembers?.map((member) => member.id.toString());
  const pageSize = MAX_ALLOWED_MEMBERS;
  // Member Count
  useEffect(() => {
    if (props.mode !== 'add') {
      return;
    }
    if (isWorkflow) {
      setTotalMember(members ? members.length : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, isWorkflow]);
  useEffect(() => {
    if (props.mode !== 'add') {
      return;
    }

    const delayCountFetch = setTimeout(() => {
      if (!isWorkflow) {
        props.updateIsLoading(true);
        fetchMembersCount(props.programId, searchKey)
          .then((count) => {
            setTotalMember(count);
          })
          .catch((error) => {
            console.error('Error fetching total member count:', error);
          })
          .finally(() => {
            props.updateIsLoading(false);
          });
      }
    }, 500);
    return () => clearTimeout(delayCountFetch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMembersCount, searchKey, selectedPrograms, isWorkflow]);
  useEffect(() => {
    if (props.mode !== 'add' || !isNull(members)) {
      return;
    }

    props?.fetchMembers(currentPage, pageSize, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode]);

  useEffect(() => {
    if (props.mode !== 'add' || isWorkflow) {
      return;
    }

    props?.fetchMembers(currentPage, pageSize, searchKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);
  useEffect(() => {
    if (props.mode !== 'add' || isWorkflow) {
      return;
    }

    const searchMember = debounce(() => {
      props?.fetchMembers(currentPage, pageSize, searchKey);
    }, 200);

      searchMember();

    return () => {
      searchMember.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);
  const data = useMemo(() => {
    if (isNull(members)) {
      return [];
    }
    return map(members, (m): IRowData => {
      let errorMessage = '';
      if (m.selected) {
        if (props.mode === 'add' && isEmpty(m.code)) {
          errorMessage = 'Manual entry needed due to missing member details.';
        } else if (m.isDuplicate) {
          errorMessage = 'Code must be unique.';
        }
      }

      switch (props.mode) {
        case 'add': {
          let code = null;
          if (!m.forceCheckIn) {
            code = {
              code: m.code,
              errorMessage,
              onChange: (event) => {
                const input = event.target;
                const cursor = input.selectionStart;
                const preCursor = cleanPromoCode(input.value.slice(0, cursor));
                const postCursor = cleanPromoCode(input.value.slice(cursor));
                const code = `${preCursor}${postCursor}`;
                if (code !== m.code) {
                  props.updateMemberCode(m.id.toString(), 'code', code);
                }
                setTimeout(() => {
                  input.selectionStart = preCursor.length;
                  input.selectionEnd = preCursor.length;
                }, 0);
              },
            };
          }
          return {
            code,
            id: m.id.toString(),
            key: m.id.toString(),
            isDuplicate: m.selected && m.isDuplicate,
            name: m.name,
            instagramUsername: m.instagramUsername,
            offerStatus: getMemberOfferStatus(m, props.isWorkflow, offerSource),
            programs: m.selectedPrograms,
            _bodyRowClassName: cx({
              [styles.codeHasError]: m.selected && (isEmpty(m.code) || m.isDuplicate),
            }),
            disableSelection: m.forceCheckIn,
            deepLink: { ...m.deepLink, memberId: m.id.toString() },
            _raw: m,
          };
        }
        case 'refresh': {
          let code = null;
          if (!m.forceCheckIn) {
            code = {
              code: m.code,
              errorMessage,
              onChange: (event) => {
                const input = event.target;
                const cursor = input.selectionStart;
                const preCursor = cleanPromoCode(input.value.slice(0, cursor));
                const postCursor = cleanPromoCode(input.value.slice(cursor));
                const code = `${preCursor}${postCursor}`;
                if (code !== m.code) {
                  props.updateMemberCode(m.id.toString(), 'code', code);
                }
                setTimeout(() => {
                  input.selectionStart = preCursor.length;
                  input.selectionEnd = preCursor.length;
                }, 0);
              },
            };
          }
          return {
            code,
            id: m.id.toString(),
            key: m.id.toString(),
            isDuplicate: m.selected && m.isDuplicate,
            name: m.name,
            offerName: m.forceCheckIn ? getMemberOfferStatus(m, props.isWorkflow, offerSource) : props.offerName,
            previousCode: m.previousCode.code,
            previousCodeEndDate: moment(m.previousCode.end).format(
              `${ACTIVE_DATE_FORMAT}, ${ACTIVE_TIME_FORMAT}`,
            ),
            previousCodeStartDate: moment(m.previousCode.start).format(
              `${ACTIVE_DATE_FORMAT}, ${ACTIVE_TIME_FORMAT}`,
            ),
            _bodyRowClassName: cx({
              [styles.codeHasError]: m.selected && m.isDuplicate,
            }),
            disableSelection: m.forceCheckIn,
            _raw: m,
          };
        }
        case 'deactivate': {
          return {
            code: m.code,
            id: m.id.toString(),
            key: m.id.toString(),
            name: m.name,
            offerName: props.offerName,
            previousCode: m.previousCode.code,
            previousCodeEndDate: moment(m.previousCode.end).format(ACTIVE_DATE_FORMAT),
            previousCodeStartDate: moment(m.previousCode.start).format(ACTIVE_DATE_FORMAT),
            programs: m.selectedPrograms,
            _raw: m,
          };
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);
  const filteredData = useMemo(() => {
    if (!searchKey && !isWorkflow) {
      setTotalMember(totalMember);
      return data;
    } else if (props.isWorkflow) {
      const filterWorkflowData = _.filter(data, (item) => _.includes(item.name.toLowerCase(), searchKey.toLowerCase()));
      setTotalMember(filterWorkflowData.length);
      return filterWorkflowData;
    } else {
      const filterWorkflowData = _.filter(data, (item) => _.includes(item.name.toLowerCase(), searchKey.toLowerCase()));
      return filterWorkflowData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchKey, props.isWorkflow]);

  const onSearch = (e) => {
    console.log(e, 'erd');
    if (updateSearchKey) {
      updateSearchKey(e.target.value);
      setCurrentPage(1);
    }
  };
  const columnConfig = useMemo((): ITableColumnConfig[] => {
    switch (props.mode) {
      case 'add':
        const columns: ITableColumnConfig[] = [{
          cellType: 'text' as ITableCellType,
          headerName: 'Name',
          field: 'name',
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 5),
        },
        (props.isWorkflow || props.isNewFlow ? {
          cellType: 'text' as ITableCellType,
          headerName: 'Instagram',
          field: 'instagramUsername',
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
          render: (params) => {
            if (params.props.children === '') {
              return '-';
            } else {
              return params.props.children;
            }
          },
        } : {
          cellType: 'text' as ITableCellType,
          headerName: programLabel,
          field: 'programs',
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 5),
        }),
        {
          cellType: 'text' as ITableCellType,
          headerName: 'Offer',
          field: 'offerStatus',
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 5),
        }];
        if (isShopify && !props.isNewFlow) {
          columns.push({
            headerName: <PotentialCodeHeader />,
            field: 'code',
            render: (data) => {
              if (!data) {
                return null;
              }
              return (
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.codeInput}
                    onChange={data.onChange}
                    placeholder="TYPE CODE HERE"
                    value={data.code}
                  />
                  {data.errorMessage && (
                    <Tooltip
                      arrowPointAtCenter
                      overlayStyle={{
                        maxWidth: 270,
                      }}
                      placement="topRight"
                      title={data.errorMessage}
                    >
                      <ExclamationCircleFilled style={{ right: '1rem' }} />
                    </Tooltip>
                  )}
                </div>
              );
            },
            sortable: false,
            width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60,
          });
        }
        if (isTune && props.deepLinkColumn) {
          const { offerLink } = props;
          const offerURL = createUrlObj(offerLink);
          if (!isString(offerURL)) {
            columns.push({
              cellType: 'link',
              headerName: 'Deep Link',
              field: 'deepLink',
              editable: true,
              sortable: false,
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
              render: (data, context) => {
                const hasError = data.error && context.isRowSelected && !data.focused;
                const hasWarning = data.warning && context.isRowSelected && !data.focused;
                const inputStatus: IInputProps['status'] = hasError ? 'error' : hasWarning ? 'warning' : null;
                if (!context.isRowSelected) {
                  return <Typography.Text type="secondary">N\A</Typography.Text>;
                }
                return (
                  <Input
                    className={cx({ [styles.urlInputError]: hasError, [styles.urlInputWarning]: !hasError && hasWarning })}
                    onChange={(e) => props.updateMemberDeepLink(data.memberId, e.target.value, true)}
                    placeholder="Enter URL"
                    disabled={!context.isRowSelected}
                    value={data.link}
                    status={inputStatus}
                    onBlur={(e) => props.updateMemberDeepLink(data.memberId, e.target.value, false)}
                    suffix={((data.warning || data.error) && context.isRowSelected && !data.focused) ? (
                      <Tooltip
                        title={data.error || data.warning}
                      >
                        <TriangleExclamationIcon />
                      </Tooltip>
                    ) : <div />}
                  />
                );
              },
            });
          }
        }
        return columns;
      case 'refresh':
        if (isShopify) {
          const cols: ITableColumnConfig[] = [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 60,
            },
            {
              cellType: 'text',
              headerName: props.refreshDatesOnly ? 'Active Code' : 'Previous Code',
              field: 'previousCode',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
            },
          ];
          if (props.refreshDatesOnly) {
            // add date columns
            cols.push({
              cellType: 'text',
              headerName: 'Start Date',
              field: 'previousCodeStartDate',
              width: Math.floor((((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60) / 2),
            });
            cols.push({
              cellType: 'text',
              headerName: 'End Date',
              field: 'previousCodeEndDate',
              width: Math.floor((((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60) / 2),
            });
          } else {
            cols.push({
              headerName: <PotentialCodeHeader />,
              field: 'code',
              render: (data) => {
                if (!data) {
                  return null;
                }
                return (
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.codeInput}
                      onChange={data.onChange}
                      placeholder="TYPE CODE HERE"
                      value={data.code}
                    />
                    {data.errorMessage && (
                      <Tooltip
                        arrowPointAtCenter
                        overlayStyle={{
                          maxWidth: 270,
                        }}
                        placement="topRight"
                        title={data.errorMessage}
                      >
                        <ExclamationCircleFilled style={{ right: '1rem' }} />
                      </Tooltip>
                    )}
                  </div>
                );
              },
              sortable: false,
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60,
            });
          }
          return cols;
        }
        if (isTune) {
          return [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
            },
          ];
        }
        break;
      case 'deactivate':
        if (isShopify) {
          const columns: ITableColumnConfig[] = [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
            },
            {
              cellType: 'text',
              headerName: 'Current Code',
              field: 'previousCode',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
            },
          ];
          if (!props.isNewFlow) {
            columns.push(
              {
                cellType: 'text',
                headerName: 'Previous Start Date',
                field: 'previousCodeStartDate',
                width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
              },
              {
                cellType: 'text',
                headerName: 'Previous End Date',
                field: 'previousCodeEndDate',
                width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
              },
            );
          }

          return columns;
        }
        if (isTune) {
          return [
            {
              cellType: 'text',
              headerName: 'Member',
              field: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
            },
            {
              cellType: 'text',
              headerName: 'Offer',
              field: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
            },
          ];
        }
    }
  }, [
    props,
    programLabel,
    isShopify,
    isTune,
  ]);
  const memberIds = useMemo(
    () => map(filter(members, (m) => m.selected), (m) => m.id.toString()),
    [members],
  );
  const columnConfigNew: IColumnsType<IRowData> = useMemo(() => {
    switch (props.mode) {
      case 'add':
        const columns: IColumnsType<IRowData> = [{
          dataIndex: 'name',
          key: 'name',
          title: 'Name',
          render: (data) => (
            <Tooltip title={data}>{data}</Tooltip>
          ),
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
          sorter: (a, b) => a?.name.localeCompare(b?.name),
        },
        (props.isWorkflow || props.isNewFlow ? {
          title: 'Instagram',
          dataIndex: 'instagramUsername',
          key: 'instagramUsername',
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
          sorter: (a, b) => {
            const usernameA = a?.instagramUsername || '';
            const usernameB = b?.instagramUsername || '';
            return usernameA.localeCompare(usernameB);
          },
          render: (data) => data || '-',
        } : {
          title: programLabel,
          dataIndex: 'programs',
          key: 'programs',
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 5),
          sorter: (a, b) => a?.programs.localeCompare(b?.programs),
        }),
        {
          title: 'Offer',
          dataIndex: 'offerStatus',
          key: 'offerStatus',
          width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 5),
          sorter: (a, b) => a.offerStatus.localeCompare(b.offerStatus),
        }];
        if (isShopify && !props.isNewFlow) {
          columns.push({
            title: <PotentialCodeHeader />,
            dataIndex: 'code',
            key: 'code',
            render: (data) => {
              if (!data) {
                return null;
              }
              return (
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.codeInput}
                    onChange={data.onChange}
                    placeholder="TYPE CODE HERE"
                    value={data.code}
                  />
                  {data.errorMessage && (
                    <Tooltip
                      arrowPointAtCenter
                      overlayStyle={{
                        maxWidth: 270,
                      }}
                      placement="topRight"
                      title={data.errorMessage}
                    >
                      <ExclamationCircleFilled style={{ right: '1rem' }} />
                    </Tooltip>
                  )}
                </div>
              );
            },
            width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60,
          });
        }
        if (isTune && props.deepLinkColumn) {
          const { offerLink } = props;
          const offerURL = createUrlObj(offerLink);
          if (!isString(offerURL)) {
            columns.push({
              title: 'Deep Link',
              dataIndex: 'deepLink',
              key: 'deepLink',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
              render: (data, record, _index) => {
                const hasError = data.error && record._raw.selected && !data.focused;
                const hasWarning = data.warning && record._raw.selected && !data.focused;
                const inputStatus: IInputProps['status'] = hasError ? 'error' : hasWarning ? 'warning' : null;
                if (!record._raw.selected) {
                  return <Typography.Text type="secondary">N\A</Typography.Text>;
                }
                return (
                  <Input
                    className={cx({ [styles.urlInputError]: hasError, [styles.urlInputWarning]: !hasError && hasWarning })}
                    onChange={(e) => props.updateMemberDeepLink(data.memberId, e.target.value, true)}
                    placeholder="Enter URL"
                    disabled={!record._raw.selected}
                    value={data.link}
                    status={inputStatus}
                    onBlur={(e) => props.updateMemberDeepLink(data.memberId, e.target.value, false)}
                    suffix={((data.warning || data.error) && record._raw.selected && !data.focused) ? (
                      <Tooltip
                        title={data.error || data.warning}
                      >
                        <TriangleExclamationIcon />
                      </Tooltip>
                    ) : <div />}
                  />
                );
              },
            });
          }
        }
        return columns;
      case 'refresh':
        if (isShopify) {
          const cols: IColumnsType<IRowData> = [
            {
              title: 'Member',
              dataIndex: 'name',
              key: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
              sorter: (a, b) => a?.name.localeCompare(b?.name),
            },
            {
              title: 'Offer',
              dataIndex: 'offerName',
              key: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) - 60,
              sorter: (a, b) => a?.offerName.localeCompare(b?.offerName),
            },
            {
              title: props.refreshDatesOnly ? 'Active Code' : 'Previous Code',
              dataIndex: 'previousCode',
              key: 'previousCode',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4),
              ellipsis: {
                showTitle: false,
              },
              render: (code) => (
                <Tooltip placement="topLeft" title={code}>
                  {code}
                </Tooltip>
              ),
            },
          ];
          if (props.refreshDatesOnly) {
            // add date columns
            cols.push({
              title: 'Start Date',
              dataIndex: 'previousCodeStartDate',
              key: 'previousCodeStartDate',
              width: Math.floor((((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60) / 2),
              sorter: (a, b) => a?.previousCodeStartDate.localeCompare(b?.previousCodeStartDate),
            });
            cols.push({
              title: 'End Date',
              dataIndex: 'previousCodeEndDate',
              key: 'previousCodeEndDate',
              width: Math.floor((((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60) / 2),
              sorter: (a, b) => a?.previousCodeEndDate.localeCompare(b?.previousCodeEndDate),
            });
          } else {
            cols.push({
              title: <PotentialCodeHeader />,
              dataIndex: 'code',
              key: 'code',
              render: (data) => {
                if (!data) {
                  return null;
                }
                return (
                  <div className={styles.inputWrapper}>
                    <input
                      className={styles.codeInput}
                      onChange={data.onChange}
                      placeholder="TYPE CODE HERE"
                      value={data.code}
                    />
                    {data.errorMessage && (
                      <Tooltip
                        arrowPointAtCenter
                        overlayStyle={{
                          maxWidth: 270,
                        }}
                        placement="topRight"
                        title={data.errorMessage}
                      >
                        <ExclamationCircleFilled style={{ right: '1rem' }} />
                      </Tooltip>
                    )}
                  </div>
                );
              },
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 4) + 60,
            });
          }
          return cols;
        }
        if (isTune) {
          return [
            {
              title: 'Member',
              dataIndex: 'name',
              key: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
              sorter: (a, b) => a?.name.localeCompare(b?.name),
            },
            {
              title: 'Offer',
              dataIndex: 'offerName',
              key: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
              sorter: (a, b) => a?.offerName.localeCompare(b?.offerName),
            },
          ];
        }
        break;
      case 'deactivate':
        if (isShopify) {
          const columns = [
            {
              title: 'Member',
              dataIndex: 'name',
              key: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
              sorter: (a, b) => a?.name.localeCompare(b?.name),
            },
            {
              title: 'Offer',
              dataIndex: 'offerName',
              key: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
              sorter: (a, b) => a?.offerName.localeCompare(b?.offerName),
            },
            {
              title: 'Current Code',
              dataIndex: 'previousCode',
              key: 'previousCode',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
              sorter: (a, b) => a?.previousCode.localeCompare(b?.previousCode),
            },
          ];
          if (!props.isNewFlow) {
            columns.push(
              {
                title: 'Previous Start Date',
                dataIndex: 'previousCodeStartDate',
                key: 'previousCodeStartDate',
                width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
                sorter: (a, b) => a?.previousCodeStartDate.localeCompare(b?.previousCodeStartDate),
              },
              {
                title: 'Previous End Date',
                dataIndex: 'previousCodeEndDate',
                key: 'previousCodeEndDate',
                width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 3),
                sorter: (a, b) => a?.previousCodeEndDate.localeCompare(b?.previousCodeEndDate),
              },
            );
          }

          return columns;
        }
        if (isTune) {
          return [
            {
              title: 'Member',
              dataIndex: 'name',
              key: 'name',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
              sorter: (a, b) => a?.name.localeCompare(b?.name),
            },
            {
              title: 'Offer',
              dataIndex: 'offerName',
              key: 'offerName',
              width: Math.floor((TABLE_WIDTH - CHECKBOX_COL_WIDTH) / 2),
              sorter: (a, b) => a?.offerName.localeCompare(b?.offerName),
            },
          ];
        }
    }
  }, [
    props,
    programLabel,
    isShopify,
    isTune,
  ]);

  if (props.mode === 'add' && props.noMembersWarning) {
    return props.noMembersWarning;
  }

  const rowSelection = {
    onChange: (_, selectedRows) => {
      markMembersSelected(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.forceCheckIn || record.disableSelection,
      name: record.name,
      hideSelectAll: true,
      defaultChecked: record.selected,
    }),
    selectedRowKeys,
  };
  const onProjectChange = (id: number) => {
    resetMember();
    // updateSearchKey('');
    if (id) {
      setCurrentPage(1);
      const selectedProject = programs.filter((program) => program.id === id);
      markProgramsSelected(selectedProject);
      props?.fetchMembers(currentPage, pageSize, searchKey, selectedProject.map((project) => Number(project.id)));
      return;
    }
    markProgramsSelected([]);
    props?.fetchMembers(currentPage, pageSize, searchKey, []);
  };
  const renderMemberCount = () => (
    <Title level={5}>
      {totalMember ? `${selectedMemberCount ? `${totalMember} Members (${selectedMemberCount} selected)` : `${totalMember} Members`}` : ''}
    </Title>
  );
  const headerAction = () => {
    const selectedProgramId = !isWorkflow && selectedPrograms && Object.values(selectedPrograms).length ? Object.values(selectedPrograms)[0].id : '';
    if (!migrateToGraphQL && isOldOffer) {
      return null;
    }
    return (
      <Row align="middle" className={styles.fullWidth} justify={isWorkflow ? 'end' : 'space-between'} gutter={8}>
        {
          !isWorkflow && programs?.length && (
            <Col span={12}>
              <Select value={selectedProgramId} onChange={(id: number) => onProjectChange(id)} className={styles.projectSelect} style={{ width: '100%' }}>
                <Option value="">All Projects</Option>
                {
                  programs.map(((program) => program)).sort((a, b) => a.name.localeCompare(b.name)).map((program) => (<Option key={program.id} value={program.id}>{program.name}</Option>))
                }
              </Select>
            </Col>
          )
        }
        <Col span={props.mode === 'refresh' || isWorkflow ? '' : '12'}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={searchKey}
            onChange={(e) => onSearch(e)}
            autoFocus
            allowClear
          />
        </Col>
      </Row>
    );
  };

  const renderHeader = () => (
    <Row justify="space-between">
      <Col>
        {renderMemberCount()}
      </Col>
      <Col>
        {headerAction()}
      </Col>
    </Row>
  );

  if (isNull(members)) {
    return (
      <>
        <LoadSpinner />
      </>
    );
  }

  if (!isWorkflow && programs?.length && !filteredData.length) {
    return (
      <>
        {renderHeader()}
        <Empty
          className={styles.emptyContainerMargin}
          description={(
            <>
              <Typography.Title level={5}>
                No members found
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Either there are no members, or all members are already part of this offer.
              </Typography.Paragraph>
            </>
          )}
          size="small"
        />
      </>
    );
  }

  return (
    <>
      {' '}
      {!props.isNewFlow ? (
        <>
          {getMissingCodesAlert(props.mode, props.offerSource, members)}
          {getDuplicateCodesAlert(members)}
        </>
      ) : null}
      {getSelectedMemberSizeAlert(members, offerSource, isNewFlow)}
      {props.isNewFlow ? (
        <AffiliateTableSTA<IRowData>
          dataSource={filteredData}
          columns={arrangeTableColumns(columnConfigNew)}
          rowSelection={rowSelection}
          pagination={false}
          searchText={searchKey}
          headerActions={renderMemberCount()}
          searchBox={headerAction()}
          enableCustomPagination={{
            pageSize,
            total: totalMember,
            page: currentPage,
            setPage: setCurrentPage,
            isDynamicPaginationEnabled: false,
          }}
          rowClassName={(record) => record._bodyRowClassName}
          loading={isLoading}
        />
      ) : (
        <Table
          columns={columnConfig}
          config={{
            allowSearch: false,
            configurableColumns: false,
            reorderableColumns: false,
            rowHeight: 56,
            scrollable: true,
            selectable: true,
            striped: false,
          }}
          data={filteredData}
          onChangeCellValue={props.mode !== 'deactivate' ? props.updateMemberCode : undefined}
          onSelectedDataChange={markMembersSelected}
          paddingBottom={100}
          headerActions={headerAction()}
          initialSelectedIds={memberIds}
        />
      )}

    </>
  );
};
