import { useMutation, MutationHookOptions } from '@apollo/client';

import { GUEST_REJECT_CONTENT_REVIEW_MUTATION } from '@frontend/app/queries';

import {
  GuestRejectContentReviewMutation,
  GuestRejectContentReviewMutationVariables,
} from '@frontend/app/queries/types/GuestRejectContentReviewMutation';

type IOptions = MutationHookOptions<GuestRejectContentReviewMutation, GuestRejectContentReviewMutationVariables>;

export function useGuestRejectContentReview(options: IOptions = {}) {
  const [rejectReview, { loading, error }] = useMutation<
    GuestRejectContentReviewMutation,
    GuestRejectContentReviewMutationVariables
  >(GUEST_REJECT_CONTENT_REVIEW_MUTATION, {
    ...options,
  });

  return {
    rejectReview,
    loading,
    error,
  };
}
