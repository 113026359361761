import { MutationHookOptions, useMutation } from '@apollo/client';

import { DELETE_AUTOMATION_MUTATION } from '@frontend/app/queries';
import { DeleteAutomation, DeleteAutomationVariables } from '@frontend/app/queries/types/DeleteAutomation';

type IOptions = MutationHookOptions<DeleteAutomation, DeleteAutomationVariables>;

export const useDeleteAutomationMutation = (options: IOptions = {}) =>
  useMutation<DeleteAutomation, DeleteAutomationVariables>(DELETE_AUTOMATION_MUTATION, {
    ...options,
    update(cache) {
      cache.evict({ fieldName: 'getAutomationSummary' });
      cache.evict({ fieldName: 'getAutomationStats' });
      cache.evict({ fieldName: 'listTemplates' });
      cache.gc();
    },
  });
