/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fmrg_157 {
  display: flex;
}

._justify-content-space-between_1fmrg_161 {
  justify-content: space-between;
}

._tabular-nums_1fmrg_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fmrg_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._fiscalYearBudgetContainer_1fmrg_178 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

._addFiscalYearButton_1fmrg_184 {
  padding-left: 0;
  background: #FAFAFA !important;
  background-color: #FAFAFA !important;
}

._addFiscalYearContainer_1fmrg_190 {
  display: flex;
  gap: 1rem;
}