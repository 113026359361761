import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const ItalicIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M19.2899 2H8.63463C8.09332 2 7.65172 2.4416 7.65172 2.98291C7.65172 3.52422 8.09332 3.96581 8.63463 3.96581H12.7728L9.2258 20.0413H4.71725C4.17594 20.0413 3.73434 20.4758 3.73434 21.0171C3.73434 21.5584 4.17594 22 4.71725 22H15.3725C15.9138 22 16.3554 21.5584 16.3554 21.0171C16.3554 20.4758 15.9138 20.0342 15.3725 20.0342H11.2343L14.7742 3.95869H19.2899C19.8312 3.95869 20.2728 3.51709 20.2728 2.97578C20.2728 2.43447 19.8312 2 19.2899 2Z" />
  </SvgIcon>
));

ItalicIcon.displayName = 'ItalicIcon';
