import * as React from 'react';

import {
 each, isEqual, first, last, map, upperFirst, isNumber,
} from 'lodash';
import { Slider } from 'antd';

import { Tooltip } from '@revfluence/fresh';
import {
  InstagramIcon,
  FacebookIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
} from '@components';

import {
  GetCampaignByProjectIdQuery_campaign_network_details as INetworkDetail,
} from '@frontend/app/queries/types/GetCampaignByProjectIdQuery';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { NETWORK_TYPE } from '../../context/model';
import { AudienceAuthenticitySection, networkSliders } from './model';
import { useListOnMarketplace } from '../../context';

import styles from './styles.scss';

const { useEffect, useMemo } = React;

interface INetworkRestrictionProps {
  type: NETWORK_TYPE
}

const NetworkRestrictionComponent: React.FC<INetworkRestrictionProps> = (props) => {
  const {
    type,
  } = props;

  const {
    campaign,
    campaignInputData,
    setFieldValue,
    getFieldValue,
    setHasBeenTouched,
  } = useListOnMarketplace();

  const networkDetailValue: INetworkDetail = useMemo(() => (
    getFieldValue(`network_details.${type}`, {}) as unknown as INetworkDetail
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [type, campaignInputData]);

  const setNetworkDetailField = (key, value) => {
    setFieldValue(`network_details.${type}.${key}`, value);
  };

  const hideIgAudAuthEligiblityResriction = useClientFeatureEnabled(ClientFeature.HIDE_IG_AUD_AUTH_ELIGIBILITY_RESTRICTION);

  const networkIcon = useMemo(() => {
    switch (type) {
      case NETWORK_TYPE.TIKTOK:
        return <TiktokIcon />;
      case NETWORK_TYPE.INSTAGRAM:
        return <InstagramIcon />;
      case NETWORK_TYPE.FACEBOOK:
        return <FacebookIcon />;
      case NETWORK_TYPE.PINTEREST:
        return <PinterestIcon />;
      case NETWORK_TYPE.TWITTER:
        return <TwitterIcon />;
      case NETWORK_TYPE.YOUTUBE:
        return <YoutubeIcon />;
      default:
        return null;
    }
  }, [type]);

  const networkName = useMemo(() => {
    switch (type) {
      case NETWORK_TYPE.TIKTOK:
        return 'TikTok';
      case NETWORK_TYPE.YOUTUBE:
        return 'YouTube';
      default:
        return upperFirst(type);
    }
  }, [type]);

  const formatNumber = (value) => {
    const formatter = new Intl.NumberFormat('en-US');
    return formatter.format(value);
  };

  const formatPercentage = (value) => {
    if (!isNaN(value)) {
      return `${value}%`;
    }
    return value;
  };

  // This is painfully complicated because we need to show a special tooltip for max slider value
  // for one-sided reverse sliders (which antd sort of supports, but the numbers go DOWN as you drag to the right)
  // so it is not usable.
  const tooltipFormatter = (isPercentSlider: boolean, minOnly: boolean, max: number) => {
    const formatterFunction = (value) => {
      if (minOnly && value === max) {
        return 'Any';
      }
      return isPercentSlider ? formatPercentage(value) : formatNumber(value);
    };
    return formatterFunction;
  };

  const handleSliderChange = (sliderKey, newValues, minOnly) => {
    const currentMinValue = networkDetailValue[`minimum_${sliderKey}`];
    const newMinValue = first(newValues);
    if (newMinValue !== currentMinValue) {
      setHasBeenTouched(true);
    }

    if (minOnly) {
      setNetworkDetailField(`minimum_${sliderKey}`, newMinValue);
    } else {
      const currentMaxValue = networkDetailValue[`maximum_${sliderKey}`];
      const newMaxValue = last(newValues);
      if (currentMaxValue !== newMaxValue) {
        setHasBeenTouched(true);
      }
      setNetworkDetailField(`minimum_${sliderKey}`, newMinValue);
      setNetworkDetailField(`maximum_${sliderKey}`, newMaxValue);
    }
  };

  const renderSlider = (sliderData) => {
    const {
      key,
      title,
      min,
      max,
      isPercentSlider,
      step,
      minOnly,
      tooltip,
    } = sliderData;

    const defaultValue = [min, max] as [number, number];

    const currentValue: [number, number] = [
      networkDetailValue[`minimum_${key}`] || min || 0,
      networkDetailValue[`maximum_${key}`] || max || 0,
    ];

    const isFullRange = isEqual(currentValue, [min, max]);
    const valueFormatter = isPercentSlider ? formatPercentage : formatNumber;

    let footerLabel = '';
    if (isFullRange || minOnly) {
      footerLabel = `Over ${valueFormatter(currentValue[0])}`;
    } else {
      footerLabel = `${valueFormatter(currentValue[0])} to ${valueFormatter(currentValue[1])}`;
      if (currentValue[1] === max) {
        footerLabel += '+';
      }
    }

    return (
      <div
        key={`slider_${type}_${key}`}
        className={styles.slider}
      >
        <Tooltip title={tooltip}>
          <div className={styles.sliderTitle}>
            {`${title}:`}
          </div>
        </Tooltip>
        <div className={styles.sliderInput}>
          <Slider
            range
            min={min}
            max={max}
            step={step}
            value={currentValue}
            defaultValue={defaultValue}
            onChange={(newValues) => handleSliderChange(key, newValues, minOnly)}
            tipFormatter={tooltipFormatter(isPercentSlider, minOnly, max)}
          />
        </div>
        <div className={styles.sliderFooter}>
          {`${footerLabel} ${title.toLowerCase()}`}
        </div>
      </div>
    );
  };

  const setDefaultData = () => {
    const defaultData = {
      network_type: type,
      maximum_budget: networkDetailValue.maximum_budget || 0,
    };

    if (networkSliders[type]) {
      if (type === NETWORK_TYPE.INSTAGRAM && !hideIgAudAuthEligiblityResriction) {
        networkSliders[type].push(AudienceAuthenticitySection);
      }
      each(networkSliders[type], (sliderData) => {
        const minKey = `minimum_${sliderData.key}`;
        const currentMin = networkDetailValue[minKey];
        if (isNumber(currentMin)) {
          defaultData[minKey] = currentMin;
        } else {
          defaultData[minKey] = sliderData.defaultMin || sliderData.min;
        }

        if (!sliderData.minOnly) {
          const maxKey = `maximum_${sliderData.key}`;
          const currentMax = networkDetailValue[maxKey];
          if (isNumber(currentMax)) {
            defaultData[maxKey] = currentMax;
          } else {
            defaultData[maxKey] = sliderData.defaultMax || sliderData.max;
          }
        }
      });
    }

    setFieldValue(`network_details.${type}`, defaultData);
  };

  const sliders = useMemo(() => {
    if (!networkSliders[type]) {
      return null;
    }

    return (
      <div className={styles.sliders}>
        {map(networkSliders[type], renderSlider)}
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, campaignInputData?.network_details]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setDefaultData, [type, campaign]);

  return (
    <div className={styles.networkRestriction}>
      <div className={styles.header}>
        <div className={styles.networkIcon}>
          {networkIcon}
        </div>
        <div className={styles.networkName}>
          {networkName}
        </div>
      </div>
      <div className={styles.content}>
        {sliders}
      </div>
    </div>
  );
};

const NetworkRestriction = React.memo(NetworkRestrictionComponent);

export default NetworkRestriction;
