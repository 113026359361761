import {
  TActiveDates,
  TMember,
  TProgram,
  TSelectedMember,
} from '@affiliates/components/MembersWizard/types';

interface IState {
  activeDates: TActiveDates;
  currentStep: number;
  error: Error | null;
  members: readonly TSelectedMember[] | null;
  programs: readonly TProgram[] | null;
  saving: boolean;
  selectedPrograms: {
    [programId: string]: TProgram;
  };
  selectedMembers: readonly TSelectedMember[];
  searchKey: string;
  showCloseConfirmation: boolean;
  showNoMembersWarning: boolean;
  isLoading: boolean,
  bulkPayoutId?: number | null;
  bulkStartDate?: Date,
  bulkEndDate?: Date,
  showEndDate?: boolean,
}
export type TState = Readonly<IState>;

interface IActions {
  dismissCloseConfirmation: () => void;
  dismissNoMembersWarning: () => void;
  populateMembers: (members: readonly TMember[]) => void;
  populatePrograms: (programs: readonly TProgram[]) => void;
  reset: () => void;
  save: (nextStep: number) => void;
  selectMembers: (members: readonly TSelectedMember[]) => void;
  selectPrograms: (programs: readonly TProgram[]) => void;
  setCurrentStep: (step: number) => void;
  setError: (error: Error) => void;
  showCloseConfirmation: () => void;
  updateActiveDateField: (dateField: keyof TState['activeDates'], value?: string) => void;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  updateMemberCode: (memberId: string, field: string, value: any) => void;
  updateMemberPayoutId: (memberId: string, field: string, value: number) => void;
  updateMemberDeepLink: (offerLink?: string, domains?: string[], isCreatorDeepLinkAllowed?: boolean) => (memberId: string, deeplink: string, focused: boolean) => void;
  updateBulkActiveDate: (field: string, value: Date) => void;
  updateBulkPayoutId: (payoutId: number) => void;
  updateMemberActiveDate: (memberId: number, field: string, value: Date) => void;
  updateShowEndDate: (showEndDate: boolean) => void;
  updateSearchKey: (searchKey: string) => void;
  resetMember: () => void;
  updateIsLoading: (isLoading: boolean) => void;
}
export type TActions = Readonly<IActions>;
