import gql from 'graphql-tag';

import { MEMBER_FRAGMENT } from './fragments';
import { GUIDELINE_FRAGMENT } from './fragments/GuidelineFragment';

export const TERMS_CONFIG_QUERY = gql`
  query TermsConfigsQuery($programId: Int!, $memberIds: [Int!]!) {
    termsConfig: getDataForTermsForm(programId: $programId, memberIds: $memberIds) {
      settings {
        daysToApproveContent
        isBrandedContentEnabled
        whitelistingLabel
        creatorEditEnabled
        advancedTerms
        exclusivityLanguage
        requiresExclusivity
        paymentPeriod
        customTerms {
          url
          text
        }
        contentUsageRights {
          type
          text
        }
        contentGuidelines {
          type
          label
          networkType
          priority
          attachments {
            filename
            url
            type
          }
          guidelines {
            ...GuidelineFragment
          }
        }
      }
      members {
        ...MemberFragment
      }
    }
  }
  ${MEMBER_FRAGMENT}
  ${GUIDELINE_FRAGMENT}
`;
