import gql from 'graphql-tag';

export const MEMBER_RESTRICTION_FRAGMENT = gql`
  fragment MemberRestrictionFragment on MemberRestriction {
    id
    fieldName
    filterType
    filterValue
    createdDate
  }
`;
