import {
    Tooltip,
    Button,
    Modal,
    Form,
    Input,
    Typography,
    message,
    Row,
    Col,
  } from '@revfluence/fresh';
  import {
    isEmpty,
  } from 'lodash';
  import React, { useMemo, useState } from 'react';
  import { EditOutlined, DeleteOutlined, InfoCircleFilled } from '@ant-design/icons';
  import { useMutation } from '@apollo/client';
import { format } from 'date-fns';
  import styles from './DetailDeeplink.scss'; // Adjusted import for SCSS styles

  import { IAdditionalDeepLinks } from '../MemberTable/types';
import { UPDATE_DEEPLINK_MUTATION } from '../../queries';
import { DELETE_DEEPLINK_MUTATION } from '../../queries';
import { UPDATE_DEFAULT_DEEPLINK } from '../../queries';
import { validateDeepLink } from '../MembersWizard/utils/validateDeepLinkUrls';
import { mapErrToMessage } from '../../utils';

  const { Text } = Typography;
  interface DetailDeepLinkProps {
    deepLink?: IAdditionalDeepLinks;
    offerLinkUrl?: string;
    domains?: string[] | null | undefined;
    isPrimaryDeeplink?: boolean;
    primaryDeeplink?: {
      affiliateOfferLinkId: number;
      shortLink: string;
      url: string;
      createdDate?: string | Date;
      creator?: string;
    };
    refresh?: () => void;
    setDeeplinkDrawerVisible?: (val: boolean)=>void;
    isOfferArchived: boolean;
  }

  export const DetailDeepLink = ({
    deepLink,
    offerLinkUrl,
    domains,
    isPrimaryDeeplink = false,
    primaryDeeplink,
    setDeeplinkDrawerVisible,
    refresh,
    isOfferArchived,
  }: DetailDeepLinkProps) => {
    // const deepLinkUrl = isPrimaryDeeplink && !primaryDeeplink.url ? null : new URL(deepLink!.url);
    // deepLinkUrl.searchParams.delete('transaction_id'); // remove transaction_id param
    const [formDeepLinkLabel, setDeepLinkLabel] = useState<string | null>(
      isPrimaryDeeplink ? 'Primary Link' : deepLink.label,
    );
    const initialDeeplinkUrl = useMemo(() => {
      let url: string;
      if (isPrimaryDeeplink) {
        if (primaryDeeplink.url) {
          const deeplink = new URL(primaryDeeplink.url);
          deeplink.searchParams.delete('transaction_id');
          url = deeplink.toString();
        } else {
          url = '';
        }
      } else {
        const deeplink = new URL(deepLink.url);
        deeplink.searchParams.delete('transaction_id');
        url = deeplink.toString();
      }
      return url;
    }, [isPrimaryDeeplink, primaryDeeplink, deepLink]);

    const [formDeepLinkUrl, setDeepLinkUrl] = useState<string>(initialDeeplinkUrl);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [editMutation, { loading: updateLoading }] = useMutation(UPDATE_DEEPLINK_MUTATION);
    const [editDefaultDeeplink] = useMutation(UPDATE_DEFAULT_DEEPLINK);
    const [deleteMutation, { loading: deleteLoading }] = useMutation(DELETE_DEEPLINK_MUTATION);
    const [isEditTooltipVisible, setEditTooltipVisible] = useState(false);
    const [linkValidation, setLinkValidation] = useState<{ error: string | null; warning: string | null }>({
      error: null,
      warning: null,
    });

    const handleValidateDeepLink = (value: string) => {
      const validation = validateDeepLink(value, offerLinkUrl, true, domains);
      setLinkValidation({
        error: validation.error,
        warning: validation.warning,
      });
    };
    const onEditDeeplinkClick = async () => {
      setEditTooltipVisible(false);
      try {
        const formUrl = new URL(formDeepLinkUrl);
        if (isPrimaryDeeplink) {
          if (!primaryDeeplink.affiliateOfferLinkId) {
            throw new Error('Cannot update primary deeplink');
          }
          const updateData = {
            affiliateOfferLinkId: primaryDeeplink.affiliateOfferLinkId,
            ...(formUrl.toString() === initialDeeplinkUrl ? {} : { deepLinkUrl: formUrl.toString() }),
          };
          await editDefaultDeeplink({
            variables: {
              data: updateData,
            },
            onCompleted() {
              if (refresh) {
                refresh();
              }
              setDeeplinkDrawerVisible(false);
            },
          });
        } else {
          const updateData = {
            affiliateDeepLinkId: deepLink.id,
            deepLinkLabel: formDeepLinkLabel,
            ...(formUrl.toString() === initialDeeplinkUrl ? {} : { deepLinkUrl: formUrl.toString() }),
          };

          await editMutation({
            variables: {
              data: updateData,
            },
            onCompleted() {
              if (refresh) {
                refresh();
              }
              setDeeplinkDrawerVisible(false);
            },
          });
        }
        message.success('Link updated successfully');
      } catch (error) {
        message.error(mapErrToMessage(error.message));
      }
    };

    const onOpenDeleteModal = () => {
      setOpenDeleteModal(true);
    };
    const onDeleteDeepLink = async () => {
      await deleteMutation({
        variables: {
          affiliateDeepLinkId: deepLink.id,
        },
        onCompleted() {
          if (refresh) {
            refresh();
          }
          setDeeplinkDrawerVisible(false);
        },
      });
    //   await refetch();
      message.success('Link removed successfully');
      // add refetch logic here
      setOpenDeleteModal(false);
    };

    const editLinkForm = (
      <div className={styles.editFormContainer}>
        <Form layout="vertical">
          {/* form inputs */}
          <div className={styles.formGroup}>
            <Form.Item
              name="customLabel"
              label={(
                <Row gutter={8}>
                  <Col>
                    <Text>Edit Label</Text>
                  </Col>
                  <Col>
                    <Tooltip title="You can edit the destination Deep Link URL. Editing it will not change the short URL generated. ">
                      <InfoCircleFilled style={{ color: 'grey' }} />
                    </Tooltip>
                  </Col>
                </Row>
              )}
              rules={[{ required: !isPrimaryDeeplink, message: 'Please Enter Link Name' }]}
            >
              <Input
                className={styles.styledInput}
                placeholder="Label"
                onChange={(event) => {
                  setDeepLinkLabel(event.target.value);
                }}
                defaultValue={formDeepLinkLabel || ''}
                disabled={isPrimaryDeeplink || isOfferArchived}
              />
            </Form.Item>
          </div>
          <div className={styles.formGroup}>
            <Form.Item name="url" label="Edit Deep Link URL ">
              <Input
                className={styles.styledInput}
                placeholder="Custom URL"
                onChange={(event) => {
                  handleValidateDeepLink(event.target.value);
                  setDeepLinkUrl(event.target.value);
                }}
                defaultValue={formDeepLinkUrl}
                status={linkValidation.error ? 'error' : linkValidation.warning ? 'warning' : ''}
                disabled={isOfferArchived}
              />
              {linkValidation.error && (
                <Text type="danger" className="text-red-500">
                  {linkValidation.error}
                </Text>
              )}
              {!linkValidation.error && linkValidation.warning && (
                <Text type="warning" className="text-yellow-500">
                  {linkValidation.warning}
                </Text>
              )}
            </Form.Item>
          </div>

          {/* form buttons */}
          <div className={styles.styledButtonGroup}>
            <Button
              type="text"
              onClick={() => {
                setShowEditForm(false);
                setDeepLinkLabel(deepLink.label);
                setDeepLinkUrl(initialDeeplinkUrl);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onEditDeeplinkClick}
              loading={updateLoading}
              disabled={isEmpty(formDeepLinkLabel) || isEmpty(formDeepLinkUrl)}
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    );

    return (
      <>
        {showEditForm && editLinkForm}
        <Row justify="space-between">
          <Col span={16}>
            <Typography.Text type="secondary">{'Created At: '}</Typography.Text>
            <Typography.Text strong>
              {format(new Date(isPrimaryDeeplink ? primaryDeeplink.createdDate : deepLink.createdDate), 'MM/dd/yyyy')}
            </Typography.Text>
          </Col>
          <Col span={8}>
            <Row gutter={16} justify="end">
              <Col>
                <Tooltip title="Delete" placement={showEditForm ? 'left' : 'top'}>
                  <Button
                    type="text"
                    className={styles.customButton}
                    onClick={onOpenDeleteModal}
                    icon={<DeleteOutlined />}
                    disabled={isPrimaryDeeplink || isOfferArchived}
                  />
                </Tooltip>
              </Col>
              <Col>
                {' '}
                <Tooltip title="Edit" visible={isEditTooltipVisible}>
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditTooltipVisible(false);
                      setShowEditForm(true);
                    }}
                    disabled={showEditForm}
                    onMouseEnter={() => setEditTooltipVisible(true)}
                    onMouseLeave={() => setEditTooltipVisible(false)}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          open={openDeleteModal}
          onOk={onDeleteDeepLink}
          onCancel={() => {
            setOpenDeleteModal(false);
          }}
          okText="Delete"
          okButtonProps={{ danger: true, type: 'default', loading: deleteLoading }}
          width={300}
          closable={false}
        >
          <h3>Are you sure you want to remove this link?</h3>
          <p>
            The link will be removed from the creators, but it will remain active for use until the Primary Link is
            deactivated. This action cannot be undone
          </p>
        </Modal>
      </>
    );
};
