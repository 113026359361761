/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1ibp8_157 {
  display: flex;
}

._justify-content-space-between_1ibp8_161 {
  justify-content: space-between;
}

._tabular-nums_1ibp8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1ibp8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SelectOffersTable_1ibp8_209 {
  height: 100%;
}
._SelectOffersTable_1ibp8_209 > div {
  height: 100%;
  max-height: 100% !important;
}
._SelectOffersTable_1ibp8_209 img {
  max-width: 2.5rem;
  max-height: 2.5rem;
}
._SelectOffersTable_1ibp8_209 .ant-table-tbody .ant-radio-inner::after {
  width: 18px;
  height: 18px;
}