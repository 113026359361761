/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fgky_157 {
  display: flex;
}

._justify-content-space-between_1fgky_161 {
  justify-content: space-between;
}

._tabular-nums_1fgky_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fgky_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._AlreadySubmitted_1fgky_178 {
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: #e6e4e7;
  color: #505256;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
._AlreadySubmitted_1fgky_178 ._Link_1fgky_188 {
  color: #3996e0;
}