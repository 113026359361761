import { isNil } from 'lodash';
import { connect } from 'react-redux';
import { hasFeature } from '@components';
import { IConnectSearchPage } from '../models';
import {
  uploadImage,
  removeImage,
  updateCountries,
  updateHideInvited,
  fetchContentLists,
  setUploadedImageUrl,
  setUploadedImageError,
  setUploadedImageLoading,
} from '../redux/imageSearchActions';
import { showCountryFilterSelector } from '../redux/selectors';
import { ImageSearch } from '../ImageSearchFilters/ImageSearch';

const mapStateToProps = (state: IConnectSearchPage) => ({
  ...state.imageSearch,
  hideCountryFilter: !showCountryFilterSelector(state),
  hideInvitedToggle: !isNil(state.external.clientId),
  useImageSearchUpload: state.imageSearch.useImageSearchUpload,
  clientId: state.external.clientId,
  uploadFolder: state.external.uploadFolder,
  selfServeExperiment: hasFeature(state.external.organization, 'self_serve_experiment'),
});

const mapDispatchToProps = {
  onUploadImage: uploadImage,
  onRemoveImage: removeImage,
  onChangeCountries: updateCountries,
  onChangeHideInvited: updateHideInvited,
  onFetchContentLists: fetchContentLists,
  setUploadedImageUrl,
  setUploadedImageError,
  setUploadedImageLoading,
};

export const ConnectedImageSearch = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImageSearch);
