import 'css-chunk:src/components/widgets/SocialProfile/Card/Detail.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_ooan5_157",
  "justify-content-space-between": "_justify-content-space-between_ooan5_161",
  "tabular-nums": "_tabular-nums_ooan5_165",
  "detail": "_detail_ooan5_165",
  "average_engagement": "_average_engagement_ooan5_165",
  "value": "_value_ooan5_165",
  "impression": "_impression_ooan5_165",
  "age_range": "_age_range_ooan5_165",
  "audience_authenticity": "_audience_authenticity_ooan5_165",
  "impression_per_story": "_impression_per_story_ooan5_165",
  "audienceLocationBarChart": "_audienceLocationBarChart_ooan5_165",
  "audienceAgeBarChart": "_audienceAgeBarChart_ooan5_166",
  "title": "_title_ooan5_170",
  "tooltip": "_tooltip_ooan5_302",
  "icon": "_icon_ooan5_313",
  "horizontal": "_horizontal_ooan5_335",
  "gender": "_gender_ooan5_352",
  "dislikes_per_video": "_dislikes_per_video_ooan5_355",
  "high": "_high_ooan5_359",
  "good": "_good_ooan5_363",
  "suspicious": "_suspicious_ooan5_367",
  "low": "_low_ooan5_368",
  "positive": "_positive_ooan5_372",
  "neutral": "_neutral_ooan5_376",
  "negative": "_negative_ooan5_380",
  "reviewedIcon": "_reviewedIcon_ooan5_384",
  "show": "_show_ooan5_1"
};