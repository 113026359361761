import * as React from 'react';

import { IMemberTableRefHandles } from '@frontend/app/components';

const { createContext } = React;

interface IProspectsListContext {
  tableRef: React.RefObject<IMemberTableRefHandles>,
}

export const ProspectsListContext = createContext<IProspectsListContext>(null);

export const ProspectsListContextProvider: React.FC<IProspectsListContext> = (props) => {
  const { children, ...context } = props;

  const value: IProspectsListContext = {
    ...context,
  };

  return (
    <ProspectsListContext.Provider value={value}>
      {children}
    </ProspectsListContext.Provider>
  );
};
