import gql from 'graphql-tag';

const GET_BUDGET_FISCAL_PERIOD_QUERY = gql`
    query GetBudgetFiscalPeriodData ($fiscalYear: String) {
        budgetFiscalPeriod: getBudgetFiscalPeriodData (fiscalYear: $fiscalYear) {
            client {
                id
                name
            }
            description
            fiscalGranularityLabels
            fiscalYear
            fiscalYearLabel
            fiscalYearStartMonth
            id
            isArchived
            isCurrentYear
        }
    }
`;

export default GET_BUDGET_FISCAL_PERIOD_QUERY;
