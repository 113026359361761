import gql from 'graphql-tag';

import { USER_FRAGMENT } from './user';

export const CONTENT_REVIEW_COMMENT_FRAGMENT = gql`
  fragment ContentReviewCommentFragment on ContentReviewComment {
    id
    reviewId
    userId
    user {
      ...UserFragment
    }
    info
    createdDate
  }
  ${USER_FRAGMENT}
`;
