import * as React from 'react';
import { CircleCheckIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { message } from '@revfluence/fresh';
import { TProject } from '@frontend/app/containers/Projects/types';
import Terms from '@frontend/app/containers/Projects/TermsPage/components/Terms';
import TermsTemplate from '@frontend/app/containers/Projects/TermsPage/components/TermsTemplate';
import {
  useGetDefaultProgramTemplate,
  useSetDefaultProgramTemplateMutation,
  useGetTermsTemplatesByProgramId,
  useGetTermsName,
} from '@frontend/app/hooks';
import { GetDefaultProgramTemplateQuery_defaultTemplate as ITermsTemplate } from '@frontend/app/queries/types/GetDefaultProgramTemplateQuery';
import { TermsDrawer } from '@frontend/applications/TermsApp/components/shared/Drawer/TermsDrawer';
import {
  TermsType,
} from '@frontend/app/types/globalTypes';

const {
  useState,
  useCallback,
  useEffect,
  useMemo,
} = React;

interface IBasicAdvancedTermsSettingsPageProps {
  project: TProject;
}

const BasicAdvancedTermsSettingsPage: React.FC<IBasicAdvancedTermsSettingsPageProps> = React.memo(({ project }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<ITermsTemplate | null>(null);
  const [editing, setEditing] = useState(false);
  const { pluralTermsName } = useGetTermsName();
  const title = pluralTermsName;

  const {
    loading: templatesLoading,
    data: {
      templates = null,
    } = {},
  } = useGetTermsTemplatesByProgramId({
    variables: {
      programId: project.id,
    },
    skip: !project.id,
    fetchPolicy: 'no-cache',
  });

  const {
    loading: defaultTemplateLoading,
    data: {
      defaultTemplate = null,
    } = {},
  } = useGetDefaultProgramTemplate({
    variables: {
      programId: project.id,
    },
    skip: !project.id,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (defaultTemplate) {
      setSelectedTemplate(defaultTemplate);
    }
  }, [
    defaultTemplate,
  ]);

  const [setDefaultProgramTemplate, {
    loading: setDefaultProgramTemplateLoading,
  }] = useSetDefaultProgramTemplateMutation({
    onCompleted(result) {
      const { newDefaultProgramTemplate } = result;
      setSelectedTemplate(newDefaultProgramTemplate);
       const text = newDefaultProgramTemplate.termsType === TermsType.BASIC ? `Flexible ${pluralTermsName}` : `Standard ${pluralTermsName}`;
       message.success({
         icon: <CircleCheckIcon />,
         content: `Default set to ${text}`,
       });
    },
    onError(error) {
      message.error({
        icon: <TriangleExclamationIcon />,
        content: error.message,
      });
    },
  });

  const termsType = useMemo(() => {
    if (selectedTemplate) {
      return selectedTemplate.termsType;
    }
    return null;
  }, [
    selectedTemplate,
  ]);

  const selectTemplateHandler = useCallback(
    (termsTemplateId: number) => {
      if (selectedTemplate && termsTemplateId === selectedTemplate.id) {
        return;
      }
      setDefaultProgramTemplate({
        variables: {
          programId: project.id,
          termsTemplateId,
        },
      });
    }, [
      selectedTemplate,
      project.id,
      setDefaultProgramTemplate,
    ],
  );

  const editTemplateHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setEditing(true);
  }, []);

  return (
    <>
      { editing && (
      <TermsDrawer
        onClose={() => setEditing(false)}
        title={
            termsType === TermsType.BASIC
              ? `Edit Flexible ${pluralTermsName} Template`
              : `Edit Standard ${pluralTermsName} Template`
            }
        open
      >
        <TermsTemplate
          isEditing={false}
          memberIds={[]}
          termsType={termsType}
          projectId={project.id}
          onClose={() => setEditing(false)}
          onSwitchTermsType={() => {}}
        />
      </TermsDrawer>
      )}
      <Terms
        title={title}
        selectedTermsType={termsType}
        templates={templates}
        loading={
          defaultTemplateLoading
          || templatesLoading
          || setDefaultProgramTemplateLoading
        }
        onSelectTemplate={selectTemplateHandler}
        onEditTemplate={editTemplateHandler}
      />
    </>
  );
});

BasicAdvancedTermsSettingsPage.displayName = 'BasicAdvancedTermsSettingsPage';

export default BasicAdvancedTermsSettingsPage;
