import * as React from 'react';
import {
  map, filter, isFunction,
} from 'lodash';
import { Space, Typography } from '@revfluence/fresh';

import { MemberFieldSchemasBySectionsQuery_sections_memberData } from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { FieldRow } from '../FieldRow';

import styles from './ApplicationData.scss';

const { useMemo, useEffect } = React;
const { Text } = Typography;

type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_memberData;

interface Props {
  member: MemberInput;
  schemas: MemberFieldSchema[];
  collapsable?: boolean;
  placeholder?: string;
  onSave: (val: MemberInput) => Promise<void>;
  viewMore?: boolean;
  onViewMoreVisibilityChange?: (visible: boolean) => void;
}

const ApplicationData: React.FC<Props> = ({
  member,
  schemas = [],
  collapsable,
  placeholder,
  onSave,
  viewMore,
  onViewMoreVisibilityChange,
}) => {
  const fieldsWithValue = useMemo(
    () => filter(schemas, (field) => member && member.fields[field.id]) as MemberFieldSchema[],
    [schemas, member],
  );

  const visibleFields = useMemo(() => {
    if (!collapsable || !member || viewMore) {
      return schemas;
    }

    return fieldsWithValue.length === 0 ? schemas : fieldsWithValue;
  }, [fieldsWithValue, schemas, member, viewMore, collapsable]);

  useEffect(() => {
    if (isFunction(onViewMoreVisibilityChange)) {
      onViewMoreVisibilityChange(
        !!fieldsWithValue?.length && fieldsWithValue.length < schemas.length,
      );
    }
  }, [fieldsWithValue, schemas, onViewMoreVisibilityChange]);

  if (!member) {
    return null;
  }

  return (
    <Space direction="vertical">
      {map(visibleFields, (field) => (
        <FieldRow
          key={field.id}
          direction="vertical"
          label={field.name}
          type={field.type}
          value={member.fields[field.id]}
          editable={field.metaData?.editable}
          copyable={field.metaData?.copyable}
          onSave={(val) => onSave({ fields: { [field.id]: val } })}
        />
      ))}
      {!visibleFields?.length && placeholder && (
        <div className={styles.noFields}>
          <Text type="secondary">{placeholder}</Text>
        </div>
      )}
    </Space>
  );
};

export default ApplicationData;
