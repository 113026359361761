import * as React from 'react';
import cx from 'classnames';

import { useParams } from 'react-router-dom';

import { ApplicationContainer } from './ApplicationContainer';

import styles from './ApplicationPage.scss';

interface IProps {
  className?: string;
}
interface IMatchParams {
  applicationId: string;
}

/**
 * @type {React.FunctionComponent}
 */
const ApplicationPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const { applicationId } = useParams<IMatchParams>();

  return (
    <div className={cx(styles.ApplicationPage, props.className)}>
      <ApplicationContainer
        applicationId={applicationId}
      />
    </div>
  );
});

ApplicationPage.displayName = 'ApplicationPage';

export default ApplicationPage;
