/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13jaq_157 {
  display: flex;
}

._justify-content-space-between_13jaq_161 {
  justify-content: space-between;
}

._tabular-nums_13jaq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13jaq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BackLink_13jaq_178 {
  margin-right: 1rem;
}
._BackLink_13jaq_178 ._icon_13jaq_181 {
  margin-right: 1rem;
  font-size: 1.5rem;
  color: #1a1818;
}
._BackLink_13jaq_178 span {
  margin-left: 8px;
}

._backLinkSection_13jaq_190 {
  margin-bottom: 1rem;
}