import React from 'react';
import { Alert } from '@revfluence/fresh';
import { CircleCheckIcon } from '@revfluence/fresh-icons/regular/esm';
import { TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import styles from './PaymentSuccess.scss';
import { StepHeader } from '../common/StepHeader';
import PaymentSuccessDetail from '../common/PaymentSuccessDetail';
import { BudgetTrackerSuccess } from '../common/BudgetTrackerSuccess';

interface IProps {
  state: TState;
}

export const PaymentSuccess: React.FC<IProps> = (props) => {
  const { state } = props;

  const {
    workflowActionParameters,
  } = useApplication();

  const currentProgram = state.programs.find((program) => program.id === workflowActionParameters?.programId);
  const staPaymentsMade = state.staPayments.filter((payment) => payment.isSelected).length;
  return (
    <div className={styles.paymentSuccessContainer}>
      {
        state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING ? null : (
          <StepHeader
            name={state.memberInfo.name}
            profileImage={state.memberInfo.profileImage}
            programImage={currentProgram?.splashImageUrl}
            programName={currentProgram?.title}
          />
        )
      }
      <Alert
        icon={<CircleCheckIcon scale={1.5} color="green" />}
        message={`Successfully sent payment to ${state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING ? `${staPaymentsMade} members.` : state.memberInfo.name}`}
        description="Payments are typically processed within 1 - 3 business days."
        type="success"
      />
      <div className={styles.main}>
        <div className={styles.left}>
          <BudgetTrackerSuccess state={state} />
        </div>
        <div className={styles.right}>
          <PaymentSuccessDetail
            state={state}
          />
        </div>
      </div>
    </div>
  );
};
