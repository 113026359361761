import * as React from 'react';

import { IMember, IMessageVariable, useGetVariables } from '@frontend/app/hooks';
import { MessageBackupPlan } from '@frontend/app/types/globalTypes';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';

interface IInvalidMemberIdsForField {
  [field: string]: number[];
}

interface IEmailComposerContext {
  disableRemoveRecipients: boolean;

  variables: IMessageVariable;
  previewMember: IMember;
  setPreviewMember(member: IMember): void;
  backupPlans: MessageBackupPlan[];
  setBackupPlans(backupPlans: MessageBackupPlan[]): void;
  invalidMemberIdsForField: IInvalidMemberIdsForField;
  setInvalidMemberIdsForField(data: IInvalidMemberIdsForField): void;
}

const { useState, useContext } = React;

export interface IEmailEditorProviderProps {
  disableRemoveRecipients?: boolean;
}

const EmailEditorContext = React.createContext<IEmailComposerContext>(null);
export const useEmailEditorContext = () => useContext(EmailEditorContext);
export const EmailEditorProvider: React.FC<IEmailEditorProviderProps> = ({
  disableRemoveRecipients, children,
}) => {
  // TODO - the PFA using proxy so need to pass via context
  const pfaContextVariables = useProductFulfillmentContext()?.variables;
  const pfaVariables = useProductFulfillmentContext()?.usePfaContextVariables && pfaContextVariables;
  const { variables: queryVariables } = useGetVariables({ skip: !!pfaVariables });
  const variables = pfaVariables || queryVariables;

  const [previewMember, setPreviewMember] = useState(null);
  const [backupPlans, setBackupPlans] = useState<MessageBackupPlan[]>([]);
  const [invalidMemberIdsForField, setInvalidMemberIdsForField] = useState<IInvalidMemberIdsForField>({});

  return (
    <EmailEditorContext.Provider
      value={{
        disableRemoveRecipients,

        variables,
        previewMember,
        setPreviewMember,
        backupPlans,
        setBackupPlans,
        invalidMemberIdsForField,
        setInvalidMemberIdsForField,
      }}
    >
      {children}
    </EmailEditorContext.Provider>
  );
};
