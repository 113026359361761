import { useQuery, QueryHookOptions } from '@apollo/client';

import { generateMemberSearchGraphQLQuery, SelectedField } from '@frontend/app/queries';
import {
  MemberSearchQuery,
  MemberSearchQueryVariables,
} from '@frontend/app/queries/types/MemberSearchQuery';

type IOptions = QueryHookOptions<MemberSearchQuery, MemberSearchQueryVariables>;

export const useMemberSearchQuery = (options: IOptions = {}, selectedFields: Set<SelectedField> = new Set([])) => useQuery<MemberSearchQuery, MemberSearchQueryVariables>(generateMemberSearchGraphQLQuery(selectedFields), options);
