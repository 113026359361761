import { useMemo } from 'react';
import { useInstalledApplicationInstances } from '@frontend/app/hooks';

interface IOptions {
  skip?: boolean;
}

export interface IInstalledApplications {
  [appId: string]: boolean;
}

export const useGetInstalledApplicationIds = (options: IOptions = {}): IInstalledApplications => {
  const {
    skip,
  } = options;

  const {
    data: {
      instances = null,
    } = {},
  } = useInstalledApplicationInstances({
    skip,
  });

  const installedApps = useMemo(() => {
    if (!instances) {
      return {};
    }
    return instances.reduce(
      (acc, instance) => ({ ...acc, ...{ [instance.applicationId]: true } }),
      {},
    );
  }, [instances]);

  return installedApps || {};
};
