import * as winston from 'winston';
import { format } from 'winston';
import { inspect } from 'util';

import { formatErrors } from './formatErrors';
import { Environment } from './Environment';

const transports = {
  // pretty-printed console formatting for local and frontend dev
  console: new winston.transports.Console({
    silent: Environment.isTest(),
    consoleWarnLevels: ['warn', 'error'],
    format: format.combine(
      formatErrors({ stack: true }),
      format.colorize(),
      format.timestamp(),
      format.printf((info) => {
        let { level, message, timestamp, stack, ...metaOriginal } = info;
        let line = `[${timestamp}] ${level}: `;

        if (typeof message === 'object') {
          line += `\n${JSON.stringify(message, null, 2)}`;
        } else {
          line += `${message}`;
        }

        if (stack) {
          line += `\n${stack}`;
        }

        // pretty print additional metadata
        if (metaOriginal && Object.keys(metaOriginal).length > 0) {
          try {
            line += `\n${JSON.stringify(metaOriginal, null, 2)}`;
          } catch (e) {
            const meta = inspect(metaOriginal);
            line += `\n${meta}`;
          }
        }

        return line;
      })
    ),
    handleExceptions: true, // Log unhandled exceptions through Winston
  }),

  // use json formatting for stackdriver logs for better indexing/searching
  stackdriver: new winston.transports.Console({
    // log warning and error messages to stderr to get picked up as errors
    consoleWarnLevels: ['warn', 'error'],
    format: format.combine(formatErrors({ stack: true }), format.json()),
    handleExceptions: true, // Log unhandled exceptions through Winston
  }),
};

export const primaryLogTransport =
  process.env.LOG_FORMAT === 'stackdriver'
    ? transports.stackdriver
    : transports.console;

export const logger = winston.createLogger({
  exitOnError: false, // We handle this in our uncaughtException/rejection event handlers.
  level: process.env.LOG_LEVEL || 'debug',
  transports: [primaryLogTransport],
});

export const injectContext = function (contextManager: any) {
  transports.stackdriver.format = format.combine(
    format((info) => ({ ...info, ...contextManager.serialize() }))(),
    transports.stackdriver.format!
  );
};

export const injectTracing = function (tracer: any) {
  transports.stackdriver.format = format.combine(
    format((info) => {
      const span = tracer.scope().active();

      if (span) {
        const context = span.context();
        info.dd = {
          span_id: context.toSpanId(),
          trace_id: context.toTraceId(),
        };
      }

      return info;
    })(),
    // original format includes the json formatter which must come last
    transports.stackdriver.format!
  );
};

logger.on('warn', (err) => {
  console.debug('Winston logger emitted warning:', err);
});

logger.on('error', (err) => {
  // Do not silently drop errors. Try to log using basic
  // console output. It may fail, but we should at least try.
  console.error('Winston logger encountered a fatal exception:', err);
});

process.on('uncaughtException', (err) => {
  logger.error('Unhandled exception:', err);
  process.exit(1);
});

process.on('unhandledRejection', (reason, p) => {
  logger.error('Unhandled rejection:', reason, p);
  process.exit(1);
});
