/**
 * Emoji Dictionary
 * Add as you need 'em!
 *
 * NOTE: Please sort alphabetically for easier lookup
 *
 * https://unicode.org/emoji/charts/full-emoji-list.html
 */
export const emojiDictionary: { [id: string]: string } = {
  check: '\u{2705}',
  heads: '\u{1F465}',
  pin: '\u{1F4CD}',
  star: '\u{2B50}',
};
