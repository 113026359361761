import { ObservableQuery } from '@apollo/client';
import {
  GetApplicationsByIds_applications as IApplicationRaw,
} from '@frontend/app/queries/types/GetApplicationsByIds';
import { IColumn } from '@frontend/app/types/Columns';

export enum FieldSource {
  All = 'All Data',
  Default = 'Default Member Data',
  Custom = 'Custom Member Data',
  Relationship = 'Relationship Status',
  Project = 'Project Data',
}

export type IApplication = Pick<IApplicationRaw, 'id' | 'name'>;

export interface IField extends IColumn {
  source?: FieldSource | IApplication;
  width?: number;
  maxWidth?: number;
  minWidth?: number;
  isDefault?: boolean;
  fixed?: string;
  dataIndex?: string;
  itemProp?: string;
  readonly?: boolean;
}

export interface IFieldOption {
  label: string;
  value: IField;
}

export interface IMemberFieldsWithSources {
  appsWithFields: IApplication[];
  fields: IField[];
  isLoading?: boolean;
  refetch?: ObservableQuery['refetch'];
}
