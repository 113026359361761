import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { UPDATE_SPECIFICATION } from '../queries';
import {
  UpdateSpecificationMutation,
  UpdateSpecificationMutationVariables,
} from '../queries/types/UpdateSpecificationMutation';

type IOptions = MutationHookOptions<
  UpdateSpecificationMutation,
  UpdateSpecificationMutationVariables
>;

export const useUpdateSpecificationMutation = (options: IOptions = {}) => (
  useMutation<UpdateSpecificationMutation, UpdateSpecificationMutationVariables>(
    UPDATE_SPECIFICATION,
    options,
  )
);
