import { CompositeDecorator, DraftDecorator } from 'draft-js';
import { map } from 'lodash';

import { Link } from './components';
import { findLinkStrategy } from './strategies';

interface IParams {
  decorators?: DraftDecorator[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
}
export const getDecorator = (params: IParams) => {
  const { decorators = [], props } = params;

  return new CompositeDecorator([
    ...map(decorators, (d) => ({
      ...d,
      props,
    })),
    {
      strategy: findLinkStrategy,
      component: Link,
      props,
    },
  ]);
};
