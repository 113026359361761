import gql from 'graphql-tag';

export const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    name
    active
    forceActive
    hostname
    domains
    memberDomainsRestricted
    createdDate
    featureIds
    objective
    brandCategory
  }
`;
