/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { map, orderBy } from 'lodash';

import { LoadSpinner } from '@components';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { TermsType } from '@frontend/app/types/globalTypes';
import { Empty, message, Typography } from '@revfluence/fresh';
import { BriefsStatistics, IBriefsStatistics } from '@frontend/applications/TermsApp/components/BriefsStatistics';
import { useGetMemberQuery, useProgramsQuery } from '@frontend/app/hooks';
import { FileLinesIcon } from '@revfluence/fresh-icons/regular/esm';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';
import { useGetData } from '../hooks/useGetData';
import { IAgreement } from '../types/IAgreement';
import { BriefsAgreement } from '../components/BriefsAgreement';

import styles from './TermsHistory.scss';
import { IBulkTermsFormConfig } from '../types/Terms';

const { useEffect } = React;

interface ITermsHistoryProps {
  onViewIteration: (iterationId: number) => void;
  setTermsInfo: (data: IBulkTermsFormConfig) => void;
}

export const TermsHistory: React.FC<ITermsHistoryProps> = ({ onViewIteration, setTermsInfo }) => {
  const { backendServerApiEndpoint, memberId } = useApplication();
  const { clientId, clientName } = useApplication();
  const url = `${backendServerApiEndpoint}/agreements?member_id=${memberId}`;
  // TODO: Update to use GRAPHQL
  const { data, loading, error } = useGetData<IAgreement[]>(url);
  const { data: memberData } = useGetMemberQuery(Number(memberId));
  const { data: programsData, loading: programsLoading } = useProgramsQuery();
  const viewBasicTerms = (backendServerPostProjectId: string, programId: number, backendServerAgreementId: string) => {
    setTermsInfo(
      {
        memberIds: [parseInt(memberId, 10)],
        programId,
        clientName,
        clientId,
        workItemsByMemberId: {},
        termsType: TermsType.BASIC,
        backendServerPostProjectId,
        isViewTerms: true,
        backendServerAgreementId,
      },
    );
    onViewIteration(null);
  };
  useEffect(() => {
    if (error) {
      message.error({
        content: "We're very sorry, there was a problem fetching this member's terms history. "
          + 'If this issue persists, please reach out to us at help@aspireiq.com',
        icon: <TriangleExclamationIcon />,
      });
    }
  }, [error]);

  const sortedAgreements = orderBy(data, ['created_at'], ['desc']);
  const briefStatistics = sortedAgreements.reduce((acc, agreement) => {
    if (!acc[agreement.status]) {
      acc[agreement.status] = 0;
    }
    acc[agreement.status]++;
    return acc;
  }, {} as IBriefsStatistics);

  if (loading || programsLoading) {
    return (<LoadSpinner className={styles.LoadingSpinner} centered />);
  }
  if (!data || data?.length === 0) {
    return (
      <div className={styles.Empty}>
        <Empty
          image={<FileLinesIcon />}
          description={(
            <>
              <Typography.Title level={5}>
                {memberData?.member.name || 'Member'}
                {' '}
                has not been sent any briefs.
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Send the member a brief from a project to start a new collaboration.
              </Typography.Paragraph>
            </>
          )}
        />
      </div>
    );
  }
  return (
    <div className={styles.TermsAppHistory}>
      <div className={styles.TermsAppHistory__content}>
        <BriefsStatistics statistics={briefStatistics} />
        {map(sortedAgreements, (agreement) => (
          <BriefsAgreement
            key={agreement.id}
            agreement={agreement}
            program={programsData?.programs.find((program) => program.id === agreement.program_id)}
            onViewIteration={onViewIteration}
            onViewBasicTerms={viewBasicTerms}
          />
        ))}

      </div>
    </div>
  );
};
