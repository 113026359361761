import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';
import { format } from 'date-fns';
import {
 chain, isNumber, map, reduce, size, last, takeRightWhile,
} from 'lodash';

import { AreaChart } from '@components';

import { IFollowerHistory } from '@components';
import { Card } from './Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

const { useCallback, useMemo } = React;
import styles from './GrowthCard.scss';
import { calculateMoM, monthDiff } from '../utils';

type TGrowthDataList = IFollowerHistory[];
type TGrowthData = IFollowerHistory;

const DATA_LIMIT = 8; // How far back do we show data in months

interface IProps {
  className?: string;
}

export const GrowthCard: React.FC<IProps> = (props) => {
  const { className } = props;
  const { socialAccount } = useSocialProfileContext();

  const trimData = useCallback(
    (data: TGrowthDataList) => {
      if (size(data) > 1) {
        // Remove duplicate months
        const history = reduce(
          data,
          (result, entry) => {
            const date = format(new Date(entry.created_ts * 1000), 'MMM yyyy');
            result[date] = {
              created_ts: new Date(date).getTime() / 1000, // floor each timestamp to the start of the month.
              count: Math.max(result[date]?.count || 0, entry.count),
            };
            return result;
          },
          {} as { [date: string]: TGrowthData },
        );

        if (size(history) > 1) {
          const sortedHistory = chain(history)
            .values()
            .sortBy((fh) => fh.created_ts)
            .value();

          const lastMonth = new Date(last(sortedHistory).created_ts * 1000);
          // Only show last 8 months of data.
          return takeRightWhile(sortedHistory, ({ created_ts }) => monthDiff(new Date(created_ts * 1000), lastMonth) < DATA_LIMIT);
        }
      }
      return [];
    },
    [],
  );

  const followerData = useMemo(
    () => trimData(socialAccount.follower_history),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socialAccount],
  );
  const hasFollowerData = followerData.length > 0;
  const followerGrowth = hasFollowerData
    ? calculateMoM(followerData)
    : null;

  const renderFollowersChart = useCallback(
    () => hasFollowerData && <FollowersLikesChart data={followerData} />,
    [hasFollowerData, followerData],
  );

  const renderGrowthHeader = useCallback(
    (growth: number) => isNumber(growth) && (
      <div
        className={cx(styles.growthValue, {
          [styles.positive]: growth > 0,
          [styles.negative]: growth < 0,
        })}
      >
        MoM
        {' '}
        {numeral(growth).format('0.00%')}
      </div>
    ),
    [],
  );

  return (
    <Card
      className={cx(className, styles.GrowthCard, {
        [styles.noData]: !hasFollowerData,
      })}
      header={(
        <div className={styles.title}>
          Follower Growth
          {hasFollowerData && renderGrowthHeader(followerGrowth)}
        </div>
      )}
    >
      <div className={cx(styles.growthChart, {
        [styles.noData]: !hasFollowerData,
      })}
      >
        {renderFollowersChart()}
      </div>
    </Card>
  );
};

GrowthCard.displayName = 'GrowthCard';

/**
 * Followers and Likes share the same chart config
 */
const FollowersLikesChart = React.memo(({ data }: { data: TGrowthDataList }) => {
  const chartData = useMemo(() => map(data, ({ created_ts, count }) => ({
      x: new Date(created_ts * 1000),
      y: count,
    })), [data]);

  const xTickValues = useMemo(() => map(chartData, ({ x }) => x), [chartData]);

  return (
    <AreaChart
      className={styles.areaChart}
      data={chartData}
      margin={{
        top: 8,
        left: 42,
        right: 120, // huge margin offset by CSS so tooltips don't get squished
        bottom: 24,
      }}
      colorGradient={{
        from: 'rgba(57, 150, 224, 0.6)',
        to: 'rgba(255, 233, 217, 0.5)',
      }}
      showCircle
      lineType="area"
      xAxisDateFormat="MMM yyyy"
      xTicks={data.length}
      xTickValues={xTickValues}
      yTicks={4}
      tooltipTopOffset={-48}
      tooltipTextLayout="xy"
    />
  );
});

FollowersLikesChart.displayName = 'FollowersLikesChart';
