import * as React from 'react';
import { List } from '@revfluence/fresh';
import {
  find, flatMap, map, orderBy, uniq, first, get, isEmpty,
} from 'lodash';

import {
  GetThreadQuery_thread as IThread,
  GetThreadQuery_thread_messages as IThreadMessages,
  GetThreadQuery_thread_members as IThreadMembers,
} from '@frontend/app/queries/types/GetThreadQuery';
import { useResourceContext } from '@frontend/app/context';
import { ResourceType } from '@frontend/app/types/globalTypes';
import { GetResourcesQuery_resources as IResources } from '@frontend/app/queries/types/GetResourcesQuery';
import { useSearchMemberByInstagram } from '@frontend/app/components/MemberSearch/useSearchMemberByInstagram';
import { useMemberFieldPartitions } from '@frontend/app/containers/MemberDetail/MemberInfo/MemberOverview/hooks';
import { InstagramMessageItem } from './InstagramMessageItem/InstagramMessageItem';
import styles from './InstagramMessageList.scss';
import { ThreadHeader } from '../ThreadHeader';
import { getThreadTitle } from '../../utils';
import { InstagramReplyItem } from '../model';
import { InstagramReplyComposer } from './InstagramReplyComposer/InstagramReplyComposer';

interface IProps {
  thread: IThread;
  refetch(): void;
  onOpenOverview: () => void;
  setSelectedMemberId: (memberId: number) => void;
}

const {
  useMemo, useState, useEffect, useRef,
} = React;

export const InstagramMessageList: React.FC<IProps> = (props) => {
  const {
    thread,
    refetch,
    onOpenOverview,
    setSelectedMemberId,
  } = props;

  const [threadMessages, setThreadMessages] = useState(thread?.messages);

  const bottomRef = useRef<HTMLDivElement>(null);
  const ref = useRef(null);

  useEffect(() => {
    if (bottomRef && bottomRef.current) {
      setTimeout(() => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 150);
    }
  }, [bottomRef]);

  const sortedMessages = useMemo(() => orderBy(
    threadMessages,
    (message) => [message.internalDate],
    ['asc'],
  ), [threadMessages]);

  const {
    sortedSocialSchemasByName,
  } = useMemberFieldPartitions({});

  const { messageResources: handlerResources } = useResourceContext();

  const audienceHandler = getThreadTitle(thread, sortedSocialSchemasByName, handlerResources).substring(1);
  const [messageToBeReplied, setMessageToBeReplied] = useState<InstagramReplyItem>(undefined);
  const [composerErrorMessage, setComposerErrorMessage] = useState<string>('');

  const resourceInfo: IResources = useMemo(() => {
    const igAccounts = uniq(flatMap(map(thread && thread.messages, (message) => [
      message.payload.from,
      ...message.payload.to,
    ])));

    return find(handlerResources, (resource) => resource.type === ResourceType.IGDM && igAccounts.includes(resource.authProvider.userExternalDisplayId));
  }, [thread, handlerResources]);

  const senderHandler: string = resourceInfo?.authProvider.userExternalDisplayId;
  const threadUsers = useMemo(() => {
    const igAccounts = uniq(flatMap(map(thread && thread.messages, (message) => [
      message.payload.from,
      ...message.payload.to,
    ])));
    return igAccounts.filter((account) => account !== senderHandler);
  }, [senderHandler, thread]);

  const {
    data,
    instagramFieldId,
    loading: loadingMembers,
  } = useSearchMemberByInstagram(threadUsers);

  const onMessageSent = (threadMessage: IThreadMessages) => {
    setThreadMessages([...threadMessages, threadMessage]);
  };

  const isInvalidIGDMThread = useMemo(() => {
    if (loadingMembers) return false;
    // Check if they are not associated with a direct message
    if (resourceInfo && (resourceInfo.authProvider.systemRevokedAccess || resourceInfo.authProvider.userRevokedAccess)) {
      setComposerErrorMessage(`This conversation cannot be synced because the Instagram account @${resourceInfo.authProvider.userExternalDisplayId} is not selected for direct messaging`);
      return true;
    }

    /**
     * Checking if the thread has members but the Instagram field is null
     * the member exists but instagram handler is no longer attached
     */
    const { members } = thread;
    const threadMember:IThreadMembers = first(members);
    const instagramHandle = get(threadMember?.fields, instagramFieldId, null);
    if (isEmpty(instagramHandle) && !isEmpty(members)) {
      setComposerErrorMessage('Member is missing Instagram Handle');
      return true;
    }

    // Checking if the thread members were deleted.
    if (isEmpty(members)) {
      setComposerErrorMessage('Member deleted');
      return true;
    }

    // Checking if there are multiple members using the same Instagram Account.
    let isInvalid = false;
    threadUsers.forEach((account) => {
      const duplicateMemberCount = data.members.filter((member) => `${member.fields[instagramFieldId]}` === account).length;
      if (duplicateMemberCount > 1) {
        setComposerErrorMessage('Multiple members are using the same Instagram account');
        isInvalid = true;
        return true;
      }
    });

    return isInvalid;
  }, [
    data,
    threadUsers,
    resourceInfo,
    loadingMembers,
    thread,
    instagramFieldId,
  ]);

  return (
    <div className={styles.IGMessageList}>
      <ThreadHeader
        onThreadStatusChange={refetch}
        thread={thread}
        className={styles.threadHeader}
        onOpenOverview={onOpenOverview}
        setSelectedMemberId={setSelectedMemberId}
      />
      <List
        ref={ref}
        itemLayout="vertical"
        className={styles.list}
        dataSource={sortedMessages}
        split={false}
        renderItem={(item) => (
          <InstagramMessageItem
            key={item.id}
            threadMessage={item}
            audienceHandler={audienceHandler}
            onReplyHandleClick={setMessageToBeReplied}
          />
        )}
        footer={(
          <InstagramReplyComposer
            thread={thread}
            bottomRef={bottomRef}
            recepient={audienceHandler}
            sender={senderHandler}
            resourceId={resourceInfo?.id}
            onMessageSent={onMessageSent}
            instagramReplyItem={messageToBeReplied}
            clearReplyItem={() => setMessageToBeReplied(undefined)}
            composerErrorMessage={composerErrorMessage}
            isInvalidIGDMThread={isInvalidIGDMThread}
          />
        )}
      />
    </div>
  );
};
