import * as React from 'react';
import {
  TNetworkIdentifier,
} from '@components';
import {
  Row,
  Col,
  Avatar,
  Card as FreshCard,
  Button,
  Tooltip,
} from '@revfluence/fresh';
import numeral from 'numeral';
import { CopyIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './TikTokSparkAdsRecommendationMeta.module.scss';

export enum RecommendationLevel {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export interface ITikTokSparkAdsRecommendation {
  id: number;
  network: TNetworkIdentifier;
  mediaId: string | null;
  socialAccountName: string;
  datetimePosted: string;
  imageUrl: string;
  link: string;
  engagementRate: number;
  profileImageUrl: string;
  recommendationLevel: RecommendationLevel;
  hasSparkAdPermission: boolean;
}

interface IProps {
  tiktokSparkAdsRecommendation: ITikTokSparkAdsRecommendation;
}

const renderRecommendationLevel = (recommendationLevel: RecommendationLevel) => {
  switch (recommendationLevel) {
    case RecommendationLevel.HIGH:
      return (
        <span className={styles.high}>High</span>
      );
    case RecommendationLevel.MEDIUM:
      return (
        <span className={styles.medium}>Medium</span>
      );
    case RecommendationLevel.LOW:
      return (
        <span className={styles.low}>Low</span>
      );
    default:
      return null;
  }
};

const renderSparkAdPermission = (hasSparkAdPermission: boolean) => {
  if (hasSparkAdPermission) {
    return (
      <span className={styles.yes}>Yes</span>
    );
  }
  return (
    <span className={styles.no}>No</span>
  );
};

const TIkTokSparkAdsRecommendationMeta: React.FC<IProps> = React.memo((props) => {
  const { tiktokSparkAdsRecommendation } = props;
  return (
    <FreshCard.Meta
      avatar={<Avatar shape="square" size={40} src={tiktokSparkAdsRecommendation.profileImageUrl} />}
      className={styles.TikTokSparkAdsRecommendationMeta}
      description={(
        <>
          <Row justify="space-between">
            <Col />
            <Col>
              { tiktokSparkAdsRecommendation.socialAccountName }
            </Col>
          </Row>
          <Row justify="space-between">
            <Col>Recommendation</Col>
            <Col>{renderRecommendationLevel(tiktokSparkAdsRecommendation.recommendationLevel)}</Col>
          </Row>
          <Row justify="space-between">
            <Col>Spark Ad Permission</Col>
            <Col>{renderSparkAdPermission(tiktokSparkAdsRecommendation.hasSparkAdPermission)}</Col>
          </Row>
          <Row justify="space-between">
            <Col>Engagement Rate</Col>
            <Col>{numeral(tiktokSparkAdsRecommendation.engagementRate).format('0.0%')}</Col>
          </Row>
          <Row justify="space-between">
            <Col>Post Id</Col>
            <Col>
              <Tooltip title="Copy">
                <Button
                  icon={<CopyIcon />}
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(tiktokSparkAdsRecommendation.mediaId);
                  }}
                  disabled={!tiktokSparkAdsRecommendation.mediaId}
                />
              </Tooltip>
            </Col>
          </Row>
        </>
      )}
    />
  );
});

export default TIkTokSparkAdsRecommendationMeta;
TIkTokSparkAdsRecommendationMeta.displayName = 'TIkTokSparkAdsRecommendationMeta';
