import React from 'react';
import { DashboardLayout, SectionHeader } from '@frontend/app/refresh-components';
import { LoadSpinner } from '@components';
import widgetMapper from '@frontend/app/utils/widgetMapper';
import { Card } from '@frontend/shadcn/components/ui/card';
import { Button } from '@frontend/shadcn/components/ui/button';
import { BankOutlined } from '@ant-design/icons';
import { Avatar, AvatarFallback, AvatarImage } from '@frontend/shadcn/components/ui/avatar';
import { BuildingColumnsIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetProjectBudgetDashboardData } from '@frontend/app/hooks/budgetAllocation/useGetProjectBudgetDashboardData';
import { useHistory } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipTrigger } from '@frontend/shadcn/components/ui/tooltip';
import { ProjectFilterOption } from './BudgetDashboard';

const { useMemo } = React;
interface IProjectTabProps {
  projects: ProjectFilterOption[];
  projectId?: number;
  fiscalYear?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  dashboardConfg?: any;
}

export const ProjectTab: React.FC<IProjectTabProps> = (props) => {
  const config = props?.dashboardConfg?.find((config) => config.name === 'project_budget_dashboard') || null;
  const { projectId, fiscalYear, projects } = props;
  const history = useHistory();
  const { projectBudgetDashboardData, loading: isProjectBudgetLoading } = useGetProjectBudgetDashboardData({
    variables: {
      fiscalYear,
      programId: projectId,
    },
    skip: !projectId || !fiscalYear,
  });

  const selectedProject = useMemo(() => projects.find((project) => project.id === projectId), [projectId, projects]);
  const isProjectWithoutBudget = useMemo(
    () => (!(!isProjectBudgetLoading && projectBudgetDashboardData?.budgetAccounts?.length)),
    [isProjectBudgetLoading, projectBudgetDashboardData],
  );

  if (!config || isProjectBudgetLoading) {
    return (
      <div className="p-8 bg-secondary h-full flex items-center justify-center">
        <LoadSpinner />
      </div>
    );
  }
  return (
    <div className="p-8 bg-secondary relative h-full">
      {config?.sections?.map((section) => (
        <section key={section.sectionId} className="mb-4">
          <SectionHeader title={section.sectionName} description={section.description} className="pb-4" />
          <DashboardLayout
            components={section?.widgets.map((widget) => ({
              content: widgetMapper({ type: widget.type, props: { projectId, fiscalYear } }),
              columns: widget.width,
            }))}
            gap="4"
          />
        </section>
      ))}
      {isProjectWithoutBudget && (
        <div className="absolute top-0 left-0 w-full h-full bg-gray-300 bg-opacity-50 backdrop-blur-md flex justify-center">
          <Card className="md:w-[657px] mt-16 w-full mx-4 p-6 h-max flex flex-col gap-5 items-center">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-center items-center gap-2">
                <Avatar className="h-[32px] w-[32px] rounded-none">
                  {selectedProject?.programImage ? (
                    <AvatarImage
                      src={selectedProject.programImage}
                      className="flex h-full w-full items-center justify-center rounded-lg"
                    />
                  ) : (
                    <AvatarFallback className="flex h-full w-full items-center justify-center rounded-lg bg-primary text-base text-secondary">
                      <BankOutlined />
                    </AvatarFallback>
                  )}
                </Avatar>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <h1 className="font-semibold text-2xl m-0">
                      {selectedProject?.name?.length > 30
                        ? `${selectedProject.name.slice(0, 30)}...`
                        : selectedProject?.name}
                    </h1>
                  </TooltipTrigger>
                  <TooltipContent>{selectedProject?.name}</TooltipContent>
                </Tooltip>
              </div>
              <p className="text-gray-600 mb-0 text-center">
                This program does not have budget setup up. To start tracking budgets go to your Project Settings and
                connect budget accounts.
              </p>
            </div>
            <Button
              className="text-white bg-blue-500 rounded-lg flex gap-1 hover:bg-blue-600 w-max"
              onClick={() => history.push(`/projects/${selectedProject.id}/settings/budget`)}
            >
              <BuildingColumnsIcon />
              Connect Budget Accounts
            </Button>
          </Card>
        </div>
      )}
    </div>
  );
};
