/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_hqwlj_157 {
  display: flex;
}

._justify-content-space-between_hqwlj_161 {
  justify-content: space-between;
}

._tabular-nums_hqwlj_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_hqwlj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Persona_hqwlj_178 {
  display: flex;
  flex-direction: column;
  margin: 5rem auto 0;
  max-width: 93.75rem;
}
@media only screen and (min-width: 20rem) {
  ._Persona_hqwlj_178 ._coverImage_hqwlj_185 {
    width: 100%;
    margin-top: 1.5rem;
    background-position: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  ._Persona_hqwlj_178 ._coverImage_hqwlj_185:after {
    content: "";
    display: block;
    padding-top: 125%;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 {
    padding: 0 1rem;
    color: #fdfdfd;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._title_hqwlj_202 {
    margin-bottom: 0;
    color: #fdfdfd;
    font-weight: 900;
    font-style: normal;
    font-size: 3rem;
    line-height: 3.25rem;
    display: flex;
    flex-direction: column;
    word-break: break-word;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 ._item_hqwlj_218 {
    display: flex;
    flex-direction: row;
    margin: 2rem 0 0;
    align-items: center;
    padding-right: 0.875rem;
    word-break: break-word;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 ._item_hqwlj_218 ._itemBullet_hqwlj_226 {
    width: 0.125rem;
    height: 100%;
    margin-right: 1.25rem;
    position: relative;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 ._item_hqwlj_218 ._itemBullet_hqwlj_226 ._itemBulletSquare_hqwlj_232 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FDFDFD;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 ._item_hqwlj_218 ._itemBullet_hqwlj_226._hide_hqwlj_240 {
    display: none;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 ._item_hqwlj_218 ._itemBullet_hqwlj_226._bullets_hqwlj_243 {
    width: 1rem;
    height: 1rem;
    border-radius: 0.125rem;
    opacity: 0.8;
    overflow: hidden;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 ._item_hqwlj_218 ._itemValue_hqwlj_250 {
    font-size: 1.375rem;
    line-height: 1.875rem;
    padding: 0.25rem 0;
    flex: 1;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._list_hqwlj_213 ._item_hqwlj_218 ._itemValue_hqwlj_250 *:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (orientation: landscape) {
  ._Persona_hqwlj_178 {
    flex-direction: row-reverse;
    justify-content: center;
  }
  ._Persona_hqwlj_178 ._coverImage_hqwlj_185 {
    position: relative;
    width: 35%;
    margin-top: 0;
    margin-bottom: 0;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 {
    display: flex;
    flex-direction: column;
    padding: 0 4.5rem;
    justify-content: center;
    flex: 1;
    overflow: hidden;
  }
  ._Persona_hqwlj_178 ._featuresContainer_hqwlj_198 ._title_hqwlj_202 {
    width: 21.9375rem;
  }
}