import { IBrand } from './brand';
import { IManager } from './manager';

export type TCurrencyCode = 'USD' | 'EUR';
export interface ICampaign {
  account_representative: IAccountRepresentative;
  campaign_goal: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invitations_closed?: any;
  default_additional_images: number;
  submitted: boolean;
  deactivated: boolean;
  request: string;
  splash_image_aspect_ratio: number;
  id: number;
  stats: IStats;
  brand: IBrand;
  setup_completed: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  score?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deliverable_guidelines_templates: any[];
  unrestriced_staged_campaigns: boolean;
  status: string;
  network_types: string[];
  analyze_network_types: string[];
  follower_ranges: IFollowerRanges;
  impression_ranges: IImpressionRanges;
  offers_product: boolean;
  default_product_shipment_choices: string;
  offers_commission: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  alerts: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  offer?: any;
  category_display_names: string[];
  other_compensation: string;
  campaign_type: string;
  stages: ICampaignStage[];
  require_content_rights: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details?: any;
  balance_enabled: boolean;
  offers_payment: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  admin_review_notes?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  age_filter: any[];
  splash_image_url: string;
  name: string;
  invite_only: boolean;
  active_stage: ICampaignStage;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autoresponder_message?: any;
  network_details: INetworkDetail[];
  gender_filter: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approved_ts?: any;
  use_segmented_guidelines: boolean;
  balance: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  quirks: any[];
  require_exclusivity: boolean;
  accepted_country_codes: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accepted_place_ids: any[];
  accepted_language_codes: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  example_post_data: any[];
  product_types: string[];
  discovery_enabled: boolean;
  categories: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product_type_details: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  commission_instructions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  campaign_reviewer?: any;
  summary: string;
  engagement_ranges: IEngagementRanges;
  days_to_approve_content: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  example_post_links: any[];
  recommended_actions_remaining: number;
  potential_recommended_count: number;
  created_ts: number;
  offers_other: boolean;
  approved: boolean;
  is_staged: boolean;
  currency_code: TCurrencyCode;
  currency_xrate: number;
  has_campaign_manager: boolean;
  brand_users?: IManager[];
  gcr_info?: ICampaignGroupContentReviewInfo;
}

export interface ICampaignGroupContentReviewInfo {
  num_content_reviews: number;
  has_unread_messages: boolean;
  has_rejected_from_brand: boolean;
}

interface IEngagementRanges {
  instagram: [number, number];
  blog: [number, number];
  facebook: [number, number];
  youtube: [number, number];
  pinterest: [number, number];
  tiktok: [number, number];
}

interface INetworkDetail {
  fixed_compensation_is_negotiable: boolean;
  minimum_engagements?: number;
  network_type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maximum_budget?: any;
  maximum_followers: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fixed_compensation_per_engagement?: any;
  minimum_followers: number;
  maximum_engagements?: number;
  ideal_social_accounts: string[];
}

export interface ICampaignStage {
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  complete?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  goal?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deadline?: any;
  stage_identifier: string;
  active: boolean;
  campaign_id: number;
  project_id: number;
}

interface IFollowerRanges {
  instagram: [number, number];
  blog: [number, number];
  facebook: [number, number];
  youtube: [number, number];
  pinterest: [number, number];
  tiktok: [number, number];
}

interface IImpressionRanges {
  instagram: [number, number];
  blog: [number, number];
  facebook: [number, number];
  youtube: [number, number];
  pinterest: [number, number];
  tiktok: [number, number];
}

interface IStats {
  completed_collabs: number;
  proposals: number;
  active_collabs: number;
  invites_sent: number;
}

interface IAccountRepresentative {
  email: string;
  full_name: string;
  profile_image_url: string;
  first_name: string;
}
