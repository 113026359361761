/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_34mz1_157 {
  display: flex;
}

._justify-content-space-between_34mz1_161 {
  justify-content: space-between;
}

._tabular-nums_34mz1_165 {
  font-variant-numeric: tabular-nums;
}

._ProgramsList_34mz1_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_34mz1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._ProgramsList_34mz1_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._ProgramsList_34mz1_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._ProgramsList_34mz1_169 ::-webkit-scrollbar-track, ._ProgramsList_34mz1_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._ProgramsList_34mz1_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._ProgramsList_34mz1_169 {
  margin-right: -0.375rem;
  white-space: initial;
}
._ProgramsList_34mz1_169 ._program_34mz1_208 {
  margin-left: 0.375rem;
  margin-right: 0.0625rem;
}
._ProgramsList_34mz1_169 ._program_34mz1_208:first-of-type {
  margin-left: 1.5rem;
}
._ProgramsList_34mz1_169._hideIcon_34mz1_215 ._program_34mz1_208:first-of-type {
  margin-left: unset;
}
._ProgramsList_34mz1_169 ._icon_34mz1_218 {
  position: absolute;
  top: 0.0625rem;
  left: 0;
  fill: #aeaeae;
  vertical-align: middle;
  transform: translateY(-0.0625rem);
}
._ProgramsList_34mz1_169 ._icon_34mz1_218._approved_34mz1_226 {
  fill: #3996e0;
}

._placeholder_34mz1_230 {
  min-width: 10rem;
  height: 1rem;
  position: relative;
  display: inline-flex;
  border-radius: 0.25rem;
}

._program_34mz1_208 {
  margin-left: 0;
  margin-right: 0.25rem;
  display: inline;
  font-size: 0.875rem;
  line-height: 0.875rem;
  overflow-wrap: anywhere;
  color: #aeaeae;
}
._program_34mz1_208._approved_34mz1_226 {
  color: #3996e0;
}