import * as React from 'react';
import * as qs from 'qs';
import cn from 'classnames';
import { map, isEqual } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Dropdown,
  Menu,
} from '@revfluence/fresh';

import { EventName } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { PieChartIcon } from '@frontend/app/components';
import {
  useParsedRouterSearch,
  useSaveSegmentMutation,
  useSegmentFoldersQuery,
} from '@frontend/app/hooks';
import { segmentFilterCount } from '@frontend/app/utils/segments';
import { SegmentInput } from '@frontend/app/types/globalTypes';
import { MemberPageSegment as ISegment } from '@frontend/app/queries/fragments/types/MemberPageSegment';
import { useEventContext } from '@frontend/app/context/EventContext';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';

import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import { EditSegmentDropdown } from './EditSegmentDropdown';

import styles from './Segment.scss';

const {
 useEffect, useMemo, useState, useCallback,
} = React;

interface IProps {
  className?: string;
  communityId: number;
  sourcingGroupId: string;
  segment: ISegment | IPredefinedSegment;
}

export const Segment: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    className,
    communityId,
    segment: segmentProps,
    sourcingGroupId,
  } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [editSegmentVisible, setEditSegmentVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const parsedRouterSearch = useParsedRouterSearch();

  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();

  const addEvent = useEventContext();

  const {
    refetch: refetchSegmentFolders,
  } = useSegmentFoldersQuery(undefined, undefined, { skip: true });

  const [saveSegment, {
    loading: isSaving,
    error: saveError,
  }] = useSaveSegmentMutation({
    onCompleted: async (result) => {
      await refetchSegmentFolders({
        communityId,
        sourceGroup: sourcingGroupId,
      });

      const segment = result.segment;
      // redirect to segment url if new segment
      const oldSegment = segmentProps as ISegment;
      if (segment.id !== oldSegment.id) {
        const newSearch = { ...parsedRouterSearch, segmentId: segment.id };
        history.push({ ...location, search: qs.stringify(newSearch) });

        addEvent(
          EventName.CreateSegment,
          {
            member_count: segmentProps.memberCount,
            filter_count: segmentFilterCount(searchQuery),
          },
        );
      } else {
        addEvent(EventName.EditSegment, {});
      }

      showSuccessMessage('Successfully saved filter!');
    },
  });

  useEffect(() => {
    if (saveError) {
      showError(saveError);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveError]);

  const {
    segmentFilters,
    filters,
    searchQuery,
  } = useMemberListContext();

  const hasChanges = useMemo(() => {
    if (segmentProps) {
      const hasFilterChanges = !isEqual(filters, segmentFilters);
      return hasFilterChanges;
    }
    return false;
  }, [segmentFilters, filters, segmentProps]);

  const handleSave = async (isNewSegment: boolean, segmentInput: SegmentInput) => {
    segmentInput = {
      ...segmentInput,
      filter: searchQuery,
    };

    // Overwrite the existing segment
    if (!isNewSegment) {
      segmentInput.id = (segmentProps as ISegment).id;
    } else if (segmentProps.columns) {
      // New segments copy over column info from original segment.
      const {
        memberFieldSchemaIds,
        dbColumns,
        order,
      } = segmentProps.columns;

      segmentInput.columns = {
        memberFieldSchemaIds,
        dbColumns,
        order: map(order, (({ memberFieldSchemaId, dbColumn }) => ({ memberFieldSchemaId, dbColumn }))),
      };
    }

    setIsDropdownVisible(false);
    setEditSegmentVisible(false);

    return saveSegment({
      variables: {
        segment: segmentInput,
      },
    });
  };

  const toggleDropdown = useCallback(() => {
    setIsDropdownVisible((show) => {
      if (show) {
        setEditSegmentVisible(false);
      }
      return !show;
    });
  }, []);

  const toggleEditSegment = useCallback(() => {
    setEditSegmentVisible((show) => !show);
  }, [setEditSegmentVisible]);

  const handleVisibleChange = useCallback((visible) => {
    if (!visible) {
      setEditSegmentVisible(false);
    }
    setIsDropdownVisible(visible);
  }, []);

  // Is this a custom segment or predefined segment.
  const isCustomSegment = segmentProps?.__typename === 'Segment';

  const menu = (
    <Menu>
      <Menu.Item
        disabled={isSaving || !hasChanges}
        onClick={handleSave.bind(this, false, {})}
      >
        Update existing Filter
      </Menu.Item>
      <Menu.Item
        disabled={isSaving}
      >
        <EditSegmentDropdown
          communityId={communityId}
          isSaving={isSaving}
          onClose={(visible) => {
            toggleEditSegment();
            // toggleDropdown();
            setIsDropdownVisible(visible);
          }}
          onSaveSegment={handleSave.bind(this, true)}
          placement="topRight"
          segment={segmentProps as ISegment}
          sourcingGroupId={sourcingGroupId}
          visible={editSegmentVisible}
        >
          <div onClick={toggleEditSegment}>
            Save as new filter
          </div>
        </EditSegmentDropdown>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={cn(className, styles.Segment)}>
      {!isCustomSegment && (
        <EditSegmentDropdown
          communityId={communityId}
          sourcingGroupId={sourcingGroupId}
          visible={editSegmentVisible}
          isSaving={isSaving}
          hasChanges={hasChanges}
          onSaveSegment={handleSave.bind(this, true)}
          onClose={setEditSegmentVisible}
        >
          <Button
            className={styles.saveBtn}
            disabled={isSaving || !hasChanges}
            icon={(
              <span
                className="anticon"
                role="img"
              >
                {/* TODO: anticon wrapper */}
                <PieChartIcon size={18} />
              </span>
            )}
            onClick={toggleEditSegment}
          >
            Save Filter
          </Button>
        </EditSegmentDropdown>
      )}
      {isCustomSegment && (
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          trigger={['click']}
          visible={isDropdownVisible}
          onVisibleChange={handleVisibleChange}
        >
          <Button
            className={styles.saveBtn}
            icon={(
              <span
                className="anticon"
                role="img"
              >
                {/* TODO: anticon wrapper */}
                <PieChartIcon size={18} />
              </span>
            )}
            onClick={toggleDropdown}
          >
            Save Filter
          </Button>
        </Dropdown>
      )}
    </div>
  );
});
