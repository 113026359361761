import * as React from 'react';

import {
  useCSVUploadContext,
  useMemberPageContext,
} from '@frontend/app/containers/Members/hooks';
import { UploadButton } from '@frontend/app/containers/Members/UploadButton/UploadButton';

import { BaseEmptyState } from './BaseEmptyState';

import styles from './CommunityEmptyState.scss';

const Subtitle: React.FC = () => {
  const {
    showCSVUpload,
    onClickUpload,
  } = useCSVUploadContext();

  const {
    toggleOpenAddMember,
  } = useMemberPageContext();

  return (
    <div>
      <span className={styles.link} onClick={onClickUpload}>
        {showCSVUpload ? (
          'Upload csv file'
        ) : (
          <UploadButton className={styles.link} />
        )}
      </span>
      &nbsp;or&nbsp;
      <span className={styles.link} onClick={toggleOpenAddMember}>
        add a person
      </span>
    </div>
  );
};

export const CommunityEmptyState: React.FC = () => (
  <BaseEmptyState subtitle={<Subtitle />} />
);

CommunityEmptyState.displayName = 'CommunityEmptyState';
