import { MutationHookOptions, useMutation } from '@apollo/client';
import { DELETE_CATALOG_COLLECTION_MUTATION } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { DeleteCatalogCollectionMutation, DeleteCatalogCollectionMutationVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/DeleteCatalogCollectionMutation';

type IOptions = MutationHookOptions<DeleteCatalogCollectionMutation, DeleteCatalogCollectionMutationVariables>;

export const useDeleteCatalogCollection = (options: IOptions = {}) => {
  const [deleteCatalogCollection, { data, loading, error }] = useMutation(DELETE_CATALOG_COLLECTION_MUTATION, options);

  return {
    loading,
    collectionItem: data?.deleteCatalogCollectionById,
    deleteCatalogCollection,
    error,
  };
};
