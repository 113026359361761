import * as React from 'react';
import { each, startCase } from 'lodash';

import { NetworkIcon, IOrganization, TNetworkIconType } from '@components';
import { INetworkOption } from '../contentFiltersModel';

export const getNetworkOptions = (organization: IOrganization) => {
  if (!organization) {
    return [];
  }

  const options: INetworkOption[] = [];

  each(organization.available_networks, (network: TNetworkIconType) => {
    if (network !== 'blog') {
      options.push({
        id: network,
        name: startCase(network),
        icon: <NetworkIcon identifier={network} size={18} />,
      });
    }
  });

  options.push({
    id: 'content_review',
    name: 'Approved Content',
    icon: <NetworkIcon identifier="additional_images" size={18} />,
    tooltip:
      'Raw images or videos that were \
    uploaded by creators as part of the content review process for your collaborations',
  });

  options.push({
    id: 'additional_media',
    name: 'Produced Media',
    icon: <NetworkIcon identifier="additional_images" size={18} />,
    tooltip:
      'Raw images or videos that were requested of creators as part of your collaborations',
  });

  options.push({
    id: 'extra_content',
    name: 'Extra Media',
    icon: <NetworkIcon identifier="additional_images" size={18} />,
    tooltip: 'Media shared by creators without prompting',
  });

  options.push({
    id: 'upload',
    name: 'Manual Upload',
    icon: <NetworkIcon identifier="additional_images" size={18} />,
    tooltip: 'Media uploaded by users in your organization',
  });

  return options;
};
