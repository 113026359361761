import { useQuery, QueryHookOptions } from '@apollo/client';

import { HAS_ISSUE_ON_SOCIAL_ACCOUNT } from '../queries/HasIssueOnSocialAccount';
import { HasIssueOnSocialAccount } from '../queries/types/HasIssueOnSocialAccount';

type IOptions = QueryHookOptions<HasIssueOnSocialAccount>;

export const useHasIssueOnSocialAccount = (options: IOptions = {}) => useQuery<HasIssueOnSocialAccount>(HAS_ISSUE_ON_SOCIAL_ACCOUNT, {
  fetchPolicy: 'cache-first',
  ...options,
});
