._tag_1uw5c_1 {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 19px !important;
  cursor: pointer;
}
._tag_1uw5c_1 .ant-tag-close-icon {
  position: absolute;
  right: 4px;
  top: 4px;
}