import * as React from 'react';
import {
 Button, Space, Typography, Select,
} from '@revfluence/fresh';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/solid/esm';

interface PaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  displayPageSize?: boolean;
  pageSize: number;
  setPageSize?: React.Dispatch<React.SetStateAction<number>>;
  total?: number;
  showTotal?: boolean;
}

const { useMemo } = React;

export const Pagination = ({
  page,
  setPage,
  displayPageSize,
  pageSize,
  setPageSize,
  total,
  showTotal,
}: PaginationProps) => {
  const totalPages = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);

  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePageSize = (value: string) => {
    setPageSize(Number(value));
    setPage(1);
  };

  const itemsDisplayed = useMemo(() => {
    const firstItem = pageSize * page - pageSize + 1;
    const lastItem = pageSize * page < total ? pageSize * page : total;
    return `${firstItem}-${lastItem}`;
  }, [page, pageSize, total]);

  return (
    <Space>
      {showTotal && (
        <Typography.Text>
          {itemsDisplayed}
          {' '}
          of
          {' '}
          {total}
        </Typography.Text>
      )}
      <Button icon={<ChevronLeftIcon />} onClick={handlePrev} disabled={page === 1} />
      <Button icon={<ChevronRightIcon />} onClick={handleNext} disabled={totalPages <= page} />
      {displayPageSize && (
        <Select
          defaultValue="50"
          onChange={handlePageSize}
          options={[
            { value: '10', label: '10 / page' },
            { value: '25', label: '25 / page' },
            { value: '50', label: '50 / page' },
            { value: '100', label: '100 / page' },
          ]}
        />
      )}
    </Space>
  );
};
