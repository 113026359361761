import * as React from 'react';
import cx from 'classnames';

import styles from './PageSection.scss';

export const PageSection: React.FC<Readonly<React.HTMLAttributes<HTMLElement>>> = ({
  className,
  children,
  ...otherProps
}) => (
  <section className={cx(styles.PageSection, className)} {...otherProps}>
    {children}
  </section>
  );
