/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_s6g63_157 {
  display: flex;
}

._justify-content-space-between_s6g63_161 {
  justify-content: space-between;
}

._tabular-nums_s6g63_165 {
  font-variant-numeric: tabular-nums;
}

._Accordion_s6g63_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_s6g63_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Accordion_s6g63_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Accordion_s6g63_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Accordion_s6g63_169 ::-webkit-scrollbar-track, ._Accordion_s6g63_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Accordion_s6g63_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Accordion_s6g63_169 ._label_s6g63_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._Accordion_s6g63_169._closed_s6g63_292 ._arrow_s6g63_292 {
  transform: rotate(-90deg);
}
._Accordion_s6g63_169._closed_s6g63_292 ._contents_s6g63_295 {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s, opacity 0s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
._Accordion_s6g63_169._open_s6g63_301 ._arrow_s6g63_292 {
  color: #3996e0;
}
._Accordion_s6g63_169._rightArrowPlacement_s6g63_304._closed_s6g63_292 ._arrow_s6g63_292 {
  transform: rotate(90deg);
}
._Accordion_s6g63_169._rightArrowPlacement_s6g63_304 ._arrow_s6g63_292 {
  margin-left: auto;
}
._Accordion_s6g63_169._rightArrowPlacement_s6g63_304 ._contents_s6g63_295 {
  padding-left: 0;
  padding-right: 1.25rem;
}
._Accordion_s6g63_169 ._toggle_s6g63_314 {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
._Accordion_s6g63_169 ._toggle_s6g63_314:hover ._arrow_s6g63_292 {
  color: #3996e0;
}
._Accordion_s6g63_169 ._toggle_s6g63_314:hover ._label_s6g63_204 {
  color: #3996e0;
}
._Accordion_s6g63_169 ._arrow_s6g63_292 {
  margin-right: 8px;
  color: #8f8d91;
  will-change: transform, color;
  transition: transform 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, color 0.1s ease-out 0s;
}
._Accordion_s6g63_169 ._label_s6g63_204 {
  margin-top: 1px;
  user-select: none;
  will-change: color;
  transition: color 0.1s ease-out 0s;
}
._Accordion_s6g63_169 ._contents_s6g63_295 {
  padding-left: 1.25rem;
  opacity: 1;
  max-height: 1000px;
  overflow-y: overlay;
  will-change: max-height, opacity;
  transition: max-height 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.45s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
}