import gql from 'graphql-tag';

import { MEMBER_TAG_FRAGMENT } from './fragments';

export const TAGS_QUERY = gql`
  query TagsQuery {
    tags {
      ...MemberTagFragment
    }
  }
  ${MEMBER_TAG_FRAGMENT}
`;

export const DELETE_TAG_MUTATION = gql`
  mutation DeleteTagMutation($id: Int!) {
    deleted: deleteTagById(id: $id)
  }
`;

export const SAVE_TAG_MUTATION = gql`
  mutation SaveTagMutation($tag: TagInput!) {
    tag: saveTag(tag: $tag) {
      ...MemberTagFragment
    }
  }
  ${MEMBER_TAG_FRAGMENT}
`;

export const ADD_MEMBERS_TO_TAGS_MUTATION = gql`
  mutation AddMembersToTagsMutation($query: JSONObject, $memberIds: [Int!], $tagIds: [Int!]!) {
    success: membersAddToTags(query: $query, memberIds: $memberIds, tagIds: $tagIds)
  }
`;

export const REMOVE_MEMBERS_FROM_TAGS_MUTATION = gql`
  mutation RemoveMembersFromTagsMutation($query: JSONObject, $memberIds: [Int!], $tagIds: [Int!]!) {
    success: membersRemoveFromTags(query: $query, memberIds: $memberIds, tagIds: $tagIds)
  }
`;
