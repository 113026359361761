import gql from 'graphql-tag';

const GET_PROJECT_BUDGET_ACCOUNT_DISTRIBUTION = gql`
    query GetProjectBudgetAccountDistribution ($budgetId: Int, $fetchMasterBudget: Boolean, $programId: Int) {
        projectBudgetAccountDistribution: getProjectBudgetAccountDistribution (budgetId: $budgetId, fetchMasterBudget: $fetchMasterBudget, programId: $programId) {
            budgetId
            budgetName
            budgetDescription
            parentBudgetId
            fiscalYearsBudget
        }
    }
`;

export default GET_PROJECT_BUDGET_ACCOUNT_DISTRIBUTION;
