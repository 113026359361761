import { CatalogStatus } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';

export interface CatalogDetailsContextProps {
  title: string;
  description: string;
  status: CatalogStatus;
  setTitle: (title: string) => void;
  setDescription: (description: string) => void;
  setStatus: (status: CatalogStatus) => void;
  setCatalog: (catalog: Partial<CatalogDetailsContextState>) => void;
}
export type CatalogDetailsContextState = Omit<
  CatalogDetailsContextProps,
  'setTitle' | 'setDescription' | 'setStatus' | 'setCatalog'
>;

const initialState: CatalogDetailsContextState = {
  title: '',
  description: '',
  status: CatalogStatus.DRAFT,
};

export const CatalogDetailsContext = createContext<CatalogDetailsContextProps>({
  ...initialState,
  setDescription: () => {},
  setTitle: () => {},
  setStatus: () => {},
  setCatalog: () => {},
});

export enum ActionType {
  SetScreen = 'SET_SCREEN',
  SetSelectionModal = 'SET_SELECTION_MODAL',
  SetTitle = 'SET_TITLE',
  SetDescription = 'SET_DESCRIPTION',
  SetStatus = 'SET_STATUS',
  SetCatalog = 'SET_CATALOG',
}

export type Action = {
  type: ActionType.SetDescription;
  payload: string;
} | {
  type: ActionType.SetTitle;
  payload: string;
} | {
  type: ActionType.SetStatus;
  payload: CatalogStatus;
} | {
  type: ActionType.SetCatalog;
  payload: Partial<CatalogDetailsContextState>;
};

export const CatalogDetailsProvider: React.FC = ({ children }) => {
  const reducer = (state: CatalogDetailsContextState, action: Action): CatalogDetailsContextState => {
    switch (action.type) {
      case ActionType.SetTitle:
        return {
          ...state,
          title: action.payload,
        };
      case ActionType.SetDescription:
        return {
          ...state,
          description: action.payload,
        };
      case ActionType.SetStatus:
        return {
          ...state,
          status: action.payload,
        };
      case ActionType.SetCatalog:
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setTitle: (title: CatalogDetailsContextState['title']) => {
        dispatch({ type: ActionType.SetTitle, payload: title });
      },
      setDescription: (description: CatalogDetailsContextState['description']) => {
        dispatch({ type: ActionType.SetDescription, payload: description });
      },
      setStatus: (status: CatalogDetailsContextState['status']) => {
        dispatch({ type: ActionType.SetStatus, payload: status });
      },
      setCatalog: (catalog: Partial<CatalogDetailsContextState>) => {
        dispatch({ type: ActionType.SetCatalog, payload: catalog });
      },
    }),
    [],
  );

  const memoizedValue = useMemo<CatalogDetailsContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <CatalogDetailsContext.Provider value={memoizedValue}>{children}</CatalogDetailsContext.Provider>;
};

export const useCatalogDetailsContext = () => useContext(CatalogDetailsContext);
