import * as React from 'react';
import { isEmpty } from 'lodash';
import { logger } from '@common';

import { ImagesCell } from './ImagesCell';
import { renderEmptyCell } from '../EmptyCell';

export const renderImagesCell = (rawValue: string) => {
  try {
    const images = JSON.parse(rawValue);
    return isEmpty(images)
      ? renderEmptyCell()
      : <ImagesCell images={images} />;
  } catch (error) {
    logger.error(error);
    return renderEmptyCell();
  }
};
