import gql from 'graphql-tag';

import { TASK_FRAGMENT } from './fragments';

export const GET_ALL_TASKS_QUERY = gql`
  query GetAllTasksQuery($specKey: String!) {
    tasks: getAllTasks(specKey: $specKey) {
      ...TaskFragment
    }
  }
  ${TASK_FRAGMENT}
`;
