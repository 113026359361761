import * as React from 'react';
import {
 useState, useRef, useReducer, useEffect,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Toast, IToastRefHandles } from '@components';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { IContent } from '@frontend/app/hooks/useUploadContent';

import { ILicensedContent } from '../saveLicensedContent';

import { NewContentSetup } from '../components/NewContentSetup';
import { UploadContent } from '../components/UploadContent';
import { ContentAssignment } from '../components/ContentAssignment';

import styles from './NewContent.scss';

interface IProps {
}

interface NewContentState {
  uploadedContent: IContent[];
  contents: ILicensedContent[];
  assigned: boolean;
}

enum NewContentPage {
  UploadContent = 'uploadContent',
  NewContentSetup = 'newContentSetup',
  ArtifactAssignment = 'artifactAssignment',
}

export enum ContentReducerActionType {
  SetMedia = 'setMedia',
  SetContent = 'setContent',
}

interface ContentReducerAction {
  type: ContentReducerActionType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

const NewContent: React.FunctionComponent<IProps> = () => {
  /* Overall flow is:
    1. Upload content as media URL's
    2. Set up content parameters (license terms, sku etc)
    3. Save content to server
    4. Assign artifact ID's
    5. Add content to requirements
  */

  // Main difference with other apps is that you can upload multiple content at once.
  // Limit this to 50 at a time to prevent issues with deadline/memory exceeded
  const [page, setPage] = useState<NewContentPage[]>([]);

  const toastRef = useRef<IToastRefHandles>(null);

  const history = useHistory();
  const location = useLocation();
  const { memberId } = useApplication();

  const contentReducer = (state: NewContentState, action: ContentReducerAction) => {
    const { value } = action;
    switch (action.type) {
      case ContentReducerActionType.SetMedia:
        return { ...state, uploadedContent: value };
      case ContentReducerActionType.SetContent:
        return { ...state, contents: value };
      default:
        throw new Error();
    }
  };

  const [contentParams, dispatch] = useReducer(contentReducer, {
    uploadedContent: [],
    contents: [],
    assigned: false,
  });

  // Page state should reflect state of reducer
  useEffect(() => {
    if (!contentParams.uploadedContent || contentParams.uploadedContent.length == 0) {
      setPage([...page, NewContentPage.UploadContent]);
    } else if (!contentParams.contents || contentParams.contents.length == 0) {
      setPage([...page, NewContentPage.NewContentSetup]);
    } else if (!contentParams.assigned) {
      setPage([...page, NewContentPage.ArtifactAssignment]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentParams]);

  const onContentCreateCompleted = () => {
    history.push({
      ...location,
      pathname: './content',
    });
  };

  return (
    <div className={styles.NewContent}>
      {page[page.length - 1] === NewContentPage.UploadContent
        && <UploadContent dispatch={dispatch} />}
      {page[page.length - 1] === NewContentPage.NewContentSetup
        && <NewContentSetup dispatch={dispatch} memberId={memberId} uploadedContent={contentParams.uploadedContent} toastRef={toastRef} />}
      {page[page.length - 1] === NewContentPage.ArtifactAssignment
        && (
        <ContentAssignment
          contents={contentParams.contents}
          onFlowComplete={onContentCreateCompleted}
        />
)}
      <Toast ref={toastRef} />
    </div>
  );
};

export default NewContent;
