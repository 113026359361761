import * as React from 'react';
import {
  isFunction, isUndefined, filter, size,
} from 'lodash';

import { Button } from '@components';

import { EventName } from '@common';
import { ProjectStatus } from '@frontend/app/containers/Projects/OverviewPage/Header/constants';
import { useEventContext } from '@frontend/app/context/EventContext';
import {
  useActivationsQuery,
  useCommunitiesQuery,
  useFuzzySearchByKeys,
  useGetUsersQuery,
  useProgramsQuery,
  useFeatureFlagVerbiage,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { SelectListPicker } from '@frontend/app/components/SelectList/SelectListPicker';

import { ClientFeature } from '@frontend/app/constants';
import { useUploadCSVContext } from '../hooks/useUploadCSVModalContext';

const { useCallback, useMemo } = React;

import styles from './BulkAssign.scss';

interface IProps {
  nextStep: () => void;
  prevStep: () => void;
  uploadIdentifier: string;
}

export const BulkAssign: React.FC<IProps> = (props) => {
  const {
    nextStep,
    prevStep,
    uploadIdentifier,
  } = props;

  const verbiage = useFeatureFlagVerbiage();
  const workflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);

  const {
    setActivationIdsParam,
    setCommunityIdsParam,
    setOwnerIdsParam,
    setProgramIdsParam,
    uploadCsvParams,
  } = useUploadCSVContext();

  // Community
  const {
    data: {
      communities,
    } = {},
  } = useCommunitiesQuery();
  type TCommunity = typeof communities[0];
  const mapCommunityToId = useCallback(
    (community: TCommunity) => community?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [communities],
  );
  const mapCommunityToLabel = useCallback(
    (community: TCommunity) => community?.title,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [communities],
  );
  const handleCommunitySearch = useFuzzySearchByKeys<TCommunity>(communities, ['title']);
  const renderCommunityRow = () => (
    <tr>
      <td>{verbiage.Communities}</td>
      <td>
        Select
        {' '}
        {verbiage.communities}
        {' '}
        for these records
      </td>
      <td>
        <SelectListPicker<TCommunity>
          options={communities}
          mapOptionToId={mapCommunityToId}
          mapOptionToLabel={mapCommunityToLabel}
          onChange={setCommunityIdsParam}
          onSearchRequest={handleCommunitySearch}
          showSearch
          placeholder={`Select ${verbiage.communities} (optional)`}
          popoverProps={{
            overlayClassName: styles.selectListPopoverOverlay,
          }}
          className={styles.selectListPicker}
        />
      </td>
    </tr>
  );

  // Programs
  const {
    data: {
      programs,
    } = {},
  } = useProgramsQuery();
  type TProgram = typeof programs[0];

  const activeProjects = useMemo(() => {
    if (isUndefined(isArchiveProjectEnabled)) return [];

    if (isArchiveProjectEnabled) {
      return filter(programs, (project) => project.status === ProjectStatus.Active);
    }

    return programs;
  }, [isArchiveProjectEnabled, programs]);

  const mapProgramToId = useCallback(
    (program: TProgram) => program?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProjects],
  );
  const mapProgramToLabel = useCallback(
    (program: TProgram) => program?.title,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeProjects],
  );
  const handleProgramSearch = useFuzzySearchByKeys<TProgram>(activeProjects, ['title']);
  const renderProgramRow = () => (
    <tr>
      <td>{verbiage.Programs}</td>
      <td>
        Select
        {' '}
        {verbiage.programs}
        {' '}
        for these records
      </td>
      <td>
        <SelectListPicker<TProgram>
          options={activeProjects}
          mapOptionToId={mapProgramToId}
          mapOptionToLabel={mapProgramToLabel}
          onChange={setProgramIdsParam}
          onSearchRequest={handleProgramSearch}
          showSearch
          placeholder={`Select ${verbiage.programs} (optional)`}
          popoverProps={{
            overlayClassName: styles.selectListPopoverOverlay,
          }}
          className={styles.selectListPicker}
        />
      </td>
    </tr>
  );

  // Activations
  const {
    activations,
  } = useActivationsQuery();
  type TActivation = typeof activations[0];
  const mapActivationToId = useCallback(
    (activation: TActivation) => activation?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activations],
  );
  const mapActivationToLabel = useCallback(
    (activation: TActivation) => activation?.name,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activations],
  );
  const handleActivationSearch = useFuzzySearchByKeys<TActivation>(activations, ['name']);
  const renderActivationRow = () => (
    <tr>
      <td>Activations</td>
      <td>Select activations for these records</td>
      <td>
        <SelectListPicker<TActivation>
          options={activations}
          mapOptionToId={mapActivationToId}
          mapOptionToLabel={mapActivationToLabel}
          onChange={setActivationIdsParam}
          onSearchRequest={handleActivationSearch}
          showSearch
          placeholder="Select activations (optional)"
          popoverProps={{
            overlayClassName: styles.selectListPopoverOverlay,
          }}
          className={styles.selectListPicker}
        />
      </td>
    </tr>
  );

  // Owners
  const {
    data: {
      users: owners,
    } = {},
  } = useGetUsersQuery();

  type TOwner = typeof owners[0];

  const mapOwnerToId = useCallback(
    (owner: TOwner) => owner?.id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [owners],
  );
  const mapOwnerToLabel = useCallback(
    (owner: TOwner) => owner?.name,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [owners],
  );

  const handleOwnerSearch = useFuzzySearchByKeys<TOwner>(owners, ['name']);
  const renderOwnerRow = () => (
    <tr>
      <td>Owners</td>
      <td>Select owners for these records</td>
      <td>
        <SelectListPicker<TOwner>
          options={owners}
          mapOptionToId={mapOwnerToId}
          mapOptionToLabel={mapOwnerToLabel}
          onChange={(newOwners: string[]) => {
            setOwnerIdsParam(newOwners);
          }}
          onSearchRequest={handleOwnerSearch}
          multi
          showSearch
          placeholder="Select owners (optional)"
          popoverProps={{
            overlayClassName: styles.selectListPopoverOverlay,
          }}
          className={styles.selectListPicker}
        />
      </td>
    </tr>
  );

  const addEvent = useEventContext();

  const handleImportData = () => {
    if (isFunction(nextStep)) {
      addEvent(EventName.CSVUploadBulkAssignCompleted, {
        uploadIdentifier,
        communitiesCount: uploadCsvParams?.params?.communityIds?.length || 0,
        programsCount: uploadCsvParams?.params?.programIds?.length || 0,
        activationsCount: uploadCsvParams?.params?.activationIds?.length || 0,
        ownerCount: size(uploadCsvParams?.params?.ownerIds),
      });
      nextStep();
    }
  };

  return (
    <div className={styles.BulkAssign}>
      <h4>Would you like to include these to your upload? (Optional)</h4>
      <p>
        Bulk assign, or edit the
        {' '}
        {verbiage.Community}
        ,
        {' '}
        {verbiage.Program}
        {workflowEnabled === false && ', Activation'}
        , and Owner fields
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Field</th>
            <th />
            <th>Assign</th>
          </tr>
        </thead>
        <tbody>
          {renderCommunityRow()}
          {renderProgramRow()}
          {workflowEnabled === false && renderActivationRow()}
          {renderOwnerRow()}
        </tbody>
      </table>
      <footer className={styles.footer}>
        <Button
          label="Previous"
          onClick={prevStep}
          theme="light"
        />
        <Button
          label="Import Data"
          onClick={handleImportData}
        />
      </footer>
    </div>
  );
};
