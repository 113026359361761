import * as React from 'react';
import { Button } from '@revfluence/fresh';
import styles from './OfferConfirmation.scss';

interface IProps {
  onRemoveOffer: () => void;
  onChangeOffer: () => void;
}
export const OfferAdjustmentButtons: React.FC<Readonly<IProps>> = ({ onRemoveOffer, onChangeOffer }) => (
  <div className={styles.buttons}>
    <Button danger className={styles.secondaryButton} onClick={onRemoveOffer}>Remove Offer from Project</Button>
    <Button className={styles.secondaryButton} onClick={onChangeOffer}>Change Offer for Project</Button>
  </div>
);
