._AnnualBetween_ju9kb_1 ._datePicker_ju9kb_1 {
  width: 100%;
}

._datePickerDropdown_ju9kb_5 .ant-picker-year-btn,
._datePickerDropdown_ju9kb_5 .ant-picker-header-super-prev-btn,
._datePickerDropdown_ju9kb_5 .ant-picker-header-super-next-btn {
  display: none !important;
}
._datePickerDropdown_ju9kb_5 .ant-picker-month-btn {
  pointer-events: none !important;
}