import React from 'react';
import {
 Card, Space, Switch, Typography,
} from '@revfluence/fresh';
import drawerStyles from '../../Products/ImportSettings/ImportSettingsDrawer.scss';

const { Text } = Typography;

export interface ISettingsCardWithSwitchProps {
  title: string;
  subtitle: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  extra?: React.ReactNode;
}

export const SettingsCardWithSwitch = ({
 title, subtitle, checked, onChange, extra = null,
}: ISettingsCardWithSwitchProps) => (
  <Card>
    <Space direction="vertical" size="middle">
      <Space size="middle" align="start">
        <Space direction="vertical" size={0}>
          <Text weight="semibold">{title}</Text>
          <Text className={drawerStyles.cardSubtitle}>{subtitle}</Text>
        </Space>
        <Switch checked={checked} onChange={onChange} />
      </Space>
      {extra}
    </Space>
  </Card>
);
