import * as React from 'react';
import {
  Row, Col, Space, useBreakpoint,
} from '@revfluence/fresh';

import { IRecommendation } from './components/AspireAdvice';

export interface IHistoricalData {
  dateTime: string;
  value: number;
}
interface IValue {
  value: number;
  percentDiff: number | null;
}

type TValueWithHistoricalData = IValue & { historicalData: IHistoricalData[] };

export interface IImpactHomeProps {
  Alert: React.ReactNode;
  clientStartDate: Date;
  impactData: {
    totalValue: number;
    investment: number;
    impact: number | null;
    percentDiff: number;
  }
  contentData: TValueWithHistoricalData;
  impressionsData: TValueWithHistoricalData;
  engagementsData: TValueWithHistoricalData;
  salesData: TValueWithHistoricalData;
  trends: {
    reach: IValue;
    views: IValue;
    conversions: IValue;
    creators: IValue;
    cpm: IValue;
    cpe: IValue;
    tmv: IValue;
  }
  recommendation: IRecommendation;
}

interface IHomeLayout {
  Header: React.ReactNode;
  Alert: React.ReactNode;
  ImpactComponent: React.ReactNode;
  MetricsPrimary: React.ReactNode;
  MetricsSecondary: React.ReactNode;
  Advice: React.ReactNode;
}

export const HomeLayout = ({
  Header,
  Alert,
  ImpactComponent,
  MetricsPrimary,
  MetricsSecondary,
  Advice,
}: IHomeLayout) => {
  const screens = useBreakpoint();
  const Layout = () => {
    if ((screens.xxl || screens.xl)) {
      return (
        <Row gutter={[24, 24]}>
          <Col span={8}>
            {ImpactComponent}
          </Col>
          <Col span={16}>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
              {MetricsPrimary}
              {MetricsSecondary}
              {Advice}
            </Space>
          </Col>
        </Row>
      );
    }
    if (screens.lg) {
      return (
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
              {ImpactComponent}
            </Space>
          </Col>
          <Col span={16}>
            {MetricsPrimary}
          </Col>
          <Col span={24}>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
              {MetricsSecondary}
              {Advice}
            </Space>
          </Col>
        </Row>
      );
    }
    if (screens.md) {
      return (
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
              {ImpactComponent}
              {MetricsSecondary}
              {Advice}
            </Space>
          </Col>
          <Col span={12}>
            {MetricsPrimary}
          </Col>
        </Row>
      );
    }
    return (
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {ImpactComponent}
        </Col>
        <Col span={24}>
          {MetricsPrimary}
        </Col>
        <Col span={24}>
          {MetricsSecondary}
        </Col>
        <Col span={24}>
          {Advice}
        </Col>
      </Row>
    );
  };

  return (
    <Space direction="vertical" size={36} style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col xs={24}>
          {Header}
        </Col>
      </Row>
      {Alert}
      <Layout />
    </Space>
  );
};
