import { TActiveDates, TSelectedMember } from '@affiliates/components/MembersWizard/types';

export type TState = Readonly<{
  activeDates: TActiveDates;
  currentActiveDatesLabel: string;
  currentStep: number;
  error: Error | null;
  members: readonly TSelectedMember[];
  saving: boolean;
  showCloseConfirmation: boolean;
  searchKey: string,
}>;

export type TActions = Readonly<{
  dismissCloseConfirmation: () => void;
  save: (step: number) => void;
  selectMembers: (members: readonly TSelectedMember[]) => void;
  setCurrentStep: (step: number) => void;
  setError: (error: Error) => void;
  showCloseConfirmation: () => void;
  reset: () => void;
  updateActiveDateField: (dateField: keyof TState['activeDates'], value?: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMemberCode: (memberId: string, field: string, value: any) => void;
  updateSearchKey: (searchKey: string) => void;
}>;
