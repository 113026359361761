import gql from 'graphql-tag';

export const ASPIREX_PROGRAMS_QUERY = gql`
  query AspireXPrograms {
    programs: aspirexPrograms {
      id
      title
      communityId
    }
  }
`;
