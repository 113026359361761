/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_f67zx_157 {
  display: flex;
}

._justify-content-space-between_f67zx_161 {
  justify-content: space-between;
}

._tabular-nums_f67zx_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_f67zx_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._DetailView_f67zx_178 ._toSection_f67zx_178 ._remove_f67zx_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.214;
  color: #1a1818;
}

._DetailView_f67zx_178 ._section_f67zx_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._DetailView_f67zx_178 {
  position: relative;
  height: 100%;
  overflow: auto;
}
._DetailView_f67zx_178 ._section_f67zx_188 {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  min-height: 3.125rem;
  padding: 0 1.5rem;
  border-top: 0.0625rem solid #dadcde;
  color: #8f8d91;
}
._DetailView_f67zx_178 ._toSection_f67zx_178 ._remove_f67zx_178 {
  margin-left: auto;
  color: #f1515f;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}
._DetailView_f67zx_178 ._messageSection_f67zx_251 {
  padding: 0;
}
._DetailView_f67zx_178 ._messageSection_f67zx_251 ._emailComposer_f67zx_254 {
  width: 100%;
}