/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ni56l_157 {
  display: flex;
}

._justify-content-space-between_ni56l_161 {
  justify-content: space-between;
}

._tabular-nums_ni56l_165, ._valueAmount_ni56l_165 {
  font-variant-numeric: tabular-nums;
}

._PostCard_ni56l_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_ni56l_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._PostCard_ni56l_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._PostCard_ni56l_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._PostCard_ni56l_169 ::-webkit-scrollbar-track, ._PostCard_ni56l_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._PostCard_ni56l_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._ClearLink_ni56l_204 {
  color: #1a1818;
  text-decoration: none;
  margin: auto;
  min-width: 20rem;
  flex: 0 1 auto;
}

._truncate_ni56l_212 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  position: relative;
}

._PostCard_ni56l_169 {
  max-width: 20rem;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  margin: auto;
}
._PostCard_ni56l_169 ._TopShadow_ni56l_226 {
  width: 100%;
  height: 3rem;
  top: 0;
  position: absolute;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 6px;
}
._PostCard_ni56l_169 ._InfoContainer_ni56l_235 {
  border-right: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
  border-left: 1px solid #ececec;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
}
._PostCard_ni56l_169 ._ValueSection_ni56l_243 {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  border-top: 0.0625rem solid #e9e8ea;
  list-style: none;
}
._PostCard_ni56l_169 ._ValueSection_ni56l_243 ._ValueSectionItem_ni56l_251 {
  margin: 12px;
}
._PostCard_ni56l_169 ._ValueSection_ni56l_243 ._ValueSectionItem_ni56l_251 ._ValueSectionItemLabel_ni56l_254 {
  display: flex;
  align-items: center;
}
._PostCard_ni56l_169 ._ValueSection_ni56l_243 ._ValueSectionItem_ni56l_251 ._ValueSectionItemLabel_ni56l_254 ._Text_ni56l_258 {
  margin-right: 0.25rem;
}
._PostCard_ni56l_169 ._tooltip_container_ni56l_261 {
  height: 1.25rem;
  color: #ea92af;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 {
  overflow: hidden;
  scrollbar-face-color: #afafaf;
  scrollbar-track-color: none;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ._imgContent_ni56l_270 {
  width: 100%;
  height: 250px;
  border-radius: 6px;
  object-fit: cover;
  object-position: top;
  display: block;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ._contentContainer_ni56l_278 {
  position: relative;
  border: none;
  border-radius: 0.5rem 0.5rem 0 0;
  /* l - caption */
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ._contentContainer_ni56l_278 ._tagList_ni56l_284 {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  text-align: left;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ._contentContainer_ni56l_278 ._tag_ni56l_284 {
  position: relative;
  display: inline-block;
  height: 1.5rem;
  margin: 0 0.1875rem 0.3125rem 0;
  padding: 0.3125rem 0.375rem;
  border: 0.0625rem solid #ececec;
  border-radius: 0.25rem;
  background-color: #fdfdfd;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.75rem;
  text-transform: capitalize;
  cursor: pointer;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ._contentContainer_ni56l_278 ._txtPreview_ni56l_305 {
  height: 171px;
  top: 1rem;
  font-size: 1.25rem;
  line-height: 1.8rem;
  padding: 0 0.5rem;
  left: 0px;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ._contentContainer_ni56l_278 ._captionContainer_ni56l_313 {
  margin-top: -2rem;
  padding: 0.5rem;
  height: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(26, 24, 24, 0.6);
  box-sizing: border-box;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ._contentContainer_ni56l_278 ._captionContainer_ni56l_313 ._captionTxt_ni56l_323 {
  font-size: 0.75rem;
  color: white;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ::-webkit-scrollbar {
  width: 6px;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 100px;
}
._PostCard_ni56l_169 ._contentImageContainer_ni56l_265 ::-webkit-scrollbar-track {
  background: none;
}