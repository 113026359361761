import * as React from 'react';

import { FileLinesIcon, WandMagicSparklesIcon } from '@revfluence/fresh-icons/regular/esm';

import { map, capitalize } from 'lodash';

import { Space, Tooltip } from '@revfluence/fresh';
import { IMessageTemplate } from '@frontend/app/hooks';
import { TAdditionalType } from './types';
import styles from './AdditionalTypes.module.scss';

import { VariableReplacement } from '../../MessageComposer/EmailEditor/VariableReplacement';
import { TemplateSelector } from '../../MessageComposer/EmailEditor/TemplateSelector';

const ADDITIONAL_TYPE_ICON: {
  [key in TAdditionalType]: React.ReactNode;
} = {
  template: <FileLinesIcon />,
  personalize: <WandMagicSparklesIcon />,
};

const ADDITIONAL_TYPE_HOTKEY: {
  [key in TAdditionalType]: string;
} = {
  template: '⌘T',
  personalize: '⌘P',
};

interface IProps {
  showProgramVariables: boolean;
  enableVariableReplacement?: boolean;
  invalidMemberIdsForField: {
    [field: string]: number[];
  };
  additionalVariables?: { [key: string]: { label: string } };
  onTemplateSelected?: (template: IMessageTemplate) => void;
  enableMessageTemplate?: boolean;
}

const AdditionalTypesComponent = ({
  enableMessageTemplate = true,
  enableVariableReplacement,
  onTemplateSelected,
  additionalVariables,
  invalidMemberIdsForField,
  showProgramVariables,
}: IProps, ref) => {
  const filteredTypes = React.useMemo(() => {
    const types: TAdditionalType[] = [];

    if (enableMessageTemplate) {
      types.push('template');
    }

    if (enableVariableReplacement) {
      types.push('personalize');
    }

    return types;
  }, [enableVariableReplacement, enableMessageTemplate]);

  return (
    <Space direction="horizontal">
      {map(filteredTypes, (type) => (
        <Tooltip
          key={type}
          overlayClassName={styles.Tooltip}
          title={(
            <div className={styles.content}>
              {capitalize(type)}
              <span className={styles.hotkey}>
                {ADDITIONAL_TYPE_HOTKEY[type]}
              </span>
            </div>
            )}
        >
          <div
            className={styles.item}
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            {type === 'personalize' && (
              <VariableReplacement
                additionalVariables={additionalVariables}
                invalidMemberIdsForField={invalidMemberIdsForField}
                onInsertVariable={(label, field) => {
                  ref.current.insertEntity({
                    type: 'variable',
                    label,
                    data: {
                      field,
                    },
                  });
                }}
                showProgramVariables={showProgramVariables}
              >
                {ADDITIONAL_TYPE_ICON[type]}
              </VariableReplacement>
            )}
            {type === 'template' && (
              <TemplateSelector
                onTemplateSelected={(template) => {
                  ref.current.setValue(template.text);
                  onTemplateSelected?.(template);
                }}
              >
                {ADDITIONAL_TYPE_ICON[type]}
              </TemplateSelector>
            )}
          </div>
        </Tooltip>
        ))}
    </Space>
  );
};

AdditionalTypesComponent.displayName = 'AdditionalTypes';

export const AdditionalTypes = React.forwardRef(AdditionalTypesComponent);
