import {
  MutationHookOptions,
  useMutation,
} from '@apollo/client';

import { DELETE_WORKLET_FROM_SPECIFICATION_MUTATION } from '@frontend/app/queries';
import {
  DeleteWorkletFromSpecificationMutation,
  DeleteWorkletFromSpecificationMutationVariables,
} from '@frontend/app/queries/types/DeleteWorkletFromSpecificationMutation';

type IOptions = MutationHookOptions<DeleteWorkletFromSpecificationMutation, DeleteWorkletFromSpecificationMutationVariables>;

export const useDeleteWorkletFromSpecification = (options: IOptions = {}) => (
  useMutation<DeleteWorkletFromSpecificationMutation, DeleteWorkletFromSpecificationMutationVariables>(DELETE_WORKLET_FROM_SPECIFICATION_MUTATION, options)
);
