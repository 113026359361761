import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const BoldIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M4.60416 4.375C4.60416 2.75 5.39582 2 7.12082 2H12.5708C16.1042 2 18.3125 3.90833 18.3125 6.925C18.3125 9.16667 17.0958 10.7667 15.0042 11.3167V11.4667C17.6958 11.775 19.3958 13.6333 19.3958 16.375C19.3958 19.8333 16.7042 22 12.4208 22H7.12082C5.39582 21.9917 4.60416 21.2667 4.60416 19.625V4.375ZM10.7292 10.45C13.7042 10.45 15.0208 9.45 15.0208 7.475C15.0208 5.7 13.8625 4.63333 11.8875 4.63333H7.91249V10.45H10.7292ZM11.0958 19.3583C14.5958 19.3583 16.0208 18.3583 16.0208 16.25C16.0208 14.1833 14.6292 12.95 12.1542 12.95H7.91249V19.35H11.0958V19.3583Z" />
  </SvgIcon>
));

BoldIcon.displayName = 'BoldIcon';
