import 'css-chunk:src/applications/ReportsApp/pages/Home/components/AspireAdvice/AspireAdvice.scss';export default {
  "AspireAdvice": "_AspireAdvice_bup8e_1",
  "icon": "_icon_bup8e_12",
  "title": "_title_bup8e_24",
  "description": "_description_bup8e_29",
  "link": "_link_bup8e_33",
  "center": "_center_bup8e_38",
  "AlertAction": "_AlertAction_bup8e_44",
  "counter": "_counter_bup8e_47",
  "button": "_button_bup8e_50"
};