import { ContentType } from '@frontend/app/types/globalTypes';
import { IState } from '../../../../types/state';
import { IToggleRequiresBrandedContentTagAction } from './index';
import { isInstagram, isTiktok } from '../../../../utils/contentTypeUtils';

const instagramContentRequiresBC = (
  type: ContentType,
  isEnabled: boolean,
): boolean => {
  if (isInstagram(type)) {
    return isEnabled;
  }
  return false;
};

const tiktokContentRequiresBC = (
  type: ContentType,
  isEnabled: boolean,
): boolean => {
  if (isTiktok(type)) {
    return isEnabled;
  }
  return false;
};

export const toggleRequiresBrandedContentTag = (
  state: IState,
  action: IToggleRequiresBrandedContentTagAction,
): IState => {
  const {
    contentGuidelineInstanceId,
    requiresBrandedContentTag,
  } = action;
  const updatedContentGuidelines = state.contentGuidelines.map((contentGuideline) => {
    if (contentGuideline.contentGuidelineInstanceId === contentGuidelineInstanceId) {
      return {
        ...contentGuideline,
        requiresBrandedContentTag,
      };
    }
    return contentGuideline;
  });
  const someIgContentRequiresBC = updatedContentGuidelines
    .some((contentGuideline) => instagramContentRequiresBC(
      contentGuideline.type,
      state.featureFlags.MetaBrandedContent && contentGuideline.requiresBrandedContentTag,
    ));

  const someTiktokContentRequiresBC = updatedContentGuidelines
    .some((contentGuideline) => tiktokContentRequiresBC(
      contentGuideline.type,
      state.featureFlags.TiktokSparkAds && contentGuideline.requiresBrandedContentTag,
    ));
  return {
    ...state,
    contentGuidelines: updatedContentGuidelines,
    collaborationDetails: {
      ...state.collaborationDetails,
      bam: {
        ...state.collaborationDetails.bam,
        toggleInstagramInsights: someIgContentRequiresBC || state.collaborationDetails.bam.toggleInstagramInsights,
      },
      adsPermissions: {
        ...state.collaborationDetails.adsPermissions,
        bcPromoteAccess: {
          ...state.collaborationDetails.adsPermissions.bcPromoteAccess,
          toggle: someIgContentRequiresBC ? state.collaborationDetails.adsPermissions.bcPromoteAccess.toggle : false,
        },
        tiktokSparkAdsAccess: {
          ...state.collaborationDetails.adsPermissions.tiktokSparkAdsAccess,
          toggle: (
            someTiktokContentRequiresBC ? state.collaborationDetails.adsPermissions.tiktokSparkAdsAccess.toggle : false
          ),
          duration: (
            someIgContentRequiresBC ? state.collaborationDetails.adsPermissions.tiktokSparkAdsAccess.duration : null
          ),
        },
      },
    },
  };
};
