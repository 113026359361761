import * as React from 'react';
import cx from 'classnames';

import { GraphContainer } from '../utils/GraphContainer';

import { PieChart as PieChartInternal, IPieChartProps as IPieChartPropsInternal } from './PieChart';

export type IPieChartProps = IPieChartPropsInternal;
interface IProps extends IPieChartPropsInternal {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const PieChart: React.FunctionComponent<IProps> = React.memo((props) => {
  const { className, ...restProps } = props;

  return (
    <GraphContainer className={cx(className)}>
      <PieChartInternal {...restProps} />
    </GraphContainer>
  );
});

PieChart.displayName = 'PieChart';
