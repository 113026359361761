import { GraphQLError } from 'graphql';
import { ApolloError } from '@apollo/client';

import { DEFAULT_GENERIC_ERROR_MSG } from '@components';

export function getErrorMessageFromGraphQL(
  error: ApolloError,
  defaultMessage: string = DEFAULT_GENERIC_ERROR_MSG,
) {
  if (error.graphQLErrors) {
    const graphQlError: GraphQLError = error.graphQLErrors[0];
    if (graphQlError) {
      return graphQlError.message;
    }
  }

  return error.message || defaultMessage;
}
