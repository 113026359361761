/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1iffb_157 {
  display: flex;
}

._justify-content-space-between_1iffb_161 {
  justify-content: space-between;
}

._tabular-nums_1iffb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1iffb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._permissionNotesHeader_1iffb_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ContentTile_1iffb_188 {
  padding: 10px 15px;
  border-radius: 6px;
  will-change: background-color;
  transition: background-color 0.15s ease-out;
}
._ContentTile_1iffb_188:hover {
  background-color: #F6F6F6;
}
._ContentTile_1iffb_188:hover ._image_1iffb_197, ._ContentTile_1iffb_188:hover ._video_1iffb_197 {
  filter: brightness(0.5);
}
._ContentTile_1iffb_188:hover ._permission_1iffb_178 {
  visibility: visible !important;
}
._ContentTile_1iffb_188 ._media_1iffb_203 {
  margin-bottom: 0.8rem;
  position: relative;
  min-height: 100px;
  cursor: pointer;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._assignButton_1iffb_209 {
  display: none;
}
._ContentTile_1iffb_188 ._media_1iffb_203:hover ._assignButton_1iffb_209 {
  top: 12px;
  right: 12px;
  position: absolute;
  display: block;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._image_1iffb_197 {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ececec;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._video_1iffb_197 {
  border-radius: 6px;
  border: 1px solid #ececec;
  will-change: filter;
  transition: filter 0.15s ease-out;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._checkbox_1iffb_229 {
  position: absolute;
  top: 4px;
  left: 6px;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._search_1iffb_234 {
  position: absolute;
  top: 0.9rem;
  right: 6.3rem;
  cursor: pointer;
  color: white;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._like_1iffb_241 {
  position: absolute;
  top: 0.9rem;
  right: 3.75rem;
  width: 24px;
  height: 24px;
  transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._like_1iffb_241._liked_1iffb_249 ._favoriteOutline_1iffb_249 {
  opacity: 0;
  fill: rgb(255, 0, 47);
  transition: opacity 0.65s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._like_1iffb_241._liked_1iffb_249 ._favorite_1iffb_249 {
  opacity: 1;
  color: white;
  fill: rgb(255, 0, 47);
  transform: scale(1);
  transition: opacity 0s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.8s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._like_1iffb_241 ._favoriteOutline_1iffb_249 {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  color: white;
  fill: white;
  will-change: opacity;
  transition: opacity 1 0s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._like_1iffb_241 ._favorite_1iffb_249 {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  color: white;
  fill: rgb(255, 0, 47);
  transform: scale(0);
  will-change: opacity;
  transition: opacity 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0s cubic-bezier(0.23, 1, 0.32, 1) 0.45s;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._typeWrapper_1iffb_282 {
  padding: 4px;
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  background-color: white;
  border-radius: 3px;
  cursor: pointer;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._typeWrapper_1iffb_282 ._type_1iffb_282 {
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._typeWrapper_1iffb_282 ._imageType_1iffb_298 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/img_black.svg);
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._typeWrapper_1iffb_282 ._videoType_1iffb_301 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/video_black.svg);
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._permission_1iffb_178 {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: hidden;
  bottom: 1.2rem;
  left: 1rem;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._permission_1iffb_178 ._lockIcon_1iffb_313 {
  margin-right: 5px;
  width: 12px;
  height: 16px;
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/lock_white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
._ContentTile_1iffb_188 ._media_1iffb_203 ._permission_1iffb_178 ._permissionText_1iffb_322 {
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}
._ContentTile_1iffb_188 ._details_1iffb_328 {
  margin-bottom: 0.8rem;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._avatar_1iffb_335 {
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  width: 32px;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._avatar_1iffb_335 ._avatarImage_1iffb_341 {
  width: 32px;
  border-radius: 100px;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._avatar_1iffb_335 ._source_1iffb_345 {
  position: absolute;
  bottom: -7px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  background-color: white;
  border: 1px solid #ececec;
  border-radius: 100px;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._avatar_1iffb_335 ._source_1iffb_345 ._sourceImage_1iffb_358 {
  width: 14px;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._info_1iffb_361 {
  padding-right: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.85rem;
  line-height: 1.5rem;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._info_1iffb_361 ._name_1iffb_372 {
  width: 100%;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._info_1iffb_361 ._name_1iffb_372 a {
  color: #1a1818;
  text-decoration: none;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._info_1iffb_361 ._name_1iffb_372 a:hover {
  color: #4fa7ee;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._info_1iffb_361 ._date_1iffb_389 {
  font-weight: 300;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._actions_1iffb_392 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._actions_1iffb_392 ._chat_1iffb_398 {
  margin-right: 16px;
  width: 20px;
  height: 18px;
  cursor: pointer;
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/chats_dark.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
._ContentTile_1iffb_188 ._details_1iffb_328 ._actions_1iffb_392 ._visit_1iffb_408 {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/linkOut_dark.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
._ContentTile_1iffb_188 ._admin_1iffb_417 {
  position: relative;
  margin: 5px 0 15px 52px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  color: #3996e0;
  will-change: text-shadow;
  transition: text-shadow 0.1s ease-out;
}
._ContentTile_1iffb_188 ._admin_1iffb_417:hover {
  color: #4fa7ee;
}

._TagList_1iffb_432 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

._addTag_1iffb_438 {
  cursor: pointer;
}
._addTag_1iffb_438 ._plusIcon_1iffb_441 {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/plus_black.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

._moreTag_1iffb_451 {
  cursor: pointer;
}
._moreTag_1iffb_451 ._ellipsisIcon_1iffb_454 {
  display: inline-block;
  width: 17px;
  height: 10px;
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/ellipsis_black.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

._addTagText_1iffb_464 {
  display: inline-block;
  margin: 0 3px 5px 0;
  padding: 5px 6px;
  user-select: none;
  color: #B1B1B1;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 0.75rem;
  font-style: italic;
}

._tag_1iffb_476 {
  margin: 0 3px 5px 0;
  padding: 0 6px;
  position: relative;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #ececec;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 0.75rem;
  cursor: pointer;
}
._tag_1iffb_476._visionTag_1iffb_493 {
  background-color: #EEF3FA;
}
._tag_1iffb_476._visionTag_1iffb_493 ._removeTag_1iffb_496 {
  background-color: #EEF3FA;
}
._tag_1iffb_476 ._removeTag_1iffb_496 {
  position: relative;
  padding: 5px 4px 4px 8px;
  background-color: white;
  cursor: pointer;
}
._tag_1iffb_476 ._removeTag_1iffb_496 ._removeTagImage_1iffb_505 {
  width: 11px;
  height: 11px;
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/close_gray.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

._permissionNotesHeader_1iffb_178 {
  color: #fdfdfd;
  margin-bottom: 8px;
}