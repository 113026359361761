import { useQuery, QueryHookOptions } from '@apollo/client';
import GET_BUDGET_PERIOD_DETAILS from '@frontend/app/queries/getBudgetPeriodDetailsQuery';
import { GetBudgetPeriodDetails, GetBudgetPeriodDetailsVariables } from '@frontend/app/queries/types/GetBudgetPeriodDetails';

type IOptions = QueryHookOptions<GetBudgetPeriodDetails, GetBudgetPeriodDetailsVariables>;

export function useGetBudgetPeriodDetails(options: IOptions) {
    const {
        loading, data: { periodDetails } = {}, error, refetch,
    } = useQuery<GetBudgetPeriodDetails, GetBudgetPeriodDetailsVariables>(GET_BUDGET_PERIOD_DETAILS, {
      ...options,
      fetchPolicy: 'no-cache',
    });
    return {
        loading,
        periodDetails,
        error,
        refetch,
    };
}
