import * as React from 'react';
import {
  map,
} from 'lodash';

import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';

import { appConfig } from '@frontend/app/config';

import { debounce } from 'lodash';
import { logger } from '@common';

const {
 useCallback, useEffect, useMemo, useState,
} = React;

const DEFAULT_DEBOUNCE_TIMEOUT = 200; // milliseconds
const PLACES_API_KEY = appConfig.serviceKeys.googlePlacesAPIKey;

const googleMapsLoader = new GoogleMapsLoader({
  apiKey: PLACES_API_KEY,
  version: 'weekly',
  libraries: ['places'],
});

export interface ISimplifiedPlace {
  formattedAddress: string;
  id: string;
  types: string[];
}

interface IUsePlaceSearchArgs {
  search: string;
  debounceMs?: number;
  placeTypes?: string[];
}

interface IUsePlaceSearch {
  isFetching: boolean;
  results: ISimplifiedPlace[];
  error: Error | undefined;
}

const usePlaceSearch = (args: IUsePlaceSearchArgs): IUsePlaceSearch => {
  const {
    search: searchArgument,
    debounceMs = DEFAULT_DEBOUNCE_TIMEOUT,
    placeTypes = ['(regions)'],
  } = args;

  const [isFetching, setIsFetching] = useState(false);
  const [results, setResults] = useState<ISimplifiedPlace[]>([]);
  const [error, setError] = useState<Error | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [googleAPI, setGoogleAPI] = useState<any>();

  useEffect(() => {
    setError(undefined);

    googleMapsLoader
      .load()
      .then((google) => {
        setGoogleAPI(google);
      })
      .catch((err) => {
        logger.error(`Error while initiating GMaps: ${JSON.stringify(err)}`);
        setError(err);
      });
  }, []);

  const placesService = useMemo(() => {
    if (!googleAPI) {
      return;
    }

    /** Google expects this to be initialized with a node */
    return new googleAPI.maps.places.AutocompleteService();
  }, [googleAPI]);

  const handleSearchChange = async (search) => {
    setResults([]);

    if (!search || !placesService) {
      return;
    }

    setError(undefined);
    setIsFetching(true);

    try {
      const request = {
        input: search,
        types: placeTypes,
        fields: ['place_id', 'formatted_address'],
      };

      const simplifiedPlaces: ISimplifiedPlace[] = await new Promise((resolve, reject) => {
        placesService.getPlacePredictions(request, (results, status) => {
          if (status === googleAPI.maps.places.PlacesServiceStatus.OK) {
            const parsedResults = map(results, (result) => ({
              formattedAddress: result.description,
              id: result.place_id,
              types: result.types || [],
            }));

            resolve(parsedResults);
          }
          reject(new Error(`Google Maps unexpected error: ${status}`));
        });
      });

      setResults(simplifiedPlaces);
    } catch (err) {
      logger.error(`Error while fetching places: ${JSON.stringify(err)}`);
      setError(err);
    } finally {
      setIsFetching(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFunction = useCallback(
    debounce(handleSearchChange, debounceMs),
    [placesService, setIsFetching],
  );

  useEffect(() => {
    (() => debounceFunction(searchArgument))();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchArgument, placesService, setIsFetching]);

  return {
    isFetching,
    results,
    error,
  };
};

export default usePlaceSearch;
