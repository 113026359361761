import gql from 'graphql-tag';

export const UPSERT_PROJECT_BUDGET_ACCOUNT_DISTRIBUTION = gql`
    mutation UpsertProjectBudgetAccountDistribution($projectBudgetAccountInput: UpsertBudgetAccountDistributionInput!) {
        projectBudgetAccountDistribution: upsertProjectBudgetAccountDistribution(projectBudgetAccountInput: $projectBudgetAccountInput) {
            budgetId
            budgetName
            budgetDescription
            parentBudgetId
            fiscalYearsBudget
        }
    }
`;
