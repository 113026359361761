import * as React from 'react';

import { Form, Input } from '@revfluence/fresh';
import { ICommonFormElementProps } from '../../../types';

interface IProps extends ICommonFormElementProps<'description'> { }

export const OfferDescription: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your offer description!' },
  ];
  return (
    <Form.Item label="Offer Description" name={name} rules={rules}>
      <Input.TextArea
        autoSize={{ minRows: 4, maxRows: 4 }}
        data-testid="offer-description"
        disabled={disabled}
        placeholder="Briefly describe your offer"
        size="large"
      />
    </Form.Item>
  );
});
