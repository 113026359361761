import React from 'react';
import { Col, Row, Statistic } from '@revfluence/fresh';

import styles from './BriefsStatistics.scss';

export interface IBriefsStatistics {
  agreed: number;
  invalid: number;
  publisher_modified: number;
  manager_modified: number;
  canceled: number;
}

interface IBriefsStatisticsProps {
  statistics: IBriefsStatistics;
}

export const BriefsStatistics: React.FC<IBriefsStatisticsProps> = ({ statistics }) => (
  <div className={styles.BriefsStatistics}>
    <Row justify="space-around">
      <Col><Statistic title="Agreed" value={statistics.agreed} /></Col>
      <Col><Statistic title="Waiting for Agreement" value={statistics.manager_modified} /></Col>
      <Col><Statistic title="Review Edits" value={statistics.publisher_modified} /></Col>
      <Col><Statistic title="Canceled" value={statistics.canceled} /></Col>
    </Row>
  </div>
  );
