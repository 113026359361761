import * as React from 'react';

import {
  Input,
} from '@revfluence/fresh';

export interface IProps {
  intro: string;
  onUpdateIntro: (description: string) => void;
}

const Intro: React.FC<IProps> = React.memo(
  ({
    intro,
    onUpdateIntro,
  }) => (
    <Input.TextArea
      rows={4}
      placeholder="Enter..."
      value={intro}
      onChange={(e) => onUpdateIntro(e.target.value)}
      maxLength={1000}
    />
  ),
);

Intro.displayName = 'Intro';

export default Intro;
