import * as React from 'react';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';

import { Typography, Tooltip, Card as FreshCard } from '@revfluence/fresh';
import { TooltipPlacement } from 'antd/es/tooltip';

import styles from './Card.module.scss';

const { Title, Text } = Typography;

export interface ICardProps {
  title: string;
  subtitle?: string;
  icon?: React.ReactElement;
  tooltip?: string;
  actions?: React.ReactNode[];
  cover?: React.ReactElement;
  bodyStyle?: React.CSSProperties;
  tooltipPlacement?: TooltipPlacement;
}

const Card: React.FC<ICardProps> = React.memo(({
  title,
  subtitle,
  icon, children,
  tooltip,
  actions,
  cover,
  bodyStyle,
  tooltipPlacement,
}) => {
  const renderTitle = (
    <Title level={5}>
      {icon}
      {' '}
      {title}
      {' '}
      <Text type="secondary">{subtitle}</Text>
    </Title>
  );

  const renderTooltip = (
    <Tooltip
      title={tooltip}
      placement={tooltipPlacement || 'bottomRight'}
    >
      <span className={styles.icon}>
        <CircleInfoIcon />
      </span>
    </Tooltip>
  );

  return (
    <FreshCard
      actions={actions}
      className={styles.card}
      title={renderTitle}
      bodyStyle={bodyStyle}
      cover={cover}
      headStyle={{ border: 'none' }}
      extra={
        tooltip
          ? renderTooltip
          : undefined
      }
    >
      {children}
    </FreshCard>
  );
});

export default Card;
Card.displayName = 'Card';
