import * as React from 'react';
import { connect } from 'react-redux';
import Bluebird from 'bluebird';
import cx from 'classnames';
import {
 find, isEqual, isEmpty, isFunction, isNil, map,
} from 'lodash';

import {
  IRouter,
  LayoutGridIcon,
  LayoutListIcon,
} from '@components';
import { CreatorDetailOverlay } from '@components';
import { LoadSpinner } from '@components';
import { Notice } from '@components';
import { Pagination } from '@components';
import { Progress } from '@components';
import { IconSelect } from '@components';
import { SocialProfileOverlay } from '@components';
import { ITableConfig } from '@components';
import { IToastRefHandles, Toast } from '@components';
import { willShowSPv2 } from '@frontend/utils';

import { ICampaign } from '@components';
import { IOrganization } from '@components';
import { ISocialAccount } from '@components';
import { IFavoriteList } from '@components';
import { hasFeature } from '@components';
import { DetailedSocialAccountCaller } from '@frontend/components/widgets/SocialProfile/hooks/useDetailedSocialAccount';
import { IGridViewRef, GridView } from './GridView';
import { ITableViewRef, TableView } from './TableView';

/**
 * types
 */
import { ICreatorList, ILoadingStatus } from './redux/creatorListModel';
import creatorListActions, { FLPThunkDispatch } from './redux/creatorListActions';

import styles from './CreatorList.scss';

export interface IOwnProps {
  $state: IRouter;
  title?: string | JSX.Element;
  listHeader?: string | JSX.Element;
  loadingStatus: ILoadingStatus;
  emptyMessage?: string | JSX.Element;
  customTableConfig?: ITableConfig;
  searchedMentions?: string;

  sendBulkOffer(socialAccounts: ISocialAccount[]);
  exportCsv(socialAccounts: ISocialAccount[]);
  reportAsIncorrect(accountName: string);

  onCreatorSelected?(socialAccount: ISocialAccount);
  selectedSocialAccountId?: number | null;

  onReachedBottom?(): void;
  onSelectPage?(pageNumber: number);

  showPagination?: boolean;
  pageCount?: number;
  selectedPage?: number;

  initialDisplayMode?: TDisplayMode;
  initialSocialAccounts: ISocialAccount[];
  selectedSocialAccounts?: ISocialAccount[];
  onSelectedAccountsChange?: (socialAccounts?: ISocialAccount[]) => void;
  showCreateFeatures?: boolean;
  reloadAccountDetails?: boolean;
  showRelevantPostImage?: boolean;
  showSocialProfileOnCreatorSelect?: boolean;
  classNames?: string[];
  apiEndpoint: string;
  clientId?: string;
  shouldShowDemoFeatures?: boolean;
}
type TDefaultProp = 'onReachedBottom' | 'classNames' | 'emptyMessage' | 'showSocialProfileOnCreatorSelect';
export interface IInitialStateProps {
  isQa: boolean;
  brandId: string | number;
  campaign: ICampaign;
  org: IOrganization;
  selectedSocialAccounts?: ISocialAccount[];
}

interface IStateProps extends IInitialStateProps {
  socialAccounts: ISocialAccount[];
  favoriteLists: IFavoriteList[];
}
interface IDispatchProps {
  setSocialAccounts(socialAccounts: ISocialAccount[]): void;
  fetchFavoriteList(): Promise<IFavoriteList[]>;
  inviteToCampaign(socialAccount: ISocialAccount): Promise<void>;
  createFavoriteList(name: string): Promise<void>;
  addToFavoriteList(accountId: number, listId: number): Promise<void>;
  renameFavoriteList(id: number, name: string): Promise<void>;
  deleteFavoriteList(id: number): Promise<void>;
}
type IProps = IOwnProps & IStateProps & IDispatchProps;

type TDisplayMode = 'grid' | 'table' | 'post';
interface IState {
  selectedMode: TDisplayMode;

  // keep a copy of social account ids
  // and call setSocialAccounts when socialAccounts changed
  accountIds: number[];
  // detail view
  prevPropSelectedSocialAccountId: number | null; // Will track prop changes, if controlled from outside the widget
  selectedSocialAccountId: number | null;
  showDetailView: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
class CreatorList extends React.Component<IProps, IState> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    onReachedBottom: () => undefined,
    classNames: [],
    emptyMessage: 'You have no creators in this list.',
    showSocialProfileOnCreatorSelect: true,
  };

  private toastRef: React.RefObject<IToastRefHandles>;

  private gridRef: React.RefObject<IGridViewRef>;

  private tableRef: React.RefObject<ITableViewRef>;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.toastRef = React.createRef();
    this.gridRef = React.createRef();
    this.tableRef = React.createRef();

    this.state = {
      selectedMode: props.initialDisplayMode || 'grid',
      accountIds: map(props.initialSocialAccounts, (socialAccount) => socialAccount.id).sort(),
      // Show detail view
      prevPropSelectedSocialAccountId: null,
      selectedSocialAccountId: props.selectedSocialAccountId,
      showDetailView: !isNil(props.selectedSocialAccountId),
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const newState: Partial<IState> = {};

    // update social accounts in redux store if needed
    const nextAccountIds = map(
      props.initialSocialAccounts,
      (socialAccount) => socialAccount.id,
    ).sort();
    if (!isEqual(state.accountIds, nextAccountIds)) {
      props.setSocialAccounts(props.initialSocialAccounts);
      newState.accountIds = nextAccountIds;
    }

    // NOTE: Antonn: selectedSocialAccountId set via props means it's being controlled from outside
    // Therefore, prioritize that ID and show detail view
    if (props.selectedSocialAccountId !== state.prevPropSelectedSocialAccountId) {
      newState.prevPropSelectedSocialAccountId = state.selectedSocialAccountId;
      newState.selectedSocialAccountId = props.selectedSocialAccountId;
    } else {
      newState.selectedSocialAccountId = state.selectedSocialAccountId;
    }

    return !isEmpty(newState) ? newState : null;
  }

  /**
   * @inheritdoc
   */
  public componentDidMount() {
    const { fetchFavoriteList } = this.props;

    if (this.props.org) {
      fetchFavoriteList();
    }
  }

  /**
   * @private
   * Renders the creator list view.
   */
  public render() {
    const {
      apiEndpoint,
      campaign,
      classNames,
      emptyMessage,
      exportCsv,
      listHeader,
      loadingStatus,
      onSelectPage,
      org,
      pageCount,
      reportAsIncorrect,
      selectedPage,
      showCreateFeatures = true,
      sendBulkOffer,
      showPagination,
      showRelevantPostImage,
      socialAccounts,
      title,
    } = this.props;
    const { selectedMode } = this.state;

    const allowFavorite = hasFeature(org, 'advanced_connect');
    const selfServeExperiment = hasFeature(org, 'self_serve_experiment');
    const showShowProgress = loadingStatus.showProgress && loadingStatus.total > 0;
    const showEmptyNotice = !loadingStatus.isLoading && isEmpty(socialAccounts);
    return (
      <div
        className={cx(
          styles.CreatorList,
          ...classNames,
          {
            [styles.tableMode]: selectedMode === 'table',
          },
        )}
      >
        <div className={styles.header}>
          {title && <div className={styles.title}>{title}</div>}
          {this.renderModeSelection()}
        </div>
        {listHeader && <div>{listHeader}</div>}

        {/* Loading */}
        {loadingStatus.isLoading && (
          showShowProgress
            ? (
              <Progress
                percentage={Math.round((socialAccounts.length / loadingStatus.total) * 100)}
                type="info"
                label={`Fetching creators...(${socialAccounts.length}/${loadingStatus.total})`}
                className={styles.progress}
              />
)
            : <LoadSpinner className={cx(styles.loadSpinner)} />
        )}

        {/* List body */}
        <>
          {selectedMode === 'grid' && (
            <GridView
              className={styles.gridView}
              ref={this.gridRef}
              allowFavorite={allowFavorite}
              apiEndpoint={apiEndpoint}
              campaign={campaign}
              goToManage={this.goToManage}
              inviteToCampaign={this.inviteToCampaign}
              onCreatorSelected={this.onCreatorSelected}
              onReachBottom={this.props.onReachedBottom}
              reportAsIncorrect={reportAsIncorrect}
              sendOffer={this.sendOffer}
              showRelevantPostImage={showRelevantPostImage}
              showCreateFeatures={showCreateFeatures}
              selfServeExperiment={selfServeExperiment}
              searchedMentions={this.props.searchedMentions}
            />
          )}
          {selectedMode === 'table' && (
            showCreateFeatures
              ? (
                <TableView
                  onCreatorSelected={this.onCreatorSelected}
                  goToManage={this.goToManage}
                  sendBulkOffer={sendBulkOffer}
                  exportCsv={exportCsv}
                  onReachBottom={this.props.onReachedBottom}
                  customConfig={this.props.customTableConfig}
                  showCreateFeatures
                />
              )
              : (
                <TableView
                  className={styles.tableView}
                  ref={this.tableRef}
                  onCreatorSelected={this.onCreatorSelected}
                  goToManage={this.goToManage}
                  sendBulkOffer={sendBulkOffer}
                  exportCsv={exportCsv}
                  onReachBottom={this.props.onReachedBottom}
                  customConfig={this.props.customTableConfig}
                  toastRef={this.toastRef}
                  showCreateFeatures={false}
                  onSelectedRowsChange={this.props.onSelectedAccountsChange}
                  selectedSocialAccounts={this.props.selectedSocialAccounts}
                />
              )
          )}
        </>

        {showEmptyNotice && (
          <Notice className={styles.notice} type="disabled">
            {emptyMessage}
          </Notice>
        )}
        {showPagination && !showEmptyNotice && (
          <Pagination
            className={styles.pagination}
            currentPage={selectedPage}
            startPage={1}
            endPage={pageCount}
            pagesDisplayed={10}
            onSelectPage={onSelectPage}
          />
        )}

        {/* Overlays, etc */}
        {this.renderDetailOverlay()}
        <Toast useFresh ref={this.toastRef} />
      </div>
    );
  }

  private renderDetailOverlay = () => {
    const {
      apiEndpoint,
      clientId,
      campaign,
      org,
      isQa,
      favoriteLists,
      socialAccounts,
      reportAsIncorrect,
      showCreateFeatures = !isNil(this.props.org),
      showSocialProfileOnCreatorSelect,
      reloadAccountDetails,
    } = this.props;
    const { showDetailView, selectedSocialAccountId } = this.state;

    const selectedSocialAccount = find(
      socialAccounts,
      (socialAccount) => socialAccount.id === selectedSocialAccountId,
    );
    const allowFavorite = hasFeature(org, 'advanced_connect');
    const selfServeExperiment = hasFeature(org, 'self_serve_experiment');
    const show = (
      showDetailView && showSocialProfileOnCreatorSelect
      && (!isNil(selectedSocialAccountId) || !isEmpty(selectedSocialAccount))
    );

    // selectedSocialAccountId not found, let onCreatorSelected know
    if (selectedSocialAccountId && isEmpty(selectedSocialAccount)) {
      this.onCreatorSelected(null);
    }

    return willShowSPv2(selectedSocialAccount)
      ? (
        <SocialProfileOverlay
          campaign={campaign}
          socialAccount={selectedSocialAccount}
          inviteToCampaign={this.inviteToCampaign}
          loadAccountDetails={reloadAccountDetails}
          sendOffer={this.sendOffer}
          goToManage={this.goToManage}
          allowFavorite={allowFavorite}
          show={show}
          isQa={isQa}
          apiEndpoint={apiEndpoint}
          clientId={clientId}
          showSimilarCreators
          toastRef={this.toastRef}
          reportAsIncorrect={reportAsIncorrect}
          onRequestClose={this.closeDetailView}
          showCreateFeatures={showCreateFeatures}
          selfServeExperiment={selfServeExperiment}
          caller={DetailedSocialAccountCaller.CREATOR_SEARCH}
        />
      )
      : (
        <CreatorDetailOverlay
          addToFavoriteList={this.addToFavoriteList}
          allowFavorite={allowFavorite}
          apiEndpoint={apiEndpoint}
          campaign={campaign}
          createFavoriteList={this.createFavoriteList}
          favoriteLists={favoriteLists}
          goToManage={this.goToManage}
          inviteToCampaign={this.inviteToCampaign}
          isQa={isQa}
          loadDetail
          loadRelated
          onRequestClose={this.closeDetailView}
          org={org}
          reportAsIncorrect={reportAsIncorrect}
          sendOffer={this.sendOffer}
          show={show}
          socialAccount={selectedSocialAccount}
          selfServeExperiment={selfServeExperiment}
          showCreateFeatures={showCreateFeatures}
        />
      );
  };

  /**
   * @private
   * Renders the mode selection.
   */
  private renderModeSelection = () => {
    if (this.props.shouldShowDemoFeatures) {
      return null;
    }

    const { selectedMode } = this.state;

    return (
      <div className={styles.mode}>
        <IconSelect
          onChange={(option) => this.selectMode(option as TDisplayMode)}
          defaultSelectedOption="grid"
          selectedOption={selectedMode}
          options={[
            {
              icon: <LayoutGridIcon size={14} />,
              value: 'grid',
            },
            {
              icon: <LayoutListIcon size={14} />,
              value: 'table',
            },
          ]}
        />
      </div>
    );
  };

  private goToManage = (
    relationId: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const { $state } = this.props;
    const { metaKey } = event;

    const url = $state.href('manage.relationship.main.mainView', {
      relationId,
    });

    if (metaKey) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  };

  private inviteToCampaign = (socialAccount: ISocialAccount) => {
    const { socialAccounts, inviteToCampaign } = this.props;
    const toast = this.toastRef.current;
    const socialAccountToBeInvited = socialAccounts.find(
      (account) => account.id === socialAccount.id,
    );

    return inviteToCampaign(socialAccountToBeInvited)
      .then(() => {
        if (toast) {
          toast.showMessage({
            type: 'success',
            content: (
              <div>
                Invited creator
                {socialAccountToBeInvited && (
                  <span>
                    {' '}
                    {socialAccountToBeInvited.name || socialAccountToBeInvited.username}
                    {' '}
                  </span>
                )}
                to campaign.
              </div>
            ),
          });
        }
      })
      .catch(() => {
        if (toast) {
          toast.showMessage({
            content: 'There was an error when trying to invite the creator to campaign.',
            type: 'error',
          });
        }
      });
  };

  private sendOffer = (accountId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { $state, campaign } = this.props;
    const { metaKey } = event;

    const url = $state.href('new_project.terms', {
      offerType: 'offer',
      offerableId: accountId,
      campaignId: campaign && campaign.id,
    });

    if (metaKey) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  };

  private addToFavoriteList = (accountId: number, listId: number) => {
    const { socialAccounts, favoriteLists, addToFavoriteList } = this.props;
    const toast = this.toastRef.current;
    const socialAccount = socialAccounts.find((socialAccount) => socialAccount.id === accountId);
    const favoriteList = favoriteLists.find((favoriteList) => favoriteList.id === listId);

    return new Bluebird((resolve, reject) => addToFavoriteList(accountId, listId)
        .then(() => {
          if (toast) {
            toast.showMessage({
              type: 'success',
              content: (
                <div>
                  Added creator
                  {socialAccount && (
                    <span>
                      {' '}
                      {socialAccount.name || socialAccount.username}
                      {' '}
                    </span>
                  )}
                  {' '}
                  to favorite list
                  {' '}
                  <span>{favoriteList.name}</span>
                  .
                </div>
              ),
            });
          }

          resolve();
        })
        .catch(() => {
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to add creator to the favorite list.',
              type: 'error',
            });
          }

          reject();
        }));
  };

  private createFavoriteList = (name: string) => {
    const { createFavoriteList } = this.props;
    const toast = this.toastRef.current;

    return new Bluebird((resolve, reject) => createFavoriteList(name)
        .then(() => {
          if (toast) {
            toast.showMessage({
              type: 'success',
              content: (
                <div>
                  Created new favorite list
                  {' '}
                  <span>{name}</span>
                  .
                </div>
              ),
            });
          }

          resolve();
        })
        .catch(() => {
          if (toast) {
            toast.showMessage({
              content: 'There was an error when trying to create new favorite list.',
              type: 'error',
            });
          }

          reject(new Error());
        }));
  };

  private onCreatorSelected = (socialAccount: ISocialAccount) => {
    if (this.props.shouldShowDemoFeatures) {
      window.open(socialAccount.link);
      return;
    }

    this.setState({
      selectedSocialAccountId: !isEmpty(socialAccount) ? socialAccount.id : null,
      showDetailView: true,
    });

    if (isFunction(this.props.onCreatorSelected)) {
      this.props.onCreatorSelected(socialAccount);
    }
  };

  private closeDetailView = () => {
    this.setState({
      showDetailView: false,
    });

    if (isFunction(this.props.onCreatorSelected)) {
      this.props.onCreatorSelected(null);
    }

    this.refreshProgramsList();
  };

  /**
   * Change the display mode.
   */
  private selectMode = (selectedMode: TDisplayMode) => {
    this.setState({
      selectedMode,
    });
  };

  /**
   * Force rerender to update programs list
   */
  private refreshProgramsList = () => {
    const { showCreateFeatures } = this.props;
    const { selectedMode } = this.state;
    if (showCreateFeatures) {
      return;
    }
    if (
      selectedMode === 'grid'
      && this.gridRef
      && this.gridRef.current
      && isFunction(this.gridRef.current.forceUpdate)
    ) {
      this.gridRef.current.forceUpdate();
    } else if (
      selectedMode === 'table'
      && this.tableRef
      && this.tableRef.current
      && isFunction(this.tableRef.current.forceUpdate)
    ) {
      this.tableRef.current.forceUpdate();
    }
  };
}

const mapStateToProps = (state: ICreatorList): IStateProps => ({
    isQa: state.isQa,
    brandId: state.brandId,
    campaign: state.campaign,
    org: state.org,

    socialAccounts: state.socialAccounts,
    favoriteLists: state.favoriteLists,
  });
const mapDispatchToProps = (dispatch: FLPThunkDispatch): IDispatchProps => ({
    setSocialAccounts: (...args) => dispatch(creatorListActions.setSocialAccounts(...args)),
    fetchFavoriteList: (...args) => dispatch(creatorListActions.fetchFavoriteList(...args)),
    inviteToCampaign: (...args) => dispatch(creatorListActions.inviteToCampaign(...args)),
    createFavoriteList: (...args) => dispatch(creatorListActions.createFavoriteList(...args)),
    addToFavoriteList: (...args) =>
      dispatch(creatorListActions.addSocialAccountsToFavoriteList(...args)),
    renameFavoriteList: (...args) => dispatch(creatorListActions.renameFavoriteList(...args)),
    deleteFavoriteList: (...args) => dispatch(creatorListActions.deleteFavoriteList(...args)),
  });

export default connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreatorList);
