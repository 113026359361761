import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const ChevronLeftIcon = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 6 10"
    >
      <path
        d="
        M4.81055 9.5C4.59961 9.5 4.41211 9.42969 4.27148 9.28906L0.521484 5.53906C0.216797 5.25781 0.216797 4.76562 0.521484 4.48438L4.27148 0.734375C4.55273 0.429688 5.04492 0.429688 5.32617 0.734375C5.63086 1.01562 5.63086 1.50781 5.32617 1.78906L2.11523 5L5.32617 8.23438C5.63086 8.51562 5.63086 9.00781 5.32617 9.28906C5.18555 9.42969 4.99805 9.5 4.81055 9.5Z
        "
      />
      <mask
        id={id}
        maskUnits="userSpaceOnUse"
      >
        <path
          d="
          M4.81055 9.5C4.59961 9.5 4.41211 9.42969 4.27148 9.28906L0.521484 5.53906C0.216797 5.25781 0.216797 4.76562 0.521484 4.48438L4.27148 0.734375C4.55273 0.429688 5.04492 0.429688 5.32617 0.734375C5.63086 1.01562 5.63086 1.50781 5.32617 1.78906L2.11523 5L5.32617 8.23438C5.63086 8.51562 5.63086 9.00781 5.32617 9.28906C5.18555 9.42969 4.99805 9.5 4.81055 9.5Z
          "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

ChevronLeftIcon.displayName = 'ChevronLeftIcon';

export { ChevronLeftIcon };
