import { TSTAPayment } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
  staPayments: TSTAPayment[];
  type: ActionType.SET_STA_PAYMENTS;
}
export type TSetSTAPaymentsAction = Readonly<IAction>;

export const setSTAPayments = (state: TState, action: TSetSTAPaymentsAction): TState => ({
    ...state,
    staPayments: action.staPayments,
  });
