/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_zgg15_157 {
  display: flex;
}

._justify-content-space-between_zgg15_161 {
  justify-content: space-between;
}

._tabular-nums_zgg15_165, ._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._Card_zgg15_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_zgg15_170 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_zgg15_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Card_zgg15_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Card_zgg15_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Card_zgg15_170 ::-webkit-scrollbar-track, ._Card_zgg15_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Card_zgg15_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_zgg15_165,
._audienceAgeBarChart_zgg15_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_zgg15_165 > [class*=list],
._audienceAgeBarChart_zgg15_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_zgg15_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_zgg15_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_zgg15_306 {
  cursor: help;
}

._Card_zgg15_170 {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  break-inside: avoid-column;
  box-sizing: border-box;
}
._Card_zgg15_170 ._cardHeader_zgg15_320 {
  margin-top: -0.5rem;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._Card_zgg15_170 ._cardHeader_zgg15_320:empty {
  display: none;
  margin: unset;
}
._Card_zgg15_170 ._tabs_zgg15_331 {
  margin-right: -0.5rem;
  align-self: flex-end;
}
._Card_zgg15_170 ._tabs_zgg15_331 ._tabButton_zgg15_335 {
  min-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  letter-spacing: 0.0625rem;
}
._Card_zgg15_170 ._tabs_zgg15_331 ._tabButton_zgg15_335, ._Card_zgg15_170 ._tabs_zgg15_331 ._tabButton_zgg15_335:hover {
  background-color: transparent;
}
._Card_zgg15_170 ._tabs_zgg15_331 ._tabButton_zgg15_335:hover > div {
  color: #3996e0;
}
._Card_zgg15_170 ._tabs_zgg15_331 ._tabButton_zgg15_335 > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aeaeae;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Card_zgg15_170 ._tabs_zgg15_331 ._tabButton_zgg15_335._activeTab_zgg15_354 > div {
  color: #3996e0;
}

._separator_zgg15_358 {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  height: 0.0625rem;
  border-bottom: 0.0625rem solid #eff5f9;
}

._cardTab_zgg15_365 {
  display: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._cardTitle_zgg15_373 {
  margin: 0 auto 0 0;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

._visible_zgg15_381 {
  display: flex !important;
  animation: _show_zgg15_1 0.4s;
}

._noData_zgg15_386::after {
  position: absolute;
  top: calc(50% + 0.6666666667rem);
  left: 50%;
  display: block;
  color: #8f8d91;
  content: "No data yet";
  transform: translate(-50%, -50%);
}

._EngagementRateCard_zgg15_396 ._betaBadge_zgg15_396 {
  margin-left: 0.75rem;
  padding: 0.25rem 0.5rem 0.3125rem;
  font-size: 0.625rem;
  line-height: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.0625rem;
}
._EngagementRateCard_zgg15_396 ._footer_zgg15_404 {
  width: 100%;
  height: 2.25rem;
  display: block;
  text-align: center;
  color: rgba(26, 24, 24, 0.6);
  font-size: 0.75rem;
}
._EngagementRateCard_zgg15_396 ._footer_zgg15_404 span {
  display: inline;
  font-weight: 600;
}
._EngagementRateCard_zgg15_396 ._organicTab_zgg15_416,
._EngagementRateCard_zgg15_396 ._sponsoredTab_zgg15_417 {
  display: relative;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 {
  width: 100%;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 {
  margin-left: -0.625rem;
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-rows: repeat(2, 1rem);
  grid-column-gap: 0.375rem;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._image_zgg15_430 {
  width: 2rem;
  height: 2rem;
  grid-column: 1/2;
  grid-row: 1/2 span;
  border-radius: 624.9375rem;
  box-shadow: 0 0.25rem 0.5rem rgba(26, 24, 24, 0.15);
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._image_zgg15_430._aiqLogo_zgg15_438 {
  object-fit: cover;
  object-position: 0 0;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._username_zgg15_442 {
  margin: 0;
  grid-column: 2;
  grid-row: 1;
  font-size: 0.75rem;
  font-weight: 600;
  width: 7.5rem;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 {
  grid-column: 2;
  grid-row: 2;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._percentage_zgg15_454 {
  color: #3996e0;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._description_zgg15_457 {
  margin-left: 0.25rem;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._description_zgg15_457._higher_zgg15_460, ._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._description_zgg15_457._lower_zgg15_460 {
  text-transform: capitalize;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._description_zgg15_457._higher_zgg15_460 {
  color: #4eb468;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._description_zgg15_457._lower_zgg15_460 {
  color: #f1515f;
}
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._username_zgg15_442,
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._percentage_zgg15_454,
._EngagementRateCard_zgg15_396 ._chart_zgg15_420 ._barInfo_zgg15_423 ._value_zgg15_450 ._description_zgg15_457 {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.8888888889rem;
  vertical-align: text-top;
  white-space: nowrap;
}