/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1l5gy_157 {
  display: flex;
}

._justify-content-space-between_1l5gy_161 {
  justify-content: space-between;
}

._tabular-nums_1l5gy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1l5gy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ConversionTrackingPane_1l5gy_178 ._title_1l5gy_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._ConversionTrackingPane_1l5gy_178 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 30rem;
}
._ConversionTrackingPane_1l5gy_178 ._title_1l5gy_178 {
  color: #1a1818;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1366px) and (min-width: 767px) {
  ._ConversionTrackingPane_1l5gy_178 {
    justify-content: flex-start;
  }
}