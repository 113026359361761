import { AutomationExecutionStatus } from '@frontend/app/types/globalTypes';

export const AUTOMATION_EXECUTION_ENDED_STATUSES: AutomationExecutionStatus[] = [
    AutomationExecutionStatus.TERMINATED,
    AutomationExecutionStatus.FAILED,
    AutomationExecutionStatus.COMPLETED,
  ];

  export const AUTOMATION_EXECUTION_TERMINABLE_STATUSES: AutomationExecutionStatus[] = [
    AutomationExecutionStatus.PENDING,
    AutomationExecutionStatus.RUNNING,
  ];
