import { useEffect, useState } from 'react';

export const BREAKPOINTS = {
    // Should match the values in tailwind config for consistency
    'xs': 0,
    'sm': 640,
    'md': 768,
    'lg': 1024,
    'xl': 1280,
    '2xl': 1400,
};

export interface BreakpointResponse {
    'xs': boolean;
    'sm': boolean;
    'md': boolean;
    'lg': boolean;
    'xl': boolean;
    '2xl': boolean;
}

const initialResponse: BreakpointResponse = {
    'xs': false,
    'sm': false,
    'md': false,
    'lg': false,
    'xl': false,
    '2xl': false,
};

const useBreakpoint = () => {
    const body = document.body;
    const [activeBreakpoint, setActiveBreakpoint] = useState<BreakpointResponse>(initialResponse);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const width = entry.borderBoxSize[0].inlineSize;
                if (width < BREAKPOINTS.sm) {
                    setActiveBreakpoint({ ...initialResponse, xs: true });
                    break;
                }

                if (width < BREAKPOINTS.md) {
                    setActiveBreakpoint({ ...initialResponse, xs: true, sm: true });
                    break;
                }

                if (width < BREAKPOINTS.lg) {
                    setActiveBreakpoint({
 ...initialResponse, xs: true, sm: true, md: true,
});
                    break;
                }

                if (width < BREAKPOINTS.xl) {
                    setActiveBreakpoint({
 ...initialResponse, xs: true, sm: true, md: true, lg: true,
});
                    break;
                }

                if (width < BREAKPOINTS['2xl']) {
                    setActiveBreakpoint({
 ...initialResponse, xs: true, sm: true, md: true, lg: true, xl: true,
});
                    break;
                }

                if (width >= BREAKPOINTS['2xl']) {
                    setActiveBreakpoint({
 ...initialResponse, 'xs': true, 'sm': true, 'md': true, 'lg': true, 'xl': true, '2xl': true,
});
                    break;
                }
            }
        });

        observer.observe(body);

        return () => {
            observer.unobserve(body);
            observer.disconnect();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return activeBreakpoint;
};

export default useBreakpoint;
