import {
  isEmpty, map, some, trim,
} from 'lodash';

import { IDispatchAction } from '@frontend/app/components/WizardV2/types';
import { CREATOR_OFFERS } from '@frontend/app/containers/Projects/ProjectsPage/ListOnMarketplacePage/context/model';
import { SPECIAL_CHARS_REGEX } from './components/FormTextInput';

import {
  OnboardingStep,
  IOnboardingState,
  OnboardingDispatchActionType,
  ISetupInput,
  IRequirementsInput,
  IMarketplaceListingInput,
  ObjectiveOption,
  ProjectType,
  IObjectiveInput,
} from './types';

// binary string represents the boolean values for brand choices in order:
// hasBudget, hasHighValueProduct, hasRecognition
const brandInfoInputProjectTypeMapper = {
  '000': ProjectType.ProductSeeding,
  '001': ProjectType.CustomProject,
  '010': ProjectType.ProductSeeding,
  '011': ProjectType.ProductSeeding,
  '100': ProjectType.InfluencerCampaign,
  '101': ProjectType.InfluencerCampaign,
  '110': ProjectType.InfluencerCampaign,
  '111': ProjectType.InfluencerCampaign,
};

export const initOnboardingState: IOnboardingState = {
  [OnboardingStep.Objective]: {
    data: {
      objective: ObjectiveOption.IncreaseVisibility,
      hasBudget: 0,
      hasHighValueProduct: 0,
      hasRecognition: 0,
    },
    hasError: false,
    isCompleted: false,
  },
  [OnboardingStep.Setup]: {
    data: {
      projectType: ProjectType.ProductSeeding,
      title: '',
      summary: '',
      description: '',
      preselectedProjectType: ProjectType.ProductSeeding,
    },
    hasError: false,
    isCompleted: false,
  },
  [OnboardingStep.Requirements]: {
    data: {
      product_types: [],
      accepted_place_ids: [],
      offers_payment: false,
      offers_product: false,
      offers_commission: false,
      offers_other: false,
    },
    hasError: false,
    isCompleted: false,
  },
  [OnboardingStep.MarketplaceListing]: {
    data: {
      marketplace_image_url: '',
      marketplace_image_thumbnail: '',
      logo_url: '',
      logo_thumbnail: '',
      hero_image_url: '',
      hero_image_thumbnail: '',
      brand_image_1_url: '',
      brand_image_1_thumbnail: '',
      brand_image_2_url: '',
      brand_image_2_thumbnail: '',
      brand_image_3_url: '',
      brand_image_3_thumbnail: '',
    },
    hasError: false,
    isCompleted: false,
  },
  [OnboardingStep.Preview]: {
    data: {},
    hasError: false,
    isCompleted: true,
  },
};

const isObjectiveCompleted = (updatedState: IOnboardingState) => {
  const data: IObjectiveInput = updatedState[OnboardingStep.Objective].data;
  return (
    !isEmpty(data.brandCategory)
  );
};

const isIntroductionCompleted = (updatedState: IOnboardingState) => {
  const data: ISetupInput = updatedState[OnboardingStep.Setup].data;
  return (
    !isEmpty(trim(data.description))
    && !isEmpty(data.projectType)
    && !isEmpty(trim(data.title))
    && !isEmpty(trim(data.summary))
    && !SPECIAL_CHARS_REGEX.test(data.title)
  );
};

const isRequirementsCompleted = (updatedState: IOnboardingState) => {
  const data: IRequirementsInput = updatedState[OnboardingStep.Requirements].data;
  if (isEmpty(data?.product_types)) {
    return false;
  }

  const creatorOfferKeys = map(CREATOR_OFFERS, (offer) => offer.key);
  return some(creatorOfferKeys, (key) => data[key] === true);
};

const isMarketpkaceListingCompleted = (updatedState: IOnboardingState) => {
  const data: IMarketplaceListingInput = updatedState[OnboardingStep.MarketplaceListing].data;
  if (
    isEmpty(data?.marketplace_image_url)
    || isEmpty(data?.logo_url)
    || isEmpty(data?.hero_image_url)
    || isEmpty(data?.brand_image_1_url)
    || isEmpty(data?.brand_image_2_url)
    || isEmpty(data?.brand_image_3_url)
  ) {
    return false;
  }

  return true;
};

const isStepCompleted = (step: string, updatedState: IOnboardingState) => {
  if (step === OnboardingStep.Objective) {
    return isObjectiveCompleted(updatedState);
  }

  if (step === OnboardingStep.Setup) {
    return isIntroductionCompleted(updatedState);
  }

  if (step === OnboardingStep.Requirements) {
    return isRequirementsCompleted(updatedState);
  }

  if (step === OnboardingStep.MarketplaceListing) {
    return isMarketpkaceListingCompleted(updatedState);
  }

  return true;
};

const handleUpdateFieldValue = (state: IOnboardingState, action: IDispatchAction) => {
  const step = action.payload?.step;
  const field = action.payload?.field;

  const updatedState: IOnboardingState = {
    ...state,
    [step]: {
      ...state[step],
      data: {
        ...state[step].data,
        [field]: action.payload?.value,
      },
    },
  };

  const isCompleted = isStepCompleted(step, updatedState);
  return {
    ...updatedState,
    [step]: {
      ...updatedState[step],
      isCompleted,
    },
  };
};

const handlePreselectProjectType = (state: IOnboardingState) => {
  const {
 data: {
    hasBudget,
    hasHighValueProduct,
    hasRecognition,
  },
} = state.objective;

  const brandInfoInput = [hasBudget, hasHighValueProduct, hasRecognition].join('');

  const preselectedProjectType = brandInfoInputProjectTypeMapper[brandInfoInput];

  return {
    ...state,
    setup: {
      ...state.setup,
      data: {
        ...state.setup.data,
        projectType: preselectedProjectType,
        preselectedProjectType,
      },
    },
  };
};

export const OnboardingReducer = (state: IOnboardingState, action: IDispatchAction) => {
  switch (action.type) {
    case OnboardingDispatchActionType.UpdateFieldValue:
      return handleUpdateFieldValue(state, action);
    case OnboardingDispatchActionType.PreselectProjectType:
      return handlePreselectProjectType(state);
    default:
      return state;
  }
};
