import {
  QueryHookOptions,
  useQuery,
} from '@apollo/client';

import { LIST_TIKTOK_SETTINGS_ACCOUNTS } from '@frontend/app/queries';
import {
  ListTikTokSettingsAccountsQuery,
  ListTikTokSettingsAccountsQueryVariables,
} from '@frontend/app/queries/types/ListTikTokSettingsAccountsQuery';

type IOptions = QueryHookOptions<
  ListTikTokSettingsAccountsQuery,
  ListTikTokSettingsAccountsQueryVariables
>;

export const useListTikTokSettingsAccountsQuery = (options: IOptions = {}) => (
  useQuery<
    ListTikTokSettingsAccountsQuery,
    ListTikTokSettingsAccountsQueryVariables
  >(LIST_TIKTOK_SETTINGS_ACCOUNTS, options)
);

export type TUseListTikTokSettingsAccountsQuery = ReturnType<typeof useListTikTokSettingsAccountsQuery>;
