import * as React from 'react';
import {
  Col,
  Form,
  Input,
  Row,
} from '@revfluence/fresh';

import { UseNaturalNumbers } from '@frontend/applications/AffiliatesApp/common-utils/useNaturalNumbers';
import { IShopifyFormElementProps } from '../../../types';
import { validateNonNegativeDecimal } from '../../../utils';

interface IProps extends IShopifyFormElementProps<'priceRuleAmount'> { }

export const OfferCodeDiscountAmount: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const rules = [
    { required: true, message: 'Please input your discount amount!' },
    { validator: validateNonNegativeDecimal },
  ];
  return (
    <Row align="middle">
      <Col span={24}>
        <Form.Item label="Discount Value" name={name} rules={rules}>
          <Input
            disabled={disabled}
            onKeyDown={UseNaturalNumbers}
            prefix="$"
            size="large"
            type="number"
          />
        </Form.Item>
      </Col>
    </Row>
  );
});
