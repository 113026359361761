import * as React from 'react';
import { TActions, TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { Spinner, message } from '@revfluence/fresh';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useUpdateMemberMutation } from '@frontend/app/hooks';
import { PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import { useFetchPaymentSourceData } from '@frontend/applications/PaymentsApp/useFetchPaymentSources';
import styles from './PaymentsConfirm.scss';
import { StepHeader } from '../common/StepHeader';
import { StepDescription } from '../common/StepDescription';
import { AddPayment } from '../common/AddPayment/AddPayment';
import { MemberPaymentSummary } from '../common/MemberPaymentSummary';
import { logger } from '../../../../../../../common/Logger';

const { useEffect, useCallback } = React;

interface IProps {
  actions: TActions;
  state: TState;
}

export const PaymentsConfirm: React.FC<IProps> = (props) => {
  const { state, actions } = props;

  const { workflowActionParameters } = useApplication();
  const {
    loading: isPaymentsSourceLoading,
    data: paymentSources,
  } = useFetchPaymentSourceData();
  const [updateMember] = useUpdateMemberMutation();
  const currentProgram = state?.programs?.find((program) => program.id === workflowActionParameters?.programId);
  const isMemberInfoRequired = ![PaymentCreationSource.SALES_TRACKING, PaymentCreationSource.REASSIGN].includes(
    state.paymentCreationSource,
  );
  useEffect(() => {
    if (!isPaymentsSourceLoading) {
      actions.setPaymentSource(paymentSources);
      actions.setFieldValue('requireHandlingFee', !paymentSources?.handling_fee_disabled || false);
    }
  }, [isPaymentsSourceLoading, actions, paymentSources]);
  useEffect(() => {
    if (!state?.paypalAddress) actions.setFieldValue('paypalAddress', state?.memberInfo?.paypalAddress);
  }, [state?.memberInfo, actions, state?.paypalAddress]);
  const updateMemberEmail = useCallback(
    async (paypalAddress: string) => {
      if (state?.paypalFieldId && isMemberInfoRequired) {
        try {
          await updateMember({
            variables: {
              id: state.memberInfo.id,
              member: {
                fields: {
                  [state.paypalFieldId]: paypalAddress,
                },
              },
            },
          });
          message.success('PayPal address sucessfully updated');
        } catch (error) {
          logger.error(error);
        }
      }
    },
    [updateMember, state.memberInfo, state.paypalFieldId, isMemberInfoRequired],
  );

  let mainContent = state?.memberInfo ? (
    <>
      <div className={styles.left}>
        <MemberPaymentSummary
          brief={state?.selectedTermId ? state?.terms?.find((term) => term.id === state?.selectedTermId) : null}
          name={state?.memberInfo?.name}
          payPalEmail={state?.paypalAddress}
          groups={state?.groups}
          selectedGroupId={state?.selectedGroupId}
          setFieldValue={actions.setFieldValue}
          programs={state.programs}
          selectedProgramId={state?.selectedProgramId}
          paymentCreationSource={state.paymentCreationSource}
          updateMemberEmail={updateMemberEmail}
        />
      </div>
      <div className={styles.right}>
        <AddPayment
          brief={state?.selectedTermId ? state?.terms.find((term) => term.id === state?.selectedTermId) : null}
          amountPaying={state?.amountPaying}
          setAmountPaying={actions?.setAmountPaying}
          paymentSource={state?.paymentSource}
          state={state}
          actions={actions}
        />
      </div>
    </>
  ) : (
    <Spinner />
  );
  if (state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING) {
    mainContent = (
      <>
        <div className={styles.left}>
          <MemberPaymentSummary
            brief={state?.selectedTermId ? state?.terms?.find((term) => term.id === state?.selectedTermId) : null}
            name={state?.memberInfo?.name}
            payPalEmail={state?.paypalAddress}
            groups={state?.groups}
            selectedGroupId={state?.selectedGroupId}
            setFieldValue={actions.setFieldValue}
            programs={state.programs}
            selectedProgramId={state?.selectedProgramId}
            paymentCreationSource={state.paymentCreationSource}
            updateMemberEmail={updateMemberEmail}
            staPaymentCount={state.staPayments.filter((payment) => payment.isSelected).length}
          />
        </div>
        <div className={styles.right}>
          <AddPayment
            brief={state?.selectedTermId ? state?.terms.find((term) => term.id === state?.selectedTermId) : null}
            amountPaying={state?.amountPaying}
            setAmountPaying={actions?.setAmountPaying}
            paymentSource={state?.paymentSource}
            state={state}
            actions={actions}
          />
        </div>
      </>
    );
  }

  return (
    <div className={styles.paymentsConfirmContainer}>
      {isMemberInfoRequired && (
        <StepHeader
          name={state.memberInfo?.name}
          profileImage={state.memberInfo?.profileImage}
          programImage={currentProgram?.splashImageUrl}
          programName={currentProgram?.title}
        />
      )}
      <StepDescription
        title="Confirm Payment Details"
        description="Confirm payment method for this payment. If necessary, additional funds can be added to your account in Payment Settings"
      />
      <div className={styles.main}>{mainContent}</div>
    </div>
  );
};
