import gql from 'graphql-tag';

export const GET_DASHBOARD_STATS_QUERY = gql`
  query GetDashboardStatistics($startDate: DateTime, $endDate: DateTime, $offerType: STATS_OFFER_TYPE, $isArchiveEnabled: Boolean) {
    summary: advertiserStatSummary(startDate: $startDate, endDate: $endDate, statsOfferType: $offerType, isArchiveEnabled: $isArchiveEnabled) {
      avgSale
      clicks
      conversions
      members
      offers
      payoutEarned
      payoutMade
      sales
    }
  }
`;
