import { Radio } from 'antd';
import * as React from 'react';
import { IFormRadioGroupOption } from './types';
import styles from './FormRadioGroup.scss';

interface IProps {
  value: string;
  name: string;
  options: IFormRadioGroupOption[];
  onFieldChange: (name: string, value: string) => void;
  header?: string;
}

const FormRadioGroup = ({
  value,
  name,
  options,
  onFieldChange,
  header,
}: IProps) => {
  const handleChange = (e) => {
    onFieldChange(name, e.target.value);
  };

  return (
    <>
      {header && <p>{header}</p>}
      <Radio.Group value={value} onChange={handleChange}>
        {
          options.map((item) => (
            <Radio
              key={item.value}
              value={item.value}
              className={styles.objectiveItem}
            >
              <div>{item.title}</div>
              <div className={styles.itemLabel}>{item.label}</div>
            </Radio>
            ))
        }
      </Radio.Group>
    </>
  );
};

export default React.memo(FormRadioGroup);
