import 'css-chunk:src/app/containers/Projects/LandingPages/LeftPanel/FormFields/Field.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_152vl_157",
  "justify-content-space-between": "_justify-content-space-between_152vl_161",
  "tabular-nums": "_tabular-nums_152vl_165",
  "Field": "_Field_152vl_178",
  "isEditing": "_isEditing_152vl_183",
  "header": "_header_152vl_186",
  "labelContainer": "_labelContainer_152vl_190",
  "label": "_label_152vl_190",
  "isRequired": "_isRequired_152vl_204",
  "editIcon": "_editIcon_152vl_209",
  "editable": "_editable_152vl_213",
  "editBlock": "_editBlock_152vl_222",
  "inputContainer": "_inputContainer_152vl_222",
  "inputLabel": "_inputLabel_152vl_222",
  "error": "_error_152vl_228",
  "checkboxContainer": "_checkboxContainer_152vl_231",
  "checkboxLabel": "_checkboxLabel_152vl_236",
  "editBtns": "_editBtns_152vl_241",
  "cancelBtn": "_cancelBtn_152vl_246",
  "right": "_right_152vl_249",
  "type": "_type_152vl_255",
  "choices": "_choices_152vl_261",
  "show": "_show_152vl_1"
};