import { TContentGuideline } from '@frontend/applications/TermsApp/components/BulkTerms/types/ContentGuidelines';
import { map } from 'lodash';
import { ISaveContentGuidelineAction, IState } from '../../../types/state';

export const saveContentGuideline = (state: IState, action: ISaveContentGuidelineAction): IState => ({
    ...state,
    contentGuidelines: map(
      state.contentGuidelines,
      (
        contentGuideline: TContentGuideline,
      ) => (contentGuideline.id === action.contentGuideline.id
      ? { ...action.contentGuideline }
      : contentGuideline),
    ),
    isEditMode: false,
});
