import { without } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import {
  endpoints,
  getErrorMessageFromResponse,
  DEFAULT_GENERIC_ERROR_MSG,
  IContentList,
  ILicensedContent,
} from '@components';
import { IConnectSearchPage, ICountryRegionOption } from '../models';

export enum ImageSearchActionTypes {
  UPLOAD_IMAGE_REQUEST = '@connectImageSearch/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS = '@connectImageSearch/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE = '@connectImageSearch/UPLOAD_IMAGE_FAILURE',

  SELECT_IMAGE = '@connectImageSearch/SELECT_IMAGE',
  REMOVE_IMAGE = '@connectImageSearch/REMOVE_IMAGE',

  UPDATE_COUNTRIES = '@connectImageSearch/UPDATE_COUNTRIES',

  UPDATE_HIDE_INVITED = '@connectImageSearch/UPDATE_HIDE_INVITED',

  FETCH_CONTENT_LISTS_REQUEST = '@connectImageSearch/FETCH_CONTENT_LISTS_REQUEST',
  FETCH_CONTENT_LISTS_SUCCESS = '@connectImageSearch/FETCH_CONTENT_LISTS_SUCCESS',
  FETCH_CONTENT_LISTS_FAILURE = '@connectImageSearch/FETCH_CONTENT_LISTS_FAILURE',

  FETCH_CONTENT_REQUEST = '@connectImageSearch/FETCH_CONTENT_REQUEST',
  FETCH_CONTENT_SUCCESS = '@connectImageSearch/FETCH_CONTENT_SUCCESS',
  FETCH_CONTENT_FAILURE = '@connectImageSearch/FETCH_CONTENT_FAILURE',

  REMOVE_CONTENT_FROM_LIST_REQUEST = '@connectImageSearch/REMOVE_CONTENT_FROM_LIST_REQUEST',
  REMOVE_CONTENT_FROM_LIST_SUCCESS = '@connectImageSearch/REMOVE_CONTENT_FROM_LIST_SUCCESS',
  REMOVE_CONTENT_FROM_LIST_FAILURE = '@connectImageSearch/REMOVE_CONTENT_FROM_LIST_FAILURE',
}

export interface IImageSearchAction {
  type: ImageSearchActionTypes;
  payload?: {
    imageURL?: string;
    countries?: ICountryRegionOption[];
    hideInvited?: boolean;
    contentLists?: IContentList[];
    contentListId?: number;
    content?: ILicensedContent[];
  };
  meta?: {
    error?: Error;
    errorMessage?: string;
  };
}

type ISThunkAction = ThunkAction<void, IConnectSearchPage, unknown, IImageSearchAction>;

export const selectImage = (imageURL: string) => ({
  type: ImageSearchActionTypes.SELECT_IMAGE,
  payload: {
    imageURL,
  },
});

export const removeImage = () => ({
  type: ImageSearchActionTypes.REMOVE_IMAGE,
});

export const updateCountries = (countries: ICountryRegionOption[]) => ({
  type: ImageSearchActionTypes.UPDATE_COUNTRIES,
  payload: {
    countries,
  },
});

export const updateHideInvited = (hideInvited: boolean) => ({
  type: ImageSearchActionTypes.UPDATE_HIDE_INVITED,
  payload: {
    hideInvited,
  },
});

export const uploadImage = (file: File): ISThunkAction => (dispatch, getState) => {
  const reader = new FileReader();

  const {
    external: { apiEndpoint, uploadFolder },
  } = getState();

  dispatch({
    type: ImageSearchActionTypes.UPLOAD_IMAGE_REQUEST,
  });

  reader.onerror = () => {
    dispatch({
      type: ImageSearchActionTypes.UPLOAD_IMAGE_FAILURE,
      meta: {
        errorMessage: DEFAULT_GENERIC_ERROR_MSG,
      },
    });
  };

  reader.onload = async () => {
    const filename = file.name.replace(/[\W\s]/g, '');

    const data = {
      file: reader.result,
      filename,
      filetype: file.type,
      folder: uploadFolder,
    };

    try {
      const resp = await fetch(`${apiEndpoint}/${endpoints.uploadFileEndpoint}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

      const json = await resp.json();

      if (json.status && json.status.code === 200) {
        dispatch({
          type: ImageSearchActionTypes.UPLOAD_IMAGE_SUCCESS,
          payload: {
            imageURL: json.data.file_url,
          },
        });
      } else {
        throw new Error(getErrorMessageFromResponse(json));
      }
    } catch (err) {
      dispatch({
        type: ImageSearchActionTypes.UPLOAD_IMAGE_FAILURE,
        meta: {
          error: err,
          errorMessage: err.message,
        },
      });
    }
  };

  reader.readAsDataURL(file);
};

export const setUploadedImageUrl = (url: string): ISThunkAction => (dispatch) => {
  dispatch({
    type: ImageSearchActionTypes.UPLOAD_IMAGE_SUCCESS,
    payload: {
      imageURL: url,
    },
  });
};

export const setUploadedImageError = (err: Error): ISThunkAction => (dispatch) => {
  dispatch({
    type: ImageSearchActionTypes.UPLOAD_IMAGE_FAILURE,
    meta: {
      error: err,
      errorMessage: err.message,
    },
  });
};

export const setUploadedImageLoading = (): ISThunkAction => (dispatch) => {
  dispatch({
    type: ImageSearchActionTypes.UPLOAD_IMAGE_REQUEST,
  });
};

export const fetchContentLists = (): ISThunkAction => async (dispatch, getState) => {
  const {
    external: { apiEndpoint, brand },
  } = getState();

  dispatch({
    type: ImageSearchActionTypes.FETCH_CONTENT_LISTS_REQUEST,
  });

  try {
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.contentListEndpoint}?brand_id=${brand.id}`,
    );
    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ImageSearchActionTypes.FETCH_CONTENT_LISTS_SUCCESS,
        payload: {
          contentLists: json.data,
        },
      });
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ImageSearchActionTypes.FETCH_CONTENT_LISTS_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const fetchContent = (contentListId: number): ISThunkAction => async (
  dispatch,
  getState,
) => {
  const {
    external: { apiEndpoint },
  } = getState();

  dispatch({
    type: ImageSearchActionTypes.FETCH_CONTENT_REQUEST,
  });

  try {
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.licensedContentEndpoint}?content_list_id=${contentListId}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      },
    );
    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ImageSearchActionTypes.FETCH_CONTENT_SUCCESS,
        payload: {
          contentListId,
          content: json.data.data,
        },
      });
    } else {
      throw new Error(getErrorMessageFromResponse(json));
    }
  } catch (err) {
    dispatch({
      type: ImageSearchActionTypes.FETCH_CONTENT_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const removeContentFromList = (
  content: ILicensedContent,
  contentListId: number,
): ISThunkAction => async (dispatch, getState) => {
  const {
    external: { apiEndpoint },
  } = getState();

  dispatch({
    type: ImageSearchActionTypes.REMOVE_CONTENT_FROM_LIST_REQUEST,
    payload: {
      contentListId,
      content: [content],
    },
  });

  const contentListIds = without(content.content_list_ids, contentListId);

  try {
    const resp = await fetch(`${apiEndpoint}/${endpoints.licensedContentEndpoint}/${content.id}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        content_list_ids: contentListIds,
      }),
    });

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: ImageSearchActionTypes.REMOVE_CONTENT_FROM_LIST_SUCCESS,
        payload: {
          contentListId,
          content: [content],
        },
      });
    } else {
      throw new Error(DEFAULT_GENERIC_ERROR_MSG);
    }
  } catch (err) {
    dispatch({
      type: ImageSearchActionTypes.REMOVE_CONTENT_FROM_LIST_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};
