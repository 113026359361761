import * as React from 'react';

const { useEffect, useState } = React;

export const useShowPopover = (buttonRef: React.RefObject<HTMLElement>) => {
  const result = useState<boolean>(false);
  const [, setShowPopover] = result;

  useEffect(() => {
    const button = buttonRef && buttonRef.current;

    const stop = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const show = (e: MouseEvent) => {
      stop(e);
      setShowPopover(true);
    };

    if (button) {
      button.addEventListener('mousedown', stop);
      button.addEventListener('mouseup', stop);
      button.addEventListener('click', show);
    } else {
      return;
    }

    return () => {
      if (button) {
        button.removeEventListener('mousedown', stop);
        button.removeEventListener('mouseup', stop);
        button.removeEventListener('click', show);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonRef]);

  return result;
};
