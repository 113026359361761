import * as React from 'react';
import { Typography, Space } from '@revfluence/fresh';
import { orderBy, groupBy } from 'lodash';
import {
  FacebookIcon,
  PinterestIcon,
  TiktokIcon,
  TwitterIcon,
  YoutubeIcon,
  InstagramIcon,
  SnapchatIcon,
 } from '@revfluence/fresh-icons/brands/esm';
 import {
  BlogIcon,
  ImageIcon,
} from '@revfluence/fresh-icons/solid/esm';
import { NetworkType, ContentType } from '@frontend/app/types/globalTypes';

import { getNetworkTypeLabel } from '../utils';
import { TContentGuideline, IContentGuidelinesWithDueDates } from '../types/ContentGuidelines';
import ContentDeliverableType from './ContentDeliverableType';

import styles from './ContentDeliverableTypes.scss';

const { useMemo, useCallback } = React;
const { Text } = Typography;

interface IProps {
  contentGuidelinesTemplates: Array<TContentGuideline | IContentGuidelinesWithDueDates>;
  contentGuidelinesWithDueDates: Array<IContentGuidelinesWithDueDates>;
  onAddPostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
  onRemovePostType: (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => void;
}

const ContentDeliverableTypes: React.FC<IProps> = React.memo((props) => {
  const {
    contentGuidelinesTemplates,
    contentGuidelinesWithDueDates,
  } = props;
  const contentGuidelines = useMemo(
    () => {
      const contentGuidelinesOrderedByNetwork = {};
      const contentGuidelinesGroupByNetwork = groupBy(contentGuidelinesTemplates, 'networkType');
      [
        NetworkType.INSTAGRAM,
        NetworkType.TIKTOK,
        NetworkType.PINTEREST,
        NetworkType.YOUTUBE,
        NetworkType.TWITTER,
        NetworkType.OTHER,
      ].forEach((networkType: NetworkType) => {
        if (contentGuidelinesGroupByNetwork[networkType]) {
          contentGuidelinesOrderedByNetwork[networkType] = orderBy(
            contentGuidelinesGroupByNetwork[networkType], ['priority'], ['asc'],
          );
        }
      });
      return contentGuidelinesOrderedByNetwork;
    },
    [contentGuidelinesTemplates],
  );

  const getIconByNetworkType = useCallback((networkType: string) => {
    switch (networkType) {
      case NetworkType.INSTAGRAM:
        return <InstagramIcon />;
      case NetworkType.FACEBOOK:
        return <FacebookIcon />;
      case NetworkType.PINTEREST:
        return <PinterestIcon />;
      case NetworkType.TIKTOK:
        return <TiktokIcon />;
      case NetworkType.TWITTER:
        return <TwitterIcon />;
      case NetworkType.YOUTUBE:
        return <YoutubeIcon />;
      case NetworkType.OTHER:
        return <ImageIcon />;
      case NetworkType.BLOG:
        return <BlogIcon />;
      case NetworkType.SNAPCHAT:
        return <SnapchatIcon />;
      default:
        throw new Error(`Unhandled networkType: "${networkType}"`);
    }
  }, []);

  return (
    <div className={styles.ContentDeliverableTypes}>
      {
        Object.keys(contentGuidelines).map((networkType: string) => {
          const contentGuidelinesGroupByType = groupBy(contentGuidelines[networkType], 'type');
          if (networkType === NetworkType.TWITTER) return null;
          return (
            <Space key={networkType} direction="vertical">
              <Text>
                { getIconByNetworkType(networkType) }
                { ' ' }
                { getNetworkTypeLabel(networkType) }
              </Text>
              {
                Object.keys(contentGuidelinesGroupByType).map((type: ContentType) => (
                    type !== ContentType.FLEXIBLE ? (
                      <ContentDeliverableType
                        key={`${networkType}-${type}`}
                        contentGuidelinesTemplates={contentGuidelinesGroupByType[type]}
                        contentGuidelinesWithDueDates={contentGuidelinesWithDueDates}
                        onAddPostType={props.onAddPostType}
                        onRemovePostType={props.onRemovePostType}
                      />
                    ) : null
                  ))
              }
            </Space>
          );
        })
      }
    </div>
  );
});

ContentDeliverableTypes.displayName = 'ContentDeliverableTypes';

export default ContentDeliverableTypes;
