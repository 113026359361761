import * as React from 'react';

import { map } from 'lodash';

import { ContentPage } from '@components';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { useActivationsQuery, useProgramsQuery, useCommunitiesQuery } from '@frontend/app/hooks';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

interface IProps {
}

const ContentAppReport: React.FunctionComponent<IProps> = () => {
  const { backendServerApiEndpoint, clientId } = useApplication();
  const isProjects = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  const {
    loading: communityLoading,
    data: communityData,
  } = useCommunitiesQuery();

  const {
    loading: programLoading,
    data: programData,
  } = useProgramsQuery();

  const {
    loading: activationsLoading,
    activations,
  } = useActivationsQuery();

  const loading = activationsLoading || programLoading || communityLoading;

  const projectFilters = [
    {
      title: 'Groups',
      options: [
        {
          value: '',
          label: 'All Groups',
        },
        ...map(communityData ? communityData.communities : [], (community) => ({
          value: community.id,
          label: community.title,
        })),
      ],
      filterKey: 'communityId',
    },
    {
      title: 'Projects',
      options: [
        {
          value: '',
          label: 'All Projects',
        },
        ...map(programData ? programData.programs : [], (program) => ({
          value: program.id,
          label: program.title,
        })),
      ],
      filterKey: 'programId',
    },
  ];

  const legacyFilters = [
    {
      title: 'Community',
      options: [
        {
          value: '',
          label: 'All Communities',
        },
        ...map(communityData ? communityData.communities : [], (community) => ({
          value: community.id,
          label: community.title,
        })),
      ],
      filterKey: 'communityId',
    },
    {
      title: 'Program',
      options: [
        {
          value: '',
          label: 'All Programs',
        },
        ...map(programData ? programData.programs : [], (program) => ({
          value: program.id,
          label: program.title,
        })),
      ],
      filterKey: 'programId',
    },
    {
      title: 'Activation',
      options: [
        {
          value: '',
          label: 'All Activations',
        },
        ...map(activations, (activation) => ({
          value: activation.id,
          label: activation.name,
        })),
      ],
      filterKey: 'activationId',
    },
  ];
  const filters = isProjects ? projectFilters : legacyFilters;
  if (loading) {
    return null;
  }

  return (
    <ContentPage
      apiEndpoint={backendServerApiEndpoint}
      contentUploadFolder="aspirex_content_library"
      showVisitManageLink={false}
      clientId={clientId}
      additionalFilterOptions={filters}
      isQA={false}
    />
  );
};

export default ContentAppReport;
