/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_j9j5a_157 {
  display: flex;
}

._justify-content-space-between_j9j5a_161 {
  justify-content: space-between;
}

._tabular-nums_j9j5a_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_j9j5a_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178 {
  display: flex;
  flex: 1;
  flex-direction: column;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178._iconOnly_j9j5a_183 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178._iconOnly_j9j5a_183 ._accountEntry_j9j5a_188 {
  margin-bottom: 0;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178._iconOnly_j9j5a_183 ._accountEntry_j9j5a_188 ._icon_j9j5a_183 {
  margin-right: 0.5rem;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178._iconOnly_j9j5a_183 ._accountEntry_j9j5a_188:last-of-type ._icon_j9j5a_183 {
  margin-right: 0;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178 ._accountEntry_j9j5a_188 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178 ._accountEntry_j9j5a_188._hasLink_j9j5a_202 {
  cursor: pointer;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178 ._accountEntry_j9j5a_188._marginBottom_j9j5a_205 {
  margin-bottom: 0.75rem;
}
._NetworkCell_j9j5a_178 ._accountList_j9j5a_178 ._accountEntry_j9j5a_188 ._icon_j9j5a_183 {
  margin-right: 6px;
  display: flex;
}