import * as qs from 'querystring';
import { map, uniq } from 'lodash';

export const headlineFontFamilyOptions = [
  { value: '', label: 'System Default' },
  { value: 'Oswald', label: 'Oswald' },
  { value: 'Merriweather', label: 'Merriweather' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Josefin Sans', label: 'Josefin Sans' },
  { value: 'Bebas Neue', label: 'Bebas Neue' },
  { value: 'Cairo', label: 'Cairo' },
  { value: 'EB Garamond', label: 'EB Garamond' },
  { value: 'Inter', label: 'Inter' },
  { value: 'Manrope', label: 'Manrope' },
  { value: 'Exo 2', label: 'Exo 2' },
  { value: 'Abril Fatface', label: 'Abril Fatface' },
  { value: 'Cormorant Garamond', label: 'Cormorant Garamond' },
  { value: 'Righteous', label: 'Righteous' },
  { value: 'Cinzel', label: 'Cinzel' },
  { value: 'Didact Gothic', label: 'Didact Gothic' },
  { value: 'Vollkorn', label: 'Vollkorn' },
  { value: 'Prata', label: 'Prata' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'IBM Plex Mono', label: 'IBM Plex Mono' },
  { value: 'JetBrains Mono', label: 'JetBrains Mono' },
];

export const bodyFontFamilyOptions = [
  { value: '', label: 'System Default' },
  { value: 'Josefin Sans', label: 'Josefin Sans' },
  { value: 'Inter', label: 'Inter' },
  { value: 'Manrope', label: 'Manrope' },
  { value: 'Exo 2', label: 'Exo 2' },
  { value: 'Didact Gothic', label: 'Didact Gothic' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'IBM Plex Mono', label: 'IBM Plex Mono' },
  { value: 'JetBrains Mono', label: 'JetBrains Mono' },
  { value: 'Open Sans', label: 'Open Sans' },
  { value: 'Noto Sans', label: 'Noto Sans' },
  { value: 'Ubuntu', label: 'Ubuntu' },
  { value: 'Lora', label: 'Lora' },
  { value: 'Cormorant Garamond', label: 'Cormorant Garamond' },
  { value: 'Fira Sans', label: 'Fira Sans' },
  { value: 'Arimo', label: 'Arimo' },
  { value: 'IBM Plex Sans', label: 'IBM Plex Sans' },
  { value: 'Crimson Text', label: 'Crimson Text' },
  { value: 'DM Sans', label: 'DM Sans' },
  { value: 'Vollkorn', label: 'Vollkorn' },
];

export const ctaFontFamilyOptions = bodyFontFamilyOptions;

export const availableFontFamiliesOptionsGenerator = () => {
  const allFamilies = [
    ...headlineFontFamilyOptions,
    ...bodyFontFamilyOptions,
    ...ctaFontFamilyOptions,
  ];

  const allFamiliesValues = map(allFamilies, 'value').filter((fontValue) => !!fontValue);

  return uniq(allFamiliesValues);
};

export const availableFontFamilies = availableFontFamiliesOptionsGenerator();

export const getGoogleFontsURL = () => {
  const baseUrl = 'https://fonts.googleapis.com/css2';

  const parameters = {
    family: availableFontFamilies,
    display: 'swap',
    indices: false,
  };

  const stringifiedParameters = qs.stringify(parameters);

  return `${baseUrl}?${stringifiedParameters}`;
};
