import { ITableColumnConfig } from '@components';

export const allColumns: Array<ITableColumnConfig> = [
  {
    headerName: 'Title',
    cellType: 'text',
    field: 'title',
  },
  {
    headerName: 'Status',
    cellType: 'text',
    field: 'status',
  },
  {
    headerName: 'Brand Signers',
    cellType: 'text',
    field: 'brand_signers',
  },
  {
    headerName: 'Member Signers',
    cellType: 'text',
    field: 'member_signers',
  },
  {
    headerName: 'Created Date',
    cellType: 'text',
    field: 'created_date',
  },
  {
    headerName: 'Project',
    cellType: 'text',
    field: 'activation_names',
  },
  {
    headerName: 'Download',
    cellType: 'selectable',
    field: 'download',
  },
];
