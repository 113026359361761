import { map } from 'lodash';

import { IState } from '../../../../types/state';
import { IUpdateShowHideNewPriceAction } from './index';

export const updateShowHideNewPrice = (state: IState, action: IUpdateShowHideNewPriceAction): IState => {
  if (state.collaborationDetails.reward.payment.showHideNewPrice === action.showHideNewPrice) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      reward: {
        ...state.collaborationDetails.reward,
        payment: {
          ...state.collaborationDetails.reward.payment,
          showHideNewPrice: action.showHideNewPrice,
        },
      },
    },
    members: map(state.members, (member) => ({
      ...member,
      paymentAmount: action.showHideNewPrice ? 0 : null,
    })),
  };
};
