import * as React from 'react';
import {
 Card, Button, Typography, Tag,
} from '@revfluence/fresh';
import { ArrowsRotateIcon } from '@revfluence/fresh-icons/regular/esm';

import styles from './GDriveSyncComponent.scss';

interface IProps {
  userExternalDisplayId: string;
  startSync: () => void;
  inProgress: boolean;
}

const GoogleDriveSyncComponent: React.FC<IProps> = ({
  userExternalDisplayId,
  startSync,
  inProgress,
}) => (
  <Card title="Connected Google Drive" className={styles.gdriveSync}>
    <div className={styles.syncHeader}>
      <Typography.Text>{userExternalDisplayId}</Typography.Text>
      <Button
        onClick={startSync}
        icon={<ArrowsRotateIcon />}
        type="primary"
        loading={inProgress}
      >
        {inProgress ? 'Syncing' : 'Sync'}
      </Button>
    </div>
    <p>
      <Tag color="success">Connected</Tag>
    </p>
  </Card>
);

GoogleDriveSyncComponent.displayName = 'GoogleDriveSyncStatus';

export default React.memo(GoogleDriveSyncComponent);
