import { ISocialAccount } from './socialAccount';
import { ISocialPost } from './socialPost';
import { IMedia } from './media';
import { IPublisher } from './publisher';

export type TLicensedContentType = 'image' | 'video';

export interface ILicensedContent {
  brand_id: number;
  campaign_id: number;
  content_list_ids: number[];
  content_type: TLicensedContentType;
  created_ts: number;
  downloadable_media?: IMedia[];
  id: string;
  image: string;
  image_link: string;
  limited_permissions: string[];
  media: IMedia[];
  member: IMember;
  metrics: IMetrics;
  permission_level: string;
  permission_notes: string;
  project_id: string;
  publisher: IPublisher;
  show_in_content_library: boolean;
  social_account: ISocialAccount;
  social_post: ISocialPost;
  source: string;
  tags: string[];
  unique_identifier: string;
  uploaded_by: {
    email: string;
  };
  video: string;
  vision_tags: string[];
}

interface IMember {
  id: number;
  name: string;
  profile_picture: string;
}

interface IMetrics {
  socialinsights__comment_count: number;
  clicks: number;
  socialinsights__share_count: number;
  facebookinsights__offsite_conversion_fb_pixel_purchase: number;
  socialinsights__favorite_count: number;
  socialinsights__like_count: number;
  ecv: number;
  facebookinsights__spend: number;
  contents__url: string;
  contents__id: string;
  spend: number;
  impressions: number;
}
