/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1eo00_157 {
  display: flex;
}

._justify-content-space-between_1eo00_161 {
  justify-content: space-between;
}

._tabular-nums_1eo00_165 {
  font-variant-numeric: tabular-nums;
}

._Select_1eo00_169 ._button_1eo00_169 ::selection, ._Select_1eo00_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1eo00_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Select_1eo00_169 ._button_1eo00_169, ._Select_1eo00_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Select_1eo00_169 ._button_1eo00_169 ::-webkit-scrollbar, ._Select_1eo00_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Select_1eo00_169 ._button_1eo00_169 ::-webkit-scrollbar-track, ._Select_1eo00_169 ::-webkit-scrollbar-track, ._Select_1eo00_169 ._button_1eo00_169 ::-webkit-scrollbar-track-piece, ._Select_1eo00_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Select_1eo00_169 ._button_1eo00_169 ::-webkit-scrollbar-thumb, ._Select_1eo00_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._Select_1eo00_169 ._button_1eo00_169 {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  color: #1a1818;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._Select_1eo00_169 ._button_1eo00_169._light_1eo00_217 {
  background-color: white;
  border-radius: 3px;
  border: solid 1px #e1e4ea;
}
._Select_1eo00_169 ._button_1eo00_169._info_1eo00_222 {
  background-color: #eff5f9;
  border-radius: 100px;
  border: none;
  color: #3996e0;
}
._Select_1eo00_169 ._button_1eo00_169._round_1eo00_228 {
  border-radius: 100px;
  padding-left: 10px;
}
._Select_1eo00_169 ._button_1eo00_169._active_1eo00_232, ._Select_1eo00_169 ._button_1eo00_169:hover {
  background-color: #e6edf2;
}
._Select_1eo00_169 ._button_1eo00_169._active_1eo00_232 ._arrow_1eo00_235, ._Select_1eo00_169 ._button_1eo00_169:hover ._arrow_1eo00_235 {
  color: #3996e0;
}
._Select_1eo00_169 ._button_1eo00_169 ._label_1eo00_238 {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
._Select_1eo00_169 ._button_1eo00_169 ._label_1eo00_238 ._text_1eo00_244 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
._Select_1eo00_169 ._button_1eo00_169 ._label_1eo00_238 ._text_1eo00_244._jsxLabel_1eo00_249 {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
._Select_1eo00_169 ._button_1eo00_169 ._label_1eo00_238 ._icon_1eo00_254 {
  display: flex;
  margin-right: 8px;
  margin-top: -2px;
}
._Select_1eo00_169 ._button_1eo00_169 ._arrow_1eo00_235 {
  margin-left: auto;
  padding: 0 15px;
  display: flex;
  color: #A2A1A4;
}
._Select_1eo00_169 ._customLabel_1eo00_265 {
  cursor: pointer;
}
._Select_1eo00_169 ._mask_1eo00_268 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

._Popover_1eo00_277 {
  z-index: 9600 !important;
  width: 12.5rem;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 {
  padding: 12px 14px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281:hover {
  background-color: #f0f4f8;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281:hover ._label_1eo00_238 {
  color: #3996e0;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281._active_1eo00_232 ._label_1eo00_238 {
  color: #3996e0;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._label_1eo00_238 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._ellipsisLable_1eo00_306 {
  width: 100%;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._ellipsisLable_1eo00_306 > div {
  width: 80%;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._actions_1eo00_312 {
  position: absolute;
  height: 100%;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._actions_1eo00_312 ._actionItem_1eo00_320 {
  min-width: unset;
  padding: unset;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._actions_1eo00_312 ._actions-enter_1eo00_327 {
  opacity: 0;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._actions_1eo00_312 ._actions-enter-active_1eo00_330 {
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._actions_1eo00_312 ._actions-exit_1eo00_334 {
  opacity: 1;
}
._Popover_1eo00_277 ._list_1eo00_281 ._option_1eo00_281 ._actions_1eo00_312 ._actions-exit-active_1eo00_337 {
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}