import { MutationHookOptions, useMutation } from '@apollo/client';

import { DISABLE_AUTOMATION_MUTATION } from '@frontend/app/queries';
import { DisableAutomation, DisableAutomationVariables } from '@frontend/app/queries/types/DisableAutomation';

type IOptions = MutationHookOptions<DisableAutomation, DisableAutomationVariables>;

export const useDisableAutomationMutation = (options: IOptions = {}) =>
  useMutation<DisableAutomation, DisableAutomationVariables>(DISABLE_AUTOMATION_MUTATION, {
    ...options,
    update(cache) {
      cache.evict({ fieldName: 'getAutomationSummary' });
      cache.evict({ fieldName: 'getAutomationStats' });
      cache.evict({ fieldName: 'listTemplates' });
      cache.gc();
    },
  });
