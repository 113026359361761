import gql from 'graphql-tag';

const GET_DASHBOARD_CONFIG = gql`
    query GetDashboardConfig{
        dashboardConfig:getDashboardConfig{
            dashboardConfig
        }
    }
`;

export default GET_DASHBOARD_CONFIG;
