export interface IManager {
  user_roles: string[];
  full_name: string;
  email: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invited_by_name?: any;
  is_org_admin: boolean;
  has_scheduled_meeting: boolean;
  id: number;
  password_not_set: boolean;
  limited_access: boolean;
  is_owner: boolean;
  profile_image_url: string;
  email_settings: IManagerEmailSettings;
  should_block_on_tos: boolean;
  is_qa: boolean;
  is_email_verified: boolean;
  suspended: boolean;
  declined_sms: boolean;
  display_name: string;
  has_signed_up: boolean;
  first_aspireiq_login_ts: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  phone?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: any;
  shared_pages: ISharedPage[];
  acl?: IAccessControlItem[];
}

export interface IAccessControlItem {
  entity: number;
  kind: string;
  role?: string;
  level?: number;
  limited?: boolean;
}

export interface ISharedPage {
  route: string;
  created_ts: number;
  page_params: IPageParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  campaign_name?: any;
  brand_name?: string;
  shared_by?: string;
}

interface IPageParams {
  token?: string;
  cid?: string;
  mid?: string;
  bid?: string;
  count?: number;
  proposal_id?: string;
  client_content_review_id?: string;
}

interface IManagerEmailSettings {
  system_message: boolean;
  reminders: boolean;
  newsletter: boolean;
  message: boolean;
  broadcast_reply: boolean;
}
