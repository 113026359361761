import 'css-chunk:src/app/containers/MessagingApp/ThreadList.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1nk04_157",
  "justify-content-space-between": "_justify-content-space-between_1nk04_161",
  "tabular-nums": "_tabular-nums_1nk04_165",
  "ThreadList": "_ThreadList_1nk04_178",
  "header": "_header_1nk04_178",
  "titleAndSearch": "_titleAndSearch_1nk04_178",
  "titleAndCount": "_titleAndCount_1nk04_178",
  "count": "_count_1nk04_178",
  "title": "_title_1nk04_178",
  "titleIGDM": "_titleIGDM_1nk04_178",
  "threadList": "_threadList_1nk04_188",
  "threadItem": "_threadItem_1nk04_188",
  "info": "_info_1nk04_188",
  "snippetAndAttachmentIcon": "_snippetAndAttachmentIcon_1nk04_198",
  "snippet": "_snippet_1nk04_198",
  "subjectAndCount": "_subjectAndCount_1nk04_198",
  "subject": "_subject_1nk04_198",
  "time": "_time_1nk04_208",
  "overflowMenu": "_overflowMenu_1nk04_249",
  "notice": "_notice_1nk04_262",
  "legacy": "_legacy_1nk04_274",
  "iconButton": "_iconButton_1nk04_294",
  "actions": "_actions_1nk04_317",
  "bulkActions": "_bulkActions_1nk04_323",
  "actionButtons": "_actionButtons_1nk04_331",
  "popOver": "_popOver_1nk04_337",
  "refreshButton": "_refreshButton_1nk04_342",
  "shiftedButton": "_shiftedButton_1nk04_345",
  "closeArea": "_closeArea_1nk04_348",
  "closeText": "_closeText_1nk04_360",
  "selected": "_selected_1nk04_380",
  "type": "_type_1nk04_389",
  "appIcon": "_appIcon_1nk04_412",
  "gmailIcon": "_gmailIcon_1nk04_417",
  "attachmentIcon": "_attachmentIcon_1nk04_458",
  "loadSpinner": "_loadSpinner_1nk04_461",
  "initialLoad": "_initialLoad_1nk04_469",
  "show": "_show_1nk04_1"
};