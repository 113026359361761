import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ALLOY_WORKFLOWS_QUERY } from '@frontend/app/queries';

import {
  GetAlloyWorkflowsQuery,
  GetAlloyWorkflowsQueryVariables,
} from '@frontend/app/queries/types/GetAlloyWorkflowsQuery';

type IOptions = QueryHookOptions<GetAlloyWorkflowsQuery, GetAlloyWorkflowsQueryVariables>;

export function useGetAlloyWorkflows(options: IOptions = {}) {
  const {
    loading, data: { workflows } = {}, error, refetch,
  } = useQuery<GetAlloyWorkflowsQuery, GetAlloyWorkflowsQueryVariables>(
    GET_ALLOY_WORKFLOWS_QUERY,
    options,
  );

  return {
    loading,
    workflows: workflows?.data || [],
    error,
    refetch,
  };
}
