import * as React from 'react';

import { Col, Row } from '@revfluence/fresh';

import { Preview } from './components/Preview';
import { Issues } from './components/Issues';
import { TContent } from './types';

import styles from './SubmittedContent.module.scss';

export const SubmittedContent = (content: TContent) => (
  <div className={styles.SubmittedContent}>
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Preview {...content} />
      </Col>
      <Col span={24}>
        <Issues {...content} />
      </Col>
    </Row>
  </div>
);
