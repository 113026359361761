import * as React from 'react';
import cx from 'classnames';
import { filter, first, map } from 'lodash';

import { Select, SelectProps } from 'antd';
import { UserAvatar } from '@frontend/app/components';

import type { SelectValue } from 'antd/lib/select';
import { useGetCurrentClient } from '@frontend/app/hooks';

import styles from './ManagerSelect.module.scss';

interface IProps extends SelectProps<SelectValue> {
  includeGuests?: boolean;
  className?: string;
}

const { useMemo } = React;

/**
 * @type {React.FC}
 */
export const ManagerSelect: React.FC<React.PropsWithChildren<IProps>> = React.memo(
  ({
    includeGuests = false,
    className,
    maxTagCount = 2,
    placeholder = 'Select teammates...',
    mode = 'multiple',
    ...rest
  }) => {
    const { client } = useGetCurrentClient();

    const managers = useMemo(() => {
      if (includeGuests) {
        return client?.users;
      }

      return filter(client?.users, (u) => {
        const clientsData = u.auth0User?.appMetadata?.clients || {};
        const role = first(clientsData[client.id]?.roles) as string;

        return ['admin:default', 'manager:admin', 'manager:default'].includes(role);
      });
    }, [client, includeGuests]);
    const options = useMemo(
      () =>
        map(managers, (u) => ({
            label: (
              <div className={styles.SelectOption}>
                <UserAvatar className={styles.avatar} size={18} name={u.name} />
                <span>{u.name}</span>
              </div>
            ),
            value: u.id,
            name: u.name,
          })),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [client],
    );

    return (
      <Select
        className={cx(styles.ManagerSelect, className)}
        dropdownClassName={styles.ManagerSelectDropdown}
        {...rest}
        mode={mode}
        options={options}
        maxTagCount={maxTagCount}
        placeholder={placeholder}
        optionFilterProp="name"
      />
    );
  },
);
