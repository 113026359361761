import { MutationHookOptions } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import { UPDATE_MEMBER } from '@frontend/app/queries';
import {
  UpdateMemberMutation,
  UpdateMemberMutationVariables,
} from '@frontend/app/queries/types/UpdateMemberMutation';

type IOptions = MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;

export const useUpdateMemberMutation = (options: IOptions = {}) => useMemberMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UPDATE_MEMBER, options);
