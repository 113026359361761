/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1jygv_157 {
  display: flex;
}

._justify-content-space-between_1jygv_161 {
  justify-content: space-between;
}

._tabular-nums_1jygv_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1jygv_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._FileDeliverable_1jygv_178 ._img_1jygv_178 {
  width: 9.375rem;
  height: 9.375rem;
  margin-right: 0.9375rem;
  margin-bottom: 0.9375rem;
  border-radius: 0.5rem;
}