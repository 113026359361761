import * as React from 'react';
import {
 Redirect, Route, Switch, useRouteMatch, useHistory, useLocation,
} from 'react-router-dom';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import NewBulkContract from '../components/NewBulkContract';
import NewContract from '../components/NewContract';
import MemberContractList from '../components/MemberContractList';

const { useEffect } = React;

const ContractAppModal: React.FunctionComponent = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const {
 bulkActionParameters, workflowActionParameters, memberId, closeModal,
} = useApplication();

  const redirect = bulkActionParameters || workflowActionParameters ? 'new_bulk' : 'contracts';

  const onCreateContractCompleted = () => {
    history.push({
      ...location,
      pathname: './contracts',
    });
  };

  useEffect(() => {
    if (!workflowActionParameters && !bulkActionParameters && !memberId) {
      closeModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <section>
        <Switch>
          <Route path={`${match.path}/contracts`} component={MemberContractList} />
          <Route
            path={`${match.path}/new`}
            render={(props) => <NewContract {...props} onCreateContractCompleted={onCreateContractCompleted} />}
          />
          <Route path={`${match.path}/new_bulk`} component={NewBulkContract} />
          <Redirect from={`${match.path}`} to={{ ...location, pathname: `${match.path}/${redirect}` }} />
        </Switch>
      </section>
    </div>
  );
};

export default ContractAppModal;
