/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_k7y4r_157 {
  display: flex;
}

._justify-content-space-between_k7y4r_161 {
  justify-content: space-between;
}

._tabular-nums_k7y4r_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_k7y4r_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._CommunitySelectItem_k7y4r_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1a1818;
  min-width: 0;
}
._CommunitySelectItem_k7y4r_178 > div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
._CommunitySelectItem_k7y4r_178 ._logo_k7y4r_190 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 1.5625rem;
  height: 1.5625rem;
  margin-top: -0.125rem;
  margin-right: 0.625rem;
  border-radius: 0.5rem;
  color: #1a1818;
  object-fit: cover;
}