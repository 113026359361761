/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_8odet_157 {
  display: flex;
}

._justify-content-space-between_8odet_161 {
  justify-content: space-between;
}

._tabular-nums_8odet_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_8odet_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OwnerSelect_8odet_178 ._label_8odet_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._OwnerSelect_8odet_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
._OwnerSelect_8odet_178 ._icon_8odet_194 {
  margin-right: 0.3125rem;
  font-size: 1rem;
}
._OwnerSelect_8odet_178 ._label_8odet_178 {
  margin-right: 0.5rem;
}
._OwnerSelect_8odet_178 ._name_8odet_201 {
  color: #3996e0;
  cursor: pointer;
  user-select: none;
  transition: color 0.1s cubic-bezier(0.4, 0, 1, 1);
  will-change: color;
  white-space: nowrap;
  display: flex;
  align-items: center;
  overflow: hidden;
}
._OwnerSelect_8odet_178 ._name_8odet_201:hover {
  color: #4fa7ee;
}
._OwnerSelect_8odet_178 ._name_8odet_201 ._names_8odet_215 {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
._OwnerSelect_8odet_178 ._name_8odet_201 ._count_8odet_220 {
  flex: none;
}
._OwnerSelect_8odet_178._isSelected_8odet_223 ._name_8odet_201:hover ._edit_8odet_223 {
  visibility: inherit;
}
._OwnerSelect_8odet_178 ._edit_8odet_223 {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.125rem;
  margin-left: 0.3125rem;
  color: #3996e0;
  visibility: hidden;
}

._ownerList_8odet_235 {
  min-width: 12.5rem;
  padding: 0.625rem;
  border-radius: 0.5rem;
  background: #fdfdfd;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0.0625rem 0.25rem;
}
._ownerList_8odet_235 .ant-list-items {
  overflow: unset !important;
}
._ownerList_8odet_235 ._scroll_8odet_245 {
  overflow-y: auto;
  max-height: 18.75rem;
}
._ownerList_8odet_235 ._spinner_8odet_249 {
  display: block;
  margin: auto;
}
._ownerList_8odet_235 ._ownerItem_8odet_253 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.3125rem;
}
._ownerList_8odet_235 ._ownerItem_8odet_253:not(:last-child) {
  margin-bottom: 0.5rem;
}
._ownerList_8odet_235 ._ownerItem_8odet_253 > *:not(:last-child) {
  margin-right: 0.5rem;
}