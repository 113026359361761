import * as React from 'react';
import cx from 'classnames';
import { map, isEmpty, isNumber } from 'lodash';

import {
 KeyboardArrowLeftIcon, CloseIcon, SpinnerIcon, StarBorderIcon, StarIcon,
} from '@components';
import { IFavoriteList, ICampaign } from '@components';
import { IOrganization } from '@components';
import { ISocialAccount } from '@components';
import { Button } from '@components';
import { SubmitButton } from '@components';
import { IconSize } from '@components';
import { Input } from '@components';
import { Popover } from '@components';
import { IToastMessage, IToastRefHandles, Toast } from '@components';

import {
  addSocialAccountsToFavoriteList,
  createFavoriteList,
  fetchFavoriteList,
} from './utils';

const {
 useCallback, useEffect, useMemo, useRef, useState,
} = React;
import styles from './FavoriteButton.scss';

interface IFavoriteButtonProps {
  className?: string;
  socialAccount?: ISocialAccount;
  // eslint-disable-next-line react/no-unused-prop-types
  org?: IOrganization;
  campaign?: ICampaign;
  apiEndpoint?: string;
  size?: IconSize | number;
}

export const FavoriteButton: React.FunctionComponent<IFavoriteButtonProps> = (props) => {
  const {
    apiEndpoint,
    campaign,
    className,
    size = 18,
    socialAccount,
  } = props;

  const brandId = useMemo(() => (
    !isEmpty(campaign) && !isEmpty(campaign.brand)
      ? campaign.brand.id
      : null
  ), [campaign]);
  const campaignId = useMemo(() => (
    !isEmpty(campaign) && isNumber(campaign.id)
      ? campaign.id
      : null
  ), [campaign]);

  const [favoriteList, setFavoriteList] = useState<IFavoriteList[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [didFetchInitialList, setDidFetchInitialList] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [isAddingNewList, setIsAddingNewList] = useState(false);
  const [isAddingToList, setIsAddingToList] = useState(false);
  const starRef = useRef<HTMLDivElement>(null);
  const toastRef = useRef<IToastRefHandles>();

  // Show toast message
  const showToastMessage = useCallback((message: IToastMessage) => {
    toastRef.current.showMessage(message);
  }, [toastRef]);

  // Update list call wrapper
  const updateList = useCallback(() => new Promise((resolve, reject) => {
    if (!apiEndpoint || !brandId) {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Missing `apiEndpoint` | `brandId`');
    } else {
      setIsFetching(true);
      return resolve(
        fetchFavoriteList({ apiEndpoint, brandId })
          .then((list) => setFavoriteList(list))
          .finally(() => setIsFetching(false)),
      );
    }
  }), [apiEndpoint, brandId, setFavoriteList]);

  // Initial list fetch
  useEffect(() => {
    updateList()
      .then(() => setDidFetchInitialList(true))
      .catch((err) => {
        console.error(err);
      });
  }, [updateList]);

  // Create list call wrapper
  const createList = useCallback((name: string) => {
    if (!apiEndpoint || !brandId) {
      return;
    }
    setIsAddingNewList(true);
    return createFavoriteList({
      apiEndpoint,
      brandId,
      name,
    })
      .then((newList) => {
        setIsAddingNewList(false);
        return newList;
      })
      .finally(() => setIsAddingNewList(false));
  }, [apiEndpoint, brandId]);

  // Adds the creator to a list
  const handleFavoriteOptionClick = useCallback((favorite: IFavoriteList) => {
    setIsAddingToList(true);
    return addSocialAccountsToFavoriteList({
      apiEndpoint,
      campaignId,
      listId: favorite.id,
      accountIds: socialAccount.id,
    })
      .then(() => updateList()) // Update list first
      .then(() => {
        showToastMessage({
          content: (
            <div>
              Added creator
              {socialAccount ? (
                <span>
                  {' '}
                  {socialAccount.name || socialAccount.username}
                  {' '}
                </span>
) : ' '}
              to favorite list
              {' '}
              <span>{favorite.name}</span>
              .
            </div>
          ),
        });
      })
      .catch(() => {
        showToastMessage({
          content: 'There was an error when trying to add creator to the favorite list.',
          type: 'error',
        });
      })
      .finally(() => {
        setIsAddingToList(false);
        setShowPopover(false);
      });
  }, [apiEndpoint, campaignId, updateList, showToastMessage, socialAccount]);

  // Adds the creator to a list
  const confirmAddList = useCallback((name: string) => {
    createList(name)
      .then((newList) => {
        setEditMode(false);
        setIsAddingToList(true);
        showToastMessage({
          content: (
            <div>
              Created new favorite list
              {' '}
              <span>{name}</span>
              .
            </div>
          ),
        });
        console.log(newList);
        return handleFavoriteOptionClick(newList); // Add to new list
      })
      .then(() => updateList()) // Then update the list
      .catch((error) => {
        console.error(error);
        showToastMessage({
          content: 'There was an error when trying to create a list.',
          type: 'error',
        });
      })
      .finally(() => {
        setNewListName('');
        setEditMode(false);
        setShowPopover(false);
        setIsAddingNewList(false);
      });
  }, [createList, handleFavoriteOptionClick, showToastMessage, updateList]);

  const listElem = useMemo(() => (
    <>
      <div className={styles.header}>
        {isAddingToList ? <SpinnerIcon className={styles.spinner} size={20} /> : null}
        {isAddingToList ? 'Adding to list...' : 'Add to a favorites list'}
      </div>
      <div className={styles.list}>
        {map(favoriteList, (favorite, index) => (
          <div
            key={index}
            className={cx(styles.option, {
              [styles.disabled]: isAddingToList || isFetching,
            })}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleFavoriteOptionClick(favorite);
            }}
          >
            <div className={styles.label}>
              {favorite.name}
              {' '}
              (
              {favorite.element_count}
              )
            </div>
          </div>
        ))}
      </div>
      <div
        className={cx(styles.createNewList, {
          [styles.disabled]: isAddingToList || isFetching,
        })}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setEditMode(true);
        }}
      >
        <div className={styles.addIconWrapper}>
          <CloseIcon size={12} className={styles.addIcon} />
        </div>
        New List...
      </div>
    </>
  ), [favoriteList, handleFavoriteOptionClick, isAddingToList, isFetching]);

  const editListElem = useMemo(() => (
    <div className={cx(styles.editList, {
      [styles.disabled]: isAddingNewList,
    })}
    >
      <div
        className={styles.back}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setEditMode(false);
          setNewListName('');
        }}
      >
        <KeyboardArrowLeftIcon
          className={styles.arrowIcon}
          size={18}
        />
        Back to favorites list
      </div>
      <Input
        className={styles.editContent}
        defaultValue={newListName}
        placeholder="List name"
        disabled={isAddingNewList}
        focusOnMount
        onChange={(newListName) => {
          setNewListName(newListName);
        }}
        onPressEnter={() => {
          confirmAddList(newListName);
        }}
      />
      <SubmitButton
        className={styles.button}
        label="Create and add to list"
        submittingLabel="Creating..."
        disabled={isEmpty(newListName) || isAddingNewList}
        isSubmitting={isAddingNewList}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          confirmAddList(newListName);
        }}
        fullWidth
        round
      />
    </div>
  ), [confirmAddList, isAddingNewList, newListName]);

  return (
    <div className={cx(styles.FavoriteButton, className)}>
      <Button
        className={cx(styles.button, {
          [styles.active]: showPopover,
          [styles.disabled]: !didFetchInitialList,
        })}
        theme="light"
        disabled={!didFetchInitialList}
        ref={starRef}
        label={(
          <>
            <StarBorderIcon size={size} className={styles.starBorder} />
            <StarIcon size={size} className={styles.star} />
          </>
)}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setShowPopover(true);
        }}
      />
      <Popover
        className={cx(styles.FavoriteListPopover, {
          [styles.disabled]: isAddingToList,
        })}
        mountRef={starRef}
        minWidth={300}
        show={showPopover}
        onRequestClose={() => setShowPopover(false)}
        contentClassName={styles.content}
        offset={{
          x: 10,
          y: 0,
        }}
      >
        {editMode
          ? editListElem
          : listElem}
      </Popover>
      <Toast ref={toastRef} />
    </div>
  );
};

FavoriteButton.displayName = 'FavoriteButton';
