import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_STATS_FOR_ADVANCED_COLLECTION_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { GetStatsForAdvancedCollectionQuery, GetStatsForAdvancedCollectionQueryVariables } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetStatsForAdvancedCollectionQuery';

type IOptions = QueryHookOptions<GetStatsForAdvancedCollectionQuery, GetStatsForAdvancedCollectionQueryVariables>;

export const useGetAdvancedCollectionStats = (options: IOptions = {}) => {
  const {
 data, loading, error, refetch,
} = useQuery(GET_STATS_FOR_ADVANCED_COLLECTION_QUERY, options);

  return {
    loading,
    stats: data?.getStatsForAdvanceCatalogCollection ?? {
      productCount: 0,
      variantCount: 0,
    },
    error,
    refetch,
  };
};
