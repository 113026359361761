import React, { useEffect } from 'react';
import cx from 'classnames';
import { ArrowRightIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { Tag } from '@revfluence/fresh';
import { Handle, Position } from 'reactflow';
import { useGetNodeOptionsQuery } from '@frontend/app/hooks';
import { AutomationNodeData } from '@frontend/app/containers/Projects/AutomationConfig/types';
import { isEmpty } from 'lodash';
import styles from './AutomationNode.scss';

const ActionNode = (props: { data: AutomationNodeData }) => {
  const {
    data: {
      isEditing,
      isLeafNode,
      metadata,
      nodeType,
      nodeId,
      automationId,
      templateId,
      refreshNodeOptions,
      onNodeItemClick,
    },
  } = props;

  const handleBackground = isEditing ? '#BDD7C8' : '#389E0D';

  const {
    data: {
      getNodeOptions: {
        actionOptions,
      },
    } = {
      getNodeOptions: {
        actionOptions: [],
      },
    },
    refetch: refetchNodeOptions,
  } = useGetNodeOptionsQuery({
    variables: {
      context: {
        automationId,
        templateId,
      },
    },
    skip: !automationId && !templateId,
  });

  useEffect(() => {
    if (refreshNodeOptions) {
      refetchNodeOptions();
    }
  }, [refreshNodeOptions, refetchNodeOptions]);

  const onActionClick = () => {
    if (!isEditing) return;
    onNodeItemClick({
      nodeType,
      targetNodeId: nodeId,
      actionNode: {
        fromPreviousSender: metadata.fromPreviousSender,
        templateId: metadata.templateId,
        resourceId: metadata.resourceId,
      },
    });
  };

  const selectedTemplate = actionOptions[0]?.messageTemplates.find((template) => template.id === metadata.templateId);
  const selectedResourceEmail = actionOptions[0]?.emailResources.find((resource) => resource.id === metadata.resourceId);

  const showAlert = isEmpty(selectedTemplate) || isEmpty(selectedResourceEmail);

  return (
    <>
      <Handle
        type="target"
        isConnectable={false}
        position={Position.Top}
        style={{ background: 'transparent' }}
      />
      <div className={styles.nodeDecorator}>
        <div className={styles.actionNodeIcon}>
          <ArrowRightIcon />
        </div>
      </div>
      <div className={styles.nodeContent}>
        <div>Then...</div>
        <div className={styles.actionContent}>
          <div>Send email:</div>
          <Tag
            className={cx(styles.actionNodeTag, { [styles.alert]: showAlert })}
            onClick={onActionClick}
          >
            <div className={styles.actionContent}>
              {
                showAlert && (
                  <div><TriangleExclamationIcon style={{ color: '#D48806' }} /></div>
                )
              }
              <div className={styles.actionResource}>
                <div className={styles.emailTemplate}>
                  {selectedTemplate?.displayName || 'Select Template'}
                </div>
                <div className={styles.resourceEmail}>
                  <span className={styles.attribute}>From: </span>
                  <span>
                    {selectedResourceEmail?.displayName || 'Select email address'}
                  </span>
                </div>

              </div>
            </div>
          </Tag>
        </div>
      </div>
      { !isLeafNode && (
        <Handle
          type="source"
          isConnectable={false}
          position={Position.Bottom}
          style={{ background: handleBackground }}
        />
      ) }
    </>
  );
};

export default ActionNode;
