/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_7fcwr_157 {
  display: flex;
}

._justify-content-space-between_7fcwr_161 {
  justify-content: space-between;
}

._tabular-nums_7fcwr_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_7fcwr_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._NeedsShopifyPermissions_7fcwr_178 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 31.25rem;
  padding-top: 4rem;
  text-align: center;
}
._NeedsShopifyPermissions_7fcwr_178 ._icon_7fcwr_188 {
  height: 4.6875rem;
  margin-left: auto;
  margin-right: auto;
  width: 4.6875rem;
}
._NeedsShopifyPermissions_7fcwr_178 ._title_7fcwr_194 {
  color: #1a1818;
  font-size: 1.5rem;
  margin: 0;
}
._NeedsShopifyPermissions_7fcwr_178 ._description_7fcwr_199 {
  color: #8f8d91;
  font-size: 1rem;
  margin: 0;
}
._NeedsShopifyPermissions_7fcwr_178 ._button_7fcwr_204 {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}