import * as React from 'react';

import { useHistory } from 'react-router-dom';

import { useState } from 'react';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import AddPostLink from './AddPostLink';
import { ISocialPostArtifact } from '../useFetchSocialPostData';
import { SocialPostAssignment } from './SocialPostAssignment';

import styles from './NewSocialPost.scss';

const NewSocialPost: React.FunctionComponent = () => {
  /* Overall flow for this is:
    1. Create social post by URL
    2. Assign CAP to social post artifact
  */
  const { memberId } = useApplication();
  const [post, setPost] = useState<ISocialPostArtifact>(null);
  const history = useHistory();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onLinkSaveSuccess = (post: any) => {
    setPost(post);
  };

  const onArtifactAssignmentSuccess = () => {
    history.push('./posts');
  };

  return (
    <div className={styles.NewSocialPost}>
      {!post && <AddPostLink onSuccess={onLinkSaveSuccess} />}
      {post
      && (
      <SocialPostAssignment
        post={post}
        memberId={memberId}
        onSuccess={onArtifactAssignmentSuccess}
      />
)}
    </div>
  );
};

export default NewSocialPost;
