import { IState } from '../../../../types/state';
import { IUpdateSpecialPaymentTerms } from './index';

export const updateSpecialPaymentTerms = (state: IState, action: IUpdateSpecialPaymentTerms): IState => {
  if (state.collaborationDetails.reward.payment.specialPaymentTerms === action.specialPaymentTerms) {
    return state;
  }
  return {
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      reward: {
        ...state.collaborationDetails.reward,
        payment: {
          ...state.collaborationDetails.reward.payment,
          specialPaymentTerms: action.specialPaymentTerms,
        },
      },
    },
  };
};
