import * as React from 'react';

import { FileTypeIcon } from '@components';

import { getFilenameFromUrl } from '@frontend/utils';
import { Content, IContentProps } from './Content';

import styles from './ApplicationContent.scss';

/**
 * @class
 * @extends {React.Component}
 */
// eslint-disable-next-line react/prefer-stateless-function
export class ApplicationContent extends React.Component<IContentProps> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritdoc
   */
  public render() {
    const {
 content, classNames, size, ...props
} = this.props;

    let fileIconSize = 70;
    if (size === 'medium') {
      fileIconSize = 80;
    } else if (size === 'large') {
      fileIconSize = 140;
    }

    return (
      <Content
        content={content}
        classNames={classNames.concat(styles.ApplicationContent)}
        size={size}
        {...props}
      >
        <div className={styles.fileIconWrapper}>
          <FileTypeIcon filename={getFilenameFromUrl(content.src)} size={fileIconSize} />
        </div>
      </Content>
    );
  }
}
