import gql from 'graphql-tag';

export const AUTOMATION_LIFECYCLE_STATS_FRAGMENT = gql`
  fragment AutomationLifecycleStatsFragment on AutomationLifecycleStats {
    total
    draft
    active
    disabled
  }
`;
