import * as React from 'react';

import { IStepInfo as Step } from '@frontend/applications/Shared/Wizard/container';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';

import { TRefreshMembersProps } from '../../types';
import { TActions, TState } from './state';
import { useState } from './useState';
import { getTrackingLinksSteps } from './getTrackingLinksSteps';
import { getPromoCodeSteps } from './getPromoCodeSteps';

type TToReturn = Readonly<{
  actions: TActions;
  state: TState;
  steps: readonly Step[];
}>;
const { useMemo } = React;

export const useRefreshMembers = (props: TRefreshMembersProps): TToReturn => {
  const { actions, state } = useState(props);
  const steps = useMemo(
    () => {
      switch (props.offerSource) {
        case OFFER_SOURCE.SHOPIFY:
          return getPromoCodeSteps(props, state, actions);
        case OFFER_SOURCE.TUNE:
          return getTrackingLinksSteps(props, state, actions);
      }
    },
    [props, actions, state],
  );
  return { actions, state, steps };
};
