import React, { useEffect } from 'react';
import {
  Button,
 Card, Col, InputNumber, message, Radio, Row, Select, Skeleton, Space, Tooltip, Typography,
} from '@revfluence/fresh';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { InventorySource } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useGetStoreLocations } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetStoreLocations';
import { useCreateInventorySettings } from '@frontend/applications/ProductFulfillmentApp/hooks/useCreateInventorySettings';
import { isEqual } from 'lodash';
import drawerStyles from './ImportSettingsDrawer.scss';
import styles from './InventoryManagement.scss';
import { SettingsCardWithSwitch } from '../../components/SettingsCardWithSwitch/SettingsCardWithSwitch';
import { useImportSettingsContext } from './ImportSettingsContext';
import { useProductsContext } from '../ProductsContext';

const { Text } = Typography;

export const InventoryManagementFooter = () => {
  const { clientConfig, loading: isConfigLoading } = useGetClientConfig();

  const {
    setIsImportSettingsDrawerOpen,
    setImportSettingScreen,
  } = useProductsContext();

  const {
 inventorySource, selectedLocations, maintainMinimumInventory, minimumStockLevel, stockAvailabilityCheck,
} = useImportSettingsContext();

  const { createInventorySetting, loading } = useCreateInventorySettings({
    onCompleted: () => {
      message.success('Inventory settings saved successfully');
    },
    onError: (error) => {
      message.error(error.message || 'Failed to save inventory settings');
    },
  });

  const handleSave = () => {
    createInventorySetting({
      variables: {
        inventorySettings: {
          inventoryLocations: selectedLocations,
          inventorySource,
          maintainMinimumInventory,
          minimumStockLevel,
          reserveStockForPendingOrders: false,
          stockAvailabilityCheck,
        },
      },
    });
  };

  const handleCancel = () => {
    setIsImportSettingsDrawerOpen(false);
    setImportSettingScreen('root');
  };

  const hasChanges = !isEqual(
    selectedLocations.map((l) => l.id).sort(),
    clientConfig?.inventorySettings?.inventoryLocations?.map((l) => l.id).sort(),
  )
  || inventorySource !== clientConfig?.inventorySettings?.inventorySource
  || maintainMinimumInventory !== clientConfig?.inventorySettings?.maintainMinimumInventory
  || minimumStockLevel !== clientConfig?.inventorySettings?.minimumStockLevel
  || stockAvailabilityCheck !== clientConfig?.inventorySettings?.stockAvailabilityCheck;

  const hasLocation = inventorySource !== InventorySource.LOCATION_BASED_INVENTORY
  || selectedLocations.length > 0;

  return (
    <Row align="middle" justify="center" gutter={24}>
      <Col flex="215px">
        <Button
          className={drawerStyles.footerBtn}
          size="large"
          onClick={handleCancel}
          disabled={loading}
        >
          Cancel
        </Button>
      </Col>
      <Col flex="215px">
        <Tooltip title={hasLocation ? '' : 'Please select at least one location'}>
          <Button
            type="primary"
            className={drawerStyles.footerBtn}
            size="large"
            loading={loading}
            onClick={handleSave}
            disabled={isConfigLoading || !hasChanges || !hasLocation}
            block
          >
            Save
          </Button>
        </Tooltip>
      </Col>
    </Row>
);
};

export const InventoryManagement = () => {
  const { clientConfig, loading: isConfigLoading } = useGetClientConfig();

  const { importSettingScreen, isImportSettingsDrawerOpen } = useProductsContext();

  const {
  inventorySource,
  selectedLocations,
  stockAvailabilityCheck,
  maintainMinimumInventory,
  minimumStockLevel,
  setInventorySource,
  setSelectedLocations,
  setStockAvailabilityCheck,
  setMaintainMinimumInventory,
  setMinimumStockLevel,
  setState,
} = useImportSettingsContext();

  useEffect(() => {
    if (clientConfig) {
      setState({
        inventorySource: clientConfig.inventorySettings?.inventorySource || InventorySource.ONLINE_SELLABLE_INVENTORY,
        selectedLocations: clientConfig.inventorySettings?.inventoryLocations?.map((location) => ({
          id: location.id,
          name: location.name,
        })) || [],
        stockAvailabilityCheck: clientConfig.inventorySettings?.stockAvailabilityCheck || false,
        maintainMinimumInventory: clientConfig.inventorySettings?.maintainMinimumInventory || false,
        minimumStockLevel: clientConfig.inventorySettings?.minimumStockLevel || 0,
      });
    }
  }, [clientConfig, setState, importSettingScreen, isImportSettingsDrawerOpen]);

  const { locations, loading: isLocationsLoading } = useGetStoreLocations();

  const handleChangeLocation = (value: string[]) => {
    setSelectedLocations(value.map((locationId) => {
      const location = locations.find((location) => location.id === locationId);
      return {
        id: locationId,
        name: location.name,
      };
    }));
  };

  if (isConfigLoading) {
    return <Skeleton />;
  }

  return (
    <Space className={styles.InventoryManagement} direction="vertical">
      <Card>
        <Space direction="vertical" size={0} className={styles.titleContainer}>
          <Text weight="semibold">Inventory Source</Text>
          <Text className={drawerStyles.cardSubtitle}>Choose where to draw inventory for your products. </Text>
        </Space>
        <Radio.Group
          value={inventorySource}
          onChange={(e) => setInventorySource(e.target.value)}
        >
          <Space direction="vertical">
            <Radio value={InventorySource.ONLINE_SELLABLE_INVENTORY}>Online sellable Inventory</Radio>
            <Radio value={InventorySource.LOCATION_BASED_INVENTORY}>Location Based Inventory</Radio>
            {inventorySource === InventorySource.LOCATION_BASED_INVENTORY
             && (isLocationsLoading ? <Skeleton /> : (
               <Select
                 placeholder="Select locations"
                 mode="tags"
                 className={styles.selectLocation}
                 value={selectedLocations.map((location) => location.id)}
                 onChange={handleChangeLocation}
               >
                 {
                locations?.map((location) => (
                  <Select.Option key={location.id} value={location.id}>{location.name}</Select.Option>
                ))
              }
               </Select>
            ))}
            <Radio value={InventorySource.ALL_AVAILABLE_INVENTORY}>All available Inventory</Radio>
          </Space>
        </Radio.Group>
      </Card>
      <SettingsCardWithSwitch
        title="Enable Stock Availability Check"
        subtitle="Activating this will ensure that orders can only be placed when items are in stock."
        checked={stockAvailabilityCheck}
        onChange={setStockAvailabilityCheck}
      />
      {/* <SettingsCardWithSwitch
      title="Reserve Stock for Pending Orders"
      subtitle="When enabled, products in pending orders will be temporarily deducted from the available in stock inventory."
    /> */}
      <SettingsCardWithSwitch
        title="Maintain minimum inventory levels"
        subtitle="Establish a lower limit for inventory counts. Sales will halt if stock falls below this number."
        checked={maintainMinimumInventory}
        onChange={setMaintainMinimumInventory}
        extra={maintainMinimumInventory && (
          <Space direction="vertical">
            <Text className={styles.minInventoryText}>Enter minimum stock level</Text>
            <InputNumber
              type="number"
              value={minimumStockLevel}
              onChange={(e) => setMinimumStockLevel(Number(e))}
              min={0}
              max={100000}
              width="180px"
              className={styles.minInventoryInput}
            />
          </Space>
        )}
      />
    </Space>
  );
};
