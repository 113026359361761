import * as React from 'react';

import {
  compact,
  isEmpty,
  map,
} from 'lodash';

import { AngleDownIcon, GearIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Menu,
  Dropdown,
  Space,
  Button,
} from '@revfluence/fresh';

import { useHistory } from 'react-router-dom';

import {
  TERMS_APP_ID,
  SOCIAL_DISCOVERY_APP_ID,
} from '@frontend/app/constants/applicationIds';

import { ProjectsRouteRoot } from '@frontend/app/containers/Projects/constants';

import { useClientFeatureEnabled, useGetProgramById } from '@frontend/app/hooks';
import { useOverviewPage } from '@frontend/app/containers/Projects/hooks';
import { formatApplicationPageURL, isApplicationPageEditable } from '@frontend/app/containers/Projects/utils';
import { TProject } from '@frontend/app/containers/Projects/types';

import { ClientFeature } from '@frontend/app/constants';
import {
  DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator,
} from '@frontend/shadcn/components/ui/dropdown-menu';
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import HeaderButton from '@frontend/app/refresh-components/HeaderButton';
import { ProjectByIdQuery_project } from '@frontend/app/queries/types/ProjectByIdQuery';
import styles from './QuickLinks.module.scss';

const {
  useMemo,
  useCallback,
} = React;

const QuickLinks: React.FC<{ projectId: number, project?: ProjectByIdQuery_project }> = React.memo(
  ({ projectId, project }) => {
    const isRefreshUIEnabled = useClientFeatureEnabled(ClientFeature.REFRESH_UI);
    const { handleCheckScreenSize } = useOverviewPage();

    const history = useHistory();

    const { isMobile } = handleCheckScreenSize();

    const {
      data: {
        program = null,
      } = {},
    } = useGetProgramById({
      variables: {
        id: projectId,
      },
      skip: !projectId,
    });

    const linkItems = useMemo(() => compact([
      {
        href: `${ProjectsRouteRoot}/${projectId}/settings/details`,
        linkText: 'Project Settings',
        search: '',
      },
      program?.gcrEnabled && {
        href: `${ProjectsRouteRoot}/${projectId}/gcr`,
        linkText: 'Group Content Review',
        search: '',
      },
      {
        href: formatApplicationPageURL(ProjectsRouteRoot, program as TProject, isRefreshUIEnabled),
        linkText: 'Application Page',
        search: '',
      },
      {
        href: `/app/${SOCIAL_DISCOVERY_APP_ID}`,
        linkText: 'Find Creators',
        search: `?projectId=${projectId}`,
      },
      isApplicationPageEditable(project) && {
        href: `${ProjectsRouteRoot}/${projectId}/settings/invite_email`,
        linkText: 'Project Invite Template',
        search: '',
      },
      {
        href: `${ProjectsRouteRoot}/${projectId}/settings/automation`,
        linkText: 'Automations',
        search: '',
      },
      {},
      {
        href: '/settings/messageTemplates',
        linkText: 'All Message Templates',
        search: '',
      },
      {
        href: `/settings/${TERMS_APP_ID}`,
        linkText: 'All Content Guidelines',
        search: `?projectId=${projectId}`,
      },
    ]), [projectId, program, isRefreshUIEnabled, project]);

    const handleNavigateToLink = useCallback((targetLink: string, search: string) => {
      history.push({
        pathname: targetLink,
        search,
        state: {
          prevLocation: history.location.pathname,
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkItems]);

    const menu = useMemo(() => (
      <Menu>
        {
          map(
            linkItems,
            (item) => (
              !isEmpty(item) ? (
                <Menu.Item key={item.linkText}>
                  <Button
                    type="link"
                    className={styles.linkText}
                    onClick={() => handleNavigateToLink(`${item.href}`, `${item.search}`)}
                    block
                  >
                    {item.linkText}
                  </Button>
                </Menu.Item>
              ) : <Menu.Divider />
            ),
          )
        }
      </Menu>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [projectId, linkItems]);

    const shadCnMenuItems = useMemo(() => {
      const groups = linkItems.reduce((groups, item) => {
        if (!groups.length) {
          groups[0] = [];
        }

        if (isEmpty(item)) {
          groups.push(<DropdownMenuSeparator />);
          groups.push([]);
        } else {
          groups[groups.length - 1].push(<DropdownMenuItem onClick={() => { handleNavigateToLink(`${item.href}`, `${item.search}`); }}>{item.linkText}</DropdownMenuItem>);
        }

        return groups;
      }, []);

      return groups.map((group, index) => <DropdownMenuGroup key={`group-${index}`}>{group}</DropdownMenuGroup>);
    }, [linkItems, handleNavigateToLink]);

    if (isRefreshUIEnabled) {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger>
            <HeaderButton label="Settings" size="default" icon={<GearIcon />} iconPosition="before" />
          </DropdownMenuTrigger>
          {/* TODO Replace hardcoded collision padding with a utility mapping to theme spacings */}
          <DropdownMenuContent className="shadcn" collisionPadding={16}>
            {shadCnMenuItems}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }

    return (
      <Dropdown
        overlay={menu}
        placement="bottomRight"
      >
        <Button>
          <Space>
            {!isMobile && <>Quick Links</>}
            <AngleDownIcon />
          </Space>
        </Button>
      </Dropdown>
    );
  },
);

export default QuickLinks;
