import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/client';
import { unionBy } from 'lodash';
import { DataProxy } from 'apollo-cache';

import { message } from 'antd';

import {
  CREATE_MEMBER_RESTRICTION_MUTATION,
  GET_MEMBER_RESTRICTIONS_QUERY,
} from '@frontend/app/queries';
import { GetMemberRestrictionsQuery } from '@frontend/app/queries/types/GetMemberRestrictionsQuery';
import {
  CreateMemberRestrictionMutation,
  CreateMemberRestrictionMutationVariables,
} from '@frontend/app/queries/types/CreateMemberRestrictionMutation';
import { IMemberRestriction } from '.';

const { useEffect } = React;

const updateCache = (store: DataProxy, restrictions: IMemberRestriction[]) => {
  const data = store.readQuery<GetMemberRestrictionsQuery>({ query: GET_MEMBER_RESTRICTIONS_QUERY });

  // Do nothing. Cache doesn't exist.
  if (!data) {
    console.log(`cache missing: ${GET_MEMBER_RESTRICTIONS_QUERY}`);
    return;
  }

  store.writeQuery<GetMemberRestrictionsQuery>({
    query: GET_MEMBER_RESTRICTIONS_QUERY,
    data: {
      ...data,
      restrictions: unionBy(data.restrictions, restrictions, 'id'),
    },
  });
};

type IOptions = MutationHookOptions<CreateMemberRestrictionMutation, CreateMemberRestrictionMutationVariables>;

export function useSaveMemberRestriction(options: IOptions = {}) {
  const [saveMemberRestriction, { loading, error }] = useMutation<
  CreateMemberRestrictionMutation,
  CreateMemberRestrictionMutationVariables
  >(CREATE_MEMBER_RESTRICTION_MUTATION, {
    ...options,
    update(...args) {
      if (options.update) {
        options.update(...args);
      }

      const [store, result] = args;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      updateCache(store as any, result.data.restrictions);
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    saveMemberRestriction,
    loading,
    error,
  };
}
