/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_fqhpd_157 {
  display: flex;
}

._justify-content-space-between_fqhpd_161 {
  justify-content: space-between;
}

._tabular-nums_fqhpd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_fqhpd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._icon_fqhpd_178 {
  background-color: #723814;
  display: flex;
  align-items: center;
  justify-content: center;
}