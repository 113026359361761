import * as React from 'react';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useApolloClient as useInitializeApolloClientPFA } from '@frontend/applications/ProductFulfillmentApp/context';
import { useInitializeApolloClient } from './utils';
import { useAuth } from './authContext';

interface IAuthContext {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  pfaApolloClient: ApolloClient<NormalizedCacheObject>;
}

const { useContext } = React;

export const AppContext = React.createContext<IAuthContext>(null);
export const useAppContext = () => useContext(AppContext);
export const AppContextProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  ({ children }) => {
    const { token } = useAuth();

    const apolloClient = useInitializeApolloClient(token);
    const pfaApolloClient = useInitializeApolloClientPFA(token);

    return (
      <AppContext.Provider
        value={{
          apolloClient,
          pfaApolloClient,
        }}
      >
        {children}
      </AppContext.Provider>
    );
  },
);

AppContextProvider.displayName = 'AppContextProvider';
