import React, { useMemo } from 'react';
import {
  WidgetContainer,
  ProgressBarWithLegends,
  MainMetric,
} from '@frontend/app/refresh-components';
import { Skeleton } from '@frontend/shadcn/components/ui/skeleton';
import { MoneyBill1Icon } from '@revfluence/fresh-icons/regular/esm';
import { useGetBudgetDashboardProjectTabSummaryQuery } from '../hooks';

interface TotalAllocatedToBriefProjectWiseWidgetProps {
  projectId?: number;
  fiscalYear?: string;
  className?: string;
}

export default function TotalAllocatedToBriefProjectWiseWidget(props: TotalAllocatedToBriefProjectWiseWidgetProps) {
  const { projectId, fiscalYear, className } = props;
  const isParemetersAbsent = !projectId || !fiscalYear;
  const { data: budgetSummary, loading } = useGetBudgetDashboardProjectTabSummaryQuery({
    variables: {
      programId: projectId,
      fiscalYear,
    },
    skip: isParemetersAbsent,
    fetchPolicy: 'no-cache',
  });

  const legend = useMemo(
    () => [
      { label: 'Paid', color: 'hsl(var(--chart-2))', percent: budgetSummary?.summary?.allocatedBudget?.briefsPaidPercent || 0 },
      { label: 'Due', color: '#dadada', percent: budgetSummary?.summary?.allocatedBudget?.briefsDuePercent || 0 },
    ],
    [budgetSummary],
  );
  const missingParametersContent = (
    <div className="w-full h-full flex justify-center items-center">
      <p>No data found</p>
    </div>
  );
  const loadingContent = (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
      <Skeleton className="h-6 w-full mb-4" />
    </div>
  );
  const mainContent = (
    <div className="flex flex-col justify-around h-full">
      <div className="flex justify-between">
        <MainMetric
          className="flex-1"
          value={budgetSummary?.summary?.allocatedBudget?.allocatedToBriefsAmount || 0}
          size="medium"
          metricType="currency"
          subHeading={`${budgetSummary?.summary?.allocatedBudget?.briefsCount?.toFixed(0) || 0} Total briefs`}
          icon={null}
        />
      </div>
      <div className="mt-3">
        <ProgressBarWithLegends edge="square" legend={legend} height={15} />
      </div>
    </div>
  );
  return (
    <WidgetContainer
      widgetTitle="Total Allocated to Briefs"
      className={`${className}`}
      bgColor="bg-[#02609C]"
      widgetIcon={<MoneyBill1Icon className="text-white" fontSize={20} />}
    >
      {isParemetersAbsent ? missingParametersContent : loading ? loadingContent : mainContent}
    </WidgetContainer>
  );
}
