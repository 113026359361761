import * as React from 'react'; // eslint-disable-line

import { ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';

import { Tag } from '@revfluence/fresh';
import cx from 'classnames';
import { isNull } from 'lodash';
import TemplateIcon from './TemplateIcon';
import styles from './TemplateItem.scss';

type TProps = {
  gotoConfig: (templateId: string) => void;
  icon: string;
  name: string;
  templateId: string;
  description?: string;
  isNavTextTag?: boolean;
  isNavTextPersistent?: boolean;
  navText?: string;
};

const TemplateItem = (props: TProps) => {
  const {
    gotoConfig,
    icon,
    name,
    templateId,
    description,
    navText,
    isNavTextTag,
    isNavTextPersistent,
  } = props;

  const onTemplateClick = () => {
    if (!gotoConfig) return;
    gotoConfig(templateId);
  };

  return (
    // TODO(jb) refactor as a <Button />
    <div
      className={cx(styles.TemplateItem, !isNull(gotoConfig) ? styles.actionable : null)}
      onClick={onTemplateClick}
    >
      <div className={styles.contentContainer}>
        <div className={styles.templateIcon}>
          <TemplateIcon icon={icon} />
        </div>
        <div className={styles.templateContent}>
          <div className={styles.title}>{name}</div>
          {description && <div className={styles.description}>{description}</div>}
        </div>
      </div>

      {navText && isNavTextTag && (
      <div className={!isNavTextPersistent ? styles.navTextNonPersistent : null}>
        <Tag color="success">{navText}</Tag>
      </div>
        )}
      {!isNavTextTag && (
      <div className={styles.nav}>
        <div className={cx(styles.navText, !isNavTextPersistent ? styles.navTextNonPersistent : null)}>
          {navText}
        </div>
        <ChevronRightIcon />
      </div>
        )}
    </div>
  );
};

export default TemplateItem;
