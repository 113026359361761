import * as React from 'react';

import { useAuth } from '@frontend/context/authContext';

interface IIdentifyParams {
  email?: string;
  name?: string;
  companyExternalId?: string;
}
declare global {
  interface Window {
    plantrack: {
      identify(id: string, params: IIdentifyParams): void;
    }
  }
}

const { useEffect } = React;

export function useInitPlanhat(): void {
  const { user, clientInfo } = useAuth();

  useEffect(() => {
    if (!window.plantrack) {
      return;
    }

    window.plantrack.identify(user.sub, {
      email: user.email,
      name: user.name,
      companyExternalId: clientInfo.id,
    });
  }, [clientInfo, user]);
}
