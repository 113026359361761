import * as React from 'react';

import { Button, Tooltip } from '@revfluence/fresh';
import { FileArrowDownIcon } from '@revfluence/fresh-icons/regular/esm';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  showText?: boolean;
}

export const ExportButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const { disabled, onClick, showText } = props;
  return (
    <Tooltip placement="top" title="Download">
      <Button disabled={disabled} icon={<FileArrowDownIcon />} onClick={onClick} type={showText ? 'text' : 'ghost'}>{showText ? 'Export CSV' : ''}</Button>
    </Tooltip>
  );
});

ExportButton.defaultProps = {
  disabled: false,
};

ExportButton.displayName = 'ExportButton';
