import * as React from 'react';
import {
  ColumnsType, Table, Tag, Typography,
} from '@revfluence/fresh';
import { ClockRotateLeftIcon } from '@revfluence/fresh-icons/regular/esm';
import { TPaymentHistory } from '@frontend/applications/PaymentsApp/types';
import { DataFormat, formatValue } from '@frontend/applications/AffiliatesApp/utils';
import styles from './PaymentHistory.scss';

const { Title } = Typography;
interface IProps {
  payemntsMade: TPaymentHistory[]
}

export const PaymentHistory: React.FC<IProps> = (props) => {
  const { payemntsMade } = props;
  const columns: ColumnsType<TPaymentHistory> = [
    {
      title: 'Paid Date',
      dataIndex: 'paidDate',
      key: 'paidDate',
      sorter: (a, b) => a.paidDate.getTime() - b.paidDate.getTime(),
      render: (paidDate: Date) => paidDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => `$${formatValue(DataFormat.Money, amount)}`,
    },
    {
      title: 'Projects',
      dataIndex: 'projects',
      key: 'projects',
    },
    {
      title: 'Budget Account',
      dataIndex: 'budgetAccount',
      key: 'budgetAccount',
      render: (budgetAccount: string[]) => {
        if (!budgetAccount.length) {
          return 'Not assigned';
        }
        const firstAccount = budgetAccount[0];
        const additionalCount = budgetAccount.length - 1;

        return (
          <>
            <Tag key={firstAccount}>
              {firstAccount}
            </Tag>
            {additionalCount > 0 && (
              <Tag key={`additional-${additionalCount}`}>
                {`+${additionalCount}`}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];
  return (
    <div className={styles.paymentHistoryContainer}>
      <div className={styles.paymentHistoryHeader}>
        <ClockRotateLeftIcon />
        <Title level={5}>Payment History</Title>
      </div>
      <div className={styles.paymentTableContainer}>
        <Table
          dataSource={payemntsMade}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};
