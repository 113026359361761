._SearchPage_1f22n_1 {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  flex-wrap: nowrap;
}
._SearchPage_1f22n_1 ._filters_1f22n_8 {
  width: 20rem;
  max-width: 20rem;
  flex: 0 0 20rem;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
._SearchPage_1f22n_1 ._filtersOptions_1f22n_19 {
  flex: 1;
  width: 100%;
}
._SearchPage_1f22n_1 ._filtersButton_1f22n_23 {
  height: 2.5rem;
  margin: 1.25rem 0 2.5rem;
}
._SearchPage_1f22n_1 ._inviteAddressPicker_1f22n_27 {
  margin-bottom: 20px;
}
._SearchPage_1f22n_1 ._results_1f22n_30 {
  margin-left: 1.5rem;
  width: calc(100% - 21.5rem);
  flex: 1;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
}
._SearchPage_1f22n_1 ._tabTitle_1f22n_38 {
  display: flex;
  justify-content: center;
  align-items: center;
}
._SearchPage_1f22n_1 ._tabTitle_1f22n_38 svg {
  margin-right: 6px;
}