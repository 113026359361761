import { BaseError } from "./BaseError";

export enum ValidationErrorReason {
  InvalidType = 'INVALID_TYPE',
  InvalidValue = 'INVALID_VALUE',
  Required = 'REQUIRED',
}

export interface IValidationError {
  message: string;
  reason: ValidationErrorReason;
  context?: Record<string, any>;
}

export class ValidationError extends BaseError {
  readonly status = 400;
  errors: IValidationError[];

  constructor(message?: string, context?: Record<string, any>, errors?: IValidationError[]) {
    super(message, { ...context, errorType: 'ValidationError', errors });
    this.errors = errors;
  }
}
