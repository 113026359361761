/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vc0h9_157 {
  display: flex;
}

._justify-content-space-between_vc0h9_161 {
  justify-content: space-between;
}

._tabular-nums_vc0h9_165 {
  font-variant-numeric: tabular-nums;
}

._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::selection, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_vc0h9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-track, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-track, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-track-piece, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-thumb, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vc0h9_157 {
  display: flex;
}

._justify-content-space-between_vc0h9_161 {
  justify-content: space-between;
}

._tabular-nums_vc0h9_165 {
  font-variant-numeric: tabular-nums;
}

._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::selection, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_vc0h9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-track, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-track, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-track-piece, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-thumb, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vc0h9_157 {
  display: flex;
}

._justify-content-space-between_vc0h9_161 {
  justify-content: space-between;
}

._tabular-nums_vc0h9_165 {
  font-variant-numeric: tabular-nums;
}

._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::selection, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_vc0h9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-track, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-track, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-track-piece, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 ::-webkit-scrollbar-thumb, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._MembersWizard_vc0h9_169 ._wizardNextButton_vc0h9_610 {
  border-color: #167cf4;
  background-color: #167cf4;
}
._MembersWizard_vc0h9_169 ._wizardNextButton_vc0h9_610:focus, ._MembersWizard_vc0h9_169 ._wizardNextButton_vc0h9_610:hover {
  background-color: #2e8af5;
  color: #fff;
}
._MembersWizard_vc0h9_169 ._wizardNextButton_vc0h9_610:disabled {
  color: #fff;
  opacity: 0.8;
}

._MembersWizard_vc0h9_169 {
  height: 100%;
}
._MembersWizard_vc0h9_169 ._statsCard_vc0h9_626 {
  height: 4.6875rem;
  margin-bottom: 1.5rem;
}
._MembersWizard_vc0h9_169 ._alert_vc0h9_630 {
  margin-bottom: 1rem;
}
._MembersWizard_vc0h9_169 ._activeDatesForm_vc0h9_633 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 27rem;
}
._MembersWizard_vc0h9_169 ._activeDatesForm_vc0h9_633 ._dateTimePicker_vc0h9_640 {
  display: block;
}
._MembersWizard_vc0h9_169 ._activeDatesForm_vc0h9_633 ._icon_vc0h9_643 {
  color: #1a1818;
}
._MembersWizard_vc0h9_169 ._confirmClose_vc0h9_646,
._MembersWizard_vc0h9_169 ._membersListWarning_vc0h9_647 {
  align-items: center;
  color: #1a1818;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 1rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 40.625rem;
}
._MembersWizard_vc0h9_169 ._confirmClose_vc0h9_646 ._icon_vc0h9_643,
._MembersWizard_vc0h9_169 ._membersListWarning_vc0h9_647 ._icon_vc0h9_643 {
  color: #F1515F;
}
._MembersWizard_vc0h9_169 ._confirmClose_vc0h9_646 ._title_vc0h9_664,
._MembersWizard_vc0h9_169 ._membersListWarning_vc0h9_647 ._title_vc0h9_664 {
  font-size: 1.25rem;
}
._MembersWizard_vc0h9_169 ._confirmClose_vc0h9_646 ._buttons_vc0h9_668,
._MembersWizard_vc0h9_169 ._membersListWarning_vc0h9_647 ._buttons_vc0h9_668 {
  align-items: center;
  display: flex;
  gap: 1.25rem;
}
._MembersWizard_vc0h9_169 ._confirmClose_vc0h9_646 ._buttons_vc0h9_668 .ant-btn,
._MembersWizard_vc0h9_169 ._membersListWarning_vc0h9_647 ._buttons_vc0h9_668 .ant-btn {
  width: 15.625rem;
}
._MembersWizard_vc0h9_169 ._membersListWarning_vc0h9_647 {
  padding-top: 1rem;
}
._MembersWizard_vc0h9_169 ._confirmClose_vc0h9_646 {
  padding-top: 5rem;
}
._MembersWizard_vc0h9_169 .ant-drawer-content {
  background-color: #fdfdfd;
}
._MembersWizard_vc0h9_169 .ant-drawer-body {
  padding: 0;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 {
  position: relative;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169 {
  border-radius: 0.375rem;
  border-color: transparent;
  border-width: 0.0625rem;
  border-style: solid;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
  margin: 0 -0.75rem;
  padding: 0.625rem 2.5rem 0.625rem 0.625rem;
  transition: border-color 0.2s ease-in-out;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169:hover, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._codeInput_vc0h9_169:focus {
  border-color: #dadcde;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169 {
  border-radius: 0.375rem;
  border-color: transparent;
  border-width: 0.0625rem;
  border-style: solid;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent;
  margin: 0 -0.75rem;
  padding: 0.625rem 2.5rem 0.625rem 0.625rem;
  width: 100%;
  transition: border-color 0.2s ease-in-out;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169:hover, ._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 ._linkInput_vc0h9_169:focus {
  border-color: #dadcde;
}
._MembersWizard_vc0h9_169 ._inputWrapper_vc0h9_169 .anticon {
  color: #F1515F;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
._MembersWizard_vc0h9_169 ._urlInputWarning_vc0h9_730 .ant-input-suffix {
  color: var(--gold-6);
}
._MembersWizard_vc0h9_169 ._urlInputError_vc0h9_733 .ant-input-suffix {
  color: var(--red-6);
}
._MembersWizard_vc0h9_169 ._codeHasError_vc0h9_736 ._codeInput_vc0h9_169 {
  background-color: #F8EDEE;
  color: #F1515F;
  border-color: #F1515F;
}
._MembersWizard_vc0h9_169 ._codeHasError_vc0h9_736 ._codeInput_vc0h9_169:hover, ._MembersWizard_vc0h9_169 ._codeHasError_vc0h9_736 ._codeInput_vc0h9_169:focus {
  border-color: #F1515F;
}
._MembersWizard_vc0h9_169 ._codeHasError_vc0h9_736 ._codeInput_vc0h9_169::placeholder {
  color: #F1515F;
}
._MembersWizard_vc0h9_169 ._enterCodeLabel_vc0h9_747 {
  color: #3996e0;
}
._MembersWizard_vc0h9_169 ._wizardButton_vc0h9_750 {
  width: 12.5rem;
}
._MembersWizard_vc0h9_169 ._wizardButton_vc0h9_750._hidden_vc0h9_753 {
  display: none;
}
._MembersWizard_vc0h9_169 ._wizardNextButton_vc0h9_610 {
  width: 12.5rem;
  transition: opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1);
}
._MembersWizard_vc0h9_169 ._wizardNextButton_vc0h9_610:disabled {
  opacity: 0.4;
  pointer-events: none;
}
._MembersWizard_vc0h9_169 ._wizardNextButton_vc0h9_610._hidden_vc0h9_753 {
  display: none;
}
._MembersWizard_vc0h9_169 ._tablePadding_vc0h9_767 {
  padding-bottom: 0.9375rem;
}
._MembersWizard_vc0h9_169 ._fullWidth_vc0h9_770 {
  width: 100%;
}
._MembersWizard_vc0h9_169 ._projectSelect_vc0h9_773 {
  width: 100%;
}
._MembersWizard_vc0h9_169 ._emptyContainerMargin_vc0h9_776 {
  margin-top: 2.25rem;
}
._MembersWizard_vc0h9_169 ._loading_vc0h9_779 {
  width: 100%;
  display: flex;
  justify-content: center;
}