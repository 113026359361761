import * as React from 'react';
import cx from 'classnames';
import {
 findIndex, get, range, map,
} from 'lodash';

import { ArrowDownFilledIcon, GroupIcon, Select } from '@components';

import styles from './AudienceDropdown.scss';

const { useMemo, useEffect, useRef } = React;

interface IProps {
  step?: number;
  selectedPercentage?: number;
  lessThan?: boolean;
  disabled?: boolean;
  classNames?: string[];
  showIQDataFilters?: boolean;

  onChangePercentage(percentage: number);
  onChangeLessThan(lessThan: boolean);
}

interface ICustomLabelProps {
  label: string;
}

interface IPercentageOption {
  label: string;
  value: number;
}

interface ILogicalOperatorOption {
  label: string;
  value: number;
}

const generatePercentages = (step: number, limit = 100, limitSymbol = ''): IPercentageOption[] => map(range(step, limit, step), (value) => {
    let label = `${value}%`;
    if (value === (limit - step)) {
      label += limitSymbol;
    }
    return { label, value };
  });

const LogicalOperators: ILogicalOperatorOption[] = [
  { label: 'Less than', value: 1 },
  { label: 'Greater than', value: 0 },
];


const CustomLabel: React.FunctionComponent<ICustomLabelProps> = (props) => (
  <div className={styles.customLabel}>
    <button>{props.label}</button>
    <ArrowDownFilledIcon className={styles.arrow} size={10} />
  </div>
  );

function cancelMouseEvent(ev: MouseEvent) {
  ev.preventDefault();
  ev.stopPropagation();
}

const DEFAULT_STEP = 5;
const DEFAULT_PERCENTAGE = 20;
const DEFAULT_LESSTHAN = false;

const AudienceDropdown: React.FunctionComponent<IProps> = (props) => {
  const {
    classNames,
    disabled,
    onChangeLessThan,
    onChangePercentage,
    step,
    showIQDataFilters,
  } = props;

  const ref = useRef<HTMLDivElement>();
  const gtPercentageOptions = useMemo(() => generatePercentages(step, 50 + step, '+'), [step]);
  const ltpercentageOptions = useMemo(() => generatePercentages(step), [step]);

  const percentageOptions = useMemo(() => (props.lessThan ? ltpercentageOptions : gtPercentageOptions), [props.lessThan]);

  const logicalOperatorIndex = useMemo(() => {
    let lessThan = props.lessThan;
    if (disabled) {
      // reset to default when disabled
      onChangeLessThan(DEFAULT_LESSTHAN);
      lessThan = DEFAULT_LESSTHAN;
    }

    return findIndex(LogicalOperators, { value: lessThan ? 1 : 0 });
  }, [props.lessThan, disabled]);

  const selectedPercentageIndex = useMemo(() => {
    const defaultIndex = findIndex(percentageOptions, { value: DEFAULT_PERCENTAGE });
    if (disabled) {
      // reset to default when disabled
      onChangePercentage(percentageOptions[defaultIndex].value);
      return defaultIndex;
    }

    const selectedIndex = findIndex(percentageOptions, { value: props.selectedPercentage });
    if (selectedIndex >= 0) {
      return selectedIndex;
    }

    return defaultIndex;
  }, [percentageOptions, props.selectedPercentage, disabled]);

  useEffect(() => {
    const current = ref.current;
    if (disabled) {
      current.addEventListener('mousedown', cancelMouseEvent);
    }

    return () => {
      current.removeEventListener('mousedown', cancelMouseEvent);
    };
  }, [disabled]);

  const handleChangeLessThan = (lessThan: number) => {
    onChangeLessThan(lessThan === 1);
  };

  return (
    <div
      ref={ref}
      className={cx(styles.AudienceDropdown, classNames, {
        [styles.disabled]: disabled,
      })}
    >
      <GroupIcon className={styles.audienceIcon} size={18} />
      <div>Audience</div>
      {
        showIQDataFilters ? 
        <span className={styles.customText}>Greater than</span> :
        <Select
          className={styles.select}
          options={LogicalOperators}
          onChange={handleChangeLessThan}
          selectedIndex={logicalOperatorIndex}
          disabled={disabled}
          customLabelElement={
            <CustomLabel label={get(LogicalOperators[logicalOperatorIndex], 'label')} />
          }
          popoverProps={{
            minWidth: 100,
          }}
        />
      }
      {percentageOptions && (
        <Select
          className={styles.select}
          options={percentageOptions}
          onChange={onChangePercentage}
          selectedIndex={selectedPercentageIndex}
          disabled={disabled}
          customLabelElement={
            <CustomLabel label={get(percentageOptions[selectedPercentageIndex], 'label')} />
          }
          popoverProps={{
            minWidth: 100,
          }}
        />
      )}
    </div>
  );
};

AudienceDropdown.defaultProps = {
  step: DEFAULT_STEP,
  selectedPercentage: DEFAULT_PERCENTAGE,
  lessThan: DEFAULT_LESSTHAN,
  disabled: false,
};

export default AudienceDropdown;
