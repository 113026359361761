import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const UploadIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M21.17,17.67c-0.46,0-0.83,0.37-0.83,0.83c0,1.05-0.66,1.93-1.45,1.93H5.1c-0.79,0-1.45-0.88-1.45-1.93
      c0-0.46-0.37-0.83-0.83-0.83S2,18.04,2,18.49c0,1.98,1.39,3.58,3.1,3.58H18.9c1.71,0,3.1-1.61,3.1-3.58
      C22,18.04,21.63,17.67,21.17,17.67z"
    />
    <path
      d="M8.85,7.07l2.33-2.33v11.59c0,0.46,0.37,0.83,0.83,0.83c0.46,0,0.83-0.37,0.83-0.83V4.75l2.33,2.33
      c0.16,0.16,0.37,0.24,0.58,0.24c0.21,0,0.42-0.08,0.58-0.24c0.32-0.32,0.32-0.85,0-1.17l-3.74-3.74c-0.32-0.32-0.85-0.32-1.17,0
      L7.68,5.9c-0.32,0.32-0.32,0.85,0,1.17S8.52,7.39,8.85,7.07z"
    />
  </SvgIcon>
));

UploadIcon.displayName = 'UploadIcon';
