import * as React from 'react';

import { isEmpty, isFunction, size } from 'lodash';
import { Menu, Modal } from '@revfluence/fresh';

import { useDeleteMembersById } from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';

import { BulkMenuAction } from './ListHeader';

import styles from './DeleteMemberMenuItem.scss';

const { useCallback, useMemo, useState } = React;

interface IDeleteMemberProps {
  className?: string[] | string;
  memberIds?: number[];
  onDelete?(deletedMembers: number[]);
  isSync?: boolean;
}

export const DeleteMemberMenuItem: React.FC<IDeleteMemberProps> = React.memo((props) => {
  const {
    memberIds,
    onDelete,
    isSync,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const {
    showSuccessMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  const {
    deleteMembers,
    isDeleting,
  } = useDeleteMembersById({
    memberIds,
    skip: isEmpty(memberIds),
    isSync,
  });

  const memberSize = size(memberIds);

  const isSingle = memberSize === 1;

  const confirmContent = useMemo(() => (
    <div className={styles.content}>
      <p>
        Permanently deleting
        {' '}
        {isSingle ? 'this' : 'these'}
        {' '}
        member
        {isSingle ? '' : 's'}
        {' '}
        will delete them from your Members table, Projects, and Inbox.
        {' '}
        <b>
          This action cannot be undone.
        </b>
      </p>
    </div>
  ), [isSingle]);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    deleteMembers({
      onSuccess: (deletedIds, message) => {
        showSuccessMessage(message);

        if (isFunction(onDelete)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          onDelete(deletedIds);
        }
        setOpen(false);
      },
      onFailure: () => {
        showGenericErrorMessage();
        setOpen(false);
      },
    });
  }, [
    deleteMembers,
    onDelete,
    setOpen,
    showGenericErrorMessage,
    showSuccessMessage,
  ]);

  return (
    <>
      <Menu.Item
        className={styles.DeleteMemberMenuItem}
        onClick={openModal}
        key={BulkMenuAction.DeleteMember}
        disabled={isEmpty(memberIds)}
      >
        Delete Member
      </Menu.Item>
      <Modal
        title={`Permanently Delete Member${isSingle ? '' : 's'}?`}
        okText="Permanently Delete"
        cancelText="Cancel"
        centered
        zIndex={10000000}
        className={styles.DeleteModal}
        okButtonProps={{
          danger: true,
        }}
        open={open}
        confirmLoading={isDeleting}
        onOk={handleDelete}
        onCancel={closeModal}
        closable={false}
      >
        {confirmContent}
      </Modal>
    </>
  );
});
