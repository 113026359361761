/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_145um_157 {
  display: flex;
}

._justify-content-space-between_145um_161 {
  justify-content: space-between;
}

._tabular-nums_145um_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_145um_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._divider_145um_178 {
  margin: 1rem 0;
}

._footerDivider_145um_182 {
  margin: 1rem 0 0.5rem;
}

._createNewColumn_145um_186 {
  display: block;
  margin-bottom: 0.5rem;
}

._doNotImportButton_145um_191 {
  display: block;
  color: #d9534f;
}
._doNotImportButton_145um_191:hover {
  color: #d9534f;
}

._overlay_145um_199 {
  width: 16.25rem;
}
._overlay_145um_199 .ant-popover-inner-content {
  padding: 1rem;
}