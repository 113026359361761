import * as React from 'react';
import cx from 'classnames';
import { isNumber } from 'lodash';

import { TableContext, ITableColumnConfig } from '../tableContext';
import { CellContent } from './CellContent';

const { useContext, useCallback, useRef } = React;
import styles from './Cell.scss';

export interface ICellProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  config: ITableColumnConfig;

  contentClassName?: string;
  className?: string;

  isHeaderCell?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const Cell: React.RefForwardingComponent<HTMLDivElement, ICellProps> = React.memo(
  React.forwardRef((props, forwardRef) => {
    const {
      className,
      config,
      contentClassName,
      isHeaderCell,
      onClick,
      value,
      ...restProps
    } = props;
    const {
      config: tableConfig,
      editing,
      columnWidths,
      fullWidthCell,
    } = useContext(TableContext);

    // DT-1391: Disable grow for now as it leads to complications with column resizing
    // grow by default
    // unless specified as false, or when config.width is defined
    // const shouldGrow = isBoolean(config.grow) ? config.grow : isNil(config.width);

    const justify = config.justify ? config.justify : 'flex-start';
    const editingRef = useRef<boolean>(editing);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      if (editingRef.current) {
        e.preventDefault();
        e.stopPropagation();
      } else if (onClick) {
        onClick(e);
      }
      editingRef.current = editing;
    }, [editingRef, editing, onClick]);

    const columnWidth = columnWidths[config.field];
    const width = fullWidthCell ? '100%' : isNumber(columnWidth) ? `${columnWidth}px` : undefined;
    return (
      <div
        ref={forwardRef}
        className={cx(styles.Cell, className, {
          [styles.fixedHeight]: isNumber(tableConfig.rowHeight),
        })}
        style={{
          width,
          // flexGrow: shouldGrow ? 1 : undefined, // Disabled by DT-1391
        }}
        onClick={handleClick}
        onMouseDown={() => editingRef.current = editing}
        {...restProps}
      >
        <div
          className={cx(styles.content, contentClassName)}
          style={{
            justifyContent: justify,
          }}
        >
          {isHeaderCell && value}
          {!isHeaderCell && <CellContent config={config} value={value} />}
        </div>
      </div>
    );
  }),
);

Cell.displayName = 'Cell';
