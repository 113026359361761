import * as React from 'react';
import cx from 'classnames';
import { isNumber } from 'lodash';
import { useHotkeys } from 'react-hotkeys-hook';

import { Tooltip } from 'antd';

import { IResource } from '@frontend/app/hooks';

import { IEmailEditorState, SendEmailForm } from '@frontend/app/components';
import { Editor, IEditor } from '@frontend/app/components';
import { EmailEditorProvider } from '@frontend/app/components/MessageComposer/EmailEditor/EmailEditorContext';
import { VariableBlock, findVariableStrategy } from '@frontend/app/components/MessageComposer/EmailEditor/decorator';

import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';

import { RawDraftContentState } from 'draft-js';
import { Button } from '@revfluence/fresh';
import styles from './MessageComposer.scss';

const {
 useRef, useMemo, useState, useCallback,
} = React;

const MAX_RECIPIENTS_PER_BULK_MESSAGE = 450;

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
// this is a hack to check if rich text message is empty
const isMessageEmpty = (message: string) => !message || message === '<p></p>';

const reachedBulkLimit = (count: number) => count > MAX_RECIPIENTS_PER_BULK_MESSAGE;

const containsRealMembers = (members) => {
  if (!members) {
    return false;
  }

  for (const member of members) {
    // real member has id field
    if (isNumber(member.id)) {
      return true;
    }
  }

  return false;
};

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorState: any;
  onEditorStateChange?(state: IEmailEditorState): void;

  subject: string;
  onSubjectChange?(subject: string): void;

  onAdditionalCcChange?(members: Partial<IMember>[]): void;

  resource?: IResource;
  onResourceChange?(resourceId: number): void;
  members?: IMember[];
  className?: string;

  // sending from members page
  memberQuery?: IMemberSearchQuery;
  memberCount?: number;

  // trigger preview
  onPreview?(config: IPreviewConfig): void;
  initialValue?: string | RawDraftContentState;

  readOnly?: boolean;
}

export interface IEmailComposer {
  sendMessage(bulk?: boolean, excludeMemberIds?: number[]): void;
}

export interface IPreviewConfig {
  resource: IResource;
  subject: string;
  editorState: IEmailEditorState;
  initialValue?: string | RawDraftContentState;
  members?: IMember[];
}

const INIT_MESSAGE_HTML = `<div>Hello,</div>
<br />
<div>We wanted to let you know that we are creating the orders now for your free product. Look for further details in a following email shortly. Let us know if you have any questions!</div>
<br />
`;

/**
 * @type {React.FunctionComponent}
 */
const _EmailComposer: React.FunctionComponent<IProps> = (props) => {
  const editorRef = useRef<IEditor>(null);
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [isEditorExpanded, setIsEditorExpanded] = useState<boolean>(false);

  useHotkeys('enter', () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, [editorRef]);

  const toggleExpandEditor = useCallback(() => {
    setIsEditorExpanded((prev) => !prev);
  }, [setIsEditorExpanded]);

  const canSend = !!props.resource
    && (containsRealMembers(props.members))
    && !reachedBulkLimit(props.memberCount)
    && !!props.subject
    && !isMessageEmpty(props.editorState?.html);

  const tooltipMessage = !props.resource ? 'Please choose which email to send message from.'
    : !(containsRealMembers(props.members)) ? 'Recipients need to include at least one member.'
      : reachedBulkLimit(props.memberCount) ? `Cannot send message to more than ${MAX_RECIPIENTS_PER_BULK_MESSAGE} users.`
        : !props.subject ? 'Subject cannot be empty.'
          : isMessageEmpty(props.editorState?.html) ? 'Message body cannot be empty.' : '';

  const signaturePreview = useMemo(() => {
    switch (props.resource?.config.signature?.mode) {
      case 'html':
        return props.resource.config.signature?.fromHTML;
      case 'editor':
        return props.resource.config.signature?.fromEditor;
    }
  }, [props.resource]);

  const toFieldString = props.memberCount > 1
    ? `${props.memberCount} Members`
    : `${props.memberCount} Member`;

  if (props.readOnly) {
    let value = props.editorState.html;
    if (props.resource?.config?.signature?.fromHTML) {
      value += `<p></p>${props.resource?.config?.signature?.fromHTML}`;
    }
    return (
      <EmailEditorProvider>
        <Editor
          key={props.resource?.id} // force comp to update
          className={styles.editor}
          readOnly
          initialValue={value}
          convertFromHTML
          decorators={[{
            strategy: findVariableStrategy,
            component: VariableBlock,
          }]}
        />
      </EmailEditorProvider>
    );
  }

  return (
    <div
      className={cx(styles.EmailComposer, props.className, {
        [styles.editorExpanded]: isEditorExpanded,
      })}
    >
      <SendEmailForm
        disableRemoveRecipients
        toFieldString={toFieldString}
        members={props.members}
        memberQuery={props.memberQuery}
        subject={props.subject}
        resourceId={props.resource?.id}
        resourceType={props.resource?.type}
        editorRef={editorRef}
        initialValue={props.initialValue ? props.initialValue : INIT_MESSAGE_HTML}
        signaturePreview={signaturePreview}
        isInitialMessageHTML
        onResourceIdChange={props.onResourceChange}
        onSubjectChange={props.onSubjectChange}
        onAdditionalCcChange={props.onAdditionalCcChange}
        onEditorStateChange={props.onEditorStateChange}
        enableVariableReplacement
        enableMessageTemplate={false}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
        toggleExpandEditor={toggleExpandEditor}
        enableExpandingEmailEditor
        actions={[
          <Tooltip
            overlayStyle={{
              display: canSend ? 'none' : undefined,
            }}
            title={tooltipMessage}
            key="submit"
          >
            <Button
              key="submit"
              type="primary"
              disabled={!canSend}
              onClick={() => {
                props.onPreview({
                  resource: props.resource,
                  subject: props.subject,
                  editorState: props.editorState,
                  members: props.members,
                });
                setPreviewVisible(true);
              }}
            >
              Next
            </Button>
          </Tooltip>,
        ]}
      />
    </div>
  );
};

export default _EmailComposer;
