import { useQuery, QueryHookOptions } from '@apollo/client';

import { IContent } from '@frontend/app/hooks';

import { MESSAGE_TEMPLATE_QUERY } from '@frontend/app/queries';
import {
  MessageTemplateQuery_template,
  MessageTemplateQuery,
  MessageTemplateQueryVariables,
} from '@frontend/app/queries/types/MessageTemplateQuery';

import {
  TemplateTransportType,
  TemplateBodyType,
  TemplateType,
} from '@frontend/app/types/globalTypes';

export type MessageTemplate = MessageTemplateQuery_template;
export type MessageTemplateInput = {
  id: string;
  title: string;
  subject: string;
  text: string;
  transportType: TemplateTransportType;
  bodyType: TemplateBodyType;
  type: TemplateType;
  programId?: number;
  attachments?: IContent[];
};

type IOptions = QueryHookOptions<MessageTemplateQuery, MessageTemplateQueryVariables>;

export function useGetMessageTemplateById(options: IOptions = {}) {
  return useQuery<MessageTemplateQuery, MessageTemplateQueryVariables>(
    MESSAGE_TEMPLATE_QUERY,
    options,
  );
}
