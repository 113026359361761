import { ThunkAction } from 'redux-thunk';
import { endpoints, ISavedSearch } from '@components';
import { IConnectSearchPage, CreatorSearchVersion } from '../models';
import {
  searchAPIParamsSelector,
  textSearchOptionsSelector,
  imageSearchAPIParamsSelector,
} from './selectors';
import { ITextSearchAction, updateSearchFilters } from './textSearchActions';
import {
  IImageSearchAction,
  selectImage,
  updateCountries,
  updateHideInvited,
} from './imageSearchActions';
import {
  ISearchResultsAction,
  selectImageSearch,
  selectTextSearch,
  applySearchFilters,
} from './searchResultsActions';
import { mapAPIParamsToSearchFilters } from '../utils/searchParams';

export enum SavedSearchesActionTypes {
  FETCH_SEARCHES_REQUEST = '@connectSavedSearches/FETCH_SEARCHES_REQUEST',
  FETCH_SEARCHES_SUCCESS = '@connectSavedSearches/FETCH_SEARCHES_SUCCESS',
  FETCH_SEARCHES_FAILURE = '@connectSavedSearches/FETCH_SEARCHES_FAILURE',

  SAVE_SEARCH_REQUEST = '@connectSavedSearches/SAVE_SEARCH_REQUEST',
  SAVE_SEARCH_SUCCESS = '@connectSavedSearches/SAVE_SEARCH_SUCCESS',
  SAVE_SEARCH_FAILURE = '@connectSavedSearches/SAVE_SEARCH_FAILURE',

  DELETE_SEARCH_REQUEST = '@connectSavedSearches/DELETE_SEARCH_REQUEST',
  DELETE_SEARCH_SUCCESS = '@connectSavedSearches/DELETE_SEARCH_SUCCESS',
  DELETE_SEARCH_FAILURE = '@connectSavedSearches/DELETE_SEARCH_FAILURE',

  SELECT_SAVED_SEARCH = '@connectSavedSearches/SELECT_SAVED_SEARCH',
}

export interface ISavedSearchesAction {
  type: SavedSearchesActionTypes;
  payload?: {
    savedSearches?: ISavedSearch[];
    savedSearch?: ISavedSearch;
    version?: CreatorSearchVersion;
  };
  meta?: {
    error?: Error;
    errorMessage?: string;
  };
}

type SSThunkAction = ThunkAction<void, IConnectSearchPage, unknown, ISavedSearchesAction>;

type TSelectSavedSearchAction =
  | ISavedSearchesAction
  | ITextSearchAction
  | IImageSearchAction
  | ISearchResultsAction;

type TSelectSavedSearchThunkAction = ThunkAction<void, IConnectSearchPage, unknown, TSelectSavedSearchAction>;

export const clearSelectedSearch = (): SSThunkAction => (dispatch) => {
  dispatch({
    type: SavedSearchesActionTypes.SELECT_SAVED_SEARCH,
    payload: {},
  });
};

export const selectSavedSearch = (
  selectedSavedSearch: ISavedSearch,
): TSelectSavedSearchThunkAction => (dispatch, getState) => {
  dispatch({
    type: SavedSearchesActionTypes.SELECT_SAVED_SEARCH,
    payload: {
      savedSearch: selectedSavedSearch,
    },
  });

  const state = getState();

  const options = textSearchOptionsSelector(state);

  const newFilters = mapAPIParamsToSearchFilters(selectedSavedSearch.params, options);

  dispatch(updateSearchFilters(newFilters));

  const isImageSearch = !!selectedSavedSearch.params.image_url;

  if (isImageSearch) {
    dispatch(selectImage(selectedSavedSearch.params.image_url));
    dispatch(updateCountries(newFilters.creatorDemographics.countries));
    dispatch(updateHideInvited(newFilters.other.hideInvited));
    dispatch(selectImageSearch());
  } else {
    dispatch(selectTextSearch());
    dispatch(selectImage(null));
    dispatch(applySearchFilters());
  }
};

export const fetchSavedSearches = (): SSThunkAction => async (dispatch, getState) => {
  dispatch({
    type: SavedSearchesActionTypes.FETCH_SEARCHES_REQUEST,
  });

  const {
    external: { apiEndpoint, campaign, clientId },
  } = getState();

  try {
    const params = campaign ? `campaign_id=${campaign.id}` : `client_id=${clientId}`;
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.savedSearchParams}?${params}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      },
    );

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      const savedSearches = json.data || [];

      dispatch({
        type: SavedSearchesActionTypes.FETCH_SEARCHES_SUCCESS,
        payload: {
          savedSearches,
        },
      });
    } else {
      throw new Error('Failed to fetch saved searches');
    }
  } catch (err) {
    dispatch({
      type: SavedSearchesActionTypes.FETCH_SEARCHES_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const saveSearch = (savedSearchId: number, name: string): SSThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: SavedSearchesActionTypes.SAVE_SEARCH_REQUEST,
  });

  const state = getState();

  const {
    external: { apiEndpoint, campaign, clientId },
  } = state;

  const isImageSearch = state.searchResults.isImageSearch;

  try {
    const resp = await fetch(
      `${apiEndpoint}/${endpoints.savedSearchParams}${savedSearchId ? `/${savedSearchId}` : ''}`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          campaign_id: campaign && campaign.id,
          client_id: clientId,
          name,
          page: state.searchResults.currentPage + 1,
          params: isImageSearch
            ? imageSearchAPIParamsSelector(state)
            : searchAPIParamsSelector(state),
        }),
      },
    );

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: SavedSearchesActionTypes.SAVE_SEARCH_SUCCESS,
        payload: {
          savedSearch: json.data,
        },
      });
    } else {
      throw new Error('Failed to save search');
    }
  } catch (err) {
    dispatch({
      type: SavedSearchesActionTypes.SAVE_SEARCH_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};

export const deleteSearch = (savedSearch: ISavedSearch): SSThunkAction => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: SavedSearchesActionTypes.DELETE_SEARCH_REQUEST,
  });

  const state = getState();

  const {
    external: { apiEndpoint, clientId },
  } = state;

  try {
    const clientIdParam = clientId ? `?client_id=${clientId}` : '';
    const resp = await fetch(`${apiEndpoint}/${endpoints.savedSearchParams}/${savedSearch.id}${clientIdParam}`,
      {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });

    const json = await resp.json();

    if (json.status && json.status.code === 200) {
      dispatch({
        type: SavedSearchesActionTypes.DELETE_SEARCH_SUCCESS,
        payload: {
          savedSearch,
        },
      });
    } else {
      throw new Error('Failed to delete search');
    }
  } catch (err) {
    dispatch({
      type: SavedSearchesActionTypes.DELETE_SEARCH_FAILURE,
      meta: {
        error: err,
        errorMessage: err.message,
      },
    });
  }
};
