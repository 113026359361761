import { IUploadProgress, UploaderError } from '@frontend/app/hooks';

import {
  IAttachment,
  IAttachmentUploaderAction,
  EmailAttachmentActionTypes,
} from './model';

/** *************************
 ***** Private actions *****
 ************************* */
const addContent = (content: IAttachment): IAttachmentUploaderAction => ({
    type: EmailAttachmentActionTypes.ADD_CONTENT,
    payload: {
      content,
    },
  });

const updateProgress = (id: string, progress: IUploadProgress): IAttachmentUploaderAction => ({
    type: EmailAttachmentActionTypes.UPDATE_PROGRESS,
    payload: {
      id,
      progress,
    },
  });

const updateFileUrl = (id: string, fileUrl: string): IAttachmentUploaderAction => ({
    type: EmailAttachmentActionTypes.UPDATE_FILE_URL,
    payload: {
      id,
      fileUrl,
    },
  });

const removeContent = (id: string): IAttachmentUploaderAction => ({
    type: EmailAttachmentActionTypes.REMOVE_CONTENT,
    payload: {
      id,
    },
  });

const removeContents = (): IAttachmentUploaderAction => ({
    type: EmailAttachmentActionTypes.REMOVE_CONTENTS,
  });

const setErrorMessage = (
  errorMessage: string = UploaderError.DefaultMessage,
): IAttachmentUploaderAction => ({
    type: EmailAttachmentActionTypes.SET_ERROR_MESSAGE,
    payload: {
      errorMessage,
    },
  });

export const actions = {
  addContent,
  updateProgress,
  removeContent,
  removeContents,
  updateFileUrl,
  setErrorMessage,
};
