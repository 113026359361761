import { assign, clone } from 'lodash';
import { ICreatorList, ICreatorListAction } from './creatorListModel';

const reducer = (state: ICreatorList, action: ICreatorListAction): ICreatorList => {
  switch (action.type) {
    case '@creatorList/SET_SOCIAL_ACCOUNTS': {
      const { socialAccounts } = action.payload;

      return assign({}, state, {
        socialAccounts,
      });
    }

    case '@creatorList/SET_FAVORITE_LISTS': {
      const { favoriteLists } = action.payload;

      return assign({}, state, {
        favoriteLists,
      });
    }

    case '@creatorList/ADD_FAVORITE_LIST': {
      const { favoriteList } = action.payload;
      const { favoriteLists } = state;

      return assign({}, state, {
        favoriteLists: [...favoriteLists, favoriteList],
      });
    }

    case '@creatorList/UPDATE_FAVORITE_LIST': {
      const { id, favoriteList } = action.payload;
      const { favoriteLists } = state;
      const index = favoriteLists.findIndex((favoriteList) => favoriteList.id === id);

      return assign({}, state, {
        favoriteLists: [
          ...favoriteLists.slice(0, index),
          {
            ...favoriteList,
          },
          ...favoriteLists.slice(index + 1),
        ],
      });
    }

    case '@creatorList/REMOVE_FAVORITE_LIST': {
      const { id } = action.payload;
      const { favoriteLists } = state;
      const index = favoriteLists.findIndex((favoriteList) => favoriteList.id === id);

      return assign({}, state, {
        favoriteLists: [...favoriteLists.slice(0, index), ...favoriteLists.slice(index + 1)],
      });
    }

    case '@creatorList/SET_IS_FETCHING_FAVORITE_LISTS': {
      const { isFetchingFavoriteLists } = action.payload;

      return assign({}, state, {
        isFetchingFavoriteLists,
      });
    }

    case '@creatorList/SET_IS_EDITING_FAVORITE_LIST': {
      const { isEditingFavoriteList } = action.payload;

      return assign({}, state, {
        isEditingFavoriteList,
      });
    }

    case '@creatorList/INVITED_TO_CAMPAIGN': {
      const { accountIds } = action.payload;
      const { socialAccounts } = state;

      // update invite field
      const newSocialAccounts = clone(socialAccounts).map((socialAccount) => {
        if (!accountIds.includes(socialAccount.id)) {
          return socialAccount;
        }

        return {
          ...socialAccount,
          invite: {
            ...socialAccount.invite,
            approved: true,
          },
        };
      });

      return assign({}, state, {
        socialAccounts: newSocialAccounts,
      });
    }

    default:
      return state;
  }
};

export default reducer;
