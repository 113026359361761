import * as React from 'react';
import { useCallback, useState } from 'react';

import { isEmpty, map } from 'lodash';

import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';

import { InvalidMembersCPS, BasicMember } from '@frontend/applications/ProductFulfillmentApp/pages/InvalidMembersCPS';

import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { useAuth } from '@frontend/context/authContext';

import { CPSEmailComposer } from '../EmailComposer/EmailComposer';

import getInitialEmailState from '../../utils/initialEmailStateOrderRequestReview';
import styles from '../SendOrderRequestEmail/MessageComposer.scss';

interface IProps {
  sendRejectionEmail: (messageParams: SendMessageInput) => void | Promise<void>;
}

export const RejectOrderRequestEmail: React.FC<Readonly<IProps>> = ({ sendRejectionEmail }: IProps) => {
  const {
    allMembers,
    getMemberShippingAddress,
  } = useProductFulfillmentContext();
  const [showInvalidMembers, setShowInvalidMembers] = useState(true);

  const validMembers = allMembers ? allMembers.filter((m) => m.email) : [];
  const invalidMembers = allMembers ? allMembers.filter((m) => !m.email) : [];
  const memberIds = validMembers.map((m) => m.id);
  const { user, clientInfo } = useAuth();

  const getInitialEmailStateCallback = useCallback(
    () => getInitialEmailState(user.name, clientInfo.name, 'reject', false, allMembers.length),
    [user, clientInfo, allMembers],
  );
  if (!isEmpty(invalidMembers) && showInvalidMembers) {
    const memberInfo: BasicMember[] = map(invalidMembers, (member) => ({
      first_name: getMemberShippingAddress(member)?.first_name,
      last_name: getMemberShippingAddress(member)?.last_name,
      email: member.email,
    }));
    return (
      <InvalidMembersCPS
        memberInfo={memberInfo}
        onContinue={() => setShowInvalidMembers(false)}
        canContinue={!isEmpty(validMembers)}
        emailSubject="order request rejection"
        requiredFields={['email']}
      />
    );
  }

  return (
    <>
      <div className={styles.MessageComposer}>
        <CPSEmailComposer
          className={styles.EmailComposer}
          memberIds={memberIds}
          customSend={sendRejectionEmail}
          getInitialState={getInitialEmailStateCallback}
        />
      </div>
    </>
  );
};
