import * as React from 'react';

import { Button, Notice } from '@affiliates/AspireUI';

import styles from './ShopifyScopesMissing.scss';

const SCOPES_MISSING_MESSAGE = `
  Please disconnect and reconnect your Shopify store (this will need to be someone
  who has appropriate access to your store). Sometimes, Shopify refreshes permissions which
  requires you to reconnect your store to allow Aspire to continue to support your workflows.
`.trim();

interface IProps {
  onClick: () => void;
}

export const ShopifyScopesMissing: React.FC<Readonly<IProps>> = (props) => {
  const { onClick } = props;

  return (
    <Notice
      type="error"
      actions={(
        <div className={styles.actionsWrapper}>
          <Button
            className={styles.button}
            onClick={onClick}
            title="Reconnect"
            type="primary"
          >
            Reconnect
          </Button>
        </div>
      )}
      className={styles.ShopifyScopesMissing}
      message={SCOPES_MISSING_MESSAGE}
    />
  );
};
