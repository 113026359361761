/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_bt8ma_157 {
  display: flex;
}

._justify-content-space-between_bt8ma_161 {
  justify-content: space-between;
}

._tabular-nums_bt8ma_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_bt8ma_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MemberTable_bt8ma_178 ._notice_bt8ma_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._MemberTable_bt8ma_178 {
  flex: 1;
  overflow: hidden;
}
._MemberTable_bt8ma_178 ._table_bt8ma_192 {
  height: 100%;
}
._MemberTable_bt8ma_178 ._notice_bt8ma_178 {
  margin-bottom: 1.25rem;
}
._MemberTable_bt8ma_178 ._notice_bt8ma_178 ._btn_bt8ma_198 {
  min-width: 5.3125rem;
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
  margin-left: 1rem;
}
._MemberTable_bt8ma_178 ._memberListLoadingSpinner_bt8ma_204 {
  height: 6.25rem;
  margin-top: 15%;
}
._MemberTable_bt8ma_178 ._emptyCell_bt8ma_208 {
  color: #aeaeae;
  pointer-events: none;
}