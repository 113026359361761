import * as React from 'react';
import { map, find } from 'lodash';
import cx from 'classnames';
import { useEffect, useState, useRef } from 'react';

import { useGetMemberQuery, useMemberFieldSchemasQuery } from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';

import {
  Notice, LoadSpinner,
} from '@components';
import { IPreviewConfig, IEmailComposer } from '@frontend/app/components/MessageComposer/EmailComposer';
import { EmailComposer } from '@frontend/app/components';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { SocialAccountAuthCell } from './SocialAccountAuthCell';

import { ISocialAccount, useFetchSocialAccountData } from '../useFetchSocialAccountData';
import { INSTAGRAM_USERNAME_FIELD } from '../appFields';

import styles from './MemberInsightsAuth.scss';

const MemberInsightsAuth: React.FunctionComponent = () => {
  const { backendServerApiEndpoint, clientId, memberId } = useApplication();
  const [instagramUsername, setInstagramUsername] = useState<string>(null);
  const [message, setMessage] = useState<string>(null);
  const [accountToRequestAuth, setAccountToRequestAuth] = useState<ISocialAccount>(null);
  const messageFormRef = useRef<IEmailComposer>(null);
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);
  const { user } = useAuth();

  const {
    loading: memberLoading,
    data: memberData,
    error: getMemberError,
  } = useGetMemberQuery(Number(memberId));
  let internalError = false;

  const {
    data: {
      schemas = null,
    } = {},
    loading: loadingSchemas,
  } = useMemberFieldSchemasQuery();

  useEffect(() => {
    const instagramField = find(schemas, { name: INSTAGRAM_USERNAME_FIELD });
    if (!instagramField) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      internalError = true;
      return;
    }

    if (memberData) {
      setInstagramUsername(memberData.member.fields[instagramField.id]);
    }
  }, [memberData, schemas]);

  const error = getMemberError || internalError;
  const url = `${backendServerApiEndpoint}/social_account`;
  const {
    data: socialAccountData,
    loading: loadingSocialData,
  } = useFetchSocialAccountData({
    url,
    clientId,
    username: instagramUsername,
    network: 'instagram',
  });

  const onAuth = (account: ISocialAccount, url: string) => {
    setAccountToRequestAuth(account);
    setMessage(`Hi ${memberData.member.name},

We’re excited to collaborate with you!

In order for us to see the impact of your Instagram posts within the ambassador program, please link your Instagram Insights. Once you do so, we will be able to see your post and stories results.

We partner with Aspire, a leader in helping brands and creators collaborate, to manage this program.

Read more about what this means and then link your Instagram Insights here: ${url}

Cheers,
${user.name}`);
  };

  const onMessageSent = () => {
    setAccountToRequestAuth(null);
  };

  return (
    <div className={styles.MemberInsightsAuth}>
      {accountToRequestAuth
        ? (
          <>
            <EmailComposer
              ref={messageFormRef}
              onMessageSent={onMessageSent}
              subject="Please link Instagram Insights"
              subjectIsEditable
              members={[memberData.member]}
              initialMessage={message}
              onPreview={setPreviewConfig}
              memberCount={1}
              className={cx(styles.messageForm, {
                [styles.hide]: !!previewConfig,
              })}
              disableEmailInput
              enableExpandingEmailEditor
            />
            {previewConfig && (
              <EmailPreviewer
                previewConfig={previewConfig}
                onBack={() => setPreviewConfig(null)}
                memberCount={1}
                onConfirm={() => {
                  setPreviewConfig(null);

                  messageFormRef.current.sendMessage(true);
                }}
                disableRemoveRecipient
              />
            )}
          </>
        )
        : (
          <div>
            <Notice>Asking members to link Instagram Insights allows you to report on Instagram stories, get impressions data on posts, and analyze demographics data of the member's audience.</Notice>
            <span className={styles.standard_label}>Listening for posts on:</span>
            {(memberLoading || loadingSchemas || loadingSocialData) ? <LoadSpinner /> : (
              <div>
                {socialAccountData && socialAccountData.length > 0
                ? (
                  <div>
                    {map(socialAccountData, (socialAccount, index) => (
                      <SocialAccountAuthCell account={socialAccount} onAuth={onAuth} key={index} />
                  ))}
                  </div>
)
                : <Notice type="disabled">This member has no Instagram accounts to authorize. Assign an Instagram account first to get started.</Notice>}
              </div>
          )}
            {error && <Notice>An error has occurred. Please try refreshing, and if this errors persists, please contact help@aspireiq.com</Notice>}
          </div>
)}
    </div>
  );
};

export default MemberInsightsAuth;
