import { format } from 'date-fns';

const getTimeStamp = (date) => {
    const targetDate = date ? new Date(date) : new Date();
    const unixTimestamp = Math.floor(targetDate.getTime() / 1000);
    return unixTimestamp;
};
const getLocaleString = (date: Date) => format(new Date(date), 'MMM d, yyyy h:mm a');

function convertToHHMM(inputDate: Date): string {
    const date = new Date(inputDate);
    const hours: string = (`0${date.getUTCHours()}`).slice(-2);
    const minutes: string = (`0${date.getUTCMinutes()}`).slice(-2);
    return `${hours}:${minutes}`;
}

export { getTimeStamp, getLocaleString, convertToHHMM };
