import * as React from 'react';
import { map } from 'lodash';

import { IYoutubeAccount } from '@components';
import { CreatorSearchVersion } from '@frontend/components/pages/SearchPage/models';
import {
  CityStateCard,
  CountryCard,
  GenderAgeCard,
  ListCard,
  PostPerformanceCard,
  ProfileCard,
} from '../Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';
import { Tab } from '../models';

import styles from './Content.scss';

export const YoutubeContent: React.FC<{ version: CreatorSearchVersion }> = () => {
  const {
    activeTab,
    socialAccount,
    hasData,
  } = useSocialProfileContext();

  const youtubeAccount = socialAccount as IYoutubeAccount;

  const topHashtags = hasData && youtubeAccount.top_hashtags || [];
  const topCategories = hasData && youtubeAccount.top_categories || [];
  const topPlaylists = hasData && map(
    youtubeAccount.playlists,
    (playlist, i) => (playlist.url
      ? (
        <a
          href={playlist.url}
          title={playlist.title}
          target="_blank"
          rel="noopener noreferrer"
          key={i}
        >
          {playlist.title}
        </a>
      )
      : playlist.title)
    ,
  );

  return (
    <>
      {(!activeTab || activeTab === Tab.SUMMARY) && (
      <div className={styles.summary}>
        <ProfileCard className={styles.ProfileCard} />
        <ListCard
          className={styles.HashtagsCard}
          items={topHashtags}
          flow="inline"
          title="Top Hashtags"
        />
        <ListCard
          className={styles.TopCategoriesCard}
          itemsClass={styles.items}
          itemClass={styles.item}
          items={topCategories}
          title="Top Categories"
        />
        <ListCard
          className={styles.TopPlaylistsCard}
          itemsClass={styles.items}
          itemClass={styles.item}
          items={topPlaylists}
          title="Top Playlists"
        />
        <PostPerformanceCard className={styles.PostPerformanceCard} />
      </div>
    )}
      {activeTab === Tab.ANALYZE && (
      <div className={styles.analyze}>
        <GenderAgeCard
          className={styles.GenderCard}
          hideAge
        />
        <GenderAgeCard
          className={styles.AgeCard}
          hideGender
        />
        <CountryCard
          className={styles.CountryCard}
          maxRecords={8}
        />
        <CityStateCard
          className={styles.StateCard}
          maxRecords={8}
          hideCity
        />
      </div>
    )}
    </>
);
};

YoutubeContent.displayName = 'YoutubeContent';
