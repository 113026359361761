import { ActionTypes } from '../../types/actionTypes';
import {
  IState,
  TAction,
} from '../../types/state';
import { selectProject } from './actions/selectProject';
import { loadContentGuidelines } from './actions/loadContentGuidelines';
import { duplicateContentGuideline } from './actions/duplicateContentGuideline';
import { deleteContentGuideline } from './actions/deleteContentGuideline';
import { saveContentGuideline } from './actions/saveContentGuideline';
import { updateContentGuidelineInstruction } from './actions/updateContentGuidelineInstruction';
import { sortContentGuideline } from './actions/sortContentGuideline';
import { addContentGuidelineAttachment } from './actions/addContentGuidelineAttachment';
import { deleteContentGuidelineAttachment } from './actions/deleteContentGuidelineAttachment';
import { selectTemplateType } from './actions/selectTemplateType';
import { updateShowHideTemplates } from './actions/updateShowHideTemplates';
import { selectOtherProject } from './actions/selectOtherProject';
import { prevStep } from './actions/prevStep';
import { nextStep } from './actions/nextStep';
import { updateContentGuidelineLabel } from './actions/updateContentGuidelineLabel';
import { updateContentGuidelineActiveKey } from './actions/updateContentGuidelineActiveKey';
import { updateContentGuidelineTitle } from './actions/updateContentGuidelineTitle';

// we deliberately want typescript to handle the fact we do not have a default
// case through its typechecking of `action.type`
/* eslint-disable default-case */
/* eslint-disable consistent-return */
export const reducer = (state: IState, action: TAction): IState => {
  switch (action.type) {
    case ActionTypes.SelectProject:
      return selectProject(state, action);
    case ActionTypes.LoadContentGuidelines:
      return loadContentGuidelines(state, action);
    case ActionTypes.DuplicateContentGuideline:
      return duplicateContentGuideline(state, action);
    case ActionTypes.DeleteContentGuideline:
      return deleteContentGuideline(state, action);
    case ActionTypes.UpdateContentGuidelineInstruction:
      return updateContentGuidelineInstruction(state, action);
    case ActionTypes.SortContentGuideline:
      return sortContentGuideline(state, action);
    case ActionTypes.AddContentGuidelineAttachment:
      return addContentGuidelineAttachment(state, action);
    case ActionTypes.DeleteContentGuidelineAttachment:
      return deleteContentGuidelineAttachment(state, action);
    case ActionTypes.SelectTemplateType:
      return selectTemplateType(state, action);
    case ActionTypes.UpdateShowHideTemplatesModal:
      return updateShowHideTemplates(state, action);
    case ActionTypes.SaveContentGuideline:
      return saveContentGuideline(state, action);
    case ActionTypes.SelectOtherProject:
      return selectOtherProject(state, action);
    case ActionTypes.NextStep:
      return nextStep(state, action);
    case ActionTypes.PrevStep:
      return prevStep(state, action);
    case ActionTypes.UpdateContentGuidelineLabel:
      return updateContentGuidelineLabel(state, action);
    case ActionTypes.UpdateContentGuidelineActiveKey:
      return updateContentGuidelineActiveKey(state, action);
    case ActionTypes.UpdateContentGuidelineTitle:
      return updateContentGuidelineTitle(state, action);
  }
};
