import * as React from 'react';
import {
  TNetworkIconType,
  ToolsIcon,
} from '@components';
import {
  Row,
  Col,
  Card as FreshCard,
  Avatar,
} from '@revfluence/fresh';
import {
  InstagramIcon,
  YoutubeIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  SnapchatIcon,
  TiktokIcon,
} from '@revfluence/fresh-icons/brands/esm';
import {
  BlogIcon,
  ImageIcon,
  VideoIcon,
} from '@revfluence/fresh-icons/solid/esm';

import styles from './TopCreatorMeta.module.scss';

export interface ITopCreator {
  username: string;
  network: TNetworkIconType;
  link: string;
  profile_image_url: string;
  rating: number;
  engagements: number;
  engagement_rate: number;
}

interface IProps {
  creator: ITopCreator;
}

const { useMemo } = React;

const TopCreatorMeta: React.FC<IProps> = React.memo((props) => {
  const { creator } = props;
  const icon = useMemo(
    () => {
      switch (creator.network) {
        case 'blog': return <BlogIcon />;
        case 'instagram': return <InstagramIcon />;
        case 'youtube': return <YoutubeIcon />;
        case 'facebook': return <FacebookIcon />;
        case 'pinterest': return <PinterestIcon />;
        case 'twitter': return <TwitterIcon />;
        case 'snapchat': return <SnapchatIcon />;
        case 'tiktok': return <TiktokIcon />;
        case 'additional_images': return <ImageIcon />;
        case 'additional_videos': return <VideoIcon />;
        case 'other': return <ToolsIcon />;
      }
    },
    [creator.network],
  );
  return (
    <FreshCard.Meta
      avatar={(
        <Avatar
          icon={icon}
          size={36}
          style={{
            fontSize: '24px',
          }}
        />
      )}
      className={styles.TopCreatorMeta}
      description={(
        <>
          <Row>
            <Col>{creator.username}</Col>
          </Row>
          <Row justify="space-between">
            <Col>Engmt Rate</Col>
            <Col className={styles.count}>
              {(creator.engagement_rate * 100).toFixed(2)}
              %
            </Col>
          </Row>
        </>
      )}
    />
  );
});

export default TopCreatorMeta;
TopCreatorMeta.displayName = 'TopCreatorMeta';
