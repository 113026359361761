import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const HomeIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 18 20">
    <path d="M8.6676 0.110815L0.213058 6.65628C0.0805126 6.75817 -0.000650616 6.92376 3.92924e-06 7.09094V19.091C3.1202e-05 19.3766 0.259858 19.6364 0.545458 19.6364H6.27273C6.55833 19.6364 6.81816 19.3766 6.81818 19.091V12.5455H11.1818V19.091C11.1818 19.3766 11.4417 19.6364 11.7273 19.6364H17.4545C17.7401 19.6364 18 19.3766 18 19.091V7.09094C18 6.92376 17.9195 6.75817 17.7869 6.65628L9.3324 0.110815C9.08392 -0.044476 8.86991 -0.0292033 8.6676 0.110815ZM9 1.22731L16.9091 7.35515V18.5455H12.2727V12C12.2727 11.7144 12.0129 11.4546 11.7273 11.4546H6.27273C5.98713 11.4546 5.7273 11.7144 5.72728 12V18.5455H1.09091V7.35515L9 1.22731Z" />
  </SvgIcon>
));

HomeIcon.displayName = 'HomeIcon';
