import { ContentType } from '@frontend/app/types/globalTypes';

export const isInstagram = (
  type: ContentType,
): boolean => {
  switch (type) {
    case ContentType.INSTAGRAM:
    case ContentType.INSTAGRAM_POST:
    case ContentType.INSTAGRAM_REEL:
    case ContentType.INSTAGRAM_STORY:
    case ContentType.INSTAGRAM_VIDEO:
      return true;
    default:
      return false;
  }
};

export const isTiktok = (
  type: ContentType,
): boolean => {
  switch (type) {
    case ContentType.TIKTOK_VIDEO:
      return true;
    default:
      return false;
  }
};
