import {
    Collapse,
  } from '@revfluence/fresh';
  import React from 'react';
  import { map } from 'lodash';
  import { DetailDeepLink } from './DetailDeeplink';
  import { Section, SectionHeader } from './DeeplinkSection';
  import styles from './DisplayDeeplinks.scss';
import { IAdditionalDeepLinks } from '../MemberTable/types';

  interface DisplayDeepLinkProps {
    deepLinks: IAdditionalDeepLinks[];
    expandOnCreated: boolean;
    offerLinkUrl?: string;
    domains?: string[] | null | undefined;
    primaryShortlink?: string
    affiliateOfferLinkId?: number
    primaryDeeplinkUrl?: string
    primaryCreationDate?: string | Date
    refresh?: ()=>void;
    setDeeplinkDrawerVisible?: (val: boolean)=>void;
    isOfferArchived: boolean
  }

  export const DisplayDeeplink = ({
    deepLinks, expandOnCreated, offerLinkUrl, domains, affiliateOfferLinkId, primaryShortlink, primaryDeeplinkUrl, primaryCreationDate, refresh, setDeeplinkDrawerVisible, isOfferArchived,
  }: DisplayDeepLinkProps) => (
    <div>
      <div className={styles.deeplinksList}>
        <Collapse expandIconPosition="left" defaultActiveKey={expandOnCreated ? ['0'] : []} className={styles.collapseBg} ghost>
          <Section
            key={-1}
            header={<SectionHeader label="Primary Link" shortLink={primaryShortlink} />}
            render={() => (
              <DetailDeepLink
                offerLinkUrl={offerLinkUrl}
                domains={domains}
                isPrimaryDeeplink
                primaryDeeplink={{
                    affiliateOfferLinkId,
                    shortLink: primaryShortlink,
                    url: primaryDeeplinkUrl,
                    createdDate: primaryCreationDate,
                  }}
                refresh={refresh}
                setDeeplinkDrawerVisible={setDeeplinkDrawerVisible}
                isOfferArchived={isOfferArchived}
              />
              )}
            loading={false}
          />
          {map(deepLinks, (deepLink, index: number) => (
            <Section
              key={index}
              header={<SectionHeader label={deepLink.label} shortLink={deepLink.shortLink} />}
              render={() => (
                <DetailDeepLink deepLink={deepLink} offerLinkUrl={offerLinkUrl} domains={domains} refresh={refresh} setDeeplinkDrawerVisible={setDeeplinkDrawerVisible} isOfferArchived={isOfferArchived} />
                )}
              loading={false}
            />
            ))}
        </Collapse>
      </div>
    </div>
    );
