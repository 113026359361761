import 'css-chunk:src/applications/AffiliatesApp/components/OfferForm/OfferForm.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_f4x9n_157",
  "justify-content-space-between": "_justify-content-space-between_f4x9n_161",
  "tabular-nums": "_tabular-nums_f4x9n_165",
  "OfferForm": "_OfferForm_f4x9n_178",
  "instructions": "_instructions_f4x9n_178",
  "title": "_title_f4x9n_178",
  "description": "_description_f4x9n_225",
  "formSection": "_formSection_f4x9n_229",
  "icon": "_icon_f4x9n_244",
  "headerSpace": "_headerSpace_f4x9n_258",
  "dividerColor": "_dividerColor_f4x9n_261",
  "customLabelText": "_customLabelText_f4x9n_273",
  "AlertWrapper": "_AlertWrapper_f4x9n_276",
  "codeSuffixWrapper": "_codeSuffixWrapper_f4x9n_279",
  "halfRow": "_halfRow_f4x9n_284",
  "descriptionWrapper": "_descriptionWrapper_f4x9n_287",
  "descriptionText": "_descriptionText_f4x9n_290",
  "indented": "_indented_f4x9n_294",
  "centered": "_centered_f4x9n_300",
  "radioGroup": "_radioGroup_f4x9n_303",
  "radio": "_radio_f4x9n_303",
  "expiredMessage": "_expiredMessage_f4x9n_313",
  "datePicker": "_datePicker_f4x9n_321",
  "tooltipList": "_tooltipList_f4x9n_330",
  "hThreeRem": "_hThreeRem_f4x9n_338",
  "hasCharacterCount": "_hasCharacterCount_f4x9n_341",
  "tagIcon": "_tagIcon_f4x9n_344",
  "fullWidth": "_fullWidth_f4x9n_347",
  "archiveOfferAlert": "_archiveOfferAlert_f4x9n_350",
  "sharedWrapper": "_sharedWrapper_f4x9n_354",
  "multipleConversionWrapper": "_multipleConversionWrapper_f4x9n_354",
  "utmWrapper": "_utmWrapper_f4x9n_354",
  "urlwrapper": "_urlwrapper_f4x9n_368",
  "boxwrapper": "_boxwrapper_f4x9n_373",
  "urltitle": "_urltitle_f4x9n_380",
  "subheading": "_subheading_f4x9n_384",
  "utmexample": "_utmexample_f4x9n_388",
  "customText": "_customText_f4x9n_395",
  "inputBox": "_inputBox_f4x9n_404",
  "Closefield": "_Closefield_f4x9n_407",
  "disable-option": "_disable-option_f4x9n_415",
  "ant-select-item-option-content": "_ant-select-item-option-content_f4x9n_415",
  "promoCodeContainer": "_promoCodeContainer_f4x9n_446",
  "descTextColor": "_descTextColor_f4x9n_446",
  "customRadioGroup": "_customRadioGroup_f4x9n_450",
  "samplePromoContainer": "_samplePromoContainer_f4x9n_457",
  "purchaseTypeContainer": "_purchaseTypeContainer_f4x9n_463",
  "offerSyncContainer": "_offerSyncContainer_f4x9n_471",
  "customSyncText": "_customSyncText_f4x9n_474",
  "offerSyncForm": "_offerSyncForm_f4x9n_478",
  "syncContainer": "_syncContainer_f4x9n_481",
  "offerStatusContainer": "_offerStatusContainer_f4x9n_485",
  "CustomTextColor": "_CustomTextColor_f4x9n_489",
  "disableRadio": "_disableRadio_f4x9n_528",
  "payoutOptionContainer": "_payoutOptionContainer_f4x9n_533",
  "startDateBorder": "_startDateBorder_f4x9n_537",
  "mPayoutConatiner": "_mPayoutConatiner_f4x9n_543",
  "addPayoutButton": "_addPayoutButton_f4x9n_548",
  "defaultPayout": "_defaultPayout_f4x9n_552",
  "radioCustomContainer": "_radioCustomContainer_f4x9n_559",
  "radioItem": "_radioItem_f4x9n_564",
  "image": "_image_f4x9n_567",
  "marginOnAlert": "_marginOnAlert_f4x9n_574",
  "errorMessage": "_errorMessage_f4x9n_580",
  "alertContainer": "_alertContainer_f4x9n_584",
  "indentationMargin": "_indentationMargin_f4x9n_588",
  "marginBottom8": "_marginBottom8_f4x9n_593",
  "show": "_show_f4x9n_1"
};