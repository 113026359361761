import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const RoundAddCircleOutlineIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12,2C6.49,2,2,6.49,2,12c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10C22,6.49,17.51,2,12,2z M12,20.77
      c-4.84,0-8.77-3.94-8.77-8.77S7.16,3.23,12,3.23s8.77,3.94,8.77,8.77S16.84,20.77,12,20.77z"
    />
    <path
      d="M16.69,11.39h-4.08V7.31c0-0.34-0.27-0.61-0.61-0.61c-0.34,0-0.61,0.27-0.61,0.61v4.08H7.31
      c-0.34,0-0.61,0.27-0.61,0.61c0,0.34,0.27,0.61,0.61,0.61h4.08v4.08c0,0.34,0.27,0.61,0.61,0.61c0.34,0,0.61-0.27,0.61-0.61v-4.08
      h4.08c0.34,0,0.61-0.27,0.61-0.61C17.31,11.66,17.03,11.39,16.69,11.39z"
    />
  </SvgIcon>
));

RoundAddCircleOutlineIcon.displayName = 'RoundAddCircleOutlineIcon';
