import { useQuery } from '@apollo/client';
import { GET_THREAD_QUERY } from '@frontend/app/queries';
import {
  GetThreadQuery,
  GetThreadQueryVariables,
} from '@frontend/app/queries/types/GetThreadQuery';
import { isFunction } from 'lodash';

export function useFetchThread(threadId: string, onCompleted?: () => void) {
  const {
    loading,
    data: { thread = null } = {},
    error,
    refetch,
  } = useQuery<GetThreadQuery, GetThreadQueryVariables>(
    GET_THREAD_QUERY,
    {
      variables: {
        id: threadId,
        includeReadStatus: true,
      },
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        if (isFunction(onCompleted)) onCompleted();
      },
    },
  );

  return {
    loading,
    thread,
    error,
    refetch,
  };
}
