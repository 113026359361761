import {
  useCallback, useMemo, useReducer,
} from 'react';
import {
  filter, first, groupBy, isEmpty,
} from 'lodash';
import moment from 'moment';

import {
  ACTIVE_DATE_FORMAT,
  ACTIVE_TIME_FORMAT,
  TRefreshMembersProps,
} from '@affiliates/components/MembersWizard/types';
import { ForceCheckInPromosVariables } from '@affiliates/queries/types/ForceCheckInPromos';
import { OFFER_SOURCE, UPSERT_PROMO_STA_TASK_ID } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
  getDefaultState,
  reducer,
  TActions,
  TState,
} from './state';

type TToReturn = Readonly<{
  actions: TActions;
  state: TState;
}>;

export const useState = (props: TRefreshMembersProps): TToReturn => {
  const {
    offerId,
    members,
    workItems = [],
  } = props;
  const defaultState = useMemo(
    () => getDefaultState(props.members, props.refreshDatesOnly, props.isNewFlow),
    [props.members, props.refreshDatesOnly, props.isNewFlow],
  );
  const workItemByMemberIdDictionary = useMemo(() => groupBy(workItems, (workItem) => workItem.data.memberId), [workItems]);
  const [state, dispatch] = useReducer(reducer, defaultState);
  const actions = {
    dismissCloseConfirmation: useCallback(() => {
      dispatch({
        showCloseConfirmation: false,
        type: 'UPDATE SHOW CLOSE CONFIRMATION',
      });
    }, []),
    reset: useCallback(() => {
      dispatch({
        members,
        type: 'RESET DEFAULT STATE',
      });
    }, [members]),
    save: useCallback((step: number) => {
      dispatch({
        saving: true,
        type: 'UPDATE SAVING STATE',
      });
      let variables = null;
      const selectedMembers = filter(state.members, (m) => m.selected);
      let forceCheckInVariables: ForceCheckInPromosVariables;
      switch (props.offerSource) {
        case OFFER_SOURCE.SHOPIFY:
          if (!isEmpty(selectedMembers)) {
            const startDate = moment(
              `${state.activeDates.startDate} ${state.activeDates.startTime}`,
              `${ACTIVE_DATE_FORMAT} ${ACTIVE_TIME_FORMAT}`,
            ).toISOString(false);
            const endDate = moment(
              `${state.activeDates.endDate} ${state.activeDates.endTime}`,
              `${ACTIVE_DATE_FORMAT} ${ACTIVE_TIME_FORMAT}`,
            ).toISOString(false);
            variables = {
              affiliates: filter(state.members, (m) => m.selected).map((m) => ({
                memberId: m.id,
                desiredPromoCode: m.code,
              })),
              endDate,
              startDate,
              id: props.offerId,
            };
            if (props.isWorkflow) {
              variables.affiliates = variables.affiliates.map((affiliate) => {
                const [workItem] = workItemByMemberIdDictionary[affiliate.memberId];
                return ({
                  ...affiliate,
                  workItemId: workItem?.id,
                  workflowTask: UPSERT_PROMO_STA_TASK_ID.fix_error_promo_task,
                });
              });
            }
          }

          const forceCheckInMembers = filter(state.members, (m) => m.forceCheckIn);
          if (!isEmpty(forceCheckInMembers)) {
            forceCheckInVariables = {
              offerId,
              forceCheckInAffiliates: forceCheckInMembers.map((member) => {
                const [workItem] = workItemByMemberIdDictionary[member.id];
                return {
                  memberId: member.id,
                  workItemId: workItem?.id,
                  workflowTask: UPSERT_PROMO_STA_TASK_ID.fix_error_promo_task,
                };
              }),
            };
          }
          break;
        case OFFER_SOURCE.TUNE:
          const { offer } = props;
          const { affiliateStats } = first(offer.links);
          variables = {
            ids: filter(affiliateStats, (affiliateStat) => {
              const affiliate = selectedMembers.find(({ id }) => id === affiliateStat.aspirexMemberId);
              return !!affiliate;
            }).map((m) => m.affiliateOfferId),
          };
          break;
      }
      props.onSave(variables, [], forceCheckInVariables)
        .then(() => {
          dispatch({
            saving: false,
            type: 'UPDATE SAVING STATE',
          });
          dispatch({
            step,
            type: 'SET CURRENT STEP',
          });
        })
        .catch((error) => {
          dispatch({
            saving: false,
            type: 'UPDATE SAVING STATE',
          });
          dispatch({
            error,
            type: 'SET ERROR',
          });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      props,
      state.activeDates,
      state.members,
    ]),
    selectMembers: useCallback((members) => {
      dispatch({
        members,
        type: 'SELECT MEMBERS',
      });
    }, []),
    setCurrentStep: useCallback((step) => {
      dispatch({
        step,
        type: 'SET CURRENT STEP',
      });
    }, []),
    setError: useCallback((error) => {
      dispatch({
        error,
        type: 'SET ERROR',
      });
    }, []),
    showCloseConfirmation: useCallback(() => {
      dispatch({
        showCloseConfirmation: true,
        type: 'UPDATE SHOW CLOSE CONFIRMATION',
      });
    }, []),
    updateActiveDateField: useCallback((field: keyof TState['activeDates'], value?: string) => {
      dispatch({
        field,
        type: 'UPDATE DATE FIELD',
        value,
      });
    }, []),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateMemberCode: useCallback((memberId: string, field: string, code: any) => {
      switch (field) {
        case 'code':
          dispatch({
            code,
            memberId: Number(memberId),
            type: 'UPDATE MEMBER CODE',
          });
          break;
      }
    }, []),
    updateSearchKey: useCallback((searchKey: string) => {
      dispatch({
        type: 'UPDATE SEARCH KEY',
        searchKey,
      });
    }, []),
  };

  return { actions, state };
};
