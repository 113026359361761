import React, { useEffect, useState } from 'react';
import { SocialInsightAge, SocialInsightGender } from '@components';
import { Progress, Typography } from '@revfluence/fresh';
import { PersonDressIcon, UserIcon, FlagUsaIcon } from '@revfluence/fresh-icons/regular/esm';
import { filter, includes, isEmpty } from 'lodash';
import styles from './SocialMetrics.scss';
import { formatEngagementRateToPerc } from '../GcrUtils';

const { Title } = Typography;

const CreatorDemographicSummary = ({ socialProfileData }) => {
  const [progressData, setProgressData] = useState([
    { label: 'Women', percent: 0, icon: <PersonDressIcon /> },
    { label: 'from USA', percent: 0, icon: <FlagUsaIcon /> },
    { label: 'Ages 18-34', percent: 0, icon: <UserIcon /> },
  ]);
  useEffect(() => {
    if (socialProfileData.length == 1) {
      if (socialProfileData[0].demographics_report) {
        const { demographics_report } = socialProfileData[0];

        const age18_24Value = !isEmpty(demographics_report.age)
          ? filter(demographics_report.age[0].values, (ageValue) => (includes(ageValue.dimension_values, SocialInsightAge.AGE_18_24)))
          : [];
        const age18_24 = !isEmpty(age18_24Value) ? age18_24Value[0].percentage : 0.0;

        const age25_34Value = !isEmpty(demographics_report.age)
          ? filter(demographics_report.age[0].values, (ageValue) => (includes(ageValue.dimension_values, SocialInsightAge.AGE_25_34)))
          : [];
        const age25_34 = !isEmpty(age25_34Value) ? age25_34Value[0].percentage : 0.0;
        const agePercent = age18_24 + age25_34;

        const unitedStatesValue = !isEmpty(demographics_report.country)
          ? filter(demographics_report.country[0].values, (countryValue) => (
            includes(countryValue.dimension_values, 'United States')
            || includes(countryValue.dimension_values, 'US')
          ))
          : [];
        const unitedStatesPercent = !isEmpty(unitedStatesValue) ? unitedStatesValue[0].percentage : 0.0;

        const womenValue = !isEmpty(demographics_report.gender)
          ? filter(demographics_report.gender[0].values, (genderValue) => (includes(genderValue.dimension_values, SocialInsightGender.FEMALE)))
          : [];
        const womenPercent = !isEmpty(womenValue) ? womenValue[0].percentage : 0.0;

        setProgressData([
          { label: 'Women', percent: womenPercent, icon: <PersonDressIcon /> },
          { label: 'from USA', percent: unitedStatesPercent, icon: <FlagUsaIcon /> },
          { label: 'Ages 18-34', percent: agePercent, icon: <UserIcon /> },
        ]);
      }
    }
  }, [socialProfileData]);
  return (
    <div className={styles.demographicSummary}>
      <Title level={5} className={styles.strongText}>
        US Audience Stats
      </Title>

      <ul>
        {progressData.map((item, index) => (
          <li key={index}>
            <div className={styles.progress}>
              <Progress
                type="circle"
                percent={Number((item.percent * 100).toFixed(2))}
                strokeWidth={10}
                strokeColor="#16282D"
                format={() => (
                  <p>
                    <Title level={4} weight="regular">{item.percent ? formatEngagementRateToPerc(item.percent.toString()) : '-'}</Title>
                    <span>
                      {item.icon}
                      {' '}
                      {item.label}
                    </span>
                  </p>
                )}
              />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CreatorDemographicSummary;
