import * as React from 'react';
import { map } from 'lodash';
import {
  Button, Col, Divider, InputNumber, List, Row, Space, Typography,
} from '@revfluence/fresh';
import { PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { UserAvatar } from '@frontend/app/components';

import { IMember } from '@frontend/app/hooks';
import { SearchResults } from '../SearchResults';
import { ICurrentRequest, ISelectedVariant } from '../../utils';
import { IProduct } from '../../../../../hooks';

import styles from './Products.module.scss';

const { Text } = Typography;

type Props = {
  products: IProduct[];
  fetchMoreProducts: () => void;
  currentRequest: ICurrentRequest;
  member: IMember;
  searchValue: string;
  setSearchValue(value: string): void;
  isLoadingProducts: boolean;
  selectedVariants: ISelectedVariant[];
  setSelectedVariants(selectedVariants: ISelectedVariant[]): void;
  showProductSearch: boolean;
  setShowProductSearch(value: boolean): void;
  addSelectedVariantsToCurrentRequest(): void;
  toggleRemovedVariantInCurrentRequest(variantId: number): void;
  adjustVariantQuantityInCurrentRequest(variantId: number, quantity: number): void;
  disabled: boolean;
  resourceId: number;
  shippingAddress: string;
};

export const Products = ({
  products,
  fetchMoreProducts,
  currentRequest,
  member,
  searchValue,
  setSearchValue,
  isLoadingProducts,
  selectedVariants,
  setSelectedVariants,
  showProductSearch,
  setShowProductSearch,
  addSelectedVariantsToCurrentRequest,
  toggleRemovedVariantInCurrentRequest,
  adjustVariantQuantityInCurrentRequest,
  disabled,
  resourceId,
  shippingAddress,
}: React.PropsWithChildren<Props>) => (
  <Space direction="vertical" size="large" style={{ width: '100%' }}>
    <Row justify="space-between">
      <Space direction="horizontal" size="small">
        <UserAvatar name={member.name} profilePicture={member.profilePicture} />
        <Text strong>{member.name}</Text>
        <Divider type="vertical" />
        <Text>{shippingAddress}</Text>
      </Space>
      {!showProductSearch
          && (
          <Button
            icon={<PlusIcon />}
            onClick={() => setShowProductSearch(true)}
            disabled={disabled}
          >
            Add Product
          </Button>
)}
    </Row>
    {showProductSearch && (
      <SearchResults
        setShowResults={setShowProductSearch}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoadingProducts={isLoadingProducts}
        products={products}
        fetchMoreProducts={fetchMoreProducts}
        selectedVariants={selectedVariants}
        setSelectedVariants={setSelectedVariants}
        addSelectedVariantsToCurrentRequest={addSelectedVariantsToCurrentRequest}
        resourceId={resourceId}
      />
)}
    {!showProductSearch && (
    <List className={styles.productList}>
      <List.Item>
        <Row style={{ width: '100%' }}>
          <Col span={8}>
            <Text type="secondary">Product Name</Text>
          </Col>
          <Col span={5}>
            <Text type="secondary">Variant Name</Text>
          </Col>
          <Col span={2}>
            <Text type="secondary">Price</Text>
          </Col>
          <Col span={3}>
            <Text type="secondary">Quantity</Text>
          </Col>
          <Col span={3}>
            <Text type="secondary">Inventory</Text>
          </Col>
          <Col span={3}>
            <Text type="secondary">Action</Text>
          </Col>
        </Row>
      </List.Item>
      {map(currentRequest, (item, variantId: number) => (
        <List.Item key={variantId}>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              <Text delete={item.removed}>{item.productTitle}</Text>
            </Col>
            <Col span={5}>
              <Text delete={item.removed}>{item.variantTitle}</Text>
            </Col>
            <Col span={2}>
              <Text delete={item.removed}>
                $
                {item.price}
              </Text>
            </Col>
            <Col span={3}>
              <Text>
                <InputNumber
                  size="middle"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore TODO: Fix in Node upgrade typing bash!
                  onChange={(value) => adjustVariantQuantityInCurrentRequest(variantId, value)}
                  defaultValue={item.quantity}
                  style={{ width: '64px' }}
                />
              </Text>
            </Col>
            <Col span={3}>
              <Text delete={item.removed}>{item.inventory}</Text>
            </Col>
            <Col span={3}>
              {!item.removed ? (
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => toggleRemovedVariantInCurrentRequest(variantId)}
                >
                  Remove
                </Button>
                    ) : (
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => toggleRemovedVariantInCurrentRequest(variantId)}
                      >
                        Undo
                      </Button>
                    )}
            </Col>
          </Row>
        </List.Item>
            ))}
    </List>
      )}
  </Space>
  );
