import gql from 'graphql-tag';

import { COMMUNITY_FRAGMENT } from './fragments/CommunityFragment';

export const SAVE_COMMUNITY = gql`
  mutation SaveCommunityMutation($community: CommunityInput!) {
    community: saveCommunity(community: $community) {
      ...CommunityPageCommunity
    }
  }
  ${COMMUNITY_FRAGMENT}
`;
