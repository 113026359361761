import * as React from 'react';
import cn from 'classnames';

import { Statistic, Tooltip } from '@revfluence/fresh';

import styles from './Statistics.module.scss';
import { StatisticsProps } from './Statistics.types';

const Statistics = ({
  bordered = false,
  padded = false,
  strong = false,
  tooltip = '',
  size = 'small',
  color = 'black',
  className,
  ...rest
}: StatisticsProps) => {
  const tooltipProps = typeof tooltip === 'string' ? { title: tooltip } : tooltip;

  return (
    <Tooltip {...tooltipProps}>
      <Statistic
        className={cn(
          styles.statistic,
          styles[size],
          styles[color],
          {
            [styles.bordered]: bordered,
            [styles.padded]: padded,
            [styles.strong]: strong,
          },
          className,
        )}
        {...rest}
      />
    </Tooltip>
  );
};

export default Statistics;
