import * as React from 'react';
import cx from 'classnames';
import { indexOf, pick } from 'lodash';
import { Tabs } from 'antd';
import { Button } from '@components';

import { PaletteIcon } from '@frontend/app/components';
import { defaultApplication } from '@frontend/app/components/ApplicationPageTemplates/CustomizedTemplate/constants';

import {
  About,
  Form,
  Intro,
  Perks,
  Persona,
  Settings,
  TFormProps,
} from './Steps';
import {
  IApplicationFormFields,
  TTemplateProps,
} from '../types';
import { StepNames } from '../constants';

import styles from './LeftPanel.scss';
import { ApplicationPageSection, isSectionUsedByPageTemplate, ProjectApplicationPageTemplateName } from '../../applicationPageUtils';

const { TabPane } = Tabs;
const { useCallback, useMemo, useRef } = React;

interface IProps {
  activeKey?: string;
  applicationFormFields?: IApplicationFormFields;
  className?: string;
  onChange?(values: TTemplateProps);
  onError?(hasError: boolean);
  onChangeApplicationFormFields?(formFields: IApplicationFormFields);
  onChangeTab?(activeKey: string);
  onChangeCustomPath?(path: string);
  onToggleSeeAllFields?();
  projectId: number;
  replaceContent?: JSX.Element;
  templateProps?: TTemplateProps;
  clientId?: string;
  clientHostname?: string;
  template: ProjectApplicationPageTemplateName | null;
  isFieldVisible: (fieldName: string) => boolean;
  onChangeFieldVisibility: (fieldName: string, isVisible: boolean) => void;
}

export const LeftPanel: React.FC<IProps> = React.memo((props) => {
  const panelRef = useRef();

  const {
    activeKey,
    applicationFormFields,
    className,
    onChange,
    onError,
    onChangeApplicationFormFields,
    onChangeTab,
    onChangeCustomPath,
    onToggleSeeAllFields,
    projectId,
    replaceContent,
    templateProps,
    clientId,
    clientHostname,
    template,
    isFieldVisible,
    onChangeFieldVisibility,
  } = props;

  const stepsOrder = useMemo(() => {
    switch (template) {
      case ProjectApplicationPageTemplateName.Quick:
        return [
          StepNames.Design,
          StepNames.Intro,
          StepNames.About,
          StepNames.Perks,
          StepNames.Form,
        ];
      default:
        return [
        StepNames.Design,
        StepNames.Intro,
        StepNames.About,
        StepNames.Perks,
        StepNames.Persona,
        StepNames.Form,
      ];
    }
  }, [template]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stepIndex = useMemo(() => indexOf(stepsOrder, activeKey), [activeKey]);

  const defaultFormProps: TFormProps = pick(defaultApplication, ['unpaidLabel', 'unpaidDescription']);

  const handleNext = useCallback(() => {
    onChangeTab(stepsOrder[stepIndex + 1]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex]);

  const handleBack = useCallback(() => {
    onChangeTab(stepsOrder[stepIndex - 1]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex]);

  const handleChange = useCallback((values: Partial<TTemplateProps>) => {
    if (onChange) {
      onChange({ ...templateProps, ...values });
    }
  }, [onChange, templateProps]);

  const handleChangeSettings = useCallback((settings: TTemplateProps['settings']) => {
    handleChange({ settings });
  }, [handleChange]);

  const handleChangeIntro = useCallback((intro: TTemplateProps['intro']) => {
    handleChange({ intro });
  }, [handleChange]);

  const handleChangeAbout = useCallback((about: TTemplateProps['about']) => {
    handleChange({ about });
  }, [handleChange]);

  const handleChangePerks = useCallback((perks: TTemplateProps['perks']) => {
    handleChange({ perks });
  }, [handleChange]);

  const handleChangePersona = useCallback((persona: TTemplateProps['persona']) => {
    handleChange({ persona });
  }, [handleChange]);

  const handleChangeForm = useCallback((application: TTemplateProps['application']) => {
    handleChange({ application });
  }, [handleChange]);

  return (
    <div
      className={cx(styles.LeftPanel, className)}
      ref={panelRef}
      id="steps"
    >
      {replaceContent}
      <Tabs
        activeKey={activeKey}
        className={cx(styles.tabs, { [styles.hide]: !!replaceContent })}
        centered
        tabBarGutter={20}
        onChange={onChangeTab}
      >
        {
          isSectionUsedByPageTemplate(ApplicationPageSection.Settings, template)
            && (
              <TabPane tab={<PaletteIcon size={18} />} key={StepNames.Design}>
                <Settings
                  clientId={clientId}
                  clientHostname={clientHostname}
                  projectId={projectId}
                  onChange={handleChangeSettings}
                  onChangeCustomPath={onChangeCustomPath}
                  settingsProps={templateProps?.settings}
                  parent={panelRef}
                  template={template}
                />
              </TabPane>
            )
        }
        {
          isSectionUsedByPageTemplate(ApplicationPageSection.Intro, template)
            && (
              <TabPane tab="Intro" key={StepNames.Intro}>
                <Intro
                  projectId={projectId}
                  onChange={handleChangeIntro}
                  introProps={templateProps?.intro}
                  template={template}
                  isFieldVisible={isFieldVisible}
                  onChangeFieldVisibility={onChangeFieldVisibility}
                />
              </TabPane>
            )
        }
        {
          isSectionUsedByPageTemplate(ApplicationPageSection.About, template)
            && (
              <TabPane tab="About" key={StepNames.About}>
                <About
                  projectId={projectId}
                  onChange={handleChangeAbout}
                  aboutProps={templateProps?.about}
                  template={template}
                  isFieldVisible={isFieldVisible}
                  onChangeFieldVisibility={onChangeFieldVisibility}
                />
              </TabPane>
            )
        }
        {
          isSectionUsedByPageTemplate(ApplicationPageSection.Perks, template)
            && (
              <TabPane tab="Perks" key={StepNames.Perks}>
                <Perks
                  onChange={handleChangePerks}
                  perksProps={templateProps?.perks}
                  template={template}
                  isFieldVisible={isFieldVisible}
                  onChangeFieldVisibility={onChangeFieldVisibility}
                />
              </TabPane>
            )
        }
        {
          isSectionUsedByPageTemplate(ApplicationPageSection.Persona, template)
            && (
              <TabPane tab="Persona" key={StepNames.Persona}>
                <Persona
                  projectId={projectId}
                  onChange={handleChangePersona}
                  template={template}
                  personaProps={templateProps?.persona}
                />
              </TabPane>
            )
        }
        {
          isSectionUsedByPageTemplate(ApplicationPageSection.Form, template)
            && (
              <TabPane tab="Form" key={StepNames.Form}>
                <Form
                  projectId={projectId}
                  onChange={handleChangeForm}
                  onError={onError}
                  formProps={templateProps?.application || defaultFormProps}
                  applicationFormFields={applicationFormFields}
                  onChangeApplicationFormFields={onChangeApplicationFormFields}
                  onToggleSeeAllFields={onToggleSeeAllFields}
                  template={template}
                />
              </TabPane>
            )
        }

      </Tabs>
      <div className={cx(styles.btns, { [styles.hide]: !!replaceContent })}>
        {stepIndex > 0 && <Button label="Back" theme="info" onClick={handleBack} />}
        {stepIndex < stepsOrder.length - 1 && <Button label="Next" theme="primary" onClick={handleNext} />}
      </div>
    </div>
  );
});

LeftPanel.displayName = 'LeftPanel';
