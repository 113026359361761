/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { reduce, sortBy } from 'lodash';

import { TNetworkIdentifier } from '@components';

import { ISortableData, TSortType } from './utils/SortedDataList';
import { ITableCellRenderContext } from './rowContext';
import { calculateCellWidth } from './utils';

const { createContext, useState, useEffect } = React;

export interface ITableConfig {
  // whether to show search inbox
  allowSearch?: boolean;
  // whether to enable pagination
  pageSize?: number;
  // whether to show columns config (show/hide columns)
  configurableColumns?: boolean;
  // header height (defaults to rowHeight, if provided)
  headerHeight?: number;
  // whether each row should have fixed height
  rowHeight?: number;
  // whether rows are selectable
  selectable?: boolean;
  // whether to add zebra-stripes to table rows
  striped?: boolean;
  // whether to show border between rows
  rowBorder?: boolean;
  // whether the row should be highlighted when hovering.
  highlightOnHover?: boolean;
  // make it scrollable on its own or not
  scrollable?: boolean;
  // make the columns reorderable
  reorderableColumns?: boolean;
  // make the columns resizable
  resizableColumns?: boolean;
  // custom row render
  renderBodyRow?: (rowData: IRowData, cellContent: JSX.Element) => JSX.Element;
  // what to render if data[field] is empty
  renderEmptyCellContent?: (cellType: ITableCellType, field: string, value: any) => JSX.Element;
}

export const defaultTableConfig: ITableConfig = {
  allowSearch: true,
  configurableColumns: true,
  selectable: true,
  striped: true,
  rowBorder: false,
  highlightOnHover: true,
  scrollable: true,
};

export type TJustify =
  | 'center'
  | 'flex-end'
  | 'flex-start';

export type ITableCellType =
  | 'link'
  | 'date'
  | 'name'
  | 'network'
  | 'numeric'
  | 'media'
  | 'rating'
  | 'text'
  | 'selectable'
  | 'boolean'
  | 'projects'
  | 'default';
export const UnsortableCellTypes = ['name', 'media'];

export type ITableColumnConfig =
  & ITableBaseColumnConfig
  & ITableLinkColumnConfig
  & ITableDateColumnConfig
  & ITableNameColumnConfig
  & ITableNumericColumnConfig
  & ITableNetworkColumnConfig
  & ITableBooleanColumnConfig
  & ITableSearchableColumnConfig;
interface ITableBaseColumnConfig {
  cellClassName?: string;
  headerCellClassName?: string;
  cellType?: ITableCellType;
  // data[field] for this column
  field: string;
  // header display name
  headerName: React.ReactNode;
  // column filter label, use in case wants a different label on columns filter
  columnFilterLabel?: React.ReactNode;
  /**
   * Default width value
   */
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  // width state
  setWidth?: number;
  // should the column grow if there's room. (flex-grow)
  grow?: boolean;
  // should the column support editing.
  editable?: boolean;
  // disables column reordering -- moves column to beginning
  lockPosition?: boolean;
  // disables column resizing
  lockWidth?: boolean;
  // custom render function for cells.
  render?: (data: any, context: ITableCellRenderContext) => JSX.Element | string;
  // less customizable version of render. Just allows formatting raw value.
  formatValue?: (data: any) => string;
  // force the column to be sortable or not
  sortable?: boolean;

  customCellRenderer?: any
}

// for date cell
interface ITableDateColumnConfig {
  // whether the given timestamp is in unix format
  isUnix?: boolean;
  // whether to show the distance between the given date and now in words.
  showDistance?: boolean;
  // the format string for date, only works when showDistance is false.
  dateFormatStr?: string;
}

// for link cell
interface ITableLinkColumnConfig {
  anchorTitleField?: string;
  hrefField?: string;
  handlerField?: string;
  linkText?: string;
  nullStr?: string;
  openInNewTab?: boolean;
  useAnchor?: boolean;
}

// for numeric cell
interface ITableNumericColumnConfig {
  // whether to show dollar sign
  isPrice?: boolean;
  // format string for number
  formatStr?: string;
  // currency code
  currencyCodeField?: string;
  // currency exchange rate
  currencyXRateField?: string;
  // justify-items
  justify?: TJustify;
  // defining max value for input field
  maxValue?: number;
  // defining min value for input field
  minValue?: number;
}

// for boolean cell
interface ITableBooleanColumnConfig {
  // null format string
  nullStr?: string;
  // true format string
  trueStr?: string;
  // false format string
  falseStr?: string;
}

// for name cell
interface ITableNameColumnConfig {
  nameField?: string;
  badgeField?: string;
}

// for searchable cell
interface ITableSearchableColumnConfig {
  searchable?: boolean;
  searchValue?: (value: any, data?: IRowData) => string;
}

// for network cell
interface ITableNetworkColumnConfig {
  // whether to remove duplicates
  unique?: boolean;
  // whether to show account name
  showAccountName?: boolean;
}
export interface INetworkCellConfig {
  type: TNetworkIdentifier;
  accountName?: string;
  link?: string;
}

type TOnSelectCallback = () => void;

export interface ISelectableCellConfig {
  value?: any;
  render: (selected: boolean, onSelect: TOnSelectCallback) => JSX.Element;
}

// type definition for row data
export interface IRowData extends ISortableData {
  [field: string]: any;

  // override checkbox selection
  disableSelection?: boolean;
  disabledReason?: string;

  // classname for body row
  _bodyRowClassName?: string;
}

// show/hide columns
export interface IShowColumns {
  [column: string]: boolean;
}

export interface IColumnSortDir {
  [key: string]: TSortType;
}

interface ITableContext {
  data: IRowData[];
  config: ITableConfig;
  columns: ITableColumnConfig[];
  showColumns: IShowColumns;

  /**
   * onChange for editable cells
   * @param rowId row id
   * @param field data[field] for the column
   * @param value updated value
   */
  onChangeCellValue?(rowId: string, field: string, newValue: any, oldValue?: any, rowData?: IRowData);

  /**
   * Global flag to let us know if any cell is currently being edited.
   */
  editing: boolean;
  updateIsEditing(editing: boolean);

  /**
   * Column order
   */
  orderedColumns?: ITableColumnConfig[];
  setColumnOrder?: (orderedColumns: ITableColumnConfig[]) => void;

  /**
   * Column widths
   */
  columnWidths?: { [field: string]: number };
  setColumnWidth?: (field: string, width: number) => void;
  fullWidthCell: boolean;
}

export const TableContext = createContext<ITableContext>(null);

export const TableContextProvider: React.FC<ITableContext> = (props) => {
  const { children, ...context } = props;

  const [orderedColumns, setColumnOrder] = useState<ITableContext['orderedColumns']>();
  const [columnWidths, setColumnWidths] = useState<ITableContext['columnWidths']>();

  useEffect(() => {
    setColumnOrder(sortBy(context.columns, (c) => (c.lockPosition ? 0 : 1)));
    setColumnWidths((widths) => (
      reduce(context.columns, (result, column) => {
        result[column.field] = result[column.field] || calculateCellWidth({
          width: column.width,
          minWidth: column.minWidth,
          maxWidth: column.maxWidth,
          setWidth: column.setWidth,
        });
        return result;
      }, widths || {})
    ));
  }, [context.columns]);

  const value: ITableContext = {
    ...context,
    orderedColumns,
    setColumnOrder,
    columnWidths,
    setColumnWidth: (field, width) => {
      if (!context.config.resizableColumns) {
        return;
      }
      setColumnWidths((widths) => ({
        ...widths,
        [field]: width,
      }));
    },
  };

  return (
    <TableContext.Provider value={value}>
      {children}
    </TableContext.Provider>
  );
};
