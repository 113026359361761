import * as React from 'react';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useBreakpoint } from '@revfluence/fresh';
import {
  endOfMonth,
  differenceInDays,
  endOfWeek,
  format,
  getWeekOfMonth,
  isFirstDayOfMonth,
  isSameDay, isSameMonth,
  lastDayOfMonth,
  addDays,
  addWeeks,
  differenceInMonths,
  addMonths,
  getMonth,
  differenceInWeeks,
  isBefore,
  isAfter,
  startOfMonth,
} from 'date-fns';
import { times } from 'lodash';

import { tickFormatters } from '../../utils/tickFormatters';
import { factoryCustomTooltip } from './Tooltip';
import styles from './LineChart.module.scss';

const { number } = tickFormatters;

const useStrokeWidth = () => {
  const screens = useBreakpoint();
  if (screens.lg) {
    return 3.5;
  }
  if (screens.md) {
    return 3;
  }
  return 2.5;
};

const usePadding = () => {
  const screens = useBreakpoint();
  if (screens.xl) {
    return { left: 100, right: 100 };
  }
  return { left: 72, right: 16 };
};
export const salesColor = '#619E9E';
export const impressionsColor = '#AC537E';
export const investmentColor = '#FFCD5B';
export const engagementsColor = '#D5712A';
export const contentColor = '#61799E';

export type TChartFilterTypes = 'sales' | 'impressions' | 'content' | 'engagements';
export type TChartFields = TChartFilterTypes | 'investment';
export type TChartTypes = 'daily' | 'weekly' | 'monthly';
export interface ILineChartROIData {
  date: string;
  sales?: number;
  salesPartial?: number;
  impressions?: number;
  impressionsPartial?: number;
  content?: number;
  contentPartial?: number;
  engagements?: number;
  engagementsPartial?: number;
  investment?: number;
  investmentPartial?: number;
}

interface IDotInterface {
  key?: string;
  cx: number;
  cy: number;
  value: string;
  stroke: string;
  fill: string;
  strokeWidth: number;
  strokeOpacity: number;
}

const CustomDot = (props: Omit<IDotInterface, 'fill'>) => {
  const {
    cx, cy, stroke, strokeOpacity, key, strokeWidth,
  } = props;
  return (
    <circle cx={cx} cy={cy} r={strokeWidth} stroke={stroke} strokeWidth={strokeWidth} fill="white" strokeOpacity={strokeOpacity} key={key} />
  );
};

const ActiveCustomDot = (props: Omit<IDotInterface, 'stroke'>) => {
  const {
    cx, cy, fill, strokeOpacity, key, strokeWidth,
  } = props;
  return (
    <circle cx={cx} cy={cy} r={strokeWidth} stroke={fill} strokeWidth={strokeWidth} fill={fill} strokeOpacity={strokeOpacity} key={key} />
  );
};

interface IChartPayload {
  value: string;
}

interface ICustomTickProps {
  x: number;
  y: number;
  payload: IChartPayload;
  fill: string;
}

export const getStartDate = (d1: Date, d2: Date) => (isBefore(d1, d2) ? d2 : d1);
export const getEndDate = (d1: Date, d2: Date) => (isAfter(d1, d2) ? d2 : d1);

const getCustomTickText = (type: TChartTypes, value: string, [startDate, endDate]: [Date, Date]) => {
  const date = new Date(value);
  switch (type) {
    case 'daily': {
      return [format(date, 'iiiiii'), format(date, 'MMM d')];
    }
    case 'weekly': {
      const endOfWeekDay = endOfWeek(date, { weekStartsOn: 1 });
      const lastDay = isSameMonth(date, endOfWeekDay) ? endOfWeekDay : lastDayOfMonth(date);
      const isPartial = isPartialWeek(date, endDate);
      const weekEnd = format(getEndDate(lastDay, endDate), 'd');
      const text = `${format(getStartDate(date, startDate), 'MMM d')} - ${weekEnd}`;
      const weekOfMonth = getWeekOfMonth(date, { weekStartsOn: 1 });
      const subtext = isPartial ? `Week ${weekOfMonth} (Partial)` : `Week ${weekOfMonth}`;
      return [text, subtext];
    }
    case 'monthly': {
      const isJanuary = getMonth(date) === 0;
      const lastDay = lastDayOfMonth(date);
      const dateFormat = isJanuary ? 'MMM yyyy' : 'MMM';
      const firstDate = getStartDate(date, startDate);
      const lastDate = getEndDate(lastDay, endDate);
      const text = format(firstDate, dateFormat);
      const isPartial = isPartialMonth(date, endDate);
      const subtext = `${format(firstDate, 'd')} - ${format(lastDate, 'd')} ${isPartial ? '(Partial)' : ''}`;
      return [text, subtext];
    }
  }
};

const factoryCustomTick = (type: TChartTypes, dateRange: [Date, Date]) => {
  const CustomTick = ({
    x, y, payload, fill,
  }: ICustomTickProps) => {
    const [text, subtext] = getCustomTickText(type, payload.value, dateRange);
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fill={fill} height={100}>
          <tspan className={styles.text} textAnchor="middle" x="0">
            {text}
          </tspan>
          <tspan className={styles.subtext} textAnchor="middle" x="0" dy="20">
            {subtext}
          </tspan>
        </text>
      </g>
    );
  };

  return CustomTick;
};

export interface ILineChartROIProps {
  data: ILineChartROIData[];
  filter?: TChartFilterTypes;
  type?: TChartTypes;
  startDate?: Date;
  endDate?: Date;
}

const factoryFilterFunction = <R, F>(returnValue: R, fallbackValue: F) =>
  (filter?: TChartFilterTypes) => (type: TChartFilterTypes): R | F => {
    if (!filter) return returnValue;
    return filter !== type ? fallbackValue : returnValue;
  };

const factoryStrokeOpacity = factoryFilterFunction(1, 0.08);

const factoryCustomDot = factoryFilterFunction(CustomDot, null);

const factoryActiveCustomDot = factoryFilterFunction(ActiveCustomDot, null);

const getDataType = (data: ILineChartROIData[]): TChartTypes => {
  const monthly = 2;
  const weekly = 1;
  const daily = 0;
  const interval: ['daily', 'weekly', 'monthly'] = ['daily', 'weekly', 'monthly'];

  const dataInterval = data.reduce((prev, current, index) => {
    if (prev === monthly) return prev;
    const nextDay = data[index + 1];
    if (!nextDay) return prev;
    const diff = differenceInDays(new Date(nextDay.date), new Date(current.date));
    if (diff > 7) return monthly;
    if (prev === weekly) return weekly;
    if (diff === 1) return daily;
    return weekly;
  }, daily);

  return interval[dataInterval];
};
export const stringToDate = (value: string) => new Date(value);
export const isPartialWeek = (date: Date, endDate: Date) => {
  const endOfWeekDay = endOfWeek(date, { weekStartsOn: 1 });
  if (isAfter(endOfWeekDay, endDate)) return true;
  const lastDay = isSameMonth(date, endOfWeekDay) ? endOfWeekDay : lastDayOfMonth(date);
  return differenceInDays(lastDay, date) < 6;
};
export const isPartialMonth = (date: Date, endDate: Date) => {
  const today = new Date();
  const monthEndDay = endOfMonth(date);
  if (isAfter(monthEndDay, endDate)) return true;
  const isEndDate = isSameMonth(today, monthEndDay) ? isSameDay(monthEndDay, today) : true;
  return !isEndDate || !isFirstDayOfMonth(date);
};

const fillData = (data: ILineChartROIData[], type: TChartTypes, firstDate?: Date, lastDate?: Date): ILineChartROIData[] => {
  const emptyChart = data.length === 0;
  const lastChartDate = data.length ? stringToDate(data[data.length - 1].date) : null;
  const endDate = lastDate || lastChartDate;
  const firstChartDate = data.length ? stringToDate(data[0].date) : null;
  const startDate = firstDate || firstChartDate;

  const fillDates = (date: Date, nTimes: number, addF: typeof addDays) =>
    times(nTimes, (n) => ({ date: format(addF(date, n), 'yyyy/MM/dd') }));
  switch (type) {
    case 'daily': {
      const diffLastDate = differenceInDays(lastChartDate, endDate);
      const diffFirstDate = differenceInDays(firstChartDate, startDate);
      return [...fillDates(startDate, diffFirstDate, addDays), ...data, ...fillDates(lastChartDate, diffLastDate, addDays)];
    }
    case 'weekly': {
      const diffLastDate = differenceInWeeks(lastChartDate, endDate);
      const diffFirstDate = differenceInWeeks(firstChartDate, startDate);
      return [...fillDates(startDate, diffFirstDate, addWeeks), ...data, ...fillDates(lastChartDate, diffLastDate, addWeeks)];
    }
    case 'monthly': {
      if (emptyChart) {
        const diff = differenceInMonths(endDate, startDate);
        return [
          {
            date: format(startDate, 'yyyy/MM/dd'),
          },
          ...fillDates(startOfMonth(addMonths(startDate, 1)), diff, addMonths),
        ];
      }
      const diffLastDate = differenceInMonths(endDate, lastChartDate);
      const diffFirstDate = differenceInMonths(firstChartDate, startDate);
      return [...fillDates(startDate, diffFirstDate - 1, addMonths), ...data, ...fillDates(lastChartDate, diffLastDate, addMonths)];
    }
    default:
      return data;
  }
};

export const LineChartROI = ({
  data,
  filter,
  startDate,
  endDate,
  type: typeProp,
}: ILineChartROIProps) => {
  const strokeWidth = useStrokeWidth();
  const padding = usePadding();
  const type = typeProp || getDataType(data);
  const isEmpty = data.length === 0;
  const filledData = fillData(data, type, startDate, endDate);
  const getStrokeOpacity = factoryStrokeOpacity(filter);
  const getCustomDot = factoryCustomDot(filter);
  const getActiveCustomDot = factoryActiveCustomDot(filter);
  const CustomTick = factoryCustomTick(type, [startDate, endDate]);
  const CustomTooltip = factoryCustomTooltip(type, [startDate, endDate], filter);
  const yTicks = data.length ? undefined : [0, 2500, 5000, 7500, 10000];
  return (
    <div className={styles.LineChart}>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={filledData} margin={{ top: 30, left: -60, bottom: 30 }}>
          <XAxis
            dataKey="date"
            padding={padding}
            tickLine={false}
            axisLine={false}
            tick={CustomTick}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            ticks={yTicks}
            tick={{ dy: -10, dx: 10, textAnchor: 'start' }}
            tickFormatter={(value) => `$${number(value)}`}
          />
          <CartesianGrid vertical={false} />
          <Tooltip cursor={{ strokeWidth, fill: '#619E9E' }} content={CustomTooltip} />
          <Line
            type="monotone"
            dataKey="sales"
            stroke={salesColor}
            strokeOpacity={getStrokeOpacity('sales')}
            dot={!isEmpty && getCustomDot('sales')}
            activeDot={!isEmpty && getActiveCustomDot('sales')}
            strokeWidth={strokeWidth}
          />
          <Line
            type="monotone"
            dataKey="impressions"
            stroke={impressionsColor}
            strokeWidth={strokeWidth}
            strokeOpacity={getStrokeOpacity('impressions')}
            dot={!isEmpty && getCustomDot('impressions')}
            activeDot={!isEmpty && getActiveCustomDot('impressions')}
          />
          <Line
            type="monotone"
            dataKey="content"
            stroke={contentColor}
            strokeWidth={strokeWidth}
            strokeOpacity={getStrokeOpacity('content')}
            dot={!isEmpty && getCustomDot('content')}
            activeDot={!isEmpty && getActiveCustomDot('content')}
          />
          <Line
            type="monotone"
            dataKey="engagements"
            stroke={engagementsColor}
            strokeWidth={strokeWidth}
            strokeOpacity={getStrokeOpacity('engagements')}
            dot={!isEmpty && getCustomDot('engagements')}
            activeDot={!isEmpty && getActiveCustomDot('engagements')}
          />
          <Line
            type="monotone"
            dataKey="investment"
            stroke={investmentColor}
            dot={!isEmpty && CustomDot}
            activeDot={!isEmpty && ActiveCustomDot}
            strokeWidth={strokeWidth}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
