/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1nvoq_157 {
  display: flex;
}

._justify-content-space-between_1nvoq_161 {
  justify-content: space-between;
}

._tabular-nums_1nvoq_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1nvoq_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._MembersWizardIcon_1nvoq_178 {
  align-items: center;
  border-radius: 1rem;
  color: #fdfdfd;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-right: 0.5rem;
  width: 2rem;
}
._MembersWizardIcon_1nvoq_178._addMembers_1nvoq_188 {
  background-color: #FFCD58;
}
._MembersWizardIcon_1nvoq_178._addMembers_1nvoq_188 > svg {
  margin-left: -0.125rem;
}
._MembersWizardIcon_1nvoq_178._deactivateMembers_1nvoq_194 {
  background-color: #e58f89;
}
._MembersWizardIcon_1nvoq_178._refreshMembers_1nvoq_197 {
  background-color: #2e5fba;
  font-size: 1rem;
}
._MembersWizardIcon_1nvoq_178._editActiveDates_1nvoq_201 {
  background-color: #77A7FF;
  font-size: 1.125rem;
}
._MembersWizardIcon_1nvoq_178._noMargin_1nvoq_205 {
  margin-right: 0rem;
}

._offerImage_1nvoq_209 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 0.5rem;
}