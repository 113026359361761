import * as React from 'react';
import {
  Typography, Space, Badge,
} from '@revfluence/fresh';
import cn from 'classnames';

import { IInvestmentValues } from '../../Reporting';
import Statistics from '../../../../components/Statistics';
import styles from './TabContent.module.scss';

const { Text } = Typography;

export const InvestmentRow = ({
  values: {
    payments,
    productCost,
    total,
  },
}: { values: IInvestmentValues }) => (
  <>
    <Space>
      <Text type="secondary" weight="semibold">
        <Badge status="default" className={cn(styles.Badge, styles.yellow)} />
        Investment
      </Text>
    </Space>
    <Space>
      <Text type="secondary" weight="regular">
        Terms Agreements
      </Text>
      <span>
        <Statistics value={payments} prefix={payments ? '$' : ''} size="default" />
      </span>
    </Space>
    <Space>
      <Text type="secondary" weight="regular">
        Product Cost
      </Text>
      <span>
        <Statistics value={productCost} prefix={productCost ? '$' : ''} size="default" />
      </span>
    </Space>
    <Space>
      <Text type="secondary" weight="semibold">
        Total Investment
      </Text>
      <span>
        (
        <Statistics value={total} prefix={total ? '$' : ''} size="default" />
        )
      </span>
    </Space>
  </>
);
