import * as React from 'react';
import {
  Checkbox,
  Form,
} from '@revfluence/fresh';

interface IProps {
  setOfferEndDate: (checked: boolean) => void;
  checked: boolean;
  name: string;
}

export const OfferEndDateCheckbox: React.FC<Readonly<IProps>> = React.memo(({
  setOfferEndDate,
  checked,
  name,
}) => {
  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setOfferEndDate(checked);
  };
  return (
    <>
      <Form.Item name={name}>
        <Checkbox onChange={handleCheckboxChange} checked={checked}>Set End Date</Checkbox>
      </Form.Item>

    </>
  );
});

OfferEndDateCheckbox.displayName = 'OfferEndDateCheckbox';
