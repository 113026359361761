import * as React from 'react';
import cx from 'classnames';

import { Button } from 'antd';
import { LoadSpinner, Notice } from '@components';

import { useAuth } from '@frontend/context/authContext';

import styles from './LoginView.scss';

interface IProps {
  className?: string;
}

const { useState, useLayoutEffect } = React;
/**
 * @type {React.FunctionComponent}
 */
export const LoginView: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    clientInfo,
    authError,
    loginWithRedirect,
    logout,
  } = useAuth();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    if (!authError) {
      const pathname = window.location.pathname;
      const newPathname = pathname.replace(/\/client\/(\w+)/, '');

      loginWithRedirect(newPathname);
    } else {
      setLoading(false);
    }
  }, [authError, loginWithRedirect]);

  return (
    <div className={cx(styles.LoginView, props.className)}>
      <div className={styles.card}>
        {loading && <LoadSpinner />}
        {!loading && !authError && (
          <>
            <div className={styles.title}>
              Sign in to
              <span className={styles.name}>
                {' '}
                {clientInfo.name}
              </span>
            </div>
            <Button
              type="default"
              onClick={() => {
                const pathname = window.location.pathname;
                const newPathname = pathname.replace(/\/client\/(\w+)/, '');

                loginWithRedirect(newPathname);
              }}
              className={styles.button}
              autoFocus
            >
              Continue
            </Button>
          </>
        )}
        {!loading && authError && (
          <>
            <Notice type="error" className={styles.notice} showDivider>
              {authError.message}
            </Notice>
            <Button
              type="ghost"
              onClick={logout}
              className={styles.button}
            >
              Go Back
            </Button>
          </>
        )}
      </div>
    </div>
  );
});

LoginView.displayName = 'LoginView';
