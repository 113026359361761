import { TNetworkIdentifier } from '@components';
import { parseUrl } from '@frontend/app/utils/url';
import { size, slice } from 'lodash';

export const YOUTUBE_CHANNEL_LENGTH = 24;
export const YOUTUBE_CHANNEL_PREFIX = 'UC';

/**
 * Generates a link to the social account
 * @param network Social network
 * @param username Username
 */
export const getSocialAccountLink = (network: TNetworkIdentifier, username: string): string | undefined => {
  if (!username) {
    return undefined;
  }

  const usernameWithoutAt = username.split('@').pop();
  switch (network) {
    case 'blog':
      return parseUrl(username)?.href || '';
    case 'facebook':
      return `https://facebook.com/${username}`;
    case 'instagram':
      return `https://instagram.com/${usernameWithoutAt}`;
    case 'pinterest':
      return `https://pinterest.com/${usernameWithoutAt}`;
    case 'snapchat':
      return `https://snapchat.com/add/${username}`;
    case 'tiktok':
      return `https://tiktok.com/@${usernameWithoutAt}`;
    case 'twitch':
      return `https://twitch.tv/${username}`;
    case 'twitter':
      return `https://twitter.com/@${usernameWithoutAt}`;
    case 'youtube':
      // check if username is a channel id
      if (size(username) === YOUTUBE_CHANNEL_LENGTH
        && slice(username, 0, size(YOUTUBE_CHANNEL_PREFIX)).join('') === YOUTUBE_CHANNEL_PREFIX) {
        return `https://youtube.com/channel/${username}`;
      }

      // otherwise, consider it a user id or a channel url,
      // also remove youtube.com from it to format consistent links
      let handle = username.split('youtube.com/').pop();
      if (!handle.startsWith('channel') && !handle.startsWith('user')) {
        handle = `@${handle}`;
      }
      return `https://youtube.com/${handle}`;
    default:
      return undefined;
  }
};
