import * as React from 'react';

import {
  ISelectableObject,
  genericAddEventLog,
  Notice,
  Button,
  LayoutCompareIcon,
} from '@components';
import { useAnalyze } from '../useAnalyze';
import { useAnalyzeCompare } from '../useAnalyzeCompare';
import { FilterRow } from './FilterRow';

import styles from './Filters.scss';
import { useLocation } from 'react-router-dom';

export interface IFilterBoxProps {
  aspirexFilterOptions?: ISelectFilterOptions[];
  onNoData: any;
  tempYoutubeDemoAccountMode?: boolean;
}

export interface ISelectFilterOptions {
  title: string;
  objects: ISelectableObject[];
  filtersKey: string;
}

const {
  useCallback,
  useMemo,
  useEffect,
} = React;

export const FilterBox: React.FunctionComponent<IFilterBoxProps> = (props) => {
  const {
    aspirexFilterOptions,
  } = props;
  const {
    filters,
    setFilters,
    currentPage,
    aspirexAnalytics,
  } = useAnalyze();
  const {
    filters: filtersCompare,
    setFilters: setFiltersCompare,
    isComparing,
  } = useAnalyzeCompare();
  const toggleInsight = (value: boolean) => {
    setFilters({ type: 'TOGGLE_INSIGHT', payload: value });
    setFiltersCompare({ type: 'TOGGLE_INSIGHT', payload: value });
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = (searchParams.get('projectId'));

  const isCompareDisabled = useMemo(() => ['breakdown', 'posts'].includes(currentPage), [currentPage]);
  const addFilter = useCallback((dispatch) => (filter) => {
    dispatch({ type: 'ADD_ADDITIONAL_FILTER', payload: filter });
    genericAddEventLog(
      'analyze_filter_added',
      {
        current_page: currentPage,
        current_additional_filters: [
          ...filters.meta.additionalFilters,
          ...filtersCompare.meta.additionalFilters,
        ],
        filter,
        is_comparing: isComparing,
      },
      aspirexAnalytics,
    );
  }, [
    aspirexAnalytics,
    currentPage,
    filters.meta.additionalFilters,
    filtersCompare.meta.additionalFilters,
    isComparing,
  ]);

  const removeFilter = useCallback((dispatch) => (filter) => {
    dispatch({ type: 'REMOVE_ADDITIONAL_FILTER', payload: filter });
    genericAddEventLog(
      'analyze_filter_removed',
      {
        current_page: currentPage,
        current_additional_filters: [
          ...filters.meta.additionalFilters,
          ...filtersCompare.meta.additionalFilters,
        ],
        filter,
        is_comparing: isComparing,
      },
      aspirexAnalytics,
    );
  }, [
    aspirexAnalytics,
    currentPage,
    filters.meta.additionalFilters,
    filtersCompare.meta.additionalFilters,
    isComparing,
  ]);
  const setFiltersCallback = useCallback((identifier: string, values: string[]) => {
    setFilters({
      type: 'SET_FILTER',
      payload: {
        filterKey: identifier,
        values,
      },
    });
  }, [setFilters]);
  const setFiltersCompareCallback = useCallback((identifier: string, values: string[]) => {
    setFiltersCompare({
      type: 'SET_FILTER',
      payload: {
        filterKey: identifier,
        values,
      },
    });
  }, [setFiltersCompare]);
  const onClickCompare = useCallback(() => {
    setFiltersCompare({ type: 'TOGGLE_COMPARE' });
    if (!isComparing) {
      genericAddEventLog(
        'analyze_compare_added',
        {
          current_page: currentPage,
          current_additional_filters: [
            ...filters.meta.additionalFilters,
            ...filtersCompare.meta.additionalFilters,
          ],
        },
        aspirexAnalytics,
      );
    }
  }, [
    aspirexAnalytics,
    currentPage,
    filters.meta.additionalFilters,
    filtersCompare.meta.additionalFilters,
    isComparing,
    setFiltersCompare,
  ]);

  useEffect(() => {
    if (projectId) {
      setFilters({
        type: 'SET_FILTER',
        payload: {
          filterKey: 'programIds',
          values: [projectId]
        }
      });
    };
  }, [projectId]);

  return (
    <>
      {filters.meta.error ? (
        <Notice type="alert" className={styles.notice}>
          There was an error loading the
          {' '}
          {filters.meta.error.filter}
          {' '}
          filter.
        </Notice>
      ) : null}

      <div className={styles.FilterBox}>
        <div className={styles.FilterSet}>
          <FilterRow
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            aspirexFilterOptions={aspirexFilterOptions}
            isComparing={false}
            isDisabled={false}
            onNoData={props.onNoData}
            setFilters={setFilters}
            setFiltersCallback={setFiltersCallback}
            toggleInsights={toggleInsight}
            tempYoutubeDemoAccountMode={props.tempYoutubeDemoAccountMode}
          />
          <FilterRow
            filters={filtersCompare}
            addFilter={addFilter}
            removeFilter={removeFilter}
            aspirexFilterOptions={aspirexFilterOptions}
            isComparing
            isDisabled={isCompareDisabled}
            onNoData={props.onNoData}
            setFilters={setFiltersCompare}
            setFiltersCallback={setFiltersCompareCallback}
            hide={!isComparing}
            toggleInsights={toggleInsight}
            tempYoutubeDemoAccountMode={props.tempYoutubeDemoAccountMode}
          />
          <div className={styles.FilterRow}>
            <Button
              className={styles.compareButton}
              onClick={() => onClickCompare()}
              icon={
                <LayoutCompareIcon size={16} className={styles.compareIcon} />
              }
              label="Compare Data Sets"
              theme="light"
              border={false}
              disabled={isCompareDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};
