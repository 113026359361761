/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dxh9_157 {
  display: flex;
}

._justify-content-space-between_1dxh9_161 {
  justify-content: space-between;
}

._tabular-nums_1dxh9_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dxh9_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ColoredTag_1dxh9_178 {
  max-width: 100%;
  height: 1.75rem;
  padding: 0.3125rem 0.75rem;
  border-radius: 6.25rem;
  background-color: #fafafa;
  color: #1a1818;
  font-size: 0.75rem;
  user-select: none;
}