/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_okfvz_157 {
  display: flex;
}

._justify-content-space-between_okfvz_161 {
  justify-content: space-between;
}

._tabular-nums_okfvz_165, ._AreaChart_okfvz_165 [class*=vx-axis-left] tspan {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_okfvz_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._AreaChart_okfvz_165 {
  position: relative;
}
._AreaChart_okfvz_165 [class*=vx-area-closed] {
  cursor: pointer;
}
._AreaChart_okfvz_165 [class*=vx-axis-left] tspan,
._AreaChart_okfvz_165 [class*=vx-axis-bottom] tspan {
  font-size: 0.6875rem;
  letter-spacing: 0.03125rem;
}
._tooltip_okfvz_188 {
  padding: 0.5rem 0.75rem !important;
  font-size: 0.75rem !important;
  background-color: #1a1818 !important;
  color: #fdfdfd !important;
  transform: translateX(-50%) !important;
}