import { TSetCurrentStepAction, setCurrentStep } from './setCurrentStep';
import { TSetErrorAction, setError } from './setError';
import { ActionType, TState } from '../types';
import { TSetTermsAction, setTerms } from './setTerms';
import { TSetProgramsAction, setPrograms } from './setProgams';
import { TSetMemberInfoAction, setMemberInfo } from './setMemberInfo';
import { TSetAmountPayingAction, setAmountPaying } from './setAmountPaying';
import { TSetPaymentSourceAction, setPaymentSource } from './setPaymentSource';
import { TSetBudgetAccountAction, setBudgetAccounts } from './setBudgetAccounts';
import { TSetSelectedBudgetAccount, setSelectedBudgetAccount } from './setSelectedBudgetAccount';
import { TSetSelectedBudgetDistribution, setSelectedBudgetDistribution } from './setSelectedBudgetDistribution';
import { TSetBudgetDistributions, setBudgetDistributionsForBudgetAccount } from './setBudgetDistributionsForBudgetAccount';
import { TSetSelectedTermIdAction, setSelectedTermId } from './setSelectedTermId';
import { TSetBudgetPaymentsAction, setBudgetPayments } from './setBudgetPayments';
import { TSetAssignPaymentToAction, setAssignPaymentTo } from './setAssignPaymentTo';
import { TSetGroupsAction, setGroups } from './setGroups';
import { TSetFieldValueAction, setFieldValue } from './setFieldValue';
import { TSetOverflowBudgetPaymentsAction, setOverflowBudgetPayments } from './setOverflowBudgetPayments';
import { TSetSelectedBudgetReassign, setSelectedBudgetReassign } from './setSelectedBudgetReassign';
import { TSetSTAPaymentsAction, setSTAPayments } from './setSTAPayments';

type TRawAction =
  | TSetCurrentStepAction
  | TSetErrorAction
  | TSetTermsAction
  | TSetProgramsAction
  | TSetMemberInfoAction
  | TSetAmountPayingAction
  | TSetPaymentSourceAction
  | TSetBudgetAccountAction
  | TSetSelectedBudgetAccount
  | TSetSelectedBudgetDistribution
  | TSetBudgetDistributions
  | TSetSelectedTermIdAction
  | TSetBudgetPaymentsAction
  | TSetOverflowBudgetPaymentsAction
  | TSetAssignPaymentToAction
  | TSetGroupsAction
  | TSetFieldValueAction
  | TSetSelectedBudgetReassign
  | TSetSTAPaymentsAction;

export const reducer = (state: TState, action: TRawAction): TState => {
  switch (action.type) {
    case ActionType.SET_CURRENT_STEP:
      return setCurrentStep(state, action);
    case ActionType.SET_ERROR:
      return setError(state, action);
    case ActionType.SET_TERMS:
      return setTerms(state, action);
    case ActionType.SET_PAYMENT_SOURCE:
      return setPaymentSource(state, action);
    case ActionType.SET_PROGRAMS:
      return setPrograms(state, action);
    case ActionType.SET_MEMBER_INFO:
      return setMemberInfo(state, action);
    case ActionType.SET_AMOUNT_PAYING:
      return setAmountPaying(state, action);
    case ActionType.SET_BUDGET_ACCOUNTS:
      return setBudgetAccounts(state, action);
    case ActionType.SET_SELECTED_BUDGET_ACCOUNT:
      return setSelectedBudgetAccount(state, action);
    case ActionType.SET_SELECTED_BUDGET_DISTRIBUTION:
      return setSelectedBudgetDistribution(state, action);
    case ActionType.SET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT:
      return setBudgetDistributionsForBudgetAccount(state, action);
    case ActionType.SET_SELECTED_TERM_ID:
      return setSelectedTermId(state, action);
    case ActionType.SET_BUDGET_PAYMENTS:
      return setBudgetPayments(state, action);
    case ActionType.SET_OVERFLOW_BUDGET_PAYMENTS:
      return setOverflowBudgetPayments(state, action);
    case ActionType.SET_ASSIGN_PAYMENT_TO:
      return setAssignPaymentTo(state, action);
    case ActionType.SET_GROUPS:
      return setGroups(state, action);
    case ActionType.SET_FIELD_VALUE:
      return setFieldValue(state, action);
    case ActionType.SET_BUDGET_REASSIGN:
      return setSelectedBudgetReassign(state, action);
    case ActionType.SET_STA_PAYMENTS:
      return setSTAPayments(state, action);
    default:
      return state;
  }
};
