import * as React from 'react';
import { Popover, Button, Tooltip } from '@revfluence/fresh';
import { TooltipPlacement } from 'antd/lib/tooltip'; // TODO: export from aspireui
import { isEmpty, isFunction, sortBy } from 'lodash';
import { SpinnerIcon } from '@components';

import { SegmentInput } from '@frontend/app/types/globalTypes';
import { EditSegmentForm, IEditSegment } from '@frontend/app/components';
import {
  useSaveSegmentFolderMutation,
  useSegmentFoldersQuery,
} from '@frontend/app/hooks';
import {
  MemberPageSegment as ISegment,
} from '@frontend/app/queries/fragments/types/MemberPageSegment';

import styles from './EditSegmentDropdown.scss';

const { useState, useCallback, useMemo } = React;

interface IProps {
  communityId?: number;
  isSaving?: boolean;
  hasChanges?: boolean;
  onClose?(visible: boolean);
  onSaveSegment(segmentInput: SegmentInput);
  placement?: TooltipPlacement;
  segment?: ISegment;
  sourcingGroupId?: string;
  visible?: boolean;
}

export const EditSegmentDropdown: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    children,
    communityId,
    isSaving,
    hasChanges,
    onClose,
    onSaveSegment,
    placement = 'bottomLeft',
    segment,
    sourcingGroupId,
    visible,
  } = props;

  const [values, setValues] = useState<IEditSegment>();
  const [saveSegmentFolder] = useSaveSegmentFolderMutation();
  const {
    data: {
      folders = [],
    } = {},
  } = useSegmentFoldersQuery(communityId, sourcingGroupId);

  const sortedFolders = useMemo(() => sortBy(folders, 'title'), [folders]);

  const hasTitle = values && values.title;
  const isValid = hasTitle;

  const tooltipMessage = (
    (!hasTitle && 'Name is required')
  );

  const disabled = !isValid || isSaving;

  const handleSave = useCallback(async () => {
    if (disabled) {
      return;
    }

    if (isEmpty(sortedFolders)) {
      const {
        data: {
          folder,
        },
      } = await saveSegmentFolder({
        variables: {
          segmentFolder: {
            title: 'Saved Filters',
            communityId,
            sourceGroup: sourcingGroupId,
          },
        },
      });
      return onSaveSegment({ ...values, folderId: folder.id });
    }

    onSaveSegment(values);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedFolders, communityId, disabled, values]);

  const onVisibleChange = useCallback((visible: boolean) => {
    if (isFunction(onClose)) {
      onClose(visible);
    }
  }, [onClose]);

  const content = (
    <div className={styles.EditSegmentDropdown}>
      <div className={styles.header}>Save Filter</div>
      <EditSegmentForm
        communityId={communityId}
        sourcingGroupId={sourcingGroupId}
        segment={segment}
        folders={sortedFolders}
        onChange={setValues}
        disabled={isSaving}
        onPressEnter={handleSave}
      />
      <Tooltip
        overlayStyle={{
          display: isValid ? 'none' : undefined,
          zIndex: 9999,
        }}
        title={tooltipMessage}
      >
        <Button
          block
          className={styles.button}
          disabled={disabled}
          icon={isSaving
            ? (
              <span
                className="anticon"
                role="img"
              >
                {/* TODO: anticon wrapper */}
                <SpinnerIcon size={16} />
              </span>
            )
            : undefined}
          onClick={handleSave}
        >
          Save
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <Popover
      content={content}
      onVisibleChange={onVisibleChange}
      placement={placement}
      trigger={(isSaving || !hasChanges) ? [] : ['click']}
      visible={visible}
    >
      {children}
    </Popover>
  );
});

EditSegmentDropdown.defaultProps = {
  isSaving: false,
  visible: false,
};

EditSegmentDropdown.displayName = 'EditSegmentDropdown';
