import { useQuery, QueryHookOptions } from '@apollo/client';

import { useAppContext } from '@frontend/context/AppContext';
import GET_DASHBOARD_CONFIG from '../queries/GetDashboardConfig';
import { GetDashboardConfig } from '../queries/types/GetDashboardConfig';

type IOptions = QueryHookOptions<GetDashboardConfig>;

export const useGetDashboardConfig = (options: IOptions = {}) => {
  const { apolloClient } = useAppContext();
  return useQuery<GetDashboardConfig>(GET_DASHBOARD_CONFIG, {
    ...options,
    client: apolloClient,
  });
};
