import * as React from 'react';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { FormItemStatus } from './types';
import styles from './FormTextInput.scss';

const { useState } = React;

interface IProps {
  label: string;
  name: string;
  placeholder: string;
  value: string;
  maxLength: number;
  isTextArea?: boolean;
  isRequired?: boolean;
  allowSpecialChars?: boolean;
  onFieldChange: (name: string, value: string) => void;
}

export const SPECIAL_CHARS_REGEX = /[^\x00-\x7F]/;

const FormTextInput: React.FC<IProps> = ({
  label,
  name,
  placeholder,
  value,
  maxLength,
  isRequired = false,
  onFieldChange,
  isTextArea = false,
  allowSpecialChars = true,
}: IProps) => {
  const [inputStatus, setInputStatus] = useState<FormItemStatus>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (isRequired) {
      setInputStatus(isEmpty(value) ? 'error' : '');
    }
    if (!allowSpecialChars) {
      if (SPECIAL_CHARS_REGEX.test(value)) {
        setInputStatus('error');
        setErrorMessage('Can’t contain special characters');
      } else {
        setInputStatus('');
        setErrorMessage('');
      }
    }
    onFieldChange(name, value);
  };

  return (
    <Form.Item
      label={label}
      validateStatus={inputStatus}
      className={styles.FormTextInput}
    >
      {isTextArea ? (
        <Input.TextArea
          name={name}
          value={value}
          autoSize={{ minRows: 5 }}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={handleChange}
        />
      ) : (
        <Input
          name={name}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={handleChange}
        />
      )}
      <div className={cx(styles.messagingContainer, { [styles.withError]: !!errorMessage })}>
        {errorMessage && (
          <div className={styles.errorMessage}>
            {errorMessage}
          </div>
        )}
        <div className={styles.inputCount}>
          {value.length}
          /
          {maxLength - value.length}
          {' '}
          characters remaining
        </div>
      </div>
    </Form.Item>
  );
};

export default React.memo(FormTextInput);
