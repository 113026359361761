import { IStepConfig } from '@frontend/app/components/WizardV2/types';
import { EventName } from '@common';

import { ObjectiveOption, OnboardingStep } from './types';
import { IFormRadioGroupOption } from './components/types';

export const steps: IStepConfig[] = [
  {
    name: OnboardingStep.Objective,
    title: 'Discover The Right Campaign Strategy For Your First Project.',
    subTitle: 'Answer a few questions to determine the best strategy for your campaign.',
    buttonLabel: 'Find Campaign',
    eventName: EventName.OnboardingFlowObjective,
  },
  {
    name: OnboardingStep.Setup,
    title: 'Set Up Your First Project',
    subTitle: 'Create a project workflow for any campaign strategy. Choose from our project templates, or create something custom.',
    buttonLabel: 'Project Setup',
    eventName: EventName.OnboardingFlowIntro,
  },
  {
    name: OnboardingStep.Requirements,
    title: 'Influencer Brief and Compensation',
    subTitle: 'Define what you’re looking for and what you’ll provide to creators.',
    buttonLabel: 'Influencer Brief & Compensation',
    eventName: EventName.OnboardingFlowCreators,
  },
  {
    name: OnboardingStep.MarketplaceListing,
    title: 'Personalize Your Creator Marketplace Listing',
    subTitle: 'Here’s where you can add your brand’s touch to personalize your application page and Marketplace listing.',
    buttonLabel: 'Create Marketplace Application Page',
    eventName: EventName.OnboardingFlowPersonalize,
  },
  {
    name: OnboardingStep.Preview,
    title: 'Preview and List Your Application Page',
    subTitle: 'Publish your Application Page to our Creator Marketplace.',
    buttonLabel: 'Preview Application Page',
    submitLabel: 'List on Marketplace',
    skipLabel: 'Skip and Save',
    eventName: EventName.OnboardingFlowPreview,
  },
];

export const onboardingObjectiveOptions: IFormRadioGroupOption[] = [
  {
    title: 'Increase Brand Visibility',
    label: 'I\'d like to boost the number of impressions and reach across audiences.',
    value: ObjectiveOption.IncreaseVisibility,
  }, {
    title: 'Drive Sales & Revenue',
    label: 'I\'d like to track sales through promo codes and affiliate links.',
    value: ObjectiveOption.DriveSales,
  }, {
    title: 'Drive Engagements & Conversions',
    label: 'I\'d like to increase the number of follows, likes, clicks, etc.',
    value: ObjectiveOption.DriveEngagements,
  }, {
    title: 'Build a Content Library',
    label: 'I\'d like to build a robust library filled with content for my brand.',
    value: ObjectiveOption.BuildLibrary,
  },
];
