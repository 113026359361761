/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_q99p7_157 {
  display: flex;
}

._justify-content-space-between_q99p7_161 {
  justify-content: space-between;
}

._tabular-nums_q99p7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_q99p7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._bold_q99p7_178 {
  font-weight: bold;
}

._logDate_q99p7_182 {
  margin-bottom: 0.75rem !important;
  margin-left: -0.5rem;
}

._drawerTitle_q99p7_187 {
  display: flex;
  justify-content: center;
}

._logItem_q99p7_192 {
  font-size: "14px";
}

._logTime_q99p7_196 {
  display: block;
  color: grey;
  margin-top: "4px";
  margin-bottom: "24px";
  font-size: "12px";
}