import React from 'react';
import classNames from 'classnames';

type LegendOption = {
  label: string;
  color: string;
  percent: number;
};

type ProgressBarWithLegendsProps = {
  edge?: 'square' | 'rounded';
  height?: number; // In px
  legend: LegendOption[];
  className?: string;
};

const ProgressBarWithLegends: React.FC<ProgressBarWithLegendsProps> = ({
  edge = 'square',
  height = 12,
  legend,
  className,
}) => {
  // Calculate total percentage to ensure it does not exceed 100%
  const totalPercent = legend.reduce((sum, item) => sum + item.percent, 0);

  // Avoid division by zero and ensure valid percentages
  const adjustedLegend = totalPercent > 0
    ? legend.map((item) => ({
        ...item,
        percent: (item.percent / totalPercent) * 100,
      }))
    : legend;

  const progressBarClass = classNames(
    'w-full bg-gray-200 flex overflow-hidden',
    {
      'rounded-full': edge === 'rounded' && legend.length === 1,
      'rounded-l-full': edge === 'rounded' && legend.length > 1,
    },
    className,
  );

  return (
    <div>
      {/* Progress Bar */}
      <div className={progressBarClass} style={{ height: `${height}px` }}>
        {adjustedLegend.map((item, index) => (
          <div
            key={index}
            className={classNames('h-full', {
              'rounded-l-full': edge === 'rounded' && index === 0,
              'rounded-r-full': edge === 'rounded' && index === adjustedLegend.length - 1,
            })}
            style={{
              width: `${item.percent}%`,
              backgroundColor: item.color,
            }}
            aria-label={`${item.label} ${item.percent.toFixed(2)}%`}
          />
        ))}
      </div>

      {/* Legend */}
      <div className="mt-2 flex space-x-4">
        {adjustedLegend.map((item, index) => (
          <div key={index} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: item.color }}
            />
            <span className="text-gray-500">{item.label}</span>
            <span className="font-medium">
              {`${item.percent > 0 ? item.percent.toFixed(2) : '0.00'}%`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBarWithLegends;
