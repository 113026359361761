import cx from 'classnames';
import { isFunction, isNil } from 'lodash';
import * as React from 'react';

import { IFolderProps } from './Folder';
import { FolderTitle } from './FolderTitle';

import styles from './Folder.scss';

const { useCallback, useState } = React;

type TFolderItemProps = Pick<
  IFolderProps,
  'accessory' | 'className' | 'hoverAccessory' | 'icon' | 'isActive' | 'onClick'
>;
export interface IFolderItemProps extends TFolderItemProps {
  /**
   * Indent the text
   */
  indent?: boolean;
}

export const FolderItem: React.FC<IFolderItemProps> = React.memo((props) => {
  const {
    accessory,
    children,
    className,
    hoverAccessory,
    icon,
    indent = isNil(props.icon),
    isActive,
    onClick,
  } = props;

  const [isHovered, setHovered] = useState(false);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      if (isFunction(onClick)) {
        onClick(event);
      }
    },
    [onClick],
  );

  return (
    <div
      className={cx(styles.FolderItem, className, {
        [styles.active]: isActive,
        [styles.indent]: indent,
      })}
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FolderTitle
        accessory={accessory}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        className={(styles as any).folderTitle}
        hoverAccessory={hoverAccessory}
        icon={icon}
        showHoverAccessory={isHovered}
      >
        {children}
      </FolderTitle>
    </div>
  );
});

FolderItem.displayName = 'FolderItem';
