import {
 filter, isNull, map, size, some, uniqBy,
} from 'lodash';

import { TSelectedMember } from '@affiliates/components/MembersWizard/types';
import { markDuplicates } from '../../../../utils';
import { TState } from '../types';

interface IAction {
  members: readonly TSelectedMember[];
  type: 'SELECT MEMBERS';
}
export type TSelectMembersAction = Readonly<IAction>;

export const selectMembers = (state: TState, action: TSelectMembersAction): TState => {
  if (isNull(state.members)) {
    return state;
  }
  let members = markDuplicates(map(state.members, (member) => ({
    ...member,
    selected: some(action.members, (m) => Number(m.id) === member.id),
  })));
  let selectedMembers = action.members;
  if (state.searchKey) {
    selectedMembers = uniqBy(
      [...state.selectedMembers, ...selectedMembers],
      (member) => member.id.toString(),
    );
    const dispayTableMembers = members.filter((member) => member.name.toLowerCase().includes(state.searchKey.toLowerCase()));
    const selectedMembersKeys = new Set<string>(
      map(selectedMembers, (member) => member.id.toString()),
    );

    const displayedSelectedMember = filter(
      dispayTableMembers,
      (member) => selectedMembersKeys.has(member.id.toString()),
    );
    // From fresh selected
    const updatedRowKeys = new Set<string>(action.members.map((member) => member.id.toString()));

    const unselectedRows = filter(displayedSelectedMember, (member) => !updatedRowKeys.has(member.id.toString()));
    const unselectedRowIds = new Set<string>(map(unselectedRows, (row) => row.id.toString()));

    // remove deselected rows from the cross-page accumulated selection list
    if (size(unselectedRowIds) > 0) {
      selectedMembers = filter(
        selectedMembers, (member) => !unselectedRowIds.has(member.id.toString()),
      );
    }

    members = markDuplicates(map(members, (member) => ({
      ...member,
      selected: some(selectedMembers, (m) => Number(m.id) === member.id),
    })));
  }
  return {
    ...state,
    members,
    selectedMembers,
  };
};
