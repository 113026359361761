import gql from 'graphql-tag';

export const WORKLET_FRAGMENT = gql`
  fragment WorkletFragment on Worklet {
    specKey
    specLogoURL
    specTitle
    specURI
  }
`;
