import {
  isEmpty, map, uniq, chain, some,
} from 'lodash';

import { backendServerApiEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { endpoints } from '@components';

import {
  PlaceTypeOption,
  TPlacesOptions,
  TPlace,
  TPlaceAutocompleteCallback,
} from '@frontend/components/widgets/PlacesInput/types';

interface AutocompletionRequest {
  query: string;
  limit?: number;
  types?: string[];
  country_code?: string;
}

interface AutocompletePrediction {
  id: number;
  name: string;
  type: string;
}

const placeTypeMap = {
  [PlaceTypeOption.City]: 'city',
  [PlaceTypeOption.State]: 'state',
  [PlaceTypeOption.Country]: 'country',
};

const predictionFilterMap = {
  [PlaceTypeOption.City]: (prediction: AutocompletePrediction) => prediction.type == 'city',
  [PlaceTypeOption.State]: (prediction: AutocompletePrediction) => prediction.type == 'state',
  [PlaceTypeOption.Country]: (prediction: AutocompletePrediction) => prediction.type == 'country',
};

const mapPrediction = (options: TPlacesOptions, prediction: AutocompletePrediction): TPlace | null => {
  if (isEmpty(options.types) || some(options.types, (t) => predictionFilterMap[t](prediction))) {
    return {
      label: prediction.name,
      value: prediction.id,
    };
  }
  return null;
};

const DEFAULT_LIMIT = 20;

const convertOptions = (options: TPlacesOptions): AutocompletionRequest => {
  const { input, types, countryCode } = options;

  const requestOptions: AutocompletionRequest = { query: input, limit: DEFAULT_LIMIT };

  if (!isEmpty(types)) {
    requestOptions.types = uniq(map(types, (t) => placeTypeMap[t]));
  }

  if (!isEmpty(countryCode)) {
    requestOptions.country_code = countryCode;
  }

  return requestOptions;
};

const getPlaces = async (options: TPlacesOptions): Promise<TPlace[]> => {
  if (!options.input) {
    return [];
  }

  const requestOptions = convertOptions(options);

  const response = await fetch(
    `${backendServerApiEndpoint()}/${endpoints.socialAccountSearchLocationsEndpoint}`,
    {
      method: 'POST',
      body: JSON.stringify(requestOptions),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    },
  );
  const results = await response.json();

  return chain(results.data)
    .map((prediction) => mapPrediction(options, prediction))
    .omitBy(isEmpty)
    .toArray()
    .value();
};

export const useIQDataLocationAutocomplete = (): TPlaceAutocompleteCallback => (options) => getPlaces(options);
