import * as React from 'react';
import cx from 'classnames';
import {
  Modal,
  ModalSize,
} from '@components';
import { EventName } from '@common';
import {
 isEmpty, size, keyBy, map, isFunction, includes, orderBy,
} from 'lodash';

import {
  IModalProps,
  RoundAddCircleOutlineIcon,
} from '@components';

import {
  SelectList,
  FormActions,
  NewTagModal,
  ColoredTag,
} from '@frontend/app/components';

import Typography from '@frontend/applications/AffiliatesApp/AspireUI/Typography/Typography';
import { useMessagingContext } from '@frontend/hooks';
import {
  ITag,
  useGetTags,
  useFuzzySearchByKeys,
  useAddMembersToTagsMutation,
  useRemoveMembersFromTagsMutation,
  useSaveTag,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { useEventContext } from '@frontend/app/context/EventContext';

import { TagActions } from './TagActions';

import styles from './ModifyTagMembersModal.scss';

const { useState, useEffect, useMemo } = React;

type TType = 'add' | 'remove';

interface IProps extends IModalProps {
  query?: IMemberSearchQuery;
  memberIds?: number[];
  memberCount?: number;
  type: TType;
  showNewTagButton?: boolean;
  onModifyComplete?(tags: ITag[], memberIds?: number[]);
  closeModalOnSave?: boolean;
}

const SEARCH_KEYS = ['name'];

export const ModifyTagMembersModal: React.FunctionComponent<IProps> = React.memo((props) => {
  const [highlightedIds, setHighlightedIds] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [showNewTagForm, setShowNewTagForm] = useState<boolean>(false);
  const [type, setType] = useState<TType>(props.type);

  const {
    showMessage,
    showGenericErrorMessage,
  } = useMessagingContext();

  useEffect(() => {
    if (props.show) {
      setType(props.type);
    } else {
      setTimeout(() => {
        setType(props.type);
      }, 500);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const {
    tags,
    tagColorMap,
    loading: isLoadingTags,
    refetch: refetchTags,
  } = useGetTags({
    skip: !props.show,
    fetchPolicy: 'no-cache',
  });

  const [addMembersToTags, {
    loading: isAddingMembers,
  }] = useAddMembersToTagsMutation({
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount} members added to tags`,
      });
    },
    onError() {
      showGenericErrorMessage();
    },
  });

  const [removeMembersFromTags, {
    loading: isRemovingMembers,
  }] = useRemoveMembersFromTagsMutation({
    onCompleted() {
      showMessage({
        type: 'success',
        content: `${memberCount} members removed from tags`,
      });
    },
    onError() {
      showGenericErrorMessage();
    },
  });

  const [saveTag] = useSaveTag({
    onCompleted: ({ tag }) => {
      setHighlightedIds([...highlightedIds, tag.id]);
      setSelectedTagIds([...selectedTagIds, tag.id]);
      setIsCreatingTag(false);
    },
    onError: (err) => {
      showMessage({
        type: 'error',
        content: err.message,
      });
      setIsCreatingTag(false);
    },
  });

  const doSearch = useFuzzySearchByKeys(tags, SEARCH_KEYS);

  const handleSearch = (search: string) => {
    setSearchValue(search);
    return doSearch(search);
  };

  type TOption = typeof tags[0];

  const mapOptionToLabel = (option: TOption) => (
    <div
      className={cx(
          styles.option,
      )}
    >
      <ColoredTag
        name={option.name}
        tagColor={tagColorMap.get(option.name)}
        boldFont
      />
      <TagActions
        tag={option}
        onDelete={refetchTags}
        onSave={refetchTags}
      />
    </div>
    );

  const memberCount = size(props.memberIds) || props.memberCount;

  const addRemoveMembers = async (tagIds: number[]) => {
    const vars = isEmpty(props.memberIds) ? {
      query: props.query,
    } : {
      memberIds: props.memberIds,
    };

    switch (type) {
      case 'add':
        await addMembersToTags({
          variables: {
            ...vars,
            tagIds,
          },
        });
        break;

      case 'remove':
        await removeMembersFromTags({
          variables: {
            ...vars,
            tagIds,
          },
        });
        break;

      default:
        break;
    }
  };

  const addEvent = useEventContext();

  const handleSave = async (tagIds: number[]) => {
    if (!isEmpty(props.memberIds) || props.query) {
      await addRemoveMembers(tagIds);
    }

    if (props.onModifyComplete) {
      const tagsById = keyBy(tags, 'id');
      const selectedTags = map(tagIds, (id) => tagsById[id]);
      props.onModifyComplete(selectedTags, props.memberIds);
    }

    if (props.closeModalOnSave) {
      onModalRequestClose();
    }

    if (props.onRequestClose) {
      props.onRequestClose();
    }

    const action = type === 'add' ? 'add_to_tag' : (type === 'remove' ? 'remove_from_tag' : null);
    if (action) {
      addEvent(
        EventName.CompleteBulkAction,
        {
          action,
          member_count: memberCount,
        },
      );
    }
  };

  const handleAddFromEmpty = async () => {
    setIsCreatingTag(true);
    await saveTag({
      variables: {
        tag: {
          name: searchValue,
        },
      },
    });
    await refetchTags();
  };

  const addEmptyMessage = searchValue ? (
    <div>
      No tags with this name, search again or
      {' '}
      <Typography.Link
        onClick={handleAddFromEmpty}
      >
        create this new tag
      </Typography.Link>
      .
    </div>
  ) : (
    <div>
      No tags found. Please enter a tag name to create one.
    </div>
  );

  let primaryType;
  let primaryText;
  let title;
  let subtitle;
  let emptyMessage;

  switch (type) {
    case 'add':
      title = 'Assign Tags';
      subtitle = `Assign selected tags to ${memberCount} members`;
      primaryText = 'Assign';
      primaryType = 'primary';
      emptyMessage = addEmptyMessage;
      break;

    case 'remove':
      title = 'Unassign Tags';
      subtitle = `Unassign selected tags from ${memberCount} members`;
      primaryText = 'Unassign';
      primaryType = 'primary';
      emptyMessage = 'There are no available tags to unassign members from';
      break;

    default:
      break;
  }

  const handleClickNewTag = () => {
    setShowNewTagForm(true);
  };

  const isLoading = isAddingMembers || isRemovingMembers;

  const showNewTagButton = props.showNewTagButton && type === 'add';

  const onModalRequestClose = () => {
    setSelectedTagIds([]);
    setHighlightedIds([]);

    if (isFunction(props.onRequestClose)) {
      props.onRequestClose();
    }
  };

  const sortedTags = useMemo(() =>
    // Newer tags goes first
     orderBy(tags, 'id', 'desc'),
   [tags]);

  const handleTagSaved = (newTagId: number) => {
    refetchTags();
    setHighlightedIds([newTagId]);
  };

  return (
    <>
      <Modal
        className={styles.ModifyTagMembersModal}
        onRequestClose={onModalRequestClose}
        width={ModalSize.Small}
        show={props.show}
      >
        <FormActions
          title={title}
          subtitle={subtitle}
          primaryAction={{
            danger: primaryType === 'danger',
            disabled: isEmpty(selectedTagIds),
            label: primaryText,
            loading: isLoading,
            onClick: handleSave.bind(this, selectedTagIds),
          }}
          secondaryAction={showNewTagButton
            ? {
              disabled: isLoading,
              icon: (
                <span
                  className="anticon"
                  role="img"
                >
                  <RoundAddCircleOutlineIcon size={16} />
                </span>
              ),
              label: 'New Tag',
              onClick: handleClickNewTag,
            }
            : undefined}
          tertiaryAction={{
            disabled: isLoading,
            label: 'Cancel',
            onClick: props.onRequestClose,
          }}
          disabled={isLoading}
        >
          <SelectList
            searchPlaceholder="Search tags..."
            showSearch
            onSearchRequest={handleSearch}
            options={sortedTags}
            mapOptionToId={(option: TOption) => option.id}
            mapOptionToLabel={mapOptionToLabel}
            mapOptionClasses={(option: TOption) => includes(highlightedIds, option.id) && styles.highlighted}
            disabled={isLoading}
            onChange={(selectedIds) => setSelectedTagIds(selectedIds as number[])}
            selectedIds={selectedTagIds}
            isLoading={isLoadingTags || isCreatingTag}
            emptyMessage={emptyMessage}
          />
        </FormActions>
      </Modal>
      <NewTagModal
        show={showNewTagForm}
        onTagSaved={handleTagSaved}
        onRequestClose={setShowNewTagForm.bind(this, false)}
      />
    </>
  );
});
