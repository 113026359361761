import {
 chain, differenceWith, filter, isEmpty, isEqual, isNil, isString, map, reduce,
} from 'lodash';

import { ITableColumnConfig } from '@components';
import { OrderedColumnInput, SelectedColumnsInput } from '@frontend/app/types/globalTypes';

import { IColumnVisibility } from '@frontend/app/types/Columns';

const buildColumnsInput = (ids: (number | string)[]): SelectedColumnsInput => (
  reduce<number | string, SelectedColumnsInput>(
    ids,
    (result, id) => {
      const numberId = parseInt(`${id}`, 10);
      if (id === 'id') return result;
      if (!isNaN(numberId)) {
        result.memberFieldSchemaIds.push(numberId);
        result.order.push({ memberFieldSchemaId: numberId });
      } else if (isString(id) && id !== 'name') {
        result.dbColumns.push(id);
        result.order.push({ dbColumn: id });
      }
      return result;
    },
    {
      dbColumns: ['name'],
      memberFieldSchemaIds: [],
      order: [{ dbColumn: 'name' }],
    },
  )
);

/**
 * Set visibility while maintaining order
 */
export const columnsInputFromVisibility = (
  columnVisibility: IColumnVisibility,
  orderedColumnInput: OrderedColumnInput[],
): SelectedColumnsInput => {
  const columnKeys = chain(columnVisibility)
    .omitBy((visible) => !visible)
    .keys()
    .value();
  const columns = buildColumnsInput(columnKeys);

  if (isEmpty(orderedColumnInput)) {
    // No `order` set in the db yet
    return columns;
  }

  const oldColumns = map(orderedColumnInput, (column) => (
    !isNil(column.dbColumn)
      ? { dbColumn: column.dbColumn }
      : { memberFieldSchemaId: column.memberFieldSchemaId }
  ) as OrderedColumnInput);

  const newColumns = differenceWith<OrderedColumnInput, OrderedColumnInput>(columns.order, oldColumns, isEqual);

  if (isEmpty(newColumns)) {
    // No new columns, just return `columns`
    return columns;
  }

  // Move new columns to the end
  // and ensure that `dbColumns` and `memberFieldSchemaIds` are in the right order
  const order = [...oldColumns, ...newColumns];

  return {
    order,
    dbColumns: filter(map(order, (column) => column.dbColumn)),
    memberFieldSchemaIds: filter(map(order, (column) => column.memberFieldSchemaId)),
  };
};

/**
 * columnConfig is already ordered
 */
export const columnsInputFromColumnConfig = (columnConfig: ITableColumnConfig[]): SelectedColumnsInput => (
  buildColumnsInput(map(columnConfig, (column) => column.field))
);
