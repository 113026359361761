import * as React from 'react';
import { first } from 'lodash';

import { AddMemberOverlay } from '@frontend/app/containers/Members/AddMember/AddMemberOverlay';
import { useCommunitySwitcherContext } from '@frontend/app/hooks';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';

const { useState, useCallback } = React;

export interface IMemberPageContext {
  communityId: number;
  sourcingGroupId?: string;
  programId?: number;

  toggleOpenAddMember();
}

export const MemberPageContext = React.createContext<IMemberPageContext>(null);

export const MemberPageContextProvider = ({ children }) => {
  const [showAddMember, setShowAddMember] = useState<boolean>(false);

  const {
    selectedCommunityId: communityId,
    selectedSourcingId: sourcingGroupId,
  } = useCommunitySwitcherContext();

  const { searchQuery } = useMemberListContext();

  const toggleOpenAddMember = useCallback(() => {
    setShowAddMember((show) => !show);
  }, [setShowAddMember]);

  const programId = searchQuery ? (
    searchQuery.programId || first(searchQuery.programIds)
  ) : undefined;

  const value: IMemberPageContext = {
    communityId,
    sourcingGroupId,
    programId,
    toggleOpenAddMember,
  };

  return (
    <MemberPageContext.Provider value={value}>
      {children}
      <AddMemberOverlay visible={showAddMember} onRequestClose={toggleOpenAddMember} />
    </MemberPageContext.Provider>
  );
};
