import * as React from 'react';
import cx from 'classnames';
import { map, isUndefined, size } from 'lodash';

import { partitionArrayIntoEqualSubArrays } from '@frontend/utils';

import styles from './RadioGroup.scss';

type TOptionValue = string | number | boolean;
type TAlignment = 'horizontal' | 'vertical';
interface IOption {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  selectedElement?: JSX.Element;
}
interface IProps {
  options: IOption[];
  onChange(value: TOptionValue, index: number);
  selectedIndex?: number;
  alignment?: TAlignment;
  classNames?: string[];
  columns?: number;
}
type TDefaultProp = 'classNames' | 'alignment';
interface IState {
  selectedIndex: number;
}

/**
 * @class
 * @extends {React.PureComponent}
 */
export class RadioGroup extends React.PureComponent<IProps, IState> {
  /**
   * @inheritDoc
   */
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
    alignment: 'vertical',
  };

  private controlled: boolean;

  /**
   * @inheritDoc
   */
  constructor(props: IProps) {
    super(props);

    this.controlled = !isUndefined(props.selectedIndex);

    this.state = {
      selectedIndex: null,
    };
  }

  /**
   * @private
   * Renders a list of radio options.
   *
   * @return {JSX.Element}
   */
  private renderRadioOptions = (radioOptions: IOption[], startIndex: number = 0) => {
    const selectedIndex = this.getSelectedIndex();
    return map(radioOptions, (option, index) => {
      const actualIndex = index + startIndex;
      const selected = selectedIndex === actualIndex;

      return (
        <React.Fragment key={index}>
          <div
            key={index}
            className={styles.item}
            onClick={this.selectOption.bind(this, actualIndex)}
          >
            <div
              className={cx(styles.dot, {
                [styles.selected]: selected,
              })}
            >
              {selected && <div className={styles.selectedDot} />}
            </div>
            <div className={styles.label}>{option.label}</div>
          </div>
          {selected && option.selectedElement && (
            <div className={styles.selectedElement}>{option.selectedElement}</div>
          )}
        </React.Fragment>
      );
    });
  };

  /**
   * @private
   * Renders columns of radio options.
   *
   * @return {JSX.Element}
   */
  private renderColumns = (columns: IOption[][]) => {
    let startIndex = 0;
    return map(columns, (column: IOption[], index) => {
      if (index > 0) {
        startIndex += size(column);
      }
      return (
        <div key={index} className={styles.column}>
          {this.renderRadioOptions(column, startIndex)}
        </div>
      );
    });
  };

  /**
   * @inheritdoc
   */
  public render() {
    const {
 options, classNames, columns, alignment,
} = this.props;

    const shouldRenderColumns = !isUndefined(columns) && alignment === 'vertical';

    let radioColumns;
    if (shouldRenderColumns) {
      radioColumns = partitionArrayIntoEqualSubArrays(options, columns);
    }
    return (
      <div
        className={cx(classNames.concat(styles.RadioGroup), {
          [styles.horizontal]: alignment === 'horizontal',
        })}
      >
        {shouldRenderColumns ? this.renderColumns(radioColumns) : this.renderRadioOptions(options)}
      </div>
    );
  }

  /**
   * @private
   * Select an option.
   *
   * @param {Number} index the selected index.
   */
  private selectOption = (index: number) => {
    const { onChange, options } = this.props;

    const selectedOption = options[index];

    onChange(selectedOption.value, index);

    if (!this.controlled) {
      this.setState({
        selectedIndex: index,
      });
    }
  };

  /**
   * @private
   * Returns the selected index based on whether component is controlled or not.
   *
   * @return {Number}
   */
  private getSelectedIndex = () => (this.controlled ? this.props.selectedIndex : this.state.selectedIndex);
}
