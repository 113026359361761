import { createContext, useContext } from 'react';

interface ContentWithSideMenuContextValue {
    setRoundedSideMenu?: (value: boolean) => void;
    setSideMenuCollapsed?: (value: boolean) => void;
}

const defaultValue: ContentWithSideMenuContextValue = {
    setRoundedSideMenu: () => {},
    setSideMenuCollapsed: () => {},
};

export const ContentWithSideMenuContext = createContext<ContentWithSideMenuContextValue>(defaultValue);

export const useContentWithSideMenuContext = () => {
    const contextValue = useContext(ContentWithSideMenuContext);
    return contextValue;
};
