import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_MEMBER_QUERY } from '@frontend/app/queries';
import {
  GetMemberQuery,
  GetMemberQueryVariables,
} from '@frontend/app/queries/types/GetMemberQuery';

type IOptions = QueryHookOptions<GetMemberQuery, GetMemberQueryVariables>;

export const useGetMemberQuery = (memberId: number, options: IOptions = {}) => useQuery<GetMemberQuery, GetMemberQueryVariables>(GET_MEMBER_QUERY, {
    ...options,
    variables: {
      id: memberId,
    },
    skip: !memberId || options.skip,
  });
