import { useCallback, useMemo, useReducer } from 'react';
import { logger } from '@common';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { GetBudgetDistributionsForBudgetAccountQuery_budgetDistributionsForBudgetAccount } from '@frontend/app/queries/types/GetBudgetDistributionsForBudgetAccountQuery';
import {
 ActionType, reducer, TActions, TState,
} from './state';
import { getDefaultState } from './state/getDefaultState';
import {
  AssignPaymentTo,
  IPaymentProps, PaymentsProgram, TBudgetAccount, TBudgetPayment, TGroup, TMemberInfoForPayment,
  TSTAPayment,
} from '../../types';
import { IPaymentSources } from '../../models';

type TReturn = Readonly<{
  actions: TActions;
  state: TState;
}>;

export const useState = (props: IPaymentProps): TReturn => {
  const defaultState = useMemo(() => getDefaultState(props), [props]);
  const [state, dispatch] = useReducer(reducer, defaultState);

  const setCurrentStep = useCallback((step: number) => {
    dispatch({
      step,
      type: ActionType.SET_CURRENT_STEP,
    });
  }, []);

  const setError = useCallback((error) => {
    logger.error(error);
    dispatch({
      error,
      type: ActionType.SET_ERROR,
    });
  }, []);

  const setTerms = useCallback((terms: IAgreement[]) => {
    dispatch({
      terms,
      type: ActionType.SET_TERMS,
    });
  }, []);

  const setPrograms = useCallback((programs: PaymentsProgram[]) => {
    dispatch({
      programs,
      type: ActionType.SET_PROGRAMS,
    });
  }, []);

  const setMemberInfo = useCallback((memberInfo: TMemberInfoForPayment) => {
    dispatch({
      memberInfo,
      type: ActionType.SET_MEMBER_INFO,
    });
  }, []);

  const setAmountPaying = useCallback((amountPaying: number) => {
    dispatch({
      amountPaying,
      type: ActionType.SET_AMOUNT_PAYING,
    });
  }, []);

  const setPaymentSource = useCallback((paymentSource: IPaymentSources) => {
    dispatch({
      paymentSource,
      type: ActionType.SET_PAYMENT_SOURCE,
    });
  }, []);

  const setBudgetAccounts = useCallback((budgetAccounts: TBudgetAccount[]) => {
    dispatch({
      budgetAccounts,
      type: ActionType.SET_BUDGET_ACCOUNTS,
    });
  }, []);

  const setBudgetDistributionsForBudgetAccount = useCallback((budgetDistributions: GetBudgetDistributionsForBudgetAccountQuery_budgetDistributionsForBudgetAccount[]) => {
    dispatch({
      budgetDistributions,
      type: ActionType.SET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT,
    });
  }, []);

  const setSelectedBudgetAccount = useCallback((selectedBudgetAccountId: number) => {
    dispatch({
      selectedBudgetAccountId,
      type: ActionType.SET_SELECTED_BUDGET_ACCOUNT,
    });
  }, []);

  const setSelectedBudgetDistribution = useCallback((selectedBudgetDistributionId: number) => {
    dispatch({
      selectedBudgetDistributionId,
      type: ActionType.SET_SELECTED_BUDGET_DISTRIBUTION,
    });
  }, []);

  const setSelectedTermId = useCallback((id: number) => {
    dispatch({
      id,
      type: ActionType.SET_SELECTED_TERM_ID,
    });
  }, []);

  const setBudgetPayments = useCallback((payments: TBudgetPayment[]) => {
    dispatch({
      payments,
      type: ActionType.SET_BUDGET_PAYMENTS,
    });
  }, []);

  const setOverflowBudgetPayments = useCallback((payments: TBudgetPayment[]) => {
    dispatch({
      payments,
      type: ActionType.SET_OVERFLOW_BUDGET_PAYMENTS,
    });
  }, []);

  const setAssignPaymentTo = useCallback((assignPaymentTo: AssignPaymentTo) => {
    dispatch({
      assignPaymentTo,
      type: ActionType.SET_ASSIGN_PAYMENT_TO,
    });
  }, []);

  const setGroups = useCallback((groups: TGroup[]) => {
    dispatch({
      groups,
      type: ActionType.SET_GROUPS,
    });
  }, []);

  const setFieldValue = useCallback((fieldName: string, value: number | string) => {
    dispatch({
      fieldName,
      value,
      type: ActionType.SET_FIELD_VALUE,
    });
  }, []);

  const setSelectedBudgetReassign = useCallback((selectedBudgetReassign:React.Key[]) => {
    dispatch({
      selectedBudgetReassign,
      type: ActionType.SET_BUDGET_REASSIGN,
    });
  }, []);

  const setSTAPayments = useCallback((staPayments: TSTAPayment[]) => {
    dispatch({
      staPayments,
      type: ActionType.SET_STA_PAYMENTS,
    });
  }, []);

  const actions = useMemo(() => ({
    setCurrentStep,
    setError,
    setTerms,
    setPrograms,
    setMemberInfo,
    setAmountPaying,
    setPaymentSource,
    setBudgetAccounts,
    setBudgetDistributionsForBudgetAccount,
    setSelectedBudgetAccount,
    setSelectedBudgetDistribution,
    setSelectedTermId,
    setBudgetPayments,
    setOverflowBudgetPayments,
    setAssignPaymentTo,
    setGroups,
    setFieldValue,
    setSelectedBudgetReassign,
    setSTAPayments,
  }), [
    setCurrentStep,
    setError,
    setTerms,
    setPrograms,
    setMemberInfo,
    setAmountPaying,
    setPaymentSource,
    setBudgetAccounts,
    setBudgetDistributionsForBudgetAccount,
    setSelectedBudgetAccount,
    setSelectedBudgetDistribution,
    setSelectedTermId,
    setBudgetPayments,
    setOverflowBudgetPayments,
    setAssignPaymentTo,
    setGroups,
    setFieldValue,
    setSelectedBudgetReassign,
    setSTAPayments,
  ]);

  return {
    actions,
    state,
  };
};
