import 'css-chunk:src/applications/AffiliatesApp/components/ManageOffer/ManageOffer.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1t6ek_157",
  "justify-content-space-between": "_justify-content-space-between_1t6ek_161",
  "tabular-nums": "_tabular-nums_1t6ek_165",
  "ManageOfferDrawer": "_ManageOfferDrawer_1t6ek_178",
  "header": "_header_1t6ek_181",
  "offerDetails": "_offerDetails_1t6ek_187",
  "offerName": "_offerName_1t6ek_197",
  "img": "_img_1t6ek_201",
  "manageOfferwrapper": "_manageOfferwrapper_1t6ek_209",
  "manageOfferButtons": "_manageOfferButtons_1t6ek_212",
  "buttonsAction": "_buttonsAction_1t6ek_224",
  "buttonTitle": "_buttonTitle_1t6ek_233",
  "mb0": "_mb0_1t6ek_251",
  "manageOfferContainer": "_manageOfferContainer_1t6ek_254",
  "boldText": "_boldText_1t6ek_268",
  "memberCount": "_memberCount_1t6ek_272",
  "ManageOfferAction": "_ManageOfferAction_1t6ek_276",
  "customInputBox": "_customInputBox_1t6ek_284",
  "tooltipError": "_tooltipError_1t6ek_293",
  "customInputBoxError": "_customInputBoxError_1t6ek_299",
  "activeDateContainer": "_activeDateContainer_1t6ek_306",
  "card": "_card_1t6ek_311",
  "dateHeader": "_dateHeader_1t6ek_314",
  "dateContainer": "_dateContainer_1t6ek_320",
  "dateInput": "_dateInput_1t6ek_323",
  "noticeContainer": "_noticeContainer_1t6ek_327",
  "iconWrapper": "_iconWrapper_1t6ek_333",
  "defaultLabel": "_defaultLabel_1t6ek_339",
  "collapseBg": "_collapseBg_1t6ek_346",
  "show": "_show_1t6ek_1"
};