import React from 'react';
import {
 Tooltip, TooltipTrigger, TooltipContent,
} from '@frontend/shadcn/components/ui/tooltip';
import cn from 'classnames';
import { TTooltipProps } from '@revfluence/fresh';

interface StatsComponentProps {
  title?: React.ReactNode;
  valueRender: React.ReactNode;
  tooltip?: string | TTooltipProps;
  className?: string;
}

const StatsComponent: React.FC<StatsComponentProps> = ({
 title, valueRender, tooltip, className,
}) => (
  <span className={cn('flex flex-col justify-center rounded-lg', className)}>
    <div className="flex items-center space-x-2 text-sm font-semibold">
      <span>{title && title}</span>
    </div>

    {/* If tooltip exists, wrap valueRender with Tooltip and TooltipTrigger */}
    {tooltip ? (
      <Tooltip>
        <TooltipTrigger asChild>
          <span>{valueRender}</span>
        </TooltipTrigger>
        <TooltipContent className="max-w-xs">
          <p>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    ) : (
      // If no tooltip, just render the valueRender without any tooltip
      <span>{valueRender}</span>
    )}
  </span>
);

export default StatsComponent;
