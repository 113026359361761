import * as React from 'react';
import { flattenDeep, orderBy } from 'lodash';

import { endpoints } from '@components';
import { useGet } from '@frontend/utils';

import { IProgram } from '@components';
import { useInviteContext } from './useInviteContext';

const { useMemo } = React;

export const useAllPrograms = () => {
  const {
    apiEndpoint,
    clientId,
  } = useInviteContext();

  const fetchAllProgramsUrl = useMemo(() => (
    apiEndpoint && clientId
      ? `${apiEndpoint}/${endpoints.allProgramsEndpoint}?client_id=${clientId}`
      : undefined
  ), [apiEndpoint, clientId]);

  const {
    loading: isFetching,
    data: fetchedPrograms,
    error,
  } = useGet({ url: fetchAllProgramsUrl, cache: true });

  const allPrograms: IProgram[] = useMemo(() => (
    orderBy(flattenDeep(fetchedPrograms), ['title'], ['asc'])
  ), [fetchedPrograms]);

  return {
    allPrograms,
    isFetching,
    error,
  };
};
