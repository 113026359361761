import * as React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { isArray } from 'lodash';
import { Menu } from 'antd';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

import { Button } from '@revfluence/fresh';
import { CircleXIcon } from '@revfluence/fresh-icons/regular/esm';
import { DatePicker } from 'antd-v5';
import { DateRangeOptions, IDateRangeSettings } from './types';

import styles from './DateMenu.scss';

const { useCallback, useEffect, useState } = React;

interface IProps {
  className?: string;
  onHideMenu: () => void;
  settings: IDateRangeSettings;
  updateResetDateOnClose: (reset: boolean) => void;
}

// override the removal of the box shadow from antd's default
const DATE_MENU_STYLE: React.CSSProperties = {
  boxShadow: '0 3px 6px -4px rgba(0, 0, 0, 12%), 0 6px 16px 0 rgba(0, 0, 0, 8%), 0 9px 28px 8px rgba(0, 0, 0, 5%)',
};

dayjs.extend(weekday);
dayjs.extend(localeData);

export const DateMenu: React.FC<Readonly<IProps>> = (props) => {
  const {
    className,
    onHideMenu,
    settings,
    updateResetDateOnClose,
  } = props;
  const { dateRange, onChange, selectedOption } = settings;

  const [overrideDateRange, setOverrideDateRange] = useState(false);
  useEffect(() => {
    if (selectedOption !== DateRangeOptions.CUSTOM) {
      setOverrideDateRange(false);
    }
  }, [selectedOption, setOverrideDateRange]);

  const onSelect = useCallback(({ key }) => {
    if (key !== DateRangeOptions.CUSTOM) {
      onChange(key);
      updateResetDateOnClose(false);
      onHideMenu();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, updateResetDateOnClose]);
  const onChangeCustomRange = useCallback((range) => {
    if (!isArray(range)) {
      // the user clicked "clear"
      updateResetDateOnClose(true);
      setOverrideDateRange(true);
      return;
    }
    onChange(
      DateRangeOptions.CUSTOM,
      { startDate: range[0].toDate(), endDate: range[1].toDate() },
    );
    setOverrideDateRange(false);
    updateResetDateOnClose(false);
    onHideMenu();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);
  let customPickerValue;
  if (selectedOption === DateRangeOptions.CUSTOM) {
    if (overrideDateRange) {
      customPickerValue = [undefined, undefined];
    } else {
      customPickerValue = [
        // seems there's a small type mismatch between our version of moment and antd's
        // these any casts are required
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        dateRange.startDate ? dayjs(dateRange.startDate) as any : undefined,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        dateRange.endDate ? dayjs(dateRange.endDate) as any : undefined,
      ];
    }
  }
  return (
    <Menu
      className={cx(styles.DateMenu, className)}
      onSelect={onSelect}
      selectedKeys={[selectedOption]}
      style={DATE_MENU_STYLE}
    >
      <Menu.Item key={DateRangeOptions.THIS_WEEK}>This Week</Menu.Item>
      <Menu.Item key={DateRangeOptions.LAST_WEEK}>Last Week</Menu.Item>
      <Menu.Item key={DateRangeOptions.LAST_MONTH}>Last Month</Menu.Item>
      <Menu.Item key={DateRangeOptions.MTD}>Month to Date</Menu.Item>
      <Menu.Item key={DateRangeOptions.LAST_QUARTER}>Last Quarter</Menu.Item>
      <Menu.Item key={DateRangeOptions.QTD}>Quarter to Date</Menu.Item>
      <Menu.Item key={DateRangeOptions.LAST_6_MONTHS}>Last 6 Months</Menu.Item>
      <Menu.Item key={DateRangeOptions.YTD}>Year to Date</Menu.Item>
      <Menu.Item key={DateRangeOptions.ALL_TIME}>All Time</Menu.Item>
      <Menu.Item className={styles.customRangeDatePicker} key={DateRangeOptions.CUSTOM}>
        <div className={styles.customRangeWrapper} onClick={(e) => e.stopPropagation()}>
          <DatePicker.RangePicker
            allowClear={false}
            onChange={onChangeCustomRange}
            value={customPickerValue}
            // defaultValue={customPickerValue}
          />
          <Button
            icon={<CircleXIcon className={styles.CircleXIcon} />}
            disabled={selectedOption !== DateRangeOptions.CUSTOM}
            onClick={() => {
              setOverrideDateRange(true);
              updateResetDateOnClose(true);
            }}
            size="small"
            type="link"
          />
        </div>
      </Menu.Item>
      <Menu.Divider />
      <li className={styles.instructions}>
        You are viewing data, including sales if applicable, in
        {' '}
        <a href="https://www.timeanddate.com/worldclock/timezone/utc" rel="noopener noreferrer" target="_blank">
          UTC timezone
        </a>
        .
      </li>
    </Menu>
  );
};
