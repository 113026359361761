import { IOrganization, IBrand, ICampaign } from '@components';
import { INetworkSearchVersion } from '@frontend/applications/SocialDiscoveryApp/pages/InfluencerSearchPage';
import { ICreatorSearchOptions, TRange } from './textSearch';

export interface IExternalVariables {
  apiEndpoint: string;
  apiEndpointV1: string;
  uploadFolder: string;
  brand?: IBrand;
  organization?: IOrganization;
  campaign?: ICampaign;
  isQa: boolean;
  searchOptions: ICreatorSearchOptions;
  clientId?: string;
  userId?: string;
  defaultReachLimitsByNetwork?: { [network: string]: TRange };
  defaultEngagementLimitsByNetwork?: { [network: string]: TRange };
  defaultImpressionLimitsByNetwork?: { [network: string]: TRange };
  defaultIQImpressionLimitsByNetwork?: { [network: string]: TRange };
  defaultImageSearchUrl?: string;
  version?: number;
  versionByNetwork?: INetworkSearchVersion;
  defaultIQReachLimitsByNetwork?: { [network: string]: TRange };
  defaultIQEngagementLimitsByNetwork?: { [network: string]: TRange };
}
