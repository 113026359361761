import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const ConversationIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path d="M8.33833 13.3062C7.95289 13.2634 7.61028 13.1777 7.26767 13.0492C7.13919 13.0064 7.01071
       13.0064 6.88223 13.0492L4.35546 14.0771L5.12634 12.7066C5.25482 12.4497 5.21199 12.1499 4.99786
       11.9358C3.84154 10.9936 3.15632 9.66595 3.15632 8.25268C3.15632 5.46895 6.0257 3.15632 9.53747
       3.15632C13.0493 3.15632 15.9186 5.42612 15.9186 8.25268C15.9186 8.59529 16.1756 8.85225 16.5182
       8.85225C16.8608 8.85225 17.1178 8.59529 17.1178 8.25268C17.0749 4.82655 13.6916 2 9.53747 2C5.3833
       2 2 4.82655 2 8.25268C2 9.88009 2.68522 11.379 3.88437 12.5353L2.47109 14.9764C2.34261 15.1906 2.38544
       15.4475 2.55675 15.6617C2.68522 15.7901 2.8137 15.8758 2.98501 15.8758C3.07066 15.8758 3.11349 15.8758
       3.19914 15.833L7.09636 14.2912C7.43897 14.3769 7.82441 14.4625 8.16702 14.5054C8.46681 14.5482 8.76659
       14.334 8.80942 13.9914C8.89507 13.6488 8.68094 13.349 8.33833 13.3062Z"
    />
    <path d="M20.5011 18.6595C21.4861 17.7173 22 16.561 22 15.2334C22 12.2355 19.1306 9.75161 15.7045
       9.75161C12.2784 9.75161 9.36618 12.2784 9.36618 15.2762C9.36618 17.0749 10.3512 18.788 11.9786 19.8587C13.6917
       20.9722 15.833 21.1863 17.7602 20.4154L20.8009 21.5717C20.8865 21.6146 20.9293 21.6146 21.015 21.6146C21.1863
       21.6146 21.3576 21.5289 21.4433 21.4004C21.6146 21.2291 21.6574 20.9722 21.5289 20.758L20.5011 18.6595ZM17.9315
       19.2163C17.8458 19.1734 17.803 19.1734 17.7174 19.1734C17.6317 19.1734 17.5461 19.1734 17.4604 19.2163C15.8758
       19.9443 14.0343 19.773 12.5782 18.8308C11.2934 17.9743 10.5225 16.6467 10.5225 15.2334C10.5225 12.9208 12.9208
       10.9079 15.6617 10.9079C18.4026 10.9079 20.8009 12.9208 20.8009 15.2334C20.8009 16.3469 20.3298 17.2891 19.3876
       18.06C19.1735 18.2313 19.0878 18.531 19.2163 18.788L19.773 19.9015L17.9315 19.2163Z"
    />
  </SvgIcon>
));

ConversationIcon.displayName = 'ConversationIcon';
