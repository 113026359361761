import * as React from 'react';
import {
 chain, filter, isEmpty, keyBy, map,
} from 'lodash';

import { IColumn } from '@frontend/app/types/Columns';
import { MemberPageSegment as ISegment } from '@frontend/app/queries/fragments/types/MemberPageSegment';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';

const { useMemo } = React;

export const useOrderedMemberColumns = (columns: IColumn[], segment: ISegment | IPredefinedSegment) => {
  const segmentColumns = segment?.columns;

  const columnsByField = useMemo(() => keyBy<IColumn>(columns, 'field'), [columns]);

  return useMemo(() => {
    if (isEmpty(segmentColumns)) {
      return [];
    }

    if (isEmpty(segmentColumns.order)) { // order not saved in backend yet
      return filter([
        columnsByField.name,
        ...map(
          segmentColumns.memberFieldSchemaIds,
          (schemaId) => columnsByField[schemaId],
        ),
        ...chain(segmentColumns.dbColumns)
          .filter((column) => column !== 'name')
          .map((dbColumn) => columnsByField[dbColumn])
          .filter()
          .value(),
      ]);
    }

    return chain(segmentColumns.order)
      .map((column) => (
        columnsByField[column.dbColumn] || columnsByField[column.memberFieldSchemaId]
      ))
      .filter()
      .value();
  },
  [columnsByField, segmentColumns]);
};
