import * as React from 'react';
import {
  Button,
  Form,
  Modal,
} from '@revfluence/fresh';
import { OfferFormTestIds as testIds } from '../OfferFormTestIds';

interface IProps {
  onClick: () => void;
  disabled: boolean;
  loading: boolean;
}

const ConfirmDelete = (onOk: () => void) => (Modal.confirm({
  title: 'You are about to permanently delete this Offer. This action cannot be undone. Are you sure you want to proceed?',
  okText: 'Delete',
  okButtonProps: {
    type: 'primary',
    danger: true,
  },
  onOk,
}));

export const DeleteButton: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  loading,
  onClick,
}) => (
  <Form.Item>
    <Button
      block
      danger
      data-testid={testIds.update}
      disabled={disabled}
      loading={loading}
      onClick={() => ConfirmDelete(onClick)}
      type="link"
      size="large"
    >
      Delete Offer
    </Button>
  </Form.Item>
));

DeleteButton.displayName = 'DeleteButton';
