import * as React from 'react';
import cx from 'classnames';
import { times } from 'lodash';

import { StarIcon } from '@components';

import { Cell, ICellProps } from './Cell';

import styles from './RatingCell.scss';

interface IProps extends ICellProps {
  value: number;
}

/**
 * @type {React.FunctionComponent}
 */
export const RatingCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const { className, value, ...restProps } = props;

  const rating = Math.min(5, Math.max(0, Math.round(value)));
  const content = (
    <>
      {times(rating, (index) => (
        <StarIcon key={`star_${index}`} size={18} className={styles.star} />
      ))}
      {times(5 - rating, (index) => (
        <StarIcon key={`star_border_${index}`} size={18} className={styles.noStar} />
      ))}
    </>
  );

  return <Cell className={cx(styles.RatingCell, className)} value={value > 0 ? content : '-'} {...restProps} />;
});

RatingCell.defaultProps = {};
RatingCell.displayName = 'RatingCell';
