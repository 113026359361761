/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_kcma8_157 {
  display: flex;
}

._justify-content-space-between_kcma8_161 {
  justify-content: space-between;
}

._tabular-nums_kcma8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_kcma8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._referenceText_kcma8_178 {
  color: #8C8C8C;
}

._calendarIcon_kcma8_182 {
  color: #8C8C8C;
  height: 0.75rem;
  width: 0.75rem;
}

._enableQuarterlyBudgetTracking_kcma8_188 {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.25rem;
}

._startMonthFieldContainer_kcma8_194 {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 0.25rem;
}
._startMonthFieldContainer_kcma8_194 .ant-typography {
  color: #1f1f21 !important;
}

._fiscalYearStartMonth_kcma8_206 {
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 16px;
  margin-bottom: 32px;
  margin-top: 32px;
}
._fiscalYearStartMonth_kcma8_206 .ant-form-item {
  margin-bottom: 0.375rem;
}

._quartersNamingStyleTitle_kcma8_216 {
  font-size: 1rem;
  font-weight: 600;
  color: #1F1F21;
}

._quarterNamingStyleContainer_kcma8_222 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
}

._quartersNamingStyleTitleContainer_kcma8_229 {
  display: flex;
  gap: 1rem;
  align-items: center;
}

._quarterNameCell_kcma8_235 {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

._quarterNamingStyleInfo_kcma8_241 {
  color: #8C8C8C;
  font-size: 0.875rem;
  font-weight: 400;
}

._iconContainer_kcma8_247 {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #5DB884;
  color: #FFFFFF;
}

._icon_kcma8_247 {
  color: #FFFFFF;
  font-size: 1rem;
}

._currentYearAlert_kcma8_263 {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}