import {
  ArrowLeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { LazyImage } from '@components';
import { Button } from '@revfluence/fresh';
import { LoadSpinner } from '@components';
import { filter, map, isFunction } from 'lodash';
import * as React from 'react';
import {
  Link,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import {
  useGetAllPublishedProjectsQuery,
  useProjectByIdQuery,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import styles from './ProjectsWithPublishedApplicationPage.scss';
import { isApplicationPageEditable, onOpenTemplate } from '../utils';
import { useGetProjectLandingPageUrl } from '../../Onboarding/OnboardingWizard/hooks/useGetProjectLandingPageUrl';

import { ProjectsRouteRoot } from '../constants';
import { ProjectStatus } from '../OverviewPage/Header/constants';

const { useMemo } = React;

const previewBtnSvg = require('@frontend/app/assets/svgs/preview_btn.svg');
const duplicateBtnSvg = require('@frontend/app/assets/svgs/duplicate_btn.svg');

const PreviewButton: React.FC<{ customLandingPagePath: string }> = ({ customLandingPagePath }) => {
  const { getFullPageUrl } = useGetProjectLandingPageUrl();

  const url = useMemo(() => (
    isFunction(getFullPageUrl) && getFullPageUrl(customLandingPagePath, '')
  ), [getFullPageUrl, customLandingPagePath]);

  const openTemplate = () => {
    onOpenTemplate(url);
  };

  return (
    <Button type="default" onClick={openTemplate}>
      <img src={previewBtnSvg} />
    </Button>
  );
};

const ProjectsWithPublishedApplicationPage: React.FC = React.memo(() => {
  const history = useHistory();
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);
  const {
    params: {
      projectId,
    },
  } = useRouteMatch<{ projectId?: string }>();

  const {
    loading: isLoadingProject,
    data: {
      project = undefined,
    } = {},
  } = useProjectByIdQuery({
    variables: {
      id: projectId ? parseInt(projectId, 10) : undefined,
    },
    skip: !projectId,
  });

  const {
    data: {
      projects = [],
    } = {},
    loading: isLoadingProjects,
  } = useGetAllPublishedProjectsQuery();

  const onBackButtonClick = () => {
    if (!project) return;

    if (isApplicationPageEditable(project)) {
      history.replace({
        pathname: `${ProjectsRouteRoot}/${project?.id}/overview`,
      });
    } else {
      history.goBack();
    }
  };

  const activeProjects = useMemo(() => {
    if (isArchiveProjectEnabled) {
      return filter(projects, (project) => project.status === ProjectStatus.Active);
    }
    return [...projects];
  }, [
    projects,
    isArchiveProjectEnabled,
  ]);

  if (isLoadingProjects || isLoadingProject) return <LoadSpinner />;

  return (
    <div className={styles.PublishedProjectsList}>
      <div className={styles.navigate}>
        <Button
          className={styles.backButton}
          onClick={onBackButtonClick}
          type="text"
        >
          <ArrowLeftOutlined className={styles.leftArrowIcon} />
        </Button>
        <LazyImage src={project?.splashImageUrl} className={styles.splashImage} />
        <h1 className={styles.title}>Settings</h1>
      </div>
      <div className={styles.content}>
        <div className={styles.newPageBtn}>
          <Link to="settings/landing_page">
            <Button type="default" icon={<PlusOutlined size={15} />}>
              Create New Page
            </Button>
          </Link>
        </div>
        <div className={styles.title}>
          Duplicate an existing application page from a project
        </div>
        <div className={styles.listGridView}>
          {
            map(activeProjects, (activeProject) => (
              <div key={activeProject.id} className={styles.listItem}>
                <div className={styles.ctaBtns}>
                  <PreviewButton customLandingPagePath={activeProject.customLandingPagePath} />
                  <Link to={{
                      pathname: `${ProjectsRouteRoot}/${project?.id}/settings/landing_page`,
                      state: {
                        onboardingTemplateConfig: activeProject.onboardingTemplateConfig,
                        applicationFormFields: activeProject.applicationFormFields,
                      },
                    }}
                  >
                    <Button type="primary">
                      <img src={duplicateBtnSvg} />
                    </Button>
                  </Link>
                </div>
                <LazyImage
                  className={styles.image}
                  src={activeProject.splashImageUrl}
                />
                <div className={styles.listItemTitle}>
                  {activeProject.title}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
});

ProjectsWithPublishedApplicationPage.displayName = 'ProjectsWithPublishedApplicationPage';

export { ProjectsWithPublishedApplicationPage };
