import gql from 'graphql-tag';

export const MESSAGE_FRAGMENT = gql`
  fragment MessageFragment on Message {
    id
    type
    externalMessageId
    internalDate
    subject
    snippet
    threadId
    title
    applicationId
    isAutomated
    attachments {
      attachmentId
      href
      type
      size
      filename
      filenameInStorage
      isInline
    }
  }
`;

export const MESSAGE_FRAGMENT_WITH_PAYLOAD = gql`
  fragment MessageFragmentWithPayload on Message {
    ...MessageFragment
    payload {
      from
      to
      cc
      internalMessageId
      textPlain
      textHtml
      imageURLs
      story {
        mention {
          link
          id
        }
      }
      attachments {
        width
        height
        url
        previewUrl
        type
      }
      messageActions {
        actionText
        actionType
        parameters
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;
