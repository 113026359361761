import { IContentList, ILicensedContent } from '@components';
import { ICountryRegionOption } from './textSearch';

export type IImageSearchUpload = (
  clientId: string,
  setUploadedImageUrl: (url: string) => void,
  setUploadedImageLoading: () => void,
  setUploadedImageError: (error: Error) => void
) => (file: File) => void;

export interface IImageSearch {
  imageURL?: string;
  isLoadingImage?: boolean;
  countries: ICountryRegionOption[];
  hideInvited: boolean;
  hideInvitedToggle: boolean;
  contentLists: IContentList[];
  contentByListId: {
    [id: string]: ILicensedContent[];
  };
  isLoadingContentLists: boolean;
  hasContentListError: boolean;
  isLoadingContent: boolean;
  hasContentError: boolean;
  useImageSearchUpload?: IImageSearchUpload;
  clientId: string;
  selfServeExperiment: boolean;
}
