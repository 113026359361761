import gql from 'graphql-tag';

export const GET_EMBEDDED_REPORTS = gql`
query GetEmbeddedReports {
  embeddedReportingItems {
    id
    name
    position
    url
  }
}
`;
