import * as React from 'react';

const { useState } = React;

/**
 * Checks if elment is hovered.
 */
export const useHover = (defaultHovered: boolean = false): [
  boolean,
  {
    onMouseEnter: (event: React.MouseEvent) => void;
    onMouseLeave: (event: React.MouseEvent) => void;
  },
] => {
  const [isHovered, setHovered] = useState(defaultHovered);

  const listeners = {
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  };

  return [isHovered, listeners];
};
