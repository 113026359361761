export enum ActionTypes {
  SET_CONTENTS = '@contentUploader/SET_CONTENTS',
  ADD_CONTENT = '@contentUploader/ADD_CONTENT',
  UPDATE_PROGRESS = '@contentUploader/UPDATE_PROGRESS',
  UPDATE_FILE_URL = '@contentUploader/UPDATE_FILE_URL',
  REMOVE_CONTENTS = '@contentUploader/REMOVE_CONTENTS',
  REMOVE_CONTENT = '@contentUploader/REMOVE_CONTENT',
  SET_ERROR_MESSAGE = '@contentUploader/SET_ERROR_MESSAGE',
}

interface IContentUploaderErrorParams {
  isUploadAborted?: boolean;
  message: string;
}

export class ContentUploaderError extends Error {
  public static DefaultMessage = `There was an issue uploading your file.
    If this continues to happen, please email help@aspireiq.com'`;

  private static paramDefaults: IContentUploaderErrorParams = {
    isUploadAborted: false,
    message: ContentUploaderError.DefaultMessage,
  };

  public isUploadAborted: boolean;

  constructor(params: IContentUploaderErrorParams = ContentUploaderError.paramDefaults) {
    // Calling parent constructor of base Error class.
    super();

    const { isUploadAborted, message } = { ...ContentUploaderError.paramDefaults, ...params };

    // Sets the error properties.
    this.name = 'ContentUploaderError';
    this.isUploadAborted = isUploadAborted;
    this.message = message;

    if (typeof Error.captureStackTrace === 'function') {
      // Capturing stack trace, excluding constructor call from it.
      Error.captureStackTrace(this, ContentUploaderError);
    } else {
      this.stack = new Error().stack;
    }
  }
}

export interface IUploadProgress {
  // bytes
  uploaded: number;
  percentage: number;
}
export interface IContentUploaderAction {
  type: ActionTypes;
  payload?: {
    contents?: IUploaderContent[];
    content?: IUploaderContent;
    id?: string;
    progress?: IUploadProgress;
    fileUrl?: string;
    previewUrl?: string;
    errorMessage?: string;
  };
}

/* States */
export interface IContentUploader {
  uploadFolder: string;
  postEndpoint: string;
  contents: IUploaderContent[];

  // disable file selector when maximum number of contents reached
  maxContents: number;
  errorMessage?: string;
}

export type TContentType = 'image' | 'video' | 'application';
export interface IUploaderContent {
  type: TContentType | string;
  file?: File;
  id?: string;
  name?: string;
  size?: number;
  localSrc?: string;
  progress?: IUploadProgress;
  // set file url after content is uploaded
  fileUrl?: string;
  // url to display to user
  previewUrl?: string;
  // whether to disable removing content
  disableRemove?: boolean;
  // xhr
  xhr?: XMLHttpRequest;
}

export const initialState: IContentUploader = {
  uploadFolder: null,
  postEndpoint: null,
  contents: [],

  maxContents: null,
  errorMessage: null,
};
