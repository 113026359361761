import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Typography } from '@revfluence/fresh';
import { useQueryParams } from '@frontend/app/hooks';

const { Title } = Typography;

export const PageTitle: React.FC = () => {
  const match = useRouteMatch();
  const query = useQueryParams();
  const queryProgramId = query.get('program_id');
  const queryWorkflowSpecUri = query.get('worklet_spec_uri');
  const queryTaskId = query.get('task_id');
  const queryHasWorkflowParams = queryProgramId && queryWorkflowSpecUri && queryTaskId;
  return (
    <Switch>
      <Route
        path={`${match.url}/offers/newAffiliateLink`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <Title level={4} style={{ margin: 0 }}>
                Create New Affiliate Offer
              </Title>
            );
          }
          return (
            <Title level={4} style={{ margin: 0 }}>
              Sales Tracking
            </Title>
          );
        }}
      />
      <Route
        path={`${match.url}/offers/newShopifyPromo`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <Title level={4} style={{ margin: 0 }}>
                Create New Promo Offer
              </Title>
            );
          }
          return (
            <Title level={4} style={{ margin: 0 }}>
              New Promo Code Offer
            </Title>
          );
        }}
      />
      <Route
        path={`${match.url}/offers/:offerid/details`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <Title level={4} style={{ margin: 0 }}>
                Offer Details
              </Title>
            );
          }
          return (
            <Title level={4} style={{ margin: 0 }}>
              Sales Tracking
            </Title>
          );
        }}
      />
      <Route
        path={`${match.url}/offers/:offerid/edit`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <Title level={4} style={{ margin: 0 }}>
                Edit Offer
              </Title>
            );
          }
          return (
            <Title level={4} style={{ margin: 0 }}>
              Sales Tracking
            </Title>
          );
        }}
      />
      <Route
        path={`${match.url}/offers/:offerId/confirmation`}
        render={() => {
          if (queryHasWorkflowParams) {
            return (
              <Title level={4} style={{ margin: 0 }}>
                Offer Confirmation
              </Title>
            );
          }
          return (
            <Title level={4} style={{ margin: 0 }}>
              Sales Tracking
            </Title>
          );
        }}
      />
      <Route
        path={`${match.url}/dashboard`}
        render={() => (
          <Title level={4} style={{ margin: 0 }}>
            Sales Tracking
          </Title>
        )}
      />
    </Switch>
  );
};
