import * as React from 'react';
// import { InstagramIcon } from '@components';

import { Head } from './Head';

import styles from './ThankYouPage.scss';

const logo = require('@frontend/app/assets/svgs/aiq-logomark.svg');
const banner = require('./assets/camera-guy.jpg');

export const ThankYouPage: React.FunctionComponent = () => (
  <div className={styles.ThankYouPage}>
    <Head />
    <img src={logo} className={styles.logo} />
    <img className={styles.sideBanner} src={banner} />
    <div className={styles.content}>
      <div className={styles.title}>Thank you!</div>
      <div className={styles.subtitle}>We will review your application and be in touch soon…</div>
      { /* TODO: Implements logic to redirect to brand's instagram account RELATED: https://aspireiq.atlassian.net/browse/DT-1433
          <a className={styles.followBox} href="https://instagram.com/aspireiq" target="_blank">
          <InstagramIcon size={34} className={styles.icon} />
          <div className={styles.text}>
            <div className={styles.title}>Stay up to date</div>
            <div className={styles.subtitle}>Follow us on Instagram!</div>
          </div>
        </a> */ }
    </div>
  </div>
  );
