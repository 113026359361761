import { useQuery, QueryHookOptions } from '@apollo/client';

import { useAuth } from '@frontend/context/authContext';
import { GET_CLIENT_CONFIG_QUERY } from '../queries';
import { GetClientConfiguration, GetClientConfigurationVariables } from '../queries/types/GetClientConfiguration';

type IOptions = QueryHookOptions<GetClientConfiguration, GetClientConfigurationVariables>;

export function useGetClientConfig(options: IOptions = {}) {
  const { clientInfo } = useAuth();

  const {
    loading,
    data,
    error,
    refetch,
  } = useQuery<GetClientConfiguration, GetClientConfigurationVariables>(
    GET_CLIENT_CONFIG_QUERY,
    {
      variables: {
        clientId: clientInfo?.id,
      },
      ...options,
    },
  );

  return {
    loading,
    clientConfig: data?.clientConfig,
    error,
    refetch,
  };
}
