import gql from 'graphql-tag';

export const SAVE_MEMBER_COMMENT = gql`
  mutation SaveMemberCommentMutation($memberId: Int!, $comment: MemberCommentInput!) {
    memberComment: saveMemberComment(memberId: $memberId, comment: $comment) {
      id
      text
      userId
      createdDate
      updatedDate
    }
  }
`;
