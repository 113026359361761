/* eslint-disable max-len */
import * as React from 'react';
import {
  map,
  keyBy,
  isEmpty,
  filter,
  isString,
  first,
} from 'lodash';
import { IApplicationContainerHandle } from '@frontend/app/containers/Application/ApplicationContainer';
import { useResourceContext } from '@frontend/app/context';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import EmptyEmailResources from '@frontend/applications/TermsApp/components/EmptyEmailResources';
import {
  Route, Switch, useHistory, useRouteMatch, Redirect,
} from 'react-router-dom';
import { useCreateBulkTermsMutation } from '@frontend/app/hooks/useCreateBulkTermsMutation';
import {
  useGetDefaultProgramTemplate,
  useBrandEditTermsMutation,
  useGetTermsName,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';

import {
  TermsType,
} from '@frontend/app/types/globalTypes';
import { logger } from '@common';
import TermsTemplate from '@frontend/app/containers/Projects/TermsPage/components/TermsTemplate';
import { IState as BasicTermsState } from '@frontend/app/containers/Projects/TermsPage/components/types/state';
import { IWorkItem } from '@services/workflow';
import { ClientFeature } from '@frontend/app/constants';
import { IBulkTermsFormConfig } from '../types/Terms';

import { AgreementIterationView } from './AgreementIterationView';
import { TermsAppMessage } from './TermsAppMessage';
import { TermsHistory } from './TermsHistory';
import { BulkTerms } from '../containers/BulkTerms/BulkTerms';
import { ITermsAppDeeplinkParams } from '../types/ITermsAppDeeplinkParams';
import { IAxProjectInfo } from '../types/IAxProjectInfo';
import { IState } from '../components/BulkTerms/types/state';
import { IMember } from '../components/BulkTerms/types/Member';
import { mapAgreementIteration, mapBasicTermsToAgreementIteration } from '../utils/TermsMappings';

const {
  useEffect,
  useMemo,
  useState,
  useRef,
  useImperativeHandle,
} = React;

interface ITermsAppModalProps {
  deepLinkParams: ITermsAppDeeplinkParams;
  onCloseModal: () => void;
  onSetTitle: (title: string) => void;
}

interface IExternalParams {
  backendServerPostProjectId: string;
  backendServerAgreementId: string;
  workItems: IWorkItem[];
  projectId: number;
  memberId: number;
  memberIds: number[];
  programId: number;
  workitemId: string;
  termsType: TermsType;
  nativeView: true;
}

interface IExternalIFrameParams {
  program_id: number;
  iteration_id: number;
  member_id: number;
  workitemId?: string;
  nativeView: false;
}
const ACTIONS = {
  VIEW_HISTORY: 'view_history',
  REVIEW_TERMS: 'review_terms',
  VIEW_TERMS: 'view_terms',
};
const TermsAppModal = React.forwardRef<IApplicationContainerHandle, ITermsAppModalProps>(({
  deepLinkParams,
  onCloseModal,
  onSetTitle,
}, ref) => {
  const { action = ACTIONS.VIEW_HISTORY } = deepLinkParams || {};
  const history = useHistory();
  const match = useRouteMatch();

  const isBasicTermsEnabled = useClientFeatureEnabled(ClientFeature.BASIC_TERMS);

  const { activeEmailResources: resources, loading } = useResourceContext();
  const {
    workflowActionParameters, memberId: memberIdFromApp, clientId, clientName,
  } = useApplication();
  const [projectInfo, setProjectInfo] = useState<IAxProjectInfo>(null);
  const [bulkTermsConfig, setBulkTermsConfig] = useState<IBulkTermsFormConfig | null>(null);
  const [extraParams, setExtraParams] = useState<IExternalParams | IExternalIFrameParams>(null);
  const memberId = deepLinkParams?.member_id || workflowActionParameters?.memberIds[0] || parseInt(memberIdFromApp, 10) || 0;
  const { singularTermsName } = useGetTermsName();
  const termsRef = useRef<IApplicationContainerHandle>(null);

  useImperativeHandle(ref, () => ({
    showWarningOnClose: () => termsRef.current?.showWarningOnClose(),
  }));

  // action comes from link params
  const isViewTerms = useMemo(
    () => action === ACTIONS.VIEW_TERMS,
    [action],
  );

  const {
    loading: defaultTemplateLoading,
    data: {
      defaultTemplate = null,
    } = {},
  } = useGetDefaultProgramTemplate({
    variables: {
      programId: bulkTermsConfig?.programId,
      termsType: isBasicTermsEnabled === false ? TermsType.ADVANCED : null,
    },
    skip: !bulkTermsConfig?.programId
      || isViewTerms,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!extraParams?.nativeView) {
      return;
    }
    const workItemsByMemberId = keyBy(extraParams.workItems, 'data.memberId');
    const termsType = extraParams.termsType || defaultTemplate?.termsType;
    if (!bulkTermsConfig || (!bulkTermsConfig.termsType && termsType)) {
      setBulkTermsConfig({
        memberIds: extraParams.memberIds,
        programId: extraParams.programId,
        clientName,
        clientId,
        workItemsByMemberId,
        termsType,
        backendServerPostProjectId: extraParams.backendServerPostProjectId,
        isViewTerms,
        backendServerAgreementId: extraParams.backendServerAgreementId,
      });
    }
  }, [
    extraParams,
    clientId,
    clientName,
    isViewTerms,
    bulkTermsConfig,
    defaultTemplate,
  ]);

  const [
    createBulkTermsMutation,
  ] = useCreateBulkTermsMutation();

  const [
    brandEditTermsMutation,
  ] = useBrandEditTermsMutation();

  const onSave = async (state: IState): Promise<boolean> => {
    const bamDetails = state.collaborationDetails.bam;
    const selectedMembers = filter(state.members, { selected: true, disabled: false });
    const mutationInput = {
      programId: bulkTermsConfig.programId,
      memberDetails: map(selectedMembers, (member: IMember) => ({
        memberId: member.id,
        workItemId: bulkTermsConfig.workItemsByMemberId[member.id].id,
        priceOverride: member.paymentAmount || 0,
      })),
      projectInput: {
        emailDetails: {
          subject: state.emailSubject,
          body: state.emailContent.html,
          resourceId: state.resourceId,
          attachments: map(state.emailAttachments, (attachment) => ({
            filename: attachment.name,
            url: attachment.fileUrl,
            type: attachment.type,
          })),
          additionalCc: state.additionalCc,
        },
        advertiserAccess: {
          startDate: bamDetails.startDateAccess ? bamDetails.startDateAccess.toLocaleDateString('en-US') : '',
          endDate: bamDetails.endDateAccess ? bamDetails.endDateAccess.toLocaleDateString('en-US') : '',
          requireAccess: state.collaborationDetails.bam.toggleInfluencerWhitelist,
        },
        brandedContentAccess: {
          requireAccess: state.collaborationDetails.bam.toggleInfluencerBrandedContent,
        },
        clientId: bulkTermsConfig.clientId,
        programId: bulkTermsConfig.programId,
        agreementIteration: mapAgreementIteration(state),
      },
    };
    try {
      await createBulkTermsMutation({
        variables: {
          programId: mutationInput.programId,
          projectInput: mutationInput.projectInput,
          memberDetails: mutationInput.memberDetails,
          fromErrorState: action === 'fix_terms',
          termsType: TermsType.ADVANCED,
        },
      });
    } catch (error) {
      // stringify the error to see all the graphql actual error
      logger.error('Error submitting bulk terms form');
      logger.error(JSON.stringify(error, null, 2));
      return false;
    }
    return true;
  };

  const handleSendBasicTerms = async (state: BasicTermsState): Promise<boolean> => {
    const mutationInput = {
      programId: bulkTermsConfig.programId,
      memberDetails: map(bulkTermsConfig.memberIds, (id: string | number) => {
        const formattedMemberId = isString(id) ? parseInt(id, 10) : id;
        return {
          memberId: formattedMemberId,
          workItemId: bulkTermsConfig.workItemsByMemberId[formattedMemberId]?.id || null,
          priceOverride: state.compensation.payment.newPrice || 0,
        };
      }),
      projectInput: {
          introMessage: state.introMessage,
          emailDetails: {
            subject: state.emailSubject,
            body: state.emailContent.html,
            resourceId: state.resourceId,
            attachments: map(state.emailAttachments, (attachment) => ({
              filename: attachment.name,
              url: attachment.fileUrl,
              type: attachment.type,
            })),
            additionalCc: state.additionalCc,
          },
          advertiserAccess: {
            startDate: '', // TODO: Product confirmation
            endDate: '', // TODO: Product confirmation
            requireAccess: false, // TODO: Product confirmation
          },
          brandedContentAccess: {
            requireAccess: false, // TODO: Product confirmation
          },
          clientId: bulkTermsConfig.clientId,
          programId: bulkTermsConfig.programId,
          agreementIteration: mapBasicTermsToAgreementIteration(state),
          backendServerPostProjectId: bulkTermsConfig.backendServerPostProjectId ? bulkTermsConfig.backendServerPostProjectId.toString() : null,
        },
    };
    if (bulkTermsConfig.isViewTerms) {
      try {
        await brandEditTermsMutation({
          variables: {
            programId: mutationInput.programId,
            projectInput: mutationInput.projectInput,
            memberDetails: mutationInput.memberDetails[0],
          },
        });
      } catch (error) {
        // stringify the error to see all the graphql actual error
        logger.error('Error submitting bulk terms form');
        logger.error(JSON.stringify(error, null, 2));
        return false;
      }
      return true;
    }
    try {
      await createBulkTermsMutation({
        variables: {
          programId: mutationInput.programId,
          projectInput: mutationInput.projectInput,
          memberDetails: mutationInput.memberDetails,
          fromErrorState: deepLinkParams?.action === 'fix_terms',
          termsType: TermsType.BASIC,
        },
      });
    } catch (error) {
      // stringify the error to see all the graphql actual error
      logger.error('Error submitting bulk terms form');
      logger.error(JSON.stringify(error, null, 2));
      return false;
    }
    return true;
  };

  const viewIteration = (iterationId: number) => {
    setProjectInfo(null);
    setExtraParams({
      program_id: null,
      iteration_id: iterationId,
      member_id: memberId,
      nativeView: false,
    });
    history.push(`${match.url}/agreement/view`);
  };

  const handleProjectInfo = (project: IAxProjectInfo) => {
    setProjectInfo(project);
    history.push(`${match.url}/message`);
  };

  const handleSwitchTermsType = (type: TermsType) => {
    setBulkTermsConfig({
      ...bulkTermsConfig,
      termsType: type,
    });
    onSetTitle(type === TermsType.BASIC ? `Send ${singularTermsName}` : `Send ${singularTermsName} - Content Requirements`);
    if (type === TermsType.BASIC) {
      history.push(`${match.url}/agreement/send_terms_basic`);
    } else {
      history.push(`${match.url}/agreement/send_terms`);
    }
  };

  useEffect(() => {
    if (!workflowActionParameters && !deepLinkParams) {
      if (action === ACTIONS.VIEW_HISTORY) {
        setExtraParams({
          program_id: null,
          iteration_id: null,
          member_id: null,
          nativeView: false,
        });
}
      return;
    }
    const workItems = workflowActionParameters?.workItems;
    const workItemData = first(workItems)?.data;
    const termsTaskData = workItemData?.sending_terms_task_data;
    const taskDataTermsType = termsTaskData ? termsTaskData.terms_type?.toUpperCase() || TermsType.ADVANCED : null;
    // deeplinkparams  (params from url) should override getting params from task
    const termsType = deepLinkParams?.terms_type || taskDataTermsType;
    const workitemId = deepLinkParams?.work_item_id || first(workItems)?.id;
    const backendServerPostProjectId = termsTaskData?.post_project_id || null;
    const backendServerAgreementId = termsTaskData?.agreement_id || null;
    if ([ACTIONS.REVIEW_TERMS, ACTIONS.VIEW_TERMS].includes(action) && (termsType !== TermsType.BASIC || !isBasicTermsEnabled)) {
      setExtraParams({
        program_id: deepLinkParams?.program_id || workflowActionParameters?.programId,
        iteration_id: null, // backend_server doesn't keep this up to date if member is editing, so pass null, let it retrieve the latest iteration
        member_id: deepLinkParams?.member_id || workflowActionParameters?.memberIds[0],
        workitemId,
        nativeView: false,
      });
    } else {
      setExtraParams({
        backendServerPostProjectId,
        backendServerAgreementId: deepLinkParams?.agreement_id || backendServerAgreementId,
        workItems,
        projectId: deepLinkParams?.project_id || workflowActionParameters?.projectId,
        memberId: deepLinkParams?.member_id || workflowActionParameters?.memberIds[0],
        memberIds: deepLinkParams?.member_id ? [deepLinkParams?.member_id] : map(workflowActionParameters?.memberIds, (id) => (isString(id) ? parseInt(id, 10) : id)),
        programId: deepLinkParams?.program_id || workflowActionParameters?.programId,
        workitemId,
        termsType,
        nativeView: true,
      });
    }
  }, [deepLinkParams, workflowActionParameters, action, isBasicTermsEnabled]);
  const isPageReady = useMemo(
    () => {
      if (deepLinkParams?.iteration_id) { // viewing from link from members page
        return true;
      }
      if (loading || defaultTemplateLoading) { // graphql is still loading
        return false;
      }
      if (extraParams?.nativeView === false) { // this means iframe view, it only needs extraparams
        return true;
      }
      if (extraParams?.nativeView === true && !!bulkTermsConfig?.termsType) { // if it's not iframe view, it needs to wait for bulkTermsConfig to be set
        return true;
      }
      return false;
    },
    [bulkTermsConfig?.termsType, defaultTemplateLoading, loading, extraParams, deepLinkParams?.iteration_id],
  );
  const getDefaultPathName = () => {
    if (action === ACTIONS.VIEW_HISTORY) {
      return `${match.url}/history`;
    }
    if ([ACTIONS.REVIEW_TERMS, ACTIONS.VIEW_TERMS].includes(action)) {
      return `${match.url}/agreement/view`;
    }
    if (!isBasicTermsEnabled) {
      return `${match.url}/agreement/send_terms`;
    }
    return (bulkTermsConfig.termsType === TermsType.BASIC) ? `${match.url}/agreement/send_terms_basic` : `${match.url}/agreement/send_terms`;
  };

  const shouldBlockForEmptyEmailResource = useMemo(
    () => isEmpty(resources) && ![ACTIONS.VIEW_HISTORY, ACTIONS.REVIEW_TERMS, ACTIONS.VIEW_TERMS].includes(action),
    [resources, action],
  );

  if (!isPageReady) return null;
  if (deepLinkParams?.iteration_id) {
    return (
      <AgreementIterationView
        memberId={deepLinkParams?.member_id}
        programId={deepLinkParams?.program_id}
        projectId={null}
        workItemId={null}
        iterationId={deepLinkParams?.iteration_id}
        disableEditTerms={isEmpty(resources).toString()}
        onSetProjectInfo={handleProjectInfo}
      />
  );
  }
  return shouldBlockForEmptyEmailResource
   ? <EmptyEmailResources />
   : (
     <Switch>
       <Route path={`${match.url}/agreement/view`}>
         <>
           {
            bulkTermsConfig?.termsType !== TermsType.BASIC && extraParams?.nativeView === false && (
              <AgreementIterationView
                memberId={extraParams?.member_id}
                programId={extraParams?.program_id}
                projectId={null}
                workItemId={extraParams?.workitemId}
                iterationId={extraParams?.iteration_id || deepLinkParams?.iteration_id}
                disableEditTerms={isEmpty(resources).toString()}
                onSetProjectInfo={handleProjectInfo}
              />
            )
          }
           {
            bulkTermsConfig?.termsType === TermsType.BASIC && (
              <TermsTemplate
                isEditing={!!bulkTermsConfig.backendServerPostProjectId}
                ref={termsRef}
                memberIds={bulkTermsConfig.memberIds}
                projectId={bulkTermsConfig.programId}
                termsType={bulkTermsConfig.termsType}
                agreementId={bulkTermsConfig?.backendServerAgreementId ? bulkTermsConfig.backendServerAgreementId.toString() : null}
                onClose={onCloseModal}
                onSwitchTermsType={() => handleSwitchTermsType(TermsType.ADVANCED)}
                onSendTerms={handleSendBasicTerms}
              />
            )
          }
         </>
       </Route>

       <Route path={`${match.url}/agreement/send_terms`}>
         <BulkTerms
           {...bulkTermsConfig}
           ref={termsRef}
           onSave={onSave}
           onClose={onCloseModal}
           onSetTitle={onSetTitle}
           onSwitchTermsType={() => handleSwitchTermsType(TermsType.BASIC)}
         />
       </Route>

       <Route path={`${match.url}/agreement/send_terms_basic`}>
         <TermsTemplate
           isEditing={false}
           ref={termsRef}
           memberIds={map(
            workflowActionParameters?.memberIds,
            (id) => (isString(id) ? parseInt(id, 10) : id),
          )}
           projectId={bulkTermsConfig?.programId}
           termsType={bulkTermsConfig?.termsType}
           onClose={onCloseModal}
           onSwitchTermsType={() => handleSwitchTermsType(TermsType.ADVANCED)}
           onSendTerms={handleSendBasicTerms}
         />
       </Route>
       <Route path={`${match.url}/message`}>
         <TermsAppMessage
           projectInfo={projectInfo}
           memberId={memberId}
         />
       </Route>
       <Route path={`${match.url}/history`}>
         <TermsHistory onViewIteration={viewIteration} setTermsInfo={(info) => setBulkTermsConfig(info)} />
       </Route>
       <Redirect
         from={match.url}
         to={
          {
            ...window.location,
            pathname: getDefaultPathName(),
}
}
       />

     </Switch>
  );
});

TermsAppModal.displayName = 'TermsAppModal';

export default TermsAppModal;
