/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1o9x6_157 {
  display: flex;
}

._justify-content-space-between_1o9x6_161 {
  justify-content: space-between;
}

._tabular-nums_1o9x6_165 {
  font-variant-numeric: tabular-nums;
}

._FilterSection_1o9x6_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1o9x6_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._FilterSection_1o9x6_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._FilterSection_1o9x6_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._FilterSection_1o9x6_169 ::-webkit-scrollbar-track, ._FilterSection_1o9x6_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._FilterSection_1o9x6_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._FilterSection_1o9x6_169 ._header_1o9x6_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._FilterSection_1o9x6_169 ._header_1o9x6_204 {
  margin: 5px 0;
  padding-bottom: 5px;
}
._FilterSection_1o9x6_169 ._caption_1o9x6_249 {
  margin-top: 0.625rem;
  color: #8c8c8c;
}
._FilterSection_1o9x6_169 ._clear_1o9x6_253 {
  margin-top: -10px;
  float: right;
  background-color: transparent;
  color: #3996e0;
}
._FilterSection_1o9x6_169 ._clear_1o9x6_253:active {
  background-color: transparent;
  color: #3996e0;
}
._FilterSection_1o9x6_169 ._accordion_1o9x6_263[class*=open] > [class*=contents] {
  max-height: unset;
}
._FilterSection_1o9x6_169._isSubsection_1o9x6_266 {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e8ea;
}
._FilterSection_1o9x6_169:not(._isSubsection_1o9x6_266) {
  padding-top: 20px;
}
._FilterSection_1o9x6_169:not(._isSubsection_1o9x6_266):first-child {
  padding-top: 0;
}