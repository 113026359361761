import * as React from 'react';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { ErrorBoundary } from '@frontend/utils';

import { initialState } from './redux/creatorListModel';
import creatorListReducer from './redux/creatorListReducer';

import CreatorListInternal, { IOwnProps, IInitialStateProps } from './CreatorList';

type IProps = IOwnProps & IInitialStateProps;
type TDefaultProp = 'classNames';

/**
 * @class
 * @extends {React.Component}
 */
export class CreatorList extends React.Component<IProps> {
  public static defaultProps: Pick<IProps, TDefaultProp> = {
    classNames: [],
  };

  private creatorListStore;

  /**
   * @inheritdoc
   */
  constructor(props: IProps) {
    super(props);

    // check endpoint
    if (!props.apiEndpoint) {
      throw new Error('API endpoint is needed.');
    }
  }

  /**
   * @inheritdoc
   */
  public UNSAFE_componentWillMount() {
    const {
 brandId, campaign, org, isQa, apiEndpoint,
} = this.props;
    const middleWares = [thunk];

    // only use redux-logger in development
    if (process.env.NODE_ENV !== 'production') {
      const createLogger = require('redux-logger').createLogger;

      middleWares.push(createLogger());
    }

    this.creatorListStore = createStore(
      creatorListReducer,
      {
        ...initialState,
        brandId,
        campaign,
        org,
        isQa,
        apiEndpoint,
      },
      compose(applyMiddleware(...middleWares)),
    );
  }

  /**
   * @inheritdoc
   */
  public render() {
    const {
      $state,
      classNames,
      customTableConfig,
      emptyMessage,
      exportCsv,
      initialDisplayMode,
      initialSocialAccounts,
      listHeader,
      loadingStatus,
      onCreatorSelected,
      onReachedBottom,
      pageCount,
      reportAsIncorrect,
      selectedPage,
      selectedSocialAccountId,
      sendBulkOffer,
      showPagination,
      showRelevantPostImage,
      title,
      apiEndpoint,
      clientId,
      showSocialProfileOnCreatorSelect,
      selectedSocialAccounts = [],
      onSelectedAccountsChange,
      showCreateFeatures = true,
      reloadAccountDetails = false,
      shouldShowDemoFeatures = true,
      searchedMentions,
    } = this.props;

    return (
      <Provider store={this.creatorListStore}>
        <ErrorBoundary>
          <CreatorListInternal
            title={title}
            listHeader={listHeader}
            loadingStatus={loadingStatus}
            emptyMessage={emptyMessage}
            initialSocialAccounts={initialSocialAccounts}
            sendBulkOffer={sendBulkOffer}
            $state={$state}
            exportCsv={exportCsv}
            reportAsIncorrect={reportAsIncorrect}
            showRelevantPostImage={showRelevantPostImage}
            classNames={classNames}
            onReachedBottom={onReachedBottom}
            customTableConfig={customTableConfig}
            showPagination={showPagination}
            onSelectPage={this.handleSelectPage}
            selectedPage={selectedPage}
            pageCount={pageCount}
            onCreatorSelected={onCreatorSelected}
            selectedSocialAccountId={selectedSocialAccountId}
            selectedSocialAccounts={selectedSocialAccounts}
            onSelectedAccountsChange={onSelectedAccountsChange}
            initialDisplayMode={initialDisplayMode}
            searchedMentions={searchedMentions}
            apiEndpoint={apiEndpoint}
            clientId={clientId}
            showSocialProfileOnCreatorSelect={showSocialProfileOnCreatorSelect}
            showCreateFeatures={showCreateFeatures}
            reloadAccountDetails={reloadAccountDetails}
            shouldShowDemoFeatures={shouldShowDemoFeatures}
          />
        </ErrorBoundary>
      </Provider>
    );
  }

  private handleSelectPage = (page: number) => {
    this.props.onSelectPage(page);
  };
}
