import * as React from 'react';
import { findIndex } from 'lodash';

import { RadioGroup } from '@components';

import { FilterSection } from '../FilterSection';

import { IActivityFilters } from '../models';

const ACTIVITY_OPTIONS = [
  { label: 'Last week', value: 7 },
  { label: 'Last month', value: 30 },
  { label: 'Last year', value: 365 },
];

const V2_ACTIVITY_OPTIONS = [
  { label: 'Last Month', value: 30 },
  { label: 'Last 3 Months', value: 90 },
  { label: 'Last Year', value: 365 },
]

const { useMemo } = React;

interface IProps extends IActivityFilters {
  onChangeLastPostActivity(value?: number);
  hide?: boolean;
  showIQDataFilters?: boolean;
}

const ActivitySection: React.FunctionComponent<IProps> = (props) => {

  const activityOptions = useMemo(() => props.showIQDataFilters ? V2_ACTIVITY_OPTIONS : ACTIVITY_OPTIONS, [props.showIQDataFilters]);

  const selectedIndex = useMemo(() => findIndex(activityOptions, { value: props.lastPostActivity }), [props.lastPostActivity, activityOptions]);

  const handleClear = () => {
    props.onChangeLastPostActivity(-1);
  };

  return (
    <FilterSection
      header="Last Post Activity"
      hide={props.hide}
      isSubsection
      showClearButton={selectedIndex >= 0}
      onClear={handleClear}
    >
      <RadioGroup
        options={activityOptions}
        onChange={props.onChangeLastPostActivity}
        selectedIndex={selectedIndex}
      />
    </FilterSection>
  );
};

export default ActivitySection;
