import * as React from 'react';
import {
 chain, keyBy, nth, toString,
} from 'lodash';
import { Divider } from 'antd';

import {
  ISelectListPickerHandles,
  SelectListPicker,
} from '@frontend/app/components/SelectList/SelectListPicker';
import { useMemberFieldsWithSources } from '@frontend/app/hooks/memberList';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { useFuzzySearchByKeys } from '@frontend/app/hooks';

import { CreateNewField } from './CreateNewField';

const { useCallback, useMemo, useRef } = React;

import styles from './FieldSelect.scss';

interface IProps {
  index: number;
  onFieldSelect: (field: IField) => void;
  selectedFields: IField[];
  filteredFields?: string[];
  className?: string;
}

interface IItem extends IField {
  isAlreadySelected?: boolean;
}

export const FieldSelect: React.FC<IProps> = (props) => {
  const {
    className,
    index,
    onFieldSelect,
    selectedFields,
    filteredFields = [],
  } = props;

  const {
    appsWithFields,
    fields,
  } = useMemberFieldsWithSources();

  const selectListPickerRef = useRef<ISelectListPickerHandles>();

  // Hide other `selectedFields` -- keep current `selectedField`
  const selectedField = nth(selectedFields, index);
  const options: IItem[] = useMemo(
    () => (
      chain(fields)
        .differenceBy(selectedFields, 'field')
        .filter((f) => !filteredFields.includes(f.field))
        .concat(selectedField || [])
        .sortBy((f) => f.headerName)
        .value()
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, selectedField, selectedFields],
  );

  // To control SelectListPicker
  const selectedIds = useMemo(
    () => (selectedField?.field
      ? [selectedField.field]
      : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedFields],
  );

  const groupByAppId = useMemo(
    () => keyBy(appsWithFields, (a) => a.id),
    [appsWithFields],
  );
  const mapOptionToGroupId = useCallback(
    (option: IItem): string | number => (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      groupByAppId[(option?.source as any)?.id]?.id || toString(option.source)
    ),
    [groupByAppId],
  );
  const mapGroupIdToLabel = useCallback(
    (groupId: string | number): string => groupByAppId[groupId]?.name || toString(groupId),
    [groupByAppId],
  );
  const fieldsByField = useMemo(
    () => keyBy(fields, (f) => f?.field),
    [fields],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFieldSelect = (field: IField) => {
    onFieldSelect(field);
    selectListPickerRef.current?.hide();
  };

  const handleSearch = useFuzzySearchByKeys<IItem>(options, ['headerName']);

  const renderFooter = useCallback(
    () => (
      <>
        <Divider className={styles.footerDivider} />
        <CreateNewField
          fields={fields}
          onCreateNewField={handleFieldSelect}
        />
        <a
          className={styles.doNotImportButton}
          onClick={() => {
          handleFieldSelect(null); // `null` shows in the UI as "Will not import"
        }}
        >
          Do not import
        </a>
      </>
),
    [fields, handleFieldSelect],
  );

  return (
    <>
      <SelectListPicker<IItem>
        options={options}
        mapOptionToId={(option: IItem) => option.field}
        mapOptionToLabel={(option: IItem) => option?.headerName}
        mapOptionToGroupId={mapOptionToGroupId}
        mapGroupIdToLabel={mapGroupIdToLabel}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(selectedFields: any[]) => {
        handleFieldSelect(fieldsByField[selectedFields[0]]);
      }}
        onSearchRequest={handleSearch}
        multi={false}
        popoverProps={{
        overlayClassName: styles.overlay,
      }}
        showGroupSelect
        showSearch
        placeholder={selectedField !== null ? 'Select a field' : 'Will not import'}
        footer={renderFooter()}
        selectedIds={selectedIds}
        selectListPickerRef={selectListPickerRef}
        className={className}
      />
    </>
);
};
