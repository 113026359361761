import * as React from 'react';
import cx from 'classnames';

import { Divider, Typography } from '@revfluence/fresh';

import styles from './PayoutBreakdown.scss';

const { Paragraph } = Typography;

interface IPayoutBreakdown {
  amountToPayMembers: number;
  amountToDeduct: number;
  amountToCharge: number;
  cardTransactionFee: number;
  totalAmountToCharge: number;
  totalAmountToPay: number;
  remainingBalance: number;
  alert: boolean;
  cardCharge: boolean;
  requireHandlingFee?: boolean;
  handlingFee?: number;
}

export const PayoutBreakdown: React.FC<Readonly<IPayoutBreakdown>> = (props: IPayoutBreakdown) => {
  const {
    amountToPayMembers,
    amountToDeduct,
    amountToCharge,
    cardTransactionFee,
    totalAmountToCharge,
    totalAmountToPay,
    remainingBalance,
    alert,
    cardCharge,
    handlingFee,
  } = props;
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <div className={styles.PayoutBreakdown}>
      <div className={styles.entry}>
        <Paragraph strong>
          Amount Due
        </Paragraph>
        <Paragraph>{`${formatter.format(amountToPayMembers)}`}</Paragraph>
      </div>
      <Divider />
      <div className={styles.entry}>
        <Paragraph>Amount to deduct from your balance</Paragraph>
        <Paragraph strong>
          {`${formatter.format(amountToDeduct)}`}
        </Paragraph>
      </div>
      <div className={styles.entry}>
        <Paragraph>Amount to charge your card</Paragraph>
        <Paragraph strong className={cx([{ [styles.alert]: alert }, { [styles.disable]: !cardCharge }])}>
          {`${formatter.format(amountToCharge)}`}
        </Paragraph>
      </div>
      <div className={styles.entry}>
        <Paragraph>Stripe card transaction fee (3%)</Paragraph>
        <Paragraph strong className={cx([{ [styles.alert]: alert }, { [styles.disable]: !cardCharge }])}>
          {`${formatter.format(cardTransactionFee)}`}
        </Paragraph>
      </div>
      {props.requireHandlingFee && (
        <div className={styles.entry}>
          <Paragraph>Handling fee (2%)</Paragraph>
          <Paragraph strong className={cx([{ [styles.alert]: alert }, { [styles.disable]: !cardCharge }])}>
            {`${formatter.format(handlingFee)}`}
          </Paragraph>
        </div>
      )}
      <div className={styles.entry}>
        <Paragraph>Total amount to charge your card</Paragraph>
        <Paragraph strong className={cx([{ [styles.alert]: alert }, { [styles.disable]: !cardCharge }])}>
          {`${formatter.format(totalAmountToCharge)}`}
        </Paragraph>
      </div>
      <div className={styles.entry}>
        <Paragraph>Total amount to pay</Paragraph>
        <Paragraph strong>
          {`${formatter.format(totalAmountToPay)}`}
        </Paragraph>
      </div>
      <div className={styles.entry}>
        <Paragraph>Your remaining balance</Paragraph>
        <Paragraph strong>
          {`${formatter.format(remainingBalance)}`}
        </Paragraph>
      </div>
    </div>
  );
};
