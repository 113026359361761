import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

export const FavoriteIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M12,21.71c0,0,10-7.21,10-14.03s-8.87-7.35-10-0.52C10.87,0.33,2,0.85,2,8.2S12,21.71,12,21.71"
      fill={props.fill}
    />
  </SvgIcon>
));

FavoriteIcon.displayName = 'FavoriteIcon';
