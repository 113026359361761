import * as React from 'react';
import { Skeleton } from '@revfluence/fresh';
import styles from './TermsLoading.module.scss';

export const TermsLoading = React.memo(() => (
  <>
    <Skeleton paragraph={{ rows: 2 }} />
    <div className={styles.TermsLoadingLayout}>
      <Skeleton paragraph />
      <Skeleton paragraph />
    </div>
  </>
));
TermsLoading.displayName = 'TermsLoading';
