import * as React from 'react';
import cx from 'classnames';
import { isUndefined } from 'lodash';
import { InfoCircleFilled } from '@ant-design/icons';
import { ReactCropperProps } from 'react-cropper';

import { FileSelector, Progress, CloseIcon } from '@components';

import { TContentType } from '@frontend/app/hooks';

import { PlusIcon } from '@revfluence/fresh-icons/solid/esm';
import { isMIMETypeVideo } from '@frontend/utils';
import { CropModal } from './CropModal';

import styles from './ThumbnailUpload.scss';

const { useCallback, useMemo, useState } = React;

const DEFAULT_MAX_FILE_SIZE = 24 * 1024 * 1024; // 24MB

export type CropperProps = Omit<ReactCropperProps, 'src' | 'onInitialized'>;

interface IProps {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  onFileSelected(file: File): void;
  onThumbnailSelected(imgStr: string): void;
  progressPercentage?: number;
  thumbnail?: string;
  showCropper?: boolean;
  cropperProps?: CropperProps;
  acceptedTypes?: string[];
  acceptedLabel?: string;
  // Literal string of image types
  accept?: string;
  hintText?: string;
  hideMaxSizeNote?: boolean;
  fileSelectorClassName?: string;
  thumbnailClassName?: string;
  display?: 'block' | 'inline' | 'expanded';
  uploadLabel?: string;
  isVideo?: boolean;
  fileBytesLimit?: number;
}

export type ThumbnailUploadProps = IProps;

export const ThumbnailUpload: React.FC<IProps> = React.memo((props) => {
  const {
 display = 'block', uploadLabel = 'Upload', isVideo, fileBytesLimit,
} = props;

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');

  const handleFilesSelected = useCallback(
    (files: FileList) => {
      const file = files[0];
      if (!file) {
        return;
      }

      setFileName(file.name);

      const isVideo = isMIMETypeVideo(file.type);

      if (!props.showCropper || isVideo) {
        props.onFileSelected(file);
      }

      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        props.onThumbnailSelected(e.target.result as string);

        if (props.showCropper && !isVideo) {
          setShowCropModal(true);
        }
      };

      reader.readAsDataURL(file);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onThumbnailSelected, props.onFileSelected, props.showCropper, setShowCropModal, setFileName],
  );

  const handleRemoveThumbnail = useCallback(
    () => {
      props.onFileSelected(null);
      props.onThumbnailSelected('');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onFileSelected, props.onThumbnailSelected],
  );

  const hideCropModal = useCallback(() => {
    setShowCropModal(false);
  }, [setShowCropModal]);

  const cancelCrop = useCallback(() => {
    hideCropModal();
    handleRemoveThumbnail();
  }, [hideCropModal, handleRemoveThumbnail]);

  const saveCrop = useCallback(
    (blob: Blob) => {
      const file = new File([blob], fileName);
      props.onFileSelected(file);

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        props.onThumbnailSelected(e.target.result as string);
      };
      reader.readAsDataURL(file);

      hideCropModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideCropModal, props.onFileSelected, props.onThumbnailSelected],
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      // prevent browser from opening files
      event.stopPropagation();
      event.preventDefault();

      const { dataTransfer } = event;
      const { files } = dataTransfer;

      handleFilesSelected(files);
      setIsDragging(false);
    },
    [handleFilesSelected, setIsDragging],
  );

  const handleDragEnter = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    // prevent children from receiving events
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      // prevent children from receiving events
      event.stopPropagation();
      event.preventDefault();

      setIsDragging(false);
    },
    [setIsDragging],
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      // prevent children from receiving events
      event.stopPropagation();
      event.preventDefault();

      if (!isDragging) {
        setIsDragging(true);
      }
    },
    [setIsDragging, isDragging],
  );

  const acceptedTypes = (props.acceptedTypes || ['image']) as TContentType[];

  const acceptedTypesLabel = useMemo(
    () => {
      if (props.acceptedLabel) {
        return props.acceptedLabel;
      }

      return props.acceptedTypes ? `${props.acceptedTypes.join(', ')} accepted` : '.jpg, .png accepted';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.acceptedTypes, props.accept, props.acceptedLabel],
  );

  return (
    <div
      className={cx(styles.ThumbnailUpload, props.className, {
        [styles.error]: props.hasError,
        [styles.displayInline]: display === 'inline',
        [styles.displayExpanded]: display === 'expanded',
      })}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
    >
      {props.thumbnail && (
        <div className={styles.tmpLogoWrapper}>
          <div className={styles.removeImg} onClick={handleRemoveThumbnail}>
            <CloseIcon size={14} />
          </div>
          {!isVideo ? (
            <img src={props.thumbnail} className={cx(styles.tmpLogo, props.thumbnailClassName)} />
          ) : (
            <video
              src={props.thumbnail}
              className={cx(styles.tmpVideo, props.thumbnailClassName)}
              autoPlay
              muted
              loop
            />
          )}
          {!isUndefined(props.progressPercentage) && <Progress percentage={props.progressPercentage} />}
        </div>
      )}
      {!props.thumbnail && (
        <div className={styles.selectorContainer}>
          <FileSelector
            accept={props.accept}
            acceptTypes={acceptedTypes}
            className={cx(styles.fileSelector, props.fileSelectorClassName)}
            disabled={props.disabled}
            onFilesSelected={handleFilesSelected}
            fileBytesLimit={fileBytesLimit || DEFAULT_MAX_FILE_SIZE}
          >
            {['inline', 'expanded'].includes(display) ? (
              <div className={styles.simpleUploadContent}>
                <PlusIcon className={styles.simpleUploadContentIcon} />
                <span>{uploadLabel}</span>
              </div>
            ) : null}
          </FileSelector>
          {!props.hideMaxSizeNote && (
            <>
              <div className={styles.fileSubtitle}>{acceptedTypesLabel}</div>
              <div className={styles.fileSubtitle}>24MB file size maximum</div>
            </>
          )}
          {props.hintText && (
            <div className={cx(styles.fileSubtitle, styles.hintText)}>
              <InfoCircleFilled size={14} className={styles.hintTextIcon} />
              {props.hintText}
            </div>
          )}
        </div>
      )}
      {props.hasError && <div className={cx(styles.fileSubtitle, styles.error)}>{props.errorMessage}</div>}
      {props.showCropper && (
        <CropModal
          visible={showCropModal}
          src={props.thumbnail}
          cropperProps={props.cropperProps}
          onCancel={cancelCrop}
          onClickSave={saveCrop}
        />
      )}
    </div>
  );
});

ThumbnailUpload.defaultProps = {
  disabled: false,
  hasError: false,
  showCropper: false,
};

ThumbnailUpload.displayName = 'ThumbnailUpload';
