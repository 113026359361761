import * as React from 'react';
import cx from 'classnames';
import numeral from 'numeral';

import { Progress } from '@components';

import {
  FileTypeDoc,
  FileTypeImage,
  FileTypePdf,
  FileTypeVideo,
} from '@components';

import { IUploaderContent } from '../redux/contentUploaderModel';

import styles from './Content.scss';

export interface IContentProps {
  content: IUploaderContent;

  classNames?: string[];
}

type TLoadStatus = 'loading' | 'loaded';
interface IState {
  status: TLoadStatus;
  hover: boolean;
}

/**
 * @class
 * @extends {React.Component}
 */
export class Content extends React.Component<IContentProps, IState> {
  public static defaultProps: Pick<IContentProps, 'classNames'> = {
    classNames: [],
  };

  /**
   * @inheritDoc
   */
  constructor(props: IContentProps) {
    super(props);

    this.state = {
      status: 'loading',
      hover: false,
    };
  }

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(nextProps: IContentProps) {
    const { content } = nextProps;

    if (content.progress && content.progress.percentage < 100) {
      return {
        status: 'loading',
      };
    }

    return {
      status: 'loaded',
    };
  }

  /**
   * @inheritdoc
   */
  public render() {
    const { status, hover } = this.state;
    const { content, classNames, children } = this.props;
    const isLoading = status === 'loading';

    return (
      <div
        className={cx(classNames.concat(styles.Content))}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div
          className={cx(styles.content, {
            [styles.loading]: isLoading,
            [styles.hover]: hover || isLoading,
          })}
        >
          {children}
        </div>
        {isLoading && (
          <div className={cx(styles.progressWrapper)}>
            <Progress
              percentage={content.progress.percentage}
              label={`${numeral(content.progress.uploaded).format('0 b')}/${numeral(
                content.size,
              ).format('0 b')} uploaded...`}
              labelClassName={styles.progressLabel}
              type="info"
            />
          </div>
        )}
        {status === 'loaded' && hover && (
          <div className={styles.info}>
            <FileType content={content} />
            <div className={styles.detail}>
              {content.name && (
                <div className={styles.name}>
                  {content.name.length < 34
                    ? content.name
                    : `${content.name.substr(0, 26)}...${content.name.substr(-5)}`}
                </div>
              )}
              {content.size && (
                <div className={styles.size}>{numeral(content.size).format('0.0 b')}</div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * @private
   * Handler for 'mouseenter' event.
   */
  private handleMouseEnter = () => {
    this.setState({
      hover: true,
    });
  };

  /**
   * @private
   * Handler for 'mouseleave' event.
   */
  private handleMouseLeave = () => {
    this.setState({
      hover: false,
    });
  };
}

/**
 * @class
 * @extends {React.FunctionComponent}
 */
const FileType: React.FunctionComponent<{
  content: IUploaderContent;
}> = ({ content }) => {
  let Icon;
  const ext = (content.name || '')
    .split('.')
    .pop()
    .toLowerCase();
  if (content.type === 'image') {
    Icon = FileTypeImage;
  } else if (content.type === 'video') {
    Icon = FileTypeVideo;
  } else {
    // application
    if (ext === 'pdf') {
      Icon = FileTypePdf;
    } else {
      // default as doc
      Icon = FileTypeDoc;
    }
  }
  return (
    <div
      className={cx(styles.fileType, {
        [styles.image]: content.type === 'image',
        [styles.video]: content.type === 'video',
        [styles.pdf]: ext === 'pdf',
      })}
    >
      <Icon size={20} />
    </div>
  );
};
