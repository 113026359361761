import * as React from 'react';
import moment from 'moment';

import { Form, DatePicker } from '@revfluence/fresh';
import { OfferFormTestIds as testIds } from '../../../OfferFormTestIds';
import { IAffiliateLinksFormElementProps } from '../../../types';

import styles from '../../../OfferForm.scss';

interface IProps extends IAffiliateLinksFormElementProps<'expirationDate'> { }

export const OfferExpirationDate: React.FC<Readonly<IProps>> = React.memo(({
  disabled,
  name,
}) => {
  const disabledDate = (current: moment.Moment) =>
    // Can not select days before today and today
     current && current < moment().endOf('day');
const rules = [
    { required: true, message: 'Please input your expiration date!' },
  ];
  return (
    <Form.Item label="Expiration date" name={name} rules={rules}>
      <DatePicker
        className={styles.datePicker}
        data-testid={testIds.expirationDate}
        disabled={disabled}
        disabledDate={disabledDate}
        size="large"
        showToday={false}
      />
    </Form.Item>
  );
});
