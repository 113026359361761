export const valueInRange = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

const roundToNearestStep = (value: number, step: number): number => Math.round(value / step) * step;

export const positionInRangeRoundedToNearestStep = (
  position: number,
  step: number,
  min: number,
  max: number,
): number => {
  const roundedPosition = min + roundToNearestStep(position - min, step);
  const roundedMax = min + roundToNearestStep(max - min, step);
  return valueInRange(roundedPosition, min, roundedMax);
};
