import gql from 'graphql-tag';

import { MEMBER_FRAGMENT } from './fragments';

export const SAVE_MEMBER = gql`
  mutation SaveMemberMutation($id: Int, $member: MemberInput!) {
    member: saveMember(id: $id, member: $member) {
      ...MemberFragment
    }
  }
  ${MEMBER_FRAGMENT}
`;
