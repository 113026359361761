import { isNull, isUndefined } from 'lodash';

import { roundToNearestCent } from './roundDigits';

export enum DataFormat {
  None,
  Integer,
  Money,
}

export const formatValue = (
  format: DataFormat,
  value: string | number | undefined | null,
  includeCents = true,
  invalidString: string = '---',
): string => {
  if (isNull(value) || isUndefined(value)) {
    return invalidString;
  }
  switch (format) {
    case DataFormat.None:
      return value.toString();
    case DataFormat.Integer:
      const asNum = parseInt(value.toString(), 10);
      if (isNaN(asNum)) {
        return invalidString;
      }
      return asNum.toLocaleString();
    case DataFormat.Money: {
      const asNum = parseFloat(value.toString());
      if (isNaN(asNum)) {
        return invalidString;
      }
      const split = roundToNearestCent(asNum).toFixed(2).split('.');
      const wholeDollars = parseInt(split[0], 10);
      if (includeCents) {
        return `${wholeDollars.toLocaleString()}.${split[1]}`;
      }
      return wholeDollars.toLocaleString();
    }
  }
};

export const formatMoney = (value: number, includeCents = true) => formatValue(DataFormat.Money, value, includeCents);
export const formatInteger = (value: number) => formatValue(DataFormat.Integer, value);
