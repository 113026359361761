import React, { ReactNode } from 'react';
import { CloseIcon, IconButton } from '@components';
import styles from '@frontend/app/containers/Projects/AutomationConfig/ConfigDrawer/ConfigDrawer.scss';

type TProps = {
  title: string;
  description: string;
  onClose: () => void;
  icon: ReactNode;
};
const FormHeader = (props: TProps) => {
  const {
    title,
    description,
    onClose,
    icon,
  } = props;

  return (
    <div className={styles.configHeader}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {icon}
          <div className={styles.titleFont}>
            {title}
          </div>
        </div>
        <IconButton
          icon={<CloseIcon size={12} />}
          onClick={onClose}
        />
      </div>
      <div className={styles.description}>
        {description}
      </div>
    </div>
  );
};

export default FormHeader;
