import { Button } from '@frontend/shadcn/components/ui/button';
import { cn } from '@frontend/shadcn/lib/utils';
import { cva } from 'class-variance-authority';
import React from 'react';

const layoutVariants = cva(
  'w-full h-full text-center flex justify-center items-center gap-6',
  {
    variants: {
      layout: {
        vertical: 'flex-col',
        horizontal: 'flex-col', // same style as vertical should be applied if size is not 'lg'
      },
      size: {
        sm: '',
        md: '',
        lg: '',
      },
    },
    compoundVariants: [
      {
        layout: 'horizontal',
        size: 'lg',
        className: 'flex-row-reverse',
      },
    ],
    defaultVariants: {
      layout: 'vertical',
      size: 'md',
    },
  },
);

const iconVariants = cva(
  'text-border',
  {
    variants: {
      size: {
        sm: 'hidden',
        md: 'text-[50px]',
        lg: 'text-[80px]',
      },
      layout: {
        vertical: '',
        horizontal: '',
      },
      type: {
        empty: '',
        error: 'text-alert-error',
      },
    },
    compoundVariants: [
      {
        layout: 'horizontal',
        size: 'lg',
        className: 'text-[120px]',
      },
    ],
    defaultVariants: {
      size: 'md',
    },
  },
);

const titleVariants = cva(
  'font-medium',
  {
    variants: {
      size: {
        sm: 'hidden',
        md: 'text-sm leading-5',
        lg: 'text-base leading-7',
      },
      type: {
        empty: '',
        error: 'text-alert-foreground',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

const descriptionVariants = cva(
  'text-subtitle',
  {
    variants: {
      size: {
        sm: 'text-sm leading-6',
        md: 'text-xs leading-5',
        lg: 'text-sm leading-6',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

const buttonVariants = cva(
  '',
  {
    variants: {
      size: {
        sm: 'hidden',
        md: '',
        lg: '',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

interface CtaProps {
  text?: string;
  icon?: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement> & { title?: string, titleId?: string }>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

interface EmptyProps {
  icon?: React.ForwardRefExoticComponent<React.SVGProps<SVGSVGElement> & { title?: string, titleId?: string }>;
  title?: string;
  description?: string | React.ReactNode;
  primaryCtaProps?: CtaProps
  secondaryCtaProps?: CtaProps
  size?: 'sm' | 'md' | 'lg';
  layout?: 'horizontal' | 'vertical';
  type?: 'empty' | 'error';
}
const Empty = ({
  icon: Icon,
  title,
  description,
  primaryCtaProps,
  secondaryCtaProps,
  layout,
  size,
  type,
}: EmptyProps) => {
  const PrimaryCtaIcon = primaryCtaProps?.icon;
  const SecondaryCtaIcon = secondaryCtaProps?.icon;

  return (
    <div className={cn(layoutVariants({ layout, size }))}>
      {Icon && (
        <Icon className={cn(iconVariants({ layout, size, type }))} />
      )}
      <div className="flex flex-col justify-center items-center gap-6">
        <div className="flex flex-col gap-1">
          <span className={cn(titleVariants({ size, type }))}>
            {title}
          </span>
          {description && (
          <span className={descriptionVariants({ size })}>
            {description}
          </span>
        )}
        </div>
        <div className="flex gap-3">
          {secondaryCtaProps && (
          <Button
            onClick={secondaryCtaProps.onClick}
            className={cn(buttonVariants({ size }), secondaryCtaProps.className)}
            variant="secondary"
          >
            {SecondaryCtaIcon && <SecondaryCtaIcon fontSize={16} />}
            {secondaryCtaProps.text}
          </Button>
        )}
          {primaryCtaProps && (
          <Button
            onClick={primaryCtaProps.onClick}
            className={cn(buttonVariants({ size }), primaryCtaProps.className)}
            variant={type === 'error' ? 'destructive' : undefined}
          >
            {PrimaryCtaIcon && <PrimaryCtaIcon fontSize={16} />}
            {primaryCtaProps.text}
          </Button>
        )}
        </div>
      </div>
    </div>
  );
};

export default Empty;
