import gql from 'graphql-tag';
import { TIKTOK_SETTINGS_ACCOUNT_FRAGMENT } from './fragments/TikTokSettings';

export const RETRIEVE_TIKTOK_SETTINGS_ACCOUNT = gql`
  query RetrieveTikTokSettingsAccountQuery($accountId: String!) {
    tiktokSettingsRetrieveAccount(accountId: $accountId) {
      ...TikTokSettingsAccountFragment
    }
  }
  ${TIKTOK_SETTINGS_ACCOUNT_FRAGMENT}
`;
