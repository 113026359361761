import * as React from 'react';
import {
  Statistic as FreshStatistic,
  Tooltip,
} from '@revfluence/fresh';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import cx from 'classnames';

import { formatters } from './formatters';
import { Loading } from './Loading';
import {
  IBasicStat,
  IComplexStat,
} from './types';

import styles from './StatisticsCard.scss';
import wrapperStyles from './Statistic.scss';

const ERROR_TOOLTIP_TITLE = 'Sorry about this, but there was an issue loading this data. You can try refreshing this page. If the problem continues, you can try again later or feel free to contact support using the Intercom icon at the bottom right of this page.';

interface IProps {
  loading: boolean;
  position: 'largeStat' | 'smallStat';
  showErrorOverlay: boolean;
  stat: IBasicStat | IComplexStat;
}

export const Statistic: React.FC<IProps> = React.memo((props) => {
  const {
    loading,
    position,
    showErrorOverlay,
    stat: {
      format,
      hasError,
      label,
      value,
    },
  } = props;

  let prefix: React.ReactNode;
  let statValue: React.ReactNode = value;

  if (showErrorOverlay) {
    statValue = '-';
  } else if (hasError) {
    statValue = ' ';
    const errorClassName = cx({
      [styles.errorWrapper]: true,
      [styles.large]: position === 'largeStat',
    });
    prefix = (
      <Tooltip placement="top" title={ERROR_TOOLTIP_TITLE}>
        <div className={errorClassName}>
          <TriangleExclamationIcon />
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={wrapperStyles.Statistic}>
      <FreshStatistic
        formatter={formatters[format]}
        prefix={prefix}
        reverse
        size={position === 'largeStat' ? 'large' : 'small'}
        title={label}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        value={statValue}
      />
      <Loading
        position={position}
        visible={loading}
      />
    </div>
  );
});
Statistic.displayName = 'Statistic';
