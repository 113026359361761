import * as React from 'react';

import { Button, Tooltip } from '@revfluence/fresh';
import { EnvelopeIcon } from '@revfluence/fresh-icons/regular/esm';

interface IProps {
  className?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>);
}

export const ComposeButton: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    className,
    disabled,
    onClick,
  } = props;
  return (
    <Tooltip placement="top" title="Email Members">
      <Button
        className={className}
        disabled={disabled}
        icon={<EnvelopeIcon />}
        onClick={onClick}
        type="text"
      >
        Email Members
      </Button>
    </Tooltip>
  );
});

ComposeButton.defaultProps = {
  disabled: false,
};

ComposeButton.displayName = 'ComposeButton';
