import { QueryHookOptions, useQuery } from '@apollo/client';

import { ADVERTISER_SHOPIFY_SCOPE_CHECK_QUERY } from '@affiliates/queries';
import { AdvertiserShopifyScopeCheck } from '@affiliates/queries/types/AdvertiserShopifyScopeCheck';

type TOptions = QueryHookOptions<AdvertiserShopifyScopeCheck>;

export const useAdvertiserShopifyScopeCheck = (options: TOptions) => (
  useQuery<AdvertiserShopifyScopeCheck>(ADVERTISER_SHOPIFY_SCOPE_CHECK_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  })
);
