import 'css-chunk:src/applications/Shared/components/ArtifactAssignmentForm.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1lmw9_157",
  "justify-content-space-between": "_justify-content-space-between_1lmw9_161",
  "tabular-nums": "_tabular-nums_1lmw9_165",
  "ArtifactAssignmentForm": "_ArtifactAssignmentForm_1lmw9_178",
  "embedded": "_embedded_1lmw9_182",
  "artifact_assign_header": "_artifact_assign_header_1lmw9_185",
  "standard_label": "_standard_label_1lmw9_209",
  "form_wrap": "_form_wrap_1lmw9_220",
  "Select": "_Select_1lmw9_227",
  "standard_fieldset": "_standard_fieldset_1lmw9_230",
  "actions_wrap": "_actions_wrap_1lmw9_233",
  "submit_button": "_submit_button_1lmw9_237",
  "show": "_show_1lmw9_1"
};