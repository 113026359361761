import React, { CSSProperties } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  PaginationState,
  PaginationOptions,
  ColumnPinningState,
  Column,
} from '@tanstack/react-table';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@frontend/shadcn/components/ui/table';
import { ChevronDownIcon, ChevronUpIcon } from '@revfluence/fresh-icons/regular/esm';
import { cn } from '@frontend/shadcn/lib/utils';

const getColumnPinningStyles = <T extends {} = {}>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px hsl(var(--border)) inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px hsl(var(--border)) inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    zIndex: isPinned ? 3 : 2,
    width: column.getSize(),
  };
};

const getCellPinningStyles = <T extends {} = {}>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px hsl(var(--border)) inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px hsl(var(--border)) inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isPinned ? 'hsl(var(--primary-foreground))' : undefined,
  };
};

export interface HeaderGroupBorder {
  borderColor?: string
}

export interface ColumnMetaType {
  headerGroupBorder?: HeaderGroupBorder
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  sortable?: boolean
  paginated?: boolean
  paginationState?: PaginationState
  paginationOptions?: PaginationOptions
  wrapperClassName?: string
  columnPinning?: ColumnPinningState
}

export default function DataTable<TData, TValue>({
  columns,
  data,
  paginated,
  paginationState,
  paginationOptions,
  wrapperClassName,
  sortable = true,
  columnPinning = {},
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: sortable && getSortedRowModel(),
    getPaginationRowModel: paginated && getPaginationRowModel(),
    state: {
      columnPinning,
    },
    ...paginationState,
    ...paginationOptions,
  });

  return (
    <div className={wrapperClassName}>
      <Table style={{ minWidth: table.getTotalSize() }}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnMeta = header.column.columnDef.meta as ColumnMetaType;

                return (
                  <TableHead
                    key={header.id}
                    style={{
                    width:
                      header.getSize() !== 150 ? header.getSize() : undefined,
                    ...getColumnPinningStyles(header.column),
                  }}
                    className={
                    cn(
                      sortable && header.column.getCanSort()
                        ? 'cursor-pointer select-none pr-6'
                        : '',
                      columnMeta?.headerGroupBorder?.borderColor
                        ? cn(
                          'border-b-2',
                          columnMeta?.headerGroupBorder?.borderColor,
                        ) : '',
                    )
                  }
                    onClick={sortable && header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <div className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4">
                      {{
                      asc: <ChevronUpIcon />,
                      desc: <ChevronDownIcon />,
                    }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    style={{ ...getCellPinningStyles(cell.column) }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
