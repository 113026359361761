import * as React from 'react';
import cx from 'classnames';
import { toString } from 'lodash';

import { Tooltip } from '@components';

import { Cell, ICellProps } from './Cell';

const { useRef } = React;
import styles from './TextCell.scss';

interface IProps extends ICellProps {
  value: string;
  nullStr?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const TextCell: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
 className, value, nullStr, ...restProps
} = props;
  const cellRef = useRef<HTMLDivElement>(null);

  const str = toString(value) || nullStr;
  const shouldShowTooltip = str.length > 50;

  const content = (
    <div className={styles.content}>{str.length > 50 ? `${str.substr(0, 47)}...` : str}</div>
  );

  return (
    <>
      <Cell
        ref={cellRef}
        value={content}
        className={cx(styles.TextCell, {
          [styles.hasTooltip]: shouldShowTooltip,
        }, className)}
        {...restProps}
      />
      {shouldShowTooltip && (
        <Tooltip
          mountRef={cellRef}
          maxWidth={600}
          className={styles.Tooltip}
          contentClassName={styles.content}
        >
          {str}
        </Tooltip>
      )}
    </>
  );
});

TextCell.defaultProps = {
  nullStr: '-',
};
TextCell.displayName = 'TextCell';
