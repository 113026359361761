import {
 get, includes, indexOf, isEmpty, isNil, size,
} from 'lodash';

import {
  CONTRACT_APP_ID,
  SHOPIFY_APP_ID,
} from '@frontend/app/constants/applicationIds';
import {
  IInstalledApplications,
} from '@frontend/app/hooks';
import { GetCampaignByProjectIdQuery_campaign } from '@frontend/app/queries/types/GetCampaignByProjectIdQuery';
import { ICounts } from './hooks';
import { TProject, TWorklet } from './types';

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const PROJECT_NAME_MAX_LENGTH = 200;

export const PROJECT_NAME_ERRORS = {
  INVALID_LENGTH: `Please enter a maximum of ${PROJECT_NAME_MAX_LENGTH} characters.`,
  EMPTY_INPUT: 'Enter your project name or any value for the Page URL',
  EMPTY_INPUT_PROJECT: 'Enter a project name',
  INVALID_CHARACTERS: 'Can’t contain special characters',
  DUPLICATE_NAME: 'Project with same name exists. Please enter a different name.',
};

export interface IValidateProjectNameOptions {
  projectName: string;
  emptyMessage?: string;
  skip?: boolean;
  duplicate?: boolean;
}
export interface IValidateProjectName {
  isValid: boolean;
  size: number;
  error?: string;
}

export const validateProjectName = (options: IValidateProjectNameOptions): IValidateProjectName => {
  const {
 projectName, emptyMessage = PROJECT_NAME_ERRORS.EMPTY_INPUT, skip, duplicate,
} = options;

  const sizeOfName = size(projectName);

  if (skip) {
    return {
      size: sizeOfName,
      isValid: true,
    };
  }

  if (sizeOfName === 0) {
    return {
      size: sizeOfName,
      error: emptyMessage,
      isValid: false,
    };
  }

  if (duplicate) {
    return {
      size: sizeOfName,
      error: PROJECT_NAME_ERRORS.DUPLICATE_NAME,
      isValid: false,
    };
  }

  if (sizeOfName > PROJECT_NAME_MAX_LENGTH) {
    return {
      size: sizeOfName,
      error: PROJECT_NAME_ERRORS.INVALID_LENGTH,
      isValid: false,
    };
  }

  const hasInvalidCharacters = /[^\x00-\x7F]/.test(projectName);
  if (hasInvalidCharacters) {
    return {
      size: sizeOfName,
      error: PROJECT_NAME_ERRORS.INVALID_CHARACTERS,
      isValid: false,
    };
  }

  return {
    size: sizeOfName,
    isValid: true,
  };
};

export enum WorkletSpecKey {
  STASalesLinks = 'UID_e23d1299-18b1-4a9b-a047-4e98c8b475b0',
  STAPromoCodes = 'UID_64d5f188-3bd4-45c6-a0e1-20d5a73e223e',
  STASalesLinksAndPromoCodes = 'UID_89815092-abc1-4512-b490-c4b95e4590ee',
  PFASendProduct = 'UID_d247e1d4-4841-4707-868b-1bc4baad0985',
  SendTerms = 'UID_0f195f0c-8623-409c-8e47-ddfcb4adf4fe',
  ReviewContent = 'UID_a6f4a27c-fbc7-46e4-a47c-1a625bd4dfd1',
  PFACreatorProductSelection = 'UID_fc459806-3e8a-4f6a-bbb9-110e713a072f',
  PFAOfflineCreatorProductSelection = 'UID_ade1123b-f471-4797-ba24-f5e352a6c46a',
  PFACreatorProductSelectionV2 = 'UID_fc459806-3e8a-4f6a-bbb9-110e713a123w',
  SendContractWorkletSpecification = 'UID_26525297-f480-46ec-bd3d-f36ee74ef8b3',
}

/**
 * Validates the worklet spec keys for a project
 * @param {string[]} specKeys List of worklets in order for the project
 * @param {IInstalledApplications} installedApps Object indicating whether app is installed for the client
 * @param {boolean} isNewProject True if this is a new project (not editing stages)
 * * Used when we add a new rule but we don't want to block editing projects that already exist and break the rule
 * @return {[boolean, string | undefined]} [0]: whether there is an error, [1]: error message
 */
export const validateProjectSpec = (
  specKeys: string[],
  installedApps: IInstalledApplications,
  isNewProject: boolean,
): [boolean, string | undefined] => {
  if (
    isNewProject && !installedApps[SHOPIFY_APP_ID]
    && (includes(specKeys, WorkletSpecKey.STAPromoCodes) || includes(specKeys, WorkletSpecKey.STASalesLinksAndPromoCodes))
  ) {
    return [
      true,
      `Promo codes are only available for Shopify stores currently.
      Please add a custom stage in order to create and send promo codes.`,
    ];
  }

  if (isNewProject && !installedApps[SHOPIFY_APP_ID] && includes(specKeys, WorkletSpecKey.PFASendProduct)) {
    return [
      true,
      `The Send Product stage is only available for Shopify stores currently.
      Please add a custom stage in order to send product.`,
    ];
  }

  if (!installedApps[SHOPIFY_APP_ID] && includes(specKeys, WorkletSpecKey.PFACreatorProductSelection)) {
    return [
      true,
      `The Creator Product Selection stage is only available for Shopify stores currently.
      Please add a custom stage in order to send product.`,
    ];
  }

  if (
    !installedApps[SHOPIFY_APP_ID] && includes(specKeys, WorkletSpecKey.PFACreatorProductSelectionV2)) {
    return [
      true,
      `The Product Catalog stage is only available for Shopify stores currently.
      Please add a custom stage in order to send product.`,
    ];
  }

  if (
    isNewProject
    && includes(specKeys, WorkletSpecKey.SendTerms) && !(includes(specKeys, WorkletSpecKey.ReviewContent))
  ) {
    return [
      true,
      `To ensure you are able to track and review content, please add a Review Content stage below the
      Send Brief stage.`,
    ];
  }

  if (
    isNewProject
    && includes(specKeys, WorkletSpecKey.ReviewContent) && !(includes(specKeys, WorkletSpecKey.SendTerms))
  ) {
    return [
      true,
      'In order to review content, please add a Send Brief stage above the Review Content stage.',
    ];
  }

  if (
    isNewProject
    && includes(specKeys, WorkletSpecKey.SendTerms) && includes(specKeys, WorkletSpecKey.ReviewContent)
    && indexOf(specKeys, WorkletSpecKey.SendTerms) > indexOf(specKeys, WorkletSpecKey.ReviewContent)
  ) {
    return [
      true,
      'To ensure a seamless workflow, please make sure that the Review Content stage is below the Send Brief stage.',
    ];
  }

  if (includes(specKeys, WorkletSpecKey.STASalesLinks) && includes(specKeys, WorkletSpecKey.STASalesLinksAndPromoCodes)) {
    return [true, 'You can only have one stage in a project with sales links.'];
  }

  if (includes(specKeys, WorkletSpecKey.STAPromoCodes) && includes(specKeys, WorkletSpecKey.STASalesLinksAndPromoCodes)) {
    return [true, 'You can only have one stage in a project with promo codes.'];
  }

  return [false, undefined];
};

/**
 * Map of app id to worklets which requires the app to be installed.
 */
const appsRelatedWorklets = {
  [SHOPIFY_APP_ID]: [
    WorkletSpecKey.PFASendProduct,
    WorkletSpecKey.STAPromoCodes,
    WorkletSpecKey.STASalesLinksAndPromoCodes,
  ],
  [CONTRACT_APP_ID]: [
    WorkletSpecKey.SendContractWorkletSpecification,
  ],
};

export const filterWorkletsForUninstalledApps = (worklets: TWorklet[], installedApps: IInstalledApplications) =>
  worklets.filter((worklet) => {
    for (const appId in appsRelatedWorklets) {
      if (!installedApps[appId] && appsRelatedWorklets[appId].includes(worklet.specKey as WorkletSpecKey)) {
        return false;
      }
    }
    return true;
  });

export const onOpenTemplate = (url: string): void => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  link.click();
};

export const isCampaignNeverPublished = (campaign: GetCampaignByProjectIdQuery_campaign): boolean => (
  !isEmpty(campaign) && !campaign.enabled_ts && !campaign.disabled_ts
);

export const isApplicationPageEditable = (project: Partial<TProject>) => (
  /**
   * All projects coming from onboarding are editable
   * */
  project?.published || project?.onboardingTemplateConfig?.skipSelectPageTemplate
);

export const formatApplicationPageURL = (
  ProjectsRouteRoot: string,
  project: TProject,
  refreshUi?: boolean,
): string => {
  const rootUrl = `${ProjectsRouteRoot}/${project?.id}`;

  if (refreshUi) {
    return `${rootUrl}/settings/application_page`;
  }

  if (!isApplicationPageEditable(project)) {
    return `${rootUrl}/settings/select_template`;
  }

  return `${rootUrl}/settings/landing_page`;
};

export const formatProjectURL = (
  ProjectsRouteRoot: string,
  project: TProject,
  campaign: GetCampaignByProjectIdQuery_campaign,
): string => {
  const rootUrl = `${ProjectsRouteRoot}/${project?.id}`;

  if (!isApplicationPageEditable(project)) {
    return `${rootUrl}/settings/select_template`;
  }

  if (isApplicationPageEditable(project) && isNil(campaign?.enabled_ts) && isNil(campaign?.disabled_ts)) {
    return `${rootUrl}/marketplace/campaign-details`;
  }

  if (campaign && campaign?.enabled_ts) {
    return `${rootUrl}/settings/landing_page`;
  }
};

export const getMembersCount = (category: string, counts: ICounts, isNewWorkletMenuEnabled: boolean) => {
  const value = get(counts, category, 0).toLocaleString();
  if (isNewWorkletMenuEnabled) {
    if (value !== '0') return value;
    return '';
  }
  return value;
};
