import { TState } from '../types';

interface IAction {
  showCloseConfirmation: boolean;
  type: 'UPDATE SHOW CLOSE CONFIRMATION';
}
export type TUpdateShowCloseConfirmationAction = Readonly<IAction>;

export const updateShowCloseConfirmation = (state: TState, action: TUpdateShowCloseConfirmationAction): TState => {
  if (state.showCloseConfirmation === action.showCloseConfirmation) {
    return state;
  }
  return {
    ...state,
    showCloseConfirmation: action.showCloseConfirmation,
  };
};
