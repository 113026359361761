import * as React from 'react';
import { Select, Space, Typography } from '@revfluence/fresh';

const { Text, Title, Link } = Typography;
interface IProps {
  tags: string[];
  onTagChange: (tags: string[]) => void;
}
export const Tags = (props: IProps) => {
  const { tags, onTagChange } = props;

  return (
    <Space direction="vertical" size={0} style={{ width: '100%' }}>
      <Title level={5}>Tags</Title>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Text>
          Enter tags separated by commas to limit the products creators can choose from. Refer to your Shopify store for
          tag names. Allow creators to choose from all products if you leave this field blank.
        </Text>
        <Text strong>
          Product Options
          <Text type="secondary"> (optional)</Text>
        </Text>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Tags Mode"
          onChange={onTagChange}
          defaultValue={tags}
        />
        <Text>
          Information about Shopify Tags can be found in the
          {' '}
          <Link href="https://help.shopify.com/en/manual/shopify-admin/productivity-tools/using-tags" target="_blank">
            Shopify Help Center.
          </Link>
        </Text>
      </Space>
    </Space>
  );
};

export default Tags;
