import {
  GetAllSpecificationTemplatesQuery_templates,
} from '@frontend/app/queries/types/GetAllSpecificationTemplatesQuery';
import { GetAllTasksQuery_tasks } from '@frontend/app/queries/types/GetAllTasksQuery';
import { GetAllWorkletsQuery_worklets } from '@frontend/app/queries/types/GetAllWorkletsQuery';
import { GetWorkItemsQuery_workItems } from '@frontend/app/queries/types/GetWorkItemsQuery';
import { MemberSearchQuery_members } from '@frontend/app/queries/types/MemberSearchQuery';
import { ProjectByIdQuery_project } from '@frontend/app/queries/types/ProjectByIdQuery';
import { GetAllPresetConditionsQuery_presetConditions } from '@frontend/app/queries/types/GetAllPresetConditionsQuery';
import { ITaskCounts } from './hooks';

export type TMember = MemberSearchQuery_members;
export type TProject = ProjectByIdQuery_project;
export type TSpecificationTemplate = GetAllSpecificationTemplatesQuery_templates;
export type TTask = GetAllTasksQuery_tasks;
export type TCondition = GetAllPresetConditionsQuery_presetConditions;
export type TWorkItem = GetWorkItemsQuery_workItems;
export type TWorklet = GetAllWorkletsQuery_worklets;
export type SortableWorklet = TWorklet & {
  canEdit: boolean;
};
export type TWidgetCount = number | { [taskId: string]: number | ITaskCounts; };

export const talentAgentLabel = 'Talent Manager';
