import * as React from 'react';
import { isEmpty, size } from 'lodash';

import { getErrorMessageFromGraphQL } from '@frontend/utils';
import { ModalSize } from '@components';
import { Menu } from '@revfluence/fresh';
import { Modal } from '@components';
import {
  SelectList,
  FormActions,
  UserAvatar,
} from '@frontend/app/components';

import { useMessagingContext } from '@frontend/hooks';

import {
  useGetCurrentClient,
  useFuzzySearchByKeys,
  useAssignOwnersToMembersMutation,
} from '@frontend/app/hooks';

import { BulkMenuAction } from './ListHeader';

import styles from './AssignOwnersMenuItem.scss';

const { useState, useCallback, useMemo } = React;

interface IProps {
  memberIds?: number[];
  disabled?: boolean;
}

export const AssignOwnersMenuItem: React.FunctionComponent<IProps> = React.memo((props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [clearSelection, setClearSelection] = useState<boolean>(false);
  const [selectedOwnerIds, setSelectedOwnerIds] = useState<string[]>([]);
  const { client, loading: loadingClient } = useGetCurrentClient();
  const [saveOwners, {
    loading: savingOwners,
  }] = useAssignOwnersToMembersMutation();

  const {
    showErrorMessage,
    showSuccessMessage,
  } = useMessagingContext();

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setClearSelection(true);
    setSelectedOwnerIds([]);
    setOpen(false);
  }, []);

  const owners = useMemo(() => {
    if (client) return client.users;
    return [];
  }, [client]);

  const resetClearSelection = useCallback(() => {
    setClearSelection(false);
  }, [setClearSelection]);

  const handleSearch = useFuzzySearchByKeys(owners, ['name']);

  const handleSave = useCallback(async () => {
    try {
      await saveOwners({
        variables: {
          memberIds: props.memberIds,
          userIds: selectedOwnerIds,
        },
      });
      setSelectedOwnerIds([]);
      const ownerLabel = `owner${size(selectedOwnerIds) > 1 ? 's' : ''}`;
      const memberLabel = `member${size(props.memberIds) > 1 ? 's' : ''}`;
      showSuccessMessage(`Assigned ${size(selectedOwnerIds)} ${ownerLabel} to ${size(props.memberIds)} ${memberLabel}`);
      closeModal();
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error));
    }
  }, [
    selectedOwnerIds,
    props.memberIds,
    saveOwners,
    showSuccessMessage,
    showErrorMessage,
    closeModal,
  ]);

  return (
    <>
      <Menu.Item
        key={BulkMenuAction.AssignOwner}
        disabled={props.disabled}
        onClick={openModal}
        className={styles.AssignOwnersMenuItem}
      >
        Assign Relationship Owner
      </Menu.Item>
      <Modal
        width={ModalSize.Small}
        onRequestClose={closeModal}
        show={open}
      >
        <FormActions
          title="Assign Relationship Owner"
          subtitle="All relationship owners will overwrite existing ones."
          primaryAction={{
            disabled: isEmpty(selectedOwnerIds),
            label: 'Save',
            onClick: handleSave,
          }}
          tertiaryAction={{
            label: 'Cancel',
            onClick: closeModal,
          }}
          disabled={savingOwners}
        >
          <SelectList
            searchPlaceholder="Search..."
            showSearch
            selectedIds={selectedOwnerIds}
            onSearchRequest={handleSearch}
            options={owners}
            mapOptionToId={(owner) => owner.id}
            clearSelection={clearSelection}
            onClearSelection={resetClearSelection}
            mapOptionToLabel={(owner) => (
              <>
                <UserAvatar
                  className={styles.ownerAvatar}
                  name={owner.name}
                  size="small"
                />
                {owner.name}
              </>
            )}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={setSelectedOwnerIds as any}
            isLoading={loadingClient}
          />
        </FormActions>
      </Modal>
    </>
  );
});

AssignOwnersMenuItem.displayName = 'AssignOwnersMenuItem';
