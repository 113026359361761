import { CatalogSelectionRuleConditionOperationType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface Condition {
  id: string;
  type: 'tags' | 'vendors' | 'productTypes';
}

export interface FindAndImportState {
  tags: string[];
  vendors: string[];
  productTypes: string[];
  conditions: Condition[];
  operation: CatalogSelectionRuleConditionOperationType;
  pageNumber: number;
  visited: Record<number, boolean>;
  isSelectionChanged: boolean;
  screen: 'find' | 'import';
  collectionName: string;
  isFindClicked: boolean;
}

export interface FindAndImportContextProps extends FindAndImportState {
  setTags: (tags: string[]) => void;
  setVendors: (vendors: string[]) => void;
  setProductTypes: (productTypes: string[]) => void;
  setConditions: (conditions: Condition[]) => void;
  setConditionById: (id: string, type: Condition['type']) => void;
  setOperation: (operation: CatalogSelectionRuleConditionOperationType) => void;
  setPageNumber: (pageNumber: number) => void;
  setVisited: (pageNumber: number) => void;
  resetPagination: () => void;
  setIsSelectionChanged: (isSelectionChanged: boolean) => void;
  setScreen: (screen: 'find' | 'import') => void;
  resetState: () => void;
  setCollectionName: (collectionName: string) => void;
  setIsFindClicked?: (isFindClicked: boolean) => void;
}

export enum Actions {
  SetTags = 'SET_TAGS',
  SetVendors = 'SET_VENDORS',
  SetProductTypes = 'SET_PRODUCT_TYPES',
  SetConditions = 'SET_CONDITIONS',
  SetConditionById = 'SET_CONDITION_BY_ID',
  SetOperation = 'SET_OPERATION',
  SetPageNumber = 'SET_PAGE_NUMBER',
  SetVisited = 'SET_VISITED',
  ResetPagination = 'RESET_PAGINATION',
  SetIsSelectionChanged = 'SET_IS_SELECTION_CHANGED',
  SetScreen = 'SET_SCREEN',
  ResetState = 'RESET_STATE',
  CollectionName = 'COLLECTION_NAME',
  SetIsFindClicked = 'SET_IS_FIND_CLICKED',
}

type ReducerAction =
  | { type: Actions.SetTags; payload: string[] }
  | { type: Actions.SetVendors; payload: string[] }
  | { type: Actions.SetProductTypes; payload: string[] }
  | { type: Actions.SetConditions; payload: Condition[] }
  | { type: Actions.SetConditionById; payload: { id: string; type: Condition['type'] } }
  | { type: Actions.SetOperation; payload: CatalogSelectionRuleConditionOperationType }
  | { type: Actions.SetPageNumber; payload: number }
  | { type: Actions.SetVisited; payload: number }
  | { type: Actions.ResetPagination }
  | { type: Actions.SetIsSelectionChanged; payload: boolean }
  | { type: Actions.SetScreen; payload: 'find' | 'import' }
  | { type: Actions.ResetState }
  | { type: Actions.CollectionName; payload: string }
  | { type: Actions.SetIsFindClicked; payload: boolean };

const initialState: FindAndImportState = {
  tags: [],
  vendors: [],
  productTypes: [],
  conditions: [{
    id: uuidv4(),
    type: null,
  }],
  operation: CatalogSelectionRuleConditionOperationType.OR,
  pageNumber: 1,
  visited: {},
  isSelectionChanged: false,
  screen: 'find',
  collectionName: '',
  isFindClicked: false,
};

export const reducer = (state: FindAndImportState, action: ReducerAction): FindAndImportState => {
  switch (action.type) {
    case Actions.SetTags:
      return { ...state, tags: action.payload };
    case Actions.SetVendors:
      return { ...state, vendors: action.payload };
    case Actions.SetProductTypes:
      return { ...state, productTypes: action.payload };
    case Actions.SetConditions:
      return { ...state, conditions: action.payload };
    case Actions.SetConditionById:
      return {
        ...state,
        conditions: state.conditions.map((condition) =>
          (condition.id === action.payload.id ? { ...condition, type: action.payload.type } : condition)),
      };
    case Actions.SetOperation:
      return { ...state, operation: action.payload };
    case Actions.SetPageNumber:
      return { ...state, pageNumber: action.payload };
    case Actions.SetVisited:
      return { ...state, visited: { ...state.visited, [action.payload]: true } };
    case Actions.ResetPagination:
      return { ...state, pageNumber: 1, visited: {} };
    case Actions.SetIsSelectionChanged:
      return { ...state, isSelectionChanged: action.payload };
    case Actions.SetScreen:
      return { ...state, screen: action.payload };
    case Actions.ResetState:
      return initialState;
    case Actions.CollectionName:
      return { ...state, collectionName: action.payload };
    case Actions.SetIsFindClicked:
      return { ...state, isFindClicked: action.payload };
    default:
      return state;
  }
};

export const useFindAndImportReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setTags: (tags: string[]) => dispatch({ type: Actions.SetTags, payload: tags }),
      setVendors: (vendors: string[]) => dispatch({ type: Actions.SetVendors, payload: vendors }),
      setProductTypes: (productTypes: string[]) =>
        dispatch({ type: Actions.SetProductTypes, payload: productTypes }),
      setConditions: (conditions: Condition[]) =>
        dispatch({ type: Actions.SetConditions, payload: conditions }),
      setConditionById: (id: string, type: Condition['type']) =>
        dispatch({ type: Actions.SetConditionById, payload: { id, type } }),
      setOperation: (operation: CatalogSelectionRuleConditionOperationType) =>
        dispatch({ type: Actions.SetOperation, payload: operation }),
      setPageNumber: (pageNumber: number) => dispatch({ type: Actions.SetPageNumber, payload: pageNumber }),
      setVisited: (pageNumber: number) => dispatch({ type: Actions.SetVisited, payload: pageNumber }),
      resetPagination: () => dispatch({ type: Actions.ResetPagination }),
      setIsSelectionChanged: (isSelectionChanged: boolean) =>
        dispatch({ type: Actions.SetIsSelectionChanged, payload: isSelectionChanged }),
      setScreen: (screen: 'find' | 'import') => dispatch({ type: Actions.SetScreen, payload: screen }),
      resetState: () => dispatch({ type: Actions.ResetState }),
      setCollectionName: (collectionName: string) =>
        dispatch({ type: Actions.CollectionName, payload: collectionName }),
      setIsFindClicked: (isFindClicked: boolean) =>
        dispatch({ type: Actions.SetIsFindClicked, payload: isFindClicked }),
    }),
    [],
  );

  return useMemo(() => ({ ...state, ...actions }), [state, actions]);
};

export const FindAndImportContext = createContext<FindAndImportContextProps>(null);

export interface FindAndImportProviderProps {
  children: React.ReactNode;
  initialValue?: Partial<FindAndImportContextProps>;
}

export const FindAndImportProvider = ({
  children,
  initialValue = {},
}: FindAndImportProviderProps) => {
  const value = useFindAndImportReducer();

  return (
    <FindAndImportContext.Provider value={{ ...value, ...initialValue }}>
      {children}
    </FindAndImportContext.Provider>
  );
};

export const useFindAndImportContext = () => useContext(FindAndImportContext);

export const useConditionById = (id: string) => {
  const { conditions } = useFindAndImportContext();
  const index = conditions.findIndex((condition) => condition.id === id);
  return { condition: conditions[index], index };
};
