import * as React from 'react';
import Linkify from 'react-linkify';
import { reduce, isEmpty } from 'lodash';
import { SortableList } from '@frontend/app/components/SortableList/SortableList';
import { Typography, List, Input } from '@revfluence/fresh';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_guidelines as TGuideline,
} from '@frontend/app/queries/types/TermsConfigsQuery';

import {
  IContentGuidelineInstruction,
  ISortableGuideline,
  IContentGuidelineTitle,
} from '../../BulkTerms/hooks/useState/actions';
import PostInstruction from './PostInstruction/PostInstruction';

import styles from './PostInstructions.scss';

const { Text, Title } = Typography;
const {
  useCallback,
  useMemo,
  useState,
} = React;

interface IProps {
  isReadOnly: boolean;
  id: number;
  guidelines: TGuideline[];
  onAddContentGuidelineInstruction: (contentGuidelineInstruction: IContentGuidelineInstruction) => void;
  onSortGuideline: (
    id: number,
    sortableGuideline: ISortableGuideline,
  ) => void;
  onUpdateContentGuidelineTitle: (contentGuidelineTitle: IContentGuidelineTitle) => void;
}

const PostInstructions: React.FC<IProps> = React.memo((props) => {
  const {
    isReadOnly,
    id,
    guidelines,
    onAddContentGuidelineInstruction,
    onSortGuideline,
    onUpdateContentGuidelineTitle,
  } = props;

  const [editedInstruction, setEditedInstruction] = useState('');

  const handleUpdateInstruction = useCallback((value: string, guidelineIndex: number, instructionIndex: number) => {
    onAddContentGuidelineInstruction({
      instruction: value,
      guidelineIndex,
      instructionIndex,
      id,
    });
  }, [onAddContentGuidelineInstruction, id]);

  const handleSortableItemsChange = useCallback((lines) => {
    const sortableGuideline = reduce(lines, (res, line) => {
      if (line.type in res) {
        res[line.type].push(line.data.props.instruction);
      } else {
        res[line.type] = [line.data.props.instruction];
      }
      return res;
    }, {});
    onSortGuideline(id, sortableGuideline);
  }, [id, onSortGuideline]);

  const handleToggle = useCallback((toggle: boolean, guidelineIndex: number, instructionIndex: number) => {
    setEditedInstruction(toggle ? `${id}-${guidelineIndex}-${instructionIndex}` : '');
  }, [id]);

  const sortableItems = useMemo(
    () => {
      if (isReadOnly) {
        return {};
      }
      const instructionsByGuidelineType = reduce(guidelines, (res, guideline, guidelineIndex) => {
        res[guideline.type] = guideline.instructions.map((
          (instruction, instructionIndex) => (
            {
              type: guideline.type,
              id: instructionIndex,
              data: (
                <PostInstruction
                  key={instruction}
                  placeholder={guideline.placeholder}
                  instruction={instruction}
                  guidelineIndex={guidelineIndex}
                  instructionIndex={instructionIndex}
                  isEditing={editedInstruction === `${id}-${guidelineIndex}-${instructionIndex}`}
                  onUpdateInstruction={handleUpdateInstruction}
                  onToggleEditing={handleToggle}
                />
              ),
            }
          )
        ));
        return res;
      }, {});
      return instructionsByGuidelineType;
    },
    [
      guidelines,
      handleUpdateInstruction,
      isReadOnly,
      editedInstruction,
      id,
      handleToggle,
    ],
  );

  return (
    <div className={styles.PostInstructions}>
      {
        isReadOnly && (
          guidelines.map((guideline: TGuideline, index) => (
            <div className={styles.PostInstructionReadOnly} key={`${id}-${index.toString()}`}>
              <Text strong>
                {guideline.title}
              </Text>
              <List
                size="small"
                dataSource={isEmpty(guideline.instructions) ? [guideline.placeholder] : guideline.instructions}
                renderItem={(item) => (
                  <List.Item>
                    <Linkify>
                      {item}
                    </Linkify>
                  </List.Item>
                )}
              />
            </div>
          ))
        )
      }
      {
        !isReadOnly && (
          Object.keys(sortableItems).map((sortableItem, guidelineIndex) => (
            <div className={styles.PostInstruction} key={`${id}-${sortableItem}`}>
              {
                guidelines[guidelineIndex].allowTitleEdition
                ? (
                  <Input
                    placeholder={guidelines[guidelineIndex].title}
                    bordered={false}
                    value={guidelines[guidelineIndex].title}
                    onChange={(e) => onUpdateContentGuidelineTitle({
                      title: e.target.value,
                      guidelineIndex,
                      id,
                    })}
                    maxLength={50}
                  />
                ) : (
                  <Title level={5}>{guidelines[guidelineIndex].title}</Title>
                )
              }
              <SortableList
                itemClassName={styles.item}
                onChange={(result) => handleSortableItemsChange(result)}
                options={sortableItems[sortableItem]}
              />
              <PostInstruction
                placeholder={guidelines[guidelineIndex].placeholder}
                instruction=""
                guidelineIndex={guidelineIndex}
                instructionIndex={guidelines[guidelineIndex].instructions.length}
                isEditing={
                  editedInstruction === `${id}-${guidelineIndex}-${guidelines[guidelineIndex].instructions.length}`
                }
                onUpdateInstruction={handleUpdateInstruction}
                key={`${id}-${sortableItem}-${guidelines[guidelineIndex].instructions.length}`}
                onToggleEditing={handleToggle}
              />
            </div>
          )))
      }
    </div>
  );
});

PostInstructions.displayName = 'PostInstructions';

export default PostInstructions;
