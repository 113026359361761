import { IState } from '../../../../types/state';
import { IUpdateInfluencerWhiteListEndDate } from './index';

export const updateInfluencerWhitelistEndDate = (
  state: IState, action: IUpdateInfluencerWhiteListEndDate,
): IState => ({
    ...state,
    collaborationDetails: {
      ...state.collaborationDetails,
      bam: {
        ...state.collaborationDetails.bam,
        endDateAccess: action.endDateAccess,
      },
    },
  });
