import gql from 'graphql-tag';

export const MESSAGE_THREAD_USER_LABEL_FRAGMENT = gql`
  fragment MessageThreadUserLabelFragment on MessageThreadUserLabel {
    threadId
    userId
    label
    createdDate
    updatedDate
  }
`;
