import { useQuery } from '@apollo/client';

import { GET_MEMBER_BY_EMAIL_QUERY } from '@frontend/app/queries';
import {
  GetMemberByEmailQuery,
  GetMemberByEmailQueryVariables,
} from '@frontend/app/queries/types/GetMemberByEmailQuery';

export function useGetMemberByEmail() {
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery<GetMemberByEmailQuery, GetMemberByEmailQueryVariables>(GET_MEMBER_BY_EMAIL_QUERY, {
    variables: {
      email: '',
    },
    skip: true,
  });

  const getMemberByEmail = async (email: string) => {
    try {
      const {
        data: {
          member,
        },
      } = await refetch({ email });
      return member;
    } catch (error) {
      return null;
    }
  };

  return {
    loading,
    error,
    data,
    getMemberByEmail,
  };
}
