import * as React from 'react';

import { WorkflowNotice as CPSWorkflowNotice } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/container/WorkflowNotice';
import { WorkflowNotice as STAWorkflowNotice } from '@frontend/applications/AffiliatesApp';
import { WorkflowNotice as ProjectsWorkflowNotice } from '@frontend/app/containers/Projects/ProjectsPage/WorkflowNotice';
import { TTask } from '@frontend/app/containers/Projects/types';
import { WorkflowNotice as OfflineWorkNotice } from '@frontend/applications/ProductFulfillmentApp/NoCatalogueProductSelection/containers/WorkflowNotice/WorkflowNotice';
import { SetupNotice as TransitNotice } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/OrderRequestSetup/components/SetupNotice';
import { BrandCatalogWorkflowNotice } from '../../Settings/ProductFulfillment/components/WorkflowNotice/WorkflowNotice';

const { useMemo } = React;

export const useCustomTaskNotice = (projectId: number, task: TTask, membersIds: number[], projectName: string) => useMemo(() => {
  if (!projectId || !task?.workletSpecUri || !task?.taskId) {
    return null;
  }
  if (task.taskMetaData.serviceId !== null) {
    switch (task.workletSpecUri) {
      case 'CreatorProductSelectionWorkletSpecification':
        switch (task.taskId) {
          case 'send_order_request_task':
            return (
              <CPSWorkflowNotice
                programId={projectId}
              />
            );
        }
        break;
      case 'SendBrandProductCatalogWorkletSpecification':
        switch (task.taskId) {
          case 'pfa_v2_send_order_request_task':
            return (
              <BrandCatalogWorkflowNotice
                programId={projectId}
              />
            );
        }
        break;
      case 'OfflineCreatorProductSelectionWorkletSpecification':
        switch (task.taskId) {
          case 'offline_send_order_request_task':
            return (
              <OfflineWorkNotice
                programName={projectName}
                programId={projectId}
              />
            );
          case 'offline_order_in_transit_task':
            return (
              <TransitNotice
                title="Orders will automatically move out of “Product in Transit” as delivery confirmation is received. Manually mark orders as delivered only if an order was not auto-updated or you did not input a tracking number."
                description={null}
              />
            );
        }
        break;
      case 'SalesLinkWorkletSpecification':
      case 'PromoCodesWorkletSpecification':
      case 'SalesLinkPromoCodesWorkletSpecification':
        switch (task.taskId) {
          case 'generate_link_task':
          case 'generating_link_task':
          case 'send_link_task':
          case 'generate_promo_task':
          case 'generating_promo_task':
          case 'fix_error_promo_task':
          case 'send_promo_task':
          case 'send_link_and_promo_task':
            return (
              <STAWorkflowNotice
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                {...{} as any}
                programId={projectId}
                workletSpecUri={task.workletSpecUri}
                taskId={task.taskId}
                memberIds={membersIds}
              />
            );
        }
        break;
      default:
        return null;
    }
  } else {
    const matchWorkletSpecKeys = [
      'UID_e80552e8-f105-4dd4-bdde-f082f8fd35b1',
      'UID_ba236371-321c-4e59-9169-c76e0d5d67d7',
    ];
    if (matchWorkletSpecKeys.includes(task.workletSpecKey)) {
      return (
        <ProjectsWorkflowNotice
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...{} as any}
          workletSpecKey={task.workletSpecKey}
          taskMetaData={task.taskMetaData}
        />
      );
    }
  }
}, [projectId, task, membersIds, projectName]);
