import { IUpdateShowHideTemplatesModalAction, IState } from '../../../types/state';

export const updateShowHideTemplates = (state: IState, action: IUpdateShowHideTemplatesModalAction): IState => {
  if (state.isTemplatesModalVisible === action.isTemplatesModalVisible) {
    return state;
  }
  return {
    ...state,
    isTemplatesModalVisible: action.isTemplatesModalVisible,
    isEditMode: false,
    otherProjectId: null,
    defaultActiveKey: 0,
  };
};
