import React, { useCallback, useEffect, useState } from 'react';
import {
 Checkbox, CheckboxChangeEvent, ITableProps, Space, Spinner, Table, Typography,
} from '@revfluence/fresh';
import { BoxIcon } from '@revfluence/fresh-icons/regular/esm';
import { debounce } from 'lodash';
import { useProductTableProps } from './useProductTableProps';
import { IProductsRow } from '../../Products/types';
import { useBasicSelectionContext, useIsProductPartiallySelected } from './BasicSelectionContext';
import styles from './ProductsTable.scss';
import { useGetImportedProducts } from '../../Products/hooks/useGetProducts';
import { useGetProductDetailsLazy } from '../../Products/hooks/useGetProductDetails';

const { Text } = Typography;

const PAGE_SIZE = 50;

const EmptyScreen = () => (
  <Space direction="vertical" size={0} align="center">
    <BoxIcon fontSize={48} className={styles.boxIcon} />
    <Text type="secondary">Please select a collection to add products </Text>
  </Space>
);

const CheckboxRenderer = ({ value, record, disabled }: { value: boolean; record: IProductsRow; disabled: boolean }) => {
  const { toggleProduct } = useBasicSelectionContext();
  const { fetchProductDetails, loading, product } = useGetProductDetailsLazy({
    variables: {
      productId: Number(record.id),
    },
    notifyOnNetworkStatusChange: true,
  });
  const isPartiallySelected = useIsProductPartiallySelected({
    productId: record.id,
    totalVariants: record.variantsCount ?? 0,
  });
  const onSelectChange = async (e: CheckboxChangeEvent) => {
    if (!product) {
      fetchProductDetails({
        variables: {
          productId: Number(record.id),
        },
        onCompleted: (data) => {
          toggleProduct({
            productId: record.id,
            variantIds: data?.getProductVariantsByProductId?.productVariants?.map((variant) => String(variant.id)) ?? [],
            checked: e.target.checked,
          });
        },
      });
    } else {
      toggleProduct({
        productId: record.id,
        variantIds: product.productVariants?.map((variant) => String(variant.id)) ?? [],
        checked: e.target.checked,
      });
    }
  };

  if (loading) {
    return <Spinner size="small" />;
  }

  return <Checkbox checked={value} onChange={onSelectChange} indeterminate={isPartiallySelected} disabled={disabled} />;
};

interface ProductsTableProps {
  collectionId: number;
}

export const ProductsTable = ({ collectionId }: ProductsTableProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [localSearch, setLocalSearch] = useState('');
  const {
    selectedProducts,
    search,
    isAllProductsFetching,
    bulkSelect,
    setIsAllProductsFetching,
  } = useBasicSelectionContext();
  const { products, refetch, loading } = useGetImportedProducts({
    variables: {
      collectionId,
      page: pageNumber,
      pageSize: PAGE_SIZE,
      search: localSearch,
    },
    skip: !collectionId,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const handleNextPage = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handleSearch = useCallback(
    (value: string) => {
      setLocalSearch(value);
      setPageNumber(1);
    },
    [],
  );

  const debouncer = useCallback((search) => debounce(() => handleSearch(search), 2000), [handleSearch]);

  useEffect(() => {
    debouncer(search)();
  }, [debouncer, search]);

  useEffect(() => {
    setPageNumber(1);
  }, [collectionId]);

  const handlePrevPage = () => {
    setPageNumber((prev) => prev - 1);
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setIsAllProductsFetching(true);
      refetch({
        collectionId,
        page: 1,
        pageSize: products.totalProducts,
        search,
      })
        .then(({ data }) => {
          bulkSelect({
            products: data.getProductsByCollectionId.products.map((product) => ({
              id: String(product.id),
              totalVariants: product.totalVariants,
            })),
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => setIsAllProductsFetching(false));
    } else {
      bulkSelect({
        products: [],
      });
    }
  };

  const {
 columnConfig, pagination, tableData, expandable,
} = useProductTableProps({
    products: products.products,
    onNextPage: handleNextPage,
    onPreviousPage: handlePrevPage,
    totalProducts: products.totalProducts,
    pageNumber,
    disabled: isAllProductsFetching,
  });

  const rowSelection: ITableProps<IProductsRow>['rowSelection'] = {
    selectedRowKeys: selectedProducts.map((product) => product.productId),
    renderCell: (value, record) => <CheckboxRenderer value={value} record={record} disabled={isAllProductsFetching} />,
  };

  if (!loading && products.totalProducts === 0) {
    return <EmptyScreen />;
  }

  return (
    <div className={styles.ProductsTable}>
      <div className={styles.mainCheckbox}>
        <Checkbox
          checked={!loading && selectedProducts.length === products.totalProducts}
          indeterminate={selectedProducts.length > 0 && selectedProducts.length !== products.totalProducts}
          onChange={(e) => handleSelectAll(e.target.checked)}
          disabled={isAllProductsFetching}
        />
        <Text weight="semibold">
          {products.totalProducts}
          {' '}
          Products (
          {selectedProducts.length}
          {' '}
          Selected)
        </Text>
        <Spinner spinning={isAllProductsFetching} />
      </div>
      <div className={styles.table}>
        <Table
          columns={columnConfig}
          pagination={pagination}
          dataSource={tableData}
          className="offerTable"
          size="small"
          expandable={expandable}
          rowSelection={rowSelection}
          showHeader={false}
          rowKey="id"
          loading={loading || isAllProductsFetching}
        />
      </div>
    </div>
  );
};
