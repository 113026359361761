/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1dy48_157 {
  display: flex;
}

._justify-content-space-between_1dy48_161 {
  justify-content: space-between;
}

._tabular-nums_1dy48_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1dy48_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._Overlay_1dy48_178 {
  z-index: 99 !important;
}

._AddMemberOverlay_1dy48_182 {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 100;
  width: 21.875rem;
  height: 100%;
  background: #fdfdfd;
  pointer-events: none;
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1) 0.15s;
  transform: translateX(25rem);
}
._AddMemberOverlay_1dy48_182._visible_1dy48_194 {
  visibility: visible;
  pointer-events: inherit;
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1) 0.15s;
  transform: translateX(0);
}