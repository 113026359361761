import * as React from 'react';
import cx from 'classnames';
import xss from 'xss';
import { isUndefined } from 'lodash';
import { ITemplateProps } from '../types';

import styles from './Perks.scss';
import { RichTextVisualizer } from '../../RichTextEditor/Visualizer';

type TBulletStyle = 'numbers' | 'bullets' | 'none';

export type IPerksProps = ITemplateProps['perks'];

export const Perks: React.FC<IPerksProps> = ({
  show = true,
  bullet_style,
  items,
  title,
  componentStyles,
}) => {
  if (!show) {
    return null;
  }

  const renderItems = (items: string[], bulletStyle: TBulletStyle) => {
    /** To comply with Product alignments, we change this from ol/ul to divs */
    const renderedItems = items.map((item, index) => (
      <div
        key={index}
        className={styles.item}
      >
        <div
          className={cx(
              styles.itemBullet,
              {
                [styles.numbered]: bulletStyle === 'numbers',
                [styles.hide]: bulletStyle === 'none',
              },
            )}
        >
          <div
            className={styles.itemBulletSquare}
            style={{ backgroundColor: componentStyles.heading.color }}
          />
          <div
            className={styles.itemBulletValue}
            style={{
                color: componentStyles.page.backgroundColor,
              }}
          >
            {index + 1}
          </div>
        </div>

        <RichTextVisualizer
          className={styles.itemValue}
          style={componentStyles.body}
        >
          {isUndefined(item) ? 'What are awesome things your community can expect from joining?' : xss(item) }
        </RichTextVisualizer>
      </div>
      ));

    return (
      <div className={styles.list}>
        {renderedItems}
      </div>
    );
  };

  return (
    <div className={cx(styles.Perks)}>
      <h2
        className={styles.title}
        style={componentStyles.heading}
      >
        {title}
      </h2>
      {renderItems(items, bullet_style as TBulletStyle)}
    </div>
  );
};
