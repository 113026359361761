import * as React from 'react';

import { Button } from '@components';

import styles from './STASendItems.scss';

interface IProps {
  title: string | React.ReactElement;
  emailComposer: React.ReactElement;
  emailPreview: React.ReactElement;
  onCancel?(): void;
}

export const STAEmailComposer: React.FC<Readonly<IProps>> = ({
 title, emailComposer, emailPreview, onCancel,
}) => (
  <>
    <div className={styles.content}>
      <div className={styles.contentTop}>{title}</div>
      <div className={styles.contentMain}>
        {emailComposer}
        {emailPreview}
      </div>
    </div>
    {onCancel && (
      <div className={styles.footer}>
        <Button label="Cancel" onClick={onCancel} theme="light" className={styles.button} />
      </div>
    )}
  </>
);
