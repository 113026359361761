import * as React from 'react';
import { isEmpty } from 'lodash';

import {
  Collapse,
  List,
  Typography,
} from '@revfluence/fresh';
import { ContentPreviewer } from '@components';
import { TContentGuidelines } from '@frontend/app/types/GroupContentReview';

export const Guidelines = ({ data, exampleContents }: TContentGuidelines) => (
  <>
    <div style={{ marginTop: '24px' }} />
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={['guideline-category-0']}
    >
      {data.map(({ title, id, rules }, category) => (
        <Collapse.Panel
          header={title}
          key={`guideline-category-${id}`}
        >
          <List style={{ backgroundColor: '#ffffff', margin: '-16px' }}>
            {rules.map(({ description, id }) => (
              <List.Item
                style={{ padding: '16px' }}
                key={`guideline-rule-${category}-${id}`}
              >
                <Typography.Text>{description}</Typography.Text>
              </List.Item>
            ))}
          </List>
        </Collapse.Panel>
      ))}
      {!isEmpty(exampleContents) && (
        <Collapse.Panel
          header="Example Content"
          key="exampleContents"
        >
          <ContentPreviewer contents={exampleContents} enableDetailView />
        </Collapse.Panel>
      )}
    </Collapse>
  </>
);
