import { useMutation, MutationHookOptions } from '@apollo/client';

import { CREATE_COST_CONFIG_MUTATION } from '../queries';
import {
  CreateCostConfiguration,
  CreateCostConfigurationVariables,
} from '../queries/types/CreateCostConfiguration';

type IOptions = MutationHookOptions<CreateCostConfiguration, CreateCostConfigurationVariables>;

export function useCreateCostConfig(options: IOptions = {}) {
  const [createCostConfig, { loading, error }] = useMutation<
  CreateCostConfiguration,
  CreateCostConfigurationVariables
  >(CREATE_COST_CONFIG_MUTATION, options);

  return {
    createCostConfig,
    loading,
    error,
  };
}
