import * as React from 'react';
import cx from 'classnames';
import {
 map, toLower, isEmpty, includes,
} from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';

import { Tooltip, Typography } from 'antd';
import { SearchOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import {
 InstagramIcon, TiktokIcon, PinterestIcon, YoutubeIcon,
} from '@revfluence/fresh-icons/brands/esm';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import { Button } from '@revfluence/fresh';
import { Notice, EnvelopeIcon } from '@components';
import { UserAvatar } from '@frontend/app/components';
import { detectOsPlatform, OsPlatform } from '@frontend/app/utils/detectOsPlatform';

import { useSearchMember } from './useSearchMember';

import styles from './MemberSearch.scss';

const {
 useEffect, useMemo, useRef, useState,
} = React;
const { Text } = Typography;
interface IProps {
  className?: string;
  isContact?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const MemberSearch: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [keyboardSelectedIndex, setKeyboardSelectedIndex] = useState<number>(null);

  const filteredSearchValue = useMemo(() => {
    if (searchValue.startsWith('@')) {
      return searchValue.split('@').pop();
    }
    return searchValue;
  }, [searchValue]);

  const {
    loading,
    data: { members = [] } = {},
    count,
    instagramFieldId,
    tiktokFieldId,
    youtubeFieldId,
    pinterestFieldId,
  } = useSearchMember(filteredSearchValue, props.isContact);

  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      if (showOverlay) {
        input.focus();
      } else {
        input.blur();
      }
    }
  }, [inputRef, showOverlay]);

  useHotkeys(
    'cmd+k,ctrl+k',
    (e) => {
      e.preventDefault();
      if (!showOverlay) {
        toggleOverlay();
      }
    },
    [showOverlay],
  );

  useHotkeys(
    'esc',
    () => {
      if (showOverlay) {
        toggleOverlay();
      }
    },
    [showOverlay],
  );

  useHotkeys(
    'enter',
    () => {
      goToKeyboardSelectedIndex();
    },
    [showOverlay],
  );

  useHotkeys('up', () => {
    shiftKeyboardSelectedIndex(true);
  });

  useHotkeys('down', () => {
    shiftKeyboardSelectedIndex(false);
  });

  const shiftKeyboardSelectedIndex = (increment: boolean) => {
    if (!members || members.length === 0) {
      return;
    }
    const currentIndex = keyboardSelectedIndex || 0;

    if (increment) {
      setKeyboardSelectedIndex((currentIndex + 1) % members.length);
    } else {
      // Because -1 % N = -1 and not N-1
      setKeyboardSelectedIndex((((currentIndex - 1) % members.length) + members.length) % members.length);
    }
  };

  const goToKeyboardSelectedIndex = () => {
    if (keyboardSelectedIndex === null) {
      return;
    }

    const memberId = members[keyboardSelectedIndex].id;
    goToMemberDetails(memberId);
  };

  // Reset keyboard selections upon new search results
  useEffect(() => {
    if (members && members.length > 0) {
      setKeyboardSelectedIndex(0);
    } else if (keyboardSelectedIndex) {
      setKeyboardSelectedIndex(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
    setSearchValue('');
  };

  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);
  const onInputFocus = () => setInputFocused(true);
  const onInputBlur = () => setInputFocused(false);
  const goToMemberDetails = (memberId) => {
    toggleOverlay();
    history.push({
      ...location,
      pathname: `/members/${memberId}`,
    });
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 27) {
      // esc
      toggleOverlay();
      setInputFocused(false);
    } else if (event.keyCode === 38) {
      // up
      shiftKeyboardSelectedIndex(false);
    } else if (event.keyCode === 40) {
      // down
      shiftKeyboardSelectedIndex(true);
    } else if (event.keyCode === 13) {
      // enter
      goToKeyboardSelectedIndex();
    }
  };

  const searchTooltipLabel = useMemo(() => {
    const platform = detectOsPlatform();
    switch (platform) {
      case OsPlatform.MAC:
      case OsPlatform.IOS:
        return 'Cmd + K';
      default:
        return 'Ctrl + K';
    }
  }, []);

  return (
    <div className={cx(styles.MemberSearch, props.className)}>
      <Tooltip
        overlayStyle={{
          zIndex: 9999,
        }}
        placement="right"
        title={(
          <>
            Search
            {' '}
            <Text className={styles.MemberSearchTooltip} keyboard>
              {searchTooltipLabel}
            </Text>
          </>
        )}
      >
        <Button size="middle" type="text" onClick={toggleOverlay} icon={<MagnifyingGlassIcon />} />
      </Tooltip>
      <div
        className={cx(styles.overlay, {
          [styles.active]: showOverlay,
        })}
      >
        <div className={styles.mask} onClick={toggleOverlay} />
        <div className={styles.content}>
          <div
            className={cx(styles.inputArea, {
              [styles.active]: inputFocused,
            })}
          >
            {loading && <LoadingOutlined className={styles.icon} />}
            {!loading && <SearchOutlined className={styles.icon} />}
            <input
              ref={inputRef}
              className={styles.input}
              placeholder="Search for names, emails, usernames, talent managers..."
              value={searchValue}
              onChange={onSearchValueChange}
              onFocus={onInputFocus}
              onKeyDown={onKeyDown}
              onBlur={onInputBlur}
              autoComplete="off"
              spellCheck={false}
            />
          </div>
          {!!searchValue && (
            <>
              {count > 0 && (
                <div className={styles.memberCount}>
                  Found
                  {' '}
                  {count}
                  {' '}
                  result
                  {count > 1 ? 's' : ''}
                  {count > 100 ? ', showing the first 100' : ''}
                </div>
              )}
              <div className={styles.suggestions}>
                {map(members, (member, index) => {
                  let instagramUsername = member.fields[instagramFieldId] || '';
                  instagramUsername = instagramUsername.split('@').pop();
                  const instagramMatches = includes(toLower(instagramUsername), toLower(filteredSearchValue));

                  let tiktokUsername = member.fields[tiktokFieldId] || '';
                  tiktokUsername = tiktokUsername.split('@').pop();
                  const tiktokMatches = includes(toLower(tiktokUsername), toLower(filteredSearchValue));

                  let pinterestUsername = member.fields[pinterestFieldId] || '';
                  pinterestUsername = pinterestUsername.split('@').pop();
                  const pinterestMatches = includes(toLower(pinterestUsername), toLower(filteredSearchValue));

                  let youtubeUsername = member.fields[youtubeFieldId] || '';
                  youtubeUsername = youtubeUsername.split('@').pop();
                  const youtubeMatches = includes(toLower(youtubeUsername), toLower(filteredSearchValue));
                  return (
                    <div
                      className={cx(styles.item, {
                        [styles.selected]: index === keyboardSelectedIndex,
                      })}
                      key={member.id}
                      onClick={goToMemberDetails.bind(this, member.id)}
                      onMouseEnter={() => setKeyboardSelectedIndex(null)}
                    >
                      <UserAvatar
                        className={styles.avatar}
                        profilePicture={member.profilePicture}
                        size={32}
                        name={member.name}
                      />
                      <div className={styles.info}>
                        <div className={styles.name}>
                          <UserOutlined className={styles.icon} />
                          {member.name}
                        </div>
                        {instagramMatches && (
                          <div className={styles.sub}>
                            <InstagramIcon className={styles.icon} />
                            {instagramUsername}
                          </div>
                        )}
                        {tiktokMatches && (
                          <div className={styles.sub}>
                            <TiktokIcon className={styles.icon} />
                            {tiktokUsername}
                          </div>
                        )}
                        {pinterestMatches && (
                          <div className={styles.sub}>
                            <PinterestIcon className={styles.icon} />
                            {pinterestUsername}
                          </div>
                        )}
                        {youtubeMatches && (
                          <div className={styles.sub}>
                            <YoutubeIcon className={styles.icon} />
                            {youtubeUsername}
                          </div>
                        )}
                        {!instagramMatches && !tiktokMatches && !pinterestMatches && !youtubeMatches && (
                          <div className={styles.sub}>
                            <EnvelopeIcon size={16} className={styles.icon} />
                            {member.email}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {isEmpty(members) && (
                  <div className={styles.noticeWrapper}>
                    <Notice type="disabled" showDivider>
                      No members found.
                    </Notice>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

MemberSearch.defaultProps = {
  className: null,
};

MemberSearch.displayName = 'MemberSearch';
