import { useMutation, MutationHookOptions } from '@apollo/client';

import { APPROVE_ORDER_REQUEST_MUTATION } from '../queries';
import {
  ApproveOrderRequestMutation,
  ApproveOrderRequestMutationVariables,
} from '../queries/types/ApproveOrderRequestMutation';

type IOptions = MutationHookOptions<ApproveOrderRequestMutation, ApproveOrderRequestMutationVariables>;

export function useApproveOrderRequest(options: IOptions = {}) {
  const [approveOrderRequest, { loading, error }] = useMutation<
    ApproveOrderRequestMutation,
    ApproveOrderRequestMutationVariables
  >(APPROVE_ORDER_REQUEST_MUTATION, options);

  return {
    approveOrderRequest,
    loading,
    error,
  };
}
