import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    name
    featureIds
    updatedDate
    createdDate
  }
`;

export const USER_FRAGMENT_WITH_AUTH0 = gql`
  fragment UserFragmentWithAuth0 on User {
    ...UserFragment
    auth0User {
      appMetadata
    }
  }
  ${USER_FRAGMENT}
`;
