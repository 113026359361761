import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { TSelectedMember } from '@affiliates/components/MembersWizard/types';
import { isOfferShopify, isOfferTune } from '@affiliates/components/MembersWizard/utils';

export const getMemberOfferStatus = (
  member: TSelectedMember,
  isWorkflow: boolean,
  offerSource: OFFER_SOURCE,
): string | null => {
  if (!member.inOffer) {
    return 'Will add to Offer';
  }

  if (isWorkflow && member.forceCheckIn) {
    if (isOfferShopify(offerSource)) {
      return 'Already In Offer - Code Exists';
    }

    if (isOfferTune(offerSource)) {
      return 'Already In Offer - Link Exists';
    }
  }

  if (!isWorkflow && isOfferShopify(offerSource) && member.inOffer) {
    return 'Will Generate Code';
  }

  return null;
};
