import { BudgetStatus, BudgetStatusColor, BudgetThemeColors } from '../types/Budget';

export function getBudgetStatusColor(status: BudgetStatus): BudgetStatusColor {
    switch (status) {
        case BudgetStatus.OVER_BUDGET:
            return {
                primaryColor: BudgetThemeColors.GOLD_DARK,
                secondaryColor: BudgetThemeColors.GOLD_LIGHT,
            };
        case BudgetStatus.OVER_SPENT:
            return {
                primaryColor: BudgetThemeColors.RED_DARK,
                secondaryColor: BudgetThemeColors.RED_LIGHT,
            };
        default:
            return {
                primaryColor: BudgetThemeColors.RED_DARK,
                secondaryColor: BudgetThemeColors.RED_LIGHT,
            };
    }
}
