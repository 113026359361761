import * as React from 'react';
import cn from 'classnames';
import { map, slice } from 'lodash';

import { Avatar, Tooltip } from 'antd';
import {
 Button, Table as ReTable, Tag, Typography,
} from '@revfluence/fresh';
import { LazyImage, Video } from '@components';

import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants/clientFeatures';
import { MinusIcon, PlusIcon } from '@revfluence/fresh-icons/regular/esm';
import { InstagramContent } from '@frontend/app/components/EmbeddedContent/InstagramContent';
import { CONTENT_SOURCE } from '@frontend/app/utils';
import { TContentTable, TContentTableRow } from '../../types';
import styles from './Table.module.scss';

const { useState } = React;
const { Text } = Typography;

const renderStatus = (_: string, record: TContentTableRow) => {
  const { statusTags } = record;

  return <div className={styles.status}>{React.Children.toArray(statusTags)}</div>;
};

const renderComments = (_: string, record: TContentTableRow) => {
  const { totalComments } = record;

  if (totalComments) {
    return (
      <Tag color="default">
        {totalComments}
        {' '}
        Comment
        {totalComments > 1 && 's'}
      </Tag>
    );
  }

  // Don't render comments for Table expandable top level rows
  return <Tag color="default"> - </Tag>;
};
const renderLastCommentDate = (_: string, record: TContentTableRow) => {
  const { lastCommentDate } = record;
  return <Tag>{lastCommentDate || '-'}</Tag>;
};

const renderContent = (_: string, record: TContentTableRow) => {
  const { content, totalContent } = record;

  if (totalContent) {
    return (
      <Tag color="default">
        {totalContent}
        {' '}
        Deliverables
      </Tag>
);
  } else if (content) {
    const { icon, description, onClick } = content;
    return (
      <div className={styles.ContentCell}>
        <span className={styles.icon}>{icon}</span>
        <Button className={styles.link} type="link" onClick={onClick}>
          {description}
        </Button>
      </div>
    );
  }

  // Don't render content origin for Table expandable top level rows
  return <span />;
};

function renderMediaContent(media, options: { className: string } = { className: '' }) {
  const { className } = options;

  if (media.source === CONTENT_SOURCE.INSTAGRAM_EMBED) {
    return <InstagramContent url={media.url} className={`${className} ${styles.instagramEmbedAvatar}`} renderAsImage />;
  }

  if (media.source === CONTENT_SOURCE.YOUTUBE_EMBED) {
    return <LazyImage src={media.image} className={className} />;
  }

  if (media.source === CONTENT_SOURCE.TIKTOK_EMBED) {
    return <LazyImage src={media.image} className={className} />;
  }

  if (media.type === 'image' && media.source === CONTENT_SOURCE.GCS) {
    return <LazyImage src={media.url} className={className} />;
  }

  if (media.type === 'video') {
    return <Video src={media.url} />;
  }

  return null;
}

const renderDeliverables = (_: string, record: TContentTableRow) => {
  const { content, totalDeliverables } = record;

  if (totalDeliverables) {
    return (
      <Tag color="default">
        {totalDeliverables}
        {' '}
        Required Content
      </Tag>
);
  } else if (content) {
    const { deliverables } = content;

    return (
      <div className={styles.deliverables}>
        {map(slice(deliverables, 0, 3), (d, index) => (
          <Tooltip
            key={index}
            title={(
              <div className={styles.OverlayContent}>
                {renderMediaContent(d, { className: styles.image })}
              </div>
            )}
          >
            <Avatar
              shape="square"
              icon={(
                <>
                  {renderMediaContent(d, { className: styles.image })}
                </>
              )}
            />
          </Tooltip>
        ))}
      </div>
    );
  }

  // Don't render deliverables items for Table expandable top level rows
  return <span />;
};

const getAllIds = (data, prefix = '') => {
  const ids = [];
  data.forEach((item) => {
    ids.push(prefix + item.id.toString());
  });
  return ids;
};

export const Table = ({ columns, rows }: TContentTable) => {
  const submissionHistory = useClientFeatureEnabled(ClientFeature.GCR_SUBMISSION_HISTORY_ENABLED);

  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);

  const rowIds = getAllIds(rows);

  const toggleExpandAll = () => {
    if (expandedRowIds.length === rowIds.length) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(rowIds);
    }
  };

  const tColumns = columns
    .filter((column) => {
      if (!submissionHistory) {
        return !['tiktok', 'instagram', 'lastCommentDate', 'totalComments'].includes(column.dataIndex as string);
      }
      return true;
    })
    .map((column) => {
      switch (column.dataIndex) {
        case 'memberData':
          column.title = (
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
              <Button
                icon={expandedRowIds.length === rowIds.length ? <MinusIcon /> : <PlusIcon />}
                onClick={toggleExpandAll}
                className={styles.expandButton}
              />
              <Text strong>Name</Text>
            </div>
          );
          column.width = '200px';
          return column;
        case 'instagram':
          column.width = '150px';
          return column;
        case 'tiktok':
          column.width = '150px';
          return column;
        case 'status':
          column.width = '150px';
          column.render = renderStatus;
          return column;
        case 'content':
          column.width = '150px';
          column.render = renderContent;
          return column;
        case 'deliverables':
          column.width = '150px';
          column.render = renderDeliverables;
          return column;
        case 'date':
          column.width = '150px';
          return column;
        case 'totalComments':
          column.width = '150px';
          column.render = renderComments;
          return column;
        case 'lastReviewer':
          column.width = '180px';
          return column;
        case 'lastCommentDate':
          column.render = renderLastCommentDate;
          return column;
        default:
          return column;
      }
    });

  return (
    <ReTable
      columns={tColumns}
      dataSource={rows}
      indentSize={0}
      size="middle"
      pagination={false}
      className={cn(styles.table)}
      scroll={{ x: 'max-content' }}
      style={{ boxShadow: 'none' }}
      showSorterTooltip={false}
      expandable={{
        expandedRowKeys: expandedRowIds,
        onExpand: (expanded, record) => {
          const recordId = Number(record.id);
          setExpandedRowIds((prevIds) => {
            if (expanded) {
              return [...new Set([...prevIds, recordId.toString()])];
            } else {
              return prevIds.filter((id) => id !== recordId.toString());
            }
          });
        },
        expandIcon: ({ expanded, onExpand, record }) =>
          (record?.children && record.children.length > 0 ? (
            <Button
              icon={expanded ? <MinusIcon /> : <PlusIcon />}
              onClick={(e) => onExpand(record, e)} // Pass the record here
              className={styles.expandButton}
            />
          ) : null),
      }}
    />
  );
};
