import { IGuideline } from './guideline';

export interface IDeliverableGuidelines {
  other_guidelines: IGuideline[];
  post_instructions?: IGuideline[];
  caption_above_read_more?: IGuideline[];
  talking_points?: IGuideline[];
  name: string;
  post_type: string;
  concept: IGuideline[];
  caption_guidelines?: IGuideline[];
  video_guidelines?: IGuideline[];
  placeholder: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  example_content_guidelines: any[];
}
