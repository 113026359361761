import * as React from 'react';

import { DateRangeOptions, IDateRangeSettings } from '@frontend/app/components';
import { useDateFilterSettings } from '@frontend/app/hooks';

// Minimal data from offer needed
interface IContextOffer {
  id: number;
  name: string;
  source: string;
}

type TRefreshFunction = () => void;

interface IPaymentsDueLoading {
  state: 'loading';
}
interface IPaymentsDueReady {
  paymentsDue: number;
  state: 'ready';
}
interface IPaymentsDueFailed {
  errorMessage: string;
  state: 'failed';
}

export type TPaymentsDue =
  | IPaymentsDueLoading
  | IPaymentsDueReady
  | IPaymentsDueFailed;

interface ISTAPaymentAppContext {
  dateFilter: IDateRangeSettings;
  offers: IContextOffer[];
  setOffers: (offers: IContextOffer[]) => void;
  paymentsDue: TPaymentsDue;
  setPaymentsDue: (paymentsDue: TPaymentsDue) => void;
  refreshCurrentDashboard: TRefreshFunction;
  setRefreshCurrentDashboard: (refresh: TRefreshFunction) => void;
  refreshCurrentTable: TRefreshFunction;
  setRefreshCurrentTable: (refresh: TRefreshFunction) => void;
}

const { useContext, useState } = React;

const STAPaymentAppContext = React.createContext<ISTAPaymentAppContext>(null);

export const useSTAPaymentAppContext = () => useContext(STAPaymentAppContext);

export const STAPaymentAppContextProvider = ({ children }) => {
  const dateFilter = useDateFilterSettings(DateRangeOptions.ALL_TIME);
  const [offers, setOffers] = useState<IContextOffer[]>([]);
  const [paymentsDue, setPaymentsDue] = useState<TPaymentsDue>({ state: 'loading' });
  const [refreshCurrentDashboard, setRefreshCurrentDashboard] = useState<TRefreshFunction>();
  const [refreshCurrentTable, setRefreshCurrentTable] = useState<TRefreshFunction>();

  return (
    <STAPaymentAppContext.Provider
      value={{
        dateFilter,
        offers,
        setOffers,
        paymentsDue,
        setPaymentsDue,
        refreshCurrentDashboard,
        setRefreshCurrentDashboard,
        refreshCurrentTable,
        setRefreshCurrentTable,
      }}
    >
      {children}
    </STAPaymentAppContext.Provider>
  );
};
