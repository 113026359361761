import * as React from 'react';
import {
  Button, Popover, Input, TPopoverAnchorOrigin,
  TPopoverArrowPosition, SpinnerIcon,
} from '@components';

import { useMessagingContext } from '@frontend/hooks';
import { useSaveSegmentMutation, useDeleteSegment, useSegmentFoldersQuery } from '@frontend/app/hooks';
import { SegmentInput } from '@frontend/app/types/globalTypes';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import { isEmpty } from 'lodash';
import { Tooltip } from 'antd';
import styles from './EditSegment.scss';
import { IFolder } from '../SegmentFolders/SegmentFolder';

const { useState, useEffect, useMemo } = React;

interface IProps {
  communityId: number;
  sourcingGroupId: string;
  show: boolean;
  segment: SegmentInput;
  folder?: IFolder;
  mountRef: React.RefObject<HTMLElement>;
  anchorOrigin?: TPopoverAnchorOrigin;
  arrowPosition?: TPopoverArrowPosition;
  onRequestClose();
}

export const EditSegment: React.FC<IProps> = (props) => {
  const [segmentName, setSegmentName] = useState<string>(props.segment.title);
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [inputErrorTooltipMsg, setInputErrorTooltipMsg] = useState<string>('');

  useEffect(() => {
    setSegmentName(props.segment.title);
  }, [props.segment.title]);

  const {
    showSuccessMessage,
    showError,
    showGenericErrorMessage,
  } = useMessagingContext();

  const addEvent = useEventContext();

  const {
    refetch: refetchSegmentFolders,
  } = useSegmentFoldersQuery(undefined, undefined, { skip: true });

  const [saveSegment] = useSaveSegmentMutation({
    onCompleted(result) {
      showSuccessMessage(`Successfully saved filter ${result.segment.title}`);
      props.onRequestClose();
      setSaving(false);
      addEvent(EventName.EditSegment, {});
    },
    onError: (error) => {
      showError(error);
      setSaving(false);
    },
  });

  const [deleteSegment] = useDeleteSegment({
    onCompleted() {
      refetchSegmentFolders({
        communityId: props.communityId,
        sourceGroup: props.sourcingGroupId,
      });
      showSuccessMessage(`Successfully deleted filter ${props.segment.title}`);
      props.onRequestClose();
      setDeleting(false);
    },
    onError() {
      showGenericErrorMessage();
      setDeleting(false);
    },
  });

  const segmentTitleIdMap = useMemo(() => props.folder.segments.reduce((acc, segment) => ({
    ...acc,
    [segment.title]: segment.id,
  }), {}), [props.folder.segments]);

  const isProcessing = saving || deleting;
  const isSaveButtonDisabled = isProcessing || !isEmpty(inputErrorTooltipMsg);

  const handleClickSave = () => {
    if (isProcessing || !props.show) {
      return;
    }

    if (props.segment.title === segmentName) {
      props.onRequestClose();
      return;
    }

    setSaving(true);

    saveSegment({
      variables: {
        segment: {
          id: props.segment.id,
          title: segmentName,
        },
      },
    });
  };

  const onInputChange = (newValue: string) => {
    setInputErrorTooltipMsg('');

    const hasDuplicateSegmentTitle = segmentTitleIdMap[newValue] !== undefined
      && segmentTitleIdMap[newValue] !== props.segment.id;

    if (!newValue) {
      setInputErrorTooltipMsg('Segment name is required');
    }

    if (hasDuplicateSegmentTitle) {
      setInputErrorTooltipMsg('This name has already been used');
    }

    setSegmentName(newValue);
  };

  const handleRequestClosed = (): void => {
    setSegmentName(props.segment.title);
    setInputErrorTooltipMsg('');
    props.onRequestClose();
  };

  const handleClickDeleteSegment = () => {
    if (confirm('Are you sure?')) {
      setDeleting(true);

      deleteSegment({
        variables: {
          id: props.segment.id,
        },
      });
    }
  };

  return (
    <Popover
      mountRef={props.mountRef}
      show={props.show}
      onRequestClose={handleRequestClosed}
      minWidth={220}
      anchorOrigin={props.anchorOrigin}
      arrowPosition={props.arrowPosition}
    >
      <div className={styles.EditSegment}>
        <div className={styles.label}>Edit your segment:</div>
        <Input
          value={segmentName}
          onChange={onInputChange}
          className={styles.field}
          disabled={isProcessing}
          onPressEnter={handleClickSave}
          blurOnEnter
        />
        <div className={styles.btns}>
          <Tooltip
            overlayStyle={{
              zIndex: 9999,
            }}
            title={inputErrorTooltipMsg}
          >
            <Button
              label="Save"
              onClick={handleClickSave}
              icon={saving && <SpinnerIcon size={18} />}
              disabled={isSaveButtonDisabled}
            />
          </Tooltip>
          <Button
            label="Delete"
            theme="danger"
            onClick={handleClickDeleteSegment}
            icon={deleting && <SpinnerIcon size={18} />}
            disabled={isProcessing}
          />
        </div>
      </div>
    </Popover>
  );
};
