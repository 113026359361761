import * as React from 'react';
import cx from 'classnames';
import {
  isEmpty,
  size,
} from 'lodash';

import {
  BarChart,
} from '@components';

import { ICardTabConfig, Card } from './Card';
import { useSocialProfileContext } from '../hooks/useSocialProfileContext';

const { useRef } = React;
import styles from './CityStateCard.scss';
import { cityDataToIDataList, stateDataToIDataList } from '../utils/demographicsReport';
import { IData } from '../../charts/PieChart/PieChart';

interface IProps {
  className?: string;
  hideCity?: boolean;
  hideState?: boolean;
  /**
   * Number of cities/states to be displayed (including "other")
   */
  maxRecords?: number;
}

export const CityStateCard: React.FC<IProps> = (props) => {
  const {
    className,
    hideCity = false,
    hideState = false,
    maxRecords,
  } = props;
  const {
    socialAccount,
    hasData,
  } = useSocialProfileContext();
  const cityTabRef = useRef<HTMLDivElement>(null);
  const stateTabRef = useRef<HTMLDivElement>(null);

  const showBoth = !hideCity && !hideState;

  const cityData = (
    hasData
    && !isEmpty(socialAccount.demographics_report)
  )
    ? cityDataToIDataList(socialAccount.demographics_report, true)
    : [];
  const stateData = (
    hasData
    && !isEmpty(socialAccount.demographics_report)
  )
    ? stateDataToIDataList(socialAccount.demographics_report)
    : [];

  const hasCityData = size(cityData) > 0;
  const hasStateData = size(stateData) > 0;

  const renderCityChart = () => !hideCity && hasCityData && (
    <CityChart
      cityData={cityData}
      maxRecords={maxRecords}
    />
  );
  const renderStateChart = () => !hideState && hasStateData && (
    <StateChart
      stateData={stateData}
      maxRecords={maxRecords}
    />
  );

  const tabs: ICardTabConfig[] = (() => [
    {
      key: 'CITY',
      header: 'Audience City',
      contentRef: cityTabRef,
      content: renderCityChart(),
      tabClassName: cx({
        [styles.noData]: !hasCityData,
      }),
    },
    {
      key: 'STATE',
      header: 'Audience State',
      contentRef: stateTabRef,
      content: renderStateChart(),
      tabClassName: cx({
        [styles.noData]: !hasStateData,
      }),
    },
  ])();

  /**
   * If showing both, prevent defaulting to an empty tab
   */
  const defaultTab = (() => {
    if (showBoth) {
      return hasCityData || !hasStateData ? tabs[0] : tabs[1];
    } else if (hideState) {
      return tabs[0]; // City
    } else if (hideCity) {
      return tabs[1]; // State
    }
  })();

  return (
    <Card
      className={cx(className, styles.CityStateCard, {
        // If either is shown, set no data state if necessary
        [styles.noData]: !showBoth && (
          (!hideCity && !hasCityData)
          || (!hideState && !hasStateData)
        ),
      })}
      header={defaultTab.header}
      tabConfigs={showBoth ? tabs : null}
      currentTabRef={defaultTab.contentRef}
    >
      {!showBoth && (renderCityChart() || renderStateChart())}
    </Card>
  );
};

CityStateCard.displayName = 'CityStateCard';

const CityChart = React.memo<{
  cityData: IData[];
  maxRecords: number;
}>((props) => (
  size(props.cityData) > 0 && (
    <BarChart
      className={styles.audienceLocationBarChart}
      data={props.cityData}
      maxRecords={props.maxRecords}
      height={200} // Shows 5 (see maxRecords in BarChartHorizontal.tsx)
      showRawValue={false}
    />
  )
));

CityChart.displayName = 'CityChart';

const StateChart = React.memo<{
  stateData: IData[];
  maxRecords: number;
}>((props) => (
  size(props.stateData) > 0 && (
    <BarChart
      className={styles.audienceLocationBarChart}
      data={props.stateData}
      maxRecords={props.maxRecords}
      height={200} // Shows 5 (see maxRecords in BarChartHorizontal.tsx)
      showRawValue={false}
    />
  )
));

StateChart.displayName = 'StateChart';
