import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_STATS_FOR_ALL_PROJECTS_QUERY } from '../queries';
import { GetStatsForAllProjects, GetStatsForAllProjectsVariables } from '../queries/types/GetStatsForAllProjects';

type IOptions = QueryHookOptions<GetStatsForAllProjects, GetStatsForAllProjectsVariables>;

export function useGetStatsForAllProjects(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetStatsForAllProjects, GetStatsForAllProjectsVariables>(
    GET_STATS_FOR_ALL_PROJECTS_QUERY,
    {
      ...options,
    },
  );

  return {
    isStatsForAllProjectsLoading: loading,
    statsForAllProjects: data?.stats,
    statsForAllProjectsError: error,
    refetchStatsForAllProjects: refetch,
  };
}
