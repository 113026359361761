import {
 RefObject, useEffect, useState, useMemo,
} from 'react';

export const useIsTextTruncated = (ref: RefObject<HTMLElement> | undefined) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const observer = useMemo(() => new ResizeObserver((entries: ResizeObserverEntry[]) => {
    if (entries[0]) {
      const el = entries[0].target as HTMLElement;
      setIsTruncated(el.scrollWidth > el.offsetWidth);
    }
  }), []);

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current, observer]);

  return isTruncated;
};
