/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1pl4b_157 {
  display: flex;
}

._justify-content-space-between_1pl4b_161 {
  justify-content: space-between;
}

._tabular-nums_1pl4b_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1pl4b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_1pl4b_178 ._header_1pl4b_178 ._title_1pl4b_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._info_1pl4b_188 ._value_1pl4b_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._content_1pl4b_178 ._header_1pl4b_178 ._title_1pl4b_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._info_1pl4b_188 ._label_1pl4b_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1pl4b_157 {
  display: flex;
}

._justify-content-space-between_1pl4b_161 {
  justify-content: space-between;
}

._tabular-nums_1pl4b_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1pl4b_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_1pl4b_178 ._header_1pl4b_178 ._title_1pl4b_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._info_1pl4b_188 ._value_1pl4b_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._content_1pl4b_178 ._header_1pl4b_178 ._title_1pl4b_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._info_1pl4b_188 ._label_1pl4b_188 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  color: #1a1818;
}

._content_1pl4b_178 {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
._content_1pl4b_178 ._header_1pl4b_178 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._content_1pl4b_178 ._header_1pl4b_178 ._title_1pl4b_178 {
  display: flex;
  flex-direction: column;
}
._content_1pl4b_178 ._header_1pl4b_178 ._title_1pl4b_178 h3 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  color: #2e426d;
}
._content_1pl4b_178 ._header_1pl4b_178 ._title_1pl4b_178 h4 {
  margin: 0 0 0.5rem 0.5rem;
  color: #8f8d91;
}
._content_1pl4b_178 ._header_1pl4b_178 ._select_1pl4b_491 {
  margin-left: auto;
}

._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._graph_1pl4b_501 {
  margin-right: 2.5rem;
  flex-shrink: 0;
  height: 100%;
  width: 22.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._graph_1pl4b_501 ._bar_1pl4b_510 {
  height: 100%;
  flex: 1;
  border-radius: 12.5rem 12.5rem 0rem 0rem;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._graph_1pl4b_501 ._bar_1pl4b_510._cost_1pl4b_515 {
  background: linear-gradient(0deg, rgba(255, 233, 217, 0) 0%, #FFE9D9 108.69%, #FFE9D9 108.69%);
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._graph_1pl4b_501 ._bar_1pl4b_510._tmv_1pl4b_518 {
  background: linear-gradient(180deg, rgba(57, 150, 224, 0.6) 0%, rgba(57, 150, 224, 0) 100%);
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 {
  display: flex;
  flex-direction: column;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188._cost_1pl4b_515 ._dot_1pl4b_531 {
  background: #FFEADA;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188._tmv_1pl4b_518 ._dot_1pl4b_531, ._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188._roi_1pl4b_534 ._dot_1pl4b_531 {
  background: #ACCAE2;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._dot_1pl4b_531 {
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6.25rem;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._dot_1pl4b_531 ._roiDot_1pl4b_548 {
  width: 0.625rem;
  height: 0.625rem;
  background: #FFEADA;
  border-radius: 6.25rem;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._info_1pl4b_188 {
  display: flex;
  flex-direction: column;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._info_1pl4b_188 ._value_1pl4b_188 {
  color: #2E426D;
}
._ROISection_1pl4b_188 ._content_1pl4b_178 ._content_1pl4b_178 ._labelSection_1pl4b_188 ._item_1pl4b_188 ._info_1pl4b_188 ._label_1pl4b_188 {
  text-transform: uppercase;
  color: #4F4F4F;
}