import { useQuery, QueryHookOptions } from '@apollo/client';

import { GET_ORDERS_FOR_DASHBOARD_QUERY } from '../queries';
import { GetOrdersForDashboard, GetOrdersForDashboardVariables } from '../queries/types/GetOrdersForDashboard';

type IOptions = QueryHookOptions<GetOrdersForDashboard, GetOrdersForDashboardVariables>;

export function useGetOrdersForDashboard(options: IOptions) {
  const {
 loading, data, error, refetch, previousData,
} = useQuery<GetOrdersForDashboard, GetOrdersForDashboardVariables>(
    GET_ORDERS_FOR_DASHBOARD_QUERY,
    options,
  );

  return {
    isOrdersLoading: loading,
    orders: data?.getOrdersForDashboard?.orders,
    totalOrders: data?.getOrdersForDashboard?.totalOrders,
    previousOrders: previousData?.getOrdersForDashboard?.orders,
    previousTotalOrders: previousData?.getOrdersForDashboard?.totalOrders,
    ordersError: error,
    refetchOrders: refetch,
  };
}
