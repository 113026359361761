import gql from 'graphql-tag';

export const PAGING_DATA_FRAGMENT = gql`
  fragment PagingDataFragment on PagingData {
    limit
    offset
    count
    hasMore
  }
`;
