import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

import { v4 as uuidv4 } from 'uuid';

const { useMemo } = React;

const Pinterest = React.memo((props: ISvgIconProps) => {
  const id = useMemo(() => uuidv4(), []);

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
    >
      <path
        fill={props.fill}
        d="
        M23.625 12C23.625 18.4219 18.4219 23.625 12 23.625C10.7812 23.625 9.60938 23.4844 8.53125 23.1094C9 22.3594 9.70312 21.0938 9.98438 20.0625C10.125 19.5469 10.6875 17.2969 10.6875 17.2969C11.0625 18.0469 12.1875 18.6562 13.3594 18.6562C16.875 18.6562 19.4062 15.4219 19.4062 11.4375C19.4062 7.59375 16.2656 4.6875 12.2344 4.6875C7.21875 4.6875 4.54688 8.0625 4.54688 11.7188C4.54688 13.4531 5.4375 15.5625 6.89062 16.2656C7.125 16.3594 7.26562 16.3125 7.3125 16.0781C7.3125 15.9375 7.54688 15.1406 7.64062 14.7656C7.64062 14.6719 7.64062 14.5312 7.54688 14.4375C7.07812 13.875 6.70312 12.7969 6.70312 11.7656C6.70312 9.23438 8.625 6.75 11.9531 6.75C14.7656 6.75 16.7812 8.67188 16.7812 11.4844C16.7812 14.625 15.1875 16.7812 13.125 16.7812C12 16.7812 11.1562 15.8438 11.3906 14.7188C11.7188 13.3125 12.375 11.8125 12.375 10.8281C12.375 9.9375 11.9062 9.1875 10.9219 9.1875C9.75 9.1875 8.8125 10.4062 8.8125 12C8.8125 13.0312 9.14062 13.7344 9.14062 13.7344C9.14062 13.7344 8.01562 18.6094 7.78125 19.5C7.54688 20.5312 7.64062 21.9375 7.73438 22.8281C3.42188 21.1406 0.375 16.9688 0.375 12C0.375 5.57812 5.57812 0.375 12 0.375C18.4219 0.375 23.625 5.57812 23.625 12Z
        "
      />
      <mask
        id={id}
        maskUnits="userSpaceOnUse"
      >
        <path
          d="
          M23.625 12C23.625 18.4219 18.4219 23.625 12 23.625C10.7812 23.625 9.60938 23.4844 8.53125 23.1094C9 22.3594 9.70312 21.0938 9.98438 20.0625C10.125 19.5469 10.6875 17.2969 10.6875 17.2969C11.0625 18.0469 12.1875 18.6562 13.3594 18.6562C16.875 18.6562 19.4062 15.4219 19.4062 11.4375C19.4062 7.59375 16.2656 4.6875 12.2344 4.6875C7.21875 4.6875 4.54688 8.0625 4.54688 11.7188C4.54688 13.4531 5.4375 15.5625 6.89062 16.2656C7.125 16.3594 7.26562 16.3125 7.3125 16.0781C7.3125 15.9375 7.54688 15.1406 7.64062 14.7656C7.64062 14.6719 7.64062 14.5312 7.54688 14.4375C7.07812 13.875 6.70312 12.7969 6.70312 11.7656C6.70312 9.23438 8.625 6.75 11.9531 6.75C14.7656 6.75 16.7812 8.67188 16.7812 11.4844C16.7812 14.625 15.1875 16.7812 13.125 16.7812C12 16.7812 11.1562 15.8438 11.3906 14.7188C11.7188 13.3125 12.375 11.8125 12.375 10.8281C12.375 9.9375 11.9062 9.1875 10.9219 9.1875C9.75 9.1875 8.8125 10.4062 8.8125 12C8.8125 13.0312 9.14062 13.7344 9.14062 13.7344C9.14062 13.7344 8.01562 18.6094 7.78125 19.5C7.54688 20.5312 7.64062 21.9375 7.73438 22.8281C3.42188 21.1406 0.375 16.9688 0.375 12C0.375 5.57812 5.57812 0.375 12 0.375C18.4219 0.375 23.625 5.57812 23.625 12Z
          "
        />
      </mask>
      <g mask={`url(#${id})`} />
    </SvgIcon>
  );
});

Pinterest.displayName = 'PinterestUncolored';

export { Pinterest };
