/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_lauf1_157 {
  display: flex;
}

._justify-content-space-between_lauf1_161 {
  justify-content: space-between;
}

._tabular-nums_lauf1_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_lauf1_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._PaginationArrow_lauf1_178 {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
._PaginationArrow_lauf1_178._arrowLeft_lauf1_188 {
  left: -5rem;
}
._PaginationArrow_lauf1_178._arrowRight_lauf1_191 {
  right: -5rem;
}