/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { chain, isString } from 'lodash';

import { logger } from '@common';
import { LoadSpinner } from '@components';
import { UserAvatar, Pagination } from '@frontend/app/components';
import {
  Table, IColumnsType, Button, Row, Col, Statistic, Card, Space, Input, Select,
} from '@revfluence/fresh';
import { ArrowsRotateIcon } from '@revfluence/fresh-icons/regular/esm';

import {
  useGetOrders, useGetOrderSummary, useSyncOrders,
} from '../hooks';
import styles from './ProductFulfillmentShipmentPage.scss';
import { GetOrdersQuery_orders } from '../queries/types/GetOrdersQuery';
import { ProjectConfigType } from '../types/globalTypes';
import { useProductFulfillmentContext } from '../context';

const { useMemo, useState } = React;

const PAGE_SIZE = 40;

export interface IColumn {
  schemaId?: number;
  headerName: string;
  field: string;
  type: string;
  editable?: boolean;
  showInFilters?: boolean;
  showInEditColumns?: boolean;
  choices?: string[];
}

export type IOrders = GetOrdersQuery_orders & {
  id: string;
  member: any;
  products: any;
  _sortableFields: {
    member: string;
    products: string;
    raw: string;
    shipmentTracking: string;
    fulfillmentStatus: string;
  };
};

const renderNameField = (member) => (
  <div className={styles.nameCell}>
    <UserAvatar name={member.name} className={styles.avatar} />
    <NavLink to={(location) => ({ ...location, pathname: `/members/${member.id}` })}>
      <div className={(styles as any).name}>{member.name || ''}</div>
    </NavLink>
  </div>
);

const customSort = (aValue: string | number, bValue: string | number) => {
  if (aValue < bValue) {
    return -1;
  }
  if (aValue > bValue) {
    return 1;
  }
  return 0;
};

const getOrderProducts = (order: GetOrdersQuery_orders): any[] => {
  switch (order.type) {
    case ProjectConfigType.NoCatalogue:
      return order.details?.no_catalogue_line_items || [];
    case ProjectConfigType.Shopify:
    case ProjectConfigType.ProductCatalog:
    default:
      return order.raw?.line_items || [];
  }
};

const getOrderProductsString = (order: GetOrdersQuery_orders): string => {
  const products = getOrderProducts(order);

  let productNames: string[] = [];
  switch (order.type) {
    case ProjectConfigType.NoCatalogue:
      productNames = chain(products)
        .map((lineItem) => lineItem?.productName)
        .filter((n): n is string => isString(n))
        .value();
      break;
    case ProjectConfigType.Shopify:
    case ProjectConfigType.ProductCatalog:
    default:
      productNames = chain(products)
        .map((lineItem) => lineItem?.name)
        .filter((n): n is string => isString(n))
        .value();
      break;
  }

  return productNames.join(', ');
};

export const ProductFulfillmentShipmentPage: React.FunctionComponent = () => {
  const { loading, orders, refetch: refetchOrders } = useGetOrders();
  const { syncOrders, loading: syncing } = useSyncOrders();
  const { summary } = useGetOrderSummary();
  const [page, setPage] = useState(1);
  const { productCostEnabled } = useProductFulfillmentContext();
  console.log(productCostEnabled);

  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<'name' | 'orderId'>('name');

  const [tableData, totalSize]: [IOrders[], number] = useMemo(() => {
    let filteredOrders = orders;
    let totalSize = filteredOrders.length;
    if (searchText) {
      filteredOrders = orders.filter((o) => {
        switch (searchType) {
          case 'name':
            return o.member?.name?.toLowerCase().includes(searchText.toLowerCase());
          case 'orderId':
            if (!o.raw?.order_number) {
              return false;
            }
            return String(o.raw?.order_number).includes(searchText);
          default:
            return true;
        }
      });
      totalSize = filteredOrders.length;
    }

    const start = PAGE_SIZE * (page - 1);
    const end = PAGE_SIZE * page;
    filteredOrders = filteredOrders.slice(start, end);

    if (!loading) {
      return [filteredOrders.map((o, index) => {
        const row = {
          ...o,
          id: index.toString(),
          member: o.member,
          products: getOrderProducts(o),
          _sortableFields: {
            member: o.member?.name?.toLowerCase(),
            products: getOrderProductsString(o),
            raw: o.raw?.order_number || 'N/A',
            shipmentTracking: o.shipmentTracking?.shipmentStatus,
            fulfillmentStatus: o.fulfillmentStatus || 'N/A',
          },
        };
        return row;
      }), totalSize];
    } else {
      return [[], 0];
    }
  }, [loading, orders, page, searchText, searchType]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    setPage(1);
  };

  const columns: IColumnsType = [
    {
      title: 'Name',
      key: 'name',
      render: (data) => <div style={{ width: 180 }}>{renderNameField(data?.member)}</div>,
      fixed: 'left',
      sorter: (a: IOrders, b: IOrders) => customSort(a?.member?.name.toLowerCase(), b?.member?.name.toLowerCase()),
    },
    {
      title: 'Products',
      key: 'products',
      render: (data) => <div style={{ width: 135 }}>{data?._sortableFields.products}</div>,
      sorter: (a: IOrders, b: IOrders) =>
        customSort(a?._sortableFields.products || '', b?._sortableFields.products || ''),
    },
    {
      title: 'Order #',
      key: 'order_number',
      render: (data) => <div style={{ width: 80 }}>{data?.raw?.order_number || 'N/A'}</div>,
      sorter: (a: IOrders, b: IOrders) =>
        customSort(Number(a?.raw?.order_number) || 0, Number(b?.raw?.order_number) || 0),
    },

    {
      title: 'Order date',
      key: 'order_date',
      render: (data) => (
        <div style={{ width: 100 }}>
          {(data?.createdDate && new Date(data?.createdDate).toLocaleDateString()) || ''}
        </div>
      ),
      sorter: (a: IOrders, b: IOrders) => customSort(a?.createdDate, b?.createdDate),
    },
    {
      title: 'Fulfillment status',
      key: 'fulfillment_status',
      render: (data) => <div style={{ width: 135 }}>{data?.fulfillmentStatus || 'N/A'}</div>,
      sorter: (a: IOrders, b: IOrders) =>
        customSort(a?.fulfillmentStatus?.toLowerCase() || 'N/A', b?.fulfillmentStatus?.toLowerCase() || 'N/A'),
    },
    {
      title: 'Shipment status',
      key: 'shipment_status',
      render: (data) => <div style={{ width: 135 }}>{data?.shipmentTracking?.shipmentStatus || 'N/A'}</div>,
      sorter: (a: IOrders, b: IOrders) =>
        customSort(
          a?.shipmentTracking?.shipmentStatus?.toLowerCase() || 'N/A',
          b?.shipmentTracking?.shipmentStatus?.toLowerCase() || 'N/A',
        ),
    },
    {
      title: 'Tracking #',
      key: 'tracking_number',
      render: (data) => <div style={{ width: 180 }}>{data?.shipmentTracking?.trackingNumber || 'N/A'}</div>,
      sorter: (a: IOrders, b: IOrders) => customSort(a?.shipmentTracking?.trackingNumber, b?.shipmentTracking?.trackingNumber),
    },
  ];

  if (loading) {
    return (
      <div className={styles.dashboard}>
        <LoadSpinner />
      </div>
    );
  }
  if (productCostEnabled) {
    columns.splice(2, 0, {
      title: 'Product Cost',
      key: 'cost',
      render: (data) => <div style={{ width: 80 }}>{`${data?.cost ? `$${data?.cost}` : 'N/A'}`}</div>,
      sorter: (a: IOrders, b: IOrders) =>
        customSort(Number(a?.cost) || 0, Number(b?.cost) || 0),
    });
  }

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }} className={styles.dashboard}>
      <Row>
        <Col flex="auto">
          <Card style={{ width: '100%' }}>
            <Row gutter={12} justify="center">
              <Col span={4}>
                <Statistic title="Orders created" value={summary?.total || 0} />
              </Col>
              <Col span={4}>
                <Statistic title="In transit" value={summary?.inTransit || 0} />
              </Col>
              <Col span={4}>
                <Statistic title="Delivered" value={summary?.delivered || 0} />
              </Col>
              {productCostEnabled
                && (
                  <Col span={4}>
                    <Statistic title="Product Cost" value={`${summary?.totalCost || 0}`} />
                  </Col>
                )}
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col flex="auto">
          <Button
            icon={<ArrowsRotateIcon />}
            disabled={loading || syncing}
            loading={loading || syncing}
            onClick={() => {
              syncOrders()
                .then(() => refetchOrders())
                .catch(logger.error);
            }}
          >
            Sync Orders
          </Button>
        </Col>
        <Col>
          <Input.Group compact className={styles.searchContainer}>
            <Select defaultValue="name" value={searchType} onChange={(value: 'name' | 'orderId') => setSearchType(value)}>
              <Select.Option value="name">Name</Select.Option>
              <Select.Option value="orderId">Order ID</Select.Option>
            </Select>
            <Input placeholder="Search" value={searchText} onChange={handleSearchChange} />
          </Input.Group>
        </Col>
        <Col>
          <Pagination total={totalSize} page={page} setPage={setPage} pageSize={PAGE_SIZE} showTotal />
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <Table<IOrders> columns={columns} scroll={{ x: true }} dataSource={tableData} pagination={false} />
        </Col>
      </Row>
    </Space>
  );
};
