import { useMemo } from 'react';
import {
 sortBy, differenceBy, toLower, keys, filter, chain, intersectionBy, keyBy,
} from 'lodash';
import { useMemberFieldSchemasQuery, useGetApplicationsByIds } from '@frontend/app/hooks';
import { SocialAccountFields } from '@frontend/app/constants/socialAccountFields';

interface IOptions {
  skip?: boolean;
}

export const useMemberFieldPartitions = (options: IOptions = {}) => {
  const {
    data: {
      schemas = null,
    } = {},
    loading,
  } = useMemberFieldSchemasQuery({
    skip: !!options.skip,
  });

  const schemasById = useMemo(() => keyBy(schemas, 'id'), [schemas]);

  const schemasByName = useMemo(() => keyBy(schemas, 'name'), [schemas]);

  const appSchemas = useMemo(() => filter(schemas, 'applicationId'), [schemas]);

  const schemasByApplicationId = useMemo(() => chain(appSchemas)
      .groupBy('applicationId')
      .mapValues((vals) => sortBy(vals, [(field) => toLower(field.name)], 'name'))
      .value(), [appSchemas]);

  const applicationIds = useMemo(() => keys(schemasByApplicationId), [schemasByApplicationId]);

  const {
    data: {
      applications = null,
    } = {},
  } = useGetApplicationsByIds(applicationIds);

  const nonSocialFields = useMemo(() => differenceBy(schemas, SocialAccountFields, 'name'), [schemas]);

  const nonSocialOrAppFields = useMemo(() => differenceBy(nonSocialFields, appSchemas, 'id'), [nonSocialFields, appSchemas]);

  const socialFields = useMemo(() => intersectionBy(schemas, SocialAccountFields, 'name'), [schemas]);

  const sortedSocialSchemasByName = useMemo(() => sortBy(socialFields, [(field) => toLower(field.name)], 'name'), [socialFields]);

  const sortedSchemasByName = useMemo(() => sortBy(nonSocialOrAppFields, [(field) => toLower(field.name)], 'name'), [nonSocialOrAppFields]);

  return {
    schemasById,
    schemasByName,
    schemasByApplicationId,
    applications,
    sortedSchemasByName,
    sortedSocialSchemasByName,
    loading,
  };
};
