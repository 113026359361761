import { isString, size } from 'lodash';
import { isEqual } from 'lodash';

type TypeUrl = string | URL;

export const createUrlObj = (url: TypeUrl): URL | string => {
  try {
    return new URL(url);
  } catch (e) {
    return url || '';
  }
};

export const getParamKeyFromValue = (url: URL, paramValue: string): string | null => {
  let paramKey: string | null = null;
  url.searchParams.forEach((value, key) => {
    if (value === paramValue) {
      paramKey = key;
    }
  });
  return paramKey;
};

export const checkUrlsHasSameParam = (firstUrl: URL, secondUrl: TypeUrl, paramValue: string) => {
  const paramKey = getParamKeyFromValue(firstUrl, paramValue);
  if (paramKey === null) {
    return {
      valid: false,
      paramString: undefined,
    };
  }

  const paramString = `${paramKey}=${firstUrl.searchParams.get(paramKey)}`;
  if (isString(secondUrl)) {
    return {
      paramString,
      valid: secondUrl.includes(paramString),
    };
  }
  return {
    paramString,
    valid: secondUrl.searchParams.get(paramKey) === firstUrl.searchParams.get(paramKey),
  };
};

export const checkUrlsHasSameDomain = (firstUrl: TypeUrl, secondUrl: TypeUrl) => {
  const firstURL = createUrlObj(firstUrl);
  const secondURL = createUrlObj(secondUrl);
  if (isString(firstURL) || isString(secondURL)) {
    return false;
  }
  return firstURL.origin.replace('www.', '') === secondURL.origin.replace('www.', '');
};

export const checkMultipleDomainHasDeepLink = (deepLink: TypeUrl, domains: string[]) => {
  const deepLinkUrl = createUrlObj(deepLink);
  if (isString(deepLinkUrl)) {
    return false;
  }
  for (const domain of domains) {
    const domainUrl = createUrlObj(domain);
    if (checkUrlsHasSameDomain(domainUrl, deepLinkUrl)) {
      return true;
    }
  }
  return false;
};

export const removeWww = (url: string) => {
  const urlObj = new URL(url);
  if (urlObj.hostname.startsWith('www.')) {
    urlObj.hostname = urlObj.hostname.substring(4);
  }
  return urlObj;
};

const validateUrl = (url: string) => {
  const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return regex.test(url);
};

export const validateDeepLinkWithDomain = (
  value: string,
  offerUrl: URL | null,
  domains: string[] | null | undefined,
  isCreatorDeepLinkEnabled: boolean | undefined = false,
) => {
  if (!value.length) {
    return { isValid: true };
  }

  if (!validateUrl(value)) {
    return { isValid: false };
  }

  try {
    const valueUrl = removeWww(value);
    const offerUrlNoWww = offerUrl ? removeWww(`${offerUrl.protocol}//${offerUrl.hostname}`) : null;
    const isValidBaseUrl = isEqual(
      `${valueUrl.protocol}//${valueUrl.hostname}`,
      `${offerUrlNoWww?.protocol}//${offerUrlNoWww?.hostname}`,
    );
    const isValidDomain = domains?.some((domain) => {
      const domainUrl = removeWww(domain);
      return isEqual(
        `${valueUrl.protocol}//${valueUrl.hostname}`,
        `${domainUrl.protocol}//${domainUrl.hostname}`,
      );
    });

    if (isValidBaseUrl) {
      return { isValid: true };
    }

    if (isCreatorDeepLinkEnabled && isValidDomain) {
      return { isValid: true };
    }

    return { isValid: false };
  } catch {
    return { isValid: false };
  }
};

const colorsForTag = [
  '#D18D9B', // Dusty Pink
  '#D4A66D', // Dusty Peach
  '#D3C1A0', // Dusty Beige
  '#A8D5BA', // Dusty Mint
  '#A4C8E1', // Dusty Sky Blue
  '#CFA3B4', // Dusty Rose
  '#BDA0D5', // Dusty Lavender
  '#D69F7B', // Dusty Terracotta
  '#E8B6A2', // Dusty Salmon
  '#B1C3E3', // Dusty Periwinkle
];

export const randomiseColorsForTags = (label: string): string => colorsForTag[label.length % size(colorsForTag)];

export const mapErrToMessage = (error: string) => {
  if (error.includes('duplicate')) {
    return 'This link already exists';
  }
  return error;
};
