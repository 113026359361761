import * as React from 'react';
import * as querystring from 'querystring';

import { logger } from '@common';

const { useEffect, useState } = React;

export interface ICreateClient {
  id: number;
  migrating_from_create: boolean;
}

const fetchClientData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

interface IUseFetchClientDataOptions {
  url: string;
  clientId: string;
}

export function useFetchClientData(options: IUseFetchClientDataOptions) {
  const {
    url: baseUrl,
    clientId,
  } = options;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ICreateClient>(null);
  const [error, setError] = useState(null);

  const filterString = querystring.stringify({
    client_id: clientId,
  });
  const url = `${baseUrl}?${filterString}`;

  useEffect(() => {
    if (clientId) {
      setLoading(true);
      fetchClientData(url)
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, [url, clientId]);

  return {
    loading,
    data,
    error,
  };
}
