import React, { useMemo } from 'react';
import {
 Button, Card, Dropdown, IMenuProps, Tooltip,
} from '@revfluence/fresh';
import { Typography } from '@revfluence/fresh';
import { AngleDownIcon, BoxesStackedIcon } from '@revfluence/fresh-icons/regular/esm';
import { BagShoppingIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './EmptyScreen.scss';
import { useProductsContext } from '../../Products/ProductsContext';

const { Text, Title } = Typography;

export const EmptyScreen = () => {
  const { isShopifyConnected, setIsOpen, setIsFindAndImportOpen } = useProductsContext();

  const items = useMemo<IMenuProps['items']>(() => [
    {
      key: 'import',
      label: 'Import Collections',
      onClick: () => setIsOpen(true),
    },
    {
      key: 'findAndImport',
      label: 'Find and Import',
      onClick: () => setIsFindAndImportOpen(true),
    },
  ], [setIsFindAndImportOpen, setIsOpen]);

  return (
    <div className={styles.NoCollections}>
      <Card>
        <div>
          <BoxesStackedIcon fontSize={80} className={styles.stackedBoxesIcon} />
          <div className={styles.titleContainer}>
            <Title level={5}>Start importing your products</Title>
            <Text>Easily import products from Shopify</Text>
          </div>
          {/* We will need the following in the next iteration, do not delete */}
          {/* <div className={styles.importOptionsContainer}>
            <Card className={styles.uploadCard} onClick={() => setIsOpen(true)}>
              <div>
                <ShopifyIcon fontSize={32} />
                <Text weight="semibold">Import Shopify Collections</Text>
                <Text type="secondary" color="var(--gray-7)">
                  Create a new object to become more powerful.
                </Text>
              </div>
            </Card>
            <Card className={styles.uploadCard}>
              <div>
                <FileCsvIcon fontSize={32} />
                <Text weight="semibold">Upload Collection via CSV</Text>
                <Text type="secondary" color="var(--gray-7)">
                  Create a new object to become more powerful.
                </Text>
              </div>
            </Card>
          </div> */}
          <Tooltip title={isShopifyConnected ? '' : 'Please connect a Shopify store to start importing products'}>
            <Dropdown menu={{ items }} disabled={!isShopifyConnected}>
              <Button type="primary" icon={<BagShoppingIcon />}>
                Import Products
                <AngleDownIcon />
              </Button>
            </Dropdown>
          </Tooltip>
        </div>
      </Card>
    </div>
  );
};
