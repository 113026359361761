import { GetMemberActivitiesQuery_activities } from '@frontend/app/queries/types/GetMemberActivitiesQuery';
import { GetApplicationsByIds_applications } from '@frontend/app/queries/types/GetApplicationsByIds';
import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';
import { GetCollaborationDetailsForProjectQuery_details } from '@frontend/app/queries/types/GetCollaborationDetailsForProjectQuery';

export type IMemberActivity = GetMemberActivitiesQuery_activities;
export type ICollaborationDetails = GetCollaborationDetailsForProjectQuery_details;
export type IApplication = GetApplicationsByIds_applications;
export type IMemberDetails = GetMemberQuery_member;

export enum ActivityFilters {
  All = 'All',
  AppNotifications = 'AppNotifications',
  Messages = 'Messages',
}

export const ActivityFiltersLabels: Record<ActivityFilters, string> = {
  [ActivityFilters.All]: 'All Activity',
  [ActivityFilters.AppNotifications]: 'App Notifications',
  [ActivityFilters.Messages]: 'Messages',
};
