import { OFFER_PROMO_CODE_STATUS } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { TMember } from '../../../types';
import { getActiveDatesFromMembers, getCurrentActiveDatesLabel } from '../../../utils';
import { TState } from './types';

export const getDefaultState = (
  members: readonly TMember[],
  usePreviousCode: boolean,
  isNewFlow: boolean,
): TState => {
  const activeDates = getActiveDatesFromMembers(members);
  return {
    activeDates,
    currentActiveDatesLabel: getCurrentActiveDatesLabel(activeDates),
    currentStep: 1,
    error: null,
    members: members.map((m) => ({
      ...m,
      code: m.promoCodeStatus === OFFER_PROMO_CODE_STATUS.FAILED_OTHER || usePreviousCode ? m.previousCode.code : '',
      inOffer: true,
      isDuplicate: false,
      selected: !isNewFlow,
      selectedPrograms: '',
    })),
    saving: false,
    showCloseConfirmation: false,
    searchKey: '',
  };
};
