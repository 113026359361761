import * as React from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Tooltip,
  Typography,
} from '@revfluence/fresh';

import styles from '@frontend/app/components/InstagramMeta/MetaItems/MetaItems.module.scss';

const { useState } = React;
const { Text } = Typography;

interface IBusiness {
  networkId: string;
  name: string;
  selected: boolean;
}

interface ISelectedBusinesses {
  [key: string]: boolean;
}

interface IBusinessBusinessInput {
  business_network_id: string;
}

interface IBusinessBusinessButtonProps {
  businesses: IBusiness[];
  selectBusiness: (businesses: IBusinessBusinessInput[]) => void;
  installedBusiness: boolean;
  isButtonDisabled: boolean;
}

export const BusinessButton: React.FC<IBusinessBusinessButtonProps> = (props) => {
  const {
    businesses,
    installedBusiness,
    selectBusiness,
    isButtonDisabled,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [input, setInput] = React.useState<string | null>(null);
  const [selectedBusiness, setSelectedBusiness] = React.useState<ISelectedBusinesses>({});

  const filteredBusinesses = React.useMemo(() => {
    if (!input) return businesses;
    return businesses.filter(({ name }) =>
      name.toLowerCase().includes(input.toLowerCase()));
  }, [input, businesses]);

  const radioOptions = React.useMemo(() => filteredBusinesses.map((business) => ({
    label: business.name,
    value: business.networkId,
  })), [filteredBusinesses]);

  const selectedIds = businesses.filter(({ networkId }) => selectedBusiness[networkId]).map(({ networkId }) => ({
    business_network_id: networkId,
  }));

  const radioSelected: string[] = React.useMemo(() => businesses
    .map((business) => {
      if (selectedBusiness[business.networkId]) {
        return business.networkId;
      }
      return null;
    })
    .filter((businessId: string | null) => !!businessId),
    [businesses, selectedBusiness]);

  const handleRadioChange = (e: RadioChangeEvent) => {
    const selectedBusinessId = e.target.value;
    setSelectedBusiness({ [selectedBusinessId]: true });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    selectBusiness(selectedIds);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderTooltip = () => {
    if (isButtonDisabled) {
      return (
        <Tooltip title="There are no businesses to select associated with your connected Meta account(s)">
          <Button onClick={showModal} disabled={isButtonDisabled}>Select Business</Button>
        </Tooltip>
      );
    } else {
      return (
        <Button onClick={showModal} disabled={isButtonDisabled}>Select Business</Button>
      );
    }
  };

  return (
    <>
      {renderTooltip()}
      <Modal
        title="Select Business"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Done"
        className={styles.TableModal}
      >
        <Space direction="vertical" size="large">
          {installedBusiness && (
            <Alert
              type="warning"
              message="You already have a business selected. Changing this business will change your existing and future allowlisting permissions and you will no longer have access to the accounts that your old business had access to."
            />
          )}
          <Text>
            Please select the business associated with your Instagram accounts for allowlisting. Currently, only one business can be utilized.
          </Text>
          <Input.Search
            placeholder="Search to Select"
            onChange={(e) => setInput(e.currentTarget.value)}
            value={input}
          />
          <Radio.Group
            name="radiogroup"
            value={radioSelected[0]}
            onChange={handleRadioChange}
          >
            <Space direction="vertical" size="middle">
              {radioOptions.map((option) => (
                <Radio key={String(option.value)} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Space>
      </Modal>
    </>
  );
};
