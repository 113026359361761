import {
  chain,
  isUndefined,
  keyBy,
} from 'lodash';
import * as React from 'react';

import { TWorklet } from '@frontend/app/containers/Projects/types';
import {
  useClientFeatureEnabled,
  useGetAllWorkletsQuery,
} from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { WorkletSpecKey } from '../utils';

const { useMemo } = React;

interface IOptions {
  workletSpecKeys?: string[];
}

interface IUseWorklets {
  loading: boolean;
  workletByKey: { [key: string]: TWorklet };
  worklets: TWorklet[];
}

export const useWorklets = ({ workletSpecKeys }: IOptions = {}): IUseWorklets => {
  const {
    loading,
    data: {
      worklets: allWorklets = undefined,
    } = {},
  } = useGetAllWorkletsQuery({
    variables: {
      specKeys: workletSpecKeys,
    },
  });
  const isOfflineCreatorProductSelectionEnabled = useClientFeatureEnabled(ClientFeature.OFFLINE_CREATOR_PRODUCT_SELECTION);
  const worklets: TWorklet[] = useMemo(
    () => (!isUndefined(allWorklets)
      ? chain(allWorklets)
        .filter((worklet) => {
          if (!isOfflineCreatorProductSelectionEnabled) {
            return worklet.specKey !== WorkletSpecKey.PFAOfflineCreatorProductSelection;
          }
          return true;
        })
        .reduce<TWorklet[]>(
          (result, worklet) => (
            worklet.specURI !== 'CustomWorkletSpecification'
              ? [...result, worklet]
              : result
          ),
          [],
        )
        .sortBy((worklet) => worklet.specTitle)
        .value()
      : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allWorklets],
  );
  const workletByKey = useMemo(
    () => (!isUndefined(worklets)
      ? keyBy(worklets, (worklet) => worklet.specKey)
      : undefined),
    [worklets],
  );

  return {
    loading,
    workletByKey,
    worklets,
  };
};
