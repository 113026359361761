import cx from 'classnames';
import { isNil, isFunction } from 'lodash';
import * as React from 'react';
import { EllipsisLabel } from '@frontend/app/components';
import { UserAvatar } from '@frontend/app/components';
import styles from './NameCell.scss';

interface IProps {
  accessory?: React.ReactNode;
  className?: string;
  imageUrl?: string;
  name: string;
  onClick?(): void;
}

export const NameCell: React.FC<IProps> = React.memo(({
  accessory,
  className,
  imageUrl,
  name,
  onClick,
}) => (
  <div
    className={cx(
      styles.NameCell,
      className,
    )}
  >
    <UserAvatar
      className={cx(styles.avatar, { [styles.interactable]: isFunction(onClick) })}
      name={name}
      profilePicture={imageUrl}
      onClick={onClick}
    />
    <div className={cx(styles.name, { [styles.interactable]: isFunction(onClick) })}>
      <EllipsisLabel
        tooltipPlacement="top"
        showTooltip
        textOnlyTitle
      >
        <span onClick={onClick}>
          {name}
        </span>
      </EllipsisLabel>
    </div>
    {!isNil(accessory) && (
      <div className={styles.accessory} onClick={(e) => e.stopPropagation()}>
        {accessory}
      </div>
    )}
  </div>
));

NameCell.displayName = 'ProjectsPageNameCell';
