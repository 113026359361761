import gql from 'graphql-tag';

export const GET_IMPORT_LOGS = gql`
  query GetImportLogs {
    getAllImportLogs {
      collection {
        id
        name
        productCount
        images
        lastSyncDate
        externalId
        metadata {
          handle
        }
      }
      id
      logPayload {
        importedProductCount
        importedVariantCount
        name
        processEnd
        processStart
        status
        message
      }
      userInfo {
        name
      }
    }
  }
`;
