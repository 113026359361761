/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_4k7kj_157 {
  display: flex;
}

._justify-content-space-between_4k7kj_161 {
  justify-content: space-between;
}

._tabular-nums_4k7kj_165 {
  font-variant-numeric: tabular-nums;
}

._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_4k7kj_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 ::-webkit-scrollbar-track, ._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._SearchResults_4k7kj_169 ._CreatorListTitle_4k7kj_204 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 ._title_4k7kj_214 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: #1a1818;
}

._CreatorListWrapper_4k7kj_224 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

._SearchResults_4k7kj_169 {
  min-width: unset;
  padding: 0;
}
._SearchResults_4k7kj_169 ._EmptyMessage_4k7kj_169 {
  width: 100%;
  text-align: center;
}