/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1yz7m_157 {
  display: flex;
}

._justify-content-space-between_1yz7m_161 {
  justify-content: space-between;
}

._tabular-nums_1yz7m_165 {
  font-variant-numeric: tabular-nums;
}

._CreatorDetail_1yz7m_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._invite_1yz7m_174 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_1yz7m_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._CreatorDetail_1yz7m_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._CreatorDetail_1yz7m_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._CreatorDetail_1yz7m_169 ::-webkit-scrollbar-track, ._CreatorDetail_1yz7m_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._CreatorDetail_1yz7m_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._CreatorDetail_1yz7m_169 {
  padding: 32px;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
}
._CreatorDetail_1yz7m_169 ._header_1yz7m_213, ._CreatorDetail_1yz7m_169 ._footer_1yz7m_213 {
  margin: 24px 0;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 {
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._creatorName_1yz7m_223 {
  display: flex;
  flex-flow: row;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._creatorName_1yz7m_223 h2 {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._creatorName_1yz7m_223 ._favoriteButton_1yz7m_233 {
  margin-left: 24px;
  display: inline-flex;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._invite_1yz7m_174,
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._actionButtonGroup_1yz7m_238 {
  margin: auto 0 auto auto;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._invite_1yz7m_174 {
  width: 16.25rem;
  border-radius: 624.9375rem;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._favorite_1yz7m_233 {
  margin-left: 1rem;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._actionButtonGroup_1yz7m_238 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorDetail_1yz7m_169 ._detailHeader_1yz7m_174 ._actionButtonGroup_1yz7m_238 > div {
  width: 120px;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 {
  display: flex;
  flex-direction: row;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 {
  margin-right: 16px;
  flex: 1;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 ._mainImageContainer_1yz7m_264 {
  width: 100%;
  padding-bottom: calc(100% - 16px);
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 ._mainImageContainer_1yz7m_264 ._mainImage_1yz7m_264 {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 ._imageList_1yz7m_278 {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 ._imageList_1yz7m_278 ._imageListItem_1yz7m_284 {
  margin-right: 10px;
  max-width: 64px;
  height: 64px;
  border-radius: 6px;
  flex: 1;
  cursor: pointer;
  object-fit: cover;
  object-position: center center;
  opacity: 0.5;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 ._imageList_1yz7m_278 ._imageListItem_1yz7m_284:hover {
  opacity: 0.8;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 ._imageList_1yz7m_278 ._imageListItem_1yz7m_284._active_1yz7m_298 {
  opacity: 1;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._images_1yz7m_260 ._imageList_1yz7m_278 ._imageListItem_1yz7m_284:last-child {
  margin-right: 0;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 {
  margin-left: 16px;
  flex: 1;
  overflow: hidden;
  border-radius: 6px;
  background-color: #f9f9f9;
  border: solid 1px #e6edf2;
  box-sizing: border-box;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 {
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  page-break-inside: avoid;
  break-inside: avoid-column;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 {
  display: flex;
  flex-direction: column;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._metricsTitle_1yz7m_324 {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._metricsTitle_1yz7m_324 ._helpIcon_1yz7m_331 {
  margin-left: 4px;
  color: #ea92af;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._metricsValue_1yz7m_335 {
  font-weight: 400;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._metricsValue_1yz7m_335 ._score_1yz7m_338 {
  margin-right: 4px;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._metricsValue_1yz7m_335 ._positive_1yz7m_341 {
  color: #4eb468;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._metricsValue_1yz7m_335 ._neutral_1yz7m_344 {
  color: #eacd60;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._metricsValue_1yz7m_335 ._negative_1yz7m_347 {
  color: #da3737;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._brandMention_1yz7m_350 {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._brandMention_1yz7m_350 ._brandLogo_1yz7m_356 {
  margin-right: 12px;
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._contentItem_1yz7m_313 ._metrics_1yz7m_320 ._subValue_1yz7m_363 {
  width: 100%;
  overflow: hidden;
  white-space: pre-line;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368 {
  margin-right: 16px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._followerIcon_1yz7m_376 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_followers.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._followerAuthIcon_1yz7m_379 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_follower_auth.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._monthlyViewsIcon_1yz7m_382 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/monthly-viewers-outline.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._expectedValueIcon_1yz7m_385 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_expected_value.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._likeIcon_1yz7m_388 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_like.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._sentimentIcon_1yz7m_391 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_sentiment.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._likeRatioIcon_1yz7m_394 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_like_ratio.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._bounceRateIcon_1yz7m_397 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_bounce_rate.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._engagementIcon_1yz7m_400 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_engagement.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._engagementQualityIcon_1yz7m_403 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_star_gray.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._clickIcon_1yz7m_406 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_click.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._commentIcon_1yz7m_409 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_comment.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._flagIcon_1yz7m_412 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_flag.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._hashtagIcon_1yz7m_415 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_hashtag.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._mentionIcon_1yz7m_418 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_mention.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._saveIcon_1yz7m_421 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_save.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._impressionIcon_1yz7m_424 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_impression.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._pageViewIcon_1yz7m_427 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_page_view.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._timeIcon_1yz7m_430 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_time.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._cityIcon_1yz7m_433 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_city.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._ageIcon_1yz7m_436 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_age.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._icon_1yz7m_368._genderIcon_1yz7m_439 {
  background-image: url(https://storage.googleapis.com/aspireiq-widgets/assets/icon_metrics_gender.svg);
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._summary_1yz7m_442 {
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  font-size: 15px;
  background-color: #fdfdfd;
  border-bottom: solid 1px #e9e8ea;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._summary_1yz7m_442 ._userInfo_1yz7m_452 {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._summary_1yz7m_442 ._userInfo_1yz7m_452 ._socialIcon_1yz7m_458 {
  margin-right: 8px;
  display: flex;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._summary_1yz7m_442 ._userInfo_1yz7m_452 ._name_1yz7m_462 {
  font-weight: bold;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._summary_1yz7m_442 ._engagement_1yz7m_400 {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._summary_1yz7m_442 ._followers_1yz7m_471 {
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._summary_1yz7m_442 ._unit_1yz7m_477 {
  margin-left: 4px;
  color: #8f8d91;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._content_1yz7m_313 {
  padding: 24px;
  columns: 2;
  column-width: auto;
  column-gap: 10px;
  font-size: 14px;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 {
  padding: 24px;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 ._sectionHeader_1yz7m_491 {
  margin: 24px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8f8d91;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 ._sectionHeader_1yz7m_491 ._igInsightsIcon_1yz7m_498 {
  margin-left: 12px;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 ._sectionHeader_1yz7m_491 ._verifiedIgInsights_1yz7m_501 {
  position: relative;
  display: flex;
  color: #3996e0;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 ._sectionHeader_1yz7m_491 ._verifiedIgInsights_1yz7m_501 ._instagramIconWrapper_1yz7m_506 {
  position: absolute;
  left: -5px;
  top: -5px;
  background-color: white;
  width: 14px;
  height: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9e8ea;
  border-radius: 100px;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 ._sectionHeader_1yz7m_491 ._divider_1yz7m_520 {
  flex: 1;
  height: 2px;
  background-color: #e9e8ea;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 ._sectionHeader_1yz7m_491 ._headerText_1yz7m_525 {
  margin: 0 24px;
  display: flex;
  font-weight: bold;
}
._CreatorDetail_1yz7m_169 ._info_1yz7m_256 ._details_1yz7m_304 ._section_1yz7m_488 ._sectionContent_1yz7m_530 {
  columns: 2;
  column-width: auto;
  font-size: 13px;
}