import { QueryHookOptions, useQuery } from '@apollo/client';
import { ShopifyResourceForClient, ShopifyResourceForClientVariables } from '../queries/types/ShopifyResourceForClient';
import { GET_SHOPIFY_RESOURCE } from '../queries/GetShopifyResourceForClientQuery';

type IOptions = QueryHookOptions<ShopifyResourceForClient, ShopifyResourceForClientVariables>;
export const useGetShopifyResourceQuery = (clientId: string, options: IOptions = {}) => (
    useQuery<ShopifyResourceForClient, ShopifyResourceForClientVariables>(GET_SHOPIFY_RESOURCE, {
        variables: {
            clientId,
        },
        fetchPolicy: 'cache-and-network',
        ...options,
    })
);
