/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1texy_157 {
  display: flex;
}

._justify-content-space-between_1texy_161 {
  justify-content: space-between;
}

._tabular-nums_1texy_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1texy_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferNameCell_1texy_178 ._name_1texy_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._OfferNameCell_1texy_178 {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
._OfferNameCell_1texy_178 ._imageWrapper_1texy_193 {
  display: flex;
}
._OfferNameCell_1texy_178 ._image_1texy_193 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
._OfferNameCell_1texy_178 ._name_1texy_178 {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  flex-grow: 1;
  cursor: pointer;
}
._OfferNameCell_1texy_178 ._name_1texy_178:hover {
  color: #4fa7ee;
}