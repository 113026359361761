import { updateGuidelineTitle } from '@frontend/applications/TermsApp/components/shared/utils/utils';
import { IUpdateContentGuidelineTitleAction, IState } from '../../../types/state';

export const updateContentGuidelineTitle = (
  state: IState,
  action: IUpdateContentGuidelineTitleAction,
): IState => ({
  ...state,
  contentGuidelines: updateGuidelineTitle(
    state.contentGuidelines,
    action.contentGuidelineTitle.id,
    action.contentGuidelineTitle.guidelineIndex,
    action.contentGuidelineTitle.title,
  ),
  isEditMode: true,
});
