/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_xicic_157 {
  display: flex;
}

._justify-content-space-between_xicic_161 {
  justify-content: space-between;
}

._tabular-nums_xicic_165, ._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td, ._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  font-variant-numeric: tabular-nums;
}

._Card_xicic_170 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

._Card_xicic_170 {
  filter: drop-shadow(0 0.0625rem 0.0625rem rgba(46, 66, 109, 0.2));
}

@keyframes _show_xicic_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._Card_xicic_170 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._Card_xicic_170 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._Card_xicic_170 ::-webkit-scrollbar-track, ._Card_xicic_170 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._Card_xicic_170 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._audienceLocationBarChart_xicic_165,
._audienceAgeBarChart_xicic_166 {
  width: 100%;
  margin-top: 0.5rem;
  flex: 1;
}
._audienceLocationBarChart_xicic_165 > [class*=list],
._audienceAgeBarChart_xicic_166 > [class*=list] {
  height: 100%;
}
._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem],
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem] {
  margin-bottom: auto !important;
  position: relative;
}
._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem]:last-of-type,
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem]:last-of-type {
  margin-bottom: 0 !important;
}
._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem] > [class*=label],
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem] > [class*=label] {
  width: calc(100% - 4rem);
  position: absolute;
  letter-spacing: 0.0625rem;
  z-index: 1;
}
._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem] > [class*=bar],
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem] > [class*=bar] {
  margin-right: 0.5rem;
  height: 1rem;
  border-radius: 624.9375rem;
  overflow: hidden;
}
._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress],
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem] > [class*=bar] > [class*=progress] {
  background: linear-gradient(270deg, rgba(57, 150, 224, 0.6) 0%, rgba(255, 233, 217, 0.4) 90%);
}
._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem] > [class*=valueSection],
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem] > [class*=valueSection] {
  width: 3rem;
}
._audienceLocationBarChart_xicic_165 > [class*=list] > [class*=listItem] > [class*=valueSection] > div,
._audienceAgeBarChart_xicic_166 > [class*=list] > [class*=listItem] > [class*=valueSection] > div {
  width: 100%;
  color: #238add !important;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: right;
}

._tooltip_xicic_306 {
  cursor: help;
}

._Card_xicic_170 {
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 0.5rem;
  break-inside: avoid-column;
  box-sizing: border-box;
}
._Card_xicic_170 ._cardHeader_xicic_320 {
  margin-top: -0.5rem;
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
._Card_xicic_170 ._cardHeader_xicic_320:empty {
  display: none;
  margin: unset;
}
._Card_xicic_170 ._tabs_xicic_331 {
  margin-right: -0.5rem;
  align-self: flex-end;
}
._Card_xicic_170 ._tabs_xicic_331 ._tabButton_xicic_335 {
  min-width: unset;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  letter-spacing: 0.0625rem;
}
._Card_xicic_170 ._tabs_xicic_331 ._tabButton_xicic_335, ._Card_xicic_170 ._tabs_xicic_331 ._tabButton_xicic_335:hover {
  background-color: transparent;
}
._Card_xicic_170 ._tabs_xicic_331 ._tabButton_xicic_335:hover > div {
  color: #3996e0;
}
._Card_xicic_170 ._tabs_xicic_331 ._tabButton_xicic_335 > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: #aeaeae;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._Card_xicic_170 ._tabs_xicic_331 ._tabButton_xicic_335._activeTab_xicic_354 > div {
  color: #3996e0;
}

._separator_xicic_358 {
  margin: 1rem -1rem;
  width: calc(100% + 2rem);
  height: 0.0625rem;
  border-bottom: 0.0625rem solid #eff5f9;
}

._cardTab_xicic_365 {
  display: none;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._cardTitle_xicic_373 {
  margin: 0 auto 0 0;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
}

._visible_xicic_381 {
  display: flex !important;
  animation: _show_xicic_1 0.4s;
}

._noData_xicic_386::after {
  position: absolute;
  top: calc(50% + 0.6666666667rem);
  left: 50%;
  display: block;
  color: #8f8d91;
  content: "No data yet";
  transform: translate(-50%, -50%);
}

._GenderAgeCard_xicic_165 ._gender_xicic_396 {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
}
._GenderAgeCard_xicic_165 ._gender_xicic_396 ._pieChart_xicic_402 {
  margin: auto;
  width: 100%;
}
._GenderAgeCard_xicic_165 ._gender_xicic_396 ._pieChart_xicic_402 > svg {
  width: unset;
}
._GenderAgeCard_xicic_165 ._gender_xicic_396 ._pieChart_xicic_402 [class*=labelItem]:not(:last-child) {
  margin-right: 0.5rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 {
  margin: auto;
  max-width: 20rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165._ageBarChart_xicic_416 {
  margin: unset;
  flex: 1;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._pieChart_xicic_402 {
  display: inline-flex;
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._pieChart_xicic_402:hover {
  transform: scale(1.2);
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 {
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: 0;
  border-collapse: collapse;
  table-layout: fixed;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 thead tr th,
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td {
  padding: 0;
  border: 0;
  height: 1.375rem;
  max-height: 1.375rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0625rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 thead tr th:first-child,
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td:first-child {
  padding-left: 0.5rem;
  text-align: left;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 thead tr th:nth-child(2),
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td:nth-child(2) {
  text-align: center;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 thead tr th:last-child,
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td:last-child {
  padding-right: 0.5rem;
  text-align: right;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 thead tr:last-child {
  height: 1.75rem;
  max-height: 1.75rem;
  line-height: 1.75rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 thead tr:last-child th {
  color: #4f4e51;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody {
  transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr {
  transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td {
  transition: padding 0.1s cubic-bezier(0.4, 0, 0.2, 1), font-size 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td:first-child, ._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td:last-child {
  color: #3996e0;
  font-weight: 600;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td:first-child span {
  min-width: 2.25rem;
  display: inline-block;
  text-align: right;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr td:nth-child(2) {
  color: #4f4e51;
  letter-spacing: 0;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:hover {
  transform: scale(1.1);
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:hover td {
  opacity: 1 !important;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:hover td:first-child {
  padding-left: 1rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:hover td:last-child {
  padding-right: 1rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:nth-child(2n) {
  border-radius: 624.9375rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:nth-child(2n) td {
  background-color: #fdfdfd;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:nth-child(2n+1) {
  border-radius: 624.9375rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:nth-child(2n+1) td {
  background-color: #f6f5f6;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:nth-child(2n+1) td:first-child {
  border-top-left-radius: 624.9375rem;
  border-bottom-left-radius: 624.9375rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody tr:nth-child(2n+1) td:last-child {
  border-top-right-radius: 624.9375rem;
  border-bottom-right-radius: 624.9375rem;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody:hover {
  transform: scale(0.95);
  transform-origin: 50% 50%;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._ageTable_xicic_165 tbody:hover tr td {
  opacity: 0.6;
}
._GenderAgeCard_xicic_165 ._age_xicic_165 ._audienceAgeBarChart_xicic_166 {
  width: 100%;
  height: calc(100% - 0.5rem);
  margin: 0.5rem 0 0;
  flex: 1;
}
._GenderAgeCard_xicic_165._youtube_xicic_535 ._ageTable_xicic_165 thead tr th, ._GenderAgeCard_xicic_165._youtube_xicic_535 ._ageTable_xicic_165 tbody tr td {
  height: 1.625rem;
  max-height: 1.625rem;
  line-height: 1.625rem;
  font-size: 0.75rem;
}
._GenderAgeCard_xicic_165._tiktok_xicic_541 ._ageTable_xicic_165 thead tr th, ._GenderAgeCard_xicic_165._tiktok_xicic_541 ._ageTable_xicic_165 tbody tr td {
  height: 1.25rem;
  max-height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.75rem;
}
._GenderAgeCard_xicic_165._tiktok_xicic_541 ._gender_xicic_396 {
  margin-top: 0.5rem;
}