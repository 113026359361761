import * as React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/react-hooks';
import { message } from 'antd';

import { UPDATE_CONTENT_FIELD_MUTATION } from '@frontend/app/queries';
import {
  UpdateContentFieldMutation,
  UpdateContentFieldMutationVariables,
} from '@frontend/app/queries/types/UpdateContentFieldMutation';

const { useEffect } = React;

type IOptions = MutationHookOptions<UpdateContentFieldMutation, UpdateContentFieldMutationVariables>;

export function useUpdateContentField(options: IOptions = {}) {
  const [updateContentField, { loading, error }] = useMutation<
    UpdateContentFieldMutation,
    UpdateContentFieldMutationVariables
  >(UPDATE_CONTENT_FIELD_MUTATION, {
    ...options,
    onCompleted() {
      message.info('Successfully Updated Content Field');
    },
  });

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return {
    updateContentField,
    loading,
    error,
  };
}
