import { useMutation, MutationHookOptions } from '@apollo/client';

import { SET_DEFAULT_PROGRAM_TEMPLATE_MUTATION } from '@frontend/app/queries';
import {
  SetDefaultProgramTemplateMutation,
  SetDefaultProgramTemplateMutationVariables,
} from '@frontend/app/queries/types/SetDefaultProgramTemplateMutation';

type IOptions = MutationHookOptions<SetDefaultProgramTemplateMutation, SetDefaultProgramTemplateMutationVariables>;

export const useSetDefaultProgramTemplateMutation = (
  options: IOptions = {},
  ) => useMutation<SetDefaultProgramTemplateMutation, SetDefaultProgramTemplateMutationVariables>(SET_DEFAULT_PROGRAM_TEMPLATE_MUTATION, options);
