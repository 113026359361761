import * as React from 'react';
import { Alert, Space } from '@revfluence/fresh';
import { filter } from 'lodash';
import { TriangleExclamationIcon } from '@revfluence/fresh-icons/solid/esm';

import { ResponsiveGrid } from '@components';
import {
  AudienceDemoSection,
  AudienceLocationSection,
  PostTypeSummarySection,
  TMVSection,
  TopCreatorsSection,
  TotalsSection,
  TopPostsSection,
} from '@frontend/components/pages/AnalyzePage/SummaryView/sections/index';
import {
  AudienceDemoSectionData,
  AudienceLocationSectionData,
  TMVSectionData,
  TopCreatorSectionData,
  TopPostsSectionData,
} from '@frontend/applications/SocialPostApp/zeroStateAnalyticsData';
import { IAudienceDemoData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/AudienceDemoSection/AudienceDemoSection';
import { IAudienceLocationData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/AudienceLocationSection/AudienceLocationSection';
import { ISummaryData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/PostTypeSummarySection/PostTypeSummarySection';
import { ITotalsData } from '@frontend/components/pages/AnalyzePage/SummaryView/sections/TotalsSection/TotalsSection';
import { ISocialAccount } from '@frontend/applications/SocialPostApp/useFetchSocialAccountData';
import { ReLinkAccountButton } from '@frontend/app/components';

import styles from '@frontend/components/pages/AnalyzePage/SummaryView/SummaryView.scss';

interface IApiData<T> {
  loading: boolean;
  data?: T;
  error: Error | null;
}

interface IProps {
  isComparing: boolean;
  accounts: ISocialAccount[];
  AudienceDemoDataCompare: IApiData<IAudienceDemoData>;
  AudienceLocationSectionDataCompare: IApiData<IAudienceLocationData>;
  PostTypeSummarySectionDataCompare: IApiData<ISummaryData[]>;
  PostTypeSummarySectionData: IApiData<ISummaryData[]>;
  TotalsSectionDataCompare: IApiData<ITotalsData>;
  TotalsSectionData: IApiData<ITotalsData>;
}

export const ZeroStateAnalytics: React.FunctionComponent<IProps> = (props) => {
  const { accounts } = props;

  const disconnectedAccounts = filter(accounts, (account) => !account.has_social_listening_api_access && account.network_identifier == 'instagram');

  const reLinkSocialAccountCopy = () => {
    if (disconnectedAccounts.length > 1) {
      return (
        <div>
          There was an error fetching information from your Instagram accounts. Please try linking the Facebook Pages that are associated with the Instagram accounts that became unlinked.
        </div>
      );
    }
    return (
      <div>
        {disconnectedAccounts.map((account) => (
          <>
            There was an error fetching information from your @
            {account.username}
            {' '}
            account. Please try
            linking the Facebook Page that is associated with the @
            {account.username}
            {' '}
            Instagram account again.
            Link @
            {account.username}
            .
          </>
        ))}
      </div>
    );
  };

  const pluralAccounts = disconnectedAccounts.length > 1;

  const reLinkSocialAccount = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '51px',
      }}
    >
      <Space
        direction="vertical"
        size="large"
        align="center"
        style={{
          width: '65%',
        }}
      >
        <Alert
          icon={<TriangleExclamationIcon />}
          type="error"
          description={reLinkSocialAccountCopy()}
          action={(
            <ReLinkAccountButton>
              Re-link Account
              {pluralAccounts ? 's' : ''}
            </ReLinkAccountButton>
          )}
        />
      </Space>
    </div>
  );

  return (
    <div>
      {disconnectedAccounts.length > 0 && (
        reLinkSocialAccount
      )}
      <TotalsSection
        className={styles.totalsAndPostTypeSummarySection}
        dataCompare={props.TotalsSectionDataCompare}
        data={props.TotalsSectionData}
        isComparing={props.isComparing}
      />
      <PostTypeSummarySection
        className={styles.totalsAndPostTypeSummarySection}
        data={props.PostTypeSummarySectionData}
        dataCompare={props.PostTypeSummarySectionDataCompare}
        isComparing={props.isComparing}
        renderZeroState
      />
      <ResponsiveGrid className={styles.grid} heights={[420, 420, 420, 420, 420]} itemMinWidth={600}>
        <TMVSection
          data={TMVSectionData}
          className={styles.analyzeDashboardSummary}
        />
        <TopPostsSection
          data={TopPostsSectionData}
          className={styles.analyzeDashboardSummary}
        />
        <TopCreatorsSection
          data={TopCreatorSectionData}
          className={styles.analyzeDashboardSummary}
        />
        <AudienceDemoSection
          className={styles.analyzeDashboardSummary}
          dataCompare={props.AudienceDemoDataCompare}
          data={AudienceDemoSectionData}
          isComparing={props.isComparing}
        />
        <AudienceLocationSection
          className={styles.analyzeDashboardSummary}
          data={AudienceLocationSectionData}
          dataCompare={props.AudienceLocationSectionDataCompare}
          isComparing={props.isComparing}
        />
      </ResponsiveGrid>
    </div>
  );
};
