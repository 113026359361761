/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fxog_157 {
  display: flex;
}

._justify-content-space-between_1fxog_161 {
  justify-content: space-between;
}

._tabular-nums_1fxog_165 {
  font-variant-numeric: tabular-nums;
}

._HistoryBackButton_1fxog_169 ::selection {
  color: #1a1818;
  background-color: #dfe8ef;
}

@keyframes _show_1fxog_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
._HistoryBackButton_1fxog_169 {
  position: relative;
  box-sizing: border-box;
  color: #1f1d1d;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
._HistoryBackButton_1fxog_169 ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
._HistoryBackButton_1fxog_169 ::-webkit-scrollbar-track, ._HistoryBackButton_1fxog_169 ::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
._HistoryBackButton_1fxog_169 ::-webkit-scrollbar-thumb {
  border-radius: 624.9375rem;
  background-color: #c6c6c6;
}

/* Text styles */
._HistoryBackButton_1fxog_169 {
  position: absolute;
  top: -2rem;
  left: 0;
  height: 1.5rem;
}
._HistoryBackButton_1fxog_169 ._backButton_1fxog_210 {
  padding: 0;
  height: 1.5rem;
  color: #fdfdfd;
  border-radius: 0;
}
._HistoryBackButton_1fxog_169 ._backButton_1fxog_210, ._HistoryBackButton_1fxog_169 ._backButton_1fxog_210:hover, ._HistoryBackButton_1fxog_169 ._backButton_1fxog_210:active {
  background: none;
}
._HistoryBackButton_1fxog_169 ._backButton_1fxog_210 > [class*=label] {
  display: flex;
  align-items: center;
}
._HistoryBackButton_1fxog_169 ._backButton_1fxog_210 ._image_1fxog_223 {
  margin: 0 0.5rem;
  border-radius: 50%;
  overflow: hidden;
}