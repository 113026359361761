import { PaymentsProgram } from '@frontend/applications/PaymentsApp/types';
import { ActionType, TState } from '../types';

interface IAction {
  programs: PaymentsProgram[];
  type: ActionType.SET_PROGRAMS;
}
export type TSetProgramsAction = Readonly<IAction>;

export const setPrograms = (state: TState, action: TSetProgramsAction): TState => ({
    ...state,
    programs: action.programs,
  });
