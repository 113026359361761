import * as React from 'react';

import { SvgIcon, ISvgIconProps } from '@components';

const WandIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M13.21,9.56c-0.29-0.29-0.67-0.45-1.08-0.45c-0.41,0-0.79,0.16-1.08,0.45l-8.59,8.59
      C2.16,18.44,2,18.82,2,19.23c0,0.41,0.16,0.79,0.45,1.08l1.32,1.32c0.29,0.29,0.67,0.45,1.08,0.45c0.41,0,0.79-0.16,1.08-0.45
      l8.59-8.59c0.6-0.6,0.6-1.57,0-2.17L13.21,9.56z M5.13,20.83c-0.15,0.15-0.41,0.15-0.56,0l-1.32-1.32
      c-0.07-0.07-0.12-0.17-0.12-0.28c0-0.1,0.04-0.2,0.12-0.28l6.54-6.54l1.87,1.87L5.13,20.83z M13.72,12.24l-1.24,1.24l-1.87-1.87
      l1.24-1.24c0.07-0.07,0.17-0.12,0.28-0.12c0.11,0,0.2,0.04,0.28,0.12l1.32,1.32C13.87,11.83,13.87,12.08,13.72,12.24z"
    />
    <path
      d="M17.74,8.71l0.68,1.6c0.1,0.22,0.41,0.22,0.51,0l0.68-1.6c0.03-0.07,0.08-0.12,0.15-0.15l1.6-0.68
      c0.22-0.1,0.22-0.41,0-0.51l-1.6-0.68c-0.07-0.03-0.12-0.08-0.15-0.15l-0.68-1.6c-0.1-0.22-0.41-0.22-0.51,0l-0.68,1.6
      c-0.03,0.07-0.08,0.12-0.15,0.15l-1.6,0.68c-0.22,0.1-0.22,0.41,0,0.51l1.6,0.68C17.66,8.59,17.71,8.64,17.74,8.71z"
    />
    <path
      d="M6.02,5.03l1.6,0.68c0.07,0.03,0.12,0.08,0.15,0.15l0.68,1.6c0.1,0.22,0.41,0.22,0.51,0l0.68-1.6
      c0.03-0.07,0.08-0.12,0.15-0.15l1.6-0.68c0.22-0.1,0.22-0.41,0-0.51l-1.6-0.68C9.73,3.81,9.67,3.76,9.65,3.69l-0.68-1.6
      c-0.1-0.22-0.41-0.22-0.51,0l-0.68,1.6C7.74,3.76,7.69,3.81,7.63,3.84l-1.6,0.68C5.8,4.62,5.8,4.93,6.02,5.03z"
    />
    <path
      d="M21.83,16.8l-1.6-0.68c-0.07-0.03-0.12-0.08-0.15-0.15l-0.68-1.6c-0.1-0.22-0.41-0.22-0.51,0l-0.68,1.6
      c-0.03,0.07-0.08,0.12-0.15,0.15l-1.6,0.68c-0.22,0.1-0.22,0.41,0,0.51l1.6,0.68c0.07,0.03,0.12,0.08,0.15,0.15l0.68,1.6
      c0.1,0.22,0.41,0.22,0.51,0l0.68-1.6c0.03-0.07,0.08-0.12,0.15-0.15l1.6-0.68C22.06,17.21,22.06,16.89,21.83,16.8z"
    />
  </SvgIcon>
));

WandIcon.displayName = 'WandIcon';

export { WandIcon };
