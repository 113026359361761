import { isArray, isString } from 'lodash';

const MIN_TARGET_LENGTH = 10; // minimum length we will abbreviate
const DEFAULT_TARGET_LENGTH = 35; // default length we will abbreviate
const ELLIPSIS = '...'; // ellipsis string used to indicate truncation

/**
 * Helper function to apply conditional truncation only if the string is
 * actually shorter than the desired length.
 * @param str string The input string.
 * @param length number The desired length.
 * @returns string Returns the truncated string appended with an ellipsis
 *          or the original string if shorter.
 */
const truncate = (str: string, length: number): string => {
  if (str.length <= length) {
    return str;
  }
  return `${str.substr(0, length - ELLIPSIS.length)}${ELLIPSIS}`;
};

/**
 * Abbreviates the target href to the specified length. Inserts ellipsis
 * as placeholders to indicate truncation.
 *
 * The method will always display the affiliate ID (if present).
 * @param href string | null The input href. Can be null.
 * @param targetLength number The target string length (in chars). Defaults to 35.
 * @returns string | null Returns a truncated string normally, but if given
 *          null, it will return null.
 */
export const abbreviateAffiliateLink = (
  href: string | null,
  targetLength: number = DEFAULT_TARGET_LENGTH,
): string | null => {
  if (!isString(href)) {
    return null;
  }
  if (targetLength < MIN_TARGET_LENGTH) {
    return truncate(href, MIN_TARGET_LENGTH);
  }
  // match on "&aff_id=1234"
  const match = /(&aff_id=(\d)+)/g.exec(href);
  if (!isArray(match) || match.length < 1) {
    // affiliate link does not have an affiliate ID
    return truncate(href, targetLength);
  }
  let affiliateId = match[0];
  if (targetLength < affiliateId.length + ELLIPSIS.length) {
    // the target length is shorter than the affiliate ID section
    return truncate(href, targetLength);
  }
  const affiliateIdIndex = href.indexOf(affiliateId);
  // check if anything comes after the affiliate ID in the href
  if (affiliateIdIndex + affiliateId.length < href.length) {
    // append an ellipsis after
    affiliateId = `${affiliateId}${ELLIPSIS}`;
  }
  return `${truncate(href, targetLength - affiliateId.length)}${affiliateId}`;
};
