import * as React from 'react';

import { ITableColumnConfig } from '@components';
import { Tooltip } from '@revfluence/fresh';
import { PaymentStatus } from '../constants';

const statusRender = (data: string) => {
  const tooltip = data === PaymentStatus.PENDING
    ? 'We have emailed the creator to provide PayPal information to send your payment. '
    + 'Payment will be sent automatically once they claim it. No further action is required from you. '
    : null;
  return (
    <Tooltip title={tooltip}>
      {data}
    </Tooltip>
  );
};

const allWorkflowModalColumns: Array<ITableColumnConfig> = [
  {
    headerName: 'Date',
    cellType: 'date',
    field: 'created_date',
    isUnix: true,
    dateFormatStr: 'MMM d, yyyy',
  },
  {
    headerName: 'Projects',
    cellType: 'text',
    field: 'program_names',
  },
  {
    headerName: 'Sent To',
    cellType: 'text',
    field: 'payee_paypal',
  },
  {
    headerName: 'Amount',
    cellType: 'numeric',
    field: 'amount',
    isPrice: true,
  },
  {
    headerName: 'Status',
    field: 'status',
    render: statusRender,
  },
];

const allLegacyModalColumns: Array<ITableColumnConfig> = [
  {
    headerName: 'Date',
    cellType: 'date',
    field: 'created_date',
    isUnix: true,
    dateFormatStr: 'MMM d, yyyy',
  },
  {
    headerName: 'Activations',
    cellType: 'text',
    field: 'activation_names',
  },
  {
    headerName: 'Sent To',
    cellType: 'text',
    field: 'payee_paypal',
  },
  {
    headerName: 'Amount',
    cellType: 'numeric',
    field: 'amount',
    isPrice: true,
  },
  {
    headerName: 'Status',
    field: 'status',
    render: statusRender,
  },
];

export const allModalColumns = (workflowEnabled: boolean) => (workflowEnabled ? allWorkflowModalColumns : allLegacyModalColumns);

export const allDashboardColumns = (workflowEnabled: boolean) => {
  const allDashboardColumns = [...allModalColumns(workflowEnabled)];
  allDashboardColumns.splice(1, 0, {
    headerName: 'Member',
    cellType: 'text',
    field: 'payee_name',
  });
  return allDashboardColumns;
};
