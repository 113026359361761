import * as React from 'react';
import cx from 'classnames';
import * as qs from 'qs';
import {
 groupBy, isEmpty, map, sortBy, isFunction,
} from 'lodash';
import { NavLink } from 'react-router-dom';
import { TagFilled, StarFilled, FlagFilled } from '@ant-design/icons';
import { PersonIcon, BookmarkIcon } from '@components';
import { MemberSearchQuery_members as IMember } from '@frontend/app/queries/types/MemberSearchQuery';

import {
  useParsedRouterSearch,
  useSegmentFoldersQuery,
  usePredefinedSegmentsQuery,
  useFeatureFlagVerbiage,
} from '@frontend/app/hooks';
import { CommunityDropdown, PersonDoneIcon } from '@frontend/app/components';
import { PredefinedSegmentFolderIdentifier } from '@frontend/app/constants';
import { SourcingGroupId } from '@frontend/app/constants/sourcingGroups';

import { SegmentFolder } from './SegmentFolder';

import styles from './SegmentFolderList.scss';

const { useCallback, useMemo } = React;

const iconsForPredefSegments = Object.freeze({
  All: <PersonIcon size={18} className={cx(styles.icon, styles.widgetsIcon)} />,
  Important: <StarFilled className={styles.icon} />,
});

interface IProps {
  communityId?: number;
  sourcingGroupId?: string;
  isContact?: boolean;
  selectedSegmentIdentifier?: string | number;
  className?: string;
  onClick?: () => void;
  setSelectedMembers?(selectedMembers: IMember[]);
}

const excludingSourceGroupIds: string[] = [
  SourcingGroupId.SHOPIFY,
  SourcingGroupId.SOCIAL_LISTENING,
];

export const SegmentFolderList: React.FunctionComponent<IProps> = React.memo(({
  communityId,
  sourcingGroupId,
  isContact,
  selectedSegmentIdentifier,
  className,
  onClick,
  setSelectedMembers,
}) => {
  const searchQuery = useParsedRouterSearch();
  const selectedSegmentId = Number(searchQuery.segmentId) || searchQuery.segmentId;
  const verbiage = useFeatureFlagVerbiage();
  const communityDropdownDisabled = excludingSourceGroupIds.includes(sourcingGroupId);
  const excludedSourceGroupIds = useMemo(
    () =>
      (!excludingSourceGroupIds.includes(sourcingGroupId)
        ? excludingSourceGroupIds
        : []),
    [sourcingGroupId],
  );

  const {
    data: {
      folders = [],
    } = {},
    refetch,
  } = useSegmentFoldersQuery(communityId, sourcingGroupId);

  const {
    data: {
      segments: predefinedSegments = [],
    } = {},
  } = usePredefinedSegmentsQuery({
    communityId,
    sourcingGroupId,
    isContact,
  }, {
    fetchPolicy: 'cache-and-network',
  });

  const sortedFolders = useMemo(() => sortBy(folders, 'title'), [folders]);

  const predefSegmentFolders = useMemo(() => groupBy(predefinedSegments, 'folderId'), [predefinedSegments]);

  const clearSelectedMembers = useCallback((segmentId: string | number) => {
    if (segmentId !== selectedSegmentId && isFunction(setSelectedMembers)) {
      setSelectedMembers([]);
    }
  }, [setSelectedMembers, selectedSegmentId]);

  const newApplicantsFolder = isEmpty(predefSegmentFolders[PredefinedSegmentFolderIdentifier.NEW_APPLICANTS]) ? null : {
    title: 'Waiting for Approval',
    segments: predefSegmentFolders[PredefinedSegmentFolderIdentifier.NEW_APPLICANTS],
  };

  const programsFolder = isEmpty(predefSegmentFolders[PredefinedSegmentFolderIdentifier.PROGRAMS]) ? null : {
    title: verbiage.Programs,
    segments: predefSegmentFolders[PredefinedSegmentFolderIdentifier.PROGRAMS],
  };

  const tagsFolder = isEmpty(predefSegmentFolders[PredefinedSegmentFolderIdentifier.TAGS]) ? null : {
    title: 'Tags',
    segments: predefSegmentFolders[PredefinedSegmentFolderIdentifier.TAGS],
  };

  const highlightsFolder = isEmpty(predefSegmentFolders[PredefinedSegmentFolderIdentifier.HIGHLIGHTS]) ? null : {
    title: 'Recommended',
    segments: predefSegmentFolders[PredefinedSegmentFolderIdentifier.HIGHLIGHTS],
  };

  return (
    <div
      className={cx(styles.SegmentFolderList, className)}
      onClick={onClick}
    >
      <CommunityDropdown
        optionAll
        showSourcingGroups
        excludedSourceGroupIds={excludedSourceGroupIds}
        disabled={communityDropdownDisabled}
      />

      <div className={styles.divider} />

      {map(predefSegmentFolders.null, (predefSegment) => (
        <NavLink
          key={predefSegment.id}
          to={{ pathname: '/member_table', search: qs.stringify({ ...searchQuery, segmentId: predefSegment.id }) }}
          className={styles.segment}
          activeClassName={styles.selected}
          onClick={() => clearSelectedMembers(predefSegment.id)}
          isActive={() => selectedSegmentIdentifier === predefSegment.id}
        >
          {iconsForPredefSegments[predefSegment.title]}
          <span>{predefSegment.title}</span>
          <span className={styles.count}>{predefSegment.memberCount?.toLocaleString?.()}</span>
        </NavLink>
        ))}

      {programsFolder && (
        <SegmentFolder
          communityId={communityId}
          sourcingGroupId={sourcingGroupId}
          folder={programsFolder}
          icon={<FlagFilled className={styles.icon} />}
          className={styles.folder}
          setSelectedMembers={setSelectedMembers}
        />
      )}
      {newApplicantsFolder && (
        <SegmentFolder
          communityId={communityId}
          sourcingGroupId={sourcingGroupId}
          folder={newApplicantsFolder}
          icon={<PersonDoneIcon size={18} className={cx(styles.icon, styles.widgetsIcon)} />}
          className={styles.folder}
          setSelectedMembers={setSelectedMembers}
        />
      )}
      {highlightsFolder && (
        <SegmentFolder
          communityId={communityId}
          sourcingGroupId={sourcingGroupId}
          folder={highlightsFolder}
          icon={<StarFilled className={styles.starIcon} />}
          className={styles.folder}
          setSelectedMembers={setSelectedMembers}
        />
      )}
      {tagsFolder && (
        <SegmentFolder
          communityId={communityId}
          sourcingGroupId={sourcingGroupId}
          folder={tagsFolder}
          icon={<TagFilled className={styles.tagIcon} />}
          className={styles.folder}
          setSelectedMembers={setSelectedMembers}
        />
      )}

      {map(sortedFolders, (folder) => (
        <SegmentFolder
          key={folder.id}
          communityId={communityId}
          sourcingGroupId={sourcingGroupId}
          folder={folder}
          editable
          onDelete={refetch}
          icon={<BookmarkIcon size={18} className={cx(styles.icon, styles.widgetsIcon, styles.bookmarkIcon)} />}
          className={styles.folder}
          setSelectedMembers={setSelectedMembers}
        />
        ))}
    </div>
  );
});
