import * as React from 'react';

import CampaignDetails from './tabs/CampaignDetails';
import EligibilityRestricitions from './tabs/EligibilityRestrictions';

export interface IMarketplaceTab {
  title: string;
  path: string;
  step: number;
  component: React.FC;
  key: string;
  nextTabKey?: string;
  requiredFields?: string[];
  requiredFieldsErrorText?: Record<string, string>;
}

export const MARKETPLACE_TABS: Record<string, IMarketplaceTab> = {
  details: {
    step: 0,
    title: 'Enter Details',
    path: 'campaign-details',
    component: CampaignDetails,
    key: 'details',
    nextTabKey: 'eligibility',
    requiredFields: [
      'name',
      'summary',
      'product_types',
      'splash_image_url',
    ],
    requiredFieldsErrorText: {
      splash_image_url: 'Please upload the image you would like to show in the Aspire Marketplace',
      name: 'Please enter the Marketplace Listing Name',
      summary: 'Please enter the Project Blurb',
      product_types: 'Please select at least one piece of content you want creators to make',
    },
  },
  eligibility: {
    step: 1,
    title: 'Set Creator Eligibility',
    path: 'eligibility-restrictions',
    key: 'eligibility',
    component: EligibilityRestricitions,
    requiredFields: [],
  },
};
