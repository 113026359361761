import * as React from 'react';
import { map } from 'lodash';

import { Space, Typography } from '@revfluence/fresh';

import { MemberFieldSchemasBySectionsQuery_sections_memberData } from '@frontend/app/queries/types/MemberFieldSchemasBySectionsQuery';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { FieldRow } from '../FieldRow';

import styles from './CustomData.scss';

const { Text } = Typography;

type MemberFieldSchema = MemberFieldSchemasBySectionsQuery_sections_memberData;

interface Props {
  member?: MemberInput;
  schemas?: MemberFieldSchema[];
  onSave: (value: MemberInput) => Promise<void>;
}

const CustomData: React.FunctionComponent<Props> = (props) => {
  const { member, schemas = [], onSave } = props;

  if (!member) {
    return null;
  }

  return (
    <Space direction="vertical">
      {map(schemas, (field) => (
        <FieldRow
          key={field.id}
          label={field.name}
          value={member.fields[field.id]}
          editable
          type={field.type}
          choices={field.choices}
          onSave={(val) => onSave({ fields: { [field.id]: val } })}
        />
      ))}
      {!schemas?.length && (
        <div className={styles.noFields}>
          <Text type="secondary">No custom data fields.</Text>
        </div>
      )}
    </Space>
  );
};

export default CustomData;
