import * as React from 'react';
import cx from 'classnames';
import { first, map } from 'lodash';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import { Typography } from 'antd';
import { UserAvatar } from '@frontend/app/components';

const { Text } = Typography;
import {
  LazyImage, Button,
} from '@components';

import { formatMessageDate } from '@frontend/app/utils';

import { useAuth } from '@frontend/context/authContext';
import { GetThreadQuery_thread_messages as IMessage } from '@frontend/app/queries/types/GetThreadQuery';

import { useReactiveVar } from '@apollo/client';
import { expandedMessagesVar } from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import styles from './SystemMessageItem.scss';

const { useMemo } = React;
interface IProps {
  message: IMessage;
  className?: string;
  onToggleMessageCollapsed?: (isCollapsed: boolean, messageId: string) => void;
}

/**
 * @type {React.FunctionComponent}
 */
/**
 * @type {React.FunctionComponent}
 */
export const SystemMessageItem: React.FunctionComponent<IProps> = React.memo((props) => {
  const { message, onToggleMessageCollapsed } = props;
  const { user } = useAuth();
  const expandedMessages = useReactiveVar(expandedMessagesVar);
  const collapsed = !expandedMessages.includes(message.id);

  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const from: {
    name: string;
    email: string;
    memberId?: number;
  } = useMemo(() => {
    if (message.payload.from && message.payload.from === user.email) {
      return {
        name: user.name || user.nickname,
        email: user.email,
      };
    }

    const member = first(message.members);

    if (member) {
      return {
        name: member.name,
        email: member.email,
        memberId: member.id,
      };
    }

    return {
      name: message.payload.from,
      email: message.payload.from,
    };
  }, [user, message]);

  const toggleCollapsed = () => {
    onToggleMessageCollapsed(collapsed, message.id);
  };

  const handleAction = async (action) => {
    history.push({
      ...location,
      pathname: `${match.url}/app/${message.applicationId}`,
      search: `link_params=${JSON.stringify(action.parameters)}`,
    });
  };

  return (
    <div
      className={cx(styles.SystemMessageItem, props.className, {
        [styles.collapsed]: collapsed,
      })}
    >
      <div
        className={styles.header}
        onClick={toggleCollapsed}
      >
        {from.name && <UserAvatar className={styles.avatar} name={from.name} size={32} />}
        <Text ellipsis className={styles.subject}>{message.subject || message.title}</Text>
        <div className={styles.date}>{formatMessageDate(message.internalDate)}</div>
      </div>
      <div
        className={cx(styles.messageDetail, {
          [styles.collapsed]: collapsed,
        })}
      >
        <div className={styles.content}>
          <div
            className={styles.message}
            dangerouslySetInnerHTML={{
              __html: message.payload.textHtml || unescape(message.payload.textPlain),
            }}
          />
          <div className={styles.attachements}>
            {map(message.attachments, (attachment, index) => (
              <a className={styles.attachment} href={attachment.href} key={index}>
                {attachment.filename}
              </a>
            ))}
          </div>
          <div>
            {map(message.payload.imageURLs, (imageUrl, index) => (
              <LazyImage
                key={index}
                className={styles.image}
                src={imageUrl}
              />
            ))}
          </div>
          <div>
            {map(message.payload.messageActions, (action, index) => (
              <React.Fragment key={index}>
                {action.actionType === 'openUrl' ? (
                  <a href={action.parameters.url} className={styles.action} target="_blank">
                    <Button
                      className={styles.action}
                      label={action.actionText}
                      theme={index === 0 ? 'primary' : 'light'}
                    />
                  </a>
                ) : (
                  <Button
                    className={styles.action}
                    label={action.actionText}
                    theme={index === 0 ? 'primary' : 'light'}
                    onClick={() => handleAction(action)}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

SystemMessageItem.displayName = 'SystemMessageItem';
