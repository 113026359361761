import gql from 'graphql-tag';

export const TASK_FRAGMENT = gql`
  fragment TaskFragment on Task {
    taskId
    taskName
    workletSpecKey
    workletSpecUri
    workletSpecType
    customServiceId
    taskMetaData {
      bulkCTADeepLink
      bulkCTAText
      serviceId
      singleCTADeepLink
      singleCTAText
      taskUI
    }
  }
`;
