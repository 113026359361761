import * as React from 'react';
import cx from 'classnames';

import { Deactivate as DeactivateIcon } from '@affiliates/AspireUI';

import styles from './MembersWizardIcon.scss';

export const DeactivateMembers: React.FC = React.memo(() => {
  const className = cx(styles.MembersWizardIcon, styles.deactivateMembers);
  return (
    <div className={className}>
      <DeactivateIcon />
    </div>
  );
});

DeactivateMembers.displayName = 'DeactivateMembersIcon';
