const masterCardIcon = require('@frontend/app/assets/images/mastercard-card-icon@2x.png');
const visaIcon = require('@frontend/app/assets/images/visa-card-icon@2x.png');
const amexIcon = require('@frontend/app/assets/images/american-express-card-icon@2x.png');
const discoverIcon = require('@frontend/app/assets/images/discover-card-icon.png');

export const getCardIcon = (card: string) => {
    switch (card) {
        case 'Visa':
            return visaIcon;
        case 'MasterCard':
            return masterCardIcon;
        case 'AmericanExpress':
            return amexIcon;
        case 'Discover':
            return discoverIcon;
        default:
            return '';
    }
};
