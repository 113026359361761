import 'css-chunk:src/components/widgets/charts/BarChart/BarChartHorizontal.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1yzda_157",
  "justify-content-space-between": "_justify-content-space-between_1yzda_161",
  "tabular-nums": "_tabular-nums_1yzda_165",
  "BarChartHorizontal": "_BarChartHorizontal_1yzda_169",
  "tooltipBody": "_tooltipBody_1yzda_235",
  "tooltipRow": "_tooltipRow_1yzda_243",
  "tooltipNumber": "_tooltipNumber_1yzda_247",
  "list": "_list_1yzda_251",
  "listItem": "_listItem_1yzda_255",
  "label": "_label_1yzda_261",
  "bar": "_bar_1yzda_270",
  "progress": "_progress_1yzda_277",
  "valueSection": "_valueSection_1yzda_283",
  "value": "_value_1yzda_283",
  "percentage": "_percentage_1yzda_299",
  "show": "_show_1yzda_1"
};