import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import {
 map, findIndex, first, concat, each, orderBy,
} from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Select, IOption } from '@components';

import {
  ALL_MEMBERS_COMMUNITY_ID, MY_FAVORITES_TITLE,
} from '@frontend/app/constants';
import { sourcingGroups as allSourcingGroups } from '@frontend/app/constants/sourcingGroups';
import {
  useCommunitySwitcherContext,
  useCommunitiesQuery,
  useIsSourceGroupEnabled,
  useFeatureFlagVerbiage,
} from '@frontend/app/hooks';
import { GetCommunitiesQuery_communities as ICommunity } from '@frontend/app/queries/types/GetCommunitiesQuery';
import { ALL_CONTACTS_IMAGE_URL } from '@frontend/app/constants/imageUrls';

import { CommunitySelectItem } from './CommunitySelectItem';
import { CommunityDropdownLabel } from './CommunityDropdownLabel';

import styles from './CommunityDropdown.scss';

const { useMemo, useEffect, useCallback } = React;

const ALL_MEMBERS: ICommunity = {
  __typename: 'Community',
  id: ALL_MEMBERS_COMMUNITY_ID,
  title: 'All Contacts',
  description: 'All Contacts',
  splashImageUrl: ALL_CONTACTS_IMAGE_URL,
  memberCount: 0,
  clientIds: [],
  createdDate: null,
  updatedDate: null,
  showApplicantReview: false,
};

interface IProps {
  optionAll?: boolean;
  useQueryParam?: boolean;
  showSourcingGroups?: boolean;
  disabled?: boolean;
  excludedSourceGroupIds?: string[];
  hideSelectedTitle?: boolean;
}

interface IOptionValue {
  sourcingId?: string;
  communityId?: number;
  imageUrl?: string;
  title?: string;
}

export const CommunityDropdown: React.FC<IProps> = React.memo((props) => {
  const history = useHistory();
  const {
    loading,
    data: {
      communities = null,
    } = {},
  } = useCommunitiesQuery();

  const verbiage = useFeatureFlagVerbiage();

  const orderedCommunities = orderBy(communities, [
    (community) => (
      community.title === MY_FAVORITES_TITLE && -1
    ),
  'title']);

  const {
    selectedCommunityId,
    selectedSourcingId,
    setSelectedCommunityId,
    setSelectedSourcingId,
  } = useCommunitySwitcherContext();

  const allCommunities = useMemo(() => {
    if (!props.optionAll) {
      return orderedCommunities;
    }

    return [
      ALL_MEMBERS,
      ...(orderedCommunities || []),
    ];
  }, [orderedCommunities, props.optionAll]);

  const allSourcingGroupIds = useMemo(() => map(allSourcingGroups, (group) => group.id), []);

  const {
    enabled: areSourcingGroupsEnabled,
    loading: loadingSourcingGroupEnabled,
  } = useIsSourceGroupEnabled(allSourcingGroupIds, {
    skip: !props.showSourcingGroups,
  });

  const sourcingGroups = useMemo(() => {
    // wait for check whether sourcing groups are enabled.
    // also wait for communities to load before showing sources
    if (loadingSourcingGroupEnabled || !props.showSourcingGroups || !communities) {
      return [];
    }

    // only show sourcing groups which are enabled
    const enabledSourcingGroups = [];
    each(allSourcingGroups, (group, idx) => {
      if (areSourcingGroupsEnabled && areSourcingGroupsEnabled[idx] && !props.excludedSourceGroupIds?.includes(group?.id)) {
        enabledSourcingGroups.push(group);
      }
    });

    return enabledSourcingGroups;
  }, [
    props.showSourcingGroups,
    props.excludedSourceGroupIds,
    communities,
    loadingSourcingGroupEnabled,
    areSourcingGroupsEnabled,
  ]);

  const options = useMemo<IOption[]>(() => {
    const sourcingGroupOptions: IOption[] = map(sourcingGroups, (sourcingGroup) => ({
        label: (
          <CommunitySelectItem
            title={sourcingGroup.title}
            imageUrl={sourcingGroup.imageUrl}
          />
        ),
        value: {
          communityId: -1,
          sourcingId: sourcingGroup.id,
          imageUrl: sourcingGroup.imageUrl,
          title: sourcingGroup.title,
        } as IOptionValue,
        onSelect: undefined,
        optionClass: undefined,
      }));

    const communityOptions: IOption[] = map(allCommunities, (community) => ({
        label: (
          <CommunitySelectItem
            title={community.title}
            imageUrl={community.splashImageUrl}
          />
        ),
        value: {
          sourcingId: null,
          communityId: community.id,
          imageUrl: community.splashImageUrl,
          title: community.title,
        } as IOptionValue,
        onSelect: undefined,
        optionClass: undefined,
      }));

    const addNewGroupOption: IOption = {
      label: (
        <CommunitySelectItem
          title={`Create new ${verbiage.community}`}
          icon={<PlusOutlined size={24} />}
        />
      ),
      value: {
        communityId: -1,
      },
      onSelect: () => history.push('/communities/new'),
      optionClass: styles.isAddNew,
    };

    return concat(communityOptions, sourcingGroupOptions, addNewGroupOption);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourcingGroups, allCommunities, history]);

  const selectedIndex = useMemo(() => {
    if (selectedSourcingId) {
      const idx = findIndex(options, (option) => option.value.sourcingId === selectedSourcingId);
      if (idx >= 0) {
        return idx;
      }
    }
    return findIndex(options, (option) => option.value.communityId === selectedCommunityId);
  }, [selectedCommunityId, selectedSourcingId, options]);

  const handleChange = useCallback((value: IOptionValue) => {
    if (value.sourcingId) {
      setSelectedCommunityId(null);
      setSelectedSourcingId(value.sourcingId);
    } else {
      setSelectedSourcingId(null);
      setSelectedCommunityId(value.communityId);
    }
  }, [setSelectedSourcingId, setSelectedCommunityId]);

  useEffect(() => {
    // making sure communities are loaded
    if (communities && selectedIndex < 0) {
      const community = first(allCommunities);
      if (community) {
        setSelectedSourcingId(null);
        setSelectedCommunityId(community.id);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCommunities, communities, selectedIndex]);

  const labelElement = useMemo(() => {
    if (selectedIndex < 0) {
      return null;
    }

    const { value } = options[selectedIndex];

    return (
      <CommunityDropdownLabel
        imageUrl={value.imageUrl}
        title={!props.hideSelectedTitle ? value.title : undefined}
        disabled={props.disabled}
      />
    );
  }, [selectedIndex, options, props.hideSelectedTitle, props.disabled]);

  if (loading) {
    return null;
  }

  return (
    <Select
      theme="info"
      className={cx(styles.CommunityDropdown, { [styles.disabled]: props.disabled })}
      options={options}
      onChange={handleChange}
      selectedIndex={selectedIndex}
      popoverProps={{
        minWidth: 240,
        maxWidth: 240,
        anchorOrigin: 'start',
        arrowPosition: 'start',
      }}
      round
      customLabelElement={labelElement}
      disabled={props.disabled}
    />
  );
});

CommunityDropdown.defaultProps = {
  optionAll: false,
  showSourcingGroups: false,
};
