/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@components';
import { useGetInstalledApplicationIds } from '@frontend/app/hooks';
import { GMAIL_APP_ID, OUTLOOK_APP_ID } from '@frontend/app/constants/applicationIds';

import styles from './EmptyEmailResources.scss';

const GOOGLE_STORAGE_URI = 'https://storage.googleapis.com/aspirex-static.aspireiq.com';
const manEquipmentIcon = `${GOOGLE_STORAGE_URI}/app/public/images/2c89c9d8f87c9093f2947991a915df23.svg`;

const EmptyEmailResources = (): React.ReactElement => {
  const installedApps = useGetInstalledApplicationIds();
  const history = useHistory();
  const header = 'Missing Setup';
  const message = 'Before we can send briefs out via email,'
  + ' we need to connect a valid email address to your Aspire account.';

    return (
      <div className={styles.EmptyEmailResources}>
        <img src={manEquipmentIcon} alt="No email resources" />
        <div className={styles.header}>{header}</div>
        <div className={styles.message}>{message}</div>
        <div className={styles.actions}>
          { installedApps[GMAIL_APP_ID] && (
            <Button
              label="Connect your Gmail account"
              onClick={() => history.push(`/settings/${encodeURIComponent(GMAIL_APP_ID)}`)}
              theme="primary"
            />
          )}
          {installedApps[OUTLOOK_APP_ID] && (
            <Button
              label="Connect your Outlook account"
              onClick={() => history.push(`/settings/${encodeURIComponent(OUTLOOK_APP_ID)}`)}
              theme="primary"
            />
          )}
        </div>
      </div>
    );
};

export default EmptyEmailResources;
