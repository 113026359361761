import * as React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import cx from 'classnames';

import styles from './Notice.scss';

type INoticeType = 'info' | 'alert' | 'error';

interface IProps {
  actions?: React.ReactElement;
  className?: string;
  message: React.ReactNode;
  type: INoticeType;
  messageClassName?: string;
}

export const Notice: React.FC<Readonly<IProps>> = (props) => {
  const {
    actions,
    className,
    message,
    type,
    messageClassName,
  } = props;
  return (
    <div className={cx(styles.Notice, styles[type], className)}>
      <div className={styles.iconWrapper}>
        <ExclamationCircleFilled />
      </div>
      <div className={cx(styles.messageWrapper, messageClassName)}>
        {message}
      </div>
      {actions}
    </div>
  );
};
