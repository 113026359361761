import * as React from 'react';
import { InstagramIcon } from '@revfluence/fresh-icons/brands/esm';
import { Link } from 'react-router-dom';
import { GetResourcesQuery_resources } from '@frontend/app/queries/types/GetResourcesQuery';
import { Tooltip } from '@revfluence/fresh';
import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import styles from './InvitesResourcesPicker.scss';
import { CircleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { invalidIGDMMessage } from '@frontend/app/utils/igdm';

const { useMemo } = React;

interface InvitesResourcesPickerIGDMProps {
  igResource: GetResourcesQuery_resources;
}

export const InvitesResourcesPickerIGDM: React.FC<InvitesResourcesPickerIGDMProps> = ({
  igResource,
}) => {
  const igdmErrorMessage = useMemo(() => {
    return invalidIGDMMessage(igResource);
  }, [igResource])

  return (
    <div className={styles.resourceRow}>
      <div className={styles.resourceIcon}>
        <InstagramIcon />
      </div>
      <div className={styles.resourceContent}>
        {
          !igResource
            ? (
              <Tooltip
                placement="right"
                title={igdmErrorMessage}
              >
                <Link to={`/settings/${SOCIAL_POST_APP_ID}`}>
                  Connect an Instagram Account
                </Link>
              </Tooltip>
            )
            : igdmErrorMessage === '' ? (
              <span className={styles.resourceLabel}>
                {`@${igResource.authProvider.userExternalDisplayId}`}
              </span>
            )
              :
              (
                <Tooltip
                  placement="right"
                  title={igdmErrorMessage}
                >
                  <div className={styles.invalidResource}>
                    <span className={styles.invalidResourceLabel}>
                      <CircleExclamationIcon /> {`@${igResource.authProvider.userExternalDisplayId}`}
                    </span>
                  </div>
                </Tooltip>
              )
        }
      </div>
    </div>
  )
}
