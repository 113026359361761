import gql from 'graphql-tag';

import { MEMBER_FIELD_SCHEMA_FRAGMENT } from '@frontend/app/queries/fragments';

export const MEMBER_FIELD_SCHEMAS_QUERY = gql`
  query MemberFieldSchemasQuery {
    schemas: memberFieldSchemas {
      ...MemberFieldSchemaFragment
    }
  }
  ${MEMBER_FIELD_SCHEMA_FRAGMENT}
`;
