import * as React from 'react';
import cx from 'classnames';
import { keyBy } from 'lodash';
import { Tooltip } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
} from '@components';
import { useApplicationNavigation, useFeatureFlagVerbiage } from '@frontend/app/hooks';
import { sourcingGroups } from '@frontend/app/constants/sourcingGroups';

import {
  useMemberPageContext,
} from '@frontend/app/containers/Members/hooks';

import { Settings } from 'lucide-react';
import { Button as ShadCnBtn } from '@frontend/shadcn/components/ui/button';
import styles from './SettingsButton.scss';

const { useCallback, useMemo } = React;

interface IProps {
  className?: string;
  refreshUi?: boolean;
}

export const SettingsButton: React.FC<IProps> = React.memo((props) => {
  const history = useHistory();
  const {
    communityId,
    sourcingGroupId,
  } = useMemberPageContext();

  const {
    goTo: navigateTo,
  } = useApplicationNavigation();

  const verbiage = useFeatureFlagVerbiage();

  const sourcingGroupsById = useMemo(() => keyBy(sourcingGroups, (group) => group.id), []);

  const sourcingGroup = useMemo(() => sourcingGroupsById[sourcingGroupId], [sourcingGroupsById, sourcingGroupId]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (sourcingGroup) {
      navigateTo(sourcingGroup.appId, '/settings');
    } else if (communityId) {
      history.push({
        pathname: `/communities/${communityId}/edit`,
      });
    } else {
      history.push({
        pathname: '/communities/all_contacts/settings',
      });
    }
  }, [history, navigateTo, communityId, sourcingGroup]);

  const title = useMemo(() => {
    if (sourcingGroupId) {
      return `${sourcingGroup?.title} Settings`;
    } else if (communityId) {
      return `${verbiage.Community} Settings`;
    } else {
      return 'All Contacts Settings';
    }
  }, [communityId, sourcingGroupId, sourcingGroup, verbiage]);

  return (
    <Tooltip title={title} placement="bottomRight">
      {props.refreshUi ? (
        <ShadCnBtn
          onClick={handleClick}
          variant="outlineHeader"
          size="headerIcon"
        >
          <Settings className="h-4 w-4" />
        </ShadCnBtn>
      ) : (
        <IconButton
          icon={<SettingFilled />}
          className={cx(props.className, styles.SettingsButton)}
          onClick={handleClick}
          round={false}
          theme="info"
        />
      )}
    </Tooltip>
  );
});

SettingsButton.displayName = 'SettingsButton';
