/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_14ru3_157 {
  display: flex;
}

._justify-content-space-between_14ru3_161 {
  justify-content: space-between;
}

._tabular-nums_14ru3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_14ru3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._HeaderRow_14ru3_178 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  height: 2.625rem;
  border-bottom: 0.0625rem solid #e6edf2;
}
._HeaderRow_14ru3_178 ._checkboxWrapper_14ru3_185 {
  z-index: 1;
  flex-shrink: 0;
  flex-basis: 3.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fdfdfd;
  border-right: 0.0625rem solid #e6edf2;
}
._HeaderRow_14ru3_178 ._cellContainer_14ru3_196 {
  flex: 1;
  display: flex;
  flex-direction: row;
  will-change: transform;
  transition: transform 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._HeaderRow_14ru3_178 ._cellContainer_14ru3_196 ._droppableContainer_14ru3_203 {
  flex: 1;
  display: flex;
  flex-direction: row;
}