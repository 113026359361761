import * as React from 'react';
import cx from 'classnames';
import { isUndefined } from 'lodash';

import { LayoutListIcon, IconButton, KeyboardArrowLeftIcon } from '@components';

import styles from './Drawer.scss';

const { useState } = React;

interface IProps {
  className?: string;
  defaultExpanded?: boolean;
  expanded?: boolean;
  hideToggle?: boolean;
  onToggle?(isOpen: boolean);
  openWidth?: number;
}

export const Drawer: React.FunctionComponent<IProps> = React.memo(({
  children,
  className,
  defaultExpanded,
  expanded: expandedProp,
  hideToggle = false,
  onToggle,
  openWidth,
}) => {
  const [expandedState, setExpandedState] = useState<boolean>(defaultExpanded);
  const isControlled = !isUndefined(expandedProp);

  const expanded = isControlled ? expandedProp : expandedState;

  const handleToggleExpand = () => {
    if (!isControlled) {
      setExpandedState((state) => !state);
    }

    if (onToggle) {
      onToggle(!expanded);
    }
  };

  const style = expanded ? { marginLeft: `${openWidth}px` } : null;

  return (
    <div className={cx(styles.Drawer, className)} style={style}>
      {!hideToggle && (
        <IconButton
          className={styles.collapseBtn}
          icon={
            expanded
              ? <KeyboardArrowLeftIcon size={14} />
              : <LayoutListIcon size={14} />
          }
          onClick={handleToggleExpand}
        />
      )}
      {children}
    </div>
  );
});

Drawer.defaultProps = {
  openWidth: 240,
  defaultExpanded: false,
};
