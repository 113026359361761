import * as React from 'react';
import { SvgIcon, ISvgIconProps } from '@components';

export const FourSquaresIcon = React.memo((props: ISvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 12 12">
    <path d="M1.32508 1.01687C1.1964 1.01687 1.11062 1.10265 1.11062 1.23133V4.148C1.11062 4.27667 1.1964 4.36246 1.32508 4.36246H4.24175C4.37042 4.36246 4.45621 4.27667 4.45621 4.148V1.23133C4.45621 1.10265 4.37042 1.01687 4.24175 1.01687H1.32508ZM1.32508 0.0732422H4.24175C4.88513 0.0732422 5.39984 0.587948 5.39984 1.23133V4.148C5.39984 4.79138 4.88513 5.30609 4.24175 5.30609H1.32508C0.681698 5.30609 0.166992 4.79138 0.166992 4.148V1.23133C0.166992 0.587948 0.681698 0.0732422 1.32508 0.0732422ZM1.32508 7.4078C1.1964 7.4078 1.11062 7.49359 1.11062 7.62226V10.5389C1.11062 10.6676 1.1964 10.7534 1.32508 10.7534H4.24175C4.37042 10.7534 4.45621 10.6676 4.45621 10.5389V7.62226C4.45621 7.49359 4.37042 7.4078 4.24175 7.4078H1.32508ZM1.32508 6.50707H4.24175C4.88513 6.50707 5.39984 7.02177 5.39984 7.66515V10.5818C5.39984 11.2252 4.88513 11.7399 4.24175 11.7399H1.32508C0.681698 11.7399 0.166992 11.2252 0.166992 10.5818V7.66515C0.166992 7.02177 0.681698 6.50707 1.32508 6.50707ZM7.7589 1.01687C7.63023 1.01687 7.54444 1.10265 7.54444 1.23133V4.148C7.54444 4.27667 7.63023 4.36246 7.7589 4.36246H10.6756C10.8042 4.36246 10.89 4.27667 10.89 4.148V1.23133C10.89 1.10265 10.8042 1.01687 10.6756 1.01687H7.7589ZM7.7589 0.0732422H10.6756C11.319 0.0732422 11.8337 0.587948 11.8337 1.23133V4.148C11.8337 4.79138 11.319 5.30609 10.6756 5.30609H7.7589C7.11552 5.30609 6.60082 4.79138 6.60082 4.148V1.23133C6.60082 0.587948 7.11552 0.0732422 7.7589 0.0732422ZM7.7589 7.4078C7.63023 7.4078 7.54444 7.49359 7.54444 7.62226V10.5389C7.54444 10.6676 7.63023 10.7534 7.7589 10.7534H10.6756C10.8042 10.7534 10.89 10.6676 10.89 10.5389V7.62226C10.89 7.49359 10.8042 7.4078 10.6756 7.4078H7.7589ZM7.7589 6.50707H10.6756C11.319 6.50707 11.8337 7.02177 11.8337 7.66515V10.5818C11.8337 11.2252 11.319 11.7399 10.6756 11.7399H7.7589C7.11552 11.7399 6.60082 11.2252 6.60082 10.5818V7.66515C6.60082 7.02177 7.11552 6.50707 7.7589 6.50707Z" fill="#1A1818" />
  </SvgIcon>
));

FourSquaresIcon.displayName = 'FourSquaresIcon';
