import * as React from 'react';
import {
  every,
  isEmpty,
  isNull,
  isUndefined,
  some,
} from 'lodash';

import { Notice } from '@affiliates/AspireUI';
import { isBefore } from 'date-fns';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { MAX_ALLOWED_MEMBERS } from '@frontend/applications/AffiliatesApp/utils';
import { IAddPromoCodeMembers } from '../../types';
import { TActions, TState } from './state';
import { getSteps } from './getSteps';

import styles from '../../components/MembersWizard.scss';

export const getWorkflowPromoCodeSteps = (props: IAddPromoCodeMembers, state: TState, actions: TActions) => {
  const {
    onClose,
    offerName,
    isUngrouped,
    offerSource,
  } = props;

  const {
    selectMembers,
    selectActiveDates,
    selectPayoutAndDate,
    reviewMembers,
    completed,
    selectedMembers,
    forceCheckInMembers,
    allMembers,
  } = getSteps(props, state, actions);

  const isOnlyForceCheckIn = every(allMembers, 'forceCheckIn');
  if (isOnlyForceCheckIn) {
    const forceCheckInSteps = [
      selectMembers({
        instructions: {
          description: 'By clicking "Move to next step", they will move forward in your workflow to the correct step.',
          title: 'These members already exist in your offer',
        },
        nextButtonConfig: {
          className: styles.wizardNextButton,
          text: 'Move to next step',
          disabled: isNull(state.members),
          action: () => {
            actions.save(1);
            onClose();
          },
        },
        previousButtonConfig: {
          action: onClose,
          className: styles.wizardButton,
          showIcon: false,
        },
        stepNum: 1,
      }),
      completed({
        instructions: {
          description: `
          Once you close this drawer, these members should be in the correct workflow step.
         `.trim(),
          title: `
          You have moved forward ${`${forceCheckInMembers.length.toLocaleString()} `}
          member${forceCheckInMembers.length !== 1 ? 's' : ''} to their next step.
        `.trim(),
        },
        nextButtonConfig: {
          action: onClose,
          className: styles.wizardNextButton,
          disabled: false,
          showIcon: false,
          text: 'Done',
        },
        previousButtonConfig: {
          className: `${styles.wizardButton} ${styles.hidden}`,
          disabled: true,
          text: 'Confirm Members',
        },
        stepNum: 2,
      }),
    ];
    if (state.error) {
      return forceCheckInSteps.map((step) => ({
        ...step,
        actionComponents: (
          <Notice type="error" message={state.error.message} />
        ),
      }));
    }
    return forceCheckInSteps;
  }

  let nextButtonDisabled = true;
  if (!isNull(state.members) && !isEmpty(selectedMembers)) {
    nextButtonDisabled = some(selectedMembers, (m) => m.code.length < 1 || m.isDuplicate);
  }
  const selectMemberDisabled = isEmpty(selectedMembers);
  const reviewButtonDisabled = isUndefined(state.activeDates.startDate)
    || isUndefined(state.activeDates.startTime)
    || isUndefined(state.activeDates.endDate)
    || isUndefined(state.activeDates.endTime);

  const commonCompletedStep = (stepNum) => ({
    instructions: {
      description: selectedMembers.length > 500
        ? ''
        : 'Their unique codes are starting to be generated, it should only take a few minutes. You can close this window. Once all codes have been generated, you can email these members their codes using the “Email Members” button above the table on this offer page.',
      title: selectedMembers.length > 500
        ? ''
        : `Congrats! You are generating promo codes for ${selectedMembers.length.toLocaleString()} member${selectedMembers.length !== 1 ? 's' : ''}.`,
    },
    nextButtonConfig: {
      action: onClose,
      className: styles.wizardNextButton,
      disabled: false,
      showIcon: false,
      text: 'Done',
    },
    previousButtonConfig: {
      className: `${styles.wizardButton} ${styles.hidden}`,
      disabled: true,
      text: 'Review Members',
    },
    stepNum,
  });
  const isUngroupedMemberCorrect = isUngrouped ? !!selectedMembers?.filter((m) => !m.startDate || (m.startDate && m.endDate && isBefore(m.endDate, m.startDate))).length : false;
  const newSteps = [
    selectMembers({
      instructions: {
        description: 'Select the members who will receive the codes for this promotion.',
        title: 'Who will receive these codes for this offer',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: selectMemberDisabled || (offerSource === OFFER_SOURCE.SHOPIFY ? selectedMembers.length > MAX_ALLOWED_MEMBERS : false),
        text: 'Select Members',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    reviewMembers({
      instructions: {
        description: state.saving ? '' : 'Select the members who will receive the codes for this promotion.',
        title: state.saving ? '' : 'Who will receive these codes for this offer',
      },
      nextButtonConfig: {
        action: () => actions.save(4),
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled || isUngroupedMemberCorrect,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Back',
      },
      stepNum: 3,
    }),
    completed(commonCompletedStep(4)),
    completed(commonCompletedStep(5)),
    completed(commonCompletedStep(6)),
  ];
  if (isUngrouped) {
    let disableButtonForUngrouping = false;
    disableButtonForUngrouping = state.showEndDate && !state.bulkEndDate;
    if (state.showEndDate) {
      if (!state.bulkEndDate) disableButtonForUngrouping = true;
      if (state.showEndDate && isBefore(state.bulkEndDate, new Date())) {
        disableButtonForUngrouping = true;
      }
      if (isBefore(state.bulkEndDate, state.bulkStartDate)) {
        disableButtonForUngrouping = true;
      }
    }

    newSteps.splice(1, 0, selectPayoutAndDate({
      instructions: {
        description: 'Promo codes will be generated based on the naming strategy of the offers. The default payout option and active dates will be applied to all the members. You can also customize the promo code name, payout and code active/end date manually for each of them by clicking on Customize Per Member button.',
        title: 'Configure Payout and active dates for all selected members.',
      },
      nextButtonConfig: {
        action: () => {
          if (nextButtonDisabled) {
            actions.setCurrentStep(3);
          } else {
            actions.save(4);
          }
        },
        className: styles.wizardNextButton,
        disabled: selectMemberDisabled || !state.bulkStartDate || disableButtonForUngrouping,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        text: 'Back',
      },
      actionButtonConfig: {
        action: () => actions.setCurrentStep(3),
        className: styles.wizardButton,
        disabled: !state.bulkStartDate || disableButtonForUngrouping,
        text: 'Customize Per Member',
      },
      stepNum: 2,
    }));
  }

  const steps = [
    selectMembers({
      instructions: {
        description: 'These are the members who will be receiving codes for this promotion.',
        title: `Who will receive these codes for ${offerName}?`,
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: nextButtonDisabled,
        text: 'Set Active Dates',
      },
      previousButtonConfig: {
        action: onClose,
        className: styles.wizardButton,
        showIcon: false,
      },
      stepNum: 1,
    }),
    selectActiveDates({
      instructions: {
        description: 'These dates are for this set of members. You will be able to change the dates for new members you give codes to or when you replace a member’s code.',
        title: 'Set how long these promo codes will be active.',
      },
      nextButtonConfig: {
        className: styles.wizardNextButton,
        disabled: reviewButtonDisabled,
        text: 'Review',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: false,
        text: 'Confirm Members',
      },
      stepNum: 2,
    }),
    reviewMembers({
      instructions: {
        description: state.saving ? '' : 'Make sure everything looks good before you generate the promo codes.',
        title: state.saving ? '' : 'Review your promo code details.',
      },
      nextButtonConfig: {
        action: () => actions.save(4),
        className: styles.wizardNextButton,
        disabled: state.saving,
        loading: state.saving,
        showIcon: false,
        text: 'Generate Promo Codes',
      },
      previousButtonConfig: {
        className: styles.wizardButton,
        disabled: state.saving,
        text: 'Set Active Dates',
      },
      stepNum: 3,
    }, { disableNextStep: true }),
    completed({
      instructions: {
        description: `
        Their unique codes are starting to be generated, it should only take a
        few minutes. You can close this window. Once all codes have been
        generated, you can email these members their codes using the
        “Email Members” button above the table on this offer page.
       `.trim(),
        title: `
        Congrats! You are generating promo codes for ${`${selectedMembers.length.toLocaleString()} `}
        member${selectedMembers.length !== 1 ? 's' : ''}.
      `.trim(),
      },
      nextButtonConfig: {
        action: onClose,
        className: styles.wizardNextButton,
        disabled: false,
        showIcon: false,
        text: 'Done',
      },
      previousButtonConfig: {
        className: `${styles.wizardButton} ${styles.hidden}`,
        disabled: true,
        text: 'Review Members',
      },
      stepNum: 4,
    }),
  ];
  const currentSteps = props.isNewFlow ? newSteps : steps;
  if (state.error) {
    return currentSteps.map((currentSteps) => ({
      ...currentSteps,
      actionComponents: (
        <Notice type="error" message={state.error.message} />
      ),
    }));
  }

  return currentSteps;
};
