import { useQuery, QueryHookOptions } from '@apollo/client';
import { GET_ORDERS_COST_SUMMARY_QUERY } from '../queries';
import { GetOrdersCostSummary, GetOrdersCostSummaryVariables } from '../queries/types/GetOrdersCostSummary';

type IOptions = QueryHookOptions<GetOrdersCostSummary, GetOrdersCostSummaryVariables>;

export function useGetOrdersCostSummary(options: IOptions = {}) {
  const {
    loading, data, error, refetch,
  } = useQuery<GetOrdersCostSummary, GetOrdersCostSummaryVariables>(
    GET_ORDERS_COST_SUMMARY_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );

  return {
    isOrdersCostSummaryLoading: loading,
    ordersCostSummary: data?.costSummary,
    ordersCostSummaryError: error,
    refetchOrdersCostSummary: refetch,
  };
}
