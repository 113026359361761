import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_PRODUCT_DETAILS_QUERY } from '../queries';
import {
  GetProductDetailsQuery, GetProductDetailsQueryVariables, GetProductDetailsQuery_productDetail,
} from '../queries/types/GetProductDetailsQuery';

export type IProduct = GetProductDetailsQuery_productDetail;
type IOptions = QueryHookOptions<GetProductDetailsQuery, GetProductDetailsQueryVariables>;

export function useGetProductDetails(options: IOptions = {}) {
  const {
    data, loading, error, refetch,
  } = useQuery<GetProductDetailsQuery>(
    GET_PRODUCT_DETAILS_QUERY,
    {
        ...options,
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false,
      },
  );

  return {
    data, refetch, loading, error,
  } as const;
}
