/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_13nhd_157 {
  display: flex;
}

._justify-content-space-between_13nhd_161 {
  justify-content: space-between;
}

._tabular-nums_13nhd_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_13nhd_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SummaryView_13nhd_209 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
._SummaryView_13nhd_209 ._totalsSection_13nhd_214 {
  margin-bottom: 3rem;
}
._SummaryView_13nhd_209 ._postTypeSummarySection_13nhd_217 {
  margin-bottom: 6.25rem;
}
._SummaryView_13nhd_209 ._grid_13nhd_220 {
  /* aligns the outer edges of sections with its container */
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
._SummaryView_13nhd_209 ._grid_13nhd_220 ._section_13nhd_225 {
  margin: 1.5rem;
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  box-sizing: content-box;
  background: #fdfdfd;
  box-shadow: 0 0.5rem 3.75rem rgba(46, 66, 109, 0.08);
  border-radius: 0.5rem;
}

._analyzeDashboardSummary_13nhd_235 {
  filter: grayscale(1);
  margin: 1.5rem;
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
  box-sizing: content-box;
  background: #fdfdfd;
  box-shadow: 0 0.5rem 3.75rem rgba(46, 66, 109, 0.08);
  border-radius: 0.5rem;
}

._totalsAndPostTypeSummarySection_13nhd_246 {
  filter: grayscale(1);
}