import {
  useCallback,
  ChangeEvent,
  useState,
} from 'react';

interface ICharCounter {
  charCount: number;
  handleChange: (e: ChangeEvent) => void;
}

export const useCharCounter = (initialCount: number): ICharCounter => {
  const [charCount, setCharCount] = useState(initialCount);
  const handleChange = useCallback((e) => {
    setCharCount(e.target.value.length);
  }, []);
  return { charCount, handleChange };
};
