/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_f4x9n_157 {
  display: flex;
}

._justify-content-space-between_f4x9n_161 {
  justify-content: space-between;
}

._tabular-nums_f4x9n_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_f4x9n_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferForm_f4x9n_178 ._instructions_f4x9n_178 ._title_f4x9n_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._OfferForm_f4x9n_178 {
  position: relative;
}
._OfferForm_f4x9n_178 ._instructions_f4x9n_178 {
  margin-bottom: 2.1875rem;
}
._OfferForm_f4x9n_178 ._instructions_f4x9n_178 ._description_f4x9n_225 {
  color: #8f8d91;
  font-weight: 400;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 {
  margin-bottom: 1.5rem;
  padding: 0px 24px 0 24px;
  border-radius: 6px;
  border: 1px solid var(--neutral-color-palette-gray-4, #F0F0F0);
  background: var(--neutral-color-palette-gray-1, #FFF);
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 ._title_f4x9n_178 {
  align-items: center;
  color: #1a1818;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 ._title_f4x9n_178 .anticon,
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 ._title_f4x9n_178 ._icon_f4x9n_244 {
  margin-right: 0.5rem;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 .ant-form-item-extra {
  margin-bottom: 0;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 .ant-input[type=text] {
  padding-left: 0.6875rem;
  padding-right: 0.6875rem;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 .ant-input-affix-wrapper-sm {
  padding-left: 0.6875rem;
  padding-right: 0.6875rem;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 ._headerSpace_f4x9n_258 {
  margin-bottom: 24px;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 ._dividerColor_f4x9n_261 {
  color: #F0F0F0;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 .ant-collapse-header-text {
  font-size: 16px;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 .ant-collapse-content-box {
  padding: 0 !important;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 .ant-collapse-header {
  padding: 24px 40px 12px 0px !important;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 ._customLabelText_f4x9n_273 {
  margin-bottom: 1rem;
}
._OfferForm_f4x9n_178 ._formSection_f4x9n_229 ._AlertWrapper_f4x9n_276 {
  margin-bottom: 1.5rem;
}
._OfferForm_f4x9n_178 ._codeSuffixWrapper_f4x9n_279 {
  margin-left: auto;
  margin-right: 0;
  width: 95%;
}
._OfferForm_f4x9n_178 ._halfRow_f4x9n_284 {
  margin-bottom: 0.75rem;
}
._OfferForm_f4x9n_178 ._descriptionWrapper_f4x9n_287 {
  margin-left: 25px;
}
._OfferForm_f4x9n_178 ._descriptionText_f4x9n_290 {
  color: var(--neutral-color-palette-gray-7, #8c8c8c);
  margin-bottom: 1.5rem;
}
._OfferForm_f4x9n_178 ._indented_f4x9n_294 {
  padding-left: 1.5rem;
}
._OfferForm_f4x9n_178 ._indented_f4x9n_294 .ant-form-item-control {
  max-width: 9.375rem !important;
}
._OfferForm_f4x9n_178 ._centered_f4x9n_300 {
  text-align: center;
}
._OfferForm_f4x9n_178 ._radioGroup_f4x9n_303 {
  width: 100%;
}
._OfferForm_f4x9n_178 ._radioGroup_f4x9n_303 ._radio_f4x9n_303 {
  display: block;
  margin-bottom: 0.75rem;
}
._OfferForm_f4x9n_178 ._radioGroup_f4x9n_303 ._radio_f4x9n_303:last-child {
  margin-bottom: 0;
}
._OfferForm_f4x9n_178 ._expiredMessage_f4x9n_313 {
  box-sizing: border-box;
  border: 0.0625rem solid #3996e0;
  border-radius: 0.25rem;
  background: #eff5f9;
  color: #3996e0;
  font-weight: normal;
}
._OfferForm_f4x9n_178 ._datePicker_f4x9n_321 {
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 100%;
}
._OfferForm_f4x9n_178 ._datePicker_f4x9n_321 .ant-picker-input input {
  font-size: 0.875rem;
}
._OfferForm_f4x9n_178 ._tooltipList_f4x9n_330 {
  margin: 0;
  padding: 0 1.125rem;
}
._OfferForm_f4x9n_178 ._tooltipList_f4x9n_330 li {
  margin: 0.375rem 0;
  line-height: 1.125rem;
}
._OfferForm_f4x9n_178 ._hThreeRem_f4x9n_338 {
  height: 3rem;
}
._OfferForm_f4x9n_178 ._hasCharacterCount_f4x9n_341 {
  margin-bottom: 0;
}
._OfferForm_f4x9n_178 ._tagIcon_f4x9n_344 {
  transform: rotate(90deg);
}
._OfferForm_f4x9n_178 ._fullWidth_f4x9n_347 {
  width: 100%;
}
._OfferForm_f4x9n_178 ._archiveOfferAlert_f4x9n_350 {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}

._sharedWrapper_f4x9n_354, ._multipleConversionWrapper_f4x9n_354, ._utmWrapper_f4x9n_354 {
  border-radius: 6px;
  border: 1px solid var(--neutral-color-palette-gray-5, #D9D9D9);
  background: var(--neutral-color-palette-gray-1, #FFF);
}

._utmWrapper_f4x9n_354 {
  margin: 40px 0;
}

._multipleConversionWrapper_f4x9n_354 {
  margin: 20px 0;
}

._urlwrapper_f4x9n_368 {
  border-radius: 6px;
  background-color: #FFF;
  padding: 0 24px 1px 24px;
}
._urlwrapper_f4x9n_368 ._boxwrapper_f4x9n_373 {
  margin-bottom: 0.5rem;
  padding-top: 1.5rem;
  display: flex;
  column-gap: 8px;
  align-items: center;
}
._urlwrapper_f4x9n_368 ._boxwrapper_f4x9n_373 ._urltitle_f4x9n_380 {
  font-size: 1rem;
  font-weight: 600;
}
._urlwrapper_f4x9n_368 ._subheading_f4x9n_384 {
  font-size: 0.875rem;
  font-weight: 400;
}
._urlwrapper_f4x9n_368 ._utmexample_f4x9n_388 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--neutral-color-palette-gray-7, #8c8c8c);
}
._urlwrapper_f4x9n_368 ._customText_f4x9n_395 {
  margin-bottom: 8px;
  color: var(--neutral-color-palette-gray-6, #BFBFBF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
._urlwrapper_f4x9n_368 ._inputBox_f4x9n_404 {
  margin-bottom: 1.5rem;
}
._urlwrapper_f4x9n_368 ._Closefield_f4x9n_407 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
._urlwrapper_f4x9n_368 ._Closefield_f4x9n_407 .ant-select-selector {
  width: 8.125rem !important;
}
._urlwrapper_f4x9n_368 ._disable-option_f4x9n_415 ._ant-select-item-option-content_f4x9n_415 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  border: 2px solid red !important;
}

._disable-option_f4x9n_415 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  font-family: "SF Pro Text";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

.custom-button {
  border: none;
  background: none !important;
}
.custom-button svg {
  color: #000;
}

.custom-button:hover svg {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

._promoCodeContainer_f4x9n_446 ._descTextColor_f4x9n_446 {
  color: var(--neutral-color-palette-gray-8, #505256);
  margin-bottom: 1.5rem;
}
._promoCodeContainer_f4x9n_446 ._customRadioGroup_f4x9n_450 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
._promoCodeContainer_f4x9n_446 ._customRadioGroup_f4x9n_450 span {
  text-transform: capitalize;
}
._promoCodeContainer_f4x9n_446 ._samplePromoContainer_f4x9n_457 {
  padding: 12px;
  border-radius: 6px;
  background: var(--branding-accents-background-1, #F0F2F5);
}

._purchaseTypeContainer_f4x9n_463 .ant-radio-wrapper {
  display: block;
  margin-bottom: 1rem;
}
._purchaseTypeContainer_f4x9n_463 .ant-radio-wrapper:last-child {
  margin-bottom: 0;
}

._offerSyncContainer_f4x9n_471 .ant-switch {
  margin-right: 0.5rem;
}
._offerSyncContainer_f4x9n_471 ._customSyncText_f4x9n_474 {
  margin-top: 0.5rem;
  color: var(--neutral-color-palette-gray-9, #1F1F21);
}
._offerSyncContainer_f4x9n_471 ._offerSyncForm_f4x9n_478 {
  margin-bottom: 0;
}
._offerSyncContainer_f4x9n_471 ._syncContainer_f4x9n_481 {
  display: flex;
}

._offerStatusContainer_f4x9n_485 .ant-picker {
  width: 100%;
}

._CustomTextColor_f4x9n_489 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  margin-bottom: 0.5rem;
}

.radioCustom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.radioCustom .ant-radio-wrapper {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 4px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioCustom .ant-radio-wrapper-checked {
  border: 2px solid #167CF4;
  border-radius: 8px;
  padding: 4px;
  background-color: #F4FCFF;
  z-index: 1;
}
.radioCustom .ant-radio {
  position: absolute;
  left: -9999px;
  overflow: hidden;
}
.radioCustom .ant-radio + * {
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
}

._disableRadio_f4x9n_528 {
  opacity: 0.5;
  pointer-events: none;
}

._payoutOptionContainer_f4x9n_533 {
  margin-bottom: 24px;
}

._startDateBorder_f4x9n_537 .ant-picker-status-error.ant-picker,
._startDateBorder_f4x9n_537 .ant-picker-status-error.ant-picker:not([disabled]):hover {
  border: 1px solid #d9d9d9;
  box-shadow: none;
}

._mPayoutConatiner_f4x9n_543 {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}
._mPayoutConatiner_f4x9n_543 ._addPayoutButton_f4x9n_548 {
  width: 100%;
  font-size: 14px;
}
._mPayoutConatiner_f4x9n_543 ._defaultPayout_f4x9n_552 {
  background-color: #F1E5AC;
  padding: 0 8px;
  margin: 0 5px;
  border-radius: 5px;
}

._radioCustomContainer_f4x9n_559 {
  margin: 24px 0px;
  display: flex;
  justify-content: space-between;
}
._radioCustomContainer_f4x9n_559 ._radioItem_f4x9n_564 {
  padding: 0.5rem;
}
._radioCustomContainer_f4x9n_559 ._radioItem_f4x9n_564 ._image_f4x9n_567 {
  width: 100%;
}
._radioCustomContainer_f4x9n_559 ._radioItem_f4x9n_564 ._description_f4x9n_225 {
  font-weight: bold;
}

._marginOnAlert_f4x9n_574 {
  margin-top: 0.5rem;
  border: 1px solid #FFE58F;
  border-radius: 6px;
}

._errorMessage_f4x9n_580 {
  color: #FF4D4F;
}

._alertContainer_f4x9n_584 {
  margin-bottom: 24px;
}

._indentationMargin_f4x9n_588 {
  margin-top: 8px;
  margin-left: 25px;
}

._marginBottom8_f4x9n_593 {
  margin-bottom: 8px;
}