import * as React from 'react';
import { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';

import { useAuth } from '@frontend/context/authContext';
import { OverlaySpinner } from '@components';
import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { useGetNoCatalogueProjectConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetNoCatalogueProjectConfig';
import { MODES, SetupNotice } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/OrderRequestSetup/components';
import { useApolloClient } from '@frontend/applications/ProductFulfillmentApp/context';
import { useCreateProjectConfig, useUpdateProjectConfig } from '@frontend/applications/ProductFulfillmentApp/hooks';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { UpdateProjectConfigInput as ProjectConfig } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { message } from '@revfluence/fresh';

import { ProjectConfigSetupDrawer } from '../../components/ProjectConfigSetupDrawer/ProjectConfigSetupDrawer';
import ProjectConfigSetupContainer from '../ProjectConfigSetupContainer/ProjectConfigSetupContainer';

const omitDeep = require('omit-deep');

interface IProps {
    programId: number;
    programName: string;
}
const defaultFormFields = [
    {
        enabled: true,
        key: 'quantity',
        required: true,
        label: 'Quantity',
    },
    {
        enabled: true,
        key: 'productName',
        required: true,
        label: 'Product Name',
    },
    {
        enabled: false,
        key: 'productSKU',
        required: true,
        label: 'Product SKU',
    },
    {
        enabled: false,
        key: 'productURL',
        required: true,
        label: 'Product URL',
    },
    {
        enabled: false,
        key: 'productPreferences',
        required: true,
        label: 'Product Preferences',
    },
];

const _WorkflowNotice: React.FC<IProps> = (props) => {
    const { programId, programName } = props;
    const {
        loading, projectConfig: initialProjectConfig, error, refetch: refetchProjectConfig,
    } = useGetNoCatalogueProjectConfig({
        variables: { projectId: programId },
        skip: !programId,
    });

    const [containerKey, setContainerKey] = useState(Date.now());
    const { loading: submittingProjectConfig, createProjectConfig, error: projectConfigSubmissionError } = useCreateProjectConfig();
    const { loading: updatingProjectConfig, updateProjectConfig, error: updateProjectConfigError } = useUpdateProjectConfig();
    const [visible, setVisible] = useState(false);
    const [projectConfigForm, setProjectConfigForm] = useState<ProjectConfig | null>({
        displayPrice: false,
        noCatalogueConfig: {
            additionalDetails: '',
            formFields: defaultFormFields,
        },
    });
    const [isSaveFormEnabled, setIsSaveFormEnabled] = useState<boolean>(true);

    useEffect(() => {
        setProjectConfigForm((currentState) => {
            // Generate new product config with updated state
            const newProductConfig = {
                ...currentState,
                ...omitDeep(initialProjectConfig, '__typename'),
            };

            if (initialProjectConfig?.noCatalogueConfig?.formFields) {
                const updatedFormFields = currentState?.noCatalogueConfig?.formFields?.map((field) => {
                    const correspondingField = initialProjectConfig.noCatalogueConfig.formFields.find((initialField) => initialField.key === field.key);
                    return correspondingField ? { ...field, ...correspondingField } : field;
                });
                newProductConfig.noCatalogueConfig.formFields = updatedFormFields;
            }
            return newProductConfig;
        });
    }, [initialProjectConfig]);

    const { showErrorMessage } = useMessagingContext();

    const title = initialProjectConfig ? 'You can edit the Order Request Form and the instructions for this collaboration.' : 'Set Up Order Request Form ';
    const description = initialProjectConfig ? null : 'Create your Order Request Form and provide instructions for how to choose products for this collaboration.';
    const mode = initialProjectConfig ? MODES.EDIT : MODES.CREATE;

    const onChange = (projectConfigForm: ProjectConfig) => {
        setProjectConfigForm(projectConfigForm);
    };

    const onSubmit = async () => {
        if (initialProjectConfig) {
            await updateProjectConfig({
                variables: {
                    id: initialProjectConfig?.id,
                    updates: {
                        instructionText: projectConfigForm.instructionText,
                        quantityMax: projectConfigForm.quantityMax,
                        displayPrice: false,
                        noCatalogueConfig: {
                            formFields: projectConfigForm.noCatalogueConfig.formFields,
                        },
                    },
                },
            });
            message.success('The order request form was successfully updated.');
        } else {
            await createProjectConfig({
                variables: {
                    projectConfig: {
                        displayPrice: false,
                        instructionText: projectConfigForm.instructionText,
                        projectId: programId,
                        type: ProjectConfigType.NoCatalogue,
                        noCatalogueConfig: {
                            formFields: projectConfigForm.noCatalogueConfig.formFields,
                        },
                        quantityMax: projectConfigForm.quantityMax,
                    },
                },
            });
            message.success('The order request form was successfully created.');
        }
        await refetchProjectConfig();
        setVisible(false);
    };

    if (error) {
        logger.error(error);
        showErrorMessage('There was an error loading the project config. Please try again later.');
    }
    if (projectConfigSubmissionError || updateProjectConfigError) {
        logger.error(projectConfigSubmissionError || updateProjectConfigError);
        showErrorMessage('There was an error submitting the project config. Please try again later.');
    }

    const onClose = () => {
        setVisible(false);

        // Check if the formFields in initialProjectConfig is empty or undefined
        const formFields = initialProjectConfig?.noCatalogueConfig?.formFields?.length
            ? initialProjectConfig.noCatalogueConfig.formFields
            : defaultFormFields;

        setProjectConfigForm({
            ...omitDeep(initialProjectConfig, '__typename'),
            noCatalogueConfig: {
                ...initialProjectConfig?.noCatalogueConfig,
                formFields,
            },
        });

        setContainerKey(Date.now());
    };

    const onOpen = () => {
        setVisible(true);
    };

    const orderRequestSetup = (
      <>
        {(loading || submittingProjectConfig || updatingProjectConfig) && <OverlaySpinner />}
        <ProjectConfigSetupDrawer
          visible={visible}
          onSubmit={onSubmit}
          onClose={onClose}
          mode={mode}
          onOpen={onOpen}
          isSaveFormEnabled={isSaveFormEnabled}
        >
          <ProjectConfigSetupContainer
            key={containerKey}
            programName={programName}
            projectConfig={projectConfigForm}
            onChange={onChange}
            hasConfig={!!initialProjectConfig}
            setIsSaveFormEnabled={setIsSaveFormEnabled}
          />
        </ProjectConfigSetupDrawer>
      </>
    );

    return (
      <SetupNotice title={title} description={description} actionComponent={orderRequestSetup} mode={mode} />
    );
};

export const WorkflowNotice: React.FC<Readonly<IProps>> = (props) => {
    const { token } = useAuth();
    const apolloClient = useApolloClient(token);

    return (
      <ApolloProvider client={apolloClient}>
        <_WorkflowNotice {...props} />
      </ApolloProvider>
    );
};
